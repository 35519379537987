import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 24px;
  
  .timeline-title {
    display: block;
    font-size: 14px;
    font-weight: bold;
  }

  .timeline-detail {
    display: block;
    font-size: 12px;
    font-weight: normal;
  }
  
`;