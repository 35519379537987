import styled from "styled-components";
import { Divider } from "antd";

export const Container = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 20px;

  padding-bottom: 150px;
`;

export const Title = styled.h1`
  font-family: Asap;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  color: #4b4b4b;
  margin: 0;
`;

export const CustomDivider = styled(Divider)`
  margin: 20px 0 20px 0 !important;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 20px 0 20px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;

export const Body = styled.div`
  .ant-row-flex {
    width: 100%;
  }
`;
