export const Types = {
  RESET_DATA: "farm/RESET_DATA",
  GET_ALL_FARM: "farm/GET_ALL_FARM",
  GET_ALL_FARM_SUCCESS: "farm/GET_ALL_FARM_SUCCESS",
  GET_ALL_FARM_ERROR: "farm/GET_ALL_FARM_ERROR",
  GET_FARM: "farm/GET_FARM",
  GET_FARM_SUCCESS: "farm/GET_FARM_SUCCESS",
  GET_FARM_ERROR: "farm/GET_FARM_ERROR",
  GET_NEW_FARM: "farm/GET_NEW_FARM",
  SHOW_DRAWER_FARM: "farm/SHOW_DRAWER_FARM",
  HIDE_DRAWER_FARM: "farm/HIDE_DRAWER_FARM",
  SAVE_FARM: "farm/SAVE_FARM",
  SAVE_FARM_SUCCESS: "farm/SAVE_FARM_SUCCESS",
  SAVE_FARM_ERROR: "farm/SAVE_FARM_ERROR",
  DELETE_FARM: "farm/DELETE_FARM",
  DELETE_FARM_SUCCESS: "farm/DELETE_FARM_SUCCESS",
  DELETE_FARM_ERROR: "farm/DELETE_FARM_ERROR",
  ACTIVATE_FARM: "farm/ACTIVATE_FARM",
  ACTIVATE_FARM_SUCCESS: "farm/ACTIVATE_FARM_SUCCESS",
  ACTIVATE_FARM_ERROR: "farm/ACTIVATE_FARM_ERROR",
  UPDATE_OWNERS_DATA: "farm/UPDATE_OWNERS_DATA",
};

export const Creators = {
  indexFarm: (
    groupId,
    page = 0,
    sorter = {},
    filters = "",
    size = 10,
    signal = null
  ) => ({
    type: Types.GET_ALL_FARM,
    payload: {
      groupId,
      page,
      sorter,
      filters,
      size,
      signal,
    },
  }),
  indexFarmSuccess: (data) => ({
    type: Types.GET_ALL_FARM_SUCCESS,
    payload: {
      data,
    },
  }),
  indexFarmError: (error) => ({
    type: Types.GET_ALL_FARM_ERROR,
    payload: {
      error,
    },
  }),
  showFarm: (groupId, id) => ({
    type: Types.GET_FARM,
    payload: {
      groupId,
      id,
    },
  }),
  showFarmSuccess: (data) => ({
    type: Types.GET_FARM_SUCCESS,
    payload: {
      data,
    },
  }),
  showFarmError: (error) => ({
    type: Types.GET_FARM_ERROR,
    payload: {
      error,
    },
  }),
  showDrawer: (actionType = "edit") => ({
    type: Types.SHOW_DRAWER_FARM,
    payload: {
      actionType,
    },
  }),
  hideDrawer: () => ({
    type: Types.HIDE_DRAWER_FARM,
    payload: {},
  }),
  saveFarm: (groupId, id = null, farm, saveOptions) => ({
    type: Types.SAVE_FARM,
    payload: {
      groupId,
      id,
      farm,
      saveOptions,
    },
  }),
  saveFarmSuccess: (data, saveOptions) => ({
    type: Types.SAVE_FARM_SUCCESS,
    payload: { data, saveOptions },
  }),
  saveFarmError: (error) => ({
    type: Types.SAVE_FARM_ERROR,
    payload: { error },
  }),
  deleteFarm: (groupId, id) => ({
    type: Types.DELETE_FARM,
    payload: {
      groupId,
      id,
    },
  }),
  deleteFarmSuccess: (data) => ({
    type: Types.DELETE_FARM_SUCCESS,
    payload: { data },
  }),
  deleteFarmError: (error) => ({
    type: Types.DELETE_FARM_ERROR,
    payload: { error },
  }),
  activateFarm: (groupId, id) => ({
    type: Types.ACTIVATE_FARM,
    payload: {
      groupId,
      id,
    },
  }),
  activateFarmSuccess: (data) => ({
    type: Types.ACTIVATE_FARM_SUCCESS,
    payload: { data },
  }),
  activateFarmError: (error) => ({
    type: Types.ACTIVATE_FARM_ERROR,
    payload: { error },
  }),
  updateOwnersData: (data) => ({
    type: Types.UPDATE_OWNERS_DATA,
    payload: { data }
  })
};

const INITIAL_STATE = {
  isLoading: false,
  isLoadingRequest: false,
  data: [],
  farmData: null,
  drawerVisible: false,
  error: null,
  owners: [],
  isOwnersValid: true,
};

export default function Farm(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_ALL_FARM:
      return { ...state, isLoading: true };
    case Types.GET_ALL_FARM_SUCCESS:
      const { data: farms } = action.payload;
      return { ...state, isLoading: false, data: farms, owners: farms.owners || [] };
    case Types.GET_ALL_FARM_ERROR:
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: null,
        error,
      };
    case Types.GET_FARM:
      return { ...state, isLoadingRequest: true };
    case Types.GET_FARM_SUCCESS:
      return {
        ...state,
        isLoadingRequest: false,
        farmData: action.payload.data,
        owners: action.payload.data?.owners,
      };
    case Types.GET_FARM_ERROR:
      return {
        ...state,
        isLoadingRequest: false,
        farmData: null,
        error,
      };
    case Types.SHOW_DRAWER_FARM:
      return {
        ...state,
        drawerVisible: true,
        farmData: action.payload.actionType === "new" ? null : state.farmData,
      };
    case Types.HIDE_DRAWER_FARM:
      return { ...state, drawerVisible: false, farmData: null, owners: [] };
    case Types.SAVE_FARM:
      return { ...state, isLoadingRequest: true };
    case Types.SAVE_FARM_SUCCESS:
      return {
        ...state,
        isLoadingRequest: false,
        farmData: null,
        error: null,
        owners: [],
        drawerVisible: action.payload.saveOptions,
      };
    case Types.SAVE_FARM_ERROR:
      return {
        ...state,
        isLoadingRequest: false,
        error: action.payload.error,
      };
    case Types.DELETE_FARM:
      return { ...state, isLoading: true };
    case Types.DELETE_FARM_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case Types.DELETE_FARM_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case Types.ACTIVATE_FARM:
      return { ...state, isLoading: true };
    case Types.ACTIVATE_FARM_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case Types.ACTIVATE_FARM_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case Types.UPDATE_OWNERS_DATA:
      const ownersData = action.payload.data;
      
      const numOfMainOwners = ownersData.reduce((n, owner) => n + (owner.mainOwner === true), 0);
      const sumOfParticipations = ownersData.map((o) => o.participation)?.reduce((a, b) => a + b, 0);
      const isValid = numOfMainOwners === 1 && sumOfParticipations === 100;
      return { ...state, owners: action.payload.data, isOwnersValid: isValid}
    default:
      return state;
  }
}
