import React from "react";

const StatusFalseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10.674" height="10.674" viewBox="0 0 10.674 10.674" style={{margin:3}}>
  <g id="Group_4383" data-name="Group 4383" transform="translate(-389.97 -1167.97)">
    <line id="Line_790" data-name="Line 790" x2="7.854" y2="7.854" transform="translate(390.5 1168.5)" fill="none" stroke="#d44c4c" strokeWidth="1.5"/>
    <line id="Line_791" data-name="Line 791" y1="7.854" x2="7.854" transform="translate(390.5 1168.5)" fill="none" stroke="#d44c4c" strokeWidth="1.5"/>
  </g>
</svg>
);
export default StatusFalseIcon;
