import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Table, Dropdown, Menu, Icon } from "antd";
import { Creators as QuotaActions } from "../../store/ducks/quota";
import { CardCustom, Title } from "./styles";

import DrawerQuota from "../../components/drawers/quota";

import TagStatus from "../../components/utils/tagStatus";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import MenuIcon from "../../components/utils/table/icons/menu";
import ButtonStandard from "../../components/utils/button";

const Quotas = () => {
  const dispatch = useDispatch();
  const {
    quota: { data, isLoadingTable },
    app: {
      translation,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (groupId && farmId) {
      dispatch(QuotaActions.index(groupId, farmId));
    }
  }, [groupId, farmId]);

  const { Column } = Table;

  function handleNew() {
    dispatch(QuotaActions.showOrHideDrawer());
  }

  function handleEdit(record) {
    dispatch(QuotaActions.showOrHideDrawer(record.id));
  }

  async function handleInactivateOrActivate(record, action) {
    try {
      if (action === "inactivate") {
        dispatch(
          QuotaActions.inactivateQuota(
            groupId,
            farmId,
            record.id,
          )
        );
      } else {
        dispatch(
          QuotaActions.activateQuota(
            groupId,
            farmId,
            record.id
          )
        );
      }
    } catch (error) { }
  }

  function renderMenu(record) {
    return record.groupId !== null ? (
      <Menu>
        <Menu.Item key="0">
          <button onClick={() => handleEdit(record)}>
            {translation.table.menu.edit}
          </button>
        </Menu.Item>
        {record.status !== "Active" ? (
          <Menu.Item key="1">
            <button
              onClick={() => handleInactivateOrActivate(record, "activate")}
            >
              {translation.table.menu.activate}
            </button>
          </Menu.Item>
        ) : (
          <Menu.Item key="2">
            <button
              onClick={() => handleInactivateOrActivate(record, "inactivate")}
            >
              {translation.table.menu.inactivate}
            </button>
          </Menu.Item>
        )}
      </Menu>)
      : <></>
  }

  return (
    <CardCustom>
      <Row type="flex" justify="space-between" style={{ marginBottom: "19px" }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Title>
            {translation.generalParameters.quotas.table.title}
          </Title>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} align="right">
          <Row>
            <ButtonStandard
              buttonType="typeWithoutBackground"
              onClick={() => dispatch(QuotaActions.index(groupId, farmId))}
            >
              <Icon type="reload" />
            </ButtonStandard>
            <ButtonStandard buttonType="type8" onClick={handleNew}>
              {translation.generalParameters.quotas.buttonNew}
            </ButtonStandard>
          </Row>

        </Col>
      </Row>
      <Row type="flex">
        <Table
          loading={isLoadingTable}
          rowKey="name"
          size="small"
          dataSource={data !== null ? data : []}
          scroll={{
            x: true,
          }}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 10,
            pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
          }}
          style={{ width: "100%", margin: "0" }}
        >
          <Column
            title={
              translation.generalParameters.quotas.table.columns.name
            }
            dataIndex="name"
            key="name"
            align="left"
          />
          <Column
            title={
              translation.generalParameters.quotas.table.columns.status
            }
            dataIndex="status"
            key="status"
            align="left"
            sorter={(first, second) => {
              return first.status.localeCompare(second.status, "pt-BR", {
                numeric: false,
                ignorePunctuation: true,
              });
            }}
            filtered
            filters={[
              {
                text: translation.status.active,
                value: "Active",
              },
              {
                text: translation.status.inactive,
                value: "Inactive",
              },
            ]}
            filterMultiple
            onFilter={(value, record) => record.status.includes(value)}
            render={(status) =>
              status === "Active" ? (
                <TagStatus
                  background="#C5F1CA"
                  borderColor="#106518"
                  color="#106518"
                >
                  {translation.status.active}
                </TagStatus>
              ) : (
                <TagStatus
                  background="#FBC7C7"
                  borderColor="#D44C4C"
                  color="#D44C4C"
                >
                  {translation.status.inactive}
                </TagStatus>
              )
            }
          />
          <Column
            align="left"
            width={50}
            render={(text, record) => (
              <Dropdown
                overlay={renderMenu(record)}
                trigger={["click"]}
                key={record.id}
              >
                <MenuContainer>
                  <MenuIcon />
                </MenuContainer>
              </Dropdown>
            )}
          />
        </Table>
      </Row>
      <DrawerQuota />
    </CardCustom>
  );
};

export default Quotas;
