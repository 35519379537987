import React, { useState, useEffect } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Creators as UserActions } from "../../store/ducks/user";
// Components
import {
  Input,
  Button,
  Icon,
  Menu,
  Row,
  Col,
  Table,
  Dropdown,
  notification,
} from "antd";
import { Container, PageTitle } from "./styles";
import TagStatus from "../../components/utils/tagStatus";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import MenuIcon from "../../components/utils/table/icons/menu";
import ButtonStandard from "../../components/utils/button";
import DrawerInviteUser from "../../components/drawers/inviteUser";
// Services
import {
  pageAllUserInvitesByFarmIdAndGroupId,
  resubmitUserInvite,
} from "../../services/userInviteService";
import AllowedComponentTo from "../../components/utils/allowedComponentTo";

const AccessInvitations = () => {
  // Variable
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Variable Redux
  const {
    translation,
    user: { id: userId },
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  // Effect
  useEffect(() => {
    async function fetchData(page = 0, sorter = {}, filters = "", size = 10) {
      try {
        setIsLoading(true);
        const {
          data: { results },
        } = await pageAllUserInvitesByFarmIdAndGroupId({
          groupId,
          farmId,
          page,
          sorter,
          filters,
          size,
        });
        setIsLoading(false);
        setData(results);
      } catch (error) {
        notification.error({
          message: "Não foi possível retornar os convites",
        });
        setIsLoading(false);
        setData(null);
      }
    }
    fetchData();
  }, [groupId, farmId]);

  // Methods

  async function fetchData(page = 0, sorter = {}, filters = "", size = 10) {
    try {
      setIsLoading(true);
      const {
        data: { results },
      } = await pageAllUserInvitesByFarmIdAndGroupId({
        groupId,
        farmId,
        page,
        sorter,
        filters,
        size,
      });
      setIsLoading(false);
      setData(results);
    } catch (error) {
      notification.error({
        message: "Não foi possível retornar os convites",
      });
      setIsLoading(false);
      setData(null);
    }
  }

  function menu(id, record) {
    return (
      <Menu>
        {id !== userId &&
          record != null &&
          (record.status === "Sended" || record.status === "Expirated") && (
            <Menu.Item key="1">
              <button onClick={() => handleResubmitInvite(id)}>
                Reenviar convite
              </button>
            </Menu.Item>
          )}
      </Menu>
    );
  }

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
    };
  }

  function handleSearch(selectedKeys, confirm) {
    confirm();
  }

  function handleReset(clearFilters) {
    clearFilters();
  }

  async function handleTableChange(pagination, filters, sorter) {
    let search = "";
    if (Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v.length > 0) {
          if (k === "status") {
            let statusString = "";
            for (let i = 0; i < v.length; i++) {
              statusString += i === v.length - 1 ? `'${v[i]}'` : `'${v[i]}',`;
            }
            search = `${
              search !== "" ? `${search};` : ``
            }u.${k} IN (${statusString})`;
          } else if (k === "closingDate") {
            search = `${search !== "" ? `${search};` : ``}${k} between '${
              v[0]
            }' and '${v[1]}'`;
          } else {
            search = `${
              search !== "" ? `${search};` : ``
            }upper(u.${k}) like upper('%25${v}%25')`;
          }
        }
      });
    }
    fetchData(pagination.current, sorter, search);
  }

  function handleInviteUser() {
    dispatch(UserActions.showOrHideInviteUserDrawer());
  }

  async function handleResubmitInvite(id) {
    setIsLoading(true);
    try {
      await resubmitUserInvite({
        groupId,
        farmId,
        id,
      });
      setIsLoading(false);
      fetchData();
      notification.success({
        message: "Convite reenviado",
      });
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: "Houve um erro ao reenviar o convite",
      });
    }
  }

  return (
    <Container>
      <Row type="flex" className="rowHeader" align="middle">
        <Col span={8}>
          <PageTitle>{translation.sidebar.userInvite}</PageTitle>
        </Col>
        <Col span={16} align="right">
          <ButtonStandard
            buttonType="typeWithoutBackground"
            onClick={() => fetchData()}
          >
            <Icon type="reload" />
          </ButtonStandard>
          <AllowedComponentTo roles={["Technician", "Trial"]} reverse hide>
            <ButtonStandard buttonType="type8" onClick={handleInviteUser}>
              Convidar
            </ButtonStandard>
          </AllowedComponentTo>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <Table
            loading={isLoading}
            rowKey="id"
            dataSource={data != null ? data.content : []}
            pagination={{
              total: data != null ? data.totalElements : 0,
              size: data != null ? data.size : 0,
              current: data != null ? data.number + 1 : 0,
              hideOnSinglePage:
                data !== null && Object.entries(data).length !== 0
                  ? data.totalElements > 10
                    ? false
                    : true
                  : true,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
            }}
            size="small"
            scroll={{
              x: true,
            }}
            onChange={handleTableChange}
          >
            <Table.Column
              title={translation.farm.table.columns.status}
              dataIndex="status"
              key="status"
              align="left"
              sorter
              render={(status) =>
                status === "Sended" ? (
                  <TagStatus
                    background="#E6F5FF"
                    borderColor="#4A85AE"
                    color="#4A85AE"
                  >
                    Convite enviado
                  </TagStatus>
                ) : status === "Expirated" ? (
                  <TagStatus
                    background="#F5F5F5"
                    borderColor="#A5A5A5"
                    color="#A5A5A5"
                  >
                    Convite expirado
                  </TagStatus>
                ) : status === "Accepted" ? (
                  <TagStatus
                    background="#E2FFE5"
                    borderColor="#33C162"
                    color="#33C162"
                  >
                    Convite aceito
                  </TagStatus>
                ) : (
                  <TagStatus
                    background="#FBC7C7"
                    borderColor="#D44C4C"
                    color="#D44C4C"
                  >
                    Convite cancelado
                  </TagStatus>
                )
              }
            />
            <Table.Column
              title="E-mail"
              dataIndex="email"
              key="email"
              align="left"
              sorter
              {...getColumnSearchProps("email")}
            />
            <Table.Column
              title="Enviado em"
              dataIndex="createdAt"
              key="createdAt"
              align="left"
              sorter
              render={(text, record) => (
                <span>
                  {moment(record.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                </span>
              )}
            />
            <Table.Column
              title="Expira em"
              dataIndex="expirationDate"
              key="expirationDate"
              align="left"
              sorter
              render={(text, record) => (
                <span>
                  {moment(record.expirationDate).format("DD/MM/YYYY HH:mm:ss")}
                </span>
              )}
            />
            <Table.Column
              align="left"
              render={(text, record) => (
                <Dropdown
                  overlay={menu(record.id, record)}
                  trigger={["click"]}
                  key={record.id}
                >
                  <MenuContainer>
                    <MenuIcon />
                  </MenuContainer>
                </Dropdown>
              )}
            />
          </Table>
        </Col>
      </Row>
      {/* Drawers */}
      <DrawerInviteUser callBackMethod={fetchData} />
    </Container>
  );
};

export default withRouter(AccessInvitations);
