import apiIam from "../config/api_iam";
import getHeaders from "./getHeaders";

export function findAll(payload) {
  const { page, sorter, filters, size } = payload;
  const { columnKey, order } = sorter;

  const orderS = order === "descend" ? "DESC" : "ASC";

  return apiIam.get(
    `/bovexo/supplier/management?${page ? `&page=${page - 1}` : ""}
		${size ? `&size=${size}` : ""}
		${
      Object.entries(sorter).length > 0
        ? `&sort=${columnKey}&direction=${orderS}`
        : ""
    }
		${filters !== "" ? `&search=${filters}` : ""}`,
    getHeaders(false, null)
  );
}

export function findById(id) {
  return apiIam.get(
    `/bovexo/supplier/management/${id}`,
    getHeaders(false, null)
  );
}

export function saveSupplierGroup(payload) {
  return apiIam.post(
    `/bovexo/supplier/management`,
    payload,
    getHeaders(false, null)
  );
}

export function updateSupplierGroup(payload) {
  const { body, id } = payload;

  return apiIam.patch(
    `/bovexo/supplier/management/${id}`,
    body,
    getHeaders(false, null)
  );
}

export function verifyApprovalStatus(userConfirmationId) {
  return apiIam.get(
    `/bovexo/auth/supplier/management/approval/${userConfirmationId}/confirm`
  );
}

export function resendEmail(id) {
  return apiIam.post(
    `/bovexo/supplier/management/${id}/resend`,
    null,
    getHeaders(false, null)
  );
}

export function getPreRegisterData(id) {
  return apiIam.get(`/bovexo/auth/supplier/management/register/${id}`);
}

export function registerUserBySupplierGroupInvite(payload) {
  return apiIam.post(`/bovexo/auth/supplier/management/register`, payload);
}
