import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 14px 5px 5px 5px !important;

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: flex !important;
    flex-direction: row !important;
    padding: 12px 30px 12px 13px !important;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-extra {
    margin-left: auto !important;
  }

  .ant-collapse > .ant-collapse-item {
    box-shadow: rgb(216, 216, 216) 0px 5px 3px !important;
  }

  .ant-collapse-arrow {
    box-shadow: rgb(216, 216, 216) 0px 6px 2px;
    padding: 2px;
  }
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;

export const NewLot = styled.button`
  width: 194.2px;
  height: 24px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #684e94;
  color: #684e94;
  text-align: center;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 19px;
  justify-content: space-between;
  width: 100%;

  div.title-container {
    display: flex;
  }

  div.boitel {
    padding-right: 10px;
    span {
      font-weight: normal;
      font-size: 14px;
    }
  }

  div.title {
    padding-right: 14px;
    span {
      font-weight: bold;
      font-size: 18px;
    }
  }
  div.amountAnimals {
    padding: 0 14px;

    label {
      font-family: Asap;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: inherit;
    }

    span {
      font-family: Asap;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: #a9c133;
    }
  }
  div.providerAndDate {
    padding-left: 14px;
    padding-right: 14px;
    span {
      font-weight: bold;
      font-size: 16px;
    }
  }
  div.receinvingDate {
    padding-left: 14px;
    span {
      font-family: Asap;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: #c4c4c4;
    }
  }
`;

export const ExtraContainer = styled.div`
  display: flex;
  flex-direction: row;

  button {
    background: transparent;
    border-radius: 5px;
    border: none;
    width: 25px;
    height: 25px;
    align-items: center;
    margin: 0 5px;

    &.deleteButton:hover {
      background: #d44c4c;
      cursor: pointer;
      path {
        fill: #fff !important;
      }
    }

    &.editButton:hover {
      background: #4a85ae;
      cursor: pointer;
      path {
        fill: #fff !important;
      }
    }
  }
`;
