import React from "react";

// import { Container } from './styles';

function ExportExcelIcon() {
  return (
    <svg
      id="Group_4867"
      data-name="Group 4867"
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 55 55"
    >
      <rect
        id="Rectangle_1763"
        data-name="Rectangle 1763"
        width="55"
        height="55"
        rx="5"
        fill="#199d58"
      />
      <g
        id="Group_4864"
        data-name="Group 4864"
        transform="translate(11.999 12)"
      >
        <g id="Group_4863" data-name="Group 4863">
          <g id="Group_4345" data-name="Group 4345">
            <g id="Group_4863-2" data-name="Group 4863">
              <g id="pdf">
                <path
                  id="Path_2342"
                  data-name="Path 2342"
                  d="M97.972,0A1.978,1.978,0,0,0,96,1.972V29.586a1.978,1.978,0,0,0,1.972,1.972H117.7a1.978,1.978,0,0,0,1.972-1.972V7.89L111.779,0Z"
                  transform="translate(-89.341)"
                  fill="#e2e5e7"
                />
                <path
                  id="Path_2343"
                  data-name="Path 2343"
                  d="M353.972,7.89h5.917L352,0V5.917A1.978,1.978,0,0,0,353.972,7.89Z"
                  transform="translate(-329.561)"
                  fill="#b0b7bd"
                />
                <path
                  id="Path_2344"
                  data-name="Path 2344"
                  d="M389.917,133.917,384,128h5.917Z"
                  transform="translate(-359.589 -120.11)"
                  fill="#cad1d8"
                />
                <path
                  id="Path_2345"
                  data-name="Path 2345"
                  d="M58.383,254.412a1.222,1.222,0,0,1-1.1,1.31H33.1a1.222,1.222,0,0,1-1.1-1.31v-13.1A1.222,1.222,0,0,1,33.1,240H57.284a1.222,1.222,0,0,1,1.1,1.31Z"
                  transform="translate(-32 -228.109)"
                  fill="#1b7343"
                />
                <path
                  id="Path_2349"
                  data-name="Path 2349"
                  d="M114.738,416.986H96v.986h18.738a.989.989,0,0,0,.986-.986V416A.989.989,0,0,1,114.738,416.986Z"
                  transform="translate(-89.341 -389.359)"
                  fill="#cad1d8"
                />
              </g>
            </g>
          </g>
        </g>
        <text
          id="xls"
          transform="translate(3.001 24)"
          fill="#fff"
          fontSize="13"
          fontFamily="Asap-Bold, Asap"
          fontWeight="700"
        >
          <tspan x="0" y="0">
            XLS
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default ExportExcelIcon;
