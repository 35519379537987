import apiAnimals from "../config/api_animals";

// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiAnimals = axios.create({
//   baseURL: "http://localhost:8084",
// });

export function saveNewLotReceipt(payload) {
  const { groupId, farmId, lotReceipt, lotItens, signal } = payload;

  const copyLotReceipt = lotReceipt;
  const copyLotItens = lotItens;

  copyLotItens.map((item) => {
    item.id = "";
    return item;
  });

  const body = {
    ...copyLotReceipt,
    items: copyLotItens,
  };

  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/receipt/lots`,
    body,
    getHeaders(false, signal)
  );
}

export function getLotReceiptItemService(payload) {
  const { groupId, farmId, lotId, lotItemId, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/receipt/lots/${lotId}/itens/${lotItemId}`,
    getHeaders(false, signal)
  );
}

export function saveLotItemEntryService(payload) {
  const { groupId, farmId, lotId, lotItemId, body, signal } = payload;
  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/receipt/lots/${lotId}/itens/${lotItemId}/entries`,
    body,
    getHeaders(false, signal)
  );
}

export function deleteLotItemEntryService(payload) {
  const { groupId, farmId, lotId, lotItemId, lotItemEntryId, body, signal } =
    payload;
  return apiAnimals.delete(
    `/bovexo/${groupId}/farms/${farmId}/receipt/lots/${lotId}/itens/${lotItemId}/entries/${lotItemEntryId}`,
    getHeaders(false, signal)
  );
}
