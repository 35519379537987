import React from "react";
import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { FiMinus, FiPlus } from "react-icons/fi";
import { CustomCollapse } from "../styles";
import { Row, Icon, Table, Menu, Dropdown, notification } from "antd";
import InfoTooltip from "../../../../components/utils/infoTooltip";
import ButtonStandard from "../../../../components/utils/button";
import { Container as MenuContainer } from "../../../../components/utils/table/menu/styles";
import MenuIcon from "../../../../components/utils/table/icons/menu";

import { Creators as ReproductionPeriodActions } from "../../../../store/ducks/reproductionPeriod";

//Drawers
import DrawerReproductionPeriod from "../../../../components/drawers/reproductionPeriod"
import { deleteById } from "../../../../services/reproductionPeriodService";

// Services

function CardReproductionPeriod() {
	const [isEditing, setIsEditing] = useState(false);
	const [isLoadingRequest, setIsLoadingRequest] = useState(false);
	// Redux
	const {
		translation,
		groupSelected: { id: groupId },
		farmSelected: { id: farmId },
	} = useSelector((state) => state.app);

	const { data, isLoading, pagination } = useSelector((state) => state.reproductionPeriod);

	const dispatch = useDispatch();

	const { Column } = Table;

	// Callbacks
	const fetch = useCallback(async (page = 0, sorter = null, filters = null, size = 10) => {
		dispatch(ReproductionPeriodActions.getAllData(groupId, farmId, page, sorter, filters, size));
	}, [dispatch, farmId, groupId, pagination]);

	// Effects
	useEffect(() => {
		fetch();
	}, []);

	const handleTableChange = async (pagination, filters, sorter) => {
		let search = "";
		fetch(
			pagination.current,
			sorter,
			search,
			pagination.pageSize
		);
	};

	// Methods
	function openOrCloseDrawer(id) {
		dispatch(ReproductionPeriodActions.showOrHideDrawer(id));
	}

	function handleEdit(id) {
		dispatch(ReproductionPeriodActions.showOrHideDrawer(id));
	}

	async function handleDelete(id) {
		if(id !== null){
			try {
				setIsLoadingRequest(true)
				await deleteById({groupId, farmId, id})
				fetch();
			} catch (error) {
				notification.error({
					message: "Houve um erro ao excluir a estação de monta",
				});
				setIsLoadingRequest(false);
			}
		}
	}

	function menu(id, record) {
		return (
			<Menu>
				{record !== null && (
					<Menu.Item key="1" onClick={() => handleEdit(id)}>
						Editar
					</Menu.Item>
				)}
				{record !== null && record.canDelete === true && (
					<Menu.Item key="2" onClick={() => handleDelete(id)}>
						Excluir
					</Menu.Item>
				)}
			</Menu>
		);
	}

	return (
		<CustomCollapse
			expandIconPosition={"right"}
			expandIcon={(panelProps) =>
				panelProps.isActive ? <FiMinus size={16} /> : <FiPlus size={16} />
			}
		>
			<CustomCollapse.Panel header={
				<strong>
					{translation.reproductionParameters.reproductionPeriod.title}{" "}
					<InfoTooltip title={translation.reproductionParameters.reproductionPeriod.titleHelper} />
				</strong>
			} key={"1"}>
				<Row type="flex" justify="end" gutter={8}>
					<ButtonStandard
						buttonType="typeWithoutBackground"
						disabled={isLoading}
						onClick={()=>fetch(pagination.page, pagination.sorter, pagination.filters, pagination.size)}
					>
						<Icon type="reload" />
					</ButtonStandard>
					<ButtonStandard
						name="buttonCreateBudget"
						buttonType="type8"
						onClick={()=> openOrCloseDrawer(null)}
					>
						Novo registro
					</ButtonStandard>
				</Row>
				<Row className="bodyContent">
					<Table
						rowKey="id"
						loading={isLoading}
						dataSource={data != null ? data.content : []}
						pagination={{
							total: data != null ? data.totalElements : 0,
							size: data != null ? pagination.size : 0,
							current: data != null ? pagination.page : 0,
							hideOnSinglePage: true,
							showSizeChanger: true,
							pageSizeOptions: [
								"10",
								"20",
								"30",
								"100",
								"500",
								"1000",
								"2000",
							],
						}}
						scroll={{
							x: true,
						}}
						onChange={handleTableChange}
					>
						<Column
							title="Descrição"
							dataIndex="description"
							key="description"
							align="left"
							width={200}
						/>

						<Column
							title="Data inicial"
							dataIndex="startDate"
							key="startDate"
							align="left"
							width={200}
							render={(text, record) => (
								<span>
									{`${record.startDate !== null ? moment(record.startDate).format("DD/MM/YYYY") : ""
										}`}
								</span>
							)}
						/>

						<Column
							title="Data Final"
							dataIndex="endDate"
							key="endDate"
							align="left"
							width={200}
							render={(text, record) => (
								<span>
									{`${record.endDate !== null ? moment(record.endDate).format("DD/MM/YYYY") : ""
										}`}
								</span>
							)}
						/>

						<Column
							align="left"
							width={50}
							render={(text, record) => (
								<Dropdown
									overlay={menu(record.id, record)}
									trigger={["click"]}
									key={record.id}
								>
									<MenuContainer>
										<MenuIcon />
									</MenuContainer>
								</Dropdown>
							)}
						/>
					</Table>
				</Row>
			</CustomCollapse.Panel>
			<DrawerReproductionPeriod />
		</CustomCollapse>
	);
}

export default CardReproductionPeriod;
