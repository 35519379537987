import apiFinancial from "../config/api_financial";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiFinancial = axios.create({
//   baseURL: "http://localhost:8086",
// });

export function findAll(payload) {
  const { groupId, farmId } = payload;
  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/financial/projects`,
    getHeaders(false, null)
  );
}

export function save(payload) {
  const { groupId, farmId, id, body } = payload;
  if (id === null) {
    return apiFinancial.post(
      `/bovexo/${groupId}/farms/${farmId}/financial/projects`,
      body,
      getHeaders(false, null)
    );
  } else {
    return apiFinancial.put(
      `/bovexo/${groupId}/farms/${farmId}/financial/projects/${id}`,
      body,
      getHeaders(false, null)
    );
  }
}

export function destroy(payload) {
  const { groupId, farmId, id } = payload;

  return apiFinancial.delete(
    `/bovexo/${groupId}/farms/${farmId}/financial/projects/${id}`,
    getHeaders(false, null)
  );
}
