import React from "react";
import { Icon } from "antd";

const svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <g id="Grupo_1315" data-name="Grupo 1315" transform="translate(-980 -209)">
      <g
        id="Elipse_181"
        data-name="Elipse 181"
        transform="translate(980 209)"
        fill="none"
        stroke="#fe8d2a"
      >
        <circle cx="7.5" cy="7.5" r="7.5" stroke="none" />
        <circle cx="7.5" cy="7.5" r="7" fill="none" />
      </g>
      <path
        id="Caminho_1083"
        data-name="Caminho 1083"
        d="M2.6-10.556H2.2c-.56,0-.875.129-.9.375l-.129.961c0,.188.244.363.818.363h.4c.56,0,.875-.129.9-.375l.129-.961C3.419-10.38,3.175-10.556,2.6-10.556ZM2.257-7.872h-.4c-.56,0-.875.129-.9.375,0,0-.545,4.056-.545,4.384,0,1.055.617,1.489,1.736,1.489.5,0,1.176-.176,1.263-.61a4.559,4.559,0,0,0,.1-.7c0-.105-.029-.129-.115-.129A2.33,2.33,0,0,1,2.974-3c-.3,0-.43-.117-.43-.387,0-.117.531-4.126.531-4.126C3.075-7.7,2.831-7.872,2.257-7.872Z"
        transform="translate(985.586 222.556)"
        fill="#fe8d2a"
      />
    </g>
  </svg>
);

const InfoIcon = (props) => (
  <Icon id="bovexoInfoIcon" component={svg} {...props} />
);

export default InfoIcon;
