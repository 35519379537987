// import axios from "axios";
import apiFarms from "../config/api_farms";
import getHeaders from "./getHeaders";

// const apiFarms = axios.create({
//   baseURL: "http://localhost:8082",
// });

export function getPicketIndex(payload) {
  const {
    groupId,
    farmId,
    page,
    sorter,
    filters,
    size,
    withoutPagination,
    signal,
    ids,
  } = payload;

  const { field, order } = sorter;
  const defaultFilters = `&search=status%20not%20in%20(%27I%27)`;

  const orderS = order === "descend" ? "DESC" : "ASC";

  return apiFarms.post(
    `/bovexo/${groupId}/farms/${farmId}/pickets/list?
    ${page ? `&page=${page - 1}` : ""}
    ${size ? `&size=${size}` : ""}
    ${
      Object.entries(sorter).length > 0
        ? `&sort=${field}&direction=${orderS}`
        : ""
    }
    ${filters !== "" ? `&search=${filters}` : defaultFilters}
    ${withoutPagination ? `&withoutPagination=true` : ""}`,
    ids != null ? ids : null,
    getHeaders(false, signal)
  );
}

export function getPicketIndexDropDown(payload) {
  const { groupId, farmId, signal } = payload;
  return apiFarms.post(
    `/bovexo/${groupId}/farms/${farmId}/pickets/list?withoutPagination=true`,
    null,
    getHeaders(false, signal)
  );
}

export function getPicketShow(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/pickets/${id}`,
    getHeaders(false, signal)
  );
}

export function getPicketByLotId(payload) {
  const { groupId, farmId, lotId } = payload;
  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/lots/${lotId}/pickets`,
    getHeaders(false, null)
  );
}

export function savePicket(payload) {
  const { groupId, farmId, id, values, signal } = payload;

  const {
    name,
    importCode,
    observation,
    propertyType,
    leaseMonthlyCost,
    productionSubSystem,
    profitCenterId,
    lotId,
    supplierId,
    status,
    drinkingFountain,
    totalTroughSpace,
    pastureAnimalCapacity,
    pastureArea,
    pastureFertilized,
    pastureId,
    pastureKgAvailable,
    pastureLeafDensity,
    pastureDailyCost,
    pastureCurrentHeight,
    pastureCurrentHeightDate,
    pastureGrowthSpeed,
    lotStartDate,
    oldLotEndDate,
  } = values;

  const body = {
    name,
    importCode,
    observation,
    supplierId,
    leaseMonthlyCost,
    lotId,
    drinkingFountain,
    totalTroughSpace,
    pastureAnimalCapacity,
    pastureArea,
    pastureFertilized,
    pastureId,
    pastureKgAvailable,
    pastureLeafDensity,
    pastureDailyCost,
    pastureCurrentHeight,
    pastureCurrentHeightDate,
    pastureGrowthSpeed,
    propertyType:
      propertyType === "O"
        ? "Own"
        : propertyType === "F"
        ? "Foreign"
        : propertyType,
    productionSubSystem:
      productionSubSystem === "E"
        ? "Extensive"
        : productionSubSystem === "SI"
        ? "SemiIntensive"
        : productionSubSystem === "I"
        ? "Intensive"
        : productionSubSystem,
    status:
      status === "B"
        ? "Break"
        : status === "P"
        ? "Pasturage"
        : status === "R"
        ? "Renovation"
        : status === "M"
        ? "Maintenance"
        : status === "L"
        ? "Leased"
        : status === "D"
        ? "Deleted"
        : status,
    profitCenter: { id: profitCenterId },
    lotStartDate,
    oldLotEndDate,
  };

  if (id != null) {
    return apiFarms.patch(
      `/bovexo/${groupId}/farms/${farmId}/pickets/${id}`,
      body,
      getHeaders(false, signal)
    );
  } else {
    return apiFarms.post(
      `/bovexo/${groupId}/farms/${farmId}/pickets`,
      body,
      getHeaders(false, signal)
    );
  }
}

export function allocateLotInPicket(payload) {
  const { groupId, farmId, id, lotId, body, signal } = payload;
  return apiFarms.post(
    `/bovexo/${groupId}/farms/${farmId}/pickets/${id}/lots/${lotId}`,
    body,
    getHeaders(false, signal)
  );
}

export function putPicketInMaintenance(payload) {
  const { groupId, farmId, id, statusLimitDate, observation, signal } = payload;

  return apiFarms.patch(
    `/bovexo/${groupId}/farms/${farmId}/pickets/${id}/status/maintenance`,
    {
      status: "Maintenance",
      statusLimitDate,
      observation,
    },
    getHeaders(false, signal)
  );
}

export function putPicketInRenovation(payload) {
  const { groupId, farmId, id, statusLimitDate, observation, signal } = payload;

  return apiFarms.patch(
    `/bovexo/${groupId}/farms/${farmId}/pickets/${id}/status/renovation`,
    {
      status: "Renovation",
      statusLimitDate,
      observation,
    },
    getHeaders(false, signal)
  );
}

export function putPicketInBreak(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiFarms.patch(
    `/bovexo/${groupId}/farms/${farmId}/pickets/${id}/status/break`,
    {
      status: "Break",
      statusLimitDate: null,
    },
    getHeaders(false, signal)
  );
}

export function inactivatePicket(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiFarms.patch(
    `/bovexo/${groupId}/farms/${farmId}/pickets/${id}/status/inactivate`,
    null,
    getHeaders(false, signal)
  );
}

export function reactivatePicket(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiFarms.patch(
    `/bovexo/${groupId}/farms/${farmId}/pickets/${id}/status/reactivate`,
    null,
    getHeaders(false, signal)
  );
}

export function getPicketLotHistories(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/pickets/${id}/lots/histories`,
    getHeaders(false, signal)
  );
}

export function getTheLastPicketLotHistory(payload) {
  const { groupId, farmId, lotId, signal } = payload;
  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/pickets/lots/${lotId}/histories`,
    getHeaders(false, signal)
  );
}

export function getPicketsWithPastureStatus(payload) {
  const { groupId, farmId, startDate, endDate, signal } = payload;

  let param = "";
  if (startDate && endDate) {
    param = `?startDate=${startDate}&endDate=${endDate}`;
  }

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/pickets/with/pasture${param}`,
    getHeaders(false, signal)
  );
}

export function deletePicket(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiFarms.delete(
    `/bovexo/${groupId}/farms/${farmId}/pickets/${id}`,
    getHeaders(false, signal)
  );
}

export function getPicketsAvailableForRetreatDropDown(payload) {
  const { groupId, farmId, retreatId, signal } = payload;

  let param = "";
  if (retreatId !== null && retreatId !== "") {
    param = `?retreatId=${retreatId}`;
  }

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/pickets/available/retreat${param}`,
    getHeaders(false, signal)
  );
}

export function getPicketStatisticsAsync({ payload }) {
  const { groupId, farmId, id, statistics, signal } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/pickets/${id}/statistics/${statistics}`,
    getHeaders(false, signal)
  );
}
