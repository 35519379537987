export const Types = {
  RESET_DATA: "supplement/RESET_DATA",
  GET_ALL_SUPPLEMENT: "supplement/GET_ALL_SUPPLEMENT",
  GET_ALL_SUPPLEMENT_SUCCESS: "supplement/GET_ALL_SUPPLEMENT_SUCCESS",
  GET_ALL_SUPPLEMENT_ERROR: "supplement/GET_ALL_SUPPLEMENT_ERROR",
  GET_SUPPLEMENT: "supplement/GET_SUPPLEMENT",
  GET_SUPPLEMENT_SUCCESS: "supplement/GET_SUPPLEMENT_SUCCESS",
  GET_SUPPLEMENT_ERROR: "supplement/GET_SUPPLEMENT_ERROR",
  SHOW_DRAWER_SUPPLEMENT: "supplement/SHOW_DRAWER_SUPPLEMENT",
  HIDE_DRAWER_SUPPLEMENT: "supplement/HIDE_DRAWER_SUPPLEMENT",
  SAVE_SUPPLEMENT: "supplement/SAVE_SUPPLEMENT",
  SAVE_SUPPLEMENT_SUCCESS: "supplement/SAVE_SUPPLEMENT_SUCCESS",
  SAVE_SUPPLEMENT_ERROR: "supplement/SAVE_SUPPLEMENT_ERROR",
  DELETE_SUPPLEMENT: "supplement/DELETE_SUPPLEMENT",
  DELETE_SUPPLEMENT_SUCCESS: "supplement/DELETE_SUPPLEMENT_SUCCESS",
  DELETE_SUPPLEMENT_ERROR: "supplement/DELETE_SUPPLEMENT_ERROR",
  UPDATE_SUSTENANCE_CAPACITY_DATA: "supplement/UPDATE_SUSTENANCE_CAPACITY_DATA",
  SHOW_HIDE_DRAWER_SUPPLEMENT_DETAILS: "supplement/SHOW_HIDE_DRAWER_SUPPLEMENT_DETAILS",
};

export const Creators = {
  indexSupplement: (groupId, farmId, page = 0, sorter = {}, filters = "", size = 10, isInputSupplier = false) => ({
    type: Types.GET_ALL_SUPPLEMENT,
    payload: {
      groupId,
      farmId,
      page,
      sorter,
      filters,
      size,
      isInputSupplier,
    },
  }),
  indexSupplementSuccess: (data) => ({
    type: Types.GET_ALL_SUPPLEMENT_SUCCESS,
    payload: {
      data,
    },
  }),
  indexSupplementError: (error) => ({
    type: Types.GET_ALL_SUPPLEMENT_ERROR,
    payload: {
      error,
    },
  }),
  showSupplement: (groupId, farmId, id, isInputSupplier = false) => ({
    type: Types.GET_SUPPLEMENT,
    payload: {
      groupId,
      farmId,
      id,
      isInputSupplier,
    },
  }),
  showSupplementSuccess: (data, sustenanceCapacityData) => ({
    type: Types.GET_SUPPLEMENT_SUCCESS,
    payload: {
      data,
      sustenanceCapacityData,
    },
  }),
  showSupplementError: (error) => ({
    type: Types.GET_SUPPLEMENT_ERROR,
    payload: {
      error,
    },
  }),
  showDrawer: (actionType = "edit") => ({
    type: Types.SHOW_DRAWER_SUPPLEMENT,
    payload: {
      actionType,
    },
  }),
  hideDrawer: () => ({
    type: Types.HIDE_DRAWER_SUPPLEMENT,
    payload: {},
  }),
  saveSupplement: (groupId, farmId, id = null, supplement, saveOptions, isInputSupplier = false) => ({
    type: Types.SAVE_SUPPLEMENT,
    payload: {
      groupId,
      farmId,
      id,
      supplement,
      saveOptions,
      isInputSupplier
    },
  }),
  saveSupplementSuccess: (data, sustenanceCapacityData, saveOptions) => ({
    type: Types.SAVE_SUPPLEMENT_SUCCESS,
    payload: {
      data,
      sustenanceCapacityData,
      saveOptions,
    },
  }),
  saveSupplementError: (error) => ({
    type: Types.SAVE_SUPPLEMENT_ERROR,
    payload: { error },
  }),
  updateSupplementSustenanceCapacityData: (sustenanceCapacityData) => ({
    type: Types.UPDATE_SUSTENANCE_CAPACITY_DATA,
    payload: {
      sustenanceCapacityData,
    },
  }),
  showHideSupplementDetails: (id) => ({
    type: Types.SHOW_HIDE_DRAWER_SUPPLEMENT_DETAILS,
    payload: {
      id,
    }
  }),
};

const INITIAL_STATE = {
  isLoading: false,
  isLoadingRequestSaveOrUpdate: false,
  data: [],
  supplementData: null,
  sustenanceCapacityData: null,
  drawerVisible: false,
  drawerDetailsVisible: false,
  idSupplementDetails: null,
  error: {},
};

export default function supplement(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_ALL_SUPPLEMENT:
      return { ...state, isLoading: true };
    case Types.GET_ALL_SUPPLEMENT_SUCCESS:
      const { data: supplements } = action.payload;
      return { ...state, isLoading: false, data: supplements };
    case Types.GET_ALL_SUPPLEMENT_ERROR:
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: null,
        error,
      };
    case Types.GET_SUPPLEMENT:
      return { ...state, isLoadingRequestSaveOrUpdate: true };
    case Types.GET_SUPPLEMENT_SUCCESS:
      return {
        ...state,
        isLoadingRequestSaveOrUpdate: false,
        supplementData: action.payload.data,
        sustenanceCapacityData: action.payload.sustenanceCapacityData,
      };
    case Types.GET_SUPPLEMENT_ERROR:
      return {
        ...state,
        isLoadingRequestSaveOrUpdate: false,
        supplementData: null,
        error,
      };
    case Types.SHOW_DRAWER_SUPPLEMENT:
      return {
        ...state,
        drawerVisible: true,
        supplementData:
          action.payload.actionType === "new" ? null : state.supplementData,
        sustenanceCapacityData:
          action.payload.actionType === "new"
            ? null
            : state.sustenanceCapacityData,
      };
    case Types.HIDE_DRAWER_SUPPLEMENT:
      return {
        ...state,
        drawerVisible: false,
        supplementData: null,
        sustenanceCapacityData: null,
      };
    case Types.SAVE_SUPPLEMENT:
      return { ...state, isLoadingRequestSaveOrUpdate: true };
    case Types.SAVE_SUPPLEMENT_SUCCESS:
      return {
        ...state,
        isLoadingRequestSaveOrUpdate: false,
        supplementData: null,
        drawerVisible: action.payload.saveOptions,
      };
    case Types.SAVE_SUPPLEMENT_ERROR:
      return {
        ...state,
        isLoadingRequestSaveOrUpdate: false,
        error: action.payload.error,
      };
    case Types.DELETE_SUPPLEMENT:
      return { ...state, isLoading: true };
    case Types.DELETE_SUPPLEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case Types.DELETE_SUPPLEMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case Types.UPDATE_SUSTENANCE_CAPACITY_DATA:
      return {
        ...state,
        sustenanceCapacityData: action.payload.sustenanceCapacityData,
      };
    case Types.SHOW_HIDE_DRAWER_SUPPLEMENT_DETAILS:
      return {
        ...state,
        drawerDetailsVisible: !state.drawerDetailsVisible,
        idSupplementDetails: action.payload.id,
      }
    default:
      return state;
  }
}
