import styled from "styled-components";
import { Card, Tabs } from "antd";

export const CardCustom = styled(Card)`
  margin-top: 13px !important;
  border-radius: 7px !important;
  background: #fff !important;
  box-shadow: 0px 0px 10px #d8d8d8 !important;

  padding: 0px !important;
  .ant-card-body {
    padding-top: 14px;
  }
  .rowHeader {
    margin-bottom: 17px;
  }
  .chartMessage {
    font-family: "Asap", sans-serif;
    font-style: italic;
    font-size: 12px;
    color: #4b4b4b;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media screen and (max-height: 700px) {
    overflow-y: auto;
    height: 420px;
  }
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;

export const NewSupplement = styled.button`
  width: 194.2px;
  height: 24px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #684e94;
  color: #684e94;
  text-align: center;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
`;

export const TagStatus = styled.span`
  padding: 2px 5px !important;
  border-radius: 5px !important;
  color: ${(props) => props.color}!important;
  font-size: 12px !important;
  font-weight: bold !important;
  border: ${(props) => `1px solid ${props.borderColor}`};
  background: ${(props) => props.background} !important;
`;

export const PageTitle = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 25px;
  text-align: left;
  color: #9074bf;
`;

export const TabContainer = styled(Tabs)`
  margin-bottom: 10px !important;

  .ant-tabs-ink-bar {
    background-color: #684e94;
    height: 5px;
  }

  .ant-tabs-line {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab {
    font-family: "Asap" !important;
    font-size: 14px !important;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab-disabled,
  .ant-tabs-nav .ant-tabs-tab-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: #4b4b4b;
    font-weight: bold;
  }

  .ant-tabs-bar {
    border-bottom: 1px solid #a9c133;
  }

  .ant-tabs-tabpane {
    background-color: #fff;
    margin-top: -15px;
  }
`;
