import getHeaders from "./getHeaders";
import apiCommons from "../config/api_commons";

export function getTypologyItemIndex(payload) {
  const { groupId, typologyName, signal } = payload;

  return apiCommons.get(
    `/bovexo/${groupId}/typologies?typology_name=${typologyName}`,
    getHeaders(false, signal)
  );
}
