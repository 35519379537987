import styled, { keyframes } from "styled-components";

const scroll = keyframes`
  0% { transform: translateX(460px); }
	100% { transform: translateX(calc(-180px * 9))}
`;

export const Container = styled.div`
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 55px;
  margin: 0 auto;
  overflow: hidden;
  width: ${(props) => (props.isCollapse === true ? "480px" : "370px")};
  /* flex: 2; */
  background: transparent;
  display: flex;

  &::before,
  &::after {
    content: "";
    height: 55px;
    position: absolute;
    width: 10px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  @media screen and (min-width: 1441px) {
    flex: 1;
  }

  @media screen and (max-width: 1024px) {
    width: ${(props) => (props.isCollapse === true ? "160px" : "100px")};
  }

  @media screen and (max-width: 769px) {
    width: 160px;
  }
`;

export const Logo = styled.div`
  padding: 0 8px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .alert-icon {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const SliderTrack = styled.div`
  position: relative;
  -webkit-animation: ${scroll} 30s linear infinite;
  animation: ${scroll} 30s linear infinite;
  display: flex;
  width: calc(180px * 18);
  /* flex: 1; */
  /* width: calc(250px * 14); */

  &:hover {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
  }
`;

export const Slide = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;

  width: 180px;

  font-family: "Asap", sans-serif;

  i {
    font-size: 24px;
  }

  span.code {
    font-size: 14px;
    color: #f3f3f3;
    font-weight: 500;
  }

  span.value {
    font-size: 14px;
  }

  .green {
    color: #33c162;
  }
  .red {
    color: #d44c4c;
  }
`;
