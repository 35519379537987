import React from "react";

const SpinBovexo = () => (
  <svg
    width="200px"
    height="200px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    className="lds-spinner shape-rendering: auto; animation-play-state: running; animation-delay: 0s; background: none;"
  >
    <g
      transform="rotate(0 50 50)"
      className="animation-play-state: running; animation-delay: 0s;"
    >
      <rect
        x="47"
        y="24"
        rx="9.4"
        ry="4.8"
        width="6"
        height="12"
        fill="#A9C133"
        className="animation-play-state: running; animation-delay: 0s;"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.2s"
          begin="-1.0999999999999999s"
          repeatCount="indefinite"
          className="animation-play-state: running; animation-delay: 0s;"
        />
      </rect>
    </g>
    <g
      transform="rotate(30 50 50)"
      className="animation-play-state: running; animation-delay: 0s;"
    >
      <rect
        x="47"
        y="24"
        rx="9.4"
        ry="4.8"
        width="6"
        height="12"
        fill="#A9C133"
        className="animation-play-state: running; animation-delay: 0s;"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.2s"
          begin="-1s"
          repeatCount="indefinite"
          className="animation-play-state: running; animation-delay: 0s;"
        />
      </rect>
    </g>
    <g
      transform="rotate(60 50 50)"
      className="animation-play-state: running; animation-delay: 0s;"
    >
      <rect
        x="47"
        y="24"
        rx="9.4"
        ry="4.8"
        width="6"
        height="12"
        fill="#A9C133"
        className="animation-play-state: running; animation-delay: 0s;"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.2s"
          begin="-0.8999999999999999s"
          repeatCount="indefinite"
          className="animation-play-state: running; animation-delay: 0s;"
        />
      </rect>
    </g>
    <g
      transform="rotate(90 50 50)"
      className="animation-play-state: running; animation-delay: 0s;"
    >
      <rect
        x="47"
        y="24"
        rx="9.4"
        ry="4.8"
        width="6"
        height="12"
        fill="#A9C133"
        className="animation-play-state: running; animation-delay: 0s;"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.2s"
          begin="-0.7999999999999999s"
          repeatCount="indefinite"
          className="animation-play-state: running; animation-delay: 0s;"
        />
      </rect>
    </g>
    <g
      transform="rotate(120 50 50)"
      className="animation-play-state: running; animation-delay: 0s;"
    >
      <rect
        x="47"
        y="24"
        rx="9.4"
        ry="4.8"
        width="6"
        height="12"
        fill="#A9C133"
        className="animation-play-state: running; animation-delay: 0s;"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.2s"
          begin="-0.7000000000000001s"
          repeatCount="indefinite"
          className="animation-play-state: running; animation-delay: 0s;"
        />
      </rect>
    </g>
    <g
      transform="rotate(150 50 50)"
      className="animation-play-state: running; animation-delay: 0s;"
    >
      <rect
        x="47"
        y="24"
        rx="9.4"
        ry="4.8"
        width="6"
        height="12"
        fill="#A9C133"
        className="animation-play-state: running; animation-delay: 0s;"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.2s"
          begin="-0.6s"
          repeatCount="indefinite"
          className="animation-play-state: running; animation-delay: 0s;"
        />
      </rect>
    </g>
    <g
      transform="rotate(180 50 50)"
      className="animation-play-state: running; animation-delay: 0s;"
    >
      <rect
        x="47"
        y="24"
        rx="9.4"
        ry="4.8"
        width="6"
        height="12"
        fill="#A9C133"
        className="animation-play-state: running; animation-delay: 0s;"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.2s"
          begin="-0.5s"
          repeatCount="indefinite"
          className="animation-play-state: running; animation-delay: 0s;"
        />
      </rect>
    </g>
    <g
      transform="rotate(210 50 50)"
      className="animation-play-state: running; animation-delay: 0s;"
    >
      <rect
        x="47"
        y="24"
        rx="9.4"
        ry="4.8"
        width="6"
        height="12"
        fill="#A9C133"
        className="animation-play-state: running; animation-delay: 0s;"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.2s"
          begin="-0.39999999999999997s"
          repeatCount="indefinite"
          className="animation-play-state: running; animation-delay: 0s;"
        />
      </rect>
    </g>
    <g
      transform="rotate(240 50 50)"
      className="animation-play-state: running; animation-delay: 0s;"
    >
      <rect
        x="47"
        y="24"
        rx="9.4"
        ry="4.8"
        width="6"
        height="12"
        fill="#A9C133"
        className="animation-play-state: running; animation-delay: 0s;"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.2s"
          begin="-0.3s"
          repeatCount="indefinite"
          className="animation-play-state: running; animation-delay: 0s;"
        />
      </rect>
    </g>
    <g
      transform="rotate(270 50 50)"
      className="animation-play-state: running; animation-delay: 0s;"
    >
      <rect
        x="47"
        y="24"
        rx="9.4"
        ry="4.8"
        width="6"
        height="12"
        fill="#A9C133"
        className="animation-play-state: running; animation-delay: 0s;"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.2s"
          begin="-0.19999999999999998s"
          repeatCount="indefinite"
          className="animation-play-state: running; animation-delay: 0s;"
        />
      </rect>
    </g>
    <g
      transform="rotate(300 50 50)"
      className="animation-play-state: running; animation-delay: 0s;"
    >
      <rect
        x="47"
        y="24"
        rx="9.4"
        ry="4.8"
        width="6"
        height="12"
        fill="#A9C133"
        className="animation-play-state: running; animation-delay: 0s;"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.2s"
          begin="-0.09999999999999999s"
          repeatCount="indefinite"
          className="animation-play-state: running; animation-delay: 0s;"
        />
      </rect>
    </g>
    <g
      transform="rotate(330 50 50)"
      className="animation-play-state: running; animation-delay: 0s;"
    >
      <rect
        x="47"
        y="24"
        rx="9.4"
        ry="4.8"
        width="6"
        height="12"
        fill="#A9C133"
        className="animation-play-state: running; animation-delay: 0s;"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.2s"
          begin="0s"
          repeatCount="indefinite"
          className="animation-play-state: running; animation-delay: 0s;"
        />
      </rect>
    </g>
  </svg>
);

export default SpinBovexo;
