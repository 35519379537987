import React from "react";
import { LinkCustom } from "./styles";

function CustomLink({ to, children }) {
  return (
    <LinkCustom target="_blank" rel="noopener noreferer" to={to}>
      {children}
    </LinkCustom>
  );
}

export default CustomLink;
