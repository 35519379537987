import { call, put, select } from "redux-saga/effects";
import { notification } from "antd";
import apiIam from "../../config/api_iam";
import { Creators as UserActions } from "../ducks/user";
import { Creators as AppActions } from "../ducks/app";
import { Creators as AccountFarmActions } from "../ducks/accountFarm";

import {
  indexUserGroupsAndFarms,
  getAllRolesByUserIdAndGroupIdAndFarmId,
} from "../../services/userService";
import {
  getHomeConfig,
  saveHomeConfig,
} from "../../services/homeConfigService";

export const getAppState = (state) => state.app;

export function* storeUser(action) {
  try {
    const { data } = yield call(apiIam.post, "/users/", action.payload.form);
    notification.success({ message: "Usuário criado !" });
    yield put(UserActions.store_user_success(data));
  } catch (error) {
    notification.error({ message: "Error ao criar usuário !" });
    yield put(UserActions.store_user_error(error));
  }
}

export function* indexUser(action) {
  try {
    const { data } = yield call(apiIam.get, "/users/");
    yield put(UserActions.get_user_success(data));
  } catch (error) {
    notification.error({ message: "Error ao pegar usuários !" });
    yield put(UserActions.get_user_error(error));
  }
}

export function* indexUserGroupsAndFarmsDescriptions(action) {
  try {
    let result = {};
    const {
      data: { results: userGroupsAndFarms },
    } = yield call(indexUserGroupsAndFarms, action.payload);

    result = {
      groups: userGroupsAndFarms,
    };

    yield put(UserActions.indexUserGroupsAndFarmsDescriptionsSuccess(result));

    let app = yield select(getAppState);
    let isInputSupplier = app?.user?.isInputSupplier;
    
    if (
      Object.entries(app.groupSelected).length === 0 &&
      Object.entries(app.farmSelected).length === 0
    ) {
      if (
        userGroupsAndFarms.length === 1 &&
        userGroupsAndFarms[0].farms.length === 1
      ) {
        yield put(AppActions.setGroupSelected(userGroupsAndFarms[0]));
        yield put(AppActions.setFarmSelected(userGroupsAndFarms[0].farms[0]));
        yield put(
          AppActions.getAllRoles(
            action.payload.userId,
            userGroupsAndFarms[0].id,
            userGroupsAndFarms[0].farms[0].id,
            isInputSupplier
          )
        );
        yield put(
          AppActions.hideModalGroupAndFarm(
            userGroupsAndFarms[0].id,
            userGroupsAndFarms[0].farms[0].id
          )
        );
        yield put(
          AccountFarmActions.checkAccountFarm(
            userGroupsAndFarms[0].id,
            userGroupsAndFarms[0].farms[0].id
          )
        );
      } else {
        yield put(AppActions.showModalGroupAndFarm());
      }
    } else if (
      Object.entries(app.groupSelected).length > 0 &&
      userGroupsAndFarms.find((gp) => gp.id === app.groupSelected.id) != null
    ) {
      if (
        app.groupSelected.farms.length !==
        userGroupsAndFarms.find((gp) => gp.id === app.groupSelected.id).farms
          .length
      ) {
        yield put(
          AppActions.updateFarmsInGroupSelected(
            userGroupsAndFarms.find((gp) => gp.id === app.groupSelected.id)
              .farms
          )
        );
      }
    }
  } catch (error) {
    notification.error({
      message: "Error ao pegar descrições dos grupos e fazendas do usuário!",
    });
    yield put(UserActions.indexUserGroupsAndFarmsDescriptionsError(error));
  }
}

export function* getAllRoles(action) {
  try {
    const {
      data: { results: res },
    } = yield call(getAllRolesByUserIdAndGroupIdAndFarmId, action.payload);
    const roles = res.map((role) => role.name);
    if (roles != null && roles.length === 1 && roles.includes("Trial")) {
      yield put(AppActions.showOrHideModalWelcomeTrial());
    }
    yield put(AppActions.getAllRolesSuccess(roles));
  } catch (error) {
    console.error(error);
  }
}

export function* getUserHomeConfig(action) {
  try {
    const {
      data: { results },
    } = yield call(getHomeConfig, action.payload);
    yield put(UserActions.getHomeConfigSuccess(results));
  } catch (error) {
    notification.error({
      message: "Error ao pegar dados de configuração da home !",
    });
    yield put(UserActions.getHomeConfigError(error));
  }
}

export function* saveUserHomeConfig(action) {
  try {
    const {
      data: { results },
    } = yield call(saveHomeConfig, action.payload);
    notification.success({
      message: "Configurações salvas !",
      description: "As configurações da tela home foram salvas",
    });
    yield put(UserActions.saveHomeConfigSuccess(results));
  } catch (error) {
    notification.error({
      message: "Error ao salvar às configurações !",
      description:
        "Houve um erro ao tentar salvar às configurações. Contate o suporte.",
    });
    yield put(UserActions.saveHomeConfigError(error));
  }
}
