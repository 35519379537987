export const Types = {
  RESET_DATA: "client/RESET_DATA",
  REFRESH_TABLE: "client/REFRESH_TABLE",
  SHOW_DRAWER_CLIENT: "client/SHOW_DRAWER_CLIENT",
  HIDE_DRAWER_CLIENT: "client/HIDE_DRAWER_CLIENT",
  SAVE_CLIENT: "clietn/SAVE_CLIENT",
  SAVE_CLIENT_SUCCESS: "clietn/SAVE_CLIENT_SUCCESS",
  SAVE_CLIENT_ERROR: "clietn/SAVE_CLIENT_ERROR",
  GET_DROPDOWN_CLIENTS: "clietn/GET_DROPDOWN_CLIENTS",
  GET_DROPDOWN_CLIENTS_SUCCESS: "clietn/GET_DROPDOWN_CLIENTS_SUCCESS",
  GET_DROPDOWN_CLIENTS_ERROR: "clietn/GET_DROPDOWN_CLIENTS_ERROR",
};

export const Creators = {
  refreshTable: (refresh) => ({
    type: Types.REFRESH_TABLE,
    payload: {
      refresh,
    },
  }),
  showDrawerClientVisible: (type, id, updateDropdown = false) => ({
    type: Types.SHOW_DRAWER_CLIENT,
    payload: { type, id, updateDropdown },
  }),
  hideDrawerClientVisible: () => ({
    type: Types.HIDE_DRAWER_CLIENT,
    payload: {},
  }),
  saveClient: (groupId, farmId, form, id = null) => ({
    type: Types.SAVE_CLIENT,
    payload: {
      groupId,
      farmId,
      form,
      id,
    },
  }),
  saveClientSuccess: (data) => ({
    type: Types.SAVE_CLIENT_SUCCESS,
    payload: { data },
  }),
  saveClientError: (error) => ({
    type: Types.SAVE_CLIENT_ERROR,
    payload: { error },
  }),

  getDropdownClients: (
    groupId,
    farmId,
    onlyActives,
  ) => ({
    type: Types.GET_DROPDOWN_CLIENTS,
    payload: {
      groupId,
      farmId,
      onlyActives,
    },
  }),

  getDropdownClientsSuccess: (data) => ({
    type: Types.GET_DROPDOWN_CLIENTS_SUCCESS,
    payload: {
      data,
    },
  }),

  getDropdownClientsError: (error) => ({
    type: Types.GET_DROPDOWN_CLIENTS_ERROR,
    payload: {
      error,
    },
  }),
};

const INITIAL_STATE = {
  refreshTable: false,
  idClientEditOrDetails: null,
  drawerClientVisible: false,
  drawerClientDetailsVisible: false,
  isLoadingRequest: false,
  error: null,
  listDropdown: [],
  isLoadingDropdown: false,
  updateDropdown: false,
};

export default function client(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.REFRESH_TABLE:
      return { ...state, refreshTable: action.payload.refresh };
    case Types.SHOW_DRAWER_CLIENT:
      if (action.payload.type === "new") {
        return {
          ...state,
          drawerClientVisible: true,
          idClientEditOrDetails: null,
          updateDropdown: action.payload.updateDropdown
        };
      } else if (action.payload.type === "edit") {
        return {
          ...state,
          drawerClientVisible: true,
          idClientEditOrDetails: action.payload.id,
        };
      } else if (action.payload.type === "details") {
        return {
          ...state,
          drawerClientDetailsVisible: true,
          idClientEditOrDetails: action.payload.id,
        };
      } else {
        return {
          ...state,
        };
      }
    case Types.HIDE_DRAWER_CLIENT:
      return {
        ...state,
        drawerClientVisible: false,
        drawerClientDetailsVisible: false,
        idClientEditOrDetails: null,
      };
    case Types.SAVE_CLIENT:
      return { ...state, isLoadingRequest: true };
    case Types.SAVE_CLIENT_SUCCESS:
      return {
        ...state,
        isLoadingRequest: false,
        drawerClientVisible: false,
      };
    case Types.SAVE_CLIENT_ERROR:
      return {
        ...state,
        error: action.payload.error,
        isLoadingRequest: false,
      };
    case Types.GET_DROPDOWN_CLIENTS:
      return { ...state, isLoadingDropdown: true };
    case Types.GET_DROPDOWN_CLIENTS_SUCCESS:
      return {
        ...state,
        isLoadingDropdown: false,
        listDropdown: action.payload.data,
      };
    case Types.GET_DROPDOWN_CLIENTS_ERROR:
      return {
        ...state,
        isLoadingDropdown: false,
        listDropdown: [],
        error: action.payload.error,
      };
    default:
      return state;
  }
}
