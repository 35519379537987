import React from 'react';

// import { Container } from './styles';

const PlusAddMenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.796"
    height="20.796"
    viewBox="0 0 20.796 20.796"
  >
    <g id="icon-add" transform="translate(-4.639 -4.864)">
      <path
        id="Path_705"
        data-name="Path 705"
        d="M0,0V18.8"
        transform="translate(15.037 5.864)"
        fill="none"
        stroke="#c4c4c4"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        id="Path_706"
        data-name="Path 706"
        d="M18.8,0H0"
        transform="translate(5.639 15.037)"
        fill="none"
        stroke="#c4c4c4"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);
export default PlusAddMenuIcon;
