import React from 'react';

const MessageIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19.796" height="19.795" viewBox="0 0 15.796 15.795">
    <g id="Grupo_4157" data-name="Grupo 4157" transform="translate(-353.621 -146.256)">
      <path id="Caminho_2079" data-name="Caminho 2079" d="M162.51,153.668a6.5,6.5,0,0,0-.584-9.088,7.428,7.428,0,0,1-7.136,9.453l-1.773-.011a6.471,6.471,0,0,0,4.581,1.892l6.033-.031a.463.463,0,0,0,.323-.792Z" transform="translate(205.326 6.137)" fill="#fff"/>
      <path id="Caminho_2080" data-name="Caminho 2080" d="M6.494,12.988a6.493,6.493,0,1,0-4.913-2.247L.138,12.165a.463.463,0,0,0,.323.792ZM3.733,4.2H9.286a.463.463,0,0,1,0,.926H3.733a.463.463,0,0,1,0-.926Zm0,1.851H9.286a.463.463,0,0,1,0,.926H3.733a.463.463,0,0,1,0-.926ZM3.27,8.36A.463.463,0,0,1,3.733,7.9H9.286a.463.463,0,0,1,0,.926H3.733A.463.463,0,0,1,3.27,8.36Z" transform="translate(353.621 146.256)" fill="#fff"/>
    </g>
  </svg>
);

export default MessageIcon;
