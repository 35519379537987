import { call, put } from "redux-saga/effects";
import { Creators as ResultCenterActions } from "../ducks/resultCenter";

import { notification } from "antd";

/** Services */
import {
  indexAll,
  Inactivate,
  Activate,
} from "../../services/resultCenterService";

export function* indexAllResultCenters(action) {
  try {
    const {
      data: { results: resultCenterList },
    } = yield call(indexAll, action.payload);
    yield put(ResultCenterActions.indexSuccess(resultCenterList))
  } catch (error) {
    yield put(ResultCenterActions.indexError(error));
  }
}

export function* inactivateResultCenter(action) {
  try {
    const {
      data: { results: resultCenter },
    } = yield call(Inactivate, action.payload);
  } catch (error) {
    notification.error({
      message: `Centro de Resultado não foi inativado. Contate o suporte`,
    });
  }

  yield put(ResultCenterActions.index(action.payload.groupId, action.payload.farmId));
}

export function* activateResultCenter(action) {
  try {
    const {
      data: { results: resultCenter },
    } = yield call(Activate, action.payload);
  } catch (error) {
    notification.error({
      message: `Centro de Resultado não foi ativado. Contate o suporte`,
    });
  }

  yield put(ResultCenterActions.index(action.payload.groupId, action.payload.farmId));
}