import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Creators as CurveActions } from "../../../../../store/ducks/curve";

import { Col, Row, Table } from "antd";
import { ButtonCurveParameter, TabAnimalPaneContent } from "../../styles";

function TabAnimalBreedCurveInfo({ tabAnimalPane }) {
  const {
    app: { translation },
    curve: { animalCurveData, lotCurveData },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  /** Variable props */

  function handleShowAnimalCurveDrawer() {
    dispatch(CurveActions.showOrHideAnimalCurveDrawer());
  }

  return (
    <TabAnimalPaneContent active={tabAnimalPane === 5 ? true : false}>
      <Row type="flex">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="cardBovexo">
          {/* Title */}
          <Row type="flex">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <span className="span16GrayRegular">
                Curva estimada de crescimento
              </span>
            </Col>
          </Row>
          {/* Message with the animal have only lot curve */}
          {(animalCurveData == null ||
            animalCurveData.breedCurveParam == null) && (
            <Row type="flex" style={{ marginTop: 5 }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <span className="span12Orange">
                  * Baseado nos dados do lote
                </span>
              </Col>
            </Row>
          )}
          {animalCurveData != null &&
          animalCurveData.breedCurveParam != null ? (
            <>
              <Row type="flex" style={{ marginTop: 13 }}>
                <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                  <Row>
                    <span className="span14Bold">
                      {`${translation.breed.formParameters.theoricalCurve}`}
                    </span>
                  </Row>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} xl={8} align="right">
                  <span className="span16Regular">
                    {animalCurveData != null &&
                    animalCurveData.breedCurveParam != null
                      ? animalCurveData.breedCurveParam.theoreticalCurveType
                      : ""}
                  </span>
                </Col>
              </Row>
              <Row type="flex" style={{ marginTop: 13 }}>
                <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                  <Row>
                    <span className="span14Bold">Genética</span>
                  </Row>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} xl={8} align="right">
                  <span className="span16Regular">
                    {animalCurveData != null
                      ? animalCurveData.breedCurveParam != null
                        ? animalCurveData.breedCurveParam.geneticCategory ===
                          "GT"
                          ? "Tauríno"
                          : animalCurveData.breedCurveParam.geneticCategory ===
                            "GZ"
                          ? "Zebuíno"
                          : animalCurveData.breedCurveParam.geneticCategory ===
                              "GC" && "Cruzado"
                        : ""
                      : ""}
                  </span>
                </Col>
              </Row>
              <Row type="flex" style={{ marginTop: 13 }}>
                <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                  <Row>
                    <span className="span14Bold">Dieta</span>
                  </Row>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                  <span className="span16Regular">
                    {animalCurveData != null
                      ? animalCurveData.breedCurveParam != null
                        ? animalCurveData.breedCurveParam.handlingCategory ===
                          "DP"
                          ? "Premium"
                          : animalCurveData.breedCurveParam.handlingCategory ===
                            "DS"
                          ? "Superior"
                          : animalCurveData.breedCurveParam.handlingCategory ===
                              "DC" && "Comum"
                        : ""
                      : ""}
                  </span>
                </Col>
              </Row>
            </>
          ) : (
            lotCurveData != null &&
            lotCurveData.breedCurveParam != null && (
              <>
                <Row type="flex" style={{ marginTop: 13 }}>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                    <Row>
                      <span className="span14Bold">
                        {`${translation.breed.formParameters.theoricalCurve}`}
                      </span>
                    </Row>
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} align="right">
                    <span className="span16Regular">
                      {lotCurveData != null &&
                      lotCurveData.breedCurveParam != null
                        ? lotCurveData.breedCurveParam.theoreticalCurveType
                        : ""}
                    </span>
                  </Col>
                </Row>
                <Row type="flex" style={{ marginTop: 13 }}>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                    <Row>
                      <span className="span14Bold">Genética</span>
                    </Row>
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                    <span className="span16Regular">
                      {lotCurveData != null
                        ? lotCurveData.breedCurveParam != null
                          ? lotCurveData.breedCurveParam.geneticCategory ===
                            "GT"
                            ? "Tauríno"
                            : lotCurveData.breedCurveParam.geneticCategory ===
                              "GZ"
                            ? "Zebuíno"
                            : lotCurveData.breedCurveParam.geneticCategory ===
                                "GC" && "Cruzado"
                          : ""
                        : ""}
                    </span>
                  </Col>
                </Row>
                <Row type="flex" style={{ marginTop: 13 }}>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                    <Row>
                      <span className="span14Bold">Dieta</span>
                    </Row>
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                    <span className="span16Regular">
                      {lotCurveData != null
                        ? lotCurveData.breedCurveParam != null
                          ? lotCurveData.breedCurveParam.handlingCategory ===
                            "DP"
                            ? "Premium"
                            : lotCurveData.breedCurveParam.handlingCategory ===
                              "DS"
                            ? "Superior"
                            : lotCurveData.breedCurveParam.handlingCategory ===
                                "DC" && "Comum"
                          : ""
                        : ""}
                    </span>
                  </Col>
                </Row>
              </>
            )
          )}
          {/* Button */}
          <Row type="flex" style={{ marginTop: 23 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} align="right">
              <ButtonCurveParameter
                type="button"
                onClick={handleShowAnimalCurveDrawer}
              >
                Estimar para animal
              </ButtonCurveParameter>
            </Col>
          </Row>
        </Col>
      </Row>
    </TabAnimalPaneContent>
  );
}

export default TabAnimalBreedCurveInfo;
