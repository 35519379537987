import apiFarm from "../config/api_farms";
import getHeaders from "./getHeaders";

export function showKPIs(payload) {
  const { signal } = payload;

  return apiFarm.get(`/bovexo/kpis`, getHeaders(false, signal));
}

export function showKPIsHarvests(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFarm.get(
    `/bovexo/${groupId}/farms/${farmId}/harvests`,
    getHeaders(false, signal)
  );
}

export function showKPIsFarmsForHome(payload) {
  const { groupId, farmId, harvestId, signal } = payload;

  return apiFarm.get(
    `/bovexo/${groupId}/farms/${farmId}/harvests/${harvestId}/kpis/results`,
    getHeaders(false, signal)
  );
}

export function loadKPIGoalResult(payload) {
  const { groupId, farmId, harvestId, kpiGoalId, kpiId, signal } = payload;
  return apiFarm.get(
    `/bovexo/${groupId}/farms/${farmId}/harvests/${harvestId}/kpis/${kpiId}/goal/${kpiGoalId}/results`,
    getHeaders(false, signal)
  );
}

export function showKPIGoalForParameters(payload) {
  const { groupId, farmId, harvestId, signal } = payload;

  return apiFarm.get(
    `/bovexo/${groupId}/farms/${farmId}/harvests/${harvestId}/kpis/goals`,
    getHeaders(false, signal)
  );
}

export function saveKPIGoalForParameters(payload) {
  const { groupId, farmId, harvestId, data, signal } = payload;

  return apiFarm.post(
    `/bovexo/${groupId}/farms/${farmId}/harvests/${harvestId}/kpis/goals`,
    data,
    getHeaders(false, signal)
  );
}

export function showReasonsByKpi(payload) {
  const { groupId, farmId, kpiId, signal } = payload;

  return apiFarm.get(
    `/bovexo/${groupId}/farms/${farmId}/kpis/${kpiId}/reasons`,
    getHeaders(false, signal)
  );
}

export function reprocessKpi(payload) {
  const {
    groupId,
    farmId,
    selectedProductionSystem,
    selectedProductionSubSystem,
    signal,
  } = payload;
  const productionSystem = selectedProductionSystem;
  const productionSubSystem = selectedProductionSubSystem || "Extensive";
  return apiFarm.post(
    `/bovexo/${groupId}/farms/${farmId}/kpis/process?productionSystem=${productionSystem}&productionSubSystem=${productionSubSystem}`,
    null,
    getHeaders(false, signal)
  );
}
