import React from "react";

// import { Container } from './styles';

const ArrowDownIcon = () => (
  <svg
    id="bovexoArrowDownIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="9.265"
    height="9.265"
    viewBox="0 0 9.265 9.265"
  >
    <path
      id="Path_844"
      data-name="Path 844"
      d="M0,0H6.551V6.551Z"
      transform="translate(9.265 4.633) rotate(135)"
      fill="#684e94"
    />
  </svg>
);
export default ArrowDownIcon;
