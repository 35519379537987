export const Types = {
  RESET_DATA: "generalParameters/RESET_DATA",
  CHANGE_PARAMETER_BEING_EDITED:
    "generalParameters/CHANGE_PARAMETER_BEING_EDITED",
  LIST_WEATHER_SEASON: "generalParameters/LIST_WEATHER_SEASON",
  LIST_WEATHER_SEASON_SUCCESS: "generalParameters/LIST_WEATHER_SEASON_SUCCESS",
  LIST_WEATHER_SEASON_ERROR: "generalParameters/LIST_WEATHER_SEASON_ERROR",
};

export const Creators = {
  changeParameterBeingEdited: (parameter) => ({
    type: Types.CHANGE_PARAMETER_BEING_EDITED,
    payload: {
      parameter,
    },
  }),
  listWeatherSeasons: (farmId) => ({
    type: Types.LIST_WEATHER_SEASON,
    payload: {
      farmId,
    },
  }),
  listWeatherSeasonsSuccess: (data) => ({
    type: Types.LIST_WEATHER_SEASON_SUCCESS,
    payload: {
      data,
    },
  }),
  listWeatherSeasonsError: (error) => ({
    type: Types.LIST_WEATHER_SEASON_ERROR,
    payload: {
      error,
    },
  }),
};

const INITIAL_STATE = {
  parameterBeingEdit: null,
  loadingListWeatherSeason: false,
  listWeatherSeasons: [],
};

export default function generalParameters(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.CHANGE_PARAMETER_BEING_EDITED:
      return { ...state, parameterBeingEdit: action.payload.parameter };
    case Types.LIST_WEATHER_SEASON:
      return { ...state, loadingListWeatherSeason: true };
    case Types.LIST_WEATHER_SEASON_SUCCESS:
      return {
        ...state,
        loadingListWeatherSeason: false,
        listWeatherSeasons: action.payload.data,
      };
    case Types.LIST_WEATHER_SEASON_ERROR:
      return {
        ...state,
        loadingListWeatherSeason: false,
        listWeatherSeasons: [],
      };
    default:
      return state;
  }
}
