import React, { Fragment, useEffect, useState } from "react";
import CanvasJSReact from "../../../../assets/canvasjs.react";
import { getTwoDecimalDigits } from "../../../../services/helpersMethodsService";

const { CanvasJSChart } = CanvasJSReact;

const LotCostPieChart = ({
  lotCost: { purchaseCost, dietCost, fixedVariableCost },
}) => {
  const [nutritionPercentage, setNutritionPercentage] = useState(0);
  const [admPercentage, setAdmPercentage] = useState(0);
  const [purchasePercentage, setPurchasePercentage] = useState(0);

  useEffect(() => {
    let totalCost = purchaseCost + dietCost + fixedVariableCost;

    if (purchaseCost !== null && purchaseCost > 0) {
      setPurchasePercentage(
        getTwoDecimalDigits((purchaseCost / totalCost) * 100)
      );
    } else {
      setPurchasePercentage(0);
    }

    if (dietCost !== null && dietCost > 0) {
      setNutritionPercentage(getTwoDecimalDigits((dietCost / totalCost) * 100));
    } else {
      setNutritionPercentage(0);
    }

    if (fixedVariableCost !== null && fixedVariableCost > 0) {
      setAdmPercentage(
        getTwoDecimalDigits((fixedVariableCost / totalCost) * 100)
      );
    } else {
      setAdmPercentage(0);
    }
  }, [purchaseCost, dietCost, fixedVariableCost]);

  const options = {
    height: 120,
    dataPointWidth: 36,
    toolTip: {
      enabled: true,
    },
    legend: {
      verticalAlign: "bottom",
      horizontalAlign: "center",
      reversed: true,
    },
    data: [
      {
        type: "pie",
        showInLegend: true,
        legendText: "{label}",
        toolTipContent: "{label}: <strong>{y}%</strong>",
        indexLabel: " ",
        indexLabelPlacement: "inside",
        dataPoints: [
          { y: nutritionPercentage, label: "Nutrição" },
          { y: admPercentage, label: "Adm/Operacionais" },
          { y: purchasePercentage, label: "Aquisição" },
        ],
      },
    ],
  };

  return (
    <Fragment>
      <div className="chartDiv">
        <CanvasJSChart options={options} />
      </div>
    </Fragment>
  );
};

export default LotCostPieChart;
