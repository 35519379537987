import { call, put } from "redux-saga/effects";
import { Creators as ReproductionPeriodActions } from "../ducks/reproductionPeriod";

/** Services */
import { findAll } from "../../services/reproductionPeriodService";

export function* indexReproductionPeriods(action) {
  try {
    const {
      data: { results },
    } = yield call(
      findAll,
      action.payload
    );
    yield put(ReproductionPeriodActions.getAllDataSuccess(results));
  } catch (error) {
    yield put(ReproductionPeriodActions.getAllDataError(error));
  }
}