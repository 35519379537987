import styled from "styled-components";

export const Circle = styled.div`
  width: 30px;
  height: 30px;

  border-radius: 50px;

  &.green {
    background-color: #83d18e;
  }

  &.red {
    background-color: #ff6c71;
  }

  &.yellow {
    background-color: #fbe870;
  }
`;
