import styled from "styled-components";

export const ModalBody = styled.div`
  .ant-row {
    & > label {
      font-family: "Asap", sans-serif;
      color: #9074bf;
      font-size: 14px;
      font-weight: bold;

      #bovexoInfoIcon {
        margin-left: 3px;
      }

      &.disabled {
        font-weight: bold;
        font-size: 14px;
        color: #c4c4c4;
      }
    }
    & + .ant-row {
      margin-top: 8px;
    }
  }

  .error {
    color: #d44c4c !important;
  }
`;

export const Table = styled.div`
  padding: 8px;

  .selectCustom {
    width: 100%;

    .ant-select-selection {
      border: none;
      border-bottom: 1px solid #fff;
      background: transparent;
      color: #fff;
      border-radius: 0px !important;
    }

    .ant-select-arrow {
      color: #fff;
    }
  }

  .col {
    margin: 4px 0px;
    &.value {
      color: #fff;
      text-align: center;

      & > * {
        border-radius: 5px;
      }

      button {
        width: 100%;
        height: 100%;
        background-color: #684e94;
        color: #fff;
        font-weight: bold;
        text-align: center;
        border: none;
        cursor: pointer;
      }

      strong {
        display: block;
        width: 100%;
        height: 100%;
        background-color: #684e94;
      }

      &.header {
        strong {
          display: block;
          width: 100%;
          height: 100%;
          background-color: #fff;
          color: #4b4b4b;
        }
      }
    }
  }
`;
