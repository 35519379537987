export const Types = {
  RESET_DATA: "boitelSaleScenario/RESET_DATA",
  CLEAR_FIELDS: "boitelSaleScenario/CLEAR_FIELDS",
  RESET_REDIRECT_TO_LIST_PAGE: "boitelSaleScenario/RESET_REDIRECT_TO_LIST_PAGE",
  UPDATE_STATUS_BOITEL_SALE_SCENARIO_LIST_DATA_ITEM:
    "boitelSaleScenario/UPDATE_STATUS_BOITEL_SALE_SCENARIO_LIST_DATA_ITEM",
  GET_BOITEL_SALE_SCENARIO_LIST_DATA:
    "boitelSaleScenario/GET_BOITEL_SALE_SCENARIO_LIST_DATA",
  GET_BOITEL_SALE_SCENARIO_LIST_DATA_SUCCESS:
    "boitelSaleScenario/GET_BOITEL_SALE_SCENARIO_LIST_DATA_SUCCESS",
  GET_BOITEL_SALE_SCENARIO_LIST_DATA_ERROR:
    "boitelSaleScenario/GET_BOITEL_SALE_SCENARIO_LIST_DATA_ERROR",
  GET_BOITEL_SALE_SCENARIO_DATA:
    "boitelSaleScenario/GET_BOITEL_SALE_SCENARIO_DATA",
  GET_BOITEL_SALE_SCENARIO_DATA_SUCCESS:
    "boitelSaleScenario/GET_BOITEL_SALE_SCENARIO_DATA_SUCCESS",
  GET_BOITEL_SALE_SCENARIO_DATA_ERROR:
    "boitelSaleScenario/GET_BOITEL_SALE_SCENARIO_DATA_ERROR",
  STORE_UPDATE_BOITEL_SALE_SCENARIO:
    "boitelSaleScenario/STORE_UPDATE_BOITEL_SALE_SCENARIO",
  STORE_UPDATE_BOITEL_SALE_SCENARIO_SUCCESS:
    "boitelSaleScenario/STORE_UPDATE_BOITEL_SALE_SCENARIO_SUCCESS",
  STORE_UPDATE_BOITEL_SALE_SCENARIO_ERROR:
    "boitelSaleScenario/STORE_UPDATE_BOITEL_SALE_SCENARIO_ERROR",
};

export const Creators = {
  resetData: () => ({
    type: Types.RESET_DATA,
    payload: {},
  }),
  clearFields: (resetBoitelSaleScenarioData = false) => ({
    type: Types.CLEAR_FIELDS,
    payload: { resetBoitelSaleScenarioData },
  }),
  resetRedirectToListPage: () => ({
    type: Types.RESET_REDIRECT_TO_LIST_PAGE,
    payload: {},
  }),
  updateStatusBoitelSaleScenarioListDataItem: (id, newStatus) => ({
    type: Types.UPDATE_STATUS_BOITEL_SALE_SCENARIO_LIST_DATA_ITEM,
    payload: {
      id,
      newStatus,
    },
  }),
  getBoitelSaleScenarioListData: (
    groupId,
    farmId,
    page = 0,
    sorter = null,
    filters = null,
    size = 10,
    ids = null,
    tableSorters = null,
    tableFilters = null
  ) => ({
    type: Types.GET_BOITEL_SALE_SCENARIO_LIST_DATA,
    payload: {
      groupId,
      farmId,
      page,
      sorter,
      filters,
      size,
      ids,
      tableSorters,
      tableFilters,
    },
  }),
  getBoitelSaleScenarioListDataSuccess: (data) => ({
    type: Types.GET_BOITEL_SALE_SCENARIO_LIST_DATA_SUCCESS,
    payload: { data },
  }),
  getBoitelSaleScenarioListDataError: (error) => ({
    type: Types.GET_BOITEL_SALE_SCENARIO_LIST_DATA_ERROR,
    payload: { error },
  }),
  getBoitelSaleScenarioData: (groupId, farmId, id) => ({
    type: Types.GET_BOITEL_SALE_SCENARIO_DATA,
    payload: { groupId, farmId, id },
  }),
  getBoitelSaleScenarioDataSuccess: (data) => ({
    type: Types.GET_BOITEL_SALE_SCENARIO_DATA_SUCCESS,
    payload: { data },
  }),
  getBoitelSaleScenarioDataError: (error) => ({
    type: Types.GET_BOITEL_SALE_SCENARIO_DATA_ERROR,
    payload: { error },
  }),
  storeUpdateBoitelSaleScenario: (groupId, farmId, id, body) => ({
    type: Types.STORE_UPDATE_BOITEL_SALE_SCENARIO,
    payload: { groupId, farmId, id, body },
  }),
  storeUpdateBoitelSaleScenarioSuccess: (data) => ({
    type: Types.STORE_UPDATE_BOITEL_SALE_SCENARIO_SUCCESS,
    payload: { data },
  }),
  storeUpdateBoitelSaleScenarioError: (error) => ({
    type: Types.STORE_UPDATE_BOITEL_SALE_SCENARIO_ERROR,
    payload: { error },
  }),
};

const INITIAL_STATE = {
  refreshTable: false,
  redirectToListPage: false,
  isLoadingListBoitelSaleScenario: false,
  listBoitelSaleScenarioData: {
    content: [],
    empty: true,
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    pageable: {
      paged: true,
      sort: { unsorted: false, sorted: true, empty: false },
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
    },
    size: 10,
    sort: { unsorted: false, sorted: true, empty: false },
    totalElements: 0,
    totalPages: 1,
  },
  isLoadingGetBoitelSaleScenarioDataRequest: false,
  isLoadingSaveRequest: false,
  boitelSaleScenarioData: {
    id: null,
    name: null,
    parameter: {
      allowSeparateSell: true,
      animalsInTruck: null,
      animalsInTruckUnit: "cab",
      commitments: [],
      deadlineGoal: null,
      goalType: "MaxProfit",
      id: null,
      useCustomCarcassHarnessing: false,
      carcassHarnessing: null,
      lots: [],
      // lots: [
      //   {
      //     dietStrategies: [],
      //     id: null,
      //     lotId: null,
      //     supplierId: null,
      //   },
      // ],
      marketAnalysisId: null,
      minimumAge: null,
      maximumAge: null,
      maximumWeight: 720,
      minimumWeight: null,
      retroactive: false,
      retroactiveDate: null,
      useDefaultMaximumWeight: true,
      strongAgeRestriction: true,
      strongWeightRestriction: true,
      strongAnimalsInTruckRestriction: true,
    },
  },
};

export default function boitelSaleScenario(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.RESET_REDIRECT_TO_LIST_PAGE:
      return { ...state, redirectToListPage: false };
    case Types.CLEAR_FIELDS:
      if (action.payload.resetBoitelSaleScenarioData === true) {
        return {
          ...state,
          clearFields: true,
          boitelSaleScenarioData: INITIAL_STATE.boitelSaleScenarioData,
        };
      } else {
        return { ...state, clearFields: !state.clearFields };
      }
    case Types.GET_BOITEL_SALE_SCENARIO_DATA:
      return { ...state, isLoadingGetBoitelSaleScenarioDataRequest: true };
    case Types.GET_BOITEL_SALE_SCENARIO_DATA_SUCCESS:
      return {
        ...state,
        isLoadingGetBoitelSaleScenarioDataRequest: false,
        boitelSaleScenarioData: action.payload.data,
      };
    case Types.GET_BOITEL_SALE_SCENARIO_DATA_ERROR:
      return { ...state, isLoadingGetBoitelSaleScenarioDataRequest: false };
    case Types.STORE_UPDATE_BOITEL_SALE_SCENARIO:
      return {
        ...state,
        isLoadingSaveRequest: true,
      };
    case Types.STORE_UPDATE_BOITEL_SALE_SCENARIO_SUCCESS:
      return {
        ...state,
        isLoadingSaveRequest: false,
        redirectToListPage: true,
        boitelSaleScenarioData: INITIAL_STATE.boitelSaleScenarioData,
      };
    case Types.STORE_UPDATE_BOITEL_SALE_SCENARIO_ERROR:
      return {
        ...state,
        isLoadingSaveRequest: false,
        isLoadingUpdateSimulationRequest: false,
      };
    case Types.UPDATE_STATUS_BOITEL_SALE_SCENARIO_LIST_DATA_ITEM:
      if (
        state.listBoitelSaleScenarioData != null &&
        state.listBoitelSaleScenarioData.content.length > 0
      ) {
        return {
          ...state,
          listBoitelSaleScenarioData: {
            ...state.listBoitelSaleScenarioData,
            content: state.listBoitelSaleScenarioData.content.map((d) => {
              if (d.id === action.payload.id) {
                d.status = action.payload.newStatus;
              }
              return d;
            }),
          },
        };
      } else {
        return {
          ...state,
        };
      }
    case Types.GET_BOITEL_SALE_SCENARIO_LIST_DATA:
      return {
        ...state,
        isLoadingListBoitelSaleScenario: true,
      };
    case Types.GET_BOITEL_SALE_SCENARIO_LIST_DATA_SUCCESS:
      return {
        ...state,
        listBoitelSaleScenarioData: action.payload.data,
        isLoadingListBoitelSaleScenario: false,
      };
    case Types.GET_BOITEL_SALE_SCENARIO_LIST_DATA_ERROR:
      return {
        ...state,
        listBoitelSaleScenarioData: INITIAL_STATE.listBoitelSaleScenarioData,
        isLoadingListBoitelSaleScenario: false,
      };
    default:
      return state;
  }
}
