/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Async } from "react-async";
import { withRouter, Link, useHistory } from "react-router-dom";

import moment from "moment";
import Axios from "axios";
/** Hooks */
import useAuthentication from "../../../hooks/useAuthentication";
import { useRelocatePicket } from "../../../hooks/useRelocatePicketReducer";
/** Redux libs */
import { useDispatch, useSelector } from "react-redux";
import { Creators as LotActions } from "../../../store/ducks/lot";
import { Creators as LotSupplementConsumptionActions } from "../../../store/ducks/lotSupplementConsumption";
import { Creators as AppActions } from "../../../store/ducks/app";
import { Creators as ReportActions } from "../../../store/ducks/report";
/** Components */
import {
  Row,
  Card,
  Col,
  Table,
  Input,
  Button,
  Icon,
  DatePicker,
  Menu,
  Dropdown,
  Tooltip,
  Popover,
} from "antd";
import { Container, Title, GenerateReport } from "./styles";
import TagStatus from "../../../components/utils/tagStatus";
import { Container as MenuContainer } from "../../../components/utils/table/menu/styles";
import MenuIcon from "../../../components/utils/table/icons/menu";
import CheckSmallIcon from "../../../components/utils/icons/check/checkSmall";
import ExclamationSmallIcon from "../../../components/utils/icons/exclamation/exclamationSmall";
import CompareButton from "../../../components/utils/compareButton";
import DeleteLotModal from "../../../components/modals/deleteLotModal";
import LotInAndOutAnimalsDash from "../dashboards/lotInAndOutAnimalsDash";
import ActivateLotModal from "../../../components/modals/activateLotModal";
import AlertDatePicker from "../../../components/utils/alertDatePicker";
import AlertDateIcon from "../../../components/utils/icons/alertDate";
import AlertButton from "../../../components/utils/alertButton";
import ButtonStandard from "../../../components/utils/button";

/** Services */
import { getLotStatisticsAsync } from "../../../services/lotService";
import { showAlertsByDateAndEntity } from "../../../services/alertService";
import CustomLink from "../../../components/utils/customLink";
import ExportReportNutritionalConsumptionModal from "../../../components/modals/exportReportNutritionalConsumption";

const ListLotProduction = ({ tab }) => {
  const { isAllowed } = useAuthentication();
  const { openModal: openRelocatePicketModal } = useRelocatePicket();
  // Variables
  const [signal] = useState(() => {
    return Axios.CancelToken.source();
  });
  const [selectedLots, setSelectedLots] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [alertsFilterDate, setAlertsFilterDate] = useState(moment());
  const [alertIds, setAlertIds] = useState(null);
  const [currentFarmId, setCurrentFarmId] = useState(null);
  const [
    isModalExportReportNutritionalConsumptionVisible,
    setIsModalExportReportNutritionalConsumptionVisible,
  ] = useState(false);
  const { RangePicker } = DatePicker;
  const Column = Table.Column;
  const rowSelection = {
    selectedRowKeys: selectedLots.map((s) => s),
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      if (selected) {
        setSelectedLots([...selectedLots, record.id]);
      } else {
        setSelectedLots(selectedLots.filter((r) => r !== record.id));
      }
    },
    onSelectAll: (record, selected, selectedRows, nativeEvent) => {
      // Get
      const keys = selectedRows;
      // Get all the selectedLots unless the unselected
      const lastSelectedLots = selectedLots.filter(
        (sAK) => !keys.filter((k) => k === sAK)
      );

      if (selected.length > 0) {
        setSelectedLots(
          selectedLots.length > 0
            ? lastSelectedLots
            : [...selectedLots, ...keys.map((k) => k.id)]
        );
      } else {
        setSelectedLots(lastSelectedLots);
      }
    },
  };

  // Redux variables
  const {
    groupSelected,
    farmSelected,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
    translation,
    languageSelected,
    alertSelected,
  } = useSelector((state) => state.app);
  const {
    refreshListProductionTable,
    dataLotProduction,
    isLoading: isLoadingLotRedux,
    paginationLotProduction: { page, size, tableProdSorters, tableProdFilters },
  } = useSelector((state) => state.lot);
  const dispatch = useDispatch();

  /** Route variables */
  const history = useHistory();

  // Callbacks
  const openModalExportReportNutritionalConsumption = useCallback(() => {
    setIsModalExportReportNutritionalConsumptionVisible(true);
  }, []);
  const closeModalExportReportNutritionalConsumption = useCallback(() => {
    setIsModalExportReportNutritionalConsumptionVisible(false);
  }, []);
  const handleShowLotSupplementConsumption = useCallback((lotId) => {
    dispatch(
      LotSupplementConsumptionActions.showOrHideDrawerAndFetch(
        groupId,
        farmId,
        lotId
      )
    );
  }, []);
  const handleDelete = useCallback((lot) => {
    dispatch(LotActions.showOrHideModalDelete(lot));
  }, []);
  const handleInactivate = useCallback((lot) => {
    dispatch(LotActions.inactivateLotProduction(groupId, farmId, lot.id, lot));
  }, []);
  const handleActivate = useCallback((lot) => {
    dispatch(LotActions.showOrHideModalActivate(lot));
  }, []);

  const handleEdit = useCallback((id) => {
    dispatch(LotActions.showDrawerLotProduction(id));
  }, []);

  const handleMovePicket = useCallback((lot) => {
    openRelocatePicketModal(lot, lot.picketId);
  }, []);

  // Methods
  function menu(id, status, record) {
    return (
      <Menu>
        {(status === "Dismembered" || status === "Inactive") && (
          <Menu.Item key="6" onClick={() => handleActivate(record)}>
            {translation.table.menu.activate}
          </Menu.Item>
        )}
        {status !== "Dismembered" && status !== "Inactive" && (
          <Menu.Item key="3">
            <Link to={`/admin/lots/${id}/add/animals`}>
              {translation.table.menu.addAnimalsToLotProduction}
            </Link>
          </Menu.Item>
        )}
        {status !== "Dismembered" && status !== "Inactive" && (
          <Menu.Item
            key="7"
            onClick={() => handleShowLotSupplementConsumption(id)}
          >
            Consumo de Suplemento/Concent.
          </Menu.Item>
        )}
        {status !== "Dismembered" && status !== "Inactive" && (
          <Menu.Item key="2">
            <Link to={`/admin/lots/${id}`}>
              {translation.table.menu.details}
            </Link>
          </Menu.Item>
        )}
        {status !== "Dismembered" && status !== "Inactive" && (
          <Menu.Item key="0" onClick={() => handleEdit(id)}>
            {translation.table.menu.edit}
          </Menu.Item>
        )}
        {status !== "Dismembered" && status !== "Inactive" && (
          <Menu.Item key="4">
            <Link to={`/admin/lots/${id}/dismember`}>
              {translation.table.menu.dismemberLotProduction}
            </Link>
          </Menu.Item>
        )}
        {status === "Active" && (
          <Menu.Item key="5" onClick={() => handleMovePicket(record)}>
            {translation.table.menu.movePickets}
          </Menu.Item>
        )}
        {status !== "Inactive" &&
          status !== "FarmExclude" &&
          isAllowed(["Trial", true]) && (
            <Menu.Item key="1" onClick={() => handleDelete(record)}>
              {translation.table.menu.delete}
            </Menu.Item>
          )}
        {status !== "Inactive" &&
          status !== "FarmExclude" &&
          isAllowed(["Trial", true]) && (
            <Menu.Item key="8" onClick={() => handleInactivate(record)}>
              {translation.table.menu.inactivate}
            </Menu.Item>
          )}
      </Menu>
    );
  }

  function expandedRows(record, index, indent, expanded) {
    const columns = [
      {
        title: translation.lot.production.table.columns.amount,
        dataIndex: "currentAmountAnimals",
        key: "currentAmountAnimals",
        render: (text, record) => (
          <Async
            promiseFn={getLotStatisticsAsync}
            payload={{
              groupId,
              farmId,
              id: record.key,
              statistics: "AmountActiveAnimals",
              signal,
            }}
          >
            <Async.Pending>
              <Icon type="loading" />
            </Async.Pending>
            <Async.Fulfilled>
              {(resp) => {
                record.currentAmountAnimals = resp.data.results;
                return <span>{`${resp.data.results || 0} animais`}</span>;
              }}
            </Async.Fulfilled>
          </Async>
        ),
      },
      {
        title: translation.lot.production.table.columns.gmd,
        dataIndex: "avgGmdWeight",
        key: "avgGmdWeight",
        render: (text, record) => (
          <Async
            promiseFn={getLotStatisticsAsync}
            payload={{
              groupId,
              farmId,
              id: record.key,
              statistics: "AvgGMDActiveAnimals",
              signal,
            }}
          >
            <Async.Pending>
              <Icon type="loading" />
            </Async.Pending>
            <Async.Fulfilled>
              {(resp) => (
                <span>{`${resp.data.results?.toFixed(2) || 0} Kg`}</span>
              )}
            </Async.Fulfilled>
          </Async>
        ),
      },
      {
        title: translation.lot.production.table.columns.averageWeight,
        dataIndex: "avgDailyWeight",
        key: "avgDailyWeight",
        render: (text, record) => (
          <Async
            promiseFn={getLotStatisticsAsync}
            payload={{
              groupId,
              farmId,
              id: record.key,
              statistics: "AvgWeightActiveAnimals",
              signal,
            }}
          >
            <Async.Pending>
              <Icon type="loading" />
            </Async.Pending>
            <Async.Fulfilled>
              {(resp) => (
                <span>{`${resp.data.results?.toFixed(2) || 0} Kg`}</span>
              )}
            </Async.Fulfilled>
          </Async>
        ),
      },
    ];

    let columnData = [
      {
        key: record.id,
        currentAmountAnimals: null,
        avgGmdWeight: null,
        avgDailyWeight: null,
      },
    ];

    return (
      <Table columns={columns} dataSource={columnData} pagination={false} />
    );
  }

  async function fetchData(
    groupSelected,
    farmSelected,
    page = 0,
    sorter = null,
    filters = null,
    size = 10,
    ids
  ) {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    let search = "is_receipt=false";
    try {
      if (filters && Object.entries(filters).length > 0) {
        Object.entries(filters).forEach(([k, v]) => {
          if (v && v.length > 0) {
            if (k === "status") {
              if (v.includes("I") || v.includes("Inactive")) v.push("X");
              let statusString = "";
              for (let i = 0; i < v.length; i++) {
                statusString += i === v.length - 1 ? `'${v[i]}'` : `'${v[i]}',`;
              }
              search = `${search};${k} IN (${statusString})`;
            } else if (k === "closingDate") {
              search = `${search};${k} between '${v[0]}' and '${v[1]}'`;
            } else {
              search = `${search};upper(${k}) like upper('%25${v}%25')`;
            }
          }
        });
      }

      if (search === "is_receipt=false") {
        search = `${search};status%20IN%20('P','A')`;
      }

      dispatch(
        LotActions.indexLotsProduction(
          groupId,
          farmId,
          page,
          sorter !== null ? sorter : { field: "createdAt", order: "descend" },
          search,
          size,
          ids,
          sorter,
          filters
        )
      );
    } catch (error) {}
  }

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys && selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
    };
  }

  function getDateSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div
          style={{
            padding: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Row style={{ marginBottom: 5 }}>
            <RangePicker
              onChange={(date, dateString) => setSelectedKeys(dateString)}
            />
          </Row>
          <Row>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{
                width: 90,
                marginRight: 8,
                background: "#684e94",
                borderColor: "none",
                border: "none",
              }}
            />
            <Button
              onClick={() => handleReset(clearFilters)}
              icon="delete"
              size="small"
              style={{ width: 90 }}
            />
          </Row>
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="calendar"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
    };
  }

  async function getAlerts(date) {
    if (alertSelected?.id != null) {
      dispatch(AppActions.setAlertSelected({}));
      setAlertIds(null);
    }

    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;

    const executionDate = date.format("YYYY-MM-DD");
    const entity = "lot";
    try {
      const {
        data: { results: alertsData },
      } = await showAlertsByDateAndEntity({
        groupId,
        farmId,
        executionDate,
        entity,
      });
      setAlerts(alertsData);
      setAlertsFilterDate(date);
    } catch (error) {
      setAlerts([]);
      setAlertsFilterDate(moment());
    }
  }

  function handleSearch(selectedKeys, confirm) {
    confirm();
  }

  function handleReset(clearFilters) {
    clearFilters();
  }

  function handleTableChange(pagination, filters, sorter) {
    fetchData(
      groupSelected,
      farmSelected,
      pagination.current,
      sorter,
      filters,
      pagination.pageSize,
      alertIds
    );
  }

  function handleCreateNewProductionLot() {
    dispatch(LotActions.showDrawerLotProduction());
  }

  function handleCompareLots() {
    dispatch(LotActions.compareLots(selectedLots));
    history.push("/admin/lots/compare");
  }

  function handleAlertSelected(alert) {
    let ids = null;
    if (
      alertSelected &&
      alertSelected.id === alert.id &&
      alertSelected.failed === alert.failed
    ) {
      dispatch(AppActions.setAlertSelected({}));
      setAlertIds(null);
    } else {
      dispatch(AppActions.setAlertSelected(alert));
      ids = "'" + alert.entityIds.join("','") + "'";
      setAlertIds(ids);
    }
    fetchData(groupSelected, farmSelected, 0, null, null, 10, ids);
  }

  function handleGenerateReport() {
    const ids = selectedLots;
    if (ids.length > 0)
      dispatch(ReportActions.showModal("Relatório de lotes", "lot", null, ids));
  }

  function handleReloadTable() {
    fetchData(groupSelected, farmSelected, 0, null, null, 10, null);
  }

  // Effects
  // Fetch Data
  useEffect(() => {
    function fetchData() {
      let search = "is_receipt=false;";
      let selectedDate = moment().format("YYYY-MM-DD");
      let ids = null;
      try {
        if (alertSelected != null && alertSelected.entity === "lot") {
          selectedDate = alertSelected.executionDate;
          setAlertsFilterDate(moment(selectedDate, "YYYY-MM-DD"));
          setAlertIds("'" + alertSelected.entityIds.join("','") + "'");
          alertSelected.alertPage = false;
          ids = "'" + alertSelected.entityIds.join("','") + "'";
        }
        if (tableProdFilters && Object.entries(tableProdFilters).length > 0) {
          Object.entries(tableProdFilters).forEach(([k, v]) => {
            if (v && v.length > 0) {
              if (k === "status") {
                if (v.includes("I") || v.includes("Inactive"))
                  v.push("FarmExclude");
                let statusString = "";
                for (let i = 0; i < v.length; i++) {
                  statusString +=
                    i === v.length - 1 ? `'${v[i]}'` : `'${v[i]}',`;
                }
                search = `${search};${k} IN (${statusString})`;
              } else if (k === "closingDate") {
                search = `${search};${k} between '${v[0]}' and '${v[1]}'`;
              } else {
                search = `${search};upper(${k}) like upper('%25${v}%25')`;
              }
            }
          });
        } else if (
          tableProdFilters === null ||
          tableProdFilters === undefined
        ) {
          search = `${search}status%20IN%20('P','A')`;
        }
        dispatch(
          LotActions.indexLotsProduction(
            groupId,
            farmId,
            page,
            tableProdSorters !== null
              ? tableProdSorters
              : { field: "createdAt", order: "descend" },
            search,
            size,
            ids,
            tableProdSorters,
            tableProdFilters
          )
        );
      } catch (error) {
        console.error(error);
      }
    }
    async function getAlerts() {
      let selectedDate = moment().format("YYYY-MM-DD");

      const executionDate = selectedDate;
      const entity = "lot";

      try {
        const {
          data: { results: alertsData },
        } = await showAlertsByDateAndEntity({
          groupId,
          farmId,
          executionDate,
          entity,
        });
        setAlerts(alertsData);
      } catch (error) {
        setAlerts([]);
      }
    }
    if (tab === "production") {
      if (currentFarmId !== farmId) {
        setCurrentFarmId(farmId);
        setAlerts([]);
        setAlertIds(null);
        setSelectedLots([]);
        getAlerts();
        fetchData();
      }
    }
  }, [groupId, farmId, alertIds, alertSelected, currentFarmId, dispatch, tab]);
  // Refresh table Effect
  useEffect(() => {
    function fetchData() {
      let search = "is_receipt=false";
      let selectedDate = moment().format("YYYY-MM-DD");
      let ids = null;
      try {
        if (alertSelected != null && alertSelected.entity === "lot") {
          selectedDate = alertSelected.executionDate;
          setAlertsFilterDate(moment(selectedDate, "YYYY-MM-DD"));
          setAlertIds("'" + alertSelected.entityIds.join("','") + "'");
          alertSelected.alertPage = false;
          ids = "'" + alertSelected.entityIds.join("','") + "'";
        }
        if (tableProdFilters && Object.entries(tableProdFilters).length > 0) {
          Object.entries(tableProdFilters).forEach(([k, v]) => {
            if (v && v.length > 0) {
              if (k === "status") {
                if (v.includes("I") || v.includes("Inactive"))
                  v.push("FarmExclude");
                let statusString = "";
                for (let i = 0; i < v.length; i++) {
                  statusString +=
                    i === v.length - 1 ? `'${v[i]}'` : `'${v[i]}',`;
                }
                search = `${search};${k} IN (${statusString})`;
              } else if (k === "closingDate") {
                search = `${search};${k} between '${v[0]}' and '${v[1]}'`;
              } else {
                search = `${search};upper(${k}) like upper('%25${v}%25')`;
              }
            }
          });
        } else if (
          tableProdFilters === null ||
          tableProdFilters === undefined
        ) {
          search = `${search};status%20IN%20('P','A')`;
        }
        dispatch(
          LotActions.indexLotsProduction(
            groupId,
            farmId,
            page,
            tableProdSorters !== null
              ? tableProdSorters
              : { field: "createdAt", order: "descend" },
            search,
            size,
            ids,
            tableProdSorters,
            tableProdFilters
          )
        );
        dispatch(LotActions.refreshTable(false));
      } catch (error) {
        console.error(error);
      }
    }
    if (refreshListProductionTable) {
      fetchData();
    }
  }, [groupId, farmId, alertIds, refreshListProductionTable, dispatch]);
  // Clean tableFilters and Sorter
  useEffect(() => {
    return () => {
      if (
        history.location.pathname != null &&
        !history.location.pathname?.includes("lots")
      ) {
        dispatch(LotActions.resetPaginationLotProduction());
        dispatch(LotActions.resetData());
      }
      signal.cancel("Cancel requests");
    };
  }, [history, dispatch]);
  return (
    <Container>
      <Row type="flex">
        <Col span={24}>
          <LotInAndOutAnimalsDash />
        </Col>
      </Row>
      <Row
        type="flex"
        justify="start"
        gutter={4}
        align="middle"
        style={{ paddingLeft: 20, marginTop: 10, marginBottom: 10 }}
      >
        <Col xs={24} sm={24} md={3} lg={3} xl={3}>
          <AlertDateIcon />
          <AlertDatePicker
            alertsFilterDate={alertsFilterDate}
            getAlerts={getAlerts}
          />
        </Col>
        {alerts?.length <= 0 ? (
          <span style={{ color: "#D44C4C", marginLeft: "10px" }}>
            {" "}
            Nenhum alerta de lote nessa data
          </span>
        ) : (
          alerts?.map((alert) => (
            <Col key={alert.id} xs={24} sm={24} md={5} lg={5} xl={5}>
              <AlertButton
                alert={alert}
                alertSelected={alertSelected}
                handleAlertSelected={handleAlertSelected}
              />
            </Col>
          ))
        )}
      </Row>
      <Card>
        <Row
          type="flex"
          justify="space-between"
          style={{ marginBottom: "19px" }}
        >
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Title>{translation.lot.production.title}</Title>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} align="right">
            <ButtonStandard
              buttonType="typeWithoutBackground"
              onClick={handleReloadTable}
            >
              <Icon type="reload" />
            </ButtonStandard>
            <Tooltip
              title="Selecione um ou vários lote(s) na tabela abaixo para gerar o relatório."
              placement="top"
            >
              <GenerateReport
                onClick={handleGenerateReport}
                className={selectedLots.length === 0 ? "disabled" : ""}
              >
                Gerar Relatório
              </GenerateReport>
            </Tooltip>
            <GenerateReport
              onClick={openModalExportReportNutritionalConsumption}
            >
              Tratos/Consumo de Ração/Suplemento
            </GenerateReport>
            <CompareButton
              disable={selectedLots.length === 0}
              onClick={handleCompareLots}
            />
            <ButtonStandard
              buttonType="type8"
              onClick={handleCreateNewProductionLot}
            >
              {translation.lot.production.buttonNewLot}
            </ButtonStandard>
          </Col>
        </Row>
        <Row type="flex">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              loading={isLoadingLotRedux}
              rowKey="id"
              dataSource={
                dataLotProduction != null ? dataLotProduction.content : []
              }
              pagination={{
                total:
                  dataLotProduction != null
                    ? dataLotProduction.totalElements
                    : 0,
                size: dataLotProduction != null ? size : 0,
                current: dataLotProduction != null ? page : 0,
                showSizeChanger: true,
                pageSizeOptions: [
                  "10",
                  "20",
                  "30",
                  "100",
                  "500",
                  "1000",
                  "2000",
                ],
                hideOnSinglePage:
                  dataLotProduction !== null &&
                  Object.entries(dataLotProduction).length !== 0
                    ? dataLotProduction.totalElements > 10
                      ? false
                      : true
                    : true,
              }}
              scroll={{
                x: 1200,
                y: 758,
              }}
              expandedRowRender={expandedRows}
              rowSelection={rowSelection}
              onChange={handleTableChange}
            >
              <Column
                title={translation.lot.production.table.columns.lotName}
                dataIndex="name"
                width={250}
                sorter
                key="name"
                align="left"
                filteredValue={tableProdFilters?.name || null}
                sortOrder={
                  tableProdSorters?.columnKey === "name" &&
                  tableProdSorters.order
                }
                {...getColumnSearchProps("name")}
                render={(text, record) =>
                  text.length > 27 ? (
                    <Tooltip title={text}>
                      <div>
                        <CustomLink
                          to={`/admin/lots/${record.id}`}
                        >{`${text.substring(0, 27)}...`}</CustomLink>
                      </div>
                    </Tooltip>
                  ) : (
                    <CustomLink to={`/admin/lots/${record.id}`}>
                      {text}
                    </CustomLink>
                  )
                }
              />
              <Column
                title={translation.lot.production.table.columns.salesScenario}
                dataIndex="saleScenarioName"
                sorter
                key="saleScenarioName"
                align="left"
              />
              <Column
                title={
                  translation.lot.production.table.columns.dietStrategyName
                }
                dataIndex="dietStrategyName"
                key="dietStrategyName"
                align="left"
                render={(text, record) => (
                  <span>
                    {record.dietStrategies != null &&
                    record.dietStrategies.length > 0
                      ? record.dietStrategies.find(
                          (ds) => ds.baseline === true
                        ) != null
                        ? record.dietStrategies.find(
                            (ds) => ds.baseline === true
                          ).dietStrategyName
                        : ""
                      : ""}
                  </span>
                )}
              />
              <Column
                title={translation.lot.production.table.columns.profitCenter}
                dataIndex="profitCenterName"
                sorter
                key="profitCenterName"
                align="left"
                filteredValue={tableProdFilters?.profitCenterName || null}
                sortOrder={
                  tableProdSorters?.columnKey === "profitCenterName" &&
                  tableProdSorters.order
                }
                {...getColumnSearchProps("profitCenterName")}
                render={(text, record) =>
                  text != null && text.length > 15 ? (
                    <Tooltip title={text}>
                      <span>{`${text.substring(0, 15)}...`}</span>
                    </Tooltip>
                  ) : (
                    <span>{text}</span>
                  )
                }
              />
              <Column
                title={
                  translation.lot.production.table.columns.createdAtAndEndAt
                }
                dataIndex="closingDate"
                key="closingDate"
                align="left"
                sorter
                sortDirections={["descend", "ascend"]}
                filteredValue={tableProdFilters?.closingDate || null}
                sortOrder={
                  tableProdSorters?.columnKey === "closingDate" &&
                  tableProdSorters.order
                }
                {...getDateSearchProps("closingDate")}
                render={(text, record) => (
                  <span>
                    {`${
                      record.creationDate !== null
                        ? languageSelected === "en"
                          ? moment(record.creationDate).format("MM/DD/YYYY")
                          : moment(record.creationDate).format("DD/MM/YYYY")
                        : ""
                    } - ${
                      record.closingDate !== null
                        ? languageSelected === "en"
                          ? moment(record.closingDate).format("MM/DD/YYYY")
                          : moment(record.closingDate).format("DD/MM/YYYY")
                        : ""
                    }`}
                  </span>
                )}
              />
              <Column
                title={translation.lot.production.table.columns.status}
                dataIndex="status"
                key="status"
                align="left"
                filtered
                filters={[
                  {
                    text: translation.status.active,
                    value: "A",
                  },
                  {
                    text: translation.status.inactive,
                    value: "I",
                  },
                  {
                    text: translation.status.dismembered,
                    value: "D",
                  },
                  {
                    text: "Vendido",
                    value: "S",
                  },
                  {
                    text: "Pendente",
                    value: "P",
                  },
                ]}
                filterMultiple
                filteredValue={tableProdFilters?.status || null}
                sortOrder={
                  tableProdSorters?.columnKey === "status" &&
                  tableProdSorters.order
                }
                sorter
                sortDirections={["descend", "ascend"]}
                render={(status, record) =>
                  status === "Active" ? (
                    <TagStatus
                      background="#C5F1CA"
                      borderColor="#106518"
                      color="#106518"
                    >
                      {translation.status.active}
                    </TagStatus>
                  ) : status === "Sold" ? (
                    <TagStatus
                      background="#FEFFF6"
                      borderColor="#A9C133"
                      color="#A9C133"
                    >
                      Vendido
                    </TagStatus>
                  ) : status === "Inactive" || status === "FarmExclude" ? (
                    <TagStatus
                      background="#FBC7C7"
                      borderColor="#D44C4C"
                      color="#D44C4C"
                    >
                      {translation.status.inactive}
                    </TagStatus>
                  ) : status === "Pending" ? (
                    <Popover
                      content={
                        <div>
                          <div>
                            <span style={{ marginRight: 7 }}>
                              <Async
                                promiseFn={getLotStatisticsAsync}
                                payload={{
                                  groupId,
                                  farmId,
                                  id: record.id,
                                  statistics: "AmountActiveAnimals",
                                  signal,
                                }}
                              >
                                <Async.Pending>
                                  <Icon type="loading" />
                                </Async.Pending>
                                <Async.Fulfilled>
                                  {(resp) =>
                                    resp.data.results ? (
                                      <CheckSmallIcon />
                                    ) : (
                                      <span style={{ margin: "0 6px 0 4px" }}>
                                        <ExclamationSmallIcon />
                                      </span>
                                    )
                                  }
                                </Async.Fulfilled>
                              </Async>
                            </span>
                            <span style={{ fontWeight: "bold" }}>
                              Adicione animais
                            </span>{" "}
                            no <span style={{ fontWeight: "bold" }}>lote</span>
                          </div>
                          <div>
                            <span style={{ marginRight: 7 }}>
                              {record?.picketId ? (
                                <CheckSmallIcon />
                              ) : (
                                <span style={{ margin: "0 6px 0 4px" }}>
                                  <ExclamationSmallIcon />
                                </span>
                              )}
                            </span>
                            Aloque o lote em um{" "}
                            <span style={{ fontWeight: "bold" }}>
                              piquete/baia
                            </span>
                          </div>
                          <div>
                            <span style={{ marginRight: 7 }}>
                              {record?.dietStrategies.length ? (
                                <CheckSmallIcon />
                              ) : (
                                <span style={{ margin: "0 6px 0 4px" }}>
                                  <ExclamationSmallIcon />
                                </span>
                              )}
                            </span>
                            Defina uma{" "}
                            <span style={{ fontWeight: "bold" }}>
                              Estratégia de dieta
                            </span>{" "}
                            para o lote em{" "}
                            <span style={{ fontWeight: "bold" }}>Manejos</span>
                          </div>
                        </div>
                      }
                      trigger="hover"
                    >
                      <TagStatus
                        background="#FFDBBC"
                        borderColor="#FE8D2A"
                        color="#FE8D2A"
                      >
                        Pendente
                      </TagStatus>
                    </Popover>
                  ) : (
                    <TagStatus
                      background="#C8E8FF"
                      borderColor="#4A85AE"
                      color="#4A85AE"
                    >
                      {translation.status.dismembered}
                    </TagStatus>
                  )
                }
              />

              <Column
                align="left"
                width={50}
                render={(text, record) => (
                  <Dropdown
                    overlay={menu(record.id, record.status, record)}
                    trigger={["click"]}
                    key={record.id}
                  >
                    <MenuContainer>
                      <MenuIcon />
                    </MenuContainer>
                  </Dropdown>
                )}
              />
            </Table>
          </Col>
        </Row>
      </Card>
      {/* Modals */}
      <DeleteLotModal />
      <ActivateLotModal />
      <ExportReportNutritionalConsumptionModal
        modalVisible={isModalExportReportNutritionalConsumptionVisible}
        handleCloseModal={closeModalExportReportNutritionalConsumption}
      />
    </Container>
  );
};

export default withRouter(ListLotProduction);
