import React from "react";

// import { Container } from './styles';

const AlertIcon = () => (
  <svg
    id="exclamation-mark"
    xmlns="http://www.w3.org/2000/svg"
    width="14.87"
    height="14.87"
    viewBox="0 0 14.87 14.87"
  >
    <path
      id="Path_1943"
      data-name="Path 1943"
      d="M7.435,0A7.435,7.435,0,1,0,14.87,7.435,7.435,7.435,0,0,0,7.435,0Zm0,13.476a6.041,6.041,0,1,1,6.041-6.041A6.041,6.041,0,0,1,7.435,13.476Zm0-10.215c-.533,0-.935.278-.935.727V8.1c0,.449.4.726.935.726s.935-.289.935-.726V3.987C8.371,3.549,7.955,3.261,7.435,3.261Zm0,6.5a.928.928,0,1,0,.928.928A.929.929,0,0,0,7.435,9.758Z"
      fill="#e2574c"
    />
  </svg>
);
export default AlertIcon;
