import { call, put } from "redux-saga/effects";
import { Creators as QuotaActions } from "../ducks/quota";

import { notification } from "antd";

/** Services */
import {
  Inactivate,
  Activate,
  indexAll,
  indexAllDropdown,
} from "../../services/quotaService";

export function* indexFarmQuotas(action) {
  try {
    const {
      data: { results: quotas },
    } = yield call(indexAll, action.payload);
    yield put(QuotaActions.indexSuccess(quotas));
  } catch (error) {
    yield put(QuotaActions.indexError(error));
  }
}

export function* getDropdownFarmQuotas(action) {
  try {
    const {
      data: { results: quotas },
    } = yield call(indexAllDropdown, action.payload);
    yield put(QuotaActions.getDropdownQuotaSuccess(quotas));
  } catch (error) {
    yield put(QuotaActions.getDropdownQuotaError(error));
  }
}

export function* inactivateFarmQuota(action) {
  try {
    const {
      data: { results: quotas },
    } = yield call(Inactivate, action.payload);
  } catch (error) {
    notification.error({
      message: `Cota não foi inativada. Contate o suporte`,
    });
  }

  yield put(QuotaActions.index(action.payload.groupId, action.payload.farmId));
}

export function* activateFarmQuota(action) {
  try {
    const {
      data: { results: quotas },
    } = yield call(Activate, action.payload);
  } catch (error) {
    notification.error({
      message: `Cota não foi ativada. Contate o suporte`,
    });
  }

  yield put(QuotaActions.index(action.payload.groupId, action.payload.farmId));
}