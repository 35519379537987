import styled, { css } from "styled-components";

export const FarmConvertionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  padding: 1rem;

  .ant-row,
  .ant-row-flex {
    width: 100%;
  }

  ${(props) =>
    !props.isActive &&
    css`
      &::after {
        content: "";

        width: 100%;
        height: 100%;

        position: absolute;

        background-color: rgba(255, 255, 255, 0.7);

        cursor: not-allowed;
      }
    `}
`;
