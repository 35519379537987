import React, { useState } from "react";
import { useDispatch } from "react-redux";

/* Redux Actions */
import { Creators as appActions } from "../../../store/ducks/app";

// Components
import { Row, Col, Spin, notification } from "antd";
import { Container, CustomInput } from "./styles";
import ButtonStandard from "../../utils/button";
import IconCheck from "../../utils/icons/check/white";
import {
  sendCodeEmailFrom,
  sendCodeEmailTo,
  verifyCodeEmailFrom,
  verifyCodeEmailTo,
} from "../../../services/userChangeEmailService";

const DrawerChangeEmail = ({ drawerVisible, onCancel, data }) => {
  const [codeFrom, setCodeFrom] = useState("");
  const [codeTo, setCodeTo] = useState("");
  const [emailTo, setEmailTo] = useState("");
  const [userId, setUserId] = useState("");
  const [changeEmailId, setChangeEmailId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailToSent, setIsEmailToSent] = useState(false);
  const [isCodeFromValidated, setIsCodeFromValidated] = useState(false);
  const [isCodeToValidated, setIsCodeToValidated] = useState(false);
  const dispatch = useDispatch();

  function handleOpenDrawer(visible) {
    if (visible) {
      if (data && data.userId !== null) {
        sendEmailFrom();
      }
    }
  }

  async function sendEmailFrom() {
    setIsLoading(true);
    try {
      let response = await sendCodeEmailFrom(data.userId, data.email);
      notification.success({
        message: "Código de validação enviado para o e-mail.",
      });
      setChangeEmailId(response.data.results);
      // setEmailFrom(data.email);
      setUserId(data.userId);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      if (e.response?.status === 404) {
        notification.error({
          message: "Usuário não encontrado.",
        });
      } else {
        notification.error({
          message: "Erro interno. Contate o suporte.",
        });
      }
    }
  }

  async function sendEmailTo() {
    setIsLoading(true);
    if (handleEmailChanged()) {
      try {
        await sendCodeEmailTo(userId, emailTo, changeEmailId);
        notification.success({
          message: "Código de validação enviado para o novo e-mail.",
        });
        setIsLoading(false);
        setIsEmailToSent(true);
      } catch (e) {
        setIsLoading(false);
        notification.error({
          message: "E-mail informado já existe.",
        });
      }
    } else {
      setIsLoading(false);
    }
  }

  const handleResendCodeFrom = () => {
    sendEmailFrom();
  };
  const handleResendCodeTo = () => {
    sendEmailTo();
  };

  const handleVerifyCodeFrom = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (codeFrom) {
      try {
        await verifyCodeEmailFrom(userId, codeFrom, changeEmailId);
        setIsLoading(false);
        setIsCodeFromValidated(true);
      } catch (e) {
        setIsLoading(false);
        if (e.response?.status === 404) {
          notification.error({
            message: "Usuário não encontrado.",
          });
        } else {
          notification.error({
            message: "Código inválido.",
          });
        }
      }
    }
  };

  const handleVerifyCodeTo = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (codeFrom) {
      try {
        await verifyCodeEmailTo(userId, codeTo, changeEmailId);
        setIsLoading(false);
        setIsCodeToValidated(true);
        handleLogout();
      } catch (e) {
        setIsLoading(false);
        if (e.response?.status === 404) {
          notification.error({
            message: "Usuário não encontrado.",
          });
        } else {
          notification.error({
            message: "Código inválido.",
          });
        }
      }
    }
  };

  function handleLogout() {
    dispatch(appActions.logout_user(null));
    window.location.href = "/login";
  }

  function handleEmailChanged() {
    if (emailTo) {
      if (emailTo.match(/([\w\-_]+)?\w+@[\w-_]+(\.\w+){1,}/gim)) {
        return true;
      } else {
        notification.error({ message: "E-mail inválido" });
        return false;
      }
    }
    return false;
  }

  function displayEmailToForm() {
    if (isCodeFromValidated) {
      return (
        <div className="drawerFormCustom">
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <span style={{ fontSize: "14px", color: "#000" }}>
                Informe seu novo e-mail no campo abaixo
              </span>
            </Col>
          </Row>
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <CustomInput
                  className="input-custom"
                  placeholder="Informe o e-mail"
                  value={emailTo}
                  disabled={isEmailToSent}
                  name="emailTo"
                  onChange={(event) => {
                    setEmailTo(event.target.value);
                  }}
                />
              </Row>
            </Col>
          </Row>
          <Row
            type="flex"
            style={{ marginTop: "13px", justifyContent: "space-between" }}
            align="middle"
          >
            <ButtonStandard
              className="verify"
              buttonType="type1"
              disabled={isEmailToSent}
              background={isEmailToSent ? "#30b142" : "#684e94"}
              type="button"
              onClick={sendEmailTo}
              size="s"
            >
              Atualizar
            </ButtonStandard>
          </Row>
        </div>
      );
    }
  }

  function displayCodeToForm() {
    if (isEmailToSent) {
      return (
        <div className="drawerFormCustom">
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <span style={{ fontSize: "14px", color: "#000" }}>
                Foi enviado um código para o seu novo e-mail, copie e cole no
                campo abaixo
              </span>
            </Col>
          </Row>
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <CustomInput
                  className="input-custom"
                  placeholder="Informe o código aqui"
                  value={codeTo}
                  name="codeTo"
                  onChange={(event) => {
                    setCodeTo(event.target.value);
                  }}
                />
              </Row>
            </Col>
          </Row>
          <Row
            type="flex"
            style={{ marginTop: "13px", justifyContent: "space-between" }}
            align="middle"
          >
            <button
              type="button"
              className="resend"
              disabled={isCodeToValidated}
              onClick={handleResendCodeTo}
            >
              <strong>Enviar código novamente</strong>
            </button>

            <ButtonStandard
              className="verify"
              buttonType="type1"
              disabled={isCodeToValidated}
              background={isCodeToValidated ? "#30b142" : "#684e94"}
              type="button"
              onClick={(event) => {
                handleVerifyCodeTo(event);
              }}
              size="s"
            >
              {isCodeToValidated ? <IconCheck /> : null}
              {isCodeToValidated ? " Verificado" : "Validar"}
            </ButtonStandard>
          </Row>
        </div>
      );
    }
  }

  return (
    <Container
      title="Alterar e-mail"
      width={425}
      onClose={onCancel}
      maskClosable={false}
      visible={drawerVisible}
      afterVisibleChange={handleOpenDrawer}
    >
      <Spin spinning={isLoading}>
        <form>
          <div className="drawerFormCustom">
            <Row type="flex">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <span style={{ fontSize: "14px", color: "#000" }}>
                  Foi enviado um código para o seu e-mail, copie e cole no{" "}
                  <br /> campo abaixo
                </span>
              </Col>
            </Row>
            <Row type="flex" style={{ marginTop: "13px" }} align="middle">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row>
                  <CustomInput
                    className="input-custom"
                    placeholder="Informe o código aqui"
                    value={codeFrom}
                    name="codeFrom"
                    onChange={(event) => {
                      setCodeFrom(event.target.value);
                    }}
                  />
                </Row>
              </Col>
            </Row>
            <Row
              type="flex"
              style={{ marginTop: "13px", justifyContent: "space-between" }}
              align="middle"
            >
              <button
                type="button"
                className="resend"
                disabled={isCodeFromValidated}
                onClick={handleResendCodeFrom}
              >
                <strong>Enviar código novamente</strong>
              </button>

              <ButtonStandard
                className="verify"
                buttonType="type1"
                disabled={isCodeFromValidated}
                background={isCodeFromValidated ? "#30b142" : "#684e94"}
                type="submit"
                onClick={(event) => {
                  handleVerifyCodeFrom(event);
                }}
                size="s"
              >
                {isCodeFromValidated ? <IconCheck /> : null}
                {isCodeFromValidated ? " Verificado" : "Validar"}
              </ButtonStandard>
            </Row>
          </div>
          {displayEmailToForm()}
          {displayCodeToForm()}
        </form>
      </Spin>
    </Container>
  );
};

export default DrawerChangeEmail;
