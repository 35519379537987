import styled from "styled-components";

export const Container = styled.div``;

export const Table = styled.table`
  width: 100% !important;
  min-width: 100% !important;
  thead {
    th {
      font-family: Asap;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: #c4c4c4;
      padding-bottom: 10px;
      width: 120px;
      span {
        display: flex;
        align-items: center;
      }

      &.cost {
        width: 120px !important;
      }

      &.status {
        width: 130px !important;
      }

      &.icon {
        width: 120px !important;
        svg {
          margin-right: 5px;
          width: 15px;
          height: 15px;
        }
        &.water {
          font-family: Asap;
          font-weight: bold;
          font-size: 14px;
          text-align: left;
          color: #568db3;
        }
        &.dry {
          font-family: Asap;
          font-weight: bold;
          font-size: 14px;
          text-align: left;
          color: #d44c4c;
        }
        &.transition {
          font-family: Asap;
          font-weight: bold;
          font-size: 14px;
          text-align: left;
          color: #f6953d;
        }
      }
    }
  }
  tbody {
    tr {
      &:first-child {
        background: #f5f5f5;
      }
      td {
        padding-top: 15px;
        padding-bottom: 15px;
        font-family: Asap;
        font-weight: normal;
        font-size: 12px;
        text-align: left;
        color: #4b4b4b;

        &:first-child {
          padding-left: 15px;
        }
        &:last-child {
          /* padding-right: 15px; */
          text-align: right;
        }


        span {
          font-family: Asap;
          font-weight: normal;
          font-size: 12px;
          text-align: left;
          color: #4b4b4b;
          display: flex;
          align-items: center;
          &.success {
            color: #41c36f;
          }
          svg {
            margin-right: 5px;
          }
        }

        div.buttonGroup {
          display: flex;
          align-items: flex-end;
        }

        button {
          border: none;
          background: transparent;
          font-family: Asap;
          font-weight: normal;
          font-size: 12px;
          display: flex;
          margin: 0;

          &.add {
            align-items: center;
            color: #4a85ae;
            svg {
              margin-left: 5px;
            }
          }

          &.edit {
            color: #9386aa;
            svg {
              margin-left: 5px;
              path {
                fill: #9386aa !important;
              }
            }
          }

          &:hover {
            cursor: pointer;
          }

          &.delete {
            color: #d44c4c;
            svg {
              margin-left: 0px;
              path {
                fill: #d44c4c !important;
              }
            }

            &:before {
              content: "|";
              color: #a5a5a5;
              margin-left: 10px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
`;
