import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { Col, Icon, Row, Table } from "antd";

import { Creators as AppActions } from "../../../store/ducks/app";

import { getFinancialTransactionsImportLog } from "../../../services/importService";

import { CardCustom, Title } from "./styles";

import ButtonStandard from "../../../components/utils/button";
import EyeIcon from "../../../components/utils/icons/eye";
import TagStatus from "../../../components/utils/tagStatus";

function FinancialTransactionImport() {
  const Column = Table.Column;

  const [
    importFinancialTransactionLogData,
    setImportFinancialTransactionLogData,
  ] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [importLogId, setImportLogId] = useState(null);
  // const [canDeleteFile, setCanDeleteFile] = useState(false);

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const fetchData = useCallback(
    async (page = 0, sorter = {}, filters = "", size = 10) => {
      setIsLoading(true);
      try {
        const {
          data: { results: responseFinancialTransactionImports },
        } = await getFinancialTransactionsImportLog({
          groupId,
          farmId,
          page,
          sorter,
          filters,
          size,
        });

        // setCanDeleteFile(responseFinancialTransactionImports.canDeleteFile);
        // setImportLogId(responseFinancialTransactionImports.importLogId);
        setImportFinancialTransactionLogData(
          responseFinancialTransactionImports
        );
      } catch (error) {
        console.error(error);
        setImportFinancialTransactionLogData(null);
      } finally {
        setIsLoading(false);
      }
    },
    [groupId, farmId]
  );

  const handleReloadTable = async () => {
    fetchData();
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    let search = "";

    fetchData(pagination.current, sorter, search, pagination.pageSize);
  };

  const confirmDelete = async (importFileRequestId) => {};

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <CardCustom>
      <Row type="flex" justify="space-between" className="rowHeader">
        <Col>
          <Title>{translation.imports.financialTransactions.table.title}</Title>
        </Col>
        <Col>
          <ButtonStandard
            buttonType="typeWithoutBackground"
            onClick={handleReloadTable}
          >
            <Icon type="reload" />
          </ButtonStandard>
          <ButtonStandard
            type="button"
            // disabled={!canDeleteFile}
            buttonType="type8"
            onClick={() =>
              dispatch(AppActions.showModalFinancialTransactionUpload())
            }
          >
            {translation.imports.financialTransactions.buttonImport}
          </ButtonStandard>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            loading={isLoading}
            rowKey="id"
            size="small"
            dataSource={
              importFinancialTransactionLogData != null
                ? importFinancialTransactionLogData.content
                : []
            }
            pagination={{
              total:
                importFinancialTransactionLogData != null
                  ? importFinancialTransactionLogData.totalElements
                  : 0,
              size:
                importFinancialTransactionLogData != null
                  ? importFinancialTransactionLogData.size
                  : 0,
              current:
                importFinancialTransactionLogData != null
                  ? importFinancialTransactionLogData.number + 1
                  : 0,
              hideOnSinglePage:
                importFinancialTransactionLogData !== null &&
                Object.entries(importFinancialTransactionLogData).length !== 0
                  ? importFinancialTransactionLogData.totalElements > 10
                    ? false
                    : true
                  : true,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
            }}
            scroll={{
              x: true,
            }}
            style={{ width: "100%", margin: "0" }}
            onChange={handleTableChange}
          >
            <Column
              title={
                translation.imports.financialTransactions.table.columns.status
              }
              dataIndex="status"
              key="status"
              align="left"
              render={(status) =>
                status === "Processed" ? (
                  <TagStatus
                    background="#C5F1CA"
                    borderColor="#106518"
                    color="#106518"
                  >
                    {translation.imports.status.processed}
                  </TagStatus>
                ) : status === "Queue" ? (
                  <TagStatus
                    background="#EBF7FF"
                    borderColor="#4A85AE"
                    color="#4A85AE"
                  >
                    {translation.imports.status.queue}
                  </TagStatus>
                ) : status === "Processing" ? (
                  <TagStatus
                    background="#FEFFF6"
                    borderColor="#A9C133"
                    color="#A9C133"
                  >
                    {translation.imports.status.processing}
                  </TagStatus>
                ) : status === "Deleting" ? (
                  <TagStatus
                    background="#FFD8D8"
                    borderColor="#D44C4C"
                    color="#D44C4C"
                  >
                    {translation.imports.status.deleting}
                  </TagStatus>
                ) : (
                  <TagStatus
                    background="#FFD8D8"
                    borderColor="#D44C4C"
                    color="#D44C4C"
                  >
                    {translation.imports.status.error}
                  </TagStatus>
                )
              }
            />
            <Column
              title={
                translation.imports.financialTransactions.table.columns.fileName
              }
              dataIndex="fileName"
              key="fileName"
              align="left"
              sorter
              sortDirections={["descend", "ascend"]}
            />
            <Column
              title={
                translation.imports.financialTransactions.table.columns.date
              }
              dataIndex="date"
              align="left"
              key="date"
            />
            <Column
              title={
                translation.imports.financialTransactions.table.columns.total
              }
              dataIndex="total"
              align="center"
              key="total"
            />
            <Column
              title={
                translation.imports.financialTransactions.table.columns
                  .numberImported
              }
              dataIndex="numberImported"
              align="center"
              key="numberImported"
            />
            <Column
              title={
                translation.imports.financialTransactions.table.columns
                  .numberError
              }
              dataIndex="numberError"
              align="center"
              key="numberError"
            />
            <Column
              title={
                translation.imports.financialTransactions.table.columns
                  .numberDuplicated
              }
              dataIndex="numberDuplicated"
              align="center"
              key="numberDuplicated"
            />
            <Column
              title={
                translation.imports.financialTransactions.table.columns.message
              }
              dataIndex="message"
              align="left"
              key="message"
            />
            <Column
              align="left"
              render={(text, record) =>
                record.status === "Processed" ? (
                  <Link
                    to={`/admin/imports/financial/transactions/${record.requestId}/details`}
                  >
                    <EyeIcon isActive />
                  </Link>
                ) : null
              }
            />
            {/* <Column
              align="left"
              render={(text, record) =>
                record.status === "Processed" &&
                canDeleteFile &&
                record.id === importLogId ? (
                  <DeleteButton
                    type="button"
                    onClick={() => confirmDelete(record.requestId)}
                  >
                    <TrashIcon />
                  </DeleteButton>
                ) : record.id === importLogId &&
                  record.status === "Deleting" ? (
                  <InfoTooltip
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "0 5px",
                        }}
                      >
                        <p>
                          Os dados de peso estão sendo recalculados devido
                          importacao/deleção de um arquivo. Aguarde o
                          processamento acabar para poder deletar um arquivo
                          novamente.
                        </p>
                      </div>
                    }
                  />
                ) : null
              }
            /> */}
          </Table>
        </Col>
      </Row>
    </CardCustom>
  );
}

export default FinancialTransactionImport;
