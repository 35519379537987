import ptBr from './pt_br';
import en from './en';
import es from './es';

export default function (lang = 'pt_br') {
  return new Promise((resolve) => {
    switch (lang) {
      case 'pt_br':
        resolve(ptBr);
        break;
      case 'en':
        resolve(en);
        break;
      case 'es':
        resolve(es);
        break;
      default:
        resolve(ptBr);
        break;
    }
  });
}
