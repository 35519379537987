import apiOptimization from "../config/api_optimization";
import getHeaders from "./getHeaders";

export function showBoitelSaleScenarioFarmSellService(payload) {
  const {
    groupId,
    farmId,
    boitelSaleScenarioId,
    boitelSaleScenarioResultId,
    id,
  } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/boitels/${boitelSaleScenarioId}/results/${boitelSaleScenarioResultId}/farm/sales/${id}`,
    getHeaders(false, null)
  );
}

export function getAnimalsBoitelSaleScenarioFarmSellService(payload) {
  const {
    groupId,
    farmId,
    boitelSaleScenarioId,
    boitelSaleScenarioResultId,
    id,
  } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/boitels/${boitelSaleScenarioId}/results/${boitelSaleScenarioResultId}/farm/sales/${id}/animals`,
    getHeaders(false, null)
  );
}

export function storeBoitelSaleScenarioFarmSell(payload) {
  const {
    groupId,
    farmId,
    boitelSaleScenarioId,
    boitelSaleScenarioResultId,
    body,
  } = payload;

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/boitels/${boitelSaleScenarioId}/results/${boitelSaleScenarioResultId}/farm/sales`,
    body,
    getHeaders(false, null)
  );
}

export function deleteBoitelSaleScenarioFarmSellService(payload) {
  const {
    groupId,
    farmId,
    boitelSaleScenarioId,
    boitelSaleScenarioResultId,
    id,
  } = payload;

  return apiOptimization.delete(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/boitels/${boitelSaleScenarioId}/results/${boitelSaleScenarioResultId}/farm/sales/${id}`,
    getHeaders(false, null)
  );
}
