import styled from "styled-components";
import { Card, Checkbox } from "antd";

export const Container = styled(Card)`
  width: 100%;
  margin-bottom: 16px !important;

  .ant-card-body {
    padding-bottom: 0px !important;
  }

  .error {
    color: #d44c4c;
  }

  .ant-row,
  .ant-row-flex {
    width: 100%;
  }

  .ant-row-flex {
    margin-bottom: 8px;
  }

  .ant-row > * {
    width: 100%;
  }

  .ant-row > label {
    display: inline-block;

    margin-bottom: 8px;
    margin-top: 8px;

    color: #9074bf;
    font-size: 14px;
    font-weight: bold;

    &.error {
      color: #d44c4c;
    }
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked {
    color: #fff !important;
  }

  #bovexoArrowDownIcon,
  #bovexoArrowUpIcon {
    width: 24px;
    height: 24px;
  }

  .buttonArrowCollapse {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;

    cursor: pointer;

    &.withMarginBottom {
      margin-bottom: 8px;
    }
  }

  .rowFooter {
    margin-top: 16px;
  }
`;

export const ButtonSelectCoverage = styled.button`
  background-color: #9074bf;
  border: 5px solid #a3bf74;
  border-radius: 25px;
  color: #f3f3f3;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px;

  cursor: pointer;

  svg {
    width: 50px;
    height: 50px;
  }
`;
export const TableSelectCoverage = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  column-gap: 16px;
  row-gap: 8px;
  margin-top: 16px;
  grid-template-rows: auto auto 50px auto auto 50px;

  .inputPregnancy {
    font-size: 24px;
    text-align: center;
    height: 40px;
    width: 90px;
    color: #000;
    border-radius: 0px;

    .ant-input-number-input {
      text-align: center;
    }
  }

  .header {
    grid-column: 1 / 5;
    background-color: #9074bf;
    color: #ffffff;
    text-align: center;
    font-weight: 700;
    font-size: 22px;
  }

  .col.title {
    color: #9074bf;
    &.center {
      text-align: center;
    }
  }
  .col.value {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 18px;
    &.center {
      justify-content: center;
    }
  }

  .col.value.check {
    grid-row: span 2;
  }
`;

export const CustomCheckBox = styled(Checkbox)`
  width: 50px;
  height: 50px;

  & > * {
    width: 100% !important;
    height: 100% !important;
  }

  .ant-checkbox-inner {
    width: 100% !important;
    height: 100% !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(3) translate(0%, -50%);
    -ms-transform: rotate(45deg) scale(3) translate(0%, -50%);
    transform: rotate(45deg) scale(3) translate(0%, -50%);
    opacity: 1;
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: " ";
  }
`;
