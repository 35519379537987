import styled from "styled-components";
import { Card, Collapse } from "antd";

export const Container = styled(Card)`
  background: #fcfcfc !important;
  border-radius: 10px !important;
  width: 100%;
  margin-top: 24px !important;

  .ant-card-head {
    border-bottom: none !important;

    button {
      background: transparent !important;
      border: none !important;
      cursor: pointer;

      i {
        color: #8a50a5 !important;
      }
    }
  }
`;

export const Body = styled.div`
  div.inputStrong {
    color: #9074bf;
    font-size: 14px;
    font-weight: normal;
    display: flex;
    align-items: center;
    & > * {
      margin-left: 8px;
    }

    .ant-switch-checked {
      background-color: #a9c133;
    }
    .ant-switch-loading-icon,
    .ant-switch-checked::after {
      left: 110% !important;
      top: 0px;
      width: 20px;
      height: 20px;
      margin-left: -1px;
      -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    .ant-switch-loading-icon,
    .ant-switch::after {
      position: absolute;
      top: 0px;
      left: 0;
      width: 20px;
      height: 20px;
      border-radius: 15px;
      cursor: pointer;
      -webkit-transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      content: " ";
    }
  }
  label.formLabel {
    display: block;
    font-family: "Asap", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #8a50a5;
    margin-left: 15px;
    margin-bottom: 8px;

    #bovexoInfoIcon {
      margin-left: 3px;
    }

    &.disabled {
      color: #c4c4c4;
    }
    &.error {
      color: #d44c4c;
    }

    @media screen and (max-width: 769px) {
      margin-top: 16px;
    }
  }

  .ant-row-flex:not(:first-of-type) {
    margin-top: 17px;
  }
`;

export const CollapseContainer = styled(Collapse)`
  margin-top: 24px !important;
  background: #fcfcfc !important;
  border: none !important;

  .ant-collapse-item,
  .ant-collapse-header,
  .ant-collapse-content {
    border: none !important;
    background: #fcfcfc !important;
  }

  .ant-collapse-header {
    padding: 0px 16px !important;

    i {
      color: #8a50a5 !important;
    }

    font-family: "Asap", sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 18px !important;
    line-height: 40px !important    ;
    border-radius: 0 !important;
    background: #fcfcfc !important;
    border-bottom: 1px solid #c4c4c4 !important;
  }
`;

export const Footer = styled.div`
  padding-top: 24px;
  width: 100%;
`;
