import React, { useState } from "react";
import { withRouter } from "react-router-dom";

/* Redux libs */
import { useSelector } from "react-redux";

/* Styles */
import { Container } from "./styles";

import { Col, Row, Tabs } from "antd";

import AllowedComponentTo from "../../../components/utils/allowedComponentTo";

// Pages
import SupplementImport from "../../import/supplement";
import Supplement from "../../supplement";
import CollaboratorsSupplierGroup from "../collaborators";
import InvitesSupplierGroup from "../invites";

const SupplierGroupHome = (props) => {
  const [tabSelect, setTabSelect] = useState("1");
  const { translation } = useSelector((state) => state.app);

  const handleTabClick = (key, e) => {
    setTabSelect(key);
  };

  return (
    <Container>
      {/* Tabs */}
      <Row type="flex" style={{ marginTop: 10 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <AllowedComponentTo roles={["Input Supplier"]} hide>
            <Tabs
              type="card"
              defaultActiveKey="1"
              activeKey={tabSelect}
              onTabClick={handleTabClick}
            >
              <Tabs.TabPane
                tab={<span>{translation.parameters.tabs.supplement}</span>}
                key="1"
              >
                <Supplement />
              </Tabs.TabPane>

              <Tabs.TabPane tab={<span>{"Importações"}</span>} key="2">
                <SupplementImport />
              </Tabs.TabPane>

              <Tabs.TabPane tab={<span>{"Convites"}</span>} key="3">
                <InvitesSupplierGroup />
              </Tabs.TabPane>
              <Tabs.TabPane tab={<span>{"Colaboradores"}</span>} key="4">
                <CollaboratorsSupplierGroup />
              </Tabs.TabPane>
            </Tabs>
          </AllowedComponentTo>
          <AllowedComponentTo roles={["Input Supplier Collaborator"]} hide>
            <Tabs
              type="card"
              defaultActiveKey="1"
              activeKey={tabSelect}
              onTabClick={handleTabClick}
            >
              <Tabs.TabPane
                tab={<span>{translation.parameters.tabs.supplement}</span>}
                key="1"
              >
                <Supplement />
              </Tabs.TabPane>

              <Tabs.TabPane tab={<span>{"Importações"}</span>} key="2">
                <SupplementImport />
              </Tabs.TabPane>
            </Tabs>
          </AllowedComponentTo>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(SupplierGroupHome);
