export const Types = {
  RESET_DATA: "dietStrategy/RESET_DATA",
  GET_ALL_DIETSTRATEGY: "dietStrategy/GET_ALL_DIETSTRATEGY",
  GET_ALL_DIETSTRATEGY_SUCCESS: "dietStrategy/GET_ALL_DIETSTRATEGY_SUCCESS",
  GET_ALL_DIETSTRATEGY_ERROR: "dietStrategy/GET_ALL_DIETSTRATEGY_ERROR",
  GET_DIETSTRATEGY: "dietStrategy/GET_DIETSTRATEGY",
  GET_DIETSTRATEGY_SUCCESS: "dietStrategy/GET_DIETSTRATEGY_SUCCESS",
  GET_DIETSTRATEGY_ERROR: "dietStrategy/GET_DIETSTRATEGY_ERROR",
  SHOW_DRAWER_DIETSTRATEGY: "dietStrategy/SHOW_DRAWER_DIETSTRATEGY",
  HIDE_DRAWER_DIETSTRATEGY: "dietStrategy/HIDE_DRAWER_DIETSTRATEGY",
  SHOW_OR_HIDE_DRAWER_DIETSTRATEGYDETAIL:
    "dietStrategy/SHOW_OR_HIDE_DRAWER_DIETSTRATEGYDETAIL",
  SAVE_DIETSTRATEGY: "dietStrategy/SAVE_DIETSTRATEGY",
  SAVE_DIETSTRATEGY_SUCCESS: "dietStrategy/SAVE_DIETSTRATEGY_SUCCESS",
  SAVE_DIETSTRATEGY_ERROR: "dietStrategy/SAVE_DIETSTRATEGY_ERROR",
  INACTIVATE_ACTIVATE_DIETSTRATEGY:
    "dietStrategy/INACTIVATE_ACTIVATE_DIETSTRATEGY",
  INACTIVATE_ACTIVATE_DIETSTRATEGY_SUCCESS:
    "dietStrategy/INACTIVATE_ACTIVATE_DIETSTRATEGY_SUCCESS",
  INACTIVATE_ACTIVATE_DIETSTRATEGY_ERROR:
    "dietStrategy/INACTIVATE_ACTIVATE_DIETSTRATEGY_ERROR",
  SELECT_DIETSTRATEGY_PERIOD: "dietStrategy/SELECT_DIETSTRATEGY_PERIOD",
};

export const Creators = {
  indexDietStrategy: (
    groupId,
    farmId,
    page = 0,
    sorter = {},
    filters = "",
    withoutPagination = false,
    ids,
    signal = null
  ) => ({
    type: Types.GET_ALL_DIETSTRATEGY,
    payload: {
      groupId,
      farmId,
      page,
      sorter,
      filters,
      withoutPagination,
      ids,
      signal,
    },
  }),
  indexDietStrategySuccess: (data) => ({
    type: Types.GET_ALL_DIETSTRATEGY_SUCCESS,
    payload: {
      data,
    },
  }),
  indexDietStrategyError: (error) => ({
    type: Types.GET_ALL_DIETSTRATEGY_ERROR,
    payload: {
      error,
    },
  }),
  showDietStrategy: (groupId, farmId, id) => ({
    type: Types.GET_DIETSTRATEGY,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),
  showDietStrategySuccess: (data) => ({
    type: Types.GET_DIETSTRATEGY_SUCCESS,
    payload: {
      data,
    },
  }),
  showDietStrategyError: (error) => ({
    type: Types.GET_DIETSTRATEGY_ERROR,
    payload: {
      error,
    },
  }),
  showDrawer: (actionType = "edit") => ({
    type: Types.SHOW_DRAWER_DIETSTRATEGY,
    payload: {
      actionType,
    },
  }),
  hideDrawer: () => ({
    type: Types.HIDE_DRAWER_DIETSTRATEGY,
    payload: {},
  }),
  saveDietStrategy: (
    groupId,
    farmId,
    id = null,
    dietStrategy,
    saveOptions
  ) => ({
    type: Types.SAVE_DIETSTRATEGY,
    payload: {
      groupId,
      farmId,
      id,
      dietStrategy,
      saveOptions,
    },
  }),
  saveDietStrategySuccess: (data, saveOptions) => ({
    type: Types.SAVE_DIETSTRATEGY_SUCCESS,
    payload: { data, saveOptions },
  }),
  saveDietStrategyError: (error) => ({
    type: Types.SAVE_DIETSTRATEGY_ERROR,
    payload: { error },
  }),
  selectDietStrategyPeriod: (data) => ({
    type: Types.SELECT_DIETSTRATEGY_PERIOD,
    payload: { data },
  }),
  showOrHideDietStrategyDetail: () => ({
    type: Types.SHOW_OR_HIDE_DRAWER_DIETSTRATEGYDETAIL,
    payload: {},
  }),
  inactivateOrActivateDietStrategy: (
    groupId,
    farmId,
    id,
    action = "activate" | "inactivate",
    dietStrategy
  ) => ({
    type: Types.INACTIVATE_ACTIVATE_DIETSTRATEGY,
    payload: {
      groupId,
      farmId,
      id,
      action,
      dietStrategy,
    },
  }),
  inactivateOrActivateDietStrategySuccess: () => ({
    type: Types.INACTIVATE_ACTIVATE_DIETSTRATEGY_SUCCESS,
    payload: {},
  }),
  inactivateOrActivateDietStrategyError: (error) => ({
    type: Types.INACTIVATE_ACTIVATE_DIETSTRATEGY_ERROR,
    payload: { error },
  }),
};

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  dietStrategyData: null,
  dietStrategyPeriodSelected: null,
  drawerVisible: false,
  drawerDetailsDietStrategy: false,
  error: {},
};

export default function dietStrategy(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_ALL_DIETSTRATEGY:
      return { ...state, isLoading: true };
    case Types.GET_ALL_DIETSTRATEGY_SUCCESS:
      return { ...state, isLoading: false, data: action.payload.data };
    case Types.GET_ALL_DIETSTRATEGY_ERROR:
      return {
        ...state,
        isLoading: false,
        data: null,
        error: action.payload.error,
      };
    case Types.GET_DIETSTRATEGY:
      return { ...state, drawerDetailsDietStrategy: true };
    case Types.GET_DIETSTRATEGY_SUCCESS:
      return {
        ...state,
        dietStrategyData: action.payload.data,
      };
    case Types.GET_DIETSTRATEGY_ERROR:
      return {
        ...state,
        dietStrategyData: null,
        error: action.payload.error,
      };
    case Types.SHOW_DRAWER_DIETSTRATEGY:
      return {
        ...state,
        drawerVisible: true,
        dietStrategyData:
          action.payload.actionType === "new" ? null : state.dietStrategyData,
      };
    case Types.HIDE_DRAWER_DIETSTRATEGY:
      return { ...state, drawerVisible: false, dietStrategyData: null };
    case Types.SAVE_DIETSTRATEGY:
      return { ...state, isLoading: true };
    case Types.SAVE_DIETSTRATEGY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dietStrategyData: null,
        drawerVisible: action.payload.saveOptions,
      };
    case Types.SAVE_DIETSTRATEGY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case Types.INACTIVATE_ACTIVATE_DIETSTRATEGY:
      return { ...state, isLoading: true };
    case Types.INACTIVATE_ACTIVATE_DIETSTRATEGY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case Types.INACTIVATE_ACTIVATE_DIETSTRATEGY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case Types.SELECT_DIETSTRATEGY_PERIOD:
      return {
        ...state,
        dietStrategyPeriodSelected: action.payload.data,
      };
    case Types.SHOW_OR_HIDE_DRAWER_DIETSTRATEGYDETAIL:
      return {
        ...state,
        drawerDetailsDietStrategy: !state.drawerDetailsDietStrategy,
      };
    default:
      return state;
  }
}
