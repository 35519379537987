import React from "react";
import { call, put } from "redux-saga/effects";

import { Modal, notification } from "antd";
import { doAuthentication } from "../../services/authService";
import { getAppVersion } from "../../services/generalParameterService";

import { Creators as AppActions } from "../ducks/app";
import { storeUserLogAccess } from "../../services/userLogAccessService";

export function* authenticate(action) {
  let authData = null;
  let appVersion = null;
  try {
    const { data } = yield call(doAuthentication, action.payload);
    authData = data;
  } catch (error) {
    try {
      if (error.response.status === 401) {
        if (
          error.response.data?.error_description?.includes("User is disabled")
        ) {
          notification.warning({
            message:
              'Seu usuário não está ativo. Se você for um usuário "Experimental", aguarde o seu ambiente está sendo criado e logo o seu usuário será ativado. Caso contrário, entre em contato conosco.',
          });
        }
        if (
          error.response.data?.error_description?.includes(
            "User account is locked"
          )
        ) {
          Modal.error({
            width: 600,
            title: <strong style={{ fontSize: 22 }}>Atenção!</strong>,
            content: (
              <div>
                <p style={{ fontSize: 16 }}>
                  Para acesso, entrar em contato com o Dep. Comercial da BovExo.
                </p>
                <p style={{ fontSize: 16 }}>
                  Contatos: comercial@bovexo.com / +55 (11) 4210-6098
                </p>
              </div>
            ),
            centered: true,
          });
        } else {
          notification.error({
            message: "Senha ou usuário incorretos",
          });
        }
      } else {
        notification.error({
          message: "Erro interno no servidor de autenticação",
        });
      }
      yield put(AppActions.authenticate_error(error));
    } catch (error) {
      notification.error({
        message: "Erro interno no servidor de autenticação",
      });
      yield put(AppActions.authenticate_error(error));
    }
  }
  try {
    const {
      data: { results: parameters },
    } = yield call(getAppVersion);
    const { value } = parameters;
    appVersion = value;
  } catch (error) {}
  if (authData !== null) {
    // Log user Access
    yield call(storeUserLogAccess, authData.access_token);

    yield put(AppActions.authenticate_success(authData, appVersion));
  }
}
