import React, { useState } from "react";
import { useCallback } from "react";
import { createContext } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  findAll,
  findAllAnimalsForApplyReproductiveProtocol,
} from "../../services/animalFarmReproductiveProtocolService";

export const ANIMAL_FARM_REPRODUCTIVE_PROTOCOL_FORM_INITIAL_STATE = {
  id: null,
  groupAnimals: [],
};

export const AnimalFarmReproductiveProtocolContext = createContext({
  dataList: [],
  isLoadingDataList: false,
  data: null,
  isDrawerGroupAnimalsByProtocolVisible: false,
  selectedAnimalsKeys: [],
  allAnimals: [],
  animalsSelectedAndGrouped: [],
  pagination: {
    page: 0,
    sorter: null,
    filters: null,
    size: 10,
    ids: null,
    tableSorters: null,
    tableFilters: null,
  },
});

export const AnimalFarmReproductiveProtocolContextProvider = ({ children }) => {
  const [dataList, setDataList] = useState([]);
  const [data, setData] = useState(null);
  const [allAnimals, setAllAnimals] = useState(null);
  const [pagination, setPagination] = useState({
    page: 0,
    sorter: null,
    filters: null,
    size: 10,
    ids: null,
    tableSorters: null,
    tableFilters: null,
  });
  const [isLoadingAnimals, setIsLoadingAnimals] = useState(false);
  const [animalsSelectedAndGrouped, setAimalsSelectedAndGrouped] = useState([]);
  const [selectedAnimalsKeys, setSelectedAnimalsKeys] = useState([]);
  const [isLoadingDataList, setIsLoadingDataList] = useState(false);
  const [
    isDrawerGroupAnimalsByProtocolVisible,
    setIsDrawerGroupAnimalsByProtocolVisible,
  ] = useState(false);
  const history = useHistory();

  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const fetchData = useCallback(async () => {
    setIsLoadingDataList(true);
    try {
      const {
        data: { results },
      } = await findAll({ groupId, farmId });
      setDataList(results);
    } catch (error) {
    } finally {
      setIsLoadingDataList(false);
    }
  }, [farmId, groupId]);

  const switchIsLoadingAnimals = () => {
    setIsLoadingAnimals((old) => !old);
  };

  const getAnimals = useCallback(
    async (
      justIds = false,
      paginationParam = null,
      animalsSelectedAndGroupedParam = null
    ) => {
      const { page, sorter, filters, size } = paginationParam
        ? paginationParam
        : pagination;

      const animalsSelected = animalsSelectedAndGroupedParam
        ? animalsSelectedAndGroupedParam
        : animalsSelectedAndGrouped;

      setIsLoadingAnimals(true);

      let newFilters = filters;

      // Set animals ids
      if (animalsSelected.length > 0) {
        let filter = "";

        filter =
          newFilters === "" || newFilters == null
            ? `id not in (`
            : `${newFilters};id not in (`;

        animalsSelected.forEach((id) => {
          filter = `${filter}'${id}',`;
        });

        filter = `${filter})`;
        filter = filter.replace(",)", ")");
        newFilters = filter;
      }

      try {
        const {
          data: { results },
        } = await findAllAnimalsForApplyReproductiveProtocol({
          groupId,
          farmId,
          page,
          sorter,
          filters: newFilters,
          size,
          withoutPagination: false,
          justIds,
        });
        setAllAnimals(results);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingAnimals(false);
      }
    },
    [farmId, groupId, pagination, animalsSelectedAndGrouped]
  );

  const updatePagination = useCallback((pagination) => {
    setPagination(pagination);
  }, []);

  const updateSelectedAnimalsKeys = useCallback(
    (newSelectedAnimalsKeys = []) => {
      setSelectedAnimalsKeys(newSelectedAnimalsKeys);
    },
    []
  );

  const updateAnimalsSelectedAndGrouped = useCallback((animals = []) => {
    if (animals.length > 0) {
      setAimalsSelectedAndGrouped((prevState) => [...prevState, ...animals]);
    } else {
      setAimalsSelectedAndGrouped([]);
    }
  }, []);

  const goToForm = useCallback(
    (data = null) => {
      if (data) {
        history.push(
          `/admin/managements/reproductive/protocol/edit/${data?.id}`
        );
      } else {
        history.push(`/admin/managements/reproductive/protocol/new`);
      }
    },
    [history]
  );

  const openDrawer = useCallback((data = null) => {}, []);

  const closeDrawer = useCallback(() => {
    setData(null);
    setIsDrawerGroupAnimalsByProtocolVisible(false);
  }, []);

  return (
    <AnimalFarmReproductiveProtocolContext.Provider
      value={{
        dataList,
        data,
        isLoadingDataList,
        isDrawerGroupAnimalsByProtocolVisible,
        pagination,
        allAnimals,
        isLoadingAnimals,
        selectedAnimalsKeys,
        animalsSelectedAndGrouped,
        switchIsLoadingAnimals,
        fetchData,
        goToForm,
        openDrawer,
        closeDrawer,
        getAnimals,
        updatePagination,
        updateSelectedAnimalsKeys,
        updateAnimalsSelectedAndGrouped,
      }}
    >
      {children}
    </AnimalFarmReproductiveProtocolContext.Provider>
  );
};
