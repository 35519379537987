import styled from "styled-components";

export const Container = styled.button`
  position: relative;

  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #94cf1c;

  margin: 0 8px;

  padding: 8px 16px;

  background: #ffffff;
  border: 1px solid #94cf1c;
  box-sizing: border-box;

  filter: drop-shadow(0px 6px 6px rgba(74, 133, 174, 0.412));
  cursor: pointer;

  &:disabled {
    border-color: #adadad;
    color: #adadad;
    cursor: disabled;
  }
`;
