import styled from "styled-components";
import { Tooltip, Spin, Button, Col, Row, Radio, Select } from "antd";

export const Container = styled.div`
  width: 100% !important;
  min-width: 100% !important;
  height: 100% !important;
  min-height: 100% !important;
  padding-bottom: 50px;
  .divChart {
    height: 500px;
    margin-top: 20px;
  }
  ul.listAnimalsStatus {
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    strong {
      margin-left: 3px;
    }
    li + li {
      margin-left: 10px;
    }
  }
`;

export const CustomSpin = styled(Spin)`
  width: 100% !important;
  &.ant-spin-nested-loading {
    width: 100% !important;
    .ant-spin-container {
      width: 100% !important;
    }
  }
`;

export const SelectLabelAnimalsBox = styled(Select)`
  margin-left: 10px !important;
  width: 150px;
  .ant-select-selection {
    border-color: #4b4b4b;
    &:hover,
    &:focus,
    &:hover,
    &:active {
      border-color: #4b4b4b !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
    }
  }
  svg {
    fill: #4b4b4b;
  }
`;

export const ButtonFilter = styled(Button)`
  margin-left: 10px;
  background-color: #81729a !important;
  color: #ffffff !important;
  border-color: #ffffff !important;
  transition: all 0.3s ease-in-out !important;
  &:hover,
  &:focus,
  &:active {
    color: #ffffff !important;
    border-color: #ffffff !important;
  }

  &.filled,
  &.filled:hover,
  &.filled:focus,
  &.filled:active {
    background: #684e94;
    color: #fff !important;
  }
`;

export const RowFilter = styled(Row)`
  width: 100%;
  margin: 10px 0px;
  padding: 10px 10px 10px 15px;
  border-radius: 10px !important;
  background: #fff !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
`;

export const RadioButtonCustom = styled(Radio.Button)`
  margin-top: 6px !important;
  border-radius: 15px !important;
  background: transparent !important;
  border: 1px solid #4b4b4b !important;
  border-color: #4b4b4b !important;

  span {
    font-family: Asap !important;
    font-weight: normal !important;
    font-size: 14px !important;
    text-align: left !important;
    color: #4b4b4b !important;
  }

  &:first-of-type {
    margin-left: 15px;
  }

  &:not(:first-of-type) {
    margin-left: 4px;
  }

  &.ant-radio-button-wrapper-disabled {
    border-color: #c4c4c4 !important;
    span {
      color: #c4c4c4 !important;
    }
  }

  &.ant-radio-button-wrapper-checked {
    &:first-child,
    &:not(:first-child) {
      border-color: #4b4b4b !important;
    }
    color: #fff !important;
    background: #4b4b4b !important;
    border-color: #4b4b4b !important;

    &.ant-radio-button-wrapper-disabled {
      border-color: #c4c4c4 !important;
      background: #c4c4c4 !important;
    }

    span {
      font-family: Asap !important;
      font-weight: normal !important;
      font-size: 14px !important;
      text-align: left !important;
      color: #fff !important;
    }
  }
  &:not(:first-child)::before {
    visibility: hidden !important;
  }
`;

export const ColFilter = styled(Col)`
  .ant-select {
    margin-left: 10px;
  }
  .ant-input {
    margin-left: 10px;
    margin-right: 10px;
  }
  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 50px !important;
  }
  strong:not(:last-of-type) {
    &::after {
      content: "-";
      color: #4b4b4b;
      width: 50px;
      margin: 0px 10px;
    }
  }
`;

export const CardAnimalsChart = styled.div`
  display: flex;
  width: 100% !important;
  padding: 10px 0px !important;

  div.titleGroup {
    padding: 5px;

    svg {
      margin: 0 5px;
    }

    p {
      font-family: "Asap", sans-serif;
      font-size: 12px;
      font-weight: bold;
      font-style: italic;
      margin: 0;
    }
  }

  button.eyeButton {
    background: transparent;
    border: none;
    margin-left: 3px;
    cursor: pointer;
  }
`;

export const BoxRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
`;

export const Box = styled.div`
  margin: 0px 4px 8px 4px;
  height: 35px;
  min-width: 35px;
  padding: 8px 4px 6px 4px;
  position: relative;
  background: #6c578f !important;
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  border-radius: 3px;
  color: #fff;

  &:hover {
    cursor: pointer;
  }

  &.medium {
    background: #85b6a6 !important;
  }

  &.bad {
    background: #b16060 !important;
  }

  &.none {
    background: #697582 !important;
  }

  &.notSelected {
    opacity: 0.4;
  }

  &.selected {
    border: 1px dashed #4b4b4b;
    transform: scale(1.2);
  }
`;

export const ButtonPin = styled.button`
  position: absolute;
  display: none;
  left: 88%;
  bottom: 76%;
  padding: 0;
  width: auto;
  height: auto;
  background: transparent;
  border: none;
  color: #4b4b4b;
  &.visible {
    display: block;
  }
`;

export const CustomTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    background-color: #fff !important;
  }
`;

export const LegendTitle = styled.span`
  font-family: "Asap";
  font-weight: bold;
  font-style: italic;
  font-size: 14px;
  text-align: left;
  color: #a5a5a5;
`;

export const Square = styled.div`
  width: ${(props) => (props.width != null ? props.width : "9px !important")};
  height: ${(props) =>
    props.height != null ? props.height : "9px !important"};
  background: #6c578f;
  margin-right: 8px;

  &.medium {
    background: #85b6a6;
  }

  &.bad {
    background: #b16060;
  }

  &.none {
    background: #697582;
  }
`;

export const ColCardCurve = styled(Col)`
  border-radius: 5px;
  background: #f5f5f5;
  box-shadow: 0px 3px 6px rgba(129, 114, 154, 0.16);
  padding: 8px 5px 8px 18px !important;
`;

export const WeightRankingContainer = styled.div`
  margin: 20px 0;
  flex: 1;

  div.valuesContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.titleGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  button.editButton {
    background: transparent;
    border: 0;
    cursor: pointer;
    margin-left: 5px;
  }

  #bovexoInfoIcon {
    margin-left: 5px;
  }

  span {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;

    &.values {
      display: flex;
      align-items: center;

      & + .values {
        margin-left: 30px;
      }
    }

    svg {
      margin-left: 6px;
    }

    strong {
      margin-left: 3px;
    }

    div.circle {
      width: 10px;
      height: 10px;
      margin-right: 6px;
      border-radius: 50%;
      &.red {
        background: linear-gradient(90deg, #b16060 50%, #9b4d4d 50%);
      }
      &.green {
        background: linear-gradient(90deg, #85b6a6 50%, #679c8b 50%);
      }
      &.purple {
        background: linear-gradient(90deg, #6c578f 50%, #5d4880 50%);
      }
    }
  }
`;

export const ButtonCurveParameter = styled.button`
  width: 95%;
  margin-left: 5px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #684e94;
  box-shadow: 0px 3px 6px rgba(129, 114, 154, 0.16);
  font-family: Asap;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #684e94;
  padding: 4px 10px;
`;

export const EditLotWeightRankingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;

    &.values {
      display: flex;
      align-items: center;
      margin-right: 30px;

      &:last-of-type {
        margin-right: 0px;
      }
    }

    svg {
      margin-left: 6px;
    }

    strong {
      margin-left: 3px;
      &.gray {
        color: #a5a5a5;
      }
    }

    div.circle {
      width: 10px;
      height: 10px;
      margin-right: 6px;
      border-radius: 50%;
      &.red {
        background: linear-gradient(90deg, #b16060 50%, #9b4d4d 50%);
      }
      &.green {
        background: linear-gradient(90deg, #85b6a6 50%, #679c8b 50%);
      }
      &.purple {
        background: linear-gradient(90deg, #6c578f 50%, #5d4880 50%);
      }
    }

    input {
      width: 100px;
      margin-left: 5px;
    }
  }
`;
