import React from "react";
import { SiConvertio } from "react-icons/si";

import { ConversionStatusContainer } from "./styles";
import { Icon, Tooltip } from "antd";

export function ConversionStatus({ status }) {
  return (
    <ConversionStatusContainer>
      {status === "loading" && <Icon type="sync" spin />}
      {status === "success" && (
        <Tooltip title="Conversão realizada com base no fechamento da taxa cambial D-1(Dia atual menos 1 dia)">
          <SiConvertio size={20} color="#33c162" />
        </Tooltip>
      )}
      {status === "error" && (
        <Tooltip title="Erro ao converter o valor, tente atualizar a tela, caso o erro persita entre em contato com nosso suporte.">
          <SiConvertio size={20} color="#d44c4c" />
        </Tooltip>
      )}
    </ConversionStatusContainer>
  );
}
