export const Types = {
  RESET_DATA: "picket/RESET_DATA",
  GET_ALL_PICKETS: "picket/GET_ALL_PICKETS",
  GET_ALL_PICKETS_SUCCESS: "picket/GET_ALL_PICKETS_SUCCESS",
  GET_ALL_PICKETS_ERROR: "picket/GET_ALL_PICKETS_ERROR",
  GET_PICKET: "picket/GET_PICKET",
  GET_PICKET_SUCCESS: "picket/GET_PICKET_SUCCESS",
  GET_PICKET_ERROR: "picket/GET_PICKET_ERROR",
  SAVE_PICKET: "picket/SAVE_PICKET",
  SAVE_PICKET_SUCCESS: "picket/SAVE_PICKET_SUCCESS",
  SAVE_PICKET_ERROR: "picket/SAVE_PICKET_ERROR",
  SHOW_DRAWER_PICKET: "picket/SHOW_DRAWER_PICKET",
  HIDE_DRAWER_PICKET: "picket/HIDE_DRAWER_PICKET",
  SHOW_DRAWER_ALLOCATE_LOT: "picket/SHOW_DRAWER_ALLOCATE_LOT",
  HIDE_DRAWER_ALLOCATE_LOT: "picket/HIDE_DRAWER_ALLOCATE_LOT",
  ADD_LOT_TO_PICKET: "picket/ADD_LOT_TO_PICKET",
  ADD_LOT_TO_PICKET_SUCCESS: "picket/ADD_LOT_TO_PICKET_SUCCESS",
  ADD_LOT_TO_PICKET_ERROR: "picket/ADD_LOT_TO_PICKET_ERROR",
  UPDATE_PICKET_IN_LIST: "picket/UPDATE_PICKET_IN_LIST",
  SHOW_DRAWER_DETAIL_PICKET: "picket/SHOW_DRAWER_DETAIL_PICKET",
  HIDE_DRAWER_DETAIL_PICKET: "picket/HIDE_DRAWER_DETAIL_PICKET",
};

export const Creators = {
  indexPicket: (
    groupId,
    farmId,
    page = 0,
    sorter = {},
    filters = "",
    size = 10,
    ids = null
  ) => ({
    type: Types.GET_ALL_PICKETS,
    payload: {
      groupId,
      farmId,
      page,
      sorter,
      filters,
      size,
      ids,
    },
  }),
  indexPicketSuccess: (data) => ({
    type: Types.GET_ALL_PICKETS_SUCCESS,
    payload: {
      data,
    },
  }),
  indexPicketError: (error) => ({
    type: Types.GET_ALL_PICKETS_ERROR,
    payload: {
      error,
    },
  }),
  showPicket: (groupId, farmId, id) => ({
    type: Types.GET_PICKET,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),
  showPicketSuccess: (picket, sustenanceCapacityData, picketLotHistories) => ({
    type: Types.GET_PICKET_SUCCESS,
    payload: {
      data: picket,
      sustenanceCapacityData,
      picketLotHistories,
    },
  }),
  showPicketError: (error) => ({
    type: Types.GET_PICKET_ERROR,
    payload: {
      error,
    },
  }),
  savePicket: (groupId, farmId, id = null, values, saveOptions) => ({
    type: Types.SAVE_PICKET,
    payload: {
      groupId,
      farmId,
      id,
      values,
      saveOptions,
    },
  }),
  savePicketSuccess: (data, sustenanceCapacityData, saveOptions) => ({
    type: Types.SAVE_PICKET_SUCCESS,
    payload: {
      data,
      sustenanceCapacityData,
      saveOptions,
    },
  }),
  savePicketError: (error) => ({
    type: Types.SAVE_PICKET_ERROR,
    payload: {
      error,
    },
  }),
  showDrawer: (actionType = "edit", id = null) => ({
    type: Types.SHOW_DRAWER_PICKET,
    payload: {
      actionType,
      id,
    },
  }),
  hideDrawer: () => ({
    type: Types.HIDE_DRAWER_PICKET,
    payload: {},
  }),
  showDrawerAllocateLot: () => ({
    type: Types.SHOW_DRAWER_ALLOCATE_LOT,
    payload: {},
  }),
  hideDrawerAllocateLot: () => ({
    type: Types.HIDE_DRAWER_ALLOCATE_LOT,
    payload: {},
  }),
  addLotToPicket: (groupId, farmId, id, lotId, body) => ({
    type: Types.ADD_LOT_TO_PICKET,
    payload: {
      groupId,
      farmId,
      id,
      lotId,
      body,
    },
  }),
  addLotToPicketSuccess: (data) => ({
    type: Types.ADD_LOT_TO_PICKET_SUCCESS,
    payload: {
      data,
    },
  }),
  addLotToPicketError: (error) => ({
    type: Types.ADD_LOT_TO_PICKET_ERROR,
    payload: {
      error,
    },
  }),
  updatePicketInList: (id, picket) => ({
    type: Types.UPDATE_PICKET_IN_LIST,
    payload: {
      id,
      picket,
    },
  }),
  showDrawerDetailPicket: (id) => ({
    type: Types.SHOW_DRAWER_DETAIL_PICKET,
    payload: {
      id,
    },
  }),
  hideDrawerDetailPicket: () => ({
    type: Types.HIDE_DRAWER_DETAIL_PICKET,
    payload: {},
  }),
};

const INITIAL_STATE = {
  data: null,
  pagination: {
    page: 0,
    sorter: {},
    filters: "",
    size: 10,
  },
  error: null,
  isLoading: false,
  isLoadingPicketData: false,
  picketData: null,
  sustenanceCapacityData: null,
  picketLotHistories: [],
  drawerVisible: false,
  drawerAllocateLot: false,
  drawerDetailPicketVisible: false,
  idPicketDetailsAndEdit: null,
};

export default function picket(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_ALL_PICKETS:
      const { page, sorter, filters, size } = action.payload;
      return {
        ...state,
        pagination: {
          page,
          sorter,
          filters,
          size,
        },
        isLoading: true,
      };
    case Types.GET_ALL_PICKETS_SUCCESS:
      const { data } = action.payload;
      return { ...state, data, isLoading: false };
    case Types.GET_ALL_PICKETS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        data: null,
      };
    case Types.GET_PICKET:
      return {
        ...state,
        isLoadingPicketData: true,
        picketLotHistories: [],
        picketData: null,
        sustenanceCapacityData: null,
      };
    case Types.GET_PICKET_SUCCESS:
      return {
        ...state,
        isLoadingPicketData: false,
        picketData: action.payload.data,
        sustenanceCapacityData: action.payload.sustenanceCapacityData,
        picketLotHistories: action.payload.picketLotHistories,
      };
    case Types.GET_PICKET_ERROR:
      return {
        ...state,
        isLoadingPicketData: false,
        picketData: null,
        error: action.payload.error,
        picketLotHistories: [],
        sustenanceCapacityData: null,
      };
    case Types.SAVE_PICKET:
      return { ...state, isLoadingPicketData: true };
    case Types.SAVE_PICKET_SUCCESS:
      return {
        ...state,
        isLoadingPicketData: false,
        drawerVisible: action.payload.saveOptions,
      };
    case Types.SAVE_PICKET_ERROR:
      return {
        ...state,
        error: action.payload.error,
        isLoadingPicketData: false,
      };
    case Types.SHOW_DRAWER_PICKET:
      return {
        ...state,
        drawerVisible: true,
        picketData: null,
        sustenanceCapacityData: null,
        isLoadingPicketData:
          action.payload.actionType === "edit" && action.payload.id !== null
            ? true
            : false,
        idPicketDetailsAndEdit: action.payload.id,
      };
    case Types.HIDE_DRAWER_PICKET:
      return {
        ...state,
        drawerVisible: false,
        picketData: null,
        sustenanceCapacityData: null,
        isLoadingPicketData: false,
        idPicketDetailsAndEdit: null,
      };
    case Types.SHOW_DRAWER_ALLOCATE_LOT:
      return {
        ...state,
        drawerAllocateLot: true,
      };
    case Types.HIDE_DRAWER_ALLOCATE_LOT:
      return { ...state, drawerAllocateLot: false, picketData: null };
    case Types.ADD_LOT_TO_PICKET:
      return { ...state, isLoading: true };
    case Types.ADD_LOT_TO_PICKET_SUCCESS:
      return {
        ...state,
        drawerAllocateLot: false,
        picketData: null,
        isLoading: false,
      };
    case Types.ADD_LOT_TO_PICKET_ERROR:
      return { ...state, error: action.payload.error, isLoading: false };
    case Types.UPDATE_PICKET_IN_LIST:
      let { data: newData } = state;
      let foundIndex = newData.content.findIndex(
        (c) => c.id === action.payload.id
      );
      newData.content[foundIndex] = action.payload.picket;
      return { ...state, data: newData };
    case Types.SHOW_DRAWER_DETAIL_PICKET:
      return {
        ...state,
        idPicketDetailsAndEdit: action.payload.id,
        isLoadingPicketData: true,
        drawerDetailPicketVisible: true,
        drawerVisible: false,
      };
    case Types.HIDE_DRAWER_DETAIL_PICKET:
      return {
        ...state,
        drawerDetailPicketVisible: false,
        picketData: null,
        isLoadingPicketData: false,
        drawerVisible: false,
        idPicketDetailsAndEdit: null,
      };
    default:
      return state;
  }
}
