import React, { useRef } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Creators as UserTriggerActions } from "../../../../store/ducks/userTrigger";
import { Col, DatePicker, Modal, Row, Select, Spin } from "antd";

import { Container } from "./styles";
import ButtonStandard from "../../../../components/utils/button";
import moment from "moment";

const validationSchema = Yup.object({
  startDate: Yup.string().required(),
  finalDate: Yup.string().required(),
  option: Yup.string().required(),
});

function UserTriggerCostModal() {
  const formikRef = useRef(null);
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const {
    isVisibleModalTriggerCost: modalVisible,
    isLoadingRequest,
    requestUserTriggerCost: form,
  } = useSelector((state) => state.userTrigger);
  const dispatch = useDispatch();
  const handleCancel = () => {
    formikRef.current.resetForm({});
    dispatch(UserTriggerActions.showOrHideModalTriggerCost());
  };
  const handleSubmit = (values) => {
    dispatch(
      UserTriggerActions.saveUserTrigger(
        groupId,
        farmId,
        "AnimalDailyCost",
        values
      )
    );
  };
  return (
    <Modal
      visible={modalVisible}
      centered
      closable={false}
      footer={null}
      title={`Recalcular Custo de Produção`}
    >
      <Container>
        <Spin spinning={isLoadingRequest}>
          <Formik
            ref={formikRef}
            enableReinitialize={true}
            initialValues={form}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            render={({
              values,
              errors,
              handleSubmit,
              setFieldValue,
              submitCount,
            }) => (
              <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="body">
                  {Object.entries(errors).length > 0 && submitCount > 0 && (
                    <Row type="flex" justify="center" align="middle">
                      <label className="error">
                        {translation.error.formError}
                      </label>
                    </Row>
                  )}
                  <Row type="flex" justify="start" gutter={8}>
                    <Col span={24}>
                      <Row>
                        <label
                          className={
                            (errors.startDate || errors.finalDate) &&
                            submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          Período*
                        </label>
                      </Row>
                      <Row type="flex" gutter={8} align="middle">
                        <Col span={8}>
                          <DatePicker
                            name="startDate"
                            format={"DD/MM/YYYY"}
                            placeholder={
                              translation.defaultDatePickerPlaceholder
                            }
                            value={
                              values.startDate
                                ? moment(values.startDate)
                                : undefined
                            }
                            disabledDate={(currentDate) =>
                              currentDate.isAfter(moment())
                            }
                            allowClear={false}
                            onChange={(date) =>
                              setFieldValue("startDate", date)
                            }
                          />
                        </Col>
                        <Col span={2}>até</Col>
                        <Col span={8}>
                          <DatePicker
                            name="finalDate"
                            format={"DD/MM/YYYY"}
                            placeholder={
                              translation.defaultDatePickerPlaceholder
                            }
                            value={
                              values.finalDate
                                ? moment(values.finalDate)
                                : undefined
                            }
                            disabledDate={(currentDate) =>
                              currentDate.isBefore(values.startDate) ||
                              currentDate.isAfter(moment())
                            }
                            allowClear={false}
                            onChange={(date) =>
                              setFieldValue("finalDate", date)
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" justify="start" gutter={8}>
                    <Col span={24}>
                      <Row>
                        <label
                          className={
                            errors.option && submitCount > 0 ? "error" : ""
                          }
                        >
                          Tipo de custos*
                        </label>
                      </Row>
                      <Row>
                        <Select
                          name="option"
                          value={values.option || undefined}
                          onChange={(value) => setFieldValue("option", value)}
                        >
                          <Select.Option value={"Both"}>Ambos</Select.Option>
                          <Select.Option value={"JustFixedCost"}>
                            Administrativos/Operacionais (Custeio)
                          </Select.Option>
                          <Select.Option value={"JustDietCost"}>
                            Nutricionais
                          </Select.Option>
                        </Select>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div className="footer">
                  {/* Footer */}
                  <Row type="flex">
                    <Col span={24}>
                      <Row type="flex" justify="end" align="middle" gutter={8}>
                        <Col>
                          <ButtonStandard
                            type="button"
                            buttonType="type4"
                            size="s"
                            onClick={handleCancel}
                          >
                            {translation.buttons.cancel}
                          </ButtonStandard>
                        </Col>
                        <Col>
                          <ButtonStandard
                            type="submit"
                            buttonType="type1"
                            size="s"
                          >
                            {translation.buttons.generate}
                          </ButtonStandard>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </form>
            )}
          />
        </Spin>
      </Container>
    </Modal>
  );
}

export default UserTriggerCostModal;
