import { call, put } from "redux-saga/effects";
import { Creators as UserTriggerActions } from "../ducks/userTrigger";

/** Services */

import {
  getLastUserTriggerRequest,
  saveTriggerRequest,
} from "../../services/userTriggerService";

export function* saveUserTrigger(action) {
  try {
    const { groupId, farmId, triggerAction } = action.payload;
    yield call(saveTriggerRequest, action.payload);
    yield put(UserTriggerActions.saveUserTriggerSuccess(triggerAction));
    yield put(
      UserTriggerActions.getLastStatusUserTrigger(
        groupId,
        farmId,
        triggerAction
      )
    );
  } catch (error) {
    yield put(UserTriggerActions.saveUserTriggerError());
  }
}

export function* getLastUserTrigger(action) {
  const { triggerAction } = action.payload;
  try {
    const {
      data: { results },
    } = yield call(getLastUserTriggerRequest, action.payload);
    yield put(
      UserTriggerActions.getLastStatusUserTriggerSuccess(triggerAction, results)
    );
  } catch (error) {
    yield put(UserTriggerActions.getLastStatusUserTriggerError(triggerAction));
  }
}
