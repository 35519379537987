import moment from "moment";
import React, { useEffect, useState } from "react";
import { Async } from "react-async";
import { Link, useRouteMatch, withRouter } from "react-router-dom";
/** Redux libs */
import { useDispatch, useSelector } from "react-redux";
import { Creators as SaleScenarioActions } from "../../../store/ducks/saleScenario";
/** Components */
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Dropdown,
  Empty,
  Icon,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
  notification,
} from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import Column from "antd/lib/table/Column";
import SuccessIcon from "../../../components/drawers/pasture/displayPastureSeason/icons/success";
import DrawerSaleScenarioDetails from "../../../components/drawers/saleScenario/details";
import ConfirmActionModal from "../../../components/modals/confirmActionModal";
import BoxText from "../../../components/utils/boxText";
import BreadCrumbs from "../../../components/utils/breadCrumbs";
import ButtonStandard from "../../../components/utils/button";
import CheckIcon from "../../../components/utils/icons/check";
import ExcelIcon from "../../../components/utils/icons/excel";
import FavoriteIcon from "../../../components/utils/icons/favorite";
import Loading from "../../../components/utils/loading";
import MenuIcon from "../../../components/utils/table/icons/menu";
import { Container as MenuContainer } from "../../../components/utils/table/menu/styles";
import {
  ButtonCustom,
  ButtonExport,
  ButtonLockPrice,
  ButtonNewFarmSell,
  CardAllAnimalsSold,
  CardCalendar,
  CardCalendarDate,
  CardCalendarTitle,
  ColSaleScenarioInfo,
  ColTable,
  Container,
  HeaderContainer,
  PopConfirmCustom,
  TabSaleScenarioInfoButtons,
  TabSaleScenarioInfoButtonsContainer,
  TabSaleScenarioInfoContainer,
  Title,
} from "./styles";

/** Services */
import AlertIcon from "../../../components/drawers/picket/icons/alert";
import PriceLock from "../../../components/utils/icons/priceLock";
import { getLastDailyWeightReactAsync } from "../../../services/animalService";
import { numberMask } from "../../../services/helpersMethodsService";
import {
  destroySaleScenarioResultFarmSell,
  indexSaleScenarioResultFarmSell,
} from "../../../services/saleScenarioResultFarmSellService";
import {
  cancelSaleScenarioResultBaseline,
  exportXlsSaleScenarioResult,
  getAllSaleScenarioResultByIds,
  getLotAndDietStragySaleScenarioResultSuggest,
  setSaleScenarioResultBaseline,
  showAllSaleScenarioResultSellsAnimals,
  showSaleScenario,
  showSaleScenarioCommitmentsByParameter,
  showSaleScenarioLots,
  showSaleScenarioResultBySaleScenarioResultId,
  showSaleScenarioResultSells,
  showSaleScenarioResultSellsAnimals,
  updateSaleScenarioResult,
} from "../../../services/saleScenarioService";
import SaleScenarioDetailsDatesAndWeightPricesTab from "./tabs/datesAndWeightPrices";
import SaleScenarioDetailsDefaultDataInfoTab from "./tabs/defaultDataInfo";
import SaleScenarioDetailsParametersAndLotsTab from "./tabs/parametersAndLots";
import SaleScenarioDetailsPricesLocksTab from "./tabs/pricesLocks";

const SaleScenarioDetails = ({ history }) => {
  /** Variables */
  const { confirm } = Modal;
  const [isEmpty, setIsEmpty] = useState(false);
  const [customPanelStyle] = useState({
    borderRadius: 7,
    background: "#fff",
    boxShadow: "0px 0px 10px #d8d8d8",
    marginBottom: 15,
  });
  const [isLoadingSaleScenarioInfo, setIsLoadingSaleScenarioInfo] =
    useState(false);
  const [
    isLoadingSaleScenarioDietStrategySuggest,
    setIsLoadingSaleScenarioDietStrategySuggest,
  ] = useState(false);
  const [
    isLoadingSaleScenarioCommitments,
    setIsLoadingSaleScenarioCommitments,
  ] = useState(false);
  const [isLoadingSaleScenarioViolations, setIsLoadingSaleScenarioViolations] =
    useState(false);
  const [isLoadingSaleScenarioLots, setIsLoadingSaleScenarioLots] =
    useState(false);
  const [
    isLoadingSaleScenarioResultSellAnimals,
    setIsLoadingSaleScenarioResultSellAnimals,
  ] = useState(false);
  const [resultsData, setResultsData] = useState([]);
  const [saleScenarioInfo, setSaleScenarioInfo] = useState(null);
  const [saleScenarioDietStrategySuggest, setSaleScenarioDietStrategySuggest] =
    useState(null);
  const [saleScenarioCommitments, setSaleScenarioCommitments] = useState(null);
  const [saleScenarioLots, setSaleScenarioLots] = useState(null);
  const [saleScenarioViolations, setSaleScenarioViolations] = useState(null);
  const [expandColTable, setExpandColTable] = useState(false);
  const [tabActive, setTabActive] = useState(1);
  const [formatDate] = useState("DD/MM/YYYY");
  const [weightFilterOperator, setWeightFilterOperator] = useState("<");
  const [priceFilterOperator, setPriceFilterOperator] = useState("<");
  const [marginFilterOperator, setMarginFilterOperator] = useState("<");
  const [modalConfirmBaselineVisible, setModalConfirmBaselineVisible] =
    useState(false);
  /** Results variables */
  const [saleScenarioResult, setSaleScenarioResult] = useState(null);
  const [listOfSaleScenarioResultSell, setListOfSaleScenarioResultSell] =
    useState(null);
  const [
    listOfSaleScenarioResultFarmSell,
    setListOfSaleScenarioResultFarmSell,
  ] = useState(null);
  const [listOfSaleScenarioResultAnimal, setListOfSaleScenarioResultAnimal] =
    useState(null);
  const [listOfResultSellSelected, setListOfResultSellSelected] = useState([]);
  const [listOfResultSellSelectedIds, setListOfResultSellSelectedIds] =
    useState(null);
  const [listOfResultSellAnimalsSelected, setListOfResultSellAnimalsSelected] =
    useState([]);
  const [
    listOfResultSellAnimalsSelectedKeys,
    setListOfResultSellAnimalsSelectedKeys,
  ] = useState([]);
  const [editResultID, setEditResultID] = useState(false);

  /** Variable props */
  const {
    params: { id: saleScenarioId, resultId: saleScenarioResultId },
  } = useRouteMatch();

  /** Variable Redux */
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  /** Effects */
  /** Get SaleScenario info */
  useEffect(() => {
    async function fetchSellAnimals(
      ids = null,
      page = 0,
      sorter = {},
      filters = "",
      size = 10
    ) {
      let sellIds = null;

      if (ids != null) sellIds = ids;

      const search =
        filters.trim() === ""
          ? `r.saleScenarioResultFarmSellId is null; r.saleScenarioResultSellId in (${sellIds})`
          : filters +
            `;r.saleScenarioResultFarmSellId is null;r.saleScenarioResultSellId in (${sellIds})`;
      setIsLoadingSaleScenarioResultSellAnimals(true);
      try {
        const {
          data: { results },
        } = await showSaleScenarioResultSellsAnimals({
          groupId,
          farmId,
          page,
          sorter,
          filters: search,
          size,
          saleScenarioId,
        });
        setIsLoadingSaleScenarioResultSellAnimals(false);
        setListOfSaleScenarioResultAnimal(results);
      } catch (error) {
        setIsLoadingSaleScenarioResultSellAnimals(false);
        console.error(error);
      }
    }
    async function fetchData() {
      setIsLoadingSaleScenarioInfo(true);
      setIsLoadingSaleScenarioDietStrategySuggest(true);
      setIsLoadingSaleScenarioCommitments(true);
      setIsLoadingSaleScenarioViolations(true);
      try {
        // Get all SaleScenario Results
        const {
          data: { results: resultsAllSaleScenarioResults },
        } = await getAllSaleScenarioResultByIds({
          groupId,
          farmId,
          saleScenarioIds: [saleScenarioId],
        });

        // Get SaleScenario Result Info
        const {
          data: { results: resultInfo },
        } = await showSaleScenarioResultBySaleScenarioResultId({
          groupId,
          farmId,
          saleScenarioId,
          saleScenarioResultId,
        });
        // Get SaleScenario Resul Sells
        const {
          data: { results: resultSell },
        } = await showSaleScenarioResultSells({
          groupId,
          farmId,
          saleScenarioId,
          saleScenarioResultId,
        });
        // Get SaleScenario DietStrategy Suggest
        const {
          data: { results: resultDietStregySuggest },
        } = await getLotAndDietStragySaleScenarioResultSuggest({
          groupId,
          farmId,
          saleScenarioId,
          saleScenarioResultId,
        });
        // Get SaleScenario Info
        const {
          data: { results: resultsSaleScenarioInfo },
        } = await showSaleScenario({
          groupId,
          farmId,
          saleScenarioId,
          saleScenarioParamId: resultInfo?.saleScenarioParamId,
        });
        // Get SaleScenario Commitments
        const {
          data: { results: resultsSaleScenarioCommitments },
        } = await showSaleScenarioCommitmentsByParameter({
          groupId,
          farmId,
          saleScenarioId,
          saleScenarioParamId: resultInfo?.saleScenarioParamId,
        });
        setIsEmpty(false);
        setResultsData(resultsAllSaleScenarioResults);
        setSaleScenarioResult(resultInfo);
        setListOfSaleScenarioResultSell(resultSell);
        /** Select all sell dates */
        if (resultSell.length > 0) {
          const ids = resultSell.map((s) => `'${s.id}'`).join(",");
          fetchSellAnimals(ids);
        } else {
          setListOfResultSellSelected(null);
          setListOfResultSellSelectedIds(null);
          setListOfSaleScenarioResultAnimal(null);
        }
        setSaleScenarioInfo(resultsSaleScenarioInfo);
        setSaleScenarioDietStrategySuggest(resultDietStregySuggest);
        setSaleScenarioCommitments(resultsSaleScenarioCommitments);
        setIsLoadingSaleScenarioInfo(false);
        setIsLoadingSaleScenarioDietStrategySuggest(false);
        setIsLoadingSaleScenarioCommitments(false);
        setTimeout(() => {
          setSaleScenarioViolations(resultInfo?.violations);
          setIsLoadingSaleScenarioViolations(false);
        }, 500);
      } catch (error) {
        setIsEmpty(true);
        setIsLoadingSaleScenarioInfo(false);
        setIsLoadingSaleScenarioCommitments(false);
      }
    }
    fetchData();
  }, [groupId, farmId, saleScenarioId, saleScenarioResultId]);

  /** Get SaleScenario Lots info */
  useEffect(() => {
    async function fetchData() {
      setIsLoadingSaleScenarioLots(true);
      try {
        const {
          data: { results },
        } = await showSaleScenarioLots({
          groupId,
          farmId,
          saleScenarioId,
        });
        setSaleScenarioLots(results);
        setIsLoadingSaleScenarioLots(false);
      } catch (error) {
        setIsLoadingSaleScenarioLots(false);
      }
    }
    fetchData();
  }, [groupId, farmId, saleScenarioId]);

  /** Get SaleScenarioResultFarmSell */
  useEffect(() => {
    async function fetchSaleScenarioResultFarmSell() {
      try {
        let {
          data: { results },
        } = await indexSaleScenarioResultFarmSell({
          groupId,
          farmId,
          saleScenarioId,
          saleScenarioResultId,
          page: 0,
          sorter: null,
          filters: "",
          size: 10,
        });
        /** Format date */
        if (results != null && results?.content.length > 0) {
          results.content = results.content.map((s) => {
            s.sellDate = moment(s.sellDate).format(formatDate);
            return s;
          });
        }
        setListOfSaleScenarioResultFarmSell(results);
      } catch (error) {
        console.error(error);
      }
    }
    fetchSaleScenarioResultFarmSell();
  }, [groupId, farmId, saleScenarioId, saleScenarioResultId, formatDate]);

  /** Memo */

  /** Methods */

  function openDrawer() {
    dispatch(SaleScenarioActions.showOrHideDrawerDetailsSaleScenario());
  }

  function selectAndDeselectResultSell(id, all = false) {
    let selectedsResultSell = listOfResultSellSelected;
    try {
      // Select or deselect unitary
      if (all === false) {
        // Add or remove in listOfResultSellSelected
        // On Select just show animals of the selected dates
        // On Deselect show all other animals that is select
        if (selectedsResultSell.findIndex((x) => x.id === id) >= 0) {
          selectedsResultSell = selectedsResultSell.filter((x) => x.id !== id);
        } else {
          selectedsResultSell = [
            listOfSaleScenarioResultSell.find((x) => x.id === id),
          ];
        }
      }
      setListOfResultSellSelected(selectedsResultSell);
      if (selectedsResultSell.length > 0) {
        const ids = selectedsResultSell.map((s) => `'${s.id}'`).join(",");
        setListOfResultSellSelectedIds(ids);
        fetchSellAnimals(ids);
      } else {
        const ids = listOfSaleScenarioResultSell
          .map((s) => `'${s.id}'`)
          .join(",");
        setListOfResultSellSelectedIds(ids);
        fetchSellAnimals(ids);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchAllDetailsData() {
    setIsLoadingSaleScenarioInfo(true);
    setIsLoadingSaleScenarioCommitments(true);
    setIsLoadingSaleScenarioViolations(true);

    try {
      // Get all SaleScenario Results
      const {
        data: { results: resultsAllSaleScenarioResults },
      } = await getAllSaleScenarioResultByIds({
        groupId,
        farmId,
        saleScenarioIds: [saleScenarioId],
      });

      // Get SaleScenario Result Info
      const {
        data: { results: resultInfo },
      } = await showSaleScenarioResultBySaleScenarioResultId({
        groupId,
        farmId,
        saleScenarioId,
        saleScenarioResultId,
      });
      // Get SaleScenario Resul Sells
      const {
        data: { results: resultSell },
      } = await showSaleScenarioResultSells({
        groupId,
        farmId,
        saleScenarioId,
        saleScenarioResultId,
      });
      // Get SaleScenario Info
      const {
        data: { results: resultsSaleScenarioInfo },
      } = await showSaleScenario({
        groupId,
        farmId,
        saleScenarioId,
        saleScenarioParamId: resultInfo?.saleScenarioParamId,
      });
      // Get SaleScenario Commitments
      const {
        data: { results: resultsSaleScenarioCommitments },
      } = await showSaleScenarioCommitmentsByParameter({
        groupId,
        farmId,
        saleScenarioId,
        saleScenarioParamId: resultInfo?.saleScenarioParamId,
      });
      let {
        data: { results: resultsFarmSell },
      } = await indexSaleScenarioResultFarmSell({
        groupId,
        farmId,
        saleScenarioId,
        saleScenarioResultId,
        page: 0,
        sorter: null,
        filters: "",
        size: 10,
      });
      /** Format date */
      if (resultsFarmSell != null && resultsFarmSell?.content.length > 0) {
        resultsFarmSell.content = resultsFarmSell.content.map((s) => {
          s.sellDate = moment(s.sellDate).format(formatDate);
          return s;
        });
      }
      setEditResultID(false);
      setIsEmpty(false);
      setResultsData(resultsAllSaleScenarioResults);
      setSaleScenarioResult(resultInfo);
      setListOfSaleScenarioResultSell(resultSell);
      setSaleScenarioInfo(resultsSaleScenarioInfo);
      setSaleScenarioCommitments(resultsSaleScenarioCommitments);
      setListOfSaleScenarioResultFarmSell(resultsFarmSell);
      setIsLoadingSaleScenarioInfo(false);
      setIsLoadingSaleScenarioCommitments(false);
      setTimeout(() => {
        setSaleScenarioViolations(resultInfo?.violations);
        setIsLoadingSaleScenarioViolations(false);
      }, 500);
      /** Select all sell dates */
      if (resultSell.length > 0) {
        const ids = resultSell.map((s) => `'${s.id}'`).join(",");
        setListOfResultSellSelected(resultSell);
        setListOfResultSellSelectedIds(ids);
        fetchSellAnimals(ids);
      } else {
        setListOfResultSellSelected(null);
        setListOfResultSellSelectedIds(null);
        setListOfSaleScenarioResultAnimal(null);
      }
    } catch (error) {
      setIsEmpty(true);
      setEditResultID(false);
      setIsLoadingSaleScenarioInfo(false);
      setIsLoadingSaleScenarioCommitments(false);
    }
  }

  async function fetchSellAnimals(
    ids = null,
    page = 0,
    sorter = {},
    filters = "",
    size = 10
  ) {
    let sellIds = null;

    if (ids != null) {
      sellIds = ids;
    } else {
      if (
        listOfResultSellSelectedIds != null &&
        listOfResultSellSelectedIds.length > 0
      ) {
        sellIds = listOfResultSellSelectedIds;
      } else {
        sellIds = listOfSaleScenarioResultSell
          .map((s) => `'${s.id}'`)
          .join(",");
      }
    }

    const search =
      filters.trim() === ""
        ? `r.saleScenarioResultFarmSellId is null; r.saleScenarioResultSellId in (${sellIds})`
        : filters +
          `;r.saleScenarioResultFarmSellId is null;r.saleScenarioResultSellId in (${sellIds})`;
    setIsLoadingSaleScenarioResultSellAnimals(true);
    try {
      const {
        data: { results },
      } = await showSaleScenarioResultSellsAnimals({
        groupId,
        farmId,
        page,
        sorter,
        filters: search,
        size,
        saleScenarioId,
      });
      setIsLoadingSaleScenarioResultSellAnimals(false);
      setListOfSaleScenarioResultAnimal(results);
    } catch (error) {
      setIsLoadingSaleScenarioResultSellAnimals(false);
      console.error(error);
    }
  }

  function showHeader(amount) {
    return (
      <HeaderContainer>
        <div className="title">
          <span>Vendas efetuadas</span>
        </div>
        <div className="amount">
          <span>{`${amount} vendas`}</span>
        </div>
      </HeaderContainer>
    );
  }

  function goToNewFarmSell() {
    const animalsIds = listOfResultSellAnimalsSelected.map((a) => a.animalId);
    dispatch(
      SaleScenarioActions.selectSaleScenarioResultAnimals(
        listOfResultSellAnimalsSelected,
        animalsIds
      )
    );
    history.push(
      `/admin/decisions/scenarios/sales/${saleScenarioId}/results/${saleScenarioResultId}/farms/sales/new`
    );
  }

  function showFarmSell(id) {
    history.push(
      `/admin/decisions/scenarios/sales/${saleScenarioId}/results/${saleScenarioResultId}/farms/sales/${id}`
    );
  }

  function editFarmSell(id) {
    history.push(
      `/admin/decisions/scenarios/sales/${saleScenarioId}/results/${saleScenarioResultId}/farms/sales/edit/${id}`
    );
  }

  function showOrHidePopoverDeleteFarmSell(id) {
    confirm({
      title: "Você tem certeza que deseja deletar a venda?",
      content:
        "Ao confirmar, a venda será excluída e os animais continurão na fazenda, sendo necessário criar uma nova venda.",
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk() {
        handleDeleteFarmSell(id);
      },
      onCancel() {},
    });
  }

  function showEditSaleScenarioResultId() {
    setEditResultID(!editResultID);
  }

  function showOrHideModalConfirmBaseline() {
    setModalConfirmBaselineVisible(!modalConfirmBaselineVisible);
  }

  /** Table methods - Start */
  function menuFarmSells(id) {
    return (
      <Menu>
        <Menu.Item key="2">
          <button onClick={() => editFarmSell(id)}>
            {translation.buttons.edit}
          </button>
        </Menu.Item>
        <Menu.Item key="0">
          <button onClick={() => showFarmSell(id)}>Visualizar</button>
        </Menu.Item>
        <Menu.Item key="1">
          <button onClick={() => showOrHidePopoverDeleteFarmSell(id)}>
            {translation.buttons.delete}
          </button>
        </Menu.Item>
      </Menu>
    );
  }

  function getColumnSearchAnimalsProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          {dataIndex === "sellDate" ? (
            <DatePicker
              value={selectedKeys[0]}
              format={formatDate}
              onChange={(date, dateString) =>
                setSelectedKeys(date ? [date] : [])
              }
              onPressEnter={() => handleSearchAnimals(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
          ) : dataIndex === "animalPrice" ? (
            <>
              <Select
                value={priceFilterOperator}
                style={{
                  width: 90,
                  textAlign: "center",
                  marginBottom: 8,
                  marginRight: 8,
                }}
                onChange={(value) => {
                  setPriceFilterOperator(value);
                }}
              >
                <Select.Option
                  key="<"
                  value="<"
                  style={{ textAlign: "center" }}
                >
                  &lt;
                </Select.Option>
                <Select.Option
                  key="<="
                  value="<="
                  style={{ textAlign: "center" }}
                >
                  &le;
                </Select.Option>
                <Select.Option
                  key=">="
                  value=">="
                  style={{ textAlign: "center" }}
                >
                  &ge;
                </Select.Option>
                <Select.Option
                  key=">"
                  value=">"
                  style={{ textAlign: "center" }}
                >
                  &gt;
                </Select.Option>
              </Select>
              <Input
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                style={{ width: 90, marginBottom: 8, marginRight: 8 }}
              />
            </>
          ) : dataIndex === "margin" ? (
            <>
              <Select
                value={marginFilterOperator}
                style={{
                  width: 90,
                  textAlign: "center",
                  marginBottom: 8,
                  marginRight: 8,
                }}
                onChange={(value) => {
                  setMarginFilterOperator(value);
                }}
              >
                <Select.Option
                  key="<"
                  value="<"
                  style={{ textAlign: "center" }}
                >
                  &lt;
                </Select.Option>
                <Select.Option
                  key="<="
                  value="<="
                  style={{ textAlign: "center" }}
                >
                  &le;
                </Select.Option>
                <Select.Option
                  key=">="
                  value=">="
                  style={{ textAlign: "center" }}
                >
                  &ge;
                </Select.Option>
                <Select.Option
                  key=">"
                  value=">"
                  style={{ textAlign: "center" }}
                >
                  &gt;
                </Select.Option>
              </Select>
              <Input
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                style={{ width: 90, marginBottom: 8, marginRight: 8 }}
              />
            </>
          ) : dataIndex === "weight" ? (
            <>
              <Select
                value={weightFilterOperator}
                style={{
                  width: 90,
                  textAlign: "center",
                  marginBottom: 8,
                  marginRight: 8,
                }}
                onChange={(value) => {
                  setWeightFilterOperator(value);
                }}
              >
                <Select.Option
                  key="<"
                  value="<"
                  style={{ textAlign: "center" }}
                >
                  &lt;
                </Select.Option>
                <Select.Option
                  key="<="
                  value="<="
                  style={{ textAlign: "center" }}
                >
                  &le;
                </Select.Option>
                <Select.Option
                  key=">="
                  value=">="
                  style={{ textAlign: "center" }}
                >
                  &ge;
                </Select.Option>
                <Select.Option
                  key=">"
                  value=">"
                  style={{ textAlign: "center" }}
                >
                  &gt;
                </Select.Option>
              </Select>
              <Input
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                style={{ width: 90, marginBottom: 8, marginRight: 8 }}
              />
            </>
          ) : (
            <Input
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => handleSearchAnimals(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
          )}
          <Button
            type="primary"
            onClick={() => handleSearchAnimals(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleAnimalsReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
    };
  }

  function handleTableAnimalsChange(pagination, filters, sorter) {
    let search = "";

    if (Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v.length > 0)
          if (k === "sellDate") {
            search =
              search === ""
                ? `r.${k} = '${moment(v[0]).format("YYYY-MM-DD").toString()}'`
                : `${search};r.${k} = '${moment(v[0])
                    .format("YYYY-MM-DD")
                    .toString()}'`;
          } else if (k === "identification") {
            search =
              search === ""
                ? `upper(r.${k}) like upper('%${v}%')`
                : `${search};upper(r.${k}) like upper('%${v}%')`;
          } else if (k === "weight") {
            let operator = weightFilterOperator;
            search =
              search === ""
                ? `r.${k} ${operator} ${v[0]}`
                : `${search};r.${k} ${operator} ${v[0]}`;
          } else if (k === "animalPrice") {
            let operator = priceFilterOperator;
            search =
              search === ""
                ? `r.${k} ${operator} ${v[0]}`
                : `${search};r.${k} ${operator} ${v[0]}`;
          } else if (k === "margin") {
            let operator = marginFilterOperator;
            search =
              search === ""
                ? `r.${k} ${operator} ${v[0]}`
                : `${search};r.${k} ${operator} ${v[0]}`;
          } else {
            search =
              search === "" ? `r.${k} = ${v}` : `${search};r.${k} = ${v}`;
          }
      });
    }
    fetchSellAnimals(
      null,
      pagination.current,
      sorter,
      search,
      pagination.pageSize
    );
  }

  function handleSearchAnimals(selectedKeys, confirm) {
    confirm();
  }

  function handleAnimalsReset(clearFilters) {
    clearFilters();
  }

  async function handleSelectAllAnimals() {
    setIsLoadingSaleScenarioResultSellAnimals(true);
    try {
      const {
        data: { results },
      } = await showAllSaleScenarioResultSellsAnimals({
        groupId,
        farmId,
        saleScenarioId,
        saleScenarioResultId,
      });
      const animalsWithoutStrongViolation = results.filter(
        (a) => !a.violations.some((v) => v.strong === true)
      );
      const ids = animalsWithoutStrongViolation.map((a) => a.id);
      setIsLoadingSaleScenarioResultSellAnimals(false);
      setListOfResultSellAnimalsSelected(animalsWithoutStrongViolation);
      setListOfResultSellAnimalsSelectedKeys(ids);
    } catch (error) {
      setIsLoadingSaleScenarioResultSellAnimals(false);
      console.error(error);
    }
  }

  /** Table methods - End */
  async function handleDeleteFarmSell(id) {
    setIsLoadingSaleScenarioInfo(true);
    try {
      await destroySaleScenarioResultFarmSell({
        groupId,
        farmId,
        saleScenarioId,
        saleScenarioResultId,
        id,
      });
      notification.success({
        message: "Venda excluída com sucesso.",
      });
      fetchAllDetailsData();
    } catch (error) {
      notification.error({
        message: "Houve um erro ao excluir à venda.",
      });
    } finally {
      setIsLoadingSaleScenarioInfo(false);
    }
  }

  function handleChangeResult(value) {
    history.push(
      `/admin/decisions/scenarios/sales/${saleScenarioId}/results/${value}`
    );
  }

  async function handleSetResultAsBaseline() {
    try {
      await setSaleScenarioResultBaseline({
        groupId,
        farmId,
        saleScenarioId,
        saleScenarioResultId,
      });
      showOrHideModalConfirmBaseline();
      notification.success({
        message: "Resultado colocado como favorito.",
      });
      fetchAllDetailsData();
    } catch (error) {
      if (error.response.data.code === 5016) {
        notification.error({
          message:
            "Não foi possível favoritar o resultado pois todos os animais contêm restrição(ões) fortes.",
        });
      } else {
        notification.error({
          message: "Houve um erro ao colocar o resultado como favorito.",
        });
      }
    }
  }

  async function handleCancelResultAsBaseline() {
    try {
      await cancelSaleScenarioResultBaseline({
        groupId,
        farmId,
        saleScenarioId,
        saleScenarioResultId,
      });
      notification.success({
        message: "Cenário cancelado.",
      });
      fetchAllDetailsData();
    } catch (error) {
      notification.error({
        message: "Houve um erro ao cancelar o cenário.",
      });
    }
  }
  function handleEditSaleScenarioResultId(e) {
    setSaleScenarioResult({
      ...saleScenarioResult,
      identification: e.target.value,
    });
  }
  async function handleSaveEditSaleScenarioResultId() {
    setIsLoadingSaleScenarioInfo(true);
    try {
      const body = { ...saleScenarioResult };
      await updateSaleScenarioResult({
        groupId,
        farmId,
        saleScenarioId,
        saleScenarioResultId,
        body,
      });
      fetchAllDetailsData();
    } catch (error) {
      setIsLoadingSaleScenarioInfo(false);
      console.error(error);
    }
  }

  function showViolations(violation) {
    let violationText = "";
    switch (violation) {
      case "MaxWeight":
        violationText = "Peso máximo";
        break;
      case "MinWeight":
        violationText = "Peso mínimo";
        break;
      case "MaxAge":
        violationText = "Idade máxima";
        break;
      case "MinAge":
        violationText = "Idade mínima";
        break;
      case "NoCommonSaleDate":
        violationText = "Data prevista de venda";
        break;
      case "SisbovReleaseDate":
        violationText = "Controle de Liberação SISBOV";
        break;
      case "SisbovTracking":
        violationText = "Idade limite na Cota selecionada";
        break;
      case "Sex":
        violationText = "Sexo do animal na Cota selecionada";
        break;
      case "Castration":
        violationText = "Animal cadastrado - SISBOV";
        break;
      case "Weight": //Legacy scenarios
        violationText = "Peso";
        break;
      case "Age": //Legacy scenarios
        violationText = "Idade";
        break;
      default:
        break;
    }
    return violationText;
  }

  async function exportToXls() {
    setIsLoadingSaleScenarioInfo(true);
    try {
      const body = { animalsKeys: [...listOfResultSellAnimalsSelectedKeys] };
      const { data } = await exportXlsSaleScenarioResult({
        groupId,
        farmId,
        saleScenarioId,
        saleScenarioResultId,
        body,
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      let link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute(
        "download",
        `Cenario_Venda_${saleScenarioInfo.name}_${saleScenarioResult.identification}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
      setIsLoadingSaleScenarioInfo(false);
    } catch (error) {
      setIsLoadingSaleScenarioInfo(false);
      console.error(error);
    }
  }

  return (
    <Container>
      {isLoadingSaleScenarioInfo && <Loading />}
      {isEmpty ? (
        <Row type="flex" justify="center" align="middle">
          <Col span={24}>
            <Empty description="O Cenário de venda não foi encontrado" />
          </Col>
        </Row>
      ) : (
        <Row type="flex" className="mainRow">
          {/* Table */}
          <ColTable
            xs={24}
            sm={24}
            md={24}
            lg={expandColTable ? 24 : 16}
            xl={expandColTable ? 24 : 16}
          >
            {/* Header and BreadCrumbs */}
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="breadCrumbsContainer"
            >
              <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                <div className="titleContainer">
                  <Title>{saleScenarioInfo?.name}</Title>
                  {editResultID ? (
                    <>
                      <Input
                        className="inputResultId"
                        value={saleScenarioResult?.identification}
                        onChange={(e) => handleEditSaleScenarioResultId(e)}
                      />
                      <ButtonStandard
                        type="button"
                        buttonType="typeWithoutBackground"
                        color="#d44c4c"
                        isUpperCase={true}
                        padding="5px 15px"
                        onClick={showEditSaleScenarioResultId}
                      >
                        {translation.buttons.cancel}
                      </ButtonStandard>

                      <ButtonStandard
                        type="submit"
                        buttonType="typeWithoutBackground"
                        color="#684E94"
                        isUpperCase={true}
                        padding="5px 5px"
                        onClick={handleSaveEditSaleScenarioResultId}
                      >
                        {translation.buttons.save}
                      </ButtonStandard>
                    </>
                  ) : (
                    <>
                      <Select
                        value={
                          resultsData.length === 0
                            ? undefined
                            : saleScenarioResultId
                        }
                        placeholder={translation.defaultSelectPlaceholder}
                        onChange={handleChangeResult}
                      >
                        {resultsData.map((r) => (
                          <Select.Option key={r.id} value={r.id}>
                            {`${r?.identification} `}
                            {r.baseline && <CheckIcon />}
                          </Select.Option>
                        ))}
                      </Select>
                      <Tooltip title="Edite a identificação do resultado">
                        <button
                          className="editIcon"
                          onClick={showEditSaleScenarioResultId}
                        >
                          <Icon type="edit" />
                        </button>
                      </Tooltip>
                    </>
                  )}
                </div>
              </Col>
              <Col xs={8} sm={8} md={8} lg={8} xl={8} align="right">
                <BreadCrumbs>
                  <Link to="/admin/decisions?tabSelect=1">
                    <span className="pageTreeInative">Decisões</span>
                  </Link>
                  <span className="pageTreeActive">
                    Detalhes: Cenário de venda
                  </span>
                </BreadCrumbs>
              </Col>
            </Row>
            {/* Content */}
            <div className="content">
              {/* Expand row */}
              <Row type="flex">
                <Col span={24} align="right">
                  {expandColTable ? (
                    <Icon
                      type="fullscreen-exit"
                      onClick={() => setExpandColTable(false)}
                    />
                  ) : (
                    <Icon
                      type="fullscreen"
                      onClick={() => setExpandColTable(true)}
                    />
                  )}
                </Col>
              </Row>
              {/* Farm sells */}
              {listOfSaleScenarioResultFarmSell != null &&
                listOfSaleScenarioResultFarmSell?.content?.length > 0 && (
                  <Row type="flex" className="rowFarmSell">
                    <Col span={24}>
                      <Collapse
                        bordered={false}
                        expandIcon={({ isActive }) =>
                          isActive ? (
                            <Icon
                              type="down-circle"
                              style={{ fontSize: "16px", color: "#4A85AE" }}
                            />
                          ) : (
                            <Icon
                              type="right-circle"
                              style={{ fontSize: "16px", color: "#4A85AE" }}
                            />
                          )
                        }
                      >
                        <CollapsePanel
                          header={showHeader(
                            listOfSaleScenarioResultFarmSell?.content?.length
                          )}
                          style={customPanelStyle}
                        >
                          <Row type="flex" justify="start">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <Table
                                rowKey="id"
                                dataSource={
                                  listOfSaleScenarioResultFarmSell?.content
                                }
                                pagination={false}
                                size="small"
                              >
                                <Column
                                  title="Cliente"
                                  dataIndex="clientName"
                                  key="clientName"
                                  align="left"
                                />
                                <Column
                                  title="Animais"
                                  dataIndex="amountAnimalsSold"
                                  key="amountAnimalsSold"
                                  align="left"
                                />
                                <Column
                                  title="Data de Saída"
                                  dataIndex="sellDate"
                                  key="sellDate"
                                  align="left"
                                  sorter
                                />
                                <Column
                                  title="Fat. estimado"
                                  dataIndex="estimatedBilling"
                                  key="estimatedBilling"
                                  align="left"
                                  sorter
                                  render={(text, record) => (
                                    <span>{`${numberMask(
                                      record.estimatedBilling || 0,
                                      true
                                    )}`}</span>
                                  )}
                                />
                                <Column
                                  title="Fat. real"
                                  dataIndex="totalBilling"
                                  key="totalBilling"
                                  align="left"
                                  sorter
                                  render={(text, record) => (
                                    <span>{`${numberMask(
                                      record.totalBilling || 0,
                                      true
                                    )}`}</span>
                                  )}
                                />
                                <Table.Column
                                  align="left"
                                  render={(text, record) => (
                                    <Dropdown
                                      overlay={menuFarmSells(record.id)}
                                      trigger={["click"]}
                                      key={record.id}
                                    >
                                      <MenuContainer>
                                        <MenuIcon />
                                      </MenuContainer>
                                    </Dropdown>
                                  )}
                                />
                              </Table>
                            </Col>
                          </Row>
                        </CollapsePanel>
                      </Collapse>
                    </Col>
                  </Row>
                )}
              {/* Box text saleScenario canceled/partial canceled */}
              {(saleScenarioInfo?.status === "Canceled" ||
                saleScenarioInfo?.status === "PartialCanceled") && (
                <Row type="flex">
                  <BoxText
                    color="#D44C4C"
                    iconComponent={
                      <Icon type="stop" style={{ color: "#D44C4C" }} />
                    }
                  >
                    <span>
                      <strong>Cenário cancelado pelo usuário/violação:</strong>{" "}
                      {moment(saleScenarioInfo?.updatedAt).format(
                        "DD/MM/YYYY - HH:mm"
                      )}
                    </span>
                  </BoxText>
                </Row>
              )}
              {/* Card all animals sold */}
              {saleScenarioInfo?.status === "Sold" ? (
                <CardAllAnimalsSold>
                  <span className="title">Animais pendentes de definição</span>
                  <span className="message">
                    <SuccessIcon />
                    Todos os animais foram definidos para venda
                  </span>
                </CardAllAnimalsSold>
              ) : (
                <>
                  {/* Info */}
                  <Row type="flex">
                    <span className="message">
                      Selecione uma(s) data(s) sugeridas no calendário, para
                      vizualizar as informações dos animais.
                    </span>
                  </Row>
                  {/* Sell Dates */}
                  <Row type="flex" align="middle">
                    <Col span={20}>
                      <CardCalendar>
                        <div className="titleContainer">
                          <CardCalendarTitle>
                            Calendário sugerido
                          </CardCalendarTitle>
                        </div>
                        <div className="datesContainer">
                          {listOfSaleScenarioResultSell !== null &&
                            listOfSaleScenarioResultSell.map((sell) => (
                              <Tooltip
                                key={sell.id}
                                placement="top"
                                title={
                                  <div>
                                    <span>{`Valor da @ = ${numberMask(
                                      sell?.weightPrice || 0,
                                      true
                                    )}`}</span>
                                    <br />
                                    <span>
                                      {`Qtd. de animais = ${
                                        sell?.amountAnimals -
                                        (sell?.amountSoldAnimals || 0)
                                      }`}
                                    </span>
                                  </div>
                                }
                              >
                                <CardCalendarDate
                                  className={
                                    (listOfResultSellSelected != null
                                      ? listOfResultSellSelected?.find(
                                          (s) => s.id === sell.id
                                        ) != null
                                        ? "selected"
                                        : ""
                                      : "") +
                                    (sell?.priceLocked === true
                                      ? " locked"
                                      : "")
                                  }
                                  onClick={() =>
                                    selectAndDeselectResultSell(sell.id)
                                  }
                                >
                                  <span>
                                    {sell.sellDate != null
                                      ? moment(sell.sellDate).format(
                                          "DD/MM/YYYY"
                                        )
                                      : "Sem data"}
                                  </span>
                                </CardCalendarDate>
                              </Tooltip>
                            ))}
                        </div>
                      </CardCalendar>
                    </Col>
                    <Col span={4}>
                      <Row
                        type="flex"
                        align="middle"
                        justify="center"
                        gutter={16}
                      >
                        <Tooltip
                          title={
                            "Adicionar trava no valor da @ para as datas sugeridas de venda."
                          }
                        >
                          <ButtonLockPrice
                            type="button"
                            border="#684e94"
                            color="#684e94"
                            onClick={() => setTabActive(4)}
                            disabled={!saleScenarioResult?.baseline}
                          >
                            <div className="div-container">
                              <PriceLock
                                className="priceLock"
                                color="#684e94"
                              />
                              <span>Travar @</span>
                            </div>
                          </ButtonLockPrice>
                        </Tooltip>
                      </Row>
                    </Col>
                  </Row>
                  {/* Box info - Only show if have some animal without farm sell id */}
                  {listOfSaleScenarioResultAnimal != null &&
                    listOfSaleScenarioResultAnimal?.content.length > 0 && (
                      <Row type="flex">
                        <Col span={24}>
                          <BoxText
                            color="#FE8D2A"
                            iconComponent={
                              <Icon
                                type="message"
                                style={{ color: "#FE8D2A" }}
                              />
                            }
                          >
                            <span>
                              Para finalizar uma venda, você precisa definir
                              para todos os animais do cenário{" "}
                              <strong>
                                Cliente, data de saída dos animais e faturamento
                                final,
                              </strong>{" "}
                              selecione animais abaixo clicando na caixa
                              seletora da tabela
                            </span>
                          </BoxText>
                        </Col>
                      </Row>
                    )}
                  {/* Button New Farm Sell - Only show if animals is selected and sale scenario is baseline */}
                  {listOfResultSellAnimalsSelected.length > 0 &&
                    saleScenarioResult?.baseline && (
                      <Row type="flex">
                        <Col span={24}>
                          <ButtonNewFarmSell
                            onClick={goToNewFarmSell}
                            disabled={
                              saleScenarioInfo?.status === "Canceled" ||
                              saleScenarioInfo?.status === "PartialCanceled"
                                ? true
                                : false
                            }
                          >
                            <span>
                              <strong>Clique aqui</strong> para definir Cliente,
                              Data de entrega e faturamento final
                            </span>
                          </ButtonNewFarmSell>
                        </Col>
                      </Row>
                    )}
                  {/* Animals Table */}
                  <Row type="flex">
                    <Col span={24}>
                      <Table
                        rowKey="id"
                        loading={isLoadingSaleScenarioResultSellAnimals}
                        dataSource={
                          listOfSaleScenarioResultAnimal != null
                            ? listOfSaleScenarioResultAnimal.content
                            : []
                        }
                        pagination={{
                          total:
                            listOfSaleScenarioResultAnimal != null
                              ? listOfSaleScenarioResultAnimal.totalElements
                              : 0,
                          size:
                            listOfSaleScenarioResultAnimal != null
                              ? listOfSaleScenarioResultAnimal.size
                              : 0,
                          current:
                            listOfSaleScenarioResultAnimal != null
                              ? listOfSaleScenarioResultAnimal.number + 1
                              : 0,
                          hideOnSinglePage:
                            listOfSaleScenarioResultAnimal !== null &&
                            Object.entries(listOfSaleScenarioResultAnimal)
                              .length !== 0
                              ? listOfSaleScenarioResultAnimal.totalElements >
                                10
                                ? false
                                : true
                              : true,
                          showSizeChanger: true,
                          pageSizeOptions: [
                            "10",
                            "20",
                            "30",
                            "100",
                            "500",
                            "1000",
                            "2000",
                          ],
                        }}
                        rowSelection={{
                          selectedRowKeys: listOfResultSellAnimalsSelectedKeys,
                          hideDefaultSelections: true,
                          selections: [
                            {
                              key: "all-data",
                              text: "Selec. todos os animais do resultado",
                              onSelect: handleSelectAllAnimals,
                            },
                            {
                              key: "deselect-all-data",
                              text: "Desselec. todos os animais do resultado",
                              onSelect: () => {
                                setListOfResultSellAnimalsSelected([]);
                                setListOfResultSellAnimalsSelectedKeys([]);
                              },
                            },
                          ],
                          getCheckboxProps: (record) => ({
                            disabled: record.violations.some(
                              (v) => v.strong === true
                            )
                              ? true
                              : false,
                          }),
                          onSelect: (
                            record,
                            selected,
                            selectedRows,
                            nativeEvent
                          ) => {
                            let updatedList = [];
                            let updatedListKey = [];
                            if (selected) {
                              updatedList = [
                                ...listOfResultSellAnimalsSelected,
                                record,
                              ];
                              updatedListKey = [
                                ...listOfResultSellAnimalsSelectedKeys,
                                record.id,
                              ];
                            } else {
                              updatedList =
                                listOfResultSellAnimalsSelected.filter(
                                  (o) => o.id !== record.id
                                );
                              updatedListKey =
                                listOfResultSellAnimalsSelectedKeys.filter(
                                  (o) => o !== record.id
                                );
                            }
                            setListOfResultSellAnimalsSelected(updatedList);
                            setListOfResultSellAnimalsSelectedKeys(
                              updatedListKey
                            );
                          },
                          onSelectAll: (
                            record,
                            selected,
                            selectedRows,
                            nativeEvent
                          ) => {
                            // Get just the ids
                            const keys = selectedRows.map((sr) => sr.id);
                            // Get all the selectedAnimals unless the unselected
                            const lastSelectedAnimals =
                              listOfResultSellAnimalsSelected.filter(
                                (sA) =>
                                  !selectedRows.find((sr) => sr.id === sA.id)
                              );
                            // Get all the selectedAnimalsKeys unless the unselected
                            const lastSelectedAnimalsKeys =
                              listOfResultSellAnimalsSelectedKeys.filter(
                                (sAK) => !keys.includes(sAK)
                              );

                            if (selected.length > 0) {
                              if (listOfResultSellAnimalsSelected.length > 0) {
                                setListOfResultSellAnimalsSelected(
                                  lastSelectedAnimals
                                );
                              } else {
                                setListOfResultSellAnimalsSelected([
                                  ...listOfResultSellAnimalsSelected,
                                  ...selectedRows,
                                ]);
                              }

                              if (
                                listOfResultSellAnimalsSelectedKeys.length > 0
                              ) {
                                setListOfResultSellAnimalsSelectedKeys(
                                  lastSelectedAnimalsKeys
                                );
                              } else {
                                setListOfResultSellAnimalsSelectedKeys([
                                  ...listOfResultSellAnimalsSelectedKeys,
                                  ...keys,
                                ]);
                              }
                            } else {
                              setListOfResultSellAnimalsSelected(
                                lastSelectedAnimals
                              );
                              setListOfResultSellAnimalsSelectedKeys(
                                lastSelectedAnimalsKeys
                              );
                            }
                          },
                        }}
                        scroll={{
                          x: true,
                        }}
                        onChange={handleTableAnimalsChange}
                      >
                        <Column
                          title=""
                          width={50}
                          dataIndex="violations"
                          key="violations"
                          align="center"
                          fixed
                          render={(text, record) =>
                            record.violations != null &&
                            record.violations.length > 0 && (
                              <Tooltip
                                title={
                                  <div>
                                    <strong>Violações:</strong>
                                    <ul>
                                      {record.violations.map((v, index) => (
                                        <li key={index}>
                                          {showViolations(v.violation)}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                }
                              >
                                <div>
                                  <AlertIcon />
                                </div>
                              </Tooltip>
                            )
                          }
                        />
                        <Column
                          title="Identificação"
                          width={200}
                          dataIndex="identification"
                          sorter
                          key="identification"
                          align="left"
                          fixed
                          {...getColumnSearchAnimalsProps("identification")}
                          render={(text, record) => (
                            <>
                              <span>{text.split(" / ")[0]}</span>
                              <br />
                              <span>{text.split(" / ")[1]}</span>
                            </>
                          )}
                        />
                        <Column
                          title="Peso estimado"
                          width={200}
                          dataIndex="weight"
                          sorter
                          key="weight"
                          align="left"
                          {...getColumnSearchAnimalsProps("weight")}
                          render={(text, record) => (
                            <span>{`${numberMask(
                              record.weight || 0
                            )} Kg`}</span>
                          )}
                        />
                        <Column
                          title="Último peso"
                          width={150}
                          dataIndex="lastWeight"
                          key="lastWeight"
                          align="left"
                          render={(text, record) => (
                            <Async
                              promiseFn={getLastDailyWeightReactAsync}
                              payload={{
                                groupId,
                                farmId,
                                animalId: record.animalId,
                                signal: null,
                              }}
                            >
                              <Async.Pending>
                                <Icon type="loading" />
                              </Async.Pending>
                              <Async.Fulfilled>
                                {(resp) => (
                                  <span>{`${
                                    numberMask(
                                      resp.data?.results?.weight || 0
                                    ) || 0
                                  } Kg`}</span>
                                )}
                              </Async.Fulfilled>
                            </Async>
                          )}
                        />
                        <Column
                          title="Fat. estimado"
                          width={200}
                          dataIndex="animalPrice"
                          sorter
                          key="animalPrice"
                          align="left"
                          {...getColumnSearchAnimalsProps("animalPrice")}
                          render={(text, record) => (
                            <span>{`${numberMask(
                              record.animalPrice || 0,
                              true
                            )}`}</span>
                          )}
                        />
                        <Column
                          title="Margem estimada"
                          width={200}
                          dataIndex="margin"
                          sorter
                          key="margin"
                          align="left"
                          {...getColumnSearchAnimalsProps("margin")}
                          render={(text, record) => (
                            <span>{`${numberMask(
                              record.margin || 0,
                              true
                            )}`}</span>
                          )}
                        />
                        <Column
                          title="Valor crédito carbono"
                          width={200}
                          dataIndex="carbonFootprintBalance"
                          key="carbonFootprintBalance"
                          align="left"
                          render={(text, record) => (
                            <span>
                              {`${numberMask(
                                record.carbonFootprintBalance || 0,
                                true
                              )}`}
                            </span>
                          )}
                        />
                        <Column
                          title="Data sugerida"
                          width={180}
                          dataIndex="sellDate"
                          sorter
                          key="sellDate"
                          align="left"
                          {...getColumnSearchAnimalsProps("sellDate")}
                          render={(text, record) => (
                            <span>
                              {record.sellDate != null
                                ? moment(record.sellDate).format("DD/MM/YYYY")
                                : "-"}
                            </span>
                          )}
                        />
                      </Table>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </ColTable>
          {/* Right Side info */}
          {expandColTable === false && (
            <ColSaleScenarioInfo xs={24} sm={24} md={24} lg={8} xl={8}>
              {/* Buttons: Use as baseline and cancel scenario */}
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="headerContainer"
              >
                {saleScenarioResult?.baseline ? (
                  <Col span={13}>
                    <span className="spanBaseline">
                      <CheckIcon /> Cenário favorito
                    </span>
                  </Col>
                ) : (
                  <Col span={13} align="middle">
                    <ButtonStandard
                      buttonType="type6"
                      onClick={showOrHideModalConfirmBaseline}
                      disabled={
                        saleScenarioInfo?.retroactive === true ||
                        saleScenarioResult?.blocked === true ||
                        saleScenarioInfo?.status === "Canceled" ||
                        saleScenarioInfo?.status === "PartialCanceled"
                          ? true
                          : false
                      }
                    >
                      Utilizar como favorito
                    </ButtonStandard>
                  </Col>
                )}

                <Col span={2} align="right">
                  <Tooltip title="Exportar animais selecionados para xls">
                    <ButtonExport
                      type="button"
                      border="#9074BF"
                      color="#9074BF"
                      backgroundColor="#9074BF"
                      disabled={
                        listOfResultSellAnimalsSelectedKeys.length === 0
                      }
                      onClick={exportToXls}
                    >
                      <ExcelIcon />
                    </ButtonExport>
                  </Tooltip>
                </Col>

                <Col span={6} align="right">
                  <ButtonCustom
                    type="button"
                    border="#9074BF"
                    color="#9074BF"
                    disabled={false}
                    onClick={openDrawer}
                  >
                    Visão Geral
                  </ButtonCustom>
                </Col>

                <Col span={3} align="right">
                  <PopConfirmCustom
                    title={
                      <>
                        {" "}
                        Ao confirmar o cenário de venda será cancelado <br /> e
                        não poderá ser utilizado novamente{" "}
                      </>
                    }
                    cancelText="Cancelar"
                    okText="Confirmar"
                    placement="bottomRight"
                    icon={
                      <Icon
                        type="exclamation-circle"
                        theme="filled"
                        style={{ fontSize: 18, color: "#D44C4C" }}
                      />
                    }
                    onConfirm={handleCancelResultAsBaseline}
                  >
                    <ButtonCustom
                      type="button"
                      border="#D44C4C"
                      color="#D44C4C"
                      disabled={
                        saleScenarioInfo?.status === "Canceled" ||
                        saleScenarioInfo?.status === "PartialCanceled"
                          ? true
                          : false
                      }
                    >
                      <Icon type="stop" />
                    </ButtonCustom>
                  </PopConfirmCustom>
                </Col>
              </Row>
              <TabSaleScenarioInfoContainer>
                {/* Tabs content 1 - Default datas */}
                <SaleScenarioDetailsDefaultDataInfoTab
                  tabActive={tabActive}
                  saleScenarioInfo={saleScenarioInfo}
                  listOfSaleScenarioResultFarmSell={
                    listOfSaleScenarioResultFarmSell
                  }
                  saleScenarioResult={saleScenarioResult}
                  saleScenarioDietStrategySuggest={
                    saleScenarioDietStrategySuggest
                  }
                  isLoadingSaleScenarioCommitments={
                    isLoadingSaleScenarioCommitments
                  }
                  saleScenarioCommitments={saleScenarioCommitments}
                  isLoadingSaleScenarioViolations={
                    isLoadingSaleScenarioViolations
                  }
                  saleScenarioViolations={saleScenarioViolations}
                  isLoadingSaleScenarioDietStrategySuggest={
                    isLoadingSaleScenarioDietStrategySuggest
                  }
                />
                {/* Tabs content 2 - Sale Scenario parameters and lots */}
                <SaleScenarioDetailsParametersAndLotsTab
                  tabActive={tabActive}
                  saleScenarioInfo={saleScenarioInfo}
                  isLoadingSaleScenarioLots={isLoadingSaleScenarioLots}
                  saleScenarioLots={saleScenarioLots}
                />
                {/* Tabs content 3 - Dates and weight price */}
                <SaleScenarioDetailsDatesAndWeightPricesTab
                  tabActive={tabActive}
                  listOfSaleScenarioResultSell={listOfSaleScenarioResultSell}
                />
                {/* Tabs content 4 - Prices Lock */}
                <SaleScenarioDetailsPricesLocksTab
                  tabActive={tabActive}
                  listOfSaleScenarioResultAnimal={
                    listOfSaleScenarioResultAnimal
                  }
                  listOfSaleScenarioResultSell={listOfSaleScenarioResultSell}
                  marketAnalysisId={saleScenarioInfo?.marketAnalysisId}
                />

                {/* Tabs buttons */}
                <TabSaleScenarioInfoButtonsContainer>
                  <TabSaleScenarioInfoButtons
                    active={tabActive === 1 ? true : false}
                    onClick={() => setTabActive(1)}
                  />
                  <TabSaleScenarioInfoButtons
                    active={tabActive === 2 ? true : false}
                    onClick={() => setTabActive(2)}
                  />
                  <TabSaleScenarioInfoButtons
                    active={tabActive === 3 ? true : false}
                    onClick={() => setTabActive(3)}
                  />
                  {saleScenarioResult?.baseline ? (
                    <TabSaleScenarioInfoButtons
                      active={tabActive === 4 ? true : false}
                      onClick={() => setTabActive(4)}
                    />
                  ) : null}
                </TabSaleScenarioInfoButtonsContainer>
              </TabSaleScenarioInfoContainer>
            </ColSaleScenarioInfo>
          )}
        </Row>
      )}
      {/* Modals */}
      {/* Modal Confirm Baseline */}
      <ConfirmActionModal
        show={modalConfirmBaselineVisible}
        cancelAction={showOrHideModalConfirmBaseline}
        confirmAction={handleSetResultAsBaseline}
        title="Favoritar Cenário de venda"
        message="Ao confirmar, os cenários de venda favoritos que possuem os mesmos animais que o cenário atual não serão mais Favoritos."
        icon={<FavoriteIcon />}
      />
      <DrawerSaleScenarioDetails
        data={saleScenarioResult}
        historySells={listOfSaleScenarioResultFarmSell?.content}
        name={saleScenarioInfo?.name}
      />
    </Container>
  );
};

export default withRouter(SaleScenarioDetails);
