import React, { useState } from "react";
import { Container, CustomDivider, SectionTitle, SupplementInfoCard } from "./styles";

//Redux
import { useSelector } from "react-redux";

//Libs
import { Row, Col } from "antd";

//Icons
import NoteIcon from "../../../utils/icons/note";

//Services
import { numberMask } from "../../../../services/helpersMethodsService";

const DrawerSupplementSustenanceCapacityDetails = ({
	data,
	onCancel,
	drawerVisible,
}) => {

	const [units] = useState(
		{
			PV_ANIMAL: "%PV",
			I_MS_PV_ANIMAL: "%IMS PV",
			G_KG_PV_ANIMAL: "g/kg de PV",
			G_ANIMAL: "g/dia",
			KG_ANIMAL: "kg/dia",
		}
	)

	const { translation } = useSelector((state) => state.app);

	return (
		<Container
			title={translation.supplement.details.sustenanceCapacityDrawerTitle}
			width={600}
			onClose={onCancel}
			maskClosable={false}
			visible={drawerVisible}
		>
			{data != null && (
				<div className="drawerForm">

					{/* Voluntary Consumption */}
					<SectionTitle>
						<span className="subTittle">{translation.supplement.details.voluntaryConsumption}</span>
					</SectionTitle>
					<Row type="flex" align="middle">
						<Col xs={12} sm={12} md={12} lg={12} xl={12}>
							<Row>
								<label>{translation.supplement.details.minimum}</label>
							</Row>
							<Row className="rowInput">
								<span className="rowInputData">{`${numberMask(data.minimumVoluntaryConsumption)} ${units[data.voluntaryConsumptionType]}`}</span>
							</Row>
						</Col>
						<Col xs={12} sm={12} md={12} lg={12} xl={12}>
							<Row>
								<label>{translation.supplement.details.maximum}</label>
							</Row>
							<Row className="rowInput">
								<span className="rowInputData">{`${numberMask(data.maximumVoluntaryConsumption)} ${units[data.voluntaryConsumptionType]}`}</span>
							</Row>
						</Col>
					</Row>

					<CustomDivider>LIMITES METABÓLICOS</CustomDivider>

					{data.type === "Supplement" && (
						<>
							<SectionTitle>
								<span className="subTittle">{translation.supplement.details.pure}</span>
							</SectionTitle>
							<Row type="flex" align="middle">
								<Col xs={12} sm={12} md={12} lg={12} xl={12}>
									<Row>
										<label>{translation.supplement.details.minimum}</label>
									</Row>
									<Row className="rowInput">
										<span className="rowInputData">{`${numberMask(data.minimumPure)} ${units[data.pureConsumptionType]}`}</span>
									</Row>
								</Col>
								<Col xs={12} sm={12} md={12} lg={12} xl={12}>
									<Row>
										<label>{translation.supplement.details.maximum}</label>
									</Row>
									<Row className="rowInput">
										<span className="rowInputData">{`${numberMask(data.maximumPure)} ${units[data.pureConsumptionType]}`}</span>
									</Row>
								</Col>
							</Row>
							<SectionTitle>
								<span className="subTittle">{translation.supplement.details.recommendedTrough}</span>
							</SectionTitle>
							<Row type="flex" align="middle">
								<Col xs={12} sm={12} md={12} lg={12} xl={12}>
									<Row>
										<label>{translation.supplement.details.minimum}</label>
									</Row>
									<Row className="rowInput">
										<span className="rowInputData">{`${numberMask(data.minimumPureTrough)} cm/animal`}</span>
									</Row>
								</Col>
								<Col xs={12} sm={12} md={12} lg={12} xl={12}>
									<Row>
										<label>{translation.supplement.details.maximum}</label>
									</Row>
									<Row className="rowInput">
										<span className="rowInputData">{`${numberMask(data.maximumPureTrough)} cm/animal`}</span>
									</Row>
								</Col>
							</Row>
						</>
					)}

					{data.type === 'Ration_Mixture' && (
						<>
							<SectionTitle>
								<span className="subTittle">{translation.supplement.details.mix}</span>
							</SectionTitle>
							<Row type="flex" align="middle">
								<Col xs={12} sm={12} md={12} lg={12} xl={12}>
									<Row>
										<label>{translation.supplement.details.minimum}</label>
									</Row>
									<Row className="rowInput">
										<span className="rowInputData">{`${numberMask(data.minimumMix)} ${units[data.mixConsumptionType]}`}</span>
									</Row>
								</Col>
								<Col xs={12} sm={12} md={12} lg={12} xl={12}>
									<Row>
										<label>{translation.supplement.details.maximum}</label>
									</Row>
									<Row className="rowInput">
										<span className="rowInputData">{`${numberMask(data.maximumMix)} ${units[data.mixConsumptionType]}`}</span>
									</Row>
								</Col>
							</Row>
							<SectionTitle>
								<span className="subTittle">{translation.supplement.details.recommendedTrough}</span>
							</SectionTitle>
							<Row type="flex" align="middle">
								<Col xs={12} sm={12} md={12} lg={12} xl={12}>
									<Row>
										<label>{translation.supplement.details.minimum}</label>
									</Row>
									<Row className="rowInput">
										<span className="rowInputData">{`${numberMask(data.minimumMixTrough)} cm/animal`}</span>
									</Row>
								</Col>
								<Col xs={12} sm={12} md={12} lg={12} xl={12}>
									<Row>
										<label>{translation.supplement.details.maximum}</label>
									</Row>
									<Row className="rowInput">
										<span className="rowInputData">{`${numberMask(data.maximumMixTrough)} cm/animal`}</span>
									</Row>
								</Col>
							</Row>
						</>
					)}
					<SupplementInfoCard>
						<NoteIcon />
						{translation.supplement.form.information}
					</SupplementInfoCard>
				</div>

			)}

		</Container>
	);
};

export default DrawerSupplementSustenanceCapacityDetails;
