import React from 'react';

// import { Container } from './styles';

const TranslatorIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
    <g id="translator" transform="translate(0.001)">
      <path
        id="Path_907"
        data-name="Path 907"
        d="M27.979,13.245a2.208,2.208,0,0,0-2.035-2.336l-6.88.019-.022-8.593A2.207,2.207,0,0,0,17.015,0L10.66.019,7.144.031a.543.543,0,0,0-.41.2A.725.725,0,0,0,6.564.7a.628.628,0,0,0,.58.663l3.516-.013,6.349-.019a.942.942,0,0,1,.869,1l.022,9.356a1.082,1.082,0,0,1-.252.713.815.815,0,0,1-.618.3l-8.17.032a.536.536,0,0,0-.438.234L6.564,15.455l-2.1-2.506a.521.521,0,0,0-.416-.2l-1.991.006a.806.806,0,0,1-.613-.291,1.086,1.086,0,0,1-.257-.713L1.161,2.392a1.082,1.082,0,0,1,.252-.713.815.815,0,0,1,.618-.3h.76A.625.625,0,0,0,3.365.713.7.7,0,0,0,3.19.24.516.516,0,0,0,2.779.05H2.025A1.89,1.89,0,0,0,.592.738,2.544,2.544,0,0,0,0,2.4l.022,9.356a2.5,2.5,0,0,0,.6,1.654,1.9,1.9,0,0,0,1.427.682h.011l1.745-.006,2.368,2.828a.561.561,0,0,0,.416.2h.016a.541.541,0,0,0,.421-.234l2.1-2.816,2.127-.006.005,8.593a2.519,2.519,0,0,0,.6,1.654,1.883,1.883,0,0,0,1.433.682l5.622-.019,2.116,2.8A.542.542,0,0,0,21.45,28h.011a.53.53,0,0,0,.416-.208l2.357-2.841,1.744-.006a1.89,1.89,0,0,0,1.433-.688A2.531,2.531,0,0,0,28,22.6Zm-1.138,9.363a1.081,1.081,0,0,1-.252.713.815.815,0,0,1-.618.3l-1.991.006a.539.539,0,0,0-.416.208l-2.089,2.519L19.6,23.871a.544.544,0,0,0-.432-.227h-.006l-5.873.013a.805.805,0,0,1-.612-.29,1.081,1.081,0,0,1-.257-.707l-.006-8.6,4.632-.013a2.1,2.1,0,0,0,1.963-1.774l6.945-.025a.943.943,0,0,1,.869,1Zm0,0"
        transform="translate(-0.001 0)"
        fill="#c4c4c4"
      />
      <path
        id="Path_908"
        data-name="Path 908"
        d="M81.141,2.359l.014.007a.7.7,0,0,0,.2.027.722.722,0,0,0,.2-1.413L81.55.974a.724.724,0,0,0-.9.485A.739.739,0,0,0,81.141,2.359Zm0,0"
        transform="translate(-75.12 -0.878)"
        fill="#c4c4c4"
      />
      <path
        id="Path_909"
        data-name="Path 909"
        d="M128.045,61.541c.335,0,.533-.116.6-.348l.369-1.358H131.3l.369,1.358c.061.232.266.348.6.348a1.5,1.5,0,0,0,.765-.218.624.624,0,0,0,.375-.526.684.684,0,0,0-.021-.13L131.316,53.9a.777.777,0,0,0-.423-.451,1.7,1.7,0,0,0-.744-.15,1.833,1.833,0,0,0-.744.15.741.741,0,0,0-.423.451l-2.062,6.772a.68.68,0,0,0-.021.13.628.628,0,0,0,.375.526,1.582,1.582,0,0,0,.772.212Zm1.338-3.106.765-2.813.765,2.813Zm0,0"
        transform="translate(-120.353 -50.662)"
        fill="#c4c4c4"
      />
      <path
        id="Path_910"
        data-name="Path 910"
        d="M309.561,230.443a.464.464,0,0,0-.109-.348.925.925,0,0,0-.676-.246.522.522,0,0,0-.56.382,5.977,5.977,0,0,0-1.236.123c.007-.218.014-.369.027-.539.048,0,.1-.007.15-.007.116-.007.253-.014.43-.027s.375-.034.6-.062c.246-.027.505-.054.744-.068.894-.089,1.181-.273,1.181-.771a.586.586,0,0,0-.56-.655,1.26,1.26,0,0,0-.273.027l-.123.021a19.5,19.5,0,0,1-2.035.239,5.234,5.234,0,0,0,.048-.56c0-.355-.232-.553-.655-.553-.54.007-.7.239-.779,1.167h-.3c-.1,0-.253,0-.4-.007s-.287-.007-.382-.007c-.458,0-.642.177-.642.642,0,.69.4.69,1.195.69.144,0,.3,0,.457-.014v.027c-.014.307-.027.594-.034.915a3.224,3.224,0,0,0-2.034,2.833,1.414,1.414,0,0,0,1.488,1.618,2.441,2.441,0,0,0,1.031-.232.489.489,0,0,0,.478.253c.471,0,.819-.273.819-.628a1.33,1.33,0,0,0-.089-.43,8.8,8.8,0,0,0,1.8-2.567,1.111,1.111,0,0,1,.669,1.079c0,.962-.805,1.283-1.338,1.5l-.014.007a.631.631,0,0,0-.437.628.736.736,0,0,0,.724.737,2.87,2.87,0,0,0,2.471-2.888A2.238,2.238,0,0,0,309.561,230.443Zm-1.748,1a5.733,5.733,0,0,1-.778,1.27c-.027-.273-.068-.806-.068-1.106A4.415,4.415,0,0,1,307.813,231.447Zm-2.144.826a14.365,14.365,0,0,0,.15,1.454,1.4,1.4,0,0,1-.628.211c-.177,0-.293-.157-.293-.389A1.7,1.7,0,0,1,305.669,232.273Zm0,0"
        transform="translate(-288.145 -213.476)"
        fill="#c4c4c4"
      />
    </g>
  </svg>
);
export default TranslatorIcon;
