export const Types = {
  RESET_DATA: "report/RESET_DATA",
  SHOW_OR_HIDE_DRAWER: "report/SHOW_OR_HIDE_DRAWER",
  SET_FALSE_CLEAN_FORM: "report/SET_FALSE_CLEAN_FORM",
  GET_ALL_DATA: "report/GET_ALL_DATA",
  GET_ALL_DATA_SUCCESS: "report/GET_ALL_DATA_SUCCESS",
  GET_ALL_DATA_ERROR: "report/GET_ALL_DATA_ERROR",
  SAVE: "report/SAVE",
  SAVE_SUCCESS: "report/SAVE_SUCCESS",
  SAVE_ERROR: "report/SAVE_ERROR",
};

export const Creators = {
  showOrHideDrawer: () => ({
    type: Types.SHOW_OR_HIDE_DRAWER,
    payload: {},
  }),
  setFalseCleanForm: () => ({
    type: Types.SET_FALSE_CLEAN_FORM,
    payload: {},
  }),
  getAllData: (groupId, farmId) => ({
    type: Types.GET_ALL_DATA,
    payload: {
      groupId,
      farmId,
    },
  }),
  getAllDataSuccess: (data) => ({
    type: Types.GET_ALL_DATA_SUCCESS,
    payload: {
      data,
    },
  }),
  getAllDataError: (error) => ({
    type: Types.GET_ALL_DATA_ERROR,
    payload: {
      error,
    },
  }),
  save: (groupId, farmId, body, id = null) => ({
    type: Types.SAVE,
    payload: {
      groupId,
      farmId,
      body,
      id,
    },
  }),
  saveSuccess: (data) => ({
    type: Types.SAVE_SUCCESS,
    payload: {
      data,
    },
  }),
  saveError: (error) => ({
    type: Types.SAVE_ERROR,
    payload: {
      error,
    },
  }),
};

const INITIAL_STATE = {
  isDrawerVisible: false,
  listAllData: [],
  isLoadingGetListAllData: false,
  isLoadingRequestSave: false,
  cleanForm: false,
};

export default function breedReproductionScore(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.SHOW_OR_HIDE_DRAWER:
      return { ...state, isDrawerVisible: !state.isDrawerVisible };
    case Types.SET_FALSE_CLEAN_FORM:
      return { ...state, cleanForm: false };
    case Types.GET_ALL_DATA:
      return { ...state, isLoadingGetListAllData: true };
    case Types.GET_ALL_DATA_SUCCESS:
      return {
        ...state,
        listAllData: action.payload.data,
        isLoadingGetListAllData: false,
      };
    case Types.GET_ALL_DATA_ERROR:
      return { ...state, isLoadingGetListAllData: false };
    case Types.SAVE:
      return {
        ...state,
        isLoadingRequestSave: true,
        isLoadingGetListAllData: true,
      };
    case Types.SAVE_SUCCESS:
      return {
        ...state,
        listAllData: action.payload.data,
        isLoadingRequestSave: false,
        isLoadingGetListAllData: false,
        cleanForm: true,
      };
    case Types.SAVE_ERROR:
      return {
        ...state,
        isLoadingRequestSave: false,
        isLoadingGetListAllData: false,
        cleanForm: false,
      };
    default:
      return state;
  }
}
