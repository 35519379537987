import React from "react";

// import { Container } from './styles';

const SalePercentageIcon = () => (
  <svg
    id="salePercentageIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="28.528"
    height="28.848"
    viewBox="0 0 28.528 28.848"
  >
    <g
      id="Grupo_4305"
      data-name="Grupo 4305"
      transform="translate(-393.472 -313)"
    >
      <g id="banking" transform="translate(388.997 312.804)">
        <g
          id="Grupo_1065"
          data-name="Grupo 1065"
          transform="translate(4.474 4.474)"
        >
          <path
            id="Caminho_1103"
            data-name="Caminho 1103"
            d="M125.121,146.619A1.536,1.536,0,0,1,124.035,144L144,124.035a1.535,1.535,0,1,1,2.171,2.171l-19.963,19.963A1.53,1.53,0,0,1,125.121,146.619Z"
            transform="translate(-122.818 -122.818)"
            fill="#4b4b4b"
          />
          <path
            id="Caminho_1104"
            data-name="Caminho 1104"
            d="M120.134,125.508a5.375,5.375,0,1,1,5.375-5.375A5.381,5.381,0,0,1,120.134,125.508Zm0-7.678a2.3,2.3,0,1,0,2.3,2.3A2.306,2.306,0,0,0,120.134,117.83Z"
            transform="translate(-114.759 -114.759)"
            fill="#4b4b4b"
          />
          <path
            id="Caminho_1105"
            data-name="Caminho 1105"
            d="M279.03,284.4a5.375,5.375,0,1,1,5.375-5.375A5.381,5.381,0,0,1,279.03,284.4Zm0-7.678a2.3,2.3,0,1,0,2.3,2.3A2.306,2.306,0,0,0,279.03,276.726Z"
            transform="translate(-259.835 -259.835)"
            fill="#4b4b4b"
          />
        </g>
      </g>
      <circle
        id="Elipse_356"
        data-name="Elipse 356"
        cx="9.5"
        cy="9.5"
        r="9.5"
        transform="translate(403 313)"
        fill="red"
        opacity="0.65"
      />
    </g>
  </svg>
);
export default SalePercentageIcon;
