import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CardCustom, Title, Footer } from "../../../styles";
import { Spin, Col, Input, Row, DatePicker, notification, Table } from "antd";
import ButtonStandard from "../../../../../components/utils/button";

import { Creators as CostCenterActions } from "../../../../../store/ducks/costCenter";

import moment from "moment";

import { findById, save, update } from "../../../../../services/costCenterService";
import { findAllFinancialClassesByGroupIdAndFarmId } from "../../../../../services/financialClassService";

const initialStateForm = {
  name: null,
  startDate: null,
  endDate: null,
  items: [
    {
      id: null,
      name: null,
      financialClassId: null,
      financialClassName: null,
    }
  ],
};

const FinancialParametersCostCenterForm = () => {

  const {
    costCenter: { costCenterId, isCostCenterFormVisible, isLoadingForm },
    app: {
      translation,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const { Column } = Table;

  const [form, setForm] = useState(initialStateForm);
  const [errors, setErrors] = useState([]);
  const [isLoadingClass, setIsLoadingClass] = useState(false);
  const [financialClassList, setFinancialClassList] = useState([]);

  // Fetch financial classes
  useEffect(() => {
    // fetch financial classes
    async function getFinancialClassOptions() {
      try {
        setIsLoadingClass(true);
        const { data: { results: classes } } = await findAllFinancialClassesByGroupIdAndFarmId({ groupId, farmId });
        setFinancialClassList(classes);
        setIsLoadingClass(false);
      } catch (error) {
        console.error(error)
        setIsLoadingClass(false);
      }
    }

    getFinancialClassOptions();
  }, [groupId, farmId, costCenterId]);

  // Fetch cost center data
  useEffect(() => {
    // fetch cost center data
    async function getCostCenterData() {
      try {
        dispatch(CostCenterActions.setIsLoadingForm(true))
        const { data: { results } } = await findById({ groupId, farmId, id: costCenterId });

        const formValue = {
          id: costCenterId,
          name: results.name,
          startDate: results.startDate,
          endDate: results.endDate,
          items: results.items,
        }

        // Add new or not used financial class to the form
        if (financialClassList !== null && financialClassList !== undefined && financialClassList.length > 0) {
          financialClassList.forEach(fn => {
            let indexInForm = formValue.items.findIndex(item => item.financialClassId === fn.id)
            if (indexInForm === -1) {
              formValue.items.push({ id: null, financialClassName: fn.name, financialClassId: fn.id })
            }
          });
        }

        setForm(formValue);
      } catch (error) {
        console.error(error)
        notification.error({
          title: "Erro ao carregar",
          message: "Não foi possível carregar o centro de custo. Contate o suporte"
        });
      } finally {
        dispatch(CostCenterActions.setIsLoadingForm(false));
      }
    }

    if (isCostCenterFormVisible) {
      if (costCenterId !== null) {
        getCostCenterData();
      } else {
        const formValue = { ...initialStateForm, items: [...financialClassList.map(fn => ({ id: null, financialClassName: fn.name, financialClassId: fn.id }))] }
        setForm(formValue);
      }
    }
  }, [isCostCenterFormVisible, groupId, farmId, costCenterId, financialClassList, dispatch])

  const closeForm = () => {
    dispatch(CostCenterActions.showOrHideForm());
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const items = form.items.filter(item => item.name !== null && item.name !== undefined && item.name.trim() !== "");
      const body = { ...form, items: items, id: costCenterId };

      try {
        dispatch(CostCenterActions.setIsLoadingForm(true));
        if (body.id === null) {
          const { data: { results } } = await save({ groupId, farmId, body });
          notification.success({ message: "Centro de Custo criado com sucesso" });
          dispatch(CostCenterActions.showOrHideForm(null));
          dispatch(CostCenterActions.showOrHideApportionmentForm(results?.id))
        } else {
          const { data: { results } } = await update({ groupId, farmId, id: costCenterId, body });
          notification.success({ message: "Centro de Custo atualizado com sucesso" });
          dispatch(CostCenterActions.showOrHideForm(null));
          dispatch(CostCenterActions.showOrHideApportionmentForm(results?.id))
        }

      } catch (error) {
        notification.error({
          title: "Erro ao salvar",
          message: "Não foi possível salvar o Centro de Custo. Contate o suporte"
        });
      } finally {
        dispatch(CostCenterActions.setIsLoadingForm(false));
      }
    }
  };

  function validateForm() {
    let aErrors = [];
    if (form.name === null || form.name.trim() === "") {
      aErrors.push("name");
    }
    if (form.startDate === null) {
      aErrors.push("startDate");
    }
    if (form.endDate === null) {
      aErrors.push("endDate");
    }
    if (moment(form.startDate).isAfter(moment(form.endDate))) {
      aErrors.push("startDate");
      aErrors.push("endDate");
      notification.error({title: "Erro ao salvar", message: "A Validade Final não pode ser antes da Validade Inicial."});
    }

    const items = form.items.filter(item => item.name === null || item.name === undefined || item.name.trim() === "");
    if (Array.isArray(items) && items.length > 0) {
      aErrors.push("items");
      notification.error({
        title: "Erro ao salvar",
        message: "Não é possível salvar o Centro de Custo sem informar todos os itens"
      });
    }

    setErrors(aErrors)
    return aErrors.length > 0 ? false : true;
  }

  const handleChangeItem = (value, field, index) => {
    const currentFormValue = { ...form };
    currentFormValue.items[index][field] = value;
    setForm({ ...currentFormValue });
  }

  const renderFinancialClasses = () => {
    const table = (
      <Table
        rowKey="financialClassId"
        loading={isLoadingClass}
        dataSource={form.items !== null ? form.items : []}
        pagination={false}
        size="small"
        scroll={{ x: true, y: 300 }}>

        <Column
          title="Classe"
          dataIndex="financialClassName"
          key="financialClassName"
          width={200}
          align="left"
        />

        <Column
          title="Nome"
          dataIndex="name"
          key="name"
          width={200}
          align="left"
          render={(value, record, index) => (
            <Input
              name={`name${index}`}
              value={form?.items !== null ? form.items[index].name : undefined}
              type="name"
              autoComplete="chrome-off"
              placeholder={translation.defaultPlaceholder}
              onChange={(e) => {
                handleChangeItem(e.target.value, "name", index)
              }}
            />)
          }
        />
      </Table>

    )

    return table;
  }

  return (
    <CardCustom>
      <Spin spinning={isLoadingForm}>
        <Row type="flex" justify="start" className="rowHeader">
          <Title>{translation.financial.parameters.costCenter.form.title}</Title>
        </Row>

        {/* Name */}
        <Row type="flex" gutter={8} justify="start" align="middle" className="rowForm">
          <Col span={12}>
            <label className={errors.includes("name") ? "error" : ""}>
              Título:
            </label>

            <Input
              name="name"
              value={form?.name != null ? form?.name : undefined}
              type="name"
              autoComplete="chrome-off"
              placeholder={translation.defaultPlaceholder}
              onChange={(e) => {
                setForm({ ...form, name: e.target.value });
                setErrors(errors.filter((e) => e !== "name"));
              }}
            />
          </Col>
        </Row>

        {/* Start and End Date */}
        <Row type="flex" gutter={8} justify="start" align="middle" className="rowForm">
          <Col span={12}>
            <label className={errors.includes("startDate") ? "error" : ""}>
              Validade Inicial:
            </label>
            <DatePicker.MonthPicker
              style={{ width: '95%' }}
              name="startDate"
              value={
                form.startDate
                  ? moment(form.startDate)
                  : undefined
              }
              placeholder={translation.defaultDatePickerPlaceholder}
              format={"MMM/YYYY"}
              allowClear={false}
              onChange={(date) => {
                setForm({ ...form, startDate: date });
                setErrors(errors.filter((e) => e !== "startDate"));
              }} />

          </Col>

          <Col span={12}>
            <label className={errors.includes("endDate") ? "error" : ""}>
              Validade Final:
            </label>
            <DatePicker.MonthPicker
              style={{ width: '95%' }}
              name="endDate"
              value={
                form.endDate
                  ? moment(form.endDate)
                  : undefined
              }
              disabledDate={(currentDate) =>
                currentDate.isBefore(form.startDate)
              }
              placeholder={translation.defaultDatePickerPlaceholder}
              format={"MMM/YYYY"}
              allowClear={false}
              onChange={(date) => {
                setForm({ ...form, endDate: date });
                setErrors(errors.filter((e) => e !== "endDate"));
              }} />
          </Col>
        </Row>

        {/* Cost Center Items */}
        <div className="rowForm" >
          {renderFinancialClasses()}
        </div>

        {/* Footer */}
        <Footer>
          <Row type="flex">
            <Col span={24} className="buttonsDiv">
              <ButtonStandard
                type="button"
                buttonType="type7"
                onClick={closeForm}
              >
                {translation.buttons.cancel}
              </ButtonStandard>

              <ButtonStandard
                type="button"
                buttonType="type6"
                onClick={handleSubmit}
              >
                {translation.buttons.save}
              </ButtonStandard>
            </Col>
          </Row>
        </Footer>

      </Spin>
    </CardCustom >
  );
}

export default FinancialParametersCostCenterForm;
