import { Drawer } from "antd";
import styled from "styled-components";

export const Container = styled(Drawer)`
  .ant-drawer-body {
    padding: 24px 0px 10px 0px;
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;

    &.error {
      color: #d44c4c;
    }
  }

  .ant-row > label {
    display: inline-block;
    margin-bottom: 8px;
  }

  .ant-table {
    margin-top: 8px;
    .ant-input {
      margin-top: 0px;
    }
  }

  .drawerForm {
    width: 100%;
    padding: 0px 24px;
    min-height: -moz-calc(100vh - 165px);
    min-height: -webkit-calc(100vh - 165px);
    min-height: calc(100vh - 165px);

    & > .ant-row-flex {
      margin-bottom: 8px;
    }

    .colButtonGenerate {
      height: 61px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .weightsContainer {
      max-height: 440px;
      overflow-y: auto;
      overflow-x: hidden;
      strong {
        margin-left: 15px;
      }

      & > .ant-row-flex {
        margin-bottom: 8px;
      }
    }
  }
`;

export const Footer = styled.div`
  border-top: 1px solid #e8e8e8;
  margin-top: 20px;
  width: 100%;
  padding: 10px 24px;
  border-radius: 0 0 4px 4px;

  .buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding-right: 24px; */

    button:first-of-type {
      margin-right: 12px !important;
    }
  }

  .ant-row-flex {
    margin: 0;
  }

  .selectDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: "Asap", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;
