import { call, put } from "redux-saga/effects";
import { Creators as AccountFarmActions } from "../ducks/accountFarm";
import {
  checkAccountFarmService,
  renewExpirationAccountFarmService,
} from "../../services/accountFarmService";
import { notification } from "antd";

export function* checkAccountFarmServiceSaga(action) {
  try {
    const {
      data: { results },
    } = yield call(checkAccountFarmService, action.payload);
    yield put(AccountFarmActions.checkAccountFarmSuccess(results));
  } catch (error) {
    yield put(AccountFarmActions.checkAccountFarmError(error.response?.data));
  }
}

export function* renewExpirationAccountFarmServiceSaga(action) {
  try {
    yield call(renewExpirationAccountFarmService, action.payload);
    notification.success({
      message: "Tempo de experiência renovado por mais 15 dias.",
    });
    yield put(AccountFarmActions.renewExpirationAccountFarmSuccess());
  } catch (error) {
    notification.error({
      message:
        "Houve um erro ao renova o tempo de experiência. Contate o suporte BovExo.",
    });
    yield put(
      AccountFarmActions.renewExpirationAccountFarmError(error.response?.data)
    );
  }
}
