import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Icon, Spin } from "antd";
import { Container } from "./styles";

import {
  getAnimalReproductionIndicatorsDashboard,
  reprocessAnimalDashboard,
} from "../../../../../services/dashboards/dashboardAnimalService";
import { numberMask } from "../../../../../services/helpersMethodsService";
import ButtonStandard from "../../../../../components/utils/button";
import moment from "moment";
import InfoTooltip from "../../../../../components/utils/infoTooltip";

const INDICATORS = [
  {
    helpMessage: "Fêmeas Aptas no início da E.M",
    isDecimal: false,
    isPercentage: false,
    row: "number_animals",
    label: "# Matrizes",
    previous: 0,
    current: 0,
  },
  {
    helpMessage: "Média atual do Score das Matizes utilizadas na E.M",
    isDecimal: true,
    isPercentage: false,
    row: "average_reproduction_score",
    label: "Score Médio",
    previous: 0,
    current: 0,
  },
  {
    helpMessage: "Qtde atual de Matrizes com Score de 1 a 3",
    isDecimal: false,
    isPercentage: false,
    row: "reproduction_score_between_1_and_3",
    label: "1 - 3",
    previous: 0,
    current: 0,
  },
  {
    helpMessage: "Qtde atual de Matrizes com Score de 4 a 7",
    isDecimal: false,
    isPercentage: false,
    row: "reproduction_score_between_3_and_7",
    label: "4 - 7",
    previous: 0,
    current: 0,
  },
  {
    helpMessage: "Qtde atual de Matrizes com Score de 8 a 9",
    isDecimal: false,
    isPercentage: false,
    row: "reproduction_score_between_7_and_9",
    label: "8 - 9",
    previous: 0,
    current: 0,
  },
  {
    helpMessage: "Qtde de IA/ IATF’s realizadas na E.M",
    isDecimal: false,
    isPercentage: false,
    row: "amount_iatf_coverage",
    label: "# IA/IATF",
    previous: 0,
    current: 0,
  },
  {
    helpMessage: "% de IA/IATF que tiveram resultado Positivo no DG",
    isDecimal: true,
    isPercentage: true,
    row: "amount_iatf_positive_pregnancy_diagnosis",
    label: "%DG+ IA/IATF",
    previous: 0,
    current: 0,
  },
  {
    helpMessage: "Qtde de Coberturas do tipo MC realizadas na E.M",
    isDecimal: false,
    isPercentage: false,
    row: "amount_mount_coverage",
    label: "# MC",
    previous: 0,
    current: 0,
  },
  {
    helpMessage: "% de MC que tiveram resultado Positivo no DG",
    isDecimal: true,
    isPercentage: true,
    row: "amount_mount_positive_pregnancy_diagnosis",
    label: "%DG+ MC",
    previous: 0,
    current: 0,
  },
  {
    helpMessage: "% de Prenhes das fêmeas cobertas",
    isDecimal: true,
    isPercentage: true,
    row: "total_pregnancy_diagnosis",
    label: "%DG+ Total",
    previous: 0,
    current: 0,
  },
  {
    helpMessage:
      "Relação da qtde de Fêmeas em relação a qtde de Reprodutores disponíveis no início da E.M",
    isDecimal: true,
    isPercentage: false,
    row: "males_per_females",
    label: "Machos/Fêmeas",
    previous: 0,
    current: 0,
  },
  {
    helpMessage: "Idade média das Fêmeas Aptas no início da E.M",
    isDecimal: false,
    isPercentage: false,
    row: "average_female_age",
    label: "Idade Méd. Fêm.",
    previous: 0,
    current: 0,
  },
  {
    helpMessage:
      "Qtde média de Gestações (Partos) das Matrizes trabalhadas na E.M",
    isDecimal: true,
    isPercentage: false,
    row: "average_pregnancy",
    label: "# Med Gest.",
    previous: 0,
    current: 0,
  },
  {
    helpMessage:
      "Intervalo entre Partos médio das Fêmeas que possuem ao menos 2 partos",
    isDecimal: false,
    isPercentage: false,
    row: "iep",
    label: "IEP",
    previous: 0,
    current: 0,
  },
];

function AnimalReproductionIndicatorDash() {
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const [dashboardData, setDashboardData] = useState([...INDICATORS]);

  const renderTableData = useCallback(() => {
    return dashboardData.map((data, indexRow) => {
      const shouldShowOneDecimalDigit =
        data.row === "males_per_females" || data.row === "average_pregnancy";

      const previous = data.isDecimal
        ? shouldShowOneDecimalDigit
          ? numberMask(
              data.isPercentage ? data.previous * 100 : data.previous,
              false,
              "BRL",
              "pt-br",
              1,
              1
            )
          : numberMask(
              data.isPercentage ? data.previous * 100 : data.previous,
              false
            )
        : data.previous;

      const current = data.isDecimal
        ? shouldShowOneDecimalDigit
          ? numberMask(
              data.isPercentage ? data.current * 100 : data.current,
              false,
              "BRL",
              "pt-br",
              1,
              1
            )
          : numberMask(
              data.isPercentage ? data.current * 100 : data.current,
              false
            )
        : data.current;
      return (
        <tr key={data.row}>
          <td>
            {data.label} <InfoTooltip title={data.helpMessage} />
          </td>
          <td>{data.isPercentage ? `${previous}%` : previous}</td>
          <td>{data.isPercentage ? `${current}%` : current}</td>
        </tr>
      );
    });
  }, [dashboardData]);

  const handleRefresh = useCallback(async () => {
    if (groupId && farmId) {
      let dashDataList = [...INDICATORS];
      setIsLoadingInternal(true);
      try {
        await reprocessAnimalDashboard({
          groupId,
          farmId,
          dashboard: "AnimalReproductionIndicatorsDashboard",
        });
        const {
          data: { results },
        } = await getAnimalReproductionIndicatorsDashboard({
          groupId,
          farmId,
        });
        if (results) {
          const tableValues = results.values_table;
          if (tableValues && tableValues.length > 0) {
            for (let i = 0; i < tableValues.length; i++) {
              const currentTableValue = tableValues[i];
              const dataDashIndex = dashDataList.findIndex(
                (data) => data.row === currentTableValue.fieldRow
              );
              const dataDash = dashDataList[dataDashIndex];
              if (dataDash) {
                const dataDashUpdated = {
                  ...dataDash,
                  [currentTableValue.fieldColumn]: currentTableValue.dvalue,
                };
                dashDataList[dataDashIndex] = dataDashUpdated;
              }
            }
            setDashboardData(dashDataList);
          }
        } else {
          setDashboardData([...INDICATORS]);
        }
      } catch (error) {
        console.error(error);
        setDashboardData([...INDICATORS]);
        setIsLoadingInternal(false);
      } finally {
        setIsLoadingInternal(false);
      }
    }
  }, [farmId, groupId]);

  useEffect(() => {
    const fetchData = async () => {
      let dashDataList = [...INDICATORS];
      setIsLoadingInternal(true);
      try {
        const {
          data: { results },
        } = await getAnimalReproductionIndicatorsDashboard({
          groupId,
          farmId,
        });
        if (results) {
          const tableValues = results.values_table;
          if (tableValues && tableValues.length > 0) {
            for (let i = 0; i < tableValues.length; i++) {
              const currentTableValue = tableValues[i];
              const dataDashIndex = dashDataList.findIndex(
                (data) => data.row === currentTableValue.fieldRow
              );
              const dataDash = dashDataList[dataDashIndex];
              if (dataDash) {
                const dataDashUpdated = {
                  ...dataDash,
                  [currentTableValue.fieldColumn]: currentTableValue.dvalue,
                };
                dashDataList[dataDashIndex] = dataDashUpdated;
              }
            }
            setDashboardData(dashDataList);
          }
        } else {
          setDashboardData([...INDICATORS]);
        }
      } catch (error) {
        console.error(error);
        setDashboardData([...INDICATORS]);
        setIsLoadingInternal(false);
      } finally {
        setIsLoadingInternal(false);
      }
    };
    if (groupId && farmId) fetchData();
    return () => {
      setDashboardData([...INDICATORS]);
    };
  }, [groupId, farmId]);

  return (
    <Spin spinning={isLoadingInternal}>
      <Container>
        <thead>
          <tr>
            <th style={{ textAlign: "right" }} colSpan={3}>
              <span className="header">{`Dados referentes à ${moment().format(
                "DD/MM/YYYY"
              )}`}</span>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={handleRefresh}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </th>
          </tr>
          <tr>
            <th colSpan={3}>
              <h2>Indicadores Operacionais</h2>
            </th>
          </tr>
          <tr>
            <th></th>
            <th>Última</th>
            <th>Próxima/Atual</th>
          </tr>
        </thead>
        <tbody>{renderTableData()}</tbody>
      </Container>
    </Spin>
  );
}

export default memo(AnimalReproductionIndicatorDash);
