import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { useLocation } from "react-router-dom";
import useRegister from "../../../../hooks/useRegister";

import codicoesRegras from "../../../../assets/documents/CondicoesRegras.pdf";

import { Checkbox, Col, Icon, notification, Row, Select } from "antd";
import { Container, FormContainer, FormFooterContaier } from "../styles";
import InputStandard from "../../../../components/utils/input";
import PhoneNumberInput from "../../../../components/utils/input/phoneNumberInput";
import NumberFormat from "react-number-format";
import CheckSmallIcon from "../../../../components/utils/icons/check/checkSmall";
import ExclamationSmallIcon from "../../../../components/utils/icons/exclamation/exclamationSmall";

// Services
import {
  sendCodeToEmail,
  verifyExistsByEmail,
} from "../../../../services/authService";
import {
  getCep,
  getOnlyNumber,
  validateCPF,
  validateEmail,
  validatePhone,
} from "../../../../services/helpersMethodsService";
import ZipCodeInput from "../../../../components/utils/input/zipCodeInput";
import {
  getCitiesByState,
  getStatesInfo,
} from "../../../../services/cityService";

const RegisterStep1 = () => {
  const { Option } = Select;
  const [loadingCep, setLoadingCep] = useState(false);
  const [zipCodeValid, setZipCodeValid] = useState(null);

  const [initialStates, setInitialStates] = useState([]);
  const [initialCountries, setInitialCountries] = useState(null);
  const [citiesArray, setCitiesArray] = useState([]);
  const [statesArray, setStatesArray] = useState([]);
  const [countriesArray, setCountriesArray] = useState([]);

  const { setForm, form, goNextStep, setIsLoading } = useRegister();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Digite um e-mail válido")
      .required("É preciso informar um e-mail"),
    fullname: Yup.string().required(),
    document: Yup.string().required(),
    phone: Yup.string().required(),
    zip_code: Yup.string().required(),
    address: Yup.string().required(),
    address_number: Yup.string().required(),
    neighborhood: Yup.string().required(),
  });

  const location = useLocation();
  const formik = useRef(null);
  const fullNameRef = useRef(null);

  /** Redux variables */
  const { translation } = useSelector((state) => state.app);

  /** Effect  */
  useEffect(() => {
    async function handle() {
      try {
        const { data } = await getStatesInfo();

        if (data) {
          setInitialStates(data.results);
          setInitialCountries([
            {
              id: data.results[0].countryId,
              name: data.results[0].countryName,
            },
          ]);
          setStatesArray(data.results);
          setCountriesArray([
            {
              id: data.results[0].countryId,
              name: data.results[0].countryName,
            },
          ]);
        }
      } catch (error) { }
    }
    handle();
  }, []);
  useEffect(() => {
    async function handleVerifyExistsByEmail(email) {
      if ((email != null || email?.trim() !== "") && validateEmail(email)) {
        try {
          await verifyExistsByEmail(email);
        } catch (e) {
          if (e.response?.status === 404) {
            setForm((old) => ({
              ...old,
              emailVerified: true,
              isEmailValid: true,
            }));
            fullNameRef.current.focus();
          } else {
            setForm((old) => ({
              ...old,
              emailVerified: true,
              isEmailValid: false,
            }));
            formik.current.setErrors({ email: "Email invalid" });
            if (e.response?.status === 409) {
              notification.error({
                message: "E-mail já cadastrado em nossa base de dados",
              });
            } else if (
              e.response?.status === 500 &&
              e.response?.data.results.description.includes(
                "query did not return a unique result"
              )
            ) {
              notification.error({
                message: "E-mail já cadastrado em nossa base de dados",
              });
            } else {
              notification.error({
                message: "E-mail inválido.",
              });
            }
          }
        }
      } else {
        if (email == null || email?.trim() === "") {
          notification.error({
            message: "Informe um e-mail.",
          });
          formik.current.setErrors({ email: "Email invalid" });
        } else {
          notification.error({
            message: "E-mail inválido.",
          });
          formik.current.setErrors({ email: "Email invalid" });
        }
      }
    }
    function handle() {
      const { search } = location;
      if (search.includes("email")) {
        const email = search.split("=")[1];
        if (email.match(/([\w\-_]+)?\w+@[\w-_]+(\.\w+){1,}/gim)) {
          setForm((old) => ({
            ...old,
            email,
          }));
          formik.current.setFieldValue("email", email);
          handleVerifyExistsByEmail(email);
        } else {
          notification.error({
            message: "E-mail passado na Url está em um formato inválido",
          });
        }
      }
    }
    handle();
  }, [location, setForm]);

  /** Methods */
  function handleCountryChange(countryName) {
    const country = initialCountries.filter((c) => c.name === countryName);
    const filteredStates = initialStates.filter(
      (s) => s.countryId === country[0].id
    );
    setStatesArray(filteredStates);
  }
  function handleKeyDown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      handleVerifyExistsByEmail(event.target.value.toLowerCase());
    }
    return false;
  }
  async function handleStateChange(stateName) {
    const state = initialStates.filter((s) => s.name === stateName);
    try {
      const { data } = await getCitiesByState(state[0].id);
      if (data) {
        setCitiesArray(data.results);
      }
      formik.setFieldValue("city", undefined);
    } catch (error) { }
  }
  async function handleSubmitForm(values, actions) {
    const { email, phone, fullname, document } = values;
    if (fullname == null || fullname?.trim() === "") {
      notification.error({
        message: "É necessário informar um nome.",
      });
      formik.current.setErrors({ fullname: "Invalid" });
    } else if (!validateCPF(document)) {
      notification.error({
        message: "O CPF informado é invalido, informe outro.",
      });
      formik.current.setErrors({ document: "Invalid" });
    } else if (!validatePhone(phone)) {
      notification.error({
        message: "O Telefone informado é invalido, informe outro.",
      });
      formik.current.setErrors({ phone: "Phone invalid" });
    } else {
      setIsLoading(true);
      try {
        await sendCodeToEmail({
          email: email,
          phone: getOnlyNumber(phone),
          document: getOnlyNumber(document),
          fullname: fullname,
        });
        notification.success({
          message: "Código de validação enviado para o e-mail.",
        });
        setForm((old) => ({
          ...old,
          ...values,
          emailVerified: true,
          isEmailValid: true,
        }));
        setIsLoading(false);
        goNextStep();
      } catch (e) {
        setIsLoading(false);
        if (e.response?.status === 409) {
          notification.error({
            message: "E-mail já cadastrado em nossa base de dados",
          });
          formik.current.setErrors({ email: "Email invalid" });
        } else if (
          e.response?.status === 500 &&
          e.response?.data.results.description.includes(
            "query did not return a unique result"
          )
        ) {
          notification.error({
            message: "E-mail já cadastrado em nossa base de dados",
          });
          formik.current.setErrors({ email: "Email invalid" });
        } else {
          notification.error({
            message: "Erro interno. Contate o suporte.",
          });
        }
      }
    }
  }
  async function handleVerifyExistsByEmail(email) {
    if ((email != null || email?.trim() !== "") && validateEmail(email)) {
      try {
        await verifyExistsByEmail(email);
      } catch (e) {
        if (e.response?.status === 404) {
          setForm((old) => ({
            ...old,
            emailVerified: true,
            isEmailValid: true,
          }));
          fullNameRef.current.focus();
        } else {
          setForm((old) => ({
            ...old,
            emailVerified: true,
            isEmailValid: false,
          }));
          formik.current.setErrors({ email: "Email invalid" });
          if (e.response?.status === 409) {
            notification.error({
              message: "E-mail já cadastrado em nossa base de dados",
            });
          } else if (
            e.response?.status === 500 &&
            e.response?.data.results.description.includes(
              "query did not return a unique result"
            )
          ) {
            notification.error({
              message: "E-mail já cadastrado em nossa base de dados",
            });
          } else {
            notification.error({
              message: "E-mail inválido.",
            });
          }
        }
      }
    } else {
      if (email == null || email?.trim() === "") {
        notification.error({
          message: "Informe um e-mail.",
        });
        formik.current.setErrors({ email: "Email invalid" });
      } else {
        notification.error({
          message: "E-mail inválido.",
        });
        formik.current.setErrors({ email: "Email invalid" });
      }
      setForm((old) => ({
        ...old,
        emailVerified: false,
        isEmailValid: false,
      }));
    }
  }
  async function handleZipCodeSearch(zipCode, props) {
    if (zipCode != null && zipCode.trim().length > 0) {
      if (zipCode.length === 9) {
        setLoadingCep(true);
        try {
          const {
            data: {
              erro,
              logradouro: street,
              uf: state,
              localidade: city,
              bairro: neighborhood,
            },
          } = await getCep(zipCode);
          if (erro) {
            setLoadingCep(false);
            setZipCodeValid(false);
          } else {
            const countries = initialCountries.filter(
              (c) => c.name === "Brasil"
            );
            const states = initialStates.filter((s) => s.name === state);

            const { data } = await getCitiesByState(states[0].id);

            if (data) {
              const cities = data.results.filter(
                (c) => c.name.toLowerCase() === city.toLowerCase()
              );
              setCitiesArray(data.results);
              props.setFieldValue("city", cities[0].name);
            }
            props.setFieldValue("address", street);
            props.setFieldValue("state", states[0].name);
            props.setFieldValue("country", countries[0].name);
            props.setFieldValue("neighborhood", neighborhood);

            setLoadingCep(false);
            setZipCodeValid(true);
          }
        } catch (error) {
          console.error("aqui" + error);
          setLoadingCep(false);
          setZipCodeValid(false);
        }
      } else {
        setLoadingCep(false);
        setZipCodeValid(false);
      }
    }
  }

  return (
    <Container>
      <FormContainer>
        <Formik
          ref={formik}
          initialValues={form}
          validationSchema={validationSchema}
          onSubmit={handleSubmitForm}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            ...props
          }) => (
            <form onSubmit={handleSubmit} autoComplete="chrome-off">
              <span className="title">Cadastro de usuário</span>
              <span className="subTitle">
                Informe os campos abaixo para iniciar o cadastro
              </span>
              {/* Input email */}
              <Row type="flex">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <label className={errors.email ? "error" : ""}>
                    E-mail de cadastro
                  </label>
                </Col>
              </Row>
              <Row type="flex">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <InputStandard
                    border={errors.email ? "error" : "normal"}
                    className="input"
                    width="100%"
                  >
                    <input
                      type="email"
                      autoCapitalize="off"
                      placeholder="Informe aqui..."
                      name="email"
                      value={values?.email || ""}
                      autoComplete="chrome-off"
                      onKeyPress={handleKeyDown}
                      onChange={(e) => {
                        if (
                          e.target.value == null ||
                          e.target.value?.trim() === ""
                        ) {
                          props.setFieldValue("email", "");
                        } else {
                          props.setFieldValue(
                            "email",
                            e.target.value.toLowerCase()
                          );
                        }
                      }}
                      onBlur={() => {
                        if (
                          values?.email !== null ||
                          values?.email?.trim() !== ""
                        ) {
                          handleVerifyExistsByEmail(values?.email);
                        }
                      }}
                    />
                    {form.emailVerified && form.isEmailValid ? (
                      <CheckSmallIcon />
                    ) : errors.email ? (
                      <ExclamationSmallIcon />
                    ) : null}
                  </InputStandard>
                </Col>
              </Row>

              {form.emailVerified && form.isEmailValid && (
                <>
                  {/* Input fullname */}
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            errors.fullname && touched.fullname ? "error" : ""
                          }
                        >
                          Nome completo
                        </label>
                      </Row>
                      <Row>
                        <InputStandard
                          border={
                            errors.fullname && touched.fullname
                              ? "error"
                              : "normal"
                          }
                          className={
                            form.emailVerified && form.isEmailValid
                              ? "input"
                              : "hide"
                          }
                          width="100%"
                        >
                          <input
                            ref={fullNameRef}
                            type="text"
                            placeholder="Informe aqui..."
                            name="fullname"
                            autoComplete="chrome-off"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </InputStandard>
                      </Row>
                    </Col>
                  </Row>
                  {/* Input document */}
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            errors.document && touched.document ? "error" : ""
                          }
                        >
                          CPF
                        </label>
                      </Row>
                      <Row>
                        <InputStandard
                          border={
                            errors.document && touched.document
                              ? "error"
                              : "normal"
                          }
                          className={
                            form.emailVerified && form.isEmailValid
                              ? "input"
                              : "hide"
                          }
                          width="100%"
                        >
                          <NumberFormat
                            type="text"
                            placeholder="Informe aqui..."
                            name="document"
                            value={values.document || undefined}
                            autoComplete="chrome-off"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            format="###.###.###-##"
                          />
                        </InputStandard>
                      </Row>
                    </Col>
                  </Row>
                  {/* Input contact */}
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            errors.phone && touched.phone ? "error" : ""
                          }
                        >
                          Contato
                        </label>
                      </Row>
                      <Row>
                        <InputStandard
                          border={
                            errors.phone && touched.phone ? "error" : "normal"
                          }
                          className="input"
                          width="100%"
                        >
                          <PhoneNumberInput
                            name="phone"
                            value={values.phone || ""}
                            autoComplete="chrome-off"
                            placeholder="(xx) xxxxx-xxxx"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </InputStandard>
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex">
                    <span className="title" style={{ margin: "24px 0 16px 0" }}>
                      Endereço
                    </span>
                  </Row>
                  {/* Input zipcode */}
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            errors.zip_code && touched.zip_code ? "error" : ""
                          }
                        >
                          CEP
                        </label>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                          <InputStandard
                            border={
                              errors.zip_code && touched.zip_code
                                ? "error"
                                : "normal"
                            }
                            className="input"
                            width="100%"
                          >
                            <ZipCodeInput
                              placeholder={
                                translation.farm.form.zipCodePlaceHolder
                              }
                              type="text"
                              value={values?.zip_code || ""}
                              autoComplete="chrome-off"
                              name="zip_code"
                              onChange={handleChange}
                              onBlur={(e) => {
                                handleBlur(e);
                                handleZipCodeSearch(e.target.value, props);
                              }}
                            />
                          </InputStandard>
                        </Col>
                        <Col xs={11} sm={11} md={14} lg={14} xl={14} push={1}>
                          {loadingCep === true ? (
                            <Row style={{ marginBottom: 32, marginTop: 8 }}>
                              <span
                                style={{
                                  color: "#4b4b4b",
                                  fontWeight: "bold",
                                }}
                              >
                                <Icon
                                  type="loading"
                                  style={{ marginRight: 10 }}
                                />
                                Processando
                              </span>
                            </Row>
                          ) : zipCodeValid != null &&
                            zipCodeValid === true &&
                            values.zip_code ? (
                            <Row style={{ marginBottom: 32, marginTop: 8 }}>
                              <span
                                style={{
                                  color: "#33C162",
                                  fontWeight: "bold",
                                }}
                              >
                                <Icon
                                  type="check-circle"
                                  style={{
                                    marginRight: 10,
                                    color: "#33C162",
                                    fontSize: 12,
                                  }}
                                />
                                {translation.zipCodeValid}
                              </span>
                            </Row>
                          ) : zipCodeValid != null &&
                            zipCodeValid === false &&
                            values.zip_code ? (
                            <Row style={{ marginBottom: 32, marginTop: 8 }}>
                              <span
                                style={{
                                  color: "#D44C4C",
                                  fontWeight: "bold",
                                }}
                              >
                                <Icon
                                  type="close-circle"
                                  style={{
                                    marginRight: 10,
                                    color: "#D44C4C",
                                    fontSize: 12,
                                  }}
                                />
                                {translation.zipCodeInvalid}
                              </span>
                            </Row>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* Address */}
                  <Row type="flex" justify="start" gutter={8}>
                    <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                      <Row>
                        <label
                          className={
                            errors.address && touched.address ? "error" : ""
                          }
                        >
                          Endereço
                        </label>
                      </Row>
                      <Row>
                        <InputStandard
                          border={
                            errors.address && touched.address
                              ? "error"
                              : "normal"
                          }
                          className={
                            form.emailVerified && form.isEmailValid
                              ? "input"
                              : "hide"
                          }
                          width="100%"
                        >
                          <input
                            type="text"
                            placeholder="Informe aqui..."
                            value={values?.address}
                            name="address"
                            autoComplete="chrome-off"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </InputStandard>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Row>
                        <label
                          className={
                            errors.address_number && touched.address_number
                              ? "error"
                              : ""
                          }
                        >
                          Número
                        </label>
                      </Row>
                      <Row>
                        <InputStandard
                          border={
                            errors.address_number && touched.address_number
                              ? "error"
                              : "normal"
                          }
                          className={
                            form.emailVerified && form.isEmailValid
                              ? "input"
                              : "hide"
                          }
                          width="100%"
                        >
                          <input
                            type="text"
                            placeholder="Informe aqui..."
                            value={values?.address_number}
                            name="address_number"
                            autoComplete="chrome-off"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </InputStandard>
                      </Row>
                    </Col>
                  </Row>
                  {/* Neighborhood, Country, State, City*/}
                  <Row type="flex" align="middle" justify="start" gutter={8}>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Row>
                        <label>{translation.supplier.form.country}</label>
                      </Row>
                      <Row>
                        <Select
                          placeholder={
                            translation.supplier.form.countryPlaceHolder
                          }
                          value={
                            values.country != null ? values.country : undefined
                          }
                          name="country"
                          disabled={loadingCep || zipCodeValid}
                          onChange={(value) => {
                            props.setFieldValue("country", value);
                            handleCountryChange(value);
                          }}
                        >
                          {countriesArray.length > 0 &&
                            countriesArray.map((c) => (
                              <Option key={c.id} value={c.name}>
                                {c.name}
                              </Option>
                            ))}
                        </Select>
                      </Row>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Row>
                        <label>{translation.farm.form.state}</label>
                      </Row>
                      <Row>
                        <Select
                          placeholder={translation.farm.form.statePlaceHolder}
                          value={
                            values.state != null ? values.state : undefined
                          }
                          name="state"
                          disabled={
                            loadingCep || zipCodeValid || values.country == null
                          }
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => {
                            props.setFieldValue("state", value);
                            handleStateChange(value);
                          }}
                        >
                          {statesArray.length > 0 &&
                            statesArray.map((state) => (
                              <Option key={state.id} value={state.name}>
                                {state.name}
                              </Option>
                            ))}
                        </Select>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                      <Row>
                        <label>{translation.supplier.form.city}</label>
                      </Row>
                      <Row>
                        <Select
                          placeholder={
                            translation.supplier.form.cityPlaceHolder
                          }
                          value={values.city != null ? values.city : undefined}
                          name="city"
                          disabled={
                            loadingCep || zipCodeValid || values.state == null
                          }
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => {
                            props.setFieldValue("city", value);
                          }}
                        >
                          {citiesArray.length > 0 &&
                            citiesArray.map((c) => (
                              <Option key={Math.random()} value={c.name}>
                                {c.name}
                              </Option>
                            ))}
                        </Select>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                      <Row>
                        <label
                          className={
                            errors.neighborhood && touched.neighborhood
                              ? "error"
                              : ""
                          }
                        >
                          Bairro
                        </label>
                      </Row>
                      <Row>
                        <InputStandard
                          border={
                            errors.neighborhood && touched.neighborhood
                              ? "error"
                              : "normal"
                          }
                          className={
                            form.emailVerified && form.isEmailValid
                              ? "input small"
                              : "hide"
                          }
                          width="100%"
                        >
                          <input
                            type="text"
                            placeholder="Informe aqui..."
                            value={values?.neighborhood}
                            name="neighborhood"
                            autoComplete="chrome-off"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </InputStandard>
                      </Row>
                    </Col>
                  </Row>
                  {/* Checkbox term */}
                  <Row type="flex">
                    <Checkbox
                      className={
                        form.emailVerified && form.isEmailValid ? "" : "hide"
                      }
                      checked={values.term}
                      onChange={() => props.setFieldValue("term", !values.term)}
                      style={{
                        fontFamily: "Asap, sans-serif",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "21px",
                        textTransform: "none",
                        color: "#591F74",
                        marginTop: 16,
                      }}
                    >
                      Ao assinalar a caixa você concorda com as{" "}
                      <a
                        href={codicoesRegras}
                        download="Condicoes e Regras de Uso BovExo.pdf"
                      >
                        <strong>condições e regras de uso</strong>
                      </a>{" "}
                      do sistema Bovexo
                    </Checkbox>
                  </Row>
                  <FormFooterContaier>
                    <button
                      type="submit"
                      disabled={values.term === false}
                      className="next"
                    >
                      Próximo
                    </button>
                  </FormFooterContaier>
                </>
              )}
            </form>
          )}
        </Formik>
      </FormContainer>
    </Container>
  );
};

export default RegisterStep1;
