import React, { useEffect, useState } from "react";
import Axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { Spin, Row, Col, DatePicker, } from "antd";
import { Container, EditButton } from "./styles";

// Services
import { getCurrentHarvestByFarmId } from "../../../../services/harvestService";
import { getFarmMortality } from "../../../../services/farmMortalityService";
import { numberMask } from "../../../../services/helpersMethodsService";

// Icons
import MaleIcon from "../../icons/dashboard/male";
import FemaleIcon from "../../icons/dashboard/female";
import PencilIcon from "../../../../components/utils/icons/pencil";
import StopIcon from "../../../../components/utils/icons/stop";
import CheckIcon from "../../../../components/utils/icons/check";

const AnimalMortalityDash = () => {
  // Variables
  const [isLoadingHarvest, setIsLoadingHarvest] = useState(true);
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(false);
  const [isEditingPeriod, setIsEditingPeriod] = useState(false);
  const [mortalityData, setMortalityData] = useState(null);
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [inputStartDate, setInputStartDate] = useState(null);
  const [inputEndDate, setInputEndDate] = useState(null);

  // Redux variables
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  // Effect
  // Get current harvest
  useEffect(() => {
    const signal = Axios.CancelToken.source();
    async function fetchData() {
      setIsLoadingHarvest(true);

      try {
        let {
          data: { results },
        } = await getCurrentHarvestByFarmId({ groupId, farmId, signal });

        if (results) {
          setCustomStartDate(results.startDate);
          setCustomEndDate(results.endDate);
          setInputStartDate(results.startDate);
          setInputEndDate(results.endDate);
        }
        setIsLoadingHarvest(false);
      } catch (error) {
        setIsLoadingHarvest(false);
      }
    }

    fetchData();
    return () => {
      signal.cancel();
    };
  }, [groupId, farmId]);

  // Get mortality
  useEffect(() => {
    const signal = Axios.CancelToken.source();
    async function fetch() {
      setIsLoadingDashboard(true);
      try {
        let {
          data: { results },
        } = await getFarmMortality({
          groupId, farmId,
          startDate: moment(customStartDate).format("YYYY-MM-DD"),
          endDate: moment(customEndDate).format("YYYY-MM-DD"), signal
        });

        setMortalityData(results);

        setIsLoadingDashboard(false);
      } catch (error) {
        setIsLoadingDashboard(false);
      }
    }

    if (customStartDate !== null && customStartDate !== undefined && customEndDate !== null && customEndDate !== undefined) {
      fetch();
    }

    return () => {
      signal.cancel();
    };

  }, [groupId, farmId, customStartDate, customEndDate]);

  function handleCancelEdit() {
    setInputStartDate(customStartDate);
    setInputEndDate(customEndDate);
    setIsEditingPeriod(false);
  }

  function handleConfirmPeriod() {
    setCustomStartDate(inputStartDate);
    setCustomEndDate(inputEndDate);
    setIsEditingPeriod(false);
  }

  return (
    <Container>
      <Spin spinning={isLoadingHarvest || isLoadingDashboard}>
        {/* Header */}
        <Row type="flex" justify="start" align="middle">
          <span className="title">
            Mortalidade
          </span>
        </Row>

        <Row type="flex" justify="start" align="middle">
          {!isEditingPeriod ?
            (
              <Row>
                {customStartDate && customEndDate &&
                  (<>
                    <span className="period">
                      Período {moment(customStartDate).format("DD/MM/YYYY")} até {moment(customEndDate).format("DD/MM/YYYY")}
                    </span>
                    <EditButton
                      type="button"
                      onClick={() => setIsEditingPeriod(!isEditingPeriod)}
                    >
                      <PencilIcon />
                    </EditButton>
                  </>
                  )}
              </Row>

            )
            : (
              <Row type="flex" justify="start" align="middle">
                <DatePicker
                  style={{ width: '110px' }}
                  size="small"
                  name="inputStartDate"
                  format={"DD/MM/YYYY"}
                  value={
                    inputStartDate
                      ? moment(inputStartDate)
                      : undefined
                  }
                  allowClear={false}
                  onChange={(date) =>
                    setInputStartDate(date)
                  }
                />
                <DatePicker
                  style={{ width: '110px' }}
                  size="small"
                  name="inputEndDate"
                  format={"DD/MM/YYYY"}
                  value={
                    inputEndDate
                      ? moment(inputEndDate)
                      : undefined
                  }
                  allowClear={false}
                  onChange={(date) =>
                    setInputEndDate(date)
                  }
                />
                <div>
                  <EditButton
                    type="button"
                    onClick={() => handleCancelEdit()}>
                    <StopIcon isRed={true} />
                  </EditButton>
                  <EditButton
                    type="button"
                    onClick={() => handleConfirmPeriod()}>
                    <CheckIcon color={"#9386aa"} />
                  </EditButton>
                </div>
              </Row>
            )}
        </Row>

        <Row type="flex" justify="start" align="middle" className="row-content">
          <Col span={12} className="content-col">
            <Row type="flex" justify="center" align="middle" gutter={16}>
              <span className="main-value">
                {mortalityData?.totalDeadAnimals || 0} - {numberMask(mortalityData?.percentageDeadAnimals || 0)} %
              </span>
            </Row>
          </Col>

          <Col span={12} className="content-col">
            <Row type="flex" justify="center" align="middle" gutter={8}>
              <MaleIcon />
              <span className="gender-content">
                {mortalityData?.numberDeadMales || 0} - {numberMask(mortalityData?.percentageDeadMales || 0)} %
              </span>
            </Row>
            <Row type="flex" justify="center" align="middle" gutter={8}>
              <FemaleIcon />
              <span className="gender-content">
                {mortalityData?.numberDeadFemales || 0} - {numberMask(mortalityData?.percentageDeadFemales || 0)} %
              </span>
            </Row>
          </Col>
        </Row>
      </Spin>
    </Container>
  );
};

export default AnimalMortalityDash;
