import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as UserActions } from "../../../store/ducks/user";
// Components
import { Container, Footer } from "./styles";
import { Row, Col, Select, Input, Divider, notification, Spin } from "antd";
import ButtonStandard from "../../utils/button";
// Services
import { validateEmail } from "../../../services/helpersMethodsService";
import { storeUserInvite } from "../../../services/userInviteService";

const DrawerInviteUser = ({ callBackMethod }) => {
  const [form, setForm] = useState({
    userIdFrom: null,
    email: null,
    farms: [],
    groupId: null,
    profitCenters: [],
    roleId: null,
  });
  const [errors, setErrors] = useState([]);
  const [loadRequest, setLoadRequest] = useState(false);
  // Redux Variable
  const {
    translation,
    user,
    groupSelected: { id: groupId, name: groupName, farms },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { drawerInviteUserVisible } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // Methods
  function resetDrawer() {
    setErrors([]);
    setForm({
      email: null,
      farms: [],
      groupId: null,
      profitCenters: [],
      roleId: null,
    });
  }
  function closeDrawer() {
    resetDrawer();
    dispatch(UserActions.showOrHideInviteUserDrawer());
  }
  async function saveForm() {
    if (validateForm()) {
      setLoadRequest(true);
      let body = { ...form, userIdFrom: user.id };
      body.email = body.email.toLowerCase();
      if (body.roleId === "665ce451-6cfc-668d-c8af-6852a76bc582")
        body = { ...body, groupId: groupId };
      if (body.farms != null && body.farms.length > 0)
        body = { ...body, groupId: null };
      try {
        await storeUserInvite({
          groupId,
          farmId,
          body,
        });
        notification.success({
          message: "Convite cadastrado com sucesso",
        });
        resetDrawer();
        if (callBackMethod != null) callBackMethod();
        setLoadRequest(false);
        dispatch(UserActions.showOrHideInviteUserDrawer());
      } catch (error) {
        notification.error({
          message: "Houve um erro ao cadastrar o convite",
        });
        setLoadRequest(false);
      }
    }
  }
  function validateForm() {
    let aErrors = [];
    if (form.email === null || form.email.trim() === "") aErrors.push("email");
    if (
      form.email !== null &&
      form.email.trim() !== "" &&
      !validateEmail(form.email)
    )
      aErrors.push("email");
    if (form.roleId === null) aErrors.push("roleId");
    if (
      form.roleId !== null &&
      form.roleId !== "665ce451-6cfc-668d-c8af-6852a76bc582" &&
      form.farms.length === 0
    )
      aErrors.push("farms");
    setErrors(aErrors);
    return aErrors.length > 0 ? false : true;
  }
  async function handleDrawerVisible(visible) {
    if (visible) {
      try {
      } catch (error) {}
    }
  }
  return (
    <Container
      title="Convidar usuário"
      width={700}
      onClose={closeDrawer}
      maskClosable={false}
      visible={drawerInviteUserVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <div className="drawerForm">
        <Spin spinning={loadRequest}>
          <Row type="flex">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <span className="subTitle">
                Preencha os campos abaixo para convidar um colaborador
              </span>
            </Col>
          </Row>
          {errors.length > 0 && (
            <Row type="flex" justify="center" align="middle">
              <label className="error">{translation.error.formError}</label>
            </Row>
          )}
          <Row type="flex" className="rowLabel">
            <label className={errors.includes("email") ? "error" : ""}>
              E-mail*
            </label>
          </Row>
          <Row type="flex">
            <Input
              name="email"
              value={form?.email != null ? form?.email : undefined}
              type="email"
              placeholder={translation.defaultPlaceholder}
              autoCapitalize="off"
              onChange={(e) => {
                setForm({ ...form, email: e.target.value.toLowerCase() });
                setErrors(errors.filter((e) => e !== "email"));
              }}
            />
          </Row>
          <Row type="flex" className="rowLabel">
            <label className={errors.includes("roleId") ? "error" : ""}>
              Perfil de acesso*
            </label>
          </Row>
          <Row type="flex">
            <Select
              name="roleId"
              value={form?.roleId != null ? form?.roleId : undefined}
              placeholder={translation.defaultSelectPlaceholder}
              onChange={(value) => {
                setForm({ ...form, roleId: value });
                setErrors(errors.filter((e) => e !== "roleId"));
              }}
            >
              {/* {(userHasPermissions(["Admin"]) ||
                userHasPermissions(["Admin Group"])) && (
                <Select.Option value="665ce451-6cfc-668d-c8af-6852a76bc582">
                  Administrador de Grupo
                </Select.Option>
              )} */}
              <Select.Option value="00c18839-ec4b-b79d-91c1-d616588dbd90">
                Administrador de Fazenda
              </Select.Option>
              <Select.Option value="42d563d9-215f-4011-b1d1-65b21cd03d55">
                Técnico
              </Select.Option>
            </Select>
          </Row>
          {form?.roleId !== null ? (
            form?.roleId === "665ce451-6cfc-668d-c8af-6852a76bc582" ? (
              <>
                <Row type="flex" className="rowLabel">
                  <label>Grupo*</label>
                </Row>
                <Row type="flex">
                  <Select
                    name="group_id"
                    value={groupId}
                    placeholder={translation.defaultSelectPlaceholder}
                    disabled
                  >
                    <Select.Option value={groupId}>{groupName}</Select.Option>
                  </Select>
                </Row>
              </>
            ) : (
              <>
                <Divider dashed />
                <span className="farmMessage">
                  Selecione a fazenda a ser gerenciada por este usuário
                </span>
                <Row type="flex" className="rowLabel">
                  <label className={errors.includes("farms") ? "error" : ""}>
                    Fazenda(s)*
                  </label>
                </Row>
                <Row type="flex">
                  <Select
                    mode="multiple"
                    name="farms"
                    value={form?.farms != null ? form?.farms : undefined}
                    placeholder={translation.defaultSelectPlaceholder}
                    onChange={(values) => {
                      setForm({ ...form, farms: values });
                      setErrors(errors.filter((e) => e !== "farms"));
                    }}
                  >
                    {farms.length > 0 &&
                      farms.map((f) => (
                        <Select.Option key={f.id} value={f.id}>
                          {f.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Row>
              </>
            )
          ) : null}
        </Spin>
      </div>
      <Footer>
        <Row type="flex">
          <Col span={24} className="buttonsDiv">
            <ButtonStandard
              type="button"
              buttonType="type7"
              onClick={closeDrawer}
            >
              {translation.buttons.cancel}
            </ButtonStandard>

            <ButtonStandard type="button" buttonType="type6" onClick={saveForm}>
              {translation.buttons.save}
            </ButtonStandard>
          </Col>
        </Row>
      </Footer>
    </Container>
  );
};

export default DrawerInviteUser;
