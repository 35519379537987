import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import { Icon, Menu, Row, Col, Table, Dropdown, notification } from "antd";

import { Container, PageTitle } from "./styles";
import TagStatus from "../../components/utils/tagStatus";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import MenuIcon from "../../components/utils/table/icons/menu";
import ButtonStandard from "../../components/utils/button";

// Services
import { findAll, resendEmail } from "../../services/supplierManagementService";
import AllowedComponentTo from "../../components/utils/allowedComponentTo";

const SupplierManagement = (props) => {
  // Variable
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Variable Redux
  const {
    translation,
    user: { id: userId },
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  // Effect
  useEffect(() => {
    async function fetchData(page = 0, sorter = {}, filters = "", size = 10) {
      try {
        setIsLoading(true);
        const {
          data: { results },
        } = await findAll({
          groupId,
          farmId,
          page,
          sorter,
          filters,
          size,
        });
        setIsLoading(false);
        setData(results);
      } catch (error) {
        notification.error({
          message: "Não foi possível retornar os convites",
        });
        setIsLoading(false);
        setData(null);
      }
    }
    fetchData();
  }, [groupId, farmId]);

  // Methods
  async function fetchData(page = 0, sorter = {}, filters = "", size = 10) {
    try {
      setIsLoading(true);
      const {
        data: { results },
      } = await findAll({
        groupId,
        farmId,
        page,
        sorter,
        filters,
        size,
      });
      setIsLoading(false);
      setData(results);
    } catch (error) {
      notification.error({
        message: "Não foi possível retornar os convites",
      });
      setIsLoading(false);
      setData(null);
    }
  }

  function menu(id, record) {
    return (
      <Menu>
        {record != null && record.status === "Pending" && (
          <Menu.Item key="1">
            <Link to={`/admin/supplierManagement/edit/${id}`}>Editar</Link>
          </Menu.Item>
        )}
        {record != null && record.status === "Pending" && (
          <Menu.Item key="2" onClick={() => handleResendEmail(id)}>
            Reenviar convite
          </Menu.Item>
        )}
      </Menu>
    );
  }

  async function handleResendEmail(id) {
    try {
      await resendEmail(id);
      notification.success({
        message: "Reenvio de email realizado com sucesso!",
      });
    } catch (error) {
      notification.error({
        message: "Ocorreu um erro ao tentar reenviar os emails!",
      });
    }
  }

  async function handleTableChange(pagination, filters, sorter) {
    let search = "";
    await fetchData(pagination.current, sorter, search, pagination.pageSize);
  }

  return (
    <Container>
      <Row type="flex" className="rowHeader" align="middle">
        <Col span={8}>
          <PageTitle>{translation.sidebar.supplierManagement}</PageTitle>
        </Col>
        <Col span={16} align="right">
          <ButtonStandard
            buttonType="typeWithoutBackground"
            onClick={() => fetchData()}
          >
            <Icon type="reload" />
          </ButtonStandard>
          <Link to="/admin/supplierManagement/new">
            <ButtonStandard buttonType="type8">Convidar</ButtonStandard>
          </Link>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <Table
            loading={isLoading}
            rowKey="id"
            dataSource={data != null ? data.content : []}
            pagination={{
              total: data != null ? data.totalElements : 0,
              size: data != null ? data.size : 0,
              current: data != null ? data.number + 1 : 0,
              hideOnSinglePage:
                data !== null && Object.entries(data).length !== 0
                  ? data.totalElements > 10
                    ? false
                    : true
                  : true,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
            }}
            size="small"
            scroll={{
              x: true,
            }}
            onChange={handleTableChange}
          >
            <Table.Column
              title={translation.farm.table.columns.status}
              dataIndex="status"
              key="status"
              align="left"
              sorter
              render={(status) =>
                status === "Pending" ? (
                  <TagStatus
                    background="#FFDBBC"
                    borderColor="#FE8D2A"
                    color="#FE8D2A"
                  >
                    Pendente
                  </TagStatus>
                ) : status === "Active" ? (
                  <TagStatus
                    background="#E2FFE5"
                    borderColor="#33C162"
                    color="#33C162"
                  >
                    Ativo
                  </TagStatus>
                ) : (
                  <TagStatus
                    background="#FBC7C7"
                    borderColor="#D44C4C"
                    color="#D44C4C"
                  >
                    Inativo
                  </TagStatus>
                )
              }
            />
            <Table.Column
              title="Razão Social"
              dataIndex="groupName"
              key="name"
              align="left"
              sorter
            />
            <Table.Column
              title="Fantasia"
              dataIndex="groupFantasyName"
              key="fantasyName"
              align="left"
              sorter
            />
            <Table.Column
              title="CNPJ"
              dataIndex="documentNumber"
              key="documentNumber"
              align="left"
              sorter
            />

            <Table.Column
              align="left"
              render={(text, record) => (
                <Dropdown
                  overlay={menu(record.id, record)}
                  trigger={["click"]}
                  key={record.id}
                >
                  <MenuContainer>
                    <MenuIcon />
                  </MenuContainer>
                </Dropdown>
              )}
            />
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(SupplierManagement);
