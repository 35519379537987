import React, { useCallback, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { FinancialProjectContextProvider } from "../../contexts/financialProjectContext";
import useFinancialProjectContext from "../../hooks/useFinancialProjectContext";

import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Icon,
  Input,
  Menu,
  Row,
  Select,
  Table,
  notification,
} from "antd";
import ButtonStandard from "../../components/utils/button";
import MenuIcon from "../../components/utils/table/icons/menu";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import TagStatus from "../../components/utils/tagStatus";
import { CardCustom, Title } from "./styles";

// Form
import DrawerFinancialProject from "../../components/drawers/financialProject";
import { useState } from "react";
import { destroy } from "../../services/financialProjectService";

const FinancialProjectLayout = () => {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { dataList, isLoadingDataList, openDrawer, fetchData } =
    useFinancialProjectContext();

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [operation, setOperation] = useState(null);

  const menu = (id, record) => {
    return (
      <Menu>
        <Menu.Item onClick={() => handleEdit(record)}>
          {translation.table.menu.edit}
        </Menu.Item>
        <Menu.Item onClick={() => handleDelete(id)}>
          {translation.table.menu.delete}
        </Menu.Item>
      </Menu>
    );
  };

  const handleEdit = useCallback(
    (record) => {
      openDrawer(record);
    },
    [openDrawer]
  );
  const handleDelete = useCallback(
    async (id) => {
      try {
        await destroy({ groupId, farmId, id });
        notification.success({
          message: "Projeto financeiro deletado com sucesso.",
        });
        fetchData();
      } catch (error) {
        if (Object.keys(error).includes("response")) {
          const { response } = error;
          if (Object.keys(response).includes("data")) {
            const {
              data: { code: errorCode },
            } = response;
            if (errorCode === 5005) {
              notification.error({
                message: "Erro",
                description: `Este Projeto Financeiro não pode ser excluído, ele já está ligado a Movimentações Financeiras.`,
              });
            } else {
              notification.error({
                message: "Erro",
                description: "Erro ao deletar projeto financeiro.",
              });
            }
          }
        } else {
          notification.error({
            message: "Erro",
            description: "Erro ao deletar projeto financeiro.",
          });
        }
      } finally {
      }
    },
    [farmId, fetchData, groupId]
  );

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetColumnDateSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Row type="flex" gutter={4}>
            <Col>
              <Select
                value={operation && operation[dataIndex]}
                onChange={(value) =>
                  setOperation((prevState) => ({
                    ...prevState,
                    [dataIndex]: value,
                  }))
                }
              >
                <Select.Option value="equal">=</Select.Option>
                <Select.Option value="greaterThan">&gt;</Select.Option>
                <Select.Option value="greaterOrEqualThan">&ge;</Select.Option>
                <Select.Option value="lessThan">&lt;</Select.Option>
                <Select.Option value="lessOrEqualThan">&le;</Select.Option>
              </Select>
            </Col>
            <Col>
              <DatePicker
                value={selectedKeys && selectedKeys[0]}
                format={"DD/MM/YYYY"}
                onChange={(date) => setSelectedKeys(date ? [date] : [])}
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </Col>
          </Row>

          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        if (operation) {
          switch (operation[dataIndex]) {
            case "equal":
              return moment(record[dataIndex]).isSame(value, "days");
            case "greaterThan":
              return moment(record[dataIndex]).isAfter(value, "days");
            case "greaterOrEqualThan":
              return moment(record[dataIndex]).isSameOrAfter(value, "days");
            case "lessThan":
              return moment(record[dataIndex]).isBefore(value, "days");
            case "lessOrEqualThan":
              return moment(record[dataIndex]).isSameOrBefore(value, "days");
            default:
              return;
          }
        }
      },
    }),
    [handleReset, handleSearch, operation]
  );

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        if (dataIndex === "males") {
          return record[dataIndex]
            ?.map((m) => m.animalHandlingNumber.toLowerCase())
            .join(" ")
            .includes(value.toString().toLowerCase());
        } else {
          return record[dataIndex]
            ?.toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase());
        }
      },
    }),
    [handleReset, handleSearch]
  );

  const handleOpenDrawer = () => {
    openDrawer();
  };

  return (
    <CardCustom>
      <Row className="rowHeader" justify="space-between">
        <Col span={8}>
          <Title>{translation.financialProject.title}</Title>
        </Col>
        <Col span={16}>
          <Row type="flex" justify="end" gutter={8}>
            <Col>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={fetchData}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </Col>
            <Col>
              <ButtonStandard buttonType="type8" onClick={handleOpenDrawer}>
                {translation.financialProject.buttonNew}
              </ButtonStandard>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <Table
            loading={isLoadingDataList}
            rowKey="id"
            size="small"
            dataSource={dataList}
            pagination={{
              hideOnSinglePage:
                dataList !== null && dataList.length > 10 ? false : true,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
            }}
          >
            <Table.Column
              title={translation.financialProject.table.columns.status}
              dataIndex="status"
              key="status"
              align="left"
              sorter={(first, second) => {
                return first.status.localeCompare(second.status, "pt-BR", {
                  numeric: false,
                  ignorePunctuation: true,
                });
              }}
              defaultFilteredValue={["Active"]}
              filtered
              filters={[
                {
                  text: translation.status.active,
                  value: "Active",
                },
                {
                  text: translation.status.inactive,
                  value: "Inactive",
                },
              ]}
              filterMultiple
              onFilter={(value, record) => record.status.includes(value)}
              render={(status) =>
                status === "Active" ? (
                  <TagStatus
                    background="#C5F1CA"
                    borderColor="#106518"
                    color="#106518"
                  >
                    {translation.status.active}
                  </TagStatus>
                ) : (
                  <TagStatus
                    background="#FBC7C7"
                    borderColor="#D44C4C"
                    color="#D44C4C"
                  >
                    {translation.status.inactive}
                  </TagStatus>
                )
              }
            />
            <Table.Column
              title={translation.financialProject.table.columns.name}
              dataIndex="name"
              sorter={(first, second) => {
                if (!first.name) {
                  first.name = "";
                }
                return first.name.localeCompare(second.name, "pt-BR", {
                  numeric: false,
                  ignorePunctuation: true,
                });
              }}
              key="name"
              {...handleGetColumnSearchProps("name")}
            />
            <Table.Column
              title={
                translation.financialProject.table.columns.startProjectDate
              }
              dataIndex="startProjectDate"
              sorter={(first, second) => {
                if (!first.startProjectDate) {
                  first.startProjectDate = "";
                }
                return first.startProjectDate.localeCompare(
                  second.startProjectDate,
                  "pt-BR",
                  {
                    numeric: false,
                    ignorePunctuation: true,
                  }
                );
              }}
              key="startProjectDate"
              {...handleGetColumnDateSearchProps("startProjectDate")}
              render={(text) => (
                <span>{moment(text).format("DD/MM/YYYY")}</span>
              )}
            />
            <Table.Column
              title={
                translation.financialProject.table.columns.finishProjectDate
              }
              dataIndex="finishProjectDate"
              sorter={(first, second) => {
                if (!first.finishProjectDate) {
                  first.finishProjectDate = "";
                }
                return first.finishProjectDate.localeCompare(
                  second.finishProjectDate,
                  "pt-BR",
                  {
                    numeric: false,
                    ignorePunctuation: true,
                  }
                );
              }}
              key="finishProjectDate"
              {...handleGetColumnDateSearchProps("finishProjectDate")}
              render={(text) => (
                <span>{text ? moment(text).format("DD/MM/YYYY") : ""}</span>
              )}
            />
            <Table.Column
              title=""
              align="left"
              render={(text, record) => (
                <Dropdown
                  overlay={menu(record.id, record)}
                  trigger={["click"]}
                  key={record.id}
                >
                  <MenuContainer>
                    <MenuIcon />
                  </MenuContainer>
                </Dropdown>
              )}
            />
          </Table>
        </Col>
      </Row>
    </CardCustom>
  );
};

const FinancialProject = () => {
  return (
    <FinancialProjectContextProvider>
      <FinancialProjectLayout />
      <DrawerFinancialProject />
    </FinancialProjectContextProvider>
  );
};

export default FinancialProject;
