import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import { useSelector } from "react-redux";
import { useFinancial } from "../../../../../hooks/useFinancialReducer";
import { Button, Col, Icon, Input, Radio, Row, Select, Table } from "antd";
import RadioButton from "../../../../../components/utils/radioButton";

// Services
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../../../services/helpersMethodsService";
import { findAllCostActivityTypeByGroupIdAndFarmId } from "../../../../../services/costActivityTypeService";
import { useCurrencyContext } from "../../../../../hooks/useCurrencyContext";

const FinancialCashFlowIncome = () => {
  const [listCostType, setListCostType] = useState([
    { costType: "AllTypes", amountAnimals: 0 },
    { costType: "Growth", amountAnimals: 0 },
    { costType: "RecreateFattenPasture", amountAnimals: 0 },
    { costType: "RecreateFattenSemiConfinement", amountAnimals: 0 },
    { costType: "RecreateFattenConfinement", amountAnimals: 0 },
    { costType: "Agriculture", amountAnimals: 0 },
    { costType: "Others", amountAnimals: 0 },
  ]);
  const [isLoadingCostType, setIsLoadingCostType] = useState(false);
  const [listHarvests, setListHarvests] = useState([]);
  const [harvestSelected, setHarvestSelected] = useState(null);
  const [visualization, setVisualization] = useState("nature");
  const [exibition, setExibition] = useState("all");
  const [status, setStatus] = useState("all");
  const [costType, setCostType] = useState(null);
  const [data, setData] = useState([]);

  const { defaultCurrencyCode } = useCurrencyContext();

  // Redux
  const { listFinancialCashFlow, isLoadingListFinancialCashFlow: isLoading } =
    useFinancial();
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const Column = Table.Column;

  // Memo
  const renderColumns = useMemo(() => {
    let fieldTotalForeseen = null;
    let fieldTotalAcomplished = null;

    switch (costType) {
      case "AllTypes":
        fieldTotalForeseen =
          status === "all"
            ? "allTypesTotalForeseen"
            : status === "notSettled"
            ? "allTypesTotalForeseenNotSettled"
            : "allTypesTotalForeseenSettled";
        fieldTotalAcomplished =
          status === "all"
            ? "allTypesTotalAccomplished"
            : status === "notSettled"
            ? "allTypesTotalAccomplishedNotSettled"
            : "allTypesTotalAccomplishedSettled";
        break;
      case "Growth":
        fieldTotalForeseen =
          status === "all"
            ? "growthTotalForeseen"
            : status === "notSettled"
            ? "growthTotalForeseenNotSettled"
            : "growthTotalForeseenSettled";
        fieldTotalAcomplished =
          status === "all"
            ? "growthTotalAccomplished"
            : status === "notSettled"
            ? "growthTotalAccomplishedNotSettled"
            : "growthTotalAccomplishedSettled";
        break;
      case "RecreateFattenPasture":
        fieldTotalForeseen =
          status === "all"
            ? "recreateFattenPastureTotalForeseen"
            : status === "notSettled"
            ? "recreateFattenPastureTotalForeseenNotSettled"
            : "recreateFattenPastureTotalForeseenSettled";
        fieldTotalAcomplished =
          status === "all"
            ? "recreateFattenPastureTotalAccomplished"
            : status === "notSettled"
            ? "recreateFattenPastureTotalAccomplishedNotSettled"
            : "recreateFattenPastureTotalAccomplishedSettled";
        break;
      case "RecreateFattenSemiConfinement":
        fieldTotalForeseen =
          status === "all"
            ? "recreateFattenSemiConfinementTotalForeseen"
            : status === "notSettled"
            ? "recreateFattenSemiConfinementTotalForeseenNotSettled"
            : "recreateFattenSemiConfinementTotalForeseenSettled";
        fieldTotalAcomplished =
          status === "all"
            ? "recreateFattenSemiConfinementTotalAccomplished"
            : status === "notSettled"
            ? "recreateFattenSemiConfinementTotalAccomplishedNotSettled"
            : "recreateFattenSemiConfinementTotalAccomplishedSettled";
        break;
      case "RecreateFattenConfinement":
        fieldTotalForeseen =
          status === "all"
            ? "recreateFattenConfinementTotalForeseen"
            : status === "notSettled"
            ? "recreateFattenConfinementTotalForeseenNotSettled"
            : "recreateFattenConfinementTotalForeseenSettled";
        fieldTotalAcomplished =
          status === "all"
            ? "recreateFattenConfinementTotalAccomplished"
            : status === "notSettled"
            ? "recreateFattenConfinementTotalAccomplishedNotSettled"
            : "recreateFattenConfinementTotalAccomplishedSettled";
        break;
      case "Agriculture":
        fieldTotalForeseen =
          status === "all"
            ? "agricultureTotalForeseen"
            : status === "notSettled"
            ? "agricultureTotalForeseenNotSettled"
            : "agricultureTotalForeseenSettled";
        fieldTotalAcomplished =
          status === "all"
            ? "agricultureTotalAccomplished"
            : status === "notSettled"
            ? "agricultureTotalAccomplishedNotSettled"
            : "agricultureTotalAccomplishedSettled";
        break;
      case "Others":
        fieldTotalForeseen =
          status === "all"
            ? "othersTotalForeseen"
            : status === "notSettled"
            ? "othersTotalForeseenNotSettled"
            : "othersTotalForeseenSettled";
        fieldTotalAcomplished =
          status === "all"
            ? "othersTotalAccomplished"
            : status === "notSettled"
            ? "othersTotalAccomplishedNotSettled"
            : "othersTotalAccomplishedSettled";
        break;
      default:
        return null;
    }

    if (data.length > 0 && listHarvests.length > 0) {
      const cloneData = cloneDeep(data);

      const startDateHarvest = listHarvests.find(
        (h) => h.id === harvestSelected
      )?.startDate;
      const endDateHarvest = listHarvests.find(
        (h) => h.id === harvestSelected
      )?.endDate;

      const filterValuesByHarvest = cloneData.map((d) => {
        d.values = d.values.filter((v) =>
          moment(v.movementDate).isBetween(
            moment(startDateHarvest),
            moment(endDateHarvest),
            "days",
            "[]"
          )
        );
        return d;
      });

      return filterValuesByHarvest[0].values.map((v) => {
        const title = moment(v.movementDate).format("MM/YY");
        const day = v.movementDate;
        return (
          <Column
            title={title}
            dataIndex="values"
            key={title}
            align="center"
            width={180}
            render={(values, record) => {
              let item = values.find((item) => item?.movementDate === day);
              if (record.natureName === "Total") {
                return exibition === "all" ? (
                  <strong>{`${numberMask(
                    getTwoDecimalDigits(item?.[fieldTotalAcomplished]),
                    true,
                    defaultCurrencyCode
                  )} (${numberMask(
                    getTwoDecimalDigits(item?.[fieldTotalForeseen]),
                    true,
                    defaultCurrencyCode
                  )})`}</strong>
                ) : exibition === "foreseen" ? (
                  <strong>{`${numberMask(
                    getTwoDecimalDigits(item?.[fieldTotalForeseen]),
                    true,
                    defaultCurrencyCode
                  )}`}</strong>
                ) : (
                  <strong>{`${numberMask(
                    getTwoDecimalDigits(item?.[fieldTotalAcomplished]),
                    true,
                    defaultCurrencyCode
                  )}`}</strong>
                );
              } else {
                return exibition === "all" ? (
                  <span>{`${numberMask(
                    getTwoDecimalDigits(item?.[fieldTotalAcomplished]),
                    true,
                    defaultCurrencyCode
                  )} (${numberMask(
                    getTwoDecimalDigits(item?.[fieldTotalForeseen]),
                    true,
                    defaultCurrencyCode
                  )})`}</span>
                ) : exibition === "foreseen" ? (
                  <span>{`${numberMask(
                    getTwoDecimalDigits(item?.[fieldTotalForeseen]),
                    true,
                    defaultCurrencyCode
                  )}`}</span>
                ) : (
                  <span>{`${numberMask(
                    getTwoDecimalDigits(item?.[fieldTotalAcomplished]),
                    true,
                    defaultCurrencyCode
                  )}`}</span>
                );
              }
            }}
          />
        );
      });
    } else {
      return null;
    }
  }, [
    costType,
    data,
    exibition,
    status,
    harvestSelected,
    listHarvests,
    defaultCurrencyCode,
  ]);

  // Methods
  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);
  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);
  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return dataIndex === "depreciation"
          ? Number(record[dataIndex]) === Number(value)
          : record[dataIndex]
              ?.toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase());
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
        }
      },
    }),
    [handleReset, handleSearch]
  );
  const handleChangeHarvest = useCallback((id) => {
    setHarvestSelected(id);
  }, []);
  const handleChangeVision = useCallback((vision) => {
    setVisualization(vision);
  }, []);

  // Tables
  const TableGroupByNature = (
    <Table
      loading={isLoading}
      rowKey="id"
      size="small"
      dataSource={data != null ? data : []}
      pagination={false}
      scroll={{
        x: true,
        y: true,
      }}
    >
      <Column
        title={translation.financial.cashFlow.table.columns.nature}
        dataIndex="natureName"
        key="natureName"
        width={200}
        fixed
        align="left"
        sorter={(first, second) => {
          return first.natureName.localeCompare(second.natureName, "pt-BR", {
            numeric: false,
            ignorePunctuation: true,
          });
        }}
        {...handleGetColumnSearchProps("natureName")}
        render={(text) =>
          text === "Total" ? <strong>Total: </strong> : <span>{text}</span>
        }
      />
      <Table.ColumnGroup
        title={translation.financial.cashFlow.table.columns.values}
      >
        {renderColumns}
      </Table.ColumnGroup>
    </Table>
  );
  const TableGroupByClass = (
    <Table
      loading={isLoading}
      rowKey="id"
      size="small"
      dataSource={data != null ? data : []}
      pagination={{
        hideOnSinglePage: data !== null && data.length > 10 ? false : true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
      }}
      scroll={{
        x: true,
        y: true,
      }}
    >
      <Column
        title={translation.financial.cashFlow.table.columns.nature}
        dataIndex="natureName"
        key="natureName"
        fixed
        width={200}
        align="left"
        sorter={(first, second) => {
          return first.natureName.localeCompare(second.natureName, "pt-BR", {
            numeric: false,
            ignorePunctuation: true,
          });
        }}
        {...handleGetColumnSearchProps("natureName")}
      />
      <Column
        title={translation.financial.cashFlow.table.columns.class}
        dataIndex="className"
        key="className"
        fixed
        width={200}
        align="left"
        sorter={(first, second) => {
          if (!first.className) {
            first.className = "";
          }
          return first.className.localeCompare(second.className, "pt-BR", {
            numeric: false,
            ignorePunctuation: true,
            sensitivity: "base",
          });
        }}
        {...handleGetColumnSearchProps("className")}
      />
      <Table.ColumnGroup
        title={translation.financial.cashFlow.table.columns.class}
      >
        {renderColumns}
      </Table.ColumnGroup>
    </Table>
  );

  // Effects
  useEffect(() => {
    if (
      listFinancialCashFlow != null &&
      listFinancialCashFlow?.harvests?.length > 0
    ) {
      setListHarvests(listFinancialCashFlow.harvests);
      setHarvestSelected(
        listFinancialCashFlow.harvests.find((h) => h.current)?.id
      );
      let newData = [];
      if (visualization === "nature") {
        newData = cloneDeep(
          listFinancialCashFlow.valuesGroupByNature.filter(
            (n) => n.natureType === "income"
          )
        );
      } else {
        newData = cloneDeep(
          listFinancialCashFlow.valuesGroupByClass.filter(
            (n) => n.natureType === "income"
          )
        );
      }
      setData(newData);
    }
  }, [listFinancialCashFlow, visualization]);

  useEffect(() => {
    async function fetch() {
      setIsLoadingCostType(true);
      try {
        const {
          data: { results },
        } = await findAllCostActivityTypeByGroupIdAndFarmId({
          groupId,
          farmId,
        });
        setListCostType([...results]);
        const max = results.reduce((prev, current) =>
          prev.amountAnimals > current.amountAnimals ? prev : current
        );
        setCostType(max.costType);
      } catch (error) {
      } finally {
        setIsLoadingCostType(false);
      }
    }
    fetch();
  }, [farmId, groupId]);

  return (
    <>
      <Row
        type="flex"
        justify="start"
        align="middle"
        gutter={16}
        className="filterRow"
      >
        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
          <Row>
            <label className="filterLabel">
              {translation.financial.cashFlow.filters.visualization}
            </label>
          </Row>
          <Row>
            <Radio.Group
              name="visualization"
              value={visualization}
              onChange={(e) => handleChangeVision(e.target.value)}
            >
              <RadioButton value={"nature"}>Por Natureza</RadioButton>
              <RadioButton value={"class"}>Por Classe</RadioButton>
            </Radio.Group>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={16} lg={12} xl={8} xxl={8}>
          <Row>
            <label className="filterLabel">
              {translation.financial.cashFlow.filters.exibition}
            </label>
          </Row>
          <Row>
            <Radio.Group
              name="exibition"
              value={exibition}
              onChange={(e) => setExibition(e.target.value)}
            >
              <RadioButton value={"foreseen"}>Orçado</RadioButton>
              <RadioButton value={"accomplished"}>Realizado</RadioButton>
              <RadioButton value={"all"}>Realizado x Orçado</RadioButton>
            </Radio.Group>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={4} xxl={4}>
          <Row>
            <label className="filterLabel">
              {translation.financial.cashFlow.filters.harvest}
            </label>
          </Row>
          <Row>
            <Select
              style={{ width: "100%" }}
              name="harvestSelected"
              value={harvestSelected || undefined}
              placeholder={translation.defaultSelectPlaceholder}
              onChange={(value) => handleChangeHarvest(value)}
            >
              {listHarvests.map((h) => (
                <Select.Option key={h.id} value={h.id}>{`${moment(
                  h.startDate
                ).format("MM/YYYY")} à ${moment(h.endDate).format(
                  "MM/YYYY"
                )}`}</Select.Option>
              ))}
            </Select>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
          <Row>
            <label className="filterLabel">
              {translation.financial.cashFlow.filters.costType}
            </label>
          </Row>
          <Row>
            <Select
              style={{ width: "100%" }}
              name="costType"
              value={costType || undefined}
              placeholder={translation.defaultSelectPlaceholder}
              loading={isLoadingCostType}
              onChange={(value) => {
                setCostType(value);
              }}
            >
              {listCostType.map((l) => (
                <Select.Option key={l.costType} value={l.costType}>
                  {`${translation.costType[l.costType]}`}
                </Select.Option>
              ))}
            </Select>
          </Row>
        </Col>
      </Row>
      <Row
        type="flex"
        justify="start"
        align="middle"
        gutter={16}
        className="filterRow"
      >
        <Col xs={24} sm={24} md={16} lg={12} xl={8} xxl={8}>
          <Row>
            <label className="filterLabel">
              {translation.financial.cashFlow.filters.status}
            </label>
          </Row>
          <Row>
            <Radio.Group
              name="status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <RadioButton value={"all"}>Todos</RadioButton>
              <RadioButton value={"notSettled"}>Em aberto</RadioButton>
              <RadioButton value={"settled"}>Baixado</RadioButton>
            </Radio.Group>
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {visualization === "nature" ? TableGroupByNature : TableGroupByClass}
        </Col>
      </Row>
    </>
  );
};

export default FinancialCashFlowIncome;
