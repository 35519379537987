import styled, { keyframes } from "styled-components";
import { Col, Divider, Popconfirm } from "antd";

const tabSaleScenarioInfoAnimation = keyframes`
 0% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: calc(100% + 10px);
  margin-right: -10px;
  overflow: hidden;
  .ant-row-flex {
    width: 100%;
  }
  .mainRow {
    width: calc(100% + 10px);
    margin-right: -10px;
  }
  .titleContainer {
    display: flex;
    align-items: center;
    .ant-select {
      width: 300px !important;
      margin-left: 15px;
    }

    .checkIconBovexo {
      margin-left: 10px;
    }

    .inputResultId {
      width: 300px !important;
      margin-left: 15px;
    }

    button.editIcon {
      background: transparent;
      border: none;
      color: #4a85ae;
      margin-left: 5px;
      cursor: pointer;
    }
  }
`;

export const ColTable = styled(Col)`
  padding-top: 27px !important;
  padding-right: 15px !important;
  height: 100%;
  overflow-y: auto;
  .breadCrumbsContainer {
    padding-bottom: 20px;
    border-bottom: 1px solid #a5a5a5;
  }
  .content {
    .message {
      font-family: Asap, sans-serif;
      font-weight: normal;
      font-size: 12px;
      color: #4b4b4b;
    }
    .ant-row-flex {
      margin-top: 15px !important;
    }
    .rowFarmSell {
      padding: 0px 0px 0px 2px;
    }
  }
`;

export const ColSaleScenarioInfo = styled(Col)`
  overflow-y: auto;
  padding-top: 27px !important;
  border-left: 3px solid;
  border-image: linear-gradient(
      180deg,
      rgba(198, 222, 82, 1) 90%,
      rgba(144, 116, 191, 1) 100%
    )
    1;
  background: #f5f5f5 !important;
  .headerContainer {
    padding-right: 20px !important;
    padding-left: 10px !important;
  }
  span.spanBaseline {
    font-family: "Asap", sans-serif;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #4b4b4b;
    svg {
      margin-right: 5px;
    }
  }
`;

export const Title = styled.h1`
  font-family: Asap;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: #4b4b4b;
  margin: 0;
`;

export const Board = styled.div`
  padding: 15px 0px;
  background: #fcfcfc;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.09);
  div.content {
    display: flex;
    justify-content: space-between;
    padding: 0px 15px 0px 24px;
    &.purple {
      span {
        color: #9074bf !important;
      }
    }
    div.group {
      display: flex;
      flex-direction: column;
      min-width: 91px;
      span {
        font-family: Asap;
        font-weight: normal;
        font-size: 12px;
        text-align: left;
        color: #4b4b4b;

        strong {
          font-size: 16px;
        }

        &.differenceValue {
          color: #a5a5a5;
          position: relative;

          i {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
    }
  }
`;

export const CustomDivider = styled(Divider)`
  margin: 10px 0 !important;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 10px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;

export const TabSaleScenarioInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: -moz-calc(100% - 40px);
  height: -webkit-calc(100% - 40px);
  height: calc(100% - 40px);
  min-height: calc(100% - 40px);
  overflow: hidden;

  .stretch-component {
    width: 100%;
  }

  .hide-col-selection {
    th.ant-table-selection-column {
      visibility: hidden;
    }
  }

  .disabled-row {
    background-color: #dcdcdc;
    pointer-events: none;
  }

  .lockDetails {
    cursor: pointer;
    border: unset;
    background-color: unset;
    align-items: center;
    color: #4a85ae;
    svg {
      margin-left: 6px;
    }
  }
`;

export const TabSaleScenarioInfoContent = styled.div`
  height: 600px;
  min-height: 600px;
  padding-right: 20px !important;
  padding-left: 10px !important;
  overflow-x: hidden;
  overflow-y: auto;

  display: ${(props) => (props.active ? "block" : "none")};
  opacity: ${(props) => (props.active ? 1 : 0)};
  animation: ${tabSaleScenarioInfoAnimation} 1s
    cubic-bezier(0.39, 0.575, 0.565, 1) both;
  -webkit-animation: ${tabSaleScenarioInfoAnimation} 1s
    cubic-bezier(0.39, 0.575, 0.565, 1) both;

  .ant-row-flex {
    margin-top: 15px !important;
  }

  table {
    width: 100%;
    &.tableWeightPrice {
      tbody {
        tr {
          td {
            padding-top: 10px;
          }
        }
        tr:not(:last-of-type) {
          td {
            padding-bottom: 10px;
            border-bottom: 1px solid #e8e5e5;
          }
        }
      }
    }

    .alignRight {
      text-align: right;
      button {
        background: transparent;
        border: none;
        margin-left: 5px;
        cursor: pointer;
      }

      &.dietStrategy {
        color: #9074bf;
        font-size: 16px;
      }
    }
    .alignCenter {
      text-align: center;
    }

    .lotTableTd {
      color: #9074bf;
      font-style: italic;
      font-weight: normal;
    }

    tbody {
      tr:first-of-type {
        td {
          padding-top: 15px;
        }
      }
      tr:not(:last-of-type) {
        td {
          padding-bottom: 15px;
        }
      }
    }
  }

  span {
    font-family: Asap;
    color: #4b4b4b;
  }

  span.spanDietStrategy {
    font-weight: bold;
    color: #9074bf;
    text-transform: uppercase;
  }
  span.spanCommitment {
    font-weight: bold;
    color: #769bb5;
    text-transform: uppercase;
  }
  span.spanViolation {
    font-weight: bold;
    color: #cf7070;
    text-transform: uppercase;
  }
  span.violationValue {
    font-size: 16px;
  }
  span.titleParameters {
    font-weight: bold;
    color: #9074bf;
    text-transform: uppercase;
  }
  .exclamationIcon {
    color: #cf7070;
  }

  @media screen and (max-height: 700px) {
    height: 360px;
    min-height: 360px;
  }
`;

export const TabSaleScenarioInfoButtonsContainer = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-top: 1px dashed #e8e5e5 !important;
`;

export const TabSaleScenarioInfoButtons = styled.button`
  width: 11px;
  height: 11px;
  border: 2px solid #707070;
  border-radius: 50%;
  background: ${(props) => (props.active ? "#707070" : "transparent")};
  &:not(:first-child) {
    margin-left: 5px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const CardCalendar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #fcfcfc;
  padding: 10px 7px 10px 15px;
  border-right: 3px solid #a5a5a5;
  overflow-x: none;

  div.titleContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 170px;
  }

  div.datesContainer {
    flex: 1;
    overflow-x: auto;
    justify-content: flex-start;
  }
`;

export const CardCalendarTitle = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 12px;
  text-align: left;
  color: #9074bf;
  text-transform: uppercase;
`;

export const CardCalendarDate = styled.button`
  min-width: 75px;
  padding: 6px 12px;
  border-radius: 12.5px;
  background: transparent;
  border: 1.5px solid #9074bf;
  cursor: pointer;
  span {
    font-family: Asap;
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    color: #9074bf;
  }
  &.selected {
    background: #9074bf;
    span {
      font-family: Asap;
      font-weight: bold;
      font-size: 12px;
      text-align: center;
      color: #fff;
    }
  }
  &.locked {
    border: 3px solid blue;
  }
  &:not(:first-of-type) {
    margin-left: 9px;
  }
`;

export const ButtonNewFarmSell = styled.button`
  width: 100%;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  background: #684e94;
  border: none;
  cursor: pointer;
  span {
    font-family: Asap, sans-serif;
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
  }

  &:disabled {
    cursor: not-allowed;
    background: #c4c4c4;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 13px;
  justify-content: flex-start;

  div.title {
    padding-right: 14px;
    border-right: 1px solid #e8e5e5;
    span {
      font-weight: bold;
      font-size: 16px;
    }
  }
  div.amount {
    padding: 0 14px;
    span {
      font-family: Asap;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: #a9c133;
    }
  }
`;

export const CardAllAnimalsSold = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #fcfcfc;
  margin-top: 20px;
  padding: 10px 10px;
  .title {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    text-align: left;
    color: #c4c4c4;
  }
  .message {
    display: flex;
    align-items: center;
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #41c36f !important;
    svg {
      margin-right: 5px;
    }
  }
`;

export const ButtonCustom = styled.button`
  height: 31px;
  padding: 0 10px;
  background: transparent;
  font-family: Asap;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  border: 2px solid ${(props) => props.border};
  border-radius: 5px;
  color: ${(props) => props.color};
  cursor: pointer;

  svg {
    height: 14px !important;
    width: 14px !important;
  }

  &:hover {
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    background: "#f0f0f0";
    color: "#adadad";
    border-color: "#adadad";
    cursor: not-allowed;
  }
`;

export const ButtonLockPrice = styled.button`
  height: 31px;
  padding: 0 10px;
  background: transparent;
  font-family: Asap;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  border: 2px solid ${(props) => props.border};
  border-radius: 5px;
  color: ${(props) => props.color};
  cursor: pointer;
  width: 80%;

  svg {
    height: 24px !important;
    width: 24px !important;
  }

  .div-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.2);
  }

  span {
    margin-left: 5px;
  }

  &:disabled {
    background: "#f0f0f0";
    color: "#adadad";
    border-color: "#adadad";
    cursor: not-allowed;
  }
`;

export const ButtonExport = styled.button`
  height: 31px;
  padding: 0 10px;
  background: ${(props) => props.backgroundColor};
  font-family: Asap;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  border: 2px solid ${(props) => props.border};
  border-radius: 5px;
  color: ${(props) => props.color};
  cursor: pointer;

  svg {
    height: 20px !important;
    width: 20px !important;
  }

  &:hover {
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    background: #f0f0f0 !important;
    color: #adadad !important;
    border-color: "#adadad";
    cursor: not-allowed;
  }
`;

export const PopConfirmCustom = styled(Popconfirm)`
  &.ant-popover-buttons {
    display: flex !important;
    justify-content: space-between !important;
  }

  &.ant-btn-primary {
    padding-left: 180px !important;
    background: #4a85ae;
  }
`;
