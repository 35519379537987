import styled from "styled-components";

export const TableScore = styled.table`
  font-family: "Asap", sans-serif;
  border: 2px solid #ffffff;
  background-color: #eeeeee;
  text-align: center;
  border-collapse: collapse;
  width: 100%;

  display: block;
  overflow-x: auto;
  white-space: nowrap;

  td,
  th {
    padding: 6px 4px;
    min-width: 50px;
    &.colCategory {
      min-width: 100px;
    }
    &.colBreed {
      min-width: 200px;
    }
  }

  tbody {
    display: table;
    width: 100%;
  }

  tbody td {
    font-size: 13px;
    color: #4b4b4b;

    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;

    button {
      padding: 0;
      svg {
        margin: 0;
      }
    }
  }

  thead {
    display: table;
    width: 100%;
    background: #ffffff;
    border-bottom: 4px solid #9074bf;
  }

  thead th {
    font-size: 15px;
    font-weight: bold;
    color: #9074bf;
    text-align: center;
    border-left: 2px solid #9074bf;
  }

  thead th:first-child {
    border-left: none;
  }
`;
