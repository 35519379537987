import styled from "styled-components";
import { Card } from "antd";

export const Container = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  //background-color: #e8e5e5;
  padding: 0px;

  .alertSelected {
    background: #fe8d2a;
    border: 1px solid #fe8d2a;
  }

  .alertDate {
    .ant-calendar-picker-input.ant-input {
      line-height: 1.5;
      padding: 4px 5px;
      width: 85px;
      border: 0px;
      color: #d44c4c !important;
      font-weight: bold;
    }

    svg {
      height: 10px;
      width: 10px;
      path {
        fill: #d44c4c !important;
      }
    }
  }

  .cardDashAnimal {
    height: 180px !important;
    margin-top: 10px;

    .ant-card-body {
      padding: 20px !important;
    }
  }

  .cardGrid {
    margin-top: 10px;
    margin-bottom: 10px;
    .btn-comparable {
      &:disabled {
        background: #f0f0f0 !important;
        color: #adadad !important;
      }
    }
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }

  .link {
    color: #4b4b4b !important;
    text-decoration: none !important;
    &:hover {
      border-bottom: 1px solid #4b4b4b !important;
    }
  }

  button.linkLot {
    background: transparent;
    border: 0;
    cursor: pointer;
  }

  .row-centralized{
    margin-top: 13px;
    margin-left: 20px;
    flex-direction: column;
    align-items: center;
  }

  .ant-timeline-item-head-blue{
    background-color: black;
    color: black;
    border-color: black;
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
    height: 600px;
    overflow-y: scroll;
  }

  .paid{
    background-color: #e8e5e5;
  }

  .current{
    color: white;
    background-color: #D44C4C;
  }

  .next{
    border: 2px solid black;
  }

  .ant-carousel .slick-dots li.slick-active button{
    width: 15px;
    background-color: #707070;
  }

  .ant-carousel .slick-dots li button{
    width: 10px;
    height: 10px;
    background-color: #707070;
    border-radius: 5px;
  }

  .previousButton{
    width: 100%;
    height: 39px;
    background-color: #591F74;
  }

  .ant-radio-wrapper{
    padding-left: 5px;
    display: block;
    height: 40px;
    line-height: 40px;
    background-color: white;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    margin-bottom: 13px;
    width: 100%;
  }

  .ant-radio-wrapper svg{
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: middle;
  }

  // Small devices (landscape phones, less than 768px)
  @media all and (max-width: 767.98px) {
    .card-col {
      display: none;
    }
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    .card-col {
      display: none;
    }
  }
`;

export const PageTitle = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  span {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #707070;
    text-transform: uppercase;
  }
  #bovexoPaymentIcon {
    width: 25px;
    height: 25px;
    margin-right: 9px;
    
    path {
      stroke: #707070;
    }

    circle {
      stroke: #707070;
    }
  }
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;

export const SubTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;

export const TotalInvoice = styled.div`
  width: 100%;
  background-color: #D44C4C;
  height: 52px;
  border-radius: 10px;
  text-align: center;

  span{
    font-size: 20px;
    font-weight: bold;
    font-family: "Asap", sans-serif;
    line-height: 52px;
    color: white;
  }
`;

export const CardCustom = styled(Card)`
  margin-top: 10px !important;
  border-radius: 7px !important;
  
  box-shadow: 0px 0px 10px #d8d8d8 !important;
  padding: 0px !important;
  .ant-card-body {
    padding-top: 14px;
    background-color: #F0EDF1;
  }
  .rowHeader {
    margin-bottom: 17px;
  }
`;

export const ListInvoicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  flex-wrap: nowrap;
  background: transparent;
  overflow-x: auto;
`;

export const ListInvoicesTitleContainer = styled.div`
  display: flex;
  background: #4b4b4b;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  align-items: center;
  justify-content: center;
  padding: 0px 30px;
  span {
    font-family: Asap;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    color: #fff;
  }
`;

export const ListInvoicesItemContainer = styled.div`
  flex: 1;
  display: flex;
  margin-left: 5px;
  //border-right: 5px solid #c6de52;
  white-space: nowrap;
  flex-wrap: nowrap;
  
  overflow-x: auto;
  padding: 0px 10px;

  

  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
    overflow-x: scroll;
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
    overflow-x: scroll;
  }


`;

export const ListInvoicesItem = styled.div`
  //display: inline-flex;
  //width: 100%;
  min-width: 100px;
  height: 100px;
  //max-height: 78px;
  border-radius: 5px;
  background: transparent;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
  transition: all 0.2s ease-in-out;
  text-align: center

  

  // &.selected {
  //   background: #a9c133;
  //   span.info {
  //     font-family: Asap;
  //     font-weight: normal;
  //     font-size: 14px;
  //     text-align: left;
  //     color: #fff;
  //   }
  // }

  // &:first-of-type {
  //   margin-left: 15px;
  // }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
    //background: #c4c4c4;
    .time {
      span {
        color: #4b4b4b !important;
      }
    }
  }

  div.itemContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self:center;

    div.iconContainer {
      width: 100%;
    }

    div.timeContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      div.time {
        width: 100%;
        display: flex;
        flex-direction: column;
        span {
          font-family: Asap;
          font-weight: normal;
          font-size: 14px;
          text-align: left;
          color: #a5a5a5;
        }
      }

      // div.icons {
      //   display: flex;
      //   flex-direction: row;
      //   justify-content: space-between;
      //   align-items: center;
      //   #bovexoAlertIcon {
      //     margin-right: 5px;
      //   }
      //   div.circle {
      //     width: 7.38px;
      //     height: 7.38px;
      //     border-radius: 50%;
      //     background: #a9c133;

      //     &.red {
      //       background: #d44c4c;
      //     }
      //   }
      // }
    }
  }

  
`;

export const ListItemTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
  text-transform: uppercase;
  color: #4B4B4B;
`;

export const ListItemSubTitle = styled.span`
  font-size: 14px;
  font-family: "Asap", sans-serif;
  color: #4B4B4B;
`;

export const ListItemCircle = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  background: red;
  margin-right: 5px;
`;