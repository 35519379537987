import styled from "styled-components";
import { Divider, Button } from "antd";

export const Container = styled.div`
  height: 100%;
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 20px;
  overflow-x: hidden;
  overflow-y: hidden;

  .headerButtonsAndMessagens {
    margin-bottom: 15px;
  }

  .back-container {
    display: flex;
    align-items: center;
    padding-right: 10px;
    cursor: pointer;
  }

  .back {
    position: relative;
    font-family: "Asap", sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #d44c4c;
    margin-left: 5px;
  }

  .divider {
    border-left: 2px solid #c4c4c4;
    height: 19px;
    width: 2px;
    margin-right: 10px;
  }

  svg {
    cursor: pointer;
  }

  #no-cursor {
    cursor: inherit !important;
  }
`;

export const Header = styled.div``;

export const Main = styled.div`
  height: -moz-calc(100% - 192px);
  height: -webkit-calc(100% - 192px);
  height: calc(100% - 192px);
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: #684e94 #e8e5e5;
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background-color: #e8e5e5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #684e94;
  }
`;

export const CustomDivider = styled(Divider)`
  margin: 8px 0 8px 0 !important;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 8px 0 8px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;

export const Title = styled.h1`
  font-family: Asap;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  color: #4b4b4b;
  margin: 0;
`;

export const FavoriteCard = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 80px;
  border-right: 4px solid #c4c4c4;
  border-radius: 8px 0px 0px 8px;
  background: #f5f5f5;
  margin-bottom: 12px;

  .flex {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scrollbar-color: #684e94 #e8e5e5;
    scrollbar-width: thin;

    .ant-spin-nested-loading,
    .ant-spin-nested-loading > .ant-spin-container {
      display: flex;
      width: 100%;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
      background-color: #e8e5e5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #684e94;
    }
  }

  .disfavorContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    .disfavor {
      display: flex;
      width: 200px;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      padding-right: 10px;
      text-align: right;
      font-weight: bold;
      text-transform: uppercase;
      color: #d44c4c;
      cursor: pointer;
      border: 0;
      background: transparent;
    }

    .disfavor:hover {
      color: rgb(175, 54, 54);
    }
  }

  span {
    font-family: "Asap", sans-serif;
    text-align: left;
    font-size: 14px;
    color: #4b4b4b;

    &.subtitle {
      font-weight: bold;
      color: #9074bf;
      font-size: 12px;
    }
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  width: 220px;
  height: 245px;
  margin: -6px -8px;
  flex-direction: column;
  .title {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;
  }
  .blueTitle {
    font-family: Asap;
    font-weight: bold;
    font-style: italic;
    font-size: 14px;
    text-align: left;
    color: #4a85ae;
  }
  .graylabel {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #a5a5a5;
  }

  .ant-row-flex-space-between {
    margin: 3px;
  }
`;

export const InitialCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 54px;
  width: 150px;
  border-radius: 8px 0px 0px 8px;
  background: #fcfcfc;
  border-right: 3px solid #c4c4c4;

  span {
    font-family: "Asap", sans-serif;
    text-align: left;
    font-size: 14px;
    color: #4b4b4b;

    &.title {
      font-weight: bold;
      color: #9074bf;
    }

    &.subtitle {
      font-weight: bold;
      color: #9074bf;
      font-size: 18px;
      padding: 10px;
    }

    &.value {
      font-weight: bold;
    }
  }
`;

export const SaleCardFavorite = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 70px;
  width: 150px;
  border-radius: 8px;
  background: #fcfcfc;
  margin: 4px 5px;
  padding: 4px;
  cursor: pointer;

  &:hover {
    background: #eee;
  }

  &.green {
    border: 2px solid #70b13f;
    width: 152px;
  }

  &.purple {
    border: 2px solid #684e94;
    width: 152px;
  }

  .favortiteStarButton {
    border: 0;
    background: transparent;
    &:hover {
      cursor: pointer;
    }
  }

  span {
    font-family: "Asap", sans-serif;
    text-align: left;
    font-size: 13px;
    color: #4b4b4b;

    &.date {
      color: #707070;
    }

    &.price {
      font-weight: bold;
      color: #707070;
    }

    &.weight {
      color: #c4c4c4;
    }

    &.subtitle {
      font-weight: bold;
      color: #9074bf;
      font-size: 18px;
      padding: 10px;
    }

    &.value {
      font-weight: bold;
    }
  }
`;

export const FilterCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  min-height: 40px;
  width: 820px;
  border-radius: 8px 20px 20px 8px;
  background: #f5f5f5;
  box-shadow: 0px 3px 4px #d8d8d8;
  scrollbar-color: #684e94 #e8e5e5;
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background-color: #e8e5e5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #684e94;
  }

  .flex {
    display: flex;
    flex-direction: row;
  }

  .disfavor {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
    text-align: right;
    font-weight: bold;
    text-transform: uppercase;
    color: #d44c4c;
    cursor: pointer;
  }

  .disfavor:hover {
    color: rgb(175, 54, 54);
  }

  span {
    font-family: "Asap", sans-serif;
    text-align: left;
    font-size: 14px;
    color: #4b4b4b;

    &.label {
      font-weight: bold;
      color: #9074bf;
      font-size: 12px;
      padding: 0px 4px;
    }
  }

  .initial {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 45px;
    border-radius: 8px 0px 0px 8px;
    background: #9074bf;
    padding: 8px;

    span {
      font-family: "Asap", sans-serif;
      text-align: left;
      font-size: 14px;
      color: #4b4b4b;

      &.title {
        font-weight: bold;
        color: #9074bf;
      }

      &.subtitle {
        font-weight: bold;
        color: #9074bf;
        font-size: 18px;
        padding: 10px;
      }

      &.value {
        font-weight: bold;
      }
    }
  }
`;

export const PriceCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  border-right: 4px solid #c4c4c4;
  border-radius: 8px 0px 0px 8px;
  background: #f5f5f5;
  box-shadow: 0px 3px 2px #d8d8d8;
  margin-bottom: 6px;

  .flex {
    display: flex;
    flex: 1 100%;
    flex-direction: row;
    padding: 5px;
    overflow-x: auto;
    scrollbar-color: #684e94 #e8e5e5;
    scrollbar-width: thin;

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
      background-color: #e8e5e5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #684e94;
    }
  }

  .favortiteStarButton {
    border: 0;
    background: transparent;
    &:hover {
      cursor: pointer;
    }
  }

  .disfavor {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
    text-align: right;
    font-weight: bold;
    text-transform: uppercase;
    color: #d44c4c;
    cursor: pointer;
  }

  .disfavor:hover {
    color: rgb(175, 54, 54);
  }

  span {
    font-family: "Asap", sans-serif;
    text-align: left;
    font-size: 14px;
    color: #4b4b4b;

    &.subtitle {
      font-weight: bold;
      color: #9074bf;
      font-size: 12px;
    }
  }

  .initial-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80px;
    border-radius: 8px 0px 0px 8px;
    background: #fcfcfc;
    border-right: 3px solid #c4c4c4;

    span {
      font-family: "Asap", sans-serif;
      text-align: left;
      font-size: 14px;
      color: #4b4b4b;

      &.title {
        font-weight: bold;
        color: #9074bf;
      }

      &.subtitle {
        font-weight: bold;
        color: #9074bf;
        font-size: 18px;
      }

      &.value {
        font-weight: bold;
      }
    }
  }
`;

export const SaleCard = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 60px;
  min-width: 110px;
  border-radius: 8px;
  background: #fcfcfc;
  margin: 0px 7px;
  padding: 5px;
  cursor: pointer;

  &:hover {
    background: #eee;
  }

  &.green {
    border: 2px solid #c6de52;
  }

  &.purple {
    border: 2px solid #684e94;
  }

  span {
    font-family: "Asap", sans-serif;
    text-align: left;
    font-size: 13px;
    color: #4b4b4b;

    &.date {
      color: #707070;
    }

    &.price {
      font-weight: bold;
      color: #707070;
    }

    &.weight {
      color: #c4c4c4;
    }

    &.subtitle {
      font-weight: bold;
      color: #9074bf;
      font-size: 18px;
      padding: 10px;
    }

    &.value {
      font-weight: bold;
    }
  }

  .favortiteStarButton {
    position: absolute;
    right: 10px;
    top: 30px;
  }
`;

export const ButtonStandard = styled(Button)`
  background: transparent !important;
  color: #684e94 !important;
  text-transform: uppercase !important;
  border: 2px solid #684e94 !important;
  font-weight: bold !important;
  border-radius: 20px !important;
`;
