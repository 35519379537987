import { call, put } from "redux-saga/effects";
import { Creators as RetreatActions } from "../ducks/retreat";

import { notification } from "antd";

/** Services */
import {
  Inactivate,
  Activate,
  indexAll
} from "../../services/retreatService";

export function* indexFarmRetreats(action) {
  try {
    const {
      data: { results: quotas },
    } = yield call(indexAll, action.payload);
    yield put(RetreatActions.indexSuccess(quotas));
  } catch (error) {
    yield put(RetreatActions.indexError(error));
  }
}

export function* inactivateFarmRetreat(action) {
  try {
    const {
      data: { results: quotas },
    } = yield call(Inactivate, action.payload);
  } catch (error) {
    notification.error({
      message: `Retiro não foi inativado. Contate o suporte`,
    });
  }

  yield put(RetreatActions.index(action.payload.groupId, action.payload.farmId));
}

export function* activateFarmRetreat(action) {
  try {
    const {
      data: { results },
    } = yield call(Activate, action.payload);
  } catch (error) {
    notification.error({
      message: `Retiro não foi ativado. Contate o suporte`,
    });
  }

  yield put(RetreatActions.index(action.payload.groupId, action.payload.farmId));
}