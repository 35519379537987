import styled from "styled-components";

export const Container = styled.div`
  width: 400px;
  height: 250px;
  border-radius: 20px;
  background-color: #8a50a5;

  cursor: pointer;

  display: flex;
  flex-direction: column;

  perspective: 1000;

  padding: 17px 15px 10px 20px;

  transition: all 1.2s ease;

  .flip {
    width: 100%;
    height: 100%;
    transition: all 1.2s ease;
    transform-style: preserve-3d;
  }

  .front,
  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .front {
    transform: translateZ(0);
  }

  .back {
    transform: rotateY(180deg) translateZ(0);
    .strip-black {
      position: absolute;
      top: 30px;
      left: 0;
      width: 100%;
      height: 50px;
      background: #000;
    }
    .ccv {
      position: absolute;
      top: 110px;
      left: 0;
      right: 0;
      height: 36px;
      width: 90%;
      padding: 10px;
      margin: 0 auto;
      border-radius: 5px;
      text-align: right;
      letter-spacing: 1px;
      color: #000;
      background: #fff;
    }
    .ccv label {
      display: block;
      margin: -30px 0 15px;
      font-size: 10px;
      text-transform: uppercase;
      color: #fff;
    }
    .terms {
      position: absolute;
      top: 150px;
      padding: 20px;
      font-size: 10px;
      text-align: justify;
    }
  }

  &:hover {
    background-color: #9e9e9e;
  }

  &:hover .flip {
    transform: rotateY(180deg);
  }

  header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    span {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 18px;

      color: #e5e5e5;
    }
    svg {
      position: absolute;
      top: -10px;
      right: 0;
      width: 50px;
      height: 50px;
    }
  }

  main {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    div.chip-container {
      display: flex;
      .chip {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 40px;
        border-radius: 5px;
        background-image: linear-gradient(to bottom left, #ffecc7, #d0b978);
        overflow: hidden;
        .chip-line {
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: #333;
        }
        .chip-line:nth-child(1) {
          top: 13px;
        }
        .chip-line:nth-child(2) {
          top: 20px;
        }
        .chip-line:nth-child(3) {
          top: 28px;
        }
        .chip-line:nth-child(4) {
          left: 25px;
          width: 1px;
          height: 50px;
        }
        .chip-main {
          width: 20px;
          height: 25px;
          border: 1px solid #333;
          border-radius: 3px;
          background-image: linear-gradient(to bottom left, #efdbab, #e1cb94);
          z-index: 1;
        }
      }
      .wave {
        margin-left: 16px;
      }
    }
    .card-number {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0px 10px;
      font-size: 23px;
      font-family: "cc font", monospace;
      color: #fff;
    }
    .end {
      margin-left: 0px;
      text-transform: uppercase;
      font-family: "cc font", monospace;
      .end-text {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.8);
      }
      .end-date {
        font-family: "Montserrat", "Helvetica", sans-serif;
        font-size: 16px;
        color: #fff;
        line-height: 1.3;
        margin-left: 8px;
      }
    }
    .card-holder {
      width: 100%;
      margin: 10px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        text-transform: uppercase;
        font-family: "cc font", monospace;
        font-size: 16px;
        color: #fff;
        line-height: 1.3;
      }
      div.icon-card-brand {
        width: 50px;
        height: 50px;

        svg {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
`;
