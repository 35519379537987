import styled from "styled-components";
import { Card, Tabs } from "antd";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;

  @media screen and (max-height: 700px) {
    height: 440px;
    overflow-y: auto;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const ButtonApplyDefaultValues = styled.button`
  font-family: Asap;
  font-weight: bold;
  font-size: 12px;
  text-align: left;
  color: #4a85ae;
  border: none;
  background: none;
  svg {
    margin-right: 5px;
  }
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const GreyCard = styled(Card)`
  margin-top: 20px !important;
  border-radius: 5px !important;
  background: #f5f5f5 !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
`;

export const TabContainer = styled(Tabs)`
  margin-bottom: 10px !important;

  .ant-tabs-ink-bar {
    background-color: #684e94;
    height: 5px;
  }

  .ant-tabs-line {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab {
    font-family: "Asap" !important;
    font-size: 14px !important;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab-disabled,
  .ant-tabs-nav .ant-tabs-tab-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: #4b4b4b;
    font-weight: bold;
  }

  .ant-tabs-bar {
    border-bottom: 1px solid #a9c133;
  }

  .ant-tabs-tabpane {
    background-color: #fff;
    margin-top: -15px;
  }
`;
