import React, { useState, useEffect, useCallback, useMemo } from "react";

// libs
import Axios from "axios";
import moment from "moment";
import NumberFormat from "react-number-format";
import {
  Row,
  Col,
  Select,
  DatePicker,
  Input,
  Spin,
  notification,
  Radio,
} from "antd";

// Styles
import { Container } from "./styles";
import { CustomDivider } from "../styles";

// Components
import useHomeContext from "../../../hooks/useHomeContext";
import FarmWeatherRainInfoModal from "../../../components/modals/farmWeatherRainInfo";

// Services
import { numberMask } from "../../../services/helpersMethodsService";

//Icons
import PencilIcon from "../../../components/utils/icons/pencil";
import Balance from "../../../components/utils/icons/balance";
import WaterIcon from "../../../components/utils/icons/water";
import {
  findFarmWeatherStationRainData,
  findFarmWeatherStationBalanceHidricData,
} from "../../../services/farmWeatherStationService";
import BalanceHidricBarChart from "./balanceHidricChart";
import ButtonStandard from "../../../components/utils/button";
import FarmHidricBarChart from "./farmHidricChart";

const INITIAL_VALUE = {
  refDate: moment().add(-1, "day"),
  period: 7,
  interval: "DAY",
};

const displayFormatDate = "DD/MM/YYYY";
const jsonFormatDate = "YYYY-MM-DD";

const LIMIT_DAYS_INPUT = 90;
const LIMIT_MONTHS_INPUT = 16;
const LIMIT_WEEKS_INPUT = 16;
const LIMIT_YEARS_INPUT = 5;

const ClimaticPanel = () => {
  const { translation, groupId, farmId } = useHomeContext();

  const [isLoading, setIsLoading] = useState(false);
  const [typeChart, setTypeChart] = useState("balanceHidricChart");

  const [currentFarmRainInfo, setCurrentFarmRainInfo] = useState(null);
  const [farmRainInfo, setFarmRainInfo] = useState(null);

  const [isFarmRainDrawerVisible, setIsFarmRainDrawerVisible] = useState(false);
  const [form, setForm] = useState(INITIAL_VALUE);

  const balanceHidric = useMemo(() => {
    if (farmRainInfo != null) {
      return farmRainInfo.preciptationTotal;
    } else {
      return 0;
    }
  }, [farmRainInfo]);

  const handleShowHideFarmRainDrawer = useCallback(() => {
    setIsFarmRainDrawerVisible((old) => !old);
  }, [setIsFarmRainDrawerVisible]);

  const handleProcessData = useCallback(async () => {
    if (form.interval && form.period && form.refDate) {
      const startDate = moment(form.refDate)
        .add(-form.period, form.interval)
        .format(jsonFormatDate);
      const endDate = moment(form.refDate).format(jsonFormatDate);
      // Get info history
      try {
        setIsLoading(true);
        const {
          data: { results: response },
        } = await findFarmWeatherStationBalanceHidricData({
          groupId,
          farmId,
          refDate: moment(form.refDate).format(jsonFormatDate),
          startDate,
          endDate,
          group: form.interval.toLocaleLowerCase(),
          interval: form.period,
        });
        setFarmRainInfo(response);
      } catch (error) {
        notification.error({
          message:
            "Não foi possível buscar as informações históricas da estação associada a fazenda!",
        });
        setFarmRainInfo(null);
      }
      // Get info about ref date
      try {
        const now = moment(form.refDate).format(jsonFormatDate);
        const {
          data: { results: response },
        } = await findFarmWeatherStationRainData({
          groupId,
          farmId,
          startDate: now,
          endDate: now,
        });
        setCurrentFarmRainInfo(response[0]);
      } catch (error) {
        notification.error({
          message:
            "Não foi possível buscar as informações referente a data selecionada da estação associada a fazenda!",
        });
        setCurrentFarmRainInfo(null);
      } finally {
        setIsLoading(false);
      }
    }
  }, [groupId, farmId, form]);

  // Fetch data - rain (today)
  useEffect(() => {
    async function fetchFarmRainInfo() {
      try {
        const now = moment(form.refDate).format(jsonFormatDate);

        const {
          data: { results: response },
        } = await findFarmWeatherStationRainData({
          groupId,
          farmId,
          startDate: now,
          endDate: now,
        });
        setCurrentFarmRainInfo(response[0]);
      } catch (error) {
        setCurrentFarmRainInfo(null);
      }
    }

    if (groupId && farmId && form) {
      fetchFarmRainInfo();
    }
  }, [groupId, farmId]);

  // Fetch data - rain (period)
  useEffect(() => {
    async function fetchFarmRainInfo() {
      if (form.interval && form.period && form.refDate) {
        const startDate = moment(form.refDate)
          .add(-form.period, form.interval)
          .format(jsonFormatDate);
        const endDate = moment(form.refDate).format(jsonFormatDate);

        try {
          setIsLoading(true);
          const {
            data: { results: response },
          } = await findFarmWeatherStationBalanceHidricData({
            groupId,
            farmId,
            refDate: moment(form.refDate).format(jsonFormatDate),
            startDate,
            endDate,
            group: form.interval.toLocaleLowerCase(),
            interval: form.period,
          });
          setFarmRainInfo(response);
        } catch (error) {
          notification.error({
            message:
              "Não foi possível buscar as informações da estação associada a fazenda!",
          });
          setFarmRainInfo(null);
        } finally {
          setIsLoading(false);
        }
      }
    }

    if (groupId && farmId) {
      fetchFarmRainInfo();
    }
  }, [groupId, farmId]);

  return (
    <Spin spinning={isLoading}>
      <Row className="title-sub-2">
        <span onClick={(e) => e.preventDefault()}>Dados Climáticos</span>
      </Row>
      <Container>
        <Row type="flex" align="middle" justify="space-between" gutter={16}>
          <Col span={8} className="col-vertical icon-svg">
            <WaterIcon />
            <span className="rain-info-label">Chuva</span>
            <span className="rain-info-content">
              {numberMask(currentFarmRainInfo?.preciptation || 0, false)} mm
            </span>
          </Col>
          <Col span={8} className="col-vertical icon-svg">
            <Balance />
            <span className="rain-info-label">Balanço Hídrico (Acumulado)</span>
            <span className="rain-info-content">
              {numberMask(balanceHidric, false)} mm
            </span>
          </Col>
          <Col span={6} className="col-vertical">
            <button
              className="button-farm-rain-form"
              type="button"
              onClick={() => handleShowHideFarmRainDrawer()}
            >
              Informar
              <PencilIcon />
            </button>
          </Col>
        </Row>
        <CustomDivider />
        {/* Fields */}
        <Row type="flex" align="middle" justify="space-between">
          <Col span={8} className="col-vertical">
            <DatePicker
              name="refDate"
              value={
                form?.refDate != null
                  ? moment(form?.refDate, jsonFormatDate)
                  : null
              }
              format={displayFormatDate}
              placeholder={translation.defaultPlaceholder}
              disabledDate={(date) =>
                moment(date).isAfter(moment()) ? true : false
              }
              allowClear={false}
              onChange={(date) => {
                if (date <= moment() && date !== null) {
                  setForm({ ...form, refDate: date });
                }
              }}
              onSubmit={handleProcessData}
            />
          </Col>
          <Col span={8} className="col-vertical">
            <NumberFormat
              name="period"
              value={form?.period !== null ? form?.period : null}
              customInput={Input}
              decimalScale={0}
              decimalSeparator={null}
              thousandSeparator="."
              fixedDecimalScale={false}
              addonAfter={
                <Select
                  value={form?.interval != null ? form.interval : "DAY"}
                  onChange={(value) =>
                    setForm((prevState) => ({
                      ...prevState,
                      interval: value,
                      period: !prevState.period
                        ? null
                        : value === "DAY" && prevState.period < LIMIT_DAYS_INPUT
                        ? prevState.period
                        : value === "DAY" &&
                          prevState.period >= LIMIT_DAYS_INPUT
                        ? LIMIT_DAYS_INPUT
                        : value === "WEEK" &&
                          prevState.period < LIMIT_WEEKS_INPUT
                        ? prevState.period
                        : value === "WEEK" &&
                          prevState.period >= LIMIT_WEEKS_INPUT
                        ? LIMIT_WEEKS_INPUT
                        : value === "MONTH" &&
                          prevState.period < LIMIT_MONTHS_INPUT
                        ? prevState.period
                        : value === "MONTH" &&
                          prevState.period >= LIMIT_MONTHS_INPUT
                        ? LIMIT_MONTHS_INPUT
                        : value === "YEAR" &&
                          prevState.period < LIMIT_YEARS_INPUT
                        ? prevState.period
                        : value === "YEAR" &&
                          prevState.period >= LIMIT_YEARS_INPUT
                        ? LIMIT_YEARS_INPUT
                        : null,
                    }))
                  }
                  // disabled={true}
                >
                  <Select.Option value="DAY">Dias</Select.Option>
                  <Select.Option value="WEEK">Semanas</Select.Option>
                  <Select.Option value="MONTH">Meses</Select.Option>
                  <Select.Option value="YEAR">Anos</Select.Option>
                </Select>
              }
              onValueChange={({ value }) => {
                setForm((prevState) => ({
                  ...prevState,
                  period: !value
                    ? null
                    : prevState.interval === "DAY" && value < LIMIT_DAYS_INPUT
                    ? value
                    : prevState.interval === "DAY" && value >= LIMIT_DAYS_INPUT
                    ? LIMIT_DAYS_INPUT
                    : prevState.interval === "WEEK" && value < LIMIT_WEEKS_INPUT
                    ? value
                    : prevState.interval === "WEEK" &&
                      value >= LIMIT_WEEKS_INPUT
                    ? LIMIT_WEEKS_INPUT
                    : prevState.interval === "MONTH" &&
                      value < LIMIT_MONTHS_INPUT
                    ? value
                    : prevState.interval === "MONTH" &&
                      value >= LIMIT_MONTHS_INPUT
                    ? LIMIT_MONTHS_INPUT
                    : prevState.interval === "YEAR" && value < LIMIT_YEARS_INPUT
                    ? value
                    : prevState.interval === "YEAR" &&
                      value >= LIMIT_YEARS_INPUT
                    ? LIMIT_YEARS_INPUT
                    : null,
                }));
              }}
              onSubmit={handleProcessData}
              placeholder={translation.defaultPlaceholder}
            />
          </Col>
          <Col span={8} className="col-vertical">
            <ButtonStandard
              type="button"
              buttonType="alternative"
              onClick={handleProcessData}
            >
              Processar
            </ButtonStandard>
          </Col>
        </Row>
        <CustomDivider />
        {/* Chart */}
        <Row type="flex" align="middle" justify="center">
          <Radio.Group
            value={typeChart}
            onChange={(e) => setTypeChart(e.target.value)}
          >
            <Radio.Button value="balanceHidricChart">
              Balanço Hidrico
            </Radio.Button>
            <Radio.Button value="farmHidricChart">
              Pluviometria Acumul. Fazenda
            </Radio.Button>
          </Radio.Group>
        </Row>
        <Row type="flex" align="middle" justify="space-between" gutter={16}>
          <Col
            span={24}
            className="col-vertical"
            style={{
              paddingRight: 20,
            }}
          >
            {typeChart === "balanceHidricChart" ? (
              <BalanceHidricBarChart
                chartData={farmRainInfo?.chartData || undefined}
              />
            ) : (
              <FarmHidricBarChart
                chartData={farmRainInfo?.farmChartData || undefined}
              />
            )}
          </Col>
        </Row>
      </Container>
      <FarmWeatherRainInfoModal
        isModalVisible={isFarmRainDrawerVisible}
        closeModal={() => handleShowHideFarmRainDrawer()}
      />
    </Spin>
  );
};

export default ClimaticPanel;
