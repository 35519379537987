import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as SupplierActions } from "../../../store/ducks/supplier";
import * as Yup from "yup";
import { Formik } from "formik";
import useVeterinaryContext from "../../../hooks/useVeterinaryContext";
import useUnitContext from "../../../hooks/useUnitContext";

// Components
import { Container, Footer } from "./styles";
import {
  Row,
  Col,
  Input,
  notification,
  Spin,
  Select,
  Button,
  Divider,
} from "antd";
import ButtonStandard from "../../../components/utils/button";
import NumberFormat from "react-number-format";
import UnitForm from "../../unit/form";
import DrawerSupplier from "../../../components/drawers/supplier";

// Services
import { save, saveForGroup } from "../../../services/veterinaryService";
import {
  RadioButtonCustom,
  RadioGroupCustom,
} from "../../../components/drawers/supplier/styles";

const validationSchema = Yup.object({
  type: Yup.string().trim().required(),
  name: Yup.string().trim().required(),
  supplierId: Yup.string().trim().required(),
  applicationGender: Yup.string().trim().required(),
  applicationType: Yup.string().trim().required(),
  applicationValue: Yup.number().min(0.1).max(1000).required(),
  applicationWeightValueParam: Yup.number()
    .notRequired()
    .nullable()
    .when(["applicationType"], {
      is: (applicationType) => applicationType === "Fixo",
      then: Yup.number().notRequired().nullable(true),
      otherwise: Yup.number().min(0.1).max(1000).required(),
    }),
  unitId: Yup.string().trim().required(),
  applicationCost: Yup.number().moreThan(0).max(1000).required(),
});

const VeterinaryForm = () => {
  const formRef = useRef(null);

  const [form, setForm] = useState(null);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  // Redux Variable
  const {
    translation,
    groupSelected: { id: groupId },
    groupSelected,
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const {
    listDrowpDown: suppliers,
    isLoadingDropDown: isLoadingDropDownSuppliers,
  } = useSelector((state) => state.supplier);

  const dispatch = useDispatch();

  const {
    isDrawerVisible,
    data,
    closeDrawer,
    fetchData,
    TYPES,
    GENDER_APPLICATION_TYPES,
    APPLICATION_TYPES,
  } = useVeterinaryContext();

  const {
    openDrawer: openUnitForm,
    dataList: unities,
    isLoadingDataList: isLoadingDropDownUnities,
    fetchData: fetchUnities,
  } = useUnitContext();

  const isEditingGroupSupplementVeterinary = useMemo(() => {
    return form && form.id !== null && form.farmId === null;
  }, [form]);

  // Method

  function fetchSuppliers() {
    dispatch(
      SupplierActions.getDropdownSuppliers(
        groupId,
        farmId,
        "VeterinarySupplement",
        true
      )
    );
  }

  function validateForm(values) {
    if (values.minimumAge != null && values.maximumAge != null) {
      if (values.minimumAge > values.maximumAge) {
        notification.error({
          message:
            'O critério de aplicação "Idade" está com um intervalo de idades errado.',
        });
        return false;
      }
    } else if (
      values.minimumAge != null &&
      (values.maximumAge == null || values.maximumAge === "")
    ) {
      notification.error({
        message:
          'O critério de aplicação "Idade" está com um intervalo de idades errado. É necessário informar o campo da idade máxima.',
      });
      return false;
    } else if (
      values.maximumAge != null &&
      (values.minimumAge == null || values.minimumAge === "")
    ) {
      notification.error({
        message:
          'O critério de aplicação "Idade" está com um intervalo de idades errado. É necessário informar o campo da idade mínima.',
      });
      return false;
    }

    return true;
  }

  const handleCreateSupplier = () => {
    dispatch(
      SupplierActions.showDrawer(
        "new_veterinary_supplement_supplier",
        null,
        true
      )
    );
  };

  const handleCreateUnit = (farmId) => {
    openUnitForm(null, farmId === null);
  };

  function handleCloseDrawer() {
    setForm({
      id: null,
      groupId: groupId,
      farmId: farmId,
      type: "Vacina",
      name: null,
      supplierId: null,
      applicationGender: "Both",
      minimumAge: "",
      maximumAge: "",
      applicationType: "Fixo",
      applicationValue: "",
      applicationWeightValueParam: "",
      unitId: null,
      applicationCost: "",
      status: "Active",
    });
    formRef.current.resetForm({
      id: null,
      groupId: groupId,
      farmId: farmId,
      type: "Vacina",
      name: null,
      supplierId: null,
      applicationGender: "Both",
      minimumAge: "",
      maximumAge: "",
      applicationType: "Fixo",
      applicationValue: "",
      applicationWeightValueParam: "",
      unitId: null,
      applicationCost: "",
      status: "Active",
    });
    closeDrawer();
  }

  async function handleSubmitForm(values) {
    setIsLoadingRequest(true);
    try {
      if (validateForm(values)) {
        if (values.farmId === null) {
          await saveForGroup({
            groupId: values.groupId,
            id: values?.id,
            body: values,
          });
        } else {
          await save({
            groupId: values.groupId,
            farmId: values.farmId,
            id: values?.id,
            body: values,
          });
        }
        notification.success({
          message: "Insumo Veterinário cadastrado/atualizado com sucesso.",
        });
        fetchData();
        handleCloseDrawer();
      }
    } catch (error) {
      notification.error({
        message: "Erro",
        description: "Erro ao cadastrar/editar Insumo Veterinário.",
      });
    } finally {
      setIsLoadingRequest(false);
    }
  }

  function handleDrawerVisible(visible) {
    if (visible) {
      fetchSuppliers();
      fetchUnities();
      if (data === null) {
        setForm({
          id: null,
          groupId: groupId,
          farmId: farmId,
          type: "Vacina",
          name: null,
          supplierId: null,
          applicationGender: "Both",
          minimumAge: "",
          maximumAge: "",
          applicationType: "Fixo",
          applicationValue: "",
          applicationWeightValueParam: "",
          unitId: null,
          applicationCost: "",
          status: "Active",
        });
        formRef.current.resetForm({
          id: null,
          groupId: groupId,
          farmId: farmId,
          type: "Vacina",
          name: null,
          supplierId: null,
          applicationGender: "Both",
          minimumAge: "",
          maximumAge: "",
          applicationType: "Fixo",
          applicationValue: "",
          applicationWeightValueParam: "",
          unitId: null,
          applicationCost: "",
          status: "Active",
        });
      } else {
        setForm(data);
      }
    }
  }

  return (
    <Container
      title={
        data?.id == null
          ? translation.veterinary.form.titleCreateNew
          : translation.veterinary.form.titleEdit
      }
      width={700}
      onClose={handleCloseDrawer}
      maskClosable={false}
      visible={isDrawerVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Spin spinning={isLoadingRequest}>
        <Formik
          ref={formRef}
          enableReinitialize={true}
          initialValues={form}
          onSubmit={handleSubmitForm}
          validationSchema={validationSchema}
          render={({
            values,
            errors,
            submitCount,
            setFieldValue,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="drawerForm">
                {errors.length > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                <Row type="flex" align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label>
                        {translation.veterinary.form.groupFarmVisibility}
                      </label>
                    </Row>
                    <Row>
                      <RadioGroupCustom
                        value={
                          values.groupId != null && values.farmId != null
                            ? "farm"
                            : values.groupId != null && values.farmId == null
                            ? "group"
                            : "farm"
                        }
                        buttonStyle="solid"
                      >
                        <RadioButtonCustom
                          value="farm"
                          onChange={(e) => {
                            setFieldValue("groupId", groupId);
                            setFieldValue("farmId", farmId);
                          }}
                        >
                          {translation.supplier.form.radiogroup.farm}
                        </RadioButtonCustom>
                        <RadioButtonCustom
                          value="group"
                          onChange={(e) => {
                            setFieldValue("groupId", groupId);
                            setFieldValue("farmId", null);
                            setFieldValue("supplierId", null);
                            setFieldValue("unitId", null);
                          }}
                        >
                          {translation.supplement.form.radiogroup.group}:{" "}
                          {groupSelected.name}
                        </RadioButtonCustom>
                      </RadioGroupCustom>
                    </Row>
                  </Col>
                </Row>
                {/* type */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={errors.type && submitCount > 0 ? "error" : ""}
                  >
                    {translation.veterinary.form.type}*
                  </label>
                </Row>
                <Row>
                  <Select
                    style={{ width: "200px" }}
                    name="type"
                    placeholder={translation.defaultSelectPlaceholder}
                    value={values.type || undefined}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      setFieldValue("type", value);
                    }}
                  >
                    {TYPES.map((item) => (
                      <Select.Option key={item.key} value={item.key}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Row>
                {/* Name */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={errors.name && submitCount > 0 ? "error" : ""}
                  >
                    {translation.veterinary.form.name}*
                  </label>
                </Row>
                <Row type="flex">
                  <Input
                    name="name"
                    value={values.name}
                    placeholder={translation.defaultPlaceholder}
                    autoCapitalize="off"
                    maxLength={50}
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                  />
                </Row>
                {/* supplierId */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={
                      errors.supplierId && submitCount > 0 ? "error" : ""
                    }
                  >
                    {translation.veterinary.form.supplierId}*
                  </label>
                </Row>
                <Row type="flex" justify="start" gutter={4} align="middle">
                  <Col xs={20} sm={20} md={20} lg={20} xl={20} align="left">
                    <Select
                      name="supplierId"
                      style={{ width: "100%" }}
                      value={
                        values.supplierId && suppliers.length > 0
                          ? suppliers.findIndex(
                              (supplier) => supplier.id === values.supplierId
                            ) >= 0
                            ? values.supplierId
                            : undefined
                          : undefined
                      }
                      placeholder="Selecione um fornecedor"
                      loading={isLoadingDropDownSuppliers}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear={true}
                      onChange={(value) => {
                        setFieldValue("supplierId", value);
                      }}
                    >
                      {suppliers.length > 0 && values.farmId !== null
                        ? suppliers.map((sup) => (
                            <Select.Option key={sup.id} value={sup.id}>
                              {sup.name}
                            </Select.Option>
                          ))
                        : suppliers
                            .filter((sup) => sup.farmId === null)
                            .map((sup) => (
                              <Select.Option key={sup.id} value={sup.id}>
                                {sup.name}
                              </Select.Option>
                            ))}
                    </Select>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <Button
                      shape="circle"
                      icon="plus"
                      onClick={() => handleCreateSupplier()}
                    />
                  </Col>
                </Row>
                <Divider>Critérios de Aplicação</Divider>
                {/* applicationGender */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={
                      errors.applicationGender && submitCount > 0 ? "error" : ""
                    }
                  >
                    {translation.veterinary.form.applicationGender}*
                  </label>
                </Row>
                <Row>
                  <Select
                    style={{ width: "200px" }}
                    name="applicationGender"
                    placeholder={translation.defaultSelectPlaceholder}
                    value={values.applicationGender || undefined}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      setFieldValue("applicationGender", value);
                    }}
                  >
                    {GENDER_APPLICATION_TYPES.map((item) => (
                      <Select.Option key={item.key} value={item.key}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Row>
                {/* applicationAge */}
                <Row type="flex" className="rowLabel">
                  <label>{translation.veterinary.form.applicationAge}</label>
                </Row>
                <Row type="flex" gutter={8} align="middle">
                  <Col span={8}>
                    <NumberFormat
                      customInput={Input}
                      value={values.minimumAge}
                      addonAfter="meses"
                      allowNegative={false}
                      decimalScale={0}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      allowLeadingZeros={false}
                      placeholder={translation.defaultPlaceholder}
                      name="minimumAge"
                      onValueChange={({ floatValue }) => {
                        setFieldValue(
                          "minimumAge",
                          floatValue != null && floatValue >= 0
                            ? floatValue > 1000
                              ? 1000
                              : floatValue
                            : null
                        );
                      }}
                    />
                  </Col>
                  <Col
                    span={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <span>até</span>
                  </Col>
                  <Col span={8}>
                    <NumberFormat
                      customInput={Input}
                      value={values.maximumAge}
                      addonAfter="meses"
                      allowNegative={false}
                      decimalScale={0}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      allowLeadingZeros={false}
                      placeholder={translation.defaultPlaceholder}
                      name="maximumAge"
                      onValueChange={({ floatValue }) => {
                        setFieldValue(
                          "maximumAge",
                          floatValue != null && floatValue >= 0
                            ? floatValue > 1000
                              ? 1000
                              : floatValue
                            : null
                        );
                      }}
                    />
                  </Col>
                </Row>
                {/* applicationType */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={
                      errors.applicationType && submitCount > 0 ? "error" : ""
                    }
                  >
                    {translation.veterinary.form.applicationType}*
                  </label>
                </Row>
                <Row>
                  <Select
                    style={{ width: "200px" }}
                    name="applicationType"
                    placeholder={translation.defaultSelectPlaceholder}
                    value={values.applicationType || undefined}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      setFieldValue("applicationType", value);
                    }}
                  >
                    {APPLICATION_TYPES.map((item) => (
                      <Select.Option key={item.key} value={item.key}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Row>
                {/* applicationValue & applicationWeightValueParam */}
                <Row type="flex" gutter={8}>
                  <Col span={12}>
                    <Row type="flex" className="rowLabel">
                      <label
                        className={
                          errors.applicationValue && submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {translation.veterinary.form.applicationValue}*
                      </label>
                    </Row>
                    <Row>
                      <NumberFormat
                        customInput={Input}
                        value={values.applicationValue}
                        allowNegative={false}
                        decimalScale={1}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        allowLeadingZeros={true}
                        placeholder={translation.defaultPlaceholder}
                        name="applicationValue"
                        onValueChange={({ floatValue }) => {
                          setFieldValue(
                            "applicationValue",
                            floatValue != null
                              ? floatValue > 1000
                                ? 1000
                                : floatValue
                              : null
                          );
                        }}
                      />
                    </Row>
                  </Col>
                  {values.applicationType === "PorPeso" && (
                    <Col span={12}>
                      <Row type="flex" className="rowLabel">
                        <label
                          className={
                            errors.applicationWeightValueParam &&
                            submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {
                            translation.veterinary.form
                              .applicationWeightValueParam
                          }
                          *
                        </label>
                      </Row>
                      <Row>
                        <NumberFormat
                          customInput={Input}
                          value={values.applicationWeightValueParam}
                          addonAfter="kg"
                          allowNegative={false}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          allowLeadingZeros={true}
                          placeholder={translation.defaultPlaceholder}
                          name="applicationWeightValueParam"
                          onValueChange={({ floatValue }) => {
                            setFieldValue(
                              "applicationWeightValueParam",
                              floatValue != null
                                ? floatValue > 1000
                                  ? 1000
                                  : floatValue
                                : null
                            );
                          }}
                        />
                      </Row>
                    </Col>
                  )}
                </Row>
                {/* unitId */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={errors.unitId && submitCount > 0 ? "error" : ""}
                  >
                    {translation.veterinary.form.unitId}*
                  </label>
                </Row>
                <Row type="flex" justify="start" gutter={4} align="middle">
                  <Col xs={20} sm={20} md={20} lg={20} xl={20} align="left">
                    <Select
                      name="unitId"
                      style={{ width: "100%" }}
                      value={
                        values.unitId && unities.length > 0
                          ? unities.findIndex(
                              (supplier) => supplier.id === values.unitId
                            ) >= 0
                            ? values.unitId
                            : undefined
                          : undefined
                      }
                      placeholder="Selecione uma unidade"
                      loading={isLoadingDropDownUnities}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear={true}
                      onChange={(value) => {
                        setFieldValue("unitId", value);
                      }}
                    >
                      {unities.length > 0 && values.farmId !== null
                        ? unities
                            .filter(
                              (unit) =>
                                unit.status === "A" || unit.status === "Active"
                            )
                            .map((unit) => (
                              <Select.Option key={unit.id} value={unit.id}>
                                {unit.name}
                              </Select.Option>
                            ))
                        : unities
                            .filter(
                              (unit) =>
                                unit.status === "A" || unit.status === "Active"
                            )
                            .filter((unit) => unit.farmId === null)
                            .map((unit) => (
                              <Select.Option key={unit.id} value={unit.id}>
                                {unit.name}
                              </Select.Option>
                            ))}
                    </Select>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                    <Button
                      shape="circle"
                      icon="plus"
                      onClick={() => handleCreateUnit(values.farmId)}
                    />
                  </Col>
                </Row>
                {/* applicationCost */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={
                      errors.applicationCost && submitCount > 0 ? "error" : ""
                    }
                  >
                    {translation.veterinary.form.applicationCost}*
                  </label>
                </Row>
                <Row>
                  <NumberFormat
                    customInput={Input}
                    value={values.applicationCost}
                    addonBefore="R$"
                    allowNegative={false}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale={true}
                    allowLeadingZeros={true}
                    placeholder={translation.defaultPlaceholder}
                    name="applicationCost"
                    onValueChange={({ floatValue }) => {
                      setFieldValue(
                        "applicationCost",
                        floatValue != null && floatValue >= 0
                          ? floatValue > 1000
                            ? 1000
                            : floatValue
                          : null
                      );
                    }}
                  />
                </Row>
              </div>
              <Footer>
                <Row type="flex">
                  <Col span={24} className="buttonsDiv">
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={closeDrawer}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard type="submit" buttonType="type6">
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
      </Spin>
      <UnitForm />
      <DrawerSupplier />
    </Container>
  );
};

export default VeterinaryForm;
