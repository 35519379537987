import React, { memo, useCallback } from "react";
import moment from "moment";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import useAnimalListContext from "../../../../hooks/useAnimalListContext";
import { useAnimalFattenContext } from "../context";

import {
  Button,
  DatePicker,
  Dropdown,
  Icon,
  Input,
  InputNumber,
  Menu,
  Select,
  Table,
} from "antd";
import CustomLink from "../../../../components/utils/customLink";
import MenuIcon from "../../../../components/utils/table/icons/menu";
import { Container as MenuContainer } from "../../../../components/utils/table/menu/styles";
import TagStatus from "../../../../components/utils/tagStatus";

// Services
import { numberMask } from "../../../../services/helpersMethodsService";

const { Column } = Table;

const AnimalFattenTable = memo(() => {
  const {
    animal: {
      isLoading,
      data,
      pagination: { page, size, tableFilters, tableSorters },
    },
    app: { translation },
    user: { roles: userRoles },
  } = useSelector((state) => state);

  const { identificationSelected, fetchData } = useAnimalFattenContext();

  const {
    isLoadingInternal,
    handleUpdateSelectedRowKeysFatten,
    handleDelete,
    handleInactivate,
    handleInactivateOrActivate,
    handleEdit,
    handleGoToLotDetails,
  } = useAnimalListContext();

  const isAllowed = useCallback(
    (roles, reverse) => {
      return userRoles?.includes("Admin")
        ? true
        : roles.length === 0
        ? true
        : reverse === false
        ? roles.some((r) => userRoles?.includes(r))
        : !roles.some((r) => userRoles?.includes(r));
    },
    [userRoles]
  );

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          value={selectedKeys && selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
            background: "#684e94",
            borderColor: "none",
            border: "none",
          }}
        />
        <Button
          onClick={() => handleReset(clearFilters)}
          icon="delete"
          size="small"
          style={{ width: 90 }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  const getColumnNumberSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ display: "block" }}>
          <Select
            value={
              selectedKeys
                ? selectedKeys["type"] != null
                  ? selectedKeys["type"]
                  : "="
                : "="
            }
            style={{
              width: 90,
              textAlign: "center",
              marginBottom: 8,
              marginRight: 8,
            }}
            onChange={(value) => {
              setSelectedKeys(
                value ? { ...selectedKeys, type: value } : { ...selectedKeys }
              );
            }}
          >
            <Select.Option key="<" value="<" style={{ textAlign: "center" }}>
              &lt;
            </Select.Option>
            <Select.Option key="<=" value="<=" style={{ textAlign: "center" }}>
              &le;
            </Select.Option>
            <Select.Option key="=" value="=" style={{ textAlign: "center" }}>
              &#61;
            </Select.Option>
            <Select.Option key=">=" value=">=" style={{ textAlign: "center" }}>
              &ge;
            </Select.Option>
            <Select.Option key=">" value=">" style={{ textAlign: "center" }}>
              &gt;
            </Select.Option>
          </Select>
          <InputNumber
            value={
              selectedKeys
                ? selectedKeys["value"] != null
                  ? selectedKeys["value"]
                  : null
                : null
            }
            onChange={(e) =>
              setSelectedKeys(
                e ? { ...selectedKeys, value: e } : { ...selectedKeys }
              )
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 90, marginBottom: 8 }}
            defaultValue={0}
            decimalSeparator=","
            min={-1000}
            max={1000}
            step={0.01}
            formatter={(value) => {
              if (value !== "") {
                value = `${value}`.replace(/^-^[^0-9.,]*/g, "");
                value = value.replace(/\.{2,}/g, ".");
                value = value.replace(/\.,/g, ",");
                value = value.replace(/,\./g, ",");
                value = value.replace(/,{2,}/g, ",");
                value = value.replace(/\.[0-9]+\./g, ".");
                value =
                  value.split(".")[1] !== "" &&
                  value.split(".")[1] !== undefined
                    ? value.split(".")[0] +
                      "." +
                      value.split(".")[1].substring(0, 2)
                    : value;
              }
              return value;
            }}
          />
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  const getColumnDateSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ display: "block" }}>
          <Select
            value={
              selectedKeys
                ? selectedKeys["type"] != null
                  ? selectedKeys["type"]
                  : "="
                : "="
            }
            style={{
              width: 90,
              textAlign: "center",
              marginBottom: 8,
              marginRight: 8,
            }}
            onChange={(value) => {
              setSelectedKeys(
                value ? { ...selectedKeys, type: value } : { ...selectedKeys }
              );
            }}
          >
            <Select.Option key="<" value="<" style={{ textAlign: "center" }}>
              &lt;
            </Select.Option>
            <Select.Option key="<=" value="<=" style={{ textAlign: "center" }}>
              &le;
            </Select.Option>
            <Select.Option key="=" value="=" style={{ textAlign: "center" }}>
              &#61;
            </Select.Option>
            <Select.Option key=">=" value=">=" style={{ textAlign: "center" }}>
              &ge;
            </Select.Option>
            <Select.Option key=">" value=">" style={{ textAlign: "center" }}>
              &gt;
            </Select.Option>
          </Select>
          <DatePicker
            value={
              selectedKeys
                ? selectedKeys["value"] != null
                  ? moment(selectedKeys["value"])
                  : null
                : null
            }
            format={"DD/MM/YYYY"}
            onChange={(date) =>
              setSelectedKeys(
                date ? { ...selectedKeys, value: date } : { ...selectedKeys }
              )
            }
          />
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  const handleTableChange = (pagination, filters, sorter) => {
    fetchData(pagination.current, sorter, filters, pagination.pageSize, null);
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const handleSelectRowFattenTable = () => {
    return {
      onChange: (selectedRowKeys, selectedRows) => {
        handleUpdateSelectedRowKeysFatten(selectedRowKeys);
      },
    };
  };

  const menu = useCallback(
    (id, animal) => {
      return (
        <Menu>
          {animal?.farmStatus === "I" && (
            <Menu.Item key="0">
              <button
                onClick={() =>
                  handleInactivateOrActivate(id, animal, "activate")
                }
              >
                {translation.table.menu.activate}
              </button>
            </Menu.Item>
          )}
          <Menu.Item key="1">
            <Link to={`/admin/animals/${id}`}>
              {translation.table.menu.details}
            </Link>
          </Menu.Item>
          {(animal?.farmStatus === "A" || animal?.farmStatus === "P") && (
            <Menu.Item key="2" onClick={() => handleEdit(id)}>
              {translation.table.menu.edit}
            </Menu.Item>
          )}
          {(animal?.farmStatus === "A" || animal?.farmStatus === "P") &&
            isAllowed(["Trial"], true) &&
            animal?.isFromBirth === false && (
              <Menu.Item key="3" onClick={() => handleDelete(animal)}>
                {translation.table.menu.delete}
              </Menu.Item>
            )}
          {(animal?.farmStatus === "A" || animal?.farmStatus === "P") &&
            isAllowed(["Trial"], true) && (
              <Menu.Item
                key="4"
                onClick={async () => {
                  handleInactivate(animal);
                }}
              >
                {translation.table.menu.inactivate}
              </Menu.Item>
            )}
        </Menu>
      );
    },
    [
      translation.table.menu.activate,
      translation.table.menu.details,
      translation.table.menu.edit,
      translation.table.menu.delete,
      translation.table.menu.inactivate,
      isAllowed,
      handleInactivateOrActivate,
      handleEdit,
      handleDelete,
      handleInactivate,
    ]
  );

  return (
    <Table
      rowKey="id"
      loading={isLoading || isLoadingInternal}
      rowSelection={handleSelectRowFattenTable()}
      dataSource={data != null ? data.content : []}
      pagination={{
        total: data != null ? data.totalElements : 0,
        size: data != null ? size : 0,
        current: data != null ? page : 0,
        hideOnSinglePage:
          data !== null && Object.entries(data).length !== 0
            ? data.totalElements > 10
              ? false
              : true
            : true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30", "100", "200"],
      }}
      scroll={{
        x: 1200,
      }}
      footer={() => (
        <span>
          <strong>Total de animais filtrados: </strong>
          {`${data ? data?.totalElements : 0} animais`}
        </span>
      )}
      onChange={handleTableChange}
    >
      {identificationSelected === "handlingNumber" ? (
        <Column
          title={translation.animal.columns.handlingNumber}
          dataIndex="handlingNumber"
          sorter
          key="handlingNumber"
          filteredValue={tableFilters?.handlingNumber || null}
          align="left"
          width={200}
          sortOrder={
            tableSorters?.columnKey === "handlingNumber" && tableSorters.order
          }
          {...getColumnSearchProps("handling_number")}
          render={(text, record) => (
            <CustomLink to={`/admin/animals/${record.id}`}>{text}</CustomLink>
          )}
        />
      ) : identificationSelected === "tagId" ? (
        <Column
          title={translation.animal.columns.handlingNumber}
          dataIndex="tagId"
          sorter
          key="tagId"
          filteredValue={tableFilters?.tagId || null}
          align="left"
          width={200}
          sortOrder={tableSorters?.columnKey === "tagId" && tableSorters.order}
          {...getColumnSearchProps("tag_id")}
          render={(text, record) => (
            <CustomLink to={`/admin/animals/${record.id}`}>{text}</CustomLink>
          )}
        />
      ) : identificationSelected === "sisbov" ? (
        <Column
          title={translation.animal.columns.handlingNumber}
          dataIndex="sisbov"
          sorter
          key="sisbov"
          filteredValue={tableFilters?.sisbov || null}
          align="left"
          width={200}
          sortOrder={tableSorters?.columnKey === "sisbov" && tableSorters.order}
          {...getColumnSearchProps("sisbov")}
          render={(text, record) => (
            <CustomLink to={`/admin/animals/${record.id}`}>{text}</CustomLink>
          )}
        />
      ) : (
        <Column
          title={translation.animal.columns.handlingNumber}
          dataIndex="nickname"
          sorter
          key="nickname"
          filteredValue={tableFilters?.nickname || null}
          align="left"
          width={200}
          sortOrder={
            tableSorters?.columnKey === "nickname" && tableSorters.order
          }
          {...getColumnSearchProps("nickname")}
          render={(text, record) => (
            <CustomLink to={`/admin/animals/${record.id}`}>{text}</CustomLink>
          )}
        />
      )}
      <Column
        title={translation.lot.production.table.columns.status}
        dataIndex="farmStatus"
        key="farmStatus"
        width={120}
        align="left"
        filteredValue={tableFilters?.farmStatus || null}
        filtered
        filters={[
          {
            text: translation.status.active,
            value: "A",
          },
          {
            text: translation.status.inactive,
            value: "I",
          },
          {
            text: "Vendido",
            value: "S",
          },
          {
            text: "Morto",
            value: "D",
          },
        ]}
        filterMultiple
        sorter
        sortOrder={
          tableSorters?.columnKey === "farmStatus" && tableSorters.order
        }
        sortDirections={["descend", "ascend"]}
        render={(farmStatus) =>
          farmStatus === "A" ? (
            <TagStatus
              background="#C5F1CA"
              borderColor="#106518"
              color="#106518"
            >
              {translation.status.active}
            </TagStatus>
          ) : farmStatus === "I" || farmStatus === "X" ? (
            <TagStatus
              background="#FBC7C7"
              borderColor="#D44C4C"
              color="#D44C4C"
            >
              {translation.status.inactive}
            </TagStatus>
          ) : farmStatus === "S" ? (
            <TagStatus
              background="#FEFFF6"
              borderColor="#A9C133"
              color="#A9C133"
            >
              Vendido
            </TagStatus>
          ) : farmStatus === "D" ? (
            <TagStatus background="#4b4b4b" borderColor="#000" color="#e3e3e3">
              Morto
            </TagStatus>
          ) : (
            <TagStatus
              background="#FBC7C7"
              borderColor="#D44C4C"
              color="#D44C4C"
            >
              {translation.status.inactive}
            </TagStatus>
          )
        }
      />
      <Column
        title={translation.animal.columns.picket}
        dataIndex="picketName"
        sorter
        key="picketName"
        filteredValue={tableFilters?.picketName || null}
        width={220}
        align="left"
        sortOrder={
          tableSorters?.columnKey === "picketName" && tableSorters.order
        }
        {...getColumnSearchProps("picket_name")}
      />
      <Column
        title={translation.animal.columns.lot}
        dataIndex="lotName"
        sorter
        key="lotName"
        filteredValue={tableFilters?.lotName || null}
        width={220}
        align="left"
        sortOrder={tableSorters?.columnKey === "lotName" && tableSorters.order}
        {...getColumnSearchProps("lot_name")}
        render={(text, record) =>
          record.lotIsReceipt === false ? (
            <button
              className="linkLot"
              type="button"
              onClick={() => handleGoToLotDetails(record)}
            >
              <span className="value link">{record?.lotName}</span>
            </button>
          ) : (
            <span>{record.lotName}</span>
          )
        }
      />
      <Column
        title={translation.animal.columns.breed}
        dataIndex="breedName"
        key="breedName"
        align="left"
        width={120}
        filteredValue={tableFilters?.breedName || null}
        sorter
        sortOrder={
          tableSorters?.columnKey === "breedName" && tableSorters.order
        }
        sortDirections={["descend", "ascend"]}
        {...getColumnSearchProps("breed_name")}
      />
      <Column
        title={translation.animal.columns.gender}
        dataIndex="gender"
        key="gender"
        align="left"
        width={120}
        filteredValue={tableFilters?.gender || null}
        filtered
        filters={[
          {
            text: translation.animal.details.male,
            value: "M",
          },
          {
            text: translation.animal.details.female,
            value: "F",
          },
        ]}
        filterMultiple={false}
        sorter
        sortOrder={tableSorters?.columnKey === "gender" && tableSorters.order}
        sortDirections={["descend", "ascend"]}
        render={(text, record) => (
          <span>
            {record.gender === "M"
              ? translation.animal.details.male
              : translation.animal.details.female}
          </span>
        )}
      />
      <Column
        title={translation.animal.columns.birthdayMonths}
        dataIndex="birthdayMonth"
        key="birthdayMonth"
        align="left"
        width={150}
        render={(text, record) =>
          record.birthdayMonth !== null && (
            <span>{`${record.birthdayMonth}m`}</span>
          )
        }
      />
      <Column
        title={translation.animal.columns.currentWeight}
        dataIndex="lastDailyWeight"
        key="lastDailyWeight"
        align="left"
        width={200}
        sorter
        sortOrder={
          tableSorters?.columnKey === "lastDailyWeight" && tableSorters.order
        }
        sortDirections={["descend", "ascend"]}
        {...getColumnNumberSearchProps("lastDailyWeight")}
        render={(text, record) => `${numberMask(text || 0, false)}kg`}
      />

      <Column
        title={translation.animal.columns.gdp}
        dataIndex="lastDailyWeightGain"
        key="lastDailyWeightGain"
        align="left"
        width={200}
        sorter
        sortOrder={
          tableSorters?.columnKey === "lastDailyWeightGain" &&
          tableSorters.order
        }
        sortDirections={["descend", "ascend"]}
        {...getColumnNumberSearchProps("lastDailyWeightGain")}
        render={(text, record) => `${numberMask(text || 0, false)} Kg`}
      />

      <Column
        title="Última Pesagem"
        dataIndex="lastDailyWeightDate"
        key="lastDailyWeightDate"
        align="left"
        width={200}
        sorter
        sortOrder={
          tableSorters?.columnKey === "lastDailyWeightDate" &&
          tableSorters.order
        }
        sortDirections={["descend", "ascend"]}
        {...getColumnDateSearchProps("lastDailyWeightDate")}
        render={(text, record) => moment(text).format("DD/MM/YYYY")}
      />
      <Column
        title={translation.animal.columns.weightRanking}
        dataIndex="currentWeightRanking"
        key="currentWeightRanking"
        align="center"
        width={150}
        filtered
        filters={[
          {
            text: "Cabeceira",
            value: "top",
          },
          {
            text: "Meio",
            value: "medium",
          },
          {
            text: "Fundo",
            value: "worst",
          },
        ]}
        filterMultiple={false}
        render={(text, record) =>
          record.currentWeightRanking !== null ? (
            record.currentWeightRanking === "top" ? (
              <TagStatus
                background="#D8FFF2"
                borderColor="#99C9B9"
                color="#99C9B9"
              >
                Cabeceira
              </TagStatus>
            ) : record.currentWeightRanking === "medium" ? (
              <TagStatus
                background="#E4F7FF"
                borderColor="#99BBC9"
                color="#99BBC9"
              >
                Meio
              </TagStatus>
            ) : (
              record.currentWeightRanking === "worst" && (
                <TagStatus
                  background="#FFDFDF"
                  borderColor="#E88D8D"
                  color="#E88D8D"
                >
                  Fundo
                </TagStatus>
              )
            )
          ) : null
        }
      />
      <Column
        align="left"
        width={50}
        render={(text, record) => (
          <Dropdown
            overlay={menu(record.id, record)}
            trigger={["click"]}
            key={record.id}
          >
            <MenuContainer>
              <MenuIcon />
            </MenuContainer>
          </Dropdown>
        )}
      />
    </Table>
  );
});

export { AnimalFattenTable };
