import React from "react";
import { useSelector } from "react-redux";

import { Col, Row, } from "antd";

import FinancialParametersResultCenterList from "./list";
import FinancialParametersResultCenterForm from "./form";
import FinancialNatureApportionmentForm from "./financialNatureApportionmentForm"

const FinancialParametersResultCenterLayout = () => {

  const {
    resultCenter: { isResultCenterFormVisible, isFinancialNatureApportionmentFormVisible, isApportionmentFormExpanded },
  } = useSelector((state) => state);

  return (
    <div className="row-of-cards">
      <Row type="flex" className="div-row" justify="start" align="middle" gutter={16}>
        <Col className="card-container" span={!isApportionmentFormExpanded ? (isResultCenterFormVisible ? 7 : 8) : 0}>
          <FinancialParametersResultCenterList />
        </Col>
        {isResultCenterFormVisible ? (
          <Col className="card-container" span={!isApportionmentFormExpanded ? (isFinancialNatureApportionmentFormVisible ? 7 : 8) : 0} >
            <FinancialParametersResultCenterForm />
          </Col>
        ) : null}
        {isFinancialNatureApportionmentFormVisible ? (
          <Col className="card-container" span={isApportionmentFormExpanded ? 24 : isResultCenterFormVisible ? 10 : 16} >
            <FinancialNatureApportionmentForm />
          </Col>
        ) : null}
      </Row>
    </div>
  );
}

export default FinancialParametersResultCenterLayout;