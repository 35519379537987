import React from "react";
import NumberFormat from "react-number-format";

const HectareInput = (props) => {
  return (
    <NumberFormat
      className="ant-input"
      allowNegative={false}
      defaultValue={0}
      suffix=" ha"
      decimalScale={2}
      decimalSeparator=","
      thousandSeparator="."
      fixedDecimalScale={true}
      {...props}
    />
  );
};

export default HectareInput;
