export const Types = {
  RESET_DATA: "marketAnalysis/RESET_DATA",
  GET_ALL_MARKET_ANALYSIS: "marketAnalysis/GET_ALL_MARKET_ANALYSIS",
  GET_ALL_MARKET_ANALYSIS_SUCCESS:
    "marketAnalysis/GET_ALL_MARKET_ANALYSIS_SUCCESS",
  GET_ALL_MARKET_ANALYSIS_ERROR: "marketAnalysis/GET_ALL_MARKET_ANALYSIS_ERROR",
  GET_MARKET_ANALYSIS: "marketAnalysis/GET_MARKET_ANALYSIS",
  GET_MARKET_ANALYSIS_SUCCESS: "marketAnalysis/GET_MARKET_ANALYSIS_SUCCESS",
  GET_MARKET_ANALYSIS_ERROR: "marketAnalysis/GET_MARKET_ANALYSIS_ERROR",
  SHOW_DRAWER_MARKET_ANALYSIS: "marketAnalysis/SHOW_DRAWER_MARKET_ANALYSIS",
  HIDE_DRAWER_MARKET_ANALYSIS: "marketAnalysis/HIDE_DRAWER_MARKET_ANALYSIS",
  SAVE_MARKET_ANALYSIS: "marketAnalysis/SAVE_MARKET_ANALYSIS",
  SAVE_MARKET_ANALYSIS_SUCCESS: "marketAnalysis/SAVE_MARKET_ANALYSIS_SUCCESS",
  SAVE_MARKET_ANALYSIS_ERROR: "marketAnalysis/SAVE_MARKET_ANALYSIS_ERROR",
  DELETE_MARKET_ANALYSIS: "marketAnalysis/DELETE_MARKET_ANALYSIS",
  DELETE_MARKET_ANALYSIS_SUCCESS:
    "marketAnalysis/DELETE_MARKET_ANALYSIS_SUCCESS",
  DELETE_MARKET_ANALYSIS_ERROR: "marketAnalysis/DELETE_MARKET_ANALYSIS_ERROR",
  SHOW_OR_HIDE_MARKET_ANALYSIS_DETAILS:
    "marketAnalysis/SHOW_OR_HIDE_MARKET_ANALYSIS_DETAILS",
  ACTIVATE_MARKET_ANALYSIS: "marketAnalysis/ACTIVATE_MARKET_ANALYSIS",
  ACTIVATE_MARKET_ANALYSIS_SUCCESS:
    "marketAnalysis/ACTIVATE_MARKET_ANALYSIS_SUCCESS",
  ACTIVATE_MARKET_ANALYSIS_ERROR:
    "marketAnalysis/ACTIVATE_MARKET_ANALYSIS_ERROR",
};

export const Creators = {
  indexMarketAnalysis: (
    groupId,
    farmId,
    page = 0,
    sorter = {},
    filters = "",
    size = 10
  ) => ({
    type: Types.GET_ALL_MARKET_ANALYSIS,
    payload: {
      groupId,
      farmId,
      page,
      sorter,
      filters,
      size,
    },
  }),
  indexMarketAnalysisSuccess: (data) => ({
    type: Types.GET_ALL_MARKET_ANALYSIS_SUCCESS,
    payload: {
      data,
    },
  }),
  indexMarketAnalysisError: (error) => ({
    type: Types.GET_ALL_MARKET_ANALYSIS_ERROR,
    payload: {
      error,
    },
  }),
  showMarketAnalysis: (groupId, farmId, id) => ({
    type: Types.GET_MARKET_ANALYSIS,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),
  showMarketAnalysisSuccess: (data, futurePricesData) => ({
    type: Types.GET_MARKET_ANALYSIS_SUCCESS,
    payload: {
      data,
      futurePricesData,
    },
  }),
  showMarketAnalysisError: (error) => ({
    type: Types.GET_MARKET_ANALYSIS_ERROR,
    payload: {
      error,
    },
  }),
  showDrawer: (actionType = "edit") => ({
    type: Types.SHOW_DRAWER_MARKET_ANALYSIS,
    payload: {
      actionType,
    },
  }),
  hideDrawer: () => ({
    type: Types.HIDE_DRAWER_MARKET_ANALYSIS,
    payload: {},
  }),
  saveMarketAnalysis: (
    groupId,
    farmId,
    id = null,
    marketAnalysis,
    saveOptions
  ) => ({
    type: Types.SAVE_MARKET_ANALYSIS,
    payload: {
      groupId,
      farmId,
      id,
      marketAnalysis,
      saveOptions,
    },
  }),
  saveMarketAnalysisSuccess: (data, futurePricesData, saveOptions) => ({
    type: Types.SAVE_MARKET_ANALYSIS_SUCCESS,
    payload: {
      data,
      futurePricesData,
      saveOptions,
    },
  }),
  saveMarketAnalysisError: (error) => ({
    type: Types.SAVE_MARKET_ANALYSIS_ERROR,
    payload: { error },
  }),
  showOrHideMarketAnalysisDetails: (data) => ({
    type: Types.SHOW_OR_HIDE_MARKET_ANALYSIS_DETAILS,
    payload: { data },
  }),
  deleteMarketAnalysis: (groupId, farmId, id) => ({
    type: Types.DELETE_MARKET_ANALYSIS,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),
  deleteMarketAnalysisSuccess: () => ({
    type: Types.DELETE_MARKET_ANALYSIS_SUCCESS,
    payload: {},
  }),
  deleteMarketAnalysisError: (error) => ({
    type: Types.DELETE_MARKET_ANALYSIS_ERROR,
    payload: {
      error,
    },
  }),
  activateMarketAnalysis: (groupId, farmId, id) => ({
    type: Types.ACTIVATE_MARKET_ANALYSIS,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),
  activateMarketAnalysisSuccess: () => ({
    type: Types.ACTIVATE_MARKET_ANALYSIS_SUCCESS,
    payload: {},
  }),
  activateMarketAnalysisError: (error) => ({
    type: Types.ACTIVATE_MARKET_ANALYSIS_ERROR,
    payload: {
      error,
    },
  }),
};

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  marketAnalysisData: null,
  futurePricesData: null,
  drawerVisible: false,
  error: {},
  drawerDetailsVisible: false,
};

export default function marketAnalysis(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_ALL_MARKET_ANALYSIS:
      return { ...state, isLoading: true };
    case Types.GET_ALL_MARKET_ANALYSIS_SUCCESS:
      const { data: marketAnalysiss } = action.payload;
      return { ...state, isLoading: false, data: marketAnalysiss };
    case Types.GET_ALL_MARKET_ANALYSIS_ERROR:
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: null,
        error,
      };
    case Types.GET_MARKET_ANALYSIS:
      return { ...state, isLoading: true };
    case Types.GET_MARKET_ANALYSIS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        marketAnalysisData: action.payload.data,
        futurePricesData: action.payload.futurePricesData,
      };
    case Types.GET_MARKET_ANALYSIS_ERROR:
      return {
        ...state,
        isLoading: false,
        marketAnalysisData: null,
        error,
      };
    case Types.SHOW_DRAWER_MARKET_ANALYSIS:
      return {
        ...state,
        drawerVisible: true,
        marketAnalysisData:
          action.payload.actionType === "new" ? null : state.marketAnalysisData,
        futurePricesData:
          action.payload.actionType === "new" ? null : state.futurePricesData,
      };
    case Types.HIDE_DRAWER_MARKET_ANALYSIS:
      return {
        ...state,
        drawerVisible: false,
        marketAnalysisData: null,
        futurePricesData: null,
      };
    case Types.SAVE_MARKET_ANALYSIS:
      return { ...state, isLoading: true };
    case Types.SAVE_MARKET_ANALYSIS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        marketAnalysisData: null,
        drawerVisible: action.payload.saveOptions,
      };
    case Types.SAVE_MARKET_ANALYSIS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case Types.DELETE_MARKET_ANALYSIS:
      return { ...state, isLoading: true };
    case Types.DELETE_MARKET_ANALYSIS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case Types.DELETE_MARKET_ANALYSIS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case Types.SHOW_OR_HIDE_MARKET_ANALYSIS_DETAILS:
      return {
        ...state,
        marketAnalysisData: action.payload.data,
        drawerDetailsVisible: !state.drawerDetailsVisible,
      };
    case Types.ACTIVATE_MARKET_ANALYSIS:
      return { ...state, isLoading: true };
    case Types.ACTIVATE_MARKET_ANALYSIS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case Types.ACTIVATE_MARKET_ANALYSIS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
