import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

//Libs
import moment from "moment";

// Components
import { Row, Col, Table, notification, Select } from "antd";
import Loading from "../../../components/utils/loading";
import ArrowIcon from "../../../components/utils/icons/arrows/red/left";

// Styles
import { Container, Title } from "./styles";

//Services
import { getHistoryBoitelPrice } from "../../../services/boitelPriceService";
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../services/helpersMethodsService";

const BoitelPriceDetails = (props) => {
  // Variable Redux
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const { id: boitelPriceId } = props.match.params;
  const history = useHistory();
  const dateFormat = "DD/MM/YYYY";
  const dateTimeFormat = "DD/MM/YYYY HH:mm:ss";

  const Column = Table.Column;
  const { Option } = Select;

  const [data, setData] = useState(null);
  const [historyData, setHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function loadData() {
      try {
        const {
          data: { results },
        } = await getHistoryBoitelPrice({ groupId, farmId, boitelPriceId });

        if (results.length > 0) {
          setData(results[0]);
          setHistoryData(results);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        notification.error({
          message:
            "Ocorreu um erro ao acessar as informações de preços dos boiteis.",
        });
      }
    }

    loadData();
  }, [groupId, farmId, boitelPriceId]);

  function handleBoitelPriceSelected(boitelPriceValidityId) {
    setData(
      historyData.find((obj) => {
        if (obj.idBoitelPriceValidity === boitelPriceValidityId) {
          return true;
        }
        return false;
      })
    );
  }

  return (
    <Container>
      {isLoading && <Loading />}
      <Row type="flex" justify="start" align="middle">
        <Col span={24}>
          <Row type="flex" justify="start" align="middle">
            <Col>
              <div
                className="back-container back"
                onClick={() =>
                  history.push("/admin/parameters", { tab: "8", subTab: "2" })
                }
              >
                <ArrowIcon />
                <span className="back">Voltar</span>
              </div>
            </Col>
            <Col>
              <Row type="flex" align="middle">
                <div className="divider" />
                <Title>{data != null ? data.name : null}</Title>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex" align="middle" style={{ marginTop: "10px" }}>
        <Col>
          <label>
            Vigência:
            {data?.initialValidity != null ? (
              <Select
                className="history-select"
                value={
                  data?.idBoitelPriceValidity != null
                    ? data?.idBoitelPriceValidity
                    : null
                }
                onChange={(value) => {
                  handleBoitelPriceSelected(value);
                }}
              >
                {historyData?.map((el) => {
                  return (
                    <Option
                      value={el?.idBoitelPriceValidity}
                      key={el?.idBoitelPriceValidity}
                    >
                      {`${el?.validityDescription} - ${moment(
                        el.initialValidity
                      ).format(dateFormat)} à ${moment(el.finalValidity).format(
                        dateFormat
                      )} - Atualização: ${moment(el.createdAt)
                        .utcOffset(-6)
                        .format(dateTimeFormat)}`}
                    </Option>
                  );
                })}
              </Select>
            ) : (
              <label className="value">Vigência não informada</label>
            )}
          </label>
        </Col>
      </Row>
      <Row type="flex" align="middle" style={{ marginTop: "10px" }}>
        <Col>
          <label>
            Valor do Protocolo de Entrada:
            <label className="value">
              {`${numberMask(data?.inputProtocol || 0, true)}`}
            </label>
          </label>
        </Col>
      </Row>
      <Row type="flex" align="middle" style={{ marginTop: "10px" }}>
        <Col>
          <label>
            Sobretaxa da Diária:
            <label className="value">
              {data?.useDailyPurchase
                ? `${numberMask(data?.dailySurchase || 0, false)} %`
                : data?.useDailyPurchase === false
                ? "Por faixa de peso/genética"
                : "-"}
            </label>
          </label>
        </Col>
      </Row>
      <Row type="flex" align="middle" style={{ marginTop: "10px" }}>
        <span className="headerText">{data?.headerText}</span>
      </Row>
      <Row
        type="flex"
        align="middle"
        style={{ marginTop: "10px", marginBottom: "70px" }}
      >
        <Table
          rowKey={(record, index) => index}
          size="small"
          dataSource={data != null ? data.weightValues : []}
          scroll={{ x: true }}
          style={{ width: "100%", margin: "0" }}
          pagination={false}
        >
          <Column
            title="Peso Inicial (Kg)"
            dataIndex="initialWeightRange"
            key="initialWeightRange"
            align="left"
            render={(value) => (
              <span>
                {`${value} - ${getTwoDecimalDigits((value / 15) * 0.5, 1)}@`}
              </span>
            )}
          />
          <Column
            title="Peso Final (Kg)"
            dataIndex="finalWeightRange"
            key="finalWeightRange"
            align="left"
            render={(value) => (
              <span>
                {`${value} - ${getTwoDecimalDigits((value / 15) * 0.5, 1)}@`}
              </span>
            )}
          />
          <Column
            title="Valor Diária Corte"
            dataIndex="dailyValueCut"
            key="dailyValueCut"
            align="left"
            render={(value) => (
              <label className="value">
                {`${numberMask(value || 0, true)}`}
              </label>
            )}
          />
          <Column
            title="Valor Diária Mestiços de Leite"
            dataIndex="dailyValueMixedMilk"
            key="dailyValueMixedMilk"
            align="left"
            render={(value) => (
              <label className="value">
                {`${numberMask(value || 0, true)}`}
              </label>
            )}
          />
          <Column
            title="Tempo Limite (Dias)"
            dataIndex="limitTime"
            key="limitTime"
            align="left"
          />
          <Column
            title="Diária Após - Corte"
            dataIndex="dailyValueAfterCut"
            key="dailyValueAfterCut"
            align="left"
            render={(value) => (
              <label className="value">
                {`${numberMask(value || 0, true)}`}
              </label>
            )}
          />
          <Column
            title="Diária Após - Mestiços de Leite"
            dataIndex="dailyValueAfterMixedMilk"
            key="dailyValueAfterMixedMilk"
            align="left"
            render={(value) => (
              <label className="value">
                {`${numberMask(value || 0, true)}`}
              </label>
            )}
          />
        </Table>
        <Row type="flex" align="middle" style={{ marginTop: "10px" }}>
          <span className="footerText">{data?.footerText}</span>
        </Row>
      </Row>
    </Container>
  );
};

export default withRouter(BoitelPriceDetails);
