import { call, put } from "redux-saga/effects";
import { Creators as SaleScenarioActions } from "../ducks/saleScenario";
/** Services */
import {
  showEditSaleScenarioParam,
  showSaleScenarioCommitmentsByParameter
} from "../../services/saleScenarioService";

export function* editSaleScenarioParam(action) {
  try {
    let dataCommitments = [];
    const {
      data: { results: dataParam }
    } = yield call(showEditSaleScenarioParam, action.payload);
    if (dataParam != null) {
      const paramsShowCommitments = {
        ...action.payload,
        saleScenarioParamId: dataParam?.id
      };

      const {
        data: { results }
      } = yield call(
        showSaleScenarioCommitmentsByParameter,
        paramsShowCommitments
      );

      dataCommitments = results;
    }
    let data = {
      id: null,
      name: null,
      saleScenarioLots: [],
      saleScenarioParam: dataParam,
      saleCommitments: dataCommitments.map(c => ({...c, key: c.id})),
      status: "Analyzing"
    };
    yield put(SaleScenarioActions.showEditSaleScenarioSuccess(data));
  } catch (error) {
    yield put(SaleScenarioActions.showEditSaleScenarioError(error));
  }
}
