import { Input } from "antd";
import React from "react";
import NumberFormat from "react-number-format";
import { useCurrencyContext } from "../../../hooks/useCurrencyContext";
import { useSelector } from "react-redux";

// import { Container } from './styles';

function InputCurrency({
  name,
  disabled,
  value = "",
  setValue,
  onBlur,
  symbol = null,
  decimalScale = null,
  placeholder = null,
}) {
  const { translation } = useSelector((state) => state.app);
  const { shouldShowConversion, defaultCurrency } = useCurrencyContext();

  return shouldShowConversion ? (
    <NumberFormat
      name={name}
      disabled={disabled}
      customInput={Input}
      value={value}
      decimalScale={
        decimalScale
          ? decimalScale
          : defaultCurrency
          ? defaultCurrency.decimal_digits === 0
            ? 2
            : defaultCurrency.decimal_digits
          : 2
      }
      allowNegative={false}
      allowLeadingZeros
      decimalSeparator=","
      thousandSeparator="."
      fixedDecimalScale={true}
      placeholder={placeholder ?? translation.defaultPlaceholder}
      addonBefore={`${
        symbol ? symbol : defaultCurrency ? defaultCurrency.symbol : "R$"
      } `}
      onValueChange={({ floatValue }) => {
        if (setValue && typeof setValue === "function") {
          setValue(floatValue);
        }
      }}
      onBlur={() => {
        if (onBlur && typeof onBlur === "function") {
          onBlur();
        }
      }}
    />
  ) : (
    <NumberFormat
      name={name}
      disabled={disabled}
      customInput={Input}
      value={value}
      decimalScale={2}
      allowNegative={false}
      allowLeadingZeros
      decimalSeparator=","
      thousandSeparator="."
      fixedDecimalScale={true}
      placeholder={placeholder ?? translation.defaultPlaceholder}
      addonBefore={`${"R$"} `}
      onValueChange={({ floatValue }) => {
        if (setValue && typeof setValue === "function") {
          setValue(floatValue);
        }
      }}
      onBlur={() => {
        if (onBlur && typeof onBlur === "function") {
          onBlur();
        }
      }}
    />
  );
}

export default InputCurrency;
