import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Icon,
  Input,
  Menu,
  Row,
  Table,
  notification,
} from "antd";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import FarmSalesReportModal from "../../../../components/modals/farmSalesReport";
import ButtonStandard from "../../../../components/utils/button";
import MenuIcon from "../../../../components/utils/table/icons/menu";
import { Container as MenuContainer } from "../../../../components/utils/table/menu/styles";
import TagStatus from "../../../../components/utils/tagStatus";
import { CardCustom, Title } from "./styles";

// Hooks
import { useFinancial } from "../../../../hooks/useFinancialReducer";

// Services
import { deleteFarmSell } from "../../../../services/farmSellService";
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../../services/helpersMethodsService";
import ConfinementResultReportModal from "../../../../components/modals/confinementResultReport";

const { Column } = Table;

const FarmProductionSell = () => {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId, confinement: isFarmConfinement },
  } = useSelector((state) => state.app);

  const [isLoading, setIsLoading] = useState(false);
  const [isModalReportVisible, setIsModalReportVisible] = useState(false);
  const [
    isModalConfinementResultReportVisible,
    setIsModalConfinementResultReportVisible,
  ] = useState(false);

  // Redux
  const { listFarmSells, isLoadingFarmSells, refreshListFarmSells } =
    useFinancial();

  const history = useHistory();

  function handleEdit(record) {
    history.push(`/admin/productionSell/edit/${record.farmSellId}`);
  }

  async function handleDelete(id) {
    setIsLoading(true);
    try {
      await deleteFarmSell({
        groupId,
        farmId,
        id,
      });

      refreshListFarmSells();
      notification.success({
        message: "Venda deletada com sucesso.",
      });
    } catch (error) {
      notification.error({
        message: "Venda não foi deletada. Contate o suporte.",
      });
    } finally {
      setIsLoading(false);
    }
  }

  function renderMenu(record) {
    return record.origin === "BovExo" ? (
      <Menu>
        {record.status === "Confirmed" && (
          <Menu.Item key="0">
            <button onClick={() => handleEdit(record)}>
              {translation.table.menu.edit}
            </button>
          </Menu.Item>
        )}
        {record.status !== "Waiting" && (
          <Menu.Item key="1">
            <button onClick={() => handleDelete(record.farmSellId)}>
              {translation.table.menu.delete}
            </button>
          </Menu.Item>
        )}
      </Menu>
    ) : (
      <></>
    );
  }

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          {dataIndex === "sellDate" ? (
            <DatePicker
              allowClear={true}
              value={
                selectedKeys[0] != null
                  ? moment(selectedKeys[0], "YYYY-MM-DD")
                  : undefined
              }
              style={{ width: 188, marginBottom: 8, display: "block" }}
              format={"DD/MM/YYYY"}
              onChange={(date, dateString) => {
                setSelectedKeys(date ? [date] : []);
              }}
            />
          ) : (
            <Input
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, dataIndex)
              }
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
          )}
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        if (dataIndex === "sellDate") {
          return record[dataIndex]
            ?.toString()
            .includes(moment(value).format("YYYY-MM-DD"));
        } else if (
          dataIndex === "amountAnimals" ||
          dataIndex === "totalValue" ||
          dataIndex === "avgAnimalValue" ||
          dataIndex === "avgCarcassHarnessing"
        ) {
          value = value?.replace(".", "")?.replace(",", ".");
          return (
            getTwoDecimalDigits(record[dataIndex] || 0, 2) === parseFloat(value)
          );
        } else {
          return record[dataIndex]
            ?.toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase());
        }
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
        }
      },
    }),
    [handleReset, handleSearch]
  );

  const handleOpenCloseModalReport = () => {
    setIsModalReportVisible((old) => !old);
  };

  const handleOpenCloseModalConfinementResultReport = () => {
    setIsModalConfinementResultReportVisible((old) => !old);
  };

  return (
    <CardCustom>
      <Row type="flex" justify="space-between" className="rowHeader">
        <Col>
          <Title>{translation.financial.animalSell.title}</Title>
        </Col>
        <Col>
          <Row type="flex">
            <div className="colButtons">
              <ButtonStandard
                buttonType="typeWithoutBackground"
                disabled={isLoadingFarmSells}
                onClick={refreshListFarmSells}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </div>
            {isFarmConfinement && (
              <div className="colButtons">
                <ButtonStandard
                  name="buttonExportTransactions"
                  buttonType="alternative"
                  onClick={handleOpenCloseModalConfinementResultReport}
                >
                  Resultado de Confinamento
                </ButtonStandard>
              </div>
            )}
            <div className="colButtons">
              <ButtonStandard
                name="buttonExportTransactions"
                buttonType="alternative"
                onClick={handleOpenCloseModalReport}
              >
                {translation.financial.animalSell.buttonExport}
              </ButtonStandard>
            </div>
            <div className="colButtons">
              <Link to="/admin/productionSell/new">
                <ButtonStandard name="buttonCreateBudget" buttonType="type8">
                  {translation.financial.animalSell.buttonNew}
                </ButtonStandard>
              </Link>
            </div>
          </Row>
        </Col>
      </Row>
      <Row type="flex">
        <Table
          loading={isLoadingFarmSells || isLoading}
          rowKey="farmSellId"
          size="small"
          dataSource={listFarmSells !== null ? listFarmSells : []}
          scroll={{
            x: true,
          }}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 10,
            pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
          }}
          style={{ width: "100%", margin: "0" }}
        >
          <Column
            title={translation.financial.animalSell.table.columns.origin}
            dataIndex="origin"
            key="origin"
            align="left"
            filtered
            filters={[
              {
                text: "BovExo",
                value: "BovExo",
              },
              {
                text: "CV - BovExo",
                value: "SaleScenario",
              },
              {
                text: "BovManager",
                value: "BovManager",
              },
            ]}
            onFilter={(value, record) => {
              return record.origin === value;
            }}
            sorter={(first, second) => {
              return first.origin.localeCompare(second.origin, "pt-BR", {
                numeric: false,
                ignorePunctuation: true,
              });
            }}
            render={(text) =>
              text === "BovExo" ? (
                <TagStatus
                  background="#684e75"
                  borderColor="#684e94"
                  color="#FFFFFF"
                >
                  BovExo
                </TagStatus>
              ) : text === "SaleScenario" ? (
                <TagStatus
                  background="#684e75"
                  borderColor="#684e94"
                  color="#FFFFFF"
                >
                  CV - Bovexo
                </TagStatus>
              ) : (
                <TagStatus
                  background="#E3E3E3"
                  borderColor="#C4C4C4"
                  color="#4B4B4B"
                >
                  BovManager
                </TagStatus>
              )
            }
          />

          <Column
            title={"Status"}
            dataIndex="status"
            key="status"
            align="left"
            filtered
            filters={[
              {
                text: translation.imports.status.processed,
                value: "Confirmed",
              },
              {
                text: "Pendente",
                value: "Waiting",
              },
              {
                text: translation.imports.status.error,
                value: "Error",
              },
            ]}
            onFilter={(value, record) => {
              return record.status === value;
            }}
            sorter={(first, second) => {
              return first.status.localeCompare(second.status, "pt-BR", {
                numeric: false,
                ignorePunctuation: true,
              });
            }}
            render={(text) =>
              text === "Confirmed" ? (
                <TagStatus
                  background="#C5F1CA"
                  borderColor="#106518"
                  color="#106518"
                >
                  {translation.imports.status.processed}
                </TagStatus>
              ) : text === "Waiting" ? (
                <TagStatus
                  background="#FFDBBC"
                  borderColor="#FE8D2A"
                  color="#FE8D2A"
                >
                  Pendente
                </TagStatus>
              ) : (
                <TagStatus
                  background="#FFD8D8"
                  borderColor="#D44C4C"
                  color="#D44C4C"
                >
                  {translation.imports.status.error}
                </TagStatus>
              )
            }
          />

          <Column
            title={
              translation.financial.animalSell.table.columns.documentNumber
            }
            dataIndex="documentNumberAlias"
            key="documentNumberAlias"
            align="left"
            sorter={(first, second) => {
              if (!first.documentNumberAlias) {
                first.documentNumberAlias = "";
              }
              return first.documentNumberAlias.localeCompare(
                second.documentNumberAlias,
                "pt-BR",
                {
                  numeric: false,
                  ignorePunctuation: true,
                  sensitivity: "base",
                }
              );
            }}
            {...handleGetColumnSearchProps("documentNumberAlias")}
          />

          <Column
            title={translation.financial.animalSell.table.columns.client}
            dataIndex="clientName"
            key="clientName"
            align="left"
            sorter={(first, second) => {
              if (!first.clientName) {
                first.clientName = "";
              }
              return first.clientName.localeCompare(
                second.clientName,
                "pt-BR",
                {
                  numeric: false,
                  ignorePunctuation: true,
                  sensitivity: "base",
                }
              );
            }}
            {...handleGetColumnSearchProps("clientName")}
          />

          <Column
            title={translation.financial.animalSell.table.columns.sellDate}
            dataIndex="sellDate"
            key="sellDate"
            align="left"
            defaultSortOrder="descend"
            sortDirections={["descend", "ascend", "descend"]}
            sorter={(first, second, order) => {
              return order === "descend"
                ? moment(first.sellDate).isAfter(moment(second.sellDate))
                : -1;
            }}
            {...handleGetColumnSearchProps("sellDate")}
            render={(text) => <span>{moment(text).format("DD/MM/YYYY")}</span>}
          />

          <Column
            title={translation.financial.animalSell.table.columns.amountAnimais}
            dataIndex="amountAnimals"
            key="amountAnimals"
            align="center"
            sorter={(first, second) => {
              return first.amountAnimals - second.amountAnimals;
            }}
            {...handleGetColumnSearchProps("amountAnimals")}
          />

          <Column
            title={translation.financial.animalSell.table.columns.gender}
            dataIndex="gender"
            key="gender"
            align="left"
            sorter={(first, second) => {
              if (!first.gender) {
                first.gender = "";
              }
              return first.gender.localeCompare(second.gender, "pt-BR", {
                numeric: false,
                ignorePunctuation: true,
                sensitivity: "base",
              });
            }}
            {...handleGetColumnSearchProps("gender")}
          />

          <Column
            title={translation.financial.animalSell.table.columns.totalValue}
            dataIndex="totalValue"
            key="totalValue"
            align="left"
            sorter={(first, second) => {
              return first.totalValue - second.totalValue;
            }}
            {...handleGetColumnSearchProps("totalValue")}
            render={(text) => (
              <span>{numberMask(getTwoDecimalDigits(text || 0), true)}</span>
            )}
          />

          <Column
            title={translation.financial.animalSell.table.columns.averageValue}
            dataIndex="avgAnimalValue"
            key="avgAnimalValue"
            align="left"
            sorter={(first, second) => {
              return first.avgAnimalValue - second.avgAnimalValue;
            }}
            {...handleGetColumnSearchProps("avgAnimalValue")}
            render={(text) => (
              <span>{numberMask(getTwoDecimalDigits(text || 0), true)}</span>
            )}
          />

          <Column
            title={
              translation.financial.animalSell.table.columns.carcassHarnessing
            }
            dataIndex="avgCarcassHarnessing"
            key="avgCarcassHarnessing"
            align="left"
            sorter={(first, second) => {
              return first.avgCarcassHarnessing - second.avgCarcassHarnessing;
            }}
            {...handleGetColumnSearchProps("avgCarcassHarnessing")}
            render={(text) => <span>{`${numberMask(text, false)} %`}</span>}
          />

          <Column
            title={translation.financial.animalSell.table.columns.paymentType}
            dataIndex="payment"
            key="payment"
            align="left"
            filtered
            filters={[
              {
                text: "À vista",
                value: "inCash",
              },
              {
                text: "Parcelado",
                value: "part",
              },
            ]}
            onFilter={(value, record) => {
              return record.payment === value;
            }}
            sorter={(first, second) => {
              return first.payment.localeCompare(second.payment, "pt-BR", {
                numeric: false,
                ignorePunctuation: true,
              });
            }}
            render={(text) => {
              if (text === "inCash") {
                return <span>À vista</span>;
              } else {
                return <span>Parcelado</span>;
              }
            }}
          />
          <Column
            title={
              translation.financial.animalSell.table.columns
                .financialProjectName
            }
            dataIndex="financialProjectName"
            key="financialProjectName"
            align="left"
            sorter={(first, second) => {
              if (!first.financialProjectName) {
                first.financialProjectName = "";
              }
              return first.financialProjectName.localeCompare(
                second.financialProjectName,
                "pt-BR",
                {
                  numeric: false,
                  ignorePunctuation: true,
                  sensitivity: "base",
                }
              );
            }}
            {...handleGetColumnSearchProps("financialProjectName")}
          />

          <Column
            align="left"
            width={50}
            render={(text, record) => (
              <Dropdown
                overlay={renderMenu(record)}
                trigger={["click"]}
                key={record.id}
              >
                <MenuContainer>
                  <MenuIcon />
                </MenuContainer>
              </Dropdown>
            )}
          />
        </Table>
      </Row>
      <FarmSalesReportModal
        modalVisible={isModalReportVisible}
        closeModal={handleOpenCloseModalReport}
      />
      <ConfinementResultReportModal
        modalVisible={isModalConfinementResultReportVisible}
        closeModal={handleOpenCloseModalConfinementResultReport}
      />
    </CardCustom>
  );
};

export default FarmProductionSell;
