/* eslint-disable react/prefer-stateless-function */
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import GlobalStyle from "./styles/global";
import "./config/reactotron";
import Routes from "./routes";
import store from "./store";
import "./styles/App.css";
import { BoitelSaleScenarioNewContextProvider } from "./contexts/boitelSaleScenarioNewContext";
const App = () => {

  useEffect(() => {
    /** Add ZendDesk chat script in the head */
    let scriptZenDesk = document.createElement("script");
    scriptZenDesk.id = "ze-snippet";
    scriptZenDesk.async = true;
    scriptZenDesk.src = process.env.REACT_APP_ZENDESK_CHAT_URL;
    document.head.appendChild(scriptZenDesk);
  }, []);
  
  return (
    <>
      <GlobalStyle />
      <Provider store={store}>
        <BoitelSaleScenarioNewContextProvider>
          <Routes />
        </BoitelSaleScenarioNewContextProvider>
      </Provider>
    </>
  );
};

export default App;
