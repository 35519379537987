import { Card, Col, Row } from "antd";
import React from "react";
import CardSupplementSupplyPerDietDashboard from "./cards/cardSupplementSupplyPerDietDashboard";
import CardTotalCostSupplementSupplyDashboard from "./cards/cardTotalCostSupplementSupplyDashboard";
import CardAverageConsumeSupplementSupplyDashboard from "./cards/cardAverageConsumeSupplementSupplyDashboard";
import CardAnimalCostPerDaySupplementSupplyDashboard from "./cards/cardAnimalCostPerDaySupplementSupplyDashboard";
import CardTotalAnimalCostSupplementSupplyDashboard from "./cards/cardTotalAnimalCostSupplementSupplyDashboard";

// import { Container } from './styles';

function LotDetailsSupplementSupplyDashboards() {
  const dashboardCards = {
    1: <CardTotalCostSupplementSupplyDashboard />,
    2: <CardAverageConsumeSupplementSupplyDashboard />,
    3: <CardAnimalCostPerDaySupplementSupplyDashboard />,
    4: <CardTotalAnimalCostSupplementSupplyDashboard />,
  };
  return (
    <Row type="flex" gutter={8}>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={12}
        xl={12}
        xxl={12}
        style={{ marginTop: "1rem", marginBottom: "1rem" }}
      >
        <Card title="Por Dieta">
          <CardSupplementSupplyPerDietDashboard />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        <Row type="flex" gutter={8}>
          {Object.entries(dashboardCards)
            .sort((a, b) => Number(a[0]) - Number(b[0]))
            .map(([key, dashboardCard]) => {
              return (
                <Col
                  key={key}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  style={{ marginTop: "1rem", marginBottom: "1rem" }}
                >
                  <Card>{dashboardCard}</Card>
                </Col>
              );
            })}
        </Row>
      </Col>
    </Row>
  );
}

export default LotDetailsSupplementSupplyDashboards;
