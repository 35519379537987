import React from "react";

const WeightIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.432"
    height="13.333"
    viewBox="0 0 16.432 13.333"
  >
    <path
      id="Path_1856"
      data-name="Path 1856"
      d="M16.39,69.927l-2.577-7.966A.854.854,0,0,0,13,61.37h-2.72a2.415,2.415,0,1,0-4.13,0H3.431a.854.854,0,0,0-.812.591L.042,69.927a.853.853,0,0,0,.812,1.116H15.578A.853.853,0,0,0,16.39,69.927Zm-9.45-9.8A1.277,1.277,0,1,1,8.49,61.37H7.942A1.278,1.278,0,0,1,6.939,60.125Zm.148,8.146L6.35,66.854H6V68.27H5.143V64.779H6v1.348H6.35l.723-1.348h.918l-.943,1.67v.01l1,1.812Zm4.495,0h-.713v-.1c0-.073,0-.146,0-.146h-.01a1.448,1.448,0,0,1-.918.3,1.71,1.71,0,0,1-1.714-1.812,1.768,1.768,0,0,1,1.832-1.8,1.929,1.929,0,0,1,1.343.464l-.41.64a1.389,1.389,0,0,0-.85-.322.931.931,0,0,0-1.03.986.991.991,0,0,0,1,1.06,1.2,1.2,0,0,0,.708-.254v-.269h-.493V66.3h1.25V68.27Z"
      transform="translate(0 -57.71)"
      fill="#fff"
    />
  </svg>
);

export default WeightIcon;
