import React from 'react';

const ChartIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16.201" height="16.201" viewBox="0 0 15.201 15.201">
  <g id="increase" transform="translate(0 -0.002)">
    <g id="Grupo_4117" data-name="Grupo 4117" transform="translate(0 14.312)">
      <g id="Grupo_4116" data-name="Grupo 4116">
        <path id="Caminho_2078" data-name="Caminho 2078" d="M14.755,482H.445a.445.445,0,1,0,0,.891h14.31a.445.445,0,1,0,0-.891Z" transform="translate(0 -481.999)" fill="#33C162"/>
      </g>
    </g>
    <g id="Grupo_4119" data-name="Grupo 4119" transform="translate(0.891 9.858)">
      <g id="Grupo_4118" data-name="Grupo 4118">
        <path id="Caminho_2079" data-name="Caminho 2079" d="M33.177,332H30.445a.445.445,0,0,0-.445.445v3.117h3.622v-3.117A.445.445,0,0,0,33.177,332Z" transform="translate(-30 -332)" fill="#33C162"/>
      </g>
    </g>
    <g id="Grupo_4121" data-name="Grupo 4121" transform="translate(5.403 7.186)">
      <g id="Grupo_4120" data-name="Grupo 4120">
        <path id="Caminho_2080" data-name="Caminho 2080" d="M185.115,242h-2.672a.445.445,0,0,0-.445.445v5.789h3.563v-5.789A.445.445,0,0,0,185.115,242Z" transform="translate(-181.998 -242)" fill="#33C162"/>
      </g>
    </g>
    <g id="Grupo_4123" data-name="Grupo 4123" transform="translate(8.075 0.002)">
      <g id="Grupo_4122" data-name="Grupo 4122">
        <path id="Caminho_2081" data-name="Caminho 2081" d="M278.989,3.695,275.872.132a.445.445,0,0,0-.63,0l-3.117,3.563a.445.445,0,0,0,.315.76h1.336v8.966h3.563V4.455h1.336a.445.445,0,0,0,.315-.76Z" transform="translate(-271.994 -0.002)" fill="#33C162"/>
      </g>
    </g>
  </g>
</svg>
);
export default ChartIcon;
