import getHeaders from "../getHeaders";
import apiCommons from "../../config/api_commons";

const PICKET_ENTITY = "picket";

export function getPicketAreaUtilizationDashboard(payload) {
  const { groupId, farmId, dashboardParams, signal } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/${PICKET_ENTITY}/dashboards/PicketAreaUtilizationDashboard`,
    dashboardParams,
    getHeaders(false, signal)
  );
}
