import styled from "styled-components";
import { Drawer } from "antd";

export const Container = styled(Drawer)`
  .ant-drawer-body {
    padding: 24px 15px 10px 15px;
  }

  .drawerForm {
    width: 100%;
    padding: 0px 24px;
    min-height: -moz-calc(100vh - 165px);
    min-height: -webkit-calc(100vh - 165px);
    min-height: calc(100vh - 165px);
  }

  .rowButton {
    display: flex;
    justify-content: center;
  }

  .rowLabel {
    margin-bottom: 7px;
    margin-top: 19px;
  }

  span.subTitle {
    font-size: 14px;
    color: #000;
    margin-bottom: 10px;
  }

  span.farmMessage {
    font-family: Asap;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    color: #4b4b4b;
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    &.error {
      color: #d44c4c;
      margin-left: 15px;
    }
  }

  .ant-select {
    width: 100%;
  }
`;

export const BoxStatus = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
  font-family: Asap;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  &.active {
    background: #c5f1ca;
    border: 1px solid #106518;

    color: #106518;
  }

  &.inactive {
    background: #ffd8d8;
    border: 1px solid #d44c4c;

    color: #d44c4c;
  }
`;

export const CardDetail = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;
  padding: 15px 15px 15px 12px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  div.contentGroup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    font-family: Asap;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;

    strong,
    span {
      margin-bottom: 10px;
    }
  }
`;

export const CardDetailWithBorder = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
  padding: 15px 15px 15px 12px;
  border-radius: 5px;
  background: #fff;
  border-top: 8px solid #4a85ae;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  div.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  div.contentGroup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    font-family: Asap;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;

    strong,
    span {
      margin-bottom: 10px;
    }

    span.title {
      font-family: Asap;
      font-weight: bold;
      font-size: 12px;
      text-align: left;
      text-transform: uppercase;
      color: #a5a5a5;
    }

    ul {
      li {
        list-style: none;
        font-family: Asap;
        font-weight: normal;
        font-size: 14px;
        text-align: left;
        color: #4b4b4b;

        &:not(:last-child) {
          padding-bottom: 10px;
        }
      }
    }
  }
`;

export const ButtonActive = styled.button`
  border-radius: 15px;
  border: none;
  padding: 7px 15px;
  background: #33c162;
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #fff;

  i {
    margin-right: 5px;
  }
`;
