import apiIam from "../config/api_iam";

export function verifyExistsUserByEmail(values) {
  return apiIam.post(`/bovexo/auth/change/passwords/verify/emails`, {
    email: values,
  });
}

export function changePassword(userId, values) {
  return apiIam.patch(`/bovexo/auth/change/passwords/${userId}`, {
    password: values.password,
  });
}

export function validateCode(values) {
  return apiIam.post(`/bovexo/auth/verify/codes`, {
    email: values.email,
    codeValidate: values.codeValidate,
  });
}
