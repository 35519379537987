import styled from "styled-components";
import Clipboard from "react-clipboard.js";

export const ClipboardCustom = styled(Clipboard)`
  border: none !important;
  background-color: transparent !important;
  display: inline-block !important;
  cursor: pointer;

  svg:hover {
    fill: #e9e9e9;
  }
`;
