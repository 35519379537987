import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import useUnitContext from "../../../hooks/useUnitContext";
import { useSelector } from "react-redux";

// Components
import { Container, Footer } from "./styles";
import { Row, Col, Input, notification, Spin, Select } from "antd";
import ButtonStandard from "../../../components/utils/button";
import NumberFormat from "react-number-format";

// Services
import { save, saveForGroup } from "../../../services/unitService";
import {
  RadioButtonCustom,
  RadioGroupCustom,
} from "../../../components/drawers/supplier/styles";

const validationSchema = Yup.object({
  name: Yup.string().trim().max(8).required(),
  description: Yup.string().trim().max(250).required(),
  basicUnit: Yup.string().trim().required(),
  multiplier: Yup.number().moreThan(0).max(10000).required(),
});

const UnitForm = () => {
  const formRef = useRef(null);

  const [form, setForm] = useState(null);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  // Redux Variable
  const {
    translation,
    groupSelected: { id: groupId },
    groupSelected,
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const {
    shouldBlockToOnlyGroupUnit,
    isDrawerVisible,
    data,
    closeDrawer,
    fetchData,
    BASIC_UNITS,
  } = useUnitContext();

  // Method

  function handleCloseDrawer() {
    setForm({
      id: null,
      groupId: groupId,
      farmId: farmId,
      name: null,
      description: null,
      basicUnit: "Unidade",
      multiplier: "",
      status: "Active",
    });
    formRef.current.resetForm({
      id: null,
      groupId: groupId,
      farmId: farmId,
      name: null,
      description: null,
      basicUnit: "Unidade",
      multiplier: "",
      status: "Active",
    });
    closeDrawer();
  }

  async function handleSubmitForm(values) {
    setIsLoadingRequest(true);
    try {
      if (values.farmId === null) {
        await saveForGroup({
          groupId: values.groupId,
          id: values?.id,
          body: values,
        });
      } else {
        await save({
          groupId: values.groupId,
          farmId: values.farmId,
          id: values?.id,
          body: values,
        });
      }
      notification.success({
        message: "Unidade cadastrada/atualizada com sucesso.",
      });
      fetchData();
      handleCloseDrawer();
    } catch (error) {
      notification.error({
        message: "Erro",
        description: "Erro ao cadastrar/editar Unidade",
      });
    } finally {
      setIsLoadingRequest(false);
    }
  }

  function handleDrawerVisible(visible) {
    if (visible) {
      if (data === null) {
        setForm({
          id: null,
          groupId: groupId,
          farmId: shouldBlockToOnlyGroupUnit ? null : farmId,
          name: null,
          description: null,
          basicUnit: "Unidade",
          multiplier: "",
          status: "Active",
        });
        formRef.current.resetForm({
          id: null,
          groupId: groupId,
          farmId: shouldBlockToOnlyGroupUnit ? null : farmId,
          name: null,
          description: null,
          basicUnit: "Unidade",
          multiplier: "",
          status: "Active",
        });
      } else {
        setForm(data);
      }
    }
  }

  return (
    <Container
      title={
        data?.id == null
          ? translation.unit.form.titleCreateNew
          : translation.unit.form.titleEdit
      }
      width={700}
      onClose={handleCloseDrawer}
      maskClosable={false}
      visible={isDrawerVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Spin spinning={isLoadingRequest}>
        <Formik
          ref={formRef}
          enableReinitialize={true}
          initialValues={form}
          onSubmit={handleSubmitForm}
          validationSchema={validationSchema}
          render={({
            values,
            errors,
            submitCount,
            setFieldValue,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="drawerForm">
                {errors.length > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                <Row type="flex" align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label>{translation.unit.form.groupFarmVisibility}</label>
                    </Row>
                    <Row>
                      <RadioGroupCustom
                        value={
                          values.groupId != null && values.farmId != null
                            ? "farm"
                            : values.groupId != null && values.farmId == null
                            ? "group"
                            : "farm"
                        }
                        buttonStyle="solid"
                      >
                        <RadioButtonCustom
                          value="farm"
                          disabled={shouldBlockToOnlyGroupUnit}
                          onChange={(e) => {
                            setFieldValue("groupId", groupId);
                            setFieldValue("farmId", farmId);
                          }}
                        >
                          {translation.supplier.form.radiogroup.farm}
                        </RadioButtonCustom>
                        <RadioButtonCustom
                          value="group"
                          onChange={(e) => {
                            setFieldValue("groupId", groupId);
                            setFieldValue("farmId", null);
                          }}
                        >
                          {translation.supplement.form.radiogroup.group}:{" "}
                          {groupSelected.name}
                        </RadioButtonCustom>
                      </RadioGroupCustom>
                    </Row>
                  </Col>
                </Row>
                {/* Name */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={errors.name && submitCount > 0 ? "error" : ""}
                  >
                    {translation.unit.form.name}*
                  </label>
                </Row>
                <Row type="flex">
                  <Input
                    name="name"
                    value={values.name}
                    placeholder={translation.defaultPlaceholder}
                    autoCapitalize="off"
                    maxLength={8}
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                  />
                </Row>
                {/* description */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={
                      errors.description && submitCount > 0 ? "error" : ""
                    }
                  >
                    {translation.unit.form.description}*
                  </label>
                </Row>
                <Row type="flex">
                  <Input
                    name="description"
                    value={values.description}
                    placeholder={translation.defaultPlaceholder}
                    maxLength={30}
                    autoCapitalize="off"
                    onChange={(e) => {
                      setFieldValue("description", e.target.value);
                    }}
                  />
                </Row>
                {/* basicUnit */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={
                      errors.basicUnit && submitCount > 0 ? "error" : ""
                    }
                  >
                    {translation.unit.form.basicUnit}*
                  </label>
                </Row>
                <Row>
                  <Select
                    style={{ width: "100%" }}
                    name="basicUnit"
                    placeholder={translation.defaultSelectPlaceholder}
                    value={values.basicUnit || undefined}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      setFieldValue("basicUnit", value);
                    }}
                  >
                    {BASIC_UNITS.map((item) => (
                      <Select.Option key={item.key} value={item.key}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Row>
                {/* multiplier */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={
                      errors.multiplier && submitCount > 0 ? "error" : ""
                    }
                  >
                    {translation.unit.form.multiplier}*
                  </label>
                </Row>
                <Row>
                  <NumberFormat
                    customInput={Input}
                    value={values.multiplier}
                    min={0}
                    allowNegative={false}
                    decimalScale={3}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale={true}
                    allowLeadingZeros={true}
                    placeholder={translation.defaultPlaceholder}
                    name="multiplier"
                    onValueChange={({ floatValue }) => {
                      setFieldValue(
                        "multiplier",
                        floatValue != null && floatValue >= 0
                          ? floatValue > 10000
                            ? 10000
                            : floatValue
                          : null
                      );
                    }}
                  />
                </Row>
              </div>
              <Footer>
                <Row type="flex">
                  <Col span={24} className="buttonsDiv">
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={closeDrawer}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard type="submit" buttonType="type6">
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
      </Spin>
    </Container>
  );
};

export default UnitForm;
