import { call, put } from 'redux-saga/effects';
import { Creators as TypologyItemActions } from '../ducks/typologyItem';
/** Services */
import { getTypologyItemIndex } from '../../services/typologyItemService';

export function* indexTypologyItem(action) {
  try {
    const {
      data: { results: allTypologyItems },
    } = yield call(getTypologyItemIndex, action.payload);
    const { items } = allTypologyItems[0];
    yield put(TypologyItemActions.indexTypologyItemSuccess(items));
  } catch (error) {
    yield put(TypologyItemActions.indexTypologyItemError(error));
  }
}
