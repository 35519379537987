import React from "react";

// import { Container } from './styles';

const DecisionIcon = () => (
  <svg
    id="bovexoDecisionIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="23.98"
    height="23.979"
    viewBox="0 0 23.98 23.979"
  >
    <g id="decision-making" transform="translate(0 -0.501)">
      <path
        id="Path_938"
        data-name="Path 938"
        d="M412.468,106.477a.468.468,0,1,0,.468.468A.469.469,0,0,0,412.468,106.477Zm0,0"
        transform="translate(-392.703 -101.012)"
        fill="#4b4b4b"
      />
      <path
        id="Path_939"
        data-name="Path 939"
        d="M80.468,266.477a.468.468,0,1,0,.468.468A.469.469,0,0,0,80.468,266.477Zm0,0"
        transform="translate(-76.253 -253.519)"
        fill="#4b4b4b"
      />
      <path
        id="Path_940"
        data-name="Path 940"
        d="M123.56,266.477h-3.091a.468.468,0,1,0,0,.937h3.091a.468.468,0,1,0,0-.937Zm0,0"
        transform="translate(-114.38 -253.519)"
        fill="#4b4b4b"
      />
      <path
        id="Path_941"
        data-name="Path 941"
        d="M20.1,1.855a.468.468,0,0,0-.8.331V3.591H13.4V1.356a.468.468,0,0,0-.137-.331,1.8,1.8,0,0,0-2.535,0,.468.468,0,0,0-.137.331V3.591H8.243a.468.468,0,0,0-.468.468V7.806a.468.468,0,0,0,.468.468h2.342v2.81h-5.9V9.68a.468.468,0,0,0-.8-.331L.137,13.1a.468.468,0,0,0,0,.662l3.747,3.747a.468.468,0,0,0,.8-.331V15.769h5.9v5.9H9.18a1.407,1.407,0,0,0-1.405,1.405v.468H6.37a.468.468,0,1,0,0,.937H17.61a.468.468,0,1,0,0-.937H16.205v-.468A1.407,1.407,0,0,0,14.8,21.67H13.4v-5.9h2.342a.468.468,0,0,0,.468-.468V11.553a.468.468,0,0,0-.468-.468H13.4V8.275h5.9V9.68a.468.468,0,0,0,.8.331l3.747-3.747a.468.468,0,0,0,0-.662Zm-8.574-.279a.86.86,0,0,1,.937,0V3.591h-.937Zm3.747,21.5v.468H8.711v-.468a.469.469,0,0,1,.468-.468H14.8A.469.469,0,0,1,15.269,23.075Zm-2.81-1.405h-.937v-5.9h.937Zm2.81-6.838H4.215a.468.468,0,0,0-.468.468v.743L1.13,13.427l2.616-2.616v.743a.468.468,0,0,0,.468.468H15.269Zm-2.81-3.747h-.937V8.275h.937Zm7.775-2.536V7.806a.468.468,0,0,0-.468-.468H8.711V4.528H19.765a.468.468,0,0,0,.468-.468V3.317L22.85,5.933Zm0,0"
        fill="#4b4b4b"
      />
      <path
        id="Path_942"
        data-name="Path 942"
        d="M309.56,106.477h-3.091a.468.468,0,1,0,0,.937h3.091a.468.468,0,1,0,0-.937Zm0,0"
        transform="translate(-291.668 -101.012)"
        fill="#4b4b4b"
      />
    </g>
  </svg>
);
export default DecisionIcon;
