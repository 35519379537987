import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
// Components
import { FiEdit2, FiFlag, FiMenu, FiStar } from "react-icons/fi";
import { Row, Col, Input, Select, Tooltip, notification, Icon } from "antd";
import { Container, Title } from "./styles";
import ButtonStandard from "../../../components/utils/button";
import BreadCrumbs from "../../../components/utils/breadCrumbs";
import BoitelSaleScenarioDetailsInfo from "./detailsInfo";
import Loading from "../../../components/utils/loading";
import BoitelSaleScenarioDetailsSalesInfo from "./salesInfo";
import CheckIcon from "../../../components/utils/icons/check";
// Services
import {
  getBoitelSaleScenarioResultArrobaPricesService,
  getBoitelSaleScenarioResultCommitmentsService,
  getBoitelSaleScenarioResultDietStrategiesSuggestedService,
  getBoitelSaleScenarioResultParameterService,
  getBoitelSaleScenarioResultService,
  getBoitelSaleScenarioResultViolationsService,
  setBoitelSaleScenarioResultAsFavoriteService,
  updateBoitelSaleScenarioResultService,
} from "../../../services/boitelSaleScenarioResultService";
import { cancelBoitelSaleScenarioService } from "../../../services/boitelSaleScenarioService";
import BoxText from "../../../components/utils/boxText";

const DetailsBoitelSaleScenario = () => {
  const { id: boitelSaleScenarioId, resultId: boitelSaleScenarioResultId } =
    useParams();

  const history = useHistory();

  const [identificationResult, setIdentificationResult] = useState(null);
  const [shouldShowParameters, setShouldShowParameters] = useState(true);
  const [shouldEditResult, setShouldEditResult] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingDietStrategiesData, setIsLoadingDietStrategiesData] =
    useState(true);
  const [isLoadingCommitmentsData, setIsLoadingCommitmentsData] =
    useState(true);
  const [isLoadingViolationsData, setIsLoadingViolationsData] = useState(true);
  const [isLoadingArrobaPricesData, setIsLoadingArrobaPricesData] =
    useState(true);
  const [
    isLoadingBoitelSaleScenarioParamData,
    setIsLoadingBoitelSaleScenarioParamData,
  ] = useState(true);
  const [boitelSaleScenarioResult, setBoitelSaleScenarioResult] =
    useState(null);
  const [boitelSaleScenarioParam, setBoitelSaleScenarioParam] = useState(null);
  const [dietStrategies, setDietStrategies] = useState([]);
  const [commitments, setCommitments] = useState([]);
  const [violations, setViolations] = useState([]);
  const [arrobaPrices, setArrobaPrices] = useState([]);

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  // Effects

  // Get principal info
  useEffect(() => {
    async function fetch() {
      setIsLoadingData(true);
      try {
        const {
          data: { results },
        } = await getBoitelSaleScenarioResultService({
          groupId,
          farmId,
          boitelSaleScenarioId,
          boitelSaleScenarioResultId,
        });
        setBoitelSaleScenarioResult(results);
      } catch (error) {
      } finally {
        setIsLoadingData(false);
      }
    }
    fetch();
  }, [groupId, farmId, boitelSaleScenarioId, boitelSaleScenarioResultId]);
  // Get parameter info
  useEffect(() => {
    async function fetch() {
      setIsLoadingBoitelSaleScenarioParamData(true);
      try {
        const {
          data: { results },
        } = await getBoitelSaleScenarioResultParameterService({
          groupId,
          farmId,
          boitelSaleScenarioId,
          boitelSaleScenarioResultId,
        });
        setBoitelSaleScenarioParam(results);
      } catch (error) {
      } finally {
        setIsLoadingBoitelSaleScenarioParamData(false);
      }
    }
    fetch();
  }, [groupId, farmId, boitelSaleScenarioId, boitelSaleScenarioResultId]);
  // Get diet strategy suggested info
  useEffect(() => {
    async function fetch() {
      setIsLoadingDietStrategiesData(true);
      try {
        const {
          data: { results },
        } = await getBoitelSaleScenarioResultDietStrategiesSuggestedService({
          groupId,
          farmId,
          boitelSaleScenarioId,
          boitelSaleScenarioResultId,
        });
        setDietStrategies(results);
      } catch (error) {
      } finally {
        setIsLoadingDietStrategiesData(false);
      }
    }
    fetch();
  }, [groupId, farmId, boitelSaleScenarioId, boitelSaleScenarioResultId]);
  // Get commitments info
  useEffect(() => {
    async function fetch() {
      setIsLoadingCommitmentsData(true);
      try {
        const {
          data: { results },
        } = await getBoitelSaleScenarioResultCommitmentsService({
          groupId,
          farmId,
          boitelSaleScenarioId,
          boitelSaleScenarioResultId,
        });
        setCommitments(results);
      } catch (error) {
      } finally {
        setIsLoadingCommitmentsData(false);
      }
    }
    fetch();
  }, [groupId, farmId, boitelSaleScenarioId, boitelSaleScenarioResultId]);
  // Get violations info
  useEffect(() => {
    async function fetch() {
      setIsLoadingViolationsData(true);
      try {
        const {
          data: { results },
        } = await getBoitelSaleScenarioResultViolationsService({
          groupId,
          farmId,
          boitelSaleScenarioId,
          boitelSaleScenarioResultId,
        });
        setViolations(results);
      } catch (error) {
      } finally {
        setIsLoadingViolationsData(false);
      }
    }
    fetch();
  }, [groupId, farmId, boitelSaleScenarioId, boitelSaleScenarioResultId]);
  // Get arroba prices info
  useEffect(() => {
    async function fetch() {
      setIsLoadingArrobaPricesData(true);
      try {
        const {
          data: { results },
        } = await getBoitelSaleScenarioResultArrobaPricesService({
          groupId,
          farmId,
          boitelSaleScenarioId,
          boitelSaleScenarioResultId,
        });
        setArrobaPrices(results);
      } catch (error) {
      } finally {
        setIsLoadingArrobaPricesData(false);
      }
    }
    fetch();
  }, [groupId, farmId, boitelSaleScenarioId, boitelSaleScenarioResultId]);

  // Methods

  const removeFarmSell = useCallback((farmSellId) => {
    setBoitelSaleScenarioResult((old) => ({
      ...old,
      farmSells: old.farmSells.filter((fs) => fs.id !== farmSellId),
    }));
  }, []);

  // Method to change the result
  const handleChangeResult = useCallback(
    (id) => {
      history.push(
        `/admin/decisions/scenarios/sales/boitel/${boitelSaleScenarioId}/results/${id}`
      );
    },
    [boitelSaleScenarioId, history]
  );

  // Method set result as favorite
  const handleSetAsFavorite = useCallback(async () => {
    setIsLoadingData(true);
    try {
      await setBoitelSaleScenarioResultAsFavoriteService({
        groupId,
        farmId,
        boitelSaleScenarioId,
        boitelSaleScenarioResultId,
      });
      setBoitelSaleScenarioResult((old) => ({
        ...old,
        baseline: true,
        allResults: old.allResults.map((r) => {
          if (r.id === boitelSaleScenarioResultId) {
            r.baseline = true;
          }
          return r;
        }),
      }));
      notification.success({
        message: "Resultado favoritado com sucesso.",
      });
    } catch (error) {
      notification.error({
        message: "Não foi possível favoritar resultado. Contate o suporte.",
      });
    } finally {
      setIsLoadingData(false);
    }
  }, [groupId, farmId, boitelSaleScenarioId, boitelSaleScenarioResultId]);

  // Method cancel Scenario
  const handleCancelScenario = useCallback(async () => {
    setIsLoadingData(true);
    try {
      await cancelBoitelSaleScenarioService({
        groupId,
        farmId,
        id: boitelSaleScenarioId,
      });
      setBoitelSaleScenarioResult((old) => ({
        ...old,
        status: "Canceled",
        baseline: false,
        allResults: old.allResults.map((r) => {
          r.baseline = false;
          return r;
        }),
      }));
      notification.success({
        message: "Cenário cancelado com sucesso.",
      });
    } catch (error) {
      notification.error({
        message: "Não foi possível cancelar cenário. Contate o suporte.",
      });
    } finally {
      setIsLoadingData(false);
    }
  }, [groupId, farmId, boitelSaleScenarioId]);

  // Method cancel Scenario
  const handleUpdateIdentificationResult = useCallback(async () => {
    setIsLoadingData(true);
    try {
      const {
        data: { results },
      } = await updateBoitelSaleScenarioResultService({
        groupId,
        farmId,
        boitelSaleScenarioId,
        boitelSaleScenarioResultId,
        body: {
          identification: identificationResult,
        },
      });
      setBoitelSaleScenarioResult(results);
      notification.success({
        message: "Identificação do resultado atualizada com sucesso.",
      });
      setShouldEditResult(false);
    } catch (error) {
      notification.error({
        message:
          "Não foi possível atualizar identificação do resultado com sucesso. Contate o suporte.",
      });
    } finally {
      setIsLoadingData(false);
    }
  }, [
    groupId,
    farmId,
    boitelSaleScenarioId,
    boitelSaleScenarioResultId,
    identificationResult,
  ]);

  return (
    <Container>
      {isLoadingData && <Loading />}
      <Row type="flex">
        {/* Col with animals and dates */}
        <Col span={shouldShowParameters ? 16 : 22} className="mainContainer">
          {boitelSaleScenarioResult?.status === "Canceled" && (
            <BoxText
              color="#D44C4C"
              iconComponent={<Icon type="stop" style={{ color: "#D44C4C" }} />}
              style={{ marginBottom: 24 }}
            >
              <span>Este cenário foi cancelado.</span>
            </BoxText>
          )}
          {/* Header and BreadCrumbs */}
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="breadCrumbsContainer"
          >
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <div className="titleContainer">
                {boitelSaleScenarioResult?.baseline && (
                  <FiStar
                    fill="#FFD754"
                    color="#FFD754"
                    size={24}
                    style={{ marginRight: 8 }}
                  />
                )}
                <Title>
                  {boitelSaleScenarioResult?.boitelSaleScenarioName || "-"}
                </Title>
                {shouldEditResult ? (
                  <>
                    <Input
                      className="inputResultId"
                      placeholder={translation.defaultPlaceholder}
                      value={identificationResult}
                      onChange={(e) => setIdentificationResult(e.target.value)}
                    />
                    <ButtonStandard
                      type="button"
                      buttonType="typeWithoutBackground"
                      color="#d44c4c"
                      isUpperCase={true}
                      padding="5px 15px"
                      onClick={() => setShouldEditResult((old) => !old)}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard
                      type="button"
                      buttonType="typeWithoutBackground"
                      color="#684E94"
                      isUpperCase={true}
                      padding="5px 5px"
                      onClick={handleUpdateIdentificationResult}
                    >
                      {translation.buttons.save}
                    </ButtonStandard>
                  </>
                ) : (
                  <>
                    <Select
                      placeholder={translation.defaultSelectPlaceholder}
                      value={
                        boitelSaleScenarioResult?.allResults
                          ? boitelSaleScenarioResultId
                          : undefined
                      }
                      onChange={(value) => handleChangeResult(value)}
                    >
                      {boitelSaleScenarioResult?.allResults.map((r) => (
                        <Select.Option key={r.id} value={r.id}>
                          {r.baseline && <CheckIcon />} {r.identification}
                        </Select.Option>
                      ))}
                    </Select>
                    {boitelSaleScenarioResult?.status !== "Canceled" && (
                      <Tooltip title="Edite a identificação do resultado">
                        <button
                          className="editIcon"
                          onClick={() => {
                            setShouldEditResult((old) => !old);
                            setIdentificationResult(
                              boitelSaleScenarioResult?.boitelSaleScenarioResultIdentification
                            );
                          }}
                        >
                          <FiEdit2 />
                        </button>
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} align="right">
              <BreadCrumbs>
                <span
                  onClick={() => history.push("/admin/decisions", { tab: "4" })}
                  className="pageTreeInative"
                  style={{ cursor: "pointer" }}
                >
                  Decisões
                </span>
                <span className="pageTreeActive">Detalhes: Boitel - Venda</span>
              </BreadCrumbs>
            </Col>
          </Row>
          {/* Main content with animals */}
          <Row>
            <BoitelSaleScenarioDetailsSalesInfo
              boitelSaleScenarioResult={boitelSaleScenarioResult}
              removeFarmSell={removeFarmSell}
            />
          </Row>
        </Col>
        {/* Col with details of result */}
        <Col
          span={shouldShowParameters ? 8 : 2}
          className="containerParameters"
        >
          {!shouldShowParameters ? (
            <Row type="flex" justify="center">
              <button
                className="buttonFlag"
                onClick={() => setShouldShowParameters(!shouldShowParameters)}
              >
                <FiMenu />
              </button>
            </Row>
          ) : (
            <>
              <BoitelSaleScenarioDetailsInfo
                setShouldShowParameters={setShouldShowParameters}
                boitelSaleScenarioResult={boitelSaleScenarioResult}
                dietStrategies={dietStrategies}
                commitments={commitments}
                violations={violations}
                arrobaPrices={arrobaPrices}
                boitelSaleScenarioParam={boitelSaleScenarioParam}
                isLoadingData={isLoadingData}
                isLoadingDietStrategiesData={isLoadingDietStrategiesData}
                isLoadingCommitmentsData={isLoadingCommitmentsData}
                isLoadingViolationsData={isLoadingViolationsData}
                isLoadingArrobaPricesData={isLoadingArrobaPricesData}
                isLoadingBoitelSaleScenarioParamData={
                  isLoadingBoitelSaleScenarioParamData
                }
              />
              <Row type="flex" justify="center" className="buttonsRows">
                {boitelSaleScenarioResult?.status !== "Canceled" &&
                  boitelSaleScenarioResult?.status !== "PartialCanceled" &&
                  boitelSaleScenarioResult?.status !== "Error" && (
                    <ButtonStandard
                      buttonType="secondary"
                      onClick={handleCancelScenario}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>
                  )}
                {!boitelSaleScenarioResult?.baseline &&
                  boitelSaleScenarioResult?.status !== "Canceled" &&
                  boitelSaleScenarioResult?.status !== "PartialCanceled" &&
                  boitelSaleScenarioResult?.status !== "Error" && (
                    <ButtonStandard
                      buttonType="principal"
                      onClick={handleSetAsFavorite}
                    >
                      {translation.buttons.useAsFavorite}
                    </ButtonStandard>
                  )}
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default DetailsBoitelSaleScenario;
