import styled from "styled-components";
import { Card } from "antd";

export const Container = styled(Card)`
  margin-top: 13px !important;
  border-radius: 7px !important;
  background: #fff !important;
  box-shadow: 0px 0px 10px #d8d8d8 !important;
  overflow-y: auto;
  height: 580px;
  padding: 0px !important;
  .ant-card-body {
    padding-top: 14px;
  }
  .rowHeader {
    margin-bottom: 17px;
  }
`;

export const PageTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;
