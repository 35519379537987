import apiCommons from "../config/api_commons";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiCommons = axios.create({
//   baseURL: "http://localhost:8083",
// });

export function findAll(payload) {
  const { groupId, farmId } = payload;
  return apiCommons.get(
    `/bovexo/${groupId}/farms/${farmId}/veterinaries/supplements`,
    getHeaders(false, null)
  );
}

export function save(payload) {
  const { groupId, farmId, id, body } = payload;
  if (id === null) {
    return apiCommons.post(
      `/bovexo/${groupId}/farms/${farmId}/veterinaries/supplements`,
      body,
      getHeaders(false, null)
    );
  } else {
    return apiCommons.patch(
      `/bovexo/${groupId}/farms/${farmId}/veterinaries/supplements/${id}`,
      body,
      getHeaders(false, null)
    );
  }
}

export function saveForGroup(payload) {
  const { groupId, id, body } = payload;
  if (id === null) {
    return apiCommons.post(
      `/bovexo/${groupId}/farms/veterinaries/supplements`,
      body,
      getHeaders(false, null)
    );
  } else {
    return apiCommons.patch(
      `/bovexo/${groupId}/farms/veterinaries/supplements/${id}`,
      body,
      getHeaders(false, null)
    );
  }
}

export function inactivate(payload) {
  const { groupId, farmId, id } = payload;
  return apiCommons.delete(
    `/bovexo/${groupId}/farms/${farmId}/veterinaries/supplements/${id}`,
    getHeaders(false, null)
  );
}

export function activate(payload) {
  const { groupId, farmId, id } = payload;
  return apiCommons.patch(
    `/bovexo/${groupId}/farms/${farmId}/veterinaries/supplements/${id}/active`,
    null,
    getHeaders(false, null)
  );
}
