import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import {
	Icon,
	Row,
	Col,
	Table,
	notification,
} from "antd";

import { Container, PageTitle } from "./styles";
import TagStatus from "../../../components/utils/tagStatus";
import ButtonStandard from "../../../components/utils/button";

// // Services
import { findAll } from "../../../services/supplierCollaboratorService";

const CollaboratorsSupplierGroup = (props) => {

	// Variable
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	// Variable Redux
	const {
		translation,
		groupSelected: { id: groupId },
		farmSelected: { id: farmId },
	} = useSelector((state) => state.app);

	// Effect
	useEffect(() => {
		async function fetchData(page = 0, sorter = {}, filters = "", size = 10) {
			try {
				setIsLoading(true);
				const {
					data: { results },
				}
					= await findAll({
						supplierGroupId: groupId,
						supplierGroupFarmId: farmId,
						page,
						sorter,
						filters,
						size,
					});
				setIsLoading(false);
				setData(results);
			} catch (error) {
				notification.error({
					message: "Não foi possível retornar os convites",
				});
				setIsLoading(false);
				setData(null);
			}
		}
		fetchData();
	}, [groupId, farmId]);

	// Methods
	async function fetchData(page = 0, sorter = {}, filters = "", size = 10) {
		try {
			setIsLoading(true);
			const {
				data: { results },
			} = await findAll({
				supplierGroupId: groupId,
				supplierGroupFarmId: farmId,
				page,
				sorter,
				filters,
				size,
			});
			setIsLoading(false);
			setData(results);
		} catch (error) {
			notification.error({
				message: "Não foi possível retornar os convites",
			});
			setIsLoading(false);
			setData(null);
		}
	}

	async function handleTableChange(pagination, filters, sorter) {
		let search = "";
		await fetchData(
			pagination.current,
			sorter,
			search,
			pagination.pageSize
		);
	};

	return (
		<Container>
			<Row type="flex" className="rowHeader" align="middle">
				<Col span={8}>
					<PageTitle>Colaboradores</PageTitle>
				</Col>
				<Col span={16} align="right">
					<ButtonStandard
						buttonType="typeWithoutBackground"
						onClick={() => fetchData()}
					>
						<Icon type="reload" />
					</ButtonStandard>
				</Col>
			</Row>
			<Row type="flex">
				<Col span={24}>
					<Table
						loading={isLoading}
						rowKey="id"
						dataSource={data != null ? data : []}
						pagination={{
							showSizeChanger: true,
							defaultPageSize: 10,
							pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
						}}
						size="small"
						scroll={{
							x: true,
						}}
						onChange={handleTableChange}
					>
						<Table.Column
							title={translation.farm.table.columns.status}
							dataIndex="status"
							key="status"
							align="left"
							sorter
							render={(status) =>
								status === "Pending" ? (
									<TagStatus
										background="#FFDBBC"
										borderColor="#FE8D2A"
										color="#FE8D2A"
									>
										Pendente
									</TagStatus>
								) : status === "Active" ? (
									<TagStatus
										background="#E2FFE5"
										borderColor="#33C162"
										color="#33C162"
									>
										Ativo
									</TagStatus>
								) : (
									<TagStatus
										background="#FBC7C7"
										borderColor="#D44C4C"
										color="#D44C4C"
									>
										Inativo
									</TagStatus>
								)
							}
						/>
						<Table.Column
							title="Nome"
							dataIndex="name"
							key="name"
							align="left"
							sorter

						/>
						<Table.Column
							title="Email"
							dataIndex="email"
							key="email"
							align="left"
							sorter
						/>
						<Table.Column
							title="Perfil"
							dataIndex="profile"
							key="profile"
							align="left"
							sorter
							render={(text) => (
								text === 'Collaborator' ? ("Colaborador")
									: text === 'Admin' && ("Responsável")
							)}
						/>
						<Table.Column
							title="Empresa"
							dataIndex="supplierGroupFarmName"
							key="supplierGroupFarmName"
							align="left"
							sorter
						/>
					</Table>
				</Col>
			</Row>
		</Container>
	);
};

export default withRouter(CollaboratorsSupplierGroup);
