import { useContext } from "react";
import { PicketSupplementSupplyContext } from "../../contexts/picketSupplementSupplyContext";

const usePicketSupplementSupplyContext = () => {
  const value = useContext(PicketSupplementSupplyContext);

  return value;
};

export default usePicketSupplementSupplyContext;
