import styled from "styled-components";

export const Container = styled.div`
  border-radius: 5px;
  background: #f5f5f5;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 21px 17px;
  margin-top: 10px;

  .ant-row-flex {
    & + .ant-row-flex {
      margin-top: 16px;
    }
  }

  font-family: "Asap", sans-serif;
  font-size: 14px;
  color: #4b4b4b;

  span {
    font-weight: normal;
    text-align: left;
  }
`;
