import { call, put } from "redux-saga/effects";
import { Creators as FinancialActions } from "../ducks/financial";
import { notification } from "antd";
// Services
import {
  getFarmCosts,
  getSummaryFarmCosts,
  saveOrUpdateCosts,
  saveOrUpdateSummarizeCosts,
} from "../../services/financialService";

export function* indexFarmCosts(action) {
  try {
    const {
      data: { results },
    } = yield call(getFarmCosts, action.payload);
    yield put(FinancialActions.getFarmCostsSuccess(results));
  } catch (error) {
    yield put(FinancialActions.getFarmCostsError(error));
  }
}

export function* createOrUpdateFarmCosts(action) {
  try {
    yield call(saveOrUpdateCosts, action.payload);
    notification.success({
      message: "Custos atualizados",
    });
    yield put(FinancialActions.saveFarmCostsSuccess(true));
    yield put(FinancialActions.disableEditForm(true));
    yield put(
      FinancialActions.getFarmCosts(
        action.payload.groupId,
        action.payload.farmId
      )
    );
    yield put(
      FinancialActions.getSummaryFarmCosts(
        action.payload.groupId,
        action.payload.farmId
      )
    );
  } catch (error) {
    notification.error({
      message: "Não foi possível atualizar os custos. Contate o suporte.",
    });
    yield put(FinancialActions.saveFarmCostsError(error));
  }
}
export function* createOrUpdateSummarizeFarmCosts(action) {
  try {
    yield call(saveOrUpdateSummarizeCosts, action.payload);
    notification.success({
      message: "Custos atualizados",
    });
    yield put(FinancialActions.saveFarmCostsSummarizeSuccess(true));
    yield put(FinancialActions.disableEditForm(true));
    yield put(
      FinancialActions.getSummaryFarmCosts(
        action.payload.groupId,
        action.payload.farmId
      )
    );
    yield put(
      FinancialActions.getFarmCosts(
        action.payload.groupId,
        action.payload.farmId
      )
    );
  } catch (error) {
    notification.error({
      message: "Não foi possível atualizar os custos. Contate o suporte.",
    });
    yield put(FinancialActions.saveFarmCostsSummarizeError(error));
  }
}

export function* indexSummaryFarmCosts(action) {
  try {
    const {
      data: { results },
    } = yield call(getSummaryFarmCosts, action.payload);
    yield put(FinancialActions.getSummaryFarmCostsSuccess(results));
  } catch (error) {
    yield put(FinancialActions.getSummaryFarmCostsError(error));
  }
}
