import { useContext, useReducer } from "react";
import { FinancialContext } from "../../contexts/financialContext";

const initialState = {
  listFinancialParameters: [],
  isLoadingListFinancialParameters: false,
  listFinancialCashFlow: {
    harvests: [],
    valuesGroupByNature: [],
    valuesGroupByClass: [],
  },
  isLoadingListFinancialCashFlow: false,
  listFinancialCosting: {
    harvests: [],
    valuesGroupByNature: [],
    valuesGroupByClass: [],
  },
  isLoadingListFinancialCosting: false,
  shouldShowDrawerFinancialCashFlowBudget: false,
  isLoadingListFinancialTransaction: false,
  listFinancialTransaction: [],
  shouldShowFormFinancialTransaction: false,
  listFarmSells: null,
  isLoadingFarmSells: false,
  isDrawerFinancialClassVisible: false,
  dataDrawerFincialClass: {
    id: null,
    name: null,
    status: "A",
    financialNatureId: null,
    depreciation: "",
    shouldCost: true,
  },
};

const financialReducer = (state = initialState, action) => {
  switch (action.type) {
    case "START_STOP_LOADING_LIST_FINANCIAL_PARAMETERS":
      return {
        ...state,
        isLoadingListFinancialParameters:
          !state.isLoadingListFinancialParameters,
      };
    case "UPDATE_LIST_FINANCIAL_PARAMETERS":
      return { ...state, listFinancialParameters: action.payload.data };
    case "START_STOP_LOADING_LIST_FINANCIAL_CASH_FLOW":
      return {
        ...state,
        isLoadingListFinancialCashFlow: !state.isLoadingListFinancialCashFlow,
      };
    case "UPDATE_LIST_FINANCIAL_CASH_FLOW":
      return { ...state, listFinancialCashFlow: action.payload.data };
    case "START_STOP_LOADING_LIST_FINANCIAL_COSTING":
      return {
        ...state,
        isLoadingListFinancialCosting: !state.isLoadingListFinancialCosting,
      };
    case "UPDATE_LIST_FINANCIAL_COSTING":
      return { ...state, listFinancialCosting: action.payload.data };
    case "SHOW_OR_HIDE_DRAWER_FINANCIAL_CASH_FLOW_BUDGET":
      return {
        ...state,
        shouldShowDrawerFinancialCashFlowBudget:
          !state.shouldShowDrawerFinancialCashFlowBudget,
      };
    case "START_STOP_LOADING_LIST_FINANCIAL_TRANSACTIONS":
      return {
        ...state,
        isLoadingListFinancialTransaction:
          !state.isLoadingListFinancialTransaction,
      };
    case "UPDATE_LIST_FINANCIAL_TRANSACTIONS":
      return { ...state, listFinancialTransaction: action.payload.data };
    case "SHOW_OR_HIDE_FORM_FINANCIAL_TRANSACTION":
      return {
        ...state,
        shouldShowFormFinancialTransaction:
          !state.shouldShowFormFinancialTransaction,
      };
    case "UPDATE_LIST_FARM_SELLS":
      return { ...state, listFarmSells: action.payload.data };
    case "START_STOP_LOADING_LIST_FARM_SELLS":
      return {
        ...state,
        isLoadingFarmSells: !state.isLoadingFarmSells,
      };
    default:
      return state;
  }
};

export const useFinancialReducer = () => {
  const [state, dispatch] = useReducer(financialReducer, initialState);

  return { state, dispatch };
};

export const useFinancial = () => {
  const value = useContext(FinancialContext);
  return value;
};
