import { useContext } from "react";
import { ReproductiveProtocolContext } from "../../contexts/reproductiveProtocolContext";

const useReproductiveProtocolContext = () => {
  const value = useContext(ReproductiveProtocolContext);

  return value;
};

export default useReproductiveProtocolContext;
