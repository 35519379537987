import React, { useState, useEffect } from "react";
import Axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import CanvasJSReact from "../../../../../../assets/canvasjs.react";

// Components
import { Container, Title } from "./styles";
import { Row, Col, Spin, Empty } from "antd";
// Services

import { getResultFutureAndRealizedSell } from "../../../../../../services/saleScenarioService";
import { getTwoDecimalDigits } from "../../../../../../services/helpersMethodsService";

function SaleFutureAndRealizedChart({ dataResult }) {
  // Variables
  const { CanvasJSChart } = CanvasJSReact;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const toggleDataSeries = (e) => {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    e.chart.render();
  };
  const chartOptions = {
    animationEnabled: true,
    zoomEnabled: true,
    dataPointWidth: 22,
    height: 290,
    axisX: {
      title: "",
    },
    toolTip: {
      fontFamily: "Asap",
      shared: true,
    },
    axisY: {
      includeZero: true,
      prefix: "R$ ",
      valueFormatString: "#0",
    },
    axisY2: {
      suffix: "",
    },
    legend: {
      cursor: "pointer",
      itemclick: toggleDataSeries,
    },
    data: data,
  };

  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  useEffect(() => {
    let signal = Axios.CancelToken.source();
    let mount = true;
    const { saleScenarioId } = dataResult;

    function formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    }

    async function fetch() {
      let dData = [];
      try {
        const {
          data: { results },
        } = await getResultFutureAndRealizedSell({
          groupId,
          farmId,
          saleScenarioId,
        });
        if (results != null && results.length > 0) {
          const estimatedBilling = results.map((x) => ({
            label: formatDate(x.sellDate),
            y: getTwoDecimalDigits(x.estimatedBilling),
          }));

          const totalBilling = results.map((x) => ({
            label: formatDate(x.sellDate),
            y: getTwoDecimalDigits(x.totalBilling),
          }));

          const estimatedMargin = results.map((x) => ({
            label: formatDate(x.sellDate),
            y: getTwoDecimalDigits(x.estimatedMargin),
          }));

          const totalMargin = results.map((x) => ({
            label: formatDate(x.sellDate),
            y: getTwoDecimalDigits(x.totalMargin),
          }));

          dData = [
            ...dData,
            {
              type: "column",
              name: "Faturamento planejado",
              showInLegend: true,
              xValueFormatString: "DD/MM/YY",
              color: "#4A85AE",
              dataPointWidth: 99,
              dataPoints: estimatedBilling,
            },
            {
              type: "column",
              name: "Faturamento real",
              showInLegend: true,
              xValueFormatString: "DD/MM/YY",
              color: "#FE8D2A",
              dataPoints: totalBilling,
            },
            {
              type: "column",
              name: "Lucro planejado",
              showInLegend: true,
              xValueFormatString: "DD/MM/YY",
              color: "#031926",
              dataPoints: estimatedMargin,
            },
            {
              type: "column",
              name: "Lucro real",
              showInLegend: true,
              xValueFormatString: "DD/MM/YY",
              color: "#A5B452",
              dataPoints: totalMargin,
            },
          ];
        }
      } catch (error) {}
      if (mount) {
        setData(dData);
        setIsLoading(false);
      }
    }
    fetch();
    return () => {
      mount = false;
      signal.cancel();
    };
  }, [groupId, farmId, dataResult]);

  return (
    <Container>
      <Row type="flex">
        <Col span={24}>
          <Title>Vendas realizadas</Title>
        </Col>
      </Row>
      <Row type="flex" style={{ margintTop: 15 }}>
        <Col span={24}>
          <Spin spinning={isLoading}>
            {data.length === 0 ? (
              <Empty description="Nenhuma venda foi realizada neste cenário de venda." />
            ) : (
              <CanvasJSChart options={chartOptions} />
            )}
          </Spin>
        </Col>
      </Row>
    </Container>
  );
}

export default SaleFutureAndRealizedChart;
