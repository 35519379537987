import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as SaleScenarioActions } from "../../../../store/ducks/saleScenario";

// Components
import { Container, CardContainer } from "./styles";
import { Row, Col, Spin } from "antd";
import SaleSummaryChart from "./charts/saleSummaryChart";
import SaleFutureAndRealizedChart from "./charts/saleFutureAndRealizedChart";
import { numberMask } from "../../../../services/helpersMethodsService";
import InfoTooltip from "../../../../components/utils/infoTooltip";
import StarIcon from "../../../../components/utils/icons/star";

// services
import { getResultSumarySell } from "../../../../services/saleScenarioService";

const DrawerSaleScenarioDetails = ({ data, name }) => {
  const [dataChartSummary, setDataChartSummary] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // Redux Variable
  const { showDrawerDetailsParameter } = useSelector(
    (state) => state.saleScenario
  );
  const dispatch = useDispatch();

  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  // Methods
  function closeDrawer() {
    dispatch(SaleScenarioActions.showOrHideDrawerDetailsSaleScenario());
  }

  function calculateCost(y, x) {
    return numberMask(y - x || 0, true) || numberMask(0, true);
  }

  async function handleDrawerVisible(visible) {
    if (visible) {
      try {
        const {
          data: { results },
        } = await getResultSumarySell({
          groupId,
          farmId,
          saleScenarioId: data.saleScenarioId,
        });
        setDataChartSummary(results);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <Container
      title={
        <Row type="flex">
          <span
            style={{
              paddingRight: 10,
              borderRight: "2px solid #A5A5A5",
            }}
          >
            {name}
          </span>
          <span
            style={{
              paddingLeft: 10,
              fontWeight: "normal",
            }}
          >
            {dataChartSummary?.baseline ? (
              <>
                <StarIcon color="#FFD754" size="15" />
                <span
                  style={{
                    paddingLeft: 5,
                    paddingRight: 8,
                    fontWeight: "bold",
                    color: "#FFD754",
                  }}
                >
                  Resultado favorito:
                </span>
              </>
            ) : (
              <span
                style={{
                  paddingLeft: 5,
                  paddingRight: 8,
                  fontWeight: "bold",
                  color: "#4B4B4B",
                }}
              >
                Último Resultado:
              </span>
            )}
            {`${dataChartSummary?.identification}`}
          </span>
        </Row>
      }
      placement="top"
      height={450}
      onClose={closeDrawer}
      maskClosable={false}
      visible={showDrawerDetailsParameter}
      afterVisibleChange={handleDrawerVisible}
    >
      <div className="drawerForm">
        <Spin spinning={false}>
          <Row type="flex">
            <Col span={6}>
              <CardContainer>
                <Spin spinning={isLoading}>
                  <Row type="flex">
                    <span className="title">VENDAS REALIZADAS</span>
                  </Row>
                  <div className="container">
                    <Col span={12}>
                      <div className="purple">
                        <div className="flex">
                          <span>Animais vendidos</span>
                          <span>
                            {dataChartSummary?.amountAnimalsSold || 0}
                          </span>
                        </div>
                        <div className="flex">
                          <span>Total recebido</span>
                          <span>
                            {`${
                              dataChartSummary?.amountAnimalsSold === 0 ||
                              dataChartSummary?.amountAnimalsSold === null
                                ? numberMask(0, true)
                                : numberMask(
                                    dataChartSummary?.totalBilling || 0,
                                    true
                                  ) || numberMask(0)
                            }`}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="purple">
                        <div className="flex">
                          <span>Margem total</span>
                          <span>
                            {`${
                              dataChartSummary?.amountAnimalsSold === 0 ||
                              dataChartSummary?.amountAnimalsSold === null
                                ? numberMask(0, true)
                                : numberMask(
                                    dataChartSummary?.totalMargin || 0,
                                    true
                                  ) || numberMask(0, true)
                            }`}
                          </span>
                        </div>
                        <div className="flex">
                          <span>Custo acumulado</span>
                          <span>
                            {dataChartSummary?.amountAnimalsSold === 0 ||
                            dataChartSummary?.amountAnimalsSold === null
                              ? numberMask(0, true)
                              : calculateCost(
                                  dataChartSummary?.totalBilling,
                                  dataChartSummary?.totalMargin
                                )}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </div>
                </Spin>
              </CardContainer>
              <CardContainer>
                <Spin spinning={isLoading}>
                  <Row type="flex">
                    <span className="title">PLANEJAMENTO INICIAL</span>
                    <span className="info">
                      <InfoTooltip
                        placement="top"
                        trigger="click"
                        title="Valores referente ao resultado marcado como Favorito"
                      />
                    </span>
                  </Row>
                  <div className="container">
                    <Col span={12}>
                      <div className="bold">
                        <div className="flex">
                          <span>Animais à vender</span>
                          <span>
                            {dataChartSummary?.amountAnimalsEstimated || 0}
                          </span>
                        </div>
                        <div className="flex">
                          <span>Receita planejada</span>
                          <span>
                            {`${
                              numberMask(
                                dataChartSummary?.estimatedBilling || 0,
                                true
                              ) || numberMask(0, true)
                            }`}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="bold">
                        <div className="flex">
                          <span>Margem planejado</span>
                          <span>
                            {`${numberMask(
                              dataChartSummary?.estimatedMargin || 0,
                              true
                            )}`}
                          </span>
                        </div>
                        <div className="flex">
                          <span>Custo acum. planej.</span>
                          <span>
                            {calculateCost(
                              dataChartSummary?.estimatedBilling,
                              dataChartSummary?.estimatedMargin
                            )}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </div>
                </Spin>
              </CardContainer>
            </Col>
            <Col span={6}>
              <SaleSummaryChart dataResult={data} />
            </Col>
            <Col span={12}>
              <SaleFutureAndRealizedChart dataResult={data} />
            </Col>
          </Row>
        </Spin>
      </div>
    </Container>
  );
};

export default DrawerSaleScenarioDetails;
