import React from "react";
import { useGeneralFinancialParametersContext } from "../context";
import {
  FarmConvertionsContextProvider,
  useFarmConvertionsContext,
} from "./context";

import { Col, Dropdown, Icon, Menu, Row, Spin, Table, Typography } from "antd";
import CurrencyFlag from "react-currency-flags";
import ButtonStandard from "../../../../../components/utils/button";
import { FarmConvertionsContainer } from "./styles";

import MenuIcon from "../../../../../components/utils/table/icons/menu";
import { Container as MenuContainer } from "../../../../../components/utils/table/menu/styles";
import TagStatus from "../../../../../components/utils/tagStatus";
import FarmConvertionsForm from "./form";

function FarmConvertionsLayout() {
  const {
    isLoading,
    showForm,
    hideOrShowForm,
    farmCurrencies,
    inactivate,
    activate,
    favorite,
  } = useFarmConvertionsContext();

  const { translation, parameter4001Value } =
    useGeneralFinancialParametersContext();

  const menu = (id, record) => {
    return (
      <Menu>
        {record.status === "Active" && record.standard === false && (
          <Menu.Item key="2" onClick={() => favorite(id, record)}>
            Colocar como padrão
          </Menu.Item>
        )}
        {record.status === "Active" && (
          <Menu.Item key="2" onClick={() => inactivate(id, record)}>
            {translation.table.menu.inactivate}
          </Menu.Item>
        )}
        {record.status === "Inactive" && (
          <Menu.Item key="3" onClick={() => activate(id, record)}>
            {translation.table.menu.activate}
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <FarmConvertionsContainer
      isActive={parameter4001Value?.value === "true" ? true : false}
    >
      <Row type="flex" justify="space-between">
        <Col span={12}>
          <Typography.Title level={3}>
            {
              translation.financial.parameters.generalFinancialParameters
                .farmConvertions.title
            }
          </Typography.Title>
        </Col>
        <Col span={12}>
          <Row type="flex" justify="end" gutter={16}>
            <Col>
              <ButtonStandard
                disabled={showForm}
                buttonType="type8"
                onClick={hideOrShowForm}
              >
                {
                  translation.financial.parameters.generalFinancialParameters
                    .farmConvertions.buttonNew
                }
              </ButtonStandard>
            </Col>
          </Row>
        </Col>
      </Row>
      <Spin spinning={isLoading}>
        {showForm && (
          <Row style={{ margin: "1rem 0" }}>
            <FarmConvertionsForm />
          </Row>
        )}
        <Row>
          <Table
            rowKey="id"
            size="small"
            loading={isLoading}
            dataSource={farmCurrencies || []}
            scroll={{
              x: true,
            }}
            pagination={{
              hideOnSinglePage:
                farmCurrencies !== null && farmCurrencies.length > 10
                  ? false
                  : true,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
            }}
          >
            <Table.Column
              title={translation.supplement.table.columns.status}
              dataIndex="status"
              key="status"
              align="left"
              sorter={(first, second) => {
                return first.status.localeCompare(second.status, "pt-BR", {
                  numeric: false,
                  ignorePunctuation: true,
                });
              }}
              defaultFilteredValue={["Active"]}
              filtered
              filters={[
                {
                  text: translation.status.active,
                  value: "Active",
                },
                {
                  text: translation.status.inactive,
                  value: "Inactive",
                },
              ]}
              filterMultiple
              onFilter={(value, record) => record.status.includes(value)}
              render={(status) =>
                status === "Active" ? (
                  <TagStatus
                    background="#C5F1CA"
                    borderColor="#106518"
                    color="#106518"
                  >
                    {translation.status.active}
                  </TagStatus>
                ) : (
                  <TagStatus
                    background="#FBC7C7"
                    borderColor="#D44C4C"
                    color="#D44C4C"
                  >
                    {translation.status.inactive}
                  </TagStatus>
                )
              }
            />
            <Table.Column
              title="Moeda"
              dataIndex="currency"
              render={(text) => (
                <span>
                  <CurrencyFlag currency={text} size="sm" />
                  {` ${text} - ${translation.currency[text]}`}
                </span>
              )}
            />
            <Table.Column
              dataIndex="standard"
              align="center"
              width={90}
              title="Padrão?"
              render={(text) => text && <Icon type="check-circle" />}
            />
            <Table.Column
              title=""
              align="left"
              render={(text, record) => (
                <Dropdown
                  overlay={menu(record.id, record)}
                  trigger={["click"]}
                  key={record.id}
                >
                  <MenuContainer>
                    <MenuIcon />
                  </MenuContainer>
                </Dropdown>
              )}
            />
          </Table>
        </Row>
      </Spin>
    </FarmConvertionsContainer>
  );
}

function FarmConvertions() {
  return (
    <FarmConvertionsContextProvider>
      <FarmConvertionsLayout />
    </FarmConvertionsContextProvider>
  );
}

export default FarmConvertions;
