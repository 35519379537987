import apiAnimals from "../config/api_animals";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiAnimals = axios.create({
//   baseURL: "http://localhost:8084",
// });

export function getAllSuppliers(payload) {
  const {
    groupId,
    farmId,
    type,
    withoutPagination,
    page,
    sorter,
    filters,
    size,
    signal,
  } = payload;
  if (withoutPagination) {
    return apiAnimals.get(
      `/bovexo/${groupId}/farms/${farmId}/suppliers?${
        type ? `type=${type}` : ""
      }`,
      getHeaders(false, signal)
    );
  } else {
    const handleOrder = (order) => (order === "descend" ? "DESC" : "ASC");
    return apiAnimals.get(
      `/bovexo/${groupId}/farms/${farmId}/suppliers?
      ${page ? `&page=${page - 1}` : ""}
      ${size ? `&size=${size}` : ""}

      ${
        filters && filters !== "" ? `&search=${filters}` : ""
      }&withoutPagination=false
      ${
        sorter && Object.entries(sorter).length > 0
          ? `&sort=${sorter.field}&direction=${handleOrder(sorter.order)}`
          : ""
      }`,
      getHeaders(false, signal)
    );
  }
}

export function getAllSuppliersSpecificForFarm(payload) {
  const { groupId, farmId, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/suppliers/only/specifics`,
    getHeaders(false, signal)
  );
}

export function getSupplierShow(payload) {
  const { groupId, farmId, id, signal } = payload;
  if (farmId != null) {
    return apiAnimals.get(
      `/bovexo/${groupId}/farms/${farmId}/suppliers/${id}`,
      getHeaders(false, signal)
    );
  } else {
    return apiAnimals.get(
      `/bovexo/${groupId}/suppliers/${id}`,
      getHeaders(false, signal)
    );
  }
}

export function getSupplierByDocument(payload) {
  const { groupId, farmId, document, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/suppliers/document/${document}`,
    getHeaders(false, signal)
  );
}

export function saveSupplier(payload) {
  const { groupId, farmId, id, supplier } = payload;
  if (supplier?.farmId != null) {
    if (id != null) {
      return apiAnimals.patch(
        `/bovexo/${groupId}/farms/${farmId}/suppliers/${id}`,
        supplier,
        getHeaders(false, null)
      );
    } else {
      return apiAnimals.post(
        `/bovexo/${groupId}/farms/${farmId}/suppliers`,
        supplier,
        getHeaders(false, null)
      );
    }
  } else {
    if (id != null) {
      return apiAnimals.patch(
        `/bovexo/${groupId}/suppliers/${id}`,
        supplier,
        getHeaders(false, null)
      );
    } else {
      return apiAnimals.post(
        `/bovexo/${groupId}/suppliers`,
        supplier,
        getHeaders(false, null)
      );
    }
  }
}

export function doReactivateSupplier(payload) {
  const { groupId, farmId, id, signal = null } = payload;
  return apiAnimals.patch(
    `/bovexo/${groupId}/farms/${farmId}/suppliers/${id}/reactivate`,
    null,
    getHeaders(false, signal)
  );
}

export function hardDeleteSupplier(payload) {
  const { groupId, farmId, id, signal = null } = payload;
  return apiAnimals.delete(
    `/bovexo/${groupId}/farms/${farmId}/suppliers/${id}`,
    getHeaders(false, signal)
  );
}
