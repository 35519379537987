import React from "react";
import { useSelector } from "react-redux";
import { AnimalBirthContextProvider } from "../../contexts/animalBirthContext";
import useAnimalBirthContext from "../../hooks/useAnimalBirthContext";

import { Col, Icon, Row } from "antd";
import ButtonStandard from "../../components/utils/button";
import AnimalBirthForm from "./form";
import { CardCustom, Title } from "./styles";
import TableAnimalBirth from "./table";

// Service

function AnimalBirthLayout() {
  const { translation } = useSelector((state) => state.app);

  const {
    tableProperties,
    fetchData,
    showCardForm,
    openOrCloseForm,
    initializeData,
  } = useAnimalBirthContext();

  const handleCreateNewRecord = () => {
    openOrCloseForm();
    initializeData(null);
  };

  return (
    <CardCustom>
      <Row className="rowHeader" justify="space-between">
        <Col span={8}>
          <Title>{translation.animalBirth.title}</Title>
        </Col>
        <Col span={16}>
          <Row type="flex" justify="end" gutter={8}>
            <Col>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={() =>
                  fetchData(
                    tableProperties.page,
                    tableProperties.size,
                    tableProperties.tableSorters,
                    tableProperties.search
                  )
                }
              >
                <Icon type="reload" />
              </ButtonStandard>
              {!showCardForm && (
                <ButtonStandard
                  name="buttonCreateBudget"
                  buttonType="type8"
                  onClick={handleCreateNewRecord}
                >
                  {translation.animalBirth.buttonNew}
                </ButtonStandard>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {showCardForm && (
        <Row type="flex">
          <AnimalBirthForm />
        </Row>
      )}
      <Row type="flex">
        <Col span={24}>
          <TableAnimalBirth />
        </Col>
      </Row>
    </CardCustom>
  );
}

const AnimalBirth = () => {
  return (
    <AnimalBirthContextProvider>
      <AnimalBirthLayout />
    </AnimalBirthContextProvider>
  );
};

export default AnimalBirth;
