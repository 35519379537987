import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-width: 100%;
  height: 100%;
`;

export const NotificationContainerItem = styled.ul`
  border: none;
  background: transparent;
  border: 1px solid #e5e5e5;
  overflow-y: auto;
  font-family: Asap;
  font-size: 14px;
  color: #4b4b4b;
  border-bottom: 1px solid #e5e5e5;
  margin-top: 13px;
  margin-bottom: 16px;
`;

export const NotificationItem = styled.li`
  width: 100%;
  height: 100% !important;
  background: #f5f5f5;
  flex-direction: column !important;
  border-bottom: 1px solid #e5e5e5;
  padding: 12px 5px 12px 10px !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  font-family: Asap;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }

  &.read {
    background: #fff;
    cursor: not-allowed !important;
  }
  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:last-child {
    border-bottom: none;
  }
  &.footer {
    border: none;
    justify-content: center !important;
    align-items: center !important;
    text-transform: uppercase;
    border-top: 1px solid #e5e5e5;
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #684e94;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    a {
      color: #684e94;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 14px !important;

      &:hover {
        color: #684e94;
        text-decoration: underline;
      }
    }
  }

  .ant-row-flex {
    width: 100% !important;
  }
  p {
    margin: 0 !important;
  }
  div.icon {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span.time {
    font-family: Asap;
    font-weight: normal;
    font-size: 12px;
    margin-left: 10px;
    color: #707070;
  }

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const NotificationType = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 12px;
  text-align: left;
  color: ${props =>
    props.notificationType === "Sale"
      ? "#E86969"
      : props.notificationType === "Purchase"
      ? "#E86969"
      : props.notificationType === "Warning"
      ? "#684E94"
      : props.notificationType === "NewObject"
      ? "#4A85AE"
      : props.notificationType === "Alert"
      ? "#FE8D2A"
      : "#4b4b4b"};
`;

export const ButtonReadAllNotifications = styled.button`
  position: relative;
  font-family: Asap;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #684e94;
  background: transparent;
  border: none;

  span.slider {
    position: absolute;
    display: block;
    left: 0;
    top: 90%;
    margin: 0 auto;
    height: 2px;
    background-color: #684e94;
    width: 0%;
    transition: width 1s ease;
  }
  &:hover {
    cursor: pointer;

    span.slider {
      width: 100%;
    }
  }
  &:disabled {
    display: none;
  }

  svg {
    margin-right: 6px;
  }
`;
