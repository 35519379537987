import styled from "styled-components";
import { Card } from "antd";

export const CardCustom = styled(Card)`
  margin-top: 13px !important;
  margin-bottom: 5px !important;
  overflow-x: hidden;
  border-radius: 7px !important;
  background: #fff !important;
  box-shadow: 0px 0px 10px #d8d8d8 !important;
  overflow-y: hidden;
  padding: 0px !important;

  .ant-card-body {
    padding-top: 14px;
  }

  .rowHeader {
    margin-bottom: 17px;
  }

  .rowForm {
    margin-top: 13px;
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-right: 5px;
    &.error {
      color: #d44c4c;
      margin-right: 5px;
    }
  }

  .ant-select {
    width: 100%;
  }

  .ant-switch-checked {
    background-color: #a9c133 !important;
  }

  .display-label {
    font-weight: bold;
    display: block;
  }
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;

export const AlertButton = styled.button`
  margin-left: 5px;
  padding: 0px 5px;
  border-radius: 5px;
  height: 34px;
  background: #d44c4c;
  border: 1px solid #d44c4c;
  color: #ffffff;
  text-align: center;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }

  svg {
    margin-left: 5px;
  }
`;