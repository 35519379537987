import React from "react";
import { Result } from "antd";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import ButtonStandard from "../../components/utils/button";
import { Container } from "./styles";

const Error404 = props => {
  const { translation, user } = useSelector(state => state.app);
  const goToHome = () => {
    if(user.isInputSupplier){
      props.history.push("/supplier");
    } else {
      props.history.push("/admin");
    }
  };
  const icon = (
    <svg width="401.585" height="308.17" viewBox="0 0 401.585 308.17">
      <g
        id="Group_1275"
        data-name="Group 1275"
        transform="translate(-548 -281)"
      >
        <g
          id="Group_1274"
          data-name="Group 1274"
          transform="translate(548 281)"
        >
          <path
            id="Path_1081"
            data-name="Path 1081"
            d="M375.851,248.215c41.923-1.442,147.718-11.29,165.663,26.624,3.183,6.727-16.072,19.714-13.312,26.624,8.458,21.184,83.127,8.407,85.79,31.062,1.807,15.382-50.862,18.56-51.77,34.02-.707,12.052,36.687,18.992,36.978,31.062.328,13.6-35.684,24.052-39.937,36.978-2.47,7.51,10.267,24.46,5.917,31.062-16.044,24.348-84.787,23.4-113.893,25.145C420.876,492.622,350.467,501.525,330,478.96c-6.956-7.668,6-32.979,0-41.416-16.337-22.967-106.5-8.795-106.5-36.978,0-29.583,70.482-21.934,72.478-42.895,1.217-12.778-41.533-21.557-38.458-34.02,4.834-19.593,73.686.393,78.394-19.229,3.3-13.768-45.844-23.131-42.895-36.978C297.448,246.652,354.6,248.946,375.851,248.215Z"
            transform="translate(-223.5 -246.007)"
            fill="#f5f5f5"
          />
          <g
            id="Rectangle_499"
            data-name="Rectangle 499"
            transform="translate(192.771 187.839)"
            fill="#4b4b4b"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="2"
          >
            <rect width="11.833" height="116.852" stroke="none" />
            <rect x="-1" y="-1" width="13.833" height="118.852" fill="none" />
          </g>
          <circle
            id="Ellipse_170"
            data-name="Ellipse 170"
            cx="73.957"
            cy="73.957"
            r="73.957"
            transform="translate(124.987 45.843)"
            fill="#4b4b4b"
          />
          <line
            id="Line_321"
            data-name="Line 321"
            x1="106.498"
            transform="translate(295.087 306.17)"
            fill="none"
            stroke="#4b4b4b"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
          <line
            id="Line_322"
            data-name="Line 322"
            x1="181.934"
            transform="translate(99.842 306.17)"
            fill="none"
            stroke="#4b4b4b"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
          <line
            id="Line_323"
            data-name="Line 323"
            x1="32.541"
            transform="translate(51.03 306.17)"
            fill="none"
            stroke="#4b4b4b"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
          <line
            id="Line_324"
            data-name="Line 324"
            x1="32.541"
            transform="translate(11.094 306.17)"
            fill="none"
            stroke="#4b4b4b"
            strokeMiterlimit="10"
            strokeWidth="4"
          />
          <path
            id="Path_1082"
            data-name="Path 1082"
            d="M451.365,326.838a74.025,74.025,0,1,1-19.856-29.626"
            transform="translate(-183.096 -230.678)"
            fill="none"
            stroke="#c6de52"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="6"
          />
          <circle
            id="Ellipse_171"
            data-name="Ellipse 171"
            cx="57.686"
            cy="57.686"
            r="57.686"
            transform="translate(141.001 63.592)"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_1083"
            data-name="Path 1083"
            d="M424,370.271c5.917,1.479,10.354,10.354,11.833,13.312,1.479-5.917,5.917-13.312,10.354-13.312-4.437-1.479-10.354-10.354-11.833-16.27C432.875,359.917,428.437,368.791,424,370.271Z"
            transform="translate(-127.433 -194.264)"
            fill="#c6de52"
          />
          <path
            id="Path_1084"
            data-name="Path 1084"
            d="M307,269.881c1.972.444,3.451,3.106,3.945,3.994.493-1.775,1.972-3.994,3.451-3.994-1.479-.444-3.451-3.106-3.945-4.881C309.958,266.775,308.479,269.437,307,269.881Z"
            transform="translate(-183.492 -236.907)"
            fill="#c6de52"
          />
          <path
            id="Path_1085"
            data-name="Path 1085"
            d="M430.116,317.287c.688-3.056,4.813-5.347,6.189-6.112-2.75-.763-6.189-3.056-6.189-5.347-.686,2.291-4.812,5.347-7.563,6.112C425.3,312.7,429.43,315,430.116,317.287Z"
            transform="translate(-128.127 -217.345)"
            fill="#c6de52"
          />
          <ellipse
            id="Ellipse_172"
            data-name="Ellipse 172"
            cx="7.396"
            cy="5.917"
            rx="7.396"
            ry="5.917"
            transform="translate(118.331 221.12)"
            fill="#7ca8c4"
            stroke="#e9e9e9"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <ellipse
            id="Ellipse_173"
            data-name="Ellipse 173"
            cx="4.992"
            cy="4.437"
            rx="4.992"
            ry="4.437"
            transform="translate(46.038 129.414)"
            fill="#7ca8c4"
            stroke="#e9e9e9"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <ellipse
            id="Ellipse_174"
            data-name="Ellipse 174"
            cx="3.605"
            cy="3.698"
            rx="3.605"
            ry="3.698"
            transform="translate(263.471 219.641)"
            fill="#7ca8c4"
            stroke="#e9e9e9"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <ellipse
            id="Ellipse_175"
            data-name="Ellipse 175"
            cx="3.698"
            cy="3.605"
            rx="3.698"
            ry="3.605"
            transform="translate(371.122 242.177) rotate(-3.849)"
            fill="#7ca8c4"
            stroke="#e9e9e9"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <ellipse
            id="Ellipse_176"
            data-name="Ellipse 176"
            cx="3.698"
            cy="3.605"
            rx="3.698"
            ry="3.605"
            transform="translate(351.893 113.492) rotate(-3.849)"
            fill="#7ca8c4"
            stroke="#e9e9e9"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <ellipse
            id="Ellipse_177"
            data-name="Ellipse 177"
            cx="3.698"
            cy="3.605"
            rx="3.698"
            ry="3.605"
            transform="translate(59.024 41.014) rotate(-3.849)"
            fill="#7ca8c4"
            stroke="#e9e9e9"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <ellipse
            id="Ellipse_178"
            data-name="Ellipse 178"
            cx="4.345"
            cy="3.698"
            rx="4.345"
            ry="3.698"
            transform="translate(118.516 67.29)"
            fill="#7ca8c4"
            stroke="#e9e9e9"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <ellipse
            id="Ellipse_179"
            data-name="Ellipse 179"
            cx="5.824"
            cy="4.957"
            rx="5.824"
            ry="4.957"
            transform="translate(199.868 11.303)"
            fill="#7ca8c4"
            stroke="#e9e9e9"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <rect
            id="Rectangle_500"
            data-name="Rectangle 500"
            width="10.18"
            height="111.695"
            transform="translate(237.207 78.189) rotate(45)"
            fill="#fff"
          />
          <path
            id="Path_1086"
            data-name="Path 1086"
            d="M347.565,349.46c1.046-.311,2.781-.355,3.02-.976a41.966,41.966,0,0,0,1.466-4.039l-4.687,4.686Z"
            transform="translate(-164.152 -198.843)"
            fill="#fff"
          />
          <path
            id="Path_1087"
            data-name="Path 1087"
            d="M369.043,316.426c-4.967.365-9.912.62-14.858-.448-4.974-1.074-9.953-2.235-15.139-1.015-.993.232-2.248-.612-3.371-1a7.727,7.727,0,0,1-1.084-.566l-.4.436,2.948,3.429a5.121,5.121,0,0,1-.348.833c-1.4,2.279-2.868,4.517-4.2,6.835-.811,1.414-1.481,3-.3,4.534,1.1,1.433,2.621,1.306,4.233.929,1.889-.442,3.828-.669,5.739-1.027,2.228-.417,3.386.766,4.082,2.659.79,2.143,1.491,4.321,2.356,6.433.936,2.282,1.951,4.5,3.979,5.94l27.9-27.905A71.43,71.43,0,0,0,369.043,316.426Z"
            transform="translate(-171.679 -213.717)"
            fill="#fff"
          />
          <path
            id="Path_1088"
            data-name="Path 1088"
            d="M393.577,322.225c-.012-3.016-1.491-4.8-4.469-5.249a14.041,14.041,0,0,0-2.535-.071l-26.221,26.221c4.025.564,8.017.621,11.91-1.429,1.174-.62,1.96-.135,1.855,1.328a3.942,3.942,0,0,0,1.345,3.788,2.337,2.337,0,0,1,.095,2.205c-2.066,3.381-4.316,6.65-6.415,10.014-.323.516-.178,1.324-.249,2,1-.141,2.415.081,2.909-.492,1.461-1.692,2.476-3.76,3.818-5.569a30.961,30.961,0,0,1,3.917-4.516,2.585,2.585,0,0,0,.957-3.119c-.479-1.523-1.089-3.217.7-4.76,1.806,2.905,3.134,5.891,2.4,9.174a74.089,74.089,0,0,1-2.983,8.5c-.686,1.91-.185,2.556,1.8,2.134a2.322,2.322,0,0,0,1.438-1.163c1.331-3.056,2.667-6.119,3.764-9.264.741-2.126,1.645-4.424,1.5-6.582-.277-4.164,1-7.764,2.887-11.336-.136,4.193-.512,8.37-.723,12.556a26.736,26.736,0,0,0,.439,4.239l1.108.064a15.61,15.61,0,0,0,.93-3.461c.089-2.341-.157-4.692-.169-7.039C393.56,334.338,393.6,328.28,393.577,322.225Z"
            transform="translate(-157.929 -212.049)"
            fill="#fff"
          />
          <path
            id="Path_1089"
            data-name="Path 1089"
            d="M351.349,348.662c-.317.509.053,1.444.106,2.183.929-.164,2.232,0,2.723-.552,2.063-2.325,1.124-5.341,1.328-8.057.036-.466.04-.933.033-1.4L352.3,344.08A7.564,7.564,0,0,1,351.349,348.662Z"
            transform="translate(-162.304 -200.571)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
  const title = (
    <span>
      <strong>{`${translation.error404.title} - `}</strong>
      {translation.error404.subTitle}
    </span>
  );
  return (
    <Container>
      <Result
        title={title}
        icon={icon}
        extra={
          <ButtonStandard buttonType="type1" size="m" onClick={goToHome}>
            {translation.error404.button}
          </ButtonStandard>
        }
      />
    </Container>
  );
};

export default withRouter(Error404);
