import { useContext } from "react";
import { LotDetailsSupplementSupplyContext } from "../../contexts/lotDetailsSupplementSupplyContext";

// import { Container } from './styles';

function useLotDetailsSupplementSupplyContext() {
  const values = useContext(LotDetailsSupplementSupplyContext);
  return values;
}

export default useLotDetailsSupplementSupplyContext;
