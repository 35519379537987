import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// Components
import { Container, CardItem } from "./styles";
import { Col, Row, DatePicker } from "antd";
import moment from "moment";

import AlertIcon from "./icons/alert";
import KpiAlertIcon from "./icons/kpiAlert";
import RedCircleIcon from "./icons/redCircle";
import AnimalIcon from "../../../components/admin/sidebar/icons/animal";
import LotIcon from "../../../components/admin/sidebar/icons/lot";
import PicketIcon from "../../../components/admin/sidebar/icons/picket";
import DecisionIcon from "../../../components/admin/sidebar/icons/decision";
import { showAlertsByDateAndEntity } from "../../../services/alertService";
/** Redux libs */
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as AppActions } from "../../../store/ducks/app";

class DrawerAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterDate: moment(),
    };
  }

  getAlerts = async (date) => {
    this.setState({ alerts: [] });
    const { appActions } = this.props;
    appActions.setAlertSelected({});
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;
    const executionDate = date.format("YYYY-MM-DD");
    try {
      const {
        data: { results: alerts },
      } = await showAlertsByDateAndEntity({ groupId, farmId, executionDate, signal: this.signal });
      
      this.setState({ alerts, filterDate: date });
    } catch (error) {
      this.setState({ alerts: [], filterDate: date });
    }
  };

  handleAlertClick = (alert) => {
    const { appActions } = this.props;
    alert.alertPage = true;
    appActions.setAlertSelected(alert);

    switch (alert.entity) {
      case "animal":
        this.props.history.push("/admin/animals");
        break;
      case "lot":
        this.props.history.push("/admin/lots");
        break;
      case "picket":
        this.props.history.push("/admin/pickets");
        break;
      case "kpi":
        this.props.history.push("/admin/parameters");
        break;
      case "diet_strategy":
        this.props.history.push("/admin/managements");
        break; 
      case "sale_scenario":
        this.props.history.push("/admin/decisions");
        break;
      default:
        break;
    }
  };

  render() {
    const {
      visible,
      onCloseDrawer,
      data,
      app: { translation },
    } = this.props;
    const { alerts, filterDate } = this.state;

    const alertList = alerts == null ? data : alerts;

    const dateFormat = "DD/MM/YYYY";

    return (
      <Container
        title={"ALERTAS & PENDÊNCIAS"}
        width={511}
        onClose={onCloseDrawer}
        maskClosable={false}
        visible={visible}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
        <Row type="flex" style={{ marginLeft: "15px"}}>
          <DatePicker
            name="filterDate"
            allowClear={false}
            style={{ width: "120px" }}
            value={filterDate == null ? moment() : moment(filterDate, "YYYY-MM-DD") }
            placeholder={translation.defaultDatePickerPlaceholder}
            format={dateFormat}
            onChange={(date, dateString) => {
              if (date <= moment() && date !== null) {
                this.getAlerts(date);             }
            }}
          />
        </Row>
        {alertList?.length <= 0 ? (
          <CardItem >
            <div>
              <span>Nenhum alerta encontrado nessa data</span>
            </div>
          </CardItem>
          ) : (alertList?.map(alert => (
            <CardItem key={alert.id} onClick={() => this.handleAlertClick(alert)}>
            <div style={{width:"100%"}}>
              <Row type="flex">
              <Col span={2}>
                <Row type="flex" justify="center" style={alert.failed ? { marginTop: "5px"} : {}}>
                  {alert.entity === "animal" ? (<AnimalIcon />) :
                  alert.entity === "lot" ? (<LotIcon />) :
                  alert.entity === "kpi" ? (<KpiAlertIcon />) :
                  alert.entity === "picket" ? (<PicketIcon />) :
                  alert.entity === "sale_scenario" ? (<DecisionIcon />) :
                  (<AlertIcon />)}
                </Row>
                {alert.failed ? (
                  <Row type="flex" justify="center" style={{ marginTop: "5px"}}>
                    <RedCircleIcon className="failed"/>
                  </Row>
                ) : (null)}
               </Col>
               <Col span={22} >
               <span><div dangerouslySetInnerHTML={{__html: alert.message}} style={{ display: "inline"}}/></span>
               </Col>
               </Row>
            </div>
            {/* {!alert.checked ? (
              <span className="new">Novo</span>
            ) : (null)} */}
          </CardItem>)
        ))}        
        </Col>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DrawerAlert)
);
