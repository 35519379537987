import React, { useCallback, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import useMovementAnimalsContext from "../../../hooks/useMovementAnimalsContext";

import {
  AlertCard,
  Container,
  CustomDivider,
  Footer,
  InfoCardDashed,
  ButtonProperty,
} from "./styles";
import {
  Button,
  Col,
  DatePicker,
  Icon,
  Input,
  notification,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd";

import NoteIcon from "../../../components/utils/icons/note";
import InfoIcon from "../../../components/utils/icons/info";
import NumberFormat from "react-number-format";
import RadioGroup from "antd/lib/radio/group";
import RadioButton from "../../../components/utils/radioButton";
import ButtonStandard from "../../../components/utils/button";

// Services
import { getLotIndexDropDown } from "../../../services/lotService";
import { getBreedIndexActive } from "../../../services/breedService";
import { getListOfProfitCenterIndex } from "../../../services/profitCenterService";
import { getPicketIndex } from "../../../services/picketService";

// import { Container } from './styles';

const MovementAnimalsFormDrawer = () => {
  const formik = useRef();
  const [useExistingLot, setUseExistingLot] = useState(true);
  const [selectedLotRowKeys, setSelectedLotRowKeys] = useState([]);
  const [formLot, setFormLot] = useState({
    id: null,
    name: "",
    breedId: null,
    gender: "Male",
    cap: false,
    identification: null,
    asymptoticWeight: "",
    carcassHarnessing: "",
    supplierId: null,
    profitCenterId: null,
    dietStrategyBaseLine: null,
    dietStrategies: [],
    referenceAcquisitionDate: null,
    referenceAcquisitionWeight: "",
    referenceBirthdayDate: null,
    status: "Pending",
    averageAnimalStay: null,
  });
  const [error, setError] = useState(null);
  const [listLots, setListLots] = useState([]);
  const [isLoadingListLots, setIsLoadingListLots] = useState(false);
  const [listProfitCenters, setListProfitCenters] = useState([]);
  const [isLoadingListProfitCenters, setIsLoadingListProfitCenters] =
    useState(false);
  const [listPickets, setListPickets] = useState([]);
  const [isLoadingListPickets, setIsLoadingListPickets] = useState(false);
  const [listBreeds, setListBreeds] = useState([]);
  const [isLoadingListBreeds, setIsLoadingListBreeds] = useState(false);

  // Redux and Context
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const {
    selectedAnimalsKeys,
    animalsDismembered,
    isFormDrawerVisible,
    closeDrawer,
    updateAnimalsDismembered,
    addDestinationLot,
  } = useMovementAnimalsContext();

  const validationSchema = !useExistingLot
    ? Yup.object().shape({
        name: Yup.string().required(),
        profitCenterId: Yup.string().required(),
        breedId: Yup.string().required(),
        referenceAcquisitionDate: Yup.string().required(),
        referenceAcquisitionWeight: Yup.string().required(),
        referenceBirthdayDate: Yup.string().required(),
        dismembermentDate: Yup.string().required(),
      })
    : Yup.object().shape({
        dismembermentDate: Yup.string().required(),
      });

  const dateFormat = "DD/MM/YYYY";

  // Methods
  const getLots = async () => {
    setIsLoadingListLots(true);
    try {
      let {
        data: { results: lots },
      } = await getLotIndexDropDown({ groupId, farmId });
      setListLots(
        lots.filter(
          (lot) =>
            lot.status === "A" ||
            lot.status === "Active" ||
            lot.status === "P" ||
            lot.status === "Pending"
        )
      );
    } catch (error) {
    } finally {
      setIsLoadingListLots(false);
    }
  };

  const getBreeds = async () => {
    setIsLoadingListBreeds(true);
    try {
      const {
        data: { results: breeds },
      } = await getBreedIndexActive({
        groupId,
        farmId,
        withoutBreedCurveParam: true,
      });
      setListBreeds(breeds);
    } catch (error) {
    } finally {
      setIsLoadingListBreeds(false);
    }
  };

  const getProfitCenters = async () => {
    setIsLoadingListProfitCenters(true);
    try {
      const {
        data: { results: allProfitCenters },
      } = await getListOfProfitCenterIndex({ groupId, farmId });
      setListProfitCenters(
        allProfitCenters.filter((e) => e.status === "Active")
      );
    } catch (error) {
    } finally {
      setIsLoadingListProfitCenters(false);
    }
  };

  const getPickets = async (profitCenterId) => {
    let filters =
      profitCenterId !== null
        ? `profitCenterId='${profitCenterId}'&status='B'`
        : "";
    let page = 0;
    let sorter = {};
    let size = 10;
    let withoutPagination = true;

    setIsLoadingListPickets(true);
    try {
      const {
        data: { results: allPickets },
      } = await getPicketIndex({
        groupId,
        farmId,
        page,
        sorter,
        filters,
        size,
        withoutPagination,
      });
      setListPickets(allPickets);
    } catch (error) {
    } finally {
      setIsLoadingListPickets(false);
    }
  };

  const validateForm = (lot) => {
    if (useExistingLot && lot.id === null) {
      notification.error({
        message: "É necessário selecionar um lote.",
      });
      return false;
    } else {
      if (
        moment(lot.referenceAcquisitionDate).isBefore(lot.referenceBirthdayDate)
      ) {
        if (!useExistingLot) {
          setError("referenceBirthdayDate");
          return false;
        }
      }
      if (moment(lot.referenceAcquisitionDate).isAfter(lot.dismembermentDate)) {
        setError("dismembermentDate");
        return false;
      }
      if (
        lot.carcassHarnessing != null &&
        (lot.carcassHarnessing < 45 || lot.carcassHarnessing > 60)
      ) {
        if (!useExistingLot) {
          notification.error({
            message:
              'O valor do campo "Aproveitamento de Carcaça" deve estar entre o intervalo de valores (45% e 60%)',
          });
          return false;
        }
      }
    }

    return true;
  };

  const handleChangeAge = (e, type, props) => {
    let newDate = null;
    switch (type) {
      case "days":
        newDate = moment().subtract(e.target.value, "days");
        break;
      case "months":
        newDate = moment().subtract(e.target.value, "months");
        break;
      case "years":
        newDate = moment().subtract(e.target.value, "years");
        break;
      default:
        break;
    }

    props.setFieldValue("averageAnimalStay", moment().diff(newDate, "days"));
  };

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return record[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    }),
    [handleReset, handleSearch]
  );

  const handleCloseDrawer = () => {
    formik.current.resetForm({
      id: null,
      name: "",
      breedId: null,
      gender: "Male",
      cap: false,
      identification: null,
      asymptoticWeight: "",
      carcassHarnessing: "",
      supplierId: null,
      profitCenterId: null,
      dietStrategyBaseLine: null,
      dietStrategies: [],
      referenceAcquisitionDate: null,
      referenceAcquisitionWeight: "",
      referenceBirthdayDate: null,
      status: "Pending",
      averageAnimalStay: null,
    });
    setFormLot({
      id: null,
      name: "",
      breedId: null,
      gender: "Male",
      cap: false,
      identification: null,
      asymptoticWeight: "",
      carcassHarnessing: "",
      supplierId: null,
      profitCenterId: null,
      dietStrategyBaseLine: null,
      dietStrategies: [],
      referenceAcquisitionDate: null,
      referenceAcquisitionWeight: "",
      referenceBirthdayDate: null,
      status: "Pending",
      averageAnimalStay: null,
    });
    setSelectedLotRowKeys([]);
    setUseExistingLot(true);
    closeDrawer();
  };

  const handleSelectProfitCenter = (value, props) => {
    props.setFieldValue("profitCenterId", value);

    if (value !== null) {
      getPickets(value);
    } else {
      setListPickets([]);
    }
  };

  const handleSubmitModalForm = (values, actions) => {
    if (validateForm(values)) {
      const body = {
        ...values,
        key: uuid(),
        animals: selectedAnimalsKeys,
      };
      addDestinationLot(body);
      updateAnimalsDismembered([
        ...animalsDismembered,
        ...selectedAnimalsKeys.map((a) => a.animalId),
      ]);
      handleCloseDrawer();
    }
  };

  const handleDrawerVisible = async (visible) => {
    if (visible) {
      getLots();
      getBreeds();
      getProfitCenters();
    }
  };

  const handleSelectChange = (selectedRowKeys) => {
    const lotIndex = listLots.findIndex((l) => l.id === selectedRowKeys[0]);
    if (lotIndex >= 0) {
      const lot = listLots[lotIndex];
      formik.current.setFieldValue("id", lot.id);
      formik.current.setFieldValue("name", lot.name);
      setSelectedLotRowKeys(selectedRowKeys);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedLotRowKeys,
    onChange: handleSelectChange,
    type: "radio",
    style: { width: 10 },
  };

  return (
    <Container
      title={translation.movimentAnimals.form.title}
      width={770}
      onClose={handleCloseDrawer}
      maskClosable={false}
      visible={isFormDrawerVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Formik
        ref={formik}
        enableReinitialize={true}
        initialValues={formLot}
        onSubmit={handleSubmitModalForm}
        validationSchema={validationSchema}
        render={(props) => (
          <form onSubmit={props.handleSubmit} autoComplete="off">
            <div className="drawerForm">
              <Row type="flex">
                <p>
                  {
                    translation.lot.production.dismemberLotPage.drawer
                      .description
                  }
                </p>
              </Row>
              <Row type="flex">
                <ButtonProperty
                  shape="round"
                  type="button"
                  className={useExistingLot === false ? "selected" : ""}
                  onClick={() => setUseExistingLot(false)}
                >
                  {translation.lot.production.dismemberLotPage.drawer.newButton}
                </ButtonProperty>
                <ButtonProperty
                  shape="round"
                  type="button"
                  className={useExistingLot === true ? "selected" : ""}
                  onClick={() => setUseExistingLot(true)}
                >
                  {
                    translation.lot.production.dismemberLotPage.drawer
                      .existingButton
                  }
                </ButtonProperty>
              </Row>
              <CustomDivider dashed />
              {error !== null ? (
                <Row type="flex" justify="space-between" align="middle">
                  <AlertCard>
                    <Icon
                      type="exclamation-circle"
                      theme="filled"
                      style={{ color: "#d44c4c" }}
                      className="iconAlert"
                    />
                    <span>
                      {error === "referenceBirthdayDate" ? (
                        <>
                          A data de nascimento do animal não pode ser maior que{" "}
                          <br /> a data de aquisição
                        </>
                      ) : error === "dismembermentDate" ? (
                        <>
                          A data de desmembramento não pode ser menor que a data{" "}
                          <br /> de aquisição{" "}
                        </>
                      ) : null}
                    </span>
                  </AlertCard>
                </Row>
              ) : null}
              {/* Error message */}
              {Object.entries(props.errors).length > 0 &&
                props.submitCount > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
              {useExistingLot ? (
                <>
                  {/* dismembermentDate */}
                  <Row type="flex" justify="start" gutter={4}>
                    <Col span={8}>
                      <Row>
                        <label
                          className={
                            (props.errors.dismembermentDate &&
                              props.submitCount > 0) ||
                            error === "dismembermentDate"
                              ? "error"
                              : ""
                          }
                        >
                          Data de desmembramento *
                        </label>
                      </Row>
                      <Row>
                        <DatePicker
                          name="dismembermentDate"
                          allowClear={false}
                          value={
                            props.values.dismembermentDate != null
                              ? moment(
                                  props.values.dismembermentDate,
                                  "YYYY-MM-DD"
                                )
                              : null
                          }
                          placeholder={translation.defaultDatePickerPlaceholder}
                          format={dateFormat}
                          onChange={(date, dateString) => {
                            if (date <= moment() && date !== null) {
                              props.setValues({
                                ...props.values,
                                dismembermentDate: date,
                              });
                            }
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  {/* Table */}
                  <Row
                    type="flex"
                    style={{ marginTop: 10 }}
                    justify="space-between"
                    align="middle"
                  >
                    <Table
                      rowKey="id"
                      size="small"
                      style={{ width: "100%" }}
                      scroll={{ x: true }}
                      dataSource={listLots}
                      pagination={{
                        hideOnSinglePage:
                          listLots !== null && listLots.length > 10
                            ? false
                            : true,
                        showSizeChanger: true,
                        pageSizeOptions: [
                          "10",
                          "20",
                          "30",
                          "100",
                          "500",
                          "1000",
                          "2000",
                        ],
                      }}
                      rowSelection={rowSelection}
                      loading={isLoadingListLots}
                    >
                      <Table.Column
                        title={translation.lot.production.table.columns.lotName}
                        dataIndex="name"
                        key="name"
                        align="left"
                        sorter={(first, second) => {
                          return first.name.localeCompare(
                            second.name,
                            "pt-BR",
                            {
                              numeric: false,
                              ignorePunctuation: true,
                            }
                          );
                        }}
                        {...handleGetColumnSearchProps("name")}
                      />
                      <Table.Column
                        title={
                          translation.lot.production.table.columns.profitCenter
                        }
                        dataIndex="profitCenterName"
                        key="profitCenterName"
                        align="left"
                      />
                      <Table.Column
                        title={translation.lot.production.table.columns.amount}
                        sorter={(first, second) => {
                          return (
                            first.currentAmountAnimals -
                            second.currentAmountAnimals
                          );
                        }}
                        dataIndex="currentAmountAnimals"
                        key="currentAmountAnimals"
                        align="center"
                      />
                    </Table>
                  </Row>
                </>
              ) : (
                <>
                  {/* Create new lot */}
                  {/* Input lot identification */}
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.name && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.lot.production.drawer.form.name}
                        </label>
                      </Row>
                      <Row>
                        <Input
                          placeholder={
                            translation.lot.production.drawer.form.name
                          }
                          value={props.values.name}
                          name="name"
                          autoComplete="chrome-off"
                          onChange={(e) => {
                            props.setValues({
                              ...props.values,
                              name: e.target.value,
                            });
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  {/* Input profitCenterId */}
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.profitCenterId && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.lot.production.drawer.form.profitCenter}
                        </label>
                      </Row>
                      <Row>
                        <Select
                          name="profitCenterId"
                          value={
                            props.values.profitCenterId != null
                              ? props.values.profitCenterId
                              : undefined
                          }
                          loading={isLoadingListProfitCenters}
                          disabled={props.values.lotId != null}
                          placeholder={
                            translation.lot.production.drawer.form.profitCenter
                          }
                          onChange={(value) =>
                            handleSelectProfitCenter(value, props)
                          }
                          style={{ marginTop: 6 }}
                        >
                          {listProfitCenters != null &&
                          Object.entries(listProfitCenters).length > 0
                            ? listProfitCenters.map((pc) =>
                                pc.productionSubSystem !== null &&
                                pc.productionSubSystem !== "" ? (
                                  <Select.Option key={pc.id} value={pc.id}>
                                    {pc.name}
                                  </Select.Option>
                                ) : null
                              )
                            : null}
                        </Select>
                      </Row>
                    </Col>
                  </Row>

                  <CustomDivider dashed />

                  {/* Input média de permanência dos animais na fazenda */}
                  <Row type="flex" justify="start">
                    <Col span={20}>
                      <Row>
                        <label>
                          Média de permanência dos animais na fazenda
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Input.Group compact>
                          <Input
                            type="number"
                            style={{ width: 74, textAlign: "center" }}
                            name="ageDay"
                            min="0"
                            placeholder="Dias"
                            value={
                              props.values.averageAnimalStay != null
                                ? props.values.averageAnimalStay
                                : null
                            }
                            onChange={(e) => {
                              handleChangeAge(e, "days", props);
                            }}
                          />
                          <Input
                            style={{
                              width: 74,
                              textAlign: "center",
                              borderLeft: "1px solid #c4c4c4",
                            }}
                            type="number"
                            name="ageMonth"
                            min="0"
                            placeholder="Meses"
                            value={
                              props.values.averageAnimalStay != null
                                ? Math.round(
                                    moment
                                      .duration(
                                        props.values.averageAnimalStay,
                                        "days"
                                      )
                                      .asMonths()
                                  )
                                : null
                            }
                            onChange={(e) =>
                              handleChangeAge(e, "months", props)
                            }
                          />
                          <Input
                            style={{
                              width: 74,
                              textAlign: "center",
                              borderLeft: "1px solid #c4c4c4",
                            }}
                            type="number"
                            name="ageYear"
                            min="0"
                            placeholder="Anos"
                            value={
                              props.values.averageAnimalStay != null
                                ? Math.round(
                                    moment
                                      .duration(
                                        props.values.averageAnimalStay,
                                        "days"
                                      )
                                      .asYears()
                                  )
                                : null
                            }
                            onChange={(e) => handleChangeAge(e, "years", props)}
                          />
                        </Input.Group>
                      </Row>
                    </Col>
                  </Row>

                  <CustomDivider dashed />

                  {/* Input picketId */}
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label>
                          {translation.lot.production.drawer.form.picket}
                        </label>
                        <p>
                          {translation.lot.production.drawer.form.picketMessage}
                        </p>
                      </Row>
                      <Row>
                        <Select
                          name="picketId"
                          value={
                            props.values.picketId != null
                              ? props.values.picketId
                              : undefined
                          }
                          loading={isLoadingListPickets}
                          disabled={
                            props.values.profitCenterId == null ||
                            listPickets == null ||
                            (listPickets != null &&
                              Object.entries(listPickets).length === 0)
                          }
                          placeholder={
                            translation.lot.production.drawer.form
                              .picketPlaceHolder
                          }
                          onChange={(value) => {
                            props.setValues({
                              ...props.values,
                              picketId: value,
                            });
                          }}
                        >
                          {listPickets != null &&
                          Object.entries(listPickets).length > 0
                            ? listPickets.map((p) => (
                                <Select.Option key={p.id} value={p.id}>
                                  {p.name}
                                </Select.Option>
                              ))
                            : null}
                        </Select>
                      </Row>
                    </Col>
                  </Row>

                  <InfoCardDashed>
                    <NoteIcon />
                    As informações referentes à aquisição do lote como data de
                    nascimento, peso médio dos animais, data de aquisição, serão
                    mantidas do lote originário.
                  </InfoCardDashed>

                  {/* Input Peso assintótico */}
                  <Row type="flex" justify="start">
                    <Col span={12}>
                      <Row>
                        <label>
                          {
                            translation.lot.production.drawer.form
                              .asymptoticWeight
                          }
                          <Tooltip
                            title="Entende-se Peso assintótico, sendo o ideal para abate do boi.
O peso assintótico é definido pela raça de animais adicionados ao lote e pode ser alterado caso exija necessidade"
                          >
                            <InfoIcon />
                          </Tooltip>
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          value={props.values.asymptoticWeight}
                          placeholder={
                            translation.lot.production.drawer.form
                              .informPlaceHolder
                          }
                          name="asymptoticWeight"
                          addonAfter="Kg"
                          decimalScale={"2"}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue("asymptoticWeight", floatValue);
                          }}
                        />
                      </Row>
                    </Col>
                    {/* Input  Carcass Harnessing  */}
                    <Col span={11} offset={1}>
                      <Row>
                        <label>
                          {
                            translation.lot.production.drawer.form
                              .carcassHarnessing
                          }
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          value={props.values.carcassHarnessing}
                          placeholder={
                            translation.lot.production.drawer.form
                              .informPlaceHolder
                          }
                          name="carcassHarnessing"
                          addonAfter="%"
                          decimalScale={"2"}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue(
                              "carcassHarnessing",
                              floatValue
                            );
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <CustomDivider dashed>
                    DADOS DE REFERÊNCIA: AQUISIÇÃO DO LOTE
                  </CustomDivider>
                  {/* Input Raça, Gender and Cap */}
                  <Row type="flex" justify="start" gutter={4}>
                    {/* Breed */}
                    <Col span={props.values?.gender === "Male" ? 12 : 16}>
                      <Row>
                        <label
                          className={
                            props.errors.breedId && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          Raça*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          style={{ width: "100%" }}
                          name="breedId"
                          loading={isLoadingListBreeds}
                          placeholder={
                            translation.animal.edit.form.breedPlaceHolder
                          }
                          value={
                            listBreeds != null && props.values?.breedId != null
                              ? props.values?.breedId
                              : undefined
                          }
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => {
                            props.setValues({
                              ...props.values,
                              breedId: value,
                            });
                          }}
                        >
                          {listBreeds != null &&
                            Object.entries(listBreeds).length > 0 &&
                            listBreeds.map((breed) => (
                              <Select.Option key={breed.id} value={breed.id}>
                                {breed.name}
                              </Select.Option>
                            ))}
                        </Select>
                      </Row>
                    </Col>
                    {/* Gender */}
                    <Col span={props.values?.gender === "Male" ? 6 : 8}>
                      <Row>
                        <label
                          className={
                            props.errors.gender && props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.sex} *
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          style={{ width: "100%" }}
                          name="gender"
                          value={
                            props.values?.gender != null
                              ? props.values?.gender
                              : undefined
                          }
                          placeholder={translation.defaultSelectPlaceholder}
                          onChange={(value) => {
                            props.setValues({
                              ...props.values,
                              gender: value,
                            });
                          }}
                        >
                          <Select.Option key="Female" value="Female">
                            {translation.animal.edit.form.female}
                          </Select.Option>
                          <Select.Option key="Male" value="Male">
                            {translation.animal.edit.form.male}
                          </Select.Option>
                        </Select>
                      </Row>
                    </Col>
                    {/* Cap */}
                    {props.values?.gender === "Male" ? (
                      <Col span={6}>
                        <Row>
                          <label className="formLabel">
                            {translation.animal.edit.form.cap}
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <RadioGroup
                            name="cap"
                            value={props.values?.cap}
                            defaultValue={false}
                            onChange={(e) => {
                              props.setValues({
                                ...props.values,
                                cap: e.target.value,
                              });
                            }}
                          >
                            <RadioButton value={true}>
                              {translation.buttons.yes}
                            </RadioButton>
                            <RadioButton value={false}>
                              {translation.buttons.no}
                            </RadioButton>
                          </RadioGroup>
                        </Row>
                      </Col>
                    ) : null}
                  </Row>
                  {/* Input data de aquisição e Nascimento animal, Input data de desmembramento de lote */}
                  <Row type="flex" justify="start" gutter={4}>
                    <Col span={8}>
                      <Row>
                        <label
                          className={
                            props.errors.referenceAcquisitionDate &&
                            props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          Data de aquisição *
                        </label>
                      </Row>
                      <Row>
                        <DatePicker
                          name="referenceAcquisitionDate"
                          allowClear={false}
                          value={
                            props.values.referenceAcquisitionDate != null
                              ? moment(
                                  props.values.referenceAcquisitionDate,
                                  "YYYY-MM-DD"
                                )
                              : null
                          }
                          placeholder={translation.defaultDatePickerPlaceholder}
                          format={dateFormat}
                          onChange={(date, dateString) => {
                            if (date <= moment() && date !== null) {
                              props.setValues({
                                ...props.values,
                                referenceAcquisitionDate: date,
                              });
                            }
                          }}
                        />
                      </Row>
                    </Col>
                    <Col span={8}>
                      <Row>
                        <label
                          className={
                            (props.errors.referenceBirthdayDate &&
                              props.submitCount > 0) ||
                            error === "referenceBirthdayDate"
                              ? "error"
                              : ""
                          }
                        >
                          Nascimento animal *
                        </label>
                      </Row>
                      <Row>
                        <DatePicker
                          name="referenceBirthdayDate"
                          allowClear={false}
                          value={
                            props.values.referenceBirthdayDate != null
                              ? moment(
                                  props.values.referenceBirthdayDate,
                                  "YYYY-MM-DD"
                                )
                              : null
                          }
                          placeholder={translation.defaultDatePickerPlaceholder}
                          format={dateFormat}
                          onChange={(date, dateString) => {
                            if (date <= moment() && date !== null) {
                              props.setValues({
                                ...props.values,
                                referenceBirthdayDate: date,
                              });
                            }
                          }}
                        />
                      </Row>
                    </Col>
                    <Col span={8}>
                      <Row>
                        <label
                          className={
                            (props.errors.dismembermentDate &&
                              props.submitCount > 0) ||
                            error === "dismembermentDate"
                              ? "error"
                              : ""
                          }
                        >
                          Data de desmembramento *
                        </label>
                      </Row>
                      <Row>
                        <DatePicker
                          name="dismembermentDate"
                          allowClear={false}
                          value={
                            props.values.dismembermentDate != null
                              ? moment(
                                  props.values.dismembermentDate,
                                  "YYYY-MM-DD"
                                )
                              : null
                          }
                          placeholder={translation.defaultDatePickerPlaceholder}
                          format={dateFormat}
                          onChange={(date, dateString) => {
                            if (date <= moment() && date !== null) {
                              props.setValues({
                                ...props.values,
                                dismembermentDate: date,
                              });
                            }
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  {/* Input peso médio por animal */}
                  <Row type="flex" justify="start">
                    <Col span={12}>
                      <Row>
                        <label
                          className={
                            props.errors.referenceAcquisitionWeight &&
                            props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          Peso médio por animal *
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          value={props.values.referenceAcquisitionWeight}
                          placeholder={
                            translation.lot.production.drawer.form
                              .informPlaceHolder
                          }
                          name="referenceAcquisitionWeight"
                          addonAfter="Kg"
                          decimalScale={"2"}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue(
                              "referenceAcquisitionWeight",
                              floatValue
                            );
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
            </div>
            {/* Footer */}
            <Footer>
              <Row type="flex" justify="space-between">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className="buttonsDiv"
                >
                  <ButtonStandard
                    type="button"
                    buttonType="type7"
                    onClick={handleCloseDrawer}
                  >
                    {translation.buttons.cancel}
                  </ButtonStandard>

                  <ButtonStandard type="submit" buttonType="type6">
                    {translation.buttons.confirm}
                  </ButtonStandard>
                </Col>
              </Row>
            </Footer>
          </form>
        )}
      />
    </Container>
  );
};

export default MovementAnimalsFormDrawer;
