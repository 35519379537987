import React, { createContext, useCallback, useState } from "react";
import moment from "moment";

export const RegisterContext = createContext(null);

export const RegisterContextProvider = ({ children }) => {
  const [form, setForm] = useState({
    email: "",
    birthday: moment().format("YYYY-MM-DD"),
    password: null,
    fullname: "",
    document: "",
    phone: "",
    term: false,
    zip_code: "",
    address: "",
    address_number: "",
    neighborhood: "",
    country: null,
    state: null,
    city: null,
    emailVerified: false,
    isEmailValid: false,
    plan: {
      planSelected: null,
      amountAnimals: null,
    },
    card: {
      token: "",
    },
  });
  const [userId, setUserId] = useState(null);
  const [mode, setMode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [trialEnvironmentSet, setTrialEnvironmentSet] = useState(null);

  const goBackStep = useCallback(() => {
    setActiveStep(activeStep === 1 ? 1 : activeStep - 1);
  }, [activeStep]);

  const goNextStep = useCallback(() => {
    setActiveStep(activeStep + 1);
  }, [activeStep]);

  return (
    <RegisterContext.Provider
      value={{
        form,
        setForm,
        userId,
        setUserId,
        mode,
        setMode,
        isLoading,
        setIsLoading,
        activeStep,
        setActiveStep,
        trialEnvironmentSet,
        setTrialEnvironmentSet,
        goBackStep,
        goNextStep,
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
};
