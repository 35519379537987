import React, { useState, useCallback } from "react";

// libs
import { Row, Spin, Tooltip, Icon } from "antd";
import moment from "moment";

// Styles
import { Card } from "../../styles";

// Components
import useKpiContext from "../../../../hooks/useKpiContext";

//Services
import { numberMask } from "../../../../services/helpersMethodsService";

//Icons
import AlertIcon from "../../icons/alert";
import ChartIcon from "../../icons/chart";
import InfoIcon from "../../../../components/utils/icons/info";

const KpiCost = () => {
  const { kpiCost: kpiData, updateKpiSelected } = useKpiContext();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isTooltipErrorVisible, setIsTooltipErrorVisible] = useState(false);

  const handleShowTooltip = useCallback(() => {
    setIsTooltipVisible(true);
    setTimeout(() => {
      setIsTooltipVisible(false);
    }, 2000);
  }, []);

  const handleShowTooltipError = useCallback(() => {
    setIsTooltipErrorVisible(true);
    setTimeout(() => {
      setIsTooltipErrorVisible(false);
    }, 2000);
  }, []);

  return (
    <Card
      xs={23}
      sm={23}
      md={7}
      lg={7}
      xl={7}
      className={
        (kpiData?.data?.isComplete
          ? kpiData?.data?.goalAchieved
            ? " border-bottom-green"
            : " border-bottom-red"
          : "") +
        (kpiData.isSelected
          ? kpiData?.data?.isComplete
            ? kpiData?.data?.goalAchieved
              ? " border-green"
              : " border-red"
            : " border-yellow"
          : "")
      }
      onClick={() => (!kpiData.isLoading ? updateKpiSelected("CUSTO") : null)}
    >
      <Spin spinning={kpiData.isLoading} size="small">
        <div className="header">
          <span>
            <Tooltip
              visible={isTooltipErrorVisible}
              title={`Último processamento: ${
                kpiData.data?.kpiDate != null
                  ? moment(kpiData.data.kpiDate).format("DD/MM/YYYY")
                  : "Sem data"
              }`}
            >
              {kpiData.data.error ? (
                <Icon
                  type="exclamation-circle"
                  className="iconError"
                  theme="filled"
                  onClick={() => handleShowTooltipError()}
                />
              ) : null}
            </Tooltip>
            CUSTO
          </span>
          <span>
            {!kpiData?.data?.isComplete ? null : kpiData?.data?.goalAchieved ? (
              <ChartIcon />
            ) : (
              <AlertIcon />
            )}
          </span>
        </div>

        <div className="content">
          <Row type="flex">
            <Tooltip
              visible={isTooltipVisible}
              title="Custo Aquisição + Custo Adm/Operac. + Custo Nutrição dos animais ativos durante a safra."
            >
              <span className="span-font">
                <b>
                  {kpiData?.data?.kpiValue == null
                    ? "-"
                    : numberMask(
                        kpiData?.data?.kpiValue || 0,
                        kpiData?.data?.isMonetary
                      )}{" "}
                </b>
                {kpiData?.data?.valueType}
              </span>
            </Tooltip>
          </Row>
          <Row type="flex">
            <InfoIcon onClick={() => handleShowTooltip()} />
          </Row>
        </div>
        <div className="content-footer">
          <Tooltip
            placement="right"
            visible={isTooltipVisible}
            title="Custo Adm/Operac. + Custo de Nutrição dos animais ativos durante a safra."
          >
            <span>
              <b>
                {numberMask(
                  kpiData.data?.parameters?.FixedAndDietCostPerArroba || 0,
                  true
                )}{" "}
              </b>
              / @
            </span>
          </Tooltip>
        </div>
      </Spin>
      {!kpiData?.data?.isComplete ? <div className="banner-image" /> : null}
    </Card>
  );
};

export default KpiCost;
