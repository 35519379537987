import React from "react";
import { useState } from "react";
import { useCallback } from "react";
import { createContext } from "react";

const INITIAL_DASHBOARD_RESULT = {
  // Receita: ORCADO - REALIZADO - DIFERECA
  totalIncomeForeseen: 0,
  totalIncomeAccomplished: 0,
  difIncomeBetweenForeseenAndAccomplished: 0,

  // Despesa: ORCADO - REALIZADO - DIFERECA
  totalExpenseForeseen: 0,
  totalExpenseAccomplished: 0,
  difExpenseBetweenForeseenAndAccomplished: 0,

  // Fluxo de Caixa: ORCADO - REALIZADO - DIFERECA
  totalCashFlowForeseen: 0,
  totalCashFlowAccomplished: 0,
  difCashFlowBetweenForeseenAndAccomplished: 0,

  // Custos: ORCADO - REALIZADO - DIFERECA
  totalCostsForeseen: 0,
  totalCostsAccomplished: 0,
  difCostsBetweenForeseenAndAccomplished: 0,

  // Lucro: ORCADO - REALIZADO - DIFERECA
  totalProfitForeseen: 0,
  totalProfitAccomplished: 0,
  difProfitBetweenForeseenAndAccomplished: 0,

  // Saldo atual
  currentBalance: 0,
  // Valor vencido
  totalPastDueAmount: 0,
  // Valor pagamento parcial
  totalPartialPayment: 0,
  // Valor a vence
  totalValueToBeWon: 0,
  // Entradas previstas
  totalPredictedEntries: 0,
};
export const FinancialTransactionDashboardContext = createContext({
  dashboardResult: INITIAL_DASHBOARD_RESULT,
  isLoadingRequest: false,
  switchIsLoadingRequest: () => {},
  updateResults: (data) => {},
});

export function FinancialTransactionDashboardContextProvider({ children }) {
  const [dashboardResult, setDashboardResult] = useState(
    INITIAL_DASHBOARD_RESULT
  );
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  const switchIsLoadingRequest = useCallback(() =>
    setIsLoadingRequest((prevState) => !prevState)
  );

  const updateResults = useCallback((data) => {
    setDashboardResult(data);
  }, []);

  return (
    <FinancialTransactionDashboardContext.Provider
      value={{
        dashboardResult,
        isLoadingRequest,
        switchIsLoadingRequest,
        updateResults,
      }}
    >
      {children}
    </FinancialTransactionDashboardContext.Provider>
  );
}
