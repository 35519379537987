import styled from "styled-components";
import { Input, Divider, Radio } from "antd";

export const Container = styled.div`
  padding: 0px 10px 10px 10px;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .headerButtonsAndMessagens {
    margin-bottom: 15px;
  }

  .buttonsCol {
    button {
      &:first-of-type {
        margin-right: 19px;
      }
    }
  }
  .iconBack {
    color: #d44c4c;
    cursor: pointer;
  }
`;

export const HeadTitle = styled.h1`
  font-family: Asap;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  color: #4b4b4b;
  margin: 0;
`;

export const CustomDivider = styled(Divider)`
  margin: 20px 0 20px 0 !important;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 20px 0 20px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;

export const Body = styled.div`
  border-radius: 5px;
  background: #fcfcfc;
  box-shadow: 0px 3px 6px #d8d8d8;
  padding: 10px 20px;
  overflow-y: auto;

  .ant-select {
    width: 100%;
  }

  .buttonCalculationColumn {
    height: 63px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .colName {
    .ant-row:not(:first-of-type),
    .ant-row-flex:not(:first-of-type) {
      margin: 10px 0px 0px 0px;
    }

    .customStrong {
      font-size: 16px;
    }
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    margin-bottom: 7px;
    &.withoutMarginLeft {
      margin-left: 0px;
    }
    &.error {
      color: #d44c4c;
    }
  }
`;

export const Title = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  color: #4a85ae;
  text-transform: uppercase;
  &.chartTitle {
    color: #a5a5a5 !important;
  }
`;

export const CustomInput = styled(Input)`
  border-color: #684e94 !important;
  &.ant-input.error,
  &.ant-input.error:hover,
  &.ant-input.error:active {
    border-color: #d44c4c !important;
  }
  &.ant-input:hover,
  &.ant-input:active {
    border-color: #684e94 !important;
  }
  &.ant-input::-webkit-input-placeholder {
    /* Edge */
    color: #9074bf !important;
    &.error {
      color: #d44c4c !important;
    }
  }

  &.ant-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #9074bf !important;
    &.error {
      color: #d44c4c !important;
    }
  }

  &.ant-input::placeholder {
    color: #9074bf !important;
    &.error {
      color: #d44c4c !important;
    }
  }
`;
export const ChartContainer = styled.div`
  height: 310px;
  margin-top: 16px !important;
`;

export const RadioGroupCustom = styled(Radio.Group)`
  &.ant-radio-group {
    width: 100%;
  }
`;

export const RadioButtonCustom = styled(Radio.Button)`
  margin-top: 6px !important;
  border-radius: 0 15px 15px 0 !important;
  // background: transparent !important;

  .ant-radio-group-solid.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #ffff !important;
    border-color: #ffff !important;
  }

  span {
    font-family: Asap !important;
    font-weight: normal !important;
    font-size: 14px !important;
    text-align: left !important;
    color: #684e94 !important;
  }

  &:first-of-type {
    margin-left: 0px;
    border-radius: 15px 0 0 15px !important;
    width: 35%;
  }

  &:not(:first-of-type) {
    margin-left: 0;
  }

  &.ant-radio-button-wrapper-disabled {
    border-color: #c4c4c4 !important;
    background-color: #c4c4c4 !important;
    span {
      color: #c4c4c4 !important;
    }
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #684e94 !important;
    border-color: #684e94 !important;
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff;
    background: #684e94 !important;
    border-color: #684e94 !important;
  }

  &.ant-radio-button-wrapper-checked {
    &:first-child,
    &:not(:first-child) {
      border-color: #9074bf !important;
    }
    color: #fff !important;
    background: #9074bf !important;
    border-color: #9074bf !important;

    &.ant-radio-button-wrapper-disabled {
      border-color: #c4c4c4 !important;
      background: #c4c4c4 !important;
    }

    span {
      font-family: Asap !important;
      font-weight: normal !important;
      font-size: 14px !important;
      text-align: left !important;
      color: #fff !important;
    }
  }
  &:not(:first-child)::before {
    visibility: hidden !important;
  }

  &.ant-radio-button-wrapper:last-child {
    width: 65%;
  }
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-row-gap: 15px;
  grid-column-gap: 10px;

  & > div {
    width: 100%;
  }
`;
