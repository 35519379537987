import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import useBoitelSaleScnearioNewContext from "../../../../../hooks/useBoitelSaleScnearioNewContext";
/** Redux libs */
import { useSelector } from "react-redux";
/** Components */
import { Container } from "./styles";
import { List, Collapse, Table, Tag, Spin, Row, Icon } from "antd";
import StarIcon from "../../../../../components/utils/icons/star";
import ButtonStandard from "../../../../../components/utils/button";
/** Services */
const TabBoitelSaleScenarioNewSelectDietStragies = () => {
  /** Variables */
  const [loadingList, setLoadingList] = useState(true);
  const [dietStragiesSelectedKeys, setDietStratgySelectedKeys] = useState([]);
  /** Context */
  const {
    form: {
      parameter: { lots },
    },
    setForm,
    goPreviousTab,
  } = useBoitelSaleScnearioNewContext();
  /** Redux variables */
  const { translation } = useSelector((state) => state.app);

  useEffect(() => {
    setTimeout(() => setLoadingList(false), 500);
  }, []);

  useEffect(() => {
    let dsKeys = [];
    lots.forEach((ssl) => {
      if (ssl.dietStrategies.length > 0) {
        dsKeys.push(
          ...ssl.dietStrategies
            .filter((ds) => ds.check === true)
            .map((ds) => ds.newKey)
        );
      }
    });
    setDietStratgySelectedKeys(dsKeys);
  }, [lots]);

  /** Methods */
  const selectDietStrategy = useCallback(
    (record, selected, selectedRows, nativeEvent) => {
      if (selected) {
        let dietStrategy = record;
        if (dietStrategy != null) {
          dietStrategy = {
            ...dietStrategy,
            baseline: record.baseline,
            check: true,
          };
          setDietStratgySelectedKeys((oldDietStragiesSelectedKeys) => [
            ...oldDietStragiesSelectedKeys,
            record.id,
          ]);
          setForm((old) => ({
            ...old,
            parameter: {
              ...old.parameter,
              lots: old.parameter.lots.map((l) => {
                if (
                  l.dietStrategies.findIndex(
                    (ds) => ds.newKey === record.newKey
                  ) >= 0
                ) {
                  l.dietStrategies = l.dietStrategies.map((ds) =>
                    ds.newKey === record.newKey ? dietStrategy : ds
                  );
                }
                return l;
              }),
            },
          }));
        }
      } else {
        setDietStratgySelectedKeys((oldDietStragiesSelectedKeys) =>
          oldDietStragiesSelectedKeys.filter((r) => r !== record.newKey)
        );
        setForm((old) => ({
          ...old,
          parameter: {
            ...old.parameter,
            lots: old.parameter.lots.map((l) => {
              if (
                l.dietStrategies.findIndex(
                  (ds) => ds.newKey === record.newKey
                ) >= 0
              ) {
                l.dietStrategies = l.dietStrategies.map((ds) => {
                  if (ds.newKey === record.newKey) {
                    ds.check = false;
                  }
                  return ds;
                });
              }
              return l;
            }),
          },
        }));
      }
    },
    [setForm]
  );

  const rowSelection = {
    hideDefaultSelections: true,
    selectedRowKeys: dietStragiesSelectedKeys,
    onSelect: selectDietStrategy,
  };

  return (
    <Container>
      {/* Message and buttons */}
      <Row type="flex" style={{ marginBottom: 24 }} align="middle">
        <ButtonStandard buttonType="arrow" onClick={goPreviousTab}>
          <Icon type="arrow-left" className="iconBack" />
        </ButtonStandard>
        <strong className="subTitle">
          {translation.boitelSaleScenario.new.tabs.dietStrategy.title}
        </strong>
      </Row>
      <Spin spinning={loadingList}>
        <List
          itemLayout="horizontal"
          dataSource={lots}
          renderItem={(item) => (
            <List.Item>
              <Collapse
                defaultActiveKey={lots.map((x) => x.lot.id)}
                expandIconPosition="right"
              >
                <Collapse.Panel
                  header={`${item.lot.lotName} - Cliente: ${item.lot.supplierName}`}
                  key={item.lot.id}
                >
                  {item.lot.dietStrategies == null ||
                  item.lot.dietStrategies.length === 0 ? (
                    <Row type="flex" justify="center">
                      <span>
                        Este lote não possui estratégia de dieta, ou suas
                        estratégias estão vencidas.
                      </span>
                    </Row>
                  ) : (
                    <Table
                      key="recpt"
                      rowKey="newKey"
                      dataSource={
                        item.lot.dietStrategies != null
                          ? item.lot.dietStrategies
                          : []
                      }
                      pagination={false}
                      size="small"
                      rowSelection={rowSelection}
                    >
                      <Table.Column
                        title="Estratégia de dieta"
                        dataIndex="dietStrategyName"
                        key="dietStrategyName"
                        render={(text, record) => <span>{record.name}</span>}
                      />
                      <Table.Column
                        title="Período total"
                        key="totalPeriod"
                        dataIndex="totalPeriod"
                        render={(text, record) => (
                          <span>
                            {record != null
                              ? `De ${moment(
                                  record.dietPeriods[0].startDate
                                ).format("DD/MM/YYYY")} até ${moment(
                                  record.dietPeriods[
                                    record.dietPeriods.length - 1
                                  ].endDate
                                ).format("DD/MM/YYYY")} `
                              : ``}
                            {record != null
                              ? moment(
                                  record.dietPeriods[
                                    record.dietPeriods.length - 1
                                  ].endDate
                                ).isSameOrBefore(moment()) && (
                                  <Tag color="#d44c4c">Vencida</Tag>
                                )
                              : null}
                          </span>
                        )}
                      />
                      <Table.Column
                        title="Favorito"
                        dataIndex="baseline"
                        key="baseline"
                        align="center"
                        render={(baseline) =>
                          baseline === true && (
                            <StarIcon color="#FFD80D" size={24} />
                          )
                        }
                      />
                    </Table>
                  )}
                </Collapse.Panel>
              </Collapse>
            </List.Item>
          )}
        />
      </Spin>
    </Container>
  );
};

export default TabBoitelSaleScenarioNewSelectDietStragies;
