import apiFarms from "../config/api_farms";
import getHeaders from "./getHeaders";

export function getCarbonFootprintIndex(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFarms.get(
    `bovexo/${groupId}/farms/${farmId}/carbon/footprint/parameters`,
    getHeaders(false, signal)
  );
}

export function classifyPeriodCarbonFootPrint(
  carbonFootprintParameters,
  period
) {
  try {
    if (carbonFootprintParameters.length > 0) {
      let carbonFootprint = null;
      const SCENARIO_ONE = carbonFootprintParameters.find(
        (c) => c.name === "SCENARIO_ONE"
      );
      const SCENARIO_TWO = carbonFootprintParameters.find(
        (c) => c.name === "SCENARIO_TWO"
      );
      const SCENARIO_SIX = carbonFootprintParameters.find(
        (c) => c.name === "SCENARIO_SIX"
      );
      const SCENARIO_SEVEN = carbonFootprintParameters.find(
        (c) => c.name === "SCENARIO_SEVEN"
      );
      if (
        period?.pasture !== null &&
        period?.pastureFertilized === false &&
        period?.supplement === null
      ) {
        carbonFootprint = SCENARIO_ONE;
      } else if (
        period?.pasture !== null &&
        period?.pastureFertilized === true &&
        period?.supplement === null
      ) {
        carbonFootprint = SCENARIO_TWO;
      } else if (
        period?.supplement !== null &&
        period?.supplement.classification === "MineralProtein"
      ) {
        carbonFootprint = SCENARIO_SIX;
      } else if (
        period?.supplement !== null &&
        period?.supplement.classification === "MineralEnergyProtein"
      ) {
        carbonFootprint = SCENARIO_SEVEN;
      } else if (
        period?.pasture !== null &&
        period?.pastureFertilized === false &&
        period?.supplement !== null &&
        period?.supplement.classification === "Mineral"
      ) {
        carbonFootprint = SCENARIO_ONE;
      } else if (
        period?.pasture !== null &&
        period?.pastureFertilized === true &&
        period?.supplement !== null &&
        period?.supplement.classification === "Mineral"
      ) {
        carbonFootprint = SCENARIO_TWO;
      }
      return carbonFootprint;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}
