// import axios from "axios";
import apiFarms from "../config/api_farms";
import getHeaders from "./getHeaders";

// const apiFarms = axios.create({
//   baseURL: `http://localhost:8082`,
// });

export function findAllDropdown(payload) {
  const { groupId, farmId } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/reproduction/periods?withoutPagination=true`,
    getHeaders(false, null)
  );
}

export function findAll(payload) {
  const { groupId, farmId, page, sorter, filters, size } = payload;

  const orderS =
    sorter !== null && sorter?.order === "descend" ? "DESC" : "ASC";

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/reproduction/periods?
    ${page ? `&page=${page - 1}` : ""}
    ${size ? `&size=${size}` : ""}
    ${
      sorter !== null && Object.entries(sorter).length > 0
        ? `&sort=${sorter.field}&direction=${orderS}`
        : ""
    }
    ${filters !== "" ? `&search=${filters}` : ""}
    &withoutPagination=false`,
    getHeaders(false, null)
  );
}

export function findById(payload) {
  const { groupId, farmId, id } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/reproduction/periods/${id}`,
    getHeaders(false, null)
  );
}

export function reproductionPeriodExists(payload) {
  const { groupId, farmId, id, startDate, endDate } = payload;

  let params = `startDate=${startDate}&endDate=${endDate}`;
  if (id !== null) {
    params += `&periodId=${id}`;
  }

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/validate/reproduction/periods?${params}`,
    getHeaders(false, null)
  );
}

export function save(payload) {
  const { groupId, farmId, body } = payload;

  return apiFarms.post(
    `/bovexo/${groupId}/farms/${farmId}/reproduction/periods`,
    body,
    getHeaders(false, null)
  );
}

export function update(payload) {
  const { groupId, farmId, id, body } = payload;

  return apiFarms.patch(
    `/bovexo/${groupId}/farms/${farmId}/reproduction/periods/${id}`,
    body,
    getHeaders(false, null)
  );
}

export function deleteById(payload) {
  const { groupId, farmId, id } = payload;

  return apiFarms.delete(
    `/bovexo/${groupId}/farms/${farmId}/reproduction/periods/${id}`,
    getHeaders(false, null)
  );
}
