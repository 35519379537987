import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import { connect } from "react-redux";
import { Collapse, Row, Col, Table, List } from "antd";
import { Creators as ImportLogActions } from "../../../../store/ducks/import";
import { Container, HeaderContainer, Title } from "./styles";
import ArrowActiveIcon from "./icons/arrow/active";
import ArrowInactiveIcon from "./icons/arrow/inactive";
import BreadCrumbs from "../../../../components/utils/breadCrumbs";
import Axios from "axios";

import { getImportLog } from "../../../../services/importService";

class ImportAnimalWeightDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      importLogData: null,
    };
  }

  signal = Axios.CancelToken.source();

  componentWillUnmount() {
    this.signal.cancel("Cancelando apis");
  }

  async componentDidMount() {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;

    if (
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      const { id: groupId } = groupSelected;
      const { id: farmId } = farmSelected;
      const { id: importLogId } = this.props.match.params;
      await this.getImportLogInfo(groupId, farmId, importLogId);
    }
  }

  getImportLogInfo = async (groupId, farmId, id) => {
    this.setState({ isLoading: true });
    try {
      const {
        data: { results: importLogData },
      } = await getImportLog({ groupId, farmId, id, signal: this.signal });
      this.setState({ importLogData });
      let importedData = importLogData.weights
        .filter((weight) => weight.success)
        .sort(compare);
      let notImportedData = importLogData.weights
        .filter((weight) => !weight.success)
        .sort(compare);
      this.setState({
        importItensData: [
          {
            imported: true,
            total: importLogData.numberImported,
            data: importedData,
          },
          {
            imported: false,
            total: importLogData.numberError + importLogData.numberDuplicated,
            data: notImportedData,
          },
        ],
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        importLogData: null,
      });
    }
  };

  render() {
    const Panel = Collapse.Panel;
    const Column = Table.Column;

    const {
      app: { translation },
    } = this.props;

    const { importLogData, importItensData, isLoading } = this.state;

    const customPanelStyle = {
      borderRadius: 7,
      background: "#fff",
      boxShadow: "0px 0px 10px #d8d8d8",
      marginBottom: 15,
    };
    const Header = (imported, amountAnimals) => (
      <HeaderContainer>
        <div className="title">
          <span>{imported ? "Importados" : "Não Importados"}</span>
        </div>
        <div className="amountAnimals">
          <span>{`${amountAnimals} ${translation.imports.weight.details.collapseAnimals}`}</span>
        </div>
      </HeaderContainer>
    );
    return (
      <Container>
        <BreadCrumbs marginBottom="16px">
          <Link to="/admin/imports?tabSelect=2">
            <span className="pageTreeInative">
              {translation.imports.weight.details.breadCumbsImportLog}
            </span>
          </Link>
          <span className="pageTreeActive">
            {translation.imports.weight.details.breadCumbsImportLogDetails}
          </span>
        </BreadCrumbs>
        <Row
          type="flex"
          justify="space-between"
          style={{ marginBottom: "19px" }}
        >
          <Col>
            <Title>{translation.imports.weight.details.statusTitle}: </Title>
            {importLogData != null
              ? importLogData.status === "Processed"
                ? translation.imports.status.processed
                : importLogData.status === "Queue"
                ? translation.imports.status.queue
                : importLogData.status === "Processing"
                ? translation.imports.status.processing
                : importLogData.status === "Error"
                ? translation.imports.status.error
                : null
              : null}
          </Col>
          <Col>
            <Title>{translation.imports.weight.details.fileNameTitle}: </Title>
            {importLogData != null ? importLogData.fileName : null}
          </Col>
          <Col>
            <Title>{translation.imports.weight.details.dateTitle}: </Title>
            {importLogData != null ? importLogData.date : null}
          </Col>
        </Row>
        <List
          itemLayout="vertical"
          loading={isLoading}
          size="large"
          dataSource={importItensData != null ? importItensData : []}
          renderItem={(item) => (
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) =>
                isActive ? <ArrowActiveIcon /> : <ArrowInactiveIcon />
              }
            >
              <Panel
                header={Header(item.imported, item.total)}
                key={item.id}
                style={customPanelStyle}
              >
                <Row type="flex" justify="start">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Table
                      rowKey="id"
                      dataSource={item.data}
                      pagination={true}
                      size="small"
                    >
                      <Column
                        title={
                          translation.imports.weight.details.table.columns
                            .rowNumber
                        }
                        dataIndex="rowNumber"
                        key="rowNumber"
                        align="left"
                      />
                      <Column
                        title={
                          translation.imports.weight.details.table.columns
                            .handlingNumber
                        }
                        dataIndex="handlingNumber"
                        key="handlingNumber"
                        align="left"
                      />
                      <Column
                        title={
                          translation.imports.weight.details.table.columns
                            .weight
                        }
                        dataIndex="weight"
                        key="weight"
                        align="left"
                      />
                      {!item.imported ? (
                        <Column
                          title={
                            translation.imports.weight.details.table.columns
                              .message
                          }
                          align="left"
                          render={(text, record) =>
                            record.errors != null ? (
                              <ol>
                                {record.errors.map((e) => (
                                  <li>{e}</li>
                                ))}
                              </ol>
                            ) : null
                          }
                        />
                      ) : null}
                    </Table>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          )}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  importLog: state.importLog,
});

const mapDispatchToProps = (dispatch) => ({
  importLogActions: bindActionCreators(ImportLogActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ImportAnimalWeightDetails)
);

function compare(a, b) {
  let comparison = 0;
  if (a.rowNumber > b.rowNumber) {
    comparison = 1;
  } else if (a.rowNumber < b.rowNumber) {
    comparison = -1;
  }
  return comparison;
}
