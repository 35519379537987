import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

/* Redux Actions */
import { Creators as AppActions } from "../../../../store/ducks/app";
import { Creators as GeneralParametersActions } from "../../../../store/ducks/generalParameters";

import { Container, Table, CustomSelect, Footer } from "./styles";
import { Empty, Row, Col, Select, Spin } from "antd";
import ButtonStandard from "../../../../components/utils/button";
import DrawerWeatherSeasonsParameter from "../../../../components/drawers/weatherSeasonsParameter";

const EditWeatherSeason = ({ data, onSave, onCancel }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [seasonSelected, setSeasonSelected] = useState(null);
  const [typeSeasonSelected, setTypeSeasonSelected] = useState(null);
  /** Redux variables */
  const {
    translation,
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { loadingListWeatherSeason, listWeatherSeasons } = useSelector(
    (state) => state.generalParameters
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (data !== null && seasonSelected === null) {
      setSeasonSelected(data);
      setTypeSeasonSelected(data.farmSelectedTypeSeason);
    } else if (
      data == null &&
      listWeatherSeasons.length > 0 &&
      seasonSelected === null
    ) {
      setSeasonSelected(listWeatherSeasons[0]);
      setTypeSeasonSelected("Default");
    }
  }, [data, listWeatherSeasons, seasonSelected]);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        dispatch(GeneralParametersActions.listWeatherSeasons(farmId));
      } catch (error) {
        setSeasonSelected(null);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [farmId, dispatch]);

  /** MEMO */
  const seasonsOfSelectWeatherSeason = useMemo(() => {
    const handleGetClimateByMonthAndList = (month, list) => {
      let climate = "";
      try {
        const season =
          list.find((s) => s.month === month) != null
            ? list.find((s) => s.month === month).season
            : null;
        climate =
          season === "Water"
            ? "Água"
            : season === "Dry"
            ? "Seca"
            : season === "Transition"
            ? "Transição"
            : null;
        return climate;
      } catch (error) {
        console.error(error);
        return null;
      }
    };
    const handleGetAmountDaysToNextSeasonByMonthAndList = (month) => {
      try {
        const list =
          typeSeasonSelected === "Default"
            ? seasonSelected?.seasonsDefault
            : typeSeasonSelected === "Drier"
            ? seasonSelected?.seasonsDrier
            : typeSeasonSelected === "Wetter"
            ? seasonSelected?.seasonsWetter
            : [];
        const amountDaysForNextSeason =
          list.find((s) => s.month === month) != null
            ? list.find((s) => s.month === month).amountDaysForNextSeason
            : 0;
        return amountDaysForNextSeason;
      } catch (error) {
        console.error(error);
        return 0;
      }
    };

    if (seasonSelected != null && typeSeasonSelected != null) {
      let seasons = [];
      seasons = seasonSelected?.seasonsDefault.map((ss) => {
        const object = {
          id: ss.id,
          month: ss.month,
          amountDaysForNextSeason: handleGetAmountDaysToNextSeasonByMonthAndList(
            ss.month
          ),
          monthDays: ss.monthDays,
          season: ss.season,
          seasonDrier: handleGetClimateByMonthAndList(
            ss.month,
            seasonSelected?.seasonsDrier
          ),
          seasonWetter: handleGetClimateByMonthAndList(
            ss.month,
            seasonSelected?.seasonsWetter
          ),
        };
        return object;
      });
      return seasons;
    } else {
      return [];
    }
  }, [seasonSelected, typeSeasonSelected]);

  /** METHODS */
  const openDrawer = () => {
    dispatch(AppActions.showDrawerWeatherSeasonsParameterVisible());
  };

  const handleChangeSelectSeason = (value) => {
    setIsLoading(true);
    setSeasonSelected(listWeatherSeasons.find((ws) => ws.id === value));
    if (typeSeasonSelected != null) {
      const weatherSeason = listWeatherSeasons.find((ws) => ws.id === value);
      setTypeSeasonSelected(
        typeSeasonSelected === "Drier" && weatherSeason.seasonsDrier.length > 0
          ? "Drier"
          : typeSeasonSelected === "Wetter" &&
            weatherSeason.seasonsWetter.length > 0
          ? "Wetter"
          : "Default"
      );
    }
    setTimeout(() => setIsLoading(false), 500);
  };

  return (
    <Container>
      <Row type="flex" justify="space-between">
        <Col xs={16} sm={16} md={16} lg={16} xl={16}>
          <p>{translation.generalParameters.weatherSeason.editMessage}</p>
        </Col>

        <Col xs={8} sm={8} md={8} lg={8} xl={8} align="right">
          <ButtonStandard type="button" buttonType="type8" onClick={openDrawer}>
            Nova estação
          </ButtonStandard>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={16}>
          <Row>
            <strong>Selecione a sua estação:</strong>
          </Row>
          <Row>
            <CustomSelect
              name="seasonSelected"
              loading={loadingListWeatherSeason}
              placeholder={translation.defaultSelectPlaceholder}
              style={{ width: "100%" }}
              value={
                seasonSelected != null && listWeatherSeasons.length > 0
                  ? seasonSelected?.id
                  : undefined
              }
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => handleChangeSelectSeason(value)}
            >
              {listWeatherSeasons != null &&
                Object.entries(listWeatherSeasons).length > 0 &&
                listWeatherSeasons.map((ws) => (
                  <Select.Option key={ws.id} value={ws.id}>
                    {ws.name}
                  </Select.Option>
                ))}
            </CustomSelect>
          </Row>
        </Col>
        <Col span={7} offset={1}>
          <Row>
            <strong>Selecione o tipo da estação:</strong>
          </Row>
          <Row>
            <CustomSelect
              style={{ width: "110px" }}
              loading={loadingListWeatherSeason}
              value={
                typeSeasonSelected != null && listWeatherSeasons.length > 0
                  ? typeSeasonSelected
                  : undefined
              }
              placeholder={translation.defaultSelectPlaceholder}
              name="typeSeasonSelected"
              onChange={(value) => setTypeSeasonSelected(value)}
            >
              <Select.Option
                key="1"
                value="Default"
                disabled={
                  seasonSelected?.seasonsDefault.length > 0 ? false : true
                }
              >
                Padrão
              </Select.Option>
              <Select.Option
                key="2"
                value="Drier"
                disabled={
                  seasonSelected?.seasonsDrier.length > 0 ? false : true
                }
              >
                + Seco
              </Select.Option>
              <Select.Option
                key="3"
                value="Wetter"
                disabled={
                  seasonSelected?.seasonsWetter.length > 0 ? false : true
                }
              >
                + Úmido
              </Select.Option>
            </CustomSelect>
          </Row>
        </Col>
      </Row>
      <Row type="flex" style={{ marginTop: 10 }}></Row>
      <Spin spinning={isLoading}>
        {seasonSelected != null ? (
          <Table>
            <thead>
              <tr>
                <th />
                <th>{`${translation.generalParameters.weatherSeason.table.columnDayMonth}*`}</th>
                <th>{`${translation.generalParameters.weatherSeason.table.columnNextSeason}`}</th>
                <th
                  className={typeSeasonSelected === "Default" ? "selected" : ""}
                >{`PADRÃO`}</th>
                <th
                  className={typeSeasonSelected === "Drier" ? "selected" : ""}
                >{`+ SECO`}</th>
                <th
                  className={typeSeasonSelected === "Wetter" ? "selected" : ""}
                >{`+ ÚMIDO`}</th>
              </tr>
            </thead>
            <tbody>
              {seasonsOfSelectWeatherSeason.map((ws) => (
                <tr key={ws.id}>
                  <td>
                    {`${ws.month}. `}
                    <strong>
                      {ws.month === 1
                        ? translation.months.january
                        : ws.month === 2
                        ? translation.months.february
                        : ws.month === 3
                        ? translation.months.march
                        : ws.month === 4
                        ? translation.months.april
                        : ws.month === 5
                        ? translation.months.may
                        : ws.month === 6
                        ? translation.months.june
                        : ws.month === 7
                        ? translation.months.july
                        : ws.month === 8
                        ? translation.months.august
                        : ws.month === 9
                        ? translation.months.september
                        : ws.month === 10
                        ? translation.months.october
                        : ws.month === 11
                        ? translation.months.november
                        : ws.month === 12
                        ? translation.months.december
                        : null}
                    </strong>
                  </td>
                  <td>{ws.monthDays} Dias</td>
                  <td>{ws.amountDaysForNextSeason} Dias</td>
                  <td>
                    {ws.season === "Water"
                      ? "Água"
                      : ws.season === "Dry"
                      ? "Seca"
                      : ws.season === "Transition" && "Transição"}
                  </td>
                  <td>{ws.seasonDrier}</td>
                  <td>{ws.seasonWetter}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Empty />
        )}
      </Spin>
      <Footer>
        <Row type="flex" justify="space-between">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="selectDiv"
          ></Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="buttonsDiv">
            <ButtonStandard
              type="button"
              buttonType="typeWithoutBackground"
              color="#d44c4c"
              isUpperCase={true}
              onClick={onCancel}
            >
              {translation.buttons.cancel}
            </ButtonStandard>

            <ButtonStandard
              type="submit"
              buttonType="typeWithoutBackground"
              color="#684E94"
              isUpperCase={true}
              onClick={() => onSave(seasonSelected, typeSeasonSelected)}
            >
              {translation.buttons.save}
            </ButtonStandard>
          </Col>
        </Row>
      </Footer>
      <DrawerWeatherSeasonsParameter />
    </Container>
  );
};

export default EditWeatherSeason;
