import React from 'react';

// import { Container } from './styles';

const LogoFull = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="114.937"
    height="20.201"
    viewBox="0 0 114.937 20.201"
  >
    <g id="Logo-horizontal" transform="translate(-23.883 -15.824)">
      <path
        id="Path_328"
        data-name="Path 328"
        d="M16.3,3.336,14-.029c-.031-.031-.092,0-.061.031.184.8.826,3.885.948,4.772.153,1.04-.642,1.009-.642,1.009H11.74a.217.217,0,0,0-.214.245l.765,3.885a2.394,2.394,0,0,0-1.285.979,3.351,3.351,0,0,1-1.071.734c-.031,0-.031.061,0,.061a4.994,4.994,0,0,0,1.927.184l-1.9,6.148H7.335L5.408,11.9a4.994,4.994,0,0,0,1.927-.184c.031,0,.031-.061,0-.061a3.718,3.718,0,0,1-1.071-.734c-.367-.428-.8-.918-1.285-.979l.765-3.885a.2.2,0,0,0-.214-.245H3.022s-.8.061-.642-1.009C2.5,3.886,3.145.828,3.328.032,3.328,0,3.267-.029,3.267,0L.942,3.336S-.556,5.416.3,6.915a4.16,4.16,0,0,0,2.447,1.56s.979.184.765.948a1.382,1.382,0,0,1-.428.734.03.03,0,0,0-.031.031l1.5,4.374L5.837,18.2a.276.276,0,0,1,.031.153v.826a.332.332,0,0,0,.122.275l.489.551a.425.425,0,0,0,.306.153h3.671a.376.376,0,0,0,.306-.153l.489-.551a.55.55,0,0,0,.122-.275v-.826A.276.276,0,0,1,11.4,18.2l1.285-3.64,1.5-4.374c0-.031,0-.031-.031-.031a1.236,1.236,0,0,1-.428-.734c-.214-.765.765-.948.765-.948a4,4,0,0,0,2.447-1.56C17.8,5.416,16.3,3.336,16.3,3.336Z"
        transform="translate(23.847 15.866)"
        fill="#a9c133"
      />
      <path
        id="Path_329"
        data-name="Path 329"
        d="M87.881,11.862c.765-.336,2.294-1.346,2.141-4.038-.275-4.282-3.579-4.863-4.1-4.925H74.3V20.855H85.74a1.041,1.041,0,0,0,.306-.031c.765-.122,3.732-.887,3.976-4.955A3.761,3.761,0,0,0,87.881,11.862Zm-2.386,7.1H76.2V4.827h9.482a2.066,2.066,0,0,1,.428.061A3.123,3.123,0,0,1,88.217,7.7,3.7,3.7,0,0,1,86.5,10.883a.435.435,0,0,1-.184.061H82.712v1.9h3.7l.122.031a3.481,3.481,0,0,1,1.682,3.151,3.064,3.064,0,0,1-1.9,2.753A1.625,1.625,0,0,1,85.5,18.959Z"
        transform="translate(-27.701 13.824)"
        fill="#a9c133"
      />
      <path
        id="Path_330"
        data-name="Path 330"
        d="M148.283,24.691v-.673h0v-1.9c.031-2.478-1.071-3.732-2.355-4.282a9.507,9.507,0,0,0-3.487-.459,11.032,11.032,0,0,0-3.487.459c-1.285.551-2.386,1.774-2.355,4.282v1.9h0v.673h0v1.9c-.031,2.478,1.071,3.732,2.355,4.282a9.507,9.507,0,0,0,3.487.459,11.033,11.033,0,0,0,3.487-.459c1.285-.551,2.386-1.774,2.355-4.282v-1.9Zm-2.049,2.416a2.356,2.356,0,0,1-2.355,2.355h-2.814a2.356,2.356,0,0,1-2.355-2.355v-5.72a2.356,2.356,0,0,1,2.355-2.355h2.814a2.356,2.356,0,0,1,2.355,2.355Z"
        transform="translate(-70.944 3.778)"
        fill="#a9c133"
      />
      <path
        id="Path_331"
        data-name="Path 331"
        d="M188.982,18.1l-3.7,11.837L181.611,18.1H179.5l4.16,13.336h3.273l4.16-13.336Z"
        transform="translate(-100.723 3.273)"
        fill="#a9c133"
      />
      <path
        id="Path_332"
        data-name="Path 332"
        d="M288.023,31.436,283.282,24.4l4.252-6.3h-2.111l-3.212,4.741L279,18.1h-2.111l4.282,6.3L276.4,31.436h2.111l3.7-5.475,3.7,5.475Z"
        transform="translate(-167.984 3.273)"
        fill="#684e94"
      />
      <path
        id="Path_333"
        data-name="Path 333"
        d="M226.827,2.9H224.9V20.886h13.673V18.959H226.827V4.827h11.746V2.9Z"
        transform="translate(-132.236 13.824)"
        fill="#684e94"
      />
      <rect
        id="Rectangle_21"
        data-name="Rectangle 21"
        width="8.932"
        height="1.896"
        transform="translate(97.374 25.197)"
        fill="#684e94"
      />
      <path
        id="Path_334"
        data-name="Path 334"
        d="M325.729,17.3a6.729,6.729,0,1,0,6.729,6.729A6.729,6.729,0,0,0,325.729,17.3Zm0,12.082a5.353,5.353,0,1,1,5.353-5.353A5.339,5.339,0,0,1,325.729,29.382Z"
        transform="translate(-197.553 3.828)"
        fill="#684e94"
      />
      <path
        id="Path_335"
        data-name="Path 335"
        d="M365.8,17.1a1.94,1.94,0,0,1,.887.245,1.587,1.587,0,0,1,.673.673,1.73,1.73,0,0,1,0,1.774,1.587,1.587,0,0,1-.673.673,1.73,1.73,0,0,1-1.774,0,1.586,1.586,0,0,1-.673-.673,1.73,1.73,0,0,1,0-1.774,1.776,1.776,0,0,1,.673-.673A1.626,1.626,0,0,1,365.8,17.1Zm0,.275a1.628,1.628,0,0,0-.734.184,1.2,1.2,0,0,0-.551.551,1.691,1.691,0,0,0-.214.765,1.283,1.283,0,0,0,.214.734,1.2,1.2,0,0,0,.551.551,1.679,1.679,0,0,0,.734.214,1.283,1.283,0,0,0,.734-.214,1.2,1.2,0,0,0,.551-.551,1.63,1.63,0,0,0,.183-.734,1.38,1.38,0,0,0-.214-.765,1.2,1.2,0,0,0-.551-.551A1.421,1.421,0,0,0,365.8,17.375Zm-.8,2.508V17.956h.673a1.732,1.732,0,0,1,.489.061.476.476,0,0,1,.245.184.493.493,0,0,1,.092.275.506.506,0,0,1-.153.367.8.8,0,0,1-.4.184.668.668,0,0,1,.153.092,1.106,1.106,0,0,1,.275.4l.245.367h-.367l-.184-.306a1.243,1.243,0,0,0-.336-.459.576.576,0,0,0-.245-.061h-.184v.826Zm.306-1.1h.367a.531.531,0,0,0,.367-.092.278.278,0,0,0,.092-.214.234.234,0,0,0-.061-.153c-.031-.031-.061-.092-.122-.092a.767.767,0,0,0-.306-.031h-.367v.581Z"
        transform="translate(-228.789 3.967)"
        fill="#684e94"
      />
    </g>
  </svg>
);
export default LogoFull;
