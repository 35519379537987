import React, { createContext, useCallback, useEffect, useState } from "react";
import ConfinementMapSuppliersModal from "../../components/modals/confinementMapSuppliersModal";
import ConfinementMapParametersModal from "../../components/modals/confinementMapParametersModal";
import { useSelector } from "react-redux";
import {
  getParameterItems,
  saveOrUpdateParameter,
} from "../../services/generalParameterService";
import axios from "axios";
import { notification } from "antd";

// import { Container } from './styles';

const CODE_PARAMETER_MAP_GOOD = 3006;
const CODE_PARAMETER_MAP_MEDIUM = 3007;
const CODE_PARAMETER_MAP_BAD = 3008;

export const ConfinementMapDashboardContext = createContext({
  isModalSuppliersVisible: false,
  showOrHideModalSuppliers: (suppliersData) => {},
  isModalParametersVisible: false,
  showOrHideModalParameters: (suppliersData) => {},
  suppliersData: [],
  confinementMapParameters: [],
  updateConfinementParameters: (newParameters) => {},
});

export const ConfinementMapDashboardContextProvider = ({ children }) => {
  const [isModalSuppliersVisible, setIsModalSuppliersVisible] = useState(false);
  const [isModalParametersVisible, setIsModalParametersVisible] =
    useState(false);
  const [suppliersData, setSuppliersData] = useState([]);
  const [confinementMapParameters, setConfinementMapParameters] = useState([
    { min: 1, max: 75, color: "green" },
    { min: 76, max: 100, color: "yellow" },
    { min: 101, max: null, color: "red" },
  ]);

  let signal = axios.CancelToken.source();

  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const showOrHideModalSuppliers = useCallback((suppliersData = null) => {
    setIsModalSuppliersVisible((prevState) => !prevState);
    setSuppliersData(suppliersData ? suppliersData : []);
  }, []);

  const showOrHideModalParameters = useCallback(() => {
    setIsModalParametersVisible((prevState) => !prevState);
  }, []);

  const updateConfinementParameters = useCallback(async (newParameters) => {
    try {
      const {
        data: { results: parameterGood },
      } = await saveOrUpdateParameter({
        groupId,
        farmId,
        parameterItemId: newParameters[0].parameterItemId,
        parameterId: newParameters[0].parameterId,
        edit: newParameters[0].isAlreadyCreated,
        body: {
          id: newParameters[0].parameterItemId,
          value: newParameters[0].min.toString(),
          valueExtra: newParameters[0].max.toString(),
        },
      });
      const {
        data: { results: parameterMedium },
      } = await saveOrUpdateParameter({
        groupId,
        farmId,
        parameterItemId: newParameters[1].parameterItemId,
        parameterId: newParameters[1].parameterId,
        edit: newParameters[1].isAlreadyCreated,
        body: {
          id: newParameters[1].parameterItemId,
          value: newParameters[1].min.toString(),
          valueExtra: newParameters[1].max.toString(),
        },
      });
      const {
        data: { results: parameterBad },
      } = await saveOrUpdateParameter({
        groupId,
        farmId,
        parameterItemId: newParameters[2].parameterItemId,
        parameterId: newParameters[2].parameterId,
        edit: newParameters[2].isAlreadyCreated,
        body: {
          id: newParameters[2].parameterItemId,
          value: newParameters[2].min.toString(),
          valueExtra: null,
        },
      });

      setConfinementMapParameters([
        {
          parameterId: newParameters[0].parameterId,
          parameterItemId: parameterGood.id,
          min: Number(parameterGood.value),
          max: Number(parameterGood.valueExtra),
          isAlreadyCreated: parameterGood.farmId ? true : false,
          color: "green",
        },
        {
          parameterId: newParameters[1].parameterId,
          parameterItemId: parameterMedium.id,
          min: Number(parameterMedium.value),
          max: Number(parameterMedium.valueExtra),
          isAlreadyCreated: parameterMedium.farmId ? true : false,
          color: "yellow",
        },
        {
          parameterId: newParameters[2].parameterId,
          parameterItemId: parameterBad.id,
          min: Number(parameterBad.value),
          max: null,
          isAlreadyCreated: parameterBad.farmId ? true : false,
          color: "red",
        },
      ]);
      notification.success({
        message: "Parâmetros atualizados com sucesso.",
      });
    } catch (error) {
      notification.success({
        message: "Houve um erro ao atualizar os parâmetros. Contate o suporte.",
      });
    }
  }, []);

  useEffect(() => {
    async function getParameters() {
      try {
        const {
          data: { results: parameterGood },
        } = await getParameterItems({
          groupId,
          farmId,
          code: CODE_PARAMETER_MAP_GOOD,
          signal,
        });
        const {
          data: { results: parameterMedium },
        } = await getParameterItems({
          groupId,
          farmId,
          code: CODE_PARAMETER_MAP_MEDIUM,
          signal,
        });
        const {
          data: { results: parameterBad },
        } = await getParameterItems({
          groupId,
          farmId,
          code: CODE_PARAMETER_MAP_BAD,
          signal,
        });

        setConfinementMapParameters([
          {
            parameterId: parameterGood.parameterId,
            parameterItemId: parameterGood.id,
            min: Number(parameterGood.value),
            max: Number(parameterGood.valueExtra),
            isAlreadyCreated: parameterGood.farmId ? true : false,
            color: "green",
          },
          {
            parameterId: parameterMedium.parameterId,
            parameterItemId: parameterMedium.id,
            min: Number(parameterMedium.value),
            max: Number(parameterMedium.valueExtra),
            isAlreadyCreated: parameterMedium.farmId ? true : false,
            color: "yellow",
          },
          {
            parameterId: parameterBad.parameterId,
            parameterItemId: parameterBad.id,
            min: Number(parameterBad.value),
            max: null,
            isAlreadyCreated: parameterBad.farmId ? true : false,
            color: "red",
          },
        ]);
      } catch (error) {}
    }
    getParameters();

    return () => {
      signal.cancel();
    };
  }, [groupId, farmId]);

  return (
    <ConfinementMapDashboardContext.Provider
      value={{
        isModalSuppliersVisible,
        showOrHideModalSuppliers,
        suppliersData,
        confinementMapParameters,
        isModalParametersVisible,
        showOrHideModalParameters,
        updateConfinementParameters,
      }}
    >
      {children}
      <ConfinementMapSuppliersModal />
      <ConfinementMapParametersModal />
    </ConfinementMapDashboardContext.Provider>
  );
};
