import styled from "styled-components";
import { Input, Drawer } from "antd";

export const Container = styled.div`
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;

  .rowLabel {
    margin-bottom: 7px;
    margin-top: 19px;
  }

  label.formLabel {
    font-family: Asap !important;
    color: #9074bf !important;
    font-size: 14px !important;
    font-weight: bold !important;
    margin-left: 15px !important;

    &.disabled {
      font-weight: bold !important;
      font-size: 12px !important;
      color: #c4c4c4 !important;
    }
    &.error {
      color: #d44c4c !important;
    }
  }
`;

export const CustomDrawerAllocateLot = styled(Drawer)`
  .ant-drawer-body {
    padding-bottom: 0px !important;
    padding-top: 10px !important;
  }
`;

export const Body = styled.div`
  max-height: 700px;
  overflow-y: auto;
  .message {
    font-family: Asap;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
    color: #4b4b4b;
  }

  .ant-row,
  .ant-row-flex {
    margin-bottom: 10px !important;

    &:last-child {
      margin-bottom: 0px !important;
    }
  }
`;

export const Title = styled.span`
  font-family: "Asap";
  font-weight: bold;
  font-style: italic;
  font-size: 16px;
  text-align: left;
  color: #4b4b4b;

  .picketName {
    font-family: "Asap", sans-serif;
    font-weight: normal;
    font-style: italic;
    font-size: 16px;
    text-align: left;
    color: #4b4b4b;
  }

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const SubTitle = styled.span`
  margin-left: 7px;
  border-left: ${props =>
    props.borderLeft
      ? `4px solid ${props.borderLeft}`
      : "4px solid #fe8d2a"} !important;
  padding-left: 2px;
  font-family: "Asap", sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  color: ${props => (props.color ? props.color : "#4b4b4b")} !important;

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const SearchInput = styled(Input)`
  background: transparent !important;
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 1px solid #c4c4c4 !important;
  .ant-input {
    border-radius: 0 !important;
    border: none !important;
    padding-left: 38px !important;
    &:hover {
      box-shadow: none !important;
    }

    &:focus {
      box-shadow: none !important;
    }
  }
`;

export const AlertCard = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: transparent;
  border: 1px dashed #d44c4c;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;

  .iconAlert {
    position: absolute;
    left: 0;
    margin-left: 10px;
  }

  span {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #d44c4c;
  }
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  width: 100%;
  padding: 10px 24px;
  border-radius: 0 0 4px 4px;

  .buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding-right: 24px; */

    button:first-of-type {
      margin-right: 12px !important;
    }
  }

  .selectDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: "Asap", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;
