import { useContext } from "react";
import { SemenContext } from "../../contexts/semenContext";

const useSemenContext = () => {
  const value = useContext(SemenContext);

  return value;
};

export default useSemenContext;
