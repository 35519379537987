import { useContext } from "react";
import { InseminatorContext } from "../../contexts/inseminatorContext";

const useInseminatorContext = () => {
  const value = useContext(InseminatorContext);

  return value;
};

export default useInseminatorContext;
