import React, { useState, useCallback } from "react";

// libs
import { Row, Spin, Tooltip, Col, Icon } from "antd";
import moment from "moment";

// Styles
import { Card } from "../../styles";

// Components
import useKpiContext from "../../../../hooks/useKpiContext";

//Services
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../../services/helpersMethodsService";

//Icons
import AlertIcon from "../../icons/alert";
import ChartIcon from "../../icons/chart";
import InfoIcon from "../../../../components/utils/icons/info";

const KpiReproductiveEfficiency = () => {
  const { kpiReproductiveEfficiency: kpiData, updateKpiSelected } =
    useKpiContext();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isTooltipErrorVisible, setIsTooltipErrorVisible] = useState(false);

  const handleShowTooltip = useCallback(() => {
    setIsTooltipVisible(true);
    setTimeout(() => {
      setIsTooltipVisible(false);
    }, 2000);
  }, []);

  const handleShowTooltipError = useCallback(() => {
    setIsTooltipErrorVisible(true);
    setTimeout(() => {
      setIsTooltipErrorVisible(false);
    }, 2000);
  }, []);

  return (
    <Card
      xs={23}
      sm={23}
      md={7}
      lg={7}
      xl={7}
      className={
        (kpiData?.data.isComplete
          ? kpiData?.data.goalAchieved
            ? " border-bottom-green"
            : " border-bottom-red"
          : "") +
        (kpiData.isSelected
          ? kpiData?.data.isComplete
            ? kpiData?.data.goalAchieved
              ? " border-green"
              : " border-red"
            : " border-yellow"
          : "")
      }
      onClick={() =>
        !kpiData.isLoading ? updateKpiSelected("EFICIENCIA REPRODUTIVA") : null
      }
    >
      <Spin spinning={kpiData.isLoading} size="small">
        {kpiData?.data?.startDate && kpiData?.data?.endDate && (
          <div className="period-header">
            <span>
              {moment(kpiData?.data?.startDate, "YYYY-MM-DD").format(
                "DD/MM/YYYY"
              )}{" "}
              a{" "}
              {moment(kpiData?.data?.endDate, "YYYY-MM-DD").format(
                "DD/MM/YYYY"
              )}
            </span>
          </div>
        )}

        <div className="header">
          <span>
            <Tooltip visible={isTooltipErrorVisible} title={`Último processamento: ${kpiData.data?.kpiDate != null ? moment(kpiData.data.kpiDate).format("DD/MM/YYYY") : "Sem data"}`}>
              {kpiData.data.error ? (
                <Icon
                  type="exclamation-circle"
                  className="iconError"
                  theme="filled"
                  onClick={() => handleShowTooltipError()} />
              ) : null}
            </Tooltip>
            Eficiência Reprodutiva
          </span>
          <span>
            {!kpiData?.data?.isComplete ? null : kpiData?.data?.goalAchieved ? (
              <ChartIcon />
            ) : (
              <AlertIcon />
            )}
          </span>
        </div>

        <div className="content">
          <Row type="flex">
            <Tooltip
              visible={isTooltipVisible}
              title="EF = TP x TN x TD"
              placement="left"
            >
              <span className="span-font">
                <b>
                  {kpiData?.data?.kpiValue === null
                    ? "-"
                    : numberMask(
                      Number(kpiData?.data?.kpiValue * 100) || 0,
                      kpiData?.data?.isMonetary
                    )}{" "}
                </b>
                {kpiData?.data?.valueType}
              </span>
            </Tooltip>
          </Row>
          <Row type="flex">
            <InfoIcon onClick={() => handleShowTooltip()} />
          </Row>
        </div>

        <div className="content-footer">
          <Tooltip
            visible={isTooltipVisible}
            title={
              <div>
                <p>
                  <strong>TS: </strong>Cobertas/Aptas
                </p>
                <p>
                  <strong>TC: </strong>Prenhas/Cobertas
                </p>
                <p>
                  <strong>TP: </strong>Prenhas/Aptas
                </p>
                <p>
                  <strong>TN: </strong>Nascimentos/Prenhas
                </p>
                <p>
                  <strong>TD: </strong>Desmame/Nascimentos
                </p>
              </div>
            }
            placement="bottom"
            style={{ width: "100%" }}
          >
            <Row type="flex" style={{ width: "100%" }} justify="space-around">
              <Col span={8}>
                <span>{`TS: ${numberMask(
                  kpiData.data?.parameters?.ServiceRate * 100 || 0,
                  false
                )} %`}</span>
              </Col>
              <Col span={8}>
                <span>{`TC: ${numberMask(
                  kpiData.data?.parameters?.ConceptionRate * 100 || 0,
                  false
                )} %`}</span>
              </Col>
              <Col span={8}>
                <span>{`TP: ${numberMask(
                  kpiData.data?.parameters?.PregnancyRate * 100 || 0,
                  false
                )} %`}</span>
              </Col>
            </Row>
            <Row type="flex" justify="center"></Row>
            <Row type="flex" style={{ width: "100%" }} justify="space-around">
              <Col span={8}>
                <span>{`TN: ${numberMask(
                  kpiData.data?.parameters?.BirthRate * 100 || 0,
                  false
                )} %`}</span>
              </Col>
              <Col span={8}>
                <span>{`TD: ${numberMask(
                  kpiData.data?.parameters?.WeaningRate * 100 || 0,
                  false
                )} %`}</span>
              </Col>
            </Row>
          </Tooltip>
        </div>
      </Spin>
      {!kpiData?.data.isComplete ? <div className="banner-image" /> : null}
    </Card>
  );
};

export default KpiReproductiveEfficiency;
