import React, { memo, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { AnimalFattenContextProvider, useAnimalFattenContext } from "./context";

import { AnimalFattenTable } from "./table";

import { Col, Icon, Row, Select } from "antd";

import ButtonStandard from "../../../components/utils/button";

// Services

const AnimalFattenLayout = memo(() => {
  const {
    pagination: { page, size, tableFilters, tableSorters },
  } = useSelector((state) => state.animal);

  const { identificationSelected, handleChangeIdentification, fetchData } =
    useAnimalFattenContext();

  const handleRefresh = () => {
    fetchData(page, tableSorters, tableFilters, size, null);
  };

  useLayoutEffect(() => {
    fetchData(page, tableSorters, tableFilters, size, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Header with identification select */}
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ marginTop: 16, marginBottom: 16 }}
      >
        <Col>
          <Row type="flex" align="middle" gutter={16}>
            <Col>
              <strong>Identificação: </strong>
            </Col>
            <Col>
              <Select
                value={identificationSelected}
                allowClear={false}
                onChange={(value) => {
                  handleChangeIdentification(value);
                  fetchData();
                }}
              >
                <Select.Option value={"handlingNumber"}>
                  Identificação
                </Select.Option>
                <Select.Option value={"tagId"}>Id. Eletrônica</Select.Option>
                <Select.Option value={"sisbov"}>SISBOV</Select.Option>
                <Select.Option value={"nickname"}>Nome</Select.Option>
              </Select>
            </Col>
            <Col>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={handleRefresh}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <AnimalFattenTable />
        </Col>
      </Row>
    </>
  );
});

const AnimalFatten = () => {
  return (
    <AnimalFattenContextProvider>
      <AnimalFattenLayout />
    </AnimalFattenContextProvider>
  );
};

export default AnimalFatten;
