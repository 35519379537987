import { call, put } from "redux-saga/effects";
import { Creators as FarmDeathReasonsActions } from "../ducks/farmDeathReason";

import { notification } from "antd";

/** Services */
import {
  Inactivate,
  Activate,
  indexAll,
  indexAllDropdown,
} from "../../services/farmDeathReasonService";

export function* indexFarmDeathReasons(action) {
  try {
    const {
      data: { results: reasons },
    } = yield call(indexAll, action.payload);
    yield put(FarmDeathReasonsActions.indexSuccess(reasons));
  } catch (error) {
    yield put(FarmDeathReasonsActions.indexError(error));
  }
}

export function* getDropdownFarmDeathReasons(action) {
  try {
    const {
      data: { results: reasons },
    } = yield call(indexAllDropdown, action.payload);
    yield put(FarmDeathReasonsActions.getDropdownFarmDeathReasonSuccess(reasons));
  } catch (error) {
    yield put(FarmDeathReasonsActions.getDropdownFarmDeathReasonError(error));
  }
}

export function* inactivateFarmDeathReason(action) {
  try {
    const {
      data: { results: reasons },
    } = yield call(Inactivate, action.payload);
  } catch (error) {
    notification.error({
      message: `Causa Mortis não foi inativada. Contate o suporte`,
    });
  }

  yield put(FarmDeathReasonsActions.index(action.payload.groupId, action.payload.farmId));
}

export function* activateFarmDeathReason(action) {
  try {
    const {
      data: { results: reasons },
    } = yield call(Activate, action.payload);
  } catch (error) {
    notification.error({
      message: `Causa Mortis não foi ativada. Contate o suporte`,
    });
  }

  yield put(FarmDeathReasonsActions.index(action.payload.groupId, action.payload.farmId));
}