import styled from "styled-components";
import { Row, Button, Card, Tag, Divider } from "antd";
import rectangle from "../../../assets/images/rectangle.png";

export const Container = styled.div`
  width: 100% !important;
  min-width: 100% !important;
  height: 100% !important;
  min-height: 100% !important;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  overflow-x: hidden !important;

  div.pageTree {
    margin-top: 10px;
    margin-bottom: -6px;
  }

  span.backIcon {
    vertical-align: middle;
  }

  span.pageTreeInative {
    color: #c4c4c4;
    font-size: 16px;
  }
  span.pageTreeActive {
    color: #684e94;
    font-size: 16px;
  }
`;

export const RowHeader = styled(Row)`
  margin-top: 16px !important;
`;

export const Title = styled.h1`
  font-family: "Asap", serif;
  font-weight: bold;
  font-size: 25px;
  text-align: left;
  color: #4b4b4b;
`;

export const TitleDate = styled.span`
  margin-left: 9px;
  font-family: "Asap", serif;
  font-weight: normal;
  font-style: italic;
  font-size: 12px;
  text-align: left;
  color: #4b4b4b;
`;

export const ButtonOptions = styled(Button)`
  border-radius: 5px !important;
  background: transparent !important;
  border: 1px solid #4a85ae !important;
  font-family: "Asap", serif !important;
  font-weight: normal !important;
  font-size: 14px !important;
  text-align: left !important;
  color: #4a85ae !important;
`;

export const CardCustomLotDetails = styled(Card)`
  height: 100% !important;
  min-height: 100px !important;
  border-radius: 10px !important;
  background: #fff !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;

  .ant-row-flex + .ant-row-flex {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .ant-card-body {
    height: 100% !important;
    padding: 13px 10px 0px 15px !important;
  }

  &.marketValuationCard {
    min-height: 100px !important;
    background: url(${rectangle}) !important;
    background-position: right bottom !important;
    background-repeat: no-repeat !important;
  }

  &.productionCostsChart {
    min-height: 100px !important;
    //background: url(${rectangle}) !important;
    //background-position: right bottom !important;
    background-repeat: no-repeat !important;

    span {
      font-family: "Asap", serif;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: #707070;

      .bold {
        margin-left: 3px;
        font-weight: bold;
      }
    }

    i {
      margin-left: 5px;
    }
  }

  .dietStrategyExpired {
    margin-left: 5px;
    width: 16px;
    color: #d44c4c;
  }

  .eyeButton {
    background: transparent;
    border: 0;
    margin-left: 5px;
    cursor: pointer;
  }
`;

export const LabelGray = styled.label`
  font-family: "Asap", serif;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #a5a5a5;
`;

export const SpanBold = styled.span`
  font-family: "Asap", serif;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: #4b4b4b;
`;
export const SpanItalic = styled.span`
  font-family: "Asap", serif;
  font-size: 14px;
  font-style: italic;
  text-align: left;
  color: #4b4b4b;
`;

export const CustomDivider = styled(Divider)`
  &.ant-divider-dashed {
    border-color: #a5a5a5 !important;
    border-width: 2px 0 0 !important;
  }
  .ant-divider-inner-text {
    font-family: "Asap", serif;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;

export const BaselineTag = styled(Tag)`
  margin: 0 0 0 7px !important;
  padding: 0px 5px !important;
  border: none !important;
  border-radius: 3px !important;
  background: #a9c133 !important;
  font-family: "Asap", serif !important;
  font-weight: normal !important;
  font-size: 12px !important;
  text-align: center !important;
  color: #fff !important;
`;

export const ButtonOptionalDiets = styled.span`
  border: none !important;
  background: transparent !important;
  font-family: "Asap", serif !important;
  font-weight: bold !important;
  font-size: 12px !important;
  text-align: left !important;
  color: #684e94 !important;
  padding: 0 !important;
  svg {
    margin-right: 3px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ListDietOptionals = styled.ul`
  li {
    list-style-type: none;
  }
`;

export const CardLotWeightAverage = styled(Row)`
  min-height: 85px;
  margin-top: 28px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border-radius: 7px;
  background: #81729a;
  font-family: "Asap", serif;
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  color: #fff;

  .ant-row + .ant-row {
    margin-top: 10px;
  }

  i + span {
    margin-left: 3px;
  }

  span.bold {
    &.title {
      font-size: 14px;
    }
    svg {
      margin-right: 4px;
    }
    font-weight: bold;
  }
`;

export const CardLotPicketData = styled(Row)`
  min-height: 85px;
  margin-top: 28px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-top-right-radius: 21.5px;
  border-bottom-right-radius: 21.5px;
  background: #fff;
  font-family: "Asap", serif;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #707070;

  .colPicketTitle {
    background: #81729a;
    display: flex;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    border: ${(props) =>
      props.error === "true" ? "2px solid #ff0000" : "1px solid #81729a"};
    color: #fff;
    align-items: center;
    justify-content: center;

    .ant-row-flex {
      height: 100%;
    }

    svg {
      margin-right: 5px;
      path {
        fill: #fff !important;
      }
    }

    span.red {
      font-weight: bold;
      color: #ff0000;
    }
  }

  .colPicketInfo {
    position: relative;
    padding: 5px 0px;
    border: ${(props) =>
      props.error === "true" ? "2px solid #ff0000" : "1px solid #707070"};
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 21.5px;
    border-bottom-right-radius: 21.5px;

    .ant-row-flex:not(:last-of-type) {
      margin-bottom: 5px;
    }

    .iconWarning {
      position: absolute;
      cursor: pointer;
      top: 10%;
      right: 5%;
    }
  }

  span.bold {
    svg {
      margin-right: 4px;
    }
    font-weight: bold;
  }
`;

export const ChartTitle = styled.span`
  font-family: "Asap", serif;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: #4b4b4b;
  margin-right: 4px;
`;

export const AlertDefault = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #fe8d2a;
  margin-left: 5px;
  /* position: absolute;
  right: 0;
  top: 0; */
`;
