import React from "react";
import { useSelector } from "react-redux";
import usePicketSupplementSupplyContext from "../../../hooks/usePicketSupplementSupplyContext";
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Icon,
  Input,
  Menu,
  Row,
  Select,
  Table,
  notification,
} from "antd";
import { useCallback } from "react";
import moment from "moment";
import { numberMask } from "../../../services/helpersMethodsService";
import { Container as MenuContainer } from "../../../components/utils/table/menu/styles";
import MenuIcon from "../../../components/utils/table/icons/menu";
import { destroyBySupplyDate } from "../../../services/picketSupplementSupplyService";

// import { Container } from './styles';

function PicketSupplementSupplyTable() {
  const { dataList, isLoadingDataList, fetchData } =
    usePicketSupplementSupplyContext();
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  function expandedRowsPicketsTreats(record, index, indent, expanded) {
    const {
      subTable: {
        supplyNumber,
        supplementName,
        dryMatterPercentage,
        quantitySupplied,
        responsibleName,
      },
    } = translation.picketSupplementSupply.table.columns.subTable;

    const columns = [
      {
        title: supplyNumber,
        dataIndex: "supplyNumber",
      },
      {
        title: supplementName,
        dataIndex: "supplementName",
      },
      {
        title: dryMatterPercentage,
        dataIndex: "dryMatterPercentage",
        render: (text) => `${numberMask(text, false)}%`,
      },
      {
        title: quantitySupplied,
        dataIndex: "quantitySupplied",
        render: (text) => `${numberMask(text, false)} Kg`,
      },
      {
        title: responsibleName,
        dataIndex: "responsibleName",
      },
    ];

    const { treatments } = record;

    if (!treatments || treatments.length === 0) return false;

    return (
      <Table
        rowKey="id"
        columns={columns}
        dataSource={treatments}
        pagination={false}
        style={{ padding: "2" }}
      />
    );
  }

  function expandedRowsPickets(record, index, indent, expanded) {
    const {
      subTable: {
        retreatName,
        picketName,
        totalAnimals,
        supplementName,
        weightedAverageDryMatter,
        totalSupplementSupplied,
        productionSubSystem,
      },
    } = translation.picketSupplementSupply.table.columns;

    const columns = [
      {
        title: retreatName,
        dataIndex: "retreatName",
      },
      {
        title: picketName,
        dataIndex: "picketName",
      },
      {
        title: totalAnimals,
        dataIndex: "totalAnimals",
      },
      {
        title: supplementName,
        dataIndex: "supplementName",
      },
      {
        title: weightedAverageDryMatter,
        dataIndex: "weightedAverageDryMatter",
        render: (text) => `${numberMask(text * 100, false)}%`,
      },
      {
        title: totalSupplementSupplied,
        dataIndex: "totalSupplementSupplied",
        render: (text) => `${numberMask(text, false)} Kg`,
      },
      {
        title: productionSubSystem,
        dataIndex: "productionSubSystem",
        render: (text) => translation.productionSubSystem[text],
      },
    ];

    const { pickets } = record;

    if (!pickets || pickets.length === 0) return false;

    return (
      <Table
        rowKey="picketId"
        columns={columns}
        dataSource={pickets}
        pagination={false}
        style={{ padding: "2" }}
        expandedRowRender={expandedRowsPicketsTreats}
      />
    );
  }

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetDateSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div
          style={{
            padding: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Row style={{ marginBottom: 5 }}>
            <DatePicker.RangePicker
              value={selectedKeys && selectedKeys[0]?.dates}
              format="DD/MM/YYYY"
              onChange={(dates, dateStrings) => setSelectedKeys([{ dates }])}
            />
          </Row>
          <Row>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{
                width: 90,
                marginRight: 8,
                background: "#684e94",
                borderColor: "none",
                border: "none",
              }}
            />
            <Button
              onClick={() => handleReset(clearFilters)}
              icon="delete"
              size="small"
              style={{ width: 90 }}
            />
          </Row>
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="calendar"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return moment(record[dataIndex]).isBetween(
          value?.dates[0],
          value?.dates[1],
          "days",
          "[]"
        );
      },
    };
  };

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return record[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    }),
    [handleReset, handleSearch]
  );

  const handleDelete = useCallback(
    async (supplyDate) => {
      try {
        await destroyBySupplyDate({
          groupId,
          farmId,
          supplyDate,
        });
        fetchData();
        notification.success({
          message: "O último trato foi deletado com sucesso!",
        });
      } catch (error) {
        notification.error({
          message: "Erro",
          description: "Erro ao deletar último trato.",
        });
      } finally {
      }
    },
    [farmId, fetchData, groupId]
  );

  const menu = (id, record) => {
    return (
      <Menu>
        <Menu.Item onClick={() => handleDelete(record.supplyDate)}>
          {translation.table.menu.delete}
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Table
      dataSource={dataList}
      loading={isLoadingDataList}
      rowKey={"supplyDate"}
      expandedRowRender={expandedRowsPickets}
    >
      <Table.Column
        dataIndex="supplyDate"
        title={translation.picketSupplementSupply.table.columns.supplyDate}
        sorter={(first, second) => {
          return first.supplyDate.localeCompare(second.supplyDate, "pt-BR", {
            numeric: false,
            ignorePunctuation: true,
          });
        }}
        {...handleGetDateSearchProps("supplyDate")}
        render={(text) => moment(text).format("DD/MM/YYYY")}
      />
      <Table.Column
        dataIndex="picketsCount"
        title={translation.picketSupplementSupply.table.columns.picketsCount}
        sorter
        {...handleGetColumnSearchProps("picketsCount")}
      />
      <Table.Column
        dataIndex="totalSupplementSupplied"
        title={
          translation.picketSupplementSupply.table.columns
            .totalSupplementSupplied
        }
        sorter
        {...handleGetColumnSearchProps("totalSupplementSupplied")}
        render={(text) => `${numberMask(text, false)} kg`}
      />
      <Table.Column
        dataIndex="responsibleName"
        title={translation.picketSupplementSupply.table.columns.responsibleName}
        sorter
        {...handleGetColumnSearchProps("responsibleName")}
      />
      <Table.Column
        align="left"
        width={50}
        render={(text, record) => (
          <Dropdown
            overlay={menu(record.id, record)}
            trigger={["click"]}
            key={record.id}
          >
            <MenuContainer>
              <MenuIcon />
            </MenuContainer>
          </Dropdown>
        )}
      />
    </Table>
  );
}

export default PicketSupplementSupplyTable;
