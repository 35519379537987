import React from "react";

import { Container } from "./styles";

const BoxText = ({
  color,
  fontSize,
  iconComponent,
  children,
  alingCenter,
  style,
}) => (
  <Container
    color={color != null ? color : null}
    fontSize={fontSize != null ? fontSize : null}
    alingCenter={alingCenter != null ? alingCenter : false}
    style={style}
  >
    {iconComponent != null ? (
      <>
        <div className="iconContainer">{iconComponent}</div>
        <div className="messageContainer">{children}</div>
      </>
    ) : (
      <div className="messageContainer withMargin">{children}</div>
    )}
  </Container>
);

export default BoxText;
