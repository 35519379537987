import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row } from "antd";
import moment from "moment";

import { Container, CardShadow, CustomDivider } from "./styles";

import { getPastureIndexDropDown } from "../../../../services/pastureService";
import { getSupplementIndexDropDown } from "../../../../services/supplementService";

// Components
import RainIcon from "../../../../pages/generalParameter/weatherSeasonsParameter/display/icons/rain";
import SunIcon from "../../../../pages/generalParameter/weatherSeasonsParameter/display/icons/sun";
import TransitionIcon from "../../../../pages/generalParameter/weatherSeasonsParameter/display/icons/transition";

const DrawerDisplayPurchaseScenarioPeriod = ({
  data,
  drawerVisible,
  onCancel,
}) => {
  const [dietPeriod, setDietPeriod] = useState(null);
  const [pastures, setPastures] = useState([]);
  const [supplements, setSupplements] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  useEffect(() => {
    async function fetch() {
      setDietPeriod(data);
      const {
        data: { results: resultsPasture },
      } = await getPastureIndexDropDown({
        groupId,
        farmId,
      });
      setPastures(resultsPasture);
      const {
        data: { results: resultsSupplement },
      } = await getSupplementIndexDropDown({
        groupId,
        farmId,
      });
      setSupplements(resultsSupplement);
      setSeasons(data != null ? data.season.split(",") : []);
    }
    fetch();
  }, [data, groupId, farmId]);

  return dietPeriod != null ? (
    <Container
      title="PARÂMETROS DO CENÁRIO"
      width={450}
      onClose={onCancel}
      maskClosable={false}
      visible={drawerVisible}
    >
      <div className="drawerForm">
        <div className="info-box">
          <p>Período(s) climático(s)</p>
          <b>
            {seasons.map((season) =>
              season === "Water" ? (
                <RainIcon style={{ marginLeft: "3px" }} />
              ) : season === "Transition" ? (
                <TransitionIcon style={{ marginLeft: "3px" }} />
              ) : season === "Dry" ? (
                <SunIcon style={{ marginLeft: "3px" }} />
              ) : null
            )}
          </b>
        </div>
        <div className="info-box">
          <p>Início da dieta</p>
          <b>
            {moment(dietPeriod.startDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
          </b>
        </div>
        <div className="info-box">
          <p>Fim da dieta</p>
          <b>{moment(dietPeriod.endDate, "YYYY-MM-DD").format("DD/MM/YYYY")}</b>
        </div>
        <div className="info-box">
          <p>Produção</p>
          <b>
            {dietPeriod.productionSubSystem === "Extensive"
              ? "Extensivo"
              : dietPeriod.productionSubSystem === "Intensive"
              ? "Intensivo"
              : "Semi-Intensivo"}
          </b>
        </div>

        <CustomDivider dashed>DIETA</CustomDivider>

        <CardShadow>
          <span className="title">Forrageira</span>
          <Row type="flex" justify="space-between">
            <b>
              {pastures != null &&
                dietPeriod.pastureId != null &&
                pastures.find((pasture) => pasture.id === dietPeriod.pastureId)
                  .name}
            </b>
            <p style={{ marginTop: 0 }}>
              {pastures != null &&
                dietPeriod.pastureId != null &&
                pastures.find((pasture) => pasture.id === dietPeriod.pastureId)
                  .pastureType.name}
            </p>
          </Row>
          <Row type="flex" justify="space-between">
            <p>Adubado</p>
            <b>{dietPeriod.pastureFertilized ? "Sim" : "Não"}</b>
          </Row>
          <Row type="flex" justify="space-between">
            <p>Custo diário</p>
            <b>R$ {dietPeriod.pastureDailyCost?.toFixed(2)}</b>
          </Row>
        </CardShadow>
        {dietPeriod.supplementId != null ? (
          <CardShadow>
            <span className="title">Suplemento/Concent.</span>
            <Row type="flex" justify="space-between">
              <b>
                {supplements != null &&
                  supplements.find(
                    (supplement) => supplement.id === dietPeriod.supplementId
                  ).name}
              </b>
            </Row>
            <Row type="flex" justify="space-between">
              <p>Consumo diário / animal</p>
              <b>
                {dietPeriod.supplementConsumptionByAnimal.toFixed(2)} g/kg de PV
              </b>
            </Row>
            <Row type="flex" justify="space-between">
              <p>Custo diário</p>
              <b>R$ {dietPeriod.supplementDailyCost.toFixed(2)}</b>
            </Row>
          </CardShadow>
        ) : null}
      </div>
    </Container>
  ) : null;
};

export default DrawerDisplayPurchaseScenarioPeriod;
