import styled from "styled-components";

export const Container = styled.div``;

export const Title = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: #4b4b4b;
  margin-bottom: 10px;
  margin-right: 5px;
`;

export const CardBoxInfoPicket = styled.button`
  position: relative;

  width: 140px;
  min-width: 140px;
  max-width: 140px;
  min-height: 110px;
  max-height: 110px;

  padding: 8px 24px;

  margin: 4px 8px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #f4f4f4;

  border-radius: 10px;

  border: none;

  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

  strong {
    font-size: 14px;
    font-family: "Asap", sans-serif;
    color: #4b4b4b;
  }

  span {
    font-size: 12px;
    font-family: "Asap", sans-serif;
    color: #4b4b4b;
  }

  &.green {
    background-color: #83d18e;
    cursor: pointer;
  }

  &.red {
    background-color: #ff6c71;
    cursor: pointer;
  }

  &.yellow {
    background-color: #fbe870;
    cursor: pointer;
  }

  .dietStrategyOutOfDate {
    position: absolute;
    top: 10px;
    right: 20px;
  }
`;
