import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Row, Col } from "antd";
/* Redux libs */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as AppActions } from "../../../store/ducks/app";

import {
  Title,
  Message,
  ModalSessionExpired,
  ButtonSession,
  Time
} from "./styles";

const AlmostExpiredModal = props => {
  const {
    app: { translation, almostExpired },
    appActions
  } = props;

  const [lastTime] = useState(
    moment(new Date(localStorage.getItem("@Bovexo:expires_in")))
  );
  const handleGoToLogin = () => {
    appActions.hideAlmostExpired();
  };
  const logout = () => {
    appActions.resetData();
    window.location.href = "/login";
  };

  const icon = (
    <svg width="47.077" height="36" viewBox="0 0 47.077 36">
      <g
        id="Group_1240"
        data-name="Group 1240"
        transform="translate(-563 -254)"
      >
        <g id="fast" transform="translate(563 258.154)">
          <g
            id="Group_1231"
            data-name="Group 1231"
            transform="translate(2.23 9.884)"
          >
            <g id="Group_1230" data-name="Group 1230">
              <rect
                id="Rectangle_502"
                data-name="Rectangle 502"
                width="4.735"
                height="2.416"
              />
            </g>
          </g>
          <g
            id="Group_1233"
            data-name="Group 1233"
            transform="translate(2.23 19.546)"
          >
            <g id="Group_1232" data-name="Group 1232">
              <rect
                id="Rectangle_503"
                data-name="Rectangle 503"
                width="4.735"
                height="2.416"
              />
            </g>
          </g>
          <g
            id="Group_1235"
            data-name="Group 1235"
            transform="translate(0 14.715)"
          >
            <g id="Group_1234" data-name="Group 1234">
              <rect
                id="Rectangle_504"
                data-name="Rectangle 504"
                width="6.964"
                height="2.416"
              />
            </g>
          </g>
          <g
            id="Group_1237"
            data-name="Group 1237"
            transform="translate(9.38 0)"
          >
            <g id="Group_1236" data-name="Group 1236">
              <path
                id="Path_1081"
                data-name="Path 1081"
                d="M132.412,58.245a15.923,15.923,0,1,0,15.923,15.923A15.941,15.941,0,0,0,132.412,58.245Zm0,29.431A13.508,13.508,0,1,1,145.92,74.168,13.523,13.523,0,0,1,132.412,87.676Z"
                transform="translate(-116.489 -58.245)"
              />
            </g>
          </g>
          <g
            id="Group_1239"
            data-name="Group 1239"
            transform="translate(23.667 8.721)"
          >
            <g id="Group_1238" data-name="Group 1238">
              <path
                id="Path_1082"
                data-name="Path 1082"
                d="M296.346,174.359v-7.8H293.93v9.079l6.225,4.242,1.36-2Z"
                transform="translate(-293.93 -166.557)"
              />
            </g>
          </g>
        </g>
        <path
          id="Path_1083"
          data-name="Path 1083"
          d="M10.731,0A10.731,10.731,0,1,1,0,10.731,10.731,10.731,0,0,1,10.731,0Z"
          transform="translate(588.615 254)"
          fill="#ce0000"
          opacity="0.64"
        />
        <g id="surface1" transform="translate(594.846 259.538)">
          <path
            id="Path_1088"
            data-name="Path 1088"
            d="M11.912,3.8V3.788c-.006-.135-.01-.277-.013-.435A1.484,1.484,0,0,0,10.5,1.9,5.759,5.759,0,0,1,6.581.246L6.572.238a.9.9,0,0,0-1.218,0L5.345.246A5.76,5.76,0,0,1,1.425,1.9a1.484,1.484,0,0,0-1.4,1.454c0,.157-.007.3-.013.435v.025a15.177,15.177,0,0,0,.613,5.54,7.052,7.052,0,0,0,1.692,2.635A8.85,8.85,0,0,0,5.63,13.947a1.026,1.026,0,0,0,.136.037,1,1,0,0,0,.394,0,1.029,1.029,0,0,0,.136-.037A8.856,8.856,0,0,0,9.6,11.987,7.062,7.062,0,0,0,11.3,9.351,15.2,15.2,0,0,0,11.912,3.8ZM5.963,10.491Z"
            transform="translate(-1.485 -1.743)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );

  return (
    <ModalSessionExpired
      visible={almostExpired}
      width={468}
      footer={null}
      centered
      maskClosable={false}
      closable={false}
      style={{ zIndex: 9999 }}
    >
      <Row justify="start">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Title>
            {icon}
            <strong>{translation.sessionExpired.titleAlmostExpired}</strong>
          </Title>
        </Col>
      </Row>
      <Row justify="start">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Time>
            {`${moment.duration(moment().diff(lastTime)).humanize()} ${
              translation.sessionExpired.toExpire
            }`}
          </Time>
        </Col>
      </Row>
      <Row justify="start" style={{ marginTop: 8 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Message>{translation.sessionExpired.messageAlmostExpired}</Message>
        </Col>
      </Row>
      <Row justify="space-between" style={{ marginTop: 21 }}>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} align="left">
          <ButtonSession className="red" onClick={logout}>
            {translation.sessionExpired.buttonLogout}
          </ButtonSession>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
          <ButtonSession onClick={handleGoToLogin}>
            {translation.sessionExpired.buttonConfirm}
          </ButtonSession>
        </Col>
      </Row>
    </ModalSessionExpired>
  );
};

const mapStateToProps = state => ({
  app: state.app
});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(AppActions, dispatch)
});
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AlmostExpiredModal)
);
