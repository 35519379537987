import React from "react";
function RegisterHandIcon() {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="28.5" cy="28" rx="28.5" ry="28" fill="#94CF1C" />
      <path
        d="M34.1408 33.7622L36.9483 31.5237L37.0338 31.3848L37.0819 31.2967C37.3448 30.814 37.4622 30.2655 37.42 29.7175C37.3778 29.1695 37.1778 28.6455 36.8441 28.2087C36.8366 28.2014 36.8266 28.1973 36.8161 28.1973C36.8056 28.1973 36.7955 28.2014 36.788 28.2087L32.0599 31.9725C31.3968 32.5016 30.9657 33.268 30.8579 34.1095L30.7671 34.7613C30.5893 36.073 30.5482 37.3997 30.6442 38.72L30.7243 40.2506V40.2987"
        stroke="white"
        strokeWidth="1.3"
        strokeMiterlimit="10"
      />
      <path
        d="M37.0872 31.4169L37.1433 31.3688C37.5467 31.0295 38.1103 30.5674 38.5778 30.1774C38.6552 30.108 38.7327 30.0465 38.8022 29.9878L38.9678 29.8355L40.5091 25.2356C40.5466 24.9782 40.612 24.7256 40.7041 24.4823C40.9481 23.7153 41.4126 23.0371 42.0397 22.5323C42.622 22.1156 43.6424 21.7523 43.9416 22.0863C43.9775 22.1348 44.0032 22.1901 44.017 22.2489C44.0308 22.3077 44.0324 22.3687 44.0217 22.4282C43.4733 24.8679 42.9239 27.3094 42.3736 29.7527C41.6631 31.0883 40.8563 32.4827 39.9321 33.9305C38.8876 35.568 36.8709 38.2819 36.5637 38.6906C36.538 38.7247 36.5231 38.7656 36.5209 38.8082L36.4515 40.2533"
        stroke="white"
        strokeWidth="1.3"
        strokeMiterlimit="10"
      />
      <path
        d="M38.5725 30.172L38.4042 25.9889C38.3801 25.4456 38.5099 24.9066 38.7789 24.434C39.0478 23.9614 39.4448 23.5744 39.9241 23.3176L40.5198 22.9971C40.5366 22.985 40.5564 22.9778 40.577 22.976C40.5976 22.9742 40.6183 22.9781 40.6369 22.9871C40.6555 22.9961 40.6713 23.01 40.6827 23.0273C40.694 23.0446 40.7005 23.0646 40.7015 23.0852V24.493"
        stroke="white"
        strokeWidth="1.3"
        strokeMiterlimit="10"
      />
      <path
        d="M22.155 33.7625L19.3502 31.524L19.2621 31.3851L19.214 31.2969C18.9508 30.8142 18.8334 30.2654 18.8761 29.7173C18.9188 29.1691 19.1197 28.6452 19.4544 28.209C19.4579 28.2054 19.4621 28.2026 19.4666 28.2007C19.4712 28.1987 19.4762 28.1978 19.4811 28.1978C19.4861 28.1978 19.491 28.1987 19.4956 28.2007C19.5002 28.2026 19.5044 28.2054 19.5078 28.209L24.2386 31.9727C24.9013 32.5022 25.3323 33.2684 25.4407 34.1097L25.5315 34.7749C25.7081 36.0867 25.7493 37.4133 25.6544 38.7336L25.5715 40.2536V40.3017"
        stroke="white"
        strokeWidth="1.3"
        strokeMiterlimit="10"
      />
      <path
        d="M19.2113 31.4171L19.1525 31.369L17.7208 30.175L17.4937 29.988L17.3308 29.8357L15.7921 25.2439C15.7524 24.9878 15.687 24.7363 15.5971 24.4932C15.3523 23.7266 14.8879 23.0486 14.2615 22.5433C13.6712 22.1265 12.6374 21.7633 12.3516 22.0891C12.3156 22.1377 12.29 22.193 12.2762 22.2518C12.2624 22.3106 12.2608 22.3716 12.2714 22.4311L13.9223 29.7556C14.6301 31.0912 15.4369 32.4856 16.3611 33.9334C17.4056 35.5708 19.4223 38.2848 19.7295 38.6935C19.7552 38.7276 19.7701 38.7685 19.7723 38.8111L19.8471 40.2535V40.3016"
        stroke="white"
        strokeWidth="1.3"
        strokeMiterlimit="10"
      />
      <path
        d="M17.7235 30.172L17.8917 25.9889C17.9158 25.446 17.7862 24.9073 17.5178 24.4347C17.2494 23.9622 16.8531 23.575 16.3745 23.3176L15.7761 22.9971C15.7593 22.985 15.7396 22.9778 15.719 22.976C15.6984 22.9742 15.6777 22.9781 15.6591 22.9871C15.6405 22.9961 15.6247 23.01 15.6133 23.0273C15.6019 23.0446 15.5954 23.0646 15.5945 23.0852V24.493"
        stroke="white"
        strokeWidth="1.3"
        strokeMiterlimit="10"
      />
      <path
        d="M28.0212 28.1954C32.0929 28.1954 35.3938 24.8946 35.3938 20.8228C35.3938 16.751 32.0929 13.4502 28.0212 13.4502C23.9494 13.4502 20.6486 16.751 20.6486 20.8228C20.6486 24.8946 23.9494 28.1954 28.0212 28.1954Z"
        stroke="white"
        strokeWidth="1.3"
        strokeMiterlimit="10"
      />
      <path
        d="M28.0745 20.7158C28.8411 20.7158 29.4635 21.2661 29.4635 21.9446C29.4635 22.6231 28.8411 23.1734 28.0745 23.1734C27.3078 23.1734 26.6854 22.6231 26.6854 21.9446"
        stroke="white"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M29.4635 19.4876C29.4635 18.8091 28.8411 18.2588 28.0745 18.2588H28.0584C27.3025 18.2588 26.6854 18.8117 26.6854 19.4876C26.6854 20.1634 27.3105 20.7163 28.0745 20.7163"
        stroke="white"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M28.0585 17.6387V18.2584V18.2798"
        stroke="white"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M28.0585 23.1948V23.8359"
        stroke="white"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M26.9126 43.8702V40.4404C26.9126 40.3894 26.8923 40.3404 26.8562 40.3044C26.8202 40.2683 26.7713 40.248 26.7202 40.248H18.0788C18.0278 40.248 17.9789 40.2683 17.9428 40.3044C17.9067 40.3404 17.8865 40.3894 17.8865 40.4404V43.8676"
        stroke="white"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M29.3834 43.8702V40.4404C29.3834 40.3894 29.4037 40.3404 29.4398 40.3044C29.4758 40.2683 29.5247 40.248 29.5758 40.248H38.2145C38.2655 40.248 38.3144 40.2683 38.3505 40.3044C38.3866 40.3404 38.4068 40.3894 38.4068 40.4404V43.8676"
        stroke="white"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default RegisterHandIcon;
