import { useContext } from "react";
import { MovementAnimalsContext } from "../../contexts/movementAnimalsContext";

const useMovementAnimalsContext = () => {
  const value = useContext(MovementAnimalsContext);

  return value;
};

export default useMovementAnimalsContext;
