import styled from "styled-components";
import { Divider } from "antd";

export const Container = styled.div`
  width: 100%;
  min-width: 100%;
  height: 100%;
  max-height: 800px;
  @media all and (max-height: 800px) {
    max-height: 600px;
  }

  .ant-row-flex {
    width: 100%;
    margin-top: 4px;
  }

  label {
    font-family: Asap;
    text-align: left;
    font-size: 14px;
    &.grey {
      font-weight: normal;
      font-size: 14px;
      color: #a5a5a5;
    }
    &.name {
      font-weight: bold;
      font-size: 18px;
      color: #684e94;
    }
    &.value {
      font-weight: bold;
      font-style: italic;
      font-size: 14px;
      color: #4B4B4B;
    }
    &.valueBlue {
      font-weight: bold;
      font-style: italic;
      font-size: 14px;
      color: #4A85AE;
    }
  }
`;

export const CustomDivider = styled(Divider)`
  margin: 10px 0 !important;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 10px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;
