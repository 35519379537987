import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useModuleContext from "../../hooks/useModuleContext";

// Components
import Supplement from "../supplement";
import Unit from "../unit";
import Veterinary from "../veterinary";

// Styles
import { Tabs } from "antd";
import { Container } from "./styles";

const SupplementParameters = () => {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { isAllowedFunctions } = useModuleContext();
  const [tabSelect, setTablSelect] = useState("1");

  useEffect(() => {
    setTablSelect(isAllowedFunctions(["UNITS"]) ? "1" : "2");
  }, [isAllowedFunctions, groupId, farmId]);

  return (
    <Container>
      <Tabs
        type="card"
        defaultActiveKey="1"
        activeKey={tabSelect}
        onChange={(key) => setTablSelect(key)}
      >
        <Tabs.TabPane
          tab={<span>{translation.parameters.tabs.unit}</span>}
          key="1"
          disabled={!isAllowedFunctions(["UNITS"])}
        >
          <Unit />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={<span>{translation.parameters.tabs.supplement}</span>}
          key="2"
          disabled={!isAllowedFunctions(["SUPPLEMENTS"])}
        >
          <Supplement />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={<span>{translation.parameters.tabs.veterinary}</span>}
          key="3"
          disabled={!isAllowedFunctions(["VETERINARY"])}
        >
          <Veterinary />
        </Tabs.TabPane>
      </Tabs>
    </Container>
  );
};

export default SupplementParameters;
