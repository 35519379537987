import styled from "styled-components";
import { Tabs } from "antd";

export const Container = styled.div`
  overflow-y: hidden;
`;
export const PageTitle = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  span {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #c4c4c4;
    text-transform: uppercase;
  }
  #bovexoParameterIcon {
    width: 25px;
    height: 25px;
    margin-right: 9px;
    path {
      fill: #c4c4c4;
    }
  }
`;

export const TabContainer = styled(Tabs)`
  margin-bottom: 10px !important;

  .ant-tabs-ink-bar {
    background-color: #684e94;
    height: 5px;
  }

  .ant-tabs-line {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab {
    font-family: "Asap" !important;
    font-size: 14px !important;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab-disabled,
  .ant-tabs-nav .ant-tabs-tab-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: #4b4b4b;
    font-weight: bold;
  }

  .ant-tabs-bar {
    border-bottom: 1px solid #a9c133;
  }

  .ant-tabs-tabpane {
    background-color: #fff;
    margin-top: -15px;
  }
`;
