import styled from "styled-components";

export const Container = styled.button`
  position: relative;
  background: transparent;
  border: none;
  &:hover {
    cursor: pointer;
    svg > g > circle {
      fill: #684e94 !important;
    }
  }
  &.active {
    cursor: pointer;
    svg > g > circle {
      fill: #684e94 !important;
    }
  }
  & > * {
    text-decoration: none !important;
    color: #4b4b4b !important;
    font-size: 14px !important;
  }
`;

export const MenuContainer = styled.ul`
  display: none;
  height: auto;
  list-style: none;
  &.active {
    z-index: 9999999 !important;
    display: flex !important;
    position: absolute !important;
    right: 100% !important;
    flex-direction: column !important;
    background: #f5f5f5;
    filter: drop-shadow(0px 3px 3px #c4c4c4);
    li {
      z-index: 9999999 !important;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #e8e8e8;
      a {
        width: 100% !important;
        height: 100% !important;
        display: flex !important;
        flex-direction: row !important;
        justify-content: flex-start !important;
        align-items: center !important;
        text-decoration: none !important;
        color: #4b4b4b !important;
        font-size: 14px;
        padding: 0px 17px 0 17px;

        &:hover {
          cursor: pointer;
          font-weight: bold;
        }
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
`;
