import React from 'react';

// import { Container } from './styles';

const MaleIcon = ({className}) => (
  <svg
    id="masculine"
    xmlns="http://www.w3.org/2000/svg"
    width="13.891"
    height="13.891"
    viewBox="0 0 13.891 13.891"
    className={className}
  >
    <path
      id="Path_913"
      data-name="Path 913"
      d="M5.395,106.052a5.395,5.395,0,1,1,5.395-5.395A5.4,5.4,0,0,1,5.395,106.052Zm0-8.772a3.377,3.377,0,1,0,3.377,3.377A3.381,3.381,0,0,0,5.395,97.28Z"
      transform="translate(0 -92.161)"
      fill="#4a85ae"
    />
    <path
      id="Path_914"
      data-name="Path 914"
      d="M266.357,5.395h-2.018V2.018h-3.377V0h5.395Z"
      transform="translate(-252.466)"
      fill="#4a85ae"
    />
    <rect
      id="Rectangle_237"
      data-name="Rectangle 237"
      width="2.018"
      height="6.203"
      transform="translate(9.209 6.108) rotate(-135)"
      fill="#4a85ae"
    />
  </svg>
);
export default MaleIcon;
