import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as LotActions } from "../../../store/ducks/lot";
// Components
import { Col, Row, Spin } from "antd";
import {
  ButtonSession,
  DeleteLotModalContainer,
  Message,
  Title,
} from "./styles";
import WarningIcon from "../../utils/icons/warning";

function DeleteLotModal() {
  // Redux variables
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { isLoadingRequest, showModalDeleteLot, dataLot } = useSelector(
    (state) => state.lot
  );
  const dispatch = useDispatch();
  // Variables
  // Effects
  // Methods
  const cancelAction = () => {
    dispatch(LotActions.showOrHideModalDelete(null));
  };
  const confirmAction = () => {
    const { id } = dataLot;
    dispatch(LotActions.deleteLotProduction(groupId, farmId, id));
  };
  return (
    <DeleteLotModalContainer
      visible={showModalDeleteLot}
      footer={null}
      centered
      maskClosable={false}
      closable={false}
    >
      <Row type="flex" justify="start">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Title>
            <WarningIcon />
            <strong>
              {dataLot?.currentAmountAnimals > 0
                ? `Atenção`
                : `Você realmente deseja deletar este lote ?`}
            </strong>
          </Title>
        </Col>
      </Row>
      {dataLot?.currentAmountAnimals > 0 ? (
        <>
          <Row type="flex" justify="center" style={{ marginTop: 8 }}>
            <Message>
              {"O Lote: "}
              <strong>{dataLot?.name || ""}</strong>
              {" não pode ser excluído, pois contem "}
              <strong>
                {`${dataLot?.currentAmountAnimals || 0} animais.`}
              </strong>
            </Message>
          </Row>
          <Row type="flex" justify="center" style={{ marginTop: 21 }}>
            <ButtonSession className="red" onClick={cancelAction}>
              {translation.buttons.back}
            </ButtonSession>
          </Row>
        </>
      ) : (
        <Spin spinning={isLoadingRequest}>
          <Row type="flex" justify="start" style={{ marginTop: 8 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Message>
                {"Após a confirmação desta ação o lote: "}
                <strong>{dataLot?.name || "-"}</strong>
                {" excluído definitivamente ou inativado."}
              </Message>
            </Col>
          </Row>
          <Row type="flex" justify={"space-between"} style={{ marginTop: 21 }}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} align="left">
              <ButtonSession className="red" onClick={cancelAction}>
                {translation.buttons.cancel}
              </ButtonSession>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
              <ButtonSession onClick={confirmAction}>
                {translation.buttons.confirm}
              </ButtonSession>
            </Col>
          </Row>
        </Spin>
      )}
    </DeleteLotModalContainer>
  );
}

export default DeleteLotModal;
