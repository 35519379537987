import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import useFarmAnimalSellContext from "../../../../../hooks/useFarmAnimalSellContext";
import { FarmAnimalSellContextProvider } from "../../../../../contexts/farmAnimalSellContext";

import { notification } from "antd";

// Components
import { Container } from "./styles";
import AnimalSelection from "./animalSelection";
import FormProductionSell from "./form";

const NewFarmProductionSellLayout = () => {
  const [step, setStep] = useState(1);

  const history = useHistory();
  const { selectedAnimalsKeys } = useFarmAnimalSellContext();

  const confirmAnimals = () => {
    if (selectedAnimalsKeys !== null && selectedAnimalsKeys.length > 0) {
      setStep(2);
    } else {
      notification.error({
        message: "Para continuar é necessário selecionar pelo menos 1 animal.",
      });
    }
  };

  const cancelAnimals = () => {
    history.push("/admin/financial");
  };

  const backToAnimalSelection = () => {
    setStep(1);
  };

  return (
    <Container>
      {step === 1 ? (
        <AnimalSelection onCancel={cancelAnimals} onConfirm={confirmAnimals} />
      ) : (
        <FormProductionSell onCancel={backToAnimalSelection} />
      )}
    </Container>
  );
};

const NewFarmProductionSell = () => {
  return (
    <FarmAnimalSellContextProvider>
      <NewFarmProductionSellLayout />
    </FarmAnimalSellContextProvider>
  );
};

export default NewFarmProductionSell;
