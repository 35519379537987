import apiOptimization from "../config/api_optimization";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiOptimization = axios.create({
//   baseURL: "http://localhost:8088",
// });

export function updateBoitelScenarioSimulationService(payload) {
  const { groupId, farmId, boitelScenarioId, id, body, signal } = payload;

  if (id != null) {
    return apiOptimization.put(
      `/bovexo/${groupId}/farms/${farmId}/scenarios/boitels/${boitelScenarioId}/simulations/${id}`,
      body,
      getHeaders(false, signal)
    );
  } else {
    throw Error("Id parameter is required");
  }
}

export function deleteBoitelScenarioSimulationService(payload) {
  const { groupId, farmId, boitelScenarioId, id, signal } = payload;

  if (id != null) {
    return apiOptimization.delete(
      `/bovexo/${groupId}/farms/${farmId}/scenarios/boitels/${boitelScenarioId}/simulations/${id}`,
      getHeaders(false, signal)
    );
  } else {
    throw Error("Id parameter is required");
  }
}
