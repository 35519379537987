import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";
import { Creators as BoitelSaleScenarioFarmSellActions } from "../../../../store/ducks/boitelSaleScenarioFarmSell";
// Components
import { FiCalendar } from "react-icons/fi";
import {
  Button,
  Card,
  Col,
  Collapse,
  Icon,
  Input,
  notification,
  Row,
  Spin,
  Table,
  Popover,
  Tooltip,
} from "antd";
import {
  Container,
  DateButton,
  HeaderContainer,
  MessageContainer,
} from "./styles";
import { ButtonNewFarmSell } from "../../../saleScenario/details/styles";
import TagStatus from "../../../../components/utils/tagStatus";
import ButtonStandard from "../../../../components/utils/button";
import EyeIcon from "../../../../components/utils/icons/eye";
import TrashIcon from "../../../../components/utils/icons/trash";
import ExclamationIcon from "../../../../components/utils/icons/exclamation";
// Services
import { getSales } from "../../../../services/boitelSaleScenarioResultSellService";
import { numberMask } from "../../../../services/helpersMethodsService";
import { deleteBoitelSaleScenarioFarmSellService } from "../../../../services/boitelSaleScenarioFarmSellService";
import AlertIcon from "../../../../components/drawers/picket/icons/alert";

function BoitelSaleScenarioDetailsSalesInfo({
  boitelSaleScenarioResult,
  removeFarmSell,
}) {
  const dateFormat = "DD/MM/YYYY";
  const customPanelStyle = {
    borderRadius: 7,
    background: "#fff",
    boxShadow: "0px 0px 10px #d8d8d8",
    marginBottom: 15,
  };
  let searchInput = useRef(null);
  const [isLoadingDataSales, setIsLoadingDataSales] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(null);
  const [sales, setSales] = useState([]);
  const [selectSellDatesIds, setSelectSellDatesIds] = useState([]);
  const [allAnimals, setAllAnimals] = useState([]);
  const [filterAnimals, setFilterAnimals] = useState([]);
  const [animalsSelected, setAnimalsSelected] = useState([]);
  const [animalsSelectedKeys, setAnimalsSelectedKeys] = useState([]);
  const [farmSells, setFarmSells] = useState([]);

  const rowSelection = {
    selectedRowKeys: animalsSelectedKeys,
    selections: [
      {
        key: "all-data",
        text: "Selec. todos os animais do resultado",
        onSelect: () => {
          setAnimalsSelected(allAnimals);
          setAnimalsSelectedKeys(allAnimals.map((a) => a.id));
        },
      },
      {
        key: "deselect-all-data",
        text: "Desselec. todos os animais do resultado",
        onSelect: () => {
          setAnimalsSelected([]);
          setAnimalsSelectedKeys([]);
        },
      },
    ],
    getCheckboxProps: (record) => ({
      disabled: record.animalStatus === "Sold",
    }),
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      if (selected) {
        setAnimalsSelected([...animalsSelected, record]);
        setAnimalsSelectedKeys([...animalsSelectedKeys, record.id]);
      } else {
        setAnimalsSelected(animalsSelected.filter((o) => o.id !== record.id));
        setAnimalsSelectedKeys(
          animalsSelectedKeys.filter((r) => r !== record.id)
        );
      }
    },
    onSelectAll: (record, selected, selectedRows, nativeEvent) => {
      // Get just the ids
      const keys = selectedRows.map((sr) => sr.id);
      // Get all the animalsSelected unless the unselected
      const lastSelectedAnimals = animalsSelected.filter(
        (sA) => !selectedRows.includes(sA)
      );
      // Get all the animalsSelectedKeys unless the unselected
      const lastSelectedAnimalsKeys = animalsSelectedKeys.filter(
        (sAK) => !keys.includes(sAK)
      );
      if (record) {
        setAnimalsSelected((old) => [...old, ...selectedRows]);
        setAnimalsSelectedKeys((old) => [...old, ...keys]);
      } else {
        setAnimalsSelected(lastSelectedAnimals);
        setAnimalsSelectedKeys(lastSelectedAnimalsKeys);
      }
    },
  };
  const history = useHistory();
  const { id: boitelSaleScenarioId, resultId: boitelSaleScenarioResultId } =
    useParams();
  // Redux var
  const dispatch = useDispatch();
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  // Effects
  // Reset states and set farmSells
  useEffect(() => {
    setAnimalsSelected([]);
    setAnimalsSelectedKeys([]);
    setFarmSells(boitelSaleScenarioResult?.farmSells || []);
  }, [boitelSaleScenarioResult]);
  // Fetch sales (animals)
  useEffect(() => {
    async function fetch() {
      setIsLoadingDataSales(true);
      try {
        const {
          data: { results },
        } = await getSales({
          groupId,
          farmId,
          boitelSaleScenarioId,
          boitelSaleScenarioResultId,
        });
        setSales(results);
        const animals = results.flatMap((r) => r.animals);
        setAllAnimals(animals);
        setFilterAnimals(animals);
      } catch (error) {
      } finally {
        setIsLoadingDataSales(false);
      }
    }
    fetch();
  }, [boitelSaleScenarioId, boitelSaleScenarioResultId, farmId, groupId]);
  // Memos
  const amountSales = useMemo(() => {
    if (farmSells.length > 0) {
      return farmSells.length;
    } else {
      return 0;
    }
  }, [farmSells]);
  // Methods
  function goToNewFarmSell() {
    dispatch(
      BoitelSaleScenarioFarmSellActions.updateSelectAnimals(
        animalsSelected,
        "add"
      )
    );
    history.push(
      `/admin/decisions/scenarios/sales/boitel/${boitelSaleScenarioId}/results/${boitelSaleScenarioResultId}/farms/sales/new`
    );
  }
  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        if (dataIndex === "lot.name") {
          return record.lot.name.includes(value);
        } else {
          return record[dataIndex].toString().includes(value);
        }
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select());
        }
      },
    };
  }
  const menuFarmSells = (record) => {
    return (
      <>
        <ButtonStandard
          buttonType="typeWithoutBackground"
          onClick={() => handleShowDetailFarmSell(record)}
        >
          <EyeIcon isActive />
        </ButtonStandard>
        <Popover
          placement="bottomRight"
          visible={popoverVisible === record.id}
          content={
            <MessageContainer>
              <div>
                <ExclamationIcon />
                <span>
                  Ao confirmar, a venda será excluída e os animais continurão na
                  fazenda, sendo necessário criar uma nova venda.
                </span>
              </div>
              <div className="buttonContainer">
                <ButtonStandard
                  background="#4A85AE"
                  padding="5px 10px"
                  color="#FFF"
                  fontSize="14px"
                  onClick={() => handleDeleteFarmSell(record.id)}
                >
                  Confirmar
                </ButtonStandard>
                <ButtonStandard
                  background="#D44C4C"
                  padding="5px 10px"
                  color="#FFF"
                  fontSize="14px"
                  onClick={() => setPopoverVisible(null)}
                >
                  Cancelar
                </ButtonStandard>
              </div>
            </MessageContainer>
          }
          trigger="click"
        >
          <ButtonStandard
            buttonType="typeWithoutBackground"
            style={{ marginLeft: 10 }}
            onClick={() => setPopoverVisible(record.id)}
          >
            <TrashIcon />
          </ButtonStandard>
        </Popover>
      </>
    );
  };
  const painelHeader = () => {
    return (
      <HeaderContainer>
        <div className="title">
          <span>Vendas efetuadas</span>
        </div>
        <div className="amountAnimals">
          <span>{`${amountSales} venda(s)`}</span>
        </div>
      </HeaderContainer>
    );
  };
  const handleSelectSellDate = useCallback(
    (sellId) => {
      const newSellDates = selectSellDatesIds.includes(sellId)
        ? selectSellDatesIds.filter((o) => o !== sellId)
        : [...selectSellDatesIds, sellId];
      setSelectSellDatesIds(newSellDates);
      if (newSellDates.length === 0) {
        setFilterAnimals(allAnimals);
      } else {
        setFilterAnimals(
          allAnimals.filter((a) =>
            newSellDates.includes(a.boitelSaleScenarioResultSellId)
          )
        );
      }
    },
    [allAnimals, selectSellDatesIds]
  );
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
  }
  function handleReset(clearFilters) {
    clearFilters();
  }
  async function handleDeleteFarmSell(id) {
    setIsLoadingDataSales(true);
    setPopoverVisible(null);
    try {
      await deleteBoitelSaleScenarioFarmSellService({
        groupId,
        farmId,
        boitelSaleScenarioId,
        boitelSaleScenarioResultId,
        id,
      });
      setFarmSells(farmSells.filter((fs) => fs.id !== id));
      setAllAnimals((old) =>
        old.map((a) => {
          if (a.boitelSaleScenarioFarmSellId === id) {
            a.animalStatus = "Active";
          }
          return a;
        })
      );
      setFilterAnimals((old) =>
        old.map((a) => {
          if (a.boitelSaleScenarioFarmSellId === id) {
            a.animalStatus = "Active";
          }
          return a;
        })
      );
      removeFarmSell(id);
      notification.success({
        message: "Venda deletada com sucesso.",
      });
    } catch (error) {
      notification.error({
        message: "Não foi possivel deletar a venda. Contate o suporte.",
      });
    } finally {
      setIsLoadingDataSales(false);
    }
  }

  function handleShowDetailFarmSell(farmSell) {
    const data = {
      ...farmSell,
    };
    dispatch(
      BoitelSaleScenarioFarmSellActions.setBoitelSaleScenarioFarmSellShowData(
        data
      )
    );
    history.push(
      `/admin/decisions/scenarios/sales/boitel/${boitelSaleScenarioId}/results/${boitelSaleScenarioResultId}/farms/sales/${farmSell.id}`
    );
  }

  function showViolationText(violation) {
    let violationText = "";
    switch (violation) {
      case "MaxWeight":
        violationText = "Peso máximo";
        break;
      case "MinWeight":
        violationText = "Peso mínimo";
        break;
      case "MaxAge":
        violationText = "Idade máxima";
        break;
      case "MinAge":
        violationText = "Idade mínima";
        break;
      case "Weight": //Legacy boitel scenarios
        violationText = "Peso";
        break;
      case "Age": //Legacy boitel scenarios
        violationText = "Idade";
        break;
      case "Boitel": //Legacy boitel scenarios
        violationText = "Boitel";
        break;
      default:
        break;
    }
    return violationText;
  }

  return (
    <Container>
      <Spin spinning={isLoadingDataSales}>
        {/* Farm sells */}
        {farmSells.length > 0 && (
          <Row type="flex" className="rowFarmSell">
            <Col span={24}>
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <i>
                      <Icon
                        type="up"
                        style={{ fontSize: "15px", color: "#684E94" }}
                      />
                    </i>
                  ) : (
                    <i>
                      <Icon
                        type="down"
                        style={{ fontSize: "15px", color: "#684E94" }}
                      />
                    </i>
                  )
                }
              >
                <Collapse.Panel
                  header={painelHeader()}
                  style={customPanelStyle}
                >
                  <Row type="flex" justify="start">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Table
                        rowKey="id"
                        dataSource={farmSells || []}
                        pagination={false}
                        size="small"
                      >
                        <Table.Column
                          title="Cliente"
                          dataIndex="clientName"
                          key="clientName"
                          align="left"
                        />
                        <Table.Column
                          title="Animais"
                          dataIndex="amountAnimalsSold"
                          key="amountAnimalsSold"
                          align="left"
                        />
                        <Table.Column
                          title="Data de Saída"
                          dataIndex="sellDate"
                          key="sellDate"
                          align="left"
                          sorter
                          render={(text, record) => (
                            <span>{`${moment(record.sellDate).format(
                              dateFormat
                            )}`}</span>
                          )}
                        />
                        <Table.Column
                          title="Fat. estimado"
                          dataIndex="estimatedBilling"
                          key="estimatedBilling"
                          align="left"
                          sorter
                          render={(text, record) => (
                            <span>{`${numberMask(
                              record.estimatedBilling || 0,
                              true
                            )}`}</span>
                          )}
                        />
                        <Table.Column
                          title="Fat. real"
                          dataIndex="totalBilling"
                          key="totalBilling"
                          align="left"
                          sorter
                          render={(text, record) => (
                            <span>{`${numberMask(
                              record.totalBilling || 0,
                              true
                            )}`}</span>
                          )}
                        />
                        <Table.Column
                          align="left"
                          render={(text, record) => menuFarmSells(record)}
                        />
                      </Table>
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Col>
          </Row>
        )}
        {/* Button New Farm Sell - Only show if animals is selected and sale scenario is baseline */}
        {boitelSaleScenarioResult?.baseline === true &&
          boitelSaleScenarioResult?.status !== "Canceled" &&
          boitelSaleScenarioResult?.status !== "PartialCanceled" &&
          boitelSaleScenarioResult?.status !== "Error" &&
          animalsSelected.length > 0 && (
            <Row type="flex" className="rowButtonNewFarmSell">
              <Col span={24}>
                <ButtonNewFarmSell onClick={goToNewFarmSell}>
                  <span>
                    <strong>Clique aqui</strong> para definir Cliente, Data de
                    entrega e faturamento final
                  </span>
                </ButtonNewFarmSell>
              </Col>
            </Row>
          )}
        <Row type="flex" className="rowMessageSelectDates">
          <Col span={24}>
            <span>
              {translation.boitelSaleScenario.details.messageSelectDates}
            </span>
          </Col>
        </Row>
        <Row type="flex">
          <Col span={24}>
            <label className="labelCalendarSuggested">
              {translation.boitelSaleScenario.details.calendarSuggested}
            </label>
          </Col>
        </Row>
        <Row type="flex" gutter={16}>
          {sales.map((s) => (
            <Col span={4} key={s.id}>
              <DateButton
                className={selectSellDatesIds.includes(s.id) ? "active" : ""}
                onClick={() => handleSelectSellDate(s.id)}
              >
                {s.sellDate
                  ? moment(s.sellDate).format(dateFormat)
                  : translation.boitelSaleScenario.details.weekDateNull}
                {s.sellDate && <FiCalendar />}
              </DateButton>
            </Col>
          ))}
        </Row>
        <Card style={{ marginTop: 24 }}>
          <Table
            rowKey="id"
            size="small"
            dataSource={filterAnimals != null ? filterAnimals : []}
            scroll={{
              x: true,
            }}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 10,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
            }}
            rowSelection={
              boitelSaleScenarioResult?.baseline === true &&
              boitelSaleScenarioResult?.status !== "Canceled" &&
              boitelSaleScenarioResult?.status !== "PartialCanceled" &&
              boitelSaleScenarioResult?.status !== "Error"
                ? rowSelection
                : null
            }
            style={{ width: "100%", margin: "0" }}
          >
            <Table.Column
              dataIndex="animalStatus"
              key="animalStatus"
              width={100}
              align="center"
              render={(text, record) => (
                <span>
                  {record.animalStatus === "Sold" ? (
                    <TagStatus
                      background="#FEFFF6"
                      borderColor="#A9C133"
                      color="#A9C133"
                    >
                      Vendido
                    </TagStatus>
                  ) : null}
                </span>
              )}
            />
            <Table.Column
              dataIndex="violations"
              key="violations"
              width={50}
              align="center"
              render={(text, record) =>
                record.violations != null &&
                record.violations.length > 0 && (
                  <Tooltip
                    title={
                      <div>
                        <strong>Violações:</strong>
                        <ul>
                          {record.violations.map((v, index) => (
                            <li key={index}>{showViolationText(v)}</li>
                          ))}
                        </ul>
                      </div>
                    }
                  >
                    <div>
                      <AlertIcon />
                    </div>
                  </Tooltip>
                )
              }
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns
                  .animalHandlingNumber
              }
              dataIndex="animalHandlingNumber"
              sorter={(first, second) => {
                return first.animalHandlingNumber.localeCompare(
                  second.animalHandlingNumber,
                  "pt-BR",
                  {
                    numeric: false,
                    ignorePunctuation: true,
                  }
                );
              }}
              key="animalHandlingNumber"
              width={180}
              align="left"
              {...getColumnSearchProps("animalHandlingNumber")}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns.sellDate
              }
              dataIndex="sellDate"
              sorter={(first, second) => {
                return first.sellDate.localeCompare(second.sellDate, "pt-BR", {
                  numeric: true,
                  ignorePunctuation: true,
                });
              }}
              key="sellDate"
              width={180}
              align="left"
              render={(text, record) => (
                <span>
                  {record.sellDate !== null
                    ? moment(record.sellDate).format(dateFormat)
                    : "-"}
                </span>
              )}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns.weight
              }
              dataIndex="weight"
              key="weight"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.weight - second.weight;
              }}
              {...getColumnSearchProps("weight")}
              render={(text, record) => (
                <span>
                  {record.weight !== null
                    ? `${numberMask(record.weight, false)} kg`
                    : "-"}
                </span>
              )}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns
                  .lastDailyWeight
              }
              dataIndex="lastDailyWeight"
              key="lastDailyWeight"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.lastDailyWeight - second.lastDailyWeight;
              }}
              {...getColumnSearchProps("lastDailyWeight")}
              render={(text, record) => (
                <span>
                  {record.lastDailyWeight !== null
                    ? `${numberMask(record.lastDailyWeight, false)} kg`
                    : "-"}
                </span>
              )}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns
                  .averageWeightGain
              }
              dataIndex="averageWeightGain"
              key="averageWeightGain"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.averageWeightGain - second.averageWeightGain;
              }}
              render={(text, record) => (
                <span>
                  {record.averageWeightGain !== null
                    ? `${numberMask(record.averageWeightGain, false)} kg/dia`
                    : "-"}
                </span>
              )}
              {...getColumnSearchProps("averageWeightGain")}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns
                  .aggregateArroba
              }
              dataIndex="aggregateArroba"
              key="aggregateArroba"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.aggregateArroba - second.aggregateArroba;
              }}
              render={(text, record) => (
                <span>
                  {record.aggregateArroba !== null
                    ? `${numberMask(record.aggregateArroba, false)} @`
                    : "-"}
                </span>
              )}
              {...getColumnSearchProps("aggregateArroba")}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns.saleIncome
              }
              dataIndex="saleIncome"
              key="saleIncome"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.saleIncome - second.saleIncome;
              }}
              render={(text, record) => (
                <span>
                  {record.saleIncome !== null
                    ? `${numberMask(record.saleIncome, true)}`
                    : "-"}
                </span>
              )}
              {...getColumnSearchProps("saleIncome")}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns
                  .aggregateIncome
              }
              dataIndex="aggregateIncome"
              key="aggregateIncome"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.aggregateIncome - second.aggregateIncome;
              }}
              render={(text, record) => (
                <span>
                  {record.aggregateIncome !== null
                    ? `${numberMask(record.aggregateIncome, true)}`
                    : "-"}
                </span>
              )}
              {...getColumnSearchProps("aggregateIncome")}
            />
            <Table.Column
              title={translation.boitelSaleScenario.details.table.columns.cost}
              dataIndex="cost"
              key="cost"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.cost - second.cost;
              }}
              render={(text, record) => (
                <span>
                  {record.cost !== null
                    ? `${numberMask(record.cost, true)}`
                    : "-"}
                </span>
              )}
              {...getColumnSearchProps("cost")}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns
                  .aggregateMargin
              }
              dataIndex="aggregateMargin"
              key="aggregateMargin"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.aggregateMargin - second.aggregateMargin;
              }}
              render={(text, record) => (
                <span>
                  {record.aggregateMargin !== null
                    ? `${numberMask(record.aggregateMargin, true)}`
                    : "-"}
                </span>
              )}
              {...getColumnSearchProps("aggregateMargin")}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns.margin
              }
              dataIndex="marginPercentage"
              key="marginPercentage"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.marginPercentage - second.marginPercentage;
              }}
              render={(text, record) => (
                <span>
                  {record.marginPercentage !== null
                    ? `${numberMask(record.marginPercentage * 100, false)} %`
                    : "-"}
                </span>
              )}
              {...getColumnSearchProps("margin")}
            />
          </Table>
        </Card>
      </Spin>
    </Container>
  );
}

export default BoitelSaleScenarioDetailsSalesInfo;
