import React, { useMemo } from "react";

// Components
import { TiArrowDownThick, TiArrowUpThick } from "react-icons/ti";
import {
  GdpModal,
  ContainerGdpModal,
  BodyCardGDPModal,
  SubTitleGDPModal,
  TitleGDPModal,
  BodyFooterGDPModal,
} from "./styles";
import ButtonStandard from "../../../components/utils/button";
import InfoTooltip from "../../../components/utils/infoTooltip";
import BullGdpExcededIcon from "../../../components/utils/icons/bullGdp/exceded";
import BullGdpInsufficientIcon from "../../../components/utils/icons/bullGdp/insufficient";
import BullGdpErrorIcon from "../../../components/utils/icons/bullGdp/error";

// Services
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../services/helpersMethodsService";
import { Col, Row } from "antd";
import BullGdpOkIcon from "../../../components/utils/icons/bullGdp/ok";

const GdpModalValidation = ({
  form,
  gdpValidationReturn,
  modalGdpValidationVisible,
  ignoreGdpAlertAndSave,
  closeModalGdpValidation,
}) => {
  const TitleHeader = useMemo(() => {
    switch (gdpValidationReturn?.code) {
      case "2.1":
        return (
          <>
            {gdpValidationReturn?.cpExcededOrMissing === "Missing" ||
            gdpValidationReturn?.tndExcededOrMissing === "Missing" ? (
              <BullGdpInsufficientIcon />
            ) : gdpValidationReturn?.cpExcededOrMissing === "Exceeded" ||
              gdpValidationReturn?.tdnExcededOrMissing === "Exceeded" ? (
              <BullGdpExcededIcon />
            ) : (
              <BullGdpErrorIcon />
            )}
            <TitleGDPModal status="ERROR">
              {`A recomendação mín. da dieta entrega GDP superior à ${numberMask(
                getTwoDecimalDigits(form?.gdpExpectation),
                false
              )} Kg`}
            </TitleGDPModal>
          </>
        );
      case "2.2":
      case "4.1":
      case "4.2":
        return (
          <>
            {gdpValidationReturn?.cpExcededOrMissing === "Missing" ||
            gdpValidationReturn?.tndExcededOrMissing === "Missing" ? (
              <BullGdpInsufficientIcon />
            ) : gdpValidationReturn?.cpExcededOrMissing === "Exceeded" ||
              gdpValidationReturn?.tdnExcededOrMissing === "Exceeded" ? (
              <BullGdpExcededIcon />
            ) : (
              <BullGdpOkIcon />
            )}
            <TitleGDPModal status="OK">
              Ok! GDP compatível com a dieta informada
            </TitleGDPModal>
          </>
        );
      case "2.4":
      case "2.3":
      case "4.3":
      case "4.4":
        return (
          <>
            {gdpValidationReturn?.cpExcededOrMissing === "Missing" ||
            gdpValidationReturn?.tndExcededOrMissing === "Missing" ? (
              <BullGdpInsufficientIcon />
            ) : gdpValidationReturn?.cpExcededOrMissing === "Exceeded" ||
              gdpValidationReturn?.tdnExcededOrMissing === "Exceeded" ? (
              <BullGdpExcededIcon />
            ) : (
              <BullGdpErrorIcon />
            )}
            <TitleGDPModal status="ERROR">
              {`GDP esperado ${numberMask(
                getTwoDecimalDigits(form?.gdpExpectation),
                false
              )} Kg não compatível com a dieta informada.`}
            </TitleGDPModal>
          </>
        );
      default:
        return (
          <>
            <BullGdpOkIcon />
            <TitleGDPModal status="OK">
              Ok! GDP compatível com a dieta informada
            </TitleGDPModal>
          </>
        );
    }
  }, [form, gdpValidationReturn]);
  const SubTitleHeader = useMemo(() => {
    switch (gdpValidationReturn?.code) {
      case "2.2":
        if (
          form?.productionSubSystem === "Extensive" ||
          form?.productionSubSystem === "SemiIntensive"
        ) {
          return (
            <SubTitleGDPModal>
              <span>
                {`A Dieta para este `}
                <span className="black">
                  {`GDP  `}
                  <strong>
                    {`${getTwoDecimalDigits(form?.gdpExpectation || 0)} Kg`}
                  </strong>
                </span>
                {` não prevê consumo de capim(fibra).`}
              </span>
            </SubTitleGDPModal>
          );
        } else {
          return (
            <SubTitleGDPModal>
              <span>
                {`A Dieta para este `}
                <span className="black">
                  {`GDP `}
                  <strong>
                    {`${getTwoDecimalDigits(form?.gdpExpectation || 0)} Kg`}
                  </strong>
                </span>
                {` consome menos que o informado.`}
              </span>
            </SubTitleGDPModal>
          );
        }
      case "2.3":
      case "2.4":
      case "4.3":
      case "4.4":
        return (
          <SubTitleGDPModal>
            <span>
              {`PB e/ou NDT insuficientes para `}
              <span className="black">
                {`GDP de `}
                <strong>
                  {`${getTwoDecimalDigits(form?.gdpExpectation || 0)} Kg`}
                </strong>
              </span>
            </span>
          </SubTitleGDPModal>
        );
      case "2.1":
        return (
          <SubTitleGDPModal>
            <span>
              {`A Dieta para este `}
              <span className="black">
                {`GDP  `}
                <strong>
                  {`${getTwoDecimalDigits(form?.gdpExpectation || 0)} Kg`}
                </strong>
              </span>
              {` não prevê consumo de capim(fibra).`}
            </span>
          </SubTitleGDPModal>
        );
      case "4.1":
        return (
          <SubTitleGDPModal>
            <span>
              {`A Dieta para este `}
              <span className="black">
                {`GDP `}
                <strong>
                  {`${getTwoDecimalDigits(form?.gdpExpectation || 0)} Kg`}
                </strong>
              </span>
              {` prevê consumo de capim inferior.`}
            </span>
          </SubTitleGDPModal>
        );
      default:
        return <SubTitleGDPModal />;
    }
  }, [form, gdpValidationReturn]);
  const Footer = useMemo(() => {
    switch (gdpValidationReturn?.code) {
      case "2.1":
      case "2.4":
      case "2.3":
      case "4.3":
      case "4.4":
        return (
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="footer"
          >
            <ButtonStandard
              type="button"
              buttonType="typeWithoutBackground"
              padding="5px 0px"
              color="#d44c4c"
              isUpperCase={false}
              onClick={ignoreGdpAlertAndSave}
            >
              Manter dados informados
            </ButtonStandard>
            <ButtonStandard
              type="submit"
              buttonType="typeWithoutBackground"
              padding="5px 0px"
              color="#684E94"
              isUpperCase={false}
              onClick={closeModalGdpValidation}
            >
              Alterar dieta
            </ButtonStandard>
          </Row>
        );
      default:
        return (
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="footer"
          >
            <ButtonStandard
              type="button"
              buttonType="typeWithoutBackground"
              padding="5px 0px"
              color="#d44c4c"
              isUpperCase={false}
              onClick={closeModalGdpValidation}
            >
              Alterar dieta
            </ButtonStandard>
            <ButtonStandard
              type="submit"
              buttonType="typeWithoutBackground"
              padding="5px 0px"
              color="#684E94"
              isUpperCase={false}
              onClick={ignoreGdpAlertAndSave}
            >
              Ok, manter dados e salvar dieta
            </ButtonStandard>
          </Row>
        );
    }
  }, [gdpValidationReturn, closeModalGdpValidation, ignoreGdpAlertAndSave]);
  return (
    <GdpModal
      width={700}
      visible={modalGdpValidationVisible}
      centered
      maskClosable={false}
      footer={null}
      closable={false}
    >
      <ContainerGdpModal>
        {/* Header */}
        {/* Title */}
        <Row type="flex">
          <Col span={24}>
            <div className="colTitle">{TitleHeader}</div>
          </Col>
        </Row>
        {/* SubTitle */}
        <Row type="flex">
          <Col span={24}>{SubTitleHeader}</Col>
        </Row>
        {/* Body - Start */}
        {/* Main */}
        <Row type="flex">
          <Col span={24}>
            <BodyCardGDPModal>
              <Row className="rowGroupValues">
                {/* GDP Possivel */}
                <Col>
                  <div className="groupValues">
                    <label>GDP Possível</label>
                    <div className="realWeightGain">
                      <label className="value realWeightGain">
                        {`${getTwoDecimalDigits(
                          gdpValidationReturn?.weightGain || 0
                        )}`}
                        <br />
                        {`Kg/Dia`}
                      </label>
                      <InfoTooltip title="Este valor corresponde ao valor máximo de ganho de peso que um animal obterá diariamente utilizando a dieta informada" />
                    </div>
                  </div>
                </Col>
                <div className="rowGroupValuesMinerals">
                  {/* PB Values */}
                  <Col>
                    <div className="groupValues">
                      <label>{`PB ${
                        gdpValidationReturn?.cpExcededOrMissing === "Missing"
                          ? "Faltante"
                          : gdpValidationReturn?.cpExcededOrMissing ===
                            "Exceeded"
                          ? "Excedido"
                          : ""
                      }`}</label>
                      <label className="value strong">
                        {gdpValidationReturn?.cpExcededOrMissing ===
                        "Missing" ? (
                          <TiArrowDownThick id="iconExOrMiss" color="#d44c4c" />
                        ) : gdpValidationReturn?.cpExcededOrMissing ===
                          "Exceeded" ? (
                          <TiArrowUpThick id="iconExOrMiss" color="#27ff00" />
                        ) : null}
                        {`${numberMask(
                          getTwoDecimalDigits(
                            gdpValidationReturn?.cpExcededOrMissingValue || 0
                          ),
                          false
                        )} g`}
                      </label>
                      <label className="value necessary">
                        {`Necessário: ${numberMask(
                          getTwoDecimalDigits(
                            gdpValidationReturn?.necessary.PB || 0
                          ),
                          false
                        )} g *`}
                      </label>
                      <label className="value consumed">
                        {`Consumido: ${numberMask(
                          getTwoDecimalDigits(
                            gdpValidationReturn?.consumed.PB || 0
                          ),
                          false
                        )} g`}
                      </label>
                    </div>
                  </Col>
                  {/* NDT Values */}
                  <Col>
                    <div className="groupValues">
                      <label>{`NDT ${
                        gdpValidationReturn?.tdnExcededOrMissing === "Missing"
                          ? "Faltante"
                          : gdpValidationReturn?.tdnExcededOrMissing ===
                            "Exceeded"
                          ? "Excedido"
                          : ""
                      }`}</label>
                      <label className="value strong">
                        {gdpValidationReturn?.tdnExcededOrMissing ===
                        "Missing" ? (
                          <TiArrowDownThick id="iconExOrMiss" color="#d44c4c" />
                        ) : gdpValidationReturn?.tdnExcededOrMissing ===
                          "Exceeded" ? (
                          <TiArrowUpThick id="iconExOrMiss" color="#27ff00" />
                        ) : null}
                        {`${numberMask(
                          getTwoDecimalDigits(
                            gdpValidationReturn?.tdnExcededOrMissingValue || 0
                          ),
                          false
                        )} g`}
                      </label>
                      <label className="value necessary">
                        {`Necessário: ${numberMask(
                          getTwoDecimalDigits(
                            gdpValidationReturn?.necessary.NDT || 0
                          ),
                          false
                        )} g *`}
                      </label>
                      <label className="value consumed">
                        {`Consumido: ${numberMask(
                          getTwoDecimalDigits(
                            gdpValidationReturn?.consumed.NDT || 0
                          ),
                          false
                        )} g`}
                      </label>
                    </div>
                  </Col>
                  {/* Ca Values */}
                  <Col>
                    <div className="groupValues">
                      <label>Ca</label>
                      <label className="value strong">
                        {gdpValidationReturn?.minerals.Ca.Code === 1 ? (
                          <TiArrowDownThick id="iconExOrMiss" color="#d44c4c" />
                        ) : gdpValidationReturn?.minerals.Ca.Code === 2 ? (
                          <TiArrowUpThick id="iconExOrMiss" color="#27ff00" />
                        ) : null}
                        {`${numberMask(
                          getTwoDecimalDigits(
                            Math.abs(
                              gdpValidationReturn?.minerals.Ca.Consumed -
                                gdpValidationReturn?.minerals.Ca.Necessary
                            )
                          ),
                          false
                        )} g`}
                      </label>
                      <label className="value necessary">
                        {`Necessário: ${numberMask(
                          getTwoDecimalDigits(
                            gdpValidationReturn?.minerals.Ca.Necessary || 0
                          ),
                          false
                        )} g **`}
                      </label>
                      <label className="value consumed">
                        {`Consumido: ${numberMask(
                          getTwoDecimalDigits(
                            gdpValidationReturn?.minerals.Ca.Consumed || 0
                          ),
                          false
                        )} g`}
                      </label>
                    </div>
                  </Col>
                  {/* P Values */}
                  <Col>
                    <div className="groupValues">
                      <label>P</label>
                      <label className="value strong">
                        {gdpValidationReturn?.minerals?.P?.Code === 1 ? (
                          <TiArrowDownThick id="iconExOrMiss" color="#d44c4c" />
                        ) : gdpValidationReturn?.minerals?.P?.Code === 2 ? (
                          <TiArrowUpThick id="iconExOrMiss" color="#27ff00" />
                        ) : null}
                        {`${numberMask(
                          getTwoDecimalDigits(
                            Math.abs(
                              gdpValidationReturn?.minerals.P?.Consumed -
                                gdpValidationReturn?.minerals.P?.Necessary
                            )
                          ),
                          false
                        )} g`}
                      </label>
                      <label className="value necessary">
                        {`Necessário: ${numberMask(
                          getTwoDecimalDigits(
                            gdpValidationReturn?.minerals?.P?.Necessary || 0
                          ),
                          false
                        )} g **`}
                      </label>
                      <label className="value consumed">
                        {`Consumido: ${numberMask(
                          getTwoDecimalDigits(
                            gdpValidationReturn?.minerals?.P?.Consumed || 0
                          ),
                          false
                        )} g`}
                      </label>
                    </div>
                  </Col>
                  {/* Zn Values */}
                  <Col>
                    <div className="groupValues">
                      <label>Zn</label>
                      <label className="value strong">
                        {gdpValidationReturn?.minerals?.Zn?.Code === 1 ? (
                          <TiArrowDownThick id="iconExOrMiss" color="#d44c4c" />
                        ) : gdpValidationReturn?.minerals?.Zn?.Code === 2 ? (
                          <TiArrowUpThick id="iconExOrMiss" color="#27ff00" />
                        ) : null}
                        {`${numberMask(
                          getTwoDecimalDigits(
                            Math.abs(
                              gdpValidationReturn?.minerals.Zn?.Consumed -
                                gdpValidationReturn?.minerals.Zn?.Necessary
                            )
                          ),
                          false
                        )} mg`}
                      </label>
                      <label className="value necessary">
                        {`Necessário: ${numberMask(
                          getTwoDecimalDigits(
                            gdpValidationReturn?.minerals?.Zn?.Necessary || 0
                          ),
                          false
                        )} mg **`}
                      </label>
                      <label className="value consumed">
                        {`Consumido: ${numberMask(
                          getTwoDecimalDigits(
                            gdpValidationReturn?.minerals?.Zn?.Consumed || 0
                          ),
                          false
                        )} mg`}
                      </label>
                    </div>
                  </Col>
                  {/* Na Values */}
                  <Col>
                    <div className="groupValues">
                      <label>Na</label>
                      <label className="value strong">
                        {gdpValidationReturn?.minerals?.Na?.Code === 1 ? (
                          <TiArrowDownThick id="iconExOrMiss" color="#d44c4c" />
                        ) : gdpValidationReturn?.minerals?.Na?.Code === 2 ? (
                          <TiArrowUpThick id="iconExOrMiss" color="#27ff00" />
                        ) : null}
                        {`${numberMask(
                          getTwoDecimalDigits(
                            Math.abs(
                              gdpValidationReturn?.minerals.Na?.Consumed -
                                gdpValidationReturn?.minerals.Na?.Necessary
                            )
                          ),
                          false
                        )} g`}
                      </label>
                      <label className="value necessary">
                        {`Necessário: ${numberMask(
                          getTwoDecimalDigits(
                            gdpValidationReturn?.minerals?.Na?.Necessary || 0
                          ),
                          false
                        )} g **`}
                      </label>
                      <label className="value consumed">
                        {`Consumido: ${numberMask(
                          getTwoDecimalDigits(
                            gdpValidationReturn?.minerals?.Na?.Consumed || 0
                          ),
                          false
                        )} g`}
                      </label>
                    </div>
                  </Col>
                </div>
              </Row>
            </BodyCardGDPModal>
          </Col>
        </Row>
        <Row type="flex">
          <p className="messageAboutGdp">
            <strong>* Necessidade para o GDP esperado.</strong>
          </p>
        </Row>
        <Row type="flex">
          <p className="messageAboutGdp">
            <strong>** Necessidade para o GDP possível.</strong>
          </p>
        </Row>
        {/* Main Footer */}
        {/* Carbon Footprint */}
        {(gdpValidationReturn?.balanceCarbonFootprint != null ||
          gdpValidationReturn?.balancePriceCarbonFootprint != null) && (
          <Row type="flex">
            <BodyFooterGDPModal>
              <Row type="flex" justify="start" gutter={8}>
                <Col span={10} className="colTitle">
                  <label>Pegada Carbônica</label>
                </Col>
                <Col span={14}>
                  <Row type="flex" justify="space-between">
                    <div>
                      <label>Emitido:</label>
                      <strong>
                        {`${numberMask(
                          gdpValidationReturn?.balanceCarbonFootprint || 0,
                          false
                        )} `}
                        kg CO<sub>2</sub>E / kg GDP
                      </strong>
                    </div>
                  </Row>
                  <Row type="flex" justify="space-between">
                    <div>
                      <label>Saldo:</label>
                      <strong>
                        {`${numberMask(
                          gdpValidationReturn?.balancePriceCarbonFootprint || 0,
                          true
                        )} `}
                        kg GDP
                      </strong>
                    </div>
                  </Row>
                </Col>
              </Row>
            </BodyFooterGDPModal>
          </Row>
        )}
        {/* Supplement infos */}
        {form?.supplement != null && (
          <Row type="flex">
            <BodyFooterGDPModal>
              <Row type="flex" justify="start" gutter={8}>
                <Col span={10} className="colTitle">
                  <label>Qtd. de Suplemento/Concent./Ração</label>
                </Col>
                <Col span={14}>
                  <Row type="flex" justify="space-between">
                    <div>
                      <label>Necessidade:</label>
                      <strong>{`${numberMask(
                        getTwoDecimalDigits(
                          gdpValidationReturn?.trough.Need || 0
                        ),
                        false
                      )} Kg`}</strong>
                    </div>
                    <div>
                      <label>Mínimo:</label>
                      <strong>{`${numberMask(
                        getTwoDecimalDigits(
                          gdpValidationReturn?.trough.MinConsumption || 0
                        ),
                        false
                      )} Kg`}</strong>
                    </div>
                  </Row>
                  <Row type="flex" justify="space-between">
                    <div>
                      <label>Consumo:</label>
                      <strong>{`${numberMask(
                        getTwoDecimalDigits(
                          gdpValidationReturn?.trough.Consumed || 0
                        ),
                        false
                      )} Kg`}</strong>
                    </div>
                    <div>
                      <label>Máximo:</label>
                      <strong>{`${numberMask(
                        getTwoDecimalDigits(
                          gdpValidationReturn?.trough.MaxConsumption || 0
                        ),
                        false
                      )} Kg`}</strong>
                    </div>
                  </Row>
                </Col>
              </Row>
            </BodyFooterGDPModal>
          </Row>
        )}
        {/* Pasture infos */}
        {form?.pasture != null && (
          <Row type="flex">
            <BodyFooterGDPModal>
              <Row type="flex" justify="start" gutter={8}>
                <Col span={10} className="colTitle">
                  <label>Qtd. de Capim</label>
                </Col>
                <Col span={14}>
                  <Row type="flex" justify="space-between">
                    <div>
                      <label>Necessidade:</label>
                      <strong>{`${numberMask(
                        getTwoDecimalDigits(
                          gdpValidationReturn?.pasture.Need || 0
                        ),
                        false
                      )} Kg`}</strong>
                    </div>
                    <div>
                      <label>Mínimo:</label>
                      <strong>{`${numberMask(
                        getTwoDecimalDigits(
                          gdpValidationReturn?.pasture.MinConsumption || 0
                        ),
                        false
                      )} Kg`}</strong>
                    </div>
                  </Row>
                  <Row type="flex" justify="space-between">
                    <div>
                      <label>Consumo:</label>
                      <strong>{`${numberMask(
                        getTwoDecimalDigits(
                          gdpValidationReturn?.pasture.Consumed || 0
                        ),
                        false
                      )} Kg`}</strong>
                    </div>
                    <div>
                      <label>Máximo:</label>
                      <strong>{`${numberMask(
                        getTwoDecimalDigits(
                          gdpValidationReturn?.pasture.MaxConsumption || 0
                        ),
                        false
                      )} Kg`}</strong>
                    </div>
                  </Row>
                </Col>
              </Row>
            </BodyFooterGDPModal>
          </Row>
        )}
        {/* Body - End */}
        {/* Footer - Buttons */}
        {Footer}
      </ContainerGdpModal>
    </GdpModal>
  );
};

export default GdpModalValidation;
