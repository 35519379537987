import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as SupplierGroupActions } from "../../../../store/ducks/supplierGroup";

// Components
import { Container, Footer } from "./styles";
import { Row, Col, Select, Input, Divider, notification, Spin } from "antd";
import ButtonStandard from "../../../../components/utils/button";

// Services
import { validateEmail } from "../../../../services/helpersMethodsService";
import { saveInvite } from "../../../../services/supplierInvitesService";

const DrawerInviteSupplierGroup = () => {
    // Redux Variable
    const {
        translation,
        user,
        groupSelected: { id: supplierGroupId, farms },
        farmSelected: { id: supplierGroupFarmId },
    } = useSelector((state) => state.app);

    const { drawerInviteVisible } = useSelector((state) => state.supplierGroup);
    const dispatch = useDispatch();

    const [form, setForm] = useState({
        fullName: null,
        email: null,
        userIdFrom: null,
        supplierGroupFarmIdTo: null,
        roleId: null,
        supplierGroupFarmTo: null,
    });
    const [errors, setErrors] = useState([]);
    const [loadRequest, setLoadRequest] = useState(false);

    // Methods
    function resetDrawer() {
        setErrors([]);
        setForm({
            fullName: null,
            email: null,
            userIdFrom: null,
            roleId: null,
            supplierGroupFarmTo: null,
        });
    }

    function closeDrawer() {
        resetDrawer();
        dispatch(SupplierGroupActions.hideDrawer());
    }

    async function saveForm() {
        if (validateForm()) {
            setLoadRequest(true);
            let body = { ...form, userIdFrom: user.id };
            body.email = body.email.toLowerCase();
            if (body.roleId === "2e68dbdc-72ef-464e-a317-9954a1ab4782")
                body = { ...body, supplierGroupFarmTo: null };
            try {
                await saveInvite({
                    supplierGroupId,
                    supplierGroupFarmId,
                    body,
                });
                notification.success({
                    message: "Convite cadastrado com sucesso",
                });

                setLoadRequest(false);
                closeDrawer();
            } catch (error) {
                notification.error({
                    message: "Houve um erro ao cadastrar o convite",
                });
                setLoadRequest(false);
            }
        }
    }

    function validateForm() {
        let aErrors = [];

        if (form.fullName === null || form.fullName.trim() === "") {
            aErrors.push("fullName");
        }

        if (form.email === null || form.email.trim() === "") {
            aErrors.push("email");
        }

        if (
            form.email !== null &&
            form.email.trim() !== "" &&
            !validateEmail(form.email)
        ) {
            aErrors.push("email");
        }

        if (form.roleId === null) { aErrors.push("roleId") };
        if (
            form.roleId !== null &&
            form.roleId === "0da018bb-ef89-4d6a-8d78-a8540770b540" &&
            form.supplierGroupFarmTo === null
        )
            aErrors.push("supplierGroupFarmTo");
        setErrors(aErrors);
        return aErrors.length > 0 ? false : true;
    }

    async function handleDrawerVisible(visible) {
    }

    return (
        <Container
            title="Convidar"
            width={700}
            onClose={closeDrawer}
            maskClosable={false}
            visible={drawerInviteVisible}
            afterVisibleChange={handleDrawerVisible}
        >
            <div className="drawerForm">
                <Spin spinning={loadRequest}>
                    <Row type="flex">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <span className="subTitle">
                                Preencha os campos abaixo para convidar um colaborador
                            </span>
                        </Col>
                    </Row>
                    {errors.length > 0 && (
                        <Row type="flex" justify="center" align="middle">
                            <label className="error">{translation.error.formError}</label>
                        </Row>
                    )}

                    {/* name */}
                    <Row type="flex" className="rowLabel">
                        <label className={errors.includes("fullName") ? "error" : ""}>
                            Nome*
                        </label>
                    </Row>
                    <Row type="flex">
                        <Input
                            name="fullName"
                            value={form?.fullName != null ? form?.fullName : undefined}
                            type="fullName"
                            placeholder={translation.defaultPlaceholder}
                            autoCapitalize="off"
                            onChange={(e) => {
                                setForm({ ...form, fullName: e.target.value });
                                setErrors(errors.filter((e) => e !== "fullName"));
                            }}
                        />
                    </Row>

                    {/* email */}
                    <Row type="flex" className="rowLabel">
                        <label className={errors.includes("email") ? "error" : ""}>
                            E-mail*
                        </label>
                    </Row>
                    <Row type="flex">
                        <Input
                            name="email"
                            value={form?.email != null ? form?.email : undefined}
                            type="email"
                            placeholder={translation.defaultPlaceholder}
                            autoCapitalize="off"
                            onChange={(e) => {
                                setForm({ ...form, email: e.target.value.toLowerCase() });
                                setErrors(errors.filter((e) => e !== "email"));
                            }}
                        />
                    </Row>

                    {/* role */}
                    <Row type="flex" className="rowLabel">
                        <label className={errors.includes("roleId") ? "error" : ""}>
                            Perfil de acesso*
                        </label>
                    </Row>
                    <Row type="flex">
                        <Select
                            name="roleId"
                            value={form?.roleId != null ? form?.roleId : undefined}
                            placeholder={translation.defaultSelectPlaceholder}
                            onChange={(value) => {
                                setForm({ ...form, roleId: value });
                                setErrors(errors.filter((e) => e !== "roleId"));
                            }}
                        >
                            <Select.Option value="2e68dbdc-72ef-464e-a317-9954a1ab4782">
                                Responsável
                            </Select.Option>
                            <Select.Option value="0da018bb-ef89-4d6a-8d78-a8540770b540">
                                Colaborador
                            </Select.Option>
                        </Select>
                    </Row>
                    {form?.roleId !== null && (
                        form?.roleId === "0da018bb-ef89-4d6a-8d78-a8540770b540" && (
                            <>
                                <Divider dashed />
                                <span className="farmMessage">
                                    Selecione a empresa a ser gerenciada por este usuário
                                </span>
                                <Row type="flex" className="rowLabel">
                                    <label className={errors.includes("supplierGroupFarmTo") ? "error" : ""}>
                                        Empresa(s)*
                                    </label>
                                </Row>
                                <Row type="flex">
                                    <Select
                                        mode="single"
                                        name="supplierGroupFarmTo"
                                        value={form?.supplierGroupFarmTo != null ? form?.supplierGroupFarmTo : undefined}
                                        placeholder={translation.defaultSelectPlaceholder}
                                        onChange={(values) => {
                                            setForm({ ...form, supplierGroupFarmTo: values });
                                            setErrors(errors.filter((e) => e !== "supplierGroupFarmTo"));
                                        }}
                                    >
                                        {farms.length > 0 &&
                                            farms.map((f) => (
                                                <Select.Option key={f.id} value={f.id}>
                                                    {f.name}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Row>
                            </>
                        ))
                    }
                </Spin>
            </div>
            <Footer>
                <Row type="flex">
                    <Col span={24} className="buttonsDiv">
                        <ButtonStandard
                            type="button"
                            buttonType="type7"
                            onClick={closeDrawer}
                        >
                            {translation.buttons.cancel}
                        </ButtonStandard>

                        <ButtonStandard type="button" buttonType="type6" onClick={saveForm}>
                            {translation.buttons.save}
                        </ButtonStandard>
                    </Col>
                </Row>
            </Footer>
        </Container>
    );
};

export default DrawerInviteSupplierGroup;
