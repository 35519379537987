export const INITIAL_DATA_FORM_ANIMAL_VACCINATION = {
  id: null,
  handlingNumber: null,
  animalId: null,
  veterinarySupplementId: null,
  applicationQuantity: null,
  applicationDay: null,
  saveOptions: "save_and_add_new",
};
export const Types = {
  RESET_DATA: "vacinations/RESET_DATA",
  GET_ALL_FARM_VACINATIONS: "vacinations/GET_ALL_FARM_VACINATIONS",
  GET_ALL_FARM_VACINATIONS_SUCCESS:
    "vacinations/GET_ALL_FARM_VACINATIONS_SUCCESS",
  GET_ALL_FARM_VACINATIONS_ERROR: "vacinations/GET_ALL_FARM_VACINATIONS_ERROR",
  OPEN_DRAWER_FORM: "vacinations/OPEN_DRAWER_FORM",
  CLOSE_DRAWER_FORM: "vacinations/CLOSE_DRAWER_FORM",
};

export const Creators = {
  index: (
    groupId,
    farmId,
    page = 0,
    size = 10,
    sort = {},
    search = null,
    tableFilters = {},
    tableSorters = {}
  ) => ({
    type: Types.GET_ALL_FARM_VACINATIONS,
    payload: {
      groupId,
      farmId,
      page,
      size,
      sort,
      search,
      tableFilters,
      tableSorters,
    },
  }),
  indexSuccess: (data) => ({
    type: Types.GET_ALL_FARM_VACINATIONS_SUCCESS,
    payload: {
      data,
    },
  }),
  indexError: (error) => ({
    type: Types.GET_ALL_FARM_VACINATIONS_ERROR,
    payload: {
      error,
    },
  }),
  openDrawerForm: (data = null) => ({
    type: Types.OPEN_DRAWER_FORM,
    payload: {
      data,
    },
  }),
  closeDrawerForm: () => ({
    type: Types.CLOSE_DRAWER_FORM,
    payload: {},
  }),
};

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  tableProperties: {
    size: 10,
    page: 0,
    tableFilters: {},
    tableSorters: {},
    search: null,
  },
  isDrawerFormVisible: false,
  formData: INITIAL_DATA_FORM_ANIMAL_VACCINATION,
  error: {},
};

export default function vacinations(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_ALL_FARM_VACINATIONS:
      return {
        ...state,
        isLoading: true,
        tableProperties: {
          page: action.payload.page,
          size: action.payload.size,
          tableFilters: action.payload.tableFilters,
          tableSorters: action.payload.tableSorters,
        },
      };
    case Types.GET_ALL_FARM_VACINATIONS_SUCCESS:
      const { data: vacinations } = action.payload;
      return { ...state, isLoading: false, data: vacinations };
    case Types.GET_ALL_FARM_VACINATIONS_ERROR:
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: null,
        error,
      };
    case Types.OPEN_DRAWER_FORM:
      return {
        ...state,
        formData: action.payload.data ?? INITIAL_STATE.formData,
        isDrawerFormVisible: true,
      };
    case Types.CLOSE_DRAWER_FORM:
      return {
        ...state,
        formData: INITIAL_STATE.formData,
        isDrawerFormVisible: false,
      };
    default:
      return state;
  }
}
