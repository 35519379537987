import styled from "styled-components";

export const CardCustom = styled.div`
  padding-top: 14px;
  overflow: hidden;

  & > .rowCard {
    margin-top: none;
  }
  & > .rowCard ~ .rowCard {
    margin-top: 16px;
  }

  .rowHeader {
    margin-bottom: 16px;
  }

  .chartMessage {
    font-family: "Asap", sans-serif;
    font-style: italic;
    font-size: 12px;
    color: #4b4b4b;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;
