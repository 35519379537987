import React from "react";

// import { Container } from './styles';

const ArrowInactiveIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.5"
    height="17.5"
    viewBox="0 0 17.5 17.5"
  >
    <g id="left-arrow" transform="translate(16.75 16.75) rotate(180)">
      <g id="Group_618" data-name="Group 618">
        <path
          id="Path_929"
          data-name="Path 929"
          d="M14.493,22.5A8,8,0,1,0,6.5,14.493,8.014,8.014,0,0,0,14.493,22.5Z"
          transform="translate(-6.5 -6.5)"
          fill="none"
          stroke="#4a85ae"
          strokeWidth="1.5"
        />
        <path
          id="Path_930"
          data-name="Path 930"
          d="M46.362,41.631a.6.6,0,0,0,.416.159.626.626,0,0,0,.416-.159.515.515,0,0,0,0-.769l-3.374-3.117,3.374-3.117a.515.515,0,0,0,0-.769.624.624,0,0,0-.833,0l-3.79,3.5a.515.515,0,0,0,0,.769Z"
          transform="translate(-37.54 -29.795)"
          fill="#468cb3"
          stroke="#468cb3"
          strokeWidth="0.5"
        />
      </g>
    </g>
  </svg>
);
export default ArrowInactiveIcon;
