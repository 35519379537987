import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Link } from "react-router-dom";
import { Formik } from "formik";
import { notification, Alert } from "antd";

import * as Yup from "yup";

/* Styles */
import {
  Wrapper,
  Container,
  RegisterContainer,
  FormContainer,
  DisplayContainer,
  DisplayBody,
  DisplayBodyMessage,
  LogoContainer,
  ButtonCustom,
} from "./styles";

/* Components */
import InputStandard from "../../components/utils/input";
import ArrowIcon from "../../components/utils/icons/arrows/red/left";
import DrawerChangePassword from "../../components/drawers/changePassword";

/* Images */
import LogoVertical from "../../components/login/logos/logoVertical";

/* Redux Actions */
import { Creators as UserActions } from "../../store/ducks/user";
import { verifyExistsUserByEmail } from "../../services/changePasswordService";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      form: {
        email: "",
        id: "",
      },
      verifyEmail: false,
      errorEmail: false,
    };
  }

  openCloseMenu = () => this.setState({ menuOpen: !this.state.menuOpen });

  openDrawer = () => {
    const { userActions } = this.props;
    userActions.showDrawerChangePassword();
  };

  handleGenerateCode = async (values, actions) => {
    const { email } = values;

    try {
      const res = await verifyExistsUserByEmail(email);
      this.setState({
        form: {
          email,
          id: res.data.results,
        },
      });
      notification.success({
        message: "O código de verificação foi enviado no e-mail informado",
      });
      this.openDrawer();
    } catch (e) {
      if (e.response.status === 404) {
        this.setState({
          errorEmail: true,
        });
      } else {
        notification.error({
          message: "Algo deu errado, favor tentar mais tarde",
        });
      }
    }
  };

  render() {
    const { form, errorEmail } = this.state;
    const validationSchema = Yup.object().shape({
      email: Yup.string()
        .email("Digite um e-mail válido")
        .required("É preciso informar um e-mail"),
    });

    return (
      <Wrapper>
        <span className="logo-vertical">
          <LogoVertical />
        </span>
        <Container>
          <RegisterContainer>
            <LogoContainer>
              <LogoVertical />
            </LogoContainer>
            <DisplayBodyMessage>
              <span>
                Decisões que <br />
                engordam <strong>o bolso</strong>
              </span>
            </DisplayBodyMessage>
          </RegisterContainer>
          <DisplayContainer>
            <DisplayBody>
              <FormContainer>
                <Link className="back-container" to="/">
                  <ArrowIcon />
                  <span className="back">Voltar</span>
                </Link>
                <span className="title">Resgatar minha senha</span>
                <span className="subTitle">
                  Informe o seu e-mail de cadastro abaixo
                </span>
                <Formik
                  initialValues={form}
                  onSubmit={this.handleGenerateCode}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    ...props
                  }) => (
                    <form onSubmit={handleSubmit} autoComplete="off">
                      <label>E-mail de cadastro</label>
                      <InputStandard
                        className="input"
                        width="100%"
                        border={
                          !touched.email
                            ? "normal"
                            : errors.email
                            ? "error"
                            : "success"
                        }
                      >
                        <input
                          type="email"
                          value={values?.email}
                          placeholder="Informe aqui..."
                          autoCapitalize="off"
                          name="email"
                          onBlur={handleBlur}
                          onChange={(e) =>
                            props.setFieldValue(
                              "email",
                              e.target.value.toLowerCase()
                            )
                          }
                        />
                      </InputStandard>

                      <div className="formFooter">
                        <ButtonCustom type="submit">
                          Resgatar senha
                        </ButtonCustom>
                      </div>
                    </form>
                  )}
                </Formik>
              </FormContainer>
              <div className={errorEmail ? "" : "hide"}>
                <Alert
                  message="E-mail informado não cadastrado, favor informar um e-mail válido"
                  type="error"
                  showIcon
                  closable
                  style={{
                    width: 495,
                    marginTop: 35,
                  }}
                />
              </div>
            </DisplayBody>
          </DisplayContainer>
        </Container>
        <DrawerChangePassword formData={form} />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(UserActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
);
