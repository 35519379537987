import React, { useState } from "react";
import CanvasJSReact from "../../../../assets/canvasjs.react";
import { Container, Title } from "./styles";
import { Row, Col } from "antd";
import { useEffect } from "react";
const toggleDataSeries = (e) => {
  if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
    e.dataSeries.visible = false;
  } else {
    e.dataSeries.visible = true;
  }
  e.chart.render();
};
function LotAverageGdpDash({ externalData }) {
  // Variables
  const { CanvasJSChart } = CanvasJSReact;
  const [data, setData] = useState(null);
  const chartOptions = {
    animationEnabled: true,
    zoomEnabled: true,
    height: 200,
    axisX: {
      valueFormatString: "MM/YY",
      interval: 1,
      intervalType: "month",
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
      },
    },
    toolTip: {
      fontFamily: "Asap",
      shared: true,
    },
    axisY: {
      includeZero: true,
      suffix: " Kg",
      gridDashType: "dot",
    },
    legend: {
      cursor: "pointer",
      itemclick: toggleDataSeries,
    },
    data: data,
  };

  // Effects
  useEffect(() => {
    setData(externalData);
  }, [externalData]);

  return (
    <Container>
      <Row type="flex">
        <Col span={24}>
          <Title>Ganho de peso médio</Title>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <CanvasJSChart options={chartOptions} />
        </Col>
      </Row>
    </Container>
  );
}

export default LotAverageGdpDash;
