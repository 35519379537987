import apiNotifications from "../config/api_notifications";
import getHeaders from "./getHeaders";

export function getNotificationIndex(payload) {
  const {
    groupId,
    farmId,
    withoutPagination,
    justNotRead,
    page,
    sorter,
    filters,
    size,
    signal
  } = payload;

  if (withoutPagination === true) {
    return apiNotifications.get(
      `/bovexo/${groupId}/farms/${farmId}/notifications?withoutPagination=true${
        justNotRead != null ? `&justNotRead=${justNotRead}` : ""
      }`,
      getHeaders(false, signal)
    );
  } else {
    const { field, order } = sorter;
    const orderS = order === "descend" ? "DESC" : "ASC";
    return apiNotifications.get(
      `/bovexo/${groupId}/farms/${farmId}/notifications?
      ${page ? `&page=${page - 1}` : ""}
      ${size ? `&size=${size}` : ""}
      ${
        Object.entries(sorter).length > 0
          ? `&sort=${field}&direction=${orderS}`
          : ""
      }
      ${
        filters !== "" ? `&search=${filters}` : ""
      }&withoutPagination=${withoutPagination}
      ${justNotRead != null ? `&justNotRead=${justNotRead}` : ""}`,
      getHeaders(false, signal)
    );
  }
}

export function visualizeOneNotification(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiNotifications.post(
    `/bovexo/${groupId}/farms/${farmId}/visualize/notifications/${id}`,
    null,
    getHeaders(false, signal)
  );
}

export function visualizeAllNotifications(payload) {
  const { groupId, farmId, signal } = payload;

  return apiNotifications.post(
    `/bovexo/${groupId}/farms/${farmId}/visualize/notifications`,
    null,
    getHeaders(false, signal)
  );
}
