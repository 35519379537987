import React from "react";

function IconAlreadyAccept() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
    >
      <g
        id="Group_3305"
        data-name="Group 3305"
        transform="translate(-714 -256)"
      >
        <g
          id="Ellipse_170"
          data-name="Ellipse 170"
          transform="translate(714 256)"
          fill="#fb9e9e"
          stroke="#b54747"
          strokeWidth="1.5"
        >
          <circle cx="20.5" cy="20.5" r="20.5" stroke="none" />
          <circle cx="20.5" cy="20.5" r="19.75" fill="none" />
        </g>
        <image
          id="alerta_convite"
          data-name="alerta convite"
          width="23"
          height="23"
          transform="translate(723 264)"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAADq0lEQVR4nO2aW4hNURjHfzMuCaGYGDMjGpJR4sWoGUIzJaQJ5YEX5UEpER5cUmg8uLzJiwfl8jKljMgoZZohUrwR5TK5jacJuTQalxbf1LZmb+esy551prN/dTpnr7PXt77vv9da39pr75L2ujqKmdKijj4TIBMgEyAToAB8CEomQKB2jwKd8jkcyIc/hFoIqcDrI8fDgJ8hHAnVA+rzOGdQyOaAQO3eDtTuAEIJ8Dzy+3Ko8a8INQlOi/z+KJ8gmPaAMuAMUOno7KvIxzX40ZJWrXwyEWAscBfYArz2IIIPxst8sld8mmpq00SAfUB15Di0CJOBB8CCSNkFUyMmc0ClBB3lO1AO9OSoWyr1VwBTgElAFTACeAF0y7daIL3PY1KcCHRJr+xH+dYIPM03IMVwg3PfiNO6CHOAOwl1VJfcCew2cQo4AZwFHif8P9dH8ArTSbBfhM9y1RsTgh8l4/KtRfBInUfAcbnaOh3AWimzDl5hmwbV5FORcIWWANe0K+TKarGpUwP8sA1eYTIEosTl7hJgK3D6P/VagTYZv2rc9wLTgZnAUmBdQr2rwBHgoFaeNETyxtdCSAXfLN1e5z6wQ1JoPjSIrYUx56qUt9iHw/34WgqvTwheldcaBK+4KXWapIdEUXeRe/y4/BcfAqwBWmLK1bi95GC3VbLIJ638GLDMwe4/uAowQRyN0isTYNykZUqPTLhdWr22hOxgjKsA22PK5gNffDgXYYa22BoJ7PJh2EUAdWUOaWUqfz9x9CmJjVq5mnNmuxp1EWBDTNlJB3u5aIuZTLe5GrUVQKW9A1rZfldn8qBZO6VJNlStsRWgQpbEUU6lFHSUG9pxlSyirLEVYKV2fCsmXaVBX0zWWR5CAH0f4LyLE4boAtS6GLMVoEw71vN0muhtzXNpy1YAffx3uzhhiN7WOBdjtneDq7Rj/c5wDLBZfr/0tCpMaqtaLqTV1rqtALm4rt21laTUjjPZo7EC8CEoaQnQN0TiT02AtOYW72Q9oAB8CEomQAH4EBRfk1WD7OYS2eePUu4xyAaPtrwJcC7H/+88teMd2yHgc23viv6w1ghbAa4EC3cg91zeMbIdAi2yKeLtAYUFX4H2HM8ic2IrwIeYB5VDkqJPg0UvgM0QmOXxEbWPe4ZvwEN5x+iXaWVTAWrk1ZVCZBNw0dQv0yGwqECDR95OMcZUgGeuC48U6bQxbToEOmStX4gM2q5wsDe706Do02AmQAH4EJTiFgD4DRSJl9IvQx/cAAAAAElFTkSuQmCC"
        />
      </g>
    </svg>
  );
}

export default IconAlreadyAccept;
