import { call, put } from "redux-saga/effects";
import { notification } from "antd";
import { Creators as BoitelScenarioActions } from "../ducks/boitelScenario";
/** Services */
import {
  deleteBoitelScenarioSimulationService,
  updateBoitelScenarioSimulationService,
} from "../../services/boitelScenarioSimulationService";

export function* updateBoitelScenarioSimulationSaga(action) {
  try {
    const {
      data: { results },
    } = yield call(updateBoitelScenarioSimulationService, action.payload);
    notification.success({
      message: `Atualização realizada`,
      description: `Atualização da simulação do Cenário Boitel realizada`,
    });
    yield put(
      BoitelScenarioActions.updateBoitelScenarioSimulationSuccess(results)
    );
  } catch (error) {
    notification.error({
      message: `Atualização não realizada`,
      description: `Atualização da simulação do Cenário Boitel não foi realizada. Contate o suporte.`,
    });
    yield put(BoitelScenarioActions.updateBoitelScenarioSimulationError(error));
  }
}

export function* deleteBoitelScenarioSimulationSaga(action) {
  try {
    yield call(deleteBoitelScenarioSimulationService, action.payload);
    notification.success({
      message: `Remoção realizada`,
      description: `Remoção da simulação do Cenário Boitel realizada`,
    });
    yield put(
      BoitelScenarioActions.deleteBoitelScenarioSimulationSuccess(
        action.payload.id
      )
    );
  } catch (error) {
    notification.error({
      message: `Remoção não realizada`,
      description: `Remoção da simulação do Cenário Boitel não foi realizada. Contate o suporte.`,
    });
    yield put(BoitelScenarioActions.deleteBoitelScenarioSimulationError(error));
  }
}
