import apiAnimals from "../config/api_animals";
import getHeaders from "./getHeaders";

export function getLotSupplementConsumption(payload) {
  const { groupId, farmId, lotId } = payload;
  return apiAnimals.get(
    `bovexo/${groupId}/farms/${farmId}/lots/${lotId}/supplements/consumption`,
    getHeaders(false, null)
  );
}

export function createLotSupplementConsumption(payload) {
  const { groupId, farmId, lotId, body } = payload;
  return apiAnimals.post(
    `bovexo/${groupId}/farms/${farmId}/lots/${lotId}/supplements/consumption`,
    body,
    getHeaders(false, null)
  );
}
export function updateLotSupplementConsumption(payload) {
  const { groupId, farmId, lotId, id, body } = payload;
  return apiAnimals.put(
    `bovexo/${groupId}/farms/${farmId}/lots/${lotId}/supplements/consumption/${id}`,
    body,
    getHeaders(false, null)
  );
}
export function deleteLSC(payload) {
  const { groupId, farmId, lotId, id } = payload;
  return apiAnimals.delete(
    `bovexo/${groupId}/farms/${farmId}/lots/${lotId}/supplements/consumption/${id}`,
    getHeaders(false, null)
  );
}
export function getLotSupplementAverageConsumption(payload) {
  const { groupId, farmId, lotId } = payload;
  return apiAnimals.get(
    `bovexo/${groupId}/farms/${farmId}/lots/${lotId}/supplements/consumption/average`,
    getHeaders(false, null)
  );
}
