import styled from "styled-components";
import { Input, Slider } from "antd";

export const Container = styled.div`
  padding: 0px 10px 10px 10px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .headerButtonsAndMessagens {
    margin-bottom: 15px;
  }

  .buttonsCol {
    button {
      &:first-of-type {
        margin-right: 19px;
      }
    }
  }
  .iconBack {
    color: #d44c4c;
    cursor: pointer;
  }
`;

export const Body = styled.div`
  min-height: 90%;
  border-radius: 5px;
  background: #fcfcfc;
  box-shadow: 0px 3px 6px #d8d8d8;
  padding: 5px 20px;
  overflow-y: auto;
  .ant-select {
    width: 100%;
  }
  .ant-row:not(:first-of-type),
  .ant-row-flex:not(:first-of-type) {
    margin: 5px 0px;
  }

  .colName {
    .rowSlider {
      margin: 10px 0px !important;
    }
    .cardGrey {
      margin-top: 13px;
      padding: 15px 13px;
      border-radius: 5px !important;
      background: #f5f5f5 !important;

      label {
        font-family: Asap;
        font-weight: bold;
        font-size: 12px;
        text-align: left;
        color: #a5a5a5;
        margin: 0px !important;
        &.default {
          color: #fe8d2a;
        }
      }
    }
    .customStrong {
      font-size: 16px;
    }
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    margin-bottom: 7px;
    &.withoutMarginLeft {
      margin-left: 0px;
    }
    &.error {
      color: #d44c4c;
    }
  }

  .ant-select-selection-selected-value {
    padding: 0px;
  }
`;

export const Title = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  color: #4a85ae;
  text-transform: uppercase;
  &.chartTitle {
    color: #a5a5a5 !important;
  }
`;

export const CustomSlider = styled(Slider)`
  .ant-slider-track {
    background-color: #684e94 !important;
  }
  .ant-slider-dot-active {
    border-color: #684e94 !important;
  }
  .ant-slider-handle {
    border: solid 2px #684e94 !important;
  }
`;

export const CustomInput = styled(Input)`
  border-color: #684e94 !important;
  &.ant-input.error,
  &.ant-input.error:hover,
  &.ant-input.error:active {
    border-color: #d44c4c !important;
  }
  &.ant-input:hover,
  &.ant-input:active {
    border-color: #684e94 !important;
  }
  &.ant-input::-webkit-input-placeholder {
    /* Edge */
    color: #9074bf !important;
    &.error {
      color: #d44c4c !important;
    }
  }

  &.ant-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #9074bf !important;
    &.error {
      color: #d44c4c !important;
    }
  }

  &.ant-input::placeholder {
    color: #9074bf !important;
    &.error {
      color: #d44c4c !important;
    }
  }
`;
export const ChartContainer = styled.div`
  height: 310px;
  margin-top: 16px !important;
`;
