import React from "react";
import { Row, Col, Spin } from "antd";
/* Redux libs */
import { useSelector } from "react-redux";

import { Title, Message, AlertModal, ButtonSession } from "./styles";
import WarningIcon from "../../utils/icons/warning";

const ConfirmActionModal = ({
  cancelAction,
  confirmAction,
  title,
  message,
  show,
  width,
  icon,
  loading = false,
}) => {
  const { translation } = useSelector((state) => state.app);

  return (
    <AlertModal
      visible={show}
      width={width != null ? width : 468}
      footer={null}
      centered
      maskClosable={false}
      closable={false}
    >
      <Spin spinning={loading}>
        <Row justify="start">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Title>
              {icon != null ? icon : <WarningIcon />}
              <strong>{title}</strong>
            </Title>
          </Col>
        </Row>
        <Row justify="start" style={{ marginTop: 8 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Message>{message}</Message>
          </Col>
        </Row>
        <Row justify="space-between" style={{ marginTop: 21 }}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} align="left">
            <ButtonSession className="red" onClick={cancelAction}>
              {translation.buttons.cancel}
            </ButtonSession>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
            <ButtonSession onClick={confirmAction}>
              {translation.buttons.confirm}
            </ButtonSession>
          </Col>
        </Row>
      </Spin>
    </AlertModal>
  );
};

export default ConfirmActionModal;
