export const Types = {
  RESET_DATA: "userTrigger/RESET_DATA",
  SHOW_OR_HIDE_MODAL_TRIGGER_COST:
    "userTrigger/SHOW_OR_HIDE_MODAL_TRIGGER_COST",
  GET_LAST_STATUS_USER_TRIGGER: "userTrigger/GET_LAST_STATUS_USER_TRIGGER",
  GET_LAST_STATUS_USER_TRIGGER_SUCCESS:
    "userTrigger/GET_LAST_STATUS_USER_TRIGGER_SUCCESS",
  GET_LAST_STATUS_USER_TRIGGER_ERROR:
    "userTrigger/GET_LAST_STATUS_USER_TRIGGER_ERROR",
  SAVE_USER_TRIGGER: "userTrigger/SAVE_USER_TRIGGER",
  SAVE_USER_TRIGGER_SUCCESS: "userTrigger/SAVE_USER_TRIGGER_SUCCESS",
  SAVE_USER_TRIGGER_ERROR: "userTrigger/SAVE_USER_TRIGGER_ERROR",
};

export const Creators = {
  showOrHideModalTriggerCost: () => ({
    type: Types.SHOW_OR_HIDE_MODAL_TRIGGER_COST,
    payload: {},
  }),
  getLastStatusUserTrigger: (groupId, farmId, triggerAction) => ({
    type: Types.GET_LAST_STATUS_USER_TRIGGER,
    payload: { groupId, farmId, triggerAction },
  }),
  getLastStatusUserTriggerSuccess: (triggerAction, data) => ({
    type: Types.GET_LAST_STATUS_USER_TRIGGER_SUCCESS,
    payload: { triggerAction, data },
  }),
  getLastStatusUserTriggerError: (triggerAction) => ({
    type: Types.GET_LAST_STATUS_USER_TRIGGER_ERROR,
    payload: { triggerAction },
  }),
  saveUserTrigger: (groupId, farmId, triggerAction, body) => ({
    type: Types.SAVE_USER_TRIGGER,
    payload: { groupId, farmId, triggerAction, body },
  }),
  saveUserTriggerSuccess: (triggerAction) => ({
    type: Types.SAVE_USER_TRIGGER_SUCCESS,
    payload: { triggerAction },
  }),
  saveUserTriggerError: () => ({
    type: Types.SAVE_USER_TRIGGER_ERROR,
    payload: {},
  }),
};

const INITIAL_STATE = {
  isLoading: false,
  isLoadingRequest: false,
  isVisibleModalTriggerCost: false,
  requestUserTriggerCost: {
    startDate: null,
    finalDate: null,
    option: "Both",
  },
  dataList: {
    AnimalDailyCost: null,
  },
};

export default function userTrigger(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.SHOW_OR_HIDE_MODAL_TRIGGER_COST:
      return {
        ...state,
        isVisibleModalTriggerCost: !state.isVisibleModalTriggerCost,
        requestUserTriggerCost: INITIAL_STATE.requestUserTriggerCost,
      };
    case Types.GET_LAST_STATUS_USER_TRIGGER:
      return {
        ...state,
        isLoading: true,
      };
    case Types.GET_LAST_STATUS_USER_TRIGGER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dataList: {
          ...state.dataList,
          [action.payload.triggerAction]: action.payload.data,
        },
      };
    case Types.GET_LAST_STATUS_USER_TRIGGER_ERROR:
      return {
        ...state,
        isLoading: false,
        dataList: { ...state.dataList, [action.payload.triggerAction]: null },
      };
    case Types.SAVE_USER_TRIGGER:
      return {
        ...state,
        isLoadingRequest: true,
      };
    case Types.SAVE_USER_TRIGGER_SUCCESS:
      switch (action.payload.triggerAction) {
        case "AnimalDailyCost":
          return {
            ...state,
            isVisibleModalTriggerCost: false,
            requestUserTriggerCost: INITIAL_STATE.requestUserTriggerCost,
            isLoadingRequest: false,
          };
        default:
          return {
            ...state,
            isLoadingRequest: false,
          };
      }
    case Types.SAVE_USER_TRIGGER_ERROR:
      return {
        ...state,
        isLoadingRequest: false,
      };
    default:
      return state;
  }
}
