import React from 'react';

// import { Container } from './styles';

const AddButtonIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
    <g id="Btn_Adicionar" data-name="Btn Adicionar" transform="translate(-1461.266 -21.533)">
      <line
        id="Linha_59"
        data-name="Linha 59"
        y2="20"
        transform="translate(1472.266 22.533)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <line
        id="Linha_60"
        data-name="Linha 60"
        x1="20"
        transform="translate(1462.266 32.293)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </g>
  </svg>
);
export default AddButtonIcon;
