export function buildPastureWithStructuredSeasons(pasture) {
  let seasonWater = [];
  let seasonTransition = [];
  let seasonDry = [];
  if (pasture.seasons.length > 0) {
    seasonWater = pasture.seasons
      .filter((s) => s.season === "Water")
      .map((s) => {
        s.status =
          s.cost === 0 ||
          s.cost === null ||
          s.cp === 0 ||
          s.cp === null ||
          s.tdn === 0 ||
          s.tdn === null ||
          s.entryHeight === 0 ||
          s.entryHeight === null ||
          s.exitHeight === 0 ||
          s.exitHeight === null ||
          s.rest === 0 ||
          s.rest === null ||
          s.leafDensityProduction === 0 ||
          s.leafDensityProduction === null ||
          s.growthRate === 0 ||
          s.growthRate === null ||
          s.maximumConsumption === 0 ||
          s.maximumConsumption === null ||
          s.minimumConsumption === 0 ||
          s.minimumConsumption === null
            ? "NI"
            : "I";
        s.key = s.fertilized ? "water01" : "water02";
        return s;
      });
    seasonTransition = pasture.seasons
      .filter((s) => s.season === "Transition")
      .map((s) => {
        s.status =
          s.cost === 0 ||
          s.cost === null ||
          s.cp === 0 ||
          s.cp === null ||
          s.tdn === 0 ||
          s.tdn === null ||
          s.entryHeight === 0 ||
          s.entryHeight === null ||
          s.exitHeight === 0 ||
          s.exitHeight === null ||
          s.rest === 0 ||
          s.rest === null ||
          s.leafDensityProduction === 0 ||
          s.leafDensityProduction === null ||
          s.growthRate === 0 ||
          s.growthRate === null ||
          s.maximumConsumption === 0 ||
          s.maximumConsumption === null ||
          s.minimumConsumption === 0 ||
          s.minimumConsumption === null
            ? "NI"
            : "I";
        s.key = s.fertilized ? "transition01" : "transition02";
        return s;
      });
    seasonDry = pasture.seasons
      .filter((s) => s.season === "Dry")
      .map((s) => {
        s.status =
          s.cost === 0 ||
          s.cost === null ||
          s.cp === 0 ||
          s.cp === null ||
          s.tdn === 0 ||
          s.tdn === null ||
          s.entryHeight === 0 ||
          s.entryHeight === null ||
          s.exitHeight === 0 ||
          s.exitHeight === null ||
          s.rest === 0 ||
          s.rest === null ||
          s.leafDensityProduction === 0 ||
          s.leafDensityProduction === null ||
          s.growthRate === 0 ||
          s.growthRate === null ||
          s.maximumConsumption === 0 ||
          s.maximumConsumption === null ||
          s.minimumConsumption === 0 ||
          s.minimumConsumption === null
            ? "NI"
            : "I";
        s.key = s.fertilized ? "dry01" : "dry02";
        return s;
      });
  } else {
    seasonWater = [
      {
        calcium: null,
        consumptionType: "PV_ANIMAL",
        cost: null,
        cp: null,
        fertilized: true,
        maximumConsumption: null,
        minimumConsumption: null,
        nitrogen: null,
        zinc: null,
        sodium: null,
        phosphorus: null,
        season: "Water",
        tdn: null,
        status: "NI",
        entryHeight: null,
        exitHeight: null,
        fdn: null,
        formation: 0,
        rest: 0,
        growthRate: null,
        leafDensityProduction: null,
        key: "water01",
      },
      {
        calcium: null,
        consumptionType: "PV_ANIMAL",
        cost: null,
        cp: null,
        fertilized: false,
        maximumConsumption: null,
        minimumConsumption: null,
        nitrogen: null,
        zinc: null,
        sodium: null,
        phosphorus: null,
        season: "Water",
        tdn: null,
        status: "NI",
        entryHeight: null,
        exitHeight: null,
        fdn: null,
        formation: 0,
        rest: 0,
        growthRate: null,
        leafDensityProduction: null,
        key: "water02",
      },
    ];
    seasonDry = [
      {
        calcium: null,
        consumptionType: "PV_ANIMAL",
        cost: null,
        cp: null,
        fertilized: true,
        maximumConsumption: null,
        minimumConsumption: null,
        nitrogen: null,
        zinc: null,
        sodium: null,
        phosphorus: null,
        season: "Dry",
        tdn: null,
        status: "NI",
        entryHeight: null,
        exitHeight: null,
        fdn: null,
        formation: 0,
        rest: 0,
        growthRate: null,
        leafDensityProduction: null,
        key: "dry01",
      },
      {
        calcium: null,
        consumptionType: "PV_ANIMAL",
        cost: null,
        cp: null,
        fertilized: false,
        maximumConsumption: null,
        minimumConsumption: null,
        nitrogen: null,
        zinc: null,
        sodium: null,
        phosphorus: null,
        season: "Dry",
        tdn: null,
        status: "NI",
        entryHeight: null,
        exitHeight: null,
        fdn: null,
        formation: 0,
        rest: 0,
        growthRate: null,
        leafDensityProduction: null,
        key: "dry02",
      },
    ];
    seasonTransition = [
      {
        calcium: null,
        consumptionType: "PV_ANIMAL",
        cost: null,
        cp: null,
        fertilized: true,
        maximumConsumption: null,
        minimumConsumption: null,
        nitrogen: null,
        zinc: null,
        sodium: null,
        phosphorus: null,
        season: "Transition",
        tdn: null,
        status: "NI",
        entryHeight: null,
        exitHeight: null,
        fdn: null,
        formation: 0,
        rest: 0,
        growthRate: null,
        leafDensityProduction: null,
        key: "transition01",
      },
      {
        calcium: null,
        consumptionType: "PV_ANIMAL",
        cost: null,
        cp: null,
        fertilized: false,
        maximumConsumption: null,
        minimumConsumption: null,
        nitrogen: null,
        zinc: null,
        sodium: null,
        phosphorus: null,
        season: "Transition",
        tdn: null,
        status: "NI",
        entryHeight: null,
        exitHeight: null,
        fdn: null,
        formation: 0,
        rest: 0,
        growthRate: null,
        leafDensityProduction: null,
        key: "transition02",
      },
    ];
  }

  const result = {
    ...pasture,
    seasonWater,
    seasonTransition,
    seasonDry,
  };

  return result;
}

export function applyPastureTemplateToExistantPastureInfo(template, pasture) {
  const newPasture = buildPastureWithStructuredSeasons(template);

  newPasture.id = pasture.id;
  newPasture.name = pasture.name;
  return newPasture;
}

export function applyPastureTemplatenfo(template) {
  const adjustedTemplate = { ...template };

  adjustedTemplate.seasons = adjustedTemplate.seasons.map((season) => {
    const newSeason = { ...season };
    newSeason.id = null;
    newSeason.createdAt = null;
    newSeason.updatedAt = null;
    return newSeason;
  });

  const newPasture = buildPastureWithStructuredSeasons(adjustedTemplate);
  newPasture.id = null;

  return newPasture;
}
