import styled from "styled-components";
import { Modal, Select } from "antd";

export const Container = styled(Modal)`
  &.ant-modal {
    padding-bottom: 0px !important;
  }
  .ant-row,
  .ant-row-flex {
    margin-bottom: 10px;
  }
  .ant-row:nth-of-type(2),
  .ant-row-flex:nth-of-type(2) {
    margin-bottom: 18px;
  }
`;

export const Title = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: #4b4b4b;
`;

export const Message = styled.p`
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #4b4b4b;
  margin: 0;
`;

export const CustomSelect = styled(Select)`
  width: 100% !important;
  &.ant-select.ant-select-enabled {
    .ant-select-selection {
      border: 1px solid #684e94 !important;
      color: #684e94 !important;
    }
    .ant-select-arrow {
      color: #684e94 !important;
    }
    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
      color: #684e94 !important;
    }
  }
`;

export const ExtensionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ExtensionButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  margin: 0 5px;
`;
