import apiIam from "../config/api_iam";
import getHeaders from "./getHeaders";

export function findAll(payload) {
	const {
		supplierGroupId,
		supplierGroupFarmId
	} = payload;

	return apiIam.get(
		`/bovexo/suppliers/groups/${supplierGroupId}/farms/${supplierGroupFarmId}/collaborators`,
		getHeaders(false, null)
	);
}