import apiAnimals from "../config/api_animals";

import moment from "moment";
import getHeaders from "./getHeaders";
// import axios from "axios";

// const apiAnimals = axios.create({
//   baseURL: "http://localhost:8084",
// });

export function getLotIndex(payload) {
  const { groupId, farmId, page, sorter, filters, size, signal, ids } = payload;

  const { field, order } = sorter;

  const orderS = order === "descend" ? "DESC" : "ASC";

  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/lots/list?
    ${page ? `&page=${page - 1}` : ""}
    ${size && `&size=${size}`}
    ${
      Object.entries(sorter).length > 0
        ? `&sort=${field}&direction=${orderS}`
        : ""
    }
    ${filters !== "" ? `&search=${filters}` : ""}`,
    ids != null ? ids : null,
    getHeaders(false, signal)
  );
}

export function getLotsForSaleScenario(payload) {
  const { groupId, farmId, page, sorter, filters, size, signal } = payload;

  const { field, order } = sorter;

  const orderS = order === "descend" ? "DESC" : "ASC";

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/saleScenarios/lots?
    ${page ? `&page=${page - 1}` : ""}
    ${size && `&size=${size}`}
    ${
      Object.entries(sorter).length > 0
        ? `&sort=${field}&direction=${orderS}`
        : ""
    }
    ${filters !== "" ? `&search=${filters}` : ""}`,
    getHeaders(false, signal)
  );
}

export function getLotIndexByAnimalSupplier(payload) {
  const { groupId, farmId, supplierId, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/suppliers/${supplierId}/lots`,
    getHeaders(false, signal)
  );
}

export function getLotIndexDropDown(payload) {
  const { groupId, farmId, signal } = payload;
  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/lots/list?withoutPagination=true`,
    null,
    getHeaders(false, signal)
  );
}

export function getLotShow(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/lots/${id}`,
    getHeaders(false, signal)
  );
}

export function getLotCosts(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/lots/${id}/costs`,
    getHeaders(false, signal)
  );
}

export function getLotShowAsync({ payload }) {
  const { groupId, farmId, id, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/lots/${id}`,
    getHeaders(false, signal)
  );
}

export function getLotStatistics(payload) {
  const { groupId, farmId, id, statistics, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/lots/${id}/statistics/${statistics}`,
    getHeaders(false, signal)
  );
}

export function getLotStatisticsAsync({ payload }) {
  const { groupId, farmId, id, statistics, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/lots/${id}/statistics/${statistics}`,
    getHeaders(false, signal)
  );
}

export function getLotAnimals(payload) {
  const {
    groupId,
    farmId,
    id,
    signal,
    page,
    size,
    filters,
    sorter,
    withoutPagination,
  } = payload;

  if (withoutPagination != null) {
    if (withoutPagination === true) {
      return apiAnimals.get(
        `/bovexo/${groupId}/farms/${farmId}/lots/${id}/animals?withoutPagination=true`,
        getHeaders(false, signal)
      );
    } else {
      let { field, order } = sorter;

      field =
        field === "handlingNumber"
          ? "handling_number"
          : field === "lastDailyWeight"
          ? "last_daily_weight"
          : field;

      const orderS = order === "descend" ? "DESC" : "ASC";

      return apiAnimals.get(
        `/bovexo/${groupId}/farms/${farmId}/lots/${id}/animals?withoutPagination=false${
          page ? `&page=${page - 1}` : ""
        }
        ${
          Object.entries(sorter).length > 0
            ? `&sort=${field}&direction=${orderS}`
            : ""
        }
        ${size && `&size=${size}`}
        ${filters !== "" ? `&search=${filters}` : ""}`,
        getHeaders(false, signal)
      );
    }
  } else {
    return apiAnimals.get(
      `/bovexo/${groupId}/farms/${farmId}/lots/${id}/animals?withoutPagination=true`,
      getHeaders(false, signal)
    );
  }
}

export function saveNewLotReceipt(payload) {
  const { groupId, farmId, lotReceipt, lotItens, signal } = payload;

  const copyLotReceipt = lotReceipt;
  const copyLotItens = lotItens;

  copyLotItens.map((item) => {
    item.id = "";
    return item;
  });

  const body = {
    ...copyLotReceipt,
    items: copyLotItens,
  };

  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/receipt/lots`,
    body,
    getHeaders(false, signal)
  );
}

export function saveNewLotProduction(payload) {
  const { groupId, farmId, lotProduction, id, signal } = payload;
  if (id != null) {
    const body = {
      ...lotProduction,
    };
    return apiAnimals.patch(
      `/bovexo/${groupId}/farms/${farmId}/lots/${id}`,
      body,
      getHeaders(false, signal)
    );
  } else {
    const body = {
      ...lotProduction,
      creationDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    };

    return apiAnimals.post(
      `/bovexo/${groupId}/farms/${farmId}/lots`,
      body,
      getHeaders(false, signal)
    );
  }
}

export function addAnimalsToProductionLot(payload) {
  const { groupId, farmId, id, animals, signal } = payload;

  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/lots/${id}/animals`,
    animals,
    getHeaders(false, signal)
  );
}

export function dismember(payload) {
  const { groupId, farmId, id, dismemberLot, signal } = payload;
  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/lots/${id}/dismember`,
    dismemberLot,
    getHeaders(false, signal)
  );
}

export function getLotDietStrategyCurve(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/lots/${id}/curves?curveType=DietStrategy`,
    getHeaders(false, signal)
  );
}

export function deleteInDatabaseAllAnimalsFromLot(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiAnimals.delete(
    `/bovexo/${groupId}/farms/${farmId}/lots/${id}/animals/delete`,
    getHeaders(false, signal)
  );
}

export function destroyLotProduction(payload) {
  const { groupId, farmId, lotId, signal } = payload;

  return apiAnimals.delete(
    `/bovexo/${groupId}/farms/${farmId}/lots/${lotId}`,
    getHeaders(false, signal)
  );
}

export function reactivateLotProduction(payload) {
  const { groupId, farmId, lotId, signal } = payload;

  return apiAnimals.put(
    `/bovexo/${groupId}/farms/${farmId}/lots/${lotId}/reactivate`,
    null,
    getHeaders(false, signal)
  );
}
export function inactivateLotProduction(payload) {
  const { groupId, farmId, lotId, signal } = payload;

  return apiAnimals.put(
    `/bovexo/${groupId}/farms/${farmId}/lots/${lotId}/inactivate`,
    null,
    getHeaders(false, signal)
  );
}

export function updateLotWeightRanking(payload) {
  const { groupId, farmId, lotId, body, signal } = payload;

  return apiAnimals.patch(
    `/bovexo/${groupId}/farms/${farmId}/lots/${lotId}/weight/ranking`,
    body,
    getHeaders(false, signal)
  );
}

export function movePicketsFromLot(payload) {
  const { groupId, farmId, lotId, body, signal } = payload;

  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/lots/${lotId}/move/pickets`,
    body,
    getHeaders(false, signal)
  );
}

export function getLoatAcquisitionData(payload) {
  const { groupId, farmId, lotId, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/lots/${lotId}/acquisition/data`,
    getHeaders(false, signal)
  );
}

export function getLotPicketSupplementSupplies(payload) {
  const { groupId, farmId, lotId, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/lots/${lotId}/supplements/supplies`,
    getHeaders(false, signal)
  );
}
