export const Types = {
  RESET_DATA: "farmDeathReason/RESET_DATA",
  GET_ALL_FARM_DEATH_REASON: "farmDeathReason/GET_ALL_FARM_DEATH_REASON",
  GET_ALL_FARM_DEATH_REASON_SUCCESS: "farmDeathReason/GET_ALL_FARM_DEATH_REASON_SUCCESS",
  GET_ALL_FARM_DEATH_REASON_ERROR: "farmDeathReason/GET_ALL_FARM_DEATH_REASON_ERROR",
  INACTIVATE_FARM_DEATH_REASON: "farmDeathReason/INACTIVATE_FARM_DEATH_REASON",
  ACTIVATE_FARM_DEATH_REASON: "farmDeathReason/ACTIVATE_FARM_DEATH_REASON",
  SHOW_OR_HIDE_DRAWER: "farmDeathReason/SHOW_HIDE_DRAWER",
  GET_ALL_DROPDOWN: "farmDeathReason/GET_ALL_DROPDOWN",
  GET_ALL_DROPDOWN_SUCCESS: "farmDeathReason/GET_ALL_DROPDOWN_SUCCESS",
  GET_ALL_DROPDOWN_ERROR: "farmDeathReason/GET_ALL_DROPDOWN_ERROR",
};

export const Creators = {
  index: (
    groupId,
    farmId,
  ) => ({
    type: Types.GET_ALL_FARM_DEATH_REASON,
    payload: {
      groupId,
      farmId
    },
  }),
  indexSuccess: (data) => ({
    type: Types.GET_ALL_FARM_DEATH_REASON_SUCCESS,
    payload: {
      data,
    },
  }),
  indexError: (error) => ({
    type: Types.GET_ALL_FARM_DEATH_REASON_ERROR,
    payload: {
      error,
    },
  }),
  showOrHideDrawer: (id = null) => ({
    type: Types.SHOW_OR_HIDE_DRAWER,
    payload: {
      id
    },
  }),
  inactivateFarmDeathReason: (groupId, farmId, farmDeathReasonId, deathReasonId) => ({
    type: Types.INACTIVATE_FARM_DEATH_REASON,
    payload: {
      groupId,
      farmId,
      farmDeathReasonId,
      deathReasonId,
    },
  }),
  activateFarmDeathReason: (groupId, farmId, farmDeathReasonId) => ({
    type: Types.ACTIVATE_FARM_DEATH_REASON,
    payload: {
      groupId,
      farmId,
      farmDeathReasonId,
    },
  }),
  getDropdownFarmDeathReason: (
    groupId,
    farmId,
  ) => ({
    type: Types.GET_ALL_DROPDOWN,
    payload: {
      groupId,
      farmId,
    },
  }),

  getDropdownFarmDeathReasonSuccess: (data) => ({
    type: Types.GET_ALL_DROPDOWN_SUCCESS,
    payload: {
      data,
    },
  }),

  getDropdownFarmDeathReasonError: (error) => ({
    type: Types.GET_ALL_DROPDOWN_ERROR,
    payload: {
      error,
    },
  }),
};

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  isDrawerVisible: false,
  farmDeathReasonId: null,
  isLoadingDropDown: false,
  listDrowpDown: [],
  error: {},
};

export default function farmDeathReason(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_ALL_FARM_DEATH_REASON:
      return {
        ...state,
        isLoading: true,
      };
    case Types.GET_ALL_FARM_DEATH_REASON_SUCCESS:
      const { data: deathReasons } = action.payload;
      return { ...state, isLoading: false, data: deathReasons };
    case Types.GET_ALL_FARM_DEATH_REASON_ERROR:
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: null,
        error,
      };
      case Types.SHOW_OR_HIDE_DRAWER:
        return { ...state, farmDeathReasonId: action.payload.id, isDrawerVisible: !state.isDrawerVisible };
      case Types.INACTIVATE_FARM_DEATH_REASON:
        return { ...state, isLoading: true };
      case Types.ACTIVATE_FARM_DEATH_REASON:
        return { ...state, isLoading: true };
      case Types.GET_ALL_DROPDOWN:
        return { ...state, isLoadingDropDown: true };
      case Types.GET_ALL_DROPDOWN_SUCCESS:
        return {
          ...state,
          isLoadingDropDown: false,
          listDrowpDown: action.payload.data,
        };
      case Types.GET_ALL_DROPDOWN_ERROR:
        return {
          ...state,
          isLoadingDropDown: false,
          listDrowpDown: [],
          error: action.payload.error,
        };
    default:
      return state;
  }
}
