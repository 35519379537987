import { call, put, select } from "redux-saga/effects";
import { Creators as BreedActions } from "../ducks/breed";
/** Services */
import {
  getBreedIndex,
  getBreedIndexActive,
  getBreedShow,
  saveBreed,
  deleteBreedServ,
  getBreedParametersShow,
  saveBreedParameters,
  inactivateBreedParameter,
  activateBreedParameter,
} from "../../services/breedService";
import { notification } from "antd";

export const getTranslation = (state) => state.app.translation;
export const getBreedState = (state) => state.breed;

export function* indexBreed(action) {
  try {
    const {
      data: { results: allBreeds },
    } = yield call(getBreedIndex, action.payload);
    yield put(BreedActions.indexBreedSuccess(allBreeds));
  } catch (error) {
    yield put(BreedActions.indexBreedError(error));
  }
}

export function* indexBreedActive(action) {
  try {
    const {
      data: { results: allBreeds },
    } = yield call(getBreedIndexActive, action.payload);
    yield put(BreedActions.indexBreedActiveSuccess(allBreeds));
  } catch (error) {
    yield put(BreedActions.indexBreedActiveError(error));
  }
}

export function* getBreed(action) {
  try {
    const {
      data: { results: allBreeds },
    } = yield call(getBreedShow, action.payload);
    yield put(BreedActions.showBreedSuccess(allBreeds));
  } catch (error) {
    yield put(BreedActions.showBreedError(error));
  }
}

export function* getBreedParameters(action) {
  try {
    const {
      data: { results: allBreedParameters },
    } = yield call(getBreedParametersShow, action.payload);
    yield put(BreedActions.showBreedParametersSuccess(allBreedParameters));
  } catch (error) {
    yield put(BreedActions.showBreedParametersError(error));
  }
}

export function* storeOrUpdateBreed(action) {
  let result = null;
  try {
    const {
      data: { results: newBreed },
    } = yield call(saveBreed, action.payload);
    result = newBreed;
    const { saveOptions } = action.payload;
    yield put(BreedActions.saveBreedSuccess(newBreed, saveOptions));
    if (action.payload.id == null)
      notification.success({ message: "Raça criada com sucesso" });
    else notification.success({ message: "Raça atualizada com sucesso" });
  } catch (error) {
    notification.error({ message: "Raça não foi criada" });
    yield put(BreedActions.saveBreedError(error));
  }

  if (result != null && Object.entries(result).length > 0) {
    const {
      pagination: { page, size, sorter, filters },
    } = yield select(getBreedState);
    const newPayload = {
      ...action.payload,
      page,
      size,
      sorter,
      filters,
    };

    yield put(
      BreedActions.indexBreed(
        newPayload.groupId,
        newPayload.farmId,
        newPayload.page,
        newPayload.sorter,
        newPayload.filters,
        10,
        true
      )
    );
  }
}

export function* storeOrUpdateBreedParameters(action) {
  let result = null;
  try {
    const {
      data: { results: newBreedParameters },
    } = yield call(saveBreedParameters, action.payload);
    result = newBreedParameters;
    const { saveOptions } = action.payload;
    yield put(
      BreedActions.saveBreedParametersSuccess(newBreedParameters, saveOptions)
    );
    if (action.payload.id == null)
      notification.success({ message: "Parâmetros configurados com sucesso" });
    else
      notification.success({ message: "Parâmetros atualizados com sucesso" });
  } catch (error) {
    notification.error({ message: "Parâmetros não criados" });
    yield put(BreedActions.saveBreedParametersError(error));
  }

  if (result != null && Object.entries(result).length > 0) {
    const {
      pagination: { page, size, sorter, filters },
    } = yield select(getBreedState);
    const newPayload = {
      ...action.payload,
      page,
      size,
      sorter,
      filters,
    };
    yield put(
      BreedActions.indexBreed(
        newPayload.groupId,
        newPayload.farmId,
        newPayload.page,
        newPayload.sorter,
        newPayload.filters,
        10,
        true
      )
    );
  }
}

export function* deleteBreed(action) {
  try {
    const { data } = yield call(deleteBreedServ, action.payload);
    yield put(BreedActions.deleteBreedSuccess(data));
    notification.success({
      message: "Raça inativada com sucesso",
    });
  } catch (error) {
    notification.error({ message: "Raça não foi inativada" });
    yield put(BreedActions.deleteBreedError(error));
  }
}

export function* inactivateOrActivateBreedParameter(action) {
  const { groupId, farmId, operationAction } = action.payload;
  try {
    if (operationAction === "activate") {
      yield call(activateBreedParameter, action.payload);
    } else {
      yield call(inactivateBreedParameter, action.payload);
    }
    yield put(BreedActions.inactivateOrActivateBreedParametersSuccess());
    yield put(BreedActions.indexBreed(groupId, farmId, 0, {}, "", 10, true));
    notification.success({
      message: `Raça ${
        operationAction === "activate" ? "ativada" : "inativada"
      } com sucesso`,
    });
  } catch (error) {
    notification.error({
      message: `Raça não foi ${
        operationAction === "activate" ? "ativada" : "inativada"
      }. Contate o suporte`,
    });
    yield put(BreedActions.inactivateOrActivateBreedParametersError(error));
  }
}
