import apiFinancial from "../config/api_financial";
import getHeaders from "./getHeaders";

export function getFuturePriceChart(payload) {
  const { groupId, farmId, marketAnalysisId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/marketAnalysis/${marketAnalysisId}/futureprice/chart?justFuture=true`,
    getHeaders(false, signal)
  );
}

export function getFuturePrice(payload) {
  const { groupId, farmId, marketAnalysisId, justFuture, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/marketAnalysis/${marketAnalysisId}/futureprice?justFuture=${justFuture ? "true" : "false"}`,
    getHeaders(false, signal)
  );
}
