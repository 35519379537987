import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 29px;
  width: ${(props) => (props.width ? props.width : "270px !important")};
  height: ${(props) => (props.height ? props.height : "40px !important")};
  border: ${(props) =>
    props.border === "error"
      ? "1px solid #C14933 !important"
      : props.border === "normal"
      ? "1px solid #D8D8D8"
      : props.border === "success"
      ? "1px solid #A9C133 !important"
      : "none"};
  background: ${(props) =>
    props.background != null && props.background !== ""
      ? props.background
      : "#ffffff"};
  padding: 8px 24px 8px 16px;
  @media screen and (max-width: 800px) {
    & {
      width: ${(props) => (props.width ? props.width : "270px")};
      height: 100%;
    }
  }

  &:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }

  &.disabled {
    cursor: not-allowed;
    background: #e8e5e5 !important;
  }

  input {
    width: 85%;
    border: none !important;
    font-size: 1em;
    background: transparent;
    color: ${(props) =>
      props.fontColor != null && props.fontColor !== ""
        ? props.fontColor
        : "#383838"};
    margin: 0;
    padding-left: 7px;
    font-size: 14px;
    box-shadow: none !important;

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${(props) =>
        props.placeholderColor != null && props.placeholderColor !== ""
          ? props.placeholderColor
          : "#d6d6d6"};
    }

    &::placeholder {
      color: ${(props) =>
        props.placeholderColor != null && props.placeholderColor !== ""
          ? props.placeholderColor
          : "#d6d6d6"};
    }

    /* Change Autocomplete styles in Chrome*/
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: none;
      -webkit-text-fill-color: #000000;
      -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    &:disabled {
      cursor: not-allowed;
      background: #e8e5e5 !important;
    }
  }
`;
