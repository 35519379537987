import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Axios from "axios";
import useKeyPress from "../../../../hooks/useKeyPress";
/* Components */
import { TiArrowDownThick, TiArrowUpThick } from "react-icons/ti";
import SimpleMessageModal from "../../../../components/modals/simpleMessageModal";
import NumberFormat from "react-number-format";
import {
  Empty,
  Row,
  Col,
  Select,
  Input,
  Spin,
  notification,
  DatePicker,
  Tooltip,
  Icon,
  Button,
  Modal,
} from "antd";
import {
  PeriodModal,
  Container,
  Header,
  Body,
  Footer,
  ButtonNavigation,
  CustomDivider,
  NutritionalPastureBox,
  NutritionalSupplementBox,
  NutritionalGdpValidationBox,
} from "./styles";
import RainIcon from "../../../generalParameter/weatherSeasonsParameter/display/icons/rain";
import SunIcon from "../../../generalParameter/weatherSeasonsParameter/display/icons/sun";
import TransitionIcon from "../../../generalParameter/weatherSeasonsParameter/display/icons/transition";
import InfoIcon from "../../../../components/utils/icons/info";
import InfoTooltip from "../../../../components/utils/infoTooltip";
import GdpModalValidation from "../../../../components/modals/gdpModalValidation";

/** Services */
import { callValidationGdp } from "../../../../services/validateGdpService";
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../../services/helpersMethodsService";
import { classifyPeriodCarbonFootPrint } from "../../../../services/carbonFootprintService";
import BoxText from "../../../../components/utils/boxText";

const PeriodModel = ({
  onAction,
  pastures,
  supplements,
  dietStrategyData,
  seasons,
  visible,
  index,
  startDateDeadline,
  dietPeriodCurveDatesAndWeights,
  picketInfo,
  amountLotAnimals,
  lotRealGdpCurveDatesAndWeights,
  lotAvgSupplementConsumption,
  breeds,
  carbonFootprintParameters,
  carbonBaseKg,
  carbonCredit,
}) => {
  /* Redux */
  const { dietStrategyPeriodSelected } = useSelector(
    (state) => state.dietStrategy
  );
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  /** Hooks key press */
  useKeyPress(37, false, true, () => {
    if (index !== 0) goPrevius();
  });
  useKeyPress(39, false, true, () => {
    if (
      dietStrategyData.dietPeriods.length > 1 &&
      index < dietStrategyData.dietPeriods.length - 1
    )
      goNext();
  });
  useKeyPress(13, true, false, () => {
    saveAndFinalize();
  });
  /** Variables */
  const [signal, setSignal] = useState(null);
  const [executeAction, setExecuteAction] = useState(null);
  const [form, setForm] = useState(null);
  const [realGdp, setRealGdp] = useState(0);
  const [showModalChangeSystem, setShowModalChangeSystem] = useState(false);
  const [gdpValidationReturn, setGdpValidationReturn] = useState({
    animal: {
      AssymptoticWeight: 0,
      GeneticGroup: "string",
      SexualClass: "string",
      System: "string",
      Weight: 0,
    },
    code: "string",
    minerals: {
      Ca: {
        Code: 1,
        Necessary: 0,
        Consumed: 0,
      },
      P: {
        Code: 1,
        Necessary: 0,
        Consumed: 0,
      },
      Zn: {
        Code: 1,
        Necessary: 0,
        Consumed: 0,
      },
      Na: {
        Code: 1,
        Necessary: 0,
        Consumed: 0,
      },
    },
    consumed: {
      NDT: 0,
      PB: 0,
    },
    day: 0,
    necessary: {
      NDT: 0,
      PB: 0,
    },
    pasture: {
      Consumed: 0,
      Need: 0,
    },
    trough: {
      Consumed: 0,
      Need: 0,
    },
    weightGain: 0,
  });
  const [modalGdpValidationVisible, setModalGdpValidationVisible] =
    useState(false);
  const [loadinDiets, setLoadingDiets] = useState(false);
  const [loadinGDPValidation, setLoadingGDPValidation] = useState(false);
  const [errors, setErrors] = useState([]);
  const productionSubSystems = [
    {
      code: "Extensive",
      name: translation.productionSubSystem.extensive,
    },
    {
      code: "SemiIntensive",
      name: translation.productionSubSystem.semiIntensive,
    },
    {
      code: "Intensive",
      name: translation.productionSubSystem.intensive,
    },
  ];
  const { Option } = Select;
  const selectSupplementConsumption = (
    <Select
      value={form?.supplement?.voluntaryConsumptionType || "G_ANIMAL"}
      onChange={(value) => {
        setForm({
          ...form,
          supplementConsumptionMeasureType: value,
        });
        setErrors(
          errors.filter((e) => e !== "supplementConsumptionMeasureType")
        );
      }}
    >
      <Select.Option
        value="PV_ANIMAL"
        disabled={form?.supplement?.voluntaryConsumptionType !== "PV_ANIMAL"}
      >
        %PV
      </Select.Option>
      <Select.Option
        value="I_MS_PV_ANIMAL"
        disabled={
          form?.supplement?.voluntaryConsumptionType !== "I_MS_PV_ANIMAL"
        }
      >
        %IMS PV
      </Select.Option>
      <Select.Option
        value="G_KG_PV_ANIMAL"
        disabled={
          form?.supplement?.voluntaryConsumptionType !== "G_KG_PV_ANIMAL"
        }
      >
        g/kg de PV
      </Select.Option>
      <Select.Option
        value="KG_ANIMAL"
        disabled={form?.supplement?.voluntaryConsumptionType !== "KG_ANIMAL"}
      >
        kg/dia
      </Select.Option>
      <Select.Option
        value="G_ANIMAL"
        disabled={form?.supplement?.voluntaryConsumptionType !== "G_ANIMAL"}
      >
        g/dia
      </Select.Option>
    </Select>
  );
  const dateFormat = "DD/MM/YYYY";

  /** Memo */
  const isCurrentPeriod = useMemo(
    () =>
      moment().isBetween(
        moment(form?.startDate),
        moment(form?.endDate),
        "days",
        "[]"
      ),
    [form]
  );

  const shouldBlockOnEdit = useMemo(() => {
    if (form != null) {
      return (
        dietStrategyData.id != null &&
        !(
          moment().isBetween(moment(form.startDate), moment(form.endDate)) ||
          moment(form.startDate).isSameOrAfter(moment())
        ) &&
        form.id !== null &&
        form.id !== undefined &&
        index < dietStrategyData.dietPeriods.length - 1
      );
    } else {
      return false;
    }
  }, [dietStrategyData, form]);

  const weightDateIn = useMemo(() => {
    if (form != null) {
      if (index === 0) {
        if (!dietStrategyData?.generic) {
          return dietStrategyData?.lot.referenceAcquisitionWeight;
        } else {
          return dietStrategyData?.initialWeight;
        }
      } else {
        let startDay = 0;
        const lastPeriod = dietStrategyData.dietPeriods[index - 1];
        if (!dietStrategyData?.generic) {
          // Using lot data
          if (
            moment(form.startDate).isSame(
              moment(dietStrategyData?.lot.referenceAcquisitionDate),
              "day"
            )
          ) {
            return dietStrategyData?.lot.referenceAcquisitionWeight;
          } else {
            startDay = moment(lastPeriod.endDate).diff(
              moment(dietStrategyData?.lot?.referenceAcquisitionDate),
              "days",
              false
            );
            return dietPeriodCurveDatesAndWeights[startDay]?.y;
          }
        } else {
          if (
            moment(form.startDate).isSame(
              moment(dietStrategyData?.initialDate),
              "day"
            )
          ) {
            return dietStrategyData?.initialWeight;
          } else {
            startDay = moment(lastPeriod.endDate).diff(
              moment(dietStrategyData?.initialDate),
              "days",
              false
            );
            return dietPeriodCurveDatesAndWeights[startDay]?.y != null
              ? dietPeriodCurveDatesAndWeights[startDay]?.y
              : dietPeriodCurveDatesAndWeights[
                  dietPeriodCurveDatesAndWeights.length - 1
                ]?.y;
          }
        }
      }
    } else {
      return 0;
    }
  }, [form, dietStrategyData, dietPeriodCurveDatesAndWeights, index]);
  const weightDateOut = useMemo(() => {
    if (form != null) {
      const startDay = form.startDay;
      const endDay = form?.endDay && form.endDay;
      const gdp = form?.gdpExpectation ? form?.gdpExpectation : 0;
      const gdpSum = (endDay - startDay) * gdp;
      let sum = weightDateIn + gdpSum;
      return sum;
    } else {
      return 0;
    }
  }, [form, weightDateIn]);

  // Pasture memos
  const pastureCP = useMemo(() => {
    if (
      form != null &&
      form.pasture != null &&
      form.pastureSeason != null &&
      form.pastureFertilized != null
    ) {
      const pasture = form.pasture.seasons.find(
        (season) =>
          season.season === form.pastureSeason &&
          season.fertilized === form.pastureFertilized
      );
      return pasture.cp;
    } else {
      return 0;
    }
  }, [form]);
  const pastureTDN = useMemo(() => {
    if (
      form != null &&
      form.pasture != null &&
      form.pastureSeason != null &&
      form.pastureFertilized != null
    ) {
      const pasture = form.pasture.seasons.find(
        (season) =>
          season.season === form.pastureSeason &&
          season.fertilized === form.pastureFertilized
      );
      return pasture.tdn;
    } else {
      return 0;
    }
  }, [form]);
  const pastureConsMax = useMemo(() => {
    if (
      form != null &&
      form.pasture != null &&
      form.pastureSeason != null &&
      form.pastureFertilized != null
    ) {
      const pasture = form.pasture.seasons.find(
        (season) =>
          season.season === form.pastureSeason &&
          season.fertilized === form.pastureFertilized
      );
      const consumptionType =
        pasture.consumptionType === "PV_ANIMAL"
          ? "%PV"
          : pasture.consumptionType === "KG"
          ? "Kg"
          : "";
      return `${pasture.maximumConsumption} ${consumptionType}`;
    } else {
      return 0;
    }
  }, [form]);
  const pastureConsMin = useMemo(() => {
    if (
      form != null &&
      form.pasture != null &&
      form.pastureSeason != null &&
      form.pastureFertilized != null
    ) {
      const pasture = form.pasture.seasons.find(
        (season) =>
          season.season === form.pastureSeason &&
          season.fertilized === form.pastureFertilized
      );
      const consumptionType =
        pasture.consumptionType === "PV_ANIMAL"
          ? "%PV"
          : pasture.consumptionType === "KG"
          ? "Kg"
          : "";
      return `${pasture.minimumConsumption} ${consumptionType}`;
    } else {
      return 0;
    }
  }, [form]);

  // Supplement memos
  const supplementCP = useMemo(() => {
    if (form != null && form.supplement != null) {
      const versionIndex = form?.supplement.versions.findIndex(
        (v) => v.version === form?.supplementVersion
      );
      return versionIndex > -1 ? form.supplement.versions[versionIndex].cp : 0;
    } else {
      return 0;
    }
  }, [form]);
  const supplementTDN = useMemo(() => {
    if (form != null && form.supplement != null) {
      const versionIndex = form?.supplement.versions.findIndex(
        (v) => v.version === form?.supplementVersion
      );
      return versionIndex > -1 ? form.supplement.versions[versionIndex].tdn : 0;
    } else {
      return 0;
    }
  }, [form]);
  const supplementDryMatterPercentage = useMemo(() => {
    if (form != null && form.supplement != null) {
      const versionIndex = form?.supplement.versions.findIndex(
        (v) => v.version === form?.supplementVersion
      );
      return versionIndex > -1
        ? form.supplement.versions[versionIndex].dryMatterPercentage
        : 0;
    } else {
      return 0;
    }
  }, [form]);
  const supplementCalcium = useMemo(() => {
    if (form != null && form.supplement != null) {
      const versionIndex = form?.supplement.versions.findIndex(
        (v) => v.version === form?.supplementVersion
      );
      return versionIndex > -1
        ? form.supplement.versions[versionIndex].calcium
        : 0;
    } else {
      return 0;
    }
  }, [form]);
  const supplementPhosphorus = useMemo(() => {
    if (form != null && form.supplement != null) {
      const versionIndex = form?.supplement.versions.findIndex(
        (v) => v.version === form?.supplementVersion
      );
      return versionIndex > -1
        ? form.supplement.versions[versionIndex].phosphorus
        : 0;
    } else {
      return 0;
    }
  }, [form]);
  const supplementZinc = useMemo(() => {
    if (form != null && form.supplement != null) {
      const versionIndex = form?.supplement.versions.findIndex(
        (v) => v.version === form?.supplementVersion
      );
      return versionIndex > -1
        ? form.supplement.versions[versionIndex].zinc
        : 0;
    } else {
      return 0;
    }
  }, [form]);
  const supplementSodium = useMemo(() => {
    if (form != null && form.supplement != null) {
      const versionIndex = form?.supplement.versions.findIndex(
        (v) => v.version === form?.supplementVersion
      );
      return versionIndex > -1
        ? form.supplement.versions[versionIndex].sodium
        : 0;
    } else {
      return 0;
    }
  }, [form]);
  // Voluntary
  const supplementMinimumVoluntaryConsuption = useMemo(() => {
    if (form != null && form.supplement != null) {
      return form.supplement.minimumVoluntaryConsumption;
    } else {
      return 0;
    }
  }, [form]);
  const supplementMaximumVoluntaryConsuption = useMemo(() => {
    if (form != null && form.supplement != null) {
      return form.supplement.maximumVoluntaryConsumption;
    } else {
      return 0;
    }
  }, [form]);
  const supplementVoluntaryConsuptionUnit = useMemo(() => {
    if (form != null && form.supplement != null) {
      return form.supplement.voluntaryConsumptionType !== null
        ? form.supplement.voluntaryConsumptionType === "G_ANIMAL"
          ? "g/dia"
          : form.supplement.voluntaryConsumptionType === "G_KG_PV_ANIMAL"
          ? "g/kg de PV"
          : form.supplement.voluntaryConsumptionType === "PV_ANIMAL"
          ? "%PV"
          : form.supplement.voluntaryConsumptionType === "I_MS_PV_ANIMAL"
          ? "%IMS PV"
          : form.supplement.voluntaryConsumptionType === "KG_ANIMAL"
          ? "kg/dia"
          : "g/dia"
        : "g/dia";
    } else {
      return "g";
    }
  }, [form]);
  // Pure
  const supplementMinimumPureConsuption = useMemo(() => {
    if (form != null && form.supplement != null) {
      return form.supplement.minimumPure;
    } else {
      return 0;
    }
  }, [form]);
  const supplementMaximumPureConsuption = useMemo(() => {
    if (form != null && form.supplement != null) {
      return form.supplement.maximumPure;
    } else {
      return 0;
    }
  }, [form]);
  const supplementMaxPureTrought = useMemo(() => {
    if (form != null && form.supplement != null) {
      return form.supplement.maximumPureTrough || 0;
    } else {
      return 0;
    }
  }, [form]);
  const supplementMinPureTrought = useMemo(() => {
    if (form != null && form.supplement != null) {
      return form.supplement.minimumPureTrough || 0;
    } else {
      return 0;
    }
  }, [form]);
  // Mix
  const supplementMinimumMixConsuption = useMemo(() => {
    if (form != null && form.supplement != null) {
      return form.supplement.minimumMix;
    } else {
      return 0;
    }
  }, [form]);
  const supplementMaximumMixConsuption = useMemo(() => {
    if (form != null && form.supplement != null) {
      return form.supplement.maximumMix;
    } else {
      return 0;
    }
  }, [form]);
  const supplementMaxMixTrought = useMemo(() => {
    if (form != null && form.supplement != null) {
      return form.supplement.maximumMixTrough || 0;
    } else {
      return 0;
    }
  }, [form]);
  const supplementMinMixTrought = useMemo(() => {
    if (form != null && form.supplement != null) {
      return form.supplement.minimumMixTrough || 0;
    } else {
      return 0;
    }
  }, [form]);

  const troughSpacePerAnimal = useMemo(() => {
    if (
      picketInfo != null &&
      picketInfo.totalTroughSpace != null &&
      amountLotAnimals > 0
    ) {
      return getTwoDecimalDigits(
        picketInfo.totalTroughSpace / amountLotAnimals
      );
    } else {
      return 0;
    }
  }, [picketInfo, amountLotAnimals]);
  const troughPerAnimalIsGreaterThanMin = useMemo(() => {
    return troughSpacePerAnimal >= supplementMinPureTrought ? true : false;
  }, [troughSpacePerAnimal, supplementMinPureTrought]);

  /** Memos - End */

  /** Callbacks */

  const calculateRealGdp = useCallback(
    (startDate, endDate) => {
      if (lotRealGdpCurveDatesAndWeights.length === 0) {
        setRealGdp(0);
      } else if (startDate !== null && endDate !== null) {
        const datesAndGdpsInPeriod = lotRealGdpCurveDatesAndWeights.filter(
          (ld) => {
            if (moment(ld.x).isBetween(startDate, endDate, null, "[]")) {
              return true;
            } else {
              return false;
            }
          }
        );
        if (datesAndGdpsInPeriod.length > 0) {
          const newGdp = datesAndGdpsInPeriod.reduce((a, b) => a + b.y, 0);
          setRealGdp(newGdp / datesAndGdpsInPeriod.length);
        } else {
          setRealGdp(0);
        }
      } else {
        setRealGdp(0);
      }
    },
    [lotRealGdpCurveDatesAndWeights]
  );

  /** Method that handle the date period change */
  const handleChangeDatePeriod = useCallback(
    (mDate, type) => {
      /* Variables */
      const { lot, generic, initialDate } = dietStrategyData;
      const baseDate = generic ? initialDate : lot.referenceAcquisitionDate;

      let startDate = null;
      let endDate = null;
      let startDay = null;
      let endDay = null;
      let gdp = null;

      if (mDate != null) {
        const date = mDate.toDate();
        const diffDateInDays = generic
          ? moment(date).diff(initialDate, "days", false)
          : moment(date).diff(lot.referenceBirthdayDate, "days", false);

        /* Validate the startDateDeadline (lot.referenceAcquisitionDate || initialDate (generic DietStategy)) */
        if (
          (type === "start" &&
            moment(date).isSameOrAfter(moment(baseDate), "day")) ||
          type === "end"
        ) {
          /** Validate if endDate is greater than startDate or inverse */
          if (
            (type === "end" &&
              moment(date).isAfter(moment(form.startDate), "day")) ||
            (type === "start" &&
              moment(date).isBefore(moment(form.endDate), "day"))
          ) {
            /** Validate deadLines */
            if (moment(date).isSameOrAfter(startDateDeadline, "days")) {
              /* Set the dates */
              if (type === "start") {
                startDate = moment(date).format("YYYY-MM-DD");
                startDay = diffDateInDays;
                endDate = form.endDate;
                endDay = form.endDay;
              } else if (type === "end") {
                startDate = form.startDate;
                startDay = form.startDay;
                endDate = moment(date).format("YYYY-MM-DD");
                endDay = diffDateInDays;
              }

              /* Recalculate season */
              const seasonMap = seasons.map((s) => s.season);
              let season = [];
              let i = startDay + 1;
              do {
                const currentMonth = generic
                  ? Number(moment(initialDate).add(i, "days").month())
                  : Number(
                      moment(lot.referenceBirthdayDate).add(i, "days").month()
                    );
                let loopSeason = seasonMap[currentMonth];
                if (!season.includes(loopSeason)) {
                  season.push(loopSeason);
                }
                i++;
              } while (i < endDay);

              /* Recalculate gdp */
              gdp = form.gdpExpectation;

              setForm((oldForm) => ({
                ...oldForm,
                startDate,
                endDate,
                startDay,
                endDay,
                season: season.join(","),
                gdpExpectation: gdp,
              }));

              /** Recalculate Real GDP based on new StartDate and EndDate */
              calculateRealGdp(startDate, endDate);
            }
          }
        }
      }
    },
    [calculateRealGdp, dietStrategyData, form, seasons, startDateDeadline]
  );

  /** Method that handle the change of ProductionSubSystem */
  const handleChangeProductionSubSystem = useCallback(
    (value) => {
      setShowModalChangeSystem(
        value &&
          picketInfo &&
          value !== picketInfo.productionSubSystem &&
          isCurrentPeriod
      );

      let updateForm = { ...form, productionSubSystem: value };
      if (value === "Intensive") {
        updateForm = {
          ...updateForm,
          pasture: null,
          pastureSeason: null,
          pastureFertilized: null,
          pastureDailyCost: null,
        };
      }
      setForm(updateForm);
      setErrors((oldErrors) =>
        oldErrors.filter((e) => e !== "productionSubSystem")
      );
    },
    [form, isCurrentPeriod, picketInfo]
  );

  /** Method that handle the change of the Pasture */
  const handleChangePastureSelected = useCallback(
    (value) => {
      let formValue = { ...form };
      try {
        if (value != null) {
          const pasture = pastures.find((p) => p.id === value);
          /** Complete the formValue */
          formValue = {
            ...formValue,
            pasture,
            pastureSeason: null,
            pastureFertilized: null,
            pastureDailyCost: null,
          };

          // Verify how much seasons have in the period
          if (formValue.season.split(",").length === 1) {
            const seasonPeriod = formValue.season.split(",")[0];
            /** Get the seasonSelected */
            const seasonSelected = pastures
              .find((p) => p.id === pasture.id)
              .arraySeasonOptions.find((aso) => aso.season === seasonPeriod);

            /* Verify if the pasture have the season */
            if (seasonSelected != null) {
              const optionsFertilized = seasonSelected?.optionsFertilized;
              /* If the season selected is only fertilized or not, so already select the option and set the daily cost */
              if (optionsFertilized.length === 1) {
                let fertilizedValue = optionsFertilized[0];
                let pastureFertilized =
                  fertilizedValue === "Yes" ? true : false;
                let pastureCost = pasture.seasons.find(
                  (season) =>
                    season.season === seasonPeriod &&
                    season.fertilized === pastureFertilized
                )?.cost;
                /** Complete the formValue */
                formValue = {
                  ...formValue,
                  pastureSeason: seasonPeriod,
                  pastureFertilized,
                  pastureDailyCost: pasture.seasons.find(
                    (season) =>
                      season.season === seasonPeriod &&
                      season.fertilized === pastureFertilized
                  )
                    ? pastureCost
                    : 0,
                };
              } else {
                // end if optionsFertilized.length === 1 and start else
                /** Complete the formValue */
                formValue = {
                  ...formValue,
                  pastureSeason: seasonPeriod,
                  pastureFertilized: null,
                  pastureDailyCost: null,
                };
              }
            } else {
              // end if seasonSelected and start else
              /** Complete the formValue */
              formValue = {
                ...formValue,
                pastureSeason: null,
                pastureFertilized: null,
                pastureDailyCost: null,
              };
            }
          } // end if how much seasons

          // Set Form
          setForm(formValue);
        } else {
          formValue = {
            ...formValue,
            pasture: null,
            pastureSeason: null,
            pastureFertilized: null,
            pastureDailyCost: null,
          };

          // Set Form
          setForm(formValue);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [form, pastures]
  );

  /** Method that handle the change of pasture season */
  const handleChangePastureSeasonSelected = useCallback(
    (value, pPasture = null) => {
      let formValue = { ...form };
      const pasture = pPasture === null ? form.pasture : pPasture;
      try {
        /** Verify if value is not null */
        if (value != null) {
          const seasonSelected = pastures
            .find((p) => p.id === pasture.id)
            .arraySeasonOptions.find((aso) => aso.season === value);

          /* Verify if the pasture have the season */
          if (seasonSelected != null) {
            const optionsFertilized = seasonSelected?.optionsFertilized;
            /* If the season selected is only fertilized or not, so already select the option and set the daily cost */
            if (optionsFertilized.length === 1) {
              let fertilizedValue = optionsFertilized[0];
              let pastureFertilized = fertilizedValue === "Yes" ? true : false;
              let pastureCost = pasture.seasons.find(
                (season) =>
                  season.season === value &&
                  season.fertilized === pastureFertilized
              )?.cost;

              formValue = {
                ...form,
                pastureSeason: value,
                pastureFertilized,
                pastureDailyCost: pasture.seasons.find(
                  (season) =>
                    season.season === value &&
                    season.fertilized === pastureFertilized
                )
                  ? pastureCost
                  : 0,
              };
            } else {
              formValue = {
                ...form,
                pastureSeason: value,
                pastureFertilized: null,
                pastureDailyCost: null,
              };
            }
          } else {
            formValue = {
              ...form,
              pastureSeason: value,
              pastureFertilized: null,
              pastureDailyCost: null,
            };
          }
        }
        setErrors((oldErrors) =>
          oldErrors.filter((e) => e !== "pastureFertilized")
        );
        setForm(formValue);
      } catch (error) {
        console.error(error);
      }
    },
    [form, pastures]
  );

  /** Method that handle the change of pasture season fertilized  */
  const handleChangePastureSeasonFertilizedSelected = useCallback(
    (value) => {
      if (value != null) {
        let pastureFertilized = value === "Yes" ? true : false;
        const newForm = {
          ...form,
          pastureFertilized,
          pastureDailyCost: form.pasture.seasons.find(
            (season) =>
              season.season === form.pastureSeason &&
              season.fertilized === pastureFertilized
          )
            ? form.pasture.seasons.find(
                (season) =>
                  season.season === form.pastureSeason &&
                  season.fertilized === pastureFertilized
              ).cost
            : 0,
        };
        setForm(newForm);
      }
    },
    [form]
  );

  /** Calbacks - End */

  /** Effects */
  useEffect(() => {
    setErrors([]);
    if (dietStrategyPeriodSelected !== null) {
      async function setPastureAndSupplement() {
        setLoadingDiets(true);
        setTimeout(() => setLoadingDiets(false), 500);
      }
      setForm(dietStrategyPeriodSelected);
      setPastureAndSupplement();
      calculateRealGdp(
        dietStrategyPeriodSelected?.startDate,
        dietStrategyPeriodSelected?.endDate
      );
    }
  }, [calculateRealGdp, dietStrategyPeriodSelected]);

  /** Methods */
  function goNext() {
    if (shouldBlockOnEdit) {
      onAction(form, "nextWithoutSave");
    } else if (validation()) {
      validateSupplementConsumptionByAnimalAndDoAction("next");
    }
  }

  function goPrevius() {
    if (shouldBlockOnEdit) {
      onAction(form, "previousWithoutSave");
    } else if (validation()) {
      validateSupplementConsumptionByAnimalAndDoAction("previous");
    }
  }

  function saveAndFinalize() {
    if (validation()) {
      validateSupplementConsumptionByAnimalAndDoAction("finalize");
    }
  }

  function remove() {
    setForm(null);
    onAction(form, "remove");
  }

  function closeModal() {
    if (signal) signal.cancel("Canceling GDP");
    setLoadingGDPValidation(false);
    setForm(null);
    onAction(null);
  }

  const validateSupplementConsumptionByAnimalAndDoAction = (action) => {
    if (
      form?.supplement != null &&
      form?.supplementConsumptionByAnimal != null &&
      form?.supplementConsumptionByAnimal > 0
    ) {
      let haveSomeError = false;
      let blockSave = false;
      let message = null;
      // 1 - Verify if the consumption by animal is between min/max of voluntary
      if (
        form?.supplementConsumptionByAnimal <
          supplementMinimumVoluntaryConsuption ||
        form?.supplementConsumptionByAnimal >
          supplementMaximumVoluntaryConsuption
      ) {
        if (
          // 2, 3 - If dont have pure and mix minimum and the consumption by animal is less than min voluntary or bigger than max voluntary
          (supplementMinimumPureConsuption === null ||
            supplementMinimumPureConsuption === 0) &&
          (supplementMinimumMixConsuption === null ||
            supplementMinimumMixConsuption === 0) &&
          (form?.supplementConsumptionByAnimal <
            supplementMinimumVoluntaryConsuption ||
            form?.supplementConsumptionByAnimal >
              supplementMaximumVoluntaryConsuption)
        ) {
          haveSomeError = true;
          blockSave = true;
          message =
            "Consumo fora da faixa Mínimo/Máximo do Consumo Voluntário.";
        } else if (
          // 4 - If have pure or mix minimum and the consumption by animal is less than min pure or min mix
          (supplementMinimumPureConsuption != null &&
            supplementMinimumPureConsuption > 0 &&
            form?.supplementConsumptionByAnimal <
              supplementMinimumPureConsuption) ||
          (supplementMinimumMixConsuption != null &&
            supplementMinimumMixConsuption > 0 &&
            form?.supplementConsumptionByAnimal <
              supplementMinimumMixConsuption)
        ) {
          haveSomeError = true;
          blockSave = true;
          message =
            "Consumo fora da faixa Mínimo/Máximo. Não há garantia da metabolização do Produto.";
        } else if (
          // 5 - If have pure or mix maximum and the consumption by animal is bigger than max pure or max mix
          (supplementMaximumPureConsuption != null &&
            supplementMaximumPureConsuption > 0 &&
            form?.supplementConsumptionByAnimal >=
              supplementMaximumPureConsuption) ||
          (supplementMaximumMixConsuption != null &&
            supplementMaximumMixConsuption > 0 &&
            form?.supplementConsumptionByAnimal >=
              supplementMaximumMixConsuption)
        ) {
          haveSomeError = true;
          blockSave = false;
          message =
            "Consumo fora da faixa Mínimo/Máximo. Risco de envenenamento ou desperdício do Produto.";
        } else if (
          // 6 - If consumption by animal is less than min voluntary and bigger than min pure or min mix
          form?.supplementConsumptionByAnimal <
            supplementMinimumVoluntaryConsuption &&
          ((supplementMinimumPureConsuption != null &&
            supplementMinimumPureConsuption > 0 &&
            form?.supplementConsumptionByAnimal >=
              supplementMinimumPureConsuption) ||
            (supplementMinimumMixConsuption != null &&
              supplementMinimumMixConsuption > 0 &&
              form?.supplementConsumptionByAnimal >=
                supplementMinimumMixConsuption))
        ) {
          haveSomeError = true;
          blockSave = false;
          message =
            "Alguns animais podem estar ingerindo menos do que o necessário recomendado.";
        } else if (
          // 7 - If consumption by animal is bigger than max voluntary and less than max pure or max mix
          form?.supplementConsumptionByAnimal >
            supplementMaximumVoluntaryConsuption &&
          ((supplementMaximumPureConsuption != null &&
            form?.supplementConsumptionByAnimal <=
              supplementMaximumPureConsuption) ||
            (supplementMaximumMixConsuption != null &&
              form?.supplementConsumptionByAnimal <=
                supplementMaximumMixConsuption))
        ) {
          haveSomeError = true;
          blockSave = false;
          message = "Pode ser que sobre produto no coxo.";
        }
      }
      // If have some error
      if (haveSomeError) {
        // If dont need to block the save
        if (!blockSave) {
          Modal.warning({
            title: "Atenção",
            content: message,
            centered: true,
            okText: "Estou ciente",
            onOk: () => {
              validateGdpAndDoAction(action);
            },
          });
        } else {
          // If need to block the save
          Modal.error({
            title: "Erro",
            content: message,
            centered: true,
            okText: "Ok",
          });
          setErrors([...errors, "supplementConsumptionByAnimal"]);
        }
      } else {
        validateGdpAndDoAction(action);
      }
    } else {
      validateGdpAndDoAction(action);
    }
  };

  /** Validate form */
  function validation() {
    let submitErrors = [];

    if (form?.productionSubSystem == null) {
      submitErrors = [...submitErrors, "productionSubSystem"];
    }

    if (
      form?.productionSubSystem != null &&
      form?.productionSubSystem === "Extensive"
    ) {
      /* Validate pastures fields*/
      if (form?.pasture === null) {
        submitErrors = [...submitErrors, "pasture"];
      }
      if (form?.pastureSeason === null) {
        submitErrors = [...submitErrors, "pastureSeason"];
      }
      if (form?.pastureFertilized === null) {
        submitErrors = [...submitErrors, "pastureFertilized"];
      }
      if (form?.pastureDailyCost === null) {
        submitErrors = [...submitErrors, "pastureDailyCost"];
      }
    }

    if (form?.pasture) {
      if (form?.pastureSeason === null) {
        submitErrors = [...submitErrors, "pastureSeason"];
      }
      if (form?.pastureFertilized === null) {
        submitErrors = [...submitErrors, "pastureFertilized"];
      }
      if (form?.pastureDailyCost === null) {
        submitErrors = [...submitErrors, "pastureDailyCost"];
      }
    }

    /* If productionSubSystem is Semi-Intensive so validate supplement fields */
    if (
      form?.supplement == null &&
      (form?.productionSubSystem === "SemiIntensive" ||
        form?.productionSubSystem === "Intensive")
    ) {
      submitErrors = [...submitErrors, "supplement"];
    }

    if (form?.supplement != null) {
      if (
        form?.supplementConsumptionByAnimal == null ||
        form?.supplementConsumptionByAnimal === 0
      ) {
        submitErrors = [...submitErrors, "supplementConsumptionByAnimal"];
      }
      if (form?.supplementConsumptionMeasureType == null) {
        submitErrors = [...submitErrors, "supplementConsumptionMeasureType"];
      }
      if (form?.supplementConsumptionType == null) {
        submitErrors = [...submitErrors, "supplementConsumptionType"];
      }
      if (form?.supplementDailyCost == null) {
        submitErrors = [...submitErrors, "supplementDailyCost"];
      }
    }

    if (form?.gdpExpectation == null || form?.gdpExpectation === 0) {
      submitErrors = [...submitErrors, "gdpExpectation"];
    }

    if (form?.carcassHarnessing == null) {
      submitErrors = [...submitErrors, "carcassHarnessing"];
    }

    if (
      form?.gdpExpectation != null &&
      (form?.gdpExpectation < -0.5 || form?.gdpExpectation > 3)
    ) {
      submitErrors = [...submitErrors, "gdpExpectation"];
      notification.error({
        message: "A expectativa de GDP deve estar entre -0.5 Kg e 3 Kg",
      });
    }

    if (
      form?.carcassHarnessing != null &&
      (form?.carcassHarnessing < 45 || form?.carcassHarnessing > 60)
    ) {
      submitErrors = [...submitErrors, "carcassHarnessing"];
      notification.error({
        message: "O aproveitamento de carcaça deve estar entre 45% e 60%",
      });
    }

    if (submitErrors.length > 0) {
      setErrors(submitErrors);
      return false;
    } else {
      setErrors([]);
      return true;
    }
  }

  /** Method validate gdp */
  async function validateGdpAndDoAction(action) {
    setLoadingGDPValidation(true);
    const newSignal = Axios.CancelToken.source();
    setSignal(newSignal);
    try {
      const body = createRequestToValidateGDP();
      let {
        data: { results: result },
      } = await callValidationGdp({
        groupId,
        farmId,
        lotId: dietStrategyData.generic ? null : dietStrategyData.lot.id,
        breedId: dietStrategyData.generic ? dietStrategyData.breedId : null,
        body,
        signal: newSignal,
      });
      const carbonFootprintParameter = classifyPeriodCarbonFootPrint(
        carbonFootprintParameters,
        form
      );
      /** Set the result into the form */
      if (result != null) {
        setForm({
          ...form,
          carbonFootprintParameter,
          gdpPossible: result.weightGain,
          pastureConsumed: result.pasture?.Consumed,
          pastureNeeded: result.pasture?.Need,
          supplementConsumed: result.trough?.Consumed,
          supplementNeeded: result.trough?.Need,
          tdnExceededOrMissing: result.tdnExcededOrMissing,
          tdnExceededOrMissingValue: result.tdnExcededOrMissingValue,
          cpExceededOrMissing: result.cpExcededOrMissing,
          cpExceededOrMissingValue: result.cpExcededOrMissingValue,
          balancePriceCarbonFootprint: result.balancePriceCarbonFootprint,
          balanceCarbonFootprint: result.balanceCarbonFootprint,
          calciumCode: result.minerals.Ca.Code,
          calciumConsumed: result.minerals.Ca.Consumed,
          calciumNeeded: result.minerals.Ca.Necessary,
          phosporusCode: result.minerals.P.Code,
          phosporusConsumed: result.minerals.P.Consumed,
          phosporusNeeded: result.minerals.P.Necessary,
          zincCode: result.minerals.Zn.Code,
          zincConsumed: result.minerals.Zn.Consumed,
          zincNeeded: result.minerals.Zn.Necessary,
          sodiumCode: result.minerals.Na.Code,
          sodiumConsumed: result.minerals.Na.Consumed,
          sodiumNeeded: result.minerals.Na.Necessary,
        });
        setGdpValidationReturn(result);
        setExecuteAction(action);
        setModalGdpValidationVisible(true);
      } else {
        const diet = {
          ...form,
          carbonFootprintParameter,
          conflictedGdp: false,
        };
        notification.error({
          message: "Houve um erro ao validar ganho de peso para esta dieta",
        });
        setForm(null);
        onAction(diet, action);
      }
    } catch (error) {
      if (!Axios.isCancel(error)) {
        notification.error({
          message: "Houve um erro ao validar ganho de peso para esta dieta",
        });
        const body = {
          ...form,
          allWeightGains: [],
        };
        setForm(null);
        onAction(body, action);
      }
    } finally {
      setLoadingGDPValidation(false);
    }
  }

  /** Method to create the request to validate gdp */
  function createRequestToValidateGDP() {
    try {
      let body = [];
      let bodyItem = {
        ExpectedWeightGain: form.gdpExpectation,
        CO2BaseKg_KgPV: carbonBaseKg || 0,
        CarbonCredit: carbonCredit || 0,
        System:
          form.productionSubSystem === "Extensive"
            ? "Extensivo"
            : form.productionSubSystem === "SemiIntensive"
            ? "Semi-Intensivo"
            : form.productionSubSystem === "Intensive"
            ? "Intensivo"
            : null,
      };
      let FirstWeightOnDiet = weightDateIn;
      /** Create DietDetails - Start */
      let DietDetails = [];
      const carbonFootprintParameter = classifyPeriodCarbonFootPrint(
        carbonFootprintParameters,
        form
      );
      setForm((old) => ({ ...old, carbonFootprintParameter }));
      let diet = {
        Begin: form.startDate,
        End: form.endDate,
        CO2Kg_KgPV:
          carbonFootprintParameter != null
            ? carbonFootprintParameter?.co2ProductionMean
            : 0,
      };
      /** Create pasture DTO */
      let Pasture = {};
      if (form?.pasture != null && form?.productionSubSystem !== "Intesive") {
        let pastureSeason = form.pasture.seasons.find(
          (s) =>
            s.season === form.pastureSeason &&
            s.fertilized === form.pastureFertilized
        );
        Pasture = {
          ...Pasture,
          id: form.pasture.id,
          NDT: pastureSeason?.tdn,
          PB: pastureSeason?.cp,
          FDN: pastureSeason?.fdn / 100,
          Ca: pastureSeason?.calcium || 0, // g/kg
          P: pastureSeason?.phosphorus || 0, // g/kg
          Na: pastureSeason?.sodium || 0, // g/kg
          Zn: pastureSeason?.zinc || 0, // mg/kg
          ConsumptionUnit:
            pastureSeason?.consumptionType === "PV_ANIMAL"
              ? "PV"
              : pastureSeason?.consumptionType === "KG"
              ? "g"
              : null,
          MaxConsumption:
            pastureSeason?.consumptionType === "PV_ANIMAL"
              ? pastureSeason?.maximumConsumption / 100
              : pastureSeason?.consumptionType === "KG"
              ? pastureSeason?.maximumConsumption * 1000
              : pastureSeason?.maximumConsumption,
          MinConsumption:
            pastureSeason?.consumptionType === "PV_ANIMAL"
              ? pastureSeason?.minimumConsumption / 100
              : pastureSeason?.consumptionType === "KG"
              ? pastureSeason?.minimumConsumption * 1000
              : pastureSeason?.minimumConsumption,
          FDA: pastureSeason?.fda ? pastureSeason?.fda : 0,
          Lignina: pastureSeason?.lignin ? pastureSeason?.lignin : 0,
          EE: pastureSeason?.ee ? pastureSeason?.ee / 100 : 0,
        };
      } else {
        Pasture = null;
      }
      /** Create Supplement DTO */
      let Trough = {};
      let Additive = {};
      if (form.supplement != null) {
        const versionIndex = form?.supplement.versions.findIndex(
          (v) => v.version === form?.supplementVersion
        );
        if (versionIndex > -1) {
          const supplementVersion = form?.supplement.versions[versionIndex];
          Trough = {
            ...Trough,
            id: form.supplement?.id,
            NDT: supplementVersion?.tdn,
            PB: supplementVersion?.cp,
            Ca: supplementVersion?.calcium || 0, // g/kg
            P: supplementVersion?.phosphorus || 0, // g/kg
            Na: supplementVersion?.sodium || 0, // g/kg
            Zn: supplementVersion?.zinc || 0, // mg/kg
            DryMatterPercentage:
              supplementVersion?.dryMatterPercentage == null
                ? 1
                : supplementVersion?.dryMatterPercentage / 100,
            ConsumptionUnit:
              form?.supplementConsumptionMeasureType === "PV_ANIMAL"
                ? "PV"
                : form?.supplementConsumptionMeasureType === "I_MS_PV_ANIMAL"
                ? "I_MS_PV"
                : form?.supplementConsumptionMeasureType === "G_KG_PV_ANIMAL"
                ? "g_kg_pv"
                : form?.supplementConsumptionMeasureType === "G_ANIMAL"
                ? "g"
                : form?.supplementConsumptionMeasureType === "KG_ANIMAL"
                ? "g"
                : null,
            Consumption:
              form?.supplementConsumptionMeasureType === "PV_ANIMAL"
                ? form?.supplementConsumptionByAnimal / 100
                : form?.supplementConsumptionMeasureType === "I_MS_PV_ANIMAL"
                ? form?.supplementConsumptionByAnimal / 100
                : form?.supplementConsumptionMeasureType === "G_KG_PV_ANIMAL"
                ? form?.supplementConsumptionByAnimal
                : form?.supplementConsumptionMeasureType === "G_ANIMAL"
                ? form?.supplementConsumptionByAnimal
                : form?.supplementConsumptionMeasureType === "KG_ANIMAL"
                ? form?.supplementConsumptionByAnimal * 1000
                : form?.supplementConsumptionByAnimal,
            MaxConsumption:
              form.supplement.voluntaryConsumptionType === "PV_ANIMAL"
                ? form.supplement?.maximumVoluntaryConsumption / 100
                : form.supplement.voluntaryConsumptionType === "I_MS_PV_ANIMAL"
                ? form.supplement?.maximumVoluntaryConsumption / 100
                : form.supplement.voluntaryConsumptionType === "KG_ANIMAL"
                ? form.supplement?.maximumVoluntaryConsumption * 1000
                : form.supplement?.maximumVoluntaryConsumption,
            MinConsumption:
              form.supplement.voluntaryConsumptionType === "PV_ANIMAL"
                ? form.supplement?.minimumVoluntaryConsumption / 100
                : form.supplement.voluntaryConsumptionType === "I_MS_PV_ANIMAL"
                ? form.supplement?.minimumVoluntaryConsumption / 100
                : form.supplement.voluntaryConsumptionType === "KG_ANIMAL"
                ? form.supplement?.minimumVoluntaryConsumption * 1000
                : form.supplement?.minimumVoluntaryConsumption,
            MinMaxUnit:
              form.supplement?.voluntaryConsumptionType !== null
                ? form.supplement?.voluntaryConsumptionType === "G_ANIMAL"
                  ? "g"
                  : form.supplement?.voluntaryConsumptionType ===
                    "G_KG_PV_ANIMAL"
                  ? "g_kg_pv"
                  : form.supplement?.voluntaryConsumptionType === "KG_ANIMAL"
                  ? "g"
                  : form.supplement.voluntaryConsumptionType === "PV_ANIMAL"
                  ? "PV"
                  : form.supplement.voluntaryConsumptionType ===
                    "I_MS_PV_ANIMAL"
                  ? "I_MS_PV"
                  : "g"
                : "g",
            FDA: form?.supplement?.fda ? form?.supplement?.fda : 0,
            Lignina: form?.supplement?.lignin ? form?.supplement?.lignin : 0,
            EE: form?.supplement?.ee ? form?.supplement?.ee / 100 : 0,
          };

          let unit =
            supplementVersion?.additiveEffectUnit === "PERCENTAGE_PB"
              ? supplementVersion?.additiveEffectUnit.toLowerCase()
              : "grams_per_day";
          let additiveValue = 0;
          if (supplementVersion?.additiveEffect) {
            additiveValue =
              supplementVersion?.additiveEffectUnit === "G_DAY"
                ? supplementVersion?.additiveEffect
                : supplementVersion?.additiveEffect / 100;
          }
          Additive = {
            ...Additive,
            Unit: unit,
            Value: additiveValue,
          };
        } else {
          Trough = null;
          Additive = null;
        }
      } else {
        Trough = null;
        Additive = null;
      }

      let SexualClass = null;
      let GeneticGroup = null;

      if (dietStrategyData.generic) {
        const breed = breeds.find((b) => b.id === dietStrategyData.breedId);
        GeneticGroup = breed.geneticGroup;
        SexualClass =
          dietStrategyData.gender === "Male" && dietStrategyData.cap
            ? "MC"
            : dietStrategyData.gender === "Male" && !dietStrategyData.cap
            ? "MNC"
            : "F";
      }

      diet = {
        ...diet,
        Pasture,
        Trough,
        Additive,
      };

      DietDetails = [...DietDetails, diet];

      /** Create DietDetails - End */
      bodyItem = {
        ...bodyItem,
        GeneticGroup,
        SexualClass,
        FirstWeightOnDiet,
        DietDetails,
      };
      body = [...body, bodyItem];
      return { Diet: body };
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /** Method close ModalGdpValidation to edit Diet */
  function closeModalGdpValidation() {
    setExecuteAction(null);
    setModalGdpValidationVisible(false);
  }

  /** Method ignore ModalGdpValidation and save */
  function ignoreGdpAlertAndSave() {
    const action = executeAction;
    const { code, allWeightGains, carbonFootPrint } = gdpValidationReturn;
    let conflictedGdp = false;
    switch (code) {
      case "2.2":
      case "4.1":
      case "4.2":
        conflictedGdp = false;
        break;
      case "2.1":
      case "2.3":
      case "2.4":
      case "4.3":
      case "4.4":
        conflictedGdp = true;
        break;
      default:
        break;
    }
    let dietPeriodAllWeightGains = [];
    let dietPeriodCarbonFootPrint = [];
    if (allWeightGains?.length > 0) {
      let loopStartDay = form.startDay;
      allWeightGains.forEach((gdp, i) => {
        loopStartDay = i === 0 ? loopStartDay : loopStartDay + 1;
        dietPeriodAllWeightGains.push({
          gdp,
          gdpDate:
            i === 0
              ? moment(form.startDate)
              : moment(form.startDate).add(i, "days"),
          gdpDay: loopStartDay,
        });
      });
    }
    if (carbonFootPrint?.length > 0) {
      let loopStartDay = form.startDay;
      carbonFootPrint.forEach((carbon, i) => {
        loopStartDay = i === 0 ? loopStartDay : loopStartDay + 1;
        dietPeriodCarbonFootPrint.push({
          carbon,
          carbonDate:
            i === 0
              ? moment(form.startDate)
              : moment(form.startDate).add(i, "days"),
          carbonDay: loopStartDay,
        });
      });
    }
    const body = {
      ...form,
      conflictedGdp,
      allWeightGains: dietPeriodAllWeightGains,
      carbonFootPrint: dietPeriodCarbonFootPrint,
    };
    setExecuteAction(null);
    setForm(null);
    onAction(body, action);
    setModalGdpValidationVisible(false);
  }

  /** Method to cancel the gdp validation */
  function handleCancelGdpValidation() {
    signal.cancel("Canceling GDP");
    setLoadingGDPValidation(false);
  }

  return (
    <PeriodModal
      width={955}
      visible={visible}
      centered
      maskClosable={false}
      closable={true}
      onCancel={closeModal}
      footer={null}
    >
      {form != null ? (
        <>
          <Container>
            <Header>
              <span>
                Complete os campos abaixo para definir a dieta entre o período
                selecionado
              </span>
            </Header>
            {shouldBlockOnEdit && (
              <BoxText
                color="#FE8D2A"
                iconComponent={
                  <Icon type="warning" style={{ color: "#FE8D2A" }} />
                }
              >
                <span>Exclusão/Edição não permitida</span>
              </BoxText>
            )}
            <Body>
              <Spin
                spinning={loadinDiets || loadinGDPValidation}
                tip={
                  loadinGDPValidation ? (
                    <>
                      <span>Validando GDP...</span>
                      <br />
                      <Button
                        type="danger"
                        onClick={() => handleCancelGdpValidation()}
                      >
                        Cancelar
                      </Button>
                    </>
                  ) : (
                    ""
                  )
                }
              >
                {errors.length > 0 && (
                  <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    style={{ marginBottom: 10 }}
                  >
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                {/* Start Date and End Date */}
                <Row type="flex">
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    xl={{ span: 24, offset: 0 }}
                  >
                    <Row
                      type="flex"
                      className="rowInput"
                      justify="space-between"
                    >
                      {/* Start Date */}
                      <Col
                        xs={{ span: 6, offset: 0 }}
                        sm={{ span: 6, offset: 0 }}
                        md={{ span: 6, offset: 0 }}
                        lg={{ span: 6, offset: 0 }}
                        xl={{ span: 6, offset: 0 }}
                      >
                        <Row>
                          <label>{`Ínicio (Min: ${moment(
                            startDateDeadline
                          ).format("DD/MM/YYYY")})`}</label>
                        </Row>
                        <Row>
                          <label>{`Peso: ${numberMask(
                            weightDateIn || 0,
                            false
                          )} Kg`}</label>
                        </Row>
                        <Row>
                          <DatePicker
                            value={moment(form.startDate, "YYYY-MM-DD")}
                            format={dateFormat}
                            onChange={(date) => {
                              handleChangeDatePeriod(date, "start");
                            }}
                            disabled={true}
                            allowClear={false}
                          />
                        </Row>
                      </Col>
                      {/* Seasons */}
                      <Col
                        xs={{ span: 11, offset: 1 }}
                        sm={{ span: 11, offset: 1 }}
                        md={{ span: 11, offset: 1 }}
                        lg={{ span: 11, offset: 1 }}
                        xl={{ span: 11, offset: 1 }}
                      >
                        <CustomDivider dashed>
                          <div className="seasonContainer">
                            {form?.season
                              .split(",")
                              .map((season) =>
                                season === "Water" ? (
                                  <RainIcon key={season} />
                                ) : season === "Dry" ? (
                                  <SunIcon key={season} />
                                ) : season === "Transition" ? (
                                  <TransitionIcon key={season} />
                                ) : null
                              )}
                          </div>
                        </CustomDivider>
                      </Col>
                      {/* End Date */}
                      <Col
                        xs={{ span: 5, offset: 1 }}
                        sm={{ span: 5, offset: 1 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                      >
                        <Row>
                          <label></label>
                        </Row>
                        <Row>
                          <label>{`Peso: ${numberMask(
                            weightDateOut,
                            false
                          )} Kg`}</label>
                        </Row>
                        <Row>
                          <DatePicker
                            value={moment(form.endDate, "YYYY-MM-DD")}
                            format={dateFormat}
                            onChange={(date) => {
                              handleChangeDatePeriod(date, "end");
                            }}
                            disabled={
                              shouldBlockOnEdit
                                ? true
                                : dietStrategyData.dietPeriods.length <= 1
                                ? false
                                : index >=
                                  dietStrategyData.dietPeriods.length - 1
                                ? false
                                : true
                            }
                            allowClear={false}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* ProductionSubSystem, Gdp Real (If is Baseline) Gdp Expectation e CarcassHarnessing */}
                <Row
                  type="flex"
                  style={{ marginTop: 10 }}
                  justify="start"
                  gutter={8}
                >
                  {/* ProductionSubSystem */}
                  <Col span={9}>
                    <Row type="flex" justify="space-between">
                      <label
                        className={
                          errors.includes("productionSubSystem") ? "error" : ""
                        }
                      >
                        SubSistema de Produção*
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <Select
                        style={{ width: "100%" }}
                        name="productionSubSystem"
                        value={
                          form.productionSubSystem == null
                            ? undefined
                            : form.productionSubSystem
                        }
                        placeholder={
                          translation.profitCenter.form
                            .productionSubSystemPlaceHolder
                        }
                        disabled={shouldBlockOnEdit}
                        onChange={(value) => {
                          handleChangeProductionSubSystem(value);
                        }}
                      >
                        {Object.entries(productionSubSystems).length > 0 &&
                          productionSubSystems.map((pss, i) => (
                            <Option key={i} value={pss.code}>
                              {pss.name}
                            </Option>
                          ))}
                      </Select>
                    </Row>
                  </Col>
                  {/* Real Gdp */}
                  <Col span={5}>
                    <Row>
                      <label>
                        GDP Médio do Lote{" "}
                        <InfoTooltip title="Esta média corresponde à quantidade de dias que existem pesagens para o período." />
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <NumberFormat
                        customInput={Input}
                        value={realGdp}
                        disabled
                        name="realGdp"
                        addonAfter="Kg"
                        decimalScale={"2"}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        min={0}
                      />
                    </Row>
                  </Col>
                  {/* Gdp Expectation */}
                  <Col span={5}>
                    <Row>
                      <label
                        className={
                          errors.includes("gdpExpectation") ? "error" : ""
                        }
                      >
                        Expectativa de GDP*
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <NumberFormat
                        customInput={Input}
                        value={
                          form?.gdpExpectation !== null
                            ? form?.gdpExpectation
                            : null
                        }
                        name="gdpExpectation"
                        addonAfter="Kg"
                        decimalScale={"2"}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        disabled={shouldBlockOnEdit}
                        onValueChange={({ floatValue }) => {
                          setForm({
                            ...form,
                            gdpExpectation: floatValue ? floatValue : 0,
                          });
                          setErrors(
                            errors.filter((e) => e !== "gdpExpectation")
                          );
                        }}
                      />
                    </Row>
                  </Col>
                  {/* CarcassHarnessing */}
                  <Col span={5}>
                    <Row>
                      <label
                        className={
                          errors.includes("carcassHarnessing") ? "error" : ""
                        }
                      >
                        Aproveit. de carcaça*{" "}
                      </label>
                      <InfoTooltip title="O % de Aproveitamento de Carcaça, informado na Estratégia de Dieta, impactará nos cálculos de resultado e faturamento dos Cenários de Venda." />
                    </Row>
                    <Row className="rowInput">
                      <Tooltip
                        trigger="hover"
                        placement="bottomRight"
                        title="O valor mencionado não será utilizado em animais que possuem aproveitamento de carcaça próprio"
                      >
                        <NumberFormat
                          customInput={Input}
                          value={
                            form.carcassHarnessing !== null
                              ? form.carcassHarnessing
                              : null
                          }
                          name="carcassHarnessing"
                          placeholder={translation.defaultPlaceholder}
                          disabled={index > 0 || shouldBlockOnEdit}
                          addonAfter="%"
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalSeparator=","
                          thousandSeparator="."
                          onValueChange={({ floatValue }) => {
                            setForm({
                              ...form,
                              carcassHarnessing: floatValue || 0,
                            });
                            setErrors(
                              errors.filter((e) => e !== "carcassHarnessing")
                            );
                          }}
                        />
                      </Tooltip>
                    </Row>
                  </Col>
                </Row>
                {/* Pasture - Start */}
                <CustomDivider>FORRAGEIRA</CustomDivider>
                <Row type="flex" justify="start" gutter={8}>
                  {/* Pasture */}
                  <Col span={9}>
                    <Row>
                      <label
                        className={errors.includes("pasture") ? "error" : ""}
                      >
                        Forrageira
                        {form?.productionSubSystem !== "SemiIntensive"
                          ? "*"
                          : null}
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <Select
                        name="pasture"
                        style={{ width: "100%" }}
                        value={
                          form.pasture != null ? form.pasture.id : undefined
                        }
                        placeholder={translation.defaultSelectPlaceholder}
                        disabled={
                          shouldBlockOnEdit
                            ? true
                            : form?.productionSubSystem === "Intensive"
                            ? true
                            : false
                        }
                        showSearch
                        allowClear={
                          form?.productionSubSystem !== "SemiIntensive"
                            ? false
                            : true
                        }
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          handleChangePastureSelected(value);
                          setErrors(errors.filter((e) => e !== "pasture"));
                        }}
                      >
                        {pastures != null && Object.entries(pastures).length > 0
                          ? pastures.map((p) => (
                              <Select.Option key={p.id} value={p.id}>
                                {p.name}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Row>
                  </Col>
                  {/* Pasture Season */}
                  <Col span={5}>
                    <Row>
                      <label
                        className={
                          errors.includes("pastureSeason") ? "error" : ""
                        }
                      >
                        Período climático
                        {form?.productionSubSystem === "Extensive" ||
                        form?.pasture !== null
                          ? "*"
                          : null}
                        <Tooltip
                          placement="top"
                          title={`O período climático refere-se aos dados nutricionais e capacidade de
                              suporte referente a forrageira selecionada para este período`}
                        >
                          <InfoIcon />
                        </Tooltip>
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <Select
                        name="pastureSeason"
                        style={{ width: "100%" }}
                        value={
                          form.pasture != null && form.pastureSeason != null
                            ? form.pastureSeason
                            : undefined
                        }
                        placeholder={translation.defaultSelectPlaceholder}
                        disabled={
                          shouldBlockOnEdit
                            ? true
                            : form?.productionSubSystem === "Intensive"
                            ? true
                            : false
                        }
                        onChange={(value) => {
                          handleChangePastureSeasonSelected(value);
                          setErrors(
                            errors.filter((e) => e !== "pastureSeason")
                          );
                        }}
                      >
                        {form.pasture != null &&
                        pastures.find((p) => p.id === form.pasture.id) !=
                          null &&
                        pastures.find((p) => p.id === form.pasture.id)
                          .arraySeasonOptions.length > 0
                          ? pastures
                              .find((p) => p.id === form.pasture.id)
                              .arraySeasonOptions.map((p) => (
                                <Select.Option key={p.season} value={p.season}>
                                  {p.season === "Water"
                                    ? "Chuva"
                                    : p.season === "Dry"
                                    ? "Seca"
                                    : p.season === "Transition"
                                    ? "Transição"
                                    : null}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Row>
                  </Col>
                  {/* Pasture Fertilized */}
                  <Col span={5}>
                    <Row>
                      <label
                        className={
                          errors.includes("pastureFertilized") ? "error" : ""
                        }
                      >
                        Adubado
                        {form?.productionSubSystem === "Extensive" ||
                        form?.pasture !== null
                          ? "*"
                          : null}
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <Select
                        name="pastureFertilized"
                        style={{ width: "100%" }}
                        value={
                          form.pasture != null && form.pastureFertilized != null
                            ? form.pastureFertilized
                              ? "Yes"
                              : "No"
                            : undefined
                        }
                        placeholder={translation.defaultSelectPlaceholder}
                        disabled={
                          shouldBlockOnEdit
                            ? true
                            : form?.productionSubSystem === "Intensive"
                            ? true
                            : false
                        }
                        onChange={(value) => {
                          handleChangePastureSeasonFertilizedSelected(value);
                          setErrors(
                            errors.filter((e) => e !== "pastureFertilized")
                          );
                        }}
                      >
                        {form.pastureSeason != null && form.pasture != null
                          ? pastures.find((p) => p.id === form.pasture.id) !=
                              null &&
                            pastures
                              .find((p) => p.id === form.pasture.id)
                              .arraySeasonOptions.find(
                                (aso) => aso.season === form.pastureSeason
                              )
                              ?.optionsFertilized.map((oF) => (
                                <Select.Option key={oF} value={oF}>
                                  {oF === "Yes"
                                    ? translation.buttons.yes
                                    : translation.buttons.no}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Row>
                  </Col>
                  {/* Pasto - Custo diário por animal */}
                  <Col span={5}>
                    <Row>
                      <label
                        className={
                          errors.includes("pastureDailyCost") ? "error" : ""
                        }
                      >
                        Custo
                        {form?.productionSubSystem === "Extensive" ||
                        form?.pasture !== null
                          ? "*"
                          : null}
                        <Tooltip
                          placement="top"
                          title={`Caso este campo não for preenchido, será utilizado o valor
                          cadastrado na Forrageira`}
                        >
                          <InfoIcon />
                        </Tooltip>
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <NumberFormat
                        customInput={Input}
                        value={
                          form?.pastureDailyCost !== null
                            ? form?.pastureDailyCost
                            : null
                        }
                        placeholder={translation.defaultPlaceholder}
                        name="pastureDailyCost"
                        addonBefore="R$"
                        addonAfter="kg"
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        disabled={
                          shouldBlockOnEdit
                            ? true
                            : form?.productionSubSystem === "Intensive" ||
                              dietStrategyPeriodSelected?.id !== null
                            ? true
                            : false
                        }
                        onValueChange={({ floatValue }) => {
                          setForm({
                            ...form,
                            pastureDailyCost:
                              floatValue >= 0 ? floatValue : null,
                          });
                          setErrors(
                            errors.filter((e) => e !== "pastureDailyCost")
                          );
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                {/* Nutrition box of pasture */}
                {form?.pasture != null && (
                  <NutritionalPastureBox>
                    <Row type="flex" justify="start" gutter={16}>
                      <Col span={12}>
                        <Row type="flex" justify="space-between">
                          <Col span={8}>
                            <span className="title">DADOS NUTRICIONAIS</span>
                          </Col>
                          <Col span={16}>
                            <div className="colInfo">
                              <div className="groupInfo">
                                <label>NDT:</label>
                                <strong>{`${pastureTDN} g/Kg`}</strong>
                              </div>
                              <div className="groupInfo">
                                <label>PB:</label>
                                <strong>{`${pastureCP} g/Kg`}</strong>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={12} align="right">
                        <Row type="flex" justify="space-between">
                          <Col span={8}>
                            <span className="title">CONSUMO ESPERADO</span>
                          </Col>
                          <Col span={16}>
                            <div className="colInfo">
                              <div className="groupInfo">
                                <label>Mínimo:</label>
                                <strong>{`${pastureConsMin}`}</strong>
                              </div>
                              <div className="groupInfo">
                                <label>Máximo:</label>
                                <strong>{`${pastureConsMax}`}</strong>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </NutritionalPastureBox>
                )}
                {/* Pasture - End */}
                {/* Supplement - Start */}
                <CustomDivider>SUPLEMENTO</CustomDivider>
                {/* Supplement Select - SupplementConsumptionType - Supplement Cost  */}
                <Row type="flex" justify="start" gutter={8}>
                  {/* Supplement & Supplement version */}
                  <Col span={12}>
                    <Row>
                      <label
                        className={errors.includes("supplement") ? "error" : ""}
                      >
                        Suplemento/Concent.*
                      </label>
                    </Row>
                    <Row type="flex" gutter={4} className="rowInput">
                      <Col span={16}>
                        <Select
                          name="supplement"
                          style={{ width: "100%" }}
                          value={
                            form.supplement != null
                              ? form.supplement.id
                              : undefined
                          }
                          placeholder={translation.defaultSelectPlaceholder}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          allowClear
                          disabled={shouldBlockOnEdit}
                          onDeselect={() => {
                            setForm({
                              ...form,
                              supplement: null,
                              supplementVersion: null,
                              supplementConsumptionMeasureType: "G_ANIMAL",
                              supplementConsumptionByAnimal: null,
                              supplementDailyCost: null,
                            });
                          }}
                          onChange={(value) => {
                            if (value == null) {
                              setForm({
                                ...form,
                                supplement: null,
                                supplementVersion: null,
                                supplementConsumptionMeasureType: "G_ANIMAL",
                                supplementConsumptionByAnimal: null,
                                supplementDailyCost: null,
                              });
                            } else {
                              const sup = supplements.find(
                                (s) => s.id === value
                              );
                              setForm({
                                ...form,
                                supplement: sup,
                                supplementVersion: sup?.versions[0].version,
                                supplementConsumptionMeasureType:
                                  sup.voluntaryConsumptionType,
                                supplementDailyCost: sup.cost,
                                supplementConsumptionType:
                                  sup.type === "Supplement" ? "Pure" : "Mix",
                              });
                              setErrors(
                                errors.filter((e) => e !== "supplement")
                              );
                            }
                          }}
                        >
                          {supplements != null &&
                          Object.entries(supplements).length > 0
                            ? supplements.map((p) => (
                                <Select.Option key={p.id} value={p.id}>
                                  {p.name}
                                </Select.Option>
                              ))
                            : null}
                        </Select>
                      </Col>
                      {/* Supplement version */}
                      <Col span={8}>
                        <Select
                          name="supplementVersion"
                          style={{ width: "100%" }}
                          value={
                            form.supplement != null
                              ? form.supplementVersion
                              : undefined
                          }
                          placeholder={translation.defaultSelectPlaceholder}
                          disabled={shouldBlockOnEdit}
                          onChange={(value) => {
                            setForm({
                              ...form,
                              supplementVersion: value,
                            });
                          }}
                        >
                          {form.supplement != null
                            ? form.supplement.versions.map((v) => (
                                <Select.Option key={v.id} value={v.version}>
                                  {`Versão ${v?.version}`}
                                </Select.Option>
                              ))
                            : null}
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                  {/* SupplementConsumptionType */}
                  <Col span={6}>
                    <Row>
                      <label
                        className={
                          errors.includes("supplementConsumptionType")
                            ? "error"
                            : ""
                        }
                      >
                        Tipo de consumo*
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <Select
                        name="supplementConsumptionType"
                        value={
                          form.supplement?.type === "Supplement"
                            ? "Pure"
                            : "Mix"
                        }
                        disabled
                        style={{ width: "100%" }}
                      >
                        <Option key={"Pure"} value={"Pure"}>
                          Puro
                        </Option>
                        <Option key={"Mix"} value={"Mix"}>
                          Mistura
                        </Option>
                      </Select>
                    </Row>
                  </Col>
                  {/* SupplementDailyCost */}
                  <Col span={6}>
                    <Row>
                      <label
                        className={
                          errors.includes("supplementDailyCost") ? "error" : ""
                        }
                      >
                        Custo{form?.supplement !== null ? "*" : null}
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <NumberFormat
                        customInput={Input}
                        value={
                          form?.supplementDailyCost !== null
                            ? form?.supplementDailyCost
                            : null
                        }
                        placeholder={translation.defaultPlaceholder}
                        addonBefore="R$"
                        addonAfter="kg"
                        name="supplementDailyCost"
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        disabled={shouldBlockOnEdit}
                        onValueChange={({ floatValue }) => {
                          setForm({
                            ...form,
                            supplementDailyCost: floatValue ? floatValue : null,
                          });
                          setErrors(
                            errors.filter((e) => e !== "supplementDailyCost")
                          );
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                {/* SupplementConsumptionByAnimal - Supplement Recommended consume values  */}
                <Row
                  type="flex"
                  justify="start"
                  gutter={8}
                  style={{ marginTop: 10 }}
                >
                  {/* SupplementConsumptionByAnimal */}
                  <Col span={8}>
                    <Row>
                      <label
                        className={
                          errors.includes("supplementConsumptionByAnimal")
                            ? "error"
                            : ""
                        }
                      >
                        Consumo diário/Animal*
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <NumberFormat
                        customInput={Input}
                        value={
                          form?.supplementConsumptionByAnimal !== null
                            ? form?.supplementConsumptionByAnimal
                            : null
                        }
                        addonAfter={selectSupplementConsumption}
                        placeholder={translation.defaultPlaceholder}
                        name="supplementConsumptionByAnimal"
                        decimalScale={"4"}
                        fixedDecimalScale={true}
                        decimalSeparator=","
                        thousandSeparator="."
                        disabled={shouldBlockOnEdit}
                        onValueChange={({ floatValue }) => {
                          setForm({
                            ...form,
                            supplementConsumptionByAnimal: floatValue
                              ? floatValue > 10000
                                ? 10000
                                : floatValue
                              : 0,
                          });
                          setErrors(
                            errors.filter(
                              (e) => e !== "supplementConsumptionByAnimal"
                            )
                          );
                        }}
                      />
                    </Row>
                  </Col>
                  {/* Supplement Recommended consume values */}
                  <Col span={16}>
                    <NutritionalSupplementBox>
                      <Row>
                        <span className="title">CONSUMO DE SUPLEMENTO</span>
                      </Row>
                      <Row type="flex" justify="start" gutter={4}>
                        <Col span={10}>
                          <Row>
                            <span className="title">
                              VOLUNTÁRIO RECOMENDADO
                            </span>
                          </Row>
                          <Row type="flex">
                            <div className="groupInfo">
                              <label>Mínimo</label>
                              <strong>{`${numberMask(
                                supplementMinimumVoluntaryConsuption,
                                false,
                                null,
                                "pt-br",
                                4,
                                2
                              )} ${supplementVoluntaryConsuptionUnit}`}</strong>
                            </div>
                            <div className="groupInfo">
                              <label>Máximo</label>
                              <strong>{`${numberMask(
                                supplementMaximumVoluntaryConsuption,
                                false,
                                null,
                                "pt-br",
                                4,
                                2
                              )} ${supplementVoluntaryConsuptionUnit}`}</strong>
                            </div>
                          </Row>
                        </Col>
                        <Col span={10}>
                          <Row>
                            <span className="title">
                              {form?.supplement?.type === "Supplement"
                                ? "PURO"
                                : "MISTURA"}
                            </span>
                          </Row>
                          <Row type="flex">
                            <div className="groupInfo">
                              <label>Mínimo</label>
                              <strong>
                                {form?.supplement?.type === "Supplement" &&
                                supplementMinimumPureConsuption !== null
                                  ? `${numberMask(
                                      supplementMinimumPureConsuption,
                                      false,
                                      null,
                                      "pt-br",
                                      4,
                                      2
                                    )} ${supplementVoluntaryConsuptionUnit}`
                                  : form?.supplement?.type ===
                                      "Ration_Mixture" &&
                                    supplementMinimumMixConsuption !== null
                                  ? `${numberMask(
                                      supplementMinimumMixConsuption,
                                      false,
                                      null,
                                      "pt-br",
                                      4,
                                      2
                                    )} ${supplementVoluntaryConsuptionUnit}`
                                  : "-"}
                              </strong>
                            </div>
                            <div className="groupInfo">
                              <label>Máximo</label>
                              <strong>
                                {form?.supplement?.type === "Supplement" &&
                                supplementMaximumPureConsuption !== null
                                  ? `${numberMask(
                                      supplementMaximumPureConsuption,
                                      false,
                                      null,
                                      "pt-br",
                                      4,
                                      2
                                    )} ${supplementVoluntaryConsuptionUnit}`
                                  : form?.supplement?.type ===
                                      "Ration_Mixture" &&
                                    supplementMaximumMixConsuption !== null
                                  ? `${numberMask(
                                      supplementMaximumMixConsuption,
                                      false,
                                      null,
                                      "pt-br",
                                      4,
                                      2
                                    )} ${supplementVoluntaryConsuptionUnit}`
                                  : "-"}
                              </strong>
                            </div>
                          </Row>
                        </Col>
                        <Col span={4}>
                          <Row>
                            <span className="title">
                              REAL{" "}
                              <InfoTooltip title='Consumo informado na tela de detalhes do Lote, menu "Opções".' />
                            </span>
                          </Row>
                          <Row type="flex">
                            <div className="groupInfo">
                              <label>Médio</label>
                              <strong>
                                {lotAvgSupplementConsumption === 0 ||
                                lotAvgSupplementConsumption === null
                                  ? "-"
                                  : `${numberMask(
                                      1000 * lotAvgSupplementConsumption,
                                      false
                                    )} g/dia`}
                              </strong>
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </NutritionalSupplementBox>
                  </Col>
                </Row>
                {/* Supplement Nutrional info and trough info */}
                <Row type="flex">
                  <Col span={6}>
                    {form?.supplement != null && (
                      <NutritionalSupplementBox>
                        <Row>
                          <span className="title">DADOS NUTRICIONAIS</span>
                        </Row>
                        <Row type="flex">
                          <div className="groupInfo">
                            <label>NDT</label>
                            <strong>{`${numberMask(
                              supplementTDN || 0
                            )} g/Kg`}</strong>
                          </div>
                          <div className="groupInfo">
                            <label>PB</label>
                            <strong>{`${numberMask(
                              supplementCP || 0
                            )} g/Kg`}</strong>
                          </div>
                        </Row>
                        <Row type="flex">
                          <div className="groupInfo">
                            <label>%MS</label>
                            <strong>{`${numberMask(
                              supplementDryMatterPercentage || 0
                            )} %`}</strong>
                          </div>
                          <div className="groupInfo">
                            <label>Ca</label>
                            <strong>{`${numberMask(
                              supplementCalcium || 0
                            )} g/Kg`}</strong>
                          </div>
                        </Row>
                        <Row type="flex">
                          <div className="groupInfo">
                            <label>P</label>
                            <strong>{`${numberMask(
                              supplementPhosphorus || 0
                            )} g/Kg`}</strong>
                          </div>
                          <div className="groupInfo">
                            <label>Zn</label>
                            <strong>{`${numberMask(
                              supplementZinc || 0
                            )} mg/Kg`}</strong>
                          </div>
                        </Row>
                        <Row type="flex">
                          <div className="groupInfo">
                            <label>Na</label>
                            <strong>{`${numberMask(
                              supplementSodium || 0
                            )} g/Kg`}</strong>
                          </div>
                        </Row>
                      </NutritionalSupplementBox>
                    )}
                  </Col>
                  <Col span={17} offset={1}>
                    <NutritionalGdpValidationBox>
                      <Row type="flex" justify="start" gutter={4}>
                        <Col span={12}>
                          {form?.supplement != null && (
                            <>
                              <Row>
                                <span className="title">
                                  ESPAÇO DE COCHO RECOMENDADO
                                  <InfoTooltip
                                    placement="top"
                                    trigger="hover"
                                    title="No caso de erro, a dieta pode ser salva, mas um alerta correlato pode ser gerado, no futuro, se a Dieta não entregar o GDP esperado."
                                  />
                                </span>
                              </Row>
                              <Row type="flex">
                                <div className="groupInfo">
                                  <label>Recom.(min/max)</label>
                                  <strong>{`${
                                    form.supplement.minimumPureTrough != null
                                      ? supplementMinPureTrought
                                      : supplementMinMixTrought
                                  }/${
                                    form.supplement.maximumPureTrough != null
                                      ? supplementMaxPureTrought
                                      : supplementMaxMixTrought
                                  } cm/cab`}</strong>
                                </div>
                                <div className="groupInfo">
                                  <label>Disponível</label>
                                  <strong>{`${
                                    isCurrentPeriod
                                      ? numberMask(
                                          troughSpacePerAnimal || 0,
                                          false
                                        )
                                      : 0
                                  } cm/cab`}</strong>
                                </div>
                              </Row>
                              {!isCurrentPeriod && !dietStrategyData.generic ? (
                                <Row type="flex">
                                  <p className="error">
                                    Piquete / Baia indefinido, verificar cocho
                                    no futuro.
                                  </p>
                                </Row>
                              ) : !troughPerAnimalIsGreaterThanMin &&
                                !dietStrategyData.generic ? (
                                <Row type="flex">
                                  <p className="error">
                                    Espaço de cocho insuficiente para a dieta.
                                  </p>
                                </Row>
                              ) : null}
                            </>
                          )}
                        </Col>
                        <Col span={12}>
                          <Row type="flex">
                            <span className="title">
                              Resumo do Quadro Cálculo da Dieta
                            </span>
                          </Row>
                          {/* Gdp Expected and Possible */}
                          <Row type="flex" justify="start" gutter={2}>
                            <Col span={16}>
                              <label>
                                GDP Esperado:
                                <strong>{` ${getTwoDecimalDigits(
                                  form?.gdpExpectation || 0
                                )} kg `}</strong>
                              </label>
                            </Col>
                            <Col span={8}>
                              <label>
                                Possível:
                                <strong>{` ${getTwoDecimalDigits(
                                  form?.gdpPossible || 0
                                )} kg`}</strong>
                              </label>
                            </Col>
                          </Row>
                          {/* Pasture and CP */}
                          <Row type="flex" justify="start" gutter={2}>
                            <Col span={16}>
                              {form?.pasture != null && (
                                <label>
                                  {`Pasto(kg/d): Cons.: `}
                                  <strong>{`${getTwoDecimalDigits(
                                    form?.pastureConsumed || 0
                                  )}`}</strong>
                                  {` Nec.: `}
                                  <strong>
                                    {`${getTwoDecimalDigits(
                                      form?.pastureNeeded || 0
                                    )}`}
                                  </strong>
                                </label>
                              )}
                            </Col>
                            <Col span={8}>
                              <label>
                                PB
                                <strong>
                                  {form?.cpExceededOrMissing === "Missing" ? (
                                    <TiArrowDownThick
                                      id="iconExOrMiss"
                                      color="#d44c4c"
                                    />
                                  ) : form?.cpExceededOrMissing ===
                                    "Exceeded" ? (
                                    <TiArrowUpThick
                                      id="iconExOrMiss"
                                      color="#27ff00"
                                    />
                                  ) : null}
                                  {` ${getTwoDecimalDigits(
                                    form?.cpExceededOrMissingValue || 0
                                  )} g`}
                                </strong>
                              </label>
                            </Col>
                          </Row>
                          {/* Trough and TDN */}
                          <Row type="flex" justify="start" gutter={2}>
                            <Col span={16}>
                              {form?.supplement != null && (
                                <label>
                                  {`Conc.(kg/d): Cons.: `}
                                  <strong>{`${getTwoDecimalDigits(
                                    form?.supplementConsumed || 0
                                  )}`}</strong>
                                  {` Nec.: `}
                                  <strong>
                                    {`${getTwoDecimalDigits(
                                      form?.supplementNeeded || 0
                                    )}`}
                                  </strong>
                                </label>
                              )}
                            </Col>
                            <Col span={8}>
                              <label>
                                NDT
                                <strong>
                                  {form?.tdnExceededOrMissing === "Missing" ? (
                                    <TiArrowDownThick
                                      id="iconExOrMiss"
                                      color="#d44c4c"
                                    />
                                  ) : form?.tdnExceededOrMissing ===
                                    "Exceeded" ? (
                                    <TiArrowUpThick
                                      id="iconExOrMiss"
                                      color="#27ff00"
                                    />
                                  ) : null}
                                  {` ${getTwoDecimalDigits(
                                    form?.tdnExceededOrMissingValue || 0
                                  )} g`}
                                </strong>
                              </label>
                            </Col>
                          </Row>
                          {/* Calcium */}
                          <Row type="flex" justify="start" gutter={2}>
                            <Col span={16}></Col>
                            <Col span={8}>
                              <label>
                                Ca
                                <strong>
                                  {form?.calciumCode === 1 ? (
                                    <TiArrowDownThick
                                      id="iconExOrMiss"
                                      color="#d44c4c"
                                    />
                                  ) : form?.calciumCode === 2 ? (
                                    <TiArrowUpThick
                                      id="iconExOrMiss"
                                      color="#27ff00"
                                    />
                                  ) : null}
                                  {` ${getTwoDecimalDigits(
                                    Math.abs(
                                      form?.calciumNeeded -
                                        form?.calciumConsumed
                                    ) || 0
                                  )} g`}
                                </strong>
                              </label>
                            </Col>
                          </Row>
                          {/* Phosporus */}
                          <Row type="flex" justify="start" gutter={2}>
                            <Col span={16}></Col>
                            <Col span={8}>
                              <label>
                                P
                                <strong>
                                  {form?.phosporusCode === 1 ? (
                                    <TiArrowDownThick
                                      id="iconExOrMiss"
                                      color="#d44c4c"
                                    />
                                  ) : form?.phosporusCode === 2 ? (
                                    <TiArrowUpThick
                                      id="iconExOrMiss"
                                      color="#27ff00"
                                    />
                                  ) : null}
                                  {` ${getTwoDecimalDigits(
                                    Math.abs(
                                      form?.phosporusNeeded -
                                        form?.phosporusConsumed
                                    ) || 0
                                  )} g`}
                                </strong>
                              </label>
                            </Col>
                          </Row>
                          {/* Zinc */}
                          <Row type="flex" justify="start" gutter={2}>
                            <Col span={16}></Col>
                            <Col span={8}>
                              <label>
                                Zn
                                <strong>
                                  {form?.zincCode === 1 ? (
                                    <TiArrowDownThick
                                      id="iconExOrMiss"
                                      color="#d44c4c"
                                    />
                                  ) : form?.zincCode === 2 ? (
                                    <TiArrowUpThick
                                      id="iconExOrMiss"
                                      color="#27ff00"
                                    />
                                  ) : null}
                                  {` ${getTwoDecimalDigits(
                                    Math.abs(
                                      form?.zincNeeded - form?.zincConsumed
                                    ) || 0
                                  )} mg`}
                                </strong>
                              </label>
                            </Col>
                          </Row>
                          {/* Sodium */}
                          <Row type="flex" justify="start" gutter={2}>
                            <Col span={16}></Col>
                            <Col span={8}>
                              <label>
                                Na
                                <strong>
                                  {form?.sodiumCode === 1 ? (
                                    <TiArrowDownThick
                                      id="iconExOrMiss"
                                      color="#d44c4c"
                                    />
                                  ) : form?.sodiumCode === 2 ? (
                                    <TiArrowUpThick
                                      id="iconExOrMiss"
                                      color="#27ff00"
                                    />
                                  ) : null}
                                  {` ${getTwoDecimalDigits(
                                    Math.abs(
                                      form?.sodiumNeeded - form?.sodiumConsumed
                                    ) || 0
                                  )} g`}
                                </strong>
                              </label>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </NutritionalGdpValidationBox>
                  </Col>
                </Row>
                {/* Supplement - End */}
              </Spin>
            </Body>
            <Footer>
              <Row type="flex" justify="space-between">
                <Col span={6} align="left">
                  <div className="buttonContainer">
                    <Tooltip title="Atalho: Alt + &#8592;" placement="top">
                      <ButtonNavigation
                        onClick={goPrevius}
                        disabled={index === 0 || loadinDiets ? true : false}
                      >
                        <Icon type="left-circle" />
                      </ButtonNavigation>
                    </Tooltip>
                    <Tooltip title="Atalho: Alt + &#8594;" placement="top">
                      <ButtonNavigation
                        disabled={
                          loadinDiets
                            ? true
                            : dietStrategyData.dietPeriods.length > 1 &&
                              index < dietStrategyData.dietPeriods.length - 1
                            ? false
                            : true
                        }
                        onClick={goNext}
                      >
                        <Icon type="right-circle" />
                      </ButtonNavigation>
                    </Tooltip>
                    {dietStrategyData.dietPeriods.length > 0 &&
                      dietStrategyData.dietPeriods.find(
                        (dp, i) => i === index
                      ) && (
                        <ButtonNavigation
                          disabled={
                            loadinDiets
                              ? true
                              : shouldBlockOnEdit
                              ? true
                              : dietStrategyData.dietPeriods.length <= 1
                              ? false
                              : index >= dietStrategyData.dietPeriods.length - 1
                              ? false
                              : true
                          }
                          onClick={remove}
                          className="remove"
                        >
                          <Icon type="delete" />
                          Deletar
                        </ButtonNavigation>
                      )}
                  </div>
                </Col>
                <Col span={12} align="center"></Col>
                <Col span={6} align="right">
                  <Tooltip title="Atalho: Ctrl + Enter" placement="top">
                    <ButtonNavigation
                      disabled={loadinDiets || shouldBlockOnEdit}
                      className="save"
                      onClick={saveAndFinalize}
                    >
                      SALVAR
                    </ButtonNavigation>
                  </Tooltip>
                </Col>
              </Row>
            </Footer>
            <SimpleMessageModal
              show={showModalChangeSystem}
              title={translation.dietStrategy.modal.simple.title}
              message={translation.dietStrategy.modal.simple.message}
              actionConfirm={() => {
                setShowModalChangeSystem(false);
              }}
              buttonText={translation.dietStrategy.modal.simple.confirmButton}
            />
          </Container>
          {/* Modal Gdp Validation */}
          <GdpModalValidation
            form={form}
            gdpValidationReturn={gdpValidationReturn}
            modalGdpValidationVisible={modalGdpValidationVisible}
            ignoreGdpAlertAndSave={ignoreGdpAlertAndSave}
            closeModalGdpValidation={closeModalGdpValidation}
          />
        </>
      ) : (
        <Empty />
      )}
    </PeriodModal>
  );
};

export default PeriodModel;
