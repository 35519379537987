import { useContext, useReducer } from "react";
import { RelocatePicketContext } from "../../contexts/relocatePicketContext";

const initialState = {
  modalVisible: false,
  lot: null,
  picketIdOrigin: null,
};

const relocatePicketReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_MODAL":
      return {
        ...state,
        modalVisible: true,
        lot: action.payload.lot,
        picketIdOrigin: action.payload.picketIdOrigin,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        modalVisible: false,
        lot: null,
        picketIdOrigin: null,
      };
    default:
      return state;
  }
};

export const useRelocatePicketReducer = () => {
  const [state, dispatch] = useReducer(relocatePicketReducer, initialState);

  return { state, dispatch };
};

export const useRelocatePicket = () => {
  const value = useContext(RelocatePicketContext);

  return value;
};
