import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { Creators as LotActions } from "../../../store/ducks/lot";
import { Creators as LotReceiptActions } from "../../../store/ducks/lotReceipt";

// Components
import { Collapse, Row, Col, Table, List, Icon, Dropdown, Menu } from "antd";
import { Container, HeaderContainer, Title } from "./styles";
import ButtonStandard from "../../../components/utils/button";
import TagStatus from "../../../components/utils/tagStatus";
import MenuIcon from "../../../components/utils/table/icons/menu";
import { Container as MenuContainer } from "../../../components/utils/table/menu/styles";

// Services
import { numberMask } from "../../../services/helpersMethodsService";
import { getLotIndex } from "../../../services/lotService";

//Libs
import moment from "moment";
import { useCallback } from "react";
import DrawerLotReceiptItemDetails from "../../../components/drawers/lotReceiptItemDetails";

const ListLotReceipt = ({ tab }) => {
  // Variables
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dateFormat = "DD/MM/YYYY";

  const Panel = Collapse.Panel;
  const Column = Table.Column;

  const customPanelStyle = {
    borderRadius: 7,
    background: "#fff",
    boxShadow: "0px 0px 10px #d8d8d8",
    marginBottom: 15,
  };

  // Redux variables
  const {
    groupSelected,
    farmSelected,
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  // Methods

  async function fetchData(
    groupSelected,
    farmSelected,
    page = 0,
    sorter = {},
    filters = "",
    size = 10
  ) {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    setIsLoading(true);
    try {
      const {
        data: { results },
      } = await getLotIndex({
        groupId,
        farmId,
        page,
        sorter: { field: "createdAt", order: "descend" },
        filters,
        size,
      });
      setData(results);
    } catch (error) {}
    setIsLoading(false);
  }

  function openDrawer() {
    dispatch(LotActions.showLotAnimalsReceipt());
  }

  function header(
    title,
    amountAnimals,
    provider,
    createdAt,
    receipt,
    isFromBoitel = false
  ) {
    return (
      <HeaderContainer>
        <div className="title-container">
          {isFromBoitel && (
            <div className="boitel">
              <span>Boitel</span>
            </div>
          )}
          <div className="title">
            <span>{title}</span>
          </div>
          <div className="boitel"></div>
          <div className="providerAndDate">
            <label>Fornecedor: </label>
            <span>{`${provider}`}</span>
          </div>
        </div>
        <div className="amountAnimals">
          {receipt != null && receipt ? <label>Total: </label> : null}
          <span>{`${amountAnimals} ${translation.lot.receipt.collapse.animals}`}</span>
        </div>
        {/*
        <div className="receinvingDate">
          <span>
            {`${translation.lot.receipt.collapse.receiptIn} ${
              languageSelected === "en"
                ? moment(createdAt).format("MM/DD/YYYY")
                : moment(createdAt).format("DD/MM/YYYY")
            }`}
          </span>
        </div> */}
      </HeaderContainer>
    );
  }

  function handleListPaginationChange(page, pageSize) {
    const search = "is_receipt=true;status='A'";
    fetchData(groupSelected, farmSelected, page, {}, search, pageSize);
  }

  function handleReloadTable() {
    const search = "is_receipt=true;status='A'";
    fetchData(groupSelected, farmSelected, 0, {}, search, 10);
  }

  function handleDetails(lotId, lotItemId) {
    dispatch(
      LotReceiptActions.getDetailsLotReceiptItem(
        groupId,
        farmId,
        lotId,
        lotItemId
      )
    );
    dispatch(LotReceiptActions.openOrHideDrawerDetails(lotId, lotItemId));
  }

  const menu = useCallback(
    (lotId, lotItemId) => {
      return (
        <Menu>
          <Menu.Item key="0" onClick={() => handleDetails(lotId, lotItemId)}>
            {translation.table.menu.details}
          </Menu.Item>
        </Menu>
      );
    },
    [handleDetails]
  );

  // Effect
  useEffect(() => {
    async function fetchData() {
      const search = "is_receipt=true;status='A'";
      const { id: groupId } = groupSelected;
      const { id: farmId } = farmSelected;
      setIsLoading(true);
      try {
        const {
          data: { results },
        } = await getLotIndex({
          groupId,
          farmId,
          page: 0,
          sorter: { field: "createdAt", order: "descend" },
          filters: search,
          size: 10,
        });
        setData(results);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }
    if (
      tab === "receipt" &&
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      fetchData();
    }
    return () => {
      setData(null);
    };
  }, [groupSelected, farmSelected, tab]);

  return (
    <Container>
      <Row type="flex" justify="space-between" style={{ marginBottom: "19px" }}>
        <Col span={12}>
          <Title>{translation.lot.receipt.title}</Title>
        </Col>
        <Col span={12}>
          <Row type="flex" justify="end" gutter={8}>
            <Col>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={handleReloadTable}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </Col>
            <Col>
              <ButtonStandard
                type="button"
                buttonType="type8"
                onClick={openDrawer}
              >
                {translation.lot.receipt.buttonNewLot}
              </ButtonStandard>
            </Col>
          </Row>
        </Col>
      </Row>

      <List
        itemLayout="vertical"
        size="large"
        loading={isLoading}
        dataSource={data != null ? data.content : []}
        pagination={{
          total: data != null ? data.totalElements : 0,
          size: data != null ? data.size : 0,
          current: data != null ? data.number + 1 : 0,
          hideOnSinglePage:
            data !== null && Object.entries(data).length !== 0
              ? data.totalElements > 10
                ? false
                : true
              : true,
          onChange: (page, pageSize) =>
            handleListPaginationChange(page, pageSize),
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
          onShowSizeChange: (page, pageSize) =>
            handleListPaginationChange(page, pageSize),
        }}
        renderItem={(item) => (
          <Collapse
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) =>
              isActive ? (
                <i>
                  <Icon
                    type="up"
                    style={{ fontSize: "15px", color: "#684E94" }}
                  />
                </i>
              ) : (
                <i>
                  <Icon
                    type="down"
                    style={{ fontSize: "15px", color: "#684E94" }}
                  />
                </i>
              )
            }
          >
            <Panel
              header={header(
                item.name,
                item.amountAnimalsReceived,
                item.supplierName,
                item.creationDate,
                item.receipt,
                item.isReceiptFromBoitelScenario
              )}
              key={item.id}
              style={customPanelStyle}
              // extra={Extra(item.id)}
            >
              <Row type="flex" justify="start">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  {item.isReceiptFromBoitelScenario != null &&
                  item.isReceiptFromBoitelScenario ? (
                    <Table
                      rowKey="id"
                      dataSource={item.lotItems}
                      pagination={false}
                      size="small"
                    >
                      <Column
                        title={
                          translation.lot.receipt.table.boitelColumns
                            .identification
                        }
                        dataIndex="identification"
                        key="identification"
                        align="left"
                      />
                      <Column
                        title={
                          translation.lot.receipt.table.boitelColumns.amount
                        }
                        dataIndex="amount"
                        key="amount"
                        align="left"
                        render={(value, record) => (
                          <span>{`${
                            record.gender === "Male" ? "M" : "F"
                          } - ${value}`}</span>
                        )}
                      />
                      <Column
                        title={
                          translation.lot.receipt.table.boitelColumns.breed
                        }
                        dataIndex="breedName"
                        key="breedName"
                        align="left"
                      />
                      <Column
                        title={
                          translation.lot.receipt.table.boitelColumns.entryDate
                        }
                        dataIndex="entryDate"
                        key="entryDate"
                        align="left"
                        render={(value, record) => {
                          if (value) {
                            return (
                              <span>{moment(value).format(dateFormat)}</span>
                            );
                          }
                        }}
                      />
                      <Column
                        title={
                          translation.lot.receipt.table.boitelColumns
                            .weightRange
                        }
                        dataIndex="boitelPriceValidityWeightRangeSelected"
                        key="boitelPriceValidityWeightRangeSelected"
                        align="left"
                        render={(value, record) =>
                          value ? (
                            <span>
                              {`${numberMask(
                                value.initialWeightRange || 0,
                                false
                              )} -
                              ${numberMask(
                                value.finalWeightRange || 0,
                                false
                              )} kg`}
                            </span>
                          ) : null
                        }
                      />
                      <Column
                        title={
                          translation.lot.receipt.table.boitelColumns
                            .confirmedAmount
                        }
                        dataIndex="amountAnimalsConfirmed"
                        key="amountAnimalsConfirmed"
                        align="left"
                        render={(value) => (value ? value : 0)}
                      />
                      <Column
                        title={
                          translation.lot.receipt.table.boitelColumns.status
                        }
                        dataIndex="status"
                        key="status"
                        align="left"
                        render={(status, record) =>
                          status === "Confirmed" ? (
                            <TagStatus
                              background="#C5F1CA"
                              borderColor="#106518"
                              color="#106518"
                            >
                              {translation.lot.receipt.table.status.confirmed}
                            </TagStatus>
                          ) : (
                            <TagStatus
                              background="#FFDBBC"
                              borderColor="#FE8D2A"
                              color="#FE8D2A"
                            >
                              {translation.lot.receipt.table.status.pending}
                            </TagStatus>
                          )
                        }
                      />
                      <Column
                        align="left"
                        width={50}
                        render={(text, record) => (
                          <Dropdown
                            overlay={menu(item.id, record.id)}
                            trigger={["click"]}
                            key={record.id}
                          >
                            <MenuContainer>
                              <MenuIcon />
                            </MenuContainer>
                          </Dropdown>
                        )}
                      />
                    </Table>
                  ) : (
                    <Table
                      rowKey="id"
                      dataSource={item.lotItems}
                      pagination={false}
                      size="small"
                    >
                      <Column
                        title={translation.lot.receipt.table.columns.breed}
                        dataIndex="breedName"
                        key="breedName"
                        align="left"
                      />
                      <Column
                        title={translation.lot.receipt.table.columns.gender}
                        dataIndex="gender"
                        key="gender"
                        align="left"
                        render={(text, record) => (
                          <span>
                            {record.gender === "Male"
                              ? translation.animal.details.male
                              : translation.animal.details.female}
                          </span>
                        )}
                      />
                      <Column
                        title={translation.lot.receipt.table.columns.birthday}
                        dataIndex="birthday"
                        key="birthday"
                        align="left"
                        render={(text, record) =>
                          record.birthday !== null ? (
                            <span>
                              {moment(record.birthday).format("MM/YYYY - ")}
                              {`${moment(new Date())
                                .diff(moment(record.birthday), "months", true)
                                .toFixed()} m`}
                            </span>
                          ) : (
                            ""
                          )
                        }
                      />
                      <Column
                        title={translation.lot.receipt.table.columns.weight}
                        dataIndex="estimatedWeight"
                        key="estimatedWeight"
                        align="left"
                        render={(text) => `${numberMask(text, false)} kg`}
                      />
                      <Column
                        title={translation.lot.receipt.table.columns.amount}
                        dataIndex="amount"
                        key="amount"
                        align="left"
                      />
                      <Column
                        title={
                          translation.lot.receipt.table.boitelColumns
                            .confirmedAmount
                        }
                        dataIndex="amountAnimalsConfirmed"
                        key="amountAnimalsConfirmed"
                        align="left"
                        render={(value) => (value ? value : 0)}
                      />
                      <Column
                        title={
                          translation.lot.receipt.table.columns.purchaseCost
                        }
                        dataIndex="purchaseCost"
                        key="purchaseCost"
                        align="left"
                        render={(text) => numberMask(text || 0, true)}
                      />
                      <Column
                        title={
                          translation.lot.receipt.table.boitelColumns.status
                        }
                        dataIndex="status"
                        key="status"
                        align="left"
                        render={(status, record) =>
                          status === "Confirmed" ? (
                            <TagStatus
                              background="#C5F1CA"
                              borderColor="#106518"
                              color="#106518"
                            >
                              {translation.lot.receipt.table.status.confirmed}
                            </TagStatus>
                          ) : (
                            <TagStatus
                              background="#FFDBBC"
                              borderColor="#FE8D2A"
                              color="#FE8D2A"
                            >
                              {translation.lot.receipt.table.status.pending}
                            </TagStatus>
                          )
                        }
                      />
                      <Column
                        align="left"
                        width={50}
                        render={(text, record) => (
                          <Dropdown
                            overlay={menu(item.id, record.id)}
                            trigger={["click"]}
                            key={record.id}
                          >
                            <MenuContainer>
                              <MenuIcon />
                            </MenuContainer>
                          </Dropdown>
                        )}
                      />
                    </Table>
                  )}
                </Col>
              </Row>
            </Panel>
          </Collapse>
        )}
      />
      <DrawerLotReceiptItemDetails />
    </Container>
  );
};

export default withRouter(ListLotReceipt);
