import axios from "axios";
import apiFinancial from "../config/api_financial";
import getHeaders from "./getHeaders";

export function getFinancialNatureForSales(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/sells/nature`,
    getHeaders(false, signal)
  );
}

export function findAllFarmSells(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/sells`,
    getHeaders(false, signal)
  );
}

export function findFarmSell(payload) {
  const { groupId, farmId, farmSellId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/sells/${farmSellId}`,
    getHeaders(false, signal)
  );
}

export function findFarmSellInfoForAnimal(payload) {
  const { groupId, farmId, farmSellId, animalId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/sells/${farmSellId}?animalId=${animalId}`,
    getHeaders(false, signal)
  );
}

export function saveFarmSell(payload) {
  const { groupId, farmId, body, signal } = payload;

  return apiFinancial.post(
    `/bovexo/${groupId}/farms/${farmId}/sells/new`,
    body,
    getHeaders(false, signal)
  );
}

export function updateFarmSell(payload) {
  const { groupId, farmId, farmSellId, body, signal } = payload;

  return apiFinancial.put(
    `/bovexo/${groupId}/farms/${farmId}/sells/update/${farmSellId}`,
    body,
    getHeaders(false, signal)
  );
}

export function deleteFarmSell(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiFinancial.delete(
    `/bovexo/${groupId}/farms/${farmId}/sells/${id}`,
    getHeaders(false, signal)
  );
}

export function getResponseFarmSaleReport(payload) {
  const { groupId, farmId, body } = payload;

  return apiFinancial.post(
    `/bovexo/${groupId}/farms/${farmId}/sells/report`,
    body,
    getHeaders(false, null)
  );
}

export function downloadFarmSaleReport(payload) {
  const { groupId, farmId, body } = payload;

  return apiFinancial.post(
    `/bovexo/${groupId}/farms/${farmId}/sells/report/download/pdf`,
    body,
    getHeaders(false, null, true)
  );
}
