import React, { useState } from "react";
import { useCallback } from "react";
import { createContext } from "react";
import { useSelector } from "react-redux";
import { findAll } from "../../services/semenService";

export const SemenContext = createContext({
  dataList: [],
  isLoadingDataList: false,
  data: null,
  isDrawerVisible: false,
});

export const SemenContextProvider = ({ children }) => {
  const [dataList, setDataList] = useState([]);
  const [data, setData] = useState(null);
  const [isLoadingDataList, setIsLoadingDataList] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const fetchData = useCallback(async () => {
    setIsLoadingDataList(true);
    try {
      const {
        data: { results },
      } = await findAll({ groupId, farmId });
      setDataList(results);
    } catch (error) {
    } finally {
      setIsLoadingDataList(false);
    }
  }, [farmId, groupId]);

  const openDrawer = useCallback((data = null) => {
    if (data) setData(data);
    setIsDrawerVisible(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setData(null);
    setIsDrawerVisible(false);
  }, []);

  return (
    <SemenContext.Provider
      value={{
        dataList,
        fetchData,
        data,
        isLoadingDataList,
        isDrawerVisible,
        openDrawer,
        closeDrawer,
      }}
    >
      {children}
    </SemenContext.Provider>
  );
};
