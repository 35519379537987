import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Axios from "axios";
import CanvasJSReact from "../../../../assets/canvasjs.react";
// Components
import { Container, Title } from "./styles";
import { Row, Col, Spin } from "antd";
import InfoTooltip from "../../../../components/utils/infoTooltip";
// Services
import { returnObjectFromAxis } from "../../../../services/dashboards/dashboardCommonService";
import { getPicketAreaUtilizationDashboard } from "../../../../services/dashboards/dashboardPicketService";

function PicketAreaUtilizationDashboard() {
  // Variables
  const { CanvasJSChart } = CanvasJSReact;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const toggleDataSeries = (e) => {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    e.chart.render();
  };
  const chartOptions = {
    animationEnabled: true,
    zoomEnabled: true,
    height: 200,
    dataPointWidth: 22,
    axisX: {
      valueFormatString: "MM/YY",
      interval: 1,
      intervalType: "month",
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
      },
    },
    toolTip: {
      fontFamily: "Asap",
      shared: true,
    },
    axisY: {
      suffix: "%",
      includeZero: true,
      gridDashType: "dot",
    },
    legend: {
      cursor: "pointer",
      itemclick: toggleDataSeries,
    },
    data: data,
  };
  // Redux variables
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  // Effects
  // Get Dashboards Values
  useEffect(() => {
    let signal = Axios.CancelToken.source();
    let mount = true;
    async function fetch() {
      let dData = [];
      setIsLoading(true);
      // Get Data
      try {
        const {
          data: { results },
        } = await getPicketAreaUtilizationDashboard({
          groupId,
          farmId,
          signal: signal,
        });
        // Get Free
        const chartData = returnObjectFromAxis(results);
        const freeData = chartData.map((x) => ({
          x: x.x,
          y: Number(x.Free).toFixed(4) * 100,
        }));
        dData = [
          ...dData,
          {
            type: "stackedColumn",
            name: "Descanso",
            showInLegend: true,
            color: "#4A85AE",
            xValueFormatString: "MM/YY",
            yValueFormatString: '#,##0.##"%"',
            dataPoints: freeData,
          },
        ];
        // Get In Use
        const useData = chartData.map((x) => ({
          x: x.x,
          y: Number(x.InUse).toFixed(4) * 100,
        }));
        dData = [
          ...dData,
          {
            type: "stackedColumn",
            name: "Pastejo",
            showInLegend: true,
            color: "#106518",
            xValueFormatString: "MM/YY",
            yValueFormatString: '#,##0.##"%"',
            dataPoints: useData,
          },
        ];
        // Get in Maintance
        const maintenanceData = chartData.map((x) => ({
          x: x.x,
          y: Number(x.InMaintenance).toFixed(4) * 100,
        }));
        dData = [
          ...dData,
          {
            type: "stackedColumn",
            name: "Reforma",
            showInLegend: true,
            color: "#FF4E00",
            xValueFormatString: "MM/YY",
            yValueFormatString: '#,##0.##"%"',
            dataPoints: maintenanceData,
          },
        ];
      } catch (error) {}
      if (mount) {
        setData(dData);
        setIsLoading(false);
      }
    }
    if (groupId != null && farmId != null) {
      fetch();
    }
    return () => {
      mount = false;
      signal.cancel();
    };
  }, [groupId, farmId]);
  return (
    <Container>
      <Row type="flex">
        <Col span={24}>
          <Title>
            Disponibilidade da Capacidade
            <InfoTooltip title="Este gráfico apresenta de forma mensal a utilização dos piquetes da propriedade em Subsistemas Extensivos e Semi-intensivo. Se no decorrer do mês, um determinado piquete tenha mudado de status, será considerado o status predominante no período. Sendo que para o mês corrente, será considerado o status atual do piquete.
              Importante: Não são considerados Piquetes/Baias, associados a atividades “Intensivas”." />
          </Title>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <Spin spinning={isLoading}>
            <CanvasJSChart options={chartOptions} />
          </Spin>
        </Col>
      </Row>
    </Container>
  );
}

export default PicketAreaUtilizationDashboard;
