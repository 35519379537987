import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import useAnimalListContext from "../../../../hooks/useAnimalListContext";
import { useAnimalReproductionContext } from "../context";

import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Icon,
  Input,
  InputNumber,
  Menu,
  Row,
  Select,
  Table,
  Tooltip,
  notification,
} from "antd";
import CustomLink from "../../../../components/utils/customLink";
import MenuIcon from "../../../../components/utils/table/icons/menu";
import { Container as MenuContainer } from "../../../../components/utils/table/menu/styles";
import TagStatus from "../../../../components/utils/tagStatus";

// Services
import ColumnGroup from "antd/lib/table/ColumnGroup";
import { getWeatherSeason } from "../../../../services/generalParameterService";
import { numberMask } from "../../../../services/helpersMethodsService";
import RainIcon from "../../../generalParameter/weatherSeasonsParameter/display/icons/rain";
import SunIcon from "../../../generalParameter/weatherSeasonsParameter/display/icons/sun";
import TransitionIcon from "../../../generalParameter/weatherSeasonsParameter/display/icons/transition";
import { EventBox } from "./styles";
import ButtonStandard from "../../../../components/utils/button";
import { reprocessAnimalReproductionEvents } from "../../../../services/animalService";

const { Column } = Table;

const WEEKS = Array.from({ length: 52 }, (_, i) => i + 1);
const YEARS = [
  ...Array.from({ length: 4 }, (_, i) => moment().subtract(i, "years").year()),
  moment().add(1, "years").year(),
].sort((a, b) => b - a);

const AnimalReproductionFemaleReproductionEventsTable = () => {
  const [periodSeasons, setPeriodSeasons] = useState([]);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const {
    animal: {
      isLoadingFemaleTable,
      reproductionFemaleReproductionEventData,
      paginationFemaleReproductionEventTable: {
        page,
        size,
        tableFilters,
        tableSorters,
      },
    },
    app: {
      translation,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
  } = useSelector((state) => state);

  const {
    identificationSelected,
    fetchReproductionFemaleReproductionEventData,
  } = useAnimalReproductionContext();

  const { handleUpdateSelectedRowKeysFemaleReproduction } =
    useAnimalListContext();

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          value={selectedKeys && selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
            background: "#684e94",
            borderColor: "none",
            border: "none",
          }}
        />
        <Button
          onClick={() => handleReset(clearFilters)}
          icon="delete"
          size="small"
          style={{ width: 90 }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  const getColumnNumberSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <div style={{ display: "block" }}>
            <Select
              value={
                selectedKeys
                  ? selectedKeys["type"] != null
                    ? selectedKeys["type"]
                    : "="
                  : "="
              }
              style={{
                width: 90,
                textAlign: "center",
                marginBottom: 8,
                marginRight: 8,
              }}
              onChange={(value) => {
                setSelectedKeys(
                  value ? { ...selectedKeys, type: value } : { ...selectedKeys }
                );
              }}
            >
              <Select.Option key="<" value="<" style={{ textAlign: "center" }}>
                &lt;
              </Select.Option>
              <Select.Option
                key="<="
                value="<="
                style={{ textAlign: "center" }}
              >
                &le;
              </Select.Option>
              <Select.Option key="=" value="=" style={{ textAlign: "center" }}>
                &#61;
              </Select.Option>
              <Select.Option
                key=">="
                value=">="
                style={{ textAlign: "center" }}
              >
                &ge;
              </Select.Option>
              <Select.Option key=">" value=">" style={{ textAlign: "center" }}>
                &gt;
              </Select.Option>
            </Select>
            {dataIndex === "current_age" ||
            dataIndex === "current_reproduction_score" ||
            dataIndex === "current_pregnancies_count" ? (
              <InputNumber
                value={
                  selectedKeys
                    ? selectedKeys["value"] != null
                      ? selectedKeys["value"]
                      : null
                    : null
                }
                onChange={(e) =>
                  setSelectedKeys(
                    e != null
                      ? { ...selectedKeys, value: e }
                      : { ...selectedKeys }
                  )
                }
                onPressEnter={() => handleSearch(selectedKeys, confirm)}
                style={{ width: 90, marginBottom: 8 }}
                defaultValue={0}
                min={0}
                max={1000}
                step={1}
              />
            ) : (
              <InputNumber
                value={
                  selectedKeys
                    ? selectedKeys["value"] != null
                      ? selectedKeys["value"]
                      : null
                    : null
                }
                onChange={(e) =>
                  setSelectedKeys(
                    e ? { ...selectedKeys, value: e } : { ...selectedKeys }
                  )
                }
                onPressEnter={() => handleSearch(selectedKeys, confirm)}
                style={{ width: 90, marginBottom: 8 }}
                defaultValue={0}
                decimalSeparator=","
                min={-1000}
                max={1000}
                step={0.01}
                formatter={(value) => {
                  if (value !== "") {
                    value = `${value}`.replace(/^-^[^0-9.,]*/g, "");
                    value = value.replace(/\.{2,}/g, ".");
                    value = value.replace(/\.,/g, ",");
                    value = value.replace(/,\./g, ",");
                    value = value.replace(/,{2,}/g, ",");
                    value = value.replace(/\.[0-9]+\./g, ".");
                    value =
                      value.split(".")[1] !== "" &&
                      value.split(".")[1] !== undefined
                        ? value.split(".")[0] +
                          "." +
                          value.split(".")[1].substring(0, 2)
                        : value;
                  }
                  return value;
                }}
              />
            )}
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{
                width: 90,
                marginRight: 8,
                background: "#684e94",
                borderColor: "none",
                border: "none",
              }}
            />
            <Button
              onClick={() => handleReset(clearFilters)}
              icon="delete"
              size="small"
              style={{ width: 90 }}
            />
          </div>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  const handleChangeYear = useCallback(
    (year) => {
      fetchReproductionFemaleReproductionEventData(
        year,
        page,
        tableSorters,
        tableFilters,
        size
      );
    },
    [
      fetchReproductionFemaleReproductionEventData,
      page,
      size,
      tableFilters,
      tableSorters,
    ]
  );

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      fetchReproductionFemaleReproductionEventData(
        selectedYear,
        pagination.current,
        sorter,
        filters,
        pagination.pageSize,
        null
      );
    },
    [fetchReproductionFemaleReproductionEventData, selectedYear]
  );

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const handleReprocessEvents = useCallback(async () => {
    try {
      await reprocessAnimalReproductionEvents({
        groupId,
        farmId,
      });

      notification.info({
        message:
          "O reprocesso foi iniciado, e logo todos os animais terão seus dados atualizados.",
      });
    } catch (error) {}
  }, [groupId, farmId]);

  const handleSelectRowTable = {
    onChange: (selectedRowKeys, selectedRows) => {
      handleUpdateSelectedRowKeysFemaleReproduction(selectedRowKeys);
    },
  };

  const translateEventType = useCallback((eventType) => {
    switch (eventType) {
      case "Coverage":
        return "C";
      case "PregnancyDiagnosisPositive":
        return "DG(+)";
      case "PregnancyDiagnosisNegative":
        return "DG(-)";
      case "Anestrus":
        return "AN";
      case "Abortion":
        return "AB";
      case "EmbryonicDeath":
        return "ME";
      case "Birth":
        return "P";
      case "Weaning":
        return "D";
      default:
        return "-";
    }
  }, []);

  const renderColumnTitle = useCallback(
    (week) => {
      try {
        let season = periodSeasons.find((ps) => ps?.week === week);

        if (season) {
          let icon;
          if (season?.season === "Water") {
            icon = <RainIcon />;
          } else if (season?.season === "Transition") {
            icon = <TransitionIcon />;
          } else {
            icon = <SunIcon />;
          }
          return (
            <div className="columnTitleContainer">
              <div>{icon}</div>
              <div>
                <span>
                  {moment()
                    .year(selectedYear)
                    .week(week)
                    .weekday(1)
                    .format("DD/MM")}
                </span>
              </div>
            </div>
          );
        }
      } catch (error) {}
    },
    [periodSeasons, selectedYear]
  );

  const today = moment();

  //Create an array with every date since today - 15 until today + 30
  useEffect(() => {
    async function createPeriodArray() {
      let arr = WEEKS.map((week) => moment().year(selectedYear).week(week));

      //Fetch seasons
      try {
        const {
          data: { results },
        } = await getWeatherSeason({ farmId: farmId });

        let periodSeasonsArray = [];
        let seasons = [];

        if (results.farmSelectedTypeSeason === "Default") {
          seasons = results.seasonsDefault;
        }
        if (results.farmSelectedTypeSeason === "Drier") {
          seasons = results.seasonsDrier;
        }
        if (results.farmSelectedTypeSeason === "Wetter") {
          seasons = results.seasonsWetter;
        }
        arr.forEach((a) => {
          let date = moment(a).format("yyyy-MM-DD");
          periodSeasonsArray.push({
            date: date,
            week: a.week(),
            season: seasons.find((s) => s.month === a.month() + 1).season,
          });
        });
        setPeriodSeasons(periodSeasonsArray);
      } catch (error) {
        console.error(error);
      }
    }
    createPeriodArray();
  }, [farmId, selectedYear]);

  return (
    <>
      <Row type="flex" justify="end" style={{ padding: "0.5rem" }}>
        <Col span={4}>
          <ButtonStandard
            buttonType="secondary"
            onClick={handleReprocessEvents}
          >
            Reprocessar eventos reprodutivos
          </ButtonStandard>
        </Col>
      </Row>
      <Row type="flex" style={{ padding: "0.5rem" }}>
        <Col span={4}>
          <strong>
            Ano:{" "}
            <Select
              value={selectedYear}
              onChange={(value) => {
                setSelectedYear(value);
                handleChangeYear(value);
              }}
            >
              {YEARS.map((year) => (
                <Select.Option value={year} key={year}>
                  {year}
                </Select.Option>
              ))}
            </Select>
          </strong>
        </Col>

        <Col span={16}>
          <Row type="flex" justify="center">
            <strong>Legenda</strong>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col>
              <strong>C</strong>: <span>Cobertura</span>
            </Col>
            |
            <Col>
              <strong>DG(+)</strong>: <span>Diagnóstico Positivo</span>
            </Col>
            |
            <Col>
              <strong>DG(-)</strong>: <span>Diagnóstico Negativo</span>
            </Col>
            |
            <Col>
              <strong>AN</strong>: <span>Anestro</span>
            </Col>
            |
            <Col>
              <strong>AB</strong>: <span>Aborto</span>
            </Col>
            |
            <Col>
              <strong>ME</strong>: <span>Morte Embrionária</span>
            </Col>
            |
            <Col>
              <strong>P</strong>: <span>Parto</span>
            </Col>
            |
            <Col>
              <strong>D</strong>: <span>Desmama</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <Table
            rowKey="animalId"
            loading={isLoadingFemaleTable}
            rowSelection={handleSelectRowTable}
            dataSource={
              reproductionFemaleReproductionEventData != null
                ? reproductionFemaleReproductionEventData.content
                : []
            }
            pagination={{
              total:
                reproductionFemaleReproductionEventData != null
                  ? reproductionFemaleReproductionEventData.totalElements
                  : 0,
              size: reproductionFemaleReproductionEventData != null ? size : 0,
              current:
                reproductionFemaleReproductionEventData != null ? page : 0,
              hideOnSinglePage:
                reproductionFemaleReproductionEventData !== null &&
                Object.entries(reproductionFemaleReproductionEventData)
                  .length !== 0
                  ? reproductionFemaleReproductionEventData.totalElements > 10
                    ? false
                    : true
                  : true,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "100", "200"],
            }}
            scroll={{
              x: true,
            }}
            footer={() => (
              <span>
                <strong>Total de animais filtrados: </strong>
                {` ${
                  reproductionFemaleReproductionEventData != null
                    ? reproductionFemaleReproductionEventData?.totalElements
                    : 0
                } animais`}
              </span>
            )}
            onChange={handleTableChange}
          >
            {identificationSelected === "handlingNumber" ? (
              <Column
                title={translation.animal.columns.handlingNumber}
                dataIndex="animalHandlingNumber"
                fixed
                sorter
                key="animalHandlingNumber"
                filteredValue={tableFilters?.animalHandlingNumber || null}
                align="left"
                width={200}
                sortOrder={
                  tableSorters?.columnKey === "animalHandlingNumber" &&
                  tableSorters.order
                }
                {...getColumnSearchProps("animal_handling_number")}
                render={(text, record) => (
                  <CustomLink to={`/admin/animals/${record.animalId}`}>
                    {text}
                  </CustomLink>
                )}
              />
            ) : identificationSelected === "tagId" ? (
              <Column
                title={translation.animal.columns.handlingNumber}
                dataIndex="animalTagId"
                sorter
                fixed
                key="animalTagId"
                filteredValue={tableFilters?.animalTagId || null}
                align="left"
                width={200}
                sortOrder={
                  tableSorters?.columnKey === "animalTagId" &&
                  tableSorters.order
                }
                {...getColumnSearchProps("animal_tag_id")}
                render={(text, record) => (
                  <CustomLink to={`/admin/animals/${record.animalId}`}>
                    {text}
                  </CustomLink>
                )}
              />
            ) : identificationSelected === "sisbov" ? (
              <Column
                title={translation.animal.columns.handlingNumber}
                dataIndex="animalSisbov"
                sorter
                fixed
                key="animalSisbov"
                filteredValue={tableFilters?.animalSisbov || null}
                align="left"
                width={200}
                sortOrder={
                  tableSorters?.columnKey === "animalSisbov" &&
                  tableSorters.order
                }
                {...getColumnSearchProps("animal_sisbov")}
                render={(text, record) => (
                  <CustomLink to={`/admin/animals/${record.animalId}`}>
                    {text}
                  </CustomLink>
                )}
              />
            ) : (
              <Column
                title={translation.animal.columns.handlingNumber}
                dataIndex="animalName"
                sorter
                key="animalName"
                filteredValue={tableFilters?.animalName || null}
                align="left"
                width={200}
                sortOrder={
                  tableSorters?.columnKey === "animalName" && tableSorters.order
                }
                {...getColumnSearchProps("animal_name")}
                render={(text, record) => (
                  <CustomLink to={`/admin/animals/${record.animalId}`}>
                    {text}
                  </CustomLink>
                )}
              />
            )}
            <Column
              title={translation.lot.production.table.columns.status}
              dataIndex="status"
              key="status"
              width={120}
              align="left"
              filteredValue={tableFilters?.status || null}
              filtered
              filters={[
                {
                  text: translation.status.active,
                  value: "Active",
                },
                {
                  text: translation.status.inactive,
                  value: "Inactive",
                },
                {
                  text: "Vendido",
                  value: "Sold",
                },
                {
                  text: "Morto",
                  value: "Dead",
                },
              ]}
              filterMultiple
              sorter
              sortOrder={
                tableSorters?.columnKey === "status" && tableSorters.order
              }
              sortDirections={["descend", "ascend"]}
              render={(farmStatus) =>
                farmStatus === "Active" ? (
                  <TagStatus
                    background="#C5F1CA"
                    borderColor="#106518"
                    color="#106518"
                  >
                    {translation.status.active}
                  </TagStatus>
                ) : farmStatus === "Inactive" || farmStatus === "X" ? (
                  <TagStatus
                    background="#FBC7C7"
                    borderColor="#D44C4C"
                    color="#D44C4C"
                  >
                    {translation.status.inactive}
                  </TagStatus>
                ) : farmStatus === "Sold" ? (
                  <TagStatus
                    background="#FEFFF6"
                    borderColor="#A9C133"
                    color="#A9C133"
                  >
                    Vendido
                  </TagStatus>
                ) : farmStatus === "Dead" ? (
                  <TagStatus
                    background="#4b4b4b"
                    borderColor="#000"
                    color="#e3e3e3"
                  >
                    Morto
                  </TagStatus>
                ) : (
                  <TagStatus
                    background="#FBC7C7"
                    borderColor="#D44C4C"
                    color="#D44C4C"
                  >
                    {translation.status.inactive}
                  </TagStatus>
                )
              }
            />
            <Column
              title={translation.animal.columns.birthdayMonths}
              dataIndex="currentAge"
              sorter
              key="currentAge"
              filteredValue={tableFilters?.currentAge || null}
              align="left"
              width={120}
              sortOrder={
                tableSorters?.columnKey === "currentAge" && tableSorters.order
              }
              {...getColumnNumberSearchProps("current_age")}
              render={(text, record) => `${text} meses`}
            />
            <Column
              title={"Peso Atual"}
              dataIndex="currentWeight"
              sorter
              key="currentWeight"
              filteredValue={tableFilters?.currentWeight || null}
              align="left"
              width={120}
              sortOrder={
                tableSorters?.columnKey === "currentWeight" &&
                tableSorters.order
              }
              {...getColumnNumberSearchProps("current_weight")}
              render={(text, record) => `${numberMask(text, false)} kg`}
            />
            <Column
              title={"Score Atual"}
              dataIndex="currentReproductionScore"
              sorter
              key="currentReproductionScore"
              filteredValue={tableFilters?.currentReproductionScore || null}
              align="left"
              width={120}
              sortOrder={
                tableSorters?.columnKey === "currentReproductionScore" &&
                tableSorters.order
              }
              {...getColumnNumberSearchProps("current_reproduction_score")}
            />
            <Column
              title={"#Gest"}
              dataIndex="currentPregnanciesCount"
              sorter
              key="currentPregnanciesCount"
              filteredValue={tableFilters?.currentPregnanciesCount || null}
              align="left"
              width={120}
              sortOrder={
                tableSorters?.columnKey === "currentPregnanciesCount" &&
                tableSorters.order
              }
              {...getColumnNumberSearchProps("current_pregnancies_count")}
            />
            <ColumnGroup
              title="Eventos"
              children={WEEKS.map((w) => (
                <Column
                  key={moment().week(w).toDate().getTime()}
                  title={renderColumnTitle(w)}
                  align="center"
                  className={
                    w === today.week() && selectedYear === today.year()
                      ? "todayColumn"
                      : ""
                  }
                  render={(text, record) =>
                    record.events[w - 1].eventType ? (
                      <Tooltip
                        title={
                          <p>
                            <strong>Data do Evento: </strong>
                            {moment(record.events[w - 1].eventDate).format(
                              "DD/MM/YYYY"
                            )}
                          </p>
                        }
                      >
                        <EventBox color={record.events[w - 1].eventType}>
                          <h2 style={{ margin: 0 }}>
                            {translateEventType(record.events[w - 1].eventType)}
                          </h2>
                        </EventBox>
                      </Tooltip>
                    ) : (
                      <EventBox>
                        <h2 style={{ margin: 0 }}>-</h2>
                      </EventBox>
                    )
                  }
                />
              ))}
            />
          </Table>
        </Col>
      </Row>
    </>
  );
};

const AnimalReproductionFemaleTable = () => {
  const {
    animal: {
      isLoadingFemaleTable,
      reproductionFemaleData,
      paginationFemaleTable: { page, size, tableFilters, tableSorters },
    },
    app: { translation },
    user: { roles: userRoles },
  } = useSelector((state) => state);

  const { identificationSelected, fetchReproductionFemaleData } =
    useAnimalReproductionContext();

  const {
    handleUpdateSelectedRowKeysFemaleReproduction,
    handleDelete,
    handleInactivate,
    handleInactivateOrActivate,
    handleEdit,
    handleGoToLotDetails,
  } = useAnimalListContext();

  const isAllowed = useCallback(
    (roles, reverse) => {
      return userRoles?.includes("Admin")
        ? true
        : roles.length === 0
        ? true
        : reverse === false
        ? roles.some((r) => userRoles?.includes(r))
        : !roles.some((r) => userRoles?.includes(r));
    },
    [userRoles]
  );

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          value={selectedKeys && selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
            background: "#684e94",
            borderColor: "none",
            border: "none",
          }}
        />
        <Button
          onClick={() => handleReset(clearFilters)}
          icon="delete"
          size="small"
          style={{ width: 90 }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  const handleTableChange = (pagination, filters, sorter) => {
    fetchReproductionFemaleData(
      pagination.current,
      sorter,
      filters,
      pagination.pageSize,
      null
    );
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const handleSelectRowTable = {
    onChange: (selectedRowKeys, selectedRows) => {
      handleUpdateSelectedRowKeysFemaleReproduction(selectedRowKeys);
    },
  };

  const menu = useCallback(
    (id, animal) => {
      return (
        <Menu>
          {animal?.farmStatus === "I" && (
            <Menu.Item key="0">
              <button
                onClick={() =>
                  handleInactivateOrActivate(id, animal, "activate")
                }
              >
                {translation.table.menu.activate}
              </button>
            </Menu.Item>
          )}
          <Menu.Item key="1">
            <Link to={`/admin/animals/${id}`}>
              {translation.table.menu.details}
            </Link>
          </Menu.Item>
          {(animal?.farmStatus === "A" || animal?.farmStatus === "P") && (
            <Menu.Item key="2" onClick={() => handleEdit(id)}>
              {translation.table.menu.edit}
            </Menu.Item>
          )}
          {(animal?.farmStatus === "A" || animal?.farmStatus === "P") &&
            isAllowed(["Trial"], true) &&
            animal?.isFromBirth === false && (
              <Menu.Item key="3" onClick={() => handleDelete(animal)}>
                {translation.table.menu.delete}
              </Menu.Item>
            )}
          {(animal?.farmStatus === "A" || animal?.farmStatus === "P") &&
            isAllowed(["Trial"], true) && (
              <Menu.Item
                key="4"
                onClick={async () => {
                  handleInactivate(animal);
                }}
              >
                {translation.table.menu.inactivate}
              </Menu.Item>
            )}
        </Menu>
      );
    },
    [
      translation.table.menu.activate,
      translation.table.menu.details,
      translation.table.menu.edit,
      translation.table.menu.delete,
      translation.table.menu.inactivate,
      isAllowed,
      handleInactivateOrActivate,
      handleEdit,
      handleDelete,
      handleInactivate,
    ]
  );

  return (
    <Row type="flex" style={{ padding: "0.5rem" }}>
      <Col span={24}>
        <Table
          rowKey="id"
          loading={isLoadingFemaleTable}
          rowSelection={handleSelectRowTable}
          dataSource={
            reproductionFemaleData != null ? reproductionFemaleData.content : []
          }
          pagination={{
            total:
              reproductionFemaleData != null
                ? reproductionFemaleData.totalElements
                : 0,
            size: reproductionFemaleData != null ? size : 0,
            current: reproductionFemaleData != null ? page : 0,
            hideOnSinglePage:
              reproductionFemaleData !== null &&
              Object.entries(reproductionFemaleData).length !== 0
                ? reproductionFemaleData.totalElements > 10
                  ? false
                  : true
                : true,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "100", "200"],
          }}
          scroll={{
            x: 1200,
          }}
          footer={() => (
            <span>
              <strong>Total de animais filtrados: </strong>
              {` ${
                reproductionFemaleData != null
                  ? reproductionFemaleData?.totalElements
                  : 0
              } animais`}
            </span>
          )}
          onChange={handleTableChange}
        >
          {identificationSelected === "handlingNumber" ? (
            <Column
              title={translation.animal.columns.handlingNumber}
              dataIndex="handlingNumber"
              sorter
              key="handlingNumber"
              filteredValue={tableFilters?.handlingNumber || null}
              align="left"
              width={200}
              sortOrder={
                tableSorters?.columnKey === "handlingNumber" &&
                tableSorters.order
              }
              {...getColumnSearchProps("handling_number")}
              render={(text, record) => (
                <CustomLink to={`/admin/animals/${record.id}`}>
                  {text}
                </CustomLink>
              )}
            />
          ) : identificationSelected === "tagId" ? (
            <Column
              title={translation.animal.columns.handlingNumber}
              dataIndex="tagId"
              sorter
              key="tagId"
              filteredValue={tableFilters?.tagId || null}
              align="left"
              width={200}
              sortOrder={
                tableSorters?.columnKey === "tagId" && tableSorters.order
              }
              {...getColumnSearchProps("tag_id")}
              render={(text, record) => (
                <CustomLink to={`/admin/animals/${record.id}`}>
                  {text}
                </CustomLink>
              )}
            />
          ) : identificationSelected === "sisbov" ? (
            <Column
              title={translation.animal.columns.handlingNumber}
              dataIndex="sisbov"
              sorter
              key="sisbov"
              filteredValue={tableFilters?.sisbov || null}
              align="left"
              width={200}
              sortOrder={
                tableSorters?.columnKey === "sisbov" && tableSorters.order
              }
              {...getColumnSearchProps("sisbov")}
              render={(text, record) => (
                <CustomLink to={`/admin/animals/${record.id}`}>
                  {text}
                </CustomLink>
              )}
            />
          ) : (
            <Column
              title={translation.animal.columns.handlingNumber}
              dataIndex="nickname"
              sorter
              key="nickname"
              filteredValue={tableFilters?.nickname || null}
              align="left"
              width={200}
              sortOrder={
                tableSorters?.columnKey === "nickname" && tableSorters.order
              }
              {...getColumnSearchProps("nickname")}
              render={(text, record) => (
                <CustomLink to={`/admin/animals/${record.id}`}>
                  {text}
                </CustomLink>
              )}
            />
          )}

          <Column
            title={translation.lot.production.table.columns.status}
            dataIndex="farmStatus"
            key="farmStatus"
            width={120}
            align="left"
            filteredValue={tableFilters?.farmStatus || null}
            filtered
            filters={[
              {
                text: translation.status.active,
                value: "A",
              },
              {
                text: translation.status.inactive,
                value: "I",
              },
              {
                text: "Vendido",
                value: "S",
              },
              {
                text: "Morto",
                value: "D",
              },
            ]}
            filterMultiple
            sorter
            sortOrder={
              tableSorters?.columnKey === "farmStatus" && tableSorters.order
            }
            sortDirections={["descend", "ascend"]}
            render={(farmStatus) =>
              farmStatus === "A" ? (
                <TagStatus
                  background="#C5F1CA"
                  borderColor="#106518"
                  color="#106518"
                >
                  {translation.status.active}
                </TagStatus>
              ) : farmStatus === "I" || farmStatus === "X" ? (
                <TagStatus
                  background="#FBC7C7"
                  borderColor="#D44C4C"
                  color="#D44C4C"
                >
                  {translation.status.inactive}
                </TagStatus>
              ) : farmStatus === "S" ? (
                <TagStatus
                  background="#FEFFF6"
                  borderColor="#A9C133"
                  color="#A9C133"
                >
                  Vendido
                </TagStatus>
              ) : farmStatus === "D" ? (
                <TagStatus
                  background="#4b4b4b"
                  borderColor="#000"
                  color="#e3e3e3"
                >
                  Morto
                </TagStatus>
              ) : (
                <TagStatus
                  background="#FBC7C7"
                  borderColor="#D44C4C"
                  color="#D44C4C"
                >
                  {translation.status.inactive}
                </TagStatus>
              )
            }
          />
          <Column
            title={translation.animal.columns.picket}
            dataIndex="picketName"
            sorter
            key="picketName"
            filteredValue={tableFilters?.picketName || null}
            width={220}
            align="left"
            sortOrder={
              tableSorters?.columnKey === "picketName" && tableSorters.order
            }
            {...getColumnSearchProps("picket_name")}
          />
          <Column
            title={translation.animal.columns.lot}
            dataIndex="lotName"
            sorter
            key="lotName"
            filteredValue={tableFilters?.lotName || null}
            width={220}
            align="left"
            sortOrder={
              tableSorters?.columnKey === "lotName" && tableSorters.order
            }
            {...getColumnSearchProps("lot_name")}
            render={(text, record) =>
              record.lotIsReceipt === false ? (
                <button
                  className="linkLot"
                  type="button"
                  onClick={() => handleGoToLotDetails(record)}
                >
                  <span className="value link">{record?.lotName}</span>
                </button>
              ) : (
                <span>{record.lotName}</span>
              )
            }
          />
          <Column
            title={translation.animal.columns.breed}
            dataIndex="breedName"
            key="breedName"
            align="left"
            width={120}
            filteredValue={tableFilters?.breedName || null}
            sorter
            sortOrder={
              tableSorters?.columnKey === "breedName" && tableSorters.order
            }
            sortDirections={["descend", "ascend"]}
            {...getColumnSearchProps("breed_name")}
          />
          <Column
            title={translation.animal.columns.birthdayMonths}
            dataIndex="birthdayMonth"
            key="birthdayMonth"
            align="left"
            width={150}
            render={(text, record) =>
              record.birthdayMonth !== null && (
                <span>{`${record.birthdayMonth}m`}</span>
              )
            }
          />
          <Column
            title={translation.animal.columns.reproductionCategory}
            dataIndex="animalReproductionCategory"
            key="animalReproductionCategory"
            align="left"
            width={150}
            filteredValue={tableFilters?.animalReproductionCategory || null}
            sorter
            sortOrder={
              tableSorters?.columnKey === "animalReproductionCategory" &&
              tableSorters.order
            }
            sortDirections={["descend", "ascend"]}
            {...getColumnSearchProps("animal_reproduction_category")}
          />
          <Column
            title={translation.animal.columns.reproductionStatus}
            dataIndex="femaleSituation"
            key="femaleSituation"
            filters={[
              {
                text: "Servida",
                value: "servida",
              },
              {
                text: "Prenha",
                value: "prenha",
              },
              {
                text: "Vazia",
                value: "vazia",
              },
            ]}
            filtered
            filterMultiple
            align="left"
            width={150}
            render={(text) => (
              <span>
                {text === "vazia"
                  ? "Vazia"
                  : text === "prenha"
                  ? "Prenha"
                  : text === "servida"
                  ? "Servida"
                  : ""}
              </span>
            )}
          />
          <Column
            title={translation.animal.columns.reproductionState}
            dataIndex="animalReproductionState"
            key="animalReproductionState"
            align="left"
            width={150}
            filters={[
              {
                text: translation.animalReproductionState.solteira,
                value: "solteira",
              },
              {
                text: translation.animalReproductionState.parida,
                value: "parida",
              },
            ]}
            filtered
            filterMultiple={false}
            render={(text) => (
              <span>
                {text ? translation.animalReproductionState[text] : "-"}
              </span>
            )}
          />
          <Column
            align="left"
            width={50}
            render={(text, record) => (
              <Dropdown
                overlay={menu(record.id, record)}
                trigger={["click"]}
                key={record.id}
              >
                <MenuContainer>
                  <MenuIcon />
                </MenuContainer>
              </Dropdown>
            )}
          />
        </Table>
      </Col>
    </Row>
  );
};

const AnimalReproductionMaleTable = () => {
  const {
    animal: {
      isLoadingMaleTable,
      reproductionMaleData,
      paginationMaleTable: { page, size, tableFilters, tableSorters },
    },
    app: { translation },
    user: { roles: userRoles },
  } = useSelector((state) => state);

  const { identificationSelected, fetchReproductionMaleData } =
    useAnimalReproductionContext();

  const {
    handleUpdateSelectedRowKeysMaleReproduction,
    handleDelete,
    handleInactivate,
    handleInactivateOrActivate,
    handleEdit,
    handleGoToLotDetails,
  } = useAnimalListContext();

  const isAllowed = useCallback(
    (roles, reverse) => {
      return userRoles?.includes("Admin")
        ? true
        : roles.length === 0
        ? true
        : reverse === false
        ? roles.some((r) => userRoles?.includes(r))
        : !roles.some((r) => userRoles?.includes(r));
    },
    [userRoles]
  );

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          value={selectedKeys && selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
            background: "#684e94",
            borderColor: "none",
            border: "none",
          }}
        />
        <Button
          onClick={() => handleReset(clearFilters)}
          icon="delete"
          size="small"
          style={{ width: 90 }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  const getColumnNumberSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ display: "block" }}>
          <Select
            value={
              selectedKeys
                ? selectedKeys["type"] != null
                  ? selectedKeys["type"]
                  : "="
                : "="
            }
            style={{
              width: 90,
              textAlign: "center",
              marginBottom: 8,
              marginRight: 8,
            }}
            onChange={(value) => {
              setSelectedKeys(
                value ? { ...selectedKeys, type: value } : { ...selectedKeys }
              );
            }}
          >
            <Select.Option key="<" value="<" style={{ textAlign: "center" }}>
              &lt;
            </Select.Option>
            <Select.Option key="<=" value="<=" style={{ textAlign: "center" }}>
              &le;
            </Select.Option>
            <Select.Option key="=" value="=" style={{ textAlign: "center" }}>
              &#61;
            </Select.Option>
            <Select.Option key=">=" value=">=" style={{ textAlign: "center" }}>
              &ge;
            </Select.Option>
            <Select.Option key=">" value=">" style={{ textAlign: "center" }}>
              &gt;
            </Select.Option>
          </Select>
          <InputNumber
            value={
              selectedKeys
                ? selectedKeys["value"] != null
                  ? selectedKeys["value"]
                  : null
                : null
            }
            onChange={(e) =>
              setSelectedKeys(
                e ? { ...selectedKeys, value: e } : { ...selectedKeys }
              )
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 90, marginBottom: 8 }}
            defaultValue={0}
            decimalSeparator=","
            min={-1000}
            max={1000}
            step={0.01}
            formatter={(value) => {
              if (value !== "") {
                value = `${value}`.replace(/^-^[^0-9.,]*/g, "");
                value = value.replace(/\.{2,}/g, ".");
                value = value.replace(/\.,/g, ",");
                value = value.replace(/,\./g, ",");
                value = value.replace(/,{2,}/g, ",");
                value = value.replace(/\.[0-9]+\./g, ".");
                value =
                  value.split(".")[1] !== "" &&
                  value.split(".")[1] !== undefined
                    ? value.split(".")[0] +
                      "." +
                      value.split(".")[1].substring(0, 2)
                    : value;
              }
              return value;
            }}
          />
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  const getColumnDateSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ display: "block" }}>
          <Select
            value={
              selectedKeys
                ? selectedKeys["type"] != null
                  ? selectedKeys["type"]
                  : "="
                : "="
            }
            style={{
              width: 90,
              textAlign: "center",
              marginBottom: 8,
              marginRight: 8,
            }}
            onChange={(value) => {
              setSelectedKeys(
                value ? { ...selectedKeys, type: value } : { ...selectedKeys }
              );
            }}
          >
            <Select.Option key="<" value="<" style={{ textAlign: "center" }}>
              &lt;
            </Select.Option>
            <Select.Option key="<=" value="<=" style={{ textAlign: "center" }}>
              &le;
            </Select.Option>
            <Select.Option key="=" value="=" style={{ textAlign: "center" }}>
              &#61;
            </Select.Option>
            <Select.Option key=">=" value=">=" style={{ textAlign: "center" }}>
              &ge;
            </Select.Option>
            <Select.Option key=">" value=">" style={{ textAlign: "center" }}>
              &gt;
            </Select.Option>
          </Select>
          <DatePicker
            value={
              selectedKeys
                ? selectedKeys["value"] != null
                  ? moment(selectedKeys["value"])
                  : null
                : null
            }
            format={"DD/MM/YYYY"}
            onChange={(date) =>
              setSelectedKeys(
                date ? { ...selectedKeys, value: date } : { ...selectedKeys }
              )
            }
          />
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  const handleTableChange = (pagination, filters, sorter) => {
    fetchReproductionMaleData(
      pagination.current,
      sorter,
      filters,
      pagination.pageSize,
      null
    );
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const handleSelectRowTable = {
    onChange: (selectedRowKeys, selectedRows) => {
      handleUpdateSelectedRowKeysMaleReproduction(selectedRowKeys);
    },
  };

  const menu = useCallback(
    (id, animal) => {
      return (
        <Menu>
          {animal?.farmStatus === "I" && (
            <Menu.Item key="0">
              <button
                onClick={() =>
                  handleInactivateOrActivate(id, animal, "activate")
                }
              >
                {translation.table.menu.activate}
              </button>
            </Menu.Item>
          )}
          <Menu.Item key="1">
            <Link to={`/admin/animals/${id}`}>
              {translation.table.menu.details}
            </Link>
          </Menu.Item>
          {(animal?.farmStatus === "A" || animal?.farmStatus === "P") && (
            <Menu.Item key="2" onClick={() => handleEdit(id)}>
              {translation.table.menu.edit}
            </Menu.Item>
          )}
          {(animal?.farmStatus === "A" || animal?.farmStatus === "P") &&
            isAllowed(["Trial"], true) &&
            animal?.isFromBirth === false && (
              <Menu.Item key="3" onClick={() => handleDelete(animal)}>
                {translation.table.menu.delete}
              </Menu.Item>
            )}
          {(animal?.farmStatus === "A" || animal?.farmStatus === "P") &&
            isAllowed(["Trial"], true) && (
              <Menu.Item
                key="4"
                onClick={async () => {
                  handleInactivate(animal);
                }}
              >
                {translation.table.menu.inactivate}
              </Menu.Item>
            )}
        </Menu>
      );
    },
    [
      translation.table.menu.activate,
      translation.table.menu.details,
      translation.table.menu.edit,
      translation.table.menu.delete,
      translation.table.menu.inactivate,
      isAllowed,
      handleInactivateOrActivate,
      handleEdit,
      handleDelete,
      handleInactivate,
    ]
  );

  return (
    <Table
      rowKey="id"
      loading={isLoadingMaleTable}
      rowSelection={handleSelectRowTable}
      dataSource={
        reproductionMaleData != null ? reproductionMaleData.content : []
      }
      pagination={{
        total:
          reproductionMaleData != null ? reproductionMaleData.totalElements : 0,
        size: reproductionMaleData != null ? size : 0,
        current: reproductionMaleData != null ? page : 0,
        hideOnSinglePage:
          reproductionMaleData != null &&
          Object.entries(reproductionMaleData).length !== 0
            ? reproductionMaleData.totalElements > 10
              ? false
              : true
            : true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30", "100", "200"],
      }}
      scroll={{
        x: 1200,
      }}
      footer={() => (
        <span>
          <strong>Total de animais filtrados: </strong>
          {` ${
            reproductionMaleData != null
              ? reproductionMaleData?.totalElements
              : 0
          } animais`}
        </span>
      )}
      onChange={handleTableChange}
    >
      {identificationSelected === "handlingNumber" ? (
        <Column
          title={translation.animal.columns.handlingNumber}
          dataIndex="handlingNumber"
          sorter
          key="handlingNumber"
          filteredValue={tableFilters?.handlingNumber || null}
          align="left"
          width={200}
          sortOrder={
            tableSorters?.columnKey === "handlingNumber" && tableSorters.order
          }
          {...getColumnSearchProps("handling_number")}
          render={(text, record) => (
            <CustomLink to={`/admin/animals/${record.id}`}>{text}</CustomLink>
          )}
        />
      ) : identificationSelected === "tagId" ? (
        <Column
          title={translation.animal.columns.handlingNumber}
          dataIndex="tagId"
          sorter
          key="tagId"
          filteredValue={tableFilters?.tagId || null}
          align="left"
          width={200}
          sortOrder={tableSorters?.columnKey === "tagId" && tableSorters.order}
          {...getColumnSearchProps("tag_id")}
          render={(text, record) => (
            <CustomLink to={`/admin/animals/${record.id}`}>{text}</CustomLink>
          )}
        />
      ) : identificationSelected === "sisbov" ? (
        <Column
          title={translation.animal.columns.handlingNumber}
          dataIndex="sisbov"
          sorter
          key="sisbov"
          filteredValue={tableFilters?.sisbov || null}
          align="left"
          width={200}
          sortOrder={tableSorters?.columnKey === "sisbov" && tableSorters.order}
          {...getColumnSearchProps("sisbov")}
          render={(text, record) => (
            <CustomLink to={`/admin/animals/${record.id}`}>{text}</CustomLink>
          )}
        />
      ) : (
        <Column
          title={translation.animal.columns.handlingNumber}
          dataIndex="nickname"
          sorter
          key="nickname"
          filteredValue={tableFilters?.nickname || null}
          align="left"
          width={200}
          sortOrder={
            tableSorters?.columnKey === "nickname" && tableSorters.order
          }
          {...getColumnSearchProps("nickname")}
          render={(text, record) => (
            <CustomLink to={`/admin/animals/${record.id}`}>{text}</CustomLink>
          )}
        />
      )}
      <Column
        title={translation.lot.production.table.columns.status}
        dataIndex="farmStatus"
        key="farmStatus"
        width={120}
        align="left"
        filteredValue={tableFilters?.farmStatus || null}
        filtered
        filters={[
          {
            text: translation.status.active,
            value: "A",
          },
          {
            text: translation.status.inactive,
            value: "I",
          },
          {
            text: "Vendido",
            value: "S",
          },
          {
            text: "Morto",
            value: "D",
          },
        ]}
        filterMultiple
        sorter
        sortOrder={
          tableSorters?.columnKey === "farmStatus" && tableSorters.order
        }
        sortDirections={["descend", "ascend"]}
        render={(farmStatus) =>
          farmStatus === "A" ? (
            <TagStatus
              background="#C5F1CA"
              borderColor="#106518"
              color="#106518"
            >
              {translation.status.active}
            </TagStatus>
          ) : farmStatus === "I" || farmStatus === "X" ? (
            <TagStatus
              background="#FBC7C7"
              borderColor="#D44C4C"
              color="#D44C4C"
            >
              {translation.status.inactive}
            </TagStatus>
          ) : farmStatus === "S" ? (
            <TagStatus
              background="#FEFFF6"
              borderColor="#A9C133"
              color="#A9C133"
            >
              Vendido
            </TagStatus>
          ) : farmStatus === "D" ? (
            <TagStatus background="#4b4b4b" borderColor="#000" color="#e3e3e3">
              Morto
            </TagStatus>
          ) : (
            <TagStatus
              background="#FBC7C7"
              borderColor="#D44C4C"
              color="#D44C4C"
            >
              {translation.status.inactive}
            </TagStatus>
          )
        }
      />
      <Column
        title={translation.animal.columns.picket}
        dataIndex="picketName"
        sorter
        key="picketName"
        filteredValue={tableFilters?.picketName || null}
        width={220}
        align="left"
        sortOrder={
          tableSorters?.columnKey === "picketName" && tableSorters.order
        }
        {...getColumnSearchProps("picket_name")}
      />
      <Column
        title={translation.animal.columns.lot}
        dataIndex="lotName"
        sorter
        key="lotName"
        filteredValue={tableFilters?.lotName || null}
        width={220}
        align="left"
        sortOrder={tableSorters?.columnKey === "lotName" && tableSorters.order}
        {...getColumnSearchProps("lot_name")}
        render={(text, record) =>
          record.lotIsReceipt === false ? (
            <button
              className="linkLot"
              type="button"
              onClick={() => handleGoToLotDetails(record)}
            >
              <span className="value link">{record?.lotName}</span>
            </button>
          ) : (
            <span>{record.lotName}</span>
          )
        }
      />
      <Column
        title={translation.animal.columns.breed}
        dataIndex="breedName"
        key="breedName"
        align="left"
        width={120}
        filteredValue={tableFilters?.breedName || null}
        sorter
        sortOrder={
          tableSorters?.columnKey === "breedName" && tableSorters.order
        }
        sortDirections={["descend", "ascend"]}
        {...getColumnSearchProps("breed_name")}
      />
      <Column
        title={translation.animal.columns.birthdayMonths}
        dataIndex="birthdayMonth"
        key="birthdayMonth"
        align="left"
        width={150}
        render={(text, record) =>
          record.birthdayMonth !== null && (
            <span>{`${record.birthdayMonth}m`}</span>
          )
        }
      />
      <Column
        title={translation.animal.columns.currentWeight}
        dataIndex="lastDailyWeight"
        key="lastDailyWeight"
        align="left"
        width={200}
        sorter
        sortOrder={
          tableSorters?.columnKey === "lastDailyWeight" && tableSorters.order
        }
        sortDirections={["descend", "ascend"]}
        {...getColumnNumberSearchProps("lastDailyWeight")}
        render={(text, record) => `${numberMask(text || 0, false)}kg`}
      />

      <Column
        title={translation.animal.columns.gdp}
        dataIndex="lastDailyWeightGain"
        key="lastDailyWeightGain"
        align="left"
        width={200}
        sorter
        sortOrder={
          tableSorters?.columnKey === "lastDailyWeightGain" &&
          tableSorters.order
        }
        sortDirections={["descend", "ascend"]}
        {...getColumnNumberSearchProps("lastDailyWeightGain")}
        render={(text, record) => `${numberMask(text || 0, false)} Kg`}
      />

      <Column
        title="Última Pesagem"
        dataIndex="lastDailyWeightDate"
        key="lastDailyWeightDate"
        align="left"
        width={200}
        sorter
        sortOrder={
          tableSorters?.columnKey === "lastDailyWeightDate" &&
          tableSorters.order
        }
        sortDirections={["descend", "ascend"]}
        {...getColumnDateSearchProps("lastDailyWeightDate")}
        render={(text, record) => moment(text).format("DD/MM/YYYY")}
      />
      <Column
        align="left"
        width={50}
        render={(text, record) => (
          <Dropdown
            overlay={menu(record.id, record)}
            trigger={["click"]}
            key={record.id}
          >
            <MenuContainer>
              <MenuIcon />
            </MenuContainer>
          </Dropdown>
        )}
      />
    </Table>
  );
};

export {
  AnimalReproductionFemaleReproductionEventsTable,
  AnimalReproductionFemaleTable,
  AnimalReproductionMaleTable,
};
