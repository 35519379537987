export const Types = {
  RESET_DATA: "typologyItem/RESET_DATA",
  GET_ALL_TYPOLOGYITEM: "typologyItem/GET_ALL_TYPOLOGYITEM",
  GET_ALL_TYPOLOGYITEM_SUCCESS: "typologyItem/GET_ALL_TYPOLOGYITEM_SUCCESS",
  GET_ALL_TYPOLOGYITEM_ERROR: "typologyItem/GET_ALL_TYPOLOGYITEM_ERROR"
};

export const Creators = {
  indexTypologyItem: (groupId, farmId, typologyName) => ({
    type: Types.GET_ALL_TYPOLOGYITEM,
    payload: {
      groupId,
      farmId,
      typologyName
    }
  }),
  indexTypologyItemSuccess: data => ({
    type: Types.GET_ALL_TYPOLOGYITEM_SUCCESS,
    payload: {
      data
    }
  }),
  indexTypologyItemError: error => ({
    type: Types.GET_ALL_TYPOLOGYITEM_ERROR,
    payload: {
      error
    }
  })
};

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: {}
};

export default function typologyItem(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_ALL_TYPOLOGYITEM:
      return { ...state, isLoading: true };
    case Types.GET_ALL_TYPOLOGYITEM_SUCCESS:
      const { data: typologyItems } = action.payload;
      return { ...state, isLoading: false, data: typologyItems };
    case Types.GET_ALL_TYPOLOGYITEM_ERROR:
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: {},
        error
      };
    default:
      return state;
  }
}
