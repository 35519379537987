import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

//Ducks
import { Creators as BoitelSaleScenarioActions } from "../../store/ducks/boitelSaleScenario";

//components
import { Row, Col, Table, Icon, Input, Button, notification, Spin } from "antd";
import ButtonStandard from "../../components/utils/button";
import TagStatus from "../../components/utils/tagStatus";
import { CardCustom, Title } from "./styles";
import { GenerateReport } from "../animal/styles";
// Icons
import EyeIcon from "../../components/utils/icons/eye";
import BigPencilIcon from "../../components/utils/icons/pencil/big";
import StarIcon from "../../components/utils/icons/star";

//Services

//Helpers
import { numberMask } from "../../services/helpersMethodsService";
import {
  downloadBoitelSaleScenarioReportService,
  reprocessBoitelSaleScenarioService,
} from "../../services/boitelSaleScenarioService";

const ListBoitelSaleScenario = ({ ...props }) => {
  const history = useHistory();
  const [tableChange, setTableChange] = useState({
    page: 0,
    size: 10,
    sorter: null,
    filters: "",
  });
  const [resultsSelected, setResultsSelected] = useState([]);
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /** Redux libs */
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { isLoadingListBoitelSaleScenario, listBoitelSaleScenarioData } =
    useSelector((state) => state.boitelSaleScenario);
  const dispatch = useDispatch();

  const rowSelection = {
    selectedRowKeys: resultsSelected.map((s) => s),
    getCheckboxProps: (record) => {
      return {
        disabled:
          record.identification === null ||
          (!resultsSelected.includes(record.id) && resultsSelected.length >= 3),
      };
    },
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      if (selected) {
        setResultsSelected([...resultsSelected, record.id]);
      } else {
        setResultsSelected(resultsSelected.filter((r) => r !== record.id));
      }
    },
    onSelectAll: (record, selected, selectedRows, nativeEvent) => {
      // Get
      const keys = selectedRows.map((sr) => sr.id);
      // Get all the resultsSelected unless the selectedRows
      const lastResultsSelected = resultsSelected.filter(
        (sAK) => !keys.includes(sAK)
      );
      if (selected.length > 0) {
        const sliceKeys = keys.slice(
          0,
          lastResultsSelected.length > 0
            ? 3 - lastResultsSelected.length
            : keys.length > 3
            ? 3
            : keys.length
        );
        setResultsSelected((old) => [...old, ...sliceKeys]);
      } else {
        setResultsSelected(lastResultsSelected);
      }
    },
  };

  // Effects

  useEffect(() => {
    async function fetchData(page = 0, sorter = {}, filters = "", size = 10) {
      dispatch(
        BoitelSaleScenarioActions.getBoitelSaleScenarioListData(
          groupId,
          farmId,
          page,
          sorter,
          filters,
          size
        )
      );
    }
    fetchData();
  }, [groupId, farmId, dispatch]);

  // Methods
  const goToNewBoitelSaleScenario = () => {
    dispatch(BoitelSaleScenarioActions.clearFields(true));
    history.push("/admin/decisions/scenarios/sales/boitel/new");
  };

  const goToEditBoitelSaleScenario = (record) => {
    dispatch(BoitelSaleScenarioActions.clearFields(true));
    history.push(`/admin/decisions/scenarios/sales/boitel/${record.id}/edit`);
  };

  const reprocessBoitelSaleScenario = async (record) => {
    const { id } = record;
    if (record.status !== "Analyzing") {
      setIsLoading(true);
      try {
        const {
          data: { results },
        } = await reprocessBoitelSaleScenarioService({
          groupId,
          farmId,
          id,
        });
        dispatch(
          BoitelSaleScenarioActions.updateStatusBoitelSaleScenarioListDataItem(
            id,
            results.status
          )
        );
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const goToDetailsBoitelSaleScenario = (record) => {
    const { id, results } = record;
    if (results.length > 0) {
      const idResult = results[results.length - 1]?.id;
      history.push(
        `/admin/decisions/scenarios/sales/boitel/${id}/results/${idResult}`
      );
    }
  };

  function fetchData(page = 0, sorter = {}, filters = "", size = 10) {
    dispatch(
      BoitelSaleScenarioActions.getBoitelSaleScenarioListData(
        groupId,
        farmId,
        page,
        sorter,
        filters,
        size
      )
    );
  }

  const handleReloadTable = () => {
    const { page, size, sorter, filters } = tableChange;
    fetchData(page, sorter, filters, size);
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    let search = "";

    if (Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v.length > 0) {
          if (k === "status") {
            search =
              search === ""
                ? `${k} in (${v.map((o) => (o = `'${o}'`)).join(", ")})`
                : `${search};${k} in (${v
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`;
          } else {
            search =
              search === ""
                ? `upper(${k}) like upper('%25${v}%25')`
                : `${search};upper(${k}) like upper('%25${v}%25')`;
          }
        }
      });
    } else {
      search = "";
    }

    setTableChange({
      page: pagination.current,
      size: pagination.pageSize,
      sorter,
      filters: search,
    });

    fetchData(pagination.current, sorter, search, pagination.pageSize);
  };

  function expandedRows(record, index, indent, expanded) {
    const {
      detailsRow: { identification, saleAnimals, receive, margin, processedAt },
    } = translation.boitelSaleScenario.table;

    const columns = [
      {
        dataIndex: ["baseline"],
        align: "center",
        render: (text, { baseline }) => (
          <span>
            <StarIcon color={baseline ? "#FFD80D" : "#C4C4C4"} size={24} />
          </span>
        ),
      },
      {
        title: identification,
        dataIndex: "identification",
        align: "left",
        render: (text, { identification }) => <span>{identification}</span>,
      },
      {
        title: saleAnimals,
        dataIndex: "amountAnimals",
        align: "right",
        render: (text, { amountAnimals }) => (
          <span>{!!amountAnimals ? amountAnimals : "-"}</span>
        ),
      },
      {
        title: receive,
        dataIndex: ["amountSaleIncome"],
        align: "right",
        render: (text, { amountSaleIncome }) =>
          numberMask(amountSaleIncome, true),
      },
      {
        title: margin,
        dataIndex: ["amountAnimalsMargin"],
        align: "right",
        render: (text, { amountAnimalsMargin }) =>
          numberMask(amountAnimalsMargin, true),
      },
      {
        title: processedAt,
        dataIndex: "processedAt",
        align: "right",
        render: (text, { processedAt }) => (
          <span>
            {!!processedAt
              ? `${moment(processedAt, "yyyy-MM-DD HH:mm:ss").format(
                  "DD/MM/YYYY  HH:mm:ss"
                )}`
              : "-"}
          </span>
        ),
      },
    ];

    const { results } = record;

    if (!results || results.length === 0) return false;

    return (
      <Table
        key="id"
        rowKey={(k) => k.id}
        columns={columns}
        dataSource={results}
        pagination={false}
        style={{ padding: "2" }}
        rowSelection={{
          ...rowSelection,
        }}
      />
    );
  }

  const handleGenerateReport = async () => {
    if (resultsSelected.length > 0) {
      const payload = {
        groupId,
        farmId,
        body: {
          resultsId: resultsSelected,
        },
      };

      setIsGeneratingReport(true);

      try {
        const { data } = await downloadBoitelSaleScenarioReportService(payload);
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        let link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "cenario_boitel.pdf");
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        notification.error({
          message: "Não foi possível gerar o PDF. Contate o suporte.",
        });
      } finally {
        setIsGeneratingReport(false);
      }
    }
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
            background: "#684e94",
            borderColor: "none",
            border: "none",
          }}
        />
        <Button
          onClick={() => handleReset(clearFilters)}
          icon="delete"
          size="small"
          style={{ width: 90 }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  const menuActions = (record) => {
    return (
      <>
        <ButtonStandard
          buttonType="typeWithoutBackground"
          style={{ marginLeft: 10 }}
          onClick={() => goToDetailsBoitelSaleScenario(record)}
        >
          <EyeIcon isActive />
        </ButtonStandard>
        {record.status !== "Sold" && (
          <ButtonStandard
            buttonType="typeWithoutBackground"
            style={{ marginLeft: 10 }}
            onClick={() => goToEditBoitelSaleScenario(record)}
          >
            <BigPencilIcon color="#684e94" />
          </ButtonStandard>
        )}
        {record.status !== "Sold" && (
          <ButtonStandard
            buttonType="typeWithoutBackground"
            style={{ marginLeft: 10 }}
            onClick={() => reprocessBoitelSaleScenario(record)}
          >
            <Icon type="sync" style={{ fontSize: 15 }} />
          </ButtonStandard>
        )}
      </>
    );
  };

  const tagStatus = (status, translation) => {
    let background = "#FFDBBC",
      borderColor = "#FE8D2A",
      color = "#FE8D2A",
      label = translation.boitelSaleScenario.table.column.labelStatus.analyzing;

    if (status === "Processed") {
      background = "#EBF7FF";
      borderColor = "#3FB3E5";
      color = "#3FB3E5";
      label = translation.boitelSaleScenario.table.column.labelStatus.processed;
    } else if (status === "Canceled") {
      background = "#F5F5F5";
      borderColor = "#A5A5A5";
      color = "#A5A5A5";
      label = translation.boitelSaleScenario.table.column.labelStatus.canceled;
    } else if (status === "PartialCanceled") {
      background = "#F5F5F5";
      borderColor = "#A5A5A5";
      color = "#A5A5A5";
      label =
        translation.boitelSaleScenario.table.column.labelStatus.partialCanceled;
    } else if (status === "Sold") {
      background = "#C5F1CA";
      borderColor = "#106518";
      color = "#106518";
      label = translation.boitelSaleScenario.table.column.labelStatus.sold;
    } else if (status === "PartialSold") {
      background = "#C5F1CA";
      borderColor = "#106518";
      color = "#106518";
      label =
        translation.boitelSaleScenario.table.column.labelStatus.partialSold;
    } else if (status === "Error") {
      return (
        <Icon type="exclamation-circle" className="iconError" theme="filled" />
      );
    }

    return (
      <TagStatus
        background={background}
        borderColor={borderColor}
        color={color}
      >
        {label}
      </TagStatus>
    );
  };

  return (
    <CardCustom>
      {/* Header */}
      <Row type="flex" justify="space-between" className="rowHeader">
        <Col>
          <Title>{translation.boitelSaleScenario.title}</Title>
        </Col>
        <Col>
          <ButtonStandard
            buttonType="typeWithoutBackground"
            onClick={handleReloadTable}
          >
            <Icon type="reload" />
          </ButtonStandard>
          <GenerateReport
            onClick={handleGenerateReport}
            className={resultsSelected.length === 0 ? "disabled" : ""}
          >
            <Spin spinning={isGeneratingReport} size="small">
              Gerar Relatório
            </Spin>
          </GenerateReport>
          <ButtonStandard
            buttonType="type8"
            onClick={goToNewBoitelSaleScenario}
          >
            {translation.boitelSaleScenario.btn.newScenare}
          </ButtonStandard>
        </Col>
      </Row>

      {/* Table */}
      <Row type="flex" justify="space-between">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            rowKey="id"
            size="small"
            dataSource={
              !listBoitelSaleScenarioData?.content
                ? []
                : listBoitelSaleScenarioData.content
            }
            expandedRowRender={expandedRows}
            loading={isLoadingListBoitelSaleScenario || isLoading}
            pagination={{
              total:
                listBoitelSaleScenarioData != null
                  ? listBoitelSaleScenarioData.totalElements
                  : 0,
              size:
                listBoitelSaleScenarioData != null
                  ? listBoitelSaleScenarioData.size
                  : 0,
              current:
                listBoitelSaleScenarioData != null
                  ? listBoitelSaleScenarioData.number + 1
                  : 0,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
              hideOnSinglePage:
                listBoitelSaleScenarioData != null &&
                listBoitelSaleScenarioData.totalElements > 10
                  ? false
                  : true,
            }}
            style={{ width: "100%", margin: "0" }}
            onChange={handleTableChange}
          >
            <Table.Column
              title={translation.boitelSaleScenario.table.column.scenare}
              dataIndex="name"
              sorter
              key="name"
              align="left"
              {...getColumnSearchProps("name")}
            />
            <Table.Column
              title={translation.boitelSaleScenario.table.column.client}
              dataIndex="supplier.name"
              sorter
              key="supplier.name"
              align="left"
              {...getColumnSearchProps("supplier.name")}
            />
            <Table.Column
              title={translation.boitelSaleScenario.table.column.favorite}
              dataIndex="baseline"
              sorter
              key="baseline"
              align="left"
              render={(baseline, date) => (
                <span>
                  {baseline ? <StarIcon color="#FFD80D" size={24} /> : ""}
                </span>
              )}
            />
            <Table.Column
              title={translation.boitelSaleScenario.table.column.status}
              dataIndex="status"
              sorter
              key="status"
              align="center"
              render={(status, record) => tagStatus(status, translation)}
            />
            <Table.Column
              align="left"
              title={translation.boitelSaleScenario.table.column.actions}
              render={(text, record) => menuActions(record)}
            />
          </Table>
        </Col>
      </Row>
    </CardCustom>
  );
};

export default ListBoitelSaleScenario;
