import apiFinancial from "../config/api_financial";
import getHeaders from "./getHeaders";

export function indexAll(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/result/centers`,
    getHeaders(false, signal)
  );
}

export function indexAllWithItems(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/result/centers/with/items`,
    getHeaders(false, signal)
  );
}

export function indexAllWithApportionmentInfo(payload) {
  const { groupId, farmId, financialNatureId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/result/centers/with/apportionment/nature/${financialNatureId}`,
    getHeaders(false, signal)
  );
}

export function findById(payload) {
  const { groupId, farmId, id } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/result/centers/${id}`,
    getHeaders(false, null)
  );
}

export function findApportionmentValuesById(payload) {
  const { groupId, farmId, id } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/result/centers/${id}/apportionment/values`,
    getHeaders(false, null)
  );
}

export function save(payload) {
  const { groupId, farmId, body } = payload;

  return apiFinancial.post(`/bovexo/${groupId}/farms/${farmId}/result/centers`,
    body,
    getHeaders(false, null)
  );
}

export function update(payload) {
  const { groupId, farmId, id, body } = payload;

  return apiFinancial.patch(`/bovexo/${groupId}/farms/${farmId}/result/centers/${id}`,
    body,
    getHeaders(false, null)
  );
}

export function updateApportionments(payload) {
  const { groupId, farmId, id, body } = payload;

  return apiFinancial.post(`/bovexo/${groupId}/farms/${farmId}/result/centers/${id}/apportionment/values`,
    body,
    getHeaders(false, null)
  );
}

export function Inactivate(payload) {
  const { groupId, farmId, id } = payload;

  return apiFinancial.put(`/bovexo/${groupId}/farms/${farmId}/result/centers/${id}/inactivate`,
    null,
    getHeaders(false, null)
  );
}

export function Activate(payload) {
  const { groupId, farmId, id } = payload;

  return apiFinancial.put(`/bovexo/${groupId}/farms/${farmId}/result/centers/${id}/activate`,
    null,
    getHeaders(false, null)
  );
}

export function deleteById(payload) {
  const { groupId, farmId, id } = payload;

  return apiFinancial.delete(`/bovexo/${groupId}/farms/${farmId}/result/centers/${id}`,
    getHeaders(false, null)
  );
}