import React, { createContext, useState, useCallback } from "react";
import { useSelector } from "react-redux";

// Services
import { findAllAnimalsForMovement } from "../../services/movementAnimalsService";

export const MovementAnimalsContext = createContext({
  form: {
    destinationLots: [],
  },
  allAnimals: null,
  animalsDismembered: [],
  selectedAnimalsKeys: [],
  isLoadingAnimals: false,
  isFormDrawerVisible: false,
});

export const MovementAnimalsContextProvider = ({ children }) => {
  const [form, setForm] = useState({
    destinationLots: [],
  });
  const [allAnimals, setAllAnimals] = useState(null);
  const [animalsDismembered, setAnimalsDismembered] = useState([]);
  const [selectedAnimalsKeys, setSelectedAnimalsKeys] = useState([]);
  const [isLoadingAnimals, setIsLoadingAnimals] = useState(false);
  const [isFormDrawerVisible, setIsFormDrawerVisible] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    sorter: null,
    filters: null,
    size: 10,
    ids: null,
    tableSorters: null,
    tableFilters: null,
  });
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const getAnimals = useCallback(
    async (justIds = false) => {
      const { page, sorter, filters, size } = pagination;

      setIsLoadingAnimals(true);

      let newFilters = filters;

      if (animalsDismembered.length > 0) {
        let filter = "";

        filter =
          newFilters === "" || newFilters == null
            ? `id not in (`
            : `${newFilters};id not in (`;

        animalsDismembered.forEach((animal) => {
          filter = `${filter}'${animal}',`;
        });

        filter = `${filter})`;
        filter = filter.replace(",)", ")");
        newFilters = filter;
      }

      try {
        const {
          data: { results },
        } = await findAllAnimalsForMovement({
          groupId,
          farmId,
          page,
          sorter,
          filters: newFilters,
          size,
          withoutPagination: false,
          justIds,
        });
        setAllAnimals(results);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingAnimals(false);
      }
    },
    [animalsDismembered, farmId, groupId, pagination]
  );

  const updatePagination = useCallback((pagination) => {
    setPagination(pagination);
  }, []);

  const updateSelectedAnimalsKeys = useCallback(
    (newSelectedAnimalsKeys = []) => {
      setSelectedAnimalsKeys(newSelectedAnimalsKeys);
    },
    []
  );

  const updateAnimalsDismembered = useCallback((newAnimalsDismembered = []) => {
    setAnimalsDismembered(newAnimalsDismembered);
    setSelectedAnimalsKeys([]);
  }, []);

  const openDrawer = () => {
    setIsFormDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsFormDrawerVisible(false);
  };

  const addDestinationLot = (destinationLot) => {
    setForm((old) => ({
      ...old,
      destinationLots: [...old.destinationLots, destinationLot],
    }));
  };

  const removeDestinationLot = (key) => {
    const destinationLotKey = form.destinationLots.find((l) => l.key === key);
    const newAnimalsDismembered = animalsDismembered.filter(
      (id) => !destinationLotKey.animals.map((a) => a.animalId).includes(id)
    );
    setForm((old) => ({
      ...old,
      destinationLots: old.destinationLots.filter((l) => l.key !== key),
    }));
    setAnimalsDismembered(newAnimalsDismembered);
  };

  const switchIsLoadingAnimals = () => {
    setIsLoadingAnimals((old) => !old);
  };

  const resetForm = () => {
    setForm({
      destinationLots: [],
    });
  };

  return (
    <MovementAnimalsContext.Provider
      value={{
        form,
        allAnimals,
        animalsDismembered,
        selectedAnimalsKeys,
        isLoadingAnimals,
        isFormDrawerVisible,
        pagination,
        updatePagination,
        getAnimals,
        updateSelectedAnimalsKeys,
        updateAnimalsDismembered,
        openDrawer,
        closeDrawer,
        addDestinationLot,
        removeDestinationLot,
        switchIsLoadingAnimals,
        resetForm,
      }}
    >
      {children}
    </MovementAnimalsContext.Provider>
  );
};
