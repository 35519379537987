import React from 'react';

// import { Container } from './styles';

const EnglishActiveFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="22" viewBox="0 0 27 22">
    <g id="EN-US" transform="translate(0.193)">
      <rect
        id="Retângulo_234"
        data-name="Retângulo 234"
        width="27"
        height="22"
        rx="3"
        transform="translate(-0.193)"
        fill="#fff"
      />
      <path
        id="Interseção_2"
        data-name="Interseção 2"
        d="M2.83,2A3,3,0,0,1,0,0H26.658a3,3,0,0,1-2.829,2Z"
        transform="translate(-0.023 19.999)"
        fill="#ee5151"
      />
      <path
        id="Interseção_1"
        data-name="Interseção 1"
        d="M0,2A3,3,0,0,1,2.829,0h21a3,3,0,0,1,2.829,2Z"
        transform="translate(-0.022 0)"
        fill="#ee5151"
      />
      <rect
        id="Retângulo_226"
        data-name="Retângulo 226"
        width="27"
        height="2"
        transform="translate(-0.193 4)"
        fill="#ee5151"
      />
      <rect
        id="Retângulo_227"
        data-name="Retângulo 227"
        width="27"
        height="2"
        transform="translate(-0.193 8.001)"
        fill="#ee5151"
      />
      <rect
        id="Retângulo_228"
        data-name="Retângulo 228"
        width="27"
        height="2"
        transform="translate(-0.193 12.001)"
        fill="#ee5151"
      />
      <rect
        id="Retângulo_229"
        data-name="Retângulo 229"
        width="27"
        height="2"
        transform="translate(-0.193 16.001)"
        fill="#ee5151"
      />
      <path
        id="Caminho_701"
        data-name="Caminho 701"
        d="M0,0H10.219A3.076,3.076,0,0,1,13.28,3.09V18.91A3.076,3.076,0,0,1,10.219,22H0Z"
        transform="translate(13.527 0)"
        fill="#0f0f0f"
        opacity="0.11"
      />
      <path
        id="Retângulo_232"
        data-name="Retângulo 232"
        d="M3,0h9a0,0,0,0,1,0,0V10a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z"
        transform="translate(-0.193)"
        fill="#3e3baa"
      />
    </g>
  </svg>
);
export default EnglishActiveFlag;
