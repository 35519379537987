import React, { useState, useMemo, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
/** Redux libs */
import { useSelector, useDispatch } from "react-redux";
import { Creators as BoitelSaleScenarioFarmSellActions } from "../../../store/ducks/boitelSaleScenarioFarmSell";
/** Components */
import {
  Container,
  ContainerDropdown,
  ItemMenu,
  ItemMenuSmall,
} from "./styles";
import {
  Row,
  Col,
  Icon,
  Input,
  DatePicker,
  Table,
  Divider,
  notification,
  Button,
  Menu,
  Dropdown,
  Modal,
  Select,
} from "antd";
import NumberFormat from "react-number-format";
import ButtonStandard from "../../../components/utils/button";
import SalePercentageIcon from "../../../components/utils/icons/salePercentage";
import ConfirmActionModal from "../../../components/modals/confirmActionModal";
import TrashIcon from "../../../components/utils/icons/trash";
import InfoTooltip from "../../../components/utils/infoTooltip";

/** Services */
import {
  cnpjMask,
  cpfMask,
  getTwoDecimalDigits,
  numberMask,
} from "../../../services/helpersMethodsService";
import { getListOfClientsDropDown } from "../../../services/managementService";
import { storeBoitelSaleScenarioFarmSell } from "../../../services/boitelSaleScenarioFarmSellService";
import { getLotStatistics } from "../../../services/lotService";

const NewBoitelSaleScenarioFarmSell = () => {
  const dateFormat = "DD/MM/YYYY";
  let searchInput = useRef(null);
  const [form, setForm] = useState({
    boitelSaleScenarioResultId: null,
    clientName: null,
    sellDate: null,
    priceNegotiated: null,
    weightPerAnimal: null,
    carcassHarnessing: null,
    amountAnimalsSold: null,
    totalBilling: null,
    totalMarginBilling: null,
    estimatedBilling: null,
  });
  const [errors, setErrors] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoadingAnimals, setIsLoadingAnimals] = useState(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [animalsData, setAnimalsData] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [clientSelected, setClientSelected] = useState("Clientes");

  /** Props variable */
  const { id: boitelSaleScenarioId, resultId: boitelSaleScenarioResultId } =
    useParams();
  const history = useHistory();
  /** Redux variables */
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { animalsSelected } = useSelector(
    (state) => state.boitelSaleScenarioFarmSell
  );
  const dispatch = useDispatch();
  /** Effect */
  // Get suppliers
  useEffect(() => {
    async function fetchAnimals() {
      try {
        const {
          data: { results: resultsClient },
        } = await getListOfClientsDropDown({
          groupId,
          farmId,
          onlyActives: true,
        });
        setClientData(resultsClient);
      } catch (error) {
        setIsLoadingAnimals(false);
        console.error(error);
      }
    }
    fetchAnimals();
  }, [groupId, farmId]);
  // Set Animals
  useEffect(() => {
    setAnimalsData(animalsSelected);
  }, [animalsSelected]);

  /** Memo */
  // Get sum of all animals price
  const estimatedBilling = useMemo(() => {
    return animalsSelected.length > 0
      ? getTwoDecimalDigits(
          animalsSelected?.reduce((a, b) => {
            return a + b.saleIncome;
          }, 0)
        )
      : 0;
  }, [animalsSelected]);

  // Calculate the animals cost, get the price - margin
  const totalAnimalCost = useMemo(() => {
    return animalsSelected.length > 0
      ? getTwoDecimalDigits(
          animalsSelected?.reduce((a, b) => {
            return a + (b.saleIncome - b.margin);
          }, 0)
        )
      : 0;
  }, [animalsSelected]);

  // Get amount animals to be sold
  const amountAnimals = useMemo(() => {
    return animalsSelected.length;
  }, [animalsSelected.length]);

  // Calculate the actual billing of the selling = Faturamento real
  const totalBilling = useMemo(() => {
    const { priceNegotiated, weightPerAnimal, carcassHarnessing } = form;
    return getTwoDecimalDigits(
      (priceNegotiated || 0) *
        ((weightPerAnimal || 0) / 15) *
        ((carcassHarnessing || 0) / 100) *
        amountAnimals
    );
  }, [form, amountAnimals]);

  // Calculate the actual profit of the selling = Lucro real
  const totalMarginBilling = useMemo(() => {
    return Number(totalBilling - totalAnimalCost);
  }, [totalBilling, totalAnimalCost]);

  const { SubMenu } = Menu;

  const menu = (
    <Menu>
      <SubMenu
        title="Empresa"
        onClick={(e) => {
          setForm({ ...form, clientName: e.key });
          setClientSelected(e.key);
          setErrors(removeError("clientName"));
        }}
      >
        {clientData?.map((item, index) => {
          if (item?.documentType === "CNPJ") {
            return (
              <ItemMenu key={item?.name} className="hover">
                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: 15 }}
                >
                  <span className="title">{item?.name}</span>
                  <span className="client">Cliente Bovexo</span>
                </Row>
                <Row type="flex" justify="space-between" align="middle">
                  <span className="info">Responsável</span>
                  <span className="info-bold">{item?.responsibleName}</span>
                </Row>
                <Row type="flex" justify="space-between" align="middle">
                  <span className="info">CNPJ</span>
                  <span className="info-bold">{cnpjMask(item?.document)}</span>
                </Row>
                <Row type="flex" justify="space-between" align="middle">
                  <span className="info">Nº CREA</span>
                  <span className="info-bold">{item?.register}</span>
                </Row>
              </ItemMenu>
            );
          }
        })}
      </SubMenu>
      <SubMenu
        title="Pessoa Física"
        onClick={(e) => {
          setForm({ ...form, clientName: e.key });
          setClientSelected(e.key);
          setErrors(removeError("clientName"));
        }}
      >
        {clientData?.map((item, index) => {
          if (item?.documentType === "CPF") {
            return (
              <ItemMenuSmall key={item?.name}>
                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: 15 }}
                >
                  <span className="title">{item?.name}</span>
                  <span className="client">Cliente Bovexo</span>
                </Row>
                <Row type="flex" justify="space-between" align="middle">
                  <span className="info">Responsável</span>
                  <span className="info-bold">{item?.name}</span>
                </Row>
                <Row type="flex" justify="space-between" align="middle">
                  <span className="info">CPF</span>
                  <span className="info-bold">{cpfMask(item?.document)}</span>
                </Row>
              </ItemMenuSmall>
            );
          }
        })}
      </SubMenu>
    </Menu>
  );
  /** Methods */
  function showOrHideModal() {
    if (validateForm()) {
      setModalVisible(!modalVisible);
    }
  }
  async function saveFarmSell() {
    setForm({
      ...form,
      boitelSaleScenarioResultId,
      estimatedBilling,
      amountAnimalsSold: amountAnimals,
      totalBilling,
      totalMarginBilling,
    });
    const body = {
      ...form,
      boitelSaleScenarioResultId,
      estimatedBilling,
      amountAnimalsSold: amountAnimals,
      totalBilling,
      totalMarginBilling,
      animals: animalsSelected,
    };
    setIsLoadingRequest(true);
    let isLastAnimalsForLot = [];
    try {
      const ids = animalsSelected.map((a) => a.lotId);
      const lotsIds = [...new Set(ids)];
      await Promise.all(
        lotsIds.map(async (idLot) => {
          const {
            data: { results: amountAnimals },
          } = await getLotStatistics({
            groupId: groupId,
            farmId: farmId,
            id: idLot,
            statistics: "AmountActiveAnimals",
          });
          const amountAnimalsForLot = animalsSelected.filter(
            (a) => a.lotId === idLot
          ).length;
          const lotName = animalsSelected.find(
            (a) => a.lotId === idLot
          ).lotName;
          if (amountAnimals === amountAnimalsForLot) {
            isLastAnimalsForLot.push(lotName);
          }
          return idLot;
        })
      );
      if (isLastAnimalsForLot.length > 0) {
        Modal.warning({
          centered: true,
          content: (
            <div>
              <p style={{ margin: 0 }}>
                <strong>Atenção! </strong>
                {`Ao confirmar a venda destes animais, estará finalizando o(s) Lote(s): `}
                <br />
                {isLastAnimalsForLot.join(", ")}
              </p>
              <p style={{ fontSize: 10, textAlign: "justify", marginTop: 10 }}>
                Obs.: Em caso de excluir essa venda posteriormente, este(s)
                Lote(s) ficarão pendentes, e precisarão ser alocados novamente
                em Piquetes.
              </p>
            </div>
          ),
        });
      }
      await storeBoitelSaleScenarioFarmSell({
        groupId,
        farmId,
        boitelSaleScenarioId,
        boitelSaleScenarioResultId,
        body,
      });
      notification.success({
        message: "Venda cadastrada com sucesso.",
      });
      history.push(
        `/admin/decisions/scenarios/sales/boitel/${boitelSaleScenarioId}/results/${boitelSaleScenarioResultId}`
      );
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Houve um erro ao cadastrar venda.",
      });
    } finally {
      setIsLoadingRequest(false);
    }
  }
  function removeError(field) {
    return errors.filter((e) => e !== field);
  }
  function validateForm() {
    let errorArray = [];
    if (form?.clientName == null) errorArray.push("clientName");
    if (
      form?.carcassHarnessing === null ||
      form?.carcassHarnessing == null ||
      form?.carcassHarnessing < 45 ||
      form?.carcassHarnessing > 60
    ) {
      errorArray.push("carcassHarnessing");
      notification.error({
        message:
          'O valor do campo "Ap. Carcaça" deve estar entre o intervalo de valores (45% e 60%)',
      });
    }
    if (form?.sellDate == null) errorArray.push("sellDate");
    if (form?.sellDate != null) {
      const cloneAnimalsData = animalsData.map((a) => a);
      const minAnimalLastDailyWeightDate = cloneAnimalsData.sort(
        (a, b) =>
          moment(a.lastDailyWeightDate).toDate() -
          moment(b.lastDailyWeightDate).toDate()
      )[0]?.lastDailyWeightDate;
      if (
        moment(form?.sellDate).isBefore(moment(minAnimalLastDailyWeightDate))
      ) {
        errorArray.push("sellDate");
        notification.error({
          message: `Atenção! Data de Saída informada inconsistente. A data deverá ser igual ou maior que a data da última pesagem registrada (${moment(
            minAnimalLastDailyWeightDate
          ).format(dateFormat)}).`,
        });
      }
    }
    if (form?.priceNegotiated == null) errorArray.push("priceNegotiated");
    if (form?.weightPerAnimal == null) errorArray.push("weightPerAnimal");
    setErrors(errorArray);
    return errorArray.length > 0 ? false : true;
  }
  function removeAnimalFromList(animal) {
    dispatch(
      BoitelSaleScenarioFarmSellActions.updateSelectAnimals([animal], "remove")
    );
  }
  /** Table methds */
  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        if (dataIndex === "lot.name") {
          return record.lot.name.includes(value);
        } else {
          return record[dataIndex].toString().includes(value);
        }
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select());
        }
      },
    };
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
  }
  function handleReset(clearFilters) {
    clearFilters();
  }

  function handleGoBack() {
    history.goBack();
    dispatch(BoitelSaleScenarioFarmSellActions.resetData());
  }

  return (
    <Container>
      <Row type="flex" align="middle" className="header">
        <Col
          xs={{ span: 1, offset: 0 }}
          sm={{ span: 1, offset: 0 }}
          md={{ span: 1, offset: 0 }}
          lg={{ span: 1, offset: 0 }}
          xl={{ span: 1, offset: 0 }}
        >
          <Icon type="arrow-left" className="iconBack" onClick={handleGoBack} />
        </Col>
        <Col
          xs={{ span: 23, offset: 0 }}
          sm={{ span: 23, offset: 0 }}
          md={{ span: 23, offset: 0 }}
          lg={{ span: 23, offset: 0 }}
          xl={{ span: 23, offset: 0 }}
        >
          <span className="message">
            Defina cliente, data de saída, faturamento final e os animais que
            pertencem a essa venda
          </span>
        </Col>
      </Row>
      {/* Form */}
      <form autoComplete="off">
        <Row type="flex">
          <Col span={16}>
            <div className="inputContainer">
              <div className="inputGroup clientName">
                <label className={errors.includes("clientName") ? "error" : ""}>
                  Cliente*
                </label>
                <Select
                  style={{ width: "100%" }}
                  value={form.clientName ?? undefined}
                  placeholder={translation.defaultSelectPlaceholder}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(value) => {
                    const clientSelected = clientData.find(
                      (c) => c.name === value
                    );
                    setForm({ ...form, clientName: clientSelected.name });
                    setClientSelected(clientSelected.name);
                    setErrors(removeError("clientName"));
                  }}
                >
                  {clientData &&
                    clientData.map((client) => (
                      <Select.Option key={client.id} value={client.name}>
                        {`${client.name} - ${
                          client.documentType === "CPF"
                            ? `CPF : ${cpfMask(client.document)}`
                            : `CNPJ : ${cnpjMask(client.document)}`
                        }`}
                      </Select.Option>
                    ))}
                </Select>
              </div>
              <div className="inputGroup">
                <label className={errors.includes("sellDate") ? "error" : ""}>
                  Data de saída*
                </label>
                <DatePicker
                  name="sellDate"
                  value={
                    form?.sellDate != null
                      ? moment(form?.sellDate, "YYYY-MM-DD")
                      : null
                  }
                  format={dateFormat}
                  placeholder={translation.defaultPlaceholder}
                  disabledDate={(date) =>
                    moment(date).isAfter(moment()) ? true : false
                  }
                  onChange={(date, dateString) => {
                    if (date <= moment() && date !== null) {
                      setForm({ ...form, sellDate: date });
                      setErrors(removeError("sellDate"));
                    }
                  }}
                />
              </div>
            </div>
            <div className="inputContainer">
              <div className="inputGroup">
                <label
                  className={errors.includes("priceNegotiated") ? "error" : ""}
                >
                  Valor/@Negociado*
                </label>
                <NumberFormat
                  name="priceNegotiated"
                  value={
                    form?.priceNegotiated !== null
                      ? form?.priceNegotiated
                      : null
                  }
                  customInput={Input}
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  addonBefore="R$"
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      priceNegotiated:
                        floatValue != null
                          ? floatValue > 1000
                            ? 1000
                            : floatValue
                          : null,
                    });
                    setErrors(removeError("priceNegotiated"));
                  }}
                  placeholder={translation.defaultPlaceholder}
                />
              </div>
              <div className="inputGroup">
                <label
                  className={errors.includes("weightPerAnimal") ? "error" : ""}
                >
                  Peso médio/animal*
                </label>
                <NumberFormat
                  name="weightPerAnimal"
                  customInput={Input}
                  value={
                    form?.weightPerAnimal !== null
                      ? form?.weightPerAnimal
                      : null
                  }
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  addonAfter="Kg"
                  placeholder={translation.defaultPlaceholder}
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      weightPerAnimal:
                        floatValue != null
                          ? floatValue > 10000
                            ? 10000
                            : floatValue
                          : null,
                    });
                    setErrors(removeError("weightPerAnimal"));
                  }}
                />
              </div>
              <div className="inputGroup">
                <label
                  className={
                    errors.includes("carcassHarnessing") ? "error" : ""
                  }
                >
                  Ap. carcaça*
                </label>
                <NumberFormat
                  name="carcassHarnessing"
                  customInput={Input}
                  value={
                    form?.carcassHarnessing !== null
                      ? form?.carcassHarnessing
                      : null
                  }
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  addonAfter="%"
                  placeholder={translation.defaultPlaceholder}
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      carcassHarnessing:
                        floatValue != null
                          ? floatValue > 100
                            ? 100
                            : floatValue
                          : null,
                    });
                    setErrors(removeError("carcassHarnessing"));
                  }}
                />
              </div>
            </div>
          </Col>
          <Col span={8} className="colWithBorderLeft">
            <div className="infoContainer">
              <div className="infoGroup">
                <span>Animais</span>
                <span>
                  <strong>{animalsSelected?.length || 0}</strong>
                </span>
              </div>
              <div className="infoGroup">
                <span>
                  Fat. total
                  <InfoTooltip
                    placement="top"
                    trigger="click"
                    title="Este valor corresponde ao peso médio dos animais, transformado em @, ou seja, dividido por 15. A quantidade de @ é multiplicada pelo valor da @ negociado com o comprador, multiplicado pelo aproveitamento de carcaça. Desta forma se tem o valor médio por animal e multiplicado pela quantidade de animais, chegando assim no valor de faturamento total."
                  />
                </span>
                <span>
                  <strong className="colored">{`${numberMask(
                    totalBilling || Number(0),
                    true
                  )}`}</strong>
                </span>
              </div>
              <div className="infoGroup">
                <span>Fat. estimado</span>
                <span>
                  <strong>{`${numberMask(
                    estimatedBilling || Number(0),
                    true
                  )}`}</strong>
                </span>
              </div>
            </div>
            <div className="infoContainer">
              <div className="infoGroup">
                <ButtonStandard
                  type="button"
                  width="141px"
                  padding="5px 10px"
                  background="#684E94"
                  disabled={animalsSelected.length === 0}
                  onClick={showOrHideModal}
                >
                  Salvar venda
                </ButtonStandard>
              </div>
            </div>
          </Col>
        </Row>
      </form>
      <Divider dashed />
      {/* Animals Table */}
      <Row type="flex">
        <Col span={24}>
          <Table
            rowKey="id"
            loading={isLoadingAnimals}
            dataSource={animalsData ? animalsData : []}
            scroll={{
              x: true,
            }}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 10,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
            }}
          >
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns
                  .animalHandlingNumber
              }
              dataIndex="animalHandlingNumber"
              sorter={(first, second) => {
                return first.animalHandlingNumber.localeCompare(
                  second.animalHandlingNumber,
                  "pt-BR",
                  {
                    numeric: false,
                    ignorePunctuation: true,
                  }
                );
              }}
              key="animalHandlingNumber"
              width={180}
              align="left"
              {...getColumnSearchProps("animalHandlingNumber")}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns.sellDate
              }
              dataIndex="sellDate"
              sorter={(first, second) => {
                return first.sellDate.localeCompare(second.sellDate, "pt-BR", {
                  numeric: true,
                  ignorePunctuation: true,
                });
              }}
              key="sellDate"
              width={180}
              align="left"
              render={(text, record) => (
                <span>
                  {record.sellDate !== null
                    ? moment(record.sellDate).format(dateFormat)
                    : "-"}
                </span>
              )}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns.weight
              }
              dataIndex="weight"
              key="weight"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.weight - second.weight;
              }}
              {...getColumnSearchProps("weight")}
              render={(text, record) => (
                <span>
                  {record.weight !== null
                    ? `${numberMask(record.weight, false)} kg`
                    : "-"}
                </span>
              )}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns
                  .lastDailyWeight
              }
              dataIndex="lastDailyWeight"
              key="lastDailyWeight"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.lastDailyWeight - second.lastDailyWeight;
              }}
              {...getColumnSearchProps("lastDailyWeight")}
              render={(text, record) => (
                <span>
                  {record.lastDailyWeight !== null
                    ? `${numberMask(record.lastDailyWeight, false)} kg`
                    : "-"}
                </span>
              )}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns
                  .averageWeightGain
              }
              dataIndex="averageWeightGain"
              key="averageWeightGain"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.averageWeightGain - second.averageWeightGain;
              }}
              render={(text, record) => (
                <span>
                  {record.averageWeightGain !== null
                    ? `${numberMask(record.averageWeightGain, false)} kg/dia`
                    : "-"}
                </span>
              )}
              {...getColumnSearchProps("averageWeightGain")}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns
                  .aggregateArroba
              }
              dataIndex="aggregateArroba"
              key="aggregateArroba"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.aggregateArroba - second.aggregateArroba;
              }}
              render={(text, record) => (
                <span>
                  {record.aggregateArroba !== null
                    ? `${numberMask(record.aggregateArroba, false)} @`
                    : "-"}
                </span>
              )}
              {...getColumnSearchProps("aggregateArroba")}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns.saleIncome
              }
              dataIndex="saleIncome"
              key="saleIncome"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.saleIncome - second.saleIncome;
              }}
              render={(text, record) => (
                <span>
                  {record.saleIncome !== null
                    ? `${numberMask(record.saleIncome, true)}`
                    : "-"}
                </span>
              )}
              {...getColumnSearchProps("saleIncome")}
            />
            <Table.Column
              title={translation.boitelSaleScenario.details.table.columns.cost}
              dataIndex="cost"
              key="cost"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.cost - second.cost;
              }}
              render={(text, record) => (
                <span>
                  {record.cost !== null
                    ? `${numberMask(record.cost, true)}`
                    : "-"}
                </span>
              )}
              {...getColumnSearchProps("cost")}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns
                  .aggregateMargin
              }
              dataIndex="aggregateMargin"
              key="aggregateMargin"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.aggregateMargin - second.aggregateMargin;
              }}
              render={(text, record) => (
                <span>
                  {record.aggregateMargin !== null
                    ? `${numberMask(record.aggregateMargin, true)}`
                    : "-"}
                </span>
              )}
              {...getColumnSearchProps("aggregateMargin")}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns.margin
              }
              dataIndex="marginPercentage"
              key="marginPercentage"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.marginPercentage - second.marginPercentage;
              }}
              render={(text, record) => (
                <span>
                  {record.marginPercentage !== null
                    ? `${numberMask(record.marginPercentage * 100, false)} %`
                    : "-"}
                </span>
              )}
              {...getColumnSearchProps("margin")}
            />
            <Table.Column
              align="left"
              render={(text, record) => (
                <button
                  className="buttonTrash"
                  onClick={() => removeAnimalFromList(record)}
                >
                  <TrashIcon />
                </button>
              )}
            />
          </Table>
        </Col>
      </Row>
      <ConfirmActionModal
        show={modalVisible}
        loading={isLoadingRequest}
        cancelAction={showOrHideModal}
        confirmAction={saveFarmSell}
        title="Registrar venda"
        message="Ao confirmar, os animais serão marcados como 'Vendido'."
        icon={<SalePercentageIcon />}
      />
    </Container>
  );
};

export default NewBoitelSaleScenarioFarmSell;
