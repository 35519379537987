import apiOptimization from "../config/api_optimization";
import getHeaders from "./getHeaders";

export function indexSaleScenarioResultFarmSell(payload) {
  const {
    groupId,
    farmId,
    saleScenarioId,
    saleScenarioResultId,
    page,
    sorter,
    filters,
    size,
  } = payload;

  let sortS = "";

  if (sorter && Object.entries(sorter).length > 0) {
    const { field, order } = sorter;

    const orderS = order && order === "descend" ? "DESC" : "ASC";

    sortS = `&sort=${field}&direction=${orderS}`;
  }

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/results/${saleScenarioResultId}/farms/sales?
    ${page ? `&page=${page - 1}` : ""}
    ${size && `&size=${size}`}
    ${sortS}
    ${filters !== "" && filters ? `&search=${filters}` : ""}`,
    getHeaders(false, null)
  );
}

export function storeSaleScenarioResultFarmSell(payload) {
  const { groupId, farmId, saleScenarioId, saleScenarioResultId, body } =
    payload;

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/results/${saleScenarioResultId}/farms/sales`,
    body,
    getHeaders(false, null)
  );
}

export function updateSaleScenarioResultFarmSell(payload) {
  const { groupId, farmId, saleScenarioId, saleScenarioResultId, id, body } =
    payload;

  return apiOptimization.patch(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/results/${saleScenarioResultId}/farms/sales/${id}`,
    body,
    getHeaders(false, null)
  );
}

export function showSaleScenarioResultFarmSell(payload) {
  const { groupId, farmId, saleScenarioId, saleScenarioResultId, id } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/results/${saleScenarioResultId}/farms/sales/${id}`,
    getHeaders(false, null)
  );
}

export function showSaleScenarioResultFarmSellAnimals(payload) {
  const { groupId, farmId, saleScenarioId, saleScenarioFarmSellId } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/farms/sales/${saleScenarioFarmSellId}/animals`,
    getHeaders(false, null)
  );
}

export function destroySaleScenarioResultFarmSell(payload) {
  const { groupId, farmId, saleScenarioId, saleScenarioResultId, id } = payload;

  return apiOptimization.delete(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/results/${saleScenarioResultId}/farms/sales/${id}`,
    getHeaders(false, null)
  );
}

export function getResponseClosedSaleReport(payload) {
  const { groupId, farmId, body } = payload;

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/results/farms/sales/report`,
    body,
    getHeaders(false, null)
  );
}

export function downloadClosedSaleReport(payload) {
  const { groupId, farmId, body } = payload;

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/results/farms/sales/report/download/pdf`,
    body,
    getHeaders(false, null, true)
  );
}
