import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
/** Redux libs */
import { useSelector } from "react-redux";
/** Components */
import { CardCustom, Title } from "./styles";
import {
  Row,
  Col,
  Table,
  Menu,
  Icon,
  Dropdown,
  notification,
  Tooltip,
  Button,
  Input,
} from "antd";
import ButtonStandard from "../../components/utils/button";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import TagStatus from "../../components/utils/tagStatus";
import MenuIcon from "../../components/utils/table/icons/menu";
import DuplicateModal from "./duplicateModal";
/** Services */
import {
  downloadComparePurchaseScenarioReport,
  indexPurchaseScenario,
  reprocessPurchaseScenario,
  inactivatePurchaseScenario,
} from "../../services/purchaseScenarioService";
import moment from "moment";
import { numberMask } from "../../services/helpersMethodsService";
import AlertIcon from "../../components/drawers/alert/icons/alert";
import CompareButton from "../../components/utils/compareButton";

const ListPurchaseScenario = () => {
  const [data, setData] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [nameSelected, setNameSelected] = useState(null);
  const [purchaseScenarioIdSelected, setPurchaseScenarioIdSelected] =
    useState(null);
  const [tableChange, setTableChange] = useState({
    page: 0,
    size: 10,
    sorter: null,
    filters: "",
  });
  const [selectedResults, setSelectedResults] = useState([]);
  const rowSelection = {
    selectedRowKeys: selectedResults,
    getCheckboxProps: (record) => ({
      disabled:
        !selectedResults.includes(record.key) && selectedResults.length >= 5,
    }),
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      if (selected) {
        setSelectedResults((old) => [...old, record.key]);
      } else {
        setSelectedResults((old) => old.filter((id) => id !== record.key));
      }
    },
    onSelectAll: (record, selected, selectedRows, nativeEvent) => {
      // Get just the ids
      const keys = selectedRows.map((sr) => sr.key);
      // Get all the selectedResults unless the 'keys'
      const othersSelectedResultsKeys = selectedResults.filter(
        (sAK) => !keys.includes(sAK)
      );

      if (selected.length > 0) {
        const sliceKeys = keys.slice(
          0,
          othersSelectedResultsKeys.length > 0
            ? 5 - othersSelectedResultsKeys.length
            : keys.length > 5
            ? 5
            : keys.length
        );
        setSelectedResults([...othersSelectedResultsKeys, ...sliceKeys]);
      } else {
        setSelectedResults(othersSelectedResultsKeys);
      }
    },
  };

  /** Redux libs */
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  /** Effects */
  useEffect(() => {
    async function fetch() {
      setLoadingTable(true);
      try {
        const {
          data: { results: scenarios },
        } = await indexPurchaseScenario({
          groupId,
          farmId,
        });
        setData(scenarios);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingTable(false);
      }
    }
    fetch();
  }, [groupId, farmId]);

  /** Methods */
  function expandedRows(record, index, indent, expanded) {
    let columnData = [];
    const columns = [
      {
        title: "Data de Processamento",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text, record) => (
          <span>
            {record.createdAt !== null
              ? `${moment(record.createdAt).format("DD/MM/YYYY HH:mm")}`
              : null}
          </span>
        ),
      },
      {
        title: "Período Inicial",
        dataIndex: "startDate",
        key: "startDate",
        render: (text, record) => (
          <span>
            {record.startDate !== null
              ? `${moment(record.startDate).format("DD/MM/YYYY")}`
              : null}
          </span>
        ),
      },
      {
        title: "Período Final",
        dataIndex: "endDate",
        key: "endDate",
        render: (text, record) => (
          <span>
            {record.endDate !== null
              ? `${moment(record.endDate).format("DD/MM/YYYY")}`
              : null}
          </span>
        ),
      },
    ];

    const { results: scenarioResults } = record;

    if (scenarioResults.length === 0) return false;

    scenarioResults.forEach((scenario, i) => {
      columnData.push({
        key: scenario.id,
        startDate: scenario.startDate,
        endDate: scenario.endDate,
        createdAt: scenario.createdAt,
      });
    });

    return (
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={columnData}
        scroll={{
          x: 1200,
          y: 758,
        }}
        pagination={false}
        style={{ padding: "2" }}
      />
    );
  }

  async function fetchData(page = 0, sorter = {}, filters = "", size = 10) {
    setLoadingTable(true);
    try {
      const {
        data: { results },
      } = await indexPurchaseScenario({
        groupId,
        farmId,
        page,
        sorter,
        filters,
        size,
      });
      setData(results);
    } catch (error) {
      setLoadingTable(false);
    } finally {
      setLoadingTable(false);
    }
  }

  function menu(id, name, status) {
    if (status === "Inactive") {
      return (
        <Menu>
          <Menu.Item key="0">
            <button onClick={() => handleReprocess(id)}>Reativar</button>
          </Menu.Item>
        </Menu>
      )
    } else {
      return (
        <Menu>
          <Menu.Item key="0">
            <Link to={`/admin/decisions/scenarios/purchases/${id}`}>
              Visualizar
            </Link>
          </Menu.Item>
          {status !== "Analyzing" && (
              <Menu.Item key="1">
                <Link to={`/admin/decisions/scenarios/purchases/${id}/edit`}>
                  Editar
                </Link>
              </Menu.Item>
            )}
          <Menu.Item key="2">
            <button onClick={() => handleReprocess(id)}>Reprocessar</button>
          </Menu.Item>
          <Menu.Item key="3">
            <button onClick={() => handleDuplicate(id, name)}>Duplicar</button>
          </Menu.Item>
          {status === "Processed" && (
            <Menu.Item key="4">
              <button onClick={() => handleInactivate(id)}>Inativar</button>
            </Menu.Item>
          )}
        </Menu>
      );
    }
  }

  function handleDuplicate(id, name) {
    setPurchaseScenarioIdSelected(id);
    setNameSelected(name);
    setModalVisible(true);
  }

  function handleCloseDuplicateModal() {
    setModalVisible(false);
    setPurchaseScenarioIdSelected(null);
  }

  async function handleReprocess(id) {
    setLoadingTable(true);
    try {
      await reprocessPurchaseScenario({
        groupId,
        farmId,
        id,
      });
      notification.success({
        message:
          "O reprocessmento do cenário foi disparado. Você será notificado quando ele terminar.",
      });
      fetchData();
    } catch (error) {
      setLoadingTable(false);
      notification.error({
        message:
          "Ocorreu algum erro no reprocessmento do cenário foi disparado. Contate o administrador do sistema.",
      });
    } finally {
      setLoadingTable(false);
    }
  }

  async function handleInactivate(id) {
    setLoadingTable(true);
    try {
      await inactivatePurchaseScenario({
        groupId,
        farmId,
        id,
      });
      notification.success({
        message:
          "O cenário foi inativado.",
      });
      fetchData();
    } catch (error) {
      setLoadingTable(false);
      notification.error({
        message:
          "Ocorreu algum erro durante a inativação do cenário.",
      });
    } finally {
      setLoadingTable(false);
    }
  }

  async function handleTableChange(pagination, filters, sorter) {
    let search = "";

    if (Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v.length > 0) {
          if (k === "status") {
            search =
              search === ""
                ? `${k} in (${v.map((o) => (o = `'${o}'`)).join(", ")})`
                : `${search};${k} in (${v
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`;
          } else {
            search =
              search === ""
                ? `upper(${k}) like upper('%25${v}%25')`
                : `${search};upper(${k}) like upper('%25${v}%25')`;
          }
        }
      });
    } else {
      search = "";
    }

    setTableChange({
      page: pagination.current,
      size: pagination.pageSize,
      sorter,
      filters: search,
    });

    fetchData(pagination.current, sorter, search, pagination.pageSize);
  }

  function handleReloadTable() {
    const { page, size, sorter, filters } = tableChange;
    fetchData(page, sorter, filters, size);
  }

  async function handleCompareScenarios() {
    setLoadingTable(true);
    try {
      const body = {
        selectedResults,
      };
      const { data } = await downloadComparePurchaseScenarioReport({
        groupId,
        farmId,
        body,
      });
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      let link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "comparativo_cenario_compra.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      const errorData = JSON.parse(await error.response.data.text());
      if (errorData.code === 5017) {
        notification.error({
          message: "Relátorio não gerado.",
          description: (
            <div>
              <span>
                Algum(ns) resultados selecionados não possuem "favoritos".
              </span>
              <br />
              <span>Cenários não atendido(s):</span>
              <ul>
                {errorData.errors.map((e, index) => (
                  <li key={index}>
                    <strong>{e.name}</strong>
                  </li>
                ))}
              </ul>
            </div>
          ),
        });
      } else {
        notification.error({
          message: "Não foi possível comparar cenários.",
        });
      }
    } finally {
      setLoadingTable(false);
    }
  }

  return (
    <CardCustom>
      {/* Header */}
      <Row type="flex" justify="space-between" className="rowHeader">
        <Col span={8}>
          <Title>Cenários de compra</Title>
        </Col>
        <Col span={16}>
          <Row type="flex" justify="end" align="middle" gutter={8}>
            <ButtonStandard
              buttonType="typeWithoutBackground"
              onClick={handleReloadTable}
            >
              <Icon type="reload" />
            </ButtonStandard>
            <Tooltip
              title="Somente é possível comparar 5 resultados por vez."
              trigger="hover"
            >
              <div>
                <CompareButton
                  title="Comparar cenários"
                  onClick={handleCompareScenarios}
                  disable={selectedResults.length <= 0 || loadingTable}
                />{" "}
              </div>
            </Tooltip>
            <Link to="/admin/decisions/scenarios/purchases/new">
              <ButtonStandard buttonType="type8">Novo cenário</ButtonStandard>
            </Link>
          </Row>
        </Col>
      </Row>
      {/* Table */}
      <Row type="flex" justify="space-between">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            rowKey="id"
            expandedRowRender={expandedRows}
            loading={loadingTable}
            size="small"
            dataSource={data != null ? data.content : []}
            scroll={{
              x: 1200,
              y: 758,
            }}
            pagination={{
              total: data != null ? data.totalElements : 0,
              size: data != null ? data.size : 0,
              current: data != null ? data.number + 1 : 0,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
              hideOnSinglePage:
                data !== null && Object.entries(data).length !== 0
                  ? data.totalElements > 10
                    ? false
                    : true
                  : true,
            }}
            style={{ width: "100%", margin: "0" }}
            onChange={handleTableChange}
          >
            <Table.Column
              title="Cenário"
              dataIndex="name"
              sorter
              key="name"
              align="left"
            />
            <Table.Column
              title="Status"
              dataIndex="status"
              key="status"
              align="center"
              sorter
              sortDirections={["descend", "ascend"]}
              filters={[
                {
                  text: "Analisando",
                  value: "A",
                },
                {
                  text: "Processado",
                  value: "P",
                },
                {
                  text: "Error",
                  value: "E",
                },
                {
                  text: "Inativo",
                  value: "X', 'IN",
                },
              ]}
              filterMultiple
              render={(status, record) =>
                status === "Analyzing" ? (
                  <TagStatus
                    background="#FFF6EE"
                    borderColor="#FE8D2A"
                    color="#FE8D2A"
                  >
                    Analizando
                  </TagStatus>
                ) : status === "Processed" ? (
                  <TagStatus
                    background="#EBF7FF"
                    borderColor="#4A85AE"
                    color="#4A85AE"
                  >
                    Processado
                  </TagStatus>
                ) : status === "Error" ? (
                  <Tooltip
                    title={
                      record?.errorCode
                        ? translation.purchaseScenario.error[record?.errorCode]
                        : "Houve um erro ao processar o cenário. Contate o suporte."
                    }
                  >
                    <Icon
                      type="exclamation-circle"
                      className="iconError"
                      theme="filled"
                    />
                  </Tooltip>
                ) : status === "Expired" ? (
                  <TagStatus
                    background="#FBC7C7"
                    borderColor="#D44C4C"
                    color="#D44C4C"
                  >
                    Vencido
                  </TagStatus>
                ) : status === "Canceled" ? (
                  <TagStatus
                    background="#F5F5F5"
                    borderColor="#A5A5A5"
                    color="#A5A5A5"
                  >
                    Cancelado
                  </TagStatus>
                ) : (
                  <TagStatus
                    background="#FBC7C7"
                    borderColor="#D44C4C"
                    color="#D44C4C"
                  >
                    {translation.status.inactive}
                  </TagStatus>
                )
              }
            />
            <Table.Column
              title="Vencimento"
              dataIndex="expiration_date"
              sorter
              key="expirationDate"
              align="left"
              render={(text, record) =>
                record.expirationDate != null ? (
                  <span>
                    {`${
                      record.expirationDate !== null
                        ? moment(record.expirationDate).format("DD/MM/YYYY")
                        : ""
                    }`}
                  </span>
                ) : null
              }
            />
            <Table.Column
              title="Raça"
              dataIndex="breed_name"
              sorter
              key="breedName"
              align="left"
              render={(text, record) => <span>{record.breedName}</span>}
            />
            <Table.Column
              title="Peso assintótico"
              dataIndex="asymptotic_weight"
              sorter
              key="asymptoticWeight"
              align="left"
              render={(text, record) =>
                record.breedId != null ? (
                  <span>
                    {numberMask(record.asymptoticWeight || 0, false)} kg
                  </span>
                ) : null
              }
            />
            <Table.Column
              title="Margem esperada"
              dataIndex="margin_profit"
              sorter
              key="marginProfit"
              align="left"
              render={(text, record) =>
                record.marginProfit != null ? (
                  <span>{numberMask(record.marginProfit || 0, false)} %</span>
                ) : null
              }
            />

            <Table.Column
              title=""
              width={100}
              dataIndex="alertSupportCapacity"
              key="alertSupportCapacity"
              align="center"
              render={(text, record) =>
                record.alertSupportCapacity === true ? (
                  <Tooltip
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "0 5px",
                        }}
                      >
                        <p>
                          Atenção! Existem uma ou mais categorias, que podem
                          apresentar falta de capacidade de suporte.
                        </p>
                      </div>
                    }
                  >
                    <div>
                      <AlertIcon />
                    </div>
                  </Tooltip>
                ) : null
              }
            />

            <Table.Column
              align="left"
              render={(text, record) => (
                <Dropdown
                  overlay={menu(record.id, record.name, record.status)}
                  trigger={["click"]}
                  key={record.id}
                >
                  <MenuContainer>
                    <MenuIcon />
                  </MenuContainer>
                </Dropdown>
              )}
            />
          </Table>
        </Col>
      </Row>
      {/* Modal */}
      <DuplicateModal
        style={{ padding: "24px 24px 0px 24px" }}
        id={purchaseScenarioIdSelected}
        name={nameSelected}
        visible={modalVisible}
        closeModal={handleCloseDuplicateModal}
      />
    </CardCustom>
  );
};

export default withRouter(ListPurchaseScenario);
