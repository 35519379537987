import styled from "styled-components";

export const ConversionStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 0.5rem;

  i {
    font-size: 1.25rem;
  }

  span {
    font-size: 1rem;
    &.success {
      color: #33c162;
    }
    &.error {
      color: #d44c4c;
    }
  }
`;
