import apiFinancial from "../config/api_financial";
import getHeaders from "./getHeaders";

export function indexAll(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/cost/centers`,
    getHeaders(false, signal)
  );
}

export function findById(payload) {
  const { groupId, farmId, id } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/cost/centers/${id}`,
    getHeaders(false, null)
  );
}

export function indexAllWithItems(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/cost/centers/with/items`,
    getHeaders(false, signal)
  );
}

export function findApportionmentInfo(payload) {
  const { groupId, farmId, id } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/cost/centers/${id}/apportionment`,
    getHeaders(false, null)
  );
}

export function saveApportionmentInfo(payload) {
  const { groupId, farmId, id, body } = payload;

  return apiFinancial.post(
    `/bovexo/${groupId}/farms/${farmId}/cost/centers/${id}/apportionment`,
    body,
    getHeaders(false, null)
  );
}

export function save(payload) {
  const { groupId, farmId, body } = payload;

  return apiFinancial.post(`/bovexo/${groupId}/farms/${farmId}/cost/centers`,
    body,
    getHeaders(false, null)
  );
}

export function update(payload) {
  const { groupId, farmId, id, body } = payload;

  return apiFinancial.patch(`/bovexo/${groupId}/farms/${farmId}/cost/centers/${id}`,
    body,
    getHeaders(false, null)
  );
}

export function Inactivate(payload) {
  const { groupId, farmId, id } = payload;

  return apiFinancial.put(`/bovexo/${groupId}/farms/${farmId}/cost/centers/${id}/inactivate`,
    null,
    getHeaders(false, null)
  );
}

export function Activate(payload) {
  const { groupId, farmId, id } = payload;

  return apiFinancial.put(`/bovexo/${groupId}/farms/${farmId}/cost/centers/${id}/activate`,
    null,
    getHeaders(false, null)
  );
}

export function deleteById(payload) {
  const { groupId, farmId, id } = payload;

  return apiFinancial.delete(`/bovexo/${groupId}/farms/${farmId}/cost/centers/${id}`,
    getHeaders(false, null)
  );
}

// export function findApportionmentValuesById(payload) {
//   const { groupId, farmId, id } = payload;

//   return apiFinancial.get(
//     `/bovexo/${groupId}/farms/${farmId}/result/centers/${id}/apportionment/values`,
//     getHeaders(false, null)
//   );
// }
// export function updateApportionments(payload) {
//   const { groupId, farmId, id, body } = payload;

//   return apiFinancial.post(`/bovexo/${groupId}/farms/${farmId}/result/centers/${id}/apportionment/values`,
//     body,
//     getHeaders(false, null)
//   );
// }