import React, { createContext, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

//Libs

// Services

export const KpiContext = createContext({});

export const KpiContextProvider = ({ children }) => {
  const {
    app: {
      translation,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
  } = useSelector((state) => state);

  const [kpiDataList, setKpiDataList] = useState([]);
  const [selectedKpi, setSelectedKpi] = useState(null);
  const [kpiHarvest, setKpiHarvest] = useState(null);
  const [productionSystem, setProductionSystem] = useState(null);
  const [productionSubSystem, setProductionSubSystem] = useState(null);

  const updateKpiHarvest = useCallback((harvest) => {
    setKpiHarvest(harvest);
  }, []);

  const updateSystemAndSubSystem = useCallback((system, subSystem) => {
    setProductionSystem(system);
    setProductionSubSystem(subSystem);
  }, []);

  const updateKpiDataList = useCallback((newKpiDataList) => {
    setKpiDataList(newKpiDataList);
  }, []);

  const updateKpiSelected = useCallback(
    (kpiName) => {
      if (!kpiName) {
        setSelectedKpi(null);
        const newList = [...kpiDataList];
        newList.forEach((kpi) => {
          kpi.isSelected = false;
        });
        setKpiDataList(newList);
        return;
      }
      if (Array.isArray(kpiDataList) && kpiDataList.length > 0) {
        const newList = [...kpiDataList];
        newList.forEach((kpi) => {
          if (kpi.name === kpiName) {
            kpi.isSelected = true;
            setSelectedKpi(kpi.data);
          } else {
            kpi.isSelected = false;
          }
        });
        setKpiDataList(newList);
      }
    },
    [kpiDataList]
  );

  // Kpi Lotation
  const kpiLotation = useMemo(() => {
    if (Array.isArray(kpiDataList)) {
      return kpiDataList.find((kpi) => kpi.name === "LOTACAO");
    }
    return null;
  }, [kpiDataList]);

  // Kpi Production
  const kpiProduction = useMemo(() => {
    if (Array.isArray(kpiDataList)) {
      return kpiDataList.find((kpi) => kpi.name === "PRODUCAO");
    }
    return null;
  }, [kpiDataList]);

  // Kpi Usufruct
  const kpiUsufruct = useMemo(() => {
    if (Array.isArray(kpiDataList)) {
      return kpiDataList.find((kpi) => kpi.name === "DESFRUTE");
    }
    return null;
  }, [kpiDataList]);

  // Kpi GDP
  const kpiGDP = useMemo(() => {
    if (Array.isArray(kpiDataList)) {
      return kpiDataList.find((kpi) => kpi.name === "GDP");
    }
    return null;
  }, [kpiDataList]);

  // Kpi COST
  const kpiCost = useMemo(() => {
    if (Array.isArray(kpiDataList)) {
      return kpiDataList.find((kpi) => kpi.name === "CUSTO");
    }
    return null;
  }, [kpiDataList]);

  // Kpi MARGIN
  const kpiMargin = useMemo(() => {
    if (Array.isArray(kpiDataList)) {
      return kpiDataList.find((kpi) => kpi.name === "MARGEM");
    }
    return null;
  }, [kpiDataList]);

  // Kpi CARBON FOOTPRINT
  const kpiCarbonFootprint = useMemo(() => {
    if (Array.isArray(kpiDataList)) {
      return kpiDataList.find((kpi) => kpi.name === "PEGADA_CARBONICA");
    }
    return null;
  }, [kpiDataList]);

  // Kpi DESEMBOLSO_CAB_MES
  const kpiDisbursement = useMemo(() => {
    if (Array.isArray(kpiDataList)) {
      return kpiDataList.find((kpi) => kpi.name === "DESEMBOLSO_CAB_MES");
    }
    return null;
  }, [kpiDataList]);

  // Kpi VALOR_DO_ATIVO
  const kpiAssetValue = useMemo(() => {
    if (Array.isArray(kpiDataList)) {
      return kpiDataList.find((kpi) => kpi.name === "VALOR_DO_ATIVO");
    }
    return null;
  }, [kpiDataList]);

  // Kpi MARGEM_INCREMENTAL
  const kpiIncrementalMargin = useMemo(() => {
    if (Array.isArray(kpiDataList)) {
      return kpiDataList.find((kpi) => kpi.name === "MARGEM_INCREMENTAL");
    }
    return null;
  }, [kpiDataList]);

  // Kpi Wean Calf
  const kpiWeanCalf = useMemo(() => {
    if (Array.isArray(kpiDataList)) {
      return kpiDataList.find((kpi) => kpi.name === "Kg Bez. Desm / Kg Vaca");
    }
    return null;
  }, [kpiDataList]);

  // Kpi IEP
  const kpiIEP = useMemo(() => {
    if (Array.isArray(kpiDataList)) {
      return kpiDataList.find((kpi) => kpi.name === "IEP");
    }
    return null;
  }, [kpiDataList]);

  //Kpi EFICIENCIA REPRODUTIVA
  const kpiReproductiveEfficiency = useMemo(() => {
    if (Array.isArray(kpiDataList)) {
      return kpiDataList.find((kpi) => kpi.name === "EFICIENCIA REPRODUTIVA");
    }
    return null;
  }, [kpiDataList]);

  //Kpi CUSTO BEZERRO DESMAMADO
  const kpiWeanCalfCost = useMemo(() => {
    if (Array.isArray(kpiDataList)) {
      return kpiDataList.find((kpi) => kpi.name === "CUSTO BEZERRO DESMAMADO");
    }
    return null;
  }, [kpiDataList]);

  //Kpi VACAS DESCARTADAS
  const kpiDiscardedCows = useMemo(() => {
    if (Array.isArray(kpiDataList)) {
      return kpiDataList.find((kpi) => kpi.name === "VACAS DESCARTADAS");
    }
    return null;
  }, [kpiDataList]);

  return (
    <KpiContext.Provider
      value={{
        translation,
        groupId,
        farmId,
        updateKpiHarvest,
        updateKpiDataList,
        updateKpiSelected,
        updateSystemAndSubSystem,
        kpiHarvest,
        productionSystem,
        productionSubSystem,
        kpiLotation,
        kpiProduction,
        kpiUsufruct,
        kpiGDP,
        kpiCost,
        kpiMargin,
        kpiCarbonFootprint,
        kpiDisbursement,
        kpiAssetValue,
        kpiIncrementalMargin,
        kpiWeanCalf,
        kpiIEP,
        kpiReproductiveEfficiency,
        kpiWeanCalfCost,
        kpiDiscardedCows,
        selectedKpi,
      }}
    >
      {children}
    </KpiContext.Provider>
  );
};
