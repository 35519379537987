import styled from "styled-components";
import { Modal, Select, Divider } from "antd";

export const CustomModal = styled(Modal)`
  position: relative !important;
  &.ant-modal {
    padding-bottom: 0px !important;
    border-radius: 5px !important;
  }
  .ant-modal-body {
    padding: 0px !important;
  }
`;

export const Container = styled.div`
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-between;

  .ant-row-flex {
    width: 100% !important;
  }

  .ant-row-flex:last-child {
    margin-top: 5px !important;
  }

  button:not(:last-child) {
    margin-right: 14px;
  }

  label.formLabel {
    font-family: Asap;
    color: #4b4b4b;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;

    &.disabled {
      font-weight: bold;
      font-size: 14px;
      margin-left: 0px;
      color: #c4c4c4;
    }
    &.error {
      color: #d44c4c;
    }
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    margin-bottom: 7px;
    margin-top: 19px;
    &.error {
      color: #d44c4c;
    }
  }

  .ant-inputs {
    margin-top: 13px;
  }

  .ant-select {
    margin-top: 13px;
    margin-bottom: 13px;
  }

  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 50px !important;
  }

  .ant-input-affix-wrapper .ant-input-prefix,
  .ant-input-affix-wrapper .ant-input-suffix {
    top: 60% !important;
    font-weight: bold !important;
  }
`;

export const Header = styled.div`
  margin-bottom: 9px;
`;

export const Title = styled.span`
  padding: 40px 20px 0 30px;
  font-family: "Asap";
  font-size: 17px;
  text-align: left;
`;

export const SubTitle = styled.span`
  padding: 5px 20px 0 30px;
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  line-height: 17px;
`;

export const ListTitle = styled.span`
  display: flex;
  justify-content: space-between;
  padding: 36px 10px 0px 30px;
  font-family: Asap;
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  line-height: 17px;

  b {
    padding-left: 10px;
  }

  span {
    padding-left: 27px;
  }

  svg {
    height: 18px !important;
    width: auto !important;
    cursor: pointer;
  }

  .pad {
    padding-right: 35px;
  }

`;

export const CustomSelect = styled(Select)`
  &.ant-select.ant-select-enabled {
    .ant-select-selection {
      border: 1px solid #684e94 !important;
      color: #684e94 !important;
    }
    .ant-select-arrow {
      color: #684e94 !important;
    }
    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
      display: block !important;
      color: #684e94 !important;
    }
  }
`;

export const CustomDivider = styled(Divider)`
  margin: 10px 0 !important;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 10px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;

export const LeftContent = styled.div`
  height: 521px;
  width: 288px;
  background: #A9C133;
  display: flex;
  flex-direction: column;
  color: #fff;
  border-radius: 5px 0 0 5px;

  b {
    padding-left: 10px;
  }

`;

export const RightContent = styled.div`
  height: 521px;
  width: 418px;
  display: flex;
  flex-direction: column;
  color: #222;
  border-radius: 0 5px 5px 0;

  .pad-2 {
    margin-left: 15px;
  }

  .edit {
    cursor: pointer;
  }

`;

export const CardProblem = styled.div`
  height: 60px;
  width: 372px;
  margin-bottom: 20px;
  padding: 6px 8px;
  display: flex;
  flex-direction: row;
  color: #FE8D2A;
  border: 2px dashed #FE8D2A;
  border-radius: 5px;
`;
