import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as DietStrategyActions } from "../../../../store/ducks/dietStrategy";
import moment from "moment";
// Components
import { Row, Spin, Col, Tooltip, Icon } from "antd";
import { Container, CustomDivider, CardDiet } from "./styles";
import DrawerDisplayDietStrategyChart from "./chart";
import DrawerDisplayDietStrategyPeriod from "./dietStrategyPeriod";
import RainIcon from "../../../../pages/generalParameter/weatherSeasonsParameter/display/icons/rain";
import SunIcon from "../../../../pages/generalParameter/weatherSeasonsParameter/display/icons/sun";
import TransitionIcon from "../../../../pages/generalParameter/weatherSeasonsParameter/display/icons/transition";
import ButtonStandard from "../../../utils/button";
import AlertIcon from "../../alert/icons/alert";
// Services
import { getWeatherSeason } from "../../../../services/generalParameterService";
import {
  dryColor,
  transitionColor,
  waterColor,
} from "../../../../utils/colorsHandler";
import { getBreedIndexActive } from "../../../../services/breedService";
import { numberMask } from "../../../../services/helpersMethodsService";

const DrawerDisplayDietStrategy = () => {
  // Variables
  const [breeds, setBreeds] = useState([]);
  const [dietStrategy, setDietStrategy] = useState(null);
  const [dietStrategyDietPeriod, setDietStrategyDietPeriod] = useState(null);
  const [drawerScenarioPeriodVisible, setDrawerScenarioPeriodVisible] =
    useState(false);
  const [
    drawerDetailsDietStrategyChartVisible,
    setDrawerDetailsDietStrategyChartVisible,
  ] = useState(false);
  const [currentPeriodIndex, setCurrentPeriodIndex] = useState(null);
  const [nextPeriodIndex, setNextPeriodIndex] = useState(null);
  const [previousPeriodIndex, setPreviousPeriodIndex] = useState(null);
  const [dietPeriodCurve, setDietPeriodCurve] = useState(null);
  const [gdpCurve, setGdpCurve] = useState(null);
  const [stripLines, setStripLines] = useState([]);
  const [maximumDate, setMaximumDate] = useState(moment());
  // Redux variable
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { dietStrategyData, drawerDetailsDietStrategy } = useSelector(
    (state) => state.dietStrategy
  );
  const dispatch = useDispatch();
  // Effects
  useEffect(() => {
    async function getBreeds() {
      try {
        const {
          data: { results: breeds },
        } = await getBreedIndexActive({
          groupId,
          farmId,
          withoutBreedCurveParam: true,
        });
        setBreeds(breeds);
      } catch (error) {
        setBreeds([]);
      }
    }

    getBreeds();
  }, [groupId, farmId]);
  /** This effect do:
   * 1 - Set the dietStrategyData(Redux) in local state dietStrategy
   * 2 - Calculate Lot curve
   * 3 - Calculate DietPeriod Curve if the dietStategy is manual
   */
  useEffect(() => {
    async function calculateCurves(dietStrategy) {
      let result = [];
      calculateDietPeriodsCurve(dietStrategy, result);
      calculateCurveOfGdp(dietStrategy, null);
    }
    /** Method to create DietPeriods Curve */
    function calculateDietPeriodsCurve(dietStrategy, lotCurveDatesAndWeights) {
      const { lot, generic, initialDate, initialWeight, dietPeriods } =
        dietStrategy;
      let baseWeight = generic
        ? initialWeight
        : lot.referenceAcquisitionWeight || 0;
      const baseDate = generic ? initialDate : lot.referenceAcquisitionDate;
      let result = [];
      try {
        if (dietPeriods != null) {
          // Get the reference date
          const dateOfReference = moment(dietPeriods[0].startDate).subtract(
            dietPeriods[0].startDay,
            "days"
          );
          dietPeriods.forEach((dp, index) => {
            let i = dp.startDay;
            let j = 0;
            do {
              const isFirstValue = moment(dateOfReference)
                .add(i, "days")
                .isSame(moment(baseDate), "days", "[]");

              const x = moment(dateOfReference).add(i, "days").toDate();
              let y = 0;
              if (isFirstValue) {
                y = baseWeight;
              } else {
                if (dp?.allWeightGains?.length > 0) {
                  const weightGainAPI = dp.allWeightGains[j];
                  if (weightGainAPI) {
                    y = baseWeight += weightGainAPI.gdp;
                  } else {
                    y = baseWeight += dp.gdpExpectation;
                  }
                  j++;
                } else {
                  y = baseWeight += dp.gdpExpectation;
                }
              }
              result.push({
                x,
                y,
              });
              i++;
            } while (i <= dp.endDay);
          });
        } else {
          result = [];
        }
      } catch (error) {
        console.error(error);
      }
      setDietPeriodCurve(result);
    }
    /** Method to create curve of gdp */
    function calculateCurveOfGdp(dietStrategy, curve) {
      const { dietPeriods: dietPeriodsCopy } = dietStrategy;

      let dataPointsArray = []; // Array with x (Date) and y (gdp)

      try {
        if (dietPeriodsCopy.length > 0) {
          // Get the reference date
          const dateOfReference = moment(dietPeriodsCopy[0].startDate).subtract(
            dietPeriodsCopy[0].startDay,
            "days"
          );
          // Go through diet periods and populate the dataPointsArray
          dietPeriodsCopy.forEach((dp) => {
            const startDay = dp.startDay;
            const endDay = dp.endDay;
            let j = 0;
            for (let i = startDay; i <= endDay; i++) {
              const x = moment(dateOfReference).add(i, "days").toDate();
              let y = 0;
              if (dp?.allWeightGains?.length > 0) {
                const weightGainAPI = dp.allWeightGains[j];
                if (weightGainAPI) {
                  y = weightGainAPI.gdp;
                } else {
                  y = dp.gdpExpectation;
                }
                j++;
              } else {
                y = dp.gdpExpectation;
              }
              const value = { x, y };
              dataPointsArray.push(value);
            }
          });
        }
        setGdpCurve(dataPointsArray);
      } catch (error) {
        console.error(error);
      }
    }

    setDietStrategy(dietStrategyData);

    if (dietStrategyData != null) {
      const newMaximumDate = moment(
        dietStrategyData.dietPeriods[dietStrategyData.dietPeriods.length - 1]
          .endDate
      ).add(2, "months");
      setMaximumDate(newMaximumDate);
      calculateCurves(dietStrategyData);
    }
    return () => {
      setDietStrategy(null);
    };
  }, [dietStrategyData, groupId, farmId]);

  /** Effect to get seasons and mount the StripLines */
  useEffect(() => {
    async function getSeasons() {
      try {
        const {
          data: { results },
        } = await getWeatherSeason({ farmId });

        generateStripLines(
          results.farmSelectedTypeSeason === "Drier"
            ? results.seasonsDrier
            : results.farmSelectedTypeSeason === "Wetter"
            ? results.seasonsWetter
            : results.seasonsDefault
        );
      } catch (error) {}
    }
    function generateStripLines(seasons) {
      const { lot, generic, initialDate, dietPeriods } = dietStrategyData;

      let startDay = generic
        ? 0
        : moment(lot.referenceAcquisitionDate).diff(
            lot.referenceBirthdayDate,
            "days",
            true
          );

      let endDay =
        dietPeriods[dietPeriods.length - 1].endDay < 1095
          ? 1095
          : dietPeriods[dietPeriods.length - 1].endDay + 90;

      if (seasons != null) {
        // Set StripLines
        let resultStripLines = [
          {
            value: generic
              ? moment(initialDate).toDate()
              : moment(lot.referenceAcquisitionDate).toDate(),
            label: "Aquisição",
            labelPlacement: "outside",
          },
          {
            value: moment(moment(), "DD/MM/YY").toDate(),
            label: "Hoje",
            labelPlacement: "inside",
            color: "#4b4b4b",
            labelFontColor: "#4b4b4b",
            labelBackgroundColor: "transparent",
          },
        ];

        let season = null;

        let newStripLine = null;

        const seasonMap = seasons.map((s) => s.season);

        do {
          const currentMonth = generic
            ? Number(moment(initialDate).add(startDay, "days").month())
            : Number(
                moment(lot.referenceBirthdayDate).add(startDay, "days").month()
              );

          let nextMonth = generic
            ? Number(
                moment(initialDate)
                  .add(startDay + 1, "days")
                  .month()
              )
            : Number(
                moment(lot.referenceBirthdayDate)
                  .add(startDay + 1, "days")
                  .month()
              );

          let loopSeasonCurrentMonth = seasonMap[currentMonth];
          let loopSeasonNextMonth = seasonMap[nextMonth];

          if (season === null) {
            season = loopSeasonCurrentMonth;
            newStripLine = {
              startValue: generic
                ? moment(initialDate).add(startDay, "days").toDate()
                : moment(lot.referenceBirthdayDate)
                    .add(startDay, "days")
                    .toDate(),
              label:
                season === "Water"
                  ? "Chuva".toUpperCase()
                  : season === "Dry"
                  ? "Seca".toUpperCase()
                  : season === "Transition"
                  ? "Transição".toUpperCase()
                  : null,
              color:
                season === "Water"
                  ? waterColor
                  : season === "Dry"
                  ? dryColor
                  : season === "Transition"
                  ? transitionColor
                  : null,
              labelFontColor: "#FFF",
              labelAlign: "center",
              labelAngle: 180,
              labelBackgroundColor: "transparent",
            };
          } else {
            /* Verifico se ñ mudou de estacao */
            if (loopSeasonCurrentMonth === season) {
              /* Se continua a mesma - verifico se no proximo dia ira mudar */
              if (loopSeasonCurrentMonth !== loopSeasonNextMonth) {
                /* Se mudou adiciono o end date e adiciono na lista */
                newStripLine = {
                  ...newStripLine,
                  endValue: generic
                    ? moment(initialDate).add(startDay, "days").toDate()
                    : moment(lot.referenceBirthdayDate)
                        .add(startDay, "days")
                        .toDate(),
                };
                resultStripLines.push(newStripLine);
                newStripLine = null;
                season = null;
              }
            }
          }

          if (startDay === endDay) {
            if (loopSeasonCurrentMonth !== loopSeasonNextMonth) {
              endDay++;
            } else {
              newStripLine = {
                ...newStripLine,
                endValue: generic
                  ? moment(initialDate).add(startDay, "days").toDate()
                  : moment(lot.referenceBirthdayDate)
                      .add(startDay, "days")
                      .toDate(),
              };
              resultStripLines.push(newStripLine);
              newStripLine = null;
              season = null;
            }
          }

          startDay++;
        } while (startDay <= endDay);

        if (dietPeriods.length > 0) {
          dietPeriods.forEach((dp, i) => {
            resultStripLines.push(
              {
                value: moment(dp.startDate).toDate(),
                color: "#000080",
                thickness: 4,
                showOnTop: true,
                lineDashType: "solid",
              },
              {
                startValue: moment(dp.startDate).toDate(),
                endValue: moment(dp.endDate).toDate(),
                label: `Dieta ${i + 1}`,
                color: "transparent",
                labelFontColor: "#000080",
                labelFontSize: 14,
                labelAlign: "near",
                showOnTop: true,
                labelFontWeight: "bold",
                labelBackgroundColor: "transparent",
              },
              {
                value: moment(dp.endDate).toDate(),
                color: "#000080",
                thickness: 4,
                showOnTop: true,
                lineDashType: "solid",
              }
            );
          });
        }

        setStripLines(resultStripLines);
      } else {
        setStripLines([]);
      }
    }
    if (dietStrategyData != null) getSeasons();
  }, [farmId, dietStrategyData]);

  // Memo
  /** Get the total period field between periods */
  const totalPeriod = useMemo(() => {
    if (dietStrategy != null) {
      if (dietStrategy.dietPeriods.length > 0) {
        return `${moment(dietStrategy.dietPeriods[0].startDate).format(
          "DD/MM/YYYY"
        )} - ${moment(
          dietStrategy.dietPeriods[dietStrategy.dietPeriods.length - 1].endDate
        ).format("DD/MM/YYYY")} `;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }, [dietStrategy]);

  /** Method to select period and open drawer of period selected */
  function handleOpenPeriodDrawer(period) {
    const size = dietStrategy.dietPeriods.length;
    // Get the index of the period selected
    const currentIndex = dietStrategy.dietPeriods.findIndex(
      (ds, index) => ds.id === period.id
    );
    // Verify if have a next period or not
    const haveNext = currentIndex === size - 1 ? false : true;
    // Verify if have a previous period or not
    const havePrevious = currentIndex === 0 ? false : true;

    setDietStrategyDietPeriod(period);
    setCurrentPeriodIndex(currentIndex);
    setNextPeriodIndex(haveNext ? currentIndex + 1 : null);
    setPreviousPeriodIndex(havePrevious ? currentIndex - 1 : null);
    setDrawerScenarioPeriodVisible(true);
  }

  /** Method to close period drawer */
  function handleClosePeriodDrawer() {
    setDrawerScenarioPeriodVisible(false);
  }

  /** Method to close this drawer */
  function handleCloseDrawer() {
    setDietStrategy(null);
    dispatch(DietStrategyActions.showOrHideDietStrategyDetail());
  }

  /** Method to navigate between the periods */
  function handleNextPreviousPeriod(index, action) {
    const size = dietStrategy.dietPeriods.length;
    let currentIndex = index;
    // Set the next period
    if (action === "next") {
      index = index + 1;
      currentIndex += 1;
      let period = dietStrategy.dietPeriods.find((dp, i) => i === index);
      setDietStrategyDietPeriod(period);
    } else if (action === "previous") {
      // Set the previous period
      index = index - 1;
      currentIndex -= 1;
      let period = dietStrategy.dietPeriods.find((dp, i) => i === index);
      setDietStrategyDietPeriod(period);
    }
    // Verify if have a next period or not
    const haveNext = currentIndex === size - 1 ? false : true;
    // Verify if have a previous period or not
    const havePrevious = currentIndex === 0 ? false : true;

    setCurrentPeriodIndex(currentIndex);
    setNextPeriodIndex(haveNext ? currentIndex + 1 : null);
    setPreviousPeriodIndex(havePrevious ? currentIndex - 1 : null);
  }

  /** Method to open and close drawer chart */
  function handleCloseOrOpenDrawerChart() {
    setDrawerDetailsDietStrategyChartVisible(
      !drawerDetailsDietStrategyChartVisible
    );
  }

  return (
    <Container
      title={dietStrategy != null ? dietStrategy?.name : "-"}
      width={450}
      visible={drawerDetailsDietStrategy}
      onClose={handleCloseDrawer}
      maskClosable={false}
    >
      <Spin spinning={dietStrategy === null ? true : false}>
        <div className="drawerForm">
          {!dietStrategy?.generic ? (
            <div className="info-box">
              <div>
                <p>Lote</p>
                <b>{dietStrategy?.lot.name}</b>
              </div>
              <div>
                <p>Periodo total</p>
                <b>{totalPeriod}</b>
              </div>
            </div>
          ) : (
            <>
              <div className="info-box">
                <div>
                  <p>Raça</p>
                  <b>
                    {breeds.find((b) => b.id === dietStrategy?.breedId)?.name ||
                      "-"}
                  </b>
                </div>
                <div>
                  <p>Sexo</p>
                  <b>{dietStrategy?.gender === "Male" ? "Macho" : "Fêmea"}</b>
                </div>
                {dietStrategy?.gender === "Male" && (
                  <div>
                    <p>Castrado</p>
                    <b>{dietStrategy?.cap ? "Sim" : "Não"}</b>
                  </div>
                )}
              </div>
              <div className="info-box">
                <div>
                  <p>Data Inicial</p>
                  <b>
                    {moment(dietStrategy?.initialDate).format("DD/MM/YYYY")}
                  </b>
                </div>
                <div>
                  <p>Peso Inicial</p>
                  <b>{`${numberMask(
                    dietStrategy?.initialWeight || 0,
                    false
                  )} kg`}</b>
                </div>
              </div>
              <div className="info-box">
                <div>
                  <p>Periodo total</p>
                  <b>{totalPeriod}</b>
                </div>
              </div>
            </>
          )}

          <CustomDivider dashed>DIETAS</CustomDivider>
          <Row type="flex" className="rowButtonShowChart">
            <Col span={24} align="right">
              <ButtonStandard
                type="submit"
                buttonType="typeWithoutBackground"
                color="#684E94"
                isUpperCase={true}
                onClick={handleCloseOrOpenDrawerChart}
                disabled={dietPeriodCurve === null ? true : false}
              >
                VISUALIZAR GRÁFICO
              </ButtonStandard>
            </Col>
          </Row>

          <Row type="flex">
            {dietStrategy?.dietPeriods.map((period) => (
              <CardDiet
                key={period.id}
                onClick={() => handleOpenPeriodDrawer(period)}
              >
                <Row
                  type="flex"
                  justify="space-between"
                  style={{
                    paddingLeft: 3,
                    paddingRight: 3,
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  {period.season
                    .split(",")
                    .map((season) =>
                      season === "Water" ? (
                        <RainIcon key={season} />
                      ) : season === "Transition" ? (
                        <TransitionIcon key={season} />
                      ) : season === "Dry" ? (
                        <SunIcon key={season} />
                      ) : null
                    )}
                </Row>
                <p>
                  {moment(period.startDate, "YYYY-MM-DD").format("DD/MM/YY")}
                </p>
                <p>{moment(period.endDate, "YYYY-MM-DD").format("DD/MM/YY")}</p>
                {period.conflictedGdp ? (
                  <Tooltip title="Dieta com GDP conflitante">
                    <div className="gdpConflited">
                      <AlertIcon />
                    </div>
                  </Tooltip>
                ) : null}
                {(period.calciumCode === 1 ||
                  period.phosporusCode === 1 ||
                  period.zincCode === 1 ||
                  period.sodiumCode === 1) && (
                  <Tooltip title="Dieta com minerais não suficientes.">
                    <div className="mineralsConflited">
                      <Icon type="exclamation-circle" theme="filled" />
                    </div>
                  </Tooltip>
                )}
              </CardDiet>
            ))}
          </Row>
        </div>
      </Spin>

      <DrawerDisplayDietStrategyPeriod
        drawerVisible={drawerScenarioPeriodVisible}
        data={dietStrategyDietPeriod}
        onCancel={handleClosePeriodDrawer}
        goNextOrPrevius={handleNextPreviousPeriod}
        index={currentPeriodIndex}
        nextIndex={nextPeriodIndex}
        previousIndex={previousPeriodIndex}
      />

      <DrawerDisplayDietStrategyChart
        lot={dietStrategy?.lot}
        maximumDate={maximumDate}
        lotCurve={[]}
        dietPeriodCurve={dietPeriodCurve}
        gdpCurve={gdpCurve}
        stripLines={stripLines}
        drawerDetailsDietStrategyChartVisible={
          drawerDetailsDietStrategyChartVisible
        }
        handleCloseDrawerChart={handleCloseOrOpenDrawerChart}
      />
    </Container>
  );
};

export default DrawerDisplayDietStrategy;
