import apiIam from "../config/api_iam";
import getHeaders from "./getHeaders";

export function pageAllUsersByFarmIdAndGroupId(payload) {
  const { groupId, farmId, page, sorter, filters, size } = payload;
  let sortS = "";

  if (sorter && Object.entries(sorter).length > 0) {
    const { field, order } = sorter;

    const fieldS = field
      ? field === "nextCommitmentDate"
        ? "next_commitment_date"
        : field
      : null;

    const orderS = order && order === "descend" ? "DESC" : "ASC";

    sortS = `&sort=${fieldS}&direction=${orderS}`;
  }

  return apiIam.get(
    `/bovexo/${groupId}/farms/${farmId}/users?
    ${page ? `&page=${page - 1}` : ""}
    ${size && `&size=${size}`}
    ${sortS}
    ${filters !== "" && filters ? `&search=${filters}` : ""}`,
    getHeaders(false, null)
  );
}

export function indexUserGroupsAndFarms(payload) {
  const { userId, signal } = payload;

  return apiIam.get(
    `/bovexo/admin/users/${userId}/groups/`,
    getHeaders(false, signal)
  );
}

export function indexUserGroupsAndFarmsOnLogin(token, userId) {
  return apiIam.get(`/bovexo/admin/users/${userId}/groups/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getUserStatus({ payload }) {
  const { groupId, farmId, userId } = payload;
  return apiIam.get(
    `/bovexo/${groupId}/farms/${farmId}/users/${userId}/status`,
    getHeaders(false, null)
  );
}

export function getUserStatusWithoutAsync(payload) {
  const { groupId, farmId, userId } = payload;
  return apiIam.get(
    `/bovexo/${groupId}/farms/${farmId}/users/${userId}/status`,
    getHeaders(false, null)
  );
}

export function indexUserById(groupId, farmId, userId) {
  return apiIam.get(
    `/bovexo/${groupId}/farms/${farmId}/users/${userId}`,
    getHeaders(false, null)
  );
}

export function updateProfileByUserId(groupId, farmId, userId, values) {
  return apiIam.patch(
    `/bovexo/${groupId}/farms/${farmId}/users/${userId}`,
    values,
    getHeaders(false, null)
  );
}

export function updateProfileByUserIdWithAvatarFile(
  groupId,
  farmId,
  userId,
  values
) {
  const { avatarFile, ...user } = values;
  let formData = new FormData();
  formData.append("avatarFile", avatarFile);
  formData.append("user", JSON.stringify(user));
  return apiIam.patch(
    `/bovexo/${groupId}/farms/${farmId}/users/${userId}`,
    formData,
    getHeaders(true, null)
  );
}

export function getAllRolesByUserIdAndGroupIdAndFarmId(payload) {
  const { userId, groupId, farmId, isInputSupplier } = payload;

  if (isInputSupplier) {
    return apiIam.get(
      `/bovexo/roles/users/${userId}`,
      getHeaders(false, null)
    );
  } else {
    return apiIam.get(
      `/bovexo/roles/users/${userId}/groups/${groupId}/farms/${farmId}`,
      getHeaders(false, null)
    );
  }
}

export function inactivateUser(payload) {
  const { userId, groupId, farmId } = payload;
  return apiIam.put(
    `/bovexo/${groupId}/farms/${farmId}/users/${userId}/inactivate`,
    null,
    getHeaders(false, null)
  );
}

export function activateUser(payload) {
  const { userId, groupId, farmId } = payload;
  return apiIam.put(
    `/bovexo/${groupId}/farms/${farmId}/users/${userId}/activate`,
    null,
    getHeaders(false, null)
  );
}

export function getRolesForUserInEspecificGroupAndFarm(payload) {
  const { userId, groupId, farmId } = payload;
  return apiIam.get(
    `/bovexo/${groupId}/farms/${farmId}/users/${userId}/roles`,
    getHeaders(false, null)
  );
}
