import styled from 'styled-components';

const positiveColor = '#33c162';
const negativeColor = '#d44c4c';
const defaultColor = '#c3c3c3';

export const Container = styled.div`
  button.title {
    display: flex;
    justify-content: center;

    background: transparent;

    padding: 8px;

    width: 100%;

    border: 3px solid;
    border-color: ${props => props.isPositive === true ? positiveColor : props.isPositive === false ? negativeColor : defaultColor};
    border-radius: 5px;

    text-align: center;
    font-size: 14px;
    color: #4b4b4b;
    font-weight: bold;

    cursor: pointer;
  }

  .ant-row {
    width: 100%;

    & + .ant-row-flex {
      margin-top: 8px;
    }
  }

`;

export const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  width: 100%;
  height: 100%;

  padding: 8px;

  border: 3px solid;
  border-radius: 5px;
  background-color: #fff;
  border-color: ${props => props.isPositive === true && props.isChecked === true ? positiveColor : props.isPositive === false && props.isChecked === true ? negativeColor : defaultColor};

  cursor: pointer;

  font-size: 12px;
  color: #4b4b4b;
  font-weight: bold;

  .icon {
    color: ${props => props.isPositive === true && props.isChecked === true ? positiveColor : props.isPositive === false && props.isChecked === true ? negativeColor : defaultColor}
    font-size: 20px;
  }

  input[type="radio"] {
    position: absolute;
    width: 100%;
    opacity: 0;
    height: 100%

    cursor: pointer;
  }

`
