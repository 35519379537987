import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as PastureActions } from "../../../store/ducks/pasture";
import { Spin, Row, Col } from "antd";
import {
  Container,
  CustomDivider,
  RadioButtonCustom,
  RadioGroupCustom,
} from "./styles";
import DisplayPastureSeason from "../pasture/displayPastureSeason";
import DrawerPastureWeatherSeasonDetails from "./pastureWeatherSeasonDetails";
/** Services */
import {
  getPastureTypeIndexDropdown,
  getPastureShow,
} from "../../../services/pastureService";
import { buildPastureWithStructuredSeasons } from "../../../utils/pastureBuilder";

const DrawerPastureDetails = () => {
  const [listPastureType, setListPastureType] = useState([]);
  const [pastureData, setPastureData] = useState(null);
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const [
    drawerPastureWeatherSeasonVisible,
    setDrawerPastureWeatherSeasonVisible,
  ] = useState(false);
  const [seasonSelected, setSeasonSelected] = useState(null);

  /** Redux variables */
  const { drawerPastureDetailsVisible, idPastureDetails } = useSelector(
    (state) => state.pasture
  );

  const {
    translation,
    groupSelected,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  /** Methods variables */
  function closeDrawer() {
    setPastureData(null);
    dispatch(PastureActions.showOrHideDrawerPastureDetails(null));
  }

  function closeDrawerSeason() {
    setDrawerPastureWeatherSeasonVisible(false);
    setSeasonSelected(null);
  }

  function handleShowSeason(key, season) {
    const { seasonWater, seasonTransition, seasonDry } = pastureData;
    let selected = null;

    switch (season) {
      case "Water":
        selected = seasonWater.find((s) => s.key === key);
        break;
      case "Transition":
        selected = seasonTransition.find((s) => s.key === key);
        break;
      case "Dry":
        selected = seasonDry.find((s) => s.key === key);
        break;
      default:
        break;
    }

    if (selected != null) {
      setSeasonSelected(selected);
      setDrawerPastureWeatherSeasonVisible(true);
    }
  }

  async function handleDrawerVisible(visible) {
    if (visible && idPastureDetails !== null) {
      setIsLoadingInternal(true);
      try {
        let {
          data: { results: pastureInfo },
        } = await getPastureShow({
          groupId,
          farmId,
          id: idPastureDetails,
        });
        const {
          data: { results: listTypes },
        } = await getPastureTypeIndexDropdown();
        pastureInfo = buildPastureWithStructuredSeasons(pastureInfo);
        setPastureData(pastureInfo);
        setListPastureType(listTypes);
      } catch (error) {
      } finally {
        setIsLoadingInternal(false);
      }
    }
  }

  return (
    <Container
      title={"Detalhes da Forrageira"}
      width={600}
      afterVisibleChange={handleDrawerVisible}
      onClose={closeDrawer}
      maskClosable={false}
      visible={drawerPastureDetailsVisible}
    >
      <Spin spinning={isLoadingInternal}>
        <div className="drawerForm">
          {/* Pasture Visibility */}
          {pastureData?.groupId !== null && (
            <Row type="flex" align="middle">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row>
                  <RadioGroupCustom
                    value={
                      pastureData != null
                        ? pastureData.farmId === null
                          ? "Group"
                          : "Farm"
                        : "Group"
                    }
                    buttonStyle="solid"
                  >
                    <RadioButtonCustom value={"Farm"}>
                      {translation.pasture.form.radiogroup.farm}
                    </RadioButtonCustom>
                    <RadioButtonCustom value={"Group"}>
                      {translation.pasture.form.radiogroup.group}:
                      {groupSelected.name}
                    </RadioButtonCustom>
                  </RadioGroupCustom>
                </Row>
              </Col>
            </Row>
          )}
          {/* Input system identification */}
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <label>{translation.pasture.form.name}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">{pastureData?.name || "-"}</span>
              </Row>
            </Col>
          </Row>
          {/* Input system identification */}
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <label>{translation.pasture.form.pastureType}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">
                  {pastureData != null && listPastureType.length > 0
                    ? listPastureType.find(
                        (l) => l.id === pastureData?.pastureType.id
                      ).name
                    : "-"}
                </span>
              </Row>
            </Col>
          </Row>
          {/* Seasons */}
          <CustomDivider dashed>
            INFORMAÇÕES NUTRICIONAIS POR ESTAÇÕES CLIMÁTICAS
          </CustomDivider>
          <Row type="flex">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <DisplayPastureSeason
                seasonType="Water"
                data={pastureData?.seasonWater}
                onlyShow={true}
                onShow={handleShowSeason}
              />
            </Col>
          </Row>
          <Row type="flex">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <DisplayPastureSeason
                seasonType="Transition"
                data={pastureData?.seasonTransition}
                onlyShow={true}
                onShow={handleShowSeason}
              />
            </Col>
          </Row>
          <Row type="flex">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <DisplayPastureSeason
                seasonType="Dry"
                data={pastureData?.seasonDry}
                onlyShow={true}
                onShow={handleShowSeason}
              />
            </Col>
          </Row>
        </div>
        <DrawerPastureWeatherSeasonDetails
          drawerVisible={drawerPastureWeatherSeasonVisible}
          data={seasonSelected}
          onCancel={closeDrawerSeason}
        />
      </Spin>
    </Container>
  );
};

export default DrawerPastureDetails;
