import React, { Fragment } from "react";
/** Components */
import { Container } from "./styles";
import { Row, Col, Tooltip, Divider } from "antd";
import InfoIcon from "../../../../components/utils/icons/info";

const DisplayWeight = ({ data }) => {
  return (
    <Container>
      {data != null && (
        <Fragment>
          <Row type="flex" justify="start" gutter={8}>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <span>
                Cálculo do Peso Diário
                <Tooltip
                  title={
                    <div>
                      <p>
                        Quando utilizado balanças de passagem em que o animal é
                        pesado mais de uma vez no dia, é necessário definir o
                        critério a ser adotado para Cálculo do Peso Diário. São
                        as opções:
                      </p>
                      <p>
                        <strong>Média Diária:</strong> Média aritmética simples
                        de todas as pesagens do animal no dia.
                      </p>
                      <p>
                        <strong>Média Diária Exceto Extremos:</strong> Média
                        aritmética simples as pesagens, desconsiderando a
                        primeira e última pesagem registrada para o animal no
                        dia.
                      </p>
                      <p>
                        <strong>Mínimo do Dia:</strong> Considera-se a menor
                        pesagem registrada para animal no dia.
                      </p>
                      <p>
                        <strong>Média Diária entre Período:</strong> Média
                        aritmética simples das pesagens registradas para o
                        animal em determinado período do dia.
                      </p>
                    </div>
                  }
                >
                  <InfoIcon />
                </Tooltip>
              </span>
            </Col>
            <Col xs={24} sm={24} md={16} lg={18} xl={18}>
              <Row>
                <strong>
                  {data.find((x) => x.code === 1004)?.value === "SimpleAverage"
                    ? "Média Diária"
                    : data.find((x) => x.code === 1004)?.value ===
                      "AverageWithoutOutliers"
                    ? "Média Diária Exceto Extremos"
                    : data.find((x) => x.code === 1004)?.value ===
                      "MinimumWeight"
                    ? "Peso Mínimo do Dia"
                    : data.find((x) => x.code === 1004)?.value ===
                      "SimpleAveragePeriod"
                    ? "Média Diária entre Período:"
                    : "Parâmetro Não Encontrado"}
                  {data.find((x) => x.code === 1004)?.value ===
                  "SimpleAveragePeriod" ? (
                    <strong>
                      {data.find((x) => x.code === 1005)?.value} até{" "}
                      {data.find((x) => x.code === 1005)?.valueExtra}
                    </strong>
                  ) : null}
                </strong>
              </Row>
            </Col>
          </Row>
          <Divider dashed="true" style={{ margin: "10px 0px" }} />
          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <span>
                Ranking de pesagens
                <Tooltip title="O Ranking de Pesagens permite que o sistema classifique o animal utilizando a porcentagem de diferença entre o peso real e o peso esperado (considerando a curva de crescimento e curva da dieta do lote/animal).">
                  <InfoIcon />
                </Tooltip>
              </span>
            </Col>
            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <Row>
                <div className="valuesContainer">
                  <span className="values">
                    <div className="circle red" />
                    Fundo:
                    <strong>{`< ${
                      data.find((x) => x.code === 1003)?.value || 0
                    }%`}</strong>
                  </span>
                  <span className="values">
                    <div className="circle green" />
                    Meio:
                    <strong>{`> ${
                      data.find((x) => x.code === 1003)?.value || 0
                    }% | < ${
                      data.find((x) => x.code === 1003)?.valueExtra || 0
                    }%`}</strong>
                  </span>
                  <span className="values">
                    <div className="circle purple" />
                    Cabeceira:
                    <strong>{`> ${
                      data.find((x) => x.code === 1003)?.valueExtra || 0
                    }%`}</strong>
                  </span>
                </div>
              </Row>
            </Col>
          </Row>
        </Fragment>
      )}
    </Container>
  );
};

export default DisplayWeight;
