/* Types & Action Creators */
export const Types = {
  RESET_DATA: "animal/RESET_DATA",
  GET_NUMBER_ANIMALS: "animal/GET_NUMBER_ANIMALS",
  GET_NUMBER_ANIMALS_SUCCESS: "animal/GET_NUMBER_ANIMALS_SUCCESS",
  GET_NUMBER_ANIMALS_ERROR: "animal/GET_NUMBER_ANIMALS_ERROR",
  GET_ANIMAL: "animal/GET_ANIMAL",
  GET_ANIMAL_SUCCESS: "animal/GET_ANIMAL_SUCCESS",
  GET_ANIMAL_ERROR: "animal/GET_ANIMAL_ERROR",
  GET_ANIMAL_DETAILS: "animal/GET_ANIMAL_DETAILS",
  GET_ANIMAL_DETAILS_SUCCESS: "animal/GET_ANIMAL_DETAILS_SUCCESS",
  GET_ANIMAL_DETAILS_ERROR: "animal/GET_ANIMAL_DETAILS_ERROR",
  GET_ANIMAL_WEIGHT_HISTORY: "animal/GET_ANIMAL_WEIGHT_HISTORY",
  GET_ANIMAL_WEIGHT_HISTORY_SUCCESS: "animal/GET_ANIMAL_WEIGHT_HISTORY_SUCCESS",
  GET_ANIMAL_WEIGHT_HISTORY_ERROR: "animal/GET_ANIMAL_WEIGHT_HISTORY_ERROR",
  GET_ANIMAL_DAILY_WEIGHT: "animal/GET_ANIMAL_DAILY_WEIGHT",
  GET_ANIMAL_DAILY_WEIGHT_PAGEABLE: "animal/GET_ANIMAL_DAILY_WEIGHT_PAGEABLE",
  GET_ANIMAL_DAILY_WEIGHT_SUCCESS: "animal/GET_ANIMAL_DAILY_WEIGHT_SUCCESS",
  GET_ANIMAL_DAILY_WEIGHT_PAGEABLE_SUCCESS:
    "animal/GET_ANIMAL_DAILY_WEIGHT_PAGEABLE_SUCCESS",
  GET_ANIMAL_DAILY_WEIGHT_PAGEABLE_ERROR:
    "animal/GET_ANIMAL_DAILY_WEIGHT_PAGEABLE_ERROR",
  GET_ANIMAL_DAILY_WEIGHT_ERROR: "animal/GET_ANIMAL_DAILY_WEIGHT_ERROR",
  GET_ANIMAL_DAILY_WEIGHT_GAIN: "animal/GET_ANIMAL_DAILY_WEIGHT_GAIN",
  GET_ANIMAL_DAILY_WEIGHT_GAIN_PAGEABLE:
    "animal/GET_ANIMAL_DAILY_WEIGHT_GAIN_PAGEABLE",
  GET_ANIMAL_DAILY_WEIGHT_GAIN_SUCCESS:
    "animal/GET_ANIMAL_DAILY_WEIGHT_GAIN_SUCCESS",
  GET_ANIMAL_DAILY_WEIGHT_GAIN_PAGEABLE_SUCCESS:
    "animal/GET_ANIMAL_DAILY_WEIGHT_GAIN_PAGEABLE_SUCCESS",
  GET_ANIMAL_DAILY_WEIGHT_GAIN_ERROR:
    "animal/GET_ANIMAL_DAILY_WEIGHT_GAIN_ERROR",
  GET_ANIMAL_TOTAL_DAILY_WEIGHT_DASHBOARD:
    "animal/GET_ANIMAL_TOTAL_DAILY_WEIGHT_DASHBOARD",
  GET_ANIMAL_TOTAL_DAILY_WEIGHT_DASHBOARD_SUCCESS:
    "animal/GET_ANIMAL_TOTAL_DAILY_WEIGHT_DASHBOARD_SUCCESS",
  GET_ANIMAL_GMD_DASHBOARD: "animal/GET_ANIMAL_GMD_DASHBOARD",
  GET_ANIMAL_GMD_DASHBOARD_SUCCESS: "animal/GET_ANIMAL_GMD_DASHBOARD_SUCCESS",
  GET_ANIMAL_GMD_DASHBOARD_ERROR: "animal/GET_ANIMAL_GMD_DASHBOARD_SUCCESS",
  SHOW_DRAWER_EDIT_ANIMAL: "animal/SHOW_DRAWER_EDIT_ANIMAL",
  HIDE_DRAWER_EDIT_ANIMAL: "animal/HIDE_DRAWER_EDIT_ANIMAL",
  SHOW_HIDE_DRAWER_NEW_ANIMAL: "animal/SHOW_HIDE_DRAWER_NEW_ANIMAL",
  SAVE_ANIMAL: "animal/SAVE_ANIMAL",
  SAVE_ANIMAL_SUCCESS: "animal/SAVE_ANIMAL_SUCCESS",
  SAVE_ANIMAL_ERROR: "animal/SAVE_ANIMAL_ERROR",
  REFRESH_ANIMAL_DATA: "animal/REFRESH_ANIMAL_DATA",
  GET_ANIMAL_DAILY_WEIGHT_DASHBOARD: "animal/GET_ANIMAL_DAILY_WEIGHT_DASHBOARD",
  GET_ANIMAL_DAILY_WEIGHT_DASHBOARD_SUCCESS:
    "animal/GET_ANIMAL_DAILY_WEIGHT_DASHBOARD_SUCCESS",
  GET_ANIMAL_DAILY_WEIGHT_DASHBOARD_ERROR:
    "animal/GET_ANIMAL_DAILY_WEIGHT_DASHBOARD_SUCCESS",
  GET_ANIMAL_DAILY_WEIGHT_GAIN_DASHBOARD:
    "animal/GET_ANIMAL_DAILY_WEIGHT_GAIN_DASHBOARD",
  GET_ANIMAL_DAILY_WEIGHT_GAIN_DASHBOARD_SUCCESS:
    "animal/GET_ANIMAL_DAILY_WEIGHT_GAIN_DASHBOARD_SUCCESS",
  GET_ANIMAL_DAILY_WEIGHT_GAIN_DASHBOARD_ERROR:
    "animal/GET_ANIMAL_DAILY_WEIGHT_GAIN_DASHBOARD_SUCCESS",
  ACTIVATE_INACTIVATE_ANIMAL: "animal/ACTIVATE_INACTIVATE_ANIMAL",
  ACTIVATE_INACTIVATE_ANIMAL_SUCCESS:
    "animal/ACTIVATE_INACTIVATE_ANIMAL_SUCCESS",
  ACTIVATE_INACTIVATE_ANIMAL_ERROR: "animal/ACTIVATE_INACTIVATE_ANIMAL_SUCCESS",
  DELETE_ANIMAL: "animal/DELETE_ANIMAL",
  DELETE_ANIMAL_SUCCESS: "animal/DELETE_ANIMAL_SUCCESS",
  DELETE_ANIMAL_ERROR: "animal/DELETE_ANIMAL_ERROR",
  RESET_ANIMAL_TABLE_PAGINATION: "animal/RESET_ANIMAL_TABLE_PAGINATION",
  GET_ANIMAL_FEMALE_TABLE: "animal/GET_ANIMAL_FEMALE_TABLE",
  GET_ANIMAL_SUCCESS_FEMALE_TABLE: "animal/GET_ANIMAL_SUCCESS_FEMALE_TABLE",
  GET_ANIMAL_ERROR_FEMALE_TABLE: "animal/GET_ANIMAL_ERROR_FEMALE_TABLE",
  GET_ANIMAL_FEMALE_REPRODUCTION_EVENTS_TABLE:
    "animal/GET_ANIMAL_FEMALE_REPRODUCTION_EVENTS_TABLE",
  GET_ANIMAL_SUCCESS_FEMALE_REPRODUCTION_EVENTS_TABLE:
    "animal/GET_ANIMAL_SUCCESS_FEMALE_REPRODUCTION_EVENTS_TABLE",
  GET_ANIMAL_ERROR_FEMALE_REPRODUCTION_EVENTS_TABLE:
    "animal/GET_ANIMAL_ERROR_FEMALE_REPRODUCTION_EVENTS_TABLE",
  GET_ANIMAL_MALE_TABLE: "animal/GET_ANIMAL_MALE_TABLE",
  GET_ANIMAL_SUCCESS_MALE_TABLE: "animal/GET_ANIMAL_SUCCESS_MALE_TABLE",
  GET_ANIMAL_ERROR_MALE_TABLE: "animal/GET_ANIMAL_ERROR_MALE_TABLE",
  GET_ANIMAL_DAILY_COST: "animal/GET_ANIMAL_DAILY_COST",
  GET_ANIMAL_DAILY_COST_SUCCESS: "animal/GET_ANIMAL_DAILY_COST_SUCCESS",
  GET_ANIMAL_DAILY_COST_ERROR: "animal/GET_ANIMAL_DAILY_COST_ERROR",
  GET_ANIMAL_WEANING_WEIGHT_DASHBOARD:
    "animal/GET_ANIMAL_WEANING_WEIGHT_DASHBOARD",
  GET_ANIMAL_WEANING_WEIGHT_DASHBOARD_SUCCESS:
    "animal/GET_ANIMAL_WEANING_WEIGHT_DASHBOARD_SUCCESS",
  GET_ANIMAL_WEANING_WEIGHT_DASHBOARD_ERROR:
    "animal/GET_ANIMAL_WEANING_WEIGHT_DASHBOARD_ERROR",
  GET_ANIMAL_MOTHER_DROPDOWN: "animal/GET_ANIMAL_MOTHER_DROPDOWN",
  GET_ANIMAL_MOTHER_DROPDOWN_SUCCESS:
    "animal/GET_ANIMAL_MOTHER_DROPDOWN_SUCCESS",
  GET_ANIMAL_MOTHER_DROPDOWN_ERROR: "animal/GET_ANIMAL_MOTHER_DROPDOWN_ERROR",
  GET_ANIMAL_FATHER_DROPDOWN_SUCCESS:
    "animal/GET_ANIMAL_FATHER_DROPDOWN_SUCCESS",
  GET_ANIMAL_FATHER_DROPDOWN: "animal/GET_ANIMAL_FATHER_DROPDOWN",
  GET_ANIMAL_FATHER_DROPDOWN_ERROR: "animal/GET_ANIMAL_FATHER_DROPDOWN_ERROR",
  GET_ANIMAL_LOT_WEIGHT_DASHBOARD: "animal/GET_ANIMAL_LOT_WEIGHT_DASHBOARD",
  GET_ANIMAL_LOT_WEIGHT_DASHBOARD_SUCCESS:
    "animal/GET_ANIMAL_LOT_WEIGHT_DASHBOARD_SUCCESS",
  GET_ANIMAL_LOT_WEIGHT_DASHBOARD_ERROR:
    "animal/GET_ANIMAL_LOT_WEIGHT_DASHBOARD_ERROR",
  GET_ANIMAL_HANDLING_COMMENTS: "animal/GET_ANIMAL_HANDLING_COMMENTS",
  GET_ANIMAL_HANDLING_COMMENTS_SUCCESS:
    "animal/GET_ANIMAL_HANDLING_COMMENTS_SUCCESS",
  GET_ANIMAL_HANDLING_COMMENTS_ERROR:
    "animal/GET_ANIMAL_HANDLING_COMMENTS_ERROR",
  GET_ANIMAL_PICKET_SUPPLEMENT_SUPPLIES:
    "animal/GET_ANIMAL_PICKET_SUPPLEMENT_SUPPLIES",
  GET_ANIMAL_PICKET_SUPPLEMENT_SUPPLIES_SUCCESS:
    "animal/GET_ANIMAL_PICKET_SUPPLEMENT_SUPPLIES_SUCCESS",
  GET_ANIMAL_PICKET_SUPPLEMENT_SUPPLIES_ERROR:
    "animal/GET_ANIMAL_PICKET_SUPPLEMENT_SUPPLIES_ERROR",
  RESET_REPRODUCTION_IDENTIFICATION_FILTER_AND_SORT:
    "animal/RESET_REPRODUCTION_IDENTIFICATION_FILTER_AND_SORT",
  RESET_FATTEN_IDENTIFICATION_FILTER_AND_SORT:
    "animal/RESET_FATTEN_IDENTIFICATION_FILTER_AND_SORT",
};

export const Creators = {
  resetData: () => ({
    type: Types.RESET_DATA,
    payload: {},
  }),
  getNumberAnimals: (groupId, farmId) => ({
    type: Types.GET_NUMBER_ANIMALS,
    payload: { groupId, farmId },
  }),
  getNumberAnimalsSuccess: (data) => ({
    type: Types.GET_NUMBER_ANIMALS_SUCCESS,
    payload: { data },
  }),
  getNumberAnimalsError: (error) => ({
    type: Types.GET_NUMBER_ANIMALS_ERROR,
    payload: { error },
  }),
  indexAnimal: (
    groupId,
    farmId,
    page = 0,
    sorter = null,
    filters = null,
    size = 10,
    ids = null,
    tableSorters = null,
    tableFilters = null
  ) => ({
    type: Types.GET_ANIMAL,
    payload: {
      groupId,
      farmId,
      page,
      sorter,
      filters,
      size,
      ids,
      tableSorters,
      tableFilters,
    },
  }),
  indexAnimalFemaleReproductionEventsTable: (
    groupId,
    farmId,
    year,
    page = 0,
    sorter = null,
    filters = null,
    size = 10,
    ids = null,
    tableSorters = null,
    tableFilters = null
  ) => ({
    type: Types.GET_ANIMAL_FEMALE_REPRODUCTION_EVENTS_TABLE,
    payload: {
      groupId,
      farmId,
      year,
      page,
      sorter,
      filters,
      size,
      ids,
      tableSorters,
      tableFilters,
    },
  }),
  indexAnimalFemaleTable: (
    groupId,
    farmId,
    page = 0,
    sorter = null,
    filters = null,
    size = 10,
    ids = null,
    tableSorters = null,
    tableFilters = null
  ) => ({
    type: Types.GET_ANIMAL_FEMALE_TABLE,
    payload: {
      groupId,
      farmId,
      page,
      sorter,
      filters,
      size,
      ids,
      tableSorters,
      tableFilters,
    },
  }),
  indexAnimalMaleTable: (
    groupId,
    farmId,
    page = 0,
    sorter = null,
    filters = null,
    size = 10,
    ids = null,
    tableSorters = null,
    tableFilters = null
  ) => ({
    type: Types.GET_ANIMAL_MALE_TABLE,
    payload: {
      groupId,
      farmId,
      page,
      sorter,
      filters,
      size,
      ids,
      tableSorters,
      tableFilters,
    },
  }),
  indexAnimalSuccess: (data) => ({
    type: Types.GET_ANIMAL_SUCCESS,
    payload: { data },
  }),
  indexAnimalError: (error) => ({
    type: Types.GET_ANIMAL_ERROR,
    payload: { error },
  }),
  indexAnimalSuccessFemaleReproductionEventsTable: (data) => ({
    type: Types.GET_ANIMAL_SUCCESS_FEMALE_REPRODUCTION_EVENTS_TABLE,
    payload: { data },
  }),
  indexAnimalErrorFemaleReproductionEventsTable: (error) => ({
    type: Types.GET_ANIMAL_ERROR_FEMALE_REPRODUCTION_EVENTS_TABLE,
    payload: { error },
  }),
  indexAnimalSuccessFemaleTable: (data) => ({
    type: Types.GET_ANIMAL_SUCCESS_FEMALE_TABLE,
    payload: { data },
  }),
  indexAnimalErrorFemaleTable: (error) => ({
    type: Types.GET_ANIMAL_ERROR_FEMALE_TABLE,
    payload: { error },
  }),
  indexAnimalSuccessMaleTable: (data) => ({
    type: Types.GET_ANIMAL_SUCCESS_MALE_TABLE,
    payload: { data },
  }),
  indexAnimalErrorMaleTable: (error) => ({
    type: Types.GET_ANIMAL_ERROR_MALE_TABLE,
    payload: { error },
  }),
  indexAnimalDetails: (groupId, farmId, animalId, isDetails = false) => ({
    type: Types.GET_ANIMAL_DETAILS,
    payload: {
      groupId,
      farmId,
      animalId,
      isDetails,
    },
  }),
  indexAnimalDetailsSuccess: (data) => ({
    type: Types.GET_ANIMAL_DETAILS_SUCCESS,
    payload: { data },
  }),
  indexAnimalDetailsError: (error) => ({
    type: Types.GET_ANIMAL_DETAILS_ERROR,
    payload: { error },
  }),
  indexAnimalWeightHistory: (
    groupId,
    farmId,
    animalId,
    page = 0,
    sorter = {},
    filters = ""
  ) => ({
    type: Types.GET_ANIMAL_WEIGHT_HISTORY,
    payload: {
      groupId,
      farmId,
      animalId,
      page,
      sorter,
      filters,
    },
  }),
  indexAnimalWeightHistorySuccess: (data) => ({
    type: Types.GET_ANIMAL_WEIGHT_HISTORY_SUCCESS,
    payload: { data },
  }),
  indexAnimalWeightHistoryError: (error) => ({
    type: Types.GET_ANIMAL_WEIGHT_HISTORY_ERROR,
    payload: { error },
  }),
  indexAnimalDailyWeight: (groupId, farmId, animalId, doPageable) => ({
    type: Types.GET_ANIMAL_DAILY_WEIGHT,
    payload: {
      groupId,
      farmId,
      animalId,
      doPageable,
    },
  }),
  indexAnimalDailyWeightPageable: (
    groupId,
    farmId,
    animalId,
    doPageable,
    page = 0,
    sorter = {},
    filters = "",
    size = 10
  ) => ({
    type: Types.GET_ANIMAL_DAILY_WEIGHT_PAGEABLE,
    payload: {
      groupId,
      farmId,
      animalId,
      doPageable,
      page,
      sorter,
      filters,
    },
  }),
  indexAnimalDailyWeightSuccess: (data) => ({
    type: Types.GET_ANIMAL_DAILY_WEIGHT_SUCCESS,
    payload: { data },
  }),
  indexAnimalDailyWeightPageableSuccess: (data) => ({
    type: Types.GET_ANIMAL_DAILY_WEIGHT_PAGEABLE_SUCCESS,
    payload: { data },
  }),
  indexAnimalDailyWeightPageableError: (error) => ({
    type: Types.GET_ANIMAL_DAILY_WEIGHT_PAGEABLE_ERROR,
    payload: { error },
  }),
  indexAnimalDailyWeightError: (error) => ({
    type: Types.GET_ANIMAL_DAILY_WEIGHT_ERROR,
    payload: { error },
  }),
  indexAnimalDailyWeightGain: (groupId, farmId, animalId, doPageable) => ({
    type: Types.GET_ANIMAL_DAILY_WEIGHT_GAIN,
    payload: {
      groupId,
      farmId,
      animalId,
      doPageable,
    },
  }),
  indexAnimalDailyWeightGainPageable: (
    groupId,
    farmId,
    animalId,
    doPageable,
    page = 0,
    sorter = {},
    filters = "",
    size = 10
  ) => ({
    type: Types.GET_ANIMAL_DAILY_WEIGHT_GAIN_PAGEABLE,
    payload: {
      groupId,
      farmId,
      animalId,
      doPageable,
      page,
      sorter,
      filters,
    },
  }),
  indexAnimalDailyWeightGainSuccess: (data) => ({
    type: Types.GET_ANIMAL_DAILY_WEIGHT_GAIN_SUCCESS,
    payload: { data },
  }),
  indexAnimalDailyWeightGainPageableSuccess: (data) => ({
    type: Types.GET_ANIMAL_DAILY_WEIGHT_GAIN_PAGEABLE_SUCCESS,
    payload: { data },
  }),
  indexAnimalDailyWeightGainError: (error) => ({
    type: Types.GET_ANIMAL_DAILY_WEIGHT_GAIN_ERROR,
    payload: { error },
  }),
  getAnimalTotalDailyWeightDashboard: (groupId, farmId, id) => ({
    type: Types.GET_ANIMAL_TOTAL_DAILY_WEIGHT_DASHBOARD,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),
  getAnimalGMDDashboard: (groupId, farmId, id) => ({
    type: Types.GET_ANIMAL_GMD_DASHBOARD,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),
  getAnimalGMDDashboardSuccess: (data) => ({
    type: Types.GET_ANIMAL_GMD_DASHBOARD_SUCCESS,
    payload: {
      data,
    },
  }),
  getAnimalGMDDashboardError: (error) => ({
    type: Types.GET_ANIMAL_GMD_DASHBOARD_ERROR,
    payload: {
      error,
    },
  }),
  getAnimalTotalDailyWeightDashboardSuccess: (data) => ({
    type: Types.GET_ANIMAL_TOTAL_DAILY_WEIGHT_DASHBOARD_SUCCESS,
    payload: {
      data,
    },
  }),
  showDrawerNewAnimal: () => ({
    type: Types.SHOW_HIDE_DRAWER_NEW_ANIMAL,
    payload: {},
  }),
  showDrawerEditAnimal: (id) => ({
    type: Types.SHOW_DRAWER_EDIT_ANIMAL,
    payload: { id },
  }),
  hideDrawerEditAnimal: () => ({
    type: Types.HIDE_DRAWER_EDIT_ANIMAL,
    payload: {},
  }),
  saveAnimal: (groupId, farmId, id = null, animal, saveOptions = false) => ({
    type: Types.SAVE_ANIMAL,
    payload: {
      groupId,
      farmId,
      id,
      animal,
      saveOptions,
    },
  }),
  saveAnimalSuccess: (data, saveOptions) => ({
    type: Types.SAVE_ANIMAL_SUCCESS,
    payload: { data, saveOptions },
  }),
  saveAnimalError: (error) => ({
    type: Types.SAVE_ANIMAL_ERROR,
    payload: { error },
  }),
  refreshAnimalData: (data) => ({
    type: Types.REFRESH_ANIMAL_DATA,
    payload: { data },
  }),
  getAnimalDailyWeightDashboard: (groupId, farmId, id) => ({
    type: Types.GET_ANIMAL_DAILY_WEIGHT_DASHBOARD,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),
  getAnimalDailyWeightDashboardSuccess: (data) => ({
    type: Types.GET_ANIMAL_DAILY_WEIGHT_DASHBOARD_SUCCESS,
    payload: {
      data,
    },
  }),
  getAnimalDailyWeightDashboardError: (error) => ({
    type: Types.GET_ANIMAL_DAILY_WEIGHT_DASHBOARD_ERROR,
    payload: {
      error,
    },
  }),
  getAnimalDailyWeightGainDashboard: (groupId, farmId, id) => ({
    type: Types.GET_ANIMAL_DAILY_WEIGHT_GAIN_DASHBOARD,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),
  getAnimalDailyWeightGainDashboardSuccess: (data) => ({
    type: Types.GET_ANIMAL_DAILY_WEIGHT_GAIN_DASHBOARD_SUCCESS,
    payload: {
      data,
    },
  }),
  getAnimalDailyWeightGainDashboardError: (error) => ({
    type: Types.GET_ANIMAL_DAILY_WEIGHT_GAIN_DASHBOARD_ERROR,
    payload: {
      error,
    },
  }),
  activateOrInactivateAnimal: (
    groupId,
    farmId,
    animalId,
    animal,
    operationAction
  ) => ({
    type: Types.ACTIVATE_INACTIVATE_ANIMAL,
    payload: {
      groupId,
      farmId,
      animalId,
      animal,
      operationAction,
    },
  }),
  activateOrInactivateAnimalSuccess: () => ({
    type: Types.ACTIVATE_INACTIVATE_ANIMAL_SUCCESS,
    payload: {},
  }),
  activateOrInactivateAnimalError: (error) => ({
    type: Types.ACTIVATE_INACTIVATE_ANIMAL_ERROR,
    payload: {
      error,
    },
  }),
  deleteAnimal: (groupId, farmId, id) => ({
    type: Types.DELETE_ANIMAL,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),
  deleteAnimalSuccess: () => ({
    type: Types.DELETE_ANIMAL_SUCCESS,
    payload: {},
  }),
  deleteAnimalError: (error) => ({
    type: Types.DELETE_ANIMAL_ERROR,
    payload: { error },
  }),
  resetAnimalTablePagination: () => ({
    type: Types.RESET_ANIMAL_TABLE_PAGINATION,
    payload: {},
  }),
  indexAnimalDailyCost: (groupId, farmId, animalId, limitDate) => ({
    type: Types.GET_ANIMAL_DAILY_COST,
    payload: {
      groupId,
      farmId,
      animalId,
      limitDate,
    },
  }),
  indexAnimalDailyCostSuccess: (data) => ({
    type: Types.GET_ANIMAL_DAILY_COST_SUCCESS,
    payload: { data },
  }),
  indexAnimalDailyCostError: (error) => ({
    type: Types.GET_ANIMAL_DAILY_COST_ERROR,
    payload: { error },
  }),
  indexAnimalWeaningWeightDashboard: (groupId, farmId, animalId) => ({
    type: Types.GET_ANIMAL_WEANING_WEIGHT_DASHBOARD,
    payload: {
      groupId,
      farmId,
      animalId,
    },
  }),
  indexAnimalWeaningWeightDashboardSuccess: (data) => ({
    type: Types.GET_ANIMAL_WEANING_WEIGHT_DASHBOARD_SUCCESS,
    payload: { data },
  }),
  indexAnimalWeaningWeightDashboardError: (error) => ({
    type: Types.GET_ANIMAL_WEANING_WEIGHT_DASHBOARD_ERROR,
    payload: { error },
  }),
  indexAnimalMotherDropdown: (groupId, farmId, parentId = null) => ({
    type: Types.GET_ANIMAL_MOTHER_DROPDOWN,
    payload: {
      groupId,
      farmId,
      parentId,
    },
  }),
  indexAnimalMotherDropdownSuccess: (data) => ({
    type: Types.GET_ANIMAL_MOTHER_DROPDOWN_SUCCESS,
    payload: { data },
  }),
  indexAnimalMotherDropdownError: (error) => ({
    type: Types.GET_ANIMAL_MOTHER_DROPDOWN_ERROR,
    payload: { error },
  }),
  indexAnimalFatherDropdown: (groupId, farmId, parentId = null) => ({
    type: Types.GET_ANIMAL_FATHER_DROPDOWN,
    payload: {
      groupId,
      farmId,
      parentId,
    },
  }),
  indexAnimalFatherDropdownSuccess: (data) => ({
    type: Types.GET_ANIMAL_FATHER_DROPDOWN_SUCCESS,
    payload: { data },
  }),
  indexAnimalFatherDropdownError: (error) => ({
    type: Types.GET_ANIMAL_FATHER_DROPDOWN_ERROR,
    payload: { error },
  }),
  indexAnimalLotWeightDashboard: (groupId, farmId, id) => ({
    type: Types.GET_ANIMAL_LOT_WEIGHT_DASHBOARD,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),
  indexAnimalLotWeightDashboardSuccess: (data) => ({
    type: Types.GET_ANIMAL_LOT_WEIGHT_DASHBOARD_SUCCESS,
    payload: { data },
  }),
  indexAnimalLotWeightDashboardError: (error) => ({
    type: Types.GET_ANIMAL_LOT_WEIGHT_DASHBOARD_ERROR,
    payload: { error },
  }),
  indexAnimalHandlingComments: (groupId, farmId, animalId) => ({
    type: Types.GET_ANIMAL_HANDLING_COMMENTS,
    payload: {
      groupId,
      farmId,
      animalId,
    },
  }),
  indexAnimalHandlingCommentsSuccess: (data) => ({
    type: Types.GET_ANIMAL_HANDLING_COMMENTS_SUCCESS,
    payload: { data },
  }),
  indexAnimalHandlingCommentsError: (error) => ({
    type: Types.GET_ANIMAL_HANDLING_COMMENTS_ERROR,
    payload: { error },
  }),
  indexAnimalPicketSupplementSupplies: (groupId, farmId, animalId) => ({
    type: Types.GET_ANIMAL_PICKET_SUPPLEMENT_SUPPLIES,
    payload: {
      groupId,
      farmId,
      animalId,
    },
  }),
  indexAnimalPicketSupplementSuppliesSuccess: (data) => ({
    type: Types.GET_ANIMAL_PICKET_SUPPLEMENT_SUPPLIES_SUCCESS,
    payload: { data },
  }),
  indexAnimalPicketSupplementSuppliesError: (error) => ({
    type: Types.GET_ANIMAL_PICKET_SUPPLEMENT_SUPPLIES_ERROR,
    payload: { error },
  }),
  resetReproductionIdentificationFilterAndSort: () => ({
    type: Types.RESET_REPRODUCTION_IDENTIFICATION_FILTER_AND_SORT,
    payload: {},
  }),
  resetFattenIdentificationFilterAndSort: () => ({
    type: Types.RESET_FATTEN_IDENTIFICATION_FILTER_AND_SORT,
    payload: {},
  }),
};

/* Initial State */
const INITIAL_STATE = {
  activeAnimals: null,
  activeLots: null,
  animalsByGender: null,
  isLoading: false,
  isLoadingAnimalWeightHistory: false,
  isLoadingAnimalDailyWeight: false,
  isLoadingAnimalDailyWeightGain: false,
  isLoadingAnimalCost: false,
  isLoadingAnimalHandlingComment: false,
  isLoadingAnimalPicketSupplementSupplies: false,
  isLoadingFemaleTable: false,
  isLoadingMaleTable: false,
  isLoadingIndexDashBoards: false,
  isAnimalWeaningWeightDashboardLoading: false,
  pagination: {
    page: 0,
    sorter: null,
    filters: null,
    size: 10,
    ids: null,
    tableSorters: null,
    tableFilters: null,
  },
  data: [],
  error: null,
  animalData: null,
  animalWeightHistoryData: {},
  animalsDailyWeight: [],
  animalsDailyWeightGain: [],
  animalsDailyWeightPageable: {},
  animalsDailyWeightGainPageable: {},
  animalTotalDailyWeightDashboard: [],
  animalGMDDashboard: [],
  animalDailyWeightDashboard: [],
  animalDailyWeightGainDashboard: [],
  drawerEditAnimalVisible: false,
  drawerNewAnimalVisible: false,
  animalEditId: null,
  reproductionFemaleReproductionEventData: [],
  reproductionFemaleData: [],
  reproductionMaleData: [],
  paginationFemaleReproductionEventTable: {
    page: 0,
    sorter: null,
    filters: null,
    size: 10,
    ids: null,
    tableSorters: null,
    tableFilters: null,
  },
  paginationFemaleTable: {
    page: 0,
    sorter: null,
    filters: null,
    size: 10,
    ids: null,
    tableSorters: null,
    tableFilters: null,
  },
  paginationMaleTable: {
    page: 0,
    sorter: null,
    filters: null,
    size: 10,
    ids: null,
    tableSorters: null,
    tableFilters: null,
  },
  numberFattenAnimals: null,
  numberReproductionAnimals: null,
  animalDailyCostData: [],
  animalWeaningWeightDashboard: [],
  animalFatherDropdown: [],
  animalMotherDropdown: [],
  animalLotDailyWeightDashboard: [],
  animalHandlingComments: [],
  animalPicketSupplementSupplies: [],
};

export default function animal(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.RESET_REPRODUCTION_IDENTIFICATION_FILTER_AND_SORT:
      return {
        ...state,
        paginationFemaleTable: {
          ...state.paginationFemaleTable,
          filters: `animal_farm_function='reproduction';gender='F';`,
          tableFilters: null,
        },
        paginationMaleTable: {
          ...state.paginationMaleTable,
          filters: `animal_farm_function='reproduction';gender='M';`,
          tableFilters: null,
        },
      };
    case Types.RESET_FATTEN_IDENTIFICATION_FILTER_AND_SORT:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          filters: null,
          tableFilters: null,
        },
      };
    case Types.GET_NUMBER_ANIMALS:
      return {
        ...state,
      };
    case Types.GET_NUMBER_ANIMALS_SUCCESS:
      const { numberFattenAnimals, numberReproductionAnimals } =
        action.payload.data;
      return {
        ...state,
        numberFattenAnimals,
        numberReproductionAnimals,
      };
    case Types.GET_NUMBER_ANIMALS_ERROR:
      return {
        ...state,
        numberFattenAnimals: null,
        numberReproductionAnimals: null,
      };
    case Types.GET_ANIMAL:
      const { page, sorter, filters, size, ids, tableFilters, tableSorters } =
        action.payload;
      return {
        ...state,
        pagination: {
          page,
          sorter,
          filters,
          size,
          ids,
          tableFilters,
          tableSorters,
        },
        isLoading: true,
      };
    case Types.GET_ANIMAL_SUCCESS:
      const { data } = action.payload.data;
      return {
        ...state,
        data,
        isLoading: false,
      };
    case Types.GET_ANIMAL_ERROR:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        data: null,
      };
    case Types.GET_ANIMAL_FEMALE_REPRODUCTION_EVENTS_TABLE:
      return {
        ...state,
        paginationFemaleReproductionEventTable: {
          page: action.payload.page,
          sorter: action.payload.sorter,
          filters: action.payload.filters,
          size: action.payload.size,
          ids: action.payload.ids,
          tableFilters: action.payload.tableFilters,
          tableSorters: action.payload.tableSorters,
        },
        isLoadingFemaleTable: true,
      };
    case Types.GET_ANIMAL_SUCCESS_FEMALE_REPRODUCTION_EVENTS_TABLE:
      const { reproductionFemaleReproductionEventData } = action.payload.data;
      return {
        ...state,
        reproductionFemaleReproductionEventData,
        isLoadingFemaleTable: false,
      };
    case Types.GET_ANIMAL_ERROR_FEMALE_REPRODUCTION_EVENTS_TABLE:
      return {
        ...state,
        error: action.payload.error,
        isLoadingFemaleTable: false,
        reproductionFemaleReproductionEventData: null,
      };
    case Types.GET_ANIMAL_FEMALE_TABLE:
      return {
        ...state,
        paginationFemaleTable: {
          page: action.payload.page,
          sorter: action.payload.sorter,
          filters: action.payload.filters,
          size: action.payload.size,
          ids: action.payload.ids,
          tableFilters: action.payload.tableFilters,
          tableSorters: action.payload.tableSorters,
        },
        isLoadingFemaleTable: true,
      };
    case Types.GET_ANIMAL_SUCCESS_FEMALE_TABLE:
      const { reproductionFemaleData } = action.payload.data;
      return {
        ...state,
        reproductionFemaleData,
        isLoadingFemaleTable: false,
      };
    case Types.GET_ANIMAL_ERROR_FEMALE_TABLE:
      return {
        ...state,
        error: action.payload.error,
        isLoadingFemaleTable: false,
        reproductionFemaleData: null,
      };
    case Types.GET_ANIMAL_MALE_TABLE:
      return {
        ...state,
        paginationMaleTable: {
          page: action.payload.page,
          sorter: action.payload.sorter,
          filters: action.payload.filters,
          size: action.payload.size,
          ids: action.payload.ids,
          tableFilters: action.payload.tableFilters,
          tableSorters: action.payload.tableSorters,
        },
        isLoadingMaleTable: true,
      };
    case Types.GET_ANIMAL_SUCCESS_MALE_TABLE:
      const { reproductionMaleData } = action.payload.data;
      return {
        ...state,
        reproductionMaleData,
        isLoadingMaleTable: false,
      };
    case Types.GET_ANIMAL_ERROR_MALE_TABLE:
      return {
        ...state,
        error: action.payload.error,
        isLoadingMaleTable: false,
        reproductionMaleData: null,
      };
    case Types.GET_ANIMAL_DETAILS:
      return { ...state, animalData: null, isLoading: true };
    case Types.GET_ANIMAL_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        animalData: action.payload.data,
      };
    case Types.GET_ANIMAL_DETAILS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        animalData: null,
      };
    case Types.GET_ANIMAL_WEIGHT_HISTORY:
      return { ...state, isLoadingAnimalWeightHistory: true };
    case Types.GET_ANIMAL_WEIGHT_HISTORY_SUCCESS:
      const { animalWeightHistoryData } = action.payload.data;
      return {
        ...state,
        animalWeightHistoryData,
        isLoadingAnimalWeightHistory: false,
      };
    case Types.GET_ANIMAL_WEIGHT_HISTORY_ERROR:
      return {
        ...state,
        error: action.payload.error,
        animalWeightHistoryData: {},
        isLoadingAnimalWeightHistory: false,
      };
    case Types.GET_ANIMAL_DAILY_WEIGHT:
      return { ...state, isLoading: true };
    case Types.GET_ANIMAL_DAILY_WEIGHT_PAGEABLE:
      return { ...state, isLoadingAnimalDailyWeight: true };
    case Types.GET_ANIMAL_DAILY_WEIGHT_SUCCESS:
      return {
        ...state,
        animalsDailyWeight: action.payload.data,
      };
    case Types.GET_ANIMAL_DAILY_WEIGHT_PAGEABLE_SUCCESS:
      return {
        ...state,
        animalsDailyWeightPageable: action.payload.data,
        isLoadingAnimalDailyWeight: false,
      };
    case Types.GET_ANIMAL_DAILY_WEIGHT_PAGEABLE_ERROR:
      return {
        ...state,
        animalsDailyWeightPageable: null,
        error: action.payload.error,
        isLoadingAnimalDailyWeight: false,
      };
    case Types.GET_ANIMAL_DAILY_WEIGHT_ERROR:
      return {
        ...state,
        error: action.payload.error,
        animalsDailyWeight: [],
        isLoadingAnimalDailyWeight: false,
      };
    case Types.GET_ANIMAL_DAILY_WEIGHT_GAIN:
      return { ...state };
    case Types.GET_ANIMAL_DAILY_WEIGHT_GAIN_PAGEABLE:
      return { ...state, isLoadingAnimalDailyWeightGain: true };
    case Types.GET_ANIMAL_DAILY_WEIGHT_GAIN_SUCCESS:
      return {
        ...state,
        animalsDailyWeightGain: action.payload.data,
      };
    case Types.GET_ANIMAL_DAILY_WEIGHT_GAIN_PAGEABLE_SUCCESS:
      return {
        ...state,
        animalsDailyWeightGainPageable: action.payload.data,
        isLoadingAnimalDailyWeightGain: false,
      };

    case Types.GET_ANIMAL_DAILY_WEIGHT_GAIN_ERROR:
      return {
        ...state,
        error: action.payload.error,
        animalsDailyWeightGain: [],
        isLoadingAnimalDailyWeightGain: false,
      };
    case Types.GET_ANIMAL_TOTAL_DAILY_WEIGHT_DASHBOARD:
      return { ...state, isLoading: true };
    case Types.GET_ANIMAL_TOTAL_DAILY_WEIGHT_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        animalTotalDailyWeightDashboard: action.payload.data,
      };
    case Types.GET_ANIMAL_GMD_DASHBOARD:
      return { ...state, isLoading: true };
    case Types.GET_ANIMAL_GMD_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        animalGMDDashboard: action.payload.data,
      };
    case Types.GET_ANIMAL_GMD_DASHBOARD_ERROR:
      return {
        ...state,
        isLoading: false,
        animalGMDDashboard: null,
        error: action.payload.error,
      };
    case Types.SHOW_DRAWER_EDIT_ANIMAL:
      return {
        ...state,
        drawerEditAnimalVisible: true,
        animalEditId: action.payload.id,
      };
    case Types.HIDE_DRAWER_EDIT_ANIMAL:
      return {
        ...state,
        drawerEditAnimalVisible: false,
        animalEditId: null,
      };
    case Types.SAVE_ANIMAL:
      return { ...state, isLoading: true };
    case Types.SAVE_ANIMAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        animalData: action.payload.data,
        drawerEditAnimalVisible: false,
        drawerNewAnimalVisible: action.payload.saveOptions,
      };
    case Types.SAVE_ANIMAL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        drawerEditAnimalVisible: false,
      };
    case Types.REFRESH_ANIMAL_DATA:
      return {
        ...state,
        animalData: { ...state.animalData, ...action.payload.data },
      };
    case Types.SHOW_HIDE_DRAWER_NEW_ANIMAL:
      return {
        ...state,
        drawerNewAnimalVisible: !state.drawerNewAnimalVisible,
      };
    case Types.GET_ANIMAL_DAILY_WEIGHT_DASHBOARD:
      return { ...state };
    case Types.GET_ANIMAL_DAILY_WEIGHT_DASHBOARD_SUCCESS:
      return {
        ...state,
        animalDailyWeightDashboard: action.payload.data,
      };
    case Types.GET_ANIMAL_DAILY_WEIGHT_DASHBOARD_ERROR:
      return {
        ...state,
        animalDailyWeightDashboard: [],
        error: action.payload.error,
      };
    case Types.GET_ANIMAL_DAILY_WEIGHT_GAIN_DASHBOARD:
      return { ...state };
    case Types.GET_ANIMAL_DAILY_WEIGHT_GAIN_DASHBOARD_SUCCESS:
      return {
        ...state,
        animalDailyWeightGainDashboard: action.payload.data,
      };
    case Types.GET_ANIMAL_DAILY_WEIGHT_GAIN_DASHBOARD_ERROR:
      return {
        ...state,
        animalDailyWeightGainDashboard: [],
        error: action.payload.error,
      };
    case Types.ACTIVATE_INACTIVATE_ANIMAL:
      return { ...state, isLoading: true };
    case Types.ACTIVATE_INACTIVATE_ANIMAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case Types.ACTIVATE_INACTIVATE_ANIMAL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case Types.DELETE_ANIMAL:
      return { ...state, isLoading: true };
    case Types.DELETE_ANIMAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case Types.DELETE_ANIMAL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case Types.RESET_ANIMAL_TABLE_PAGINATION:
      return {
        ...state,
        pagination: INITIAL_STATE.pagination,
      };
    case Types.GET_ANIMAL_DAILY_COST:
      return { ...state, isLoadingAnimalCost: true };
    case Types.GET_ANIMAL_DAILY_COST_SUCCESS:
      const { animalDailyCostData } =
        action.payload.data !== null ? action.payload.data : [];
      return {
        ...state,
        animalDailyCostData,
        isLoadingAnimalCost: false,
      };
    case Types.GET_ANIMAL_DAILY_COST_ERROR:
      return {
        ...state,
        error: action.payload.error,
        animalDailyCostData: [],
        isLoadingAnimalCost: false,
      };
    case Types.GET_ANIMAL_WEANING_WEIGHT_DASHBOARD:
      return { ...state, isAnimalWeaningWeightDashboardLoading: true };
    case Types.GET_ANIMAL_WEANING_WEIGHT_DASHBOARD_SUCCESS:
      const { animalWeaningWeightDashboard } =
        action.payload.data !== null ? action.payload.data : [];
      return {
        ...state,
        animalWeaningWeightDashboard,
        isAnimalWeaningWeightDashboardLoading: false,
      };
    case Types.GET_ANIMAL_WEANING_WEIGHT_DASHBOARD_ERROR:
      return {
        ...state,
        error: action.payload.error,
        animalWeaningWeightDashboard: [],
        isAnimalWeaningWeightDashboardLoading: false,
      };
    case Types.GET_ANIMAL_MOTHER_DROPDOWN:
      return { ...state };
    case Types.GET_ANIMAL_MOTHER_DROPDOWN_SUCCESS:
      const { motherDropdown } =
        action.payload.data !== null ? action.payload.data : [];
      return {
        ...state,
        animalMotherDropdown: motherDropdown,
      };
    case Types.GET_ANIMAL_MOTHER_DROPDOWN_ERROR:
      return {
        ...state,
        error: action.payload.error,
        animalMotherDropdown: [],
      };
    case Types.GET_ANIMAL_FATHER_DROPDOWN:
      return { ...state };
    case Types.GET_ANIMAL_FATHER_DROPDOWN_SUCCESS:
      const { fatherDropdown } =
        action.payload.data !== null ? action.payload.data : [];
      return {
        ...state,
        animalFatherDropdown: fatherDropdown,
      };
    case Types.GET_ANIMAL_FATHER_DROPDOWN_ERROR:
      return {
        ...state,
        error: action.payload.error,
        animalFatherDropdown: [],
      };
    case Types.GET_ANIMAL_LOT_WEIGHT_DASHBOARD:
      return { ...state };
    case Types.GET_ANIMAL_LOT_WEIGHT_DASHBOARD_SUCCESS:
      return {
        ...state,
        animalLotDailyWeightDashboard: action.payload.data,
      };
    case Types.GET_ANIMAL_LOT_WEIGHT_DASHBOARD_ERROR:
      return {
        ...state,
        error: action.payload.error,
        animalLotDailyWeightDashboard: [],
      };
    case Types.GET_ANIMAL_HANDLING_COMMENTS:
      return { ...state, isLoadingAnimalHandlingComment: true };
    case Types.GET_ANIMAL_HANDLING_COMMENTS_SUCCESS:
      const { commentsData } =
        action.payload.data !== null ? action.payload.data : [];
      return {
        ...state,
        animalHandlingComments: commentsData,
        isLoadingAnimalHandlingComment: false,
      };
    case Types.GET_ANIMAL_HANDLING_COMMENTS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        animalHandlingComments: [],
        isLoadingAnimalHandlingComment: false,
      };
    case Types.GET_ANIMAL_PICKET_SUPPLEMENT_SUPPLIES:
      return { ...state, isLoadingAnimalPicketSupplementSupplies: true };
    case Types.GET_ANIMAL_PICKET_SUPPLEMENT_SUPPLIES_SUCCESS:
      const { animalPicketSupplementSuppliesData } = action.payload.data ?? {};
      return {
        ...state,
        animalPicketSupplementSupplies:
          animalPicketSupplementSuppliesData ?? [],
        isLoadingAnimalPicketSupplementSupplies: false,
      };
    case Types.GET_ANIMAL_PICKET_SUPPLEMENT_SUPPLIES_ERROR:
      return {
        ...state,
        error: action.payload.error,
        animalPicketSupplementSupplies: [],
        isLoadingAnimalPicketSupplementSupplies: false,
      };
    default:
      return state;
  }
}
