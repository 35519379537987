import React from 'react';

// import { Container } from './styles';

const TrashIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16">
    <g id="Group_710" data-name="Group 710" transform="translate(-1312 -445)">
      <g id="unhover" transform="translate(1312 445)">
        <path
          id="Path_965"
          data-name="Path 965"
          d="M289.35,113.485h-.15a.159.159,0,0,0,.15-.165v.165h5.7v-.165a.159.159,0,0,0,.15.165h-.15v1.485h1.35v-1.65a1.266,1.266,0,0,0-1.2-1.32h-6a1.266,1.266,0,0,0-1.2,1.32v1.65h1.35Z"
          transform="translate(-284.699 -112)"
          fill="#d44c4c"
        />
        <path
          id="Path_966"
          data-name="Path 966"
          d="M142.375,256h-13.75a.63.63,0,0,0-.625.636v.635a.158.158,0,0,0,.156.159h1.18l.482,10.388a1.261,1.261,0,0,0,1.248,1.212h8.867a1.258,1.258,0,0,0,1.248-1.212l.482-10.388h1.18a.158.158,0,0,0,.156-.159v-.635A.63.63,0,0,0,142.375,256Zm-2.592,11.6h-8.566l-.473-10.169h9.512Z"
          transform="translate(-128 -253.029)"
          fill="#d44c4c"
        />
      </g>
    </g>
  </svg>
);
export default TrashIcon;
