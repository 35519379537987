export const Types = {
  RESET_DATA: "diet/RESET_DATA",
  GET_ALL_DIET: "diet/GET_ALL_DIET",
  GET_ALL_DIET_SUCCESS: "diet/GET_ALL_DIET_SUCCESS",
  GET_ALL_DIET_ERROR: "diet/GET_ALL_DIET_ERROR",
  GET_DIET: "diet/GET_DIET",
  GET_DIET_SUCCESS: "diet/GET_DIET_SUCCESS",
  GET_DIET_ERROR: "diet/GET_DIET_ERROR",
  SHOW_DRAWER_DIET: "diet/SHOW_DRAWER_DIET",
  HIDE_DRAWER_DIET: "diet/HIDE_DRAWER_DIET",
  SAVE_DIET: "diet/SAVE_DIET",
  SAVE_DIET_SUCCESS: "diet/SAVE_DIET_SUCCESS",
  SAVE_DIET_ERROR: "diet/SAVE_DIET_ERROR",
  DELETE_DIET: "diet/DELETE_DIET",
  DELETE_DIET_SUCCESS: "diet/DELETE_DIET_SUCCESS",
  DELETE_DIET_ERROR: "diet/DELETE_DIET_ERROR"
};

export const Creators = {
  indexDiet: (groupId, farmId, page = 0, sorter = {}, filters = "") => ({
    type: Types.GET_ALL_DIET,
    payload: {
      groupId,
      farmId,
      page,
      sorter,
      filters
    }
  }),
  indexDietSuccess: data => ({
    type: Types.GET_ALL_DIET_SUCCESS,
    payload: {
      data
    }
  }),
  indexDietError: error => ({
    type: Types.GET_ALL_DIET_ERROR,
    payload: {
      error
    }
  }),
  showDiet: (groupId, farmId, id) => ({
    type: Types.GET_DIET,
    payload: {
      groupId,
      farmId,
      id
    }
  }),
  showDietSuccess: data => ({
    type: Types.GET_DIET_SUCCESS,
    payload: {
      data
    }
  }),
  showDietError: error => ({
    type: Types.GET_DIET_ERROR,
    payload: {
      error
    }
  }),
  showDrawer: (actionType = "edit") => ({
    type: Types.SHOW_DRAWER_DIET,
    payload: {
      actionType
    }
  }),
  hideDrawer: () => ({
    type: Types.HIDE_DRAWER_DIET,
    payload: {}
  }),
  saveDiet: (groupId, farmId, id = null, diet, saveOptions) => ({
    type: Types.SAVE_DIET,
    payload: {
      groupId,
      farmId,
      id,
      diet,
      saveOptions
    }
  }),
  saveDietSuccess: (data, saveOptions) => ({
    type: Types.SAVE_DIET_SUCCESS,
    payload: { data, saveOptions }
  }),
  saveDietError: error => ({
    type: Types.SAVE_DIET_ERROR,
    payload: { error }
  })
};

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  dietData: null,
  drawerVisible: false,
  error: {}
};

export default function diet(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_ALL_DIET:
      return { ...state, isLoading: true };
    case Types.GET_ALL_DIET_SUCCESS:
      const { data: diets } = action.payload;
      return { ...state, isLoading: false, data: diets };
    case Types.GET_ALL_DIET_ERROR:
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: null,
        error
      };
    case Types.GET_DIET:
      return { ...state, isLoading: true };
    case Types.GET_DIET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dietData: action.payload.data
      };
    case Types.GET_DIET_ERROR:
      return {
        ...state,
        isLoading: false,
        dietData: null,
        error
      };
    case Types.SHOW_DRAWER_DIET:
      return {
        ...state,
        drawerVisible: true,
        dietData: action.payload.actionType === "new" ? null : state.dietData
      };
    case Types.HIDE_DRAWER_DIET:
      return { ...state, drawerVisible: false, dietData: null };
    case Types.SAVE_DIET:
      return { ...state, isLoading: true };
    case Types.SAVE_DIET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dietData: null,
        drawerVisible: action.payload.saveOptions
      };
    case Types.SAVE_DIET_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    case Types.DELETE_DIET:
      return { ...state, isLoading: true };
    case Types.DELETE_DIET_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case Types.DELETE_DIET_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}
