import React, { memo, useCallback, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";
import { Formik } from "formik";
import { REPRODUCTIVE_PROTOCOL_FORM_INITIAL_STATE } from "../../../contexts/reproductiveProtocolContext";
import useReproductiveProtocolContext from "../../../hooks/useReproductiveProtocolContext";
import { useSelector } from "react-redux";

// Components
import { Container, Footer } from "./styles";
import {
  Row,
  Col,
  Input,
  notification,
  Spin,
  Table,
  Icon,
  InputNumber,
} from "antd";
import ButtonStandard from "../../../components/utils/button";
import PencilIcon from "../../../components/utils/icons/pencil";
import TrashIcon from "../../../components/utils/icons/trash";
import {
  RadioButtonCustom,
  RadioGroupCustom,
} from "../../../components/drawers/supplier/styles";

// Services
import {
  save,
  saveForGroup,
} from "../../../services/reproductiveProtocolService";
import { handleKeyDownInputNumber } from "../../../utils/keyDownFunctions";

const validationSchema = Yup.object({
  name: Yup.string().trim().max(50).required(),
  duration: Yup.number().min(0).required(),
  applicationDays: Yup.array()
    .of(
      Yup.object().shape({
        orderApplication: Yup.number().min(1).required(),
        dayApplication: Yup.number().min(0).required(),
        product: Yup.string().required(),
      })
    )
    .compact((applicationDay) => applicationDay.product != null)
    .required(),
});

const ReproductiveProtocolApplicationDayForm = memo(
  ({ data, handleSave, handleCancel, isDrawerVisible }) => {
    const { translation } = useSelector((state) => state.app);
    const formikRef = useRef();
    const inputRef = useRef();
    const validationSchema = Yup.object({
      dayApplication: Yup.number().min(0).required(),
      product: Yup.string().required(),
    });
    const handleSubmit = async (values) => {
      try {
        await handleSave(values);
        formikRef.current.resetForm({
          orderApplication: null,
          dayApplication: null,
          product: null,
        });
      } catch (error) {}
    };
    const handleCloseDrawer = () => {
      handleCancel();
      formikRef.current.resetForm({
        orderApplication: null,
        dayApplication: null,
        product: null,
      });
    };
    function handleDrawerVisible(visible) {
      if (visible) {
        inputRef.current.focus();
        if (data === null) {
          formikRef.current.resetForm(REPRODUCTIVE_PROTOCOL_FORM_INITIAL_STATE);
        } else {
          formikRef.current.resetForm(data);
        }
      }
    }
    return (
      <Container
        title="Aplicação"
        width={400}
        visible={isDrawerVisible}
        afterVisibleChange={handleDrawerVisible}
        onClose={handleCloseDrawer}
        maskClosable={false}
      >
        <Formik
          ref={formikRef}
          initialValues={
            data || {
              orderApplication: null,
              dayApplication: null,
              product: null,
            }
          }
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          render={({
            values,
            setFieldValue,
            errors,
            submitCount,
            handleSubmit,
          }) => (
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className="drawerForm">
                {errors.length > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                {/* product */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={errors.product && submitCount > 0 ? "error" : ""}
                  >
                    {
                      translation.reproductiveProtocol.form.applicationDays
                        .product
                    }
                    *
                  </label>
                </Row>
                <Row type="flex">
                  <Input
                    ref={inputRef}
                    name="product"
                    value={values.product}
                    placeholder={translation.defaultPlaceholder}
                    autoCapitalize="off"
                    maxLength={50}
                    onChange={(e) => {
                      setFieldValue("product", e.target.value);
                    }}
                  />
                </Row>
                {/* dayApplication */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={
                      errors.dayApplication && submitCount > 0 ? "error" : ""
                    }
                  >
                    {
                      translation.reproductiveProtocol.form.applicationDays
                        .dayApplication
                    }
                    *
                  </label>
                </Row>
                <Row type="flex">
                  <InputNumber
                    name="dayApplication"
                    value={values.dayApplication}
                    placeholder={translation.defaultPlaceholder}
                    autoCapitalize="off"
                    max={100}
                    min={0}
                    onKeyDown={handleKeyDownInputNumber}
                    onChange={(value) => setFieldValue("dayApplication", value)}
                  />
                </Row>
              </div>
              <Footer>
                <Row type="flex">
                  <Col span={24} className="buttonsDiv">
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={handleCloseDrawer}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard type="submit" buttonType="type6">
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
      </Container>
    );
  }
);

const ReproductiveProtocolApplicationDaysTable = memo(
  ({ data = [], handleEdit, handleRemove }) => {
    const { translation } = useSelector((state) => state.app);
    return (
      <Table rowKey="id" dataSource={data} pagination={false} size="small">
        <Table.Column
          dataIndex="orderApplication"
          title={
            translation.reproductiveProtocol.form.applicationDays
              .orderApplication
          }
        />
        <Table.Column
          dataIndex="product"
          title={translation.reproductiveProtocol.form.applicationDays.product}
        />
        <Table.Column
          dataIndex="dayApplication"
          title={
            translation.reproductiveProtocol.form.applicationDays.dayApplication
          }
        />
        <Table.Column
          dataIndex="options"
          render={(_, record) => (
            <Row type="flex" justify="end" gutter={8}>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                type={"button"}
                onClick={() => handleEdit(record)}
              >
                <PencilIcon />
              </ButtonStandard>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                type={"button"}
                onClick={() => handleRemove(record)}
              >
                <TrashIcon />
              </ButtonStandard>
            </Row>
          )}
        />
      </Table>
    );
  }
);

const ReproductiveProtocolForm = () => {
  const formRef = useRef(null);
  const inputRef = useRef();

  const [form, setForm] = useState(REPRODUCTIVE_PROTOCOL_FORM_INITIAL_STATE);
  const [formApplicationDay, setFormApplicationDay] = useState({
    orderApplication: null,
    dayApplication: null,
    product: null,
  });
  const [isDrawerApplicationDayVisible, setIsDrawerApplicationDayVisible] =
    useState(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  // Redux Variable
  const {
    translation,
    groupSelected: { id: groupId },
    groupSelected,
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const {
    shouldBlockToOnlyGroupUnit,
    isDrawerVisible,
    data,
    closeDrawer,
    fetchData,
  } = useReproductiveProtocolContext();

  // Method

  function handleCloseDrawer() {
    setForm(REPRODUCTIVE_PROTOCOL_FORM_INITIAL_STATE);
    formRef.current.resetForm(REPRODUCTIVE_PROTOCOL_FORM_INITIAL_STATE);
    closeDrawer();
  }

  async function handleSubmitForm(values) {
    setIsLoadingRequest(true);
    try {
      if (values.farmId === null) {
        await saveForGroup({
          groupId: values.groupId,
          id: values?.id,
          body: values,
        });
      } else {
        await save({
          groupId: values.groupId,
          farmId: values.farmId,
          id: values?.id,
          body: values,
        });
      }
      notification.success({
        message: "Protocolo cadastrado/atualizado com sucesso.",
      });
      fetchData();
      handleCloseDrawer();
    } catch (error) {
      notification.error({
        message: "Erro",
        description: "Erro ao cadastrar/editar Protocolo",
      });
    } finally {
      setIsLoadingRequest(false);
    }
  }

  function handleDrawerVisible(visible) {
    if (visible) {
      inputRef.current.focus();
      if (data === null) {
        setForm({
          ...REPRODUCTIVE_PROTOCOL_FORM_INITIAL_STATE,
          groupId,
          farmId,
        });
        formRef.current.resetForm(REPRODUCTIVE_PROTOCOL_FORM_INITIAL_STATE);
      } else {
        setForm(data);
      }
    }
  }

  const handleEditApplicationDay = useCallback((applicationDay) => {
    setFormApplicationDay(applicationDay);
    setIsDrawerApplicationDayVisible(true);
  }, []);
  const handleRemoveApplicationDay = useCallback((applicationDay) => {
    const formValues = formRef.current.state.values;
    const applicationDays = formValues?.applicationDays;

    let order = 1;
    const updatedApplicationDaysArray = applicationDays
      .filter((ap) => ap.id !== applicationDay.id)
      .map((ap, index, newArray) => {
        ap.orderApplication = order;
        order++;
        return ap;
      });

    formRef.current.setFieldValue(
      "applicationDays",
      updatedApplicationDaysArray
    );

    let newDuration = null;
    if (updatedApplicationDaysArray.length > 0) {
      newDuration =
        updatedApplicationDaysArray[updatedApplicationDaysArray.length - 1]
          .dayApplication;
    }

    formRef.current.setFieldValue("duration", newDuration);
  }, []);
  const handleNewApplicationDay = useCallback(() => {
    setIsDrawerApplicationDayVisible(true);
    setFormApplicationDay({
      orderApplication: null,
      dayApplication: null,
      product: null,
    });
  }, []);
  const handleSaveApplicationDayForm = useCallback(
    (values) => {
      return new Promise((resolve, reject) => {
        const formValues = formRef.current.state.values;
        const applicationDays = formValues?.applicationDays;

        const lastApplicationDay =
          applicationDays.length === 0
            ? null
            : applicationDays[applicationDays.length - 1];
        const lastOrder =
          applicationDays.length === 0
            ? 0
            : applicationDays[applicationDays.length - 1].orderApplication;

        if (values.orderApplication) {
          const indexOfApplicationDay =
            values.id !== null
              ? applicationDays.findIndex((ap) => ap.id === values.id)
              : null;
          const previousApplicationDay =
            applicationDays.length === 1
              ? null
              : indexOfApplicationDay
              ? applicationDays[indexOfApplicationDay - 1]
              : null;

          const nextApplicationDay =
            applicationDays.length === 1
              ? null
              : applicationDays.length === indexOfApplicationDay + 1
              ? null
              : applicationDays[indexOfApplicationDay + 1];

          // Update application day
          // Check if the application day is less than the previous application day
          if (
            previousApplicationDay &&
            values.dayApplication < previousApplicationDay.dayApplication &&
            applicationDays.length > 1
          ) {
            notification.error({
              message:
                "A data de aplicação (D?) é menor que a da aplicação anterior, corrija para prosseguir.",
            });
            reject(
              "Error: A data de aplicação (D?) é menor que a da aplicação anterior, corrija para prosseguir."
            );
            return;
          }
          // Check if the application day is greater than the next application day
          if (
            nextApplicationDay &&
            values.dayApplication > nextApplicationDay.dayApplication
          ) {
            notification.error({
              message:
                "A data de aplicação (D?) é maior que a da aplicação posterior, corrija para prosseguir.",
            });
            reject(
              "Error: A data de aplicação (D?) é maior que a da aplicação posterior, corrija para prosseguir."
            );
            return;
          }

          const updatedApplicationDaysArray = applicationDays.map(
            (applicationDay) => {
              if (values.id === applicationDay.id) {
                return values;
              }
              return applicationDay;
            }
          );
          formRef.current.setFieldValue(
            "applicationDays",
            updatedApplicationDaysArray
          );
          if (values.orderApplication === lastOrder)
            formRef.current.setFieldValue("duration", values.dayApplication);
        } else {
          if (
            lastApplicationDay &&
            values.dayApplication < lastApplicationDay.dayApplication
          ) {
            notification.error({
              message:
                "A data de aplicação (D?) é menor que a da aplicação anterior, corrija para prosseguir.",
            });
            reject(
              "Error: A data de aplicação (D?) é menor que a da aplicação anterior, corrija para prosseguir."
            );
            return;
          }
          formRef.current.setFieldValue("applicationDays", [
            ...applicationDays,
            { ...values, id: uuid(), orderApplication: lastOrder + 1 },
          ]);
          formRef.current.setFieldValue("duration", values.dayApplication);
        }
        setIsDrawerApplicationDayVisible(false);
        setFormApplicationDay({
          orderApplication: null,
          dayApplication: null,
          product: null,
        });
        resolve(true);
      });
    },
    [formRef]
  );
  const handleCancelApplicationDayForm = useCallback((values) => {
    setIsDrawerApplicationDayVisible(false);
    setFormApplicationDay({
      orderApplication: null,
      dayApplication: null,
      product: null,
    });
  }, []);

  return (
    <Container
      title={
        data?.id == null
          ? translation.reproductiveProtocol.form.titleCreateNew
          : translation.reproductiveProtocol.form.titleEdit
      }
      width={700}
      onClose={handleCloseDrawer}
      maskClosable={false}
      visible={isDrawerVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Spin spinning={isLoadingRequest}>
        <Formik
          ref={formRef}
          enableReinitialize={true}
          initialValues={form}
          onSubmit={handleSubmitForm}
          validationSchema={validationSchema}
          render={({
            values,
            errors,
            submitCount,
            setFieldValue,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="drawerForm">
                {errors.length > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                <Row type="flex" align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label>
                        {
                          translation.reproductiveProtocol.form
                            .groupFarmVisibility
                        }
                      </label>
                    </Row>
                    <Row>
                      <RadioGroupCustom
                        value={
                          values.groupId != null && values.farmId != null
                            ? "farm"
                            : values.groupId != null && values.farmId == null
                            ? "group"
                            : "farm"
                        }
                        buttonStyle="solid"
                      >
                        <RadioButtonCustom
                          value="farm"
                          disabled={shouldBlockToOnlyGroupUnit}
                          onChange={(e) => {
                            setFieldValue("groupId", groupId);
                            setFieldValue("farmId", farmId);
                          }}
                        >
                          {translation.supplier.form.radiogroup.farm}
                        </RadioButtonCustom>
                        <RadioButtonCustom
                          value="group"
                          onChange={(e) => {
                            setFieldValue("groupId", groupId);
                            setFieldValue("farmId", null);
                          }}
                        >
                          {translation.supplement.form.radiogroup.group}:{" "}
                          {groupSelected.name}
                        </RadioButtonCustom>
                      </RadioGroupCustom>
                    </Row>
                  </Col>
                </Row>
                {/* Name */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={errors.name && submitCount > 0 ? "error" : ""}
                  >
                    {translation.reproductiveProtocol.form.name}*
                  </label>
                </Row>
                <Row type="flex">
                  <Input
                    ref={inputRef}
                    name="name"
                    value={values.name}
                    placeholder={translation.defaultPlaceholder}
                    autoCapitalize="off"
                    maxLength={50}
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                  />
                </Row>
                {/* duration */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={
                      errors.duration && submitCount > 0 ? "error" : ""
                    }
                  >
                    {translation.reproductiveProtocol.form.duration}*
                  </label>
                </Row>
                <Row type="flex">
                  <Input
                    name="duration"
                    value={values.duration}
                    placeholder={translation.defaultPlaceholder}
                    maxLength={30}
                    style={{ width: 200 }}
                    autoCapitalize="off"
                    disabled
                  />
                </Row>
                {/* new application day */}
                <Row
                  type="flex"
                  justify="end"
                  style={{
                    borderBottom: "2px solid #4b4b4b",
                    paddingBottom: 16,
                    margin: "8px 0px",
                  }}
                >
                  <ButtonStandard
                    buttonType="type8"
                    type="button"
                    onClick={handleNewApplicationDay}
                  >
                    <Icon type="plus" />{" "}
                    {translation.reproductiveProtocol.form.newApplicationDay}
                  </ButtonStandard>
                </Row>
                {/* Table Application Days */}
                <ReproductiveProtocolApplicationDaysTable
                  data={values.applicationDays || []}
                  handleEdit={handleEditApplicationDay}
                  handleRemove={handleRemoveApplicationDay}
                />
                {/* Observations */}
                <Row type="flex" className="rowLabel">
                  <label>
                    {translation.reproductiveProtocol.form.observations}
                  </label>
                </Row>
                <Row type="flex">
                  <Input
                    name="observations"
                    value={values.observations}
                    placeholder={translation.defaultPlaceholder}
                    autoCapitalize="off"
                    maxLength={250}
                    onChange={(e) => {
                      setFieldValue("observations", e.target.value);
                    }}
                  />
                </Row>
              </div>
              <Footer>
                <Row type="flex">
                  <Col span={24} className="buttonsDiv">
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={closeDrawer}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard type="submit" buttonType="type6">
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
      </Spin>
      <ReproductiveProtocolApplicationDayForm
        data={formApplicationDay}
        isDrawerVisible={isDrawerApplicationDayVisible}
        handleSave={handleSaveApplicationDayForm}
        handleCancel={handleCancelApplicationDayForm}
      />
    </Container>
  );
};

export default ReproductiveProtocolForm;
