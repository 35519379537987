import styled from "styled-components";
import { Modal, Select } from "antd";

export const CustomModal = styled(Modal)`
  position: relative !important;
  &.ant-modal {
    padding-bottom: 0px !important;
  }
  .ant-modal-body {
    padding: 0px !important;
  }
`;

export const Container = styled.div`
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;

  padding: 32px 20px 22px 25px;

  .ant-row-flex {
    width: 100% !important;
  }

  .ant-row-flex:last-child {
    margin-top: 5px !important;
  }

  button:not(:last-child) {
    margin-right: 14px;
  }

  label.formLabel {
    font-family: Asap;
    color: #4b4b4b;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;

    &.disabled {
      font-weight: bold;
      font-size: 14px;
      margin-left: 0px;
      color: #c4c4c4;
    }
    &.error {
      color: #d44c4c;
    }
  }

  .ant-inputs {
    margin-top: 13px;
  }

  .ant-select {
    margin-top: 13px;
    margin-bottom: 13px;
  }

  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 50px !important;
  }

  .ant-input-affix-wrapper .ant-input-prefix,
  .ant-input-affix-wrapper .ant-input-suffix {
    top: 60% !important;
    font-weight: bold !important;
  }
`;

export const Header = styled.div`
  margin-bottom: 9px;
`;

export const Title = styled.span`
  font-family: "Asap";
  font-weight: bold;
  font-size: 25px;
  text-align: left;
  color: #4b4b4b;
`;

export const SubTitle = styled.span`
  font-family: Asap;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  color: #4b4b4b;
`;

export const CustomSelect = styled(Select)`
  &.ant-select.ant-select-enabled {
    .ant-select-selection {
      border: 1px solid #684e94 !important;
      color: #684e94 !important;
    }
    .ant-select-arrow {
      color: #684e94 !important;
    }
    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
      color: #684e94 !important;
    }
  }
`;
