import React, { useMemo } from "react";
import { Route } from "react-router";
import { useSelector } from "react-redux";
import Error404 from "../../../pages/404";

/**
 * Props
 * * Component: Is the page Component
 * * Roles: Are the roles
 * * Reverse: If true we will return in isAllowed if the user have some role passed in props, if false we will return in isAllowed if the user don't have some role passed in props
 */

const AllowedRouteTo = ({
  component: Component,
  roles = [],
  reverse = false,
  ...restOfProps
}) => {
  const {
    user: { roles: userRoles },
  } = useSelector((state) => state.app);

  const isAllowed = useMemo(() => {
    return userRoles?.includes("Admin")
      ? true
      : roles.length === 0
      ? true
      : reverse === false
      ? roles.some((r) => userRoles?.includes(r))
      : !roles.some((r) => userRoles?.includes(r));
  }, [roles, reverse, userRoles]);

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAllowed ? <Component {...props} /> : <Route component={Error404} />
      }
    />
  );
};

export default AllowedRouteTo;
