import { Tabs } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import FinancialProject from "../financialProject";
import { Container } from "./styles";
import FinancialParametersCostCenterLayout from "./tabs/costCenter";
import CostingParameters from "./tabs/costing";
import FinancialParametersResultCenterLayout from "./tabs/resultCenter";
import GeneralFinancialParameters from "./tabs/generalFinancialParameters";

const FinancialParameters = () => {
  const { translation } = useSelector((state) => state.app);
  const [tabSelected, setTabSelected] = useState("0");

  return (
    <Container>
      <Tabs
        type="card"
        defaultActiveKey="0"
        activeKey={tabSelected}
        onChange={(key) => setTabSelected(key)}
      >
        <Tabs.TabPane
          tab={translation.financial.parameters.tabs.parameters}
          key="0"
        >
          <GeneralFinancialParameters />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={translation.financial.parameters.tabs.costing}
          key="1"
        >
          <CostingParameters />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={translation.financial.parameters.tabs.resultCenter}
          key="2"
        >
          <FinancialParametersResultCenterLayout />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={translation.financial.parameters.tabs.costCenter}
          key="3"
        >
          <FinancialParametersCostCenterLayout />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={translation.financial.parameters.tabs.financialProject}
          key="4"
        >
          <FinancialProject />
        </Tabs.TabPane>
      </Tabs>
    </Container>
  );
};

export default FinancialParameters;
