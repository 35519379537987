import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Libs
import { Col, Row, Skeleton, Spin, Tooltip } from "antd";
import Axios from "axios";
import moment from "moment";

// Components
import DrawerKpiWhatToDo from "../../../../components/drawers/kpiWhatToDo";
import InfoTooltip from "../../../../components/utils/infoTooltip";
import BarBenchmarkChart from "../../charts/barBenchmarkChart";
import {
  CardContainer,
  CardContainerWhatToDo,
  CardItemBottom,
  SelectCustom,
} from "../../styles";

//Contexts
import useKpiContext from "../../../../hooks/useKpiContext";

//Services
import { showBenchmarkForHome } from "../../../../services/benchmarkService";
import { numberMask } from "../../../../services/helpersMethodsService";
import { showReasonsByKpi } from "../../../../services/kpiService";

//Icons
import AlertIcon from "../../icons/alert";

const { Option } = SelectCustom;
const signal = Axios.CancelToken.source();

const KpiDetail = () => {
  const {
    groupId,
    farmId,
    kpiHarvest,
    productionSystem,
    productionSubSystem,
    selectedKpi,
  } = useKpiContext();

  const [drawerKpiWhatToDoIsVisible, setDrawerKpiWhatToDoIsVisible] =
    useState(false);
  const [isLoadingBenchmarks, setIsLoadingBenchmarks] = useState(false);
  const [isLoadingReasons, setIsLoadingReasons] = useState(false);
  const [kpiReasons, setKpiReasons] = useState([]);
  const [kpiBenchmarks, setKpiBenchmarks] = useState([]);
  const [selectedBenchmarks, setSelectedBenchmarks] = useState([]);
  const [selectedBenchmarksIds, setSelectedBenchmarksIds] = useState([]);
  const [benchmarkData, setBenchmarkData] = useState([]);

  const renderKpiName = useCallback((name) => {
    switch (name) {
      case "PEGADA_CARBONICA":
        return "PEGADA CARBÔNICA";
      case "DESEMBOLSO_CAB_MES":
        return "DESEMBOLSO CAB/MÊS";
      case "VALOR_DO_ATIVO":
        return "VALOR DO ATIVO";
      case "MARGEM_INCREMENTAL":
        return "MARGEM INCREMENTAL";
      default:
        return name;
    }
  }, []);

  // Fetch reasons
  useEffect(() => {
    async function fetchReasons() {
      try {
        setIsLoadingReasons(true);
        const { goalAchieved: isGoalAchieved, goalValue, kpiId } = selectedKpi;

        if (!isGoalAchieved && goalValue != null && goalValue > 0) {
          const {
            data: { results: kpiReasons },
          } = await showReasonsByKpi({
            groupId,
            farmId,
            kpiId,
            signal,
          });
          setKpiReasons(kpiReasons);
        } else {
          setKpiReasons([]);
        }
      } catch (error) {
        setKpiReasons([]);
      } finally {
        setIsLoadingReasons(false);
      }
    }

    if (selectedKpi != null) {
      fetchReasons();
    }
  }, [groupId, farmId, selectedKpi]);

  // Fetch benchmarks
  useEffect(() => {
    async function fetchBenchmarks() {
      try {
        setIsLoadingBenchmarks(true);
        const {
          data: { results },
        } = await showBenchmarkForHome({
          groupId,
          farmId,
          harvestId: kpiHarvest.id,
          signal,
        });
        if (results != null) {
          setKpiBenchmarks(results);
        }
        setIsLoadingBenchmarks(false);
      } catch (error) {
        setIsLoadingBenchmarks(false);
        setKpiBenchmarks(null);
        setSelectedBenchmarks([]);
      }
    }

    if (kpiHarvest != null) {
      fetchBenchmarks();
    }
  }, [groupId, farmId, kpiHarvest]);

  // Update benchmarks
  useEffect(() => {
    function updateBenchmarks() {
      let benchmarks = kpiBenchmarks.filter(
        (goal) =>
          goal.productionSystem === productionSystem &&
          goal.productionSubSystem === productionSubSystem &&
          goal.kpi.name === selectedKpi.name
      );
      if (benchmarks != null) {
        setSelectedBenchmarks(benchmarks);
        setSelectedBenchmarksIds([]);
        setBenchmarkData([]);
      }
    }

    if (
      productionSystem != null &&
      selectedKpi != null &&
      Array.isArray(kpiBenchmarks)
    ) {
      updateBenchmarks();
    }
  }, [kpiBenchmarks, productionSystem, productionSubSystem, selectedKpi]);

  //On select benchmarks
  const handleBenchmarkSelectChange = useCallback(
    (selectedBenchmarksIds) => {
      setSelectedBenchmarksIds(selectedBenchmarksIds);

      let i = 0;
      let newBenchmarkData = selectedBenchmarks
        .filter((bk) => selectedBenchmarksIds.includes(bk.id))
        .map((bk) => {
          const label = bk.benchmarkKpi.name;
          const x = i++;
          const y = bk.kpiValue;
          return { label, x, y };
        });

      setBenchmarkData(newBenchmarkData);
    },
    [selectedBenchmarks]
  );

  return (
    <>
      <Spin spinning={isLoadingBenchmarks || isLoadingReasons}>
        {/* Kpi Details */}
        <CardContainer>
          <Row type="flex" justify="space-between" align="middle">
            <label className="title-1">
              Clique em um <b> Indicadores (KPIs) </b>para analisá-lo:
              <span> {renderKpiName(selectedKpi?.name)} </span>
            </label>
          </Row>
          {selectedKpi === null ? (
            <Skeleton paragraph active />
          ) : (
            <>
              {!selectedKpi?.goalAchieved && kpiReasons?.length > 0 ? (
                <Row type="flex" justify="space-between" align="middle">
                  <CardContainerWhatToDo>
                    <div>
                      <label className="title-1">
                        <b>O QUE FAZER?</b>
                      </label>
                      {kpiReasons?.map((reason) =>
                        reason?.sugestions.map((sugestion) => (
                          <CardItemBottom key={sugestion?.id}>
                            <div>
                              <AlertIcon />
                              <Tooltip
                                placement="topLeft"
                                title={
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: sugestion.steps,
                                    }}
                                  />
                                }
                              >
                                <span>{sugestion.name}</span>
                              </Tooltip>
                            </div>
                          </CardItemBottom>
                        ))
                      )}
                    </div>
                    <span
                      className="text-purple"
                      onClick={() => setDrawerKpiWhatToDoIsVisible(true)}
                    >
                      Visualizar todos
                    </span>
                  </CardContainerWhatToDo>
                </Row>
              ) : null}
              <Row type="flex" justify="start" gutter={8}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <div className="card-1">
                    {selectedKpi?.history != null &&
                    selectedKpi?.name !== "EFICIENCIA REPRODUTIVA"
                      ? selectedKpi?.history.map((his) => (
                          <div key={his.name} className="item-2 flex">
                            <label>Safra {his.name}</label>
                            <div
                              className={
                                his.goalAchieved
                                  ? "text-color-green"
                                  : "text-color-red"
                              }
                            >
                              <label>
                                <b>
                                  {his?.kpiValue == null
                                    ? "- "
                                    : numberMask(
                                        his?.kpiValue || 0,
                                        his.isMonetary
                                      )}
                                </b>
                              </label>
                              <label> {his?.valueType}</label>
                            </div>
                          </div>
                        ))
                      : selectedKpi?.history.map((his) => (
                          <div
                            key={his.name}
                            style={{
                              borderBottom: "1px solid #c3c3c3",
                              marginBottom: 16,
                              paddingBottom: 8,
                            }}
                          >
                            <div className="item-2 flex">
                              <label>{his.name}</label>
                              <div
                                className={
                                  his.goalAchieved
                                    ? "text-color-green"
                                    : "text-color-red"
                                }
                              >
                                <label>
                                  <b>
                                    {his?.kpiValue == null
                                      ? "- "
                                      : numberMask(
                                          his?.kpiValue * 100 || 0,
                                          his.isMonetary
                                        )}
                                  </b>
                                </label>
                                <label> {his?.valueType}</label>
                              </div>
                            </div>
                            <div className="item-2 flex">
                              <span>{`TS: ${numberMask(
                                his?.parameters?.ServiceRate * 100 || 0,
                                false
                              )} %`}</span>
                              <span>{`TC: ${numberMask(
                                his?.parameters?.ConceptionRate * 100 || 0,
                                false
                              )} %`}</span>
                              <span>{`TP: ${numberMask(
                                his?.parameters?.PregnancyRate * 100 || 0,
                                false
                              )} %`}</span>
                            </div>
                            <div className="item-2 flex">
                              <span>{`TN: ${numberMask(
                                his?.parameters?.BirthRate * 100 || 0,
                                false
                              )} %`}</span>
                              <span>{`TD: ${numberMask(
                                his?.parameters?.WeaningRate * 100 || 0,
                                false
                              )} %`}</span>
                            </div>
                          </div>
                        ))}
                    <label className="sub-title">
                      meta{" "}
                      {moment(kpiHarvest?.startDate, "YYYY-MM-DD").format(
                        "YYYY"
                      )}
                      /
                      {moment(kpiHarvest?.endDate, "YYYY-MM-DD").format("YYYY")}
                    </label>
                    {selectedKpi?.goalValue != null &&
                    selectedKpi?.goalValue > 0 ? (
                      <>
                        <label className="sub-title-2">
                          {kpiHarvest?.startDate
                            ? moment(
                                kpiHarvest?.startDate,
                                "YYYY-MM-DD"
                              ).format("DD/MM/YYYY")
                            : ""}{" "}
                          -{" "}
                          {kpiHarvest?.endDate
                            ? moment(kpiHarvest?.endDate, "YYYY-MM-DD").format(
                                "DD/MM/YYYY"
                              )
                            : ""}
                        </label>

                        <div className="item-1 flex">
                          <label className="pad">Safra</label>
                          <div className="padGoal">
                            <label>
                              <b>
                                {selectedKpi?.name === "IEP"
                                  ? selectedKpi?.goalValue
                                  : numberMask(
                                      selectedKpi?.goalValue || 0,
                                      selectedKpi?.isMonetary
                                    )}
                              </b>
                            </label>
                            <label> {selectedKpi?.valueType}</label>
                          </div>
                        </div>
                        {selectedKpi?.isFractionalGoal ? (
                          <div className="item-1 flex">
                            <label className="pad">Fracionada</label>
                            <div className="padGoal">
                              <label>
                                <b>
                                  {selectedKpi?.name === "IEP"
                                    ? selectedKpi?.fractionalGoal
                                    : numberMask(
                                        selectedKpi?.fractionalGoal || 0,
                                        selectedKpi?.isMonetary
                                      )}
                                </b>
                              </label>
                              <label> {selectedKpi?.valueType}</label>
                            </div>
                          </div>
                        ) : null}

                        <label className="sub-title-2">
                          {moment(kpiHarvest?.startDate, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                          )}{" "}
                          {kpiHarvest?.current
                            ? "- hoje"
                            : moment(kpiHarvest?.endDate, "YYYY-MM-DD").format(
                                "DD/MM/YYYY"
                              )}
                        </label>
                      </>
                    ) : (
                      <>
                        <label className="text-red">META NÃO CADASTRADA</label>
                        <div className="item-1 flex">
                          <label className="text-info">
                            "Falta informar a Meta do Indicadores (KPIs) em
                            parâmetros"
                          </label>
                        </div>
                        <div className="item-1 flex">
                          <Link className="text-purple" to="/admin/parameters">
                            CLIQUE AQUI
                          </Link>
                        </div>
                      </>
                    )}
                    <label className="result">resultados</label>
                    <div className="item-1 flex-2">
                      <label className="pad">Atual</label>
                      {selectedKpi?.isFractionalGoal && (
                        <InfoTooltip title="Resultado atual é comparado com a 'Safra fracionada'" />
                      )}
                      <div
                        className={
                          selectedKpi?.goalAchieved
                            ? "text-color-green"
                            : selectedKpi?.isComplete
                            ? "text-color-red"
                            : "padGoal"
                        }
                      >
                        <label>
                          <b>
                            {Number(selectedKpi?.kpiValue) == null
                              ? "- "
                              : selectedKpi?.name === "IEP"
                              ? Number(selectedKpi?.kpiValue)
                              : selectedKpi?.name === "EFICIENCIA REPRODUTIVA"
                              ? numberMask(
                                  Number(selectedKpi?.kpiValue * 100) || 0,
                                  selectedKpi?.isMonetary
                                )
                              : numberMask(
                                  Number(selectedKpi?.kpiValue) || 0,
                                  selectedKpi?.isMonetary
                                )}
                          </b>
                        </label>
                        <label> {selectedKpi?.valueType}</label>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                  <div className="card-2">
                    <label className="sub-title">benchmark</label>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} align="center">
                      <BarBenchmarkChart
                        benchmarkDashboard={benchmarkData}
                        currentValue={Number(selectedKpi?.kpiValue)}
                      />
                    </Col>
                  </div>

                  <div>
                    <label className="title-2">
                      Clique aqui para comparar outros resultados
                    </label>
                  </div>

                  <SelectCustom
                    mode="multiple"
                    placeholder=""
                    defaultValue={[]}
                    value={selectedBenchmarksIds}
                    onChange={(value) => handleBenchmarkSelectChange(value)}
                    optionLabelProp="label"
                    style={{ width: "97%" }}
                  >
                    {selectedBenchmarks &&
                      selectedBenchmarks.map((bk) => (
                        <Option
                          key={bk.id}
                          value={bk.id}
                          label={bk.benchmarkKpi.name}
                        >
                          <div className="demo-option-label-item">
                            {bk.benchmarkKpi.name}
                          </div>
                        </Option>
                      ))}
                  </SelectCustom>
                </Col>
              </Row>
            </>
          )}

          <DrawerKpiWhatToDo
            visible={drawerKpiWhatToDoIsVisible}
            onCloseDrawer={() => setDrawerKpiWhatToDoIsVisible(false)}
            data={kpiReasons}
          />
        </CardContainer>
      </Spin>
    </>
  );
};

export default KpiDetail;
