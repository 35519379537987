import React from "react";
import { Container, Grid } from "./styles";
import { Col, Row } from "antd";

function CardBankAccountTransactions() {
  return (
    <Container>
      <Grid>
        <Row type="flex" className="header">
          <Col span={24}>
            <strong>MOVTO CONTA BANCO</strong>
          </Col>
        </Row>
        {/* SALDO PERÍODO ANTERIOR */}
        <Row type="flex" className="grid-row">
          <Col className="grid-row-col-title" span={12}>
            <strong>SALDO PERÍODO ANTERIOR</strong>
          </Col>
          <Col className="grid-row-col-value" span={12}>
            <strong>-</strong>
          </Col>
        </Row>
        {/* ENTRADAS */}
        <Row type="flex" className="grid-row sub">
          <Col className="grid-row-col-title" span={12}>
            <strong>ENTRADAS</strong>
          </Col>
          <Col className="grid-row-col-value" span={12}>
            <strong>-</strong>
          </Col>
        </Row>
        {/* SAÍDAS */}
        <Row type="flex" className="grid-row sub">
          <Col className="grid-row-col-title" span={12}>
            <strong>SAÍDAS</strong>
          </Col>
          <Col className="grid-row-col-value" span={12}>
            <strong>-</strong>
          </Col>
        </Row>
        {/* SALDO PARCIAL PERÍODO */}
        <Row type="flex" className="grid-row">
          <Col className="grid-row-col-title" span={12}>
            <strong>SALDO PARCIAL PERÍODO</strong>
          </Col>
          <Col className="grid-row-col-value" span={12}>
            <strong>-</strong>
          </Col>
        </Row>
        {/* SALDO ATUAL */}
        <Row type="flex" className="grid-row">
          <Col className="grid-row-col-title" span={12}>
            <strong>SALDO ATUAL</strong>
          </Col>
          <Col className="grid-row-col-value" span={12}>
            <strong>-</strong>
          </Col>
        </Row>
        {/* SALDO PREVISTO PERÍODO */}
        <Row type="flex" className="grid-row">
          <Col className="grid-row-col-title" span={12}>
            <strong>SALDO PREVISTO PERÍODO</strong>
          </Col>
          <Col className="grid-row-col-value" span={12}>
            <strong>-</strong>
          </Col>
        </Row>
      </Grid>
    </Container>
  );
}

export default CardBankAccountTransactions;
