import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  flex-direction: column;

  .spinAdmin {
    height: 100vh;
    min-height: 100vh;
  }

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
  }
`;
export const Header = styled.nav`
  width: 100%;
  display: flex;
  height: 100%;
  height: 55px;
  flex-direction: row;
  align-items: center;
  background-image: linear-gradient(-90deg, #34274a, #684e94);
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
  }
`;

export const LogoContainer = styled.button`
  height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.collapse ? "67px" : "175px")} !important;
  transition: all 0.5s ease-in-out;
  background: #fff;
  border: none;
  cursor: pointer;

  svg {
    max-width: 196px;
    max-height: 35px;
  }

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 769px) {
    /* start of medium tablet styles */
    display: none;
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
    display: none;
  }
`;
export const Container = styled.div`
  display: flex;
  width: 100%;
  height: -moz-calc(100vh - 55px);
  height: -webkit-calc(100vh - 55px);
  height: calc(100vh - 55px);

  min-height: -moz-calc(100vh - 55px);
  min-height: -webkit-calc(100vh - 55px);
  min-height: calc(100vh - 55px);

  overflow-y: hidden;
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
  }
`;
export const Main = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 0 10px 0 10px;
  overflow-y: auto;
  overflow-x: hidden;
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
  }
`;