import React, { useCallback, useEffect } from "react";
import useAnimalWeightHistoryContext from "../../../hooks/useAnimalWeightHistoryContext";
import { useSelector } from "react-redux";
import moment from "moment";
import { Async } from "react-async";

import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Icon,
  Input,
  InputNumber,
  Menu,
  Row,
  Select,
  Table,
  notification,
} from "antd";
import { Container as MenuContainer } from "../../../components/utils/table/menu/styles";
import MenuIcon from "../../../components/utils/table/icons/menu";

// Service
import { numberMask } from "../../../services/helpersMethodsService";
import {
  deleteAnimalsWeightHistories,
  verifyCanEditAndDelete,
} from "../../../services/animalWeightHistoryService";
import InfoTooltip from "../../../components/utils/infoTooltip";
import CustomLink from "../../../components/utils/customLink";

const TableAnimalWeightHistory = () => {
  const {
    handleTableChange,
    fetchData,
    initializeData,
    openOrCloseForm,
    dataList,
    isLoadingDataList,
    tableProperties: { size, page, search, tableFilters, tableSorters },
  } = useAnimalWeightHistoryContext();

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { Column } = Table;

  const handleSearch = useCallback((selectedKeys, confirm) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleEdit = (record) => {
    initializeData(record);
    openOrCloseForm();
  };

  const handleDelete = useCallback(
    async (id) => {
      try {
        await deleteAnimalsWeightHistories({
          groupId,
          farmId,
          id,
        });
        fetchData(page, size, {}, search);
        notification.success({ message: "Pesagem deletada com sucesso!" });
      } catch (error) {
        notification.error({
          message: "Pesagem não foi deletada. Contate o suporte.",
        });
      }
    },
    [farmId, fetchData, groupId, page, search, size]
  );

  const getColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys && selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
    }),
    [handleReset, handleSearch]
  );

  const getColumnDateSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Row type="flex" gutter={4}>
            <Col>
              <Select
                value={selectedKeys ? selectedKeys[1] : "equal"}
                onChange={(value) =>
                  setSelectedKeys(
                    value
                      ? selectedKeys
                        ? [selectedKeys[0], value]
                        : [null, value]
                      : []
                  )
                }
              >
                <Select.Option value="equal">=</Select.Option>
                <Select.Option value="greaterThan">&gt;</Select.Option>
                <Select.Option value="greaterOrEqualThan">&ge;</Select.Option>
                <Select.Option value="lessThan">&lt;</Select.Option>
                <Select.Option value="lessOrEqualThan">&le;</Select.Option>
              </Select>
            </Col>
            <Col>
              <DatePicker
                value={selectedKeys && selectedKeys[0]}
                format={"DD/MM/YYYY"}
                onChange={(date) =>
                  setSelectedKeys(
                    date
                      ? selectedKeys && selectedKeys.length > 1
                        ? [date, selectedKeys[1]]
                        : [date, "equal"]
                      : []
                  )
                }
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </Col>
          </Row>

          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
    }),
    [handleReset, handleSearch]
  );

  const getColumnNumberSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Row type="flex" gutter={4}>
            <Col>
              <Select
                value={selectedKeys ? selectedKeys[1] : "equal"}
                onChange={(value) =>
                  setSelectedKeys(
                    value
                      ? selectedKeys
                        ? [selectedKeys[0], value]
                        : [null, value]
                      : []
                  )
                }
              >
                <Select.Option value="equal">=</Select.Option>
                <Select.Option value="greaterThan">&gt;</Select.Option>
                <Select.Option value="greaterOrEqualThan">&ge;</Select.Option>
                <Select.Option value="lessThan">&lt;</Select.Option>
                <Select.Option value="lessOrEqualThan">&le;</Select.Option>
              </Select>
            </Col>
            <Col>
              <InputNumber
                value={selectedKeys && selectedKeys[0]}
                min={0}
                precision={2}
                step={0.01}
                onChange={(value) =>
                  setSelectedKeys(
                    value
                      ? selectedKeys && selectedKeys.length > 1
                        ? [value, selectedKeys[1]]
                        : [value, "equal"]
                      : []
                  )
                }
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </Col>
          </Row>

          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
    }),
    [handleReset, handleSearch]
  );

  const menu = (id, record) => {
    return (
      <Menu>
        <Menu.Item onClick={() => handleEdit(record)}>
          {translation.table.menu.edit}
        </Menu.Item>
        <Menu.Item onClick={() => handleDelete(id)}>
          {translation.table.menu.delete}
        </Menu.Item>
      </Menu>
    );
  };

  useEffect(() => {
    handleTableChange();
  }, [handleTableChange]);

  return (
    <Table
      rowKey="id"
      loading={isLoadingDataList}
      dataSource={dataList != null ? dataList.content : []}
      pagination={{
        total: dataList != null ? dataList.totalElements : 0,
        size: dataList != null ? size : 0,
        current: dataList != null ? page : 0,
        hideOnSinglePage:
          dataList !== null && Object.entries(dataList).length !== 0
            ? dataList.totalElements > 10
              ? false
              : true
            : true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
      }}
      scroll={{
        x: 1200,
      }}
      onChange={handleTableChange}
    >
      <Column
        title={translation.animalWeightHistory.table.columns.origination}
        dataIndex="origination"
        sorter
        key="origination"
        filteredValue={tableFilters?.origination || null}
        align="left"
        width={200}
        sortOrder={
          tableSorters?.columnKey === "origination" && tableSorters.order
        }
        filtered
        filters={[
          {
            text: "Manual",
            value: "manual",
          },
          {
            text: "BovManager",
            value: "bovManager",
          },
          {
            text: "Balança",
            value: "balance",
          },
          {
            text: "Importação",
            value: "import",
          },
          {
            text: "ODD",
            value: "ODD",
          },
        ]}
        filterMultiple={true}
        render={(text) => (
          <span>
            {text === "Manual"
              ? "Manual"
              : text === "BovManager"
              ? "BovManager"
              : text === "Balance"
              ? "Balança"
              : text === "Import"
              ? "Importação"
              : text === "ODD"
              ? "ODD"
              : "-"}
          </span>
        )}
      />
      <Column
        title={translation.animalWeightHistory.table.columns.handlingNumber}
        dataIndex="handlingNumber"
        sorter
        key="handlingNumber"
        filteredValue={tableFilters?.handlingNumber || null}
        align="left"
        width={200}
        sortOrder={
          tableSorters?.columnKey === "handlingNumber" && tableSorters.order
        }
        {...getColumnSearchProps("handlingNumber")}
        render={(text, record) => (
          <CustomLink to={`/admin/animals/${record.animalId}`}>
            {text}
          </CustomLink>
        )}
      />
      <Column
        title={translation.animalWeightHistory.table.columns.date}
        dataIndex="date"
        sorter
        key="date"
        filteredValue={tableFilters?.date || null}
        align="left"
        width={200}
        sortOrder={tableSorters?.columnKey === "date" && tableSorters.order}
        {...getColumnDateSearchProps("date")}
        render={(text) => <span>{`${moment(text).format("DD/MM/YYYY")}`}</span>}
      />
      <Column
        title={translation.animalWeightHistory.table.columns.weight}
        dataIndex="weight"
        sorter
        key="weight"
        filteredValue={tableFilters?.weight || null}
        align="left"
        width={200}
        sortOrder={tableSorters?.columnKey === "weight" && tableSorters.order}
        {...getColumnNumberSearchProps("weight")}
        render={(text) => <span>{`${numberMask(text, false)} kg`}</span>}
      />
      <Column
        title={
          <span>
            {translation.animalWeightHistory.table.columns.originationLotName}{" "}
            <InfoTooltip title="Lote em que o animal estava no momento da pesagem." />
          </span>
        }
        dataIndex="originationLotName"
        sorter
        key="originationLotName"
        filteredValue={tableFilters?.originationLotName || null}
        align="left"
        width={200}
        sortOrder={
          tableSorters?.columnKey === "originationLotName" && tableSorters.order
        }
        {...getColumnSearchProps("originationLotName")}
      />
      <Column
        title={
          <span>
            {
              translation.animalWeightHistory.table.columns
                .originationPicketName
            }{" "}
            <InfoTooltip title="Piquete em que o animal estava no momento da pesagem." />
          </span>
        }
        dataIndex="originationPicketName"
        sorter
        key="originationPicketName"
        filteredValue={tableFilters?.originationPicketName || null}
        align="left"
        width={200}
        sortOrder={
          tableSorters?.columnKey === "originationPicketName" &&
          tableSorters.order
        }
        {...getColumnSearchProps("originationPicketName")}
      />
      <Column
        title={
          <span>{translation.animalWeightHistory.table.columns.event}</span>
        }
        dataIndex="originEvent"
        sorter
        key="originEvent"
        filteredValue={tableFilters?.originEvent || null}
        align="left"
        width={200}
        sortOrder={
          tableSorters?.columnKey === "originEvent" && tableSorters.order
        }
        filtered
        filters={[
          {
            text: translation.animalWeightHistory.originEvents.birthday,
            value: "birthday",
          },
          {
            text: translation.animalWeightHistory.originEvents.sale,
            value: "sale",
          },
          {
            text: translation.animalWeightHistory.originEvents.weaning,
            value: "weaning",
          },
          {
            text: translation.animalWeightHistory.originEvents.purchase,
            value: "purchase",
          },
        ]}
        filterMultiple={false}
        render={(text) => (
          <span>
            {text !== "weighing"
              ? translation.animalWeightHistory.originEvents[text]
              : ""}
          </span>
        )}
      />
      <Column
        align="left"
        width={50}
        render={(text, record) => (
          <Async
            promiseFn={verifyCanEditAndDelete}
            payload={{
              groupId,
              farmId,
              id: record.id,
            }}
          >
            <Async.Pending>
              <Icon type="loading" />
            </Async.Pending>
            <Async.Fulfilled>
              {(resp) =>
                resp.data.results ? (
                  <Dropdown
                    overlay={menu(record.id, record)}
                    trigger={["click"]}
                    key={record.id}
                  >
                    <MenuContainer>
                      <MenuIcon />
                    </MenuContainer>
                  </Dropdown>
                ) : null
              }
            </Async.Fulfilled>
          </Async>
        )}
      />
    </Table>
  );
};
export default TableAnimalWeightHistory;
