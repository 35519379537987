import React from 'react';

// import { Container } from './styles';

const BigPencilIcon = ( {color} ) => (
<svg xmlns="http://www.w3.org/2000/svg" width="15.002" height="14.929" viewBox="0 0 15.002 14.929">
  <g id="Btn_editar" data-name="Btn editar" transform="translate(-519.108 -268.908)">
    <g id="unhover" transform="translate(-159.35 -58.35)">
      <g id="pencil-edit-button" transform="translate(678.457 327.258)">
        <path id="Path_747" data-name="Path 747" d="M9.329,3.78l3.052,3.052L4.656,14.557l-3.05-3.052ZM14.7,3.044,13.336,1.683a1.351,1.351,0,0,0-1.908,0l-1.3,1.3,3.052,3.052L14.7,4.518A1.04,1.04,0,0,0,14.7,3.044ZM.009,15.795a.347.347,0,0,0,.42.413l3.4-.825L.78,12.332Z" transform="translate(-0.001 -1.289)" fill={color}/>
      </g>
    </g>
  </g>
</svg>
);
export default BigPencilIcon;