import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";

//Libs
import moment from "moment";

// Components
import { Row, Col, Table, Input, notification, DatePicker, Radio } from "antd";
import ButtonStandard from "../../../components/utils/button";
import InputStandard from "../../../components/utils/input";
import Loading from "../../../components/utils/loading";
import ArrowIcon from "../../../components/utils/icons/arrows/red/left";

// Styles
import { Container } from "./styles";

//Services
import {
  showBoitelPrices,
  saveBoitelPrices,
} from "../../../services/boitelPriceService";
import { getTwoDecimalDigits } from "../../../services/helpersMethodsService";

const BoitelPriceEdit = (props) => {
  // Variable Redux
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const { id: boitelPriceId } = props.match.params;
  const history = useHistory();
  const dateFormat = "DD/MM/YYYY";

  const Column = Table.Column;
  const { TextArea } = Input;
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [errorsDailyValueCut, setErrorsDailyValueCut] = useState([]);
  const [errorsDailyValueAfterCut, setErrorsDailyValueAfterCut] = useState([]);
  const [errorsDailyValueMixedMilk, setErrorsDailyValueMixedMilk] = useState(
    []
  );
  const [errorsDailyValueAfterMixedMilk, setErrorsDailyValueAfterMixedMilk] =
    useState([]);
  const [errorsLimitTime, setErrorsLimitTime] = useState([]);

  const [form, setForm] = useState({
    name: "",
    inicialValidity: "",
    finalValidity: "",
    inputProtocol: "",
    dailySurchase: 20,
    useDailyPurchase: true,
  });

  const [weightValues, setWeightValues] = useState([]);

  useEffect(() => {
    async function loadData() {
      try {
        const {
          data: { results },
        } = await showBoitelPrices({ groupId, farmId, boitelPriceId });

        setData(results);
        setForm({ ...results, validityDescription: "" });
        setIsLoading(false);
      } catch (error) {
        notification.error({
          message:
            "Ocorreu um erro ao acessar as informações de preços dos boiteis.",
        });
      }
    }

    loadData();
  }, [groupId, farmId, boitelPriceId]);

  async function handleSubmitForm() {
    setIsLoading(true);
    if (validateForm()) {
      try {
        const {
          data: { results },
        } = await saveBoitelPrices({
          groupId,
          farmId,
          boitelPriceId,
          body: form,
        });

        setData(results);
        setForm(results);
        setWeightValues(results.weightValues);
        setIsLoading(false);
        notification.success({
          message: "Informações de preços atualizadas com sucesso.",
        });
        history.push("/admin/parameters", { tab: "8", subTab: "2" });
      } catch (error) {
        setIsLoading(false);
        notification.error({
          message: "Ocorreu um erro ao salvar as informações de preços.",
        });
      }
    } else {
      setIsLoading(false);
    }
  }

  function validateForm() {
    let errorsArray = [];
    let errorsDailyValueCut = [];
    let errorsDailyValueAfterCut = [];
    let errorsDailyValueMixedMilk = [];
    let errorsDailyValueAfterMixedMilk = [];
    let errorsLimitTime = [];

    if (!form.name || form.name === "") {
      errorsArray.push("name");
    }
    if (!form.validityDescription || form.validityDescription === "") {
      errorsArray.push("validityDescription");
    }
    if (!form.initialValidity) {
      errorsArray.push("initialValidity");
    }
    if (!form.finalValidity) {
      errorsArray.push("finalValidity");
    }
    if (!form.dailySurchase || form?.dailySurchase <= 0) {
      errorsArray.push("dailySurchase");
    }
    if (!form.inputProtocol || form?.inputProtocol <= 0) {
      errorsArray.push("inputProtocol");
    }

    if (form?.weightValues) {
      form.weightValues.map((w) => {
        if (!w.dailyValueCut || w.dailyValueCut <= 0) {
          errorsDailyValueCut.push(w.id);
          if (!errorsArray.includes("dailyValueCut")) {
            errorsArray.push("dailyValueCut");
          }
        }
        if (!w.dailyValueAfterCut || w.dailyValueAfterCut <= 0) {
          errorsDailyValueAfterCut.push(w.id);
          if (!errorsArray.includes("dailyValueAfterCut")) {
            errorsArray.push("dailyValueAfterCut");
          }
        }

        if (!w.dailyValueMixedMilk || w.dailyValueMixedMilk <= 0) {
          errorsDailyValueMixedMilk.push(w.id);
          if (!errorsArray.includes("dailyValueMixedMilk")) {
            errorsArray.push("dailyValueMixedMilk");
          }
        }
        if (!w.dailyValueAfterMixedMilk || w.dailyValueAfterMixedMilk <= 0) {
          errorsDailyValueAfterMixedMilk.push(w.id);
          if (!errorsArray.includes("dailyValueAfterMixedMilk")) {
            errorsArray.push("dailyValueAfterMixedMilk");
          }
        }

        if (!w.limitTime || w.limitTime <= 0) {
          errorsLimitTime.push(w.id);
          if (!errorsArray.includes("limitTime")) {
            errorsArray.push("limitTime");
          }
        }
      });
    }

    setErrors([...errorsArray]);
    setErrorsDailyValueCut([...errorsDailyValueCut]);
    setErrorsDailyValueAfterCut([...errorsDailyValueAfterCut]);
    setErrorsDailyValueMixedMilk([...errorsDailyValueMixedMilk]);
    setErrorsDailyValueAfterMixedMilk([...errorsDailyValueAfterMixedMilk]);
    setErrorsLimitTime([...errorsLimitTime]);

    if (errorsArray.length > 0) {
      return false;
    }
    return true;
  }

  function handleWeightValueUpdate(name, value, id) {
    form.weightValues.map((w) => {
      if (w.id === id) {
        w[name] = value;
      }
    });
  }

  function handleInputChange(name, value) {
    setForm({
      ...form,
      [name]: value,
    });
  }

  const dailySurchaseLimit = 100.0;
  const maxValue = 10000;
  const dailySurchaseLimitValidator = ({ floatValue }) =>
    floatValue ? floatValue <= dailySurchaseLimit : true;
  const monetaryLimitValidator = ({ floatValue }) =>
    floatValue ? floatValue < maxValue : true;

  return (
    <Container>
      {isLoading && <Loading />}
      <Row type="flex" justify="space-between" align="middle">
        <Col span={3}>
          <div
            className="back-container back"
            onClick={() =>
              history.push("/admin/parameters", { tab: "8", subTab: "2" })
            }
          >
            <ArrowIcon />
            <span className="back">Voltar</span>
          </div>
        </Col>
        {errors.length > 0 ? (
          <Col span={6}>
            <label className="error">
              Preencha os campos marcados com (*) corretamente.
            </label>
          </Col>
        ) : null}
        <Col span={3}>
          <ButtonStandard
            type="button"
            buttonType="type1"
            width="121px"
            height="35px"
            padding="5px 10px 5px 10px"
            onClick={handleSubmitForm}
          >
            Atualizar
          </ButtonStandard>
        </Col>
      </Row>

      <Row type="flex" align="middle" className="row-field">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Row type="flex" align="middle">
            <Col xs={19} sm={19} md={19} lg={19} xl={19}>
              <Row>
                <label className={errors.includes("name") ? "error" : ""}>
                  Nome *
                </label>
              </Row>
              <Row>
                <InputStandard
                  border={errors.includes("name") ? "error" : "normal"}
                  width="100%"
                  className="input-small"
                >
                  <input
                    type="text"
                    placeholder="Informe aqui..."
                    name="name"
                    value={form?.name}
                    autoComplete="chrome-off"
                    onChange={(event) => {
                      setErrors((old) => old.filter((e) => e !== "name"));
                      handleInputChange("name", event.target.value);
                    }}
                  />
                </InputStandard>
              </Row>
            </Col>
            <Col
              xs={5}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              style={{ paddingLeft: "10px" }}
            >
              <Row>
                <label
                  className={
                    errors.includes("validityDescription") ? "error" : ""
                  }
                >
                  Identificação *
                </label>
              </Row>
              <Row>
                <InputStandard
                  border={
                    errors.includes("validityDescription") ? "error" : "normal"
                  }
                  width="100%"
                  className="input-small"
                >
                  <input
                    type="text"
                    maxLength="10"
                    placeholder="Informe aqui..."
                    name="validityDescription"
                    value={
                      form?.validityDescription ? form?.validityDescription : ""
                    }
                    autoComplete="chrome-off"
                    onChange={(event) => {
                      setErrors((old) =>
                        old.filter((e) => e !== "validityDescription")
                      );
                      handleInputChange(
                        "validityDescription",
                        event.target.value
                      );
                    }}
                  />
                </InputStandard>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Row className="row-field">
                <label
                  className={errors.includes("initialValidity") ? "error" : ""}
                >
                  Vigência inicial *
                </label>
              </Row>
              <Row>
                <DatePicker
                  name="initialValidity"
                  className="validity-date-field"
                  allowClear={false}
                  value={
                    form?.initialValidity
                      ? moment(form?.initialValidity, "YYYY-MM-DD")
                      : null
                  }
                  placeholder={translation.defaultDatePickerPlaceholder}
                  format={dateFormat}
                  onChange={(date, dateString) => {
                    if (date !== null) {
                      handleInputChange("initialValidity", date);
                    }
                  }}
                />
              </Row>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ paddingLeft: "10px" }}
            >
              <Row className="row-field">
                <label
                  className={errors.includes("finalValidity") ? "error" : ""}
                >
                  Vigência final *
                </label>
              </Row>
              <Row>
                <DatePicker
                  name="finalValidity"
                  className="validity-date-field"
                  allowClear={false}
                  value={
                    form?.finalValidity
                      ? moment(form?.finalValidity, "YYYY-MM-DD")
                      : null
                  }
                  placeholder={translation.defaultDatePickerPlaceholder}
                  format={dateFormat}
                  onChange={(date, dateString) => {
                    if (form?.initialValidity) {
                      if (
                        date !== null &&
                        moment(form.initialValidity).isBefore(date)
                      ) {
                        handleInputChange("finalValidity", date);
                      }
                    }
                  }}
                />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Row className="row-field">
                <label
                  className={errors.includes("inputProtocol") ? "error" : ""}
                >
                  Valor do Protocolo de Entrada *
                </label>
              </Row>
              <Row>
                <NumberFormat
                  placeholder={translation.defaultPlaceholder}
                  customInput={Input}
                  isAllowed={monetaryLimitValidator}
                  allowNegative={false}
                  name="inputProtocol"
                  value={form?.inputProtocol ? form.inputProtocol : null}
                  addonBefore="R$"
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  onValueChange={({ floatValue }) => {
                    handleInputChange("inputProtocol", floatValue);
                    setErrors((old) =>
                      old.filter((e) => e !== "inputProtocol")
                    );
                  }}
                />
              </Row>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ paddingLeft: "10px" }}
            >
              <Row className="row-field">
                <label
                  className={errors.includes("dailySurchase") ? "error" : ""}
                >
                  Sobretaxa da Diária *
                </label>
              </Row>
              <Row>
                <Radio.Group
                  value={form?.useDailyPurchase}
                  defaultValue={true}
                  onChange={(e) => {
                    handleInputChange("useDailyPurchase", e.target.value);
                    setErrors((prevState) =>
                      prevState.filter((e) => e !== "useDailyPurchase")
                    );
                  }}
                >
                  <Radio value={false}>
                    <span style={{ color: "#4f4f4f" }}>
                      Por faixa de peso/genética
                    </span>
                  </Radio>
                  <Radio value={true}>
                    <span style={{ color: "#4f4f4f" }}>% padrão</span>{" "}
                    <NumberFormat
                      placeholder={translation.defaultPlaceholder}
                      customInput={Input}
                      name="dailySurchase"
                      value={
                        form?.dailySurchase !== null ? form.dailySurchase : ""
                      }
                      style={{ width: 80 }}
                      addonAfter="%"
                      decimalScale={0}
                      decimalSeparator=","
                      thousandSeparator="."
                      allowNegative={false}
                      fixedDecimalScale={true}
                      isAllowed={dailySurchaseLimitValidator}
                      disabled={!form?.useDailyPurchase}
                      onValueChange={({ floatValue }) => {
                        handleInputChange("dailySurchase", floatValue);
                        setErrors(errors.filter((e) => e !== "dailySurchase"));
                      }}
                    />
                  </Radio>
                </Radio.Group>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          style={{ paddingLeft: "8px" }}
        >
          <Row>
            <label className={errors.includes("headerText") ? "error" : ""}>
              Cabeçalho
            </label>
          </Row>
          <Row>
            <TextArea
              rows={3}
              border={errors.includes("headerText") ? "error" : "normal"}
              placeholder="Informe aqui..."
              name="headerText"
              width="100%"
              value={form?.headerText}
              onChange={(event) => {
                setErrors((old) => old.filter((e) => e !== "headerText"));
                handleInputChange("headerText", event.target.value);
              }}
            />
          </Row>
          <Row>
            <label className={errors.includes("footerText") ? "error" : ""}>
              Rodapé
            </label>
          </Row>
          <Row>
            <TextArea
              rows={3}
              border={errors.includes("footerText") ? "error" : "normal"}
              placeholder="Informe aqui..."
              name="footerText"
              width="100%"
              value={form?.footerText}
              onChange={(event) => {
                setErrors((old) => old.filter((e) => e !== "footerText"));
                handleInputChange("footerText", event.target.value);
              }}
            />
          </Row>
        </Col>
      </Row>
      <Row
        type="flex"
        align="middle"
        className="row-field"
        style={{ marginBottom: "70px" }}
      >
        <Table
          rowKey={(record, index) => index}
          size="small"
          dataSource={form?.weightValues != null ? form.weightValues : []}
          scroll={{ x: true }}
          style={{ width: "100%", margin: "0" }}
          pagination={false}
        >
          <Column
            title="Peso Inicial (Kg)"
            dataIndex="initialWeightRange"
            key="initialWeightRange"
            align="left"
            render={(value) => (
              <span>
                {value +
                  " - " +
                  getTwoDecimalDigits((value * 0.5) / 15, 1) +
                  "@"}
              </span>
            )}
          />
          <Column
            title="Peso Final (Kg)"
            dataIndex="finalWeightRange"
            key="finalWeightRange"
            align="left"
            render={(value) => (
              <span>
                {value +
                  " - " +
                  getTwoDecimalDigits((value * 0.5) / 15, 1) +
                  "@"}
              </span>
            )}
          />
          <Column
            title="Valor Diária Corte *"
            dataIndex="dailyValueCut"
            key="dailyValueCut"
            align="left"
            style={{ color: "red" }}
            render={(value, record) => {
              return (
                <NumberFormat
                  placeholder={translation.defaultPlaceholder}
                  customInput={Input}
                  isAllowed={monetaryLimitValidator}
                  allowNegative={false}
                  className={
                    errorsDailyValueCut.includes(record?.id) ? "cell-error" : ""
                  }
                  name="dailyValueCut"
                  value={record?.dailyValueCut}
                  addonBefore="R$"
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  onValueChange={({ floatValue }) => {
                    handleWeightValueUpdate(
                      "dailyValueCut",
                      floatValue,
                      record["id"]
                    );
                    if (form.useDailyPurchase === true) {
                      handleWeightValueUpdate(
                        "dailyValueAfterCut",
                        floatValue + floatValue * (form.dailySurchase / 100),
                        record["id"]
                      );
                    }
                    setErrorsDailyValueCut((old) =>
                      old.filter((e) => e !== record["id"])
                    );
                  }}
                />
              );
            }}
          />
          <Column
            title="Valor Diária Mestiços de Leite *"
            dataIndex="dailyValueMixedMilk"
            key="dailyValueMixedMilk"
            align="left"
            render={(value, record) => {
              return (
                <NumberFormat
                  placeholder={translation.defaultPlaceholder}
                  customInput={Input}
                  isAllowed={monetaryLimitValidator}
                  allowNegative={false}
                  className={
                    errorsDailyValueMixedMilk.includes(record?.id)
                      ? "cell-error"
                      : ""
                  }
                  name="dailyValueMixedMilk"
                  value={record?.dailyValueMixedMilk}
                  addonBefore="R$"
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  onValueChange={({ floatValue }) => {
                    handleWeightValueUpdate(
                      "dailyValueMixedMilk",
                      floatValue,
                      record["id"]
                    );
                    if (form.useDailyPurchase === true) {
                      handleWeightValueUpdate(
                        "dailyValueAfterMixedMilk",
                        floatValue + floatValue * (form.dailySurchase / 100),
                        record["id"]
                      );
                    }
                    setErrorsDailyValueMixedMilk((old) =>
                      old.filter((e) => e !== record["id"])
                    );
                  }}
                />
              );
            }}
          />
          <Column
            title="Tempo Limite (Dias) *"
            dataIndex="limitTime"
            key="limitTime"
            align="left"
            render={(value, record) => {
              return (
                <NumberFormat
                  placeholder={translation.defaultPlaceholder}
                  customInput={Input}
                  maxLength={3}
                  allowNegative={false}
                  className={
                    errorsLimitTime.includes(record?.id) ? "cell-error" : ""
                  }
                  name="limitTime"
                  value={record?.limitTime}
                  decimalScale={0}
                  decimalSeparator=","
                  onValueChange={({ floatValue }) => {
                    handleWeightValueUpdate(
                      "limitTime",
                      floatValue,
                      record["id"]
                    );
                    setErrors((old) => old.filter((e) => e !== "limitTime"));
                  }}
                />
              );
            }}
          />
          <Column
            title="Diária Após - Corte *"
            dataIndex="dailyValueAfterCut"
            key="dailyValueAfterCut"
            align="left"
            style={{ color: "red" }}
            render={(value, record) => {
              return (
                <NumberFormat
                  placeholder={translation.defaultPlaceholder}
                  customInput={Input}
                  isAllowed={monetaryLimitValidator}
                  allowNegative={false}
                  className={
                    errorsDailyValueAfterCut.includes(record?.id)
                      ? "cell-error"
                      : ""
                  }
                  name="dailyValueAfterCut"
                  value={record?.dailyValueAfterCut}
                  addonBefore="R$"
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  disabled={form?.useDailyPurchase}
                  onValueChange={({ floatValue }) => {
                    handleWeightValueUpdate(
                      "dailyValueAfterCut",
                      floatValue,
                      record["id"]
                    );
                    setErrors((old) =>
                      old.filter((e) => e !== "dailyValueAfterCut")
                    );
                    setErrorsDailyValueAfterCut((old) =>
                      old.filter((e) => e !== record["id"])
                    );
                  }}
                />
              );
            }}
          />
          <Column
            title="Diária Após - Mestiços de Leite *"
            dataIndex="dailyValueAfterMixedMilk"
            key="dailyValueAfterMixedMilk"
            align="left"
            render={(value, record) => {
              return (
                <NumberFormat
                  placeholder={translation.defaultPlaceholder}
                  customInput={Input}
                  isAllowed={monetaryLimitValidator}
                  allowNegative={false}
                  className={
                    errorsDailyValueAfterMixedMilk.includes(record?.id)
                      ? "cell-error"
                      : ""
                  }
                  name="dailyValueAfterMixedMilk"
                  value={record?.dailyValueAfterMixedMilk}
                  addonBefore="R$"
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  disabled={form?.useDailyPurchase}
                  onValueChange={({ floatValue }) => {
                    handleWeightValueUpdate(
                      "dailyValueAfterMixedMilk",
                      floatValue,
                      record["id"]
                    );
                    setErrors((old) =>
                      old.filter((e) => e !== "dailyValueAfterMixedMilk")
                    );
                    setErrorsDailyValueAfterMixedMilk((old) =>
                      old.filter((e) => e !== record["id"])
                    );
                  }}
                />
              );
            }}
          />
        </Table>
      </Row>
    </Container>
  );
};

export default withRouter(BoitelPriceEdit);
