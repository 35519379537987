import styled from "styled-components";

export const Container = styled.div`
  .table-row-green {
    color: rgb(51, 193, 98);
  }

  .table-row-red {
    color: rgb(212, 76, 76);
  }

  label {
    color: #9074bf;
    font-family: "Asap", sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    &:first-child {
      margin-top: 5px;
    }
    &.error {
      color: #d44c4c;
    }
  }
  .ant-radio-wrapper {
    span {
      color: black;
    }
  }
`;
