import React, { useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import template from "../../../../assets/templates/BovExo_template_pesagens.xlsx";
import { Creators as AppActions } from "../../../../store/ducks/app";
import { Row, Col, Icon, Upload, notification, Spin } from "antd";
import ButtonStandard from "../../../utils/button";
import {
  CustomModal,
  Container,
  Header,
  Title,
  ButtonWithoutBackground,
  DownloadButton,
  CustomSelect
} from "./styles";
import { importAnimalWeightHistory } from "../../../../services/importService";

const AnimalWeightsUploadModal = () => {
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [identificationType, setIdentificationType] = useState("handling_number");

  const {
    translation,
    modalAnimalWeightUploadVisible,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const handleCancel = () => {
    setIsLoading(false);
    setFileList([]);
    dispatch(AppActions.hideModalAnimalWeightUpload());
  };

  const handleUpload = async () => {
    try {
      setIsLoading(true);
      await importAnimalWeightHistory({
        groupId,
        farmId,
        file: fileList[0],
        identificationType: identificationType,
      });
      notification.success({
        message: "Pesagens em processamento",
        description: (
          <p>
            O seu arquivo esta sendo processado, aguarde. Você será notificado
            quando todas as pesagens forem processadas.
          </p>
        ),
      });
      handleCancel();
    } catch (error) {
      notification.error({
        message: "Erro ao importar",

        description: `Nenhuma pesagem foi inclusa.`,
        duration: 0,
      });
      handleCancel();
    }
  };
  const props = {
    accept: ".xlsx",
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    showUploadList: false,
    fileList,
  };

  const { Dragger } = Upload;
  const { Option } = CustomSelect;

  return (
    <CustomModal
      width={535}
      visible={modalAnimalWeightUploadVisible}
      centered
      maskClosable={false}
      closable={false}
      footer={null}
    >
      <Container>
        <Spin spinning={isLoading}>
          <Header>
            <Title>{translation.importAnimalWeights.title}</Title>
          </Header>
          <Row type="flex">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row type="flex" justify="start">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: translation.importAnimalWeights.message,
                    }}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="space-between" align="middle">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} align="left">
                  <DownloadButton
                    href={template}
                    download="BovExo_template_pesagens.xlsx"
                  >
                    <Icon type="download" />
                    {translation.buttons.download}
                  </DownloadButton>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} align="left">
                  <Row type="flex" justify="space-between" align="middle">
                    <label className="formLabel">
                      Identificação*
                    </label>
                    <CustomSelect
                      name="identificationType"
                      value={identificationType}
                      onChange={(value) => setIdentificationType(value)}
                    >
                      <Option value="handling_number">
                        Identificação
                      </Option>
                      <Option value="tag_id">
                        Id. Eletrônica
                      </Option>
                      <Option value="sisbov">
                        SISBOV
                      </Option>
                    </CustomSelect>
                  </Row>
                </Col>
              </Row>
              <Row type="flex" className="uploadRow">
                <Row type="flex">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Dragger {...props}>
                      {fileList.length > 0 ? (
                        <Fragment>
                          <p className="ant-upload-drag-icon">
                            <Icon type="file-excel" />
                          </p>
                          <p className="ant-upload-text">{fileList[0].name}</p>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                          </p>
                          <p className="ant-upload-text">
                            {translation.importAnimalWeights.draggerMessage}
                          </p>
                        </Fragment>
                      )}
                    </Dragger>
                  </Col>
                </Row>

                <Row
                  type="flex"
                  className="footer"
                  justify="space-between"
                  align="middle"
                >
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <ButtonStandard
                      type="button"
                      buttonType="type4"
                      size="s"
                      onClick={handleCancel}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} align="right">
                    <ButtonWithoutBackground
                      type="submit"
                      onClick={handleUpload}
                      disabled={fileList.length === 0 ? true : false}
                    >
                      {translation.buttons.processWeights}
                    </ButtonWithoutBackground>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Spin>
      </Container>
    </CustomModal>
  );
};

export default AnimalWeightsUploadModal;
