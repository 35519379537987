import React, { useState, useRef, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import NumberFormat from "react-number-format";

/* Redux libs */
import { Creators as AccountFarmActions } from "../../store/ducks/accountFarm";

import * as Yup from "yup";
import { Formik } from "formik";
import {
  Spin,
  Row,
  Col,
  Select,
  Checkbox,
  notification,
  Modal,
  Collapse,
  Icon,
} from "antd";
import {
  isValid,
  isExpirationDateValid,
  isSecurityCodeValid,
  getCreditCardNameByNumber,
} from "creditcard.js";

import CreditCardIcon from "../../components/utils/icons/creditCard";
import InvoiceIcon from "../../components/utils/icons/invoice";

import {
  Container,
  FormContainer,
  FormFooterContaier,
  CustomRadio,
  CustomRadioGroup,
} from "./styles";

import InputStandard from "../../components/utils/input";
import CreditCard from "../../components/utils/creditCard";
import ZipCodeInput from "../../components/utils/input/zipCodeInput";
import HectareInput from "../../components/utils/input/hectareInput";
import DocumentInput from "../../components/utils/input/documentInput";
import ButtonStandard from "../../components/utils/button";
import {
  getCep,
  getOnlyNumber,
  validateCPF,
  numberMask,
  validateCNPJ,
} from "../../services/helpersMethodsService";
import { getCitiesByState, getStatesInfo } from "../../services/cityService";
import { getPlansConfig } from "../../services/planConfigSercice";
import { updateAccountFarmTrialToContract } from "../../services/accountFarmService";
import { getTokenCreditCard } from "../../services/paymentGatewayService";

const Radio = ({ value, selected, children, onClick }) => {
  return (
    <CustomRadio onClick={onClick}>
      <div
        className={`radio-outer-circle ${
          selected === null
            ? "unselected"
            : value !== selected
            ? "unselected"
            : ""
        }`}
      >
        <div
          className={`radio-inner-circle ${
            selected === null
              ? "unselected-circle"
              : value !== selected
              ? "unselected-circle"
              : ""
          }`}
        />
      </div>
      {children}
    </CustomRadio>
  );
};

const UpdateTrialToContract = (props) => {
  // Variable Redux
  const {
    translation,
    user: { id: userId },
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const history = useHistory();

  const form = {
    userId: userId,
    groupSelected: groupId,
    farmSelected: farmId,
    group: null,
    statusGroup: "A",
    name: null,
    companyName: null,
    document: null,
    documentType: "CPF",
    address: null,
    state: null,
    city: null,
    country: null,
    complement_address: null,
    zipCode: null,
    status: "Active",
    totalArea: 0,
    livestockArea: 0,
    cultivationArea: 0,
    improvementsArea: 0,
    nonProductiveArea: 0,
    rentAreaOutSourced: 0,
    clientCheck: false,
    plan: { planSelected: null, amountAnimals: null },
    dueDay: null,
    paymentMethod: null,
    gatewayToken: null,
  };

  const [creditCardForm, setCreditCardForm] = useState({
    holder_name: "", // Nome do titular/portador do perfil de pagamento
    registry_code: "", // CPF ou CNPJ do titular/portador
    card_expiration: "", // Validade do cartão de crédito no formato MM/AA
    card_number: "", // Número completo do cartão de crédito
    card_cvv: "", // Código de segurança do cartão de crédito com 3 ou 4 dígitos
    payment_company_code: "",
  });

  const formik = useRef(null);
  const { Option } = Select;
  const { Panel } = Collapse;

  const [loadingCep, setLoadingCep] = useState(false);
  const [zipCodeValid, setZipCodeValid] = useState(null);
  const [initialStates, setInitialStates] = useState([]);
  const [initialCountries, setInitialCountries] = useState(null);
  const [citiesArray, setCitiesArray] = useState([]);
  const [statesArray, setStatesArray] = useState([]);
  const [countriesArray, setCountriesArray] = useState([]);

  const [planByAnimalMonthly, setPlanByAnimalMonthly] = useState(null);
  const [planByAnimalAmountMonthly, setPlanByAnimalAmountMonthly] =
    useState(null);

  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [errors, setErrors] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const validationSchema = Yup.object().shape({
    group: Yup.string().required(),
    document: Yup.string().required(),
    companyName: Yup.string().required(),
    name: Yup.string().required(),
    zipCode: Yup.string().required(),
    address: Yup.string().required(),
    country: Yup.string().required(),
    state: Yup.string().required(),
    city: Yup.string().required(),
    livestockArea: Yup.number().required(),
    rentAreaOutSourced: Yup.number().required(),
    improvementsArea: Yup.number().required(),
    cultivationArea: Yup.number().required(),
    nonProductiveArea: Yup.number().required(),
    totalArea: Yup.number().required(),
    plan: Yup.object().shape({
      planSelected: Yup.string().required(),
    }),
    dueDay: Yup.number().required(),
    paymentMethod: Yup.string().required(),
  });

  useEffect(() => {
    // componentWillUnmount
    return () => {
      dispatch(AccountFarmActions.checkAccountFarm(groupId, farmId));
    };
  }, [dispatch, groupId, farmId]);

  /** Effect  */
  useEffect(() => {
    async function handle() {
      try {
        const { data } = await getStatesInfo();

        if (data) {
          setInitialStates(data.results);
          setInitialCountries([
            {
              id: data.results[0].countryId,
              name: data.results[0].countryName,
            },
          ]);
          setStatesArray(data.results);
          setCountriesArray([
            {
              id: data.results[0].countryId,
              name: data.results[0].countryName,
            },
          ]);
        }
      } catch (error) {}
    }
    handle();
  }, []);

  useEffect(() => {
    async function loadPlanConfig() {
      try {
        const {
          data: { results },
        } = await getPlansConfig();

        setPlanByAnimalMonthly(
          Object.values(results).find((plan) => {
            if (plan.code === "ByAnimalAmountMonthly") {
              return plan;
            } else {
              return null;
            }
          })
        );

        setPlanByAnimalAmountMonthly(
          Object.values(results).find((plan) => {
            if (plan.code === "ByAnimalAmountMonthly") {
              return plan;
            } else {
              return null;
            }
          })
        );
      } catch (error) {
        notification.error({
          message:
            "Ocorreu um erro ao acessar as informações de planos disponíveis.",
        });
      }
    }

    function handle() {
      loadPlanConfig();
    }
    handle();
  }, []);

  function openModal() {
    setShowModal(true);
  }

  const confirmModal = () => {
    if (handleValidateNewCreditCardForm()) {
      setShowModal(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  async function handleSubmitForm(values, props, event) {
    event.preventDefault();

    const {
      group,
      name,
      companyName,
      document,
      documentType,
      address,
      city,
      zipCode,
      totalArea,
      plan,
      dueDay,
      paymentMethod,
    } = values;

    if (group == null || group?.trim() === "") {
      notification.error({
        message: "É necessário informar um nome para o grupo.",
      });
    } else if (document == null || document?.trim() === "") {
      notification.error({
        message: "É necessário informar um CPF/CNPJ.",
      });
    } else if (documentType === "CPF" && !validateCPF(document)) {
      notification.error({
        message: "CPF inválido.",
      });
    } else if (documentType === "CNPJ" && !validateCNPJ(document)) {
      notification.error({
        message: "CNPJ inválido.",
      });
    } else if (companyName == null || companyName?.trim() === "") {
      notification.error({
        message: "É necessário informar um nome fantasia.",
      });
    } else if (name == null || name?.trim() === "") {
      notification.error({
        message: "É necessário informar um nome fantasia.",
      });
    } else if (address == null || address?.trim() === "") {
      notification.error({
        message: "É necessário informar um endereço.",
      });
    } else if (city == null) {
      notification.error({
        message: "É necessário informar uma cidade.",
      });
    } else if (zipCode == null || zipCode?.trim() === "") {
      notification.error({
        message: "É necessário informar um CEP.",
      });
    } else if (totalArea == null || totalArea === 0) {
      notification.error({
        message: "Área total da fazenda não pode ser 0.",
      });
    } else if (plan?.planSelected == null) {
      notification.error({
        message: "É necessário selecionar um plano.",
      });
    } else if (dueDay == null || dueDay === 0) {
      notification.error({
        message: "É necessário escolher um dia de vencimento.",
      });
    } else if (paymentMethod == null) {
      notification.error({
        message: "É necessário selecionar uma forma de pagamento.",
      });
    } else {
      setIsLoadingRequest(true);
      try {
        if (values.paymentMethod === "CreditCard") {
          const {
            data: {
              payment_profile: { gateway_token: token },
            },
          } = await getTokenCreditCard(creditCardForm);
          let data = { paymentMethod: "CreditCard", gatewayToken: token };
          values = {
            ...values,
            paymentMethod: data.paymentMethod,
            gatewayToken: data.gatewayToken,
          };
        }

        const {
          data: { results },
        } = await updateAccountFarmTrialToContract({
          groupId: groupId,
          farmId: farmId,
          body: values,
        });
        if (results) {
          notification.success({
            message: "Contratação realizada com sucesso!",
          });
          history.push(`/admin`);
        }
      } catch {
        setIsLoadingRequest(false);
      }
    }
  }

  async function handleZipCodeSearch(zipCode, props) {
    if (zipCode != null && zipCode.trim().length > 0) {
      if (zipCode.length === 9) {
        setLoadingCep(true);
        try {
          const {
            data: { erro, logradouro: street, uf: state, localidade: city },
          } = await getCep(zipCode);
          if (erro) {
            setLoadingCep(false);
            setZipCodeValid(false);
          } else {
            const countries = initialCountries.filter(
              (c) => c.name === "Brasil"
            );
            const states = initialStates.filter((s) => s.name === state);

            const { data } = await getCitiesByState(states[0].id);

            if (data) {
              const cities = data.results.filter(
                (c) => c.name.toLowerCase() === city.toLowerCase()
              );
              setCitiesArray(data.results);
              props.setFieldValue("city", cities[0].name);
            }
            props.setFieldValue("address", street);
            props.setFieldValue("state", states[0].name);
            props.setFieldValue("country", countries[0].name);

            setLoadingCep(false);
            setZipCodeValid(true);
          }
        } catch (error) {
          setLoadingCep(false);
          setZipCodeValid(false);
        }
      } else {
        setLoadingCep(false);
        setZipCodeValid(false);
      }
    }
  }

  function handleCountryChange(countryName) {
    const country = initialCountries.filter((c) => c.name === countryName);
    const filteredStates = initialStates.filter(
      (s) => s.countryId === country[0].id
    );
    setStatesArray(filteredStates);
  }

  async function handleStateChange(stateName) {
    const state = initialStates.filter((s) => s.name === stateName);
    try {
      const { data } = await getCitiesByState(state[0].id);
      if (data) {
        setCitiesArray(data.results);
      }
      formik.setFieldValue("city", undefined);
    } catch (error) {}
  }

  const handleCalculateTotalArea = (values, props, field, currentValue) => {
    let {
      livestockArea,
      cultivationArea,
      improvementsArea,
      nonProductiveArea,
      rentAreaOutSourced,
    } = values;

    switch (field) {
      case "livestockArea":
        livestockArea = currentValue;
        break;
      case "cultivationArea":
        cultivationArea = currentValue;
        break;
      case "improvementsArea":
        improvementsArea = currentValue;
        break;
      case "nonProductiveArea":
        nonProductiveArea = currentValue;
        break;
      case "rentAreaOutSourced":
        rentAreaOutSourced = currentValue;
        break;
      default:
        break;
    }
    const totalArea =
      livestockArea +
      cultivationArea +
      improvementsArea +
      nonProductiveArea +
      rentAreaOutSourced;
    props.setFieldValue("totalArea", totalArea);
  };

  function handleMinAmountAnimals(values) {
    if (planByAnimalAmountMonthly) {
      if (
        values?.plan.amountAnimals > planByAnimalAmountMonthly?.minAnimalsAmount
      ) {
        return values?.plan.amountAnimals * planByAnimalAmountMonthly?.value;
      } else {
        return (
          planByAnimalAmountMonthly?.minAnimalsAmount *
          planByAnimalAmountMonthly?.value
        );
      }
    }

    return 0;
  }

  function renderCreditCardForm() {
    return (
      <>
        <Row type="flex" justify="start" gutter={8}>
          {/* Credit Card info */}
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            {/* holder_name */}
            <Row type="flex" justify="start" gutter={8}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row>
                  <label
                    className={errors.includes("holder_name") ? "error" : ""}
                  >
                    Nome do titular/portador
                  </label>
                </Row>
                <Row>
                  <InputStandard
                    border={errors.includes("holder_name") ? "error" : "normal"}
                    className={`input text-uppercase`}
                    width="100%"
                  >
                    <input
                      type="text"
                      placeholder="Informe aqui..."
                      name="holder_name"
                      value={creditCardForm?.holder_name}
                      autoComplete="chrome-off"
                      onChange={(event) => {
                        setErrors((old) =>
                          old.filter((e) => e !== "holder_name")
                        );
                        setCreditCardForm({
                          ...creditCardForm,
                          holder_name: event.target.value,
                        });
                      }}
                    />
                  </InputStandard>
                </Row>
              </Col>
            </Row>
            {/* registry_code */}
            <Row type="flex" justify="start" gutter={8}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row>
                  <label
                    className={errors.includes("registry_code") ? "error" : ""}
                  >
                    CPF
                  </label>
                </Row>
                <Row>
                  <InputStandard
                    border={
                      errors.includes("registry_code") ? "error" : "normal"
                    }
                    className={`input`}
                    width="100%"
                  >
                    <NumberFormat
                      type="text"
                      placeholder="Informe aqui..."
                      name="registry_code"
                      value={creditCardForm?.registry_code}
                      autoComplete="chrome-off"
                      format="###.###.###-##"
                      onChange={(event) => {
                        setErrors((old) =>
                          old.filter((e) => e !== "registry_code")
                        );
                        setCreditCardForm({
                          ...creditCardForm,
                          registry_code: event.target.value,
                        });
                      }}
                    />
                  </InputStandard>
                </Row>
              </Col>
            </Row>
            {/* card_number */}
            <Row type="flex" justify="start" gutter={8}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row>
                  <label
                    className={errors.includes("card_number") ? "error" : ""}
                  >
                    Número do Cartão
                  </label>
                </Row>
                <Row>
                  <InputStandard
                    border={errors.includes("card_number") ? "error" : "normal"}
                    className={`input`}
                    width="100%"
                  >
                    <NumberFormat
                      type="text"
                      placeholder="#### #### #### ####"
                      name="card_number"
                      autoComplete="chrome-off"
                      format="#### #### #### ####"
                      value={creditCardForm?.card_number}
                      onChange={(event) => {
                        setCreditCardForm({
                          ...creditCardForm,
                          card_number: event.target.value,
                        });
                        setErrors((old) =>
                          old.filter((e) => e !== "card_number")
                        );
                      }}
                      onBlur={(event) =>
                        handleValidateCreditCardNumber(event.target.value)
                      }
                    />
                    {/* <div className="icon">{creditCardBrand}</div> */}
                  </InputStandard>
                </Row>
              </Col>
            </Row>
            {/* card_expiration - card_cvv */}
            <Row type="flex" justify="start" gutter={8}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Row>
                  <label
                    className={
                      errors.includes("card_expiration") ? "error" : ""
                    }
                  >
                    Data do vencimento
                  </label>
                </Row>
                <Row>
                  <InputStandard
                    border={
                      errors.includes("card_expiration") ? "error" : "normal"
                    }
                    className={`input`}
                    width="100%"
                  >
                    <NumberFormat
                      type="text"
                      placeholder="MM/AA"
                      name="card_expiration"
                      autoComplete="chrome-off"
                      format="##/##"
                      value={creditCardForm?.card_expiration}
                      onChange={(event) => {
                        setCreditCardForm({
                          ...creditCardForm,
                          card_expiration: event.target.value,
                        });
                        setErrors((old) =>
                          old.filter((e) => e !== "card_expiration")
                        );
                      }}
                      onBlur={(event) =>
                        handleValidateCreditCardExpiration(event.target.value)
                      }
                    />
                  </InputStandard>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Row>
                  <label className={errors.includes("card_cvv") ? "error" : ""}>
                    Código de segurança
                  </label>
                </Row>
                <Row>
                  <InputStandard
                    border={errors.includes("card_cvv") ? "error" : "normal"}
                    className={`input`}
                    width="100%"
                  >
                    <NumberFormat
                      type="text"
                      placeholder="####"
                      name="card_cvv"
                      autoComplete="chrome-off"
                      format="####"
                      value={creditCardForm?.card_cvv}
                      onChange={(event) => {
                        setCreditCardForm({
                          ...creditCardForm,
                          card_cvv: event.target.value?.trim(),
                        });

                        setErrors((old) => old.filter((e) => e !== "card_cvv"));
                      }}
                      onBlur={(event) =>
                        handleValidateCreditCardCVV(event.target.value?.trim())
                      }
                    />
                  </InputStandard>
                </Row>
              </Col>
            </Row>
          </Col>
          {/* Credit Card Component */}
          <Col xs={24} sm={24} md={24} lg={12} xl={12} className="card-col">
            <Row type="flex" className="row-centralized">
              <CreditCard
                card_number={getOnlyNumber(creditCardForm?.card_number)}
                card_expiration={creditCardForm?.card_expiration}
                holder_name={creditCardForm?.holder_name}
                card_cvv={creditCardForm?.card_cvv}
                payment_company_code={creditCardForm?.payment_company_code}
              />
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  function renderModalCreditCard() {
    return (
      <Modal
        title="Cartão de Crédito"
        visible={showModal}
        onOk={confirmModal}
        onCancel={closeModal}
        okText="Confirmar"
        cancelText="Cancelar"
        style={{ minWidth: "60%" }}
      >
        <Container>{renderCreditCardForm()}</Container>
      </Modal>
    );
  }

  function handleValidateCreditCardNumber(value) {
    if (value != null) {
      const number = getOnlyNumber(value.trim());
      if (isValid(number)) {
        const brand = getCreditCardNameByNumber(number);
        setCreditCardForm((old) => ({
          ...old,
          payment_company_code: brand,
        }));
        return true;
      } else {
        setErrors((old) => [...old, "card_number"]);
        return false;
      }
    }
  }

  function handleValidateCreditCardExpiration(value) {
    if (value != null) {
      const month = value.trim().substr(0, 2);
      const year = value.trim().substr(3, 2);
      if (!isExpirationDateValid(month, year)) {
        setErrors((old) => [...old, "card_expiration"]);
        return false;
      }
      return true;
    }
  }

  function handleValidateCreditCardCVV(value) {
    const { card_number } = creditCardForm;
    const number = getOnlyNumber(card_number.trim());
    if (card_number != null && value != null) {
      if (!isSecurityCodeValid(number, value)) {
        setErrors((old) => [...old, "card_cvv"]);
        return false;
      }
      return true;
    }
  }

  function handleValidateNewCreditCardForm() {
    const {
      holder_name,
      registry_code,
      card_expiration,
      card_number,
      card_cvv,
    } = creditCardForm;
    let errorsArray = [];

    if (holder_name == null || holder_name === "") {
      errorsArray.push("holder_name");
      notification.error({
        message: "Nome do titular/portardor é obrigatório.",
      });
    }
    if (
      registry_code == null ||
      registry_code === "" ||
      !validateCPF(registry_code)
    ) {
      errorsArray.push("registry_code");
      notification.error({
        message: "CPF inválido.",
      });
    }
    if (
      card_number == null ||
      card_number === "" ||
      !handleValidateCreditCardNumber(card_number)
    ) {
      errorsArray.push("card_number");
      notification.error({
        message: "Número do Cartão inválido.",
      });
    }
    if (
      card_expiration == null ||
      card_expiration === "" ||
      !handleValidateCreditCardExpiration(card_expiration)
    ) {
      errorsArray.push("card_expiration");
      notification.error({
        message: "Data do Vencimento inválida.",
      });
    }
    if (
      card_cvv == null ||
      card_cvv === "" ||
      !handleValidateCreditCardCVV(card_cvv)
    ) {
      errorsArray.push("card_cvv");
      notification.error({
        message: "Código de segurança inválido.",
      });
    }

    setErrors(errorsArray);

    return errorsArray.length === 0;
  }

  return (
    <FormContainer>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Spin spinning={isLoadingRequest}>
          <Formik
            ref={formik}
            initialValues={form}
            validationSchema={validationSchema}
            onSubmit={handleSubmitForm}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              ...props
            }) => (
              <form>
                <Row type="flex" className="marginRow">
                  <Col>
                    <Row>
                      <span className="title">Grupos & fazendas</span>
                    </Row>
                    <Row>
                      <span className="title small">
                        Complete os campos abaixo para inserir no sistema seu
                        primeiro grupo e fazenda
                      </span>
                    </Row>
                  </Col>
                </Row>
                {/* Input Group name */}
                <Row type="flex" gutter={8}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label
                        className={errors.group && touched.group ? "error" : ""}
                      >
                        Grupo*
                      </label>
                    </Row>
                    <Row>
                      <InputStandard
                        border={
                          errors.group && touched.group ? "error" : "normal"
                        }
                        className="input small"
                      >
                        <input
                          placeholder="Informe aqui..."
                          maxLength="255"
                          name="group"
                          autoComplete="chrome-off"
                          onChange={(event) =>
                            props.setFieldValue("group", event.target.value)
                          }
                          onBlur={handleBlur}
                        />
                      </InputStandard>
                    </Row>
                  </Col>
                </Row>
                {/* Farm SubGroup */}
                <Row type="flex" className="marginRow">
                  <span className="subTitle">Cadastro de fazenda</span>
                </Row>
                {/* Farm Document, name and companyName */}
                <Row type="flex" gutter={8}>
                  <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                    <Row>
                      <label
                        className={
                          errors.document && touched.document ? "error" : ""
                        }
                      >
                        CPF/CNPJ*
                      </label>
                    </Row>
                    <Row>
                      <DocumentInput
                        border={
                          errors.document && touched.document
                            ? "error"
                            : "normal"
                        }
                        className="input small documentInput"
                        kind="mixed"
                        placeholder="Informe aqui..."
                        value={values?.document}
                        name="document"
                        onChange={(event) => {
                          let value = event.target.value
                            ?.replace(/\./g, "")
                            .replace(/\//g, "")
                            .replace(/-/g, "");
                          props.setFieldValue("document", value);
                          props.setFieldValue(
                            "documentType",
                            value.length <= 11 ? "CPF" : "CNPJ"
                          );
                        }}
                        onBlur={handleBlur}
                      />
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                    <Row>
                      <label
                        className={
                          errors.companyName && touched.companyName
                            ? "error"
                            : ""
                        }
                      >
                        Razão Social*
                      </label>
                    </Row>
                    <Row>
                      <InputStandard
                        border={
                          errors.companyName && touched.companyName
                            ? "error"
                            : "normal"
                        }
                        className="input small"
                      >
                        <input
                          placeholder="Informe aqui..."
                          maxLength="255"
                          name="companyName"
                          autoComplete="chrome-off"
                          onChange={(event) =>
                            props.setFieldValue(
                              "companyName",
                              event.target.value
                            )
                          }
                          onBlur={handleBlur}
                        />
                      </InputStandard>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                    <Row>
                      <label
                        className={errors.name && touched.name ? "error" : ""}
                      >
                        Nome fantasia da fazenda*
                      </label>
                    </Row>
                    <Row>
                      <InputStandard
                        border={
                          errors.name && touched.name ? "error" : "normal"
                        }
                        className="input small"
                      >
                        <input
                          placeholder="Informe aqui..."
                          maxLength="255"
                          name="name"
                          autoComplete="chrome-off"
                          onChange={(event) =>
                            props.setFieldValue("name", event.target.value)
                          }
                          onBlur={handleBlur}
                        />
                      </InputStandard>
                    </Row>
                  </Col>
                </Row>

                <Row type="flex" gutter={8} className="marginRow">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Collapse defaultActiveKey={["1"]}>
                      <Panel header="Endereço da fazenda" key="1">
                        {/* Input zipCode */}
                        <Row type="flex" gutter={8}>
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row>
                              <label
                                className={
                                  errors.zipCode && touched.zipCode
                                    ? "error"
                                    : "normal"
                                }
                              >
                                CEP*
                              </label>
                            </Row>
                            <Row>
                              <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                <InputStandard
                                  border={
                                    errors.zipCode && touched.zipCode
                                      ? "error"
                                      : "normal"
                                  }
                                  className="input small"
                                >
                                  <ZipCodeInput
                                    placeholder={
                                      translation.farm.form.zipCodePlaceHolder
                                    }
                                    type="text"
                                    value={values?.zipCode || ""}
                                    autoComplete="chrome-off"
                                    name="zipCode"
                                    onChange={(event) =>
                                      props.setFieldValue(
                                        "zipCode",
                                        event.target.value
                                      )
                                    }
                                    onBlur={(e) => {
                                      handleBlur(e);
                                      handleZipCodeSearch(
                                        e.target.value,
                                        props
                                      );
                                    }}
                                  />
                                </InputStandard>
                              </Col>
                              <Col
                                xs={11}
                                sm={11}
                                md={14}
                                lg={14}
                                xl={14}
                                push={1}
                              >
                                {loadingCep === true ? (
                                  <Row
                                    style={{ marginBottom: 32, marginTop: 8 }}
                                  >
                                    <span
                                      style={{
                                        color: "#4b4b4b",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Icon
                                        type="loading"
                                        style={{ marginRight: 10 }}
                                      />
                                      Processando
                                    </span>
                                  </Row>
                                ) : zipCodeValid != null &&
                                  zipCodeValid === true &&
                                  values.zip_code ? (
                                  <Row
                                    style={{ marginBottom: 32, marginTop: 8 }}
                                  >
                                    <span
                                      style={{
                                        color: "#33C162",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Icon
                                        type="check-circle"
                                        style={{
                                          marginRight: 10,
                                          color: "#33C162",
                                          fontSize: 12,
                                        }}
                                      />
                                      {translation.zipCodeValid}
                                    </span>
                                  </Row>
                                ) : zipCodeValid != null &&
                                  zipCodeValid === false &&
                                  values.zip_code ? (
                                  <Row
                                    style={{ marginBottom: 32, marginTop: 8 }}
                                  >
                                    <span
                                      style={{
                                        color: "#D44C4C",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Icon
                                        type="close-circle"
                                        style={{
                                          marginRight: 10,
                                          color: "#D44C4C",
                                          fontSize: 12,
                                        }}
                                      />
                                      {translation.zipCodeInvalid}
                                    </span>
                                  </Row>
                                ) : null}
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        {/* Address, country, state and city */}
                        <Row type="flex" gutter={8}>
                          {/* Address */}
                          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Row>
                              <label
                                className={
                                  errors.address && touched.address
                                    ? "error"
                                    : ""
                                }
                              >
                                Endereço*
                              </label>
                            </Row>
                            <Row>
                              <InputStandard
                                border={
                                  errors.address && touched.address
                                    ? "error"
                                    : "normal"
                                }
                                className="input small"
                              >
                                <input
                                  value={values?.address || ""}
                                  placeholder="Informe aqui..."
                                  maxLength="255"
                                  name="address"
                                  autoComplete="chrome-off"
                                  onChange={(event) =>
                                    props.setFieldValue(
                                      "address",
                                      event.target.value
                                    )
                                  }
                                  onBlur={handleBlur}
                                />
                              </InputStandard>
                            </Row>
                          </Col>

                          {/* Country */}
                          <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Row>
                              <label
                                className={
                                  errors.country && touched.country
                                    ? "error"
                                    : ""
                                }
                              >
                                País
                              </label>
                            </Row>
                            <Row>
                              <Select
                                placeholder="Informe aqui..."
                                value={
                                  values.country != null
                                    ? values.country
                                    : undefined
                                }
                                name="country"
                                disabled={loadingCep || zipCodeValid}
                                onChange={(value) => {
                                  props.setFieldValue("country", value);
                                  handleCountryChange(value);
                                }}
                                onBlur={handleBlur}
                              >
                                {countriesArray.length > 0 &&
                                  countriesArray.map((c) => (
                                    <Option key={c.id} value={c.name}>
                                      {c.name}
                                    </Option>
                                  ))}
                              </Select>
                            </Row>
                          </Col>

                          {/* State */}
                          <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Row>
                              <label
                                className={
                                  errors.state && touched.state ? "error" : ""
                                }
                              >
                                Estado
                              </label>
                            </Row>
                            <Row>
                              <Select
                                placeholder="Informe aqui..."
                                value={
                                  values.state != null
                                    ? values.state
                                    : undefined
                                }
                                name="state"
                                disabled={
                                  loadingCep ||
                                  zipCodeValid ||
                                  values.country == null
                                }
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(value) => {
                                  props.setFieldValue("state", value);
                                  handleStateChange(value);
                                }}
                                onBlur={handleBlur}
                              >
                                {statesArray.length > 0 &&
                                  statesArray.map((state) => (
                                    <Option key={state.id} value={state.name}>
                                      {state.name}
                                    </Option>
                                  ))}
                              </Select>
                            </Row>
                          </Col>

                          {/* City */}
                          <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <Row>
                              <label
                                className={
                                  errors.city && touched.city ? "error" : ""
                                }
                              >
                                Cidade
                              </label>
                            </Row>
                            <Row>
                              <Select
                                placeholder="Informe aqui..."
                                value={
                                  values.city != null ? values.city : undefined
                                }
                                name="city"
                                disabled={
                                  loadingCep ||
                                  zipCodeValid ||
                                  values.state == null
                                }
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(value) => {
                                  props.setFieldValue("city", value);
                                }}
                                onBlur={handleBlur}
                              >
                                {citiesArray.length > 0 &&
                                  citiesArray.map((c) => (
                                    <Option key={Math.random()} value={c.name}>
                                      {c.name}
                                    </Option>
                                  ))}
                              </Select>
                            </Row>
                          </Col>
                        </Row>
                        {/* Complement */}
                        <Row type="flex" gutter={8}>
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row>
                              <label
                                className={
                                  errors.complement_address &&
                                  touched.complement_address
                                    ? "error"
                                    : ""
                                }
                              >
                                Complemento
                              </label>
                            </Row>
                            <Row>
                              <InputStandard
                                border={
                                  errors.complement_address &&
                                  touched.complement_address
                                    ? "error"
                                    : "normal"
                                }
                                className="input small"
                              >
                                <input
                                  name="complement_address"
                                  autoComplete="chrome-off"
                                  onChange={(event) =>
                                    props.setFieldValue(
                                      "complement_address",
                                      event.target.value
                                    )
                                  }
                                  placeholder="Utilize este campo para exemplificar como chegar em sua fazenda caso não exista sinalização..."
                                />
                              </InputStandard>
                            </Row>
                          </Col>
                        </Row>
                      </Panel>
                      <Panel header="Área da fazenda" key="2">
                        {/* Farm area */}
                        <Row type="flex" gutter={8}>
                          {/* Área total */}
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Row>
                              <label>Área total</label>
                            </Row>
                            <Row>
                              <HectareInput
                                className="input small hectarInput disable"
                                placeholder="Informe"
                                value={values?.totalArea}
                                name="areaTotal"
                                disabled
                              />
                            </Row>
                          </Col>

                          {/* Área útil */}
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Row>
                              <label
                                className={
                                  errors.livestockArea && touched.livestockArea
                                    ? "error"
                                    : ""
                                }
                              >
                                Área útil*
                              </label>
                            </Row>
                            <Row>
                              <HectareInput
                                className="input small hectarInput"
                                placeholder="Informe"
                                name="livestockArea"
                                disabled={loadingCep}
                                onValueChange={({ floatValue }) => {
                                  props.setFieldValue(
                                    "livestockArea",
                                    floatValue
                                  );
                                  handleCalculateTotalArea(
                                    values,
                                    props,
                                    "livestockArea",
                                    floatValue || 0
                                  );
                                }}
                                onBlur={handleBlur}
                              />
                            </Row>
                          </Col>

                          {/* Área de cultivo */}
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Row>
                              <label
                                className={
                                  errors.cultivationArea &&
                                  touched.cultivationArea
                                    ? "error"
                                    : ""
                                }
                              >
                                Área de cultivo*
                              </label>
                            </Row>
                            <Row>
                              <HectareInput
                                className="input small hectarInput"
                                placeholder="Informe"
                                value={values?.cultivationArea}
                                name="cultivationArea"
                                disabled={loadingCep}
                                onValueChange={({ floatValue }) => {
                                  props.setFieldValue(
                                    "cultivationArea",
                                    floatValue || 0
                                  );
                                  handleCalculateTotalArea(
                                    values,
                                    props,
                                    "cultivationArea",
                                    floatValue || 0
                                  );
                                }}
                                onBlur={handleBlur}
                              />
                            </Row>
                          </Col>

                          {/* Arrendada para terceiros */}
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Row>
                              <label
                                className={
                                  errors.rentAreaOutSourced &&
                                  touched.rentAreaOutSourced
                                    ? "error"
                                    : ""
                                }
                              >
                                Arrendada para terceiros*
                              </label>
                            </Row>
                            <Row>
                              <HectareInput
                                className="input small hectarInput"
                                placeholder="Informe"
                                value={values?.rentAreaOutSourced}
                                name="rentAreaOutSourced"
                                disabled={loadingCep}
                                onValueChange={({ floatValue }) => {
                                  props.setFieldValue(
                                    "rentAreaOutSourced",
                                    floatValue || 0
                                  );
                                  handleCalculateTotalArea(
                                    values,
                                    props,
                                    "rentAreaOutSourced",
                                    floatValue || 0
                                  );
                                }}
                                onBlur={handleBlur}
                              />
                            </Row>
                          </Col>

                          {/* Não produtiva */}
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Row>
                              <label
                                className={
                                  errors.nonProductiveArea &&
                                  touched.nonProductiveArea
                                    ? "error"
                                    : ""
                                }
                              >
                                Não produtiva*
                              </label>
                            </Row>
                            <Row>
                              <HectareInput
                                className="input small hectarInput"
                                placeholder="Informe"
                                value={values?.nonProductiveArea}
                                name="nonProductiveArea"
                                disabled={loadingCep}
                                onValueChange={({ floatValue }) => {
                                  props.setFieldValue(
                                    "nonProductiveArea",
                                    floatValue || 0
                                  );
                                  handleCalculateTotalArea(
                                    values,
                                    props,
                                    "nonProductiveArea",
                                    floatValue || 0
                                  );
                                }}
                                onBlur={handleBlur}
                              />
                            </Row>
                          </Col>

                          {/* Área de melhoria */}
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Row>
                              <label
                                className={
                                  errors.improvementsArea &&
                                  touched.improvementsArea
                                    ? "error"
                                    : ""
                                }
                              >
                                Área de melhoria*
                              </label>
                            </Row>
                            <Row>
                              <HectareInput
                                className="input small hectarInput"
                                placeholder="Informe"
                                value={values?.improvementsArea}
                                name="improvementsArea"
                                disabled={loadingCep}
                                onValueChange={({ floatValue }) => {
                                  props.setFieldValue(
                                    "improvementsArea",
                                    floatValue || 0
                                  );
                                  handleCalculateTotalArea(
                                    values,
                                    props,
                                    "improvementsArea",
                                    floatValue || 0
                                  );
                                }}
                                onBlur={handleBlur}
                              />
                            </Row>
                          </Col>
                        </Row>

                        {/* Client Check */}
                        <Row type="flex" align="middle">
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Checkbox
                              onChange={() =>
                                props.setFieldValue("clientCheck", true)
                              }
                              style={{
                                color: "#4B4B4B",
                                fontWeight: "normal",
                                fontSize: 14,
                                textTransform: "none",
                                marginTop: 10,
                              }}
                            >
                              Assinale a caixa caso você deseje aparecer na
                              lista de fornecedores e na lista de clientes nas
                              vendas de animais de outros usuários Bovexo.
                            </Checkbox>
                          </Col>
                        </Row>
                      </Panel>
                      <Panel header="Planos" key="3">
                        {/* Plans */}
                        <Row type="flex" gutter={8}>
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <CustomRadioGroup>
                              <Radio
                                value="ByAnimalMonthly"
                                selected={values?.plan.planSelected}
                                onClick={() =>
                                  props.setFieldValue(
                                    "plan.planSelected",
                                    "ByAnimalMonthly"
                                  )
                                }
                              >
                                <label>
                                  R$ {numberMask(planByAnimalMonthly?.value)}
                                  /mês/cab.
                                </label>
                              </Radio>
                              <p className="radioDetails">
                                Nesta forma, você paga de acordo com a
                                quantidade de cabeças medidas mensalmente. Este
                                valor corresponde, em média, a menos de 2,5% do
                                valor adicional por cabeça gerado por BovExo.
                              </p>
                              <Radio
                                value="ByAnimalAmountMonthly"
                                selected={values?.plan.planSelected}
                                onClick={() =>
                                  props.setFieldValue(
                                    "plan.planSelected",
                                    "ByAnimalAmountMonthly"
                                  )
                                }
                              >
                                <label
                                  className={
                                    errors.amountAnimals &&
                                    touched.amountAnimals
                                      ? "error"
                                      : ""
                                  }
                                >
                                  Quantidade Média mensal de cabeças
                                </label>
                                <InputStandard
                                  className={`input no-margin-bottom ${
                                    values?.plan.planSelected !==
                                    "ByAnimalAmountMonthly"
                                      ? "disable"
                                      : ""
                                  }`}
                                  width="100px"
                                >
                                  <NumberFormat
                                    type="text"
                                    placeholder="XXX"
                                    name="amountAnimals"
                                    value={values?.plan.amountAnimals}
                                    decimalScale={0}
                                    onValueChange={({ floatValue }) => {
                                      props.setFieldValue(
                                        "plan.amountAnimals",
                                        floatValue
                                      );
                                      setErrors((old) =>
                                        old.filter((e) => e !== "amountAnimals")
                                      );
                                    }}
                                  />
                                </InputStandard>
                              </Radio>
                              <p className="radioDetails">
                                Valor fixo de:{" "}
                                {numberMask(
                                  handleMinAmountAnimals(values),
                                  true
                                )}{" "}
                                /mês. Para facilitar a sua programação de fluxo
                                de caixa, você pode optar por pagar um valor
                                fixo mensal. BovExo fará um balanço anual da
                                diferença entre a média contratada e a efetiva
                                e, valores a maior ou a menor, serão
                                acrescidos/descontados dos valores devidos para
                                os doze meses subsequentes..
                              </p>
                              {/* <Radio
                                                value="Custom"
                                                selected={values?.plan.planSelected}
                                                onClick={() => props.setFieldValue("plan.planSelected", "Custom")}
                                            >
                                                <label>
                                                    Outros modelos de precificação, incluindo por performance.
                                                </label>
                                            </Radio> */}
                            </CustomRadioGroup>
                          </Col>
                        </Row>
                      </Panel>
                      <Panel header="Formas de pagamento" key="4">
                        {/* Due day And Payment method*/}
                        <Row type="flex" gutter={8}>
                          <Col xs={10} sm={10} md={10} lg={6} xl={6}>
                            <Row>
                              <label
                                className={
                                  errors.dueDay && touched.dueDay ? "error" : ""
                                }
                              >
                                Vencimento
                              </label>
                            </Row>
                            <Row>
                              <Select
                                placeholder="Selecione um dia"
                                value={
                                  values.dueDay != null
                                    ? values.dueDay
                                    : undefined
                                }
                                name="dueDay"
                                onChange={(day) =>
                                  props.setFieldValue("dueDay", day)
                                }
                                onBlur={handleBlur}
                              >
                                {[...Array(31).keys()].map((el) => (
                                  <Option value={el + 1} key={el}>
                                    {" "}
                                    Dia {el + 1}{" "}
                                  </Option>
                                ))}
                              </Select>
                            </Row>
                          </Col>

                          <Col xs={12} sm={12} md={12} lg={12} xl={12} push={2}>
                            <Row>
                              <label
                                className={
                                  errors.paymentMethod && touched.paymentMethod
                                    ? "error"
                                    : ""
                                }
                              >
                                Formas de pagamento
                              </label>
                            </Row>
                            <Row>
                              <CustomRadioGroup>
                                <Radio
                                  value="CreditCard"
                                  selected={values?.paymentMethod}
                                  onClick={() => {
                                    props.setFieldValue(
                                      "paymentMethod",
                                      "CreditCard"
                                    );
                                    openModal();
                                  }}
                                >
                                  <div className="option-icon">
                                    <CreditCardIcon />
                                  </div>
                                  <label>Cartão de crédito</label>
                                </Radio>
                                <Radio
                                  value="BankSlip"
                                  selected={values?.paymentMethod}
                                  onClick={() =>
                                    props.setFieldValue(
                                      "paymentMethod",
                                      "BankSlip"
                                    )
                                  }
                                >
                                  <div className="option-icon">
                                    <InvoiceIcon />
                                  </div>
                                  <label>Boleto</label>
                                </Radio>
                              </CustomRadioGroup>
                            </Row>
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>

                <FormFooterContaier>
                  <Row type="flex" gutter={8} justify="end">
                    <Col xs={5} sm={5} md={4} lg={3} xl={2}>
                      <ButtonStandard
                        buttonType="type2"
                        width="100%"
                        height="39px"
                        background="#591F74"
                        onClick={(event) =>
                          handleSubmitForm(values, props, event)
                        }
                      >
                        Contratar
                      </ButtonStandard>
                    </Col>
                  </Row>
                </FormFooterContaier>
                {renderModalCreditCard()}
              </form>
            )}
          </Formik>
        </Spin>
      </Col>
    </FormContainer>
  );
};

export default withRouter(UpdateTrialToContract);
