import styled from "styled-components";

export const Container = styled.div`
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 3px 6px #dcdcdc;
  margin-top: 10px;
  margin-left: 2px;
  padding-left: 22px;

  .row-content {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  span.title {
    font-family: Asap;
    font-style: italic;
    font-weight: bold;
    font-size: 14px;
    color: #9074bf;
    text-transform: uppercase;
  }

  span.period {
    font-family: Asap;
    font-weight: normal;
    font-size: 12px;
  }

  span.gender-content {
    margin-left: 5px;
  }

  .content-col {
    margin-bottom: 22px;
  }

  .main-value {
    font-family: Asap;
    font-weight: bold;
    font-size: 20px;
    color: #858585;
  }
`;

export const EditButton = styled.button`
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #9386aa;
  border: none;
  background: none;
  display: inline-flex;
  align-items: center;

  svg {
    margin-left: 5px;
  }

  &:hover {
    color: #a9c133;
    cursor: pointer;

    svg {
      margin-left: 5px;
      path {
        fill: #a9c133 !important;
      }
    }
  }
`;