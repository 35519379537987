import React from "react";
import { useSelector } from "react-redux";
import { currencies } from "../../../utils/currency";

import { Select } from "antd";
import CurrencyFlag from "react-currency-flags";

// import { Container } from './styles';

function SelectCurrency({
  id,
  value,
  setValue,
  filterOptions = [],
  onBlur,
  disabled = false,
}) {
  const { translation } = useSelector((state) => state.app);
  return (
    <Select
      id={id}
      style={{ width: 250 }}
      value={value || undefined}
      disabled={disabled}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) => {
        return (
          option.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >=
          0
        );
      }}
      placeholder={translation.defaultSelectPlaceholder}
      onChange={(value) => setValue(value)}
      onBlur={() => {
        if (onBlur && typeof onBlur === "function") {
          onBlur();
        }
      }}
    >
      {currencies
        .filter((currency) =>
          filterOptions.length > 0
            ? filterOptions.includes(currency.code)
            : currency
        )
        .map((currency) => (
          <Select.Option key={currency.code} value={currency.code}>
            <CurrencyFlag currency={currency.code} size="sm" />
            {` ${currency.code} - ${
              translation.currency[currency.code] || currency.name
            }`}
          </Select.Option>
        ))}
    </Select>
  );
}

export default SelectCurrency;
