import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as SupplierActions } from "../../../store/ducks/supplier";
import * as Yup from "yup";
import Axios from "axios";
import { Formik } from "formik";
import {
  Container,
  Footer,
  RadioButtonCustom,
  RadioGroupCustom,
} from "./styles";
/* Components */
import {
  Row,
  Col,
  Input,
  Select,
  Divider,
  Icon,
  notification,
  Spin,
} from "antd";
import ButtonStandard from "../../utils/button";
import ZipCodeInput from "../../utils/input/zipCodeInput";
import DocumentInput from "../../utils/input/documentInput";
import PhoneNumberInput from "../../utils/input/phoneNumberInput";
/* Services */
import {
  validateCNPJ,
  getCep,
  getOnlyNumber,
  validateCPF,
  phoneMask,
} from "../../../services/helpersMethodsService";
import { getStatesInfo, getCitiesByState } from "../../../services/cityService";
import { getSupplierShow } from "../../../services/supplierService";

class DrawerSupplier extends Component {
  initialCountries = [];

  initialStates = [];

  initialCities = [];

  constructor(props) {
    super(props);
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;
    this.state = {
      formSupplier: {
        id: null,
        existingSupplierId: null,
        groupId,
        farmId,
        name: null,
        phoneNumber: "",
        document: "",
        documentType: "CPF",
        email: "",
        zipCode: "",
        address: null,
        city: null,
        state: null,
        country: null,
        status: "Active",
      },
      isLoadingRequest: false,
      loadingCep: false,
      zipCodeValid: null,
      statesArray: [],
      citiesArray: [],
      countriesArray: [],
      saveOptions: "only_save",
    };

    this.formik = React.createRef();
  }

  signal = Axios.CancelToken.source();

  closeDrawer = () => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      supplierActions,
    } = this.props;
    this.signal.cancel();
    this.setState({
      formSupplier: {
        id: null,
        existingSupplierId: null,
        groupId,
        farmId,
        name: null,
        phoneNumber: "",
        document: "",
        documentType: "CPF",
        email: "",
        zipCode: "",
        address: null,
        city: null,
        state: null,
        country: null,
        status: "Active",
      },
      isLoadingRequest: false,
      loadingCep: false,
      zipCodeValid: null,
      statesArray: [],
      citiesArray: [],
      countriesArray: [],
      saveOptions: "only_save",
    });
    this.formik.current.resetForm({
      id: null,
      existingSupplierId: null,
      groupId,
      farmId,
      name: null,
      phoneNumber: "",
      document: "",
      documentType: "CPF",
      email: "",
      zipCode: "",
      address: null,
      city: null,
      state: null,
      country: null,
      status: "Active",
    });
    supplierActions.hideDrawer();
  };

  adjustSupplierCityInfo = (supplierData) => {
    const adjustedSupplierInfo = { ...supplierData };
    const { city } = supplierData;
    adjustedSupplierInfo.city = city?.name;
    adjustedSupplierInfo.state = city?.state?.name;
    adjustedSupplierInfo.country = city?.state?.country?.name;
    return adjustedSupplierInfo;
  };

  validateForm = (form) => {
    if (
      form.document !== null &&
      form.document.trim() !== "" &&
      form.documentType === "CPF" &&
      !validateCPF(form.document)
    ) {
      notification.error({
        message: "CPF incorreto !",
        description: "Por favor, informe um CPF válido.",
      });
      return false;
    } else if (
      form.document !== null &&
      form.document.trim() !== "" &&
      form.documentType === "CNPJ" &&
      !validateCNPJ(form.document)
    ) {
      notification.error({
        message: "CNPJ incorreto !",
        description: "Por favor, informe um CNPJ válido.",
      });
      return false;
    }
    return true;
  };

  handleSubmitModalForm = (
    values,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) => {
    const {
      supplier: { error },
    } = this.props;
    if (values.document !== null) {
      const document = getOnlyNumber(values.document);
      values.documentType = document.length <= 11 ? "CPF" : "CNPJ";
    } else {
      values.documentType = null;
    }
    if (this.validateForm(values)) {
      // Get only numbers in document value
      if (values.document) values.document = getOnlyNumber(values.document);
      if (values.phoneNumber)
        values.phoneNumber = getOnlyNumber(values.phoneNumber);
      if (values.id === null) {
        this.handleNewSupplier(values);
      } else {
        this.handleUpdateSupplier(values, values.id);
      }
      if (error === null) {
        resetForm({
          id: null,
          existingSupplierId: null,
          name: null,
          type: null,
          phoneNumber: "",
          document: "",
          documentType: "CPF",
          email: "",
          zipCode: "",
          address: null,
          city: null,
          state: null,
          country: null,
          status: "Active",
        });
        this.setState({
          formSupplier: {
            id: null,
            existingSupplierId: null,
            name: null,
            type: null,
            phoneNumber: "",
            document: "",
            documentType: "CPF",
            email: "",
            zipCode: "",
            address: null,
            city: null,
            state: null,
            country: null,
            status: "Active",
          },
          zipCodeValid: null,
        });
      }
    }
  };

  handleNewSupplier = (supplier) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      supplierActions,
    } = this.props;
    try {
      let saveOptions = this.state.saveOptions === "only_save" ? false : true;
      supplierActions.saveSupplier(
        groupId,
        farmId,
        null,
        supplier,
        saveOptions,
        this.signal
      );
      this.setState({
        saveOptions: "only_save",
      });
    } catch (error) {}
  };

  handleUpdateSupplier = (supplier, id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      supplierActions,
    } = this.props;
    try {
      let saveOptions = this.state.saveOptions === "only_save" ? false : true;
      supplierActions.saveSupplier(
        groupId,
        farmId,
        id,
        supplier,
        saveOptions,
        this.signal
      );
      this.setState({
        saveOptions: "only_save",
      });
    } catch (error) {}
  };

  handleZipCodeSearch = async (event, props) => {
    const { formSupplier } = this.state;

    if (event.target.value.length === 9) {
      this.setState({
        loadingCep: true,
        zipCodeValid: false,
      });
      try {
        const {
          data: {
            erro,
            logradouro: street,
            uf: state,
            localidade: city,
            complemento: complementAddress,
          },
        } = await getCep(event.target.value);
        if (erro) {
          this.setState({
            loadingCep: false,
            zipCodeValid: false,
          });
        } else {
          const countries = this.initialCountries.filter(
            (c) => c.name === "Brasil"
          );
          const states = this.initialStates.filter((s) => s.name === state);

          const { data } = await getCitiesByState(states[0].id);
          let selectedCity = null;
          if (data) {
            const cities = data.results.filter(
              (c) => c.name.toLowerCase() === city.toLowerCase()
            );

            selectedCity = cities[0].name;

            this.setState({
              citiesArray: data.results,
            });
          }
          props.setValues({
            ...props.values,
            address: street,
            state: states[0].name,
            city: selectedCity,
            complementAddress,
            country: countries[0].name,
          });
          this.setState({
            formSupplier: {
              ...formSupplier,
              address: street,
              state: states[0].name,
              city: selectedCity,
              complementAddress,
              country: countries[0].name,
            },
            loadingCep: false,
            zipCodeValid: true,
          });
        }
      } catch (error) {
        this.setState({
          loadingCep: false,
          zipCodeValid: false,
        });
      }
    }
  };

  handleDrawerVisible = async (visible) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      supplier: {
        idSupplierEditOrDetails,
        drawerActionType,
        blockChooseGroupSelection,
      },
    } = this.props;

    if (visible) {
      this.setState({
        zipCodeInvalid: null,
        isLoadingRequest: true,
      });

      try {
        /** Get states info */
        const { data } = await getStatesInfo();
        if (data) {
          /** Fill the initialStates and initialCountries */
          this.initialStates = data.results;
          this.initialCountries = [
            {
              id: data.results[0].countryId,
              name: data.results[0].countryName,
            },
          ];

          this.setState({
            statesArray: this.initialStates,
            countriesArray: this.initialCountries,
          });

          /** If i have passing a id so get the info about the supplier */
          if (idSupplierEditOrDetails) {
            const {
              data: { results: supplierData },
            } = await getSupplierShow({
              groupId,
              farmId,
              id: idSupplierEditOrDetails,
            });

            /** If return something ... */
            if (supplierData != null) {
              if (supplierData.city !== null) {
                const filteredStates = this.initialStates.filter(
                  (s) => s.name === supplierData.city.state.name
                );
                const { data } = await getCitiesByState(filteredStates[0].id);

                if (data) {
                  this.setState({
                    citiesArray: data.results,
                  });
                }
              }
              const supplierAdjusted =
                this.adjustSupplierCityInfo(supplierData);

              const formSupplier = {
                ...supplierAdjusted,
                phoneNumber: phoneMask(supplierAdjusted.phoneNumber),
              };

              this.setState({
                formSupplier,
              });
            }
          } else {
            this.setState({
              formSupplier: {
                id: null,
                groupId,
                farmId: blockChooseGroupSelection ? null : farmId,
                existingSupplierId: null,
                name: null,
                type:
                  drawerActionType === "new_supplement_supplier"
                    ? "Supplement"
                    : drawerActionType === "new_others_supplier"
                    ? "Others"
                    : drawerActionType === "new_veterinary_supplement_supplier"
                    ? "VeterinarySupplement"
                    : drawerActionType === "new_animal_supplier"
                    ? "CattleFarmer"
                    : null,
                phoneNumber: "",
                document: "",
                documentType: "CPF",
                email: "",
                zipCode: "",
                address: null,
                city: null,
                state: null,
                country: null,
                status: "Active",
              },
              zipCodeValid: null,
            });
          }
        }
      } catch (error) {
        this.setState({
          formSupplier: {
            id: null,
            groupId,
            farmId: blockChooseGroupSelection ? null : farmId,
            existingSupplierId: null,
            name: null,
            type: null,
            phoneNumber: "",
            document: "",
            documentType: "CPF",
            email: "",
            zipCode: "",
            address: null,
            city: null,
            state: null,
            country: null,
            status: "Active",
          },
          zipCodeValid: null,
        });
      } finally {
        this.setState({
          isLoadingRequest: false,
        });
      }
    } else {
      this.setState({
        formSupplier: {
          id: null,
          groupId,
          farmId,
          existingSupplierId: null,
          name: null,
          type: null,
          phoneNumber: "",
          document: "",
          documentType: "CPF",
          email: "",
          zipCode: "",
          address: null,
          city: null,
          state: null,
          country: null,
          status: "Active",
        },
        isLoadingRequest: false,
        zipCodeValid: null,
      });
      this.formik.current.resetForm({
        id: null,
        existingSupplierId: null,
        groupId,
        farmId,
        name: null,
        phoneNumber: "",
        document: "",
        documentType: "CPF",
        email: "",
        zipCode: "",
        address: null,
        city: null,
        state: null,
        country: null,
        status: "Active",
      });
    }
  };

  handleCountryChange = (countryName) => {
    const country = this.initialCountries.filter((c) => c.name === countryName);
    const filteredStates = this.initialStates.filter(
      (s) => s.countryId === country[0].id
    );
    this.setState({ statesArray: filteredStates });
  };

  handleStateChange = async (stateName) => {
    const state = this.initialStates.filter((s) => s.name === stateName);
    const { data } = await getCitiesByState(state[0].id);

    if (data) {
      const filteredCities = data.results;

      this.setState({ citiesArray: filteredCities });
    }
    this.formik.current.setFieldValue("city", undefined);
  };

  render() {
    const {
      app: { translation, groupSelected, farmSelected },
      supplier: {
        drawerVisible,
        isLoadingRequest: isLoadingRequestRedux,
        blockChooseSupplierType,
        blockChooseGroupSelection,
      },
    } = this.props;

    const {
      formSupplier,
      statesArray,
      citiesArray,
      countriesArray,
      loadingCep,
      zipCodeValid,
      isLoadingRequest,
    } = this.state;

    const validationSchema = Yup.object().shape({
      name: Yup.string().required().trim(),
      type: Yup.string().required(),
      email: Yup.string().email().nullable(),
      phoneNumber: Yup.string().min(14).max(15).nullable(),
    });

    const { Option } = Select;

    return (
      <Container
        title={
          formSupplier?.id != null
            ? translation.supplier.drawerTitleEdit
            : translation.supplier.drawerTitle
        }
        width={511}
        onClose={this.closeDrawer}
        maskClosable={false}
        visible={drawerVisible}
        afterVisibleChange={this.handleDrawerVisible}
      >
        <Spin spinning={isLoadingRequest || isLoadingRequestRedux}>
          <Formik
            ref={this.formik}
            enableReinitialize={true}
            initialValues={formSupplier}
            initialErrors={{}}
            onSubmit={this.handleSubmitModalForm}
            validationSchema={validationSchema}
            render={(props) => (
              <form onSubmit={props.handleSubmit} autoComplete="off">
                <div className="drawerForm">
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <span style={{ fontSize: 14, color: "#000" }}>
                        {formSupplier?.id != null
                          ? translation.supplier.drawerSubTitleEdit
                          : translation.supplier.drawerSubTitle}
                      </span>
                    </Col>
                  </Row>
                  {/* Supplier Visibility */}
                  {formSupplier?.id === null && (
                    <>
                      <Row
                        type="flex"
                        style={{ marginTop: "13px" }}
                        align="middle"
                      >
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Row>
                            <label>
                              {translation.supplier.form.groupFarmVisibility}
                            </label>
                          </Row>
                          <Row>
                            <RadioGroupCustom
                              value={
                                props.values.groupId != null &&
                                props.values.farmId != null
                                  ? "farm"
                                  : props.values.groupId != null &&
                                    props.values.farmId == null
                                  ? "group"
                                  : "farm"
                              }
                              buttonStyle="solid"
                            >
                              <RadioButtonCustom
                                value="farm"
                                disabled={blockChooseGroupSelection}
                                onChange={(e) => {
                                  props.setValues({
                                    ...props.values,
                                    groupId: groupSelected.id,
                                    farmId: farmSelected.id,
                                  });
                                  this.setState({
                                    formSupplier: {
                                      ...formSupplier,
                                      groupId: groupSelected.id,
                                      farmId: farmSelected.id,
                                    },
                                  });
                                }}
                              >
                                {translation.supplier.form.radiogroup.farm}
                              </RadioButtonCustom>
                              <RadioButtonCustom
                                value="group"
                                onChange={(e) => {
                                  props.setValues({
                                    ...props.values,
                                    groupId: groupSelected.id,
                                    farmId: null,
                                  });
                                  this.setState({
                                    formSupplier: {
                                      ...formSupplier,
                                      groupId: groupSelected.id,
                                      farmId: null,
                                    },
                                  });
                                }}
                              >
                                {translation.supplier.form.radiogroup.group}:
                                {groupSelected.name}
                              </RadioButtonCustom>
                            </RadioGroupCustom>
                          </Row>
                        </Col>
                      </Row>
                      <Divider />
                    </>
                  )}
                  {/* Errors */}
                  {Object.entries(props.errors).length > 0 &&
                    props.submitCount > 0 && (
                      <>
                        <Row type="flex" justify="center" align="middle">
                          <label className="error">
                            {translation.error.formError}
                          </label>
                        </Row>
                        {Object.keys(props.errors).includes("phoneNumber") && (
                          <Row type="flex" justify="center" align="middle">
                            <label className="error">
                              Formato do contato inválido.
                            </label>
                          </Row>
                        )}
                      </>
                    )}
                  {/* Document */}
                  <Row type="flex" style={{ marginTop: 13 }} align="middle">
                    <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                      <Row>
                        <label>{translation.supplier.form.document} </label>
                      </Row>
                      <Row
                        type="flex"
                        justify="start"
                        gutter={4}
                        align="middle"
                      >
                        <Col span={16}>
                          <DocumentInput
                            kind="mixed"
                            placeholder={
                              translation.supplier.form.documentPlaceHolder
                            }
                            value={props.values.document}
                            disabled={formSupplier?.existingSupplierId != null}
                            name="document"
                            autoComplete="chrome-off"
                            onChange={(e) => {
                              props.setValues({
                                ...props.values,
                                document: e.target.value,
                              });
                              this.setState({
                                formSupplier: {
                                  ...formSupplier,
                                  document: e.target.value,
                                },
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* Input name  */}
                  <Row type="flex" style={{ marginTop: 13 }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.name && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.supplier.form.name}*
                        </label>
                      </Row>
                      <Row>
                        <Input
                          placeholder={
                            translation.supplier.form.namePlaceHolder
                          }
                          value={props.values.name}
                          name="name"
                          disabled={formSupplier?.existingSupplierId != null}
                          autoComplete="chrome-off"
                          onChange={(e) => {
                            props.setValues({
                              ...props.values,
                              name: e.target.value,
                            });
                            this.setState({
                              formSupplier: {
                                ...formSupplier,
                                name: e.target.value,
                              },
                            });
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  {/* Input type */}
                  <Row type="flex" style={{ marginTop: 13 }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.type && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.supplier.form.type}*
                        </label>
                      </Row>
                      <Row>
                        <Select
                          placeholder={
                            translation.supplier.form.typePlaceHolder
                          }
                          value={
                            props.values.type != null
                              ? props.values.type
                              : undefined
                          }
                          name="type"
                          disabled={
                            formSupplier?.existingSupplierId != null ||
                            blockChooseSupplierType
                          }
                          onChange={(value) => {
                            props.setValues({
                              ...props.values,
                              type: value,
                            });
                            this.setState({
                              formSupplier: {
                                ...formSupplier,
                                type: value,
                              },
                            });
                          }}
                        >
                          <Option key="CattleFarmer" value="CattleFarmer">
                            {translation.supplier.types.cattleFarmer}
                          </Option>
                          <Option key="Supplement" value="Supplement">
                            {translation.supplier.types.supplement}
                          </Option>
                          <Option key="Technology" value="Technology">
                            {translation.supplier.types.technology}
                          </Option>
                          <Option
                            key="VeterinarySupplement"
                            value="VeterinarySupplement"
                          >
                            {translation.supplier.types.veterinarySupplement}
                          </Option>
                          <Option key="Others" value="Others">
                            {translation.supplier.types.others}
                          </Option>
                        </Select>
                      </Row>
                    </Col>
                  </Row>
                  {/* Input phoneNumber  */}
                  <Row type="flex" style={{ marginTop: 13 }} align="middle">
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                      <Row>
                        <label
                          className={
                            props.errors.phoneNumber && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.supplier.form.phoneNumber}
                        </label>
                      </Row>
                      <Row>
                        <PhoneNumberInput
                          placeholder={
                            translation.supplier.form.phoneNumberPlaceHolder
                          }
                          value={props.values.phoneNumber}
                          name="phoneNumber"
                          disabled={formSupplier?.existingSupplierId != null}
                          onChange={(e) => {
                            props.setValues({
                              ...props.values,
                              phoneNumber: e.target.value,
                            });
                            this.setState({
                              formSupplier: {
                                ...formSupplier,
                                phoneNumber: e.target.value,
                              },
                            });
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  {/* Input email */}
                  <Row type="flex" style={{ marginTop: 13 }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.email && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.supplier.form.email}
                        </label>
                      </Row>
                      <Row>
                        <Input
                          name="email"
                          placeholder={
                            translation.supplier.form.emailPlaceHolder
                          }
                          disabled={formSupplier?.existingSupplierId != null}
                          value={props.values.email}
                          type="email"
                          autoCapitalize="off"
                          onChange={(e) => {
                            props.setValues({
                              ...props.values,
                              email: e.target.value.toLowerCase(),
                            });
                            this.setState({
                              formSupplier: {
                                ...formSupplier,
                                email: e.target.value.toLowerCase(),
                              },
                            });
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <Divider />
                  {/* Input zipcode */}
                  <Row type="flex" style={{ marginTop: 13 }} align="middle">
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Row>
                        <label>{translation.farm.form.zipCode}</label>
                      </Row>
                      <Row>
                        <ZipCodeInput
                          placeholder={translation.farm.form.zipCodePlaceHolder}
                          type="text"
                          value={props.values.zipCode}
                          disabled={formSupplier?.existingSupplierId != null}
                          autoComplete="chrome-off"
                          name="zipCode"
                          onChange={(e) => {
                            props.setValues({
                              ...props.values,
                              zipCode: e.target.value,
                            });
                            this.setState({
                              formSupplier: {
                                ...formSupplier,
                                zipCode: e.target.value,
                              },
                              zipCodeValid: null,
                            });
                          }}
                          onBlur={(e) => this.handleZipCodeSearch(e, props)}
                        />
                      </Row>
                    </Col>
                    <Col xs={14} sm={14} md={14} lg={14} xl={14} push={1}>
                      {loadingCep === true ? (
                        <Row style={{ marginTop: 18 }}>
                          <span
                            style={{
                              color: "#4b4b4b",
                              fontWeight: "bold",
                            }}
                          >
                            <Icon type="loading" style={{ marginRight: 10 }} />
                            Processando
                          </span>
                        </Row>
                      ) : zipCodeValid === true ? (
                        <Row style={{ marginTop: 18 }}>
                          <span
                            style={{
                              color: "#33C162",
                              fontWeight: "bold",
                            }}
                          >
                            <Icon
                              type="check-circle"
                              style={{
                                marginRight: 6,
                                color: "#33C162",
                                fontSize: 12,
                              }}
                            />
                            {translation.zipCodeValid}
                          </span>
                        </Row>
                      ) : zipCodeValid === false ? (
                        <Row style={{ marginTop: 18 }}>
                          <span
                            style={{ color: "#D44C4C", fontWeight: "bold" }}
                          >
                            <Icon
                              type="close-circle"
                              style={{
                                marginRight: 6,
                                color: "#D44C4C",
                                fontSize: 12,
                              }}
                            />
                            {translation.zipCodeInvalid}
                          </span>
                        </Row>
                      ) : null}
                    </Col>
                  </Row>
                  {/* Input address */}
                  <Row type="flex" style={{ marginTop: 13 }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label>{translation.supplier.form.address}</label>
                      </Row>
                      <Row>
                        <Input
                          placeholder={translation.farm.form.addressPlaceHolder}
                          value={props.values.address}
                          name="address"
                          autoComplete="chrome-off"
                          disabled={
                            loadingCep ||
                            formSupplier?.existingSupplierId != null
                          }
                          onChange={(e) => {
                            props.setValues({
                              ...props.values,
                              address: e.target.value,
                            });
                            this.setState({
                              formSupplier: {
                                ...formSupplier,
                                address: e.target.value,
                              },
                            });
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  {/* Input city and state, country */}
                  <Row type="flex" style={{ marginTop: 13 }} align="middle">
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Row>
                        <label>{translation.supplier.form.country}</label>
                      </Row>
                      <Row>
                        <Select
                          placeholder={
                            translation.supplier.form.countryPlaceHolder
                          }
                          value={
                            props.values.country != null
                              ? props.values.country
                              : undefined
                          }
                          name="country"
                          disabled={
                            loadingCep ||
                            zipCodeValid ||
                            formSupplier?.existingSupplierId != null
                          } //loadingCep}
                          onChange={(value) => {
                            props.setValues({
                              ...props.values,
                              country: value,
                            });
                            this.setState({
                              formSupplier: {
                                ...formSupplier,
                                country: value,
                              },
                            });
                            this.handleCountryChange(value);
                          }}
                        >
                          {countriesArray.length > 0 &&
                            countriesArray.map((c, index) => (
                              <Option key={c.id} value={c.name}>
                                {c.name}
                              </Option>
                            ))}
                        </Select>
                      </Row>
                    </Col>
                    <Col xs={5} sm={5} md={5} lg={5} xl={5} offset={1}>
                      <Row>
                        <label>{translation.farm.form.state}</label>
                      </Row>
                      <Row>
                        <Select
                          placeholder={translation.farm.form.statePlaceHolder}
                          value={
                            props.values.state != null
                              ? props.values.state
                              : undefined
                          }
                          name="state"
                          disabled={
                            loadingCep ||
                            zipCodeValid ||
                            props.values.country == null ||
                            formSupplier?.existingSupplierId != null
                          } //loadingCep}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => {
                            props.setValues({
                              ...props.values,
                              state: value,
                            });
                            this.setState({
                              formSupplier: {
                                ...formSupplier,
                                state: value,
                              },
                            });
                            this.handleStateChange(value);
                          }}
                        >
                          {statesArray.length > 0 &&
                            statesArray.map((state) => (
                              <Option key={state.id} value={state.name}>
                                {state.name}
                              </Option>
                            ))}
                        </Select>
                      </Row>
                    </Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
                      <Row>
                        <label>{translation.supplier.form.city}</label>
                      </Row>
                      <Row>
                        <Select
                          placeholder={
                            translation.supplier.form.cityPlaceHolder
                          }
                          value={
                            props.values.city != null
                              ? props.values.city
                              : undefined
                          }
                          name="city"
                          disabled={
                            loadingCep ||
                            zipCodeValid ||
                            props.values.state == null ||
                            formSupplier?.existingSupplierId != null
                          } //loadingCep}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => {
                            props.setValues({
                              ...props.values,
                              city: value,
                            });
                            this.setState({
                              formSupplier: {
                                ...formSupplier,
                                city: value,
                              },
                            });
                          }}
                        >
                          {citiesArray.length > 0 &&
                            citiesArray.map((c) => (
                              <Option key={c.id} value={c.name}>
                                {c.name}
                              </Option>
                            ))}
                        </Select>
                      </Row>
                    </Col>
                  </Row>
                </div>
                {/* Footer */}
                <Footer>
                  <Row type="flex" justify="space-between">
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="selectDiv"
                    >
                      {formSupplier?.id === null ? (
                        <Select
                          value={this.state.saveOptions}
                          name="saveOptions"
                          onChange={(value) =>
                            this.setState({ saveOptions: value })
                          }
                        >
                          <Option key="1" value="only_save">
                            {translation.supplier.saveOptions.only_save}
                          </Option>
                          <Option key="2 " value="create_new">
                            {
                              translation.supplier.saveOptions
                                .save_and_create_new
                            }
                          </Option>
                        </Select>
                      ) : null}
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="buttonsDiv"
                    >
                      <ButtonStandard
                        type="button"
                        buttonType="type7"
                        onClick={this.closeDrawer}
                      >
                        {translation.buttons.cancel}
                      </ButtonStandard>

                      <ButtonStandard
                        buttonType="type6"
                        type="submit"
                        disabled={loadingCep}
                      >
                        {translation.buttons.save}
                      </ButtonStandard>
                    </Col>
                  </Row>
                </Footer>
              </form>
            )}
          />
        </Spin>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  supplier: state.supplier,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  supplierActions: bindActionCreators(SupplierActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerSupplier);
