import React from "react";
import { useSelector } from "react-redux";
import { PicketSupplementSupplyContextProvider } from "../../contexts/picketSupplementSupplyContext";

import { Col, Icon, Row } from "antd";
import { CardCustom, Title } from "./styles";

import PicketSupplementSupplyTable from "./table";
import ButtonStandard from "../../components/utils/button";
import usePicketSupplementSupplyContext from "../../hooks/usePicketSupplementSupplyContext";
import { useEffect } from "react";

function PicketSupplementSupplyLayout() {
  const { translation } = useSelector((state) => state.app);
  const { openDrawer, fetchData } = usePicketSupplementSupplyContext();

  function handleRefresh() {
    fetchData();
  }

  function handleCreateNewRecord() {
    openDrawer();
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CardCustom>
      <Row className="rowHeader" justify="space-between" align="middle">
        <Col span={8}>
          <Title>{translation.picketSupplementSupply.title}</Title>
        </Col>
        <Col span={16}>
          <Row type="flex" justify="end" gutter={8}>
            <Col>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={handleRefresh}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </Col>
            <Col>
              <ButtonStandard
                name="buttonCreateBudget"
                buttonType="type8"
                onClick={handleCreateNewRecord}
              >
                {translation.picketSupplementSupply.buttonNew}
              </ButtonStandard>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <PicketSupplementSupplyTable />
        </Col>
      </Row>
    </CardCustom>
  );
}

function PicketSupplementSupply() {
  return <PicketSupplementSupplyLayout />;
}

export default PicketSupplementSupply;
