import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as PastureActions } from "../../../store/ducks/pasture";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Spin,
  Row,
  Col,
  Input,
  Select,
  Switch,
  notification,
  Icon,
  Modal,
} from "antd";
import ButtonStandard from "../../utils/button";
import {
  Container,
  Footer,
  CustomDivider,
  RadioButtonCustom,
  RadioGroupCustom,
  Bold,
} from "./styles";
import {
  getPastureTypeIndexDropdown,
  getPastureTemplateByTypeId,
} from "../../../services/pastureService";
import DisplayPastureSeason from "./displayPastureSeason";
import DrawerPastureWeatherSeason from "./pastureWeatherSeason";
import {
  applyPastureTemplateToExistantPastureInfo,
  applyPastureTemplatenfo,
} from "../../../utils/pastureBuilder";
import ConfirmActionModal from "../../modals/confirmActionModal";

class DrawerPasture extends Component {
  lastTypeSelected = null;

  isOnlyToFarm = false;

  defaultFarmIdValue = "";

  defaultGroupIdValue = "";

  defaultVisibilityIdValue = "";

  blankFormPasture = {
    id: null,
    name: "",
    pastureType: null,
    entryHeight: null,
    exitHeight: null,
    seasonWater: [
      {
        calcium: null,
        consumptionType: "PV_ANIMAL",
        cost: null,
        cp: null,
        fertilized: true,
        maximumConsumption: null,
        minimumConsumption: null,
        nitrogen: null,
        zinc: null,
        sodium: null,
        phosphorus: null,
        season: "Water",
        tdn: null,
        status: "NI",
        entryHeight: null,
        exitHeight: null,
        fdn: null,
        formation: 0,
        rest: 0,
        growthRate: null,
        leafDensityProduction: null,
        key: Math.random(),
      },
      {
        calcium: null,
        consumptionType: "PV_ANIMAL",
        cost: null,
        cp: null,
        fertilized: false,
        maximumConsumption: null,
        minimumConsumption: null,
        nitrogen: null,
        zinc: null,
        sodium: null,
        phosphorus: null,
        season: "Water",
        tdn: null,
        status: "NI",
        entryHeight: null,
        exitHeight: null,
        fdn: null,
        formation: 0,
        rest: 0,
        growthRate: null,
        leafDensityProduction: null,
        key: Math.random(),
      },
    ],
    seasonDry: [
      {
        calcium: null,
        consumptionType: "PV_ANIMAL",
        cost: null,
        cp: null,
        fertilized: true,
        maximumConsumption: null,
        minimumConsumption: null,
        nitrogen: null,
        zinc: null,
        sodium: null,
        phosphorus: null,
        season: "Dry",
        tdn: null,
        status: "NI",
        entryHeight: null,
        exitHeight: null,
        fdn: null,
        formation: 0,
        rest: 0,
        growthRate: null,
        leafDensityProduction: null,
        key: Math.random(),
      },
      {
        calcium: null,
        consumptionType: "PV_ANIMAL",
        cost: null,
        cp: null,
        fertilized: false,
        maximumConsumption: null,
        minimumConsumption: null,
        nitrogen: null,
        zinc: null,
        sodium: null,
        phosphorus: null,
        season: "Dry",
        tdn: null,
        status: "NI",
        entryHeight: null,
        exitHeight: null,
        fdn: null,
        formation: 0,
        rest: 0,
        growthRate: null,
        leafDensityProduction: null,
        key: Math.random(),
      },
    ],
    seasonTransition: [
      {
        calcium: null,
        consumptionType: "PV_ANIMAL",
        cost: null,
        cp: null,
        fertilized: true,
        maximumConsumption: null,
        minimumConsumption: null,
        nitrogen: null,
        zinc: null,
        sodium: null,
        phosphorus: null,
        season: "Transition",
        tdn: null,
        status: "NI",
        entryHeight: null,
        exitHeight: null,
        fdn: null,
        formation: 0,
        rest: 0,
        growthRate: null,
        leafDensityProduction: null,
        key: Math.random(),
      },
      {
        calcium: null,
        consumptionType: "PV_ANIMAL",
        cost: null,
        cp: null,
        fertilized: false,
        maximumConsumption: null,
        minimumConsumption: null,
        nitrogen: null,
        zinc: null,
        sodium: null,
        phosphorus: null,
        season: "Transition",
        tdn: null,
        status: "NI",
        entryHeight: null,
        exitHeight: null,
        fdn: null,
        formation: 0,
        rest: 0,
        growthRate: null,
        leafDensityProduction: null,
        key: Math.random(),
      },
    ],
    status: "Active",
  };

  resetedState = {
    newTypeSelected: null,
    defaultPastureVisibilityIdValue: null,
    showModalChangeType: false,
    changedPastureType: false,
    pastureTemplateData: null,
    isLoadingPastureType: false,
    listPastureType: null,
    formPasture: { ...this.blankFormPasture },
    saveOptions: "only_save",
    drawerPastureWeatherSeasonVisible: false,
    seasonSelected: null,
  };

  constructor(props) {
    super(props);

    const {
      pasture: { pastureData },
    } = this.props;

    const newState = { ...this.resetedState };
    newState.pastureTemplateData = pastureData;

    this.state = { ...newState };
    this.formRef = React.createRef();
  }

  resetState = () => {
    this.lastTypeSelected = null;
    this.isOnlyToFarm = false;
    this.defaultFarmIdValue = "";
    this.defaultGroupIdValue = "";
    this.defaultVisibilityIdValue = "";
    const newState = { ...this.resetedState };
    this.setState({ ...newState });
  };

  componentDidMount = () => {
    this.handleGroupFarmRadio();
  };

  closeDrawer = () => {
    const { pastureActions } = this.props;
    this.formRef.current.resetForm({ ...this.blankFormPasture });
    pastureActions.hideDrawer();
  };

  showConfirmChangeTypeModal = (value) => {
    this.lastTypeSelected = value;
    const {
      pasture: { pastureData },
    } = this.props;

    if (pastureData) {
      this.setState({ showModalChangeType: true });
    } else {
      this.confirmTypeChange();
    }
  };

  confirmTypeChange = async () => {
    // this.lastTypeSelected = this.newTypeTypeSelected;
    try {
      const {
        app: {
          farmSelected,
          groupSelected,
          farmSelected: { id: farmId },
          groupSelected: { id: groupId },
        },
        pasture: { pastureData },
      } = this.props;

      const {
        data: { results },
      } = await getPastureTemplateByTypeId(
        groupId,
        farmId,
        this.lastTypeSelected
      );

      if (pastureData != null) {
        const strutucteredPasture = applyPastureTemplateToExistantPastureInfo(
          results,
          pastureData
        );
        this.setState({
          ...this.state,
          formPasture: { pastureType: this.lastTypeSelected },
          pastureTemplateData: strutucteredPasture,
          changedPastureType: true,
          showModalChangeType: false,
        });
      } else {
        const strutucteredPasture = applyPastureTemplatenfo(results);
        strutucteredPasture.farmId = farmSelected.id;
        strutucteredPasture.groupId = groupSelected.id;

        this.setState({
          ...this.state,
          formPasture: { pastureType: this.lastTypeSelected },
          pastureTemplateData: strutucteredPasture,
          changedPastureType: true,
          showModalChangeType: false,
        });
      }
    } catch (error) {}
  };

  cancelTypeChange = () => {
    this.setState({
      ...this.state,
      formPasture: { pastureType: this.lastTypeSelected },
      showModalChangeType: false,
    });
  };

  handleSubmitModalForm = async (values, actions) => {
    const {
      pasture: { error },
      app: { translation },
    } = this.props;
    const body = {
      id: values.id,
      name: values.name,
      pastureType: values.pastureType,
      entryHeight: values.entryHeight,
      exitHeight: values.exitHeight,
      status: values.status,
      seasons: [
        ...values.seasonDry,
        ...values.seasonWater,
        ...values.seasonTransition,
      ],
    };
    const title = "Ao confirmar, os itens abaixo devem ser considerados:";
    const content = (
      <ul>
        <li style={{ marginBottom: 26, marginTop: 25 }}>
          Atualize as informações de forrageira em todas as dietas em{" "}
          <Bold>Edição de "Estratégia de dieta", </Bold>
          caso contrário, todas as estratégias estarão considerando os valores
          antigos deste forrageira.
        </li>
        <li style={{ marginBottom: 26 }}>
          Após finalizar o passo acima, <Bold>reprocesse</Bold> todas os
          Cenários de venda - <Bold>Favoritos</Bold> que utilizam as
          <Bold> estratégias acima como baseline,</Bold> caso contrário, todos
          os cenários estarão considerando os valores antigos desta forrageira.
        </li>
        <li>
          Para os <Bold>Cenários de compra,</Bold> você deve atualizar a
          estratégia de dieta e reprocessar o cenário em{" "}
          <Bold>Edição de Cenário de compra</Bold>, caso contrário, todas as
          estratégias e cenários de compra estarão considerando os valores
          antigos desta forrageira.
        </li>
      </ul>
    );
    const fertilizedWithPastureInfoSeasons = body.seasons.filter(
      (s) =>
        s.fertilized &&
        s.cost > 0 &&
        s.cp > 0 &&
        s.tdn > 0 &&
        s.maximumConsumption > 0 &&
        s.minimumConsumption > 0
    ).length;
    const unfertilizedWithPastureInfoSeasons = body.seasons.filter(
      (s) =>
        !s.fertilized &&
        s.cost > 0 &&
        s.cp > 0 &&
        s.tdn > 0 &&
        s.maximumConsumption > 0 &&
        s.minimumConsumption > 0
    ).length;

    if (body.seasons.filter((s) => s.status === "I").length === 0) {
      notification.error({
        message: "Dados incompletos",
        description: translation.pasture.errorNecessaryOneSeason,
      });
    } else if (
      fertilizedWithPastureInfoSeasons > 0 &&
      fertilizedWithPastureInfoSeasons < 3
    ) {
      notification.error({
        message: "Forrageira adubado",
        description: translation.pasture.errorFertilizedSeasonInfoIncomplete,
      });
    } else if (
      unfertilizedWithPastureInfoSeasons > 0 &&
      unfertilizedWithPastureInfoSeasons < 3
    ) {
      notification.error({
        message: "Forrageira não adubado",
        description: translation.pasture.errorUnfertilizedSeasonInfoIncomplete,
      });
    } else if (body.entryHeight < body.exitHeight) {
      notification.error({
        message: "Dados incorretos",
        description:
          translation.pasture.errorEntryHeightLessThanExitEntryHeight,
      });
    } else {
      if (body.id === null) {
        Modal.confirm({
          title,
          content,
          width: 580,
          icon: <Icon type="reload" />,
          onOk: async () => await this.handleNewPasture(body),
        });
      } else {
        Modal.confirm({
          title,
          content,
          width: 580,
          icon: <Icon type="reload" />,
          onOk: async () => await this.handleUpdatePasture(body, body.id),
        });
      }

      if (error === null) {
        actions.setErrors({});
        this.setState({
          formPasture: { ...this.blankFormPasture },
        });
      }
    }
  };

  handleNewPasture = async (pasture) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      pastureActions,
    } = this.props;

    if (!this.isOnlyToFarm) {
      pasture.farmId = null;
    } else {
      pasture.farmId = farmId;
    }

    pasture.groupId = groupId;

    let saveOptions = this.state.saveOptions === "only_save" ? false : true;
    pastureActions.savePasture(groupId, farmId, null, pasture, saveOptions);
  };

  handleUpdatePasture = async (pasture, id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      pastureActions,
    } = this.props;

    if (!this.isOnlyToFarm) {
      pasture.farmId = null;
    } else {
      pasture.farmId = farmId;
    }

    pasture.groupId = groupId;

    let saveOptions = this.state.saveOptions === "only_save" ? false : true;

    pastureActions.savePasture(groupId, farmId, id, pasture, saveOptions);
  };

  handleDrawerVisible = async (visible) => {
    let listPastureType = [];

    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      pasture: { pastureData, idPastureEdit },
      pastureActions,
    } = this.props;

    if (pastureData == null) {
      this.resetState();
    }

    if (visible) {
      this.setState({
        isLoadingPastureType: true,
        newTypeSelected: null,
      });
      try {
        const {
          data: { results },
        } = await getPastureTypeIndexDropdown();
        listPastureType = results;
        if (idPastureEdit != null) {
          pastureActions.showPasture(groupId, farmId, idPastureEdit);
        }
      } catch (error) {
        this.setState({
          isLoadingPastureType: false,
        });
      } finally {
        this.setState({
          listPastureType,
          isLoadingPastureType: false,
        });
      }
    }
  };

  /* Method will be use in button on DisplayPastureSeason */
  handleEditSeason = (key, season) => {
    const {
      state: {
        values: { seasonWater, seasonTransition, seasonDry },
      },
    } = this.formRef.current;

    let seasonSelected = null;

    switch (season) {
      case "Water":
        seasonSelected = seasonWater.find((s) => s.key === key);
        break;
      case "Transition":
        seasonSelected = seasonTransition.find((s) => s.key === key);
        break;
      case "Dry":
        seasonSelected = seasonDry.find((s) => s.key === key);
        break;
      default:
        break;
    }

    if (seasonSelected != null) {
      this.setState({
        drawerPastureWeatherSeasonVisible: true,
        seasonSelected,
      });
    }
  };

  /* Method will be use in button on DisplayPastureSeason */
  handleDeleteSeason = (key, season) => {
    const {
      state: {
        values: { seasonWater, seasonTransition, seasonDry },
      },
    } = this.formRef.current;
    switch (season) {
      case "Water":
        const newSeasonWater = seasonWater.map((s) => {
          if (s.key === key) {
            s.calcium = null;
            s.consumptionType = "PV_ANIMAL";
            s.cost = null;
            s.cp = null;
            s.maximumConsumption = null;
            s.minimumConsumption = null;
            s.nitrogen = null;
            s.phosphorus = null;
            s.zinc = null;
            s.sodium = null;
            s.tdn = null;
            s.entryHeight = null;
            s.exitHeight = null;
            s.fdn = null;
            s.formation = 0;
            s.rest = 0;
            s.growthRate = null;
            s.leafDensityProduction = null;
            s.status = "NI";
          }
          return s;
        });
        this.setState({
          formPasture: {
            ...this.state.formPasture,
            seasonWater: newSeasonWater,
          },
        });
        this.formRef.current.setFieldValue("seasonWater", newSeasonWater);
        break;
      case "Transition":
        const newSeasonTransition = seasonTransition.map((s) => {
          if (s.key === key) {
            s.calcium = null;
            s.consumptionType = "PV_ANIMAL";
            s.cost = null;
            s.cp = null;
            s.maximumConsumption = null;
            s.minimumConsumption = null;
            s.nitrogen = null;
            s.phosphorus = null;
            s.zinc = null;
            s.sodium = null;
            s.tdn = null;
            s.tdn = null;
            s.entryHeight = null;
            s.exitHeight = null;
            s.fdn = null;
            s.formation = 0;
            s.rest = 0;
            s.growthRate = null;
            s.leafDensityProduction = null;
            s.status = "NI";
          }
          return s;
        });
        this.setState({
          formPasture: {
            ...this.state.formPasture,
            seasonTransition: newSeasonTransition,
          },
        });
        this.formRef.current.setFieldValue(
          "seasonTransition",
          newSeasonTransition
        );
        break;
      case "Dry":
        const newSeasonDry = seasonDry.map((s) => {
          if (s.key === key) {
            s.calcium = null;
            s.consumptionType = "PV_ANIMAL";
            s.cost = null;
            s.cp = null;
            s.maximumConsumption = null;
            s.minimumConsumption = null;
            s.nitrogen = null;
            s.phosphorus = null;
            s.zinc = null;
            s.sodium = null;
            s.tdn = null;
            s.tdn = null;
            s.entryHeight = null;
            s.exitHeight = null;
            s.fdn = null;
            s.formation = 0;
            s.rest = 0;
            s.growthRate = null;
            s.leafDensityProduction = null;
            s.status = "NI";
          }
          return s;
        });
        this.setState({
          formPasture: {
            ...this.state.formPasture,
            seasonDry: newSeasonDry,
          },
        });
        this.formRef.current.setFieldValue("seasonDry", newSeasonDry);
        break;
      default:
        break;
    }
  };

  /* Method will save in the props.values of the form. This will be used by DrawerPastureWeatherSeason */

  handleSaveSeason = (data) => {
    if (data != null) {
      const {
        state: {
          values: { seasonWater, seasonTransition, seasonDry },
        },
      } = this.formRef.current;
      switch (data.season) {
        case "Water":
          const newSeasonWater = seasonWater.map((s) => {
            if (s.key === data.key) s = data;
            return s;
          });
          this.setState({
            formPasture: {
              ...this.state.formPasture,
              seasonWater: newSeasonWater,
            },
          });
          this.formRef.current.setFieldValue("seasonWater", newSeasonWater);
          break;
        case "Transition":
          const newSeasonTransition = seasonTransition.map((s) => {
            if (s.key === data.key) s = data;
            return s;
          });
          this.setState({
            formPasture: {
              ...this.state.formPasture,
              seasonTransition: newSeasonTransition,
            },
          });
          this.formRef.current.setFieldValue(
            "seasonTransition",
            newSeasonTransition
          );
          break;
        case "Dry":
          const newSeasonDry = seasonDry.map((s) => {
            if (s.key === data.key) s = data;
            return s;
          });
          this.setState({
            formPasture: {
              ...this.state.formPasture,
              seasonDry: newSeasonDry,
            },
          });
          this.formRef.current.setFieldValue("seasonDry", newSeasonDry);
          break;
        default:
          break;
      }
      this.setState({
        drawerPastureWeatherSeasonVisible: false,
        seasonSelected: null,
      });
    }
  };

  handleGroupFarmRadio = () => {
    const {
      app: { farmSelected, groupSelected },
      pasture: { pastureData },
    } = this.props;

    this.defaultFarmIdValue =
      pastureData != null && pastureData.farmId != null
        ? pastureData.farmId
        : farmSelected != null && farmSelected.id != null
        ? farmSelected.id
        : null;
    this.defaultGroupIdValue =
      pastureData != null && pastureData.groupId != null
        ? pastureData.groupId
        : groupSelected != null && groupSelected.id != null
        ? groupSelected.id
        : null;

    if (this.state.defaultPastureVisibilityIdValue == null) {
      this.defaultVisibilityIdValue =
        pastureData == null
          ? this.defaultFarmIdValue
          : pastureData.farmId == null
          ? this.defaultGroupIdValue
          : pastureData.farmId;
    } else {
      this.defaultVisibilityIdValue =
        this.state.defaultPastureVisibilityIdValue;
    }

    this.isOnlyToFarm =
      this.defaultFarmIdValue === this.defaultVisibilityIdValue;
  };

  handleChangeVisibility = (event) => {
    this.setState({ defaultPastureVisibilityIdValue: event.target.value });
  };

  /* Method will cancel the edit of a season. This will be used by DrawerPastureWeatherSeason */

  handleCancelSeason = () => {
    this.setState({
      drawerPastureWeatherSeasonVisible: false,
      seasonSelected: null,
    });
  };

  render() {
    const {
      app: { translation, groupSelected },
      pasture: { pastureData, drawerVisible, isLoadingRequest },
    } = this.props;

    const { pastureTemplateData } = this.state;

    this.handleGroupFarmRadio();

    const {
      isLoadingPastureType,
      listPastureType,
      formPasture,
      drawerPastureWeatherSeasonVisible,
      seasonSelected,
    } = this.state;

    const validationSchema = Yup.object().shape({
      name: Yup.string().required(),
      pastureType: Yup.object().required(),
      entryHeight: Yup.number().required(),
      exitHeight: Yup.number().required(),
    });

    const { Option } = Select;

    return (
      <Container
        title={
          pastureData != null
            ? translation.pasture.drawerTitleEdit
            : translation.pasture.drawerTitle
        }
        width={600}
        afterVisibleChange={this.handleDrawerVisible}
        onClose={this.closeDrawer}
        maskClosable={false}
        visible={drawerVisible}
      >
        <Spin spinning={isLoadingRequest}>
          <Formik
            ref={this.formRef}
            enableReinitialize={true}
            initialValues={
              pastureData != null && !this.state.changedPastureType
                ? pastureData
                : pastureTemplateData != null
                ? pastureTemplateData
                : formPasture
            }
            onSubmit={this.handleSubmitModalForm}
            validationSchema={validationSchema}
            render={(props) => (
              <form onSubmit={props.handleSubmit} autoComplete="off">
                <div className="drawerForm">
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <span style={{ fontSize: "14px", color: "#000" }}>
                        {pastureData != null
                          ? translation.pasture.drawerSubTitleEdit
                          : translation.pasture.drawerSubTitle}
                      </span>
                    </Col>
                  </Row>
                  {pastureData != null && pastureData.status !== "Pending" ? (
                    <Row
                      type="flex"
                      style={{ marginTop: "13px" }}
                      justify="space-between"
                      align="middle"
                    >
                      <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                        <label
                          style={{
                            color: "#9074bf",
                            fontWeight: "bold",
                          }}
                        >
                          {translation.pasture.form.status}
                        </label>
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6} align="right">
                        {props.values.status === "Active" ? (
                          <span
                            style={{
                              color: "#A9C133",
                              fontFamily: "Asap",
                              fontWeight: "normal",
                              marginRight: 6,
                            }}
                          >
                            {translation.status.active}
                          </span>
                        ) : (
                          <span
                            style={{
                              color: "#D44C4C",
                              fontFamily: "Asap",
                              fontWeight: "normal",
                              marginRight: 6,
                            }}
                          >
                            {translation.status.inactive}
                          </span>
                        )}
                        <Switch
                          checked={
                            props.values.status === "Active" ? true : false
                          }
                          onChange={(checked, event) => {
                            props.setValues({
                              ...props.values,
                              status: checked ? "Active" : "Inactive",
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  ) : null}
                  {Object.entries(props.errors).length > 0 &&
                    props.submitCount > 0 && (
                      <Row type="flex" justify="center" align="middle">
                        <label className="error">
                          {translation.error.formError}
                        </label>
                      </Row>
                    )}
                  {/* Pasture Visibility */}
                  {pastureData === null && (
                    <Row
                      type="flex"
                      style={{ marginTop: "13px" }}
                      align="middle"
                    >
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row>
                          <label
                            className={
                              props.errors.name && props.submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            {translation.pasture.form.groupFarmVisibility}*
                          </label>
                        </Row>
                        <Row>
                          <RadioGroupCustom
                            value={
                              this.state.defaultPastureVisibilityIdValue != null
                                ? this.state.defaultPastureVisibilityIdValue
                                : this.defaultVisibilityIdValue
                            }
                            buttonStyle="solid"
                          >
                            <RadioButtonCustom
                              value={this.defaultFarmIdValue}
                              onChange={(e) => this.handleChangeVisibility(e)}
                            >
                              {translation.pasture.form.radiogroup.farm}
                            </RadioButtonCustom>
                            <RadioButtonCustom
                              value={this.defaultGroupIdValue}
                              onChange={(e) => this.handleChangeVisibility(e)}
                            >
                              {translation.pasture.form.radiogroup.group}:
                              {groupSelected.name}
                            </RadioButtonCustom>
                          </RadioGroupCustom>
                        </Row>
                      </Col>
                    </Row>
                  )}
                  {/* Input system identification */}
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.pastureType && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.pasture.form.pastureType}*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          name="pastureType"
                          value={
                            this.state.newTypeSelected != null
                              ? this.lastTypeSelected
                              : listPastureType != null &&
                                props.values.pastureType != null
                              ? props.values.pastureType.id
                              : undefined
                          }
                          loading={isLoadingPastureType}
                          placeholder={translation.defaultSelectPlaceholder}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onSelect={(value) => {
                            this.showConfirmChangeTypeModal(value);
                          }}
                        >
                          {listPastureType != null &&
                          Object.entries(listPastureType).length > 0
                            ? listPastureType.map((ptype) => (
                                <Option key={ptype.id} value={ptype.id}>
                                  {ptype.name}
                                </Option>
                              ))
                            : null}
                        </Select>
                      </Row>
                    </Col>
                  </Row>
                  {/* Input system identification */}
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.name && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.pasture.form.name}*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Input
                          placeholder={translation.pasture.form.namePlaceHolder}
                          value={props.values.name}
                          name="name"
                          autoComplete="chrome-off"
                          onChange={(e) => {
                            props.setFieldValue("name", e.target.value);
                            this.setState({
                              formPasture: {
                                ...this.state.formPasture,
                                name: e.target.value,
                              },
                            });
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  {/* Seasons */}
                  <CustomDivider dashed>
                    INFORMAÇÕES NUTRICIONAIS POR ESTAÇÕES CLIMÁTICAS
                  </CustomDivider>
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <DisplayPastureSeason
                        seasonType="Water"
                        data={props.values.seasonWater}
                        onEdit={this.handleEditSeason}
                        onDelete={this.handleDeleteSeason}
                      />
                    </Col>
                  </Row>
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <DisplayPastureSeason
                        seasonType="Transition"
                        data={props.values.seasonTransition}
                        onEdit={this.handleEditSeason}
                        onDelete={this.handleDeleteSeason}
                      />
                    </Col>
                  </Row>
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <DisplayPastureSeason
                        seasonType="Dry"
                        data={props.values.seasonDry}
                        onEdit={this.handleEditSeason}
                        onDelete={this.handleDeleteSeason}
                      />
                    </Col>
                  </Row>
                </div>
                {/* Footer */}
                <Footer>
                  <Row type="flex" justify="space-between">
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="selectDiv"
                    >
                      {props.values.id === null ? (
                        <Select
                          value={this.state.saveOptions}
                          name="saveOptions"
                          onChange={(value) =>
                            this.setState({ saveOptions: value })
                          }
                        >
                          <Option key="1" value="only_save">
                            {translation.pasture.saveOptions.only_save}
                          </Option>
                          <Option key="2 " value="save_and_create_new">
                            {
                              translation.pasture.saveOptions
                                .save_and_create_new
                            }
                          </Option>
                        </Select>
                      ) : null}
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="buttonsDiv"
                    >
                      <ButtonStandard
                        type="button"
                        buttonType="type7"
                        onClick={this.closeDrawer}
                      >
                        {translation.buttons.cancel}
                      </ButtonStandard>

                      <ButtonStandard type="submit" buttonType="type6">
                        {translation.buttons.save}
                      </ButtonStandard>
                    </Col>
                  </Row>
                </Footer>
              </form>
            )}
          />
          <DrawerPastureWeatherSeason
            drawerVisible={drawerPastureWeatherSeasonVisible}
            data={seasonSelected}
            onSave={this.handleSaveSeason}
            onCancel={this.handleCancelSeason}
          />
        </Spin>
        <ConfirmActionModal
          show={this.state.showModalChangeType}
          title={translation.pasture.modal.confirmation.title}
          message={translation.pasture.modal.confirmation.message}
          confirmAction={this.confirmTypeChange}
          cancelAction={this.cancelTypeChange}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  pasture: state.pasture,
  changedPastureType: state.changedPastureType,
});

const mapDispatchToProps = (dispatch) => ({
  pastureActions: bindActionCreators(PastureActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerPasture);
