import React, { useState, useEffect } from "react";
import CanvasJSReact from "../../../../assets/canvasjs.react";
import { Container, Title } from "./styles";
import { Row, Col,  } from "antd";
import InfoTooltip from "../../../../components/utils/infoTooltip";
const toggleDataSeries = (e) => {
  if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
    e.dataSeries.visible = false;
  } else {
    e.dataSeries.visible = true;
  }
  e.chart.render();
};
function LotProfitDash({ externalData }) {
  // Variables
  const { CanvasJSChart } = CanvasJSReact;
  const [data, setData] = useState(null);
  const chartOptions = {
    animationEnabled: true,
    zoomEnabled: true,
    height: 200,
    toolTip: {
      fontFamily: "Asap",
      shared: true,
    },
    axisY: {
      includeZero: true,
      prefix: "R$ ",
      valueFormatString: "###0.00",
      gridDashType: "dot",
    },
    legend: {
      cursor: "pointer",
      itemclick: toggleDataSeries,
    },
    dataPointWidth: 20,
    data: data,
  };

  // Effects
  useEffect(() => {
    setData(externalData);
  }, [externalData]);

  return (
    <Container>
      <Row type="flex">
        <Col span={24}>
          <Title>Lucratividade<InfoTooltip title="Serão mostrados os dados dos lotes que conterem cenário de vendas favoritos e conterem vendas reais." /></Title> 
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <CanvasJSChart options={chartOptions} />
        </Col>
      </Row>
    </Container>
  );
}

export default LotProfitDash;
