import moment from "moment";

export const Types = {
  RESET_DATA: "purchaseScenario/RESET_DATA",
  CHANGE_TAB: "purchaseScenario/CHANGE_TAB",
  UPDATE_NEW_PURCHASE_SCENARIO: "purchaseScenario/UPDATE_NEW_PURCHASE_SCENARIO",
  UPDATE_DIET_STRATEGY: "purchaseScenario/UPDATE_DIET_STRATEGY",
  UPDATE_BREEDS: "purchaseScenario/UPDATE_BREEDS",
  UPDATE_PAGE_LOADED: "purchaseScenario/UPDATE_PAGE_LOADED",
  SELECT_DIETSTRATEGY_PERIOD: "purchaseScenario/SELECT_DIETSTRATEGY_PERIOD",
};

export const Creators = {
  resetData: () => ({
    type: Types.RESET_DATA,
    payload: {},
  }),
  changeTab: (index) => ({
    type: Types.CHANGE_TAB,
    payload: {
      index,
    },
  }),
  updateNewPurchaseScenario: (newPurchaseScenario) => ({
    type: Types.UPDATE_NEW_PURCHASE_SCENARIO,
    payload: {
      newPurchaseScenario,
    },
  }),
  updateDietStrategy: (dietStrategy) => ({
    type: Types.UPDATE_DIET_STRATEGY,
    payload: {
      dietStrategy,
    },
  }),
  updateBreeds: (breeds) => ({
    type: Types.UPDATE_BREEDS,
    payload: {
      breeds,
    },
  }),
  updatePageLoaded: (pageLoaded) => ({
    type: Types.UPDATE_PAGE_LOADED,
    payload: {
      pageLoaded,
    },
  }),
  selectDietStrategyPeriod: (data) => ({
    type: Types.SELECT_DIETSTRATEGY_PERIOD,
    payload: { data },
  }),
};

const INITIAL_STATE = {
  // Form that will guard all info to send to backend
  newPurchaseScenario: {
    id: null,
    name: null,
    marginProfit: 0,
    breedId: null,
    breedName: null,
    asymptoticWeight: null,
    cap: false,
    gender: "Male",
    marketAnalysisId: null,
    dietStrategyId: null,
    createNewDietStrategy: false,
    initialSimulationDate: moment().day(1),
    productionSubSystem: null,
    status: "Analyzing",
    minWeight: null,
    maxWeight: 720,
    commission: null,
    commissionUnit: "PERCENTAGE",
    freight: null,
    respectCapacity: true,
  },
  dietStrategy: {
    id: null,
    dietPeriods: [],
    name: null,
    lot: null,
    useEstablishedCurve: false,
    status: "Active",
  },
  breeds: [],
  pageLoaded: false,
  dietStrategyPeriodSelected: null, // Diet Strategy Period
  activeTab: "tab1",
};

export default function purchaseScenario(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.CHANGE_TAB:
      return { ...state, activeTab: action.payload.index };
    case Types.UPDATE_NEW_PURCHASE_SCENARIO:
      return {
        ...state,
        newPurchaseScenario: action.payload.newPurchaseScenario,
      };
    case Types.UPDATE_DIET_STRATEGY:
      return {
        ...state,
        dietStrategy: action.payload.dietStrategy,
      };
    case Types.UPDATE_BREEDS:
      return {
        ...state,
        breeds: action.payload.breeds,
      };
    case Types.UPDATE_PAGE_LOADED:
      return {
        ...state,
        pageLoaded: action.payload.pageLoaded,
      };
    case Types.SELECT_DIETSTRATEGY_PERIOD:
      return {
        ...state,
        dietStrategyPeriodSelected: action.payload.data,
      };
    default:
      return state;
  }
}
