import styled from "styled-components";

export const Container = styled.div`
  padding: 5px 5px;

  strong.subTitle {
    font-family: "Asap", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;

    color: #4b4b4b;

    margin-left: 8px;
  }

  .ant-row,
  .ant-row-flex {
    width: 100%;
  }

  label {
    font-family: "Asap", sans-serif;
    color: #9074bf;
    font-size: 12px;
    font-weight: bold;
    margin-left: 12px;
  }
`;

export const ColSelectLots = styled.div`
  padding: 24px;
  height: 440px;
  /* min-height: 562px; */
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  background: #fff;
  box-shadow: 0px 0px 10px #d8d8d8;
  overflow-y: auto;

  span.subTitle {
    font-family: "Asap", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #8a50a5;
  }

  .ant-row-flex:first-of-type {
    margin-bottom: 16px;
  }

  .ant-table-pagination.ant-pagination {
    margin-right: 10px;
  }
  .header {
    width: 100%;
    height: 60px;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;

    span.listAnimals {
      font-family: Asap;
      font-weight: bold;
      font-size: 18px;
      text-align: left;
      color: #4b4b4b;
    }
    .ant-radio-button-wrapper {
      color: #684e94 !important;
      background: #fff;
      border-color: #684e94 !important;
      box-shadow: -1px 0 0 0 #684e94 !important;
      font-size: 14px;
      font-weight: bold;
    }
    .ant-radio-button-wrapper-checked {
      color: #fff !important;
      background: #684e94;
      border-color: #684e94 !important;
      box-shadow: -1px 0 0 0 #684e94 !important;
      font-size: 14px;
      font-weight: bold;
    }
  }
  @media screen and (max-height: 700px) {
    height: 350px;
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const ColButtons = styled.div`
  height: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    width: 62px;
    height: 62px;
    background: transparent;
    border: 2px solid;
    border-color: #ff5757;
    color: #ff5757;
    border-radius: 100%;

    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 16px;

    i {
      color: #ff5757;
      font-weight: bold;
    }

    &:first-of-type {
      margin-bottom: 16px;
      color: #5cdb75;
      border-color: #5cdb75;

      i {
        color: #5cdb75;
        font-weight: bold;
      }
    }
    &:hover {
      cursor: pointer;
    }
    &:disabled {
      cursor: not-allowed;
      color: #c4c4c4;
      border-color: #c4c4c4;

      i {
        color: #c4c4c4;
        font-weight: bold;
      }
    }
  }
  @media screen and (max-height: 700px) {
    height: 200px;
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 768px) {
    /* start of medium tablet styles */
    flex-direction: row;
    height: 100px;

    button {
      &:first-of-type {
        margin-bottom: 0px;
        margin-right: 16px;
      }
      svg {
        transform: rotate(90deg);
      }
    }
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const ColLotsSelected = styled.div`
  padding: 24px;
  height: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  display: flex;
  border-radius: 7px;
  background: #fff;
  box-shadow: 0px 0px 10px #d8d8d8;

  .header {
    width: 100%;
    height: 40px;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;

    .ant-checkbox .ant-checkbox-inner {
      background: transparent;
    }

    span {
      font-family: "Asap", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 28px;
      color: #8a50a5;
    }
  }
  @media screen and (max-height: 700px) {
    height: 350px;
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;
