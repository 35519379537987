import React from "react";
import { Icon, notification } from "antd";
import { ClipboardCustom } from "./styles";

const CopyClipboard = ({ text }) => {
  const message = () => {
    notification.success({ message: "Copiado!" });
  };

  return (
    <ClipboardCustom
      data-clipboard-text={text != null ? text : ""}
      onClick={message}
    >
      <Icon type="copy" />
    </ClipboardCustom>
  );
};

export default CopyClipboard;
