import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import sagas from "./sagas";
import sessionExpiredMiddleware from "../middlewares/sessionExpiredMiddleware";
import resetDataMiddleware from "../middlewares/resetDataMiddleware";

/* Reducers */
import combineReducers from "./ducks";

const middlewares = [];

const sagaMiddleware = createSagaMiddleware();

middlewares.push(sessionExpiredMiddleware);
middlewares.push(resetDataMiddleware);
middlewares.push(sagaMiddleware);

// const createAppropriateStore =
//   process.env.NODE_ENV === "development"
//     ? console.tron.createStore
//     : createStore;

const createAppropriateStore = createStore;

const store = createAppropriateStore(
  combineReducers,
  compose(applyMiddleware(...middlewares))
);

sagaMiddleware.run(sagas);

export default store;
