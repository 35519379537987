import React, { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Body, CollapseContainer, Container, Table } from "./styles";
import { Icon, Row } from "antd";
// Services
import {
  numberMask,
  getTwoDecimalDigits,
} from "../../../../services/helpersMethodsService";

const NewEditBoitelScenarioResultConsolidated = () => {
  const [minimizeCard, setMinimizeCard] = useState(false);
  const {
    boitelScenarioData: { consolidatedResult },
  } = useSelector((state) => state.boitelScenario);
  return (
    <Container
      title="Resultado Consolidado"
      extra={
        !minimizeCard ? (
          <button onClick={() => setMinimizeCard(true)}>
            <Icon type="minus" />
          </button>
        ) : (
          <button onClick={() => setMinimizeCard(false)}>
            <Icon type="plus" />
          </button>
        )
      }
    >
      {!minimizeCard && (
        <Body>
          {consolidatedResult != null ? (
            <>
              {/* Parameters Collapse */}
              <CollapseContainer
                defaultActiveKey={["1"]}
                expandIconPosition="right"
                expandIcon={({ isActive }) =>
                  isActive ? <Icon type="minus" /> : <Icon type="plus" />
                }
              >
                <CollapseContainer.Panel header="Parâmetros" key="1">
                  <Table>
                    <thead>
                      <tr>
                        <th className="width-140"></th>
                        <th className="width-140">Quantidade Animais</th>
                        <th className="width-200">Peso de entrada</th>
                        <th className="width-140">Permanência</th>
                        <th className="width-140">Preço diária</th>
                        <th className="width-140">Aprov. Carcaça</th>
                      </tr>
                    </thead>

                    <tbody>
                      {/* Table */}
                      <tr>
                        <td className="width-140">Tabela</td>
                        <td className="width-140">
                          {consolidatedResult?.numberOfAnimals} cab
                        </td>
                        <td className="width-200">{`${numberMask(
                          consolidatedResult?.inputWeight || 0,
                          false
                        )} kg PV/cab`}</td>
                        <td className="width-140">
                          {consolidatedResult?.boitelPriceLimitTimeSimulation
                            ? `${consolidatedResult?.boitelPriceLimitTimeSimulation} dias`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult?.boitelPriceLimitTimeSimulation
                            ? `${numberMask(
                                consolidatedResult?.boitelDailyPrice || 0,
                                true
                              )} /cab/dia`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.averageCarcassHarnessing
                            ? `${numberMask(
                                consolidatedResult.averageCarcassHarnessing ||
                                  0,
                                false
                              )} %`
                            : "-"}
                        </td>
                      </tr>
                      {/* Negoc */}
                      <tr>
                        <td className="width-140">Negociação</td>
                        <td className="width-140">
                          {consolidatedResult?.numberOfAnimals} cab
                        </td>
                        <td className="width-200">{`${numberMask(
                          consolidatedResult?.inputWeight || 0,
                          false
                        )} kg PV/cab`}</td>
                        <td className="width-140">
                          {consolidatedResult?.userLimitTimeSimulation
                            ? `${consolidatedResult?.userLimitTimeSimulation} dias`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult?.userDailyPrice
                            ? `${numberMask(
                                consolidatedResult?.userDailyPrice || 0,
                                true
                              )} /cab/dia`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.averageCarcassHarnessing
                            ? `${numberMask(
                                consolidatedResult.averageCarcassHarnessing ||
                                  0,
                                false
                              )} %`
                            : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CollapseContainer.Panel>
              </CollapseContainer>
              {/* Boitel Collapse */}
              <CollapseContainer
                defaultActiveKey={["1"]}
                expandIconPosition="right"
                expandIcon={({ isActive }) =>
                  isActive ? <Icon type="minus" /> : <Icon type="plus" />
                }
              >
                <CollapseContainer.Panel header="Boitel" key="1">
                  <Table>
                    <thead>
                      <tr>
                        <th className="width-140"></th>
                        <th className="width-140">Qtd. máx. cabeça</th>
                        <th className="width-140">Lucro est. (R$)</th>
                        <th className="width-140">Lucro (%)</th>
                        <th className="width-140">Máx. lucro</th>
                        <th className="width-140">Máx. lucro em dias</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Table */}
                      <tr>
                        <td className="width-140">Tabela</td>
                        <td className="width-140">
                          {consolidatedResult?.maximumNumberOfAnimals
                            ? `${consolidatedResult?.maximumNumberOfAnimals} cab`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.boitelPriceMargin
                            ? `${numberMask(
                                consolidatedResult.boitelPriceMargin || 0,
                                true
                              )} /cab`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.boitelPriceMarginPercentage
                            ? `${numberMask(
                                consolidatedResult.boitelPriceMarginPercentage ||
                                  0,
                                false
                              )} %`
                            : "-"}
                        </td>

                        <td className="width-140">
                          {consolidatedResult.boitelPriceMaximumMargin
                            ? `${numberMask(
                                consolidatedResult.boitelPriceMaximumMargin ||
                                  0,
                                true
                              )} /cab`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.boitelPriceMaximumMarginDays
                            ? `${
                                consolidatedResult.boitelPriceMaximumMarginDays ||
                                0
                              } dias`
                            : "-"}
                        </td>
                      </tr>
                      {/* Negoc */}
                      <tr>
                        <td className="width-140">Negociação</td>
                        <td className="width-140">
                          {consolidatedResult?.maximumNumberOfAnimals
                            ? `${consolidatedResult?.maximumNumberOfAnimals} cab`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.userMargin
                            ? `${numberMask(
                                consolidatedResult.userMargin || 0,
                                true
                              )} /cab`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.userMarginPercentage
                            ? `${numberMask(
                                consolidatedResult.userMarginPercentage || 0,
                                false
                              )} %`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.userMaximumMargin
                            ? `${numberMask(
                                consolidatedResult.userMaximumMargin || 0,
                                true
                              )} /cab`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.userMaximumMarginDays
                            ? `${
                                consolidatedResult.userMaximumMarginDays || 0
                              } dias`
                            : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CollapseContainer.Panel>
              </CollapseContainer>
              {/* Cliente Collapse */}
              <CollapseContainer
                defaultActiveKey={["1"]}
                expandIconPosition="right"
                expandIcon={({ isActive }) =>
                  isActive ? <Icon type="minus" /> : <Icon type="plus" />
                }
              >
                <CollapseContainer.Panel header="Cliente" key="1">
                  <Table>
                    <thead>
                      <tr>
                        <th className="width-140"></th>
                        <th className="width-140">Data prev. saída</th>
                        <th className="width-140">@ Liq. agregados</th>
                        <th className="width-140">Receita na venda</th>
                        <th className="width-140">Lucro agreg. (R$)</th>
                        <th className="width-140">Lucro (%)</th>
                        <th className="width-140">Máx. lucro agregado</th>
                        <th className="width-140">Máx. lucro em dias</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Table */}
                      <tr>
                        <td className="width-140">Tabela</td>
                        <td className="width-140">
                          {consolidatedResult.boitelPriceOutDayIn &&
                          consolidatedResult.boitelPriceOutDayOut
                            ? `${moment(
                                consolidatedResult.boitelPriceOutDayIn
                              ).format("DD/MM/YYYY")} - ${moment(
                                consolidatedResult.boitelPriceOutDayOut
                              ).format("DD/MM/YYYY")}`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.boitelPriceAggregateArroba
                            ? `${numberMask(
                                consolidatedResult.boitelPriceAggregateArroba ||
                                  0,
                                false
                              )} @`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.boitelPriceSaleIncome
                            ? `${numberMask(
                                consolidatedResult.boitelPriceSaleIncome || 0,
                                true
                              )} /cab`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.boitelPriceAggregateMargin
                            ? `${numberMask(
                                consolidatedResult.boitelPriceAggregateMargin ||
                                  0,
                                true
                              )} /cab`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.boitelPriceAggregateMarginPercentage
                            ? `${numberMask(
                                consolidatedResult.boitelPriceAggregateMarginPercentage ||
                                  0,
                                false
                              )} %`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.boitelPriceMaximumMarginAggregate
                            ? `${numberMask(
                                consolidatedResult.boitelPriceMaximumMarginAggregate ||
                                  0,
                                true
                              )} /cab`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.boitelPriceMaximumMarginAggregateDays
                            ? `${
                                consolidatedResult.boitelPriceMaximumMarginAggregateDays ||
                                0
                              } dias`
                            : "-"}
                        </td>
                      </tr>
                      {/* Negoc */}
                      <tr>
                        <td className="width-140">Negociação</td>
                        <td className="width-140">
                          {consolidatedResult.userOutDayIn &&
                          consolidatedResult.userOutDayOut
                            ? `${moment(consolidatedResult.userOutDayIn).format(
                                "DD/MM/YYYY"
                              )} - ${moment(
                                consolidatedResult.userOutDayOut
                              ).format("DD/MM/YYYY")}`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.userAggregateArroba
                            ? `${numberMask(
                                consolidatedResult.userAggregateArroba || 0,
                                false
                              )} @`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.userSaleIncome
                            ? `${numberMask(
                                consolidatedResult.userSaleIncome || 0,
                                true
                              )} /cab`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.userAggregateMargin
                            ? `${numberMask(
                                consolidatedResult.userAggregateMargin || 0,
                                true
                              )} /cab`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.userAggregateMarginPercentage
                            ? `${numberMask(
                                consolidatedResult.userAggregateMarginPercentage ||
                                  0,
                                false
                              )} %`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.userMaximumMarginAggregate
                            ? `${numberMask(
                                consolidatedResult.userMaximumMarginAggregate ||
                                  0,
                                true
                              )} /cab`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.userMaximumMarginAggregateDays
                            ? `${
                                consolidatedResult.userMaximumMarginAggregateDays ||
                                0
                              } dias`
                            : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <br />
                  <Table>
                    <thead>
                      <tr>
                        <th className="width-140"></th>
                        <th className="width-140">Valor da @</th>
                        <th className="width-140">Peso vivo de venda final</th>
                        <th className="width-140">GMD</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Table */}
                      <tr>
                        <td className="width-140">Tabela</td>
                        <td className="width-140">
                          {consolidatedResult.boitelPriceArrobaPrice
                            ? `${numberMask(
                                consolidatedResult.boitelPriceArrobaPrice || 0,
                                true
                              )} /@`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.boitelPriceFinalWeight
                            ? `Pvf ${numberMask(
                                consolidatedResult.boitelPriceFinalWeight || 0,
                                false
                              )} kg ou ${getTwoDecimalDigits(
                                (consolidatedResult.boitelPriceFinalWeight *
                                  (consolidatedResult.averageCarcassHarnessing !=
                                  null
                                    ? consolidatedResult.averageCarcassHarnessing /
                                      100
                                    : 0.5)) /
                                  15,
                                1
                              )} @`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.boitelPriceAverageWeightGain
                            ? `${numberMask(
                                consolidatedResult.boitelPriceAverageWeightGain ||
                                  0,
                                false
                              )} kg/dia`
                            : "-"}
                        </td>
                      </tr>
                      {/* Negoc */}
                      <tr>
                        <td className="width-140">Negociação</td>
                        <td className="width-140">
                          {consolidatedResult.userArrobaPrice
                            ? `${numberMask(
                                consolidatedResult.userArrobaPrice || 0,
                                true
                              )} /@`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.userFinalWeight
                            ? `Pvf ${numberMask(
                                consolidatedResult.userFinalWeight || 0,
                                false
                              )} kg ou ${getTwoDecimalDigits(
                                (consolidatedResult.userFinalWeight *
                                  (consolidatedResult.averageCarcassHarnessing !=
                                  null
                                    ? consolidatedResult.averageCarcassHarnessing /
                                      100
                                    : 0.5)) /
                                  15,
                                1
                              )} @`
                            : "-"}
                        </td>
                        <td className="width-140">
                          {consolidatedResult.userAverageWeightGain
                            ? `${numberMask(
                                consolidatedResult.userAverageWeightGain || 0,
                                false
                              )} kg/dia`
                            : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CollapseContainer.Panel>
              </CollapseContainer>
            </>
          ) : (
            <Row type="flex" justify="center">
              <strong>
                Resultado consolidado sendo calculado <Icon type="loading" />
              </strong>
            </Row>
          )}
        </Body>
      )}
    </Container>
  );
};

export default NewEditBoitelScenarioResultConsolidated;
