import { call, put, select } from "redux-saga/effects";
import { Creators as ClientActions } from "../ducks/client";
import { notification } from "antd";
/** Services */
import { storeClient, updateClient, getListOfClientsDropDown } from "../../services/managementService";

export function* storeOrUpdateClient(action) {
  try {
    if (action.payload.id === null) {
      yield call(storeClient, action.payload);
      notification.success({
        message: "Cliente cadastrado com sucesso",
      });
    } else {
      yield call(updateClient, action.payload);
    }
    yield put(ClientActions.refreshTable(true));
    yield put(ClientActions.saveClientSuccess(true));

    const { updateDropdown } = yield select((state) => state.client);

    if(updateDropdown === true) {
      const { groupId, farmId } = action.payload;
      yield put(ClientActions.getDropdownClients(groupId, farmId, true));
    }

  } catch (error) {
    if (action.payload.id == null)
      notification.error({ message: "Cliente não foi criado." });
    else notification.error({ message: "Cliente não foi atualizado." });

    if (error.response.status === 406) {
      notification.error({
        message: "CPF/CNPJ já cadastrado em sua base de clientes.",
      });
    } else if (error.response.data.code === 5006) {
      notification.error({
        message: "CPF/CNPJ já cadastrado em sua base de clientes.",
      });
    } else if (error.response.data.code === 5007) {
      notification.error({
        message: " Este cliente já esta na sua base de clientes.",
      });
    } else {
      notification.error({
        message: "Houve um erro ao cadastrar o cliente",
      });
    }
    yield put(ClientActions.saveClientError(error));
  }
}

export function* listDropDownClient(action) {
  try {
    const {
      data: { results: allData },
    } = yield call(getListOfClientsDropDown, action.payload);

    yield put(ClientActions.getDropdownClientsSuccess(allData));
  } catch (error) {
    yield put(ClientActions.getDropdownClientsError(error));
  }
}