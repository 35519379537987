import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-left: 5px;
  border-radius: 5px;
  height: 34px;
  background: #d44c4c;
  border: 1px solid #d44c4c;
  color: #ffffff;
  text-align: center;
  font-size: 0.5rem;
  cursor: pointer;

  ${(props) =>
    props.alertSelected &&
    css`
      background: #fe8d2a;
      border: 1px solid #fe8d2a;
    `}

  .ant-spin-nested-loading {
    width: 100%;
  }

  .ant-spin-container {
    display: flex;
    height: 34px;
    align-items: center;
  }

  .messageContainer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    p {
      font-family: "Asap", sans-serif;
      margin: 0;
      font-size: 0.75rem;
      strong {
        margin-left: 3px;
        text-decoration: underline;
      }
    }
  }

  .buttonMenu {
    display: flex;
    background: transparent;
    align-items: center;
    justify-content: center;

    padding: 10px;

    cursor: pointer;

    border: 0;
    border-left: 1px solid rgba(255, 255, 255, 0.4);
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
    margin-bottom: 5px;
  }
`;
