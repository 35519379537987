import React from "react";

import { Container } from "./styles";

const CustomDivider = ({ title, dashed }) => {
  return (
    <Container dashed={dashed != null && dashed ? true : false}>
      {title}
    </Container>
  );
};

export default CustomDivider;
