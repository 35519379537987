import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  Row,
  Col,
  Table,
  Input,
  Button,
  Icon,
  Menu,
  Dropdown,
  Popover,
} from "antd";

import { Creators as BreedActions } from "../../store/ducks/breed";

import { Title, CardCustom } from "./styles";
import TagStatus from "../../components/utils/tagStatus";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import MenuIcon from "../../components/utils/table/icons/menu";
import DrawerBreed from "../../components/drawers/breed";
import DrawerBreedParameters from "../../components/drawers/breedParameters";
import CheckSmallIcon from "../../components/utils/icons/check/checkSmall";
import ExclamationSmallIcon from "../../components/utils/icons/exclamation/exclamationSmall";

class Breed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      success: false,
    };
  }

  componentDidMount() {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;
    if (
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      this.fetchData(groupSelected, farmSelected, 0, {});
    }
  }

  fetchData = (
    groupSelected,
    farmSelected,
    page = 0,
    sorter = {},
    filters = "",
    size = 10
  ) => {
    const { breedActions } = this.props;
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    breedActions.indexBreed(groupId, farmId, page, sorter, filters, size, true);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
            background: "#684e94",
            borderColor: "none",
            border: "none",
          }}
        />
        <Button
          onClick={() => this.handleReset(clearFilters)}
          icon="delete"
          size="small"
          style={{ width: 90 }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (dataIndex === "lot.name") {
        return record.lot.name.includes(value);
      } else {
        return record[dataIndex].toString().includes(value);
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleTableChange = async (pagination, filters, sorter) => {
    this.setState({
      paginationInfo: pagination,
      sortedInfo: sorter,
      filteredInfo: filters,
    });
    const {
      app: { groupSelected, farmSelected },
    } = this.props;

    let search = "";
    this.fetchData(
      groupSelected,
      farmSelected,
      pagination.current,
      sorter,
      search,
      pagination.pageSize
    );
  };

  handleEdit = async (id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      breedActions,
    } = this.props;
    await Promise.all([
      breedActions.showBreed(groupId, farmId, id),
      breedActions.showDrawer(),
    ]);
  };

  handleParameters = async (id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      breedActions,
    } = this.props;
    breedActions.showBreedParameters(groupId, farmId, id);
    breedActions.showDrawerParameters("edit", id);
  };

  handleInactivateOrActivateBreedParameter = (
    operationAction,
    breedId,
    breedParameterId,
    breed
  ) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      breedActions,
    } = this.props;
    breedActions.inactivateOrActivateBreedParameters(
      groupId,
      farmId,
      breedId,
      breedParameterId,
      breed,
      operationAction
    );
  };

  render() {
    const {
      app: { translation },
      breed: { data, isLoading },
    } = this.props;

    const Column = Table.Column;

    const menu = (id, record) => {
      const {
        app: { translation },
      } = this.props;
      return (
        <Menu>
          {record.status === "Inactive" && (
            <Menu.Item key="0">
              <button
                onClick={() =>
                  this.handleInactivateOrActivateBreedParameter(
                    "activate",
                    id,
                    record.breedParameterId,
                    record
                  )
                }
              >
                {translation.table.menu.activate}
              </button>
            </Menu.Item>
          )}
          {record.status === "Active" && (
            <Menu.Item key="2">
              <button
                onClick={() =>
                  this.handleInactivateOrActivateBreedParameter(
                    "inactivate",
                    id,
                    record.breedParameterId,
                    record
                  )
                }
              >
                {translation.table.menu.inactivate}
              </button>
            </Menu.Item>
          )}
          {record.status === "Active" && (
            <Menu.Item key="1">
              <button onClick={() => this.handleParameters(id)}>
                {translation.breed.table.menu.parameters}
              </button>
            </Menu.Item>
          )}
        </Menu>
      );
    };

    return (
      <CardCustom>
        <Row type="flex" justify="space-between" className="rowHeader">
          <Col>
            <Title>{translation.breed.table.title}</Title>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              loading={isLoading}
              rowKey="breedId"
              size="small"
              dataSource={data != null ? data : []}
              scroll={{
                x: true,
              }}
              pagination={{
                showSizeChanger: true,
                defaultPageSize: 10,
                pageSizeOptions: [
                  "10",
                  "20",
                  "30",
                  "100",
                  "500",
                  "1000",
                  "2000",
                ],
              }}
              style={{ width: "100%", margin: "0" }}
            >
              <Column
                title={translation.breed.table.columns.status}
                dataIndex="status"
                key="status"
                align="left"
                sorter={(first, second) => {
                  return first.status.localeCompare(second.status, "pt-BR", {
                    numeric: false,
                    ignorePunctuation: true,
                  });
                }}
                filtered
                filters={[
                  {
                    text: translation.status.active,
                    value: "Active",
                  },
                  {
                    text: "Pendente",
                    value: "Pending",
                  },
                ]}
                filterMultiple
                onFilter={(value, record) => record.status.includes(value)}
                render={(status, record) =>
                  status === "Active" ? (
                    <TagStatus
                      background="#C5F1CA"
                      borderColor="#106518"
                      color="#106518"
                    >
                      {translation.status.active}
                    </TagStatus>
                  ) : status === "Pending" ? (
                    <Popover
                      content={
                        <div>
                          <div>
                            <span style={{ marginRight: 7 }}>
                              {record?.maleBirthdayWeight > 0 ? (
                                <CheckSmallIcon />
                              ) : (
                                <span style={{ margin: "0 6px 0 4px" }}>
                                  <ExclamationSmallIcon />
                                </span>
                              )}
                            </span>
                            Peso de nascimento informado
                          </div>
                          <div>
                            <span style={{ marginRight: 7 }}>
                              {record?.maleWeaningWeight > 0 ? (
                                <CheckSmallIcon />
                              ) : (
                                <span style={{ margin: "0 6px 0 4px" }}>
                                  <ExclamationSmallIcon />
                                </span>
                              )}
                            </span>
                            Peso no desmame informado
                          </div>
                          <div>
                            <span style={{ marginRight: 7 }}>
                              {record?.asymptoticWeight > 0 ? (
                                <CheckSmallIcon />
                              ) : (
                                <span style={{ margin: "0 6px 0 4px" }}>
                                  <ExclamationSmallIcon />
                                </span>
                              )}
                            </span>
                            Peso assintótico informado
                          </div>
                        </div>
                      }
                      trigger="hover"
                    >
                      <TagStatus
                        background="#FFDBBC"
                        borderColor="#FE8D2A"
                        color="#FE8D2A"
                      >
                        Pendente
                      </TagStatus>
                    </Popover>
                  ) : (
                    <TagStatus
                      background="#FBC7C7"
                      borderColor="#D44C4C"
                      color="#D44C4C"
                    >
                      {translation.status.inactive}
                    </TagStatus>
                  )
                }
              />
              <Column
                title={translation.breed.table.columns.name}
                dataIndex="name"
                sorter={(first, second) => {
                  return first.name.localeCompare(second.name, "pt-BR", {
                    numeric: false,
                    ignorePunctuation: true,
                  });
                }}
                key="name"
                align="left"
                {...this.getColumnSearchProps("name")}
              />
              <Column
                title={translation.breed.table.columns.description}
                dataIndex="description"
                key="description"
                align="left"
                {...this.getColumnSearchProps("description")}
              />
              <Column
                title={translation.breed.table.columns.geneticGroup}
                dataIndex="geneticGroup"
                key="geneticGroup"
                align="left"
                sorter={(first, second) => {
                  return first.geneticGroup.localeCompare(
                    second.geneticGroup,
                    "pt-BR",
                    {
                      numeric: false,
                      ignorePunctuation: true,
                    }
                  );
                }}
                {...this.getColumnSearchProps("geneticGroup")}
              />
              <Column
                title={translation.breed.table.columns.asymptoticWeight}
                dataIndex="asymptoticWeight"
                key="asymptoticWeight"
                align="left"
                render={(text, record) => (
                  <span>
                    {record.asymptoticWeight !== null
                      ? `${record.asymptoticWeight} kg`
                      : ""}
                  </span>
                )}
                {...this.getColumnSearchProps("asymptoticWeight")}
              />
              <Column
                title={translation.breed.table.columns.estimatedMaximumAge}
                dataIndex="estimatedMaximumAge"
                key="estimatedMaximumAge"
                align="left"
                render={(text, record) => (
                  <span>
                    {record.estimatedMaximumAge !== null
                      ? `${record.estimatedMaximumAge} m`
                      : ""}
                  </span>
                )}
              />
              <Column
                align="left"
                render={(text, record) =>
                  record.breedCurveParams != null &&
                  record.breedCurveParams.length > 0 && (
                    <Dropdown
                      overlay={menu(record.breedId, record)}
                      trigger={["click"]}
                      key={record.breedId}
                    >
                      <MenuContainer>
                        <MenuIcon />
                      </MenuContainer>
                    </Dropdown>
                  )
                }
              />
            </Table>
          </Col>
        </Row>
        <DrawerBreed />
        <DrawerBreedParameters />
      </CardCustom>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  breed: state.breed,
});

const mapDispatchToProps = (dispatch) => ({
  breedActions: bindActionCreators(BreedActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Breed));
