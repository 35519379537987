import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Creators as SupplierActions } from "../../../store/ducks/supplier";
import { Creators as LotActions } from "../../../store/ducks/lot";

import moment from "moment";
import "moment/locale/pt-br";
import "moment/locale/en-nz";
import "moment/locale/es";

import * as Yup from "yup";
import { Formik } from "formik";
import {
  Drawer,
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Divider,
  Icon,
  Table,
  notification,
  Popconfirm,
  Button,
  Spin,
} from "antd";
import { Container, AnimalsAmount, Footer } from "./styles";
import PencilIcon from "../../utils/icons/pencil";
import TrashIcon from "../../utils/icons/trash";
import ButtonStandard from "../../utils/button";
import NewLotItem from "../newLotItem";
import DrawerSupplier from "../supplier";

class NewLotAnimalsReceipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        supplierId: null,
        supplierName: "",
        name: "",
        creationDate: null,
        amountAnimalsReceived: null,
      },
      editingKey: "",
      saveOptions: "only_save",
    };

    this.formikRef = React.createRef();
  }

  fetchData = async (
    groupSelected,
    farmSelected,
    page = 0,
    sorter = {},
    filters = ""
  ) => {
    const { supplierActions } = this.props;
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    await supplierActions.getDropdownSuppliers(groupId, farmId, "CattleFarmer");
  };

  closeDrawer = () => {
    const { lotActions } = this.props;
    lotActions.hideLotAnimalsReceipt();
  };

  isEditing = (record) => record.key === this.state.editingKey;

  openNotificationError = (title, message) => {
    notification["error"]({
      message: title,
      description: message,
    });
  };

  handleRemoveLotItem = (id) => {
    this.props.lotActions.removeLotReceiptItem(id);
  };

  handleAddNewLotItem = () => {
    this.props.lotActions.showLotItemReceipt();
  };

  handleFinalizeLotReceipt = async (values, actions) => {
    const {
      lot: { lotItens, error },
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
        translation,
      },
      lotActions,
      history,
    } = this.props;

    const { saveOptions } = this.state;

    const lotReceipt = values;

    let amountAnimalsInLotItem = 0;

    lotItens.forEach(
      (item) =>
        (amountAnimalsInLotItem =
          parseInt(amountAnimalsInLotItem) + parseInt(item.amount))
    );

    lotReceipt.amountAnimalsReceived = amountAnimalsInLotItem;

    if (amountAnimalsInLotItem === 0) {
      this.openNotificationError(
        translation.lot.receipt.finalize.errors.noAnimalsAdd.title,
        translation.lot.receipt.finalize.errors.noAnimalsAdd.description
      );
    } else {
      lotActions.storeNewLotReceipt(
        groupId,
        farmId,
        lotReceipt,
        lotItens,
        history,
        saveOptions === "only_save" ? false : true
      );
      if (error == null)
        actions.resetForm({
          supplierId: null,
          supplierName: "",
          name: "",
          creationDate: null,
          amountAnimalsReceived: null,
        });

      this.setState({
        form: {
          supplierId: null,
          supplierName: "",
          name: "",
          creationDate: null,
          amountAnimalsReceived: null,
        },
      });
    }
  };

  handleEditLotItem = (record) => {
    const { lotActions } = this.props;
    lotActions.getLotItem(record);
  };

  handleDrawerVisible = async (visible) => {
    if (visible) {
      const {
        app: { groupSelected, farmSelected },
      } = this.props;
      if (
        Object.entries(groupSelected).length > 0 &&
        Object.entries(farmSelected).length > 0
      ) {
        this.setState({
          form: {
            supplierId: null,
            supplierName: "",
            name: "",
            creationDate: null,
            amountAnimalsReceived: null,
          },
        });
        this.formikRef.current.resetForm({
          supplierId: null,
          supplierName: "",
          name: "",
          creationDate: null,
          amountAnimalsReceived: null,
        });

        await this.fetchData(groupSelected, farmSelected);
      }
    }
  };

  handleCreateSupplier = () => {
    const { supplierActions } = this.props;
    supplierActions.showDrawer("new_animal_supplier", null);
  };

  render() {
    const {
      app: { translation },
      lot: { drawerLotAnimalsReceiptVisible, lotItens, isLoading },
      supplier: {
        listDrowpDown: suppliers,
        isLoadingDropDown: isLoadingDropDownSuppliers,
      },
    } = this.props;

    const { form, saveOptions } = this.state;

    const validationSchema = Yup.object().shape({
      supplierId: Yup.string().required(),
      name: Yup.string().required(),
      creationDate: Yup.date().required(),
    });

    const amountAnimals = () => {
      let amountAnimalsInLotItem = 0;

      lotItens.forEach(
        (item) =>
          (amountAnimalsInLotItem =
            parseInt(amountAnimalsInLotItem) + parseInt(item.amount))
      );

      return amountAnimalsInLotItem;
    };

    const { Column } = Table;

    const { Option } = Select;

    const dateFormat = "DD/MM/YYYY";

    return (
      <Drawer
        title={translation.lot.receipt.finalize.newLotAnimalsReceipt.title}
        width={"70vw"}
        maskClosable={false}
        onClose={this.closeDrawer}
        visible={drawerLotAnimalsReceiptVisible}
        afterVisibleChange={this.handleDrawerVisible}
      >
        <Spin spinning={isLoading} size="large">
          <Container>
            <Formik
              ref={this.formikRef}
              initialValues={form}
              onSubmit={this.handleFinalizeLotReceipt}
              validationSchema={validationSchema}
              render={(props) => (
                <form onSubmit={props.handleSubmit} autoComplete="off">
                  {/* Supplier Info and Lot Animals Receipt info */}
                  <div>
                    <Row type="flex">
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row type="flex">
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "normal",
                              color: "#4B4B4B",
                            }}
                          >
                            {
                              translation.lot.receipt.finalize
                                .newLotAnimalsReceipt.description
                            }
                          </span>
                        </Row>
                        {Object.entries(props.errors).length > 0 &&
                        props.submitCount > 0 ? (
                          <Row type="flex" justify="center" align="middle">
                            <label className="error">
                              {translation.lot.receipt.supplier.messageError}
                            </label>
                          </Row>
                        ) : null}
                        <Row type="flex" style={{ width: "100%" }}>
                          <Row type="flex" style={{ width: "100%" }}>
                            <Col
                              xs={11}
                              sm={11}
                              md={11}
                              lg={11}
                              xl={11}
                              className="rowLabel"
                            >
                              <Row>
                                <label
                                  className={
                                    props.errors.supplierId &&
                                    props.submitCount > 0
                                      ? "error"
                                      : ""
                                  }
                                >
                                  {
                                    translation.lot.receipt.supplier
                                      .supplierTitle
                                  }
                                  *
                                </label>
                              </Row>
                              <Row type="flex" gutter={4}>
                                <Select
                                  style={{ width: "80%" }}
                                  value={
                                    props.values.supplierId != null
                                      ? props.values.supplierId
                                      : undefined
                                  }
                                  showSearch
                                  placeholder={
                                    translation.lot.receipt.supplier
                                      .supplierComboBoxPlaceHolder
                                  }
                                  loading={isLoadingDropDownSuppliers}
                                  name="supplierId"
                                  onChange={(value) => {
                                    props.setValues({
                                      ...props.values,
                                      supplierId: value,
                                      supplierName: suppliers.find(
                                        (supplier) => supplier.id === value
                                      ).name,
                                    });
                                  }}
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {suppliers !== null &&
                                    suppliers?.map((supplier) => (
                                      <Option
                                        key={supplier.id}
                                        value={supplier.id}
                                      >
                                        {supplier.name}
                                      </Option>
                                    ))}
                                </Select>
                                <Button
                                  shape="circle"
                                  icon="plus"
                                  style={{ marginLeft: 10 }}
                                  onClick={() => this.handleCreateSupplier()}
                                />
                              </Row>
                            </Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              offset={1}
                              className="rowLabel"
                            >
                              <Row>
                                <label
                                  className={
                                    props.errors.name && props.submitCount > 0
                                      ? "error"
                                      : ""
                                  }
                                >
                                  {translation.lot.receipt.supplier.lotName}*
                                </label>
                              </Row>
                              <Row>
                                <Input
                                  type="text"
                                  style={{ width: "100%" }}
                                  placeholder={
                                    translation.lot.receipt.supplier
                                      .lotNameInputPlaceHolder
                                  }
                                  value={props.values.name}
                                  name="name"
                                  autoComplete="chrome-off"
                                  onChange={props.handleChange}
                                />
                              </Row>
                            </Col>
                          </Row>
                          <Row type="flex" style={{ width: "100%" }}>
                            <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                              <Row type="flex" style={{ width: "100%" }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <label
                                    className={
                                      props.errors.creationDate &&
                                      props.submitCount > 0
                                        ? "error"
                                        : ""
                                    }
                                  >
                                    {translation.lot.receipt.supplier.dateTitle}
                                    *
                                  </label>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <DatePicker
                                    style={{ width: "100%" }}
                                    value={
                                      props.values.creationDate === null
                                        ? null
                                        : moment(
                                            props.values.creationDate,
                                            "YYYY-MM-DD"
                                          )
                                    }
                                    placeholder={
                                      translation.lot.receipt.supplier
                                        .datePlaceHolder
                                    }
                                    format={dateFormat}
                                    name="creationDate"
                                    allowClear={false}
                                    onChange={(dateMoment, dateString) => {
                                      props.setValues({
                                        ...props.values,
                                        creationDate: dateMoment,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              offset={1}
                            >
                              <Row type="flex" style={{ width: "100%" }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <label />
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <AnimalsAmount
                                    className={
                                      amountAnimals() > 0 ? "haveAnimals" : ""
                                    }
                                  >
                                    <span>
                                      {
                                        translation.lot.receipt.supplier
                                          .qtyAnimalsTitle
                                      }
                                    </span>
                                    <span className="number">
                                      {amountAnimals()}
                                    </span>
                                  </AnimalsAmount>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Row>
                      </Col>
                    </Row>
                    <Divider />
                    {/* Table with Lot Receipt Item */}
                    <Row type="flex" style={{ width: "100%" }}>
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        className="tableButtonsDiv"
                      >
                        <ButtonStandard
                          buttonType="type2"
                          size="l"
                          background="#4A85AE"
                          type="button"
                          onClick={this.handleAddNewLotItem}
                        >
                          <Icon type="plus" /> Linha
                        </ButtonStandard>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Table
                          rowKey="id"
                          rowClassName={() => "editable-row"}
                          size="small"
                          style={{ width: "100%", marginBottom: "10px" }}
                          dataSource={lotItens}
                          pagination={{
                            hideOnSinglePage: true,
                            disabled: true,
                          }}
                        >
                          <Column
                            title={
                              translation.lot.receipt.finalize.grid.columns
                                .breed
                            }
                            dataIndex="breedName"
                            key="breedName"
                            sorter={(a, b) => {
                              return a.breedName.localeCompare(b.breedName);
                            }}
                          />
                          <Column
                            title={
                              translation.lot.receipt.finalize.grid.columns
                                .gender
                            }
                            dataIndex="gender"
                            key="gender"
                            sorter={(a, b) => {
                              return a.gender.localeCompare(b.gender);
                            }}
                            render={(text, record) => (
                              <span>
                                {record.gender === "M"
                                  ? translation.animal.details.male
                                  : translation.animal.details.female}
                              </span>
                            )}
                          />
                          <Column
                            title={
                              translation.lot.receipt.finalize.grid.columns
                                .birthday
                            }
                            dataIndex="birthday"
                            key="birthday"
                            sorter={(a, b) => {
                              return a.birthday.localeCompare(b.birthday);
                            }}
                            render={(text, record) =>
                              record.birthday !== null ? (
                                <span>
                                  {moment(record.birthday).format("MM/YYYY - ")}
                                  {moment(new Date())
                                    .diff(
                                      new Date(record.birthday),
                                      "months",
                                      true
                                    )
                                    .toFixed()}{" "}
                                  m
                                </span>
                              ) : (
                                ""
                              )
                            }
                          />
                          <Column
                            title={
                              translation.lot.receipt.finalize.grid.columns
                                .weight
                            }
                            dataIndex="estimatedWeight"
                            key="estimatedWeight"
                            sorter={(a, b) => {
                              return a.estimatedWeight - b.estimatedWeight;
                            }}
                            render={(text, record) => <span>{text} Kg</span>}
                          />
                          <Column
                            title={
                              translation.lot.receipt.finalize.grid.columns
                                .amount
                            }
                            dataIndex="amount"
                            key="amount"
                            sorter={(a, b) => {
                              return a.amount - b.amount;
                            }}
                          />
                          <Column
                            title={
                              translation.lot.receipt.finalize.grid.columns
                                .purchaseCost
                            }
                            dataIndex="purchaseCost"
                            key="purchaseCost"
                            sorter={(a, b) => {
                              return a.purchaseCost - b.purchaseCost;
                            }}
                          />
                          {/* <Column
                                  title={translation.lot.receipt.finalize.grid.columns.numbers}
                                  dataIndex="numbers"
                                  key="numbers"
                                  render={(text, record) => (
                                    <span style={{ color: record.amount === record.sum ? '#4A125AE' : '#D44C4C'}}>
                                      {text}
                                    </span>
                                  )}
                                /> */}
                          <Column
                            width="60px"
                            align="right"
                            render={(text, record) => {
                              const { editingKey } = this.state;
                              return editingKey === "" ? (
                                <Fragment>
                                  <Col
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    style={{ textAlign: "center" }}
                                  >
                                    <button
                                      type="button"
                                      style={{
                                        border: "none",
                                        background: "transparent",
                                      }}
                                      onClick={() =>
                                        this.handleEditLotItem(record)
                                      }
                                    >
                                      <PencilIcon color="#4A85AE" />
                                    </button>
                                  </Col>
                                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Popconfirm
                                      title={
                                        translation.lot.receipt.finalize.grid
                                          .deleteBox.description
                                      }
                                      cancelText={
                                        translation.lot.receipt.finalize.grid
                                          .deleteBox.buttonCancel
                                      }
                                      okText={
                                        translation.lot.receipt.finalize.grid
                                          .deleteBox.buttonConfirm
                                      }
                                      placement="topRight"
                                      icon={
                                        <Icon
                                          type="question-circle-o"
                                          style={{ color: "red" }}
                                        />
                                      }
                                      onConfirm={() =>
                                        this.handleRemoveLotItem(record.id)
                                      }
                                    >
                                      <button
                                        type="button"
                                        style={{
                                          border: "none",
                                          background: "transparent",
                                        }}
                                      >
                                        <TrashIcon />
                                      </button>
                                    </Popconfirm>
                                  </Col>
                                </Fragment>
                              ) : null;
                            }}
                          />
                        </Table>
                      </Col>
                    </Row>
                    {/* Footer */}
                  </div>
                  <Footer>
                    <Row type="flex" justify="space-between">
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="selectDiv"
                      >
                        <Select
                          style={{ width: 250 }}
                          value={saveOptions}
                          onChange={(value) =>
                            this.setState({ saveOptions: value })
                          }
                        >
                          <Option key="1" value="only_save">
                            {
                              translation.lot.receipt.finalize
                                .newLotAnimalsReceipt.saveOptions.only_save
                            }
                          </Option>
                          <Option key="2 " value="save_and_create_new">
                            {
                              translation.lot.receipt.finalize
                                .newLotAnimalsReceipt.saveOptions
                                .save_and_create_new
                            }
                          </Option>
                          <Option key="3 " value="save_and_handle">
                            {
                              translation.lot.receipt.finalize
                                .newLotAnimalsReceipt.saveOptions
                                .save_and_handle
                            }
                          </Option>
                        </Select>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="buttonsDiv"
                      >
                        <ButtonStandard
                          type="button"
                          buttonType="type7"
                          onClick={this.closeDrawer}
                        >
                          {translation.buttons.cancel}
                        </ButtonStandard>

                        <ButtonStandard type="submit" buttonType="type6">
                          {translation.buttons.save}
                        </ButtonStandard>
                      </Col>
                    </Row>
                  </Footer>
                </form>
              )}
            />
          </Container>
        </Spin>
        <NewLotItem />
        <DrawerSupplier />
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  lot: state.lot,
  supplier: state.supplier,
});

const mapDispatchToProps = (dispatch) => ({
  lotActions: bindActionCreators(LotActions, dispatch),
  supplierActions: bindActionCreators(SupplierActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewLotAnimalsReceipt)
);
