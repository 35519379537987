import styled from "styled-components";

export const Container = styled.div`
  padding: 5px 5px;
  overflow-y: hidden;

  .buttonsCol {
    button {
      &:first-of-type {
        margin-right: 19px;
      }
    }
  }

  div.inputStrong {
    color: #9074bf;
    font-size: 14px;
    font-weight: normal;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    & > * {
      margin-left: 8px;
    }

    .ant-switch-checked {
      background-color: #a9c133;
    }
    .ant-switch-loading-icon,
    .ant-switch-checked::after {
      left: 110% !important;
      top: 0px;
      width: 20px;
      height: 20px;
      margin-left: -1px;
      -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    .ant-switch-loading-icon,
    .ant-switch::after {
      position: absolute;
      top: 0px;
      left: 0;
      width: 20px;
      height: 20px;
      border-radius: 15px;
      cursor: pointer;
      -webkit-transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      content: " ";
    }
  }

  strong.subTitle {
    font-family: "Asap", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;

    color: #4b4b4b;

    margin-left: 8px;
  }

  form {
    label.formLabel {
      display: block;
      font-family: "Asap", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #8a50a5;
      margin-bottom: 8px;

      #bovexoInfoIcon {
        margin-left: 3px;
      }

      &.disabled {
        color: #c4c4c4;
      }
      &.error {
        color: #d44c4c;
      }

      @media screen and (max-width: 769px) {
        margin-top: 16px;
      }
    }

    .ant-row-flex:not(:first-of-type) {
      margin-top: 17px;
    }

    .ant-row-flex.withBackground {
      background-color: #f5f5f5;
      padding: 24px 0;
    }
  }
`;
export const Title = styled.h1`
  font-family: Asap;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  color: #4b4b4b;
  margin: 0;
`;
