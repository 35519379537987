import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// libs
import Axios from "axios";
import moment from "moment";
import { Row, Col } from "antd";

// Styles
import { CardContainerAlert, CardItem } from "../styles";

// Components
import { Creators as AppActions } from "../../../store/ducks/app";
import useHomeContext from "../../../hooks/useHomeContext";
import DrawerAlert from "../../../components/drawers/alert";

// Services
import { showAlertsByDateAndEntity } from "../../../services/alertService";

//Icons
import AlertIcon from "../icons/alert";
import KpiAlertIcon from "../icons/kpiAlert";
import RedCircleIcon from "../icons/redCircle";
import AnimalIcon from "../../../components/admin/sidebar/icons/animal";
import LotIcon from "../../../components/admin/sidebar/icons/lot";
import PicketIcon from "../../../components/admin/sidebar/icons/picket";
import DecisionIcon from "../../../components/admin/sidebar/icons/decision";

const signal = Axios.CancelToken.source();

const AlertPanel = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { translation, groupId, farmId } = useHomeContext();

    const [alertList, setAlertList] = useState([]);
    const [visibleDrawerAlert, setVisibleDrawerAlert] = useState(false);

    // Fetch data
    useEffect(() => {
        async function fetchAlerts() {
            dispatch(AppActions.setAlertSelected({}));
            const executionDate = moment().format("YYYY-MM-DD");

            try {
                const {
                    data: { results: alerts },
                } = await showAlertsByDateAndEntity({
                    groupId,
                    farmId,
                    executionDate,
                    signal,
                });

                setAlertList(alerts);
            } catch (error) {
                setAlertList([]);
            }
        }

        if (groupId && farmId) {
            fetchAlerts();
        }
    }, [groupId, farmId, dispatch]);

    const handleAlertClick = useCallback((alert) => {
        alert.alertPage = true;
        dispatch(AppActions.setAlertSelected(alert));
    
        switch (alert.entity) {
          case "animal":
            history.push("/admin/animals");
            break;
          case "lot":
            history.push("/admin/lots");
            break;
          case "picket":
            history.push("/admin/pickets");
            break;
          case "kpi":
            history.push("/admin/parameters");
            break;
          case "diet_strategy":
            history.push("/admin/managements");
            break;
          case "sale_scenario":
            history.push("/admin/decisions");
            break;
          default:
            break;
        }
    }, [history, dispatch]);

    return (
        <>
            <Row className="title-sub-2">
                {/* <Dropdown overlay={menu}> */}
                <span onClick={(e) => e.preventDefault()}>
                    alertas & pendências
                </span>
                {/* </Dropdown> */}
            </Row>
            <CardContainerAlert>
                <div>
                    {Array.isArray(alertList) && alertList.length <= 0 ? (
                        <CardItem>
                            <div>
                                <span>Nenhum alerta encontrado nessa data</span>
                            </div>
                        </CardItem>
                    ) : (
                        alertList.map((alert) => (
                            <CardItem
                                key={alert.id}
                                onClick={() => handleAlertClick(alert)}
                            >
                                <div style={{ width: "100%" }}>
                                    <Row type="flex">
                                        <Col span={2}>
                                            <Row
                                                type="flex"
                                                justify="center"
                                                style={alert.failed ? { marginTop: "5px" } : {}}
                                            >
                                                {alert.entity === "animal" ? (
                                                    <AnimalIcon />
                                                ) : alert.entity === "lot" ? (
                                                    <LotIcon />
                                                ) : alert.entity === "kpi" ? (
                                                    <KpiAlertIcon />
                                                ) : alert.entity === "picket" ? (
                                                    <PicketIcon />
                                                ) : alert.entity === "sale_scenario" ? (
                                                    <DecisionIcon />
                                                ) : (
                                                    <AlertIcon />
                                                )}
                                            </Row>
                                            {alert.failed ? (
                                                <Row
                                                    type="flex"
                                                    justify="center"
                                                    style={{ marginTop: "5px" }}
                                                >
                                                    <RedCircleIcon className="failed" />
                                                </Row>
                                            ) : null}
                                        </Col>
                                        <Col span={22}>
                                            <span>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: alert.message,
                                                    }}
                                                    style={{ display: "inline" }}
                                                />
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            </CardItem>
                        ))
                    )}
                </div>
                <div className="flex-2">
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}></Col>
                    <Col
                        xs={16}
                        sm={16}
                        md={16}
                        lg={16}
                        xl={16}
                        style={{ display: "flex", justifyContent: "space-between" }}
                    >
                        <span
                            className="text-purple"
                            onClick={() => setVisibleDrawerAlert(true)}
                        >
                            Visualizar todos
                        </span>
                    </Col>
                </div>
            </CardContainerAlert>
            <DrawerAlert
                visible={visibleDrawerAlert}
                onCloseDrawer={() => setVisibleDrawerAlert(false)}
                data={alertList}
            />
        </>
    );
};

export default AlertPanel;