import styled from "styled-components";

export const Container = styled.div`
  label {
    color: #9074bf;
    font-family: "Asap", sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    &:first-child {
      margin-top: 5px;
    }
    &.error {
      color: #d44c4c;
    }
  }
  .ant-select {
    width: 100%;
  }

  .body {
    margin-bottom: 16px;
    .ant-row-flex {
      margin-top: 8px;
    }
  }

  .cell-date {
    font-family: "Asap", sans-serif;
    font-size: 14px;
    font-weight: bold;
  }

  .row-date {
    margin-top: 5px;
    margin-bottom: 3px;
    padding: 5px;
  }

  .row-button {
    margin-top: 13px;
  }

  .iconError {
    color: #d44c4c;
    margin-right: 5px;
  }
`;

export const Form = styled.div`
  width: 100%;
  max-height: 400px;

  overflow-y: auto;
  overflow-x: hidden;
`;

export const ButtonWithoutBackground = styled.button`
  background: transparent;
  border: none;
  font-family: Asap;
  font-weight: bold;
  font-size: 14px;
  text-align: right;
  color: #684e94;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    color: #c4c4c4c4;
  }
`;
