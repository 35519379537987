import React from 'react';

const KpiAlertIcon = () => (
  <svg id="speedometer" xmlns="http://www.w3.org/2000/svg" width="17.402" height="17.402" viewBox="0 0 17.402 17.402">
    <path id="Caminho_2349" data-name="Caminho 2349" d="M8.7,17.4A8.7,8.7,0,1,0,0,8.7,8.72,8.72,0,0,0,8.7,17.4ZM3.709,14.534a6.7,6.7,0,0,1,9.983,0,7.665,7.665,0,0,1-9.983,0ZM3.082,8.191a5.617,5.617,0,0,1,1.287-3.1l.693.693.721-.721L5.09,4.369a5.617,5.617,0,0,1,3.1-1.287v1h1.02v-1a5.617,5.617,0,0,1,3.1,1.287l-.693.693.721.721.693-.693a5.618,5.618,0,0,1,1.287,3.1h-1V9.21h1a5.68,5.68,0,0,1-1.442,3.3,7.724,7.724,0,0,0-8.352,0,5.679,5.679,0,0,1-1.442-3.3h1V8.191ZM8.7,1.02a7.677,7.677,0,0,1,5.721,12.8,7.633,7.633,0,0,0-.723-.7A6.666,6.666,0,1,0,2.039,8.7,6.711,6.711,0,0,0,3.7,13.12a7.632,7.632,0,0,0-.723.7A7.677,7.677,0,0,1,8.7,1.02Zm0,0" fill="#a5a5a5"/>
    <path id="Caminho_2350" data-name="Caminho 2350" d="M211,392h3.059v1.02H211Zm0,0" transform="translate(-203.829 -378.677)" fill="#a5a5a5"/>
    <path id="Caminho_2351" data-name="Caminho 2351" d="M167.545,169.65a1.53,1.53,0,1,0,2.1-2.1l-.026-.016-3.835-1.743,1.744,3.835Zm1.593-1.217a.51.51,0,1,1-.708.708l-.59-1.3Zm0,0" transform="translate(-160.151 -160.154)" fill="#a5a5a5"/>
  </svg>
);
export default KpiAlertIcon;
