import apiFarms from "../config/api_farms";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiFarms = axios.create({
//   baseURL: "http://localhost:8082",
// });

export function generateConfinementMapDashboardData(payload) {
  const { groupId, farmId, signal } = payload;
  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/confinement/map/dashboard`,
    getHeaders(false, signal)
  );
}
