import React, { useEffect, useRef, useState, useCallback } from "react";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import useFinancialTransactionDashboardContext from "../../../../hooks/useFinancialTransactionDashboardContext";

import { Col, DatePicker, Row, Select, notification } from "antd";
import ButtonStandard from "../../../../components/utils/button";

import { indexAll } from "../../../../services/costCenterService";
import { findAll } from "../../../../services/financialProjectService";
import { indexAllWithItems } from "../../../../services/resultCenterService";
import { getFinancialTransactionDashboard } from "../../../../services/financialTransactionsService";
import { useCurrencyContext } from "../../../../hooks/useCurrencyContext";
import SelectCurrency from "../../../../components/utils/selectCurrency";

const INITIAL_FORM_VALUES = {
  resultCenterId: null,
  costCenterId: null,
  financialProjectId: null,
  costType: "AllTypes",
  period: "lastSevenDays",
  customStartDatePeriod: null,
  customFinalDatePeriod: null,
  bankAccountId: null,
};

const LIST_COST_TYPE = [
  { costType: "AllTypes", amountAnimals: 0 },
  { costType: "Growth", amountAnimals: 0 },
  { costType: "RecreateFattenPasture", amountAnimals: 0 },
  { costType: "RecreateFattenSemiConfinement", amountAnimals: 0 },
  { costType: "RecreateFattenConfinement", amountAnimals: 0 },
  { costType: "Agriculture", amountAnimals: 0 },
  { costType: "Others", amountAnimals: 0 },
];

const LIST_PERIODS = [
  "lastSevenDays",
  "lastThirdyDays",
  "currentMonth",
  "lastMonth",
  "currentYear",
  "lastYear",
  "currentHarvest",
  "lastHarvest",
  "customDateRange",
];

const validationSchema = Yup.object().shape({
  period: Yup.string().required(),
  customStartDatePeriod: Yup.date().when(["period"], {
    is: (period) => period === "customDateRange",
    then: Yup.date().required(),
    otherwise: Yup.date().notRequired().nullable(),
  }),
  customFinalDatePeriod: Yup.date().when(["period"], {
    is: (period) => period === "customDateRange",
    then: Yup.date().required(),
    otherwise: Yup.date().notRequired().nullable(),
  }),
});

function FinancialDashboardForm() {
  const formikRef = useRef();

  const [resultCenterList, setResultCenterList] = useState([]);
  const [isLoadingResultCenterList, setIsLoadingResultCenterList] =
    useState(false);
  const [costCenterList, setCostCenterList] = useState([]);
  const [isLoadingCostCenter, setIsLoadingCostCenter] = useState(false);

  const [financialProjectsList, setFinancialProjectsList] = useState([]);
  const [isLoadingFinancialProjects, setIsLoadingFinancialProjects] =
    useState(false);

  const { switchIsLoadingRequest, updateResults } =
    useFinancialTransactionDashboardContext();

  const { defaultCurrencyCode } = useCurrencyContext();

  const {
    app: {
      translation,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
  } = useSelector((state) => state);

  const handleSubmitFormFilters = useCallback(
    async (values, actions) => {
      switchIsLoadingRequest(true);
      try {
        const {
          data: { results },
        } = await getFinancialTransactionDashboard({
          groupId,
          farmId,
          body: values,
          currency: defaultCurrencyCode,
        });
        updateResults(results);
      } catch (error) {
        console.error(error);
        notification.error({
          message: "Erro ao retornar resultado do painel financeiro!",
        });
      } finally {
        switchIsLoadingRequest(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupId, farmId, defaultCurrencyCode]
  );

  useEffect(() => {
    async function fetchProjects() {
      setIsLoadingFinancialProjects(true);
      try {
        const {
          data: { results },
        } = await findAll({
          groupId,
          farmId,
        });
        setFinancialProjectsList(results);
      } catch (error) {
        notification.error({
          message: "Nenhum projeto financeiro encontrado!",
        });
      } finally {
        setIsLoadingFinancialProjects(false);
      }
    }
    async function fetchResultCenter() {
      setIsLoadingResultCenterList(true);
      try {
        const {
          data: { results: rcList },
        } = await indexAllWithItems({ groupId, farmId });
        setResultCenterList(rcList.filter((rc) => rc.status !== "Pending"));
        const resultCenterBovexo =
          rcList.find((rc) => rc.groupId === null && rc.farmId === null) ||
          null;
        formikRef.current.setFieldValue(
          "resultCenterId",
          resultCenterBovexo.id
        );
      } catch (error) {
        notification.error({
          message: "Nenhum centro de resultado encontrado!",
        });
      } finally {
        setIsLoadingResultCenterList(false);
      }
    }
    async function fetchCostCenters() {
      setIsLoadingCostCenter(true);
      try {
        const {
          data: { results: ccList },
        } = await indexAll({ groupId, farmId });
        setCostCenterList(ccList.filter((cc) => cc.status !== "Pending"));
        const costCenterBovexo =
          ccList.find((cc) => cc.groupId === null && cc.farmId === null) ||
          null;
        formikRef.current.setFieldValue("costCenterId", costCenterBovexo.id);
      } catch (error) {
        notification.error({
          message: "Nenhum centro de custo encontrado!",
        });
      } finally {
        setIsLoadingCostCenter(false);
      }
    }
    async function fetchInitialDashboardData() {
      switchIsLoadingRequest(true);
      try {
        const {
          data: { results },
        } = await getFinancialTransactionDashboard({
          groupId,
          farmId,
          body: {
            costType: "AllTypes",
            period: "lastSevenDays",
          },
          currency: defaultCurrencyCode,
        });
        updateResults(results);
      } catch (error) {
        console.error(error);
        notification.error({
          message: "Erro ao retornar resultado do painel financeiro!",
        });
      } finally {
        switchIsLoadingRequest(false);
      }
    }

    async function fetch() {
      try {
        formikRef.current.resetForm(INITIAL_FORM_VALUES);
        await Promise.all([
          fetchProjects(),
          fetchResultCenter(),
          fetchCostCenters(),
          fetchInitialDashboardData(),
        ]);
      } catch (error) {}
    }
    if (groupId && farmId && formikRef) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, farmId, formikRef, defaultCurrencyCode]);

  return (
    <Formik
      ref={formikRef}
      enableReinitialize
      initialValues={INITIAL_FORM_VALUES}
      onSubmit={handleSubmitFormFilters}
      validationSchema={validationSchema}
      render={({
        values,
        errors,
        submitCount,
        setFieldValue,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          {Object.entries(errors).length > 0 && submitCount > 0 && (
            <Row type="flex" justify="center" align="middle">
              <label className="filter-label error">
                {translation.error.formError}
              </label>
            </Row>
          )}
          <Row type="flex" gutter={8}>
            <Col xs={24} sm={24} md={24} lg={20} xl={20} xxl={20}>
              <Row type="flex" gutter={8}>
                <Col xs={12} sm={12} md={8} lg={6} xl={6} xxl={6}>
                  <Row>
                    <label className="filter-label">
                      {translation.financialDashboard.resultCenter}
                    </label>
                  </Row>
                  <Row>
                    <Select
                      style={{ width: "100%" }}
                      name="resultCenterId"
                      value={values.resultCenterId || undefined}
                      placeholder={translation.defaultSelectPlaceholder}
                      disabled
                      isLoading={isLoadingResultCenterList}
                      onChange={(selectedId) =>
                        setFieldValue("resultCenterId", selectedId)
                      }
                    >
                      {resultCenterList?.map((rc) => (
                        <Select.Option key={rc?.id} value={rc?.id}>
                          {rc?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
                <Col xs={12} sm={12} md={8} lg={6} xl={6} xxl={6}>
                  <Row>
                    <label className="filter-label">
                      {translation.financialDashboard.costCenter}
                    </label>
                  </Row>
                  <Row>
                    <Select
                      style={{ width: "100%" }}
                      name="costCenterId"
                      value={values.costCenterId || undefined}
                      placeholder={translation.defaultSelectPlaceholder}
                      disabled
                      isLoading={isLoadingCostCenter}
                      onChange={(selectedId) =>
                        setFieldValue("costCenterId", selectedId)
                      }
                    >
                      {costCenterList?.map((cc) => (
                        <Select.Option key={cc?.id} value={cc?.id}>
                          {cc?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
                <Col xs={12} sm={12} md={8} lg={6} xl={6} xxl={6}>
                  <Row>
                    <label className="filterLabel">
                      {translation.financialDashboard.costType}
                    </label>
                  </Row>
                  <Row>
                    <Select
                      style={{ width: "100%" }}
                      name="costType"
                      value={values.costType || undefined}
                      placeholder={translation.defaultSelectPlaceholder}
                      onChange={(value) => {
                        setFieldValue("costType", value);
                      }}
                    >
                      {LIST_COST_TYPE.map((l) => (
                        <Select.Option key={l.costType} value={l.costType}>
                          {`${translation.costType[l.costType]}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
                <Col xs={12} sm={12} md={8} lg={6} xl={6} xxl={6}>
                  <Row>
                    <label className="filter-label">
                      {translation.financialDashboard.financialProject}
                    </label>
                  </Row>
                  <Row>
                    <Select
                      style={{ width: "100%" }}
                      name="financialProjectId"
                      value={values.financialProjectId || undefined}
                      placeholder={translation.defaultSelectPlaceholder}
                      loading={isLoadingFinancialProjects}
                      allowClear
                      onDeselect={() => {
                        setFieldValue("financialProjectId", null);
                      }}
                      onChange={(value) => {
                        setFieldValue("financialProjectId", value);
                      }}
                    >
                      {financialProjectsList.map((l) => (
                        <Select.Option key={l.id} value={l.id}>
                          {l.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
              </Row>
              <Row type="flex" gutter={8}>
                <Col xs={12} sm={12} md={8} lg={6} xl={6} xxl={6}>
                  <Row>
                    <label className="filter-label">
                      {translation.financialDashboard.period}
                    </label>
                  </Row>
                  <Row>
                    <Select
                      style={{ width: "100%" }}
                      name="period"
                      value={values.period || undefined}
                      placeholder={translation.defaultSelectPlaceholder}
                      onChange={(period) => {
                        setFieldValue("period", period);
                        if (period !== "customDateRange") {
                          setFieldValue("customStartDatePeriod", null);
                          setFieldValue("customFinalDatePeriod", null);
                        }
                      }}
                    >
                      {LIST_PERIODS?.map((period) => (
                        <Select.Option key={period} value={period}>
                          {translation.financialDashboard.periods[period]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
                {values.period === "customDateRange" && (
                  <Col xs={12} sm={12} md={8} lg={6} xl={6} xxl={6}>
                    <Row type="flex" gutter={8}>
                      <Col span={12}>
                        <Row>
                          <label
                            className={
                              errors.customStartDatePeriod && submitCount > 0
                                ? "filter-label error"
                                : "filter-label"
                            }
                          >
                            De*
                          </label>
                        </Row>
                        <Row>
                          <DatePicker
                            name="customStartDatePeriod"
                            value={values.customStartDatePeriod || null}
                            placeholder={
                              translation.defaultDatePickerPlaceholder
                            }
                            format={"DD/MM/YYYY"}
                            onChange={(date) => {
                              setFieldValue("customStartDatePeriod", date);
                              if (
                                values.customFinalDatePeriod &&
                                date.isAfter(values.customFinalDatePeriod)
                              )
                                setFieldValue("customFinalDatePeriod", null);
                            }}
                          />
                        </Row>
                      </Col>
                      <Col span={12}>
                        <Row>
                          <label
                            className={
                              errors.customFinalDatePeriod && submitCount > 0
                                ? "filter-label error"
                                : "filter-label"
                            }
                          >
                            Até*
                          </label>
                        </Row>
                        <Row>
                          <DatePicker
                            name="customFinalDatePeriod"
                            value={values.customFinalDatePeriod || null}
                            placeholder={
                              translation.defaultDatePickerPlaceholder
                            }
                            format={"DD/MM/YYYY"}
                            disabledDate={(current) =>
                              values.customStartDatePeriod
                                ? current.isBefore(values.customStartDatePeriod)
                                : current.isAfter(moment())
                            }
                            onChange={(date) =>
                              setFieldValue("customFinalDatePeriod", date)
                            }
                          />
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col xs={12} sm={12} md={8} lg={6} xl={6} xxl={6}>
                  <Row>
                    <label className="filter-label">
                      {translation.financialDashboard.bankAccount}
                    </label>
                  </Row>
                  <Row>
                    <Select
                      style={{ width: "100%" }}
                      name="period"
                      value={values.bankAccountId || undefined}
                      placeholder={translation.defaultSelectPlaceholder}
                      disabled
                    />
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <label className="filterLabel">Moeda padrão</label>
                  </Row>
                  <Row>
                    <SelectCurrency value={defaultCurrencyCode} disabled />
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={4}
              xl={4}
              xxl={4}
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Row
                type="flex"
                justify="center"
                align="middle"
                style={{
                  marginTop: 8,
                }}
              >
                <ButtonStandard
                  name="buttonProcess"
                  buttonType="type8"
                  type="submit"
                >
                  Processar
                </ButtonStandard>
              </Row>
            </Col>
          </Row>
        </form>
      )}
    />
  );
}

export default FinancialDashboardForm;
