import React from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";

import { FiMinus, FiPlus, FiRefreshCcw } from "react-icons/fi";
import { CustomCollapse, EditButton, Footer } from "../styles";
import { Col, Input, Row, Spin } from "antd";
import PencilIcon from "../../../../components/utils/icons/pencil";
import ButtonStandard from "../../../../components/utils/button";
import NumberFormat from "react-number-format";
import { TableScore } from "./styles";
import InfoTooltip from "../../../../components/utils/infoTooltip";

// Services
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../../services/helpersMethodsService";
import {
  findAllReproductionScoreByGroupIdAndFarmId,
  saveReproductionScore,
} from "../../../../services/reproductionScoreService";

// import { Container } from './styles';

function CardReproductionScore() {
  const [isEditing, setIsEditing] = useState(false);
  const [defaultData, setDefaultData] = useState([]);
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [form, setForm] = useState({
    values: [],
  });
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  // Redux
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  // Callbacks
  const fetch = useCallback(async () => {
    setIsLoadingData(true);
    try {
      const {
        data: { results },
      } = await findAllReproductionScoreByGroupIdAndFarmId({
        groupId,
        farmId,
      });
      setDefaultData(results?.defaultValues);
      setData(results?.farmValues);
      setForm({
        values: [...results?.farmValues],
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingData(false);
    }
  }, [farmId, groupId]);

  const renderVisible = useCallback(
    (score) => {
      const item = form.values.find((v) => v.score === score);
      return (
        <strong>
          {`${numberMask(getTwoDecimalDigits(item?.value || 0), false)} %`}
        </strong>
      );
    },
    [form]
  );

  const renderEdit = useCallback(
    (score, pvalues, setFieldValue) => {
      const item = pvalues.values.find((v) => v.score === score);
      const index = pvalues.values.findIndex((v) => v.score === score);
      return (
        <NumberFormat
          customInput={Input}
          value={item?.value || 0}
          min={0}
          decimalScale={2}
          decimalSeparator=","
          thousandSeparator="."
          fixedDecimalScale={true}
          addonAfter={"%"}
          onValueChange={({ floatValue }) => {
            setFieldValue(
              `values[${index}].value`,
              floatValue != null ? (floatValue > 100 ? 100 : floatValue) : 0
            );
            setFieldValue(`values[${index}].groupId`, groupId);
            setFieldValue(`values[${index}].farmId`, farmId);
          }}
        />
      );
    },
    [farmId, groupId]
  );

  // Memos

  // Effects
  useEffect(() => {
    fetch();
  }, [fetch]);

  // Methods
  function handleResetDefault(resetForm) {
    setForm({
      values: [...defaultData],
    });
    resetForm({
      values: [...defaultData],
    });
  }
  function handleCancel(resetForm) {
    setForm({
      values: [...data],
    });
    resetForm({
      values: [...data],
    });
    setIsEditing((old) => !old);
  }

  async function handleSubmitModalForm(pvalues, actions) {
    const { values } = pvalues;
    let hasErrors = false;
    setIsLoadingRequest(true);
    try {
      values.forEach((v) => {
        if (v.value == null) {
          hasErrors = true;
        }
      });

      if (hasErrors) {
        actions.setErrors([
          {
            teste: "Algum campo está nulo ou errado",
          },
        ]);
        setIsLoadingRequest(false);
      } else {
        const body = {
          values: [...values.map((v) => v)],
        };
        const {
          data: { results },
        } = await saveReproductionScore({
          groupId,
          farmId,
          body,
        });
        setDefaultData(results?.defaultValues);
        setData(results?.farmValues);
        setForm({
          values: [...results?.farmValues],
        });
        actions.resetForm({
          values: [...results?.farmValues],
        });
        setIsEditing(false);
        setIsLoadingRequest(false);
      }
    } catch (error) {
      setIsLoadingRequest(false);
    }
  }

  return (
    <CustomCollapse
      expandIconPosition={"right"}
      expandIcon={(panelProps) =>
        panelProps.isActive ? <FiMinus size={16} /> : <FiPlus size={16} />
      }
    >
      <CustomCollapse.Panel
        header={
          <strong>
            {translation.reproductionParameters.score.title}{" "}
            <InfoTooltip
              title={translation.reproductionParameters.score.titleHelper}
            />
          </strong>
        }
        key={"1"}
      >
        <Spin spinning={isLoadingData || isLoadingRequest}>
          <Row type="flex" justify="end" gutter={8}>
            <Col span={2}>
              <EditButton
                type="button"
                onClick={() => setIsEditing((old) => !old)}
                disabled={isEditing}
              >
                {isEditing
                  ? `${translation.buttons.editing}...`
                  : `${translation.buttons.edit}`}
                <PencilIcon />
              </EditButton>
            </Col>
          </Row>
          <Formik
            enableReinitialize={true}
            initialValues={form}
            initialErrors={{}}
            onSubmit={handleSubmitModalForm}
            render={({
              values,
              errors,
              submitCount,
              setFieldValue,
              handleSubmit,
              resetForm,
            }) => (
              <form autoComplete="off" onSubmit={handleSubmit}>
                {Object.entries(errors).length > 0 && submitCount > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                <Row className="bodyContent">
                  <TableScore>
                    <thead>
                      <tr>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                        <th>7</th>
                        <th>8</th>
                        <th>9</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {!isEditing
                            ? renderVisible(1)
                            : renderEdit(1, values, setFieldValue)}
                        </td>
                        <td>
                          {!isEditing
                            ? renderVisible(2)
                            : renderEdit(2, values, setFieldValue)}
                        </td>
                        <td>
                          {!isEditing
                            ? renderVisible(3)
                            : renderEdit(3, values, setFieldValue)}
                        </td>
                        <td>
                          {!isEditing
                            ? renderVisible(4)
                            : renderEdit(4, values, setFieldValue)}
                        </td>
                        <td>
                          {!isEditing
                            ? renderVisible(5)
                            : renderEdit(5, values, setFieldValue)}
                        </td>
                        <td>
                          {!isEditing
                            ? renderVisible(6)
                            : renderEdit(6, values, setFieldValue)}
                        </td>
                        <td>
                          {!isEditing
                            ? renderVisible(7)
                            : renderEdit(7, values, setFieldValue)}
                        </td>
                        <td>
                          {!isEditing
                            ? renderVisible(8)
                            : renderEdit(8, values, setFieldValue)}
                        </td>
                        <td>
                          {!isEditing
                            ? renderVisible(9)
                            : renderEdit(9, values, setFieldValue)}
                        </td>
                      </tr>
                    </tbody>
                  </TableScore>
                </Row>
                {isEditing && (
                  <Footer>
                    <Row type="flex" justify="start">
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        className="buttonsDiv"
                      >
                        <ButtonStandard buttonType="principal" type="submit">
                          {translation.buttons.save}
                        </ButtonStandard>
                        <ButtonStandard
                          buttonType="thirdary"
                          type="button"
                          onClick={() => handleResetDefault(resetForm)}
                        >
                          <FiRefreshCcw /> Reverter valores padrões
                        </ButtonStandard>
                        <ButtonStandard
                          type="button"
                          buttonType="secondary"
                          onClick={() => handleCancel(resetForm)}
                        >
                          {translation.buttons.cancel}
                        </ButtonStandard>
                      </Col>
                    </Row>
                  </Footer>
                )}
              </form>
            )}
          />
        </Spin>
      </CustomCollapse.Panel>
    </CustomCollapse>
  );
}

export default CardReproductionScore;
