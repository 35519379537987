import apiFarms from "../config/api_farms";
import getHeaders from "./getHeaders";

export function indexAll(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/death/reasons`,
    getHeaders(false, signal)
  );
}

export function findById(payload) {
  const { groupId, farmId, id } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/death/reasons/${id}`,
    getHeaders(false, null)
  );
}

export function indexAllDropdown(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/death/reasons?onlyActives=true`,
    getHeaders(false, signal)
  );
}

export function save(payload) {
  const { groupId, farmId, body } = payload;

  return apiFarms.post(`/bovexo/${groupId}/farms/${farmId}/death/reasons`,
    body,
    getHeaders(false, null)
  );
}

export function update(payload) {
  const { groupId, farmId, id, body } = payload;

  return apiFarms.patch(`/bovexo/${groupId}/farms/${farmId}/death/reasons/${id}`,
    body,
    getHeaders(false, null)
  );
}

export function Inactivate(payload) {
  const { groupId, farmId, farmDeathReasonId, deathReasonId } = payload;

  let isDeathReason = false;
  let id = farmDeathReasonId;

  if(farmDeathReasonId === null) {
    isDeathReason = true;
    id = deathReasonId;
  }

  return apiFarms.put(`/bovexo/${groupId}/farms/${farmId}/death/reasons/${id}/inactivate${isDeathReason ? '?is_default_death_reason=true' : ''}`,
    null,
    getHeaders(false, null)
  );
}

export function Activate(payload) {
  const { groupId, farmId, farmDeathReasonId } = payload;

  return apiFarms.put(`/bovexo/${groupId}/farms/${farmId}/death/reasons/${farmDeathReasonId}/activate`,
    null,
    getHeaders(false, null)
  );
}

export function deleteById(payload) {
  const { groupId, farmId, id } = payload;

  return apiFarms.delete(`/bovexo/${groupId}/farms/${farmId}/death/reasons/${id}`,
    getHeaders(false, null)
  );
}