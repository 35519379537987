import {
  Col,
  DatePicker,
  Empty,
  Icon,
  Modal,
  Row,
  Spin,
  notification,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import ButtonStandard from "../../../../components/utils/button";
import { ModalBody } from "./styles";

import { getAnimalReproductionWeaningDashboard } from "../../../../services/dashboards/dashboardAnimalReproductionService";

import moment from "moment";
import {
  getParameterItems,
  saveOrUpdateParameter,
} from "../../../../services/generalParameterService";
import DashboardReproductionWeaningTable from "./table";

const PARAMETER_START_DATE_FILTER_CODE = 3004;
const PARAMETER_START_DATE_FILTER_ID = "1411cab3-5551-49ce-9f43-5af54b3f0ff9";
const PARAMETER_FINAL_DATE_FILTER_CODE = 3005;
const PARAMETER_FINAL_DATE_FILTER_ID = "d64d90e4-7506-42bc-8c8e-6579d647a289";

const DashboardReproductionWeaning = () => {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const [errors, setErrors] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [startDateFilterParameterItemId, setStartDateFilterParameterItemId] =
    useState(null);
  const [finalDateFilterParameterItemId, setFinalDateFilterParameterItemId] =
    useState(null);

  const [startDateFilter, setStartDateFilter] = useState(null);
  const [finalDateFilter, setFinalDateFilter] = useState(null);
  const [startDateFilterFromParam, setStartDateFilterFromParam] =
    useState(null);
  const [finalDateFilterFromParam, setFinalDateFilterFromParam] =
    useState(null);

  const [dashboardResult, setDashboardResult] = useState(null);

  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  const labelPeriod = useMemo(() => {
    if (startDateFilter && finalDateFilter) {
      return `Período: ${moment(startDateFilter).format("MMM/YYYY")} à ${moment(
        finalDateFilter
      ).format("MMM/YYYY")}`;
    } else {
      return "";
    }
  }, [startDateFilter, finalDateFilter]);

  const applyFilters = useCallback(async () => {
    if (!startDateFilter || !finalDateFilter) {
      notification.error({
        message: "É obrigatório informar uma Data Inicial e uma Data Final!",
      });
      if (!startDateFilter) {
        setErrors((prevState) => [...prevState, "startDateFilter"]);
      }
      if (!finalDateFilter) {
        setErrors((prevState) => [...prevState, "finalDateFilter"]);
      }
    }
    try {
      const {
        data: { results: resultsStartParam },
      } = await saveOrUpdateParameter({
        groupId,
        farmId,
        parameterItemId: startDateFilterParameterItemId,
        parameterId: PARAMETER_START_DATE_FILTER_ID,
        edit: startDateFilterParameterItemId != null,
        body: {
          id: startDateFilterParameterItemId,
          value: startDateFilter,
          valueExtra: null,
        },
      });
      const {
        data: { results: resultsFinalParam },
      } = await saveOrUpdateParameter({
        groupId,
        farmId,
        parameterItemId: finalDateFilterParameterItemId,
        parameterId: PARAMETER_FINAL_DATE_FILTER_ID,
        edit: finalDateFilterParameterItemId != null,
        body: {
          id: finalDateFilterParameterItemId,
          value: finalDateFilter,
          valueExtra: null,
        },
      });

      setStartDateFilterParameterItemId(resultsStartParam?.id);
      setFinalDateFilterParameterItemId(resultsFinalParam?.id);
      setStartDateFilterFromParam(startDateFilter);
      setFinalDateFilterFromParam(finalDateFilter);

      setIsModalVisible(false);
      fetchDashboard();
    } catch (error) {
    } finally {
    }
  }, [
    startDateFilter,
    startDateFilterParameterItemId,
    finalDateFilter,
    finalDateFilterParameterItemId,
  ]);

  const fetchDashboard = useCallback(
    async (pStartDate = null, pFinalDate = null) => {
      const startDate = pStartDate ? pStartDate : startDateFilter;
      const finalDate = pFinalDate ? pFinalDate : finalDateFilter;

      if (startDate && finalDate) {
        setIsLoadingRequest(true);
        try {
          const {
            data: { results },
          } = await getAnimalReproductionWeaningDashboard({
            groupId,
            farmId,
            startDate: startDate,
            finalDate: finalDate,
          });
          setDashboardResult(results);
        } catch (error) {
          setDashboardResult(null);
          notification.error({
            message:
              "Erro ao buscar dados de Desmame. Por favor, contate o suporte.",
          });
        } finally {
          setIsLoadingRequest(false);
        }
      }
    },
    [groupId, farmId, startDateFilter, finalDateFilter]
  );

  const handleCancelModal = useCallback(() => {
    setStartDateFilter(startDateFilterFromParam);
    setFinalDateFilter(finalDateFilterFromParam);
    setIsModalVisible(false);
    setErrors([]);
  }, [startDateFilterFromParam, finalDateFilterFromParam]);

  useEffect(() => {
    async function fetchParameters() {
      try {
        const [
          {
            data: { results: paramStartDate },
          },
          {
            data: { results: paramFinalDate },
          },
        ] = await Promise.all([
          getParameterItems({
            groupId,
            farmId,
            code: PARAMETER_START_DATE_FILTER_CODE,
          }),
          getParameterItems({
            groupId,
            farmId,
            code: PARAMETER_FINAL_DATE_FILTER_CODE,
          }),
        ]);

        setStartDateFilterParameterItemId(paramStartDate?.id || null);
        setFinalDateFilterParameterItemId(paramFinalDate?.id || null);

        setStartDateFilter(
          paramStartDate?.value ? moment(paramStartDate?.value) : null
        );
        setFinalDateFilter(
          paramFinalDate?.value ? moment(paramFinalDate?.value) : null
        );
        setStartDateFilterFromParam(
          paramStartDate?.value ? moment(paramStartDate?.value) : null
        );
        setFinalDateFilterFromParam(
          paramFinalDate?.value ? moment(paramFinalDate?.value) : null
        );
        fetchDashboard(
          paramStartDate?.value ? moment(paramStartDate?.value) : null,
          paramFinalDate?.value ? moment(paramFinalDate?.value) : null
        );
      } catch (error) {}
    }
    async function fetchAll() {
      await Promise.all([fetchParameters()]);
    }
    fetchAll();
  }, [groupId, farmId]);

  return (
    <Spin spinning={isLoadingRequest}>
      <div style={{ padding: 8 }}>
        <Row
          type="flex"
          justify="center"
          style={{
            backgroundColor: "#684e94",
            padding: "8px 16px",
            borderRadius: 5,
            height: 50,
            alignItems: "center",
          }}
          gutter={8}
        >
          <Col span={12}>
            <Row type="flex" justify="start" gutter={8}>
              <Col>
                <h3 style={{ color: "#fff", margin: 0 }}>Desmame</h3>
              </Col>
              <Col>
                <ButtonStandard
                  buttonType="arrow"
                  onClick={() => setIsModalVisible((prevState) => !prevState)}
                >
                  <Icon type="filter" />
                </ButtonStandard>
              </Col>
              <Col>
                <ButtonStandard
                  buttonType="arrow"
                  onClick={() => fetchDashboard()}
                >
                  <Icon type="reload" />
                </ButtonStandard>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row type="flex" justify="end" gutter={8}>
              <strong style={{ color: "#fff", margin: 0 }}>
                {labelPeriod}
              </strong>
            </Row>
          </Col>
        </Row>
        {startDateFilterParameterItemId == null &&
        finalDateFilterParameterItemId == null ? (
          <Empty description="Aplique algum filtro para poder mostrar os dados do gráfico." />
        ) : (
          <Row type="flex" gutter={4}>
            <Col span={24}>
              <DashboardReproductionWeaningTable
                dashboardResult={dashboardResult}
              />
            </Col>
          </Row>
        )}
        {/* Modal */}
        <Modal
          visible={isModalVisible}
          centered
          footer={null}
          closable
          onCancel={handleCancelModal}
          title="Aplicar filtros"
        >
          <ModalBody>
            {errors.length > 0 && (
              <Row
                type="flex"
                justify="center"
                align="middle"
                style={{ marginBottom: 8 }}
              >
                <label className="error">{translation.error.formError}</label>
              </Row>
            )}
            <Row type="flex" gutter={8}>
              <Col span={12}>
                <Row>
                  <label
                    className={
                      errors.includes("startDateFilter") ? "error" : ""
                    }
                  >
                    Data Inicial*
                  </label>
                </Row>
                <Row>
                  <DatePicker.MonthPicker
                    format={"MM/YYYY"}
                    value={startDateFilter || undefined}
                    placeholder={translation.defaultDatePickerPlaceholder}
                    onChange={(date) => {
                      setStartDateFilter(date);
                      setFinalDateFilter(null);
                      setErrors((prevState) =>
                        prevState.filter((error) => error !== "startDateFilter")
                      );
                    }}
                  />
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <label
                    className={
                      errors.includes("finalDateFilter") ? "error" : ""
                    }
                  >
                    Data Final*
                  </label>
                </Row>
                <Row>
                  <DatePicker.MonthPicker
                    value={finalDateFilter || undefined}
                    format={"MM/YYYY"}
                    placeholder={translation.defaultDatePickerPlaceholder}
                    disabledDate={(current) =>
                      startDateFilter
                        ? current.isBefore(moment(startDateFilter))
                        : false
                    }
                    onChange={(date) => {
                      setFinalDateFilter(date);
                      setErrors((prevState) =>
                        prevState.filter((error) => error !== "finalDateFilter")
                      );
                    }}
                  />
                </Row>
              </Col>
            </Row>
            <Row type="flex" justify="center" style={{ marginTop: 16 }}>
              <ButtonStandard buttonType="principal" onClick={applyFilters}>
                APLICAR FILTROS
              </ButtonStandard>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    </Spin>
  );
};

export default DashboardReproductionWeaning;
