import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";

import { Col, Row, Tabs } from "antd";

import ImportsIcon from "../../components/admin/sidebar/icons/imports";
import { Container, PageTitle } from "./styles";

// Pages
import useModuleContext from "../../hooks/useModuleContext";
import AnimalImport from "./animal";
import SupplementImport from "./supplement";
import WeightImport from "./weight";
import FinancialTransactionImport from "./financialTransaction";

const Import = () => {
  const [tabSelect, setTabSelect] = useState("1");
  const { translation } = useSelector((state) => state.app);
  const { isAllowedFunctions } = useModuleContext();

  const location = useLocation();

  const handleTabClick = (key, e) => {
    setTabSelect(key);
  };

  useEffect(() => {
    const values = queryString.parse(location.search);
    if (values != null && values.tabSelect != null) {
      setTabSelect(values.tabSelect);
    }
  }, [location.search]);

  return (
    <Container>
      {/* HEADER */}
      <Row type="flex">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <PageTitle>
            <ImportsIcon />
            <span>{translation.imports.title}</span>
          </PageTitle>
        </Col>
      </Row>
      {/* Tabs */}
      <Row type="flex" style={{ marginTop: 16 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Tabs
            type="card"
            defaultActiveKey="1"
            activeKey={tabSelect}
            onTabClick={handleTabClick}
          >
            <Tabs.TabPane
              tab={<span>{translation.imports.tabs.animal}</span>}
              key="1"
            >
              <AnimalImport />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span>{translation.imports.tabs.weight}</span>}
              key="2"
            >
              <WeightImport />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span>{translation.imports.tabs.supplement}</span>}
              key="3"
              disabled={!isAllowedFunctions(["SUPPLEMENTS"])}
            >
              <SupplementImport />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <span>{translation.imports.tabs.financialTransactions}</span>
              }
              key="4"
            >
              <FinancialTransactionImport />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Import));
