import React, { useEffect } from "react";
import moment from "moment/moment";
import { Creators as UserTriggerActions } from "../../store/ducks/userTrigger";
import { useDispatch, useSelector } from "react-redux";

import { Card, Col, Row, Spin } from "antd";
import ButtonStandard from "../../components/utils/button";
import UserTriggerCostModal from "./modals/triggerCost";

import { Container, LogTextContainer, Title } from "./styles";

const UserTriggerItem = ({ buttonClick, data }) => {
  return (
    <Card>
      <Row type="flex" align="middle">
        <Col span={12}>
          <ButtonStandard buttonType="type8" onClick={buttonClick}>
            Recalcular Custos de Produção
          </ButtonStandard>
        </Col>
        <Col span={12}>
          <LogTextContainer>
            <span>
              <strong>Último processamento: </strong>
              {data != null
                ? `(${moment(data?.request.startDate).format(
                    "DD/MM/YYYY"
                  )} à ${moment(data?.request.finalDate).format(
                    "DD/MM/YYYY"
                  )}) - ${moment(data?.processedDate).format(
                    "DD/MM/YYYY"
                  )} - ${data?.userName}`
                : "-"}
            </span>
          </LogTextContainer>
        </Col>
      </Row>
    </Card>
  );
};

const UserTriggerLayout = () => {
  const { isLoading, dataList } = useSelector((state) => state.userTrigger);
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      UserTriggerActions.getLastStatusUserTrigger(
        groupId,
        farmId,
        "AnimalDailyCost"
      )
    );
  }, [dispatch, groupId, farmId]);
  const handleOpenModal = (modal) => {
    switch (modal) {
      case "AnimalDailyCost":
        dispatch(UserTriggerActions.showOrHideModalTriggerCost());
        break;
      default:
        break;
    }
  };
  return (
    <Container>
      <Row type="flex">
        <Col span={12}>
          <Title>Rotinas Especiais</Title>
        </Col>
      </Row>
      <Spin spinning={isLoading}>
        <UserTriggerItem
          buttonClick={() => handleOpenModal("AnimalDailyCost")}
          data={dataList?.AnimalDailyCost}
        />
      </Spin>
    </Container>
  );
};

const UserTrigger = () => {
  return (
    <>
      <UserTriggerLayout />
      {/* Modals */}
      <UserTriggerCostModal />
    </>
  );
};

export default UserTrigger;
