import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .ant-row-flex,
  .ant-row {
    width: 100%;
  }
  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }
`;

export const Header = styled.div`
  padding: 10px 0px;
  .back-container {
    text-decoration: none;
    margin-right: 15px;
    padding-right: 10px;
    border-right: 1px solid #c8c8c8;
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #d44c4c !important;

    svg {
      color: #d44c4c;
    }
  }

  span.title {
    font-family: Asap;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    color: #4b4b4b;
  }
`;

export const Main = styled.div`
  height: -moz-calc(100% - 50px);
  height: -webkit-calc(100% - 50px);
  height: calc(100% - 50px);
  display: flex;
  justify-content: flex-start;
  padding-top: 15px;

  &.center {
    justify-content: center;
  }
`;

export const ColLots = styled.ul`
  padding: 2px 0px;
  margin: 0px 5px;
  background: #fff;
  filter: drop-shadow(0px 1px 2px #bebebe);
  list-style: none;
`;

export const ColLotsItem = styled.li`
  border-top: 1px solid #f5f5f5;
  &:first-of-type {
    border-top: none;
    div.main {
      div.headerContent {
        span.lotName {
          font-family: Asap;
          font-weight: normal;
          font-size: 12px;
          text-align: left;
          color: #a5a5a5;
          text-transform: uppercase;
        }
      }
    }
  }

  div.main {
    display: flex;
    flex-direction: column;
    div.headerContent {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 15px;
      span.lotName {
        font-family: Asap;
        font-weight: bold;
        font-size: 14px;
        text-align: left;
        color: #4b4b4b;
      }
      div.icons {
        & > * {
          cursor: pointer;
        }
        button {
          background: transparent;
          border: none;
        }
        #arrowIcon {
          width: 15px;
          height: 15px;
          margin-left: 10px;
          color: #9074bf;
        }
      }
    }
    div.details {
      display: none;
    }

    &.active {
      div.details {
        display: flex;
        padding: 15px;
        flex-direction: column;
        background: #fcfcfc;
        font-family: Asap;
        font-weight: normal;
        font-size: 12px;
        text-align: left;
        color: #4b4b4b;

        .ant-row-flex:not(:first-of-type) {
          margin-top: 11px;
        }
      }
    }
  }
`;

export const ColDashboards = styled.div`
  margin: 0px 5px;
`;
