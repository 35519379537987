import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as ClientActions } from "../../../../store/ducks/client";

// Components
import { Container, BoxStatus, CardDetail } from "./styles";
import { Row, Col, Spin, Icon } from "antd";
import CopyClipboard from "../../../utils/copyClipboard";
// Services
import { findClientById } from "../../../../services/managementService";
import {
  cnpjMask,
  cpfMask,
  getOnlyNumber,
  phoneMask,
} from "../../../../services/helpersMethodsService";

const DrawerClientDetails = () => {
  const [loadRequest, setLoadRequest] = useState(false);
  const [clientData, setClientData] = useState(null);
  // Redux Variable
  const {
    groupSelected: { id: groupIdSelected },
    farmSelected: { id: farmIdSelected },
  } = useSelector((state) => state.app);
  const { drawerClientDetailsVisible, idClientEditOrDetails } = useSelector(
    (state) => state.client
  );
  const dispatch = useDispatch();

  // Methods
  function closeDrawer() {
    setClientData(null);
    dispatch(ClientActions.hideDrawerClientVisible());
  }

  async function handleDrawerVisible(visible) {
    if (visible) {
      setLoadRequest(true);
      try {
        if (idClientEditOrDetails !== null) {
          const {
            data: { results },
          } = await findClientById({
            clientId: idClientEditOrDetails,
            groupId: groupIdSelected,
            farmId: farmIdSelected,
          });
          let resultsFormated = {
            ...results,
            document:
              results.documentType === "CNPJ"
                ? cnpjMask(results.document)
                : cpfMask(results.document ? results.document : ""),
          };
          setClientData(resultsFormated);
        }
      } catch (error) {
      } finally {
        setLoadRequest(false);
      }
    }
  }

  return (
    <Container
      title="Detalhes do cliente"
      width={511}
      onClose={closeDrawer}
      maskClosable={false}
      visible={drawerClientDetailsVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <div className="drawerForm">
        <Spin spinning={loadRequest}>
          {clientData?.documentType === "CNPJ" ? (
            <>
              <Row type="flex">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Row>
                    <span className="subTitle">Nome da empresa</span>
                  </Row>
                  <Row>
                    <span className="bold">{clientData?.name}</span>
                  </Row>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Row type="flex" justify="end" align="middle">
                    {clientData?.status === "Active" ? (
                      <BoxStatus className="active">
                        <span>Ativo</span>
                      </BoxStatus>
                    ) : clientData?.status === "Inactive" ? (
                      <BoxStatus className="inactive">
                        <span>Inativo</span>
                      </BoxStatus>
                    ) : clientData?.status === "Bovexo" ? (
                      <BoxStatus className="bovexo">
                        <span>Bovexo</span>
                      </BoxStatus>
                    ) : null}
                  </Row>
                </Col>
              </Row>
              <Row type="flex">
                <CardDetail>
                  <div className="contentGroup">
                    <span className="title-grey">
                      {clientData?.documentType}
                    </span>
                    <span className="bold">{clientData?.document}</span>
                  </div>
                  <div className="contentGroup">
                    <span className="title-grey">INCRA</span>
                    <span className="bold">
                      {clientData?.register === null
                        ? clientData?.register
                        : "-"}
                    </span>
                  </div>
                </CardDetail>
              </Row>
            </>
          ) : null}

          {clientData?.documentType === "CNPJ" ? (
            <Row type="flex">
              <CardDetail>
                <div className="contentRow">
                  <Icon
                    type="user"
                    style={{ fontSize: 24, color: "#C4C4C4" }}
                  />
                  <span className="title">{clientData?.responsibleName}</span>
                </div>
              </CardDetail>
            </Row>
          ) : null}

          {clientData?.documentType === "CPF" ? (
            <Row type="flex">
              <CardDetail>
                <Row type="flex" justify="space-between">
                  <div className="contentRow">
                    <Icon
                      type="user"
                      style={{ fontSize: 24, color: "#C4C4C4" }}
                    />
                    <span className="title">{clientData?.name}</span>
                  </div>
                  <Row
                    type="flex"
                    justify="end"
                    align="middle"
                    style={{ marginLeft: 50 }}
                  >
                    {clientData?.status === "Active" ? (
                      <BoxStatus className="active">
                        <span>Ativo</span>
                      </BoxStatus>
                    ) : clientData?.status === "Inactive" ? (
                      <BoxStatus className="inactive">
                        <span>Inativo</span>
                      </BoxStatus>
                    ) : clientData?.status === "Bovexo" ? (
                      <BoxStatus className="bovexo">
                        <span>Bovexo</span>
                      </BoxStatus>
                    ) : null}
                  </Row>
                </Row>
              </CardDetail>
            </Row>
          ) : null}

          {clientData?.documentType === "CPF" ? (
            <Row type="flex">
              <CardDetail>
                <div className="contentRow">
                  <span className="document">CPF</span>
                  <span className="title margin-top">
                    {clientData?.document}
                  </span>
                </div>
              </CardDetail>
            </Row>
          ) : null}

          <Row type="flex">
            <CardDetail>
              <div className="contentRow">
                <Icon type="phone" style={{ fontSize: 24, color: "#C4C4C4" }} />
                <span className="title">
                  {clientData?.phone === null || clientData?.phone === ""
                    ? "-"
                    : phoneMask(getOnlyNumber(clientData?.phone))}
                </span>
              </div>
              <div className="contentRow svgCustom">
                <CopyClipboard text={clientData?.phone} />
              </div>
            </CardDetail>
          </Row>

          <Row type="flex">
            <CardDetail>
              <Row style={{ width: "100%" }}>
                <Row>
                  <div className="contentRowFlex">
                    <div>
                      <Icon
                        type="pushpin"
                        style={{ fontSize: 24, color: "#C4C4C4" }}
                      />
                      <span className="title">
                        <span>
                          {clientData?.address}, {clientData?.number}
                        </span>{" "}
                        <br />
                        <span className="title-margin">
                          {clientData?.city?.name} - {clientData?.state} -{" "}
                          {clientData?.zipCode}
                        </span>{" "}
                        <br />
                        <span className="title-card">Complemento</span> <br />
                        <span className="title-margin">
                          {clientData?.complement}
                        </span>
                      </span>
                    </div>
                    <div className="svgCustom">
                      <CopyClipboard
                        text={`${clientData?.address}, ${clientData?.number}, ${clientData?.city?.name}/${clientData?.state}. CEP: ${clientData?.zipCode}`}
                      />
                    </div>
                  </div>
                </Row>
                <Row></Row>
                <Row></Row>
              </Row>
            </CardDetail>
          </Row>
        </Spin>
      </div>
    </Container>
  );
};

export default DrawerClientDetails;
