import React, { Component } from "react";
import Axios from "axios";
import { withRouter, Link } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Row, Col, Table, Icon } from "antd";

import { Creators as AppActions } from "../../../store/ducks/app";

import { getAnimalImportLog } from "../../../services/importService";

import { Title, CardCustom } from "./styles";

import EyeIcon from "../../../components/utils/icons/eye";
import ButtonStandard from "../../../components/utils/button";
import TagStatus from "../../../components/utils/tagStatus";
import AllowedComponentTo from "../../../components/utils/allowedComponentTo";

class AnimalImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      importAnimalLogData: [],
      isLoading: false,
    };
    this.signal = Axios.CancelToken.source();
  }

  componentWillUnmount() {
    this.signal.cancel("Cancelando apis");
  }

  componentDidMount() {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;
    if (
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      this.fetchData(groupSelected, farmSelected);
    }
  }

  fetchData = async (
    groupSelected,
    farmSelected,
    page = 0,
    sorter = {},
    filters = "",
    size = 10
  ) => {
    try {
      this.setState({ isLoading: true });

      const { id: groupId } = groupSelected;
      const { id: farmId } = farmSelected;
      const {
        data: { results: importAnimalLogData },
      } = await getAnimalImportLog({
        groupId,
        farmId,
        page,
        sorter,
        filters,
        size,
        signal: this.signal,
      });
      this.setState({
        importAnimalLogData,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        importAnimalLogData: null,
      });
    }
  };

  handleReloadTable = async () => {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;
    this.fetchData(groupSelected, farmSelected);
  };

  handleTableChange = async (pagination, filters, sorter) => {
    this.setState({
      paginationInfo: pagination,
      sortedInfo: sorter,
      filteredInfo: filters,
    });
    const {
      app: { groupSelected, farmSelected },
    } = this.props;

    let search = "";

    this.fetchData(
      groupSelected,
      farmSelected,
      pagination.current,
      sorter,
      search,
      pagination.pageSize
    );
  };

  render() {
    const {
      app: { translation },
      appActions,
    } = this.props;
    const { importAnimalLogData: data, isLoading } = this.state;

    const Column = Table.Column;

    return (
      <CardCustom>
        <Row type="flex" justify="space-between" className="rowHeader">
          <Col>
            <Title>{translation.imports.animal.table.title}</Title>
          </Col>
          <Col>
            <ButtonStandard
              buttonType="typeWithoutBackground"
              onClick={this.handleReloadTable}
            >
              <Icon type="reload" />
            </ButtonStandard>
            <AllowedComponentTo roles={["Trial"]} reverse hide>
              <ButtonStandard
                type="button"
                buttonType="type8"
                onClick={() => appActions.showModalAnimalUpload()}
              >
                {translation.imports.animal.buttonImport}
              </ButtonStandard>
            </AllowedComponentTo>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              loading={isLoading}
              rowKey="id"
              size="small"
              dataSource={data != null ? data.content : []}
              pagination={{
                total: data != null ? data.totalElements : 0,
                size: data != null ? data.size : 0,
                current: data != null ? data.number + 1 : 0,
                hideOnSinglePage:
                  data !== null && Object.entries(data).length !== 0
                    ? data.totalElements > 10
                      ? false
                      : true
                    : true,
                showSizeChanger: true,
                pageSizeOptions: [
                  "10",
                  "20",
                  "30",
                  "100",
                  "500",
                  "1000",
                  "2000",
                ],
              }}
              scroll={{
                x: true,
              }}
              style={{ width: "100%", margin: "0" }}
              onChange={this.handleTableChange}
            >
              <Column
                title={translation.imports.animal.table.columns.status}
                dataIndex="status"
                key="status"
                align="left"
                render={(status) =>
                  status === "Processed" ? (
                    <TagStatus
                      background="#C5F1CA"
                      borderColor="#106518"
                      color="#106518"
                    >
                      {translation.imports.status.processed}
                    </TagStatus>
                  ) : status === "Queue" ? (
                    <TagStatus
                      background="#EBF7FF"
                      borderColor="#4A85AE"
                      color="#4A85AE"
                    >
                      {translation.imports.status.queue}
                    </TagStatus>
                  ) : status === "Processing" ? (
                    <TagStatus
                      background="#FEFFF6"
                      borderColor="#A9C133"
                      color="#A9C133"
                    >
                      {translation.imports.status.processing}
                    </TagStatus>
                  ) : (
                    <TagStatus
                      background="#FFD8D8"
                      borderColor="#D44C4C"
                      color="#D44C4C"
                    >
                      {translation.imports.status.error}
                    </TagStatus>
                  )
                }
              />
              <Column
                title={translation.imports.animal.table.columns.fileName}
                dataIndex="fileName"
                key="fileName"
                align="left"
                sorter
                sortDirections={["descend", "ascend"]}
              />
              <Column
                title={translation.imports.animal.table.columns.date}
                dataIndex="date"
                align="left"
                key="date"
              />
              <Column
                title={translation.imports.animal.table.columns.total}
                dataIndex="total"
                align="center"
                key="total"
              />
              <Column
                title={translation.imports.animal.table.columns.numberImported}
                dataIndex="numberImported"
                align="center"
                key="numberImported"
              />
              <Column
                title={translation.imports.animal.table.columns.numberAlerted}
                dataIndex="numberAlerted"
                align="center"
                key="numberAlerted"
              />
              <Column
                title={translation.imports.animal.table.columns.numberError}
                dataIndex="numberError"
                align="center"
                key="numberError"
              />
              <Column
                title={
                  translation.imports.animal.table.columns.numberDuplicated
                }
                dataIndex="numberDuplicated"
                align="center"
                key="numberDuplicated"
              />
              <Column
                title={translation.imports.animal.table.columns.message}
                dataIndex="message"
                align="left"
                key="message"
              />
              <Column
                align="left"
                render={(text, record) =>
                  record.status === "Processed" ? (
                    <Link
                      to={`/admin/imports/animals/${record.requestId}/details`}
                    >
                      <EyeIcon isActive />
                    </Link>
                  ) : null
                }
              />
            </Table>
          </Col>
        </Row>
      </CardCustom>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnimalImport)
);
