import styled from "styled-components";

export const LastLotHistoryContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LastLotHistory = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  background: #f5f5f5;
  width: 630px;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.16);

  h3 {
    font-family: "Asap", sans-serif;
    color: #9074bf;
  }

  .nameContainer {
    height: 100%;
    display: flex;
    align-items: center;

    .lotName {
      font-family: Asap;
      font-weight: bold;
      font-size: 16px;
      text-align: left;
      color: #a5a5a5;
    }
  }
  .datesContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: flex-end;
  }
`;
