import styled from "styled-components";
export const Container = styled.div`
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 3px 6px #dcdcdc;
  margin-top: 10px;
  margin-right: 2px;
  padding: 0px 0px 25px 22px;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  div.header {
    padding: 7px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span {
      font-family: Asap;
      font-weight: bold;
      font-style: italic;
      font-size: 11px;
      text-align: left;
      color: #a5a5a5;
    }
  }
  div.data {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    div.titleContainer {
      display: flex;
      align-items: center;
      svg {
        margin-left: 10px;
      }
      p.title {
        font-family: Asap;
        font-weight: normal;
        font-size: 14px;
        text-align: left;
        color: #a5a5a5;
        margin: 0;
      }
    }
    div.ranking {
      flex: 1;
      display: flex;
      justify-content: space-between;
      padding-left: 50px;
      padding-right: 20px;
      div.numbers {
        display: flex;
        flex-direction: column;
        padding: 5px;
        transition: transform 0.4s;
        cursor: pointer;
        &.active {
          border-radius: 5px;
          transform: translate(0, -10px);
          box-shadow: 0px 3px 6px #4b4b4b;
        }
        &.inactive {
          background-color: #ffffff;
          opacity: 0.3;
        }
        div.label {
          display: flex;
          flex-direction: row;
          align-items: center;
          span.rank {
            font-family: Asap;
            font-weight: normal;
            font-size: 14px;
            &.red {
              color: #e88d8d;
            }
            &.blue {
              color: #99bbc9;
            }
            &.green {
              color: #94c0b1;
            }
          }
          div.circle {
            width: 10px;
            height: 10px;
            margin-right: 6px;
            border-radius: 50%;
            &.red {
              background: linear-gradient(90deg, #b16060 50%, #9b4d4d 50%);
            }
            &.green {
              background: linear-gradient(90deg, #85b6a6 50%, #679c8b 50%);
            }
            &.blue {
              background: linear-gradient(90deg, #99bbc9 50%, #8facb8 50%);
            }
          }
          span.rankValue {
            font-family: Asap;
            font-weight: normal;
            font-size: 14px;
            text-align: right;
            color: #c4c4c4;
          }
        }
        div.value {
          display: flex;
          justify-content: center;
          span {
            font-family: Asap;
            font-weight: bold;
            font-size: 20px;
            color: #858585;
          }
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
    div.data {
      flex-direction: column;
      flex: 1;

      div.titleContainer {
        justify-content: center;
      }

      div.ranking {
        padding: 0;
        margin-top: 10px;
        div.numbers {
          div.label {
            flex-direction: column;
          }
        }
      }
    }
  }
`;
