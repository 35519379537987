import React from "react";

const FinancialIcon = () => (
  <svg
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 25 25"
  >
    <style type="text/css"></style>
    <g>
      <path
        className="st0"
        d="M0.82,11.48c0.04-0.32,0.08-0.63,0.13-0.95c0.5-3.12,2.02-5.63,4.5-7.57c1.64-1.28,3.5-2.05,5.56-2.33
		c1.32-0.18,2.63-0.12,3.93,0.15c0.44,0.09,0.67,0.43,0.59,0.83c-0.07,0.38-0.43,0.6-0.86,0.52c-0.92-0.17-1.85-0.26-2.79-0.2
		C9.74,2.07,7.83,2.82,6.16,4.16C4.1,5.8,2.8,7.93,2.36,10.53c-0.51,3.01,0.24,5.72,2.08,8.14c0.01,0.02,0.03,0.03,0.08,0.07
		c-0.02-0.18-0.04-0.32-0.07-0.47c-0.07-0.44,0.18-0.8,0.59-0.86c0.38-0.06,0.72,0.22,0.78,0.66c0.12,0.81,0.22,1.62,0.35,2.43
		c0.12,0.69-0.4,1.04-0.94,0.91c-0.88-0.21-1.77-0.35-2.66-0.52c-0.42-0.08-0.68-0.42-0.61-0.8c0.08-0.4,0.44-0.63,0.88-0.55
		c0.18,0.03,0.36,0.07,0.59,0.11c-0.07-0.1-0.12-0.16-0.16-0.22c-1.42-1.86-2.22-3.95-2.43-6.28c-0.01-0.07-0.03-0.13-0.04-0.2
		C0.82,12.46,0.82,11.97,0.82,11.48z"
      />
      <path
        className="st0"
        d="M12.03,24c-0.38-0.05-0.76-0.09-1.14-0.14c-0.24-0.03-0.48-0.08-0.72-0.13c-0.37-0.09-0.59-0.43-0.53-0.79
		c0.06-0.35,0.4-0.59,0.78-0.55c0.66,0.07,1.32,0.2,1.98,0.22c4.55,0.11,8.66-2.8,10.04-7.18c1.06-3.38,0.49-6.52-1.57-9.39
		c-0.05-0.07-0.11-0.14-0.21-0.2c0.02,0.18,0.05,0.36,0.06,0.54c0.02,0.33-0.16,0.59-0.45,0.7c-0.42,0.15-0.84-0.11-0.92-0.59
		c-0.07-0.45-0.13-0.91-0.19-1.36c-0.06-0.39-0.11-0.79-0.17-1.18C18.9,3.39,19.31,3,19.85,3.11c0.91,0.18,1.82,0.35,2.73,0.53
		c0.44,0.09,0.7,0.43,0.61,0.84c-0.08,0.38-0.45,0.59-0.89,0.51c-0.18-0.03-0.36-0.06-0.54-0.1c0.31,0.47,0.64,0.91,0.92,1.38
		c0.94,1.57,1.48,3.26,1.62,5.08c0.01,0.07,0.03,0.15,0.04,0.22c0,0.47,0,0.95,0,1.42c-0.05,0.39-0.09,0.79-0.16,1.18
		c-0.41,2.37-1.42,4.44-3.08,6.18c-1.86,1.95-4.15,3.11-6.82,3.52c-0.37,0.06-0.74,0.09-1.11,0.13C12.79,24,12.41,24,12.03,24z"
      />
      <path
        className="st0"
        d="M19.75,12.31c-0.01,3.95-3.22,7.16-7.18,7.15c-3.95-0.01-7.17-3.23-7.16-7.18c0.01-3.95,3.24-7.16,7.18-7.15
		C16.56,5.15,19.76,8.36,19.75,12.31z M12.59,6.52c-3.19,0-5.78,2.59-5.79,5.78c0,3.18,2.6,5.79,5.78,5.79
		c3.19,0,5.79-2.59,5.79-5.77C18.38,9.11,15.79,6.52,12.59,6.52z"
      />
      <path
        className="st0"
        d="M13.27,15.62c0,0.2,0.01,0.39,0,0.57c-0.01,0.38-0.3,0.67-0.67,0.67c-0.38,0.01-0.69-0.28-0.71-0.67
		c-0.01-0.19,0-0.38,0-0.57c-0.24-0.04-0.46-0.05-0.67-0.12c-0.26-0.09-0.51-0.2-0.74-0.34c-0.31-0.2-0.38-0.6-0.2-0.91
		c0.18-0.31,0.58-0.44,0.9-0.25c0.32,0.19,0.65,0.23,1.01,0.22c0.36-0.01,0.72,0,1.08,0c0.35-0.01,0.6-0.21,0.64-0.51
		c0.06-0.39-0.2-0.71-0.61-0.72c-0.51-0.01-1.01,0.02-1.52-0.01c-0.79-0.04-1.39-0.42-1.73-1.14c-0.34-0.72-0.26-1.41,0.21-2.05
		c0.35-0.48,0.85-0.73,1.45-0.79C11.77,9,11.82,9,11.89,8.99c0-0.18,0-0.35,0-0.51c0.01-0.44,0.3-0.75,0.7-0.75
		c0.39,0,0.68,0.31,0.68,0.74c0,0.17,0,0.33,0,0.5c0.22,0.04,0.42,0.07,0.62,0.13c0.39,0.11,0.73,0.32,1.01,0.61
		c0.28,0.29,0.29,0.72,0.02,0.99c-0.26,0.26-0.69,0.28-0.97,0.01c-0.24-0.23-0.52-0.33-0.85-0.32c-0.39,0.01-0.78,0-1.17,0
		c-0.41,0-0.68,0.26-0.68,0.62c0,0.36,0.28,0.61,0.69,0.61c0.47,0,0.93-0.01,1.4,0c1.05,0.02,1.9,0.82,1.96,1.84
		c0.06,1.08-0.64,1.96-1.7,2.12C13.5,15.59,13.4,15.6,13.27,15.62z"
      />
    </g>
  </svg>
);
export default FinancialIcon;
