export const Types = {
  RESET_DATA: "supplier/RESET_DATA",
  GET_DROPDOWN_SUPPLIERS: "supplier/GET_DROPDOWN_SUPPLIERS",
  GET_DROPDOWN_SUPPLIERS_SUCCESS: "supplier/GET_DROPDOWN_SUPPLIERS_SUCCESS",
  GET_DROPDOWN_SUPPLIERS_ERROR: "supplier/GET_DROPDOWN_SUPPLIERS_ERROR",
  GET_ALL_SUPPLIERS: "supplier/GET_ALL_SUPPLIERS",
  GET_ALL_SUPPLIERS_SUCCESS: "supplier/GET_ALL_SUPPLIERS_SUCCESS",
  GET_ALL_SUPPLIERS_ERROR: "supplier/GET_ALL_SUPPLIERS_ERROR",
  GET_ALL_SUPPLIERS_PAGEABLE: "supplier/GET_ALL_SUPPLIERS_PAGEABLE",
  GET_ALL_SUPPLIERS_PAGEABLE_SUCCESS:
    "supplier/GET_ALL_SUPPLIERS_PAGEABLE_SUCCESS",
  GET_SUPPLIER: "supplier/GET_SUPPLIER",
  GET_SUPPLIER_SUCCESS: "supplier/GET_SUPPLIER_SUCCESS",
  GET_SUPPLIER_ERROR: "supplier/GET_SUPPLIER_ERROR",
  SHOW_DRAWER_SUPPLIER: "supplier/SHOW_DRAWER_SUPPLIER",
  HIDE_DRAWER_SUPPLIER: "supplier/HIDE_DRAWER_SUPPLIER",
  SAVE_SUPPLIER: "supplier/SAVE_SUPPLIER",
  SAVE_SUPPLIER_SUCCESS: "supplier/SAVE_SUPPLIER_SUCCESS",
  SAVE_SUPPLIER_ERROR: "supplier/SAVE_SUPPLIER_ERROR",
  DELETE_SUPPLIER: "supplier/DELETE_SUPPLIER",
  DELETE_SUPPLIER_SUCCESS: "supplier/DELETE_SUPPLIER_SUCCESS",
  DELETE_SUPPLIER_ERROR: "supplier/DELETE_SUPPLIER_ERROR",
  REACTIVATE_SUPPLIER: "supplier/REACTIVATE_SUPPLIER",
  REACTIVATE_SUPPLIER_SUCCESS: "supplier/REACTIVATE_SUPPLIER_SUCCESS",
  REACTIVATE_SUPPLIER_ERROR: "supplier/REACTIVATE_SUPPLIER_ERROR",
  REFRESH_LIST_DROPDOWN: "supplier/REFRESH_LIST_DROPDOWN",
};

export const Creators = {
  indexSuppliers: (
    groupId,
    farmId,
    type = null,
    withoutPagination = false,
    page = 0,
    sorter = null,
    filters = null,
    size = 10
  ) => ({
    type: Types.GET_ALL_SUPPLIERS,
    payload: {
      groupId,
      farmId,
      type,
      withoutPagination,
      page,
      sorter,
      filters,
      size,
    },
  }),

  indexSuppliersSuccess: (data) => ({
    type: Types.GET_ALL_SUPPLIERS_SUCCESS,
    payload: {
      data,
    },
  }),

  indexSuppliersError: (error) => ({
    type: Types.GET_ALL_SUPPLIERS_ERROR,
    payload: {
      error,
    },
  }),

  getDropdownSuppliers: (
    groupId,
    farmId,
    type = null,
    withoutPagination = true,
    onlySpecifics = false
  ) => ({
    type: Types.GET_DROPDOWN_SUPPLIERS,
    payload: {
      groupId,
      farmId,
      type,
      withoutPagination,
      onlySpecifics,
    },
  }),

  getDropdownSuppliersSuccess: (data) => ({
    type: Types.GET_DROPDOWN_SUPPLIERS_SUCCESS,
    payload: {
      data,
    },
  }),

  getDropdownSuppliersError: (error) => ({
    type: Types.GET_DROPDOWN_SUPPLIERS_ERROR,
    payload: {
      error,
    },
  }),

  indexSuppliersPageable: (
    groupId,
    farmId,
    type = null,
    withoutPagination = false,
    page = 0,
    sorter = {},
    filters = "",
    size = 10,
    signal = null
  ) => ({
    type: Types.GET_ALL_SUPPLIERS_PAGEABLE,
    payload: {
      groupId,
      farmId,
      type,
      withoutPagination,
      page,
      sorter,
      filters,
      size,
      signal,
    },
  }),

  indexSuppliersPageableSuccess: (data) => ({
    type: Types.GET_ALL_SUPPLIERS_PAGEABLE_SUCCESS,
    payload: {
      data,
    },
  }),

  indexSuppliersPageableSuccessError: (error) => ({
    type: Types.GET_ALL_SUPPLIERS_ERROR,
    payload: {
      error,
    },
  }),

  showSupplier: (groupId, farmId, id) => ({
    type: Types.GET_SUPPLIER,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),

  showSupplierSuccess: (data) => ({
    type: Types.GET_SUPPLIER_SUCCESS,
    payload: {
      data,
    },
  }),

  showSupplierError: (error) => ({
    type: Types.GET_SUPPLIER_ERROR,
    payload: {
      error,
    },
  }),

  showDrawer: (actionType = "edit", id = null, onlyForGroup = false) => ({
    type: Types.SHOW_DRAWER_SUPPLIER,
    payload: {
      actionType,
      id,
      onlyForGroup,
    },
  }),

  hideDrawer: () => ({
    type: Types.HIDE_DRAWER_SUPPLIER,
    payload: {},
  }),

  saveSupplier: (
    groupId,
    farmId = null,
    id = null,
    supplier,
    saveOptions,
    signal = null
  ) => ({
    type: Types.SAVE_SUPPLIER,
    payload: {
      groupId,
      farmId,
      id,
      supplier,
      saveOptions,
      signal,
    },
  }),

  saveSupplierSuccess: (data, saveOptions) => ({
    type: Types.SAVE_SUPPLIER_SUCCESS,
    payload: { data, saveOptions },
  }),

  saveSupplierError: (error) => ({
    type: Types.SAVE_SUPPLIER_ERROR,
    payload: { error },
  }),

  deleteSupplier: (groupId, farmId = null, id) => ({
    type: Types.DELETE_SUPPLIER,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),

  deleteSupplierSuccess: () => ({
    type: Types.DELETE_SUPPLIER_SUCCESS,
    payload: {},
  }),

  deleteSupplierError: (error) => ({
    type: Types.DELETE_SUPPLIER_ERROR,
    payload: { error },
  }),

  reactivateSupplier: (groupId, farmId = null, id) => ({
    type: Types.REACTIVATE_SUPPLIER,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),

  reactivateSupplierSuccess: () => ({
    type: Types.REACTIVATE_SUPPLIER_SUCCESS,
    payload: {},
  }),

  reactivateSupplierError: (error) => ({
    type: Types.REACTIVATE_SUPPLIER_ERROR,
    payload: { error },
  }),

  refreshDrowpDownList: (newSupplier = null) => ({
    type: Types.REFRESH_LIST_DROPDOWN,
    payload: { newSupplier },
  }),
};

const INITIAL_STATE = {
  data: [],
  dataPageable: null,
  listDrowpDown: [],
  pagination: {
    page: 0,
    sorter: null,
    filters: null,
    size: 10,
  },
  isLoading: false,
  isLoadingDropDown: false,
  isLoadingRequest: false,
  shouldRefreshListDropDown: false,
  blockChooseSupplierType: false,
  blockChooseGroupSelection: false,
  newSupplier: null,
  error: [],
  supplierSelected: {},
  supplierData: null,
  idSupplierEditOrDetails: null,
  drawerVisible: false,
  drawerActionType: null,
  drawerSupplierDetailsVisible: false,
};

export default function supplier(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_ALL_SUPPLIERS:
      const { page, sorter, filters, size } = action.payload;
      return {
        ...state,
        pagination: {
          page,
          sorter,
          filters,
          size,
        },
        isLoading: true,
      };
    case Types.GET_ALL_SUPPLIERS_SUCCESS:
      return { ...state, isLoading: false, data: action.payload.data };
    case Types.GET_ALL_SUPPLIERS_ERROR:
      const { error } = action.payload;
      return { ...state, isLoading: false, data: null, error };
    case Types.GET_DROPDOWN_SUPPLIERS:
      return { ...state, isLoadingDropDown: true };
    case Types.GET_DROPDOWN_SUPPLIERS_SUCCESS:
      return {
        ...state,
        isLoadingDropDown: false,
        listDrowpDown: action.payload.data,
      };
    case Types.GET_DROPDOWN_SUPPLIERS_ERROR:
      return {
        ...state,
        isLoadingDropDown: false,
        listDrowpDown: [],
        error: action.payload.error,
      };
    case Types.GET_ALL_SUPPLIERS_PAGEABLE:
      return { ...state, isLoading: true };
    case Types.GET_ALL_SUPPLIERS_PAGEABLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dataPageable: action.payload.data,
      };
    case Types.GET_SUPPLIER:
      return { ...state, isLoadingRequest: true };
    case Types.GET_SUPPLIER_SUCCESS:
      return {
        ...state,
        isLoadingRequest: false,
        supplierData: action.payload.data,
      };
    case Types.GET_SUPPLIER_ERROR:
      return {
        ...state,
        isLoadingRequest: false,
        supplierData: null,
        error,
      };
    case Types.SHOW_DRAWER_SUPPLIER:
      return {
        ...state,
        drawerActionType: action.payload.actionType,
        blockChooseGroupSelection: action.payload.onlyForGroup,
        blockChooseSupplierType:
          action.payload.actionType === "new_supplement_supplier" ||
          action.payload.actionType === "new_veterinary_supplement_supplier" ||
          action.payload.actionType === "new_others_supplier" ||
          action.payload.actionType === "new_animal_supplier"
            ? true
            : false,
        drawerVisible:
          action.payload.actionType === "edit" ||
          action.payload.actionType === "new" ||
          action.payload.actionType === "new_supplement_supplier" ||
          action.payload.actionType === "new_veterinary_supplement_supplier" ||
          action.payload.actionType === "new_others_supplier" ||
          action.payload.actionType === "new_animal_supplier"
            ? true
            : false,
        drawerSupplierDetailsVisible:
          action.payload.actionType === "details" ? true : false,
        idSupplierEditOrDetails: action.payload.id,
      };
    case Types.HIDE_DRAWER_SUPPLIER:
      return {
        ...state,
        drawerVisible: false,
        drawerSupplierDetailsVisible: false,
        blockChooseGroupSelection: false,
        supplierData: null,
        idSupplierEditOrDetails: null,
      };
    case Types.SAVE_SUPPLIER:
      return { ...state, isLoadingRequest: true };
    case Types.SAVE_SUPPLIER_SUCCESS:
      return {
        ...state,
        isLoadingRequest: false,
        supplierData: null,
        blockChooseSupplierType: false,
        drawerVisible: action.payload.saveOptions,
        drawerActionType: null,
      };
    case Types.SAVE_SUPPLIER_ERROR:
      return {
        ...state,
        isLoadingRequest: false,
        error: action.payload.error,
      };
    case Types.DELETE_SUPPLIER:
      return { ...state, isLoading: true };
    case Types.DELETE_SUPPLIER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case Types.DELETE_SUPPLIER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case Types.REACTIVATE_SUPPLIER:
      return { ...state, isLoading: true };
    case Types.REACTIVATE_SUPPLIER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case Types.REACTIVATE_SUPPLIER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case Types.REFRESH_LIST_DROPDOWN:
      return {
        ...state,
        shouldRefreshListDropDown: !state.shouldRefreshListDropDown,
        newSupplier: action.payload.newSupplier,
      };
    default:
      return state;
  }
}
