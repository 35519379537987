import { call, put } from "redux-saga/effects";
import { Creators as DietActions } from "../ducks/diet";
/** Services */
import {
  getDietIndex,
  getDietShow,
  saveDiet,
  deleteDietServ
} from "../../services/dietService";
import { notification } from "antd";

export function* indexDiet(action) {
  try {
    const {
      data: { results: allDiets }
    } = yield call(getDietIndex, action.payload);

    yield put(DietActions.indexDietSuccess(allDiets));
  } catch (error) {
    yield put(DietActions.indexDietError(error));
  }
}

export function* getDiet(action) {
  try {
    const {
      data: { results: allDiets }
    } = yield call(getDietShow, action.payload);
    yield put(DietActions.showDietSuccess(allDiets));
  } catch (error) {
    yield put(DietActions.showDietError(error));
  }
}

export function* storeOrUpdateDiet(action) {
  let result = null;
  try {
    const {
      data: { results: newDiet }
    } = yield call(saveDiet, action.payload);
    result = newDiet;
    const { saveOptions } = action.payload;
    yield put(DietActions.saveDietSuccess(newDiet, saveOptions));
    if (action.payload.id == null)
      notification.success({ message: "Dieta criada com sucesso" });
    else notification.success({ message: "Dieta atualizada com sucesso" });
  } catch (error) {
    notification.error({ message: "Dieta não foi criado" });
    yield put(DietActions.saveDietError(error));
  }

  if (result != null && Object.entries(result).length > 0) {
    const newPayload = {
      ...action.payload,
      page: 1,
      size: 10,
      sorter: {}
    };

    const {
      data: { results: allDiets }
    } = yield call(getDietIndex, newPayload);

    yield put(DietActions.indexDietSuccess(allDiets));
  }
}

export function* deleteDiet(action) {
  try {
    const { data } = yield call(deleteDietServ, action.payload);
    yield put(DietActions.deleteDietSuccess(data));
    notification.success({
      message: "Dieta inativada com sucesso"
    });
  } catch (error) {
    notification.error({ message: "Dieta não foi inativada" });
    yield put(DietActions.deleteDietError(error));
  }
}
