import styled from "styled-components";
import { Card } from "antd";

export const Container = styled.div`
  min-height: 580px;
  overflow-y: auto;
  overflow-x: hidden;
  @media screen and (max-width: 576px) {
    height: 440px;
    min-height: 440px;
  }

  .alertSelected {
    background: #fe8d2a;
    border: 1px solid #fe8d2a;
  }

  .alertDate {
    .ant-calendar-picker-input.ant-input {
      line-height: 1.5;
      padding: 4px 5px;
      width: 85px;
      border: 0px;
      color: #d44c4c !important;
      font-weight: bold;
    }

    svg {
      height: 10px;
      width: 10px;
      path {
        fill: #d44c4c !important;
      }
    }
  }
`;

export const CardCustom = styled(Card)`
  margin-top: 13px !important;
  margin-left: 2px;
  border-radius: 7px !important;
  background: #fff !important;
  box-shadow: 0px 0px 10px #d8d8d8 !important;

  padding: 14px 0 5px 0 !important;
  .ant-card-body {
    padding-top: 14px;
  }
  .rowHeader {
    margin-bottom: 17px;
  }
  .iconError {
    color: #d44c4c;
  }
  .iconCheck {
    color: #4b4b4b;
  }
  .colButtons {
    display: flex;
    justify-content: flex-end;
  }
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;

export const AlertButton = styled.button`
  margin-left: 5px;
  padding: 0px 5px;
  border-radius: 5px;
  height: 34px;
  background: #d44c4c;
  border: 1px solid #d44c4c;
  color: #ffffff;
  text-align: center;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }

  svg {
    margin-left: 5px;
  }
`;
