import styled from "styled-components";
import { Divider, Tabs } from "antd";

export const Container = styled.div`
  height: 100%;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 20px;
`;

export const Title = styled.h1`
  font-family: Asap;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  color: #4b4b4b;
  margin: 0;
`;

export const CustomDivider = styled(Divider)`
  margin: 20px 0 20px 0 !important;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 20px 0 20px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;

export const Body = styled.div`
  height: -moz-calc(100% - 55px - 20px - 20px);
  height: -webkit-calc(100% - 55px - 20px - 20px);
  height: calc(100% - 55px - 20px - 20px);

  .buttonsCol {
    button {
      &:first-of-type {
        margin-right: 19px;
      }
    }
  }
`;

export const SaleScenarioTabs = styled(Tabs)`
  height: 550px;
  overflow-y: auto;

  &.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-scroll {
    margin-top: 50% !important;
  }

  &.ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
  &.ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
    margin: 0px 0 0px 0;
  }

  .ant-tabs-tabpane.ant-tabs-tabpane-active {
    height: 550px;
    border-radius: 5px !important;
    background: #fff !important;
  }
`;
