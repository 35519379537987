import React, { Component, Fragment } from "react";
import axios from "axios";
import CanvasJSReact from "../../../../assets/canvasjs.react";
import { bindActionCreators } from "redux";
import moment from "moment";
import { connect } from "react-redux";
import { Creators as LotActions } from "../../../../store/ducks/lot";
/** Components */
import {
  Row,
  Col,
  Popover,
  Empty,
  Icon,
  Select,
  Button,
  Input,
  Radio,
  notification,
  Tooltip,
  Modal,
  Spin,
} from "antd";
import { CustomDivider, AlertDefault } from "../styles";
import {
  Container,
  CardAnimalsChart,
  Box,
  LegendTitle,
  Square,
  CustomSpin,
  ButtonPin,
  BoxRow,
  ButtonFilter,
  ColFilter,
  RowFilter,
  RadioButtonCustom,
  SelectLabelAnimalsBox,
  ColCardCurve,
  ButtonCurveParameter,
  WeightRankingContainer,
  EditLotWeightRankingContainer,
} from "./styles";
import DrawerLotCurveParameter from "../lotCurveParameter";
import EyeIcon from "../../../../components/utils/icons/eye";
import TagStatus from "../../../../components/utils/tagStatus";
import InfoTooltip from "../../../../components/utils/infoTooltip";
import PencilIcon from "../../../../components/utils/icons/pencil";
import BoxText from "../../../../components/utils/boxText";
import InfoIcon from "../../../../components/utils/icons/info";
import ButtonStandard from "../../../../components/utils/button";
import NumberFormat from "react-number-format";
/* Services*/
import {
  getLotAnimals,
  getLotShow,
  updateLotWeightRanking,
} from "../../../../services/lotService";
import { returnDietStrategyCurve } from "../../../../services/curveService";
import { getBreedIndexActive } from "../../../../services/breedService";
import { setLotEstimatedCurve } from "../../../../services/estimatedWeightCurveService";
import { getParameterItems } from "../../../../services/generalParameterService";
import { getAnimalDailyWeightDashboard } from "../../../../services/dashboards/dashboardAnimalService";
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../../services/helpersMethodsService";
import {
  generateNewColor,
  dietStrategyColor,
  lotBreedColor,
  mediaWeightColor,
} from "../../../../utils/colorsHandler";
import { getDietStrategyShow } from "../../../../services/dietStrategyService";
import { getDashboardLotDailyWeightAverage } from "../../../../services/dashboards/dashboardLotService";

const { CanvasJSChart } = CanvasJSReact;

const CODE_PARAMETER_4002 = 4002;

class LotDetailsCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoadingChart: true,
      isLoadingRequestUpdateLotWeightRanking: false,
      isLoadingRequestSetCurve: false,
      lotInfo: null,
      originalListAnimals: [],
      allAnimals: [],
      animals: null,
      showFilters: false,
      lotCurveInfo: null,
      fundoPercentage: 0,
      cabeceiraPercentage: 0,
      lotCurveDietStrategyData: [],
      lotCurveData: [],
      animalSelected: [],
      dataChart: [],
      breeds: [],
      suppliers: [],
      farmOwners: [],
      showAnimalsLabel: "handlingNumber",
      animalsSort: "handlingNumber",
      rankingAnimalsType: "lastDailyWeight",
      lotDrawerCurveParameterVisible: false,
      oldAnimalBirthyDay: null,
      filters: [
        {
          key: null,
          comparator: "equal",
          min: null,
          max: null,
          uniqueValue: null,
          values: null,
        },
      ],
      isModalEditLotWeightRankingVisible: false,
      editFundoPercentage: 0,
      editCabeceiraPercentage: 0,
      shouldStartAnimalHidden: false,
      lotAverageWeight: 0,
      lotAverageGMD: 0,
      animalsWeightAndDates: [],
      useDietStrategyCost: true,
    };
  }

  signal = axios.CancelToken.source();

  componentWillUnmount() {
    this.signal.cancel("Cancelando apis");
  }

  async componentDidMount() {
    const {
      app: { groupSelected, farmSelected },
      lotActions,
      lot: { animalSelectedFromAnimalDetails },
    } = this.props;

    if (
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      const { id: groupId } = groupSelected;
      const { id: farmId } = farmSelected;
      const { lotId } = this.props;
      if (animalSelectedFromAnimalDetails) {
        this.setState(
          {
            animalSelected: [
              ...this.state.animalSelected,
              animalSelectedFromAnimalDetails,
            ],
            shouldStartAnimalHidden: false,
          },
          () => {
            lotActions.showAnimalInChartFromAnimalDetails(null);
            this.getAnimalWeights(
              groupId,
              farmId,
              animalSelectedFromAnimalDetails
            );
            this.getLotInfo(groupId, farmId, lotId);
          }
        );
      } else {
        this.setState({
          filters: [
            {
              key: null,
              comparator: "equal",
              min: null,
              max: null,
              uniqueValue: null,
              values: null,
            },
          ],
          isModalEditLotWeightRankingVisible: false,
          lotCurveDietStrategyData: [],
          lotCurveData: [],
          animalSelected: [],
          originalListAnimals: [],
          allAnimals: [],
          animals: null,
          shouldStartAnimalHidden: false,
        });
        this.getLotInfo(groupId, farmId, lotId);
      }
    }
  }

  getParameter4002 = async (groupId, farmId) => {
    const {
      data: { results: parameter4002 },
    } = await getParameterItems({
      groupId,
      farmId,
      code: CODE_PARAMETER_4002,
    });
    this.setState({
      useDietStrategyCost: parameter4002.value === "DietStrategy",
    });
  };

  getBreedsAndWeightRanking = async (groupId, farmId) => {
    try {
      let code = 1003;
      if (groupId != null && farmId != null) {
        const [
          {
            data: { results: breeds },
          },
          {
            data: {
              results: {
                value: fundoPercentage,
                valueExtra: cabeceiraPercentage,
              },
            },
          },
        ] = await Promise.all([
          getBreedIndexActive({
            groupId,
            farmId,
            signal: this.signal,
            withoutBreedCurveParam: true,
          }),
          getParameterItems({
            groupId,
            farmId,
            code,
            signal: this.signal,
          }),
        ]);

        this.setState({ breeds, fundoPercentage, cabeceiraPercentage });
      }
    } catch (error) {}
  };

  sortAnimalsByLastWeight = (first, second) => {
    if (first.lastDailyWeight < second.lastDailyWeight) {
      return 1;
    }
    if (first.lastDailyWeight > second.lastDailyWeight) {
      return -1;
    }

    return 0;
  };

  sortAnimalsByLastWeightGain = (first, second) => {
    if (first.lastDailyWeightGain < second.lastDailyWeightGain) {
      return 1;
    }
    if (first.lastDailyWeightGain > second.lastDailyWeightGain) {
      return -1;
    }

    return 0;
  };

  sortAnimalsByAgeInDays = (first, second) => {
    if (first.ageInDays < second.ageInDays) {
      return -1;
    }
    if (first.ageInDays > second.ageInDays) {
      return 1;
    }

    return 0;
  };

  sortAnimalsByHandlingNumber = (first, second) => {
    return first.handlingNumber.localeCompare(second.handlingNumber, "pt-BR", {
      numeric: true,
      ignorePunctuation: true,
    });
  };

  /****************************** Lot methods ***************************/

  getLotInfo = async (groupId, farmId, id) => {
    try {
      const {
        data: { results: lot },
      } = await getLotShow({ groupId, farmId, id, signal: this.signal });

      const {
        data: { results: curve },
      } = await getDashboardLotDailyWeightAverage({
        groupId,
        farmId,
        id,
        signal: this.signal,
      });

      this.setState({
        lotInfo: lot,
        oldAnimalBirthyDay: lot.referenceBirthdayDate,
        lotCurveInfo: curve,
      });

      if (
        lot !== null &&
        lot.highestWeightPercentage !== null &&
        lot.highestWeightPercentage > 0 &&
        lot.worstWeightPercentage !== null &&
        lot.worstWeightPercentage > 0
      ) {
        this.setState({
          cabeceiraPercentage: lot.highestWeightPercentage,
          fundoPercentage: lot.worstWeightPercentage,
        });
      } else {
        await this.getBreedsAndWeightRanking(groupId, farmId);
        await this.getParameter4002(groupId, farmId);
      }

      this.calculateLotCurve(lot, curve, lot.referenceBirthdayDate);
    } catch (error) {}
  };

  calculateLotDietStrategyCurve = async (
    groupId,
    farmId,
    id,
    referenceDate,
    lot
  ) => {
    try {
      const dietStrategyBaselineId =
        lot?.dietStrategies.find((l) => l.baseline === true) != null
          ? lot?.dietStrategies.find((l) => l.baseline === true).dietStrategyId
          : null;
      let lotCurveDietStrategyData = [];
      if (dietStrategyBaselineId != null) {
        const {
          data: { results: dietStrategyBaseline },
        } = await getDietStrategyShow({
          groupId,
          farmId,
          id: dietStrategyBaselineId,
        });
        lotCurveDietStrategyData = await returnDietStrategyCurve(
          groupId,
          farmId,
          id,
          "Lot",
          referenceDate,
          this.signal,
          dietStrategyBaseline
        );

        if (this.state.dataChart.find((dc) => dc.id === "lotDSCurve")) {
          this.setState({
            lotCurveDietStrategyData: lotCurveDietStrategyData
              ? lotCurveDietStrategyData
              : [],
            dataChart: this.state.dataChart.map((dc) => {
              if (dc.id === "lotDSCurve")
                return {
                  id: "lotDSCurve",
                  type: "line",
                  name: "Estratégia de Dieta",
                  showInLegend: true,
                  markerType: "none",
                  markerSize: 5,
                  xValueFormatString: "DD/MM/YY",
                  yValueFormatString: "###0.## kg",
                  color: dietStrategyColor,
                  dataPoints: lotCurveDietStrategyData,
                };
              else return dc;
            }),
            isLoadingChart: false,
          });
        } else {
          this.setState({
            lotCurveDietStrategyData: lotCurveDietStrategyData
              ? lotCurveDietStrategyData
              : [],
            dataChart: [
              ...this.state.dataChart,
              {
                id: "lotDSCurve",
                type: "line",
                name: "Estratégia de Dieta",
                showInLegend: true,
                markerType: "none",
                markerSize: 5,
                xValueFormatString: "DD/MM/YY",
                yValueFormatString: "###0.## kg",
                color: dietStrategyColor,
                dataPoints: lotCurveDietStrategyData,
              },
            ],
            isLoadingChart: false,
          });
        }
      } else {
        this.setState({
          isLoadingChart: false,
        });
      }
    } catch (error) {
      this.setState({
        isLoadingChart: false,
      });
    }
  };

  calculateLotCurve = (lot, curve, startDate = null, refreshAnimals = true) => {
    let result = [];
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;
    if (lot != null && curve != null) {
      try {
        curve.forEach((e) => {
          let obj = {
            x: moment(e.weightDate).toDate(),
            y: getTwoDecimalDigits(e.weight),
            toolTipContent: `<span style='"'color: {color};'"'>{name}:</span> ${moment(
              e.weightDate
            ).format("DD/MM/YY")}  | {y}`,
            markerType: "none",
          };
          result.push(obj);
        });
      } catch (error) {}
    }
    if (this.state.dataChart.find((dc) => dc.id === "lotCurve")) {
      this.setState({
        lotCurveData: result,
        dataChart: this.state.dataChart.map((dc) => {
          if (dc.id === "lotCurve")
            return {
              id: "lotCurve",
              type: "line",
              name: "Lote",
              showInLegend: true,
              markerType: "none",
              markerSize: 5,
              xValueFormatString: "DD/MM/YY",
              yValueFormatString: "###0.## kg",
              color: lotBreedColor,
              dataPoints: result,
            };
          else return dc;
        }),
      });
    } else {
      this.setState({
        lotCurveData: result,
        dataChart: [
          ...this.state.dataChart,
          {
            id: "lotCurve",
            type: "line",
            name: "Lote",
            showInLegend: true,
            markerType: "none",
            markerSize: 5,
            xValueFormatString: "DD/MM/YY",
            yValueFormatString: "###0.## kg",
            color: lotBreedColor,
            dataPoints: result,
          },
        ],
      });
    }
    if (refreshAnimals) {
      this.calculateLotDietStrategyCurve(
        groupId,
        farmId,
        lot.id,
        lot.referenceBirthdayDate,
        lot
      );
      // Get Animals and Classify
      this.getAnimals(groupId, farmId, lot.id);
    }
  };

  calculateLotAverageCurve = () => {
    let result = [];
    const { dataChart, animalsWeightAndDates } = this.state;
    try {
      // Flat map the animalsWeightAndDates creating a new array with all values
      const allValues = animalsWeightAndDates.flatMap((a) => a.values);

      // Group all values by Date
      const groupByDate = allValues.reduce((groups, value) => {
        const date = value.date;
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(value.weight);
        return groups;
      }, {});

      // Transform groupByDate into an array of objects
      let groupByDateArrays = Object.keys(groupByDate).map((date) => {
        return {
          date,
          weights: groupByDate[date],
        };
      });

      // Sort the groupByDateArrays by date
      groupByDateArrays = groupByDateArrays.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });
      // Populate result with date and weight's average
      groupByDateArrays.forEach((group) => {
        const sum = group.weights.reduce((total, b) => {
          return total + b;
        }, 0);
        const amount = group.weights.length;
        const average = sum / amount;
        result.push({
          x: moment(group.date).toDate(),
          y: getTwoDecimalDigits(average),
          toolTipContent: `<span style='"'color: {color};'"'>{name}:</span> {x} | {y}`,
        });
      });

      if (dataChart.find((dc) => dc.id === "calculateMeanWeightID")) {
        this.setState({
          dataChart: dataChart.map((dc) => {
            if (dc.id === "calculateMeanWeightID")
              return {
                id: "calculateMeanWeightID",
                type: "line",
                name: "Média Animais",
                showInLegend: true,
                color: mediaWeightColor,
                markerType: "none",
                markerSize: 5,
                xValueFormatString: "DD/MM/YY",
                yValueFormatString: "###0.## kg",
                dataPoints: result,
              };
            else return dc;
          }),
        });
      } else {
        this.setState({
          dataChart: [
            ...dataChart,
            {
              id: "calculateMeanWeightID",
              type: "line",
              name: "Média Animais",
              showInLegend: true,
              color: mediaWeightColor,
              markerType: "none",
              markerSize: 5,
              xValueFormatString: "DD/MM/YY",
              yValueFormatString: "###0.## kg",
              dataPoints: result,
            },
          ],
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  /************* Animals methods *******************/

  getAnimals = async (groupId, farmId, lotId) => {
    this.setState({ isLoading: true });
    try {
      const {
        data: { results: animals },
      } = await getLotAnimals({
        groupId,
        farmId,
        id: lotId,
        signal: this.signal,
      });

      /** Add new field (age in days) for each animal **/
      let totalWeight = 0;
      let totalGMD = 0;

      let lastWeightDate = null;
      animals.forEach((a) => {
        if (lastWeightDate == null || a.lastDailyWeightDate > lastWeightDate) {
          lastWeightDate = a.lastDailyWeightDate;
        }
      });

      animals.map((a) => {
        totalWeight += a.lastDailyWeight;
        a.originalLastDailyWeightGain = a.lastDailyWeightGain;

        if (
          (lastWeightDate === null ||
            lastWeightDate === a.lastDailyWeightDate) &&
          a.birthday !== a.lastDailyWeightDate &&
          a.acquisitionDate !== a.lastDailyWeightDate
        ) {
          totalGMD += a.lastDailyWeightGain;
        } else {
          a.lastDailyWeightGain = 0;
        }
        a.ageInDays = moment().diff(moment(a.birthday), "days");
        a.ageInMonths = moment().diff(moment(a.birthday), "months");

        if (a.acquisitionDate !== null) {
          a.timeInFarm = moment().diff(moment(a.acquisitionDate), "days");
        } else {
          a.timeInFarm = moment().diff(moment(a.birthday), "days");
        }

        return a;
      });

      let averageWeight = totalWeight / animals.length;
      let averageGMD =
        totalGMD /
        animals.filter(
          (a) =>
            (lastWeightDate === null ||
              a.lastDailyWeightDate === lastWeightDate) &&
            a.birthday !== a.lastDailyWeightDate &&
            a.acquisitionDate !== a.lastDailyWeightDate
        ).length;

      this.setState({
        lotAverageWeight: averageWeight,
        lotAverageGMD: averageGMD,
      });

      /** Classifying the animals */
      const newAnimals = await this.classifyAnimals(animals, averageWeight);

      /** Sort the animals by the handling number */
      newAnimals.sort(this.sortAnimalsByHandlingNumber);

      /** Separate the animals in differents weight rankings */
      const animalsTop = newAnimals.filter((a) => a.type === "top");
      const animalsMedium = newAnimals.filter((a) => a.type === "medium");
      const animalsBad = newAnimals.filter((a) => a.type === "bad");
      const animalsNone = newAnimals.filter((a) => a.type === "none");

      const suppliersFromAnimals = animals.map((a) => {
        return { id: a.supplierId, name: a.supplierName };
      });

      const animalOwners = animals.map((a) => ({
        id: a.farmOwnerId,
        name: a.farmOwnerName,
      }));

      const uniqueSuppliers = suppliersFromAnimals.filter(
        (v, i, a) =>
          a.findIndex((t) => t.id === v.id && t.name === v.name) === i
      );

      const uniqueOwners = animalOwners.filter(
        (v, i, a) =>
          a.findIndex((t) => t.id === v.id && t.name === v.name) === i
      );

      /** Update the state */
      this.setState({
        suppliers: uniqueSuppliers,
        farmOwners: uniqueOwners,
        originalListAnimals: animals,
        allAnimals: newAnimals,
        animals: [
          {
            key: "animalsTop",
            visible: !this.state.shouldStartAnimalHidden,
            values: animalsTop,
          },
          {
            key: "animalsMedium",
            visible: !this.state.shouldStartAnimalHidden,
            values: animalsMedium,
          },
          {
            key: "animalsBad",
            visible: !this.state.shouldStartAnimalHidden,
            values: animalsBad,
          },
          {
            key: "animalsNone",
            visible: !this.state.shouldStartAnimalHidden,
            values: animalsNone,
          },
        ],
        isLoading: false,
      });
    } catch (error) {
      console.error(error);
    }
  };

  reClassifyAnimals = async () => {
    const { originalListAnimals: animals } = this.state;
    this.setState({ isLoading: true });
    try {
      /** Classifying the animals */
      const newAnimals = await this.classifyAnimals(animals);

      /** Sort the animals by the handling number */
      newAnimals.sort(this.sortAnimalsByHandlingNumber);

      /** Separate the animals in differents weight rankings */
      const animalsTop = newAnimals.filter((a) => a.type === "top");
      const animalsMedium = newAnimals.filter((a) => a.type === "medium");
      const animalsBad = newAnimals.filter((a) => a.type === "bad");
      const animalsNone = newAnimals.filter((a) => a.type === "none");

      /** Update the state */
      this.setState({
        filters: [
          {
            key: null,
            comparator: "equal",
            min: null,
            max: null,
            uniqueValue: null,
            values: null,
          },
        ],
        allAnimals: newAnimals,
        animals: [
          {
            key: "animalsTop",
            visible: this.state.animals.find((a) => a.key === "animalsTop")
              .visible,
            values: animalsTop,
          },
          {
            key: "animalsMedium",
            visible: this.state.animals.find((a) => a.key === "animalsMedium")
              .visible,
            values: animalsMedium,
          },
          {
            key: "animalsBad",
            visible: this.state.animals.find((a) => a.key === "animalsBad")
              .visible,
            values: animalsBad,
          },
          {
            key: "animalsNone",
            visible: this.state.animals.find((a) => a.key === "animalsNone")
              .visible,
            values: animalsNone,
          },
        ],
        isLoading: false,
      });
    } catch (error) {}
  };

  classifyAnimals = (animals, average = null) => {
    return new Promise((resolve, reject) => {
      try {
        const {
          fundoPercentage,
          cabeceiraPercentage,
          lotAverageWeight: stateLotAverageWeight,
          lotAverageGMD: stateLotAverageGMD,
          rankingAnimalsType,
        } = this.state;

        let lotAverageWeight = stateLotAverageWeight;
        let lotAverageGMD = stateLotAverageGMD;

        if (rankingAnimalsType === "gmd" && lotAverageGMD === null) {
          lotAverageGMD = average;
        }

        if (
          rankingAnimalsType === "lastDailyWeight" &&
          lotAverageWeight === null
        ) {
          lotAverageWeight = average;
        }

        const newAnimals = Promise.all(
          animals.map(async (animal) => {
            /* Get lastDailyWeight  */
            /** Validate weight ranking */
            let lotReferenceFundo = 0;
            let lotReferenceCabeceira = 0;

            if (rankingAnimalsType === "gmd") {
              //GMD

              /** Calculate reference for Cabeceira and Fundo */
              lotReferenceCabeceira =
                lotAverageGMD * (cabeceiraPercentage / 100);
              lotReferenceFundo = lotAverageGMD * (fundoPercentage / 100);

              /** Verify where the animal will be classified - GMD*/
              if (
                animal.lastDailyWeightDate === animal.acquisitionDate ||
                animal.lastDailyWeightDate === animal.birthday
              ) {
                animal.type = "none";
              } else if (animal.lastDailyWeightGain <= lotReferenceFundo) {
                animal.type = "bad";
              } else if (
                animal.lastDailyWeightGain > lotReferenceFundo &&
                animal.lastDailyWeightGain < lotReferenceCabeceira
              ) {
                animal.type = "medium";
              } else if (animal.lastDailyWeightGain >= lotReferenceCabeceira) {
                animal.type = "top";
              }
            } else {
              //lastDailyWeight

              /** Calculate reference for Cabeceira and Fundo */
              lotReferenceCabeceira =
                lotAverageWeight * (cabeceiraPercentage / 100);
              lotReferenceFundo = lotAverageWeight * (fundoPercentage / 100);

              /** Verify where the animal will be classified - lastDailyWeight*/
              if (animal.lastDailyWeight === 0) {
                animal.type = "none";
              } else if (animal.lastDailyWeight <= lotReferenceFundo) {
                animal.type = "bad";
              } else if (
                animal.lastDailyWeight > lotReferenceFundo &&
                animal.lastDailyWeight < lotReferenceCabeceira
              ) {
                animal.type = "medium";
              } else if (animal.lastDailyWeight >= lotReferenceCabeceira) {
                animal.type = "top";
              }
            }

            return animal;
          })
        );

        this.setState({
          lotAverageWeight,
          lotAverageGMD,
        });
        resolve(newAnimals);
      } catch (error) {
        console.error(error);
        reject(null);
      }
    });
  };

  getAnimalWeights = async (groupId, farmId, animalData) => {
    let result = [];
    let datesAndWeights = [];
    this.setState({
      isLoadingChart: true,
    });
    try {
      const {
        data: { results: animalDailyWeightDashboard },
      } = await getAnimalDailyWeightDashboard({
        groupId,
        farmId,
        id: animalData.id,
      });
      animalDailyWeightDashboard.forEach((e) => {
        let obj = {
          x: moment(e.weightDate).toDate(),
          y: e.weight,
          toolTipContent: `<span style='"'color: {color};'"'>{name}:</span> ${moment(
            e.weightDate
          ).format("DD/MM/YY")}  | {y}`,
          markerType: "none",
        };
        if (!e.isReal) {
          obj = {
            ...obj,
            toolTipContent: `<span style='"'color: {color};'"'>{name} - Estimado:</span> ${moment(
              e.weightDate
            ).format("DD/MM/YY")}  | {y}`,
            markerType: "circle",
            lineDashType: "dash",
          };
        }
        datesAndWeights.push({
          date: moment(e.weightDate).toDate(),
          weight: e.weight,
        });

        result.push(obj);
      });
      this.setState(
        {
          dataChart: [
            ...this.state.dataChart,
            {
              id: animalData.id,
              type: "line",
              name: `Animal - ${animalData.handlingNumber}`,
              showInLegend: true,
              color: generateNewColor(),
              markerType: "none",
              markerSize: 5,
              yValueFormatString: "###0.## kg",
              dataPoints: result,
            },
          ],
          isLoadingChart: false,
          animalsWeightAndDates: [
            ...this.state.animalsWeightAndDates,
            {
              animalId: animalData.id,
              values: datesAndWeights,
            },
          ],
        },
        () => {
          this.calculateLotAverageCurve();
        }
      );
    } catch (error) {
      // animalData.isLoadingWeight = false;
      this.setState({
        isLoadingChart: false,
      });
    }
  };

  handleSelectAnimal = async (animalSelected) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;
    if (
      this.state.animalSelected.length > 0 &&
      this.state.animalSelected.find((a) => a.id === animalSelected.id)
    ) {
      const newDataChart = this.state.dataChart.filter(
        (dc, index) => dc.id !== animalSelected.id
      );
      this.setState(
        {
          animalSelected: this.state.animalSelected.filter(
            (a) => a.id !== animalSelected.id
          ),
          animalsWeightAndDates: this.state.animalsWeightAndDates.filter(
            (a) => a.animalId !== animalSelected.id
          ),
          dataChart: newDataChart,
        },
        () => {
          this.calculateLotAverageCurve();
        }
      );
    } else {
      this.setState({
        animalSelected: [...this.state.animalSelected, animalSelected],
      });
      this.getAnimalWeights(groupId, farmId, animalSelected);
    }
  };

  handleSelectRankingType = () => {
    this.reClassifyAnimals();
  };

  /*********************** Filters methods ***********************************/
  /** Filters states
   *    key: '' age | gender | breed
        comparator: 'equal' | 'greaterThan' | 'lessThan' | 'between'
        min: // in case comparator range
        max: // in case comparator range
        uniqueValue: // just for age(equal, greaterThan, lessThan) and gender
        values: []
   */
  handleFilterAnimals = async () => {
    const { filters, allAnimals: newAnimals } = this.state;

    try {
      /** Separate the animals in differents weight rankings */
      const animalsTop = newAnimals.filter((a) => a.type === "top");
      const animalsMedium = newAnimals.filter((a) => a.type === "medium");
      const animalsBad = newAnimals.filter((a) => a.type === "bad");
      const animalsNone = newAnimals.filter((a) => a.type === "none");

      let animalsTopValues = animalsTop;
      let animalsMediumValues = animalsMedium;
      let animalsBadValues = animalsBad;
      let animalsNoneValues = animalsNone;

      this.setState({ isLoading: true });

      if (filters.length > 0) {
        filters.forEach((filter) => {
          switch (filter.key) {
            case "age":
              switch (filter.comparator) {
                case "equal":
                  animalsTopValues = animalsTopValues.filter(
                    (a) =>
                      moment().diff(
                        moment(a.birthday, "YYYY-MM-DD"),
                        "days"
                      ) === Number(filter.uniqueValue)
                  );
                  animalsMediumValues = animalsMediumValues.filter(
                    (a) =>
                      moment().diff(
                        moment(a.birthday, "YYYY-MM-DD"),
                        "days"
                      ) === Number(filter.uniqueValue)
                  );
                  animalsBadValues = animalsBadValues.filter(
                    (a) =>
                      moment().diff(
                        moment(a.birthday, "YYYY-MM-DD"),
                        "days"
                      ) === Number(filter.uniqueValue)
                  );
                  animalsNoneValues = animalsNoneValues.filter(
                    (a) =>
                      moment().diff(
                        moment(a.birthday, "YYYY-MM-DD"),
                        "days"
                      ) === Number(filter.uniqueValue)
                  );
                  break;
                case "greaterThan":
                  animalsTopValues = animalsTopValues.filter((a) =>
                    moment().diff(moment(a.birthday, "YYYY-MM-DD"), "days") >=
                    Number(filter.uniqueValue)
                      ? true
                      : false
                  );
                  animalsMediumValues = animalsMediumValues.filter((a) =>
                    moment().diff(moment(a.birthday, "YYYY-MM-DD"), "days") >=
                    Number(filter.uniqueValue)
                      ? true
                      : false
                  );
                  animalsBadValues = animalsBadValues.filter((a) =>
                    moment().diff(moment(a.birthday, "YYYY-MM-DD"), "days") >=
                    Number(filter.uniqueValue)
                      ? true
                      : false
                  );
                  animalsNoneValues = animalsNoneValues.filter((a) =>
                    moment().diff(moment(a.birthday, "YYYY-MM-DD"), "days") >=
                    Number(filter.uniqueValue)
                      ? true
                      : false
                  );
                  break;
                case "lessThan":
                  animalsTopValues = animalsTopValues.filter((a) =>
                    moment().diff(moment(a.birthday, "YYYY-MM-DD"), "days") <=
                    Number(filter.uniqueValue)
                      ? true
                      : false
                  );
                  animalsMediumValues = animalsMediumValues.filter((a) =>
                    moment().diff(moment(a.birthday, "YYYY-MM-DD"), "days") <=
                    Number(filter.uniqueValue)
                      ? true
                      : false
                  );
                  animalsBadValues = animalsBadValues.filter((a) =>
                    moment().diff(moment(a.birthday, "YYYY-MM-DD"), "days") <=
                    Number(filter.uniqueValue)
                      ? true
                      : false
                  );
                  animalsNoneValues = animalsNoneValues.filter((a) =>
                    moment().diff(moment(a.birthday, "YYYY-MM-DD"), "days") <=
                    Number(filter.uniqueValue)
                      ? true
                      : false
                  );
                  break;
                case "between":
                  animalsTopValues = animalsTopValues.filter((a) =>
                    moment().diff(moment(a.birthday, "YYYY-MM-DD"), "days") <=
                      Number(filter.max) &&
                    moment().diff(moment(a.birthday, "YYYY-MM-DD"), "days") >=
                      Number(filter.min)
                      ? true
                      : false
                  );
                  animalsMediumValues = animalsMediumValues.filter((a) =>
                    moment().diff(moment(a.birthday, "YYYY-MM-DD"), "days") <=
                      Number(filter.max) &&
                    moment().diff(moment(a.birthday, "YYYY-MM-DD"), "days") >=
                      Number(filter.min)
                      ? true
                      : false
                  );
                  animalsBadValues = animalsBadValues.filter((a) =>
                    moment().diff(moment(a.birthday, "YYYY-MM-DD"), "days") <=
                      Number(filter.max) &&
                    moment().diff(moment(a.birthday, "YYYY-MM-DD"), "days") >=
                      Number(filter.min)
                      ? true
                      : false
                  );
                  animalsNoneValues = animalsNoneValues.filter((a) =>
                    moment().diff(moment(a.birthday, "YYYY-MM-DD"), "days") <=
                      Number(filter.max) &&
                    moment().diff(moment(a.birthday, "YYYY-MM-DD"), "days") >=
                      Number(filter.min)
                      ? true
                      : false
                  );
                  break;
                default:
                  animalsTopValues = animalsTopValues.filter(
                    (a) =>
                      moment().diff(
                        moment(a.birthday, "YYYY-MM-DD"),
                        "days"
                      ) === Number(filter.uniqueValue)
                  );
                  animalsMediumValues = animalsMediumValues.filter(
                    (a) =>
                      moment().diff(
                        moment(a.birthday, "YYYY-MM-DD"),
                        "days"
                      ) === Number(filter.uniqueValue)
                  );
                  animalsBadValues = animalsBadValues.filter(
                    (a) =>
                      moment().diff(
                        moment(a.birthday, "YYYY-MM-DD"),
                        "days"
                      ) === Number(filter.uniqueValue)
                  );
                  animalsNoneValues = animalsNoneValues.filter(
                    (a) =>
                      moment().diff(
                        moment(a.birthday, "YYYY-MM-DD"),
                        "days"
                      ) === Number(filter.uniqueValue)
                  );
                  break;
              }
              break;
            case "gender":
              animalsTopValues = animalsTopValues.filter((a) =>
                filter.uniqueValue === a.gender ? true : false
              );
              animalsMediumValues = animalsMediumValues.filter((a) =>
                filter.uniqueValue === a.gender ? true : false
              );
              animalsBadValues = animalsBadValues.filter((a) =>
                filter.uniqueValue === a.gender ? true : false
              );
              animalsNoneValues = animalsNoneValues.filter((a) =>
                filter.uniqueValue === a.gender ? true : false
              );
              break;
            case "breed":
              animalsTopValues = animalsTopValues.filter((a) =>
                filter.values.includes(a.breedId) ? true : false
              );
              animalsMediumValues = animalsMediumValues.filter((a) =>
                filter.values.includes(a.breedId) ? true : false
              );
              animalsBadValues = animalsBadValues.filter((a) =>
                filter.values.includes(a.breedId) ? true : false
              );
              animalsNoneValues = animalsNoneValues.filter((a) =>
                filter.values.includes(a.breedId) ? true : false
              );
              break;
            case "supplier":
              animalsTopValues = animalsTopValues.filter((a) =>
                filter.values.includes(a.supplierId) ? true : false
              );
              animalsMediumValues = animalsMediumValues.filter((a) =>
                filter.values.includes(a.supplierId) ? true : false
              );
              animalsBadValues = animalsBadValues.filter((a) =>
                filter.values.includes(a.supplierId) ? true : false
              );
              animalsNoneValues = animalsNoneValues.filter((a) =>
                filter.values.includes(a.supplierId) ? true : false
              );
              break;
            case "farmOwner":
              animalsTopValues = animalsTopValues.filter((a) =>
                filter.values.includes(a.farmOwnerId) ? true : false
              );
              animalsMediumValues = animalsMediumValues.filter((a) =>
                filter.values.includes(a.farmOwnerId) ? true : false
              );
              animalsBadValues = animalsBadValues.filter((a) =>
                filter.values.includes(a.farmOwnerId) ? true : false
              );
              animalsNoneValues = animalsNoneValues.filter((a) =>
                filter.values.includes(a.farmOwnerId) ? true : false
              );
              break;
            default:
              break;
          }
        });

        this.setState({
          animals: [
            {
              key: "animalsTop",
              visible: true,
              values: animalsTopValues,
            },
            {
              key: "animalsMedium",
              visible: true,
              values: animalsMediumValues,
            },
            {
              key: "animalsBad",
              visible: true,
              values: animalsBadValues,
            },
            {
              key: "animalsNone",
              visible: true,
              values: animalsNoneValues,
            },
          ],
          showFilters: false,
          animalSelected: [],
          dataChart: this.state.dataChart.filter((dc, index) =>
            index === 0 ? true : false
          ),
        });
      }
      this.setState({ isLoading: false });
    } catch (error) {}
  };

  handleCompleteFilter = (index, field, value) => {
    const { filters } = this.state;

    const updatedFilters = filters.map((f, i) => {
      if (i === index) {
        switch (field) {
          case "key":
            f.key = value;
            break;
          case "comparator":
            f.comparator = value;
            break;
          case "min":
            f.min = value;
            break;
          case "max":
            f.max = value;
            break;
          case "uniqueValue":
            f.uniqueValue = value;
            break;
          case "values":
            f.values = value;
            break;
          default:
            break;
        }
      }
      return f;
    });

    this.setState({
      filters: updatedFilters,
    });
  };

  handleAddNewFilter = () => {
    this.setState({
      filters: [
        ...this.state.filters,
        {
          key: null,
          comparator: "equal",
          min: null,
          max: null,
          uniqueValue: null,
          values: null,
        },
      ],
    });
  };

  handleCleanFilters = async (index = null) => {
    if (index != null) {
      this.setState({
        filters: this.state.filters.filter((f, i) => i !== index),
      });
    } else {
      const { allAnimals: newAnimals } = this.state;
      /** Separate the animals in differents weight rankings */
      const animalsTop = newAnimals.filter((a) => a.type === "top");
      const animalsMedium = newAnimals.filter((a) => a.type === "medium");
      const animalsBad = newAnimals.filter((a) => a.type === "bad");
      const animalsNone = newAnimals.filter((a) => a.type === "none");
      this.setState({
        filters: [
          {
            key: null,
            comparator: "equal",
            min: null,
            max: null,
            uniqueValue: null,
            values: null,
          },
        ],
        animalSelected: [],
        dataChart: this.state.dataChart.filter((dc, index) =>
          index === 0 ? true : false
        ),
        animals: [
          {
            key: "animalsTop",
            visible: true,
            values: animalsTop,
          },
          {
            key: "animalsMedium",
            visible: true,
            values: animalsMedium,
          },
          {
            key: "animalsBad",
            visible: true,
            values: animalsBad,
          },
          {
            key: "animalsNone",
            visible: true,
            values: animalsNone,
          },
        ],
        isLoading: false,
      });
    }
  };

  handleShowOrHideCategory = (category) => {
    const { animals } = this.state;
    const index = animals.findIndex((a) => a.key === category);

    if (index >= 0) {
      let newAnimals = [...animals];
      let animalCategory = newAnimals[index];
      animalCategory.visible = !animalCategory.visible;
      newAnimals[index] = animalCategory;
      this.setState({ animals: newAnimals });
    }
  };

  handleAnimalsOrderChange = async (value) => {
    const { animals, allAnimals } = this.state;

    this.setState({
      isLoading: true,
    });

    let newAnimals = null;

    let animalsTop = [];
    let animalsMedium = [];
    let animalsBad = [];
    let animalsNone = [];

    if (value === "lastDailyWeight") {
      newAnimals = animals.map((a) => a);

      /** Separate the animals in differents weight rankings */
      animalsTop = newAnimals
        .find((a) => a.key === "animalsTop")
        .values.sort(this.sortAnimalsByLastWeight);
      animalsMedium = newAnimals
        .find((a) => a.key === "animalsMedium")
        .values.sort(this.sortAnimalsByLastWeight);
      animalsBad = newAnimals
        .find((a) => a.key === "animalsBad")
        .values.sort(this.sortAnimalsByLastWeight);
      animalsNone = newAnimals
        .find((a) => a.key === "animalsNone")
        .values.sort(this.sortAnimalsByLastWeight);
    } else if (value === "lastDailyWeightGain") {
      newAnimals = animals.map((a) => a);

      /** Separate the animals in differents weight rankings */
      animalsTop = newAnimals
        .find((a) => a.key === "animalsTop")
        .values.sort(this.sortAnimalsByLastWeightGain);
      animalsMedium = newAnimals
        .find((a) => a.key === "animalsMedium")
        .values.sort(this.sortAnimalsByLastWeightGain);
      animalsBad = newAnimals
        .find((a) => a.key === "animalsBad")
        .values.sort(this.sortAnimalsByLastWeightGain);
      animalsNone = newAnimals
        .find((a) => a.key === "animalsNone")
        .values.sort(this.sortAnimalsByLastWeightGain);
    } else if (value === "handlingNumber") {
      newAnimals = allAnimals.sort(this.sortAnimalsByHandlingNumber);

      /** Separate the animals in differents weight rankings */
      animalsTop = newAnimals.filter((a) => a.type === "top");
      animalsMedium = newAnimals.filter((a) => a.type === "medium");
      animalsBad = newAnimals.filter((a) => a.type === "bad");
      animalsNone = newAnimals.filter((a) => a.type === "none");
    } else if (value === "ageInDays") {
      newAnimals = allAnimals.sort(this.sortAnimalsByAgeInDays);

      /** Separate the animals in differents weight rankings */
      animalsTop = newAnimals.filter((a) => a.type === "top");
      animalsMedium = newAnimals.filter((a) => a.type === "medium");
      animalsBad = newAnimals.filter((a) => a.type === "bad");
      animalsNone = newAnimals.filter((a) => a.type === "none");
    }

    newAnimals = [
      {
        key: "animalsTop",
        visible: animals.find((a) => a.key === "animalsTop").visible,
        values: animalsTop,
      },
      {
        key: "animalsMedium",
        visible: animals.find((a) => a.key === "animalsMedium").visible,
        values: animalsMedium,
      },
      {
        key: "animalsBad",
        visible: animals.find((a) => a.key === "animalsBad").visible,
        values: animalsBad,
      },
      {
        key: "animalsNone",
        visible: animals.find((a) => a.key === "animalsNone").visible,
        values: animalsNone,
      },
    ];

    /** Update the state */
    this.setState({
      animals: newAnimals,
      animalsSort: value,
      isLoading: false,
    });
  };

  /******************** Drawer methods **********************/

  handleShowDrawer = () => {
    this.setState({ lotDrawerCurveParameterVisible: true });
  };
  handleCancelDrawer = () => {
    this.setState({ lotDrawerCurveParameterVisible: false });
  };
  handleSaveDrawer = async (body) => {
    const {
      lotId: id,
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      refreshLotInfo,
    } = this.props;

    const { oldAnimalBirthyDay: startDate, lotInfo } = this.state;

    this.setState({
      isLoadingChart: true,
      isLoadingRequestSetCurve: true,
    });

    try {
      const {
        data: { results: curve },
      } = await setLotEstimatedCurve({ groupId, farmId, id, body });
      refreshLotInfo(curve.entity);
      this.calculateLotDietStrategyCurve(
        groupId,
        farmId,
        id,
        startDate,
        lotInfo
      );
      notification.success({
        message: "Os parâmetros da curva de crescimento foram atualizados",
      });
      this.setState({
        lotDrawerCurveParameterVisible: false,
        lotCurveInfo: curve,
        isLoadingChart: false,
        isLoadingRequestSetCurve: false,
      });
    } catch (error) {
      this.setState({
        isLoadingChart: false,
        isLoadingRequestSetCurve: false,
      });
      notification.error({
        message:
          "Não foi possível atualizar os parâmetros da curva de crescimento.",
      });
    }
  };

  /********************* Lot Weight Ranking Modal methods  *******************/
  handleShowOrHideModalLotWeightRanking = () => {
    const {
      isModalEditLotWeightRankingVisible,
      cabeceiraPercentage,
      fundoPercentage,
    } = this.state;
    this.setState({
      isModalEditLotWeightRankingVisible: !isModalEditLotWeightRankingVisible,
      editCabeceiraPercentage: cabeceiraPercentage,
      editFundoPercentage: fundoPercentage,
    });
  };
  handleCancelModalLotWeightRanking = () => {
    this.handleShowOrHideModalLotWeightRanking();
  };
  handleSubmitModalLotWeightRanking = async () => {
    if (this.state.editFundoPercentage >= this.state.editCabeceiraPercentage) {
      notification.error({
        message:
          "A porcentagem de fundo, deve ser menor que porcentagem de cabeceira.",
      });
      return;
    }
    this.setState({
      isLoadingRequestUpdateLotWeightRanking: true,
    });
    try {
      const {
        app: {
          groupSelected: { id: groupId },
          farmSelected: { id: farmId },
        },
        lotId,
      } = this.props;
      const body = {
        highestWeightPercentage: this.state.editCabeceiraPercentage,
        worstWeightPercentage: this.state.editFundoPercentage,
      };
      await updateLotWeightRanking({
        groupId,
        farmId,
        lotId,
        body,
      });
      notification.success({
        message: "Valores de referência de peso atualizadas.",
      });
      this.setState(
        {
          cabeceiraPercentage: this.state.editCabeceiraPercentage,
          fundoPercentage: this.state.editFundoPercentage,
          isModalEditLotWeightRankingVisible: false,
        },
        () => this.reClassifyAnimals()
      );
    } catch (error) {
      notification.error({
        message: "Não foi possível atualizar as referências de peso",
      });
    } finally {
      this.setState({
        isLoadingRequestUpdateLotWeightRanking: false,
      });
    }
  };

  isLoadingWeight = () => {
    const { animalSelected } = this.state;
    return animalSelected?.filter((a) => a.isLoadingWeight).length > 0;
  };

  animalCost = (animal) => {
    const { useDietStrategyCost } = this.state;
    const dietCost = useDietStrategyCost
      ? animal.sumDietCost
      : animal.sumDietCostBySupplementSupply;
    let animalCost =
      dietCost + animal.sumFixedVariableCost + animal.purchaseCost;
    if (animalCost === null || animalCost === undefined) {
      animalCost = 0;
    }

    return numberMask(getTwoDecimalDigits(animalCost), true);
  };

  animalLastDayCost = (animal) => {
    const { useDietStrategyCost } = this.state;
    const dietCost = useDietStrategyCost
      ? animal.lastDietCost
      : animal.lastDietCostBySupplementSupply;
    let animalLastCost = dietCost + animal.lastFixedVariableCost;
    if (animalLastCost === null || animalLastCost === undefined) {
      animalLastCost = 0;
    }

    return numberMask(getTwoDecimalDigits(animalLastCost), true);
  };

  render() {
    const {
      allAnimals,
      animals,
      isLoading,
      isLoadingChart,
      isLoadingRequestSetCurve,
      animalSelected,
      dataChart,
      showAnimalsLabel,
      showFilters,
      filters,
      breeds,
      suppliers,
      lotCurveInfo,
      lotDrawerCurveParameterVisible,
      fundoPercentage,
      cabeceiraPercentage,
      isModalEditLotWeightRankingVisible,
      isLoadingRequestUpdateLotWeightRanking,
      editFundoPercentage,
      editCabeceiraPercentage,
      farmOwners,
    } = this.state;
    const {
      app: { translation },
    } = this.props;
    const { Group: RadioGroup } = Radio;
    const toggleDataSeries = (e) => {
      if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
        e.dataSeries.visible = false;
      } else {
        e.dataSeries.visible = true;
      }
      e.chart.render();
    };
    const options = {
      animationEnabled: true,
      zoomEnabled: true,
      axisX: {
        valueFormatString: "MM/YY",
        interval: 2,
        intervalType: "month",
        crosshair: {
          enabled: true,
          snapToDataPoint: true,
        },
        stripLines: [
          {
            value: moment(moment(), "DD/MM/YY").toDate(),
            label: "Hoje",
            labelPlacement: "inside",
            color: "#4b4b4b",
            labelFontColor: "#4b4b4b",
            labelBackgroundColor: "transparent",
          },
        ],
      },
      toolTip: {
        fontFamily: "Asap",
        shared: true,
      },
      axisY: {
        includeZero: true,
        valueFormatString: "###0.##",
        suffix: "kg",
        gridDashType: "dot",
      },
      legend: {
        cursor: "pointer",
        itemclick: toggleDataSeries,
      },
      data: dataChart,
    };
    //debugger;
    const HeatMap =
      animals != null &&
      animals.map(
        (animal) =>
          animal.visible && (
            <BoxRow key={animal.key}>
              {animal.values.map((a) => (
                <Tooltip
                  key={a.id}
                  placement="topLeft"
                  title={
                    <div>
                      <span>
                        Status:{" "}
                        {a.farmStatus === "Active" ? (
                          <TagStatus
                            background="#C5F1CA"
                            borderColor="#106518"
                            color="#106518"
                          >
                            {translation.status.active}
                          </TagStatus>
                        ) : a.farmStatus === "Inactive" ||
                          a.farmStatus === "FarmExclude" ? (
                          <TagStatus
                            background="#FBC7C7"
                            borderColor="#D44C4C"
                            color="#D44C4C"
                          >
                            {translation.status.inactive}
                          </TagStatus>
                        ) : a.farmStatus === "Sold" ? (
                          <TagStatus
                            background="#FEFFF6"
                            borderColor="#A9C133"
                            color="#A9C133"
                          >
                            Vendido
                          </TagStatus>
                        ) : a.farmStatus === "Dead" ? (
                          <TagStatus
                            background="#4b4b4b"
                            borderColor="#000"
                            color="#e3e3e3"
                          >
                            Morto
                          </TagStatus>
                        ) : (
                          <TagStatus
                            background="#FBC7C7"
                            borderColor="#D44C4C"
                            color="#D44C4C"
                          >
                            {translation.status.inactive}
                          </TagStatus>
                        )}{" "}
                      </span>
                      <br />
                      <span>Identificação: {a.handlingNumber}</span>
                      <br />
                      <span>Idade: {a.ageInMonths} meses</span>
                      <span>
                        {"    "}Sexo: {a.gender}
                      </span>
                      <br />
                      <span>Permanência: {a.timeInFarm} dias</span>
                      <br />
                      <span style={{ fontWeight: "bold" }}>Última pesagem</span>
                      <br />
                      <span>
                        {numberMask(a.lastDailyWeight || 0, false)}kg -&nbsp;
                        {a?.lastDailyWeightDate
                          ? `${moment(a?.lastDailyWeightDate).format(
                              "DD/MM/YYYY"
                            )} - `
                          : ""}
                        GMD:{" "}
                        {numberMask(
                          getTwoDecimalDigits(
                            a.originalLastDailyWeightGain || 0
                          ),
                          false
                        )}
                        kg
                      </span>
                      <br />
                      <span>Custo Atual: {this.animalCost(a)}</span>
                      <br />
                      <span>Diária Atual: {this.animalLastDayCost(a)}</span>
                      <br />
                    </div>
                  }
                >
                  <Box
                    onClick={() => this.handleSelectAnimal(a)}
                    className={
                      animalSelected.length > 0 &&
                      animalSelected.findIndex((a2) => a2.id === a.id) < 0
                        ? `notSelected ${
                            animal.key === "animalsMedium"
                              ? "medium"
                              : animal.key === "animalsBad"
                              ? "bad"
                              : animal.key === "animalsNone"
                              ? "none"
                              : ""
                          }`
                        : animalSelected.length > 0 &&
                          animalSelected.find((a2) => a2.id === a.id)
                        ? `selected ${
                            animal.key === "animalsMedium"
                              ? "medium"
                              : animal.key === "animalsBad"
                              ? "bad"
                              : animal.key === "animalsNone"
                              ? "none"
                              : ""
                          }`
                        : animalSelected.length === 0 &&
                          `${
                            animal.key === "animalsMedium"
                              ? "medium"
                              : animal.key === "animalsBad"
                              ? "bad"
                              : animal.key === "animalsNone"
                              ? "none"
                              : ""
                          }`
                    }
                  >
                    {showAnimalsLabel === "handlingNumber"
                      ? a.handlingNumber
                      : numberMask(a.lastDailyWeight || 0, false)}
                    <ButtonPin
                      className={
                        animalSelected != null &&
                        animalSelected.find((a2) => a2.id === a.id) &&
                        "visible"
                      }
                    >
                      <Icon type="pushpin" />
                    </ButtonPin>
                  </Box>
                </Tooltip>
              ))}
            </BoxRow>
          )
      );

    const FilterRow = (
      <RowFilter type="flex">
        {filters.map((filter, index) => (
          <Row type="flex" key={index} style={{ width: "100%", marginTop: 10 }}>
            <ColFilter xs={22} sm={22} md={22} lg={22} xl={22} align="left">
              <strong>
                {translation.filters.filterBy}
                <Select
                  style={{ width: 120 }}
                  value={filters[index].key}
                  onChange={(value) =>
                    this.handleCompleteFilter(index, "key", value)
                  }
                >
                  <Select.Option value="age">
                    {translation.filters.filterByOptions.age}
                  </Select.Option>
                  <Select.Option value="gender">
                    {translation.filters.filterByOptions.gender}
                  </Select.Option>
                  <Select.Option value="breed">
                    {translation.filters.filterByOptions.breed}
                  </Select.Option>
                  <Select.Option value="supplier">
                    {translation.filters.filterByOptions.supplier}
                  </Select.Option>
                  <Select.Option value="farmOwner">
                    {translation.filters.filterByOptions.farmOwner}
                  </Select.Option>
                </Select>
              </strong>
              {filters[index].key === "age" && (
                <strong>
                  {translation.filters.compareBy}
                  <Select
                    style={{ width: 180 }}
                    defaultValue={"equal"}
                    value={filters[index].comparator}
                    onChange={(value) =>
                      this.handleCompleteFilter(index, "comparator", value)
                    }
                  >
                    <Select.Option value="equal">
                      {translation.filters.equal}
                    </Select.Option>
                    <Select.Option value="greaterThan">
                      {translation.filters.greaterThan}
                    </Select.Option>
                    <Select.Option value="lessThan">
                      {translation.filters.lessThan}
                    </Select.Option>
                    <Select.Option value="between">
                      {translation.filters.between}
                    </Select.Option>
                  </Select>
                </strong>
              )}
              {filters[index].key != null && (
                <Fragment>
                  {filters[index].key === "gender" ? (
                    <strong>
                      Valor:
                      <RadioGroup
                        value={filters[index].uniqueValue}
                        defaultValue={false}
                        onChange={(e) =>
                          this.handleCompleteFilter(
                            index,
                            "uniqueValue",
                            e.target.value
                          )
                        }
                      >
                        <RadioButtonCustom value="M">
                          {translation.filters.male}
                        </RadioButtonCustom>
                        <RadioButtonCustom value="F">
                          {translation.filters.female}
                        </RadioButtonCustom>
                      </RadioGroup>
                    </strong>
                  ) : filters[index].key === "breed" ? (
                    <strong>
                      Valor:
                      <Select
                        style={{ width: 300 }}
                        mode="multiple"
                        value={
                          breeds != null && filters[index].values != null
                            ? filters[index].values.length > 0
                              ? filters[index].values
                              : []
                            : []
                        }
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) =>
                          this.handleCompleteFilter(index, "values", value)
                        }
                      >
                        {breeds != null &&
                          Object.entries(breeds).length > 0 &&
                          breeds.map((b) => (
                            <Select.Option key={b.id} value={b.id}>
                              {b.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </strong>
                  ) : filters[index].key === "age" &&
                    filters[index].comparator === "between" ? (
                    <strong>
                      Valor:
                      <Input
                        style={{ width: 100, textAlign: "center" }}
                        placeholder={translation.filters.min}
                        value={filters[index].min}
                        onChange={(e) =>
                          this.handleCompleteFilter(
                            index,
                            "min",
                            e.target.value
                          )
                        }
                      />
                      <span>e</span>
                      <Input
                        style={{
                          width: 100,
                          textAlign: "center",
                        }}
                        placeholder={translation.filters.max}
                        value={filters[index].max}
                        onChange={(e) =>
                          this.handleCompleteFilter(
                            index,
                            "max",
                            e.target.value
                          )
                        }
                      />
                    </strong>
                  ) : filters[index].key === "age" &&
                    filters[index].comparator !== "between" ? (
                    <strong>
                      Valor:
                      <Input
                        type="number"
                        style={{ width: 180 }}
                        suffix=" Dias"
                        value={filters[index].uniqueValue}
                        defaultValue={false}
                        onChange={(e) =>
                          this.handleCompleteFilter(
                            index,
                            "uniqueValue",
                            e.target.value
                          )
                        }
                      />
                    </strong>
                  ) : filters[index].key === "supplier" ? (
                    <strong>
                      Valor:
                      <Select
                        style={{ width: 300 }}
                        mode="multiple"
                        value={
                          suppliers != null && filters[index].values != null
                            ? filters[index].values.length > 0
                              ? filters[index].values
                              : []
                            : []
                        }
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) =>
                          this.handleCompleteFilter(index, "values", value)
                        }
                      >
                        {suppliers != null &&
                          Object.entries(suppliers).length > 0 &&
                          suppliers.map((b) => (
                            <Select.Option key={b.id} value={b.id}>
                              {b.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </strong>
                  ) : filters[index].key === "farmOwner" ? (
                    <strong>
                      Valor:
                      <Select
                        style={{ width: 300 }}
                        mode="multiple"
                        value={
                          farmOwners != null && filters[index].values != null
                            ? filters[index].values.length > 0
                              ? filters[index].values
                              : []
                            : []
                        }
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) =>
                          this.handleCompleteFilter(index, "values", value)
                        }
                      >
                        {farmOwners != null &&
                          Object.entries(farmOwners).length > 0 &&
                          farmOwners.map((b) => (
                            <Select.Option key={b.id} value={b.id}>
                              {b.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </strong>
                  ) : null}
                </Fragment>
              )}
            </ColFilter>
            <ColFilter xs={2} sm={2} md={2} lg={2} xl={2} align="right">
              {index !== 0 && (
                <Button
                  shape="circle"
                  onClick={() => this.handleCleanFilters(index)}
                  icon="delete"
                />
              )}
              {index + 1 === filters.length && (
                <Button
                  disabled={
                    filters[index].uniqueValue != null ||
                    filters[index].values != null ||
                    (filters[index].min != null && filters[index].max != null)
                      ? false
                      : true
                  }
                  style={{ marginLeft: 10 }}
                  shape="circle"
                  onClick={this.handleAddNewFilter}
                  icon="plus"
                />
              )}
            </ColFilter>
          </Row>
        ))}
        <Row type="flex" style={{ width: "100%", marginTop: 20 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} align="center">
            <Button
              type="default"
              style={{
                color: "#684e94",
                fontWeight: "bold",
                fontFamily: "Asap",
              }}
              onClick={this.handleFilterAnimals}
            >
              {translation.filters.applyFilter}
            </Button>
          </Col>
        </Row>
      </RowFilter>
    );
    // Return
    return (
      <Container>
        <Row type="flex">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <CustomDivider dashed>
              {translation.lot.production.details.titleAnimals}
            </CustomDivider>
          </Col>
        </Row>
        {/* Animals board */}
        <CustomSpin spinning={isLoading}>
          {/* Filters */}
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ width: "100%" }}
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24} align="center">
              <InfoTooltip
                title={`Último Peso: Os animais serão ranqueados de acordo com a última pesagem real registrada em relação ao peso médio dos animais do lote de produção.
                                GMD: Os animais serão ranqueados de acordo com o GMD (ganho entre a última e penúltima pesagem). Para isto, somente serão considerados os animais que foram pesados na última data de pesagem do Lote. Caso algum animal, não tenha sido pesado, com os demais do Lote, este não será considerado para classificação, ficando como "SEM PESAGEM".`}
              />
              <strong style={{ marginLeft: 5 }}>
                {translation.lot.production.details.rankBy}
                <SelectLabelAnimalsBox
                  onChange={(value) =>
                    this.setState(
                      { rankingAnimalsType: value },
                      this.handleSelectRankingType
                    )
                  }
                  value={this.state.rankingAnimalsType}
                >
                  <SelectLabelAnimalsBox.Option value="lastDailyWeight">
                    Última pesagem
                  </SelectLabelAnimalsBox.Option>
                  <SelectLabelAnimalsBox.Option value="gmd">
                    GMD
                  </SelectLabelAnimalsBox.Option>
                </SelectLabelAnimalsBox>
              </strong>
              <strong style={{ marginLeft: 5 }}>
                {translation.lot.production.details.viewBy}
                <SelectLabelAnimalsBox
                  onChange={(value) =>
                    this.setState({ showAnimalsLabel: value })
                  }
                  value={this.state.showAnimalsLabel}
                >
                  <SelectLabelAnimalsBox.Option value="handlingNumber">
                    {translation.animal.details.handlingNumber}
                  </SelectLabelAnimalsBox.Option>
                  <SelectLabelAnimalsBox.Option value="lastDailyWeight">
                    Última pesagem
                  </SelectLabelAnimalsBox.Option>
                </SelectLabelAnimalsBox>
              </strong>
              <strong style={{ marginLeft: 5 }}>
                Ordernar por:
                <SelectLabelAnimalsBox
                  onChange={(value) => this.handleAnimalsOrderChange(value)}
                  value={this.state.animalsSort}
                >
                  <SelectLabelAnimalsBox.Option value="handlingNumber">
                    {translation.animal.details.handlingNumber}
                  </SelectLabelAnimalsBox.Option>
                  <SelectLabelAnimalsBox.Option value="lastDailyWeight">
                    Última pesagem
                  </SelectLabelAnimalsBox.Option>
                  <SelectLabelAnimalsBox.Option value="lastDailyWeightGain">
                    GMD
                  </SelectLabelAnimalsBox.Option>
                  <SelectLabelAnimalsBox.Option value="ageInDays">
                    Idade
                  </SelectLabelAnimalsBox.Option>
                </SelectLabelAnimalsBox>
              </strong>
              <ButtonFilter
                shape="circle"
                className={
                  filters.length > 0 &&
                  (filters[0].values != null ||
                    filters[0].uniqueValue != null ||
                    (filters[0].min != null && filters[0].max != null))
                    ? "filled"
                    : ""
                }
                icon="filter"
                onClick={() => this.setState({ showFilters: !showFilters })}
              />
              {filters.length > 0 &&
                (filters[0].values != null ||
                  filters[0].uniqueValue != null ||
                  (filters[0].min != null && filters[0].max != null)) && (
                  <Button
                    type="link"
                    icon="delete"
                    style={{ color: "#d44c4c" }}
                    onClick={() => this.handleCleanFilters(null)}
                  >
                    {translation.filters.cleanFilter}
                  </Button>
                )}
            </Col>
          </Row>
          {showFilters && FilterRow}
          {/* Filters - End */}
          {/* Weight Ranking */}
          <Row type="flex">
            <WeightRankingContainer>
              <Row type="flex" justify="start">
                <Col span={24} align="center">
                  <Row type="flex" align="middle" justify="center">
                    <div className="titleGroup">
                      <strong>Valores de Referência </strong>
                      <InfoTooltip title="Os valores de referência são utilizados para classificar o peso do animal de acordo com a porcentagem de diferença em relação à estratégia de dieta (baseline) do lote, ou da curva de peso estimada." />
                      <button
                        className="editButton"
                        onClick={this.handleShowOrHideModalLotWeightRanking}
                      >
                        <PencilIcon />
                      </button>
                    </div>
                  </Row>
                  <Row>
                    <div className="valuesContainer">
                      <span className="values">
                        <div className="circle red" />
                        Fundo:
                        <strong>{`< ${fundoPercentage || 0}%`}</strong>
                      </span>
                      <span className="values">
                        <div className="circle green" />
                        Meio:
                        <strong>{`> ${fundoPercentage || 0}% | < ${
                          cabeceiraPercentage || 0
                        }%`}</strong>
                      </span>
                      <span className="values">
                        <div className="circle purple" />
                        Cabeceira:
                        <strong>{`> ${cabeceiraPercentage || 0}%`}</strong>
                      </span>
                    </div>
                  </Row>
                </Col>
              </Row>
            </WeightRankingContainer>
          </Row>
          {/* Amount animals by Status */}
          <Row type="flex">
            <Col span={24} align="center">
              <strong>Quantidade de animais</strong>
            </Col>
          </Row>
          <Row type="flex">
            <Col span={24} align="center">
              <ul className="listAnimalsStatus">
                <li>
                  <span>
                    Ativos:
                    <strong>
                      {allAnimals.filter((a) => a.farmStatus === "Active")
                        .length || 0}
                    </strong>
                  </span>
                </li>
                <li>
                  <span>
                    Vendidos:
                    <strong>
                      {allAnimals.filter((a) => a.farmStatus === "Sold")
                        .length || 0}
                    </strong>
                  </span>
                </li>
                <li>
                  <span>
                    Mortos:
                    <strong>
                      {allAnimals.filter((a) => a.farmStatus === "Dead")
                        .length || 0}
                    </strong>
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
          <CardAnimalsChart>
            {animals != null &&
            animals[0].values.length === 0 &&
            animals[1].values.length === 0 &&
            animals[2].values.length === 0 &&
            animals[3].values.length === 0 ? (
              <Row
                type="flex"
                justify="center"
                align="middle"
                style={{ width: "100%" }}
              >
                <Empty />
              </Row>
            ) : (
              <Fragment>
                <Row type="flex" gutter={8} style={{ width: "100%" }}>
                  <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                    {HeatMap}
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={4}
                    xl={4}
                    style={{
                      borderLeft: "1px solid #F5F5F5",
                      paddingLeft: 17,
                    }}
                  >
                    <Row type="flex" align="middle" justify="center">
                      <BoxText color="#FE8D2A" iconComponent={<InfoIcon />}>
                        <span>
                          Clique na imagem da <EyeIcon isActive={false} />
                          classificação da Legenda para não exibir animais de um
                          determinado ranking.
                        </span>
                      </BoxText>
                    </Row>
                    <Row type="flex" style={{ marginTop: 10, marginBottom: 5 }}>
                      <Col>
                        <LegendTitle>LEGENDA</LegendTitle>
                      </Col>
                    </Row>
                    <Row type="flex">
                      <Col span={24}>
                        {/* Legend - Top */}
                        {animals != null
                          ? animals.find((a) => a.key === "animalsTop") !=
                              null &&
                            !!animals.find((a) => a.key === "animalsTop").values
                              .length && (
                              <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                              >
                                <Col span={22}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Square />
                                    <span>
                                      CABECEIRA
                                      <button
                                        className="eyeButton"
                                        onClick={() =>
                                          this.handleShowOrHideCategory(
                                            "animalsTop"
                                          )
                                        }
                                      >
                                        <EyeIcon
                                          isActive={
                                            !animals.find(
                                              (a) => a.key === "animalsTop"
                                            ).visible
                                          }
                                        />
                                      </button>
                                    </span>
                                  </div>
                                </Col>
                                <Col span={2}>
                                  <strong style={{ textAlign: "left" }}>
                                    {animals != null &&
                                      animals.find(
                                        (a) => a.key === "animalsTop"
                                      ).values.length}
                                  </strong>
                                </Col>
                              </Row>
                            )
                          : null}
                        {/* Legend - Medium */}
                        {animals != null
                          ? animals.find((a) => a.key === "animalsMedium") !=
                              null &&
                            !!animals.find((a) => a.key === "animalsMedium")
                              .values.length && (
                              <Row
                                type="flex"
                                justify="space-between"
                                style={{ marginTop: 7 }}
                              >
                                <Col span={22}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Square className="medium" />
                                    <span>
                                      MEIO
                                      <button
                                        className="eyeButton"
                                        onClick={() =>
                                          this.handleShowOrHideCategory(
                                            "animalsMedium"
                                          )
                                        }
                                      >
                                        <EyeIcon
                                          isActive={
                                            !animals.find(
                                              (a) => a.key === "animalsMedium"
                                            ).visible
                                          }
                                        />
                                      </button>
                                    </span>
                                  </div>
                                </Col>
                                <Col span={2}>
                                  <strong style={{ textAlign: "left" }}>
                                    {animals != null &&
                                      animals.find(
                                        (a) => a.key === "animalsMedium"
                                      ).values.length}
                                  </strong>
                                </Col>
                              </Row>
                            )
                          : null}
                        {/* Legend - Bad */}
                        {animals != null
                          ? animals.find((a) => a.key === "animalsBad") !=
                              null &&
                            !!animals.find((a) => a.key === "animalsBad").values
                              .length && (
                              <Row
                                type="flex"
                                justify="space-between"
                                style={{ marginTop: 7 }}
                              >
                                <Col span={22}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Square className="bad" />
                                    <span>
                                      FUNDO
                                      <button
                                        className="eyeButton"
                                        onClick={() =>
                                          this.handleShowOrHideCategory(
                                            "animalsBad"
                                          )
                                        }
                                      >
                                        <EyeIcon
                                          isActive={
                                            !animals.find(
                                              (a) => a.key === "animalsBad"
                                            ).visible
                                          }
                                        />
                                      </button>
                                    </span>
                                  </div>
                                </Col>
                                <Col span={2}>
                                  <strong style={{ textAlign: "left" }}>
                                    {
                                      animals.find(
                                        (a) => a.key === "animalsBad"
                                      ).values.length
                                    }
                                  </strong>
                                </Col>
                              </Row>
                            )
                          : null}
                        {/* Legend - None */}
                        {animals != null
                          ? animals.find((a) => a.key === "animalsNone") !=
                              null &&
                            !!animals.find((a) => a.key === "animalsNone")
                              .values.length && (
                              <Row
                                type="flex"
                                justify="space-between"
                                style={{ marginTop: 7 }}
                              >
                                <Col span={22}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Square className="none" />
                                    <span>
                                      SEM PESAGEM
                                      <button
                                        className="eyeButton"
                                        onClick={() =>
                                          this.handleShowOrHideCategory(
                                            "animalsNone"
                                          )
                                        }
                                      >
                                        <EyeIcon
                                          isActive={
                                            !animals.find(
                                              (a) => a.key === "animalsNone"
                                            ).visible
                                          }
                                        />
                                      </button>
                                    </span>
                                  </div>
                                </Col>
                                <Col span={2}>
                                  <strong style={{ textAlign: "left" }}>
                                    {
                                      animals.find(
                                        (a) => a.key === "animalsNone"
                                      ).values.length
                                    }
                                  </strong>
                                </Col>
                              </Row>
                            )
                          : null}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Fragment>
            )}
          </CardAnimalsChart>
        </CustomSpin>
        <Row type="flex">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <CustomDivider dashed>
              {translation.lot.production.details.titleWeigth}
            </CustomDivider>
          </Col>
        </Row>
        {/* Chart */}
        <CustomSpin spinning={isLoadingChart || this.isLoadingWeight()}>
          {lotCurveInfo === null ? (
            <Empty description="O lote e raça predominante não contem curvas, por favor cadastrar uma curva em Parêmtros -> Raças" />
          ) : (
            <>
              <Row type="flex" align="middle">
                <ColCardCurve xs={20} sm={20} md={20} lg={20} xl={20}>
                  <Row type="flex" justify="start" gutter={8}>
                    <Col span={8}>
                      <strong>
                        {translation.breed.formParameters.extimatedCurveTitle}
                      </strong>
                    </Col>
                    <Col span={6}>
                      <Row type="flex" align="middle">
                        <span>
                          {`${translation.breed.formParameters.theoricalCurve}: `}
                          <strong>
                            {lotCurveInfo != null
                              ? lotCurveInfo.breedCurveParam != null
                                ? lotCurveInfo.breedCurveParam
                                    .theoreticalCurveType
                                : ""
                              : ""}
                          </strong>
                        </span>
                        {lotCurveInfo != null &&
                          lotCurveInfo.default === true && (
                            <Popover
                              title={null}
                              content={
                                translation.lot.production.details
                                  .tooltipEstimatedCurve
                              }
                              trigger="hover"
                              placement="top"
                            >
                              <AlertDefault />
                            </Popover>
                          )}
                      </Row>
                    </Col>
                    <Col span={5}>
                      <Row type="flex" align="middle">
                        <span>
                          {`${translation.breed.formParameters.genetic}: `}
                          <strong>
                            {lotCurveInfo != null
                              ? lotCurveInfo.breedCurveParam != null
                                ? lotCurveInfo.breedCurveParam
                                    .geneticCategory === "GT"
                                  ? "Tauríno"
                                  : lotCurveInfo.breedCurveParam
                                      .geneticCategory === "GZ"
                                  ? "Zebuíno"
                                  : lotCurveInfo.breedCurveParam
                                      .geneticCategory === "GC" && "Cruzado"
                                : ""
                              : ""}
                          </strong>
                        </span>
                        {lotCurveInfo != null &&
                          lotCurveInfo.default === true && (
                            <Popover
                              title={null}
                              content={
                                translation.lot.production.details
                                  .tooltipEstimatedCurve
                              }
                              trigger="hover"
                              placement="top"
                            >
                              <AlertDefault />
                            </Popover>
                          )}
                      </Row>
                    </Col>
                    <Col span={5}>
                      <Row type="flex" align="middle">
                        <span>
                          {`${translation.breed.formParameters.diet}: `}
                          <strong>
                            {lotCurveInfo != null
                              ? lotCurveInfo.breedCurveParam != null
                                ? lotCurveInfo.breedCurveParam
                                    .handlingCategory === "DP"
                                  ? "Premium"
                                  : lotCurveInfo.breedCurveParam
                                      .handlingCategory === "DS"
                                  ? "Superior"
                                  : lotCurveInfo.breedCurveParam
                                      .handlingCategory === "DC" && "Comum"
                                : ""
                              : ""}
                          </strong>
                        </span>
                        {lotCurveInfo != null &&
                          lotCurveInfo.default === true && (
                            <Popover
                              title={null}
                              content={
                                translation.lot.production.details
                                  .tooltipEstimatedCurve
                              }
                              trigger="hover"
                              placement="top"
                            >
                              <AlertDefault />
                            </Popover>
                          )}
                      </Row>
                    </Col>
                  </Row>
                </ColCardCurve>
                <Col xs={4} sm={4} md={4} lg={4} xl={4} align="right">
                  <ButtonCurveParameter
                    type="button"
                    onClick={this.handleShowDrawer}
                  >
                    Estimar para lote
                  </ButtonCurveParameter>
                </Col>
              </Row>
              <div className="divChart">
                <CanvasJSChart options={options} />
              </div>
              <DrawerLotCurveParameter
                drawerParametersVisible={lotDrawerCurveParameterVisible}
                lotCurveParametersData={lotCurveInfo}
                isLoadingRequestSetCurve={isLoadingRequestSetCurve}
                onClose={this.handleCancelDrawer}
                onSave={this.handleSaveDrawer}
              />
            </>
          )}
        </CustomSpin>
        {/* Modal edit Lot Weight Ranking values */}
        <Modal
          visible={isModalEditLotWeightRankingVisible}
          width={680}
          centered
          title="Editar - Referência de pesos do Lote"
          onCancel={this.handleCancelModalLotWeightRanking}
          footer={[
            <ButtonStandard
              key="buttonCancelModalLotWeightRanking"
              type="button"
              buttonType="type7"
              onClick={this.handleCancelModalLotWeightRanking}
            >
              {translation.buttons.cancel}
            </ButtonStandard>,

            <ButtonStandard
              key="buttonSubmitModalLotWeightRanking"
              type="button"
              buttonType="type6"
              onClick={this.handleSubmitModalLotWeightRanking}
            >
              {translation.buttons.save}
            </ButtonStandard>,
          ]}
        >
          <Spin spinning={isLoadingRequestUpdateLotWeightRanking}>
            <Row type="flex">
              <EditLotWeightRankingContainer>
                <span className="values">
                  <div className="circle red" />
                  {`Fundo: <`}
                  <NumberFormat
                    name="value"
                    customInput={Input}
                    decimalScale="2"
                    allowNegative={false}
                    suffix=" %"
                    value={editFundoPercentage}
                    onValueChange={({ floatValue }) => {
                      this.setState({
                        editFundoPercentage: floatValue ? floatValue : 1,
                      });
                    }}
                  />
                </span>
                <span className="values">
                  <div className="circle green" />
                  Meio:
                  <strong className="gray">{`>${editFundoPercentage || 1}% | <${
                    editCabeceiraPercentage || 2
                  }%`}</strong>
                </span>
                <span className="values">
                  <div className="circle purple" />
                  {`Cabeceira: >`}
                  <NumberFormat
                    name="valueExtra"
                    customInput={Input}
                    decimalScale="2"
                    allowNegative={false}
                    suffix=" %"
                    value={editCabeceiraPercentage}
                    onValueChange={({ floatValue }) => {
                      this.setState({
                        editCabeceiraPercentage: floatValue ? floatValue : 2,
                      });
                    }}
                  />
                </span>
              </EditLotWeightRankingContainer>
            </Row>
          </Spin>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  lot: state.lot,
});

const mapDispatchToProps = (dispatch) => ({
  lotActions: bindActionCreators(LotActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LotDetailsCharts);
