import styled from "styled-components";
import { Modal } from "antd";

export const CustomModal = styled(Modal)`
  position: relative !important;
  &.ant-modal {
    padding-bottom: 0px !important;
  }
  .ant-modal-body {
    padding: 0px !important;
  }
`;

export const Container = styled.div`
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;

  padding: 27px 20px 0px 25px;

  .ant-row-flex {
    width: 100% !important;
  }

  .ant-row-flex:last-child {
    margin-top: 5px !important;
  }

  button:not(:last-child) {
    margin-right: 14px;
  }

  label.formLabel {
    font-family: Asap;
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;

    &.disabled {
      font-weight: bold;
      font-size: 14px;
      margin-left: 0px;
      color: #c4c4c4;
    }
    &.error {
      color: #d44c4c;
    }
  }

  input {
    margin-top: 6px;
  }

  textarea {
    margin-top: 6px;
  }

  .form-row {
    margin-top: 13px;
  }

  .ant-select {
    margin-top: 6px;
  }

  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 50px !important;
  }

  .ant-input-affix-wrapper .ant-input-prefix,
  .ant-input-affix-wrapper .ant-input-suffix {
    top: 60% !important;
    font-weight: bold !important;
  }
`;

export const Header = styled.div`
  margin-bottom: 23px;
`;

export const Title = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  color: #4b4b4b;
`;

export const SubTitle = styled.span`
  font-family: Asap;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  color: #4b4b4b;
`;

export const DateSpan = styled.span`
  font-family: Asap;
  font-weight: normal;
  font-size: 22px;
  text-align: left;
  color: #4b4b4b;
`;

export const Footer = styled.div`
  margin-top: 15px;
  border-top: 1px solid #e8e5e5;
  padding: 15px 20px;
  width: 100%;
  bottom: 0;
`;

export const InfoCard = styled.div`
  width: 100%;
  padding: 10px 0px 10px 10px;
  position: relative;
  border-radius: 6px;
  background: transparent;
  border: 1px dashed #4a85ae;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4a85ae;
  svg {
    position: absolute;
    left: 0%;
    margin-left: 10px;
  }
  span.title {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #4a85ae;
    text-align: center;
  }
`;
