import styled from "styled-components";
import { Modal } from "antd";

export const StyledModal = styled(Modal)`
  &.ant-modal {
    padding: 0px;
  }
  .ant-modal-body {
    padding: 29px 26px;
  }

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Asap", serif;

    svg {
      margin-bottom: 20px;
    }

    strong {
      font-size: 16px;
    }

    p {
      text-align: center;
      margin: 10px 0;
    }

    ul {
      list-style: none;
      margin-top: 5px;
      text-align: center;
      li {
        display: inline-block;
        font-size: 14px;
        color: #7c51a1;
        font-weight: bold;

        &:before {
          display: inline-block;
          content: " ";
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #a6ce39;
          margin-right: 8px;
        }

        & + li {
          padding-left: 20px;
        }
      }
    }
  }
`;
