import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import ArrowDownWhite from "../icons/arrows/green/down";

import { Container } from "./styles";

function AlertDatePicker({ alertsFilterDate, getAlerts }) {
  const { translation } = useSelector((state) => state.app);
  return (
    <Container
      name="alertsFilterDate"
      allowClear={false}
      bordered={false}
      suffixIcon={
        <ArrowDownWhite color="#D44C4C" style={{ color: "#D44C4C" }} />
      }
      className="alertDate"
      value={
        alertsFilterDate == null
          ? moment()
          : moment(alertsFilterDate, "YYYY-MM-DD")
      }
      placeholder={translation.defaultDatePickerPlaceholder}
      format="DD/MM/YYYY"
      onChange={(date, dateString) => {
        if (date <= moment() && date !== null) {
          getAlerts(date, false);
        }
      }}
    />
  );
}

export default AlertDatePicker;
