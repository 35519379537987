import { call, put } from "redux-saga/effects";
import { Creators as ImportLogActions } from "../ducks/import";
/** Services */
import {
  getAnimalImportLog,
  getAnimalWeightImportLog
} from "../../services/importService";

export function* indexAnimalImportLog(action) {

  try {
    const {
      data: { results: allAnimalImportLogs }
    } = yield call(getAnimalImportLog, action.payload);
    yield put(ImportLogActions.indexAnimalImportLogSuccess(allAnimalImportLogs));
  } catch (error) {
    yield put(ImportLogActions.indexAnimalImportLogError(error));
  }
}

export function* indexAnimalWeightImportLog(action) {
  try {
    const {
      data: { results: allAnimalWeightImportLogs }
    } = yield call(getAnimalWeightImportLog, action.payload);
    yield put(ImportLogActions.indexAnimalWeightImportLogSuccess(allAnimalWeightImportLogs));
  } catch (error) {
    yield put(ImportLogActions.indexAnimalWeightImportLogError(error));
  }
}



