import React from 'react';

// import { Container } from './styles';

const LinkedinHomeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24.657" height="23.561" viewBox="0 0 24.657 23.561">
    <path id="LinkedIn" d="M24.657,24v9.116H19.372V24.614c0-2.137-.764-3.595-2.677-3.595a2.892,2.892,0,0,0-2.712,1.933,3.616,3.616,0,0,0-.175,1.289v8.878H8.521s.071-14.405,0-15.9h5.286v2.253c-.011.017-.025.035-.035.051h.035v-.051a5.249,5.249,0,0,1,4.764-2.627C22.05,16.848,24.657,19.12,24.657,24ZM2.991,9.558a2.755,2.755,0,1,0-.07,5.494h.035a2.756,2.756,0,1,0,.035-5.494ZM.314,33.119H5.6v-15.9H.314Z" transform="translate(0 -9.558)" fill="#fff"/>
  </svg>

);
export default LinkedinHomeIcon;
