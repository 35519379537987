import React from 'react';

// import { Container } from './styles';

const MenuIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="5" viewBox="0 0 21 5">
    <g id="unhover" transform="translate(-1306 -463)">
      <circle
        id="Ellipse_163"
        data-name="Ellipse 163"
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="translate(1306 463)"
        fill="#c4c4c4"
      />
      <circle
        id="Ellipse_164"
        data-name="Ellipse 164"
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="translate(1314 463)"
        fill="#c4c4c4"
      />
      <circle
        id="Ellipse_165"
        data-name="Ellipse 165"
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="translate(1322 463)"
        fill="#c4c4c4"
      />
    </g>
  </svg>
);
export default MenuIcon;
