import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useMultipleBullContext from "../../hooks/useMultipleBullContext";
import { MultipleBullContextProvider } from "../../contexts/multipleBullContext";
import moment from "moment";

import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Icon,
  Input,
  Menu,
  Modal,
  notification,
  Row,
  Spin,
  Table,
} from "antd";
import ButtonStandard from "../../components/utils/button";
import { CardCustom, Title } from "./styles";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import MenuIcon from "../../components/utils/table/icons/menu";
import TagStatus from "../../components/utils/tagStatus";

// Form
import DrawerMultipleBull from "../../components/drawers/multipleBull";
import { destroy, save } from "../../services/multipleBullService";

const MultipleBullLayout = () => {
  const [multipleBullSelect, setMultipleBullSelect] = useState(null);
  const [showModalInactivate, setShowModalInactivate] = useState(false);
  const [generalDateOut, setGeneralDateOut] = useState(null);

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const {
    dataList,
    isLoadingDataList,
    openDrawer,
    fetchData,
    switchIsLoading,
  } = useMultipleBullContext();

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const menu = (id, record) => {
    return (
      <Menu>
        <Menu.Item onClick={() => handleEdit(record)}>
          {translation.table.menu.edit}
        </Menu.Item>
        <Menu.Item onClick={() => handleDelete(id)}>
          {translation.table.menu.delete}
        </Menu.Item>
        {record.status === "Active" && (
          <Menu.Item onClick={() => handleOpenModalInactivate(record)}>
            {translation.table.menu.inactivate}
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const handleEdit = useCallback(
    (record) => {
      openDrawer(record);
    },
    [openDrawer]
  );
  const handleDelete = useCallback(
    async (id) => {
      try {
        switchIsLoading();
        await destroy({ groupId, farmId, id });
        notification.success({
          message: "Touro múltiplo deletado com sucesso.",
        });
        fetchData();
        setShowModalInactivate(false);
      } catch (error) {
        if (Object.keys(error).includes("response")) {
          const { response } = error;
          if (Object.keys(response).includes("data")) {
            const {
              data: { code: errorCode },
            } = response;
            if (errorCode === 5005) {
              notification.error({
                message: "Erro",
                description: `Este Touro Múltiplo não pode ser excluído, ele já está ligado a Coberturas.`,
              });
            } else {
              notification.error({
                message: "Erro",
                description: "Erro ao deletar touro múltiplo.",
              });
            }
          }
        } else {
          notification.error({
            message: "Erro",
            description: "Erro ao deletar touro múltiplo.",
          });
        }
      } finally {
        switchIsLoading();
      }
    },
    [farmId, fetchData, groupId, switchIsLoading]
  );

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetDateSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div
          style={{
            padding: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Row style={{ marginBottom: 5 }}>
            <DatePicker.RangePicker
              value={selectedKeys && selectedKeys[0]?.dates}
              format="DD/MM/YYYY"
              onChange={(dates, dateStrings) => setSelectedKeys([{ dates }])}
            />
          </Row>
          <Row>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{
                width: 90,
                marginRight: 8,
                background: "#684e94",
                borderColor: "none",
                border: "none",
              }}
            />
            <Button
              onClick={() => handleReset(clearFilters)}
              icon="delete"
              size="small"
              style={{ width: 90 }}
            />
          </Row>
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="calendar"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return moment(record[dataIndex]).isBetween(
          value?.dates[0],
          value?.dates[1],
          "days",
          "[]"
        );
      },
    };
  };

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        if (dataIndex === "males") {
          return record[dataIndex]
            ?.map((m) => m.animalHandlingNumber.toLowerCase())
            .join(" ")
            .includes(value.toString().toLowerCase());
        } else {
          return record[dataIndex]
            ?.toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase());
        }
      },
    }),
    [handleReset, handleSearch]
  );

  const handleOpenDrawer = () => {
    openDrawer();
  };

  const handleOpenModalInactivate = (record) => {
    setMultipleBullSelect(record);
    setShowModalInactivate(true);
    setGeneralDateOut(null);
  };

  const handleCancelModalInactivate = () => {
    setMultipleBullSelect(null);
    setShowModalInactivate(false);
    setGeneralDateOut(null);
  };

  const handleInactivate = async () => {
    try {
      switchIsLoading();
      const body = {
        ...multipleBullSelect,
        males: multipleBullSelect.males.map((m) => {
          if (m.dateOut === null) {
            m.dateOut = generalDateOut;
          }
          return m;
        }),
        status: "Inactive",
      };
      await save({ groupId, farmId, id: body?.id, body });
      notification.success({
        message: "Touro múltiplo inativado com sucesso.",
      });
      fetchData();
      setShowModalInactivate(false);
    } catch (error) {
      notification.error({
        message: "Erro",
        description: "Erro ao inativar touro múltiplo.",
      });
    } finally {
      switchIsLoading();
    }
  };

  const handleActivate = async (record) => {
    try {
      switchIsLoading();
      const body = {
        ...record,
        status: "Active",
      };
      await save({ groupId, farmId, id: body?.id, body });
      notification.success({
        message: "Touro múltiplo ativado com sucesso.",
      });
      fetchData();
      setShowModalInactivate(false);
    } catch (error) {
      notification.error({
        message: "Erro",
        description: "Erro ao ativar touro múltiplo.",
      });
    } finally {
      switchIsLoading();
    }
  };

  return (
    <CardCustom>
      <Row className="rowHeader" justify="space-between">
        <Col span={8}>
          <Title>{translation.multipleBull.title}</Title>
        </Col>
        <Col span={16}>
          <Row type="flex" justify="end" gutter={8}>
            <Col>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={fetchData}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </Col>
            <Col>
              <ButtonStandard buttonType="type8" onClick={handleOpenDrawer}>
                {translation.multipleBull.buttonNewMultipleBull}
              </ButtonStandard>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <Table
            loading={isLoadingDataList}
            rowKey="id"
            size="small"
            dataSource={dataList}
            scroll={{
              x: true,
            }}
            pagination={{
              hideOnSinglePage:
                dataList !== null && dataList.length > 10 ? false : true,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
            }}
          >
            <Table.Column
              title={translation.multipleBull.table.columns.code}
              dataIndex="code"
              sorter={(first, second) => {
                if (!first.code) {
                  first.code = "";
                }
                return first.code.localeCompare(second.code, "pt-BR", {
                  numeric: false,
                  ignorePunctuation: true,
                });
              }}
              key="code"
              {...handleGetColumnSearchProps("code")}
            />
            <Table.Column
              title={translation.multipleBull.table.columns.name}
              dataIndex="name"
              sorter={(first, second) => {
                if (!first.name) {
                  first.name = "";
                }
                return first.name.localeCompare(second.name, "pt-BR", {
                  numeric: false,
                  ignorePunctuation: true,
                });
              }}
              key="name"
              {...handleGetColumnSearchProps("name")}
            />
            <Table.Column
              title={translation.multipleBull.table.columns.males}
              dataIndex="males"
              key="males"
              {...handleGetColumnSearchProps("males")}
              render={(text, record) => (
                <span>
                  {record.males.map((m) => m.animalHandlingNumber).join(", ")}
                </span>
              )}
            />
            <Table.Column
              title={translation.multipleBull.table.columns.formationDate}
              dataIndex="formationDate"
              sorter={(first, second) => {
                if (!first.formationDate) {
                  first.formationDate = "";
                }
                return first.formationDate.localeCompare(
                  second.formationDate,
                  "pt-BR",
                  {
                    numeric: false,
                    ignorePunctuation: true,
                  }
                );
              }}
              key="formationDate"
              {...handleGetDateSearchProps("formationDate")}
              render={(text) => (
                <span>{moment(text).format("DD/MM/YYYY")}</span>
              )}
            />
            <Table.Column
              title={translation.multipleBull.table.columns.status}
              dataIndex="status"
              key="status"
              align="left"
              sorter={(first, second) => {
                return first.status.localeCompare(second.status, "pt-BR", {
                  numeric: false,
                  ignorePunctuation: true,
                });
              }}
              defaultFilteredValue={["Active"]}
              filtered
              filters={[
                {
                  text: translation.status.active,
                  value: "Active",
                },
                {
                  text: translation.status.inactive,
                  value: "Inactive",
                },
              ]}
              filterMultiple
              onFilter={(value, record) => record.status.includes(value)}
              render={(status) =>
                status === "Active" ? (
                  <TagStatus
                    background="#C5F1CA"
                    borderColor="#106518"
                    color="#106518"
                  >
                    {translation.status.active}
                  </TagStatus>
                ) : (
                  <TagStatus
                    background="#FBC7C7"
                    borderColor="#D44C4C"
                    color="#D44C4C"
                  >
                    {translation.status.inactive}
                  </TagStatus>
                )
              }
            />
            <Table.Column
              title=""
              align="left"
              render={(text, record) => (
                <Dropdown
                  overlay={menu(record.id, record)}
                  trigger={["click"]}
                  key={record.id}
                >
                  <MenuContainer>
                    <MenuIcon />
                  </MenuContainer>
                </Dropdown>
              )}
            />
          </Table>
        </Col>
      </Row>
      <Modal visible={showModalInactivate} centered footer={null}>
        <Spin spinning={isLoadingDataList}>
          <Row type="flex" justify="center">
            <h3>{`Deseja inativar ${multipleBullSelect?.name}?`}</h3>
          </Row>
          <Row type="flex" justify="center">
            <h3>Informe primeiro a data de saída para os reprodutores:</h3>
          </Row>
          <Row style={{ marginTop: 8 }} gutter={8} type="flex" align="middle">
            <Col>
              <strong style={{ color: "#9074bf" }}>Saída Geral</strong>
            </Col>
            <Col>
              <DatePicker
                name="generalDateOut"
                format={"DD/MM/YYYY"}
                disabledDate={(current) =>
                  current.isAfter(moment()) ||
                  (multipleBullSelect?.formationDate &&
                    current.isBefore(multipleBullSelect?.formationDate))
                }
                value={generalDateOut ? moment(generalDateOut) : undefined}
                placeholder={translation.defaultDatePickerPlaceholder}
                onChange={(date) => setGeneralDateOut(date)}
              />
            </Col>
          </Row>
          <Row type="flex" justify="end" gutter={8}>
            <Col>
              <ButtonStandard
                type="button"
                buttonType="type7"
                onClick={handleCancelModalInactivate}
              >
                {translation.buttons.cancel}
              </ButtonStandard>
            </Col>
            <Col>
              <ButtonStandard
                type="button"
                buttonType="type6"
                disabled={!generalDateOut}
                onClick={handleInactivate}
              >
                {translation.buttons.confirm}
              </ButtonStandard>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </CardCustom>
  );
};

const MultipleBull = () => {
  return (
    <MultipleBullContextProvider>
      <MultipleBullLayout />
      <DrawerMultipleBull />
    </MultipleBullContextProvider>
  );
};

export default MultipleBull;
