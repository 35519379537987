import styled from "styled-components";

export const Container = styled.div`
  padding-top: 27px;
  .breadCrumbsContainer {
    padding-bottom: 20px;
  }
  .titleContainer {
    display: flex;
    align-items: center;
  }
  .back-container {
    text-decoration: none;
    margin-right: 15px;
    padding-right: 10px;
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #d44c4c !important;
    cursor: pointer;

    svg {
      color: #d44c4c;
    }
  }
`;

export const Title = styled.h1`
  font-family: sans-serif;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: #4b4b4b;
  margin: 0 16px 0 0;
`;

export const Document = styled.div`
  display: block;
  width: 100%;

  @media print {
    tr {
      page-break-after: avoid;
    }
    thead {
      display: table-header-group;
    }
  }

  @page {
    size: auto;
  }
`;

export const Header = styled.header`
  width: 100%;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 16px 16px;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & + div {
      margin-top: 16px;
    }

    strong,
    span {
      display: flex;

      font-family: sans-serif;
      font-style: normal;
      &.title {
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        /* identical to box height */

        text-align: center;

        /* BovExo - Roxo primária */

        color: #8a50a5;
      }
      &.farm-name {
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        /* identical to box height */

        text-align: center;

        /* BovExo - Texto */

        color: #4b4b4b;
      }
    }
  }
`;

export const Body = styled.div`
  padding: 0 8px;
`;

export const Table = styled.table`
  margin-top: 24px;
  margin-bottom: 64px;

  width: 100%;
  font-family: sans-serif;
  border-collapse: separate;
  border-spacing: 0;

  thead {
    border: none;

    tr {
      text-align: center;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 11px;
      color: #4b4b4b;
      th {
        padding: 8px 0;
        span {
          margin: -3px 0px 0px 2px;
          position: absolute;
        }
      }
      th:nth-child(1) {
        width: 275px;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 8px 0;
        font-style: normal;
        font-weight: bold;
        line-height: 9px;
        text-align: center;
        color: #4b4b4b;

        &.title {
          font-size: 16px;
          background-color: #a676bc;
          color: #f4f4f4;
        }

        &.field {
          font-size: 12px;
          span {
            margin: -4px 0px 0px 2px;
            position: absolute;
          }
        }
        &.value {
          font-size: 10px;
        }

        div.lotInfo {
          & + div.lotInfo {
            padding-top: 8px;
            border-top: 1px solid #4b4b4b;
          }
        }
      }

      &:nth-child(1) {
        td {
          border-top: 1px solid #4b4b4b;
          border-bottom: 1px solid #4b4b4b;
          & + td {
            border-left: 1px solid #4b4b4b;
          }
          &:first-child {
            border-top-left-radius: 10px;
            border-left: 1px solid #4b4b4b;
          }
          &:last-child {
            border-top-right-radius: 10px;
            border-right: 1px solid #4b4b4b;
          }
        }
      }

      &.separator {
        td {
          border-bottom: 1px solid #4b4b4b;
        }
      }

      & + tr:not(.separator) {
        td {
          border-bottom: 1px solid #4b4b4b;
          & + td {
            border-left: 1px solid #4b4b4b;
          }
          &:first-child {
            border-left: 1px solid #4b4b4b;
          }
          &:last-child {
            border-right: 1px solid #4b4b4b;
          }
        }
      }
    }
  }
`;
