import { Col, DatePicker, Input, Row, Tag, notification } from "antd";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import ButtonStandard from "../../../../../../components/utils/button";
import { Container, InstallmentsContainer } from "./styles";
// Services
import TrashIcon from "../../../../../../components/utils/icons/trash";
import InputCurrency from "../../../../../../components/utils/inputCurrency";
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../../../../services/helpersMethodsService";

// import { Container } from './styles';

function InstallmentsModal({
  isInstallmentsModalVisible,
  closeInstallmentsModal,
  value = null,
  valueCurrencySymbol = "BRL",
  expirationDate = null,
  installments = [],
  installmentTotal = null,
  setFieldValue,
}) {
  const firstInputRef = useRef();
  const [form, setForm] = useState({
    value: null,
    expirationDate: null,
    installments: [],
    installmentTotal: null,
  });
  const { translation } = useSelector((state) => state.app);

  useEffect(() => {
    if (isInstallmentsModalVisible) {
      if (firstInputRef.current) firstInputRef.current.focus();
      setForm({
        value: value,
        expirationDate: expirationDate,
        installments: installments,
        installmentTotal: installmentTotal,
      });
    }
  }, [
    expirationDate,
    installmentTotal,
    installments,
    isInstallmentsModalVisible,
    value,
  ]);

  const validate = () => {
    if (form.installments.length === 0) {
      throw new Error(
        "Não foi gerado nenhuma parcela. Preencha o campo 'Nº de Parcelas' e clique no botão 'Calcular'."
      );
    }
    if (form.installments.length !== form.installmentTotal) {
      throw new Error(
        "A quantidade de parcelas geradas não é igual ao Nº de Parcelas.Clique no botão 'Calcular' para gerar novamente as parcelas."
      );
    }

    let sumInstallmentsValues = form.installments.reduce(
      (prev, curr) => prev + curr.value,
      0
    );

    sumInstallmentsValues = Math.round(sumInstallmentsValues * 100) / 100;

    if (
      sumInstallmentsValues < form.value ||
      sumInstallmentsValues > form.value
    ) {
      throw new Error(
        'O valor total das parcelas geradas não é igual ao "Valor da Transação".'
      );
    }
  };

  const handleSave = () => {
    try {
      validate();
      setFieldValue("installmentTotal", form.installmentTotal);
      setFieldValue("installments", form.installments);
      closeInstallmentsModal();
    } catch (error) {
      notification.error({
        message: "Erro ao salvar",
        description: error.message,
      });
    }
  };

  const handleCancel = () => {
    setForm({
      value: null,
      expirationDate: null,
      installments: [],
      installmentTotal: null,
    });
    closeInstallmentsModal();
  };

  const handleCalc = useCallback(
    (pInstallmentTotal = null) => {
      const totalInstallments =
        pInstallmentTotal != null ? pInstallmentTotal : form.installmentTotal;
      if (form.expirationDate != null && totalInstallments >= 1) {
        let arrayOfInstallments = [];
        const size = arrayOfInstallments.length;
        for (let i = size; i < totalInstallments; i++) {
          let expirationDatePlus1Month = moment(form.expirationDate).add(
            i,
            "months"
          );
          let obj = {
            date: expirationDatePlus1Month,
            value: getTwoDecimalDigits(form.value / totalInstallments),
          };
          arrayOfInstallments.push(obj);
        }

        const sumInstallmentsValues = arrayOfInstallments.reduce(
          (prev, curr) => prev + curr.value,
          0
        );

        if (
          sumInstallmentsValues < form.value ||
          sumInstallmentsValues > form.value
        ) {
          const lastValue =
            arrayOfInstallments[arrayOfInstallments.length - 1].value;

          const diffBetweenInstallmentsSumAndDocumentValue = Number(
            Number(form.value - sumInstallmentsValues).toPrecision(2)
          );

          const newValue = getTwoDecimalDigits(
            lastValue + diffBetweenInstallmentsSumAndDocumentValue
          );

          arrayOfInstallments[arrayOfInstallments.length - 1].value = newValue;
        }

        setForm((old) => ({
          ...old,
          installmentTotal: totalInstallments,
          installments: arrayOfInstallments,
        }));
      } else if (form.expirationDate == null) {
        notification.warn({
          message:
            "É necessário informar a Data de Vencimento para gerar as parcelas.",
        });
      }
    },
    [form.expirationDate, form.installmentTotal, form.value]
  );

  const handleChangeInstallmentDate = useCallback(
    (newDate, index) => {
      let obj = form.installments[index];
      obj.date = newDate;
      setForm((old) => ({
        ...old,
        installments: old.installments.map((o, i) => (i === index ? obj : o)),
      }));
    },
    [form.installments]
  );
  const handleChangeInstallmentValue = useCallback(
    (newValue, index) => {
      let obj = form.installments[index];
      obj.value = newValue;
      setForm((old) => ({
        ...old,
        installments: old.installments.map((o, i) => (i === index ? obj : o)),
      }));
    },
    [form.installments]
  );

  const handleDeleteInstallment = useCallback(
    (index) => {
      handleCalc(form.installmentTotal - 1);
    },
    [form.installmentTotal, handleCalc]
  );

  const renderInstalllments = useMemo(() => {
    return form.installments.map((i, index) => (
      <Row key={index} type="flex" justify="start" align="middle" gutter={8}>
        <Col span={6} className="alingMiddle">
          <Tag color="#684e94">{`${index + 1}/${
            form.installments.length
          }`}</Tag>
        </Col>
        <Col span={6}>
          <DatePicker
            value={i.date != null ? moment(i.date) : undefined}
            format="DD/MM/YYYY"
            allowClear={false}
            disabledDate={(current) =>
              current.isBefore(moment(form.expirationDate))
            }
            onChange={(date) => handleChangeInstallmentDate(date, index)}
          />
        </Col>
        <Col span={6} className="alingMiddle">
          <InputCurrency
            name="mainValue"
            value={i.value || ""}
            symbol={valueCurrencySymbol}
            setValue={(floatValue) => {
              handleChangeInstallmentValue(floatValue, index);
            }}
          />
        </Col>
        <Col span={6} className="alingMiddle">
          <ButtonStandard
            padding="0px 10px"
            background="transparent"
            type="button"
            onClick={() => handleDeleteInstallment(index)}
          >
            <TrashIcon />
          </ButtonStandard>
        </Col>
      </Row>
    ));
  }, [
    form.installments,
    form.expirationDate,
    valueCurrencySymbol,
    handleChangeInstallmentDate,
    handleChangeInstallmentValue,
    handleDeleteInstallment,
  ]);

  return (
    <Container
      title={
        <Row type="flex" justify="center">
          <strong>
            {translation.financial.transactions.modalInstallments.title}
          </strong>
        </Row>
      }
      width={750}
      visible={isInstallmentsModalVisible}
      footer={null}
      centered
      closable={false}
    >
      <Row type="flex" justify="start" align="middle" gutter={8}>
        <Col span={6}>
          <Row>
            <label>
              {translation.financial.transactions.modalInstallments.value}*
            </label>
          </Row>
          <Row>
            <InputCurrency
              name="mainValue"
              value={form.value != null ? numberMask(form.value, false) : ""}
              symbol={valueCurrencySymbol}
              disabled
            />
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <label>
              {
                translation.financial.transactions.modalInstallments
                  .expirationDate
              }
              *
            </label>
          </Row>
          <Row>
            <DatePicker
              name="expirationDate"
              value={
                form.expirationDate ? moment(form.expirationDate) : undefined
              }
              format="DD/MM/YYYY"
              disabled
            />
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <label>
              {
                translation.financial.transactions.modalInstallments
                  .installmentTotal
              }
              *
            </label>
          </Row>
          <Row>
            <Input
              ref={firstInputRef}
              type="number"
              autoFocus
              name="installmentTotal"
              min={1}
              placeholder={translation.defaultSelectPlaceholder}
              value={form.installmentTotal || ""}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleCalc();
                }
              }}
              onChange={(e) => {
                if (e.target.value > 999) {
                  setForm({
                    ...form,
                    installmentTotal: 999,
                  });
                } else {
                  setForm({
                    ...form,
                    installmentTotal: Number(e.target.value),
                  });
                }
              }}
            />
          </Row>
        </Col>
        <Col span={6} className="colButtonCalc">
          <ButtonStandard buttonType="thirdary" onClick={() => handleCalc()}>
            {translation.financial.transactions.modalInstallments.buttonCalc}
          </ButtonStandard>
        </Col>
      </Row>
      {/* Installments */}
      <InstallmentsContainer>{renderInstalllments}</InstallmentsContainer>
      {/* Footer */}
      <Row
        className="rowFooter"
        type="flex"
        justify="center"
        align="middle"
        gutter={8}
      >
        <Col>
          <ButtonStandard
            type="submit"
            name="buttonSaveFinancialTransactionsForm"
            buttonType="principal"
            onClick={handleSave}
          >
            {translation.buttons.save}
          </ButtonStandard>
        </Col>
        <Col>
          <ButtonStandard
            name="buttonSaveFinancialTransactionsForm"
            buttonType="secondary"
            type="button"
            onClick={handleCancel}
          >
            {translation.buttons.cancel}
          </ButtonStandard>
        </Col>
      </Row>
    </Container>
  );
}

export default InstallmentsModal;
