import moment from "moment";
import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";
import { Link, withRouter } from "react-router-dom";
/* Redux libs */
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
// Styles components
import { Col, Empty, Icon, Row, Tooltip, notification } from "antd";
import {
  Container,
  FarmDropDownMenu,
  FarmMenu,
  GroupDropDownMenu,
  GroupMenu,
  LanguageDropDownMenu,
  LanguageMenu,
  NotificationBadge,
  NotificationContainerItem,
  NotificationDropDownMenu,
  NotificationItem,
  NotificationMenu,
  NotificationType,
  SearchButton,
  SearchDropDownMenu,
  SearchMenu,
  UserDropDownMenu,
  UserMenu,
  Wrapper,
} from "./styles";

/* Languages */
import loadLang from "../../../languages";

/* Actions from ducks */
import { Creators as AccountFarmActions } from "../../../store/ducks/accountFarm";
import { Creators as AnimalActions } from "../../../store/ducks/animal";
import { Creators as AppActions } from "../../../store/ducks/app";
import { Creators as LotActions } from "../../../store/ducks/lot";
import { Creators as PicketActions } from "../../../store/ducks/picket";
import { Creators as ProfitCenterActions } from "../../../store/ducks/profitCenter";
import { Creators as UserActions } from "../../../store/ducks/user";

/* Icons */
import ArrowDownWhite from "../../utils/icons/arrows/green/down";
import AvatarUser from "../../utils/icons/avatarUser";
import BrazilActiveFlag from "../../utils/icons/flags/brazil/active";
import BrazilBlankFlag from "../../utils/icons/flags/brazil/blank";
import EnglishActiveFlag from "../../utils/icons/flags/english/active";
import EnglishBlankFlag from "../../utils/icons/flags/english/blank";
import SpanishActiveFlag from "../../utils/icons/flags/spanish/active";
import SpanishBlankFlag from "../../utils/icons/flags/spanish/blank";
import GroupIcon from "../../utils/icons/group";
import NotificationIcon from "../../utils/icons/notification";
import PlusPurpleIcon from "../../utils/icons/plus/purple";
import TranslatorIcon from "../../utils/icons/translator";
import AddButtonIcon from "./icons/addButton";
import FarmIcon from "./icons/farm";
import PlusAddMenuIcon from "./icons/plus";

import AllowedComponentTo from "../../utils/allowedComponentTo";

// Services
import useModuleContext from "../../../hooks/useModuleContext";
import { downloadBovManagerService } from "../../../services/generalParameterService";
import {
  getNotificationIndex,
  visualizeOneNotification,
} from "../../../services/notificationsService";
import SliderDataAgro from "./sliderDataAgro";
import usePicketSupplementSupplyContext from "../../../hooks/usePicketSupplementSupplyContext";

const PlusMenuOptions = ({
  openMenuPlus,
  user,
  renderPlusMenuInputSupplier,
  openDrawer,
  openModal,
  isDownloadBovManager,
  downloadBovManager,
}) => {
  const { translation } = useSelector((state) => state.app);
  const { isAllowedFunctions } = useModuleContext();
  const { openDrawer: openPicketSupplementSupplyDrawer } =
    usePicketSupplementSupplyContext();

  return (
    <SearchDropDownMenu className={openMenuPlus ? "active" : ""}>
      {user.isInputSupplier ? (
        renderPlusMenuInputSupplier()
      ) : (
        <>
          <li
            className={
              !isAllowedFunctions(["PLUS_MENU_PROFIT_CENTER"])
                ? "download disable"
                : ""
            }
          >
            <button
              onClick={() => openDrawer("profitCenter")}
              disabled={!isAllowedFunctions(["PLUS_MENU_PROFIT_CENTER"])}
            >
              {translation.topbar.add.submenus.profit_center}
            </button>
          </li>
          <li
            className={
              !isAllowedFunctions(["PLUS_MENU_PICKET"])
                ? "download disable"
                : ""
            }
          >
            <button
              onClick={() => openDrawer("picket")}
              disabled={!isAllowedFunctions(["PLUS_MENU_PICKET"])}
            >
              {translation.topbar.add.submenus.picket}
            </button>
          </li>
          <li
            className={
              !isAllowedFunctions(["PLUS_MENU_PICKET"])
                ? "download disable"
                : ""
            }
          >
            <button onClick={() => openPicketSupplementSupplyDrawer()}>
              {translation.topbar.add.submenus.picketSupplementSupply}
            </button>
          </li>
          <li
            className={
              !isAllowedFunctions(["PLUS_MENU_ANIMAL"])
                ? "download disable"
                : ""
            }
          >
            <button
              onClick={() => openDrawer("animal")}
              disabled={!isAllowedFunctions(["PLUS_MENU_ANIMAL"])}
            >
              {translation.topbar.add.submenus.animal}
            </button>
          </li>
          <li
            className={
              !isAllowedFunctions(["PLUS_MENU_LOT"]) ? "download disable" : ""
            }
          >
            <button
              onClick={() => openDrawer("lotProduction")}
              disabled={!isAllowedFunctions(["PLUS_MENU_LOT"])}
            >
              {translation.topbar.add.submenus.lotProduction}
            </button>
          </li>
          <AllowedComponentTo roles={[]} hide>
            <li
              className={
                !isAllowedFunctions(["PLUS_MENU_IMPORT_WEIGHT"])
                  ? "download disable"
                  : ""
              }
            >
              <button
                onClick={() => openModal("animalWeightUpload")}
                disabled={!isAllowedFunctions(["PLUS_MENU_IMPORT_WEIGHT"])}
              >
                {translation.topbar.add.submenus.importAnimalsWeights}
              </button>
            </li>
          </AllowedComponentTo>
          <AllowedComponentTo roles={["Trial"]} reverse hide>
            <li
              className={
                !isAllowedFunctions(["PLUS_MENU_IMPORT_ANIMAL"])
                  ? "download disable"
                  : ""
              }
            >
              <button
                onClick={() => openModal("animalUpload")}
                disabled={!isAllowedFunctions(["PLUS_MENU_IMPORT_ANIMAL"])}
              >
                {translation.topbar.add.submenus.importAnimals}
              </button>
            </li>
          </AllowedComponentTo>
          <AllowedComponentTo roles={["Trial"]} reverse hide>
            <li
              className={
                !isAllowedFunctions(["PLUS_MENU_IMPORT_FINANCIAL_TRANSACTION"])
                  ? "download disable"
                  : ""
              }
            >
              <button
                onClick={() => openModal("financialTransactionUpload")}
                disabled={
                  !isAllowedFunctions([
                    "PLUS_MENU_IMPORT_FINANCIAL_TRANSACTION",
                  ])
                }
              >
                {translation.topbar.add.submenus.importFinancialTransactions}
              </button>
            </li>
          </AllowedComponentTo>
          <AllowedComponentTo roles={["Trial"]} reverse hide>
            <li
              className={
                !isAllowedFunctions(["PLUS_MENU_IMPORT_SUPPLEMENT"])
                  ? "download disable"
                  : ""
              }
            >
              <button onClick={() => openModal("supplementUpload")}>
                {translation.topbar.add.submenus.importSupplements}
              </button>
            </li>
          </AllowedComponentTo>
          <li
            className={
              !isAllowedFunctions(["PLUS_MENU_DOWNLOAD_BOVMANAGER"])
                ? "download disable"
                : isDownloadBovManager
                ? "download disable"
                : "download"
            }
          >
            <button onClick={downloadBovManager}>
              <span>{translation.topbar.add.submenus.downloadBovManager}</span>
              {isDownloadBovManager && (
                <Icon type="loading" spin style={{ fontSize: 24 }} />
              )}
            </button>
          </li>
          <li>
            <div>
              <span className="title">{translation.topbar.add.title}</span>
              <PlusAddMenuIcon />
            </div>
          </li>
        </>
      )}
    </SearchDropDownMenu>
  );
};

class TopBar extends Component {
  state = {
    isDownloadBovManager: false,
    screenWidth: null,
    screenHeight: null,
    openMenuGroup: false,
    openMenuFarm: false,
    openMenuNotification: false,
    openMenuPlus: false,
    openMenuPlusAnimal: false,
    openMenuLanguage: false,
    openMenuHelp: false,
    openMenuUser: false,
    groupSelected: null,
    farmSelected: null,
    farms: [],
    notificationLoading: false,
    languageSelected: {
      id: "pt_br",
      name: "PT",
      icon: <BrazilActiveFlag />,
      iconBlank: <BrazilBlankFlag />,
    },
    languages: [
      {
        id: "pt_br",
        name: "PT",
        icon: <BrazilActiveFlag />,
        iconBlank: <BrazilBlankFlag />,
      },
      {
        id: "en",
        name: "EN",
        icon: <EnglishActiveFlag />,
        iconBlank: <EnglishBlankFlag />,
      },
      {
        id: "es",
        name: "SP",
        icon: <SpanishActiveFlag />,
        iconBlank: <SpanishBlankFlag />,
      },
    ],
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    });
  };

  getUserGroupsAndFarmsDescriptions = async () => {
    const {
      app: { user },
      userActions,
    } = this.props;

    if (Object.entries(user).length > 0) {
      userActions.indexUserGroupsAndFarmsDescriptions(user.id);
    }
  };

  selectGroup = (group) => {
    const {
      appActions,
      accountFarmActions,
      app: {
        user,
        groupSelected: { id: groupId },
      },
      history,
    } = this.props;
    this.setState({
      groupSelected: group,
      farms: group.farms,
      openMenuGroup: !this.state.openMenuGroup,
    });

    if (group?.id !== groupId) {
      appActions.resetAllDatas();
      appActions.setGroupSelected(group);

      if (user.isInputSupplier) {
        history.push("/supplier");
      } else {
        history.push("/admin");
      }

      if (group.farms.length === 1) {
        const farm = group.farms[0];
        appActions.setFarmSelected(farm);
        appActions.getAllRoles(
          user.id,
          group.id,
          farm.id,
          user.isInputSupplier
        );
        accountFarmActions.checkAccountFarm(group.id, farm.id);
        this.setState({
          farmSelected: farm,
        });
      }
    }
  };

  selectFarm = async (farm) => {
    const {
      appActions,
      accountFarmActions,
      app: {
        user,
        groupSelected,
        farmSelected: { id: farmId },
      },
      history,
    } = this.props;
    this.setState({
      farmSelected: farm,
      openMenuFarm: !this.state.openMenuFarm,
    });
    if (farm.id !== farmId) {
      appActions.setFarmSelected(farm);
      appActions.getAllRoles(
        user.id,
        groupSelected.id,
        farm.id,
        user.isInputSupplier
      );
      if (user.isInputSupplier) {
        history.push("/supplier");
      } else {
        accountFarmActions.checkAccountFarm(groupSelected.id, farm.id);
        history.push("/admin");
      }
    }
  };

  selectLanguage = async (l) => {
    const { appActions } = this.props;
    await loadLang(l.id).then((translation) =>
      appActions.changeLanguage(l.id, translation)
    );
    this.setState({
      languageSelected: {
        id: l.id,
        name: l.name,
        icon: l.icon,
      },
      openMenuLanguage: !this.state.openMenuLanguage,
    });
  };

  getLanguageName = (id) => {
    const {
      app: { translation },
    } = this.props;
    switch (id) {
      case "pt_br":
        return translation.topbar.language.submenus.pt_br;
      case "en":
        return translation.topbar.language.submenus.en;
      case "es":
        return translation.topbar.language.submenus.es;
      default:
        return null;
    }
  };

  openMenuMethod = (menu) => {
    const {
      openMenuFarm,
      openMenuGroup,
      openMenuNotification,
      openMenuPlus,
      openMenuPlusAnimal,
      openMenuLanguage,
      openMenuUser,
      openMenuHelp,
    } = this.state;

    switch (menu) {
      case "farmMenu":
        this.setState({
          openMenuFarm: !openMenuFarm,
          openMenuGroup: false,
          openMenuNotification: false,
          openMenuPlus: false,
          openMenuPlusAnimal: false,
          openMenuLanguage: false,
          openMenuHelp: false,
          openMenuUser: false,
        });
        break;
      case "groupMenu":
        if (!openMenuGroup) {
          this.getUserGroupsAndFarmsDescriptions();
        }
        this.setState({
          openMenuGroup: !openMenuGroup,
          openMenuFarm: false,
          openMenuNotification: false,
          openMenuPlus: false,
          openMenuPlusAnimal: false,
          openMenuLanguage: false,
          openMenuHelp: false,
          openMenuUser: false,
        });
        break;
      case "notificationMenu":
        this.setState({
          openMenuFarm: false,
          openMenuGroup: false,
          openMenuNotification: !openMenuNotification,
          openMenuPlus: false,
          openMenuPlusAnimal: false,
          openMenuLanguage: false,
          openMenuHelp: false,
          openMenuUser: false,
        });
        break;
      case "plusMenu":
        this.setState({
          openMenuFarm: false,
          openMenuGroup: false,
          openMenuNotification: false,
          openMenuPlus: !openMenuPlus,
          openMenuPlusAnimal: false,
          openMenuLanguage: false,
          openMenuHelp: false,
          openMenuUser: false,
        });
        break;
      case "plusMenuAnimal":
        this.setState({
          openMenuFarm: false,
          openMenuGroup: false,
          openMenuNotification: false,
          openMenuPlus: true,
          openMenuPlusAnimal: !openMenuPlusAnimal,
          openMenuLanguage: false,
          openMenuUser: false,
          openMenuHelp: false,
        });
        break;
      case "languageMenu":
        this.setState({
          openMenuFarm: false,
          openMenuGroup: false,
          openMenuNotification: false,
          openMenuPlus: false,
          openMenuPlusAnimal: false,
          openMenuLanguage: !openMenuLanguage,
          openMenuHelp: false,
          openMenuUser: false,
        });
        break;
      case "userMenu":
        this.setState({
          openMenuFarm: false,
          openMenuGroup: false,
          openMenuNotification: false,
          openMenuPlus: false,
          openMenuPlusAnimal: false,
          openMenuLanguage: false,
          openMenuHelp: false,
          openMenuUser: !openMenuUser,
        });
        break;
      case "helpMenu":
        this.setState({
          openMenuFarm: false,
          openMenuGroup: false,
          openMenuNotification: false,
          openMenuPlus: false,
          openMenuPlusAnimal: false,
          openMenuLanguage: false,
          openMenuUser: false,
          openMenuHelp: !openMenuHelp,
        });
        break;
      default:
        break;
    }
  };

  getFirstUserName = () => {
    const {
      app: { user },
    } = this.props;

    if (user.fullName.indexOf(" ") >= 0) {
      let initials = user.fullName.split(" ");
      return initials[0];
    } else {
      return user.fullName;
    }
  };

  downloadBovManager = async () => {
    this.setState({
      isDownloadBovManager: true,
    });
    notification.info({
      message:
        "Aguarde, o BovManager está sendo carregado, e logo será feito o download.",
      duration: 20,
    });
    this.openMenuMethod("plusMenu");
    try {
      const { data } = await downloadBovManagerService();
      const href = URL.createObjectURL(data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "BovManagerSetup.zip"); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      this.setState({
        isDownloadBovManager: false,
      });

      notification.success({
        message:
          "BovManager carregado! Aguarde até finalizar de abaixar para o seu computador.",
      });
    } catch (error) {
      notification.error({
        message: "Erro ao fazer download do BovManager",
      });
      this.setState({
        isDownloadBovManager: false,
      });
    }
  };

  openModal = (modal) => {
    this.setState({
      openMenuGroup: false,
      openMenuFarm: false,
      openMenuNotification: false,
      openMenuPlus: false,
      openMenuPlusAnimal: false,
      openMenuLanguage: false,
      openMenuUser: false,
      openMenuHelp: false,
    });
    const { appActions } = this.props;
    switch (modal) {
      case "changeGroupAndFarm":
        appActions.showModalGroupAndFarm();
        break;
      case "animalWeightUpload":
        appActions.showModalAnimalWeightUpload();
        break;
      case "animalUpload":
        appActions.showModalAnimalUpload();
        break;
      case "supplementUpload":
        appActions.showModalSupplementUpload();
        break;
      case "financialTransactionUpload":
        appActions.showModalFinancialTransactionUpload();
        break;
      case "contactUs":
        appActions.showModalContactUs();
        break;
      case "systemAbout":
        appActions.showOrHideSystemAboutModal();
        break;
      default:
        break;
    }
  };

  openDrawer = (drawer) => {
    const {
      lotActions,
      picketActions,
      profitCenterActions,
      userActions,
      animalActions,
    } = this.props;
    this.setState({
      openMenuGroup: false,
      openMenuFarm: false,
      openMenuNotification: false,
      openMenuPlus: false,
      openMenuPlusAnimal: false,
      openMenuLanguage: false,
      openMenuUser: false,
      openMenuHelp: false,
    });
    switch (drawer) {
      case "animal":
        animalActions.showDrawerNewAnimal();
        break;
      case "profitCenter":
        profitCenterActions.showDrawer("new");
        break;
      case "picket":
        picketActions.showDrawer("new");
        break;
      case "newLotAnimalsReceipt":
        lotActions.showLotAnimalsReceipt();
        break;
      case "lotProduction":
        lotActions.showDrawerLotProduction();
        break;
      case "editProfile":
        userActions.showDrawerEditProfile();
        break;
      case "changePassword":
        userActions.showDrawerChangePassword();
        break;
      default:
        break;
    }
  };

  handleClickOutside = (evt) => {
    this.setState({
      openMenuGroup: false,
      openMenuFarm: false,
      openMenuNotification: false,
      openMenuPlus: false,
      openMenuLanguage: false,
      openMenuUser: false,
      openMenuHelp: false,
    });
  };

  handleLogout = () => {
    const { appActions, history } = this.props;
    appActions.logout_user(history);
    // history.push("/login");
    window.location.href = "/login";
  };

  handleVisualizeNotification = async (id) => {
    const {
      appActions,
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;
    try {
      await visualizeOneNotification({
        groupId,
        farmId,
        id,
      });

      const {
        data: { results: notifications },
      } = await getNotificationIndex({
        groupId,
        farmId,
        withoutPagination: false,
        sorter: {},
        filters: "",
        page: 0,
        size: 10,
        justNotRead: true,
      });
      const newNot =
        notifications != null && notifications?.content.length > 0
          ? notifications?.content.map((n) => {
              n.createdAt = moment(n.createdAt)
                .subtract(3, "hours")
                .format("DD/MM/YYYY - HH:mm");
              return n;
            })
          : [];
      appActions.setNotifications({
        content: newNot,
        totalElements: notifications?.totalElements,
      });
    } catch (error) {}
  };

  handleDisplayDrawerNotifications = () => {
    const { appActions } = this.props;
    this.openDrawer();
    appActions.showHideDrawerNotifications();
  };

  render() {
    const {
      openMenuGroup,
      openMenuFarm,
      openMenuNotification,
      openMenuPlus,
      openMenuLanguage,
      openMenuUser,
      openMenuHelp,
      farms,
      languageSelected,
      languages,
      screenWidth,
      isDownloadBovManager,
    } = this.state;

    const {
      user: { userGroups },
      app: {
        user,
        translation,
        notifications,
        groupSelected,
        groupSelected: { farms: farmsFromProps },
        collapse_menu_admin: bCollapse,
        farmSelected,
      },
    } = this.props;
    const groups = Object.entries(userGroups).length > 0 ? userGroups : [];

    const ListGroups = groups.map((group) => (
      <li
        className={groupSelected.id === group.id ? "active" : null}
        key={group.id}
      >
        <button
          type="button"
          className={groupSelected.id === group.id ? "active" : null}
          onClick={() => this.selectGroup(group)}
        >
          <div>
            <span className="title">{group.name}</span>
            <span
              className={
                group.farms.length === 0 ? "subtitle disable" : "subtitle"
              }
            >
              {`${group.farms.length} ${
                user.isInputSupplier
                  ? "Empresas"
                  : translation.topbar.group.submenus.farm
              }`}
            </span>
          </div>
          {group.farms.length > 0 ? (
            <span />
          ) : (
            <Link to="/admin/farms">
              <span>
                {translation.topbar.group.submenus.add_farm}
                <PlusPurpleIcon />
              </span>
            </Link>
          )}
        </button>
      </li>
    ));

    const ListFarms = () => {
      const aFarms =
        farmsFromProps != null
          ? farmsFromProps.length > 0
            ? farmsFromProps
            : farms
          : farms;
      return aFarms.map((farm) => (
        <li
          className={farmSelected.id === farm.id ? "active" : null}
          key={farm.id}
        >
          <button
            type="button"
            className={farmSelected.id === farm.id ? "active" : null}
            onClick={() => this.selectFarm(farm)}
          >
            <div>
              <span className="title">{farm.name}</span>
            </div>
          </button>
        </li>
      ));
    };

    const ListNotifications = () => {
      if (notifications != null && notifications.content.length > 0) {
        return (
          <NotificationContainerItem>
            {notifications.content.length > 0 ? (
              notifications.content.map((notification) => (
                <NotificationItem
                  className="notRead"
                  key={notification.id}
                  onClick={() =>
                    this.handleVisualizeNotification(notification.id)
                  }
                >
                  <Row type="flex" justify="start">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} align="left">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: notification.message,
                        }}
                      />
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between" align="middle">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} align="left">
                      <span className="iconContainer">
                        <span
                          className="icon"
                          dangerouslySetInnerHTML={{
                            __html: notification.icon.iconSmall,
                          }}
                        />
                        <NotificationType notificationType={notification.type}>
                          {notification.type === "NewObject"
                            ? translation.notifications.types.newObject
                            : notification.type === "Alert"
                            ? translation.notifications.types.alert
                            : notification.type === "Warning"
                            ? translation.notifications.types.warning
                            : notification.type === "Purchase"
                            ? translation.notifications.types.purchase
                            : notification.type === "Sale" &&
                              translation.notifications.types.sale}
                        </NotificationType>
                      </span>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                      <span className="time">{notification.createdAt}</span>
                    </Col>
                  </Row>
                </NotificationItem>
              ))
            ) : (
              <Empty />
            )}
          </NotificationContainerItem>
        );
      } else {
        return <Empty />;
      }
    };

    const renderPlusMenuInputSupplier = () => {
      return (
        <AllowedComponentTo roles={["Trial"]} reverse hide>
          <li>
            <button onClick={() => this.openModal("supplementUpload")}>
              {translation.topbar.add.submenus.importSupplements}
            </button>
          </li>
        </AllowedComponentTo>
      );
    };

    return (
      <Wrapper>
        <Container>
          {screenWidth >= 800 ? (
            <>
              <GroupMenu>
                <span>{`${translation.topbar.group.title}:`}</span>
                <button
                  type="button"
                  className={openMenuGroup ? "active" : ""}
                  onClick={() => this.openMenuMethod("groupMenu")}
                >
                  <span>
                    {Object.entries(groupSelected).length > 0 &&
                    groupSelected.name != null
                      ? `${
                          groupSelected.name.length > 20
                            ? `${groupSelected.name.substring(0, 20)}...`
                            : groupSelected.name
                        }`
                      : translation.topbar.group.subtitle}
                  </span>
                  <div>
                    <ArrowDownWhite />
                  </div>
                </button>
                <GroupDropDownMenu className={openMenuGroup ? "active" : ""}>
                  <li>
                    <button type="button">
                      <GroupIcon />
                      Grupos
                    </button>
                  </li>
                  {ListGroups}
                </GroupDropDownMenu>
              </GroupMenu>
              <li className="divider" />
              <FarmMenu>
                <button
                  type="button"
                  className={
                    openMenuFarm
                      ? "active"
                      : !openMenuFarm &&
                        (farmSelected == null ||
                          Object.entries(farmSelected).length === 0)
                      ? "animate"
                      : ""
                  }
                  onClick={() => this.openMenuMethod("farmMenu")}
                >
                  <span>
                    {Object.entries(farmSelected).length > 0 &&
                    farmSelected.name != null
                      ? `${
                          farmSelected.name.length > 30
                            ? `${farmSelected.name.substring(0, 30)}...`
                            : farmSelected.name
                        }`
                      : `Selecione uma ${
                          !user.isInputSupplier ? "Fazenda" : "Empresa"
                        }`}
                  </span>
                  <div>
                    <ArrowDownWhite />
                  </div>
                </button>
                <FarmDropDownMenu
                  className={openMenuFarm ? "active moz" : "moz"}
                >
                  <li>
                    <button type="button">
                      {!user.isInputSupplier ? (
                        <>
                          <FarmIcon />
                          Fazendas
                        </>
                      ) : (
                        <>Empresas</>
                      )}
                    </button>
                  </li>
                  {ListFarms()}
                </FarmDropDownMenu>
              </FarmMenu>
            </>
          ) : (
            <li onClick={() => this.openModal("changeGroupAndFarm")}>
              <button type="button" className="change-grupo-farm">
                <Icon type="interaction" />
              </button>
            </li>
          )}
          {/* SLIDER DATA AGRO */}
          <SliderDataAgro isCollapse={bCollapse} />
          {/* SEARCH MENU */}
          <SearchMenu>
            {/* ADD BUTTON MENU */}
            <SearchButton onClick={() => this.openMenuMethod("plusMenu")}>
              <AddButtonIcon />
            </SearchButton>
            <PlusMenuOptions
              openMenuPlus={openMenuPlus}
              user={user}
              renderPlusMenuInputSupplier={renderPlusMenuInputSupplier}
              openDrawer={this.openDrawer}
              openModal={this.openModal}
              isDownloadBovManager={isDownloadBovManager}
              downloadBovManager={this.downloadBovManager}
            />
          </SearchMenu>
          {/* NOTIFICATION MENU */}
          <NotificationMenu>
            <button
              className={openMenuNotification ? "active" : ""}
              onClick={() => this.openMenuMethod("notificationMenu")}
            >
              <NotificationIcon />
              {notifications != null && notifications.content.length > 0 ? (
                <NotificationBadge>
                  {notifications.totalElements}
                </NotificationBadge>
              ) : null}
            </button>
            <NotificationDropDownMenu
              className={openMenuNotification ? "active" : ""}
            >
              {ListNotifications()}
              <NotificationItem className="footer">
                <button
                  type="button"
                  onClick={this.handleDisplayDrawerNotifications}
                >
                  <span>{translation.topbar.notification.footer}</span>
                </button>
              </NotificationItem>
            </NotificationDropDownMenu>
          </NotificationMenu>
          <li className="divider" />
          {/* LANGUAGE MENU */}
          {screenWidth > 800 && (
            <LanguageMenu>
              <button onClick={() => this.openMenuMethod("languageMenu")}>
                {languageSelected.icon}
              </button>
              <LanguageDropDownMenu
                className={openMenuLanguage ? "active" : ""}
              >
                {languages.map((language) => (
                  <li key={language.id}>
                    <button onClick={() => this.selectLanguage(language)}>
                      {languageSelected.id === language.id
                        ? language.icon
                        : language.iconBlank}
                      {this.getLanguageName(language.id)}
                    </button>
                  </li>
                ))}
                <li>
                  <div>
                    <span className="title">
                      {translation.topbar.language.title}
                    </span>
                    <TranslatorIcon />
                  </div>
                </li>
              </LanguageDropDownMenu>
            </LanguageMenu>
          )}
          {/* USER MENU */}
          <UserMenu>
            {screenWidth > 800 ? (
              <button
                className={openMenuUser ? "principal active" : "principal"}
                onClick={() => this.openMenuMethod("userMenu")}
              >
                Olá,
                <span className="user_name">{this.getFirstUserName()}</span>
                {!!user.avatar ? (
                  <img
                    src={user.avatar}
                    alt="Avatar"
                    className="topUserImage"
                  />
                ) : (
                  <AvatarUser />
                )}
              </button>
            ) : (
              <button
                className={openMenuUser ? "principal active" : "principal"}
                onClick={() => this.openMenuMethod("userMenu")}
              >
                {!!user.avatar ? (
                  <img
                    src={user.avatar}
                    alt="Avatar"
                    className="topUserImage"
                  />
                ) : (
                  <AvatarUser />
                )}
              </button>
            )}
            <UserDropDownMenu className={openMenuUser ? "active" : ""}>
              <div className="userInfo">
                <div className="avatar">
                  {!!user.avatar ? (
                    <img src={user.avatar} alt="Avatar" />
                  ) : (
                    <AvatarUser />
                  )}
                </div>
                <span>{user.fullName}</span>
                <span className="email">{user.email}</span>
              </div>
              {!user.isInputSupplier && (
                <div className="icons">
                  <Tooltip
                    placement="top"
                    title={translation.topbar.user.submenus.change_password}
                    trigger="hover"
                  >
                    <div className="icon">
                      <button onClick={() => this.openDrawer("changePassword")}>
                        <Icon type="key" />
                      </button>
                    </div>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title={translation.topbar.user.submenus.edit_profile}
                    trigger="hover"
                  >
                    <div className="icon">
                      <button onClick={() => this.openDrawer("editProfile")}>
                        <Icon type="edit" />
                      </button>
                    </div>
                  </Tooltip>
                </div>
              )}
              <ul>
                <li>
                  <Icon type="logout" />
                  <button onClick={() => this.handleLogout()}>
                    {translation.topbar.user.submenus.signout_application}
                  </button>
                </li>
              </ul>
            </UserDropDownMenu>
          </UserMenu>
        </Container>
      </Wrapper>
    );
  }
}
const mapStateToProps = (state) => ({
  app: state.app,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  animalActions: bindActionCreators(AnimalActions, dispatch),
  userActions: bindActionCreators(UserActions, dispatch),
  picketActions: bindActionCreators(PicketActions, dispatch),
  lotActions: bindActionCreators(LotActions, dispatch),
  profitCenterActions: bindActionCreators(ProfitCenterActions, dispatch),
  accountFarmActions: bindActionCreators(AccountFarmActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(onClickOutside(TopBar))
);
