import { useContext } from "react";

import { FinancialTransactionDashboardContext } from "../../contexts/financialTransactionDashboardContext";

const useFinancialTransactionDashboardContext = () => {
  const value = useContext(FinancialTransactionDashboardContext);

  return value;
};

export default useFinancialTransactionDashboardContext;
