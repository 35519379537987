import React, { useCallback, useEffect, useState } from "react";
import { useConfinementMapContext } from "../../../hooks/useConfinementMapContext";

import {
  Col,
  Icon,
  Input,
  Modal,
  Row,
  Table,
  Typography,
  notification,
} from "antd";
import NumberFormat from "react-number-format";
import ButtonStandard from "../../utils/button";
import { Circle } from "./styles";

const ConfinementMapParametersModal = () => {
  const [form, setForm] = useState([]);

  const {
    isModalParametersVisible,
    showOrHideModalParameters,
    confinementMapParameters,
    updateConfinementParameters,
  } = useConfinementMapContext();

  const handleInputValue = useCallback((field, index, value) => {
    setForm((prevState) =>
      prevState.map((state, i) => {
        if (i === index) {
          state[field] = value;
        }
        return state;
      })
    );
  }, []);

  const restoreToDefault = useCallback(async () => {
    setForm([
      { ...confinementMapParameters[0], min: 1, max: 75, color: "green" },
      { ...confinementMapParameters[1], min: 76, max: 100, color: "yellow" },
      { ...confinementMapParameters[2], min: 101, max: null, color: "red" },
    ]);
  }, [confinementMapParameters]);

  const handleSubmit = useCallback(() => {
    const minValues = form.map((item) => item.min);
    const maxValues = form.map((item) => item.max);
    if (minValues[1] > minValues[2]) {
      notification.error({
        message: "Existem valores minimos fora de ordem.",
      });
      return;
    } else if (maxValues[0] > maxValues[1]) {
      notification.error({
        message: "Existem valores máximos fora de ordem.",
      });
      return;
    } else if (
      form.filter(
        (item, i) =>
          (i !== 2 && (item.min == null || item.max == null)) ||
          (i === 2 && item.min == null)
      ).length > 0
    ) {
      notification.error({
        message: "Existem valores minimos ou máximos vazios",
      });
      return;
    } else if (form[0].min > 1) {
      notification.error({
        message: "O valor mínimo do campo verde deve começar com 1.",
      });
      return;
    }

    updateConfinementParameters(form);
    showOrHideModalParameters();
  }, [form]);

  useEffect(() => {
    const parameters = confinementMapParameters.sort((a, b) => a.min - b.min);
    setForm(parameters);
  }, [confinementMapParameters]);

  return (
    <Modal
      visible={isModalParametersVisible}
      width={700}
      centered
      closable
      onCancel={() => showOrHideModalParameters()}
      footer={null}
    >
      <Row type="flex">
        <Typography.Title level={4}>
          Editar Parâmetros: Mapa do Confinamento
        </Typography.Title>
      </Row>
      <Row type="flex" justify="end">
        <ButtonStandard buttonType="secondary" onClick={restoreToDefault}>
          <Icon type="rollback" />
          Restaurar ao padrão do sistema
        </ButtonStandard>
      </Row>
      <Row
        type="flex"
        justify="center"
        style={{ width: "100%", marginTop: 16 }}
        gutter={16}
      >
        <Col span={8} style={{ textAlign: "center" }}>
          <strong>Mínimo</strong>
        </Col>
        <Col span={8} style={{ textAlign: "center" }}>
          <strong>Máximo</strong>
        </Col>
        <Col span={8} style={{ textAlign: "center" }}>
          <strong>Cor</strong>
        </Col>
      </Row>
      {form.map((formItem, index) => (
        <Row
          key={formItem.color}
          type="flex"
          justify="center"
          style={{
            width: "100%",
            paddingTop: 8,
            paddingBottom: 8,
            borderBottom: index !== 2 ? "1px solid #f4f4f4" : "none",
          }}
          gutter={16}
        >
          <Col span={8}>
            <NumberFormat
              value={formItem.min}
              disabled={index === 0}
              customInput={Input}
              decimalScale={0}
              addonAfter="Dias"
              allowNegative={false}
              onValueChange={({ floatValue }) => {
                handleInputValue("min", index, floatValue);
                if (
                  index > 0 &&
                  (form[index - 1].max > floatValue ||
                    form[index - 1].max < floatValue - 1)
                )
                  handleInputValue("max", index - 1, floatValue - 1);
              }}
            />
          </Col>
          <Col span={8}>
            {index !== 2 && (
              <NumberFormat
                value={formItem.max}
                customInput={Input}
                decimalScale={0}
                addonAfter="Dias"
                allowNegative={false}
                onValueChange={({ floatValue }) => {
                  handleInputValue("max", index, floatValue);
                  handleInputValue("min", index + 1, floatValue + 1);
                }}
              />
            )}
          </Col>
          <Col
            span={8}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Circle className={formItem.color} />
          </Col>
        </Row>
      ))}
      <Row type="flex" justify="center" style={{ marginTop: 16 }}>
        <ButtonStandard
          type="button"
          buttonType="type1"
          size="m"
          onClick={handleSubmit}
        >
          Confirmar
        </ButtonStandard>
      </Row>
    </Modal>
  );
};

export default ConfinementMapParametersModal;
