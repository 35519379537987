import styled from "styled-components";
import { Select } from "antd";

export const Container = styled.div`
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
  padding-bottom: 10px !important;

  div.pageHeader {
    padding-top: 24px;
    padding-right: 5px;
  }

  div.pageTree {
    /* margin-bottom: 22px !important; */
    /* margin-top: 9px;
    margin-bottom: 9px; */
  }

  span.backIcon {
    vertical-align: middle;
  }

  span.pageTreeInative {
    color: #c4c4c4;
    font-size: 16px;
  }
  span.pageTreeActive {
    color: #684e94;
    font-size: 16px;
  }

  .buttonsCol {
    button {
      &:first-of-type {
        margin-right: 19px;
      }
    }
  }

  .pageBody {
    padding: 20px 5px 0px 5px;
  }

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const Title = styled.span`
  font-family: "Asap", sans-serif;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  color: #4b4b4b;

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const SubTitle = styled.span`
  margin-left: 28px;
  height: 22px;
  border-left: ${props =>
    props.borderLeftColor
      ? `3px solid ${props.borderLeftColor}`
      : "3px solid #fe8d2a"};
  padding-left: 8px;
  font-family: "Asap", sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  color: #4b4b4b;

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const SubTitleItalic = styled.span`
  font-family: Asap;
  font-weight: normal;
  font-style: italic;
  font-size: 14px;
  text-align: left;
  color: #d44c4c;

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const DescriptionMessage = styled.span`
  font-family: "Asap", sans-serif;
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 18px;
  text-align: left;
  color: #4b4b4b;

  svg {
    margin-left: 6px;
  }

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const CustomSelect = styled(Select)`
  &.ant-select.ant-select-enabled {
    .ant-select-selection {
      border: 1px solid #684e94 !important;
      color: #684e94 !important;
    }
    .ant-select-arrow {
      color: #684e94 !important;
    }
    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
      color: #684e94 !important;
    }
  }
`;

export const ColSelectAnimals = styled.div`
  height: auto;
  /* min-height: 562px; */
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  background: #fff;
  box-shadow: 0px 0px 10px #d8d8d8;
  .ant-table-pagination.ant-pagination {
    margin-right: 10px;
  }
  .header {
    width: 100%;
    height: 60px;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;

    span.listAnimals {
      font-family: Asap;
      font-weight: bold;
      font-size: 18px;
      text-align: left;
      color: #4b4b4b;
    }
    .ant-radio-button-wrapper {
      color: #684e94 !important;
      background: #fff;
      border-color: #684e94 !important;
      box-shadow: -1px 0 0 0 #684e94 !important;
      font-size: 14px;
      font-weight: bold;
    }
    .ant-radio-button-wrapper-checked {
      color: #fff !important;
      background: #684e94;
      border-color: #684e94 !important;
      box-shadow: -1px 0 0 0 #684e94 !important;
      font-size: 14px;
      font-weight: bold;
    }
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const ColButtons = styled.div`
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    width: 40px;
    height: 40px;
    background: #c4c4c4;
    border: none;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:first-of-type {
      margin-bottom: 16px;
    }
    &:hover {
      cursor: pointer;
    }
    &:disabled {
      cursor: not-allowed;
    }
    &.active {
      background: #684e94;
    }
    i {
      color: #fff !important;
      font-weight: bold;
    }
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const ColAnimalsSelected = styled.div`
  height: 496px;
  min-height: 496px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  display: flex;
  border-radius: 7px;
  background: #fff;
  box-shadow: 0px 0px 10px #d8d8d8;

  .header {
    width: 100%;
    height: 40px;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    background: #FE8D2A
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-bottom: 2px solid #C4C4C4;

    .ant-checkbox .ant-checkbox-inner {
      background: transparent;
    }

    span {
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      font-family: 'Asap', sans-serif;

    }
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: Asap, sans-serif !important;
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  span {
    font-family: Asap, sans-serif;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;
    margin-left: 6px;
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;

  button {
    margin-right: 14px;
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;
