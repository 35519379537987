import { createGlobalStyle } from "styled-components";

import "font-awesome/css/font-awesome.min.css";

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
    }
    html, body, #root {
      height: 100%;
      min-height: 100%;
      margin: 0;
      background: #ffffff !important;
      text-rendering: optimizeLegibility !important;
      -webkit-font-smoothing: antialiased !important;
      font-family: 'Asap', sans-serif;
      overflow-y: hidden;
      overflow-x: hidden;
    }

    #root {
      display: flex;
      flex: 1;
    }

`;

export default GlobalStyle;
