import styled from "styled-components";

export const Container = styled.div`
  width: 100% !important;
  min-width: 100% !important;
  height: 100% !important;
  min-height: 100% !important;
  border-radius: 5px;
  background: #f5f5f5;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 21px 17px;
  margin-top: 10px;

  .ant-row-flex.rowInfo {
    margin-top: 4px;

    &:last-child {
      margin-top: 0px;
    }
  }

  .monthName {
    font-family: Asap;
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    color: #4b4b4b;
    text-transform: uppercase;
  }

  .monthDays {
    align-self: flex-end;
    font-family: Asap;
    font-weight: normal;
    font-size: 10px;
    text-align: right;
    color: #a5a5a5;
  }
`;
