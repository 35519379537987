export function handleKeyDownDatePicker(e) {
  switch (e.key) {
    case "Backspace":
    case "Delete":
    case "Escape":
    case "Enter":
    case "Tab":
    case "ArrowLeft":
    case "ArrowRight":
    case "ArrowUp":
    case "ArrowDown":
    case "Shift":
    case "0":
    case "1":
    case "2":
    case "3":
    case "4":
    case "5":
    case "6":
    case "7":
    case "8":
    case "9":
    case "/":
    case "-":
      return e;
    default:
      return e.preventDefault();
  }
}

export function handleKeyDownInputNumber(e) {
  switch (e.key) {
    case "Backspace":
    case "Delete":
    case "Escape":
    case "Enter":
    case "Tab":
    case "ArrowLeft":
    case "ArrowRight":
    case "ArrowUp":
    case "ArrowDown":
    case "Shift":
    case "0":
    case "1":
    case "2":
    case "3":
    case "4":
    case "5":
    case "6":
    case "7":
    case "8":
    case "9":
    case "-":
    case ",":
    case ".":
      return e;
    default:
      return e.preventDefault();
  }
}
