import apiFinancial from "../config/api_financial";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiFinancial = axios.create({
//   baseURL: "http://localhost:8086",
// });

function generateFinancialCosting(payload) {
  const { groupId, farmId, currency, financialProjectId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/financial/costing?currency=${currency}${
      financialProjectId ? `?financialProjectId=${financialProjectId}` : ""
    }`,
    getHeaders(false, signal)
  );
}
export { generateFinancialCosting };
