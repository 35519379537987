import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { Creators as AnimalActions } from "../../../../store/ducks/animal";
import { Creators as SupplierActions } from "../../../../store/ducks/supplier";
import moment from "moment";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Row,
  Col,
  Select,
  Input,
  Radio,
  DatePicker,
  notification,
  Spin,
  Icon,
  Checkbox,
  Button,
  Modal,
} from "antd";
import {
  Container,
  Footer,
  Body,
  AnimalCard,
  CustomDivider,
  DeathButton,
  DeathAnimalCard,
  GeneticBox,
  PopConfirmCustom,
  DiscardAnimalCard,
} from "./styles";

// Components
import ButtonStandard from "../../../utils/button";
import BullAvatarIcon from "./icons/bullAvatar";
import StopIcon from "../../../utils/icons/stop";
import AnimalDeath from "../animalDeath";
import InfoTooltip from "../../../utils/infoTooltip";
import RadioButton from "../../../utils/radioButton";
import DrawerSupplier from "../../supplier";

// Services
import {
  getBreedIndexActive,
  getBreedParametersShow,
} from "../../../../services/breedService";
import {
  getAnimalById,
  getAnimalDropdownListByGender,
  saveAnimal,
  verifyIfHaveOtherAnimalsWithSameHandlingNumber,
  verifyLastAnimal,
  verifyLastAnimalMovementEvent,
} from "../../../../services/animalService";
import { getSupplierShow } from "../../../../services/supplierService";
import { getLotIndex } from "../../../../services/lotService";
import AnimalDiscard from "../animalDiscard";
import { findAll } from "../../../../services/semenService";
import { findAll as findAllMultipleBull } from "../../../../services/multipleBullService";
import { findAllFarmOwners } from "../../../../services/farmOwnerService";
import { getUnknownBullInfo } from "../../../../services/unknownBullService";

class DrawerEditAnimal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerAnimalDeathVisible: false,
      drawerAnimalDiscardVisible: false,
      lastAnimal: false,
      animalData: {
        id: null,
        supplierId: null,
        handlingNumber: null,
        sisbov: null,
        tagId: null,
        nickname: null,
        breedId: null,
        birthday: null,
        gender: null,
        cap: null,
        birthdayWeight: "",
        firstInseminationWeight: "",
        asymptoticWeight: "",
        weaningWeight: "",
        weaningDate: null,
        acquisitionDate: null,
        purchaseCost: "",
        acquisitionEstimatedWeight: "",
        carcassHarnessing: "",
        motherId: null,
        motherHandlingNumber: null,
        motherSupplierId: null,
        fromInsermination: false,
        fatherSemenId: null,
        fatherId: null,
        fatherHandlingNumber: null,
        fatherSupplierId: null,
        status: null,
        farmStatus: null,
        animalFarmFunction: "fatten",
        animalReproductionCategory: null,
        mixedMilk: false,
        discard: false,
        discardDate: null,
        discardMotive: null,
        discardObservation: null,
        sisbovIdentificationDate: null,
        sisbovInsertBNDDate: null,
        sisbovSlaughterDate: null,
        farmOwnerId: null,
        fromBirth: false,
        observation: null,
        lotReceiptStatus: null,
        lotItemEntryId: null,
      },
      breeds: [],
      suppliers: [],
      semens: [],
      males: [],
      farmOwners: [],
      isLoading: true,
      isFarmAnimal: false,
      isFarmMotherAnimal: false,
      isFarmFatherAnimal: false,
      onlyReadSupplier: null,
      allowReceiptLot: false,
      isReceiptLotsLoading: true,
      receiptLots: [],
      initialAnimalFunction: null,
      initialAnimalGender: null,
    };
    this.formik = React.createRef();
  }

  openNotificationError = (title, message) => {
    notification["error"]({
      message: title,
      description: message,
    });
  };

  closeDrawer = (props) => {
    const { animalActions } = this.props;
    this.formik.current.resetForm({
      id: null,
      supplierId: null,
      handlingNumber: null,
      sisbov: null,
      tagId: null,
      nickname: null,
      breedId: null,
      birthday: null,
      gender: null,
      cap: null,
      birthdayWeight: "",
      firstInseminationWeight: "",
      asymptoticWeight: "",
      weaningWeight: "",
      weaningDate: null,
      acquisitionDate: null,
      purchaseCost: "",
      acquisitionEstimatedWeight: "",
      carcassHarnessing: "",
      motherId: null,
      motherHandlingNumber: null,
      motherSupplierId: null,
      fromInsermination: false,
      fatherSemenId: null,
      fatherId: null,
      fatherHandlingNumber: null,
      fatherSupplierId: null,
      animalFarmFunction: "fatten",
      animalReproductionCategory: null,
      status: "Active",
      farmStatus: "Active",
      mixedMilk: false,
      discard: false,
      discardDate: null,
      discardMotive: null,
      discardObservation: null,
      sisbovIdentificationDate: null,
      sisbovInsertBNDDate: null,
      sisbovSlaughterDate: null,
      farmOwnerId: null,
      fromBirth: false,
      observation: null,
      lotReceiptStatus: null,
      lotItemEntryId: null,
    });
    this.setState({
      animalData: {
        id: null,
        supplierId: null,
        handlingNumber: null,
        sisbov: null,
        tagId: null,
        nickname: null,
        breedId: null,
        birthday: null,
        gender: null,
        cap: null,
        birthdayWeight: "",
        firstInseminationWeight: "",
        asymptoticWeight: "",
        weaningWeight: "",
        weaningDate: null,
        acquisitionDate: null,
        purchaseCost: "",
        acquisitionEstimatedWeight: "",
        carcassHarnessing: "",
        motherId: null,
        motherHandlingNumber: null,
        motherSupplierId: null,
        fromInsermination: false,
        fatherSemenId: null,
        fatherId: null,
        fatherHandlingNumber: null,
        fatherSupplierId: null,
        animalFarmFunction: "fatten",
        animalReproductionCategory: null,
        status: "Active",
        farmStatus: "Active",
        mixedMilk: false,
        discard: false,
        discardDate: null,
        discardMotive: null,
        discardObservation: null,
        sisbovIdentificationDate: null,
        sisbovInsertBNDDate: null,
        sisbovSlaughterDate: null,
        farmOwnerId: null,
        fromBirth: false,
        observation: null,
        lotReceiptStatus: null,
        lotItemEntryId: null,
      },
      isFarmAnimal: false,
      isFarmMotherAnimal: false,
      isFarmFatherAnimal: false,
      isMixedMilk: false,
    });
    animalActions.hideDrawerEditAnimal();
  };

  confirmDeath = async (
    deathDate,
    deathDescription,
    props,
    deathReasonId,
    isFarmDeathReason
  ) => {
    props.setValues({
      ...props.values,
      finalDate: deathDate,
      deathDate: deathDate,
      deathObservation: deathDescription,
      deathReasonId,
      isFarmDeathReason,
    });

    this.setState({
      drawerAnimalDeathVisible: false,
    });
    const animal = {
      ...props.values,
      finalDate: deathDate,
      deathDate: deathDate,
      deathObservation: deathDescription,
      deathReasonId,
      isFarmDeathReason,
    };
    this.handleUpdateAnimal(animal, props.values?.id, props);
  };

  confirmDiscard = (discardDate, discardMotive, discardObservation, props) => {
    props.setFieldValue("discardObservation", discardObservation);
    props.setFieldValue("discardMotive", discardMotive);
    props.setFieldValue("discardDate", discardDate);
    props.setFieldValue("discard", true);
    props.setFieldValue("animalFarmFunction", "fatten");

    this.setState({
      drawerAnimalDiscardVisible: false,
    });

    const animal = {
      ...props.values,
      animalFarmFunction: "fatten",
      discard: true,
      discardDate,
      discardMotive,
      discardObservation,
    };

    this.handleUpdateAnimal(animal, props.values?.id, props);
  };

  fetchData = async (groupSelected, farmSelected) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;

    this.setState({ isLoading: true, isReceiptLotsLoading: true });

    try {
      this.getAnimalInfo(groupId, farmId);
      this.getBreeds(groupId, farmId);
      this.getSuppliers(groupId, farmId);
      this.getLotsReceipt(groupId, farmId);
      this.getSemenOptions(groupId, farmId);
      this.getFarmOwnersOptions(groupId, farmId);
    } catch (error) {}
  };

  validateGeneticGroup = (breedId) => {
    const { breeds } = this.state;
    return (
      breeds.filter(
        (breed) => breed.id === breedId && breed.geneticGroup === "Cruzado"
      ).length > 0
    );
  };

  getBreedParameter = async (id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;
    const { isFarmAnimal } = this.state;
    let isMixedMilk = false;
    this.setState({ isLoading: true });
    try {
      const {
        data: { results: breedParameter },
      } = await getBreedParametersShow({
        groupId,
        farmId,
        id,
      });
      if (breedParameter != null) {
        const { asymptoticWeight, maleBirthdayWeight } = breedParameter;
        this.formik.current.setFieldValue("asymptoticWeight", asymptoticWeight);
        this.formik.current.setFieldValue("birthdayWeight", maleBirthdayWeight);
        if (isFarmAnimal) {
          this.formik.current.setFieldValue(
            "acquisitionEstimatedWeight",
            maleBirthdayWeight
          );
        }
        isMixedMilk = breedParameter.geneticGroup === "Cruzado";
        if (!isMixedMilk) {
          this.formik.current.setFieldValue("mixedMilk", isMixedMilk);
        }
      }
    } catch (error) {
    } finally {
      this.setState({ isLoading: false, isMixedMilk });
    }
  };

  getAnimalInfo = async (groupId, farmId) => {
    const {
      animal: { animalEditId: animalId },
    } = this.props;
    try {
      const {
        data: { results: animalData },
      } = await getAnimalById({ groupId, farmId, animalId });
      if (animalData.sisbov !== null)
        animalData.sisbov = `${animalData.sisbov}`;
      if (animalData != null) {
        const isMixedMilk = this.validateGeneticGroup(animalData.breedId);

        const isFarmMotherAnimal = animalData?.motherId !== null;
        const isFarmFatherAnimal =
          animalData?.fatherId !== null &&
          animalData?.fromInsermination === false;

        this.setState({
          isFarmAnimal: animalData?.supplierId === null ? true : false,
          isFarmFatherAnimal,
          isFarmMotherAnimal,
          animalData,
          isMixedMilk,
          allowReceiptLot: animalData?.lotReceiptId !== null ? true : false,
          initialAnimalFunction: animalData?.animalFarmFunction,
          initialAnimalGender: animalData?.gender,
        });

        this.getMotherOptions(groupId, farmId, animalData?.motherId);
        this.getFatherOptions(groupId, farmId, animalData?.fatherId);
      }
    } catch (error) {
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  getBreeds = async (groupId, farmId) => {
    const {
      data: { results: breeds },
    } = await getBreedIndexActive({
      groupId,
      farmId,
      withoutBreedCurveParam: true,
    });
    this.setState({ breeds });
  };

  getSuppliers = async (groupId, farmId) => {
    const { supplierActions } = this.props;
    supplierActions.getDropdownSuppliers(groupId, farmId, "CattleFarmer", true);
  };

  getLotsReceipt = async (groupId, farmId) => {
    const {
      data: { results: listOfReceiptLots },
    } = await getLotIndex({
      groupId,
      farmId,
      page: null,
      sorter: { field: "createdAt", order: "descend" },
      filters: "is_receipt=true;status='A'",
    });
    this.setState({
      isReceiptLotsLoading: false,
      receiptLots: listOfReceiptLots?.content ? listOfReceiptLots.content : [],
    });
  };

  getMotherOptions = async (groupId, farmId, motherId = null) => {
    const { animalActions } = this.props;
    animalActions.indexAnimalMotherDropdown(groupId, farmId, motherId);
  };

  getFatherOptions = async (groupId, farmId, fatherId = null) => {
    let results = [];
    let resultAnimalsMale = [];
    let resultMultipleBulls = [];
    let resultUnknownBull = [];
    try {
      const {
        data: { results: animalMales },
      } = await getAnimalDropdownListByGender({
        groupId,
        farmId,
        gender: "M",
      });
      resultAnimalsMale = animalMales.filter(
        (a) =>
          (a.status === "A" || a.status === "Active") &&
          a.gender === "M" &&
          a.animalFarmFunction === "reproduction"
      );
    } catch (error) {
      console.error(error);
    }
    try {
      const {
        data: { results: multipleBulls },
      } = await findAllMultipleBull({ groupId, farmId });
      resultMultipleBulls = multipleBulls.filter(
        (mb) => mb.status === "Active"
      );
    } catch (error) {
      console.error(error);
    }
    if (fatherId != null) {
      try {
        const {
          data: { results: unknownBullsInfo },
        } = await getUnknownBullInfo({
          groupId,
          farmId,
          id: fatherId,
        });
        if (unknownBullsInfo) {
          resultUnknownBull.push(unknownBullsInfo);
        }
      } catch (error) {}
    }
    results = [
      ...resultAnimalsMale.map((am) => ({
        id: am.id,
        name: am.handlingNumber,
        gender: am.gender,
        animalFarmFunction: am.animalFarmFunction,
        birthday: am.birthday,
        isMultipleBull: false,
        isUnknownBull: false,
      })),
      ...resultMultipleBulls.map((mb) => ({
        id: mb.id,
        name: `${mb.name}(TM)`,
        gender: "M",
        isMultipleBull: true,
        isUnknownBull: false,
      })),
      ...resultUnknownBull.map((ub) => ({
        id: ub.id,
        name: `${ub.name}(TD)`,
        gender: "M",
        isMultipleBull: false,
        isUnknownBull: true,
      })),
    ];

    this.setState({
      males: results,
    });
  };

  getSemenOptions = async (groupId, farmId) => {
    try {
      const {
        data: { results },
      } = await findAll({
        groupId,
        farmId,
      });
      this.setState({
        semens: results,
      });
    } catch (error) {}
  };

  getFarmOwnersOptions = async (groupId, farmId) => {
    try {
      const {
        data: { results },
      } = await findAllFarmOwners({
        groupId,
        farmId,
      });

      //Update state
      this.setState({
        farmOwners: results,
      });
    } catch (error) {}
  };

  validateForm = (values) => {
    const {
      app: { translation },
    } = this.props;
    if (
      values.carcassHarnessing != null &&
      values.carcassHarnessing !== "" &&
      (values.carcassHarnessing < 45 || values.carcassHarnessing > 60)
    ) {
      this.openNotificationError(
        "Aproveitamento de Carcaça inválida",
        'O valor do campo "Aproveitamento de Carcaça" deve estar entre o intervalo de valores (45% e 60%)'
      );
      return false;
    } else if (
      values.sisbov != null &&
      values.sisbov.trim() !== "" &&
      values.sisbov.length !== 15
    ) {
      this.openNotificationError(
        translation.error.sisbovInvalidTitle,
        translation.error.sisbovInvalidMessage
      );
      return false;
    } else if (
      moment(values.acquisitionDate).isBefore(moment(values.birthday))
    ) {
      this.openNotificationError(
        "Datas inválidas",
        "A Data de Aquisição não pode ser antes da Data de Nascimento."
      );
      return false;
    } else if (
      values.weaningDate !== null &&
      moment(values.weaningDate).isBefore(moment(values.birthday))
    ) {
      this.openNotificationError(
        "Datas inválidas",
        "A Data de Desmame não pode ser antes da Data de Nascimento."
      );
      return false;
    } else if (
      values.weaningWeight &&
      values.birthdayWeight > values.weaningWeight
    ) {
      this.openNotificationError(
        "Pesos inválidos",
        "O Peso de Nascimento não pode ser maior do que o Peso de Desmame."
      );
      return false;
    } else {
      return true;
    }
  };

  handleSubmitModalForm = async (values, actions) => {
    if (this.validateForm(values)) {
      const {
        app: {
          groupSelected: { id: groupId },
          farmSelected: { id: farmId },
          translation,
        },
      } = this.props;

      this.setState({ isLoading: true });

      const {
        data: { results: haveOthersAnimalsWithHandlingNumber },
      } = await verifyIfHaveOtherAnimalsWithSameHandlingNumber({
        groupId,
        farmId,
        handlingNumber: values.handlingNumber,
        excludeAnimalId: values.id,
      });

      if (haveOthersAnimalsWithHandlingNumber === true) {
        Modal.warning({
          title: "Alerta",
          centered: true,
          content:
            "Existem animais ativos nesta propriedade que possuem essa mesma Identificação.",
        });
      }

      if (
        values.lotItemEntryId != null &&
        values.lotReceiptStatus === "pending"
      ) {
        Modal.confirm({
          centered: true,
          title: "Atenção! Você deseja identificar este animal?",
          width: 500,
          okText: translation.buttons.no,
          onOk: () => {
            this.handleUpdateAnimal(values, values.id, actions);
          },
          cancelText: translation.buttons.yes,
          onCancel: () => {
            values.lotReceiptStatus = "identified";
            this.handleUpdateAnimal(values, values.id, actions);
          },
        });
      } else {
        this.handleUpdateAnimal(values, values.id, actions);
      }
    }
  };

  handleUpdateAnimal = async (animal, id, actions) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
        translation,
      },
      animal: {
        pagination: {
          page,
          sorter,
          filters,
          size,
          ids,
          tableFilters,
          tableSorters,
        },
        paginationFemaleTable,
        paginationMaleTable,
      },
      animalActions,
    } = this.props;
    let animalClone = { ...animal };
    this.setState({ isLoading: true });

    const { initialAnimalFunction, initialAnimalGender } = this.state;

    try {
      if (animalClone.deathDate != null) {
        try {
          const {
            data: { results: lastMovement },
          } = await verifyLastAnimalMovementEvent({ groupId, farmId, id });

          if (
            lastMovement != null &&
            moment(animalClone.deathDate).isBefore(moment(lastMovement.date))
          ) {
            let message = (
              <>
                <p>
                  Animal possui evento de{" "}
                  <b>{translation.animalMovementEvents[lastMovement.type]}</b>{" "}
                  posterior à data de morte informada.
                </p>
                <p>Data: {moment(lastMovement.date).format("DD/MM/YYYY")}</p>
              </>
            );

            this.openNotificationError("Não é possível atualizar.", message);
            this.setState({ isLoading: false });
            return;
          }
        } catch (error) {}
      }

      await saveAnimal({
        groupId,
        farmId,
        id,
        animal: animalClone,
      });

      // Update table data
      if (animal.animalFarmFunction === "reproduction") {
        if (animal.gender === "M") {
          animalActions.indexAnimalMaleTable(
            groupId,
            farmId,
            paginationMaleTable.page,
            paginationMaleTable.sorter,
            paginationMaleTable.filters,
            paginationMaleTable.size,
            paginationMaleTable.ids,
            paginationMaleTable.tableSorters,
            paginationMaleTable.tableFilters
          );
        } else {
          animalActions.indexAnimalFemaleTable(
            groupId,
            farmId,
            paginationFemaleTable.page,
            paginationFemaleTable.sorter,
            paginationFemaleTable.filters,
            paginationFemaleTable.size,
            paginationFemaleTable.ids,
            paginationFemaleTable.tableSorters,
            paginationFemaleTable.tableFilters
          );
        }
      } else {
        animalActions.indexAnimal(
          groupId,
          farmId,
          page,
          sorter,
          filters,
          size,
          ids,
          tableSorters,
          tableFilters
        );
      }

      // Update old table data
      if (initialAnimalFunction !== null && initialAnimalFunction !== "") {
        if (initialAnimalFunction === "reproduction") {
          if (initialAnimalGender === "M") {
            animalActions.indexAnimalMaleTable(
              groupId,
              farmId,
              paginationMaleTable.page,
              paginationMaleTable.sorter,
              paginationMaleTable.filters,
              paginationMaleTable.size,
              paginationMaleTable.ids,
              paginationMaleTable.tableSorters,
              paginationMaleTable.tableFilters
            );
          } else {
            animalActions.indexAnimalFemaleTable(
              groupId,
              farmId,
              paginationFemaleTable.page,
              paginationFemaleTable.sorter,
              paginationFemaleTable.filters,
              paginationFemaleTable.size,
              paginationFemaleTable.ids,
              paginationFemaleTable.tableSorters,
              paginationFemaleTable.tableFilters
            );
          }
        } else {
          animalActions.indexAnimal(
            groupId,
            farmId,
            page,
            sorter,
            filters,
            size,
            ids,
            tableSorters,
            tableFilters
          );
        }
      }

      notification.success({ message: "Animal atualizado com sucesso" });
      this.closeDrawer(actions);
    } catch (error) {
      this.openNotificationError(
        "Houve um erro ao atualizar",
        "Por favor, contacte o administrador."
      );
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleDrawerVisible = (visible) => {
    if (visible) {
      const {
        app: { groupSelected, farmSelected },
      } = this.props;

      if (
        Object.entries(groupSelected).length > 0 &&
        Object.entries(farmSelected).length > 0
      ) {
        this.fetchData(groupSelected, farmSelected);
      }
    }
  };

  handleChangeAge = (e, type, props) => {
    let newDate = null;
    switch (type) {
      case "days":
        newDate = moment().subtract(e.target.value, "days");
        break;
      case "months":
        newDate = moment().subtract(e.target.value, "months");
        break;
      case "years":
        newDate = moment().subtract(e.target.value, "years");
        break;
      default:
        break;
    }
    props.setValues({
      ...props.values,
      birthday: newDate,
    });
  };

  handleCreateSupplier = () => {
    const { supplierActions } = this.props;
    supplierActions.showDrawer("new_animal_supplier", null);
  };

  handleReturnAnimalToActive = async (props) => {
    let values = { ...props.values };
    values.deathDate = null;
    values.finalDate = null;
    values.status = "Active";
    values.deathObservation = null;

    this.handleUpdateAnimal(values, values.id, props);
  };

  handleLoadSupplier = async (groupId, farmId, supplierId) => {
    const {
      data: { results },
    } = await getSupplierShow({ groupId: groupId, id: supplierId });
    this.setState({ onlyReadSupplier: results.name });
  };

  handleCloseDrawerDiscardAnimal = (props) => {
    this.setState({ drawerAnimalDiscardVisible: false });
    props.setFieldValue("discardObservation", null);
    props.setFieldValue("discardMotive", null);
    props.setFieldValue("discardDate", null);
    props.setFieldValue("discard", false);
  };

  // Build the animal description to the dropdown
  getParentDescriptionMale = (animal) => {
    let description = animal.name;

    if (animal.gender === "M") {
      description += " / Macho";
    } else {
      description += " / Fêmea";
    }

    if (animal.isMultipleBull === false && animal.isUnknownBull === false) {
      if (animal.animalFarmFunction === "reproduction") {
        description += " / Reprodução";
      } else {
        description += " / Engorda";
      }

      if (animal?.birthday !== null && animal?.birthday !== undefined) {
        description +=
          " / " + moment(animal.birthday, "YYYY-MM-DD").format("DD/MM/YYYY");
      }
    }

    return description;
  };

  getParentDescriptionFemale = (animal) => {
    let description = animal.handlingNumber;

    if (animal.gender === "M") {
      description += " / Macho";
    } else {
      description += " / Fêmea";
    }

    if (animal.animalFarmFunction === "reproduction") {
      description += " / Reprodução";
    } else {
      description += " / Engorda";
    }

    if (animal?.birthday !== null && animal?.birthday !== undefined) {
      description +=
        " / " + moment(animal.birthday, "YYYY-MM-DD").format("DD/MM/YYYY");
    }

    return description;
  };

  render() {
    const {
      app: {
        translation,
        groupSelected,
        farmSelected: { farmSisbov },
      },
      animal: { drawerEditAnimalVisible, animalMotherDropdown },
      supplier: {
        listDrowpDown: suppliers,
        isLoadingDropDown: isLoadingDropDownSuppliers,
      },
    } = this.props;

    const {
      drawerAnimalDeathVisible,
      drawerAnimalDiscardVisible,
      animalData,
      breeds,
      isLoading,
      lastAnimal,
      isFarmAnimal,
      isFarmMotherAnimal,
      isFarmFatherAnimal,
      onlyReadSupplier,
      allowReceiptLot,
      receiptLots,
      isReceiptLotsLoading,
      semens,
      males,
      farmOwners,
    } = this.state;

    const { Option } = Select;

    const { Group: RadioGroup } = Radio;

    const { Group: InputGroup } = Input;

    const dateFormat = "DD/MM/YYYY";

    const validationSchema = Yup.object().shape({
      handlingNumber: Yup.string().required(),
      breedId: Yup.string().required(),
      birthday: Yup.date().required().max(moment().add(1, "days").toDate()),
      fromBirth: Yup.boolean(),
      acquisitionDate: Yup.date()
        .nullable()
        .notRequired()
        .when(["fromBirth"], {
          is: (fromBirth) => fromBirth === true,
          then: Yup.date().nullable().notRequired(),
          otherwise: Yup.date()
            .required()
            .max(moment().add(1, "days").toDate()),
        }),
      acquisitionEstimatedWeight: Yup.number()
        .nullable()
        .notRequired()
        .when(["fromBirth"], {
          is: (fromBirth) => fromBirth === true,
          then: Yup.number().nullable().notRequired(),
          otherwise: Yup.number().required(),
        }),
      birthdayWeight: Yup.number().required().moreThan(0),
      asymptoticWeight: Yup.number().required().moreThan(0),
      purchaseCost: Yup.number()
        .nullable()
        .notRequired()
        .when(["fromBirth"], {
          is: (fromBirth) => fromBirth === true,
          then: Yup.number().nullable().notRequired(),
          otherwise: Yup.number().required(),
        }),
      animalFarmFunction: Yup.string().required(),
      animalReproductionCategory: Yup.string()
        .nullable()
        .notRequired()
        .when(["animalFarmFunction"], {
          is: (animalFarmFunction) => animalFarmFunction === "reproduction",
          then: Yup.string().required(),
          otherwise: Yup.string().nullable().notRequired(),
        }),
      fromInsermination: Yup.boolean().required(),
      fatherSemenId: Yup.string()
        .nullable()
        .notRequired()
        .when(["fromInsermination"], {
          is: (fromInsermination) => fromInsermination === true,
          then: Yup.string().required(),
          otherwise: Yup.string().nullable().notRequired(),
        }),
      farmOwnerId: Yup.string().required(),
    });

    let block = null;
    if (animalData && animalData.supplierId && suppliers?.length > 0) {
      block =
        suppliers.find((supplier) => {
          if (supplier.id === animalData.supplierId) {
            return true;
          }
          return false;
        }) == null
          ? true
          : false;

      if (block && onlyReadSupplier == null) {
        this.handleLoadSupplier(groupSelected.id, null, animalData.supplierId);
      }
    }
    const blockSupplier = block;

    const InfoTooltipContentAnimalReproductionCategory = (
      <div>
        <strong>Para Fêmeas:</strong>
        <ul style={{ listStyle: "none", margin: "8px 0" }}>
          <li>
            Nulíparas: Fêmeas de reprodução, desmamadas, que não foram
            trabalhadas (inseminadas ou colocadas à disposição do touro).
          </li>
          <li>
            Novilhas: Fêmeas de reprodução, desmamadas, que foram trabalhadas,
            mas inda não pariram.
          </li>
          <li>Primíparas: Fêmeas de reprodução, que tiverem 1 parto.</li>
          <li>Secundíparas: Fêmeas de reprodução, que tiverem 2 partos.</li>
          <li>
            Multíparas: Fêmeas de reprodução, que tiveram 3 ou mias partos.
          </li>
        </ul>
        <span>
          *São considerados partos, a geração de cria viva ou natimorto. Abortos
          ou Mortes Embrionárias, não evoluem a categoria da matriz.
        </span>
        <br />
        <strong style={{ marginTop: 8 }}>Para Machos:</strong>
        <ul style={{ listStyle: "none", margin: "8px 0" }}>
          <li>Reprodutor: Machos destinados para uso na reprodução.</li>
        </ul>
      </div>
    );

    return (
      <Formik
        ref={this.formik}
        enableReinitialize={true}
        initialValues={animalData != null ? animalData : null}
        onSubmit={this.handleSubmitModalForm}
        validationSchema={validationSchema}
        render={(props) => (
          <Container
            title={translation.animal.drawerTitleEdit}
            width={770}
            onClose={() => this.closeDrawer(props)}
            maskClosable={false}
            visible={drawerEditAnimalVisible}
            afterVisibleChange={this.handleDrawerVisible}
          >
            <Spin spinning={isLoading}>
              <form onSubmit={props.handleSubmit} autoComplete="off">
                <Body>
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <span style={{ fontSize: "14px", color: "#000" }}>
                        {animalData != null && animalData?.deathDate != null
                          ? translation.animal.drawerSubTitleEditDeathAnimal
                          : translation.animal.drawerSubTitleEdit}
                      </span>
                    </Col>
                  </Row>
                  {/* Error message */}
                  {Object.entries(props.errors).length > 0 &&
                    props.submitCount > 0 && (
                      <Row type="flex" justify="center" align="middle">
                        <label className="formLabel error">
                          {translation.error.formError}
                        </label>
                      </Row>
                    )}
                  {/* Animal Death Card */}
                  {animalData != null && animalData?.deathDate != null && (
                    <>
                      <DeathAnimalCard>
                        <Row
                          type="flex"
                          className="header"
                          justify="space-between"
                          align="middle"
                        >
                          <Col>
                            <span className="title">
                              <StopIcon />
                              Animal em óbito
                            </span>
                          </Col>
                          <Col>
                            <span className="date">
                              {moment(animalData?.deathDate).format(dateFormat)}
                            </span>
                          </Col>
                        </Row>
                        {animalData?.deathObservation && (
                          <Row type="flex">
                            <Col>
                              <p className="discription">
                                {animalData?.deathObservation}
                              </p>
                            </Col>
                          </Row>
                        )}
                      </DeathAnimalCard>
                      <Row type="flex" align="middle">
                        <Col
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          align="right"
                        >
                          {lastAnimal ? (
                            <PopConfirmCustom
                              title={
                                <p>
                                  Atenção! Este é o último animal presente no
                                  Lote "{animalData.lotName}".
                                  <br /> Ao confirmar o registro de óbito, o
                                  Lote ficará com a situação “Pendente”.
                                  <br /> Se não for mais utilizar este lote,
                                  poderá inativá-lo.
                                </p>
                              }
                              cancelText="Cancelar"
                              okText="Confirmar"
                              placement="topRight"
                              icon={
                                <Icon
                                  type="exclamation-circle"
                                  theme="filled"
                                  style={{
                                    fontSize: 18,
                                    color: "#D44C4C",
                                    marginRight: 5,
                                  }}
                                />
                              }
                              onConfirm={() =>
                                this.handleReturnAnimalToActive(props)
                              }
                            >
                              <DeathButton type="button">
                                <Icon type="plus" />
                                Definir animal como vivo
                              </DeathButton>
                            </PopConfirmCustom>
                          ) : (
                            <PopConfirmCustom
                              title={
                                <p>
                                  Caso o animal estiver em um lote que possua{" "}
                                  <br /> cenário(s) de venda, ao confirmar,
                                  reprocesse os <br /> cenário(s) para que o
                                  animal seja considerado.
                                </p>
                              }
                              cancelText="Cancelar"
                              okText="Confirmar"
                              placement="topRight"
                              icon={
                                <Icon
                                  type="exclamation-circle"
                                  theme="filled"
                                  style={{
                                    fontSize: 18,
                                    color: "#D44C4C",
                                    marginRight: 5,
                                  }}
                                />
                              }
                              onConfirm={() =>
                                this.handleReturnAnimalToActive(props)
                              }
                            >
                              <DeathButton type="button">
                                <Icon type="plus" />
                                Definir animal como vivo
                              </DeathButton>
                            </PopConfirmCustom>
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                  {/* Animal Discard Card */}
                  {animalData.discard && (
                    <DiscardAnimalCard>
                      <Row type="flex" className="header">
                        <Row type="flex" justify="space-between" align="middle">
                          <Col>
                            <span className="title">
                              <StopIcon />
                              Animal descartado
                            </span>
                          </Col>
                          <Col>
                            <span className="date">
                              {moment(animalData?.discardDate).format(
                                dateFormat
                              )}
                            </span>
                          </Col>
                        </Row>
                        <Row type="flex" justify="space-between" align="middle">
                          <Col>
                            <span className="date">
                              Causa:{" "}
                              {
                                translation.animal.discard.motives[
                                  animalData?.discardMotive
                                ]
                              }
                            </span>
                          </Col>
                        </Row>
                      </Row>
                      {animalData?.discardObservation && (
                        <Row type="flex">
                          <Col>
                            <p className="discription">
                              {animalData?.discardObservation}
                            </p>
                          </Col>
                        </Row>
                      )}
                    </DiscardAnimalCard>
                  )}
                  {/* Animal Supplier */}
                  <Row type="flex" justify="start">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            animalData?.deathDate != null
                              ? "formLabel disabled"
                              : "formLabel"
                          }
                        >
                          {translation.lot.receipt.supplier.supplierTitle}
                        </label>
                      </Row>
                      <Row className="rowInput">
                        {blockSupplier === true ? (
                          <Input
                            disabled={true}
                            value={onlyReadSupplier}
                            name="readOnlySupplier"
                            style={{ width: "90%" }}
                          />
                        ) : (
                          <Select
                            style={{ width: "90%" }}
                            loading={isLoadingDropDownSuppliers}
                            value={
                              props.values?.supplierId != null
                                ? suppliers != null &&
                                  isLoadingDropDownSuppliers === false &&
                                  suppliers.find(
                                    (s) => s.id === props.values?.supplierId
                                  ) != null
                                  ? props.values?.supplierId
                                  : undefined
                                : undefined
                            }
                            showSearch
                            placeholder={
                              translation.lot.receipt.supplier
                                .supplierComboBoxPlaceHolder
                            }
                            name="supplierId"
                            onChange={(value) => {
                              props.setFieldValue("supplierId", value);
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={
                              animalData?.deathDate != null || isFarmAnimal
                            }
                          >
                            {suppliers != null &&
                              Object.entries(suppliers).length > 0 &&
                              suppliers.map((supplier) => (
                                <Option key={supplier.id} value={supplier.id}>
                                  {supplier.name}
                                </Option>
                              ))}
                          </Select>
                        )}
                        <Button
                          disabled={isFarmAnimal || blockSupplier}
                          shape="circle"
                          icon="plus"
                          style={{ marginLeft: 10 }}
                          onClick={() => this.handleCreateSupplier()}
                        />
                      </Row>
                      <Row className="rowInput">
                        <Checkbox
                          checked={isFarmAnimal}
                          disabled={true}
                          onChange={(e) => {
                            if (e.target.checked)
                              props.setFieldValue("supplierId", null);
                            props.setFieldValue("lotReceiptId", null);
                            props.setFieldValue("lotItemReceiptId", null);
                            this.setState({
                              ...this.state,
                              isFarmAnimal: e.target.checked,
                            });
                          }}
                        >
                          Animal fornecido pela <strong>própria fazenda</strong>
                        </Checkbox>
                      </Row>
                    </Col>
                  </Row>
                  <CustomDivider dashed />
                  {/* Animal Card */}
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <AnimalCard
                        className={
                          animalData?.deathDate != null ? "disabled" : ""
                        }
                      >
                        <Row type="flex" justify="start">
                          <Col>
                            <BullAvatarIcon />
                          </Col>
                          <Col
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            offset={1}
                            className="animalCardCol"
                          >
                            <Row>
                              <label
                                className={
                                  animalData?.deathDate != null
                                    ? "formLabel disabled"
                                    : props.errors.handlingNumber &&
                                      props.submitCount > 0
                                    ? "formLabel error"
                                    : "formLabel"
                                }
                              >
                                {translation.animal.edit.form.handlingNumber}*
                              </label>
                            </Row>
                            <Row className="rowInput">
                              <Input
                                placeholder={
                                  translation.animal.edit.form
                                    .handlingNumberPlaceHolder
                                }
                                value={props.values?.handlingNumber}
                                name="handlingNumber"
                                onChange={(e) => {
                                  props.setValues({
                                    ...props.values,
                                    handlingNumber: e.target.value,
                                  });
                                }}
                                disabled={animalData?.deathDate != null}
                              />
                            </Row>
                          </Col>
                          <Col
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            offset={1}
                            className="animalCardCol"
                          >
                            <Row>
                              <label
                                className={
                                  animalData?.deathDate != null
                                    ? "formLabel disabled"
                                    : "formLabel"
                                }
                              >
                                {translation.animal.edit.form.sisbov}
                              </label>
                            </Row>
                            <Row className="rowInput">
                              <NumberFormat
                                customInput={Input}
                                placeholder={
                                  translation.animal.edit.form.sisbovPlaceHolder
                                }
                                isNumericString={true}
                                maxLength={15}
                                decimalScale={0}
                                allowLeadingZeros={false}
                                value={
                                  props.values?.sisbov !== null
                                    ? props.values?.sisbov
                                    : ""
                                }
                                name="sisbov"
                                onValueChange={({ value }) => {
                                  props.setValues({
                                    ...props.values,
                                    sisbov: value,
                                  });
                                }}
                                disabled={animalData?.deathDate != null}
                              />
                            </Row>
                          </Col>
                          <Col
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            offset={1}
                            className="animalCardCol"
                          >
                            <Row>
                              <label
                                className={
                                  animalData?.deathDate != null
                                    ? "formLabel disabled"
                                    : "formLabel"
                                }
                              >
                                {translation.animal.edit.form.tagId}
                              </label>
                            </Row>
                            <Row className="rowInput">
                              <NumberFormat
                                customInput={Input}
                                placeholder={
                                  translation.animal.edit.form.tagIdPlaceHolder
                                }
                                value={
                                  props.values?.tagId !== null
                                    ? props.values?.tagId
                                    : ""
                                }
                                decimalScale={0}
                                allowLeadingZeros={true}
                                isNumericString={true}
                                name="tagId"
                                onValueChange={({ value }) => {
                                  props.setValues({
                                    ...props.values,
                                    tagId: value,
                                  });
                                }}
                                disabled={animalData?.deathDate != null}
                              />
                            </Row>
                          </Col>
                        </Row>
                      </AnimalCard>
                    </Col>
                  </Row>
                  {/* Animal nickname and breed  */}
                  <Row
                    type="flex"
                    justify="space-between"
                    style={{ marginTop: 24 }}
                  >
                    <Col span={12}>
                      <Row>
                        <label
                          className={
                            animalData?.deathDate != null
                              ? "formLabel disabled"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.nickname}
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Input
                          placeholder={
                            translation.animal.edit.form.nicknamePlaceHolder
                          }
                          value={props.values?.nickname}
                          name="nickname"
                          onChange={(e) => {
                            props.setValues({
                              ...props.values,
                              nickname: e.target.value,
                            });
                          }}
                          disabled={animalData?.deathDate != null}
                        />
                      </Row>
                    </Col>
                    <Col span={11} offset={1}>
                      <Row>
                        <label
                          className={
                            animalData?.deathDate != null
                              ? "formLabel disabled"
                              : props.errors.breedId && props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.breed}*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          style={{ width: "100%" }}
                          name="breedId"
                          placeholder={
                            translation.animal.edit.form.breedPlaceHolder
                          }
                          value={
                            breeds != null && props.values?.breedId != null
                              ? props.values?.breedId
                              : undefined
                          }
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => {
                            props.setValues({
                              ...props.values,
                              breedId: value,
                              breedName:
                                breeds != null &&
                                breeds.filter((breed) => breed.id === value)[0]
                                  .name,
                            });
                            this.getBreedParameter(value);
                          }}
                          disabled={animalData?.deathDate != null}
                        >
                          {breeds != null &&
                            Object.entries(breeds).length > 0 &&
                            breeds.map((breed) => (
                              <Option key={breed.id} value={breed.id}>
                                {breed.name}
                              </Option>
                            ))}
                        </Select>
                      </Row>
                      <Row className="rowInput">
                        {!this.state.isMixedMilk ? (
                          <></>
                        ) : (
                          <Checkbox
                            checked={props.values?.mixedMilk}
                            onChange={(e) => {
                              props.setValues({
                                ...props.values,
                                mixedMilk: e.target.checked,
                              });
                            }}
                          >
                            <strong>Cruzado de leite</strong>
                          </Checkbox>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  {/* Animal birthday date and cap */}
                  <Row type="flex" justify="start" style={{ marginTop: 16 }}>
                    {/* Birthday */}
                    <Col span={5}>
                      <Row>
                        <label
                          className={
                            animalData?.deathDate != null
                              ? "formLabel disabled"
                              : props.errors.birthday && props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.birthday}*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <DatePicker
                          name="birthday"
                          allowClear={false}
                          style={{ width: "100%" }}
                          value={
                            props.values?.birthday != null
                              ? moment(props.values?.birthday, "YYYY-MM-DD")
                              : null
                          }
                          placeholder={translation.defaultDatePickerPlaceholder}
                          format={dateFormat}
                          onChange={(date, dateString) => {
                            if (date <= moment() && date !== null) {
                              props.setValues({
                                ...props.values,
                                birthday: date,
                              });
                              if (isFarmAnimal) {
                                props.setFieldValue("acquisitionDate", date);
                              }
                            }
                          }}
                          disabled={animalData?.deathDate != null}
                        />
                      </Row>
                    </Col>
                    <Col span={1}>
                      <Row
                        type="flex"
                        align="middle"
                        justify="center"
                        style={{ height: "100%" }}
                      >
                        <span>-</span>
                      </Row>
                    </Col>
                    <Col span={7}>
                      <Row>
                        <label
                          className={
                            animalData?.deathDate != null
                              ? "formLabel disabled"
                              : "formLabel"
                          }
                        >
                          Idade (dias/meses/anos)
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Col>
                          <InputGroup compact>
                            <Input
                              type="number"
                              style={{ width: 65, textAlign: "center" }}
                              name="ageDay"
                              min="0"
                              placeholder="Dias"
                              disabled={animalData?.deathDate != null}
                              value={
                                props.values?.birthday != null
                                  ? moment().diff(
                                      props.values?.birthday,
                                      "days"
                                    )
                                  : null
                              }
                              onChange={(e) =>
                                this.handleChangeAge(e, "days", props)
                              }
                            />
                            <Input
                              style={{
                                width: 65,
                                textAlign: "center",
                                borderLeft: "1px solid #c4c4c4",
                              }}
                              type="number"
                              name="ageMonth"
                              min="0"
                              placeholder="Meses"
                              disabled={animalData?.deathDate != null}
                              value={
                                props.values?.birthday != null
                                  ? moment().diff(
                                      props.values?.birthday,
                                      "months"
                                    )
                                  : null
                              }
                              onChange={(e) =>
                                this.handleChangeAge(e, "months", props)
                              }
                            />
                            <Input
                              style={{
                                width: 65,
                                textAlign: "center",
                                borderLeft: "1px solid #c4c4c4",
                              }}
                              type="number"
                              name="ageYear"
                              min="0"
                              placeholder="Anos"
                              disabled={animalData?.deathDate != null}
                              value={
                                props.values?.birthday != null
                                  ? moment().diff(
                                      props.values?.birthday,
                                      "years",
                                      false
                                    )
                                  : null
                              }
                              onChange={(e) =>
                                this.handleChangeAge(e, "years", props)
                              }
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </Col>
                    {/* Gender */}
                    <Col
                      span={props.values?.gender === "M" ? 5 : 10}
                      offset={1}
                    >
                      <Row>
                        <label
                          className={
                            animalData?.deathDate != null
                              ? "formLabel disabled"
                              : props.errors.gender && props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.sex}
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          style={{ width: "100%" }}
                          name="gender"
                          value={
                            props.values?.gender != null
                              ? props.values?.gender
                              : undefined
                          }
                          placeholder={translation.defaultSelectPlaceholder}
                          onChange={(value) => {
                            props.setValues({
                              ...props.values,
                              gender: value,
                              animalReproductionCategory:
                                value === "F"
                                  ? null
                                  : props.values.animalFarmFunction ===
                                    "reproduction"
                                  ? "reprodutor"
                                  : null,
                            });
                          }}
                          disabled={animalData?.deathDate != null}
                        >
                          <Option key="F" value="F">
                            {translation.animal.edit.form.female}
                          </Option>
                          <Option key="M" value="M">
                            {translation.animal.edit.form.male}
                          </Option>
                        </Select>
                      </Row>
                    </Col>
                    {/* Cap */}
                    {props.values?.gender === "M" ? (
                      <Col span={5}>
                        <Row>
                          <label
                            className={
                              animalData?.deathDate != null
                                ? "formLabel disabled"
                                : "formLabel"
                            }
                          >
                            {translation.animal.edit.form.cap}
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <RadioGroup
                            name="cap"
                            value={props.values?.cap}
                            defaultValue={false}
                            onChange={(e) => {
                              props.setValues({
                                ...props.values,
                                cap: e.target.value,
                              });
                            }}
                            disabled={animalData?.deathDate != null}
                          >
                            <RadioButton value={true}>
                              {translation.buttons.yes}
                            </RadioButton>
                            <RadioButton value={false}>
                              {translation.buttons.no}
                            </RadioButton>
                          </RadioGroup>
                        </Row>
                      </Col>
                    ) : null}
                  </Row>
                  {/* animalFarmFunction and animalReproductionCategory*/}
                  <Row type="flex" justify="start" style={{ marginTop: 16 }}>
                    <Col span={11}>
                      <Row>
                        <label
                          className={
                            props.errors.animalFarmFunction &&
                            props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.animalFarmFunction}*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          name="animalFarmFunction"
                          value={props.values.animalFarmFunction || undefined}
                          disabled={
                            props.values.discard ||
                            props.values.firstCoverageId != null
                          }
                          placeholder={translation.defaultSelectPlaceholder}
                          onChange={(value) => {
                            props.setFieldValue("animalFarmFunction", value);
                            if (value === "fatten") {
                              props.setFieldValue(
                                "animalReproductionCategory",
                                null
                              );
                            } else if (
                              props.values.gender === "M" &&
                              value === "reproduction"
                            ) {
                              props.setFieldValue(
                                "animalReproductionCategory",
                                "reprodutor"
                              );
                            }
                          }}
                        >
                          <Option value="reproduction">Reprodução</Option>
                          <Option value="fatten">Engorda</Option>
                        </Select>
                      </Row>
                    </Col>
                    {props.values.animalFarmFunction === "reproduction" && (
                      <Col span={12} offset={1}>
                        <Row>
                          <label
                            className={
                              props.errors.animalReproductionCategory &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            {
                              translation.animal.edit.form
                                .animalReproductionCategory
                            }
                            *{" "}
                            <InfoTooltip
                              title={
                                InfoTooltipContentAnimalReproductionCategory
                              }
                            />
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <Select
                            name="animalReproductionCategory"
                            value={
                              props.values.animalReproductionCategory ||
                              undefined
                            }
                            placeholder={translation.defaultSelectPlaceholder}
                            onChange={(value) =>
                              props.setFieldValue(
                                "animalReproductionCategory",
                                value
                              )
                            }
                            disabled={props.values.firstCoverageId != null}
                          >
                            {props.values.gender === "F" && (
                              <Option key="nulipara" value="nulipara">
                                Nulípara
                              </Option>
                            )}
                            {props.values.gender === "F" && (
                              <Option key="novilha" value="novilha">
                                Novilha
                              </Option>
                            )}
                            {props.values.gender === "F" && (
                              <Option key="primipara" value="primipara">
                                Primípara
                              </Option>
                            )}
                            {props.values.gender === "F" && (
                              <Option key="secundipara" value="secundipara">
                                Secundípara
                              </Option>
                            )}
                            {props.values.gender === "F" && (
                              <Option key="multipara" value="multipara">
                                Multípara
                              </Option>
                            )}
                            {props.values.gender === "M" && (
                              <Option value="reprodutor">Reprodutor</Option>
                            )}
                          </Select>
                        </Row>
                      </Col>
                    )}
                  </Row>
                  {/* farm owner */}
                  <Row type="flex" justify="start" style={{ marginTop: 16 }}>
                    <Col span={11}>
                      <Row>
                        <label
                          className={
                            props.errors.farmOwnerId && props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.farmOwner}*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          name="farmOwnerId"
                          value={props.values.farmOwnerId || undefined}
                          placeholder={translation.defaultSelectPlaceholder}
                          onChange={(value) =>
                            props.setFieldValue("farmOwnerId", value)
                          }
                        >
                          {farmOwners != null &&
                            Object.entries(farmOwners).length > 0 &&
                            farmOwners.map((fo) => (
                              <Option key={fo.id} value={fo.id}>
                                {fo.name}
                              </Option>
                            ))}
                        </Select>
                      </Row>
                    </Col>
                  </Row>
                  <CustomDivider>
                    {translation.animal.edit.weights}
                  </CustomDivider>
                  {/* Animal birthday, first weighting, firts weight date */}
                  <Row type="flex" justify="start">
                    {/* Birthday weight */}
                    <Col span={5}>
                      <Row>
                        <label
                          className={
                            animalData?.deathDate != null
                              ? "formLabel disabled"
                              : props.errors.birthdayWeight &&
                                props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.birthdayWeight}*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          value={
                            props.values?.birthdayWeight !== null
                              ? props.values?.birthdayWeight
                              : ""
                          }
                          name="birthdayWeight"
                          addonAfter="Kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          placeholder={
                            translation.animal.edit.form.birthdayWeight
                          }
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              birthdayWeight: floatValue
                                ? floatValue > 100
                                  ? 100
                                  : floatValue
                                : null,
                            });
                            if (isFarmAnimal) {
                              props.setFieldValue(
                                "acquisitionEstimatedWeight",
                                floatValue
                                  ? floatValue > 100
                                    ? 100
                                    : floatValue
                                  : null
                              );
                            }
                          }}
                          disabled={animalData?.deathDate != null}
                        />
                      </Row>
                    </Col>
                    {/* First insemination Weight */}
                    {props.values?.gender === "F" && (
                      <Col span={5} offset={1}>
                        <Row>
                          <label
                            className={
                              animalData?.deathDate != null
                                ? "formLabel disabled"
                                : "formLabel"
                            }
                          >
                            1a. Inseminação
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <NumberFormat
                            customInput={Input}
                            value={
                              props.values?.firstInseminationWeight !== null
                                ? props.values?.firstInseminationWeight
                                : ""
                            }
                            placeholder={
                              translation.animal.edit.form
                                .firstInseminationWeight
                            }
                            name="firstInseminationWeight"
                            addonAfter="Kg"
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale={true}
                            onValueChange={({ floatValue }) => {
                              props.setValues({
                                ...props.values,
                                firstInseminationWeight: floatValue,
                              });
                            }}
                            disabled={
                              animalData?.deathDate != null ||
                              animalData?.firstWeightCoverage != null
                            }
                          />
                        </Row>
                      </Col>
                    )}
                    {/* Asymptotic Weight */}
                    <Col span={5} offset={1}>
                      <Row>
                        <label
                          className={
                            animalData?.deathDate != null
                              ? "formLabel disabled"
                              : props.errors.asymptoticWeight &&
                                props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.asymptoticWeight}*
                          <InfoTooltip
                            title={
                              translation.animal.edit.form
                                .asymptoticWeightInfoTooltip
                            }
                          />
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          value={
                            props.values?.asymptoticWeight !== null
                              ? props.values?.asymptoticWeight
                              : ""
                          }
                          placeholder={
                            translation.animal.edit.form.asymptoticWeight
                          }
                          name="asymptoticWeight"
                          addonAfter="Kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              asymptoticWeight: floatValue,
                            });
                          }}
                          disabled={animalData?.deathDate != null}
                        />
                      </Row>
                    </Col>
                    {/* Weaning Weight */}
                    <Col span={5} offset={1}>
                      <Row>
                        <label
                          className={
                            animalData?.deathDate != null
                              ? "formLabel disabled"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.weaningWeight}
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          value={
                            props.values?.weaningWeight !== null
                              ? props.values?.weaningWeight
                              : ""
                          }
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          maxLength={6}
                          fixedDecimalScale={true}
                          name="weaningWeight"
                          placeholder={
                            translation.animal.edit.form.weaningWeight
                          }
                          addonAfter="Kg"
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              weaningWeight: floatValue,
                            });
                          }}
                          disabled={animalData?.deathDate != null}
                        />
                      </Row>
                    </Col>
                    {/* Weaning Date */}
                    <Col span={5} offset={props.values?.gender === "F" ? 0 : 1}>
                      <Row>
                        <label
                          className={
                            animalData?.deathDate != null
                              ? "formLabel disabled"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.weaningDate}
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <DatePicker
                          name="weaningDate"
                          allowClear={false}
                          style={{ width: "100%" }}
                          value={
                            props.values?.weaningDate != null
                              ? moment(props.values?.weaningDate, "YYYY-MM-DD")
                              : null
                          }
                          placeholder={translation.defaultDatePickerPlaceholder}
                          format={dateFormat}
                          onChange={(date, dateString) => {
                            if (date <= moment() && date !== null) {
                              props.setValues({
                                ...props.values,
                                weaningDate: date,
                              });
                            }
                          }}
                          disabled={animalData?.deathDate != null}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <CustomDivider>
                    {translation.animal.edit.receipt}
                  </CustomDivider>
                  {/* Animal acquisition date, purchase cost and acquisition weight */}
                  <Row type="flex" justify="start">
                    {/* Acquisition Date */}
                    <Col span={7}>
                      <Row>
                        <label
                          className={
                            animalData?.deathDate != null
                              ? "formLabel disabled"
                              : props.errors.acquisitionDate &&
                                props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.acquisitionDate}
                          {props.values.fromBirth ? "" : "*"}
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <DatePicker
                          name="acquisitionDate"
                          placeholder={
                            translation.animal.edit.form.acquisitionDate
                          }
                          allowClear={false}
                          style={{ width: "100%" }}
                          value={
                            props.values?.acquisitionDate != null
                              ? moment(
                                  props.values?.acquisitionDate,
                                  "YYYY-MM-DD"
                                )
                              : undefined
                          }
                          format={dateFormat}
                          onChange={(date, dateString) => {
                            props.setValues({
                              ...props.values,
                              acquisitionDate: date,
                            });
                          }}
                          disabled={
                            animalData?.deathDate != null || isFarmAnimal
                          }
                        />
                      </Row>
                    </Col>
                    {/* Purchase cost */}
                    <Col span={8} offset={1}>
                      <Row>
                        <label
                          className={
                            animalData?.deathDate != null
                              ? "formLabel disabled"
                              : props.errors.purchaseCost &&
                                props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.purchaseCost}
                          {props.values.fromBirth ? "" : "*"}
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          value={
                            props.values?.purchaseCost !== null
                              ? props.values?.purchaseCost
                              : ""
                          }
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          placeholder={
                            translation.animal.edit.form.purchaseCost
                          }
                          name="purchaseCost"
                          addonBefore="R$ "
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              purchaseCost: floatValue,
                            });
                          }}
                          disabled={
                            animalData?.deathDate != null ||
                            props.values.fromBirth === true
                          }
                        />
                      </Row>
                    </Col>
                    {/* Acquisition Weight */}
                    <Col span={7} offset={1}>
                      <Row>
                        <label
                          className={
                            animalData?.deathDate != null
                              ? "formLabel disabled"
                              : props.errors.acquisitionEstimatedWeight &&
                                props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {
                            translation.animal.edit.form
                              .acquisitionEstimatedWeight
                          }
                          {props.values.fromBirth ? "" : "*"}
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          value={
                            props.values?.acquisitionEstimatedWeight !== null
                              ? props.values?.acquisitionEstimatedWeight
                              : ""
                          }
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          placeholder={
                            translation.animal.edit.form
                              .acquisitionEstimatedWeight
                          }
                          name="acquisitionEstimatedWeight"
                          addonAfter="Kg"
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              acquisitionEstimatedWeight: floatValue,
                            });
                          }}
                          disabled={
                            animalData?.deathDate != null || isFarmAnimal
                          }
                        />
                      </Row>
                    </Col>
                  </Row>

                  <CustomDivider>{translation.animal.edit.sale}</CustomDivider>

                  {/* Animal sale data */}
                  <Row type="flex" justify="start">
                    <Col span={7}>
                      <Row>
                        <label
                          className={
                            animalData?.deathDate != null
                              ? "formLabel disabled"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.carcassHarnessing}
                          <InfoTooltip
                            title={
                              translation.animal.carcassHarnessingInfoTooltip
                            }
                          />
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          placeholder={
                            translation.animal.edit.form.carcassHarnessing
                          }
                          value={
                            props.values?.carcassHarnessing !== null
                              ? props.values?.carcassHarnessing
                              : ""
                          }
                          name="carcassHarnessing"
                          decimalScale={2}
                          fixedDecimalScale={true}
                          addonAfter="%"
                          decimalSeparator=","
                          thousandSeparator="."
                          disabled={animalData?.deathDate != null}
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              carcassHarnessing: floatValue,
                            });
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>

                  {farmSisbov === true ? (
                    <>
                      <CustomDivider>RASTREABIDLIDADE (SISBOV)</CustomDivider>

                      {/* Animal sisbov info */}
                      <Row type="flex" justify="start" gutter={8}>
                        <Col span={7}>
                          <Row>
                            <label
                              className={
                                props.errors.sisbovIdentificationDate &&
                                props.submitCount > 0
                                  ? "formLabel error"
                                  : "formLabel"
                              }
                            >
                              {
                                translation.animal.edit.form
                                  .sisbovIdentificationDate
                              }
                            </label>
                          </Row>
                          <Row className="rowInput">
                            <DatePicker
                              name="sisbovIdentificationDate"
                              placeholder={
                                translation.animal.edit.form
                                  .sisbovIdentificationDate
                              }
                              allowClear={false}
                              style={{ width: "100%" }}
                              value={
                                props.values?.sisbovIdentificationDate != null
                                  ? moment(
                                      props.values?.sisbovIdentificationDate,
                                      "YYYY-MM-DD"
                                    )
                                  : undefined
                              }
                              format={dateFormat}
                              onChange={(date, dateString) => {
                                props.setValues({
                                  ...props.values,
                                  sisbovIdentificationDate: date,
                                });
                              }}
                            />
                          </Row>
                        </Col>
                        <Col span={7}>
                          <Row>
                            <label
                              className={
                                props.errors.sisbovInsertBNDDate &&
                                props.submitCount > 0
                                  ? "formLabel error"
                                  : "formLabel"
                              }
                            >
                              {translation.animal.edit.form.sisbovInsertBNDDate}
                            </label>
                          </Row>
                          <Row className="rowInput">
                            <DatePicker
                              name="sisbovInsertBNDDate"
                              placeholder={
                                translation.animal.edit.form.sisbovInsertBNDDate
                              }
                              allowClear={false}
                              style={{ width: "100%" }}
                              value={
                                props.values?.sisbovInsertBNDDate != null
                                  ? moment(
                                      props.values?.sisbovInsertBNDDate,
                                      "YYYY-MM-DD"
                                    )
                                  : undefined
                              }
                              format={dateFormat}
                              onChange={(date, dateString) => {
                                let sisbovSlaughterDate =
                                  props.values.sisbovSlaughterDate;
                                if (
                                  sisbovSlaughterDate === null ||
                                  sisbovSlaughterDate === undefined
                                ) {
                                  sisbovSlaughterDate = date
                                    .clone()
                                    .add(90, "day");
                                }

                                props.setValues({
                                  ...props.values,
                                  sisbovInsertBNDDate: date,
                                  sisbovSlaughterDate: sisbovSlaughterDate,
                                });
                              }}
                            />
                          </Row>
                        </Col>
                        <Col span={7}>
                          <Row>
                            <label
                              className={
                                props.errors.sisbovSlaughterDate &&
                                props.submitCount > 0
                                  ? "formLabel error"
                                  : "formLabel"
                              }
                            >
                              {translation.animal.edit.form.sisbovSlaughterDate}
                            </label>
                          </Row>
                          <Row className="rowInput">
                            <DatePicker
                              name="sisbovSlaughterDate"
                              placeholder={
                                translation.animal.edit.form.sisbovSlaughterDate
                              }
                              allowClear={false}
                              style={{ width: "100%" }}
                              value={
                                props.values?.sisbovSlaughterDate != null
                                  ? moment(
                                      props.values?.sisbovSlaughterDate,
                                      "YYYY-MM-DD"
                                    )
                                  : undefined
                              }
                              format={dateFormat}
                              onChange={(date, dateString) => {
                                props.setValues({
                                  ...props.values,
                                  sisbovSlaughterDate: date,
                                });
                              }}
                            />
                          </Row>
                        </Col>
                      </Row>
                    </>
                  ) : null}

                  <CustomDivider>GENEALOGIA</CustomDivider>
                  {props.values.fromBirth && (
                    <Row type="flex" justify="center">
                      <h2 style={{ color: "#d44c4c" }}>
                        Animal de origem de Parto
                      </h2>
                    </Row>
                  )}
                  {/* Animal genetic info */}
                  <Row type="flex" justify="start" gutter={8}>
                    {/* Mother info box */}
                    <Col span={12}>
                      <GeneticBox>
                        <Row>
                          <span className="geneticTitle">MÃE</span>
                        </Row>
                        <Row className="rowInput">
                          <Checkbox
                            checked={isFarmMotherAnimal}
                            disabled={
                              animalData?.deathDate != null ||
                              props.values.fromBirth
                            }
                            onChange={(e) => {
                              props.setFieldValue(
                                "isFarmMotherAnimal",
                                e.target.checked
                              );
                              if (e.target.checked)
                                props.setFieldValue("motherSupplierId", null);
                              else props.setFieldValue("motherId", null);

                              this.setState({
                                ...this.state,
                                isFarmMotherAnimal: e.target.checked,
                              });
                            }}
                          >
                            Animal fornecido pela{" "}
                            <strong>própria fazenda</strong>
                          </Checkbox>
                        </Row>
                        <Row>
                          <label
                            className={
                              animalData?.deathDate !== null
                                ? "formLabel disabled"
                                : "formLabel"
                            }
                          >
                            Fornecedor
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <Select
                            value={
                              props.values?.motherSupplierId != null
                                ? suppliers != null &&
                                  suppliers.find(
                                    (s) =>
                                      s.id === props.values?.motherSupplierId
                                  ) != null
                                  ? props.values?.motherSupplierId
                                  : undefined
                                : undefined
                            }
                            showSearch
                            placeholder={
                              translation.lot.receipt.supplier
                                .supplierComboBoxPlaceHolder
                            }
                            name="motherSupplierId"
                            onChange={(value) => {
                              props.setFieldValue("motherSupplierId", value);
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={
                              animalData?.deathDate != null ||
                              isFarmMotherAnimal ||
                              props.values.fromBirth
                            }
                          >
                            {suppliers != null &&
                              Object.entries(suppliers).length > 0 &&
                              suppliers.map((supplier) => (
                                <Option key={supplier.id} value={supplier.id}>
                                  {supplier.name}
                                </Option>
                              ))}
                          </Select>
                        </Row>
                        <Row>
                          <label
                            className={
                              animalData?.deathDate !== null
                                ? "formLabel disabled"
                                : "formLabel"
                            }
                          >
                            Identificação
                          </label>
                        </Row>
                        <Row className="rowInput">
                          {isFarmMotherAnimal ? (
                            <Select
                              value={
                                props.values?.motherId != null
                                  ? animalMotherDropdown != null &&
                                    animalMotherDropdown.find(
                                      (a) => a.id === props.values?.motherId
                                    ) != null
                                    ? props.values?.motherId
                                    : undefined
                                  : undefined
                              }
                              showSearch
                              placeholder={translation.defaultPlaceholder}
                              name="motherId"
                              onChange={(value) => {
                                props.setFieldValue("motherId", value);
                              }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              disabled={props.values.fromBirth}
                            >
                              {animalMotherDropdown != null &&
                                Object.entries(animalMotherDropdown).length >
                                  0 &&
                                animalMotherDropdown.map((a) => (
                                  <Option key={a.id} value={a.id}>
                                    {this.getParentDescriptionFemale(a)}
                                  </Option>
                                ))}
                            </Select>
                          ) : (
                            <Input
                              placeholder={translation.defaultPlaceholder}
                              value={props.values?.motherHandlingNumber}
                              name="motherHandlingNumber"
                              disabled={
                                animalData?.deathDate != null ||
                                props.values.fromBirth
                              }
                              onChange={(e) => {
                                props.setFieldValue(
                                  "motherHandlingNumber",
                                  e.target.value
                                );
                              }}
                            />
                          )}
                        </Row>
                      </GeneticBox>
                    </Col>
                    {/* Father info box */}
                    <Col span={12}>
                      <GeneticBox>
                        <Row>
                          <span className="geneticTitle">PAI</span>
                        </Row>
                        <Row className="rowInput">
                          <Checkbox
                            checked={isFarmFatherAnimal}
                            disabled={
                              animalData?.deathDate != null ||
                              props.values.fromBirth
                            }
                            onChange={(e) => {
                              if (e.target.checked)
                                props.setFieldValue("fatherSupplierId", null);
                              else props.setFieldValue("fatherId", null);

                              if (e.target.checked)
                                props.setFieldValue("fatherSupplierId", null);
                              this.setState({
                                ...this.state,
                                isFarmFatherAnimal: e.target.checked,
                              });
                            }}
                          >
                            Animal fornecido pela{" "}
                            <strong>própria fazenda</strong>
                          </Checkbox>
                        </Row>
                        <Row className="rowInput">
                          <Checkbox
                            checked={props.values.fromInsermination}
                            onChange={(e) => {
                              props.setFieldValue(
                                "fromInsermination",
                                e.target.checked
                              );
                              if (e.target.checked) {
                                props.setFieldValue("fatherSupplierId", null);
                                props.setFieldValue(
                                  "fatherHandlingNumber",
                                  null
                                );
                                props.setFieldValue("fatherId", null);
                              } else {
                                props.setFieldValue("fatherSemenId", null);
                              }
                            }}
                            disabled={props.values.fromBirth}
                          >
                            Sêmen
                          </Checkbox>
                        </Row>
                        <Row>
                          <label
                            className={
                              animalData?.deathDate !== null
                                ? "formLabel disabled"
                                : "formLabel"
                            }
                          >
                            Fornecedor
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <Select
                            value={
                              props.values?.fatherSupplierId != null
                                ? suppliers != null &&
                                  suppliers.find(
                                    (s) =>
                                      s.id === props.values?.fatherSupplierId
                                  ) != null
                                  ? props.values?.fatherSupplierId
                                  : undefined
                                : undefined
                            }
                            showSearch
                            placeholder={
                              translation.lot.receipt.supplier
                                .supplierComboBoxPlaceHolder
                            }
                            name="fatherSupplierId"
                            onChange={(value) => {
                              props.setFieldValue("fatherSupplierId", value);
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={
                              animalData?.deathDate != null ||
                              isFarmFatherAnimal ||
                              props.values.fromBirth
                            }
                          >
                            {suppliers != null &&
                              Object.entries(suppliers).length > 0 &&
                              suppliers.map((supplier) => (
                                <Option key={supplier.id} value={supplier.id}>
                                  {supplier.name}
                                </Option>
                              ))}
                          </Select>
                        </Row>
                        <Row>
                          <label
                            className={
                              animalData?.deathDate !== null
                                ? "formLabel disabled"
                                : props.values.fromInsermination &&
                                  props.errors.fatherSemenId &&
                                  props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            {`Identificação${
                              props.values.fromInsermination === true ? "*" : ""
                            }`}
                          </label>
                        </Row>
                        <Row className="rowInput">
                          {props.values.fromInsermination ? (
                            <Select
                              value={
                                semens.length === 0
                                  ? undefined
                                  : props.values.fatherSemenId != null
                                  ? props.values.fatherSemenId
                                  : undefined
                              }
                              showSearch
                              placeholder={translation.defaultPlaceholder}
                              name="fatherSemenId"
                              onChange={(value) => {
                                props.setFieldValue("fatherSemenId", value);
                              }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              disabled={props.values.fromBirth}
                            >
                              {semens.map((a) => (
                                <Option key={a.id} value={a.id}>
                                  {a.name}
                                </Option>
                              ))}
                            </Select>
                          ) : isFarmFatherAnimal ? (
                            <Select
                              value={
                                props.values?.fatherId != null
                                  ? males != null &&
                                    males.find(
                                      (a) => a.id === props.values?.fatherId
                                    ) != null
                                    ? props.values?.fatherId
                                    : undefined
                                  : undefined
                              }
                              showSearch
                              placeholder={translation.defaultPlaceholder}
                              name="fatherId"
                              onChange={(value) => {
                                props.setFieldValue("fatherId", value);
                              }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              disabled={props.values.fromBirth}
                            >
                              {males != null &&
                                Object.entries(males).length > 0 &&
                                males.map((a) => (
                                  <Option
                                    key={a.id}
                                    value={a.id}
                                    style={
                                      a.isMultipleBull
                                        ? {
                                            color: "#9074bf",
                                            fontWeight: 700,
                                          }
                                        : {}
                                    }
                                  >
                                    {this.getParentDescriptionMale(a)}
                                  </Option>
                                ))}
                            </Select>
                          ) : (
                            <Input
                              placeholder={translation.defaultPlaceholder}
                              value={props.values?.fatherHandlingNumber}
                              name="fatherHandlingNumber"
                              onChange={(e) => {
                                props.setFieldValue(
                                  "fatherHandlingNumber",
                                  e.target.value
                                );
                              }}
                              disabled={
                                animalData?.deathDate != null ||
                                props.values.fromBirth
                              }
                            />
                          )}
                        </Row>
                      </GeneticBox>
                    </Col>
                  </Row>
                  <Row className="rowInput">
                    <Checkbox
                      disabled={
                        isFarmAnimal || props.values.lotItemEntryId !== null
                      }
                      checked={allowReceiptLot}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          props.setFieldValue("lotReceiptId", null);
                          props.setFieldValue("lotItemReceiptId", null);
                        }
                        this.setState({
                          ...this.state,
                          allowReceiptLot: e.target.checked,
                        });
                      }}
                    >
                      Associar o animal a um Lote de Recebimento.
                    </Checkbox>
                    <InfoTooltip title="Selecione os lotes de recebimento já cadastrados em Lotes/ Recebimento, somente poderão ser selecionados os lotes de recebimento pendentes e que sejam do mesmo fornecedor dos animais." />
                  </Row>
                  <Row>
                    <label
                      className={
                        props.errors.lotReceiptId && props.submitCount > 0
                          ? "formLabel error"
                          : "formLabel"
                      }
                    >
                      Lote de Recebimento
                    </label>
                  </Row>
                  <Row className="rowInput">
                    <Select
                      disabled={
                        !allowReceiptLot ||
                        isReceiptLotsLoading ||
                        isFarmAnimal ||
                        props.values.lotItemEntryId !== null
                      }
                      name="lotReceiptId"
                      loading={isReceiptLotsLoading}
                      value={
                        props.values?.lotItemReceiptId != null
                          ? props.values?.lotItemReceiptId
                          : undefined
                      }
                      placeholder={translation.defaultSelectPlaceholder}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear={true}
                      onChange={(value) => {
                        if (value == null) {
                          props.setFieldValue("lotReceiptId", null);
                          props.setFieldValue("lotItemReceiptId", null);
                        } else {
                          let lotReceipt = receiptLots.find((lot) => {
                            let item = lot.lotItems.find(
                              (item) => item.id === value
                            );

                            if (item) {
                              return true;
                            }
                            return false;
                          });
                          props.setFieldValue("lotReceiptId", lotReceipt.id);
                          props.setFieldValue("lotItemReceiptId", value);
                        }
                      }}
                    >
                      {receiptLots.length > 0 &&
                        receiptLots.map((lot) => {
                          return lot.lotItems
                            .filter((item) => {
                              return (
                                item.id === props.values?.lotItemReceiptId ||
                                (item.status !== "Confirmed" &&
                                  item.gender.substring(0, 1) ===
                                    props.values.gender &&
                                  lot.supplierId === props.values.supplierId)
                              );
                            })
                            .map((item) => {
                              return lot.isReceiptFromBoitelScenario ===
                                true ? (
                                <Option key={item.id} value={item.id}>
                                  {`Boitel:
                                  ${item.identification} /
                                  Fornecedor: ${lot.supplierName} /
                                  ${
                                    item.entryDate
                                      ? moment(item.entryDate).format(
                                          dateFormat
                                        ) + " / "
                                      : ""
                                  }
                                  ${item?.gender.substring(0, 1)} /
                                  ${item.amountAnimalsConfirmed}
                                `}
                                </Option>
                              ) : (
                                <Option key={item.id} value={item.id}>
                                  {`${lot.name} / Fornecedor: ${
                                    lot.supplierName
                                  } / ${moment(item.birthday).format(
                                    "MM/YYYY - "
                                  )} ${moment(new Date())
                                    .diff(moment(item.birthday), "months", true)
                                    .toFixed()} m / ${item?.gender.substring(
                                    0,
                                    1
                                  )} / ${item.amountAnimalsConfirmed || 0}
                              `}
                                </Option>
                              );
                            });
                        })}
                    </Select>
                  </Row>
                  <Row>
                    <label className="formLabel">Observações</label>
                  </Row>
                  <Row className="rowInput">
                    <Input
                      value={props.values.observation || undefined}
                      placeholder={translation.defaultPlaceholder}
                      style={{
                        width: "100%",
                      }}
                      onChange={(e) =>
                        props.setFieldValue("observation", e.target.value)
                      }
                    />
                  </Row>
                </Body>
                {/* Footer */}
                {animalData?.deathDate == null && (
                  <>
                    <Footer>
                      <Row type="flex" justify="space-between">
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="selectDiv"
                        >
                          <DeathButton
                            type="button"
                            onClick={async () => {
                              this.setState({ isLoading: true });
                              const {
                                data: { results: lastAnimal },
                              } = await verifyLastAnimal(animalData);
                              this.setState({
                                drawerAnimalDeathVisible: true,
                                lastAnimal,
                                isLoading: false,
                              });
                            }}
                          >
                            <StopIcon />
                            {translation.buttons.putToDeath}
                          </DeathButton>
                          {props.values.animalFarmFunction !== "fatten" &&
                            props.values.discard === false && (
                              <ButtonStandard
                                type="button"
                                buttonType="alternative"
                                onClick={() =>
                                  this.setState({
                                    drawerAnimalDiscardVisible: true,
                                  })
                                }
                              >
                                <Icon type="minus-circle" />
                                {translation.buttons.putToDiscard}
                              </ButtonStandard>
                            )}
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className="buttonsDiv"
                        >
                          <ButtonStandard
                            type="button"
                            buttonType="type7"
                            onClick={() => this.closeDrawer(props)}
                          >
                            {translation.buttons.cancel}
                          </ButtonStandard>

                          <ButtonStandard type="submit" buttonType="type6">
                            {translation.buttons.save}
                          </ButtonStandard>
                        </Col>
                      </Row>
                    </Footer>
                    <AnimalDeath
                      lotName={animalData?.lotName}
                      lastAnimal={lastAnimal}
                      title={translation.animal.drawerAnimalDeathTitle}
                      propsFormik={props}
                      visible={drawerAnimalDeathVisible}
                      closeDrawer={() =>
                        this.setState({ drawerAnimalDeathVisible: false })
                      }
                      confirmDeath={this.confirmDeath}
                    />
                    <AnimalDiscard
                      title={translation.animal.drawerAnimalDiscardTitle}
                      gender={props.values.gender}
                      propsFormik={props}
                      visible={drawerAnimalDiscardVisible}
                      closeDrawer={() =>
                        this.setState({ drawerAnimalDiscardVisible: false })
                      }
                      confirmDiscard={this.confirmDiscard}
                    />
                  </>
                )}
              </form>
            </Spin>
            <DrawerSupplier />
          </Container>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  animal: state.animal,
  supplier: state.supplier,
});

const mapDispatchToProps = (dispatch) => ({
  animalActions: bindActionCreators(AnimalActions, dispatch),
  supplierActions: bindActionCreators(SupplierActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerEditAnimal);
