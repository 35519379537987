import { useContext } from "react";
import { FarmAnimalSellContext } from "../../contexts/farmAnimalSellContext";

const useFarmAnimalSellContext = () => {
  const value = useContext(FarmAnimalSellContext);

  return value;
};

export default useFarmAnimalSellContext;
