import { useContext } from "react";
import { MultipleBullContext } from "../../contexts/multipleBullContext";

const useMultipleBullContext = () => {
  const value = useContext(MultipleBullContext);

  return value;
};

export default useMultipleBullContext;
