import React, { useState } from "react";
import { useSelector } from "react-redux";

// Components
import Breed from "../breed";
import DeathReasons from "../deathReasons";

// Styles
import { Tabs } from "antd";
import useModuleContext from "../../hooks/useModuleContext";
import { Container } from "./styles";

const AnimalParameters = () => {
  const { translation } = useSelector((state) => state.app);

  const [tabSelect, setTablSelect] = useState("1");
  const { isAllowedFunctions } = useModuleContext();

  return (
    <Container>
      <Tabs
        type="card"
        defaultActiveKey="1"
        activeKey={tabSelect}
        onChange={(key) => setTablSelect(key)}
      >
        <Tabs.TabPane
          tab={<span>{translation.parameters.tabs.breed}</span>}
          key="1"
          disabled={!isAllowedFunctions(["ANIMALS"])}
        >
          <Breed />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={<span>{translation.parameters.tabs.deathReasons}</span>}
          key="2"
          disabled={!isAllowedFunctions(["ANIMALS"])}
        >
          <DeathReasons />
        </Tabs.TabPane>
      </Tabs>
    </Container>
  );
};

export default AnimalParameters;
