import React, { useCallback, useEffect, useState } from "react";
import Axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { Spin, Icon } from "antd";
import { Main, Container } from "./styles";
import InfoTooltip from "../../../../components/utils/infoTooltip";
// Services
import { returnObjectFromMapValues } from "../../../../services/dashboards/dashboardCommonService";
import {
  getAnimalWeightRankingDashboard,
  reprocessAnimalDashboard,
} from "../../../../services/dashboards/dashboardAnimalService";
import { getParameterItems } from "../../../../services/generalParameterService";
import ButtonStandard from "../../../../components/utils/button";

const AnimalWeightRankingDash = ({ onCallBack }) => {
  // Variables
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingParameter, setIsLoadingParameter] = useState(false);
  const [parameters, setParameters] = useState(null);
  const [weightRankingStatistics, setWeightRankingStatistics] = useState(null);
  const [selectedRank, setSelectedRank] = useState(null);
  // Redux variables
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  // Methods
  function handleSelectRanking(rank, ids) {
    let result = null;
    if (selectedRank !== rank) {
      result = rank;
    } else {
      result = null;
    }
    setSelectedRank(result);
    if (result !== null && ids != null && ids?.length > 0) {
      let aIds = ids.split(",");
      let idSearch = aIds.map((a) => `'${a}'`).join(",");
      onCallBack(idSearch);
    } else if (result !== null && (ids == null || ids?.length === 0)) {
      onCallBack("null");
    } else if (result === null) {
      onCallBack("");
    }
  }

  const handleRefresh = useCallback(async () => {
    setIsLoading(true);
    try {
      await reprocessAnimalDashboard({
        groupId,
        farmId,
        dashboard: "AnimalWeightRankingDashboard",
      });
      const {
        data: { results: weightRankingDash },
      } = await getAnimalWeightRankingDashboard({
        groupId,
        farmId,
      });
      const weightRankingValues = returnObjectFromMapValues(weightRankingDash);
      setWeightRankingStatistics(weightRankingValues);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [farmId, groupId]);

  // Effect
  // Get Parameter Item values
  useEffect(() => {
    const signal = Axios.CancelToken.source();
    async function fetchData() {
      if (groupId == null || farmId == null) {
        setParameters(null);
      } else {
        setIsLoadingParameter(true);
        let dataResult = null;
        try {
          let codes = [1003];
          for (var i = 0; i < codes.length; i++) {
            let code = codes[i];
            let {
              data: { results },
            } = await getParameterItems({ groupId, farmId, code, signal });
            results.isDefault =
              results.farmId == null && results.groupId == null;
            results.updated = false;
            dataResult = results;
          }
          setParameters(dataResult);
          setIsLoadingParameter(false);
        } catch (error) {}
      }
    }
    fetchData();
    return () => {
      signal.cancel();
    };
  }, [groupId, farmId]);
  // Get Dash Values
  useEffect(() => {
    const signal = Axios.CancelToken.source();
    async function fetchData() {
      if (groupId == null || farmId == null) {
        setWeightRankingStatistics(null);
        setSelectedRank(null);
      } else {
        setIsLoading(true);
        try {
          const {
            data: { results: weightRankingDash },
          } = await getAnimalWeightRankingDashboard({
            groupId,
            farmId,
            signal,
          });
          const weightRankingValues =
            returnObjectFromMapValues(weightRankingDash);
          setWeightRankingStatistics(weightRankingValues);
        } catch (error) {
          setWeightRankingStatistics(null);
        }
        setIsLoading(false);
        setSelectedRank(null);
      }
    }
    fetchData();
    return () => {
      signal.cancel();
    };
  }, [groupId, farmId]);

  return (
    <Container>
      <Spin spinning={isLoading}>
        <Main>
          {/* Header */}
          <div className="header">
            <span>{`Dados referentes a ${moment().format("DD/MM/YYYY")}`}</span>
            <ButtonStandard
              buttonType="typeWithoutBackground"
              onClick={handleRefresh}
            >
              <Icon type="reload" />
            </ButtonStandard>
          </div>
          {/* Data */}
          <div className="data">
            {/* Title */}
            <div className="titleContainer">
              <p className="title">
                Ranking de <br />
                <strong>Pesagens</strong>
              </p>
              <InfoTooltip
                title={
                  'O ranking de pesagens permite que o sistema classifique os animais em "Cabeceira", "Meio" e "Fundo", conforme suas classificações, dentro de seus respectivos lotes de produção. Para ranquear os animais, é considerado a última pesagem em relação ao peso médio dos animais. Os parâmetros de referência de classificação a serem utilizados, são os definidos em seus respectivos lotes de produção. Animais sem classificação dentro de seus respectivos lotes não serão contabilizados.'
                }
              />
            </div>
            <div className="ranking">
              {/* Worst Rank */}
              <div
                className={`numbers ${
                  selectedRank != null
                    ? selectedRank === "worst"
                      ? "active"
                      : "inactive"
                    : ""
                }`}
                onClick={() =>
                  handleSelectRanking(
                    "worst",
                    weightRankingStatistics?.WorstAnimalsIds
                  )
                }
              >
                <div className="label">
                  <div className="circle red" />
                  <span className="rank red">
                    Fundo:{" "}
                    {isLoadingParameter ? (
                      <span className="rankValue">
                        <Icon type="loading" />
                      </span>
                    ) : (
                      <span className="rankValue">
                        &lt;{` ${parameters?.value || 0}%`}
                      </span>
                    )}
                  </span>
                </div>
                <div className="value">
                  <span>{weightRankingStatistics?.WorstAnimalsCount || 0}</span>
                </div>
              </div>
              {/* Medium Rank */}
              <div
                className={`numbers ${
                  selectedRank != null
                    ? selectedRank === "medium"
                      ? "active"
                      : "inactive"
                    : ""
                }`}
                onClick={() =>
                  handleSelectRanking(
                    "medium",
                    weightRankingStatistics?.MediumAnimalsIds
                  )
                }
              >
                <div className="label">
                  <div className="circle blue" />
                  <span className="rank blue">
                    Meio:{" "}
                    {isLoadingParameter ? (
                      <span className="rankValue">
                        <Icon type="loading" />
                      </span>
                    ) : (
                      <span className="rankValue">
                        &gt;{` ${parameters?.value || 0}%`} | &lt;
                        {` ${parameters?.valueExtra || 0}%`}
                      </span>
                    )}
                  </span>
                </div>
                <div className="value">
                  <span>
                    {weightRankingStatistics?.MediumAnimalsCount || 0}
                  </span>
                </div>
              </div>
              {/* Best Rank */}
              <div
                className={`numbers ${
                  selectedRank != null
                    ? selectedRank === "best"
                      ? "active"
                      : "inactive"
                    : ""
                }`}
                onClick={() =>
                  handleSelectRanking(
                    "best",
                    weightRankingStatistics?.BestAnimalsIds
                  )
                }
              >
                <div className="label">
                  <div className="circle green" />
                  <span className="rank green">
                    Cabeceira:{" "}
                    {isLoadingParameter ? (
                      <span className="rankValue">
                        <Icon type="loading" />
                      </span>
                    ) : (
                      <span className="rankValue">
                        &gt;{` ${parameters?.valueExtra || 0}%`}
                      </span>
                    )}
                  </span>
                </div>
                <div className="value">
                  <span>{weightRankingStatistics?.BestAnimalsCount || 0}</span>
                </div>
              </div>
            </div>
          </div>
        </Main>
      </Spin>
    </Container>
  );
};

export default AnimalWeightRankingDash;
