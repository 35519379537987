import React from "react";
import InputMask from "react-input-mask";
import { Input } from "antd";
// import { Container } from './styles';

const DocumentInput = ({ kind, ...props }) => {
  let value = props.value?.replace(/\./g, "").replace(/\//g, "").replace(/-/g, "");
  switch (kind) {
    case "cpf":
      return (
        <InputMask {...props} mask="999.999.999-99" maskChar={null}>
          {inputProps => <Input {...inputProps} />}
        </InputMask>
      );
    case "cnpj":
      return (
        <InputMask {...props} mask="99.999.999/9999-99" maskChar={null}>
          {inputProps => <Input {...inputProps} />}
        </InputMask>
      );
    case "mixed":
      return (
        <InputMask
          {...props}
          mask={
            value?.length <= 11
              ? "999.999.999-999999"
              : "99.999.999/9999-99"
          }
          maskChar={null}
        >
          {inputProps => <Input {...inputProps} />}
        </InputMask>
      );
    default:
      return null;
  }
};

export default DocumentInput;
