import React from "react";

// import { Container } from './styles';

const SuccessIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.37"
    height="15.37"
    viewBox="0 0 15.37 15.37"
  >
    <g
      id="Group_2455"
      data-name="Group 2455"
      transform="translate(-646.493 -100.493)"
    >
      <g
        id="Group_726"
        data-name="Group 726"
        transform="translate(646.493 100.493)"
      >
        <circle
          id="Ellipse_169"
          data-name="Ellipse 169"
          cx="7.685"
          cy="7.685"
          r="7.685"
          transform="translate(0 0)"
          fill="#33c162"
        />
      </g>
      <path
        id="Path_973"
        data-name="Path 973"
        d="M-6316.687,3789.92l2.061,2.552,1.887-1.849,3.021-2.96"
        transform="translate(6967.644 -3681.673)"
        fill="none"
        stroke="#fff"
        strokeWidth="2"
      />
    </g>
  </svg>
);
export default SuccessIcon;
