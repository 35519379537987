import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as BreedActions } from "../../../store/ducks/breed";
import * as Yup from "yup";
import { Formik } from "formik";
import { Row, Col, Input, Select, Switch } from "antd";
import ButtonStandard from "../../utils/button";
import { Container, Footer } from "./styles";

class DrawerBreed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formBreed: {
        id: null,
        name: "",
        description: "",
        geneticGroup: null,
        status: "Active",
      },
      saveOptions: "only_save",
    };
  }

  closeDrawer = () => {
    const { breedActions } = this.props;
    this.setState({
      formBreed: {
        id: null,
        name: "",
        description: "",
        geneticGroup: null,
        status: "Active",
      },
    });
    breedActions.hideDrawer();
  };

  handleSubmitModalForm = async (values, actions) => {
    actions.resetForm({
      id: null,
      name: "",
      description: "",
      geneticGroup: null,
      status: "Active",
    });
    this.setState({
      formBreed: {
        id: null,
        name: "",
        description: "",
        geneticGroup: null,
        status: "Active",
      },
    });
    if (values.id === null) {
      await this.handleNewBreed(values);
    } else {
      await this.handleUpdateBreed(values, values.id);
    }
  };

  handleNewBreed = async (breed) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      breedActions,
    } = this.props;
    let saveOptions = this.state.saveOptions === "only_save" ? false : true;
    await breedActions.saveBreed(groupId, farmId, null, breed, saveOptions);
  };

  handleUpdateBreed = async (breed, id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      breedActions,
    } = this.props;
    let saveOptions = this.state.saveOptions === "only_save" ? false : true;
    await breedActions.saveBreed(groupId, farmId, id, breed, saveOptions);
  };

  render() {
    const {
      app: { translation },
      breed: { breedData, drawerVisible },
    } = this.props;

    const { formBreed } = this.state;

    const validationSchema = Yup.object().shape({
      name: Yup.string().required(),
      geneticGroup: Yup.string().required(),
    });

    const { Option } = Select;

    return (
      <Container
        title={
          breedData != null
            ? translation.breed.drawerTitleEdit
            : translation.breed.drawerTitle
        }
        width={511}
        onClose={this.closeDrawer}
        maskClosable={false}
        visible={drawerVisible}
      >
        <Formik
          enableReinitialize={true}
          initialValues={breedData != null ? breedData : formBreed}
          onSubmit={this.handleSubmitModalForm}
          validationSchema={validationSchema}
          render={(props) => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <div className="drawerForm">
                <Row type="flex">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <span style={{ fontSize: "14px", color: "#000" }}>
                      {breedData != null
                        ? translation.breed.drawerSubTitleEdit
                        : translation.breed.drawerSubTitle}
                    </span>
                  </Col>
                </Row>
                {breedData != null ? (
                  <Row
                    type="flex"
                    style={{ marginTop: "13px" }}
                    justify="space-between"
                    align="middle"
                  >
                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                      <label
                        style={{
                          color: "#9074bf",
                          fontWeight: "bold",
                        }}
                      >
                        {translation.breed.form.status}
                      </label>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6} align="right">
                      {props.values.status === "Active" ? (
                        <span
                          style={{
                            color: "#A9C133",
                            fontFamily: "Asap",
                            fontWeight: "normal",
                            marginRight: 6,
                          }}
                        >
                          {translation.status.active}
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "#D44C4C",
                            fontFamily: "Asap",
                            fontWeight: "normal",
                            marginRight: 6,
                          }}
                        >
                          {translation.status.inactive}
                        </span>
                      )}
                      <Switch
                        checked={
                          props.values.status === "Active" ? true : false
                        }
                        onChange={(checked, event) => {
                          props.setValues({
                            ...props.values,
                            status: checked ? "Active" : "Inactive",
                          });
                        }}
                      />
                    </Col>
                  </Row>
                ) : null}
                {Object.entries(props.errors).length > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                {/* Input system identification */}
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label className={props.errors.name ? "error" : ""}>
                        {translation.breed.form.name} *
                      </label>
                    </Row>
                    <Row>
                      <Input
                        placeholder={translation.breed.form.namePlaceHolder}
                        value={props.values.name}
                        name="name"
                        autoComplete="chrome-off"
                        onChange={(e) => {
                          props.setValues({
                            ...props.values,
                            name: e.target.value,
                          });
                          this.setState({
                            formBreed: {
                              ...formBreed,
                              name: e.target.value,
                            },
                          });
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label>{translation.breed.form.description}</label>
                    </Row>
                    <Row>
                      <Input
                        placeholder={
                          translation.breed.form.descriptionPlaceHolder
                        }
                        value={props.values.description}
                        name="description"
                        onChange={(e) => {
                          props.setValues({
                            ...props.values,
                            description: e.target.value,
                          });
                          this.setState({
                            formBreed: {
                              ...formBreed,
                              description: e.target.value,
                            },
                          });
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label
                        className={props.errors.geneticGroup ? "error" : ""}
                      >
                        {translation.breed.form.geneticGroup} *
                      </label>
                    </Row>
                    <Row>
                      <Select
                        style={{ width: "100%" }}
                        name="geneticGroup"
                        value={
                          props.values.geneticGroup !== null
                            ? props.values.geneticGroup
                            : undefined
                        }
                        placeholder={
                          translation.breed.form.geneticGroupPlaceHolder
                        }
                        onChange={(value) => {
                          props.setValues({
                            ...props.values,
                            geneticGroup: value,
                          });
                          this.setState({
                            formBreed: {
                              ...formBreed,
                              geneticGroup: value,
                            },
                          });
                        }}
                      >
                        <Option key="Cruzado" value="Cruzado">
                          Cruzado
                        </Option>
                        <Option key="Zebuíno" value="Zebuíno">
                          Zebuíno
                        </Option>
                      </Select>
                    </Row>
                  </Col>
                </Row>
              </div>
              {/* Footer */}
              <Footer>
                <Row type="flex" justify="space-between">
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="selectDiv"
                  >
                    {props.values.id === null ? (
                      <Select
                        value={this.state.saveOptions}
                        name="saveOptions"
                        onChange={(value) =>
                          this.setState({ saveOptions: value })
                        }
                      >
                        <Option key="1" value="only_save">
                          {translation.breed.saveOptions.only_save}
                        </Option>
                        <Option key="2 " value="save_and_create_new">
                          {translation.breed.saveOptions.save_and_create_new}
                        </Option>
                      </Select>
                    ) : null}
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="buttonsDiv"
                  >
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={this.closeDrawer}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard type="submit" buttonType="type6">
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  breed: state.breed,
});

const mapDispatchToProps = (dispatch) => ({
  breedActions: bindActionCreators(BreedActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerBreed);
