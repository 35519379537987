import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import moment from "moment";
import {
  Col,
  DatePicker,
  Icon,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  notification,
} from "antd";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import { useRelocatePicket } from "../../../hooks/useRelocatePicketReducer";

import { Container } from "./styles";
import ButtonStandard from "../../utils/button";
import BoxText from "../../utils/boxText";
import NumberFormat from "react-number-format";

// Services
import {
  getPicketIndexDropDown,
  getPicketLotHistories,
  getTheLastPicketLotHistory,
} from "../../../services/picketService";
import { movePicketsFromLot } from "../../../services/lotService";
import { getProfitCenterShow } from "../../../services/profitCenterService";

const validateSchema = Yup.object().shape({
  dateMove: Yup.date().required(),
  picketIdOrigin: Yup.string().required(),
  picketOrigin: Yup.object().nullable(),
  picketIdDestination: Yup.string().required(),
  picketDestination: Yup.object().nullable(),
  pastureOriginCurrentHeight: Yup.number()
    .nullable()
    .notRequired()
    .when(["picketOrigin", "picketDestination"], {
      is: (picketOrigin, picketDestination) =>
        picketOrigin?.productionSubSystem === "I" ||
        picketDestination?.productionSubSystem === "I",
      then: Yup.number().nullable().notRequired(),
      otherwise: Yup.number().required(),
    }),
  pastureDestinationCurrentHeight: Yup.number()
    .nullable()
    .notRequired()
    .when(["picketOrigin", "picketDestination"], {
      is: (picketOrigin, picketDestination) =>
        picketOrigin?.productionSubSystem === "I" ||
        picketDestination?.productionSubSystem === "I",
      then: Yup.number().nullable().notRequired(),
      otherwise: Yup.number().required(),
    }),
});

const RelocatePicketModal = () => {
  const formik = useRef();
  const { modalVisible, lot, closeModal, picketIdOrigin } = useRelocatePicket();
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const [form, setForm] = useState({
    lotId: null,
    dateMove: moment(),
    picketIdOrigin: "",
    picketOrigin: null,
    picketIdDestination: "",
    picketDestination: null,
    pastureOriginCurrentHeight: null,
    pastureDestinationCurrentHeight: null,
  });
  const [lastPicketLotOfLot, setLastPicketLotOfLot] = useState(null);
  const [isLoadingPickets, setIsLoadingPickets] = useState(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [listPickets, setLisPickets] = useState([]);
  const [listPicketsInBreak, setLisPicketsInBreak] = useState([]);
  const signal = axios.CancelToken.source();

  // Effects
  useEffect(() => {
    let signal = axios.CancelToken.source();
    async function fetch() {
      try {
        const {
          data: { results },
        } = await getTheLastPicketLotHistory({
          groupId,
          farmId,
          lotId: lot?.id,
          signal,
        });
        setLastPicketLotOfLot(results);
      } catch (error) {}
    }
    if (lot != null) {
      fetch();
    }

    return () => {
      signal.cancel("Cancelou");
      setLastPicketLotOfLot(null);
    };
  }, [farmId, groupId, lot]);

  useEffect(() => {
    let signal = axios.CancelToken.source();
    async function fetch() {
      setIsLoadingPickets(true);
      try {
        const {
          data: { results: profitCenterLot },
        } = await getProfitCenterShow({
          groupId,
          farmId,
          id: lot?.profitCenterId,
        });
        const lotProductionSubSystem =
          profitCenterLot?.productionSubSystem === "SemiIntensive"
            ? "SI"
            : profitCenterLot?.productionSubSystem === "Extensive"
            ? "E"
            : "I";
        const {
          data: { results },
        } = await getPicketIndexDropDown({ groupId, farmId, signal });
        setLisPickets(results);
        setLisPicketsInBreak(
          results.filter(
            (p) =>
              p.status === "Break" &&
              p.productionSubSystem === lotProductionSubSystem
          )
        );
        const picketOrigin = results?.find((p) => p.id === picketIdOrigin);
        setForm((old) => ({
          ...old,
          lotId: lot?.id,
          picketOrigin,
          picketIdOrigin: picketIdOrigin,
        }));
      } catch (error) {
      } finally {
        setIsLoadingPickets(false);
      }
    }
    if (modalVisible) {
      fetch();
    }
    return () => {
      signal.cancel("Cancelou");
    };
  }, [farmId, groupId, lot, modalVisible, picketIdOrigin]);

  const handleCancel = useCallback(() => {
    formik.current.resetForm({
      lotId: null,
      dateMove: moment(),
      picketIdOrigin: "",
      picketOrigin: null,
      picketIdDestination: "",
      picketDestination: null,
      pastureOriginCurrentHeight: null,
      pastureDestinationCurrentHeight: null,
    });
    setForm({
      lotId: null,
      dateMove: moment(),
      picketIdOrigin: "",
      picketOrigin: null,
      picketIdDestination: "",
      picketDestination: null,
      pastureOriginCurrentHeight: null,
      pastureDestinationCurrentHeight: null,
    });
    setLastPicketLotOfLot(null);
    closeModal();
    signal.cancel("Cancelou");
  }, [closeModal, signal]);
  const handleSubmit = useCallback(
    async (values, actions) => {
      let lastDateOutUserLotOfPicketDestination = null;
      const body = {
        dateMove: values.dateMove,
        picketIdOrigin: values.picketIdOrigin,
        pastureOriginCurrentHeight: values.pastureOriginCurrentHeight,
        picketIdDestination: values.picketIdDestination,
        pastureDestinationCurrentHeight: values.pastureDestinationCurrentHeight,
      };
      setIsLoadingRequest(true);
      try {
        const {
          data: { results: picketDestinationLotHistory },
        } = await getPicketLotHistories({
          groupId,
          farmId,
          id: values.picketIdDestination,
        });
        if (
          picketDestinationLotHistory != null &&
          picketDestinationLotHistory.length > 0
        ) {
          const lastPicketLotHistoryFromPicketDestination =
            picketDestinationLotHistory[picketDestinationLotHistory.length - 1];
          lastDateOutUserLotOfPicketDestination =
            lastPicketLotHistoryFromPicketDestination?.dateOutUser;
        }
        if (
          lastDateOutUserLotOfPicketDestination !== null &&
          moment(lastDateOutUserLotOfPicketDestination).isAfter(values.dateMove)
        ) {
          notification.error({
            message: `O piquete escolhido para destino teve um resgistro de saída de um lote na data ${moment(
              lastDateOutUserLotOfPicketDestination
            ).format(
              "DD/MM/YYYY"
            )}. Escolha outro piquete de destino ou altere a data de movimentação.`,
            duration: 10,
          });
          setIsLoadingRequest(false);
        } else {
          await movePicketsFromLot({
            groupId,
            farmId,
            lotId: values.lotId,
            body,
            signal,
          });
          notification.success({
            message: "Os piquetes/baias foram movimentados com sucesso.",
          });
          setIsLoadingRequest(false);
          handleCancel();
        }
      } catch (error) {
        notification.error({
          title: "Erro interno",
          message:
            "Os piquetes/baias não foram movimentados. Contate o suporte",
        });
        setIsLoadingRequest(false);
      }
    },
    [farmId, groupId, handleCancel, signal]
  );

  return (
    <Modal
      visible={modalVisible}
      centered
      closable={false}
      footer={null}
      title="Movimentação entre Piquetes/Baias"
    >
      <Container>
        <Spin spinning={isLoadingRequest}>
          {/* Message */}
          <Row type="flex" gutter={8}>
            <Col span={24}>
              <p>
                Informe o Piquete/Baia destino. Para Piquetes com atividades
                Extensivas e Semi-Intensivas, informe a altura do capim do
                piquete de origem e destino.
              </p>
            </Col>
          </Row>
          {/* Show alert about the last lot allocation */}
          {lastPicketLotOfLot != null && (
            <Row type="flex">
              <BoxText
                color="#FE8D2A"
                iconComponent={
                  <Icon type="message" style={{ color: "#FE8D2A" }} />
                }
              >
                <span>
                  A sua última entrada em um piquete / baia foi:{" "}
                  <strong>
                    {moment(lastPicketLotOfLot?.dateInUser).format(
                      "DD/MM/YYYY"
                    )}
                  </strong>
                </span>
              </BoxText>
            </Row>
          )}
          {/* Form */}
          <Row type="flex" gutter={8}>
            <Col span={24}>
              <Formik
                ref={formik}
                initialValues={form}
                enableReinitialize={true}
                onSubmit={handleSubmit}
                validationSchema={validateSchema}
                render={({
                  values,
                  errors,
                  submitCount,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    {/* Body */}
                    <div className="body">
                      {Object.entries(errors).length > 0 && submitCount > 0 && (
                        <Row type="flex" justify="center" align="middle">
                          <label className="error">
                            {translation.error.formError}
                          </label>
                        </Row>
                      )}
                      <Row type="flex" justify="start" gutter={8}>
                        <Col span={24}>
                          <Row>
                            <label
                              className={
                                errors.dateMove && submitCount > 0
                                  ? "error"
                                  : ""
                              }
                            >
                              Data Movimentação
                            </label>
                          </Row>
                          <Row>
                            <DatePicker
                              name="dateMove"
                              format={"DD/MM/YYYY"}
                              value={values.dateMove}
                              allowClear={false}
                              disabledDate={(currentDate) =>
                                lastPicketLotOfLot?.dateInUser != null
                                  ? !currentDate.isBetween(
                                      lastPicketLotOfLot?.dateInUser,
                                      moment()
                                    )
                                  : !currentDate.isBetween(
                                      lot?.referenceAcquisitionDate,
                                      moment()
                                    )
                              }
                              onChange={(date) =>
                                setFieldValue("dateMove", date)
                              }
                            />
                          </Row>
                        </Col>
                      </Row>

                      <Row type="flex" justify="start" gutter={8}>
                        <Col span={16}>
                          <Row>
                            <label
                              className={
                                errors.picketIdOrigin && submitCount > 0
                                  ? "error"
                                  : ""
                              }
                            >
                              Piquete Origem
                            </label>
                          </Row>
                          <Row>
                            <Select
                              name="picketIdOrigin"
                              loading={isLoadingPickets}
                              value={
                                !isLoadingPickets
                                  ? values.picketIdOrigin || undefined
                                  : undefined
                              }
                              placeholder={translation.defaultSelectPlaceholder}
                              disabled
                            >
                              {listPickets.map((p) => (
                                <Select.Option
                                  key={p.id}
                                >{`${p.name} / Atividade: ${p.profitCenterName}`}</Select.Option>
                              ))}
                            </Select>
                          </Row>
                        </Col>
                        <Col span={8}>
                          <Row>
                            <label
                              className={
                                errors.pastureOriginCurrentHeight &&
                                submitCount > 0
                                  ? "error"
                                  : ""
                              }
                            >
                              Alt. Capim
                            </label>
                          </Row>
                          <Row>
                            <NumberFormat
                              customInput={Input}
                              value={
                                values?.pastureOriginCurrentHeight !== null
                                  ? values?.pastureOriginCurrentHeight
                                  : null
                              }
                              placeholder={translation.defaultPlaceholder}
                              name="pastureOriginCurrentHeight"
                              disabled={
                                values.picketOrigin?.productionSubSystem ===
                                  "I" ||
                                values.picketDestination
                                  ?.productionSubSystem === "I"
                              }
                              allowNegative={false}
                              addonAfter="cm"
                              decimalScale={2}
                              decimalSeparator=","
                              thousandSeparator="."
                              fixedDecimalScale={true}
                              onValueChange={({ floatValue }) => {
                                setFieldValue(
                                  "pastureOriginCurrentHeight",
                                  floatValue
                                    ? floatValue >= 0 && floatValue <= 1000
                                      ? floatValue
                                      : 100
                                    : null
                                );
                              }}
                            />
                          </Row>
                        </Col>
                      </Row>
                      <Row type="flex" justify="start" gutter={8}>
                        <Col span={16}>
                          <Row>
                            <label
                              className={
                                errors.picketIdDestination && submitCount > 0
                                  ? "error"
                                  : ""
                              }
                            >
                              Piquete Destino
                            </label>
                          </Row>
                          <Row>
                            <Select
                              loading={isLoadingPickets}
                              value={
                                !isLoadingPickets
                                  ? values.picketIdDestination || undefined
                                  : undefined
                              }
                              placeholder={translation.defaultSelectPlaceholder}
                              onChange={(value) => {
                                let picket = listPicketsInBreak.find(
                                  (p) => p.id === value
                                );
                                setFieldValue("picketDestination", picket);
                                if (picket?.productionSubSystem === "I") {
                                  setFieldValue(
                                    "pastureOriginCurrentHeight",
                                    null
                                  );
                                  setFieldValue(
                                    "pastureDestinationCurrentHeight",
                                    null
                                  );
                                }
                                setFieldValue("picketIdDestination", value);
                              }}
                            >
                              {listPicketsInBreak.map((p) => (
                                <Select.Option
                                  key={p.id}
                                >{`${p.name} / Atividade: ${p.profitCenterName}`}</Select.Option>
                              ))}
                            </Select>
                          </Row>
                        </Col>
                        <Col span={8}>
                          <Row>
                            <label
                              className={
                                errors.pastureDestinationCurrentHeight &&
                                submitCount > 0
                                  ? "error"
                                  : ""
                              }
                            >
                              Alt. Capim
                            </label>
                          </Row>
                          <Row>
                            <NumberFormat
                              customInput={Input}
                              value={
                                values?.pastureDestinationCurrentHeight !== null
                                  ? values?.pastureDestinationCurrentHeight
                                  : null
                              }
                              placeholder={translation.defaultPlaceholder}
                              name="pastureDestinationCurrentHeight"
                              disabled={
                                values.picketOrigin?.productionSubSystem ===
                                  "I" ||
                                values.picketDestination
                                  ?.productionSubSystem === "I"
                              }
                              allowNegative={false}
                              addonAfter="cm"
                              decimalScale={2}
                              decimalSeparator=","
                              thousandSeparator="."
                              fixedDecimalScale={true}
                              onValueChange={({ floatValue }) => {
                                setFieldValue(
                                  "pastureDestinationCurrentHeight",
                                  floatValue
                                    ? floatValue >= 0 && floatValue <= 1000
                                      ? floatValue
                                      : 100
                                    : null
                                );
                              }}
                            />
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    {/* Footer */}
                    <Row type="flex">
                      <Col span={24}>
                        <Row
                          type="flex"
                          justify="end"
                          align="middle"
                          gutter={8}
                        >
                          <Col>
                            <ButtonStandard
                              type="button"
                              buttonType="type4"
                              size="s"
                              onClick={handleCancel}
                            >
                              {translation.buttons.cancel}
                            </ButtonStandard>
                          </Col>
                          <Col>
                            <ButtonStandard
                              type="submit"
                              buttonType="type1"
                              size="s"
                            >
                              {translation.buttons.save}
                            </ButtonStandard>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </form>
                )}
              />
            </Col>
          </Row>
        </Spin>
      </Container>
    </Modal>
  );
};

export default RelocatePicketModal;
