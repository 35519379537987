import React, { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import useAnimalFarmReproductiveProtocolContext from "../../../../hooks/useAnimalFarmReproductiveProtocolContext";

import {
  Button,
  DatePicker,
  Icon,
  Input,
  InputNumber,
  Select,
  Table,
} from "antd";
import { TagStatus } from "../styles";

// import { Container } from './styles';

const { Column } = Table;

function AnimalReproductiveProtocolTable() {
  const [weightFilterOperator, setWeightFilterOperator] = useState("=");
  const [weightDateFilterOperator, setWeightDateFilterOperator] = useState("=");

  const { translation } = useSelector((state) => state.app);
  const {
    pagination,
    updatePagination,
    selectedAnimalsKeys,
    allAnimals,
    isLoadingAnimals,
    updateSelectedAnimalsKeys,
    getAnimals,
  } = useAnimalFarmReproductiveProtocolContext();

  const rowSelection = {
    selectedRowKeys: selectedAnimalsKeys.map((a) => a.id),
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      if (selected) {
        updateSelectedAnimalsKeys([...selectedAnimalsKeys, record]);
      } else {
        updateSelectedAnimalsKeys(
          selectedAnimalsKeys.filter((r) => r.id !== record.id)
        );
      }
    },
    onSelectAll: (record, selected, selectedRows, nativeEvent) => {
      // Get just the ids
      const keys = selectedRows;
      // Get all the selectedAnimalsKeys unless the unselected
      const lastSelectedAnimalsKeys = selectedAnimalsKeys.filter(
        (sAK) => !keys.map((k) => k.id).includes(sAK.id)
      );

      if (selected.length > 0) {
        updateSelectedAnimalsKeys(
          selectedAnimalsKeys.length > 0
            ? lastSelectedAnimalsKeys
            : [...selectedAnimalsKeys, ...keys]
        );
      } else {
        updateSelectedAnimalsKeys(lastSelectedAnimalsKeys);
      }
    },
  };

  // Methods
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          value={selectedKeys ? selectedKeys[0] : null}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
            background: "#684e94",
            borderColor: "none",
            border: "none",
          }}
        />
        <Button
          onClick={() => handleReset(clearFilters)}
          icon="delete"
          size="small"
          style={{ width: 90 }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  const getColumnWeightSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ display: "block" }}>
          <Select
            value={weightFilterOperator}
            style={{
              width: 90,
              textAlign: "center",
              marginBottom: 8,
              marginRight: 8,
            }}
            onChange={(value) => {
              setWeightFilterOperator(value);
              setSelectedKeys([]);
            }}
          >
            <Select.Option key="<" value="<" style={{ textAlign: "center" }}>
              &lt;
            </Select.Option>
            <Select.Option key="<=" value="<=" style={{ textAlign: "center" }}>
              &le;
            </Select.Option>
            <Select.Option key="=" value="=" style={{ textAlign: "center" }}>
              &#61;
            </Select.Option>
            <Select.Option key=">=" value=">=" style={{ textAlign: "center" }}>
              &ge;
            </Select.Option>
            <Select.Option key=">" value=">" style={{ textAlign: "center" }}>
              &gt;
            </Select.Option>
          </Select>
          {dataIndex === "age" ? (
            <Input
              type="number"
              onChange={(e) => setSelectedKeys(e ? [e.target.value] : [])}
              value={selectedKeys ? selectedKeys[0] : undefined}
              onPressEnter={() => handleSearch(selectedKeys, confirm)}
              style={{ width: 90, marginBottom: 8 }}
            />
          ) : (
            <InputNumber
              value={selectedKeys ? selectedKeys[0] : undefined}
              onChange={(e) => {
                setSelectedKeys(e ? [e] : []);
              }}
              onPressEnter={() => handleSearch(selectedKeys, confirm)}
              style={{ width: 90, marginBottom: 8 }}
              defaultValue={0}
              min={0}
              max={1000}
              step={0.01}
              formatter={(value) => {
                if (value !== "") {
                  value = `${value}`.replace(/[^0-9.,]*/g, "");
                  value = value.replace(/\.{2,}/g, ".");
                  value = value.replace(/\.,/g, ",");
                  value = value.replace(/,\./g, ",");
                  value = value.replace(/,{2,}/g, ",");
                  value = value.replace(/\.[0-9]+\./g, ".");
                  value =
                    value.split(".")[1] !== "" &&
                    value.split(".")[1] !== undefined
                      ? value.split(".")[0] +
                        "." +
                        value.split(".")[1].substring(0, 2)
                      : value;
                }
                return value;
              }}
            />
          )}
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  const getDateColumnSearchProps = (dataIndex, columnPlaceHolder) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ display: "block" }}>
          <Select
            value={weightDateFilterOperator || undefined}
            style={{
              width: 70,
              textAlign: "center",
              marginBottom: 8,
              marginRight: 8,
            }}
            onChange={(value) => {
              setWeightDateFilterOperator(value);
            }}
          >
            <Select.Option key="<" value="<" style={{ textAlign: "center" }}>
              &lt;
            </Select.Option>
            <Select.Option key="<=" value="<=" style={{ textAlign: "center" }}>
              &le;
            </Select.Option>
            <Select.Option key="=" value="=" style={{ textAlign: "center" }}>
              &#61;
            </Select.Option>
            <Select.Option key=">=" value=">=" style={{ textAlign: "center" }}>
              &ge;
            </Select.Option>
            <Select.Option key=">" value=">" style={{ textAlign: "center" }}>
              &gt;
            </Select.Option>
          </Select>
          <DatePicker
            value={
              selectedKeys
                ? selectedKeys[0] != null
                  ? moment(selectedKeys[0], "YYYY-MM-DD")
                  : null
                : null
            }
            placeholder={columnPlaceHolder}
            format="DD/MM/YYYY"
            onChange={(e) => setSelectedKeys(e ? [e.format("YYYY-MM-DD")] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 110, marginBottom: 8 }}
          />
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const handleTableAnimalsChange = async (pagination, filters, sorter) => {
    let search = ``;

    if (sorter["field"]) {
      sorter["field"] =
        sorter["field"] === "femaleSituation"
          ? "female_situation"
          : sorter["field"] === "animalFarmFunction"
          ? "animal_farm_function"
          : sorter["field"] === "animalReproductionState"
          ? "animal_reproduction_state"
          : sorter["field"] === "animalReproductionCategory"
          ? "animal_reproduction_category"
          : sorter["field"] === "lastBirthDate"
          ? "last_birth_date"
          : sorter["field"] === "lotName"
          ? "lot_name"
          : sorter["field"] === "breedName"
          ? "breed_name"
          : sorter["field"];
    }

    if (Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v && v.length > 0) {
          if (k === "age") {
            let operator = weightFilterOperator
              .replace("<", "%3C")
              .replace(">", "%3E");
            if (operator === "=") {
              search =
                search === "" || search === null
                  ? `age = ${v}`
                  : `${search};age = ${v}`;
            } else if (operator === "%3C=") {
              const max_value = parseFloat(v[0]) + 0.4999999;
              search =
                search === "" || search === null
                  ? `age ${operator} ${max_value}`
                  : `${search};age ${operator} ${max_value}`;
            } else if (operator === "%3E=") {
              const min_value = parseFloat(v[0]) - 0.5;
              search =
                search === "" || search === null
                  ? `age ${operator} ${min_value}`
                  : `${search};age ${operator} ${min_value}`;
            } else {
              search =
                search === "" || search === null
                  ? `age${operator}'${v[0]}'`
                  : `${search};age${operator}'${v[0]}'`;
            }
          } else if (k === "lastBirthDate") {
            let operator = weightDateFilterOperator
              .replace("<", "%3C")
              .replace(">", "%3E");
            search =
              search === "" || search === null
                ? `last_birth_date${operator}'${v[0]}'`
                : `${search};last_birth_date${operator}'${v[0]}'`;
          } else if (k === "lotName") {
            search =
              search === "" || search === null
                ? `lower(lot_name) like lower('%25${v}%25')`
                : `${search};lower(lot_name) like lower('%25${v}%25')`;
          } else if (k === "breedName") {
            search =
              search === "" || search === null
                ? `lower(breed_name) like lower('%25${v}%25')`
                : `${search};lower(breed_name) like lower('%25${v}%25')`;
          } else if (k === "handlingNumber") {
            search =
              search === "" || search === null
                ? `lower(handling_number) like lower('%25${v}%25')`
                : `${search};lower(handling_number) like lower('%25${v}%25')`;
          } else if (k === "animalFarmFunction") {
            search =
              search === "" || search === null
                ? `lower(animal_farm_function) like lower('${v}')`
                : `${search};lower(animal_farm_function) like lower('${v}')`;
          } else if (k === "animalReproductionCategory") {
            search =
              search === "" || search === null
                ? `animal_reproduction_category IN (${v
                    .map((options) => `'${options}'`)
                    .join(",")})`
                : `${search};animal_reproduction_category IN (${v
                    .map((options) => `'${options}'`)
                    .join(",")})`;
          } else if (k === "femaleSituation") {
            search =
              search === "" || search === null
                ? `female_situation IN (${v
                    .map((options) => `'${options}'`)
                    .join(",")})`
                : `${search};female_situation IN (${v
                    .map((options) => `'${options}'`)
                    .join(",")})`;
          } else if (k === "animalReproductionState") {
            search =
              search === "" || search === null
                ? `lower(animal_reproduction_state) like lower('${v}')`
                : `${search};lower(animal_reproduction_state) like lower('${v}')`;
          } else {
            search =
              search === "" || search === null
                ? `${k}='${v}'`
                : `${search};${k}='${v}'`;
          }
        }
      });
    }

    updatePagination({
      page: pagination.current,
      size: pagination.pageSize,
      sorter: sorter,
      filters: search,
      tableFilters: filters,
      tableSorters: sorter,
    });

    getAnimals(null, {
      page: pagination.current,
      size: pagination.pageSize,
      sorter: sorter,
      filters: search,
      tableFilters: filters,
      tableSorters: sorter,
    });
  };

  return (
    <Table
      key="dismemberLotAnimals"
      rowKey="id"
      loading={isLoadingAnimals}
      dataSource={allAnimals != null ? allAnimals.content : []}
      pagination={{
        total: allAnimals != null ? allAnimals.totalElements : 0,
        size: allAnimals != null ? pagination.size : 0,
        current: allAnimals != null ? pagination.page : 0,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
        hideOnSinglePage:
          allAnimals !== null && Object.entries(allAnimals).length !== 0
            ? allAnimals.totalElements > 10
              ? false
              : true
            : true,
      }}
      scroll={{ x: true }}
      size="small"
      rowSelection={rowSelection}
      onChange={handleTableAnimalsChange}
      rowClassName={(record) =>
        record.animalFarmFunction === "fatten" ||
        record.femaleSituation === "servida" ||
        record.femaleSituation === "prenha"
          ? "table-row-red"
          : ""
      }
      footer={() => (
        <span>
          <strong>Total de animais filtrados: </strong>
          {` ${allAnimals != null ? allAnimals.totalElements : 0} animais`}
        </span>
      )}
    >
      <Column
        title={
          translation.animalFarmReproductiveProtocol.form.animalsTable
            .handlingNumber
        }
        dataIndex="handlingNumber"
        sorter
        key="handlingNumber"
        filteredValue={pagination.tableFilters?.handlingNumber || null}
        sortOrder={
          pagination.sorter?.columnKey === "handlingNumber" &&
          pagination.sorter?.order
        }
        {...getColumnSearchProps("handlingNumber")}
      />
      <Column
        title={
          translation.animalFarmReproductiveProtocol.form.animalsTable.lotName
        }
        dataIndex="lotName"
        sorter
        key="lotName"
        filteredValue={pagination.tableFilters?.lotName || null}
        sortOrder={
          pagination.sorter?.columnKey === "lotName" && pagination.sorter?.order
        }
        {...getColumnSearchProps("lotName")}
      />
      <Column
        title={
          translation.animalFarmReproductiveProtocol.form.animalsTable.breedName
        }
        dataIndex="breedName"
        key="breedName"
        sorter
        filteredValue={pagination.tableFilters?.breedName || null}
        sortOrder={
          pagination.sorter?.columnKey === "breedName" &&
          pagination.sorter?.order
        }
        {...getColumnSearchProps("breedName")}
      />
      <Column
        title={translation.animalFarmReproductiveProtocol.form.animalsTable.age}
        dataIndex="age"
        key="age"
        filteredValue={pagination.tableFilters?.age || null}
        sorter
        sortOrder={
          pagination.sorter?.columnKey === "age" && pagination.sorter?.order
        }
        {...getColumnWeightSearchProps("age")}
        sortDirections={["descend", "ascend"]}
        render={(text, record) =>
          record.age !== null ? <span>{`${record.age} m`}</span> : ""
        }
      />
      <Column
        title={
          translation.animalFarmReproductiveProtocol.form.animalsTable
            .animalFarmFunction
        }
        dataIndex="animalFarmFunction"
        key="animalFarmFunction"
        sorter
        sortOrder={
          pagination.sorter?.columnKey === "animalFarmFunction" &&
          pagination.sorter?.order
        }
        filtered
        filteredValue={pagination.tableFilters?.animalFarmFunction || null}
        filters={[
          {
            text: translation.animalFarmFunction.reproduction,
            value: "reproduction",
          },
          {
            text: translation.animalFarmFunction.fatten,
            value: "fatten",
          },
        ]}
        filterMultiple={false}
        render={(text) => (text ? translation.animalFarmFunction[text] : "-")}
      />
      <Column
        title={
          translation.animalFarmReproductiveProtocol.form.animalsTable
            .animalReproductionCategory
        }
        dataIndex="animalReproductionCategory"
        key="animalReproductionCategory"
        sorter
        sortOrder={
          pagination.sorter?.columnKey === "animalReproductionCategory" &&
          pagination.sorter?.order
        }
        filtered
        filteredValue={
          pagination.tableFilters?.animalReproductionCategory || null
        }
        filters={[
          {
            text: translation.animalReproductionCategory.nulipara,
            value: "nulipara",
          },
          {
            text: translation.animalReproductionCategory.novilha,
            value: "novilha",
          },
          {
            text: translation.animalReproductionCategory.primipara,
            value: "primipara",
          },
          {
            text: translation.animalReproductionCategory.secundipara,
            value: "secundipara",
          },
          {
            text: translation.animalReproductionCategory.multipara,
            value: "multipara",
          },
        ]}
        filterMultiple={true}
        render={(text) =>
          text ? translation.animalReproductionCategory[text] : "-"
        }
      />
      <Column
        title={
          translation.animalFarmReproductiveProtocol.form.animalsTable
            .femaleSituation
        }
        dataIndex="femaleSituation"
        key="femaleSituation"
        sorter
        sortOrder={
          pagination.sorter?.columnKey === "femaleSituation" &&
          pagination.sorter?.order
        }
        filtered
        filteredValue={pagination.tableFilters?.femaleSituation || null}
        filters={[
          {
            text: translation.femaleSituation.vazia,
            value: "vazia",
          },
          {
            text: translation.femaleSituation.prenha,
            value: "prenha",
          },
          {
            text: translation.femaleSituation.servida,
            value: "servida",
          },
        ]}
        filterMultiple={true}
        render={(text) => (text ? translation.femaleSituation[text] : "-")}
      />
      <Column
        title={
          translation.animalFarmReproductiveProtocol.form.animalsTable
            .animalReproductionState
        }
        dataIndex="animalReproductionState"
        key="animalReproductionState"
        sorter
        sortOrder={
          pagination.sorter?.columnKey === "animalReproductionState" &&
          pagination.sorter?.order
        }
        filtered
        filteredValue={pagination.tableFilters?.animalReproductionState || null}
        filters={[
          {
            text: translation.animalReproductionState.solteira,
            value: "solteira",
          },
          {
            text: translation.animalReproductionState.parida,
            value: "parida",
          },
        ]}
        filterMultiple={false}
        render={(text) =>
          text ? translation.animalReproductionState[text] : "-"
        }
      />
      <Column
        title={
          translation.animalFarmReproductiveProtocol.form.animalsTable
            .lastBirthDate
        }
        dataIndex="lastBirthDate"
        key="lastBirthDate"
        sorter
        filteredValue={pagination.tableFilters?.lastBirthDate || null}
        sortOrder={
          pagination.sorter?.columnKey === "lastBirthDate" &&
          pagination.sorter?.order
        }
        {...getDateColumnSearchProps("lastBirthDate")}
        render={(text) => (text ? moment(text).format("DD/MM/YYYY") : "-")}
      />
    </Table>
  );
}

export default AnimalReproductiveProtocolTable;
