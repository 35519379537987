import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as AppActions } from "../../../store/ducks/app";
import moment from "moment";
import {
  Container,
  NotificationContainerItem,
  NotificationItem,
  NotificationType,
  ButtonReadAllNotifications,
} from "./styles";
import { Row, Col, Drawer, Spin, Empty } from "antd";

/* Services */
import {
  visualizeOneNotification,
  visualizeAllNotifications,
  getNotificationIndex,
} from "../../../services/notificationsService";

const DrawerNotifications = (props) => {
  const [limitNotifications, setLimitNotifications] = useState(10);
  const [notifications, setNotifications] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    drawerNotificationVisible,
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
    notifications: appNotifications,
  } = useSelector((state) => state.app);

  useEffect(() => {
    if (props.newNotificationFromWS != null)
      setNotifications((oldNots) => [props.newNotificationFromWS, ...oldNots]);
  }, [props.newNotificationFromWS]);

  const closeDrawer = () => {
    dispatch(AppActions.showHideDrawerNotifications());
  };

  const loadMoreNotifications = () => {
    setIsLoading(true);
    setTimeout(async () => {
      setLimitNotifications(limitNotifications + 5);
      await fetchData(limitNotifications + 5);
      setIsLoading(false);
    }, 900);
  };

  const svgTickInsideCircle = (
    <svg
      id="tick-inside-circle"
      xmlns="http://www.w3.org/2000/svg"
      width="13.882"
      height="13.882"
      viewBox="0 0 13.882 13.882"
    >
      <g id="check-circle-outline">
        <path
          id="Path_1118"
          data-name="Path 1118"
          d="M4.1,5.622l-.972.972L6.247,9.717l6.941-6.941L12.216,1.8,6.247,7.774Zm8.4,1.319A5.553,5.553,0,1,1,6.941,1.388,5.413,5.413,0,0,1,8.468,1.6L9.578.486A8.448,8.448,0,0,0,6.941,0a6.941,6.941,0,1,0,6.941,6.941Z"
          fill="#684e94"
        />
      </g>
    </svg>
  );

  const onOpenDrawer = async (visible) => {
    if (visible) {
      await fetchData();
    }
  };

  const fetchData = async (size = 10) => {
    setIsLoading(true);
    try {
      const {
        data: {
          results: { content: allNotifications, totalElements },
        },
      } = await getNotificationIndex({
        groupId,
        farmId,
        withoutPagination: false,
        sorter: {},
        size,
      });
      const editedallNotifications =
        allNotifications.length > 0 &&
        allNotifications.map((n) => {
          n.createdAt = moment(n.createdAt)
            .subtract(3, "hours")
            .format("DD/MM/YYYY - HH:mm");
          return n;
        });

      setNotifications(editedallNotifications);
      setTotalElements(totalElements);
    } catch (error) {
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 900);
    }
  };

  const handleVisualizeNotification = async (n) => {
    if (n.read === false) {
      setIsLoading(true);
      try {
        const {
          data: { results: notification },
        } = await visualizeOneNotification({
          groupId,
          farmId,
          id: n.id,
        });
        dispatch(AppActions.updateOneNotificationVisualization(notification));
        const updateNotificationsOne = notifications.map((n2) => {
          if (n2.id === n.id) {
            n2.notificationUsers = notification.notificationUsers;
            n2.read = true;
          }
          return n2;
        });
        setNotifications(updateNotificationsOne);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleVisualizeAllNotifications = async () => {
    setIsLoading(true);
    try {
      const {
        data: { results: notificationsResult },
      } = await visualizeAllNotifications({
        groupId,
        farmId,
      });
      dispatch(
        AppActions.updateAllNotificationsVisualization(notificationsResult)
      );
      const updateNotificationsAll = notifications.map((n) => {
        if (notificationsResult.find((n2) => n.id === n2.id) != null) {
          n.notificationUsers = notificationsResult.find(
            (n2) => n.id === n2.id
          ).notificationUsers;
          n.read = true;
        }
        return n;
      });
      setNotifications(updateNotificationsAll);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      title={translation.notifications.drawer.title}
      width={807}
      onClose={closeDrawer}
      maskClosable={true}
      visible={drawerNotificationVisible}
      afterVisibleChange={onOpenDrawer}
    >
      <Container>
        <Row type="flex" justify="space-between">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} align="left">
            <strong>
              {notifications != null &&
              appNotifications != null &&
              appNotifications.totalElements > 0
                ? `${appNotifications.totalElements} ${translation.notifications.drawer.notificationsNotRead}`
                : ""}
            </strong>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
            <ButtonReadAllNotifications
              type="button"
              onClick={handleVisualizeAllNotifications}
              disabled={
                appNotifications != null && appNotifications.totalElements > 0
                  ? false
                  : true
              }
            >
              {svgTickInsideCircle}
              {` ${translation.notifications.drawer.buttonVilualizeAll}`}
              <span className="slider" />
            </ButtonReadAllNotifications>
          </Col>
        </Row>
        <Spin spinning={isLoading}>
          {notifications != null && notifications.length > 0 ? (
            <NotificationContainerItem>
              {notifications.map(
                (notification) =>
                  notification != null && (
                    <NotificationItem
                      className={notification.read ? "read" : ""}
                      key={notification.id}
                      onClick={() => handleVisualizeNotification(notification)}
                    >
                      <Row type="flex" justify="start">
                        <Col xs={2} sm={2} md={2} lg={2} xl={2} align="middle">
                          <div
                            className="icon"
                            dangerouslySetInnerHTML={{
                              __html: notification.icon.icon,
                            }}
                          />
                        </Col>
                        <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                          <Row type="flex" justify="start">
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                              align="left"
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: notification.message,
                                }}
                              />
                            </Col>
                          </Row>
                          <Row type="flex" justify="start">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <span>
                                <NotificationType
                                  notificationType={notification.type}
                                >
                                  {notification.type === "NewObject"
                                    ? translation.notifications.types.newObject
                                    : notification.type === "Alert"
                                    ? translation.notifications.types.alert
                                    : notification.type === "Warning"
                                    ? translation.notifications.types.warning
                                    : notification.type === "Purchase"
                                    ? translation.notifications.types.purchase
                                    : notification.type === "Sale" &&
                                      translation.notifications.types.sale}
                                </NotificationType>
                                <span className="time">
                                  {notification.createdAt}
                                </span>
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </NotificationItem>
                  )
              )}
            </NotificationContainerItem>
          ) : (
            <Empty />
          )}
        </Spin>
        <Row type="flex" justify="center" align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} align="middle">
            <ButtonReadAllNotifications
              type="button"
              onClick={loadMoreNotifications}
              disabled={limitNotifications >= totalElements}
            >
              {translation.notifications.drawer.buttonLoadOldNotifications}
              <span className="slider" />
            </ButtonReadAllNotifications>
          </Col>
        </Row>
      </Container>
    </Drawer>
  );
};

export default DrawerNotifications;
