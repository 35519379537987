import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Spin } from "antd";
import { Container, CustomDivider } from "./styles";
/** Services */
import { numberMask } from "../../../../services/helpersMethodsService";

const DrawerPastureWeatherSeasonDetails = ({
  data,
  onCancel,
  drawerVisible,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(null);
  const { translation } = useSelector((state) => state.app);

  function handleOpenDrawer(visible) {
    if (visible) {
      setIsLoading(true);
      setForm({
        ...data,
        minimumConsumption:
          data.minimumConsumption == null || data.minimumConsumption === 0
            ? data.consumptionType === "PV_ANIMAL"
              ? 2
              : 0
            : data.minimumConsumption,
        maximumConsumption:
          data.maximumConsumption == null || data.maximumConsumption === 0
            ? data.consumptionType === "PV_ANIMAL"
              ? 3
              : 0
            : data.maximumConsumption,
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }

  return (
    <Container
      title={
        <span>
          {`Dados da forrageira em `}
          <strong
            className={
              form != null
                ? form?.season === "Water"
                  ? "water"
                  : form.season === "Transition"
                  ? "transition"
                  : form.season === "Dry"
                  ? "dry"
                  : ""
                : ""
            }
          >
            {form != null
              ? form?.season === "Water"
                ? "Águas"
                : form.season === "Transition"
                ? "Transição"
                : form.season === "Dry"
                ? "Seca"
                : ""
              : " - "}
          </strong>
          {`: Pasto `}
          <strong>
            {form != null
              ? form?.fertilized
                ? "adubado"
                : "não adubado"
              : " - "}
          </strong>
        </span>
      }
      width={511}
      closable
      onClose={onCancel}
      maskClosable={false}
      visible={drawerVisible}
      afterVisibleChange={handleOpenDrawer}
    >
      <Spin spinning={isLoading}>
        <div className="drawerForm">
          {/* Body */}
          <Row type="flex" align="middle">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Row>
                <label>{translation.pasture.form.cost}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">
                  {`${numberMask(
                    form?.cost || 0,
                    true,
                    undefined,
                    undefined,
                    form?.cost >= 0.1 ? 2 : 3
                  )} /kg`}
                </span>
              </Row>
            </Col>
          </Row>
          <CustomDivider>
            {translation.pasture.form.nutritionalData}
          </CustomDivider>
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            {/* NDT */}
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Row>
                <label>{translation.pasture.form.tdn}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">
                  {numberMask(form?.tdn || 0)} g/kg
                </span>
              </Row>
            </Col>
            {/* PB */}
            <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
              <Row>
                <label>{translation.pasture.form.cp}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">
                  {numberMask(form?.cp || 0)} g/kg
                </span>
              </Row>
            </Col>
          </Row>
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            {/* FDN */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <label>{translation.pasture.form.fdn}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">
                  {numberMask(form?.fdn || 0)} %
                </span>
              </Row>
            </Col>
          </Row>
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            {/* CALCIO */}
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
              <Row>
                <label>{translation.pasture.form.calcium}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">
                  {numberMask(form?.calcium || 0)} g/kg
                </span>
              </Row>
            </Col>
            {/* FÓSFORO */}
            <Col xs={7} sm={7} md={7} lg={7} xl={7} offset={1}>
              <Row>
                <label>{translation.pasture.form.phosphorus}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">
                  {numberMask(form?.phosphorus || 0)} g/kg
                </span>
              </Row>
            </Col>
            {/* NITROGENIO */}
            <Col xs={7} sm={7} md={7} lg={7} xl={7} offset={1}>
              <Row>
                <label>{translation.pasture.form.nitrogen}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">
                  {numberMask(form?.nitrogen || 0)} mg/kg
                </span>
              </Row>
            </Col>
          </Row>
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            {/* ZINCO */}
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
              <Row>
                <label>{translation.pasture.form.zinc}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">
                  {numberMask(form?.zinc || 0)} mg/kg
                </span>
              </Row>
            </Col>
            {/* SODIO */}
            <Col xs={7} sm={7} md={7} lg={7} xl={7} offset={1}>
              <Row>
                <label>{translation.pasture.form.sodium}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">
                  {numberMask(form?.sodium || 0)} g/kg
                </span>
              </Row>
            </Col>
          </Row>

          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            {/* FDA */}
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
              <Row>
                <label>{translation.pasture.form.fda}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">
                  {numberMask(form?.fda || 0)} g/kg
                </span>
              </Row>
            </Col>
            {/* LIGNIN */}
            <Col xs={7} sm={7} md={7} lg={7} xl={7} offset={1}>
              <Row>
                <label>{translation.pasture.form.lignin}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">
                  {numberMask(form?.lignin || 0)} g/kg
                </span>
              </Row>
            </Col>
            {/* EE */}
            <Col xs={7} sm={7} md={7} lg={7} xl={7} offset={1}>
              <Row>
                <label>{translation.pasture.form.ee}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">
                  {numberMask(form?.ee || 0)} %/MS
                </span>
              </Row>
            </Col>
          </Row>

          <CustomDivider>CAPACIDADE DE CRESCIMENTO ESPERADO</CustomDivider>
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            {/* FORMAÇÃO */}
            <Col s={12} sm={12} md={12} lg={12} xl={12}>
              <Row>
                <label>{translation.pasture.form.formation}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">{form?.formation} dias</span>
              </Row>
            </Col>
            {/* DESCANSO */}
            <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
              <Row>
                <label>{translation.pasture.form.rest}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">{form?.rest} dias</span>
              </Row>
            </Col>
          </Row>
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            {/* ALTURA DE ENTRADA */}
            <Col s={12} sm={12} md={12} lg={12} xl={12}>
              <Row>
                <label>{translation.pasture.form.entryHeight}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">
                  {numberMask(form?.entryHeight || 0)} cm
                </span>
              </Row>
            </Col>
            {/* ALTURA DE SAÍDA */}
            <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
              <Row>
                <label>{translation.pasture.form.exitHeight}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">
                  {numberMask(form?.exitHeight || 0)} cm
                </span>
              </Row>
            </Col>
          </Row>
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            {/* TAXA DE CRESCIMENTO */}
            <Col s={12} sm={12} md={12} lg={12} xl={12}>
              <Row>
                <label>{translation.pasture.form.growthRate}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">
                  {numberMask(form?.growthRate || 0)} kg/ha/dia
                </span>
              </Row>
            </Col>
            {/* PRODUÇÃO MS FOLIAR (t/ha/corte) */}
            <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
              <Row>
                <label>{translation.pasture.form.leafDensityProduction}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">
                  {numberMask(form?.leafDensityProduction || 0)} t/ha/corte
                </span>
              </Row>
            </Col>
          </Row>
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            {/* ALTURA MÁXIMA */}
            <Col s={12} sm={12} md={12} lg={12} xl={12}>
              <Row>
                <label>{translation.pasture.form.maximumHeight}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">
                  {numberMask(form?.maximumHeight || 0)} cm
                </span>
              </Row>
            </Col>
          </Row>
          <CustomDivider>CAPACIDADE POR CONSUMO ESPERADO</CustomDivider>
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            <Col s={12} sm={12} md={12} lg={12} xl={12}>
              <Row>
                <label>{translation.pasture.form.minimumConsumption}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">
                  {numberMask(form?.minimumConsumption || 0)}{" "}
                  {form?.consumptionType === "PV_ANIMAL" ? "%PV" : "kg"}
                </span>
              </Row>
            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
              <Row>
                <label>{translation.pasture.form.maximumConsumption}</label>
              </Row>
              <Row className="rowInput">
                <span className="rowInputData">
                  {numberMask(form?.maximumConsumption || 0)}{" "}
                  {form?.consumptionType === "PV_ANIMAL" ? "%PV" : "kg"}
                </span>
              </Row>
            </Col>
          </Row>
        </div>
      </Spin>
    </Container>
  );
};

export default DrawerPastureWeatherSeasonDetails;
