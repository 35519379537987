import React from "react";
import { useSelector } from "react-redux";
import { Container } from "./styles";
import { Row, Col } from "antd";
import RainIcon from "./icons/rain";
import SunIcon from "./icons/sun";
import TransitionIcon from "./icons/transition";

const DisplayWeatherSeason = ({ data }) => {
  const { translation } = useSelector((state) => state.app);
  return (
    <Container>
      <Row type="flex" justify="start" gutter={16}>
        {data != null && (
          <>
            {data.map((s) => (
              <Col span={2} align="center" key={s.id}>
                <Row type="flex" justify="center">
                  {s.season === "Water" ? (
                    <RainIcon />
                  ) : s.season === "Dry" ? (
                    <SunIcon />
                  ) : s.season === "Transition" ? (
                    <TransitionIcon />
                  ) : null}
                </Row>
                <Row type="flex" justify="center" className="rowInfo">
                  <span className="monthName">
                    {s.month === 1
                      ? translation.months.jan
                      : s.month === 2
                      ? translation.months.feb
                      : s.month === 3
                      ? translation.months.mar
                      : s.month === 4
                      ? translation.months.apr
                      : s.month === 5
                      ? translation.months.mmay
                      : s.month === 6
                      ? translation.months.jun
                      : s.month === 7
                      ? translation.months.jul
                      : s.month === 8
                      ? translation.months.aug
                      : s.month === 9
                      ? translation.months.sep
                      : s.month === 10
                      ? translation.months.oct
                      : s.month === 11
                      ? translation.months.nov
                      : s.month === 12
                      ? translation.months.dec
                      : null}
                  </span>
                </Row>
                <Row type="flex" justify="center" className="rowInfo">
                  <span className="monthDays">{s.monthDays}</span>
                </Row>
              </Col>
            ))}
          </>
        )}
      </Row>
    </Container>
  );
};

export default DisplayWeatherSeason;
