import React from "react";

// import { Container } from './styles';

const PlusCirclePurpleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
  >
    <defs>
      <filter
        id="Ellipse_217"
        x="0"
        y="0"
        width="36"
        height="36"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dx="1" dy="1" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="2.5" result="blur" />
        <feFlood floodOpacity="0.231" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g
      id="Component_31_6"
      data-name="Component 31 – 6"
      transform="translate(6.5 6.5)"
    >
      <g transform="matrix(1, 0, 0, 1, -6.5, -6.5)" filter="url(#Ellipse_217)">
        <circle
          id="Ellipse_217-2"
          data-name="Ellipse 217"
          cx="10.5"
          cy="10.5"
          r="10.5"
          transform="translate(6.5 6.5)"
          fill="#9074bf"
        />
      </g>
      <g
        id="Group_2994"
        data-name="Group 2994"
        transform="translate(-725 -706)"
      >
        <g
          id="Group_2991"
          data-name="Group 2991"
          transform="translate(1425.75 236.25) rotate(90)"
        >
          <line
            id="Line_570"
            data-name="Line 570"
            y2="8.5"
            transform="translate(480.5 686)"
            fill="none"
            stroke="#9074bf"
            strokeLinecap="round"
            strokeWidth="3"
          />
          <line
            id="Line_571"
            data-name="Line 571"
            y2="8.5"
            transform="translate(480.5 686)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="2"
          />
        </g>
        <path
          id="Path_2021"
          data-name="Path 2021"
          d="M0,0V8.5"
          transform="translate(735.5 712.5)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);
export default PlusCirclePurpleIcon;
