import styled from "styled-components";
import { Drawer, Divider } from "antd";

export const Container = styled(Drawer)`
  .ant-drawer-body {
    padding: 24px 0px 10px 0px;
  }

  .drawerForm {
    width: 100%;
    min-height: -moz-calc(100vh - 165px);
    min-height: -webkit-calc(100vh - 165px);
    min-height: calc(100vh - 165px);
    padding: 0px 24px;

    #bovexoAvatarUser {
      width: 100%;
      height: 100%;
    }

    .personal-image {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .personal-image input[type="file"] {
      display: none;
    }
    .personal-figure {
      position: relative;
      width: 120px;
      height: 120px;
      border-radius: 100%;
      background-color: transparent;
      border: 2px dashed #4b4b4b;
      border-spacing: 2px;
    }
    .personal-avatar {
      cursor: pointer;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 100%;
      border: 2px solid transparent;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
      transition: all ease-in-out 0.3s;
    }
    .personal-avatar:hover {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    }
    .personal-figcaption,
    .personal-figcaption-with-image {
      cursor: pointer;
      position: absolute;
      top: 0px;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      opacity: 1;
      background-color: rgba(0, 0, 0, 0);
      transition: all ease-in-out 0.3s;
    }
    .personal-figcaption:hover,
    .personal-figcaption-with-image:hover {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .personal-figcaption-with-image:hover > img {
      visibility: visible;
      margin-top: 32.5px;
      width: 50px;
      height: 50px;
    }
    .personal-figcaption-with-image > img {
      visibility: hidden;
    }
    .personal-figcaption > img {
      margin-top: 32.5px;
      width: 50px;
      height: 50px;
    }
  }

  .rowLabel {
    margin-bottom: 7px;
    margin-top: 19px;
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    margin-bottom: 7px;
    margin-top: 19px;
    &.error {
      color: #d44c4c;
    }
  }

  .rowInput {
    margin-top: 8px;
  }

  .tableButtonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      display: flex;
      justify-content: center !important;
      text-align: center !important;
      align-items: center !important;
      font-size: 14px !important;
      width: 77px !important;
      height: 30px !important;

      svg {
        margin-right: 3px !important;
      }
    }

    button:first-of-type {
      width: 116px !important;
      margin-right: 12px !important;
    }
  }

  .ant-switch-checked {
    background-color: #a9c133 !important;
  }

  div.icon {
    margin: 0 5px;
    padding: 5px;
    border: 1px solid #c4c4c4;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      background: transparent;
      border: none;
      margin: 0px;
      padding: 0px;
      color: #4b4b4b;
      svg,
      i {
        margin: 0;
        width: 15px;
        height: 15px;
      }
      &:hover,
      &:active {
        color: #4b4b4b;
      }
    }
    &:hover,
    &:active {
      background: #c4c4c4;
    }
  }
`;

export const AnimalsAmount = styled.div`
  height: 35px;
  border-radius: 17.5px;
  background: transparent;
  border: 1px solid #d44c4c;
  padding: 0 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-family: "Asap", sans-serif;
    font-size: 14px;
    color: #d44c4c;
  }

  span.number {
    font-size: 20px;
  }

  &.haveAnimals {
    background: #4a85ae;
    border: 1px solid #4a85ae;
    span {
      color: #fff;
    }

    span.number {
      font-size: 20px;
    }
  }
`;

export const ModalTitle = styled.span`
  font-size: 25px;
  font-family: "Asap", sans-serif;
  font-weight: bold;
  color: #4b4b4b;
`;

export const ButtonInputNumber = styled.button`
  background: transparent;
  color: #fff;
  border: none;
  width: 100% !important;
  height: 100% !important;

  &:hover {
    cursor: pointer;
  }

  svg {
    margin-left: 5px;
  }
`;

export const Footer = styled.div`
  border-top: 1px solid #e8e8e8;
  margin-top: 20px;
  width: 100%;
  padding: 10px 24px;
  border-radius: 0 0 4px 4px;

  .buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding-right: 24px; */

    button:first-of-type {
      margin-right: 12px !important;
    }
  }

  .selectDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: "Asap", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;

export const CustomDivider = styled(Divider)`
  margin: 10px 0 !important;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 10px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;
