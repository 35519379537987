import React from "react";

// import { Container } from './styles';

function IconExpirated() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
    >
      <g
        id="Group_3305"
        data-name="Group 3305"
        transform="translate(-714 -256)"
      >
        <g
          id="Ellipse_170"
          data-name="Ellipse 170"
          transform="translate(714 256)"
          fill="#fb9e9e"
          stroke="#b54747"
          strokeWidth="1.5"
        >
          <circle cx="20.5" cy="20.5" r="20.5" stroke="none" />
          <circle cx="20.5" cy="20.5" r="19.75" fill="none" />
        </g>
        <g id="fast" transform="translate(719.42 266.78)">
          <g
            id="Group_1231"
            data-name="Group 1231"
            transform="translate(1.358 6.021)"
          >
            <g id="Group_1230" data-name="Group 1230">
              <rect
                id="Rectangle_502"
                data-name="Rectangle 502"
                width="2.884"
                height="1.472"
                fill="#b54747"
              />
            </g>
          </g>
          <g
            id="Group_1233"
            data-name="Group 1233"
            transform="translate(1.358 11.907)"
          >
            <g id="Group_1232" data-name="Group 1232">
              <rect
                id="Rectangle_503"
                data-name="Rectangle 503"
                width="2.884"
                height="1.472"
                fill="#b54747"
              />
            </g>
          </g>
          <g
            id="Group_1235"
            data-name="Group 1235"
            transform="translate(0 8.964)"
          >
            <g id="Group_1234" data-name="Group 1234">
              <rect
                id="Rectangle_504"
                data-name="Rectangle 504"
                width="4.242"
                height="1.472"
                fill="#b54747"
              />
            </g>
          </g>
          <g
            id="Group_1237"
            data-name="Group 1237"
            transform="translate(5.714 0)"
          >
            <g id="Group_1236" data-name="Group 1236">
              <path
                id="Path_1081"
                data-name="Path 1081"
                d="M126.189,58.245a9.7,9.7,0,1,0,9.7,9.7A9.711,9.711,0,0,0,126.189,58.245Zm0,17.929a8.229,8.229,0,1,1,8.229-8.229A8.238,8.238,0,0,1,126.189,76.174Z"
                transform="translate(-116.489 -58.245)"
                fill="#b54747"
              />
            </g>
          </g>
          <g
            id="Group_1239"
            data-name="Group 1239"
            transform="translate(14.418 5.313)"
          >
            <g id="Group_1238" data-name="Group 1238">
              <path
                id="Path_1082"
                data-name="Path 1082"
                d="M295.4,171.31v-4.753H293.93v5.531l3.792,2.584.829-1.216Z"
                transform="translate(-293.93 -166.557)"
                fill="#b54747"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconExpirated;
