import React, { useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Creators as FarmSellActions } from "../../store/ducks/farmSell";
import { Empty, notification, Row, Spin } from "antd";
import ButtonStandard from "../../components/utils/button";
import ArrowRedLeftIcon from "../../components/utils/icons/arrows/red/left";
import { Container } from "./styles";
import ClosedSalesReportBody from "./components/body";
// Services
import { downloadClosedSaleReport } from "../../services/saleScenarioResultFarmSellService";
import { downloadFarmSaleReport } from "../../services/farmSellService";

const ClosedSalesReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { closeReportResponseData, closeReportRequest } = useSelector(
    (state) => state.farmSell
  );
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const history = useHistory();

  // Methods
  const goBack = () => {
    dispatch(FarmSellActions.cleanClosedReportData());
    dispatch(FarmSellActions.cleanClosedReportRequest());

    if (history.location.state?.origin === "farmSell") {
      history.push("/admin/financial", { tab: "1" });
    } else {
      history.push("/admin/decisions", { tab: "1" });
    }
  };

  const handleGeneratePdf = async () => {
    const payload = {
      groupId,
      farmId,
      body: closeReportRequest,
    };
    setIsLoading(true);
    try {
      let origin = history.location.state?.origin;
      const { data } =
        origin === "farmSell"
          ? await downloadFarmSaleReport(payload)
          : await downloadClosedSaleReport(payload);
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      let link = document.createElement("a");
      link.href = downloadUrl;
      const dayFormat = moment().format("YYYYMMDD");
      const hourFormat = moment().format("HHmm");
      link.setAttribute(
        "download",
        `Resultado_Vendas_${dayFormat}_${hourFormat}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      notification.error({
        message: "Não foi possível gerar o PDF. Contate o suporte.",
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Container>
      <Spin spinning={isLoading}>
        <Row>
          <div className="back-container back" onClick={goBack}>
            <ArrowRedLeftIcon />
            <span className="back">Voltar</span>
          </div>
        </Row>
        {closeReportResponseData != null ? (
          <main>
            <table className="header">
              <tbody>
                <tr>
                  <td className="scenario-content">
                    <table>
                      <tbody>
                        <tr>
                          <td className="align-center farm-logo"></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td className="center-content">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <h1>Resultado de Venda(s)</h1>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Período: </strong>
                            <span>{closeReportResponseData?.period}</span>
                          </td>
                          <td className="text-align-right">
                            <strong>Cenário: </strong>
                            <span>
                              {closeReportResponseData?.saleScenarioNames ||
                                "-"}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Cliente: </strong>
                            <span>
                              {closeReportResponseData?.clientName || "-"}
                            </span>
                          </td>
                          <td className="text-align-right">
                            <strong>Fornecedor: </strong>
                            <span>
                              {closeReportResponseData?.supplierName || "-"}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td className="default-content">
                    <div>
                      <ButtonStandard
                        buttonType="alternative"
                        onClick={handleGeneratePdf}
                      >
                        Gerar PDF
                      </ButtonStandard>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="simulation-table">
              <ClosedSalesReportBody
                closeReportResponseData={closeReportResponseData}
              />
            </table>
          </main>
        ) : (
          <Empty />
        )}
      </Spin>
    </Container>
  );
};

export default ClosedSalesReport;
