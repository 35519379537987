import apiAnimals from "../config/api_animals";
import getHeaders from "./getHeaders";

// import axios from "axios";
// const apiAnimals = axios.create({
//   baseURL: "http://localhost:8084",
// });

export function getBreedIndexActive(payload) {
  const { groupId, farmId, signal, withoutBreedCurveParam = false } = payload;
  if (groupId != null && farmId != null) {
    return apiAnimals.get(
      `/bovexo/${groupId}/farms/${farmId}/breeds/active?withoutBreedCurveParam=${withoutBreedCurveParam}`,
      getHeaders(false, signal)
    );
  } else {
    return new Promise((resolve, reject) => {
      reject("Sem grupo ou fazenda");
    });
  }
}

export function getBreedIndex(payload) {
  const {
    groupId,
    farmId,
    page,
    sorter,
    filters,
    signal,
    size,
    withoutPagination,
  } = payload;

  const { field, order } = sorter;
  if (groupId != null && farmId != null) {
    let fieldUnderscore =
      Object.entries(sorter).length > 0
        ? field
            .replace(/\.?([A-Z]+)/g, function (x, y) {
              return "_" + y.toLowerCase();
            })
            .replace(/^_/, "")
        : "";

    const orderS = order === "descend" ? "DESC" : "ASC";

    return apiAnimals.get(
      `/bovexo/${groupId}/farms/${farmId}/breeds?
      ${withoutPagination ? `&withoutPagination=${withoutPagination}` : ""}
      ${page ? `&page=${page - 1}` : ""}
      ${size ? `&size=${size}` : ""}
      ${
        Object.entries(sorter).length > 0
          ? `&sort=${fieldUnderscore}&direction=${orderS}`
          : `&sort=name&direction=${orderS}`
      }
      ${filters !== "" ? `&search=${filters}` : ""}`,
      getHeaders(false, signal)
    );
  } else {
    return new Promise((resolve, reject) => {
      reject("Sem grupo ou fazenda");
    });
  }
}

export function getBreedShow(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/breeds/${id}`,
    getHeaders(false, signal)
  );
}

export function getBreedShowReactAsync({ payload }) {
  const { groupId, farmId, id, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/breeds/${id}`,
    getHeaders(false, signal)
  );
}

export function getBreedParametersShow(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/breeds/${id}/parameters/default`,
    getHeaders(false, signal)
  );
}

export function saveBreed(payload) {
  const { groupId, farmId, id, breed, signal } = payload;
  if (id != null) {
    return apiAnimals.patch(
      `/bovexo/${groupId}/farms/${farmId}/breeds/${id}`,
      breed,
      getHeaders(false, signal)
    );
  } else {
    return apiAnimals.post(
      `/bovexo/${groupId}/farms/${farmId}/breeds`,
      breed,
      getHeaders(false, signal)
    );
  }
}

export function saveBreedParameters(payload) {
  const { groupId, farmId, breedId, id, breedParameters, signal } = payload;
  if (id != null) {
    return apiAnimals.patch(
      `/bovexo/${groupId}/farms/${farmId}/breeds/${breedId}/parameters/${id}`,
      breedParameters,
      getHeaders(false, signal)
    );
  } else {
    return apiAnimals.post(
      `/bovexo/${groupId}/farms/${farmId}/breeds/${breedId}/parameters`,
      breedParameters,
      getHeaders(false, signal)
    );
  }
}

export function deleteBreedServ(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiAnimals.delete(
    `/bovexo/${groupId}/farms/${farmId}/breeds/${id}`,
    getHeaders(false, signal)
  );
}

export function getBreedCurveParam(payload) {
  const { id, signal } = payload;

  return apiAnimals.get(
    `/bovexo/breeds/${id}/curve/parameters`,
    getHeaders(false, signal)
  );
}

export function inactivateBreedParameter(payload) {
  const { groupId, farmId, breedId, id, signal } = payload;

  return apiAnimals.patch(
    `/bovexo/${groupId}/farms/${farmId}/breeds/${breedId}/parameters/${id}/inactivate`,
    {},
    getHeaders(false, signal)
  );
}
export function activateBreedParameter(payload) {
  const { groupId, farmId, breedId, id, signal } = payload;

  return apiAnimals.patch(
    `/bovexo/${groupId}/farms/${farmId}/breeds/${breedId}/parameters/${id}/activate`,
    {},
    getHeaders(false, signal)
  );
}
