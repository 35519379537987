import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFinancialReducer } from "../../../../hooks/useFinancialReducer";
import { CardCustom, Title, EditButton } from "../../styles";
import {
  Button,
  Col,
  Dropdown,
  Icon,
  Input,
  Menu,
  Row,
  Table,
  notification,
} from "antd";

// Components
import ButtonStandard from "../../../../components/utils/button";
import FinancialCostCenterModal from "./costCenterModal";

// Services
import { findAllFinancialParametersByGroupIdAndFarmId } from "../../../../services/financialParameterService";
import DrawerFinancialClass from "../../../../components/drawers/financialClass";
import TagStatus from "../../../../components/utils/tagStatus";
import { Container as MenuContainer } from "../../../../components/utils/table/menu/styles";
import MenuIcon from "../../../../components/utils/table/icons/menu";
import {
  activateFinancialClass,
  inactivateFinancialClass,
  resetFinancialClassDefaultValues,
} from "../../../../services/financialClassService";
import InfoTooltip from "../../../../components/utils/infoTooltip";

const CostingParameters = () => {
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const {
    state: {
      listFinancialParameters: data,
      isLoadingListFinancialParameters: isLoading,
    },
    dispatch: financialDispatch,
  } = useFinancialReducer();

  const { translation } = useSelector((state) => state.app);
  const [isCostCenterModalVisible, setIsCostCenterModalVisible] =
    useState(false);
  const [isDrawerFinancialClassVisible, setIsDrawerFinancialClassVisible] =
    useState(false);
  const [dataDrawerFincialClass, setDataDrawerFincialClass] = useState(null);

  const Column = Table.Column;

  // Methods

  const refreshListFinancialParameters = useCallback(async () => {
    try {
      financialDispatch({
        type: "START_STOP_LOADING_LIST_FINANCIAL_PARAMETERS",
      });
      const {
        data: { results },
      } = await findAllFinancialParametersByGroupIdAndFarmId({
        groupId,
        farmId,
      });
      financialDispatch({
        type: "UPDATE_LIST_FINANCIAL_PARAMETERS",
        payload: {
          data: results,
        },
      });
    } catch (error) {
    } finally {
      financialDispatch({
        type: "START_STOP_LOADING_LIST_FINANCIAL_PARAMETERS",
      });
    }
  }, [farmId, financialDispatch, groupId]);

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        switch (dataIndex) {
          case "natureType":
            let labelValue =
              record[dataIndex] === "expense" ? "Despesa" : "Receita";
            return labelValue
              .toLowerCase()
              .includes(value.toString().toLowerCase());

          case "depreciation":
            return Number(record[dataIndex]) === Number(value);

          default:
            return record[dataIndex]
              ?.toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase());
        }
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
        }
      },
    }),
    [handleReset, handleSearch]
  );

  const handleOpenCloseCostCenterModal = useCallback(() => {
    setIsCostCenterModalVisible(!isCostCenterModalVisible);
  }, [isCostCenterModalVisible, setIsCostCenterModalVisible]);

  const handleOpenFinancialClassDrawer = useCallback((data = null) => {
    setIsDrawerFinancialClassVisible(true);
    setDataDrawerFincialClass(data);
  }, []);

  const handleCloseFinancialClassDrawer = useCallback(() => {
    setIsDrawerFinancialClassVisible(false);
    setDataDrawerFincialClass({
      groupId: null,
      farmId: null,
      id: null,
      name: null,
      status: "Active",
      financialNatureId: null,
      depreciation: "",
      shouldCost: true,
      shouldDisbursement: true,
      costCenterName: null,
    });
    refreshListFinancialParameters();
  }, [refreshListFinancialParameters]);

  const handleEdit = useCallback(
    async (record) => {
      handleOpenFinancialClassDrawer({
        groupId: record.groupId,
        farmId: record.farmId,
        id: record.classId,
        name: record.className,
        depreciation: record.depreciation,
        shouldCost: record.shouldCost,
        shouldDisbursement: record.shouldDisbursement,
        financialNatureId: record.natureId,
        status: "Active",
      });
    },
    [handleOpenFinancialClassDrawer]
  );
  const handleInactive = useCallback(
    async (id) => {
      try {
        await inactivateFinancialClass({ groupId, farmId, id });
        notification.success({
          message: "Classe financeira inativada com sucesso.",
        });
        refreshListFinancialParameters();
      } catch (error) {
        notification.error({
          message:
            "Não foi possivel inativar classe financeira. Contate o suporte.",
        });
      }
    },
    [groupId, farmId, refreshListFinancialParameters]
  );
  const handleActivate = useCallback(
    async (id) => {
      try {
        await activateFinancialClass({ groupId, farmId, id });
        notification.success({
          message: "Classe financeira ativada com sucesso.",
        });
        refreshListFinancialParameters();
      } catch (error) {
        notification.error({
          message:
            "Não foi possivel ativar classe financeira. Contate o suporte.",
        });
      }
    },
    [farmId, groupId, refreshListFinancialParameters]
  );
  const handleResetValues = useCallback(
    async (id) => {
      try {
        await resetFinancialClassDefaultValues({ groupId, farmId, id });
        notification.success({
          message:
            "Classe financeira redefinado com sucesso, os valores padrões da BovExo foram restaurados.",
        });
        refreshListFinancialParameters();
      } catch (error) {
        notification.error({
          message:
            "Não foi possivel redefinir classe financeira. Contate o suporte.",
        });
      }
    },
    [farmId, groupId, refreshListFinancialParameters]
  );

  const menu = (id, record) => {
    return (
      <Menu>
        {record.classStatus === "Active" && (
          <Menu.Item key="1" onClick={() => handleEdit(record)}>
            {translation.table.menu.edit}
          </Menu.Item>
        )}
        {record.classStatus === "Active" && (
          <Menu.Item key="2" onClick={() => handleInactive(id)}>
            {translation.table.menu.inactivate}
          </Menu.Item>
        )}
        {record.classStatus === "Inactive" && (
          <Menu.Item key="3" onClick={() => handleActivate(id)}>
            {translation.table.menu.activate}
          </Menu.Item>
        )}
        {record.defaultEdit && (
          <Menu.Item key="4" onClick={() => handleResetValues(id)}>
            Redefinir valores padrão
          </Menu.Item>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    async function fetch() {
      try {
        financialDispatch({
          type: "START_STOP_LOADING_LIST_FINANCIAL_PARAMETERS",
        });
        const {
          data: { results },
        } = await findAllFinancialParametersByGroupIdAndFarmId({
          groupId,
          farmId,
        });
        financialDispatch({
          type: "UPDATE_LIST_FINANCIAL_PARAMETERS",
          payload: {
            data: results,
          },
        });
      } catch (error) {
      } finally {
        financialDispatch({
          type: "START_STOP_LOADING_LIST_FINANCIAL_PARAMETERS",
        });
      }
    }
    fetch();

    return () => {
      financialDispatch({
        type: "UPDATE_LIST_FINANCIAL_PARAMETERS",
        payload: {
          data: [],
        },
      });
    };
  }, [farmId, financialDispatch, groupId]);

  return (
    <CardCustom>
      <Row type="flex" justify="space-between" className="rowHeader">
        <Col>
          <Title>{translation.financial.parameters.costing.title}</Title>
        </Col>
        <Col>
          <ButtonStandard
            buttonType="typeWithoutBackground"
            disabled={isLoading}
            onClick={refreshListFinancialParameters}
          >
            <Icon type="reload" />
          </ButtonStandard>
          <ButtonStandard
            buttonType="type8"
            onClick={() =>
              handleOpenFinancialClassDrawer({
                id: null,
                name: null,
                status: "Active",
                financialNatureId: null,
                groupId,
                farmId,
                depreciation: "",
                shouldCost: true,
                costCenterName: null,
              })
            }
          >
            Nova classe
          </ButtonStandard>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            loading={isLoading}
            rowKey="classId"
            size="small"
            dataSource={data != null ? data : []}
            pagination={{
              hideOnSinglePage:
                data !== null && data.length > 10 ? false : true,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
            }}
            scroll={{
              x: true,
            }}
            style={{ width: "100%", margin: "0" }}
          >
            <Column
              title={translation.inseminator.table.columns.status}
              dataIndex="classStatus"
              key="classStatus"
              align="left"
              sorter={(first, second) => {
                return first.classStatus.localeCompare(
                  second.classStatus,
                  "pt-BR",
                  {
                    numeric: false,
                    ignorePunctuation: true,
                  }
                );
              }}
              defaultFilteredValue={["Active"]}
              filtered
              filters={[
                {
                  text: translation.status.active,
                  value: "Active",
                },
                {
                  text: translation.status.inactive,
                  value: "Inactive",
                },
              ]}
              filterMultiple
              onFilter={(value, record) => record.classStatus.includes(value)}
              render={(status) =>
                status === "Active" ? (
                  <TagStatus
                    background="#C5F1CA"
                    borderColor="#106518"
                    color="#106518"
                  >
                    {translation.status.active}
                  </TagStatus>
                ) : (
                  <TagStatus
                    background="#FBC7C7"
                    borderColor="#D44C4C"
                    color="#D44C4C"
                  >
                    {translation.status.inactive}
                  </TagStatus>
                )
              }
            />
            <Column
              title={
                translation.financial.parameters.costing.table.columns.nature
              }
              dataIndex="natureName"
              key="natureName"
              align="left"
              sorter={(first, second) => {
                return first.natureName.localeCompare(
                  second.natureName,
                  "pt-BR",
                  {
                    numeric: false,
                    ignorePunctuation: true,
                  }
                );
              }}
              {...handleGetColumnSearchProps("natureName")}
            />

            <Column
              title={
                translation.financial.parameters.costing.table.columns.type
              }
              dataIndex="natureType"
              key="natureType"
              align="left"
              sorter={(first, second) => {
                return first.natureType.localeCompare(
                  second.natureType,
                  "pt-BR",
                  {
                    numeric: false,
                    ignorePunctuation: true,
                  }
                );
              }}
              {...handleGetColumnSearchProps("natureType")}
              render={(text) =>
                text === "expense" ? <span>Despesa</span> : <span>Receita</span>
              }
            />

            <Column
              title={
                translation.financial.parameters.costing.table.columns.stock
              }
              dataIndex="stock"
              key="stock"
              align="left"
              sorter={(first, second) => {
                return first.stock
                  .toString()
                  .localeCompare(second.stock.toString(), "pt-BR", {
                    numeric: false,
                    ignorePunctuation: true,
                    sensitivity: "base",
                  });
              }}
              filtered
              filters={[
                {
                  text: "S",
                  value: true,
                },
                {
                  text: "N",
                  value: false,
                },
              ]}
              onFilter={(value, record) => {
                return record.stock === value;
              }}
              render={(stock) => (stock ? <span>S</span> : <span>N</span>)}
            />
            <Column
              title={
                translation.financial.parameters.costing.table.columns.class
              }
              dataIndex="className"
              key="className"
              align="left"
              sorter={(first, second) => {
                if (!first.className) {
                  first.className = "";
                }
                return first.className.localeCompare(
                  second.className,
                  "pt-BR",
                  {
                    numeric: false,
                    ignorePunctuation: true,
                    sensitivity: "base",
                  }
                );
              }}
              {...handleGetColumnSearchProps("className")}
              render={(text, record) => (
                <span>
                  {text}{" "}
                  {record.defaultEdit && (
                    <InfoTooltip title={"Valor padrão alterado"} />
                  )}
                </span>
              )}
            />

            <Column
              title={
                translation.financial.parameters.costing.table.columns
                  .depreciation
              }
              dataIndex="depreciation"
              align="left"
              key="depreciation"
              sorter={(first, second) => {
                if (!first.depreciation) {
                  first.depreciation = 0;
                }
                return first?.depreciation - second?.depreciation;
              }}
              {...handleGetColumnSearchProps("depreciation")}
            />
            <Column
              title={
                translation.financial.parameters.costing.table.columns
                  .shouldCost
              }
              dataIndex="shouldCost"
              align="left"
              key="shouldCost"
              filtered
              filters={[
                {
                  text: "S",
                  value: true,
                },
                {
                  text: "N",
                  value: false,
                },
              ]}
              onFilter={(value, record) => {
                return record.shouldCost === value;
              }}
              sorter={(first, second) => {
                return first?.shouldCost === second?.shouldCost
                  ? 0
                  : first?.shouldCost
                  ? -1
                  : 1;
              }}
              render={(text, record) => (
                <span>{record.shouldCost === true ? "S" : "N"}</span>
              )}
            />
            <Column
              title={
                translation.financial.parameters.costing.table.columns
                  .shouldDisbursement
              }
              dataIndex="shouldDisbursement"
              align="left"
              key="shouldDisbursement"
              filtered
              filters={[
                {
                  text: "S",
                  value: true,
                },
                {
                  text: "N",
                  value: false,
                },
              ]}
              onFilter={(value, record) => {
                return record.shouldDisbursement === value;
              }}
              sorter={(first, second) => {
                return first?.shouldDisbursement === second?.shouldDisbursement
                  ? 0
                  : first?.shouldDisbursement
                  ? -1
                  : 1;
              }}
              render={(text, record) => (
                <span>{record.shouldDisbursement === true ? "S" : "N"}</span>
              )}
            />
            <Column
              title={() => {
                return (
                  <Row type="flex" justify="start" align="middle" gutter={8}>
                    <Col>
                      <span>
                        {
                          translation.financial.parameters.costing.table.columns
                            .costCenter
                        }
                      </span>
                    </Col>
                    <Col>
                      <EditButton onClick={handleOpenCloseCostCenterModal}>
                        <Icon type="select" rotate={90} />
                      </EditButton>
                    </Col>
                  </Row>
                );
              }}
              align="left"
              key="costCenterItemName"
              dataIndex="costCenterItemName"
            />
            <Table.Column
              title=""
              align="left"
              render={(text, record) => (
                <Dropdown
                  overlay={menu(record.classId, record)}
                  trigger={["click"]}
                  key={record.classId}
                >
                  <MenuContainer>
                    <MenuIcon />
                  </MenuContainer>
                </Dropdown>
              )}
            />
          </Table>
          <FinancialCostCenterModal
            isModalVisible={isCostCenterModalVisible}
            closeModal={handleOpenCloseCostCenterModal}
          />
          <DrawerFinancialClass
            isDrawerFinancialClassVisible={isDrawerFinancialClassVisible}
            data={dataDrawerFincialClass}
            handleCloseDrawer={handleCloseFinancialClassDrawer}
          />
        </Col>
      </Row>
    </CardCustom>
  );
};

export default CostingParameters;
