import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Login from "../pages/login";
import Admin from "../pages/admin";
import Register from "../pages/register";
import RegisterGroupAndFarm from "../pages/registerGroupAndFarm";
import changePassword from "../pages/changePassword";
import AccessConfirmation from "../pages/accessConfirmation";
import { RegisterContextProvider } from "../contexts/registerContext";
import accessSupplierGroupConfirmation from "../pages/accessSupplierGroupConfirmation";
import RegisterSupplierGroup from "../pages/registerSupplierGroup";
import SupplierGroup from "../pages/supplierGroup";

const Routes = () => (
  <RegisterContextProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/login" exact component={Login} />
        <Route path="/admin" component={Admin} />
        <Route path="/register" exact component={Register} />
        <Route
          path="/register/group/farm"
          exact
          component={RegisterGroupAndFarm}
        />
        <Route path="/change/password" exact component={changePassword} />
        <Route path="/validate/access/:id" component={AccessConfirmation} />
        <Route path="/validate/supplier/group/:id" component={accessSupplierGroupConfirmation} />
        <Route path="/validate/register/supplier/group/:id" component={RegisterSupplierGroup} />
        <Route path="/supplier" component={SupplierGroup} />
      </Switch>
    </BrowserRouter>
  </RegisterContextProvider>
);
export default Routes;
