import styled from "styled-components";
import { lighten } from "polished";

export const Container = styled.table`
  --border-color: #f5f5f5;

  width: 100%;

  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 3px 6px #dcdcdc;

  border-collapse: collapse;

  span.header {
    font-family: Asap, sans-serif;
    font-weight: bold;
    font-style: italic;
    font-size: 11px;
    text-align: left;
    color: #a5a5a5;
  }

  thead {
    th {
      font-size: 0.875rem;
      padding: 8px 0px;
      text-align: center;
    }
    border-bottom: 2px solid #684e94;
  }

  td {
    font-size: 0.75rem;
    width: 50px;
    text-align: center;
  }

  tbody {
    td {
      border-top: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
      border-right: 1px solid var(--border-color);
      border-left: 1px solid var(--border-color);

      &:nth-child(1) {
        border-left: none !important;
        font-weight: 700;
      }

      &:nth-child(11) {
        border-right: none !important;
      }

      &.red {
        background-color: ${lighten(0.3, "#cc3300")};
      }

      &.orange {
        background-color: ${lighten(0.2, "#ff9966")};
      }

      &.yellow {
        background-color: ${lighten(0.2, "#ffcc00")};
      }

      &.green {
        background-color: ${lighten(0.2, "#99cc33")};
      }
    }

    tr:first-of-type {
      td {
        border-top: none;
      }
    }
  }
`;
