import styled from "styled-components";
import { Drawer } from "antd";

export const Container = styled(Drawer)`
  .ant-drawer-body {
    padding: 10px 0px 10px 0px;
  }

  p {
    display: inline-block;
    margin-bottom: 0;
  }

  .drawerForm {
    width: 100%;
    min-height: 600px;
    padding: 0px 24px;
  }

  .drawerFormCustom {
    width: 100%;
    height: 145px;
    padding: 0px 24px;
  }

  .rowLabel {
    margin-bottom: 7px;
    margin-top: 19px;
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    margin-bottom: 7px;
    margin-top: 19px;
    &.error {
      color: #d44c4c;
    }
  }

  .rowInput {
    margin-top: 8px;
  }

  .tableButtonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      display: flex;
      justify-content: center !important;
      text-align: center !important;
      align-items: center !important;
      font-size: 14px !important;
      width: 77px !important;
      height: 30px !important;

      svg {
        margin-right: 3px !important;
      }
    }

    button:first-of-type {
      width: 116px !important;
      margin-right: 12px !important;
    }
  }

  .ant-switch-checked {
    background-color: #a9c133 !important;
  }

  .resend {
    background: transparent;
    border: none;
    color: #A9C133 !important;
    font-size: 14px;
    cursor: pointer;
  }

  .hide {
    display: none;
  }

`;



export const Card = styled.div`
  height: 230px;
  width: 100%;
  padding: 0px 15px;
  border-radius: 6px;
  box-shadow: 0px 3px 6px #d8d8d8;

  img{
    margin: 0px auto;
  }

`;


export const CardItem= styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  width: 100%;
  padding: 15px;
  border-bottom: 1px solid #E2E2E2;
  color: black;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
  
  span {
    margin-left: 5px;
    font-size: 14px;
  }

  svg {
    height: 20px;
    width: 30px;
    path {
      fill: #a5a5a5 !important;
    }
  }

  .failed {
    height: 6px;
    width: 6px;
  }

 

  .new{
      color: #c4c4c4;
      font-style: italic;
      font-size: 13px;
  }
`;