import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { withRouter, Link, useRouteMatch, useHistory } from "react-router-dom";
import {
  notification,
  Checkbox,
  Spin,
  Input,
  Icon,
  Row,
  DatePicker,
} from "antd";
import moment from "moment";

/* Styles */
import {
  Wrapper,
  Container,
  RegisterContainer,
  FormContainer,
  DisplayContainer,
  DisplayBody,
  DisplayBodyMessage,
  LogoContainer,
  TitleMessage,
  SubTitleMessage,
  MessageContainer,
} from "./styles";

/* Components */
import ButtonStandard from "../../components/utils/button";
import NumberFormat from "react-number-format";
import IconAlreadyAccept from "./icons/alreadyAccept";
import IconJustConfirm from "./icons/justConfirm";
import IconExpirated from "./icons/expirated";

/* Images */
import LogoVertical from "../../components/login/logos/logoVertical";

/** Services */
import {
  verifyInviteStatus,
  registerUserByInvitation,
  verifyDocument
} from "../../services/userInviteService";

const AccessConfirmation = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    fullname: null,
    email: null,
    birthday: null,
    phone: null,
    password: null,
    confirmPassword: null,
  });
  const [errors, setErrors] = useState([]);
  const [checkStatus, setCheckStatus] = useState(null); // just_confirm, already_confirm, expirated, register
  const [checkTerm, setCheckTerm] = useState(false);
  /** Variable props */
  const {
    params: { id: inviteId },
  } = useRouteMatch();
  // Effect
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const {
          data: {
            results: {
              checkStatus,
              userInvite: { email },
            },
          },
        } = await verifyInviteStatus(inviteId);
        setForm({
          fullname: null,
          email,
          birthday: null,
          phone: null,
          password: null,
          confirmPassword: null,
        });
        setCheckStatus(checkStatus);
        setLoading(false);
      } catch (error) {
        setCheckStatus(null);
        setLoading(false);
      }
    }
    fetchData();
  }, [inviteId]);

  // Redux Variable
  const { translation } = useSelector((state) => state.app);
  // Methods
  function goToLogin() {
    history.push("/login");
  }
  function validateForm() {
    let aErrors = [];
    if (form?.fullname == null || form?.fullname?.trim() === "")
      aErrors.push("fullname");
    if (form?.document == null || form?.document?.trim() === "")
      aErrors.push("document");
    if (form?.phone == null || form?.phone?.trim() === "")
      aErrors.push("phone");
    if (form?.password == null || form?.password?.trim() === "")
      aErrors.push("password");
    if (form?.confirmPassword == null || form?.confirmPassword?.trim() === "")
      aErrors.push("confirmPassword");
    if (
      (form?.password != null || form?.password?.trim() !== "") &&
      (form?.confirmPassword != null || form?.confirmPassword?.trim() !== "") &&
      form?.password !== form.confirmPassword
    )
      aErrors.push("differentPassword");
    setErrors(aErrors);
    return aErrors.length > 0 ? false : true;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);

      verifyDocument(form.document).then(async () => {
        try {
          await registerUserByInvitation(form, inviteId);
          setLoading(false);
          goToLogin()
        } catch (error) {
          setLoading(false);
          notification.error({
            message: "Não foi possível cadastrar usuário",
          });
        }
      }).catch(
        (error) => {
          setLoading(false);
          notification.error({
            message: "Já existe usuário cadastrado com este documento",
          });
        });
    }
  }

  return (
    <Wrapper>
      <span className="logo-vertical">
        <LogoVertical />
      </span>
      <Container>
        <RegisterContainer>
          <LogoContainer>
            <LogoVertical />
          </LogoContainer>
          <DisplayBodyMessage>
            <span>
              Decisões que <br />
              engordam <strong>o bolso</strong>
            </span>
          </DisplayBodyMessage>
        </RegisterContainer>
        <DisplayContainer>
          <DisplayBody>
            <Spin spinning={loading}>
              {checkStatus === "register" ? (
                <FormContainer>
                  <span className="title">
                    Parece que você ainda não possui cadastro
                  </span>
                  <span className="subTitle">
                    Informe os campos abaixo para cadastrar o seu usuário
                  </span>
                  {errors.length > 0 && (
                    <>
                      <Row type="flex" justify="center" align="middle">
                        <label className="error">
                          {translation.error.formError}
                        </label>
                      </Row>
                      {errors.includes("differentPassword") && (
                        <Row type="flex" justify="center" align="middle">
                          <label className="error">
                            A senha e a confirmação de senha, estão diferentes
                          </label>
                        </Row>
                      )}
                    </>
                  )}
                  <div className="form">
                    <label>Email</label>
                    <Input
                      type="email"
                      value={form?.email}
                      autoCapitalize="off"
                      placeholder="Informe aqui..."
                      name="email"
                      suffix={<Icon type="lock" />}
                      disabled
                    />
                    <label
                      className={errors.includes("fullname") ? "error" : ""}
                    >
                      Nome completo*
                    </label>
                    <Input
                      type="fullname"
                      value={form?.fullname}
                      placeholder="Informe aqui..."
                      name="fullname"
                      onChange={(e) => {
                        setForm({ ...form, fullname: e.target.value });
                        setErrors(errors.filter((e) => e !== "fullname"));
                      }}
                    />

                    <label
                      className={
                        errors.includes("document") ? "error" : "input"
                      }
                    >
                      CPF*
                    </label>
                    <NumberFormat
                      customInput={Input}
                      placeholder="Informe aqui..."
                      name="document"
                      style={{ maxWidth: 170 }}
                      value={form?.document}
                      onChange={(e) => {
                        setForm({ ...form, document: e.target.value });
                        setErrors(errors.filter((e) => e !== "document"));
                      }}
                      format="###.###.###-##"
                    />

                    <label
                      className={
                        errors.includes("birthday") ? "error" : "input"
                      }
                    >
                      Data de Nascimento*
                    </label>
                    <DatePicker
                      name="birthday"
                      style={{ maxWidth: 170 }}
                      value={
                        form?.birthday != null
                          ? moment(form.birthday, "YYYY-MM-DD")
                          : undefined
                      }
                      placeholder={translation.defaultDatePickerPlaceholder}
                      onChange={(date, dateString) => {
                        if (date !== null) {
                          setForm({ ...form, birthday: date });
                          setErrors(errors.filter((e) => e !== "birthday"));
                        }
                      }}
                      format="DD/MM/YYYY"
                    />

                    <label
                      className={errors.includes("phone") ? "error" : "input"}
                    >
                      Contato*
                    </label>
                    <NumberFormat
                      customInput={Input}
                      style={{ maxWidth: 170 }}
                      placeholder="(xx) xxxxx-xxxx"
                      name="phone"
                      onChange={(e) => {
                        setForm({ ...form, phone: e.target.value });
                        setErrors(errors.filter((e) => e !== "phone"));
                      }}
                      format="(##) #####-####"
                    />
                    {/* Input password */}
                    <label
                      className={
                        errors.includes("password") ||
                        errors.includes("differentPassword")
                          ? "error"
                          : "input"
                      }
                    >
                      Nova senha
                    </label>
                    <Input.Password
                      placeholder={translation.defaultPlaceholder}
                      value={form?.password}
                      name="password"
                      onChange={(e) => {
                        setForm({ ...form, password: e.target.value });
                        setErrors(errors.filter((e) => e !== "password"));
                      }}
                    />
                    {/* Input confirm password */}
                    <label
                      className={
                        errors.includes("confirmPassword") ||
                        errors.includes("differentPassword")
                          ? "error"
                          : "input"
                      }
                    >
                      Confirme a nova senha
                    </label>
                    <Input.Password
                      type="confirmPassword"
                      placeholder={translation.defaultPlaceholder}
                      value={form?.confirmPassword}
                      name="confirmPassword"
                      onChange={(e) => {
                        setForm({ ...form, confirmPassword: e.target.value });
                        setErrors(
                          errors.filter(
                            (e) =>
                              e !== "confirmPassword" &&
                              e !== "differentPassword"
                          )
                        );
                      }}
                    />

                    <Checkbox
                      onChange={() => setCheckTerm(!checkTerm)}
                      style={{
                        color: "#4B4B4B",
                        fontWeight: "normal",
                        fontSize: 12,
                        textTransform: "none",
                        marginTop: 10,
                      }}
                    >
                      Ao assinalar a caixa você concorda com as
                      <Link to="/">
                        <strong> condições e regras de uso </strong>
                      </Link>
                      do sistema Bovexo
                    </Checkbox>

                    <div className="formFooter">
                      <ButtonStandard
                        type="submit"
                        buttonType="type1"
                        size="m"
                        disabled={!checkTerm}
                        onClick={handleSubmit}
                      >
                        {translation.buttons.save}
                      </ButtonStandard>
                    </div>
                  </div>
                </FormContainer>
              ) : checkStatus === "just_confirm" ? (
                <MessageContainer>
                  <Row>
                    <IconJustConfirm />
                  </Row>
                  <Row className="rowTitle">
                    <TitleMessage>Convite foi confirmado!</TitleMessage>
                  </Row>
                  <Row className="rowSubTitle">
                    <SubTitleMessage>
                      Clique no botão abaixo e efetue o login.
                    </SubTitleMessage>
                  </Row>
                  <Row>
                    <ButtonStandard
                      type="submit"
                      buttonType="typeWithoutBackground"
                      padding="0px 0px"
                      isUpperCase={true}
                      onClick={goToLogin}
                    >
                      OK, ENTENDI
                    </ButtonStandard>
                  </Row>
                </MessageContainer>
              ) : checkStatus === "already_confirm" ? (
                <MessageContainer>
                  <Row>
                    <IconAlreadyAccept />
                  </Row>
                  <Row className="rowTitle">
                    <TitleMessage>Convite já confirmado</TitleMessage>
                  </Row>
                  <Row className="rowSubTitle">
                    <SubTitleMessage>
                      Clique no botão abaixo e efetue o login.
                    </SubTitleMessage>
                  </Row>
                  <Row>
                    <ButtonStandard
                      type="submit"
                      buttonType="typeWithoutBackground"
                      padding="0px 0px"
                      isUpperCase={true}
                      onClick={goToLogin}
                    >
                      OK, ENTENDI
                    </ButtonStandard>
                  </Row>
                </MessageContainer>
              ) : checkStatus === "expirated" ? (
                <MessageContainer>
                  <Row>
                    <IconExpirated />
                  </Row>
                  <Row className="rowTitle">
                    <TitleMessage>Convite expirado!</TitleMessage>
                  </Row>
                  <Row className="rowSubTitle">
                    <SubTitleMessage>
                      Aguarde ou entre em contato com o responsável para
                      reenviar o convite
                    </SubTitleMessage>
                  </Row>
                  <Row>
                    <ButtonStandard
                      type="submit"
                      buttonType="typeWithoutBackground"
                      padding="0px 0px"
                      isUpperCase={true}
                      onClick={goToLogin}
                    >
                      OK, ENTENDI
                    </ButtonStandard>
                  </Row>
                </MessageContainer>
              ) : (
                <div></div>
              )}
            </Spin>
          </DisplayBody>
        </DisplayContainer>
      </Container>
    </Wrapper>
  );
};
export default withRouter(AccessConfirmation);
