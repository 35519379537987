import axios from "axios";

const auth = axios.create({
  baseURL: `${process.env.REACT_APP_BOVEXO_IAM_URL}/oauth/token`,
  // baseURL: `http://localhost:8080/oauth/token`,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  timeout: 60000,
  auth: {
    username: "USER_CLIENT_APP",
    password: "password",
  },
});

export default auth;
