import React, { createContext, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Creators as UserActions } from "../../store/ducks/user";

// Services
import { checkAccountFarmService } from "../../services/accountFarmService";

export const HomeContext = createContext({});

export const HomeContextProvider = ({ children }) => {
  const {
    app: {
      translation,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
    user: { homeConfigData },
  } = useSelector((state) => state);

  const [isLoading, setIsLoading] = useState(false);
  const [showContractButton, setShowContractButton] = useState(false);

  const dispatch = useDispatch();

  // Show/Hide contract button
  useEffect(() => {
    async function fetchProfile() {
      const {
        data: { results },
      } = await checkAccountFarmService({ groupId, farmId });

      if (results?.type && results?.type === "Trial") {
        setShowContractButton(true);
      } else {
        setShowContractButton(false);
      }
    }

    function fetchHomeConfig() {
      dispatch(UserActions.getHomeConfig(groupId, farmId));
    }

    if (groupId && farmId) {
      try {
        setIsLoading(true);
        fetchProfile();
        fetchHomeConfig();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [groupId, farmId]);

  return (
    <HomeContext.Provider
      value={{
        translation,
        groupId,
        farmId,
        isLoading,
        showContractButton,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};
