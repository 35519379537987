import {
  Button,
  Col,
  DatePicker,
  Icon,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import ButtonStandard from "../../../../../components/utils/button";
import { LotDetailsSupplementSupplyContextProvider } from "../../../../../contexts/lotDetailsSupplementSupplyContext";
import useLotDetailsSupplementSupplyContext from "../../../../../hooks/useLotDetailsSupplementSupplyContext";
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../../../services/helpersMethodsService";
import { useSelector } from "react-redux";
import LotDetailsSupplementSupplyDashboards from "./dashboards";

// import { Container } from './styles';

function LotDetailsSupplementSupplyTable() {
  const { translation } = useSelector((state) => state.app);

  const { dataList, isLoadingDataList } =
    useLotDetailsSupplementSupplyContext();

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);
  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            placeholder={translation.defaultSelectPlaceholder}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return record[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
        }
      },
    }),
    [handleReset, handleSearch, translation]
  );

  const handleGetColumnNumberSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <div style={{ display: "block" }}>
            <Select
              value={
                selectedKeys
                  ? selectedKeys[0]?.type != null
                    ? selectedKeys[0]?.type
                    : "equal"
                  : "equal"
              }
              placeholder={translation.defaultSelectPlaceholder}
              onChange={(value) => {
                setSelectedKeys(
                  value
                    ? [{ ...selectedKeys[0], type: value }]
                    : [{ ...selectedKeys[0] }]
                );
              }}
            >
              <Select.Option value="equal">=</Select.Option>
              <Select.Option value="greaterThan">&gt;</Select.Option>
              <Select.Option value="greaterOrEqualThan">&ge;</Select.Option>
              <Select.Option value="lessThan">&lt;</Select.Option>
              <Select.Option value="lessOrEqualThan">&le;</Select.Option>
            </Select>
            <InputNumber
              value={
                selectedKeys
                  ? selectedKeys[0]?.value != null
                    ? selectedKeys[0]?.value
                    : null
                  : null
              }
              placeholder={translation.defaultSelectPlaceholder}
              onChange={(value) => {
                const operator =
                  selectedKeys && selectedKeys[0]
                    ? selectedKeys[0].type
                    : "equal";
                setSelectedKeys(
                  value
                    ? [
                        {
                          type: operator,
                          value: value,
                        },
                      ]
                    : [
                        {
                          type: operator,
                          value: null,
                        },
                      ]
                );
              }}
              onPressEnter={() => handleSearch(selectedKeys, confirm)}
              style={{ width: 90, marginBottom: 8 }}
              defaultValue={0}
              decimalSeparator=","
              min={-1000}
              max={1000}
              step={0.01}
              formatter={(value) => {
                if (value !== "") {
                  value = `${value}`.replace(/^-^[^0-9.,]*/g, "");
                  value = value.replace(/\.{2,}/g, ".");
                  value = value.replace(/\.,/g, ",");
                  value = value.replace(/,\./g, ",");
                  value = value.replace(/,{2,}/g, ",");
                  value = value.replace(/\.[0-9]+\./g, ".");
                  value =
                    value.split(".")[1] !== "" &&
                    value.split(".")[1] !== undefined
                      ? value.split(".")[0] +
                        "." +
                        value.split(".")[1].substring(0, 2)
                      : value;
                }
                return value;
              }}
            />
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{
                width: 90,
                marginRight: 8,
                background: "#684e94",
                borderColor: "none",
                border: "none",
              }}
            />
            <Button
              onClick={() => handleReset(clearFilters)}
              icon="delete"
              size="small"
              style={{ width: 90 }}
            />
          </div>
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        switch (value?.type) {
          case "equal":
            return getTwoDecimalDigits(record[dataIndex]) === value?.value;
          case "lessOrEqualThan":
            return getTwoDecimalDigits(record[dataIndex]) <= value?.value;
          case "lessThan":
            return getTwoDecimalDigits(record[dataIndex]) < value?.value;
          case "greaterOrEqualThan":
            return getTwoDecimalDigits(record[dataIndex]) >= value?.value;
          case "greaterThan":
            return getTwoDecimalDigits(record[dataIndex]) > value?.value;
          default:
            return getTwoDecimalDigits(record[dataIndex]) === value?.value;
        }
      },
    }),
    [handleReset, handleSearch, translation.defaultSelectPlaceholder]
  );

  const handleGetColumnDateSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 6,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Row style={{ marginBottom: 5, marginLeft: 5 }}>
          <Select
            style={{ width: 70 }}
            name="filterType"
            value={
              selectedKeys
                ? selectedKeys[0]?.type != null
                  ? selectedKeys[0]?.type
                  : "Equal"
                : "Equal"
            }
            placeholder={translation.defaultSelectPlaceholder}
            onChange={(value) => {
              setSelectedKeys(
                value
                  ? [{ ...selectedKeys[0], type: value }]
                  : [{ ...selectedKeys[0] }]
              );
            }}
          >
            <Select.Option key={"Lesser"} value={"Lesser"}>
              {"<"}
            </Select.Option>
            <Select.Option key={"LesserOrEqual"} value={"LesserOrEqual"}>
              {"<="}
            </Select.Option>
            <Select.Option key={"Equal"} value={"Equal"}>
              {"="}
            </Select.Option>
            <Select.Option key={"GreaterOrEqual"} value={"GreaterOrEqual"}>
              {">="}
            </Select.Option>
            <Select.Option key={"Greater"} value={"Greater"}>
              {">"}
            </Select.Option>
          </Select>

          <DatePicker
            allowClear={true}
            value={
              selectedKeys
                ? selectedKeys[0]?.value != null
                  ? selectedKeys[0]?.value
                  : null
                : null
            }
            placeholder={translation.defaultSelectPlaceholder}
            style={{ width: 175, marginBottom: 8 }}
            format={"DD/MM/YYYY"}
            onChange={(date, dateString) => {
              setSelectedKeys(
                date
                  ? [
                      {
                        type: selectedKeys
                          ? selectedKeys[0]?.type || "Equal"
                          : "Equal",
                        value: date,
                      },
                    ]
                  : []
              );
            }}
          />
        </Row>
        <Row>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </Row>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon
        type="calendar"
        style={{ color: filtered ? "#684e94" : undefined }}
      />
    ),
    onFilter: (filterValue, record) => {
      if (filterValue.type) {
        if (filterValue.type === "Equal") {
          return moment(record[dataIndex], "YYYY-MM-DD").isSame(
            filterValue.value,
            "days",
            "[]"
          );
        } else if (filterValue.type === "Lesser")
          return moment(record[dataIndex], "YYYY-MM-DD").isBefore(
            filterValue.value,
            "days",
            "[]"
          );
        else if (filterValue.type === "LesserOrEqual")
          return moment(record[dataIndex], "YYYY-MM-DD").isSameOrBefore(
            filterValue.value,
            "days",
            "[]"
          );
        else if (filterValue.type === "Greater")
          return moment(record[dataIndex], "YYYY-MM-DD").isAfter(
            filterValue.value,
            "days",
            "[]"
          );
        else
          return moment(record[dataIndex], "YYYY-MM-DD").isSameOrAfter(
            filterValue.value,
            "days",
            "[]"
          );
      }
    },
  });

  return (
    <Table
      loading={isLoadingDataList}
      dataSource={dataList}
      pagination={{
        hideOnSinglePage:
          dataList !== null && dataList.length > 10 ? false : true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
      }}
      size="middle"
      rowKey="id"
    >
      <Table.Column
        // title={translation.animal.details.history.grid.date}
        title="Data"
        dataIndex="supplyDate"
        key="supplyDate"
        defaultSortOrder="descend"
        sorter={(a, b) => a.supplyDate > b.supplyDate}
        sortDirections={["descend", "ascend"]}
        {...handleGetColumnDateSearchProps("supplyDate")}
        render={(text) =>
          text !== null ? moment(text).format("DD/MM/YYYY") : ""
        }
      />
      <Table.Column
        title="N° Trato"
        dataIndex="treatNumber"
        key="treatNumber"
        sorter={(a, b) => Number(a.treatNumber) - Number(b.treatNumber)}
        sortDirections={["descend", "ascend"]}
        {...handleGetColumnNumberSearchProps("treatNumber")}
      />
      <Table.Column
        title="Piquete/Baia"
        dataIndex="picketName"
        key="picketName"
        sortDirections={["descend", "ascend"]}
        {...handleGetColumnSearchProps("picketName")}
      />
      <Table.Column
        title="Dieta"
        dataIndex="supplementName"
        key="supplementName"
        sortDirections={["descend", "ascend"]}
        {...handleGetColumnSearchProps("supplementName")}
      />
      <Table.Column
        title="Qtde Cab."
        dataIndex="amountAnimals"
        key="amountAnimals"
        sorter={(a, b) => Number(a.amountAnimals) - Number(b.amountAnimals)}
        sortDirections={["descend", "ascend"]}
        {...handleGetColumnNumberSearchProps("amountAnimals")}
      />
      <Table.Column
        title="Qtde Ofertada (Kg)"
        dataIndex="treatQuantitySupplied"
        key="treatQuantitySupplied"
        sorter={(a, b) =>
          Number(a.treatQuantitySupplied) - Number(b.treatQuantitySupplied)
        }
        sortDirections={["descend", "ascend"]}
        {...handleGetColumnNumberSearchProps("treatQuantitySupplied")}
        render={(text) => numberMask(text, false)}
      />
      <Table.Column
        title="Custo"
        dataIndex="totalSupplyCost"
        key="totalSupplyCost"
        sorter={(a, b) => Number(a.totalSupplyCost) - Number(b.totalSupplyCost)}
        sortDirections={["descend", "ascend"]}
        {...handleGetColumnNumberSearchProps("totalSupplyCost")}
        render={(text) => numberMask(text, true)}
      />
    </Table>
  );
}

function LotDetailsSupplementSupplyLayout() {
  const { fetchData } = useLotDetailsSupplementSupplyContext();
  const [isDashboardVisible, setIsDashboardVisible] = useState(true);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <Row type="flex">
      <Col xs={24} sm={24} md={24} lg={24} xl={24} className="cardBovexo">
        <Row type="flex">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography.Title level={4}>
              Fornecimento de Suplemento
            </Typography.Title>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Row type="flex" justify="end">
              <ButtonStandard buttonType="secondary" onClick={fetchData}>
                <Icon type="reload" /> Atualizar dados
              </ButtonStandard>
            </Row>
          </Col>
        </Row>
        {/* Dashboard */}
        <Row type="flex" justify="end" style={{ margin: "0.5rem" }}>
          <Col>
            <ButtonStandard
              type="button"
              buttonType="secondary"
              onClick={() => setIsDashboardVisible((old) => !old)}
            >
              {isDashboardVisible ? (
                <>
                  <Icon type="fullscreen-exit" /> Ocultar painéis
                </>
              ) : (
                <>
                  <Icon type="fullscreen" /> Exibir painéis
                </>
              )}
            </ButtonStandard>
          </Col>
        </Row>
        {isDashboardVisible && <LotDetailsSupplementSupplyDashboards />}
        <LotDetailsSupplementSupplyTable />
      </Col>
    </Row>
  );
}

function LotDetailsSupplementSupply() {
  return (
    <LotDetailsSupplementSupplyContextProvider>
      <LotDetailsSupplementSupplyLayout />
    </LotDetailsSupplementSupplyContextProvider>
  );
}

export default LotDetailsSupplementSupply;
