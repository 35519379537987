import React from 'react';

// import { Container } from './styles';

const ArrowDownWhite = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.293"
    height="20.293"
    viewBox="0 0 20.293 20.293"
  >
    <path
      id="Caminho_673"
      data-name="Caminho 673"
      d="M0,0,14.349,14.349H0Z"
      transform="translate(0 10.147) rotate(-45)"
      fill="#fff"
    />
  </svg>
);
export default ArrowDownWhite;
