import { Button, Col, Icon, Row } from "antd";
import React, { useCallback } from "react";

import { Container, RadioButtonWrapper } from "./styles";

function SelectOvarianResult({
  result,
  ovarianEvaluationResult,
  onChangeOvarianResultCallback,
  onChangeResultCallback
}) {
  const ListPositiveOvarianResult = useCallback(() => {
    const arrayOValues = [
      {
        id: "Normal",
        value: "Normal",
        label: "Normal"
      },
      {
        id: "Reabsorvendo",
        value: "Reabsorvendo",
        label: "Reabsorvendo"
      },
      {
        id: "Gemelar",
        value: "Gemelar",
        label: "Gemelar"
      }
    ];
    return (
      <Container isPositive={result ? (result === 1 ? true : null) : null}>
        <Row>
          <button
            type="button"
            className="title"
            onClick={() => {
              onChangeResultCallback(1);
              onChangeOvarianResultCallback("Normal");
            }}
          >
            Positivo
          </button>
        </Row>
        <Row type="flex" justify="space-around" gutter={8}>
          {arrayOValues.map((valueObj) => (
            <Col key={valueObj.id} span={4}>
              <RadioButtonWrapper
                isPositive={result ? (result === 1 ? true : null) : null}
                isChecked={
                  ovarianEvaluationResult
                    ? valueObj.value === ovarianEvaluationResult
                    : false
                }
              >
                <input
                  type="radio"
                  value={valueObj.value}
                  checked={
                    ovarianEvaluationResult
                      ? valueObj.value === ovarianEvaluationResult
                      : false
                  }
                  onChange={() => {
                    onChangeResultCallback(1);
                    onChangeOvarianResultCallback(valueObj.value);
                  }}
                />
                <Icon type="plus-circle" theme="filled" className="icon" />
                <strong>{valueObj.label}</strong>
              </RadioButtonWrapper>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }, [
    result,
    ovarianEvaluationResult,
    onChangeOvarianResultCallback,
    onChangeResultCallback
  ]);
  const ListNegativeOvarianResult = useCallback(() => {
    const arrayOValues = [
      {
        id: "SemAvaliacao",
        value: "SemAvaliacao",
        label: "Sem Avaliação"
      },
      {
        id: "CorpoLuteo",
        value: "CorpoLuteo",
        label: "Corpo Lúteo"
      },
      {
        id: "Cisto",
        value: "Cisto",
        label: "Cisto"
      },
      {
        id: "Foliculo",
        value: "Foliculo",
        label: "Folículo"
      },
      {
        id: "Anestro",
        value: "Anestro",
        label: "Anestro"
      }
    ];
    return (
      <Container isPositive={result ? (result === 2 ? false : null) : null}>
        <Row>
          <button
            type="button"
            className="title"
            onClick={() => {
              onChangeResultCallback(2);
              onChangeOvarianResultCallback("SemAvaliacao");
            }}
          >
            Negativo
          </button>
        </Row>
        <Row type="flex" justify="space-around" gutter={8}>
          {arrayOValues.map((valueObj) => (
            <Col key={valueObj.id} span={4}>
              <RadioButtonWrapper
                isPositive={result ? (result === 2 ? false : null) : null}
                isChecked={
                  ovarianEvaluationResult
                    ? valueObj.value === ovarianEvaluationResult
                    : false
                }
              >
                <input
                  type="radio"
                  value={valueObj.value}
                  checked={
                    ovarianEvaluationResult
                      ? valueObj.value === ovarianEvaluationResult
                      : false
                  }
                  onChange={() => {
                    onChangeResultCallback(2);
                    onChangeOvarianResultCallback(valueObj.value);
                  }}
                />
                <Icon type="minus-circle" theme="filled" className="icon" />
                <strong>{valueObj.label}</strong>
              </RadioButtonWrapper>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }, [
    result,
    ovarianEvaluationResult,
    onChangeOvarianResultCallback,
    onChangeResultCallback
  ]);
  return (
    <Row type="flex" gutter={8}>
      <Col span={12}>{ListPositiveOvarianResult()}</Col>
      <Col span={12}>{ListNegativeOvarianResult()}</Col>
    </Row>
  );
}

export default SelectOvarianResult;
