import { Formik } from "formik";
import moment from "moment";
import React, { useCallback, useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { INITIAL_DATA_FORM_ANIMAL_WEIGHT_HISTORY } from "../../../contexts/animalWeightHistoryContext";
import useAnimalWeightHistoryContext from "../../../hooks/useAnimalWeightHistoryContext";

import {
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Switch,
  notification,
} from "antd";
import SelectAnimalsModal from "../../modals/selectAnimalsModal";
import ButtonStandard from "../../utils/button";
import { Body, Container, Footer } from "./styles";
// Services
import { getAnimalsByIdentificationHandlingNumber } from "../../../services/animalService";
import {
  saveAnimalsWeightHistories,
  updateAnimalsWeightHistories,
} from "../../../services/animalWeightHistoryService";
import { numberMask } from "../../../services/helpersMethodsService";
import { getLotIndexDropDown } from "../../../services/lotService";
import { handleKeyDownInputNumber } from "../../../utils/keyDownFunctions";
import { AnimalCard } from "../animal/new/styles";
import BullAvatarIcon from "../animal/new/icons/bullAvatar";
import NumberFormat from "react-number-format";

function DrawerNewAnimalWeightHistory() {
  const formikRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldBlockWeaningField, setShouldBlockWeaningField] = useState(false);
  const [shouldEnableEditIdentifications, setShouldEnableEditIdentifications] =
    useState(false);
  const [controlDate, setControlDate] = useState(moment());
  const [lots, setLots] = useState([]);
  const [isVisibleModalSelectAnimal, setIsVisibleModalSelectAnimal] =
    useState(false);
  const [animalSelected, setAnimalSelected] = useState(null);
  const [animals, setAnimals] = useState([]);
  const [form, setForm] = useState(INITIAL_DATA_FORM_ANIMAL_WEIGHT_HISTORY);

  const {
    tableProperties: { size, page, search },
    fetchData,
    showDrawerForm,
    openOrCloseForm,
    data,
  } = useAnimalWeightHistoryContext();

  const validationSchema = Yup.object().shape({
    date: Yup.string().required(),
    weight: Yup.number().min(0).required(),
    animalId: Yup.string().required(),
    newAnimalHandlingNumber: Yup.string().when(["hasEditHandlingNumber"], {
      is: (hasEditHandlingNumber) => hasEditHandlingNumber === true,
      then: Yup.string().required(),
      otherwise: Yup.string().nullable(),
    }),
    newAnimalSisbov: Yup.string().when(["hasEditSisbov"], {
      is: (hasEditSisbov) => hasEditSisbov === true,
      then: Yup.string().min(15).max(15).required(),
      otherwise: Yup.string().nullable(),
    }),
    newAnimalTag: Yup.string().when(["hasEditAnimalTag"], {
      is: (hasEditAnimalTag) => hasEditAnimalTag === true,
      then: Yup.string().min(15).max(15).required(),
      otherwise: Yup.string().nullable(),
    }),
  });

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const closeDrawer = useCallback(() => {
    formikRef.current.resetForm(INITIAL_DATA_FORM_ANIMAL_WEIGHT_HISTORY);
    setAnimalSelected(null);
    setShouldEnableEditIdentifications(false);
    openOrCloseForm();
  }, [openOrCloseForm]);

  const fetchLots = useCallback(async () => {
    const {
      data: { results },
    } = await getLotIndexDropDown({
      groupId,
      farmId,
    });
    setLots(
      results.filter(
        (lot) =>
          lot.status === "Active" ||
          lot.status === "A" ||
          lot.status === "Pending" ||
          lot.status === "P"
      )
    );
  }, [groupId, farmId]);

  const findAnimal = useCallback(
    async (identification) => {
      const setFieldValue = formikRef.current.setFieldValue;
      const setFieldError = formikRef.current.setFieldError;
      setIsLoading(true);
      try {
        const {
          data: { results },
        } = await getAnimalsByIdentificationHandlingNumber({
          groupId,
          farmId,
          identification,
        });
        if (results.length === 0) {
          notification.error({
            message: "Nenhum animal encontrado!",
          });
          setFieldError("handlingNumber", "Animal não encontrado");
        } else if (results.length > 1) {
          setAnimals(results);
          setIsVisibleModalSelectAnimal(true);
        } else {
          const animal = results[0];
          setAnimalSelected(animal);
          setFieldValue("animalId", animal.id);
          setFieldValue("handlingNumber", animal.handlingNumber);
          setFieldValue("newAnimalHandlingNumber", animal.handlingNumber);
          setFieldValue("newAnimalSisbov", animal.sisbov);
          setFieldValue("newAnimalTag", animal.tagId);
          setFieldValue("newAnimalNickName", animal.nickname);
        }
      } catch (error) {
        notification.error({
          message: "Nenhum animal encontrado!",
        });
        setFieldError("handlingNumber", "Animal não encontrado");
      } finally {
        setIsLoading(false);
      }
    },
    [farmId, groupId]
  );

  const onConfirmSelectionAnimal = useCallback(
    (keys) => {
      const setFieldValue = formikRef.current.setFieldValue;
      const animalIndex = animals.findIndex((animal) => animal.id === keys[0]);
      const animal = animals[animalIndex];
      if (animal) {
        setAnimalSelected(animal);
        setFieldValue("animalId", animal.id);
        setFieldValue("handlingNumber", animal.handlingNumber);
        setFieldValue("newAnimalHandlingNumber", animal.handlingNumber);
        setFieldValue("newAnimalSisbov", animal.sisbov);
        setFieldValue("newAnimalTag", animal.tagId);
        setFieldValue("newAnimalNickName", animal.nickname);
      }
      setIsVisibleModalSelectAnimal(false);
    },
    [animals]
  );

  const onCancelSelectionAnimal = useCallback(() => {
    setIsVisibleModalSelectAnimal(false);
  }, []);

  const calculateGMD = useCallback(
    (weight, dateWeight) => {
      if (animalSelected && weight && dateWeight) {
        const lastDailyWeightDate = moment(animalSelected.lastDailyWeightDate);
        const lastDailyWeight = animalSelected.lastDailyWeight;
        const diffDateWeightAndLastDailyWeightDate = moment(dateWeight).diff(
          lastDailyWeightDate,
          "days"
        );

        return (
          (weight - lastDailyWeight) /
          (diffDateWeightAndLastDailyWeightDate === 0
            ? 1
            : diffDateWeightAndLastDailyWeightDate)
        );
      } else {
        return 0;
      }
    },
    [animalSelected]
  );

  const handleSelectLot = useCallback(
    (value, setFieldValue) => {
      if (animalSelected && animalSelected.lotId === value) {
        notification.warning({
          message: "Atenção! Animal já se encontra neste Lote.",
        });
      }
      setFieldValue("lotDestinationId", value);
    },
    [animalSelected]
  );

  const handleDrawerOpen = (visible) => {
    if (visible === true) {
      fetchLots();
      setForm(data);
      formikRef.current.setValues(data);
    }
  };

  const handleSubmitModalForm = useCallback(
    async (values, actions) => {
      setIsLoading(true);
      try {
        const body = {
          ...values,
          date: moment(values.date).format("YYYY-MM-DD HH:mm:ss"),
        };
        if (body.id != null) {
          await updateAnimalsWeightHistories({
            groupId,
            farmId,
            body,
            id: body.id,
          });
          notification.success({
            message: "Pesagem atualizada com sucesso!",
          });
          fetchData(page, size, {}, search);
          closeDrawer(actions.resetForm);
        } else {
          await saveAnimalsWeightHistories({
            groupId,
            farmId,
            body,
          });
          notification.success({
            message: "Pesagem criada com sucesso!",
          });
          fetchData(page, size, {}, search);
          if (values.saveOptions === "save_and_add_new") {
            actions.resetForm({
              ...INITIAL_DATA_FORM_ANIMAL_WEIGHT_HISTORY,
              date: values.date,
              lotDestinationId: values.lotDestinationId,
              saveOptions: "save_and_add_new",
            });
            setAnimalSelected(null);
          } else {
            closeDrawer();
          }
        }
      } catch (error) {
        console.error("error", error);
        if (Object.keys(error).includes("response")) {
          const { response } = error;
          if (Object.keys(response).includes("data")) {
            const {
              data: { code: errorCode },
            } = response;
            if (errorCode === 5029) {
              notification.error({
                message:
                  "Atenção! Animal já possui uma movimentação entre lotes em data posterior.",
              });
            } else {
              notification.error({
                message: "Pesagem não foi criada/atualizada. Contate o suporte",
              });
            }
          }
        } else {
          notification.error({
            message: "Pesagem não foi criada/atualizada. Contate o suporte",
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [closeDrawer, farmId, fetchData, groupId, page, search, size]
  );

  useEffect(() => {
    if (animalSelected && controlDate) {
      const ageAnimal = moment(controlDate).diff(
        moment(animalSelected.birthday),
        "months"
      );
      if (ageAnimal > 12) {
        setShouldBlockWeaningField(true);
      } else if (
        animalSelected?.weaningWeight != null &&
        animalSelected?.weaningDate != null
      ) {
        setShouldBlockWeaningField(true);
      } else {
        setShouldBlockWeaningField(false);
      }
    } else {
      setShouldBlockWeaningField(false);
    }
  }, [animalSelected, controlDate]);

  return (
    <Container
      title={
        data.id != null
          ? translation.animalWeightHistory.form.titleEdit
          : translation.animalWeightHistory.form.titleCreateNew
      }
      width={770}
      onClose={closeDrawer}
      maskClosable={false}
      visible={showDrawerForm}
      afterVisibleChange={handleDrawerOpen}
    >
      <Formik
        ref={formikRef}
        enableReinitialize={true}
        initialValues={form}
        onSubmit={handleSubmitModalForm}
        validationSchema={validationSchema}
        render={({
          values,
          errors,
          setFieldValue,
          submitCount,
          resetForm,
          handleSubmit,
        }) => (
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit} autoComplete="off">
              <Body>
                {Object.entries(errors).length > 0 && submitCount > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                <Row type="flex" gutter={8}>
                  <Col span={8}>
                    <Row>
                      <label
                        className={
                          errors.date && submitCount > 0 ? "error" : ""
                        }
                      >
                        {translation.animalWeightHistory.form.date}*
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <DatePicker
                        autoFocus={true}
                        format="DD/MM/YYYY"
                        disabled={values.id != null}
                        value={values.date ? moment(values.date) : undefined}
                        placeholder={translation.defaultDatePickerPlaceholder}
                        disabledDate={(current) =>
                          animalSelected != null
                            ? current.isAfter(moment()) ||
                              current.isBefore(moment(animalSelected?.birthday))
                            : current.isAfter(moment())
                        }
                        onChange={(date) => {
                          setFieldValue("date", date);
                          setControlDate(date);
                        }}
                      />
                    </Row>
                  </Col>
                  <Col span={16}>
                    <Row>
                      <label
                        className={
                          (errors.handlingNumber || errors.animalId) &&
                          submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {translation.animalWeightHistory.form.animalId}*
                      </label>
                    </Row>
                    <Row
                      type="flex"
                      align="middle"
                      className="rowInput"
                      gutter={16}
                    >
                      <Col>
                        <Input
                          value={values.handlingNumber ?? undefined}
                          placeholder={translation.defaultPlaceholder}
                          disabled={values.id != null}
                          onPressEnter={(e) => {
                            e.preventDefault();
                            findAnimal(values.handlingNumber);
                          }}
                          onChange={(e) =>
                            setFieldValue("handlingNumber", e.target.value)
                          }
                        />
                      </Col>
                      <Col>
                        <Button
                          disabled={values.id != null}
                          shape="circle"
                          icon="search"
                          onClick={() => findAnimal(values.handlingNumber)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row type="flex" gutter={8}>
                  <Col span={values.id !== null ? 8 : 6}>
                    <Row>
                      <label
                        className={
                          errors.weight && submitCount > 0 ? "error" : ""
                        }
                      >
                        {translation.animalWeightHistory.form.weight}*
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <InputNumber
                        onKeyDown={handleKeyDownInputNumber}
                        value={values.weight || undefined}
                        placeholder={translation.defaultPlaceholder}
                        min={1}
                        max={9999}
                        maxLength={5}
                        precision={1}
                        decimalSeparator=","
                        step={0.1}
                        onChange={(value) =>
                          setFieldValue("weight", value > 9999 ? 9999 : value)
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={values.id !== null ? 0 : 6}>
                    <Row>
                      <label>{translation.animalWeightHistory.form.gmd}</label>
                    </Row>
                    <Row className="rowInput">
                      <strong>
                        {values.weight && values.date && animalSelected
                          ? `${numberMask(
                              calculateGMD(values.weight, values.date),
                              false
                            )} kg`
                          : ""}
                      </strong>
                    </Row>
                  </Col>
                  <Col span={values.id !== null ? 0 : 6}>
                    <Row>
                      <label>
                        {translation.animalWeightHistory.form.lotOriginalId}
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <strong>{animalSelected?.lotName ?? "-"}</strong>
                    </Row>
                  </Col>
                  {values.id === null && (
                    <Col span={6}>
                      <Row>
                        <label>
                          {
                            translation.animalWeightHistory.form
                              .lotDestinationId
                          }
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          value={values.lotDestinationId || undefined}
                          placeholder={translation.defaultSelectPlaceholder}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          allowClear
                          onDeselect={() =>
                            setFieldValue("lotDestinationId", null)
                          }
                          onChange={(value) =>
                            handleSelectLot(value, setFieldValue)
                          }
                        >
                          {lots.map((lot) => (
                            <Select.Option key={lot.id} value={lot.id}>
                              {lot.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Row>
                    </Col>
                  )}
                </Row>
                {/* Row Desmama(Weaning) */}
                <Row type="flex" gutter={8}>
                  <Col span={6}>
                    <Row>
                      <label>
                        {translation.animalWeightHistory.form.weaningWeight}
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <Switch
                        checked={values.weaningWeight}
                        onChange={(checked, event) => {
                          setFieldValue("weaningWeight", checked);
                        }}
                        disabled={shouldBlockWeaningField}
                      />
                    </Row>
                  </Col>
                </Row>
                {values.id === null && (
                  <>
                    <Divider dashed>PESAGEM ANTERIOR</Divider>
                    <Row type="flex" gutter={8}>
                      <Col span={12}>
                        <Row>
                          <label>
                            {
                              translation.animalWeightHistory.form
                                .lastDateWeight
                            }
                          </label>
                        </Row>
                        <Row>
                          <strong>
                            {animalSelected?.lastDailyWeightDate
                              ? moment(
                                  animalSelected?.lastDailyWeightDate
                                ).format("DD/MM/YYYY")
                              : "-"}
                          </strong>
                        </Row>
                      </Col>
                      <Col span={12}>
                        <Row>
                          <label>
                            {translation.animalWeightHistory.form.lastWeight}
                          </label>
                        </Row>
                        <Row>
                          <strong>
                            {animalSelected?.lastDailyWeight
                              ? `${numberMask(
                                  animalSelected?.lastDailyWeight || 0,
                                  false
                                )} kg`
                              : "-"}
                          </strong>
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}
                {/* Row Edit Identifications */}
                {values.id === null && (
                  <Row type="flex" gutter={8}>
                    <Col span={6}>
                      <Row>
                        <label>
                          {
                            translation.animalWeightHistory.form
                              .editIdentifications
                          }
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Switch
                          checked={shouldEnableEditIdentifications}
                          onChange={(checked, event) => {
                            setShouldEnableEditIdentifications(checked);
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                )}
                {values.id === null && shouldEnableEditIdentifications && (
                  <>
                    <Divider dashed>IDENTIFICAÇÕES</Divider>
                    <Row type="flex">
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <AnimalCard>
                          <Row type="flex" justify="start">
                            <Col>
                              <BullAvatarIcon />
                            </Col>
                            <Col
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              offset={1}
                              className="animalCardCol"
                            >
                              <Row>
                                <label
                                  className={
                                    errors.newAnimalHandlingNumber &&
                                    submitCount > 0
                                      ? "error"
                                      : ""
                                  }
                                >
                                  {translation.animal.edit.form.handlingNumber}*
                                </label>
                              </Row>
                              <Row className="rowInput">
                                <Input
                                  placeholder={
                                    translation.animal.edit.form
                                      .handlingNumberPlaceHolder
                                  }
                                  value={values?.newAnimalHandlingNumber}
                                  name="newAnimalHandlingNumber"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "newAnimalHandlingNumber",
                                      e.target.value
                                    );
                                    setFieldValue(
                                      "hasEditHandlingNumber",
                                      true
                                    );
                                  }}
                                />
                              </Row>
                            </Col>
                            <Col
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              offset={1}
                              className="animalCardCol"
                            >
                              <Row>
                                <label
                                  className={
                                    errors.newAnimalSisbov && submitCount > 0
                                      ? "error"
                                      : ""
                                  }
                                >
                                  {translation.animal.edit.form.sisbov}
                                </label>
                              </Row>
                              <Row className="rowInput">
                                <NumberFormat
                                  customInput={Input}
                                  placeholder={
                                    translation.animal.edit.form
                                      .sisbovPlaceHolder
                                  }
                                  maxLength={15}
                                  decimalScale={0}
                                  allowLeadingZeros={false}
                                  isNumericString={true}
                                  value={
                                    values?.newAnimalSisbov !== null
                                      ? values?.newAnimalSisbov
                                      : ""
                                  }
                                  name="newAnimalSisbov"
                                  onValueChange={({ value }) => {
                                    setFieldValue("newAnimalSisbov", value);
                                    setFieldValue("hasEditSisbov", true);
                                  }}
                                />
                                {errors.newAnimalSisbov && submitCount > 0 && (
                                  <p className="error">
                                    O SISBOV deve conter 15 números.
                                  </p>
                                )}
                              </Row>
                            </Col>
                            <Col
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              offset={1}
                              className="animalCardCol"
                            >
                              <Row>
                                <label
                                  className={
                                    errors.newAnimalTag && submitCount > 0
                                      ? "error"
                                      : ""
                                  }
                                >
                                  {translation.animal.edit.form.tagId}
                                </label>
                              </Row>
                              <Row className="rowInput">
                                <NumberFormat
                                  customInput={Input}
                                  placeholder={
                                    translation.animalBirth.form.child
                                      .tagIdPlaceHolder
                                  }
                                  value={
                                    values?.newAnimalTag !== null
                                      ? values?.newAnimalTag
                                      : null
                                  }
                                  decimalScale={0}
                                  maxLength={15}
                                  allowLeadingZeros={true}
                                  isNumericString={true}
                                  name="newAnimalTag"
                                  onValueChange={({ value }) => {
                                    setFieldValue("newAnimalTag", value);
                                    setFieldValue("hasEditAnimalTag", true);
                                  }}
                                />
                              </Row>
                              {errors?.newAnimalTag && submitCount > 0 && (
                                <p className="error">
                                  O Id. Eletrônica deve conter 15 números
                                </p>
                              )}
                            </Col>
                          </Row>
                        </AnimalCard>
                      </Col>
                    </Row>
                    <Row type="flex" style={{ marginTop: 24 }}>
                      {/* Nickname */}
                      <Col span={12}>
                        <Row>
                          <label className="formLabel">
                            {translation.animal.edit.form.nickname}
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <Input
                            placeholder={
                              translation.animal.edit.form.nicknamePlaceHolder
                            }
                            value={values?.newAnimalNickName}
                            name="newAnimalNickName"
                            onChange={(e) => {
                              setFieldValue(
                                "newAnimalNickName",
                                e.target.value
                              );
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}
              </Body>
              {/* Footer */}
              <Footer>
                <Row type="flex" justify="space-between">
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="selectDiv"
                  >
                    {values.id === null && (
                      <Select
                        value={values.saveOptions}
                        name="saveOptions"
                        onChange={(value) =>
                          setFieldValue("saveOptions", value)
                        }
                      >
                        <Select.Option key="1" value="only_save">
                          Salvar somente
                        </Select.Option>
                        <Select.Option key="2 " value="save_and_add_new">
                          Salvar e adicionar nova pesagem
                        </Select.Option>
                      </Select>
                    )}
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="buttonsDiv"
                  >
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={() => closeDrawer(resetForm)}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>
                    <ButtonStandard type="submit" buttonType="type6">
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          </Spin>
        )}
      />
      <SelectAnimalsModal
        data={animals}
        visible={isVisibleModalSelectAnimal}
        selectedAnimalsKeys={animalSelected ? [animalSelected] : []}
        onConfirmSelection={onConfirmSelectionAnimal}
        onCancel={onCancelSelectionAnimal}
        isMultiple={false}
      />
    </Container>
  );
}

export default DrawerNewAnimalWeightHistory;
