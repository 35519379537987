import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 14px 5px 5px 5px !important;
  overflow-y: auto;
  height: 600px;
  @media screen and (max-height: 700px) {
    height: 450px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: flex !important;
    flex-direction: row !important;
    padding: 12px 16px 12px 13px !important;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-extra {
    margin-left: auto !important;
  }
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
  color: #4B4B4B;
`;

export const NewLot = styled.button`
  width: 194.2px;
  height: 24px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #684e94;
  color: #684e94;
  text-align: center;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 19px;
  justify-content: flex-start;

  div.title {
    padding-right: 14px;
    border-right: 1px solid #e8e5e5;
    span {
      font-weight: bold;
      font-size: 16px;
      color: #4B4B4B;
    }
  }
  div.amountAnimals {
    padding: 0 14px;
    border-right: 1px solid #e8e5e5;
    span {
      font-family: Asap;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: #a9c133;
    }
  }
`;

export const ExtraContainer = styled.div`
  display: flex;
  flex-direction: row;

  button {
    background: transparent;
    border-radius: 5px;
    border: none;
    width: 25px;
    height: 25px;
    align-items: center;
    margin: 0 5px;

    &.deleteButton:hover {
      background: #d44c4c;
      cursor: pointer;
      path {
        fill: #fff !important;
      }
    }

    &.editButton:hover {
      background: #4a85ae;
      cursor: pointer;
      path {
        fill: #fff !important;
      }
    }
  }
`;
