export const Types = {
  RESET_DATA: "accountFarm/RESET_DATA",
  CHECK_ACCOUNT_FARM: "accountFarm/CHECK_ACCOUNT_FARM",
  CHECK_ACCOUNT_FARM_SUCCESS: "accountFarm/CHECK_ACCOUNT_FARM_SUCCESS",
  CHECK_ACCOUNT_FARM_ERROR: "accountFarm/CHECK_ACCOUNT_FARM_ERROR",
  RENEW_EXPIRATION_ACCOUNT_FARM: "accountFarm/RENEW_EXPIRATION_ACCOUNT_FARM",
  RENEW_EXPIRATION_ACCOUNT_FARM_SUCCESS:
    "accountFarm/RENEW_EXPIRATION_ACCOUNT_FARM_SUCCESS",
  RENEW_EXPIRATION_ACCOUNT_FARM_ERROR:
    "accountFarm/RENEW_EXPIRATION_ACCOUNT_FARM_ERROR",
  SHOW_OR_HIDE_MODAL_EXPIRED_TRIAL_ACCOUNT:
    "accountFarm/SHOW_OR_HIDE_MODAL_EXPIRED_TRIAL_ACCOUNT",
};

export const Creators = {
  resetData: () => ({
    type: Types.RESET_DATA,
  }),
  showOrHideExpiredTrialAccountModal: () => ({
    type: Types.SHOW_OR_HIDE_MODAL_EXPIRED_TRIAL_ACCOUNT,
  }),
  checkAccountFarm: (groupId, farmId) => ({
    type: Types.CHECK_ACCOUNT_FARM,
    payload: {
      groupId,
      farmId,
    },
  }),
  checkAccountFarmSuccess: (data) => ({
    type: Types.CHECK_ACCOUNT_FARM_SUCCESS,
    payload: {
      data,
    },
  }),
  checkAccountFarmError: (error) => ({
    type: Types.CHECK_ACCOUNT_FARM_ERROR,
    payload: {
      error,
    },
  }),
  renewExpirationAccountFarm: (groupId, farmId) => ({
    type: Types.RENEW_EXPIRATION_ACCOUNT_FARM,
    payload: {
      groupId,
      farmId,
    },
  }),
  renewExpirationAccountFarmSuccess: (data) => ({
    type: Types.RENEW_EXPIRATION_ACCOUNT_FARM_SUCCESS,
    payload: {
      data,
    },
  }),
  renewExpirationAccountFarmError: (error) => ({
    type: Types.RENEW_EXPIRATION_ACCOUNT_FARM_ERROR,
    payload: {
      error,
    },
  }),
};

const INITIAL_STATE = {
  isModalExpiredTrialAccountVisible: false,
  accountFarmData: {
    id: null,
    status: null,
    type: null,
    expirationDate: null,
    newExpirationDate: null,
    showModalToRenew: false,
    showModalJustForContract: false,
  },
  isLoadingRequest: false,
  error: null,
};

export default function accountFarm(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.SHOW_OR_HIDE_MODAL_EXPIRED_TRIAL_ACCOUNT:
      return {
        ...state,
        isModalExpiredTrialAccountVisible:
          !state.isModalExpiredTrialAccountVisible,
      };
    case Types.CHECK_ACCOUNT_FARM:
      return {
        ...state,
      };
    case Types.CHECK_ACCOUNT_FARM_SUCCESS:
      let { showModalToRenew, showModalJustForContract } = action.payload.data;
      return {
        ...state,
        isModalExpiredTrialAccountVisible:
          showModalToRenew || showModalJustForContract,
        accountFarmData: action.payload.data,
      };
    case Types.CHECK_ACCOUNT_FARM_ERROR:
      return {
        ...state,
        isModalExpiredTrialAccountVisible: false,
        accountFarmData: null,
        error: action.payload.error,
      };
    case Types.RENEW_EXPIRATION_ACCOUNT_FARM:
      return {
        ...state,
        isLoadingRequest: true,
      };
    case Types.RENEW_EXPIRATION_ACCOUNT_FARM_SUCCESS:
      return {
        ...state,
        isModalExpiredTrialAccountVisible: false,
        isLoadingRequest: false,
      };
    case Types.RENEW_EXPIRATION_ACCOUNT_FARM_ERROR:
      return {
        ...state,
        isModalExpiredTrialAccountVisible: false,
        isLoadingRequest: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
