import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as RetreatActions } from "../../../store/ducks/retreat";

// Components
import { Container, Footer } from "./styles";
import { Row, Col, Select, Input, Spin, notification } from "antd";
import ButtonStandard from "../../utils/button";

// Services
import { save, findById, update } from "../../../services/retreatService";
import { getPicketsAvailableForRetreatDropDown } from "../../../services/picketService";

const DrawerRetreat = () => {
  // Redux Variable
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const { isDrawerVisible, retreatId } = useSelector((state) => state.retreat);

  const initialStateForm = {
    id: null,
    name: null,
    picketsIds: [],
  };

  const [form, setForm] = useState(initialStateForm);
  const [picketList, setPicketList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPicketDropdown, setIsLoadingPicketDropdown] = useState(false);
  const [picketSubSystem, setPicketSubSystem] = useState(null);
  const [errors, setErrors] = useState([]);

  const { Option } = Select;

  const dispatch = useDispatch();

  // Methods
  function closeDrawer() {
    setForm(initialStateForm);
    dispatch(RetreatActions.showOrHideDrawer());
  }

  //Load dropdown
  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoadingPicketDropdown(true);
        const {
          data: { results },
        } = await getPicketsAvailableForRetreatDropDown({
          groupId,
          farmId,
          retreatId,
        });

        setPicketList(results);
      } catch (error) {
        console.error(error);
        notification.error({
          title: "Erro ao salvar",
          message:
            "Não foi possível carregar as informações dos piquetes. Contate o suporte",
        });
      } finally {
        setIsLoadingPicketDropdown(false);
      }
    }
    if (isDrawerVisible && groupId !== null && farmId !== null) {
      fetchData();
    }
  }, [isDrawerVisible, groupId, farmId, retreatId]);

  //Load initial data in edit
  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const {
          data: { results },
        } = await findById({ groupId, farmId, id: retreatId });

        const formValue = {
          id: retreatId,
          name: results.name,
          picketsIds: results?.pickets.map((p) => p.id),
        };

        setForm(formValue);
      } catch (error) {
        console.error(error);
        notification.error({
          title: "Erro ao salvar",
          message: "Não foi possível salvar o retiro. Contate o suporte",
        });
      } finally {
        setIsLoading(false);
      }
    }

    if (retreatId === null) {
      setForm(initialStateForm);
      setIsLoading(false);
    } else {
      fetchData();
    }
  }, [retreatId]);

  //Reset form
  useEffect(() => {
    if (isDrawerVisible) {
      setForm(initialStateForm);
      setPicketSubSystem(null);
    }
  }, [isDrawerVisible]);

  function validateForm() {
    let aErrors = [];
    if (form.name === null || form.name.trim() === "") {
      aErrors.push("name");
    }

    setErrors(aErrors);
    return aErrors.length > 0 ? false : true;
  }

  async function handleSubmit() {
    if (validateForm()) {
      const body = { ...form, id: retreatId };

      try {
        setIsLoading(true);
        if (body.id === null) {
          await save({ groupId, farmId, body });
          notification.success({ message: "Retiro criado com sucesso" });
        } else {
          await update({ groupId, farmId, id: retreatId, body });
          notification.success({ message: "Retiro atualizado com sucesso" });
        }

        dispatch(RetreatActions.index(groupId, farmId));
        closeDrawer();
      } catch (error) {
        notification.error({
          title: "Erro ao salvar",
          message: "Não foi possível salvar o retiro. Contate o suporte",
        });
      } finally {
        setIsLoading(false);
      }
    }
  }

  function handleSelectPicket(value) {
    setForm({ ...form, picketsIds: value });
  }

  return (
    <Container
      title={retreatId === null ? "Cadastro de Retiro" : "Editar Retiro"}
      width={480}
      onClose={closeDrawer}
      maskClosable={false}
      visible={isDrawerVisible}
    >
      <div className="drawerForm">
        <Spin spinning={isLoading}>
          <Row type="flex">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <span className="subTitle">
                Preencha os campos abaixo para cadastrar um retiro
              </span>
            </Col>
          </Row>
          {errors.length > 0 && (
            <Row type="flex" justify="center" align="middle">
              <label className="error">{translation.error.formError}</label>
            </Row>
          )}

          {/* Name */}
          <Row
            className="rowLabel"
            type="flex"
            gutter={8}
            justify="space-between"
            align="middle"
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <label className={errors.includes("name") ? "error" : ""}>
                Nome*
              </label>
              <Input
                name="name"
                value={form?.name != null ? form?.name : undefined}
                type="name"
                autoComplete="chrome-off"
                placeholder={translation.defaultPlaceholder}
                onChange={(e) => {
                  setForm({ ...form, name: e.target.value });
                  setErrors(errors.filter((e) => e !== "name"));
                }}
              />
            </Col>
          </Row>

          {/* Pickets */}
          <Row
            className="rowLabel"
            type="flex"
            gutter={8}
            justify="space-between"
            align="middle"
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <label className={errors.includes("picketsIds") ? "error" : ""}>
                Piquetes
              </label>
              <Select
                name="picketsIds"
                mode="tags"
                showSearch
                allowClear
                value={
                  form?.picketsIds !== null && !isLoadingPicketDropdown
                    ? form.picketsIds
                    : []
                }
                loading={isLoadingPicketDropdown}
                placeholder={translation.defaultPlaceholder}
                onChange={(value) => handleSelectPicket(value)}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {picketList !== null &&
                  picketList !== undefined &&
                  picketList?.length > 0 &&
                  picketList.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {`${item.name} / ${item.profitCenterName} / ${
                        item.productionSubSystem === "I"
                          ? `${item.pastureArea * 10000} m²`
                          : `${item.pastureArea} ha`
                      }`}
                    </Option>
                  ))}
              </Select>
            </Col>
          </Row>
        </Spin>
      </div>

      <Footer>
        <Row type="flex">
          <Col span={24} className="buttonsDiv">
            <ButtonStandard
              type="button"
              buttonType="type7"
              onClick={closeDrawer}
            >
              {translation.buttons.cancel}
            </ButtonStandard>

            <ButtonStandard
              type="button"
              buttonType="type6"
              onClick={handleSubmit}
            >
              {translation.buttons.save}
            </ButtonStandard>
          </Col>
        </Row>
      </Footer>
    </Container>
  );
};

export default DrawerRetreat;
