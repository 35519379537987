import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useRegister from "../../../../hooks/useRegister";
// Components
import { Row, Col, notification } from "antd";
import {
  Container,
  CustomRadio,
  CustomRadioGroup,
  FormContainer,
  FormFooterContaier,
  InfoContainer,
} from "../styles";
import InputStandard from "../../../../components/utils/input";
import NumberFormat from "react-number-format";
import { numberMask } from "../../../../services/helpersMethodsService";

// Services
import { createUser } from "../../../../services/authService";
import { getPlansConfig } from "../../../../services/planConfigSercice";

const Radio = ({ value, selected, children, onClick }) => {
  return (
    <CustomRadio onClick={onClick}>
      <div
        className={`radio-outer-circle ${
          selected === null
            ? "unselected"
            : value !== selected
            ? "unselected"
            : ""
        }`}
      >
        <div
          className={`radio-inner-circle ${
            selected === null
              ? "unselected-circle"
              : value !== selected
              ? "unselected-circle"
              : ""
          }`}
        />
      </div>
      {children}
    </CustomRadio>
  );
};

const RegisterStep3 = () => {
  const [formPlans, setFormPlans] = useState({
    planSelected: null,
    amountAnimals: null,
  });
  const [planByAnimalMonthly, setPlanByAnimalMonthly] = useState(null);
  const [planByAnimalAmountMonthly, setPlanByAnimalAmountMonthly] =
    useState(null);
  const [errors, setErrors] = useState([]);
  const { mode, form, setForm, goBackStep, goNextStep, trialEnvironmentSet } =
    useRegister();
  const history = useHistory();
  /** Redux variables */
  /** Effect  */
  useEffect(() => {
    setFormPlans(form.plan);
  }, [form.plan]);
  useEffect(() => {
    async function loadPlanConfig() {
      try {
        const {
          data: { results },
        } = await getPlansConfig();

        setPlanByAnimalMonthly(
          Object.values(results).find((plan) => {
            if (plan.code === "ByAnimalAmountMonthly") {
              return plan;
            } else {
              return null;
            }
          })
        );

        setPlanByAnimalAmountMonthly(
          Object.values(results).find((plan) => {
            if (plan.code === "ByAnimalAmountMonthly") {
              return plan;
            } else {
              return null;
            }
          })
        );
      } catch (error) {
        notification.error({
          message:
            "Ocorreu um erro ao acessar as informações de planos disponíveis.",
        });
      }
    }

    function handle() {
      loadPlanConfig();
    }
    handle();
  }, []);
  /** Methods */

  function handleGoBack() {
    goBackStep();
  }
  function handleGoNext() {
    goNextStep();
  }
  function handleGoToLogin() {
    history.push("/login");
  }

  const handleSelectPlan = useCallback((value) => {
    if (value !== "ByAnimalAmountMonthly") {
      setErrors((old) => old.filter((e) => e !== "amountAnimals"));
      setFormPlans((old) => ({
        ...old,
        amountAnimals: null,
        planSelected: value,
      }));
    } else {
      setFormPlans((old) => ({
        ...old,
        planSelected: value,
      }));
    }
  }, []);

  async function handleSubmitForm() {
    const { planSelected, amountAnimals } = formPlans;
    if (
      planSelected === "ByAnimalAmountMonthly" &&
      (amountAnimals == null || amountAnimals === 0)
    ) {
      notification.error({
        message:
          "É necessário informar a quantidade de cabeças para o plano selecionado.",
      });
      setErrors((old) => [...old, "amountAnimals"]);
    } else {
      setForm((old) => ({
        ...old,
        plan: {
          planSelected,
          amountAnimals,
        },
      }));

      //Save user info and send email to commercial
      if (planSelected === "Custom") {
        const newForm = { ...form, plan: formPlans };
        await createUser(newForm);
      }

      handleGoNext();
    }
  }

  function handleMinAmountAnimals() {
    if (planByAnimalAmountMonthly) {
      if (
        formPlans?.amountAnimals > planByAnimalAmountMonthly?.minAnimalsAmount
      ) {
        return formPlans?.amountAnimals * planByAnimalAmountMonthly?.value;
      } else {
        return (
          planByAnimalAmountMonthly?.minAnimalsAmount *
          planByAnimalAmountMonthly?.value
        );
      }
    }

    return 0;
  }

  return (
    <Container>
      {mode === "Demo" ? (
        <>
          <InfoContainer>
            <h2>
              Agradecemos por <br /> você ter nos <br /> escolhido!
            </h2>
            <p>
              Conta conosco para ajuda-lo a executar uma
              <strong> PECUÁRIA MAIS LUCRATIVA.</strong>
            </p>
            <p>
              {trialEnvironmentSet
                ? "Seu ambiente demo está pronto e você já pode fazer o login."
                : "Seu ambiente demo está sendo criado, lhe enviaremos um e-mail quando estiver pronto."}
            </p>
            <p>Qualquer dúvida estamos a disposição pelo chat.</p>
            <p style={{ display: "none" }}>
              Code 1 - Evento conversão google ads experimente BovExo
            </p>
          </InfoContainer>
          <button className="bg-green" onClick={handleGoToLogin}>
            Entrar na BovExo
          </button>
        </>
      ) : (
        <FormContainer>
          <Row type="flex" justify="start">
            <Col span={24}>
              <span className="title">Modelo de contratação</span>
            </Col>
          </Row>
          <Row type="flex" justify="start">
            <Col span={24}>
              <span className="subTitle">
                Selecione o modelo que mais se adequa a sua fazenda
              </span>
            </Col>
          </Row>
          <Row type="flex" justify="start">
            <Col span={24}>
              <CustomRadioGroup>
                <Radio
                  value="ByAnimalMonthly"
                  selected={formPlans.planSelected}
                  onClick={() => handleSelectPlan("ByAnimalMonthly")}
                >
                  <label>
                    R$ {numberMask(planByAnimalMonthly?.value)}/mês/cab.
                  </label>
                </Radio>
                <p className="radioDetails">
                  Nesta forma, você paga de acordo com a quantidade de cabeças
                  medidas mensalmente. Este valor corresponde, em média, a menos
                  de 2,5% do valor adicional por cabeça gerado por BovExo.
                </p>
                <Radio
                  value="ByAnimalAmountMonthly"
                  selected={formPlans.planSelected}
                  onClick={() => handleSelectPlan("ByAnimalAmountMonthly")}
                >
                  <label>Quantidade Média mensal de cabeças</label>
                  <InputStandard
                    border={
                      errors.includes("amountAnimals") ? "error" : "normal"
                    }
                    className={`input no-margin-bottom ${
                      formPlans.planSelected !== "ByAnimalAmountMonthly"
                        ? "disable"
                        : ""
                    }`}
                    width="100px"
                  >
                    <NumberFormat
                      type="text"
                      placeholder="XXX"
                      name="amountAnimals"
                      value={formPlans?.amountAnimals}
                      decimalScale={0}
                      onValueChange={({ floatValue }) => {
                        setFormPlans((old) => ({
                          ...old,
                          amountAnimals: floatValue,
                        }));
                        setErrors((old) =>
                          old.filter((e) => e !== "amountAnimals")
                        );
                      }}
                    />
                  </InputStandard>
                </Radio>
                <p className="radioDetails">
                  Valor fixo de: {numberMask(handleMinAmountAnimals(), true)}{" "}
                  /mês. Para facilitar a sua programação de fluxo de caixa, você
                  pode optar por pagar um valor fixo mensal. BovExo fará um
                  balanço anual da diferença entre a média contratada e a
                  efetiva e, valores a maior ou a menor, serão
                  acrescidos/descontados dos valores devidos para os doze meses
                  subsequentes..
                </p>
                <Radio
                  value="Custom"
                  selected={formPlans.planSelected}
                  onClick={() => handleSelectPlan("Custom")}
                >
                  <label>
                    Outros modelos de precificação, incluindo por performance.
                  </label>
                </Radio>
              </CustomRadioGroup>
            </Col>
          </Row>
          <FormFooterContaier>
            <button type="button" className="previous" onClick={handleGoBack}>
              Voltar
            </button>
            <button
              type="button"
              disabled={formPlans?.planSelected === null}
              className="next"
              onClick={handleSubmitForm}
            >
              Próximo
            </button>
          </FormFooterContaier>
        </FormContainer>
      )}
    </Container>
  );
};

export default RegisterStep3;
