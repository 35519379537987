export const Types = {
  RESET_DATA: "financial/RESET_DATA",
  DISABLE_EDIT_FORM: "financial/DISABLE_EDIT_FORM",
  SAVE_FARM_COST_SUMMARIZE: "financial/SAVE_FARM_COST_SUMMARIZE",
  SAVE_FARM_COST_SUMMARIZE_SUCCESS:
    "financial/SAVE_FARM_COST_SUMMARIZE_SUCCESS",
  SAVE_FARM_COST_SUMMARIZE_ERROR: "financial/SAVE_FARM_COST_SUMMARIZE_ERROR",
  SAVE_FARM_COST: "financial/SAVE_FARM_COST",
  SAVE_FARM_COST_SUCCESS: "financial/SAVE_FARM_COST_SUCCESS",
  SAVE_FARM_COST_ERROR: "financial/SAVE_FARM_COST_ERROR",
  GET_FARM_COST: "financial/GET_FARM_COST",
  GET_FARM_COST_SUCCESS: "financial/GET_FARM_COST_SUCCESS",
  GET_FARM_COST_ERROR: "financial/GET_FARM_COST_ERROR",
  GET_SUMMARY_FARM_COST: "financial/GET_SUMMARY_FARM_COST",
  GET_SUMMARY_FARM_COST_SUCCESS: "financial/GET_SUMMARY_FARM_COST_SUCCESS",
  GET_SUMMARY_FARM_COST_ERROR: "financial/GET_FARM_COST_ERROR",
};

export const Creators = {
  disableEditForm: (disable) => ({
    type: Types.DISABLE_EDIT_FORM,
    payload: {
      disable,
    },
  }),
  saveFarmCostsSummarize: (groupId, farmId, body) => ({
    type: Types.SAVE_FARM_COST_SUMMARIZE,
    payload: {
      groupId,
      farmId,
      body,
    },
  }),
  saveFarmCostsSummarizeSuccess: (data) => ({
    type: Types.SAVE_FARM_COST_SUMMARIZE_SUCCESS,
    payload: {
      data,
    },
  }),
  saveFarmCostsSummarizeError: (error) => ({
    type: Types.SAVE_FARM_COST_SUMMARIZE_ERROR,
    payload: {
      error,
    },
  }),
  saveFarmCosts: (groupId, farmId, body) => ({
    type: Types.SAVE_FARM_COST,
    payload: {
      groupId,
      farmId,
      body,
    },
  }),
  saveFarmCostsSuccess: (data) => ({
    type: Types.SAVE_FARM_COST_SUCCESS,
    payload: {
      data,
    },
  }),
  saveFarmCostsError: (error) => ({
    type: Types.SAVE_FARM_COST_ERROR,
    payload: {
      error,
    },
  }),
  getFarmCosts: (groupId, farmId) => ({
    type: Types.GET_FARM_COST,
    payload: {
      groupId,
      farmId,
    },
  }),
  getFarmCostsSuccess: (data) => ({
    type: Types.GET_FARM_COST_SUCCESS,
    payload: {
      data,
    },
  }),
  getFarmCostsError: (error) => ({
    type: Types.GET_FARM_COST_ERROR,
    payload: {
      error,
    },
  }),
  getSummaryFarmCosts: (groupId, farmId) => ({
    type: Types.GET_SUMMARY_FARM_COST,
    payload: {
      groupId,
      farmId,
    },
  }),
  getSummaryFarmCostsSuccess: (data) => ({
    type: Types.GET_SUMMARY_FARM_COST_SUCCESS,
    payload: {
      data,
    },
  }),
  getSummaryFarmCostsError: (error) => ({
    type: Types.GET_SUMMARY_FARM_COST_ERROR,
    payload: {
      error,
    },
  }),
};

const INITIAL_STATE = {
  farmCostData: null,
  loadingRequestFarmCostData: false,
  summaryFarmCostData: null,
  loadingRequestSummaryFarmCostData: false,
  disableEditForm: false,
};

export default function financial(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.DISABLE_EDIT_FORM:
      return { ...state, disableEditForm: action.payload.disable };
    case Types.SAVE_FARM_COST_SUMMARIZE:
      return { ...state, loadingRequestSummaryFarmCostData: true };
    case Types.SAVE_FARM_COST_SUMMARIZE_SUCCESS:
      return {
        ...state,
        loadingRequestSummaryFarmCostData: false,
      };
    case Types.SAVE_FARM_COST_SUMMARIZE_ERROR:
      return {
        ...state,
        loadingRequestSummaryFarmCostData: false,
      };
    case Types.SAVE_FARM_COST:
      return { ...state, loadingRequestFarmCostData: true };
    case Types.SAVE_FARM_COST_SUCCESS:
      return {
        ...state,
        loadingRequestFarmCostData: false,
      };
    case Types.SAVE_FARM_COST_ERROR:
      return {
        ...state,
        loadingRequestFarmCostData: false,
      };
    case Types.GET_FARM_COST:
      return { ...state, farmCostData: null, loadingRequestFarmCostData: true };
    case Types.GET_FARM_COST_SUCCESS:
      return {
        ...state,
        farmCostData: action.payload.data,
        loadingRequestFarmCostData: false,
      };
    case Types.GET_FARM_COST_ERROR:
      return {
        ...state,
        farmCostData: null,
        loadingRequestFarmCostData: false,
      };
    case Types.GET_SUMMARY_FARM_COST:
      return {
        ...state,
        summaryFarmCostData: null,
        loadingRequestSummaryFarmCostData: true,
      };
    case Types.GET_SUMMARY_FARM_COST_SUCCESS:
      return {
        ...state,
        summaryFarmCostData: action.payload.data,
        loadingRequestSummaryFarmCostData: false,
      };
    case Types.GET_SUMMARY_FARM_COST_ERROR:
      return {
        ...state,
        summaryFarmCostData: null,
        loadingRequestSummaryFarmCostData: false,
      };
    default:
      return state;
  }
}
