import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Select, Spin, Row, Col } from "antd";
import moment from "moment";
import CanvasJSReact from "../../../../../assets/canvasjs.react";

import { Container } from "./styles";

import { useFinancial } from "../../../../../hooks/useFinancialReducer";

// Modifica os separadores de milhar e decimal
CanvasJSReact.CanvasJS.addCultureInfo("br", {
  decimalSeparator: ",",
  digitGroupSeparator: ".",
});

const { CanvasJSChart } = CanvasJSReact;

const StackedColumnBarChart = (props) => {
  const { translation } = useSelector((state) => state.app);

  const [listCostType] = useState([
    { costType: "Growth", dataIndex: "growthTotalAccomplished" },
    {
      costType: "RecreateFattenPasture",
      dataIndex: "recreateFattenPastureTotalAccomplished",
    },
    {
      costType: "RecreateFattenSemiConfinement",
      dataIndex: "recreateFattenSemiConfinementTotalAccomplished",
    },
    {
      costType: "RecreateFattenConfinement",
      dataIndex: "recreateFattenConfinementTotalAccomplished",
    },
    { costType: "Agriculture", dataIndex: "agricultureTotalAccomplished" },
    { costType: "Others", dataIndex: "othersTotalAccomplished" },
  ]);

  const [costType, setCostType] = useState("AllTypes");
  const [listHarvests, setListHarvests] = useState([]);
  const [harvestSelected, setHarvestSelected] = useState(null);

  const [expenseData, setExpenseData] = useState([]);
  const [incomeData, setIncomeData] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [balanceData, setBalanceData] = useState([]);

  // Redux
  const {
    listFinancialCashFlow: cashFlowData,
    isLoadingListFinancialCashFlow,
  } = useFinancial();

  useEffect(() => {
    function loadHarvest() {
      if (
        Array.isArray(cashFlowData?.harvests) &&
        cashFlowData.harvests.length > 0
      ) {
        setListHarvests(cashFlowData.harvests);
        setHarvestSelected(
          cashFlowData.harvests.find((h) => h.current === true)?.id
        );
      }
    }

    if (cashFlowData !== null && cashFlowData !== undefined) {
      loadHarvest();
    }
  }, [cashFlowData]);

  const options = {
    culture: "br",
    animationEnabled: true,
    exportEnabled: false,
    axisY: {
      valueFormatString: "#,##0.00",
      includeZero: true,
      gridDashType: "dot",
    },
    toolTip: {
      shared: true,
      reversed: false,
    },
    legend: {
      verticalAlign: "center",
      horizontalAlign: "right",
      reversed: true,
      cursor: "pointer",
      itemclick: (e) => toggleDataSeries(e),
    },
    data: [
      {
        type: "stackedColumn",
        name: "Despesas",
        showInLegend: true,
        yValueFormatString: "#,##0.00",
        color: "#d44c4c",
        dataPoints: expenseData,
      },
      {
        type: "stackedColumn",
        name: "Receitas",
        showInLegend: true,
        yValueFormatString: "#,##0.00",
        color: "#33c162",
        dataPoints: incomeData,
      },
      {
        type: "line",
        name: "Resultado",
        showInLegend: true,
        color: "#166AAB",
        yValueFormatString: "#,##0.00",
        dataPoints: resultData,
      },
      {
        type: "line",
        name: "Saldo",
        showInLegend: true,
        color: "#4b4b4b",
        yValueFormatString: "#,##0.00",
        dataPoints: balanceData,
      },
    ],
  };

  const toggleDataSeries = (e) => {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    e.chart.render();
  };

  useEffect(() => {
    function generateChart() {
      if (
        Array.isArray(cashFlowData?.valuesGroupByNature) &&
        cashFlowData.valuesGroupByNature.length > 0
      ) {
        const startDateHarvest = listHarvests.find(
          (h) => h.id === harvestSelected
        )?.startDate;
        const endDateHarvest = listHarvests.find(
          (h) => h.id === harvestSelected
        )?.endDate;

        const expenseList = cashFlowData.valuesGroupByNature.find(
          (cf) => cf.natureType === "expense" && cf.natureName === "Total"
        )?.values;
        const incomeList = cashFlowData.valuesGroupByNature.find(
          (cf) => cf.natureType === "income" && cf.natureName === "Total"
        )?.values;

        // Filter expenses by harvest
        const expensesInPeriod = expenseList.filter((v) =>
          moment(v.movementDate).isBetween(
            moment(startDateHarvest),
            moment(endDateHarvest),
            "days",
            "[]"
          )
        );

        // Filter incomes by harvest
        const incomesInPeriod = incomeList.filter((v) =>
          moment(v.movementDate).isBetween(
            moment(startDateHarvest),
            moment(endDateHarvest),
            "days",
            "[]"
          )
        );

        // Chart Expense Values
        const expenseChartData = expensesInPeriod.map((expense) => {
          let expenseValue = 0;
          if (costType === "AllTypes") {
            expenseValue =
              expense.growthTotalAccomplished +
              expense.recreateFattenPastureTotalAccomplished +
              expense.recreateFattenSemiConfinementTotalAccomplished +
              expense.recreateFattenConfinementTotalAccomplished +
              expense.agricultureTotalAccomplished +
              expense.othersTotalAccomplished;
          } else {
            let costTypeDataIndex = listCostType.find(
              (ct) => ct.costType === costType
            )?.dataIndex;
            expenseValue = expense[costTypeDataIndex];
          }

          return {
            label: moment(expense.movementDate).format("MM/YYYY"),
            y: -expenseValue,
          };
        });

        // Chart Income values
        const incomeChartData = incomesInPeriod.map((income) => {
          let incomeValue = 0;
          if (costType === "AllTypes") {
            incomeValue =
              income.growthTotalAccomplished +
              income.recreateFattenPastureTotalAccomplished +
              income.recreateFattenSemiConfinementTotalAccomplished +
              income.recreateFattenConfinementTotalAccomplished +
              income.agricultureTotalForeseen +
              income.othersTotalForeseen;
          } else {
            let costTypeDataIndex = listCostType.find(
              (ct) => ct.costType === costType
            )?.dataIndex;
            incomeValue = income[costTypeDataIndex];
          }

          return {
            label: moment(income.movementDate).format("MM/YYYY"),
            y: incomeValue,
          };
        });

        // Chart Result and Balance values
        const resultChartData = [];
        const balanceChartData = [];
        let balance = 0;

        for (let index = 0; index < expenseChartData.length; index++) {
          let resultValue =
            expenseChartData[index].y + incomeChartData[index].y;
          balance = balance + resultValue;

          resultChartData.push({
            label: expenseChartData[index].label,
            y: resultValue,
          });
          balanceChartData.push({
            label: expenseChartData[index].label,
            y: balance,
          });
        }

        setExpenseData(expenseChartData);
        setIncomeData(incomeChartData);
        setResultData(resultChartData);
        setBalanceData(balanceChartData);
      }
    }

    if (
      cashFlowData !== null &&
      cashFlowData !== undefined &&
      harvestSelected !== null
    ) {
      generateChart();
    }
  }, [cashFlowData, costType, harvestSelected, listHarvests, listCostType]);

  return (
    <Fragment>
      <Container>
        <Row type="flex" align="middle" gutter={16} className="filterRow">
          <Col span={4}>
            <Row>
              <label className="filterLabel">
                {translation.financial.cashFlow.filters.harvest}
              </label>
            </Row>
            <Row>
              <Select
                style={{ width: "100%" }}
                name="harvestSelected"
                value={harvestSelected || undefined}
                placeholder={translation.defaultSelectPlaceholder}
                onChange={(value) => setHarvestSelected(value)}
              >
                {listHarvests.map((h) => (
                  <Select.Option key={h.id} value={h.id}>{`${moment(
                    h.startDate
                  ).format("MM/YYYY")} à ${moment(h.endDate).format(
                    "MM/YYYY"
                  )}`}</Select.Option>
                ))}
              </Select>
            </Row>
          </Col>
          <Col span={6}>
            <Row>
              <label className="filterLabel">
                {translation.financial.cashFlow.filters.costType}
              </label>
            </Row>
            <Row>
              <Select
                style={{ width: "100%" }}
                name="costType"
                value={costType || undefined}
                placeholder={translation.defaultSelectPlaceholder}
                onChange={(value) => setCostType(value)}
              >
                <Select.Option key="AllTypes" value="AllTypes">
                  Todos
                </Select.Option>
                {listCostType.map((l) => (
                  <Select.Option key={l.costType} value={l.costType}>
                    {`${translation.costType[l.costType]}`}
                  </Select.Option>
                ))}
              </Select>
            </Row>
          </Col>
        </Row>
        <Spin spinning={isLoadingListFinancialCashFlow}>
          <CanvasJSChart options={options} />
        </Spin>
      </Container>
    </Fragment>
  );
};

export default StackedColumnBarChart;
