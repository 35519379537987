import React, { useCallback, useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  Creators as AnimalVaccinationActions,
  INITIAL_DATA_FORM_ANIMAL_VACCINATION,
} from "../../../store/ducks/vacinations";

import {
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  Row,
  Select,
  Spin,
  notification,
} from "antd";
import SelectAnimalsModal from "../../modals/selectAnimalsModal";
import ButtonStandard from "../../utils/button";
import { Body, Container, Footer } from "./styles";
// Services
import NumberFormat from "react-number-format";
import { getAnimalsByIdentificationHandlingNumber } from "../../../services/animalService";
import { numberMask } from "../../../services/helpersMethodsService";
import { findAll as findAllUnities } from "../../../services/unitService";
import {
  getAnimalLastVaccination,
  storeNewVaccination,
  updateVaccination,
} from "../../../services/vacinationService";
import { findAll } from "../../../services/veterinaryService";

function DrawerAnimalVaccinationForm() {
  const formikRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [veterinarySupplements, setVeterinarySupplements] = useState([]);
  const [isVisibleModalSelectAnimal, setIsVisibleModalSelectAnimal] =
    useState(false);
  const [animalSelected, setAnimalSelected] = useState(null);
  const [animalSelectedLastVaccination, setAnimalSelectedLastVaccination] =
    useState(null);
  const [veterinarySupplementSelected, setVeterinarySupplementSelected] =
    useState(null);
  const [animals, setAnimals] = useState([]);

  const [form, setForm] = useState(null);

  const validationSchema = Yup.object().shape({
    animalId: Yup.string().required(),
    veterinarySupplementId: Yup.string().required(),
    applicationQuantity: Yup.number().min(0).required(),
    applicationDay: Yup.date().required(),
  });

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const { isDrawerFormVisible, formData: data } = useSelector(
    (state) => state.vacinations
  );

  const dispatch = useDispatch();

  const closeDrawer = useCallback(() => {
    formikRef.current.resetForm(INITIAL_DATA_FORM_ANIMAL_VACCINATION);
    setAnimalSelected(null);
    setVeterinarySupplementSelected(null);
    setAnimalSelectedLastVaccination(null);
    dispatch(AnimalVaccinationActions.closeDrawerForm());
  }, [dispatch]);

  const findAnimal = useCallback(
    async (identification) => {
      const setFieldValue = formikRef.current.setFieldValue;
      const setFieldError = formikRef.current.setFieldError;
      setIsLoading(true);
      try {
        const {
          data: { results },
        } = await getAnimalsByIdentificationHandlingNumber({
          groupId,
          farmId,
          identification,
        });
        if (results.length === 0) {
          notification.error({
            message: "Nenhum animal encontrado!",
          });
          setFieldError("handlingNumber", "Animal não encontrado");
        } else if (results.length > 1) {
          setAnimals(results);
          setIsVisibleModalSelectAnimal(true);
        } else {
          setAnimalSelected(results[0]);
          setFieldValue("animalId", results[0]?.id);
          setFieldValue("handlingNumber", results[0]?.handlingNumber);
        }
      } catch (error) {
        notification.error({
          message: "Nenhum animal encontrado!",
        });
        setFieldError("handlingNumber", "Animal não encontrado");
      } finally {
        setIsLoading(false);
      }
    },
    [farmId, groupId]
  );

  const onConfirmSelectionAnimal = useCallback(
    (keys) => {
      const setFieldValue = formikRef.current.setFieldValue;
      const animalIndex = animals.findIndex((animal) => animal.id === keys[0]);
      const animal = animals[animalIndex];
      if (animal) {
        setAnimalSelected(animal);
        setFieldValue("animalId", animal?.id);
        setFieldValue("handlingNumber", animal?.handlingNumber);
      }
      setIsVisibleModalSelectAnimal(false);
    },
    [animals]
  );

  const onCancelSelectionAnimal = useCallback(() => {
    setIsVisibleModalSelectAnimal(false);
  }, []);

  const handleSelectVeterinarySupplement = useCallback(
    (setFieldValue, value) => {
      setFieldValue("veterinarySupplementId", value);
      const selectedVetSup = veterinarySupplements.find(
        (vetSup) => vetSup.id === value
      );
      setVeterinarySupplementSelected(selectedVetSup);
    },
    [veterinarySupplements]
  );

  const handleGetVeterinarySupplements = useCallback(async () => {
    try {
      const [
        {
          data: { results },
        },
        {
          data: { results: unities },
        },
      ] = await Promise.all([
        findAll({
          groupId,
          farmId,
        }),
        findAllUnities({
          groupId,
          farmId,
        }),
      ]);

      const veterinarySupplementsActive = results
        .filter(
          (veterinarySupplement) =>
            veterinarySupplement.status === "A" ||
            veterinarySupplement.status === "Active"
        )
        .map((vetSup) => {
          const unitIndex = unities.findIndex(
            (unit) => unit.id === vetSup.unitId
          );
          vetSup.unitName = unitIndex >= 0 ? unities[unitIndex].name : null;
          return vetSup;
        });

      setVeterinarySupplements(veterinarySupplementsActive);
    } catch (error) {}
  }, [groupId, farmId]);

  const handleDrawerOpen = (visible) => {
    if (visible === true) {
      setForm(data);
      handleGetVeterinarySupplements();
      formikRef.current.setValues(data);
    }
  };

  const handleSubmitModalForm = useCallback(
    async (values, actions) => {
      setIsLoading(true);
      try {
        if (values.id) {
          await updateVaccination({
            groupId,
            farmId,
            id: values.id,

            body: values,
          });
        } else {
          await storeNewVaccination({
            groupId,
            farmId,
            body: values,
          });
        }
        notification.success({
          message: "Aplicação foi criada/atualizada com sucesso.",
        });
        dispatch(AnimalVaccinationActions.index(groupId, farmId));
        if (values.saveOptions && values.saveOptions === "save_and_add_new") {
          formikRef.current.resetForm({
            ...INITIAL_DATA_FORM_ANIMAL_VACCINATION,
            veterinarySupplementId: values.veterinarySupplementId,
            applicationDay: values.applicationDay,
            applicationQuantity: values.applicationQuantity,
          });
          setAnimalSelected(null);
          setAnimalSelectedLastVaccination(null);
        } else {
          closeDrawer();
        }
      } catch (error) {
        notification.error({
          message: "Aplicação não foi criada/atualizada. Contate o suporte",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [closeDrawer, dispatch, farmId, groupId]
  );

  useEffect(() => {
    async function fetchData(animalId) {
      try {
        const {
          data: { results },
        } = await getAnimalLastVaccination({
          groupId,
          farmId,
          animalId,
        });
        setAnimalSelectedLastVaccination(results);
      } catch (error) {}
    }
    if (
      data &&
      data.id !== null &&
      data.animalId !== null &&
      veterinarySupplements.length > 0
    ) {
      fetchData(data.animalId);
      setVeterinarySupplementSelected(
        veterinarySupplements.find(
          (vetSup) => vetSup.id === data.veterinarySupplementId
        )
      );
    }
  }, [data, farmId, groupId, veterinarySupplements]);

  useEffect(() => {
    async function fetchData() {
      try {
        const animalId = animalSelected.id;
        const {
          data: { results },
        } = await getAnimalLastVaccination({
          groupId,
          farmId,
          animalId,
        });
        setAnimalSelectedLastVaccination(results);
      } catch (error) {}
    }
    if (animalSelected && groupId && farmId) {
      fetchData();
    }
  }, [animalSelected, groupId, farmId]);

  return (
    <Container
      title={
        data.id != null
          ? translation.handling.vacinations.form.titleEdit
          : translation.handling.vacinations.form.titleCreateNew
      }
      width={770}
      onClose={closeDrawer}
      maskClosable={false}
      visible={isDrawerFormVisible}
      afterVisibleChange={handleDrawerOpen}
    >
      <Formik
        ref={formikRef}
        enableReinitialize={true}
        initialValues={form}
        onSubmit={handleSubmitModalForm}
        validationSchema={validationSchema}
        render={({
          values,
          errors,
          setFieldValue,
          submitCount,
          resetForm,
          handleSubmit,
        }) => (
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit} autoComplete="off">
              <Body>
                {Object.entries(errors).length > 0 && submitCount > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                <Row type="flex" gutter={8}>
                  <Col span={6}>
                    <Row>
                      <label
                        className={
                          errors.applicationDay && submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {translation.handling.vacinations.form.applicationDay}*
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <DatePicker
                        autoFocus={true}
                        format="DD/MM/YYYY"
                        value={
                          values.applicationDay
                            ? moment(values.applicationDay)
                            : undefined
                        }
                        placeholder={translation.defaultDatePickerPlaceholder}
                        disabledDate={(current) =>
                          animalSelected != null
                            ? current.isAfter(moment()) ||
                              current.isBefore(moment(animalSelected?.birthday))
                            : current.isAfter(moment())
                        }
                        onChange={(date) => {
                          setFieldValue("applicationDay", date);
                        }}
                      />
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <label
                        className={
                          (errors.handlingNumber || errors.animalId) &&
                          submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {translation.handling.vacinations.form.animalId}*
                      </label>
                    </Row>
                    <Row
                      type="flex"
                      align="middle"
                      className="rowInput"
                      gutter={16}
                    >
                      <Col span={16}>
                        <Input
                          value={values.handlingNumber ?? undefined}
                          placeholder={translation.defaultPlaceholder}
                          disabled={values.id != null}
                          onPressEnter={(e) => {
                            e.preventDefault();
                            findAnimal(values.handlingNumber);
                          }}
                          onChange={(e) =>
                            setFieldValue("handlingNumber", e.target.value)
                          }
                        />
                      </Col>
                      <Col span={8}>
                        <Button
                          disabled={values.id != null}
                          shape="circle"
                          icon="search"
                          onClick={() => findAnimal(values.handlingNumber)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  {!values.id && (
                    <Col span={6}>
                      <Row>
                        <center>
                          <label>
                            {
                              translation.handling.vacinations.form
                                .lastDailyWeight
                            }
                          </label>
                        </center>
                      </Row>
                      <Row className="rowInput">
                        <center>
                          <strong>
                            {animalSelected?.lastDailyWeight
                              ? `${numberMask(
                                  animalSelected?.lastDailyWeight,
                                  false
                                )} kg`
                              : "-"}
                          </strong>
                        </center>
                      </Row>
                    </Col>
                  )}
                </Row>
                <Row type="flex" gutter={8}>
                  <Col span={6}>
                    <Row>
                      <label
                        className={
                          errors.veterinarySupplementId && submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {
                          translation.handling.vacinations.form
                            .veterinarySupplementId
                        }
                        *
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <Select
                        value={values.veterinarySupplementId ?? undefined}
                        placeholder={translation.defaultSelectPlaceholder}
                        onChange={(value) => {
                          handleSelectVeterinarySupplement(
                            setFieldValue,
                            value
                          );
                        }}
                      >
                        {veterinarySupplements.map((veterinarySupplement) => (
                          <Select.Option
                            key={veterinarySupplement.id}
                            value={veterinarySupplement.id}
                          >
                            {veterinarySupplement.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <center>
                        <label>
                          {
                            translation.handling.vacinations.form
                              .veterinarySupplementApplicationType
                          }
                        </label>
                      </center>
                    </Row>
                    <Row className="rowInput">
                      <center>
                        <strong>
                          {veterinarySupplementSelected?.applicationType
                            ? veterinarySupplementSelected?.applicationType ===
                              "PorPeso"
                              ? "Por Peso"
                              : "Fixo"
                            : "-"}
                        </strong>
                      </center>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <center>
                        <label>
                          {
                            translation.handling.vacinations.form
                              .applicationUnit
                          }
                        </label>
                      </center>
                    </Row>
                    <Row className="rowInput">
                      <center>
                        <strong>
                          {veterinarySupplementSelected?.unitName ?? "-"}
                        </strong>
                      </center>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <center>
                        <label>
                          {
                            translation.handling.vacinations.form
                              .veterinarySupplementApplicationValue
                          }
                        </label>
                      </center>
                    </Row>
                    <Row className="rowInput">
                      <center>
                        <strong>
                          {veterinarySupplementSelected?.applicationValue
                            ? numberMask(
                                veterinarySupplementSelected?.applicationValue,
                                false
                              )
                            : "-"}
                        </strong>
                      </center>
                    </Row>
                  </Col>
                </Row>
                <Row type="flex" gutter={8}>
                  <Col span={8}>
                    <Row>
                      <label
                        className={
                          errors.applicationQuantity && submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {
                          translation.handling.vacinations.form
                            .applicationQuantity
                        }
                        *
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <NumberFormat
                        customInput={Input}
                        value={values.applicationQuantity ?? ""}
                        placeholder={translation.defaultPlaceholder}
                        decimalScale={2}
                        allowLeadingZeros
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        onValueChange={({ floatValue }) =>
                          setFieldValue("applicationQuantity", floatValue)
                        }
                      />
                    </Row>
                  </Col>
                </Row>
                <Divider dashed>
                  {translation.handling.vacinations.form.dividerTitle}
                </Divider>
                <Row type="flex" justify="space-between" gutter={8}>
                  <Col>
                    <Row>
                      <center>
                        <label>
                          {translation.handling.vacinations.form.applicationDay}
                        </label>
                      </center>
                    </Row>
                    <Row>
                      <center>
                        <strong>
                          {animalSelectedLastVaccination?.applicationDay
                            ? moment(
                                animalSelectedLastVaccination?.applicationDay
                              ).format("DD/MM/YYYY")
                            : "-"}
                        </strong>
                      </center>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <center>
                        <label>
                          {
                            translation.handling.vacinations.form
                              .veterinarySupplementId
                          }
                        </label>
                      </center>
                    </Row>
                    <Row>
                      <center>
                        <strong>
                          {animalSelectedLastVaccination?.veterinarySupplementName ??
                            "-"}
                        </strong>
                      </center>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <center>
                        <label>
                          {
                            translation.handling.vacinations.form
                              .applicationUnit
                          }
                        </label>
                      </center>
                    </Row>
                    <Row>
                      <center>
                        <strong>
                          {animalSelectedLastVaccination?.applicationUnit ??
                            "-"}
                        </strong>
                      </center>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <center>
                        <label>
                          {
                            translation.handling.vacinations.form
                              .applicationQuantity
                          }
                        </label>
                      </center>
                    </Row>
                    <Row>
                      <center>
                        <strong>
                          {animalSelectedLastVaccination?.applicationQuantity
                            ? numberMask(
                                animalSelectedLastVaccination?.applicationQuantity,
                                false
                              )
                            : "-"}
                        </strong>
                      </center>
                    </Row>
                  </Col>
                </Row>
              </Body>
              {/* Footer */}
              <Footer>
                <Row type="flex" justify="space-between">
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="selectDiv"
                  >
                    {values.id === null && (
                      <Select
                        value={values.saveOptions ?? undefined}
                        placeholder={translation.defaultSelectPlaceholder}
                        name="saveOptions"
                        onChange={(value) =>
                          setFieldValue("saveOptions", value)
                        }
                      >
                        <Select.Option key="1" value="only_save">
                          Salvar somente
                        </Select.Option>
                        <Select.Option key="2 " value="save_and_add_new">
                          Salvar e adicionar nova aplicação
                        </Select.Option>
                      </Select>
                    )}
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="buttonsDiv"
                  >
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={() => closeDrawer(resetForm)}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>
                    <ButtonStandard type="submit" buttonType="type6">
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          </Spin>
        )}
      />
      <SelectAnimalsModal
        data={animals}
        visible={isVisibleModalSelectAnimal}
        selectedAnimalsKeys={animalSelected ? [animalSelected] : []}
        onConfirmSelection={onConfirmSelectionAnimal}
        onCancel={onCancelSelectionAnimal}
        isMultiple={false}
      />
    </Container>
  );
}

export default DrawerAnimalVaccinationForm;
