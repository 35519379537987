import React from "react";
const EyeIcon = ({ isActive }) =>
  isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.506"
      height="11.031"
      viewBox="0 0 18.506 11.031"
    >
      <g id="eye" transform="translate(-88.566 -453)">
        <path
          id="Path_233"
          data-name="Path 233"
          d="M9.253,98.725c-3.536,0-6.742,1.934-9.108,5.077a.733.733,0,0,0,0,.874c2.366,3.146,5.572,5.08,9.108,5.08s6.742-1.934,9.108-5.077a.733.733,0,0,0,0-.874C16,100.659,12.789,98.725,9.253,98.725Zm.254,9.4a3.892,3.892,0,1,1,3.63-3.63A3.895,3.895,0,0,1,9.507,108.125Zm-.117-1.794a2.1,2.1,0,1,1,1.957-1.957A2.092,2.092,0,0,1,9.389,106.33Z"
          transform="translate(88.566 354.275)"
          fill="#684E94"
        />
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.351"
      height="11.314"
      viewBox="0 0 16.351 11.314"
    >
      <g
        id="Group_4831"
        data-name="Group 4831"
        transform="translate(-421.648 -427.49)"
      >
        <path
          id="Subtraction_3"
          data-name="Subtraction 3"
          d="M12583.177-4609.865a8.283,8.283,0,0,1-2.221-.314l.913-.913a3.325,3.325,0,0,0,1.308.266,3.356,3.356,0,0,0,3.354-3.353,3.318,3.318,0,0,0-.267-1.308l1.632-1.632a14.63,14.63,0,0,1,3.337,2.621.484.484,0,0,1,0,.638C12591.085-4613.7,12587.57-4609.865,12583.177-4609.865Zm-4.245-1.117h0a14.433,14.433,0,0,1-3.81-2.877.484.484,0,0,1,0-.638c.036-.04,3.6-3.994,8.055-3.994a8.545,8.545,0,0,1,2.778.487l-.991.991a3.337,3.337,0,0,0-1.787-.517,3.357,3.357,0,0,0-3.353,3.353,3.339,3.339,0,0,0,.517,1.787l-1.408,1.409Z"
          transform="translate(-12153.354 5047.49)"
          fill="#c4c4c4"
        />
        <rect
          id="Rectangle_1748"
          data-name="Rectangle 1748"
          width="1"
          height="15"
          transform="translate(434.597 427.49) rotate(45)"
          fill="#c4c4c4"
        />
      </g>
    </svg>
  );

export default EyeIcon;
