// import axios from 'axios';
import apiFarms from "../config/api_farms";
import getHeaders from "./getHeaders";

export function getDietIndex(payload) {
  const { groupId, farmId, page, sorter, filters, signal } = payload;

  const { field, order } = sorter;

  const orderS = order === "descend" ? "DESC" : "ASC";

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/diets?
    ${page ? `&page=${page - 1}` : ""}
    ${
      Object.entries(sorter).length > 0
        ? `&sort=${field}&direction=${orderS}`
        : ""
    }
    ${filters !== "" ? `&search=${filters}` : ""}&withoutPagination=false`,
    getHeaders(false, signal)
  );
}

export function getDietListIndex(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/diets?withoutPagination=true`,
    getHeaders(false, signal)
  );
}

export function getDietShow(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/diets/${id}`,
    getHeaders(false, signal)
  );
}

export function saveDiet(payload) {
  const { groupId, farmId, id, diet, signal } = payload;
  if (id != null) {
    return apiFarms.patch(
      `/bovexo/${groupId}/farms/${farmId}/diets/${id}`,
      diet,
      getHeaders(false, signal)
    );
  } else {
    return apiFarms.post(
      `/bovexo/${groupId}/farms/${farmId}/diets`,
      diet,
      getHeaders(false, signal)
    );
  }
}

export function deleteDietServ(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiFarms.delete(
    `/bovexo/${groupId}/farms/${farmId}/diets/${id}`,
    getHeaders(false, signal)
  );
}
