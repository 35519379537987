export const Types = {
  RESET_DATA: "benchmark/RESET_DATA",
  GET_ALL_BENCHMARK: "benchmark/GET_ALL_BENCHMARK",
  GET_ALL_BENCHMARK_SUCCESS: "benchmark/GET_ALL_BENCHMARK_SUCCESS",
  GET_ALL_BENCHMARK_ERROR: "benchmark/GET_ALL_BENCHMARK_ERROR",
  GET_BENCHMARK: "benchmark/GET_BENCHMARK",
  GET_BENCHMARK_SUCCESS: "benchmark/GET_BENCHMARK_SUCCESS",
  GET_BENCHMARK_ERROR: "benchmark/GET_BENCHMARK_ERROR",
  SHOW_DRAWER_BENCHMARK: "benchmark/SHOW_DRAWER_BENCHMARK",
  HIDE_DRAWER_BENCHMARK: "benchmark/HIDE_DRAWER_BENCHMARK",
  SAVE_BENCHMARK: "benchmark/SAVE_BENCHMARK",
  SAVE_BENCHMARK_SUCCESS: "benchmark/SAVE_BENCHMARK_SUCCESS",
  SAVE_BENCHMARK_ERROR: "benchmark/SAVE_BENCHMARK_ERROR",
  DELETE_BENCHMARK: "benchmark/DELETE_BENCHMARK",
  DELETE_BENCHMARK_SUCCESS: "benchmark/DELETE_BENCHMARK_SUCCESS",
  DELETE_BENCHMARK_ERROR: "benchmark/DELETE_BENCHMARK_ERROR"
};

export const Creators = {
  indexBenchmark: (groupId, farmId, page = 0, sorter = {}, filters = "") => ({
    type: Types.GET_ALL_BENCHMARK,
    payload: {
      groupId,
      farmId,
      page,
      sorter,
      filters
    }
  }),
  indexBenchmarkSuccess: data => ({
    type: Types.GET_ALL_BENCHMARK_SUCCESS,
    payload: {
      data
    }
  }),
  indexBenchmarkError: error => ({
    type: Types.GET_ALL_BENCHMARK_ERROR,
    payload: {
      error
    }
  }),
  showBenchmark: (groupId, farmId, id) => ({
    type: Types.GET_BENCHMARK,
    payload: {
      groupId,
      farmId,
      id
    }
  }),
  showBenchmarkSuccess: (data) => ({
    type: Types.GET_BENCHMARK_SUCCESS,
    payload: {
      data
    }
  }),
  showBenchmarkError: error => ({
    type: Types.GET_BENCHMARK_ERROR,
    payload: {
      error
    }
  }),
  showDrawer: (actionType = "edit") => ({
    type: Types.SHOW_DRAWER_BENCHMARK,
    payload: {
      actionType
    }
  }),
  hideDrawer: () => ({
    type: Types.HIDE_DRAWER_BENCHMARK,
    payload: {}
  }),
  saveBenchmark: (groupId, farmId, id = null, benchmark, saveOptions) => ({
    type: Types.SAVE_BENCHMARK,
    payload: {
      groupId,
      farmId,
      id,
      benchmark,
      saveOptions
    }
  }),
  saveBenchmarkSuccess: (data, saveOptions) => ({
    type: Types.SAVE_BENCHMARK_SUCCESS,
    payload: {
      data, 
      saveOptions }
  }),
  saveBenchmarkError: error => ({
    type: Types.SAVE_BENCHMARK_ERROR,
    payload: { error }
  })
};

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  benchmarkData: null,
  drawerVisible: false,
  error: {}
};

export default function benchmark(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_ALL_BENCHMARK:
      return { ...state, isLoading: true };
    case Types.GET_ALL_BENCHMARK_SUCCESS:
      const { data: benchmarks } = action.payload;
      return { ...state, isLoading: false, data: benchmarks };
    case Types.GET_ALL_BENCHMARK_ERROR:
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: null,
        error
      };
    case Types.GET_BENCHMARK:
      return { ...state, isLoading: true };
    case Types.GET_BENCHMARK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        benchmarkData: action.payload.data
      };
    case Types.GET_BENCHMARK_ERROR:
      return {
        ...state,
        isLoading: false,
        benchmarkData: null,
        error
      };
    case Types.SHOW_DRAWER_BENCHMARK:
      return {
        ...state,
        drawerVisible: true,
        benchmarkData: action.payload.actionType === "new" ? null : state.benchmarkData,
      };
    case Types.HIDE_DRAWER_BENCHMARK:
      return { ...state, drawerVisible: false, benchmarkData: null };
    case Types.SAVE_BENCHMARK:
      return { ...state, isLoading: true };
    case Types.SAVE_BENCHMARK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        benchmarkData: null,
        drawerVisible: action.payload.saveOptions
      };
    case Types.SAVE_BENCHMARK_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    case Types.DELETE_BENCHMARK:
      return { ...state, isLoading: true };
    case Types.DELETE_BENCHMARK_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case Types.DELETE_BENCHMARK_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}
