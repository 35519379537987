import React from "react";

const FavoriteIcon = () => (
  <svg
    id="favoriteIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="32.606"
    height="29.031"
    viewBox="0 0 32.606 29.031"
  >
    <g
      id="Group_4305"
      data-name="Group 4305"
      transform="translate(-393.394 -314)"
    >
      <g id="star" transform="translate(393.909 304.146)">
        <g
          id="Group_4302"
          data-name="Group 4302"
          transform="translate(4.937 15.933)"
        >
          <g id="Group_4301" data-name="Group 4301" transform="translate(0 0)">
            <path
              id="Path_2266"
              data-name="Path 2266"
              d="M113.087,97.432a1.1,1.1,0,0,0-.961-.769l-8.588-.8-1.379-3.188A.411.411,0,0,0,101.4,93l1.475,3.413a.411.411,0,0,0,.34.247l8.832.819a.3.3,0,0,1,.168.518l-6.664,5.854a.411.411,0,0,0-.13.4l1.95,8.652a.3.3,0,0,1-.44.32L99.308,108.7a.412.412,0,0,0-.42,0l-7.626,4.528a.3.3,0,0,1-.44-.32l.814-3.688.006-.029a.411.411,0,0,0-.8-.181l-.821,3.717a1.119,1.119,0,0,0,1.663,1.208l7.416-4.4,7.416,4.4a1.1,1.1,0,0,0,1.229-.057,1.1,1.1,0,0,0,.434-1.152l-.85-3.772-1.046-4.642,6.48-5.693A1.1,1.1,0,0,0,113.087,97.432Z"
              transform="translate(-89.99 -91.665)"
              stroke="#000"
              strokeWidth="1"
            />
          </g>
        </g>
        <g
          id="Group_4304"
          data-name="Group 4304"
          transform="translate(0 11.537)"
        >
          <g id="Group_4303" data-name="Group 4303" transform="translate(0 0)">
            <path
              id="Path_2267"
              data-name="Path 2267"
              d="M15.778,13.87h0l-.705-1.657a1.119,1.119,0,0,0-2.056,0L9.606,20.135,7.8,20.3l-6.778.629a1.119,1.119,0,0,0-.635,1.955l6.48,5.693-.287,1.1-.158.7a.411.411,0,0,0,.311.492l.012,0,.079.008a.412.412,0,0,0,.4-.321l0-.011.5-2.03a.411.411,0,0,0-.13-.4L.926,22.269a.3.3,0,0,1,.168-.518l7.633-.708,1.2-.111a.412.412,0,0,0,.34-.247l3.508-8.146a.3.3,0,0,1,.544,0l.705,1.657a.411.411,0,1,0,.756-.325Z"
              transform="translate(0 -11.537)"
              stroke="#000"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
      <circle
        id="Ellipse_356"
        data-name="Ellipse 356"
        cx="9.5"
        cy="9.5"
        r="9.5"
        transform="translate(407 314)"
        fill="red"
        opacity="0.65"
      />
    </g>
  </svg>
);
export default FavoriteIcon;
