import {
  Col,
  InputNumber,
  Radio,
  Row,
  Select,
  Table,
  notification,
} from "antd";
import Column from "antd/lib/table/Column";
import moment from "moment";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import AlertIcon from "../../../../../components/drawers/alert/icons/alert";
import DrawerPriceLockDetails from "../../../../../components/drawers/saleScenario/priceLockDetails";
import ButtonStandard from "../../../../../components/utils/button";
import EyeIcon from "../../../../../components/utils/icons/eye";
import { numberMask } from "../../../../../services/helpersMethodsService";
import {
  findPriceLock,
  saveContracts,
} from "../../../../../services/saleScenarioService";
import { CustomDivider, TabSaleScenarioInfoContent } from "../../styles";
import SaleScenarioDetailsPricesLocksTabChart from "./chart";

const corretoraList = [
  {
    name: "Rico Invest.",
    code: "ricoInvest",
    value: Math.floor(Math.random() * 500) / 100,
  },
  {
    name: "Rural Vent.",
    code: "ruralVent",
    value: Math.floor(Math.random() * 500) / 100,
  },
  { name: "B3", code: "b3", value: Math.floor(Math.random() * 500) / 100 },
  {
    name: "Chicago",
    code: "chicago",
    value: Math.floor(Math.random() * 500) / 100,
  },
];

const FORMAT_DATE = "DD/MM/YYYY";
const PRODUCTION_COST = 264;

const SaleScenarioDetailsPricesLocksTab = memo(
  ({
    tabActive,
    marketAnalysisId,
    listOfSaleScenarioResultSell,
    listOfSaleScenarioResultAnimal,
  }) => {
    const {
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    } = useSelector((state) => state.app);

    const {
      params: { id: saleScenarioId, resultId: saleScenarioResultId },
    } = useRouteMatch();

    // Price Lock *** START ***
    const [savedPriceLockData, setSavedPriceLockData] = useState([]);
    const [selectedDatesForPriceLock, setSelectedDatesForPriceLock] = useState(
      []
    );
    const [selectedCorretoras, setSelectedCorretoras] = useState([]);
    const [showTableCotacaoValues, setShowTableCotacaoValues] = useState(false);
    const [corretorasData, setCorretorasData] = useState([]);
    const [selectedValuesForPriceLock, setSelectedValuesForPriceLock] =
      useState([]);
    const [selectedValuesForPriceLockKeys, setSelectedValuesForPriceLockKeys] =
      useState([]);
    const [drawerPriceLockVisible, setDrawerPriceLockVisible] = useState(false);
    const [lockedPriceInfo, setLockedPriceInfo] = useState(null);

    const [lockOption, setLockOption] = useState(null);
    const [marginLock, setMarginLock] = useState(null);
    const [futurePriceLock, setFuturePriceLock] = useState(null);

    const PRODUCTION_COSTPlusMarginLock = useMemo(() => {
      if (marginLock) {
        return PRODUCTION_COST + PRODUCTION_COST * (marginLock / 100);
      }
      return 0;
    }, [marginLock]);

    const priceLockData = useMemo(() => {
      let data = [];
      if (
        Array.isArray(listOfSaleScenarioResultSell) &&
        listOfSaleScenarioResultSell.length > 0
      ) {
        listOfSaleScenarioResultSell
          .filter((ssrs) => ssrs.sellDate != null)
          .forEach((ssrs, index) => {
            data.push({
              id: index,
              resultSellId: ssrs.id,
              sellDate: ssrs.sellDate,
              weight: ssrs.totalWeightAnimals / 30,
              price: ssrs.weightPrice,
              locked: ssrs.priceLocked,
              alertPrice: index === 0,
            });
          });
      }
      return data;
    }, [
      listOfSaleScenarioResultAnimal,
      listOfSaleScenarioResultSell,
      savedPriceLockData,
    ]);

    const handleCotacao = useCallback(() => {
      let data = [];
      selectedCorretoras.map((code) => {
        let corretoraSelected = corretoraList.find((c) => c.code === code);

        selectedDatesForPriceLock.map((date, index) => {
          let d = priceLockData.find((d) => d.sellDate === date);

          data.push({
            id: code + index,
            resultSellId: d.resultSellId,
            date: d.sellDate,
            weight: d.weight,
            price: d.price,
            corretoraCode: code,
            corretora: corretoraSelected.name,
            lockCost: corretoraSelected.value,
            lockCostPercentage: corretoraSelected.value / d.price,
          });
        });
      });
      setCorretorasData(data);
      setShowTableCotacaoValues(true);
      setSelectedValuesForPriceLock([]);
      setSelectedValuesForPriceLockKeys([]);
    }, [
      selectedDatesForPriceLock,
      selectedCorretoras,
      setShowTableCotacaoValues,
      setCorretorasData,
    ]);

    const handleContratacao = useCallback(async () => {
      if (
        Array.isArray(selectedValuesForPriceLock) &&
        selectedValuesForPriceLock.length > 0
      ) {
        try {
          await saveContracts({
            groupId,
            farmId,
            saleScenarioId,
            saleScenarioResultId,
            body: selectedValuesForPriceLock,
          });

          notification.success({
            message: "Contratação realizada com sucesso.",
          });
          window.location.reload();
        } catch (error) {
          console.error(error);
          notification.error({
            message: "Erro ao tentar salvar a contratação, contate o suporte!",
          });
        }
      }
    }, [
      groupId,
      farmId,
      saleScenarioId,
      saleScenarioResultId,
      selectedValuesForPriceLock,
    ]);

    const handleShowLockDetails = useCallback(
      (sellDate) => {
        if (Array.isArray(savedPriceLockData)) {
          let info = savedPriceLockData.find(
            (item) => item.sellDate === sellDate
          );
          if (info != null) {
            setLockedPriceInfo(info);
            setDrawerPriceLockVisible(true);
          }
        }
      },
      [savedPriceLockData]
    );

    const handleHideDrawerPriceLock = useCallback(() => {
      setDrawerPriceLockVisible(false);
    }, []);

    useEffect(() => {
      async function fetchPriceLock() {
        try {
          const {
            data: { results },
          } = await findPriceLock({
            groupId,
            farmId,
            saleScenarioId,
            saleScenarioResultId,
          });

          if (Array.isArray(results) && results.length > 0) {
            results.forEach((r) => {
              let corretoraSelected = corretoraList.find(
                (c) => c.code === r.corretoraCode
              );
              if (corretoraSelected != null) {
                r.corretoraName = corretoraSelected.name;
              }
            });
            setSavedPriceLockData(results);
          } else {
            setSavedPriceLockData([]);
          }
        } catch (error) {
          console.error(error);
        }
      }
      if (saleScenarioResultId != null) {
        fetchPriceLock();
      }
    }, [groupId, farmId, saleScenarioId, saleScenarioResultId]);

    return (
      <>
        <TabSaleScenarioInfoContent active={tabActive === 4 ? true : false}>
          <Row type="flex">
            <span className="titleParameters">TRAVA DE VALOR FUTURO DA @</span>
          </Row>
          <Row type="flex">
            <Table
              rowKey="sellDate"
              dataSource={priceLockData != null ? priceLockData : []}
              size="small"
              rowSelection={{
                getCheckboxProps: (record) => ({
                  disabled: record.locked,
                }),
                selectedRowKeys: selectedDatesForPriceLock,
                hideDefaultSelections: true,
                onSelect: (record, selected) => {
                  let updatedList = [];
                  if (selected) {
                    updatedList = [
                      ...selectedDatesForPriceLock,
                      record.sellDate,
                    ];
                  } else {
                    updatedList = selectedDatesForPriceLock.filter(
                      (o) => o !== record.sellDate
                    );
                  }
                  setSelectedDatesForPriceLock(updatedList);
                },
                onSelectAll: (record, selected) =>
                  setSelectedDatesForPriceLock(
                    selected.map((sr) => sr.sellDate)
                  ),
              }}
              pagination={false}
              className="stretch-component"
            >
              <Column
                title="Data"
                dataIndex="sellDate"
                key="sellDate"
                align="left"
                render={(text, record) => {
                  if (record.locked)
                    return (
                      <span>
                        {moment(text).format(FORMAT_DATE)}
                        <button
                          className="lockDetails"
                          type="button"
                          onClick={() =>
                            handleShowLockDetails(record?.sellDate)
                          }
                        >
                          <EyeIcon isActive />
                        </button>
                      </span>
                    );
                  else return <span>{moment(text).format(FORMAT_DATE)}</span>;
                }}
              />
              <Column
                title="Qtd @"
                dataIndex="weight"
                key="weight"
                align="left"
                render={(text) => numberMask(text)}
              />
              <Column
                title="Valor @"
                dataIndex="price"
                key="price"
                align="left"
                render={(text) => numberMask(text)}
              />
              <Column
                dataIndex="alertPrice"
                key="alertPrice"
                align="left"
                render={(text) => (text === true ? <AlertIcon /> : null)}
              />
            </Table>
          </Row>
          {/* Chart */}
          <SaleScenarioDetailsPricesLocksTabChart
            marketAnalysisId={marketAnalysisId}
            priceLockData={priceLockData}
          />
          <CustomDivider dashed />
          <Row type="flex">
            <span className="titleParameters">TRAVAR:</span>
          </Row>
          <Row type="flex">
            <Col span={24}>
              <Radio.Group
                onChange={(e) => setLockOption(e.target.value)}
                value={lockOption}
              >
                <Radio
                  style={{
                    display: "block",
                    height: "30px",
                    lineHeight: "30px",
                  }}
                  value={1}
                >
                  CUSTO DE PRODUÇÃO + MARGEM DE{" "}
                  <InputNumber
                    style={{
                      width: 80,
                    }}
                    formatter={(value) => `${value} %`}
                    step={1}
                    disabled={!lockOption || lockOption === 2}
                    parser={(value) => value.replace(/\D/g, "")}
                    value={marginLock || undefined}
                    onChange={setMarginLock}
                  />{" "}
                  = {`${numberMask(PRODUCTION_COSTPlusMarginLock, true)}`}
                </Radio>
                <Radio
                  style={{
                    display: "block",
                    height: "30px",
                    lineHeight: "30px",
                  }}
                  value={2}
                >
                  PREÇO FUTURO DA @ A{" "}
                  <InputNumber
                    style={{
                      width: 80,
                    }}
                    formatter={(value) => `R$ ${value}`}
                    parser={(value) => value.replace(/\D/g, "")}
                    step={1}
                    disabled={!lockOption || lockOption === 1}
                    value={futurePriceLock}
                    onChange={setFuturePriceLock}
                  />{" "}
                  COM MARGEM DE 4%
                </Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row type="flex">
            <Col span={16}>
              <Select
                name="corretoras"
                mode="multiple"
                allowClear
                value={selectedCorretoras}
                placeholder="Selecione as Corretoras"
                onChange={(value) => setSelectedCorretoras(value)}
                className="stretch-component"
              >
                {Array.isArray(corretoraList) && corretoraList.length > 0
                  ? corretoraList.map((item) => (
                      <Select.Option key={item.code} value={item.code}>
                        {item.name}
                      </Select.Option>
                    ))
                  : null}
              </Select>
            </Col>
            <Col span={7} offset={1}>
              <div className="stretch-component">
                <ButtonStandard
                  buttonType="type6"
                  onClick={() => handleCotacao()}
                  disabled={
                    !(
                      Array.isArray(selectedCorretoras) &&
                      selectedCorretoras.length > 0 &&
                      Array.isArray(selectedDatesForPriceLock) &&
                      selectedDatesForPriceLock.length > 0
                    )
                  }
                >
                  Cotar
                </ButtonStandard>
              </div>
            </Col>
          </Row>
          {showTableCotacaoValues ? (
            <>
              <Row type="flex">
                <Table
                  rowKey="id"
                  dataSource={corretorasData != null ? corretorasData : []}
                  size="small"
                  className="stretch-component hide-col-selection "
                  pagination={false}
                  rowSelection={{
                    selectedRowKeys: selectedValuesForPriceLockKeys,
                    hideDefaultSelections: true,
                    onSelect: (record, selected) => {
                      let updatedList = [];
                      let updatedListKeys = [];
                      if (selected) {
                        updatedList = [
                          ...selectedValuesForPriceLock.filter(
                            (o) => o.date !== record.date
                          ),
                          record,
                        ];
                        updatedListKeys = updatedList.map((o) => o.id);
                      } else {
                        updatedList = selectedValuesForPriceLock.filter(
                          (o) => o.date !== record.date
                        );
                        updatedListKeys = updatedList.map((o) => o.id);
                      }
                      setSelectedValuesForPriceLock(updatedList);
                      setSelectedValuesForPriceLockKeys(updatedListKeys);
                    },
                  }}
                >
                  <Column
                    title="Data"
                    dataIndex="date"
                    key="date"
                    align="left"
                    render={(text) => moment(text).format(FORMAT_DATE)}
                  />
                  <Column
                    title="Qtd @"
                    dataIndex="weight"
                    key="weight"
                    align="left"
                    render={(text) => numberMask(text)}
                  />
                  <Column
                    title="Corretora"
                    dataIndex="corretora"
                    key="corretora"
                    align="left"
                  />
                  <Column
                    title="Custo Trava"
                    dataIndex="lockCost"
                    key="lockCost"
                    align="left"
                    render={(text) => numberMask(text)}
                  />
                  <Column
                    title="% Trava"
                    dataIndex="lockCostPercentage"
                    key="lockCostPercentage"
                    align="left"
                    render={(text) => numberMask(text)}
                  />
                </Table>
              </Row>
              <Row type="flex" justify="end">
                <ButtonStandard
                  buttonType="type6"
                  onClick={() => handleContratacao()}
                  disabled={
                    !(
                      Array.isArray(selectedValuesForPriceLockKeys) &&
                      selectedValuesForPriceLockKeys.length > 0
                    )
                  }
                >
                  Contratar
                </ButtonStandard>
              </Row>
            </>
          ) : null}
        </TabSaleScenarioInfoContent>
        <DrawerPriceLockDetails
          data={lockedPriceInfo}
          isVisible={drawerPriceLockVisible}
          closeDrawer={handleHideDrawerPriceLock}
        />
      </>
    );
  }
);

export default SaleScenarioDetailsPricesLocksTab;
