import React from "react";

import { Container } from "./styles";
import { Col, Row } from "antd";

import FinancialDashboardForm from "./form";
import FinancialDashboardResult from "./result";
import { FinancialTransactionDashboardContextProvider } from "../../../contexts/financialTransactionDashboardContext";

function FinancialDashboard({ visible }) {
  return !visible ? null : (
    <FinancialTransactionDashboardContextProvider>
      <Container>
        <Row type="flex">
          <Col span={24}>
            <FinancialDashboardForm />
          </Col>
        </Row>
        <Row type="flex">
          <Col span={24}>
            <FinancialDashboardResult />
          </Col>
        </Row>
      </Container>
    </FinancialTransactionDashboardContextProvider>
  );
}

export default FinancialDashboard;
