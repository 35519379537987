import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as PastureActions } from "../../../../store/ducks/pasture";
import moment from "moment";
import "moment/locale/pt-br";
// Components
import { Row, Col, Input, Select, Spin, DatePicker } from "antd";
import ButtonStandard from "../../../utils/button";
import NumberFormat from "react-number-format";
import { Container, Footer, CustomDivider } from "./styles";
import DrawerPastureDetails from "../../pastureDetails";

const DrawerPicketSustenanceCapacity = ({
  data,
  onSave,
  onCancel,
  drawerVisible,
  subSystem,
}) => {
  const [showFertilizedOptionYes, setShowFertilizedOptionYes] = useState(false);
  const [showFertilizedOptionNo, setShowFertilizedOptionNo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(null);
  const [errors, setErrors] = useState([]);

  // Redux variables
  const { translation } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const { Option } = Select;

  const dateFormat = "DD/MM/YYYY";

  function handleOpenDrawer(visible) {
    if (visible) {
      setIsLoading(true);
      setErrors([]);
      setForm(data);
      if (data != null && data.pastureId !== null) {
        const pastures = data.pastures.filter((p) => p.id === data.pastureId);
        if (pastures && pastures.length > 0) {
          const [selectedPasture] = pastures;
          const { seasons } = selectedPasture;

          changeYesAndNoFertilizedOption(seasons);
        }
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }

  function handlePastureChange(value) {
    const pastures = form.pastures.filter((p) => p.id === value);
    if (pastures && pastures.length > 0) {
      const [selectedPasture] = pastures;
      const { seasons } = selectedPasture;

      changeYesAndNoFertilizedOption(seasons);
    }

    setForm({
      ...form,
      pastureId: value,
      pastureFertilized: undefined,
    });
    setErrors(errors.filter((e) => e !== "pastureId"));
  }

  function handleShowPastureDetails() {
    const { pastureId: id } = form;
    dispatch(PastureActions.showOrHideDrawerPastureDetails(id));
  }

  function changeYesAndNoFertilizedOption(seasons) {
    const drySeason = seasons.filter(
      (s) =>
        s.season === "Dry" &&
        s.cost != null &&
        s.cp != null &&
        s.tdn != null &&
        s.entryHeight != null &&
        s.exitHeight != null &&
        s.rest != null &&
        s.leafDensityProduction != null &&
        s.growthRate != null &&
        s.maximumConsumption != null &&
        s.minimumConsumption != null &&
        s.cost > 0 &&
        s.cp > 0 &&
        s.tdn > 0 &&
        s.entryHeight > 0 &&
        s.exitHeight > 0 &&
        s.rest > 0 &&
        s.leafDensityProduction > 0 &&
        s.growthRate > 0 &&
        s.maximumConsumption > 0 &&
        s.minimumConsumption > 0
    );
    const waterSeason = seasons.filter(
      (s) =>
        s.season === "Water" &&
        s.cost != null &&
        s.cp != null &&
        s.tdn != null &&
        s.entryHeight != null &&
        s.exitHeight != null &&
        s.rest != null &&
        s.leafDensityProduction != null &&
        s.growthRate != null &&
        s.maximumConsumption != null &&
        s.minimumConsumption != null &&
        s.cost > 0 &&
        s.cp > 0 &&
        s.tdn > 0 &&
        s.entryHeight > 0 &&
        s.exitHeight > 0 &&
        s.rest > 0 &&
        s.leafDensityProduction > 0 &&
        s.growthRate > 0 &&
        s.maximumConsumption > 0 &&
        s.minimumConsumption > 0
    );
    const transitionSeason = seasons.filter(
      (s) =>
        s.season === "Transition" &&
        s.cost != null &&
        s.cp != null &&
        s.tdn != null &&
        s.entryHeight != null &&
        s.exitHeight != null &&
        s.rest != null &&
        s.leafDensityProduction != null &&
        s.growthRate != null &&
        s.maximumConsumption != null &&
        s.minimumConsumption != null &&
        s.cost > 0 &&
        s.cp > 0 &&
        s.tdn > 0 &&
        s.entryHeight > 0 &&
        s.exitHeight > 0 &&
        s.rest > 0 &&
        s.leafDensityProduction > 0 &&
        s.growthRate > 0 &&
        s.maximumConsumption > 0 &&
        s.minimumConsumption > 0
    );

    const canShowFertilizedOptionYes =
      drySeason.filter((s) => s.fertilized === true).length > 0 &&
      waterSeason.filter((s) => s.fertilized === true).length > 0 &&
      transitionSeason.filter((s) => s.fertilized === true).length > 0;

    const canShowFertilizedOptionNo =
      drySeason.filter((s) => s.fertilized === false).length > 0 &&
      waterSeason.filter((s) => s.fertilized === false).length > 0 &&
      transitionSeason.filter((s) => s.fertilized === false).length > 0;

    setShowFertilizedOptionYes(canShowFertilizedOptionYes);

    setShowFertilizedOptionNo(canShowFertilizedOptionNo);
  }

  function onSubmit() {
    let data = form;
    if (validateForm()) {
      data.pastureLeafDensity =
        data.pastureLeafDensity *
        10000; /* O campo pastureLeafDensity está sendo lançado como kg/(cm x m2) e salvo no banco como Kg/(cm x ha)  */
      data.status = "I";
      onSave(data);
    }
  }

  function validateForm() {
    let data = form;
    /* Validate fields */
    let submitErrors = [];
    if (data.pastureAnimalCapacity === null || data.pastureAnimalCapacity === 0 || data.pastureAnimalCapacity === '')
      submitErrors = [...submitErrors, "pastureAnimalCapacity"];
    if (data.pastureArea === null || data.pastureArea === 0 || data.pastureArea === '')
      submitErrors = [...submitErrors, "pastureArea"];
    /* Validate pasture fields if subSystem is Extensive or Semi-Intensive */
    if (subSystem !== "Intensive" && subSystem !== "I") {
      if (data.pastureId == null) submitErrors = [...submitErrors, "pastureId"];
      if (data.pastureFertilized == null || data.pastureFertilized === "")
        submitErrors = [...submitErrors, "pastureFertilized"];
      if (data.pastureCurrentHeight === null || data.pastureCurrentHeight === 0 || data.pastureCurrentHeight === '')
        submitErrors = [...submitErrors, "pastureCurrentHeight"];
      if (data.pastureCurrentHeightDate === null)
        submitErrors = [...submitErrors, "pastureCurrentHeightDate"];
    }
    setErrors(submitErrors);
    return submitErrors.length > 0 ? false : true;
  }

  return (
    <Container
      title={`${subSystem === "Intensive" ? translation.picket.form.sustenanceCapacityDrawerTitleIntensiveSubSystem : translation.picket.form.sustenanceCapacityDrawerTitle}`}
      width={600}
      onClose={onCancel}
      maskClosable={false}
      visible={drawerVisible}
      afterVisibleChange={handleOpenDrawer}
    >
      <Spin spinning={isLoading}>
        {/* Body */}
        <div className="drawerForm">
          <form autoComplete="off">
            {errors.length > 0 && (
              <Row type="flex" justify="center" align="middle">
                <label className="error">{translation.error.formError}</label>
              </Row>
            )}
            <Row type="flex" align="middle">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Row>
                  <label
                    className={
                      errors.includes("pastureAnimalCapacity") ? "error" : ""
                    }
                  >
                    {translation.picket.form.pastureAnimalCapacity}*
                  </label>
                </Row>
                <Row className="rowInput">
                  <NumberFormat
                    customInput={Input}
                    placeholder={translation.defaultPlaceholder}
                    value={form?.pastureAnimalCapacity}
                    name="pastureAnimalCapacity"
                    addonAfter={
                      subSystem === "Intensive" || subSystem === "I"
                        ? "CAB"
                        : "UA/ha"
                    }
                    decimalScale={
                      subSystem === "Intensive" || subSystem === "I"
                        ? 0
                        : 2
                    }
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale={true}
                    onValueChange={({ floatValue }) => {
                      setForm({
                        ...form,
                        pastureAnimalCapacity:
                          floatValue >= 0 ? floatValue : null,
                      });
                      setErrors(
                        errors.filter((e) => e !== "pastureAnimalCapacity")
                      );
                    }}
                  />
                </Row>
              </Col>
              {/* Cost or Pasture Area */}
              {subSystem !== "Intensive" && subSystem !== "I" ? (
                <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}></Col>
              ) : (
                <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
                  <Row>
                    <label
                      className={errors.includes("pastureArea") ? "error" : ""}
                    >
                      {translation.picket.form.pastureArea}*
                    </label>
                  </Row>
                  <Row className="rowInput">
                    <NumberFormat
                      customInput={Input}
                      placeholder={translation.defaultPlaceholder}
                      value={form?.pastureArea}
                      name="pastureArea"
                      addonAfter={
                        <span>
                          m<sup>2</sup>
                        </span>
                      }
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      onValueChange={({ floatValue }) => {
                        setForm({
                          ...form,
                          pastureArea: floatValue >= 0 ? floatValue : null,
                        });
                        setErrors(errors.filter((e) => e !== "pastureArea"));
                      }}
                    />
                  </Row>
                </Col>
              )}
            </Row>
            {subSystem !== "Intensive" && subSystem !== "I" && (
              <>
                <CustomDivider dashed />
                <Row type="flex">
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    style={{
                      fontSize: "14px",
                      color: "#000",
                      marginBottom: 10,
                    }}
                  >
                    <span>Informe os dados da forrageira abaixo</span>
                  </Col>
                </Row>
                {/* PastureId and Fertilized */}
                <Row type="flex" align="middle" className="rowInput">
                  <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                    <Row>
                      <label
                        className={errors.includes("pastureId") ? "error" : ""}
                      >
                        {translation.picket.form.pastureId}*
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <Select
                        name="pastureId"
                        value={
                          form != null &&
                          form.pastures != null &&
                          form.pastureId != null
                            ? form.pastureId
                            : undefined
                        }
                        style={{ width: "100%" }}
                        placeholder={
                          translation.picket.form.pastureIdPlaceHolder
                        }
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          handlePastureChange(value);
                        }}
                      >
                        {form != null &&
                          form.pastures != null &&
                          form.pastures.map(
                            (p) =>
                              p.status === "Active" && (
                                <Option key={p.id} value={p.id}>
                                  {p.name}
                                </Option>
                              )
                          )}
                      </Select>
                    </Row>
                  </Col>
                  <Col xs={7} sm={7} md={7} lg={7} xl={7} offset={1}>
                    <Row>
                      <label
                        className={
                          errors.includes("pastureFertilized") ? "error" : ""
                        }
                      >
                        {translation.picket.form.pastureFertilized}*
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <Select
                        name="pastureFertilized"
                        value={
                          form != null &&
                          form?.pastureId != null &&
                          form?.pastureFertilized != null
                            ? form?.pastureFertilized
                              ? "true"
                              : "false"
                            : undefined
                        }
                        style={{ width: "100%" }}
                        placeholder="Selecione"
                        onChange={(value) => {
                          setForm({
                            ...form,
                            pastureFertilized: value === "false" ? false : true,
                          });
                          setErrors(
                            errors.filter((e) => e !== "pastureFertilized")
                          );
                        }}
                      >
                        {showFertilizedOptionNo && (
                          <Option key="false" value="false">
                            Não
                          </Option>
                        )}
                        {showFertilizedOptionYes && (
                          <Option key="true" value="true">
                            Sim
                          </Option>
                        )}
                      </Select>
                    </Row>
                  </Col>
                </Row>
                {/* PastureArea and PastureLeafDensity */}
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Row>
                      <label
                        className={
                          errors.includes("pastureArea") ? "error" : ""
                        }
                      >
                        {translation.picket.form.pastureArea}*
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <NumberFormat
                        customInput={Input}
                        placeholder={translation.defaultPlaceholder}
                        value={form?.pastureArea}
                        name="pastureArea"
                        addonAfter="ha"
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        onValueChange={({ floatValue }) => {
                          setForm({
                            ...form,
                            pastureArea: floatValue >= 0 ? floatValue : null,
                          });
                          setErrors(errors.filter((e) => e !== "pastureArea"));
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                {/* PastureCurrentHeight and PastureCurrentHeightDate*/}
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Row>
                      <label
                        className={
                          errors.includes("pastureCurrentHeight") ? "error" : ""
                        }
                      >
                        {translation.picket.form.pastureCurrentHeight}*
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <NumberFormat
                        customInput={Input}
                        value={form?.pastureCurrentHeight}
                        placeholder={translation.defaultPlaceholder}
                        addonAfter="cm"
                        name="pastureCurrentHeight"
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        onValueChange={({ floatValue }) => {
                          setForm({
                            ...form,
                            pastureCurrentHeight:
                              floatValue >= 0 ? floatValue : null,
                          });
                          setErrors(
                            errors.filter((e) => e !== "pastureCurrentHeight")
                          );
                        }}
                      />
                    </Row>
                  </Col>
                  <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
                    <Row>
                      <label
                        className={
                          errors.includes("pastureCurrentHeightDate")
                            ? "error"
                            : ""
                        }
                      >
                        {translation.picket.form.pastureCurrentHeightDate}*
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <DatePicker
                        name="pastureCurrentHeightDate"
                        allowClear={false}
                        value={
                          form !== null && form.pastureCurrentHeightDate != null
                            ? moment(
                                form.pastureCurrentHeightDate,
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                        format={dateFormat}
                        onChange={(date, dateString) => {
                          if (date <= moment() && date !== null) {
                            setForm({
                              ...form,
                              pastureCurrentHeightDate: date,
                            });
                          }
                          setErrors(
                            errors.filter(
                              (e) => e !== "pastureCurrentHeightDate"
                            )
                          );
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row
                  type="flex"
                  style={{ marginTop: "13px" }}
                  justify="center"
                  align="middle"
                >
                  <ButtonStandard
                    buttonType="typeWithoutBackground"
                    color="#684E94"
                    type="button"
                    isUpperCase={true}
                    onClick={handleShowPastureDetails}
                    disabled={
                      form === null || form?.pastureId === null ? true : false
                    }
                  >
                    VISUALIZAR INFORMAÇÕES SOBRE A FORRAGEIRA
                  </ButtonStandard>
                </Row>
              </>
            )}
          </form>
        </div>
      </Spin>
      {/* Footer */}
      <Footer>
        <Row type="flex" justify="space-between">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="selectDiv"
          ></Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="buttonsDiv">
            <ButtonStandard type="button" buttonType="type7" onClick={onCancel}>
              {translation.buttons.cancel}
            </ButtonStandard>

            <ButtonStandard type="submit" buttonType="type6" onClick={onSubmit}>
              {translation.buttons.save}
            </ButtonStandard>
          </Col>
        </Row>
      </Footer>
      <DrawerPastureDetails />
    </Container>
  );
};

export default DrawerPicketSustenanceCapacity;
