import React from "react";

import { Container } from "./styles";

const BreadCrumbs = ({ children, marginTop, marginBottom, marginLeft }) => (
  <Container
    marginTop={marginTop}
    marginBottom={marginBottom}
    marginLeft={marginLeft}
  >
    {children}
  </Container>
);

export default BreadCrumbs;
