import { useContext } from "react";
import { HandlingReproductionCoverageContext } from "../../contexts/handlingReproductionCoverageContext";

const useHandlingReproductionCoverageContext = () => {
  const value = useContext(HandlingReproductionCoverageContext);

  return value;
};

export default useHandlingReproductionCoverageContext;
