import apiFarm from "../config/api_farms";
import getHeaders from "./getHeaders";

export function getHarvestByGroupAndFarm(payload) {
  const { groupId, farmId, signal } = payload;
  
  return apiFarm.get(
    `/bovexo/${groupId}/farms/${farmId}/harvests`,
    getHeaders(false, signal)
  );
}

export function getCurrentHarvestByFarmId(payload) {
  const { groupId, farmId, signal } = payload;
  
  return apiFarm.get(
    `/bovexo/${groupId}/farms/${farmId}/harvests/current`,
    getHeaders(false, signal)
  );
}