import { Col, Icon, Row, Spin, Tooltip } from "antd";
import moment from "moment";
import React, { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import BoxText from "../../../../../components/utils/boxText";
import BookmarkIcon from "../../../../../components/utils/icons/bookmark";
import ExclamationIcon from "../../../../../components/utils/icons/exclamation";
import EyeIcon from "../../../../../components/utils/icons/eye";
import InfoTooltip from "../../../../../components/utils/infoTooltip";
import { numberMask } from "../../../../../services/helpersMethodsService";
import { Creators as DietStrategyActions } from "../../../../../store/ducks/dietStrategy";
import { Board, CustomDivider, TabSaleScenarioInfoContent } from "../../styles";

const SaleScenarioDetailsDefaultDataInfoTab = memo(
  ({
    tabActive,
    saleScenarioInfo,
    listOfSaleScenarioResultFarmSell,
    saleScenarioResult,
    saleScenarioDietStrategySuggest,
    isLoadingSaleScenarioCommitments,
    saleScenarioCommitments,
    isLoadingSaleScenarioViolations,
    saleScenarioViolations,
    isLoadingSaleScenarioDietStrategySuggest,
  }) => {
    const {
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    } = useSelector((state) => state.app);

    const dispatch = useDispatch();

    const showDietStrategyDetails = useCallback(
      (id) => {
        dispatch(DietStrategyActions.showDietStrategy(groupId, farmId, id));
      },
      [groupId, farmId, dispatch]
    );

    const diffAnimalsCount = useMemo(
      () =>
        saleScenarioResult != null
          ? saleScenarioResult?.amountAnimals -
            saleScenarioResult?.amountAnimalsSold
          : 0,
      [saleScenarioResult]
    );

    const diffAnimalsPrice = useMemo(
      () =>
        saleScenarioResult != null
          ? saleScenarioResult?.amountAnimalsPrice -
            saleScenarioResult?.amountAnimalsPriceSold
          : 0,
      [saleScenarioResult]
    );

    const diffAnimalsMargin = useMemo(
      () =>
        saleScenarioResult != null
          ? saleScenarioResult?.amountAnimalsMarginSold > 0
            ? saleScenarioResult?.amountAnimalsMargin -
              saleScenarioResult?.amountAnimalsMarginSold
            : saleScenarioResult?.amountAnimalsMargin -
              saleScenarioResult?.amountAnimalsMarginSold * -1
          : 0,
      [saleScenarioResult]
    );
    return (
      <TabSaleScenarioInfoContent active={tabActive === 1 ? true : false}>
        {/* Total period */}
        <Row type="flex" align="middle" justify="center">
          <Col span={24} align="middle">
            <span>
              Período total:
              <strong>{` ${moment(
                saleScenarioInfo?.initialSimulationDate
              ).format("DD/MM/YYYY")} a ${moment(
                saleScenarioInfo?.lastSellDate
              ).format("DD/MM/YYYY")}`}</strong>
            </span>
          </Col>
        </Row>
        {/* Retroactive box */}
        {saleScenarioInfo?.retroactive && (
          <BoxText color="#9074BF" alingCenter>
            <strong>Resultado retroativo</strong>
          </BoxText>
        )}
        {/* Board with resume of sales and sale scenario result */}
        <Row type="flex">
          <Col span={24}>
            <Board>
              {listOfSaleScenarioResultFarmSell != null &&
                listOfSaleScenarioResultFarmSell?.content?.length > 0 &&
                saleScenarioResult?.amountAnimalsSold > 0 && (
                  <>
                    <div className="content purple">
                      <div className="group">
                        <span>Animais vendidos</span>
                        <span>
                          <strong>
                            {saleScenarioResult?.amountAnimalsSold || 0}
                          </strong>
                        </span>
                      </div>
                      <div className="group">
                        <span>Total recebido</span>
                        <span>
                          <strong>{`${numberMask(
                            saleScenarioResult?.amountAnimalsPriceSold || 0,
                            true
                          )}`}</strong>
                        </span>
                      </div>
                      <div className="group">
                        <span>Margem total</span>
                        <span>
                          <strong>{`${numberMask(
                            saleScenarioResult?.amountAnimalsMarginSold || 0,
                            true
                          )}`}</strong>
                        </span>
                      </div>
                    </div>
                    <CustomDivider dashed />
                  </>
                )}
              <div className="content">
                <div className="group">
                  <span>Animais a vender</span>
                  <span>
                    <strong>{saleScenarioResult?.amountAnimals || 0}</strong>
                  </span>
                  {listOfSaleScenarioResultFarmSell != null &&
                    listOfSaleScenarioResultFarmSell?.content?.length > 0 &&
                    saleScenarioResult?.amountAnimalsSold > 0 && (
                      <span className="differenceValue">
                        {diffAnimalsCount}
                        <InfoTooltip
                          placement="bottom"
                          trigger="click"
                          title="Diferença entre Animais a vender e Animais vendidos "
                        />
                      </span>
                    )}
                </div>
                <div className="group">
                  <span>Receita planejada</span>
                  <span>
                    <strong>{`${numberMask(
                      saleScenarioResult?.amountAnimalsPrice || 0,
                      true
                    )}`}</strong>
                  </span>
                  {listOfSaleScenarioResultFarmSell != null &&
                    listOfSaleScenarioResultFarmSell?.content?.length > 0 &&
                    saleScenarioResult?.amountAnimalsSold > 0 && (
                      <span className="differenceValue">
                        {`${numberMask(diffAnimalsPrice || 0, true)} `}
                        <InfoTooltip
                          placement="bottom"
                          trigger="click"
                          title="Diferença entre Receita Planejada e receita recebida"
                        />
                      </span>
                    )}
                </div>
                <div className="group">
                  <span>Margem planejada</span>
                  <span>
                    <strong>{`${numberMask(
                      saleScenarioResult?.amountAnimalsMargin || 0,
                      true
                    )}`}</strong>
                  </span>
                  {listOfSaleScenarioResultFarmSell != null &&
                    listOfSaleScenarioResultFarmSell?.content?.length > 0 &&
                    saleScenarioResult?.amountAnimalsSold > 0 && (
                      <span className="differenceValue">
                        {`${numberMask(diffAnimalsMargin || 0, true)} `}
                        <InfoTooltip
                          placement="bottom"
                          trigger="click"
                          title="Diferença entre Lucro Planejado e Lucro recebido"
                        />
                      </span>
                    )}
                </div>
              </div>
            </Board>
          </Col>
        </Row>
        {/* List lot and dietStretegies suggest */}
        <Spin spinning={isLoadingSaleScenarioDietStrategySuggest}>
          <Row type="flex">
            <Col span={24}>
              <table>
                <thead>
                  <tr>
                    <th>
                      <span className="spanDietStrategy">
                        ESTRAT. DE DIETA SUGERIDAS
                      </span>
                    </th>
                    <th className="alignRight dietStrategy">
                      <Icon type="fund" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {saleScenarioDietStrategySuggest != null &&
                  saleScenarioDietStrategySuggest.length > 0 ? (
                    saleScenarioDietStrategySuggest.map((ds, index) => (
                      <tr key={index}>
                        <td>
                          <span>{ds.lotName}</span>
                        </td>
                        <td className="alignRight">
                          {ds.dietStrategyName.length < 15 ? (
                            <>
                              <strong>{ds.dietStrategyName}</strong>
                              <button
                                onClick={() =>
                                  showDietStrategyDetails(ds.dietStrategyId)
                                }
                              >
                                <EyeIcon isActive />
                              </button>
                            </>
                          ) : (
                            <>
                              <Tooltip title={ds.dietStrategyName}>
                                <strong>{`${ds.dietStrategyName.substring(
                                  0,
                                  15
                                )}...`}</strong>
                              </Tooltip>
                              <button
                                onClick={() =>
                                  showDietStrategyDetails(ds.dietStrategyId)
                                }
                              >
                                <EyeIcon isActive />
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2" className="alignCenter">
                        <span>Nenhuma estratégia de dieta sugerida</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
        </Spin>
        <CustomDivider dashed />
        {/* List commitments */}
        <Spin spinning={isLoadingSaleScenarioCommitments}>
          <Row type="flex">
            <Col span={24}>
              <table>
                <thead>
                  <tr>
                    <th>
                      <span className="spanCommitment">
                        COMPROMISSOS{" "}
                        <InfoTooltip title="Os compromissos de venda, seja em relação à necessidade de venda de animais ou uma receita específica em determinada data, sempre será considerada como uma Restrição 'Forte', sobrepondo as demais restrições, de forma que seja exibido ao usuário, se com os Lotes e Animais selecionados, o compromisso será respeitado." />
                      </span>
                    </th>
                    <th className="alignRight">
                      <BookmarkIcon />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {saleScenarioCommitments != null &&
                  saleScenarioCommitments.length > 0 ? (
                    saleScenarioCommitments.map((ssc, index) => (
                      <tr key={index}>
                        <td>
                          <span>
                            {moment(ssc.commitmentDate).format("DD/MM/YYYY")}
                          </span>
                        </td>
                        <td className="alignRight">
                          <strong>
                            {ssc.saleCommitmentType === "SellValue"
                              ? `${numberMask(ssc.commitmentValue || 0, true)}`
                              : `${ssc.commitmentValue} animais`}
                          </strong>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2" className="alignCenter">
                        <span>Nenhum compromisso</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
        </Spin>
        <CustomDivider dashed />
        {/* List violations */}
        <Spin spinning={isLoadingSaleScenarioViolations}>
          <Row type="flex">
            <Col span={24}>
              <table>
                <thead>
                  <tr>
                    <th>
                      <span className="spanViolation">VIOLAÇÕES</span>
                    </th>
                    <th className="alignRight">
                      <ExclamationIcon />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {saleScenarioViolations != null &&
                  Object.entries(saleScenarioViolations).length > 0 ? (
                    <>
                      {saleScenarioViolations.hasOwnProperty(
                        "NoCommonSaleDate"
                      ) && (
                        <tr>
                          <td>
                            <span>Data prevista de venda</span>
                            <br />
                            <span className="violationValue">
                              <strong>{`${saleScenarioViolations?.NoCommonSaleDate} animais`}</strong>
                            </span>
                          </td>
                        </tr>
                      )}
                      {saleScenarioViolations.hasOwnProperty("MaxWeight") && (
                        <tr>
                          <td>
                            <span>Peso máximo para venda</span>
                            <br />
                            <span className="violationValue">
                              <strong>{`${saleScenarioViolations?.MaxWeight} animais`}</strong>
                            </span>
                          </td>
                        </tr>
                      )}
                      {saleScenarioViolations.hasOwnProperty("MinWeight") && (
                        <tr>
                          <td>
                            <span>Peso mínimo para venda</span>
                            <br />
                            <span className="violationValue">
                              <strong>{`${saleScenarioViolations?.MinWeight} animais`}</strong>
                            </span>
                          </td>
                        </tr>
                      )}
                      {saleScenarioViolations.hasOwnProperty("Weight") && (
                        <tr>
                          <td>
                            <span>Peso mínimo para venda</span>
                            <br />
                            <span className="violationValue">
                              <strong>{`${saleScenarioViolations?.Weight} animais`}</strong>
                            </span>
                          </td>
                        </tr>
                      )}
                      {saleScenarioViolations.hasOwnProperty("MaxAge") && (
                        <tr>
                          <td>
                            <span>Idade máxima para venda</span>
                            <br />
                            <span className="violationValue">
                              <strong>{`${saleScenarioViolations?.MaxAge} animais`}</strong>
                            </span>
                          </td>
                        </tr>
                      )}
                      {saleScenarioViolations.hasOwnProperty("MinAge") && (
                        <tr>
                          <td>
                            <span>Idade mínima para venda</span>
                            <br />
                            <span className="violationValue">
                              <strong>{`${saleScenarioViolations?.MinAge} animais`}</strong>
                            </span>
                          </td>
                        </tr>
                      )}
                      {saleScenarioViolations.hasOwnProperty("Age") && (
                        <tr>
                          <td>
                            <span>Idade mínima para venda</span>
                            <br />
                            <span className="violationValue">
                              <strong>{`${saleScenarioViolations?.Age} animais`}</strong>
                            </span>
                          </td>
                        </tr>
                      )}
                      {saleScenarioViolations.hasOwnProperty("Pasture") && (
                        <tr>
                          <td>
                            <span>Capacidade de suporte - Pasto</span>
                            <br />
                            <span className="violationValue">
                              <strong>{`${saleScenarioViolations?.Pasture} dias`}</strong>
                            </span>
                          </td>
                        </tr>
                      )}
                      {saleScenarioViolations.hasOwnProperty("Trough") && (
                        <tr>
                          <td>
                            <span>
                              Capacidade de suporte - Suplemento/Concent.
                            </span>
                            <br />
                            <span className="violationValue">
                              <strong>{`${saleScenarioViolations.Trough} dias`}</strong>
                            </span>
                          </td>
                        </tr>
                      )}
                      {saleScenarioViolations.hasOwnProperty(
                        "SisbovReleaseDate"
                      ) && (
                        <tr>
                          <td>
                            <span>Controle de Liberação SISBOV</span>
                            <br />
                            <span className="violationValue">
                              <strong>{`${saleScenarioViolations?.SisbovReleaseDate} animais`}</strong>
                            </span>
                          </td>
                        </tr>
                      )}
                      {saleScenarioViolations.hasOwnProperty(
                        "SisbovTracking"
                      ) && (
                        <tr>
                          <td>
                            <span>Idade limite na Cota selecionada</span>
                            <br />
                            <span className="violationValue">
                              <strong>{`${saleScenarioViolations?.SisbovTracking} animais`}</strong>
                            </span>
                          </td>
                        </tr>
                      )}
                      {saleScenarioViolations.hasOwnProperty("Sex") && (
                        <tr>
                          <td>
                            <span>Sexo do animal na Cota selecionada</span>
                            <br />
                            <span className="violationValue">
                              <strong>{`${saleScenarioViolations?.Sex} animais`}</strong>
                            </span>
                          </td>
                        </tr>
                      )}
                      {saleScenarioViolations.hasOwnProperty("Castration") && (
                        <tr>
                          <td>
                            <span>Animal cadastrado - SISBOV</span>
                            <br />
                            <span className="violationValue">
                              <strong>{`${saleScenarioViolations?.Castration} animais`}</strong>
                            </span>
                          </td>
                        </tr>
                      )}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="2" className="alignCenter">
                        <span>Nenhuma violação</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
        </Spin>
      </TabSaleScenarioInfoContent>
    );
  }
);

export default SaleScenarioDetailsDefaultDataInfoTab;
