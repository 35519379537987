import React, { createContext, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFinancialReducer } from "../../hooks/useFinancialReducer";
import { generateFinancialCashFlow } from "../../services/financialCashFlowService";
import { generateFinancialCosting } from "../../services/financialCostingService";
import { findAllFinancialParametersByGroupIdAndFarmId } from "../../services/financialParameterService";
import { getFinancialTransactions } from "../../services/financialTransactionsService";
import { findAllFarmSells } from "../../services/farmSellService";
import SettleFinancialTransactionModal from "../../components/modals/settleFinancialTransactionModal";
import PartialPaymentFinancialTransactionModal from "../../components/modals/partialPaymentFinancialTransactionModal";
import { useCurrencyContext } from "../../hooks/useCurrencyContext";

export const FinancialContext = createContext(null);

const NATURE_ID_NOT_VISIBLE = ["78346782-621f-4ae9-87ab-6f8b33204c1b"];
const CLASSES_ID_NOT_VISIBLE = [
  "e223f767-15b8-4bfb-8387-d2003ec339ff",
  "403f06b5-6abc-49f9-8396-832592d4a463",
];

export const FinancialProvider = ({ children }) => {
  const [
    selectedFinancialTransactionElements,
    setSelectedFinancialTransactionElements,
  ] = useState([]);

  const [
    selectedFinancialTransactionKeys,
    setSelectedFinancialTransactionKeys,
  ] = useState([]);

  const [
    shouldShowModalSettledFinancialTransaction,
    setShouldShowModalSettledFinancialTransaction,
  ] = useState(false);

  const [
    shouldShowModalPartialPaymentFinancialTransaction,
    setShouldShowModalPartialPaymentFinancialTransaction,
  ] = useState(false);

  const [financialTransactionData, setFinancialTransactionData] =
    useState(null);

  const [financialTransactionDataEdit, setFinancialTransactionDataEdit] =
    useState(null);

  const [
    modalSettledFinancialTransactionAction,
    setModalSettledFinancialTransactionAction,
  ] = useState("new");

  const { defaultCurrencyCode } = useCurrencyContext();

  const {
    state: {
      listFinancialParameters,
      isLoadingListFinancialParameters,
      listFinancialCashFlow,
      isLoadingListFinancialCashFlow,
      listFinancialCosting,
      isLoadingListFinancialCosting,
      shouldShowDrawerFinancialCashFlowBudget,
      isLoadingListFinancialTransaction,
      listFinancialTransaction,
      shouldShowFormFinancialTransaction,
      listFarmSells,
      isLoadingFarmSells,
    },
    dispatch: financialDispatch,
  } = useFinancialReducer();

  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  // Methods
  const openOrCloseDrawerFinancialCashFlowBudget = useCallback(() => {
    financialDispatch({
      type: "SHOW_OR_HIDE_DRAWER_FINANCIAL_CASH_FLOW_BUDGET",
    });
  }, [financialDispatch]);

  const refreshListFinancialParameters = useCallback(async () => {
    try {
      financialDispatch({
        type: "START_STOP_LOADING_LIST_FINANCIAL_PARAMETERS",
      });
      const {
        data: { results },
      } = await findAllFinancialParametersByGroupIdAndFarmId({
        groupId,
        farmId,
      });
      financialDispatch({
        type: "UPDATE_LIST_FINANCIAL_PARAMETERS",
        payload: {
          data: results,
        },
      });
    } catch (error) {
    } finally {
      financialDispatch({
        type: "START_STOP_LOADING_LIST_FINANCIAL_PARAMETERS",
      });
    }
  }, [farmId, financialDispatch, groupId]);

  const refreshListFinancialCashFlow = useCallback(
    async (financialProjectId = null) => {
      if (defaultCurrencyCode) {
        try {
          financialDispatch({
            type: "START_STOP_LOADING_LIST_FINANCIAL_CASH_FLOW",
          });
          const {
            data: { results },
          } = await generateFinancialCashFlow({
            groupId,
            farmId,
            financialProjectId,
            currency: defaultCurrencyCode ?? "BRL",
          });
          financialDispatch({
            type: "UPDATE_LIST_FINANCIAL_CASH_FLOW",
            payload: {
              data: results,
            },
          });
        } catch (error) {
        } finally {
          financialDispatch({
            type: "START_STOP_LOADING_LIST_FINANCIAL_CASH_FLOW",
          });
        }
      }
    },
    [farmId, financialDispatch, groupId, defaultCurrencyCode]
  );

  const refreshListFinancialCosting = useCallback(
    async (financialProjectId = null) => {
      if (defaultCurrencyCode) {
        try {
          financialDispatch({
            type: "START_STOP_LOADING_LIST_FINANCIAL_COSTING",
          });
          const {
            data: { results },
          } = await generateFinancialCosting({
            groupId,
            farmId,
            financialProjectId,
            currency: defaultCurrencyCode ?? "BRL",
          });
          financialDispatch({
            type: "UPDATE_LIST_FINANCIAL_COSTING",
            payload: {
              data: results,
            },
          });
        } catch (error) {
        } finally {
          financialDispatch({
            type: "START_STOP_LOADING_LIST_FINANCIAL_COSTING",
          });
        }
      }
    },
    [farmId, financialDispatch, groupId, defaultCurrencyCode]
  );

  const refreshListFinancialTransaction = useCallback(async () => {
    try {
      financialDispatch({
        type: "START_STOP_LOADING_LIST_FINANCIAL_TRANSACTIONS",
      });
      const {
        data: { results },
      } = await getFinancialTransactions({
        groupId,
        farmId,
      });
      financialDispatch({
        type: "UPDATE_LIST_FINANCIAL_TRANSACTIONS",
        payload: {
          data: results,
        },
      });
    } catch (error) {
    } finally {
      financialDispatch({
        type: "START_STOP_LOADING_LIST_FINANCIAL_TRANSACTIONS",
      });
    }
  }, [farmId, financialDispatch, groupId]);

  const openOrCloseDrawerFinancialTransactionForm = useCallback(() => {
    financialDispatch({
      type: "SHOW_OR_HIDE_FORM_FINANCIAL_TRANSACTION",
    });
  }, [financialDispatch]);

  const refreshListFarmSells = useCallback(async () => {
    try {
      financialDispatch({
        type: "START_STOP_LOADING_LIST_FARM_SELLS",
      });
      const {
        data: { results },
      } = await findAllFarmSells({
        groupId,
        farmId,
      });
      financialDispatch({
        type: "UPDATE_LIST_FARM_SELLS",
        payload: {
          data: results,
        },
      });
    } catch (error) {
    } finally {
      financialDispatch({
        type: "START_STOP_LOADING_LIST_FARM_SELLS",
      });
    }
  }, [farmId, financialDispatch, groupId]);

  const handleSelectFinancialTransaction = useCallback((records) => {
    const keys = records.map((record) => record.id);
    setSelectedFinancialTransactionKeys(keys);
    setSelectedFinancialTransactionElements(records);
  }, []);

  const handleOpenModalSettled = useCallback((action = "new") => {
    setShouldShowModalSettledFinancialTransaction(true);
    setModalSettledFinancialTransactionAction(action);
  }, []);

  const handleCloseModalSettled = useCallback(() => {
    setSelectedFinancialTransactionKeys([]);
    setSelectedFinancialTransactionElements([]);
    setShouldShowModalSettledFinancialTransaction(false);
    setModalSettledFinancialTransactionAction("new");
  }, []);

  const handleOpenModalPartialPayment = useCallback((financialTransaction) => {
    setFinancialTransactionData(financialTransaction);
    setShouldShowModalPartialPaymentFinancialTransaction(true);
  }, []);

  const handleCloseModalPartialPayment = useCallback(() => {
    setFinancialTransactionData(null);
    setShouldShowModalPartialPaymentFinancialTransaction(false);
  }, []);

  const handleSetFinancialTransactionDataToEdit = useCallback((data) => {
    setFinancialTransactionDataEdit(data);
  }, []);

  // Effects

  // LOAD PARAMETERS
  useEffect(() => {
    async function fetch() {
      try {
        financialDispatch({
          type: "START_STOP_LOADING_LIST_FINANCIAL_PARAMETERS",
        });
        const {
          data: { results },
        } = await findAllFinancialParametersByGroupIdAndFarmId({
          groupId,
          farmId,
        });
        financialDispatch({
          type: "UPDATE_LIST_FINANCIAL_PARAMETERS",
          payload: {
            data: results,
          },
        });
      } catch (error) {
      } finally {
        financialDispatch({
          type: "START_STOP_LOADING_LIST_FINANCIAL_PARAMETERS",
        });
      }
    }
    fetch();

    return () => {
      financialDispatch({
        type: "UPDATE_LIST_FINANCIAL_PARAMETERS",
        payload: {
          data: [],
        },
      });
    };
  }, [farmId, financialDispatch, groupId]);

  // LOAD CASH FLOW
  useEffect(() => {
    async function fetch() {
      try {
        financialDispatch({
          type: "START_STOP_LOADING_LIST_FINANCIAL_CASH_FLOW",
        });
        const {
          data: { results },
        } = await generateFinancialCashFlow({
          groupId,
          farmId,
          currency: defaultCurrencyCode ?? "BRL",
        });
        financialDispatch({
          type: "UPDATE_LIST_FINANCIAL_CASH_FLOW",
          payload: {
            data: results,
          },
        });
      } catch (error) {
      } finally {
        financialDispatch({
          type: "START_STOP_LOADING_LIST_FINANCIAL_CASH_FLOW",
        });
      }
    }

    if (farmId && groupId && defaultCurrencyCode) fetch();

    return () => {
      financialDispatch({
        type: "UPDATE_LIST_FINANCIAL_CASH_FLOW",
        payload: {
          data: [],
        },
      });
    };
  }, [farmId, financialDispatch, groupId, defaultCurrencyCode]);

  // LOAD COSTING
  useEffect(() => {
    async function fetch() {
      try {
        financialDispatch({
          type: "START_STOP_LOADING_LIST_FINANCIAL_COSTING",
        });
        const {
          data: { results },
        } = await generateFinancialCosting({
          groupId,
          farmId,
          currency: defaultCurrencyCode ?? "BRL",
        });
        const resultsFiltered = {
          ...results,
          valuesGroupByNature: results.valuesGroupByNature.filter(
            (n) => !NATURE_ID_NOT_VISIBLE.includes(n.id)
          ),
          valuesGroupByClass: results.valuesGroupByClass.filter(
            (n) => !CLASSES_ID_NOT_VISIBLE.includes(n.id)
          ),
        };
        financialDispatch({
          type: "UPDATE_LIST_FINANCIAL_COSTING",
          payload: {
            data: resultsFiltered,
          },
        });
      } catch (error) {
      } finally {
        financialDispatch({
          type: "START_STOP_LOADING_LIST_FINANCIAL_COSTING",
        });
      }
    }
    if (farmId && groupId && defaultCurrencyCode) fetch();

    return () => {
      financialDispatch({
        type: "UPDATE_LIST_FINANCIAL_COSTING",
        payload: {
          data: [],
        },
      });
    };
  }, [farmId, financialDispatch, groupId, defaultCurrencyCode]);

  // LOAD TRANSACTIONS
  useEffect(() => {
    async function fetch() {
      try {
        financialDispatch({
          type: "START_STOP_LOADING_LIST_FINANCIAL_TRANSACTIONS",
        });
        const {
          data: { results },
        } = await getFinancialTransactions({
          groupId,
          farmId,
        });
        financialDispatch({
          type: "UPDATE_LIST_FINANCIAL_TRANSACTIONS",
          payload: {
            data: results,
          },
        });
      } catch (error) {
      } finally {
        financialDispatch({
          type: "START_STOP_LOADING_LIST_FINANCIAL_TRANSACTIONS",
        });
      }
    }
    fetch();

    return () => {
      financialDispatch({
        type: "UPDATE_LIST_FINANCIAL_TRANSACTIONS",
        payload: {
          data: [],
        },
      });
    };
  }, [farmId, financialDispatch, groupId]);

  // LOAD FARM SELLS
  useEffect(() => {
    async function fetch() {
      try {
        financialDispatch({
          type: "START_STOP_LOADING_LIST_FARM_SELLS",
        });
        const {
          data: { results },
        } = await findAllFarmSells({
          groupId,
          farmId,
        });
        financialDispatch({
          type: "UPDATE_LIST_FARM_SELLS",
          payload: {
            data: results,
          },
        });
      } catch (error) {
      } finally {
        financialDispatch({
          type: "START_STOP_LOADING_LIST_FARM_SELLS",
        });
      }
    }
    fetch();

    return () => {
      financialDispatch({
        type: "UPDATE_LIST_FARM_SELLS",
        payload: {
          data: [],
        },
      });
    };
  }, [farmId, financialDispatch, groupId]);

  return (
    <FinancialContext.Provider
      value={{
        listFinancialParameters,
        isLoadingListFinancialParameters,
        refreshListFinancialParameters,
        listFinancialCashFlow,
        isLoadingListFinancialCashFlow,
        refreshListFinancialCashFlow,
        listFinancialCosting,
        isLoadingListFinancialCosting,
        refreshListFinancialCosting,
        shouldShowDrawerFinancialCashFlowBudget,
        openOrCloseDrawerFinancialCashFlowBudget,
        isLoadingListFinancialTransaction,
        listFinancialTransaction,
        shouldShowFormFinancialTransaction,
        refreshListFinancialTransaction,
        openOrCloseDrawerFinancialTransactionForm,
        listFarmSells,
        isLoadingFarmSells,
        refreshListFarmSells,
        selectedFinancialTransactionElements,
        selectedFinancialTransactionKeys,
        handleSelectFinancialTransaction,
        shouldShowModalSettledFinancialTransaction,
        modalSettledFinancialTransactionAction,
        handleOpenModalSettled,
        handleCloseModalSettled,
        shouldShowModalPartialPaymentFinancialTransaction,
        financialTransactionData,
        handleOpenModalPartialPayment,
        handleCloseModalPartialPayment,
        financialTransactionDataEdit,
        handleSetFinancialTransactionDataToEdit,
      }}
    >
      {children}
      <SettleFinancialTransactionModal />
      <PartialPaymentFinancialTransactionModal />
    </FinancialContext.Provider>
  );
};
