import React from "react";
import { useSelector } from "react-redux";
import useAnimalWeightHistoryContext from "../../hooks/useAnimalWeightHistoryContext";
import { AnimalWeightHistoryContextProvider } from "../../contexts/animalWeightHistoryContext";

import { CardCustom, Title } from "./styles";
import { Col, Icon, Row, Switch } from "antd";
import ButtonStandard from "../../components/utils/button";

import DrawerNewAnimalWeightHistory from "../../components/drawers/animalWeightHistory";
import TableAnimalWeightHistory from "./table";
import ReportAnimalWeightHistoryModal from "../../components/modals/reportAnimalWeightHistory";

const AnimalWeightHistoryLayout = () => {
  const { translation } = useSelector((state) => state.app);

  const {
    tableProperties: { size, page, search },
    initializeData,
    fetchData,
    openOrCloseForm,
    openOrCloseModalExport,
    shouldShowWeightsOnlyForActiveAnimals,
    setShouldShowWeightsOnlyForActiveAnimals,
  } = useAnimalWeightHistoryContext();

  const handleCreateNewRecord = () => {
    initializeData(null);
    openOrCloseForm();
  };
  const handleExport = () => {
    openOrCloseModalExport();
  };

  const handleRefresh = () => {
    fetchData(page, size, {}, search);
  };

  return (
    <CardCustom>
      <Row className="rowHeader" justify="space-between">
        <Col span={8}>
          <Title>{translation.animalWeightHistory.title}</Title>
        </Col>
        <Col span={8}>
          <strong>Buscar pesagens somente de animais Ativos </strong>
          <Switch
            checked={shouldShowWeightsOnlyForActiveAnimals}
            onChange={(checked) =>
              setShouldShowWeightsOnlyForActiveAnimals(checked)
            }
          ></Switch>
        </Col>
        <Col span={8}>
          <Row type="flex" justify="end" gutter={8}>
            <Col>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={handleRefresh}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </Col>
            <Col>
              <ButtonStandard
                name="buttonCreateBudget"
                buttonType="alternative"
                onClick={handleExport}
              >
                {translation.animalWeightHistory.buttonExport}
              </ButtonStandard>
            </Col>
            <Col>
              <ButtonStandard
                name="buttonCreateBudget"
                buttonType="type8"
                onClick={handleCreateNewRecord}
              >
                {translation.animalWeightHistory.buttonNew}
              </ButtonStandard>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <TableAnimalWeightHistory />
        </Col>
      </Row>
    </CardCustom>
  );
};

const AnimalWeightHistory = () => {
  return (
    <AnimalWeightHistoryContextProvider>
      <AnimalWeightHistoryLayout />
      <DrawerNewAnimalWeightHistory />
      <ReportAnimalWeightHistoryModal />
    </AnimalWeightHistoryContextProvider>
  );
};

export default AnimalWeightHistory;
