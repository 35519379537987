import React from "react";
import SpinBovexo from "../icons/spinBovexo";
import { Container } from "./styles";

const Loading = () => (
  <Container>
    <SpinBovexo />
  </Container>
);

export default Loading;
