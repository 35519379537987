import styled from "styled-components";

export const Container = styled.div`
  padding: 5px 5px;
`;

export const ColSelectLots = styled.div`
  height: 540px;
  /* min-height: 562px; */
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  background: #fff;
  box-shadow: 0px 0px 10px #d8d8d8;
  overflow-y: auto;

  .ant-table-pagination.ant-pagination {
    margin-right: 10px;
  }
  .header {
    width: 100%;
    height: 60px;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;

    span.listAnimals {
      font-family: Asap;
      font-weight: bold;
      font-size: 18px;
      text-align: left;
      color: #4b4b4b;
    }
    .ant-radio-button-wrapper {
      color: #684e94 !important;
      background: #fff;
      border-color: #684e94 !important;
      box-shadow: -1px 0 0 0 #684e94 !important;
      font-size: 14px;
      font-weight: bold;
    }
    .ant-radio-button-wrapper-checked {
      color: #fff !important;
      background: #684e94;
      border-color: #684e94 !important;
      box-shadow: -1px 0 0 0 #684e94 !important;
      font-size: 14px;
      font-weight: bold;
    }
  }
  @media screen and (max-height: 700px) {
    height: 350px;
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const ColButtons = styled.div`
  height: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    width: 40px;
    height: 40px;
    background: #c4c4c4;
    border: none;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:first-of-type {
      margin-bottom: 16px;
    }
    &:hover {
      cursor: pointer;
    }
    &:disabled {
      cursor: not-allowed;
    }
    &.active {
      background: #684e94;
    }
    i {
      color: #fff !important;
      font-weight: bold;
    }
  }
  @media screen and (max-height: 700px) {
    height: 350px;
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const ColLotsSelected = styled.div`
  height: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  display: flex;
  border-radius: 7px;
  background: #fff;
  box-shadow: 0px 0px 10px #d8d8d8;

  .header {
    width: 100%;
    height: 40px;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    background: #FE8D2A;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-bottom: 2px solid #C4C4C4;

    .ant-checkbox .ant-checkbox-inner {
      background: transparent;
    }

    span {
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      font-family: 'Asap', sans-serif;

    }
  }
  @media screen and (max-height: 700px) {
      height: 350px;
    }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;
