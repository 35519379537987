import React, { Component } from "react";
import Axios from "axios";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import "moment/locale/pt-br";
import "moment/locale/en-nz";
import "moment/locale/es";

/* Redux libs */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as SupplierActions } from "../../store/ducks/supplier";

/** Styles Components */
import { Title, CardCustom } from "./styles";

/** Components */
import { Row, Col, Table, Dropdown, Menu, Icon, Input, Button } from "antd";
import TagStatus from "../../components/utils/tagStatus";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import MenuIcon from "../../components/utils/table/icons/menu";
import DrawerSupplier from "../../components/drawers/supplier";
import DrawerSupplierDetails from "../../components/drawers/supplier/details";
import ButtonStandard from "../../components/utils/button";
import { getOnlyNumber, phoneMask } from "../../services/helpersMethodsService";

class Supplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      success: false,
    };
  }

  static propTypes = {
    supplierActions: PropTypes.any.isRequired,
    app: PropTypes.any.isRequired,
    supplier: PropTypes.any.isRequired,
  };

  signal = Axios.CancelToken.source();

  componentWillUnmount() {
    this.signal.cancel("Cancelando apis");
  }

  async componentDidMount() {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;
    if (
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      this.fetchData(groupSelected, farmSelected);
    }
  }

  async shouldComponentUpdate(nextProps) {
    const differentGroup =
      this.props.app.groupSelected?.id !== nextProps.app.groupSelected?.id;
    const differentFarm =
      this.props.app.farmSelected?.id !== nextProps.app.farmSelected?.id;
    if (differentGroup || differentFarm) {
      if (
        Object.entries(nextProps.app.groupSelected).length > 0 &&
        Object.entries(nextProps.app.farmSelected).length > 0
      ) {
        this.fetchData(nextProps.app.groupSelected, nextProps.app.farmSelected);
      }
    }
    return true;
  }

  fetchData = (
    groupSelected,
    farmSelected,
    page = 0,
    sorter = null,
    filters = null,
    size = 10
  ) => {
    const { supplierActions } = this.props;
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;

    let search = "";

    if (filters && Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v && v.length > 0) {
          const fieldS = k
            ? k === "name"
              ? "name"
              : k === "phoneNumber"
              ? "phone_number"
              : k
            : null;

          if ("name" === fieldS || "email" === fieldS) {
            search =
              search === ""
                ? `upper(${fieldS}) like upper('%25${v}%25')`
                : `${search};upper(${fieldS}) like upper('%25${v}%25')`;
          } else if ("status" === fieldS) {
            search =
              search === ""
                ? `farm_status = '${v}'`
                : `${search};farm_status = '${v}'`;
          } else if ("phone_number" === fieldS) {
            const phoneNumberOnlyNumbers = new String(v).replaceAll(
              /[^0-9]/g,
              ""
            );
            search =
              search === ""
                ? `upper(${fieldS}) like upper('%25${phoneNumberOnlyNumbers}%25')`
                : `${search};upper(${fieldS}) like upper('%25${phoneNumberOnlyNumbers}%25')`;
          } else if ("city" === fieldS) {
            search =
              search === ""
                ? `((upper(\"city_name\") like upper('%25${v}%25')) or (upper(\"state_name\") like upper('%25${v}%25')))`
                : `${search};((upper(\"city_name\") like upper('%25${v}%25')) or (upper(\"state_name\") like upper('%25${v}%25')))`;
          } else {
            search =
              search === ""
                ? `${fieldS} = '${v}'`
                : `${search};${fieldS} = '${v}'`;
          }
        }
      });
    }

    const sort =
      sorter != null && sorter?.field != null
        ? {
            ...sorter,
            field:
              sorter.field === "city"
                ? "city_name"
                : sorter.field === "phoneNumber"
                ? "phone_number"
                : sorter.field,
          }
        : null;

    supplierActions.indexSuppliers(
      groupId,
      farmId,
      null,
      false,
      page,
      sort,
      search,
      size
    );
  };

  handleEdit = async (id) => {
    const { supplierActions } = this.props;
    supplierActions.showDrawer("edit", id);
  };

  handleDetails = async (id) => {
    const { supplierActions } = this.props;
    supplierActions.showDrawer("details", id);
  };

  handleStatusChange = async (supplier) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      supplierActions,
    } = this.props;

    let bodyUpdate = { ...supplier };

    if (bodyUpdate.status === "Active") {
      bodyUpdate.status = "Inactive";
    } else {
      bodyUpdate.status = "Active";
    }

    bodyUpdate.country = bodyUpdate?.city?.state?.country?.name;
    bodyUpdate.state = bodyUpdate?.city?.state?.name;
    bodyUpdate.city = bodyUpdate?.city?.name;

    let saveOptions = false;
    let id = bodyUpdate.id;
    supplierActions.saveSupplier(groupId, farmId, id, bodyUpdate, saveOptions);
  };

  handleDelete = async (id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      supplierActions,
    } = this.props;

    supplierActions.deleteSupplier(groupId, farmId, id);
  };

  handleReactivate = (id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      supplierActions,
    } = this.props;

    supplierActions.reactivateSupplier(groupId, farmId, id);
  };

  handleTableChange = async (pagination, filters, sorter) => {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;

    let search = null;
    if (filters && Object.entries(filters).length > 0) {
      search = filters;
    }

    this.fetchData(
      groupSelected,
      farmSelected,
      pagination.current,
      sorter,
      search,
      pagination.pageSize
    );
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          value={selectedKeys && selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
            background: "#684e94",
            borderColor: "none",
            border: "none",
          }}
        />
        <Button
          onClick={() => this.handleReset(clearFilters)}
          icon="delete"
          size="small"
          style={{ width: 90 }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  handleReset = (clearFilters) => {
    clearFilters();
  };

  render() {
    const {
      app: { translation, groupSelected, farmSelected },
      supplier: {
        data,
        isLoading,
        pagination: { page, size, tableFilters },
      },
      supplierActions,
    } = this.props;

    const menu = (id, record) => {
      const {
        app: { translation },
      } = this.props;
      return (
        <Menu>
          <Menu.Item key="0" onClick={() => this.handleDetails(id)}>
            {translation.table.menu.details}
          </Menu.Item>
          {/* Menu Edit */}
          {record.status === "Active" && (
            <Menu.Item key="1" onClick={() => this.handleEdit(id)}>
              {translation.table.menu.edit}
            </Menu.Item>
          )}
          {/* Menu Delete(Excluir) */}
          {(record.status === "FarmBovexo" ||
            (record.status === "Active" &&
              !record.beingUsed &&
              record.farmId !== null)) && (
            <Menu.Item key="2" onClick={() => this.handleDelete(record.id)}>
              {translation.table.menu.inactivate}
            </Menu.Item>
          )}
          {/* Menu Inactivate */}
          {(record.status === "Active" && record.beingUsed) ||
            (record.status === "Active" &&
              !record.beingUsed &&
              record.farmId === null && (
                <Menu.Item key="3" onClick={() => this.handleDelete(record.id)}>
                  {translation.table.menu.inactivate}
                </Menu.Item>
              ))}
          {/* Menu Activate */}
          {(record.status === "Inactive" ||
            record.status === "FarmBovexoInactive") && (
            <Menu.Item key="4" onClick={() => this.handleReactivate(record.id)}>
              {translation.table.menu.activate}
            </Menu.Item>
          )}
        </Menu>
      );
    };
    const { Column } = Table;

    return (
      <CardCustom>
        <Row type="flex" justify="space-between" className="rowHeader">
          <Col>
            <Title>{translation.supplier.table.title}</Title>
          </Col>
          <Col>
            <ButtonStandard
              buttonType="typeWithoutBackground"
              onClick={() => this.fetchData(groupSelected, farmSelected)}
            >
              <Icon type="reload" />
            </ButtonStandard>
            <ButtonStandard
              buttonType="type8"
              onClick={() => supplierActions.showDrawer("new")}
            >
              {translation.buttons.buttonNewSupplier}
            </ButtonStandard>
          </Col>
        </Row>
        <Row type="flex">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              loading={isLoading}
              rowKey="id"
              dataSource={data != null ? data.content : []}
              pagination={{
                total: data != null ? data.totalElements : 0,
                size: data != null ? size : 0,
                current: data != null ? page : 0,
                hideOnSinglePage:
                  data !== null && Object.entries(data).length !== 0
                    ? data.totalElements > 10
                      ? false
                      : true
                    : true,
                showSizeChanger: true,
                pageSizeOptions: [
                  "10",
                  "20",
                  "30",
                  "100",
                  "500",
                  "1000",
                  "2000",
                ],
              }}
              size="small"
              scroll={{
                x: true,
              }}
              onChange={this.handleTableChange}
            >
              <Column
                title={translation.supplier.table.columns.status}
                dataIndex="status"
                key="status"
                align="left"
                sorter
                filtered
                filters={[
                  {
                    text: translation.status.active,
                    value: "A",
                  },
                  {
                    text: translation.status.inactive,
                    value: "I",
                  },
                ]}
                filterMultiple={false}
                render={(status) =>
                  status === "Active" ? (
                    <TagStatus
                      background="#E2FFE5"
                      borderColor="#33C162"
                      color="#33C162"
                    >
                      Ativo
                    </TagStatus>
                  ) : status === "Inactive" ? (
                    <TagStatus
                      background="#FBC7C7"
                      borderColor="#D44C4C"
                      color="#D44C4C"
                    >
                      Inativo
                    </TagStatus>
                  ) : status === "FarmBovexoInactive" ? (
                    <TagStatus
                      background="#FBC7C7"
                      borderColor="#D44C4C"
                      color="#D44C4C"
                    >
                      Bovexo
                    </TagStatus>
                  ) : (
                    <TagStatus
                      background="#F6FFC8"
                      borderColor="#A9C133"
                      color="#A9C133"
                    >
                      Bovexo
                    </TagStatus>
                  )
                }
              />
              <Column
                title={translation.supplier.table.columns.name}
                dataIndex="name"
                sorter
                key="name"
                filteredValue={tableFilters?.name || null}
                align="left"
                {...this.getColumnSearchProps("name")}
              />
              <Column
                title={translation.supplier.table.columns.type}
                dataIndex="type"
                key="type"
                align="left"
                filtered
                filters={[
                  {
                    text: translation.supplier.types.supplement,
                    value: "Supplement",
                  },
                  {
                    text: translation.supplier.types.technology,
                    value: "Technology",
                  },
                  {
                    text: translation.supplier.types.cattleFarmer,
                    value: "CattleFarmer",
                  },
                  {
                    text: translation.supplier.types.veterinarySupplement,
                    value: "VeterinarySupplement",
                  },
                  {
                    text: translation.supplier.types.others,
                    value: "Others",
                  },
                ]}
                filterMultiple={false}
                render={(text, record) => (
                  <span>
                    {record.type === "Supplement"
                      ? translation.supplier.types.supplement
                      : record.type === "Technology"
                      ? translation.supplier.types.technology
                      : record.type === "CattleFarmer"
                      ? translation.supplier.types.cattleFarmer
                      : record.type === "VeterinarySupplement"
                      ? translation.supplier.types.veterinarySupplement
                      : record.type === "Others"
                      ? translation.supplier.types.others
                      : "Não informado"}
                  </span>
                )}
              />
              <Column
                title={translation.supplier.table.columns.phoneNumber}
                dataIndex="phoneNumber"
                sorter
                key="phoneNumber"
                align="left"
                render={(text, record) => (
                  <span>{phoneMask(getOnlyNumber(record?.phoneNumber))}</span>
                )}
                {...this.getColumnSearchProps("phoneNumber")}
              />
              <Column
                title={translation.supplier.table.columns.city_uf}
                dataIndex="city"
                key="city"
                align="left"
                sorter
                render={(text, record) => (
                  <span>
                    {record.city !== null &&
                      `${record?.city?.name}/${record?.city?.state?.name}`}
                  </span>
                )}
                {...this.getColumnSearchProps("city")}
              />
              <Column
                title={translation.supplier.table.columns.email}
                dataIndex="email"
                key="email"
                align="left"
                sorter
                {...this.getColumnSearchProps("email")}
              />
              <Column
                align="left"
                render={(text, record) => (
                  <Dropdown
                    overlay={menu(record.id, record)}
                    trigger={["click"]}
                    key={record.id}
                  >
                    <MenuContainer>
                      <MenuIcon />
                    </MenuContainer>
                  </Dropdown>
                )}
              />
            </Table>
          </Col>
        </Row>
        <DrawerSupplier />
        <DrawerSupplierDetails />
      </CardCustom>
    );
  }
}
const mapStateToProps = (state) => ({
  app: state.app,
  supplier: state.supplier,
});

const mapDispatchToProps = (dispatch) => ({
  supplierActions: bindActionCreators(SupplierActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Supplier)
);
