import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import { Row, Col, Tabs } from "antd";
/* Redux libs */
import { useSelector } from "react-redux";
/** Components */
import { Container, PageTitle } from "./styles";
import LotIcon from "../../components/admin/sidebar/icons/lot";
// Pages
import ListLotProduction from "./listProduction";
import ListLotReceipt from "./listReceipt";
import MovementLots from "./movementLot";
import useModuleContext from "../../hooks/useModuleContext";

const Lots = (props) => {
  const [tabSelected, setTabSelected] = useState("production");
  const { translation } = useSelector((state) => state.app);
  const { isAllowedFunctions } = useModuleContext();

  const { location } = props;

  useEffect(() => {
    function handleTabChange() {
      const values = queryString.parse(location.search);
      if (values != null && values.tabSelected === "receipt")
        setTabSelected("receipt");
    }

    handleTabChange();
  });

  function handleTabClick(key, e) {
    setTabSelected(key);
  }

  return (
    <Container>
      {/* Header */}
      <Row type="flex" style={{ marginBottom: 16 }}>
        <Col span={24}>
          <PageTitle>
            <LotIcon />
            <span>{translation.sidebar.lot}</span>
          </PageTitle>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <Tabs
            type="card"
            defaultActiveKey="production"
            activeKey={tabSelected}
            onTabClick={handleTabClick}
          >
            <Tabs.TabPane
              tab={<span>{translation.lot.tabs.production}</span>}
              key="production"
              disabled={!isAllowedFunctions(["LOT"])}
            >
              <ListLotProduction tab={tabSelected} />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span>Mov. Lotes</span>}
              key="movementLots"
              disabled={!isAllowedFunctions(["MOVE_LOT"])}
            >
              <MovementLots />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span>{translation.lot.tabs.receipt}</span>}
              key="receipt"
              disabled={!isAllowedFunctions(["LOT_RECEIPT"])}
            >
              <ListLotReceipt tab={tabSelected} />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Lots);
