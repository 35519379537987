import React, { useState } from "react";
import { useCallback } from "react";
import { createContext } from "react";
import { useSelector } from "react-redux";
import { findAll } from "../../services/unitService";

export const UnitContext = createContext({
  dataList: [],
  isLoadingDataList: false,
  data: null,
  isDrawerVisible: false,
  shouldBlockToOnlyGroupUnit: false,
  BASIC_UNITS: [],
});

export const UnitContextProvider = ({ children }) => {
  const BASIC_UNITS = [
    { key: "Unidade", name: "Unidade (un)" },
    { key: "Dose", name: "Dose (ds)" },
    { key: "Peso", name: "Peso (kg)" },
    { key: "Volume", name: "Volume (lt)" },
    { key: "Metro", name: "Metro (m)" },
  ];

  const [dataList, setDataList] = useState([]);
  const [data, setData] = useState(null);
  const [isLoadingDataList, setIsLoadingDataList] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [shouldBlockToOnlyGroupUnit, setShouldBlockToOnlyGroupUnit] =
    useState(false);

  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const fetchData = useCallback(async () => {
    setIsLoadingDataList(true);
    try {
      const {
        data: { results },
      } = await findAll({ groupId, farmId });
      setDataList(results);
    } catch (error) {
    } finally {
      setIsLoadingDataList(false);
    }
  }, [farmId, groupId]);

  const openDrawer = useCallback(
    (data = null, shouldBlockToOnlyGroupUnit = false) => {
      if (data) setData(data);
      setShouldBlockToOnlyGroupUnit(shouldBlockToOnlyGroupUnit);
      setIsDrawerVisible(true);
    },
    []
  );

  const closeDrawer = useCallback(() => {
    setData(null);
    setShouldBlockToOnlyGroupUnit(false);
    setIsDrawerVisible(false);
  }, []);

  return (
    <UnitContext.Provider
      value={{
        dataList,
        fetchData,
        data,
        isLoadingDataList,
        isDrawerVisible,
        openDrawer,
        closeDrawer,
        BASIC_UNITS,
        shouldBlockToOnlyGroupUnit,
      }}
    >
      {children}
    </UnitContext.Provider>
  );
};
