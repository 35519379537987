import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import { Creators as BenchmarkActions } from "../../../store/ducks/benchmark";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Row,
  Col,
  Input,
  Select,
  Switch,
  notification,
} from "antd";
import ButtonStandard from "../../utils/button";
import AlertIcon from "./icons/alert";
import SuccessIcon from "./icons/success";
import PencilIcon from "../../utils/icons/pencil";
import PlusBlueIcon from "../../utils/icons/plus/blue";
import {
  Container,
  Footer,
  CustomDivider,
  Table,
  RadioGroupCustom,
  RadioButtonCustom,
} from "./styles";
import { showKPIsHarvests, showKPIs } from "../../../services/kpiService";
import DrawerBenchmarkHarvestKpis from "./benchmarkHarvestKpis";

class DrawerBenchmark extends Component {
  constructor(props) {
    super(props);
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;
    this.state = {
      formBenchmark: {
        id: null,
        groupId,
        farmId,
        name: "",
        kpiValues:[],
        status: "Active",
      },
      harvests: [],
      kpis: [],
      drawerBenchmarkHarvestVisible: false,
      benchmarkHarvestData: [],
      selectedHarvest: null,
      saveOptions: "only_save",      
    };
    this.formRef = React.createRef();
  }

  getHarvests = async (groupId, farmId) => {
    try {
      const {
        data: { results: harvests },
      } = await showKPIsHarvests({ groupId, farmId });
      this.setState({
          harvests
      });
    } catch (error) {
      this.setState({
        harvests: []
      });
    }
  };

  getKpis = async (groupId, farmId) => {
    try {
      const {
        data: { results: kpis },
      } = await showKPIs({ groupId, farmId });
      this.setState({
          kpis
      });
    } catch (error) {
      this.setState({
        kpis: []
      });
    }
  };

  closeDrawer = () => {
    const { benchmarkActions } = this.props;
    benchmarkActions.hideDrawer();
  };

  cleanDrawer = () => {};

  handleDrawerVisible = async (visible) => {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;
    if (visible) {
      if (
        Object.entries(groupSelected).length > 0 &&
        Object.entries(farmSelected).length > 0
      ) {
        await Promise.all([
          this.getHarvests(groupSelected.id, farmSelected.id),
          this.getKpis(),
        ]);
      }
    } else {
      this.formik.resetForm({
        id: null,
        groupId: groupSelected.id,
        farmId: farmSelected.id,
        name: "",
        status: "Active",
        kpiValues: []
      });
      this.setState({
        formBenchmark: {
          id: null,
          groupId: groupSelected.id,
          farmId: farmSelected.id,
          name: "",
          status: "Active",
          kpiValues: [],
        },
        benchmarkHarvestData: [],
        selectedHarvest: null
      });
    }
  };

  openNotificationError = (title, message) => {
    notification["error"]({
      message: title,
      description: message,
    });
  };

  handleSubmitModalForm = async (values, actions) => {
    const {
      app: {
        translation,
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      benchmark: { error },
    } = this.props;

    if (values.kpiValues == null || values.kpiValues.length <= 0){
      this.openNotificationError("Dados incorretos", translation.benchmark.errorAtLeastOneKPIValue);
    } else {
    
      const body = {
        id: values.id,
        groupId: values.groupId,
        farmId: values.farmId,
        name: values.name,
        status: values.status,
        kpiValues: values.kpiValues
      };

      if (body.id === null) {
        await this.handleNewBenchmark(body);
      } else {
        await this.handleUpdateBenchmark(body, body.id);
      }
      
      if (error === null) {
        actions.setErrors({});
        this.formik.resetForm({
          id: null,
          groupId: groupId,
          farmId: farmId,
          name: "",
          status: "Active",
          kpiValues: [],
        });
        this.setState({
          formBenchmark: {
            id: null,
            groupId: groupId,
            farmId: farmId,
            name: "",
            status: "Active",
            kpiValues: []
          },
          benchmarkHarvestData: [],
          selectedHarvest: null
        });
      }
    }
  };

  handleNewBenchmark = async (benchmark) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      benchmarkActions,
    } = this.props;
    let saveOptions = this.state.saveOptions === "only_save" ? false : true;
    await benchmarkActions.saveBenchmark(
      groupId,
      farmId,
      null,
      benchmark,
      saveOptions
    );
  };

  handleUpdateBenchmark = async (benchmark, id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      benchmarkActions,
    } = this.props;
    let saveOptions = this.state.saveOptions === "only_save" ? false : true;

    await benchmarkActions.saveBenchmark(
      groupId,
      farmId,
      id,
      benchmark,
      saveOptions
    );
  };


  handleEditBenchmarkHarvestKpi = (harvest, benchmark) => {
    this.setState({
      selectedHarvest: harvest,
      benchmarkHarvestData: benchmark.filter(kpiValue=> kpiValue.harvest.id === harvest.id),
      drawerBenchmarkHarvestVisible: true,
    });
  };

  handleSaveBenchmarkHarvestKpi = (data) => {
    if (data != null) {
      
      const {
        formBenchmark,
        selectedHarvest
      } = this.state;

      const {
        benchmark: {
          benchmarkData
        }
      } = this.props;

      if (benchmarkData != null) {
        let harvestList = benchmarkData.kpiValues.filter(k => k.harvest.id !== selectedHarvest.id);
        harvestList.push(...data);
        benchmarkData.kpiValues = harvestList;
        this.setState({
          formBenchmark: {
            ...formBenchmark,
            kpiValues: harvestList
          },
          drawerBenchmarkHarvestVisible: false,
          benchmarkHarvestData: [],
          selectedHarvest: null
        });
      } else {
        let harvestList = formBenchmark.kpiValues.filter(k => k.harvest.id !== selectedHarvest.id);
        harvestList.push(...data);
        this.setState({
          formBenchmark: {
            ...formBenchmark,
            kpiValues: harvestList
          },  
          drawerBenchmarkHarvestVisible: false,
          benchmarkHarvestData: [],
          selectedHarvest: null
        }); 
      }
    }
  };

  handleCancelBenchmarkHarvestKpi = () => {
    this.setState({
      drawerBenchmarkHarvestVisible: false,
      benchmarkHarvestData: [],
      selectedHarvest: null
    });
  };

  render() {
    const {
      app: { translation, groupSelected, farmSelected },
      benchmark: {
        benchmarkData,
        drawerVisible,
      },
    } = this.props;

    const {
      formBenchmark,
      harvests,
      drawerBenchmarkHarvestVisible,
      benchmarkHarvestData,
      selectedHarvest,
      kpis
    } = this.state;

    const validationSchema = Yup.object().shape({
      name: Yup.string().required(),
    });

    const { Option } = Select;

    return (
      <Container
        title={
          benchmarkData != null
            ? translation.benchmark.drawerTitleEdit
            : translation.benchmark.drawerTitle
        }
        width={500}
        onClose={this.closeDrawer}
        maskClosable={false}
        visible={drawerVisible}
        afterVisibleChange={this.handleDrawerVisible}
      >
        <Formik
          ref={(ref) => (this.formik = ref)}
          enableReinitialize={true}
          initialValues={ benchmarkData != null ? benchmarkData : formBenchmark }
          onSubmit={this.handleSubmitModalForm}
          validationSchema={validationSchema}
          render={(props) => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <div className="drawerForm">
                <Row type="flex">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <span style={{ fontSize: "14px", color: "#000" }}>
                      {benchmarkData != null
                        ? translation.benchmark.drawerSubTitleEdit
                        : translation.benchmark.drawerSubTitle}
                    </span>
                  </Col>
                </Row>
                {/* Benchmark Visibility */}
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label>
                        {translation.benchmark.form.groupFarmVisibility}
                      </label>
                    </Row>
                    <Row>
                      <RadioGroupCustom
                        value={
                          props.values.groupId != null &&
                          props.values.farmId != null
                            ? "farm"
                            : props.values.groupId != null &&
                              props.values.farmId == null
                            ? "group"
                            : "farm"
                        }
                        buttonStyle="solid"
                      >
                        <RadioButtonCustom
                          value="farm"
                          onChange={(e) => {
                            props.setFieldValue("groupId", groupSelected.id);
                            props.setFieldValue("farmId", farmSelected.id);
                            this.setState({
                              formBenchmark: {
                                ...this.state.formBenchmark,
                                groupId: groupSelected.id,
                                farmId: farmSelected.id,
                              },
                            });
                          }}
                        >
                          {translation.supplier.form.radiogroup.farm}
                        </RadioButtonCustom>
                        <RadioButtonCustom
                          value="group"
                          onChange={(e) => {
                            props.setFieldValue("groupId", groupSelected.id);
                            props.setFieldValue("farmId", null);
                            this.setState({
                              formBenchmark: {
                                ...this.state.formBenchmark,
                                groupId: groupSelected.id,
                                farmId: null,
                              },
                            });
                          }}
                        >
                          {translation.benchmark.form.radiogroup.group}:
                          {groupSelected.name}
                        </RadioButtonCustom>
                      </RadioGroupCustom>
                    </Row>
                  </Col>
                </Row>
                {benchmarkData != null ? (
                  <Row
                    type="flex"
                    style={{ marginTop: "13px" }}
                    justify="space-between"
                    align="middle"
                  >
                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                      <label style={{ color: "#9074bf", fontWeight: "bold", }} >
                        {translation.benchmark.form.status}
                      </label>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6} align="right">
                      {props.values.status === "Active" ? (
                        <span
                          style={{
                            color: "#A9C133",
                            fontFamily: "Asap",
                            fontWeight: "normal",
                            marginRight: 6,
                          }}
                        >
                          {translation.status.active}
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "#D44C4C",
                            fontFamily: "Asap",
                            fontWeight: "normal",
                            marginRight: 6,
                          }}
                        >
                          {translation.status.inactive}
                        </span>
                      )}
                      <Switch
                        checked={props.values.status === "Active" ? true : false}
                        onChange={(checked, event) => {
                          props.setValues({
                            ...props.values,
                            status: checked ? "Active" : "Inactive",
                          });
                        }}
                      />
                    </Col>
                  </Row>
                ) : null}
                {Object.entries(props.errors).length > 0 &&
                  props.submitCount > 0 && (
                    <Row type="flex" justify="center" align="middle">
                      <label className="error">
                        {translation.error.formError}
                      </label>
                    </Row>
                  )}
                {/* Input system identification */}
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label className={props.errors.name && props.submitCount > 0 ? "error" : ""}>
                        {translation.benchmark.form.name}*
                      </label>
                    </Row>
                    <Row>
                      <Input
                        placeholder={translation.benchmark.form.namePlaceHolder}
                        value={props.values.name}
                        name="name"
                        autoComplete="chrome-off"
                        onChange={(e) => {
                          props.setFieldValue("name", e.target.value);
                          this.setState({
                            formBenchmark: {
                              ...this.state.formBenchmark,
                              name: e.target.value,
                            },
                          });
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                {/* Input additional data*/}
                <CustomDivider dashed>
                  {translation.benchmark.form.harvestDivider}
                </CustomDivider>
                <Row style={{ marginTop: "13px" }}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Table>
                      <tbody>
                        {harvests && harvests.map(harvest => (
                          <tr key={harvest.id}>
                            <td>
                              <span>
                                Safra {moment(harvest.startDate, "YYYY-MM-DD").format("DD/MM/YYYY")}{" "}
                                -{" "}
                                {moment(harvest.endDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                              </span>
                            </td>
                            <td>
                              {!props.values.kpiValues?.find(kpiValues => kpiValues.harvest.id === harvest.id) ? (
                                <span>
                                  <AlertIcon /> {translation.status.pendingData}
                                </span>
                              ) : (
                                <span className="success">
                                  <SuccessIcon />{" "}
                                  {translation.status.informedData}
                                </span>
                              )}
                            </td>
                            <td>
                              {props.values.kpiValues?.find(kpiValues => kpiValues.harvest.id === harvest.id) ? (
                                <button
                                  className="edit"
                                  type="button"
                                  style={{ marginLeft: "60px" }}
                                  onClick={() => this.handleEditBenchmarkHarvestKpi(harvest, props.values.kpiValues)}
                                >
                                  {translation.buttons.edit}
                                  <PencilIcon />
                                </button>
                              ) : (
                                <button
                                  className="add"
                                  type="button"
                                  onClick={() => this.handleEditBenchmarkHarvestKpi(harvest, props.values.kpiValues) }
                                >
                                  {translation.buttons.addInfo}
                                  <PlusBlueIcon />
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
              {/* Footer */}
              <Footer>
                <Row type="flex" justify="space-between">
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="selectDiv"
                  >
                    {props.values.id === null ? (
                      <Select
                        value={this.state.saveOptions}
                        name="saveOptions"
                        onChange={(value) => this.setState({ saveOptions: value })}
                      >
                        <Option key="1" value="only_save">
                          {translation.benchmark.saveOptions.only_save}
                        </Option>
                        <Option key="2 " value="save_and_create_new">
                          {translation.benchmark.saveOptions.save_and_create_new}
                        </Option>
                      </Select>
                    ) : null}
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="buttonsDiv"
                  >
                    <ButtonStandard type="button" buttonType="type7" onClick={this.closeDrawer}>
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard type="submit" buttonType="type6">
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
        <DrawerBenchmarkHarvestKpis
          drawerVisible={drawerBenchmarkHarvestVisible}
          data={benchmarkHarvestData}
          harvest={selectedHarvest}
          kpis={kpis}
          onSave={this.handleSaveBenchmarkHarvestKpi}
          onCancel={this.handleCancelBenchmarkHarvestKpi}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  benchmark: state.benchmark,
});

const mapDispatchToProps = (dispatch) => ({
  benchmarkActions: bindActionCreators(BenchmarkActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerBenchmark);
