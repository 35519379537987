import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useSelector } from "react-redux";
import {
  Container,
  Title,
  Message,
  CardMessage,
  EditButton,
  Body,
  GreyCard,
} from "./styles";
import { Row, Col, Spin, notification } from "antd";
import PencilIcon from "../../components/utils/icons/pencil";
import EditAlerts from "./edit";
import DisplayAlerts from "./display";
/** Services */
import {
  saveOrUpdateParameter,
  getAlertParameterItems,
} from "../../services/generalParameterService";

const AlertParameter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [alertsData, setAlertsData] = useState([]);

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  useEffect(() => {
    const signal = Axios.CancelToken.source();
    async function fetchData() {
      setIsLoading(true);
      try {
        let {
          data: { results },
        } = await getAlertParameterItems({ groupId, farmId, signal });

        const dataResult = results.map((r) => {
          r.isDefault = r.farmId == null && r.groupId == null;
          r.updated = false;
          return r;
        });
        setAlertsData(dataResult);
        setIsLoading(false);
      } catch (error) {
        notification.error({
          message: "Erro ao buscar lista de alertas",
        });
        setIsLoading(false);
      }
    }
    fetchData();
    return () => {
      signal.cancel();
    };
  }, [groupId, farmId]);

  async function saveEdit(data) {
    setEditing(false);
    setIsLoading(true);
    try {
      for (var i = 0; i < data.length; i++) {
        let parameter = data[i];
        if (parameter.updated) {
          let {
            data: { results },
          } = await saveOrUpdateParameter({
            groupId,
            farmId,
            parameterItemId: parameter.id,
            parameterId: parameter.parameterId,
            edit: !parameter.isDefault,
            body: parameter,
          });
          results.code = parameter.code;
          results.parameterId = parameter.parameterId;
          results.isDefault = false;
          results.updated = false;
          data[i] = results;
        }
      }
      setAlertsData(data);
      notification.success({
        message: translation.generalParameters.successParameterUpdated,
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: translation.generalParameters.errorParameterUpdated,
      });
    } finally {
      setIsLoading(false);
    }
  }

  function cancelEdit() {
    setEditing(false);
  }

  function openEdit() {
    setEditing(true);
  }

  return (
    <Container>
      <Body>
        <GreyCard>
          <Row type="flex" justify="space-between">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Title>Alertas</Title>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
              <EditButton type="button" onClick={openEdit} disabled={isEditing}>
                {isEditing
                  ? `${translation.buttons.editing}...`
                  : `${translation.buttons.edit}`}
                <PencilIcon />
              </EditButton>
            </Col>
          </Row>
          {isEditing ? (
            <EditAlerts
              data={alertsData}
              onSave={saveEdit}
              onCancel={cancelEdit}
            />
          ) : (
            <Spin spinning={isLoading}>
              {alertsData == null ? (
                <CardMessage>
                  <Row type="flex" justify="center" align="middle">
                    <Message>
                      Clique em <span className="red">"editar"</span>
                    </Message>
                  </Row>
                </CardMessage>
              ) : (
                <DisplayAlerts data={alertsData} />
              )}
            </Spin>
          )}
        </GreyCard>
      </Body>
    </Container>
  );
};

export default AlertParameter;
