import apiFinancial from "../config/api_financial";
import getHeaders from "./getHeaders";

function findAllFinancialParametersByGroupIdAndFarmId(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/financial/parameters`,
    getHeaders(false, signal)
  );
}

export { findAllFinancialParametersByGroupIdAndFarmId };
