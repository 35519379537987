import React from "react";
import moment from "moment";
import { Col, Icon, Row } from "antd";
import { CardSaveDietStrategy } from "../styles";
// import PencilIcon from "../../../../components/utils/icons/pencil";
import { numberMask } from "../../../../services/helpersMethodsService";
import PencilIcon from "../../../../components/utils/icons/pencil";

// import { Container } from './styles';

const DietStrategyDisplay = ({
  formDietStrategy,
  lotAvgWeight,
  amountLotAnimals,
  handleEditFirstForm,
  breeds,
}) => {
  return (
    <CardSaveDietStrategy>
      {/* Diet Strategy Name and button edit */}
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        className="rowWithMarginBottom"
      >
        <Col span={12}>
          <Row>
            <label className="info title">Identificação da estratégia *</label>
          </Row>
          <Row className="rowInput">
            <strong>{formDietStrategy?.name}</strong>
          </Row>
        </Col>
        <Col span={6} align="right">
          <button type="button" className="edit" onClick={handleEditFirstForm}>
            Editar
            <PencilIcon />
          </button>
        </Col>
      </Row>
      {formDietStrategy?.generic ? (
        <>
          <Row className="rowWithMarginBottom" type="flex" justify="start">
            <Col span={8}>
              <span className="info">
                Raça:
                <strong>
                  {breeds?.find((b) => b.id === formDietStrategy?.breedId)
                    ?.name || "-"}
                </strong>
              </span>
            </Col>
            <Col span={8}>
              <span className="info">
                Sexo:
                <strong>
                  {formDietStrategy.gender === "Male" ? "Macho" : "Fêmea"}
                </strong>
              </span>
            </Col>
            {formDietStrategy.gender === "Male" ? (
              <Col span={8}>
                <span className="info">
                  Castrado:
                  <strong>{formDietStrategy.cap ? "Sim" : "Não"}</strong>
                </span>
              </Col>
            ) : null}
          </Row>
          <Row type="flex" justify="start">
            <Col span={8}>
              <span className="info">
                Data Inicial:
                <strong>
                  {moment(formDietStrategy?.initialDate).format("DD/MM/YYYY")}
                </strong>
              </span>
            </Col>
            <Col span={8}>
              <span className="info">
                Peso Inicial:
                <strong>
                  {`${numberMask(
                    formDietStrategy?.initialWeight || 0,
                    false
                  )} kg`}
                </strong>
              </span>
            </Col>
          </Row>
        </>
      ) : (
        <>
          {/* Lot name,  referenceBirthdayDate and referenceAcquisitionWeight */}
          <Row type="flex" justify="start" className="rowWithMarginBottom">
            <Col span={6}>
              <span className="info">
                Lote:
                <strong>
                  {formDietStrategy.lot != null && formDietStrategy.lot.name}
                </strong>
              </span>
            </Col>
            <Col span={6}>
              <span className="info">
                Data Med. Nascimento:
                <strong>
                  {formDietStrategy.lot != null &&
                    moment(formDietStrategy.lot.referenceBirthdayDate).format(
                      "DD/MM/YYYY"
                    )}
                </strong>
              </span>
            </Col>
            <Col span={6}>
              <span className="info">
                Peso médio aquisição:
                <strong>
                  {formDietStrategy.lot != null &&
                    `${numberMask(
                      formDietStrategy.lot.referenceAcquisitionWeight || 0,
                      false
                    )} kg`}
                </strong>
              </span>
            </Col>
            <Col span={6}>
              <span className="info">
                Peso assintótico:
                <strong>
                  {formDietStrategy.lot != null &&
                    `${numberMask(
                      formDietStrategy.lot.asymptoticWeight || 0,
                      false
                    )} kg`}
                </strong>
              </span>
            </Col>
          </Row>
          {/* amountLotAnimals, referenceAcquisitionDate and lotAvgWeight  */}
          <Row type="flex" justify="start">
            <Col span={6}>
              <span className="info">
                Qtd. de animais no lote:
                <strong>
                  {formDietStrategy.lot != null ? (
                    amountLotAnimals === null ? (
                      <Icon type="loading" spin />
                    ) : (
                      <strong>{`${amountLotAnimals} animais`}</strong>
                    )
                  ) : null}
                </strong>
              </span>
            </Col>
            <Col span={6}>
              <span className="info">
                Data de aquisição:
                <strong>
                  {formDietStrategy.lot != null &&
                    moment(
                      formDietStrategy.lot.referenceAcquisitionDate
                    ).format("DD/MM/YYYY")}
                </strong>
              </span>
            </Col>
            <Col span={6}>
              <span className="info">
                Média Última Pesagem:
                <strong>
                  {formDietStrategy.lot != null ? (
                    lotAvgWeight === null ? (
                      <Icon type="loading" spin />
                    ) : (
                      <strong>{`${numberMask(
                        lotAvgWeight || 0,
                        false
                      )} kg`}</strong>
                    )
                  ) : null}
                </strong>
              </span>
            </Col>
          </Row>
        </>
      )}
    </CardSaveDietStrategy>
  );
};

export default DietStrategyDisplay;
