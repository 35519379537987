import React from "react";
import { useHistory } from "react-router-dom";
import useRegister from "../../../../hooks/useRegister";
// Components
import { Container, InfoContainer } from "../styles";
// Services

const RegisterStep5 = () => {
  const history = useHistory();
  const { form, userId } = useRegister();
  /** Redux variables */
  /** Effect  */
  /** Methods */
  function handleGoToCreateFarm() {
    history.push({
      pathname: "/register/group/farm",
      state: {
        nome: form?.fullname,
        email: form?.email,
        userId: userId,
        plan: form?.plan,
        due_day: form?.due_day,
        isFromAccountFarmModal: false,
      },
    });
  }

  function handleGoToLogin() {
    history.push("/login");
  }

  return (
    <Container>
      {form?.plan?.planSelected === "Custom" ? (
        <>
          <InfoContainer>
            <h2>
              Agradecemos por <br /> você ter nos <br /> escolhido!
            </h2>
            <p>
              Conta conosco para ajuda-lo a executar uma
              <strong> PECUÁRIA MAIS LUCRATIVA.</strong>
            </p>
            <p>
              O Departamento Comercial entrará em contato para apresentar
              propostas.
            </p>
            <p style={{ display: "none" }}>
              Code 2 - Evento conversão google ads Contrate BovExo
            </p>
          </InfoContainer>
          <button className="bg-purple" onClick={handleGoToLogin}>
            Confirmar
          </button>
        </>
      ) : (
        <>
          <InfoContainer>
            <h2>
              Agradecemos por <br /> você ter nos <br /> escolhido!
            </h2>
            <p>
              Conta conosco para ajuda-lo a executar uma
              <strong> PECUÁRIA MAIS LUCRATIVA.</strong>
            </p>
            <p>Qualquer dúvida estamos a disposição pelo chat.</p>
            <p>Vamos lá?</p>
            <p style={{ display: "none" }}>
              Code 2 - Evento conversão google ads Contrate BovExo
            </p>
          </InfoContainer>
          <button className="bg-green" onClick={handleGoToCreateFarm}>
            Criar fazenda
          </button>
        </>
      )}
    </Container>
  );
};

export default RegisterStep5;
