import { useEffect } from "react";

export default function useKeyPress(
  code,
  useControll = true,
  useAlt = false,
  action
) {
  useEffect(() => {
    function onKeyUp(e) {
      if (useAlt) {
        if (e.altKey && e.keyCode === code) {
          action();
        }
      } else if (useControll) {
        if (e.ctrlKey && e.keyCode === code) {
          action();
        }
      } else {
        if (e.keyCode === code) {
          action();
        }
      }
    }
    window.addEventListener("keydown", onKeyUp);
    return () => window.removeEventListener("keydown", onKeyUp);
  }, [action, code, useAlt, useControll]);
}
