import React from "react";

const PriceLock = ({color="black"}) => (
  <svg
    enableBackground="new 0 0 52 52"
    id="priceLock"
    version="1.1"
    height={24}
    width={24}
    viewBox="0 0 52 52"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}>
    <g>
      <g>
        <path d="M37.0042114,21.9987183v-8.036499c0-6.5957031-4.9345703-11.9609375-11-11.9609375s-11,5.3652344-11,11.9609375v8.036499    H8v28h36v-28H37.0042114z M17.0042114,13.9622192c0-5.4921875,4.0375977-9.9609375,9-9.9609375s9,4.46875,9,9.9609375v8.036499    h-18V13.9622192z M29.8474731,42.1351318c-0.7713013,0.796936-1.7911987,1.2307739-3.0597534,1.3032227v1.5629272h-1.1941528    v-1.585022c-1.7043457-0.2023315-3.2340088-0.991333-4.5894775-2.3660889l1.3618774-1.8889771    c1.0446167,1.0567017,2.1203613,1.685791,3.2276001,1.8889771v-4.2125854    c-1.4055786-0.390564-2.4346313-0.9047241-3.0878906-1.541748c-0.6528931-0.6370239-0.9793091-1.581543-0.9793091-2.8334961    s0.3761597-2.2759399,1.1288452-3.072876c0.7523193-0.7960815,1.7316895-1.2298584,2.9383545-1.3023071v-1.0858765h1.1941528    v1.1070557c1.367981,0.1166382,2.6616211,0.6591187,3.8804321,1.6292114l-1.2127686,1.9976807    c-0.8206177-0.6802979-1.710022-1.0999756-2.6676636-1.2598877v4.0818481h0.0557861    c1.4302368,0.3914185,2.4813232,0.9197388,3.1531372,1.585022c0.6715088,0.6661987,1.0074463,1.6327515,1.0074463,2.8988647    C31.0040894,40.3080444,30.6184082,41.3399658,29.8474731,42.1351318z" />
      </g>
      <g>
        <path d="M26.7877197,37.2068481v3.9078369c0.5970459-0.0715332,1.0696411-0.2747803,1.4177246-0.6078491    c0.3480835-0.3322144,0.522644-0.7518921,0.522644-1.2590332c0-0.5062256-0.1370239-0.9082642-0.4107056-1.2051392    C28.0437622,37.7457886,27.5339355,37.4675293,26.7877197,37.2068481z" />
      </g>
      <g>
        <path d="M23.8023071,32.2017212c0,0.4850464,0.1245117,0.8684692,0.3731689,1.1503296    c0.2489624,0.2827148,0.7211914,0.5539551,1.4180908,0.8146362v-3.7567749    c-0.5598755,0.0583496-0.998291,0.250061-1.3152466,0.5760498C23.9609985,31.3110962,23.8023071,31.7166748,23.8023071,32.2017212    z" />
      </g>
    </g>
  </svg>
);

export default PriceLock;
