import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Creators as SaleScenarioActions } from "../../../store/ducks/saleScenario";
import { Link } from "react-router-dom";

import { Col, Empty, Icon, Row, Spin, notification } from "antd";
import BreadCrumbs from "../../../components/utils/breadCrumbs";
import ButtonStandard from "../../../components/utils/button";

import InfoTooltip from "../../../components/utils/infoTooltip";

// Services
import { numberMask } from "../../../services/helpersMethodsService";

import { Body, Container, Document, Table, Title } from "./styles";
import StarIcon from "../../../components/utils/icons/star";
import {
  compareSaleScenarios,
  compareSaleScenariosGeneratePdf,
} from "../../../services/saleScenarioService";

const CompareSaleScenario = () => {
  const [isLoading, setIsLoading] = useState(false);
  /** Redux values */
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const {
    compareRequestData,
    compareSaleScenarioResults: { results },
  } = useSelector((state) => state.saleScenario);
  const dispatch = useDispatch();

  // Effects
  useEffect(() => {
    async function handle() {
      setIsLoading(true);
      try {
        const {
          data: { results },
        } = await compareSaleScenarios({
          groupId,
          farmId,
          body: compareRequestData,
        });
        dispatch(SaleScenarioActions.setCompareSaleScenarioResults(results));
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
    handle();
  }, [groupId, farmId, compareRequestData, dispatch]);

  /** Methods */
  async function exportPdf() {
    setIsLoading(true);
    try {
      const { data } = await compareSaleScenariosGeneratePdf({
        groupId,
        farmId,
        body: compareRequestData,
      });
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      let link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "comparacao_cenario_venda.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      notification.error({
        message: "Não foi possível gerar o PDF. Contate o suporte.",
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <Spin spinning={isLoading}>
        <Row
          type="flex"
          justify="start"
          align="middle"
          gutter={16}
          className="breadCrumbsContainer"
        >
          <Col xs={2} sm={2} md={2} lg={2} xl={2}>
            <Link className="back-container" to="/admin/decisions?tabSelect=1">
              <Icon type="left-circle" theme="filled" />{" "}
              {translation.buttons.back}
            </Link>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="titleContainer">
              <Title>Comparação de Cenários de Venda</Title>
              <ButtonStandard
                buttonType="thirdary"
                disabled={false}
                onClick={exportPdf}
              >
                Gerar PDF
              </ButtonStandard>
            </div>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} align="right">
            <BreadCrumbs>
              <Link to="/admin/decisions?tabSelect=1">
                <span className="pageTreeInative">Decisões</span>
              </Link>
              <span className="pageTreeActive">
                Comparação: Cenário de venda
              </span>
            </BreadCrumbs>
          </Col>
        </Row>
        {results != null ? (
          <Document>
            <Body>
              <Row>
                <h3>
                  <StarIcon color="#FFD754" size="15" /> representam os
                  resultados favoritos.
                </h3>
              </Row>
              <Table>
                <thead>
                  <tr>
                    <th>
                      CENÁRIO
                      <span>
                        <InfoTooltip title="Nome do Cenário de Venda e a Identificação do Resultado Selecionado." />
                      </span>
                    </th>
                    {results?.map((r) => (
                      <th key={r.id}>
                        <div>
                          {r?.baseline && (
                            <StarIcon color="#FFD754" size="15" />
                          )}{" "}
                          {r?.name}
                        </div>
                        <br />
                        {r?.resultName}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="field">
                      Executado em
                      <span>
                        <InfoTooltip title="Data de execução deste Resultado do Cenário de Venda." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">{`${moment(
                        r?.executionDate
                      ).format("DD/MM/YYYY")}`}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="field">
                      Curva de Preços da @
                      <span>
                        <InfoTooltip title="Curva de Preços futuros da @, utilizada nos parâmetros deste resultado." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td
                        key={r.id}
                        className="value"
                      >{`${r?.marketAnalysisName}`}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="field">
                      Data Venda
                      <span>
                        <InfoTooltip title="Tempo em dias, da data do processamento da simulação até a data sugerida de venda." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">
                        {`dia ${r?.sellDateInDays}`}
                      </td>
                    ))}
                  </tr>
                  {/* Valor @ na Data Venda */}
                  <tr>
                    <td className="field">
                      Valor @ na Data Venda
                      <span>
                        <InfoTooltip title="Valor da @ prevista na data sugerida de abate, conforme a Curva de Preço da @ Futura escolhida como parâmetro para este Cenário de Venda." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">{`${numberMask(
                        r?.weightPrice || 0,
                        true
                      )}`}</td>
                    ))}
                  </tr>
                  <tr className="separator">
                    <td className="field"></td>
                    {results?.map((r) => (
                      <td key={r.id} className="value"></td>
                    ))}
                  </tr>
                  {/* Quadro Produtivo */}
                  <tr>
                    <td className="title" colSpan={results.length + 1}>
                      Quadro Produtivo
                    </td>
                  </tr>
                  {/* Qtd Animais */}
                  <tr>
                    <td className="field">
                      Qtd Animais
                      <span>
                        <InfoTooltip title="Quantidade total de animais presentes no(s) lote(s) selecionado(s) para compor o cenário de venda." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">
                        {r?.amountAnimals}
                      </td>
                    ))}
                  </tr>
                  {/* Peso Med Inicial */}
                  <tr>
                    <td className="field">
                      Peso Med Inicial
                      <span>
                        <InfoTooltip title="Peso médio das últimas pesagens dos animais do(s) lote(s) selecionado(s)." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">{`${numberMask(
                        r?.averageInitialWeight || 0,
                        false
                      )} kg`}</td>
                    ))}
                  </tr>
                  {/* Peso Med Venda */}
                  <tr>
                    <td className="field">
                      Peso Med Venda
                      <span>
                        <InfoTooltip title="Peso Médio Previsto para abate dos animais presentes no Cenário de Venda, na data sugerida de venda, de acordo com a Estratégia de Dieta associada ao seu Lote de Produção." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">{`${numberMask(
                        r?.averageFinalWeight || 0,
                        false
                      )} kg`}</td>
                    ))}
                  </tr>
                  {/* GDP */}
                  <tr>
                    <td className="field">
                      GDP
                      <span>
                        <InfoTooltip title="Ganho de peso médio dos animais presentes no Cenário de Venda, desde a entrada deles na fazenda, seja nascimento ou compra, até a data da sugerida de venda." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">{`${numberMask(
                        r?.averageGdp || 0,
                        false
                      )} kg`}</td>
                    ))}
                  </tr>
                  {/* Aprov. Carcaça */}
                  <tr>
                    <td className="field">
                      Aprov. Carcaça
                      <span>
                        <InfoTooltip title="% de Aproveitamento de Carcaça previsto. Cálculo: (Receita/((Valor da @na Data do Abate x Peso médio Previsto ao Abate)/(15 x Quantidade de Animais))." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">{`${numberMask(
                        r?.carcassHarnessing || 0,
                        false
                      )} %`}</td>
                    ))}
                  </tr>
                  <tr className="separator">
                    <td className="field"></td>
                    {results?.map((r) => (
                      <td key={r.id} className="value"></td>
                    ))}
                  </tr>
                  {/* Resultado Financeiro */}
                  <tr>
                    <td className="title" colSpan={results.length + 1}>
                      Resultado Financeiro
                    </td>
                  </tr>
                  {/* Receita Total */}
                  <tr>
                    <td className="field">
                      Receita Total
                      <span>
                        <InfoTooltip title="Receita prevista com a venda dos animais, de acordo com os parâmetros informados, peso previsto de abate, % Aprov. Carcaça e curva de preços futuro do @." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">{`${numberMask(
                        r?.income || 0,
                        true
                      )}`}</td>
                    ))}
                  </tr>
                  {/* Receita/Cab */}
                  <tr>
                    <td className="field">
                      Receita/Cab
                      <span>
                        <InfoTooltip title="Receita média prevista, por animal, de acordo com os parâmetros informados, peso previsto de abate, % Aprov. Carcaça e curva de preços futuro do @." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">{`${numberMask(
                        r?.income / r?.amountAnimals || 0,
                        true
                      )}`}</td>
                    ))}
                  </tr>
                  {/* Margem Total */}
                  <tr>
                    <td className="field">
                      Margem Total
                      <span>
                        <InfoTooltip title="Resultado financeiro da operação (Margem = Receita – Custo Total)." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">
                        {`${numberMask(r?.margin || 0, true)}`}
                        <br />
                        <br />
                        {`${numberMask(
                          (r?.margin / r?.income) * 100 || 0,
                          false
                        )} %`}
                      </td>
                    ))}
                  </tr>
                  {/* Margem / cab */}
                  <tr>
                    <td className="field">
                      Margem/cab
                      <span>
                        <InfoTooltip title="Resultado financeiro da operação por cabeça. Margem/Cab = ((Receita – Custo Total) /  Qte Animais)" />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">{`${numberMask(
                        r?.marginPerAnimal || 0,
                        true
                      )}`}</td>
                    ))}
                  </tr>

                  <tr className="separator">
                    <td className="field"></td>
                    {results?.map((r) => (
                      <td key={r.id} className="value"></td>
                    ))}
                  </tr>
                  {/* Análise em Ciclo Equivalentes */}
                  <tr>
                    <td className="title" colSpan={results.length + 1}>
                      Análise em Ciclo Equivalentes
                    </td>
                  </tr>
                  {/* # Giros em Período Equivalente */}
                  <tr>
                    <td className="field">
                      # Giros em Período Equivalente
                      <span>
                        <InfoTooltip title="Comparação da quantidade de giros de produção possíveis, de cada resultado, quando comparamos com o maior ciclo, dentre os cenários apresentados." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">{`${numberMask(
                        r?.numberOfTurns || 0,
                        false
                      )}`}</td>
                    ))}
                  </tr>
                  {/* Receita em Período Equivalente */}
                  <tr>
                    <td className="field">
                      Receita em Período Equivalente
                      <span>
                        <InfoTooltip title="Receita equivalente, considerando a data prevista de abate dos cenários. O sistema irá calcular a receita prevista, considerando para todos os resultados uma única (e a maior) data prevista de abate, dentre os resultados comparados." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">{`${numberMask(
                        r?.incomeInEquivalentPeriod || 0,
                        true
                      )}`}</td>
                    ))}
                  </tr>
                  {/* Margem em Período Equivalente */}
                  <tr>
                    <td className="field">
                      Margem em Período Equivalente
                      <span>
                        <InfoTooltip title="Margem equivalente, considerando a Receita e Custos Totais equivalentes para uma única (e maior) data prevista de abate, dentre os resultados comparados." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">
                        {`${numberMask(
                          r?.marginInEquivalentPeriod || 0,
                          true
                        )}`}
                        <br />
                        <br />
                        {`${numberMask(
                          (r?.marginInEquivalentPeriod /
                            r?.incomeInEquivalentPeriod) *
                            100 || 0,
                          false
                        )} %`}
                      </td>
                    ))}
                  </tr>
                  <tr className="separator">
                    <td className="field"></td>
                    {results?.map((r) => (
                      <td key={r.id} className="value"></td>
                    ))}
                  </tr>
                  {/* Análise a Valor Presente */}
                  <tr>
                    <td className="title" colSpan={results.length + 1}>
                      Análise a Valor Presente
                    </td>
                  </tr>
                  {/* Receita a Valor Presente (VP) */}
                  <tr>
                    <td className="field">
                      Receita a Valor Presente (VP)
                      <span>
                        <InfoTooltip title='Receita total prevista, trazendo para o momento atual o montante previsto para o futuro, conforme tava "Valor Presente" informada.' />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">
                        {r?.valuePresent === null
                          ? ""
                          : `${numberMask(r?.valuePresent.income, true)}`}
                      </td>
                    ))}
                  </tr>
                  {/* Receita VP/Cab */}
                  <tr>
                    <td className="field">
                      Receita VP/Cab
                      <span>
                        <InfoTooltip title='Receita prevista, por cabeça, trazendo para o momento atual o montante previsto para o futuro, conforme tava "Valor Presente" informada.' />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">
                        {r?.valuePresent === null
                          ? ""
                          : `${numberMask(
                              r?.valuePresent.income / r?.amountAnimals,
                              true
                            )}`}
                      </td>
                    ))}
                  </tr>
                  {/* Margem a Valor Presente (VP) */}
                  <tr>
                    <td className="field">
                      Margem a Valor Presente (VP)
                      <span>
                        <InfoTooltip title='Margem total prevista, trazendo para o momento atual o montante previsto para o futuro, conforme tava "Valor Presente" informada.' />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">
                        {r?.valuePresent === null
                          ? ""
                          : `${numberMask(r?.valuePresent.margin, true)}`}
                        <br />
                        <br />
                        {r?.valuePresent === null
                          ? ""
                          : `${numberMask(
                              (r?.valuePresent.margin /
                                r?.valuePresent.income) *
                                100 || 0,
                              false
                            )} %`}
                      </td>
                    ))}
                  </tr>
                  {/* Margem VP/Cab */}
                  <tr>
                    <td className="field">
                      Margem VP/Cab
                      <span>
                        <InfoTooltip title='Margem prevista, por cabeça, trazendo para o momento atual o montante previsto para o futuro, conforme tava "Valor Presente" informada.' />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">
                        {r?.valuePresent === null
                          ? ""
                          : `${numberMask(
                              r?.valuePresent.margin / r?.amountAnimals,
                              true
                            )}`}
                      </td>
                    ))}
                  </tr>
                  <tr className="separator">
                    <td className="field"></td>
                    {results?.map((r) => (
                      <td key={r.id} className="value"></td>
                    ))}
                  </tr>
                  {/* Composição de Custos */}
                  <tr>
                    <td className="title" colSpan={results.length + 1}>
                      Composição de Custos
                    </td>
                  </tr>
                  <tr>
                    <td className="field">
                      Custo Dieta
                      <span>
                        <InfoTooltip title="Custo da Dieta apropriado conforme estratégia de dieta associada ao lote de produção dos animais." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">{`${numberMask(
                        r?.dietCost || 0,
                        true
                      )}`}</td>
                    ))}
                  </tr>

                  <tr>
                    <td className="field">
                      Custo Adm/Operacionais
                      <span>
                        <InfoTooltip title="Custos Administrativos e Operacionais apropriados aos animais a partir da movimentação financeira/Custeio." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">{`${numberMask(
                        r?.totalFixedVariableCost || 0,
                        true
                      )}`}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="field">
                      Custo Aquisição
                      <span>
                        <InfoTooltip title="Custos de Aquisição, quando animais de compra." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">{`${numberMask(
                        r?.aquisitionCost || 0,
                        true
                      )}`}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="field">
                      Custo Total
                      <span>
                        <InfoTooltip title="Custo Total de Produção dos Animais, considerando Custo de Aquisição, Dietas, Zootécnicos (Sanidade), Variáveis Operacionais, Infraestrutura e mão de obra e imobilizados." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">{`${numberMask(
                        r?.totalCost || 0,
                        true
                      )}`}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="field">
                      Custo Previsto Dieta/Cab
                      <span>
                        <InfoTooltip title="Custo médio Previsto com Dieta, por cabeça, a apropriado conforme estratégia de dieta associada ao lote de produção dos animais até a data sugerida de venda." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">{`${numberMask(
                        r?.dietCost / (r?.amountAnimals * r?.sellDateInDays) ||
                          0,
                        true
                      )}`}</td>
                    ))}
                  </tr>

                  <tr>
                    <td className="field">
                      Custo Previsto Adm/Operacionais/Cab
                      <span>
                        <InfoTooltip title="Custo Adm/Operacionais previsto, por cabeça, conforme custeio registrado a partir das movimentações financeiras até a data sugerida de venda." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">{`${numberMask(
                        r?.totalFixedVariableCost /
                          (r?.amountAnimals * r?.sellDateInDays) || 0,
                        true
                      )}`}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="field">
                      Custo Aquisição/Cab
                      <span>
                        <InfoTooltip title="Custo médio de Aquisição, por cabeça, quando animais de compra." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">{`${numberMask(
                        r?.aquisitionCost / r?.amountAnimals || 0,
                        true
                      )}`}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="field">
                      Custo Previsto Total/Cab
                      <span>
                        <InfoTooltip title="Custo previsto total, por cabeça, até a data sugerida de venda." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">{`${numberMask(
                        (r?.dietCost + r?.totalFixedVariableCost) /
                          (r?.amountAnimals * r?.sellDateInDays) || 0,
                        true
                      )}`}</td>
                    ))}
                  </tr>
                  <tr className="separator">
                    <td className="field"></td>
                    {results?.map((r) => (
                      <td key={r.id} className="value"></td>
                    ))}
                  </tr>
                  {/* Infos Adicionais */}
                  <tr>
                    <td className="title" colSpan={results.length + 1}>
                      Infos Adicionais
                    </td>
                  </tr>
                  {/* Dias Restantes do Ciclo */}
                  <tr>
                    <td className="field">
                      Dias Restantes do Ciclo
                      <span>
                        <InfoTooltip title="Tempo em dias, da data de emissão deste relatório até a data prevista/sugerida de venda." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td
                        key={r.id}
                        className="value"
                      >{`${r?.cycleTime} dias`}</td>
                    ))}
                  </tr>
                  {/* Cred. Potencial CO2eq */}
                  <tr>
                    <td className="field">
                      Cred. Potencial CO2eq
                      <span>
                        <InfoTooltip title="Crédito potencial total calculado referente a emissão de CO2eq." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">{`${numberMask(
                        r?.carbonFootprintTotal || 0,
                        true
                      )} / ${numberMask(
                        r?.carbonFootprintAverage || 0,
                        true
                      )}`}</td>
                    ))}
                  </tr>
                  {/* Lotes */}
                  <tr>
                    <td className="field">
                      Lotes
                      <span>
                        <InfoTooltip title="Dados do(s) Lote(s) de Produção utilizado(s) no processamento deste cenário de venda. Sendo o Nome do Lote, Estratégia de Dieta utilizada, Data Prevista de Venda, Quantidade de Animais e Peso Médio Previsto de Venda.Quando, mais de um Lote de Produção utilizado para criar o Cenário de Venda, os dados são apresentados de forma separada por Lote." />
                      </span>
                    </td>
                    {results?.map((r) => (
                      <td key={r.id} className="value">
                        {r?.lotsInfo?.map((l) => (
                          <div key={`${Math.random()}`} className="lotInfo">
                            <p>{l.lotName}</p>
                            <p>{l.dietStrategyName}</p>
                            <p>{moment(l.sellDate).format("DD/MM/YYYY")}</p>
                            <p>{`${l.amountAnimals} cab`}</p>
                            <p>{`${numberMask(
                              l.averageFinalWeight || 0,
                              false
                            )} kg`}</p>
                          </div>
                        ))}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </Table>
            </Body>
          </Document>
        ) : (
          <Empty description="Nenhum cenário foi selecionado" />
        )}
      </Spin>
    </Container>
  );
};

export default CompareSaleScenario;
