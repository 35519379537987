// import axios from "axios";
import apiOptimization from "../config/api_optimization";
import getHeaders from "./getHeaders";

export function getSales(payload) {
  const { groupId, farmId, boitelSaleScenarioId, boitelSaleScenarioResultId } =
    payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/boitels/${boitelSaleScenarioId}/results/${boitelSaleScenarioResultId}/sales`,
    getHeaders(false, null)
  );
}
