import React, { Component } from "react";
import { connect } from "react-redux";
import CanvasJSReact from "../../../../assets/canvasjs.react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Row, Col, Select, Radio, Spin, Empty } from "antd";
import ButtonStandard from "../../../../components/utils/button";
import {
  Container,
  Footer,
  CustomDivider,
  ChartContainer,
  LotCurveParameterCard,
} from "./styles";
import NoteIcon from "../../../../components/utils/icons/note";
/** Services */
import { getBreedCurveParam } from "../../../../services/breedService";
import { getTwoDecimalDigits } from "../../../../services/helpersMethodsService";

class DrawerLotCurveParameter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDefault: false,
      saveOptions: "only_save",
      breedId: null,
      chartOptions: {
        animationEnabled: true,
        zoomEnabled: true,
        height: 300,
        axisX: {
          maximum: 830,
          interval: 210,
          intervalType: "number",
          crosshair: {
            enabled: true,
            snapToDataPoint: true,
          },
          stripLines: [
            {
              value: 210,
              label: "7 meses",
              labelPlacement: "outside", //"outside",
              lineDashType: "dashDot",
              color: "#4b4b4b",
              labelFontColor: "#4b4b4b",
              labelBackgroundColor: "#fff",
            },
            {
              value: 420,
              label: "14 meses",
              labelPlacement: "outside", //"outside",
              lineDashType: "dashDot",
              color: "#4b4b4b",
              labelFontColor: "#4b4b4b",
              labelBackgroundColor: "#fff",
            },
            {
              value: 630,
              label: "21 meses",
              labelPlacement: "outside", //"outside",
              lineDashType: "dashDot",
              color: "#4b4b4b",
              labelFontColor: "#4b4b4b",
              labelBackgroundColor: "#fff",
            },
            {
              value: 800,
              label: "32 meses",
              labelPlacement: "outside", //"outside",
              lineDashType: "dashDot",
              color: "#4b4b4b",
              labelFontColor: "#4b4b4b",
              labelBackgroundColor: "#fff",
            },
          ],
        },
        toolTip: {
          fontFamily: "Asap",
          shared: true,
        },
        axisY: {
          includeZero: true,
          valueFormatString: "###0.##",
          suffix: "kg",
          gridDashType: "dot",
        },
        legend: {
          cursor: "pointer",
          itemclick: (e) => {
            if (
              typeof e.dataSeries.visible === "undefined" ||
              e.dataSeries.visible
            ) {
              e.dataSeries.visible = false;
            } else {
              e.dataSeries.visible = true;
            }
            e.chart.render();
          },
        },
        data: [],
      },
      breedCurveParams: null,
      curveSelected: {
        geneticCategory: "GT",
        handlingCategory: "DP",
        theoreticalCurveType: "Logistic",
      },
      isLoadingChart: true,
    };
  }

  closeDrawer = () => {
    const { onClose } = this.props;
    onClose();
  };

  handleSubmitModalForm = async (values, actions) => {
    const {
      curveSelected: {
        theoreticalCurveType,
        geneticCategory,
        handlingCategory,
      },
      breedCurveParams,
    } = this.state;
    if (
      breedCurveParams.find(
        (bcp) =>
          geneticCategory === bcp.geneticCategory &&
          handlingCategory === bcp.handlingCategory &&
          theoreticalCurveType === bcp.theoreticalCurveType
      ) != null
    ) {
      values.breedCurveParam = breedCurveParams.find(
        (bcp) =>
          geneticCategory === bcp.geneticCategory &&
          handlingCategory === bcp.handlingCategory &&
          theoreticalCurveType === bcp.theoreticalCurveType
      );
      const { onSave } = this.props;
      const body = {
        asymptoticWeight: values.asymptoticWeight,
        breedCurveParam: {
          id: values.breedCurveParam.id,
        },
      };

      onSave(body);
    }
  };

  handleChangeAsymptoticWeight = (value) => {
    const { lotCurveParametersData } = this.props;
    const { breedCurveParams } = this.state;
    if (lotCurveParametersData != null)
      this.handleCalculateCurves(breedCurveParams, value);
  };

  handleChangeCurveSelected = (field, value) => {
    const {
      lotCurveParametersData: { asymptoticWeight },
    } = this.props;

    const { breedCurveParams } = this.state;

    this.setState(
      {
        isLoadingChart: true,
        curveSelected: {
          ...this.state.curveSelected,
          [field]: value,
        },
      },
      () => this.handleCalculateCurves(breedCurveParams, asymptoticWeight)
    );
  };

  handleCalculateCurves = (breedCurveParams, asymptoticWeight) => {
    const {
      curveSelected: {
        theoreticalCurveType,
        geneticCategory,
        handlingCategory,
      },
    } = this.state;
    let result = [];

    breedCurveParams
      .filter((bcp) => bcp.theoreticalCurveType === theoreticalCurveType)
      .forEach((bcp) => {
        let day = 1;
        let dataChart = {
          type: "line",
          name: `${bcp.geneticCategory} - ${bcp.handlingCategory}`,
          showInLegend: true,
          markerType: "none",
          markerSize: 5,
          yValueFormatString: "###0.## kg",
          lineDashType:
            geneticCategory === bcp.geneticCategory &&
            handlingCategory === bcp.handlingCategory
              ? "solid"
              : "dot",
          color:
            geneticCategory === bcp.geneticCategory &&
            handlingCategory === bcp.handlingCategory
              ? "#A9C133"
              : "#e8e5e5",
          dataPoints: [],
        };
        switch (theoreticalCurveType) {
          case "Logistic":
            do {
              dataChart.dataPoints.push({
                x: day,
                y: Number.parseFloat(
                  Number(
                    1.09441891891892 *
                      (asymptoticWeight /
                        (1 +
                          bcp.correctionFactor *
                            Math.exp(bcp.maturationRate * day)))
                  ).toFixed(2)
                ),
              });

              day++;
            } while (day <= 800);
            break;
          case "Brody":
            do {
              dataChart.dataPoints.push({
                x: day,
                y: Number.parseFloat(
                  Number(
                    1.21843243243243 *
                      (asymptoticWeight *
                        (1 -
                          bcp.correctionFactor *
                            Math.exp(-bcp.maturationRate * day)))
                  ).toFixed(2)
                ),
              });
              day++;
            } while (day <= 800);
            break;
          case "VonBertalanffy":
            do {
              dataChart.dataPoints.push({
                x: day,
                y: Number.parseFloat(
                  Number(
                    1.29818918918919 *
                      (asymptoticWeight *
                        Math.pow(
                          1 -
                            bcp.correctionFactor *
                              Math.exp(-bcp.maturationRate * day),
                          3
                        ))
                  ).toFixed(2)
                ),
              });
              day++;
            } while (day <= 800);
            break;
          default:
            break;
        }
        result.push(dataChart);
      });
    this.setState({
      isLoadingChart: false,
      chartOptions: {
        ...this.state.chartOptions,
        data: result,
      },
    });
  };

  handleVisibleDrawer = (visible) => {
    if (visible) {
      const { lotCurveParametersData } = this.props;
      if (
        lotCurveParametersData != null &&
        lotCurveParametersData.breedCurveParam != null
      ) {
        this.setState({
          isDefault: lotCurveParametersData.default,
          curveSelected: {
            geneticCategory:
              lotCurveParametersData.breedCurveParam.geneticCategory,
            handlingCategory:
              lotCurveParametersData.breedCurveParam.handlingCategory,
            theoreticalCurveType:
              lotCurveParametersData.breedCurveParam.theoreticalCurveType,
          },
        });
      }
      if (lotCurveParametersData.breedCurveParam != null) {
        try {
          getBreedCurveParam({
            id: lotCurveParametersData.breedCurveParam.breed.id,
          }).then(({ data: { results: breedCurveParams } }) => {
            this.setState({
              breedCurveParams,
            });
            this.handleCalculateCurves(
              breedCurveParams,
              lotCurveParametersData.asymptoticWeight
            );
          });
        } catch (error) {}
      }
    }
  };

  render() {
    const {
      app: { translation },
      lotCurveParametersData,
      drawerParametersVisible,
      isLoadingRequestSetCurve,
    } = this.props;

    const {
      curveSelected,
      chartOptions,
      isLoadingChart,
      isDefault,
    } = this.state;

    const validationSchema = Yup.object().shape({
      asymptoticWeight: Yup.number().required(),
    });

    const { Option } = Select;
    const { CanvasJSChart } = CanvasJSReact;

    return (
      <Container
        title="Parâmetros de lote"
        width={650}
        onClose={this.closeDrawer}
        maskClosable={false}
        visible={drawerParametersVisible}
        afterVisibleChange={this.handleVisibleDrawer}
      >
        {lotCurveParametersData.breedCurveParam != null ? (
          <Formik
            enableReinitialize={true}
            initialValues={lotCurveParametersData}
            onSubmit={this.handleSubmitModalForm}
            validationSchema={validationSchema}
            render={(props) => (
              <Spin spinning={isLoadingRequestSetCurve}>
                <form onSubmit={props.handleSubmit} autoComplete="off">
                  <div className="drawerForm">
                    <Row type="flex">
                      <span className="message">
                        Defina os parâmetros do lote
                      </span>
                    </Row>
                    <Row type="flex" justify="start" style={{ marginTop: 13 }}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row>
                          <label className="title">
                            {translation.breed.table.columns.name}
                          </label>
                        </Row>
                        <Row>
                          <label className="subTitle">
                            {props.values.breedCurveParam != null &&
                              props.values.breedCurveParam.breed.name}
                          </label>
                        </Row>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row>
                          <label className="title">
                            {translation.breed.table.columns.geneticGroup}
                          </label>
                        </Row>
                        <Row>
                          <label className="subTitle">
                            {props.values.breedCurveParam != null &&
                              props.values.breedCurveParam.breed.geneticGroup}
                          </label>
                        </Row>
                      </Col>
                    </Row>
                    <CustomDivider dashed>
                      {translation.breed.formParameters.extimatedCurveTitle}
                    </CustomDivider>
                    <Spin spinning={isLoadingChart}>
                      {Object.entries(props.errors).length > 0 &&
                        props.submitCount > 0 && (
                          <Row type="flex" justify="center" align="middle">
                            <label className="error">
                              {translation.error.formError}
                            </label>
                          </Row>
                        )}
                      <Row type="flex" justify="start">
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Row>
                            <label
                              className={
                                props.values.entity.asymptoticWeight === null
                                  ? "withoutMarginLeft default"
                                  : "withoutMarginLeft"
                              }
                            >
                              {
                                translation.breed.formParameters
                                  .asymptoticWeight
                              }
                            </label>
                          </Row>
                          <Row className="rowInput">
                            <strong className="customStrong">{`${getTwoDecimalDigits(
                              props.values.asymptoticWeight
                            )} Kg`}</strong>
                          </Row>
                        </Col>
                        <Col xs={14} sm={14} md={14} lg={14} xl={14} offset={2}>
                          <Row>
                            <label className={isDefault ? "default" : ""}>
                              {translation.breed.formParameters.theoricalCurve}{" "}
                              *
                            </label>
                          </Row>
                          <Row className="rowInput">
                            <Select
                              value={curveSelected.theoreticalCurveType}
                              onChange={(value) =>
                                this.handleChangeCurveSelected(
                                  "theoreticalCurveType",
                                  value
                                )
                              }
                            >
                              <Option value="Brody">Brody</Option>
                              <Option value="Logistic">Logistic</Option>
                              <Option value="VonBertalanffy">
                                Von-Bertalanffy
                              </Option>
                            </Select>
                          </Row>
                        </Col>
                      </Row>
                      <Row
                        type="flex"
                        className="cardGrey"
                        justify="start"
                        align="middle"
                      >
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <label className={isDefault ? "default" : ""}>
                            {translation.breed.formParameters.genetic}
                          </label>
                        </Col>
                        <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                          <Radio.Group
                            value={curveSelected.geneticCategory}
                            onChange={(e) =>
                              this.handleChangeCurveSelected(
                                "geneticCategory",
                                e.target.value
                              )
                            }
                          >
                            <Radio value="GT">Tauríno</Radio>
                            <Radio value="GZ">Zebuíno</Radio>
                            <Radio value="GC">Cruzado</Radio>
                          </Radio.Group>
                        </Col>
                      </Row>
                      <Row
                        type="flex"
                        className="cardGrey"
                        justify="start"
                        align="middle"
                      >
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <label className={isDefault ? "default" : ""}>
                            {translation.breed.formParameters.diet}
                          </label>
                        </Col>
                        <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                          <Radio.Group
                            value={curveSelected.handlingCategory}
                            onChange={(e) =>
                              this.handleChangeCurveSelected(
                                "handlingCategory",
                                e.target.value
                              )
                            }
                          >
                            <Radio value="DP">Premium</Radio>
                            <Radio value="DS">Superior</Radio>
                            <Radio value="DC">Comum</Radio>
                          </Radio.Group>
                        </Col>
                      </Row>
                      <ChartContainer>
                        <CanvasJSChart options={chartOptions} />
                      </ChartContainer>
                      {isDefault && (
                        <LotCurveParameterCard>
                          <div className="header">
                            <span className="title">
                              <NoteIcon />
                              Os títulos em laranja indicam que os valores
                              atribuídos são obtidos da raça do animal
                            </span>
                          </div>
                        </LotCurveParameterCard>
                      )}
                    </Spin>
                  </div>
                  {/* Footer */}
                  <Footer>
                    <Row type="flex" justify="space-between">
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="selectDiv"
                      ></Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="buttonsDiv"
                      >
                        <ButtonStandard
                          type="button"
                          buttonType="type7"
                          onClick={this.closeDrawer}
                        >
                          {translation.buttons.cancel}
                        </ButtonStandard>

                        <ButtonStandard type="submit" buttonType="type6">
                          {translation.buttons.save}
                        </ButtonStandard>
                      </Col>
                    </Row>
                  </Footer>
                </form>
              </Spin>
            )}
          />
        ) : (
          <Empty description="O lote e raça predominante não contem curvas, por favor cadastrar uma curva em Parêmtros -> Raças" />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  breed: state.breed,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrawerLotCurveParameter);
