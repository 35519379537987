import apiFarm from "../config/api_farms";
import getHeaders from "./getHeaders";


export function showAlertsByDateAndEntity(payload) {
  const { groupId, farmId, executionDate, entity, signal } = payload;

  return apiFarm.get(
    `/bovexo/${groupId}/farms/${farmId}/alerts?${executionDate != null ? `&executionDate=${executionDate}` : ""}${entity != null ? `&entity=${entity}` : ""}`,
    getHeaders(false, signal)
  );
}

export function saveAlertChecked(payload) {
  const { groupId, farmId, alertExecutionId, checked, signal } = payload;
  
  return apiFarm.patch(
    `/bovexo/${groupId}/farms/${farmId}/alerts/${alertExecutionId}/checked/${checked}`,
    {},
    getHeaders(false, signal)
  );
}

