import React, { Component } from "react";
import CanvasJSReact from "../../../assets/canvasjs.react";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";

/* Redux libs */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as DietStrategyActions } from "../../../store/ducks/dietStrategy";
import { Creators as PicketActions } from "../../../store/ducks/picket";

/** Styles Components */
import {
  Container,
  Title,
  ChartContainer,
  ListPeriodsItem,
  ListPeriodsItemPlus,
  ListPeriodsContainer,
  ListPeriodsTitleContainer,
  ListPeriodsItemContainer,
  PopConfirmCustom,
} from "./styles";

/** Components */
import BreadCrumbs from "../../../components/utils/breadCrumbs";
import Loading from "../../../components/utils/loading";
import {
  Row,
  Col,
  notification,
  Skeleton,
  Spin,
  Icon,
  Tooltip,
  Empty,
  Modal,
} from "antd";
import ButtonStandard from "../../../components/utils/button";
import RainIcon from "../../generalParameter/weatherSeasonsParameter/display/icons/rain";
import SunIcon from "../../generalParameter/weatherSeasonsParameter/display/icons/sun";
import TransitionIcon from "../../generalParameter/weatherSeasonsParameter/display/icons/transition";
import PeriodModel from "./periodModel";
import TextToolIcon from "../../../components/utils/icons/textTool";
import PlusCirclePurpleIcon from "../../../components/utils/icons/plus/circle/purple";
import InfoTooltip from "../../../components/utils/infoTooltip";
import AlertIcon from "../../../components/drawers/alert/icons/alert";
import DietStrategyForm from "./form";
import DietStrategyDisplay from "./display";
import RoutingLeavingGuardModal from "../../../components/modals/routingLeavingGuardModal";
/** Services */
import {
  saveDietStrategy,
  getDietStrategyShow,
} from "../../../services/dietStrategyService";
import {
  getLotIndexDropDown,
  getLotStatisticsAsync,
  getLotStatistics,
  getLotShow,
} from "../../../services/lotService";
import { calculateCurve } from "../../../services/curveService";
import {
  getPicketIndexDropDown,
  getPicketByLotId,
  getPicketLotHistories,
} from "../../../services/picketService";
import {
  getParameterItems,
  getWeatherSeason,
} from "../../../services/generalParameterService";
import { getPastureIndexDropDown } from "../../../services/pastureService";
import { getSupplementIndexDropDown } from "../../../services/supplementService";
import { getTwoDecimalDigits } from "../../../services/helpersMethodsService";
import {
  getLotRealAverageGdpDashboard,
  getLotRealAverageWeightDashboard,
} from "../../../services/dashboards/dashboardLotService";
import { returnObjectFromAxis } from "../../../services/dashboards/dashboardCommonService";
import {
  dryColor,
  lotBreedColor,
  transitionColor,
  waterColor,
} from "../../../utils/colorsHandler";
import { getLotSupplementAverageConsumption } from "../../../services/lotSuplementConsumptionService";
import { getBreedIndexActive } from "../../../services/breedService";
import {
  classifyPeriodCarbonFootPrint,
  getCarbonFootprintIndex,
} from "../../../services/carbonFootprintService";
import { callValidationGdp } from "../../../services/validateGdpService";

class NewDietStrategy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldBlockFieldsOnEdit: false,
      isRecalculateGdpPeriods: false,
      isLoading: true,
      isLoadingListOfPickets: false,
      isLoadingPicketHistoryInfo: false,
      dataChart: [],
      stripLines: [],
      lastPicketLotOfLot: null,
      lotStartDate: moment(),
      formDietStrategy: {
        id: null,
        generic: true,
        breedId: null,
        cap: false,
        gender: "Male",
        initialDate: moment(),
        initialWeight: 0,
        dietPeriods: [],
        name: null,
        lot: null,
        useEstablishedCurve: false,
        status: "Active",
      },
      picketInfo: null,
      lotCurveInfo: null,
      lotCurveDatesAndWeights: [],
      dietPeriodCurveDatesAndWeights: [],
      gdpCurveDatesAndWeights: [],
      lotRealWeightCurveDatesAndWeights: [],
      lotRealGdpCurveDatesAndWeights: [],
      amountLotAnimals: 0,
      seasons: null,
      modalVisible: false,
      dietPeriodIndexSelected: null,
      dietPeriodSelected: null,
      editingDietStrategy: true,
      listOfDietsPeriods: null,
      listOfBreeds: null,
      listOfPickets: null,
      loadingLots: false,
      listOfLots: null,
      listOfPastures: null,
      listOfSupplements: null,
      loadingChart: false,
      startDateDeadline: null,
      lotAvgWeight: null,
      lotAvgSupplementConsumption: 0,
      allocatePicketVisible: false,
      allocatePicketId: null,
      allocateLotId: null,
      shouldBlockNavigation: true,
      maximumDate: moment(),
      carbonFootprintParameters: [],
      carbonBaseKg: 0,
      carbonCredit: 0,
    };
    this.inputName = React.createRef();
    this.chart = React.createRef();
  }

  componentDidMount() {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;

    this.setState({
      shouldBlockNavigation: true,
    });

    if (
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      const { id: groupId } = groupSelected;
      const { id: farmId } = farmSelected;
      const { id: dietStrategyId } = this.props.match.params;
      this.getBreeds(groupId, farmId);
      this.getCarbonFootprintParameters(groupId, farmId);
      this.getCarbonBaseKgAndCredit(groupId, farmId);
      this.getListOfLots(groupId, farmId);
      this.getListOfPastures(groupId, farmId);
      this.getListOfSupplements(groupId, farmId);
      this.getSeasons(farmId);
      if (dietStrategyId != null) {
        this.getDietStrategyInfo(groupId, farmId, dietStrategyId);
      } else {
        this.setState({
          isLoading: false,
        });
      }
    }
  }

  /** Method to get real average weight lot curve and real gdp average lot curve   */
  calculateLotRealCurvesForBaselineDietStrategy = async (
    groupId,
    farmId,
    lotId
  ) => {
    try {
      this.setState({
        loadingChart: true,
      });
      const dashboardParams = {
        lotId,
      };
      const [
        {
          data: { results: averageWeightResults },
        },
        {
          data: { results: averageGdpResults },
        },
      ] = await Promise.all([
        getLotRealAverageWeightDashboard({
          groupId,
          farmId,
          dashboardParams,
        }),
        getLotRealAverageGdpDashboard({
          groupId,
          farmId,
          dashboardParams,
        }),
      ]);
      const averageWeightData = returnObjectFromAxis(
        averageWeightResults,
        false
      );
      const averageGdpData = returnObjectFromAxis(averageGdpResults, false);
      const averageWeightDataChart = averageWeightData.map((d) => ({
        x: d.x,
        y: getTwoDecimalDigits(d.y),
        toolTipContent: `<span style='"'color: {color};'"'>{name}:</span> {x} | {y}`,
      }));
      const averageGdpDataChart = averageGdpData.map((d) => ({
        x: d.x,
        y: getTwoDecimalDigits(d.y),
        toolTipContent: `<span style='"'color: {color};'"'>{name}:</span> {x}  | {y}`,
      }));
      const newDataChart = this.state.dataChart.filter(
        (dc) => dc.id !== "lotRealWeightCurve" && dc.id !== "lotRealGdpCurve"
      );

      this.setState({
        lotRealWeightCurveDatesAndWeights: averageWeightResults.values_axis,
        lotRealGdpCurveDatesAndWeights: averageGdpResults.values_axis,
        dataChart: [
          ...newDataChart,
          {
            id: "lotRealWeightCurve",
            type: "line",
            name: "Lote - Peso médio",
            showInLegend: true,
            markerType: "none",
            markerSize: 5,
            xValueFormatString: "DD/MM/YY",
            yValueFormatString: "###0.## kg",
            color: "#005CFE",
            dataPoints: averageWeightDataChart,
          },
          {
            id: "lotRealGdpCurve",
            type: "line",
            name: "Lote - Gdp médio",
            showInLegend: true,
            markerType: "none",
            markerSize: 5,
            visible: false,
            axisYType: "secondary",
            xValueFormatString: "DD/MM/YY",
            yValueFormatString: "###0.## kg",
            color: "#c53030",
            dataPoints: averageGdpDataChart,
          },
        ],
        loadingChart: false,
      });
    } catch (error) {
      console.error(error);
    }
  };

  /** Method to calculate lot curve */
  calculateLotCurve = async (lot, curve, startDate = null) => {
    let result = [];
    let startDay = moment(lot.referenceAcquisitionDate).diff(
      lot.referenceBirthdayDate,
      "days",
      true
    );
    if (lot != null && curve != null) {
      try {
        result = calculateCurve(
          curve.asymptoticWeight,
          lot.referenceBirthdayDate,
          curve.breedCurveParam.correctionFactor,
          curve.breedCurveParam.maturationRate,
          curve.breedCurveParam.theoreticalCurveType,
          1095,
          startDay
        );
      } catch (error) {}
    }
    if (this.state.dataChart.find((dc) => dc.id === "lotCurve")) {
      this.setState({
        dataChart: this.state.dataChart.map((dc) => {
          if (dc.id === "lotCurve")
            return {
              id: "lotCurve",
              type: "line",
              name: "Lote",
              showInLegend: true,
              markerType: "none",
              markerSize: 5,
              xValueFormatString: "DD/MM/YY",
              yValueFormatString: "###0.## kg",
              color: lotBreedColor,
              dataPoints: result,
            };
          else return dc;
        }),
        loadingChart: false,
      });
    } else {
      this.setState({
        dataChart: [
          ...this.state.dataChart,
          {
            id: "lotCurve",
            type: "line",
            name: "Lote",
            showInLegend: true,
            markerType: "none",
            markerSize: 5,
            xValueFormatString: "DD/MM/YY",
            yValueFormatString: "###0.## kg",
            color: lotBreedColor,
            dataPoints: result,
          },
        ],
        loadingChart: false,
      });
    }

    this.setState({
      lotCurveDatesAndWeights: result,
    });
  };

  /** Method to calculate diet periods curve (only if useEstablishedCurve = false) */
  calculateDietPeriodsCurve = (periods = null) => {
    const {
      formDietStrategy: {
        lot,
        generic,
        initialDate,
        initialWeight,
        dietPeriods,
      },
      dataChart: stateDataChart,
    } = this.state;

    let dataChart = [...stateDataChart];

    let baseWeight = generic
      ? initialWeight
      : lot.referenceAcquisitionWeight || 0;
    const baseDate = generic ? initialDate : lot.referenceAcquisitionDate;

    let maxEndDay = 1095;

    try {
      let result = [];
      /** Mount results */
      if (periods != null) {
        if (periods.length > 0) {
          // Get the reference date
          const dateOfReference = moment(periods[0].startDate).subtract(
            periods[0].startDay,
            "days"
          );
          // Set the max end day
          maxEndDay =
            periods[periods.length - 1].endDay > 1095
              ? periods[periods.length - 1].endDay
              : 1095;
          /** Mount curve */
          periods.forEach((dp, index) => {
            let i = dp.startDay;
            let j = 0;
            do {
              const isFirstValue = moment(dateOfReference)
                .add(i, "days")
                .isSame(moment(baseDate), "days", "[]");
              const x = moment(dateOfReference).add(i, "days").toDate();
              let y = 0;
              if (isFirstValue) {
                y = baseWeight;
              } else {
                if (dp?.allWeightGains?.length > 0) {
                  const weightGainAPI = dp.allWeightGains[j];
                  if (weightGainAPI) {
                    y = baseWeight += weightGainAPI.gdp;
                  } else {
                    y = baseWeight += dp.gdpExpectation;
                  }
                } else {
                  y = baseWeight += dp.gdpExpectation;
                }
              }
              result.push({
                x,
                y,
                toolTipContent: `<span style='"'color: {color};'"'>{name}:</span> {x} | {y}`,
              });
              i++;
              j++;
            } while (i <= dp.endDay);
          });
        }
      } else {
        if (dietPeriods != null && dietPeriods.length > 0) {
          // Get the reference date
          const dateOfReference = moment(dietPeriods[0].startDate).subtract(
            dietPeriods[0].startDay,
            "days"
          );
          // Set the max end day
          maxEndDay =
            dietPeriods[dietPeriods.length - 1].endDay > 1095
              ? dietPeriods[dietPeriods.length - 1].endDay
              : 1095;
          dietPeriods.forEach((dp, index) => {
            let i = dp.startDay;
            let j = 0;
            do {
              const isFirstValue = moment(dateOfReference)
                .add(i, "days")
                .isSame(moment(baseDate), "days", "[]");
              const x = moment(dateOfReference).add(i, "days").toDate();
              let y = 0;
              if (isFirstValue) {
                y = baseWeight;
              } else {
                if (dp?.allWeightGains?.length > 0) {
                  const weightGainAPI = dp.allWeightGains[j];
                  if (weightGainAPI) {
                    y = baseWeight += weightGainAPI.gdp;
                  } else {
                    y = baseWeight += dp.gdpExpectation;
                  }
                } else {
                  y = baseWeight += dp.gdpExpectation;
                }
              }
              result.push({
                x,
                y,
                toolTipContent: `<span style='"'color: {color};'"'>{name}:</span> {x} | {y}`,
              });
              i++;
              j++;
            } while (i <= dp.endDay);
          });
        }
      }
      // Set result
      if (result.length > 0) {
        if (this.state.dataChart.find((dc) => dc.id === "dietStrategyCurve")) {
          dataChart = dataChart.map((dc) => {
            if (dc.id === "dietStrategyCurve")
              return {
                id: "dietStrategyCurve",
                type: "line",
                name: "Estratégia de Dieta",
                showInLegend: true,
                markerType: "none",
                markerSize: 5,
                xValueFormatString: "DD/MM/YY",
                yValueFormatString: "###0.## kg",
                color: "#684E94",
                dataPoints: result,
              };
            else return dc;
          });
        } else {
          dataChart.push({
            id: "dietStrategyCurve",
            type: "line",
            name: "Estratégia de Dieta",
            showInLegend: true,
            markerType: "none",
            markerSize: 5,
            xValueFormatString: "DD/MM/YY",
            yValueFormatString: "###0.## kg",
            color: "#684E94",
            dataPoints: result,
          });
        }
        this.setState(
          {
            dietPeriodCurveDatesAndWeights: result,
            dataChart,
          },
          () => {
            /** Regenerate stripLines */
            this.generateStripLines(lot, maxEndDay > 1095 ? maxEndDay : 1095);
          }
        );
      } else if (
        result.length === 0 &&
        periods !== null &&
        periods.length === 0
      ) {
        dataChart = dataChart.filter((dc) => dc.id !== "dietStrategyCurve");
        this.setState(
          {
            dietPeriodCurveDatesAndWeights: result,
            dataChart,
          },
          () => {
            /** Regenerate stripLines */
            this.generateStripLines(lot, maxEndDay > 1095 ? maxEndDay : 1095);
          }
        );
      }
    } catch (error) {
      console.error(error);
    }

    return dataChart;
  };

  /** Method to create curve of gdp */
  calculateGdpCurve = (dietPeriodsP = null, dataChartP = null) => {
    const {
      formDietStrategy: { dietPeriods },
      dataChart,
    } = this.state;

    let dietPeriodsCopy = dietPeriodsP != null ? dietPeriodsP : dietPeriods;
    let dataChartCopy = null;
    let dataPointsArray = []; // Array with x (Date) and y (gdp)

    if (dataChartP != null) {
      dataChartCopy = dataChartP.map((dc) => dc);
    } else {
      dataChartCopy = dataChart.map((dc) => dc);
    }

    // Remove previus line of diet strategy curve gdp
    dataChartCopy = dataChartCopy.filter(
      (dc) => dc.id !== "dietStrategyCurveGdp"
    );

    try {
      if (dietPeriodsCopy.length > 0) {
        // Get the reference date
        const dateOfReference = moment(dietPeriodsCopy[0].startDate).subtract(
          dietPeriodsCopy[0].startDay,
          "days"
        );
        // Go through diet periods and populate the dataPointsArray
        dietPeriodsCopy.forEach((dp) => {
          const startDay = dp.startDay;
          const endDay = dp.endDay;
          let j = 0;
          for (let i = startDay; i <= endDay; i++) {
            const x = moment(dateOfReference).add(i, "days").toDate();
            let y = 0;
            if (dp?.allWeightGains?.length > 0) {
              const weightGainAPI = dp.allWeightGains[j];
              if (weightGainAPI) {
                y = weightGainAPI.gdp;
              } else {
                y = dp.gdpExpectation;
              }
              j++;
            } else {
              y = dp.gdpExpectation;
            }
            const value = {
              x,
              y,
              toolTipContent: `<span style='"'color: {color};'"'>{name}:</span> {x} | {y}`,
            };
            dataPointsArray.push(value);
          }
        });
      }
      dataChartCopy.push({
        id: "dietStrategyCurveGdp",
        type: "line",
        name: "Ganho de peso",
        showInLegend: true,
        markerType: "none",
        markerSize: 5,
        xValueFormatString: "DD/MM/YY",
        yValueFormatString: "###0.## kg",
        color: "#106518",
        axisYType: "secondary",
        dataPoints: dataPointsArray,
      });

      this.setState({
        dataChart: dataChartCopy,
        gdpCurveDatesAndWeights: dataPointsArray,
        loadingChart: false,
      });
    } catch (error) {
      console.error(error);
    }
  };

  /** Method to generate stripe lines in chart, the strip lines are the seasons */
  generateStripLines = async (lot, pEndDay = null) => {
    const {
      seasons,
      formDietStrategy,
      formDietStrategy: { generic, initialDate },
    } = this.state;

    const {
      app: {
        farmSelected: { id: farmId },
      },
    } = this.props;

    try {
      let startDay = generic
        ? 0
        : moment(lot.referenceAcquisitionDate).diff(
            lot.referenceBirthdayDate,
            "days",
            true
          );

      const baseDate = generic ? initialDate : lot.referenceBirthdayDate;

      let endDay = pEndDay ? pEndDay + 90 : 1095;

      // Set StripLines
      let resultStripLines = generic
        ? [
            {
              value: moment(moment(), "DD/MM/YY").toDate(),
              label: "Hoje",
              labelPlacement: "inside",
              color: "#4b4b4b",
              labelFontColor: "#4b4b4b",
              labelBackgroundColor: "transparent",
            },
          ]
        : [
            {
              value: moment(lot.referenceAcquisitionDate).toDate(),
              label: "Aquisição",
              labelPlacement: "outside",
            },
            {
              value: moment(moment(), "DD/MM/YY").toDate(),
              label: "Hoje",
              labelPlacement: "inside",
              color: "#4b4b4b",
              labelFontColor: "#4b4b4b",
              labelBackgroundColor: "transparent",
            },
          ];

      let season = null;

      let newStripLine = null;

      let seasonMap = [];

      if (seasons != null) {
        seasonMap = seasons.map((s) => s.season);
      } else {
        const {
          data: { results },
        } = await getWeatherSeason({ farmId });

        let resultSeasons =
          results.farmSelectedTypeSeason === "Drier"
            ? results.seasonsDrier
            : results.farmSelectedTypeSeason === "Wetter"
            ? results.seasonsWetter
            : results.seasonsDefault;

        seasonMap = resultSeasons.map((s) => s.season);
      }

      do {
        const currentMonth = Number(
          moment(baseDate).add(startDay, "days").month()
        );

        let nextMonth = Number(
          moment(baseDate)
            .add(startDay + 1, "days")
            .month()
        );

        let loopSeasonCurrentMonth = seasonMap[currentMonth];
        let loopSeasonNextMonth = seasonMap[nextMonth];

        if (season === null) {
          season = loopSeasonCurrentMonth;
          newStripLine = {
            startValue: moment(baseDate).add(startDay, "days").toDate(),
            label:
              season === "Water"
                ? "Chuva".toUpperCase()
                : season === "Dry"
                ? "Seca".toUpperCase()
                : season === "Transition"
                ? "Transição".toUpperCase()
                : null,
            color:
              season === "Water"
                ? waterColor
                : season === "Dry"
                ? dryColor
                : season === "Transition"
                ? transitionColor
                : null,
            labelFontColor: "#FFF",
            labelAlign: "center",
            labelAngle: 180,
            labelBackgroundColor: "transparent",
          };
        } else {
          /* Verifico se ñ mudou de estacao */
          if (loopSeasonCurrentMonth === season) {
            /* Se continua a mesma - verifico se no proximo dia ira mudar */
            if (loopSeasonCurrentMonth !== loopSeasonNextMonth) {
              /* Se mudou adiciono o end date e adiciono na lista */
              newStripLine = {
                ...newStripLine,
                endValue: moment(baseDate).add(startDay, "days").toDate(),
              };
              resultStripLines.push(newStripLine);
              newStripLine = null;
              season = null;
            }
          }
        }

        if (startDay === endDay) {
          if (loopSeasonCurrentMonth !== loopSeasonNextMonth) {
            endDay++;
          } else {
            newStripLine = {
              ...newStripLine,
              endValue: moment(baseDate).add(startDay, "days").toDate(),
            };
            resultStripLines.push(newStripLine);
            newStripLine = null;
            season = null;
          }
        }

        startDay++;
      } while (startDay <= endDay);

      if (formDietStrategy.dietPeriods.length > 0) {
        formDietStrategy.dietPeriods.forEach((dp, i) => {
          resultStripLines.push(
            {
              value: moment(dp.startDate).toDate(),
              color: "#000080",
              thickness: 4,
              showOnTop: true,
              lineDashType: "solid",
            },
            {
              startValue: moment(dp.startDate).toDate(),
              endValue: moment(dp.endDate).toDate(),
              label: `Dieta ${i + 1}`,
              color: "transparent",
              labelFontColor: "#000080",
              labelFontSize: 14,
              labelAlign: "near",
              showOnTop: true,
              labelFontWeight: "bold",
              labelBackgroundColor: "transparent",
            },
            {
              value: moment(dp.endDate).toDate(),
              color: "#000080",
              thickness: 4,
              showOnTop: true,
              lineDashType: "solid",
            }
          );
        });
      }

      this.setState({
        stripLines: resultStripLines,
      });
    } catch (error) {
      console.error(`Error on generate stripeslines. Error: ${error}`);
    }
  };

  getBreeds = async (groupId, farmId) => {
    try {
      const {
        data: { results: breeds },
      } = await getBreedIndexActive({
        groupId,
        farmId,
        withoutBreedCurveParam: true,
      });
      this.setState({ listOfBreeds: breeds });
    } catch (error) {
      this.setState({ listOfBreeds: [] });
    }
  };

  getCarbonFootprintParameters = async (groupId, farmId) => {
    try {
      const {
        data: { results: parameters },
      } = await getCarbonFootprintIndex({
        groupId,
        farmId,
      });
      this.setState({ carbonFootprintParameters: parameters });
    } catch (error) {
      this.setState({ carbonFootprintParameters: [] });
    }
  };

  getDietStrategyInfo = async (groupId, farmId, id) => {
    let result = null;
    try {
      const {
        data: { results: dietStrategy },
      } = await getDietStrategyShow({ groupId, farmId, id });

      result = dietStrategy;

      const endDateOfDietStrategy =
        dietStrategy.dietPeriods[dietStrategy.dietPeriods.length - 1].endDate;

      this.setState({
        formDietStrategy: { ...dietStrategy },
        editingDietStrategy: false,
        maximumDate: moment(endDateOfDietStrategy),
      });
    } catch (error) {
      this.openNotificationError(
        "Erro",
        "Não foi possível encontrar a Estratégia de Dieta"
      );
      this.setState({
        isLoading: false,
        loadingChart: true,
      });
    } finally {
      this.setState({
        isLoading: false,
        loadingChart: true,
      });
    }
    /* Creating curves */
    if (result != null) {
      if (!result.generic) {
        this.getPicketOfLot(groupId, farmId, result.lot.id);
        this.getLotAmountAnimals(groupId, farmId, result.lot.id);
        this.getLotAvgWeight(result.lot.id);
        this.getLotAvgSupplementConsumption(result.lot.id);

        /** Get Lot Real Curves */
        this.calculateLotRealCurvesForBaselineDietStrategy(
          groupId,
          farmId,
          result.lot.id
        );
      }
      /* Generate diet period curves */
      this.calculateDietPeriodsCurve(result.dietPeriods);
      /** Generate Gdp Curve */
      this.calculateGdpCurve(result.dietPeriods);
      this.chart.current.render();
    }
  };

  getPicketOfLot = async (groupId, farmId, lotId) => {
    try {
      const {
        data: { results: picketInfo },
      } = await getPicketByLotId({ groupId, farmId, lotId });
      this.setState({
        picketInfo,
      });
    } catch (error) {
      console.error(error);
    }
  };

  getLotAmountAnimals = async (groupId, farmId, lotId) => {
    try {
      const {
        data: { results: amountLotAnimals },
      } = await getLotStatistics({
        groupId,
        farmId,
        id: lotId,
        statistics: "AmountActiveAnimals",
      });
      this.setState({
        amountLotAnimals,
      });
    } catch (error) {
      console.error(error);
    }
  };

  getListOfPastures = async (groupId, farmId) => {
    try {
      const {
        data: { results: listOfPastures },
      } = await getPastureIndexDropDown({ groupId, farmId });
      listOfPastures.map((pasture) => {
        let arraySeasonOptions = [];
        pasture.seasons.forEach((season) => {
          if (
            season.cost > 0 &&
            season.cp > 0 &&
            season.tdn > 0 &&
            season.entryHeight > 0 &&
            season.exitHeight > 0 &&
            season.rest > 0 &&
            season.leafDensityProduction > 0 &&
            season.growthRate > 0 &&
            season.maximumConsumption > 0 &&
            season.minimumConsumption > 0
          ) {
            if (
              !arraySeasonOptions.find((aro) => season.season === aro.season)
            ) {
              arraySeasonOptions.push({
                id: pasture.id,
                season: season.season,
                optionsFertilized: [season.fertilized === true ? "Yes" : "No"],
              });
            } else {
              arraySeasonOptions = arraySeasonOptions.map((aro) => {
                if (aro.season === season.season) {
                  aro.optionsFertilized = ["Yes", "No"];
                }
                return aro;
              });
            }
          }
        });
        pasture.arraySeasonOptions = arraySeasonOptions;

        return pasture;
      });
      this.setState({
        listOfPastures: listOfPastures.filter(
          (p) => p.seasons.length > 0 && p.arraySeasonOptions.length > 0
        ),
      });
    } catch (error) {
      this.setState({
        listOfPastures: null,
      });
    }
  };

  getListOfSupplements = async (groupId, farmId) => {
    try {
      const {
        data: { results: listOfSupplements },
      } = await getSupplementIndexDropDown({ groupId, farmId });
      this.setState({
        listOfSupplements: listOfSupplements.filter((s) => s.cost != null),
      });
    } catch (error) {
      this.setState({
        listOfSupplements: null,
      });
    }
  };

  getListOfLots = async (groupId, farmId) => {
    this.setState({
      loadingLots: true,
    });
    try {
      const {
        data: { results: listOfLots },
      } = await getLotIndexDropDown({ groupId, farmId });
      const listFiltered = listOfLots.filter(
        (lot) =>
          (lot.status === "Active" || lot.status === "Pending") &&
          lot.receipt === false &&
          lot.currentAmountAnimals > 0 &&
          lot.asymptoticWeight != null &&
          (lot.breedCurveParam != null || lot.dietStrategyCurve != null)
      );
      this.setState({
        listOfLots: listFiltered,
        loadingLots: false,
      });
    } catch (error) {
      this.setState({
        listOfLots: null,
        loadingLots: false,
      });
    }
  };

  getSeasons = async (farmId) => {
    try {
      const {
        data: { results },
      } = await getWeatherSeason({ farmId });

      this.setState({
        seasons:
          results.farmSelectedTypeSeason === "Drier"
            ? results.seasonsDrier
            : results.farmSelectedTypeSeason === "Wetter"
            ? results.seasonsWetter
            : results.seasonsDefault,
      });
    } catch (error) {
      this.openNotificationError(
        "Erro",
        "Não foi possível encontrar a Estação climática para a fazenda selecionada."
      );
      this.setState({
        isLoading: false,
      });
    }
  };

  getListOfPickets = async (groupId, farmId, lotProfitCenterId = null) => {
    this.setState({
      isLoadingListOfPickets: true,
    });
    try {
      const {
        data: { results: listOfPickets },
      } = await getPicketIndexDropDown({ groupId, farmId });
      const listFiltered = listOfPickets.filter(
        (picket) =>
          picket.lotId === null && picket.profitCenterId === lotProfitCenterId
      );
      this.setState({
        listOfPickets: listFiltered,
        isLoadingListOfPickets: false,
      });
    } catch (error) {
      this.setState({
        listOfPickets: null,
        isLoadingListOfPickets: false,
      });
    }
  };

  getLotAvgWeight = async (lotId) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;
    try {
      const payload = {
        groupId,
        farmId,
        id: lotId,
        statistics: "AvgWeightActiveAnimals",
      };

      let weight = await getLotStatisticsAsync({ payload });
      this.setState({
        lotAvgWeight: weight.data.results,
      });
    } catch {
      this.setState({
        lotAvgWeight: 0,
      });
    }
  };

  getLotAvgSupplementConsumption = async (lotId) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;
    try {
      const {
        data: { results: avg },
      } = await getLotSupplementAverageConsumption({
        groupId,
        farmId,
        lotId,
      });
      this.setState({
        lotAvgSupplementConsumption: avg,
      });
    } catch (error) {}
  };

  getCarbonBaseKgAndCredit = async (groupId, farmId) => {
    try {
      const [
        {
          data: { results: carbonBaseKgParam },
        },
        {
          data: { results: carbonCreditParam },
        },
      ] = await Promise.all([
        getParameterItems({ groupId, farmId, code: 1006 }),
        getParameterItems({ groupId, farmId, code: 1007 }),
      ]);
      this.setState({
        carbonCredit: Number(carbonCreditParam?.value),
        carbonBaseKg: Number(carbonBaseKgParam?.value),
      });
    } catch (error) {
      this.setState({ carbonCredit: 0, carbonBaseKg: 0 });
    }
  };

  openConfirmation = () => {
    const {
      formDietStrategy: { dietPeriods },
    } = this.state;
    if (dietPeriods.length > 0) {
      this.handleSaveCompleteNewDietStrategy();
    } else {
      this.openNotificationError(
        "Estratégia Incompleta",
        "É necessário informar ao menos uma Dieta!"
      );
    }
  };

  openNotificationError = (title, message) => {
    notification["error"]({
      message: title,
      description: message,
    });
  };

  validateForm = (values, { setFieldError }) => {
    const { generic } = values;
    let haveSomeError = false;
    if (generic) {
      if (values.breedId == null) {
        setFieldError("breedId", "Erro");
        haveSomeError = true;
      }
      if (values.initialDate == null) {
        setFieldError("initialDate", "Erro");
        haveSomeError = true;
      }
      if (values.gender == null) {
        setFieldError("gender", "Erro");
        haveSomeError = true;
      }
      if (values.initialWeight == null || values.initialWeight === 0) {
        setFieldError("initialWeight", "Erro");
        haveSomeError = true;
      }
    } else {
      if (values.lot == null) {
        setFieldError("lot", "Erro");
        haveSomeError = true;
      } else {
        if (
          values.lot.breedId == null ||
          values.lot.gender == null ||
          values.lot.referenceAcquisitionDate == null ||
          values.lot.referenceAcquisitionWeight == null ||
          values.lot.referenceBirthdayDate == null
        ) {
          setFieldError("lot", "Erro");
          haveSomeError = true;
        }
      }
    }
    return haveSomeError;
  };

  handleSetLotStartDate = (value) => {
    this.setState({
      lotStartDate: value,
    });
  };

  /** Method to select lot */
  handleSelectLot = async (props, value) => {
    const { listOfLots } = this.state;
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;
    if (value == null) {
      props.setFieldValue("lot", null);
    } else {
      let lotSelected = listOfLots.find((lote) => lote.id === value);

      props.setFieldValue("lot", lotSelected);
      props.setFieldValue("breedId", lotSelected.breedId);
      props.setFieldValue("gender", lotSelected.gender);
      props.setFieldValue("cap", lotSelected.cap);

      this.getLotAvgWeight(value);

      //* handle lot picket allocation
      this.setState({
        lotId: value,
        allocatePicketVisible: lotSelected.picketId == null,
        picketId: null,
      });

      if (lotSelected.picketId == null) {
        try {
          this.getListOfPickets(groupId, farmId, lotSelected.profitCenterId);
        } catch (error) {}
      }
    }
    this.setState({
      lastPicketLotOfLot: null,
      lotStartDate: moment(),
    });
  };

  /** Method to get selected picket lot history info */
  handleSelectPicket = async (id) => {
    let lastPicketLotOfLot = null;
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;
    this.setState({
      isLoadingPicketHistoryInfo: true,
    });
    if (id !== null) {
      try {
        const {
          data: { results },
        } = await getPicketLotHistories({
          groupId,
          farmId,
          id,
        });
        if (results != null && results.length > 0) {
          lastPicketLotOfLot = results[results.length - 1];
        } else {
          lastPicketLotOfLot = null;
        }
      } catch (error) {}
    }
    this.setState({
      picketId: id,
      lastPicketLotOfLot,
      isLoadingPicketHistoryInfo: false,
    });
  };

  /** Method to allocate lot in picket */
  handleAllocateLotInPicket = async () => {
    const { picketId, lotId, lotStartDate } = this.state;
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      picketActions,
    } = this.props;

    try {
      if (lotId != null && picketId != null) {
        const body = {
          lotStartDate,
        };
        await picketActions.addLotToPicket(
          groupId,
          farmId,
          picketId,
          lotId,
          body
        );
        this.setState({
          allocatePicketVisible: false,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  /** Method to get the last date of season */
  handleGetLastDate = (startDate, startDay) => {
    const { seasons } = this.state;
    let result = null;
    let stopDoWhile = false;
    let endDay = startDay;
    let season = null;
    const seasonMap = seasons.map((s) => s.season);
    let i = 0;
    do {
      const currentMonth = Number(moment(startDate).add(i, "days").month());
      let loopSeason = seasonMap[currentMonth];
      if (season === null || season === loopSeason) {
        season = loopSeason;
        endDay += 1;
        i++;
      } else {
        stopDoWhile = true;
      }
    } while (!stopDoWhile);
    const endDate = moment(startDate).add(i - 1, "days");
    result = {
      endDate,
      endDay: endDay - 1,
    };
    return result;
  };

  /** Method to save only the DietStrategy data */
  handleSaveFirstFormDietStrategy = (values, actions) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;

    const { formDietStrategy: oldFormDietStrategy } = this.state;
    let newOrUpdateFormDietStrategy = null;

    /* If is creating a new DietStrategy, so call api to generate periods */
    if (this.state.formDietStrategy.id === null) {
      if (!this.validateForm(values, actions)) {
        try {
          newOrUpdateFormDietStrategy = {
            ...this.state.formDietStrategy,
            name: values?.name,
            lot: values?.lot,
            shouldUpdateLotAcquisitionData:
              values?.shouldUpdateLotAcquisitionData || false,
            generic: values?.generic,
            breedId: values?.breedId,
            cap: values?.cap,
            gender: values?.gender,
            initialDate: values?.initialDate,
            initialWeight: values?.initialWeight,
            useEstablishedCurve: false,
          };
          this.setState({
            formDietStrategy: newOrUpdateFormDietStrategy,
            editingDietStrategy: false,
            maximumDate: newOrUpdateFormDietStrategy?.generic
              ? moment(newOrUpdateFormDietStrategy?.initialDate).add(
                  36,
                  "months"
                )
              : moment(
                  newOrUpdateFormDietStrategy?.lot.referenceBirthdayDate
                ).add(36, "months"),
          });
          /* Calculate curve for lot and diet periods if is new values? or change lot */
          if (
            !newOrUpdateFormDietStrategy?.generic &&
            oldFormDietStrategy.lot === null &&
            oldFormDietStrategy.lot !== newOrUpdateFormDietStrategy?.lot
          ) {
            this.setState({
              loadingChart: true,
            });
            this.getPicketOfLot(
              groupId,
              farmId,
              newOrUpdateFormDietStrategy?.lot.id
            );
            this.getLotAmountAnimals(
              groupId,
              farmId,
              newOrUpdateFormDietStrategy?.lot.id
            );

            /** Get Lot Avg Supplement Consumption */
            this.getLotAvgSupplementConsumption(
              newOrUpdateFormDietStrategy?.lot.id
            );
            /** Get Lot Real Curves */
            this.calculateLotRealCurvesForBaselineDietStrategy(
              groupId,
              farmId,
              newOrUpdateFormDietStrategy?.lot.id
            );
          }
          /** Build the diet periods curve */
          this.calculateDietPeriodsCurve();
          /** Build the diet periods gdp curve */
          this.calculateGdpCurve();
          /* Generate stripLines */
          this.generateStripLines(newOrUpdateFormDietStrategy?.lot);
        } catch (error) {}
      }
    } else {
      if (!this.validateForm(values, actions)) {
        newOrUpdateFormDietStrategy = {
          ...this.state.formDietStrategy,
          name: values?.name,
          lot: values?.lot,
          shouldUpdateLotAcquisitionData:
            values?.shouldUpdateLotAcquisitionData || false,
          generic: values?.generic,
          breedId: values?.breedId,
          cap: values?.cap,
          gender: values?.gender,
          initialDate: values?.initialDate,
          initialWeight: values?.initialWeight,
          useEstablishedCurve: false,
        };
        this.setState({
          formDietStrategy: newOrUpdateFormDietStrategy,
          editingDietStrategy: false,
          maximumDate: newOrUpdateFormDietStrategy?.generic
            ? moment(newOrUpdateFormDietStrategy?.initialDate).add(36, "months")
            : moment(
                newOrUpdateFormDietStrategy?.lot.referenceBirthdayDate
              ).add(36, "months"),
        });
      }
    }

    // If a some generic diet strategy change breed or initial weight value or cap or gender, recalculate gdp of all periods
    if (
      newOrUpdateFormDietStrategy?.dietPeriods.length > 0 &&
      newOrUpdateFormDietStrategy?.generic &&
      (newOrUpdateFormDietStrategy?.breedId !== oldFormDietStrategy?.breedId ||
        newOrUpdateFormDietStrategy?.initialWeight !==
          oldFormDietStrategy?.initialWeight ||
        newOrUpdateFormDietStrategy?.cap !== oldFormDietStrategy?.cap ||
        newOrUpdateFormDietStrategy?.gender !== oldFormDietStrategy?.gender)
    ) {
      this.handleRecalculateAllPeriodsGdp(
        newOrUpdateFormDietStrategy?.dietPeriods,
        newOrUpdateFormDietStrategy?.generic,
        newOrUpdateFormDietStrategy?.breedId,
        newOrUpdateFormDietStrategy?.cap,
        newOrUpdateFormDietStrategy?.gender,
        newOrUpdateFormDietStrategy?.initialWeight,
        newOrUpdateFormDietStrategy?.initialDate,
        newOrUpdateFormDietStrategy?.initialWeight !==
          oldFormDietStrategy?.initialWeight
      );
    }
  };

  /** Method to save the complete dietStrategy */
  handleSaveCompleteNewDietStrategy = async () => {
    const {
      app: {
        translation,
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      history,
    } = this.props;

    const { formDietStrategy } = this.state;

    this.setState({
      isLoading: true,
      shouldBlockNavigation: false,
    });

    const body = {
      ...formDietStrategy,
      useEstablishedCurve: false,
    };

    try {
      /* Validate all periods required fields */
      if (formDietStrategy.id === null) {
        if (body.generic === false) {
          const lotId = body.lot.id;
          const {
            data: { results: lotData },
          } = await getLotShow({
            groupId,
            farmId,
            id: lotId,
          });
          if (lotData.dietStrategies.length > 0) {
            notification.warning({
              message: "Atenção",
              description:
                "O Lote informado já possui uma Estratégia de Dieta associada. A Estratégia a ser criada, não ficará associada ao Lote selecionado, caso deseje alterar, altere no cadastro do Lote.",
            });
          }
        }
        /* If is creating a new  */
        await saveDietStrategy({
          groupId,
          farmId,
          dietStrategy: body,
        });
      } else {
        /* If is editing */
        await saveDietStrategy({
          groupId,
          farmId,
          dietStrategy: body,
          id: body.id,
        });
      }
      this.setState(
        {
          isLoading: false,
        },
        () => {
          notification.success({
            message:
              formDietStrategy.id === null
                ? translation.dietStrategy.new.notification.success.message
                : "Estratégia de dieta editada!",
            description:
              formDietStrategy.id === null
                ? translation.dietStrategy.new.notification.success.description
                : "A estratégia foi atualizada no sistema.",
          });
          history.push("/admin/managements?tabSelect=1");
        }
      );
    } catch (error) {
      this.setState({
        isLoading: false,
        shouldBlockNavigation: true,
      });
      if (body.id === null) {
        this.openNotificationError(
          "Ops...",
          "Houve algum erro ao criar a estratégia de dieta !"
        );
      } else {
        this.openNotificationError(
          "Ops...",
          "Houve algum erro ao criar a estratégia de dieta !"
        );
      }
    }
  };

  /** Method to select period when user click in box */
  handleSelectPeriod = (index) => {
    const { dietStrategyActions } = this.props;
    const {
      formDietStrategy: { dietPeriods, lot, generic, initialDate },
      carbonFootprintParameters,
    } = this.state;
    let startDateDeadline = null;

    /** Update the startDateDeadline  */
    if (dietPeriods.length > 0) {
      if (dietPeriods.find((ds, i) => i === index - 1)) {
        startDateDeadline = moment(
          dietPeriods.find((ds, i) => i === index - 1).startDate
        )
          .add(2, "days")
          .format("YYYY-MM-DD");
      } else {
        startDateDeadline = generic
          ? moment(initialDate).format("YYYY-MM-DD")
          : moment(lot.referenceAcquisitionDate).format("YYYY-MM-DD");
      }
    }

    // Get period
    let period = dietPeriods.find((ds, i) => {
      if (index === i) {
        // if find period the carcassHarnessing will receive the carcassHarnessing value from the first period
        ds.carcassHarnessing = dietPeriods[0].carcassHarnessing;
        return ds;
      } else {
        return null;
      }
    });

    // Verify carbon
    if (
      Object.keys(period).includes("carbonFootprintParameter") &&
      period.carbonFootprintParameter === null
    ) {
      const carbonFootprintParameter = classifyPeriodCarbonFootPrint(
        carbonFootprintParameters,
        period
      );
      if (carbonFootprintParameter) {
        period = {
          ...period,
          carbonFootprintParameter,
        };
      }
    }

    dietStrategyActions.selectDietStrategyPeriod(period);
    this.setState({
      dietPeriodSelected: period,
      dietPeriodIndexSelected: index,
      modalVisible: true,
      startDateDeadline,
    });
  };

  /** Method to create a period */
  handleCreatePeriod = async () => {
    /* Variables - start */
    const { dietStrategyActions } = this.props;
    const {
      formDietStrategy: { lot, generic, initialDate, dietPeriods },
      seasons,
    } = this.state;

    const baseDate = generic ? initialDate : lot.referenceAcquisitionDate;

    let startDateDeadline = null;

    let datesAndGdp = null;

    let startDate = null;
    let endDate = null;
    let startDay = null;
    let endDay = null;

    let index = null;

    /* Variables - end */

    /** Update the startDateDeadline  */
    if (dietPeriods.length > 1) {
      if (dietPeriods.find((ds, i) => i === dietPeriods.length - 2)) {
        startDateDeadline = moment(
          dietPeriods.find((ds, i) => i === dietPeriods.length - 2).startDate
        )
          .add(2, "days")
          .format("YYYY-MM-DD");
      }
    } else if (dietPeriods.length === 1) {
      startDateDeadline = moment(dietPeriods.find((ds, i) => i === 0).startDate)
        .add(2, "days")
        .format("YYYY-MM-DD");
    } else {
      startDateDeadline = moment(baseDate).format("YYYY-MM-DD");
    }

    /** Calculate the start date and end date */
    if (dietPeriods.length > 0) {
      index = dietPeriods.length;
      const lastPeriod = dietPeriods.find(
        (p, i) => i === dietPeriods.length - 1
      );
      startDate = moment(lastPeriod.endDate)
        .add(1, "days")
        .format("YYYY-MM-DD");
      startDay = lastPeriod.endDay + 1;
    } else {
      index = 0;
      startDate = baseDate;
      startDay = generic
        ? 0
        : moment(baseDate).diff(lot.referenceBirthdayDate, "days", true);
    }
    const { endDate: rEndDate, endDay: rEndDay } = this.handleGetLastDate(
      startDate,
      startDay
    );
    endDate = rEndDate;
    endDay = Math.round(rEndDay);

    /** Calcualte gdp */
    let gdpExpectation = 0;

    /** Update object datesAndGdp */
    datesAndGdp = {
      startDate,
      startDay,
      endDate,
      endDay,
      gdpExpectation,
    };

    /** Get the season or seasons for the new period */
    const seasonMap = seasons.map((s) => s.season);
    let season = [];
    let i = startDay + 1;
    do {
      const currentMonth = generic
        ? Number(moment(initialDate).add(i, "days").month())
        : Number(moment(lot.referenceBirthdayDate).add(i, "days").month());
      let loopSeason = seasonMap[currentMonth];
      if (!season.includes(loopSeason)) {
        season.push(loopSeason);
      }
      i++;
    } while (i < endDay);

    let carcassHarnessing = 0;

    if (index === 0) {
      carcassHarnessing = generic ? null : lot.carcassHarnessing;
    } else {
      carcassHarnessing = dietPeriods[0].carcassHarnessing;
    }

    /** Mount the diet period form */
    const dietPeriodSelected = {
      id: null,
      pasture: null,
      season: season.join(","),
      productionSubSystem: null,
      pastureSeason: null,
      pastureFertilized: null,
      pastureDailyCost: null,
      supplement: null,
      supplementVersion: null,
      supplementConsumptionMeasureType: "G_ANIMAL",
      supplementConsumptionByAnimal: null,
      supplementConsumptionType: "Pure",
      supplementDailyCost: null,
      ...datesAndGdp,
      carcassHarnessing,
      completed: false,
      conflictedGdp: false,
    };
    /** Set in redux the new period */
    dietStrategyActions.selectDietStrategyPeriod(dietPeriodSelected);
    /** Update the state */
    this.setState({
      dietPeriodSelected,
      dietPeriodIndexSelected: index,
      modalVisible: true,
      startDateDeadline:
        startDateDeadline === null ? startDate : startDateDeadline,
    });
  };

  /** Method to enable or disable form to edit DietStrategy info */
  handleEditFirstForm = () => {
    this.setState({
      shouldBlockFieldsOnEdit: true,
      editingDietStrategy: true,
    });
  };

  /** Method to recalculate periods gdp */
  handleRecalculateAllPeriodsGdp = async (
    periods,
    isGeneric,
    breedId,
    cap,
    gender,
    pInitialWeight,
    pInitialDate,
    changeInitialWeight = false
  ) => {
    let index = 0;
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;

    let initialDate = pInitialDate;
    let initialWeight = pInitialWeight;
    let havePeriodsWithConflict = 0;

    try {
      this.setState({
        isRecalculateGdpPeriods: true,
      });

      let pDietPeriodCurveDatesAndWeights = [];

      // If change initial weight recalculate curve
      if (changeInitialWeight) {
        // Get the reference date
        const dateOfReference = moment(periods[0].startDate).subtract(
          periods[0].startDay,
          "days"
        );
        periods.forEach((dp) => {
          let i = dp.startDay;
          let j = 0;
          do {
            const isFirstValue = moment(dateOfReference)
              .add(i, "days")
              .isSame(moment(initialDate), "days", "[]");
            const x = moment(dateOfReference).add(i, "days").toDate();
            let y = 0;
            if (isFirstValue) {
              y = initialWeight;
            } else {
              if (dp?.allWeightGains?.length > 0) {
                const weightGainAPI = dp.allWeightGains[j];
                if (weightGainAPI) {
                  y = initialWeight += weightGainAPI.gdp;
                } else {
                  y = initialWeight += dp.gdpExpectation;
                }
              } else {
                y = initialWeight += dp.gdpExpectation;
              }
            }
            pDietPeriodCurveDatesAndWeights.push({
              x,
              y,
              toolTipContent: `<span style='"'color: {color};'"'>{name}:</span> {x} | {y}`,
            });
            i++;
            j++;
          } while (i <= dp.endDay);
        });
      }
      // For each period recalculate gdp
      for (const period of periods) {
        try {
          const body = this.handleCreateRequestToValidateGDP(
            period,
            isGeneric,
            breedId,
            cap,
            gender,
            index === 0 ? pInitialWeight : null,
            pDietPeriodCurveDatesAndWeights
          );
          if (body != null) {
            try {
              let {
                data: { results: result },
              } = await callValidationGdp({
                groupId,
                farmId,
                lotId: null,
                breedId: breedId,
                body,
              });
              if (result != null) {
                const { code, allWeightGains, carbonFootPrint } = result;
                let conflictedGdp = false;
                // Verify by the result's code if has a conflict
                switch (code) {
                  case "2.2":
                  case "4.1":
                  case "4.2":
                    conflictedGdp = false;
                    break;
                  case "2.1":
                  case "2.3":
                  case "2.4":
                  case "4.3":
                  case "4.4":
                    conflictedGdp = true;
                    break;
                  default:
                    break;
                }
                let dietPeriodAllWeightGains = [];
                let dietPeriodCarbonFootPrint = [];
                // Populate dietPeriodAllWeightGains
                if (allWeightGains?.length > 0) {
                  let loopStartDay = period.startDay;
                  allWeightGains.forEach((gdp, i) => {
                    loopStartDay = i === 0 ? loopStartDay : loopStartDay + 1;
                    dietPeriodAllWeightGains.push({
                      gdp,
                      gdpDate:
                        i === 0
                          ? moment(period.startDate)
                          : moment(period.startDate).add(i, "days"),
                      gdpDay: loopStartDay,
                    });
                  });
                }
                // Populate dietPeriodCarbonFootPrint
                if (carbonFootPrint?.length > 0) {
                  let loopStartDay = period.startDay;
                  carbonFootPrint.forEach((carbon, i) => {
                    loopStartDay = i === 0 ? loopStartDay : loopStartDay + 1;
                    dietPeriodCarbonFootPrint.push({
                      carbon,
                      carbonDate:
                        i === 0
                          ? moment(period.startDate)
                          : moment(period.startDate).add(i, "days"),
                      carbonDay: loopStartDay,
                    });
                  });
                }
                // If conflited increase variable havePeriodsWithConflict
                if (conflictedGdp) {
                  havePeriodsWithConflict++;
                }
                // Set new values in period
                const newPeriod = {
                  ...period,
                  gdpPossible: result.weightGain,
                  pastureConsumed: result.pasture?.Consumed,
                  pastureNeeded: result.pasture?.Need,
                  supplementConsumed: result.trough?.Consumed,
                  supplementNeeded: result.trough?.Need,
                  tdnExceededOrMissing: result.tdnExcededOrMissing,
                  tdnExceededOrMissingValue: result.tdnExcededOrMissingValue,
                  cpExceededOrMissing: result.cpExcededOrMissing,
                  cpExceededOrMissingValue: result.cpExcededOrMissingValue,
                  balancePriceCarbonFootprint:
                    result.balancePriceCarbonFootprint,
                  balanceCarbonFootprint: result.balanceCarbonFootprint,
                  conflictedGdp,
                  allWeightGains: dietPeriodAllWeightGains,
                  carbonFootPrint: dietPeriodCarbonFootPrint,
                };
                periods[index] = newPeriod;
              } else {
                const newPeriod = {
                  ...period,
                  gdpPossible: null,
                  pastureConsumed: null,
                  pastureNeeded: null,
                  supplementConsumed: null,
                  supplementNeeded: null,
                  tdnExceededOrMissing: null,
                  tdnExceededOrMissingValue: null,
                  cpExceededOrMissing: null,
                  cpExceededOrMissingValue: null,
                  balancePriceCarbonFootprint: null,
                  balanceCarbonFootprint: null,
                  conflictedGdp: true,
                  allWeightGains: [],
                  carbonFootPrint: [],
                };
                havePeriodsWithConflict++;
                periods[index] = newPeriod;
              }
            } catch (error) {
              const newPeriod = {
                ...period,
                gdpPossible: null,
                pastureConsumed: null,
                pastureNeeded: null,
                supplementConsumed: null,
                supplementNeeded: null,
                tdnExceededOrMissing: null,
                tdnExceededOrMissingValue: null,
                cpExceededOrMissing: null,
                cpExceededOrMissingValue: null,
                balancePriceCarbonFootprint: null,
                balanceCarbonFootprint: null,
                conflictedGdp: true,
                allWeightGains: [],
                carbonFootPrint: [],
              };
              havePeriodsWithConflict++;
              periods[index] = newPeriod;
            }
          }
          index++;
        } catch (error) {
          throw new Error(error);
        }
      }
      // If have conflicts alert user
      if (havePeriodsWithConflict > 0) {
        Modal.error({
          title: "Alerta",
          centered: true,
          content:
            "Alguns períodos tiveram conflitos referente ao GDP experado. Será necessário revisá-los.",
        });
      }
      // Save new Periods
      this.setState({
        formDietStrategy: {
          ...this.state.formDietStrategy,
          dietPeriods: periods,
        },
      });
      // Recalculate chart
      this.calculateDietPeriodsCurve(periods);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({
        isRecalculateGdpPeriods: false,
      });
    }
  };

  /** Method to create request to validate gdp */
  handleCreateRequestToValidateGDP = (
    period,
    isGeneric,
    breedId,
    cap,
    gender,
    initialWeight,
    pDietPeriodCurveDatesAndWeights
  ) => {
    try {
      const {
        carbonBaseKg,
        carbonCredit,
        listOfBreeds: breeds,
        dietPeriodCurveDatesAndWeights,
      } = this.state;

      const dateAndWeights =
        pDietPeriodCurveDatesAndWeights.length > 0
          ? pDietPeriodCurveDatesAndWeights
          : dietPeriodCurveDatesAndWeights;

      // Set Weight
      const weightDateIn =
        initialWeight !== null
          ? initialWeight
          : dateAndWeights.find((d) => {
              if (moment(d.x).isSame(moment(period.startDate), "days", "[]")) {
                return true;
              } else {
                return false;
              }
            })?.y;

      let body = [];
      let bodyItem = {
        ExpectedWeightGain: period.gdpExpectation,
        CO2BaseKg_KgPV: carbonBaseKg || 0,
        CarbonCredit: carbonCredit || 0,
        System:
          period.productionSubSystem === "Extensive"
            ? "Extensivo"
            : period.productionSubSystem === "SemiIntensive"
            ? "Semi-Intensivo"
            : period.productionSubSystem === "Intensive"
            ? "Intensivo"
            : null,
      };
      let FirstWeightOnDiet = weightDateIn;
      /** Create DietDetails - Start */
      let DietDetails = [];
      let diet = {
        Begin: 0,
        End:
          moment(period.endDate).diff(moment(period.startDate), "days", true) +
          1, // Get difference in days between startDate and endDate. Add 1 to include the endDate
        CO2Kg_KgPV: period?.carbonFootprintParameter?.co2ProductionMean,
      };
      /** Create pasture DTO */
      let Pasture = {};
      if (
        period?.pasture != null &&
        period?.productionSubSystem !== "Intesive"
      ) {
        let pastureSeason = period.pasture.seasons.find(
          (s) =>
            s.season === period.pastureSeason &&
            s.fertilized === period.pastureFertilized
        );
        Pasture = {
          ...Pasture,
          id: period.pasture.id,
          NDT: pastureSeason?.tdn,
          PB: pastureSeason?.cp,
          FDN: pastureSeason?.fdn / 100,
          ConsumptionUnit:
            pastureSeason?.consumptionType === "PV_ANIMAL"
              ? "PV"
              : pastureSeason?.consumptionType === "KG"
              ? "g"
              : null,
          MaxConsumption:
            pastureSeason?.consumptionType === "PV_ANIMAL"
              ? pastureSeason?.maximumConsumption / 100
              : pastureSeason?.consumptionType === "KG"
              ? pastureSeason?.maximumConsumption * 1000
              : pastureSeason?.maximumConsumption,
          MinConsumption:
            pastureSeason?.consumptionType === "PV_ANIMAL"
              ? pastureSeason?.minimumConsumption / 100
              : pastureSeason?.consumptionType === "KG"
              ? pastureSeason?.minimumConsumption * 1000
              : pastureSeason?.minimumConsumption,
        };
      } else {
        Pasture = null;
      }
      /** Create Supplement DTO */
      let Trough = {};
      if (period.supplement != null) {
        Trough = {
          ...Trough,
          id: period.supplement?.id,
          NDT: period.supplement?.tdn,
          PB: period.supplement?.cp,
          DryMatterPercentage:
            period.supplement?.dryMatterPercentage == null
              ? 1
              : period.supplement?.dryMatterPercentage / 100,
          ConsumptionUnit:
            period?.supplementConsumptionMeasureType === "PV_ANIMAL"
              ? "PV"
              : period?.supplementConsumptionMeasureType === "I_MS_PV_ANIMAL"
              ? "I_MS_PV"
              : period?.supplementConsumptionMeasureType === "G_KG_PV_ANIMAL"
              ? "g_kg_pv"
              : period?.supplementConsumptionMeasureType === "G_ANIMAL"
              ? "g"
              : period?.supplementConsumptionMeasureType === "KG_ANIMAL"
              ? "g"
              : null,
          Consumption:
            period?.supplementConsumptionMeasureType === "PV_ANIMAL"
              ? period?.supplementConsumptionByAnimal / 100
              : period?.supplementConsumptionMeasureType === "I_MS_PV_ANIMAL"
              ? period?.supplementConsumptionByAnimal / 100
              : period?.supplementConsumptionMeasureType === "G_KG_PV_ANIMAL"
              ? period?.supplementConsumptionByAnimal
              : period?.supplementConsumptionMeasureType === "G_ANIMAL"
              ? period?.supplementConsumptionByAnimal
              : period?.supplementConsumptionMeasureType === "KG_ANIMAL"
              ? period?.supplementConsumptionByAnimal * 1000
              : period?.supplementConsumptionByAnimal,
          MaxConsumption:
            period.supplement.voluntaryConsumptionType === "PV_ANIMAL"
              ? period.supplement?.maximumVoluntaryConsumption / 100
              : period.supplement.voluntaryConsumptionType === "I_MS_PV_ANIMAL"
              ? period.supplement?.maximumVoluntaryConsumption / 100
              : period.supplement.voluntaryConsumptionType === "KG_ANIMAL"
              ? period.supplement?.maximumVoluntaryConsumption * 1000
              : period.supplement?.maximumVoluntaryConsumption,
          MinConsumption:
            period.supplement.voluntaryConsumptionType === "PV_ANIMAL"
              ? period.supplement?.minimumVoluntaryConsumption / 100
              : period.supplement.voluntaryConsumptionType === "I_MS_PV_ANIMAL"
              ? period.supplement?.minimumVoluntaryConsumption / 100
              : period.supplement.voluntaryConsumptionType === "KG_ANIMAL"
              ? period.supplement?.minimumVoluntaryConsumption * 1000
              : period.supplement?.minimumVoluntaryConsumption,
          MinMaxUnit:
            period.supplement?.voluntaryConsumptionType !== null
              ? period.supplement?.voluntaryConsumptionType === "G_ANIMAL"
                ? "g"
                : period.supplement?.voluntaryConsumptionType ===
                  "G_KG_PV_ANIMAL"
                ? "g_kg_pv"
                : period.supplement?.voluntaryConsumptionType === "KG_ANIMAL"
                ? "g"
                : period.supplement.voluntaryConsumptionType === "PV_ANIMAL"
                ? "PV"
                : period.supplement.voluntaryConsumptionType ===
                  "I_MS_PV_ANIMAL"
                ? "I_MS_PV"
                : "g"
              : "g",
        };
      } else {
        Trough = null;
      }

      let SexualClass = null;
      let GeneticGroup = null;

      if (isGeneric) {
        const breed = breeds.find((b) => b.id === breedId);
        GeneticGroup = breed.geneticGroup;
        SexualClass =
          gender === "Male" && cap
            ? "MC"
            : gender === "Male" && !cap
            ? "MNC"
            : "F";
      }

      diet = {
        ...diet,
        Pasture,
        Trough,
      };

      DietDetails = [...DietDetails, diet];

      /** Create DietDetails - End */
      bodyItem = {
        ...bodyItem,
        GeneticGroup,
        SexualClass,
        FirstWeightOnDiet,
        DietDetails,
      };
      body = [...body, bodyItem];
      return { Diet: body };
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  /* Modal methods - Start */
  handleModalActions = async (data = null, action = "close") => {
    /* Variables - start */

    const { dietStrategyActions } = this.props;
    const {
      formDietStrategy: { dietPeriods, lot, generic, initialDate },
      dietPeriodIndexSelected,
    } = this.state;

    const index = dietPeriodIndexSelected;

    let newDietPeriods = null;

    let startDateDeadline = null;

    /* Variables - end */

    if (data === null) {
      dietStrategyActions.selectDietStrategyPeriod(null);
      this.setState({
        modalVisible: false,
        dietPeriodSelected: null,
        dietPeriodIndexSelected: null,
        startDateDeadline: null,
      });
    } else {
      /* If action remove */
      if (action === "remove") {
        /* Update the dietPeriods */
        newDietPeriods =
          dietPeriods.length > 0 && dietPeriods.find((dp, i) => i === index)
            ? dietPeriods.filter((dp, i) => i !== index)
            : dietPeriods;

        /* Deselect in Redux */
        dietStrategyActions.selectDietStrategyPeriod(null);
        /* Update the state */
        this.setState({
          formDietStrategy: {
            ...this.state.formDietStrategy,
            dietPeriods: newDietPeriods,
          },
          modalVisible: false,
          dietPeriodSelected: null,
          dietPeriodIndexSelected: null,
          startDateDeadline: null,
        });
      } else if (
        action === "nextWithoutSave" ||
        action === "previousWithoutSave"
      ) {
        /* Update the DietPeriods */
        const updatedIndex =
          action === "nextWithoutSave"
            ? index + 1
            : action === "previousWithoutSave" && index - 1;
        this.handleSelectPeriod(updatedIndex);
      } else {
        let dietStrategyUpdated = data;
        /* Update the DietPeriods */
        newDietPeriods =
          dietPeriods.length > 0 && dietPeriods.find((dp, i) => i === index)
            ? dietPeriods.map((dp, i) =>
                i === index ? dietStrategyUpdated : dp
              )
            : [...dietPeriods, dietStrategyUpdated];

        /* Update other periods */
        if (newDietPeriods.length > 1) {
          /** Change next period */
          if (newDietPeriods.find((ds, i) => i === index + 1)) {
            newDietPeriods = this.handelChangeNextOrPreviusPeriod(
              data.startDay,
              data.startDate,
              data.endDay,
              data.endDate,
              index + 1,
              "next",
              newDietPeriods
            );
          }
          /** Change previous period */
          if (newDietPeriods.find((ds, i) => i === index - 1)) {
            newDietPeriods = this.handelChangeNextOrPreviusPeriod(
              data.startDay,
              data.startDate,
              data.endDay,
              data.endDate,
              index - 1,
              "previous",
              newDietPeriods
            );
          }

          /** If is updating the first period, update other period's carcassHarnessing*/
          if (index === 0) {
            newDietPeriods = newDietPeriods.map((ds, i) => {
              ds.carcassHarnessing = dietStrategyUpdated.carcassHarnessing;
              return ds;
            });
          }
        }

        /* Finalize/Save Action Or Next/Previous Action */
        if (action === "finalize") {
          dietStrategyActions.selectDietStrategyPeriod(null);
          this.setState({
            formDietStrategy: {
              ...this.state.formDietStrategy,
              dietPeriods: newDietPeriods,
            },
            modalVisible: false,
            dietPeriodSelected: null,
            dietPeriodIndexSelected: null,
          });
        } else if (action === "next" || action === "previous") {
          const updatedIndex =
            action === "next" ? index + 1 : action === "previous" && index - 1;
          dietStrategyActions.selectDietStrategyPeriod(
            newDietPeriods.find((ds, i) => updatedIndex === i)
          );
          if (newDietPeriods.length > 0) {
            if (newDietPeriods.find((ds, i) => i === updatedIndex - 1)) {
              startDateDeadline = moment(
                newDietPeriods.find((ds, i) => i === updatedIndex - 1).startDate
              )
                .add(2, "days")
                .format("YYYY-MM-DD");
            } else {
              startDateDeadline = generic
                ? moment(initialDate).format("YYYY-MM-DD")
                : moment(lot.referenceAcquisitionDate).format("YYYY-MM-DD");
            }
          }
          this.setState({
            formDietStrategy: {
              ...this.state.formDietStrategy,
              dietPeriods: newDietPeriods,
            },
            dietPeriodSelected: dietPeriods.find((ds, i) => updatedIndex === i),
            dietPeriodIndexSelected: updatedIndex,
            startDateDeadline,
          });
        }
        /** Recalculate Maximum date */
        const endDietPeriodsDate =
          newDietPeriods[newDietPeriods.length - 1].endDate;

        const newMaximumDate = generic
          ? moment(initialDate)
              .add(36, "months")
              .isBefore(moment(endDietPeriodsDate), "days")
            ? moment(endDietPeriodsDate).add(2, "months")
            : moment(initialDate).add(36, "months")
          : moment(lot.referenceBirthdayDate)
              .add(36, "months")
              .isBefore(moment(endDietPeriodsDate), "days")
          ? moment(endDietPeriodsDate).add(2, "months")
          : moment(lot.referenceBirthdayDate).add(36, "months");

        this.setState({
          maximumDate: newMaximumDate,
        });
      }
      if (!(action === "nextWithoutSave" || action === "previousWithoutSave")) {
        /* Recalculate DietPeriodsCurve */
        const newDataChart = this.calculateDietPeriodsCurve(newDietPeriods);
        /** Recalculate GDP */
        this.calculateGdpCurve(newDietPeriods, newDataChart);
      }
    }
  };

  handelChangeNextOrPreviusPeriod = (
    currentStartDay,
    currentStartDate,
    currentEndDay,
    currentEndDate,
    index,
    type,
    dietPeriods
  ) => {
    const {
      formDietStrategy: { lot, generic, initialDate },
      seasons,
    } = this.state;

    let newDietPeriods = null;
    let updatedPeriod = dietPeriods.find((dp, i) => i === index);

    let startDate = null;
    let endDate = null;
    let startDay = null;
    let endDay = null;
    let gdp = null;

    /* Manipulate the dates and days */

    if (type === "next") {
      startDate = moment(currentEndDate).add(1, "days").format("YYYY-MM-DD");
      startDay = currentEndDay + 1;
      endDate = updatedPeriod.endDate;
      endDay = updatedPeriod.endDay;
    } else if (type === "previous") {
      startDate = updatedPeriod.startDate;
      startDay = updatedPeriod.startDay;
      endDate = moment(currentStartDate)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      endDay = currentStartDay - 1;
    }

    /* Recalculate gdp */
    gdp = updatedPeriod.gdpExpectation;

    /* Recalculate season */
    let i = startDay + 1;
    let season = [];

    do {
      const currentMonth = generic
        ? Number(moment(initialDate).add(i, "days").month())
        : Number(moment(lot.referenceBirthdayDate).add(i, "days").month());
      let loopSeason = seasons.find((s) => s.month === currentMonth + 1).season;

      if (!season.includes(loopSeason)) {
        season.push(loopSeason);
      }

      i++;
    } while (i < endDay);

    /* Update the period (previous or next) */
    updatedPeriod = {
      ...updatedPeriod,
      startDate,
      endDate,
      startDay,
      endDay,
      gdpExpectation: gdp,
      season: season.join(","),
    };

    /* Update dietPeriods */
    newDietPeriods = dietPeriods.map((ds, i) =>
      i === index ? updatedPeriod : ds
    );

    return newDietPeriods;
  };

  handleCancel = () => {
    this.setState(
      {
        shouldBlockNavigation: false,
      },
      () => {
        this.props.history.goBack();
      }
    );
  };
  /* Modal methods - End */

  render() {
    const {
      app: { translation },
      history,
    } = this.props;

    const {
      shouldBlockFieldsOnEdit,
      isRecalculateGdpPeriods,
      isLoading,
      stripLines,
      dataChart,
      formDietStrategy,
      modalVisible,
      dietPeriodIndexSelected,
      seasons,
      editingDietStrategy,
      listOfBreeds: breeds,
      listOfLots,
      listOfPickets,
      listOfPastures,
      listOfSupplements,
      loadingLots,
      loadingChart,
      startDateDeadline,
      lotAvgWeight,
      dietPeriodCurveDatesAndWeights,
      picketInfo,
      amountLotAnimals,
      allocatePicketVisible,
      picketId,
      isLoadingListOfPickets,
      lastPicketLotOfLot,
      lotStartDate,
      isLoadingPicketHistoryInfo,
      shouldBlockNavigation,
      lotRealGdpCurveDatesAndWeights,
      lotAvgSupplementConsumption,
      maximumDate,
      carbonFootprintParameters,
      carbonBaseKg,
      carbonCredit,
    } = this.state;

    const { CanvasJSChart } = CanvasJSReact;

    /* Chart options */

    const toggleDataSeries = (e) => {
      if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
        e.dataSeries.visible = false;
      } else {
        e.dataSeries.visible = true;
      }
      e.chart.render();
    };

    const options = {
      animationEnabled: true,
      zoomEnabled: true,
      height: 340,
      axisX: {
        labelFontSize: 12,
        valueFormatString: "MM/YY",
        interval: 7,
        intervalType: "month",

        maximum: maximumDate.toDate(),
        crosshair: {
          enabled: true,
        },
        stripLines: stripLines,
      },
      toolTip: {
        fontFamily: "Asap",
        shared: true,
      },
      axisY: {
        labelFontSize: 12,
        includeZero: true,
        valueFormatString: "###0.##",
        suffix: "kg",
        gridDashType: "dot",
        crosshair: {
          enabled: true,
        },
        stripLines:
          formDietStrategy.lot != null
            ? [
                {
                  value: formDietStrategy.lot?.asymptoticWeight,
                  color: "#f53030",
                  thickness: 3,
                  showOnTop: true,
                  label: "Peso assintótico",
                  labelFontColor: "#f53030",
                  labelFontWeight: "bold",
                },
              ]
            : [],
      },
      axisY2: {
        lineColor: "#106518",
        tickColor: "#106518",
        labelFontColor: "#106518",
        labelFontSize: 12,
        includeZero: true,
        valueFormatString: "###0.##",
        suffix: "kg",
        gridDashType: "dot",
      },
      legend: {
        cursor: "pointer",
        itemclick: toggleDataSeries,
      },
      data: dataChart,
    };

    return (
      <>
        <RoutingLeavingGuardModal
          when={shouldBlockNavigation}
          navigate={(path) => history.push(path)}
          message="Parece que você está saindo sem salvar a estratégia de dieta."
        />
        <Container>
          {isLoading && <Loading />}
          {/* Header and BreadCrumbs */}
          <Row type="flex" justify="space-between" align="middle">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {formDietStrategy.id != null ? (
                <Title>Edição: Estratégia de Dieta</Title>
              ) : (
                <Title>Criação: Estratégia de Dieta</Title>
              )}
              <InfoTooltip title="Uma estrategia de dieta depende do estagio de desenvolvimento do animal. O lote de referencia, provê uma base para o desenho das diferentes etapas da estrategia de dieta" />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
              <BreadCrumbs>
                <Link to="/admin/managements?tabSelect=1">
                  <span className="pageTreeInative">Lista de Estratégias</span>
                </Link>
                <span className="pageTreeActive">Criação de Estratégia</span>
              </BreadCrumbs>
            </Col>
          </Row>
          {/* Form and Display */}
          {editingDietStrategy ? (
            <DietStrategyForm
              shouldBlockFieldsOnEdit={shouldBlockFieldsOnEdit}
              formDietStrategy={formDietStrategy}
              handleSaveFirstFormDietStrategy={
                this.handleSaveFirstFormDietStrategy
              }
              loadingLots={loadingLots}
              listOfLots={listOfLots}
              handleSelectLot={this.handleSelectLot}
              breeds={breeds}
              lotAvgWeight={lotAvgWeight}
              lotStartDate={lotStartDate}
              handleAllocateLotInPicket={this.handleAllocateLotInPicket}
              lastPicketLotOfLot={lastPicketLotOfLot}
              allocatePicketVisible={allocatePicketVisible}
              isLoadingListOfPickets={isLoadingListOfPickets}
              isLoadingPicketHistoryInfo={isLoadingPicketHistoryInfo}
              listOfPickets={listOfPickets}
              picketId={picketId}
              handleSetLotStartDate={this.handleSetLotStartDate}
              handleSelectPicket={this.handleSelectPicket}
            />
          ) : (
            <DietStrategyDisplay
              formDietStrategy={formDietStrategy}
              lotAvgWeight={lotAvgWeight}
              breeds={breeds}
              amountLotAnimals={amountLotAnimals}
              handleEditFirstForm={this.handleEditFirstForm}
            />
          )}
          {/* Body (Chart and periods) */}
          <Skeleton loading={editingDietStrategy} active>
            <Spin
              spinning={isRecalculateGdpPeriods}
              tip={
                isRecalculateGdpPeriods ? "Validando o GDP dos períodos" : null
              }
            >
              <Row style={{ marginBottom: 10 }}>
                <Col
                  xs={{ span: 16, offset: 0 }}
                  sm={{ span: 16, offset: 0 }}
                  md={{ span: 16, offset: 0 }}
                  lg={{ span: 16, offset: 0 }}
                  xl={{ span: 16, offset: 0 }}
                >
                  <strong>
                    Defina as dietas que irão compor essa estratégia clicando
                    nos cards de períodos climáticos.
                  </strong>
                </Col>
                <Col
                  xs={{ span: 8, offset: 0 }}
                  sm={{ span: 8, offset: 0 }}
                  md={{ span: 8, offset: 0 }}
                  lg={{ span: 8, offset: 0 }}
                  xl={{ span: 8, offset: 0 }}
                  align="right"
                  className="buttonsCol"
                >
                  <ButtonStandard
                    type="button"
                    buttonType="type4"
                    size="s"
                    onClick={this.handleCancel}
                  >
                    {translation.buttons.cancel}
                  </ButtonStandard>

                  <PopConfirmCustom
                    title={
                      <>
                        {" "}
                        Ao confirmar, reprocesse todos os cenários de venda{" "}
                        <br />e compra - Favoritos que utilizam esta Estratégia
                        de <br />
                        dieta como Baseline{" "}
                      </>
                    }
                    cancelText="Cancelar"
                    okText="Confirmar"
                    placement="bottomRight"
                    icon={
                      <Icon
                        type="exclamation-circle"
                        theme="filled"
                        style={{ fontSize: 18, color: "#D44C4C" }}
                      />
                    }
                    onConfirm={this.openConfirmation}
                  >
                    <ButtonStandard
                      type="button"
                      buttonType="type1"
                      width="121px"
                      height="35px"
                      padding="5px 10px 5px 10px"
                    >
                      {formDietStrategy.id === null ? "Criar" : "Atualizar"}
                    </ButtonStandard>
                  </PopConfirmCustom>
                </Col>
              </Row>
              {/* Message and buttons */}
              <Spin spinning={loadingChart}>
                <ChartContainer>
                  {formDietStrategy.dietPeriods.length > 0 ? (
                    <CanvasJSChart ref={this.chart} options={options} />
                  ) : (
                    <Empty
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                      description="Por favor, informe ao menos uma dieta para exibir o gráfico."
                    />
                  )}
                </ChartContainer>
              </Spin>
              <ListPeriodsContainer>
                <ListPeriodsTitleContainer>
                  <span>
                    Períodos
                    <br />
                    climáticos
                  </span>
                </ListPeriodsTitleContainer>
                <ListPeriodsItemContainer>
                  {formDietStrategy.dietPeriods.map((period, index) => (
                    <ListPeriodsItem
                      key={index}
                      onClick={() => this.handleSelectPeriod(index)}
                      className={
                        dietPeriodIndexSelected === index ? "selected" : ""
                      }
                    >
                      {dietPeriodIndexSelected === index ? (
                        <div className="itemContainer">
                          <div className="iconContainer">
                            <TextToolIcon />
                          </div>
                          <div className="timeContainer">
                            <span className="info">
                              Informando <br />
                              dados...
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="itemContainer">
                          <div className="iconContainer">
                            {period.season
                              .split(",")
                              .map((season) =>
                                season === "Water" ? (
                                  <RainIcon key="W" />
                                ) : season === "Dry" ? (
                                  <SunIcon key="D" />
                                ) : season === "Transition" ? (
                                  <TransitionIcon key="T" />
                                ) : null
                              )}
                          </div>
                          <div className="timeContainer">
                            <div className="time">
                              <span>
                                {moment(period.startDate).format("MMM YY")}
                              </span>
                              <span>
                                {moment(period.endDate).format("MMM YY")}
                              </span>
                            </div>
                            <div className="icons">
                              {period.conflictedGdp && (
                                <Tooltip title="Dieta com GDP conflitante">
                                  <div>
                                    <AlertIcon />
                                  </div>
                                </Tooltip>
                              )}
                              {(period.calciumCode === 1 ||
                                period.phosporusCode === 1 ||
                                period.zincCode === 1 ||
                                period.sodiumCode === 1) && (
                                <Tooltip title="Dieta com minerais não suficientes.">
                                  <div>
                                    <Icon
                                      type="exclamation-circle"
                                      theme="filled"
                                    />
                                  </div>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </ListPeriodsItem>
                  ))}
                  <ListPeriodsItemPlus onClick={this.handleCreatePeriod}>
                    <span>
                      Adicionar
                      <br />
                      <strong>Dieta</strong>
                    </span>
                    <PlusCirclePurpleIcon />
                  </ListPeriodsItemPlus>
                </ListPeriodsItemContainer>
              </ListPeriodsContainer>
            </Spin>
          </Skeleton>
          {/* Modal */}
          <PeriodModel
            dietStrategyData={formDietStrategy}
            visible={modalVisible}
            index={dietPeriodIndexSelected}
            seasons={seasons}
            onAction={this.handleModalActions}
            pastures={listOfPastures}
            supplements={listOfSupplements}
            startDateDeadline={startDateDeadline}
            dietPeriodCurveDatesAndWeights={dietPeriodCurveDatesAndWeights}
            picketInfo={picketInfo}
            amountLotAnimals={amountLotAnimals}
            lotRealGdpCurveDatesAndWeights={lotRealGdpCurveDatesAndWeights}
            lotAvgSupplementConsumption={lotAvgSupplementConsumption}
            breeds={breeds}
            carbonFootprintParameters={carbonFootprintParameters}
            carbonBaseKg={carbonBaseKg}
            carbonCredit={carbonCredit}
          />
        </Container>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  app: state.app,
  lot: state.lot,
});

const mapDispatchToProps = (dispatch) => ({
  dietStrategyActions: bindActionCreators(DietStrategyActions, dispatch),
  picketActions: bindActionCreators(PicketActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewDietStrategy)
);
