import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 20px;
  overflow-x: hidden;
  overflow-y: auto;

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    margin-bottom: 7px;

    &.error {
      color: #d44c4c;
    }
  }

  .cell-error {
    border: solid 1px #d44c4c;
    border-radius: 20px;
    span {
      color: #d44c4c;
    }
  }

  textarea{
    resize: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 29px;
  }
  
  .back-container {
    display: flex;
    align-items: center;
    padding-right: 10px;
    cursor: pointer;
  }

  .back {
    position: relative;
    font-family: "Asap", sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #d44c4c;
    margin-left: 5px;
  }

  svg {
    cursor: pointer;
  }

  .input-small{
    height: 30px !important;
  }

  .row-field{
    margin-top: 13px;
  }

  .validity-date-field{
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-family: Asap;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  color: #4b4b4b;
  margin: 0;
`;