import styled, { keyframes } from "styled-components";

// Animations

const cardBoxAnimatios = keyframes`
  0% {
    left: 150%;
  }
  100% {
    left: 0%;
  }
`;

const tabAnimation = keyframes`
   0% {
    right: 150%;
  }
  100% {
    right: 0%;
  }
`;

const tabAnimalAnimation = keyframes`
 0% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden !important;
  overflow-y: auto !important;

  #bovexoInfoIcon {
    margin-left: 3px;
  }

  .columnWithCards {
    margin-bottom: 30px;
    padding: 10px;
    height: 100%;
    min-height: 100%;
  }

  .ant-card-body {
    padding: 14px;
    height: 100%;
  }

  .cardDashAnimal {
    height: 170px !important;
    margin-top: 20px;
  }

  .cardDashAnimalDetails {
    height: 170px !important;
    margin-top: 20px;
    border-left-color: #684e94;
    border-left-width: 5px;
  }

  .cardDashAnimalWeightHistory {
    height: 100% !important;
    margin: 20px;
  }

  .cardGrid {
    height: 100% !important;
  }

  .cardAnimalDetail {
    height: 100% !important;
    margin-top: 30px !important;
    bordered: false;
  }

  .gridRow {
    /* height: 100vh !important; */
    margin-top: 30px !important;
  }

  div.pageTree {
    margin-top: 10px;
    margin-bottom: -6px;
  }

  span.backIcon {
    vertical-align: middle;
  }

  span.pageTreeInative {
    color: #c4c4c4;
    font-size: 16px;
  }
  span.pageTreeActive {
    color: #684e94;
    font-size: 16px;
  }
  span.detailTitle {
    font-family: Asap;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #4b4b4b;
    margin-left: 8px;

    svg {
      margin-left: 6px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .withAnimation {
    animation: ${cardBoxAnimatios};
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
  }

  .cardBovexo {
    display: flex;
    flex-direction: column;
    border-radius: 5px !important;
    background: #fff !important;
    box-shadow: 0px 0px 10px #d8d8d8;
    padding: 20px 10px 15px 15px !important;
    margin-top: 10px !important;

    &.withBorder {
      border-left: 4px solid #4a85ae;
    }

    .span12Orange {
      font-family: Asap;
      font-style: italic;
      font-size: 12px;
      text-align: left;
      color: #fe8d2a;
    }

    .span24 {
      font-family: Asap;
      font-weight: bold;
      font-size: 24px;
      text-align: left;
      color: #4b4b4b;
    }

    .span20 {
      font-family: Asap;
      font-weight: bold;
      font-size: 20px;
      text-align: left;
      color: #4b4b4b;
      cursor: pointer;
    }

    .span20Link {
      font-family: Asap;
      font-weight: bold;
      font-size: 20px;
      text-align: left;
      color: #4b4b4b;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    .span16 {
      font-family: Asap;
      font-weight: normal;
      font-style: italic;
      font-size: 16px;
      text-align: left;
      color: #989898;
    }

    .span16GrayRegular {
      font-family: Asap;
      font-weight: normal;
      font-style: normal;
      font-size: 16px;
      text-align: left;
      color: #989898;
    }

    .span16Regular {
      font-family: Asap;
      font-weight: normal;
      font-size: 16px;
      text-align: right;
      color: #4b4b4b;
      display: flex;
      align-items: center;
    }

    .span16Bold {
      font-family: Asap;
      font-weight: bold;
      font-size: 16px;
      text-align: right;
      color: #4b4b4b;
    }

    .span14Bold {
      font-family: Asap;
      font-weight: bold;
      font-size: 14px;
      text-align: left;
      color: #4b4b4b;
    }

    .span14 {
      font-family: Asap;
      font-size: 14px;
      text-align: left;
      color: #4b4b4b;
    }
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: flex !important;
    justify-content: flex-start !important;
    padding-left: 20px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;

    svg:first-child {
      circle {
        fill: #4a85ae !important;
      }
    }

    .svg-tooltip {
      svg{
          circle {
          fill: white !important;
        }
        
      }
    }
  }
`;

export const AnimalDetailsCard = styled.div`
  width: 100% !important;
  height: 100% !important;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #dcdcdc;
  padding: 18px 0 24px 25px;

  span.title {
    color: #684e94;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }
  span.label {
    color: #4b4b4b;
    font-size: 14px;
    font-weight: bold;
    margin-top: 6px;
  }
  span.value {
    color: #4b4b4b;
    font-size: 14px;
    font-weight: normal;

    &.link {
      text-decoration: underline;
    }
  }
  button.linkLot {
    background: transparent;
    border: 0;
    cursor: pointer;
  }

  table {
    margin-top: 5px;
    td {
      padding-top: 10px;
    }
  }
`;
export const AnimalFarmIdContainer = styled.div`
  width: 100% !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;

  span.title {
    color: #684e94;
    font-size: 20px;
    font-weight: bold;
  }
  span.value {
    color: #4b4b4b;
    font-size: 35px;
    font-weight: normal;
    text-align: center;
    margin-top: 25px;
  }
`;

export const AnimalWeightContainer = styled.div`
  width: 100% !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;

  span.title {
    color: #684e94;
    font-size: 20px;
    font-weight: bold;
  }
  span.label {
    color: #4b4b4b;
    font-size: 16px;
    font-weight: bold;
  }
  span.value {
    color: #4b4b4b;
    font-size: 16px;
    font-weight: normal;
    top-margin: 5px;
  }
  table {
    margin-top: 15px;
  }
  td {
    padding-top: 10px;
  }
`;

export const AnimalLocateAtContainer = styled.div`
  width: 100% !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;

  span.title {
    color: #684e94;
    font-size: 20px;
    font-weight: bold;
  }
  span.label {
    color: #4b4b4b;
    font-size: 16px;
    font-weight: bold;
  }
  span.value {
    color: #4b4b4b;
    font-size: 16px;
    font-weight: normal;
  }
  table {
    margin-top: 15px;
  }
  td {
    padding-top: 10px;
  }
`;

export const AnimalProductionTabContainer = styled.div`
  position: relative;
  margin-bottom: 30px;
  &.withTabAnimation {
    -webkit-animation: ${tabAnimation};
    animation: ${tabAnimation};
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
  }
  .ant-tabs-ink-bar {
    background-color: #684e94;
    height: 5px;
  }

  .ant-tabs-line {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab {
    font-family: "Asap" !important;
    font-size: 14px !important;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab-disabled,
  .ant-tabs-nav .ant-tabs-tab-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: #4b4b4b;
    font-weight: bold;
  }

  .ant-tabs-bar {
    border-bottom: 1px solid #a9c133;
  }

  .ant-tabs-tabpane {
    background-color: #fff;
    margin-top: -15px;
  }

  .ant-table-thead > tr > th {
    padding: 5px;
    text-align: center;
    font-size: 14px !important;
  }

  .ant-table-tbody > tr > td {
    text-align: center;
    font-size: 14px !important;
    padding: 8px;
  }
  span.historyDateFilter {
    font-size: 21px;
    font-weight: bold;
    color: #684e94;
    text-align: center;
  }

  .cardDashAnimalTotalGMDWeight {
    margin: 20px;
    box-shadow: none !important;
    border: none !important;
    background-color: transparent;
    .detailTitle {
      font-family: Asap !important;
      font-weight: bold !important;
      font-size: 18px !important;
      text-align: left !important;
      color: #4b4b4b !important;
      margin-left: 0 !important;
    }
    div.chartDiv {
      width: 100%;
      height: 100%;
      margin-top: 8px !important;
    }
  }
  .cardDashAnimalTotalGMDWeight .ant-card-body {
    padding: 0px;
  }

  .cardDashAnimalTotalGMDWeight .ant-card-bordered {
    border: none !important;
  }

  .cardDashAnimalGPDGraphic {
    /* height: 400px !important; */
    margin: 20px;
    box-shadow: none !important;
    border: none !important;
    background-color: transparent;
    .detailTitle {
      font-family: Asap !important;
      font-weight: bold !important;
      font-size: 18px !important;
      text-align: left !important;
      color: #4b4b4b !important;
      margin-left: 0 !important;
    }
    div.chartDiv {
      width: 100%;
      height: 100%;
      margin-top: 8px !important;
    }
  }
  .cardDashAnimalGPDGraphic .ant-card-body {
    padding: 0px;
  }

  table {
    margin-top: 30px;
  }
`;

export const TabAnimalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const TabButtonsPaneContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const TabAnimalPane = styled.button`
  width: 11px;
  height: 11px;
  border: 2px solid #707070;
  border-radius: 50%;
  background: ${(props) => (props.active ? "#707070" : "transparent")};

  &:not(:first-child) {
    margin-left: 5px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const TabAnimalPaneContent = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
  opacity: ${(props) => (props.active ? 1 : 0)};
  animation: ${tabAnimalAnimation} 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  -webkit-animation: ${tabAnimalAnimation} 1s
    cubic-bezier(0.39, 0.575, 0.565, 1) both;
`;

export const AlertDefault = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #fe8d2a;
  margin-left: 5px;
`;

export const ButtonCurveParameter = styled.button`
  background: transparent;
  border: none;
  font-family: Asap;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #684e94;
  cursor: pointer;
`;

export const EditButton = styled.button`
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #9386aa;
  border: none;
  background: none;

  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
    path {
      fill: #9386aa !important;
    }
  }

  &:hover {
    color: #a9c133;
    cursor: pointer;

    svg {
      margin-left: 5px;
      path {
        fill: #a9c133 !important;
      }
    }
  }

  &:disabled {
    color: #a5a5a5;
    cursor: not-allowed;
    svg {
      margin-left: 5px;
      path {
        fill: #a5a5a5 !important;
      }
    }
  }
`;

export const ConfirmEditButton = styled.button`
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #a9c133;
  border: none;
  background: none;

  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
    path {
      fill: #a9c133 !important;
    }
  }

  &:hover {
    color: #9386aa;
    cursor: pointer;

    svg {
      margin-left: 5px;
      path {
        fill: #9386aa !important;
      }
    }
  }

  &:disabled {
    color: #a5a5a5;
    cursor: not-allowed;
    svg {
      margin-left: 5px;
      path {
        fill: #a5a5a5 !important;
      }
    }
  }
`;

export const CancelEditButton = styled.button`
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #c94c4c;
  border: none;
  background: none;

  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
    path {
      fill: #c94c4c !important;
    }
  }

  &:hover {
    color: #989898;
    cursor: pointer;

    svg {
      margin-left: 5px;
      path {
        fill: #989898 !important;
      }
    }
  }

  &:disabled {
    color: #a5a5a5;
    cursor: not-allowed;
    svg {
      margin-left: 5px;
      path {
        fill: #a5a5a5 !important;
      }
    }
  }
`;

export const DeleteButton = styled.button`
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #c94c4c;
  border: none;
  background: none;

  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
    path {
      fill: #c94c4c !important;
    }
  }

  &:hover {
    color: #eea29a;
    cursor: pointer;

    svg {
      margin-left: 5px;
      path {
        fill: #a9c133 !important;
      }
    }
  }

  &:disabled {
    color: #a5a5a5;
    cursor: not-allowed;
    svg {
      margin-left: 5px;
      path {
        fill: #a5a5a5 !important;
      }
    }
  }
`;

export const AnimalWeightActionsContainer = styled.div`
  width: 100% !important;
  height: 100% !important;
  display: flex;

  span {
    margin: 0 5px 0 5px;
    opacity: 0.5;
  }
`;

export const AnimalWeightActionsConfirmContainer = styled.div`
  width: 100% !important;
  height: 100% !important;
  display: flex;

  span {
    margin: 0 5px 0 5px;
    opacity: 0.5;
  }
`;