import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    font-family: Asap !important;
    font-size: 20px !important;
    text-align: center !important;
    color: #4b4b4b !important;
  }

  .ant-result-extra {
    margin-top: 23px !important;
  }
`;
