// import axios from "axios";
import apiFarms from "../config/api_farms";
import apiCommons from "../config/api_commons";
import getHeaders from "./getHeaders";

// const apiCommons = axios.create({
//   baseURL: "http://localhost:8083",
// });

export function getAppVersion() {
  return apiCommons.get(`/bovexo/parameters/items/application/version`);
}

export function getAllWeatherSeasons(payload) {
  const { farmId, signal } = payload;

  return apiFarms.get(
    `/bovexo/farms/${farmId}/weatherSeasons/list`,
    getHeaders(false, signal)
  );
}

export function getWeatherSeason(payload) {
  const { farmId, signal } = payload;

  return apiFarms.get(
    `/bovexo/farms/${farmId}/weatherSeasons`,
    getHeaders(false, signal)
  );
}

export function setWeatherSeason(payload) {
  const { farmId, weatherSeasonId, typeSeason, signal } = payload;

  return apiFarms.post(
    `/bovexo/farms/${farmId}/weatherSeasons/${weatherSeasonId}/type/${typeSeason}`,
    {},
    getHeaders(false, signal)
  );
}

export function getParameterItems(payload) {
  const { groupId, farmId, code, signal } = payload;
  return apiCommons.get(
    `/bovexo/${groupId}/farms/${farmId}/parameters/code/${code}/items/default`,
    getHeaders(false, signal)
  );
}

export function getAlertParameterItems(payload) {
  const { groupId, farmId, signal } = payload;
  return apiCommons.get(
    `/bovexo/${groupId}/farms/${farmId}/parameters/items/alerts/default`,
    getHeaders(false, signal)
  );
}

export function saveOrUpdateParameter(payload) {
  const { groupId, farmId, parameterId, parameterItemId, edit, body, signal } =
    payload;
  if (edit === true) {
    if (body.type === "List") {
      return apiCommons.post(
        `/bovexo/${groupId}/farms/${farmId}/parameters/${parameterId}/items`,
        body,
        getHeaders(false, signal)
      );
    }
    return apiCommons.patch(
      `/bovexo/${groupId}/farms/${farmId}/parameters/${parameterId}/items/${parameterItemId}`,
      body,
      getHeaders(false, signal)
    );
  } else {
    if (body.type === "List") {
      for (var i = 0; i < body.items.length; i++) {
        body.items[i].id = null;
      }
    }
    let newBody = {
      value: body.value,
      valueExtra: body.valueExtra,
      status: body.status ? body.status : "Active",
      items: body.items,
    };
    return apiCommons.post(
      `/bovexo/${groupId}/farms/${farmId}/parameters/${parameterId}/items`,
      newBody,
      getHeaders(false, signal)
    );
  }
}

export function downloadBovManagerService() {
  const s3Environment = process.env.REACT_APP_S3_ENV;

  return apiCommons.get(
    `/bovexo/files/download/bovManager?environment=${s3Environment}`,
    getHeaders(false, null, true)
  );
}
