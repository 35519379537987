import React from 'react';

const RedCircleIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="6px" height="6px" viewBox="0 0 6 6" className="failed">
        <circle id="Ellipse_237" data-name="Ellipse 237" cx="3" cy="3" r="3" fill="#d44c4c"/>
    </svg>
);
export default RedCircleIcon;



