import React from "react";

// import { Container } from './styles';

const ArrowUpIcon = () => (
  <svg
    id="bovexoArrowUpIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="9.265"
    height="9.265"
    viewBox="0 0 9.265 9.265"
  >
    <path
      id="Path_794"
      data-name="Path 794"
      d="M0,0H6.551V6.551Z"
      transform="translate(0 4.633) rotate(-45)"
      fill="#684e94"
    />
  </svg>
);
export default ArrowUpIcon;
