import { call, put } from "redux-saga/effects";
import { notification } from "antd";
import { Creators as BoitelSaleScenarioActions } from "../ducks/boitelSaleScenario";
/** Services */
import {
  showBoitelSaleScenarioService,
  storeUpdateBoitelSaleScenarioService,
  indexBoitelSaleScenarioService
} from "../../services/boitelSaleScenarioService";

export function* storeUpdateBoitelSaleScenarioSaga(action) {
  try {
    const {
      data: { results },
    } = yield call(storeUpdateBoitelSaleScenarioService, action.payload);
    notification.success({
      message: `${action.payload.id != null ? "Atualização" : "Cadastro"} ${
        action.payload.id != null ? "realizada" : "realizado"
      }`,
      description: `${
        action.payload.id != null ? "Atualização" : "Cadastro"
      } do Cenário Boitel - Venda ${
        action.payload.id != null ? "realizada" : "realizado"
      }`,
    });
    yield put(
      BoitelSaleScenarioActions.storeUpdateBoitelSaleScenarioSuccess(results)
    );
  } catch (error) {
    notification.error({
      message: `${action.payload.id != null ? "Atualização" : "Cadastro"} não ${
        action.payload.id != null ? "realizada" : "realizado"
      }`,
      description: `${
        action.payload.id != null ? "Atualização" : "Cadastro"
      } do Cenário Boitel - Venda não ${
        action.payload.id != null ? "realizada" : "realizado"
      }`,
    });
    yield put(
      BoitelSaleScenarioActions.storeUpdateBoitelSaleScenarioError(error)
    );
  }
}

export function* getBoitelSaleScenarioDataSaga(action) {
  try {
    const {
      data: { results },
    } = yield call(showBoitelSaleScenarioService, action.payload);
    yield put(
      BoitelSaleScenarioActions.getBoitelSaleScenarioDataSuccess(results)
    );
  } catch (error) {
    notification.error({
      message: `Erro ao buscar Cenário Boitel.`,
      description: `Erro ao buscar Cenário Boitel. Contate o suporte.`,
    });
    yield put(BoitelSaleScenarioActions.getBoitelSaleScenarioDataError(error));
  }
}

export function* getBoitelSaleScenarioListDataSaga(action) {
  try {
    const {
      data: { results }
    } = yield call(indexBoitelSaleScenarioService, action.payload);

    yield put(
      BoitelSaleScenarioActions.getBoitelSaleScenarioListDataSuccess(results)
    )
  } catch (error) {
    notification.error({
      message: `Erro ao buscar Cenário Boitel.`,
      description: `Erro ao buscar Cenário Boitel. Contate o suporte.`,
    });
    yield put(BoitelSaleScenarioActions.getBoitelSaleScenarioListDataError(error));
  }
}
