import styled from "styled-components";
import { Tabs } from "antd";

export const Container = styled.div`
  height: 100%;
  /* height: 100% !important; */
  .cardDashPicket {
    height: 180px;
    margin-top: 20px;

    .ant-card-body {
      padding: 20px !important;
    }
  }

  .cardGrid {
    .btn-comparable {
      &:disabled {
        background: #f0f0f0 !important;
        color: #adadad !important;
      }
    }
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }

  .gridRow {
    /* height: 100vh !important; */
    margin-top: 5px !important;
  }

  .link {
    color: #4b4b4b !important;
    text-decoration: none !important;
    &:hover {
      border-bottom: 1px solid #4b4b4b !important;
    }
  }

  div.colLabelForm {
    margin-bottom: 7px !important;
  }

  .alertSelected {
    background: #fe8d2a;
    border: 1px solid #fe8d2a;
  }

  .alertDate {
    .ant-calendar-picker-input.ant-input {
      line-height: 1.5;
      padding: 4px 5px;
      width: 85px;
      border: 0px;
      color: #d44c4c !important;
      font-weight: bold;
    }

    svg {
      height: 10px;
      width: 10px;
      path {
        fill: #d44c4c !important;
      }
    }
  }
`;

export const PageTitle = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  span {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #c4c4c4;
    text-transform: uppercase;
  }
  #bovexoPicketIcon {
    width: 25px;
    height: 25px;
    margin-right: 9px;
    path {
      fill: #c4c4c4;
    }
  }
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;

export const NewPicket = styled.button`
  width: 194.2px;
  height: 24px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #684e94;
  color: #684e94;
  text-align: center;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
`;

export const AlertButton = styled.button`
  margin-left: 5px;
  padding: 0px 5px;
  border-radius: 5px;
  height: 34px;
  background: #d44c4c;
  border: 1px solid #d44c4c;
  color: #ffffff;
  text-align: center;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }

  svg {
    margin-left: 5px;
  }
`;

export const TabContainer = styled(Tabs)`
  margin-bottom: 10px !important;

  .ant-tabs-ink-bar {
    background-color: #684e94;
    height: 5px;
  }

  .ant-tabs-line {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab {
    font-family: "Asap" !important;
    font-size: 14px !important;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab-disabled,
  .ant-tabs-nav .ant-tabs-tab-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: #4b4b4b;
    font-weight: bold;
  }

  .ant-tabs-bar {
    border-bottom: 1px solid #a9c133;
  }

  .ant-tabs-tabpane {
    background-color: #fff;
    margin-top: -15px;
  }
`;