import { Statistic } from "antd";
import React from "react";
import { numberMask } from "../../../../../../../services/helpersMethodsService";
import useLotDetailsSupplementSupplyContext from "../../../../../../../hooks/useLotDetailsSupplementSupplyContext";

// import { Container } from './styles';

function CardTotalAnimalCostSupplementSupplyDashboard() {
  const { totalSupplyCostPerAnimal } = useLotDetailsSupplementSupplyContext();
  return (
    <Statistic
      title="Custo total"
      value={numberMask(totalSupplyCostPerAnimal, true)}
      precision={2}
      suffix={"Cab"}
    />
  );
}

export default CardTotalAnimalCostSupplementSupplyDashboard;
