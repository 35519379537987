import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as AppActions } from "../../../store/ducks/app";
import { useHistory } from "react-router-dom";
import { Creators as AccountFarmActions } from "../../../store/ducks/accountFarm";
import { Container, CustomModal, Title, Header } from "./styles";
import { CustomSelect } from "../../../pages/lot/addAnimalsInLot/styles";
import { Select, Row, Col } from "antd";
import ButtonStandard from "../../utils/button";

const GroupAndFarmsModal = () => {
  const [group, setGroup] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [farm, setFarm] = useState(null);
  const [farmId, setFarmId] = useState(null);
  const history = useHistory();
  // Redux
  const { translation, user, modalGroupAndFarmsVisible, farmSelected } =
    useSelector((state) => state.app);
  const { userGroups } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { Option } = Select;

  /** Effects */
  useEffect(() => {
    if (userGroups.length === 1) {
      if (userGroups[0].farms.length === 1 && farmSelected == null) {
        dispatch(AppActions.setGroupSelected(userGroups[0]));
        dispatch(AppActions.setFarmSelected(userGroups[0].farms[0]));
        dispatch(
          AppActions.getAllRoles(
            user.id,
            userGroups[0].id,
            userGroups[0].farms[0].id,
            user.isInputSupplier
          )
        );
        dispatch(AppActions.hideModalGroupAndFarm());
        dispatch(
          AccountFarmActions.checkAccountFarm(
            userGroups[0].id,
            userGroups[0].farms[0].id
          )
        );
      } else {
        setGroup(userGroups[0]);
        setGroupId(userGroups[0].id);
      }
    }
  }, [user, userGroups, farmSelected, dispatch]);

  /* Methods */
  const handleSelectGroup = (value) => {
    const group = userGroups.find((ug) => ug.id === value);
    setGroupId(value);
    setGroup(group);
    if (group.farms.length === 1) {
      setFarmId(group.farms[0]?.id);
      setFarm(group.farms[0]);
    } else {
      setFarmId(null);
      setFarm(null);
    }
  };
  const handleSelectFarm = (value) => {
    setFarmId(value);
    setFarm(group.farms.find((f) => f.id === value));
  };

  const handleFinish = () => {
    dispatch(AppActions.setGroupSelected(group));
    dispatch(AppActions.setFarmSelected(farm));
    dispatch(AppActions.getAllRoles(user.id, group.id, farm.id, user.isInputSupplier));
    dispatch(AppActions.hideModalGroupAndFarm());
    
    if(user.isInputSupplier){
      history.push("/supplier");
    }else{
      dispatch(AccountFarmActions.checkAccountFarm(group.id, farm.id));
      history.push("/admin");
    }
  };

  return (
    <CustomModal
      width={460}
      visible={modalGroupAndFarmsVisible}
      centered
      maskClosable={false}
      closable={false}
      footer={null}
    >
      <Container>
        <Header>
          <Title>{`${translation.groupAndFarmModal.hello} ${user.fullName},`}</Title>
        </Header>
        <Row type="flex">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {user.isInputSupplier ? (
              <label>Selecione um grupo</label>)
              : (<label>{translation.groupAndFarmModal.groupSelectedLabel}</label>)
            }
            <CustomSelect
              name="groupId"
              style={{ width: "100%" }}
              placeholder={
                translation.groupAndFarmModal.groupSelectedPlaceHolder
              }
              value={groupId != null ? groupId : undefined}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleSelectGroup}
            >
              {userGroups != null &&
                Object.entries(userGroups).length > 0 &&
                userGroups
                  .filter((ug) => ug.farms.length > 0)
                  .map((group) => (
                    <Option key={group.id} value={group.id}>
                      {group.name}
                    </Option>
                  ))}
            </CustomSelect>
          </Col>
        </Row>
        {group != null && (
          <Row type="flex">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {user.isInputSupplier ? (
                <label>Selecione uma empresa</label>)
                : (<label>{translation.groupAndFarmModal.farmSelectedLabel}</label>)
              }
              <CustomSelect
                name="farmId"
                style={{ width: "100%" }}
                placeholder={
                  user.isInputSupplier ? "Selecione uma empresa" : 
                  translation.groupAndFarmModal.farmSelectedPlaceHolder
                }
                value={farmId != null ? farmId : undefined}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleSelectFarm}
              >
                {group != null &&
                  Object.entries(group.farms).length > 0 &&
                  group.farms.map((farm) => (
                    <Option key={farm.id} value={farm.id}>
                      {farm.name}
                    </Option>
                  ))}
              </CustomSelect>
            </Col>
          </Row>
        )}
        <Row type="flex">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} align="right">
            <ButtonStandard
              buttonType="type1"
              type="button"
              width="162px"
              height="42px"
              padding="10px 0px"
              disabled={farmId == null ? true : false}
              onClick={handleFinish}
            >
              {translation.groupAndFarmModal.buttonAccessFarm}
            </ButtonStandard>
          </Col>
        </Row>
      </Container>
    </CustomModal>
  );
};

export default GroupAndFarmsModal;
