import React, { useState } from "react";
import { useSelector } from "react-redux";

//Pages
import { Tabs } from "antd";
import useModuleContext from "../../hooks/useModuleContext";
import Inseminator from "../inseminator";
import MultipleBull from "../multipleBull";
import ReproductionParameters from "../reproductionParameters";
import ReproductiveProtocol from "../reproductiveProtocol";
import Semen from "../semen";

const Reproduction = () => {
  const [tabSelect, setTabSelect] = useState("1");
  const { isAllowedFunctions } = useModuleContext();
  const { translation } = useSelector((state) => state.app);

  const handleTabClick = (key, e) => {
    setTabSelect(key);
  };

  return (
    <Tabs
      type="card"
      defaultActiveKey="1"
      activeKey={tabSelect}
      onTabClick={handleTabClick}
    >
      <Tabs.TabPane
        tab={<span>{translation.parameters.tabs.reproductionParameter}</span>}
        key="1"
        disabled={!isAllowedFunctions(["REPRODUCTION_PARAMETERS"])}
      >
        <ReproductionParameters />
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={<span>{translation.parameters.tabs.semen}</span>}
        key="2"
        disabled={!isAllowedFunctions(["SEMEN"])}
      >
        <Semen />
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={<span>{translation.parameters.tabs.multipleBull}</span>}
        key="3"
        disabled={!isAllowedFunctions(["MULTIPLE_BULL"])}
      >
        <MultipleBull />
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={<span>{translation.parameters.tabs.inseminators}</span>}
        key="4"
        disabled={!isAllowedFunctions(["INSEMINATORS"])}
      >
        <Inseminator />
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={<span>{translation.parameters.tabs.reproductiveProtocol}</span>}
        key="5"
        disabled={!isAllowedFunctions(["REPRODUCTION_PARAMETERS"])}
      >
        <ReproductiveProtocol />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default Reproduction;
