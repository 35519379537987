import styled from "styled-components";

export const Container = styled.div`
  width: 100% !important;
  min-width: 100% !important;
  height: 100% !important;
  min-height: 100% !important;

  padding-top: 14px;
  padding-left: 6px;

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;

export const NewDiet = styled.button`
  width: 194.2px;
  height: 24px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #684e94;
  color: #684e94;
  text-align: center;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
`;

export const TagStatus = styled.span`
  padding: 2px 5px !important;
  border-radius: 5px !important;
  color: ${props => props.color}!important;
  font-size: 12px !important;
  font-weight: bold !important;
  border: ${props => `1px solid ${props.borderColor}`};
  background: ${props => props.background} !important;
`;

export const PageTitle = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 25px;
  text-align: left;
  color: #9074bf;
`;
