import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Async } from "react-async";
import { useSelector } from "react-redux";

import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Icon,
  Input,
  Menu,
  Row,
  Select,
  Table,
  notification,
} from "antd";
import useAnimalBirthContext from "../../../hooks/useAnimalBirthContext";

import { Link } from "react-router-dom";
import EyeIcon from "../../../components/utils/icons/eye";
import MenuIcon from "../../../components/utils/table/icons/menu";
import { Container as MenuContainer } from "../../../components/utils/table/menu/styles";
// Service
import {
  checkCanEditOrDelete,
  deleteAnimalBirth,
} from "../../../services/animalBirthService";
import { numberMask } from "../../../services/helpersMethodsService";
import CustomLink from "../../../components/utils/customLink";

const TableAnimalBirth = () => {
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const {
    handleTableChange,
    fetchData,
    dataList,
    isLoadingDataList,
    tableProperties: { size, page, search, tableFilters, tableSorters },
  } = useAnimalBirthContext();

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { Column } = Table;

  const handleSearch = useCallback((selectedKeys, confirm) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  // const handleEdit = (record) => {
  //   initializeData(record);
  //   openOrCloseForm();
  // };

  const handleDelete = useCallback(
    async (id) => {
      setIsLoadingRequest(true);
      try {
        await deleteAnimalBirth({
          groupId,
          farmId,
          id,
        });
        fetchData(page, size, tableSorters, search);
        notification.success({ message: "Parto deletado com sucesso!" });
      } catch (error) {
        if (Object.keys(error).includes("response")) {
          const { response } = error;
          if (Object.keys(response).includes("data")) {
            const {
              data: { code: errorCode, errors: errorsItens },
            } = response;
            if (errorCode === 5030) {
              notification.error({
                message: "Erro",
                description: `Este parto não pode ser excluído, pois a cria (${errorsItens
                  .map((e) => e.message)
                  .join(", ")}) contem registros após a data de criação.`,
              });
            } else {
              notification.error({
                message: "Erro",
                description: "Erro ao deletar parto.",
              });
            }
          }
        } else {
          notification.error({
            message: "Erro",
            description: "Erro ao deletar parto.",
          });
        }
      } finally {
        setIsLoadingRequest(false);
      }
    },
    [farmId, fetchData, groupId, page, search, size, tableSorters]
  );

  const getColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys && selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
    }),
    [handleReset, handleSearch]
  );

  const getColumnDateSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Row type="flex" gutter={4}>
            <Col>
              <Select
                value={selectedKeys ? selectedKeys[1] : "equal"}
                onChange={(value) =>
                  setSelectedKeys(
                    value
                      ? selectedKeys
                        ? [selectedKeys[0], value]
                        : [null, value]
                      : []
                  )
                }
              >
                <Select.Option value="equal">=</Select.Option>
                <Select.Option value="greaterThan">&gt;</Select.Option>
                <Select.Option value="greaterOrEqualThan">&ge;</Select.Option>
                <Select.Option value="lessThan">&lt;</Select.Option>
                <Select.Option value="lessOrEqualThan">&le;</Select.Option>
              </Select>
            </Col>
            <Col>
              <DatePicker
                value={selectedKeys && selectedKeys[0]}
                format={"DD/MM/YYYY"}
                onChange={(date) =>
                  setSelectedKeys(
                    date
                      ? selectedKeys && selectedKeys.length > 1
                        ? [date, selectedKeys[1]]
                        : [date, "equal"]
                      : []
                  )
                }
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </Col>
          </Row>

          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
    }),
    [handleReset, handleSearch]
  );

  const menu = (id, record) => {
    return (
      <Menu>
        <Menu.Item onClick={() => handleDelete(id)}>
          {translation.table.menu.delete}
        </Menu.Item>
      </Menu>
    );
  };

  function expandedRows(record, index, indent, expanded) {
    const {
      childs: { handlingNumber, gender, birthdayWeight, situation },
    } = translation.animalBirth.table.columns;

    const columns = [
      {
        title: handlingNumber,
        dataIndex: "handlingNumber",
      },
      {
        title: gender,
        dataIndex: "gender",
        render: (text, record) => (
          <span>{text === "F" || text === "Female" ? "Fêmea" : "Macho"}</span>
        ),
      },
      {
        title: birthdayWeight,
        dataIndex: "birthdayWeight",
        render: (text, { birthdayWeight }) => (
          <span>{`${numberMask(birthdayWeight, false)} kg`}</span>
        ),
      },
      {
        title: situation,
        dataIndex: "stillborn",
        render: (text, { stillborn }) => (
          <span>{`${stillborn ? "Nat." : "Vivo"}`}</span>
        ),
      },
      {
        title: "",
        align: "center",
        dataIndex: "animalId",
        render: (text, { animalId }) => (
          <Link to={`/admin/animals/${animalId}`}>
            <EyeIcon isActive />
          </Link>
        ),
      },
    ];

    const { childs } = record;

    if (!childs || childs.length === 0) return false;

    return (
      <Table
        rowKey="id"
        columns={columns}
        dataSource={childs}
        pagination={false}
        style={{ padding: "2" }}
      />
    );
  }

  useEffect(() => {
    handleTableChange();
  }, [handleTableChange]);

  return (
    <Table
      rowKey="id"
      loading={isLoadingDataList || isLoadingRequest}
      dataSource={dataList != null ? dataList.content : []}
      expandedRowRender={expandedRows}
      pagination={{
        total: dataList != null ? dataList.totalElements : 0,
        size: dataList != null ? size : 0,
        current: dataList != null ? page : 0,
        hideOnSinglePage:
          dataList !== null && Object.entries(dataList).length !== 0
            ? dataList.totalElements > 10
              ? false
              : true
            : true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
      }}
      scroll={{
        x: 1200,
      }}
      onChange={handleTableChange}
    >
      <Column
        title={translation.animalBirth.table.columns.matrizAnimalHandlingNumber}
        dataIndex="matrizAnimalHandlingNumber"
        sorter
        key="matrizAnimalHandlingNumber"
        filteredValue={tableFilters?.matrizAnimalHandlingNumber || null}
        align="left"
        width={200}
        sortOrder={
          tableSorters?.columnKey === "matrizAnimalHandlingNumber" &&
          tableSorters.order
        }
        {...getColumnSearchProps("matrizAnimalHandlingNumber")}
        render={(text, record) => (
          <CustomLink to={`/admin/animals/${record.matrizAnimalId}`}>
            {text}
          </CustomLink>
        )}
      />
      <Column
        title={translation.animalBirth.table.columns.birthDate}
        dataIndex="birthDate"
        sorter
        key="birthDate"
        filteredValue={tableFilters?.birthDate || null}
        align="left"
        width={100}
        sortOrder={tableSorters?.columnKey === "date" && tableSorters.order}
        {...getColumnDateSearchProps("birthDate")}
        render={(text) => <span>{`${moment(text).format("DD/MM/YYYY")}`}</span>}
      />
      <Column
        title={translation.animalBirth.table.columns.type}
        dataIndex="type"
        sorter
        key="type"
        filteredValue={tableFilters?.type || null}
        align="left"
        width={100}
        filtered
        filters={[
          {
            text: translation.animalBirth.types.parto,
            value: "parto",
          },
          {
            text: translation.animalBirth.types.aborto,
            value: "aborto",
          },
          {
            text: translation.animalBirth.types.morteEmbrionaria,
            value: "morteEmbrionaria",
          },
        ]}
        filterMultiple={false}
        sortOrder={tableSorters?.columnKey === "type" && tableSorters.order}
        render={(text) => <span>{translation.animalBirth.types[text]}</span>}
      />
      <Column
        title={translation.animalBirth.table.columns.coverageDate}
        dataIndex="coverageDate"
        sorter
        key="coverageDate"
        filteredValue={tableFilters?.coverageDate || null}
        align="left"
        width={100}
        sortOrder={tableSorters?.columnKey === "date" && tableSorters.order}
        {...getColumnDateSearchProps("coverageDate")}
        render={(text) => (
          <span>{`${
            text != null ? moment(text).format("DD/MM/YYYY") : "-"
          }`}</span>
        )}
      />
      <Column
        title={translation.animalBirth.table.columns.coverageType}
        dataIndex="coverageType"
        sorter
        key="coverageType"
        filteredValue={tableFilters?.coverageType || null}
        align="left"
        width={100}
        sortOrder={
          tableSorters?.columnKey === "coverageType" && tableSorters.order
        }
        filtered
        filters={[
          {
            text: translation.handlingReproductionCoverage.type.iatf,
            value: "IA/IATF",
          },
          {
            text: translation.handlingReproductionCoverage.type.repasse,
            value: "MC/REPASSE",
          },
        ]}
        filterMultiple={false}
        render={(text) => <span>{text != null ? text : "-"}</span>}
      />
      <Column
        title={translation.animalBirth.table.columns.reproductor}
        dataIndex="reproductor"
        sorter
        key="reproductor"
        filteredValue={tableFilters?.reproductor || null}
        align="left"
        width={200}
        sortOrder={
          tableSorters?.columnKey === "reproductor" && tableSorters.order
        }
        {...getColumnSearchProps("reproductor")}
      />
      <Column
        align="left"
        width={50}
        render={(text, record) => (
          <Async
            promiseFn={checkCanEditOrDelete}
            payload={{
              groupId,
              farmId,
              id: record.id,
            }}
          >
            <Async.Pending>
              <Icon type="loading" />
            </Async.Pending>
            <Async.Fulfilled>
              {(resp) =>
                resp.data.results ? (
                  <Dropdown
                    overlay={menu(record.id, record)}
                    trigger={["click"]}
                    key={record.id}
                  >
                    <MenuContainer>
                      <MenuIcon />
                    </MenuContainer>
                  </Dropdown>
                ) : null
              }
            </Async.Fulfilled>
          </Async>
        )}
      />
    </Table>
  );
};
export default TableAnimalBirth;
