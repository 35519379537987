import apiFarms from "../config/api_farms";
import getHeaders from "./getHeaders";

export function findAllBreedReproductionScoreByGroupIdAndFarmId(payload) {
  const { groupId, farmId } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/breed/reproduction/score`,
    getHeaders(false, null)
  );
}

export function saveBreedReproductionScore(payload) {
  const { groupId, farmId, body, id } = payload;

  if (id != null) {
    return apiFarms.put(
      `/bovexo/${groupId}/farms/${farmId}/breed/reproduction/score/${id}`,
      body,
      getHeaders(false, null)
    );
  } else {
    return apiFarms.post(
      `/bovexo/${groupId}/farms/${farmId}/breed/reproduction/score`,
      body,
      getHeaders(false, null)
    );
  }
}

export function deleteBreedReproductionScore(payload) {
  const { groupId, farmId, id } = payload;
  return apiFarms.delete(
    `/bovexo/${groupId}/farms/${farmId}/breed/reproduction/score/${id}`,
    getHeaders(false, null)
  );
}
