import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Row, Col, Table, Input, Button, Icon, Menu, Dropdown } from "antd";

import { Creators as BenchmarkActions } from "../../store/ducks/benchmark";

import { Title, CardCustom } from "./styles";
import TagStatus from "../../components/utils/tagStatus";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import MenuIcon from "../../components/utils/table/icons/menu";
import DrawerBenchmark from "../../components/drawers/benchmark";
import ButtonStandard from "../../components/utils/button";
class Benchmark extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
    };
  }

  static propTypes = {
    benchmarkActions: PropTypes.any.isRequired,
    app: PropTypes.any.isRequired,
    benchmark: PropTypes.any.isRequired,
  };

  async componentDidMount() {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;
    if (
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      await Promise.all([this.fetchData(groupSelected, farmSelected, 0, {})]);
    }
  }

  async shouldComponentUpdate(nextProps) {
    const differentGroup =
      this.props.app.groupSelected?.id !== nextProps.app.groupSelected?.id;
    const differentFarm =
      this.props.app.farmSelected?.id !== nextProps.app.farmSelected?.id;
    if (differentGroup || differentFarm) {
      if (
        Object.entries(nextProps.app.groupSelected).length > 0 &&
        Object.entries(nextProps.app.farmSelected).length > 0
      ) {
        await Promise.all([
          this.fetchData(
            nextProps.app.groupSelected,
            nextProps.app.farmSelected,
            0,
            {}
          ),
        ]);
      }
    }
    return true;
  }

  fetchData = async (
    groupSelected,
    farmSelected,
    page = 0,
    sorter = {},
    filters = "",
    size = 10
  ) => {
    const { benchmarkActions } = this.props;
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    await benchmarkActions.indexBenchmark(
      groupId,
      farmId,
      page,
      sorter,
      filters,
      size
    );
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
            background: "#684e94",
            borderColor: "none",
            border: "none",
          }}
        />
        <Button
          onClick={() => this.handleReset(clearFilters)}
          icon="delete"
          size="small"
          style={{ width: 90 }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  handleReset = (clearFilters) => {
    clearFilters();
  };

  handleTableChange = async (pagination, filters, sorter) => {
    this.setState({
      paginationInfo: pagination,
      sortedInfo: sorter,
      filteredInfo: filters,
    });
    const {
      app: { groupSelected, farmSelected },
    } = this.props;

    let search = "";
    if (Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v.length > 0) {
          if (k === "status") {
            let statusString = "";
            for (let i = 0; i < v.length; i++) {
              statusString += i === v.length - 1 ? `'${v[i]}'` : `'${v[i]}',`;
            }
            search = `${search};${k} IN (${statusString})`;
          } else if (k === "closingDate") {
            search = `${search};${k} between '${v[0]}' and '${v[1]}'`;
          } else {
            search = `${search};${k}='${v}'`;
          }
        }
      });
    }
    await this.fetchData(
      groupSelected,
      farmSelected,
      pagination.current,
      sorter,
      search,
      pagination.pageSize
    );
  };

  handleEdit = async (id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      benchmarkActions,
    } = this.props;
    await Promise.all([
      benchmarkActions.showBenchmark(groupId, farmId, id),
      benchmarkActions.showDrawer(),
    ]);
  };

  render() {
    const {
      app: { translation },
      benchmark: { data, isLoading },
      benchmarkActions,
    } = this.props;

    const Column = Table.Column;

    const menu = (id) => {
      const {
        app: { translation },
      } = this.props;
      return (
        <Menu>
          <Menu.Item key="1" onClick={() => this.handleEdit(id)}>
            {translation.table.menu.edit}
          </Menu.Item>
        </Menu>
      );
    };

    return (
      <CardCustom>
        <Row type="flex" justify="space-between" className="rowHeader">
          <Col>
            <Title>{translation.benchmark.table.title}</Title>
          </Col>
          <Col>
            <ButtonStandard
              type="button"
              buttonType="type8"
              onClick={() => benchmarkActions.showDrawer("new")}
            >
              {translation.benchmark.buttonNewBenchmark}
            </ButtonStandard>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              loading={isLoading}
              rowKey="id"
              size="small"
              dataSource={data != null ? data : []}
              pagination={{
                total: data != null ? data.length : 0,
                size: data != null ? data.length : 0,
                hideOnSinglePage: true,
              }}
              scroll={{
                x: true,
              }}
              style={{ width: "100%", margin: "0" }}
              onChange={this.handleTableChange}
            >
              <Column
                title={translation.benchmark.table.columns.status}
                dataIndex="status"
                key="status"
                align="left"
                sorter
                sortDirections={["descend", "ascend"]}
                render={(status) =>
                  status === "Active" ? (
                    <TagStatus
                      background="#C5F1CA"
                      borderColor="#106518"
                      color="#106518"
                    >
                      {translation.status.active}
                    </TagStatus>
                  ) : (
                    <TagStatus
                      background="#FBC7C7"
                      borderColor="#D44C4C"
                      color="#D44C4C"
                    >
                      {translation.status.inactive}
                    </TagStatus>
                  )
                }
              />
              <Column
                title={translation.benchmark.table.columns.description}
                dataIndex="name"
                align="left"
                sorter
                key="name"
              />
              <Column
                align="left"
                render={(text, record) => (
                  <Dropdown
                    overlay={menu(record.id)}
                    trigger={["click"]}
                    key={record.id}
                  >
                    <MenuContainer>
                      <MenuIcon />
                    </MenuContainer>
                  </Dropdown>
                )}
              />
            </Table>
          </Col>
        </Row>
        <DrawerBenchmark />
      </CardCustom>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  benchmark: state.benchmark,
});

const mapDispatchToProps = (dispatch) => ({
  benchmarkActions: bindActionCreators(BenchmarkActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Benchmark)
);
