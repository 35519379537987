import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
// Components
import { Col, DatePicker, Row, Select, Spin, notification } from "antd";
import CustomDivider from "../../utils/customDivider";
import ExportExcelIcon from "../../utils/icons/export/excel";
import {
  Container,
  ExtensionButton,
  ExtensionContainer,
  Title,
} from "./styles";
// Services
import { processReportNutritionalConsumption } from "../../../services/reportService";
import moment from "moment";
import { getLotIndexDropDown } from "../../../services/lotService";
import { findLatest } from "../../../services/picketSupplementSupplyService";

const ExportReportNutritionalConsumptionModal = ({
  modalVisible,
  handleCloseModal,
}) => {
  // Variables
  const [errors, setErrors] = useState([]);
  const [reportDate, setReportDate] = useState(null);
  const [lotIds, setLotIds] = useState([]);
  const [lots, setLots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // Redux Variables
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  // Methods
  const validateBody = () => {
    let errorsArray = [];
    if (!reportDate) {
      errorsArray.push("reportDate");
    }
    setErrors(errorsArray);
    return errorsArray.length === 0;
  };
  const fetchLots = useCallback(async () => {
    const {
      data: { results },
    } = await getLotIndexDropDown({
      groupId,
      farmId,
    });
    setLots(
      results.filter(
        (lot) =>
          (lot.status === "Active" ||
            lot.status === "A" ||
            lot.status === "Pending" ||
            lot.status === "P") &&
          lot.currentAmountAnimals > 0
      )
    );
  }, [groupId, farmId]);

  async function handleGetFile(reportType) {
    if (!validateBody()) return;
    setIsLoading(true);
    notification.info({
      message:
        "O seu relatório está sendo preparado e logo será feito o download!",
    });
    const body = {
      lotIds,
      reportDate: reportDate,
    };
    setTimeout(() => {
      setIsLoading(false);
      handleCloseModal();
    }, 100);
    try {
      const { data } = await processReportNutritionalConsumption({
        groupId,
        farmId,
        body,
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      let link = document.createElement("a");
      link.href = downloadUrl;
      const dayFormat = moment().format("YYYYMMDD");
      const hourFormat = moment().format("HHmm");
      link.setAttribute(
        "download",
        `Tratos_Consumo_de_Racao_Suplemento_${dayFormat}_${hourFormat}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();

      notification.success({
        message: "Relatório gerado com sucesso.",
      });
    } catch (error) {
      notification.error({
        message:
          "Houve um erro ao gerar relatório. Entre em contato com o suporte",
      });
    }
  }

  useEffect(() => {
    fetchLots();
    return () => {
      setLots([]);
    };
  }, [fetchLots]);

  useEffect(() => {
    async function getLastestPicketSupply() {
      try {
        const {
          data: { results },
        } = await findLatest({
          groupId,
          farmId,
        });
        if (results) {
          setReportDate(moment(results.supplyDate));
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (modalVisible) {
      getLastestPicketSupply();
      setLotIds([]);
      setReportDate(null);
      setErrors([]);
    }

    return () => {
      setLotIds([]);
      setReportDate(null);
      setErrors([]);
    };
  }, [farmId, groupId, modalVisible]);

  return (
    <Container
      width={600}
      visible={modalVisible}
      centered
      closable={true}
      footer={null}
      onCancel={handleCloseModal}
    >
      <Spin spinning={isLoading}>
        <Row type="flex">
          <Col span={24}>
            <Title>Relatório de Tratos/Consumo de Ração/Suplemento</Title>
          </Col>
        </Row>
        {errors.length > 0 && (
          <Row type="flex" justify="center">
            <label className="error">{translation.error.formError}</label>
          </Row>
        )}
        <Row type="flex" gutter={16}>
          <Col span={12}>
            <Row>
              <label
                htmlFor="reportDate"
                className={`${errors.includes("reportDate") ? "error" : ""}`}
              >
                Data da Emissão*
              </label>
            </Row>
            <Row>
              <DatePicker
                name="reportDate"
                format={"DD/MM/YYYY"}
                value={reportDate ?? undefined}
                placeholder={translation.defaultPlaceholder}
                disabledDate={(current) => current.isAfter(moment())}
                onChange={(date) => setReportDate(date)}
              />
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <label htmlFor="lotIds">Lotes</label>
            </Row>
            <Row>
              <Select
                name="lotIds"
                value={lotIds || undefined}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder={translation.defaultMultiSelectPlaceholder}
                mode="multiple"
                onChange={(value) => {
                  setLotIds(value);
                }}
              >
                {lots.map((lot) => (
                  <Select.Option value={lot.id} key={lot.id}>
                    {lot.name}
                  </Select.Option>
                ))}
              </Select>
            </Row>
          </Col>
        </Row>
        <CustomDivider dashed title="EXTENSÃO" />
        <Row type="flex">
          <Col span={24}>
            <ExtensionContainer>
              <ExtensionButton onClick={() => handleGetFile("EXCEL")}>
                <ExportExcelIcon />
              </ExtensionButton>
            </ExtensionContainer>
          </Col>
        </Row>
      </Spin>
    </Container>
  );
};

export default ExportReportNutritionalConsumptionModal;
