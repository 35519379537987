import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as LotReceiptActions } from "../../../store/ducks/lotReceipt";

import {
  Card,
  Col,
  Drawer,
  Icon,
  Modal,
  Row,
  Skeleton,
  Spin,
  Statistic,
  Table,
  notification,
} from "antd";
import { Container, HeaderContainer } from "./styles";
import moment from "moment";
import { numberMask } from "../../../services/helpersMethodsService";
import ButtonStandard from "../../utils/button";
import DrawerLotReceiptItemEntry from "../lotReceiptItemEntry";
import { useCallback } from "react";
import { deleteLotItemEntryService } from "../../../services/lotReceiptService";
import Paragraph from "antd/lib/typography/Paragraph";

const DrawerLotReceiptItemDetails = () => {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const {
    lotId,
    lotItemId,
    isLoadingDataDetails,
    showDrawerDetails,
    lotReceiptItemDetailsData,
  } = useSelector((state) => state.lotReceipt);

  const dispatch = useDispatch();

  const isFromBoitel = useMemo(() => {
    if (lotReceiptItemDetailsData != null) {
      return lotReceiptItemDetailsData.boitelScenarioSimulationId != null;
    }
    return false;
  }, [lotReceiptItemDetailsData]);

  const handleRefresh = useCallback(() => {
    dispatch(
      LotReceiptActions.getDetailsLotReceiptItem(
        groupId,
        farmId,
        lotId,
        lotItemId
      )
    );
  }, [dispatch, groupId, farmId, lotId, lotItemId]);

  const handleOpenDrawer = (visible) => {
    if (visible) {
    }
  };
  const handleCloseDrawer = () => {
    dispatch(LotReceiptActions.openOrHideDrawerDetails());
  };
  const handleOpenDrawerItemEntry = (entry = null) => {
    dispatch(LotReceiptActions.openOrHideDrawerItemEntry(entry));
  };
  const handleDelete = useCallback(
    (record) => {
      Modal.confirm({
        title: `Você realmente deseja deletar a entrada ${moment(
          record.entryDate
        ).format("DD/MM/YYYY")} ?`,
        width: 500,
        content:
          "Esta ação é irreversível e os animais criados pela entrada serão inativados.",
        centered: true,
        okText: "Não",
        onOk: () => {
          return;
        },
        cancelText: "Sim",
        onCancel: async () => {
          try {
            await deleteLotItemEntryService({
              lotId,
              lotItemId,
              groupId,
              farmId,
              lotItemEntryId: record.id,
            });
            notification.success({
              message: "Entrada deletada com sucesso!",
            });
            handleRefresh();
          } catch (error) {
            if (Object.keys(error).includes("response")) {
              const { response } = error;
              if (Object.keys(response).includes("data")) {
                const {
                  data: { code: errorCode },
                } = response;
                if (errorCode === 5005) {
                  notification.error({
                    message: "Erro",
                    description: `Esta Entrada de Animais não pode ser excluída, pois os animais gerados por ela já possuem eventos posterios a data de entrada.`,
                  });
                } else {
                  notification.error({
                    message: "Erro",
                    description: "Erro ao deletar entrada de animais.",
                  });
                }
              }
            } else {
              notification.error({
                message: "Erro",
                description: "Erro ao deletar entrada de animais.",
              });
            }
          }
        },
      });
    },
    [lotId, lotItemId, groupId, farmId, handleRefresh]
  );

  return (
    <Drawer
      title="Detalhes - Lote de Recebimento"
      visible={showDrawerDetails}
      width={"50vw"}
      afterVisibleChange={handleOpenDrawer}
      onClose={handleCloseDrawer}
    >
      <Spin spinning={isLoadingDataDetails}>
        {lotReceiptItemDetailsData ? (
          <Container>
            <Row type="flex" align="middle">
              <Col span={8}>
                <HeaderContainer>
                  <div className="title-container">
                    <div className="title">
                      {isFromBoitel && (
                        <>
                          <label>Boitel</label>
                          <br />
                        </>
                      )}
                      <span>{lotReceiptItemDetailsData.lotName}</span>
                    </div>
                  </div>
                </HeaderContainer>
              </Col>
              <Col span={16}>
                <Row type="flex" justify="space-between">
                  <Col
                    span={6}
                    style={{ border: "2px solid #9074bf", borderRadius: 5 }}
                  >
                    <Statistic
                      title="Total"
                      style={{ textAlign: "center" }}
                      value={lotReceiptItemDetailsData.amount}
                      suffix="cab."
                      precision={0}
                    />
                  </Col>
                  <Col
                    span={6}
                    style={{ border: "2px solid #9074bf", borderRadius: 5 }}
                  >
                    <Statistic
                      title="Recebidos"
                      style={{ textAlign: "center" }}
                      value={lotReceiptItemDetailsData.amountAnimalsConfirmed}
                      suffix="cab."
                      precision={0}
                    />
                  </Col>
                  <Col
                    span={6}
                    style={{ border: "2px solid #9074bf", borderRadius: 5 }}
                  >
                    <Statistic
                      title="Identificados"
                      style={{ textAlign: "center" }}
                      value={lotReceiptItemDetailsData.amountAnimalsIdentified}
                      suffix="cab."
                      precision={0}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row type="flex" style={{ marginTop: 16 }}>
              <Col span={6}>
                <Row>
                  <span>Fornecedor</span>
                </Row>
                <Row>
                  <strong>{lotReceiptItemDetailsData.supplierName}</strong>
                </Row>
              </Col>
              <Col span={6}>
                <Row>
                  <span>Código Import.</span>
                </Row>
                <Row>
                  <strong>
                    <Paragraph
                      copyable={{
                        text: new String(
                          `${lotReceiptItemDetailsData.importCode}` || "-"
                        ),
                      }}
                    >
                      {lotReceiptItemDetailsData.importCode || "-"}
                    </Paragraph>
                  </strong>
                </Row>
              </Col>
              {isFromBoitel && (
                <>
                  <Col span={12}>
                    <Row>
                      <span>Identificação</span>
                    </Row>
                    <Row>
                      <strong>
                        {lotReceiptItemDetailsData.identification}
                      </strong>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <span>Faixa de peso</span>
                    </Row>
                    <Row>
                      <strong>{`${numberMask(
                        lotReceiptItemDetailsData.boitelInitialWeightRange,
                        false
                      )} - ${numberMask(
                        lotReceiptItemDetailsData.boitelFinalWeightRange,
                        false
                      )} kg`}</strong>
                    </Row>
                  </Col>
                </>
              )}
            </Row>
            <Row type="flex" style={{ marginTop: 16 }}>
              <Col span={6}>
                <Row>
                  <span>Data de entrada</span>
                </Row>
                <Row>
                  <strong>
                    {lotReceiptItemDetailsData.entryDate
                      ? moment(lotReceiptItemDetailsData.entryDate).format(
                          "DD/MM/YYYY"
                        )
                      : "-"}
                  </strong>
                </Row>
              </Col>
              <Col span={6}>
                <Row>
                  <span>Raça</span>
                </Row>
                <Row>
                  <strong>{lotReceiptItemDetailsData.breedName}</strong>
                </Row>
              </Col>
              <Col span={6}>
                <Row>
                  <span>Sexo</span>
                </Row>
                <Row>
                  <strong>
                    {translation.animalGender[lotReceiptItemDetailsData.gender]}
                  </strong>
                </Row>
              </Col>
              {!isFromBoitel && (
                <Col span={6}>
                  <Row>
                    <span>Idade</span>
                  </Row>
                  <Row>
                    <strong>
                      {lotReceiptItemDetailsData.ageInMonths} mêses
                    </strong>
                  </Row>
                </Col>
              )}
            </Row>
            {/* Table headers */}
            <Row
              type="flex"
              justify="space-between"
              style={{ marginTop: 16, borderBottom: "2px solid #4f4f4f" }}
            >
              <Col span={8}>
                <strong>Entradas</strong>
              </Col>
              <Col span={8}>
                <Row type="flex" justify="end" gutter={8}>
                  <Col span={4}>
                    <ButtonStandard
                      buttonType="typeWithoutBackground"
                      onClick={handleRefresh}
                    >
                      <Icon type="reload" />
                    </ButtonStandard>
                  </Col>
                  <Col span={4}>
                    <ButtonStandard
                      buttonType="typeWithoutBackground"
                      onClick={() => handleOpenDrawerItemEntry()}
                    >
                      <Icon type="plus-circle" />
                    </ButtonStandard>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* Entries */}
            <Table
              rowKey="id"
              dataSource={lotReceiptItemDetailsData.lotItemEntries || []}
            >
              <Table.Column
                dataIndex="entryDate"
                title="Data de Entrada"
                render={(text) => (
                  <span>{moment(text).format("DD/MM/YYYY")}</span>
                )}
              />
              <Table.Column dataIndex="amountAnimals" title="Qtd. de Animais" />
              <Table.Column
                dataIndex="averageWeight"
                title="Peso médio"
                render={(text) => (
                  <span>{`${numberMask(text, false)} kg`}</span>
                )}
              />
              <Table.Column dataIndex="lotDestinationName" title="Lote" />
              <Table.Column
                dataIndex="menu"
                title=""
                render={(text, record) =>
                  record.last === true ? (
                    <ButtonStandard
                      buttonType="typeWithoutBackground"
                      onClick={() => handleDelete(record)}
                    >
                      <Icon type="delete" />
                    </ButtonStandard>
                  ) : null
                }
              />
            </Table>
          </Container>
        ) : (
          <Skeleton active />
        )}
      </Spin>
      <DrawerLotReceiptItemEntry />
    </Drawer>
  );
};

export default DrawerLotReceiptItemDetails;
