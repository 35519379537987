import React, { useEffect, useState } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { Creators as FarmActions } from "../../../store/ducks/farm";

// Libs
import {
	Spin,
	Row,
	Col,
	Table,
	Menu,
	Dropdown,
	Icon,
} from "antd";

import { Container, } from "./styles";

//Components
import ButtonStandard from "../../utils/button";
import FormFarmOwnerDrawer from "./form";
import MenuIcon from "../../utils/table/icons/menu";
import { Container as MenuContainer } from "../../utils/table/menu/styles";


// Services
import { numberMask } from "../../../services/helpersMethodsService";

const FarmOwner = ({ onClose, drawerVisible, }) => {
	/** Redux variables */
	const {
		app: {
			translation,
		},
		farm: { owners: ownersData }
	} = useSelector((state) => state);

	// State
	const [isLoading, setIsLoading] = useState(false);
	const [ownerIdSelected, setOwnerIdSelected] = useState(null);
	const [ownerSelected, setOwnerSelected] = useState(null);
	const [isDrawerFormVisible, setIsDrawerFormVisible] = useState(false);

	const { Column } = Table;
	const dispatch = useDispatch();

	function renderMenu(record, index) {
		return record.groupId !== null ? (
			<Menu>
				{record.id !== null ? (
					<Menu.Item key="0">
						<button
							onClick={() => handleEditOwner(record)}
						>
							{translation.table.menu.edit}
						</button>
					</Menu.Item>
				) : null}
				<Menu.Item key="1">
					<button
						onClick={() => handleDeleteOwner(index)}
					>
						{translation.table.menu.delete}
					</button>
				</Menu.Item>

			</Menu>)
			: <></>
	}

	const handleDeleteOwner = (index) => {
		let owners = ownersData.filter((item, i) => index !== i);
		dispatch(FarmActions.updateOwnersData([...owners]));
	}

	const handleEditOwner = (record) => {
		setOwnerSelected(record);
		setOwnerIdSelected(record.id);
		setIsDrawerFormVisible(true);
	}

	const handleShowOwnerFormDrawer = (record) => {
		setOwnerSelected(null);
		setOwnerIdSelected(null);
		setIsDrawerFormVisible(true);
	};

	const handleSaveFormOwner = (newData) => {
		if (newData.id !== null) {
			const cloneOwnersData = [...ownersData];
			let ownerIndex = cloneOwnersData.findIndex(owner => owner.id === newData.id);
			if (ownerIndex !== -1) {
				cloneOwnersData[ownerIndex] = newData;
				dispatch(FarmActions.updateOwnersData([...cloneOwnersData]));
			}
		} else {
			dispatch(FarmActions.updateOwnersData([...ownersData, newData]));
		}
		setIsDrawerFormVisible(false);
	};

	const handleCancelFormOwner = () => {
		setIsDrawerFormVisible(false);
	};

	return (
		<Container
			title={translation.farmOwner.title}
			width={640}
			onClose={onClose}
			maskClosable={false}
			visible={drawerVisible}
		>
			<div className="drawerForm">
				<Spin spinning={isLoading}>
					<Row type="flex" justify="space-between" style={{ marginBottom: "19px" }}>

						<Col span={24} align="right">
							<Row>
								<ButtonStandard buttonType="type8"
									onClick={() => handleShowOwnerFormDrawer(null)}
								>
									{translation.farmOwner.buttonNew}
								</ButtonStandard>
							</Row>

						</Col>
					</Row>

					<Table
						rowKey={((record, index) => record.id !== null ? record.id : index)}
						size="small"
						dataSource={ownersData !== null ? ownersData : []}
						scroll={{
							x: true,
						}}
						pagination={{
							showSizeChanger: true,
							defaultPageSize: 10,
							pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
						}}
						style={{ width: "100%", margin: "0" }}
					>
						<Column
							title={translation.farmOwner.table.columns.code}
							dataIndex="code"
							key="code"
							align="left"
						/>
						<Column
							title={translation.farmOwner.table.columns.name}
							dataIndex="name"
							key="name"
							align="left"
						/>
						<Column
							title={translation.farmOwner.table.columns.document}
							dataIndex="document"
							key="document"
							align="left"
						/>
						<Column
							title={translation.farmOwner.table.columns.participation}
							dataIndex="participation"
							key="participation"
							align="left"
							render={(participation) =>
								<span>
									{numberMask(participation || 0, false)}
								</span>}
						/>
						<Column
							title={translation.farmOwner.table.columns.mainOwner}
							dataIndex="mainOwner"
							key="mainOwner"
							align="left"
							render={(mainOwner) => mainOwner ? (
								<Icon
									type="check-circle"
									theme="filled"
									className="iconCheck"
								/>
							) : null}
						/>
						<Column
							width={50}
							align="left"
							render={(text, record, index) => (
								<Dropdown
									overlay={renderMenu(record, index)}
									trigger={["click"]}
									key={record.id}
								>
									<MenuContainer>
										<MenuIcon />
									</MenuContainer>
								</Dropdown>
							)}
						/>
					</Table>
				</Spin>
			</div>

			<FormFarmOwnerDrawer
				drawerVisible={isDrawerFormVisible}
				ownerId={ownerIdSelected}
				ownerData={ownerSelected}
				onSave={handleSaveFormOwner}
				onCancel={handleCancelFormOwner}
			/>
		</Container>
	);
}

export default FarmOwner;