import styled from "styled-components";

export const Container = styled.div`
  min-height: 500px;
  padding: 5px 5px;

  strong.subTitle {
    font-family: "Asap", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;

    color: #4b4b4b;

    margin-left: 8px;
  }

  .ant-collapse {
    width: 100%;
    background: #fcfcfc;
    border-radius: 5px;
  }
  .ant-list-split .ant-list-item {
    border-bottom: none;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    position: relative;
    padding: 12px 16px;
    padding-left: 40px;
    line-height: 22px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-family: "Asap", sans-serif;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
    color: #4b4b4b;
    background-color: #fcfcfc;
  }

  .ant-collapse-content {
    border-top: none;
  }

  .ant-collapse > .ant-collapse-item {
    box-shadow: rgb(216, 216, 216) 0px 5px 3px !important;
  }

  .ant-collapse-arrow {
    box-shadow: rgb(216, 216, 216) 0px 6px 2px;
    padding: 2px;
  }
`;
export const IconContainer = styled.div`
  svg {
    margin-right: 5px;
  }
`;
