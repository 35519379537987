import React from "react";

// import { Container } from './styles';

const TextToolIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
  >
    <g id="text-tool" transform="translate(-8.72)">
      <path
        id="Path_1938"
        data-name="Path 1938"
        d="M86.908,3.477a.58.58,0,0,0,.58-.58V.58a.58.58,0,0,0-.58-.58H84.59a.579.579,0,0,0-.58.58v.58H73.965V.58a.579.579,0,0,0-.58-.58H71.068a.58.58,0,0,0-.58.58V2.9a.58.58,0,0,0,.58.58h.58V13.523h-.58a.58.58,0,0,0-.58.58V16.42a.58.58,0,0,0,.58.58h2.318a.58.58,0,0,0,.58-.58v-.58H84.011v.58a.58.58,0,0,0,.58.58h2.318a.58.58,0,0,0,.58-.58V14.1a.58.58,0,0,0-.58-.58h-.58V3.477ZM85.17,1.159h1.159V2.318H85.17Zm-13.523,0h1.159V2.318H71.647Zm1.159,14.682H71.647V14.682h1.159Zm13.523,0H85.17V14.682h1.159ZM85.17,13.523h-.58a.579.579,0,0,0-.58.58v.58H73.965V14.1a.58.58,0,0,0-.58-.58h-.58V3.477h.58a.579.579,0,0,0,.58-.58v-.58H84.011V2.9a.579.579,0,0,0,.58.58h.58Zm0,0"
        transform="translate(-61.768)"
        fill="#fff"
      />
      <path
        id="Path_1939"
        data-name="Path 1939"
        d="M195.453,106H188.12a.588.588,0,0,0-.632.531V107.8a.642.642,0,0,0,1.264,0v-.743h2.4V113h-.421a.539.539,0,1,0,0,1.061h2.107a.539.539,0,1,0,0-1.061h-.421v-5.943h2.4v.743a.642.642,0,0,0,1.264,0v-1.273A.588.588,0,0,0,195.453,106Zm0,0"
        transform="translate(-174.603 -101.533)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default TextToolIcon;
