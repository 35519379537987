import { useContext } from "react";
import { VeterinaryContext } from "../../contexts/veterinaryContext";

const useVeterinaryContext = () => {
  const value = useContext(VeterinaryContext);

  return value;
};

export default useVeterinaryContext;
