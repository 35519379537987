import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

//Libs
import { Col, Divider, Input, Modal, notification, Row, Spin } from "antd";
import axios from "axios";
import moment from "moment";

// Components
import { Container, ButtonWithoutBackground, Form } from "./styles";
import ButtonStandard from "../../utils/button";
import NumberFormat from "react-number-format";

// Services
import {
  findFarmWeatherStationRainData,
  updateFarmWeatherStationRainDate,
} from "../../../services/farmWeatherStationService";

const step = -7;
const JsonFormat = "YYYY-MM-DD";

const FarmWeatherRainInfoModal = ({ isModalVisible, closeModal }) => {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const [isLoading, setIsLoading] = useState(false);

  const [startDate, setStartDate] = useState(moment().add(step, "day"));

  const [form, setForm] = useState([]);

  const fetchData = useCallback(
    async (newStartDate = null, newEndDate = null) => {
      try {
        setIsLoading(true);
        const {
          data: { results: response },
        } = await findFarmWeatherStationRainData({
          groupId,
          farmId,
          startDate: newStartDate
            ? newStartDate.format(JsonFormat)
            : moment().add(step, "day"),
          endDate: newEndDate
            ? newEndDate.format(JsonFormat)
            : moment().subtract(1, "days").format(JsonFormat),
        });

        response.reverse();
        setForm((prevState) => [...prevState, ...response]);
      } catch (error) {
        console.error(error);
        notification.error({
          message: "Error interno ao trazer os dados. Contate o suporte!",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [groupId, farmId]
  );

  const handleStartDateChange = (startDate) => {
    let newEndDate = moment(startDate).subtract(1, "day");

    let newStartDate = moment(startDate);
    newStartDate.add(step, "day");
    setStartDate(newStartDate);

    fetchData(newStartDate, newEndDate);
  };

  const handleCancel = useCallback(() => {
    setStartDate(moment().add(step, "day"));
    setForm([]);
    closeModal();
  }, []);

  const handleSubmit = useCallback(
    async (form) => {
      try {
        setIsLoading(true);
        await updateFarmWeatherStationRainDate({
          groupId,
          farmId,
          body: form,
        });

        notification.success({
          message: "Informações climáticas atualizadas com sucesso!",
        });

        setIsLoading(false);
        handleCancel();
      } catch (error) {
        console.error(error);
        notification.error({
          message: "Error interno. Contate o suporte!",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [groupId, farmId]
  );

  const handlePreciptationValueChange = useCallback(
    (value, date) => {
      const formClone = [...form];
      let index = formClone.findIndex((f) => f.date === date);
      if (index != null) {
        formClone[index].preciptation = value;
        setForm([...formClone]);
      }
    },
    [form]
  );

  useEffect(() => {
    if (groupId != null && farmId != null && isModalVisible) {
      fetchData(moment().add(step, "day"));
    }
  }, [groupId, farmId, isModalVisible]);

  return (
    <Modal
      visible={isModalVisible}
      centered
      closable={false}
      footer={null}
      title="Volume de chuvas"
    >
      {isModalVisible ? (
        <Container>
          <Spin spinning={isLoading}>
            <Row type="flex" justify="space-between" align="middle" gutter={8}>
              <Col span={10}>
                <label>DATA</label>
              </Col>
              <Col span={10}>
                <label>PRECIPTAÇÃO (MM)</label>
              </Col>
            </Row>
            <Form>
              {form.map((data) => {
                return (
                  <Row
                    key={data.date}
                    type="flex"
                    className="row-date"
                    justify="space-between"
                    align="middle"
                    gutter={8}
                  >
                    <Col span={10}>
                      <span className="cell-date">
                        {moment(data.date).format("DD/MM/YYYY")}
                      </span>
                    </Col>
                    <Col span={10}>
                      <NumberFormat
                        name="preciptation"
                        value={
                          data?.preciptation !== null ? data.preciptation : null
                        }
                        customInput={Input}
                        allowNegative={false}
                        addonAfter="mm"
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        onValueChange={({ floatValue }) =>
                          handlePreciptationValueChange(
                            floatValue > 10000 ? 9999 : floatValue,
                            data.date
                          )
                        }
                        placeholder={translation.defaultPlaceholder}
                      />
                    </Col>
                  </Row>
                );
              })}
            </Form>
            <Divider />
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="row-button"
            >
              <Col align="start">
                <ButtonStandard
                  type="button"
                  buttonType="type4"
                  size="s"
                  onClick={() => handleCancel()}
                >
                  {translation.buttons.cancel}
                </ButtonStandard>
              </Col>
              <Col align="center">
                <ButtonWithoutBackground
                  type="button"
                  onClick={() => handleStartDateChange(startDate)}
                  // disabled={true}
                >
                  Carregar mais
                </ButtonWithoutBackground>
              </Col>
              <Col align="right">
                <ButtonWithoutBackground
                  type="submit"
                  onClick={() => handleSubmit(form)}
                >
                  Salvar
                </ButtonWithoutBackground>
              </Col>
            </Row>
          </Spin>
        </Container>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default FarmWeatherRainInfoModal;
