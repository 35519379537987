import React from 'react';

// import { Container } from './styles';

const SpanishBlankFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="22" viewBox="0 0 27 22">
    <g id="unselect" transform="translate(0.193)">
      <path
        id="Caminho_702"
        data-name="Caminho 702"
        d="M3.111,0H23.889A3.1,3.1,0,0,1,27,3.09V18.91A3.1,3.1,0,0,1,23.889,22H3.111A3.1,3.1,0,0,1,0,18.91V3.09A3.1,3.1,0,0,1,3.111,0Z"
        transform="translate(-0.193 0)"
        fill="#c4c4c4"
      />
      <rect
        id="Retângulo_223"
        data-name="Retângulo 223"
        width="27"
        height="10"
        transform="translate(-0.193 5.905)"
        fill="#e8e5e5"
      />
      <path
        id="Caminho_701"
        data-name="Caminho 701"
        d="M0,0H10.219A3.076,3.076,0,0,1,13.28,3.09V18.91A3.076,3.076,0,0,1,10.219,22H0Z"
        transform="translate(13.527 0)"
        fill="#0f0f0f"
        opacity="0.11"
      />
    </g>
  </svg>
);

export default SpanishBlankFlag;
