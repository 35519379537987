import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import moment from "moment";
/** Redux libs */
/** Components */
import { Container, MessageContainer } from "./styles";
import {
  Row,
  Col,
  Icon,
  Table,
  Divider,
  Spin,
  Popover,
  Input,
  Button,
  notification,
} from "antd";
import ButtonStandard from "../../../components/utils/button";
import ExclamationIcon from "../../../components/utils/icons/exclamation";
import BreadCrumbs from "../../../components/utils/breadCrumbs";
import TrashIcon from "../../../components/utils/icons/trash";
/** Services */
import { numberMask } from "../../../services/helpersMethodsService";
import {
  deleteBoitelSaleScenarioFarmSellService,
  getAnimalsBoitelSaleScenarioFarmSellService,
} from "../../../services/boitelSaleScenarioFarmSellService";

const BoitelSaleScenarioFarmSellDetails = () => {
  const dateFormat = "DD/MM/YYYY";
  let searchInput = useRef(null);
  const [form, setForm] = useState({
    saleScenarioResultId: null,
    clientName: null,
    sellDate: null,
    priceNegotiated: null,
    weightPerAnimal: null,
    carcassHarnessing: null,
    amountAnimalsSold: null,
    totalBilling: null,
    totalMarginBilling: null,
    estimatedBilling: null,
  });
  const [animalsData, setAnimalsData] = useState(null);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [loadingInfoFarmSell, setLoadingInfoFarmSell] = useState(false);
  const [isLoadingAnimals, setIsLoadingAnimals] = useState(false);
  /** Props variable */
  const {
    boitelSaleScenarioId,
    resultId: boitelSaleScenarioResultId,
    id: farmSellId,
  } = useParams();
  const history = useHistory();
  /** Redux variables */
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
    translation,
  } = useSelector((state) => state.app);
  const { dataBoitelSaleScenarioFarmSell } = useSelector(
    (state) => state.boitelSaleScenarioFarmSell
  );
  /** Effects */
  useEffect(() => {
    async function fetch() {
      setLoadingInfoFarmSell(true);
      setIsLoadingAnimals(true);
      try {
        const {
          data: { results: listAnimals },
        } = await getAnimalsBoitelSaleScenarioFarmSellService({
          groupId,
          farmId,
          boitelSaleScenarioId,
          boitelSaleScenarioResultId,
          id: farmSellId,
        });
        setForm(dataBoitelSaleScenarioFarmSell);
        setAnimalsData(listAnimals);
      } catch (error) {
      } finally {
        setLoadingInfoFarmSell(false);
        setIsLoadingAnimals(false);
      }
    }
    fetch();
  }, [
    boitelSaleScenarioId,
    boitelSaleScenarioResultId,
    dataBoitelSaleScenarioFarmSell,
    farmId,
    farmSellId,
    groupId,
  ]);

  /** Methods */
  function showOrHidePopover() {
    setPopoverVisible(!popoverVisible);
  }
  async function deleteFarmSell() {
    try {
      await deleteBoitelSaleScenarioFarmSellService({
        groupId,
        farmId,
        boitelSaleScenarioId,
        boitelSaleScenarioResultId,
        id: farmSellId,
      });
      notification.success({
        message: "Venda excluída com sucesso.",
      });
      history.push(
        `/admin/decisions/scenarios/sales/boitel/${boitelSaleScenarioId}/results/${boitelSaleScenarioResultId}`
      );
    } catch (error) {
      notification.error({
        message: "Houve um erro ao excluir à venda.",
      });
    }
  }
  /** Table methds */
  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        if (dataIndex === "lot.name") {
          return record.lot.name.includes(value);
        } else {
          return record[dataIndex].toString().includes(value);
        }
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select());
        }
      },
    };
  }
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
  }
  function handleReset(clearFilters) {
    clearFilters();
  }
  return (
    <Container>
      <Row type="flex" align="middle" className="header">
        <Col xs={1} sm={1} md={1} lg={1} xl={1}>
          <Icon
            type="arrow-left"
            className="iconBack"
            onClick={() => history.goBack()}
          />
        </Col>
        <Col xs={23} sm={23} md={23} lg={23} xl={23} align="right">
          <BreadCrumbs>
            <Link to="/admin/decisions?tabSelect=4">
              <span className="pageTreeInative">Decisões</span>
            </Link>
            <Link
              to={`/admin/decisions/scenarios/sales/boitel/${boitelSaleScenarioId}/results/${boitelSaleScenarioResultId}`}
            >
              <span className="pageTreeInative">Detalhes: Boitel - Venda</span>
            </Link>
            <span className="pageTreeActive">Detalhes: Venda</span>
          </BreadCrumbs>
        </Col>
      </Row>
      {/* Form */}
      <Spin spinning={loadingInfoFarmSell}>
        <Row type="flex">
          <Col span={16}>
            <div className="infoContainer first">
              <div className="infoGroup">
                <span>Cliente</span>
                <span>
                  <strong>{form.clientName}</strong>
                </span>
              </div>
              <div className="infoGroup">
                <span>Data de saída</span>
                <span>
                  <strong>
                    {form.sellDate
                      ? moment(form.sellDate).format(dateFormat)
                      : "-"}
                  </strong>
                </span>
              </div>
              <div className="infoGroup">
                <span>Faturamento final</span>
                <span>
                  <strong>{`${numberMask(
                    form.totalBilling || 0,
                    true
                  )}`}</strong>
                </span>
              </div>
            </div>
          </Col>
          <Col span={8} className="colWithBorderLeft">
            <div className="infoContainer">
              <div className="infoGroup">
                <span>Animais</span>
                <span>
                  <strong>{form.amountAnimalsSold || 0}</strong>
                </span>
              </div>
              <div className="infoGroup">
                <span>Fat. estimado</span>
                <span>
                  <strong>{`${numberMask(
                    form.estimatedBilling || 0,
                    true
                  )}`}</strong>
                </span>
              </div>
              <div className="infoGroup">
                <Popover
                  placement="bottomRight"
                  visible={popoverVisible}
                  content={
                    <MessageContainer>
                      <div>
                        <ExclamationIcon />
                        <span>
                          Ao confirmar, a venda será excluída e os animais
                          continurão na fazenda, sendo necessário criar uma nova
                          venda.
                        </span>
                      </div>
                      <div className="buttonContainer">
                        <ButtonStandard
                          background="#4A85AE"
                          padding="5px 10px"
                          color="#FFF"
                          fontSize="14px"
                          onClick={deleteFarmSell}
                        >
                          Confirmar
                        </ButtonStandard>
                        <ButtonStandard
                          background="#D44C4C"
                          padding="5px 10px"
                          color="#FFF"
                          fontSize="14px"
                          onClick={showOrHidePopover}
                        >
                          Cancelar
                        </ButtonStandard>
                      </div>
                    </MessageContainer>
                  }
                  trigger="click"
                >
                  <ButtonStandard
                    width="141px"
                    padding="5px 10px"
                    background="#D44C4C"
                    onClick={showOrHidePopover}
                  >
                    <TrashIcon /> Deletar venda
                  </ButtonStandard>
                </Popover>
              </div>
            </div>
          </Col>
        </Row>
      </Spin>
      <Divider dashed />
      {/* Animals Table */}
      <Row type="flex">
        <Col span={24}>
          <Table
            rowKey="id"
            loading={isLoadingAnimals}
            dataSource={animalsData ? animalsData : []}
            scroll={{
              x: true,
            }}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 10,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
            }}
          >
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns
                  .animalHandlingNumber
              }
              dataIndex="animalHandlingNumber"
              sorter={(first, second) => {
                return first.animalHandlingNumber.localeCompare(
                  second.animalHandlingNumber,
                  "pt-BR",
                  {
                    numeric: false,
                    ignorePunctuation: true,
                  }
                );
              }}
              key="animalHandlingNumber"
              width={180}
              align="left"
              {...getColumnSearchProps("animalHandlingNumber")}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns.sellDate
              }
              dataIndex="sellDate"
              sorter={(first, second) => {
                return first.sellDate.localeCompare(second.sellDate, "pt-BR", {
                  numeric: true,
                  ignorePunctuation: true,
                });
              }}
              key="sellDate"
              width={180}
              align="left"
              render={(text, record) => (
                <span>
                  {record.sellDate !== null
                    ? moment(record.sellDate).format(dateFormat)
                    : "-"}
                </span>
              )}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns.weight
              }
              dataIndex="weight"
              key="weight"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.weight - second.weight;
              }}
              {...getColumnSearchProps("weight")}
              render={(text, record) => (
                <span>
                  {record.weight !== null
                    ? `${numberMask(record.weight, false)} kg`
                    : "-"}
                </span>
              )}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns
                  .lastDailyWeight
              }
              dataIndex="lastDailyWeight"
              key="lastDailyWeight"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.lastDailyWeight - second.lastDailyWeight;
              }}
              {...getColumnSearchProps("lastDailyWeight")}
              render={(text, record) => (
                <span>
                  {record.lastDailyWeight !== null
                    ? `${numberMask(record.lastDailyWeight, false)} kg`
                    : "-"}
                </span>
              )}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns
                  .averageWeightGain
              }
              dataIndex="averageWeightGain"
              key="averageWeightGain"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.averageWeightGain - second.averageWeightGain;
              }}
              render={(text, record) => (
                <span>
                  {record.averageWeightGain !== null
                    ? `${numberMask(record.averageWeightGain, false)} kg/dia`
                    : "-"}
                </span>
              )}
              {...getColumnSearchProps("averageWeightGain")}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns
                  .aggregateArroba
              }
              dataIndex="aggregateArroba"
              key="aggregateArroba"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.aggregateArroba - second.aggregateArroba;
              }}
              render={(text, record) => (
                <span>
                  {record.aggregateArroba !== null
                    ? `${numberMask(record.aggregateArroba, false)} @`
                    : "-"}
                </span>
              )}
              {...getColumnSearchProps("aggregateArroba")}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns.saleIncome
              }
              dataIndex="saleIncome"
              key="saleIncome"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.saleIncome - second.saleIncome;
              }}
              render={(text, record) => (
                <span>
                  {record.saleIncome !== null
                    ? `${numberMask(record.saleIncome, true)}`
                    : "-"}
                </span>
              )}
              {...getColumnSearchProps("saleIncome")}
            />
            <Table.Column
              title={translation.boitelSaleScenario.details.table.columns.cost}
              dataIndex="cost"
              key="cost"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.cost - second.cost;
              }}
              render={(text, record) => (
                <span>
                  {record.cost !== null
                    ? `${numberMask(record.cost, true)}`
                    : "-"}
                </span>
              )}
              {...getColumnSearchProps("cost")}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns
                  .aggregateMargin
              }
              dataIndex="aggregateMargin"
              key="aggregateMargin"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.aggregateMargin - second.aggregateMargin;
              }}
              render={(text, record) => (
                <span>
                  {record.aggregateMargin !== null
                    ? `${numberMask(record.aggregateMargin, true)}`
                    : "-"}
                </span>
              )}
              {...getColumnSearchProps("aggregateMargin")}
            />
            <Table.Column
              title={
                translation.boitelSaleScenario.details.table.columns.margin
              }
              dataIndex="marginPercentage"
              key="marginPercentage"
              width={180}
              align="left"
              sorter={(first, second) => {
                return first.marginPercentage - second.marginPercentage;
              }}
              render={(text, record) => (
                <span>
                  {record.marginPercentage !== null
                    ? `${numberMask(record.marginPercentage * 100, false)} %`
                    : "-"}
                </span>
              )}
              {...getColumnSearchProps("margin")}
            />
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default BoitelSaleScenarioFarmSellDetails;
