import styled from "styled-components";
import { Col, Divider, Select } from "antd";

export const Container = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-color: #684e94 #e8e5e5;
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background-color: #e8e5e5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #684e94;
  }

  .headerButtonsAndMessagens {
    margin-bottom: 15px;
  }

  .ant-dropdown-trigger {
    cursor: pointer;
  }

  .back-container {
    display: flex;
    align-items: center;
    padding-right: 10px;
  }

  .back {
    position: relative;
    font-family: "Asap", sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #d44c4c;
    margin-left: 5px;
  }

  .divider {
    border-left: 2px solid #c4c4c4;
    height: 19px;
    padding-right: 9px;
  }

  .title {
    display: flex;
    align-items: center;
    font-family: "Asap", sans-serif;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    color: #c4c4c4;
    margin-top: 0px;
    margin-bottom: 10px;

    span {
      padding-left: 10px;
    }
  }

  .buttonConfig {
    margin-top: 10px;
    display: flex;
    font-family: "Asap", sans-serif;
    align-items: center;
    background: transparent;
    border: none;
    font-size: 14px;
    margin-right: 10px;
    color: #684e94;
    cursor: pointer;
    i {
      margin-right: 5px;
    }
    &:disabled {
      cursor: not-allowed;
      color: #c4c4c4;
    }
    &:hover {
      &:disabled {
        span {
          border-bottom: 1px solid #c4c4c4;
        }
      }
      span {
        border-bottom: 1px solid #684e94;
      }
    }
  }

  .title-sub {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Asap", sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #684e94;
    margin: 5px 0px 9px 0px;
    text-transform: uppercase;

    svg {
      width: 18px;
      height: 18px;
      path {
        fill: #684e94 !important;
      }
    }

    span {
      cursor: pointer;
    }
  }

  .title-sub-2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Asap", sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #684e94;
    margin: 13px 0px 17px 15px;
    text-transform: uppercase;

    svg {
      width: 18px;
      height: 18px;
      path {
        fill: #684e94 !important;
      }
    }

    span {
      cursor: pointer;
    }
  }

  .ant-tooltip {
    max-width: 400px !important;
  }

  .text-blue {
    color: #4a85ae;
    text-transform: none;
    font-weight: normal;

    svg {
      margin-left: 5px;
      width: 14px;
      height: 14px;
      path {
        fill: #4a85ae !important;
      }
    }
  }

  .flex {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    /* margin-bottom: 8px; */
    font-family: "Asap", sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #684e94;

    span {
      cursor: pointer;
    }

    .ant-dropdown-trigger {
      margin-bottom: 3px;
    }
  }

  .flex-2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 8px;
    font-family: "Asap", sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #684e94;

    span {
      cursor: pointer;
    }
  }

  .flex-start {
    display: flex;
    flex-direction: row;
    justify-content: start;

    svg {
      width: 18px;
      height: 18px;
      path {
        fill: #684e94 !important;
      }
    }
  }

  .font-grey {
    font-weight: normal;
    text-transform: none;
    color: #a5a5a5;
    margin: 0px 7px;
  }

  .card-system {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 380px;
    background: #f5f5f5;
    border-radius: 6px;
    padding: 0px 10px;
    margin-left: 7px;
    text-align: center;
    text-transform: none;
  }

  .vertical-line {
    height: 35px;
    border-right: 2px solid #e8e5e5;
    margin: 0px 5px;
  }

  .hide {
    display: none;
  }

  .text-color-green {
    font-size: 14px;
    color: #33c162;
  }

  .text-color-red {
    font-size: 14px;
    color: #d44c4c;
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
    .dropdown-harvest {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
    }
  }
`;

export const PageTitle = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  span {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #c4c4c4;
    text-transform: uppercase;
  }
  #bovexoHomeIcon {
    width: 25px;
    height: 25px;
    margin-right: 9px;
    path {
      fill: #c4c4c4;
    }
  }
`;

export const Card = styled(Col)`
  position: relative;
  margin: 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  /*height: 93px;*/
  border-radius: 8px 8px 0px 0px;
  border-bottom: none;
  box-shadow: 0px 1px 2px #d8d8d8;
  background: #fcfcfc;
  cursor: pointer;

  .hide {
    display: none;
  }

  &.border-bottom-green {
    border-bottom: 3px solid #33c162;
  }

  &.border-bottom-red {
    border-bottom: 3px solid #d44c4c !important;
  }

  &:hover {
    background: #f5f5f5;
  }

  .period-header {
    display: flex;
    justify-content: end;
    width: 100%;
    height: 21px;

    span {
      color: #4a85ae;
      font-size: 12px;
      font-weight: normal;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 33px;
    border-radius: 8px 8px 0px 0px;
    background: transparent;
    margin-bottom: 3px;
    padding: 10px 15px 0px 10px;

    span:nth-child(1) {
      color: #000;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      text-transform: uppercase;
    }

    b {
      font-size: 14px;
      padding-right: 2px;
    }
  }

  .content {
    margin: 3px 10px 3px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
      color: #a5a5a5;
    }
    .space {
      padding: 0px 8px;
    }
  }

  .content-footer {
    padding: 3px 10px 3px 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e8e5e5;
    span {
      color: #a5a5a5;
    }
    & > span {
      width: 100%;
    }
  }

  .content-footer-target {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e8e5e5;
  }

  .banner-image {
    position: absolute;
    bottom: 0;
    height: 2.8px;
    background: repeating-linear-gradient(
      -55deg,
      #ffe600,
      #ffe600 10px,
      #4a4a4a 10px,
      #4a4a4a 20px
    );
  }

  #bovexoBannerIcon {
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  .span-font {
    font-size: 16px;
    color: #684e94 !important;
  }

  &.green {
    border: 2px solid #33c162;
  }

  &.border-green {
    border: 3px solid #33c162;
  }

  &.border-red {
    border: 3px solid #d44c4c;
  }

  &.border-yellow {
    border: 3px solid #ffe600;
  }

  span {
    font-family: "Asap", sans-serif;
    text-align: left;
    font-size: 13px;
    color: #4b4b4b;
  }

  .row-space-evenly {
    justify-content: space-evenly;
  }

  .male-icon {
    width: 12px;
    margin-right: 5px;
  }

  .female-icon {
    width: 12px;
    margin-right: 5px;
  }

  .iep-births-div {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .iconError {
    color: #d44c4c;
    margin-right: 5px;
  }
`;

export const CardAlert = styled.div`
  margin-right: 8px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 90px;
  width: 32%;
  min-width: 120px;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 1px 2px #d8d8d8;
  background: #fcfcfc;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 33px;
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    background: transparent;
    padding: 3px 12px 0px 12px;

    span:nth-child(1) {
      color: #000;
      font-size: 14px;
      font-weight: bold;
      font-style: italic;
      text-transform: uppercase;
    }
  }

  .content-alert {
    margin: 0px 10px 3px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span:nth-child(1) {
      color: #d44c4c;
    }

    .text-purple {
      padding-top: 1px;
      color: #684e94 !important;
      font-weight: bold;
      text-transform: uppercase;
    }
    .card-alert {
      min-width: 107px;
      padding: 0px 10px 0px 10px;
      border: 2px solid #d44c4c;
      border-radius: 5px;
    }
  }

  .content-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .span-font {
    font-size: 16px;
    color: #684e94 !important;
  }

  &.green {
    border: 2px solid #33c162;
  }

  &.border-green {
    border: 3px solid #33c162;
  }

  &.border-red {
    border: 3px solid #d44c4c;
  }

  span {
    font-family: "Asap", sans-serif;
    text-align: left;
    font-size: 13px;
    color: #4b4b4b;
  }
`;

export const CardContainer = styled.div`
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  min-height: 317px;
  width: 100%;
  background: #fcfcfc;
  border-radius: 6px 0px 0px 6px;
  box-shadow: 0px 3px 6px #d8d8d8;
  margin-bottom: 10px;

  img {
    margin: 0px auto;
  }

  .title-1 {
    display: block;
    margin: 8px 15px;
    color: #a5a5a5;
    font-size: 14px;

    span {
      color: #9074bf;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .title-2 {
    display: block;
    margin: 8px 18px 0px 18px;
    color: #9074bf;
    font-weight: bold;
    font-size: 14px;
  }

  .sub-title {
    display: block;
    color: #a5a5a5;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
  }

  .result {
    margin-top: 15px;
    display: block;
    color: #a5a5a5;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
  }

  .sub-title-2 {
    display: block;
    color: #a5a5a5;
    font-size: 12px;
    text-transform: uppercase;
  }

  .card-1 {
    height: 265px;
    width: 100%;
    padding: 10px 15px;
    background: #f5f5f5;
    border-radius: 6px 0px 0px 6px;

    .item-2 {
      display: flex;
      flex-direction: row;
      color: #a5a5a5;
      font-size: 14px;

      b {
        font-size: 16px;
      }
    }

    .item-3 {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      color: #4b4b4b;
      font-size: 14px;

      b {
        font-size: 16px;
      }
    }

    .item-1 {
      display: flex;
      flex-direction: row;
      margin-top: 5px;
      color: #a5a5a5;
      font-size: 14px;

      .pad {
        padding-right: 8px;
        color: #000;
        font-weight: bold;
      }

      .padGoal {
        color: #4b4b4b;
      }

      b {
        font-size: 16px;
      }
    }
  }

  .card-2 {
    height: 170px;
    width: 100%;
    padding: 10px;
    padding-left: 10px;
    padding-right: 15px;
    padding-bottom: 5px;
  }

  .text-red {
    color: #d44c4c !important;
    font-style: italic;
    font-weight: bold;
    font-size: 12px;
  }

  .text-info {
    color: #4b4b4b !important;
    font-style: italic;
    font-size: 12px;
    margin: 5px 0px 5px 0px;
  }

  .text-purple {
    font-size: 12px;
    color: #684e94 !important;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
  }
`;

export const CardContainerAlert = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20vh;
  width: 100%;
  min-height: 560px;
  background: #fcfcfc;
  border-radius: 6px;
  box-shadow: 0px 3px 6px #d8d8d8;
  margin-left: 15px;
  margin-bottom: 12px;

  p {
    display: inline-block;
    margin-bottom: 0;
  }

  img {
    margin: 0px auto;
  }

  .title-1 {
    display: block;
    margin: 8px 15px;
    color: #a5a5a5;
    font-size: 14px;
  }

  .title-2 {
    display: block;
    margin: 8px 18px 0px 18px;
    color: #9074bf;
    font-weight: bold;
    font-size: 14px;
  }

  .sub-title {
    display: block;
    color: #a5a5a5;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
  }

  .text-purple {
    margin-left: 10px;
    padding-bottom: 6px;
    color: #684e94 !important;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
  }

  .text-red {
    /* margin-left: 105px; */
    margin-right: 13px;
    padding-bottom: 6px;
    color: #d44c4c !important;
    text-align: center;
  }

  .flex-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @media screen and (max-width: 769px) {
    /* start of medium tablet styles */
    margin-left: 0;
  }
`;

export const CardContainerWhatToDo = styled.div`
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background: #f5f5f5;
  border-radius: 0px 6px 6px 0px;
  box-shadow: 0px 0px 6px #d8d8d8;
  clip-path: inset(-5px -5px -5px 0px);

  img {
    margin: 0px auto;
  }

  .title-1 {
    display: block;
    margin: 8px 15px;
    color: #a5a5a5;
    font-size: 14px;
  }

  .title-2 {
    display: block;
    margin: 8px 18px 0px 18px;
    color: #9074bf;
    font-weight: bold;
    font-size: 14px;
  }

  .sub-title {
    display: block;
    color: #a5a5a5;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
  }

  .text-purple {
    padding-bottom: 6px;
    color: #684e94 !important;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
  }

  .text-red {
    margin-left: 105px;
    margin-right: 13px;
    padding-bottom: 6px;
    color: #d44c4c !important;
    text-align: center;
  }

  .flex-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const CustomDivider = styled(Divider)`
  margin: 5px 0 5px 0 !important;
  min-height: 1px;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 8px 0 8px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;

export const SelectCustom = styled(Select)`
  .ant-select-selection {
    margin: 10px 0px 10px 15px !important;
    border-top-width: 1.02px;
    border-radius: 5px !important;
    border: 2px solid #9074bf;

    ul {
      .ant-select-search {
        color: #79a9bb !important;
        background: transparent !important;
        border: none !important;
      }
      li {
        color: #c9b88a !important;
        background: #fff7e2 !important;
        border: 2px solid !important;
        border-radius: 6px !important;
        svg {
          fill: black;
        }
      }
    }
  }
`;

export const CardItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  width: 100%;
  padding: 3px 15px;
  border-bottom: 1px solid #f5f5f5;
  color: black;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }

  span {
    margin-left: 5px;
    font-size: 12px;
  }

  svg {
    height: 20px;
    width: 30px;
    path {
      fill: #a5a5a5 !important;
    }
  }

  .failed {
    height: 6px;
    width: 6px;
  }

  .ant-tooltip {
    max-width: 400px !important;
  }

  .new {
    color: #c4c4c4;
    font-style: italic;
    font-size: 13px;
  }
`;

export const CardItemBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  width: 100%;
  padding: 15px;
  border-bottom: 1px solid #e2e2e2;
  color: black;

  &:hover {
    background: #f5f5f5;
  }

  span {
    margin-left: 10px;
    cursor: default;
  }

  svg {
    height: 16px;
    width: 16px;
    path {
      fill: #a5a5a5 !important;
    }
  }

  .ant-tooltip {
    max-width: 400px !important;
  }

  .new {
    padding-top: 25px;
    color: #c4c4c4;
    font-style: italic;
    font-size: 13px;
  }
`;
