import React from "react";

const HandlingIcon = ({ color }) => (
  <svg
    id="bovexoHandlingIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="25.542"
    height="20.495"
    viewBox="0 0 25.542 20.495"
  >
    <g
      id="Group_1029"
      data-name="Group 1029"
      transform="translate(-29.011 -384.007)"
    >
      <g
        id="Group_1024"
        data-name="Group 1024"
        transform="translate(29.261 384.257)"
      >
        <path
          id="Path_1071"
          data-name="Path 1071"
          d="M-170.075,0h13.338a2.265,2.265,0,0,1,2.262,2.262V16.692a1.171,1.171,0,0,1-1.17,1.17h-5.122a.39.39,0,0,1-.39-.39.39.39,0,0,1,.39-.39h5.122a.39.39,0,0,0,.39-.39V2.262A1.484,1.484,0,0,0-156.737.78h-11.631a2.253,2.253,0,0,1,.555,1.482V14.831a.652.652,0,0,0,.652.652.652.652,0,0,0,.619-.446l.588-1.764a1.215,1.215,0,0,1,1.155-.832,1.219,1.219,0,0,1,1.217,1.217v.3h2.021a.39.39,0,0,1,.39.39.39.39,0,0,1-.39.39h-2.021v1.94a4.318,4.318,0,0,1-.334,1.669l-.574,1.377a.39.39,0,0,1-.36.24.382.382,0,0,1-.15-.03.39.39,0,0,1-.21-.51l.574-1.377a3.542,3.542,0,0,0,.274-1.369V13.659a.438.438,0,0,0-.437-.437.436.436,0,0,0-.415.3l-.588,1.764a1.431,1.431,0,0,1-1.359.979,1.433,1.433,0,0,1-1.432-1.432V8.144l-.522.52a4.882,4.882,0,0,0-1.442,3.477v4.146a7.338,7.338,0,0,0,.7,3.125.39.39,0,0,1-.186.52.39.39,0,0,1-.519-.185,8.127,8.127,0,0,1-.779-3.459V12.14a5.655,5.655,0,0,1,1.671-4.028l1.073-1.07V6.669h-2.574a1.171,1.171,0,0,1-1.17-1.17V2.262A2.264,2.264,0,0,1-170.075,0Zm-1.482,5.5a.39.39,0,0,0,.39.39h2.574V2.262A1.484,1.484,0,0,0-170.075.78a1.484,1.484,0,0,0-1.482,1.482Z"
          transform="translate(172.337)"
          fill={color}
          stroke={color}
        />
        <path
          id="Path_1072"
          data-name="Path 1072"
          d="M-159.583,27.771a.392.392,0,0,1,.276.114.392.392,0,0,1,.114.276.392.392,0,0,1-.114.276.392.392,0,0,1-.276.114.392.392,0,0,1-.276-.114.392.392,0,0,1-.114-.276.392.392,0,0,1,.114-.276A.392.392,0,0,1-159.583,27.771Z"
          transform="translate(169.45 -10.689)"
          fill={color}
          stroke={color}
        />
        <path
          id="Path_1073"
          data-name="Path 1073"
          d="M-168.934,4.2a.781.781,0,0,1,.78-.78h1.716a.781.781,0,0,1,.78.78V5.92a.781.781,0,0,1-.78.78h-1.716a.781.781,0,0,1-.78-.78Zm.78,1.716h1.716V4.2h-1.716V5.92Z"
          transform="translate(181.427 -1.318)"
          fill={color}
          stroke={color}
        />
        <path
          id="Path_1074"
          data-name="Path 1074"
          d="M-168.934,11.812a.781.781,0,0,1,.78-.78h1.716a.781.781,0,0,1,.78.78v1.716a.781.781,0,0,1-.78.78h-1.716a.781.781,0,0,1-.78-.78Zm.78,1.716h1.716V11.812h-1.716v1.716Z"
          transform="translate(181.427 -4.246)"
          fill={color}
          stroke={color}
        />
        <path
          id="Path_1075"
          data-name="Path 1075"
          d="M-168.154,18.641h1.716a.781.781,0,0,1,.78.78v1.716a.781.781,0,0,1-.78.78h-1.716a.781.781,0,0,1-.78-.78V19.421A.781.781,0,0,1-168.154,18.641Zm0,2.5h1.716V19.421h-1.716v1.716Z"
          transform="translate(181.427 -7.175)"
          fill={color}
          stroke={color}
        />
        <path
          id="Path_1076"
          data-name="Path 1076"
          d="M-158.163,8.262a.39.39,0,0,1-.39-.39.39.39,0,0,1,.39-.39h2.689a.39.39,0,0,1,.39.39.39.39,0,0,1-.39.39Z"
          transform="translate(164.468 -2.88)"
          fill={color}
          stroke={color}
        />
        <path
          id="Path_1077"
          data-name="Path 1077"
          d="M-161.062,8.262a.392.392,0,0,1-.276-.114.393.393,0,0,1-.114-.276.394.394,0,0,1,.114-.276.394.394,0,0,1,.276-.114.4.4,0,0,1,.276.114.393.393,0,0,1,.114.276.393.393,0,0,1-.114.276A.393.393,0,0,1-161.062,8.262Z"
          transform="translate(171.839 -2.88)"
          fill={color}
          stroke={color}
        />
        <path
          id="Path_1078"
          data-name="Path 1078"
          d="M-156.591,15.87h-4.472a.39.39,0,0,1-.39-.39.39.39,0,0,1,.39-.39h4.472a.391.391,0,0,1,.391.39A.39.39,0,0,1-156.591,15.87Z"
          transform="translate(167.368 -5.808)"
          fill={color}
          stroke={color}
        />
      </g>
      <path
        id="Subtraction_2"
        data-name="Subtraction 2"
        d="M26.008,20.387A5.249,5.249,0,0,1,24,19.58a11.458,11.458,0,0,1-2-.233v-.984c.289.065.6.12.913.163a.482.482,0,0,1,.122-.65.473.473,0,0,1,.284-.093.486.486,0,0,1,.391.2,4.777,4.777,0,0,0,.714.724,5.19,5.19,0,0,0,1.587.716,11.232,11.232,0,0,0,1.712-.3A1.9,1.9,0,0,1,27.2,17.38a1.686,1.686,0,0,1,.9-.98l.041-.02.038-.019a20.015,20.015,0,0,0-1.2-2c-.768-1.153-1.535-2.18-1.54-2.185a.484.484,0,0,1-.089-.367,5.305,5.305,0,0,0,0-1.167,13.461,13.461,0,0,0-1-2.579A14.318,14.318,0,0,1,22,8.43V7.461c2.264-.188,3.87-.76,4.775-1.7a3.015,3.015,0,0,0,.285-.343,7.651,7.651,0,0,1-2.918.949,14.154,14.154,0,0,1-1.475.079c-.219,0-.444-.006-.668-.017V5.463c.218.012.442.019.668.019l.131,0h.012c-.347-.416-.558-.527-.629-.527a.6.6,0,0,0-.182.03V4a1.532,1.532,0,0,1,.182-.011c.474,0,.949.327,1.495,1.028.111.143.205.274.29.4l.031,0,.026,0,.018,0A7.041,7.041,0,0,0,27.65,3.852a.483.483,0,0,1,.8.328,2.416,2.416,0,0,1-.1.734,3.564,3.564,0,0,1-.85,1.479A5.7,5.7,0,0,1,25.277,7.78,12.428,12.428,0,0,1,26.3,10.553a7.057,7.057,0,0,1,.019,1.2c.237.322.855,1.17,1.457,2.075,1.451,2.181,1.636,2.883,1.635,3.25a6.593,6.593,0,0,1-.872,2.68.493.493,0,0,1-.3.229A13.654,13.654,0,0,1,26.008,20.387Zm2.426-3.068a.663.663,0,0,0-.31.341.676.676,0,0,0,.1.488A3.988,3.988,0,0,0,28.435,17.319Zm-5.77-6.1a.482.482,0,1,1,.483-.482A.482.482,0,0,1,22.665,11.222Z"
        transform="translate(25.137 382)"
        fill={color}
      />
    </g>
  </svg>
);
export default HandlingIcon;
