import React, { useCallback, useEffect, useState } from "react";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { Creators as BoitelScenarioActions } from "../../../../store/ducks/boitelScenario";
import { Body, CollapseContainer, Container, Table } from "./styles";
import {
  Col,
  Input,
  Row,
  Select,
  Icon,
  List,
  Spin,
  Modal,
  notification,
  Popconfirm,
  DatePicker,
  Switch,
} from "antd";
import NumberFormat from "react-number-format";
import ButtonStandard from "../../../../components/utils/button";
// Context
import useBoitelScnearioNewEditContext from "../../../../hooks/useBoitelScnearioNewEditContext";
// Services
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../../services/helpersMethodsService";
import { getBoitelPricesHistoryDropDown } from "../../../../services/boitelPriceService";
import BoxText from "../../../../components/utils/boxText";
import { indexAllDropdownFarmQuota } from "../../../../services/quotaService";

const NewEditBoitelScenarioResults = () => {
  const dateFormat = "DD/MM/YYYY";
  const dateTimeFormat = "DD/MM/YYYY HH:mm:ss";
  const [quotas, setQuotas] = useState([]);
  const [loadingQuota, setLoadingQuota] = useState(false);
  const [form, setForm] = useState([]);
  const [isEditing, setIsEditing] = useState([]);
  const [boitelPrice, setBoitelPrice] = useState(null);
  const [allWeightRanges, setAllWeightRanges] = useState([]);
  const [minimizeCard, setMinimizeCard] = useState(false);

  //  Props
  const { allDietStrategies, breeds, marketAnalysis } =
    useBoitelScnearioNewEditContext();

  /** Redux Variables */
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const {
    boitelScenarioData,
    boitelScenarioData: { id: boitelScenarioId, simulations },
    isLoadingUpdateSimulationRequest,
  } = useSelector((state) => state.boitelScenario);
  const dispatch = useDispatch();

  // Methods and Callbacks

  const validateDietStrategy = useCallback(
    (dietStrategyId, entryDate, limitTimeSimulation) => {
      const ds = allDietStrategies.find((ds) => ds.id === dietStrategyId);
      const startDs = moment(ds.dietPeriods[0].startDate);
      const endDs = moment(ds.dietPeriods[ds.dietPeriods.length - 1].endDate);
      const entryDateM = moment(entryDate);
      const entryDatePlusLimitTime = moment(entryDate).add(
        limitTimeSimulation,
        "days"
      );

      let haveError = false;
      let message = null;

      if (entryDateM.isBefore(startDs)) {
        haveError = true;
        message =
          "A data de entrada se inicia antes da estratégia de dieta da simulação.";
      } else if (entryDateM.isAfter(endDs)) {
        haveError = true;
        message =
          "A data de entrada se inicia depois do término da estratégia de dieta da simulação.";
      } else if (entryDatePlusLimitTime.isAfter(endDs)) {
        haveError = true;
        message =
          "A estratégia de dieta da simulação não comporta o tempo de permanência informado.";
      }

      if (haveError) {
        Modal.error({
          title: "Erro",
          content: message,
          centered: true,
          okText: "Ok",
        });
      }

      return !haveError;
    },
    [allDietStrategies]
  );

  const validateBoitelPriceValidity = useCallback(
    (boitelPriceValidityId, entryDate, limitTimeSimulation) => {
      const bp = boitelPrice.find(
        (x) => x.idBoitelPriceValidity === boitelPriceValidityId
      );
      const startBs = moment(bp.initialValidity);
      const endBs = moment(bp.finalValidity);
      const entryDateM = moment(entryDate);

      let haveError = false;
      let message = null;

      if (entryDateM.isBefore(startBs)) {
        haveError = true;
        message =
          'A data de entrada se inicia antes da vigência da "Tabela de Diárias" selecionada.';
      } else if (entryDateM.isAfter(endBs)) {
        haveError = true;
        message =
          'A data de entrada se inicia depois do término da vigência da "Tabela de Diárias" selecionada.';
      }

      if (haveError) {
        Modal.warning({
          title: "Alerta",
          content: message,
          centered: true,
          okText: "Ok",
        });
      }

      return true;
    },
    [boitelPrice]
  );

  const validateForm = useCallback((form) => {
    let haveError = false;

    if (form?.entryDate == null) {
      haveError = true;
      notification.error({
        message: 'A coluna "Data Entrada" não pode estar em branco',
      });
    }

    if (
      form.useQuotaCriteria === false &&
      (form?.minimumWeightSell == null || form.minimumWeightSell === 0)
    ) {
      haveError = true;
      notification.error({
        message: 'A coluna "Peso Min." não pode ser 0 ou estar em branco',
      });
    }

    if (
      form.useQuotaCriteria === false &&
      (form?.maximumWeightSell == null || form.maximumWeightSell === 0)
    ) {
      haveError = true;
      notification.error({
        message: 'A coluna "Peso Max." não pode ser 0 ou estar em branco',
      });
    }

    if (
      form.useQuotaCriteria === false &&
      (form?.minimumAgeSell == null || form.minimumAgeSell === 0)
    ) {
      haveError = true;
      notification.error({
        message: 'A coluna "Idade Min." não pode ser 0 ou estar em branco',
      });
    }

    if (
      form.useQuotaCriteria === false &&
      (form?.maximumAgeSell == null || form.maximumAgeSell === 0)
    ) {
      haveError = true;
      notification.error({
        message: 'A coluna "Idade Max." não pode ser 0 ou estar em branco',
      });
    }

    if (form?.priceDifferenceOxCow == null || form.priceDifferenceOxCow === 0) {
      haveError = true;
      notification.error({
        message: 'A coluna "Dif. Boi-Vaca" não pode ser 0 ou estar em branco',
      });
    }

    if (form?.marketAnalysisId == null) {
      haveError = true;
      notification.error({
        message: 'A coluna "Curva Preços @" não pode estar em branco',
      });
    }

    if (form?.boitelPriceValidityId == null) {
      haveError = true;
      notification.error({
        message: 'A coluna "Vigência" não pode estar em branco',
      });
    }

    if (form?.boitelPriceValidityWeightRangeId == null) {
      haveError = true;
      notification.error({
        message: 'A coluna "Peso de entrada" não pode estar em branco',
      });
    }

    if (form?.limitTimeSimulation == null || form.limitTimeSimulation === 0) {
      haveError = true;
      notification.error({
        message: 'A coluna "Perman. Negoc." não pode ser 0 ou estar em branco',
      });
    }

    if (form?.priceSimulation == null || form.priceSimulation === 0) {
      haveError = true;
      notification.error({
        message:
          'A coluna "Preço Dia Negoc." não pode ser 0 ou estar em branco',
      });
    }

    return !haveError;
  }, []);

  const handleInput = useCallback(
    (id, field, value) => {
      let item = form.find((f) => f.id === id);
      item = {
        ...item,
        [field]: value,
      };
      setForm((old) =>
        old.map((o) => {
          if (o.id === id) {
            return item;
          } else {
            return o;
          }
        })
      );
    },
    [form]
  );

  const handleChangeBoitelValidity = useCallback(
    (id, value) => {
      let item = form.find((f) => f.id === id);
      const boitelSelected = boitelPrice.find(
        (b) => b.idBoitelPriceValidity === value
      );
      item = {
        ...item,
        boitelPriceValidityWeightRangeId: null,
        boitelPriceId: boitelSelected?.idBoitelPrice,
        boitelPriceValidityId: value,
      };
      setForm((old) =>
        old.map((o) => {
          if (o.id === id) {
            return item;
          } else {
            return o;
          }
        })
      );
    },
    [boitelPrice, form]
  );

  const handleChangeBoitelWeightRange = useCallback(
    (id, value) => {
      let item = form.find((f) => f.id === id);
      item = {
        ...item,
        boitelPriceValidityWeightRangeId: value,
      };
      setForm((old) =>
        old.map((o) => {
          if (o.id === id) {
            return item;
          } else {
            return o;
          }
        })
      );
    },
    [form]
  );

  const handleOnDropdownVisibleChange = useCallback(
    (open, item) => {
      if (open) {
        const weightRanges = boitelPrice.find(
          (b) => b.idBoitelPriceValidity === item.boitelPriceValidityId
        ).weightValues;
        setAllWeightRanges(weightRanges);
      } else {
        const weightRanges = boitelPrice.flatMap((v) => v.weightValues);
        setAllWeightRanges(weightRanges);
      }
    },
    [boitelPrice]
  );

  const handleDelete = useCallback(
    (id) => {
      dispatch(
        BoitelScenarioActions.deleteBoitelScenarioSimulation(
          groupId,
          farmId,
          boitelScenarioId,
          id
        )
      );
    },
    [boitelScenarioId, dispatch, farmId, groupId]
  );

  const handleCancel = useCallback(
    (id) => {
      let item = simulations.find((s) => s.id === id);
      setForm((old) =>
        old.map((o) => {
          if (o.id === id) {
            return item;
          } else {
            return o;
          }
        })
      );
      setIsEditing((old) => old.filter((e) => e !== id));
    },
    [simulations]
  );

  const handleSaveUpdate = useCallback(
    (id) => {
      const body = form.find((f) => f.id === id);
      if (
        validateDietStrategy(
          body.dietStrategyId,
          body.entryDate,
          body.limitTimeSimulation
        ) &&
        validateBoitelPriceValidity(
          body.boitelPriceValidityId,
          body.entryDate,
          body.limitTimeSimulation
        ) &&
        validateForm(body)
      ) {
        dispatch(
          BoitelScenarioActions.updateBoitelScenarioSimulation(
            groupId,
            farmId,
            boitelScenarioId,
            id,
            body
          )
        );
        setIsEditing((old) => old.filter((e) => e !== id));
      }
    },
    [
      boitelScenarioId,
      dispatch,
      farmId,
      form,
      groupId,
      validateBoitelPriceValidity,
      validateDietStrategy,
      validateForm,
    ]
  );

  const handleRefresh = useCallback(() => {
    if (boitelScenarioId != null) {
      dispatch(
        BoitelScenarioActions.getBoitelScenarioData(
          groupId,
          farmId,
          boitelScenarioId
        )
      );
    }
  }, [boitelScenarioId, dispatch, groupId, farmId]);

  // Get BoitelPrices
  useEffect(() => {
    async function fetchBoitelPrices() {
      try {
        const {
          data: { results },
        } = await getBoitelPricesHistoryDropDown({ groupId, farmId });
        setBoitelPrice(results);
        const weightRanges = results.flatMap((v) => v.weightValues);
        setAllWeightRanges(weightRanges);
      } catch (error) {
        setBoitelPrice(null);
      }
    }
    async function fetchQuotas() {
      setLoadingQuota(true);
      try {
        const {
          data: { results },
        } = await indexAllDropdownFarmQuota({ groupId, farmId });
        setQuotas(results);
        setLoadingQuota(false);
      } catch (error) {
        setLoadingQuota([]);
        setLoadingQuota(false);
      }
    }
    fetchBoitelPrices();
    fetchQuotas();
  }, [groupId, farmId]);

  // Set Form
  useEffect(() => {
    setForm(simulations);
  }, [simulations]);

  return (
    <Container
      title="Simulação de Resultados"
      extra={
        !minimizeCard ? (
          <button onClick={() => setMinimizeCard(true)}>
            <Icon type="minus" />
          </button>
        ) : (
          <button onClick={() => setMinimizeCard(false)}>
            <Icon type="plus" />
          </button>
        )
      }
    >
      {!minimizeCard && (
        <Spin spinning={isLoadingUpdateSimulationRequest}>
          <List
            itemLayout="vertical"
            size="large"
            pagination={{
              pageSize: 1,
            }}
            dataSource={form}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <Body>
                  {/* Result name */}
                  <Row type="flex" justify="start" gutter={8}>
                    <Col xs={20} sm={20} md={8} lg={8} xl={8}>
                      <Input
                        name="name"
                        value={item?.name != null ? item?.name : undefined}
                        placeholder={translation.defaultPlaceholder}
                        disabled={!isEditing.includes(item.id)}
                        onChange={(e) =>
                          handleInput(item.id, "name", e.target.value)
                        }
                      />{" "}
                    </Col>
                    {!isEditing.includes(item.id) &&
                      boitelScenarioData.status !== "Hired" && (
                        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                          <Popconfirm
                            title="Você realmente deseja deletar esta simulação?"
                            onConfirm={() => handleDelete(item.id)}
                            okText="Sim"
                            cancelText="Não"
                          >
                            <button className="iconButton">
                              <Icon type="delete" className="deleteIcon" />
                            </button>
                          </Popconfirm>
                        </Col>
                      )}
                    <Col
                      xs={24}
                      sm={24}
                      md={isEditing.includes(item.id) ? 16 : 14}
                      lg={isEditing.includes(item.id) ? 16 : 14}
                      xl={isEditing.includes(item.id) ? 16 : 14}
                    >
                      {!isEditing.includes(item.id) ? (
                        <Row type="flex" justify="end" gutter={16}>
                          <ButtonStandard
                            buttonType="typeWithoutBackground"
                            onClick={handleRefresh}
                          >
                            <Icon type="reload" />
                          </ButtonStandard>
                          {boitelScenarioData.status !== "Hired" && (
                            <ButtonStandard
                              buttonType="typeWithoutBackground"
                              color="#94CF1C"
                              font
                              onClick={() =>
                                setIsEditing((old) => [...old, item.id])
                              }
                            >
                              <Icon type="edit" /> Editar
                            </ButtonStandard>
                          )}
                        </Row>
                      ) : (
                        <Row type="flex" justify="end" gutter={24}>
                          <Col>
                            <ButtonStandard
                              buttonType="secondary"
                              onClick={() => handleCancel(item.id)}
                            >
                              Cancelar
                            </ButtonStandard>
                          </Col>
                          <Col>
                            <ButtonStandard
                              buttonType="principal"
                              onClick={() => handleSaveUpdate(item.id)}
                            >
                              Salvar
                            </ButtonStandard>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                  {/* Parameters Collapse */}
                  <CollapseContainer
                    defaultActiveKey={["1"]}
                    expandIconPosition="right"
                    expandIcon={({ isActive }) =>
                      isActive ? <Icon type="minus" /> : <Icon type="plus" />
                    }
                  >
                    <CollapseContainer.Panel header="Parâmetros" key="1">
                      <Table>
                        <thead>
                          <tr>
                            <th className="width-140">Data Entrada</th>
                            <th className="width-140">Dif. Boi-Vaca</th>
                            <th className="width-200">Curva Preços @</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="width-140">
                              <DatePicker
                                name="entryDate"
                                style={{ width: "100%" }}
                                allowClear={false}
                                value={
                                  item.entryDate != null
                                    ? moment(item.entryDate, "YYYY-MM-DD")
                                    : null
                                }
                                disabled={!isEditing.includes(item.id)}
                                placeholder={
                                  translation.defaultDatePickerPlaceholder
                                }
                                format={dateFormat}
                                onChange={(date, dateString) => {
                                  handleInput(item.id, "entryDate", date);
                                }}
                              />
                            </td>
                            <td className="width-140">
                              <NumberFormat
                                customInput={Input}
                                value={
                                  item?.priceDifferenceOxCow !== null
                                    ? item?.priceDifferenceOxCow
                                    : null
                                }
                                disabled={!isEditing.includes(item.id)}
                                placeholder={translation.defaultPlaceholder}
                                name="priceDifferenceOxCow"
                                addonAfter="%"
                                decimalScale={2}
                                decimalSeparator=","
                                thousandSeparator="."
                                fixedDecimalScale={true}
                                onValueChange={({ floatValue }) => {
                                  handleInput(
                                    item.id,
                                    "priceDifferenceOxCow",
                                    floatValue
                                      ? floatValue > 100
                                        ? 100
                                        : floatValue
                                      : null
                                  );
                                }}
                              />
                            </td>
                            <td className="width-200">
                              <Select
                                name="marketAnalysisId"
                                value={
                                  item.marketAnalysisId
                                    ? item.marketAnalysisId
                                    : undefined
                                }
                                disabled={!isEditing.includes(item.id)}
                                placeholder={
                                  translation.defaultSelectPlaceholder
                                }
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(value) => {
                                  handleInput(
                                    item.id,
                                    "marketAnalysisId",
                                    value
                                  );
                                }}
                              >
                                {marketAnalysis.map((m) => (
                                  <Select.Option key={m.id} value={m.id}>
                                    {m.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      {item.useQuotaCriteria ? (
                        <Table>
                          <thead>
                            <tr>
                              <th className="width-140">Cotas</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="width-140">
                                <Select
                                  name="quotaId"
                                  style={{ width: "100%" }}
                                  value={item.quotaId || undefined}
                                  loading={loadingQuota}
                                  disabled={!isEditing.includes(item.id)}
                                  placeholder={
                                    translation.defaultSelectPlaceholder
                                  }
                                  onChange={(value) => {
                                    handleInput(item.id, "quotaId", value);
                                  }}
                                >
                                  {quotas.map((q) => (
                                    <Select.Option key={q.id} value={q.id}>
                                      {q.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      ) : (
                        <Table>
                          <thead>
                            <tr>
                              <th className="width-140">Forte?</th>
                              <th className="width-140">Idade Min.</th>
                              <th className="width-140">Idade Max.</th>
                              <th className="width-140">Forte?</th>
                              <th className="width-140">Peso Min.</th>
                              <th className="width-140">Peso Max.</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="width-140">
                                <Switch
                                  checked={item.strongAgeRestriction}
                                  disabled={!isEditing.includes(item.id)}
                                  onChange={(checked, event) =>
                                    handleInput(
                                      item.id,
                                      "strongAgeRestriction",
                                      checked
                                    )
                                  }
                                />
                              </td>
                              <td className="width-140">
                                <NumberFormat
                                  customInput={Input}
                                  value={
                                    item?.minimumAgeSell !== null
                                      ? item?.minimumAgeSell
                                      : null
                                  }
                                  disabled={!isEditing.includes(item.id)}
                                  placeholder={translation.defaultPlaceholder}
                                  name="minimumAgeSell"
                                  addonAfter="Dias"
                                  decimalScale={0}
                                  onValueChange={({ floatValue }) => {
                                    handleInput(
                                      item.id,
                                      "minimumAgeSell",
                                      floatValue
                                    );
                                  }}
                                />
                              </td>
                              <td className="width-140">
                                <NumberFormat
                                  customInput={Input}
                                  value={
                                    item?.maximumAgeSell !== null
                                      ? item?.maximumAgeSell
                                      : null
                                  }
                                  disabled={!isEditing.includes(item.id)}
                                  placeholder={translation.defaultPlaceholder}
                                  name="maximumAgeSell"
                                  addonAfter="Dias"
                                  decimalScale={0}
                                  onValueChange={({ floatValue }) => {
                                    handleInput(
                                      item.id,
                                      "maximumAgeSell",
                                      floatValue
                                    );
                                  }}
                                />
                              </td>
                              <td className="width-140">
                                <Switch
                                  checked={item.strongWeightRestriction}
                                  disabled={!isEditing.includes(item.id)}
                                  onChange={(checked, event) =>
                                    handleInput(
                                      item.id,
                                      "strongWeightRestriction",
                                      checked
                                    )
                                  }
                                />
                              </td>
                              <td className="width-140">
                                <NumberFormat
                                  customInput={Input}
                                  value={
                                    item?.minimumWeightSell !== null
                                      ? item?.minimumWeightSell
                                      : null
                                  }
                                  disabled={!isEditing.includes(item.id)}
                                  placeholder={translation.defaultPlaceholder}
                                  name="minimumWeightSell"
                                  addonAfter="Kg"
                                  decimalScale={2}
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  fixedDecimalScale={true}
                                  onValueChange={({ floatValue }) => {
                                    handleInput(
                                      item.id,
                                      "minimumWeightSell",
                                      floatValue
                                        ? floatValue < 1000
                                          ? floatValue
                                          : 999.99
                                        : null
                                    );
                                  }}
                                />
                              </td>
                              <td className="width-140">
                                <NumberFormat
                                  customInput={Input}
                                  value={
                                    item?.maximumWeightSell !== null
                                      ? item?.maximumWeightSell
                                      : null
                                  }
                                  disabled={!isEditing.includes(item.id)}
                                  placeholder={translation.defaultPlaceholder}
                                  name="maximumWeightSell"
                                  addonAfter="Kg"
                                  decimalScale={2}
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  fixedDecimalScale={true}
                                  onValueChange={({ floatValue }) => {
                                    handleInput(
                                      item.id,
                                      "maximumWeightSell",
                                      floatValue
                                        ? floatValue < 1000
                                          ? floatValue
                                          : 999.99
                                        : null
                                    );
                                  }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      )}
                      <Table>
                        <thead>
                          <tr>
                            <th className="width-200">Tabela de Diárias</th>
                            <th className="width-200">Peso de entrada</th>
                            <th className="width-140">Perman. Negoc.</th>
                            <th className="width-140">Preço Dia Negoc.</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="width-200">
                              <Select
                                name="boitelPriceValidityId"
                                value={
                                  item.boitelPriceValidityId
                                    ? item.boitelPriceValidityId
                                    : undefined
                                }
                                disabled={!isEditing.includes(item.id)}
                                placeholder={
                                  translation.defaultSelectPlaceholder
                                }
                                onChange={(value) => {
                                  handleChangeBoitelValidity(item.id, value);
                                }}
                              >
                                {boitelPrice != null &&
                                  boitelPrice.map((el) => (
                                    <Select.Option
                                      value={el?.idBoitelPriceValidity}
                                      key={el?.idBoitelPriceValidity}
                                    >
                                      {`${
                                        el?.validityDescription
                                          ? `${el?.validityDescription} - `
                                          : ""
                                      }${moment(el.initialValidity).format(
                                        dateFormat
                                      )} à ${moment(el.finalValidity).format(
                                        dateFormat
                                      )} - Atualização: ${moment
                                        .utc(el.createdAt)
                                        .local()
                                        .format(dateTimeFormat)}`}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </td>
                            <td className="width-200">
                              <Select
                                name="boitelPriceValidityWeightRangeId"
                                value={
                                  boitelPrice === null
                                    ? undefined
                                    : item.boitelPriceValidityWeightRangeId !=
                                      null
                                    ? item.boitelPriceValidityWeightRangeId
                                    : undefined
                                }
                                disabled={!isEditing.includes(item.id)}
                                placeholder={
                                  translation.defaultSelectPlaceholder
                                }
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onDropdownVisibleChange={(open) =>
                                  handleOnDropdownVisibleChange(open, item)
                                }
                                onChange={(value) => {
                                  handleChangeBoitelWeightRange(item.id, value);
                                }}
                              >
                                {boitelPrice != null &&
                                  item.boitelPriceValidityId != null &&
                                  allWeightRanges.map((w) => (
                                    <Select.Option
                                      key={w.id}
                                      value={w.id}
                                    >{`${numberMask(
                                      w.initialWeightRange,
                                      false
                                    )} Kg - ${numberMask(
                                      w.finalWeightRange,
                                      false
                                    )} Kg / ${
                                      w.limitTime
                                    } dias`}</Select.Option>
                                  ))}
                              </Select>
                            </td>
                            <td className="width-140">
                              <NumberFormat
                                customInput={Input}
                                value={
                                  item.limitTimeSimulation !== null
                                    ? item.limitTimeSimulation
                                    : null
                                }
                                placeholder={translation.defaultPlaceholder}
                                name="limitTimeSimulation"
                                addonAfter="dias"
                                decimalScale={0}
                                decimalSeparator=","
                                thousandSeparator="."
                                fixedDecimalScale={false}
                                disabled={!isEditing.includes(item.id)}
                                onValueChange={({ floatValue }) => {
                                  handleInput(
                                    item.id,
                                    "limitTimeSimulation",
                                    floatValue
                                      ? floatValue < 1000
                                        ? floatValue
                                        : 999
                                      : null
                                  );
                                }}
                              />
                            </td>
                            <td className="width-140">
                              <NumberFormat
                                customInput={Input}
                                value={
                                  item.priceSimulation !== null
                                    ? item.priceSimulation
                                    : null
                                }
                                placeholder={translation.defaultPlaceholder}
                                name="priceSimulation"
                                addonBefore="R$"
                                decimalScale={2}
                                decimalSeparator=","
                                thousandSeparator="."
                                fixedDecimalScale={true}
                                disabled={!isEditing.includes(item.id)}
                                onValueChange={({ floatValue }) => {
                                  handleInput(
                                    item.id,
                                    "priceSimulation",
                                    floatValue
                                      ? floatValue < 10000
                                        ? floatValue
                                        : 9999
                                      : null
                                  );
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </CollapseContainer.Panel>
                  </CollapseContainer>
                  {/* General Data Collapse */}
                  <CollapseContainer
                    defaultActiveKey={["1"]}
                    expandIconPosition="right"
                    expandIcon={({ isActive }) =>
                      isActive ? <Icon type="minus" /> : <Icon type="plus" />
                    }
                  >
                    <CollapseContainer.Panel header="Dados Gerais" key="1">
                      <Table>
                        <thead>
                          <tr>
                            <th className="width-140">Qtd. Animais</th>
                            <th className="width-200">Estratégia de Dieta</th>
                            <th className="width-140">Raça</th>
                            <th className="width-140">Mestiço</th>
                            <th className="width-140">Genero</th>
                            <th className="width-140">Aprov. Carcaça</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="width-140">
                              {item.amountAnimals || "-"}
                            </td>
                            <td className="width-200">
                              {allDietStrategies.find(
                                (ds) => ds.id === item.dietStrategyId
                              )?.name || "-"}
                            </td>
                            <td className="width-140">
                              {breeds.find((b) => b.id === item.breedId)
                                ?.name || "-"}
                            </td>
                            <td className="width-140">
                              {item.mixedMilk ? "Sim" : "Não"}
                            </td>
                            <td className="width-140">
                              {item.gender === "Male" ? "Macho" : "Fêmea"}
                            </td>
                            <td className="width-140">
                              {item.carcassHarnessing
                                ? `${numberMask(
                                    item.carcassHarnessing || 0,
                                    false
                                  )} %`
                                : `${numberMask(
                                    allDietStrategies.find(
                                      (ds) => ds.id === item.dietStrategyId
                                    )?.dietPeriods[0]?.carcassHarnessing || 0,
                                    false
                                  )} %`}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </CollapseContainer.Panel>
                  </CollapseContainer>
                  {item?.result === null &&
                  item?.resultProcessedWithSuccess === true ? (
                    <Row type="flex" justify="center" align="middle">
                      <strong>
                        Resultado sendo processado <Icon type="loading" />
                      </strong>
                    </Row>
                  ) : item?.result === null &&
                    item?.resultProcessedWithSuccess === false ? (
                    <Row type="flex" justify="center" align="middle">
                      <BoxText
                        color="#D44C4C"
                        iconComponent={
                          <Icon type="stop" style={{ color: "#D44C4C" }} />
                        }
                      >
                        <span>
                          Erro no processamento deste resultado! Por favor,
                          contacte o Suporte Técnico.
                        </span>
                      </BoxText>
                    </Row>
                  ) : (
                    <>
                      {/* Boitel Collapse */}
                      <CollapseContainer
                        defaultActiveKey={["1"]}
                        expandIconPosition="right"
                        expandIcon={({ isActive }) =>
                          isActive ? (
                            <Icon type="minus" />
                          ) : (
                            <Icon type="plus" />
                          )
                        }
                      >
                        <CollapseContainer.Panel header="Boitel" key="1">
                          <Table>
                            <thead>
                              <tr>
                                <th className="width-140"></th>
                                <th className="width-140">Valor diária</th>
                                <th className="width-140">Permanência</th>
                                <th className="width-140">Receita total</th>
                                <th className="width-140">Lucro Total (R$)</th>
                                <th className="width-140">Lucro (%)</th>
                                <th className="width-140">Lucro (R$/cab)</th>
                                <th className="width-140">Máx. lucro (dias)</th>
                                <th className="width-140">
                                  Máx. lucro (R$/cab)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Table */}
                              <tr>
                                <td className="width-140">Tabela</td>
                                <td className="width-140">
                                  {item?.boitelPriceValidityWeightRangeId !==
                                  null
                                    ? item?.mixedMilk
                                      ? numberMask(
                                          allWeightRanges.find(
                                            (w) =>
                                              w.id ===
                                              item?.boitelPriceValidityWeightRangeId
                                          )?.dailyValueMixedMilk,
                                          true
                                        )
                                      : numberMask(
                                          allWeightRanges.find(
                                            (w) =>
                                              w.id ===
                                              item?.boitelPriceValidityWeightRangeId
                                          )?.dailyValueCut,
                                          true
                                        )
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result
                                    ?.boitelPriceLimitTimeSimulation !== null
                                    ? `${item.result?.boitelPriceLimitTimeSimulation} dias`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.boitelPriceTotalIncome
                                    ? `${numberMask(
                                        item.result?.boitelPriceTotalIncome ||
                                          0,
                                        true
                                      )}`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.boitelPriceTotalMargin
                                    ? `${numberMask(
                                        item.result?.boitelPriceTotalMargin ||
                                          0,
                                        true
                                      )}`
                                    : "-"}
                                </td>
                                <td className="width-140">{`${numberMask(
                                  item.result?.boitelPriceMarginPercentage || 0,
                                  false
                                )} %`}</td>
                                <td className="width-140">
                                  {item.result?.boitelPriceMargin != null
                                    ? `${numberMask(
                                        item.result?.boitelPriceMargin || 0,
                                        true
                                      )} /cab`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.boitelPriceMaximumMarginDays
                                    ? `${
                                        item.result
                                          ?.boitelPriceMaximumMarginDays || 0
                                      } dias`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.boitelPriceMaximumMargin != null
                                    ? `${numberMask(
                                        item.result?.boitelPriceMaximumMargin ||
                                          0,
                                        true
                                      )}`
                                    : "-"}
                                </td>
                              </tr>
                              {/* Negoc */}
                              <tr>
                                <td className="width-140">Negociação</td>
                                <td className="width-140">
                                  {item.priceSimulation != null
                                    ? numberMask(item.priceSimulation, true)
                                    : item?.mixedMilk
                                    ? numberMask(
                                        allWeightRanges.find(
                                          (w) =>
                                            w.id ===
                                            item?.boitelPriceValidityWeightRangeId
                                        )?.dailyValueMixedMilk,
                                        true
                                      )
                                    : numberMask(
                                        allWeightRanges.find(
                                          (w) =>
                                            w.id ===
                                            item?.boitelPriceValidityWeightRangeId
                                        )?.dailyValueCut,
                                        true
                                      )}
                                </td>
                                <td className="width-140">{`${item.limitTimeSimulation} dias`}</td>
                                <td className="width-140">
                                  {item.result?.userTotalIncome
                                    ? `${numberMask(
                                        item.result?.userTotalIncome || 0,
                                        true
                                      )}`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.userTotalMargin
                                    ? `${numberMask(
                                        item.result?.userTotalMargin || 0,
                                        true
                                      )}`
                                    : "-"}
                                </td>
                                <td className="width-140">{`${numberMask(
                                  item.result?.userMarginPercentage || 0,
                                  false
                                )} %`}</td>
                                <td className="width-140">
                                  {item.result?.userMargin != null
                                    ? `${numberMask(
                                        item.result?.userMargin || 0,
                                        true
                                      )} /cab`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.userMaximumMarginDays
                                    ? `${
                                        item.result?.userMaximumMarginDays || 0
                                      } dias`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.userMaximumMargin != null
                                    ? `${numberMask(
                                        item.result?.userMaximumMargin || 0,
                                        true
                                      )}`
                                    : "-"}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </CollapseContainer.Panel>
                      </CollapseContainer>
                      {/* Cliente Collapse */}
                      <CollapseContainer
                        defaultActiveKey={["1"]}
                        expandIconPosition="right"
                        expandIcon={({ isActive }) =>
                          isActive ? (
                            <Icon type="minus" />
                          ) : (
                            <Icon type="plus" />
                          )
                        }
                      >
                        <CollapseContainer.Panel header="Cliente" key="1">
                          <Table>
                            <thead>
                              <tr>
                                <th className="width-140"></th>
                                <th className="width-140">Data saída</th>
                                <th className="width-140">@ Liq. agregados</th>
                                <th className="width-140">Receita na venda</th>
                                <th className="width-140">Receita agregada</th>
                                <th className="width-140">Lucro agreg.</th>
                                <th className="width-140">Lucro(%)</th>
                                <th className="width-140">
                                  Máx. lucro em dias
                                </th>
                                <th className="width-140">
                                  Máx. lucro agregado
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Table */}
                              <tr>
                                <td className="width-140">Tabela</td>
                                <td className="width-140">
                                  {item.result?.boitelPriceOutDay
                                    ? moment(
                                        item.result?.boitelPriceOutDay
                                      ).format("DD/MM/YYYY")
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.boitelPriceAggregateArroba
                                    ? `${numberMask(
                                        item.result
                                          ?.boitelPriceAggregateArroba || 0,
                                        false
                                      )} @`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.boitelPriceSaleIncome
                                    ? `${numberMask(
                                        item.result?.boitelPriceSaleIncome || 0,
                                        true
                                      )} /cab`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.boitelPriceAggregateIncome
                                    ? `${numberMask(
                                        item.result
                                          ?.boitelPriceAggregateIncome || 0,
                                        true
                                      )} /cab`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.boitelPriceAggregateMargin
                                    ? `${numberMask(
                                        item.result
                                          ?.boitelPriceAggregateMargin || 0,
                                        true
                                      )} /cab`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result
                                    ?.boitelPriceAggregateMarginPercentage
                                    ? `${numberMask(
                                        item.result
                                          ?.boitelPriceAggregateMarginPercentage ||
                                          0,
                                        false
                                      )} %`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result
                                    ?.boitelPriceMaximumMarginAggregateDays
                                    ? `${
                                        item.result
                                          ?.boitelPriceMaximumMarginAggregateDays ||
                                        0
                                      } dias`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result
                                    ?.boitelPriceMaximumMarginAggregate
                                    ? `${numberMask(
                                        item.result
                                          ?.boitelPriceMaximumMarginAggregate ||
                                          0,
                                        true
                                      )} /cab`
                                    : "-"}
                                </td>
                              </tr>
                              {/* Negoc */}
                              <tr>
                                <td className="width-140">Negociação</td>
                                <td className="width-140">
                                  {item.result?.userOutDay
                                    ? moment(item.result?.userOutDay).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.userAggregateArroba
                                    ? `${numberMask(
                                        item.result?.userAggregateArroba || 0,
                                        false
                                      )} @`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.userSaleIncome
                                    ? `${numberMask(
                                        item.result?.userSaleIncome || 0,
                                        true
                                      )} /cab`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.userAggregateIncome
                                    ? `${numberMask(
                                        item.result?.userAggregateIncome || 0,
                                        true
                                      )} /cab`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.userAggregateMargin
                                    ? `${numberMask(
                                        item.result?.userAggregateMargin || 0,
                                        true
                                      )} /cab`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.userAggregateMarginPercentage
                                    ? `${numberMask(
                                        item.result
                                          ?.userAggregateMarginPercentage || 0,
                                        false
                                      )} %`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.userMaximumMarginAggregateDays
                                    ? `${
                                        item.result
                                          ?.userMaximumMarginAggregateDays || 0
                                      } dias`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.userMaximumMarginAggregate
                                    ? `${numberMask(
                                        item.result
                                          ?.userMaximumMarginAggregate || 0,
                                        true
                                      )} /cab`
                                    : "-"}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <br />
                          <Table>
                            <thead>
                              <tr>
                                <th className="width-140"></th>
                                <th className="width-140">Valor da @</th>
                                <th className="width-140">
                                  Peso vivo de venda final
                                </th>
                                <th className="width-140">GMD</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Table */}
                              <tr>
                                <td className="width-140">Tabela</td>
                                <td className="width-140">
                                  {item.result?.boitelPriceArrobaPrice
                                    ? `${numberMask(
                                        item.result?.boitelPriceArrobaPrice ||
                                          0,
                                        true
                                      )} /@`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.boitelPriceFinalWeight
                                    ? `Pvf ${numberMask(
                                        item.result?.boitelPriceFinalWeight ||
                                          0,
                                        false
                                      )} kg ou ${getTwoDecimalDigits(
                                        (item.result?.boitelPriceFinalWeight *
                                          0.5) /
                                          15,
                                        1
                                      )} @`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.boitelPriceAverageWeightGain
                                    ? `${numberMask(
                                        item.result
                                          ?.boitelPriceAverageWeightGain || 0,
                                        false
                                      )} kg/dia`
                                    : "-"}
                                </td>
                              </tr>
                              {/* User */}
                              <tr>
                                <td className="width-140">Negociação</td>
                                <td className="width-140">
                                  {item.result?.userArrobaPrice
                                    ? `${numberMask(
                                        item.result?.userArrobaPrice || 0,
                                        true
                                      )} /@`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.userFinalWeight
                                    ? `Pvf ${numberMask(
                                        item.result?.userFinalWeight || 0,
                                        false
                                      )} kg ou ${getTwoDecimalDigits(
                                        (item.result?.userFinalWeight *
                                          (item.carcassHarnessing
                                            ? item.carcassHarnessing / 100
                                            : allDietStrategies.find(
                                                (ds) =>
                                                  ds.id === item.dietStrategyId
                                              )?.dietPeriods[0]
                                                ?.carcassHarnessing / 100)) /
                                          15 || 0,
                                        1
                                      )} @`
                                    : "-"}
                                </td>
                                <td className="width-140">
                                  {item.result?.userAverageWeightGain
                                    ? `${numberMask(
                                        item.result?.userAverageWeightGain || 0,
                                        false
                                      )} kg/dia`
                                    : "-"}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </CollapseContainer.Panel>
                      </CollapseContainer>
                    </>
                  )}
                </Body>
              </List.Item>
            )}
          />
        </Spin>
      )}
    </Container>
  );
};

export default NewEditBoitelScenarioResults;
