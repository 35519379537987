import styled from "styled-components";

export const Container = styled.div`
    .filterRow {
        margin-top: 16px;
        margin-bottom: 16px;
    
        .ant-row:first-child {
            margin-bottom: 8px;
        }
    
        .filterLabel {
            color: #9074bf;
            font-size: 14px;
            font-weight: bold;
            margin-left: 15px;
        }
    }
`;