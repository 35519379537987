import React from "react";

// import { Container } from './styles';

const ArrowRedLeftIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.818"
    height="17.818"
    viewBox="0 0 17.818 17.818"
  >
    <g
      id="next_1_"
      data-name="next (1)"
      transform="translate(17.818 17.818) rotate(180)"
    >
      <g id="Group_2710" data-name="Group 2710">
        <path
          id="Path_1943"
          data-name="Path 1943"
          d="M8.909,0a8.909,8.909,0,1,0,8.909,8.909A8.909,8.909,0,0,0,8.909,0Zm2.715,9.513a.84.84,0,0,1-.141.112L8.14,12.968a.832.832,0,0,1-1.176-1.176L9.845,8.91,6.978,6.043A.832.832,0,0,1,8.154,4.867L11.483,8.2a.864.864,0,0,1,.141,1.317Z"
          fill="#D44C4C"
        />
      </g>
    </g>
  </svg>
);
export default ArrowRedLeftIcon;
