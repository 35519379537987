// import axios from "axios";
import apiFarms from "../config/api_farms";
import getHeaders from "./getHeaders";

// const apiFarms = axios.create({
//   baseURL: "http://localhost:8082",
// });

export function getSupplementIndex(payload) {
  const { groupId, farmId, signal, isInputSupplier } = payload;

  if (isInputSupplier) {
    return apiFarms.get(
      `/bovexo/suppliers/groups/${groupId}/farms/${farmId}/supplements`,
      getHeaders(false, signal)
    );
  } else {
    return apiFarms.get(
      `/bovexo/${groupId}/farms/${farmId}/supplements`,
      getHeaders(false, signal)
    );
  }
}

export function getSupplementIndexDropDown(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/supplements/active`,
    getHeaders(false, signal)
  );
}

export function getSupplementShow(payload) {
  const { groupId, farmId, id, signal, isInputSupplier } = payload;
  if (isInputSupplier) {
    return apiFarms.get(
      `/bovexo/suppliers/groups/${groupId}/farms/${farmId}/supplements/${id}`,
      getHeaders(false, signal)
    );
  } else {
    if (farmId != null) {
      return apiFarms.get(
        `/bovexo/${groupId}/farms/${farmId}/supplements/${id}`,
        getHeaders(false, signal)
      );
    } else {
      return apiFarms.get(
        `/bovexo/${groupId}/supplements/${id}`,
        getHeaders(false, signal)
      );
    }
  }
}

export function getSupplementBySeasonShow(payload) {
  const { groupId, farmId, id, season, signal } = payload;
  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/supplements/${id}/season/${season}`,
    getHeaders(false, signal)
  );
}

export function saveSupplement(payload) {
  const { groupId, farmId, id, supplement, signal, isInputSupplier } = payload;

  if (isInputSupplier) {
    if (id != null) {
      return apiFarms.patch(
        `/bovexo/suppliers/groups/${groupId}/farms/${farmId}/supplements/${id}`,
        supplement,
        getHeaders(false, signal)
      );
    } else {
      return apiFarms.post(
        `/bovexo/suppliers/groups/${groupId}/farms/${farmId}/supplements`,
        supplement,
        getHeaders(false, signal)
      );
    }
  } else {
    if (supplement?.farmId != null) {
      if (id != null) {
        return apiFarms.patch(
          `/bovexo/${groupId}/farms/${farmId}/supplements/${id}`,
          supplement,
          getHeaders(false, signal)
        );
      } else {
        return apiFarms.post(
          `/bovexo/${groupId}/farms/${farmId}/supplements`,
          supplement,
          getHeaders(false, signal)
        );
      }
    } else {
      if (id != null) {
        return apiFarms.patch(
          `/bovexo/${groupId}/supplements/${id}`,
          supplement,
          getHeaders(false, signal)
        );
      } else {
        return apiFarms.post(
          `/bovexo/${groupId}/supplements`,
          supplement,
          getHeaders(false, signal)
        );
      }
    }
  }
}

export function deleteSupplementServ(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiFarms.delete(
    `/bovexo/${groupId}/farms/${farmId}/supplements/${id}`,
    getHeaders(false, signal)
  );
}
