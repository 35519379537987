export const Types = {
  RESET_DATA: "importLog/RESET_DATA",
  GET_ALL_ANIMAL_IMPORT: "importLog/GET_ALL_ANIMAL_IMPORT",
  GET_ALL_ANIMAL_IMPORT_SUCCESS: "importLog/GET_ALL_ANIMAL_IMPORT_SUCCESS",
  GET_ALL_ANIMAL_IMPORT_ERROR: "importLog/GET_ALL_ANIMAL_IMPORT_ERROR",
  GET_ALL_ANIMAL_WEIGHT_IMPORT: "importLog/GET_ALL_ANIMAL_WEIGHT_IMPORT",
  GET_ALL_ANIMAL_WEIGHT_IMPORT_SUCCESS: "importLog/GET_ALL_ANIMAL_WEIGHT_IMPORT_SUCCESS",
  GET_ALL_ANIMAL_WEIGHT_IMPORT_ERROR: "importLog/GET_ALL_ANIMAL_WEIGHT_IMPORT_ERROR",
  SHOW_IMPORT_LOG_DETAIL: "importLog/SHOW_IMPORT_LOG_DETAIL",
};

export const Creators = {
  indexAnimalImportLog: (groupId, farmId, page = 0, sorter = {}, filters = "") => ({
    type: Types.GET_ALL_ANIMAL_IMPORT,
    payload: {
      groupId,
      farmId,
      page,
      sorter,
      filters
    }
  }),
  indexAnimalImportLogSuccess: data => ({
    type: Types.GET_ALL_ANIMAL_IMPORT_SUCCESS,
    payload: {
      data
    }
  }),
  indexAnimalImportLogError: error => ({
    type: Types.GET_ALL_ANIMAL_IMPORT_ERROR,
    payload: {
      error
    }
  }),
  indexAnimalWeightImportLog: (groupId, farmId, page = 0, sorter = {}, filters = "") => ({
    type: Types.GET_ALL_ANIMAL_WEIGHT_IMPORT,
    payload: {
      groupId,
      farmId,
      page,
      sorter,
      filters
    }
  }),
  indexAnimalWeightImportLogSuccess: data => ({
    type: Types.GET_ALL_ANIMAL_WEIGHT_IMPORT_SUCCESS,
    payload: {
      data
    }
  }),
  indexAnimalWeightImportLogError: error => ({
    type: Types.GET_ALL_ANIMAL_WEIGHT_IMPORT_ERROR,
    payload: {
      error
    }
  }),
  showImportLogDetail: () => ({
    type: Types.SHOW_IMPORT_LOG_DETAIL,
    payload: {}
  })
};

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  importData: null,
  error: {}
};

export default function importLog(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_ALL_ANIMAL_IMPORT:
      return { ...state, isLoading: true };
    case Types.GET_ALL_ANIMAL_IMPORT_SUCCESS:
      const { data: animalImportLogs } = action.payload;
      return { ...state, isLoading: false, data: animalImportLogs };
    case Types.GET_ALL_ANIMAL_IMPORT_ERROR:
      return {
        ...state,
        isLoading: false,
        data: null,
        error: action.payload.error
      };
    case Types.GET_ALL_ANIMAL_WEIGHT_IMPORT:
      return { ...state, isLoading: true };
    case Types.GET_ALL_ANIMAL_WEIGHT_IMPORT_SUCCESS:
      const { data: animalWeightImportLogs } = action.payload;
      return { ...state, isLoading: false, data: animalWeightImportLogs };
    case Types.GET_ALL_ANIMAL_WEIGHT_IMPORT_ERROR:
      return {
        ...state,
        isLoading: false,
        data: null,
        error: action.payload.error
      };
    case Types.SHOW_IMPORT_LOG_DETAIL:
      return {
        ...state,
        importData: state.importData
      };      
    default:
      return state;
  }
}
