import styled from "styled-components";

export const Container = styled.div`
  border-radius: 5px;
  background: #f5f5f5;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 21px 17px;
  margin-top: 10px;

  div.valuesContainer {
    display: flex;
  }

  valuesRight {
    display: flex;
    text-align: right;
  }

  .iconError {
    color: #d44c4c;
  }

  span.sub-title {
    color: #9074BF;
    font-size: 14px;
    font-weight: bold;
  }

  span.sub-title-value {
    color: #A5A5A5;
    font-size: 14px;
  }




  span {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;

    &.values {
      display: flex;
      align-items: center;
      margin-right: 30px;
    }

    svg {
      margin-left: 6px;
    }

    strong {
      margin-left: 3px;
    }

    



    div.circle {
      width: 10px;
      height: 10px;
      margin-right: 6px;
      border-radius: 50%;
      &.red {
        background: linear-gradient(90deg, #b16060 50%, #9b4d4d 50%);
      }
      &.green {
        background: linear-gradient(90deg, #85b6a6 50%, #679c8b 50%);
      }
      &.purple {
        background: linear-gradient(90deg, #6c578f 50%, #5d4880 50%);
      }
    }
  }
`;
