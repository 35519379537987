import React from "react";

// import { Container } from './styles';

const PlusBlueIcon = () => (
  <svg
    id="bovexoPlusBlueIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="15.046"
    height="15.046"
    viewBox="0 0 15.046 15.046"
  >
    <defs>
      <clipPath id="clip-path">
        <rect width="15.046" height="15.046" fill="none" />
      </clipPath>
    </defs>
    <g
      id="Btn_add_animal"
      data-name="Btn add animal"
      clipPath="url(#clip-path)"
    >
      <g id="unhover" transform="translate(-1280.196 -442.758)">
        <g id="plus" transform="translate(1216.196 378.758)">
          <path
            id="Path_968"
            data-name="Path 968"
            d="M326.314,322.687h-2.552v-2.552a.135.135,0,0,0-.134-.134h-.806a.135.135,0,0,0-.134.134v2.552h-2.552a.135.135,0,0,0-.134.134v.806a.135.135,0,0,0,.134.134h2.552v2.552a.135.135,0,0,0,.134.134h.806a.135.135,0,0,0,.134-.134v-2.552h2.552a.135.135,0,0,0,.134-.134v-.806A.135.135,0,0,0,326.314,322.687Z"
            transform="translate(-251.701 -251.701)"
            fill="#4a85ae"
          />
          <path
            id="Path_969"
            data-name="Path 969"
            d="M71.523,64a7.523,7.523,0,1,0,7.523,7.523A7.524,7.524,0,0,0,71.523,64Zm0,13.77a6.247,6.247,0,1,1,6.247-6.247A6.248,6.248,0,0,1,71.523,77.77Z"
            fill="#4a85ae"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default PlusBlueIcon;
