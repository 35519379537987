import { Col, Row, Spin } from "antd";
import moment from "moment";
import React, { memo, useCallback } from "react";
import { numberMask } from "../../../../../services/helpersMethodsService";
import { CustomDivider, TabSaleScenarioInfoContent } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import { Creators as DietStrategyActions } from "../../../../../store/ducks/dietStrategy";
import EyeIcon from "../../../../../components/utils/icons/eye";

const SaleScenarioDetailsParametersAndLotsTab = memo(
  ({
    tabActive,
    saleScenarioInfo,
    isLoadingSaleScenarioLots,
    saleScenarioLots,
  }) => {
    const {
      translation,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    } = useSelector((state) => state.app);

    const dispatch = useDispatch();

    const showDietStrategyDetails = useCallback(
      (id) => {
        dispatch(DietStrategyActions.showDietStrategy(groupId, farmId, id));
      },
      [groupId, farmId, dispatch]
    );

    return (
      <TabSaleScenarioInfoContent active={tabActive === 2 ? true : false}>
        <Row type="flex">
          <Col span={24}>
            <span className="titleParameters">PARÂMETROS DO CENÁRIO</span>
          </Col>
          <Col span={24}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <span>Retroativo</span>
                  </td>
                  <td className="alignRight">
                    <span>
                      <strong>
                        {saleScenarioInfo?.retroactive
                          ? translation.buttons.yes
                          : translation.buttons.no}
                      </strong>
                    </span>
                  </td>
                </tr>
                {saleScenarioInfo?.retroactive && (
                  <tr>
                    <td>
                      <span>Data de início do Processamento</span>
                    </td>
                    <td className="alignRight">
                      <span>
                        <strong>
                          {moment(saleScenarioInfo?.retroactiveDate).format(
                            "DD/MM/YYYY"
                          )}
                        </strong>
                      </span>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <span>Lote econômico</span>
                  </td>
                  <td className="alignRight">
                    <span>
                      <strong>
                        {`${saleScenarioInfo?.animalsInTruck} ${
                          saleScenarioInfo?.animalsInTruckUnit === "UA"
                            ? "UA"
                            : "cabeças"
                        }`}
                      </strong>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Análise de Mercado</span>
                  </td>
                  <td className="alignRight">
                    <span>
                      <strong>{`${saleScenarioInfo?.marketAnalysisName}`}</strong>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Peso máximo para venda</span>
                  </td>
                  <td className="alignRight">
                    <span>
                      <strong>
                        {saleScenarioInfo?.useQuotaCriteria === true
                          ? `${numberMask(
                              saleScenarioInfo?.quotaMaxWeight || 0
                            )} kg`
                          : `${numberMask(
                              saleScenarioInfo?.maximumWeight || 0
                            )} kg`}
                      </strong>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Peso mínimo para venda</span>
                  </td>
                  <td className="alignRight">
                    <span>
                      <strong>
                        {saleScenarioInfo?.useQuotaCriteria === true
                          ? `${numberMask(
                              saleScenarioInfo?.quotaMinWeight || 0
                            )} kg`
                          : `${numberMask(
                              saleScenarioInfo?.minimumWeight || 0
                            )} kg`}
                      </strong>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Idade máxima para venda</span>
                  </td>
                  <td className="alignRight">
                    <span>
                      <strong>
                        {saleScenarioInfo?.useQuotaCriteria === true
                          ? `${saleScenarioInfo?.quotaMaxAge || 0} dias`
                          : `${
                              saleScenarioInfo?.maximumAge != null
                                ? saleScenarioInfo?.maximumAge.toFixed(0)
                                : "-"
                            } dias`}
                      </strong>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Idade mínima para venda</span>
                  </td>
                  <td className="alignRight">
                    <span>
                      <strong>
                        {saleScenarioInfo?.useQuotaCriteria === true
                          ? `${saleScenarioInfo?.quotaMinAge || 0} dias`
                          : `${
                              saleScenarioInfo?.minimumAge != null
                                ? saleScenarioInfo?.minimumAge.toFixed(0)
                                : "-"
                            } dias`}
                      </strong>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Descasca</span>
                  </td>
                  <td className="alignRight">
                    <span>
                      <strong>
                        {saleScenarioInfo?.allowSeparateSell ? `Sim` : `Não`}
                      </strong>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Informar novo % de Aprov. de Carcaça?</span>
                  </td>
                  <td className="alignRight">
                    <span>
                      <strong>
                        {saleScenarioInfo?.useCustomCarcassHarnessing
                          ? "Sim"
                          : "Não"}
                      </strong>
                    </span>
                  </td>
                </tr>
                {saleScenarioInfo?.useCustomCarcassHarnessing && (
                  <tr>
                    <td>
                      <span>Aprov. de Carcaça</span>
                    </td>
                    <td className="alignRight">
                      <span>
                        <strong>
                          {`${numberMask(
                            saleScenarioInfo?.carcassHarnessing || 0,
                            false
                          )} %`}
                        </strong>
                      </span>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <span>Simular potencial de créditos carbono ?</span>
                  </td>
                  <td className="alignRight">
                    <span>
                      <strong>
                        {saleScenarioInfo?.useCarbonCredit ? "Sim" : "Não"}
                      </strong>
                    </span>
                  </td>
                </tr>
                {saleScenarioInfo?.useCarbonCredit && (
                  <tr>
                    <td>
                      <span>Valor da crédito de carbono</span>
                    </td>
                    <td className="alignRight">
                      <span>
                        <strong>
                          {`${numberMask(
                            Number(saleScenarioInfo?.carbonCredit || 0),
                            true
                          )} `}
                          <span>
                            kg CO<sub>2</sub>E
                          </span>
                        </strong>
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Col>
        </Row>
        {/* Restrictions */}
        <CustomDivider dashed>RESTRIÇÕES</CustomDivider>
        <Row type="flex">
          <Col span={24}>
            <table>
              <thead>
                <tr>
                  <th>
                    <span className="lotTableTd">Restrição</span>
                  </th>
                  <th className="lotTableTd alignRight">Forte ?</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span>Animais no caminhão</span>
                  </td>
                  <td className="alignRight">
                    <span>
                      <strong>
                        {saleScenarioInfo?.strongAnimalsInTruckRestriction
                          ? "Sim"
                          : "Não"}
                      </strong>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Idade para Venda</span>
                  </td>
                  <td className="alignRight">
                    <span>
                      <strong>
                        {saleScenarioInfo?.strongAgeRestriction ? "Sim" : "Não"}
                      </strong>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Peso para Venda</span>
                  </td>
                  <td className="alignRight">
                    <span>
                      <strong>
                        {saleScenarioInfo?.strongWeightRestriction
                          ? "Sim"
                          : "Não"}
                      </strong>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        {/* Lots table */}
        <CustomDivider dashed>LOTES</CustomDivider>
        <Spin spinning={isLoadingSaleScenarioLots}>
          <Row type="flex">
            <Col span={24}>
              <table>
                <thead>
                  <tr>
                    <th>
                      <span className="lotTableTd">Lotes</span>
                    </th>
                    <th className="lotTableTd alignRight">Estratégias</th>
                  </tr>
                </thead>
                <tbody>
                  {saleScenarioLots != null && saleScenarioLots.length > 0 ? (
                    saleScenarioLots.map((ssl, index) => (
                      <tr key={index}>
                        <td>
                          <span>{ssl.lot.name}</span>
                        </td>
                        <td className="alignRight">
                          {ssl.dietStrategies.map((ds) => (
                            <p key={ds.id}>
                              <strong>{ds.name}</strong>
                              <button
                                onClick={() => showDietStrategyDetails(ds.id)}
                              >
                                <EyeIcon isActive />
                              </button>
                            </p>
                          ))}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2" className="alignCenter">
                        <span>Nenhum lote</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
        </Spin>
      </TabSaleScenarioInfoContent>
    );
  }
);

export default SaleScenarioDetailsParametersAndLotsTab;
