export const Types = {
  RESET_DATA: "costCenter/RESET_DATA",
  GET_ALL_COST_CENTER: "costCenter/GET_ALL_COST_CENTER",
  GET_ALL_COST_CENTER_SUCCESS: "costCenter/GET_ALL_COST_CENTER_SUCCESS",
  GET_ALL_COST_CENTER_ERROR: "costCenter/GET_ALL_COST_CENTER_ERROR",
  SET_IS_LOADING_LIST: "costCenter/SET_IS_LOADING_LIST",
  SHOW_HIDE_COST_CENTER_FORM: "costCenter/SHOW_HIDE_COST_CENTER_FORM",
  SET_IS_LOADING_FORM: "costCenter/SET_IS_LOADING_FORM",
  INACTIVATE_COST_CENTER: "costCenter/INACTIVATE_COST_CENTER",
  ACTIVATE_COST_CENTER: "costCenter/ACTIVATE_COST_CENTER",
  SET_IS_LOADING_APPORTIONMENT_FORM: "costCenter/SET_IS_LOADING_APPORTIONMENT_FORM",
  EXPAND_OR_COMPACT_APPORTIONMENT_FORM: "costCenter/EXPAND_OR_COMPACT_APPORTIONMENT_FORM",
  SHOW_HIDE_APPORTIONMENT_FORM: "costCenter/SHOW_HIDE_APPORTIONMENT_FORM",
};

export const Creators = {
  resetState: () => ({
    type: Types.RESET_DATA,
  }),
  index: (
    groupId,
    farmId,
  ) => ({
    type: Types.GET_ALL_COST_CENTER,
    payload: {
      groupId,
      farmId
    },
  }),
  indexSuccess: (data) => ({
    type: Types.GET_ALL_COST_CENTER_SUCCESS,
    payload: {
      data,
    },
  }),
  indexError: (error) => ({
    type: Types.GET_ALL_COST_CENTER_ERROR,
    payload: {
      error,
    },
  }),
  setIsLoadingList: (isLoading = false) => ({
    type: Types.SET_IS_LOADING_LIST,
    payload: {
      isLoading
    },
  }),
  showOrHideForm: (id = null) => ({
    type: Types.SHOW_HIDE_COST_CENTER_FORM,
    payload: {
      id
    },
  }),
  setIsLoadingForm: (isLoading = false) => ({
    type: Types.SET_IS_LOADING_FORM,
    payload: {
      isLoading
    },
  }),
  inactivateCostCenter: (groupId, farmId, id) => ({
    type: Types.INACTIVATE_COST_CENTER,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),
  activateCostCenter: (groupId, farmId, id) => ({
    type: Types.ACTIVATE_COST_CENTER,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),
  showOrHideApportionmentForm: (id = null) => ({
    type: Types.SHOW_HIDE_APPORTIONMENT_FORM,
    payload: {
      id
    },
  }),
  setIsLoadingApportionmentForm: (isLoading = false) => ({
    type: Types.SET_IS_LOADING_APPORTIONMENT_FORM,
    payload: {
      isLoading
    },
  }),
  expandOrCompactApportionmentForm: () => ({
    type: Types.EXPAND_OR_COMPACT_APPORTIONMENT_FORM,
  }),
};

const INITIAL_STATE = {
  costCenterList: [],
  isCostCenterFormVisible: false,
  isApportionmentFormVisible: false,
  costCenterId: null,
  isLoadingList: false,
  isLoadingForm: false,
  isLoadingApportionmentForm: false,
  isApportionmentFormExpanded: false,
  error: {},
};

export default function costCenter(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_ALL_COST_CENTER:
      return {
        ...state,
        isLoadingList: true,
      };
    case Types.GET_ALL_COST_CENTER_SUCCESS:
      const { data: list } = action.payload;
      return { ...state, isLoadingList: false, costCenterList: list };
    case Types.GET_ALL_COST_CENTER_ERROR:
      const { error } = action.payload;
      return {
        ...state,
        isLoadingList: false,
        costCenterList: null,
        error,
      };
    case Types.SET_IS_LOADING_LIST:
      return { ...state, isLoadingList: action.payload.isLoading };
    case Types.SHOW_HIDE_COST_CENTER_FORM:
      return { ...state, costCenterId: action.payload.id, isCostCenterFormVisible: !state.isCostCenterFormVisible };
    case Types.SET_IS_LOADING_FORM:
      return { ...state, isLoadingForm: action.payload.isLoading };
    case Types.INACTIVATE_COST_CENTER:
      return { ...state, isLoadingList: true };
    case Types.ACTIVATE_COST_CENTER:
      return { ...state, isLoadingList: true };
    case Types.SET_IS_LOADING_APPORTIONMENT_FORM:
      return { ...state, isLoadingApportionmentForm: action.payload.isLoading };
    case Types.EXPAND_OR_COMPACT_APPORTIONMENT_FORM:
      return { ...state, isApportionmentFormExpanded: !state.isApportionmentFormExpanded };
    case Types.SHOW_HIDE_APPORTIONMENT_FORM:
      return { ...state, costCenterId: action.payload.id, isApportionmentFormExpanded: true, isApportionmentFormVisible: !state.isApportionmentFormVisible };
    default:
      return state;
  }
}
