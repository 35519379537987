import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

import { Col, Icon, Row, Tabs } from "antd";

import { Container } from "./styles";
// Pages
import ButtonStandard from "../../components/utils/button";
import AnimalBirth from "../animalBirth";
import AnimalFarmReproductiveProtocol from "../animalFarmReproductiveProtocol";
import AnimalPregnancyDiagnosis from "../animalPregnancyDiagnosis";
import HandlingReproductionCoverage from "../handlingReproductionCoverage";
import DashboardReproductionBirth from "./dashboards/dashboardReproductionBirth";
import DashboardReproductionPeriod from "./dashboards/dashboardReproductionPeriod";
import DashboardReproductionWeaning from "./dashboards/dashboardReproductionWeaning";

const HandlingReproduction = () => {
  const [tabSelect, setTabSelect] = useState("5");
  const [isVisibleDashboards, setIsVisibleDashboards] = useState(true);

  const { translation } = useSelector((state) => state.app);

  const location = useLocation();

  const handleTabClick = (key, e) => {
    setTabSelect(key);
  };

  useEffect(() => {
    const values = queryString.parse(location.search);
    if (values && values.tabSelect && values.subTabSelect) {
      if (values.tabSelect === "4") setTabSelect(values.subTabSelect);
    }
  }, [location.search]);

  return (
    <Container>
      <Row type="flex" justify="end" style={{ marginTop: 10 }}>
        <Col>
          <ButtonStandard
            type="button"
            buttonType="secondary"
            onClick={() => setIsVisibleDashboards((prevState) => !prevState)}
          >
            {isVisibleDashboards ? (
              <>
                <Icon type="fullscreen-exit" /> Ocultar painéis
              </>
            ) : (
              <>
                <Icon type="fullscreen" /> Exibir painéis
              </>
            )}
          </ButtonStandard>
        </Col>
      </Row>
      {isVisibleDashboards && (
        <>
          <Row type="flex" gutter={8} style={{ marginTop: 10 }}>
            <Col span={12}>
              <DashboardReproductionPeriod />
            </Col>
            <Col span={12}>
              <DashboardReproductionWeaning />
            </Col>
          </Row>
          <Row type="flex" gutter={8} style={{ marginTop: 10 }}>
            <Col span={24}>
              <DashboardReproductionBirth />
            </Col>
          </Row>
        </>
      )}
      {/* Tabs */}
      <Row type="flex" style={{ marginTop: 10 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Tabs
            type="card"
            defaultActiveKey="5"
            activeKey={tabSelect}
            onTabClick={handleTabClick}
          >
            <Tabs.TabPane
              tab={
                <span>
                  {translation.handlingReproduction.tabs.reproductiveProtocol}
                </span>
              }
              key="5"
            >
              <AnimalFarmReproductiveProtocol />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <span>{translation.handlingReproduction.tabs.coverage}</span>
              }
              key="1"
            >
              <HandlingReproductionCoverage />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <span>
                  {translation.handlingReproduction.tabs.pregnancyDiagnosis}
                </span>
              }
              key="2"
            >
              <AnimalPregnancyDiagnosis />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <span>{translation.handlingReproduction.tabs.animalBirth}</span>
              }
              key="3"
            >
              <AnimalBirth />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default HandlingReproduction;
