import React from 'react';

// import { Container } from './styles';

const MenuBars = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g id="unhover" transform="translate(-123 -15)">
      <g
        id="Retângulo_34"
        data-name="Retângulo 34"
        transform="translate(123 15)"
        fill="none"
        stroke="#fff"
        strokeWidth="1"
      >
        <rect width="32" height="32" rx="7" stroke="none" />
        <rect x="0.5" y="0.5" width="31" height="31" rx="6.5" fill="none" />
      </g>
      <g id="Grupo_516" data-name="Grupo 516" transform="translate(-153.5 -3.5)">
        <g id="Grupo_514" data-name="Grupo 514" transform="translate(-30)">
          <line
            id="Linha_192"
            data-name="Linha 192"
            x2="14"
            transform="translate(315.5 29.5)"
            fill="none"
            stroke="#fff"
            strokeWidth="2"
          />
          <line
            id="Linha_193"
            data-name="Linha 193"
            x2="14"
            transform="translate(315.5 34.5)"
            fill="none"
            stroke="#fff"
            strokeWidth="2"
          />
          <line
            id="Linha_194"
            data-name="Linha 194"
            x2="14"
            transform="translate(315.5 39.5)"
            fill="none"
            stroke="#fff"
            strokeWidth="2"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default MenuBars;
