export const Types = {
  SHOW_DRAWER_INVITE: "supplierGroup/SHOW_DRAWER_INVITE",
  HIDE_DRAWER_INVITE: "supplierGroup/HIDE_DRAWER_INVITE",
};

export const Creators = {
  showDrawer: () => {
    return ({
    type: Types.SHOW_DRAWER_INVITE,
    payload: {},
  })},
  hideDrawer: () => ({
    type: Types.HIDE_DRAWER_INVITE,
    payload: {},
  }),
};

const INITIAL_STATE = {
  drawerInviteVisible: false,
};

export default function supplierGroup(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SHOW_DRAWER_INVITE:
      return {
        ...state,
        drawerInviteVisible: true,
      };
    case Types.HIDE_DRAWER_INVITE:
      return {
        ...state,
        drawerInviteVisible: false,
      };
    default:
      return state;
  }
}
