import apiAnimals from "../config/api_animals";
import getHeaders from "./getHeaders";

export function getLotMovementHistoryIndex(payload) {
  const { groupId, farmId, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/lots/movement/histories`,
    getHeaders(false, signal)
  );
}

export function deleteLotMovementHistory(payload) {
  const { groupId, farmId, requestId, signal } = payload;

  return apiAnimals.delete(
    `/bovexo/${groupId}/farms/${farmId}/lots/movement/histories/${requestId}`,
    getHeaders(false, signal)
  );
}
