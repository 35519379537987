import { call, put } from "redux-saga/effects";
import { Creators as VacinationsActions } from "../ducks/vacinations";

/** Services */
import {
  indexAll,
} from "../../services/vacinationService";

export function* indexVacinations(action) {
  try {
    const {
      data: { results: vacinations },
    } = yield call(indexAll, action.payload);
    yield put(VacinationsActions.indexSuccess(vacinations));
  } catch (error) {
    yield put(VacinationsActions.indexError(error));
  }
}