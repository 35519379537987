import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Container, Table } from "./styles";
import RainIcon from "./icons/rain";
import SunIcon from "./icons/sun";
import TransitionIcon from "./icons/transition";
import AlertIcon from "./icons/alert";
import SuccessIcon from "./icons/success";
import PencilIcon from "../../../utils/icons/pencil";
import TrashIcon from "../../../utils/icons/trash";
import PlusBlueIcon from "../../../utils/icons/plus/blue";
import EyeIcon from "../../../utils/icons/eye";
// Services
import { numberMask } from "../../../../services/helpersMethodsService";

const DisplayPastureSeason = ({
  data,
  seasonType,
  onEdit,
  onDelete,
  onlyShow = false,
  onShow,
}) => {
  const [season, setSeason] = useState([]);
  const { translation } = useSelector((state) => state.app);
  useEffect(() => {
    setSeason(data);
    return () => {};
  }, [data]);
  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th
              className={
                seasonType === "Water"
                  ? "icon water"
                  : seasonType === "Dry"
                  ? "icon dry"
                  : seasonType === "Transition"
                  ? "icon transition"
                  : ""
              }
            >
              {seasonType === "Water" ? (
                <span>
                  <RainIcon />
                  Águas
                </span>
              ) : seasonType === "Dry" ? (
                <span>
                  <SunIcon /> Seca
                </span>
              ) : seasonType === "Transition" ? (
                <span>
                  <TransitionIcon /> Transição
                </span>
              ) : null}
            </th>
            <th className="status">Status</th>
            <th className="cost">{translation.pasture.form.cost}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {season &&
            season.map((data) => (
              <tr key={data.key}>
                <td>
                  <strong>
                    {data.fertilized
                      ? translation.pasture.form.fertilized
                      : translation.pasture.form.notFertilized}
                  </strong>
                </td>
                <td>
                  {data.status === "NI" ? (
                    <span>
                      <AlertIcon /> {translation.status.pendingData}
                    </span>
                  ) : (
                    <span className="success">
                      <SuccessIcon /> {translation.status.informedData}
                    </span>
                  )}
                </td>
                <td className="purple">
                  {data?.cost === 0 && data?.status === "NI"
                    ? "-"
                    : `${numberMask(
                        data?.cost || 0,
                        true,
                        undefined,
                        undefined,
                        data?.cost >= 0.1 ? 2 : 3
                      )} /kg`}
                </td>
                <td>
                  <div className="buttonGroup">
                    {data.status === "I" && onlyShow === true && (
                      <button
                        className="add"
                        type="button"
                        onClick={() => onShow(data.key, seasonType)}
                      >
                        <EyeIcon isActive />
                      </button>
                    )}
                    {data.status === "NI" && !onlyShow && (
                      <button
                        className="add"
                        type="button"
                        onClick={() => onEdit(data.key, seasonType)}
                      >
                        {translation.buttons.addInfo}
                        <PlusBlueIcon />
                      </button>
                    )}
                    {data.status === "I" && !onlyShow && (
                      <button
                        className="edit"
                        type="button"
                        onClick={() => onEdit(data.key, seasonType)}
                      >
                        {translation.buttons.edit}
                        <PencilIcon />
                      </button>
                    )}
                    {data.status === "I" && !onlyShow && (
                      <button
                        className="delete"
                        type="button"
                        onClick={() => onDelete(data.key, seasonType)}
                      >
                        <TrashIcon />
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default DisplayPastureSeason;
