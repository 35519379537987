import React, { useRef, useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import useInseminatorContext from "../../../hooks/useInseminatorContext";

// Components
import { Container, Footer } from "./styles";
import { Row, Col, Input, notification, Spin } from "antd";
import ButtonStandard from "../../utils/button";

// Services
import { save } from "../../../services/inseminatorService";

const validationSchema = Yup.object({
  name: Yup.string().trim().max(25).required(),
  code: Yup.string().trim().nullable().max(10),
});

const DrawerInseminator = () => {
  const formRef = useRef(null);

  const [form, setForm] = useState({
    id: null,
    name: "",
    code: "",
    status: "Active",
  });
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  // Redux Variable
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const { isDrawerVisible, data, closeDrawer, fetchData } =
    useInseminatorContext();

  useEffect(() => {
    if (data !== null) {
      setForm(data);
    }
  }, [data]);

  // Method

  function handleCloseDrawer() {
    setForm({
      id: null,
      name: "",
      code: "",
      status: "Active",
    });
    formRef.current.resetForm({
      id: null,
      name: "",
      code: "",
      status: "Active",
    });
    closeDrawer();
  }

  async function handleSubmitForm(values) {
    setIsLoadingRequest(true);
    try {
      await save({ groupId, farmId, id: values?.id, body: values });
      notification.success({
        message: "Inseminador cadastrado/atualizado com sucesso.",
      });
      fetchData();
      handleCloseDrawer();
    } catch (error) {
      notification.error({
        message: "Erro",
        description: "Erro ao cadastrar/editar inseminador.",
      });
    } finally {
      setIsLoadingRequest(false);
    }
  }

  function handleDrawerVisible(visible) {
    if (visible) {
      if (data === null) {
        formRef.current.resetForm({
          id: null,
          name: "",
          code: "",
          status: "Active",
        });
      }
    }
  }

  return (
    <Container
      title={
        data?.id == null
          ? translation.inseminator.form.titleCreateNewInseminator
          : translation.inseminator.form.titleEditInseminator
      }
      width={700}
      onClose={handleCloseDrawer}
      maskClosable={false}
      visible={isDrawerVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Spin spinning={isLoadingRequest}>
        <Formik
          ref={formRef}
          enableReinitialize={true}
          initialValues={form}
          onSubmit={handleSubmitForm}
          validationSchema={validationSchema}
          render={({
            values,
            errors,
            submitCount,
            setFieldValue,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="drawerForm">
                {errors.length > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                {/* Name */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={errors.name && submitCount > 0 ? "error" : ""}
                  >
                    {translation.inseminator.form.name}*
                  </label>
                </Row>
                <Row type="flex">
                  <Input
                    name="name"
                    value={values.name}
                    maxLength={25}
                    placeholder={translation.defaultPlaceholder}
                    autoCapitalize="off"
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                  />
                </Row>
                {/* Code */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={errors.code && submitCount > 0 ? "error" : ""}
                  >
                    {translation.inseminator.form.code}
                  </label>
                </Row>
                <Row type="flex">
                  <Input
                    name="code"
                    value={values.code}
                    maxLength={10}
                    placeholder={translation.defaultPlaceholder}
                    autoCapitalize="off"
                    onChange={(e) => {
                      setFieldValue("code", e.target.value);
                    }}
                  />
                </Row>
              </div>
              <Footer>
                <Row type="flex">
                  <Col span={24} className="buttonsDiv">
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={closeDrawer}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard type="submit" buttonType="type6">
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
      </Spin>
    </Container>
  );
};

export default DrawerInseminator;
