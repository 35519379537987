import styled from "styled-components";

export const Table = styled.div`
  padding: 8px;

  .selectCustom {
    width: 100%;

    .ant-select-selection {
      border: none;
      border-bottom: 1px solid #fff;
      background: transparent;
      color: #fff;
      border-radius: 0px !important;
    }

    .ant-select-arrow {
      color: #fff;
    }
  }

  .header {
    background-color: #684e94;
    padding: 8px 8px;
    border-radius: 5px;
    color: #fff;
    height: 50px;
  }

  .col {
    border-radius: 5px;
    padding: 4px 8px;
    margin: 4px 0px;
    &.value {
      background-color: #684e94;
      color: #fff;
      text-align: center;

      button {
        width: 100%;
        height: 100%;
        background-color: #684e94;
        color: #fff;
        font-weight: bold;
        text-align: center;
        border: none;
        cursor: pointer;
      }
    }
  }
`;
