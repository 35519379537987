import React, { useState } from "react";

// Redux
import { useSelector } from "react-redux";

// Styles
import { Body, Container, GreyCard } from "./styles";

// Components
import { Tabs } from "antd";
import useModuleContext from "../../hooks/useModuleContext";
import AlertParameter from "../alertParameter";
import Benchmark from "../benchmark";
import IntegrationPartners from "../integrationPartners";
import ProfitCenter from "../profit_center";
import CarbonFootprintParameter from "./carbonFootprintParameter";
import KPIsParameter from "./kpisParameter";
import WeatherSeasonsParameter from "./weatherSeasonsParameter";
import WeightsRankingParameter from "./weightsRanking";

const GeneralParameter = () => {
  const { translation } = useSelector((state) => state.app);
  const { isAllowedFunctions } = useModuleContext();

  const [tabSelect, setTablSelect] = useState("1");

  return (
    <Container>
      <Body>
        <Tabs
          type="card"
          defaultActiveKey="1"
          activeKey={tabSelect}
          onChange={(key) => setTablSelect(key)}
        >
          <Tabs.TabPane tab={<span>Geral</span>} key="1">
            <GreyCard>
              <CarbonFootprintParameter />
            </GreyCard>
            <GreyCard>
              <WeightsRankingParameter />
            </GreyCard>
            <GreyCard>
              <WeatherSeasonsParameter />
            </GreyCard>
            <GreyCard>
              <KPIsParameter />
            </GreyCard>
          </Tabs.TabPane>

          <Tabs.TabPane
            tab={<span>{translation.parameters.tabs.activity}</span>}
            key="2"
            disabled={!isAllowedFunctions(["PROFIT_CENTER"])}
          >
            <ProfitCenter />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<span>{translation.parameters.tabs.alert}</span>}
            key="3"
            disabled={!isAllowedFunctions(["ALERTS"])}
          >
            <AlertParameter />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<span>{translation.parameters.tabs.benchmark}</span>}
            key="4"
            disabled={!isAllowedFunctions(["BENCHMARKS"])}
          >
            <Benchmark />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<span>{translation.parameters.tabs.integration}</span>}
            key="6"
            disabled={!isAllowedFunctions(["INTEGRATIONS"])}
          >
            <IntegrationPartners />
          </Tabs.TabPane>
        </Tabs>
      </Body>
    </Container>
  );
};

export default GeneralParameter;
