import styled from "styled-components";
import { Drawer } from "antd";

export const Container = styled(Drawer)`
  .ant-drawer-body {
    padding: 24px 0px 10px 0px;
  }

  .rowLabel {
    margin-bottom: 7px;
    margin-top: 19px;
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    margin-bottom: 7px;
    margin-top: 19px;
    &.error {
      color: #d44c4c;
    }
  }

  input {
    margin-top: 6px;
  }

  .tableButtonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      display: flex;
      justify-content: center !important;
      text-align: center !important;
      align-items: center !important;
      font-size: 14px !important;
      width: 77px !important;
      height: 30px !important;

      svg {
        margin-right: 3px !important;
      }
    }

    button:first-of-type {
      width: 116px !important;
      margin-right: 12px !important;
    }
  }

  .ant-switch-checked {
    background-color: #a9c133 !important;
  }

  .drawerForm {
    width: 100%;
    padding: 0px 24px;
    min-height: 600px;
  }
`;

export const ModalTitle = styled.span`
  font-size: 25px;
  font-family: "Asap", sans-serif;
  font-weight: bold;
  color: #4b4b4b;
`;

export const ButtonInputNumber = styled.button`
  background: transparent;
  color: #fff;
  border: none;
  width: 100% !important;
  height: 100% !important;

  &:hover {
    cursor: pointer;
  }

  svg {
    margin-left: 5px;
  }
`;

export const Footer = styled.div`
  border-top: 1px solid #e8e8e8;
  margin-top: 20px;
  width: 100%;
  padding: 10px 24px;
  border-radius: 0 0 4px 4px;

  .buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding-right: 24px; */

    button:first-of-type {
      margin-right: 12px !important;
    }
  }

  .selectDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: "Asap", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;
