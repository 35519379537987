import React from 'react';

// import { Container } from './styles';

const BrazilActiveFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="22" viewBox="0 0 27 22">
    <g id="Pt-br" transform="translate(0)">
      <path
        id="Caminho_702"
        data-name="Caminho 702"
        d="M3.111,0H23.889A3.1,3.1,0,0,1,27,3.09V18.91A3.1,3.1,0,0,1,23.889,22H3.111A3.1,3.1,0,0,1,0,18.91V3.09A3.1,3.1,0,0,1,3.111,0Z"
        transform="translate(0 0)"
        fill="#6da544"
      />
      <path
        id="Caminho_698"
        data-name="Caminho 698"
        d="M55.312,100.174l10.79,7.7-10.79,7.7-10.79-7.7Z"
        transform="translate(-41.783 -96.887)"
        fill="#ffda44"
      />
      <ellipse
        id="Elipse_101"
        data-name="Elipse 101"
        cx="5.42"
        cy="5.42"
        rx="5.42"
        ry="5.42"
        transform="translate(8.103 5.572)"
        fill="#f0f0f0"
      />
      <g id="Grupo_251" data-name="Grupo 251" transform="translate(8.104 5.572)">
        <path
          id="Caminho_699"
          data-name="Caminho 699"
          d="M169.693,250.435a9.114,9.114,0,0,0-2.708.409,5.419,5.419,0,0,0,9.857,3.038A9.134,9.134,0,0,0,169.693,250.435Z"
          transform="translate(-166.985 -245.354)"
          fill="#0052b4"
        />
        <path
          id="Caminho_700"
          data-name="Caminho 700"
          d="M184.476,173.416a5.421,5.421,0,0,0-10.3-3.178,11.173,11.173,0,0,1,10.3,3.178Z"
          transform="translate(-173.737 -166.957)"
          fill="#0052b4"
        />
      </g>
      <path
        id="Caminho_701"
        data-name="Caminho 701"
        d="M0,0H10.409a3.1,3.1,0,0,1,3.117,3.09V18.91A3.1,3.1,0,0,1,10.409,22H0Z"
        transform="translate(13.473 0)"
        fill="#0f0f0f"
        opacity="0.11"
      />
    </g>
  </svg>
);
export default BrazilActiveFlag;
