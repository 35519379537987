import apiAnimals from "../config/api_animals";
import getHeaders from "./getHeaders";

export function getHandlingOperationIndex(payload) {
  const { groupId, page, sorter, filters, withoutPagination, signal } = payload;

  if (withoutPagination) {
    return apiAnimals.get(
      `/bovexo/${groupId}/handling/operations?withoutPagination=true`,
      getHeaders(false, signal)
    );
  } else {
    const { field, order } = sorter;

    const orderS = order === "descend" ? "DESC" : "ASC";

    return apiAnimals.get(
      `/bovexo/${groupId}/handling/operations?
     ${page ? `&page=${page - 1}` : ""}
     ${
       Object.entries(sorter).length > 0
         ? `&sort=${field}&direction=${orderS}`
         : ""
     }
     ${filters !== "" ? `&search=${filters}` : ""}`,
      getHeaders(false, signal)
    );
  }
}

export function getHandlingOperationIndexDropDown(payload) {
  const { groupId, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/handling/operations?withoutPagination=true`,
    getHeaders(false, signal)
  );
}

export function getHandlingOperationShow(payload) {
  const { groupId, id, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/handling/operations/${id}`,
    getHeaders(false, signal)
  );
}

export function saveHandlingOperation(payload) {
  const { groupId, id, handlingOperation, signal } = payload;
  if (id != null) {
    return apiAnimals.patch(
      `/bovexo/${groupId}/handling/operations/${id}`,
      handlingOperation,
      getHeaders(false, signal)
    );
  } else {
    return apiAnimals.post(
      `/bovexo/${groupId}/handling/operations`,
      handlingOperation,
      getHeaders(false, signal)
    );
  }
}

export function deleteHandlingOperationServ(payload) {
  const { groupId, id, signal } = payload;

  return apiAnimals.delete(
    `/bovexo/${groupId}/handling/operations/${id}`,
    getHeaders(false, signal)
  );
}
