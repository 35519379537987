import styled from "styled-components";
import { Button, Divider, Row, Drawer } from "antd";

export const Container = styled(Drawer)`
  .ant-drawer-body {
    padding: 24px 0px 10px 0px;
  }

  .drawerForm {
    width: 100%;
    padding: 0px 24px;
    min-height: -moz-calc(100vh - 165px);
    min-height: -webkit-calc(100vh - 165px);
    min-height: calc(100vh - 165px);
  }

  .rowLabel {
    margin-bottom: 7px;
    margin-top: 19px;
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    margin-bottom: 7px;
    margin-top: 19px;
    &.error {
      color: #d44c4c;
      &:first-of-type {
        margin-top: 10px;
        margin-left: 0px;
      }
    }
  }

  .ant-row.rowInput {
    margin-top: 8px;
  }

  .tableButtonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      display: flex;
      justify-content: center !important;
      text-align: center !important;
      align-items: center !important;
      font-size: 14px !important;
      width: 77px !important;
      height: 30px !important;

      svg {
        margin-right: 3px !important;
      }
    }

    button:first-of-type {
      width: 116px !important;
      margin-right: 12px !important;
    }
  }

  .ant-switch-checked {
    background-color: #a9c133 !important;
  }
`;

export const ModalTitle = styled.span`
  font-size: 25px;
  font-family: "Asap", sans-serif;
  font-weight: bold;
  color: #4b4b4b;
`;

export const ButtonProperty = styled(Button)`
  background: transparent !important;
  border-color: #4b4b4b !important;
  font-family: Asap !important;
  font-weight: normal !important;
  font-size: 14px !important;
  color: #4b4b4b !important;
  text-align: center !important;
  margin-top: 6px;

  &:first-of-type {
    margin-left: 15px;
    margin-right: 9px;
  }

  &.selected {
    background: #4b4b4b !important;
    border-color: none !important;
    color: #fff !important;
  }
`;

export const CustomDivider = styled(Divider)`
  margin: 10px 0 !important;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 10px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;

export const ProfitCenterInfo = styled(Row)`
  visibility: hidden;
  margin-top: 20px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #d8d8d8;
  padding: 10px 10px !important;

  &.visible {
    visibility: visible;
  }

  span {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;

    &.value {
      font-weight: bold;
    }
  }
`;

export const Footer = styled.div`
  border-top: 1px solid #e8e8e8;
  margin-top: 20px;
  width: 100%;
  padding: 10px 24px;
  border-radius: 0 0 4px 4px;

  .buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding-right: 24px; */

    button:first-of-type {
      margin-right: 12px !important;
    }
  }

  .ant-row-flex {
    margin: 0;
  }

  .selectDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: "Asap", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;

export const Table = styled.table`
  width: 100% !important;
  min-width: 100% !important;
  margin-top: 10px;
  tbody {
    tr {
      background: #f5f5f5;
      td {
        padding-top: 10px;
        padding-bottom: 10px;
        font-family: Asap;
        font-weight: normal;
        font-size: 12px;

        color: #4b4b4b;

        &:first-child {
          text-align: left;
          padding-left: 15px;
        }
        &:last-child {
          width: 120px;
          padding-right: 15px;
          text-align: right;
        }

        span {
          font-family: Asap;
          font-weight: bold;
          font-size: 12px;
          text-align: center;
          color: #a5a5a5;
          display: flex;
          align-items: center;
          &.success {
            color: #41c36f;
          }
          svg {
            margin-right: 5px;
          }
        }

        div.buttonGroup {
          display: flex;
          align-items: flex-end;
        }

        button {
          border: none;
          background: transparent;
          font-family: Asap;
          font-weight: normal;
          font-size: 12px;
          display: flex;

          &.add {
            align-items: center;
            color: #4a85ae;
            #bovexoPlusBlueIcon {
              margin-left: 5px;
            }

            &:disabled {
              cursor: not-allowed;
              color: #a5a5a5;
            }
          }

          &.edit {
            color: #9386aa;
            svg {
              margin-left: 5px;
              path {
                fill: #9386aa !important;
              }
            }
          }

          &:hover {
            cursor: pointer;
          }

          &.delete {
            color: #d44c4c;
            svg {
              margin-left: 0px;
              path {
                fill: #d44c4c !important;
              }
            }

            &:before {
              content: "|";
              color: #a5a5a5;
              margin-left: 10px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
`;
