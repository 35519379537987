import styled from "styled-components";
import { darken, lighten } from "polished";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  /* border: 2px solid green; */

  padding-bottom: 24px;

  button.bg-green {
    display: block;

    min-width: 242px;
    height: 40px;

    border: none;
    border-radius: 10px;

    background-color: #94cf1c;

    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    text-align: center;

    cursor: pointer;

    color: #ffffff;

    transition: background-color 0.2s ease;

    &:hover {
      background-color: ${lighten(0.2, "#94cf1c")};
    }
  }

  // Small devices (landscape phones, less than 768px)
  @media all and (max-width: 767.98px) {
    button.bg-green {
      margin: 0 auto;
    }

    .card-col {
      display: none;
    }
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    .card-col {
      display: none;
    }
  }
`;

export const InfoContainer = styled.div`
  padding-left: 24px;

  font-family: "Asap", sans-serif;
  font-style: normal;
  color: #591f74;
  h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 55px;

    color: #591f74;
  }
  p {
    font-size: 30px;
    line-height: 34px;
  }
  strong {
    font-weight: 600;
    font-size: 30px;
    line-height: 34px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-right: 24px; 
  padding-left: 24px;
  margin-bottom: 70px;

  .title {
    font-family: "Asap", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    /* identical to box height */

    color: #591f74;
    &.small {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .subTitle {
    font-family: "Asap", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #591f74;
  }

  .marginRow {
    margin-top: 10px;
  }

  label {
    display: block;

    font-family: "Asap", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    color: #9074bf;

    margin-left: 19px;
    margin-top: 8px;
    margin-bottom: 8px;

    &.error {
      color: #d44c4c;
    }
  }

  .ant-input {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 10px !important;

    &:hover,
    &:active,
    &:focus {
      background: transparent !important;
      box-shadow: none !important;
    }
  }

  .input {
    position: relative;
    background: transparent !important;
    border: 1px solid #8a50a5;
    box-shadow: none !important;
    border-radius: 10px !important;
    margin-bottom: 16px;
    width: 100% !important;
    transition: all 0.2s ease;

    &.small {
      margin-bottom: 0px;
      height: 32px !important;
    }

    &.hectarInput {
      padding-left: 10px;
      padding-right: 10px;

      &.disable {
        background-color: #f5f5f5 !important;
        color: rgba(0, 0, 0, 0.25);
      }
    }

    &.documentInput {
      padding-left: 10px;
      padding-right: 10px;
      border: 1px solid #8a50a5 !important;
    }

    div.icon {
      width: 50px;
      height: 50px;
      & > svg {
        width: 50px;
        height: 50px;
      }
    }

    &.no-margin-bottom {
      margin-bottom: 0px;
    }

    &.text-uppercase {
      input {
        text-transform: uppercase !important;
        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          text-transform: none !important;
          color: #591f74 !important;
        }

        &::placeholder {
          text-transform: none !important;
          color: #591f74 !important;
        }
      }
    }

    &.disable {
      cursor: not-allowed;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        z-index: 2;

        background-color: rgba(196, 196, 196, 0.2);
      }
    }

    &:hover,
    &:active,
    &:focus {
      background: transparent !important;
      box-shadow: none !important;
    }
  }

  input {
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #591f74 !important;
    }

    &::placeholder {
      color: #591f74 !important;
    }
  }

  .ant-row-flex {
    width: 100%;
    & > button {
      margin-top: 10px;
    }
  }

  form {
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

    button.resend-code {
      margin: 16px 0;

      border: none;
      background: transparent;

      cursor: pointer;

      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      text-decoration-line: underline;

      color: #8a50a5;
    }

    span.error {
      color: #c14933;
      margin-left: 19px;
    }

    .ant-select-selection {
      background: transparent !important;
      border: 1px solid #8a50a5 !important;
      box-shadow: none !important;
      border-radius: 10px !important;
    }

    .hide {
      display: none;
    }

    div.captcha {
      width: 100%;
      margin-top: 24px;
      display: flex;
      justify-content: center;
    }
  }

  .form-footer {
  }

  // X-Small devices (portrait phones, less than 576px)
  @media all and (max-width: 575.98px) {
    padding-left: 0;
  }

  // Small devices (landscape phones, less than 768px)
  @media all and (max-width: 767.98px) {
    padding-left: 0;
  }
`;

export const CustomRadioGroup = styled.ul`
  list-style: none;
  p {
    display: block;
    margin: 0;
    margin-left: 32px;
    margin-bottom: 10px;

    font-family: "Asap", sans-serif;
    font-style: normal;
    font-weight: normal;
  }
`;

export const CustomRadio = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;

  .radio-outer-circle {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    border: 2px solid #330c45;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
  }

  .radio-inner-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #591f74;
    transition: all 0.1s linear;
  }

  .unselected {
    border: 2px solid #666;
  }
  .unselected-circle {
    width: 0;
    height: 0;
  }

  label {
    margin-left: 16px;
    margin-right: 16px;

    font-family: "Asap", sans-serif;
    font-style: normal;
    font-weight: 600;
  }

  .option-icon {
    margin-left: 10px;
  }
`;

export const FormFooterContaier = styled.div`
  width: 100%;

  margin-top: 32px;
  margin-left: 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 242px;
    height: 40px;

    cursor: pointer;

    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;

    border: none;
    border-radius: 10px;

    transition: background-color 0.5s ease;

    &.next {
      margin-left: auto;
      background: #8a50a5;
      color: #ffffff;
    }

    &.previous {
      background: #ffffff;
      color: #8a50a5;
    }

    &:disabled {
      background: #c4c4c4;
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      &.next {
        background-color: ${lighten(0.2, "#8A50A5")};
      }

      &.previous {
        background-color: ${darken(0.2, "#FFFFFF")};
      }
    }
  }

  // Small devices (landscape phones, less than 768px)
  @media all and (max-width: 767.98px) {
    width: 100%;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      height: 48px;

      & + button {
        margin-top: 32px;
      }

      &.next {
        margin-left: 0;
      }
    }
  }
`;