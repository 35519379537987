import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Drawer, Row, Spin, Tabs, Timeline } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Creators as PicketActions } from "../../../../store/ducks/picket";

import TagStatus from "../../../../components/utils/tagStatus";
import LotHistory from "../../../utils/lotHistory";
import DrawerPicketDetailSustenanceCapacity from "./detailSustenanceCapacity";
import {
  Body,
  ButtonSustenanceCapacity,
  Container,
  CustomDivider,
} from "./styles";

import moment from "moment";

// Services
import { numberMask } from "../../../../services/helpersMethodsService";
import { getPastureShow } from "../../../../services/pastureService";
import { getProfitCenterShow } from "../../../../services/profitCenterService";
import { getSupplementShow } from "../../../../services/supplementService";
import { getSupplierShow } from "../../../../services/supplierService";
import { getTypologyItemIndex } from "../../../../services/typologyItemService";

const iconProperty = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.25"
    height="13.98"
    viewBox="0 0 18.25 13.98"
  >
    <g
      id="placeholder-on-map-paper-in-perspective"
      transform="translate(0.001 -66.585)"
    >
      <path
        id="Path_1096"
        data-name="Path 1096"
        d="M15.4,74.5l2.81,5.652a.269.269,0,0,1-.267.418H.31a.269.269,0,0,1-.267-.418L2.853,74.5a.326.326,0,0,1,.267-.163H5.529a.341.341,0,0,1,.223.1c.163.185.33.366.5.545s.316.342.473.518H3.862a.326.326,0,0,0-.267.163L1.734,79.4H16.514l-1.861-3.744a.326.326,0,0,0-.267-.163H11.52c.157-.176.316-.348.474-.518s.334-.36.5-.545a.34.34,0,0,1,.223-.1h2.415A.326.326,0,0,1,15.4,74.5Zm-2.14-3.862c0,3.088-2.626,3.667-3.861,6.635a.3.3,0,0,1-.547,0c-1.114-2.676-3.359-3.41-3.788-5.793a4.15,4.15,0,0,1,3.66-4.87A4.1,4.1,0,0,1,13.255,70.633Zm-1.951,0a2.184,2.184,0,1,0-2.183,2.138A2.161,2.161,0,0,0,11.3,70.633Z"
        transform="translate(0 0)"
        fill="#a9c133"
      />
    </g>
  </svg>
);

const DrawerDetailPicket = () => {
  const {
    app: {
      translation,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
    picket: {
      drawerDetailPicketVisible,
      picketData,
      idPicketDetailsAndEdit,
      isLoadingPicketData,
      picketLotHistories,
    },
  } = useSelector((state) => state);

  const [
    drawerPicketDetailSustenanceCapacityVisible,
    setDetailSustenanceCapacityVisible,
  ] = useState(null);
  const [profitCenterInfo, setProfitCenterInfo] = useState(null);
  const [supplierInfo, setSupplierInfo] = useState(null);
  const [supplementInfo, setSupplementInfo] = useState(null);
  const [pastureInfo, setPastureInfo] = useState(null);
  const [isLoadingProfitCenterInfo, setIsLoadingProfitCenterInfo] =
    useState(false);
  const [tabSelect, setTabSelect] = useState("1");
  const [maintenanceHistoryList, setMaintenanceHistoryList] = useState(null);

  const dispatch = useDispatch();

  /** Effect */
  useEffect(() => {
    const getInfoProfitCenter = async () => {
      setIsLoadingProfitCenterInfo(true);
      let {
        data: { results },
      } = await getProfitCenterShow({
        groupId,
        farmId,
        id: picketData?.profitCenterId,
      });
      const {
        data: { results: profitCenterTypes },
      } = await getTypologyItemIndex({
        groupId,
        typologyName: "Sistema Produtivo",
      });
      const { items } = profitCenterTypes[0];
      results = {
        ...results,
        typeName: items.find((t) => t.id === results.productionSystemId).name,
      };
      setProfitCenterInfo(results);
      setIsLoadingProfitCenterInfo(false);
    };
    const getInfoSupplier = async () => {
      if (picketData?.supplierId != null) {
        try {
          const {
            data: { results },
          } = await getSupplierShow({
            groupId,
            farmId,
            id: picketData?.supplierId,
          });
          setSupplierInfo(results);
        } catch (error) {}
      }
    };
    const getInfoSupplement = async () => {
      if (picketData?.supplementId != null) {
        try {
          const {
            data: { results },
          } = await getSupplementShow({
            groupId,
            farmId,
            id: picketData?.supplementId,
          });
          setSupplementInfo(results);
        } catch (error) {}
      }
    };
    const getInfoPasture = async () => {
      if (picketData?.pastureId != null) {
        try {
          const {
            data: { results },
          } = await getPastureShow({
            groupId,
            farmId,
            id: picketData?.pastureId,
          });
          setPastureInfo(results);
        } catch (error) {}
      }
    };
    if (picketData != null) {
      Promise.all([
        getInfoProfitCenter(),
        getInfoSupplier(),
        getInfoSupplement(),
        getInfoPasture(),
      ]);

      if (
        picketData?.statusHistory &&
        Array.isArray(picketData.statusHistory)
      ) {
        setMaintenanceHistoryList(
          picketData.statusHistory.filter(
            (sh) => sh.status === "Maintenance" || sh.status === "Renovation"
          )
        );
      }
    }
    return () => {
      setProfitCenterInfo(null);
      setPastureInfo(null);
      setSupplementInfo(null);
      setSupplierInfo(null);
    };
  }, [groupId, farmId, picketData]);

  const closeDrawer = () => {
    setTabSelect("1");
    dispatch(PicketActions.hideDrawerDetailPicket());
  };

  const handleDrawerVisible = async (visible) => {
    if (visible) {
      if (idPicketDetailsAndEdit != null) {
        dispatch(
          PicketActions.showPicket(groupId, farmId, idPicketDetailsAndEdit)
        );
      }
      setDetailSustenanceCapacityVisible(false);
    }
  };

  const handleShowSustenanceCapacityDetailDrawer = async () => {
    picketData.supplementName =
      supplementInfo != null ? supplementInfo.name : null;
    picketData.pastureName = pastureInfo != null ? pastureInfo.name : null;
    picketData.pastureType =
      pastureInfo != null
        ? pastureInfo.pastureType != null
          ? pastureInfo.pastureType.name
          : null
        : null;
    setDetailSustenanceCapacityVisible(true);
  };

  const handleCloseSustenanceCapacityDetailDrawer = async () => {
    setDetailSustenanceCapacityVisible(false);
  };

  function handleDrawerTittle() {
    if (picketData?.productionSubSystem === "I") {
      return "Detalhes da baia";
    } else {
      return "Detalhes do piquete";
    }
  }

  const handleTabClick = (key, e) => {
    setTabSelect(key);
  };

  const renderTimelineItem = (statusHistory) => {
    const statusName =
      translation.picket.status[statusHistory.status.toLowerCase()];
    const date = moment(statusHistory.dateInUser).format("DD/MM/YYYY");
    return (
      <Timeline.Item key={statusHistory.id}>
        <span className="timeline-title">
          {date} - {statusName}
        </span>
        <span className="timeline-detail">{statusHistory?.observation}</span>
      </Timeline.Item>
    );
  };

  const renderTimelineObservation = (picketComment) => {
    const comment = picketComment.comment;
    const date = moment(picketComment.commentDate).format("DD/MM/YYYY");
    return (
      <Timeline.Item key={picketComment.id}>
        <span className="timeline-title">{date}</span>
        <span className="timeline-detail">{comment}</span>
      </Timeline.Item>
    );
  };

  return (
    <Drawer
      title={handleDrawerTittle()}
      width={770}
      onClose={closeDrawer}
      maskClosable={false}
      visible={drawerDetailPicketVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Spin spinning={isLoadingPicketData}>
        <Body>
          <Tabs
            type="card"
            defaultActiveKey="1"
            activeKey={tabSelect}
            onTabClick={handleTabClick}
          >
            <Tabs.TabPane
              tab={picketData?.productionSubSystem === "I" ? "Baia" : "Piquete"}
              key="1"
            >
              <Container>
                <Row type="flex">
                  <Col>
                    <label className="grey">
                      {translation.picket.detail.name}
                    </label>
                  </Col>
                </Row>
                <Row type="flex" justify="space-between">
                  <Col>
                    <label className="name">{picketData?.name}</label>
                  </Col>
                  <Col>
                    <label className="propertyType">
                      {iconProperty}
                      {picketData?.propertyType === "O"
                        ? translation.picket.propertyType.own
                        : translation.picket.propertyType.foreign}
                    </label>
                  </Col>
                </Row>
                <Row type="flex">
                  {picketData?.status === "B" ? (
                    <TagStatus
                      background="#E4F4FF"
                      borderColor="#4A85AE"
                      color="#4A85AE"
                    >
                      {translation.picket.status.break}
                    </TagStatus>
                  ) : picketData?.status === "P" ? (
                    <TagStatus
                      background="#E1FFE4"
                      borderColor="#106518"
                      color="#106518"
                    >
                      {picketData.productionSubSystem === "I"
                        ? translation.picket.status.active
                        : translation.picket.status.pasturage}
                    </TagStatus>
                  ) : picketData?.status === "R" ? (
                    <TagStatus
                      background="#FFEAE1"
                      borderColor="#FF4E00"
                      color="#FF4E00"
                    >
                      {translation.picket.status.renovation}
                    </TagStatus>
                  ) : picketData?.status === "M" ? (
                    <TagStatus
                      background="#FCEEEC"
                      borderColor="#D44C4C"
                      color="#D44C4C"
                    >
                      {translation.picket.status.maintenance}
                    </TagStatus>
                  ) : picketData?.status === "L" ? (
                    <TagStatus background="#FE8D2A">
                      {translation.picket.status.leased}
                    </TagStatus>
                  ) : (
                    <TagStatus
                      background="#FBC7C7"
                      borderColor="#D44C4C"
                      color="#D44C4C"
                    >
                      {translation.picket.status.deleted}
                    </TagStatus>
                  )}
                </Row>
                <Row type="flex">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Card>
                      {profitCenterInfo == null && isLoadingProfitCenterInfo ? (
                        <Row type="flex" justify="center">
                          <Spin />
                        </Row>
                      ) : (
                        profitCenterInfo != null && (
                          <Fragment>
                            <Row type="flex" justify="space-between">
                              <Col>
                                <label>
                                  <strong>{`${translation.picket.detail.profitCenterId}:`}</strong>
                                </label>
                              </Col>
                              <Col>
                                <label>
                                  <strong>{profitCenterInfo.name}</strong>
                                </label>
                              </Col>
                            </Row>
                            <Row type="flex" justify="space-between">
                              <Col>
                                <label>{`${translation.picket.detail.productionSubSystem}:`}</label>
                              </Col>
                              <Col>
                                <label>
                                  <i>
                                    {profitCenterInfo.productionSubSystem ===
                                    "Extensive"
                                      ? translation.productionSubSystem
                                          .extensive
                                      : profitCenterInfo.productionSubSystem ===
                                        "Intensive"
                                      ? translation.productionSubSystem
                                          .intensive
                                      : profitCenterInfo.productionSubSystem ===
                                        "SemiIntensive"
                                      ? translation.productionSubSystem
                                          .semiIntensive
                                      : ""}
                                  </i>
                                </label>
                              </Col>
                            </Row>
                            <Row type="flex" justify="space-between">
                              <Col>
                                <label>{`${translation.picket.detail.profitCenterType}: `}</label>
                              </Col>
                              <Col>
                                <label>
                                  <i>{profitCenterInfo.typeName}</i>
                                </label>
                              </Col>
                            </Row>
                          </Fragment>
                        )
                      )}
                    </Card>
                  </Col>
                </Row>
                {(picketData?.propertyType === "F" ||
                  picketData?.propertyType === "Foreign") && (
                  <Fragment>
                    <CustomDivider dashed>
                      {translation.picket.detail.supplierDivider}
                    </CustomDivider>
                    <Row type="flex" justify="space-between">
                      <Col>
                        <label className="grey">
                          {translation.picket.detail.supplierId}
                        </label>
                      </Col>
                      <Col>
                        <label className="grey">
                          {translation.picket.detail.leaseCost}
                        </label>
                      </Col>
                    </Row>
                    <Spin spinning={supplierInfo == null}>
                      <Row type="flex" justify="space-between">
                        <Col>
                          <label>
                            {supplierInfo != null && supplierInfo.name}
                          </label>
                        </Col>
                        <Col>
                          <label>{`${numberMask(
                            picketData?.leaseMonthlyCost || 0,
                            true
                          )} /mês`}</label>
                        </Col>
                      </Row>
                    </Spin>
                  </Fragment>
                )}
                <CustomDivider dashed>
                  {translation.picket.detail.sustenanceCapacityDivider}
                </CustomDivider>
                <Card>
                  {picketData?.productionSubSystem !== "Intensive" &&
                    picketData?.productionSubSystem !== "I" && (
                      <Row
                        type="flex"
                        justify="space-between"
                        style={{ marginBottom: "13px" }}
                      >
                        <Col>
                          <label className="nameElement">
                            {translation.picket.detail.pasture}
                          </label>
                        </Col>
                        <Col>
                          <label className="value">
                            {pastureInfo != null && pastureInfo.name}
                          </label>
                        </Col>
                      </Row>
                    )}
                  {/* PastureAnimalCapacity */}
                  <Row
                    type="flex"
                    justify="space-between"
                    style={{ marginBottom: "13px" }}
                  >
                    <Col>
                      <label>
                        {translation.picket.detail.pastureAnimalCapacity}
                      </label>
                    </Col>
                    <Col>
                      <label className="valueBold">{`${numberMask(
                        picketData?.pastureAnimalCapacity || 0,
                        false
                      )} ${
                        picketData?.productionSubSystem !== "Intensive" &&
                        picketData?.productionSubSystem !== "I"
                          ? "UA/ha"
                          : "CAB"
                      }`}</label>
                    </Col>
                  </Row>
                  {/* PastureDailyCost */}
                  {picketData?.productionSubSystem !== "Intensive" &&
                    picketData?.productionSubSystem !== "I" && (
                      <Row
                        type="flex"
                        justify="space-between"
                        style={{ marginBottom: "13px" }}
                      >
                        <Col>
                          <label>
                            {translation.picket.detail.pastureDailyCost}
                          </label>
                        </Col>
                        <Col>
                          <label className="valueBold">{`${numberMask(
                            picketData?.pastureDailyCost || 0,
                            true
                          )} kg/animal`}</label>
                        </Col>
                      </Row>
                    )}
                  {/* Button */}
                  <Row type="flex" style={{ marginTop: 23 }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} align="right">
                      <ButtonSustenanceCapacity
                        type="button"
                        onClick={handleShowSustenanceCapacityDetailDrawer}
                      >
                        {translation.picket.detail.moreInformationButton}
                      </ButtonSustenanceCapacity>
                    </Col>
                  </Row>
                </Card>
                <CustomDivider dashed>
                  {translation.picket.detail.infrastructureDivider}
                </CustomDivider>
                <Row
                  type="flex"
                  justify="space-between"
                  style={{ marginBottom: "13px" }}
                >
                  <Col>
                    <label>{translation.picket.detail.drinkingFountain}</label>
                  </Col>
                  <Col>
                    <label className="valueBold">{`${numberMask(
                      picketData?.drinkingFountain / 100 || 0,
                      false
                    )} m`}</label>
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="space-between"
                  style={{ marginBottom: "13px" }}
                >
                  <Col>
                    <label>{translation.picket.detail.totalTroughSpace}</label>
                  </Col>
                  <Col>
                    <label className="valueBold">{`${numberMask(
                      picketData?.totalTroughSpace / 100 || 0,
                      false
                    )} m`}</label>
                  </Col>
                </Row>
                {picketData != null && (
                  <LotHistory lotHistories={picketLotHistories || []} />
                )}
              </Container>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Histórico" key="2">
              <Container>
                <Timeline>
                  {maintenanceHistoryList &&
                  Array.isArray(maintenanceHistoryList) &&
                  maintenanceHistoryList.length > 0
                    ? maintenanceHistoryList.map((item) =>
                        renderTimelineItem(item)
                      )
                    : null}
                </Timeline>
              </Container>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Observações" key="3">
              <Container>
                <Timeline>
                  {picketData?.comments.length > 0
                    ? picketData?.comments.map((item) =>
                        renderTimelineObservation(item)
                      )
                    : null}
                </Timeline>
              </Container>
            </Tabs.TabPane>
          </Tabs>
        </Body>
      </Spin>
      <DrawerPicketDetailSustenanceCapacity
        drawerVisible={drawerPicketDetailSustenanceCapacityVisible}
        data={picketData}
        onClose={handleCloseSustenanceCapacityDetailDrawer}
      />
    </Drawer>
  );
};

export default DrawerDetailPicket;
