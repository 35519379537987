import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Creators as UserActions } from "../../store/ducks/user";
// Components
import {
  Input,
  Button,
  Icon,
  Menu,
  Row,
  Col,
  Table,
  Dropdown,
  notification,
  Tag,
} from "antd";
import { Container, PageTitle } from "./styles";
import TagStatus from "../../components/utils/tagStatus";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import MenuIcon from "../../components/utils/table/icons/menu";
import ButtonStandard from "../../components/utils/button";
import ConfirmActionModal from "../../components/modals/confirmActionModal";
import DrawerInviteUser from "../../components/drawers/inviteUser";
import DrawerUserDetails from "../../components/drawers/user/details";
// Services
import {
  pageAllUsersByFarmIdAndGroupId,
  getUserStatusWithoutAsync,
  inactivateUser,
  activateUser,
  getRolesForUserInEspecificGroupAndFarm,
} from "../../services/userService";
import AllowedComponentTo from "../../components/utils/allowedComponentTo";

const Users = () => {
  // Variable
  const [data, setData] = useState(null);
  const [selectData, setSelectData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modalInactivateVisible, setModalInactivateVisible] = useState(false);
  const [modalActivateVisible, setModalActivateVisible] = useState(false);

  // Variable Redux
  const {
    translation,
    user: { id: userId },
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  // Effect
  useEffect(() => {
    async function fetchData(page = 0, sorter = {}, filters = "", size = 10) {
      setIsLoading(true);
      try {
        if (groupId != null && farmId != null) {
          const {
            data: { results },
          } = await pageAllUsersByFarmIdAndGroupId({
            groupId,
            farmId,
            page,
            sorter,
            filters,
            size,
          });

          let ndata = results;
          let { content } = ndata;
          let newContent = await Promise.all(
            content.map(async (c) => {
              try {
                const {
                  data: { results: status },
                } = await getUserStatusWithoutAsync({
                  groupId,
                  farmId,
                  userId: c.id,
                });
                const {
                  data: { results: aRoles },
                } = await getRolesForUserInEspecificGroupAndFarm({
                  groupId,
                  farmId,
                  userId: c.id,
                });
                c.status = status;
                c.roles = aRoles.map((r) => r.name);
              } catch (error) {}
              return c;
            })
          );
          ndata.content = newContent;

          setData(ndata);
        }
        setIsLoading(false);
      } catch (error) {
        if (groupId != null && farmId != null)
          notification.error({
            message: "Não foi possível retornar os usuários",
          });
        setData(null);
        setIsLoading(false);
      }
    }
    fetchData();
  }, [groupId, farmId]);

  // Methods
  async function fetchData(page = 0, sorter = {}, filters = "", size = 10) {
    setIsLoading(true);
    try {
      if (groupId != null && farmId != null) {
        const {
          data: { results },
        } = await pageAllUsersByFarmIdAndGroupId({
          groupId,
          farmId,
          page,
          sorter,
          filters,
          size,
        });
        let ndata = results;
        let { content } = ndata;
        let newContent = await Promise.all(
          content.map(async (c) => {
            try {
              const {
                data: { results: status },
              } = await getUserStatusWithoutAsync({
                groupId,
                farmId,
                userId: c.id,
              });
              const {
                data: { results: aRoles },
              } = await getRolesForUserInEspecificGroupAndFarm({
                groupId,
                farmId,
                userId: c.id,
              });
              c.status = status;
              c.roles = aRoles.map((r) => r.name);
            } catch (error) {}
            return c;
          })
        );
        ndata.content = newContent;
        setData(ndata);
      }
      setIsLoading(false);
    } catch (error) {
      if (groupId != null && farmId != null)
        notification.error({
          message: "Não foi possível retornar os usuários",
        });
      setIsLoading(false);
      setData(null);
    }
  }

  function menu(id, status = null, record = null) {
    return (
      <Menu>
        <Menu.Item key="0">
          <button onClick={() => handleDetails(record)}>
            {translation.table.menu.details}
          </button>
        </Menu.Item>
        {id !== userId &&
          !record.roles.includes("Admin") &&
          !record.roles.includes("Admin Group") && <Menu.Divider />}
        {id !== userId &&
          status === "Active" &&
          !record.roles.includes("Admin") &&
          !record.roles.includes("Admin Group") && (
            <Menu.Item key="2">
              <button onClick={() => handleEdit(id, "inactivate")}>
                {translation.table.menu.inactivate}
              </button>
            </Menu.Item>
          )}
        {id !== userId &&
          status === "Inactive" &&
          !record.roles.includes("Admin") &&
          !record.roles.includes("Admin Group") && (
            <Menu.Item key="2">
              <button onClick={() => handleEdit(id, "activate")}>
                {translation.table.menu.activate}
              </button>
            </Menu.Item>
          )}
      </Menu>
    );
  }

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
    };
  }

  function handleSearch(selectedKeys, confirm) {
    confirm();
  }

  function handleReset(clearFilters) {
    clearFilters();
  }

  async function handleTableChange(pagination, filters, sorter) {
    let search = "";
    if (Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v.length > 0) {
          if (k === "status") {
            let statusString = "";
            for (let i = 0; i < v.length; i++) {
              statusString += i === v.length - 1 ? `'${v[i]}'` : `'${v[i]}',`;
            }
            search = `${
              search !== "" ? `${search};` : ``
            }u.${k} IN (${statusString})`;
          } else if (k === "closingDate") {
            search = `${search !== "" ? `${search};` : ``}${k} between '${
              v[0]
            }' and '${v[1]}'`;
          } else {
            search = `${
              search !== "" ? `${search};` : ``
            }upper(u.${k}) like upper('%25${v}%25')`;
          }
        }
      });
    }
    fetchData(pagination.current, sorter, search);
  }

  function handleInviteUser() {
    dispatch(UserActions.showOrHideInviteUserDrawer());
  }

  function handleEdit(id, action = "edit") {
    setSelectData(id);
    switch (action) {
      case "activate":
        setModalActivateVisible(true);
        break;
      case "inactivate":
        setModalInactivateVisible(true);
        break;

      default:
        break;
    }
  }

  function handleDetails(record) {
    dispatch(UserActions.showOrHideDetaisUserDrawer(record));
  }

  async function handleInactivate() {
    try {
      await inactivateUser({
        groupId,
        farmId,
        userId: selectData,
      });
      setModalInactivateVisible(false);
      fetchData();
    } catch (error) {}
  }

  async function handleActivate() {
    try {
      await activateUser({
        groupId,
        farmId,
        userId: selectData,
      });
      setModalActivateVisible(false);
      fetchData();
    } catch (error) {}
  }

  return (
    <Container>
      <Row type="flex" className="rowHeader" align="middle">
        <Col span={8}>
          <PageTitle>{translation.sidebar.adminAndUser}</PageTitle>
        </Col>
        <Col span={16} align="right">
          <ButtonStandard
            buttonType="typeWithoutBackground"
            onClick={() => fetchData()}
          >
            <Icon type="reload" />
          </ButtonStandard>
          <AllowedComponentTo
            roles={["Admin Group", "Admin", "Admin Farm"]}
            hide
          >
            <ButtonStandard buttonType="type8" onClick={handleInviteUser}>
              Convidar
            </ButtonStandard>
          </AllowedComponentTo>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <Table
            loading={isLoading}
            rowKey="id"
            dataSource={data != null ? data.content : []}
            pagination={{
              total: data != null ? data.totalElements : 0,
              size: data != null ? data.size : 0,
              current: data != null ? data.number + 1 : 0,
              hideOnSinglePage:
                data !== null && Object.entries(data).length !== 0
                  ? data.totalElements > 10
                    ? false
                    : true
                  : true,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
            }}
            size="small"
            scroll={{
              x: true,
            }}
            onChange={handleTableChange}
          >
            <Table.Column
              title={translation.farm.table.columns.status}
              dataIndex="status"
              key="status"
              align="left"
              render={(status) =>
                status === "Active" ? (
                  <TagStatus
                    background="#C5F1CA"
                    borderColor="#106518"
                    color="#106518"
                  >
                    {translation.status.active}
                  </TagStatus>
                ) : (
                  <TagStatus
                    background="#FBC7C7"
                    borderColor="#D44C4C"
                    color="#D44C4C"
                  >
                    {translation.status.inactive}
                  </TagStatus>
                )
              }
            />
            <Table.Column
              title="Nome completo"
              dataIndex="fullname"
              key="fullname"
              align="left"
              sorter
              {...getColumnSearchProps("fullname")}
            />
            <Table.Column
              title="E-mail"
              dataIndex="email"
              key="email"
              align="left"
              sorter
              {...getColumnSearchProps("email")}
            />
            <Table.Column
              title="Perfil(is)"
              dataIndex="roles"
              key="roles"
              align="left"
              render={(text, record) =>
                record.roles.map((r, i) => (
                  <Tag key={i}>
                    {r === "Admin"
                      ? "Administrador"
                      : r === "Admin Group"
                      ? "Admin. de Grupo"
                      : r === "Admin Farmer"
                      ? "Admin. de Fazenda"
                      : r === "Technician"
                      ? "Técnico"
                      : r === "Admin Profit Center"
                      ? "Admin. de Atividade"
                      : null}
                  </Tag>
                ))
              }
            />
            <Table.Column
              align="left"
              render={(text, record) => (
                <Dropdown
                  overlay={menu(record.id, record.status, record)}
                  trigger={["click"]}
                  key={record.id}
                >
                  <MenuContainer>
                    <MenuIcon />
                  </MenuContainer>
                </Dropdown>
              )}
            />
          </Table>
        </Col>
      </Row>
      {/* Modals */}
      <ConfirmActionModal
        show={modalInactivateVisible}
        title="Inativar usuário"
        message="Você realmente deseja inativar este usuário ?"
        cancelAction={() => setModalInactivateVisible(false)}
        confirmAction={handleInactivate}
      />
      <ConfirmActionModal
        show={modalActivateVisible}
        title="Ativar usuário"
        message="Você realmente deseja ativar este usuário ?"
        cancelAction={() => setModalActivateVisible(false)}
        confirmAction={handleActivate}
      />
      {/* Drawers */}
      <DrawerInviteUser callBackMethod={fetchData} />
      <DrawerUserDetails />
    </Container>
  );
};

export default withRouter(Users);
