import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CardCustom, Title } from "./styles";
import { Col, Icon, Input, Row, Table, Dropdown, Menu, Button, notification } from "antd";

import { Creators as RetreatActions } from "../../../store/ducks/retreat";

import DrawerRetreat from "../../../components/drawers/retreat";
import { Container as MenuContainer } from "../../../components/utils/table/menu/styles";
import ButtonStandard from "../../../components/utils/button/index"
import MenuIcon from "../../../components/utils/table/icons/menu";
import TagStatus from "../../../components/utils/tagStatus";

import { deleteById } from "../../../services/retreatService";

const Retreat = () => {

  const {
    retreat: { isLoadingTable, data },
    app: {
      translation,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },

  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { Column } = Table;

  useEffect(() => {
    if (groupId && farmId) {
      dispatch(RetreatActions.index(groupId, farmId));
    }
  }, [groupId, farmId]);

  const handleNew = () => {
    dispatch(RetreatActions.showOrHideDrawer());
  }

  const handleEdit = (id) => {
    dispatch(RetreatActions.showOrHideDrawer(id));
  }

  const handleDelete = async (id) => {
    try {
      dispatch(RetreatActions.setIsLoadingTable(true));
      await deleteById({
        groupId,
        farmId,
        id,
      });

      notification.success({ message: "Retiro excluído com sucesso" });
      dispatch(RetreatActions.index(groupId, farmId));
    } catch (error) {
      dispatch(RetreatActions.setIsLoadingTable(false));
      notification.error({
        title: "Erro ao excluir",
        message: "Não foi possível excluir o Retiro. Contate o suporte"
      });
    } finally {
      dispatch(RetreatActions.index(groupId, farmId));
    }
  }

  const handleInactivateOrActivate = (record, mode) => {
    if (mode === "activate") {
      dispatch(RetreatActions.activateRetreat(groupId, farmId, record.id));
    } else {
      dispatch(RetreatActions.inactivateRetreat(groupId, farmId, record.id));
    }
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => confirm();

  const handleReset = (clearFilters) => clearFilters();

  const handleGetColumnSearchProps =
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return record[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    });


  function renderMenu(record) {
    if (record.status === "Inactive") {
      return (
        <Menu>
          <Menu.Item key="1">
            <button
              onClick={() => handleInactivateOrActivate(record, "activate")}
            >
              {translation.table.menu.activate}
            </button>
          </Menu.Item>
          <Menu.Item key="2">
            <button onClick={() => handleDelete(record.id)}>
              {translation.table.menu.delete}
            </button>
          </Menu.Item>
        </Menu>);
    } else {
      return (
        <Menu>
          <Menu.Item key="0">
            <button onClick={() => handleEdit(record.id)}>
              {translation.table.menu.edit}
            </button>
          </Menu.Item>

          {record.status === "Active" && (record.pickets === null || record?.pickets?.length === 0) ? (
            <Menu.Item key="3">
              <button
                onClick={() => handleInactivateOrActivate(record, "inactivate")}
              >
                {translation.table.menu.inactivate}
              </button>
            </Menu.Item>
          ) : null}
        </Menu>);
    }
  }

  function expandedRows(record, index, indent, expanded) {
    const columns = [
      {
        title: translation.retreat.table.expandedColumns.picketName,
        dataIndex: "name",
        key: "name",
        sorter: (first, second) => {
          return first.name.localeCompare(second.name, "pt-BR", {
            numeric: false,
            ignorePunctuation: true,
          });
        },
        ...handleGetColumnSearchProps("name")
      },
      {
        title: translation.retreat.table.expandedColumns.area,
        dataIndex: "pastureArea",
        key: "pastureArea",
        render: (area, picket) => {
          if (picket.productionSubSystem === "I") {
            return (<span>{area * 10000} m²</span>);
          }
          else {
            return (<span>{area} ha</span>);
          }
        },
        sorter: (first, second) => first.pastureArea - second.pastureArea,
      },
      {
        title: translation.retreat.table.expandedColumns.activity,
        dataIndex: "profitCenterName",
        key: "profitCenterName",
        sorter: (first, second) => {
          return first.profitCenterName.localeCompare(second.profitCenterName, "pt-BR", {
            numeric: false,
            ignorePunctuation: true,
          });
        },
      },
      {
        title: translation.retreat.table.expandedColumns.status,
        dataIndex: "status",
        key: "status",
        render: (picketStatus, picket) => {
          return picketStatus === "Break" ? (
            <TagStatus
              background="#E4F4FF"
              borderColor="#4A85AE"
              color="#4A85AE"
            >
              {translation.picket.status.break}
            </TagStatus>
          ) : picketStatus === "Pasturage" ? (
            <TagStatus
              background="#E1FFE4"
              borderColor="#106518"
              color="#106518"
            >
              {picket.productionSubSystem === "I" ? translation.picket.status.active : translation.picket.status.pasturage}
            </TagStatus>
          ) : picketStatus === "Renovation" ? (
            <TagStatus
              background="#FFEAE1"
              borderColor="#FF4E00"
              color="#FF4E00"
            >
              {translation.picket.status.renovation}
            </TagStatus>
          ) : picketStatus === "Maintenance" ? (
            <TagStatus
              background="#FCEEEC"
              borderColor="#D44C4C"
              color="#D44C4C"
            >
              {translation.picket.status.maintenance}
            </TagStatus>
          ) : picketStatus === "Leased" ? (
            <TagStatus background="#FE8D2A">
              {translation.picket.status.leased}
            </TagStatus>
          ) : picketStatus === "FarmExclude" ? (
            <TagStatus
              background="#FBC7C7"
              borderColor="#D44C4C"
              color="#D44C4C"
            >
              {translation.status.inactive}
            </TagStatus>
          ) : picketStatus === "Inactive" ? (
            <TagStatus
              background="#FBC7C7"
              borderColor="#D44C4C"
              color="#D44C4C"
            >
              {translation.status.inactive}
            </TagStatus>
          ) : (
            <TagStatus
              background="#FBC7C7"
              borderColor="#D44C4C"
              color="#D44C4C"
            >
              {translation.picket.status.deleted}
            </TagStatus>
          )
        },
        sorter: (first, second) => {
          return first.status.localeCompare(second.status, "pt-BR", {
            numeric: false,
            ignorePunctuation: true,
          });
        },
      },
      {
        title: translation.retreat.table.expandedColumns.lot,
        dataIndex: "lotName",
        key: "lotName",
        sorter: (first, second) => {
          if (first?.lotName === null || first?.lotName === undefined) {
            return 1;
          }

          return first?.lotName?.localeCompare(second?.lotName, "pt-BR", {
            numeric: false,
            ignorePunctuation: true,
          });
        },
      },
    ];

    return (
      <Table rowKey="id" columns={columns} dataSource={record.pickets} pagination={false} />
    );
  }

  return (
    <CardCustom>
      <Row type="flex" justify="space-between" style={{ marginBottom: "19px" }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Title>
            {translation.retreat.title}
          </Title>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} align="right">
          <Row>
            <ButtonStandard
              buttonType="typeWithoutBackground"
              onClick={() => dispatch(RetreatActions.index(groupId, farmId))}
            >
              <Icon type="reload" />
            </ButtonStandard>
            <ButtonStandard buttonType="type8" onClick={handleNew}>
              {translation.retreat.buttonNew}
            </ButtonStandard>
          </Row>

        </Col>
      </Row>

      <Row type="flex">
        <Table
          loading={isLoadingTable}
          rowKey="id"
          size="small"
          dataSource={data !== null ? data : []}
          scroll={{
            x: true,
          }}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 10,
            pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
          }}
          style={{ width: "100%", margin: "0" }}
          expandedRowRender={expandedRows}
        >
          <Column
            title={
              translation.retreat.table.columns.status
            }
            dataIndex="status"
            key="status"
            align="left"
            sorter={(first, second) => {
              return first.status.localeCompare(second.status, "pt-BR", {
                numeric: false,
                ignorePunctuation: true,
              });
            }}
            filtered
            filters={[
              {
                text: translation.status.active,
                value: "Active",
              },
              {
                text: translation.status.inactive,
                value: "Inactive",
              },
            ]}
            filterMultiple
            onFilter={(value, record) => record.status.includes(value)}
            render={(status) =>
              status === "Active" ? (
                <TagStatus
                  background="#C5F1CA"
                  borderColor="#106518"
                  color="#106518"
                >
                  {translation.status.active}
                </TagStatus>
              ) : (
                <TagStatus
                  background="#FBC7C7"
                  borderColor="#D44C4C"
                  color="#D44C4C"
                >
                  {translation.status.inactive}
                </TagStatus>
              )
            }
          />
          <Column
            title={
              translation.retreat.table.columns.retreatName
            }
            dataIndex="name"
            key="name"
            align="left"
            sorter={(first, second) => {
              return first.name.localeCompare(second.name, "pt-BR", {
                numeric: false,
                ignorePunctuation: true,
              });
            }}
            {...handleGetColumnSearchProps("name")}
          />
          <Column
            title={
              translation.retreat.table.columns.area
            }
            dataIndex="totalArea"
            key="totalArea"
            align="left"

            sorter={(first, second) => first.totalArea - second.totalArea}
          />
          <Column
            align="left"
            width={50}
            render={(text, record) => (
              <Dropdown
                overlay={renderMenu(record)}
                trigger={["click"]}
                key={record.id}
              >
                <MenuContainer>
                  <MenuIcon />
                </MenuContainer>
              </Dropdown>
            )}
          />
        </Table>
      </Row>
      <DrawerRetreat />
    </CardCustom>
  );
}

export default Retreat;