import React from "react";

// import { Container } from './styles';

function ExportPdfIcon() {
  return (
    <svg
      id="Group_4865"
      data-name="Group 4865"
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="55"
      viewBox="0 0 55 55"
    >
      <rect
        id="Rectangle_1763"
        data-name="Rectangle 1763"
        width="55"
        height="55"
        rx="5"
        fill="#eb7777"
      />
      <g
        id="Group_4864"
        data-name="Group 4864"
        transform="translate(11.999 12)"
      >
        <g id="Group_4863" data-name="Group 4863">
          <g id="Group_4345" data-name="Group 4345">
            <g id="Group_4863-2" data-name="Group 4863">
              <g id="pdf">
                <path
                  id="Path_2342"
                  data-name="Path 2342"
                  d="M97.972,0A1.978,1.978,0,0,0,96,1.972V29.586a1.978,1.978,0,0,0,1.972,1.972H117.7a1.978,1.978,0,0,0,1.972-1.972V7.89L111.779,0Z"
                  transform="translate(-89.341)"
                  fill="#e2e5e7"
                />
                <path
                  id="Path_2343"
                  data-name="Path 2343"
                  d="M353.972,7.89h5.917L352,0V5.917A1.978,1.978,0,0,0,353.972,7.89Z"
                  transform="translate(-329.561)"
                  fill="#b0b7bd"
                />
                <path
                  id="Path_2344"
                  data-name="Path 2344"
                  d="M389.917,133.917,384,128h5.917Z"
                  transform="translate(-359.589 -120.11)"
                  fill="#cad1d8"
                />
                <path
                  id="Path_2345"
                  data-name="Path 2345"
                  d="M58.383,254.412a1.222,1.222,0,0,1-1.1,1.31H33.1a1.222,1.222,0,0,1-1.1-1.31v-13.1A1.222,1.222,0,0,1,33.1,240H57.284a1.222,1.222,0,0,1,1.1,1.31Z"
                  transform="translate(-32 -228.109)"
                  fill="#f15642"
                />
                <path
                  id="Path_2349"
                  data-name="Path 2349"
                  d="M114.738,416.986H96v.986h18.738a.989.989,0,0,0,.986-.986V416A.989.989,0,0,1,114.738,416.986Z"
                  transform="translate(-89.341 -389.359)"
                  fill="#cad1d8"
                />
              </g>
            </g>
          </g>
        </g>
        <path
          id="Path_2342-2"
          data-name="Path 2342"
          d="M4.147-9.1H1.417c-.364,0-.52.156-.52.52V-.39c0,.273.234.416.78.416h.442c.546,0,.78-.143.78-.416V-3.406H4.277A2.8,2.8,0,0,0,7.241-6.253C7.241-8.762,5.3-9.1,4.147-9.1ZM4.056-7.4A1.032,1.032,0,0,1,5.187-6.253,1.022,1.022,0,0,1,4.056-5.122H2.9V-7.4Zm7.683-1.7H8.827c-.364,0-.52.156-.52.52V-.52c0,.364.156.52.52.52h2.912c2.678,0,4.16-1.612,4.16-4.55S14.417-9.1,11.739-9.1Zm0,1.7c1.391,0,2.106,1,2.106,2.847S13.13-1.7,11.739-1.7H10.322V-7.4ZM22.2-9.1H17.862c-.364,0-.52.156-.52.52V-.39c0,.273.234.416.78.416h.442c.546,0,.78-.143.78-.416V-3.8h2.769c.273,0,.39-.195.39-.65v-.4c0-.455-.117-.65-.39-.65H19.344V-7.4H22.2c.273,0,.39-.2.39-.65v-.4C22.594-8.905,22.477-9.1,22.2-9.1Z"
          transform="translate(1.714 24.251)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default ExportPdfIcon;
