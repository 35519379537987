import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// Components
import { Col, Row, Tabs } from "antd";

import SettingsIcon from "../../components/admin/sidebar/icons/settings";
import { Container, PageTitle } from "./styles";

// Pages
import AccessInvitations from "../accessInvitations";
import Client from "../client";
import Farm from "../farm";
import Supplier from "../supplier";
import SupplierManagement from "../supplierManagement";
import UserTrigger from "../userTrigger";
import Users from "../users";

class Management extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabSelect: "1",
      roles: props?.app?.user?.roles,
      isProduction: process.env.REACT_APP_PRODUCTION_ENVIRONMENT,
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    const values = queryString.parse(location.search);
    if (values != null && values.tabSelect != null) {
      this.setState({
        tabSelect: values.tabSelect,
      });
    } else {
      if (location?.state?.tabSelect) {
        this.setState({
          tabSelect: location?.state?.tabSelect,
        });
      }
    }
  }

  handleTabClick = (key, e) => {
    this.setState({
      tabSelect: key,
    });
  };

  render() {
    const {
      app: { translation },
    } = this.props;

    const { tabSelect, roles, isProduction } = this.state;
    const showSupplierManagement =
      isProduction === "false" ||
      (roles.includes("Admin") && isProduction === "true");
    return (
      <Container>
        {/* HEADER */}
        <Row type="flex">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <PageTitle>
              <SettingsIcon />
              <span>{translation.sidebar.administration}</span>
            </PageTitle>
          </Col>
        </Row>
        {/* Tabs */}
        <Row type="flex" style={{ marginTop: 16 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Tabs
              type="card"
              defaultActiveKey="1"
              activeKey={tabSelect}
              onTabClick={this.handleTabClick}
            >
              <Tabs.TabPane
                tab={<span>{translation.sidebar.farm}</span>}
                key="1"
              >
                <Farm />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={<span>{translation.sidebar.suppliers}</span>}
                key="2"
              >
                <Supplier />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={<span>{translation.sidebar.client}</span>}
                key="6"
              >
                <Client />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={<span>{translation.sidebar.adminAndUser}</span>}
                key="4"
              >
                <Users />
              </Tabs.TabPane>

              <Tabs.TabPane
                tab={<span>{translation.sidebar.userInvite}</span>}
                key="5"
              >
                <AccessInvitations />
              </Tabs.TabPane>
              <Tabs.TabPane tab={<span>Rotinas Especiais</span>} key="8">
                <UserTrigger />
              </Tabs.TabPane>
              {showSupplierManagement && (
                <Tabs.TabPane
                  tab={<span>{translation.sidebar.supplierManagement}</span>}
                  key="7"
                >
                  <SupplierManagement />
                </Tabs.TabPane>
              )}
            </Tabs>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Management)
);
