import React, { useState, useEffect, useCallback } from "react";
import CanvasJSReact from "../../../../../assets/canvasjs.react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Creators as PurchaseScenarioActions } from "../../../../../store/ducks/purchaseScenario";
import { Creators as DietStrategyActions } from "../../../../../store/ducks/dietStrategy";
import { Container, Body, CustomSlider } from "./styles";
import {
  Row,
  Col,
  Input,
  Select,
  Spin,
  Tooltip,
  DatePicker,
  notification,
} from "antd";
import ButtonStandard from "../../../../../components/utils/button";
import NumberFormat from "react-number-format";
import RoutingLeavingGuardModal from "../../../../../components/modals/routingLeavingGuardModal";
import InfoTooltip from "../../../../../components/utils/infoTooltip";

/** Services */
import {
  getBreedIndexActive,
  getBreedParametersShow,
} from "../../../../../services/breedService";
import {
  showPurchaseScenario,
  storePurchaseScenario,
  updatePurchaseScenario,
} from "../../../../../services/purchaseScenarioService";
import RadioGroup from "antd/lib/radio/group";
import RadioButton from "../../../../../components/utils/radioButton";
import { getDietStrategyIndex } from "../../../../../services/dietStrategyService";
import { getFuturePriceChart } from "../../../../../services/futurePriceService";
import { getMarketAnalysisDropDown } from "../../../../../services/marketAnalysisService";
import { validateDietStrategyValidation } from "../../../../../services/helpersMethodsService";

const PurchaseParametersTab = ({ history, props }) => {
  /** Variables */
  const [allDietStrategies, setAllDietStrategies] = useState([]);
  const [dietStrategies, setDietStrategies] = useState([]);
  const [isLoadingDietStrategies, setIsLoadingDietStrategies] = useState(false);
  const [listMarketAnalysis, setListMarketAnalysis] = useState([]);
  const [loadingListMarketAnalysis, setLoadingListMarketAnalysis] =
    useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const [dataChart, setDataChart] = useState([]);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(true);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [isLoadingBreed, setIsLoadingBreed] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const [form, setForm] = useState(null);
  const [marks] = useState({
    0: "0%",
    100: "100%",
  });
  const [errors, setErrors] = useState([]);
  const [breedCurveParamSelected] = useState(null);
  const dateFormat = "DD/MM/YYYY";
  const { CanvasJSChart } = CanvasJSReact;

  /** Redux variables */
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { activeTab, pageLoaded, newPurchaseScenario, breeds } = useSelector(
    (state) => state.purchaseScenario
  );
  const {
    match: {
      params: { id: scenarioId },
    },
    location: { pathname },
  } = props;
  const dispatch = useDispatch();
  const productionSubSystems = [
    {
      code: "Extensive",
      name: translation.productionSubSystem.extensive,
    },
    {
      code: "SemiIntensive",
      name: translation.productionSubSystem.semiIntensive,
    },
    {
      code: "Intensive",
      name: translation.productionSubSystem.intensive,
    },
  ];

  /** Effects */
  // Get DietStrategies and Market Analysis
  useEffect(() => {
    async function getDietStrategies() {
      setIsLoadingDietStrategies(true);
      try {
        const {
          data: { results: allDietStrategies },
        } = await getDietStrategyIndex({
          groupId,
          farmId,
          withoutPagination: true,
        });
        const filteredDS = allDietStrategies.filter(
          (e) =>
            e.status === "Active" &&
            e.generic === true &&
            !validateDietStrategyValidation(e)
        );
        setAllDietStrategies(filteredDS);
        setDietStrategies(filteredDS);
      } catch (error) {
      } finally {
        setIsLoadingDietStrategies(false);
      }
    }
    async function fetchMarketAnalysis() {
      setLoadingListMarketAnalysis(true);
      try {
        const {
          data: { results },
        } = await getMarketAnalysisDropDown({ groupId, farmId });
        setListMarketAnalysis(results);
        setLoadingListMarketAnalysis(false);
      } catch (error) {
        setListMarketAnalysis([]);
        setLoadingListMarketAnalysis(false);
      }
    }
    fetchMarketAnalysis();
    getDietStrategies();
  }, [groupId, farmId]);
  // Set Form
  useEffect(() => {
    async function renderChart(marketAnalysisId) {
      setLoadingChart(true);
      let chartData = null;
      let nameMarketAnalysis = "Valores";
      try {
        const {
          data: { results },
        } = await getFuturePriceChart({ groupId, farmId, marketAnalysisId });
        if (results.length > 0) {
          chartData = results.map((r) => {
            r.x = moment(r.x).toDate();
            r.y = Number.parseFloat(Number(r.y).toFixed(2));
            return r;
          });
        } else {
          chartData = null;
        }
      } catch (error) {
        chartData = null;
      }
      if (chartData !== null) {
        nameMarketAnalysis = listMarketAnalysis.find(
          (ma) => ma.id === marketAnalysisId
        )?.name;
        setDataChart([
          {
            type: "line",
            showInLegend: true,
            name: nameMarketAnalysis || "-",
            color: "#4A85AE",
            xValueFormatString: "DD/MM/YYYY",
            yValueFormatString: "R$#######.00",
            dataPoints: chartData,
          },
        ]);
      } else {
        setDataChart([]);
      }

      setLoadingChart(false);
    }
    setForm(newPurchaseScenario);
    if (
      newPurchaseScenario.marketAnalysisId != null &&
      listMarketAnalysis.length > 0
    ) {
      renderChart(newPurchaseScenario.marketAnalysisId);
    }
  }, [farmId, groupId, listMarketAnalysis, newPurchaseScenario]);
  // Get Info about PurchaseScenario(Edit or Cloning) and Breeds
  useEffect(() => {
    function compareByStartDate(a, b) {
      let comparison = 0;
      if (a.startDate > b.startDate) {
        comparison = 1;
      } else if (a.startDate < b.startDate) {
        comparison = -1;
      }
      return comparison;
    }
    async function loadPage() {
      setIsLoadingBreed(true);
      setIsLoadingPage(true);
      try {
        if (!pageLoaded) {
          const {
            data: { results: breeds },
          } = await getBreedIndexActive({
            groupId,
            farmId,
            withoutBreedCurveParam: false,
          });
          let internalBreeds = breeds.filter(
            (b) => b.breedCurveParams.length > 0
          );

          dispatch(PurchaseScenarioActions.updateBreeds(internalBreeds));

          let duplicate = pathname.includes("duplicate");
          setDuplicate(duplicate);

          if (scenarioId != null) {
            if (internalBreeds != null && internalBreeds.length > 0) {
              try {
                const {
                  data: { results: scenario },
                } = await showPurchaseScenario({
                  groupId,
                  farmId,
                  id: scenarioId,
                });
                let dietStrategy = null;
                if (scenario?.dietPeriods.length > 0) {
                  let dietPeriodsSorted =
                    scenario?.dietPeriods.sort(compareByStartDate);
                  dietPeriodsSorted = dietPeriodsSorted.map((dp) => {
                    dp.pasture =
                      dp.pastureId == null ? null : { id: dp.pastureId };
                    dp.supplement =
                      dp.supplementId == null ? null : { id: dp.supplementId };
                    return dp;
                  });
                  if (duplicate) {
                    dietPeriodsSorted = dietPeriodsSorted.map((dp) => {
                      dp.id = null;
                      return dp;
                    });
                  }
                  dietStrategy = {
                    dietPeriods: dietPeriodsSorted,
                  };
                }

                let initialSimulationDate = null;

                if (
                  scenario?.dietPeriods.length > 0 &&
                  scenario?.initialSimulationDate == null
                ) {
                  let dietPeriodsSorted =
                    scenario?.dietPeriods.sort(compareByStartDate);
                  initialSimulationDate = dietPeriodsSorted[0].startDate;
                } else if (scenario?.initialSimulationDate !== null) {
                  initialSimulationDate = scenario?.initialSimulationDate;
                } else {
                  initialSimulationDate = moment().day(1);
                }

                let body = {
                  id: duplicate ? null : scenario?.id,
                  name: duplicate
                    ? scenario?.name + " - DUPLICADO"
                    : scenario?.name,
                  marginProfit: scenario?.marginProfit,
                  breedId: scenario?.breedId,
                  breedName: internalBreeds.find(
                    (breed) => breed.id === scenario?.breedId
                  )?.name,
                  asymptoticWeight: scenario?.asymptoticWeight,
                  cap: scenario?.cap,
                  gender: scenario?.gender,
                  marketAnalysisId: scenario?.marketAnalysisId,
                  dietStrategyId: scenario?.dietStrategyId,
                  createNewDietStrategy: scenario?.createNewDietStrategy,
                  initialSimulationDate,
                  productionSubSystem: scenario?.productionSubSystem,
                  status: scenario?.status,
                  dietStrategy,
                  minWeight: scenario?.minWeight || null,
                  maxWeight: scenario?.maxWeight || 720,
                  commission: scenario?.commission,
                  commissionUnit: scenario?.commissionUnit || "PERCENTAGE",
                  freight: scenario?.freight,
                  respectCapacity:
                    scenario?.respectCapacity != null
                      ? scenario.respectCapacity
                      : true,
                };
                dispatch(
                  PurchaseScenarioActions.updateDietStrategy(dietStrategy)
                );
                dispatch(
                  PurchaseScenarioActions.updateNewPurchaseScenario(body)
                );
                setForm(body);
              } catch (error) {
                console.error(error);
              }
            }
          }

          dispatch(PurchaseScenarioActions.updatePageLoaded(true));
        }
      } catch (error) {
      } finally {
        setIsLoadingBreed(false);
        setIsLoadingPage(false);
      }
    }
    loadPage();
  }, [scenarioId, pathname, pageLoaded, dispatch, groupId, farmId]);

  // Callbacks
  const disabledInitialDate = useCallback((initialDate) => {
    if (!initialDate) {
      return false;
    }
    return initialDate.valueOf() < moment({ year: 2000, month: 0, day: 1 });
  }, []);
  const refreshDietStrategies = useCallback(
    (form) => {
      const filterDS = allDietStrategies.filter(
        (ds) =>
          ds.breedId === form.breedId &&
          ds.cap === form?.cap &&
          ds.gender === form?.gender
      );
      setDietStrategies(filterDS);
    },
    [allDietStrategies]
  );

  /** Methods */
  async function goNextTabOrCreateUpdate() {
    if (validationForm()) {
      const body = {
        ...form,
        breedCurveParamSelected,
      };
      if (body.createNewDietStrategy) {
        dispatch(PurchaseScenarioActions.updateNewPurchaseScenario(body));

        dispatch(
          PurchaseScenarioActions.changeTab(
            activeTab === "tab1" ? "tab2" : "tab2"
          )
        );
      } else {
        setShouldBlockNavigation(false);
        setIsLoadingPage(true);
        let isSuccess = false;
        if (body.id === null) {
          try {
            await storePurchaseScenario({
              groupId,
              farmId,
              body,
            });
            notification.success({
              message: "Cenário de compra criado!",
              description: "Você será notificado quando o mesmo for executado!",
            });
            isSuccess = true;
          } catch (error) {
            setIsLoadingPage(false);
            notification.error({
              message: "Erro!",
              description:
                "Houve um erro no momento de criar o cenário de compra, contate o administrador do sistema",
            });
          }
        } else {
          let id = body.id;
          try {
            await updatePurchaseScenario({
              groupId,
              farmId,
              id,
              body,
            });
            notification.success({
              message: "Cenário de compra atualizado!",
              description: "Você será notificado quando o mesmo for executado!",
            });
            isSuccess = true;
          } catch (error) {
            setIsLoadingPage(false);
            notification.error({
              message: "Erro!",
              description:
                "Houve um erro no momento de atualizar o cenário de compra, contate o administrador do sistema",
            });
          }
        }
        if (isSuccess) {
          setTimeout(() => {
            setIsLoadingPage(false);
            history.push({
              pathname: "/admin/decisions",
              state: {
                tab: "2",
              },
            });
          }, 250);
        }
      }
    }
  }

  async function renderChart(marketAnalysisId) {
    setLoadingChart(true);
    let chartData = null;
    let nameMarketAnalysis = "Valores";
    try {
      const {
        data: { results },
      } = await getFuturePriceChart({ groupId, farmId, marketAnalysisId });
      if (results.length > 0) {
        chartData = results.map((r) => {
          r.x = moment(r.x).toDate();
          r.y = Number.parseFloat(Number(r.y).toFixed(2));
          return r;
        });
      } else {
        chartData = null;
      }
    } catch (error) {
      chartData = null;
    }
    if (chartData !== null) {
      nameMarketAnalysis = listMarketAnalysis.find(
        (ma) => ma.id === marketAnalysisId
      )?.name;
      setDataChart([
        {
          type: "line",
          showInLegend: true,
          name: nameMarketAnalysis,
          color: "#4A85AE",
          xValueFormatString: "DD/MM/YYYY",
          yValueFormatString: "R$#######.00",
          dataPoints: chartData,
        },
      ]);
    } else {
      setDataChart([]);
    }

    setLoadingChart(false);
  }

  function validationForm() {
    let errorsArray = [];

    if (!form.name || form.name.includes(" - DUPLICADO")) {
      errorsArray.push("name");
    }
    if (!form.breedId) {
      errorsArray.push("breedId");
    }
    if (!form.marginProfit || form?.marginProfit === 0) {
      errorsArray.push("marginProfit");
    }
    if (!form?.asymptoticWeight || form?.asymptoticWeight === 0) {
      errorsArray.push("asymptoticWeight");
    }
    if (form?.gender === null) {
      errorsArray.push("gender");
    }
    if (form?.productionSubSystem === null) {
      errorsArray.push("productionSubSystem");
    }
    if (form?.initialSimulationDate === null) {
      errorsArray.push("initialSimulationDate");
    }
    if (form?.marketAnalysisId === null) {
      errorsArray.push("marketAnalysisId");
    }

    if (form?.maxWeight == null || form?.maxWeight <= form?.minWeight) {
      errorsArray.push("maxWeight");
    }

    if (
      form?.createNewDietStrategy === false &&
      form?.dietStrategyId === null
    ) {
      errorsArray.push("dietStrategyId");
    }
    setErrors(errorsArray);
    if (errorsArray.length > 0) {
      return false;
    }
    return true;
  }

  function handleShowDietStrategyDetails() {
    if (form?.dietStrategyId)
      dispatch(
        DietStrategyActions.showDietStrategy(
          groupId,
          farmId,
          form?.dietStrategyId
        )
      );
  }

  function handleInputChange(name, value) {
    setForm({
      ...form,
      [name]: value,
    });
  }

  function selectUnitCommission() {
    return (
      <Select
        value={form?.commissionUnit}
        style={{ width: 90 }}
        onChange={(value) =>
          setForm({
            ...form,
            commissionUnit: value,
            commission: null,
          })
        }
      >
        <Select.Option value="PERCENTAGE">%</Select.Option>
        <Select.Option value="RS_CAB">R$/Cab</Select.Option>
      </Select>
    );
  }

  async function handleInputBreed(name, value) {
    setIsLoadingBreed(true);
    setIsLoadingDietStrategies(true);
    try {
      const {
        data: { results: breedParameter },
      } = await getBreedParametersShow({
        groupId,
        farmId,
        id: value,
      });
      const { asymptoticWeight } = breedParameter;
      const breedCurveParams = breeds.find(
        (b) => b.id === value
      ).breedCurveParams;
      const breedName = breeds.find((b) => b.id === value).name;
      const newForm = {
        ...form,
        breedId: value,
        breedName,
        breedCurveParams,
        asymptoticWeight,
        dietStrategyId: null,
      };
      setForm(newForm);
      refreshDietStrategies(newForm);
    } catch (error) {
    } finally {
      setIsLoadingDietStrategies(false);
      setIsLoadingBreed(false);
    }
  }

  async function handleCancel() {
    await setShouldBlockNavigation(false);
    history.push({
      pathname: "/admin/decisions",
      state: {
        tab: "2",
      },
    });
  }

  /* Chart options */
  const options = {
    animationEnabled: true,
    zoomEnabled: true,
    height: 280,
    backgroundColor: "#fcfcfc",
    axisX: {
      labelFontSize: 12,
      valueFormatString: "MM/YY",
      interval: 1,
      intervalType: "month",
      viewportMinimum: moment().toDate(),
      viewportMaximum: moment().add(1, "years").toDate(),
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
      },
    },
    toolTip: {
      fontFamily: "Asap",
      shared: true,
    },
    axisY: {
      labelFontSize: 12,
      includeZero: true,
      valueFormatString: "###0.##",
      suffix: " R$/@",
      gridDashType: "dot",
    },
    data: dataChart,
  };

  return (
    <>
      <RoutingLeavingGuardModal
        when={shouldBlockNavigation}
        navigate={(path) => history.push(path)}
        message="Parece que você está saindo sem salvar o cenário de compra."
      />
      <Container>
        <Spin spinning={isLoadingPage}>
          {/* Message and buttons */}
          <Row className="headerButtonsAndMessagens" align="middle">
            <Col
              xs={{ span: activeTab !== "tab1" ? 15 : 16, offset: 0 }}
              sm={{ span: activeTab !== "tab1" ? 15 : 16, offset: 0 }}
              md={{ span: activeTab !== "tab1" ? 15 : 16, offset: 0 }}
              lg={{ span: activeTab !== "tab1" ? 15 : 16, offset: 0 }}
              xl={{ span: activeTab !== "tab1" ? 15 : 16, offset: 0 }}
            >
              <strong>Complete os campos de parâmetros da compra</strong>
            </Col>
            <Col
              xs={{ span: 8, offset: 0 }}
              sm={{ span: 8, offset: 0 }}
              md={{ span: 8, offset: 0 }}
              lg={{ span: 8, offset: 0 }}
              xl={{ span: 8, offset: 0 }}
              align="right"
              className="buttonsCol"
            >
              <ButtonStandard
                type="button"
                buttonType="type4"
                size="s"
                onClick={handleCancel}
              >
                {translation.buttons.cancel}
              </ButtonStandard>
              <ButtonStandard
                type="button"
                buttonType="type1"
                width="121px"
                height="35px"
                padding="5px 10px 5px 10px"
                onClick={goNextTabOrCreateUpdate}
              >
                {form?.createNewDietStrategy
                  ? "Próximo"
                  : scenarioId != null
                  ? "Atualizar"
                  : "Criar"}
              </ButtonStandard>
            </Col>
          </Row>
          <Body>
            <Row type="flex">
              <Col span={12} className="colName">
                {/* Input name */}
                <Row>
                  <label className={errors.includes("name") ? "error" : ""}>
                    {errors.includes("name") && duplicate
                      ? "Nome do Cenário* -  ajuste o nome do cenário duplicado"
                      : "Nome do Cenário*"}
                  </label>
                </Row>
                <Row typle="flex">
                  <Input
                    name="name"
                    className={errors.includes("name") ? "error" : ""}
                    value={form?.name}
                    maxLength={20}
                    autoComplete="chrome-off"
                    placeholder="Nome do Cenário *"
                    onChange={(e) => {
                      handleInputChange("name", e.target.value);
                      setErrors(errors.filter((e) => e !== "name"));
                    }}
                  />
                </Row>
                {/* Input profit margin */}
                <Row className="rowSlider">
                  <label
                    className={errors.includes("marginProfit") ? "error" : ""}
                  >
                    Margem de Lucro
                  </label>
                </Row>
                <Row typle="flex" className="rowSlider">
                  <Col span={18}>
                    <CustomSlider
                      marks={marks}
                      min={0}
                      max={100}
                      value={form?.marginProfit}
                      onChange={(value) => {
                        handleInputChange("marginProfit", value);
                        setErrors(errors.filter((e) => e !== "marginProfit"));
                      }}
                    />
                  </Col>
                  <Col span={5} offset={1}>
                    <NumberFormat
                      customInput={Input}
                      name="marginProfit"
                      value={form !== null ? form.marginProfit : 0}
                      addonAfter="%"
                      decimalScale={0}
                      decimalSeparator=","
                      thousandSeparator="."
                      onValueChange={({ floatValue }) => {
                        handleInputChange(
                          "marginProfit",
                          floatValue != null
                            ? floatValue > 100
                              ? 100
                              : floatValue
                            : 0
                        );
                        setErrors(errors.filter((e) => e !== "marginProfit"));
                      }}
                    />
                  </Col>
                </Row>
                {/* Input MarketAnalysis */}
                <Row>
                  <label
                    className={
                      errors.includes("marketAnalysisId") ? "error" : ""
                    }
                  >
                    Análise de mercado *
                  </label>
                </Row>
                <Row>
                  <Select
                    name="marketAnalysisId"
                    style={{ width: "100%" }}
                    value={
                      form?.marketAnalysisId != null &&
                      !loadingListMarketAnalysis
                        ? form?.marketAnalysisId
                        : undefined
                    }
                    loading={loadingListMarketAnalysis}
                    placeholder={translation.defaultSelectPlaceholder}
                    onChange={(value) => {
                      setForm({
                        ...form,
                        marketAnalysisId: value,
                      });
                      setErrors(errors.filter((e) => e !== "marketAnalysisId"));
                      renderChart(value);
                    }}
                  >
                    {listMarketAnalysis
                      .filter((ma) => ma.status === "Active")
                      .map((ma) => (
                        <Select.Option key={ma.id} value={ma.id}>
                          {ma.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Row>
                {/* Chart */}
                {(dataChart.length > 0 || loadingChart === true) && (
                  <Row>
                    <Spin spinning={loadingChart}>
                      <CanvasJSChart options={options} />
                    </Spin>
                  </Row>
                )}
              </Col>
              {/* Col Select DietStrategy */}
              <Col span={11} offset={1}>
                {/* BreedId & Gender & Cap */}
                <Row typle="flex" justify="start" gutter={8}>
                  {/* BreedId */}
                  <Col span={10}>
                    <Row>
                      <label
                        className={errors.includes("breedId") ? "error" : ""}
                      >
                        Defina a raça de compra *
                      </label>
                    </Row>
                    <Row type="flex">
                      <Select
                        loading={isLoadingBreed}
                        value={
                          breeds.length === 0
                            ? undefined
                            : !form?.breedId
                            ? undefined
                            : form?.breedId
                        }
                        placeholder={translation.defaultSelectPlaceholder}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          setForm({ ...form, breedId: value });
                          handleInputBreed("breedId", value);
                          setErrors(errors.filter((e) => e !== "breedId"));
                        }}
                      >
                        {breeds.map((b) => (
                          <Select.Option key={b.id} value={b.id}>
                            {b.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Row>
                  </Col>
                  {/* Gender */}
                  <Col span={6}>
                    <Row>
                      <label
                        className={errors.includes("gender") ? "error" : ""}
                      >
                        {translation.animal.edit.form.sex} *
                      </label>
                    </Row>
                    <Row type="flex">
                      <Select
                        style={{ width: "100%" }}
                        name="gender"
                        value={form?.gender}
                        placeholder={translation.defaultSelectPlaceholder}
                        onChange={(value) => {
                          if (value === "Male") {
                            setForm({
                              ...form,
                              gender: value,
                              cap: false,
                              dietStrategyId: null,
                            });
                          } else {
                            setForm({
                              ...form,
                              gender: value,
                              cap: false,
                              dietStrategyId: null,
                            });
                          }
                          refreshDietStrategies({
                            ...form,
                            gender: value,
                            cap: false,
                            dietStrategyId: null,
                          });
                        }}
                      >
                        <Select.Option key="Female" value="Female">
                          {translation.animal.edit.form.female}
                        </Select.Option>
                        <Select.Option key="Male" value="Male">
                          {translation.animal.edit.form.male}
                        </Select.Option>
                      </Select>
                    </Row>
                  </Col>
                  {/* Cap */}
                  <Col span={8}>
                    {form?.gender === "Male" ? (
                      <>
                        <Row>
                          <label className="formLabel">
                            {translation.animal.edit.form.cap}
                          </label>
                        </Row>
                        <Row type="flex">
                          <RadioGroup
                            name="cap"
                            value={form?.cap}
                            defaultValue={false}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                cap: e.target.value,
                                dietStrategyId: null,
                              });
                              refreshDietStrategies({
                                ...form,
                                cap: e.target.value,
                                dietStrategyId: null,
                              });
                            }}
                          >
                            <RadioButton value={true}>
                              {translation.buttons.yes}
                            </RadioButton>
                            <RadioButton value={false}>
                              {translation.buttons.no}
                            </RadioButton>
                          </RadioGroup>
                        </Row>
                      </>
                    ) : null}
                  </Col>
                </Row>
                {/* Product SubSystem & initialSimulationDate*/}
                <Row typle="flex" justify="start" gutter={8}>
                  {/* ProductSubSystem */}
                  <Col span={8}>
                    <Row>
                      <label
                        className={
                          errors.includes("productionSubSystem") ? "error" : ""
                        }
                      >
                        SubSistema de Produção*
                      </label>
                    </Row>
                    <Row type="flex">
                      <Select
                        style={{ width: "100%" }}
                        name="productionSubSystem"
                        value={
                          form?.productionSubSystem == null
                            ? undefined
                            : form?.productionSubSystem
                        }
                        placeholder={
                          translation.profitCenter.form
                            .productionSubSystemPlaceHolder
                        }
                        onChange={(value) => {
                          setForm({ ...form, productionSubSystem: value });
                          setErrors(
                            errors.filter((e) => e !== "productionSubSystem")
                          );
                        }}
                      >
                        {productionSubSystems.length > 0 &&
                          productionSubSystems.map((pss, i) => (
                            <Select.Option key={i} value={pss.code}>
                              {pss.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Row>
                  </Col>
                  {/* initialSimulationDate */}
                  <Col span={8}>
                    <Row>
                      <label
                        className={
                          errors.includes("initialSimulationDate")
                            ? "error"
                            : ""
                        }
                      >
                        Data inicial *
                      </label>
                    </Row>
                    <Row type="flex">
                      <DatePicker
                        name="initialSimulationDate"
                        disabledDate={disabledInitialDate}
                        disabled={scenarioId != null}
                        allowClear={false}
                        value={
                          form?.initialSimulationDate != null
                            ? moment(form?.initialSimulationDate, "YYYY-MM-DD")
                            : null
                        }
                        placeholder={translation.defaultDatePickerPlaceholder}
                        format={dateFormat}
                        onChange={(date, dateString) => {
                          if (date <= moment() && date !== null) {
                            setForm({
                              ...form,
                              initialSimulationDate: date,
                            });
                          }
                        }}
                      />
                    </Row>
                  </Col>
                  {/* respectCapacity */}
                  <Col span={8}>
                    <Row>
                      <label
                        className="formLabel"
                        style={{ marginRight: "7px" }}
                      >
                        Respeitar capacidade de suporte?
                      </label>
                      <InfoTooltip
                        title={
                          "Defina se para o Cenário de Compra, deverá ser considerado a Capacidade de Suporte calculado da fazenda, com base na área de pastejo, disponibilidade de forrageira (estoque estimado de MS) e taxa de lotação (Ua/ha), atual e prevista, considerando vendas previstas de animais (cenários de venda definidos como favoritos) e estações climáticas."
                        }
                      />
                    </Row>
                    <Row type="flex">
                      <RadioGroup
                        name="respectCapacity"
                        value={form?.respectCapacity}
                        defaultValue={true}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            respectCapacity: e.target.value,
                          });
                          refreshDietStrategies({
                            ...form,
                            respectCapacity: e.target.value,
                          });
                        }}
                      >
                        <RadioButton value={true}>
                          {translation.buttons.yes}
                        </RadioButton>
                        <RadioButton value={false}>
                          {translation.buttons.no}
                        </RadioButton>
                      </RadioGroup>
                    </Row>
                  </Col>
                </Row>
                {/* Input restrict weight for purchase */}
                <Row>
                  <label
                    className={errors.includes("maxWeight") ? "error" : ""}
                  >
                    Defina as Restrições de Peso de Venda *
                  </label>
                </Row>
                <Row typle="flex" justify="start" gutter={9}>
                  <Col span={9}>
                    <Row>
                      <NumberFormat
                        customInput={Input}
                        value={
                          form?.minWeight !== null ? form?.minWeight : null
                        }
                        name="minWeight"
                        addonAfter="Kg"
                        addonBefore={translation.filters.min}
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        placeholder="00,00"
                        onValueChange={({ floatValue }) => {
                          handleInputChange(
                            "minWeight",
                            floatValue != null
                              ? floatValue > 9999.99
                                ? 9999.99
                                : floatValue
                              : null
                          );
                          setErrors(errors.filter((e) => e !== "minWeight"));
                        }}
                      />
                    </Row>
                  </Col>
                  <Col span={9}>
                    <Row>
                      <NumberFormat
                        customInput={Input}
                        value={
                          form?.maxWeight !== null ? form?.maxWeight : null
                        }
                        name="maxWeight"
                        addonBefore={translation.filters.max}
                        addonAfter="Kg"
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        placeholder="000,00"
                        defaultValue={720}
                        onValueChange={({ floatValue }) => {
                          handleInputChange(
                            "maxWeight",
                            floatValue != null
                              ? floatValue > 9999.99
                                ? 9999.99
                                : floatValue
                              : null
                          );
                          setErrors(errors.filter((e) => e !== "maxWeight"));
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                {/* commission and freight */}
                <Row>
                  <Col span={10}>
                    <label style={{ marginRight: "7px" }}>
                      Comissão à Pagar
                    </label>
                    <InfoTooltip
                      title={
                        "Opção para informar o valor a ser considerado como Comissão, podendo ser informado em % ou o valor pago por animal. Este valor impactará nos custos de aquisição dos animais a serem adquiridos."
                      }
                    />
                  </Col>
                  <Col span={10}>
                    <label style={{ marginRight: "7px" }}>Frete à Pagar</label>
                    <InfoTooltip
                      title={
                        "Opção para informar o valor a ser considerado como frete a ser pago por animal. Este valor impactará nos custos de aquisição dos animais a serem adquiridos."
                      }
                    />
                  </Col>
                </Row>
                <Row typle="flex" justify="start" gutter={9}>
                  <Col span={10}>
                    <NumberFormat
                      customInput={Input}
                      value={
                        form?.commission !== null ? form?.commission : null
                      }
                      name="commission"
                      addonAfter={selectUnitCommission()}
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      placeholder="00,00"
                      maxLength={9}
                      onValueChange={({ floatValue }) => {
                        handleInputChange(
                          "commission",
                          form?.commissionUnit === "PERCENTAGE" &&
                            floatValue > 99
                            ? 99
                            : floatValue
                        );
                      }}
                    />
                  </Col>
                  <Col span={10}>
                    <NumberFormat
                      customInput={Input}
                      value={form?.freight !== null ? form?.freight : null}
                      name="freight"
                      addonAfter="R$/Cab"
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      placeholder="00,00"
                      maxLength={9}
                      onValueChange={({ floatValue }) => {
                        handleInputChange("freight", floatValue);
                      }}
                    />
                  </Col>
                </Row>
                {/* Use a DietStrategy already created or Create a new one */}
                <Row>
                  <label>
                    Utilizar um Est. de Dieta já criada ou criar uma Nova?
                  </label>
                </Row>
                <Row type="flex">
                  <RadioGroup
                    name="createNewDietStrategy"
                    value={form?.createNewDietStrategy}
                    defaultValue={false}
                    disabled={scenarioId != null ? true : false}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        createNewDietStrategy: e.target.value,
                      });
                    }}
                  >
                    <RadioButton value={false}>Existente</RadioButton>
                    <RadioButton value={true}>Nova</RadioButton>
                  </RadioGroup>
                </Row>
                {/* If use a existing diet strategy */}
                {!form?.createNewDietStrategy && (
                  <>
                    {/* Input dietStrategyBaseLine */}
                    <Row type="flex">
                      <label
                        className={
                          errors.includes("dietStrategyId") ? "error" : ""
                        }
                      >
                        Estratégia de Dieta*
                      </label>
                    </Row>
                    <Row type="flex">
                      <Tooltip
                        title={
                          form?.breedId === null
                            ? "É necessário selecionar uma Raça para poder selecionar a Est. de Dieta."
                            : null
                        }
                        trigger="hover"
                      >
                        <Select
                          name="dietStrategyId"
                          value={
                            dietStrategies != null && dietStrategies.length > 0
                              ? form?.dietStrategyId != null
                                ? form?.dietStrategyId
                                : undefined
                              : undefined
                          }
                          loading={isLoadingDietStrategies}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Selecione a estratégia"
                          disabled={form?.breedId === null}
                          onChange={(value) => {
                            setForm({ ...form, dietStrategyId: value });
                            setErrors(
                              errors.filter((e) => e !== "dietStrategyId")
                            );
                          }}
                        >
                          {dietStrategies != null && dietStrategies.length > 0
                            ? dietStrategies.map((ds) => (
                                <Select.Option key={ds.id} value={ds.id}>
                                  {ds.name}
                                </Select.Option>
                              ))
                            : null}
                        </Select>
                      </Tooltip>
                    </Row>
                    <Row
                      type="flex"
                      style={{ marginTop: "13px" }}
                      justify="center"
                      align="middle"
                    >
                      <ButtonStandard
                        buttonType="typeWithoutBackground"
                        color="#684E94"
                        type="button"
                        isUpperCase={true}
                        onClick={handleShowDietStrategyDetails}
                        disabled={form?.dietStrategyId === null}
                      >
                        VISUALIZAR DETALHES SOBRE A ESTRATÉGIA DE DIETA
                      </ButtonStandard>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </Body>
        </Spin>
      </Container>
    </>
  );
};

export default withRouter(PurchaseParametersTab);
