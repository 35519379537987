import styled from "styled-components";
import { Drawer, Divider, Input } from "antd";

export const Container = styled(Drawer)`
  .ant-drawer-body {
    padding: 24px 0px 10px 0px;
  }

  .drawerForm {
    width: 100%;
    min-height: -moz-calc(100vh - 165px);
    min-height: -webkit-calc(100vh - 165px);
    min-height: calc(100vh - 165px);
    padding: 0px 24px;
  }

  .drawerFormCustom {
    width: 100%;
    height: 145px;
    padding: 0px 24px;
    margin-bottom: 15px;
  }

  .rowLabel {
    margin-bottom: 7px;
    margin-top: 19px;
  }

  .captcha {
    width: 100%;
    margin-top: 30px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    margin-bottom: 7px;
    margin-top: 19px;
    &.error {
      color: #d44c4c;
    }
  }

  .rowInput {
    margin-top: 8px;
  }

  .tableButtonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      display: flex;
      justify-content: center !important;
      text-align: center !important;
      align-items: center !important;
      font-size: 14px !important;
      width: 77px !important;
      height: 30px !important;

      svg {
        margin-right: 3px !important;
      }
    }

    button:first-of-type {
      width: 116px !important;
      margin-right: 12px !important;
    }
  }

  .ant-switch-checked {
    background-color: #a9c133 !important;
  }

  .resend {
    background: transparent;
    border: none;
    color: #a9c133 !important;
    font-size: 14px;
    cursor: pointer;
  }

  .hide {
    display: none;
  }
`;

export const ModalTitle = styled.span`
  font-size: 25px;
  font-family: "Asap", sans-serif;
  font-weight: bold;
  color: #4b4b4b;
`;

export const ButtonInputNumber = styled.button`
  background: transparent;
  color: #fff;
  border: none;
  width: 100% !important;
  height: 100% !important;

  &:hover {
    cursor: pointer;
  }

  svg {
    margin-left: 5px;
  }
`;

export const Footer = styled.div`
  border-top: 1px solid #e8e8e8;
  margin-top: 20px;
  width: 100%;
  padding: 10px 24px;
  border-radius: 0 0 4px 4px;

  .buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding-right: 24px; */

    button:first-of-type {
      margin-right: 12px !important;
    }
  }

  .selectDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: "Asap", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;

export const CustomDivider = styled(Divider)`
  margin: 10px 0 !important;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 10px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;

export const CustomInput = styled(Input)`
  border-color: #9074bf !important;
  text-align: center;

  &ant-input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #9074bf !important;
  }
  &ant-input::-moz-placeholder {
    /* Firefox 19+ */
    color: #9074bf !important;
  }
  &ant-input:-ms-input-placeholder {
    /* IE 10+ */
    color: #9074bf !important;
  }
  &ant-input:-moz-placeholder {
    /* Firefox 18- */
    color: #9074bf !important;
  }
`;
