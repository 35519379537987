import styled from "styled-components";
import { Modal } from "antd";

export const StyledModal = styled(Modal)`
  &.ant-modal {
    padding: 0px;
  }
  .ant-modal-body {
    padding: 29px 26px;
  }

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Asap", serif;

    strong {
      font-size: 16px;
    }

    p {
      font-family: "Asap", sans-serif;
      font-size: 16px;
      text-align: center;
      margin: 20px 0;

      strong {
        color: #684e94;
      }
    }
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
