import { useContext } from "react";
import { UnitContext } from "../../contexts/unitContext";

const useUnitContext = () => {
  const value = useContext(UnitContext);

  return value;
};

export default useUnitContext;
