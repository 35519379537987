import styled from "styled-components";

export const Container = styled.div`
  label {
    display: block;
    color: #9074bf;
    font-family: "Asap", sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 15px;
    &.error {
      color: #d44c4c;
    }
  }
  .ant-select {
    width: 100%;
  }

  .body {
    margin-bottom: 16px;
  }

  .ant-row > label + .ant-row,
  .ant-row-flex > label + .ant-row-flex {
    margin-bottom: 8px;
  }
`;
