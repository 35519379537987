import React, { useState, useCallback, useRef, useEffect } from "react";
import moment from "moment";
import Axios from "axios";
/** Hooks */
/** Redux libs */
import { useSelector } from "react-redux";
/** Components */
import {
  Row,
  Card,
  Col,
  Table,
  Input,
  Button,
  Icon,
  DatePicker,
  notification,
  Modal,
} from "antd";
import { Container, Title } from "./styles";
import ButtonStandard from "../../../components/utils/button";
import TrashIcon from "../../../components/utils/icons/trash";

/** Services */
import {
  deleteLotMovementHistory,
  getLotMovementHistoryIndex,
} from "../../../services/lotMovementHistoryService";

const MovementHistory = ({ tab }) => {
  // Variables
  let searchInput = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dataHistoryMovement, setDataHistoryMovement] = useState([]);
  const signal = Axios.CancelToken.source();
  const { RangePicker } = DatePicker;
  const Column = Table.Column;

  // Redux variables
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
    translation,
  } = useSelector((state) => state.app);

  // Effects
  useEffect(() => {
    const signal = Axios.CancelToken.source();
    async function fetch() {
      setIsLoading(true);
      try {
        const {
          data: { results },
        } = await getLotMovementHistoryIndex({
          groupId,
          farmId,
          signal,
        });
        const resultsWithoutDeleting = results.filter(
          (r) => r.status !== "Deleting"
        );
        setDataHistoryMovement(resultsWithoutDeleting);
      } catch (error) {
        setDataHistoryMovement([]);
      } finally {
        setIsLoading(false);
      }
    }
    fetch();
    return () => {
      setDataHistoryMovement([]);
      signal.cancel("Cancelou");
    };
  }, [farmId, groupId]);
  // Callbacks
  const handleDelete = useCallback(
    async (requestId) => {
      Modal.confirm({
        title: "Você realmente deseja deletar este registro?",
        okType: "danger",
        okText: translation.buttons.yes,
        cancelText: translation.buttons.no,
        centered: true,
        onOk: async () => {
          try {
            await deleteLotMovementHistory({
              groupId,
              farmId,
              requestId,
              signal,
            });
            notification.success({
              message:
                "Foi iniciado o processo de deleção do registro e reverção da movimentação dos animais. Assim que concluído, você receberá uma notificação.",
            });
          } catch (error) {
            notification.error({
              message:
                "Houve um erro ao deletar o registro. Contate o suporte.",
            });
          }
        },
        onCancel() {},
      });
    },
    [farmId, groupId, signal, translation.buttons.no, translation.buttons.yes]
  );

  // Methods
  function expandedRows(record, index, indent, expanded) {
    const columns = [
      {
        title: translation.movementHistory.table.columns.animalHandlingNumber,
        dataIndex: "animalHandlingNumber",
        key: "animalHandlingNumber",
      },
      {
        title: translation.movementHistory.table.columns.lotOriginName,
        dataIndex: "lotOriginName",
        key: "lotOriginName",
      },
      {
        title: translation.movementHistory.table.columns.lotDestinationName,
        dataIndex: "lotDestinationName",
        key: "lotDestinationName",
      },
    ];

    return (
      <Table
        rowKey="animalId"
        columns={columns}
        dataSource={record.animals}
        pagination={{ pageSize: 5, hideOnSinglePage: true }}
      />
    );
  }

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            value={selectedKeys && selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        if (dataIndex === "originalLotsNames") {
          return (
            record["originalLotsNames"].filter((v) =>
              v.toString().includes(value)
            ).length > 0
          );
        }
        if (dataIndex === "destinationLotsNames") {
          return (
            record["destinationLotsNames"].filter((v) =>
              v.toString().includes(value)
            ).length > 0
          );
        } else {
          return (
            record[dataIndex] && record[dataIndex].toString().includes(value)
          );
        }
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
    };
  }

  function getDateSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div
          style={{
            padding: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Row style={{ marginBottom: 5 }}>
            <RangePicker
              value={selectedKeys && selectedKeys[0]?.dates}
              format="DD/MM/YYYY"
              onChange={(dates, dateStrings) => setSelectedKeys([{ dates }])}
            />
          </Row>
          <Row>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{
                width: 90,
                marginRight: 8,
                background: "#684e94",
                borderColor: "none",
                border: "none",
              }}
            />
            <Button
              onClick={() => handleReset(clearFilters)}
              icon="delete"
              size="small"
              style={{ width: 90 }}
            />
          </Row>
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="calendar"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return moment(record[dataIndex]).isBetween(
          value?.dates[0],
          value?.dates[1],
          "days",
          "[]"
        );
      },
    };
  }

  function handleSearch(selectedKeys, confirm) {
    confirm();
  }

  function handleReset(clearFilters) {
    clearFilters();
  }

  async function handleFetchTable() {
    setIsLoading(true);
    try {
      const {
        data: { results },
      } = await getLotMovementHistoryIndex({
        groupId,
        farmId,
        signal,
      });
      const resultsWithoutDeleting = results.filter(
        (r) => r.status !== "Deleting"
      );
      setDataHistoryMovement(resultsWithoutDeleting);
    } catch (error) {
      setDataHistoryMovement([]);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <Card>
        <Row
          type="flex"
          justify="space-between"
          style={{ marginBottom: "19px" }}
        >
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Title>{translation.movementHistory.title}</Title>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Row type="flex" justify="end" gutter={8}>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={handleFetchTable}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </Row>
          </Col>
        </Row>
        <Row type="flex">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              expandedRowRender={expandedRows}
              loading={isLoading}
              rowKey="id"
              dataSource={dataHistoryMovement}
              pagination={{
                showSizeChanger: true,
                pageSizeOptions: [
                  "10",
                  "20",
                  "30",
                  "100",
                  "500",
                  "1000",
                  "2000",
                ],
                hideOnSinglePage:
                  dataHistoryMovement.length > 10 ? false : true,
              }}
              scroll={{
                x: 1200,
                y: 758,
              }}
            >
              <Column
                title={
                  translation.movementHistory.table.columns.originalLotsNames
                }
                dataIndex="originalLotsNames"
                key="originalLotsNames"
                align="left"
                {...getColumnSearchProps("originalLotsNames")}
                render={(text, record) => (
                  <span>{`${
                    record.originalLotsNames.length > 1
                      ? record.originalLotsNames.join(", ")
                      : record.originalLotsNames[0]
                  }`}</span>
                )}
              />
              <Column
                title={
                  translation.movementHistory.table.columns.destinationLotsNames
                }
                dataIndex="destinationLotsNames"
                key="destinationLotsNames"
                align="left"
                {...getColumnSearchProps("destinationLotsNames")}
                render={(text, record) => (
                  <span>{`${
                    record.destinationLotsNames.length > 1
                      ? record.destinationLotsNames.join(", ")
                      : record.destinationLotsNames[0]
                  }`}</span>
                )}
              />
              <Column
                title={translation.movementHistory.table.columns.requestDate}
                dataIndex="requestDate"
                key="requestDate"
                align="left"
                {...getDateSearchProps("requestDate")}
                render={(text, record) => (
                  <span>{`${moment(record.requestDate).format(
                    "DD/MM/YYYY"
                  )}`}</span>
                )}
              />
              <Column
                title={translation.movementHistory.table.columns.createdAtDate}
                dataIndex="createdAtDate"
                key="createdAtDate"
                align="left"
                {...getDateSearchProps("createdAtDate")}
                render={(text, record) => (
                  <span>{`${moment(record.createdAtDate).format(
                    "DD/MM/YYYY"
                  )}`}</span>
                )}
              />
              <Column
                title={
                  translation.movementHistory.table.columns.fromDismemberLot
                }
                dataIndex="fromDismemberLot"
                key="fromDismemberLot"
                align="center"
                render={(text, record) =>
                  record.fromDismemberLot ? (
                    <Icon
                      type="check-circle"
                      theme="filled"
                      className="iconCheck"
                    />
                  ) : null
                }
              />
              <Column
                title={translation.movementHistory.table.columns.fileName}
                dataIndex="fileName"
                key="fileName"
                align="left"
                {...getColumnSearchProps("fileName")}
              />
              <Column
                align="center"
                title={translation.boitelSaleScenario.table.column.actions}
                render={(text, record) =>
                  record.showDeleteButton && (
                    <ButtonStandard
                      buttonType="typeWithoutBackground"
                      style={{ marginLeft: 10 }}
                      onClick={() => handleDelete(record.id)}
                    >
                      <TrashIcon />
                    </ButtonStandard>
                  )
                }
              />
            </Table>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default MovementHistory;
