import { notification } from "antd";
import moment from "moment";

function validateForm(form, female) {
  const hasErrors = [];

  if (form.diagnosisDate === null) {
    hasErrors.push("pregnancyDiagnosisExecutionDate");
  }

  // Check if female is VAZIA or is a DG for MC/REPASSE
  if (
    (female.femaleSituation === "vazia" ||
      form.animalCoverageType === "MC/REPASSE") &&
    form.result === 1
  ) {
    if (form.gestationDays === null) {
      hasErrors.push("gestationDays");
    }
  }

  // Check result
  if (form.result === null || (form.result !== 1 && form.result !== 2)) {
    if (form.result !== 1 && form.result !== 2) {
      notification.error({
        message: "Informe um resultado válido: Positivo ou Negativo",
      });
    }
    hasErrors.push("pregnancyDiagnosisResult");
  }

  // Check if ovarian result is null
  if (form.ovarianEvaluationResult === null) {
    notification.error({
      message: "Informe um resultado para a Avaliação Ovariana",
    });
    hasErrors.push("registerOvarianEvaluation");
  }

  // Check if need to select coverage
  if (
    female.femaleSituation !== "vazia" &&
    form.animalCoverageId == null &&
    female.lastCoverages &&
    female.lastCoverages?.length > 1 &&
    form.result === 1
  ) {
    notification.error({
      message: 'Selecione uma cobertura através do botão "Definir Cobertura".',
    });
    hasErrors.push("animalCoverageId");
  }

  // Check if female is vazia but have a last diagnosis negative, compare if new coverage date is before lastCoverageDate
  if (
    female.femaleSituation === "vazia" &&
    female.lastCoverageDate != null &&
    form.result === 1
  ) {
    const newLastCoverageDate = moment(form.diagnosisDate).subtract(
      form.gestationDays,
      "days"
    );
    if (moment(female.lastCoverageDate).isAfter(newLastCoverageDate, "days")) {
      notification.error({
        message:
          "Atenção! Tempo de Gestação informado inconsistente, por compreender período em que a fêmea já possui cobertura(s) com resultado(s).",
      });
      hasErrors.push("gestationDays");
    }
  }

  // Check if female has a pregnancy diagnosis in same date
  if (
    female.lastCoverageDiagnosisDate != null &&
    moment(female.lastCoverageDiagnosisDate).isSame(
      moment(form.diagnosisDate),
      "dates"
    )
  ) {
    notification.error({
      message:
        "Atenção! Já existe um diagnóstico de gestação na mesma data selecionada.",
    });
    hasErrors.push("diagnosisDate");
  }

  return hasErrors.length === 0;
}

async function validateSelectFemale(animal, reproductionParameters) {
  return new Promise((resolve, reject) => {
    const periodAfterCoverage = reproductionParameters
      ? Number(reproductionParameters["TEMPO_APOS_COBERTURA_PARA_DG"])
      : 0;
    // Check if is Male, if so dont continue the process
    if (animal.gender === "M") {
      reject("DG-ERROR-MALE");
    }
    // Check if allowFattenFemale = TRUE and ANIMAL IS FATTEN
    else if (animal.animalFarmFunction === "fatten") {
      reject("DG-CHECK-FEMALE-FATTEN");
    }
    // If female is REPRODUCTION and is VAZIA
    else if (
      animal.animalFarmFunction === "reproduction" &&
      animal.femaleSituation === "vazia"
    ) {
      reject("DG-CHECK-FEMALE-VAZIA");
    }
    // If female is REPRODUCTION and is SERVIDA and LAST COVERAGE is not null
    // Check if female have a coverage with diff days bigger than param "TEMPO_APOS_COBERTURA_PARA_DG"
    else if (
      animal.animalFarmFunction === "reproduction" &&
      animal.femaleSituation === "servida" &&
      animal.lastCoverageDate != null
    ) {
      // Check if female have a coverage with diff days bigger than param "TEMPO_APOS_COBERTURA_PARA_DG"
      const diffBetweenNowAndLastCoverage = moment().diff(
        moment(animal.lastCoverageDate),
        "days"
      );
      if (diffBetweenNowAndLastCoverage < periodAfterCoverage) {
        reject("DG-CHECK-FEMALE-LAST-COVERAGE-TIME");
      }
    }
    resolve("OK");
  });
}

export { validateSelectFemale, validateForm };
