import React, { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Input, notification, Spin } from "antd";
import ButtonStandard from "../../../utils/button";
import NumberFormat from "react-number-format";
import moment from "moment";
import { Container, Footer } from "./styles";

const DrawerBenchmarkHarvestKpis = ({
  data,
  kpis,
  harvest,
  onSave,
  onCancel,
  drawerVisible,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState([]);
  const [kpiCapacity, setKpiCapacity] = useState(null);
  const [kpiProduction, setKpiProduction] = useState(null);
  const [kpiCost, setKpiCost] = useState(null);
  const [kpiMargin, setKpiMargin] = useState(null);
  const [kpiGDP, setKpiGDP] = useState(null);
  const [kpiEnjoy, setKpiEnjoy] = useState(null);
  const [errors, setErrors] = useState([]);
  const { translation } = useSelector((state) => state.app);

  function handleOpenDrawer(visible) {
    if (visible) {
      setIsLoading(true);
      setErrors([]);
      setForm(data);
      setKpiCapacity(kpis.find((k) => k.name === "LOTACAO"));
      setKpiProduction(kpis.find((k) => k.name === "PRODUCAO"));
      setKpiCost(kpis.find((k) => k.name === "CUSTO"));
      setKpiMargin(kpis.find((k) => k.name === "MARGEM"));
      setKpiGDP(kpis.find((k) => k.name === "GDP"));
      setKpiEnjoy(kpis.find((k) => k.name === "DESFRUTE"));
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } else {
      setForm([]);
    }
  }

  function onSubmit() {
    let data = form;
    /* Validate fields */
    let submitErrors = [];

    if (data.length <= 0) {
      notification.error({
        title: "Dados incorretos",
        message: translation.benchmark.errorAtLeastOneKPIValue,
      });
    } else {
      if (submitErrors.length === 0) {
        onSave(data);
      }
    }
    setErrors(submitErrors);
  }

  function handleValueChange(
    kpi,
    productionSubSystem,
    productionSystem,
    kpiValue
  ) {
    let benchKpi = form.find(
      (k) =>
        k.kpi.id === kpi.id &&
        k.productionSubSystem === productionSubSystem.code &&
        k.productionSystem === productionSystem.code
    );

    if (benchKpi == null && kpiValue != null && kpiValue > 0) {
      let benchKpi = {
        kpi,
        productionSubSystem: productionSubSystem.code,
        productionSystem: productionSystem.code,
        harvest,
        kpiValue,
      };
      form.push(benchKpi);
    } else if (kpiValue != null && kpiValue > 0) {
      benchKpi.kpiValue = kpiValue;
    } else {
      setForm(form.filter((k) => k !== benchKpi));
    }
  }

  const productionSubSystems = [
    {
      code: "Extensive",
      name: translation.productionSubSystem.extensive,
    },
    {
      code: "Intensive",
      name: translation.productionSubSystem.intensive,
    },
    {
      code: "SemiIntensive",
      name: translation.productionSubSystem.semiIntensive,
    },
  ];

  const productionSystems = [
    {
      code: "Fatten",
      name: translation.productionSystem.fatten,
    },
    // {
    //   code: "Create",
    //   name: translation.productionSystem.create
    // }
  ];

  return (
    <Container
      title={
        "Safra " +
        moment(harvest?.startDate, "YYYY-MM-DD").format("DD/MM/YYYY") +
        " - " +
        moment(harvest?.endDate, "YYYY-MM-DD").format("DD/MM/YYYY")
      }
      width={600}
      onClose={onCancel}
      maskClosable={false}
      visible={drawerVisible}
      afterVisibleChange={handleOpenDrawer}
    >
      {/* Body */}
      <div className="drawerForm">
        <Spin spinning={isLoading}>
          {errors.length > 0 && (
            <Row type="flex" justify="center" align="middle">
              <label className="error">{translation.error.formError}</label>
            </Row>
          )}
          {productionSystems.map((ps) => (
            <Fragment key={ps.code}>
              <Row type="flex" justify="start">
                <div className="card">
                  <span className="title-purple">
                    Sistema: <b>{ps.name}</b>
                  </span>
                </div>
              </Row>
              <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  {productionSubSystems.map((pss) => (
                    <Fragment key={pss.code}>
                      <Row style={{ marginTop: "13px" }}>
                        <label>{pss.name}</label>
                      </Row>
                      <Row className="rowInput">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <NumberFormat
                            customInput={Input}
                            placeholder={translation.defaultPlaceholder}
                            value={
                              form.find(
                                (h) =>
                                  h.kpi.id === kpiCapacity?.id &&
                                  h.productionSubSystem === pss.code &&
                                  h.productionSystem === ps.code
                              )?.kpiValue || null
                            }
                            name="minimumVoluntaryConsumption"
                            addonBefore={translation.kpi.capacity}
                            addonAfter={
                              pss.code === "Intensive"
                                ? "%"
                                : kpiCapacity?.valueType
                            }
                            onValueChange={({ floatValue }) => {
                              handleValueChange(
                                kpiCapacity,
                                pss,
                                ps,
                                floatValue
                              );
                            }}
                          />
                        </Col>
                        {pss.code !== "Intensive" && (
                          <Col
                            xs={11}
                            sm={11}
                            md={11}
                            lg={11}
                            xl={11}
                            offset={1}
                          >
                            <NumberFormat
                              customInput={Input}
                              placeholder={translation.defaultPlaceholder}
                              value={
                                form.find(
                                  (h) =>
                                    h.kpi.id === kpiProduction.id &&
                                    h.productionSubSystem === pss.code &&
                                    h.productionSystem === ps.code
                                )?.kpiValue || null
                              }
                              name="maximumVoluntaryConsumption"
                              addonBefore={translation.kpi.production}
                              addonAfter={kpiProduction?.valueType}
                              onValueChange={({ floatValue }) => {
                                handleValueChange(
                                  kpiProduction,
                                  pss,
                                  ps,
                                  floatValue
                                );
                              }}
                            />
                          </Col>
                        )}
                      </Row>
                      <Row className="rowInput">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <NumberFormat
                            customInput={Input}
                            placeholder={translation.defaultPlaceholder}
                            value={
                              form.find(
                                (h) =>
                                  h.kpi.id === kpiCost?.id &&
                                  h.productionSubSystem === pss.code &&
                                  h.productionSystem === ps.code
                              )?.kpiValue || null
                            }
                            name="minimumVoluntaryConsumption"
                            addonBefore={translation.kpi.cost}
                            addonAfter={"R$" + kpiCost?.valueType}
                            onValueChange={({ floatValue }) => {
                              handleValueChange(kpiCost, pss, ps, floatValue);
                            }}
                          />
                        </Col>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
                          <NumberFormat
                            customInput={Input}
                            placeholder={translation.defaultPlaceholder}
                            value={
                              form.find(
                                (h) =>
                                  h.kpi.id === kpiMargin.id &&
                                  h.productionSubSystem === pss.code &&
                                  h.productionSystem === ps.code
                              )?.kpiValue || null
                            }
                            name="maximumVoluntaryConsumption"
                            addonBefore={translation.kpi.margin}
                            addonAfter={"R$" + kpiMargin?.valueType}
                            onValueChange={({ floatValue }) => {
                              handleValueChange(kpiMargin, pss, ps, floatValue);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="rowInput">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <NumberFormat
                            customInput={Input}
                            placeholder={translation.defaultPlaceholder}
                            value={
                              form.find(
                                (h) =>
                                  h.kpi.id === kpiGDP?.id &&
                                  h.productionSubSystem === pss.code &&
                                  h.productionSystem === ps.code
                              )?.kpiValue || null
                            }
                            name="minimumVoluntaryConsumption"
                            addonBefore={translation.kpi.gdp}
                            addonAfter={kpiGDP?.valueType}
                            onValueChange={({ floatValue }) => {
                              handleValueChange(kpiGDP, pss, ps, floatValue);
                            }}
                          />
                        </Col>
                        {pss.code !== "Intensive" && (
                          <Col
                            xs={11}
                            sm={11}
                            md={11}
                            lg={11}
                            xl={11}
                            offset={1}
                          >
                            <NumberFormat
                              customInput={Input}
                              placeholder={translation.defaultPlaceholder}
                              value={
                                form.find(
                                  (h) =>
                                    h.kpi.id === kpiEnjoy.id &&
                                    h.productionSubSystem === pss.code &&
                                    h.productionSystem === ps.code
                                )?.kpiValue || null
                              }
                              name="maximumVoluntaryConsumption"
                              addonBefore={translation.kpi.enjoy}
                              addonAfter={kpiEnjoy?.valueType}
                              onValueChange={({ floatValue }) => {
                                handleValueChange(
                                  kpiEnjoy,
                                  pss,
                                  ps,
                                  floatValue
                                );
                              }}
                            />
                          </Col>
                        )}
                      </Row>
                    </Fragment>
                  ))}
                </Col>
              </Row>
            </Fragment>
          ))}
        </Spin>
      </div>
      {/* Footer */}
      <Footer>
        <Row type="flex" justify="space-between">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="selectDiv"
          ></Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="buttonsDiv">
            <ButtonStandard type="button" buttonType="type7" onClick={onCancel}>
              {translation.buttons.cancel}
            </ButtonStandard>

            <ButtonStandard
              type="submit"
              buttonType="type6"
              onClick={onSubmit}
              disabled={errors.length > 0 ? true : false}
            >
              {translation.buttons.save}
            </ButtonStandard>
          </Col>
        </Row>
      </Footer>
    </Container>
  );
};

export default DrawerBenchmarkHarvestKpis;
