import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
/** Redux libs */
import { useSelector, useDispatch } from "react-redux";
import { Creators as SupplierActions } from "../../../../../store/ducks/supplier";
/** Components */
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import {
  Container,
  ColSelectLots,
  ColButtons,
  ColLotsSelected,
} from "./styles";
import { Row, Col, List, Checkbox, Table, Select } from "antd";
import useBoitelSaleScnearioNewContext from "../../../../../hooks/useBoitelSaleScnearioNewContext";
/** Services */
import { getLotIndexByAnimalSupplier } from "../../../../../services/lotService";
import { getDietStrategyIndex } from "../../../../../services/dietStrategyService";
import {
  numberMask,
  validateDietStrategyValidation,
} from "../../../../../services/helpersMethodsService";

const TabBoitelSaleScenarioNewSelectLots = () => {
  /** Variables */
  const [loadingTable, setLoadingTable] = useState(false);

  /** Redux variables */
  const {
    listDrowpDown: suppliers,
    isLoadingDropDown: isLoadingDropDownSuppliers,
  } = useSelector((state) => state.supplier);
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
    translation,
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  /** Context */
  const {
    form,
    setForm,
    supplierId,
    setSupplierId,
    allLots,
    setAllLots,
    allDietStrategies,
    setAllDietStrategies,
    lotsSelected,
    setLotsSelected,
    lotsSelectedKeys,
    setLotsSelectedKeys,
    lotsSelectForLotProduction,
    setLotsSelectForLotProduction,
    lotsSelectForLotProductionKeys,
    setLotsSelectForLotProductionKeys,
    lotsToRemoveKeys,
    setLotsToRemoveKeys,
    selectAllLotsForProduction,
    setSelectAllLotsForProduction,
  } = useBoitelSaleScnearioNewContext();

  /** Methods */
  function findBaselineFromLotDietStrategies(lotDietStrategies) {
    return lotDietStrategies.find((l) => l.baseline === true) != null
      ? lotDietStrategies.find((l) => l.baseline === true).dietStrategyId
      : null;
  }
  // Method to get lots by animal supplier
  async function fetchData(supplierId = null) {
    setLoadingTable(true);
    try {
      const {
        data: { results: lotResults },
      } = await getLotIndexByAnimalSupplier({
        groupId,
        farmId,
        supplierId,
      });
      let newContent = [];
      lotResults.forEach((l) => {
        const dietStrategyBaseLineId = findBaselineFromLotDietStrategies(
          l.dietStrategies
        );
        let newDietStrategies = [];
        allDietStrategies.forEach((ds) => {
          let newDs = { ...ds };
          if (
            newDs.status === "Active" &&
            (newDs.lot?.id === l.id ||
              (newDs.lot === null &&
                newDs.breedId === l.lotBreedId &&
                newDs.cap === l.lotCap &&
                newDs.gender === l.lotGender)) &&
            !validateDietStrategyValidation(newDs)
          ) {
            if (newDs.id === dietStrategyBaseLineId) {
              newDs.baseline = true;
              newDs.check = true; // This variable will be responsable to control if this ds was selected in the next step
            } else {
              newDs.baseline = false;
              newDs.check = false; // This variable will be responsable to control if this ds was selected in the next step
            }
            newDs.newKey = uuid();
            newDietStrategies.push(newDs);
          }
        });
        l.dietStrategies = newDietStrategies;
        newContent.push(l);
      });
      setAllLots(newContent);
      setLoadingTable(false);
    } catch (error) {
      setAllLots(null);
    }
  }
  // Method to pass lot from left to right (Add) and right to left (Remove)
  function handleMoveLots(action) {
    switch (action) {
      case "add":
        const lots = lotsSelected.map((l) => ({
          supplierId: l.supplierId,
          superKey: l.superKey,
          lot: l,
          lotId: l.id,
          dietStrategies: l.dietStrategies,
        }));
        setLotsSelectForLotProduction(lots);
        setLotsSelectForLotProductionKeys(
          lotsSelected.map((l) => ({
            supplierId: l.supplierId,
            superKey: l.superKey,
            lotId: l.id,
          }))
        );
        setSelectAllLotsForProduction(false);
        setForm((old) => ({ ...old, parameter: { ...old.parameter, lots } }));
        break;
      case "remove":
        let removedLotsSelectForLotProduction =
          lotsSelectForLotProduction.filter(
            (obj) => !lotsToRemoveKeys.includes(obj.superKey)
          );
        let removedLotsSelectForLotProductionKeys =
          lotsSelectForLotProductionKeys.filter(
            (obj) => !lotsToRemoveKeys.includes(obj.superKey)
          );
        let removedSelectedLots = lotsSelected.filter(
          (obj) => !lotsToRemoveKeys.includes(obj.superKey)
        );
        let removedSelectedLotsKeys = lotsSelectedKeys.filter(
          (obj) => !lotsToRemoveKeys.includes(obj)
        );
        setLotsSelectForLotProduction(removedLotsSelectForLotProduction);
        setLotsSelectForLotProductionKeys(
          removedLotsSelectForLotProductionKeys
        );
        setLotsSelected(removedSelectedLots);
        setLotsSelectedKeys(removedSelectedLotsKeys);
        setLotsToRemoveKeys([]);
        setSelectAllLotsForProduction(false);
        setForm((old) => ({
          ...old,
          parameter: {
            ...old.parameter,
            lots: removedLotsSelectForLotProduction,
          },
        }));
        break;
      default:
        break;
    }
  }
  // Method to check lot in right list
  function handleSelectLotsForProduction(e) {
    if (e.target.checked)
      setLotsToRemoveKeys([...lotsToRemoveKeys, e.target.value]);
    else
      setLotsToRemoveKeys(lotsToRemoveKeys.filter((i) => i !== e.target.value));
  }
  // Method to select all lots in right list
  function handleSelectAllLotsForProduction(e) {
    setSelectAllLotsForProduction(e.target.checked);
    if (e.target.checked)
      setLotsToRemoveKeys(
        lotsSelectForLotProductionKeys.map((lKey) => lKey.superKey)
      );
    else setLotsToRemoveKeys([]);
  }
  // Method to select client
  function handleSelectClient(value) {
    setSupplierId(value);
    fetchData(value);
    setLotsSelected([]);
    setLotsSelectedKeys([]);
    setLotsToRemoveKeys([]);
    setLotsSelectForLotProduction([]);
    setLotsSelectForLotProductionKeys([]);
    setSelectAllLotsForProduction(false);
    setForm((old) => ({ ...old, parameter: { ...old.parameter, lots: [] } }));
  }

  /* Table variables */
  const rowSelection = {
    selectedRowKeys: lotsSelectedKeys,
    getCheckboxProps: (record) => ({
      disabled:
        lotsSelectForLotProductionKeys.findIndex(
          (l) => l.superKey === record.superKey
        ) >= 0,
    }),
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      if (selected) {
        setLotsSelected([...lotsSelected, record]);
        setLotsSelectedKeys([...lotsSelectedKeys, record.superKey]);
      } else {
        setLotsSelected(
          lotsSelected.filter((o) => o.superKey !== record.superKey)
        );
        setLotsSelectedKeys(
          lotsSelectedKeys.filter((r) => r !== record.superKey)
        );
      }
    },
    onSelectAll: (record, selected, selectedRows, nativeEvent) => {
      // Get just the ids
      const keys = selectedRows.map((sr) => sr.superKey);
      // Get all the selectedLots unless the unselected
      const lastSelectedLots = lotsSelected.filter(
        (sA) => !selectedRows.includes(sA)
      );
      // Get all the selectedLotsKeys unless the unselected
      const lastSelectedLotsKeys = lotsSelectedKeys.filter(
        (sAK) => !keys.includes(sAK)
      );

      if (selected.length > 0) {
        setLotsSelected(
          lotsSelected.length > 0
            ? lastSelectedLots
            : [...lotsSelected, ...selectedRows]
        );
        setLotsSelectedKeys(
          lotsSelectedKeys.length > 0
            ? lastSelectedLotsKeys
            : [...lotsSelectedKeys, ...keys]
        );
      } else {
        setLotsSelected(lastSelectedLots);
        setLotsSelectedKeys(lastSelectedLotsKeys);
      }
    },
  };

  const TableLots = (
    <Table
      key="recpt"
      rowKey="superKey"
      loading={loadingTable}
      dataSource={allLots != null ? allLots : []}
      pagination={{
        defaultPageSize: 10,
        hideOnSinglePage: true,
      }}
      scroll={{
        x: true,
      }}
      size="small"
      rowSelection={rowSelection}
    >
      <Table.Column title="Nome" dataIndex="lotName" key="lotName" />
      <Table.Column
        title="Qtd."
        dataIndex="amountAnimals"
        key="amountAnimals"
        render={(value) => <span>{`${value} animais`}</span>}
      />
      <Table.Column
        title="Idade"
        dataIndex="averageAge"
        key="averageAge"
        render={(value) => <span>{`${value} meses`}</span>}
      />
      <Table.Column
        title="Peso médio"
        dataIndex="averageLastDailyWeight"
        key="averageLastDailyWeight"
        render={(value) => <span>{`${numberMask(value, false)} kg`}</span>}
      />
    </Table>
  );

  /** Effect  */

  // Load lotsSelectForLotProduction
  useEffect(() => {
    setLotsSelectForLotProduction(form?.parameter?.lots);
  }, [form, setLotsSelectForLotProduction]);

  // Get DietStragies and Suppliers CattleFarmer
  useEffect(() => {
    async function getSuppliers() {
      try {
        const {
          data: { results: rAllDietStrategies },
        } = await getDietStrategyIndex({
          groupId,
          farmId,
          withoutPagination: true,
        });
        setAllDietStrategies(rAllDietStrategies);
        dispatch(
          SupplierActions.getDropdownSuppliers(
            groupId,
            farmId,
            "CattleFarmer",
            true
          )
        );
      } catch (error) {}
    }
    getSuppliers();
  }, [groupId, farmId, dispatch, setAllDietStrategies]);
  return (
    <Container>
      <Row type="flex" style={{ marginBottom: 24 }} align="middle">
        <strong className="subTitle">
          {translation.boitelSaleScenario.new.tabs.lots.title}
        </strong>
      </Row>
      <Row type="flex" style={{ marginBottom: 24 }}>
        <Col xs={24} sm={24} md={24} lg={14} xl={14}>
          <Row>
            <label>Cliente</label>
          </Row>
          <Row>
            <Select
              style={{
                width: "80%",
              }}
              value={
                supplierId != null
                  ? suppliers != null &&
                    isLoadingDropDownSuppliers === false &&
                    suppliers.find((s) => s.id === supplierId) != null
                    ? supplierId
                    : undefined
                  : undefined
              }
              loading={isLoadingDropDownSuppliers}
              showSearch
              placeholder={
                translation.lot.receipt.supplier.supplierComboBoxPlaceHolder
              }
              name="supplierId"
              onChange={(value) => {
                handleSelectClient(value);
              }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {suppliers != null &&
                Object.entries(suppliers).length > 0 &&
                suppliers.map((supplier) => (
                  <Select.Option key={supplier.id} value={supplier.id}>
                    {supplier.name}
                  </Select.Option>
                ))}
            </Select>
          </Row>
        </Col>
      </Row>
      <Row type="flex">
        {/* Col with lots table */}
        <Col xs={24} sm={24} md={24} lg={14} xl={14}>
          <ColSelectLots>
            <Row type="flex">
              <Col span={12}>
                <span className="subTitle">Lotes disponíveis</span>
              </Col>
            </Row>
            {TableLots}
          </ColSelectLots>
        </Col>
        {/* Col with buttons */}
        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
          <ColButtons>
            <button
              className={
                lotsSelectedKeys.length > lotsSelectForLotProductionKeys.length
                  ? "active"
                  : ""
              }
              onClick={() => handleMoveLots("add")}
              disabled={
                lotsSelectedKeys.length > lotsSelectForLotProductionKeys.length
                  ? false
                  : true
              }
            >
              <FaChevronRight />
            </button>
            <button
              className={lotsToRemoveKeys.length > 0 ? "active" : ""}
              onClick={() => handleMoveLots("remove")}
              disabled={lotsToRemoveKeys.length > 0 ? false : true}
            >
              <FaChevronLeft />
            </button>
          </ColButtons>
        </Col>
        {/* Col with list with lots selected */}
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <ColLotsSelected>
            <div className="header">
              <span>Lotes selecionados</span>
              <span>{`${lotsSelectForLotProduction.length} lotes`}</span>
            </div>
            <Row
              type="flex"
              align="middle"
              style={{ borderBottom: "1px solid #e8e8e8", padding: "12px 0" }}
            >
              <Checkbox
                style={{
                  marginRight: 8,
                }}
                onChange={handleSelectAllLotsForProduction}
                disabled={lotsSelectForLotProduction.length > 0 ? false : true}
                checked={selectAllLotsForProduction}
              ></Checkbox>
              <strong>Nome</strong>
            </Row>
            <Row type="flex" style={{ width: "100%" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <List
                  itemLayout="horizontal"
                  dataSource={lotsSelectForLotProduction}
                  renderItem={(item) => (
                    <List.Item>
                      <Checkbox
                        style={{
                          marginLeft: "12px",
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "Asap",
                          color: "#4B4B4B",
                        }}
                        key={item.superKey}
                        value={item.superKey}
                        onChange={handleSelectLotsForProduction}
                        checked={
                          lotsToRemoveKeys.includes(item.superKey)
                            ? true
                            : false
                        }
                      >
                        {`${item.lot.lotName} / ${item.lot.supplierName} / ${item.lot.amountAnimals} animais`}
                      </Checkbox>
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </ColLotsSelected>
        </Col>
      </Row>
    </Container>
  );
};

export default TabBoitelSaleScenarioNewSelectLots;
