import styled from "styled-components";
import { Divider, Drawer } from "antd";

export const Container = styled(Drawer)`
  p {
    margin: 0;
  }

  .ant-drawer-body {
    padding: 24px 0px 10px 0px;
  }

  .ant-row,
  .ant-row-flex {
    width: 100%;
  }

  .drawerForm {
    width: 100%;
    padding: 0px 24px;
    min-height: 600px;
  }

  .rowLabel {
    margin-bottom: 7px;
    margin-top: 19px;
  }

  .info-box {
    display: flex;
    justify-content: space-between;
    p {
      margin-bottom: 0px;
    }
    b {
      color: #707070;
    }
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    margin-bottom: 7px;
    margin-top: 19px;
    &.error {
      color: #d44c4c;
      &:first-of-type {
        margin-top: 10px;
        margin-left: 0px;
      }
    }
  }

  .ant-row.rowInput {
    margin-top: 8px;
  }

  .tableButtonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      display: flex;
      justify-content: center !important;
      text-align: center !important;
      align-items: center !important;
      font-size: 14px !important;
      width: 77px !important;
      height: 30px !important;

      svg {
        margin-right: 3px !important;
      }
    }

    button:first-of-type {
      width: 116px !important;
      margin-right: 12px !important;
    }
  }

  .ant-switch-checked {
    background-color: #a9c133 !important;
  }

  .status {
    padding: 2px;
    width: 95px;
    height: 26px;
    text-align: center;
    border-radius: 7px;
    font-size: 14px !important;
  }
`;

export const CustomDivider = styled(Divider)`
  margin: 10px 0 !important;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 10px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;

export const Assessing = styled.div`
  width: 95px;
  height: 26px;
  text-align: center;
  border-radius: 7px;
`;

export const CardShadow = styled.div`
  width: 100%;
  height: auto;
  margin: 15px 0px;
  padding: 15px 13px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 3px 6px #d8d8d8;

  p {
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .title {
    color: #c4c4c4;
    text-transform: uppercase;
    font-weight: bold;
  }
`;

export const CardDiet = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 85px;
  margin: 0px 6px 8px 5px;
  padding: 5px;
  border-radius: 5px;
  background: #f5f5f5;
  text-align: center;
  cursor: pointer;

  &:hover {
    background: #ddd;
  }

  p {
    margin-bottom: 0px;
    color: #707070;
    font-weight: bold;
  }
`;
