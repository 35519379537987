import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as HandlingOperationActions } from "../../../store/ducks/handlingOperation";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Row,
  Col,
  Input,
  Select,
  Switch,
  Checkbox,
  Radio,
  notification,
} from "antd";
import ButtonStandard from "../../utils/button";
import { Container, Footer, CustomDivider } from "./styles";
import NumberInput from "../../utils/input/numberInput";
import { getBreedIndexActive } from "../../../services/breedService";
import { getHandlingActionIndex } from "../../../services/handlingActionService";

class DrawerHandlingOperation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formHandlingOperation: {
        id: null,
        name: null,
        handlingAction: null,
        breeds: [],
        maxAge: null,
        minAge: null,
        recurrenceAmount: null,
        redoFromAmount: null,
        redoToAmount: null,
        quarantineAmount: null,
        maxWeight: null,
        minWeight: null,
        inQuarantine: false,
        inPregnancy: false,
        haveCost: true,
        status: "Active",
      },
      breeds: null,
      actions: null,
      saveOptions: "only_save",
    };
  }

  fetchData = async () => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;

    const {
      data: { results: breeds },
    } = await getBreedIndexActive({
      groupId,
      farmId,
      withoutBreedCurveParam: true,
    });

    const {
      data: { results: actions },
    } = await getHandlingActionIndex({});

    this.setState({ breeds, actions });
  };

  closeDrawer = () => {
    const { handlingOperationActions } = this.props;
    handlingOperationActions.hideDrawer();
  };

  openNotificationError = (title, message) => {
    notification["error"]({
      message: title,
      description: message,
    });
  };

  handleSubmitModalForm = async (values, actions) => {
    const {
      app: { translation },
    } = this.props;
    const { recurrenceAmount, redoFromAmount, redoToAmount } = values;
    if (
      recurrenceAmount != null &&
      recurrenceAmount > 0 &&
      (redoFromAmount == null ||
        redoFromAmount === 0 ||
        redoToAmount == null ||
        redoToAmount === 0)
    ) {
      this.openNotificationError(
        translation.handlingOperation.errors.recurrenceMinAndMaxDayCanBeNull
          .title,
        translation.handlingOperation.errors.recurrenceMinAndMaxDayCanBeNull
          .message
      );
    } else if (
      (recurrenceAmount == null || recurrenceAmount === 0) &&
      (redoFromAmount > 0 || redoToAmount > 0)
    ) {
      this.openNotificationError(
        translation.handlingOperation.errors.recurrenceQtdCanBeNull.title,
        translation.handlingOperation.errors.recurrenceQtdCanBeNull.message
      );
    } else {
      if (values.id === null) {
        await this.handleNewHandlingOperation(values);
      } else {
        await this.handleUpdateHandlingOperation(values, values.id);
      }
      actions.resetForm({
        id: null,
        name: null,
        handlingAction: null,
        breeds: [],
        maxAge: null,
        minAge: null,
        recurrenceAmount: null,
        redoFromAmount: null,
        redoToAmount: null,
        quarantineAmount: null,
        maxWeight: null,
        minWeight: null,
        inQuarantine: false,
        inPregnancy: false,
        haveCost: true,
        status: "Active",
      });
      this.setState({
        formHandlingOperation: {
          id: null,
          name: null,
          handlingAction: null,
          breeds: [],
          maxAge: null,
          minAge: null,
          recurrenceAmount: null,
          redoFromAmount: null,
          redoToAmount: null,
          quarantineAmount: null,
          maxWeight: null,
          minWeight: null,
          inQuarantine: false,
          inPregnancy: false,
          haveCost: true,
          status: "Active",
        },
      });
    }
  };

  handleNewHandlingOperation = async (handlingOperation) => {
    const {
      app: {
        groupSelected: { id: groupId },
      },
      handlingOperationActions,
    } = this.props;
    let saveOptions = this.state.saveOptions === "only_save" ? false : true;
    await handlingOperationActions.saveHandlingOperation(
      groupId,
      null,
      handlingOperation,
      saveOptions
    );
  };

  handleUpdateHandlingOperation = async (handlingOperation, id) => {
    const {
      app: {
        groupSelected: { id: groupId },
      },
      handlingOperationActions,
    } = this.props;
    let saveOptions = this.state.saveOptions === "only_save" ? false : true;
    await handlingOperationActions.saveHandlingOperation(
      groupId,
      id,
      handlingOperation,
      saveOptions
    );
  };

  handleSelectBreed = (value, props) => {
    const { formHandlingOperation } = this.state;

    let selectedArray = [];

    value.map((selected) =>
      selectedArray.push({
        id: selected,
      })
    );

    props.setValues({
      ...props.values,
      breeds: selectedArray,
    });

    this.setState({
      formHandlingOperation: {
        ...formHandlingOperation,
        breeds: selectedArray,
      },
    });
  };

  handleDrawerVisible = async (visible) => {
    if (visible) {
      await this.fetchData();
    }
  };

  render() {
    const {
      app: { translation },
      handlingOperation: { handlingOperationData, drawerVisible },
    } = this.props;

    const { formHandlingOperation, breeds, actions } = this.state;

    const validationSchema = Yup.object().shape({
      name: Yup.string().required(),
      handlingAction: Yup.string().required(),
    });

    const { Option } = Select;

    const { Group: InputGroup } = Input;

    return (
      <Container
        title={
          handlingOperationData != null
            ? translation.handlingOperation.drawerTitleEdit
            : translation.handlingOperation.drawerTitle
        }
        width={720}
        onClose={this.closeDrawer}
        maskClosable={false}
        visible={drawerVisible}
        afterVisibleChange={this.handleDrawerVisible}
      >
        <Formik
          enableReinitialize={true}
          initialValues={
            handlingOperationData != null
              ? handlingOperationData
              : formHandlingOperation
          }
          onSubmit={this.handleSubmitModalForm}
          validationSchema={validationSchema}
          render={(props) => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <div className="drawerForm">
                <Row type="flex">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <span style={{ fontSize: "14px", color: "#000" }}>
                      {handlingOperationData != null
                        ? translation.handlingOperation.drawerSubTitleEdit
                        : translation.handlingOperation.drawerSubTitle}
                    </span>
                  </Col>
                </Row>
                {handlingOperationData != null ? (
                  <Row
                    type="flex"
                    className="rowForm"
                    justify="space-between"
                    align="middle"
                  >
                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                      <label
                        style={{
                          color: "#9074bf",
                          fontWeight: "bold",
                        }}
                      >
                        {translation.handlingOperation.form.status}
                      </label>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6} align="right">
                      {props.values.status === "Active" ? (
                        <span
                          style={{
                            color: "#A9C133",
                            fontFamily: "Asap",
                            fontWeight: "normal",
                            marginRight: 6,
                          }}
                        >
                          {translation.status.active}
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "#D44C4C",
                            fontFamily: "Asap",
                            fontWeight: "normal",
                            marginRight: 6,
                          }}
                        >
                          {translation.status.inactive}
                        </span>
                      )}
                      <Switch
                        checked={
                          props.values.status === "Active" ? true : false
                        }
                        onChange={(checked, event) => {
                          props.setValues({
                            ...props.values,
                            status: checked ? "Active" : "Inactive",
                          });
                        }}
                      />
                    </Col>
                  </Row>
                ) : null}
                {Object.entries(props.errors).length > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                {/* Name and HandlingAction */}
                <Row type="flex" className="rowForm" align="middle">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row>
                      <label className={props.errors.name ? "error" : ""}>
                        {translation.handlingOperation.form.name}*
                      </label>
                    </Row>
                    <Row>
                      <Input
                        placeholder={
                          translation.handlingOperation.form.namePlaceHolder
                        }
                        value={props.values.name}
                        name="name"
                        autoComplete="chrome-off"
                        onChange={(e) => {
                          props.setValues({
                            ...props.values,
                            name: e.target.value,
                          });
                          this.setState({
                            formHandlingOperation: {
                              ...formHandlingOperation,
                              name: e.target.value,
                            },
                          });
                        }}
                      />
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={11} lg={11} xl={11} offset={1}>
                    <Row>
                      <label
                        className={props.errors.handlingAction ? "error" : ""}
                      >
                        {translation.handlingOperation.form.action}*
                      </label>
                    </Row>
                    <Row>
                      <Select
                        name="handlingAction"
                        value={
                          actions != null && props.values.handlingAction != null
                            ? props.values.handlingAction.id
                            : undefined
                        }
                        style={{ width: "100%" }}
                        placeholder={
                          translation.handlingOperation.form.actionPlaceHolder
                        }
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          props.setValues({
                            ...props.values,
                            handlingAction: { id: value },
                          });
                          this.setState({
                            formHandlingOperation: {
                              ...formHandlingOperation,
                              handlingAction: { id: value },
                            },
                          });
                        }}
                      >
                        {actions != null &&
                          actions.map(
                            (a) =>
                              a.status === "Active" && (
                                <Option key={a.id} value={a.id}>
                                  {a.name}
                                </Option>
                              )
                          )}
                      </Select>
                    </Row>
                  </Col>
                </Row>
                <CustomDivider>
                  {translation.handlingOperation.parameters}
                </CustomDivider>
                {/* Recurrence and Quarantine */}
                <Row type="flex" className="rowForm" align="middle">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row justify="space-between">
                      <Col span={12}>
                        <label>
                          {translation.handlingOperation.form.recurrence}
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <InputGroup compact>
                        <NumberInput
                          placeholder={
                            translation.handlingOperation.form.recurrenceAmount
                          }
                          value={
                            props.values.recurrenceAmount > 0 &&
                            props.values.recurrenceAmount
                          }
                          name="recurrenceAmount"
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              recurrenceAmount: floatValue,
                            });
                            this.setState({
                              formHandlingOperation: {
                                ...formHandlingOperation,
                                recurrenceAmount: floatValue,
                              },
                            });
                          }}
                          style={{
                            width: 80,
                          }}
                        />
                        <NumberInput
                          style={{ width: 70, textAlign: "center" }}
                          placeholder="Min"
                          value={
                            props.values.redoFromAmount > 0 &&
                            props.values.redoFromAmount
                          }
                          name="redoFromAmount"
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              redoFromAmount: floatValue,
                            });
                            this.setState({
                              formHandlingOperation: {
                                ...formHandlingOperation,
                                redoFromAmount: floatValue,
                              },
                            });
                          }}
                        />
                        <Input
                          style={{
                            width: 40,
                            borderLeft: 0,
                            pointerEvents: "none",
                            backgroundColor: "#fff",
                          }}
                          placeholder="-"
                          disabled
                        />
                        <NumberInput
                          style={{
                            width: 70,
                            textAlign: "center",
                            borderLeft: 0,
                          }}
                          placeholder="Max"
                          value={
                            props.values.redoToAmount > 0 &&
                            props.values.redoToAmount
                          }
                          name="redoToAmount"
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              redoToAmount: floatValue,
                            });
                            this.setState({
                              formHandlingOperation: {
                                ...formHandlingOperation,
                                redoToAmount: floatValue,
                              },
                            });
                          }}
                        />
                        <Input
                          style={{
                            width: 60,
                            borderLeft: 0,
                            textAlign: "right",
                            pointerEvents: "none",
                            backgroundColor: "#fff",
                          }}
                          placeholder="Dias"
                          disabled
                        />
                      </InputGroup>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={11} lg={11} xl={11} offset={1}>
                    <Row justify="space-between">
                      <Col span={12}>
                        <label>
                          {translation.handlingOperation.form.quarantine}
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <NumberInput
                        placeholder={
                          translation.handlingOperation.form.quarantineAmount
                        }
                        value={
                          props.values.quarantineAmount > 0 &&
                          props.values.quarantineAmount
                        }
                        name="quarantineAmount"
                        onValueChange={({ floatValue }) => {
                          props.setValues({
                            ...props.values,
                            quarantineAmount: floatValue,
                          });
                          this.setState({
                            formHandlingOperation: {
                              ...formHandlingOperation,
                              quarantineAmount: floatValue,
                            },
                          });
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                <CustomDivider>
                  {translation.handlingOperation.limitations}
                </CustomDivider>
                {/* Breed and genre */}
                <Row type="flex" className="rowForm" align="middle">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row>
                      <label>{translation.handlingOperation.form.breed}</label>
                    </Row>
                    <Row>
                      <Select
                        style={{ width: "100%" }}
                        name="breeds"
                        mode="multiple"
                        value={
                          breeds != null
                            ? props.values.breeds == null
                              ? []
                              : props.values.breeds.map((b) => {
                                  return b.id;
                                })
                            : []
                        }
                        placeholder={
                          translation.handlingOperation.form.breedPlaceHolder
                        }
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) =>
                          this.handleSelectBreed(value, props)
                        }
                      >
                        {breeds != null &&
                          Object.entries(breeds).length > 0 &&
                          breeds.map((b) => (
                            <Option key={b.id} value={b.id}>
                              {b.name}
                            </Option>
                          ))}
                      </Select>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={11} lg={11} xl={11} offset={1}>
                    <Row>
                      <label>{translation.handlingOperation.form.gender}</label>
                    </Row>
                    <Row>
                      <Select
                        style={{ width: "100%" }}
                        name="gender"
                        value={
                          props.values.gender != null
                            ? props.values.gender
                            : undefined
                        }
                        placeholder={
                          translation.handlingOperation.form.genderPlaceHolder
                        }
                        onChange={(value) => {
                          props.setValues({ ...props.values, gender: value });
                          this.setState({
                            formHandlingOperation: {
                              ...formHandlingOperation,
                              gender: value,
                            },
                          });
                        }}
                      >
                        <Option key="M" value="M">
                          {translation.animal.details.male}
                        </Option>
                        <Option key="F" value="F">
                          {translation.animal.details.female}
                        </Option>
                      </Select>
                    </Row>
                  </Col>
                </Row>
                {/* Age and Weight */}
                <Row type="flex" className="rowForm" align="middle">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row>
                      <label>{translation.handlingOperation.form.age}</label>
                    </Row>
                    <Row>
                      <InputGroup compact>
                        <NumberInput
                          style={{ width: 80, textAlign: "center" }}
                          placeholder="De"
                          value={props.values.minAge > 0 && props.values.minAge}
                          name="minAge"
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              minAge: floatValue,
                            });
                            this.setState({
                              formHandlingOperation: {
                                ...formHandlingOperation,
                                minAge: floatValue,
                              },
                            });
                          }}
                        />
                        <Input
                          style={{
                            width: 40,
                            borderLeft: 0,
                            pointerEvents: "none",
                            backgroundColor: "#fff",
                          }}
                          placeholder="-"
                          disabled
                        />
                        <NumberInput
                          style={{
                            width: 100,
                            textAlign: "center",
                            borderLeft: 0,
                          }}
                          placeholder="Até"
                          value={props.values.maxAge > 0 && props.values.maxAge}
                          name="maxAge"
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              maxAge: floatValue,
                            });
                            this.setState({
                              formHandlingOperation: {
                                ...formHandlingOperation,
                                maxAge: floatValue,
                              },
                            });
                          }}
                        />
                        <Input
                          style={{
                            width: 99,
                            borderLeft: 0,
                            textAlign: "right",
                            pointerEvents: "none",
                            backgroundColor: "#fff",
                          }}
                          placeholder="Meses"
                          disabled
                        />
                      </InputGroup>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={11} lg={11} xl={11} offset={1}>
                    <Row>
                      <label>{translation.handlingOperation.form.weight}</label>
                    </Row>
                    <Row>
                      <InputGroup compact>
                        <NumberInput
                          style={{ width: 80, textAlign: "center" }}
                          placeholder="De"
                          value={props.values.minWeight}
                          name="minWeight"
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              minWeight: floatValue,
                            });
                            this.setState({
                              formHandlingOperation: {
                                ...formHandlingOperation,
                                minWeight: floatValue,
                              },
                            });
                          }}
                        />
                        <Input
                          style={{
                            width: 40,
                            borderLeft: 0,
                            pointerEvents: "none",
                            backgroundColor: "#fff",
                          }}
                          placeholder="-"
                          disabled
                        />
                        <NumberInput
                          style={{
                            width: 100,
                            textAlign: "center",
                            borderLeft: 0,
                          }}
                          placeholder="Até"
                          value={props.values.maxWeight}
                          name="maxWeight"
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              maxWeight: floatValue,
                            });
                            this.setState({
                              formHandlingOperation: {
                                ...formHandlingOperation,
                                maxWeight: floatValue,
                              },
                            });
                          }}
                        />
                        <Input
                          style={{
                            width: 71,
                            borderLeft: 0,
                            textAlign: "right",
                            pointerEvents: "none",
                            backgroundColor: "#fff",
                          }}
                          placeholder="Kg"
                          disabled
                        />
                      </InputGroup>
                    </Row>
                  </Col>
                </Row>
                {/* inQuarantine, inPregnancy, haveCost */}
                <Row type="flex" className="rowForm" align="middle">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row>
                      <span className="spanBold">
                        {
                          translation.handlingOperation.form
                            .dontExecuteOperation
                        }
                      </span>
                    </Row>
                    <Row>
                      <Row>
                        <Checkbox
                          checked={props.values.inQuarantine}
                          onChange={(e) => {
                            props.setValues({
                              ...props.values,
                              inQuarantine: e.target.checked,
                            });
                            this.setState({
                              formHandlingOperation: {
                                ...formHandlingOperation,
                                inQuarantine: e.target.checked,
                              },
                            });
                          }}
                        >
                          {translation.handlingOperation.form.inQuarantine}
                        </Checkbox>
                      </Row>
                      <Row>
                        <Checkbox
                          checked={props.values.inPregnancy}
                          onChange={(e) => {
                            props.setValues({
                              ...props.values,
                              inPregnancy: e.target.checked,
                            });
                            this.setState({
                              formHandlingOperation: {
                                ...formHandlingOperation,
                                inPregnancy: e.target.checked,
                              },
                            });
                          }}
                        >
                          {translation.handlingOperation.form.inPregnancy}
                        </Checkbox>
                      </Row>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={11} lg={11} xl={11} offset={1}>
                    <Row>
                      <span className="spanBold">
                        {translation.handlingOperation.form.operationCost}
                      </span>
                    </Row>
                    <Row>
                      <Radio.Group
                        value={props.values.haveCost}
                        onChange={(e) => {
                          props.setValues({
                            ...props.values,
                            haveCost: e.target.value,
                          });
                          this.setState({
                            formHandlingOperation: {
                              ...formHandlingOperation,
                              haveCost: e.target.value,
                            },
                          });
                        }}
                      >
                        <Radio value={false}>
                          {translation.handlingOperation.form.noCost}
                        </Radio>
                        <Radio value={true}>
                          {translation.handlingOperation.form.haveCost}
                        </Radio>
                      </Radio.Group>
                    </Row>
                  </Col>
                </Row>
              </div>
              {/* Footer */}
              <Footer>
                <Row type="flex" justify="space-between">
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="selectDiv"
                  >
                    {props.values.id === null ? (
                      <Select
                        value={this.state.saveOptions}
                        name="saveOptions"
                        onChange={(value) =>
                          this.setState({ saveOptions: value })
                        }
                      >
                        <Option key="1" value="only_save">
                          {translation.handlingOperation.saveOptions.only_save}
                        </Option>
                        <Option key="2" value="save_and_create_new">
                          {
                            translation.handlingOperation.saveOptions
                              .save_and_create_new
                          }
                        </Option>
                      </Select>
                    ) : null}
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="buttonsDiv"
                  >
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={this.closeDrawer}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard type="submit" buttonType="type6">
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  handlingOperation: state.handlingOperation,
});

const mapDispatchToProps = (dispatch) => ({
  handlingOperationActions: bindActionCreators(
    HandlingOperationActions,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrawerHandlingOperation);
