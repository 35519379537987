import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import moment from "moment";

import "moment/locale/pt-br";
import "moment/locale/en-nz";
import "moment/locale/es";

import { Creators as FarmDeathReasonActions } from "../../../../store/ducks/farmDeathReason";

// Components
import { Input, Row, Col, DatePicker, Icon, Select } from "antd";
import { Container, Body, Footer, PopConfirmCustom } from "../edit/styles";
import ButtonStandard from "../../../utils/button";

const AnimalDeath = ({
  lotName,
  lastAnimal,
  title,
  visible,
  closeDrawer,
  propsFormik,
  confirmDeath,
}) => {
  const dispatch = useDispatch();

  const {
    farmDeathReason: {
      isLoadingDropdown: isLoadingDeathReasons,
      listDrowpDown: deathReasonsData,
    },
    app: {
      translation,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
  } = useSelector((state) => state);

  const dateFormat = "DD/MM/YYYY";
  const { TextArea } = Input;
  const validationSchema = Yup.object().shape({
    deathDate: Yup.string().required(),
    deathReasonId: Yup.string().required(),
  });

  useEffect(() => {
    dispatch(
      FarmDeathReasonActions.getDropdownFarmDeathReason(groupId, farmId)
    );
  }, [groupId, farmId, dispatch]);

  return (
    <Container
      title={title}
      width={435}
      visible={visible}
      onClose={closeDrawer}
      maskClosable={false}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          deathDate: null,
          deathDescription: "",
          isFarmDeathReason: false,
        }}
        onSubmit={(values, actions) => {
          confirmDeath(
            values.deathDate,
            values.deathDescription,
            propsFormik,
            values.deathReasonId,
            deathReasonsData.findIndex(
              (dr) => dr.id === values.deathReasonId
            ) !== -1
          );
          actions.resetForm({});
        }}
        validationSchema={validationSchema}
        render={(props) => (
          <form>
            <Body>
              {/* Errors */}
              {Object.entries(props.errors).length > 0 &&
                props.submitCount > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="formLabel error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
              <Row type="flex" style={{ marginTop: 8 }}>
                <Col span={12}>
                  <Row>
                    <label
                      className={
                        props.errors.deathDate ? "formLabel error" : "formLabel"
                      }
                    >
                      Data*
                    </label>
                  </Row>
                  <Row>
                    <DatePicker
                      name="deathDate"
                      allowClear={false}
                      style={{ width: "100%" }}
                      value={
                        props.values.deathDate != null
                          ? moment(props.values.deathDate, "YYYY-MM-DD")
                          : undefined
                      }
                      placeholder={translation.defaultDatePickerPlaceholder}
                      format={dateFormat}
                      onChange={(date, dateString) => {
                        if (date <= moment() && date !== null) {
                          props.setValues({
                            ...props.values,
                            deathDate: date,
                          });
                        }
                      }}
                    />
                  </Row>
                </Col>
              </Row>
              <Row type="flex" style={{ marginTop: 8 }}>
                <Col span={12}>
                  <Row>
                    <label
                      className={
                        props.errors.deathReasonId
                          ? "formLabel error"
                          : "formLabel"
                      }
                    >
                      Causa Mortis*
                    </label>
                  </Row>
                  <Row>
                    <Select
                      name="deathReasonId"
                      value={props.values.deathReasonId || undefined}
                      placeholder={translation.defaultSelectPlaceholder}
                      onChange={(value) => {
                        props.setFieldValue("deathReasonId", value);
                        props.setFieldValue(
                          "isFarmDeathReason",
                          deathReasonsData.find(
                            (dr) => dr.deathReasonId === value
                          ).farmId !== null
                        );
                      }}
                      loading={isLoadingDeathReasons}
                    >
                      {deathReasonsData.map((dr) => (
                        <Select.Option
                          key={dr.name}
                          value={dr.id !== null ? dr.id : dr.deathReasonId}
                        >
                          {dr.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
              </Row>
              <Row type="flex" style={{ marginTop: 21 }}>
                <Col span={24}>
                  <TextArea
                    name="deathDescription"
                    value={props.values.deathDescription}
                    placeholder="Descrição da morte"
                    maxLength={90}
                    style={{
                      width: "100%",
                      height: 249,
                      padding: "10px 10px",
                    }}
                    onChange={(e) => {
                      props.setValues({
                        ...props.values,
                        deathDescription: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </Body>
            <Footer>
              <Row type="flex" justify="end">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className="buttonsDiv"
                >
                  <ButtonStandard
                    type="button"
                    buttonType="type7"
                    onClick={closeDrawer}
                  >
                    {translation.buttons.cancel}
                  </ButtonStandard>
                  {lastAnimal ? (
                    <PopConfirmCustom
                      title={
                        <p>
                          Atenção! Este é o último animal presente no Lote "
                          {lotName}".
                          <br /> Ao confirmar o registro de óbito, o Lote ficará
                          com a situação “Pendente”.
                          <br /> Se não for mais utilizar este lote, poderá
                          inativá-lo.
                        </p>
                      }
                      cancelText="Cancelar"
                      okText="Confirmar"
                      placement="topRight"
                      icon={
                        <Icon
                          type="exclamation-circle"
                          theme="filled"
                          style={{
                            fontSize: 18,
                            color: "#D44C4C",
                            marginRight: 5,
                          }}
                        />
                      }
                      onConfirm={props.submitForm}
                    >
                      <ButtonStandard type="button" buttonType="type6">
                        {translation.buttons.putToDeath}
                      </ButtonStandard>
                    </PopConfirmCustom>
                  ) : (
                    <PopConfirmCustom
                      title={
                        <p>
                          Caso o animal estiver em um lote que possua <br />{" "}
                          cenário(s) de venda, ao confirmar, reprocesse os{" "}
                          <br /> cenário(s) para que o animal seja
                          desconsiderado.
                        </p>
                      }
                      cancelText="Cancelar"
                      okText="Confirmar"
                      placement="topRight"
                      icon={
                        <Icon
                          type="exclamation-circle"
                          theme="filled"
                          style={{
                            fontSize: 18,
                            color: "#D44C4C",
                            marginRight: 5,
                          }}
                        />
                      }
                      onConfirm={props.submitForm}
                    >
                      <ButtonStandard type="button" buttonType="type6">
                        {translation.buttons.putToDeath}
                      </ButtonStandard>
                    </PopConfirmCustom>
                  )}
                </Col>
              </Row>
            </Footer>
          </form>
        )}
      />
    </Container>
  );
};

export default AnimalDeath;
