import getHeaders from "../getHeaders";
import apiCommons from "../../config/api_commons";
import apiAnimals from "../../config/api_animals";

const ENTITY = "animal";

export function reprocessAnimalDashboard(payload) {
  const { groupId, farmId, dashboard, dashboardParams, signal } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/${ENTITY}/dashboards/${dashboard}/generate `,
    dashboardParams,
    getHeaders(false, signal)
  );
}

export function getAnimalReproductionAgePregnanciesDashboard(payload) {
  const { groupId, farmId, dashboardParams, signal } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/${ENTITY}/dashboards/AnimalReproductionAgePregnanciesDashboard`,
    dashboardParams,
    getHeaders(false, signal)
  );
}

export function getAnimalReproductionWeightScoreDashboard(payload) {
  const { groupId, farmId, dashboardParams, signal } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/${ENTITY}/dashboards/AnimalReproductionWeightScoreDashboard`,
    dashboardParams,
    getHeaders(false, signal)
  );
}

export function getAnimalReproductionIndicatorsDashboard(payload) {
  const { groupId, farmId, dashboardParams, signal } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/${ENTITY}/dashboards/AnimalReproductionIndicatorsDashboard`,
    dashboardParams,
    getHeaders(false, signal)
  );
}

export function getAnimalCountDashboard(payload) {
  const { groupId, farmId, dashboardParams, signal } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/${ENTITY}/dashboards/AnimalCountDashboard`,
    dashboardParams,
    getHeaders(false, signal)
  );
}

export function getAnimalWeightStatisticsByMaleGenderDashboard(payload) {
  const { groupId, farmId, dashboardParams, signal } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/${ENTITY}/dashboards/AnimalWeightStatisticsByMaleGenderDashboard`,
    dashboardParams,
    getHeaders(false, signal)
  );
}

export function getAnimalWeightStatisticsByFemaleGenderDashboard(payload) {
  const { groupId, farmId, dashboardParams, signal } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/${ENTITY}/dashboards/AnimalWeightStatisticsByFemaleGenderDashboard`,
    dashboardParams,
    getHeaders(false, signal)
  );
}

export function getAnimalWeightRankingDashboard(payload) {
  const { groupId, farmId, dashboardParams, signal } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/${ENTITY}/dashboards/AnimalWeightRankingDashboard`,
    dashboardParams,
    getHeaders(false, signal)
  );
}

export function getAnimalTotalWeightDashboard(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/${id}/dashboards/TotalWeight`,
    getHeaders(false, signal)
  );
}

export function getAnimalWeaningWeightDashboard(payload) {
  const { groupId, farmId, animalId, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/${animalId}/dashboards/WeaningWeight`,
    getHeaders(false, signal)
  );
}

export function getAnimalDailyGMDDashboard(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/${id}/dashboards/TotalGMD`,
    getHeaders(false, signal)
  );
}

export function getAnimalDailyWeightDashboard(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/${id}/dashboards/AnimalDailyWeight?shouldUseFutureWeights=true`,
    getHeaders(false, signal)
  );
}

export function getAnimalDailyWeightGainDashboard(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/${id}/dashboards/AnimalDailyWeightGain?shouldUseFutureWeights=true`,
    getHeaders(false, signal)
  );
}

export function getAnimalLotDailyWeightGainDashboard(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/${id}/dashboards/AnimalLotDailyWeight?shouldUseFutureWeights=true`,
    getHeaders(false, signal)
  );
}
