import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// Libs
import { Spin, Row, Col, Icon } from "antd";

// Redux
import { Creators as UserActions } from "../../store/ducks/user";

// Components
import { Container, CustomDivider, PageTitle } from "./styles";
import DrawerHomeConfig from "../../components/drawers/homeConfig";
import AlertPanel from "./alertPanel";
import KpiPanel from "./kpiPanel";
import ClimaticPanel from "./climaticPanel";

// Icons
import HomeIcon from "./icons/home";

// Contexts
import useHomeContext from "../../hooks/useHomeContext";
import { HomeContextProvider } from "../../contexts/homeContext";
import { KpiContextProvider } from "../../contexts/kpiContext";

const DashboardLayout = () => {
  const dispatch = useDispatch();

  const { farmId, isLoading, showContractButton } = useHomeContext();

  const handleEditHomeConfig = useCallback(() => {
    dispatch(UserActions.showOrHideHomeConfigDrawer());
  }, [dispatch]);

  return (
    <Container>
      <Spin spinning={isLoading}>
        {/* Title Row */}
        <Row type="flex" justify="space-between">
          <Col span={8}>
            <PageTitle>
              <HomeIcon />
              <span>home</span>
            </PageTitle>
          </Col>
          <Col span={16} align="right">
            <div style={{ display: "inline-flex" }}>
              {showContractButton ? (
                <Link
                  to="/admin/updateToContract"
                  className="buttonConfig"
                  style={{ marginRight: "13px" }}
                  disabled={farmId === null || farmId === undefined}
                >
                  <span>
                    <b>Contratar</b>
                  </span>
                </Link>
              ) : null}
              <button
                type="button"
                className="buttonConfig"
                disabled={farmId === null || farmId === undefined}
                onClick={() => handleEditHomeConfig()}
              >
                <Icon type="setting" />
                <span>Configurar tela</span>
              </button>
            </div>
          </Col>
        </Row>
        <CustomDivider dashed />
        <Row type="flex" justify="start" gutter={4}>
          {/* Col with kpis */}
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <KpiContextProvider>
              <KpiPanel />
            </KpiContextProvider>
          </Col>
          {/* Col with alerts, pendencies */}
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <ClimaticPanel />
            <AlertPanel />
          </Col>
        </Row>
      </Spin>
      <DrawerHomeConfig />
    </Container>
  );
};

const Dashboard = () => {
  return (
    <HomeContextProvider>
      <DashboardLayout />
    </HomeContextProvider>
  );
};

export default Dashboard;
