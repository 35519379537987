import React, { createContext, useCallback, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Creators as AnimalActions } from "../../../store/ducks/animal";
import useAnimalListContext from "../../../hooks/useAnimalListContext";
import moment from "moment";

const AnimalFattenContext = createContext({
  identificationSelected: "handlingNumber",
  handleChangeIdentification: (identification) => {},
  fetchData: (
    page = 0,
    sorter = null,
    filters = null,
    size = 10,
    ids = null
  ) => {},
});

export const AnimalFattenContextProvider = ({ children }) => {
  const {
    app: {
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { alertsEntityIds } = useAnimalListContext();

  const [identificationSelected, setIdentificationSelected] =
    useState("handlingNumber");

  const handleChangeIdentification = useCallback((identification) => {
    setIdentificationSelected(identification);
  }, []);

  const getSearchString = (filters) => {
    let search = "";

    if (filters && Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v && (v.length > 0 || Object.keys(v)?.length > 0)) {
          const fieldS = k
            ? k === "handlingNumber"
              ? "handling_number"
              : k === "tagId"
              ? "tag_id"
              : k === "farmStatus"
              ? "farm_status"
              : k === "lotName"
              ? "lot_name"
              : k === "picketName"
              ? "picket_name"
              : k === "breedName"
              ? "breed_name"
              : k === "supplierName"
              ? "supplier_name"
              : k === "currentWeightRanking"
              ? "current_weight_ranking"
              : k === "animalReproductionCategory"
              ? "animal_reproduction_category"
              : k === "femaleSituation"
              ? "female_situation"
              : k === "animalReproductionState"
              ? "animal_reproduction_state"
              : k === "lastDailyWeight"
              ? "last_daily_weight"
              : k === "lastDailyWeightGain"
              ? "last_daily_weight_gain"
              : k === "lastDailyWeightDate"
              ? "last_daily_weight_date"
              : k
            : null;

          if (
            fieldS === "last_daily_weight" ||
            fieldS === "last_daily_weight_gain"
          ) {
            let operator = v.type ? v.type : "=";
            operator = operator.replace("<", "%3C").replace(">", "%3E");
            if (operator === "=") {
              const min_value = Math.floor(v.value);
              const max_value = Math.ceil(v.value);
              search =
                search === "" || search === null
                  ? `${fieldS} %3E ${min_value} and ${fieldS} %3C ${max_value}`
                  : `${search};${fieldS} %3E ${min_value} and ${fieldS} %3C ${max_value}`;
            } else if (operator === "%3C=") {
              const max_value = parseFloat(v.value);
              search =
                search === "" || search === null
                  ? `${fieldS} ${operator} ${max_value}`
                  : `${search};${fieldS} ${operator} ${max_value}`;
            } else if (operator === "%3E=") {
              const min_value = parseFloat(v.value);
              search =
                search === "" || search === null
                  ? `${fieldS} ${operator} ${min_value}`
                  : `${search};${fieldS} ${operator} ${min_value}`;
            } else {
              search =
                search === "" || search === null
                  ? `${fieldS} ${operator} ${v.value}`
                  : `${search};${fieldS} ${operator} ${v.value}`;
            }
          } else if (fieldS === "last_daily_weight_date") {
            let operator = v.type ? v.type : "=";
            operator = operator.replace("<", "%3C").replace(">", "%3E");
            const formatedDateValue = `'${moment(v.value).format(
              "YYYY-MM-DD"
            )}'`;
            if (operator === "=") {
              search =
                search === "" || search === null
                  ? `${fieldS} = ${formatedDateValue}`
                  : `${search};${fieldS} = ${formatedDateValue}`;
            } else if (operator === "%3C=") {
              search =
                search === "" || search === null
                  ? `${fieldS} ${operator} ${formatedDateValue}`
                  : `${search};${fieldS} ${operator} ${formatedDateValue}`;
            } else if (operator === "%3E=") {
              search =
                search === "" || search === null
                  ? `${fieldS} ${operator} ${formatedDateValue}`
                  : `${search};${fieldS} ${operator} ${formatedDateValue}`;
            } else {
              search =
                search === "" || search === null
                  ? `${fieldS} ${operator} ${formatedDateValue}`
                  : `${search};${fieldS} ${operator} ${formatedDateValue}`;
            }
          } else if (fieldS === "current_weight_ranking") {
            search =
              search === ""
                ? `${fieldS} = '${v}'`
                : `${search};${fieldS} = '${v}'`;
          } else if (fieldS === "farm_status") {
            if (v.includes("I")) v.push("X");
            search =
              search === ""
                ? `${fieldS} in (${v.map((o) => (o = `'${o}'`)).join(", ")})`
                : `${search};${fieldS} in (${v
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`;
          } else if (fieldS === "female_situation") {
            if (v.includes("I")) v.push("X");
            search =
              search === ""
                ? `${fieldS} in (${v.map((o) => (o = `'${o}'`)).join(", ")})`
                : `${search};${fieldS} in (${v
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`;
          } else {
            search =
              search === ""
                ? `upper(${fieldS}) like upper('%25${v}%25')`
                : `${search};upper(${fieldS}) like upper('%25${v}%25')`;
          }
        }
      });
    }

    return search !== "" ? search : "farm_status='A';";
  };

  const getSortString = (sorter) => {
    let customSort = sorter;

    if (sorter && Object.entries(sorter).length > 0) {
      const { field } = sorter;

      const fieldS = field
        ? field === "handlingNumber"
          ? "handling_number"
          : field === "tagId"
          ? "tag_id"
          : field === "farmStatus"
          ? "farm_status"
          : field === "lotName"
          ? "lot_name"
          : field === "picketName"
          ? "picket_name"
          : field === "breedName"
          ? "breed_name"
          : field === "supplierName"
          ? "supplier_name"
          : field === "animalReproductionCategory"
          ? "animal_reproduction_category"
          : field === "femaleSituation"
          ? "female_situation"
          : field === "animalReproductionState"
          ? "animal_reproduction_state"
          : field === "lastDailyWeight"
          ? "last_daily_weight"
          : field === "lastDailyWeightGain"
          ? "last_daily_weight_gain"
          : field === "lastDailyWeightDate"
          ? "last_daily_weight_date"
          : field
        : null;

      customSort = {
        ...customSort,
        field: fieldS,
      };
    }

    return customSort;
  };

  const fetchData = useCallback(
    (page = 0, sorter = null, filters = null, size = 10, ids = null) => {
      let customSort = getSortString(sorter);
      let search = `animal_farm_function='fatten';` + getSearchString(filters);

      dispatch(
        AnimalActions.indexAnimal(
          groupId,
          farmId,
          page,
          customSort,
          search,
          size,
          ids != null ? ids : alertsEntityIds !== null ? alertsEntityIds : null,
          sorter,
          filters
        )
      );
    },
    [dispatch, groupId, farmId, alertsEntityIds]
  );

  return (
    <AnimalFattenContext.Provider
      value={{
        identificationSelected,
        handleChangeIdentification,
        fetchData,
      }}
    >
      {children}
    </AnimalFattenContext.Provider>
  );
};

export function useAnimalFattenContext() {
  const value = useContext(AnimalFattenContext);
  return value;
}
