import React, { memo } from "react";
import { numberMask } from "../../../../services/helpersMethodsService";
import moment from "moment";

const ClosedSalesReportBodyConsolidatedItem = memo(
  ({ closeReportResponseData }) => {
    const { consolidated } = closeReportResponseData;
    return (
      <td className="simulation-container">
        <strong className="title">Consolidado</strong>
        <div className="simulation-container-item">
          <div className="header">Dados da Venda</div>
          <div className="content">
            <table>
              <tbody>
                <tr>
                  <td className="field">Qtde Animais</td>
                  <td className="value">
                    {closeReportResponseData?.consolidated.amountAnimals}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="simulation-container-item">
          <div className="header">Resultado Zootécnico</div>
          <div className="content">
            <table>
              <tbody>
                <tr>
                  <td className="field">Peso Entrada</td>
                  <td className="value">
                    {`${numberMask(consolidated.startWeight || 0, false)} kg`}
                  </td>
                </tr>
                <tr>
                  <td className="field">Peso Venda</td>
                  <td className="value">
                    {`${numberMask(
                      closeReportResponseData?.consolidated.sellWeight || 0,
                      false
                    )} kg`}
                  </td>
                </tr>
                <tr>
                  <td className="field">Perm. (Dias)</td>
                  <td className="value">
                    {closeReportResponseData?.consolidated.stayDays}
                  </td>
                </tr>
                <tr>
                  <td className="field">GDP</td>
                  <td className="value">
                    {`${numberMask(
                      closeReportResponseData?.consolidated.gdp || 0,
                      false
                    )} kg`}
                  </td>
                </tr>
                <tr>
                  <td class="field">GDP Acumulado</td>
                  <td class="value">
                    {`${numberMask(
                      closeReportResponseData?.consolidated.accumulatedGDP || 0,
                      false
                    )} Kg`}
                  </td>
                </tr>
                <tr>
                  <td className="field">% Aprov. Carc.</td>
                  <td className="value">
                    {`${numberMask(
                      closeReportResponseData?.consolidated.carcassHarnessing ||
                        0,
                      false
                    )}%`}
                  </td>
                </tr>
                <tr>
                  <td className="field">GDP Carc.</td>
                  <td className="value">
                    {`${numberMask(
                      closeReportResponseData?.consolidated.gdpCarcass || 0,
                      false
                    )} kg`}
                  </td>
                </tr>
                <tr>
                  <td class="field">@ Produzidas</td>
                  <td class="value">
                    {`${numberMask(
                      closeReportResponseData?.consolidated.arrobaInPeriod || 0,
                      false
                    )} @`}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="simulation-container-item">
          <div className="header">Resultado Econômico</div>
          <div className="content">
            <table>
              <tbody>
                <tr>
                  <td className="field">Vlr. Aquisição(R$)</td>
                  <td className="value">
                    {`${numberMask(
                      closeReportResponseData?.consolidated.acquisitionPrice ||
                        0,
                      true
                    )}`}
                  </td>
                </tr>
                <tr>
                  <td className="field">Vlr. @/Kg Venda</td>
                  <td className="value">
                    {`${numberMask(
                      closeReportResponseData?.consolidated.weightSellPrice ||
                        0,
                      true
                    )}`}
                  </td>
                </tr>
                <tr>
                  <td className="field">Bonificação</td>
                  <td className="value">
                    {`${numberMask(
                      closeReportResponseData?.consolidated.totalBonus || 0,
                      true
                    )} | ${numberMask(
                      closeReportResponseData?.consolidated
                        .totalBonusPerArroba || 0,
                      true
                    )}`}
                  </td>
                </tr>
                <tr>
                  <td className="field">Receita Total</td>
                  <td className="value">
                    {`${numberMask(
                      closeReportResponseData?.consolidated.totalIncome || 0,
                      true
                    )}`}
                  </td>
                </tr>
                <tr>
                  <td className="field">Receita (Cab)</td>
                  <td className="value">
                    {`${numberMask(
                      closeReportResponseData?.consolidated.incomePerAnimal ||
                        0,
                      true
                    )}`}
                  </td>
                </tr>
                <tr>
                  <td className="field">Custo Nutrição</td>
                  <td className="value">
                    {`${numberMask(
                      closeReportResponseData?.consolidated
                        .totalNutritionCost || 0,
                      true
                    )}`}
                  </td>
                </tr>
                <tr>
                  <td className="field">Custo Nutrição (Cab)</td>
                  <td className="value">
                    {`${numberMask(
                      closeReportResponseData?.consolidated
                        .nutritionCostPerAnimal || 0,
                      true
                    )}`}
                  </td>
                </tr>
                <tr>
                  <td className="field">Custo Adm/Operac.</td>
                  <td className="value">
                    {`${numberMask(
                      closeReportResponseData?.consolidated.totalFixedCost || 0,
                      true
                    )}`}
                  </td>
                </tr>
                <tr>
                  <td className="field">Custo Adm/Operac. (Cab)</td>
                  <td className="value">
                    {`${numberMask(
                      closeReportResponseData?.consolidated
                        .fixedCostPerAnimal || 0,
                      true
                    )}`}
                  </td>
                </tr>
                <tr>
                  <td class="field">Custo Prod. @</td>
                  <td class="value">
                    {`${numberMask(
                      closeReportResponseData?.consolidated.costByArroba || 0,
                      true
                    )}`}
                  </td>
                </tr>
                <tr>
                  <td className="field">Margem Total</td>
                  <td className="value">
                    {`${numberMask(
                      closeReportResponseData?.consolidated.totalMargin || 0,
                      true
                    )}`}
                  </td>
                </tr>
                <tr>
                  <td className="field">Margem Total (Cab)</td>
                  <td className="value">
                    {`${numberMask(
                      closeReportResponseData?.consolidated.marginPerAnimal ||
                        0,
                      true
                    )}`}
                  </td>
                </tr>
                <tr>
                  <td className="field">Margem (%)</td>
                  <td className="value">
                    {`${numberMask(
                      closeReportResponseData?.consolidated.marginPercent *
                        100 || 0,
                      false
                    )}%`}
                  </td>
                </tr>
                <tr>
                  <td className="field">&nbsp;</td>
                  <td className="value">&nbsp;</td>
                </tr>
                <tr>
                  <td className="field">&nbsp;</td>
                  <td className="value">&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </td>
    );
  }
);

const ClosedSalesReportBodySimulationItem = memo(({ simulation }) => {
  return (
    <td className="simulation-container">
      <strong className="title">{simulation.identification}</strong>
      <div className="simulation-container-item">
        <div className="header">Dados da Venda</div>
        <div className="content">
          <table>
            <tbody>
              <tr>
                <td className="field">Cliente</td>
                <td className="value">{simulation.clientName}</td>
              </tr>
              <tr>
                <td className="field">Data Venda</td>
                <td className="value">
                  {moment(simulation.saleDate).format("DD/MM/YYYY")}
                </td>
              </tr>
              <tr>
                <td className="field">Qtde Animais</td>
                <td className="value">{simulation.amountAnimals}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="simulation-container-item">
        <div className="header">Resultado Zootécnico</div>
        <div className="content">
          <table>
            <tbody>
              <tr>
                <td className="field">Peso Entrada</td>
                <td className="value">
                  {`${numberMask(simulation.startWeight || 0, false)} kg`}
                </td>
              </tr>
              <tr>
                <td className="field">Peso Venda</td>
                <td className="value">
                  {`${numberMask(simulation.sellWeight || 0, false)} kg`}
                </td>
              </tr>
              <tr>
                <td className="field">Perm. (Dias)</td>
                <td className="value">{simulation.stayDays}</td>
              </tr>
              <tr>
                <td className="field">GDP</td>
                <td className="value">
                  {`${numberMask(simulation.gdp || 0, false)} kg`}
                </td>
              </tr>
              <tr>
                <td class="field">GDP Acumulado</td>
                <td class="value">
                  {`${numberMask(simulation.accumulatedGDP || 0, false)} kg`}
                </td>
              </tr>
              <tr>
                <td className="field">% Aprov. Carc.</td>
                <td className="value">
                  {`${numberMask(simulation.carcassHarnessing || 0, false)}%`}
                </td>
              </tr>
              <tr>
                <td className="field">GDP Carc.</td>
                <td className="value">
                  {`${numberMask(simulation.gdpCarcass || 0, false)} kg`}
                </td>
              </tr>
              <tr>
                <td class="field">@ Produzidas</td>
                <td class="value">
                  {`${numberMask(simulation.arrobaInPeriod || 0, false)} @`}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="simulation-container-item">
        <div className="header">Resultado Econômico</div>
        <div className="content">
          <table>
            <tbody>
              <tr>
                <td className="field">Vlr. Aquisição(R$)</td>
                <td className="value">
                  {`${numberMask(simulation.acquisitionPrice || 0, true)}`}
                </td>
              </tr>
              <tr>
                <td className="field">
                  {simulation.priceNegotiatedType === "@"
                    ? "Vlr. @ Venda"
                    : "Vlr. Kg Venda"}
                </td>
                <td className="value">
                  {`${numberMask(simulation.weightSellPrice || 0, true)}`}
                </td>
              </tr>
              <tr>
                <td className="field">Bonificação</td>
                <td className="value">
                  {`${numberMask(simulation.bonus || 0, true)} | ${numberMask(
                    simulation.bonusPerArroba || 0,
                    true
                  )} / @`}
                </td>
              </tr>
              <tr>
                <td className="field">Receita Total</td>
                <td className="value">
                  {`${numberMask(simulation.totalIncome || 0, true)}`}
                </td>
              </tr>
              <tr>
                <td className="field">Receita (Cab)</td>
                <td className="value">
                  {`${numberMask(simulation.incomePerAnimal || 0, true)}`}
                </td>
              </tr>
              <tr>
                <td className="field">Custo Nutrição</td>
                <td className="value">
                  {`${numberMask(simulation.totalNutritionCost || 0, true)}`}
                </td>
              </tr>
              <tr>
                <td className="field">Custo Nutrição (Cab)</td>
                <td className="value">
                  {`${numberMask(
                    simulation.nutritionCostPerAnimal || 0,
                    true
                  )}`}
                </td>
              </tr>
              <tr>
                <td className="field">Custo Adm/Operac.</td>
                <td className="value">
                  {`${numberMask(simulation.totalFixedCost || 0, true)}`}
                </td>
              </tr>
              <tr>
                <td className="field">Custo Adm/Operac. (Cab)</td>
                <td className="value">
                  {`${numberMask(simulation.fixedCostPerAnimal || 0, true)}`}
                </td>
              </tr>
              <tr>
                <td class="field">Custo Prod. @</td>
                <td class="value">
                  {`${numberMask(simulation.costByArroba || 0, true)}`}
                </td>
              </tr>
              <tr>
                <td className="field">Margem Total</td>
                <td className="value">
                  {`${numberMask(simulation.totalMargin || 0, true)}`}
                </td>
              </tr>
              <tr>
                <td className="field">Margem Total (Cab)</td>
                <td className="value">
                  {`${numberMask(simulation.marginPerAnimal || 0, true)}`}
                </td>
              </tr>
              <tr>
                <td className="field">Margem (%)</td>
                <td className="value">
                  {`${numberMask(simulation.marginPercent * 100 || 0, false)}%`}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </td>
  );
});

const ClosedSalesReportBody = ({ closeReportResponseData }) => {
  return (
    <tbody>
      {closeReportResponseData?.items.map((simulationArray, index) => (
        <tr key={index}>
          {simulationArray.map((simulation) => (
            <ClosedSalesReportBodySimulationItem
              key={simulation.id}
              simulation={simulation}
            />
          ))}
          {index + 1 === closeReportResponseData?.items.length &&
            closeReportResponseData?.consolidated != null && (
              <ClosedSalesReportBodyConsolidatedItem
                closeReportResponseData={closeReportResponseData}
              />
            )}
        </tr>
      ))}
    </tbody>
  );
};

export default memo(ClosedSalesReportBody);
