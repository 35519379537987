import React from "react";
function RegisterContractIcon() {
  return (
    <svg
      width="57"
      height="57"
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="28.5" cy="28.5" r="28.5" fill="#94CF1C" />
      <path
        d="M14.4 15.7602V44.3202H37.52V40.2402H41.6V11.6802H18.48V15.7602H14.4ZM36.16 42.9602H15.76V17.1202H18.48H31.4V21.8802H36.16V40.2402V42.9602ZM32.76 18.0817L35.1985 20.5202H32.76V18.0817ZM19.84 13.0402H40.24V38.8802H37.52V20.9187L32.3615 15.7602H19.84V13.0402Z"
        fill="white"
      />
      <path d="M33.44 24.6001H18.48V25.9601H33.44V24.6001Z" fill="white" />
      <path d="M33.44 28.6802H18.48V30.0402H33.44V28.6802Z" fill="white" />
      <path d="M33.44 32.7603H18.48V34.1203H33.44V32.7603Z" fill="white" />
      <path d="M24.6 38.2002H18.48V39.5602H24.6V38.2002Z" fill="white" />
      <path
        d="M33.4401 38.2002H27.3201V39.5602H33.4401V38.2002Z"
        fill="white"
      />
      <path d="M28.68 20.52H23.24V21.88H28.68V20.52Z" fill="white" />
    </svg>
  );
}

export default RegisterContractIcon;
