import React, { useState, useEffect } from "react";
/** Redux libs */
import { useSelector } from "react-redux";
/** Components */
import { Container, Footer, Body } from "./styles";
import { Row, Col, Input, Tooltip, Divider, Select, TimePicker } from "antd";
import ButtonStandard from "../../../../components/utils/button";
import NumberFormat from "react-number-format";
import InfoIcon from "../../../../components/utils/icons/info";
import moment from "moment";

const EditWeight = ({ data, onSave, onCancel }) => {
  const { translation } = useSelector((state) => state.app);
  const [form, setForm] = useState([]);
  const [weightPeriodVisible, setWeightPeriodVisible] = useState(false);
  const [weightRanking, setWeightRanking] = useState(null);
  const [dailyWeightCalculationPeriod, setDailyWeightCalculationPeriod] =
    useState(null);
  const [dailyWeightCalculation, setDailyWeightCalculation] = useState(null);
  useEffect(() => {
    setWeightRanking(data.find((x) => x.code === 1003));
    setDailyWeightCalculation(data.find((x) => x.code === 1004));
    setDailyWeightCalculationPeriod(data.find((x) => x.code === 1005));
    setWeightPeriodVisible(
      data.find((x) => x.code === 1004)?.value === "SimpleAveragePeriod"
    );
    setForm(data.map((d) => d));
  }, [data]);

  useEffect(() => {
    form[0] = weightRanking;
  }, [form, weightRanking]);

  useEffect(() => {
    form[1] = dailyWeightCalculation;
  }, [form, dailyWeightCalculation]);

  useEffect(() => {
    form[2] = dailyWeightCalculationPeriod;
  }, [form, dailyWeightCalculationPeriod]);

  const { Option } = Select;
  return (
    <Container>
      {/* Body */}
      <Body type="flex">
        <Row type="flex" align="middle" style={{ width: "100%" }}>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <span>
              <strong>Cálculo do Peso Diário</strong>
              <Tooltip
                title={
                  <div>
                    <p>
                      Quando utilizado balanças de passagem em que o animal é
                      pesado mais de uma vez no dia, é necessário definir o
                      critério a ser adotado para Cálculo do Peso Diário. São as
                      opções:
                    </p>
                    <p>
                      <strong>Média Diária:</strong> Média aritmética simples de
                      todas as pesagens do animal no dia.
                    </p>
                    <p>
                      <strong>Média Diária Exceto Extremos:</strong> Média
                      aritmética simples as pesagens, desconsiderando a primeira
                      e última pesagem registrada para o animal no dia.
                    </p>
                    <p>
                      <strong>Mínimo do Dia:</strong> Considera-se a menor
                      pesagem registrada para animal no dia.
                    </p>
                    <p>
                      <strong>Média Diária entre Período:</strong> Média
                      aritmética simples das pesagens registradas para o animal
                      em determinado período do dia.
                    </p>
                  </div>
                }
              >
                <InfoIcon />
              </Tooltip>
            </span>
          </Col>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <Row style={{ textAlign: "right" }}>
              <Select
                name="dailyWeightCalculation"
                value={
                  dailyWeightCalculation
                    ? dailyWeightCalculation.value
                    : "SimpleAverage"
                }
                style={{ width: "100%" }}
                placeholder="Selecione"
                onChange={(value) => {
                  setDailyWeightCalculation({
                    ...dailyWeightCalculation,
                    value: value,
                    updated: true,
                  });
                  setWeightPeriodVisible(value === "SimpleAveragePeriod");
                }}
              >
                <Option key={"SimpleAverage"} value={"SimpleAverage"}>
                  Média Diária
                </Option>
                <Option
                  key={"AverageWithoutOutliers"}
                  value={"AverageWithoutOutliers"}
                >
                  Média Diária Exceto Extremos
                </Option>
                <Option key={"MinimumWeight"} value={"MinimumWeight"}>
                  Peso Mínimo do Dia
                </Option>
                <Option
                  key={"SimpleAveragePeriod"}
                  value={"SimpleAveragePeriod"}
                >
                  Média Diária entre Período
                </Option>
              </Select>
            </Row>
          </Col>
        </Row>
        <Row
          type="flex"
          align="middle"
          style={{ width: "100%", marginTop: "10px" }}
          hidden={!weightPeriodVisible}
        >
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <strong>Selecione o Período Inicial e Final</strong>
          </Col>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <Row style={{ textAlign: "right" }}>
              <TimePicker
                value={moment(dailyWeightCalculationPeriod?.value, "HH:mm")}
                format={"HH:mm"}
                minuteStep={10}
                style={{ marginRight: "5px", width: "90px" }}
                onChange={(value) => {
                  let finalHour = moment(
                    dailyWeightCalculationPeriod?.valueExtra,
                    "HH:mm"
                  );
                  if (finalHour.isAfter(value)) {
                    setDailyWeightCalculationPeriod({
                      ...dailyWeightCalculationPeriod,
                      value: value.format("HH:mm"),
                      updated: true,
                    });
                  }
                }}
              />
              até
              <TimePicker
                value={moment(
                  dailyWeightCalculationPeriod?.valueExtra,
                  "HH:mm"
                )}
                format={"HH:mm"}
                minuteStep={10}
                style={{ marginLeft: "5px", width: "90px" }}
                onChange={(value) => {
                  let initialHour = moment(
                    dailyWeightCalculationPeriod?.value,
                    "HH:mm"
                  );
                  if (initialHour.isBefore(value)) {
                    setDailyWeightCalculationPeriod({
                      ...dailyWeightCalculationPeriod,
                      valueExtra: value.format("HH:mm"),
                      updated: true,
                    });
                  }
                }}
              />
            </Row>
          </Col>
        </Row>
      </Body>
      <Divider dashed="true" style={{ margin: "5px 0px" }} />
      <Body type="flex" justify="start" align="middle">
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <span>
            <strong>Ranking de pesagens</strong>
            <Tooltip title="O Ranking de Pesagens permite que o sistema classifique o animal utilizando a porcentagem de diferença entre o peso real e o peso esperado (considerando a curva de crescimento e curva da dieta do lote/animal).">
              <InfoIcon />
            </Tooltip>
          </span>
        </Col>
        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
          <Row>
            <div className="valuesContainer">
              <span className="values">
                <div className="circle red" />
                {`Fundo: <`}
                <NumberFormat
                  name="value"
                  customInput={Input}
                  decimalScale="2"
                  allowNegative={false}
                  suffix=" %"
                  value={weightRanking ? weightRanking.value : 1}
                  onValueChange={({ floatValue }) => {
                    setWeightRanking({
                      ...weightRanking,
                      value:
                        floatValue == null
                          ? 1
                          : floatValue > 0
                          ? floatValue > 999
                            ? 999
                            : floatValue
                          : 1,
                      updated: true,
                    });
                  }}
                />
              </span>
              <span className="values">
                <div className="circle green" />
                Meio:
                <strong className="gray">{`>${
                  (weightRanking && weightRanking.value) || 1
                }% | <${
                  (weightRanking && weightRanking.valueExtra) || 2
                }%`}</strong>
              </span>
              <span className="values">
                <div className="circle purple" />
                {`Cabeceira: >`}
                <NumberFormat
                  name="valueExtra"
                  customInput={Input}
                  decimalScale="2"
                  allowNegative={false}
                  suffix=" %"
                  value={weightRanking ? weightRanking.valueExtra : 2}
                  onValueChange={({ floatValue }) => {
                    setWeightRanking({
                      ...weightRanking,
                      valueExtra:
                        floatValue == null
                          ? 2
                          : floatValue > 0
                          ? floatValue > 999
                            ? 999
                            : floatValue
                          : 2,
                      updated: true,
                    });
                  }}
                />
              </span>
            </div>
          </Row>
        </Col>
      </Body>
      {/* Footer */}
      <Footer>
        <Row type="flex" justify="space-between">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="selectDiv"
          ></Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="buttonsDiv">
            <ButtonStandard
              type="button"
              buttonType="typeWithoutBackground"
              color="#d44c4c"
              isUpperCase={true}
              onClick={onCancel}
            >
              {translation.buttons.cancel}
            </ButtonStandard>

            <ButtonStandard
              type="submit"
              buttonType="typeWithoutBackground"
              color="#684E94"
              isUpperCase={true}
              onClick={() => onSave(form)}
            >
              {translation.buttons.save}
            </ButtonStandard>
          </Col>
        </Row>
      </Footer>
    </Container>
  );
};

export default EditWeight;
