import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CanvasJSReact from "../../../assets/canvasjs.react";
import { Creators as BreedActions } from "../../../store/ducks/breed";
import * as Yup from "yup";
import { Formik } from "formik";
import { Row, Col, Select, Input, Radio, Spin } from "antd";
import ButtonStandard from "../../utils/button";
import { Container, Footer, CustomDivider, ChartContainer } from "./styles";
import NumberFormat from "react-number-format";
import InfoTooltip from "../../utils/infoTooltip";
const { CanvasJSChart } = CanvasJSReact;

class DrawerBreedParameters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formBreedParameters: {
        id: null,
        breedId: null,
        name: "",
        maleBirthdayWeight: 0,
        maleWeaningWeight: 0,
        maleTwelveMonthsWeight: 0,
        adultMaleWeight: 0,
        asymptoticWeight: 0,
        estimatedMaximumAge: 32,
        breedCurveParam: null,
        minGestationTime: "",
        medGestationTime: "",
        maxGestationTime: "",
        status: "Active",
      },
      saveOptions: "only_save",
      breedId: null,
      chartOptions: {
        animationEnabled: true,
        zoomEnabled: true,
        height: 300,
        axisX: {
          maximum: 830,
          interval: 210,
          intervalType: "number",
          crosshair: {
            enabled: true,
            snapToDataPoint: true,
          },
          stripLines: [
            {
              value: 210,
              label: "7 meses",
              labelPlacement: "outside", //"outside",
              lineDashType: "dashDot",
              color: "#4b4b4b",
              labelFontColor: "#4b4b4b",
              labelBackgroundColor: "#fff",
            },
            {
              value: 420,
              label: "14 meses",
              labelPlacement: "outside", //"outside",
              lineDashType: "dashDot",
              color: "#4b4b4b",
              labelFontColor: "#4b4b4b",
              labelBackgroundColor: "#fff",
            },
            {
              value: 630,
              label: "21 meses",
              labelPlacement: "outside", //"outside",
              lineDashType: "dashDot",
              color: "#4b4b4b",
              labelFontColor: "#4b4b4b",
              labelBackgroundColor: "#fff",
            },
            {
              value: 800,
              label: "32 meses",
              labelPlacement: "outside", //"outside",
              lineDashType: "dashDot",
              color: "#4b4b4b",
              labelFontColor: "#4b4b4b",
              labelBackgroundColor: "#fff",
            },
          ],
        },
        toolTip: {
          fontFamily: "Asap",
          shared: true,
        },
        axisY: {
          includeZero: true,
          valueFormatString: "###0.##",
          suffix: "kg",
          gridDashType: "dot",
        },
        legend: {
          cursor: "pointer",
          itemclick: (e) => {
            if (
              typeof e.dataSeries.visible === "undefined" ||
              e.dataSeries.visible
            ) {
              e.dataSeries.visible = false;
            } else {
              e.dataSeries.visible = true;
            }
            e.chart.render();
          },
        },
        data: [],
      },
      curveSelected: {
        geneticCategory: "GT",
        handlingCategory: "DP",
        theoreticalCurveType: "Logistic",
      },
      isLoadingChart: false,
    };
  }

  async shouldComponentUpdate(nextProps) {
    const differentBreedParametersData =
      this.props.breed.breedParametersData !==
      nextProps.breed.breedParametersData;
    if (
      differentBreedParametersData &&
      nextProps.breed.breedParametersData != null
    ) {
      const { breedCurveParam } = nextProps.breed.breedParametersData;
      if (breedCurveParam)
        this.setState({
          curveSelected: {
            geneticCategory: breedCurveParam.geneticCategory,
            handlingCategory: breedCurveParam.handlingCategory,
            theoreticalCurveType: breedCurveParam.theoreticalCurveType,
          },
        });
      this.setState({
        isLoadingChart: true,
      });
      this.handleCalculateCurves(
        nextProps.breed.breedParametersData.breedCurveParams,
        nextProps.breed.breedParametersData.asymptoticWeight
      );
    }
    return true;
  }

  closeDrawer = () => {
    const { breedActions } = this.props;
    this.setState({
      formBreedParameters: {
        id: null,
        breedId: null,
        name: "",
        asymptoticWeight: 0,
        estimatedMaximumAge: 32,
        breedCurveParam: null,
        minGestationTime: "",
        medGestationTime: "",
        maxGestationTime: "",
        status: "Active",
      },
    });
    breedActions.hideDrawerParameters();
  };

  handleSubmitModalForm = async (values, actions) => {
    const { breedCurveParams } = values;
    const {
      curveSelected: {
        theoreticalCurveType,
        geneticCategory,
        handlingCategory,
      },
    } = this.state;

    if (
      breedCurveParams.find(
        (bcp) =>
          geneticCategory === bcp.geneticCategory &&
          handlingCategory === bcp.handlingCategory &&
          theoreticalCurveType === bcp.theoreticalCurveType
      ) != null
    ) {
      values.breedCurveParam = breedCurveParams.find(
        (bcp) =>
          geneticCategory === bcp.geneticCategory &&
          handlingCategory === bcp.handlingCategory &&
          theoreticalCurveType === bcp.theoreticalCurveType
      );
    }
    const {
      breed: { breedId, error },
    } = this.props;

    const body = {
      id: values.breedParameterId,
      maleBirthdayWeight: values.maleBirthdayWeight,
      maleWeaningWeight: values.maleWeaningWeight,
      maleTwelveMonthsWeight: values.maleTwelveMonthsWeight,
      adultMaleWeight: values.adultMaleWeight,
      asymptoticWeight: values.asymptoticWeight,
      estimatedMaximumAge: values.estimatedMaximumAge,
      minGestationTime: values.minGestationTime,
      medGestationTime: values.medGestationTime,
      maxGestationTime: values.maxGestationTime,
      breed: {
        id: values.breedId,
      },
      breedCurveParam: {
        id: values.breedCurveParam.id,
      },
      status: values.status,
    };

    if (values.farmId == null && values.groupId == null) {
      await this.handleNewBreedParameters(body, breedId);
    } else {
      await this.handleUpdateBreedParameters(body, breedId, body.id);
    }

    if (error === null) {
      actions.resetForm({
        id: null,
        breedId: null,
        name: "",
        asymptoticWeight: 0,
        estimatedMaximumAge: 32,
        breedCurveParam: null,
        minGestationTime: "",
        medGestationTime: "",
        maxGestationTime: "",
        status: "Active",
      });
      this.setState({
        formBreedParameters: {
          id: null,
          breedId: null,
          name: "",
          asymptoticWeight: 0,
          estimatedMaximumAge: 32,
          breedCurveParam: null,
          minGestationTime: "",
          medGestationTime: "",
          maxGestationTime: "",
          status: "Active",
        },
      });
    }
  };

  handleNewBreedParameters = async (breedParameters, breedId) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      breedActions,
    } = this.props;
    let saveOptions = this.state.saveOptions === "only_save" ? false : true;
    breedActions.saveBreedParameters(
      groupId,
      farmId,
      breedId,
      null,
      breedParameters,
      saveOptions
    );
  };

  handleUpdateBreedParameters = async (breedParameters, breedId, id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      breedActions,
    } = this.props;
    let saveOptions = this.state.saveOptions === "only_save" ? false : true;
    breedActions.saveBreedParameters(
      groupId,
      farmId,
      breedId,
      id,
      breedParameters,
      saveOptions
    );
  };

  handleChangeAsymptoticWeight = (value) => {
    const {
      breed: { breedParametersData },
    } = this.props;
    if (breedParametersData != null)
      this.handleCalculateCurves(breedParametersData.breedCurveParams, value);
  };

  handleChangeCurveSelected = (field, value, weightValue) => {
    const {
      breed: {
        breedParametersData: { breedCurveParams },
      },
    } = this.props;

    this.setState(
      {
        isLoadingChart: true,
        curveSelected: {
          ...this.state.curveSelected,

          [field]: value,
        },
      },
      () => this.handleCalculateCurves(breedCurveParams, weightValue)
    );
  };

  handleCalculateCurves = (breedCurveParams, weight) => {
    const {
      curveSelected: {
        theoreticalCurveType,
        geneticCategory,
        handlingCategory,
      },
    } = this.state;
    let result = [];

    breedCurveParams
      .filter((bcp) => bcp.theoreticalCurveType === theoreticalCurveType)
      .forEach((bcp) => {
        let day = 1;
        let dataChart = {
          type: "line",
          name: `${bcp.geneticCategory} - ${bcp.handlingCategory}`,
          showInLegend: true,
          markerType: "none",
          markerSize: 5,
          yValueFormatString: "###0.## kg",
          lineDashType:
            geneticCategory === bcp.geneticCategory &&
            handlingCategory === bcp.handlingCategory
              ? "solid"
              : "dot",
          color:
            geneticCategory === bcp.geneticCategory &&
            handlingCategory === bcp.handlingCategory
              ? "#A9C133"
              : "#e8e5e5",
          dataPoints: [],
        };
        switch (theoreticalCurveType) {
          case "Logistic":
            do {
              dataChart.dataPoints.push({
                x: day,
                y: Number.parseFloat(
                  Number(
                    1.09441891891892 *
                      (weight /
                        (1 +
                          bcp.correctionFactor *
                            Math.exp(bcp.maturationRate * day)))
                  ).toFixed(2)
                ),
              });

              day++;
            } while (day <= 800);
            break;
          case "Brody":
            do {
              dataChart.dataPoints.push({
                x: day,
                y: Number.parseFloat(
                  Number(
                    1.21843243243243 *
                      (weight *
                        (1 -
                          bcp.correctionFactor *
                            Math.exp(-bcp.maturationRate * day)))
                  ).toFixed(2)
                ),
              });
              day++;
            } while (day <= 800);
            break;
          case "VonBertalanffy":
            do {
              dataChart.dataPoints.push({
                x: day,
                y: Number.parseFloat(
                  Number(
                    1.29818918918919 *
                      (weight *
                        Math.pow(
                          1 -
                            bcp.correctionFactor *
                              Math.exp(-bcp.maturationRate * day),
                          3
                        ))
                  ).toFixed(2)
                ),
              });
              day++;
            } while (day <= 800);
            break;
          default:
            break;
        }
        result.push(dataChart);
      });
    this.setState({
      isLoadingChart: false,
      chartOptions: {
        ...this.state.chartOptions,
        data: result,
      },
    });
  };

  render() {
    const {
      app: { translation },
      breed: {
        breedParametersData,
        drawerParametersVisible,
        isLoadingBreedParameters,
        isLoadingRequest,
      },
    } = this.props;

    const { formBreedParameters, curveSelected, chartOptions, isLoadingChart } =
      this.state;

    const validationSchema = Yup.object().shape({
      maleBirthdayWeight: Yup.number().required().moreThan(0),
      maleWeaningWeight: Yup.number().required().moreThan(0),
      adultMaleWeight: Yup.number().required().moreThan(0),
      asymptoticWeight: Yup.number().required().moreThan(0),
      estimatedMaximumAge: Yup.number().required().moreThan(0),
      minGestationTime: Yup.number().required().moreThan(0).lessThan(1000),
      medGestationTime: Yup.number().required().moreThan(0).lessThan(1000),
      maxGestationTime: Yup.number().required().moreThan(0).lessThan(1000),
    });

    const { Option } = Select;

    return (
      <Container
        title={translation.breed.drawerParametersTitle}
        width={620}
        onClose={this.closeDrawer}
        maskClosable={false}
        visible={drawerParametersVisible}
      >
        <Spin spinning={isLoadingBreedParameters || isLoadingRequest}>
          <Formik
            enableReinitialize={true}
            initialValues={
              breedParametersData != null
                ? breedParametersData
                : formBreedParameters
            }
            onSubmit={this.handleSubmitModalForm}
            validationSchema={validationSchema}
            render={(props) => (
              <form onSubmit={props.handleSubmit} autoComplete="off">
                <div className="drawerForm">
                  <Row type="flex" justify="start" gutter={16}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Row>
                        <label className="title">
                          {translation.breed.table.columns.name}
                        </label>
                      </Row>
                      <Row>
                        <label className="subTitle">{props.values?.name}</label>
                      </Row>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Row>
                        <label className="title">
                          {translation.breed.table.columns.geneticGroup}
                        </label>
                      </Row>
                      <Row>
                        <label className="subTitle">
                          {props.values?.geneticGroup}
                        </label>
                      </Row>
                    </Col>
                  </Row>
                  <CustomDivider dashed />
                  {Object.entries(props.errors).length > 0 &&
                    props.submitCount > 0 && (
                      <Row type="flex" justify="center" align="middle">
                        <label className="error">
                          {translation.error.formError}
                        </label>
                      </Row>
                    )}
                  {/* Idade adulta */}
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Row>
                        <label
                          className={
                            props.errors.estimatedMaximumAge &&
                            props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.breed.formParameters.estimatedMaximumAge}
                          *
                        </label>
                      </Row>
                      <Row>
                        <NumberFormat
                          placeholder={
                            translation.breed.formParameters
                              .estimatedMaximumAgePlaceHolder
                          }
                          customInput={Input}
                          value={props.values?.estimatedMaximumAge}
                          name="estimatedMaximumAge"
                          addonAfter="Meses"
                          decimalSeparator=","
                          thousandSeparator="."
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              estimatedMaximumAge: floatValue,
                            });
                            this.setState({
                              formBreedParameters: {
                                ...formBreedParameters,
                                estimatedMaximumAge: floatValue,
                              },
                            });
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  {/* Peso no nascimento e no desmame */}
                  <Row
                    type="flex"
                    style={{ marginTop: "13px" }}
                    justify="start"
                    gutter={16}
                  >
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Row>
                        <label
                          className={
                            props.errors.maleBirthdayWeight &&
                            props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          Peso médio no nascimento *
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          placeholder={translation.defaultPlaceholder}
                          customInput={Input}
                          value={props.values?.maleBirthdayWeight}
                          name="maleBirthdayWeight"
                          addonAfter="Kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              maleBirthdayWeight: floatValue,
                            });
                          }}
                        />
                      </Row>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Row>
                        <label
                          className={
                            props.errors.maleWeaningWeight &&
                            props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          Peso médio no desmame *
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          placeholder={translation.defaultPlaceholder}
                          customInput={Input}
                          value={props.values?.maleWeaningWeight}
                          name="maleWeaningWeight"
                          addonAfter="Kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              maleWeaningWeight: floatValue,
                            });
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <CustomDivider dashed>
                    {translation.breed.formParameters.gestationDivider}
                  </CustomDivider>
                  <Row
                    type="flex"
                    style={{ marginTop: "13px" }}
                    justify="start"
                    gutter={16}
                  >
                    {/* Min */}
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Row>
                        <label
                          className={
                            props.errors.minGestationTime &&
                            props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.breed.formParameters.minGestationTime}*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          placeholder={translation.defaultPlaceholder}
                          customInput={Input}
                          value={props.values?.minGestationTime}
                          name="minGestationTime"
                          addonAfter="Dias"
                          decimalScale={0}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              minGestationTime: floatValue,
                            });
                          }}
                        />
                      </Row>
                    </Col>
                    {/* Med */}
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Row>
                        <label
                          className={
                            props.errors.medGestationTime &&
                            props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.breed.formParameters.medGestationTime}*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          placeholder={translation.defaultPlaceholder}
                          customInput={Input}
                          value={props.values?.medGestationTime}
                          name="medGestationTime"
                          addonAfter="Dias"
                          decimalScale={0}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              medGestationTime: floatValue,
                            });
                          }}
                        />
                      </Row>
                    </Col>
                    {/* Max */}
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Row>
                        <label
                          className={
                            props.errors.maxGestationTime &&
                            props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.breed.formParameters.maxGestationTime}*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          placeholder={translation.defaultPlaceholder}
                          customInput={Input}
                          value={props.values?.maxGestationTime}
                          name="maxGestationTime"
                          addonAfter="Dias"
                          decimalScale={0}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              maxGestationTime: floatValue,
                            });
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <CustomDivider dashed />
                  {/* Peso com 12 mêses e adulto */}
                  <Row
                    type="flex"
                    style={{ marginTop: "13px" }}
                    justify="start"
                    gutter={16}
                  >
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Row>
                        <label>Peso médio aos 12 meses</label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          placeholder={translation.defaultPlaceholder}
                          customInput={Input}
                          value={props.values?.maleTwelveMonthsWeight}
                          name="maleTwelveMonthsWeight"
                          addonAfter="Kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              maleTwelveMonthsWeight: floatValue,
                            });
                          }}
                        />
                      </Row>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Row>
                        <label>Peso adulto *</label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          placeholder={translation.defaultPlaceholder}
                          customInput={Input}
                          value={props.values?.adultMaleWeight}
                          name="adultMaleWeight"
                          addonAfter="Kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              adultMaleWeight: floatValue,
                            });
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  {/* Peso assintótico e tipo da curva */}
                  <Row
                    type="flex"
                    style={{ marginTop: "13px" }}
                    justify="start"
                    gutter={16}
                  >
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Row>
                        <label
                          className={
                            props.errors.asymptoticWeight &&
                            props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.breed.formParameters.asymptoticWeight} *
                        </label>
                        <InfoTooltip
                          title="Peso assintótico é o peso que, com base nas informações que o usuário/produtor possui, sobre o histórico do Animal ou Lote de Animais, (considerando a genética, manejo anterior, interação genótipo-ambiente, frame, entre outras variáveis), acredite ser o potencial limite de peso. Ou seja, acima deste peso, dificilmente este animal ganhará peso, em condições normais."
                          placement="bottom"
                        />
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          placeholder={
                            translation.breed.formParameters
                              .asymptoticWeightPlaceHolder
                          }
                          customInput={Input}
                          value={props.values?.asymptoticWeight}
                          name="asymptoticWeight"
                          addonAfter="Kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              asymptoticWeight: floatValue,
                            });
                            this.handleChangeAsymptoticWeight(floatValue);
                          }}
                        />
                      </Row>
                    </Col>
                    <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                      <Row>
                        <label>
                          {translation.breed.formParameters.theoricalCurve} *
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          value={curveSelected.theoreticalCurveType}
                          onChange={(value) =>
                            this.handleChangeCurveSelected(
                              "theoreticalCurveType",
                              value,
                              props.values?.adultMaleWeight
                            )
                          }
                        >
                          <Option value="Brody">Brody</Option>
                          <Option value="Logistic">Logistic</Option>
                          <Option value="VonBertalanffy">
                            Von-Bertalanffy
                          </Option>
                        </Select>
                      </Row>
                    </Col>
                  </Row>
                  <CustomDivider dashed>
                    {translation.breed.formParameters.extimatedCurveTitle}
                  </CustomDivider>
                  {props.values?.breedCurveParams != null &&
                    props.values?.breedCurveParams.length > 0 && (
                      <>
                        <Row
                          type="flex"
                          className="cardGrey"
                          justify="start"
                          align="middle"
                          gutter={4}
                        >
                          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                            <label>
                              {translation.breed.formParameters.genetic}
                            </label>
                          </Col>
                          <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                            <Radio.Group
                              value={curveSelected.geneticCategory}
                              onChange={(e) =>
                                this.handleChangeCurveSelected(
                                  "geneticCategory",
                                  e.target.value,
                                  props.values?.adultMaleWeight
                                )
                              }
                            >
                              <Radio value="GT">Tauríno</Radio>
                              <Radio value="GZ">Zebuíno</Radio>
                              <Radio value="GC">Cruzado</Radio>
                            </Radio.Group>
                          </Col>
                        </Row>
                        <Row
                          type="flex"
                          className="cardGrey"
                          justify="start"
                          align="middle"
                          gutter={4}
                        >
                          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                            <label>
                              {translation.breed.formParameters.diet}
                            </label>
                          </Col>
                          <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                            <Radio.Group
                              value={curveSelected.handlingCategory}
                              onChange={(e) =>
                                this.handleChangeCurveSelected(
                                  "handlingCategory",
                                  e.target.value,
                                  props.values?.adultMaleWeight
                                )
                              }
                            >
                              <Radio value="DP">Premium</Radio>
                              <Radio value="DS">Superior</Radio>
                              <Radio value="DC">Comum</Radio>
                            </Radio.Group>
                          </Col>
                        </Row>
                        <Spin spinning={isLoadingChart}>
                          <ChartContainer>
                            <CanvasJSChart options={chartOptions} />
                          </ChartContainer>
                        </Spin>
                      </>
                    )}
                </div>
                {/* Footer */}
                <Footer>
                  <Row type="flex" justify="space-between">
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="selectDiv"
                    >
                      {props.values?.id === null &&
                      !isLoadingBreedParameters ? (
                        <Select
                          value={this.state.saveOptions}
                          name="saveOptions"
                          onChange={(value) =>
                            this.setState({ saveOptions: value })
                          }
                        >
                          <Option key="1" value="only_save">
                            {translation.breed.saveOptions.only_save}
                          </Option>
                        </Select>
                      ) : null}
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="buttonsDiv"
                    >
                      <ButtonStandard
                        type="button"
                        buttonType="type7"
                        onClick={this.closeDrawer}
                      >
                        {translation.buttons.cancel}
                      </ButtonStandard>

                      <ButtonStandard type="submit" buttonType="type6">
                        {translation.buttons.save}
                      </ButtonStandard>
                    </Col>
                  </Row>
                </Footer>
              </form>
            )}
          />
        </Spin>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  breed: state.breed,
});

const mapDispatchToProps = (dispatch) => ({
  breedActions: bindActionCreators(BreedActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrawerBreedParameters);
