import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { SemenContextProvider } from "../../contexts/semenContext";
import useSemenContext from "../../hooks/useSemenContext";
import { Button, Col, Dropdown, Icon, Input, Menu, Row, Table } from "antd";
import ButtonStandard from "../../components/utils/button";
import { CardCustom, Title } from "./styles";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import MenuIcon from "../../components/utils/table/icons/menu";
import DrawerSemen from "../../components/drawers/semen";

const SemenLayout = () => {
  const { translation } = useSelector((state) => state.app);
  const { dataList, isLoadingDataList, openDrawer, fetchData } =
    useSemenContext();

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const menu = (id, record) => {
    return (
      <Menu>
        <Menu.Item key="1" onClick={() => handleEdit(record)}>
          {translation.table.menu.edit}
        </Menu.Item>
      </Menu>
    );
  };

  const handleEdit = useCallback(
    (record) => {
      openDrawer(record);
    },
    [openDrawer]
  );

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return record[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    }),
    [handleReset, handleSearch]
  );

  const handleOpenDrawer = () => {
    openDrawer();
  };

  return (
    <CardCustom>
      <Row className="rowHeader" justify="space-between">
        <Col span={8}>
          <Title>{translation.semen.title}</Title>
        </Col>
        <Col span={16}>
          <Row type="flex" justify="end" gutter={8}>
            <Col>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={fetchData}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </Col>
            <Col>
              <ButtonStandard buttonType="type8" onClick={handleOpenDrawer}>
                {translation.semen.buttonNewSemen}
              </ButtonStandard>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <Table
            loading={isLoadingDataList}
            rowKey="id"
            size="small"
            dataSource={dataList}
            scroll={{
              x: true,
            }}
            pagination={{
              hideOnSinglePage:
                dataList !== null && dataList.length > 10 ? false : true,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
            }}
          >
            <Table.Column
              title={translation.semen.table.columns.name}
              dataIndex="name"
              sorter={(first, second) => {
                return first.name.localeCompare(second.name, "pt-BR", {
                  numeric: false,
                  ignorePunctuation: true,
                });
              }}
              key="name"
              {...handleGetColumnSearchProps("name")}
            />
            <Table.Column
              title={translation.semen.table.columns.registration}
              dataIndex="registration"
              sorter={(first, second) => {
                if (!first.registration) {
                  first.registration = "";
                }
                return first.registration.localeCompare(
                  second.registration,
                  "pt-BR",
                  {
                    numeric: false,
                    ignorePunctuation: true,
                  }
                );
              }}
              key="registration"
              {...handleGetColumnSearchProps("registration")}
            />
            <Table.Column
              title={translation.semen.table.columns.breedName}
              dataIndex="breedName"
              sorter={(first, second) => {
                return first.breedName.localeCompare(
                  second.breedName,
                  "pt-BR",
                  {
                    numeric: false,
                    ignorePunctuation: true,
                  }
                );
              }}
              key="breedName"
              {...handleGetColumnSearchProps("breedName")}
            />
            <Table.Column
              title=""
              align="left"
              render={(text, record) => (
                <Dropdown
                  overlay={menu(record.id, record)}
                  trigger={["click"]}
                  key={record.id}
                >
                  <MenuContainer>
                    <MenuIcon />
                  </MenuContainer>
                </Dropdown>
              )}
            />
          </Table>
        </Col>
      </Row>
    </CardCustom>
  );
};

const Semen = () => {
  return (
    <SemenContextProvider>
      <SemenLayout />
      <DrawerSemen />
    </SemenContextProvider>
  );
};

export default Semen;
