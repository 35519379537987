import apiFarms from "../config/api_farms";
import getHeaders from "./getHeaders";

export function getDietStrategyIndex(payload) {
  const {
    groupId,
    farmId,
    page,
    sorter,
    filters,
    withoutPagination,
    signal,
    ids,
  } = payload;

  if (withoutPagination) {
    return apiFarms.post(
      `/bovexo/${groupId}/farms/${farmId}/dietStrategies/list?withoutPagination=true`,
      ids,
      getHeaders(false, signal)
    );
  } else {
    const { field, order } = sorter;

    const orderS = order === "descend" ? "DESC" : "ASC";

    return apiFarms.post(
      `/bovexo/${groupId}/farms/${farmId}/dietStrategies/list?
     ${page ? `&page=${page - 1}` : ""}
     ${
       Object.entries(sorter).length > 0
         ? `&sort=${field}&direction=${orderS}`
         : ""
     }
     ${filters !== "" ? `&search=${filters}` : ""}`,
      ids,
      getHeaders(false, signal)
    );
  }
}

export function getDietStrategyShow(payload) {
  const { groupId, farmId, id, signal } = payload;
  if (!groupId || !farmId || !id) return;
  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/dietStrategies/${id}`,
    getHeaders(false, signal)
  );
}

export function getDietStrategyShowFromSaleScenario({ payload }) {
  const { groupId, farmId, id, signal } = payload;
  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/dietStrategies/${id}`,
    getHeaders(false, signal)
  );
}

export function saveDietStrategy(payload) {
  const { groupId, farmId, id, dietStrategy, signal } = payload;
  if (id != null) {
    return apiFarms.patch(
      `/bovexo/${groupId}/farms/${farmId}/dietStrategies/${id}`,
      dietStrategy,
      getHeaders(false, signal)
    );
  } else {
    return apiFarms.post(
      `/bovexo/${groupId}/farms/${farmId}/dietStrategies`,
      dietStrategy,
      getHeaders(false, signal)
    );
  }
}

export function inactivateDietStrategy(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiFarms.patch(
    `/bovexo/${groupId}/farms/${farmId}/dietStrategies/${id}/inactivate`,
    {},
    getHeaders(false, signal)
  );
}
export function activateDietStrategy(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiFarms.patch(
    `/bovexo/${groupId}/farms/${farmId}/dietStrategies/${id}/reactivate`,
    {},
    getHeaders(false, signal)
  );
}

export function createDietStrategyPeriod(payload) {
  const { groupId, farmId, lotId, signal } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/lots/${lotId}/dietStrategies/periods`,
    getHeaders(false, signal)
  );
}
