import styled from "styled-components";
import { Drawer, Radio } from "antd";

export const Container = styled(Drawer)`
  .ant-drawer-body {
    padding: 24px 0px 10px 0px;
  }

  .drawerForm {
    width: 100%;
    padding: 0px 24px;
    min-height: -moz-calc(100vh - 165px);
    min-height: -webkit-calc(100vh - 165px);
    min-height: calc(100vh - 165px);
  }

  .rowLabel {
    margin-bottom: 7px;
    margin-top: 19px;
  }

  span.subTitle {
    font-size: 14px;
    color: #000;
    margin-bottom: 10px;
  }

  span.farmMessage {
    font-family: Asap;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    color: #4b4b4b;
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    &.error {
      color: #d44c4c;
      margin-left: 15px;
    }
  }

  .ant-select {
    width: 100%;
  }
`;

export const RadioGroupCustom = styled(Radio.Group)`
  &.ant-radio-group {
    width: 100%;
    display: flex;
  }
`;

export const RadioButtonCustom = styled(Radio.Button)`
  margin-top: 6px !important;
  border-radius: 0 15px 15px 0 !important;
  flex: 1;

  .ant-radio-group-solid.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #ffff !important;
    border-color: #ffff !important;
  }

  span {
    font-family: Asap !important;
    font-weight: normal !important;
    font-size: 14px !important;
    text-align: left !important;
    color: #684e94 !important;
  }

  &:first-of-type {
    margin-left: 0px;
    border-radius: 15px 0 0 15px !important;
  }

  &:not(:first-of-type) {
    margin-left: 0;
    border-radius: 0 !important;
  }

  &:last-of-type {
    margin-left: 0px;
    border-radius: 0px 15px 15px 0px !important;
  }

  &.ant-radio-button-wrapper-disabled {
    border-color: #c4c4c4 !important;
    background-color: #c4c4c4 !important;
    span {
      color: #c4c4c4 !important;
    }
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #684e94 !important;
    border-color: #684e94 !important;
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff;
    background: #684e94 !important;
    border-color: #684e94 !important;
  }

  &.ant-radio-button-wrapper-checked {
    &:first-child,
    &:not(:first-child) {
      border-color: #9074bf !important;
    }
    color: #fff !important;
    background: #9074bf !important;
    border-color: #9074bf !important;

    &.ant-radio-button-wrapper-disabled {
      border-color: #c4c4c4 !important;
      background: #c4c4c4 !important;
    }

    span {
      font-family: Asap !important;
      font-weight: normal !important;
      font-size: 14px !important;
      text-align: left !important;
      color: #fff !important;
    }
  }
  &:not(:first-child)::before {
    visibility: hidden !important;
  }
`;

export const Footer = styled.div`
  border-top: 1px solid #e8e8e8;
  margin-top: 20px;
  width: 100%;
  padding: 10px 24px;
  border-radius: 0 0 4px 4px;

  .buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding-right: 24px; */

    button:first-of-type {
      margin-right: 12px !important;
    }
  }

  .ant-row-flex {
    margin: 0;
  }

  .selectDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: "Asap", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;
