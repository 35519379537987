import React from "react";

// import { Container } from './styles';

const TransitionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.942"
    height="18.232"
    viewBox="0 0 20.942 18.232"
  >
    <g
      id="Grupo_2338"
      data-name="Grupo 2338"
      transform="translate(-241.227 -407.885)"
    >
      <path
        id="Path_1917"
        data-name="Path 1917"
        d="M255.1,424.83l-.007-.028a.629.629,0,0,0-.8-.381A7.867,7.867,0,0,1,244.279,414.3l.687,1.286,1.1-.587-1.684-3.159-3.157,1.684.589,1.1,1.26-.672A9.121,9.121,0,0,0,254.7,425.6.628.628,0,0,0,255.1,424.83Z"
        fill="#fe8d2a"
      />
      <path
        id="Path_1918"
        data-name="Path 1918"
        d="M262.581,418.37l-1.261.672A9.118,9.118,0,0,0,249.7,407.4a.623.623,0,0,0-.383.8h0a.627.627,0,0,0,.795.375A7.866,7.866,0,0,1,260.118,418.7l-.687-1.286-1.1.587,1.684,3.159,3.157-1.684Z"
        transform="translate(-1 1)"
        fill="#fe8d2a"
      />
    </g>
  </svg>
);
export default TransitionIcon;
