import { Drawer } from "antd";
import styled from "styled-components";

export const Container = styled(Drawer)`
  .ant-drawer-body {
    padding: 10px 0px 10px 0px;
  }
  .drawerForm {
    position: relative;
    width: 100%;
    height: -moz-calc(100vh - 85px);
    height: -webkit-calc(100vh - 85px);
    height: calc(100vh - 85px);
  }
  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }

  .dietDetails {
    cursor: pointer;
    border: unset;
    background-color: unset;
    align-items: center;
    color: #4a85ae;
    svg {
      margin-left: 6px;
    }
  }

  div.alert-icon {
    margin-left: 5px;
    width: 16px;
    color: #d44c4c;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 24px;
  height: -moz-calc(100vh - 165px);
  height: -webkit-calc(100vh - 165px);
  height: calc(100vh - 165px);

  .ant-row,
  .ant-row-flex {
    margin-top: 7px;
  }

  label + span {
    display: block;
    font-size: 16px;
    color: #4b4b4b;
    font-weight: bold;
  }

  label {
    display: block;
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 7px;
    &.error {
      color: #d44c4c;
    }
  }

  .menuButtons {
    button {
      background: transparent;
      border: 0;
      cursor: pointer;
      & + button {
        margin-left: 10px;
      }
    }
  }
`;
export const Form = styled.div`
  margin-top: 20px;
`;

export const Footer = styled.div`
  position: absolute;
  top: calc(100% - 35px);
  border-top: 1px solid #e8e8e8;
  width: 100%;
  padding: 5px 24px 0 24px;
  border-radius: 0 0 4px 4px;

  .buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding-right: 24px; */

    button:first-of-type {
      margin-right: 12px !important;
    }
  }

  .ant-row-flex {
    margin: 0;
  }

  .selectDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: "Asap", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;
