import apiOptimization from "../config/api_optimization";
import getHeaders from "./getHeaders";

export function indexBoitelSaleScenarioService(payload) {
  const { groupId, farmId, page, sorter, filters, size, signal, ids } = payload;
  let sortS = "";
  if (sorter && Object.entries(sorter).length > 0) {
    const { field, order } = sorter;

    const fieldS = field
      ? field === "nextCommitmentDate"
        ? "next_commitment_date"
        : field
      : null;

    const orderS = order && order === "descend" ? "DESC" : "ASC";

    sortS = `&sort=${fieldS}&direction=${orderS}`;
  }

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/boitels/list?
    ${page ? `&page=${page - 1}` : ""}
    ${size && `&size=${size}`}
    ${sortS}
    ${filters !== "" && filters ? `&search=${filters}` : ""}`,
    ids != null ? ids : null,
    getHeaders(false, signal)
  );
}

export function indexDropDownBoitelSaleScenarioService(payload) {
  const { groupId, farmId, signal } = payload;
  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/boitels/list?withoutPagination=true`,
    null,
    getHeaders(false, signal)
  );
}

export function storeUpdateBoitelSaleScenarioService(payload) {
  const { groupId, farmId, id, body, signal } = payload;

  if (id != null) {
    return apiOptimization.patch(
      `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/boitels/${id}`,
      body,
      getHeaders(false, signal)
    );
  } else {
    return apiOptimization.post(
      `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/boitels`,
      body,
      getHeaders(false, signal)
    );
  }
}

export function showBoitelSaleScenarioService(payload) {
  const { groupId, farmId, id } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/boitels/${id}`,
    getHeaders(false, null)
  );
}

export function reprocessBoitelSaleScenarioService(payload) {
  const { groupId, farmId, id, signal } = payload;
  if (id != null) {
    return apiOptimization.patch(
      `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/boitels/${id}/reprocess`,
      null,
      getHeaders(false, signal)
    );
  }
}

export function downloadBoitelSaleScenarioReportService(payload) {
  const { groupId, farmId, body } = payload;

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/boitels/download/pdf`,
    body,
    getHeaders(false, null, true)
  );
}

export function cancelBoitelSaleScenarioService(payload) {
  const { groupId, farmId, id } = payload;

  return apiOptimization.patch(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/boitels/${id}/cancel`,
    null,
    getHeaders(false, null, true)
  );
}
