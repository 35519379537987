import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { Creators as AppActions } from "../../../store/ducks/app";
import { Creators as AccountFarmActions } from "../../../store/ducks/accountFarm";
// Components
import LogoVertical from "../../login/logos/logoVertical";
import ButtonStandard from "../../utils/button";
import { StyledModal } from "./styles";
import { Spin } from "antd";

const ExpiredTrialAccountModal = () => {
  // variables
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  // Redux Variables
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const {
    isModalExpiredTrialAccountVisible,
    accountFarmData,
    isLoadingRequest,
  } = useSelector((state) => state.accountFarm);
  const dispatch = useDispatch();

  //  Methods
  function closeModal() {
    dispatch(AccountFarmActions.showOrHideExpiredTrialAccountModal());
  }

  function handleRenewExpiration() {
    dispatch(AccountFarmActions.renewExpirationAccountFarm(groupId, farmId));
  }

  function handleLogout() {
    dispatch(AppActions.logout_user(history));
    window.location.href = "/login";
  }

  async function handleUpdateToContract() {
    history.push(`/admin/updateToContract`);
    closeModal();
    // setIsLoading(true);
    // try {
    //   await accountFarmContractBovExoService({
    //     groupId,
    //     farmId,
    //   });
    //   notification.success({
    //     message:
    //       "Sua solicitação foi encaminhada para o nosso comercial, e logo entraremos em contato.",
    //     duration: 0,
    //   });
    //   setIsLoading(false);
    //   handleLogout();
    // } catch (error) {
    //   setIsLoading(false);
    //   notification.error({
    //     message:
    //       "Houve um erro ao enviar sua solicitação. Entre em contato com o suporte.",
    //   });
    // }
  }

  function handleCallCommercial() {
    // if (window.zE) {
    //   window.zE(function () {
    //     window.zE.activate();
    //   });
    // }
    setIsLoading(true);
    window.location.href =
      "mailto:comercial@bovexo.com?subject=Prazo de Experiência";
    setTimeout(() => {
      setIsLoading(false);
      handleLogout();
    }, 3000);
  }
  return (
    <StyledModal
      visible={isModalExpiredTrialAccountVisible}
      width={550}
      footer={null}
      centered
      maskClosable={false}
      closable={false}
      onCancel={closeModal}
    >
      <Spin spinning={isLoading || isLoadingRequest}>
        <main>
          <header>
            <LogoVertical />
          </header>
          {accountFarmData?.showModalToRenew ? (
            <p>
              Prazo de Experiência encerrado.
              <br />
              <strong>Contrate</strong> BovExo para a sua fazenda ou{" "}
              <strong>Estenda</strong> a experiência por mais 15 dias.
            </p>
          ) : (
            <p>
              Prazo de Experiência encerrado.
              <br />
              <strong>Contrate</strong> BovExo para a sua fazenda ou entre em
              contato com <strong>Comercial</strong> para estender a
              experiência.
            </p>
          )}
        </main>
        <footer>
          <ButtonStandard
            buttonType="type1"
            size="m"
            type="button"
            onClick={handleUpdateToContract}
          >
            Contrate
          </ButtonStandard>
          {accountFarmData?.showModalToRenew ? (
            <ButtonStandard
              buttonType="typeWithoutBackgroundWithBorder"
              size="m"
              type="button"
              onClick={handleRenewExpiration}
            >
              Estender
            </ButtonStandard>
          ) : (
            <ButtonStandard
              buttonType="typeWithoutBackgroundWithBorder"
              size="m"
              type="button"
              onClick={handleCallCommercial}
            >
              Comercial
            </ButtonStandard>
          )}
        </footer>
      </Spin>
    </StyledModal>
  );
};

export default ExpiredTrialAccountModal;
