import React from 'react';

// import { Container } from './styles';

const ExcelIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.635"
    height="18.895"
    viewBox="0 0 17.635 18.895"
  >
    <g id="excel" transform="translate(0 -15.989)">
      <g id="Group_1008" data-name="Group 1008" transform="translate(10.077 21.029)">
        <path
          id="Path_1003"
          data-name="Path 1003"
          d="M259.149,145.271H256.63a.63.63,0,1,1,0-1.26h2.519a.63.63,0,1,1,0,1.26Z"
          transform="translate(-256 -144.011)"
          fill="#fff"
        />
        <path
          id="Path_1004"
          data-name="Path 1004"
          d="M259.149,209.271H256.63a.63.63,0,1,1,0-1.26h2.519a.63.63,0,1,1,0,1.26Z"
          transform="translate(-256 -205.492)"
          fill="#fff"
        />
        <path
          id="Path_1005"
          data-name="Path 1005"
          d="M259.149,273.271H256.63a.63.63,0,1,1,0-1.26h2.519a.63.63,0,1,1,0,1.26Z"
          transform="translate(-256 -266.972)"
          fill="#fff"
        />
        <path
          id="Path_1006"
          data-name="Path 1006"
          d="M259.149,337.271H256.63a.63.63,0,1,1,0-1.26h2.519a.63.63,0,1,1,0,1.26Z"
          transform="translate(-256 -328.453)"
          fill="#fff"
        />
        <path
          id="Path_1007"
          data-name="Path 1007"
          d="M385.889,145.271h-1.26a.63.63,0,1,1,0-1.26h1.26a.63.63,0,1,1,0,1.26Z"
          transform="translate(-378.961 -144.011)"
          fill="#fff"
        />
        <path
          id="Path_1008"
          data-name="Path 1008"
          d="M385.889,209.271h-1.26a.63.63,0,1,1,0-1.26h1.26a.63.63,0,1,1,0,1.26Z"
          transform="translate(-378.961 -205.492)"
          fill="#fff"
        />
        <path
          id="Path_1009"
          data-name="Path 1009"
          d="M385.889,273.271h-1.26a.63.63,0,1,1,0-1.26h1.26a.63.63,0,1,1,0,1.26Z"
          transform="translate(-378.961 -266.972)"
          fill="#fff"
        />
        <path
          id="Path_1010"
          data-name="Path 1010"
          d="M385.889,337.271h-1.26a.63.63,0,1,1,0-1.26h1.26a.63.63,0,1,1,0,1.26Z"
          transform="translate(-378.961 -328.453)"
          fill="#fff"
        />
      </g>
      <path
        id="Path_1011"
        data-name="Path 1011"
        d="M11.109,16.135A.619.619,0,0,0,10.591,16L.514,17.891A.628.628,0,0,0,0,18.51V32.366a.629.629,0,0,0,.514.618l10.077,1.889a.593.593,0,0,0,.116.011.63.63,0,0,0,.63-.63V16.62A.628.628,0,0,0,11.109,16.135Z"
        fill="#fff"
      />
      <path
        id="Path_1012"
        data-name="Path 1012"
        d="M85.5,165.91,83.5,163.634l2.014-2.59a.63.63,0,0,0-.994-.775l-1.866,2.4-1.572-1.8a.629.629,0,1,0-.947.829l1.732,1.98-1.756,2.257a.63.63,0,0,0,1,.773l1.606-2.066,1.832,2.092a.629.629,0,1,0,.947-.828Z"
        transform="translate(-76.835 -138.367)"
        fill="#388e3c"
      />
    </g>
  </svg>
);
export default ExcelIcon;
