import { Icon } from "antd";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getLastUserTriggerRequest } from "../../../services/userTriggerService";
import BoxText from "../boxText";

// import { Container } from './styles';

function UserTriggerInProcessMessage({ triggerAction }) {
  const [result, setResult] = useState(null);
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  useEffect(() => {
    async function fetch() {
      try {
        const {
          data: { results },
        } = await getLastUserTriggerRequest({ groupId, farmId, triggerAction });
        setResult(results);
      } catch (error) {}
    }
    fetch();
  }, [groupId, farmId, triggerAction]);

  const displayMessage = useMemo(() => {
    if (result != null && triggerAction != null) {
      if (result?.status === "P") {
        let message = null;
        switch (triggerAction) {
          case "AnimalDailyCost":
            message = "Atenção! Os custos estão sendo processados.";
            break;
          default:
            break;
        }

        return (
          <BoxText
            color="#FE8D2A"
            iconComponent={<Icon type="warning" style={{ color: "#FE8D2A" }} />}
          >
            <span>{message}</span>
          </BoxText>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [result, triggerAction]);
  return <div>{displayMessage}</div>;
}

export default UserTriggerInProcessMessage;
