import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NumberFormat from "react-number-format";
import { Creators as QuotaActions } from "../../../store/ducks/quota";

// Components
import {
  Container,
  Footer,
  RadioGroupCustom,
  RadioButtonCustom,
  CustomDivider,
} from "./styles";
import { Row, Col, Select, Input, Spin, notification } from "antd";
import ButtonStandard from "../../utils/button";
import InfoTooltip from "../../utils/infoTooltip";

// Services
import { save, findById, update } from "../../../services/quotaService";

const DrawerQuota = () => {

  // Redux Variable
  const {
    translation,
    groupSelected: { id: groupId, name: groupName },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const {
    isDrawerVisible,
    quotaId,
    isLoadingDropDown,
    listDropDown,
  } = useSelector((state) => state.quota);

  const initialStateForm = {
    id: null,
    groupId: groupId,
    farmId: farmId,
    name: null,
    referenceQuotaId: null,
    gender: "B",
    cap: false,
    minAge: 0,
    maxAge: (999 * 30.417).toFixed(0), // 999 months
    minWeight: 0,
    maxWeight: 720,
    sisbov: false,
    sisbovMaxAge: false,
    sisbovMaxAgeParam: null,
    sisbovControlRelease: true,
  };

  const [form, setForm] = useState(initialStateForm);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const { Option } = Select;
  const { Group: InputGroup } = Input;

  const dispatch = useDispatch();

  // Methods
  function closeDrawer() {
    setForm(initialStateForm);
    dispatch(QuotaActions.showOrHideDrawer());
  }

  //Load dropdown
  useEffect(() => {
    if (groupId !== null && farmId !== null) {
      dispatch(QuotaActions.getDropdownQuota(groupId, farmId));
    }
  }, [groupId, farmId]);

  //Load initial data in edit
  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const { data: { results } } = await findById({ groupId, farmId, id: quotaId });

        const formValue = {
          id: quotaId,
          groupId: results.groupId,
          farmId: results.farmId,
          name: results.name,
          referenceQuotaId: results.referenceQuotaId,
          gender: results.quotaGender,
          cap: results.cap,
          minAge: results.minAge,
          maxAge: results.maxAge,
          minWeight: results.minWeight,
          maxWeight: results.maxWeight,
          sisbov: results.sisbov,
          sisbovMaxAge: results.sisbovMaxAge,
          sisbovMaxAgeParam: results.sisbovMaxAgeParam,
          sisbovControlRelease: results.sisbovControlRelease,
        }

        setForm(formValue)

      } catch (error) {
        notification.error({
          title: "Erro ao salvar",
          message: "Não foi possível salvar a cota. Contate o suporte"
        });
      } finally {
        setIsLoading(false);
      }
    }

    if (quotaId === null) {
      setForm(initialStateForm)
      setIsLoading(false);
    } else {
      fetchData();
    }
  }, [quotaId])

  useEffect(() => {
    if (isDrawerVisible) {
      setForm(initialStateForm);
    }
  }, [isDrawerVisible])

  function validateForm() {
    let aErrors = [];
    if (form.name === null || form.name.trim() === "") {
      aErrors.push("name");
    }
    if (form.gender === null || form.gender.trim() === "") {
      aErrors.push("gender");
    }
    if (form.cap === null) {
      aErrors.push("cap");
    }
    if (form.minAge === null || form.minAge === undefined) {
      aErrors.push("minAge");
    }
    if (form.maxAge === null || form.maxAge === undefined) {
      aErrors.push("maxAge");
    }
    if (form.minWeight === null || form.minWeight === undefined) {
      aErrors.push("minWeight");
    }
    if (form.maxWeight === null || form.maxWeight === undefined) {
      aErrors.push("maxWeight");
    }

    if (form.sisbov === null || form.sisbov === undefined) {
      aErrors.push("sisbov");
    }
    if (form.sisbovMaxAge === null || form.sisbovMaxAge === undefined) {
      aErrors.push("sisbovMaxAge");
    }
    if (form.sisbovMaxAge === true && (form.sisbovMaxAgeParam === null || form.sisbovMaxAgeParam === undefined || form.sisbovMaxAgeParam === 0)) {
      aErrors.push("sisbovMaxAgeParam");
    }
    if (form.sisbovControlRelease === null || form.sisbovControlRelease === undefined) {
      aErrors.push("sisbovControlRelease");
    }

    setErrors(aErrors)
    return aErrors.length > 0 ? false : true;
  }

  async function handleSubmit() {
    if (validateForm()) {
      const body = { ...form, id: quotaId };

      try {
        setIsLoading(true);
        if (body.id === null) {
          await save({ groupId, farmId, body });
          notification.success({ message: "Cota criada com sucesso" });
        } else {
          await update({ groupId, farmId, id: quotaId, body });
          notification.success({ message: "Cota atualizada com sucesso" });
        }

        dispatch(QuotaActions.index(groupId, farmId));
        closeDrawer();
      } catch (error) {
        notification.error({
          title: "Erro ao salvar",
          message: "Não foi possível salvar a cota. Contate o suporte"
        });
      } finally {
        setIsLoading(false);
      }
    }
  }

  function handleDefaultQuotaSelected(quotaId) {
    if (quotaId !== null && quotaId !== undefined) {
      const quota = listDropDown.find(q => q.id === quotaId);

      const formValue = {
        ...form,
        referenceQuotaId: quota.id,
        gender: quota.quotaGender,
        cap: quota.cap,
        minAge: quota.minAge,
        maxAge: quota.maxAge,
        minWeight: quota.minWeight,
        maxWeight: quota.maxWeight,
        sisbov: quota.sisbov,
        sisbovMaxAge: quota.sisbovMaxAge,
        sisbovMaxAgeParam: quota.sisbovMaxAgeParam,
        sisbovControlRelease: quota.sisbovControlRelease,
      };

      setForm({
        ...formValue
      });
    } else {
      setForm({
        ...form,
        referenceQuotaId: null
      });
    }
  }

  return (
    <Container
      title={
        quotaId === null
          ? "Cadastro de Cotas"
          : "Editar Cota"
      }
      width={640}
      onClose={closeDrawer}
      maskClosable={false}
      visible={isDrawerVisible}
    >
      <div className="drawerForm">
        <Spin spinning={isLoading}>
          <Row type="flex">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <span className="subTitle">
                Preencha os campos abaixo para cadastrar uma cota
              </span>
            </Col>
          </Row>
          {errors.length > 0 && (
            <Row type="flex" justify="center" align="middle">
              <label className="error">{translation.error.formError}</label>
            </Row>
          )}
          {form?.id === null && (
            <>
              <Row className="rowLabel" type="flex" gutter={8} justify="space-between" align="middle">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <label className={errors.includes("groupId") ? "error" : ""}>
                    Quem irá utilizar a Cota?*
                  </label>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <RadioGroupCustom
                    defaultValue={
                      form?.id === null
                        ? "onlyFarm"
                        : form.farmId === null
                          ? "allFarmsGroup"
                          : "onlyFarm"
                    }
                    buttonStyle="solid"
                  >
                    <RadioButtonCustom
                      value="onlyFarm"
                      onChange={(e) => {
                        setForm({
                          ...form,
                          farmId: farmId,
                          groupId: groupId,
                        });
                      }}
                    >
                      Somente esta fazenda
                    </RadioButtonCustom>
                    <RadioButtonCustom
                      value="allFarmsGroup"
                      onChange={(e) => {
                        setForm({
                          ...form,
                          farmId: null,
                          groupId: groupId,
                        });
                      }}
                    >
                      Fazendas do grupo:{" "}
                      {`${groupName.length > 10
                        ? `${groupName.substr(0, 10)}...`
                        : groupName
                        }`}
                    </RadioButtonCustom>
                  </RadioGroupCustom>
                </Col>

              </Row>
              <CustomDivider dashed />
            </>
          )}

          {/* Name */}
          <Row className="rowLabel" type="flex" gutter={8} justify="space-between" align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <label className={errors.includes("name") ? "error" : ""}>
                Nome*
              </label>
              <Input
                name="name"
                value={form?.name != null ? form?.name : undefined}
                type="name"
                autoComplete="chrome-off"
                placeholder={translation.defaultPlaceholder}
                onChange={(e) => {
                  setForm({ ...form, name: e.target.value });
                  setErrors(errors.filter((e) => e !== "name"));
                }}
              />
            </Col>
          </Row>

          {/* Reference Quota */}
          <Row className="rowLabel" type="flex" gutter={8} justify="space-between" align="middle">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <label>Cota de Referência</label>
              <Select
                value={form?.referenceQuotaId !== null && !isLoadingDropDown ? form?.referenceQuotaId : undefined}
                loading={isLoadingDropDown}
                showSearch
                allowClear
                placeholder={translation.defaultPlaceholder}
                name="referenceQuotaId"
                onChange={(value) => handleDefaultQuotaSelected(value)}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {listDropDown !== null && listDropDown !== undefined && listDropDown?.length > 0 &&
                  (listDropDown.map((item) => (<Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>)))
                }
              </Select>
            </Col>
          </Row>

          {/* Gender and Cap */}
          <Row className="rowLabel" type="flex" gutter={8} justify="space-between" align="middle">
            {/* Gender */}
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <label
                className={
                  errors.includes("gender") ? "error" : ""
                }
              >
                Sexo*
              </label>
              <Select
                value={form.gender || undefined}
                placeholder={translation.defaultSelectPlaceholder}
                name="gender"
                onChange={(value) => {
                  setForm({
                    ...form,
                    gender: value
                  })
                }}
              >
                <Option key="both" value={'B'}>
                  Ambos
                </Option>
                <Option key="male" value={'M'}>
                  Macho
                </Option>
                <Option key="female" value={'F'}>
                  Fêmea
                </Option>
              </Select>
            </Col>

            {/* Cap */}
            {form?.gender === 'B' || form?.gender === 'M' ? (
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <label>Animal Castrado?</label>
                <RadioGroupCustom
                  value={form.cap}
                  buttonStyle="solid"
                >
                  <RadioButtonCustom
                    value={true}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        cap: e.target.value,
                      });
                    }}
                  >
                    Sim
                  </RadioButtonCustom>
                  <RadioButtonCustom
                    value={false}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        cap: e.target.valuee,
                      });
                    }}
                  >
                    Não
                  </RadioButtonCustom>
                </RadioGroupCustom>
              </Col>

            ) : null}
          </Row>

          {/* MinAge and MaxAge */}
          <Row className="rowLabel" type="flex" gutter={8} justify="space-between" align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <label>Idade para Venda</label>
            </Col>

            {/* MinAge */}
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <label className={errors.includes("minAge") ? "error" : ""}>
                Idade mínima para venda*
              </label>
              <InputGroup compact>
                <Input
                  type="number"
                  style={{ width: 80, textAlign: "center" }}
                  value={form != null ? form.minAge : null}
                  name="ageDay"
                  min="0"
                  placeholder="Dias"
                  onChange={(e) => {
                    setForm({
                      ...form,
                      minAge: e.target.value > 0 ? e.target.value : 0,
                    });
                    setErrors(errors.filter((e) => e !== "minAge"));
                  }}
                />
                <Input
                  style={{
                    width: 80,
                    textAlign: "center",
                    borderLeft: "1px solid #c4c4c4",
                  }}
                  type="number"
                  name="ageMonth"
                  min="0"
                  placeholder="Meses"
                  value={
                    form != null && form.minAge != null
                      ? Number(form.minAge / 30.417).toFixed(0)
                      : null
                  }
                  onChange={(e) => {
                    setForm({
                      ...form,
                      minAge:
                        e.target.value > 0
                          ? Math.floor(e.target.value * 30.417)
                          : 0,
                    });
                    setErrors(errors.filter((e) => e !== "minAge"));
                  }}
                />
                <Input
                  style={{
                    width: 80,
                    textAlign: "center",
                    borderLeft: "1px solid #c4c4c4",
                  }}
                  type="number"
                  name="ageYear"
                  min="0"
                  placeholder="Anos"
                  value={
                    form != null && form.minAge != null
                      ? form.minAge >= 365
                        ? Math.floor(form.minAge / 365)
                        : 0
                      : null
                  }
                  onChange={(e) => {
                    setForm({
                      ...form,
                      minAge:
                        e.target.value > 0 ? e.target.value * 365 : 0,
                    });
                    setErrors(errors.filter((e) => e !== "minAge"));
                  }}
                />
              </InputGroup>
            </Col>

            {/* MaxAge */}
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <label className={errors.includes("maxAge") ? "error" : ""}>
                Idade máxima para venda*
              </label>
              <InputGroup compact>
                <Input
                  type="number"
                  style={{ width: 80, textAlign: "center" }}
                  value={form != null ? form.maxAge : null}
                  name="ageDay"
                  min="0"
                  placeholder="Dias"
                  onChange={(e) => {
                    setForm({
                      ...form,
                      maxAge: e.target.value > 0 ? e.target.value : 0,
                    });
                    setErrors(errors.filter((e) => e !== "maxAge"));
                  }}
                />
                <Input
                  style={{
                    width: 80,
                    textAlign: "center",
                    borderLeft: "1px solid #c4c4c4",
                  }}
                  type="number"
                  name="ageMonth"
                  min="0"
                  placeholder="Meses"
                  value={
                    form != null && form.maxAge != null
                      ? Number(form.maxAge / 30.417).toFixed(0)
                      : null
                  }
                  onChange={(e) => {
                    setForm({
                      ...form,
                      maxAge:
                        e.target.value > 0
                          ? Math.floor(e.target.value * 30.417)
                          : 0,
                    });
                    setErrors(errors.filter((e) => e !== "maxAge"));
                  }}
                />
                <Input
                  style={{
                    width: 80,
                    textAlign: "center",
                    borderLeft: "1px solid #c4c4c4",
                  }}
                  type="number"
                  name="ageYear"
                  min="0"
                  placeholder="Anos"
                  value={
                    form != null && form.maxAge != null
                      ? form.maxAge >= 365
                        ? Math.floor(form.maxAge / 365)
                        : 0
                      : null
                  }
                  onChange={(e) => {
                    setForm({
                      ...form,
                      maxAge:
                        e.target.value > 0 ? e.target.value * 365 : 0,
                    });
                    setErrors(errors.filter((e) => e !== "maxAge"));
                  }}
                />
              </InputGroup>
            </Col>
          </Row>

          {/* MinWeight and MaxWeight */}
          <Row className="rowLabel" type="flex" gutter={8} justify="space-between" align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <label>Peso para Venda</label>
            </Col>

            {/* MinWeight */}
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <label
                className={errors.includes("minWeight") ? "error" : ""}
              >
                Peso mínimo para venda*
              </label>
              <NumberFormat
                customInput={Input}
                value={form?.minWeight}
                placeholder={translation.defaultPlaceholder}
                name="minWeight"
                addonAfter="Kg"
                decimalScale={"2"}
                onValueChange={({ floatValue }) => {
                  setForm({
                    ...form,
                    minWeight: floatValue >= 0 ? floatValue : null,
                  });
                  setErrors(errors.filter((e) => e !== "minWeight"));
                }}
              />
            </Col>

            {/* MaxWeight */}
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Row type="flex" gutter={8} justify="start" align="middle">
                <label
                  className={errors.includes("maxWeight") ? "error" : ""}
                >
                  Peso máximo para venda*
                </label>
              </Row>
              <Row>
                <NumberFormat
                  customInput={Input}
                  value={form?.maxWeight}
                  placeholder={translation.defaultPlaceholder}
                  name="maxWeight"
                  addonAfter="Kg"
                  decimalScale={"2"}
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      maxWeight: floatValue >= 0 ? floatValue : null,
                    });
                    setErrors(errors.filter((e) => e !== "maxWeight"));
                  }}
                />
              </Row>
            </Col>
          </Row>

          {/* sisbov */}
          <Row className="rowLabel" type="flex" gutter={8} justify="space-between" align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <label>Rastreabilidade (SISBOV)?</label>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <RadioGroupCustom
                value={form.sisbov}
                buttonStyle="solid"
              >
                <RadioButtonCustom
                  value={true}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      sisbov: e.target.value,
                      sisbovMaxAge: false,
                      sisbovMaxAgeParam: null,
                      sisbovControlRelease: true,
                    });
                  }}
                >
                  Sim
                </RadioButtonCustom>
                <RadioButtonCustom
                  value={false}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      sisbov: e.target.value,
                      sisbovMaxAgeParam: null,
                      sisbovControlRelease: false,
                    });
                  }}
                >
                  Não
                </RadioButtonCustom>
              </RadioGroupCustom>
            </Col>
          </Row>

          {form?.sisbov === true ? (
            <>
              {/* sisbov max age */}
              <Row className="rowLabel" type="flex" gutter={8} justify="space-between" align="middle">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <label className={errors.includes("sisbovMaxAge") || errors.includes("sisbovMaxAgeParam") ? "error" : ""}>
                    Idade máxima na identificação SISBOV?
                    <InfoTooltip title={"Informar a idade máxima do animal, na data da Identificação SISBOV."} />
                  </label>
                </Col>

                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <RadioGroupCustom
                    value={form.sisbovMaxAge}
                    buttonStyle="solid"
                  >
                    <RadioButtonCustom
                      value={true}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          sisbovMaxAge: e.target.value,
                        });
                      }}
                    >
                      Sim
                    </RadioButtonCustom>
                    <RadioButtonCustom
                      value={false}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          sisbovMaxAge: e.target.value,
                        });
                      }}
                    >
                      Não
                    </RadioButtonCustom>
                  </RadioGroupCustom>
                </Col>

                {/* sisbov max age param */}
                {form?.sisbovMaxAge === true ? (
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <NumberFormat
                      customInput={Input}
                      value={form?.sisbovMaxAgeParam}
                      placeholder={translation.defaultPlaceholder}
                      name="sisbovMaxAgeParam"
                      addonAfter="Meses"
                      decimalScale={"2"}
                      onValueChange={({ floatValue }) => {
                        setForm({
                          ...form,
                          sisbovMaxAgeParam: floatValue >= 0 ? floatValue : null,
                        });
                        setErrors(errors.filter((e) => e !== "sisbovMaxAgeParam"));
                      }}
                    />
                  </Col>
                ) : null}
              </Row>

              {/* sisbovControlRelease */}
              <Row className="rowLabel" type="flex" gutter={8} justify="space-between" align="middle">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <label>
                    Controle de Liberação SISBOV?
                    <InfoTooltip title={"Opções para permitir vender somente animais, que tenham cumprido a noventena."} />
                  </label>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <RadioGroupCustom
                    value={form.sisbovControlRelease}
                    buttonStyle="solid"
                  >
                    <RadioButtonCustom
                      value={true}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          sisbovControlRelease: e.target.value,
                        });
                      }}
                    >
                      Sim
                    </RadioButtonCustom>
                    <RadioButtonCustom
                      value={false}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          sisbovControlRelease: e.target.value,
                        });
                      }}
                    >
                      Não
                    </RadioButtonCustom>
                  </RadioGroupCustom>
                </Col>
              </Row>
            </>
          ) : null}

        </Spin>
      </div>

      <Footer>
        <Row type="flex">
          <Col span={24} className="buttonsDiv">
            <ButtonStandard
              type="button"
              buttonType="type7"
              onClick={closeDrawer}
            >
              {translation.buttons.cancel}
            </ButtonStandard>

            <ButtonStandard
              type="button"
              buttonType="type6"
              onClick={handleSubmit}
            >
              {translation.buttons.save}
            </ButtonStandard>
          </Col>
        </Row>
      </Footer>
    </Container>
  );
};

export default DrawerQuota;
