import styled from "styled-components";
import { Card } from "antd";

export const CardCustom = styled(Card)`
  margin-top: 13px !important;
  border-radius: 7px !important;
  background: #fff !important;
  box-shadow: 0px 0px 10px #d8d8d8 !important;
  height: 580px;
  overflow-y: auto;
  padding: 14px 0 5px 0 !important;
  .ant-card-body {
    padding-top: 14px;
  }
  .rowHeader {
    margin-bottom: 17px;
  }
  .iconError {
    color: #d44c4c;
  }
  .iconCheck {
    color: #4b4b4b;
  }
  @media screen and (max-height: 700px) {
    height: 440px;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 6px;
  }
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;
