import styled from "styled-components";

export const Container = styled.div`
  width: calc(100% + 10px);
  margin-right: -10px;
  height: 100%;

  & > .ant-row-flex {
    height: 100%;
  }

  .ant-row,
  .ant-row-flex {
    width: 100%;
  }

  .mainContainer {
    height: 100%;

    overflow-y: auto;

    padding-top: 24px;
    padding-right: 24px;

    .titleContainer {
      display: flex;
      align-items: center;
      .ant-select {
        width: 300px !important;
        margin-left: 15px;
      }

      .checkIconBovexo {
        margin-left: 10px;
      }

      .inputResultId {
        width: 300px !important;
        margin-left: 15px;
      }

      .editIcon {
        background: transparent;
        border: none;
        color: #8a50a5;
        margin-left: 5px;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }

  .containerParameters {
    height: 100%;

    overflow-y: auto;

    padding: 24px;
    background-color: #f5f5f5;

    .buttonFlag {
      border: none;
      cursor: pointer;
      background-color: transparent;
      font-size: 24px;
      color: #8a50a5;
    }

    p.title {
      font-family: "Asap", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      margin: 0;
    }

    .buttonClose {
      position: absolute;
      top: 0;
      right: 30px;
      border: none;
      cursor: pointer;
      background-color: transparent;
      font-size: 24px;
      color: #8a50a5;
    }

    .buttonsRows {
      padding-top: 24px;
      & > button:nth-child(2) {
        margin-left: 16px;
      }
    }
  }

  .ant-card {
    border-radius: 10px;
  }

  .ant-collapse:not(:first-of-type) {
    margin-top: 24px;
  }

  .ant-collapse > .ant-collapse-item:last-child,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 10px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    position: relative;
    padding: 12px 16px;
    padding-left: 40px;
    line-height: 22px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-family: "Asap", sans-serif;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #8a50a5;
    background-color: #fff;
  }

  .ant-collapse-content {
    border-top: none;
  }

  .ant-collapse > .ant-collapse-item {
    box-shadow: rgb(216, 216, 216) 0px 5px 3px;
  }

  .ant-collapse-arrow {
    box-shadow: rgb(216, 216, 216) 0px 6px 2px;
    padding: 2px;
  }

  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    color: #8a50a5;
  }
`;

export const Title = styled.h1`
  font-family: "Asap", sans-serif;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: #4b4b4b;
  margin: 0;
`;
