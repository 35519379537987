// import axios from 'axios';
import apiFarms from "../config/api_farms";
import getHeaders from "./getHeaders";

export function getPastureIndex(payload) {
  const { groupId, farmId, signal } = payload;

  //const { groupId, farmId, page, sorter, filters, size } = payload;

  //const { field, order } = sorter;

  //const orderS = order === "descend" ? "DESC" : "ASC";

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/pastures`,
    getHeaders(false, signal)
  );
}

export function getPastureIndexDropDown(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/pastures/active`,
    getHeaders(false, signal)
  );
}

export function getPastureShow(payload) {
  const { groupId, farmId, id, signal } = payload;

  if (farmId) {
    return apiFarms.get(
      `/bovexo/${groupId}/farms/${farmId}/pastures/${id}`,
      getHeaders(false, signal)
    );
  }

  return apiFarms.get(
    `/bovexo/${groupId}/pastures/${id}`,
    getHeaders(false, signal)
  );
}

export function getPastureTemplateShow(payload) {
  const { id } = payload;
  return apiFarms.get(`/bovexo/pasture/types/${id}/template`, getHeaders());
}

export function getPastureBySeasonShow(payload) {
  const { groupId, farmId, id, season, signal } = payload;
  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/pastures/${id}/season/${season}`,
    getHeaders(false, signal)
  );
}

export function savePasture(payload) {
  const { groupId, farmId, id, pasture, signal } = payload;
  if (id != null) {
    let urlTocall = `/bovexo/${groupId}/farms/${farmId}/pastures/${id}`;

    // if(farmId == null){
    //   urlTocall = `/bovexo/${groupId}/pastures/${id}`;
    // }

    return apiFarms.put(urlTocall, pasture, getHeaders(false, signal));
  } else {
    return apiFarms.post(
      `/bovexo/${groupId}/farms/${farmId}/pastures`,
      pasture,
      getHeaders(false, signal)
    );
  }
}

export function deletePastureServ(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiFarms.delete(
    `/bovexo/${groupId}/farms/${farmId}/pastures/${id}`,
    getHeaders(false, signal)
  );
}

export function getPastureByType() {
  return apiFarms.get(`/bovexo/pasture/types`, getHeaders());
}

export function getPastureTypeIndexDropdown() {
  return apiFarms.get(`/bovexo/pasture/types`, getHeaders());
}

export function getPastureTemplateByTypeId(groupId, farmId, id) {
  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/pasture/types/${id}/template`,
    getHeaders()
  );
}
