import styled from "styled-components";
import { Tabs } from "antd";

export const Container = styled.div`
  overflow-y: auto;
  overflow-x: hidden;

  .alertSelected {
    background: #fe8d2a;
    border: 1px solid #fe8d2a;
  }

  .alertDate {
    .ant-calendar-picker-input.ant-input {
      line-height: 1.5;
      padding: 4px 5px;
      width: 85px;
      border: 0px;
      color: #d44c4c !important;
      font-weight: bold;
    }

    svg {
      height: 10px;
      width: 10px;
      path {
        fill: #d44c4c !important;
      }
    }
  }

  .cardDashAnimal {
    height: 180px !important;
    margin-top: 10px;

    .ant-card-body {
      padding: 20px !important;
    }
  }

  .cardGrid {
    margin-top: 10px;
    margin-bottom: 10px;
    .btn-comparable {
      &:disabled {
        background: #f0f0f0 !important;
        color: #adadad !important;
      }
    }
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }

  .link {
    color: #4b4b4b !important;
    text-decoration: none !important;
    &:hover {
      border-bottom: 1px solid #4b4b4b !important;
    }
  }

  .columnTitleContainer {
    width: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .todayColumn {
    border: solid 2px black;
    border-bottom: none;
    border-top: none;
  }

  button.linkLot {
    background: transparent;
    border: 0;
    cursor: pointer;
    text-align: left;
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
    height: 600px;
    overflow-y: scroll;
  }
`;

export const PageTitle = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  span {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #c4c4c4;
    text-transform: uppercase;
  }
  #bovexoAnimalIcon {
    width: 25px;
    height: 25px;
    margin-right: 9px;
    path {
      fill: #c4c4c4;
    }
  }
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;

export const NewAnimal = styled.button`
  padding: 5px 15px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #684e94;
  color: #684e94;
  text-align: center;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
`;

export const DashboardActivity = styled.div`
  min-height: 114px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 3px 6px #d8d8d8;
  margin-top: 10px;
  padding: 23px 13px 20px 20px;

  span {
    font-family: "Asap", sans-serif;
    text-align: left;
    font-size: 14px;
    color: #4b4b4b;

    &.title {
      font-weight: bold;
      font-style: italic;
      color: #9074bf;
      text-transform: uppercase;
    }

    &.subtitle {
      font-weight: bold;
      color: #9074bf;
      font-size: 12px;
      text-transform: uppercase;
    }

    &.value {
      font-weight: bold;
    }
  }
`;

export const GenerateReport = styled.button`
  padding: 5px 15px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #a9c133;
  color: #a9c133;
  text-align: center;
  font-size: 14px;
  margin-right: 10px;
  &:hover {
    cursor: pointer;
  }
  &.disabled {
    cursor: not-allowed;
    border-color: #adadad;
    color: #adadad;
  }
`;

export const AlertButton = styled.button`
  margin-left: 5px;
  padding: 0px 5px;
  border-radius: 5px;
  height: 34px;
  background: #d44c4c;
  border: 1px solid #d44c4c;
  color: #ffffff;
  text-align: center;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }

  svg {
    margin-left: 5px;
  }
`;

export const HeaderPanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  div.amountAnimals {
    padding: 0px 8px;

    label {
      font-family: "Asap", sans-serif;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: inherit;
    }

    span {
      padding-left: 5px;
      font-family: "Asap", sans-serif;
      font-weight: bold;
      font-size: 14px;
      text-align: left;
      color: #a9c133;
      margin-right: 10px;
    }
  }
`;

export const TabContainer = styled(Tabs)`
  .ant-tabs-ink-bar {
    background-color: #684e94;
    height: 5px;
  }

  .ant-tabs-line {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab {
    font-family: "Asap" !important;
    font-size: 14px !important;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab-disabled,
  .ant-tabs-nav .ant-tabs-tab-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: #4b4b4b;
    font-weight: bold;
  }

  .ant-tabs-bar {
    border-bottom: 1px solid #a9c133;
  }

  .ant-tabs-tabpane {
    background-color: #fff;
    padding: 0 10px 20px;
  }
`;
