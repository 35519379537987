import styled from "styled-components";
import { Menu } from "antd";

const { Item } = Menu;

export const Container = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 28px 5px 0px 5px;

  .iconBack {
    color: #d44c4c;
    font-size: 16px;
  }

  div.inputWithButton {
    display: flex;
    align-items: center;

    button {
      cursor: pointer;
      margin-left: 5px;
    }
  }
  
  .inputGroupWithIcon {
    display: flex;

    button {
      background: transparent;
      border: none;
      cursor: pointer;
      margin-left: 10px;
    }
  }

  .buttonTrash {
    background: transparent;
    border: none;
    cursor: pointer;
  }

  span.message {
    font-family: Asap;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
    color: #4b4b4b;
  }

  .ant-row:not(:first-of-type),
  .ant-row-flex:not(:first-of-type) {
    margin-top: 15px;
  }

  .colWithBorderLeft {
    border-left: 1px solid #c4c4c4;
  }

  .header {
    margin-bottom: 10px;
  }

  div.inputContainer {
    min-height: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-right: 20px;
    &:not(:first-of-type) {
      margin-top: 10px;
    }
    div.inputGroup {
      display: flex;
      flex-direction: column;
      label {
        font-family: "Asap", sans-serif;
        font-weight: bold;
        font-size: 14px;
        text-align: left;
        color: #9074bf;
        margin-left: 15px;
        margin-bottom: 7px;

        &.error {
          color: #d44c4c;
        }
      }
      span {
        font-family: "Asap", sans-serif;
        font-weight: bold;
        strong {
          font-size: 16px;
          margin-left: 15px;
          &.colored {
            color: #9074bf;
          }
        }
      }
      &.clientName {
        flex: 1;
        @media screen and (max-width: 768px) {
          min-width: 100%;
        }
      }
      &:not(:first-of-type) {
        margin-left: 15px;
        @media screen and (max-width: 768px) {
          margin-left: 0px;
        }
      }
      @media screen and (max-width: 768px) {
        margin-left: 0px;
        min-width: 100%;
      }
    }
  }
  div.infoContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    &:not(:first-of-type) {
      margin-top: 10px;
    }
    div.infoGroup {
      display: flex;
      flex-direction: column;
      svg {
        margin-left: 5px;
      }
      span {
        font-family: Asap;
        font-weight: normal;
        font-size: 14px;
        text-align: left;
        color: #4b4b4b;

        strong {
          font-size: 24px;
          &.colored {
            color: #9074bf;
          }
        }
      }

      &:not(:first-of-type) {
        margin-left: 15px;
      }
    }
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  div {
    align-items: center;
  }
  svg {
    margin-right: 5px;
  }
  span {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;
  }
  div.buttonContainer {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 5px 5px 5px 0px;

    button:first-of-type {
      margin-right: 5px;
    }
  }
`;

export const ContainerDropdown = styled.div`
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 17px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-radius: 29px !important;
  outline: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
`;

export const ItemMenu = styled(Item)`
  height: 120px !important;
  width: 415px !important;
  border-bottom: 1px solid #eeeeee;

  &.ant-dropdown-menu-item:hover {
    background-color: #f3f3f3 !important;
  }

  .title {
    font-family: Asap;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    color: #684e94;
  }

  .client {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #a9c133;
  }

  .info {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;
  }

  .info-bold {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;
  }
`;

export const ItemMenuSmall = styled(Item)`
  height: 90px !important;
  width: 415px !important;
  border-bottom: 1px solid #eeeeee;

  &.ant-dropdown-menu-item:hover {
    background-color: #f3f3f3 !important;
  }

  .title {
    font-family: Asap;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    color: #684e94;
  }

  .client {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #a9c133;
  }

  .info {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;
  }

  .info-bold {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;
  }
`;
