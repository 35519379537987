import { notification } from "antd";
import React, { createContext, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { getLotPicketSupplementSupplies } from "../../services/lotService";

export const LotDetailsSupplementSupplyContext = createContext({
  dataList: [],
  isLoadingDataList: false,
  totalCost: 0,
  totalSupplyCostPerAnimal: 0,
  supplementSuppliedPerDiet: [],
  averageAnimalSupply: 0,
  averageAnimalSupplyCost: 0,
});

export const LotDetailsSupplementSupplyContextProvider = ({ children }) => {
  const [dataList, setDataList] = useState([]);
  const [isLoadingDataList, setIsLoadingDataList] = useState(false);

  const {
    app: {
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
  } = useSelector((state) => state);
  const {
    params: { id: lotId },
  } = useRouteMatch();

  // Get total cost of supplement's supplies for lot
  const totalCost = useMemo(() => {
    if (dataList.length === 0) return 0;
    return dataList.reduce(
      (prev, currentData) => prev + currentData.totalSupplyCost,
      0
    );
  }, [dataList]);
  // Get total cost per animal of supplement's supplies for lot
  const totalSupplyCostPerAnimal = useMemo(() => {
    if (dataList.length === 0) return 0;
    return dataList.reduce(
      (prev, currentData) => prev + currentData.totalSupplyCostPerAnimal,
      0
    );
  }, [dataList]);
  // Get agrouped supplement's supplies for lot by diet
  const supplementSuppliedPerDiet = useMemo(() => {
    if (dataList.length === 0) return [];
    let response = [];
    dataList.forEach((suppliedTreatData) => {
      if (response.length === 0) {
        response.push({
          dietName: suppliedTreatData.supplementName,
          dates: [suppliedTreatData.supplyDate],
          days: 1,
          amountSupplied: suppliedTreatData.treatQuantitySupplied,
          totalCost: suppliedTreatData.totalSupplyCost,
        });
      } else {
        if (
          response.findIndex(
            (r) => r.dietName === suppliedTreatData.supplementName
          ) >= 0
        ) {
          const index = response.findIndex(
            (r) => r.dietName === suppliedTreatData.supplementName
          );
          if (!response[index].dates.includes(suppliedTreatData.supplyDate)) {
            response[index].days = response[index].days + 1;
            response[index].dates = [
              ...response[index].dates,
              suppliedTreatData.supplyDate,
            ];
          }
          response[index].amountSupplied =
            response[index].amountSupplied +
            suppliedTreatData.treatQuantitySupplied;
          response[index].totalCost =
            response[index].totalCost + suppliedTreatData.totalSupplyCost;
        } else {
          response.push({
            dietName: suppliedTreatData.supplementName,
            days: 1,
            dates: [suppliedTreatData.supplyDate],
            amountSupplied: suppliedTreatData.treatQuantitySupplied,
            totalCost: suppliedTreatData.totalSupplyCost,
          });
        }
      }
    });
    return response;
  }, [dataList]);
  // Get average of animals's supply of supplement's supplies for lot
  const averageAnimalSupply = useMemo(() => {
    if (dataList.length === 0) return 0;
    const groupedByDate = dataList.reduce((acc, currentItem) => {
      const date = currentItem.supplyDate;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(currentItem);
      return acc;
    }, {});
    const amountDays = Object.keys(groupedByDate).length;
    let totalSupplied = 0;
    Object.keys(groupedByDate).forEach((date) => {
      totalSupplied += groupedByDate[date].reduce(
        (acc, currentItem) =>
          acc + currentItem.totalSupplementSuppliedInKilosPerAnimal,
        0
      );
    });
    return totalSupplied / amountDays;
  }, [dataList]);
  // Get average of animals's supply cost of supplement's supplies for lot
  const averageAnimalSupplyCost = useMemo(() => {
    if (dataList.length === 0) return 0;
    const groupedByDate = dataList.reduce((acc, currentItem) => {
      const date = currentItem.supplyDate;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(currentItem);
      return acc;
    }, {});
    const amountDays = Object.keys(groupedByDate).length;
    let totalSuppliedCost = 0;
    Object.keys(groupedByDate).forEach((date) => {
      totalSuppliedCost += groupedByDate[date].reduce(
        (acc, currentItem) => acc + currentItem.totalSupplyCostPerAnimal,
        0
      );
    });
    return totalSuppliedCost / amountDays;
  }, [dataList]);

  const fetchData = useCallback(async () => {
    setIsLoadingDataList(true);
    try {
      const {
        data: { results },
      } = await getLotPicketSupplementSupplies({
        groupId,
        farmId,
        lotId,
      });
      setDataList(results);
    } catch (error) {
      notification.error({
        message: "Erro ao trazer dados de fornecimento. Contate o suporte",
      });
    } finally {
      setIsLoadingDataList(false);
    }
  }, [groupId, farmId, lotId]);

  return (
    <LotDetailsSupplementSupplyContext.Provider
      value={{
        dataList,
        fetchData,
        isLoadingDataList,
        totalCost,
        totalSupplyCostPerAnimal,
        supplementSuppliedPerDiet,
        averageAnimalSupply,
        averageAnimalSupplyCost,
      }}
    >
      {children}
    </LotDetailsSupplementSupplyContext.Provider>
  );
};
