import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import CanvasJSReact from "../../../../../assets/canvasjs.react";
import { Tooltip } from "antd";
import InfoIcon from "../../../../../components/utils/icons/info";
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../../../services/helpersMethodsService";

const { CanvasJSChart } = CanvasJSReact;
const BarTotalWeightChart = (props) => {
  const { translation } = useSelector((state) => state.app);

  const { animalData, animalTotalDailyWeightDashboard } = useSelector(
    (state) => state.animal
  );

  const options = {
    height: 250,
    dataPointWidth: 36,
    axisY: {
      valueFormatString: "0.00",
      includeZero: true,
      gridDashType: "dot",
      maximum:
        animalTotalDailyWeightDashboard != null && animalData != null
          ? Math.max.apply(
              2,
              animalTotalDailyWeightDashboard.map((a) => a.y)
            ) > 720
            ? Math.round(
                Math.max.apply(
                  2,
                  animalTotalDailyWeightDashboard.map((a) => a.y)
                ) +
                  Math.max.apply(
                    2,
                    animalTotalDailyWeightDashboard.map((a) => a.y)
                  ) *
                    1
              )
            : 720
          : 720,
      stripLines: [
        {
          value:
            animalData != null && animalData?.currentWeight != null
              ? Number(animalData?.currentWeight).toFixed(2)
              : 0,
          color: "#4B4B4B",
          thickness: 3,
          labelPlacement: "inside",
          labelFontColor: "#4B4B4B",
          labelAlign: "far",
          showOnTop: true,
          label: `Último: ${
            animalData != null && animalData?.currentWeight != null
              ? numberMask(
                  getTwoDecimalDigits(animalData?.currentWeight),
                  false
                )
              : 0
          } Kg`,
        },
      ],
    },
    toolTip: {
      enabled: false, //enable here
    },
    data: [
      {
        type: "column",
        yValueFormatString: "0.00",
        indexLabel: "{y}",
        indexLabelPlacement: "inside",
        indexLabelFontSize: 12,
        dataPoints: animalTotalDailyWeightDashboard,
      },
    ],
  };
  return (
    <Fragment>
      <span className="detailTitle">
        {translation.animal.details.totalWeight.title}
        <Tooltip title={translation.animal.details.totalWeight.tooltip}>
          <InfoIcon />
        </Tooltip>
      </span>
      <div className="chartDiv">
        <CanvasJSChart options={options} />
      </div>
    </Fragment>
  );
};

export default BarTotalWeightChart;
