import React, { useState, useEffect } from "react";
import moment from "moment";
import { withRouter, useHistory } from "react-router-dom";
/** Redux libs */
import { useSelector, useDispatch } from "react-redux";
import { Creators as SaleScenarioActions } from "../../store/ducks/saleScenario";
import { Creators as AppActions } from "../../store/ducks/app";
/** Components */
import { Container, CardCustom, Title } from "./styles";
import {
  Row,
  Col,
  Table,
  Menu,
  Button,
  Input,
  Icon,
  Dropdown,
  notification,
  Tooltip,
  Modal,
  Select,
} from "antd";
import ButtonStandard from "../../components/utils/button";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import TagStatus from "../../components/utils/tagStatus";
import AlertDateIcon from "../../components/utils/icons/alertDate";
import MenuIcon from "../../components/utils/table/icons/menu";
import DrawerEditSaleScenarioParameters from "../../components/drawers/editSaleScenarioParameters";
import CustomDivider from "../../components/utils/customDivider";
import DecisionInAndOutAnimalsDash from "./dashboards/decisionInAndOutAnimalsDash";
import DecisionProfitAndCostDash from "./dashboards/decisionProfitAndCostDash";
import DecisionMarketAnalysisDash from "./dashboards/decisionMarketAnalysisDash";
/** Services */
import {
  indexSaleScenario,
  getAllSaleScenarioResultByIds,
  reprocessSaleScenario,
  inactiveSaleScenario,
} from "../../services/saleScenarioService";
import { showAlertsByDateAndEntity } from "../../services/alertService";
import { numberMask } from "../../services/helpersMethodsService";
import AlertDatePicker from "../../components/utils/alertDatePicker";
import AlertButton from "../../components/utils/alertButton";
import CompareButton from "../../components/utils/compareButton";
import ClosedSalesReportModal from "../../components/modals/closedSalesReport";
import PriceLock from "../../components/utils/icons/priceLock";
import InfoTooltip from "../../components/utils/infoTooltip";
import NumberFormat from "react-number-format";

const ListSaleScenario = () => {
  const [data, setData] = useState(null);
  const [resultsData, setResultsData] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [tableChange, setTableChange] = useState({
    page: 0,
    size: 10,
    sorter: null,
    filters: "",
  });
  const [alerts, setAlerts] = useState([]);
  const [alertsFilterDate, setAlertsFilterDate] = useState(moment());
  const [alertIds, setAlertIds] = useState(null);
  const [currentGroupId, setCurrentGroupId] = useState(null);
  const [currentFarmId, setCurrentFarmId] = useState(null);
  const [selectedResults, setSelectedResults] = useState([]);
  const [isModalClosedSalesReportVisible, setIsModalClosedSalesReportVisible] =
    useState(false);
  const [isModalCompareScenarioVisible, setIsModalCompareScenarioVisible] =
    useState(false);
  const [presentValueTax, setPresentValueTax] = useState(null);
  const [presentValueTaxUnit, setPresentValueTaxUnit] = useState("MONTH");

  const rowSelection = {
    selectedRowKeys: selectedResults,
    getCheckboxProps: (record) => ({
      disabled:
        record.amountAnimals === 0 ||
        (!selectedResults.includes(record.id) && selectedResults.length >= 5),
    }),
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      if (selected && record.amountAnimals > 0) {
        setSelectedResults((old) => [...old, record.id]);
      } else {
        setSelectedResults((old) => old.filter((id) => id !== record.id));
      }
    },
    onSelectAll: (record, selected, selectedRows, nativeEvent) => {
      // Get just the ids
      const keys = selectedRows
        .filter((sr) => sr.amountAnimals > 0)
        .map((sr) => sr.id);
      // Get all the selectedResults unless the 'keys'
      const othersSelectedResultsKeys = selectedResults.filter(
        (sAK) => !keys.includes(sAK)
      );

      if (selected.length > 0) {
        const sliceKeys = keys.slice(
          0,
          othersSelectedResultsKeys.length > 0
            ? 5 - othersSelectedResultsKeys.length
            : keys.length > 5
            ? 5
            : keys.length
        );
        setSelectedResults([...othersSelectedResultsKeys, ...sliceKeys]);
      } else {
        setSelectedResults(othersSelectedResultsKeys);
      }
    },
  };
  /** Props */
  const history = useHistory();
  /** Redux libs */
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
    alertSelected,
  } = useSelector((state) => state.app);
  const { refreshTable } = useSelector((state) => state.saleScenario);
  const dispatch = useDispatch();
  /** Effects */
  useEffect(() => {
    async function fetch() {
      setLoadingTable(true);
      let ids = [];
      let alertIdsS = null;
      let selectedDate = moment().format("YYYY-MM-DD");

      // Get SaleScenario Data
      try {
        if (alertSelected != null && alertSelected.entity === "sale_scenario") {
          if (alertSelected.alertPage) {
            selectedDate = alertSelected.executionDate;
            alertIdsS = "'" + alertSelected.entityIds.join("','") + "'";
            setAlertsFilterDate(moment(selectedDate, "YYYY-MM-DD"));
            setAlertIds(alertIdsS);
          } else {
            dispatch(AppActions.setAlertSelected({}));
          }
        }
        const {
          data: { results },
        } = await indexSaleScenario({
          groupId,
          farmId,
          ids: alertIdsS,
        });
        setData(results);
        setLoadingTable(false);
        ids = results?.content?.map((o) => o.id);
      } catch (error) {
        console.error(error);
        setLoadingTable(false);
      }
      // Get SaleScenario Results
      if (ids.length > 0) {
        try {
          const {
            data: { results },
          } = await getAllSaleScenarioResultByIds({
            groupId,
            farmId,
            saleScenarioIds: ids,
          });
          setResultsData(results);
        } catch (error) {
          setResultsData([]);
          console.error(error);
        }
      }
    }

    async function getAlerts() {
      let selectedDate = moment().format("YYYY-MM-DD");
      const executionDate = selectedDate;
      const entity = "sale_scenario";
      try {
        const {
          data: { results: alertsData },
        } = await showAlertsByDateAndEntity({
          groupId,
          farmId,
          executionDate,
          entity,
        });
        setAlerts(alertsData);
      } catch (error) {
        setAlerts([]);
      }
    }

    if (groupId && farmId) {
      if (currentGroupId === null && currentFarmId === farmId) {
        setCurrentGroupId(groupId);
        setCurrentFarmId(farmId);
        fetch();
        getAlerts();
      } else if (currentGroupId !== groupId || currentFarmId !== farmId) {
        setCurrentGroupId(groupId);
        setCurrentFarmId(farmId);
        fetch();
        getAlerts();
      }
    }
  }, [groupId, farmId, alertSelected, currentGroupId, currentFarmId, dispatch]);

  /** Refresh Table */
  useEffect(() => {
    async function fetch() {
      setLoadingTable(true);
      let ids = [];
      // Get SaleScenario Data
      try {
        const {
          data: { results },
        } = await indexSaleScenario({
          groupId,
          farmId,
          ids: null,
        });
        setData(results);
        setLoadingTable(false);
        ids = results?.content?.map((o) => o.id);
        dispatch(SaleScenarioActions.refreshTable());
      } catch (error) {
        console.error(error);
        setLoadingTable(false);
      }
      // Get SaleScenario Results
      if (ids.length > 0) {
        try {
          const {
            data: { results },
          } = await getAllSaleScenarioResultByIds({
            groupId,
            farmId,
            saleScenarioIds: ids,
          });
          setResultsData(results);
        } catch (error) {
          setResultsData([]);
          console.error(error);
        }
      }
    }
    if (refreshTable) {
      if (groupId && farmId) {
        if (currentGroupId !== groupId || currentFarmId !== farmId) {
          setCurrentGroupId(groupId);
          setCurrentFarmId(farmId);
        }
        fetch();
      }
    }
  }, [groupId, farmId, currentGroupId, currentFarmId, dispatch, refreshTable]);

  /** Methods */

  function menu(id, status, record) {
    return (
      <Menu>
        {status !== "Error" && status !== "Inactive" && (
          <Menu.Item key="0" onClick={() => handleShowSaleScenario(id)}>
            Detalhes
          </Menu.Item>
        )}
        {status !== "Canceled" &&
          status !== "PartialCanceled" &&
          status !== "Analyzing" &&
          status !== "Sold" &&
          status !== "Inactive" && (
            <Menu.Item key="2" onClick={() => editParameters(id)}>
              Editar parâmetros
            </Menu.Item>
          )}
        {status !== "Canceled" &&
          status !== "PartialCanceled" &&
          status !== "Analyzing" &&
          status !== "Sold" &&
          status !== "Inactive" && (
            <Menu.Item key="3" onClick={() => handleReprocess(id)}>
              Reprocessar
            </Menu.Item>
          )}
        {(status === "Canceled" ||
          status === "PartialCanceled" ||
          status === "Inactive") && (
          <Menu.Item key="4" onClick={() => handleReactivate(id)}>
            Reativar
          </Menu.Item>
        )}
        {status === "Processed" && record.baseline === false && (
          <Menu.Item key="5" onClick={() => handleInactivate(id)}>
            Inativar
          </Menu.Item>
        )}
      </Menu>
    );
  }

  function editParameters(id) {
    dispatch(SaleScenarioActions.showEditSaleScenario(groupId, farmId, id));
  }

  function newSaleScenario() {
    dispatch(SaleScenarioActions.resetData());
    history.push("/admin/decisions/scenarios/sales/new");
  }

  async function getAlerts(date) {
    if (alertSelected?.id != null) {
      dispatch(AppActions.setAlertSelected({}));
      setAlertIds(null);
    }

    const executionDate = date.format("YYYY-MM-DD");
    const entity = "sale_scenario";
    try {
      const {
        data: { results: alertsData },
      } = await showAlertsByDateAndEntity({
        groupId,
        farmId,
        executionDate,
        entity,
      });
      setAlerts(alertsData);
      setAlertsFilterDate(date);
    } catch (error) {
      setAlerts([]);
      setAlertsFilterDate(moment());
    }
  }

  async function fetchData(
    page = 0,
    sorter = {},
    filters = "",
    size = 10,
    ids
  ) {
    setLoadingTable(true);
    let returnIds = [];
    try {
      const {
        data: { results },
      } = await indexSaleScenario({
        groupId,
        farmId,
        page,
        sorter,
        filters,
        size,
        ids,
      });
      returnIds = results?.content?.map((o) => o.id);
      setData(results);
      setLoadingTable(false);
    } catch (error) {
      setLoadingTable(false);
      setLoadingTable(false);
    }

    // Get SaleScenario Results
    if (returnIds.length > 0) {
      try {
        const {
          data: { results },
        } = await getAllSaleScenarioResultByIds({
          groupId,
          farmId,
          saleScenarioIds: returnIds,
        });
        setResultsData(results);
      } catch (error) {
        console.error(error);
      }
    }
  }

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
    };
  }

  function expandedRows(record, index, indent, expanded) {
    const columns = [
      {
        title: "Resultado(s)",
        children: [
          {
            title: "Identificação",
            dataIndex: "identification",
            key: "identification",
            align: "left",
          },
          {
            title: "Animais à vender",
            dataIndex: "amountAnimals",
            key: "amountAnimals",
            align: "left",
            render: (text, record) => (
              <span>{`${record.amountAnimals} animais`}</span>
            ),
          },
          {
            title: "Favorito",
            dataIndex: "baseline",
            key: "baseline",
            align: "center",
            render: (text, record) =>
              record.baseline === true ? (
                <Icon
                  type="check-circle"
                  theme="filled"
                  className="iconCheck"
                />
              ) : null,
          },
          {
            title: "A receber",
            dataIndex: "amountAnimalsPrice",
            key: "amountAnimalsPrice",
            align: "left",
            render: (text, record) => (
              <span>{`${numberMask(
                record.amountAnimalsPrice || 0,
                true
              )}`}</span>
            ),
          },
          {
            title: "Margem estimada",
            dataIndex: "amountAnimalsMargin",
            key: "amountAnimalsMargin",
            align: "left",
            render: (text, record) => (
              <span>{`${numberMask(
                record.amountAnimalsMargin || 0,
                true
              )}`}</span>
            ),
          },
          {
            title: "Processado às",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "left",
          },
        ],
      },
    ];
    let columnData = [];
    if (resultsData != null && resultsData.length > 0)
      resultsData.forEach((dp) => {
        if (dp.saleScenarioId === record.id) {
          columnData.push({
            key: dp.id,
            id: dp.id,
            identification: dp.identification,
            baseline: dp.baseline,
            saleScenarioId: dp.saleScenarioId,
            amountAnimals: dp.amountAnimals,
            amountAnimalsPrice: dp.amountAnimalsPrice,
            amountAnimalsMargin: dp.amountAnimalsMargin,
            createdAt: moment(dp.createdAt).format("DD/MM/YYYY HH:mm"),
          });
        }
      });

    return (
      <Table
        rowKey="id"
        bordered
        scroll={{
          x: 1200,
          y: 758,
        }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={columnData}
        pagination={false}
      />
    );
  }

  function handleSearch(selectedKeys, confirm) {
    confirm();
  }

  function handleReset(clearFilters) {
    clearFilters();
  }

  function handleTableChange(pagination, filters, sorter) {
    let search = "";

    if (Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v.length > 0) {
          if (k === "status") {
            search =
              search === ""
                ? `${k} in (${v.map((o) => (o = `'${o}'`)).join(", ")})`
                : `${search};${k} in (${v
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`;
          } else {
            search =
              search === ""
                ? `upper(${k}) like upper('%25${v}%25')`
                : `${search};upper(${k}) like upper('%25${v}%25')`;
          }
        }
      });
    } else {
      search = "";
    }

    setTableChange({
      page: pagination.current,
      size: pagination.pageSize,
      sorter,
      filters: search,
    });

    fetchData(
      pagination.current,
      sorter,
      search,
      pagination.pageSize,
      alertIds
    );
  }

  async function handleReprocess(id) {
    try {
      await reprocessSaleScenario({
        groupId,
        farmId,
        id,
      });
      notification.success({
        message:
          "Aguarde, o cenário está sendo reprocessado e logo terá um novo resultado.",
      });
      handleReloadTable();
    } catch (error) {
      const { status } = error.response;
      if (status === 400) {
        notification.error({
          message:
            "Cenário não pode der ser reprocessado, pois todos os animais já foram vendidos.",
        });
      } else if (status === 401) {
        notification.error({
          message: "Cenário não pode ser encontrado.",
        });
      } else {
        notification.error({
          message: "Cenário não pode der ser reprocessado, contate o suporte.",
        });
      }
    }
  }

  async function handleReactivate(id) {
    try {
      await reprocessSaleScenario({
        groupId,
        farmId,
        id,
      });
      notification.success({
        message:
          "Aguarde, o cenário está sendo reprocessado e logo terá um novo resultado.",
      });
      handleReloadTable();
    } catch (error) {
      const { status } = error.response;
      if (status === 400) {
        notification.error({
          message:
            "Cenário não pode der ser reativado, pois todos os animais já foram vendidos.",
        });
      } else if (status === 401) {
        notification.error({
          message: "Cenário não pode ser encontrado.",
        });
      } else {
        notification.error({
          message: "Cenário não pode der ser reativado, contate o suporte.",
        });
      }
    }
  }

  async function handleInactivate(id) {
    try {
      await inactiveSaleScenario({
        groupId,
        farmId,
        id,
      });

      notification.success({
        message: "Cenário inativado.",
      });
      handleReloadTable();
    } catch (error) {
      const { status } = error.response;
      if (status === 404) {
        notification.error({
          message: "Cenário não pode ser encontrado.",
        });
      } else {
        notification.error({
          message: "Cenário não pode der ser inativado.",
        });
      }
    }
  }

  function handleReloadTable() {
    const { page, size, sorter, filters } = tableChange;
    fetchData(page, sorter, filters, size, alertIds);
  }

  function handleShowSaleScenario(id) {
    const results = resultsData.filter((rd) => rd.saleScenarioId === id) || [];
    if (results.length > 0) {
      const resultId = results[0].id;
      history.push(
        `/admin/decisions/scenarios/sales/${id}/results/${resultId}`
      );
    }
  }

  function handleAlertSelected(alert) {
    let ids = null;
    if (
      alertSelected &&
      alertSelected.id === alert.id &&
      alertSelected.failed === alert.failed
    ) {
      dispatch(AppActions.setAlertSelected({}));
      setAlertIds(null);
    } else {
      dispatch(AppActions.setAlertSelected(alert));
      ids = "'" + alert.entityIds.join("','") + "'";
    }
    setAlertIds(ids);
    fetchData(0, {}, "", 10, ids);
  }

  function handleOpenCloseModalCompareScenario() {
    setIsModalCompareScenarioVisible((prevState) => !prevState);
    setPresentValueTax(null);
    setPresentValueTaxUnit("MONTH");
  }

  async function handleSubmitCompareScenarios() {
    try {
      const body = {
        selectedResults: selectedResults.map((id) => ({
          resultId: id,
          reference: false,
        })),
        presentValueTax,
        presentValueTaxUnit,
      };
      dispatch(SaleScenarioActions.setCompareSaleScenario(body));
      history.push("/admin/decisions/scenarios/sales/compare");
    } catch (error) {
      notification.error({
        title: "Não foi possível comparar cenários.",
      });
    }
  }

  function handleOpenCloseModalClosedSalesReport() {
    setIsModalClosedSalesReportVisible((old) => !old);
  }

  return (
    <Container>
      {/* Dashboards */}
      <Row type="flex" justify="start" gutter={8}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <DecisionInAndOutAnimalsDash />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <DecisionProfitAndCostDash />
        </Col>
      </Row>
      <Row type="flex" justify="start" gutter={8}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <DecisionMarketAnalysisDash />
        </Col>
      </Row>
      <CustomDivider dashed={true} />
      <Row
        type="flex"
        justify="start"
        gutter={4}
        align="middle"
        style={{ paddingLeft: "20px", marginTop: "10px" }}
      >
        <Col xs={24} sm={24} md={3} lg={3} xl={3}>
          <AlertDateIcon />
          <AlertDatePicker
            alertsFilterDate={alertsFilterDate}
            getAlerts={getAlerts}
          />
        </Col>
        {alerts?.length <= 0 ? (
          <span style={{ color: "#D44C4C", marginLeft: "10px" }}>
            {" "}
            Nenhum alerta de cenário de venda nessa data
          </span>
        ) : (
          alerts?.map((alert) => (
            <Col key={alert.id} xs={24} sm={24} md={5} lg={5} xl={5}>
              <AlertButton
                alert={alert}
                alertSelected={alertSelected}
                handleAlertSelected={handleAlertSelected}
              />
            </Col>
          ))
        )}
      </Row>
      <CardCustom>
        {/* Header */}
        <Row type="flex" justify="space-between" className="rowHeader">
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <Title>Cenários de venda</Title>
          </Col>
          <Col
            xs={16}
            sm={16}
            md={16}
            lg={16}
            xl={16}
            className="colButtons"
            align="right"
          >
            <ButtonStandard
              buttonType="typeWithoutBackground"
              onClick={handleReloadTable}
            >
              <Icon type="reload" />
            </ButtonStandard>
            <ButtonStandard
              buttonType="alternative"
              onClick={handleOpenCloseModalClosedSalesReport}
            >
              Resultado de Vendas
            </ButtonStandard>
            <Tooltip
              title="Somente é possível comparar 5 resultados por vez."
              trigger="hover"
            >
              <div>
                <CompareButton
                  title="Comparar cenários"
                  onClick={handleOpenCloseModalCompareScenario}
                  disable={selectedResults.length <= 1}
                />{" "}
              </div>
            </Tooltip>
            <ButtonStandard buttonType="type8" onClick={newSaleScenario}>
              Novo cenário
            </ButtonStandard>
          </Col>
        </Row>
        {/* Table */}
        <Row type="flex" justify="space-between">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              rowKey="id"
              expandedRowRender={expandedRows}
              loading={loadingTable}
              size="small"
              dataSource={data != null ? data.content : []}
              scroll={{
                x: 1200,
                y: 758,
              }}
              pagination={{
                total: data != null ? data.totalElements : 0,
                size: data != null ? data.size : 0,
                current: data != null ? data.number + 1 : 0,
                showSizeChanger: true,
                pageSizeOptions: [
                  "10",
                  "20",
                  "30",
                  "100",
                  "500",
                  "1000",
                  "2000",
                ],
                hideOnSinglePage:
                  data !== null && Object.entries(data).length !== 0
                    ? data.totalElements > 10
                      ? false
                      : true
                    : true,
              }}
              style={{ width: "100%", margin: "0" }}
              onChange={handleTableChange}
            >
              <Table.Column
                title="Cenário"
                dataIndex="name"
                sorter
                key="name"
                align="left"
                {...getColumnSearchProps("name")}
              />
              <Table.Column
                title="Favorito"
                dataIndex="baseline"
                sorter
                key="baseline"
                align="center"
                render={(text, record) =>
                  record.baseline === true ? (
                    <Icon
                      type="check-circle"
                      theme="filled"
                      className="iconCheck"
                    />
                  ) : null
                }
              />
              <Table.Column
                title="Status"
                dataIndex="status"
                key="status"
                align="center"
                sorter
                sortDirections={["descend", "ascend"]}
                filters={[
                  {
                    text: "Analisando",
                    value: "A",
                  },
                  {
                    text: "Processado",
                    value: "P",
                  },
                  {
                    text: "Error",
                    value: "E",
                  },
                  {
                    text: "Vendido",
                    value: "S",
                  },
                  {
                    text: "Vendido Parical",
                    value: "PS",
                  },
                  {
                    text: "Cancelado",
                    value: "C",
                  },
                  {
                    text: "C. Parcial",
                    value: "PC",
                  },
                  {
                    text: "Inativo",
                    value: "X', 'IN",
                  },
                ]}
                filterMultiple
                render={(status, record) =>
                  status === "Analyzing" ? (
                    <TagStatus
                      background="#FFF6EE"
                      borderColor="#FE8D2A"
                      color="#FE8D2A"
                    >
                      Analisando
                    </TagStatus>
                  ) : status === "Processed" ? (
                    <TagStatus
                      background="#EBF7FF"
                      borderColor="#4A85AE"
                      color="#4A85AE"
                    >
                      Processado
                    </TagStatus>
                  ) : status === "Error" ? (
                    <Tooltip
                      title={
                        record?.errorCode
                          ? translation.saleScenario.error[record?.errorCode]
                          : "Houve um erro ao processar o cenário. Contate o suporte."
                      }
                    >
                      <Icon
                        type="exclamation-circle"
                        className="iconError"
                        theme="filled"
                      />
                    </Tooltip>
                  ) : status === "Sold" ? (
                    <TagStatus
                      background="#FEFFF6"
                      borderColor="#A9C133"
                      color="#A9C133"
                    >
                      Vendido
                    </TagStatus>
                  ) : status === "PartialSold" ? (
                    <TagStatus
                      background="#FEFFF6"
                      borderColor="#A9C183"
                      color="#A9C133"
                    >
                      V. Parcial
                    </TagStatus>
                  ) : status === "Expired" ? (
                    <TagStatus
                      background="#FBC7C7"
                      borderColor="#D44C4C"
                      color="#D44C4C"
                    >
                      Vencido
                    </TagStatus>
                  ) : status === "Invalidated" ? (
                    <TagStatus
                      background="#FE8D6A"
                      borderColor="#FE8D2A"
                      color="#FFF"
                    >
                      Invalidado
                    </TagStatus>
                  ) : status === "Canceled" ? (
                    <TagStatus
                      background="#F5F5F5"
                      borderColor="#A5A5A5"
                      color="#A5A5A5"
                    >
                      Cancelado
                    </TagStatus>
                  ) : status === "PartialCanceled" ? (
                    <TagStatus
                      background="#F5F5F5"
                      borderColor="#A5A5A5"
                      color="#A5A5A5"
                    >
                      C. Parcial
                    </TagStatus>
                  ) : (
                    <TagStatus
                      background="#FBC7C7"
                      borderColor="#D44C4C"
                      color="#D44C4C"
                    >
                      {translation.status.inactive}
                    </TagStatus>
                  )
                }
              />
              <Table.Column
                align="center"
                render={(_, record) => {
                  let totalSaleDates =
                    record?.totalSaleDates != null ? record.totalSaleDates : 0;
                  let totalLockedDates =
                    record?.totalLockedDates != null
                      ? record.totalLockedDates
                      : 0;

                  if (totalSaleDates > 0) {
                    if (totalSaleDates === totalLockedDates) {
                      return (
                        <Tooltip
                          title={
                            "Todas as datas sugeridas de venda possuem trava de preço."
                          }
                        >
                          <span>
                            <PriceLock />
                          </span>
                        </Tooltip>
                      );
                    }
                  } else {
                    return <></>;
                  }
                }}
              />
              <Table.Column
                align="left"
                render={(text, record) => (
                  <Dropdown
                    overlay={menu(record.id, record.status, record)}
                    trigger={["click"]}
                    key={record.id}
                  >
                    <MenuContainer>
                      <MenuIcon />
                    </MenuContainer>
                  </Dropdown>
                )}
              />
            </Table>
          </Col>
        </Row>
        <DrawerEditSaleScenarioParameters />
        <ClosedSalesReportModal
          modalVisible={isModalClosedSalesReportVisible}
          closeModal={handleOpenCloseModalClosedSalesReport}
        />
        <Modal
          visible={isModalCompareScenarioVisible}
          centered
          closable
          onCancel={handleOpenCloseModalCompareScenario}
          footer={null}
        >
          <Row type="flex">
            <Col>
              <Row>
                <label
                  style={{
                    color: "#9074bf",
                    fontFamily: "Asap",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Taxa Valor Presente{" "}
                  <InfoTooltip title="Para uma comparação mais assertiva, dos Cenários de Venda, informe uma taxa “Mensal” ou “Anual” a ser considerada no cálculo de “Valor Presente”. O seu cálculo envolve trazer para o momento atual um montante previsto para o futuro." />
                </label>
              </Row>
              <Row style={{ marginTop: 8 }}>
                <NumberFormat
                  customInput={Input}
                  placeholder={translation.defaultPlaceholder}
                  value={presentValueTax || ""}
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  allowLeadingZeros
                  onValueChange={({ floatValue }) =>
                    setPresentValueTax(
                      floatValue > 1000 ? 1000 : floatValue > 0 ? floatValue : 0
                    )
                  }
                  suffix="%"
                  addonAfter={
                    <Select
                      value={presentValueTaxUnit}
                      onChange={(value) => setPresentValueTaxUnit(value)}
                    >
                      <Select.Option value={"MONTH"}>/Mês</Select.Option>
                      <Select.Option value={"YEAR"}>/Ano</Select.Option>
                    </Select>
                  }
                />
              </Row>
            </Col>
          </Row>
          <Row type="flex">
            <Col span={24}>
              <Row type="flex" justify="end" align="middle" gutter={8}>
                <Col>
                  <ButtonStandard
                    type="button"
                    buttonType="type4"
                    size="s"
                    onClick={handleOpenCloseModalCompareScenario}
                  >
                    {translation.buttons.cancel}
                  </ButtonStandard>
                </Col>
                <Col>
                  <ButtonStandard
                    buttonType="type1"
                    size="s"
                    onClick={handleSubmitCompareScenarios}
                  >
                    {translation.buttons.generate}
                  </ButtonStandard>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>
      </CardCustom>
    </Container>
  );
};

export default withRouter(ListSaleScenario);
