import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import moment from "moment";
// Components
import { Col, DatePicker, Icon, Input, Row, Select, Spin } from "antd";
import { AllocatePicketInfoCard, CardSaveDietStrategy } from "../styles";
import InfoTooltip from "../../../../components/utils/infoTooltip";
import RadioGroup from "antd/lib/radio/group";
import RadioButton from "../../../../components/utils/radioButton";
import StopIcon from "../../../../components/utils/icons/stop";
import BoxText from "../../../../components/utils/boxText";
// Services
import { numberMask } from "../../../../services/helpersMethodsService";
import { getLoatAcquisitionData } from "../../../../services/lotService";
import ButtonStandard from "../../../../components/utils/button";

const DietStrategyForm = ({
  shouldBlockFieldsOnEdit,
  formDietStrategy,
  handleSaveFirstFormDietStrategy,
  loadingLots,
  listOfLots,
  handleSelectLot,
  breeds,
  lotAvgWeight,
  lotStartDate,
  handleAllocateLotInPicket,
  lastPicketLotOfLot,
  allocatePicketVisible,
  isLoadingListOfPickets,
  isLoadingPicketHistoryInfo,
  listOfPickets,
  picketId,
  handleSetLotStartDate,
  handleSelectPicket,
}) => {
  const [isLoadingLotAcquisitionData, setIsLoadingLotAcquisitionData] =
    useState(false);
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { Option } = Select;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
  });
  const dateFormat = "DD/MM/YYYY";
  // Methods
  const disabledInitialDate = (initialDate) => {
    if (!initialDate) {
      return false;
    }
    return initialDate.valueOf() < moment({ year: 2000, month: 0, day: 1 });
  };
  const initialWeightValueLimit = (inputObj) => {
    const { value } = inputObj;
    if (value >= 0 && value < 10000) return inputObj;
  };
  const handleChangeGeneric = (props, e) => {
    props.setFieldValue("generic", e.target.value);
    if (e.target.value) {
      props.setFieldValue("lot", null);
      props.setFieldValue("breedId", null);
      props.setFieldValue("gender", null);
      props.setFieldValue("cap", null);
    }
  };
  const handleUpdateLotAcquisitionData = async (props) => {
    if (props.values.lot !== null) {
      setIsLoadingLotAcquisitionData(true);
      try {
        const {
          data: { results },
        } = await getLoatAcquisitionData({
          groupId,
          farmId,
          lotId: props.values.lot.id,
        });
        props.setFieldValue("shouldUpdateLotAcquisitionData", true);
        props.setFieldValue("lot.breedId", results.breedId);
        props.setFieldValue(
          "lot.gender",
          results.animalGender === null ? "Male" : results.animalGender
        );
        props.setFieldValue(
          "lot.referenceAcquisitionDate",
          results.referenceAcquisitionDate
        );
        props.setFieldValue(
          "lot.referenceAcquisitionWeight",
          results.referenceAcquisitionWeight
        );
        props.setFieldValue(
          "lot.referenceBirthdayDate",
          results.referenceBirthdayDate
        );
      } catch (error) {
      } finally {
        setIsLoadingLotAcquisitionData(false);
      }
    }
  };
  return (
    <CardSaveDietStrategy>
      <Formik
        enableReinitialize={true}
        initialValues={formDietStrategy}
        initialErrors={{}}
        onSubmit={(values, actions) =>
          handleSaveFirstFormDietStrategy(values, actions)
        }
        validationSchema={validationSchema}
        render={(props) => (
          <form onSubmit={props.handleSubmit} autoComplete="off">
            <Row type="flex" justify="start">
              {/* Input name, isGeneric and lot */}
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 6, offset: 0 }}
                xl={{ span: 6, offset: 0 }}
              >
                {/* Name */}
                <Row>
                  <label
                    className={
                      props.errors.name && props.submitCount > 0 ? "error" : ""
                    }
                  >
                    Identificação da estratégia *
                  </label>
                </Row>
                <Row className="rowInput">
                  <Input
                    name="name"
                    autoComplete="chrome-off"
                    placeholder="Digite"
                    value={props.values.name}
                    type="text"
                    onChange={(e) => {
                      props.setFieldValue("name", e.target.value);
                    }}
                  />
                </Row>
                {/* IsGeneric */}
                <Row className="rowInput">
                  <label>
                    Esta estratégia é GENÉRICA ?{" "}
                    <InfoTooltip title="Estratégia de Dieta Genérica, são estratégias que podem ser utilizadas em diversos Lotes de Produção, ou mesmo, como sendo a base para criação de outras estratégias específicas. Quando não Genéricas, as Estratégias de Dietas devem ser associadas a um Lote de Produção específico, já cadastrado anteriormente e não poderão ser reaproveitadas para outros Lotes." />
                  </label>
                </Row>
                <Row className="rowInput">
                  <RadioGroup
                    name="cap"
                    value={props.values?.generic}
                    disabled={
                      shouldBlockFieldsOnEdit || formDietStrategy.id != null
                    }
                    defaultValue={false}
                    onChange={(e) => {
                      handleChangeGeneric(props, e);
                    }}
                  >
                    <RadioButton
                      value={true}
                      disabled={formDietStrategy.id != null ? true : false}
                    >
                      {translation.buttons.yes}
                    </RadioButton>
                    <RadioButton
                      value={false}
                      disabled={formDietStrategy.id != null ? true : false}
                    >
                      {translation.buttons.no}
                    </RadioButton>
                  </RadioGroup>
                </Row>
                {!props.values.generic && (
                  <>
                    {/* Lot */}
                    <Row className="rowInput">
                      <label
                        className={
                          props.errors.lot && props.submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        Lote de Referência *
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <Select
                        name="lot"
                        value={
                          props.values.lot != null
                            ? props.values.lot.id
                            : undefined
                        }
                        placeholder={translation.defaultSelectPlaceholder}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear={false}
                        loading={loadingLots}
                        onChange={(value) => {
                          handleSelectLot(props, value);
                        }}
                        disabled={
                          shouldBlockFieldsOnEdit || formDietStrategy.id != null
                        }
                      >
                        {listOfLots != null &&
                          listOfLots.length > 0 &&
                          listOfLots.map((lot) => (
                            <Option key={lot.id} value={lot.id}>
                              {`${lot.name} / ${
                                lot.currentAmountAnimals
                              } animais / Atividade: ${lot.profitCenterName} ${
                                lot.picketId != null
                                  ? `/ Piquete: ${lot.picketName}`
                                  : ``
                              }`}
                            </Option>
                          ))}
                      </Select>
                    </Row>
                  </>
                )}
              </Col>
              {/* Generic or Lot info */}
              {props.values.generic ? (
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 11, offset: 1 }}
                  xl={{ span: 11, offset: 1 }}
                >
                  {/* Generic info */}
                  {/* Input Raça, Gender and Cap */}
                  <Row type="flex" justify="start" gutter={4}>
                    {/* Breed */}
                    <Col span={props.values?.gender === "Male" ? 12 : 16}>
                      <Row>
                        <label
                          className={
                            props.errors.breedId && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          Raça *
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          style={{ width: "100%" }}
                          name="breedId"
                          placeholder={
                            translation.animal.edit.form.breedPlaceHolder
                          }
                          value={
                            breeds != null && props.values?.breedId != null
                              ? props.values?.breedId
                              : undefined
                          }
                          disabled={formDietStrategy.id != null}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => {
                            props.setFieldValue("breedId", value);
                          }}
                        >
                          {breeds != null &&
                            Object.entries(breeds).length > 0 &&
                            breeds.map((breed) => (
                              <Option key={breed.id} value={breed.id}>
                                {breed.name}
                              </Option>
                            ))}
                        </Select>
                      </Row>
                    </Col>
                    {/* Gender */}
                    <Col span={props.values?.gender === "Male" ? 6 : 8}>
                      <Row>
                        <label
                          className={
                            props.errors.gender && props.submitCount > 0
                              ? "formLabel error"
                              : "formLabel"
                          }
                        >
                          {translation.animal.edit.form.sex} *
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          style={{ width: "100%" }}
                          name="gender"
                          value={
                            props.values?.gender != null
                              ? props.values?.gender
                              : undefined
                          }
                          disabled={formDietStrategy.id != null}
                          placeholder={translation.defaultSelectPlaceholder}
                          onChange={(value) => {
                            props.setFieldValue("gender", value);
                            if (value === "Male") {
                              props.setFieldValue("cap", false);
                            }
                          }}
                        >
                          <Option key="Female" value="Female">
                            {translation.animal.edit.form.female}
                          </Option>
                          <Option key="Male" value="Male">
                            {translation.animal.edit.form.male}
                          </Option>
                        </Select>
                      </Row>
                    </Col>
                    {/* Cap */}
                    {props.values?.gender === "Male" ? (
                      <Col span={6}>
                        <Row>
                          <label className="formLabel">
                            {translation.animal.edit.form.cap}
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <RadioGroup
                            name="cap"
                            value={props.values?.cap}
                            disabled={formDietStrategy.id != null}
                            defaultValue={false}
                            onChange={(e) => {
                              props.setFieldValue("cap", e.target.value);
                            }}
                          >
                            <RadioButton value={true}>
                              {translation.buttons.yes}
                            </RadioButton>
                            <RadioButton value={false}>
                              {translation.buttons.no}
                            </RadioButton>
                          </RadioGroup>
                        </Row>
                      </Col>
                    ) : null}
                  </Row>
                  {/* Initial date and initial weight*/}
                  <Row type="flex" justify="start" gutter={4}>
                    {/* Initial date */}
                    <Col span={8}>
                      <Row className="rowInput">
                        <label
                          className={
                            props.errors.initialDate && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          Data inicial *
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <DatePicker
                          name="initialDate"
                          disabledDate={disabledInitialDate}
                          allowClear={false}
                          value={
                            props.values.initialDate != null
                              ? moment(props.values.initialDate, "YYYY-MM-DD")
                              : null
                          }
                          placeholder={translation.defaultDatePickerPlaceholder}
                          format={dateFormat}
                          disabled={
                            shouldBlockFieldsOnEdit ||
                            formDietStrategy?.id != null
                          }
                          onChange={(date, dateString) => {
                            if (date <= moment() && date !== null) {
                              props.setValues({
                                ...props.values,
                                initialDate: date,
                              });
                            }
                          }}
                        />
                      </Row>
                    </Col>
                    {/* Initial weight */}
                    <Col span={8}>
                      <Row className="rowInput">
                        <label
                          className={
                            props.errors.initialWeight && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          Peso inicial *
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          value={
                            props.values?.initialWeight !== null
                              ? props.values?.initialWeight
                              : null
                          }
                          placeholder={translation.defaultPlaceholder}
                          name="initialWeight"
                          addonAfter="Kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          allowNegative={false}
                          isAllowed={initialWeightValueLimit}
                          disabled={formDietStrategy.id != null}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue("initialWeight", floatValue);
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              ) : (
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 6, offset: 1 }}
                  xl={{ span: 6, offset: 1 }}
                >
                  <Spin spinning={isLoadingLotAcquisitionData}>
                    {/* Lot info */}
                    <div className="cardLotInfo">
                      {props.values.lot != null &&
                        (props.values.lot?.breedId == null ||
                          props.values.lot?.gender == null ||
                          props.values.lot?.referenceAcquisitionDate == null ||
                          props.values.lot?.referenceAcquisitionWeight ==
                            null ||
                          props.values.lot?.referenceBirthdayDate == null) && (
                          <Row type="flex" justify="end">
                            <ButtonStandard
                              type="button"
                              buttonType="alternative"
                              onClick={() =>
                                handleUpdateLotAcquisitionData(props)
                              }
                            >
                              Atualizar dados
                            </ButtonStandard>
                          </Row>
                        )}
                      <Row type="flex" justify="space-between">
                        <span className="title">
                          Dados referentes a aquisição do lote:
                        </span>
                      </Row>
                      <Row>
                        <span>
                          Nascimento do animal:
                          <strong>
                            {props.values.lot != null &&
                              props.values.lot.referenceBirthdayDate &&
                              moment(
                                props.values.lot.referenceBirthdayDate
                              ).format("DD/MM/YYYY")}
                          </strong>
                        </span>
                      </Row>
                      <Row>
                        <span>
                          Data de aquisição:
                          <strong>
                            {props.values.lot != null &&
                              props.values.lot.referenceAcquisitionDate &&
                              moment(
                                props.values.lot.referenceAcquisitionDate
                              ).format("DD/MM/YYYY")}
                          </strong>
                        </span>
                      </Row>
                      <Row>
                        <span>
                          Peso médio de aquisição:
                          <strong>
                            {props.values.lot != null &&
                              `${numberMask(
                                props.values.lot.referenceAcquisitionWeight ||
                                  0,
                                false
                              )} kg`}
                          </strong>
                        </span>
                      </Row>
                      <Row>
                        <span>
                          Peso médio do lote:
                          {props.values.lot != null ? (
                            lotAvgWeight === null ? (
                              <Icon type="loading" spin />
                            ) : (
                              <strong>{`${numberMask(
                                lotAvgWeight || 0,
                                false
                              )} kg`}</strong>
                            )
                          ) : null}
                        </span>
                      </Row>
                    </div>
                  </Spin>
                </Col>
              )}
              {allocatePicketVisible && !props.values.generic ? (
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 10, offset: 1 }}
                  xl={{ span: 10, offset: 1 }}
                >
                  <Spin
                    style={{ width: "100%" }}
                    spinning={isLoadingPicketHistoryInfo}
                  >
                    <Row>
                      <AllocatePicketInfoCard>
                        <StopIcon isRed /> O lote precisa estar alocado em um
                        piquete / baia para criar uma estratégia de dieta
                      </AllocatePicketInfoCard>
                    </Row>
                    <Row className="rowInput">
                      <label>Alocar lote em piquete / baia</label>
                    </Row>
                    {/* Select picket */}
                    <Row className="rowInput">
                      <Select
                        name="picket"
                        loading={isLoadingListOfPickets}
                        value={
                          picketId != null
                            ? picketId
                            : listOfPickets != null &&
                              listOfPickets.length === 0
                            ? 0
                            : undefined
                        }
                        placeholder={translation.defaultSelectPlaceholder}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear={false}
                        onChange={(value) => {
                          handleSelectPicket(value);
                        }}
                      >
                        {listOfPickets != null ? (
                          listOfPickets.length === 0 ? (
                            <Option key={0} value={0}>
                              Nenhum piquete / baia liberado para alocação
                            </Option>
                          ) : (
                            listOfPickets.map((picket) => (
                              <Option key={picket.id} value={picket.id}>
                                {`${picket.name} / Atividade: ${picket.profitCenterName}`}
                              </Option>
                            ))
                          )
                        ) : null}
                      </Select>
                    </Row>
                    {/* BoxText with last lot in picket */}
                    {lastPicketLotOfLot != null && (
                      <Row type="flex" style={{ marginTop: 13 }}>
                        <BoxText
                          color="#FE8D2A"
                          iconComponent={
                            <Icon type="message" style={{ color: "#FE8D2A" }} />
                          }
                        >
                          <span>
                            O piquete / baia escolhido já esteve alocado. A
                            última entrada de um lote neste piquete / baia foi:{" "}
                            <strong>
                              {moment(lastPicketLotOfLot?.dateInUser).format(
                                "DD/MM/YYYY"
                              )}
                            </strong>{" "}
                            e sua saída foi:{" "}
                            <strong>
                              {lastPicketLotOfLot?.dateOutUser != null
                                ? moment(
                                    lastPicketLotOfLot?.dateOutUser
                                  ).format("DD/MM/YYYY")
                                : "-"}
                            </strong>
                          </span>
                        </BoxText>
                      </Row>
                    )}
                    {/* Input lot start date */}
                    <Row
                      type="flex"
                      justify="start"
                      className="rowInput"
                      style={{ marginTop: "13px" }}
                    >
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Row>
                          <label>{translation.picket.form.lotStartDate}</label>
                        </Row>
                        <Row className="rowInput">
                          <DatePicker
                            name="lotStartDate"
                            allowClear={true}
                            disabledDate={(currentDate) =>
                              lastPicketLotOfLot != null
                                ? lastPicketLotOfLot?.dateOutUser !== null
                                  ? currentDate.isBefore(
                                      moment(lastPicketLotOfLot?.dateOutUser)
                                    )
                                    ? true
                                    : false
                                  : currentDate.isBefore(
                                      moment(lastPicketLotOfLot?.dateInUser)
                                    )
                                  ? true
                                  : false
                                : currentDate.isSameOrAfter(
                                    moment().add(1, "days"),
                                    "days"
                                  )
                                ? true
                                : false
                            }
                            value={
                              lotStartDate != null
                                ? moment(lotStartDate, "YYYY-MM-DD")
                                : moment()
                            }
                            format={"DD/MM/YYYY"}
                            onChange={(date, dateString) => {
                              if (date <= moment() && date !== null) {
                                handleSetLotStartDate(date);
                              }
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                    {/* Button Allocate picket */}
                    <Row>
                      <div className="buttonGroup">
                        <button
                          type="button"
                          hidden={
                            listOfPickets == null || listOfPickets.length === 0
                          }
                          onClick={handleAllocateLotInPicket}
                        >
                          ALOCAR
                        </button>
                      </div>
                    </Row>
                  </Spin>
                </Col>
              ) : (
                <>
                  {/* Submit */}
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: props.values.generic ? 5 : 10, offset: 1 }}
                    xl={{ span: props.values.generic ? 5 : 10, offset: 1 }}
                  >
                    <div className="buttonGroup">
                      <button type="submit">Informar</button>
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </form>
        )}
      />
    </CardSaveDietStrategy>
  );
};

export default DietStrategyForm;
