import React from "react";

// import { Container } from './styles';

const BullGdpErrorIcon = () => {
  return (
    <svg
      id="bullGdpErrorIcon"
      xmlns="http://www.w3.org/2000/svg"
      width="39.001"
      height="29.592"
      viewBox="0 0 39.001 29.592"
    >
      <g
        id="Group_4353"
        data-name="Group 4353"
        transform="translate(-75.999 -12)"
      >
        <g id="animals" transform="translate(75.998 -48.945)">
          <g
            id="Group_4329"
            data-name="Group 4329"
            transform="translate(0.001 66.976)"
          >
            <g
              id="Group_4328"
              data-name="Group 4328"
              transform="translate(4.369 3.354)"
            >
              <rect
                id="Rectangle_1654"
                data-name="Rectangle 1654"
                width="1.092"
                height="1.092"
                transform="translate(0 0)"
                fill="#75462e"
              />
              <path
                id="Path_2271"
                data-name="Path 2271"
                d="M405.786,146.311a2.263,2.263,0,0,1-1.984-2.291v-.411a1.112,1.112,0,0,0-1.112-1.113h-1.073a.546.546,0,0,1-.546-.546h0a.546.546,0,0,1,.546-.546h1.072a2.205,2.205,0,0,1,2.205,2.205v.447a1.147,1.147,0,0,0,.923,1.158,1.092,1.092,0,0,0,1.262-1.08V141.95a.546.546,0,0,1,.546-.546h0a.546.546,0,0,1,.546.546v2.185a2.185,2.185,0,0,1-2.184,2.185C405.92,146.32,405.853,146.317,405.786,146.311Z"
                transform="translate(-379.77 -140.312)"
                fill="#75462e"
              />
            </g>
            <rect
              id="Rectangle_1655"
              data-name="Rectangle 1655"
              width="1.092"
              height="1.092"
              transform="translate(4.369 3.354)"
            />
            <path
              id="Path_2274"
              data-name="Path 2274"
              d="M28.949,72.568H11.472v.017l-.964-.371a3.2,3.2,0,0,0-2.954.292l-1.371-.457A2.924,2.924,0,0,0,3.7,72.5a2.729,2.729,0,0,0-1.55-.475H.549A.546.546,0,0,0,0,72.567a3.038,3.038,0,0,0,1.406,2.968l.164.076L.3,77.906a2.275,2.275,0,0,0,1.978,3.4H5.426a.546.546,0,0,1,.424.2l3.861,4.756a.545.545,0,0,1,.122.344V94.96a.546.546,0,0,0,.546.546h2.185a.546.546,0,0,0,.535-.44l1.452-7.318a.546.546,0,0,1,.534-.431h12l1.28,1.28L27.318,94.87a.546.546,0,0,0,.448.629.554.554,0,0,0,.09.008h2.185a.546.546,0,0,0,.522-.386l2.185-7.1a.547.547,0,0,0-.015-.364l-.768-1.919a1.633,1.633,0,0,1,0-1.217l.768-1.919a.551.551,0,0,0,.039-.2V76.39A3.828,3.828,0,0,0,28.949,72.568ZM1.9,74.56a1.6,1.6,0,0,1-.783-1.447H2.149a1.676,1.676,0,0,1,.74.168c-.028.039-.061.073-.087.113l-.7,1.261Zm29.779,3.469v1.092a1.092,1.092,0,0,1-2.185,0v-.546a2.185,2.185,0,0,0-2.185-2.185H25.672v1.092H27.31A1.092,1.092,0,0,1,28.4,78.575v.546a2.185,2.185,0,0,0,2.185,2.185,2.161,2.161,0,0,0,1.092-.3v1.291l-.729,1.822a2.723,2.723,0,0,0,0,2.028l.7,1.74-2.009,6.53H28.5l.983-5.918a.547.547,0,0,0-.152-.476l-2.861-2.863a2.71,2.71,0,0,1-.8-1.931V82.4H24.579v.827a3.8,3.8,0,0,0,1.12,2.7l.292.293H15.086a1.648,1.648,0,0,0-1.6,1.3l-1.366,6.889h-1.19V86.609a1.645,1.645,0,0,0-.366-1.032L6.7,80.819a1.631,1.631,0,0,0-1.272-.606H2.277a1.182,1.182,0,0,1-1.025-1.771l2.488-4.49a2.034,2.034,0,0,1,2.1-.867l1.638.546a.555.555,0,0,0,.535-.109,2.113,2.113,0,0,1,2.1-.289l.96.37c-.455.907-.933,1.1-1.279,1.147H8.741v1.092H9.833a.477.477,0,0,0,.068,0c1.024-.127,1.789-.846,2.342-2.179h16.7a2.734,2.734,0,0,1,2.731,2.731v1.638Z"
              transform="translate(-0.001 -71.945)"
            />
          </g>
        </g>
        <g
          id="Group_4331"
          data-name="Group 4331"
          transform="translate(50.5 -10.604)"
        >
          <circle
            id="Ellipse_357"
            data-name="Ellipse 357"
            cx="9.5"
            cy="9.5"
            r="9.5"
            transform="translate(45.5 22.603)"
            fill="red"
            opacity="0.65"
          />
          <line
            id="Line_598"
            data-name="Line 598"
            x2="10"
            transform="translate(50 31.593)"
            fill="none"
            stroke="#fff"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export default BullGdpErrorIcon;
