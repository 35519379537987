import React from "react";

import moment from "moment";

// Components
import { Container } from "./styles";
import { Row, Col } from "antd";

// Services
import { numberMask } from "../../../../services/helpersMethodsService";

const DrawerPriceLockDetails = ({ data, isVisible, closeDrawer }) => {

	return (
		<Container
			title="Detalhes Trava de Valor Futuro da @"
			width={450}
			onClose={closeDrawer}
			maskClosable={false}
			visible={isVisible}
		>
			{data != null ? (
				<>
					<Row type="flex" className="row-margin">
						<Col span={12}>
							<label>
								Data
							</label>
						</Col>
						<Col span={12}>
							<span>
								{moment(data?.sellDate).format("DD/MM/YYYY")}
							</span>
						</Col>
					</Row>
					<Row type="flex" className="row-margin">
						<Col span={12}>
							<label>
								Corretora
							</label>
						</Col>
						<Col span={12}>
							<span>
								{data?.corretoraName}
							</span>
						</Col>
					</Row>
					<Row type="flex" className="row-margin">
						<Col span={12}>
							<label>
								Preço @
							</label>
						</Col>
						<Col span={12}>
							<span>
								{numberMask(data?.price, true)}
							</span>
						</Col>
					</Row>
					<Row type="flex" className="row-margin">
						<Col span={12}>
							<label>
								Custo Trava
							</label>
						</Col>
						<Col span={12}>
							<span>
								{numberMask(data?.lockCost, true)}
							</span>
						</Col>
					</Row>
					<Row type="flex" className="row-margin">
						<Col span={12}>
							<label>
								% Trava
							</label>
						</Col>
						<Col span={12}>
							<span>
								{numberMask((data.lockCost / data.price), false)}
							</span>
						</Col>
					</Row>
					<Row type="flex" className="row-margin">
						<Col span={12}>
							<label>
								Qtd @
							</label>
						</Col>
						<Col span={12}>
							<span>
								{numberMask(data?.weight, false)}
							</span>
						</Col>
					</Row>
				</>
			) : null}

		</Container>
	);
};

export default DrawerPriceLockDetails;
