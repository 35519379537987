import React, { createContext, useCallback } from "react";
import RelocatePicketModal from "../../components/modals/relocatePicketModal";
import { useRelocatePicketReducer } from "../../hooks/useRelocatePicketReducer";

export const RelocatePicketContext = createContext(null);

export const RelocatePicketProvider = ({ children }) => {
  const {
    state: { modalVisible, lot, picketIdOrigin },
    dispatch: relocatePicketDispatch,
  } = useRelocatePicketReducer();

  const openModal = useCallback(
    (lot, picketIdOrigin) => {
      relocatePicketDispatch({
        type: "OPEN_MODAL",
        payload: {
          lot,
          picketIdOrigin,
        },
      });
    },
    [relocatePicketDispatch]
  );

  const closeModal = useCallback(() => {
    relocatePicketDispatch({
      type: "CLOSE_MODAL",
    });
  }, [relocatePicketDispatch]);

  return (
    <RelocatePicketContext.Provider
      value={{
        modalVisible,
        lot,
        picketIdOrigin,
        openModal,
        closeModal,
      }}
    >
      {children}
      <RelocatePicketModal />
    </RelocatePicketContext.Provider>
  );
};
