import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Table, Dropdown, Menu } from "antd";
import { Creators as FarmDeathReasonActions } from "../../store/ducks/farmDeathReason";
import { CardCustom, Title } from "./styles";

import DrawerFarmDeathReason from "../../components/drawers/farmDeathReason";

import TagStatus from "../../components/utils/tagStatus";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import MenuIcon from "../../components/utils/table/icons/menu";
import ButtonStandard from "../../components/utils/button";

const DeathReasons = () => {
  const dispatch = useDispatch();
  const {
    farmDeathReason: { data, isLoading },
    app: {
      translation,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (groupId && farmId) {
      dispatch(FarmDeathReasonActions.index(groupId, farmId));
    }
  }, [groupId, farmId]);

  const { Column } = Table;

  function handleNewDeathReason() {
    dispatch(FarmDeathReasonActions.showOrHideDrawer());
  }

  async function handleInactivateOrActivate(record, action) {
    try {
      if (action === "inactivate") {
        dispatch(
          FarmDeathReasonActions.inactivateFarmDeathReason(
            groupId,
            farmId,
            record.id,
            record.deathReasonId
          )
        );
      } else {
        dispatch(
          FarmDeathReasonActions.activateFarmDeathReason(
            groupId,
            farmId,
            record.id
          )
        );
      }
    } catch (error) {}
  }

  function handleEdit(record) {
    dispatch(FarmDeathReasonActions.showOrHideDrawer(record.id));
  }

  function renderMenu(record) {
    return (
      <Menu>
        {record.id !== null &&
          record.deathReasonId === null &&
          record.status === "Active" && (
            <Menu.Item key="0">
              <button onClick={() => handleEdit(record)}>
                {translation.table.menu.edit}
              </button>
            </Menu.Item>
          )}
        {record.status !== "Active" ? (
          <Menu.Item key="1">
            <button
              onClick={() => handleInactivateOrActivate(record, "activate")}
            >
              {translation.table.menu.activate}
            </button>
          </Menu.Item>
        ) : (
          <Menu.Item key="2">
            <button
              onClick={() => handleInactivateOrActivate(record, "inactivate")}
            >
              {translation.table.menu.inactivate}
            </button>
          </Menu.Item>
        )}
      </Menu>
    );
  }

  return (
    <CardCustom>
      <Row type="flex" justify="space-between" style={{ marginBottom: "19px" }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Title>
            {translation.generalParameters.farmDeathReason.table.title}
          </Title>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} align="right">
          <ButtonStandard buttonType="type8" onClick={handleNewDeathReason}>
            {translation.generalParameters.farmDeathReason.buttonNew}
          </ButtonStandard>
        </Col>
      </Row>
      <Row type="flex">
        <Table
          loading={isLoading}
          rowKey="name"
          size="small"
          dataSource={data !== null ? data : []}
          scroll={{
            x: true,
          }}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 10,
            pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
          }}
          style={{ width: "100%", margin: "0" }}
        >
          <Column
            title={
              translation.generalParameters.farmDeathReason.table.columns.name
            }
            dataIndex="name"
            key="name"
            align="left"
          />
          <Column
            title={
              translation.generalParameters.farmDeathReason.table.columns.status
            }
            dataIndex="status"
            key="status"
            align="left"
            render={(status) =>
              status === "Active" ? (
                <TagStatus
                  background="#C5F1CA"
                  borderColor="#106518"
                  color="#106518"
                >
                  {translation.status.active}
                </TagStatus>
              ) : (
                <TagStatus
                  background="#FBC7C7"
                  borderColor="#D44C4C"
                  color="#D44C4C"
                >
                  {translation.status.inactive}
                </TagStatus>
              )
            }
          />
          <Column
            align="left"
            width={50}
            render={(text, record) => (
              <Dropdown
                overlay={renderMenu(record)}
                trigger={["click"]}
                key={record.id}
              >
                <MenuContainer>
                  <MenuIcon />
                </MenuContainer>
              </Dropdown>
            )}
          />
        </Table>
      </Row>
      <DrawerFarmDeathReason />
    </CardCustom>
  );
};

export default DeathReasons;
