import React, { useEffect, useState } from "react";
import CanvasJSReact from "../../../../assets/canvasjs.react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Creators as MarketAnalysisActions } from "../../../../store/ducks/marketAnalysis";
import { Container } from "./styles";
import { Col, Icon, Radio, Row, Spin, Table } from "antd";
import { TagStatus } from "../../../../pages/marketAnalysis/styles";
// Services
import { numberMask } from "../../../../services/helpersMethodsService";
import { getMarketAnalysisShow } from "../../../../services/marketAnalysisService";
import { getFuturePrice } from "../../../../services/futurePriceService";

const DrawerMarketAnalysisDetails = () => {
  // Variables
  const { CanvasJSChart } = CanvasJSReact;
  const [menu, setMenu] = useState("dashboard");
  const [dataChart, setDataChart] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [marketAnalysisRefData, setMarketAnalysisRefData] = useState(null);
  const [futurePrices, setFuturePrices] = useState(null);
  const toggleDataSeries = (e) => {
    const id = e?.dataSeries?.id;
    const index = dataChart.findIndex((dc) => dc?.id === id);
    const item = dataChart[index];
    if (
      dataChart.filter((dc) => dc.visible).length > 1 ||
      item?.visible === false
    ) {
      const newDataChart = dataChart.map((dc) =>
        id === dc.id ? { ...dc, visible: !dc.visible } : dc
      );
      setDataChart(newDataChart);
    }
  };
  /* Chart options */
  const options = {
    animationEnabled: true,
    zoomEnabled: true,
    height: 220,
    backgroundColor: "#ffff",
    axisX: {
      labelFontSize: 12,
      valueFormatString: "MM/YY",
      interval: 1,
      intervalType: "month",
      viewportMinimum: moment().subtract(1, "months").toDate(),
      viewportMaximum: moment().add(1, "years").toDate(),
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
      },
    },
    toolTip: {
      fontFamily: "Asap",
      shared: true,
    },
    axisY: {
      labelFontSize: 12,
      includeZero: true,
      valueFormatString: "###0.##",
      suffix: " R$/@",
      gridDashType: "dot",
    },
    legend: {
      cursor: "pointer",
      itemclick: toggleDataSeries,
    },
    data: dataChart,
  };
  // Redux variables
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { marketAnalysisData, drawerDetailsVisible } = useSelector(
    (state) => state.marketAnalysis
  );
  const dispatch = useDispatch();
  // Effects
  useEffect(() => {
    async function getMarketFuturePrice() {
      return new Promise(async (resolve, reject) => {
        /** Set future prices */
        const {
          data: { results: dataMarketAnalysisFuturePrice },
        } = await getFuturePrice({
          groupId,
          farmId,
          marketAnalysisId: marketAnalysisData?.id,
          justFuture: true,
        });

        const formFuturePricesArray = dataMarketAnalysisFuturePrice.map(
          (r, i) => {
            if (i === 0) {
              return {
                price: r.price,
                month: r.month,
                first: true,
              };
            } else {
              return {
                price: r.price,
                month: r.month,
              };
            }
          }
        );
        setFuturePrices(formFuturePricesArray);

        const futurePricesArrayXY = formFuturePricesArray.map((fp) => ({
          x: moment(fp.month, "YYYY-MM").toDate(),
          y: fp.price,
        }));

        resolve(futurePricesArrayXY);
      });
    }

    async function getRefMarketAnalysis() {
      return new Promise(async (resolve, reject) => {
        /** Create chart for reference market */
        if (marketAnalysisData?.referenceMarketAnalysisId != null) {
          /** Get market analysy */
          const {
            data: { results: reference },
          } = await getMarketAnalysisShow({
            groupId,
            farmId,
            id: marketAnalysisData?.referenceMarketAnalysisId,
          });
          setMarketAnalysisRefData(reference);

          /** Get Future Prices of the reference */
          const {
            data: { results: dataRefMarketAnalysisFuturePrice },
          } = await getFuturePrice({
            groupId,
            farmId,
            marketAnalysisId: marketAnalysisData?.referenceMarketAnalysisId,
            justFuture: true,
          });
          const refFuturePricesArray = dataRefMarketAnalysisFuturePrice.map(
            (r, i) => {
              if (i === 0) {
                return {
                  price: r.price,
                  month: r.month,
                  first: true,
                };
              } else {
                return {
                  price: r.price,
                  month: r.month,
                };
              }
            }
          );
          const refFuturePricesArrayXY = refFuturePricesArray.map((fp) => ({
            x: moment(fp.month, "YYYY-MM").toDate(),
            y: fp.price,
          }));
          resolve(refFuturePricesArrayXY);
        } else {
          resolve([]);
        }
      });
    }

    async function fetchData() {
      let futurePricesArrayXY = [];
      let refFuturePricesArrayXY = [];
      setIsLoading(true);
      try {
        if (marketAnalysisData != null) {
          futurePricesArrayXY = await getMarketFuturePrice();
          refFuturePricesArrayXY = await getRefMarketAnalysis();
          if (refFuturePricesArrayXY.length > 0) {
            setDataChart([
              {
                id: "newMarket",
                type: "line",
                showInLegend: true,
                name: marketAnalysisData?.name,
                color: "#4A85AE",
                visible: true,
                xValueFormatString: "DD/MM/YYYY",
                yValueFormatString: "R$#######.00",
                dataPoints: futurePricesArrayXY,
              },
              {
                id: "referenceMarket",
                type: "line",
                showInLegend: true,
                name: "Referência ",
                color: "#A3A3A3",
                visible: true,
                xValueFormatString: "DD/MM/YYYY",
                yValueFormatString: "R$#######.00",
                dataPoints: refFuturePricesArrayXY,
              },
            ]);
          } else {
            setDataChart([
              {
                id: "newMarket",
                type: "line",
                showInLegend: true,
                name: marketAnalysisData?.name,
                color: "#4A85AE",
                visible: true,
                xValueFormatString: "DD/MM/YYYY",
                yValueFormatString: "R$#######.00",
                dataPoints: futurePricesArrayXY,
              },
            ]);
          }
        }
      } catch (error) {}

      setIsLoading(false);
    }
    if (futurePrices === null) {
      fetchData();
    }
  }, [marketAnalysisData, futurePrices, farmId, groupId]);

  // Methods
  const handleClose = () => {
    setFuturePrices(null);
    setMarketAnalysisRefData(null);
    setMenu("dashboard");
    dispatch(MarketAnalysisActions.showOrHideMarketAnalysisDetails(null));
  };

  return (
    <Container
      title={`Detalhes da Curva de Preço`}
      visible={drawerDetailsVisible}
      width={700}
      onClose={handleClose}
      maskClosable={false}
    >
      <Spin spinning={isLoading}>
        <div className="drawerForm">
          {/* Nome e tipo */}
          <Row type="flex">
            <Col span={12}>
              <Row>
                <label htmlFor="">Nome</label>
              </Row>
              <Row>
                <span>{marketAnalysisData?.name}</span>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <label htmlFor="">Tipo</label>
              </Row>
              <Row>
                {marketAnalysisData?.type === "Free" ? (
                  <TagStatus
                    background="#C5F1CA"
                    borderColor="#106518"
                    color="#106518"
                  >
                    {translation.marketAnalysis.type.free}
                  </TagStatus>
                ) : marketAnalysisData?.type === "Progressive" ? (
                  <TagStatus
                    background="#FFDBBC"
                    borderColor="#FE8D2A"
                    color="#FE8D2A"
                  >
                    {translation.marketAnalysis.type.progressive}
                  </TagStatus>
                ) : marketAnalysisData?.type === "Linear" ? (
                  <TagStatus
                    background="#684e75"
                    borderColor="#684e94"
                    color="#FFFFFF"
                  >
                    {translation.marketAnalysis.type.linear}
                  </TagStatus>
                ) : (
                  <TagStatus
                    background="#E3E3E3"
                    borderColor="#C4C4C4"
                    color="#4B4B4B"
                  >
                    {translation.marketAnalysis.type.bovexo}
                  </TagStatus>
                )}
              </Row>
            </Col>
          </Row>
          {/* Referencia */}
          {marketAnalysisData?.referenceMarketAnalysisId != null && (
            <Row type="flex">
              <Col span={24}>
                <Row>
                  <label htmlFor="">Referência</label>
                </Row>
                <Row>
                  <span>{marketAnalysisRefData?.name}</span>
                </Row>
              </Col>
            </Row>
          )}
          {/* Porcentagem */}
          <Row type="flex">
            <Col span={24}>
              <Row>
                <label htmlFor="">Porcentagem</label>
              </Row>
              <Row>
                <span>
                  {`${numberMask(
                    marketAnalysisData?.percentage || 0,
                    false
                  )} %`}
                </span>
              </Row>
            </Col>
          </Row>
          {/* Dash Or Table */}
          {/* Buttons menu */}
          <Row type="flex">
            <Col span={24} align="right">
              <Radio.Group
                value={menu}
                onChange={(e) => setMenu(e.target.value)}
              >
                <Radio.Button value="dashboard">
                  <Icon type="dashboard" />
                </Radio.Button>
                <Radio.Button value="table">
                  <Icon type="table" />
                </Radio.Button>
              </Radio.Group>
            </Col>
          </Row>

          {menu === "dashboard" ? (
            <Row type="flex">
              <Col span={24}>
                <CanvasJSChart options={options} />
              </Col>
            </Row>
          ) : (
            <Row type="flex">
              <Col span={24} className="customTable">
                <Table
                  size="small"
                  rowKey="month"
                  dataSource={futurePrices || []}
                  scroll={{ y: 380 }}
                  pagination={false}
                >
                  <Table.Column key="month" dataIndex="month" title="Ano/Mês" />
                  <Table.Column
                    key="price"
                    dataIndex="price"
                    title="Preço"
                    render={(text, record) => (
                      <span>{`${numberMask(
                        record?.price || 0,
                        true
                      )} /@`}</span>
                    )}
                  />
                </Table>
              </Col>
            </Row>
          )}
        </div>
      </Spin>
    </Container>
  );
};

export default DrawerMarketAnalysisDetails;
