import React, { useState } from "react";

import { Col, Row, Tabs } from "antd";
/* Redux libs */
import { useSelector } from "react-redux";
/** Components */
import { Container } from "./styles";
// Pages
import MovementAnimals from "../../movementAnimals";
import MovementHistory from "../movementHistory";

const MovementLots = () => {
  const [tabSelected, setTabSelected] = useState("moveAnimals");
  const { translation } = useSelector((state) => state.app);

  function handleTabClick(key, e) {
    setTabSelected(key);
  }

  return (
    <Container>
      {/* Header */}
      <Row type="flex">
        <Col span={24}>
          <Tabs
            type="card"
            defaultActiveKey="moveAnimals"
            activeKey={tabSelected}
            onTabClick={handleTabClick}
          >
            <Tabs.TabPane
              tab={<span>Nova Movimentação</span>}
              key="moveAnimals"
            >
              <MovementAnimals />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span>{translation.lot.tabs.movementHistory}</span>}
              key="movementHistory"
            >
              <MovementHistory tab={tabSelected} />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default MovementLots;
