import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

/* Redux Actions */
import { Creators as UserActions } from "../../../../store/ducks/user";

// Components
import ButtonStandard from "../../../utils/button";
import { Row, Col, Input, Spin, notification } from "antd";
import { Container, Footer } from "./styles";

// Services
import { changePassword } from "../../../../services/changePasswordService";

const DrawerChangePasswordLogged = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(false);
  const { translation, user } = useSelector((state) => state.app);
  const { drawerVisibleChangePassword } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const closeDrawer = () => {
    dispatch(UserActions.hideDrawerChangePassword());
    setConfirmPassword("");
    setPassword("");
    setError(false);
  };

  const handleDrawerVisible = async (visible) => {};

  const handleSubmit = async () => {
    try {
      if (password === confirmPassword) {
        await changePassword(user.id, { password: password });
        notification.success({ message: "Senha redefinida com sucesso!" });
        setConfirmPassword("");
        setPassword("");
        setError(false);
        closeDrawer();
      } else {
        setError(true);
      }
    } catch (e) {
      if (
        e !== undefined &&
        e !== null &&
        e.toString().indexOf("Request failed with status code 409") > -1
      ) {
        notification.error({ message: "Senha já utilizada anteriormente." });
      } else {
        notification.error({ message: "Erro ao redefinir as senha." });
      }
    }
  };

  return (
    <Container
      title="Redefinir senha"
      width={511}
      onClose={closeDrawer}
      maskClosable={false}
      visible={drawerVisibleChangePassword}
      afterVisibleChange={handleDrawerVisible}
    >
      <Spin spinning={false}>
        <form>
          <div className="drawerForm">
            <Row type="flex">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <span style={{ fontSize: "14px", color: "#000" }}>
                  Defina sua senha de acesso
                </span>
              </Col>
            </Row>

            {error ? (
              <Row type="flex">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <span
                    style={{
                      marginTop: 20,
                      fontSize: "16px",
                      color: "#d44c4c",
                    }}
                  >
                    Valores não corrrespondentes
                  </span>
                </Col>
              </Row>
            ) : null}

            {/* Input password */}
            <Row type="flex" style={{ marginTop: "13px" }} align="middle">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row>
                  <label className={error ? "error" : ""}>Nova senha</label>
                </Row>
                <Row>
                  <Input
                    type="password"
                    autoComplete="chrome-off"
                    placeholder={translation.defaultPlaceholder}
                    value={password}
                    name="password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </Row>
              </Col>
            </Row>

            {/* Input confirm password */}
            <Row type="flex" style={{ marginTop: "13px" }} align="middle">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row>
                  <label className={error ? "error" : ""}>
                    Confirme a nova senha
                  </label>
                </Row>
                <Row>
                  <Input
                    type="password"
                    autoComplete="chrome-off"
                    placeholder={translation.defaultPlaceholder}
                    value={confirmPassword}
                    name="confirmPassword"
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                </Row>
              </Col>
            </Row>
          </div>
          {/* Footer */}
          <Footer>
            <Row type="flex" justify="space-between">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="selectDiv"
              />

              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="buttonsDiv"
              >
                <ButtonStandard
                  type="button"
                  buttonType="type7"
                  onClick={closeDrawer}
                >
                  {translation.buttons.cancel}
                </ButtonStandard>

                <ButtonStandard
                  buttonType="type6"
                  type="button"
                  onClick={handleSubmit}
                >
                  {translation.buttons.save}
                </ButtonStandard>
              </Col>
            </Row>
          </Footer>
        </form>
      </Spin>
    </Container>
  );
};

export default DrawerChangePasswordLogged;
