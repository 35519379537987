export const Types = {
  RESET_DATA: "lotReceipt/RESET_DATA",
  OPEN_OR_HIDE_DRAWER_ITEM_ENTRY: "lotReceipt/OPEN_OR_HIDE_DRAWER_ITEM_ENTRY",
  OPEN_OR_HIDE_DRAWER_DETAILS: "lotReceipt/OPEN_OR_HIDE_DRAWER_DETAILS",
  GET_DETAILS_LOT_RECEIPT_ITEM: "lotReceipt/GET_DETAILS_LOT_RECEIPT_ITEM",
  GET_DETAILS_LOT_RECEIPT_ITEM_SUCCESS:
    "lotReceipt/GET_DETAILS_LOT_RECEIPT_ITEM_SUCCESS",
  GET_DETAILS_LOT_RECEIPT_ITEM_ERROR:
    "lotReceipt/GET_DETAILS_LOT_RECEIPT_ITEM_ERROR",
  SAVE_LOT_ITEM_ENTRY: "lotReceipt/SAVE_LOT_ITEM_ENTRY",
  SAVE_LOT_ITEM_ENTRY_SUCCESS: "lotReceipt/SAVE_LOT_ITEM_ENTRY_SUCCESS",
  SAVE_LOT_ITEM_ENTRY_ERROR: "lotReceipt/SAVE_LOT_ITEM_ENTRY_ERROR",
};

export const Creators = {
  openOrHideDrawerItemEntry: (lotItemEntryData = null) => ({
    type: Types.OPEN_OR_HIDE_DRAWER_ITEM_ENTRY,
    payload: {
      lotItemEntryData,
    },
  }),
  openOrHideDrawerDetails: (lotId = null, lotItemId = null) => ({
    type: Types.OPEN_OR_HIDE_DRAWER_DETAILS,
    payload: {
      lotId,
      lotItemId,
    },
  }),
  getDetailsLotReceiptItem: (groupId, farmId, lotId, lotItemId) => ({
    type: Types.GET_DETAILS_LOT_RECEIPT_ITEM,
    payload: {
      groupId,
      farmId,
      lotId,
      lotItemId,
    },
  }),
  getDetailsLotReceiptItemSuccess: (data) => ({
    type: Types.GET_DETAILS_LOT_RECEIPT_ITEM_SUCCESS,
    payload: {
      data,
    },
  }),
  getDetailsLotReceiptItemError: (error) => ({
    type: Types.GET_DETAILS_LOT_RECEIPT_ITEM_ERROR,
    payload: {
      error,
    },
  }),
  saveLotItemEntry: (groupId, farmId, lotId, lotItemId, body, id = null) => ({
    type: Types.SAVE_LOT_ITEM_ENTRY,
    payload: {
      groupId,
      farmId,
      lotId,
      lotItemId,
      body,
      id,
    },
  }),
  saveLotItemEntrySuccess: () => ({
    type: Types.SAVE_LOT_ITEM_ENTRY_SUCCESS,
    payload: {},
  }),
  saveLotItemEntryError: () => ({
    type: Types.SAVE_LOT_ITEM_ENTRY_ERROR,
    payload: {},
  }),
};

const INITIAL_STATE = {
  lotId: null,
  lotItemId: null,
  isLoadingDataDetails: false,
  isLoadingRequestEntry: false,
  showDrawerDetails: false,
  showDrawerItemEntry: false,
  lotReceiptItemDetailsData: null,
  lotItemEntryData: null,
  error: {},
};

export default function lotReceipt(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.OPEN_OR_HIDE_DRAWER_ITEM_ENTRY:
      return {
        ...state,
        showDrawerItemEntry: !state.showDrawerItemEntry,
        lotItemEntryData: action.payload.lotItemEntryData,
      };
    case Types.OPEN_OR_HIDE_DRAWER_DETAILS:
      return {
        ...state,
        showDrawerDetails: !state.showDrawerDetails,
        lotId: action.payload.lotId,
        lotItemId: action.payload.lotItemId,
        lotReceiptItemDetailsData: null,
      };
    case Types.GET_DETAILS_LOT_RECEIPT_ITEM:
      return {
        ...state,
        isLoadingDataDetails: true,
      };
    case Types.GET_DETAILS_LOT_RECEIPT_ITEM_SUCCESS:
      const { data: lotReceiptItem } = action.payload;
      return {
        ...state,
        isLoadingDataDetails: false,
        lotReceiptItemDetailsData: lotReceiptItem,
      };
    case Types.GET_DETAILS_LOT_RECEIPT_ITEM_ERROR:
      const { error } = action.payload;
      return {
        ...state,
        isLoadingDataDetails: false,
        lotReceiptItemDetailsData: null,
        error,
      };
    case Types.SAVE_LOT_ITEM_ENTRY:
      return {
        ...state,
        isLoadingRequestEntry: true,
      };
    case Types.SAVE_LOT_ITEM_ENTRY_SUCCESS:
      return {
        ...state,
        isLoadingRequestEntry: false,
        showDrawerItemEntry: false,
        lotItemEntryData: null,
      };
    case Types.SAVE_LOT_ITEM_ENTRY_ERROR:
      return {
        ...state,
        isLoadingRequestEntry: false,
      };
    default:
      return state;
  }
}
