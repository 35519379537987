import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

/* Redux Actions */
import { Creators as AppActions } from "../../../store/ducks/app";
import { Creators as GeneralParametersActions } from "../../../store/ducks/generalParameters";

// Components
import ButtonStandard from "../../utils/button";
import {
  Row,
  Col,
  Input,
  Spin,
  notification,
  Select,
  Tooltip,
  Icon,
} from "antd";
import { Container, Footer, CustomDivider, Table } from "./styles";

// Services
import { saveWeatherSeasons } from "../../../services/farmService";
import { getAllWeatherSeasons } from "../../../services/generalParameterService";

const DrawerWeatherSeasonsParameter = () => {
  const [error, setError] = useState([]);
  const [form, setForm] = useState({
    name: "",
    seasonsDefault: [
      {
        month: 1,
        season: undefined,
      },
      {
        month: 2,
        season: undefined,
      },
      {
        month: 3,
        season: undefined,
      },
      {
        month: 4,
        season: undefined,
      },
      {
        month: 5,
        season: undefined,
      },
      {
        month: 6,
        season: undefined,
      },
      {
        month: 7,
        season: undefined,
      },
      {
        month: 8,
        season: undefined,
      },
      {
        month: 9,
        season: undefined,
      },
      {
        month: 10,
        season: undefined,
      },
      {
        month: 11,
        season: undefined,
      },
      {
        month: 12,
        season: undefined,
      },
    ],
    seasonsDrier: [
      {
        month: 1,
        season: undefined,
      },
      {
        month: 2,
        season: undefined,
      },
      {
        month: 3,
        season: undefined,
      },
      {
        month: 4,
        season: undefined,
      },
      {
        month: 5,
        season: undefined,
      },
      {
        month: 6,
        season: undefined,
      },
      {
        month: 7,
        season: undefined,
      },
      {
        month: 8,
        season: undefined,
      },
      {
        month: 9,
        season: undefined,
      },
      {
        month: 10,
        season: undefined,
      },
      {
        month: 11,
        season: undefined,
      },
      {
        month: 12,
        season: undefined,
      },
    ],
    seasonsWetter: [
      {
        month: 1,
        season: undefined,
      },
      {
        month: 2,
        season: undefined,
      },
      {
        month: 3,
        season: undefined,
      },
      {
        month: 4,
        season: undefined,
      },
      {
        month: 5,
        season: undefined,
      },
      {
        month: 6,
        season: undefined,
      },
      {
        month: 7,
        season: undefined,
      },
      {
        month: 8,
        season: undefined,
      },
      {
        month: 9,
        season: undefined,
      },
      {
        month: 10,
        season: undefined,
      },
      {
        month: 11,
        season: undefined,
      },
      {
        month: 12,
        season: undefined,
      },
    ],
    status: "Active",
  });
  const [seasonSelected, setSeasonSelected] = useState(null);
  const [listWeatherSeason, setListWeatherSeason] = useState([]);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  /** Const Redux */
  const {
    translation,
    drawerWeatherSeasonsParameterVisible,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const closeDrawer = () => {
    setSeasonSelected(null);
    setForm({
      name: "",
      seasonsDefault: [
        {
          month: 1,
          season: undefined,
        },
        {
          month: 2,
          season: undefined,
        },
        {
          month: 3,
          season: undefined,
        },
        {
          month: 4,
          season: undefined,
        },
        {
          month: 5,
          season: undefined,
        },
        {
          month: 6,
          season: undefined,
        },
        {
          month: 7,
          season: undefined,
        },
        {
          month: 8,
          season: undefined,
        },
        {
          month: 9,
          season: undefined,
        },
        {
          month: 10,
          season: undefined,
        },
        {
          month: 11,
          season: undefined,
        },
        {
          month: 12,
          season: undefined,
        },
      ],
      seasonsDrier: [
        {
          month: 1,
          season: undefined,
        },
        {
          month: 2,
          season: undefined,
        },
        {
          month: 3,
          season: undefined,
        },
        {
          month: 4,
          season: undefined,
        },
        {
          month: 5,
          season: undefined,
        },
        {
          month: 6,
          season: undefined,
        },
        {
          month: 7,
          season: undefined,
        },
        {
          month: 8,
          season: undefined,
        },
        {
          month: 9,
          season: undefined,
        },
        {
          month: 10,
          season: undefined,
        },
        {
          month: 11,
          season: undefined,
        },
        {
          month: 12,
          season: undefined,
        },
      ],
      seasonsWetter: [
        {
          month: 1,
          season: undefined,
        },
        {
          month: 2,
          season: undefined,
        },
        {
          month: 3,
          season: undefined,
        },
        {
          month: 4,
          season: undefined,
        },
        {
          month: 5,
          season: undefined,
        },
        {
          month: 6,
          season: undefined,
        },
        {
          month: 7,
          season: undefined,
        },
        {
          month: 8,
          season: undefined,
        },
        {
          month: 9,
          season: undefined,
        },
        {
          month: 10,
          season: undefined,
        },
        {
          month: 11,
          season: undefined,
        },
        {
          month: 12,
          season: undefined,
        },
      ],
      status: "Active",
    });
    dispatch(AppActions.hideDrawerWeatherSeasonsParameterVisible());
  };

  const onCheckInputs = () => {
    const { name, seasonsDefault, seasonsDrier, seasonsWetter } = form;
    const countSeasonsDefaultWithSeasonUndefined = seasonsDefault.filter(
      (s) => s.season === undefined || s.season === null
    ).length;
    const countSeasonsDrierWithSeasonCompleted = seasonsDrier.filter(
      (s) => s.season !== undefined && s.season !== null
    ).length;
    const countSeasonsDrierWithSeasonUndefined = seasonsDrier.filter(
      (s) => s.season === undefined || s.season === null
    ).length;
    const countSeasonsWetterWithSeasonCompleted = seasonsWetter.filter(
      (s) => s.season !== undefined && s.season !== null
    ).length;
    const countSeasonsWetterWithSeasonUndefined = seasonsWetter.filter(
      (s) => s.season === undefined || s.season === null
    ).length;
    let errorArray = [];
    if (name === null || name.trim() === "") errorArray.push("name");
    if (countSeasonsDefaultWithSeasonUndefined > 0) {
      errorArray.push("seasonsDefault");
      notification.error({
        message: "É necessário preencher todos os mêses da coluna Padrão",
      });
    }
    if (
      countSeasonsDrierWithSeasonCompleted > 0 &&
      countSeasonsDrierWithSeasonUndefined > 0
    ) {
      errorArray.push("seasonsDrier");
      notification.error({
        message:
          "Se algum mês da coluna + Seco, foi preenchida. Por favor, preencha os mêses restantes.",
      });
    }
    if (
      countSeasonsWetterWithSeasonCompleted > 0 &&
      countSeasonsWetterWithSeasonUndefined > 0
    ) {
      errorArray.push("seasonsWetter");
      notification.error({
        message:
          "Se algum mês da coluna + Úmido, foi preenchida. Por favor, preencha os mêeses restantes.",
      });
    }

    setError(errorArray);
    if (errorArray.length > 0) return false;
    return true;
  };

  const handleChangeSelectSeason = (value) => {
    const seasonsDrierEmpty = [
      {
        month: 1,
        season: undefined,
      },
      {
        month: 2,
        season: undefined,
      },
      {
        month: 3,
        season: undefined,
      },
      {
        month: 4,
        season: undefined,
      },
      {
        month: 5,
        season: undefined,
      },
      {
        month: 6,
        season: undefined,
      },
      {
        month: 7,
        season: undefined,
      },
      {
        month: 8,
        season: undefined,
      },
      {
        month: 9,
        season: undefined,
      },
      {
        month: 10,
        season: undefined,
      },
      {
        month: 11,
        season: undefined,
      },
      {
        month: 12,
        season: undefined,
      },
    ];
    const seasonsWetterEmpty = [
      {
        month: 1,
        season: undefined,
      },
      {
        month: 2,
        season: undefined,
      },
      {
        month: 3,
        season: undefined,
      },
      {
        month: 4,
        season: undefined,
      },
      {
        month: 5,
        season: undefined,
      },
      {
        month: 6,
        season: undefined,
      },
      {
        month: 7,
        season: undefined,
      },
      {
        month: 8,
        season: undefined,
      },
      {
        month: 9,
        season: undefined,
      },
      {
        month: 10,
        season: undefined,
      },
      {
        month: 11,
        season: undefined,
      },
      {
        month: 12,
        season: undefined,
      },
    ];
    const selected = listWeatherSeason.find((ws) => ws.id === value);
    setSeasonSelected(selected);
    const newForm = {
      ...form,
      seasonsDefault: selected.seasonsDefault,
      seasonsDrier:
        selected.seasonsDrier.length > 0
          ? selected.seasonsDrier
          : seasonsDrierEmpty,
      seasonsWetter:
        selected.seasonsWetter.length > 0
          ? selected.seasonsWetter
          : seasonsWetterEmpty,
    };
    setForm(newForm);
  };

  const handleDrawerVisible = async (visible) => {
    if (visible) {
      setIsLoadingList(true);
      try {
        const {
          data: { results },
        } = await getAllWeatherSeasons({ farmId });
        setListWeatherSeason(results);
      } catch (error) {
        setSeasonSelected(null);
      } finally {
        setIsLoadingList(false);
      }
    }
  };

  const handleSetMonthSeason = (value, month, type) => {
    let cloneSeasonDefault = form.seasonsDefault;
    let cloneSeasonDrier = form.seasonsDrier;
    let cloneSeasonWetter = form.seasonsWetter;
    switch (type) {
      case "Default":
        cloneSeasonDefault = cloneSeasonDefault.map((s) => {
          if (s.month === month) s.season = value;
          return s;
        });
        break;
      case "Drier":
        cloneSeasonDrier = cloneSeasonDrier.map((s) => {
          if (s.month === month) s.season = value;
          return s;
        });
        break;
      case "Wetter":
        cloneSeasonWetter = cloneSeasonWetter.map((s) => {
          if (s.month === month) s.season = value;
          return s;
        });
        break;
      default:
        break;
    }

    setForm({
      ...form,
      seasonsDefault: cloneSeasonDefault,
      seasonsDrier: cloneSeasonDrier,
      seasonsWetter: cloneSeasonWetter,
    });
  };

  const handleCleanColumn = (column) => {
    const seasonsDrierEmpty = [
      {
        month: 1,
        season: undefined,
      },
      {
        month: 2,
        season: undefined,
      },
      {
        month: 3,
        season: undefined,
      },
      {
        month: 4,
        season: undefined,
      },
      {
        month: 5,
        season: undefined,
      },
      {
        month: 6,
        season: undefined,
      },
      {
        month: 7,
        season: undefined,
      },
      {
        month: 8,
        season: undefined,
      },
      {
        month: 9,
        season: undefined,
      },
      {
        month: 10,
        season: undefined,
      },
      {
        month: 11,
        season: undefined,
      },
      {
        month: 12,
        season: undefined,
      },
    ];
    const seasonsWetterEmpty = [
      {
        month: 1,
        season: undefined,
      },
      {
        month: 2,
        season: undefined,
      },
      {
        month: 3,
        season: undefined,
      },
      {
        month: 4,
        season: undefined,
      },
      {
        month: 5,
        season: undefined,
      },
      {
        month: 6,
        season: undefined,
      },
      {
        month: 7,
        season: undefined,
      },
      {
        month: 8,
        season: undefined,
      },
      {
        month: 9,
        season: undefined,
      },
      {
        month: 10,
        season: undefined,
      },
      {
        month: 11,
        season: undefined,
      },
      {
        month: 12,
        season: undefined,
      },
    ];
    switch (column) {
      case "Drier":
        setForm({
          ...form,
          seasonsDrier: seasonsDrierEmpty,
        });
        break;
      case "Wetter":
        setForm({
          ...form,
          seasonsWetter: seasonsWetterEmpty,
        });
        break;

      default:
        break;
    }
  };

  const handleSubmit = async () => {
    setIsLoadingForm(true);
    try {
      if (onCheckInputs()) {
        const countSeasonsDrierWithSeasonUndefined = form.seasonsDrier.filter(
          (s) => s.season === undefined || s.season === null
        ).length;
        const countSeasonsWetterWithSeasonUndefined = form.seasonsWetter.filter(
          (s) => s.season === undefined || s.season === null
        ).length;
        const body = {
          ...form,
          seasonsDrier:
            countSeasonsDrierWithSeasonUndefined > 0 ? [] : form.seasonsDrier,
          seasonsWetter:
            countSeasonsWetterWithSeasonUndefined > 0 ? [] : form.seasonsWetter,
          farmId,
          groupId,
        };
        await saveWeatherSeasons(body);
        notification.success({
          message: "Estação climática criada com sucesso",
        });
        dispatch(GeneralParametersActions.listWeatherSeasons(farmId));
        closeDrawer();
      }
    } catch (e) {
      notification.error({ message: "Erro ao criar a estação climática" });
    } finally {
      setIsLoadingForm(false);
    }
  };

  const { Option } = Select;

  return (
    <Container
      title="Criar padrão de estações climáticas"
      width={600}
      onClose={closeDrawer}
      maskClosable={false}
      visible={drawerWeatherSeasonsParameterVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Spin spinning={isLoadingForm}>
        <div className="drawerForm">
          <Row type="flex">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <span style={{ fontSize: "14px", color: "#000" }}>
                Defina o período da safra e suas metas de resultado
              </span>
            </Col>
          </Row>

          {error.length > 0 ? (
            <Row type="flex" justify="center">
              <span
                style={{
                  fontSize: "16px",
                  color: "#d44c4c",
                }}
              >
                Por favor, defina os valores de todos os campos
              </span>
            </Row>
          ) : null}

          <Row
            type="flex"
            style={{ marginTop: error.length > 0 ? 13 : 5 }}
            align="middle"
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <label className={error.includes("name") ? "error" : ""}>
                  Nome da Estação*
                </label>
              </Row>
              <Row>
                <Input
                  type="text"
                  placeholder={translation.defaultPlaceholder}
                  value={form?.name != null ? form?.name : undefined}
                  name="name"
                  autoComplete="chrome-off"
                  onChange={(e) => setForm({ ...form, name: e.target.value })}
                />
              </Row>
            </Col>
          </Row>
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <label>Baseado na estação</label>
              </Row>
              <Row>
                <Select
                  name="seasonSelected"
                  loading={isLoadingList}
                  placeholder={translation.defaultSelectPlaceholder}
                  style={{ width: "100%" }}
                  value={
                    seasonSelected != null ? seasonSelected?.id : undefined
                  }
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => handleChangeSelectSeason(value)}
                >
                  {listWeatherSeason != null &&
                    Object.entries(listWeatherSeason).length > 0 &&
                    listWeatherSeason.map((ws) => (
                      <Select.Option key={ws.id} value={ws.id}>
                        {ws.name}
                      </Select.Option>
                    ))}
                </Select>
              </Row>
            </Col>
          </Row>

          <CustomDivider dashed />

          <Table>
            <thead>
              <tr>
                <th />
                <th
                  className={error.includes("seasonsDefault") ? "error" : ""}
                >{`PADRÃO`}</th>
                <th className={error.includes("seasonsDrier") ? "error" : ""}>
                  {`+ SECO`}
                  <Tooltip title="Limpar dados da coluna?">
                    <button
                      className="iconButton"
                      onClick={() => handleCleanColumn("Drier")}
                    >
                      <Icon type="rest" />
                    </button>
                  </Tooltip>
                </th>
                <th className={error.includes("seasonsWetter") ? "error" : ""}>
                  {`+ ÚMIDO`}
                  <Tooltip title="Limpar dados da coluna?">
                    <button
                      className="iconButton"
                      onClick={() => handleCleanColumn("Wetter")}
                    >
                      <Icon type="rest" />
                    </button>
                  </Tooltip>
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Janeiro */}
              <tr>
                <td>{translation.months.january}</td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="januarySeason"
                    value={
                      form?.seasonsDefault.find((s) => s.month === 1).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 1, "Default")}
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="januarySeasonDrier"
                    value={form?.seasonsDrier.find((s) => s.month === 1).season}
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 1, "Drier")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="januarySeasonWetter"
                    value={
                      form?.seasonsWetter.find((s) => s.month === 1).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 1, "Wetter")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
              </tr>
              {/* Fevereiro */}
              <tr>
                <td>{translation.months.february}</td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="februarySeason"
                    value={
                      form?.seasonsDefault.find((s) => s.month === 2).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 2, "Default")}
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="februarySeasonDrier"
                    value={form?.seasonsDrier.find((s) => s.month === 2).season}
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 2, "Drier")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="februarySeasonWetter"
                    value={
                      form?.seasonsWetter.find((s) => s.month === 2).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 2, "Wetter")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
              </tr>
              {/* Março */}
              <tr>
                <td>{translation.months.march}</td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="marchSeason"
                    value={
                      form?.seasonsDefault.find((s) => s.month === 3).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 3, "Default")}
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="marchSeasonDrier"
                    value={form?.seasonsDrier.find((s) => s.month === 3).season}
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 3, "Drier")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="marchSeasonWetter"
                    value={
                      form?.seasonsWetter.find((s) => s.month === 3).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 3, "Wetter")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
              </tr>
              {/* Abril */}
              <tr>
                <td>{translation.months.april}</td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="aprilSeason"
                    value={
                      form?.seasonsDefault.find((s) => s.month === 4).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 4, "Default")}
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="aprilSeasonDrier"
                    value={form?.seasonsDrier.find((s) => s.month === 4).season}
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 4, "Drier")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="aprilSeasonWetter"
                    value={
                      form?.seasonsWetter.find((s) => s.month === 4).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 4, "Wetter")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
              </tr>
              {/* Março */}
              <tr>
                <td>{translation.months.may}</td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="maySeason"
                    value={
                      form?.seasonsDefault.find((s) => s.month === 5).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 5, "Default")}
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="maySeasonDrier"
                    value={form?.seasonsDrier.find((s) => s.month === 5).season}
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 5, "Drier")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="maySeasonWetter"
                    value={
                      form?.seasonsWetter.find((s) => s.month === 5).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 5, "Wetter")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
              </tr>
              {/* Junho */}
              <tr>
                <td>{translation.months.june}</td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="juneSeason"
                    value={
                      form?.seasonsDefault.find((s) => s.month === 6).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 6, "Default")}
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="juneSeasonDrier"
                    value={form?.seasonsDrier.find((s) => s.month === 6).season}
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 6, "Drier")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="juneSeasonWetter"
                    value={
                      form?.seasonsWetter.find((s) => s.month === 6).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 6, "Wetter")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
              </tr>
              {/* Julho */}
              <tr>
                <td>{translation.months.july}</td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="julySeason"
                    value={
                      form?.seasonsDefault.find((s) => s.month === 7).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 7, "Default")}
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="julySeasonDrier"
                    value={form?.seasonsDrier.find((s) => s.month === 7).season}
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 7, "Drier")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="julySeasonWetter"
                    value={
                      form?.seasonsWetter.find((s) => s.month === 7).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 7, "Wetter")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
              </tr>
              {/* Agosto */}
              <tr>
                <td>{translation.months.august}</td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="augustSeason"
                    value={
                      form?.seasonsDefault.find((s) => s.month === 8).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 8, "Default")}
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="augustSeasonDrier"
                    value={form?.seasonsDrier.find((s) => s.month === 8).season}
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 8, "Drier")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="augustSeasonWetter"
                    value={
                      form?.seasonsWetter.find((s) => s.month === 8).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 8, "Wetter")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
              </tr>
              {/* Setembro */}
              <tr>
                <td>{translation.months.september}</td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="septemberSeason"
                    value={
                      form?.seasonsDefault.find((s) => s.month === 9).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 9, "Default")}
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="septemberSeasonDrier"
                    value={form?.seasonsDrier.find((s) => s.month === 9).season}
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 9, "Drier")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="septemberSeasonWetter"
                    value={
                      form?.seasonsWetter.find((s) => s.month === 9).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 9, "Wetter")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
              </tr>
              {/* Outubro */}
              <tr>
                <td>{translation.months.october}</td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="octoberSeason"
                    value={
                      form?.seasonsDefault.find((s) => s.month === 10).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 10, "Default")}
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="octoberSeasonDrier"
                    value={
                      form?.seasonsDrier.find((s) => s.month === 10).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 10, "Drier")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="octoberSeasonWetter"
                    value={
                      form?.seasonsWetter.find((s) => s.month === 10).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 10, "Wetter")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
              </tr>
              {/* Novembro */}
              <tr>
                <td>{translation.months.november}</td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="novemberSeason"
                    value={
                      form?.seasonsDefault.find((s) => s.month === 11).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 11, "Default")}
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="novemberSeasonDrier"
                    value={
                      form?.seasonsDrier.find((s) => s.month === 11).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 11, "Drier")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="novemberSeasonWetter"
                    value={
                      form?.seasonsWetter.find((s) => s.month === 11).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 11, "Wetter")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
              </tr>
              {/* Dezembro */}
              <tr>
                <td>{translation.months.december}</td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="decemberSeason"
                    value={
                      form?.seasonsDefault.find((s) => s.month === 12).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 12, "Default")}
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="decemberSeasonDrier"
                    value={
                      form?.seasonsDrier.find((s) => s.month === 12).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 12, "Drier")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
                <td>
                  <Select
                    style={{ width: 120 }}
                    name="decemberSeasonWetter"
                    value={
                      form?.seasonsWetter.find((s) => s.month === 12).season
                    }
                    placeholder="Defina"
                    onChange={(e) => handleSetMonthSeason(e, 12, "Wetter")}
                    allowClear
                  >
                    <Option value="Water">Águas</Option>
                    <Option value="Transition">Transição</Option>
                    <Option value="Dry">Seca</Option>
                  </Select>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        {/* Footer */}
        <Footer>
          <Row type="flex" justify="space-between">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="selectDiv"
            />

            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="buttonsDiv">
              <ButtonStandard
                type="button"
                buttonType="type7"
                onClick={closeDrawer}
              >
                {translation.buttons.cancel}
              </ButtonStandard>

              <ButtonStandard
                buttonType="type6"
                type="button"
                onClick={handleSubmit}
              >
                {translation.buttons.save}
              </ButtonStandard>
            </Col>
          </Row>
        </Footer>
      </Spin>
    </Container>
  );
};

export default DrawerWeatherSeasonsParameter;
