import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { AnimalPregnancyDiagnosisContextProvider } from "../../contexts/animalPregnancyDiagnosisContext";
import useAnimalPregnancyDiagnosisContext from "../../hooks/useAnimalPregnancyDiagnosisContext";

import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Icon,
  Input,
  Menu,
  Row,
  Select,
  Table,
  notification,
} from "antd";
import ButtonStandard from "../../components/utils/button";
import MenuIcon from "../../components/utils/table/icons/menu";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import AnimalPregnancyDiagnosisForm from "./form";
import { CardCustom, Title } from "./styles";

// Service
import CustomLink from "../../components/utils/customLink";
import { deleteAnimalPregnancyDiagnosis } from "../../services/animalPregnancyDiagnosisService";

// import { Container } from './styles';

function AnimalPregnancyDiagnosisTable() {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const {
    dataList,
    isLoadingDataList,
    handleTableChange,
    showCardForm,
    openOrCloseForm,
    initializeData,
    fetchFemalesDropDown,
    tableProperties: { size, page, tableFilters, tableSorters },
  } = useAnimalPregnancyDiagnosisContext();

  useEffect(() => {
    handleTableChange();
  }, [handleTableChange]);

  const menu = (id, record) => {
    return (
      <Menu>
        {record.canDelete === true && (
          <Menu.Item onClick={() => handleDelete(id)}>
            {translation.table.menu.delete}
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const handleCreateNewRecord = () => {
    openOrCloseForm();
    initializeData(null);
  };

  const handleDelete = useCallback(
    async (id) => {
      try {
        await deleteAnimalPregnancyDiagnosis({ groupId, farmId, id });
        notification.success({
          message: "Diagnóstico de gestação deletado com sucesso!",
        });
        handleTableChange(
          { pageSize: size, current: page },
          tableFilters,
          tableSorters
        );
        fetchFemalesDropDown();
      } catch (error) {
        if (Object.keys(error).includes("response")) {
          const { response } = error;
          if (Object.keys(response).includes("data")) {
            const {
              data: { code: errorCode },
            } = response;
            if (errorCode === 5030) {
              notification.error({
                message: "Erro",
                description: `Não é possível excluir o Diagnóstico de Gestação por ter registro de Parto.`,
              });
            } else {
              notification.error({
                message:
                  "Não foi possível deletar Diagnóstico de gestação. Contate o suporte.",
              });
            }
          }
        } else {
          notification.error({
            message:
              "Não foi possível deletar Diagnóstico de gestação. Contate o suporte.",
          });
        }
      }
    },
    [
      groupId,
      farmId,
      handleTableChange,
      size,
      page,
      tableFilters,
      tableSorters,
      fetchFemalesDropDown,
    ]
  );

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetColumnDateSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Row type="flex" gutter={4}>
            <Col>
              <Select
                value={selectedKeys ? selectedKeys[1] : "equal"}
                onChange={(value) =>
                  setSelectedKeys(
                    value
                      ? selectedKeys
                        ? [selectedKeys[0], value]
                        : [null, value]
                      : selectedKeys
                      ? [selectedKeys[0], "equal"]
                      : [null, "equal"]
                  )
                }
              >
                <Select.Option value="equal">=</Select.Option>
                <Select.Option value="greaterThan">&gt;</Select.Option>
                <Select.Option value="greaterOrEqualThan">&ge;</Select.Option>
                <Select.Option value="lessThan">&lt;</Select.Option>
                <Select.Option value="lessOrEqualThan">&le;</Select.Option>
              </Select>
            </Col>
            <Col>
              <DatePicker
                value={selectedKeys && selectedKeys[0]}
                format={"DD/MM/YYYY"}
                onChange={(date) =>
                  setSelectedKeys(
                    date
                      ? selectedKeys && selectedKeys.length > 1
                        ? [date, selectedKeys[1]]
                        : [date, "equal"]
                      : []
                  )
                }
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </Col>
          </Row>

          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
    }),
    [handleReset, handleSearch]
  );

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys && selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return record[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    }),
    [handleReset, handleSearch]
  );

  const handleRefresh = useCallback(() => {
    handleTableChange(
      { pageSize: size, current: page },
      tableFilters,
      tableSorters
    );
  }, [handleTableChange, page, size, tableFilters, tableSorters]);

  return (
    <CardCustom>
      <Row className="rowHeader" justify="space-between">
        <Col span={8}>
          <Title>{translation.animalPregnancyDiagnosis.title}</Title>
        </Col>
        <Col span={16}>
          <Row type="flex" justify="end" gutter={8}>
            <Col>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={handleRefresh}
              >
                <Icon type="reload" />
              </ButtonStandard>
              {!showCardForm && (
                <ButtonStandard
                  name="buttonCreateBudget"
                  buttonType="type8"
                  onClick={handleCreateNewRecord}
                >
                  {translation.animalPregnancyDiagnosis.buttonNew}
                </ButtonStandard>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {showCardForm && (
        <Row type="flex">
          <AnimalPregnancyDiagnosisForm />
        </Row>
      )}
      <Row type="flex">
        <Col span={24}>
          <Table
            loading={isLoadingDataList}
            rowKey="id"
            size="small"
            dataSource={dataList ? dataList.content : []}
            scroll={{
              x: true,
            }}
            pagination={{
              total: dataList != null ? dataList.totalElements : 0,
              size: dataList != null ? size : 0,
              current: dataList != null ? page : 0,
              hideOnSinglePage:
                dataList !== null && Object.entries(dataList).length !== 0
                  ? dataList.totalElements > 10
                    ? false
                    : true
                  : true,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
            }}
            onChange={handleTableChange}
          >
            <Table.Column
              title={
                translation.animalPregnancyDiagnosis.table.columns
                  .animalHandlingNumber
              }
              dataIndex="animalHandlingNumber"
              filteredValue={tableFilters?.animalHandlingNumber || null}
              sorter
              sortOrder={
                tableSorters?.columnKey === "animalHandlingNumber" &&
                tableSorters.order
              }
              key="animalHandlingNumber"
              {...handleGetColumnSearchProps("animalHandlingNumber")}
              render={(text, record) => (
                <CustomLink to={`/admin/animals/${record.animalId}`}>
                  {text}
                </CustomLink>
              )}
            />
            <Table.Column
              title={
                translation.animalPregnancyDiagnosis.table.columns
                  .lotOriginalName
              }
              dataIndex="lotOriginalName"
              filteredValue={tableFilters?.lotOriginalName || null}
              sorter
              sortOrder={
                tableSorters?.columnKey === "lotOriginalName" &&
                tableSorters.order
              }
              key="lotOriginalName"
              {...handleGetColumnSearchProps("lotOriginalName")}
            />
            <Table.Column
              title={
                translation.animalPregnancyDiagnosis.table.columns.diagnosisDate
              }
              dataIndex="diagnosisDate"
              filteredValue={tableFilters?.diagnosisDate || null}
              sorter
              sortOrder={
                tableSorters?.columnKey === "diagnosisDate" &&
                tableSorters.order
              }
              key="diagnosisDate"
              {...handleGetColumnDateSearchProps("diagnosisDate")}
              render={(text) => (
                <span>{!text ? "" : moment(text).format("DD/MM/YYYY")}</span>
              )}
            />
            <Table.Column
              title={translation.animalPregnancyDiagnosis.table.columns.result}
              dataIndex="result"
              key="result"
              filtered
              filters={[
                {
                  text: "Positivo",
                  value: 1,
                },
                {
                  text: "Negativo",
                  value: 2,
                },
              ]}
              filterMultiple={false}
              filteredValue={tableFilters?.result || null}
              sorter
              sortOrder={
                tableSorters?.columnKey === "result" && tableSorters.order
              }
              render={(text) =>
                text === 1 ? (
                  <span>Positivo</span>
                ) : text === 2 ? (
                  <span>Negativo</span>
                ) : null
              }
            />
            <Table.Column
              title={
                translation.animalPregnancyDiagnosis.table.columns
                  .ovarianEvaluationResult
              }
              dataIndex="ovarianEvaluationResult"
              key="ovarianEvaluationResult"
              filtered
              filters={[
                { value: "SemAvaliacao", text: "Sem Avaliação" },
                { value: "CorpoLuteo", text: "Corpo Lúteo" },
                { value: "Foliculo", text: "Folículo" },
                { value: "Cisto", text: "Cisto" },
                { value: "Anestro", text: "Anestro" },
                { value: "Normal", text: "Normal" },
                { value: "Reabsorvendo", text: "Reabsorvendo" },
                { value: "Gemelar", text: "Gemelar" },
              ]}
              filteredValue={tableFilters?.ovarianEvaluationResult || null}
              sorter
              sortOrder={
                tableSorters?.columnKey === "ovarianEvaluationResult" &&
                tableSorters.order
              }
              render={(text) =>
                text !== null ? (
                  <span>{translation.ovarianResult[text]}</span>
                ) : null
              }
            />

            <Table.Column
              title=""
              align="left"
              render={(text, record) => (
                <Dropdown
                  overlay={menu(record.id, record)}
                  trigger={["click"]}
                  key={record.id}
                >
                  <MenuContainer>
                    <MenuIcon />
                  </MenuContainer>
                </Dropdown>
              )}
            />
          </Table>
        </Col>
      </Row>
    </CardCustom>
  );
}

const AnimalPregnancyDiagnosis = () => {
  return (
    <AnimalPregnancyDiagnosisContextProvider>
      <AnimalPregnancyDiagnosisTable />
    </AnimalPregnancyDiagnosisContextProvider>
  );
};

export default AnimalPregnancyDiagnosis;
