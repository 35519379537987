import styled from "styled-components";
import { Divider, Drawer } from "antd";

export const Container = styled(Drawer)`
  .ant-drawer-body {
    padding: 24px 0px 10px 0px;
  }

  .title {
    display: flex;
    .gdpConflited {
      margin-left: 5px;
    }
  }

  .drawerForm {
    width: 100%;
    padding: 0px 24px;
    min-height: 790px;
    @media screen and (max-height: 768px) {
      min-height: 590px;
    }
  }

  .rowLabel {
    margin-bottom: 7px;
    margin-top: 19px;
  }

  .info-box {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    p {
      margin: 0px;
    }
    svg:not(:first-of-type) {
      margin-left: 5px;
    }
  }

  label {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    margin-left: 0px;
    color: #4b4b4b;
  }

  strong {
    font-family: Asap;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;
  }

  .ant-row,
  .ant-row-flex {
    margin-top: 10px;
  }

  .tableButtonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      display: flex;
      justify-content: center !important;
      text-align: center !important;
      align-items: center !important;
      font-size: 14px !important;
      width: 77px !important;
      height: 30px !important;

      svg {
        margin-right: 3px !important;
      }
    }

    button:first-of-type {
      width: 116px !important;
      margin-right: 12px !important;
    }
  }

  .ant-switch-checked {
    background-color: #a9c133 !important;
  }
`;

export const CustomDivider = styled(Divider)`
  margin: 10px 0 !important;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 10px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;

export const CardShadow = styled.div`
  width: 450px;
  height: auto;
  margin: 15px 0px;
  padding: 15px 13px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 3px 6px #d8d8d8;

  p {
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .title {
    color: #c4c4c4;
    text-transform: uppercase;
    font-weight: bold;
  }
`;

export const Footer = styled.div`
  border-top: 1px solid #e8e8e8;
  margin-top: 20px;
  width: 100%;
  padding: 10px 24px;
  border-radius: 0 0 4px 4px;
`;

export const ButtonNavigation = styled.button`
  display: flex;
  height: 25px;
  align-items: center;
  font-family: Asap;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  color: #684e94;
  border: none;
  background: transparent;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    margin: 0 5px;
    color: #684e94;
  }

  &.remove {
    color: #d44c4c;
    margin-left: 15px;
    svg {
      color: #d44c4c;
    }
  }

  &:hover {
    &.save {
      border-bottom: 1px solid #684e94;
    }
    &.remove {
      border-bottom: 1px solid #d44c4c;
    }
  }

  &:disabled {
    color: #a5a5a5;
    cursor: not-allowed;
    svg {
      path {
        fill: #a5a5a5;
      }
    }
  }
`;
