import React from "react";
import useVeterinaryContext from "../../hooks/useVeterinaryContext";
import { useSelector } from "react-redux";

import { Col, Icon, Row } from "antd";
import { CardCustom, Title } from "./styles";
import ButtonStandard from "../../components/utils/button";
import VeterinaryForm from "./form";
import VeterinaryTable from "./table";
import { VeterinaryContextProvider } from "../../contexts/veterinaryContext";

const VeterinaryLayout = () => {
  const { translation } = useSelector((state) => state.app);
  const { fetchData, openDrawer } = useVeterinaryContext();
  return (
    <CardCustom>
      <Row className="rowHeader" justify="space-between">
        <Col span={8}>
          <Title>{translation.veterinary.title}</Title>
        </Col>
        <Col span={16}>
          <Row type="flex" justify="end" gutter={8}>
            <Col>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={fetchData}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </Col>
            <Col>
              <ButtonStandard buttonType="type8" onClick={() => openDrawer()}>
                {translation.veterinary.buttonNew}
              </ButtonStandard>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <VeterinaryTable />
        </Col>
      </Row>
    </CardCustom>
  );
};

const Veterinary = () => {
  return (
    <VeterinaryContextProvider>
      <VeterinaryLayout />
      <VeterinaryForm />
    </VeterinaryContextProvider>
  );
};

export default Veterinary;
