import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 30px 10px 0px;
  background: transparent;
  border-radius: 6px;
  justify-content: ${(props) =>
    props.alingCenter != null && props.alingCenter === true
      ? "center"
      : "flex-start"};
  border: ${(props) =>
    props.color != null ? `1px dashed ${props.color}` : "1px dashed #4b4b4b"};
  div.iconContainer {
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  div.messageContainer {
    &.withMargin {
      margin-left: 40px;
    }
    span {
      font-family: Asap;
      font-weight: normal;
      font-size: ${(props) =>
        props.fontSize != null ? props.fontSize : "12px"};
      text-align: left;
      color: ${(props) => (props.color != null ? props.color : "#4b4b4b")};
    }
    strong {
      font-family: Asap;
      font-size: ${(props) =>
        props.fontSize != null ? props.fontSize : "12px"};
      text-align: left;
      color: ${(props) => (props.color != null ? props.color : "#4b4b4b")};
    }
  }
`;
