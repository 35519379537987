import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as PastureActions } from "../../../../../store/ducks/pasture";
import moment from "moment";
import "moment/locale/pt-br";
import "moment/locale/en-nz";
import "moment/locale/es";
// Components
import { Drawer, Row, Col, Spin } from "antd";
import { Container, CustomDivider } from "./styles";
import DrawerPastureDetails from "../../../pastureDetails";
import ButtonStandard from "../../../../utils/button";
import { numberMask } from "../../../../../services/helpersMethodsService";

const DrawerPicketDetailSustenanceCapacity = ({
  data,
  onClose,
  drawerVisible,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [
    picketSustenanceCapacityData,
    setPicketSustenanceCapacityData,
  ] = useState(null);
  // Redux variables
  const { translation } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  function handleOpenDrawer(visible) {
    if (visible) {
      setIsLoading(true);
      setPicketSustenanceCapacityData(data);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }

  function handleShowPastureDetails() {
    const { pastureId: id } = picketSustenanceCapacityData;
    dispatch(PastureActions.showOrHideDrawerPastureDetails(id));
  }

  function handleDrawerTittle() {
    if (picketSustenanceCapacityData?.productionSubSystem === "I") {
      return translation.picket.form.sustenanceCapacityDrawerTitleIntensiveSubSystem;
    } else {
      return translation.picket.form.sustenanceCapacityDrawerTitle;
    }
  }

  return (
    <Drawer
      title={handleDrawerTittle()}
      width={400}
      onClose={onClose}
      maskClosable={false}
      visible={drawerVisible}
      afterVisibleChange={handleOpenDrawer}
    >
      {picketSustenanceCapacityData != null ? (
        <Container>
          <Spin spinning={isLoading}>
            {/* Picket Animal Capacity */}
            <Row type="flex" justify="space-between">
              <Col>
                <label>{translation.picket.detail.pastureAnimalCapacity}</label>
              </Col>
              <Col>
                <label className="value">{`${numberMask(
                  picketSustenanceCapacityData.pastureAnimalCapacity || 0,
                  false
                )} ${
                  picketSustenanceCapacityData.productionSubSystem !==
                    "Intensive" &&
                  picketSustenanceCapacityData.productionSubSystem !== "I"
                    ? "UA/ha" : "CAB" }`}
                </label>
              </Col>
            </Row>
            {picketSustenanceCapacityData.productionSubSystem !== "Intensive" &&
            picketSustenanceCapacityData.productionSubSystem !== "I" ? (
              <Row type="flex" justify="space-between">
                {/* Picket Cost */}
                <Col>
                  <label>{translation.picket.detail.pastureDailyCost}</label>
                </Col>
                <Col>
                  <label className="value">{`${numberMask(
                    picketSustenanceCapacityData.pastureDailyCost || 0,
                    true
                  )} kg/animal`}</label>
                </Col>
              </Row>
            ) : (
              <Row type="flex" justify="space-between">
                <Col>
                  <label>{translation.picket.detail.pastureArea}</label>
                </Col>
                <Col>
                  <label className="value">
                    {`${numberMask(
                      picketSustenanceCapacityData.pastureArea * 10000 || 0,
                      false
                    )} `}
                    <span>
                      m<sup>2</sup>
                    </span>
                  </label>
                </Col>
              </Row>
            )}
            {/* Pasture Data */}
            {picketSustenanceCapacityData.productionSubSystem !== "Intensive" &&
              picketSustenanceCapacityData.productionSubSystem !== "I" && (
                <>
                  <CustomDivider dashed>
                    {translation.picket.detail.pastureDivider}
                  </CustomDivider>
                  <Row type="flex" justify="space-between">
                    <Col></Col>
                    <Col>
                      <label className="valueBlue">
                        {picketSustenanceCapacityData.pastureType != null
                          ? `${picketSustenanceCapacityData.pastureName} / ${picketSustenanceCapacityData.pastureType}`
                          : `${picketSustenanceCapacityData.pastureName}`}
                      </label>
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between">
                    <Col>
                      <label>
                        {translation.picket.detail.pastureFertilized}
                      </label>
                    </Col>
                    <Col>
                      <label className="value">
                        {picketSustenanceCapacityData.pastureFertilized
                          ? "Sim"
                          : "Não"}
                      </label>
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between">
                    <Col>
                      <label>{translation.picket.detail.pastureArea}</label>
                    </Col>
                    <Col>
                      <label className="value">{`${numberMask(
                        picketSustenanceCapacityData.pastureArea || 0,
                        false
                      )} ha`}</label>
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between">
                    <Col>
                      <label>
                        {translation.picket.detail.pastureCurrentHeight}
                      </label>
                    </Col>
                    <Col>
                      <label className="value">{`${numberMask(
                        picketSustenanceCapacityData.pastureCurrentHeight || 0,
                        false
                      )} cm`}</label>
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between">
                    <Col>
                      <label>
                        {translation.picket.detail.pastureCurrentHeightDate}
                      </label>
                    </Col>
                    <Col>
                      <label className="value">
                        {moment(
                          picketSustenanceCapacityData.pastureCurrentHeightDate
                        ).format("DD/MM/YYYY")}
                      </label>
                    </Col>
                  </Row>

                  <Row
                    type="flex"
                    justify="center"
                    style={{ marginTop: 20 }}
                    align="middle"
                  >
                    <ButtonStandard
                      buttonType="typeWithoutBackground"
                      color="#684E94"
                      type="button"
                      isUpperCase={true}
                      onClick={handleShowPastureDetails}
                      disabled={
                        picketSustenanceCapacityData === null ||
                        picketSustenanceCapacityData?.pastureId === null
                          ? true
                          : false
                      }
                    >
                      VISUALIZAR INFORMAÇÕES SOBRE A FORRAGEIRA
                    </ButtonStandard>
                  </Row>
                </>
              )}
          </Spin>
          <DrawerPastureDetails />
        </Container>
      ) : null}
    </Drawer>
  );
};

export default DrawerPicketDetailSustenanceCapacity;
