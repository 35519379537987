import styled from "styled-components";
import { Drawer } from "antd";

export const Container = styled(Drawer)`
  .ant-drawer-body {
    padding: 24px 0px 10px 0px;
  }

  .ant-select {
    width: 100%;
  }

  .ant-drawer-header{
    border-bottom: 1px dashed #e8e8e8 !important;
  }

  .row-margin {
    margin-top: 13px;
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
  }

  span {
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
  }

`;