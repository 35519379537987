import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Row, Col, Table, Icon } from "antd";

import { Creators as AppActions } from "../../../store/ducks/app";

import {
  getAnimalWeightImportLog,
  deleteImportWeightFileByRequestId,
} from "../../../services/importService";

import { Title, CardCustom, DeleteButton } from "./styles";

import EyeIcon from "../../../components/utils/icons/eye";
import TrashIcon from "../../../components/utils/icons/trash";
import InfoTooltip from "../../../components/utils/infoTooltip";
import ButtonStandard from "../../../components/utils/button";
import TagStatus from "../../../components/utils/tagStatus";
import Axios from "axios";

class WeightImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      importWeightLogData: [],
      isLoading: false,
      importLogId: null,
      canDeleteFile: false,
    };
  }

  static propTypes = {
    app: PropTypes.any.isRequired,
  };

  signal = Axios.CancelToken.source();

  componentWillUnmount() {
    this.signal.cancel("Cancelando apis");
  }

  async componentDidMount() {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;
    if (
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      this.fetchData(groupSelected, farmSelected);
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.modalAnimalWeightUploadVisible !==
        this.props.modalAnimalWeightUploadVisible &&
      !this.props.modalAnimalWeightUploadVisible
    ) {
      this.handleReloadTable();
    }
  }

  fetchData = async (
    groupSelected,
    farmSelected,
    page = 0,
    sorter = {},
    filters = "",
    size = 10
  ) => {
    try {
      this.setState({ isLoading: true });
      const { id: groupId } = groupSelected;
      const { id: farmId } = farmSelected;
      const {
        data: { results: responseWeightHistory },
      } = await getAnimalWeightImportLog({
        groupId,
        farmId,
        page,
        sorter,
        filters,
        size,
        signal: this.signal,
      });

      this.setState({
        importWeightLogData: responseWeightHistory.page,
        isLoading: false,
        importLogId: responseWeightHistory.importLogId,
        canDeleteFile: responseWeightHistory.canDeleteFile,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        importAnimalLogData: null,
      });
    }
  };

  handleReloadTable = async () => {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;
    this.fetchData(groupSelected, farmSelected, 0, {});
  };

  handleTableChange = async (pagination, filters, sorter) => {
    this.setState({
      paginationInfo: pagination,
      sortedInfo: sorter,
      filteredInfo: filters,
    });
    const {
      app: { groupSelected, farmSelected },
    } = this.props;

    let search = "";

    this.fetchData(
      groupSelected,
      farmSelected,
      pagination.current,
      sorter,
      search,
      pagination.pageSize
    );
  };

  confirmDelete = async (importFileRequestId) => {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;

    try {
      this.setState({ isLoading: true, canDeleteFile: false });

      await deleteImportWeightFileByRequestId({
        groupId: groupSelected.id,
        farmId: farmSelected.id,
        id: importFileRequestId,
        signal: this.signal,
      });

      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false, canDeleteFile: true });
    }

    this.fetchData(groupSelected, farmSelected, 0, {}, "", 10);
  };

  render() {
    const {
      app: { translation },
      appActions,
    } = this.props;
    const {
      importWeightLogData: data,
      isLoading,
      importLogId,
      canDeleteFile,
    } = this.state;

    const Column = Table.Column;

    return (
      <CardCustom>
        <Row type="flex" justify="space-between" className="rowHeader">
          <Col>
            <Title>{translation.imports.weight.table.title}</Title>
          </Col>
          <Col>
            <ButtonStandard
              buttonType="typeWithoutBackground"
              onClick={this.handleReloadTable}
            >
              <Icon type="reload" />
            </ButtonStandard>
            <ButtonStandard
              type="button"
              // disabled={!canDeleteFile}
              buttonType="type8"
              onClick={() => appActions.showModalAnimalWeightUpload()}
            >
              {translation.imports.weight.buttonImport}
            </ButtonStandard>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              loading={isLoading}
              rowKey="id"
              size="small"
              dataSource={data != null ? data.content : []}
              pagination={{
                total: data != null ? data.totalElements : 0,
                size: data != null ? data.size : 0,
                current: data != null ? data.number + 1 : 0,
                hideOnSinglePage:
                  data !== null && Object.entries(data).length !== 0
                    ? data.totalElements > 10
                      ? false
                      : true
                    : true,
                showSizeChanger: true,
                pageSizeOptions: [
                  "10",
                  "20",
                  "30",
                  "100",
                  "500",
                  "1000",
                  "2000",
                ],
              }}
              scroll={{
                x: true,
              }}
              style={{ width: "100%", margin: "0" }}
              onChange={this.handleTableChange}
            >
              <Column
                title={translation.imports.weight.table.columns.status}
                dataIndex="status"
                key="status"
                align="left"
                render={(status) =>
                  status === "Processed" ? (
                    <TagStatus
                      background="#C5F1CA"
                      borderColor="#106518"
                      color="#106518"
                    >
                      {translation.imports.status.processed}
                    </TagStatus>
                  ) : status === "Queue" ? (
                    <TagStatus
                      background="#EBF7FF"
                      borderColor="#4A85AE"
                      color="#4A85AE"
                    >
                      {translation.imports.status.queue}
                    </TagStatus>
                  ) : status === "Processing" ? (
                    <TagStatus
                      background="#FEFFF6"
                      borderColor="#A9C133"
                      color="#A9C133"
                    >
                      {translation.imports.status.processing}
                    </TagStatus>
                  ) : status === "Deleting" ? (
                    <TagStatus
                      background="#FFD8D8"
                      borderColor="#D44C4C"
                      color="#D44C4C"
                    >
                      {translation.imports.status.deleting}
                    </TagStatus>
                  ) : (
                    <TagStatus
                      background="#FFD8D8"
                      borderColor="#D44C4C"
                      color="#D44C4C"
                    >
                      {translation.imports.status.error}
                    </TagStatus>
                  )
                }
              />
              <Column
                title={translation.imports.weight.table.columns.fileName}
                dataIndex="fileName"
                key="fileName"
                align="left"
                sorter
                sortDirections={["descend", "ascend"]}
              />
              <Column
                title={translation.imports.weight.table.columns.date}
                dataIndex="date"
                align="left"
                key="date"
              />
              <Column
                title={translation.imports.weight.table.columns.total}
                dataIndex="total"
                align="center"
                key="total"
              />
              <Column
                title={translation.imports.weight.table.columns.numberImported}
                dataIndex="numberImported"
                align="center"
                key="numberImported"
              />
              <Column
                title={translation.imports.weight.table.columns.numberError}
                dataIndex="numberError"
                align="center"
                key="numberError"
              />
              <Column
                title={
                  translation.imports.weight.table.columns.numberDuplicated
                }
                dataIndex="numberDuplicated"
                align="center"
                key="numberDuplicated"
              />
              <Column
                title={translation.imports.weight.table.columns.message}
                dataIndex="message"
                align="left"
                key="message"
              />
              <Column
                align="left"
                render={(text, record) =>
                  record.status === "Processed" ? (
                    <Link
                      to={`/admin/imports/weights/${record.requestId}/details`}
                    >
                      <EyeIcon isActive />
                    </Link>
                  ) : null
                }
              />
              <Column
                align="left"
                render={(text, record) =>
                  record.status === "Processed" &&
                  canDeleteFile &&
                  record.id === importLogId ? (
                    <DeleteButton
                      type="button"
                      onClick={() => this.confirmDelete(record.requestId)}
                    >
                      <TrashIcon />
                    </DeleteButton>
                  ) : record.id === importLogId &&
                    record.status === "Deleting" ? (
                    <InfoTooltip
                      title={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "0 5px",
                          }}
                        >
                          <p>
                            Os dados de peso estão sendo recalculados devido
                            importacao/deleção de um arquivo. Aguarde o
                            processamento acabar para poder deletar um arquivo
                            novamente.
                          </p>
                        </div>
                      }
                    />
                  ) : null
                }
              />
            </Table>
          </Col>
        </Row>
      </CardCustom>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WeightImport)
);
