import styled from "styled-components";
import { Drawer, Input } from "antd";

export const Container = styled(Drawer)`
  .ant-drawer-body {
    padding: 24px 0px 10px 0px;
    overflow-x: hidden !important;
  }

  .drawerForm {
    width: 100%;
    min-height: -moz-calc(100vh - 165px);
    min-height: -webkit-calc(100vh - 165px);
    min-height: calc(100vh - 165px);
    padding: 0px 24px;
  }

  .drawerFormCustom {
    width: 100%;
    height: 145px;
    padding: 0px 24px;
    margin-bottom: 15px;
  }

  .rowLabel {
    margin-bottom: 7px;
    margin-top: 19px;
  }

  .captcha {
    width: 100%;
    margin-top: 30px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    margin-bottom: 7px;
    margin-top: 19px;
    &.error {
      color: #d44c4c;
    }
  }

  .rowInput {
    margin-top: 8px;
  }

  .tableButtonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      display: flex;
      justify-content: center !important;
      text-align: center !important;
      align-items: center !important;
      font-size: 14px !important;
      width: 77px !important;
      height: 30px !important;

      svg {
        margin-right: 3px !important;
      }
    }

    button:first-of-type {
      width: 116px !important;
      margin-right: 12px !important;
    }
  }

  .ant-switch-checked {
    background-color: #a9c133 !important;
  }

  .resend {
    background: transparent;
    border: none;
    color: #a9c133 !important;
    font-size: 14px;
    cursor: pointer;
  }

  .hide {
    display: none;
  }
`;

export const CustomInput = styled(Input)`
  border-color: #9074bf !important;
  text-align: center;

  &ant-input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #9074bf !important;
  }
  &ant-input::-moz-placeholder {
    /* Firefox 19+ */
    color: #9074bf !important;
  }
  &ant-input:-ms-input-placeholder {
    /* IE 10+ */
    color: #9074bf !important;
  }
  &ant-input:-moz-placeholder {
    /* Firefox 18- */
    color: #9074bf !important;
  }
`;
