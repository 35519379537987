import React from 'react';


const AlertDateIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg"  width="19" height="23" viewBox="0 0 19 23">
  <defs>
    <pattern id="pattern" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 42 51">
      <image width="42" height="51" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAzCAYAAADhGpoIAAADO0lEQVRoge2ZTYhNURzAfzOGxIRiYsyMaEhGiY2xmBGaKSFNKAs2dkqJsPCRksbCx042FsrHZkoZkVFqxIgU2RCFmRjGxmc+mmk8Ovrf15n/nPvu3HfPe94wv3q9d84999zf/Z+Pe965Rd319XjkCBBU2A4c9FW1b9HbQJ2VHgWkfFRc7KMSi7ohl4yJb9Gc4Vu0Y7iIvrB+X/LVP8nBYJph/f4sHy8EES0DTgOVCSt9ZX2SSo6T6e6Pk4loKfAIqJYCVUB3woskZaLMw4ukngoT0X2WpOG1h8gmYSrwwJI0nDcRrRQ5mz6gHPgQccFiuamVwDRgirTIaOAl0CPf5kHwbgiDazLQBZRaecatsUSaucohOw+4E1LhdGAnsDtm5I4DZ4AnIcfnuySBZ8FgCmS/ShQbQyTHAnuBN1lIIuc8Bo5J9DS3gHVaEsf0ZDpxRcgdLwWuqjtOyhqpU1MD/AwkDSWqgGvuKwK2AqcySLUCbdK/TL/sBWYCs4FlwPqQ864Ahx2rrEGBiprwjWSzNLfmPrADuJupAosGqWux41iHtTx0EvUI3RAiafJrY0gabsg5TRJxG7Pq2pOt6FqgxZFv+tXFGIKaVpk1vqj8o8DysJPCmn4S8FHl9cpI/ZZAUtMpfTmgT27ivS4YFtHtjryFniUNs9RDZQywy1XQJWqmqEMqz8x/T/06ptmk0mZMzNWFXKIbHXkn/LoNoM0xKLfpQlrUTEcHVN7+3DmmaVbpJvljmEaLVsij1OZkHkSvq3SVPCzSaNFVKt3umEZyQb9MWzYrMonqdei5PEgGaNFaO6FFy1S6K2dag9HXWmAntKjunz15EAy71gQ7oVdPq1Var6TGA1vkd2fIEi1b9LWqJZApl2gU19Qqp8ijaEb+2y2dnBFXtH+4iMbt0974ZyP61xgR9U3U4GiQf49Y/9Ntyj36NGQ6GCV6NuL42/g+2aGb3uezOykDNu206OUCEr1nb1Pqpm+RxXPoRkAe+A7c1HtdWvSTz9eCPhmZR31jN/2cqK2/GPhYE/wAHso7gF+BaI1sWRcim4ELQdMvKVBJZLc73UefO96KFArm1U+6j5q3EfY+ZSGRAvgNfTyYc98O2lkAAAAASUVORK5CYII="/>
    </pattern>
  </defs>
  <rect id="alerta22" width="19" height="23" fill="url(#pattern)"/>
</svg>
);

export default AlertDateIcon;