import styled, { keyframes } from "styled-components";

const opacityAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  height: 100% !important;
  min-height: 100% !important;
  width: 100% !important;
  min-width: 100% !important;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1024px) {
    overflow-x: hidden !important;
    overflow-y: hidden !important;

    .logo-vertical {
      display: none;
    }
  }

  @media (max-width: 900px) {
    .logo-vertical {
      margin-top: 50px;
      display: flex;
      justify-content: center;
    }
  }

`;

export const Container = styled.div`
  height: 100% !important;
  min-height: 100% !important;
  width: 100% !important;
  min-width: 100% !important;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  flex-direction: row;
  background: #ffffff;

  @media all and (width: 812px) and (height: 375px) {
    height: 100% !important;
    min-height: 100% !important;
    width: 100% !important;
    min-width: 100% !important;
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;

    span {
      margin: 10px 0;
    }
  }

  @media all and (max-width: 767px) {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex;
    flex-direction: column;
    padding: 10px;

    span {
      margin: 10px 0;
    }
  }

`;

export const RegisterContainer = styled.div`
  position: relative;
  width: 500px;
  height: 100% !important;
  min-height: 100% !important;
  -webkit-box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.40);
  -moz-box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.40);
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.40);

  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  flex-direction: column;

  align-items: flex-start;

  background: #f5f5f5;

  padding: 40px 57px 32px 20px;

  svg {
    width: 136.56px !important;
    height: 24px !important;
    margin-left: 19px;
  }

  @media screen and (max-width: 1024px) {
   display: none;
  }


`;

export const LogoContainer = styled.div`
  text-align: center;
  margin-top: 200px;
  svg {
    width: 136.56px !important;
    height: 24px !important;
    margin-left: 19px;
  }

  @media screen and (max-width: 1024px) {
    /* start of large tablet styles */
    width: 100% !important;
    svg {
      width: auto !important;
      height: auto !important;
      margin: 0;
      margin-top: 40px;
    }
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
    width: 100% !important;
    svg {
      width: auto !important;
      height: auto !important;
      margin: 0;
    }
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
    width: 100% !important;
    svg {
      width: auto !important;
      height: auto !important;
      margin: 0;
    }
  }
`;

export const FormContainer = styled.div`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 455px;

  & > * {
    position: relative;
    animation: ${opacityAnimation};
    -webkit-animation: ${opacityAnimation};
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
  }

  .hide {
    display: none;
  }

  .back-container{
    display: flex;
    align-items: center;
    padding-bottom: 10px;
  }

  .title {
    position: relative;
    font-family: "Asap", sans-serif;
    font-weight: bold;
    font-size: 27px;
    color: #111;
    margin-left: 19px;
  }

  .back {
    position: relative;
    font-family: "Asap", sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #D44C4C;
    margin-left: 5px;
  }

  .subTitle {
    font-family: "Asap", sans-serif;
    font-weight: normal;
    font-size: 16px;
    color: #111;
    margin-left: 19px;
    padding-bottom: 10px;
  }

  label {
    margin-left: 19px !important;
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #9074bf;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  form {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    width: 100% !important;
    min-width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

    span.error {
      color: #c14933;
      margin-left: 19px;
    }
    a {
      margin-top: 20px;
      font-size: 1em;
      text-decoration: none;
    }

    .formFooter {
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        margin-left: 19px;
        margin-bottom: 0;
      }
    }
    @media screen and (max-width: 1024px) {
      .formFooter {
        justify-content: center;
      }
    }

  }

  .input {
    max-width: 455px;
  }

  @media screen and (max-width: 1024px) {
    .formFooter {
      justify-content: center;
    }
  }

`;

export const DisplayContainer = styled.div`
  width: 100% !important;
  height: 100% !important;
  min-height: 100% !important;

  /* filter: drop-shadow(0px 3px 18px rgba(0, 0, 0, 0.38)) !important; */

  #myVideo {
    position: fixed;
    opacity: 0.69;
    min-width: 100%;
    min-height: 100%;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: center;
  }

`;

export const DisplayBody = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;

  strong{
    color: #4A85AE;
  }

  @media screen and (min-width: 1024px) {
    width: 100% !important;
    min-width: 100% !important;
    height: 100% !important;
    min-height: 100% !important;
    margin-top: 45px;
    margin-left: 50px;
    justify-content: initial;
  }

  @media screen and (max-width: 1024px) {
    justify-content: flex-start;
    padding-top: 50px;
  }

  .hide{
    display: none;
  }
`;

export const DisplayBodyMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  & > * {
    -webkit-animation: ${opacityAnimation};
    animation: ${opacityAnimation};
    animation-timing-function: ease-in;
  }

  svg {
    width: 111px !important;
    height: 130px !important;
    margin-right: 41px !important;
    animation-duration: 1s;
    path {
      fill: #fff !important;
      opacity: 1 !important;
    }
  }
  span {
    padding-left: 19px;
    font-family: "Asap", sans-serif;
    font-size: 24px;
    text-align: left;
    color: #c4c4c4;
    animation-duration: 2s;
  }

`;


export const ButtonCustom = styled.button`
  width: 146px;
  height: 40px;
  border-radius: 30px;
  border: none;
  font-size: 16px;
  font-weight: bold !important;
  color: #fff !important;
  background: #684e94;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);
  }
  &:active {
    background: #a9c133;
  }

  &:disabled {
    cursor: not-allowed;
    color: #fff !important;
    background: #e8e5e5 !important;
  }
`;