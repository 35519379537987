import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Creators as IntegrationPartnersActions } from "../../store/ducks/integrationPartners";

// Components
import { Row, Col, Table, Menu, Dropdown, notification, Icon } from "antd";
import TagStatus from "../../components/utils/tagStatus";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import MenuIcon from "../../components/utils/table/icons/menu";
import ButtonStandard from "../../components/utils/button";
import DrawerIntegrationPartner from "../../components/drawers/integrationPartner";

// Styles
import { Title, CardCustom } from "./styles";

//Services
import {
  getIndexIntegrationsFarm,
  inactivateFarmIntegrationPartner,
  reactivateFarmIntegrationPartner,
} from "../../services/integrationPartnersService";

const IntegrationPartners = (props) => {
  // Variable Redux
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const Column = Table.Column;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshTable, setRefreshTable] = useState(true);

  useEffect(() => {
    async function loadData() {
      setIsLoading(true);
      try {
        const {
          data: { results },
        } = await getIndexIntegrationsFarm({ groupId, farmId });

        setData(results);
      } catch (error) {
        notification.error({
          message:
            "Ocorreu um erro ao acessar as informações de parceiros para integração.",
        });
      } finally {
        setIsLoading(false);
        setRefreshTable(false);
      }
    }

    if (refreshTable) {
      loadData();
    }
  }, [groupId, farmId, refreshTable]);

  function refresh() {
    setRefreshTable(true);
  }

  function handleOpenDrawer(action = "new", id = null) {
    dispatch(
      IntegrationPartnersActions.showDrawerIntegrationVisible(action, id)
    );
  }

  function handleOpenDrawerDetails(id) {
    dispatch(
      IntegrationPartnersActions.showDrawerIntegrationVisible("details", id)
    );
  }

  function handleEditOrDetails(id, action) {
    try {
      if (action === "edit") {
        handleOpenDrawer("edit", id);
      } else {
        handleOpenDrawerDetails(id);
      }
    } catch (e) {}
  }

  async function handleInactivate(id) {
    try {
      setIsLoading(true);
      await inactivateFarmIntegrationPartner({
        groupId,
        farmId,
        id,
      });
      refresh();
    } catch (error) {}
  }

  async function handleReactivate(id) {
    try {
      setIsLoading(true);
      await reactivateFarmIntegrationPartner({
        groupId,
        farmId,
        id,
      });
      refresh();
    } catch (error) {}
  }

  const renderMenu = (record) => {
    return (
      <Menu>
        <Menu.Item key="0">
          <button onClick={() => handleEditOrDetails(record.id, "edit")}>
            Editar
          </button>
        </Menu.Item>
        {record.status === "Active" ? (
          <Menu.Item key="1">
            <button onClick={() => handleInactivate(record.id)}>
              Inativar
            </button>
          </Menu.Item>
        ) : (
          <Menu.Item key="2">
            <button onClick={() => handleReactivate(record.id)}>
              Reativar
            </button>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <CardCustom>
      <Row type="flex" justify="space-between" className="rowHeader">
        <Col>
          <Title>{translation.integrationPartners.table.title}</Title>
        </Col>
        <Col>
          <ButtonStandard
            buttonType="typeWithoutBackground"
            onClick={() => refresh()}
          >
            <Icon type="reload" />
          </ButtonStandard>
          <ButtonStandard
            buttonType="type8"
            onClick={() => handleOpenDrawer("new", null)}
          >
            Nova Integração
          </ButtonStandard>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            loading={isLoading}
            rowKey="id"
            size="small"
            dataSource={data != null ? data : []}
            pagination={{
              pageSize: 10,
              hideOnSinglePage: data.length > 10 ? false : true,
            }}
            style={{ width: "100%", margin: "0" }}
          >
            <Column
              title={translation.integrationPartners.table.columns.status}
              dataIndex="status"
              key="status"
              align="left"
              sorter
              sortDirections={["descend", "ascend"]}
              render={(status) =>
                status === "Active" ? (
                  <TagStatus
                    background="#C5F1CA"
                    borderColor="#106518"
                    color="#106518"
                  >
                    {translation.status.active}
                  </TagStatus>
                ) : (
                  <TagStatus
                    background="#FBC7C7"
                    borderColor="#D44C4C"
                    color="#D44C4C"
                  >
                    {translation.status.inactive}
                  </TagStatus>
                )
              }
            />
            <Column
              title={translation.integrationPartners.table.columns.system}
              dataIndex="integrationPartner.partnerSystemName"
              key="nampartnerSystemNamee"
              align="left"
              sorter
              sortDirections={["descend", "ascend"]}
            />
            <Column
              title={translation.integrationPartners.table.columns.authType}
              dataIndex="integrationPartner.authentication"
              key="authType"
              align="left"
              render={(text) => translation.integrationPartners.authType[text]}
            />
            <Column
              align="left"
              render={(text, record) => {
                return (
                  <Dropdown
                    overlay={renderMenu(record)}
                    trigger={["click"]}
                    key={record.id}
                  >
                    <MenuContainer>
                      <MenuIcon />
                    </MenuContainer>
                  </Dropdown>
                );
              }}
            />
          </Table>
        </Col>
      </Row>
      {/* Drawers */}
      <DrawerIntegrationPartner />
    </CardCustom>
  );
};

export default withRouter(IntegrationPartners);
