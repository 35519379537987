import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const InputStandard = ({
  background,
  fontColor,
  children,
  border,
  width,
  height,
  inputWidth,
  placeholderColor,
  className,
}) => (
  <Container
    background={background}
    fontColor={fontColor}
    border={border}
    width={width}
    height={height}
    inputWidth={inputWidth}
    placeholderColor={placeholderColor}
    className={className}
  >
    {children}
  </Container>
);

InputStandard.propTypes = {
  background: PropTypes.string,
  fontColor: PropTypes.string,
  children: PropTypes.any,
  border: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  inputWidth: PropTypes.string,
  placeholderColor: PropTypes.string,
  className: PropTypes.any,
};

export default InputStandard;
