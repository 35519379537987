import React, { useState, useEffect } from "react";
import Axios from "axios";
import { withRouter, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Table, Icon } from "antd";

import { Creators as AppActions } from "../../../store/ducks/app";

import { Title, CardCustom } from "./styles";

import EyeIcon from "../../../components/utils/icons/eye";
import ButtonStandard from "../../../components/utils/button";
import TagStatus from "../../../components/utils/tagStatus";

import { getSupplementWeightImportLog, getSupplementWeightImportLogSupplierGroup } from "../../../services/importService";

const SupplementImport = () => {
  const [importSupplementLogData, setImportSupplementLogData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
    user: {isInputSupplier}
  } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  useEffect(() => {
    const signal = Axios.CancelToken.source();
    async function fetch(page = 0, sorter = {}, filters = "", size = 10) {
      setIsLoading(true);
      try {
        if (isInputSupplier) {
          const {
            data: { results },
          } = await getSupplementWeightImportLogSupplierGroup({
            groupId,
            farmId,
            page,
            sorter,
            filters,
            size,
            signal: signal,
          });
          setImportSupplementLogData(results);
        } else {
          const {
            data: { results },
          } = await getSupplementWeightImportLog({
            groupId,
            farmId,
            page,
            sorter,
            filters,
            size,
            signal: signal,
          });
          setImportSupplementLogData(results);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
    fetch();
    return () => {
      signal.cancel("Cancelando apis");
    };
  }, [groupId, farmId]);

  const fetchData = async (page = 0, sorter = {}, filters = "", size = 10) => {
    const signal = Axios.CancelToken.source();
    setIsLoading(true);
    try {
      if (isInputSupplier) {
        const {
          data: { results },
        } = await getSupplementWeightImportLogSupplierGroup({
          groupId,
          farmId,
          page,
          sorter,
          filters,
          size,
          signal: signal,
        });
        setImportSupplementLogData(results);
      } else {
        const {
          data: { results },
        } = await getSupplementWeightImportLog({
          groupId,
          farmId,
          page,
          sorter,
          filters,
          size,
          signal: signal,
        });
        setImportSupplementLogData(results);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const showModalToImport = () => {
    dispatch(AppActions.showModalSupplementUpload());
  };

  const handleReloadTable = async () => {
    fetchData();
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    let search = "";
    fetchData(pagination.current, sorter, search, pagination.pageSize);
  };

  return (
    <CardCustom>
      <Row type="flex" justify="space-between" className="rowHeader">
        <Col>
          <Title>{translation.imports.supplement.table.title}</Title>
        </Col>
        <Col>
          <ButtonStandard
            buttonType="typeWithoutBackground"
            onClick={handleReloadTable}
          >
            <Icon type="reload" />
          </ButtonStandard>
          <ButtonStandard
            type="button"
            buttonType="type8"
            onClick={showModalToImport}
          >
            {translation.imports.supplement.buttonImport}
          </ButtonStandard>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            loading={isLoading}
            rowKey="id"
            size="small"
            dataSource={
              importSupplementLogData != null
                ? importSupplementLogData.content
                : []
            }
            pagination={{
              total:
                importSupplementLogData != null
                  ? importSupplementLogData.totalElements
                  : 0,
              size:
                importSupplementLogData != null
                  ? importSupplementLogData.size
                  : 0,
              current:
                importSupplementLogData != null
                  ? importSupplementLogData.number + 1
                  : 0,
              hideOnSinglePage:
                importSupplementLogData !== null &&
                Object.entries(importSupplementLogData).length !== 0
                  ? importSupplementLogData.totalElements > 10
                    ? false
                    : true
                  : true,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
            }}
            scroll={{
              x: true,
            }}
            style={{ width: "100%", margin: "0" }}
            onChange={handleTableChange}
          >
            <Table.Column
              title={translation.imports.supplement.table.columns.status}
              dataIndex="status"
              key="status"
              align="left"
              render={(status) =>
                status === "Processed" ? (
                  <TagStatus
                    background="#C5F1CA"
                    borderColor="#106518"
                    color="#106518"
                  >
                    {translation.imports.status.processed}
                  </TagStatus>
                ) : status === "Queue" ? (
                  <TagStatus
                    background="#EBF7FF"
                    borderColor="#4A85AE"
                    color="#4A85AE"
                  >
                    {translation.imports.status.queue}
                  </TagStatus>
                ) : status === "Processing" ? (
                  <TagStatus
                    background="#FEFFF6"
                    borderColor="#A9C133"
                    color="#A9C133"
                  >
                    {translation.imports.status.processing}
                  </TagStatus>
                ) : status === "Deleting" ? (
                  <TagStatus
                    background="#FFD8D8"
                    borderColor="#D44C4C"
                    color="#D44C4C"
                  >
                    {translation.imports.status.deleting}
                  </TagStatus>
                ) : (
                  <TagStatus
                    background="#FFD8D8"
                    borderColor="#D44C4C"
                    color="#D44C4C"
                  >
                    {translation.imports.status.error}
                  </TagStatus>
                )
              }
            />
            <Table.Column
              title={translation.imports.supplement.table.columns.fileName}
              dataIndex="fileName"
              key="fileName"
              align="left"
              sorter
              sortDirections={["descend", "ascend"]}
            />
            <Table.Column
              title={translation.imports.supplement.table.columns.date}
              dataIndex="date"
              align="left"
              key="date"
            />
            <Table.Column
              title={translation.imports.supplement.table.columns.total}
              dataIndex="total"
              align="center"
              key="total"
            />
            <Table.Column
              title={
                translation.imports.supplement.table.columns.numberImported
              }
              dataIndex="numberImported"
              align="center"
              key="numberImported"
            />
            <Table.Column
              title={translation.imports.supplement.table.columns.numberError}
              dataIndex="numberError"
              align="center"
              key="numberError"
            />
            <Table.Column
              title={
                translation.imports.supplement.table.columns.numberDuplicated
              }
              dataIndex="numberDuplicated"
              align="center"
              key="numberDuplicated"
            />
            <Table.Column
              title={translation.imports.supplement.table.columns.numberUpdated}
              dataIndex="numberUpdated"
              align="center"
              key="numberUpdated"
            />
            <Table.Column
              title={translation.imports.supplement.table.columns.message}
              dataIndex="message"
              align="left"
              key="message"
            />
            {!isInputSupplier && (
              <Table.Column
                align="left"
                render={(text, record) => (
                  <Link
                    to={`/admin/imports/supplements/${record.requestId}/details`}
                  >
                    <EyeIcon isActive />
                  </Link>
                )}
              />)}
          </Table>
        </Col>
      </Row>
    </CardCustom>
  );
};

export default withRouter(SupplementImport);
