import { useContext } from "react";
import { FinancialProjectContext } from "../../contexts/financialProjectContext";

const useFinancialProjectContext = () => {
  const value = useContext(FinancialProjectContext);

  return value;
};

export default useFinancialProjectContext;
