import React from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import moment from "moment";

import "moment/locale/pt-br";
import "moment/locale/en-nz";
import "moment/locale/es";

// Components
import { Input, Row, Col, DatePicker, Select } from "antd";
import { Container, Body, Footer } from "../edit/styles";
import ButtonStandard from "../../../utils/button";
import { useMemo } from "react";

const AnimalDiscard = ({
  gender,
  title,
  visible,
  closeDrawer,
  propsFormik,
  confirmDiscard,
}) => {
  const { translation } = useSelector((state) => state.app);
  const dateFormat = "DD/MM/YYYY";
  const { TextArea } = Input;
  const validationSchema = Yup.object().shape({
    discardDate: Yup.string().required(),
    discardMotive: Yup.string().required(),
  });

  const listDiscardMotives = useMemo(() => {
    return [
      {
        id: 0,
        value: "Age",
      },
      {
        id: 1,
        value: "ReproductiveProblems",
      },
      {
        id: 2,
        value: "IllnessesOrPhysicalProblems",
      },
      {
        id: 3,
        value: "BodyCondition",
      },
      {
        id: 4,
        value: "NegativePregnancy",
      },
      {
        id: 5,
        value: "MaternalAbility",
      },
    ].filter((m) =>
      gender === "M" || gender === "Male" ? m.id <= 3 : m.id >= 0
    );
  }, [gender]);
  return (
    <Container
      title={title}
      width={435}
      visible={visible}
      onClose={closeDrawer}
      maskClosable={false}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          discardDate: null,
          discardObservation: "",
          discardMotive: null,
        }}
        onSubmit={(values, actions) => {
          confirmDiscard(
            values.discardDate,
            values.discardMotive,
            values.discardObservation,
            propsFormik
          );
          actions.resetForm({});
        }}
        validationSchema={validationSchema}
        render={(props) => (
          <form>
            <Body>
              <Row type="flex">
                <Col span={12}>
                  <Row>
                    <label
                      className={
                        props.errors.discardDate && props.touched.discardDate
                          ? "formLabel error"
                          : "formLabel"
                      }
                    >
                      Data*
                    </label>
                  </Row>
                  <Row>
                    <DatePicker
                      name="discardDate"
                      allowClear={false}
                      style={{ width: "100%" }}
                      disabledDate={(current) => current.isAfter(moment())}
                      value={
                        props.values.discardDate != null
                          ? moment(props.values.discardDate, "YYYY-MM-DD")
                          : undefined
                      }
                      placeholder={translation.defaultDatePickerPlaceholder}
                      format={dateFormat}
                      onChange={(date, dateString) => {
                        props.setFieldValue("discardDate", date);
                      }}
                    />
                  </Row>
                </Col>
              </Row>
              <Row type="flex" style={{ marginTop: 8 }}>
                <Col span={16}>
                  <Row>
                    <label
                      className={
                        props.errors.discardMotive &&
                        props.touched.discardMotive
                          ? "formLabel error"
                          : "formLabel"
                      }
                    >
                      Causa*
                    </label>
                  </Row>
                  <Row>
                    <Select
                      name="discardMotive"
                      style={{ width: "100%" }}
                      value={props.values.discardMotive || undefined}
                      placeholder={translation.defaultSelectPlaceholder}
                      onChange={(value) =>
                        props.setFieldValue("discardMotive", value)
                      }
                    >
                      {listDiscardMotives.map((motive) => (
                        <Select.Option key={motive.id} value={motive.value}>
                          {translation.animal.discard.motives[motive.value]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
              </Row>
              <Row type="flex" style={{ marginTop: 21 }}>
                <Col span={24}>
                  <TextArea
                    name="discardObservation"
                    value={props.values.discardObservation}
                    placeholder="Descrição do descarte"
                    maxLength={90}
                    style={{
                      width: "100%",
                      height: 249,
                      padding: "10px 10px",
                    }}
                    onChange={(e) => {
                      props.setValues({
                        ...props.values,
                        discardObservation: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </Body>
            <Footer>
              <Row type="flex" justify="end">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className="buttonsDiv"
                >
                  <ButtonStandard
                    type="button"
                    buttonType="type7"
                    onClick={closeDrawer}
                  >
                    {translation.buttons.cancel}
                  </ButtonStandard>
                  <ButtonStandard
                    type="button"
                    buttonType="type6"
                    onClick={props.submitForm}
                  >
                    {translation.buttons.putToDiscard}
                  </ButtonStandard>
                </Col>
              </Row>
            </Footer>
          </form>
        )}
      />
    </Container>
  );
};

export default AnimalDiscard;
