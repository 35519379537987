import React from "react";

// import { Container } from './styles';

const AvatarUser = () => (
  <svg
    id="bovexoAvatarUser"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g id="user" transform="translate(-4.522 -4.522)">
      <path
        id="Path_911"
        data-name="Path 911"
        d="M14.521,27,10.14,29.444a3.939,3.939,0,0,0-.7.511,14.431,14.431,0,0,0,18.853.05,3.882,3.882,0,0,0-.772-.529l-4.691-2.4a1.833,1.833,0,0,1-.989-1.636V23.562a7.222,7.222,0,0,0,.444-.583,11.133,11.133,0,0,0,1.458-3,1.5,1.5,0,0,0,1.045-1.433V16.534a1.514,1.514,0,0,0-.491-1.113v-2.9S24.877,8,18.892,8s-5.4,4.518-5.4,4.518v2.9A1.512,1.512,0,0,0,13,16.534v2.008a1.514,1.514,0,0,0,.679,1.263,10.045,10.045,0,0,0,1.776,3.756V25.4A1.837,1.837,0,0,1,14.521,27Z"
        transform="translate(0.63 1.038)"
        fill="#684e94"
      />
      <g
        id="Group_540"
        data-name="Group 540"
        transform="translate(4.522 4.522)"
      >
        <path
          id="Path_912"
          data-name="Path 912"
          d="M15.257,0A14.9,14.9,0,0,0,5.348,26.336a3.986,3.986,0,0,1,.712-.5L10.536,23.4a1.818,1.818,0,0,0,.953-1.6V19.982a9.884,9.884,0,0,1-1.815-3.736,1.5,1.5,0,0,1-.694-1.257v-2a1.488,1.488,0,0,1,.5-1.107V9S8.885,4.506,15,4.506,20.519,9,20.519,9v2.887a1.486,1.486,0,0,1,.5,1.107v2a1.5,1.5,0,0,1-1.068,1.425,10.951,10.951,0,0,1-1.49,2.987,7.207,7.207,0,0,1-.453.58v1.871a1.817,1.817,0,0,0,1.01,1.627l4.793,2.384a3.992,3.992,0,0,1,.787.525A14.905,14.905,0,0,0,15.257,0Z"
          transform="translate(0 0)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
export default AvatarUser;
