import { call, put } from "redux-saga/effects";
import { Creators as CostCenterActions } from "../ducks/costCenter";

import { notification } from "antd";

/** Services */
import {
  indexAll,
  Inactivate,
  Activate,
} from "../../services/costCenterService";

export function* indexAllCostCenters(action) {
  try {
    const {
      data: { results: list },
    } = yield call(indexAll, action.payload);
    yield put(CostCenterActions.indexSuccess(list))
  } catch (error) {
    yield put(CostCenterActions.indexError(error));
  }
}

export function* inactivateCostCenter(action) {
  try {
    yield call(Inactivate, action.payload);
  } catch (error) {
    notification.error({
      message: `Centro de Custo não foi inativado. Contate o suporte`,
    });
  }

  yield put(CostCenterActions.index(action.payload.groupId, action.payload.farmId));
}

export function* activateCostCenter(action) {
  try {
    yield call(Activate, action.payload);
  } catch (error) {
    notification.error({
      message: `Centro de Custo não foi ativado. Contate o suporte`,
    });
  }

  yield put(CostCenterActions.index(action.payload.groupId, action.payload.farmId));
}