import styled from "styled-components";

export const Container = styled.div`
  label {
    color: #9074bf;
    font-family: "Asap", sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    &:first-child {
      margin-top: 5px;
    }
    &.error {
      color: #d44c4c;
    }
  }
  .ant-select {
    width: 100%;
  }

  .body {
    margin-bottom: 16px;
    .ant-row-flex {
      margin-top: 8px;
    }
  }
`;
