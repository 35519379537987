import styled from "styled-components";
import { Popconfirm } from "antd";

export const Container = styled.div`
  height: 100%;
  min-height: 100%;

  padding-top: 16px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;

  overflow-x: hidden;
  overflow-y: auto !important;
  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;

    &.error {
      color: #d44c4c;
    }
  }

  #bovexoInfoIcon {
    margin-left: 5px;
  }

  .ant-row.rowInput {
    margin-top: 8px;
  }
  .buttonsCol {
    button {
      &:first-of-type {
        margin-right: 19px;
      }
    }
  }
`;

export const Title = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  color: #4b4b4b;
  margin: 0;
  width: auto;
`;

export const CardSaveDietStrategy = styled.div`
  width: 100%;
  border-radius: 5px;
  background: #f5f5f5;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 20px;
  margin-top: 17px;
  margin-bottom: 18px;

  p {
    text-align: justify;
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    color: #4b4b4b;
  }

  .radioStyle {
    display: block;
    height: 30px;
    line-height: 30px;
  }

  .rowWithMarginBottom {
    margin-bottom: 5px;
  }

  button.edit {
    background: transparent;
    border: none;
    font-family: Asap;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    color: #fe8d2a;
    text-transform: uppercase;
    cursor: pointer;
    svg {
      margin-left: 5px;
      path {
        fill: #fe8d2a;
      }
    }
  }

  span.info {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;

    &.title {
      color: #684e94;
      font-weight: bold;
      font-size: 16px;
    }

    strong {
      margin-left: 5px;
    }
  }

  div.cardLotInfo {
    height: 100%;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 15px;

    .ant-row:not(:first-of-type) {
      margin-top: 11px !important;
    }

    span {
      font-family: Asap;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: #4b4b4b;

      &.title {
        color: #9074bf;
      }

      strong {
        margin-left: 5px;
      }
    }
  }

  div.buttonGroup {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      background: transparent;
      border: none;
      font-family: Asap;
      font-weight: bold;
      font-size: 14px;
      text-transform: uppercase;
      color: #684e94;
      &.cancel {
        color: #d44c4c;
        margin-right: 19px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
    .ant-col {
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
    .ant-col {
      margin-top: 10px;
    }
  }
`;

export const AllocatePicketInfoCard = styled.div`
  display: flex;
  width: 100%;
  border-radius: 6px;
  background: transparent;
  border: 1px dashed #d44c4c;
  margin-top: 13px;
  font-family: Asap;
  font-size: 12px;
  text-align: left;
  color: #d44c4c;
  padding: 8px;
  align-items: center;
  svg {
    margin-right: 5px;
    g {
      path {
        fill: #d44c4c !important;
        color: #d44c4c !important;
      }
    }
  }
`;

export const ChartContainer = styled.div`
  /* margin: 14px 0; */
  height: 360px;
`;

export const ListPeriodsContainer = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  flex-wrap: nowrap;
  background: transparent;
  overflow-x: auto;
`;
export const ListPeriodsTitleContainer = styled.div`
  display: flex;
  background: #4b4b4b;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  align-items: center;
  justify-content: center;
  padding: 0px 30px;
  span {
    font-family: Asap;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    color: #fff;
  }
`;

export const ListPeriodsItemContainer = styled.div`
  flex: 1;
  display: flex;
  margin-left: 5px;
  border-right: 5px solid #c6de52;
  white-space: nowrap;
  flex-wrap: nowrap;
  background: #4b4b4b;
  overflow-x: auto;
  padding: 0px 10px;

  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
    overflow-x: scroll;
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
    overflow-x: scroll;
  }
`;

export const ListPeriodsItem = styled.div`
  display: inline-flex;
  width: 100px;
  min-width: 100px;
  height: 88px;
  max-height: 88px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 15px;
  padding: 10px;
  transition: all 0.2s ease-in-out;

  &.selected {
    background: #a9c133;
    span.info {
      font-family: Asap;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: #fff;
    }
  }

  &:first-of-type {
    margin-left: 15px;
  }

  &:hover {
    cursor: pointer;
    background: #f5f5f5;
    .time {
      span {
        color: #4b4b4b !important;
      }
    }
  }
  div.itemContainer {
    width: 100%;
    display: flex;
    flex-direction: column;

    div.iconContainer {
      width: 100%;

      svg {
        margin-right: 5px;
      }
    }

    div.timeContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      div.time {
        width: 100%;
        display: flex;
        flex-direction: column;
        span {
          font-family: Asap;
          font-weight: normal;
          font-size: 14px;
          text-align: left;
          color: #a5a5a5;
        }
      }

      div.icons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        #bovexoAlertIcon {
          margin-right: 5px;
        }
        div.circle {
          width: 7.38px;
          height: 7.38px;
          border-radius: 50%;
          background: #a9c133;

          &.red {
            background: #d44c4c;
          }
        }
      }
    }
  }
`;

export const ListPeriodsItemPlus = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  max-width: 100px;
  height: 88px;
  max-height: 88px;
  border-radius: 5px;
  background: #fff;
  border: 2px solid #9074bf;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 15px;
  padding: 20px 10px 10px 10px;
  transition: all 0.2s ease-in-out;
  position: relative;

  span {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #707070;
  }

  svg {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const PopConfirmCustom = styled(Popconfirm)`
  &.ant-popover-buttons {
    display: flex !important;
    justify-content: space-between !important;
  }

  &.ant-btn-primary {
    padding-left: 180px !important;
    background: #4a85ae;
  }
`;
