import React, { useState, useEffect } from "react";
import Axios from "axios";
/** Redux Libs */
import { useSelector, useDispatch } from "react-redux";
import { Creators as GeneralParameterActions } from "../../../store/ducks/generalParameters";
/** Components */
import { Container, Title, Message, CardMessage, EditButton } from "./styles";
import { Row, Col, Spin, notification } from "antd";
import EditWeatherSeason from "./edit";
import DisplayWeatherSeason from "./display";
import PencilIcon from "../../../components/utils/icons/pencil";
/** Services */
import {
  getWeatherSeason,
  setWeatherSeason,
} from "../../../services/generalParameterService";

const WeatherSeasonsParameter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [weatherSeasons, setWeatherSeasons] = useState(null);

  const {
    translation,
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const { parameterBeingEdit } = useSelector(
    (state) => state.generalParameters
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const signal = Axios.CancelToken.source();
    async function fetchData() {
      setIsLoading(true);
      try {
        const {
          data: { results },
        } = await getWeatherSeason({ farmId, signal });
        setWeatherSeasons(results);
        setIsLoading(false);
      } catch (error) {}
    }

    fetchData();

    return () => {
      signal.cancel();
    };
  }, [farmId]);

  async function saveEdit(data, typeSeason) {
    setIsLoading(true);
    dispatch(GeneralParameterActions.changeParameterBeingEdited(null));
    setEditing(false);
    try {
      const {
        data: { results },
      } = await setWeatherSeason({
        farmId,
        weatherSeasonId: data.id,
        typeSeason,
      });
      setWeatherSeasons(results);
      notification.success({
        message: translation.generalParameters.successParameterUpdated,
      });
    } catch (error) {
      notification.error({
        message: translation.generalParameters.errorParameterUpdated,
      });
    } finally {
      setIsLoading(false);
    }
  }

  function cancelEdit() {
    dispatch(GeneralParameterActions.changeParameterBeingEdited(null));
    setEditing(false);
  }

  function openEdit() {
    dispatch(
      GeneralParameterActions.changeParameterBeingEdited("weatherSeason")
    );
    setEditing(true);
  }

  return (
    <Container>
      <Row type="flex" justify="space-between">
        <Col xs={18} sm={18} md={18} lg={18} xl={18}>
          <Title>{`${translation.generalParameters.weatherSeason.title}: ${
            weatherSeasons != null
              ? `${weatherSeasons?.name} / ${
                  weatherSeasons?.farmSelectedTypeSeason === "Default"
                    ? "Padrão"
                    : weatherSeasons?.farmSelectedTypeSeason === "Drier"
                    ? "+ Seco"
                    : weatherSeasons?.farmSelectedTypeSeason === "Wetter"
                    ? "+ Umído"
                    : ""
                }`
              : ""
          }`}</Title>
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} align="right">
          <EditButton
            type="button"
            onClick={openEdit}
            disabled={
              isEditing ||
              (parameterBeingEdit !== null &&
                parameterBeingEdit !== "weatherSeason")
                ? true
                : false
            }
          >
            {isEditing
              ? `${translation.buttons.editing}...`
              : `${translation.buttons.edit}`}
            <PencilIcon />
          </EditButton>
        </Col>
      </Row>
      {isEditing ? (
        <EditWeatherSeason
          data={weatherSeasons}
          onSave={saveEdit}
          onCancel={cancelEdit}
        />
      ) : (
        <Spin spinning={isLoading}>
          {weatherSeasons == null ? (
            <CardMessage>
              <Row type="flex" justify="center" align="middle">
                <Message>
                  Clique em <span className="red">"editar"</span> para preencher
                  as estações climáticas
                </Message>
              </Row>
            </CardMessage>
          ) : (
            <DisplayWeatherSeason
              data={
                weatherSeasons.farmSelectedTypeSeason === "Default"
                  ? weatherSeasons.seasonsDefault
                  : weatherSeasons.farmSelectedTypeSeason === "Drier"
                  ? weatherSeasons.seasonsDrier
                  : weatherSeasons.farmSelectedTypeSeason === "Wetter"
                  ? weatherSeasons.seasonsWetter
                  : []
              }
            />
          )}
        </Spin>
      )}
    </Container>
  );
};

export default WeatherSeasonsParameter;
