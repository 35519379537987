import React, { Fragment, Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import CanvasJSReact from "../../../../../assets/canvasjs.react";
import { Tooltip, Spin } from "antd";
import InfoIcon from "../../../../../components/utils/icons/info";
import {
  animalBreedColor,
  dietStrategyColor,
  lotBreedColor,
} from "../../../../../utils/colorsHandler";
import { getTwoDecimalDigits } from "../../../../../services/helpersMethodsService";

const { CanvasJSChart } = CanvasJSReact;

class LineAverageDailyGainChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      animal: {
        animalDailyWeightDashboard,
        animalDailyWeightGainDashboard,
        animalLotDailyWeightDashboard,
        animalData,
      },
      curve: { animalCurveResult, dietStrategyCurveResult, isLoading },
      app: { translation },
    } = this.props;

    const transformAnimalsDailyWeight = () => {
      let result = [];
      if (
        animalDailyWeightDashboard != null &&
        animalDailyWeightDashboard.length > 0
      )
        animalDailyWeightDashboard.forEach((e) => {
          let obj = {
            x: moment(e.weightDate).toDate(),
            y: getTwoDecimalDigits(e.weight),
            toolTipContent: `<span style='"'color: {color};'"'>{name}:</span> ${moment(
              e.weightDate
            ).format("DD/MM/YY")}  | {y}`,
            markerType: "none",
          };
          if (!e.isReal) {
            obj = {
              ...obj,
              toolTipContent: `<span style='"'color: {color};'"'>{name} - Estimado:</span> ${moment(
                e.weightDate
              ).format("DD/MM/YY")}  | {y}`,
              markerType: "circle",
              lineDashType: "dash",
            };
          }
          result.push(obj);
        });
      return result;
    };

    const transformAnimalsDailyWeightGain = () => {
      let result = [];
      if (
        animalDailyWeightGainDashboard != null &&
        animalDailyWeightGainDashboard.length > 0
      )
        animalDailyWeightGainDashboard.forEach((e) => {
          let obj = {
            x: moment(e.weightGainDate).toDate(),
            y: getTwoDecimalDigits(e.weightGain),
            toolTipContent: `<span style='"'color: {color};'"'>{name}:</span> ${moment(
              e.weightGainDate
            ).format("DD/MM/YY")}  | {y}`,
            markerType: "none",
          };
          if (!e.isReal) {
            obj = {
              ...obj,
              toolTipContent: `<span style='"'color: {color};'"'>{name} - Estimado:</span> ${moment(
                e.weightGainDate
              ).format("DD/MM/YY")}  | {y}`,
              markerType: "circle",
              lineDashType: "dash",
            };
          }
          result.push(obj);
        });
      return result;
    };

    const transformAnimalsLotDailyWeight = () => {
      let result = [];
      if (
        animalLotDailyWeightDashboard != null &&
        animalLotDailyWeightDashboard.length > 0
      )
        animalLotDailyWeightDashboard.forEach((e) => {
          let obj = {
            x: moment(e.weightDate).toDate(),
            y: getTwoDecimalDigits(e.weight),
            toolTipContent: `<span style='"'color: {color};'"'>{name}:</span> ${moment(
              e.weightDate
            ).format("DD/MM/YY")}  | {y}`,
            markerType: "none",
          };
          result.push(obj);
        });
      return result;
    };

    const toggleDataSeries = (e) => {
      if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
        e.dataSeries.visible = false;
      } else {
        e.dataSeries.visible = true;
      }
      e.chart.render();
    };

    const options = {
      animationEnabled: true,
      zoomEnabled: true,
      height: 300,
      zoomType: "x",
      axisX: {
        valueFormatString: "MM/YY",
        interval: 7,
        intervalType: "month",
        minimum: moment(animalData?.birthday).toDate(),
        crosshair: {
          enabled: true,
          snapToDataPoint: true,
        },
        stripLines: [
          {
            value: moment(moment(), "DD/MM/YY").toDate(),
            label: "Hoje",
            labelPlacement: "inside",
            color: "#4b4b4b",
            labelFontColor: "#4b4b4b",
            labelBackgroundColor: "transparent",
          },
        ],
      },
      toolTip: {
        fontFamily: "Asap",
        shared: true,
      },
      axisY: {
        includeZero: true,
        valueFormatString: "###0.##",
        suffix: "kg",
        gridDashType: "dot",
      },
      axisY2: {
        includeZero: true,
        valueFormatString: "###0.##",
        suffix: "kg",
        lineColor: "#D3D3D3",
        tickColor: "#D3D3D3",
        titleFontColor: "#D3D3D3",
        labelFontColor: "#D3D3D3",
        gridDashType: "dot",
      },
      legend: {
        cursor: "pointer",
        itemclick: toggleDataSeries,
      },
      data: [
        {
          type: "line",
          name: "C. Animal",
          showInLegend: true,
          markerType: "none",
          markerSize: 5,
          yValueFormatString: "###0.## kg",
          color: animalBreedColor,
          dataPoints: animalCurveResult,
        },
        {
          type: "line",
          name: "C. Lote",
          showInLegend: true,
          markerType: "none",
          markerSize: 5,
          yValueFormatString: "###0.## kg",
          color: lotBreedColor,
          dataPoints: transformAnimalsLotDailyWeight(),
        },
        {
          type: "line",
          name: "Est. Dieta",
          showInLegend: true,
          markerType: "none",
          markerSize: 5,
          yValueFormatString: "###0.## kg",
          color: dietStrategyColor,
          dataPoints: dietStrategyCurveResult,
        },
        {
          type: "line",
          name: "Peso diário",
          showInLegend: true,
          markerType: "none",
          markerSize: 5,
          yValueFormatString: "###0.## kg",
          color: "#4F81BC",
          dataPoints: transformAnimalsDailyWeight(),
        },

        {
          type: "line",
          name: "Ganho de peso",
          axisYType: "secondary",
          showInLegend: true,
          markerType: "none",
          markerSize: 5,
          yValueFormatString: "###0.## kg",
          color: "#D3D3D3",
          dataPoints: transformAnimalsDailyWeightGain(),
        },
      ],
    };

    return (
      <Fragment>
        <Spin spinning={isLoading}>
          <span className="detailTitle">
            {translation.animal.details.dailyWeightGain.title}
            <Tooltip title="Curva de ganho de peso do animal de acordo com a Estratégia de Dieta. Compare com o Peso Estimado dos animais presentes no mesmo Lote e submetidos à mesma Estratégia de Dieta.">
              <InfoIcon />
            </Tooltip>
          </span>
          <div className="chartDiv">
            <CanvasJSChart
              options={options}
              onRef={(ref) => (this.chartRef = ref)}
            />
          </div>
        </Spin>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  animal: state.animal,
  curve: state.curve,
});

export default connect(mapStateToProps)(LineAverageDailyGainChart);
