import React, { useState, useEffect } from "react";
/** Redux libs */
import { useSelector } from "react-redux";
/** Components */
import { Container, Footer, Body } from "./styles";
import { Row, Col, Input } from "antd";
import ButtonStandard from "../../../../components/utils/button";
import NumberFormat from "react-number-format";

const EditCarbonFootprintParameter = ({ data, onSave, onCancel }) => {
  const { translation } = useSelector((state) => state.app);
  const [form, setForm] = useState(null);
  const [referenceValue, setReferenceValue] = useState(null);
  const [carbonCreditPrice, setCarbonCreditPrice] = useState(null);

  useEffect(() => {
    if (data != null) {
      setReferenceValue(data?.find((x) => x.code === 1006));
      setCarbonCreditPrice(data?.find((x) => x.code === 1007));
      setForm(data.map((d) => d));
    }
  }, [data]);

  useEffect(() => {
    if (form != null) {
      form[0] = referenceValue;
    }
  }, [form, referenceValue]);

  useEffect(() => {
    if (form != null) {
      form[1] = carbonCreditPrice;
    }
  }, [form, carbonCreditPrice]);

  return (
    <Container>
      {/* Body */}
      <Body type="flex">
        <Row type="flex" justify="start" gutter={8}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <span>Valor de Referência</span>
          </Col>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <NumberFormat
              name="referenceValue"
              customInput={Input}
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              allowNegative={false}
              addonAfter={
                <span>
                  kg CO<sub>2</sub>E / kg GDP
                </span>
              }
              value={referenceValue ? Number(referenceValue.value) : 0}
              onValueChange={({ floatValue }) => {
                setReferenceValue({
                  ...referenceValue,
                  value:
                    floatValue == null
                      ? 0
                      : floatValue > 0
                      ? floatValue > 999
                        ? 999
                        : floatValue
                      : 0,
                  updated: true,
                });
              }}
            />
          </Col>
        </Row>
        <Row type="flex" justify="start" gutter={8}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <span>Crédito de Carbono</span>
          </Col>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <NumberFormat
              name="carbonCreditPrice"
              disabled
              customInput={Input}
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              allowNegative={false}
              addonBefore="R$"
              addonAfter={
                <span>
                  kg CO<sub>2</sub>E
                </span>
              }
              value={carbonCreditPrice ? Number(carbonCreditPrice.value) : 0}
              onValueChange={({ floatValue }) => {
                setCarbonCreditPrice({
                  ...carbonCreditPrice,
                  value:
                    floatValue == null
                      ? 0
                      : floatValue > 0
                      ? floatValue > 999
                        ? 999
                        : floatValue
                      : 0,
                  updated: true,
                });
              }}
            />
          </Col>
        </Row>
      </Body>
      {/* Footer */}
      <Footer>
        <Row type="flex" justify="space-between">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="selectDiv"
          ></Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="buttonsDiv">
            <ButtonStandard
              type="button"
              buttonType="typeWithoutBackground"
              color="#d44c4c"
              isUpperCase={true}
              onClick={onCancel}
            >
              {translation.buttons.cancel}
            </ButtonStandard>

            <ButtonStandard
              type="submit"
              buttonType="typeWithoutBackground"
              color="#684E94"
              isUpperCase={true}
              onClick={() => onSave(form)}
            >
              {translation.buttons.save}
            </ButtonStandard>
          </Col>
        </Row>
      </Footer>
    </Container>
  );
};

export default EditCarbonFootprintParameter;
