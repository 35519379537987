import styled from "styled-components";
import { Select, Divider, Tabs } from "antd";

export const Container = styled.div`
  margin-top: 10px;
  background: #fff;
  padding: 24px 0;

  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  .ant-input-group-addon {
    min-width: 63px !important;
  }
  .ant-input-group-addon:first-child {
    min-width: 79px !important;
  }

  .ant-row-flex {
    padding-left: 5px !important;
    padding-right: 5px !important;

    flex-flow: nowrap;

    p {
      font-family: Asap;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: #4b4b4b;
    }
  }

  .title-purple {
    color: #9074bf;
  }

  .flex {
    margin-top: 6px;
    display: flex;
    align-items: center;
    flex-direction: row;
    vertical-align: middle;
    font-size: 13px;

    i {
      margin: 0px 8px 0px 5px;
    }
  }

  .card {
    margin-top: 10px;
    max-width: 140px;
    padding: 7px 14px;
    background: #f5f5f5;
    border-radius: 6px;
    text-align: center;

    span {
      margin-left: 0px;
    }
  }

  .title-span {
    display: flex;
    align-items: center;
    vertical-align: middle;
    gap: 0.5rem;
  }
`;

export const Table = styled.table`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100% !important;
  min-width: 100% !important;

  tbody {
    tr {
      &:not(:first-child) {
        border-top: 1px solid #e8e5e5;
      }
      &:last-child {
        border-bottom: 1px solid #e8e5e5;
      }
      td {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 3px;
        font-family: Asap;
        font-weight: normal;
        font-size: 12px;
        text-align: left;
        color: #4b4b4b;

        &:first-child {
          padding-left: 27px;
          font-size: 14px;
        }
        &:last-child {
          padding-right: 10px;
        }
      }
    }
  }
`;

export const CustomSelect = styled(Select)`
  &.ant-select.ant-select-enabled {
    .ant-select-selection {
      border: 1px solid #684e94 !important;
      color: #684e94 !important;
    }
    .ant-select-arrow {
      color: #684e94 !important;
    }
    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
      color: #684e94 !important;
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  border-top: 1px solid #e8e5e5;
  margin-top: 1rem;
  padding-top: 1rem;

  .buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .selectDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: "Asap", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;

export const CustomDivider = styled(Divider)`
  margin: 10px 0 10px 0 !important;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 8px 0 8px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;

export const TabContainer = styled(Tabs)`
  margin-bottom: 10px !important;

  .ant-tabs-ink-bar {
    background-color: #a9c133;
    height: 5px;
  }

  .ant-tabs-line {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab {
    font-family: "Asap" !important;
    font-size: 14px !important;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab-disabled,
  .ant-tabs-nav .ant-tabs-tab-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: #a9c133;
    font-weight: normal;
    svg {
      circle {
        fill: #a9c133 !important;
      }
    }
  }

  .ant-tabs-nav .ant-tabs-tab-active:hover {
    color: #a9c133;
    font-weight: normal;
    svg {
      circle {
        fill: #a9c133 !important;
      }
    }
  }

  .ant-tabs-nav {
    color: #a5a5a5;
  }

  .ant-tabs-nav:hover {
    color: #a5a5a5;
  }

  .ant-tabs-bar {
    border-bottom: 1px solid #e2e2e2;
  }

  .ant-tabs-tabpane {
    background-color: #fff;
    margin-top: -15px;
  }

  .title-span {
    display: flex;
    align-items: center;
    vertical-align: middle;

    div {
      padding-left: 8px;
    }
  }

  #bovexoExclamationIcon {
    width: 14px;
    height: 14px;

    circle {
      fill: #d44c4c;
    }
  }

  .checkIconBovexo {
    width: 14px;
    height: 14px;

    circle {
      fill: #33c162;
    }
  }
`;
