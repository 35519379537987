import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { AnimalListContextProvider } from "../../contexts/animalListContext";
import useAnimalListContext from "../../hooks/useAnimalListContext";
/* Redux libs */
import { useDispatch, useSelector } from "react-redux";
import { Creators as AnimalActions } from "../../store/ducks/animal";

/** Styles Components */
import {
  Container,
  DashboardActivity,
  GenerateReport,
  HeaderPanelContainer,
  PageTitle,
  Title,
} from "./styles";
/** Components */
import { Col, Icon, Row, Tabs, Tooltip } from "antd";
import AnimalIcon from "../../components/admin/sidebar/icons/animal";
import AlertButton from "../../components/utils/alertButton";
import AlertDatePicker from "../../components/utils/alertDatePicker";
import ButtonStandard from "../../components/utils/button";
import AlertDateIcon from "../../components/utils/icons/alertDate";

import AnimalFatten from "./fatten";
import AnimalReproduction from "./reproduction";
import MaleIcon from "../../components/utils/icons/male";
import FemaleIcon from "../../components/utils/icons/female";

// Services
import { getProfitCenterDashboard } from "../../services/profitCenterService";
import AnimalWeightRankingDash from "./dashboards/animalWeightRankingDash";
import AnimalWeightAndCountDash from "./dashboards/animalWeightAndCountDash";
import AnimalMortalityDash from "./dashboards/animalMortalityDash";

const AnimalLayout = () => {
  const {
    alerts,
    alertsFilterDate,
    getAlerts,
    handleAlertSelected,
    handleGenerateReport,
    handleCreateNewAnimal,
    handleCallBackDashboard,
  } = useAnimalListContext();

  const [tabSelect, setTabSelect] = useState("1");
  const { translation, alertSelected } = useSelector((state) => state.app);
  const [isDashboardVisible, setIsDashboardVisible] = useState(true);

  const [dashboardExtensive, setDashboardExtensive] = useState({
    amountAnimals: null,
    amountLots: null,
    aVGWeight: null,
    aVGAge: null,
    gmd: null,
  });
  const [dashboardIntensive, setDashboardIntensive] = useState({
    amountAnimals: null,
    amountLots: null,
    aVGWeight: null,
    aVGAge: null,
    gmd: null,
  });
  const [dashboardSemiIntensive, setDashboardSemiIntensive] = useState({
    amountAnimals: null,
    amountLots: null,
    aVGWeight: null,
    aVGAge: null,
    gmd: null,
  });

  const { numberFattenAnimals, numberReproductionAnimals } = useSelector(
    (state) => state.animal
  );
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const renderHeaderFattenPanel = useMemo(() => {
    let numberMales =
      numberFattenAnimals && numberFattenAnimals?.find((a) => a.answer === "M")
        ? numberFattenAnimals?.find((a) => a.answer === "M").count
        : 0;
    let numberFemales =
      numberFattenAnimals && numberFattenAnimals?.find((a) => a.answer === "F")
        ? numberFattenAnimals?.find((a) => a.answer === "F").count
        : 0;
    return (
      <HeaderPanelContainer>
        <span>Recria/Engorda</span>
        <div className="amountAnimals">
          <MaleIcon className="genderIcon" />
          <span>{numberMales}</span>
          <FemaleIcon className="genderIcon" />
          <span>{numberFemales}</span>
        </div>
      </HeaderPanelContainer>
    );
  }, [numberFattenAnimals]);

  const renderHeaderReproductionPanel = useMemo(() => {
    let numberMales =
      numberReproductionAnimals &&
      numberReproductionAnimals?.find((a) => a.answer === "M")
        ? numberReproductionAnimals?.find((a) => a.answer === "M").count
        : 0;
    let numberFemales =
      numberReproductionAnimals &&
      numberReproductionAnimals?.find((a) => a.answer === "F")
        ? numberReproductionAnimals?.find((a) => a.answer === "F").count
        : 0;
    return (
      <HeaderPanelContainer>
        <span>Reprodução</span>
        <div className="amountAnimals">
          <MaleIcon className="genderIcon" />
          <span>{numberMales}</span>
          <FemaleIcon className="genderIcon" />
          <span>{numberFemales}</span>
        </div>
      </HeaderPanelContainer>
    );
  }, [numberReproductionAnimals]);

  const handleTabClick = (key, e) => {
    setTabSelect(key);
  };

  useEffect(() => {
    const signal = axios.CancelToken.source();

    // Get extensive
    async function fetchExtensiveActivity() {
      try {
        const [
          amountAnimalsExtensive,
          amountLotsExtensive,
          aVGWeightExtensive,
          aVGAgeExtensive,
          gmdExtensive,
        ] = await Promise.all([
          getProfitCenterDashboard({
            groupId,
            farmId,
            productionSubSystem: "Extensive",
            dashboard: "AmountAnimals",
            signal: signal,
          }),
          getProfitCenterDashboard({
            groupId,
            farmId,
            productionSubSystem: "Extensive",
            dashboard: "AmountLots",
            signal: signal,
          }),
          getProfitCenterDashboard({
            groupId,
            farmId,
            productionSubSystem: "Extensive",
            dashboard: "AVGWeight",
            signal: signal,
          }),
          getProfitCenterDashboard({
            groupId,
            farmId,
            productionSubSystem: "Extensive",
            dashboard: "AVGAge",
            signal: signal,
          }),
          getProfitCenterDashboard({
            groupId,
            farmId,
            productionSubSystem: "Extensive",
            dashboard: "GMD",
            signal: signal,
          }),
        ]);
        setDashboardExtensive({
          amountAnimals: amountAnimalsExtensive.data.results,
          amountLots: amountLotsExtensive.data.results,
          aVGWeight: Number(aVGWeightExtensive.data.results).toFixed(2),
          aVGAge: Number(aVGAgeExtensive.data.results).toFixed(0),
          gmd: Number(gmdExtensive.data.results).toFixed(2),
        });
      } catch (error) {}
    }

    // Get semiIntensive
    async function fetchSemiIntesiveActivity() {
      try {
        const [
          amountAnimalsSemiIntensive,
          amountLotsSemiIntensive,
          aVGWeightSemiIntensive,
          aVGAgeSemiIntensive,
          gmdSemiIntensive,
        ] = await Promise.all([
          getProfitCenterDashboard({
            groupId,
            farmId,
            productionSubSystem: "SemiIntensive",
            dashboard: "AmountAnimals",
            signal: signal,
          }),
          getProfitCenterDashboard({
            groupId,
            farmId,
            productionSubSystem: "SemiIntensive",
            dashboard: "AmountLots",
            signal: signal,
          }),
          getProfitCenterDashboard({
            groupId,
            farmId,
            productionSubSystem: "SemiIntensive",
            dashboard: "AVGWeight",
            signal: signal,
          }),
          getProfitCenterDashboard({
            groupId,
            farmId,
            productionSubSystem: "SemiIntensive",
            dashboard: "AVGAge",
            signal: signal,
          }),
          getProfitCenterDashboard({
            groupId,
            farmId,
            productionSubSystem: "SemiIntensive",
            dashboard: "GMD",
            signal: signal,
          }),
        ]);
        setDashboardSemiIntensive({
          amountAnimals: amountAnimalsSemiIntensive.data.results,
          amountLots: amountLotsSemiIntensive.data.results,
          aVGWeight: Number(aVGWeightSemiIntensive.data.results).toFixed(2),
          aVGAge: Number(aVGAgeSemiIntensive.data.results).toFixed(0),
          gmd: Number(gmdSemiIntensive.data.results).toFixed(2),
        });
      } catch (error) {}
    }

    // Get intensive
    async function fetchIntensiveActivity() {
      try {
        const [
          amountAnimalsIntensive,
          amountLotsIntensive,
          aVGWeightIntensive,
          aVGAgeIntensive,
          gmdIntensive,
        ] = await Promise.all([
          getProfitCenterDashboard({
            groupId,
            farmId,
            productionSubSystem: "Intensive",
            dashboard: "AmountAnimals",
            signal: signal,
          }),
          getProfitCenterDashboard({
            groupId,
            farmId,
            productionSubSystem: "Intensive",
            dashboard: "AmountLots",
            signal: signal,
          }),
          getProfitCenterDashboard({
            groupId,
            farmId,
            productionSubSystem: "Intensive",
            dashboard: "AVGWeight",
            signal: signal,
          }),
          getProfitCenterDashboard({
            groupId,
            farmId,
            productionSubSystem: "Intensive",
            dashboard: "AVGAge",
            signal: signal,
          }),
          getProfitCenterDashboard({
            groupId,
            farmId,
            productionSubSystem: "Intensive",
            dashboard: "GMD",
            signal: signal,
          }),
        ]);
        setDashboardIntensive({
          amountAnimals: amountAnimalsIntensive.data.results,
          amountLots: amountLotsIntensive.data.results,
          aVGWeight: Number(aVGWeightIntensive.data.results).toFixed(2),
          aVGAge: Number(aVGAgeIntensive.data.results).toFixed(0),
          gmd: Number(gmdIntensive.data.results).toFixed(2),
        });
      } catch (error) {}
    }

    if (groupId && farmId) {
      fetchExtensiveActivity();
      fetchSemiIntesiveActivity();
      fetchIntensiveActivity();
    }

    return () => {
      signal.cancel();
    };
  }, [groupId, farmId]);

  useEffect(() => {
    if (groupId && farmId) {
      dispatch(AnimalActions.getNumberAnimals(groupId, farmId));
    }
  }, [groupId, farmId, dispatch]);

  return (
    <Container id="container-animal">
      {/* Header */}
      <Row type="flex">
        <Col span={24}>
          <PageTitle>
            <AnimalIcon />
            <span>{translation.sidebar.animal}</span>
          </PageTitle>
        </Col>
      </Row>
      {/* Dashboard */}
      <Row type="flex" justify="end">
        <Col>
          <ButtonStandard
            type="button"
            buttonType="secondary"
            onClick={() => setIsDashboardVisible((old) => !old)}
          >
            {isDashboardVisible ? (
              <>
                <Icon type="fullscreen-exit" /> Ocultar painéis
              </>
            ) : (
              <>
                <Icon type="fullscreen" /> Exibir painéis
              </>
            )}
          </ButtonStandard>
        </Col>
      </Row>
      {isDashboardVisible && (
        <>
          {/* Dashboard Animals - Start */}
          <Row type="flex" gutter={8}>
            <Col xs={24} sm={24} md={24} lg={12} xl={8}>
              <AnimalWeightAndCountDash />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={11}>
              <AnimalWeightRankingDash
                onCallBack={(search) => handleCallBackDashboard(search)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={5}>
              <AnimalMortalityDash />
            </Col>
          </Row>
          {/* Dashboard Animals - End */}
          {/* DASHSBOARDS Atividades - Start */}
          <Row type="flex" justify="start" gutter={8}>
            <Col xs={24} sm={16} md={12} lg={12} xl={8}>
              <DashboardActivity>
                <Row type="flex">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <span className="title">EXTENSIVO</span>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                    <span className="subtitle">
                      {dashboardExtensive.amountAnimals === null ? (
                        <Icon type="loading" spin />
                      ) : (
                        dashboardExtensive.amountAnimals
                      )}
                      {" Animais | "}
                      {dashboardExtensive.amountLots === null ? (
                        <Icon type="loading" spin />
                      ) : (
                        dashboardExtensive.amountLots
                      )}
                      {" Lotes"}
                    </span>
                  </Col>
                </Row>
                <Row gutter={48} style={{ marginTop: 20 }}>
                  <Col span={8}>
                    <Row>
                      <span>GMD</span>
                    </Row>
                    <Row>
                      <span className="value">
                        {dashboardExtensive.gmd === null ? (
                          <Icon type="loading" spin />
                        ) : (
                          `${dashboardExtensive.gmd} kg`
                        )}
                      </span>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row>
                      <span>Peso médio</span>
                    </Row>
                    <Row>
                      <span className="value">
                        {dashboardExtensive.aVGWeight === null ? (
                          <Icon type="loading" spin />
                        ) : (
                          `${dashboardExtensive.aVGWeight} kg`
                        )}
                      </span>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row>
                      <span>Idade média</span>
                    </Row>
                    <Row>
                      <span className="value">
                        {dashboardExtensive.aVGAge === null ? (
                          <Icon type="loading" spin />
                        ) : (
                          `${dashboardExtensive.aVGAge} meses`
                        )}
                      </span>
                    </Row>
                  </Col>
                </Row>
              </DashboardActivity>
            </Col>
            <Col xs={24} sm={16} md={12} lg={12} xl={8}>
              <DashboardActivity>
                <Row type="flex">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <span className="title">SEMI-INTENSIVO</span>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                    <span className="subtitle">
                      {dashboardSemiIntensive.amountAnimals === null ? (
                        <Icon type="loading" spin />
                      ) : (
                        dashboardSemiIntensive.amountAnimals
                      )}
                      {" Animais | "}
                      {dashboardSemiIntensive.amountLots === null ? (
                        <Icon type="loading" spin />
                      ) : (
                        dashboardSemiIntensive.amountLots
                      )}
                      {" Lotes"}
                    </span>
                  </Col>
                </Row>
                <Row gutter={48} style={{ marginTop: 20 }}>
                  <Col span={8}>
                    <Row>
                      <span>GMD</span>
                    </Row>
                    <Row>
                      <span className="value">
                        {dashboardSemiIntensive.gmd === null ? (
                          <Icon type="loading" spin />
                        ) : (
                          `${dashboardSemiIntensive.gmd} kg`
                        )}
                      </span>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row>
                      <span>Peso médio</span>
                    </Row>
                    <Row>
                      <span className="value">
                        {dashboardSemiIntensive.aVGWeight === null ? (
                          <Icon type="loading" spin />
                        ) : (
                          `${dashboardSemiIntensive.aVGWeight} kg`
                        )}
                      </span>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row>
                      <span>Idade média</span>
                    </Row>
                    <Row>
                      <span className="value">
                        {dashboardSemiIntensive.aVGAge === null ? (
                          <Icon type="loading" spin />
                        ) : (
                          `${dashboardSemiIntensive.aVGAge} meses`
                        )}
                      </span>
                    </Row>
                  </Col>
                </Row>
              </DashboardActivity>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
              <DashboardActivity>
                <Row type="flex">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <span className="title">INTENSIVO</span>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                    <span className="subtitle">
                      {dashboardIntensive.amountAnimals === null ? (
                        <Icon type="loading" spin />
                      ) : (
                        dashboardIntensive.amountAnimals
                      )}
                      {" Animais | "}
                      {dashboardIntensive.amountLots === null ? (
                        <Icon type="loading" spin />
                      ) : (
                        dashboardIntensive.amountLots
                      )}
                      {" Lotes"}
                    </span>
                  </Col>
                </Row>
                <Row gutter={48} style={{ marginTop: 20 }}>
                  <Col span={8}>
                    <Row>
                      <span>GMD</span>
                    </Row>
                    <Row>
                      <span className="value">
                        {dashboardIntensive.gmd === null ? (
                          <Icon type="loading" spin />
                        ) : (
                          `${dashboardIntensive.gmd} kg`
                        )}
                      </span>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row>
                      <span>Peso médio</span>
                    </Row>
                    <Row>
                      <span className="value">
                        {dashboardIntensive.aVGWeight === null ? (
                          <Icon type="loading" spin />
                        ) : (
                          `${dashboardIntensive.aVGWeight} kg`
                        )}
                      </span>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row>
                      <span>Idade média</span>
                    </Row>
                    <Row>
                      <span className="value">
                        {dashboardIntensive.aVGAge === null ? (
                          <Icon type="loading" spin />
                        ) : (
                          `${dashboardIntensive.aVGAge} meses`
                        )}
                      </span>
                    </Row>
                  </Col>
                </Row>
              </DashboardActivity>
            </Col>
          </Row>
          {/* DASHSBOARDS Atividades - End */}
        </>
      )}
      {/* Title & Common buttons */}
      <Row
        type="flex"
        justify="space-between"
        style={{ marginTop: 16, marginBottom: 16, paddingRight: 16 }}
      >
        <Col>
          <Title>{translation.animal.columns.title}</Title>
        </Col>
        <Col>
          <Tooltip
            title={
              "Selecione um ou vários animal(is) na tabela abaixo para gerar o relatório."
            }
            placement="top"
            trigger="hover"
          >
            <GenerateReport onClick={handleGenerateReport}>
              Gerar Relatório
            </GenerateReport>
          </Tooltip>
          <ButtonStandard buttonType="type8" onClick={handleCreateNewAnimal}>
            Novo animal
          </ButtonStandard>
        </Col>
      </Row>
      {/* Alerts */}
      <Row type="flex" justify="start" align="middle" gutter={16}>
        <Col span={24}>
          <Row type="flex" justify="start" align="middle" gutter={16}>
            <Col>
              <AlertDateIcon />
            </Col>
            <Col>
              <AlertDatePicker
                alertsFilterDate={alertsFilterDate}
                getAlerts={getAlerts}
              />
            </Col>
            {alerts?.length <= 0 ? (
              <Col>
                <span style={{ color: "#D44C4C" }}>
                  Nenhum alerta de animal nessa data
                </span>
              </Col>
            ) : (
              alerts?.map((alert) => (
                <Col key={alert.id} xs={24} sm={24} md={6} lg={4} xl={4}>
                  <AlertButton
                    alert={alert}
                    alertSelected={alertSelected}
                    handleAlertSelected={handleAlertSelected}
                  />
                </Col>
              ))
            )}
          </Row>
        </Col>
      </Row>
      {/* Tab - Fatten & Reproduction */}
      <Row type="flex">
        <Col span={24}>
          <Tabs
            type="card"
            defaultActiveKey="1"
            activeKey={tabSelect}
            onTabClick={handleTabClick}
          >
            <Tabs.TabPane tab={renderHeaderFattenPanel} key="1">
              <AnimalFatten />
            </Tabs.TabPane>
            <Tabs.TabPane tab={renderHeaderReproductionPanel} key="2">
              <AnimalReproduction />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

const Animal = () => {
  return (
    <AnimalListContextProvider>
      <AnimalLayout />
    </AnimalListContextProvider>
  );
};

export default Animal;
