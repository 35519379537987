import apiAnimals from "../config/api_animals";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiAnimals = axios.create({
//   baseURL: "http://localhost:8084",
// });

export function findAll(payload) {
  const { groupId, farmId } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/reproductive/protocols`,
    getHeaders(false, null)
  );
}

export function saveApplyReproductiveProtocol(payload) {
  const { groupId, farmId, body } = payload;
  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/apply/reproductive/protocol`,
    body,
    getHeaders(false, null)
  );
}

export function findAllAnimalsForApplyReproductiveProtocol(payload) {
  const {
    groupId,
    farmId,
    page,
    size,
    filters,
    sorter,
    withoutPagination,
    justIds,
    ids,
  } = payload;

  const baseUrl = `/bovexo/${groupId}/farms/${farmId}/apply/reproductive/protocol/animals/list`;

  if (justIds != null && justIds === true) {
    return apiAnimals.post(
      `${baseUrl}?justIds=true${
        filters !== "" && filters != null ? `&search=${filters}` : ""
      }`,
      null,
      getHeaders(false, null)
    );
  } else if (withoutPagination != null && withoutPagination === true) {
    if (ids != null) {
      return apiAnimals.post(
        `${baseUrl}?withoutPagination=true${
          filters ? `&search=${filters}` : ""
        }`,
        ids,
        getHeaders(false, null)
      );
    }
    return apiAnimals.post(
      `${baseUrl}?withoutPagination=true${filters ? `&search=${filters}` : ""}`,
      null,
      getHeaders(false, null)
    );
  } else {
    let field = null;
    let order = null;

    if (sorter) {
      let { field: fieldSorter, order: orderSorter } = sorter;

      field =
        fieldSorter === "handlingNumber"
          ? "handling_number"
          : fieldSorter === "lastDailyWeight"
          ? "last_daily_weight"
          : fieldSorter;

      order = orderSorter === "descend" ? "DESC" : "ASC";
    }

    return apiAnimals.post(
      `${baseUrl}?withoutPagination=false${page ? `&page=${page - 1}` : ""}
        ${
          sorter != null && Object.entries(sorter).length > 0
            ? `&sort=${field}&direction=${order}`
            : ""
        }
        ${size && `&size=${size}`}
        ${filters !== "" && filters != null ? `&search=${filters}` : ""}`,
      null,
      getHeaders(false, null)
    );
  }
}

export function deleteAnimalFarmReproductiveProtocol(payload) {
  const { groupId, farmId, id } = payload;
  return apiAnimals.delete(
    `/bovexo/${groupId}/farms/${farmId}/animals/reproductive/protocols/${id}`,
    getHeaders(false, null)
  );
}
