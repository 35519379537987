import React, { memo, useCallback, useEffect, useState } from "react";
import { Container } from "./styles";
import { useSelector } from "react-redux";
import {
  getAnimalReproductionAgePregnanciesDashboard,
  reprocessAnimalDashboard,
} from "../../../../../services/dashboards/dashboardAnimalService";
import { Icon, Spin } from "antd";
import ButtonStandard from "../../../../../components/utils/button";
import moment from "moment";

// import { Container } from './styles';

const AGES_RANGE = [
  {
    range: "96-1000",
    pregnanciesCount: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
    },
  },
  {
    range: "84-95",
    pregnanciesCount: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
    },
  },
  {
    range: "72-83",
    pregnanciesCount: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
    },
  },
  {
    range: "60-71",
    pregnanciesCount: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
    },
  },
  {
    range: "48-59",
    pregnanciesCount: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
    },
  },
  {
    range: "36-47",
    pregnanciesCount: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
    },
  },
  {
    range: "24-35",
    pregnanciesCount: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
    },
  },
  {
    range: "12-23",
    pregnanciesCount: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
    },
  },
];

const AnimalAgePregnanciesDash = () => {
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const [dashboardData, setDashboardData] = useState([...AGES_RANGE]);

  const renderTableDataColumn = useCallback(
    (data, endRed, endOrange, endYellow, endGreen) => {
      return Object.entries(data.pregnanciesCount).map((pregnancieRow) => {
        const color =
          Number(pregnancieRow[0]) <= endRed
            ? "red"
            : Number(pregnancieRow[0]) > endRed &&
              Number(pregnancieRow[0]) <= endOrange
            ? "orange"
            : Number(pregnancieRow[0]) > endOrange &&
              Number(pregnancieRow[0]) <= endYellow
            ? "yellow"
            : Number(pregnancieRow[0]) > endYellow &&
              Number(pregnancieRow[0]) <= endGreen
            ? "green"
            : "blank";
        const key = `${data.range}_${pregnancieRow[0]}`;
        return (
          <td key={key} className={color}>
            {pregnancieRow[1].toString()}
          </td>
        );
      });
    },
    []
  );

  const renderTableData = useCallback(() => {
    return dashboardData.map((data, indexRow) => {
      const min = `${data.range.split("-")[0]}`;
      const max = `${data.range.split("-")[1]}`;

      const endRed = 4 - indexRow;
      const endOrange = 5 - indexRow;
      const endYellow = 6 - indexRow;
      const endGreen = 8 - indexRow;

      return (
        <tr key={data.range}>
          <td>{min}</td>
          <td>{max === "1000" ? "-" : max}</td>
          {renderTableDataColumn(data, endRed, endOrange, endYellow, endGreen)}
        </tr>
      );
    });
  }, [dashboardData, renderTableDataColumn]);

  const handleRefresh = useCallback(async () => {
    if (groupId && farmId) {
      let dashDataList = [...AGES_RANGE];
      setIsLoadingInternal(true);
      try {
        await reprocessAnimalDashboard({
          groupId,
          farmId,
          dashboard: "AnimalReproductionAgePregnanciesDashboard",
        });
        const {
          data: { results },
        } = await getAnimalReproductionAgePregnanciesDashboard({
          groupId,
          farmId,
        });
        if (results) {
          const tableValues = results.values_table;
          if (tableValues && tableValues.length > 0) {
            for (let i = 0; i < tableValues.length; i++) {
              const currentTableValue = tableValues[i];
              const dataDashIndex = dashDataList.findIndex(
                (data) => data.range === currentTableValue.fieldRow
              );
              const dataDash = dashDataList[dataDashIndex];
              if (dataDash && dataDash.pregnanciesCount) {
                const dataDashUpdated = {
                  ...dataDash,
                  pregnanciesCount: {
                    ...dataDash.pregnanciesCount,
                    [currentTableValue.fieldColumn]: currentTableValue.dvalue,
                  },
                };
                dashDataList[dataDashIndex] = dataDashUpdated;
              }
            }
            setDashboardData(dashDataList);
          }
        } else {
          setDashboardData(AGES_RANGE);
        }
      } catch (error) {
        console.error(error);
        setDashboardData([...AGES_RANGE]);
        setIsLoadingInternal(false);
      } finally {
        setIsLoadingInternal(false);
      }
    }
  }, [farmId, groupId]);

  useEffect(() => {
    const fetchData = async () => {
      let dashDataList = [...AGES_RANGE];
      setIsLoadingInternal(true);
      try {
        const {
          data: { results },
        } = await getAnimalReproductionAgePregnanciesDashboard({
          groupId,
          farmId,
        });
        if (results) {
          const tableValues = results.values_table;
          if (tableValues && tableValues.length > 0) {
            for (let i = 0; i < tableValues.length; i++) {
              const currentTableValue = tableValues[i];
              const dataDashIndex = dashDataList.findIndex(
                (data) => data.range === currentTableValue.fieldRow
              );
              const dataDash = dashDataList[dataDashIndex];
              if (dataDash && dataDash.pregnanciesCount) {
                const dataDashUpdated = {
                  ...dataDash,
                  pregnanciesCount: {
                    ...dataDash.pregnanciesCount,
                    [currentTableValue.fieldColumn]: currentTableValue.dvalue,
                  },
                };
                dashDataList[dataDashIndex] = dataDashUpdated;
              }
            }
            setDashboardData(dashDataList);
          }
        } else {
          setDashboardData(AGES_RANGE);
        }
      } catch (error) {
        console.error(error);
        setDashboardData([...AGES_RANGE]);
        setIsLoadingInternal(false);
      } finally {
        setIsLoadingInternal(false);
      }
    };
    if (groupId && farmId) fetchData();
    return () => {
      setDashboardData([...AGES_RANGE]);
    };
  }, [groupId, farmId]);

  return (
    <Spin spinning={isLoadingInternal}>
      <Container>
        <thead>
          <tr>
            <th
              style={{ textAlign: "right", alignContent: "center" }}
              colSpan={11}
            >
              <span className="header">{`Dados referentes à ${moment().format(
                "DD/MM/YYYY"
              )}`}</span>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={handleRefresh}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </th>
          </tr>
          <tr>
            <th colSpan={2}>Idade(meses)</th>
            <th colSpan={9}>
              <h2>Idade X Gestações</h2>
            </th>
          </tr>
        </thead>
        <tbody>{renderTableData()}</tbody>
        <tfoot>
          <tr>
            <td colSpan={2}>#Gestações</td>
            <td>0</td>
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>4</td>
            <td>5</td>
            <td>6</td>
            <td>7</td>
            <td>8</td>
          </tr>
        </tfoot>
      </Container>
    </Spin>
  );
};

export default memo(AnimalAgePregnanciesDash);
