import apiAnimals from "../config/api_animals";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiAnimals = axios.create({
//   baseURL: "http://localhost:8084",
// });

export function findAll(payload) {
  const { groupId, farmId } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/semens`,
    getHeaders(false, null)
  );
}

export function findById(payload) {
  const { groupId, farmId, id } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/semens/${id}`,
    getHeaders(false, null)
  );
}

export function save(payload) {
  const { groupId, farmId, id, body } = payload;
  if (id === null) {
    return apiAnimals.post(
      `/bovexo/${groupId}/farms/${farmId}/semens`,
      body,
      getHeaders(false, null)
    );
  } else {
    return apiAnimals.patch(
      `/bovexo/${groupId}/farms/${farmId}/semens/${id}`,
      body,
      getHeaders(false, null)
    );
  }
}
