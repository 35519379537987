import React from 'react';

// import { Container } from './styles';

const FemaleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.588"
    height="15.826"
    viewBox="0 0 10.588 15.826"
  >
    <g id="Group_543" data-name="Group 543" transform="translate(-2.69 -0.114)">
      <path
        id="Caminho_913"
        data-name="Caminho 913"
        d="M4.239,1.664a5.3,5.3,0,1,1,0,7.49A5.295,5.295,0,0,1,4.239,1.664Zm6.088,6.088a3.315,3.315,0,1,0-4.688,0h0a3.319,3.319,0,0,0,4.689,0Z"
        transform="translate(0 0)"
        fill="#ff065b"
      />
      <rect
        id="Retângulo_237"
        data-name="Retângulo 237"
        width="5.398"
        height="1.98"
        transform="translate(5.285 12.078)"
        fill="#ff065b"
      />
      <rect
        id="Retângulo_237-2"
        data-name="Retângulo 237"
        width="1.98"
        height="6.228"
        transform="translate(6.994 9.712)"
        fill="#ff065b"
      />
    </g>
  </svg>
);
export default FemaleIcon;
