import { call, put } from "redux-saga/effects";
import { Creators as MarketAnalysisActions } from "../ducks/marketAnalysis";
/** Services */
import {
  getMarketAnalysisIndex,
  getMarketAnalysisShow,
  saveMarketAnalysis,
  deleteMarketAnalysisServ,
  reactivateMarketAnalysisServ,
} from "../../services/marketAnalysisService";
import { notification } from "antd";

export function* indexMarketAnalysis(action) {
  try {
    const {
      data: { results: allMarketAnalysis },
    } = yield call(getMarketAnalysisIndex, action.payload);
    yield put(
      MarketAnalysisActions.indexMarketAnalysisSuccess(allMarketAnalysis)
    );
  } catch (error) {
    yield put(MarketAnalysisActions.indexMarketAnalysisError(error));
  }
}

export function* getMarketAnalysis(action) {
  try {
    const {
      data: { results: marketAnalysis },
    } = yield call(getMarketAnalysisShow, action.payload);
    let futurePriceData = {
      status: "I",
    };
    yield put(
      MarketAnalysisActions.showMarketAnalysisSuccess(
        marketAnalysis,
        futurePriceData
      )
    );
  } catch (error) {
    yield put(MarketAnalysisActions.showMarketAnalysisError(error));
  }
}

export function* storeOrUpdateMarketAnalysis(action) {
  let result = null;
  try {
    const {
      data: { results: newMarketAnalysis },
    } = yield call(saveMarketAnalysis, action.payload);
    result = newMarketAnalysis;
    const { saveOptions } = action.payload;
    yield put(
      MarketAnalysisActions.saveMarketAnalysisSuccess(
        newMarketAnalysis,
        saveOptions
      )
    );
    if (action.payload.id == null)
      notification.success({ message: "Curva de Preços criada com sucesso" });
    else
      notification.success({
        message: "Curva de Preços atualizada com sucesso",
      });
  } catch (error) {
    notification.error({ message: "Curva de Preços não foi criada" });
    yield put(MarketAnalysisActions.saveMarketAnalysisError(error));
  }

  if (result != null && Object.entries(result).length > 0) {
    const newPayload = {
      ...action.payload,
      page: 1,
      size: 10,
      sorter: {},
    };

    const {
      data: { results: allMarketAnalysis },
    } = yield call(getMarketAnalysisIndex, newPayload);
    yield put(
      MarketAnalysisActions.indexMarketAnalysisSuccess(allMarketAnalysis)
    );
  }
}

export function* deleteMarketAnalysis(action) {
  try {
    const { data } = yield call(deleteMarketAnalysisServ, action.payload);
    yield put(MarketAnalysisActions.deleteMarketAnalysisSuccess(data));
    yield put(
      MarketAnalysisActions.indexMarketAnalysis(
        action.payload.groupId,
        action.payload.farmId
      )
    );
    notification.success({
      message: "Curva de Preços inativada com sucesso",
    });
  } catch (error) {
    notification.error({ message: "Curva de Preços não foi inativada" });
    yield put(MarketAnalysisActions.deleteMarketAnalysisError(error));
  }
}

export function* activateMarketAnalysis(action) {
  try {
    const { data } = yield call(reactivateMarketAnalysisServ, action.payload);
    yield put(MarketAnalysisActions.activateMarketAnalysisSuccess(data));
    yield put(
      MarketAnalysisActions.indexMarketAnalysis(
        action.payload.groupId,
        action.payload.farmId
      )
    );
    notification.success({
      message: "Curva de Preços ativada com sucesso.",
    });
  } catch (error) {
    notification.error({ message: "Curva de Preços não foi ativada." });
    yield put(MarketAnalysisActions.activateMarketAnalysisError(error));
  }
}
