import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as ProfitCenterActions } from "../../../store/ducks/profitCenter";
import * as Yup from "yup";
import { Formik } from "formik";
import { Row, Col, Input, Select, Switch, Spin } from "antd";
import { Container, Footer } from "./styles";
// Components
import ButtonStandard from "../../utils/button";
// Services
import { getTypologyItemIndex } from "../../../services/typologyItemService";

class DrawerProfitCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formProfitCenter: {
        id: null,
        name: "",
        productionSystemId: null,
        productionSystemName: "",
        productionSubSystem: null,
        status: "Active",
      },
      types: [],
      saveOptions: "only_save",
    };
  }

  fetchData = async (groupSelected, farmSelected) => {
    const {
      profitCenter: { idProfitCenter },
      profitCenterActions,
    } = this.props;
    if (groupSelected != null) {
      const { id: groupId } = groupSelected;
      const { id: farmId } = farmSelected;

      if (idProfitCenter != null) {
        profitCenterActions.showProfitCenter(groupId, farmId, idProfitCenter);
      }

      const {
        data: { results },
      } = await getTypologyItemIndex({
        groupId,
        typologyName: "Sistema Produtivo",
      });
      let items = [];
      if (results != null) {
        results.forEach((element) => {
          items = [...items, ...element.items];
        });
        this.setState({
          types: items,
        });
      }
    }
  };

  closeDrawer = () => {
    const { profitCenterActions } = this.props;
    profitCenterActions.hideDrawer();
  };

  handleSubmitModalForm = async (values, actions) => {
    actions.resetForm({
      id: null,
      name: "",
      productionSystemId: null,
      productionSystemName: "",
      productionSubSystem: null,
      status: "Active",
    });
    this.setState({
      formProfitCenter: {
        id: null,
        name: "",
        productionSystemId: null,
        productionSystemName: "",
        productionSubSystem: null,
        status: "Active",
      },
    });
    if (values.id === null) {
      await this.handleNewProfitCenter(values);
    } else {
      await this.handleUpdateProfitCenter(values, values.id);
    }
  };

  handleNewProfitCenter = async (profitCenter) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      profitCenterActions,
    } = this.props;
    let saveOptions = this.state.saveOptions === "only_save" ? false : true;
    await profitCenterActions.saveProfitCenter(
      groupId,
      farmId,
      null,
      profitCenter,
      saveOptions
    );
    this.setState({
      saveOptions: "only_save",
    });
  };

  handleUpdateProfitCenter = async (profitCenter, id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      profitCenterActions,
    } = this.props;
    let saveOptions = this.state.saveOptions === "only_save" ? false : true;
    await profitCenterActions.saveProfitCenter(
      groupId,
      farmId,
      id,
      profitCenter,
      saveOptions
    );
    this.setState({
      saveOptions: "only_save",
    });
  };

  handleOpenDrawer = async (visible) => {
    if (visible) {
      const {
        app: { groupSelected, farmSelected },
      } = this.props;
      await this.fetchData(groupSelected, farmSelected);
    }
  };

  render() {
    const {
      app: { translation },
      profitCenter: { profitCenterData, drawerVisible, isLoadingRequest },
    } = this.props;

    const { formProfitCenter, types } = this.state;

    const validationSchema = Yup.object().shape({
      name: Yup.string().required(),
      productionSystemId: Yup.string().required(),
      productionSubSystem: Yup.string().required(),
    });

    const { Option } = Select;

    const productionSubSystems = [
      {
        code: "Extensive",
        name: translation.productionSubSystem.extensive,
      },
      {
        code: "Intensive",
        name: translation.productionSubSystem.intensive,
      },
      {
        code: "SemiIntensive",
        name: translation.productionSubSystem.semiIntensive,
      },
    ];

    return (
      <Container
        title={
          profitCenterData != null
            ? translation.profitCenter.drawerTitleEdit
            : translation.profitCenter.drawerTitle
        }
        width={511}
        onClose={this.closeDrawer}
        maskClosable={false}
        visible={drawerVisible}
        afterVisibleChange={this.handleOpenDrawer}
      >
        <Formik
          enableReinitialize={true}
          initialValues={
            profitCenterData != null ? profitCenterData : formProfitCenter
          }
          onSubmit={this.handleSubmitModalForm}
          validationSchema={validationSchema}
          render={(props) => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <Spin spinning={isLoadingRequest}>
                {/* Body */}
                <div className="drawerForm">
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <span style={{ fontSize: "14px", color: "#000" }}>
                        {profitCenterData != null
                          ? translation.profitCenter.drawerSubTitleEdit
                          : translation.profitCenter.drawerSubTitle}
                      </span>
                    </Col>
                  </Row>
                  {profitCenterData != null ? (
                    <Row
                      type="flex"
                      style={{ marginTop: "13px" }}
                      justify="space-between"
                      align="middle"
                    >
                      <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                        <label
                          style={{
                            color: "#9074bf",
                            fontWeight: "bold",
                          }}
                        >
                          {translation.profitCenter.form.status}
                        </label>
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6} align="right">
                        {props.values.status === "Active" ? (
                          <span
                            style={{
                              color: "#A9C133",
                              fontFamily: "Asap",
                              fontWeight: "normal",
                              marginRight: 6,
                            }}
                          >
                            {translation.status.active}
                          </span>
                        ) : (
                          <span
                            style={{
                              color: "#D44C4C",
                              fontFamily: "Asap",
                              fontWeight: "normal",
                              marginRight: 6,
                            }}
                          >
                            {translation.status.inactive}
                          </span>
                        )}
                        <Switch
                          checked={
                            props.values.status === "Active" ? true : false
                          }
                          onChange={(checked, event) => {
                            props.setValues({
                              ...props.values,
                              status: checked ? "Active" : "Inactive",
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  ) : null}
                  {Object.entries(props.errors).length > 0 &&
                    props.submitCount > 0 && (
                      <Row type="flex" justify="center" align="middle">
                        <label className="error">
                          {translation.error.formError}
                        </label>
                      </Row>
                    )}
                  {/* Input system identification */}
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.name && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.profitCenter.form.name}*
                        </label>
                      </Row>
                      <Row>
                        <Input
                          placeholder={
                            translation.profitCenter.form.namePlaceHolder
                          }
                          value={props.values.name}
                          name="name"
                          autoComplete="chrome-off"
                          onChange={(e) => {
                            props.setFieldValue("name", e.target.value);
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.productionSystemId &&
                            props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.profitCenter.form.type}*
                        </label>
                      </Row>
                      <Row>
                        <Select
                          style={{ width: "100%" }}
                          name="productionSystemId"
                          value={
                            props.values.productionSystemId == null
                              ? undefined
                              : props.values.productionSystemId
                          }
                          placeholder={
                            translation.profitCenter.form.typePlaceHolder
                          }
                          onChange={(value) => {
                            props.setFieldValue("productionSystemId", value);
                            props.setFieldValue(
                              "productionSystemName",
                              types.filter((type) => type.id === value)[0].name
                            );
                          }}
                        >
                          {Object.entries(types).length > 0 &&
                            types.map((type) => (
                              <Option key={type.id} value={type.id}>
                                {type.name}
                              </Option>
                            ))}
                        </Select>
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.productionSubSystem &&
                            props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.profitCenter.form.productionSubSystem}*
                        </label>
                      </Row>
                      <Row>
                        <Select
                          style={{ width: "100%" }}
                          name="productionSubSystem"
                          value={
                            props.values.productionSubSystem == null
                              ? undefined
                              : props.values.productionSubSystem
                          }
                          placeholder={
                            translation.profitCenter.form
                              .productionSubSystemPlaceHolder
                          }
                          onChange={(value) => {
                            props.setFieldValue("productionSubSystem", value);
                          }}
                        >
                          {Object.entries(productionSubSystems).length > 0 &&
                            productionSubSystems.map((pss, i) => (
                              <Option key={i} value={pss.code}>
                                {pss.name}
                              </Option>
                            ))}
                        </Select>
                      </Row>
                    </Col>
                  </Row>
                </div>
                {/* Footer */}
                <Footer>
                  <Row type="flex" justify="space-between">
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="selectDiv"
                    >
                      {props.values.id === null ? (
                        <Select
                          value={this.state.saveOptions}
                          name="saveOptions"
                          onChange={(value) =>
                            this.setState({ saveOptions: value })
                          }
                        >
                          <Option key="1" value="only_save">
                            {translation.profitCenter.saveOptions.only_save}
                          </Option>
                          <Option key="2 " value="create_new">
                            {
                              translation.profitCenter.saveOptions
                                .save_and_create_new
                            }
                          </Option>
                        </Select>
                      ) : null}
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="buttonsDiv"
                    >
                      <ButtonStandard
                        type="button"
                        buttonType="type7"
                        onClick={this.closeDrawer}
                      >
                        {translation.buttons.cancel}
                      </ButtonStandard>

                      <ButtonStandard buttonType="type6" type="submit">
                        {translation.buttons.save}
                      </ButtonStandard>
                    </Col>
                  </Row>
                </Footer>
              </Spin>
            </form>
          )}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  profitCenter: state.profitCenter,
});

const mapDispatchToProps = (dispatch) => ({
  profitCenterActions: bindActionCreators(ProfitCenterActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerProfitCenter);
