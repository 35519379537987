import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useSelector } from "react-redux";

//Libs
import moment from "moment";

// Components
import { Row, Col, Table, Menu, Dropdown, notification } from "antd";
import TagStatus from "../../components/utils/tagStatus";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import MenuIcon from "../../components/utils/table/icons/menu";
import AllowedComponentTo from "../../components/utils/allowedComponentTo";

// Styles
import { Title, CardCustom } from "./styles";

//Services
import { getBoitelPricesIndex } from "../../services/boitelPriceService";

const BoitelPrice = (props) => {
  // Variable Redux
  const {
    translation,
    user: { id: userId },
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const Column = Table.Column;
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const dateFormat = "DD/MM/YYYY";

  useEffect(() => {
    async function loadData() {
      try {
        const {
          data: { results },
        } = await getBoitelPricesIndex({ groupId, farmId });

        setData(results);
        setIsLoading(false);
      } catch (error) {
        notification.error({
          message:
            "Ocorreu um erro ao acessar as informações de preços dos boiteis.",
        });
      }
    }

    loadData();
  }, [groupId, farmId, userId]);

  const menu = (record) => {
    return (
      <Menu>
        <Menu.Item key="0">
          <Link to={`/admin/boitel/prices/show/${record.idBoitelPrice}`}>
            Visualizar
          </Link>
        </Menu.Item>
        <Menu.Item key="1">
          <Link to={`/admin/boitel/prices/edit/${record.idBoitelPrice}`}>
            Editar
          </Link>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <CardCustom>
      <Row type="flex" justify="space-between" className="rowHeader">
        <Col>
          <Title>{translation.boitelPrice.table.title}</Title>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            loading={isLoading}
            rowKey="idBoitelPrice"
            size="small"
            dataSource={data != null ? data.content : []}
            pagination={{
              total: data != null ? data.totalElements : 0,
              size: data != null ? data.size : 0,
              current: data != null ? data.number + 1 : 0,
              hideOnSinglePage:
                data !== null && Object.entries(data).length !== 0
                  ? data.totalElements > 10
                    ? false
                    : true
                  : true,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
            }}
            scroll={{ x: true }}
            style={{ width: "100%", margin: "0" }}
            // onChange={this.handleTableChange}
          >
            <Column
              title={translation.boitelPrice.table.columns.status}
              dataIndex="status"
              key="status"
              align="left"
              sorter
              sortDirections={["descend", "ascend"]}
              render={(status) =>
                status === "Active" ? (
                  <TagStatus
                    background="#C5F1CA"
                    borderColor="#106518"
                    color="#106518"
                  >
                    {translation.status.active}
                  </TagStatus>
                ) : (
                  <TagStatus
                    background="#FBC7C7"
                    borderColor="#D44C4C"
                    color="#D44C4C"
                  >
                    {translation.status.inactive}
                  </TagStatus>
                )
              }
            />
            <Column
              title={translation.boitelPrice.table.columns.name}
              dataIndex="name"
              key="name"
              align="left"
              sorter
              sortDirections={["descend", "ascend"]}
              render={(name) => <span>{name}</span>}
            />
            <Column
              title={translation.boitelPrice.table.columns.validity}
              dataIndex="validity"
              key="validity"
              align="left"
              render={(value, record) => {
                if (record.initialValidity) {
                  return (
                    <span>
                      {moment(record?.initialValidity).format(dateFormat)} à{" "}
                      {moment(record?.finalValidity).format(dateFormat)}
                    </span>
                  );
                } else {
                  return <span>Vigência não informada</span>;
                }
              }}
            />
            <Column
              align="left"
              render={(text, record) => {
                return (
                  <Dropdown
                    overlay={menu(record)}
                    trigger={["click"]}
                    key={record.id}
                  >
                    <MenuContainer>
                      <MenuIcon />
                    </MenuContainer>
                  </Dropdown>
                );
              }}
            />
          </Table>
        </Col>
      </Row>
    </CardCustom>
  );
};

export default withRouter(BoitelPrice);
