import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as BreedActions } from "../../../store/ducks/breed";
import { Creators as LotActions } from "../../../store/ducks/lot";

import moment from "moment";
import "moment/locale/pt-br";
import "moment/locale/en-nz";
import "moment/locale/es";

import * as Yup from "yup";
import { Formik } from "formik";
import { Drawer, Row, Col, Select, DatePicker, Input } from "antd";
import ButtonStandard from "../../utils/button";
import { Container, Footer } from "./styles";
import NumberFormat from "react-number-format";

class NewLotItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLotItem: {
        id: null,
        breedId: null,
        breedName: null,
        gender: null,
        birthday: null,
        estimatedWeight: "",
        amount: "",
        purchaseCost: "",
        numbersArray: [],
        numbers: "",
        numbersInserted: 0,
      },
      saveOptions: "only_save",
    };

    this.formikRef = React.createRef();
  }

  fetchData = async (
    groupSelected,
    farmSelected,
    page = 0,
    sorter = {},
    filters = ""
  ) => {
    const { breedActions } = this.props;
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    await breedActions.indexBreedActive(groupId, farmId);
  };

  closeDrawer = () => {
    const { lotActions } = this.props;
    this.formikRef.current.resetForm({
      id: null,
      breedId: null,
      breedName: null,
      gender: null,
      birthday: null,
      estimatedWeight: "",
      amount: "",
      purchaseCost: "",
      numbersArray: [],
      numbers: "",
      numbersInserted: 0,
    });
    this.setState({
      formLotItem: {
        id: null,
        breedId: null,
        breedName: null,
        gender: null,
        birthday: null,
        estimatedWeight: null,
        amount: null,
        purchaseCost: null,
        numbersArray: [],
        numbers: "",
        numbersInserted: 0,
      },
    });
    lotActions.hideLotItemReceipt();
  };

  handleSubmitModalForm = async (values, actions) => {
    actions.resetForm({
      id: null,
      breedId: null,
      breedName: null,
      gender: null,
      birthday: null,
      estimatedWeight: "",
      amount: "",
      purchaseCost: "",
      numbersArray: [],
      numbers: "",
      numbersInserted: 0,
    });
    this.setState({
      formLotItem: {
        id: null,
        breedId: null,
        breedName: null,
        gender: null,
        birthday: null,
        estimatedWeight: "",
        amount: "",
        purchaseCost: "",
        numbersArray: [],
        numbers: "",
        numbersInserted: 0,
      },
    });
    if (values.id === null) {
      values.id = Math.random();
      await this.handleAddNewLotItem(values);
    } else {
      await this.handleUpdateLotItem(values);
    }
  };

  handleAddNewLotItem = async (lotItem) => {
    const { lotActions } = this.props;
    await lotActions.addNewLotReceiptItem(lotItem, false);
  };

  handleUpdateLotItem = async (lotItem) => {
    const { lotActions } = this.props;
    await lotActions.updateLotItem(lotItem);
  };

  handleDrawerVisible = async (visible) => {
    if (visible) {
      const {
        app: { groupSelected, farmSelected },
      } = this.props;
      if (
        Object.entries(groupSelected).length > 0 &&
        Object.entries(farmSelected).length > 0
      ) {
        await this.fetchData(groupSelected, farmSelected);
      }
    }
  };

  render() {
    const {
      app: { translation },
      lot: { drawerLotItemReceiptVisible, lotItem },
      breed: { data: breeds },
    } = this.props;

    const validationSchema = Yup.object().shape({
      breedId: Yup.string().required(),
      gender: Yup.string().required(),
      birthday: Yup.date().required().max(new Date()),
      estimatedWeight: Yup.number().positive().required(),
      amount: Yup.number().positive().required(),
      purchaseCost: Yup.number().positive().required(),
    });

    const { Option } = Select;

    const { Group: InputGroup } = Input;

    const dateFormat = "DD/MM/YYYY";

    return (
      <Drawer
        title={translation.lot.receipt.finalize.newLotItem.title}
        width={550}
        onClose={this.closeDrawer}
        maskClosable={false}
        visible={drawerLotItemReceiptVisible}
        afterVisibleChange={this.handleDrawerVisible}
      >
        <Container>
          <Formik
            ref={this.formikRef}
            enableReinitialize={true}
            initialValues={lotItem}
            onSubmit={this.handleSubmitModalForm}
            validationSchema={validationSchema}
            render={(props) => (
              <form onSubmit={props.handleSubmit} autoComplete="off">
                <div id="drawerForm">
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <span style={{ fontSize: "14px", color: "#000" }}>
                        {
                          translation.lot.receipt.finalize.newLotItem
                            .description
                        }
                        :
                      </span>
                    </Col>
                  </Row>
                  {Object.entries(props.errors).length > 0 &&
                    props.submitCount > 0 && (
                      <Row type="flex" justify="center" align="middle">
                        <label className="error">
                          {translation.error.formError}
                        </label>
                      </Row>
                    )}
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.breedId && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.lot.receipt.finalize.newLotItem.breed}*
                        </label>
                      </Row>
                      <Row>
                        <Select
                          style={{ width: "100%" }}
                          name="breedId"
                          value={
                            props.values.breedId != null
                              ? props.values.breedId
                              : undefined
                          }
                          placeholder={
                            translation.lot.receipt.finalize.newLotItem.breed
                          }
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => {
                            props.setValues({
                              ...props.values,
                              breedId: value,
                              breedName: breeds.filter(
                                (breed) => breed.id === value
                              )[0].name,
                            });
                          }}
                        >
                          {Object.entries(breeds).length > 0 &&
                            breeds.map((breed) => (
                              <Option key={breed.id} value={breed.id}>
                                {breed.name}
                              </Option>
                            ))}
                        </Select>
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.gender && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.lot.receipt.finalize.newLotItem.gender}*
                        </label>
                      </Row>
                      <Row>
                        <Select
                          style={{ width: "100%" }}
                          name="gender"
                          value={
                            props.values.gender == null
                              ? undefined
                              : props.values.gender
                          }
                          placeholder={translation.defaultSelectPlaceholder}
                          onChange={(value) => {
                            props.setValues({ ...props.values, gender: value });
                          }}
                        >
                          <Option key="M" value="M">
                            {translation.animal.details.male}
                          </Option>
                          <Option key="F" value="F">
                            {translation.animal.details.female}
                          </Option>
                        </Select>
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Row>
                        <label
                          className={
                            props.errors.birthday && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.lot.receipt.finalize.newLotItem.birthday}
                          *
                        </label>
                      </Row>
                      <Row>
                        <DatePicker
                          style={{ width: "100%" }}
                          allowClear={false}
                          placeholder={
                            translation.lot.receipt.finalize.newLotItem
                              .birthdayPlaceHolder
                          }
                          value={
                            props.values.birthday != null
                              ? moment(props.values.birthday, "YYYY-MM-DD")
                              : null
                          }
                          format={dateFormat}
                          name="birthday"
                          onChange={(dateMoment, dateString) => {
                            if (dateMoment <= moment() && dateMoment !== null) {
                              props.setValues({
                                ...props.values,
                                birthday: dateMoment,
                              });
                            }
                          }}
                        />
                      </Row>
                    </Col>
                    <Col xs={0} sm={0} md={2} lg={2} xl={2}>
                      <Row type="flex" align="middle" justify="center">
                        <span>-</span>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                      <Row>
                        <label>Idade (dias/meses/anos)</label>
                      </Row>
                      <Row>
                        <Col>
                          <InputGroup compact>
                            <Input
                              type="number"
                              style={{ width: 80, textAlign: "center" }}
                              name="ageDay"
                              min="0"
                              placeholder="Dias"
                              value={
                                props.values.birthday != null
                                  ? moment().diff(props.values.birthday, "days")
                                  : null
                              }
                              onChange={(e) => {
                                props.setValues({
                                  ...props.values,
                                  birthday: moment().subtract(
                                    e.target.value > 5478
                                      ? 5478
                                      : e.target.value,
                                    "days"
                                  ),
                                });
                              }}
                            />
                            <Input
                              style={{
                                width: 80,
                                textAlign: "center",
                                borderLeft: "1px solid #c4c4c4",
                              }}
                              type="number"
                              name="ageMonth"
                              min="0"
                              placeholder="Meses"
                              value={
                                props.values.birthday != null
                                  ? moment().diff(
                                      props.values.birthday,
                                      "months"
                                    )
                                  : null
                              }
                              onChange={(e) => {
                                props.setValues({
                                  ...props.values,
                                  birthday: moment().subtract(
                                    e.target.value > 180 ? 180 : e.target.value,
                                    "months"
                                  ),
                                });
                              }}
                            />
                            <Input
                              style={{
                                width: 80,
                                textAlign: "center",
                                borderLeft: "1px solid #c4c4c4",
                              }}
                              type="number"
                              name="ageYear"
                              min="0"
                              placeholder="Anos"
                              value={
                                props.values.birthday != null
                                  ? moment().diff(
                                      props.values.birthday,
                                      "years",
                                      false
                                    )
                                  : null
                              }
                              onChange={(e) => {
                                props.setValues({
                                  ...props.values,
                                  birthday: moment().subtract(
                                    e.target.value > 15 ? 15 : e.target.value,
                                    "years"
                                  ),
                                });
                              }}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.estimatedWeight &&
                            props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {
                            translation.lot.receipt.finalize.newLotItem
                              .estimatedWeight
                          }
                          *
                        </label>
                      </Row>
                      <Row>
                        <NumberFormat
                          customInput={Input}
                          placeholder={
                            translation.lot.receipt.finalize.newLotItem
                              .estimatedWeightPlaceHolder
                          }
                          value={
                            props.values.estimatedWeight
                              ? props.values.estimatedWeight
                              : ""
                          }
                          addonAfter="Kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          name="estimatedWeight"
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              estimatedWeight: floatValue
                                ? floatValue < 1000
                                  ? floatValue
                                  : 1000
                                : null,
                            });
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.amount && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.lot.receipt.finalize.newLotItem.amount}*
                        </label>
                      </Row>
                      <Row>
                        <NumberFormat
                          customInput={Input}
                          placeholder={
                            translation.lot.receipt.finalize.newLotItem
                              .amoutPlaceHolder
                          }
                          value={props.values.amount ? props.values.amount : ""}
                          name="amount"
                          min={0}
                          decimalScale={0}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              amount: floatValue
                                ? floatValue > 1000
                                  ? 1000
                                  : floatValue
                                : null,
                            });
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.purchaseCost && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {
                            translation.lot.receipt.finalize.newLotItem
                              .purchaseCost
                          }
                          *
                        </label>
                      </Row>
                      <Row>
                        <NumberFormat
                          customInput={Input}
                          placeholder={
                            translation.lot.receipt.finalize.newLotItem
                              .purchaseCostPlaceHolder
                          }
                          value={
                            props.values.purchaseCost
                              ? props.values.purchaseCost
                              : ""
                          }
                          name="purchaseCost"
                          min={0}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          addonBefore="R$"
                          style={{ width: "100%" }}
                          onValueChange={({ floatValue }) => {
                            props.setValues({
                              ...props.values,
                              purchaseCost: floatValue
                                ? floatValue > 99999
                                  ? 99999
                                  : floatValue
                                : null,
                            });
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                </div>
                {/* Footer */}
                <Footer>
                  <Row type="flex" justify="space-between">
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="selectDiv"
                    />
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="buttonsDiv"
                    >
                      <ButtonStandard
                        type="button"
                        buttonType="type7"
                        onClick={this.closeDrawer}
                      >
                        {translation.buttons.cancel}
                      </ButtonStandard>

                      <ButtonStandard type="submit" buttonType="type6">
                        {translation.buttons.save}
                      </ButtonStandard>
                    </Col>
                  </Row>
                </Footer>
              </form>
            )}
          />
        </Container>
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  lot: state.lot,
  breed: state.breed,
});

const mapDispatchToProps = (dispatch) => ({
  lotActions: bindActionCreators(LotActions, dispatch),
  breedActions: bindActionCreators(BreedActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewLotItem);
