import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import CanvasJSReact from "../../../../../assets/canvasjs.react";
import { useCallback } from "react";

// Modifica os separadores de milhar e decimal
CanvasJSReact.CanvasJS.addCultureInfo("br", {
  decimalSeparator: ",",
  digitGroupSeparator: ".",
});

const { CanvasJSChart } = CanvasJSReact;

const DashboardReproductionBirthChart = ({ chartData = [] }) => {
  const [dataChart, setDataChart] = useState(null);

  const toggleDataSeries = useCallback(
    (e) => {
      const id = e?.dataSeries?.id;
      const index = dataChart.findIndex((dc) => dc?.id === id);
      const item = dataChart[index];
      if (
        dataChart.filter((dc) => dc.visible).length > 1 ||
        item?.visible === false
      ) {
        const newDataChart = dataChart.map((dc) =>
          id === dc.id ? { ...dc, visible: !dc.visible } : dc
        );
        setDataChart(newDataChart);
      }
    },
    [dataChart]
  );

  useEffect(() => {
    let newChartData = [];

    if (chartData) {
      for (let i = 0; i < 2; i++) {
        let baseChart = {
          type: "column",
          showInLegend: true,
          visible: true,
        };
        let dataPoints = [];
        if (i === 1) {
          baseChart.id = "accomplished";
          baseChart.name = "Realizado";
          baseChart.color = "#9074bf";
          dataPoints = chartData.map((data) => ({
            label: data.label,
            y: data.accomplishedTotal,
          }));
        } else {
          baseChart.id = "foreseen";
          baseChart.name = "Previsto";
          baseChart.color = "#c6de52";
          dataPoints = chartData.map((data) => ({
            label: data.label,
            y: data.foreseenTotal,
          }));
        }
        baseChart = { ...baseChart, dataPoints: dataPoints };
        newChartData.push(baseChart);
      }
    }

    setDataChart(newChartData);
  }, [chartData]);

  const options = {
    animationEnabled: true,
    zoomEnabled: true,
    height: 220,
    backgroundColor: "#ffff",
    axisX: {
      labelFontSize: 12,
    },
    toolTip: {
      fontFamily: "Asap",
      shared: true,
    },
    axisY: {
      labelFontSize: 12,
    },
    legend: {
      verticalAlign: "bottom",
      cursor: "pointer",
      itemclick: toggleDataSeries,
    },
    data: dataChart,
  };

  return <CanvasJSChart options={options} />;
};

export default DashboardReproductionBirthChart;
