import {
  Col,
  DatePicker,
  Empty,
  Icon,
  Modal,
  Row,
  Select,
  Spin,
  notification,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import ButtonStandard from "../../../../components/utils/button";
import { ModalBody } from "./styles";

import { getAnimalReproductionBirthDashboard } from "../../../../services/dashboards/dashboardAnimalReproductionService";

import moment from "moment";
import {
  getParameterItems,
  saveOrUpdateParameter,
} from "../../../../services/generalParameterService";
import DashboardReproductionBirthChart from "./chart";
import DashboardReproductionBirthTable from "./table";
import { getAnimalDropdownListByGender } from "../../../../services/animalService";
import { findAll } from "../../../../services/multipleBullService";
import { findAll as findAllSemens } from "../../../../services/semenService";

const PARAMETER_START_DATE_FILTER_CODE = 3001;
const PARAMETER_START_DATE_FILTER_ID = "0ea0529d-1da0-4dfc-a44f-32a93bf23943";
const PARAMETER_FINAL_DATE_FILTER_CODE = 3002;
const PARAMETER_FINAL_DATE_FILTER_ID = "d2e4c696-52de-40ac-97a3-030ad011bec7";
const PARAMETER_BULL_ID_FILTER_CODE = 3003;
const PARAMETER_BULL_ID_FILTER_ID = "680ac792-27df-4809-971a-6a6067583807";

const DashboardReproductionBirth = () => {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const [males, setMales] = useState([]);
  const [errors, setErrors] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [startDateFilterParameterItemId, setStartDateFilterParameterItemId] =
    useState(null);
  const [finalDateFilterParameterItemId, setFinalDateFilterParameterItemId] =
    useState(null);
  const [bullIdFilterParameterItemId, setBullIdFilterParameterItemId] =
    useState(null);

  const [startDateFilterFromParam, setStartDateFilterFromParam] =
    useState(null);
  const [finalDateFilterFromParam, setFinalDateFilterFromParam] =
    useState(null);
  const [bullIdFilterFromParam, setBullIdFilterFromParam] = useState(null);

  const [startDateFilter, setStartDateFilter] = useState(null);
  const [finalDateFilter, setFinalDateFilter] = useState(null);
  const [bullIdFilter, setBullIdFilter] = useState(null);

  const [dashboardResult, setDashboardResult] = useState(null);
  const [dashboardChartDataResult, setDashboardChartDataResult] =
    useState(null);

  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  const labelPeriod = useMemo(() => {
    if (startDateFilter && finalDateFilter) {
      return `Período: ${moment(startDateFilter).format("MMM/YYYY")} à ${moment(
        finalDateFilter
      ).format("MMM/YYYY")}`;
    } else {
      return "";
    }
  }, [startDateFilter, finalDateFilter]);

  const labelReproductor = useMemo(() => {
    if (bullIdFilter && males.length > 0) {
      const reproductorName = males.find(
        (male) => male.id === bullIdFilter
      ).name;
      return ` - Reprodutor: ${reproductorName}`;
    } else {
      return "";
    }
  }, [bullIdFilter, males]);

  const applyFilters = useCallback(async () => {
    if (!startDateFilter || !finalDateFilter) {
      notification.error({
        message: "É obrigatório informar uma Data Inicial e uma Data Final!",
      });
      if (!startDateFilter) {
        setErrors((prevState) => [...prevState, "startDateFilter"]);
      }
      if (!finalDateFilter) {
        setErrors((prevState) => [...prevState, "finalDateFilter"]);
      }
    } else {
      try {
        const {
          data: { results: resultsStartParam },
        } = await saveOrUpdateParameter({
          groupId,
          farmId,
          parameterItemId: startDateFilterParameterItemId,
          parameterId: PARAMETER_START_DATE_FILTER_ID,
          edit: startDateFilterParameterItemId != null,
          body: {
            id: startDateFilterParameterItemId,
            value: startDateFilter,
            valueExtra: null,
          },
        });
        const {
          data: { results: resultsFinalParam },
        } = await saveOrUpdateParameter({
          groupId,
          farmId,
          parameterItemId: finalDateFilterParameterItemId,
          parameterId: PARAMETER_FINAL_DATE_FILTER_ID,
          edit: finalDateFilterParameterItemId != null,
          body: {
            id: finalDateFilterParameterItemId,
            value: finalDateFilter,
            valueExtra: null,
          },
        });
        if (
          (bullIdFilterParameterItemId == null && bullIdFilter != null) ||
          (bullIdFilter == null && bullIdFilterParameterItemId != null) ||
          (bullIdFilter && bullIdFilterParameterItemId)
        ) {
          const {
            data: { results: resultsBullIdParam },
          } = await saveOrUpdateParameter({
            groupId,
            farmId,
            parameterItemId: bullIdFilterParameterItemId,
            parameterId: PARAMETER_BULL_ID_FILTER_ID,
            edit: bullIdFilterParameterItemId != null,
            body: {
              id: bullIdFilterParameterItemId,
              value: bullIdFilter || "",
              valueExtra: null,
            },
          });
          setBullIdFilterParameterItemId(resultsBullIdParam?.id);
          setBullIdFilterFromParam(bullIdFilter);
        }

        setStartDateFilterParameterItemId(resultsStartParam?.id);
        setFinalDateFilterParameterItemId(resultsFinalParam?.id);

        setStartDateFilterFromParam(startDateFilter);
        setFinalDateFilterFromParam(finalDateFilter);

        setIsModalVisible(false);
        fetchDashboard();
      } catch (error) {
      } finally {
      }
    }
  }, [
    startDateFilter,
    startDateFilterParameterItemId,
    finalDateFilter,
    finalDateFilterParameterItemId,
    bullIdFilter,
    bullIdFilterParameterItemId,
  ]);

  const fetchDashboard = useCallback(
    async (pStartDate = null, pFinalDate = null, pBullId = null) => {
      const startDate = pStartDate ? pStartDate : startDateFilter;
      const finalDate = pFinalDate ? pFinalDate : finalDateFilter;
      const bullId = pBullId ? pBullId : bullIdFilter;

      if (startDate && finalDate) {
        setIsLoadingRequest(true);
        try {
          const {
            data: { results },
          } = await getAnimalReproductionBirthDashboard({
            groupId,
            farmId,
            startDate: startDate,
            finalDate: finalDate,
            bullId: bullId,
          });
          setDashboardResult(results);
          setDashboardChartDataResult(
            results.chartData != null ? results.chartData : []
          );
        } catch (error) {
          setDashboardResult(null);
          notification.error({
            message:
              "Erro ao buscar dados do gráfico de Partos. Por favor, contate o suporte.",
          });
        } finally {
          setIsLoadingRequest(false);
        }
      }
    },
    [groupId, farmId, startDateFilter, finalDateFilter, bullIdFilter]
  );

  const handleCancelModal = useCallback(() => {
    setStartDateFilter(startDateFilterFromParam);
    setFinalDateFilter(finalDateFilterFromParam);
    setBullIdFilter(bullIdFilterFromParam);
    setIsModalVisible(false);
    setErrors([]);
  }, [
    startDateFilterFromParam,
    finalDateFilterFromParam,
    bullIdFilterFromParam,
  ]);

  useEffect(() => {
    async function fetchMales() {
      let results = [];
      let resultAnimalsMale = [];
      let resultMultipleBulls = [];
      let resultSemens = [];
      // Get Males Reproductors
      try {
        const {
          data: { results: animalMales },
        } = await getAnimalDropdownListByGender({
          groupId,
          farmId,
          gender: "M",
        });
        resultAnimalsMale = animalMales.filter(
          (a) =>
            (a.status === "A" || a.status === "Active") &&
            a.gender === "M" &&
            a.animalFarmFunction === "reproduction"
        );
      } catch (error) {
        console.error(error);
      }
      // Get MultipleBulls
      try {
        const {
          data: { results: multipleBulls },
        } = await findAll({ groupId, farmId });
        resultMultipleBulls = multipleBulls.filter(
          (mb) => mb.status === "Active"
        );
      } catch (error) {
        console.error(error);
      }
      // Get Semens
      try {
        const {
          data: { results: semens },
        } = await findAllSemens({ groupId, farmId });
        resultSemens = semens.filter((mb) => mb.status === "Active");
      } catch (error) {
        console.error(error);
      }
      results = [
        ...resultAnimalsMale.map((am) => ({
          id: am.id,
          name: am.handlingNumber,
          isMultipleBull: false,
          isSemen: false,
        })),
        ...resultMultipleBulls.map((mb) => ({
          id: mb.id,
          name: `${mb.name}(TM)`,
          isMultipleBull: true,
          isSemen: false,
        })),
        ...resultSemens.map((mb) => ({
          id: mb.id,
          name: `${mb.name}(S)`,
          isMultipleBull: false,
          isSemen: true,
        })),
      ];

      setMales(results);
    }
    async function fetchParameters() {
      try {
        const [
          {
            data: { results: paramStartDate },
          },
          {
            data: { results: paramFinalDate },
          },
          {
            data: { results: paramBullId },
          },
        ] = await Promise.all([
          getParameterItems({
            groupId,
            farmId,
            code: PARAMETER_START_DATE_FILTER_CODE,
          }),
          getParameterItems({
            groupId,
            farmId,
            code: PARAMETER_FINAL_DATE_FILTER_CODE,
          }),
          getParameterItems({
            groupId,
            farmId,
            code: PARAMETER_BULL_ID_FILTER_CODE,
          }),
        ]);

        setStartDateFilterParameterItemId(paramStartDate?.id || null);
        setFinalDateFilterParameterItemId(paramFinalDate?.id || null);
        setBullIdFilterParameterItemId(paramBullId?.id || null);

        setStartDateFilter(
          paramStartDate?.value ? moment(paramStartDate?.value) : null
        );
        setStartDateFilterFromParam(
          paramStartDate?.value ? moment(paramStartDate?.value) : null
        );
        setFinalDateFilter(
          paramFinalDate?.value ? moment(paramFinalDate?.value) : null
        );
        setFinalDateFilterFromParam(
          paramFinalDate?.value ? moment(paramFinalDate?.value) : null
        );
        setBullIdFilter(paramBullId?.value || null);
        setBullIdFilterFromParam(paramBullId?.value || null);

        fetchDashboard(
          paramStartDate?.value ? moment(paramStartDate?.value) : null,
          paramFinalDate?.value ? moment(paramFinalDate?.value) : null,
          paramBullId?.value || null
        );
      } catch (error) {}
    }
    async function fetchAll() {
      await Promise.all([fetchParameters(), fetchMales()]);
    }
    fetchAll();
  }, [groupId, farmId]);

  return (
    <Spin spinning={isLoadingRequest}>
      <Row
        type="flex"
        justify="center"
        style={{
          backgroundColor: "#684e94",
          padding: "8px 16px",
          borderRadius: 5,
        }}
      >
        <Col span={12}>
          <Row type="flex" justify="end" gutter={8}>
            <Col>
              <h3 style={{ color: "#fff", margin: 0 }}>Partos</h3>
            </Col>
            <Col>
              <ButtonStandard
                buttonType="arrow"
                onClick={() => setIsModalVisible((prevState) => !prevState)}
              >
                <Icon type="filter" />
              </ButtonStandard>
            </Col>
            <Col>
              <ButtonStandard
                buttonType="arrow"
                onClick={() => fetchDashboard()}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row type="flex" justify="end" gutter={8}>
            <strong style={{ color: "#fff", margin: 0 }}>
              {labelPeriod}
              {labelReproductor}
            </strong>
          </Row>
        </Col>
      </Row>
      {startDateFilterParameterItemId == null &&
      finalDateFilterParameterItemId == null ? (
        <Empty description="Aplique algum filtro para poder mostrar os dados do gráfico." />
      ) : (
        <Row type="flex" gutter={4}>
          <Col span={20}>
            <DashboardReproductionBirthChart
              chartData={dashboardChartDataResult}
            />
          </Col>
          <Col span={4}>
            <DashboardReproductionBirthTable
              dashboardResult={dashboardResult}
            />
          </Col>
        </Row>
      )}
      {/* Modal */}
      <Modal
        visible={isModalVisible}
        centered
        footer={null}
        closable
        onCancel={handleCancelModal}
        title="Aplicar filtros"
      >
        <ModalBody>
          {errors.length > 0 && (
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ marginBottom: 8 }}
            >
              <label className="error">{translation.error.formError}</label>
            </Row>
          )}
          <Row type="flex" gutter={8}>
            <Col span={12}>
              <Row>
                <label
                  className={errors.includes("startDateFilter") ? "error" : ""}
                >
                  Data Inicial*
                </label>
              </Row>
              <Row>
                <DatePicker.MonthPicker
                  format={"MM/YYYY"}
                  value={startDateFilter || undefined}
                  placeholder={translation.defaultDatePickerPlaceholder}
                  onChange={(date) => {
                    setStartDateFilter(date);
                    setFinalDateFilter(null);
                    setErrors((prevState) =>
                      prevState.filter((error) => error !== "startDateFilter")
                    );
                  }}
                />
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <label
                  className={errors.includes("finalDateFilter") ? "error" : ""}
                >
                  Data Final*
                </label>
              </Row>
              <Row>
                <DatePicker.MonthPicker
                  value={finalDateFilter || undefined}
                  format={"MM/YYYY"}
                  placeholder={translation.defaultDatePickerPlaceholder}
                  disabledDate={(current) =>
                    startDateFilter
                      ? current.isBefore(moment(startDateFilter)) ||
                        current.isAfter(
                          moment(startDateFilter).add(9, "months")
                        )
                      : false
                  }
                  onChange={(date) => {
                    setFinalDateFilter(date);
                    setErrors((prevState) =>
                      prevState.filter((error) => error !== "finalDateFilter")
                    );
                  }}
                />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Row>
                <label>Reprodutor/Sêmen</label>
              </Row>
              <Row>
                <Select
                  value={
                    males.length > 0
                      ? bullIdFilter && bullIdFilter !== ""
                        ? bullIdFilter
                        : undefined
                      : undefined
                  }
                  style={{ width: "100%" }}
                  placeholder="Selecione um item"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => setBullIdFilter(value)}
                >
                  {males.map((a) => (
                    <Select.Option
                      key={a.id}
                      value={a.id}
                      style={
                        a.isMultipleBull
                          ? {
                              color: "#9074bf",
                              fontWeight: 700,
                            }
                          : a.isSemen
                          ? {
                              color: "#c6de52",
                              fontWeight: 700,
                            }
                          : {}
                      }
                    >
                      {a.name}
                    </Select.Option>
                  ))}
                </Select>
              </Row>
            </Col>
          </Row>
          <Row type="flex" justify="center" style={{ marginTop: 16 }}>
            <ButtonStandard buttonType="principal" onClick={applyFilters}>
              APLICAR FILTROS
            </ButtonStandard>
          </Row>
        </ModalBody>
      </Modal>
    </Spin>
  );
};

export default DashboardReproductionBirth;
