import React, { useEffect, useState } from "react";
import moment from "moment";
import { Col, Drawer, Row } from "antd";
import { LastLotHistory, LastLotHistoryContainer } from "./styles";
import ButtonStandard from "../button";

// import { Container } from './styles';

const LotHistoriesDrawer = ({ lotHistories, closeDrawer, visible }) => {
  return (
    <Drawer width={700} visible={visible} onClose={() => closeDrawer()}>
      <Row type="flex" justify="center">
        <h3>Histórico de Lotes</h3>
      </Row>
      {lotHistories.map((lh) => (
        <LastLotHistory key={lh.id}>
          <Row type="flex" justify="start" gutter={8}>
            <Col span={10}>
              <div className="nameContainer">
                <span className="lotName">{lh?.lot?.name}</span>
              </div>
            </Col>
            <Col span={14} align="right">
              <div className="datesContainer">
                <span>
                  Entrada:{" "}
                  <strong>{moment(lh?.dateInUser).format("DD/MM/YYYY")}</strong>
                </span>
                <span>
                  Saída:{" "}
                  <strong>
                    {lh?.dateOutUser != null
                      ? moment(lh?.dateOutUser).format("DD/MM/YYYY")
                      : "-"}
                  </strong>
                </span>
                <span>
                  Total de dias:{" "}
                  <strong>
                    {Math.abs(
                      moment(
                        lh?.dateOutUser != null
                          ? moment(lh?.dateOutUser)
                          : moment()
                      ).diff(lh?.dateInUser, "days", false)
                    )}
                  </strong>
                </span>
              </div>
            </Col>
          </Row>
        </LastLotHistory>
      ))}
    </Drawer>
  );
};

const LotHistory = ({ lotHistories }) => {
  const [lastLotHistory, setLastLotHistory] = useState(null);
  const [showOrHideDrawer, setShowOrHideDrawer] = useState(false);

  useEffect(() => {
    if (lotHistories != null && lotHistories.length > 0) {
      let obj = lotHistories[lotHistories.length - 1];
      setLastLotHistory(obj);
    }

    return () => {
      setLastLotHistory(null);
    };
  }, [lotHistories]);

  // Methods

  const handleVisualizeDrawer = () => {
    setShowOrHideDrawer(!showOrHideDrawer);
  };

  return (
    <LastLotHistoryContainer>
      {lastLotHistory != null ? (
        <LastLotHistory>
          <Row type="flex" justify="center">
            <h3>Histórico de Lotes - Lote mais recente</h3>
          </Row>
          <Row type="flex" justify="start" gutter={8}>
            <Col span={10}>
              <div className="nameContainer">
                <span className="lotName">{lastLotHistory?.lot?.name}</span>
              </div>
            </Col>
            <Col span={14} align="right">
              <div className="datesContainer">
                <span>
                  Entrada:{" "}
                  <strong>
                    {moment(lastLotHistory?.dateInUser).format("DD/MM/YYYY")}
                  </strong>
                </span>
                <span>
                  Saída:{" "}
                  <strong>
                    {lastLotHistory?.dateOutUser != null
                      ? moment(lastLotHistory?.dateOutUser).format("DD/MM/YYYY")
                      : "-"}
                  </strong>
                </span>
                <span>
                  Total de dias:{" "}
                  <strong>
                    {Math.abs(
                      moment(
                        lastLotHistory?.dateOutUser != null
                          ? moment(lastLotHistory?.dateOutUser)
                          : moment()
                      ).diff(lastLotHistory?.dateInUser, "days", false)
                    )}
                  </strong>
                </span>
              </div>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <ButtonStandard
              type="button"
              buttonType="typeWithoutBackground"
              color="#684E94"
              isUpperCase={false}
              onClick={() => handleVisualizeDrawer()}
              disabled={lotHistories.length === 1}
            >
              Visualizar tudo
            </ButtonStandard>
          </Row>
        </LastLotHistory>
      ) : null}
      <LotHistoriesDrawer
        visible={showOrHideDrawer}
        lotHistories={lotHistories}
        closeDrawer={handleVisualizeDrawer}
      />
    </LastLotHistoryContainer>
  );
};

export default LotHistory;
