import React, { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import template from "../../../../assets/templates/BovExo_template_suplementos.xlsx";
import { Creators as AppActions } from "../../../../store/ducks/app";
import { Creators as SupplierActions } from "../../../../store/ducks/supplier";
import {
  Row,
  Col,
  Icon,
  Upload,
  notification,
  Spin,
  Select,
  Button,
} from "antd";
import ButtonStandard from "../../../utils/button";
import DrawerSupplier from "../../../drawers/supplier";
import {
  CustomModal,
  Container,
  Header,
  Title,
  ButtonWithoutBackground,
  DownloadButton,
  CustomSelect,
} from "./styles";
import { importSupplement } from "../../../../services/importService";

const SupplementUploadModal = () => {
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [supplierSelected, setSupplierSelected] = useState(null);

  const props = {
    accept: ".xlsm, .xlsx",
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    showUploadList: false,
    fileList,
  };
  const { Dragger } = Upload;
  const { Option } = Select;

  // Redux Variables
  const {
    translation,
    modalSupplementUploadVisible,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
    user: { isInputSupplier }
  } = useSelector((state) => state.app);
  const {
    listDrowpDown: suppliers,
    isLoadingDropDown: isLoadingDropDownSuppliers,
  } = useSelector((state) => state.supplier);
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchData(groupId, farmId) {
      dispatch(
        SupplierActions.getDropdownSuppliers(
          groupId,
          farmId,
          "Supplement",
          true
        )
      );
    }
    if (modalSupplementUploadVisible) fetchData(groupId, farmId);
  }, [groupId, farmId, modalSupplementUploadVisible, dispatch]);
  // Methods
  const handleCreateSupplier = () => {
    dispatch(SupplierActions.showDrawer("new_supplement_supplier", null));
  };
  const handleCancel = () => {
    setIsLoading(false);
    setFileList([]);
    setSupplierSelected(null);
    dispatch(AppActions.hideModalSupplementUpload());
  };
  const handleUpload = async () => {
    try {
      setIsLoading(true);
      const {
        data: { results },
      } = await importSupplement({
        groupId,
        farmId,
        supplierId: supplierSelected,
        file: fileList[0],
        isInputSupplier,
      });
      if (results.numberUpdated > 0 && results.numberError === 0) {
        notification.success({
          message: "Suplementos importados e atualizados",
          description:
            'Houve alguns suplementos que foram atualizados. Acesse o menu "Importações" aba "Suplementos" para ver os detalhes.',
          duration: 30,
        });
      } else if (results.numberImported > 0 && results.numberError === 0) {
        notification.success({
          message: "Suplementos importados",
          duration: 30,
        });
      } else if (results.numberImported > 0 && results.numberError > 0) {
        notification.success({
          message: "Suplementos importados parcialmente",
          description:
            'Houve alguns suplementos que não foram importados. Acesse o menu "Importações" aba "Suplementos" para ver os detalhes.',
          duration: 30,
        });
      } else if (results.numberImported === 0 && results.numberError > 0) {
        notification.error({
          message: "Arquivo processado com erro !",
          description:
            'Todos os suplementos não foram importados. Acesse o menu "Importações" aba "Suplementos" para ver os detalhes.',
          duration: 0,
        });
      }
      handleCancel();
    } catch (error) {
      notification.error({
        message: "Erro ao importar",
        description: `Nenhum suplemento foi incluído.`,
        duration: 0,
      });
      handleCancel();
    }
  };

  return (
    <CustomModal
      width={535}
      visible={modalSupplementUploadVisible}
      centered
      maskClosable={false}
      closable={false}
      footer={null}
    >
      <Container>
        <Spin spinning={isLoading}>
          <Header>
            <Title>{translation.importSupplements.title}</Title>
          </Header>

          {/* Message */}
          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div
                dangerouslySetInnerHTML={{
                  __html: translation.importSupplements.message,
                }}
              />
            </Col>
          </Row>
          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} align="left">
              <DownloadButton
                href={template}
                download="BovExo_template_suplementos.xlsx"
              >
                <Icon type="download" />
                {translation.buttons.download}
              </DownloadButton>
            </Col>
          </Row>

          {/* Supplier Select */}
          {!isInputSupplier && (
            <Row type="flex" justify="start" gutter={4} align="middle">
              <Col xs={20} sm={20} md={20} lg={20} xl={20} align="left">
                <CustomSelect
                  name="supplierId"
                  style={{ width: "100%" }}
                  value={supplierSelected != null ? supplierSelected : undefined}
                  placeholder="Selecione um fornecedor"
                  loading={isLoadingDropDownSuppliers}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear={true}
                  onChange={(value) => {
                    if (value == null) {
                      setSupplierSelected(null);
                    } else {
                      setSupplierSelected(value);
                    }
                  }}
                >
                  {suppliers.length > 0 &&
                    suppliers.map((sup) => (
                      <Option key={sup.id} value={sup.id}>
                        {sup.name}
                      </Option>
                    ))}
                </CustomSelect>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <Button
                  shape="circle"
                  icon="plus"
                  onClick={() => handleCreateSupplier()}
                />
              </Col>
            </Row>
          )}

          {/* Upload area */}
          <Row type="flex" className="uploadRow">
            <Row type="flex">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Dragger {...props}>
                  {fileList.length > 0 ? (
                    <Fragment>
                      <p className="ant-upload-drag-icon">
                        <Icon type="file-excel" />
                      </p>
                      <p className="ant-upload-text">{fileList[0].name}</p>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-upload-text">
                        {translation.importSupplements.draggerMessage}
                      </p>
                    </Fragment>
                  )}
                </Dragger>
              </Col>
            </Row>

            <Row
              type="flex"
              className="footer"
              justify="space-between"
              align="middle"
            >
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <ButtonStandard
                  type="button"
                  buttonType="type4"
                  size="s"
                  onClick={handleCancel}
                >
                  {translation.buttons.cancel}
                </ButtonStandard>
              </Col>
              <Col xs={16} sm={16} md={16} lg={16} xl={16} align="right">
                <ButtonWithoutBackground
                  type="submit"
                  onClick={handleUpload}
                  disabled={fileList.length === 0 || (supplierSelected === null && !isInputSupplier)}
                >
                  {translation.buttons.processSupplements}
                </ButtonWithoutBackground>
              </Col>
            </Row>
          </Row>
        </Spin>
      </Container>
      <DrawerSupplier />
    </CustomModal>
  );
};

export default SupplementUploadModal;
