import styled from "styled-components";

export const Container = styled.div`
  padding: 14px 0 5px 0 !important;

  .alertSelected {
    background: #fe8d2a;
    border: 1px solid #fe8d2a;
  }

  .alertDate {
    .ant-calendar-picker-input.ant-input {
      line-height: 1.5;
      padding: 4px 5px;
      width: 85px;
      border: 0px;
      color: #d44c4c !important;
      font-weight: bold;
    }

    svg {
      height: 10px;
      width: 10px;
      path {
        fill: #d44c4c !important;
      }
    }
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;

export const NewLot = styled.button`
  padding: 5px 15px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #684e94;
  color: #684e94;
  text-align: center;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
`;

export const GenerateReport = styled.button`
  padding: 5px 15px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #a9c133;
  color: #a9c133;
  text-align: center;
  font-size: 14px;
  margin-right: 10px;
  &:hover {
    cursor: pointer;
  }
  &.disabled {
    cursor: not-allowed;
    border-color: #adadad;
    color: #adadad;
  }
`;

export const TagStatus = styled.span`
  padding: 2px 5px !important;
  border-radius: 5px !important;
  color: ${(props) => props.color}!important;
  font-size: 12px !important;
  font-weight: bold !important;
  border: ${(props) => `1px solid ${props.borderColor}`};
  background: ${(props) => props.background} !important;
`;

export const AlertButton = styled.button`
  margin-left: 5px;
  padding: 0px 5px;
  border-radius: 5px;
  height: 34px;
  background: #d44c4c;
  border: 1px solid #d44c4c;
  color: #ffffff;
  text-align: center;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }

  svg {
    margin-left: 5px;
  }
`;
