import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NumberFormat from "react-number-format";

import { Col, DatePicker, Icon, Input, Row, Spin, Table, Tooltip } from "antd";
import { Body, Container, Footer, Form } from "./styles";
import moment from "moment";

import { Creators as LotSupplementConsumptionActions } from "../../../store/ducks/lotSupplementConsumption";
import { Creators as DietStrategyActions } from "../../../store/ducks/dietStrategy";

// Components
import ConfirmActionModal from "../../modals/confirmActionModal";
import ButtonStandard from "../../utils/button";
import CustomDivider from "../../utils/customDivider";

// Services
import { getDietStrategyShow } from "../../../services/dietStrategyService";
import { numberMask } from "../../../services/helpersMethodsService";
import { validateDietStrategyValidation } from "../../../services/helpersMethodsService";

// Icons
import PencilIcon from "../../utils/icons/pencil";
import TrashIcon from "../../utils/icons/trash";
import EyeIcon from "../../utils/icons/eye";
import AlertIcon from "../../drawers/alert/icons/alert";

const DrawerLotSupplementConsumption = () => {
  const [isShowingModalDelete, setIsShowingModalDelete] = useState(false);
  const [
    lotSupplementConsumptionForDelete,
    setLotSupplementConsumptionForDelete,
  ] = useState(null);
  const [form, setForm] = useState({
    amountAnimals: 0,
    consumptionDate: null,
    effectiveTotalConsumption: 0,
    totalConsumption: 0,
  });
  const [errors, setErrors] = useState([]);
  const [dietPeriod, setDietPeriod] = useState(null);
  const [dietExpired, setDietExpired] = useState(false);

  // Redux variables
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const {
    lotData: lot,
    operationMode,
    isLoading,
    drawerVisible,
    listOfLotSupplementConsumption,
  } = useSelector((state) => state.lotSupplementConsumption);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchDietStrategy(dietStrategyId) {
      try {
        const now = moment();
        const {
          data: { results },
        } = await getDietStrategyShow({ groupId, farmId, id: dietStrategyId });

        if (results !== null && results !== undefined) {
          setDietExpired(validateDietStrategyValidation(results));
          const currentPeriod = results?.dietPeriods?.find(
            (period) =>
              now.isSameOrAfter(moment(period.startDate)) &&
              now.isSameOrBefore(moment(period.endDate))
          );

          if (
            currentPeriod !== null &&
            currentPeriod !== undefined &&
            Object.keys(currentPeriod).length > 0
          ) {
            setDietPeriod(currentPeriod);
          } else {
            setDietPeriod(null);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (
      drawerVisible &&
      Array.isArray(lot?.dietStrategies) &&
      lot?.dietStrategies.length > 0
    ) {
      fetchDietStrategy(lot.dietStrategies[0]?.dietStrategyId);
    }
  }, [groupId, farmId, drawerVisible, lot]);

  // Callbacks
  function handleDelete(id) {
    setLotSupplementConsumptionForDelete(id);
    setIsShowingModalDelete(true);
  }

  function showDietStrategyDetails(id) {
    dispatch(DietStrategyActions.showDietStrategy(groupId, farmId, id));
  }

  function handleChangeOperationMode(mode, data) {
    let body = null;
    if (data !== null) {
      body = {
        ...data,
        consumptionDate: moment(data?.consumptionDate, "DD/MM/YYYY"),
        amountAnimals: lot?.activeAnimalsAmount || 0,
      };
    } else {
      body = {
        amountAnimals: lot?.activeAnimalsAmount || 0,
        consumptionDate: null,
        effectiveTotalConsumption: 0,
        totalConsumption: 0,
      };
    }
    setForm(body);
    dispatch(LotSupplementConsumptionActions.changeOperationMode(mode, body));
  }

  function handleClose() {
    dispatch(
      LotSupplementConsumptionActions.showOrHideDrawerAndFetch(null, null, null)
    );
  }

  function handleFormValidation() {
    let aErrors = [];
    if (form.consumptionDate === null) {
      aErrors.push("consumptionDate");
    }
    if (form.totalConsumption === null || form.totalConsumption <= 0) {
      aErrors.push("totalConsumption");
    }
    setErrors(aErrors);
    return aErrors.length > 0 ? false : true;
  }

  function handleSubmit() {
    if (handleFormValidation()) {
      dispatch(
        LotSupplementConsumptionActions.createOrUpdate(
          groupId,
          farmId,
          lot?.id,
          form?.id ? form?.id : null,
          form
        )
      );
    }
  }

  function handleConfirmDelete() {
    dispatch(
      LotSupplementConsumptionActions.delete(
        groupId,
        farmId,
        lot?.id,
        lotSupplementConsumptionForDelete?.id
      )
    );
    setLotSupplementConsumptionForDelete(null);
    setIsShowingModalDelete(false);
  }

  function handleCancelDelete() {
    setLotSupplementConsumptionForDelete(null);
    setIsShowingModalDelete(false);
  }

  return (
    <Container
      title={`Consumo de Suplemento/Concent. do Lote`}
      visible={drawerVisible}
      width={700}
      onClose={handleClose}
      maskClosable={false}
    >
      <div className="drawerForm">
        <Spin spinning={isLoading}>
          <Body>
            <Row type="flex" justify="space-between">
              <Col span={12}>
                <label>Nome do Lote</label>
                <span>{lot?.name}</span>
              </Col>
              <Col span={12}>
                <label>Aividade</label>
                <span>{lot?.profitCenterName}</span>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={12}>
                <label>Qtde. de Animais Ativos</label>
                <span>{`${lot?.activeAnimalsAmount || 0} animais`}</span>
              </Col>
              <Col span={12}>
                <label>Piquete / Baia</label>
                <span>{lot?.picketName}</span>
              </Col>
            </Row>
            <CustomDivider dashed title="Estratégia de dieta" />
            {Array.isArray(lot?.dietStrategies) &&
            lot?.dietStrategies.length > 0 ? (
              <Row type="flex" justify="space-between">
                <Col span={12}>
                  <label>
                    Estratégia de Dieta
                    <button
                      className="dietDetails"
                      type="button"
                      onClick={() =>
                        showDietStrategyDetails(
                          lot?.dietStrategies[0]?.dietStrategyId
                        )
                      }
                    >
                      <EyeIcon isActive />
                    </button>
                  </label>
                  <Row type="flex">
                    <b>{lot?.dietStrategies[0]?.dietStrategyName}</b>
                    {dietExpired ? (
                      <Tooltip
                        title="Esta estratégia está vencida."
                        trigger="hover"
                      >
                        <div className="alert-icon">
                          <AlertIcon />
                        </div>
                      </Tooltip>
                    ) : null}
                  </Row>
                </Col>
                {dietPeriod !== null ? (
                  <Col span={12}>
                    <label>Suplemento/Concent.</label>
                    <span>{`${dietPeriod?.supplement?.name} - Versão ${dietPeriod?.supplementVersion}`}</span>
                    <Row type="flex" justify="space-between">
                      <label>Consumo diário / animal</label>
                      <span>
                        {`${numberMask(
                          dietPeriod?.supplementConsumptionByAnimal || 0
                        )} ${
                          dietPeriod?.supplementConsumptionMeasureType ===
                          "PV_ANIMAL"
                            ? "%PV"
                            : dietPeriod?.supplementConsumptionMeasureType ===
                              "G_KG_PV_ANIMAL"
                            ? "g/kg de PV"
                            : dietPeriod?.supplementConsumptionMeasureType ===
                              "G_ANIMAL"
                            ? "g/dia"
                            : dietPeriod?.supplementConsumptionMeasureType ===
                              "KG_ANIMAL"
                            ? "kg/dia"
                            : dietPeriod?.supplementConsumptionMeasureType ===
                              "I_MS_PV_ANIMAL"
                            ? "%IMS PV"
                            : null
                        }`}
                      </span>
                    </Row>
                    <Row type="flex" justify="space-between">
                      <label>Custo/Kg</label>
                      <span>
                        {numberMask(dietPeriod?.supplementDailyCost || 0, true)}
                      </span>
                    </Row>
                  </Col>
                ) : (
                  <Col span={12}>
                    <label>Suplemento/Concent.</label>
                    <span>-</span>
                  </Col>
                )}
              </Row>
            ) : null}

            <CustomDivider dashed title="Consumos de Suplemento/Concent." />

            {operationMode === "list" && (
              <>
                <Row type="flex" justify="space-between">
                  <Col span={24} align="right">
                    <ButtonStandard
                      buttonType="typeWithoutBackground"
                      isUpperCase={false}
                      onClick={() => handleChangeOperationMode("new", null)}
                    >
                      <Icon type="plus" />
                      Novo registro
                    </ButtonStandard>
                  </Col>
                </Row>
                <Table
                  rowKey="id"
                  size="small"
                  dataSource={listOfLotSupplementConsumption}
                  pagination={{
                    pageSize: 10,
                    hideOnSinglePage:
                      listOfLotSupplementConsumption.length > 10 ? false : true,
                  }}
                >
                  <Table.Column
                    key="consumptionDate"
                    dataIndex="consumptionDate"
                    title="Data"
                  />
                  <Table.Column
                    key="totalConsumption"
                    dataIndex="totalConsumption"
                    title="Total Consumido"
                    render={(text) => (
                      <span>{`${numberMask(text, false)} Kg`}</span>
                    )}
                  />
                  <Table.Column
                    key="amountAnimals"
                    dataIndex="amountAnimals"
                    title="Qtd. Animais"
                    render={(text) => <span>{`${text} animais`}</span>}
                  />
                  <Table.Column
                    key="effectiveTotalConsumption"
                    dataIndex="effectiveTotalConsumption"
                    title="Consumo efetivo"
                    render={(text) => (
                      <span>{`${numberMask(text, false)} Kg/animal`}</span>
                    )}
                  />
                  <Table.Column
                    render={(text, record) => (
                      <div className="menuButtons">
                        <button
                          onClick={() =>
                            handleChangeOperationMode("edit", record)
                          }
                        >
                          <PencilIcon />
                        </button>
                        <button onClick={() => handleDelete(record)}>
                          <TrashIcon />
                        </button>
                      </div>
                    )}
                  />
                </Table>
              </>
            )}
            {operationMode !== "list" && (
              <Form>
                {!!errors.length && (
                  <Row>
                    <Row type="flex" justify="center" align="middle">
                      <label className="error">
                        {translation.error.formError}
                      </label>
                    </Row>
                  </Row>
                )}
                <Row type="flex" justify="start" glutter={8}>
                  {/* ConsumptionDate */}
                  <Col span={12}>
                    <Row>
                      <label
                        className={
                          errors.includes("consumptionDate") ? "error" : ""
                        }
                      >
                        Data do Consumo do Suplemento/Concent.*
                      </label>
                    </Row>
                    <Row>
                      <DatePicker
                        value={form?.consumptionDate}
                        format="DD/MM/YYYY"
                        disabledDate={(current) => current.isAfter(moment())}
                        onChange={(date) =>
                          setForm({ ...form, consumptionDate: date })
                        }
                      />
                    </Row>
                  </Col>
                  {/* TotalConsumption */}
                  <Col span={12}>
                    <Row>
                      <label
                        className={
                          errors.includes("totalConsumption") ? "error" : ""
                        }
                      >
                        Quantidade Total Consumida*
                      </label>
                    </Row>
                    <Row>
                      <NumberFormat
                        customInput={Input}
                        addonAfter="Kg"
                        value={form?.totalConsumption}
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        onValueChange={({ floatValue }) => {
                          setForm({
                            ...form,
                            totalConsumption: floatValue,
                            effectiveTotalConsumption:
                              form?.amountAnimals > 0
                                ? floatValue / form?.amountAnimals
                                : 0,
                          });
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                {/* EffectiveTotalConsumption */}
                <Row type="flex" justify="start" glutter={8}>
                  <Col span={12}>
                    <Row>
                      <label>Quantidade Efetiva Consumida*</label>
                    </Row>
                    <Row>
                      <NumberFormat
                        style={{ width: 180 }}
                        customInput={Input}
                        addonAfter="Kg/animal"
                        value={form?.effectiveTotalConsumption}
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        disabled
                      />
                    </Row>
                  </Col>
                </Row>
              </Form>
            )}
          </Body>
          {/* Footer */}
          {operationMode !== "list" && (
            <Footer>
              <Row type="flex" justify="space-between">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className="buttonsDiv"
                  align="right"
                >
                  <ButtonStandard
                    type="button"
                    buttonType="type7"
                    onClick={() => handleChangeOperationMode("list", null)}
                  >
                    {translation.buttons.cancel}
                  </ButtonStandard>

                  <ButtonStandard buttonType="type6" onClick={handleSubmit}>
                    {translation.buttons.save}
                  </ButtonStandard>
                </Col>
              </Row>
            </Footer>
          )}
        </Spin>
      </div>
      {/* Modal */}
      <ConfirmActionModal
        show={isShowingModalDelete}
        title="Você realmente deseja prosseguir?"
        message={`Ao confirmar, você estará deletando o registro do dia ${lotSupplementConsumptionForDelete?.consumptionDate} de consumo de Suplemento/Concent.`}
        cancelAction={handleCancelDelete}
        confirmAction={handleConfirmDelete}
      />
    </Container>
  );
};

export default DrawerLotSupplementConsumption;
