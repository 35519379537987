import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import { Col, Collapse, List, Row, Table } from "antd";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../../../../components/utils/breadCrumbs";
import ArrowActiveIcon from "./icons/arrow/active";
import ArrowInactiveIcon from "./icons/arrow/inactive";
import { Container, HeaderContainer, Title } from "./styles";

import { getImportLog } from "../../../../services/importService";
import { numberMask } from "../../../../services/helpersMethodsService";

function FinancialTransactionImportDetails() {
  const [isLoading, setIsLoading] = useState(false);
  const [importLogData, setImportLogData] = useState(null);
  const [importItensData, setImportItensData] = useState([]);

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const { id } = useParams();

  const customPanelStyle = {
    borderRadius: 7,
    background: "#fff",
    boxShadow: "0px 0px 10px #d8d8d8",
    marginBottom: 15,
  };

  const Column = Table.Column;

  const Header = (imported, amountAnimals) => (
    <HeaderContainer>
      <div className="title">
        <span>{imported ? "Importados" : "Não Importados"}</span>
      </div>
      <div className="amountAnimals">
        <span>{`${amountAnimals} movimentações`}</span>
      </div>
    </HeaderContainer>
  );

  const getImportLogInfo = useCallback(async () => {
    setIsLoading(true);
    try {
      const {
        data: { results: importLogData },
      } = await getImportLog({ groupId, farmId, id });
      setImportLogData(importLogData);
      let importedData = importLogData.financialTransactions
        .filter((financialTransactions) => financialTransactions.success)
        .sort(compare);
      let notImportedData = importLogData.financialTransactions
        .filter((financialTransactions) => !financialTransactions.success)
        .sort(compare);
      setImportItensData([
        {
          imported: true,
          total: importLogData.numberImported,
          data: importedData,
        },
        {
          imported: false,
          total: importLogData.numberError + importLogData.numberDuplicated,
          data: notImportedData,
        },
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [farmId, groupId, id]);

  useEffect(() => {
    getImportLogInfo();
    return () => {
      setImportLogData(null);
      setImportItensData([]);
    };
  }, [getImportLogInfo]);

  return (
    <Container>
      <BreadCrumbs marginBottom="16px">
        <Link to="/admin/imports?tabSelect=4">
          <span className="pageTreeInative">
            {
              translation.imports.financialTransactions.details
                .breadCumbsImportLog
            }
          </span>
        </Link>
        <span className="pageTreeActive">
          {
            translation.imports.financialTransactions.details
              .breadCumbsImportLogDetails
          }
        </span>
      </BreadCrumbs>
      <Row type="flex" justify="space-between" style={{ marginBottom: "19px" }}>
        <Col>
          <Title>
            {translation.imports.financialTransactions.details.statusTitle}:{" "}
          </Title>
          {importLogData != null
            ? importLogData.status === "Processed"
              ? translation.imports.status.processed
              : importLogData.status === "Queue"
              ? translation.imports.status.queue
              : importLogData.status === "Processing"
              ? translation.imports.status.processing
              : importLogData.status === "Error"
              ? translation.imports.status.error
              : null
            : null}
        </Col>
        <Col>
          <Title>
            {translation.imports.financialTransactions.details.fileNameTitle}:{" "}
          </Title>
          {importLogData != null ? importLogData.fileName : null}
        </Col>
        <Col>
          <Title>
            {translation.imports.financialTransactions.details.dateTitle}:{" "}
          </Title>
          {importLogData != null ? importLogData.date : null}
        </Col>
      </Row>
      <List
        itemLayout="vertical"
        loading={isLoading}
        size="large"
        dataSource={importItensData != null ? importItensData : []}
        renderItem={(item) => (
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) =>
              isActive ? <ArrowActiveIcon /> : <ArrowInactiveIcon />
            }
          >
            <Collapse.Panel
              header={Header(item.imported, item.total)}
              key={item.id}
              style={customPanelStyle}
            >
              <Row type="flex" justify="start">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Table
                    rowKey="rowNumber"
                    dataSource={item.data}
                    pagination={true}
                    size="small"
                  >
                    <Column
                      title={
                        translation.imports.financialTransactions.details.table
                          .columns.rowNumber
                      }
                      dataIndex="rowNumber"
                      key="rowNumber"
                      align="left"
                    />
                    {item.imported && (
                      <Column
                        title="Data da Fatura"
                        dataIndex="billingDate"
                        key="billingDate"
                        align="left"
                        render={(text) => moment(text).format("DD/MM/YYYY")}
                      />
                    )}
                    {item.imported && (
                      <Column
                        title="Documento"
                        dataIndex="documentNumber"
                        key="documentNumber"
                        align="left"
                      />
                    )}
                    {item.imported && (
                      <Column
                        title="Valor"
                        dataIndex="value"
                        key="value"
                        align="left"
                        render={(text) => numberMask(text, true)}
                      />
                    )}
                    {!item.imported ? (
                      <Column
                        title={
                          translation.imports.financialTransactions.details
                            .table.columns.message
                        }
                        align="left"
                        render={(text, record) =>
                          record.errors != null ? (
                            <ol>
                              {record.errors.map((e, index) => (
                                <li key={`${index}_${record.id}`}>{e}</li>
                              ))}
                            </ol>
                          ) : null
                        }
                      />
                    ) : null}
                  </Table>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>
        )}
      />
    </Container>
  );
}

export default FinancialTransactionImportDetails;

function compare(a, b) {
  let comparison = 0;
  if (a.rowNumber > b.rowNumber) {
    comparison = 1;
  } else if (a.rowNumber < b.rowNumber) {
    comparison = -1;
  }
  return comparison;
}
