import React from 'react';

// import { Container } from './styles';

const FacebookHomeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12.738" height="23.561" viewBox="0 0 12.738 23.561">
    <path id="Caminho_974" data-name="Caminho 974" d="M34.336,0,31.28,0c-3.433,0-5.651,2.276-5.651,5.8V8.472H22.557a.48.48,0,0,0-.48.481v3.874a.48.48,0,0,0,.48.48h3.072v9.774a.48.48,0,0,0,.48.48h4.008a.48.48,0,0,0,.48-.48V13.307H34.19a.48.48,0,0,0,.48-.48V8.953a.481.481,0,0,0-.481-.481H30.6V6.206c0-1.089.26-1.642,1.679-1.642h2.058a.48.48,0,0,0,.48-.48V.485A.481.481,0,0,0,34.336,0Z" transform="translate(-22.077)" fill="#fff"/>
  </svg>
);

export default FacebookHomeIcon;
