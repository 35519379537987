import React, { useCallback, useState } from "react";

// libs
import { Col, Row, Spin, Tooltip, Icon } from "antd";
import moment from "moment";

// Styles
import { Card } from "../../styles";

// Components
import useKpiContext from "../../../../hooks/useKpiContext";

//Services
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../../services/helpersMethodsService";

//Icons
import FemaleIcon from "../../../../components/utils/icons/female";
import InfoIcon from "../../../../components/utils/icons/info";
import MaleIcon from "../../../../components/utils/icons/male";
import AlertIcon from "../../icons/alert";
import ChartIcon from "../../icons/chart";

const KpiIEP = () => {
  const { kpiIEP: kpiData, updateKpiSelected } = useKpiContext();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isTooltipErrorVisible, setIsTooltipErrorVisible] = useState(false);

  const handleShowTooltip = useCallback(() => {
    setIsTooltipVisible(true);
    setTimeout(() => {
      setIsTooltipVisible(false);
    }, 2000);
  }, []);

  const handleShowTooltipError = useCallback(() => {
    setIsTooltipErrorVisible(true);
    setTimeout(() => {
      setIsTooltipErrorVisible(false);
    }, 2000);
  }, []);

  return (
    <Card
      xs={23}
      sm={23}
      md={7}
      lg={7}
      xl={7}
      className={
        (kpiData?.data?.isComplete
          ? kpiData?.data?.goalAchieved
            ? " border-bottom-green"
            : " border-bottom-red"
          : "") +
        (kpiData.isSelected
          ? kpiData?.data?.isComplete
            ? kpiData?.data?.goalAchieved
              ? " border-green"
              : " border-red"
            : " border-yellow"
          : "")
      }
      onClick={() => (!kpiData.isLoading ? updateKpiSelected("IEP") : null)}
    >
      <Spin spinning={kpiData.isLoading} size="small">
        {kpiData?.data?.startDate && kpiData?.data?.endDate && (
          <div className="period-header">
            <span>
              {moment(kpiData?.data?.startDate, "YYYY-MM-DD").format(
                "DD/MM/YYYY"
              )}{" "}
              a{" "}
              {moment(kpiData?.data?.endDate, "YYYY-MM-DD").format(
                "DD/MM/YYYY"
              )}
            </span>
          </div>
        )}

        <div className="header">
          <span>
            <Tooltip visible={isTooltipErrorVisible} title={`Último processamento: ${kpiData.data?.kpiDate != null ? moment(kpiData.data.kpiDate).format("DD/MM/YYYY") : "Sem data"}`}>
              {kpiData.data.error ? (
                <Icon
                  type="exclamation-circle"
                  className="iconError"
                  theme="filled"
                  onClick={() => handleShowTooltipError()} />
              ) : null}
            </Tooltip>
            IEP
          </span>
          <span>
            {!kpiData?.data?.isComplete ? null : kpiData?.data?.goalAchieved ? (
              <ChartIcon />
            ) : (
              <AlertIcon />
            )}
          </span>
        </div>

        <div className="content">
          <Row type="flex">
            <Tooltip
              visible={isTooltipVisible}
              title="Intervalo entre Partos em Dias (Todas as Fêmeas Ativas, com mais de 2 Partos)"
            >
              <span className="span-font">
                <b>
                  {kpiData?.data?.kpiValue === null
                    ? "-"
                    : Number(kpiData?.data?.kpiValue) || 0}{" "}
                </b>
                {kpiData?.data?.valueType}
              </span>
            </Tooltip>
          </Row>
          <Row type="flex">
            <InfoIcon onClick={() => handleShowTooltip()} />
          </Row>
        </div>

        <div className="content-footer">
          <Col span={12}>
            <Row type="flex" justify="center">
              <span>Partos</span>
            </Row>
            <Row type="flex" className="row-space-evenly">
              <Col span={12} className="iep-births-div">
                <Tooltip
                  visible={isTooltipVisible}
                  title="Qtde de Partos e Nascimentos, registrados no Período"
                  placement="left"
                >
                  <span>{kpiData.data?.parameters?.TotalOfBirths || 0}</span>
                </Tooltip>
              </Col>
              <Col span={12}>
                <div>
                  <span>
                    <MaleIcon className="male-icon" />
                    <b>{kpiData.data?.parameters?.TotalOfMaleBirths || 0}</b>
                  </span>
                </div>
                <div>
                  <span>
                    <FemaleIcon className="female-icon" />
                    <b>{kpiData.data?.parameters?.TotalOfFemaleBirths || 0}</b>
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row type="flex" justify="end">
              <Tooltip
                visible={isTooltipVisible}
                title="Nº de Partos por vacas (Geral)"
                placement="right"
              >
                <span>{`Nº Partos/vacas: ${numberMask(
                  kpiData.data?.parameters?.AverageOfBirthsPerCow || 0,
                  false
                )}`}</span>
              </Tooltip>
            </Row>
            <Row type="flex" justify="end">
              <Tooltip
                visible={isTooltipVisible}
                title="Idade ao Primeiro parto em meses (Geral)"
                placement="bottom"
              >
                <span>{`IPP: ${getTwoDecimalDigits(
                  kpiData.data?.parameters?.AverageAgeInMonthsOnFirstBirth || 0,
                  0
                )} meses`}</span>
              </Tooltip>
            </Row>
          </Col>
        </div>
      </Spin>
      {!kpiData?.data.isComplete ? <div className="banner-image" /> : null}
    </Card>
  );
};

export default KpiIEP;
