import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as PicketActions } from "../../../store/ducks/picket";
import moment from "moment";
import "moment/locale/pt-br";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Row,
  Col,
  Input,
  Select,
  notification,
  Icon,
  Spin,
  Tooltip,
  DatePicker,
} from "antd";
import {
  Container,
  Footer,
  ButtonProperty,
  CustomDivider,
  ProfitCenterInfo,
  Table,
} from "./styles";
// Components
import ButtonStandard from "../../utils/button";
import { AlertCard } from "./allocateLot/styles";
import NumberFormat from "react-number-format";
import AlertIcon from "./icons/alert";
import SuccessIcon from "./icons/success";
import PencilIcon from "../../utils/icons/pencil";
import PlusBlueIcon from "../../utils/icons/plus/blue";
import DrawerPicketSustenanceCapacity from "./sustenanceCapacity";
import BoxText from "../../utils/boxText";
import LotHistory from "../../utils/lotHistory";
// Services
import { getListOfProfitCenterIndex } from "../../../services/profitCenterService";
import { getTypologyItemIndex } from "../../../services/typologyItemService";
import { getAllSuppliers } from "../../../services/supplierService";
import { getPastureIndexDropDown } from "../../../services/pastureService";
import { getLotIndexDropDown } from "../../../services/lotService";
import { getTheLastPicketLotHistory } from "../../../services/picketService";

class DrawerPicket extends Component {
  constructor(props) {
    super(props);
    this.supplierIdSelect = React.createRef();
    this.state = {
      loadingInternal: false,
      formPicket: {
        id: null,
        name: null,
        importCode: null,
        observation: null,
        propertyType: "Own",
        leaseMonthlyCost: 0,
        productionSubSystem: null,
        profitCenterId: null,
        drinkingFountain: "",
        totalTroughSpace: "",
        lotId: null,
        lotStartDate: null,
        oldLotEndDate: null,
        pastureId: null,
        supplierId: null,
        status: "B",
      },
      sustenanceCapacityData: {
        profitCenter: null,
        pastureAnimalCapacity: "",
        pastureArea: "",
        pastureFertilized: null,
        pastureId: null,
        pastureLeafDensity: null,
        pastureDailyCost: "",
        pastureCurrentHeight: "",
        pastureCurrentHeightDate: null,
        pastureGrowthSpeed: "",
        status: "NI",
        pastures: [],
      },
      drawerPicketSustenanceCapacityVisible: false,
      saveOptions: "only_save",
      listOfProfitCenters: null,
      listOfLots: [],
      pastures: [],
      suppliers: null,
      lotPcSelected: null,
      subSystemSelected: null,
      lotHistories: [],
      lastPicketLotOfLot: null,
    };

    this.formik = React.createRef();
  }

  shouldComponentUpdate(nextProps) {
    const {
      formPicket,
      listOfProfitCenters: profitCenters,
      subSystemSelected: stateSubSystemSelected,
    } = this.state;
    const {
      picket: {
        picketData,
        sustenanceCapacityData: savedSustenanceCapacity,
        drawerVisible,
      },
    } = nextProps;
    try {
      if (
        drawerVisible === true &&
        picketData !== null &&
        savedSustenanceCapacity !== null &&
        formPicket?.id === null
      ) {
        let subSystemSelected = stateSubSystemSelected;

        const picketDataEdited = {
          ...picketData,
          lotStartDate: moment(),
          oldLotEndDate: moment(),
          drinkingFountain: picketData.drinkingFountain / 100, // Transform in m
          totalTroughSpace: picketData.totalTroughSpace / 100, // Transform in m
        };

        const savedSustenanceCapacityEdited = {
          ...savedSustenanceCapacity,
          pastureArea:
            picketData.productionSubSystem === "I" ||
            picketData.productionSubSystem === "Intensive"
              ? savedSustenanceCapacity?.pastureArea * 10000
              : savedSustenanceCapacity?.pastureArea,
        };

        if (subSystemSelected === null && profitCenters !== null) {
          subSystemSelected =
            profitCenters.find((lpc) => lpc.id === picketData.profitCenterId) !=
            null
              ? profitCenters.find(
                  (lpc) => lpc.id === picketData.profitCenterId
                ).productionSubSystem
              : null;
        }

        this.setState({
          formPicket: picketDataEdited,
          sustenanceCapacityData: savedSustenanceCapacityEdited,
          subSystemSelected,
        });
      }
    } catch (error) {
      console.error(error);
    }

    return true;
  }

  closeDrawer = () => {
    const { picketActions } = this.props;
    this.resetForm();
    picketActions.hideDrawer();
  };

  resetForm = () => {
    this.formik.current.resetForm({
      id: null,
      name: null,
      importCode: null,
      observation: null,
      propertyType: "Own",
      leaseMonthlyCost: 0,
      productionSubSystem: null,
      profitCenterId: null,
      drinkingFountain: "",
      totalTroughSpace: "",
      lotId: null,
      pastureId: null,
      supplierId: null,
      status: "B",
    });
    this.setState({
      formPicket: {
        id: null,
        name: null,
        importCode: null,
        observation: null,
        propertyType: "Own",
        leaseMonthlyCost: 0,
        productionSubSystem: null,
        profitCenterId: null,
        drinkingFountain: "",
        totalTroughSpace: "",
        lotId: null,
        lotStartDate: null,
        oldLotEndDate: null,
        pastureId: null,
        supplierId: null,
        status: "B",
      },
      sustenanceCapacityData: {
        profitCenter: null,
        pastureAnimalCapacity: "",
        pastureArea: "",
        pastureFertilized: null,
        pastureId: null,
        pastureLeafDensity: "",
        pastureDailyCost: "",
        pastureCurrentHeight: "",
        pastureCurrentHeightDate: null,
        pastureGrowthSpeed: "",
        status: "NI",
        pastures: [],
      },
    });
  };

  getProfitCenters = async (groupSelected, farmSelected) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;

    const {
      data: { results: allProfitCenters },
    } = await getListOfProfitCenterIndex({ groupId, farmId });
    const {
      data: { results: profitCenterTypes },
    } = await getTypologyItemIndex({
      groupId,
      typologyName: "Sistema Produtivo",
    });
    const { items } = profitCenterTypes[0];
    if (Object.entries(allProfitCenters).length > 0) {
      if (allProfitCenters.length > 0) {
        allProfitCenters.map((pc) => {
          if (pc.productionSystemId != null) {
            const { name: typeName } = items.find(
              (t) => t.id === pc.productionSystemId
            );
            pc.typeName = typeName;
          } else {
            pc.typeName = null;
          }
          return pc;
        });
      }
    }
    this.setState({
      listOfProfitCenters: allProfitCenters.filter(
        (e) => e.status === "Active"
      ),
    });
  };

  getPastures = async (groupSelected, farmSelected) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    const {
      data: { results: pastures },
    } = await getPastureIndexDropDown({ groupId, farmId });
    const pastureFilters = pastures.filter((pasture) => {
      const { seasons } = pasture;

      const drySeason = seasons.filter((s) => s.season === "Dry");
      const waterSeason = seasons.filter((s) => s.season === "Water");
      const transitionSeason = seasons.filter((s) => s.season === "Transition");

      const canShowFertilizedOptionYes =
        drySeason.filter((s) => s.cost > 0 && s.fertilized).length > 0 &&
        waterSeason.filter((s) => s.cost > 0 && s.fertilized).length > 0 &&
        transitionSeason.filter((s) => s.cost > 0 && s.fertilized).length > 0;

      const canShowFertilizedOptionNo =
        drySeason.filter((s) => s.cost > 0 && !s.fertilized).length > 0 &&
        waterSeason.filter((s) => s.cost > 0 && !s.fertilized).length > 0 &&
        transitionSeason.filter((s) => s.cost > 0 && !s.fertilized).length > 0;

      return canShowFertilizedOptionYes === false &&
        canShowFertilizedOptionNo === false
        ? false
        : true;
    });

    this.setState({
      pastures: pastureFilters,
    });
  };

  getLots = async (groupSelected, farmSelected) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    const {
      data: { results: listOfLots },
    } = await getLotIndexDropDown({ groupId, farmId });
    const listFiltered = listOfLots.filter(
      (lot) =>
        (lot.status === "Active" || lot.status === "Pending") &&
        lot.receipt === false
    );
    this.setState({
      listOfLots: listFiltered,
    });
  };

  getSuppliers = async (groupSelected, farmSelected) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    const {
      data: { results: suppliers },
    } = await getAllSuppliers({
      groupId,
      farmId,
      type: "CattleFarmer",
      withoutPagination: true,
    });
    this.setState({
      suppliers,
    });
  };

  openNotificationError = (title, message) => {
    notification["error"]({
      message: title,
      description: message,
    });
  };

  validateProductionSubSystem = (picketProfitCenterId, lotId) => {
    if (lotId != null) {
      const { listOfProfitCenters, listOfLots } = this.state;

      const prodSubSystemPicket = listOfProfitCenters.find(
        (pc) => pc.id === picketProfitCenterId
      ).productionSubSystem;
      const prodSubSystemLot = listOfProfitCenters.find(
        (pc) => pc.id === listOfLots.find((l) => l.id === lotId).profitCenterId
      ).productionSubSystem;

      if (prodSubSystemPicket !== prodSubSystemLot) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  };

  validateForm = (picket) => {
    const {
      app: { translation },
      picket: { picketData },
    } = this.props;
    if (picket.propertyType === "Foreign" && picket.supplierId === null) {
      this.openNotificationError(
        translation.picket.form.error.requiredSupplierId.title,
        translation.picket.form.error.requiredSupplierId.message
      );
      this.supplierIdSelect.current.focus();
      return false;
    } else if (
      (picket.productionSubSystem === "SemiIntensive" ||
        picket.productionSubSystem === "Extensive") &&
      picket.pastureId === null
    ) {
      this.openNotificationError(
        translation.picket.form.error.requiredPastureId.title,
        translation.picket.form.error.requiredPastureId.message
      );
      return false;
    } else if (
      this.validateProductionSubSystem(picket.profitCenterId, picket.lotId)
    ) {
      this.openNotificationError(
        translation.picket.form.error
          .productionSubSystemDifferentFromPicketProductionSubSystem.title,
        translation.picket.form.error
          .productionSubSystemDifferentFromPicketProductionSubSystem.message
      );
      return false;
    } else if (picket.sustenanceCapacityStatus !== "I") {
      this.openNotificationError(
        translation.picket.form.error.sustenanceCapacityNotInformed.title,
        translation.picket.form.error.sustenanceCapacityNotInformed.message
      );
      return false;
    } else if (this.state.sustenanceCapacityData.status === "NI") {
      this.openNotificationError(
        "Dados inválidos",
        "Informe a capacidade de suporte"
      );
      return false;
    } else if (
      picketData !== null &&
      picketData.lotId !== null &&
      picket.lotId === null &&
      picket.oldLotEndDate == null
    ) {
      this.openNotificationError(
        "Dados inválidos",
        "Informe um valor para a sáida do lote anterior"
      );
      return false;
    } else {
      return true;
    }
  };

  handleSubmitModalForm = async (
    values,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) => {
    const {
      listOfProfitCenters: profitCenters,
      sustenanceCapacityData: stateSustenanceCapacity,
    } = this.state;

    const {
      picket: {
        sustenanceCapacityData: savedSustenanceCapacity,
        picketData,
        picketLotHistories,
      },
    } = this.props;

    let sustenanceCapacityData =
      stateSustenanceCapacity.status === "I"
        ? stateSustenanceCapacity
        : savedSustenanceCapacity;

    let picket = {
      id: values.id,
      name: values.name,
      importCode: values.importCode,
      observation: values.observation,
      propertyType: values.propertyType,
      leaseMonthlyCost: values.leaseMonthlyCost,
      productionSubSystem: values.productionSubSystem,
      profitCenterId: values.profitCenterId,
      lotId: values.lotId,
      lotStartDate: values.lotStartDate,
      oldLotEndDate: values.oldLotEndDate,
      supplierId: values.supplierId,
      status: values.status,
      drinkingFountain: values.drinkingFountain * 100, // Transform in cm
      totalTroughSpace: values.totalTroughSpace * 100, // Transform in cm
      pastureId: sustenanceCapacityData?.pastureId,
      pastureAnimalCapacity: sustenanceCapacityData?.pastureAnimalCapacity,
      pastureArea:
        values.productionSubSystem === "I" ||
        values.productionSubSystem === "Intensive"
          ? sustenanceCapacityData?.pastureArea / 10000
          : sustenanceCapacityData?.pastureArea,
      pastureFertilized: sustenanceCapacityData?.pastureFertilized,
      pastureLeafDensity: sustenanceCapacityData?.pastureLeafDensity,
      pastureDailyCost: sustenanceCapacityData?.pastureDailyCost,
      pastureCurrentHeight: sustenanceCapacityData?.pastureCurrentHeight,
      pastureCurrentHeightDate:
        sustenanceCapacityData?.pastureCurrentHeightDate,
      pastureGrowthSpeed: sustenanceCapacityData?.pastureGrowthSpeed,
      sustenanceCapacityStatus: sustenanceCapacityData?.status,
    };

    if (picketData !== null && picketData.lotId === picket.lotId) {
      picket.lotStartDate = null;
      picket.oldLotEndDate = null;
    }

    if (
      picketData !== null &&
      picketData.lotId !== null &&
      picket.lotId === null
    ) {
      picket.lotStartDate = null;
    }

    if (picketLotHistories.length === 0) {
      picket.oldLotEndDate = null;
    }

    if (picket.propertyType == null) {
      picket.propertyType = "Own";
    }

    picket.productionSubSystem = profitCenters.find(
      (pc) => pc.id === picket.profitCenterId
    ).productionSubSystem;

    if (this.validateForm(picket)) {
      if (picket.id === null) {
        this.handleNewPicket(picket);
      } else {
        this.handleUpdatePicket(picket, picket.id);
      }
    }
  };

  handleNewPicket = (picket) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      picketActions,
    } = this.props;

    const { saveOptions } = this.state;

    let saveOptionsSelected =
      saveOptions === "only_save"
        ? false
        : saveOptions === "save_and_create_new"
        ? true
        : false;

    picketActions.savePicket(
      groupId,
      farmId,
      null,
      picket,
      saveOptionsSelected
    );

    this.resetForm();
  };

  handleUpdatePicket = async (picket, id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      picketActions,
    } = this.props;

    let saveOptions = this.state.saveOptions === "only_save" ? false : true;

    picketActions.savePicket(groupId, farmId, id, picket, saveOptions);
  };

  handleSelectLot = async (lotId) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;
    let lastPicketLotOfLot = null;
    this.setState({
      loadingInternal: true,
    });

    if (lotId == null) {
      lastPicketLotOfLot = null;
    } else {
      const {
        data: { results },
      } = await getTheLastPicketLotHistory({
        groupId,
        farmId,
        lotId,
      });
      lastPicketLotOfLot = results;
    }

    this.setState({
      lastPicketLotOfLot,
      loadingInternal: false,
    });
  };

  handleSelectProperty = (props, propertyType) => {
    props.setFieldValue("propertyType", propertyType);
  };

  handleDrawerVisible = async (visible) => {
    if (visible) {
      try {
        const {
          app: {
            groupSelected,
            farmSelected,
            groupSelected: { id: groupId },
            farmSelected: { id: farmId },
          },
          picket: { idPicketDetailsAndEdit },
          picketActions,
        } = this.props;

        // Reset state
        this.setState({
          formPicket: {
            id: null,
            name: null,
            importCode: null,
            observation: null,
            propertyType: "Own",
            leaseMonthlyCost: 0,
            productionSubSystem: null,
            profitCenterId: null,
            drinkingFountain: "",
            totalTroughSpace: "",
            lotId: null,
            pastureId: null,
            supplierId: null,
            status: "B",
          },
          sustenanceCapacityData: {
            profitCenter: null,
            pastureAnimalCapacity: "",
            pastureArea: "",
            pastureFertilized: null,
            pastureId: null,
            pastureLeafDensity: "",
            pastureDailyCost: "",
            pastureCurrentHeight: "",
            pastureCurrentHeightDate: null,
            pastureGrowthSpeed: "",
            status: "NI",
            pastures: [],
          },
          subSystemSelected: null,
          lastPicketLotOfLot: null,
        });

        // Find Pastures, ProfitCenters, Picket (on Edit)
        if (
          Object.entries(groupSelected).length > 0 &&
          Object.entries(farmSelected).length > 0
        ) {
          if (idPicketDetailsAndEdit != null) {
            picketActions.showPicket(groupId, farmId, idPicketDetailsAndEdit);
          }
          await Promise.all([
            this.getProfitCenters(groupSelected, farmSelected),
            this.getPastures(groupSelected, farmSelected),
            this.getSuppliers(groupSelected, farmSelected),
            this.getLots(groupSelected, farmSelected),
          ]);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      this.formik.current.resetForm({
        id: null,
        name: null,
        importCode: null,
        observation: null,
        propertyType: "Own",
        leaseMonthlyCost: 0,
        productionSubSystem: null,
        profitCenterId: null,
        drinkingFountain: null,
        totalTroughSpace: null,
        lotId: null,
        pastureId: null,
        supplierId: null,
        status: "B",
      });
      this.setState({
        formPicket: {
          id: null,
          name: null,
          importCode: null,
          observation: null,
          propertyType: "Own",
          leaseMonthlyCost: 0,
          productionSubSystem: null,
          profitCenterId: null,
          drinkingFountain: "",
          totalTroughSpace: "",
          lotId: null,
          pastureId: null,
          supplierId: null,
          status: "B",
        },
        sustenanceCapacityData: {
          profitCenter: null,
          pastureAnimalCapacity: "",
          pastureArea: "",
          pastureFertilized: null,
          pastureId: null,
          pastureLeafDensity: "",
          pastureDailyCost: "",
          pastureCurrentHeight: "",
          pastureCurrentHeightDate: null,
          pastureGrowthSpeed: "",
          status: "NI",
          pastures: [],
        },
        saveOptions: "only_save",
        lastPicketLotOfLot: null,
      });
    }
  };

  handleSaveSustenanceCapacity = (data) => {
    if (data != null) {
      this.setState({
        sustenanceCapacityData: data,
        drawerPicketSustenanceCapacityVisible: false,
      });
    }
  };

  handleCancelSustenanceCapacity = () => {
    this.setState({
      drawerPicketSustenanceCapacityVisible: false,
    });
  };

  handleGetValueSubSystem = (id) => {
    const {
      listOfProfitCenters: profitCenters,
      sustenanceCapacityData,
      subSystemSelected: oldSubSystemSelected,
    } = this.state;
    let editSustenanceCapacityData = sustenanceCapacityData;
    if (id != null && profitCenters != null) {
      const subSystemSelected =
        profitCenters.find((lpc) => lpc.id === id) != null
          ? profitCenters.find((lpc) => lpc.id === id).productionSubSystem
          : null;
      if (
        oldSubSystemSelected !== null &&
        subSystemSelected !== oldSubSystemSelected
      ) {
        editSustenanceCapacityData = {
          ...editSustenanceCapacityData,
          pastureFertilized: null,
          pastureId: null,
          pastureArea: "",
          pastureLeafDensity: "",
          pastureCurrentHeight: "",
          pastureCurrentHeightDate: null,
          pastureGrowthSpeed: "",
          status: "NI",
        };
      }
      this.setState({
        subSystemSelected,
        sustenanceCapacityData: editSustenanceCapacityData,
      });
    }
  };

  /* Method will be use in button on SustenanceCapacity */
  handleEditSustenanceCapacity = (sustenanceCapacityData) => {
    const { pastures } = this.state;

    if (sustenanceCapacityData.status === "I") {
      sustenanceCapacityData.pastureLeafDensity =
        sustenanceCapacityData.pastureLeafDensity /
        10000; /* No modo Edit o campo é convertido de  Kg/(cm x ha) para Kg/(cm x ㎡)*/
    }
    this.setState({
      sustenanceCapacityData: {
        ...sustenanceCapacityData,
        pastures,
      },
      drawerPicketSustenanceCapacityVisible: true,
    });
  };

  render() {
    const {
      app: { translation },
      picket: {
        picketData,
        drawerVisible,
        isLoadingPicketData,
        picketLotHistories,
      },
    } = this.props;

    const {
      formPicket,
      listOfProfitCenters: profitCenters,
      listOfLots,
      suppliers,
      sustenanceCapacityData,
      drawerPicketSustenanceCapacityVisible,
      subSystemSelected,
      pastures,
      lastPicketLotOfLot,
      loadingInternal,
    } = this.state;

    const validationSchema = Yup.object().shape({
      name: Yup.string().required(),
      lotStartDate: Yup.date()
        .nullable()
        .notRequired()
        .when(["lotId"], {
          is: (lotId) => lotId !== null,
          then: Yup.date().required(),
          otherwise: Yup.date().nullable().notRequired(),
        }),
      profitCenterId: Yup.string().required(),
      totalTroughSpace: Yup.mixed().test(
        "totalTroughSpace-ok",
        "This value is required",
        function (value) {
          if (
            (value == null || value <= 0) &&
            subSystemSelected != null &&
            (subSystemSelected === "Intensive" || subSystemSelected === "I")
          ) {
            return false;
          } else {
            return true;
          }
        }
      ),
    });

    function handleDrawerTittle() {
      if (picketData.productionSubSystem === "I") {
        return translation.picket.drawerTitleEditIntensiveSubSystem;
      } else {
        return translation.picket.drawerTitleEdit;
      }
    }

    const { Option } = Select;
    return (
      <Container
        title={
          picketData != null
            ? handleDrawerTittle()
            : translation.picket.drawerTitle
        }
        width={770}
        onClose={this.closeDrawer}
        maskClosable={false}
        visible={drawerVisible}
        afterVisibleChange={this.handleDrawerVisible}
      >
        <Formik
          ref={this.formik}
          enableReinitialize={true}
          initialValues={formPicket}
          initialErros={{}}
          onSubmit={this.handleSubmitModalForm}
          validationSchema={validationSchema}
          render={(props) => (
            <Spin spinning={isLoadingPicketData || loadingInternal}>
              <form onSubmit={props.handleSubmit} autoComplete="off">
                <div className="drawerForm">
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <span style={{ fontSize: "14px", color: "#000" }}>
                        {picketData != null
                          ? translation.picket.drawerSubTitleEdit
                          : translation.picket.drawerSubTitle}
                      </span>
                    </Col>
                  </Row>
                  {Object.entries(props.errors).length > 0 &&
                    props.submitCount > 0 && (
                      <Row type="flex" justify="center" align="middle">
                        <label className="error">
                          {translation.error.formError}
                        </label>
                      </Row>
                    )}
                  {/* Input picket identification and select propertyType */}
                  <Row
                    type="flex"
                    gutter={16}
                    align="middle"
                    style={{ marginTop: "10px" }}
                  >
                    <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                      <Row>
                        <label
                          className={
                            props.errors.name && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.picket.form.name}*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Input
                          name="name"
                          autoComplete="chrome-off"
                          placeholder={translation.picket.form.namePlaceHolder}
                          value={
                            props.values.name == null
                              ? undefined
                              : props.values.name
                          }
                          onChange={(e) => {
                            props.setFieldValue("name", e.target.value);
                          }}
                          onBlur={props.handleBlur}
                        />
                      </Row>
                    </Col>
                    <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                      <Row>
                        <label>{translation.picket.form.propertyType}</label>
                      </Row>
                      <Row>
                        <ButtonProperty
                          shape="round"
                          className={
                            props.values.propertyType === "Own" ||
                            props.values.propertyType === "O"
                              ? "selected"
                              : ""
                          }
                          onClick={() =>
                            this.handleSelectProperty(props, "Own")
                          }
                        >
                          {translation.picket.propertyType.own}
                        </ButtonProperty>
                        <ButtonProperty
                          shape="round"
                          className={
                            props.values.propertyType === "Foreign" ||
                            props.values.propertyType === "F"
                              ? "selected"
                              : ""
                          }
                          onClick={() => {
                            this.handleSelectProperty(props, "Foreign");
                          }}
                        >
                          {translation.picket.propertyType.foreign}
                        </ButtonProperty>
                      </Row>
                    </Col>
                  </Row>
                  {/* Import Code */}
                  <Row
                    type="flex"
                    gutter={16}
                    align="middle"
                    style={{ marginTop: "10px" }}
                  >
                    <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                      <Row>
                        <label>{translation.picket.form.importCode}</label>
                      </Row>
                      <Row className="rowInput">
                        <Input
                          name="importCode"
                          autoComplete="chrome-off"
                          placeholder={translation.defaultPlaceholder}
                          value={
                            props.values.importCode == null
                              ? undefined
                              : props.values.importCode
                          }
                          onChange={(e) => {
                            props.setFieldValue("importCode", e.target.value);
                          }}
                          onBlur={props.handleBlur}
                        />
                      </Row>
                    </Col>
                  </Row>
                  {/* Input supplier */}
                  {props.values.propertyType === "Foreign" ||
                  props.values.propertyType === "F" ? (
                    <Row
                      type="flex"
                      align="middle"
                      gutter={16}
                      style={{ marginTop: "10px" }}
                    >
                      <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                        <Row>
                          <label>{translation.picket.form.supplierId}</label>
                        </Row>
                        <Row className="rowInput">
                          <Select
                            name="supplierId"
                            ref={this.supplierIdSelect}
                            value={
                              suppliers != null &&
                              props.values.supplierId != null
                                ? props.values.supplierId
                                : undefined
                            }
                            style={{ width: "100%" }}
                            placeholder={
                              translation.picket.form.supplierIdPlaceHolder
                            }
                            onChange={(value) => {
                              props.setFieldValue("supplierId", value);
                            }}
                          >
                            {suppliers != null &&
                              suppliers.map((supplier) => (
                                <Option key={supplier.id} value={supplier.id}>
                                  {supplier.name}
                                </Option>
                              ))}
                          </Select>
                        </Row>
                      </Col>
                      <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                        <Row type="flex">
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            className="colLabelForm"
                          >
                            <label>
                              {translation.picket.form.leaseMonthlyCost}
                            </label>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row className="rowInput">
                              <NumberFormat
                                customInput={Input}
                                name="leaseMonthlyCost"
                                value={
                                  props.values.leaseMonthlyCost !== null
                                    ? props.values.leaseMonthlyCost
                                    : null
                                }
                                placeholder={translation.defaultPlaceholder}
                                addonBefore="R$"
                                addonAfter="ha/mês"
                                decimalScale={2}
                                decimalSeparator=","
                                thousandSeparator="."
                                fixedDecimalScale={true}
                                onValueChange={({ floatValue }) => {
                                  props.setFieldValue(
                                    "leaseMonthlyCost",
                                    floatValue == null ? 0 : floatValue
                                  );
                                }}
                              />
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : null}
                  <CustomDivider dashed />
                  {/* Input profitCenterId */}
                  <Row
                    type="flex"
                    justify="space-between"
                    align="middle"
                    gutter={16}
                    className="rowInput"
                  >
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Row>
                        <label
                          className={
                            props.errors.profitCenterId && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.picket.form.profitCenterId} *
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          name="profitCenterId"
                          value={
                            profitCenters != null &&
                            props.values.profitCenterId != null
                              ? props.values.profitCenterId
                              : undefined
                          }
                          disabled={props.values.lotId != null}
                          placeholder={
                            translation.picket.form.profitCenterIdPlaceHolder
                          }
                          onSelect={(value) => {
                            props.setFieldValue("profitCenterId", value);
                            props.setFieldValue(
                              "productionSubSystem",
                              profitCenters.find((pc) => pc.id === value)
                                .productionSubSystem
                            );
                            this.handleGetValueSubSystem(value);
                          }}
                          onBlur={props.handleBlur}
                        >
                          {profitCenters != null &&
                          Object.entries(profitCenters).length > 0
                            ? profitCenters.map((pc) =>
                                pc.typeName != null &&
                                pc.status === "Active" &&
                                pc.productionSubSystem !== null &&
                                pc.productionSubSystem !== "" ? (
                                  <Option key={pc.id} value={pc.id}>
                                    {pc.name}
                                  </Option>
                                ) : null
                              )
                            : null}
                        </Select>
                      </Row>
                    </Col>
                    {/* Info of the profit center selected */}
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <ProfitCenterInfo
                        type="flex"
                        justify="space-between"
                        align="middle"
                        className={
                          props.values.profitCenterId != null ? "visible" : ""
                        }
                      >
                        <span>Tipo:</span>
                        <span className="value">
                          {props.values.profitCenterId != null &&
                            profitCenters != null &&
                            profitCenters.find(
                              (pc) => pc.id === props.values.profitCenterId
                            ).typeName}
                        </span>
                      </ProfitCenterInfo>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <ProfitCenterInfo
                        type="flex"
                        justify="space-between"
                        align="middle"
                        className={
                          props.values.profitCenterId != null ? "visible" : ""
                        }
                      >
                        <span>Subsistema:</span>
                        <span className="value">
                          {props.values.profitCenterId != null &&
                          profitCenters != null
                            ? profitCenters.find(
                                (lpc) => lpc.id === props.values.profitCenterId
                              ).productionSubSystem === "SemiIntensive" ||
                              profitCenters.find(
                                (lpc) => lpc.id === props.values.profitCenterId
                              ).productionSubSystem === "SI"
                              ? translation.productionSubSystem.semiIntensive
                              : profitCenters.find(
                                  (lpc) =>
                                    lpc.id === props.values.profitCenterId
                                ).productionSubSystem === "Intensive" ||
                                profitCenters.find(
                                  (lpc) =>
                                    lpc.id === props.values.profitCenterId
                                ).productionSubSystem === "I"
                              ? translation.productionSubSystem.intensive
                              : profitCenters.find(
                                  (lpc) =>
                                    lpc.id === props.values.profitCenterId
                                ).productionSubSystem === "Extensive" ||
                                profitCenters.find(
                                  (lpc) =>
                                    lpc.id === props.values.profitCenterId
                                ).productionSubSystem === "E"
                              ? translation.productionSubSystem.extensive
                              : null
                            : null}
                        </span>
                      </ProfitCenterInfo>
                    </Col>
                  </Row>
                  <CustomDivider dashed>
                    {translation.picket.form.infrastructureDivider}
                  </CustomDivider>
                  {/* TotalTroughSpace and DrinkingFountain*/}
                  <Row style={{ marginTop: "13px" }}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Row>
                        <label
                          className={
                            props.errors.totalTroughSpace &&
                            props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.picket.form.totalTroughSpace}
                          {(subSystemSelected === "Intensive" ||
                            subSystemSelected === "I") && <span>*</span>}
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          value={
                            props?.values?.totalTroughSpace !== null
                              ? props?.values?.totalTroughSpace
                              : null
                          }
                          name="totalTroughSpace"
                          placeholder={translation.defaultPlaceholder}
                          addonAfter="m"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue("totalTroughSpace", floatValue);
                          }}
                        />
                      </Row>
                    </Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
                      <Row>
                        <label>
                          {translation.picket.form.drinkingFountain}
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          placeholder={translation.defaultPlaceholder}
                          value={
                            props?.values?.drinkingFountain !== null
                              ? props?.values?.drinkingFountain
                              : null
                          }
                          name="drinkingFountain"
                          addonAfter="m"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue("drinkingFountain", floatValue);
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <CustomDivider dashed />
                  <Row style={{ marginTop: "13px" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Table>
                        <tbody>
                          <tr>
                            <td>
                              <span>
                                {translation.picket.form.sustenanceCapacity}*
                              </span>
                            </td>
                            <td>
                              {sustenanceCapacityData.status === "NI" ? (
                                <span>
                                  <AlertIcon /> {translation.status.pendingData}
                                </span>
                              ) : (
                                <span className="success">
                                  <SuccessIcon />{" "}
                                  {translation.status.informedData}
                                </span>
                              )}
                            </td>
                            <td>
                              {pastures.length === 0 && <Icon type="loading" />}
                              {pastures.length > 0 &&
                              sustenanceCapacityData.status === "NI" &&
                              subSystemSelected !== null ? (
                                <button
                                  className="add"
                                  type="button"
                                  onClick={() =>
                                    this.handleEditSustenanceCapacity(
                                      sustenanceCapacityData
                                    )
                                  }
                                >
                                  {translation.buttons.addInfo}
                                  <PlusBlueIcon />
                                </button>
                              ) : pastures.length > 0 &&
                                sustenanceCapacityData.status === "NI" &&
                                subSystemSelected === null ? (
                                <Tooltip title="Informe a Atividade do piquete / baia">
                                  <button
                                    className="add"
                                    type="button"
                                    disabled
                                    onClick={() =>
                                      this.handleEditSustenanceCapacity(
                                        sustenanceCapacityData
                                      )
                                    }
                                  >
                                    Selecione uma atividade
                                  </button>
                                </Tooltip>
                              ) : null}
                              {pastures.length > 0 &&
                                sustenanceCapacityData.status === "I" && (
                                  <button
                                    className="edit"
                                    type="button"
                                    style={{ marginLeft: "60px" }}
                                    onClick={() =>
                                      this.handleEditSustenanceCapacity(
                                        sustenanceCapacityData
                                      )
                                    }
                                  >
                                    {translation.buttons.edit}
                                    <PencilIcon />
                                  </button>
                                )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  {props.values.profitCenterId != null && (
                    <Fragment>
                      <CustomDivider dashed>
                        {translation.picket.form.alocateLotTitle}
                      </CustomDivider>
                      {/* Input lotId */}
                      <Row type="flex" align="middle" className="rowInput">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Row>
                            <label>{translation.picket.form.lotId}</label>
                          </Row>
                          <Row className="rowInput">
                            <Select
                              name="lotId"
                              value={
                                props.values.lotId != null
                                  ? props.values.lotId
                                  : undefined
                              }
                              placeholder={
                                translation.picket.form.lotIdPlaceHolder
                              }
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              allowClear={true}
                              onChange={(value) => {
                                if (value == null) {
                                  props.setFieldValue("lotId", null);
                                  props.setFieldValue("lotStartDate", null);
                                } else {
                                  props.setFieldValue("lotId", value);
                                  props.setFieldValue("lotStartDate", null);
                                  if (props.values.profitCenterId == null) {
                                    props.setFieldValue(
                                      "profitCenterId",
                                      listOfLots.find((l) => l.id === value)
                                        .profitCenterId
                                    );
                                    props.setFieldValue(
                                      "productionSubSystem",
                                      profitCenters.find(
                                        (pc) =>
                                          pc.id ===
                                          listOfLots.find((l) => l.id === value)
                                            .profitCenterId
                                      ).productionSubSystem
                                    );
                                  }
                                }
                                this.handleSelectLot(value);
                              }}
                            >
                              {listOfLots.length > 0 &&
                                listOfLots
                                  .filter(
                                    (l) =>
                                      l.profitCenterId ===
                                      props.values.profitCenterId
                                  )
                                  .map((lot) => (
                                    <Option key={lot.id} value={lot.id}>
                                      {`${lot.name} / ${
                                        lot.currentAmountAnimals
                                      } animais / Atividade: ${
                                        lot.profitCenterName
                                      } ${
                                        lot.picketId != null
                                          ? `/ Piquete: ${lot.picketName}`
                                          : ``
                                      }`}
                                    </Option>
                                  ))}
                            </Select>
                          </Row>
                        </Col>
                      </Row>
                      {/* Show alert about the last lot allocation */}
                      {lastPicketLotOfLot != null && (
                        <Row type="flex" style={{ marginTop: 13 }}>
                          <BoxText
                            color="#FE8D2A"
                            iconComponent={
                              <Icon
                                type="message"
                                style={{ color: "#FE8D2A" }}
                              />
                            }
                          >
                            <span>
                              O lote escolhido está ou esteve alocado em um
                              piquete / baia. A sua última entrada em um piquete
                              / baia foi:{" "}
                              <strong>
                                {moment(lastPicketLotOfLot?.dateInUser).format(
                                  "DD/MM/YYYY"
                                )}
                              </strong>{" "}
                              e sua saída foi:{" "}
                              <strong>
                                {lastPicketLotOfLot?.dateOutUser != null
                                  ? moment(
                                      lastPicketLotOfLot?.dateOutUser
                                    ).format("DD/MM/YYYY")
                                  : "-"}
                              </strong>
                            </span>
                          </BoxText>
                        </Row>
                      )}
                      {/* Input lotStartDate and Input oldLotEndDate */}
                      <Row
                        type="flex"
                        justify="start"
                        gutter={16}
                        className="rowInput"
                        style={{ marginTop: "13px" }}
                      >
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Row>
                            <label
                              className={
                                props.errors.lotStartDate &&
                                props.submitCount > 0
                                  ? "error"
                                  : ""
                              }
                            >
                              {translation.picket.form.lotStartDate}*
                            </label>
                          </Row>
                          <Row className="rowInput">
                            <DatePicker
                              name="lotStartDate"
                              allowClear={true}
                              disabled={
                                props.values.lotId === null
                                  ? true
                                  : picketData !== null &&
                                    picketData.lotId === props.values.lotId
                                  ? true
                                  : false
                              }
                              disabledDate={(currentDate) =>
                                picketLotHistories.length === 0
                                  ? lastPicketLotOfLot != null
                                    ? lastPicketLotOfLot?.dateOutUser !== null
                                      ? currentDate.isBefore(
                                          moment(
                                            lastPicketLotOfLot?.dateOutUser
                                          ),
                                          "days"
                                        )
                                        ? true
                                        : false
                                      : currentDate.isBefore(
                                          moment(
                                            lastPicketLotOfLot?.dateInUser
                                          ),
                                          "days"
                                        )
                                      ? true
                                      : false
                                    : currentDate.isSameOrAfter(
                                        moment().add(1, "days"),
                                        "days"
                                      )
                                    ? true
                                    : false
                                  : picketLotHistories[
                                      picketLotHistories.length - 1
                                    ].dateOutUser !== null &&
                                    currentDate.isBefore(
                                      moment(
                                        picketLotHistories[
                                          picketLotHistories.length - 1
                                        ].dateOutUser
                                      ),
                                      "days"
                                    )
                                  ? true
                                  : currentDate.isBefore(
                                      moment(
                                        picketLotHistories[
                                          picketLotHistories.length - 1
                                        ].dateInUser
                                      ),
                                      "days"
                                    )
                                  ? true
                                  : currentDate.isSameOrAfter(
                                      moment().add(1, "days"),
                                      "days"
                                    )
                                  ? true
                                  : false
                              }
                              value={
                                props.values !== null &&
                                props.values.lotStartDate != null
                                  ? moment(
                                      props.values.lotStartDate,
                                      "YYYY-MM-DD"
                                    )
                                  : undefined
                              }
                              format={"DD/MM/YYYY"}
                              onChange={(date, dateString) => {
                                if (
                                  date <= moment() &&
                                  date !== null &&
                                  date >= moment("2000-01-01")
                                ) {
                                  props.setFieldValue("lotStartDate", date);
                                }
                              }}
                            />
                          </Row>
                        </Col>
                        {picketLotHistories.length > 0 &&
                          picketLotHistories[picketLotHistories.length - 1]
                            .dateOutUser === null && (
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Row>
                                <label>
                                  {translation.picket.form.oldLotEndDate}
                                </label>
                              </Row>
                              <Row className="rowInput">
                                <DatePicker
                                  name="oldLotEndDate"
                                  allowClear={true}
                                  disabled={
                                    picketData !== null &&
                                    picketData.lotId === props.values.lotId
                                      ? true
                                      : false
                                  }
                                  disabledDate={(currentDate) =>
                                    currentDate.isBefore(
                                      moment(
                                        picketLotHistories[
                                          picketLotHistories.length - 1
                                        ].dateInUser
                                      ),
                                      "days"
                                    )
                                      ? true
                                      : false
                                  }
                                  value={
                                    props.values !== null &&
                                    props.values.oldLotEndDate != null
                                      ? moment(
                                          props.values.oldLotEndDate,
                                          "YYYY-MM-DD"
                                        )
                                      : undefined
                                  }
                                  format={"DD/MM/YYYY"}
                                  onChange={(date, dateString) => {
                                    if (
                                      date <= moment() &&
                                      date !== null &&
                                      date >= moment("2000-01-01")
                                    ) {
                                      props.setFieldValue(
                                        "oldLotEndDate",
                                        date
                                      );
                                    }
                                  }}
                                />
                              </Row>
                            </Col>
                          )}
                      </Row>
                      {/* Get Last Lot */}
                      {picketData != null && (
                        <LotHistory lotHistories={picketLotHistories || []} />
                      )}
                      {/* Set Picket Observation */}
                      {props.values.id != null && (
                        <Row type="flex" align="middle" className="rowInput">
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row>
                              <label>
                                {translation.picket.form.observation}
                              </label>
                            </Row>
                            <Row className="rowInput">
                              <Input
                                value={props.values.observation || undefined}
                                placeholder={translation.defaultPlaceholder}
                                style={{
                                  width: "100%",
                                }}
                                onChange={(e) =>
                                  props.setFieldValue(
                                    "observation",
                                    e.target.value
                                  )
                                }
                              />
                            </Row>
                          </Col>
                        </Row>
                      )}
                    </Fragment>
                  )}
                  {props.values.lotId != null &&
                    listOfLots != null &&
                    listOfLots.find((l) => l.id === props.values.lotId) !=
                      null &&
                    listOfLots.find((l) => l.id === props.values.lotId)
                      .profitCenterId !== props.values.profitCenterId && (
                      <AlertCard>
                        <Icon
                          type="exclamation-circle"
                          theme="filled"
                          style={{ color: "#d44c4c" }}
                          className="iconAlert"
                        />
                        <span>
                          {translation.picket.alertLotWithProfitCenterDifferent}
                        </span>
                      </AlertCard>
                    )}
                </div>
                {/* Footer */}
                <Footer>
                  <Row type="flex" justify="space-between">
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="selectDiv"
                    >
                      {props.values.id === null ? (
                        <Select
                          value={this.state.saveOptions}
                          name="saveOptions"
                          onChange={(value) =>
                            this.setState({ saveOptions: value })
                          }
                        >
                          <Option key="1" value="only_save">
                            {translation.picket.saveOptions.only_save}
                          </Option>
                          <Option key="2" value="save_and_create_new">
                            {translation.picket.saveOptions.save_and_create_new}
                          </Option>
                          <Option key="3" value="save_and_add_lot">
                            {translation.picket.saveOptions.save_and_add_lot}
                          </Option>
                        </Select>
                      ) : null}
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="buttonsDiv"
                    >
                      <ButtonStandard
                        type="button"
                        buttonType="type7"
                        onClick={this.closeDrawer}
                      >
                        {translation.buttons.cancel}
                      </ButtonStandard>

                      <ButtonStandard type="submit" buttonType="type6">
                        {translation.buttons.save}
                      </ButtonStandard>
                    </Col>
                  </Row>
                </Footer>
              </form>
            </Spin>
          )}
        />
        <DrawerPicketSustenanceCapacity
          drawerVisible={drawerPicketSustenanceCapacityVisible}
          data={sustenanceCapacityData}
          subSystem={subSystemSelected}
          onSave={this.handleSaveSustenanceCapacity}
          onCancel={this.handleCancelSustenanceCapacity}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  picket: state.picket,
  saveOptions: state.saveOptions,
});

const mapDispatchToProps = (dispatch) => ({
  picketActions: bindActionCreators(PicketActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerPicket);
