import { Statistic } from "antd";
import React from "react";
import { numberMask } from "../../../../../../../services/helpersMethodsService";
import useLotDetailsSupplementSupplyContext from "../../../../../../../hooks/useLotDetailsSupplementSupplyContext";

// import { Container } from './styles';

function CardTotalCostSupplementSupplyDashboard() {
  const { totalCost } = useLotDetailsSupplementSupplyContext();
  return (
    <Statistic
      title="Custo total apropriado"
      value={numberMask(totalCost, true)}
    />
  );
}

export default CardTotalCostSupplementSupplyDashboard;
