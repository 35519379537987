import React from "react";
import { Row, Col } from "antd";
/* Redux libs */
// import { useSelector } from "react-redux";

import { Title, Message, AlertModal, ButtonSession } from "./styles";
import WarningIcon from "../../utils/icons/warning";

const SimpleMessageModal = ({
  title,
  message,
  buttonText,
  show,
  width,
  icon,
  actionConfirm,
}) => {
  // const { translation } = useSelector((state) => state.app);

  return (
    <AlertModal
      visible={show}
      width={width != null ? width : 468}
      footer={null}
      centered
      maskClosable={false}
      closable={false}
    >
      <Row justify="start">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Title>
            {icon != null ? icon : <WarningIcon />}
            <strong>{title}</strong>
          </Title>
        </Col>
      </Row>
      <Row justify="start" style={{ marginTop: 8 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Message>{message}</Message>
        </Col>
      </Row>
      <Row justify="start" style={{ marginTop: 21 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} align="right">
          <ButtonSession onClick={actionConfirm}>{buttonText}</ButtonSession>
        </Col>
      </Row>
    </AlertModal>
  );
};

export default SimpleMessageModal;
