import styled from "styled-components";
import { Popconfirm } from "antd";

export const Container = styled.div`
  padding: 0px 10px 10px 10px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .headerButtonsAndMessagens {
    margin-bottom: 15px;
  }

  .buttonsCol {
    button {
      &:first-of-type {
        margin-right: 19px;
      }
    }
  }
  .iconBack {
    color: #d44c4c;
    cursor: pointer;
  }
`;

export const Body = styled.div`
  min-height: 90%;
  padding: 5px 20px;
  overflow-y: auto;
  .ant-select {
    width: 100%;
  }

  .colName {
    .ant-row:not(:first-of-type),
    .ant-row-flex:not(:first-of-type) {
      margin: 15px 0px;
    }
    .rowSlider {
      margin: 10px 0px !important;
    }
    .cardGrey {
      margin-top: 13px;
      padding: 15px 13px;
      border-radius: 5px !important;
      background: #f5f5f5 !important;

      label {
        font-family: Asap;
        font-weight: bold;
        font-size: 12px;
        text-align: left;
        color: #a5a5a5;
        margin: 0px !important;
        &.default {
          color: #fe8d2a;
        }
      }
    }
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    margin-bottom: 7px;
    &.error {
      color: #d44c4c;
    }
  }
`;

export const Header = styled.div`
  span {
    font-family: Asap, sans-serif;
    font-size: 14px;
    color: #a5a5a5;
    &.title {
      margin-left: 30px;
      font-size: 18px;
      font-weight: bold;
      color: #4b4b4b !important;
    }
  }
  .backgroundGray {
    border-radius: 5px;
    background: #fcfcfc;
    padding: 10px 20px;

    span:not(:first-of-type) {
      margin-left: 35px;
    }
  }
`;

export const ChartContainer = styled.div`
  height: 360px;
  margin-top: 16px !important;
`;

export const ListPeriodsContainer = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  flex-wrap: nowrap;
  background: transparent;
  overflow-x: auto;
`;
export const ListPeriodsTitleContainer = styled.div`
  display: flex;
  background: #4b4b4b;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  align-items: center;
  justify-content: center;
  padding: 0px 30px;
  span {
    font-family: Asap;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    color: #fff;
  }
`;

export const ListPeriodsItemContainer = styled.div`
  flex: 1;
  display: flex;
  margin-left: 5px;
  border-right: 5px solid #c6de52;
  white-space: nowrap;
  flex-wrap: nowrap;
  background: #4b4b4b;
  overflow-x: auto;
  padding: 0px 10px;

  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
    overflow-x: scroll;
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
    overflow-x: scroll;
  }
`;

export const ListPeriodsItem = styled.div`
  display: inline-flex;
  width: 100px;
  max-width: 100px;
  height: 88px;
  max-height: 88px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 15px;
  padding: 10px;
  transition: all 0.2s ease-in-out;

  &.selected {
    background: #a9c133;
    span.info {
      font-family: Asap;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: #fff;
    }
  }

  &:first-of-type {
    margin-left: 15px;
  }

  &:hover {
    cursor: pointer;
    background: #f5f5f5;
    .time {
      span {
        color: #4b4b4b !important;
      }
    }
  }
  div.itemContainer {
    width: 100%;
    display: flex;
    flex-direction: column;

    div.iconContainer {
      width: 100%;

      svg {
        margin-right: 5px;
      }
    }

    div.timeContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      div.time {
        width: 100%;
        display: flex;
        flex-direction: column;
        span {
          font-family: Asap;
          font-weight: normal;
          font-size: 14px;
          text-align: left;
          color: #a5a5a5;
        }
      }

      div.circle {
        margin-left: auto;
        margin-bottom: 5px;
        width: 7.38px;
        height: 7.38px;
        border-radius: 50%;
        background: #a9c133;

        &.red {
          background: #d44c4c;
        }
      }
    }

    div.icons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      #bovexoAlertIcon {
        margin-right: 5px;
      }
      div.circle {
        width: 7.38px;
        height: 7.38px;
        border-radius: 50%;
        background: #a9c133;

        &.red {
          background: #d44c4c;
        }
      }
    }
  }
`;

export const ListPeriodsItemPlus = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  max-width: 100px;
  height: 88px;
  max-height: 88px;
  border-radius: 5px;
  background: #fff;
  border: 2px solid #9074bf;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 15px;
  padding: 20px 10px 10px 10px;
  transition: all 0.2s ease-in-out;
  position: relative;

  span {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #707070;
  }

  svg {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const PopConfirmCustom = styled(Popconfirm)`
  &.ant-popover-buttons {
    display: flex !important;
    justify-content: space-between !important;
  }

  &.ant-btn-primary {
    padding-left: 180px !important;
    background: #4a85ae;
  }
`;
