import React from "react";

const PaymentIcon = () => (
    <svg id="bovexoPaymentIcon" width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5137 8.29355V7.60968C13.0882 7.5981 12.6704 7.49263 12.2903 7.30081L12.5057 6.45807C12.872 6.66032 13.2825 6.76917 13.7008 6.775C14.1105 6.775 14.392 6.61371 14.392 6.32903C14.392 6.05484 14.1613 5.88226 13.6291 5.70242C12.8589 5.44274 12.3331 5.08307 12.3331 4.38468C12.3438 4.05932 12.4735 3.7492 12.6977 3.51314C12.9218 3.27709 13.2248 3.13151 13.5492 3.10403V2.41936H14.2549V3.05323C14.617 3.05821 14.9742 3.13897 15.3032 3.29032L15.0936 4.10403C14.7708 3.94636 14.4165 3.86366 14.0573 3.8621C13.5895 3.8621 13.4387 4.06371 13.4387 4.26533C13.4387 4.50242 13.6903 4.65323 14.3024 4.88387C15.1589 5.18629 15.5041 5.58226 15.5041 6.23065C15.4931 6.57182 15.356 6.89682 15.1193 7.14276C14.8826 7.38871 14.5631 7.53812 14.2226 7.5621V8.29597L13.5137 8.29355Z" fill="#4B4B4B" />
        <circle cx="13.9033" cy="5.24194" r="4.74194" stroke="#4B4B4B" />
        <path d="M1 4.03226V25H12.2903L15.5161 21.371V10.0806C14.9785 10.4839 13.0968 10.7143 11.0806 9.27419C9.06452 7.8341 8.7957 4.97312 9.06452 4.03226H1Z" stroke="#4B4B4B" />
        <path d="M3.41937 9.2742H8.25808" stroke="#4B4B4B" strokeLinecap="round" />
        <path d="M3.41937 11.2903H8.25808" stroke="#4B4B4B" strokeLinecap="round" />
        <path d="M3.41937 15.3226H10.6774" stroke="#4B4B4B" strokeLinecap="round" />
        <path d="M3.41937 17.7419H13.9032" stroke="#4B4B4B" strokeLinecap="round" />
        <path d="M11.8871 25V21.371H15.5161" stroke="#4B4B4B" strokeLinecap="round" />
    </svg>
);

export default PaymentIcon;