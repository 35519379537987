import React, { useState, useEffect } from "react";
import { Async } from "react-async";
/** Redux libs */
import { useSelector, useDispatch } from "react-redux";
import { Creators as SaleScenarioActions } from "../../../../../store/ducks/saleScenario";
/** Components */
import {
  Container,
  ColSelectLots,
  ColButtons,
  ColLotsSelected,
} from "./styles";
import { Row, Col, List, Checkbox, Icon, Table } from "antd";
/** Services */
import {
  getLotsForSaleScenario,
  getLotStatisticsAsync,
} from "../../../../../services/lotService";
import { getDietStrategyIndex } from "../../../../../services/dietStrategyService";
import { validateDietStrategyValidation } from "../../../../../services/helpersMethodsService";

const TabSelectLots = () => {
  /** Variables */
  const [loadingTable, setLoadingTable] = useState(true);
  const [allLots, setAllLots] = useState(null);
  const [allDietStrategies, setAllDietStrategies] = useState([]);
  const [lotsSelected, setLotsSelected] = useState([]);
  const [lotsSelectedKeys, setLotsSelectedKeys] = useState([]);
  const [lotsSelectForLotProduction, setLotsSelectForLotProduction] = useState(
    []
  );
  const [lotsSelectForLotProductionKeys, setLotsSelectForLotProductionKeys] =
    useState([]);
  const [lotsToRemoveKeys, setLotsToRemoveKeys] = useState([]);
  const [selectAllLotsForProduction, setSelectAllLotsForProduction] =
    useState(false);
  /** Redux variables */
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  /** Effect  */
  useEffect(() => {
    function findBaselineFromLotDietStrategies(lotDietStrategies) {
      return lotDietStrategies.find((l) => l.baseline === true) != null
        ? lotDietStrategies.find((l) => l.baseline === true).dietStrategyId
        : null;
    }
    async function fetchData(page = 0, sorter = {}, size = 10) {
      let search = ``;
      try {
        const {
          data: { results: lotResults },
        } = await getLotsForSaleScenario({
          groupId,
          farmId,
          page,
          sorter,
          filters: search,
          size,
        });
        const {
          data: { results: rAllDietStrategies },
        } = await getDietStrategyIndex({
          groupId,
          farmId,
          withoutPagination: true,
        });
        let newContent = [];
        lotResults.content.forEach((l) => {
          const dietStrategyBaseLineId = findBaselineFromLotDietStrategies(
            l.dietStrategies
          );
          let newDietStrategies = [];
          rAllDietStrategies.forEach((ds) => {
            let newDs = { ...ds };
            if (
              newDs.status === "Active" &&
              (newDs.lot?.id === l.id ||
                (newDs.lot === null && newDs.breedId === l.breedId)) &&
              !validateDietStrategyValidation(newDs)
            ) {
              if (newDs.id === dietStrategyBaseLineId) {
                newDs.baseline = true;
              } else {
                newDs.baseline = false;
              }
              newDs.newKey = `${l.id} - ${newDs.id}`;
              newDietStrategies.push(newDs);
            }
          });
          l.dietStrategies = newDietStrategies;
          newContent.push(l);
        });
        setAllDietStrategies(rAllDietStrategies);
        setAllLots({
          ...lotResults,
          content: newContent,
        });
        setLoadingTable(false);
      } catch (error) {}
    }
    fetchData();
  }, [groupId, farmId]);

  /** Methods */
  function findBaselineFromLotDietStrategies(lotDietStrategies) {
    return lotDietStrategies.find((l) => l.baseline === true) != null
      ? lotDietStrategies.find((l) => l.baseline === true).dietStrategyId
      : null;
  }
  async function fetchData(page = 0, sorter = {}, filter = "", size = 10) {
    let search = `${filter};`;
    setLoadingTable(true);
    try {
      const {
        data: { results: lotResults },
      } = await getLotsForSaleScenario({
        groupId,
        farmId,
        page,
        sorter,
        filters: search,
        size,
      });
      let newContent = [];
      lotResults.content.forEach((l) => {
        const dietStrategyBaseLineId = findBaselineFromLotDietStrategies(
          l.dietStrategies
        );
        let newDietStrategies = [];
        allDietStrategies.forEach((ds) => {
          let newDs = { ...ds };
          if (
            newDs.status === "Active" &&
            (newDs.lot?.id === l.id ||
              (newDs.lot === null && newDs.breedId === l.breedId)) &&
            !validateDietStrategyValidation(newDs)
          ) {
            if (newDs.id === dietStrategyBaseLineId) {
              newDs.baseline = true;
            } else {
              newDs.baseline = false;
            }
            newDs.newKey = `${l.id} - ${newDs.id}`;
            newDietStrategies.push(newDs);
          }
        });
        l.dietStrategies = newDietStrategies;
        newContent.push(l);
      });
      setAllLots({
        ...lotResults,
        content: newContent,
      });
      setLoadingTable(false);
    } catch (error) {
      setAllLots(null);
    }
  }
  // Method to pass animal from left to right (Add) and right to left (Remove)
  function handleMoveLots(action) {
    switch (action) {
      case "add":
        setLotsSelectForLotProduction(lotsSelected);
        setLotsSelectForLotProductionKeys(lotsSelectedKeys);
        setSelectAllLotsForProduction(false);
        dispatch(SaleScenarioActions.addOrRemoveLots(lotsSelected));
        break;
      case "remove":
        let removedLotsSelectForLotProduction =
          lotsSelectForLotProduction.filter(
            (obj) => !lotsToRemoveKeys.includes(obj.id)
          );
        let removedLotsSelectForLotProductionKeys =
          lotsSelectForLotProductionKeys.filter(
            (obj) => !lotsToRemoveKeys.includes(obj)
          );
        let removedSelectedLots = lotsSelected.filter(
          (obj) => !lotsToRemoveKeys.includes(obj.id)
        );
        let removedSelectedLotsKeys = lotsSelectedKeys.filter(
          (obj) => !lotsToRemoveKeys.includes(obj)
        );
        setLotsSelectForLotProduction(removedLotsSelectForLotProduction);
        setLotsSelectForLotProductionKeys(
          removedLotsSelectForLotProductionKeys
        );
        setLotsSelected(removedSelectedLots);
        setLotsSelectedKeys(removedSelectedLotsKeys);
        setLotsToRemoveKeys([]);
        setSelectAllLotsForProduction(false);
        dispatch(
          SaleScenarioActions.addOrRemoveLots(removedLotsSelectForLotProduction)
        );
        break;
      default:
        break;
    }
  }

  // Method to check animal in right list
  function handleSelectLotsForProduction(e) {
    if (e.target.checked)
      setLotsToRemoveKeys([...lotsToRemoveKeys, e.target.value]);
    else
      setLotsToRemoveKeys(lotsToRemoveKeys.filter((i) => i !== e.target.value));
  }

  // Method to select all animals in right list
  function handleSelectAllLotsForProduction(e) {
    setSelectAllLotsForProduction(e.target.checked);
    if (e.target.checked) setLotsToRemoveKeys(lotsSelectForLotProductionKeys);
    else setLotsToRemoveKeys([]);
  }

  /** Table methods - start */
  async function handleTableLotsChange(pagination, filters, sorter) {
    let search = "";
    setLoadingTable(true);
    if (Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v.length > 0)
          search = search === "" ? `${k}='${v}'` : `${search};${k}='${v}'`;
      });
    }
    await fetchData(pagination.current, sorter, search, pagination.pageSize);
  }
  /** Table methods - end */

  /* Table variables */
  const rowSelection = {
    selectedRowKeys: lotsSelectedKeys,
    getCheckboxProps: (record) => ({
      disabled: lotsSelectForLotProductionKeys.includes(record.id),
    }),
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      if (selected) {
        setLotsSelected([...lotsSelected, record]);
        setLotsSelectedKeys([...lotsSelectedKeys, record.id]);
      } else {
        setLotsSelected(lotsSelected.filter((o) => o.id !== record.id));
        setLotsSelectedKeys(lotsSelectedKeys.filter((r) => r !== record.id));
      }
    },
    onSelectAll: (record, selected, selectedRows, nativeEvent) => {
      // Get just the ids
      const keys = selectedRows.map((sr) => sr.id);
      // Get all the selectedLots unless the unselected
      const lastSelectedLots = lotsSelected.filter(
        (sA) => !selectedRows.includes(sA)
      );
      // Get all the selectedLotsKeys unless the unselected
      const lastSelectedLotsKeys = lotsSelectedKeys.filter(
        (sAK) => !keys.includes(sAK)
      );

      if (selected.length > 0) {
        setLotsSelected(
          lotsSelected.length > 0
            ? lastSelectedLots
            : [...lotsSelected, ...selectedRows]
        );
        setLotsSelectedKeys(
          lotsSelectedKeys.length > 0
            ? lastSelectedLotsKeys
            : [...lotsSelectedKeys, ...keys]
        );
      } else {
        setLotsSelected(lastSelectedLots);
        setLotsSelectedKeys(lastSelectedLotsKeys);
      }
    },
  };
  const TableLots = (
    <Table
      key="recpt"
      rowKey="id"
      loading={loadingTable}
      dataSource={allLots != null ? allLots.content : []}
      pagination={{
        total: allLots != null ? allLots.totalElements : 0,
        size: allLots != null ? allLots.size : 0,
        current: allLots != null ? allLots.number + 1 : 0,
        hideOnSinglePage:
          allLots !== null && Object.entries(allLots).length !== 0
            ? allLots.totalElements > 10
              ? false
              : true
            : true,
      }}
      scroll={{
        x: true,
      }}
      size="small"
      rowSelection={rowSelection}
      onChange={handleTableLotsChange}
    >
      <Table.Column title="Nome" dataIndex="name" key="name" />
      <Table.Column
        title="Qtd."
        render={(text, record) => (
          <Async
            promiseFn={getLotStatisticsAsync}
            payload={{
              groupId,
              farmId,
              id: record.id,
              statistics: "AmountActiveAnimals",
            }}
          >
            <Async.Pending>
              <Icon type="loading" />
            </Async.Pending>
            <Async.Fulfilled>
              {(resp) => <span>{`${resp.data.results} animais`}</span>}
            </Async.Fulfilled>
          </Async>
        )}
      />
      <Table.Column
        title="Idade"
        render={(text, record) => (
          <Async
            promiseFn={getLotStatisticsAsync}
            payload={{
              groupId,
              farmId,
              id: record.id,
              statistics: "AvgAgeInMonthsActiveAnimals",
            }}
          >
            <Async.Pending>
              <Icon type="loading" />
            </Async.Pending>
            <Async.Fulfilled>
              {(resp) => (
                <span>{`${resp.data.results?.toFixed(0) || 0} meses`}</span>
              )}
            </Async.Fulfilled>
          </Async>
        )}
      />
      <Table.Column
        title="Peso médio"
        render={(text, record) => (
          <Async
            promiseFn={getLotStatisticsAsync}
            payload={{
              groupId,
              farmId,
              id: record.id,
              statistics: "AvgWeightActiveAnimals",
            }}
          >
            <Async.Pending>
              <Icon type="loading" />
            </Async.Pending>
            <Async.Fulfilled>
              {(resp) => (
                <span>{`${resp.data.results?.toFixed(2) || 0} Kg`}</span>
              )}
            </Async.Fulfilled>
          </Async>
        )}
      />
    </Table>
  );

  return (
    <Container>
      <Row type="flex" className="pageBody">
        {/* Col with animals table */}
        <Col xs={24} sm={24} md={24} lg={14} xl={14}>
          <ColSelectLots>{TableLots}</ColSelectLots>
        </Col>
        {/* Col with buttons */}
        <Col xs={24} sm={24} md={24} lg={2} xl={2}>
          <ColButtons>
            <button
              className={
                lotsSelectedKeys.length > lotsSelectForLotProductionKeys.length
                  ? "active"
                  : ""
              }
              onClick={() => handleMoveLots("add")}
              disabled={
                lotsSelectedKeys.length > lotsSelectForLotProductionKeys.length
                  ? false
                  : true
              }
            >
              <Icon type="right" />
            </button>
            <button
              className={lotsToRemoveKeys.length > 0 ? "active" : ""}
              onClick={() => handleMoveLots("remove")}
              disabled={lotsToRemoveKeys.length > 0 ? false : true}
            >
              <Icon type="left" />
            </button>
          </ColButtons>
        </Col>
        {/* Col with list with animals selected */}
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <ColLotsSelected>
            <div className="header">
              <Checkbox
                style={{
                  color: "#FFF",
                  fontSize: "14px",
                  fontWeight: "bold",
                  fontFamily: "Asap",
                }}
                onChange={handleSelectAllLotsForProduction}
                disabled={lotsSelectForLotProduction.length > 0 ? false : true}
                checked={selectAllLotsForProduction}
              >
                Lotes selecionados
              </Checkbox>
              <span>{`${lotsSelectForLotProduction.length} lotes`}</span>
            </div>
            <Row type="flex" style={{ width: "100%" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <List
                  itemLayout="horizontal"
                  dataSource={lotsSelectForLotProduction}
                  renderItem={(item) => (
                    <List.Item>
                      <Checkbox
                        style={{
                          marginLeft: "12px",
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "Asap",
                        }}
                        key={item.id}
                        value={item.id}
                        onChange={handleSelectLotsForProduction}
                        checked={
                          lotsToRemoveKeys.includes(item.id) ? true : false
                        }
                      >
                        {item.name}
                      </Checkbox>
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </ColLotsSelected>
        </Col>
      </Row>
    </Container>
  );
};

export default TabSelectLots;
