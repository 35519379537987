import React, { useState, useEffect } from "react";
import Axios from "axios";
/** Redux libs */
import { Creators as GeneralParameterActions } from "../../../store/ducks/generalParameters";
import { useSelector, useDispatch } from "react-redux";
/** Components */
import { Container, Title, Message, CardMessage, EditButton } from "./styles";
import { Row, Col, Spin, notification } from "antd";
import PencilIcon from "../../../components/utils/icons/pencil";
import DisplayCarbonFootprintParameter from "./display";
import EditCarbonFootprintParameter from "./edit";
/** Services */
import {
  saveOrUpdateParameter,
  getParameterItems,
} from "../../../services/generalParameterService";

const CarbonFootprintParameter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [parameterData, setParameterData] = useState(null);

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const { parameterBeingEdit } = useSelector(
    (state) => state.generalParameters
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const signal = Axios.CancelToken.source();
    async function fetchData() {
      setIsLoading(true);
      let dataResult = [];
      try {
        let codes = [1006, 1007];
        for (var i = 0; i < codes.length; i++) {
          let code = codes[i];
          let {
            data: { results },
          } = await getParameterItems({ groupId, farmId, code, signal });
          results.isDefault = results.farmId == null && results.groupId == null;
          results.updated = false;
          dataResult.push(results);
        }
        setParameterData(dataResult);
        setIsLoading(false);
      } catch (error) {}
    }
    fetchData();
    return () => {
      signal.cancel();
    };
  }, [groupId, farmId]);

  async function saveEdit(data) {
    dispatch(GeneralParameterActions.changeParameterBeingEdited(null));
    setEditing(false);
    setIsLoading(true);
    try {
      for (var i = 0; i < data.length; i++) {
        let parameter = data[i];
        if (parameter.updated) {
          let {
            data: { results },
          } = await saveOrUpdateParameter({
            groupId,
            farmId,
            parameterItemId: parameter.id,
            parameterId: parameter.parameterId,
            edit: !parameter.isDefault,
            body: parameter,
          });
          results.code = parameter.code;
          results.parameterId = parameter.parameterId;
          results.isDefault = false;
          results.updated = false;
          data[i] = results;
        }
      }
      setParameterData(data);
      notification.success({
        message: translation.generalParameters.successParameterUpdated,
      });
    } catch (error) {
      notification.error({
        message: translation.generalParameters.errorParameterUpdated,
      });
    } finally {
      setIsLoading(false);
    }
  }

  function cancelEdit() {
    dispatch(GeneralParameterActions.changeParameterBeingEdited(null));
    setEditing(false);
  }

  function openEdit() {
    dispatch(
      GeneralParameterActions.changeParameterBeingEdited(
        "carbonFootPrintParameter"
      )
    );
    setEditing(true);
  }

  return (
    <Container>
      <Row type="flex" justify="space-between">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Title>Pegada Carbônica</Title>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
          <EditButton
            type="button"
            onClick={openEdit}
            disabled={
              isEditing ||
              (parameterBeingEdit !== null &&
                parameterBeingEdit !== "carbonFootPrintParameter")
                ? true
                : false
            }
          >
            {isEditing
              ? `${translation.buttons.editing}...`
              : `${translation.buttons.edit}`}
            <PencilIcon />
          </EditButton>
        </Col>
      </Row>
      {isEditing ? (
        <EditCarbonFootprintParameter
          data={parameterData}
          onSave={saveEdit}
          onCancel={cancelEdit}
        />
      ) : (
        <Spin spinning={isLoading}>
          {parameterData == null ? (
            <CardMessage>
              <Row type="flex" justify="center" align="middle">
                <Message>
                  Clique em <span className="red">"editar"</span>
                </Message>
              </Row>
            </CardMessage>
          ) : (
            <DisplayCarbonFootprintParameter data={parameterData} />
          )}
        </Spin>
      )}
    </Container>
  );
};

export default CarbonFootprintParameter;
