import styled from "styled-components";
import { Input, Radio, List, Divider, Drawer } from "antd";

export const Container = styled(Drawer)`
  overflow-y: hidden;

  div.inputStrong {
    color: #9074bf;
    font-size: 14px;
    font-weight: normal;
    display: flex;
    align-items: center;
    & > * {
      margin-left: 8px;
    }

    .ant-switch-checked {
      background-color: #a9c133;
    }
    .ant-switch-loading-icon,
    .ant-switch-checked::after {
      left: 110% !important;
      top: 0px;
      width: 20px;
      height: 20px;
      margin-left: -1px;
      -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    .ant-switch-loading-icon,
    .ant-switch::after {
      position: absolute;
      top: 0px;
      left: 0;
      width: 20px;
      height: 20px;
      border-radius: 15px;
      cursor: pointer;
      -webkit-transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      content: " ";
    }
  }

  .customCol {
    min-height: 600px;
    overflow-y: auto;

    &.colParam {
      display: flex;
      flex-direction: column;
      overflow-y: hidden;

      .ant-row {
        margin-top: 8px;
        width: 100%;
      }
      .ant-row-flex {
        margin-top: 8px;
        width: 100%;
      }

      &.editing {
        p.emptySaleCommitments {
          text-align: center;
          margin: 0;
        }

        button.addSaleCommitment {
          background: transparent;
          border: none;
          font-family: Asap;
          font-weight: bold;
          font-size: 14px;
          text-align: center;
          color: #4a85ae;
          cursor: pointer;
          margin-left: 15px;
          svg {
            margin-right: 10px;
            path {
              fill: #4a85ae;
            }
          }
        }

        span.errorMessage {
          color: #d44c4c;
          font-size: 14px;
          font-weight: bold;
          font-family: "Asap", sans-serif;
        }

        label {
          color: #9074bf;
          font-size: 14px;
          font-weight: bold;
          margin-left: 15px;

          &.labelDays {
            margin-left: 0px;
          }

          &.error {
            color: #d44c4c;
          }

          &.alert {
            color: #fe8d2a;
          }

          svg {
            margin-left: 5px;
          }
        }
      }

      &.viewing {
        .viewHeader {
          padding: 10px 20px;
          border-bottom: 1px solid #e8e5e5;
        }
        button.edit {
          background: transparent;
          border: none;
          font-family: Asap;
          font-weight: bold;
          font-size: 12px;
          text-align: center;
          color: #4a85ae;
          text-transform: uppercase;
          cursor: pointer;
          svg {
            margin-left: 5px;
            path {
              fill: #4a85ae;
            }
          }
        }
        p {
          margin-bottom: 0px;
          &:not(:first-of-type) {
            margin-top: 5px;
          }
        }
        span.titleNameView {
          font-family: Asap;
          font-weight: bold;
          font-size: 14px;
          text-align: left;
          color: #684e94;
        }
        div.body {
          padding: 0 20px;
        }
        label {
          font-family: Asap;
          font-weight: normal;
          font-size: 14px;
          text-align: left;
          color: #4b4b4b;
        }
      }
    }
  }
`;

export const CustomInput = styled(Input)`
  border-color: #684e94 !important;
  &.ant-input.error,
  &.ant-input.error:hover,
  &.ant-input.error:active {
    border-color: #d44c4c !important;
  }
  &.ant-input:hover,
  &.ant-input:active {
    border-color: #684e94 !important;
  }
  &.ant-input::-webkit-input-placeholder {
    /* Edge */
    color: #9074bf !important;
    &.error {
      color: #d44c4c !important;
    }
  }

  &.ant-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #9074bf !important;
    &.error {
      color: #d44c4c !important;
    }
  }

  &.ant-input::placeholder {
    color: #9074bf !important;
    &.error {
      color: #d44c4c !important;
    }
  }
`;

export const RadioButtonCustom = styled(Radio.Button)`
  margin-top: 6px !important;
  border-radius: 15px !important;
  background: transparent !important;
  border: 1px solid #4b4b4b !important;
  border-color: #4b4b4b !important;

  span {
    font-family: Asap !important;
    font-weight: normal !important;
    font-size: 14px !important;
    text-align: left !important;
    color: #4b4b4b !important;
  }

  &:first-of-type {
    margin-left: 15px;
  }

  &:not(:first-of-type) {
    margin-left: 4px;
  }

  &.ant-radio-button-wrapper-disabled {
    border-color: #c4c4c4 !important;
    span {
      color: #c4c4c4 !important;
    }
  }

  &.ant-radio-button-wrapper-checked {
    &:first-child,
    &:not(:first-child) {
      border-color: #4b4b4b !important;
    }
    color: #fff !important;
    background: #4b4b4b !important;
    border-color: #4b4b4b !important;

    &.ant-radio-button-wrapper-disabled {
      border-color: #c4c4c4 !important;
      background: #c4c4c4 !important;
    }

    span {
      font-family: Asap !important;
      font-weight: normal !important;
      font-size: 14px !important;
      text-align: left !important;
      color: #fff !important;
    }
  }
  &:not(:first-child)::before {
    visibility: hidden !important;
  }
`;

export const ListSaleCommitments = styled(List)`
  margin-left: 15px !important;
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #4b4b4b;

  .ant-list-item {
    border-bottom: 1px solid #e8e5e5 !important;
    padding-bottom: 10px;
  }
  .delete {
    color: #d44c4c;
    margin-left: 7px;
  }
  .buttonGroup {
    display: flex;
    justify-content: center;
    svg {
      cursor: pointer;
    }
  }
`;

export const CustomDivider = styled(Divider)`
  margin: 10px 0 !important;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 10px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;
export const Footer = styled.div`
  border-top: 1px solid #e8e8e8;
  margin-top: 20px;
  width: 100%;
  padding: 10px 24px;
  border-radius: 0 0 4px 4px;

  .buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding-right: 24px; */

    button:first-of-type {
      margin-right: 12px !important;
    }
  }

  .selectDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: "Asap", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;
