import styled from "styled-components";

export const Container = styled.div`
  .back-container {
    display: flex;
    align-items: center;
    padding: 16px 0px;
    cursor: pointer;
  }

  .back {
    position: relative;
    font-family: "Asap", sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #d44c4c;
    margin-left: 5px;
  }

  .align-center {
    text-align: center;
    align-items: center;
  }

  main {
    width: 100%;
    display: flex;
    flex-direction: column;

    table.header {
      width: 100%;
      min-height: 130px;
      background: #f5f5f5;
      padding: 8px;

      td.default-content {
        & > div {
          height: 130px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      td.center-content {
        & > table {
          width: 100%;
          tr {
            display: flex;
          }

          tr:not(:first-of-type) {
            td {
              padding: 8px 0px;
            }
          }

          td {
            width: 100%;

            &.text-align-right {
              text-align: right;
            }
          }

          h1 {
            text-align: center;
          }
        }
      }

      td.scenario-content {
        width: 200px;
        padding-right: 36px;
        text-align: left;

        table {
          width: 100%;
        }

        .farm-logo {
          width: 117px;
          height: 74px;
          text-align: center;
          align-items: center;
        }

        img {
          max-width: 117px !important;
          max-height: 74px !important;
        }

        div {
          margin-left: 16px;
          margin-bottom: 8px;
        }

        strong,
        span {
          font-family: "Asap", sans-serif;
          font-style: normal;

          &.title {
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            text-align: right;
            color: #8a50a5;
          }
          &.farm-name {
            margin-right: auto;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            color: #4b4b4b;
          }
        }
      }
    }

    table.simulation-table {
      border-spacing: 18px;

      td.simulation-container {
        padding: 8px;
        strong.title {
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
        }

        div.simulation-container-item {
          margin-top: 8px;
          .header {
            font-style: normal;
            font-weight: bold;
            font-size: 10.0174px;
            line-height: 11px;
            color: #4b4b4b;
            background: #f5f5f5;

            padding: 8px;
          }
          .content {
            padding: 4px 0;

            table {
              width: 100%;
              td {
                padding: 0px 0px 4px 0px;
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 11px;
                &.field {
                  width: 126px;
                }
                &.value {
                  text-align: right;
                }
              }
            }
          }
        }
      }
    }
  }
`;
