import { notification } from "antd";
import moment from "moment";
import { all, call, put } from "redux-saga/effects";
import { getLotShow, getLotStatistics } from "../../services/lotService";
import {
  createLotSupplementConsumption,
  getLotSupplementConsumption,
  updateLotSupplementConsumption,
  deleteLSC,
} from "../../services/lotSuplementConsumptionService";
import { Creators as LotSupplementConsumptionActions } from "../ducks/lotSupplementConsumption";

export function* getLotInfoAndLotSupplementConsumption(action) {
  const { lotId } = action.payload;

  if (lotId !== null) {
    try {
      const [
        {
          data: { results: lotData },
        },
        {
          data: { results: amountActiveAnimals },
        },
        {
          data: { results: listOfLotSupplementConsumption },
        },
      ] = yield all([
        call(getLotShow, { ...action.payload, id: lotId }),
        call(getLotStatistics, {
          ...action.payload,
          id: lotId,
          statistics: "AmountActiveAnimals",
        }),
        call(getLotSupplementConsumption, action.payload),
      ]);
      lotData.activeAnimalsAmount = amountActiveAnimals;
      listOfLotSupplementConsumption.map((lsc) => {
        lsc.consumptionDate = moment(lsc.consumptionDate).format("DD/MM/YYYY");
        return lsc;
      });
      yield put(
        LotSupplementConsumptionActions.showOrHideDrawerAndFetchSuccess(
          lotData,
          listOfLotSupplementConsumption
        )
      );
    } catch (error) {
      console.error(error);
      yield put(
        LotSupplementConsumptionActions.showOrHideDrawerAndFetchError()
      );
    }
  }
}

export function* fetchListOfSupplementConsumpiton(action) {
  try {
    const {
      data: { results: listOfLotSupplementConsumption },
    } = yield call(getLotSupplementConsumption, action.payload);
    listOfLotSupplementConsumption.map((lsc) => {
      lsc.consumptionDate = moment(lsc.consumptionDate).format("DD/MM/YYYY");
      return lsc;
    });
    yield put(
      LotSupplementConsumptionActions.fetchListOfSupplementConsumpitonSuccess(
        listOfLotSupplementConsumption
      )
    );
  } catch (error) {
    yield put(
      LotSupplementConsumptionActions.fetchListOfSupplementConsumpitonError()
    );
  }
}

export function* createOrUpdateLotSupplementConsumption(action) {
  const { id, groupId, farmId, lotId } = action.payload;
  try {
    if (id) {
      // Update
      yield call(updateLotSupplementConsumption, action.payload);
    } else {
      // Create
      yield call(createLotSupplementConsumption, action.payload);
    }
    notification.success({
      message: `Foi ${
        id ? "atualizado" : "criado"
      } o registro do consumo de Suplemento/Concent.`,
    });
    yield all([
      put(LotSupplementConsumptionActions.createOrUpdateSuccess()),
      put(
        LotSupplementConsumptionActions.fetchListOfSupplementConsumpiton(
          groupId,
          farmId,
          lotId
        )
      ),
    ]);
  } catch ({ response: error }) {
    if (error.data.message.indexOf("date") !== -1) {
      notification.error({
        message: `Error ao ${
          id ? "atualizar" : "criar"
        } o registro. Já existe um outro registro deste lote com a mesma data.`,
      });
    } else {
      notification.error({
        message: `Error ao ${
          id ? "atualizar" : "criar"
        } o registro. Contate o suporte`,
      });
    }
    yield put(LotSupplementConsumptionActions.createOrUpdateError());
  }
}

export function* deleteLotSupplementConsumption(action) {
  const { groupId, farmId, lotId } = action.payload;
  try {
    yield call(deleteLSC, action.payload);
    notification.success({
      message: `Foi deletado o registro do consumo de Suplemento/Concent.`,
    });
    yield all([
      put(LotSupplementConsumptionActions.deleteSuccess()),
      put(
        LotSupplementConsumptionActions.fetchListOfSupplementConsumpiton(
          groupId,
          farmId,
          lotId
        )
      ),
    ]);
  } catch (error) {
    notification.success({
      message: `Não foi possível deletar o registro do consumo de Suplemento/Concent.. Contate o suporte`,
    });
    yield put(LotSupplementConsumptionActions.deleteError());
  }
}
