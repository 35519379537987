import styled from "styled-components";
import { Divider, Tabs } from "antd";

export const Container = styled.div`
  width: 100%;
  min-width: 100%;
  margin-top: 10px;

  .ant-row-flex {
    width: 100%;
    margin-top: 4px;
  }

  label {
    font-family: Asap;
    text-align: left;
    font-size: 14px;
    &.grey {
      font-weight: normal;
      font-size: 14px;
      color: #a5a5a5;
    }
    &.name {
      font-weight: bold;
      font-size: 18px;
      color: #684e94;
    }
    &.nameElement {
      font-weight: bold;
      font-size: 14px;
      color: #684e94;
    }
    &.propertyType {
      font-weight: normal;
      font-size: 16px;
      color: #a9c133;
      svg {
        margin-right: 6px;
      }
    }
    &.value {
      font-style: italic;
      font-size: 14px;
      color: #4b4b4b;
    }
    &.valueBold {
      font-weight: bold;
      font-style: italic;
      font-size: 14px;
      color: #4b4b4b;
    }
  }

  .timeline-title {
    display: block;
    font-size: 14px;
    font-weight: bold;
  }

  .timeline-detail {
    display: block;
    font-size: 12px;
    font-weight: normal;
  }
`;

export const Body = styled.div`
  width: 100%;
  padding: 0px 24px;
  min-height: -moz-calc(100vh - 165px);
  min-height: -webkit-calc(100vh - 165px);
  min-height: calc(100vh - 165px);

  overflow-y: auto;
`;

export const TagStatus = styled.span`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
  padding: 2px 5px !important;
  border: ${(props) => `1px solid ${props.borderColor}`};
  border-radius: 5px !important;
  color: ${(props) => props.color}!important;
  font-size: 12px !important;
  font-weight: bold !important;
  background: ${(props) => props.background} !important;
`;

export const CustomDivider = styled(Divider)`
  margin: 10px 0 !important;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 10px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;

export const ButtonSustenanceCapacity = styled.button`
  background: transparent;
  border: none;
  font-family: Asap;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #fe8d2a;
  cursor: pointer;
`;

export const TabContainer = styled(Tabs)`
  .ant-tabs-ink-bar {
    background-color: #684e94;
    height: 5px;
  }

  .ant-tabs-line {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab {
    font-family: "Asap" !important;
    font-size: 14px !important;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab-disabled,
  .ant-tabs-nav .ant-tabs-tab-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: #4b4b4b;
    font-weight: bold;
  }

  .ant-tabs-bar {
    border-bottom: 1px solid #a9c133;
  }

  .ant-tabs-tabpane {
    background-color: #fff;
    margin-top: -15px;
  }
`;
