import React from "react";

const ImportsIcon = () => (
  <svg
    id="bovexoImportsIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="19.957"
    height="20.999"
    viewBox="0 0 19.957 20.999"
  >
    <g id="multimedia-option" transform="translate(-8.997 -7.999)">
      <path
        id="Path_2068"
        data-name="Path 2068"
        d="M30.482,30.572a.831.831,0,0,0,.58-1.427l-2.73-2.659a1.662,1.662,0,0,0-2.344-.007L23.25,29.145a.831.831,0,1,0,1.16,1.191l2.754-2.667L29.9,30.336A.828.828,0,0,0,30.482,30.572Z"
        transform="translate(-8.18 -10.516)"
        fill="#4b4b4b"
      />
      <path
        id="Path_2070"
        data-name="Path 2070"
        d="M14.44,26.867a.831.831,0,0,0,.757-1.481,8.315,8.315,0,1,1,7.558,0,.831.831,0,1,0,.757,1.481,9.979,9.979,0,1,0-9.072,0Z"
        fill="#4b4b4b"
      />
      <circle
        id="Ellipse_224"
        data-name="Ellipse 224"
        cx="2"
        cy="2"
        r="2"
        transform="translate(16.997 24.998)"
        fill="#616161"
      />
    </g>
  </svg>
);
export default ImportsIcon;
