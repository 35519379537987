export const Types = {
  RESET_DATA: "boitelScenario/RESET_DATA",
  CLEAR_FIELDS: "boitelScenario/CLEAR_FIELDS",
  GET_BOITEL_SCENARIO_DATA: "boitelScenario/GET_BOITEL_SCENARIO_DATA",
  GET_BOITEL_SCENARIO_DATA_SUCCESS:
    "boitelScenario/GET_BOITEL_SCENARIO_DATA_SUCCESS",
  GET_BOITEL_SCENARIO_DATA_ERROR:
    "boitelScenario/GET_BOITEL_SCENARIO_DATA_ERROR",
  STORE_UPDATE_BOITEL_SCENARIO: "boitelScenario/STORE_UPDATE_BOITEL_SCENARIO",
  STORE_UPDATE_BOITEL_SCENARIO_SUCCESS:
    "boitelScenario/STORE_UPDATE_BOITEL_SCENARIO_SUCCESS",
  STORE_UPDATE_BOITEL_SCENARIO_ERROR:
    "boitelScenario/STORE_UPDATE_BOITEL_SCENARIO_ERROR",
  UPDATE_BOITEL_SCENARIO_SIMULATION:
    "boitelScenario/UPDATE_BOITEL_SCENARIO_SIMULATION",
  UPDATE_BOITEL_SCENARIO_SIMULATION_SUCCESS:
    "boitelScenario/UPDATE_BOITEL_SCENARIO_SIMULATION_SUCCESS",
  UPDATE_BOITEL_SCENARIO_SIMULATION_ERROR:
    "boitelScenario/UPDATE_BOITEL_SCENARIO_SIMULATION_ERROR",
  DELETE_BOITEL_SCENARIO_SIMULATION:
    "boitelScenario/DELETE_BOITEL_SCENARIO_SIMULATION",
  DELETE_BOITEL_SCENARIO_SIMULATION_SUCCESS:
    "boitelScenario/DELETE_BOITEL_SCENARIO_SIMULATION_SUCCESS",
  DELETE_BOITEL_SCENARIO_SIMULATION_ERROR:
    "boitelScenario/DELETE_BOITEL_SCENARIO_SIMULATION_ERROR",
  CONTRACT_BOITEL_SCENARIO: "boitelScenario/CONTRACT_BOITEL_SCENARIO",
  CONTRACT_BOITEL_SCENARIO_SUCCESS:
    "boitelScenario/CONTRACT_BOITEL_SCENARIO_SUCCESS",
  CONTRACT_BOITEL_SCENARIO_ERROR:
    "boitelScenario/CONTRACT_BOITEL_SCENARIO_ERROR",
};

export const Creators = {
  resetData: () => ({
    type: Types.RESET_DATA,
    payload: {},
  }),
  clearFields: (resetBoitelScenarioData = false) => ({
    type: Types.CLEAR_FIELDS,
    payload: { resetBoitelScenarioData },
  }),
  getBoitelScenarioData: (groupId, farmId, id) => ({
    type: Types.GET_BOITEL_SCENARIO_DATA,
    payload: { groupId, farmId, id },
  }),
  getBoitelScenarioDataSuccess: (data) => ({
    type: Types.GET_BOITEL_SCENARIO_DATA_SUCCESS,
    payload: { data },
  }),
  getBoitelScenarioDataError: (error) => ({
    type: Types.GET_BOITEL_SCENARIO_DATA_ERROR,
    payload: { error },
  }),
  storeUpdateBoitelScenario: (groupId, farmId, id, body) => ({
    type: Types.STORE_UPDATE_BOITEL_SCENARIO,
    payload: { groupId, farmId, id, body },
  }),
  storeUpdateBoitelScenarioSuccess: (data) => ({
    type: Types.STORE_UPDATE_BOITEL_SCENARIO_SUCCESS,
    payload: { data },
  }),
  storeUpdateBoitelScenarioError: (error) => ({
    type: Types.STORE_UPDATE_BOITEL_SCENARIO_ERROR,
    payload: { error },
  }),
  updateBoitelScenarioSimulation: (
    groupId,
    farmId,
    boitelScenarioId,
    id,
    body
  ) => ({
    type: Types.UPDATE_BOITEL_SCENARIO_SIMULATION,
    payload: { groupId, farmId, boitelScenarioId, id, body },
  }),
  updateBoitelScenarioSimulationSuccess: (data) => ({
    type: Types.UPDATE_BOITEL_SCENARIO_SIMULATION_SUCCESS,
    payload: { data },
  }),
  updateBoitelScenarioSimulationError: (error) => ({
    type: Types.UPDATE_BOITEL_SCENARIO_SIMULATION_ERROR,
    payload: { error },
  }),
  deleteBoitelScenarioSimulation: (groupId, farmId, boitelScenarioId, id) => ({
    type: Types.DELETE_BOITEL_SCENARIO_SIMULATION,
    payload: { groupId, farmId, boitelScenarioId, id },
  }),
  deleteBoitelScenarioSimulationSuccess: (removeId) => ({
    type: Types.DELETE_BOITEL_SCENARIO_SIMULATION_SUCCESS,
    payload: { removeId },
  }),
  deleteBoitelScenarioSimulationError: (error) => ({
    type: Types.DELETE_BOITEL_SCENARIO_SIMULATION_ERROR,
    payload: { error },
  }),
  contractBoitelScenario: (groupId, farmId, id) => ({
    type: Types.CONTRACT_BOITEL_SCENARIO,
    payload: { groupId, farmId, id },
  }),
  contractBoitelScenarioSuccess: (data) => ({
    type: Types.CONTRACT_BOITEL_SCENARIO_SUCCESS,
    payload: { data },
  }),
  contractBoitelScenarioError: (error) => ({
    type: Types.CONTRACT_BOITEL_SCENARIO_ERROR,
    payload: { error },
  }),
};

const INITIAL_STATE = {
  refreshTable: false,
  clearFields: false,
  isLoadingGetBoitelScenarioDataRequest: false,
  isLoadingSaveRequest: false,
  isLoadingUpdateSimulationRequest: false,
  boitelScenarioData: {
    createdAt: null,
    deletedAt: null,
    farmId: null,
    groupId: null,
    id: null,
    name: null,
    supplierId: null,
    supplierContact: null,
    simulations: [
      {
        amountAnimals: null,
        boitelPriceId: null,
        boitelPriceValidityId: null,
        boitelPriceValidityWeightRangeId: null,
        breedId: null,
        carcassHarnessing: null,
        createdAt: null,
        deletedAt: null,
        dietStrategyId: null,
        entryDate: null,
        expectedMargin: null,
        gender: "Male",
        id: null,
        name: null,
        limitTimeSimulation: null,
        marketAnalysisId: null,
        maximumWeightSell: 720,
        minimumWeightSell: null,
        mixedMilk: false,
        priceDifferenceOxCow: null,
        priceSimulation: null,
        resultProcessedWithSuccess: true,
        useQuotaCriteria: false,
        quotaId: null,
        minimumAge: null,
        maximumAge: null,
        strongAgeRestriction: null,
        minimumWeight: null,
        maximumWeight: null,
        strongWeightRestriction: null,
        result: {
          aggregateArroba: null,
          animalsAmount: null,
          arrobaPrice: null,
          averageWeightGain: null,
          boitelPriceLimitTimeSimulation: null,
          boitelPriceMargin: null,
          boitelPriceMarginPercentage: null,
          createdAt: null,
          deletedAt: null,
          finalWeight: null,
          id: null,
          marginOutDay: null,
          maximumMargin: null,
          maximumMarginAggregate: null,
          maximumMarginDays: null,
          maximumMarginDaysOutDay: null,
          maximumNumberOfAnimals: null,
          name: null,
          outDay: null,
          saleIncome: null,
          updatedAt: null,
          userLimitTimeSimulation: null,
          userMargin: null,
          userMarginPercentage: null,
        },
        updatedAt: null,
      },
    ],
    consolidatedResult: null,
    status: "Save",
    updatedAt: null,
  },
};

export default function boitelScenario(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.CLEAR_FIELDS:
      if (action.payload.resetBoitelScenarioData === true) {
        return {
          ...state,
          clearFields: !state.clearFields,
          boitelScenarioData: INITIAL_STATE.boitelScenarioData,
        };
      } else {
        return { ...state, clearFields: !state.clearFields };
      }
    case Types.GET_BOITEL_SCENARIO_DATA:
      return { ...state, isLoadingGetBoitelScenarioDataRequest: true };
    case Types.GET_BOITEL_SCENARIO_DATA_SUCCESS:
      return {
        ...state,
        isLoadingGetBoitelScenarioDataRequest: false,
        boitelScenarioData: action.payload.data,
      };
    case Types.GET_BOITEL_SCENARIO_DATA_ERROR:
      return { ...state, isLoadingGetBoitelScenarioDataRequest: false };
    case Types.STORE_UPDATE_BOITEL_SCENARIO:
      return {
        ...state,
        isLoadingSaveRequest: true,
        isLoadingUpdateSimulationRequest: true,
      };
    case Types.STORE_UPDATE_BOITEL_SCENARIO_SUCCESS:
      return {
        ...state,
        isLoadingSaveRequest: false,
        isLoadingUpdateSimulationRequest: false,
        boitelScenarioData: action.payload.data,
        clearFields: true,
      };
    case Types.STORE_UPDATE_BOITEL_SCENARIO_ERROR:
      return {
        ...state,
        isLoadingSaveRequest: false,
        isLoadingUpdateSimulationRequest: false,
        clearFields: false,
      };
    case Types.UPDATE_BOITEL_SCENARIO_SIMULATION:
      return { ...state, isLoadingUpdateSimulationRequest: true };
    case Types.UPDATE_BOITEL_SCENARIO_SIMULATION_SUCCESS:
      const { id: simulationId } = action.payload.data;
      let simulationsUpdated = state.boitelScenarioData.simulations.map((s) => {
        return s.id === simulationId ? action.payload.data : s;
      });
      return {
        ...state,
        isLoadingUpdateSimulationRequest: false,
        boitelScenarioData: {
          ...state.boitelScenarioData,
          consolidatedResult: null,
          simulations: simulationsUpdated,
        },
      };
    case Types.UPDATE_BOITEL_SCENARIO_SIMULATION_ERROR:
      return {
        ...state,
        isLoadingUpdateSimulationRequest: false,
      };
    case Types.DELETE_BOITEL_SCENARIO_SIMULATION:
      return { ...state, isLoadingUpdateSimulationRequest: true };
    case Types.DELETE_BOITEL_SCENARIO_SIMULATION_SUCCESS:
      const removeId = action.payload.removeId;
      let simulationsUpdatedAfterDelete =
        state.boitelScenarioData.simulations.filter((s) => s.id !== removeId);
      return {
        ...state,
        isLoadingUpdateSimulationRequest: false,
        boitelScenarioData: {
          ...state.boitelScenarioData,
          consolidatedResult: null,
          simulations: simulationsUpdatedAfterDelete,
        },
      };
    case Types.DELETE_BOITEL_SCENARIO_SIMULATION_ERROR:
      return {
        ...state,
        isLoadingUpdateSimulationRequest: false,
      };
    case Types.CONTRACT_BOITEL_SCENARIO:
      return {
        ...state,
        isLoadingGetBoitelScenarioDataRequest: true,
      };
    case Types.CONTRACT_BOITEL_SCENARIO_SUCCESS:
      return {
        ...state,
        isLoadingGetBoitelScenarioDataRequest: false,
        boitelScenarioData: action.payload.data,
      };
    case Types.CONTRACT_BOITEL_SCENARIO_ERROR:
      return {
        ...state,
        isLoadingGetBoitelScenarioDataRequest: false,
      };
    default:
      return state;
  }
}
