import React, { useState, useCallback } from "react";

// libs
import { Row, Col, Spin, Tooltip, Icon } from "antd";
import moment from "moment";

// Styles
import { Card } from "../../styles";

// Components
import useKpiContext from "../../../../hooks/useKpiContext";

//Services
import { numberMask } from "../../../../services/helpersMethodsService";

//Icons
import AlertIcon from "../../icons/alert";
import ChartIcon from "../../icons/chart";
import InfoIcon from "../../../../components/utils/icons/info";
import MaleIcon from "../../../../components/utils/icons/male";
import FemaleIcon from "../../../../components/utils/icons/female";

const KpiWeanCalf = () => {
  const { kpiWeanCalf: kpiData, updateKpiSelected } = useKpiContext();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isTooltipErrorVisible, setIsTooltipErrorVisible] = useState(false);

  const handleShowTooltip = useCallback(() => {
    setIsTooltipVisible(true);
    setTimeout(() => {
      setIsTooltipVisible(false);
    }, 2000);
  }, []);

  const handleShowTooltipError = useCallback(() => {
    setIsTooltipErrorVisible(true);
    setTimeout(() => {
      setIsTooltipErrorVisible(false);
    }, 2000);
  }, []);

  return (
    <Card
      xs={23}
      sm={23}
      md={7}
      lg={7}
      xl={7}
      className={
        (kpiData?.data.isComplete
          ? kpiData?.data.goalAchieved
            ? " border-bottom-green"
            : " border-bottom-red"
          : "") +
        (kpiData.isSelected
          ? kpiData?.data.isComplete
            ? kpiData?.data.goalAchieved
              ? " border-green"
              : " border-red"
            : " border-yellow"
          : "")
      }
      onClick={() =>
        !kpiData.isLoading ? updateKpiSelected("Kg Bez. Desm / Kg Vaca") : null
      }
    >
      <Spin spinning={kpiData.isLoading} size="small">
        {kpiData?.data?.startDate && kpiData?.data?.endDate && (
          <div className="period-header">
            <span>
              {moment(kpiData?.data?.startDate, "YYYY-MM-DD").format(
                "DD/MM/YYYY"
              )}{" "}
              a{" "}
              {moment(kpiData?.data?.endDate, "YYYY-MM-DD").format(
                "DD/MM/YYYY"
              )}
            </span>
          </div>
        )}

        <div className="header">
          <span>
            <Tooltip visible={isTooltipErrorVisible} title={`Último processamento: ${kpiData.data?.kpiDate != null ? moment(kpiData.data.kpiDate).format("DD/MM/YYYY") : "Sem data"}`}>
              {kpiData.data.error ? (
                <Icon
                  type="exclamation-circle"
                  className="iconError"
                  theme="filled"
                  onClick={() => handleShowTooltipError()} />
              ) : null}
            </Tooltip>
            KG BEZ. DESM / KG VACA
          </span>
          <span>
            {!kpiData?.data?.isComplete ? null : kpiData?.data?.goalAchieved ? (
              <ChartIcon />
            ) : (
              <AlertIcon />
            )}
          </span>
        </div>

        <div className="content">
          <Row type="flex">
            <span className="span-font">
              <b>
                {kpiData?.data?.kpiValue === null
                  ? "-"
                  : numberMask(
                    Number(kpiData?.data?.kpiValue) || 0,
                    kpiData?.data?.isMonetary
                  )}{" "}
              </b>
              {kpiData?.data?.valueType}
            </span>
          </Row>
          <Row type="flex">
            <InfoIcon onClick={() => handleShowTooltip()} />
          </Row>
        </div>

        <div className="content-footer">
          <Col span={12}>
            <Row type="flex" justify="center">
              <span>Desmama</span>
            </Row>
            <Tooltip
              placement="top"
              visible={isTooltipVisible}
              title="Bezerros Desmamados / Peso Médio"
            >
              <Row type="flex" className="row-space-evenly">
                <span>
                  <MaleIcon className="male-icon" />
                  <b>{kpiData?.data?.parameters?.MaleCalvesCount || 0}</b>
                </span>
                <span>
                  <b>
                    {numberMask(
                      kpiData?.data?.parameters?.MaleCalvesAvgWeight || 0
                    )}
                    {" kg"}
                  </b>
                </span>
              </Row>
            </Tooltip>
            <Tooltip
              placement="bottom"
              visible={isTooltipVisible}
              title="Bezerras Desmamadas / Peso Médio"
            >
              <Row type="flex" className="row-space-evenly">
                <span>
                  <FemaleIcon className="female-icon" />
                  <b>{kpiData?.data?.parameters?.FemaleCalvesCount || 0}</b>
                </span>
                <span>
                  <b>
                    {numberMask(
                      kpiData?.data?.parameters?.FemaleCalvesAvgWeight || 0
                    )}
                    {" kg"}
                  </b>
                </span>
              </Row>
            </Tooltip>
          </Col>
          <Col span={12}>
            <Row type="flex" justify="center">
              <span>Fêmeas Aptas</span>
            </Row>
            <Tooltip
              placement="right"
              visible={isTooltipVisible}
              title="Nº Fêmeas Aptas / Peso Médio"
            >
              <Row type="flex" className="row-space-evenly">
                <span>
                  <b>
                    {kpiData?.data?.parameters?.CowsFitForBreedCount || 0} /{" "}
                    {numberMask(
                      kpiData?.data?.parameters?.CowsFitForBreedAvgWeight || 0
                    )}
                    {" kg"}
                  </b>
                </span>
              </Row>
            </Tooltip>
          </Col>
        </div>
      </Spin>
      {!kpiData?.data.isComplete ? <div className="banner-image" /> : null}
    </Card>
  );
};

export default KpiWeanCalf;
