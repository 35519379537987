import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import * as Yup from "yup";
import { Formik } from "formik";
import {
	Spin,
	Row,
	Col,
	notification,
	Divider,
	Button
} from "antd";

import { FormContainer, FormFooterContaier } from "./styles";

import InputStandard from "../../../components/utils/input";
import DocumentInput from "../../../components/utils/input/documentInput";
import ButtonStandard from "../../../components/utils/button";
import PhoneNumberInput from "../../../components/utils/input/phoneNumberInput";
import ArrowIcon from "../../../components/utils/icons/arrows/red/left";

// Services
import {
	validateCNPJ,
	validateEmail,
	validatePhone
} from "../../../services/helpersMethodsService";

import { saveSupplierGroup } from "../../../services/supplierManagementService";

const NewSupplierManagement = (props) => {
	// Variable Redux
	const {
		translation,
	} = useSelector((state) => state.app);

	const [isLoadingRequest, setIsLoadingRequest] = useState(false);

	const [form, setForm] = useState({
		id: null,
		groupName: null,
		groupFantasyName: null,
		documentNumber: null,
		documentType: "CNPJ",
		managers: [{
			name: "",
			email: "",
			phone: "",
			status: 'Active'
		}],
		companies: [{
			companyName: "",
			fantasyName: "",
			documentNumber: "",
			documentType: "CNPJ",
			status: 'Active'
		}]
	});

	const history = useHistory();
	const formik = useRef(null);

	const validationSchema = Yup.object().shape({
		groupName: Yup.string().required(),
		groupFantasyName: Yup.string().required(),
		documentNumber: Yup.string().required(),
		managers: Yup.array().of(
			Yup.object().shape({
				name: Yup.string().required(),
				email: Yup.string().required(),
				phone: Yup.string().required()
			}
			)),
		companies: Yup.array().of(
			Yup.object().shape({
				companyName: Yup.string().required(),
				fantasyName: Yup.string().required(),
				documentNumber: Yup.string().required()
			}
			))

	});

	async function handleSubmitForm(values, props, event) {
		event.preventDefault();
		setIsLoadingRequest(true);

		const {
			groupName,
			groupFantasyName,
			documentNumber,
			documentType,
			managers,
			companies
		} = values;

		if (formIsValid(values)) {
			let body = {
				groupName,
				groupFantasyName,
				documentNumber,
				documentType,
				managers,
				companies
			}

			const {
				data: { results },
			} = await saveSupplierGroup(body);

			if (results) {
				notification.success({
					message: "Contratação realizada com sucesso!",
				});
				history.push("/admin/administrations", { tabSelect: "7" });
			} else {
				notification.error({
					message: "Ocorreu um erro ao tentar salvar as informações do grupo!",
				});
			}
		}
		setIsLoadingRequest(false);
	}

	// Validate form
	function formIsValid(values) {
		let isValid = false;

		const {
			groupName,
			groupFantasyName,
			documentNumber,
			documentType,
			managers,
			companies
		} = values;

		if (groupName == null || groupName?.trim() === "") {
			notification.error({
				message: "É necessário informar a Razão Social do Grupo.",
			});
		} else if (groupFantasyName == null || groupFantasyName?.trim() === "") {
			notification.error({
				message: "É necessário informar o Nome Fantasia do Grupo.",
			});
		} else if (documentNumber == null || documentNumber?.trim() === "") {
			notification.error({
				message: "É necessário informar o CNPJ do Grupo.",
			});
		} else if (documentType === "CNPJ" && !validateCNPJ(documentNumber)) {
			notification.error({
				message: "CNPJ do Grupo inválido.",
			});
		} else if (managers == null || managers?.length === 0) {
			notification.error({
				message: "É necessário informar pelo menos 1 Responsável.",
			});
		} else if (companies == null || companies?.length === 0) {
			notification.error({
				message: "É necessário informar pelo menos 1 Empresa.",
			});
		} else {
			let hasErrorsManager = false;
			let hasErrorsCompanies = false;

			// Validate Managers
			managers.map((m) => {
				if (m.name == null || m.name?.trim() === "") {
					hasErrorsManager = true;
					notification.error({
						message: "É necessário informar o Nome do Responsável.",
					});
				} else if (m.email == null || m.email?.trim() === "") {
					hasErrorsManager = true;
					notification.error({
						message: "É necessário informar o E-mail do Responsável.",
					});
				} else if (!validateEmail(m.email)) {
					hasErrorsManager = true;
					notification.error({
						message: "E-mail do Responsável Inválido.",
					});
				} else if (m.phone == null || m.phone?.trim() === "") {
					hasErrorsManager = true;
					notification.error({
						message: "É necessário informar o Telefone do Responsável.",
					});
				} else if (!validatePhone(m.phone)) {
					hasErrorsManager = true;
					notification.error({
						message: "Telefone do Responsável Inválido.",
					});
				}
			});

			//Validade Companies
			companies.map(c => {
				if (c.companyName == null || c.companyName?.trim() === "") {
					hasErrorsCompanies = true;
					notification.error({
						message: "É necessário informar a Razão Social da Empresa.",
					});
				} else if (c.fantasyName == null || c.fantasyName?.trim() === "") {
					hasErrorsCompanies = true;
					notification.error({
						message: "É necessário informar o Nome Fantasia da Empresa.",
					});
				} else if (c.documentNumber == null || c.documentNumber?.trim() === "") {
					hasErrorsCompanies = true;
					notification.error({
						message: "É necessário informar o CNPJ da Empresa.",
					});
				} else if (c.documentType === "CNPJ" && !validateCNPJ(c.documentNumber)) {
					hasErrorsCompanies = true;
					notification.error({
						message: "CNPJ da Empresa inválido.",
					});
				}
			});

			if (hasErrorsCompanies === false && hasErrorsManager === false) {
				isValid = true;
			}
		}

		return isValid;
	}

	// Add a new line of manager in the form
	function handleCreateManager(managers) {
		managers.push({
			name: "",
			email: "",
			phone: ""
		});
	}

	// Delete a manager in the form
	function handleDeleteManager(managers, index) {
		managers.splice(index, 1);
	}

	// Add a new line of company in the form
	function handleCreateCompany(companies) {
		companies.push({
			companyName: "",
			fantasyName: "",
			documentNumber: "",
			documentType: "CNPJ",
		});
	}

	// Delete a company in the form
	function handleDeleteCompany(companies, index) {
		companies.splice(index, 1);
	}

	// hasErrors
	function hasErrors(errors, touched, array, index, fieldName) {
		if (errors[array] && touched[array]) {
			if (errors[array][index] && touched[array][index]) {
				if (errors[array][index][fieldName] && touched[array][index][fieldName]) {
					return true
				}
			}
		}
		return false;
	}

	return (
		<FormContainer>
			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<Spin spinning={isLoadingRequest}>
					<Formik
						ref={formik}
						initialValues={form}
						validationSchema={validationSchema}
						onSubmit={handleSubmitForm}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							...props
						}) => (
							<form>
								<Row type="flex" align="middle">
									<div
										className="back-container back"
										onClick={() =>
											history.push("/admin/administrations", { tabSelect: "7" })
										}
									>
										<ArrowIcon />
										<span className="back">Voltar</span>
									</div>
								</Row>
								{/* Tittle */}
								<Row type="flex" className="marginRow">
									<Col>
										<Row>
											<span className="title">Grupos e empresas de fornecedores</span>
										</Row>
										<Row>
											<span className="title small">
												Preencha o formulário abaixo para convidar um novo usuário do tipo fornecedor
											</span>
										</Row>
									</Col>
								</Row>

								{/* Group Form */}
								<Row type="flex" gutter={8}>

									{/* group name */}
									<Col xs={24} sm={24} md={8} lg={8} xl={8}>
										<Row>
											<label
												className={errors.groupName && touched.groupName ? "error" : ""}
											>
												Razão Social*
											</label>
										</Row>
										<Row>
											<InputStandard
												border={
													errors.groupName && touched.groupName ? "error" : "normal"
												}
												className="input small"
											>
												<input
													placeholder="Informe aqui..."
													maxLength="255"
													name="groupName"
													autoComplete="chrome-off"
													onChange={(event) =>
														props.setFieldValue("groupName", event.target.value)
													}
													onBlur={handleBlur}
												/>
											</InputStandard>
										</Row>
									</Col>

									{/* fantasy name */}
									<Col xs={24} sm={24} md={8} lg={8} xl={8}>
										<Row>
											<label
												className={errors.groupFantasyName && touched.groupFantasyName ? "error" : ""}
											>
												Nome Fantasia*
											</label>
										</Row>
										<Row>
											<InputStandard
												border={
													errors.groupFantasyName && touched.groupFantasyName ? "error" : "normal"
												}
												className="input small"
											>
												<input
													placeholder="Informe aqui..."
													maxLength="255"
													name="groupFantasyName"
													autoComplete="chrome-off"
													onChange={(event) =>
														props.setFieldValue("groupFantasyName", event.target.value)
													}
													onBlur={handleBlur}
												/>
											</InputStandard>
										</Row>
									</Col>

									{/* document */}
									<Col xs={24} sm={24} md={8} lg={8} xl={8}>
										<Row>
											<label
												className={errors.groupFantasyName && touched.groupFantasyName ? "error" : ""}
											>
												CNPJ*
											</label>
										</Row>
										<Row>
											<DocumentInput
												border={errors.documentNumber && touched.documentNumber ? "error" : "normal"}
												className="input small documentInput"
												kind="mixed"
												placeholder="Informe aqui..."
												value={values?.documentNumber}
												name="documentNumber"
												onChange={(event) => {
													let value = event.target.value
														?.replace(/\./g, "")
														.replace(/\//g, "")
														.replace(/-/g, "");
													props.setFieldValue("documentNumber", value);
												}}
												onBlur={handleBlur}
											/>
										</Row>
									</Col>
								</Row>

								<Divider className="marginDivider"></Divider>

								<Row type="flex" className="marginRow">
									<span className="subTitle">Responsável</span>
								</Row>

								{/* managers */}
								{values.managers.map((m, index) => {
									return (
										<Row key={index} type="flex" gutter={8}>

											{/* name */}
											<Col xs={24} sm={24} md={8} lg={7} xl={8}>
												<Row>

													<label
														className={hasErrors(errors, touched, "managers", index, "name") ? "error" : ""}
													>
														{index + 1} - Nome*
													</label>
												</Row>
												<Row>
													<InputStandard
														border={
															hasErrors(errors, touched, "managers", index, "name") ? "error" : "normal"
														}
														className="input small"
													>
														<input
															placeholder="Informe aqui..."
															maxLength="255"
															name={`managers[${index}].name`}
															autoComplete="chrome-off"
															onChange={(event) =>
																props.setFieldValue(`managers[${index}].name`, event.target.value)
															}
															onBlur={handleBlur}
														/>
													</InputStandard>
												</Row>
											</Col>

											{/* email */}
											<Col xs={24} sm={24} md={8} lg={7} xl={7}>
												<Row>
													<label
														className={hasErrors(errors, touched, "managers", index, "email") ? "error" : ""}
													>
														E-mail*
													</label>
												</Row>
												<Row>
													<InputStandard
														border={
															hasErrors(errors, touched, "managers", index, "email") ? "error" : "normal"
														}
														className="input small"
													>
														<input
															placeholder="Informe aqui..."
															maxLength="255"
															name={`managers[${index}].email`}
															autoComplete="chrome-off"
															onChange={(event) =>
																props.setFieldValue(`managers[${index}].email`, event.target.value)
															}
															onBlur={handleBlur}
														/>
													</InputStandard>
												</Row>
											</Col>

											{/* phone */}
											<Col xs={24} sm={18} md={8} lg={7} xl={7}>
												<Row>
													<label
														className={hasErrors(errors, touched, "managers", index, "phone") ? "error" : ""}
													>
														Telefone*
													</label>
												</Row>
												<Row>
													<InputStandard
														border={
															hasErrors(errors, touched, "managers", index, "phone") ? "error" : "normal"
														}
														className="input small"
													>
														<PhoneNumberInput
															value={values?.managers[index].phone}
															name={`managers[${index}].phone`}
															autoComplete="chrome-off"
															placeholder="(xx) xxxxx-xxxx"
															onBlur={handleBlur}
															onChange={(event) =>
																props.setFieldValue(`managers[${index}].phone`, event.target.value)
															}
														/>
													</InputStandard>

												</Row>
											</Col>

											{/* action buttons */}
											<Col xs={24} sm={6} md={4} lg={3} xl={2}>
												<Row type="flex" className="actionButtonRow">
													{index === 0 ? (
														<Button
															shape="circle"
															icon="plus"
															style={{ marginLeft: 10 }}
															onClick={() => {
																handleCreateManager(values.managers);
																handleBlur();
															}}
														/>
													) : null}

													<Button
														disabled={index === 0}
														shape="circle"
														icon="delete"
														style={{ marginLeft: 10 }}
														onClick={() => {
															handleDeleteManager(values.managers, index);
															handleBlur();
														}}
													/>
												</Row>
											</Col>
										</Row>)
								})}

								<Divider className="marginDivider"></Divider>

								<Row type="flex" className="marginRow">
									<span className="subTitle">Empresas</span>
								</Row>

								{/* companies */}
								{values.companies.map((c, index) => {
									return <Row key={index} type="flex" gutter={8}>

										{/* company name */}
										<Col xs={24} sm={24} md={8} lg={7} xl={8}>
											<Row>
												<label
													className={hasErrors(errors, touched, "companies", index, "companyName") ? "error" : ""}
												>
													{index + 1} - Razão Social*
												</label>
											</Row>
											<Row>
												<InputStandard
													border={hasErrors(errors, touched, "companies", index, "companyName") ? "error" : "normal"}
													className="input small"
												>
													<input
														placeholder="Informe aqui..."
														maxLength="255"
														name={`companies[${index}].companyName`}
														autoComplete="chrome-off"
														onChange={(event) =>
															props.setFieldValue(`companies[${index}].companyName`, event.target.value)
														}
														onBlur={handleBlur}
													/>
												</InputStandard>
											</Row>
										</Col>

										{/* company fantasy name */}
										<Col xs={24} sm={24} md={8} lg={7} xl={7}>
											<Row>
												<label
													className={hasErrors(errors, touched, "companies", index, "fantasyName") ? "error" : ""}
												>
													Fantasia*
												</label>
											</Row>
											<Row>
												<InputStandard
													border={
														hasErrors(errors, touched, "companies", index, "fantasyName") ? "error" : "normal"
													}
													className="input small"
												>
													<input
														placeholder="Informe aqui..."
														maxLength="255"
														name={`companies[${index}].fantasyName`}
														autoComplete="chrome-off"
														onChange={(event) =>
															props.setFieldValue(`companies[${index}].fantasyName`, event.target.value)
														}
														onBlur={handleBlur}
													/>
												</InputStandard>
											</Row>
										</Col>

										{/* company document */}
										<Col xs={24} sm={18} md={8} lg={7} xl={7}>
											<Row>
												<label
													className={hasErrors(errors, touched, "companies", index, "documentNumber") ? "error" : ""}
												>
													CNPJ*
												</label>
											</Row>
											<Row>
												<DocumentInput
													border={hasErrors(errors, touched, "companies", index, "documentNumber") ? "error" : "normal"}
													className="input small documentInput"
													kind="mixed"
													placeholder="Informe aqui..."
													name={`companies[${index}].documentNumber`}
													value={values?.companies[index]?.documentNumber}
													onChange={(event) => {
														let value = event.target.value
															?.replace(/\./g, "")
															.replace(/\//g, "")
															.replace(/-/g, "");
														props.setFieldValue(`companies[${index}].documentNumber`, value)
													}}
													onBlur={handleBlur}
												/>
											</Row>
										</Col>

										{/* action buttons */}
										<Col xs={24} sm={6} md={4} lg={3} xl={2}>
											<Row type="flex" className="actionButtonRow">
												{index === 0 ? (
													<Button
														shape="circle"
														icon="plus"
														style={{ marginLeft: 10 }}
														onClick={() => {
															handleCreateCompany(values.companies);
															handleBlur()
														}}
													/>
												) : null}

												<Button
													disabled={index === 0}
													shape="circle"
													icon="delete"
													style={{ marginLeft: 10 }}
													onClick={() => {
														handleDeleteCompany(values.companies, index);
														handleBlur();
													}}
												/>
											</Row>
										</Col>
									</Row>
								})}

								<FormFooterContaier>
									<Row type="flex" gutter={8} justify="end">
										<Col xs={5} sm={5} md={4} lg={3} xl={2}>
											<ButtonStandard
												buttonType="type2"
												width="100%"
												height="39px"
												background="#591F74"
												onClick={(event) =>
													handleSubmitForm(values, props, event)
												}
											>
												Salvar
											</ButtonStandard>
										</Col>
									</Row>
								</FormFooterContaier>
							</form>
						)}
					</Formik>
				</Spin>
			</Col>
		</FormContainer>
	);
};

export default NewSupplierManagement;
