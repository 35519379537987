export const Types = {
  RESET_DATA: "retreat/RESET_DATA",
  GET_ALL_FARM_RETREAT: "retreat/GET_ALL_FARM_RETREAT",
  GET_ALL_FARM_RETREAT_SUCCESS: "retreat/GET_ALL_FARM_RETREAT_SUCCESS",
  GET_ALL_FARM_RETREAT_ERROR: "retreat/GET_ALL_FARM_RETREAT_ERROR",
  SHOW_OR_HIDE_DRAWER: "retreat/SHOW_OR_HIDE_DRAWER",
  INACTIVATE_FARM_RETREAT: "retreat/INACTIVATE_FARM_RETREAT",
  ACTIVATE_FARM_RETREAT: "retreat/ACTIVATE_FARM_RETREAT",
  SET_IS_LOADING_TABLE: "retreat/SET_IS_LOADING_TABLE",
};

export const Creators = {
  index: (
    groupId,
    farmId,
  ) => ({
    type: Types.GET_ALL_FARM_RETREAT,
    payload: {
      groupId,
      farmId
    },
  }),
  indexSuccess: (data) => ({
    type: Types.GET_ALL_FARM_RETREAT_SUCCESS,
    payload: {
      data,
    },
  }),
  indexError: (error) => ({
    type: Types.GET_ALL_FARM_RETREAT_ERROR,
    payload: {
      error,
    },
  }),
  showOrHideDrawer: (id = null) => ({
    type: Types.SHOW_OR_HIDE_DRAWER,
    payload: {
      id
    },
  }),
  inactivateRetreat: (groupId, farmId, id) => ({
    type: Types.INACTIVATE_FARM_RETREAT,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),
  activateRetreat: (groupId, farmId, id) => ({
    type: Types.ACTIVATE_FARM_RETREAT,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),
  setIsLoadingTable: (isLoading = false) => ({
    type: Types.SET_IS_LOADING_TABLE,
    payload: {
      isLoading
    },
  }),
};

const INITIAL_STATE = {
  data: [],
  isDrawerVisible: false,
  retreatId: null,
  isLoadingTable: false,
  isLoadingDrawer: false,
  error: {},
};

export default function quota(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_ALL_FARM_RETREAT:
      return {
        ...state,
        isLoadingTable: true,
      };
    case Types.GET_ALL_FARM_RETREAT_SUCCESS:
      const { data: retreats } = action.payload;
      return { ...state, isLoadingTable: false, data: retreats };
    case Types.GET_ALL_FARM_RETREAT_ERROR:
      const { error } = action.payload;
      return {
        ...state,
        isLoadingTable: false,
        data: null,
        error,
      };
    case Types.SHOW_OR_HIDE_DRAWER:
      return { ...state, retreatId: action.payload.id, isDrawerVisible: !state.isDrawerVisible };
    case Types.INACTIVATE_FARM_RETREAT:
      return { ...state, isLoadingTable: true };
    case Types.ACTIVATE_FARM_RETREAT:
      return { ...state, isLoadingTable: true };
    case Types.SET_IS_LOADING_TABLE:
      return { ...state, isLoadingTable: action.payload.isLoading };
    default:
      return state;
  }
}
