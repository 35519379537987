import React from "react";

// import { Container } from './styles';

const LotIcon = () => (
  <svg
    id="bovexoLotIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="31.834"
    height="19.831"
    viewBox="0 0 31.834 19.831"
  >
    <g id="icon-lote" transform="translate(-32.59 -511.214)">
      <circle
        id="Ellipse_98"
        data-name="Ellipse 98"
        cx="0.586"
        cy="0.586"
        r="0.586"
        transform="translate(46.242 522.038)"
        fill="#4b4b4b"
      />
      <circle
        id="Ellipse_99"
        data-name="Ellipse 99"
        cx="0.586"
        cy="0.586"
        r="0.586"
        transform="translate(49.521 522.038)"
        fill="#4b4b4b"
      />
      <path
        id="Path_688"
        data-name="Path 688"
        d="M682.252-508.453v-.527h-1.931a3.878,3.878,0,0,0,1.018-2.736,5.717,5.717,0,0,0-.5-2.236l-.146-.3h-1.63l.051.581a2.713,2.713,0,0,1-1.977,2.762h-8.019a2.713,2.713,0,0,1-1.977-2.762l.051-.581h-1.63l-.146.3a5.717,5.717,0,0,0-.5,2.236,3.877,3.877,0,0,0,1.018,2.736H664v.527c0,.011,0,.021,0,.032a2.7,2.7,0,0,0,2.692,2.692,2.686,2.686,0,0,0,1.581-.513l1.648,3.591a1.475,1.475,0,0,0-.443,1.055v1.123A1.481,1.481,0,0,0,670.956-499h4.34a1.481,1.481,0,0,0,1.479-1.479V-501.6a1.474,1.474,0,0,0-.443-1.055l1.648-3.591a2.686,2.686,0,0,0,1.581.513,2.7,2.7,0,0,0,2.692-2.692C682.253-508.432,682.252-508.442,682.252-508.453Zm-16.135-4.286a3.528,3.528,0,0,0,1.562,2.28,1.769,1.769,0,0,0-.342.425,1.755,1.755,0,0,0-.225.68A3.111,3.111,0,0,1,666.117-512.74Zm.574,5.94a1.625,1.625,0,0,1-1.54-1.111h2.356l.3.661A1.618,1.618,0,0,1,666.692-506.8Zm9.015,6.326a.41.41,0,0,1-.41.41h-4.34a.41.41,0,0,1-.41-.41V-501.6a.41.41,0,0,1,.41-.41h4.34a.41.41,0,0,1,.41.41Zm2.282-8.348-.013.022-2.625,5.723H670.9l-2.615-5.7-.01-.023-.013-.022a.667.667,0,0,1,0-.679.667.667,0,0,1,.588-.339H677.4a.667.667,0,0,1,.588.339A.667.667,0,0,1,677.989-508.822Zm2.147-3.918a3.11,3.11,0,0,1-1,3.385,1.755,1.755,0,0,0-.225-.68,1.766,1.766,0,0,0-.342-.425A3.528,3.528,0,0,0,680.135-512.74Zm-.574,5.939a1.618,1.618,0,0,1-1.12-.449l.3-.661H681.1A1.625,1.625,0,0,1,679.561-506.8Z"
        transform="translate(-624.658 1030.04)"
        fill="#4b4b4b"
      />
      <path
        id="Path_691"
        data-name="Path 691"
        d="M803.851-472.843h-1.669l-.215.469a1.147,1.147,0,0,0,.793.319A1.151,1.151,0,0,0,803.851-472.843Z"
        transform="translate(-757.707 990.106)"
        fill="#4b4b4b"
      />
      <path
        id="Path_692"
        data-name="Path 692"
        d="M766.993-322.356l-.077-.168a1.04,1.04,0,0,1-.068.291A1.631,1.631,0,0,0,766.993-322.356Z"
        transform="translate(-723.84 845.146)"
        fill="#4b4b4b"
      />
      <path
        id="Path_693"
        data-name="Path 693"
        d="M804.824-566.908a1.245,1.245,0,0,1,.16.482,2.2,2.2,0,0,0,.705-2.4,2.5,2.5,0,0,1-1.107,1.615A1.254,1.254,0,0,1,804.824-566.908Z"
        transform="translate(-760.229 1082.666)"
        fill="#4b4b4b"
      />
      <path
        id="Path_696"
        data-name="Path 696"
        d="M488.886-637.964v-.413h-1.514a3.042,3.042,0,0,0,.8-2.146,4.483,4.483,0,0,0-.393-1.754l-.115-.236h-1.279l.04.456a2.128,2.128,0,0,1-1.55,2.166h-6.29a2.127,2.127,0,0,1-1.55-2.166l.04-.456h-1.279l-.115.236a4.482,4.482,0,0,0-.393,1.754,3.041,3.041,0,0,0,.8,2.146h-1.514v.413c0,.008,0,.017,0,.025a2.113,2.113,0,0,0,2.111,2.111,2.107,2.107,0,0,0,1.24-.4l1.292,2.817a1.156,1.156,0,0,0-.348.827v.881a1.162,1.162,0,0,0,1.16,1.16H481.4a2.994,2.994,0,0,1-.628-.839h-.744a.322.322,0,0,1-.322-.322v-.881a.322.322,0,0,1,.322-.322h.428v-.428l-.008-.411h-.462l-2.051-4.471-.008-.018-.01-.017a.523.523,0,0,1,0-.532.523.523,0,0,1,.461-.266h6.705a.523.523,0,0,1,.461.266.523.523,0,0,1,0,.532l-.01.017-.565,1.314a1.373,1.373,0,0,0,.389,1.081l.179-.39a2.107,2.107,0,0,0,1.24.4,2.113,2.113,0,0,0,2.111-2.111C488.886-637.948,488.886-637.955,488.886-637.964Zm-12.655-3.362a2.767,2.767,0,0,0,1.225,1.788,1.388,1.388,0,0,0-.268.333,1.376,1.376,0,0,0-.177.534A2.44,2.44,0,0,1,476.231-641.326Zm.45,4.659a1.274,1.274,0,0,1-1.207-.871h1.848l.238.519A1.269,1.269,0,0,1,476.681-636.667Zm10.544-4.659a2.44,2.44,0,0,1-.781,2.655,1.377,1.377,0,0,0-.177-.534,1.387,1.387,0,0,0-.268-.333A2.767,2.767,0,0,0,487.226-641.326Zm-.45,4.659a1.269,1.269,0,0,1-.878-.353l.238-.519h1.848A1.274,1.274,0,0,1,486.775-636.667Z"
        transform="translate(-441.98 1153.728)"
        fill="#4b4b4b"
      />
      <path
        id="Path_697"
        data-name="Path 697"
        d="M966-637.94a2.114,2.114,0,0,0,2.111,2.111,2.107,2.107,0,0,0,1.24-.4l.179.39a1.373,1.373,0,0,0,.389-1.081l-.565-1.314-.01-.017a.523.523,0,0,1,0-.532.523.523,0,0,1,.461-.266h6.705a.523.523,0,0,1,.461.266.523.523,0,0,1,0,.532l-.01.017-.008.018-2.051,4.471h-.462l-.008.411v.428h.428a.322.322,0,0,1,.322.322v.881a.322.322,0,0,1-.322.322h-.744a2.993,2.993,0,0,1-.628.839h1.371a1.162,1.162,0,0,0,1.16-1.16v-.881a1.156,1.156,0,0,0-.348-.827l1.292-2.817a2.107,2.107,0,0,0,1.24.4,2.114,2.114,0,0,0,2.111-2.111c0-.008,0-.016,0-.025v-.413H978.8a3.041,3.041,0,0,0,.8-2.146,4.484,4.484,0,0,0-.393-1.754l-.115-.236h-1.279l.04.456a2.128,2.128,0,0,1-1.55,2.166h-6.29a2.128,2.128,0,0,1-1.55-2.166l.04-.456h-1.279l-.115.236a4.482,4.482,0,0,0-.393,1.754,3.042,3.042,0,0,0,.8,2.146H966v.413C966-637.955,966-637.948,966-637.94Zm11.875-.732a1.377,1.377,0,0,0-.177-.534,1.387,1.387,0,0,0-.268-.333,2.767,2.767,0,0,0,1.225-1.788A2.44,2.44,0,0,1,977.875-638.671Zm-.548,1.651.238-.519h1.848a1.275,1.275,0,0,1-1.208.871A1.269,1.269,0,0,1,977.327-637.02Zm-8.441-2.518a1.387,1.387,0,0,0-.268.333,1.377,1.377,0,0,0-.177.534,2.44,2.44,0,0,1-.781-2.655A2.767,2.767,0,0,0,968.886-639.538Zm-1.983,2h1.848l.238.519a1.269,1.269,0,0,1-.878.353A1.275,1.275,0,0,1,966.9-637.539Z"
        transform="translate(-915.893 1153.728)"
        fill="#4b4b4b"
      />
    </g>
  </svg>
);
export default LotIcon;
