import React, { Component } from "react";
import Axios from "axios";
import { withRouter, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as DietStrategyActions } from "../../store/ducks/dietStrategy";
import { Creators as AppActions } from "../../store/ducks/app";
import moment from "moment";

import {
  Row,
  Col,
  Table,
  Menu,
  Dropdown,
  Tooltip,
  Input,
  Button,
  Icon,
} from "antd";
import { Title, CardCustom, IconContainer, Container } from "./styles";
import TagStatus from "../../components/utils/tagStatus";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import MenuIcon from "../../components/utils/table/icons/menu";
import AlertDateIcon from "../../components/utils/icons/alertDate";
import RainIcon from "../generalParameter/weatherSeasonsParameter/display/icons/rain";
import SunIcon from "../generalParameter/weatherSeasonsParameter/display/icons/sun";
import TransitionIcon from "../generalParameter/weatherSeasonsParameter/display/icons/transition";
import AlertIcon from "../../components/drawers/alert/icons/alert";
import ButtonStandard from "../../components/utils/button";
import AlertDatePicker from "../../components/utils/alertDatePicker";
import AlertButton from "../../components/utils/alertButton";

// Services
import { showAlertsByDateAndEntity } from "../../services/alertService";

class DietStrategy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      lots: [],
      alerts: [],
      alertsFilterDate: moment(),
    };
  }

  signal = Axios.CancelToken.source();

  componentWillUnmount() {
    this.signal.cancel("Cancelando apis");
  }

  async componentDidMount() {
    const {
      app: { groupSelected, farmSelected, alertSelected },
    } = this.props;
    if (
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      if (alertSelected != null && alertSelected.entity === "diet_strategy") {
        this.getAlerts(groupSelected, farmSelected, moment(), true);
      } else {
        this.fetchData(groupSelected, farmSelected);
        this.getAlerts(groupSelected, farmSelected, moment(), true);
      }
    }
  }

  fetchData = (groupSelected, farmSelected, ids = null) => {
    const { dietStrategyActions } = this.props;
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    try {
      dietStrategyActions.indexDietStrategy(
        groupId,
        farmId,
        0,
        {},
        "",
        true,
        ids,
        this.signal
      );
    } catch (error) {}
  };

  getAlerts = async (groupSelected, farmSelected, date, loadPage) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    const {
      app: { alertSelected },
    } = this.props;
    let selectedDate = date.format("YYYY-MM-DD");

    if (loadPage) {
      if (alertSelected != null && alertSelected.entity === "diet_strategy") {
        selectedDate = alertSelected.executionDate;
        let ids = alertSelected.entityIds.join(",");
        this.fetchData(groupSelected, farmSelected, ids);
        alertSelected.alertPage = false;
      }
    } else if (alertSelected?.id != null) {
      const { appActions } = this.props;
      appActions.setAlertSelected({});
      this.fetchData(groupSelected, farmSelected, null);
    }
    const executionDate = selectedDate;

    const entity = "diet_strategy";
    try {
      const {
        data: { results: alerts },
      } = await showAlertsByDateAndEntity({
        groupId,
        farmId,
        executionDate,
        entity,
        signal: this.signal,
      });
      this.setState({ alerts, alertsFilterDate: selectedDate });
    } catch (error) {
      this.setState({ alerts: null, alertsFilterDate: selectedDate });
    }
  };

  showDietStrategyDetails = (id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      dietStrategyActions,
    } = this.props;
    dietStrategyActions.showDietStrategy(groupId, farmId, id);
  };

  menu = (id, record) => {
    const {
      app: { translation },
    } = this.props;

    return (
      <Menu>
        {record?.status === "Inactive" && (
          <Menu.Item
            key="0"
            onClick={() =>
              this.handleActivateOrInactivate(id, record, "activate")
            }
          >
            {translation.table.menu.activate}
          </Menu.Item>
        )}
        <Menu.Item key="2" onClick={() => this.showDietStrategyDetails(id)}>
          {translation.table.menu.details}
        </Menu.Item>
        {record?.status === "Active" && (
          <Menu.Item key="1">
            <Link to={`/admin/managements/diets/strategies/edit/${id}`}>
              {translation.table.menu.edit}
            </Link>
          </Menu.Item>
        )}
        {record?.status === "Active" && (
          <Menu.Item
            key="3"
            onClick={() =>
              this.handleActivateOrInactivate(id, record, "inactivate")
            }
          >
            {translation.table.menu.inactivate}
          </Menu.Item>
        )}
      </Menu>
    );
  };

  expandedRows = (record, index, indent, expanded) => {
    const {
      dietStrategy: { data },
    } = this.props;
    const columns = [
      {
        title: "Estação",
        dataIndex: "season",
        key: "season",
        align: "center",
        render: (season) => (
          <IconContainer>
            {season
              .split(",")
              .map((season) =>
                season === "Water" ? (
                  <RainIcon key="W" />
                ) : season === "Dry" ? (
                  <SunIcon key="D" />
                ) : season === "Transition" ? (
                  <TransitionIcon key="T" />
                ) : null
              )}
          </IconContainer>
        ),
      },
      { title: "Período", dataIndex: "period", key: "period" },
      { title: "Forrageira", dataIndex: "pasture", key: "pasture" },
      {
        title: "Suplemento/Concent.",
        dataIndex: "supplement",
        key: "supplement",
      },
      {
        title: "",
        dataIndex: "conflictedGdp",
        key: "conflictedGdp",
        render: (conflictedGdp) =>
          conflictedGdp ? (
            <Tooltip title="Dieta com GDP conflitante">
              <div className="gdpConflited">
                <AlertIcon />
              </div>
            </Tooltip>
          ) : null,
      },
    ];
    let columnData = [];
    const dsIndex = data.findIndex((ds) => ds.id === record.id);
    data[dsIndex].dietPeriods.forEach((dp) => {
      columnData.push({
        key: dp.id,
        period: `${moment(dp.startDate).format("MMMM YY")} à ${moment(
          dp.endDate
        ).format("MMMM YY")}`,
        season: dp.season,
        pasture: dp.pasture != null ? dp.pasture.name : null,
        supplement:
          dp.supplement != null
            ? `${dp.supplement.name} - Versão ${dp.supplementVersion}`
            : null,
        conflictedGdp: dp.conflictedGdp,
      });
    });

    return (
      <Table columns={columns} dataSource={columnData} pagination={false} />
    );
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
            background: "#684e94",
            borderColor: "none",
            border: "none",
          }}
        />
        <Button
          onClick={() => this.handleReset(clearFilters)}
          icon="delete"
          size="small"
          style={{ width: 90 }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (dataIndex === "lot.name") {
        return record.lot.name.includes(value);
      } else {
        return record[dataIndex].toString().includes(value);
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleAlertSelected = (alert) => {
    const {
      app: { groupSelected, farmSelected, alertSelected },
    } = this.props;
    const { appActions } = this.props;
    let ids = null;
    if (
      alertSelected &&
      alertSelected.id === alert.id &&
      alertSelected.failed === alert.failed
    ) {
      appActions.setAlertSelected({});
    } else {
      appActions.setAlertSelected(alert);
      ids = alert.entityIds.join(",");
    }
    this.fetchData(groupSelected, farmSelected, ids);
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleActivateOrInactivate = (
    id,
    dietstrategy = "activate" | "inactivate",
    action
  ) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      dietStrategyActions,
    } = this.props;
    dietStrategyActions.inactivateOrActivateDietStrategy(
      groupId,
      farmId,
      id,
      action,
      dietstrategy
    );
  };

  handleRefresh = () => {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;
    this.fetchData(groupSelected, farmSelected);
  };

  render() {
    const { alerts, alertsFilterDate } = this.state;

    const {
      app: { translation, alertSelected },
      dietStrategy: { isLoading: dietStrategyIsLoading, data },
    } = this.props;

    const Column = Table.Column;

    return (
      <Container>
        <Row
          type="flex"
          justify="start"
          gutter={4}
          align="middle"
          style={{ paddingLeft: "20px", marginTop: "10px" }}
        >
          <Col xs={24} sm={24} md={3} lg={3} xl={3}>
            <AlertDateIcon />
            <AlertDatePicker
              alertsFilterDate={alertsFilterDate}
              getAlerts={this.getAlerts}
            />
          </Col>
          {alerts?.length <= 0 ? (
            <span style={{ color: "#D44C4C", marginLeft: "10px" }}>
              {" "}
              Nenhum alerta de est. de dieta nessa data
            </span>
          ) : (
            alerts?.map((alert) => (
              <Col key={alert.id} xs={24} sm={24} md={5} lg={5} xl={5}>
                <AlertButton
                  alert={alert}
                  alertSelected={alertSelected}
                  handleAlertSelected={this.handleAlertSelected}
                />
              </Col>
            ))
          )}
        </Row>
        <CardCustom>
          <Row type="flex" justify="space-between" className="rowHeader">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Title>{translation.dietStrategy.title}</Title>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
              <Row type="flex" justify="end">
                <Col>
                  <ButtonStandard
                    buttonType="typeWithoutBackground"
                    onClick={this.handleRefresh}
                  >
                    <Icon type="reload" />
                  </ButtonStandard>
                </Col>
                <Col>
                  <Link to="/admin/managements/diets/strategies/new">
                    <ButtonStandard type="button" buttonType="type8">
                      {translation.dietStrategy.buttonNewDietStrategy}
                    </ButtonStandard>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
          <Table
            rowKey="id"
            size="small"
            expandedRowRender={this.expandedRows}
            dataSource={data != null ? data : []}
            scroll={{
              x: 1200,
              y: 758,
            }}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 10,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
              hideOnSinglePage: true,
            }}
            onChange={this.handleTableChange}
            loading={dietStrategyIsLoading}
          >
            <Column
              title={translation.dietStrategy.table.status}
              dataIndex="status"
              key="status"
              align="left"
              sorter={(first, second) => {
                return first.status.localeCompare(second.status, "pt-BR", {
                  numeric: false,
                  ignorePunctuation: true,
                });
              }}
              filtered
              filters={[
                {
                  text: translation.status.active,
                  value: "Active",
                },
                {
                  text: translation.status.inactive,
                  value: "Inactive",
                },
                {
                  text: "Pendente",
                  value: "Pending",
                },
              ]}
              filterMultiple
              defaultFilteredValue={["Active"]}
              onFilter={(value, record) => record.status.includes(value)}
              render={(status) =>
                status === "Active" ? (
                  <TagStatus
                    background="#C5F1CA"
                    borderColor="#106518"
                    color="#106518"
                  >
                    {translation.status.active}
                  </TagStatus>
                ) : status === "Inactive" ? (
                  <TagStatus
                    background="#FBC7C7"
                    borderColor="#D44C4C"
                    color="#D44C4C"
                  >
                    {translation.status.inactive}
                  </TagStatus>
                ) : status === "Pending" ? (
                  <TagStatus
                    background="#FFEAE1"
                    borderColor="#FF4E00"
                    color="#FF4E00"
                  >
                    Pendente
                  </TagStatus>
                ) : null
              }
            />
            <Column
              title={translation.dietStrategy.table.name}
              dataIndex="name"
              sorter={(first, second) => {
                return first.name.localeCompare(second.name, "pt-BR", {
                  numeric: false,
                  ignorePunctuation: true,
                });
              }}
              key="name"
              align="left"
              {...this.getColumnSearchProps("name")}
            />

            <Column
              title="Genérica"
              width={100}
              dataIndex="generic"
              key="generic"
              align="left"
              filtered
              filters={[
                {
                  text: translation.buttons.yes,
                  value: true,
                },
                {
                  text: translation.buttons.no,
                  value: false,
                },
              ]}
              filterMultiple={false}
              onFilter={(value, record) => record.generic === value}
              render={(text, record) =>
                record.generic === true ? (
                  <Icon
                    type="check-circle"
                    theme="filled"
                    className="iconCheck"
                  />
                ) : null
              }
            />

            <Column
              title="Lote"
              dataIndex="lot.name"
              key="lot.name"
              align="left"
              sorter={(first, second) => {
                var firstName = "";
                if (first.lot) {
                  firstName = first.lot.name;
                }
                return firstName.localeCompare(second.lot.name, "pt-BR", {
                  numeric: false,
                  ignorePunctuation: true,
                });
              }}
              {...this.getColumnSearchProps("lot.name")}
            />

            <Column
              title="Em Uso"
              dataIndex="lotsThatHaveThisStrategy"
              key="id"
              align="left"
              render={(lots, record, i) => {
                if (lots !== null && lots.length > 0) {
                  return (
                    <Tooltip
                      key={i}
                      title={lots.map((l) => (
                        <p style={{ margin: "0px", padding: "0px" }} key={l}>
                          {l}
                        </p>
                      ))}
                      trigger="hover"
                    >
                      <Icon
                        type="check-circle"
                        theme="filled"
                        className="iconCheck"
                      />
                    </Tooltip>
                  );
                } else {
                  return null;
                }
              }}
            />

            <Column
              title=""
              dataIndex="expired"
              key="expired"
              align="center"
              render={(expired) =>
                expired ? (
                  <Tooltip
                    title="Esta estratégia está vencida."
                    trigger="hover"
                  >
                    <div className="gdpConflited">
                      <AlertIcon />
                    </div>
                  </Tooltip>
                ) : null
              }
            />

            <Column
              align="left"
              render={(text, record) => (
                <Dropdown
                  overlay={this.menu(record.id, record)}
                  trigger={["click"]}
                  key={record.id}
                >
                  <MenuContainer>
                    <MenuIcon />
                  </MenuContainer>
                </Dropdown>
              )}
            />
          </Table>
        </CardCustom>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  dietStrategy: state.dietStrategy,
});

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  dietStrategyActions: bindActionCreators(DietStrategyActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DietStrategy)
);
