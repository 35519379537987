import apiAnimals from "../config/api_animals";
import getHeaders from "./getHeaders";

export function getEstimatedCurveByCurveId(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/curves/${id}`,
    getHeaders(false, signal)
  );
}

export function getLotEstimatedCurve(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/lots/${id}/curves`,
    getHeaders(false, signal)
  );
}

export function setLotEstimatedCurve(payload) {
  const { groupId, farmId, id, body, signal } = payload;
  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/lots/${id}/curves`,
    body,
    getHeaders(false, signal)
  );
}

export function getAnimalEstimatedCurve(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/${id}/curves`,
    getHeaders(false, signal)
  );
}

export function setAnimalEstimatedCurve(payload) {
  const { groupId, farmId, id, body, signal } = payload;
  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/animals/${id}/curves`,
    body,
    getHeaders(false, signal)
  );
}
