import React, { Fragment } from "react";
import { useSelector } from "react-redux";
/** Components */
import { Container } from "./styles";
import { Row, Col, Tooltip, Divider, Icon } from "antd";
import InfoIcon from "../../../components/utils/icons/info";
import StatusTrueIcon from "./icons/statusTrue";
import StatusFalseIcon from "./icons/statusFalse";
import { numberMask } from "../../../services/helpersMethodsService";

const DisplayAlerts = ({ data }) => {
  const { translation } = useSelector((state) => state.app);

  const tooltip6014 = {
    text1: (
      <p>
        1. O tempo determinado só é válido após primeira pesagem; <br />
        2. O animal deve passar na balança destinada a ele, ou seja, no piquete
        / baia que o lote do mesmo esta alocado.
      </p>
    ),
  };

  return (
    <Container>
      {data != null && (
        <Fragment>
          <Row type="flex">
            <Col span={24}>
              <span>
                {data.find((x) => x.code === 6001)?.status === "Active" ? (
                  <StatusTrueIcon />
                ) : (
                  <StatusFalseIcon />
                )}
                Alertar caso GDP de <strong>Animal</strong> seja
                <strong>
                  {data.find((x) => x.code === 6001)?.value}% inferior
                </strong>{" "}
                ao GDP da Estratégia de Dieta
              </span>
            </Col>
          </Row>
          <Divider dashed="true" style={{ margin: "10px 0px" }} />
          <Row type="flex">
            <Col span={24}>
              <span>
                {data.find((x) => x.code === 6002)?.status === "Active" ? (
                  <StatusTrueIcon />
                ) : (
                  <StatusFalseIcon />
                )}
                Alertar caso GDP de <strong>Lote</strong> seja
                <strong>
                  {data.find((x) => x.code === 6002)?.value}% inferior
                </strong>{" "}
                ao GDP da Estratégia de Dieta
              </span>
            </Col>
          </Row>
          <Divider dashed="true" style={{ margin: "10px 0px" }} />
          <Row type="flex">
            <Col span={24}>
              <span>
                {data.find((x) => x.code === 6003)?.status === "Active" ? (
                  <StatusTrueIcon />
                ) : (
                  <StatusFalseIcon />
                )}
                Alertar caso GDP de <strong>Animal</strong> seja
                <strong>
                  {data.find((x) => x.code === 6003)?.value}% superior
                </strong>{" "}
                ao GDP da Estratégia de Dieta
              </span>
            </Col>
          </Row>
          <Divider dashed="true" style={{ margin: "10px 0px" }} />
          <Row type="flex">
            <Col span={24}>
              <span>
                {data.find((x) => x.code === 6004)?.status === "Active" ? (
                  <StatusTrueIcon />
                ) : (
                  <StatusFalseIcon />
                )}
                Alertar caso GDP de <strong>Lote</strong> seja
                <strong>
                  {data.find((x) => x.code === 6004)?.value}% superior
                </strong>{" "}
                ao GDP da Estratégia de Dieta
              </span>
            </Col>
          </Row>
          <Divider dashed="true" style={{ margin: "10px 0px" }} />
          <Row type="flex">
            <Col span={24}>
              <Row type="flex">
                <span>
                  {data
                    .find((x) => x.code === 6005)
                    ?.items?.some((item) => item.status === "Active") ? (
                    <StatusTrueIcon />
                  ) : (
                    <StatusFalseIcon />
                  )}
                  Alertar vencimento de Cenários de Venda selecionados como
                  Favoritos
                </span>
              </Row>
              <Row type="flex" justify="center">
                {data
                  .find((x) => x.code === 6005)
                  ?.items?.find((item) => item.value === "30")?.status ===
                  "Active" ? (
                  <Col span={6} justify="center">
                    <span className="sub-title-value">Faltando 30 dias</span>
                  </Col>
                ) : null}
                {data
                  .find((x) => x.code === 6005)
                  ?.items?.find((item) => item.value === "14")?.status ===
                  "Active" ? (
                  <Col span={6} justify="center">
                    <span className="sub-title-value">Faltando 14 dias</span>
                  </Col>
                ) : null}
                {data
                  .find((x) => x.code === 6005)
                  ?.items?.find((item) => item.value === "7")?.status ===
                  "Active" ? (
                  <Col span={6} justify="center">
                    <span className="sub-title-value">Faltando 7 dias</span>
                  </Col>
                ) : null}
                {data
                  .find((x) => x.code === 6005)
                  ?.items?.find((item) => item.value === "0")?.status ===
                  "Active" ? (
                  <Col span={6} justify="center">
                    <span className="sub-title-value">Ao vencer o cenário</span>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
          <Divider dashed="true" style={{ margin: "10px 0px" }} />
          <Row type="flex">
            <Col span={24}>
              <span>
                {data.find((x) => x.code === 6006)?.status === "Active" ? (
                  <StatusTrueIcon />
                ) : (
                  <StatusFalseIcon />
                )}
                Alertar caso valor da Lotação for inferior à Meta de Lotação
                definida para a Safra
              </span>
            </Col>
          </Row>
          <Divider dashed="true" style={{ margin: "10px 0px" }} />
          <Row type="flex">
            <Col span={24}>
              <span>
                {data.find((x) => x.code === 6007)?.status === "Active" ? (
                  <StatusTrueIcon />
                ) : (
                  <StatusFalseIcon />
                )}
                Alertar caso custo da "@" da Estratégia de Dieta esteja superior
                à Meta para Custo definida para a Safra
              </span>
            </Col>
          </Row>
          <Divider dashed="true" style={{ margin: "10px 0px" }} />
          <Row type="flex">
            <Col span={24}>
              <span>
                {data.find((x) => x.code === 6008)?.status === "Active" ? (
                  <StatusTrueIcon />
                ) : (
                  <StatusFalseIcon />
                )}
                Alertar caso animal estiver abaixo da média estimada para o Lote
                - Animal de Fundo
              </span>
            </Col>
          </Row>
          <Divider dashed="true" style={{ margin: "10px 0px" }} />
          <Row type="flex">
            <Col span={24}>
              <span>
                {data.find((x) => x.code === 6009)?.status === "Active" ? (
                  <StatusTrueIcon />
                ) : (
                  <StatusFalseIcon />
                )}
                Alertar caso custo da "@" de um Lote esteja superior à Meta para
                Custo definida para a Safra
              </span>
            </Col>
          </Row>
          <Divider dashed="true" style={{ margin: "10px 0px" }} />
          {/* <Row type="flex">
            <Col span={24}>
              <span>
                {data.find(x=>x.code === 6010)?.status === "Active" ? (<StatusTrueIcon />) : (<StatusFalseIcon />) }
                Alertar caso Capacidade de Suporte não seja suficiente para Cenário de Compra ou Venda
              </span>
            </Col>
          </Row>
          <Divider dashed="true" style={{ margin: "10px 0px"}}/>*/}
          <Row type="flex">
            <Col span={24}>
              <span>
                {data.find((x) => x.code === 6011)?.status === "Active" ? (
                  <StatusTrueIcon />
                ) : (
                  <StatusFalseIcon />
                )}
                Alertar caso Estrutura do Piquete / Baia não for compatível com
                a Dieta estabelecida para o Lote alocado
              </span>
            </Col>
          </Row>
          {/* <Divider dashed="true" style={{ margin: "10px 0px"}}/>
          <Row type="flex">
            <Col span={24}>
              <span>
                {data.find(x=>x.code === 6012)?.status === "Active" ? (<StatusTrueIcon />) : (<StatusFalseIcon />) }
                Alertar caso Cenário de Decisão possua animais não disponíveis
              </span>
            </Col>
          </Row> */}
          <Divider dashed="true" style={{ margin: "10px 0px" }} />
          <Row type="flex">
            <Col span={24}>
              <span>
                {data.find((x) => x.code === 6013)?.status === "Active" ? (
                  <StatusTrueIcon />
                ) : (
                  <StatusFalseIcon />
                )}
                Alertar caso Animal passe em balança não destinada a ele
              </span>
            </Col>
          </Row>
          <Divider dashed="true" style={{ margin: "10px 0px" }} />
          <Row type="flex">
            <Col span={23}>
              <span>
                {data.find((x) => x.code === 6014)?.status === "Active" ? (
                  <StatusTrueIcon />
                ) : (
                  <StatusFalseIcon />
                )}
                Alertar caso não haja recorrência de pesagem de animal em{" "}
                <strong>
                  {data.find((x) => x.code === 6014)?.value}
                  {data.find((x) => x.code === 6014)?.valueExtra === "Days"
                    ? " dias"
                    : " horas"}
                </strong>{" "}
                após a última
              </span>
            </Col>
            <Col span={1} style={{ textAlign: "right" }}>
              <Tooltip title={tooltip6014.text1}>
                <InfoIcon />
              </Tooltip>
              <Tooltip title="Necessário efetuar cadastro de balança">
                <Icon type="exclamation-circle" className="iconError" />
              </Tooltip>
            </Col>
          </Row>
          <Divider dashed="true" style={{ margin: "10px 0px" }} />
          <Row type="flex">
            <Col span={24}>
              <span>
                {data.find((x) => x.code === 6015)?.status === "Active" ? (
                  <StatusTrueIcon />
                ) : (
                  <StatusFalseIcon />
                )}
                Alertar caso Valor Atualizado da "@" varie{" "}
                <strong>{data.find((x) => x.code === 6015)?.value}% </strong>{" "}
                para mais ou para menos em relação ao valor estabelecido nas
                Estratégias de dieta Favoritas
              </span>
            </Col>
          </Row>
          <Divider dashed="true" style={{ margin: "10px 0px" }} />
          <Row type="flex">
            <Col span={23}>
              <span>
                {data.find((x) => x.code === 6016)?.status === "Active" ? (
                  <StatusTrueIcon />
                ) : (
                  <StatusFalseIcon />
                )}
                Alertar caso exista a possibilidade do valor do consumo hídrico
                do animal estar fora da faixa destinada para sua idade e raça
              </span>
            </Col>
            <Col span={1} style={{ textAlign: "right" }}>
              <Tooltip title="Necessário efetuar cadastro de balança">
                <Icon type="exclamation-circle" className="iconError" />
              </Tooltip>
            </Col>
          </Row>
          <Divider dashed="true" style={{ margin: "10px 0px" }} />
          <Row type="flex">
            <Col span={23}>
              <span>
                {data
                  .find((x) => x.code === 6017)
                  ?.items?.some((item) => item.status === "Active") ? (
                  <StatusTrueIcon />
                ) : (
                  <StatusFalseIcon />
                )}
                Alertar caso valor da Permanência for superior à Meta de Ciclo
                Médio definida para cada lote
              </span>
            </Col>
            <Col span={1} style={{ textAlign: "right" }}>
              <Tooltip title="Permanência é definida pelo tempo total que o animal está na fazenda">
                <InfoIcon />
              </Tooltip>
            </Col>
          </Row>
          <Divider dashed="true" style={{ margin: "10px 0px" }} />
          <Row type="flex">
            <Col span={24}>
              <span>
                {data.find((x) => x.code === 6018)?.status === "Active" ? (
                  <StatusTrueIcon />
                ) : (
                  <StatusFalseIcon />
                )}
                Alertar caso existam piquetes com a capacidade atual da pastagem
                inferior a
                <strong>
                  {data.find((x) => x.code === 6018)?.value}
                  {data.find((x) => x.code === 6018)?.valueExtra}
                </strong>
              </span>
            </Col>
          </Row>
          <Divider dashed="true" style={{ margin: "10px 0px" }} />
          <Row type="flex">
            <Col span={24}>
              <span>
                {data.find((x) => x.code === 6019)?.status === "Active" ? (
                  <StatusTrueIcon />
                ) : (
                  <StatusFalseIcon />
                )}
                Alertar caso existam piquetes com a capacidade atual da pastagem
                em 100 % há
                <strong>{data.find((x) => x.code === 6019)?.value} dias</strong>
              </span>
            </Col>
          </Row>
          <Divider dashed="true" style={{ margin: "10px 0px" }} />
          <Row type="flex">
            <Col span={24}>
              <span>
                {data
                  .find((x) => x.code === 6020)
                  ?.items?.some(
                    (item) =>
                      item.valueExtra !== null && item.valueExtra !== "0"
                  ) ? (
                  <StatusTrueIcon />
                ) : (
                  <StatusFalseIcon />
                )}
                Alertar caso existam lotes com Pegada Carbônica superior a média
                das atividades abaixo:
              </span>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col>
              <span className="sub-title-value">
                {`${translation.costType.RecreateFattenPasture}: ${numberMask(
                  Number(
                    data
                      .find((x) => x.code === 6020)
                      ?.items.find(
                        (item) => item.value === "RecreateFattenPasture"
                      )?.valueExtra
                  ) || 0,
                  false
                )} `}
                kg CO<sub>2</sub>E / kg GDP
              </span>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col>
              <span className="sub-title-value">
                {`${translation.costType.RecreateFattenSemiConfinement
                  }: ${numberMask(
                    Number(
                      data
                        .find((x) => x.code === 6020)
                        ?.items.find(
                          (item) => item.value === "RecreateFattenSemiConfinement"
                        )?.valueExtra
                    ) || 0,
                    false
                  )} `}
                kg CO<sub>2</sub>E / kg GDP
              </span>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col>
              <span className="sub-title-value">
                {`${translation.costType.RecreateFattenConfinement
                  }: ${numberMask(
                    Number(
                      data
                        .find((x) => x.code === 6020)
                        ?.items.find(
                          (item) => item.value === "RecreateFattenConfinement"
                        )?.valueExtra
                    ) || 0,
                    false
                  )} `}
                kg CO<sub>2</sub>E / kg GDP
              </span>
            </Col>
          </Row>
          <Divider dashed="true" style={{ margin: "10px 0px" }} />
          <Row type="flex">
            <Col span={24}>
              <span>
                {data.find((x) => x.code === 6021)?.status === "Active" ? (
                  <StatusTrueIcon />
                ) : (
                  <StatusFalseIcon />
                )}
                Alertar caso existam piquetes com altura do pasto <strong>inferior</strong> à altura de <strong>saída</strong>.
              </span>
            </Col>
          </Row>
          <Divider dashed="true" style={{ margin: "10px 0px" }} />
          <Row type="flex">
            <Col span={24}>
              <span>
                {data.find((x) => x.code === 6022)?.status === "Active" ? (
                  <StatusTrueIcon />
                ) : (
                  <StatusFalseIcon />
                )}
                Alertar caso existam piquetes com altura do pasto <strong>superior</strong> à altura de <strong>entrada</strong>.
              </span>
            </Col>
          </Row>

        </Fragment>
      )}
    </Container>
  );
};

export default DisplayAlerts;
