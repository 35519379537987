import apiFarms from "../config/api_farms";
import getHeaders from "./getHeaders";

export function findAll(payload) {
  const { groupId, farmId } = payload;
  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/weather/station`,
    getHeaders(false, null)
  );
}

