import React from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as BreedReproductionScoreActions } from "../../../../store/ducks/breedReproductionScore";

import { FiMinus, FiPlus } from "react-icons/fi";
import { CustomCollapse } from "../styles";
import { Icon, notification, Row, Spin } from "antd";
import ButtonStandard from "../../../../components/utils/button";
import { TableScore } from "./styles";
import InfoTooltip from "../../../../components/utils/infoTooltip";
import TrashIcon from "../../../../components/utils/icons/trash";
import DrawerBreedReproductionScore from "../../../../components/drawers/breedReproductionScore";

// Services
import { getTwoDecimalDigits } from "../../../../services/helpersMethodsService";
import { deleteBreedReproductionScore } from "../../../../services/breedReproductionScoreService";

// import { Container } from './styles';

function CardBreedReproductionScore() {
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  // Redux
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { listAllData: data, isLoadingGetListAllData: isLoadingData } =
    useSelector((state) => state.breedReproductionScore);

  const dispatch = useDispatch();

  // Callbacks
  const deleteRecord = useCallback(
    async (id) => {
      setIsLoadingRequest(true);
      try {
        await deleteBreedReproductionScore({ groupId, farmId, id });
        notification.success({
          message: "Registro deletado com sucesso !",
        });
        dispatch(BreedReproductionScoreActions.getAllData(groupId, farmId));
      } catch (error) {
        notification.error({
          message: "Não foi possível deletar registro. Contate o suporte.",
        });
      } finally {
        setIsLoadingRequest(false);
      }
    },
    [dispatch, farmId, groupId]
  );
  const fetch = useCallback(async () => {
    dispatch(BreedReproductionScoreActions.getAllData(groupId, farmId));
  }, [dispatch, farmId, groupId]);

  // Memos
  const arrayWeights = useMemo(() => {
    let arrayWeights = [];
    if (data && data.length > 0) {
      data.forEach((d) => {
        d.ages.forEach((ar) => {
          ar.weightsAndScore.forEach((ws) => {
            if (arrayWeights.length === 0) {
              arrayWeights.push(ws.weight);
            } else if (!arrayWeights.includes(ws.weight)) {
              arrayWeights.push(ws.weight);
            }
          });
        });
      });
      arrayWeights = arrayWeights.sort((a, b) => a - b);
    }
    return arrayWeights;
  }, [data]);

  const renderColumns = useMemo(() => {
    return arrayWeights.map((weight) => (
      <th>{`${getTwoDecimalDigits(weight, 0)}`}</th>
    ));
  }, [arrayWeights]);

  const renderDataBody = useMemo(() => {
    return data?.map((d) => (
      <>
        <tr key={d.breedId}>
          <td className="colBreed" rowSpan={d.ages.length + 1}>
            {d.breedName}
          </td>
        </tr>
        {d.ages.map((arc) => (
          <tr key={arc.id}>
            <td className="colCategory">{`${arc.age} meses`}</td>
            <td>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={() => deleteRecord(arc.id)}
              >
                <TrashIcon />
              </ButtonStandard>
            </td>
            {arrayWeights.map((weight) =>
              arc.weightsAndScore.find((ws) => ws.weight === weight) ? (
                <td key={`${arc.id}_${weight}`}>
                  {arc.weightsAndScore.find((ws) => ws.weight === weight).score}
                </td>
              ) : (
                <td key={`${arc.id}_${weight}`}>-</td>
              )
            )}
          </tr>
        ))}
      </>
    ));
  }, [arrayWeights, data, deleteRecord]);

  // Effects
  useEffect(() => {
    fetch();
  }, [fetch]);

  // Methods
  function openOrCloseDrawer() {
    dispatch(BreedReproductionScoreActions.showOrHideDrawer());
  }

  return (
    <CustomCollapse
      expandIconPosition={"right"}
      expandIcon={(panelProps) =>
        panelProps.isActive ? <FiMinus size={16} /> : <FiPlus size={16} />
      }
    >
      <CustomCollapse.Panel
        header={
          <strong>
            {translation.reproductionParameters.breedScore.title}{" "}
            <InfoTooltip
              title={translation.reproductionParameters.breedScore.titleHelper}
            />
          </strong>
        }
        key={"1"}
      >
        <Spin spinning={isLoadingData || isLoadingRequest}>
          <Row type="flex" justify="end" gutter={8}>
            <ButtonStandard
              buttonType="typeWithoutBackground"
              disabled={isLoadingData}
              onClick={fetch}
            >
              <Icon type="reload" />
            </ButtonStandard>
            <ButtonStandard
              name="buttonCreateBudget"
              buttonType="type8"
              onClick={openOrCloseDrawer}
            >
              Novo registro
            </ButtonStandard>
          </Row>
          <Row className="bodyContent">
            {data.length === 0 ? (
              <span>Nenhum dado registrado</span>
            ) : (
              <TableScore>
                <thead>
                  <tr>
                    <th className="colBreed">Raça</th>
                    <th className="colCategory">Idade</th>
                    <th></th>
                    {renderColumns}
                  </tr>
                </thead>
                <tbody>{renderDataBody}</tbody>
              </TableScore>
            )}
          </Row>
        </Spin>
      </CustomCollapse.Panel>
      <DrawerBreedReproductionScore />
    </CustomCollapse>
  );
}

export default CardBreedReproductionScore;
