import React from 'react';

const MenuVerticalIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="3" height="13" viewBox="0 0 3 13">
  <g id="Btn_3_pontos" data-name="Btn 3 pontos" transform="translate(-1 14) rotate(-90)">
    <g id="Unhover">
      <circle id="Ellipse_163" data-name="Ellipse 163" cx="1.5" cy="1.5" r="1.5" transform="translate(1 1)" fill="#fff"/>
      <circle id="Ellipse_164" data-name="Ellipse 164" cx="1.5" cy="1.5" r="1.5" transform="translate(6 1)" fill="#fff"/>
      <circle id="Ellipse_226" data-name="Ellipse 226" cx="1.5" cy="1.5" r="1.5" transform="translate(11 1)" fill="#fff"/>
    </g>
  </g>
</svg>
);

export default MenuVerticalIcon;
