import { Col, Row, Spin } from "antd";
import React from "react";
import CardTransactions from "./cards/CardTransactions";
import CardAccountsPlayableAndReceivable from "./cards/CardAccountsPlayableAndReceivable";
import CardBankAccountTransactions from "./cards/CardBankAccountTransactions";
import useFinancialTransactionDashboardContext from "../../../../hooks/useFinancialTransactionDashboardContext";

// import { Container } from './styles';

function FinancialDashboardResult() {
  const { isLoadingRequest } = useFinancialTransactionDashboardContext();
  return (
    <Spin spinning={isLoadingRequest}>
      <Row type="flex" justify="space-between" gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
          <CardTransactions />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
          <CardAccountsPlayableAndReceivable />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
          <CardBankAccountTransactions />
        </Col>
      </Row>
    </Spin>
  );
}

export default FinancialDashboardResult;
