import styled from "styled-components";

export const Container = styled.div`
  overflow-x: hidden;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  div.title-container {
    display: flex;
  }

  div.boitel {
    padding-right: 10px;
    span {
      font-weight: normal;
      font-size: 14px;
    }
  }

  div.title {
    padding-right: 14px;
    span {
      font-weight: bold;
      font-size: 18px;
    }
  }
  div.amountAnimals {
    padding: 0 14px;

    label {
      font-family: Asap;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: inherit;
    }

    span {
      font-family: Asap;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: #a9c133;
    }
  }
  div.providerAndDate {
    padding-left: 14px;
    padding-right: 14px;
    span {
      font-weight: bold;
      font-size: 16px;
    }
  }
  div.receinvingDate {
    padding-left: 14px;
    span {
      font-family: Asap;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: #c4c4c4;
    }
  }
`;
