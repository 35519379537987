import { call, put } from "redux-saga/effects";
import { Creators as GeneralParametersActions } from "../ducks/generalParameters";
import { getAllWeatherSeasons } from "../../services/generalParameterService";

export function* indexWeatherSeasons(action) {
  try {
    const {
      data: { results },
    } = yield call(getAllWeatherSeasons, action.payload);

    yield put(GeneralParametersActions.listWeatherSeasonsSuccess(results));
  } catch (error) {
    yield put(GeneralParametersActions.listWeatherSeasonsError(error));
  }
}
