import React from 'react';

// import { Container } from './styles';

const GroupIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="66.308"
    height="66.268"
    viewBox="0 0 66.308 66.268"
  >
    <g id="Agrupar_266" data-name="Agrupar 266" transform="translate(-1500.8 1523.31)">
      <path
        id="Caminho_708"
        data-name="Caminho 708"
        d="M1500.8-1324.024a3.4,3.4,0,0,1,.458-.524c.724-.553,1.623-.066,1.7.946.09,1.154.141,2.312.183,3.469a1.06,1.06,0,0,1-.973,1.208,1.072,1.072,0,0,1-1.142-1.055c-.084-.57-.153-1.142-.229-1.714Z"
        transform="translate(0 -166.398)"
        fill="#C4C4C4"
      />
      <path
        id="Caminho_709"
        data-name="Caminho 709"
        d="M1691.638-1523.31a5.981,5.981,0,0,1,.692.145.994.994,0,0,1,.744,1.076.912.912,0,0,1-.869.961c-1.355.05-2.714.051-4.069.006a.922.922,0,0,1-.893-1.007.992.992,0,0,1,.854-1.055c.228-.053.461-.084.692-.126Z"
        transform="translate(-156.267)"
        fill="#C4C4C4"
      />
      <path
        id="Caminho_710"
        data-name="Caminho 710"
        d="M1805.417-1487.974a5.877,5.877,0,0,0-1.97,3.9c-.08.943-.018,1.9-.039,2.846-.01.443.183.621.617.62q5.954-.007,11.909,0c.435,0,.641-.184.61-.626-.1-1.392-.17-2.786-.3-4.175-.064-.687.128-1.148.666-1.283.582-.145,1.033.157,1.233.839a15.021,15.021,0,0,1,.281,5.333,2.224,2.224,0,0,1-2.446,1.852c-3.02.011-6.041.005-9.061.006-.992,0-1.985.011-2.977-.005a2.263,2.263,0,0,1-2.461-2.464,30.425,30.425,0,0,1,.318-4.558,6.381,6.381,0,0,1,2.272-3.675.754.754,0,0,0,.282-.829,5.627,5.627,0,0,1,2.759-6.1,5.568,5.568,0,0,1,6.747.85,5.392,5.392,0,0,1,1.389,6,5.4,5.4,0,0,1-4.565,3.7,5.476,5.476,0,0,1-5.053-1.861A1.948,1.948,0,0,1,1805.417-1487.974Zm4.523-7.126a3.756,3.756,0,0,0-3.78,3.7,3.8,3.8,0,0,0,3.757,3.808,3.8,3.8,0,0,0,3.75-3.75A3.772,3.772,0,0,0,1809.94-1495.1Z"
        transform="translate(-252.015 -22.006)"
        fill="#C4C4C4"
      />
      <path
        id="Caminho_711"
        data-name="Caminho 711"
        d="M1805.312-1244.112a5.862,5.862,0,0,0-1.884,3.828c-.074.944-.016,1.9-.039,2.847-.011.442.175.629.609.628q5.955-.006,11.91,0c.426,0,.647-.175.616-.623-.095-1.371-.156-2.745-.289-4.112-.071-.733.1-1.2.666-1.342.594-.155,1.034.167,1.244.906a15.44,15.44,0,0,1,.272,5.208,2.241,2.241,0,0,1-2.466,1.911c-1.661.015-3.323,0-4.984,0-2.266,0-4.531.006-6.8,0-1.86,0-2.755-.87-2.711-2.741a28.045,28.045,0,0,1,.305-4.238,6.474,6.474,0,0,1,2.3-3.734.754.754,0,0,0,.265-.836,5.65,5.65,0,0,1,2.446-5.9,5.544,5.544,0,0,1,6.283.024,5.594,5.594,0,0,1,2.4,5.921,5.466,5.466,0,0,1-4.176,4.314,5.482,5.482,0,0,1-5.551-1.616C1805.594-1243.8,1805.468-1243.943,1805.312-1244.112Zm4.628-7.185a3.76,3.76,0,0,0-3.8,3.748,3.791,3.791,0,0,0,3.742,3.754,3.8,3.8,0,0,0,3.765-3.734A3.755,3.755,0,0,0,1809.939-1251.3Z"
        transform="translate(-251.996 -226.331)"
        fill="#C4C4C4"
      />
      <path
        id="Caminho_712"
        data-name="Caminho 712"
        d="M1508.833-1244.041a5.787,5.787,0,0,0-1.875,3.815c-.078.943-.016,1.9-.04,2.846-.011.447.183.625.613.625q5.955,0,11.91,0c.428,0,.645-.172.614-.62-.1-1.392-.169-2.786-.295-4.176-.063-.693.124-1.138.67-1.282.576-.152,1.03.153,1.228.84a15.127,15.127,0,0,1,.278,5.334,1.991,1.991,0,0,1-1.676,1.757,3.747,3.747,0,0,1-.9.093q-5.826.009-11.651.005a2.486,2.486,0,0,1-2.02-.675,2.192,2.192,0,0,1-.646-1.219,34.973,34.973,0,0,1,.253-5.078,6.3,6.3,0,0,1,2.282-3.74.763.763,0,0,0,.276-.832,5.726,5.726,0,0,1,3.343-6.407,5.644,5.644,0,0,1,6.848,1.957,5.365,5.365,0,0,1,.606,5.524,5.431,5.431,0,0,1-4.531,3.4,5.5,5.5,0,0,1-4.985-1.882C1509.053-1243.846,1508.955-1243.927,1508.833-1244.041Zm4.581-7.2a3.742,3.742,0,0,0-3.742,3.733,3.792,3.792,0,0,0,3.734,3.764,3.792,3.792,0,0,0,3.773-3.789A3.738,3.738,0,0,0,1513.414-1251.238Z"
        transform="translate(-3.558 -226.385)"
        fill="#C4C4C4"
      />
      <path
        id="Caminho_713"
        data-name="Caminho 713"
        d="M1508.533-1488.021a5.694,5.694,0,0,0-1.883,3.859c-.072.923-.014,1.855-.041,2.782-.014.488.212.642.664.641q5.891-.01,11.781,0c.485,0,.732-.159.693-.675-.1-1.37-.168-2.743-.3-4.111-.069-.726.137-1.193.714-1.309s1,.19,1.2.89a15.629,15.629,0,0,1,.27,5.273,2.229,2.229,0,0,1-2.432,1.869c-2.2.014-4.4.008-6.6.008q-2.621,0-5.243-.005c-1.817,0-2.725-.88-2.677-2.709a28.094,28.094,0,0,1,.334-4.363,6.444,6.444,0,0,1,2.249-3.62.76.76,0,0,0,.3-.823,5.446,5.446,0,0,1,2.364-5.867,5.452,5.452,0,0,1,6.165-.159,5.71,5.71,0,0,1,2.6,6.014,5.76,5.76,0,0,1-4.729,4.454,5.6,5.6,0,0,1-5.206-1.955A2.913,2.913,0,0,0,1508.533-1488.021Zm8.336-3.494a3.765,3.765,0,0,0-3.765-3.718,3.764,3.764,0,0,0-3.745,3.739,3.812,3.812,0,0,0,3.8,3.77A3.8,3.8,0,0,0,1516.869-1491.515Z"
        transform="translate(-3.249 -21.873)"
        fill="#C4C4C4"
      />
      <path
        id="Caminho_714"
        data-name="Caminho 714"
        d="M1898.267-1323.22c-.08.921-.139,1.845-.246,2.763a1.043,1.043,0,0,1-1.154,1.038,1.055,1.055,0,0,1-.959-1.222c.045-1.182.1-2.364.187-3.544a1.088,1.088,0,0,1,1.139-1.138c.627.04,1,.505,1.015,1.26,0,.28,0,.559,0,.839Z"
        transform="translate(-331.159 -165.942)"
        fill="#C4C4C4"
      />
      <path
        id="Caminho_715"
        data-name="Caminho 715"
        d="M1501.936-1365.9c.232-1.225.438-2.456.705-3.673a1.041,1.041,0,0,1,1.241-.855,1.086,1.086,0,0,1,.834,1.328c-.185,1.168-.394,2.333-.616,3.494a1.078,1.078,0,0,1-1.167.965A1.171,1.171,0,0,1,1501.936-1365.9Z"
        transform="translate(-0.952 -128.122)"
        fill="#C4C4C4"
      />
      <path
        id="Caminho_716"
        data-name="Caminho 716"
        d="M1643.076-1517.006a1.034,1.034,0,0,1-1.111-.8.892.892,0,0,1,.535-1.175c1.36-.39,2.74-.723,4.129-.991.513-.1.887.3,1.013.815a.905.905,0,0,1-.571,1.165C1645.731-1517.608,1644.363-1517.317,1643.076-1517.006Z"
        transform="translate(-118.281 -2.783)"
        fill="#C4C4C4"
      />
      <path
        id="Caminho_717"
        data-name="Caminho 717"
        d="M1734.287-1520.587c1.311.333,2.632.635,3.929,1.015a.989.989,0,0,1,.635,1.255,1.054,1.054,0,0,1-1.253.761q-1.827-.4-3.638-.868a1.025,1.025,0,0,1-.807-1.215A1.207,1.207,0,0,1,1734.287-1520.587Z"
        transform="translate(-194.73 -2.282)"
        fill="#C4C4C4"
      />
      <path
        id="Caminho_718"
        data-name="Caminho 718"
        d="M1778.065-1507.613a8.052,8.052,0,0,1,.845.285c1.007.49,2.015.978,3.007,1.5a1.1,1.1,0,0,1,.6,1.551,1.112,1.112,0,0,1-1.619.357c-1.039-.522-2.081-1.039-3.113-1.574a.93.93,0,0,1-.529-1.237A3.561,3.561,0,0,1,1778.065-1507.613Z"
        transform="translate(-231.647 -13.156)"
        fill="#C4C4C4"
      />
      <path
        id="Caminho_719"
        data-name="Caminho 719"
        d="M1505.672-1277.87a1.2,1.2,0,0,1,.923-1.117.907.907,0,0,1,1.166.561c.433,1.3.826,2.621,1.166,3.95a.9.9,0,0,1-.7,1.085.912.912,0,0,1-1.218-.452C1506.514-1275.168,1506.111-1276.525,1505.672-1277.87Z"
        transform="translate(-4.083 -204.747)"
        fill="#C4C4C4"
      />
      <path
        id="Caminho_720"
        data-name="Caminho 720"
        d="M1894.67-1366.5a1.156,1.156,0,0,1-.935,1.24,1.052,1.052,0,0,1-1.227-.953c-.224-1.139-.432-2.281-.617-3.427a1.089,1.089,0,0,1,.826-1.385,1.069,1.069,0,0,1,1.271.98C1894.239-1368.866,1894.445-1367.68,1894.67-1366.5Z"
        transform="translate(-327.773 -127.62)"
        fill="#C4C4C4"
      />
      <path
        id="Caminho_721"
        data-name="Caminho 721"
        d="M1888.625-1278.061c-.438,1.346-.839,2.706-1.334,4.03a.907.907,0,0,1-1.22.445.986.986,0,0,1-.713-1.038,1.269,1.269,0,0,1,.042-.32c.347-1.175.683-2.354,1.057-3.521a1.018,1.018,0,0,1,1.2-.748A1.233,1.233,0,0,1,1888.625-1278.061Z"
        transform="translate(-322.318 -204.579)"
        fill="#C4C4C4"
      />
      <path
        id="Caminho_722"
        data-name="Caminho 722"
        d="M1665.962-1129.556c1.219.13,2.44.238,3.654.4a1.066,1.066,0,0,1,.957,1.175,1.085,1.085,0,0,1-1.186.953c-1.241-.115-2.482-.257-3.713-.449a1.016,1.016,0,0,1-.915-1.194A1.032,1.032,0,0,1,1665.962-1129.556Z"
        transform="translate(-137.413 -330.022)"
        fill="#C4C4C4"
      />
      <path
        id="Caminho_723"
        data-name="Caminho 723"
        d="M1621.817-1142.88c.14.04.349.088.55.16.993.354,1.983.714,2.974,1.071.791.286,1.117.807.91,1.459-.195.614-.821.859-1.607.583-1.1-.384-2.188-.781-3.267-1.208a1.009,1.009,0,0,1-.663-1.25A1.042,1.042,0,0,1,1621.817-1142.88Z"
        transform="translate(-100.477 -318.858)"
        fill="#C4C4C4"
      />
      <path
        id="Caminho_724"
        data-name="Caminho 724"
        d="M1712.052-1127.172c-.9,0-1.4-.435-1.436-1.027a1.107,1.107,0,0,1,1.022-1.13c1.172-.151,2.346-.291,3.523-.4a1.046,1.046,0,0,1,1.267.9,1.04,1.04,0,0,1-.97,1.215C1714.248-1127.426,1713.029-1127.3,1712.052-1127.172Z"
        transform="translate(-175.856 -329.871)"
        fill="#C4C4C4"
      />
      <path
        id="Caminho_725"
        data-name="Caminho 725"
        d="M1600.548-1502.808c-.24-.237-.623-.47-.782-.808a.958.958,0,0,1,.455-1.275c.59-.352,1.2-.671,1.813-.984.478-.244.967-.47,1.461-.681a1.085,1.085,0,0,1,1.535.479,1.058,1.058,0,0,1-.587,1.444c-1.048.545-2.107,1.071-3.166,1.6A6.032,6.032,0,0,1,1600.548-1502.808Z"
        transform="translate(-82.867 -13.932)"
        fill="#C4C4C4"
      />
      <path
        id="Caminho_726"
        data-name="Caminho 726"
        d="M1761.532-1141.611c-.132.148-.333.563-.651.7-1.187.5-2.406.924-3.627,1.334a1.023,1.023,0,0,1-1.262-.627.993.993,0,0,1,.5-1.314c1.255-.5,2.526-.973,3.816-1.374C1760.957-1143.1,1761.551-1142.529,1761.532-1141.611Z"
        transform="translate(-213.823 -318.812)"
        fill="#C4C4C4"
      />
      <g id="Agrupar_265" data-name="Agrupar 265" transform="translate(1520.722 -1499.763)">
        <g id="Agrupar_264" data-name="Agrupar 264" transform="translate(0 0)">
          <path
            id="Caminho_727"
            data-name="Caminho 727"
            d="M1645.135-1375.724h-12.008v-.486h-1.286a4.552,4.552,0,0,0,.654-.912l-1.281-.7a3.081,3.081,0,0,1-2.707,1.609,3.08,3.08,0,0,1-2.708-1.612l-1.282.7a4.55,4.55,0,0,0,.657.916h-1.286v.729a1.168,1.168,0,0,0,1.167,1.167h.541l.639,3.513a1.7,1.7,0,0,0,1.674,1.4h.564a10.087,10.087,0,0,0,2.946,2.562l.608,6.19h2.218l1.1-4.919a9.991,9.991,0,0,0,1.065.057h6.529l.335,1.059-.471,3.8h2.321l2.2-6.5v2.373h1.458v-7.293A3.65,3.65,0,0,0,1645.135-1375.724Zm-15.79,4.662a.243.243,0,0,1-.239.2h-1.2a.243.243,0,0,1-.239-.2l-.671-3.69h3.017Zm17.977-.65-2.653,7.845.09-.726-1.7-5.373-1.391.44.808,2.551h-6.067a8.589,8.589,0,0,1-1.5-.133l-.687-.122-.953,4.268-.472-4.8-.35-.182a8.614,8.614,0,0,1-2.337-1.786,1.7,1.7,0,0,0,.673-1.071l.63-3.464h.219a3.167,3.167,0,0,0,2.24,2.783,1.422,1.422,0,0,1,.923.826,2.289,2.289,0,0,0,2.126,1.447,2.289,2.289,0,0,0,2.116-1.422,1.464,1.464,0,0,1,.96-.848,3.414,3.414,0,0,0,2.432-2.786h2.709a2.188,2.188,0,0,1,2.188,2.188Z"
            transform="translate(-1623.889 1377.822)"
            fill="#C4C4C4"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default GroupIcon;
