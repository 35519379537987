import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/pt-br";
import "moment/locale/en-nz";
import "moment/locale/es";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Input } from "antd";
import {
  Title,
  SubTitle,
  Container,
  Header,
  InfoCard,
  CustomModal,
  Footer,
  DateSpan,
} from "./styles";
import ButtonStandard from "../../../utils/button";
import { putPicketInRenovation } from "../../../../services/picketService";

const { TextArea } = Input;

const IconAlert = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.615"
    height="14.615"
    viewBox="0 0 14.615 14.615"
  >
    <g
      id="Group_1016"
      data-name="Group 1016"
      transform="translate(-0.606 -1.003)"
    >
      <g
        id="Group_726"
        data-name="Group 726"
        transform="translate(0.606 1.003)"
      >
        <circle
          id="Ellipse_169"
          data-name="Ellipse 169"
          cx="7.307"
          cy="7.307"
          r="7.307"
          fill="#4a85ae"
        />
      </g>
      <g
        id="Group_1154"
        data-name="Group 1154"
        transform="translate(7.91 3.659)"
      >
        <line
          id="Line_251"
          data-name="Line 251"
          y2="5.975"
          fill="none"
          stroke="#fff"
        />
        <line
          id="Line_252"
          data-name="Line 252"
          y2="1.328"
          transform="translate(0 7.967)"
          fill="none"
          stroke="#fff"
        />
      </g>
    </g>
  </svg>
);

const ChangePicketToRenovationModal = ({ picketId, visible, onClose }) => {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  
  const [amountDays, setAmountDays] = useState(1);
  const [statusLimitDate, setStatusLimitDate] = useState(null);
  const [finalizedAt, setFinalizedAt] = useState(moment().format("DD/MM/YYYY"));
  const [observation, setObservation] = useState(null);

  const handleChangeAmountDays = (e, props) => {
    const {
      target: { value },
    } = e;
    setAmountDays(Number(value));
    props.setValues({
      ...props.values,
      amountDays: Number(value),
    });
    setStatusLimitDate(moment().add(value, "days"));
    setFinalizedAt(moment().add(value, "days").format("DD/MM/YYYY"));
  };

  const handleSubmitForm = async (values, actions) => {
    try {
      actions.resetForm({
        amountDays: 1,
      });
      setAmountDays(1);
      await putPicketInRenovation({
        groupId,
        farmId,
        id: picketId,
        statusLimitDate,
        observation: observation,
      });
      setFinalizedAt(moment().format("DD/MM/YYYY"));
      onClose(true);
    } catch (error) {

    }
  };
  
  const validationSchema = Yup.object().shape({
    amountDays: Yup.number().required().min(1),
  });

  return (
    <Fragment>
      <CustomModal
        width={529}
        visible={visible}
        centered
        maskClosable={false}
        closable={false}
        footer={null}
      >
        <Container>
          {/* Modal header */}
          <Header>
            <Row type="flex">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Title>{translation.picket.drawerRenovation.title}</Title>
              </Col>
            </Row>
            <Row type="flex">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <SubTitle>
                  {translation.picket.drawerRenovation.subtitle}
                </SubTitle>
              </Col>
            </Row>
          </Header>
          {/* Modal body */}
          <Formik
            initialValues={{ amountDays: 1 }}
            validationSchema={validationSchema}
            onSubmit={handleSubmitForm}
            render={(props) => (
              <form onSubmit={props.handleSubmit} autoComplete="off">
                <Row type="flex" justify="start" gutter={16} align="middle">
                  <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                    <Row>
                      <label
                        className={
                          props.errors.amountDays
                            ? "formLabel error"
                            : "formLabel"
                        }
                      >
                        {translation.picket.drawerRenovation.labelAmountDays}*
                      </label>
                    </Row>
                    <Row>
                      <Input
                        name="amountDays"
                        type="number"
                        value={Number(amountDays)}
                        suffix=" Dias"
                        min={1}
                        defaultValue={1}
                        onChange={(e) => handleChangeAmountDays(e, props)}
                      />
                    </Row>
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                    <Row>
                      <label className="formLabel disabled">
                        {translation.picket.drawerRenovation.finishIn}
                      </label>
                    </Row>
                    <Row>
                      <DateSpan>{finalizedAt}</DateSpan>
                    </Row>
                  </Col>
                </Row>
                <Row type="flex" justify="start" gutter={16} align="middle" className="form-row">
                  <Col span={24}>
                    <Row>
                      <label className="formLabel"                      >
                        Observação
                      </label>
                    </Row>
                    <Row>
                      <TextArea
                        rows={5}
                        value={observation}
                        onChange={(e) => setObservation(e.target.value)}
                        />
                    </Row>
                  </Col>
                </Row>

                {/* Buttons */}
                <Row type="flex" justify="end">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} align="right">
                    <ButtonStandard
                      type="button"
                      buttonType="type4"
                      size="s"
                      onClick={(e) => onClose(false)}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>
                    <ButtonStandard type="submit" buttonType="type1" size="s">
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </form>
            )}
          />
          {/* Modal footer */}
        </Container>
        <Footer>
          <InfoCard>
            {IconAlert}
            <span className="title">
              {translation.picket.drawerRenovation.infoMessage}
            </span>
          </InfoCard>
        </Footer>
      </CustomModal>
    </Fragment>
  );
};

export default ChangePicketToRenovationModal;
