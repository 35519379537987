import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Creators as EditProfileActions } from "../../../store/ducks/user";
import { Creators as AppActions } from "../../../store/ducks/app";

import { Formik } from "formik";
import {
  Row,
  Col,
  Input,
  Select,
  Icon,
  Tooltip,
  Spin,
  notification,
  Switch,
} from "antd";
import { Container, Footer, CustomDivider } from "./styles";
// Components
import AvatarUser from "../../utils/icons/avatarUser";
import ButtonStandard from "../../utils/button";
import ZipCodeInput from "../../utils/input/zipCodeInput";
import PhoneNumberInput from "../../utils/input/phoneNumberInput";

// Services
import {
  getCep,
  cpfMask,
  getOnlyNumber,
} from "../../../services/helpersMethodsService";

import {
  indexUserById,
  updateProfileByUserIdWithAvatarFile,
} from "../../../services/userService";
import { getStatesInfo, getCitiesByState } from "../../../services/cityService";
import DrawerChangeEmail from "../changeEmail";

class DrawerEditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formProfile: {
        id: null,
        fullname: null,
        document: "",
        documentType: "CPF",
        zipCode: "",
        address: "",
        state: null,
        city: null,
        country: null,
        phone: "",
        sendSnsEnable: false,
        secondaryPhone: "",
        complement: "",
        number: "",
        avatar: null,
        email: "",
      },
      loadingCep: false,
      zipCodeValid: null,
      statesArray: [],
      citiesArray: [],
      countriesArray: [],
      types: [],
      saveOptions: "only_save",
      isLoading: false,
      isImageLoading: false,
      imageUrl: null,
      avatarFile: null,
      drawerChangeEmailVisible: false,
      changeEmailData: {
        userId: null,
        email: "",
      },
    };
  }

  closeDrawer = () => {
    const { editProfileActions } = this.props;

    this.setState({
      formProfile: {
        fullname: null,
        document: "",
        documentType: "CPF",
        zip_code: "",
        address: "",
        state: null,
        city: null,
        country: null,
        phone: "",
        sendSnsEnable: false,
        secondaryPhone: "",
        complement: "",
        number: "",
        avatar: null,
      },
      loadingCep: false,
      zipCodeValid: null,
      statesArray: [],
      citiesArray: [],
      countriesArray: [],
      types: [],
      imageUrl: null,
      avatarFile: null,
      saveOptions: "only_save",
      drawerChangeEmailVisible: false,
    });

    editProfileActions.hideDrawerEditProfile();
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleDrawerVisible = async (visible) => {
    if (visible) {
      this.setState({ isLoading: true });
      const {
        app: {
          user,
          groupSelected: { id: groupId },
          farmSelected: { id: farmId },
        },
      } = this.props;

      try {
        // Fetch cities and states
        const { data } = await getStatesInfo();
        if (data) {
          this.initialStates = data.results;
          this.initialCountries = [
            {
              id: data.results[0].countryId,
              name: data.results[0].countryName,
            },
          ];

          this.setState({
            statesArray: this.initialStates,
            countriesArray: this.initialCountries,
          });
        }
        // Fetch user data
        const {
          data: { results: dataUser },
        } = await indexUserById(groupId, farmId, user.id);

        const {
          id,
          fullname,
          document,
          address,
          zip_code,
          city,
          phone,
          secondaryPhone,
          complement,
          number,
          avatar,
          email,
          sendSnsEnable,
        } = dataUser;

        if (city != null) {
          const {
            state,
            state: { country },
          } = city;

          const { data: citiesData } = await getCitiesByState(state.id);
          if (citiesData) {
            this.setState({
              citiesArray: citiesData.results,
            });
          }
          this.setState({
            formProfile: {
              id: id,
              fullname: fullname,
              document: cpfMask(document),
              zip_code: zip_code,
              address: address,
              state: state?.id || null,
              city: city || null,
              country: country?.id || null,
              phone: phone,
              secondaryPhone: secondaryPhone,
              complement: complement,
              number: number,
              avatar: avatar,
              email: email,
              sendSnsEnable: sendSnsEnable,
            },
            imageUrl: null,
            avatarFile: null,
            isLoading: false,
            drawerChangeEmailVisible: false,
            changeEmailData: {
              userId: id,
              email: email,
            },
          });
        } else {
          this.setState({
            formProfile: {
              id: id,
              fullname: fullname,
              document: cpfMask(document),
              zip_code: zip_code,
              address: address,
              state: null,
              city: null,
              country: null,
              phone: phone,
              secondaryPhone: secondaryPhone,
              complement: complement,
              number: number,
              avatar: avatar,
              email: email,
              sendSnsEnable: sendSnsEnable,
            },
            imageUrl: null,
            avatarFile: null,
            isLoading: false,
            drawerChangeEmailVisible: false,
            changeEmailData: {
              userId: id,
              email: email,
            },
          });
        }
      } catch (error) {
        console.error(error);
        this.setState({
          isLoading: false,
        });
      }
    } else {
      this.setState({
        formProfile: {
          fullname: null,
          document: "",
          documentType: "CPF",
          zip_code: "",
          address: "",
          state: null,
          city: null,
          country: null,
          phone: "",
          sendSnsEnable: false,
          secondaryPhone: "",
          complement: "",
          number: "",
          avatar: null,
          email: null,
        },
        imageUrl: null,
        avatarFile: null,
        zipCodeValid: null,
        drawerChangeEmailVisible: false,
        changeEmailData: {
          userId: null,
          email: null,
        },
      });
    }
  };

  handleSubmitModalForm = async (
    values,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) => {
    const {
      editProfileActions,
      appActions,
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;
    const { avatarFile } = this.state;

    this.setState({ isLoading: true });

    let { state, country, avatar, ...body } = values;

    body = {
      ...body,
      phone: getOnlyNumber(body?.phone),
      secondaryPhone: getOnlyNumber(body?.secondaryPhone),
      avatarFile,
    };

    try {
      const {
        data: { results: userResponse },
      } = await updateProfileByUserIdWithAvatarFile(
        groupId,
        farmId,
        values.id,
        body
      );
      this.setState({
        saveOptions: "only_save",
        isLoading: false,
      });
      notification.success({ message: "Perfil atualizado com sucesso" });
      appActions.updateUserInfo(userResponse);
      editProfileActions.hideDrawerEditProfile();
    } catch (error) {
      console.error(error);
      notification.error({ message: "Perfil não atualizado" });
      this.setState({ isLoading: false });
    }
  };

  handleCountryChange = (id) => {
    const country = this.initialCountries.filter((c) => c.id === id);
    const filteredStates = this.initialStates.filter(
      (s) => s.countryId === country[0].id
    );
    this.setState({ statesArray: filteredStates });
  };

  handleStateChange = async (id) => {
    const state = this.initialStates.filter((s) => s.id === id);
    const { data } = await getCitiesByState(state[0].id);

    if (data) {
      this.setState({ citiesArray: data.results });
    }
    this.formik.setFieldValue("city", undefined);
  };

  handleZipCodeSearch = async (zipCode, props) => {
    if (zipCode.length === 9) {
      this.setState({
        loadingCep: true,
      });
      try {
        const {
          data: {
            erro,
            logradouro: street,
            uf: state,
            localidade: city,
            complemento: complementAddress,
          },
        } = await getCep(zipCode);
        if (erro) {
          this.setState({
            loadingCep: false,
            zipCodeValid: false,
          });
        } else {
          const countries = this.initialCountries.filter(
            (c) => c.name === "Brasil"
          );
          const states = this.initialStates.filter((s) => s.name === state);

          const { data } = await getCitiesByState(states[0].id);

          if (data) {
            const cities = data.results.filter(
              (c) => c.name.toLowerCase() === city.toLowerCase()
            );
            this.setState({
              citiesArray: data.results,
            });
            props.setFieldValue("city", cities[0]);
          }
          props.setFieldValue("address", street);
          props.setFieldValue("state", states[0].id);
          props.setFieldValue("country", countries[0].id);
          props.setFieldValue("complement", complementAddress);

          this.setState({
            loadingCep: false,
            zipCodeValid: true,
          });
        }
      } catch (error) {
        this.setState({
          loadingCep: false,
          zipCodeValid: false,
        });
      }
    } else {
      this.setState({
        loadingCep: false,
        zipCodeValid: false,
      });
    }
  };

  handleAvatarBeforeUpload = ({ target: { files } }) => {
    const file = files[0];
    if (file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        notification.error({ message: "You can only upload JPG/PNG file!" });
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        notification.error({ message: "Image must smaller than 2MB!" });
        return;
      }
      if (isJpgOrPng && isLt2M) {
        this.getBase64(file, (imageUrl) =>
          this.setState({
            imageUrl,
            avatarFile: file,
          })
        );
      }
    }
  };

  handleDrawerChangeEmail = () => {
    this.setState({
      drawerChangeEmailVisible: true,
    });
  };

  handleCloseDrawerChangeEmail = () => {
    this.setState({
      drawerChangeEmailVisible: false,
    });
  };

  render() {
    const {
      app: { translation },
      user: { drawerVisibleEditUser },
    } = this.props;

    const {
      loadingCep,
      statesArray,
      zipCodeValid,
      citiesArray,
      countriesArray,
      formProfile,
      isLoading,
      imageUrl,
      drawerChangeEmailVisible,
      changeEmailData,
    } = this.state;

    const { Option } = Select;

    const validationSchema = Yup.object().shape({
      fullname: Yup.string().required(),
      document: Yup.string().length(14),
      phone: Yup.string().required(),
      zip_code: Yup.string().required(),
      address: Yup.string().required(),
      state: Yup.string().required(),
      city: Yup.object().required(),
      country: Yup.string().required(),
    });
    return (
      <Container
        title={translation.Profile.drawerTitle}
        width={511}
        onClose={this.closeDrawer}
        maskClosable={false}
        visible={drawerVisibleEditUser}
        afterVisibleChange={this.handleDrawerVisible}
      >
        <Spin spinning={isLoading}>
          <Formik
            ref={(ref) => (this.formik = ref)}
            enableReinitialize={true}
            initialValues={formProfile}
            initialErrors={{}}
            onSubmit={this.handleSubmitModalForm}
            validationSchema={validationSchema}
            render={(props) => (
              <form
                onSubmit={props.handleSubmit}
                autoComplete="off"
                encType="multipart/form-data"
              >
                <div className="drawerForm">
                  {/* Avatar */}
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col span={24}>
                      <div
                        className="personal-image"
                        onChange={this.handleAvatarBeforeUpload}
                      >
                        <label className="label">
                          <input type="file" accept="image/png, image/jpeg" />
                          <figure className="personal-figure">
                            <figcaption className="personal-figcaption-with-image">
                              <img
                                src="https://raw.githubusercontent.com/ThiagoLuizNunes/angular-boilerplate/master/src/assets/imgs/camera-white.png"
                                alt="camera"
                              />
                            </figcaption>
                            {props.values.avatar !== null &&
                            imageUrl === null ? (
                              <img
                                src={props.values.avatar}
                                alt="avatar"
                                className="personal-avatar"
                              />
                            ) : imageUrl ? (
                              <img
                                src={imageUrl}
                                alt="avatar"
                                className="personal-avatar"
                              />
                            ) : (
                              <AvatarUser />
                            )}
                          </figure>
                        </label>
                      </div>
                    </Col>
                  </Row>
                  {/* Input E-mail */}
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.email && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          E-mail*
                        </label>
                      </Row>
                      <Row>
                        <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                          <Row>
                            <Input
                              placeholder={translation.defaultPlaceholder}
                              value={props.values.email}
                              name="email"
                              disabled
                            />
                          </Row>
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                          <Row>
                            <Tooltip
                              placement="top"
                              title="Alterar e-mail"
                              trigger="hover"
                            >
                              <div className="icon">
                                <button
                                  type="button"
                                  onClick={() => {
                                    this.handleDrawerChangeEmail();
                                  }}
                                >
                                  <Icon type="edit" />
                                </button>
                              </div>
                            </Tooltip>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* Input system identification */}
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.fullname && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          Nome Completo*
                        </label>
                      </Row>
                      <Row>
                        <Input
                          placeholder={translation.defaultPlaceholder}
                          value={props.values.fullname}
                          name="fullname"
                          onChange={(e) => {
                            props.setFieldValue("fullname", e.target.value);
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>

                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.document && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          CPF*
                        </label>
                      </Row>
                      <Row>
                        <Input
                          placeholder={translation.defaultPlaceholder}
                          value={cpfMask(props.values.document)}
                          name="document"
                          onChange={(e) => {
                            if (e.target.value.length <= 14)
                              props.setFieldValue("document", e.target.value);
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>

                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.phone && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          Telefone Principal*
                        </label>
                      </Row>
                      <Row>
                        <PhoneNumberInput
                          placeholder={translation.defaultPlaceholder}
                          value={
                            props.values.phone != null ? props.values.phone : ""
                          }
                          name="phone"
                          onChange={(e) => {
                            props.setFieldValue("phone", e.target.value);
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label>Enviar notificações via SMS?*</label>
                      </Row>
                      <Row>
                        <Switch
                          checked={props.values.sendSnsEnable === true}
                          onChange={(checked, event) => {
                            props.setValues({
                              ...props.values,
                              sendSnsEnable: checked,
                            });
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>

                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.secondaryPhone && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          Telefone Secundário
                        </label>
                      </Row>
                      <Row>
                        <PhoneNumberInput
                          placeholder={translation.defaultPlaceholder}
                          value={
                            props.values.secondaryPhone != null
                              ? props.values.secondaryPhone
                              : ""
                          }
                          name="secondaryPhone"
                          onChange={(e) => {
                            props.setFieldValue(
                              "secondaryPhone",
                              e.target.value
                            );
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>

                  <CustomDivider dashed>ENDEREÇOS DE COBRANÇAS</CustomDivider>

                  {/* Input zipcode */}
                  <Row type="flex" style={{ marginTop: 13 }} align="middle">
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Row>
                        <label
                          className={
                            props.errors.zip_code && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          CEP*
                        </label>
                      </Row>
                      <Row>
                        <ZipCodeInput
                          placeholder={translation.farm.form.zipCodePlaceHolder}
                          type="text"
                          value={props.values.zip_code}
                          name="zip_code"
                          autoComplete="chrome-off"
                          onChange={(e) => {
                            props.setFieldValue("zip_code", e.target.value);
                          }}
                          onBlur={(e) =>
                            this.handleZipCodeSearch(e.target.value, props)
                          }
                        />
                      </Row>
                    </Col>
                    <Col xs={14} sm={14} md={14} lg={14} xl={14} push={1}>
                      {loadingCep === true ? (
                        <Row style={{ marginTop: 18 }}>
                          <span
                            style={{
                              color: "#4b4b4b",
                              fontWeight: "bold",
                            }}
                          >
                            <Icon type="loading" style={{ marginRight: 10 }} />
                            Processando
                          </span>
                        </Row>
                      ) : zipCodeValid != null && zipCodeValid === true ? (
                        <Row style={{ marginTop: 18 }}>
                          <span
                            style={{
                              color: "#33C162",
                              fontWeight: "bold",
                            }}
                          >
                            <Icon
                              type="check-circle"
                              style={{
                                marginRight: 10,
                                color: "#33C162",
                                fontSize: 12,
                              }}
                            />
                            {translation.zipCodeValid}
                          </span>
                        </Row>
                      ) : zipCodeValid != null && zipCodeValid === false ? (
                        <Row style={{ marginTop: 18 }}>
                          <span
                            style={{ color: "#D44C4C", fontWeight: "bold" }}
                          >
                            <Icon
                              type="close-circle"
                              style={{
                                marginRight: 10,
                                color: "#D44C4C",
                                fontSize: 12,
                              }}
                            />
                            {translation.zipCodeInvalid}
                          </span>
                        </Row>
                      ) : null}
                    </Col>
                  </Row>

                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.address && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          Endereço*
                        </label>
                      </Row>
                      <Row>
                        <Input
                          placeholder={translation.defaultPlaceholder}
                          value={props.values.address}
                          autoComplete="chrome-off"
                          name="address"
                          onChange={(e) => {
                            props.setFieldValue("address", e.target.value);
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  {/* Input complement */}
                  <Row type="flex" style={{ marginTop: 13 }} align="middle">
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Row className="rowInput">
                        <Input
                          placeholder={
                            translation.farm.form.complementPlaceHolder
                          }
                          value={props.values.number}
                          name="number"
                          disabled={loadingCep}
                          maxLength={200}
                          addonBefore="Nº"
                          onChange={(e) => {
                            props.setFieldValue("number", e.target.value);
                          }}
                        />
                      </Row>
                    </Col>

                    <Col xs={15} sm={15} md={15} lg={15} xl={15} offset={1}>
                      <Row className="rowInput">
                        <Input
                          placeholder={
                            translation.farm.form.complementAddressPlaceHolder
                          }
                          value={props.values.complement}
                          name="complement"
                          addonBefore="Comp."
                          maxLength={200}
                          onChange={(e) => {
                            props.setFieldValue("complement", e.target.value);
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" style={{ marginTop: 13 }} align="middle">
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Row>
                        <label>{translation.supplier.form.country}</label>
                      </Row>
                      <Row>
                        <Select
                          placeholder={
                            translation.supplier.form.countryPlaceHolder
                          }
                          value={
                            props.values.country != null &&
                            countriesArray?.length > 0
                              ? props.values.country
                              : undefined
                          }
                          name="country"
                          disabled={loadingCep || zipCodeValid}
                          onChange={(value) => {
                            props.setFieldValue("country", value);
                            this.handleCountryChange(value);
                          }}
                        >
                          {countriesArray?.length > 0 &&
                            countriesArray?.map((c) => (
                              <Option key={c.id} value={c.id}>
                                {c.name}
                              </Option>
                            ))}
                        </Select>
                      </Row>
                    </Col>
                    <Col xs={5} sm={5} md={5} lg={5} xl={5} offset={1}>
                      <Row>
                        <label>{translation.farm.form.state}</label>
                      </Row>
                      <Row>
                        <Select
                          placeholder={translation.farm.form.statePlaceHolder}
                          value={
                            props.values.state != null &&
                            statesArray?.length > 0
                              ? props.values.state
                              : undefined
                          }
                          name="state"
                          disabled={loadingCep || zipCodeValid}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => {
                            props.setFieldValue("state", value);
                            this.handleStateChange(value);
                          }}
                        >
                          {statesArray?.length > 0 &&
                            statesArray?.map((state) => (
                              <Option key={state.id} value={state.id}>
                                {state.name}
                              </Option>
                            ))}
                        </Select>
                      </Row>
                    </Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
                      <Row>
                        <label>{translation.supplier.form.city}</label>
                      </Row>
                      <Row>
                        <Select
                          placeholder={
                            translation.supplier.form.cityPlaceHolder
                          }
                          value={
                            props.values.city != null && citiesArray?.length > 0
                              ? props.values.city.id
                              : undefined
                          }
                          name="city"
                          disabled={loadingCep || zipCodeValid}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => {
                            props.setFieldValue("city", { id: value });
                          }}
                        >
                          {citiesArray?.length > 0 &&
                            citiesArray?.map((c) => (
                              <Option key={c.id} value={c.id}>
                                {c.name}
                              </Option>
                            ))}
                        </Select>
                      </Row>
                    </Col>
                  </Row>
                </div>
                {/* Footer */}
                <Footer>
                  <Row type="flex" justify="space-between">
                    <Col span={12} />
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="buttonsDiv"
                    >
                      <ButtonStandard
                        type="button"
                        buttonType="type7"
                        onClick={this.closeDrawer}
                      >
                        {translation.buttons.cancel}
                      </ButtonStandard>

                      <ButtonStandard buttonType="type6" type="submit">
                        {translation.buttons.save}
                      </ButtonStandard>
                    </Col>
                  </Row>
                </Footer>
              </form>
            )}
          />
        </Spin>
        <DrawerChangeEmail
          drawerVisible={drawerChangeEmailVisible}
          onCancel={this.handleCloseDrawerChangeEmail}
          data={changeEmailData}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  editProfileActions: bindActionCreators(EditProfileActions, dispatch),
  appActions: bindActionCreators(AppActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerEditProfile);
