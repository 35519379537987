import styled from "styled-components";
import { Divider, Drawer } from "antd";

export const Container = styled(Drawer)`
  .ant-drawer-body {
    padding: 10px 0px 10px 0px;
  }

  .drawerForm {
    width: 100%;
    padding: 0px 24px;
  }

  .ant-row {
    margin-top: 7px;
  }
  .ant-row-flex {
    margin-top: 15px;
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 7px;
    &.error {
      color: #d44c4c;
      &:first-of-type {
        margin-top: 10px;
        margin-left: 0px;
      }
    }
  }
`;

export const CustomDivider = styled(Divider)`
  margin: 10px 0 !important;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 10px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;
