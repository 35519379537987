export const Types = {
  RESET_DATA: "breed/RESET_DATA",
  GET_ALL_BREED: "breed/GET_ALL_BREED",
  GET_ALL_BREED_SUCCESS: "breed/GET_ALL_BREED_SUCCESS",
  GET_ALL_BREED_ERROR: "breed/GET_ALL_BREED_ERROR",
  GET_ALL_BREED_ACTIVE: "breed/GET_ALL_ACTIVE_BREED",
  GET_ALL_BREED_ACTIVE_SUCCESS: "breed/GET_ALL_BREED_ACTIVE_SUCCESS",
  GET_ALL_BREED_ACTIVE_ERROR: "breed/GET_ALL_BREED_ACTIVE_ERROR",
  GET_BREED: "breed/GET_BREED",
  GET_BREED_SUCCESS: "breed/GET_BREED_SUCCESS",
  GET_BREED_ERROR: "breed/GET_BREED_ERROR",
  GET_BREEDPARAMETERS: "breed/GET_BREEDPARAMETERS",
  GET_BREEDPARAMETERS_SUCCESS: "breed/GET_BREEDPARAMETERS_SUCCESS",
  GET_BREEDPARAMETERS_ERROR: "breed/GET_BREEDPARAMETERS_ERROR",
  SHOW_DRAWER_BREED: "breed/SHOW_DRAWER_BREED",
  HIDE_DRAWER_BREED: "breed/HIDE_DRAWER_BREED",
  SHOW_DRAWER_BREEDPARAMETERS: "breed/SHOW_DRAWER_BREEDPARAMETERS",
  HIDE_DRAWER_BREEDPARAMETERS: "breed/HIDE_DRAWER_BREEDPARAMETERS",
  SAVE_BREED: "breed/SAVE_BREED",
  SAVE_BREED_SUCCESS: "breed/SAVE_BREED_SUCCESS",
  SAVE_BREED_ERROR: "breed/SAVE_BREED_ERROR",
  SAVE_BREEDPARAMETERS: "breed/SAVE_BREEDPARAMETERS",
  SAVE_BREEDPARAMETERS_SUCCESS: "breed/SAVE_BREEDPARAMETERS_SUCCESS",
  SAVE_BREEDPARAMETERS_ERROR: "breed/SAVE_BREEDPARAMETERS_ERROR",
  DELETE_BREED: "breed/DELETE_BREED",
  DELETE_BREED_SUCCESS: "breed/DELETE_BREED_SUCCESS",
  DELETE_BREED_ERROR: "breed/DELETE_BREED_ERROR",
  INACTIVATE_ACTIVATE_BREEDPARAMETERS:
    "breed/INACTIVATE_ACTIVATE_BREEDPARAMETERS",
  INACTIVATE_ACTIVATE_BREEDPARAMETERS_SUCCESS:
    "breed/INACTIVATE_ACTIVATE_BREEDPARAMETERS_SUCCESS",
  INACTIVATE_ACTIVATE_BREEDPARAMETERS_ERROR:
    "breed/INACTIVATE_ACTIVATE_BREEDPARAMETERS_ERROR",
};

export const Creators = {
  indexBreed: (
    groupId,
    farmId,
    page = 0,
    sorter = {},
    filters = "",
    size = 10,
    withoutPagination = false
  ) => ({
    type: Types.GET_ALL_BREED,
    payload: {
      groupId,
      farmId,
      page,
      sorter,
      filters,
      size,
      withoutPagination,
    },
  }),
  indexBreedSuccess: (data) => ({
    type: Types.GET_ALL_BREED_SUCCESS,
    payload: {
      data,
    },
  }),
  indexBreedError: (error) => ({
    type: Types.GET_ALL_BREED_ERROR,
    payload: {
      error,
    },
  }),
  indexBreedActive: (groupId, farmId) => ({
    type: Types.GET_ALL_BREED_ACTIVE,
    payload: {
      groupId,
      farmId,
    },
  }),
  indexBreedActiveSuccess: (data) => ({
    type: Types.GET_ALL_BREED_ACTIVE_SUCCESS,
    payload: {
      data,
    },
  }),
  indexBreedActiveError: (error) => ({
    type: Types.GET_ALL_BREED_ACTIVE_ERROR,
    payload: {
      error,
    },
  }),
  showBreed: (groupId, farmId, id) => ({
    type: Types.GET_BREED,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),
  showBreedSuccess: (data) => ({
    type: Types.GET_BREED_SUCCESS,
    payload: {
      data,
    },
  }),
  showBreedError: (error) => ({
    type: Types.GET_BREED_ERROR,
    payload: {
      error,
    },
  }),
  showBreedParameters: (groupId, farmId, id) => ({
    type: Types.GET_BREEDPARAMETERS,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),
  showBreedParametersSuccess: (data) => ({
    type: Types.GET_BREEDPARAMETERS_SUCCESS,
    payload: {
      data,
    },
  }),
  showBreedParametersError: (error) => ({
    type: Types.GET_BREEDPARAMETERS_ERROR,
    payload: {
      error,
    },
  }),
  showDrawer: (actionType = "edit") => ({
    type: Types.SHOW_DRAWER_BREED,
    payload: {
      actionType,
    },
  }),
  hideDrawer: () => ({
    type: Types.HIDE_DRAWER_BREED,
    payload: {},
  }),
  showDrawerParameters: (actionType = "edit", breedId) => ({
    type: Types.SHOW_DRAWER_BREEDPARAMETERS,
    payload: {
      actionType,
      breedId,
    },
  }),
  hideDrawerParameters: () => ({
    type: Types.HIDE_DRAWER_BREEDPARAMETERS,
    payload: {},
  }),
  saveBreed: (groupId, farmId, id = null, breed, saveOptions) => ({
    type: Types.SAVE_BREED,
    payload: {
      groupId,
      farmId,
      id,
      breed,
      saveOptions,
    },
  }),
  saveBreedSuccess: (data, saveOptions) => ({
    type: Types.SAVE_BREED_SUCCESS,
    payload: { data, saveOptions },
  }),
  saveBreedError: (error) => ({
    type: Types.SAVE_BREED_ERROR,
    payload: { error },
  }),
  saveBreedParameters: (
    groupId,
    farmId,
    breedId,
    id = null,
    breedParameters,
    saveOptions
  ) => ({
    type: Types.SAVE_BREEDPARAMETERS,
    payload: {
      groupId,
      farmId,
      breedId,
      id,
      breedParameters,
      saveOptions,
    },
  }),
  saveBreedParametersSuccess: (data, saveOptions) => ({
    type: Types.SAVE_BREEDPARAMETERS_SUCCESS,
    payload: { data, saveOptions },
  }),
  saveBreedParametersError: (error) => ({
    type: Types.SAVE_BREEDPARAMETERS_ERROR,
    payload: { error },
  }),
  inactivateOrActivateBreedParameters: (
    groupId,
    farmId,
    breedId,
    id = null,
    breed,
    operationAction = "activate" | "inactivate"
  ) => ({
    type: Types.INACTIVATE_ACTIVATE_BREEDPARAMETERS,
    payload: {
      groupId,
      farmId,
      breedId,
      id,
      breed,
      operationAction,
    },
  }),
  inactivateOrActivateBreedParametersSuccess: () => ({
    type: Types.INACTIVATE_ACTIVATE_BREEDPARAMETERS_SUCCESS,
    payload: {},
  }),
  inactivateOrActivateBreedParametersError: (error) => ({
    type: Types.INACTIVATE_ACTIVATE_BREEDPARAMETERS_ERROR,
    payload: { error },
  }),
};

const INITIAL_STATE = {
  isLoading: false,
  isLoadingRequest: false,
  isLoadingBreedParameters: false,
  data: [],
  pagination: {
    page: 0,
    sorter: {},
    filters: "",
    size: 10,
  },
  breedId: null,
  breedData: null,
  breedParametersData: null,
  drawerVisible: false,
  drawerParametersVisible: false,
  error: {},
};

export default function breed(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_ALL_BREED:
      const { page, sorter, filters, size } = action.payload;
      return {
        ...state,
        pagination: {
          page,
          sorter,
          filters,
          size,
        },
        isLoading: true,
      };
    case Types.GET_ALL_BREED_SUCCESS:
      const { data: breeds } = action.payload;
      return { ...state, isLoading: false, data: breeds };
    case Types.GET_ALL_BREED_ERROR:
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: null,
        error,
      };
    case Types.GET_ALL_BREED_ACTIVE:
      return { ...state, isLoading: true };
    case Types.GET_ALL_BREED_ACTIVE_SUCCESS:
      const { data: breedsActive } = action.payload;
      return { ...state, isLoading: false, data: breedsActive };
    case Types.GET_ALL_BREED_ACTIVE_ERROR:
      return {
        ...state,
        isLoading: false,
        data: null,
        error: action.payload.error,
      };
    case Types.GET_BREED:
      return { ...state, isLoading: true };
    case Types.GET_BREED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        breedData: action.payload.data,
      };
    case Types.GET_BREED_ERROR:
      return {
        ...state,
        isLoading: false,
        breedData: null,
        error,
      };
    case Types.GET_BREEDPARAMETERS:
      return { ...state, isLoadingBreedParameters: true };
    case Types.GET_BREEDPARAMETERS_SUCCESS:
      return {
        ...state,
        isLoadingBreedParameters: false,
        breedParametersData: action.payload.data,
      };
    case Types.GET_BREEDPARAMETERS_ERROR:
      return {
        ...state,
        isLoadingBreedParameters: false,
        breedParametersData: null,
        error,
      };
    case Types.SHOW_DRAWER_BREED:
      return {
        ...state,
        drawerVisible: true,
        breedData: action.payload.actionType === "new" ? null : state.breedData,
      };
    case Types.HIDE_DRAWER_BREED:
      return { ...state, drawerVisible: false, breedData: null };
    case Types.SHOW_DRAWER_BREEDPARAMETERS:
      return {
        ...state,
        drawerParametersVisible: true,
        breedId: action.payload.breedId,
        breedParametersData:
          action.payload.actionType === "new"
            ? null
            : state.breedParametersData,
      };
    case Types.HIDE_DRAWER_BREEDPARAMETERS:
      return {
        ...state,
        drawerParametersVisible: false,
        breedParametersData: null,
      };
    case Types.SAVE_BREED:
      return { ...state, isLoadingRequest: true };
    case Types.SAVE_BREED_SUCCESS:
      return {
        ...state,
        isLoadingRequest: false,
        breedData: null,
        drawerVisible: action.payload.saveOptions,
      };
    case Types.SAVE_BREED_ERROR:
      return {
        ...state,
        isLoadingRequest: false,
        error: action.payload.error,
      };
    case Types.SAVE_BREEDPARAMETERS:
      return { ...state, isLoadingRequest: true };
    case Types.SAVE_BREEDPARAMETERS_SUCCESS:
      return {
        ...state,
        isLoadingRequest: false,
        breedParametersData: null,
        drawerParametersVisible: action.payload.saveOptions,
      };
    case Types.SAVE_BREEDPARAMETERS_ERROR:
      return {
        ...state,
        isLoadingRequest: false,
        error: action.payload.error,
      };
    case Types.DELETE_BREED:
      return { ...state, isLoading: true };
    case Types.DELETE_BREED_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case Types.DELETE_BREED_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case Types.INACTIVATE_ACTIVATE_BREEDPARAMETERS:
      return { ...state, isLoading: true };
    case Types.INACTIVATE_ACTIVATE_BREEDPARAMETERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case Types.INACTIVATE_ACTIVATE_BREEDPARAMETERS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
