export const Types = {
  RESET_DATA: "pasture/RESET_DATA",
  GET_ALL_PASTURE: "pasture/GET_ALL_PASTURE",
  GET_ALL_PASTURE_SUCCESS: "pasture/GET_ALL_PASTURE_SUCCESS",
  GET_ALL_PASTURE_ERROR: "pasture/GET_ALL_PASTURE_ERROR",
  GET_PASTURE: "pasture/GET_PASTURE",
  GET_PASTURE_SUCCESS: "pasture/GET_PASTURE_SUCCESS",
  GET_PASTURE_ERROR: "pasture/GET_PASTURE_ERROR",
  SHOW_DRAWER_PASTURE: "pasture/SHOW_DRAWER_PASTURE",
  HIDE_DRAWER_PASTURE: "pasture/HIDE_DRAWER_PASTURE",
  SAVE_PASTURE: "pasture/SAVE_PASTURE",
  SAVE_PASTURE_SUCCESS: "pasture/SAVE_PASTURE_SUCCESS",
  SAVE_PASTURE_ERROR: "pasture/SAVE_PASTURE_ERROR",
  DELETE_PASTURE: "pasture/DELETE_PASTURE",
  DELETE_PASTURE_SUCCESS: "pasture/DELETE_PASTURE_SUCCESS",
  DELETE_PASTURE_ERROR: "pasture/DELETE_PASTURE_ERROR",
  CHANGE_PASTURE_TYPE: "pasture/SAVE_PASTURE",
  SHOW_OR_HIDE_DRAWER_PASTURE_DETAILS:
    "pasture/SHOW_OR_HIDE_DRAWER_PASTURE_DETAILS",
};

export const Creators = {
  indexPasture: (groupId, farmId, page = 0, sorter = {}, filters = "") => ({
    type: Types.GET_ALL_PASTURE,
    payload: {
      groupId,
      farmId,
      page,
      sorter,
      filters,
    },
  }),
  indexPastureSuccess: (data) => ({
    type: Types.GET_ALL_PASTURE_SUCCESS,
    payload: {
      data,
    },
  }),
  indexPastureError: (error) => ({
    type: Types.GET_ALL_PASTURE_ERROR,
    payload: {
      error,
    },
  }),
  showPasture: (groupId, farmId, id) => ({
    type: Types.GET_PASTURE,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),
  showPastureSuccess: (data) => ({
    type: Types.GET_PASTURE_SUCCESS,
    payload: {
      data,
    },
  }),
  showPastureError: (error) => ({
    type: Types.GET_PASTURE_ERROR,
    payload: {
      error,
    },
  }),
  showDrawer: (actionType = "edit", id = null) => ({
    type: Types.SHOW_DRAWER_PASTURE,
    payload: {
      actionType,
      id,
    },
  }),
  hideDrawer: () => ({
    type: Types.HIDE_DRAWER_PASTURE,
    payload: {},
  }),
  savePasture: (groupId, farmId, id = null, pasture, saveOptions) => ({
    type: Types.SAVE_PASTURE,
    payload: {
      groupId,
      farmId,
      id,
      pasture,
      saveOptions,
    },
  }),
  savePastureSuccess: (data, saveOptions) => ({
    type: Types.SAVE_PASTURE_SUCCESS,
    payload: { data, saveOptions },
  }),
  savePastureError: (error) => ({
    type: Types.SAVE_PASTURE_ERROR,
    payload: { error },
  }),
  showOrHideDrawerPastureDetails: (id) => ({
    type: Types.SHOW_OR_HIDE_DRAWER_PASTURE_DETAILS,
    payload: {
      id,
    },
  }),
};

const INITIAL_STATE = {
  isLoading: false,
  isLoadingRequest: false,
  data: [],
  pastureData: null,
  drawerVisible: false,
  drawerPastureDetailsVisible: false,
  idPastureDetails: null,
  idPastureEdit: null,
  error: null,
};

export default function pasture(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_ALL_PASTURE:
      return { ...state, isLoading: true };
    case Types.GET_ALL_PASTURE_SUCCESS:
      const { data: pastures } = action.payload;
      return { ...state, isLoading: false, error: null, data: pastures };
    case Types.GET_ALL_PASTURE_ERROR:
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: null,
        error,
      };
    case Types.GET_PASTURE:
      return { ...state, isLoadingRequest: true };
    case Types.GET_PASTURE_SUCCESS:
      return {
        ...state,
        isLoadingRequest: false,
        error: null,
        pastureData: action.payload.data,
      };
    case Types.GET_PASTURE_ERROR:
      return {
        ...state,
        isLoadingRequest: false,
        pastureData: null,
        error,
      };
    case Types.SHOW_DRAWER_PASTURE:
      return {
        ...state,
        drawerVisible: true,
        error: null,
        idPastureEdit:
          action.payload.actionType === "new" ? null : action.payload.id,
        isLoadingRequest: action.payload.actionType === "new" ? false : true,
        pastureData: null,
      };
    case Types.HIDE_DRAWER_PASTURE:
      return {
        ...state,
        drawerVisible: false,
        error: null,
        pastureData: null,
        isLoading: false,
      };
    case Types.SAVE_PASTURE:
      return { ...state, isLoadingRequest: true };
    case Types.SAVE_PASTURE_SUCCESS:
      return {
        ...state,
        isLoadingRequest: false,
        pastureData: null,
        error: null,
        drawerVisible: false,
      };
    case Types.SAVE_PASTURE_ERROR:
      return {
        ...state,
        isLoadingRequest: false,
        error: action.payload.error,
      };
    case Types.DELETE_PASTURE:
      return { ...state, isLoading: true };
    case Types.DELETE_PASTURE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case Types.DELETE_PASTURE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case Types.SHOW_OR_HIDE_DRAWER_PASTURE_DETAILS:
      return {
        ...state,
        drawerPastureDetailsVisible: !state.drawerPastureDetailsVisible,
        idPastureDetails: action.payload.id,
      };
    default:
      return state;
  }
}
