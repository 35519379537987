import React from "react";
import moment from "moment";

import { Button, Col, Row } from "antd";
import TrashIcon from "../../../../components/utils/icons/trash";

function AnimalReproductiveProtocolGroup({ group, removeGroup }) {
  return (
    <Row type="flex">
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        {moment(group.dateApplication).format("DD/MM/YYYY")}
      </Col>
      <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ textAlign: "right" }}>
        {group.animalsIds.length}{" "}
        {group.animalsIds.length > 1 ? `animais` : `animal`}
      </Col>
      <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right" }}>
        <Button
          onClick={() => removeGroup(group.id)}
          style={{ border: 0, marginTop: -5 }}
        >
          <TrashIcon />
        </Button>
      </Col>
    </Row>
  );
}

export default React.memo(AnimalReproductiveProtocolGroup);
