import { useContext } from "react";
import { AnimalPregnancyDiagnosisContext } from "../../contexts/animalPregnancyDiagnosisContext";


const useAnimalPregnancyDiagnosisContext = () => {
  const value = useContext(AnimalPregnancyDiagnosisContext);

  return value;
};

export default useAnimalPregnancyDiagnosisContext;
