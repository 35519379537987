import styled from "styled-components";
import { Card } from "antd";

export const CardCustom = styled(Card)`
  margin-top: 13px !important;
  overflow-x: hidden;
  border-radius: 7px !important;
  background: #fff !important;
  box-shadow: 0px 0px 10px #d8d8d8 !important;
  overflow-y: hidden;
  padding: 0px !important;

  .mark {
    font-weight: bold;
  }

  .ant-card-body {
    padding-top: 14px;
  }

  .rowHeader {
    margin-bottom: 17px;
  }

  .rowForm {
    margin-bottom: 16px;
  }

  .colButtons {
    margin-right: 5px;
    margin-left: 5px;
  }
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;

export const PageTitle = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 25px;
  text-align: left;
  color: #9074bf;
`;

export const TagStatus = styled.span`
  padding: 2px 5px !important;
  border-radius: 5px !important;
  color: ${(props) => props.color}!important;
  font-size: 12px !important;
  font-weight: bold !important;
  border: ${(props) => `1px solid ${props.borderColor}`};
  background: ${(props) => props.background} !important;
`;
