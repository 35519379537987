import { Formik } from "formik";
import React, { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { useFarmConvertionsContext } from "../context";

import { Col, Radio, Row, notification } from "antd";
import ButtonStandard from "../../../../../../components/utils/button";
import SelectCurrency from "../../../../../../components/utils/selectCurrency";
import { FarmConvertionsFormContainer } from "./styles";

// import { Container } from './styles';

const FORM_INITIAL_VALUES = {
  currency: null,
  standard: false,
};

const validationSchema = yup.object().shape({
  currency: yup.string().required(),
  standard: yup.bool().required(),
});

function FarmConvertionsForm() {
  const formikRef = useRef(null);
  const { translation } = useSelector((state) => state.app);

  const { farmCurrencies, hideOrShowForm, createFarmCurrency } =
    useFarmConvertionsContext();

  const handleSubmit = useCallback(
    async (values, actions) => {
      if (
        farmCurrencies &&
        farmCurrencies.length > 0 &&
        farmCurrencies.filter(
          (farmCurrency) => farmCurrency.currency === values.currency
        ).length > 0
      ) {
        notification.error({
          message: "Moeda já cadastrada !",
        });
        return;
      }
      createFarmCurrency(values);
      actions.resetForm(FORM_INITIAL_VALUES);
    },
    [farmCurrencies, createFarmCurrency]
  );

  const handleCancel = () => {
    formikRef.current.resetForm(FORM_INITIAL_VALUES);
    hideOrShowForm();
  };

  return (
    <FarmConvertionsFormContainer
      title={
        <Row type="flex" justify="center">
          <strong>
            {
              translation.financial.parameters.generalFinancialParameters
                .farmConvertions.form.titleNew
            }
          </strong>
        </Row>
      }
    >
      <Formik
        ref={formikRef}
        initialValues={FORM_INITIAL_VALUES}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        render={({
          values,
          setFieldValue,
          errors,
          submitCount,
          handleSubmit,
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            {Object.entries(errors).length > 0 && submitCount > 0 && (
              <Row type="flex" justify="center" align="middle">
                <label className="error">{translation.error.formError}</label>
              </Row>
            )}
            {/* Body */}
            <Row type="flex" justify="center" gutter={16}>
              <Col>
                <Row>
                  <label
                    htmlFor="currency"
                    className={
                      errors.currency && submitCount > 0 ? "error" : ""
                    }
                  >
                    {
                      translation.financial.parameters
                        .generalFinancialParameters.farmConvertions.form
                        .currency
                    }
                    *
                  </label>
                </Row>
                <Row>
                  <SelectCurrency
                    id={"currency"}
                    value={values.currency}
                    setValue={(value) => setFieldValue("currency", value)}
                  />
                </Row>
              </Col>
              <Col>
                <Row>
                  <label htmlFor="standard">
                    {
                      translation.financial.parameters
                        .generalFinancialParameters.farmConvertions.form
                        .standard
                    }
                  </label>
                </Row>
                <Row>
                  <Radio.Group
                    id="standard"
                    value={values.standard}
                    onChange={(e) => setFieldValue("standard", e.target.value)}
                  >
                    <Radio value={true}>{translation.buttons.yes}</Radio>
                    <Radio value={false}>{translation.buttons.no}</Radio>
                  </Radio.Group>
                </Row>
              </Col>
            </Row>
            {/* Footer */}
            <Row
              className="rowFooter"
              type="flex"
              justify="center"
              align="middle"
              gutter={8}
              style={{
                padding: "0.5rem 0",
              }}
            >
              <Col>
                <ButtonStandard
                  type="submit"
                  name="buttonSaveFinancialTransactionsForm"
                  buttonType="principal"
                >
                  {translation.buttons.save}
                </ButtonStandard>
              </Col>
              <Col>
                <ButtonStandard
                  name="buttonSaveFinancialTransactionsForm"
                  buttonType="secondary"
                  type="button"
                  onClick={handleCancel}
                >
                  {translation.buttons.cancel}
                </ButtonStandard>
              </Col>
            </Row>
          </form>
        )}
      />
    </FarmConvertionsFormContainer>
  );
}

export default FarmConvertionsForm;
