import {
  Col,
  DatePicker,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Spin,
  Switch,
} from "antd";
import axios from "axios";
import { Formik } from "formik";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Creators as SupplierActions } from "../../../store/ducks/supplier";

import { getListOfClientsDropDown } from "../../../services/managementService";
import { processReportConfinementResult } from "../../../services/reportService";
import { indexDropDownSaleScenario } from "../../../services/saleScenarioService";
import ButtonStandard from "../../utils/button";
import { Container } from "./styles";
import { getLotIndexDropDown } from "../../../services/lotService";
import fileReader from "../../../utils/fileReader";

// Services

const validateSchema = Yup.object().shape({
  startDate: Yup.date().required(),
  endDate: Yup.date().required(),
  lotIds: Yup.array(Yup.string())
    .nullable()
    .when(["showDeathData"], {
      is: (showDeathData) => showDeathData === true,
      then: Yup.array(Yup.string()).test({
        test: (arr) => arr.length > 0,
      }),
      otherwise: Yup.array(Yup.string()).nullable(),
    }),
});

const ConfinementResultReportModal = ({ modalVisible, closeModal }) => {
  const formik = useRef();
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const {
    listDrowpDown: suppliers,
    isLoadingDropDown: isLoadingDropDownSuppliers,
  } = useSelector((state) => state.supplier);

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [lots, setLots] = useState([]);
  const [form, setForm] = useState({
    startDate: null,
    endDate: null,
    clientId: null,
    supplierId: null,
    saleScenarioIds: [],
    lotIds: [],
    documentNumber: null,
    showDeathData: false,
    enableLotResult: false,
  });
  const [clients, setClients] = useState([]);
  const [saleScenarios, setSaleScenarios] = useState([]);

  const cleanFields = () => {
    formik.current.resetForm({
      startDate: null,
      endDate: null,
      clientId: null,
      supplierId: null,
      saleScenarioIds: [],
      lotIds: [],
      documentNumber: null,
      showDeathData: false,
      enableLotResult: false,
    });
    setForm({
      startDate: null,
      endDate: null,
      clientId: null,
      supplierId: null,
      saleScenarioIds: [],
      lotIds: [],
      documentNumber: null,
      showDeathData: false,
      enableLotResult: false,
    });
  };

  const handleCancel = useCallback(() => {
    cleanFields();
    closeModal();
  }, [closeModal]);

  const handleSubmit = async (values, actions) => {
    setIsLoading(true);
    notification.info({
      message:
        "O seu relatório está sendo preparado e logo será feito o download! Por favor, não feche esta aba do navegador.",
    });
    setTimeout(() => {
      setIsLoading(false);
      cleanFields();
      closeModal();
    }, 100);
    try {
      const { data } = await processReportConfinementResult({
        groupId,
        farmId,
        body: values,
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      let link = document.createElement("a");
      link.href = downloadUrl;
      const dayFormat = moment().format("YYYYMMDD");
      const hourFormat = moment().format("HHmm");
      link.setAttribute(
        "download",
        `Resultado_do_Confinamento_${dayFormat}_${hourFormat}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();

      notification.success({
        message: "Relatório gerado com sucesso.",
      });
    } catch (error) {
      if (error?.response) {
        try {
          const { data } = error.response;
          // Read file
          const file = await fileReader(data);

          if (typeof file === "string") {
            // Parse content and retrieve 'message'
            const result = JSON.parse(file);
            if (result.code === 5035) {
              notification.error({
                message:
                  "Não foram encontradas vendas com os filtros informados.",
              });
            } else {
              notification.error({
                message:
                  "Houve um erro ao gerar relatório. Entre em contato com o suporte",
              });
            }
          }
        } catch (readError) {
          notification.error({
            message:
              "Houve um erro ao gerar relatório. Entre em contato com o suporte",
          });
        }
      } else {
        notification.error({
          message:
            "Houve um erro ao gerar relatório. Entre em contato com o suporte",
        });
      }
    }
  };

  // Effects
  useEffect(() => {
    let signal = axios.CancelToken.source();
    async function fetchLots() {
      const {
        data: { results },
      } = await getLotIndexDropDown({
        groupId,
        farmId,
      });
      setLots(
        results.filter((lot) => lot.status !== "Inactive" || lot.status === "I")
      );
    }
    async function fetch() {
      setIsLoading(true);
      try {
        dispatch(
          SupplierActions.getDropdownSuppliers(
            groupId,
            farmId,
            "CattleFarmer",
            true
          )
        );
        const [
          {
            data: { results: resultsClient },
          },
          {
            data: { results: resultsSaleScenarios },
          },
        ] = await Promise.all([
          getListOfClientsDropDown({
            groupId,
            farmId,
            onlyActives: true,
          }),
          indexDropDownSaleScenario({
            groupId,
            farmId,
          }),
        ]);
        setClients(resultsClient);
        const filteredOnlyPartialSaledOrSaled = resultsSaleScenarios.filter(
          (ss) =>
            ss.status === "PartialSold" ||
            ss.status === "Sold" ||
            ss.status === "PS" ||
            ss.status === "S" ||
            ss.status === "PartialCanceled" ||
            ss.status === "PC"
        );
        setSaleScenarios(filteredOnlyPartialSaledOrSaled);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }

    if (modalVisible === true) {
      fetch();
      fetchLots();
    }

    return () => {
      signal.cancel("Cancelou");
    };
  }, [dispatch, farmId, groupId, modalVisible]);

  return (
    <Modal
      visible={modalVisible}
      centered
      closable={false}
      footer={null}
      title={translation.confinementResultReport.form.title}
    >
      <Container>
        <Spin spinning={isLoading}>
          {/* Form */}
          <Row type="flex" gutter={8}>
            <Col span={24}>
              <Formik
                ref={formik}
                initialValues={form}
                enableReinitialize={true}
                onSubmit={handleSubmit}
                validationSchema={validateSchema}
                render={({
                  values,
                  errors,
                  submitCount,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    {/* Body */}
                    <div className="body">
                      {Object.entries(errors).length > 0 && submitCount > 0 && (
                        <Row type="flex" justify="center" align="middle">
                          <label className="error">
                            {translation.error.formError}
                          </label>
                        </Row>
                      )}
                      {/* salesPeriod */}
                      <Row type="flex" justify="start" gutter={8}>
                        <Col span={24}>
                          <Row>
                            <label
                              className={
                                (errors.startDate || errors.endDate) &&
                                submitCount > 0
                                  ? "error"
                                  : ""
                              }
                            >
                              {
                                translation.confinementResultReport.form
                                  .salesPeriod
                              }
                              *
                            </label>
                          </Row>
                          <Row type="flex" gutter={8} align="middle">
                            <Col span={8}>
                              <DatePicker
                                name="startDate"
                                format={"DD/MM/YYYY"}
                                value={
                                  values.startDate
                                    ? moment(values.startDate)
                                    : undefined
                                }
                                disabledDate={(currentDate) =>
                                  currentDate.isAfter(moment())
                                }
                                allowClear={false}
                                onChange={(date) =>
                                  setFieldValue("startDate", date)
                                }
                              />
                            </Col>
                            <Col span={2}>até</Col>
                            <Col span={8}>
                              <DatePicker
                                name="endDate"
                                format={"DD/MM/YYYY"}
                                value={
                                  values.endDate
                                    ? moment(values.endDate)
                                    : undefined
                                }
                                disabledDate={(currentDate) =>
                                  currentDate.isBefore(values.startDate) ||
                                  currentDate.isAfter(moment())
                                }
                                allowClear={false}
                                onChange={(date) =>
                                  setFieldValue("endDate", date)
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {/* clientId & supplierId */}
                      <Row type="flex" justify="start" gutter={8}>
                        <Col span={12}>
                          <Row>
                            <label>
                              {
                                translation.confinementResultReport.form
                                  .clientId
                              }
                            </label>
                          </Row>
                          <Row type="flex">
                            <Select
                              name="clientId"
                              value={values.clientId || undefined}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              allowClear
                              onDeselect={() => setFieldValue("clientId", null)}
                              placeholder={translation.defaultSelectPlaceholder}
                              onChange={(value) =>
                                setFieldValue("clientId", value)
                              }
                            >
                              {clients.map((c) => (
                                <Select.Option value={c.id} key={c.id}>
                                  {c.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Row>
                            <label>
                              {
                                translation.confinementResultReport.form
                                  .supplierId
                              }
                            </label>
                          </Row>
                          <Row type="flex">
                            <Select
                              name="supplierId"
                              value={values.supplierId || undefined}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              placeholder={translation.defaultSelectPlaceholder}
                              loading={isLoadingDropDownSuppliers}
                              allowClear
                              onDeselect={() =>
                                setFieldValue("supplierId", null)
                              }
                              onChange={(value) =>
                                setFieldValue("supplierId", value)
                              }
                            >
                              {suppliers.map((c) => (
                                <Select.Option value={c.id} key={c.id}>
                                  {c.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Row>
                        </Col>
                      </Row>
                      {/* saleScenarioId & documentNumber */}
                      <Row type="flex" justify="start" gutter={8}>
                        <Col span={12}>
                          <Row>
                            <label>
                              {
                                translation.confinementResultReport.form
                                  .saleScenarioId
                              }
                            </label>
                          </Row>
                          <Row type="flex">
                            <Select
                              name="saleScenarioIds"
                              mode="multiple"
                              value={values.saleScenarioIds || undefined}
                              placeholder={translation.defaultSelectPlaceholder}
                              onChange={(pValues) => {
                                setFieldValue("saleScenarioIds", pValues);
                              }}
                            >
                              {saleScenarios.map((c) => (
                                <Select.Option value={c.id} key={c.id}>
                                  {c.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Row>
                            <label>
                              {
                                translation.confinementResultReport.form
                                  .documentNumber
                              }
                            </label>
                          </Row>
                          <Row type="flex">
                            <Input
                              name="documentNumber"
                              value={values.documentNumber}
                              placeholder={translation.defaultPlaceholder}
                              onChange={(e) =>
                                setFieldValue("documentNumber", e.target.value)
                              }
                            />
                          </Row>
                        </Col>
                      </Row>
                      {/* enableLotResult */}
                      <Row type="flex" justify="start" gutter={8}>
                        <Col span={16}>
                          <Row>
                            <label>Habilitar Resultado por Lote?</label>
                          </Row>
                          <Row type="flex">
                            <Switch
                              checked={values.enableLotResult}
                              onChange={(checked) => {
                                setFieldValue("enableLotResult", checked);
                                setFieldValue("showDeathData", checked);
                              }}
                            />
                          </Row>
                        </Col>
                      </Row>
                      {values.enableLotResult && (
                        <>
                          {/* lotIds */}
                          <Row type="flex" justify="start" gutter={8}>
                            <Col span={24}>
                              <Row>
                                <label
                                  className={
                                    errors.lotIds && submitCount > 0
                                      ? "error"
                                      : ""
                                  }
                                >
                                  {
                                    translation.confinementResultReport.form
                                      .lotIds
                                  }
                                  *
                                </label>
                              </Row>
                              <Row type="flex">
                                <Select
                                  name="lotIds"
                                  mode="multiple"
                                  value={values.lotIds || undefined}
                                  placeholder={
                                    translation.defaultSelectPlaceholder
                                  }
                                  onChange={(pValues) => {
                                    setFieldValue("lotIds", pValues);
                                  }}
                                >
                                  {lots.map((c) => (
                                    <Select.Option value={c.id} key={c.id}>
                                      {c.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Row>
                            </Col>
                          </Row>
                          {/* showDeathData */}
                          <Row type="flex" justify="start" gutter={8}>
                            <Col span={16}>
                              <Row>
                                <label>
                                  {
                                    translation.confinementResultReport.form
                                      .showDeathData
                                  }
                                </label>
                              </Row>
                              <Row type="flex">
                                <Switch
                                  checked={values.showDeathData}
                                  onChange={(checked) =>
                                    setFieldValue("showDeathData", checked)
                                  }
                                />
                              </Row>
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                    {/* Footer */}
                    <Row type="flex">
                      <Col span={24}>
                        <Row
                          type="flex"
                          justify="end"
                          align="middle"
                          gutter={8}
                        >
                          <Col>
                            <ButtonStandard
                              type="button"
                              buttonType="type4"
                              size="s"
                              onClick={handleCancel}
                            >
                              {translation.buttons.cancel}
                            </ButtonStandard>
                          </Col>
                          <Col>
                            <ButtonStandard
                              type="submit"
                              buttonType="type1"
                              size="s"
                            >
                              {translation.buttons.generate}
                            </ButtonStandard>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </form>
                )}
              />
            </Col>
          </Row>
        </Spin>
      </Container>
    </Modal>
  );
};

export default ConfinementResultReportModal;
