import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
/** Components */
import { Col, Row, Tabs } from "antd";
import DecisionIcon from "../../components/admin/sidebar/icons/decision";
import ListBoitelSaleScenario from "../boitelSaleScenario";
import ListBoitelScenario from "../boitelScenario";
import ListPurchaseScenario from "../purchaseScenario";
import ListSaleScenario from "../saleScenario";
import { Container, PageTitle } from "./styles";

const Decisions = () => {
  const [tabSelect, setTabSelect] = useState("1");
  const history = useHistory();

  const {
    farmSelected: { boitel },
  } = useSelector((state) => state.app);

  useEffect(() => {
    if (history.location.state?.tab) {
      setTabSelect(history.location.state?.tab);
    }
  }, [history]);

  const handleTabClick = (e) => {
    setTabSelect(e);
  };

  return (
    <Container>
      {/* HEADER */}
      <Row type="flex">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <PageTitle>
            <DecisionIcon />
            <span>Decisões</span>
          </PageTitle>
        </Col>
      </Row>
      {/* Tabs */}
      <Row type="flex" style={{ marginTop: 16 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Tabs
            type="card"
            defaultActiveKey="1"
            activeKey={tabSelect}
            onTabClick={handleTabClick}
          >
            <Tabs.TabPane tab={<span>Venda</span>} key="1">
              <ListSaleScenario />
            </Tabs.TabPane>
            <Tabs.TabPane tab={<span>Compra</span>} key="2">
              <ListPurchaseScenario />
            </Tabs.TabPane>
            {boitel && (
              <Tabs.TabPane tab={<span>Boitel – Contratação</span>} key="3">
                <ListBoitelScenario />
              </Tabs.TabPane>
            )}
            {boitel && (
              <Tabs.TabPane tab={<span>Boitel – Venda </span>} key="4">
                <ListBoitelSaleScenario />
              </Tabs.TabPane>
            )}
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Decisions);
