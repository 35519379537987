import getHeaders from "../getHeaders";
import apiCommons from "../../config/api_commons";
import apiAnimals from "../../config/api_animals";

const ANIMAL_ENTITY = "lot";

export function getLotInputAndOutputAnimalsDashboard(payload) {
  const { groupId, farmId, dashboardParams, signal } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/${ANIMAL_ENTITY}/dashboards/LotInputAndOutputAnimalsDashboard`,
    dashboardParams,
    getHeaders(false, signal)
  );
}

export function getLotCapacityUtilizationExtensiveConsiderInAndOutAnimalsDashboard(
  payload
) {
  const { groupId, farmId, dashboardParams, signal } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/${ANIMAL_ENTITY}/dashboards/LotCapacityUtilizationExtensiveConsiderInAndOutAnimalsDashboard`,
    dashboardParams,
    getHeaders(false, signal)
  );
}
export function getLotCapacityUtilizationSemiIntensiveConsiderInAndOutAnimalsDashboard(
  payload
) {
  const { groupId, farmId, dashboardParams, signal } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/${ANIMAL_ENTITY}/dashboards/LotCapacityUtilizationSemiIntensiveConsiderInAndOutAnimalsDashboard`,
    dashboardParams,
    getHeaders(false, signal)
  );
}
export function getLotCapacityUtilizationIntensiveConsiderInAndOutAnimalsDashboard(
  payload
) {
  const { groupId, farmId, dashboardParams, signal } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/${ANIMAL_ENTITY}/dashboards/LotCapacityUtilizationIntensiveConsiderInAndOutAnimalsDashboard`,
    dashboardParams,
    getHeaders(false, signal)
  );
}

export function getLotAverageWeightAndGdpDashboard(payload) {
  const { groupId, farmId, dashboardParams, signal } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/${ANIMAL_ENTITY}/dashboards/LotAverageWeightAndGdpDashboard`,
    dashboardParams,
    getHeaders(false, signal)
  );
}

export function getLotProfitAndCostDashboard(payload) {
  const { groupId, farmId, dashboardParams, signal } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/${ANIMAL_ENTITY}/dashboards/LotProfitAndCostDashboard`,
    dashboardParams,
    getHeaders(false, signal)
  );
}

export function getLotRealAverageWeightDashboard(payload) {
  const { groupId, farmId, dashboardParams, signal } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/${ANIMAL_ENTITY}/dashboards/LotRealAverageWeightDashboard`,
    dashboardParams,
    getHeaders(false, signal)
  );
}

export function getLotRealAverageGdpDashboard(payload) {
  const { groupId, farmId, dashboardParams, signal } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/${ANIMAL_ENTITY}/dashboards/LotRealAverageGdpDashboard`,
    dashboardParams,
    getHeaders(false, signal)
  );
}

export function getDashboardLotDailyWeightAverage(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/lots/${id}/dashboards/LotDailyWeightAverage`,
    getHeaders(false, signal)
  );
}
