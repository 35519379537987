import React from "react";

// import { Container } from './styles';

function IconJustConfirm() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
    >
      <defs>
        <pattern
          id="pattern"
          preserveAspectRatio="none"
          width="100%"
          height="100%"
          viewBox="0 0 46 48"
        >
          <image
            width="46"
            height="48"
            href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAwCAYAAABuZUjcAAADGklEQVRogc2ZXYhNURTHf2auKVOK5KsUcqNGiuJlhPER5QmRB0ke5ONBHpTiiTxQU74SNV5MKTLlQXnwQClKKVI8uTU+m8lHItQ0t7SnfaZ9113nzD377Lmz/3W7Z62z716/e846e6+9zySe7CGg2oEpwD/gb8iOpVoC9bMbeA38Ab7Z76fA0XCotSoV/H0r0APsV8512k8XsKMoqFTRK341BdrVduBswTh1KgJeBg422PYUsKRArDoVAT+p+HbZ9NuonDtSIFadfMHbbAq46gX6gCrwyP4JV2tjADdpMk34rgj7pbAXeMZS5Qu+VfG9EPYcYQ96xlLlC75F2A+VNjOF/dMzliof8KnAJuG7r7SbK+zvBTjr5APeqfg0cHnFJzxV1gv7F/BeaSfBg15xnylf5rd2tVEeTqNWj3hVzZn3is8GlgtfGri84seB4ZyfQTsn1BVrecG1YbAvpa18OH00w6bmJeANzh3LC94l7ErarQSm5+fMVAfwOWmQB7xVmbbT0sToazjmUZlUvUvOh3OZMm2npQkphVZezQO6gXXO73aaSjMPuBxNsKucNA0EAB+w6flDpN62PKkix+8s6NCSJcXCRsHNNL9G+O41EVyO/x9LGScTtdu8ahf+W+H5VC228V09TsCvA3sVuDRVA+XwWJoPPBBtfgPPSjYFGl07JmpWmtwGFgnfZew4Xvbo8E4YrjHVozS4QYGFRNb4HVI3lb7KWeBl53OgSZCaqsqwO4uMmbPiHH9IuWXNkKkwV4s4/QTcOxwvnRb9mjvwNnbwE8Bh4etNFt0xg3covjPJQczg3YqvPzmIGbyi+EbrpZjB5ZoVO92PKPaH05V5NfMqsWMFP6aMKDXFVozgK4ALiv+8a8QIbranLwrfkNwNjjVVZInRJhvECv5J8dW8SIgVfIOwh+T+elp1ONk53qyc99m8bESGZyVwTfifaw01DY0RYDgca0M6JxvFXtZi17dywTwCHvTdTGCZ2nuf1mWL/UfyjdlE6wtwCFjq1ieukhxf5byTTx48s9owx+73eMvEeGf2TTIDAf8BLm+AFYCzG5EAAAAASUVORK5CYII="
          />
        </pattern>
      </defs>
      <g
        id="Group_3305"
        data-name="Group 3305"
        transform="translate(-714 -256)"
      >
        <g
          id="Ellipse_170"
          data-name="Ellipse 170"
          transform="translate(714 256)"
          fill="#cbffdc"
          stroke="#33c162"
          strokeWidth="1.5"
        >
          <circle cx="20.5" cy="20.5" r="20.5" stroke="none" />
          <circle cx="20.5" cy="20.5" r="19.75" fill="none" />
        </g>
        <rect
          id="joinha"
          width="17"
          height="18"
          transform="translate(726 267)"
          fill="url(#pattern)"
        />
      </g>
    </svg>
  );
}

export default IconJustConfirm;
