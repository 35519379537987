import React, { useMemo } from "react";
import { useSelector } from "react-redux";

/**
 * Props
 * * Component: Is the page Component
 * * Roles: Are the roles
 * * Reverse: If true we will return in isAllowed if the user have some role passed in props, if false we will return in isAllowed if the user don't have some role passed in props
 */

const AllowedComponentTo = ({
  roles = [],
  reverse = false,
  hide = false,
  ...props
}) => {
  const {
    user: { roles: userRoles },
  } = useSelector((state) => state.app);

  const isAllowed = useMemo(() => {
    return userRoles?.includes("Admin")
      ? true
      : roles.length === 0
      ? true
      : reverse === false
      ? roles.some((r) => userRoles?.includes(r))
      : !roles.some((r) => userRoles?.includes(r));
  }, [roles, reverse, userRoles]);
  return isAllowed
    ? React.cloneElement(props.children)
    : hide
    ? null
    : React.cloneElement(props.children, { disabled: true });
};

export default AllowedComponentTo;
