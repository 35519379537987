import React, { useState, useEffect } from "react";
import useWindowSize from "../../../../../hooks/useWindowSize";
/** Components */
import CanvasJSReact from "../../../../../assets/canvasjs.react";
import { Container } from "../styles";
import { Row } from "antd";

const DrawerDisplayDietStrategyChart = ({
  lot,
  maximumDate,
  lotCurve,
  dietPeriodCurve,
  gdpCurve,
  stripLines,
  drawerDetailsDietStrategyChartVisible,
  handleCloseDrawerChart,
}) => {
  const { CanvasJSChart } = CanvasJSReact;
  const [chartOptions, setChartOptions] = useState({
    animationEnabled: true,
    zoomEnabled: true,
    height: 380,
    axisX: {
      labelFontSize: 12,
      valueFormatString: "MM/YY",
      interval: 7,
      maximum: maximumDate.toDate(),
      intervalType: "month",
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
      },
      stripLines: [],
    },
    toolTip: {
      fontFamily: "Asap",
      shared: true,
    },
    axisY: {
      labelFontSize: 12,
      includeZero: true,
      valueFormatString: "###0.##",
      suffix: "kg",
      gridDashType: "dot",
    },
    axisY2: {
      lineColor: "#106518",
      tickColor: "#106518",
      labelFontSize: 12,
      includeZero: true,
      valueFormatString: "###0.##",
      suffix: "kg",
      gridDashType: "dot",
    },
    data: [
      {
        id: "dietStrategyCurve",
        type: "line",
        name: "Estratégia de Dieta",
        showInLegend: true,
        markerType: "none",
        markerSize: 5,
        xValueFormatString: "DD/MM/YY",
        yValueFormatString: "###0.## kg",
        color: "#684E94",
        dataPoints: [],
      },
      {
        id: "dietStrategyCurveGdp",
        type: "line",
        name: "Ganho de peso",
        showInLegend: true,
        markerType: "none",
        markerSize: 5,
        xValueFormatString: "DD/MM/YY",
        yValueFormatString: "###0.## kg",
        color: "#a9c133",
        axisYType: "secondary",
        dataPoints: [],
      },
    ],
  });
  const size = useWindowSize();
  /** Effect */
  /** In this effect the chart options will be updated with values from props sending by DrawerDisplayDietStrategy   */
  useEffect(() => {
    setChartOptions({
      animationEnabled: true,
      zoomEnabled: true,
      height: 380,
      exportFileName: `Gráfico - Estratégia de Dieta`,
      exportEnabled: true,
      axisX: {
        labelFontSize: 12,
        valueFormatString: "MM/YY",
        interval: 7,
        maximum: maximumDate.toDate(),
        intervalType: "month",
        crosshair: {
          enabled: true,
          snapToDataPoint: true,
        },
        stripLines: stripLines,
      },
      toolTip: {
        fontFamily: "Asap",
        shared: true,
      },
      axisY: {
        labelFontSize: 12,
        includeZero: true,
        valueFormatString: "###0.##",
        suffix: "kg",
        gridDashType: "dot",
        stripLines:
          lot != null
            ? [
                {
                  value: lot?.asymptoticWeight,
                  color: "#f53030",
                  thickness: 3,
                  showOnTop: true,
                  label: "Peso assintótico",
                  labelFontColor: "#f53030",
                  labelFontWeight: "bold",
                },
              ]
            : [],
      },
      axisY2: {
        lineColor: "#106518",
        tickColor: "#106518",
        labelFontColor: "#106518",
        labelFontSize: 12,
        includeZero: true,
        valueFormatString: "###0.##",
        suffix: "kg",
        gridDashType: "dot",
      },
      data: [
        {
          id: "dietStrategyCurve",
          type: "line",
          name: "Estratégia de Dieta",
          showInLegend: true,
          markerType: "none",
          markerSize: 5,
          xValueFormatString: "DD/MM/YY",
          yValueFormatString: "###0.## kg",
          color: "#684E94",
          dataPoints: dietPeriodCurve,
        },
        {
          id: "dietStrategyCurveGdp",
          type: "line",
          name: "Ganho de peso",
          showInLegend: true,
          markerType: "none",
          markerSize: 5,
          xValueFormatString: "DD/MM/YY",
          yValueFormatString: "###0.## kg",
          color: "#106518",
          axisYType: "secondary",
          dataPoints: gdpCurve,
        },
      ],
    });
  }, [lotCurve, dietPeriodCurve, gdpCurve, stripLines, maximumDate, lot]);
  return (
    <Container
      title="Gráfico"
      width={size.width >= 1440 ? 1000 : 600}
      visible={drawerDetailsDietStrategyChartVisible}
      onClose={handleCloseDrawerChart}
      maskClosable={false}
    >
      <Row type="flex">
        <CanvasJSChart options={chartOptions} />
      </Row>
    </Container>
  );
};

export default DrawerDisplayDietStrategyChart;
