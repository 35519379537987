import styled from "styled-components";
import { Card, Collapse, Icon, Row } from "antd";

export const CardCustom = styled(Card)`
  margin-top: 13px !important;
  overflow-x: hidden;
  border-radius: 7px !important;
  background: #fff !important;
  box-shadow: 0px 0px 10px #d8d8d8 !important;
  overflow-y: hidden;
  padding: 0px !important;

  .ant-card-body {
    padding-top: 14px;
  }

  .filter-row {
    margin-bottom: 16px;

    .ant-row:first-child {
      margin-bottom: 8px;
    }
  }

  .filter-label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
  }

  .card-row {
    width: 100%;

    .arrow-up {
      svg {
        font-size: 15px;
        color: #684e94;
      }
    }
  }

  .row-summarize {
    background: #ba93fb !important;
  }
  .ant-table-row-hover:has(.row-summarize) {
    background: #ba93fb !important;
  }
`;

export const IncomePanel = styled(Collapse.Panel)`
  border-radius: 7px !important;
  box-shadow: 0px 0px 10px #d8d8d8 !important;
  background-color: #fff !important;
  margin-bottom: 13px;

  .income-title {
    font-size: 15px;
    font-weight: bold;
  }

  .income-value {
    font-size: 15px;
    font-weight: bold;
    color: rgb(51, 193, 98);
  }

  .value-percentage {
    font-size: 11px;
    font-style: italic;
  }
`;

export const ExpensePanel = styled(Collapse.Panel)`
  border-radius: 7px !important;
  box-shadow: 0px 0px 10px #d8d8d8 !important;
  background-color: #fff !important;
  margin-bottom: 13px;

  .expense-title {
    font-size: 15px;
    font-weight: bold;
  }

  .expense-value {
    font-size: 15px;
    font-weight: bold;
    color: rgb(212, 76, 76);
  }

  .value-percentage {
    font-size: 11px;
    font-style: italic;
  }
`;

export const TotalPanel = styled(Row)`
  border-radius: 7px !important;
  box-shadow: 0px 0px 10px #d8d8d8 !important;
  background-color: #fff !important;
  padding: 7px 13px 7px 13px;
  margin-bottom: 13px;

  span {
    color: rgba(0, 0, 0, 0.85);
    font-size: 15px;
    font-weight: bold;
  }

  .positive-value {
    font-size: 15px;
    font-weight: bold;
    color: rgb(51, 193, 98);
  }

  .negative-value {
    font-size: 15px;
    font-weight: bold;
    color: rgb(212, 76, 76);
  }
`;

export const ArrowIcon = styled(Icon)`
  svg {
    font-size: 15px !important;
    color: #684e94 !important;
  }
`;
