import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Col, Row, Table } from "antd";
import { Container } from "./styles";

// Components
import ButtonStandard from "../../../../../components/utils/button";

// Services
import { findAllFinancialClassesByGroupIdAndFarmId } from "../../../../../services/financialClassService";
import { indexAllWithItems } from "../../../../../services/costCenterService";

const FinancialCostCenterModal = ({ isModalVisible, closeModal }) => {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const Column = Table.Column;

  const [isLoading, setIsLoading] = useState(false);
  const [financialClassList, setFinancialClassList] = useState([]);
  const [costCenterList, setCostCenterList] = useState(null);

  // Fetch financial classes
  useEffect(() => {
    // fetch data
    async function fetch() {
      try {
        setIsLoading(true);

        const {
          data: { results: classes },
        } = await findAllFinancialClassesByGroupIdAndFarmId({
          groupId,
          farmId,
        });
        const {
          data: { results: costCenterList },
        } = await indexAllWithItems({ groupId, farmId });

        setFinancialClassList(classes);
        setCostCenterList(costCenterList);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }

    if (isModalVisible) {
      fetch();
    }
  }, [groupId, farmId, isModalVisible]);

  const handleClose = () => {
    closeModal();
  };

  const handleRenderColumns = () => {
    if (
      costCenterList !== null &&
      costCenterList !== undefined &&
      costCenterList.length > 0
    ) {
      return costCenterList.map((cc) => (
        <Column
          title={cc.name}
          key={cc.id}
          align="left"
          width={200}
          render={(value, record) => {
            let cci = cc.items.find(
              (cci) =>
                cci.costCenterId === cc.id && cci.financialClassId === record.id
            );
            return <span>{cci?.name}</span>;
          }}
        />
      ));
    }
  };

  return (
    <Container
      title={
        <Row type="flex" justify="start">
          <strong>
            {translation.financial.parameters.costCenter.modal.title}
          </strong>
        </Row>
      }
      width={1040}
      visible={isModalVisible}
      footer={null}
      centered
      closable={false}
    >
      <Row type="flex" justify="start" align="middle" gutter={8}>
        <Col span={24}>
          <Table
            rowKey="id"
            loading={isLoading}
            dataSource={financialClassList !== null ? financialClassList : []}
            pagination={false}
            size="small"
            scroll={{ x: true, y: 400 }}
          >
            <Column
              title="Classe"
              dataIndex="name"
              key="name"
              width={200}
              align="left"
            />

            {handleRenderColumns()}
          </Table>
        </Col>
      </Row>

      {/* Footer */}
      <Row
        className="rowFooter"
        type="flex"
        justify="center"
        align="middle"
        gutter={8}
      >
        <Col>
          <ButtonStandard
            type="button"
            name="buttonClose"
            buttonType="principal"
            onClick={handleClose}
          >
            Fechar
          </ButtonStandard>
        </Col>
      </Row>
    </Container>
  );
};

export default FinancialCostCenterModal;
