import moment from "moment";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import CanvasJSReact from "../../assets/canvasjs.react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as MarketAnalysisActions } from "../../store/ducks/marketAnalysis";

import {
  Button,
  Col,
  Dropdown,
  Icon,
  Input,
  Menu,
  Modal,
  Row,
  Spin,
  Table,
  Tabs,
  notification,
} from "antd";

import DrawerMarketAnalysisDetails from "../../components/drawers/marketAnalysis/details";
import ButtonStandard from "../../components/utils/button";
import MenuIcon from "../../components/utils/table/icons/menu";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import TagStatus from "../../components/utils/tagStatus";
import { Title } from "./styles";

//Pages
import BoitelPrice from "../boitelPrice";
import Quotas from "../quotas";

// Services
import ConfirmActionModal from "../../components/modals/confirmActionModal";
import WarningIcon from "../../components/utils/icons/warning";
import { getFuturePrice } from "../../services/futurePriceService";
import { setMarketAnalysisBaseline } from "../../services/marketAnalysisService";

const { CanvasJSChart } = CanvasJSReact;

class MarketAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      loadingChart: false,
      showModalDelete: false,
      showModalReactive: false,
      selectedMarketAnalysis: null,
      chartOptions: {
        animationEnabled: true,
        zoomEnabled: true,
        height: 220,
        backgroundColor: "#fff",
        axisX: {
          labelFontSize: 12,
          valueFormatString: "MM/YY",
          interval: 1,
          intervalType: "month",
          viewportMinimum: moment().subtract(1, "months").toDate(),
          viewportMaximum: moment().add(1, "years").toDate(),
          crosshair: {
            enabled: true,
            snapToDataPoint: true,
          },
        },
        toolTip: {
          fontFamily: "Asap",
          shared: true,
        },
        axisY: {
          labelFontSize: 12,
          includeZero: true,
          valueFormatString: "###0.##",
          suffix: " R$/@",
          gridDashType: "dot",
        },
        legend: {
          cursor: "pointer",
        },
        data: [],
      },
      tabSelect: "1",
    };
  }

  handleTabClick = (key, e) => {
    this.setState({
      tabSelect: key,
    });
  };

  componentDidMount() {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;
    if (
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      this.fetchData(groupSelected, farmSelected, 0, {});
    }

    const { location } = this.props;
    if (location?.state?.subTab) {
      this.setState({
        tabSelect: location?.state?.subTab,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    const differentGroup =
      this.props.app.groupSelected?.id !== nextProps.app.groupSelected?.id;
    const differentFarm =
      this.props.app.farmSelected?.id !== nextProps.app.farmSelected?.id;
    if (differentGroup || differentFarm) {
      if (
        Object.entries(nextProps.app.groupSelected).length > 0 &&
        Object.entries(nextProps.app.farmSelected).length > 0
      ) {
        this.fetchData(
          nextProps.app.groupSelected,
          nextProps.app.farmSelected,
          0,
          {}
        );
      }
    }
    return true;
  }

  fetchData = async (
    groupSelected,
    farmSelected,
    page = 0,
    sorter = {},
    filters = "",
    size = 10
  ) => {
    const { marketAnalysisActions } = this.props;
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    marketAnalysisActions.indexMarketAnalysis(
      groupId,
      farmId,
      page,
      sorter,
      filters,
      size
    );
  };

  showOrHideModalActions = (modal, marketAnalysis) => {
    switch (modal) {
      case "delete":
        this.setState({
          showModalDelete: !this.state.showModalDelete,
          selectedMarketAnalysis: marketAnalysis,
        });
        break;
      case "reactive":
        this.setState({
          showModalReactive: !this.state.showModalReactive,
          selectedMarketAnalysis: marketAnalysis,
        });
        break;
      default:
        break;
    }
  };

  showOrHidePopoverSetBaseline = (id) => {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;

    const handleSetBaseline = async (groupSelected, farmSelected, id) => {
      try {
        if (
          await setMarketAnalysisBaseline({
            groupId: groupSelected.id,
            farmId: farmSelected.id,
            id,
          })
        ) {
          notification.success({
            message: "Curva de Preço configurada como Padrão com sucesso",
          });
          await this.fetchData(groupSelected, farmSelected, 0, {});
        }
      } catch (error) {
        notification.error({ message: "A Curva de Preço não foi atualizada" });
      }
    };

    Modal.confirm({
      title:
        "Você tem certeza que deseja tornar essa curva Padrão para a Fazenda?",
      content:
        "Ao confirmar, todos os cálculos que utilizam curva de preços passão a usar esses novos valores.",
      okText: "Confirmar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        handleSetBaseline(groupSelected, farmSelected, id);
      },
      onCancel() {},
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
            background: "#684e94",
            borderColor: "none",
            border: "none",
          }}
        />
        <Button
          onClick={() => this.handleReset(clearFilters)}
          icon="delete"
          size="small"
          style={{ width: 90 }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  handleReset = (clearFilters) => {
    clearFilters();
  };

  handleTableChange = async (pagination, filters, sorter) => {
    this.setState({
      paginationInfo: pagination,
      sortedInfo: sorter,
      filteredInfo: filters,
    });
    const {
      app: { groupSelected, farmSelected },
    } = this.props;

    let search = "";
    if (Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v.length > 0) {
          if (k === "status") {
            let statusString = "";
            for (let i = 0; i < v.length; i++) {
              statusString += i === v.length - 1 ? `'${v[i]}'` : `'${v[i]}',`;
            }
            search = `${search};${k} IN (${statusString})`;
          } else if (k === "closingDate") {
            search = `${search};${k} between '${v[0]}' and '${v[1]}'`;
          } else {
            search = `${search};${k}='${v}'`;
          }
        }
      });
    }
    await this.fetchData(
      groupSelected,
      farmSelected,
      pagination.current,
      sorter,
      search,
      pagination.pageSize
    );
  };

  handleEdit = async (id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      marketAnalysisActions,
    } = this.props;
    await Promise.all([
      marketAnalysisActions.showMarketAnalysis(groupId, farmId, id),
      marketAnalysisActions.showDrawer(),
    ]);
  };

  handleDelete = async () => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      marketAnalysisActions,
    } = this.props;
    const { selectedMarketAnalysis } = this.state;

    marketAnalysisActions.deleteMarketAnalysis(
      groupId,
      farmId,
      selectedMarketAnalysis.id
    );

    this.showOrHideModalActions("delete", null);
  };

  handleActive = async () => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      marketAnalysisActions,
    } = this.props;
    const { selectedMarketAnalysis } = this.state;

    marketAnalysisActions.activateMarketAnalysis(
      groupId,
      farmId,
      selectedMarketAnalysis.id
    );

    this.showOrHideModalActions("reactive", null);
  };

  handleDetails = (record) => {
    const { marketAnalysisActions } = this.props;
    marketAnalysisActions.showOrHideMarketAnalysisDetails(record);
  };

  handleBuildChart = async (selectedRowKeys) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      marketAnalysis: { data: marketAnalysis },
    } = this.props;
    let chartData = [];
    this.setState({
      loadingChart: true,
    });
    await Promise.all(
      selectedRowKeys.map(async (id) => {
        const index = marketAnalysis.content.findIndex((ma) => ma.id === id);
        if (index >= 0) {
          const marketObj = marketAnalysis.content[index];
          try {
            const {
              data: { results: dataMarketAnalysisFuturePrice },
            } = await getFuturePrice({
              groupId,
              farmId,
              marketAnalysisId: id,
              justFuture: true,
            });

            if (
              dataMarketAnalysisFuturePrice != null &&
              dataMarketAnalysisFuturePrice.length > 0
            ) {
              const futurePricesArray = dataMarketAnalysisFuturePrice.map(
                (r, i) => {
                  if (i === 0) {
                    return {
                      price: r.price,
                      month: r.month,
                      first: true,
                    };
                  } else {
                    return {
                      price: r.price,
                      month: r.month,
                    };
                  }
                }
              );

              const futurePricesArrayXY = futurePricesArray.map((fp) => ({
                x: moment(fp.month, "YYYY-MM").toDate(),
                y: fp.price,
              }));

              chartData.push({
                id,
                type: "line",
                showInLegend: true,
                name: marketObj?.name,
                visible: true,
                xValueFormatString: "DD/MM/YYYY",
                yValueFormatString: "R$#######.00",
                dataPoints: futurePricesArrayXY,
              });
            }
          } catch (error) {}
        }
      })
    );
    this.setState({
      loadingChart: false,
      chartOptions: {
        ...this.state.chartOptions,
        data: chartData,
      },
    });
  };

  render() {
    const {
      app: {
        translation,
        farmSelected: { boitel },
      },
      marketAnalysis: { data, isLoading },
    } = this.props;

    const {
      loadingChart,
      chartOptions,
      selectedRowKeys,
      showModalDelete,
      showModalReactive,
      selectedMarketAnalysis,
      tabSelect,
    } = this.state;

    const Column = Table.Column;

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRowKeys,
        });
        this.handleBuildChart(selectedRowKeys);
      },
    };

    const menu = (marketAnalysis) => {
      const {
        app: { translation },
      } = this.props;
      return (
        <Menu>
          {marketAnalysis.groupId !== null &&
          marketAnalysis?.status === "Inactive" ? (
            <Menu.Item
              key="5"
              onClick={() =>
                this.showOrHideModalActions("reactive", marketAnalysis)
              }
            >
              {translation.table.menu.activate}
            </Menu.Item>
          ) : null}
          <Menu.Item key="3" onClick={() => this.handleDetails(marketAnalysis)}>
            {translation.table.menu.details}
          </Menu.Item>
          {marketAnalysis.groupId !== null &&
          marketAnalysis?.status === "Active" ? (
            <Menu.Item key="1">
              <Link to={`/admin/marketAnalysis/${marketAnalysis.id}/edit`}>
                {translation.table.menu.edit}
              </Link>
            </Menu.Item>
          ) : null}
          {marketAnalysis.groupId !== null &&
          marketAnalysis?.status === "Active" ? (
            <Menu.Item
              key="4"
              onClick={() =>
                this.showOrHideModalActions("delete", marketAnalysis)
              }
            >
              {translation.table.menu.inactivate}
            </Menu.Item>
          ) : null}
          {marketAnalysis?.status === "Active" && (
            <Menu.Item
              key="2"
              onClick={() =>
                this.showOrHidePopoverSetBaseline(marketAnalysis.id)
              }
            >
              {translation.table.menu.setBaseline}
            </Menu.Item>
          )}
        </Menu>
      );
    };

    const renderMarketAnalysisPage = () => {
      return (
        <>
          <Row
            type="flex"
            justify="space-between"
            className="rowHeader"
            style={{ marginTop: 10 }}
          >
            <Col>
              <Title>{translation.marketAnalysis.table.title}</Title>
            </Col>
            <Col>
              <Link to={`/admin/marketAnalysis/new`}>
                <ButtonStandard type="button" buttonType="type8">
                  {translation.marketAnalysis.buttonNewMarketAnalysis}
                </ButtonStandard>
              </Link>
            </Col>
          </Row>
          <Row type="flex">
            {selectedRowKeys.length > 0 && (
              <Col span={24}>
                <Spin spinning={loadingChart}>
                  <CanvasJSChart options={chartOptions} />
                </Spin>
              </Col>
            )}
          </Row>
          <Row type="flex" justify="center">
            <span className="chartMessage">
              *Selecione uma ou mais curvas para visualizar seus valores em
              formato de gráfico.
            </span>
          </Row>
          <Row type="flex" justify="space-between">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Table
                loading={isLoading}
                rowSelection={rowSelection}
                rowKey="id"
                size="small"
                dataSource={data != null ? data.content : []}
                pagination={{
                  total: data != null ? data.totalElements : 0,
                  size: data != null ? data.size : 0,
                  current: data != null ? data.number + 1 : 0,
                  hideOnSinglePage:
                    data !== null && Object.entries(data).length !== 0
                      ? data.totalElements > 10
                        ? false
                        : true
                      : true,
                  showSizeChanger: true,
                  pageSizeOptions: [
                    "10",
                    "20",
                    "30",
                    "100",
                    "500",
                    "1000",
                    "2000",
                  ],
                }}
                scroll={{ x: true }}
                style={{ width: "100%", margin: "0" }}
                onChange={this.handleTableChange}
              >
                <Column
                  title={translation.marketAnalysis.table.columns.status}
                  dataIndex="status"
                  key="status"
                  align="left"
                  sorter
                  sortDirections={["descend", "ascend"]}
                  render={(status) =>
                    status === "Active" ? (
                      <TagStatus
                        background="#C5F1CA"
                        borderColor="#106518"
                        color="#106518"
                      >
                        {translation.status.active}
                      </TagStatus>
                    ) : (
                      <TagStatus
                        background="#FBC7C7"
                        borderColor="#D44C4C"
                        color="#D44C4C"
                      >
                        {translation.status.inactive}
                      </TagStatus>
                    )
                  }
                />
                <Column
                  title={translation.marketAnalysis.table.columns.type}
                  dataIndex="type"
                  key="type"
                  align="left"
                  sorter
                  sortDirections={["descend", "ascend"]}
                  render={(type) =>
                    type === "Free" ? (
                      <TagStatus
                        background="#C5F1CA"
                        borderColor="#106518"
                        color="#106518"
                      >
                        {translation.marketAnalysis.type.free}
                      </TagStatus>
                    ) : type === "Progressive" ? (
                      <TagStatus
                        background="#FFDBBC"
                        borderColor="#FE8D2A"
                        color="#FE8D2A"
                      >
                        {translation.marketAnalysis.type.progressive}
                      </TagStatus>
                    ) : type === "Linear" ? (
                      <TagStatus
                        background="#684e75"
                        borderColor="#684e94"
                        color="#FFFFFF"
                      >
                        {translation.marketAnalysis.type.linear}
                      </TagStatus>
                    ) : (
                      <TagStatus
                        background="#E3E3E3"
                        borderColor="#C4C4C4"
                        color="#4B4B4B"
                      >
                        {translation.marketAnalysis.type.bovexo}
                      </TagStatus>
                    )
                  }
                />
                <Column
                  title={translation.marketAnalysis.table.columns.description}
                  dataIndex="name"
                  align="left"
                  sorter
                  key="name"
                />
                <Column
                  title={translation.marketAnalysis.table.columns.baseline}
                  dataIndex="baseline"
                  align="left"
                  key="baseline"
                  render={(baseline) => (baseline ? "Padrão" : "")}
                />
                <Column
                  align="left"
                  render={(text, record) => (
                    <Dropdown
                      overlay={menu(record)}
                      trigger={["click"]}
                      key={record.id}
                    >
                      <MenuContainer>
                        <MenuIcon />
                      </MenuContainer>
                    </Dropdown>
                  )}
                />
              </Table>
            </Col>
          </Row>
          <DrawerMarketAnalysisDetails />
          <ConfirmActionModal
            show={showModalDelete}
            title={`Você realmente quer inativar esta Curva de Preço ?`}
            message={
              <span>
                {`Ao confirmar, a curva de preço: `}
                <strong>{selectedMarketAnalysis?.name}</strong>
                {` ficará com o status Inativo, podendo posteriormente ser reativada.`}
              </span>
            }
            icon={<WarningIcon />}
            width={550}
            cancelAction={() => this.showOrHideModalActions("delete", null)}
            confirmAction={this.handleDelete}
          />
          <ConfirmActionModal
            show={showModalReactive}
            width={550}
            title={`Você realmente quer reativar esta Curva de Preço ?`}
            message={
              <span>
                {`Ao confirmar, a curva de preço: `}
                <strong>{selectedMarketAnalysis?.name}</strong>
                {` ficará com o status Ativo.`}
              </span>
            }
            icon={
              <Icon
                type="question-circle"
                style={{ color: "#4A85AE", fontSize: 24 }}
              />
            }
            cancelAction={() => this.showOrHideModalActions("reactive", null)}
            confirmAction={this.handleActive}
          />
        </>
      );
    };

    return (
      <Row type="flex">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {/* Tabs */}
          <Row type="flex">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Tabs
                type="card"
                defaultActiveKey="1"
                activeKey={tabSelect}
                onTabClick={this.handleTabClick}
              >
                <Tabs.TabPane
                  tab={
                    <span>{translation.parameters.tabs.marketAnalysis}</span>
                  }
                  key="1"
                >
                  {renderMarketAnalysisPage()}
                </Tabs.TabPane>
                {boitel === true && (
                  <Tabs.TabPane
                    tab={<span>{translation.parameters.tabs.boitelPrice}</span>}
                    key="2"
                  >
                    <BoitelPrice />
                  </Tabs.TabPane>
                )}
                <Tabs.TabPane
                  tab={<span>{translation.parameters.tabs.quota}</span>}
                  key="3"
                >
                  <Quotas />
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  marketAnalysis: state.marketAnalysis,
});

const mapDispatchToProps = (dispatch) => ({
  marketAnalysisActions: bindActionCreators(MarketAnalysisActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MarketAnalysis)
);
