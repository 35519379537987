import { useContext } from "react";
import { AnimalWeightHistoryContext } from "../../contexts/animalWeightHistoryContext";

const useAnimalWeightHistoryContext = () => {
  const value = useContext(AnimalWeightHistoryContext);

  return value;
};

export default useAnimalWeightHistoryContext;
