import styled, { keyframes } from "styled-components";

const heart_beat = keyframes`
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 768px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
  }
`;
export const Container = styled.ul`
  height: 55px !important;
  width: 100% !important;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0 !important;

  cursor: pointer;

  li {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      text-decoration: none;
      color: #a6bd32;
      &:hover {
        cursor: pointer;
        color: #82932a;
      }
    }
    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      border: none;
      background: transparent;
      text-decoration: none;
      color: #a6bd32;
      height: 100%;
      cursor: pointer;

      svg {
        margin-left: 12px;
      }

      &:hover {
        color: #82932a;
      }
      &.active {
        color: #82932a;
      }
    }

    &:hover {
      cursor: pointer;
    }
    svg {
      margin-left: 12px;
    }

    &:last-child {
      margin-right: 10px;
      /* &:before {
        content: '|';
        color: #ebebf2;
        margin-right: 20px;
      } */
    }
    &.divider {
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
      height: 29px !important;
      margin: 0 5px 0 25px;
    }
  }

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 768px) {
    /* start of medium tablet styles */
    justify-content: start;
    .divider {
      display: none;
    }
    > li {
      margin: 0;
      flex: 1;

      button.change-grupo-farm {
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 30px;
        color: #fff;
      }

      &:last-child {
        margin-right: 0px;
      }

      svg {
        margin: 0px !important;
      }

      img {
        margin: 0px !important;
      }
    }
  }

  @media screen and (max-width: 576px) {
    justify-content: start;
    .divider {
      display: none;
    }
    > li {
      margin: 0;
      flex: 1;

      button.change-grupo-farm {
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 30px;
        color: #fff;
      }

      &:last-child {
        margin-right: 0px;
      }

      svg {
        margin: 0px !important;
      }

      img {
        margin: 0px !important;
      }
    }
  }
`;

/* ---------- GROUP MENU ------------------ */
export const GroupMenu = styled.li`
  justify-content: center;
  align-items: center;
  span {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    margin-right: 14px;
  }
  button {
    display: flex;
    flex-direction: row !important;
    width: auto;
    height: 34px !important;
    max-width: 322px !important;
    border-bottom: 1px solid #fff !important;
    font-size: 14px !important;
    justify-content: flex-start !important;
    text-align: center;
    div {
      width: auto !important;
      height: auto !important;
    }
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    span {
      font-size: 10px !important;
    }
    button {
      font-size: 10px !important;
    }
  }

  @media screen and (max-width: 768px) {
    span,
    button > span {
      font-size: 0.2em;
      word-wrap: normal;
    }
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
    span,
    button > span {
      font-size: 0.2em;
      word-wrap: normal;
    }
  }
`;

export const FarmMenu = styled.li`
  margin-left: 15px !important;
  margin-right: auto !important;

  justify-content: center;
  align-items: center;

  span {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    margin-right: 14px;
  }
  button {
    display: flex;
    flex-direction: row !important;
    width: auto;
    height: 34px !important;
    max-width: 322px !important;
    border-bottom: 1px solid #fff !important;
    font-size: 14px !important;
    justify-content: flex-start !important;
    text-align: center;
    &.animate {
      -webkit-animation: ${heart_beat} 2s ease-in-out infinite both;
      animation: ${heart_beat} 2s ease-in-out infinite both;
    }
    div {
      width: auto !important;
      height: auto !important;
    }
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    span {
      font-size: 10px !important;
    }
    button {
      font-size: 10px !important;
    }
  }

  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
  }
`;

export const GroupDropDownMenu = styled.ul`
  display: none;
  position: relative;
  max-height: 490px;
  overflow: auto;
  overflow-x: hidden;

  &.moz {
    scrollbar-color: #684e94 #e8e5e5;
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #e8e5e5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #684e94;
  }

  &.active {
    display: flex;
    background: #f5f5f5;
    position: absolute;
    flex-direction: column;
    left: 0;
    top: 90%;
    width: 351px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    -moz-box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    z-index: 999999;
    li {
      width: 100% !important;
      margin: 0 !important;
      border-bottom: 1px solid #e8e8e8;
      height: 70px !important;

      &:first-of-type {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        height: 70px !important;
        button {
          padding: 0 !important;
          width: 100% !important;
          height: 100% !important;
          font-weight: bold;
          color: #d6d6d6 !important;
          text-transform: uppercase;
          svg {
            margin-right: 24px;
          }

          &:hover {
            background: #f5f5f5;
          }
        }
        &:hover {
          background: #f5f5f5;
        }
        svg {
          width: 37.47px !important;
          height: 37.47px !important;
        }
      }

      &:last-child {
        margin: 0;
        border: none;
      }

      button {
        padding: 0 !important;
        width: 100% !important;
        display: flex !important;
        flex-direction: row !important;
        justify-content: flex-start !important;
        align-items: center !important;
        height: 70px !important;
        color: #383838 !important;
        font-size: 14px !important;

        div {
          display: flex;
          flex-direction: column;
          height: 100% !important;
          align-items: flex-start;
          justify-content: center;
          margin: 0 !important;
          span.title {
            color: #4b4b4b !important;
            font-size: 14px;
            font-weight: bold;
          }
          span.subtitle {
            color: #684e94 !important;
            font-size: 12px;

            &.disable {
              color: #c4c4c4 !important;
              font-size: 12px;
            }
          }
        }
        a {
          margin-left: auto;
          z-index: 9999;
          span {
            font-weight: bold;
            color: #684e94 !important;
            font-size: 14px !important;
          }

          &:hover {
            span {
              text-decoration: underline;
            }
          }
        }
      }

      &:hover {
        background: #fdfcff;
        &:first-of-type {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        &:last-of-type {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 768px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
  }
`;

export const FarmDropDownMenu = styled.ul`
  display: none;
  position: relative;
  max-height: 490px;
  overflow: auto;
  overflow-x: hidden;

  &.moz {
    scrollbar-color: #684e94 #e8e5e5;
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #e8e5e5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #684e94;
  }

  &.active {
    display: flex;
    background: #f5f5f5;
    position: absolute;
    flex-direction: column;
    left: 0;
    top: 90%;
    width: 351px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    -moz-box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    z-index: 999999;
    li {
      width: 100% !important;
      margin: 0 !important;
      border-bottom: 1px solid #e8e8e8;
      height: 70px !important;

      &:first-of-type {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        height: 70px !important;
        button {
          padding: 0 !important;
          width: 100% !important;
          height: 70px !important;
          font-weight: bold;
          color: #d6d6d6 !important;
          text-transform: uppercase;
          svg {
            margin-right: 24px;
          }

          &:hover {
            background: #f5f5f5;
          }
        }
        &:hover {
          background: #f5f5f5;
        }
        svg {
          width: 37.47px !important;
          height: 37.47px !important;
        }
      }

      &:last-child {
        margin: 0;
        border: none;
      }

      button {
        padding: 0 !important;
        width: 100% !important;
        display: flex !important;
        flex-direction: row !important;
        justify-content: flex-start !important;
        align-items: center !important;
        height: 70px !important;
        color: #383838 !important;
        font-size: 14px !important;

        div {
          display: flex;
          flex-direction: column;
          height: 100% !important;
          align-items: flex-start;
          justify-content: center;
          margin: 0 !important;
          span.title {
            color: #4b4b4b !important;
            font-size: 14px;
            font-weight: bold;
          }
          span.subtitle {
            color: #684e94 !important;
            font-size: 12px;

            &.disable {
              color: #c4c4c4 !important;
              font-size: 12px;
            }
          }
        }
        a {
          margin-left: auto;
          z-index: 9999;
          span {
            font-weight: bold;
            color: #684e94 !important;
            font-size: 14px !important;
          }

          &:hover {
            span {
              text-decoration: underline;
            }
          }
        }
      }

      &:hover {
        background: #fdfcff;
        &:first-of-type {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        &:last-of-type {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 768px) {
    /* start of medium tablet styles */
    &.active {
      left: -200px;
    }
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
    &.active {
      left: -200px;
    }
  }
`;

export const GroupDropDownMenuFarm = styled.ul`
  display: none;
  position: relative;
  opacity: 0;
  transition: opacity 1s linear;
  &.active {
    opacity: 1;
    display: flex;
    background: #ffffff;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 102% !important;
    top: 0 !important;
    width: 100%;
    min-width: 229px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    -moz-box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);

    li {
      width: 100% !important;
      display: flex;
      margin: 0 !important;
      border-bottom: 1px solid #c4c4c4;
      height: 50px !important;

      &:last-child {
        margin: 0;
        border: none;
      }

      button {
        padding: 0 !important;
        width: 100% !important;
        height: 100% !important;
        color: #383838 !important;
        font-size: 20px !important;

        &::before {
          content: "•";
          margin-right: 24px;
          color: #acacac;
        }
      }
      &:first-of-type {
        button {
          padding: 0 !important;
          width: 100% !important;
          height: 100% !important;
          color: #383838 !important;
          font-size: 20px !important;
          font-weight: normal;
        }
      }
    }
  }

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 768px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
  }
`;

/* ---------- SEARCH MENU ------------------ */

export const SearchMenu = styled.li`
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 768px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
  }
`;

export const SearchButton = styled.button`
  width: 35px;
  height: 35px;
  border: none;
  background: transparent;
`;

export const SearchDropDownMenu = styled.ul`
  display: none;
  opacity: 0;
  transition: opacity 1s linear;
  &.active {
    opacity: 1;
    display: flex;
    background: #f5f5f5;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 90%;
    width: 240px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    -moz-box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    z-index: 99999;
    li {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin: 0;
      border-bottom: 1px solid #e8e8e8;
      color: #38383838 !important;
      height: 50px;

      &:last-child {
        margin: 0;
        border: none;
      }
      button {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: flex-start;
        color: #4b4b4b !important;
        font-size: 14px;
        padding-left: 13px;
        padding-right: 25px;
        &:hover {
          font-weight: bold;
        }
      }
      div {
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: #c4c4c4 !important;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        padding-left: 13px;
        padding-right: 25px;
        .title {
          flex: 1;
        }
        &:hover {
          cursor: auto;
        }
      }

      &.download {
        flex-direction: column;
        padding-right: 8px;
        button {
          justify-content: space-between;
        }
      }

      &.disable {
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          z-index: 2;
          background: rgba(0, 0, 0, 0.5);
          cursor: not-allowed;

          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 768px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
    &.active {
      left: -200px;
    }
  }
`;

export const SearchDropDownMenuAnimal = styled.ul`
  display: none;
  position: relative;
  opacity: 0;
  transition: opacity 1s linear;
  &.active {
    opacity: 1;
    display: flex;
    background: #f5f5f5;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    right: 102%;
    top: 0%;
    width: 240px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    -moz-box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    z-index: 99999;
    li {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin: 0;
      border-bottom: 1px solid #e8e8e8;
      color: #38383838 !important;
      height: 50px;

      &:last-child {
        margin: 0;
        border: none;
      }
      button {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: flex-start;
        color: #4b4b4b !important;
        font-size: 14px;
        padding-left: 13px;
        padding-right: 25px;
        &:hover {
          font-weight: bold;
        }
      }
      div {
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: #c4c4c4 !important;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        padding-left: 13px;
        padding-right: 25px;
        .title {
          flex: 1;
        }
        &:hover {
          cursor: auto;
        }
      }
    }
  }

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 768px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
  }
`;

/* ---------- NOTIFICATION MENU ------------------ */

export const NotificationMenu = styled.li`
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 768px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
  }
`;

export const NotificationBadge = styled.span`
  background-color: #ff0000;
  position: absolute;
  border-radius: 100px;
  width: 24px;
  height: 24px;
  padding: 5px;
  color: white;
  font-size: 12px;
  left: 60%;
  top: 15%;

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 768px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
    display: none;
  }
`;

export const NotificationDropDownMenu = styled.ul`
  display: none;
  opacity: 0;
  transition: opacity 1s linear;
  &.active {
    opacity: 1;
    list-style: none !important;
    display: list-item;
    background: #f5f5f5;
    position: absolute;
    top: 90%;
    width: 344px;
    height: auto;
    border-radius: 5px !important;
    -webkit-box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    -moz-box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    width: 344.03px;

    z-index: 99999;

    .ant-empty {
      margin: 8px 8px !important;
    }
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 768px) {
    /* start of medium tablet styles */
    &.active {
      left: -190px;
    }
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
    &.active {
      left: -290px;
    }
  }
`;

export const NotificationContainerItem = styled.div`
  border: none;
  background: transparent;
  height: 100%;
  min-height: 78px;
  max-height: 468px;
  overflow-y: auto;
  font-family: Asap;
  font-size: 14px;
  color: #4b4b4b;
  border-bottom: 1px solid #e5e5e5;
`;

export const NotificationItem = styled.li`
  width: 100%;
  height: 100% !important;
  background: #f5f5f5;
  flex-direction: column !important;
  border-bottom: 1px solid #e5e5e5;
  padding: 12px 5px 12px 10px !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;

  font-family: Asap;
  font-size: 14px;

  &.read {
    background: #fff;
    cursor: not-allowed !important;
  }
  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:last-child {
    border-bottom: none;
  }
  &.footer {
    border: none;
    justify-content: center !important;
    align-items: center !important;
    text-transform: uppercase;
    border-top: 1px solid #e5e5e5;
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #684e94;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    a {
      color: #684e94;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 14px !important;

      &:hover {
        color: #684e94;
        text-decoration: underline;
      }
    }

    button {
      color: #684e94 !important;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 14px !important;
      background: transparent;
      border: none;

      &:hover {
        cursor: pointer;
        color: #684e94 !important;
        text-decoration: underline;
      }
    }
  }

  .ant-row-flex {
    width: 100% !important;
    &:last-child {
      margin-top: 11px;
    }
  }
  p {
    margin: 0 !important;
  }

  span.iconContainer {
    height: 20px;
    display: flex;
    align-items: center !important;
    span.icon {
      width: 20px;
      height: 100%;
      display: flex;
      align-items: center !important;
      svg {
        margin: 0 !important;
      }
    }
  }
  span.time {
    font-family: Asap;
    font-weight: normal;
    font-size: 12px;
    text-align: right;
    color: #707070;
  }

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 768px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
  }
`;

export const NotificationType = styled.span`
  height: 100%;
  display: flex;
  align-items: center !important;
  font-family: Asap;
  font-weight: bold;
  font-size: 12px;
  text-align: left;
  color: ${(props) =>
    props.notificationType === "Sale"
      ? "#E86969"
      : props.notificationType === "Purchase"
      ? "#E86969"
      : props.notificationType === "Warning"
      ? "#684E94"
      : props.notificationType === "NewObject"
      ? "#4A85AE"
      : props.notificationType === "Alert"
      ? "#FE8D2A"
      : "#4b4b4b"};
`;

/* -------------- LANGUAGE  MENU ------------------ */

export const LanguageMenu = styled.li`
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 768px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
  }
`;

export const LanguageDropDownMenu = styled.ul`
  display: none;
  opacity: 0;
  transition: opacity 1s linear;
  &.active {
    opacity: 1;
    display: flex;
    background: #f5f5f5;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 90%;
    width: 200px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    -moz-box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    z-index: 9999;

    li {
      width: 100%;
      display: flex;
      margin: 0;
      border-bottom: 1px solid #e8e8e8;
      padding: 20px 0 16px 0;

      button {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        color: #000 !important;
        font-size: 12px;
        &:hover {
          font-weight: bold;
        }

        svg {
          margin-right: 18px;
        }
      }

      &:last-child {
        margin: 0;
        border: none;
        div {
          display: flex;
          justify-content: flex-start !important;
          width: 100%;
          span {
            margin-left: 12px;
            margin-right: 47.5px;
            color: #c4c4c4;
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
          }
          svg {
            margin: 0 !important;
          }
        }
      }
    }
    /* start of desktop styles */
    @media screen and (max-width: 991px) {
      /* start of large tablet styles */
    }

    @media screen and (max-width: 768px) {
      /* start of medium tablet styles */
    }

    @media screen and (max-width: 576px) {
      /* start of phone styles */
    }
  }
`;

/* ------------ HELP MENU ------------- */

export const HelpMenu = styled.li`
  margin-left: 12px;
  a {
    width: auto !important;
    height: auto !important;
  }
  svg {
    margin: 0 !important;
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 768px) {
    /* start of medium tablet styles */
    margin-left: 30px;
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
    margin-left: 30px;
  }
`;

/* ------------ USER MENU ---------------- */

export const UserMenu = styled.li`
  margin-left: 10px;
  button.principal {
    color: #fff;
    font-size: 14px;

    .user_name {
      margin-left: 2px;
      font-weight: bold;
    }
  }

  .topUserImage {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 12px;
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 768px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
  }
`;

export const UserDropDownMenu = styled.div`
  display: none;
  opacity: 0;
  transition: opacity 1s linear;
  cursor: auto;
  &.active {
    opacity: 1;
    display: flex;
    background: #f5f5f5;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 90%;
    width: 250px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    -moz-box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    z-index: 1000;

    div.userInfo {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      & > * {
        margin-top: 10px;
      }

      div.avatar {
        position: relative;
        cursor: pointer;

        svg,
        img {
          margin-left: 0;
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }

      span {
        font-family: "Asap", sans-serif;
        color: #4b4b4b;
        font-size: 12px;
        font-weight: bold;
        &.email {
          font-weight: normal;
          margin-top: 5px;
        }
      }
    }

    div.icons {
      width: 100%;
      display: flex;
      margin-top: 10px;
      margin-bottom: 10px;
      justify-content: center;

      div.icon {
        margin: 0 5px;
        padding: 5px;
        border: 1px solid #c4c4c4;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          background: transparent;
          border: none;
          margin: 0px;
          padding: 0px;
          color: #4b4b4b;
          svg,
          i {
            margin: 0;
            width: 15px;
            height: 15px;
          }
          &:hover,
          &:active {
            color: #4b4b4b;
          }
        }
        &:hover,
        &:active {
          background: #c4c4c4;
        }
      }
    }

    ul {
      width: 100%;
      li {
        flex: 1;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        border-top: 1px solid #e8e8e8;
        height: 50px;
        padding-left: 17px;

        svg,
        i {
          margin: 0;
          margin-right: 5px;
        }
        button {
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: flex-start;
          color: #4b4b4b !important;
          font-size: 14px;
          &:hover {
            font-weight: bold;
          }
        }
      }
    }
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 768px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
  }
`;

export const HelpDropDownMenu = styled.ul`
  display: none;
  opacity: 0;
  transition: opacity 1s linear;
  &.active {
    opacity: 1;
    display: flex;
    background: #f5f5f5;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 90%;
    width: 200px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    -moz-box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    box-shadow: 0px 3px 3px rgba(119, 119, 119, 0.48);
    z-index: 99999;
    li {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin: 0;
      border-bottom: 1px solid #e8e8e8;

      color: #38383838 !important;
      height: 50px;

      &:last-child {
        margin: 0;
        border: none;
      }
      button {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: flex-start;
        color: #4b4b4b !important;
        font-size: 14px;
        padding-left: 17px;
        padding-right: 25px;
        &:hover {
          font-weight: bold;
        }
      }
    }
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 768px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
  }
`;
