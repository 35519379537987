import { call, put } from "redux-saga/effects";
import { Creators as BenchmarkActions } from "../ducks/benchmark";
/** Services */
import {
  getBenchmarkIndex,
  getBenchmarkShow,
  saveBenchmark,
  deleteBenchmarkServ
} from "../../services/benchmarkService";
import { notification } from "antd";

export function* indexBenchmark(action) {
  try {
    const {
      data: { results: allBenchmarks }
    } = yield call(getBenchmarkIndex, action.payload);
    yield put(BenchmarkActions.indexBenchmarkSuccess(allBenchmarks));
  } catch (error) {
    yield put(BenchmarkActions.indexBenchmarkError(error));
  }
}

export function* getBenchmark(action) {
  try {
    const {
      data: { results: benchmark }
    } = yield call(getBenchmarkShow, action.payload);
    benchmark.baseBenchmark = benchmark.groupId == null && benchmark.farmId == null;
    yield put(BenchmarkActions.showBenchmarkSuccess(benchmark));
  } catch (error) {
    yield put(BenchmarkActions.showBenchmarkError(error));
  }
}

export function* storeOrUpdateBenchmark(action) {
  let result = null;
  try {
    const {
      data: { results: newBenchmark }
    } = yield call(saveBenchmark, action.payload);
    result = newBenchmark;
    const { saveOptions } = action.payload;
    yield put(
      BenchmarkActions.saveBenchmarkSuccess(newBenchmark, saveOptions)
    );
    if (action.payload.id == null)
      notification.success({ message: "Benchmark criado com sucesso" });
    else notification.success({ message: "Benchmark atualizado com sucesso" });
  } catch (error) {
    notification.error({ message: "Benchmark não foi criado" });
    yield put(BenchmarkActions.saveBenchmarkError(error));
  }

  if (result != null && Object.entries(result).length > 0) {
    const newPayload = {
      ...action.payload,
      page: 1,
      size: 10,
      sorter: {}
    };

    const {
      data: { results: allBenchmarks }
    } = yield call(getBenchmarkIndex, newPayload);

    yield put(BenchmarkActions.indexBenchmarkSuccess(allBenchmarks));
  }
}

export function* deleteBenchmark(action) {
  try {
    const { data } = yield call(deleteBenchmarkServ, action.payload);
    yield put(BenchmarkActions.deleteBenchmarkSuccess(data));
    notification.success({
      message: "Benchmark inativado com sucesso"
    });
  } catch (error) {
    notification.error({ message: "Benchmark não foi inativado" });
    yield put(BenchmarkActions.deleteBenchmarkError(error));
  }
}
