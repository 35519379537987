import styled from "styled-components";
import { Modal } from "antd";

export const ModalSessionExpired = styled(Modal)`
  &.ant-modal {
    padding: 0px;
  }
  .ant-modal-body {
    padding: 29px 26px;
  }
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  strong {
    margin-left: 8px;
    font-family: Asap;
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    color: #4b4b4b;
  }
`;

export const Time = styled.span`
  font-family: Asap;
  font-weight: normal;
  font-size: 20px;
  text-align: left;
  color: #fe8d2a;
  margin-left: 60px;
`;

export const Message = styled.div`
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #4b4b4b;
`;

export const ButtonSession = styled.button`
  background: transparent;
  border: none;
  font-family: Asap;
  font-weight: normal;
  font-size: 16px;
  text-align: right;
  color: #684e94;

  &.red {
    color: #d44c4c;
  }

  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
`;
