import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Creators as AnimalActions } from "../../../../../store/ducks/animal";

import { Col, Icon, Row, Spin, Table } from "antd";
import { TabAnimalPaneContent } from "../../styles";

import ButtonStandard from "../../../../../components/utils/button";

const { Column } = Table;

function TabAnimalHandlingCommentsInfo({ tabAnimalPane }) {
  const {
    animal: {
      animalHandlingComments,
      isLoadingAnimalHandlingComment,
      isLoading,
    },
    app: {
      languageSelected,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  /** Variable props */
  const {
    params: { id: animalId },
  } = useRouteMatch();

  useEffect(() => {
    if (tabAnimalPane === 4)
      dispatch(
        AnimalActions.indexAnimalHandlingComments(groupId, farmId, animalId)
      );
  }, [animalId, dispatch, farmId, groupId, tabAnimalPane]);

  return (
    <TabAnimalPaneContent active={tabAnimalPane === 4 ? true : false}>
      <Row type="flex">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="cardBovexo">
          <Spin spinning={isLoading}>
            <Row type="flex">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <span className="span16">Observações manejos</span>
              </Col>
            </Row>
            <Row
              justify="end"
              type="flex"
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              <Col>
                <ButtonStandard
                  buttonType="secondary"
                  onClick={() =>
                    dispatch(
                      AnimalActions.indexAnimalHandlingComments(
                        groupId,
                        farmId,
                        animalId
                      )
                    )
                  }
                >
                  <Icon type="reload" /> Atualizar tabela
                </ButtonStandard>
              </Col>
            </Row>
            {animalHandlingComments !== null ? (
              <Table
                loading={isLoadingAnimalHandlingComment}
                dataSource={animalHandlingComments}
                pagination={{
                  total: animalHandlingComments?.length,
                  defaultCurrent: 1,
                  size: 10,
                }}
                size="middle"
                rowKey="id"
              >
                <Column
                  // title={translation.animal.details.history.grid.date}
                  title="Data"
                  dataIndex="executionDate"
                  key="executionDate"
                  sorter={(a, b) => a.date > b.date}
                  sortDirections={["descend", "ascend"]}
                  render={(text) =>
                    text !== null
                      ? languageSelected === "en"
                        ? moment(text).format("MM/DD/YYYY")
                        : moment(text).format("DD/MM/YYYY")
                      : ""
                  }
                />
                <Column
                  // title={translation.animal.details.history.grid.date}
                  title="Observação"
                  dataIndex="comment"
                  key="comment"
                  sorter={(a, b) => a.comment > b.comment}
                  sortDirections={["descend", "ascend"]}
                  render={(text) => (
                    <p style={{ wordBreak: "break-all" }}>{text}</p>
                  )}
                />
              </Table>
            ) : (
              <Row type="flex" justify="center" style={{ marginTop: 13 }}>
                <span className="span16Regular" style={{ textAlign: "left" }}>
                  Este animal não observações realizadas em manejos.
                </span>
              </Row>
            )}
          </Spin>
        </Col>
      </Row>
    </TabAnimalPaneContent>
  );
}

export default TabAnimalHandlingCommentsInfo;
