import styled, { keyframes } from "styled-components";
import { lighten } from "polished";

const opacityAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  background: #fff;

  display: flex;
  flex-direction: column;

  overflow-x: hidden;

  @media screen and (min-width: 1024px) {
    .logo-vertical {
      display: none;
    }
  }

  @media (max-width: 1199.98px) {
    background: #f0edf1;
    .logo-vertical {
      margin-top: 50px;
      display: flex;
      justify-content: center;
    }
  }

  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background: #fff;

  @media all and (width: 812px) and (height: 375px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
  }

  @media (max-width: 767.98px) {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #f0edf1;
  }
`;

export const DisplayContainer = styled.div`
  width: 500px;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #fff;

  padding: 0px 120px;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const DisplayBodyMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 16px;

  & > * {
    -webkit-animation: ${opacityAnimation};
    animation: ${opacityAnimation};
    animation-timing-function: ease-in;
  }

  span {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 127%;
    /* or 38px */

    color: #c4c4c4;
    animation-duration: 2s;
  }
`;

export const DisplayWelcomeMessage = styled.div`
  margin-top: 152px;

  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    -webkit-animation: ${opacityAnimation};
    animation: ${opacityAnimation};
    animation-timing-function: ease-in;
  }

  span {
    font-family: "Asap", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 127%;
    /* or 51px */

    color: #591f74;
    animation-duration: 2s;
  }
`;

export const LogoContainer = styled.div`
  text-align: center;
  svg {
    width: 136.56px !important;
    height: 24px !important;
    margin-left: 19px;
  }

  @media screen and (max-width: 1024px) {
    /* start of large tablet styles */
    width: 100% !important;
    svg {
      width: auto !important;
      height: auto !important;
      margin: 0;
      margin-top: 40px;
    }
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
    width: 100% !important;
    svg {
      width: auto !important;
      height: auto !important;
      margin: 0;
    }
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
    width: 100% !important;
    svg {
      width: auto !important;
      height: auto !important;
      margin: 0;
    }
  }
`;

export const RegisterContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  background: #f0edf1;

  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: center;
  }

  // X-Small devices (portrait phones, less than 576px)
  @media all and (max-width: 575.98px) {
  }

  // Small devices (landscape phones, less than 768px)
  @media all and (max-width: 767.98px) {
  }
`;

export const RegisterBody = styled.div`
  width: 100%;
  display: flex;
  /* border: 1px solid red; */

  padding-top: 96px;
  padding-left: 150px;
  padding-right: 200px;

  // X-Small devices (portrait phones, less than 576px)
  @media all and (max-width: 575.98px) {
    padding-top: 32px;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  // Small devices (landscape phones, less than 768px)
  @media all and (max-width: 767.98px) {
    padding-top: 32px;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  // Medium devices (tablets, less than 992px)
  @media all and (max-width: 991.98px) {
    padding-top: 32px;
    padding-left: 64px;
    padding-right: 64px;
  }
`;

export const RegisterInfoContainer = styled.div`
  margin-top: 100px;
  h1 {
    max-width: 333px;
    margin-bottom: 64px;
    margin-left: 16px;

    font-family: "Asap", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 44px;

    color: #591f74;
  }

  div.card-info {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-left: 16px;

    & + div.card-info {
      margin-top: 24px;
    }

    & > div.icon {
      width: 57px;
      height: 56px;
      margin-right: 32px;
    }

    & > p {
      margin: 0;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 157%;
      /* or 22px */
      color: #591f74;
    }
  }

  div.button-group {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 64px;

    span {
      font-family: "Asap", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      /* identical to box height */
      color: #591f74;
    }

    button {
      border: 0;
      border-radius: 10px;
      cursor: pointer;
      text-align: center;
      width: 242px;
      height: 40px;

      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;

      color: #ffffff;

      transition: background-color 0.5s ease;

      &:nth-of-type(1) {
        background-color: #94cf1c;
      }

      &:nth-of-type(2) {
        background-color: #8a50a5;
      }

      &:hover {
        &:nth-of-type(1) {
          background-color: ${lighten(0.2, "#94CF1C")};
        }

        &:nth-of-type(2) {
          background-color: ${lighten(0.2, "#8A50A5")};
        }
      }
    }
  }

  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    margin-top: 0px;
    h1 {
      margin-bottom: 16px;
      margin-left: 0px;
    }
    div.button-group {
      display: flex;
      flex-direction: column;
      margin-top: 32px;
      span {
        margin: 8px 0 !important;
      }
    }
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    margin-top: 0px;
    h1 {
      margin-bottom: 16px;
      margin-left: 0px;
    }
    div.button-group {
      display: flex;
      flex-direction: column;
      margin-top: 32px;
      span {
        margin: 8px 0 !important;
      }
    }
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    margin-top: 0px;
    h1 {
      max-width: 500px;
    }
    div.button-group {
      span {
        margin: 0 16px;
      }
    }
  }
`;

export const RegisterStepContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  ul.steps {
    margin: 0;
    li {
      cursor: pointer;

      display: inline-block;
      justify-content: center;
      align-items: center;

      width: 39px;
      height: 39px;
      border-radius: 50%;

      background: #e2ccec;

      button {
        width: 100%;
        height: 100%;

        background: transparent;
        border: none;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-align: center;

        color: #330c45;
      }

      & + li {
        margin-left: 16px;
      }

      &.active {
        background: #591f74;
        button {
          color: #fff;
        }
      }
    }
  }

  div.step-content {
    width: 100%;
    height: 100%;

    /* border: 1px solid blue; */
    margin-top: 40px;
  }

  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    ul.steps {
      align-self: center;
    }
    div.step-content {
      margin-left: 0px;
    }
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    ul.steps {
      align-self: center;
    }
    div.step-content {
      margin-left: 0px;
    }
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    ul.steps {
      align-self: center;
    }
    div.step-content {
      margin-left: 0px;
    }
  }
`;
