import { lighten } from "polished";

export const mediaWeightColor = "#ff5722";
export const dietStrategyColor = "#684E94";
export const lotBreedColor = "#35DFCE";
export const animalBreedColor = "#F53030";
export const breedColor = "#FF724E";
export const geneticColor = "#889379";

export const waterColor = lighten(0.2, "#4A85AE");
export const transitionColor = lighten(0.2, "#FE8D2A");
export const dryColor = lighten(0.2, "#D44C4C");

export function generateNewColor(exceptionsColors = []) {
  const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  const exceptionsColorsWithConstants = [
    ...exceptionsColors,
    dietStrategyColor,
    lotBreedColor,
    animalBreedColor,
    breedColor,
    geneticColor,
  ];
  if (!exceptionsColorsWithConstants.includes(randomColor)) {
    return randomColor;
  } else {
    generateNewColor(exceptionsColors);
  }
}
