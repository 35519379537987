import styled from "styled-components";
import { Drawer } from "antd";

export const Container = styled(Drawer)`
  .ant-drawer-body {
    padding: 24px 0px 10px 0px;
  }

  .ant-row-flex {
    & + .ant-row-flex {
      margin-top: 8px;
    }
  }

  .ant-row {
    label {
      font-family: "Asap", sans-serif;
      color: #9074bf;
      font-size: 14px;
      font-weight: bold;

      #bovexoInfoIcon {
        margin-left: 3px;
      }

      &.disabled {
        font-weight: bold;
        font-size: 14px;
        color: #c4c4c4;
      }
    }
  }

  .error {
    color: #d44c4c !important;
  }

  .rowInput {
    margin-top: 6px;
  }

  .tableButtonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      display: flex;
      justify-content: center !important;
      text-align: center !important;
      align-items: center !important;
      font-size: 14px !important;
      width: 77px !important;
      height: 30px !important;

      svg {
        margin-right: 3px !important;
      }
    }

    button:first-of-type {
      width: 116px !important;
      margin-right: 12px !important;
    }
  }

  .ant-switch-checked {
    background-color: #a9c133 !important;
  }

  .buttonPutToDeath {
    width: auto;
    height: 30px;
    border: none;
    padding: 0px 10px;
    border-radius: 5px;
    background: #684e94;
    box-shadow: 0px 6px 6px rgba(104, 78, 148, 0.3);

    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    color: #fff;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const Body = styled.div`
  width: 100%;
  padding: 0px 24px;
  min-height: -moz-calc(100vh - 165px);
  min-height: -webkit-calc(100vh - 165px);
  min-height: calc(100vh - 165px);

  .lotMessage {
    font-family: Asap;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
    color: #4b4b4b;
  }
`;

export const Footer = styled.div`
  border-top: 1px solid #e8e8e8;
  margin-top: 20px;
  width: 100%;
  padding: 10px 24px;
  border-radius: 0 0 4px 4px;

  .buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding-right: 24px; */

    button:first-of-type {
      margin-right: 12px !important;
    }
  }

  .ant-row-flex {
    margin: 0;
  }

  .selectDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: "Asap", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;
