// import axios from 'axios';
import apiFarms from "../config/api_farms";
import getHeaders from "./getHeaders";

export function showBenchmarkForHome(payload) {
  const {  groupId, farmId, harvestId, signal } = payload;
  
  return apiFarms.get(
    
    `/bovexo/${groupId}/farms/${farmId}/benchmarks/harvests/${harvestId}/kpis/values`,
    getHeaders(false, signal)
  );
}

export function getBenchmarkIndex(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/benchmarks`,
    getHeaders(false, signal)
  );
}

export function getBenchmarkIndexDropDown(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/benchmarks/active`,
    getHeaders(false, signal)
  );
}

export function getBenchmarkShow(payload) {
  const { groupId, farmId, id, signal } = payload;
  if (farmId != null){
    return apiFarms.get(
      `/bovexo/${groupId}/farms/${farmId}/benchmarks/${id}`,
      getHeaders(false, signal)
    );
  } else  {
    return apiFarms.get(
      `/bovexo/${groupId}/benchmarks/${id}`,
      getHeaders(false, signal)
    );
  }

}

export function saveBenchmark(payload) {
  const { groupId, farmId, id, benchmark, signal } = payload;
  if (benchmark?.farmId != null) {
    if (id != null) {
      return apiFarms.patch(
        `/bovexo/${groupId}/farms/${farmId}/benchmarks/${id}`,
        benchmark,
        getHeaders(false, signal)
      );
    } else {
      return apiFarms.post(
        `/bovexo/${groupId}/farms/${farmId}/benchmarks`,
        benchmark,
        getHeaders(false, signal)
      );
    }
  } else {
    if (id != null) {
      return apiFarms.patch(
        `/bovexo/${groupId}/benchmarks/${id}`,
        benchmark,
        getHeaders(false, signal)
      );
    } else {
      return apiFarms.post(
        `/bovexo/${groupId}/benchmarks`,
        benchmark,
        getHeaders(false, signal)
      );
    }
  }

}

export function deleteBenchmarkServ(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiFarms.delete(
    `/bovexo/${groupId}/farms/${farmId}/benchmarks/${id}`,
    getHeaders(false, signal)
  );
}
