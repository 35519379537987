import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Container, CustomDivider, KpiValuesTable } from "./styles";
import { Row, Col, Spin, notification, Dropdown, Menu } from "antd";
import ExclamationIcon from "../../../../components/utils/icons/exclamation";
import ArrowDownWhite from "../../../../components/utils/icons/arrows/green/down";
import moment from "moment";

/* Service */
import { numberMask } from "../../../../services/helpersMethodsService";

const DisplayKPIs = ({
  kpiGoalList,
  kpiHarvestList,
  onHarvestChange,
  selectedHarvest,
}) => {
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
    translation,
  } = useSelector((state) => state.app);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fattenList, setFattenList] = useState([]);
  const [createList, setCreateList] = useState([]);

  const handleHarvestChange = useCallback(
    (harvestId) => {
      onHarvestChange(harvestId);
    },
    [onHarvestChange]
  );

  const harvestMenu = useMemo(
    () => (
      <Menu style={{ width: 243 }}>
        {kpiHarvestList?.map((harvest) => (
          <Menu.Item
            key={harvest.id}
            onClick={() => handleHarvestChange(harvest.id)}
          >
            Safra{" "}
            {moment(harvest?.startDate, "YYYY-MM-DD").format("DD/MM/YYYY")} a{" "}
            {moment(harvest?.endDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
          </Menu.Item>
        ))}
      </Menu>
    ),
    [kpiHarvestList, handleHarvestChange]
  );

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        if (kpiGoalList !== null) {
          let fattenList = kpiGoalList.filter(
            (kpi) => kpi.productionSystem === "Fatten"
          );
          let createList = kpiGoalList.filter(
            (kpi) => kpi.productionSystem === "Create"
          );

          if (fattenList !== null) {
            setStartDate(fattenList[0].harvest.startDate);
            setEndDate(fattenList[0].harvest.endDate);
          }

          setFattenList(fattenList);
          setCreateList(createList);
        }
        setIsLoading(false);
      } catch (ex) {
        notification.error({ message: "Ocorreu um erro ao carregar os dados" });
      }
    }
    fetchData();
  }, [groupId, farmId, kpiGoalList]);

  return (
    <Container>
      <Spin spinning={isLoading}>
        <Row type="flex" justify="start">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Dropdown overlay={harvestMenu} value={selectedHarvest?.id}>
              <span className="title-data" onClick={(e) => e.preventDefault()}>
                Safra:{" "}
                <b>
                  {moment(startDate).format("DD/MM/YYYY")} a{" "}
                  {moment(endDate).format("DD/MM/YYYY")}
                </b>
                <ArrowDownWhite />
              </span>
            </Dropdown>
          </Col>
        </Row>
        <KpiValuesTable>
          <div className="col-title">
            <div className="row">
              <span className="title-grid">Engorda</span>
            </div>
            <div className="row">
              <span className="title-item">Semi-Intensivo</span>
            </div>
            <div className="row">
              <span className="title-item">Intensivo</span>
            </div>
            <div className="row">
              <span className="title-item">Extensivo</span>
            </div>
          </div>
          <div className="col-values">
            {/* Header  */}
            <div className="row">
              <div className="row-col">
                <span className="sub-title-grid">Lotação</span>
              </div>
              <div className="row-col">
                <span className="sub-title-grid">Produção</span>
              </div>
              <div className="row-col">
                <span className="sub-title-grid">Desfrute</span>
              </div>
              <div className="row-col">
                <span className="sub-title-grid">GDP</span>
              </div>
              <div className="row-col">
                <span className="sub-title-grid">Custo</span>
              </div>
              <div className="row-col">
                <span className="sub-title-grid">Margem</span>
              </div>
              <div className="row-col">
                <span className="sub-title-grid">Pegada Carbônica</span>
              </div>
              <div className="row-col">
                <span className="sub-title-grid">Desembolso Cab/Mês</span>
              </div>
              <div className="row-col">
                <span className="sub-title-grid">Valor do Ativo</span>
              </div>
              <div className="row-col">
                <span className="sub-title-grid">Margem Incremental</span>
              </div>
            </div>
            {/* Semi-intensive */}
            <div className="row">
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "SemiIntensive" &&
                      kpiGoal.kpi.name === "LOTACAO"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "SemiIntensive" &&
                      kpiGoal.kpi.name === "PRODUCAO"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "SemiIntensive" &&
                      kpiGoal.kpi.name === "DESFRUTE"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "SemiIntensive" &&
                      kpiGoal.kpi.name === "GDP"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "SemiIntensive" &&
                      kpiGoal.kpi.name === "CUSTO"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "SemiIntensive" &&
                      kpiGoal.kpi.name === "MARGEM"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "SemiIntensive" &&
                      kpiGoal.kpi.name === "PEGADA_CARBONICA"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "SemiIntensive" &&
                      kpiGoal.kpi.name === "DESEMBOLSO_CAB_MES"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "SemiIntensive" &&
                      kpiGoal.kpi.name === "VALOR_DO_ATIVO"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "SemiIntensive" &&
                      kpiGoal.kpi.name === "MARGEM_INCREMENTAL"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
            </div>
            {/* Intensive */}
            <div className="row">
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "Intensive" &&
                      kpiGoal.kpi.name === "LOTACAO"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " %"
                      ) : (
                        //goal?.kpi?.valueType
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "Intensive" &&
                      kpiGoal.kpi.name === "PRODUCAO"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "Intensive" &&
                      kpiGoal.kpi.name === "DESFRUTE"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "Intensive" &&
                      kpiGoal.kpi.name === "GDP"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "Intensive" &&
                      kpiGoal.kpi.name === "CUSTO"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "Intensive" &&
                      kpiGoal.kpi.name === "MARGEM"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "Intensive" &&
                      kpiGoal.kpi.name === "PEGADA_CARBONICA"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "Intensive" &&
                      kpiGoal.kpi.name === "DESEMBOLSO_CAB_MES"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "Intensive" &&
                      kpiGoal.kpi.name === "VALOR_DO_ATIVO"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "Intensive" &&
                      kpiGoal.kpi.name === "MARGEM_INCREMENTAL"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
            </div>
            {/* Extensive */}
            <div className="row">
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "Extensive" &&
                      kpiGoal.kpi.name === "LOTACAO"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "Extensive" &&
                      kpiGoal.kpi.name === "PRODUCAO"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "Extensive" &&
                      kpiGoal.kpi.name === "DESFRUTE"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "Extensive" &&
                      kpiGoal.kpi.name === "GDP"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "Extensive" &&
                      kpiGoal.kpi.name === "CUSTO"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "Extensive" &&
                      kpiGoal.kpi.name === "MARGEM"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "Extensive" &&
                      kpiGoal.kpi.name === "PEGADA_CARBONICA"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "Extensive" &&
                      kpiGoal.kpi.name === "DESEMBOLSO_CAB_MES"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "Extensive" &&
                      kpiGoal.kpi.name === "VALOR_DO_ATIVO"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {fattenList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSubSystem === "Extensive" &&
                      kpiGoal.kpi.name === "MARGEM_INCREMENTAL"
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
            </div>
          </div>
        </KpiValuesTable>
        <CustomDivider dashed />
        {/* Cria */}
        <KpiValuesTable>
          <div className="col-title">
            <div className="row">
              <span className="title-grid">Cria</span>
            </div>
            <div className="row">
              <div></div>
            </div>
          </div>
          <div className="col-values">
            {/* Header  */}
            <div className="row">
              <div className="row-col">
                <span className="sub-title-grid">
                  {translation.kpi.weanCalf}
                </span>
              </div>
              <div className="row-col">
                <span className="sub-title-grid">{translation.kpi.iep}</span>
              </div>
              <div className="row-col">
                <span className="sub-title-grid">
                  {translation.kpi.reproductiveEfficiency}
                </span>
              </div>
              <div className="row-col">
                <span className="sub-title-grid">
                  {translation.kpi.weanCalfCost}
                </span>
              </div>
              <div className="row-col">
                <span className="sub-title-grid">
                  {translation.kpi.discardedCows}
                </span>
              </div>
            </div>
            {/* Não tem sistema */}
            <div className="row">
              <div className="row-col">
                {createList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSystem === "Create" &&
                      kpiGoal.kpi.name === "Kg Bez. Desm / Kg Vaca" &&
                      kpiGoal.harvest === null
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {createList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSystem === "Create" &&
                      kpiGoal.kpi.name === "IEP" &&
                      kpiGoal.harvest === null
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>

              <div className="row-col">
                {createList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSystem === "Create" &&
                      kpiGoal.kpi.name === "EFICIENCIA REPRODUTIVA" &&
                      kpiGoal.harvest === null
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {createList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSystem === "Create" &&
                      kpiGoal.kpi.name === "CUSTO BEZERRO DESMAMADO" &&
                      kpiGoal.harvest === null
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
              <div className="row-col">
                {createList
                  .filter(
                    (kpiGoal) =>
                      kpiGoal.productionSystem === "Create" &&
                      kpiGoal.kpi.name === "VACAS DESCARTADAS" &&
                      kpiGoal.harvest === null
                  )
                  .map((goal) => (
                    <span key={goal.id} className="sub-title-item">
                      {goal?.goalValue != null ? (
                        numberMask(goal?.goalValue || 0, goal?.kpi.monetary) +
                        " " +
                        goal?.kpi?.valueType
                      ) : (
                        <ExclamationIcon />
                      )}
                    </span>
                  ))}
              </div>
            </div>
          </div>
        </KpiValuesTable>
      </Spin>
    </Container>
  );
};

export default DisplayKPIs;
