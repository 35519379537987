import React, { useEffect } from "react";
import moment from "moment";
import useRegister from "../../hooks/useRegister";
import { withRouter } from "react-router-dom";

/* Styles */
import {
  Wrapper,
  Container,
  DisplayContainer,
  DisplayBodyMessage,
  RegisterContainer,
  RegisterBody,
  LogoContainer,
  DisplayWelcomeMessage,
  RegisterInfoContainer,
  RegisterStepContainer,
} from "./styles";

/* Components */

/* Images */
import LogoVertical from "../../components/login/logos/logoVertical";

import RegisterSearchIcon from "../../components/utils/icons/register/search";
import RegisterHandIcon from "../../components/utils/icons/register/hand";
import RegisterContractIcon from "../../components/utils/icons/register/contract";
// Steps
import RegisterStep1 from "./steps/step-1";
import RegisterStep2 from "./steps/step-2";
import RegisterStep3 from "./steps/step-3";
import RegisterStep4 from "./steps/step-4";
import RegisterStep5 from "./steps/step-5";
import { Modal, Spin } from "antd";

const Register = () => {
  const { mode, setMode, activeStep, setActiveStep, setForm, isLoading } =
    useRegister();
  /** Props variables */

  /** Methods */

  useEffect(() => {
    return () => {
      setForm({
        email: "",
        birthday: moment().format("YYYY-MM-DD"),
        password: null,
        fullname: "",
        document: "",
        phone: "",
        term: false,
        zip_code: "",
        address: "",
        address_number: "",
        neighborhood: "",
        country: null,
        state: null,
        city: null,
        emailVerified: false,
        isEmailValid: false,
        plan: {
          planSelected: null,
          amountAnimals: null,
        },
        due_day: null,
        card: {
          token: "",
        },
      });
      setMode(null);
      setActiveStep(1);
    };
  }, [setActiveStep, setForm, setMode]);

  function handleChangeMode(mode) {
    if (mode === "Demo") {
      Modal.info({
        title: "Informativo",
        centered: true,
        content: (
          <span>
            O sistema de teste está em processo de atualização. <br /> <br />
            Para acessar, entre em contato com o Departamento Comercial, através
            do e-mail <strong>atendimento@bovexo.com</strong>.
          </span>
        ),
      });
    } else {
      Modal.info({
        title: "Informativo",
        centered: true,
        content: (
          <span>
            O sistema de contratação está em processo de atualização. <br />{" "}
            <br />
            Para acessar, entre em contato com o Departamento Comercial, através
            do e-mail <strong>atendimento@bovexo.com</strong>.
          </span>
        ),
      });
    }
  }

  function handleShowStepContent() {
    switch (activeStep) {
      case 1:
        return <RegisterStep1 />;
      case 2:
        return <RegisterStep2 />;
      case 3:
        return <RegisterStep3 />;
      case 4:
        return <RegisterStep4 />;
      case 5:
        return <RegisterStep5 />;
      default:
        return null;
    }
  }

  return (
    <Wrapper>
      <span className="logo-vertical">
        <LogoVertical />
      </span>
      <Container>
        <DisplayContainer>
          <LogoContainer>
            <LogoVertical />
          </LogoContainer>
          <DisplayBodyMessage>
            <span>
              Decisões que <br />
              engordam <strong>o bolso</strong>
            </span>
          </DisplayBodyMessage>
          {mode !== null && (
            <DisplayWelcomeMessage>
              <span>
                Olá, seja <br />
                bem vindo!
              </span>
            </DisplayWelcomeMessage>
          )}
        </DisplayContainer>
        <RegisterContainer>
          <Spin spinning={isLoading}>
            <RegisterBody>
              {mode === null ? (
                <RegisterInfoContainer>
                  <h1>Quer ter acesso a uma PECUÁRIA MAIS LUCRATIVA?</h1>
                  <div className="card-info">
                    <div className="icon">
                      <RegisterSearchIcon />
                    </div>
                    <p>
                      Experimente e tenha acesso pleno a todas as
                      funcionalidades do sistema. Um ambiente demo,
                      pré-configurado, por +15 dias !
                    </p>
                  </div>
                  <div className="card-info">
                    <div className="icon">
                      <RegisterHandIcon />
                    </div>
                    <p>
                      Custa menos de 2,5% para descobrir o potencial valor
                      agregado, por BovExo, à sua fazenda. Ficou curioso? Fala
                      conosco no chat.
                    </p>
                  </div>
                  <div className="card-info">
                    <div className="icon">
                      <RegisterContractIcon />
                    </div>

                    <p>
                      Se interessou? Analisa nossos planos nas telas seguintes.
                    </p>
                  </div>
                  <div className="button-group">
                    <button
                      type="button"
                      onClick={() => handleChangeMode("Contract")}
                    >
                      Contrate Agora!
                    </button>
                    <span>ou</span>
                    <button
                      type="button"
                      onClick={() => handleChangeMode("Demo")}
                    >
                      Experimente
                    </button>
                  </div>
                </RegisterInfoContainer>
              ) : (
                <RegisterStepContainer>
                  <ul className="steps">
                    <li className={activeStep === 1 ? "active" : ""}>
                      <button type="button">1</button>
                    </li>
                    <li className={activeStep === 2 ? "active" : ""}>
                      <button type="button">2</button>
                    </li>
                    <li className={activeStep === 3 ? "active" : ""}>
                      <button type="button">3</button>
                    </li>
                    {mode === "Contract" && (
                      <>
                        <li className={activeStep === 4 ? "active" : ""}>
                          <button type="button">4</button>
                        </li>
                        <li className={activeStep === 5 ? "active" : ""}>
                          <button type="button">5</button>
                        </li>
                      </>
                    )}
                  </ul>
                  <div className="step-content">{handleShowStepContent()}</div>
                </RegisterStepContainer>
              )}
            </RegisterBody>
          </Spin>
        </RegisterContainer>
      </Container>
    </Wrapper>
  );
};

export default withRouter(Register);
