import React from "react";

const AlertIcon = () => (
  <svg
    id="bovexoAlertIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="15.397"
    height="14.51"
    viewBox="0 0 15.397 14.51"
  >
    <g id="alert" transform="translate(0 -14.759)">
      <g id="Grupo_4124" data-name="Grupo 4124" transform="translate(0 14.759)">
        <path
          id="Caminho_2082"
          data-name="Caminho 2082"
          d="M15.078,25.862l-5.439-10a2.256,2.256,0,0,0-3.879,0l-5.439,10a2.254,2.254,0,0,0,1.939,3.407H13.138A2.255,2.255,0,0,0,15.078,25.862ZM7.7,27.464a.9.9,0,1,1,.9-.9A.9.9,0,0,1,7.7,27.464Zm.9-3.609a.9.9,0,0,1-1.8,0V19.344a.9.9,0,1,1,1.8,0Z"
          transform="translate(0 -14.759)"
          fill="#d44c4c"
        />
      </g>
    </g>
  </svg>
);
export default AlertIcon;
