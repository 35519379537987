import React, { useRef, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as BreedReproductionScoreActions } from "../../../store/ducks/breedReproductionScore";
import * as Yup from "yup";
import { Formik } from "formik";

import { Col, Input, notification, Row, Select, Spin } from "antd";
import NumberFormat from "react-number-format";
import ButtonStandard from "../../utils/button";

import { Container, Footer } from "./styles";

// Services
import { getBreedIndexActive } from "../../../services/breedService";

function DrawerBreedReproductionScore() {
  const formikRef = useRef();
  const [breeds, setBreeds] = useState([]);
  const [form, setForm] = useState({
    age: "",
    breedId: null,
    startWeight: 310,
    endWeight: 410,
    weights: [
      {
        weight: 310,
        score: null,
      },
      {
        weight: 320,
        score: null,
      },
      {
        weight: 330,
        score: null,
      },
      {
        weight: 340,
        score: null,
      },
      {
        weight: 350,
        score: null,
      },
      {
        weight: 360,
        score: null,
      },
      {
        weight: 370,
        score: null,
      },
      {
        weight: 380,
        score: null,
      },
      {
        weight: 390,
        score: null,
      },
      {
        weight: 400,
        score: null,
      },
      {
        weight: 410,
        score: null,
      },
    ],
  });

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const {
    isDrawerVisible,
    isLoadingRequestSave: isLoadingRequest,
    cleanForm,
  } = useSelector((state) => state.breedReproductionScore);

  const validationSchema = Yup.object().shape({
    breedId: Yup.string().required(),
    age: Yup.number().required().min(1),
    startWeight: Yup.number().required().min(0),
    endWeight: Yup.number().required().max(1000),
    weights: Yup.array()
      .of(
        Yup.object().shape({
          weight: Yup.number().required(),
          score: Yup.number().required(),
        })
      )
      .compact((w) => w.score != null)
      .required(),
  });

  const dispatch = useDispatch();

  const renderWeights = useCallback(
    (values, setFieldValue) => {
      return values.weights.map(({ weight, score }, index) => (
        <Row key={`weight_${index}`} type="flex" justify="start" gutter={8}>
          <Col span={12}>
            <strong>{`${weight} Kg`}</strong>
          </Col>
          <Col span={12}>
            <Select
              value={score || undefined}
              placeholder={translation.defaultSelectPlaceholder}
              onChange={(value) =>
                setFieldValue(`weights[${index}].score`, value)
              }
            >
              <Select.Option key={1} value={1}>
                1
              </Select.Option>
              <Select.Option key={2} value={2}>
                2
              </Select.Option>
              <Select.Option key={3} value={3}>
                3
              </Select.Option>
              <Select.Option key={4} value={4}>
                4
              </Select.Option>
              <Select.Option key={5} value={5}>
                5
              </Select.Option>
              <Select.Option key={6} value={6}>
                6
              </Select.Option>
              <Select.Option key={7} value={7}>
                7
              </Select.Option>
              <Select.Option key={8} value={8}>
                8
              </Select.Option>
              <Select.Option key={9} value={9}>
                9
              </Select.Option>
            </Select>
          </Col>
        </Row>
      ));
    },
    [translation.defaultSelectPlaceholder]
  );

  function closeDrawer(reset = false) {
    if (reset === true) {
      setForm({
        age: "",
        breedId: null,
        startWeight: 310,
        endWeight: 410,
        weights: [
          {
            weight: 310,
            score: null,
          },
          {
            weight: 320,
            score: null,
          },
          {
            weight: 330,
            score: null,
          },
          {
            weight: 340,
            score: null,
          },
          {
            weight: 350,
            score: null,
          },
          {
            weight: 360,
            score: null,
          },
          {
            weight: 370,
            score: null,
          },
          {
            weight: 380,
            score: null,
          },
          {
            weight: 390,
            score: null,
          },
          {
            weight: 400,
            score: null,
          },
          {
            weight: 410,
            score: null,
          },
        ],
      });
      formikRef.current.resetForm({
        age: "",
        breedId: null,
        startWeight: 310,
        endWeight: 410,
        weights: [
          {
            weight: 310,
            score: null,
          },
          {
            weight: 320,
            score: null,
          },
          {
            weight: 330,
            score: null,
          },
          {
            weight: 340,
            score: null,
          },
          {
            weight: 350,
            score: null,
          },
          {
            weight: 360,
            score: null,
          },
          {
            weight: 370,
            score: null,
          },
          {
            weight: 380,
            score: null,
          },
          {
            weight: 390,
            score: null,
          },
          {
            weight: 400,
            score: null,
          },
          {
            weight: 410,
            score: null,
          },
        ],
      });
    }
    dispatch(BreedReproductionScoreActions.showOrHideDrawer());
  }

  function generateWeights(values, setFieldValue) {
    const startWeight = values.startWeight;
    const endWeight = values.endWeight;
    const increment = 10;

    let newWeights = [];

    if (endWeight < startWeight) {
      notification.error({
        message:
          'O valor do campo "Peso final" deve ser maior que o valor de "Peso inicial".',
      });
    } else {
      for (let i = startWeight; i <= endWeight; i = i + increment) {
        newWeights.push({
          weight: i,
          score: null,
        });
      }

      setFieldValue("weights", newWeights);

      renderWeights(values, setFieldValue);
    }
  }

  async function getBreeds() {
    try {
      const {
        data: { results },
      } = await getBreedIndexActive({
        groupId,
        farmId,
        withoutBreedCurveParam: true,
      });
      setBreeds(results);
    } catch (error) {}
  }

  function handleSubmitModalForm(values, actions) {
    const { startWeight, endWeight, ...body } = values;
    try {
      if (body.weights.filter((w) => w.score == null).length > 0) {
        notification.error({
          message:
            'Existem alguns pesos sem "Score". Preencha-los para salvar o formulário.',
        });
      } else {
        dispatch(BreedReproductionScoreActions.save(groupId, farmId, body));
      }
    } catch (error) {}
  }

  function handleDrawerVisible(visible) {
    if (visible) {
      getBreeds();
    }
  }

  // Effects
  useEffect(() => {
    if (cleanForm === true) {
      setForm({
        age: "",
        breedId: null,
        startWeight: 310,
        endWeight: 410,
        weights: [
          {
            weight: 310,
            score: null,
          },
          {
            weight: 320,
            score: null,
          },
          {
            weight: 330,
            score: null,
          },
          {
            weight: 340,
            score: null,
          },
          {
            weight: 350,
            score: null,
          },
          {
            weight: 360,
            score: null,
          },
          {
            weight: 370,
            score: null,
          },
          {
            weight: 380,
            score: null,
          },
          {
            weight: 390,
            score: null,
          },
          {
            weight: 400,
            score: null,
          },
          {
            weight: 410,
            score: null,
          },
        ],
      });
      formikRef.current.resetForm({
        age: "",
        breedId: null,
        startWeight: 310,
        endWeight: 410,
        weights: [
          {
            weight: 310,
            score: null,
          },
          {
            weight: 320,
            score: null,
          },
          {
            weight: 330,
            score: null,
          },
          {
            weight: 340,
            score: null,
          },
          {
            weight: 350,
            score: null,
          },
          {
            weight: 360,
            score: null,
          },
          {
            weight: 370,
            score: null,
          },
          {
            weight: 380,
            score: null,
          },
          {
            weight: 390,
            score: null,
          },
          {
            weight: 400,
            score: null,
          },
          {
            weight: 410,
            score: null,
          },
        ],
      });
      dispatch(BreedReproductionScoreActions.setFalseCleanForm());
    }
  }, [cleanForm, dispatch]);

  return (
    <Container
      title={translation.reproductionParameters.breedScore.form.title}
      width={500}
      onClose={closeDrawer}
      maskClosable={false}
      visible={isDrawerVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Spin spinning={isLoadingRequest}>
        <Formik
          ref={formikRef}
          enableReinitialize={true}
          initialValues={form}
          initialErrors={{}}
          onSubmit={handleSubmitModalForm}
          validationSchema={validationSchema}
          render={({
            handleSubmit,
            errors,
            values,
            submitCount,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="drawerForm">
                {Object.entries(errors).length > 0 && submitCount > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                <Row type="flex" justify="start" gutter={8}>
                  <Col span={12}>
                    <Row>
                      <label
                        htmlFor="breedId"
                        className={
                          errors.breedId && submitCount > 0 ? "error" : ""
                        }
                      >
                        {
                          translation.reproductionParameters.breedScore.form
                            .breedId
                        }
                      </label>
                    </Row>
                    <Row>
                      <Select
                        style={{ width: "100%" }}
                        name="breedId"
                        placeholder={translation.defaultSelectPlaceholder}
                        value={
                          breeds != null && values?.breedId != null
                            ? values?.breedId
                            : undefined
                        }
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          setFieldValue("breedId", value);
                        }}
                      >
                        {breeds != null &&
                          Object.entries(breeds).length > 0 &&
                          breeds.map((breed) => (
                            <Select.Option key={breed.id} value={breed.id}>
                              {breed.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row>
                      <label
                        htmlFor="age"
                        className={errors.age && submitCount > 0 ? "error" : ""}
                      >
                        {translation.reproductionParameters.breedScore.form.age}
                      </label>
                    </Row>
                    <Row>
                      <Input
                        name="age"
                        value={values.age}
                        placeholder={translation.defaultPlaceholder}
                        type="number"
                        min={1}
                        addonAfter="meses"
                        onChange={(e) => setFieldValue("age", e.target.value)}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row type="flex" justify="start" align="middle" gutter={8}>
                  <Col span={6}>
                    <Row>
                      <label
                        htmlFor="startWeight"
                        className={
                          errors.startWeight && submitCount > 0 ? "error" : ""
                        }
                      >
                        {
                          translation.reproductionParameters.breedScore.form
                            .startWeight
                        }
                      </label>
                    </Row>
                    <Row>
                      <NumberFormat
                        name="startWeight"
                        value={values.startWeight}
                        placeholder={translation.defaultPlaceholder}
                        customInput={Input}
                        decimalScale={0}
                        allowNegative={false}
                        onValueChange={({ floatValue }) =>
                          setFieldValue(
                            "startWeight",
                            floatValue != null
                              ? floatValue > 1000
                                ? 1000
                                : floatValue
                              : null
                          )
                        }
                        addonAfter="Kg"
                      />
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <label
                        htmlFor="endWeight"
                        className={
                          errors.endWeight && submitCount > 0 ? "error" : ""
                        }
                      >
                        {
                          translation.reproductionParameters.breedScore.form
                            .endWeight
                        }
                      </label>
                    </Row>
                    <Row>
                      <NumberFormat
                        name="endWeight"
                        value={values.endWeight}
                        placeholder={translation.defaultPlaceholder}
                        customInput={Input}
                        decimalScale={0}
                        allowNegative={false}
                        onValueChange={({ floatValue }) =>
                          setFieldValue(
                            "endWeight",
                            floatValue != null
                              ? floatValue > 1000
                                ? 1000
                                : floatValue
                              : null
                          )
                        }
                        addonAfter="Kg"
                      />
                    </Row>
                  </Col>
                  <Col span={6} className="colButtonGenerate">
                    <ButtonStandard
                      type="button"
                      buttonType={"alternative"}
                      onClick={() => generateWeights(values, setFieldValue)}
                    >
                      {translation.buttons.generate}
                    </ButtonStandard>
                  </Col>
                </Row>
                <Row type="flex" justify="start" gutter={8}>
                  <Col span={12}>
                    <label
                      className={
                        errors.weights && submitCount > 0 ? "error" : ""
                      }
                    >
                      {
                        translation.reproductionParameters.breedScore.form
                          .weight
                      }
                    </label>
                  </Col>
                  <Col span={12}>
                    <label
                      className={
                        errors.weights && submitCount > 0 ? "error" : ""
                      }
                    >
                      {translation.reproductionParameters.breedScore.form.score}
                    </label>
                  </Col>
                </Row>
                {errors.weights && submitCount > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {
                        translation.reproductionParameters.breedScore.form
                          .errors.errorWithFieldWeights
                      }
                    </label>
                  </Row>
                )}
                <div className="weightsContainer">
                  {renderWeights(values, setFieldValue)}
                </div>
              </div>
              {/* Footer */}
              <Footer>
                <Row type="flex" justify="space-between">
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="selectDiv"
                  ></Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="buttonsDiv"
                  >
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={() => closeDrawer(true)}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard buttonType="type6" type="submit">
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
      </Spin>
    </Container>
  );
}

export default DrawerBreedReproductionScore;
