import React from "react";
import {
  GeneralFinancialParametersContextProvider,
  useGeneralFinancialParametersContext,
} from "./context";

import { Col, Divider, Radio, Row, Spin, Switch } from "antd";
import InfoTooltip from "../../../../components/utils/infoTooltip";
import RadioButton from "../../../../components/utils/radioButton";
import { CardCustom, Title } from "../../styles";
import FarmConvertions from "./farmConvertions";

// import { Container } from './styles';

function GeneralFinancialParametersLayout() {
  const {
    translation,
    isLoading,
    parameter4001Value,
    handleChangeParameter4001Value,
    parameter4002Value,
    handleChangeParameter4002Value,
  } = useGeneralFinancialParametersContext();

  return (
    <CardCustom>
      <Spin spinning={isLoading}>
        <Row type="flex" justify="space-between" className="rowHeader">
          <Col>
            <Title>
              {
                translation.financial.parameters.generalFinancialParameters
                  .title
              }
            </Title>
          </Col>
        </Row>
        <Row type="flex" align="middle" gutter={16}>
          {/* Parameter 4002 */}
          <Col>
            <Row type="flex" justify="center" align="middle" gutter={16}>
              <Col>
                <label htmlFor="parameter4002Value">
                  {
                    translation.financial.parameters.generalFinancialParameters
                      .parameter4002
                  }
                  {": "}
                  <InfoTooltip
                    title={
                      translation.financial.parameters
                        .generalFinancialParameters.parameter4002Help
                    }
                  />
                </label>
              </Col>
              <Col>
                <Radio.Group
                  value={parameter4002Value && parameter4002Value?.value}
                  onChange={(e) => {
                    handleChangeParameter4002Value(e.target.value);
                  }}
                >
                  <RadioButton value={"DietStrategy"}>
                    Estratégia de Dieta
                  </RadioButton>
                  <RadioButton value={"SupplementSupply"}>
                    Fornecimento
                  </RadioButton>
                </Radio.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row type="flex" align="middle" gutter={16}>
          {/* Parameter 4001 */}
          <Col>
            <Row type="flex" align="middle" gutter={16}>
              <Col>
                <label htmlFor="parameter4001Value">
                  {
                    translation.financial.parameters.generalFinancialParameters
                      .parameter4001
                  }
                </label>
              </Col>
              <Col>
                <Switch
                  id="parameter4001Value"
                  checked={
                    parameter4001Value && parameter4001Value?.value === "true"
                      ? true
                      : false
                  }
                  onChange={handleChangeParameter4001Value}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <FarmConvertions />
      </Spin>
    </CardCustom>
  );
}

function GeneralFinancialParameters() {
  return (
    <GeneralFinancialParametersContextProvider>
      <GeneralFinancialParametersLayout />
    </GeneralFinancialParametersContextProvider>
  );
}

export default GeneralFinancialParameters;
