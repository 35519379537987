import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as ReproductionPeriodActions } from "../../../store/ducks/reproductionPeriod";
import moment from "moment";

// Components
import { Container, Footer } from "./styles";
import { Row, Col, DatePicker, Input, notification, Spin } from "antd";
import ButtonStandard from "../../utils/button";

// Services
import { reproductionPeriodExists, save, findById, update } from "../../../services/reproductionPeriodService";

const DrawerReproductionPeriod = () => {

	const [form, setForm] = useState({
		description: null,
		startDate: null,
		endDate: null,
	});

	const [errors, setErrors] = useState([]);
	const [loadRequest, setLoadRequest] = useState(false);
	// Redux Variable
	const {
		translation,
		groupSelected: { id: groupId },
		farmSelected: { id: farmId },
	} = useSelector((state) => state.app);

	const { reproductionPeriodId, isDrawerVisible, pagination } = useSelector((state) => state.reproductionPeriod);

	const dispatch = useDispatch();
	const dateFormat = "DD/MM/YYYY"

	// Methods
	function resetDrawer() {
		setErrors([]);
		setForm({
			description: null,
			startDate: null,
			endDate: null,
		});
	}

	function closeDrawer() {
		resetDrawer();
		dispatch(ReproductionPeriodActions.showOrHideDrawer());
	}

	async function validateDates(startDate, endDate) {
		if (startDate !== null && endDate !== null) {
			try {
				await reproductionPeriodExists({groupId, farmId, id: reproductionPeriodId, startDate, endDate});
				return true;
			} catch (e) {
				notification.error({ message: "Período já utilizado em outra estação de monta." });
				return false;
			}
		}
	}

	async function handleSubmitForm() {
		if (validateForm()) {
			// Prepare request body
			let startDate = form.startDate.format("yyyy-MM-DD");
			let endDate = form.endDate.format("yyyy-MM-DD");
			const body = { ...form, startDate, endDate };

			setLoadRequest(true);
			if(await validateDates(startDate, endDate)) {

				//Save or Update
				try {
					if(reproductionPeriodId !== null) {
						await update({
							groupId,
							farmId,
							id: reproductionPeriodId,
							body,
						});
	
						notification.success({
							message: "Estação de monta atualizada com sucesso",
						});
					} else {
						await save({
							groupId,
							farmId,
							body,
						});
	
						notification.success({
							message: "Estação de monta cadastrada com sucesso",
						});
					}
					
					dispatch(ReproductionPeriodActions.getAllData(groupId, farmId, pagination.page, pagination.sorter, pagination.filters, pagination.size));
					setLoadRequest(false);
					closeDrawer();
				} catch (error) {
					notification.error({
						message: "Houve um erro ao cadastrar a estação de monta",
					});
					setLoadRequest(false);
				}
			} else {
				setLoadRequest(false);
			}
		}
	}

	function validateForm() {
		let errors = [];

		if (form?.description === null || form.description.trim() === "") {
			errors.push("description");
		}

		if (form?.startDate === null) {
			errors.push("startDate");
		}

		if (form?.endDate === null) {
			errors.push("endDate");
		}

		if (form?.startDate !== null && form?.endDate !== null && form.endDate.isBefore(form.startDate)) {
			errors.push("startDate");
			errors.push("endDate");
			notification.error({ message: "Data Final não pode ser anterior à Data Inicial." });
		}

		setErrors(errors);
		return errors.length > 0 ? false : true;
	}

	async function handleDrawerVisible(visible) {
		if (visible) {
			if(reproductionPeriodId !== null){
				setLoadRequest(true);
				try {
					const { data: { results: reproductionPeriodData }} = await findById({groupId, farmId, id: reproductionPeriodId});
					if(reproductionPeriodData !== null){
						setForm({
							description: reproductionPeriodData?.description,
							startDate: moment(reproductionPeriodData?.startDate, "YYYY-MM-DD"),
							endDate: moment(reproductionPeriodData?.endDate, "YYYY-MM-DD"),
						});
					}
					setLoadRequest(false);
				} catch (error) {
					setLoadRequest(false);
				}
			}
		}
	}

	return (
		<Container
			title={reproductionPeriodId === null ? "Nova Estação de Monta" : "Editar Estação de Monta"}
			width={700}
			onClose={closeDrawer}
			maskClosable={false}
			visible={isDrawerVisible}
			afterVisibleChange={handleDrawerVisible}
		>
			<div className="drawerForm">
				<Spin spinning={loadRequest}>
					{reproductionPeriodId === null && (
					<Row type="flex">
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<span className="subTitle">
								Preencha os campos abaixo para cadastrar uma nova estação de monta
							</span>
						</Col>
					</Row>)}
					{errors.length > 0 && (
						<Row type="flex" justify="center" align="middle">
							<label className="error">{translation.error.formError}</label>
						</Row>
					)}
					<Row type="flex" className="rowLabel">
						<label className={errors.includes("description") ? "error" : ""}>
							Descrição*
						</label>
					</Row>
					{/* Description */}
					<Row type="flex">
						<Input
							name="description"
							value={form?.description !== null ? form.description : null}
							type="description"
							placeholder={translation.defaultPlaceholder}
							autoCapitalize="off"
							onChange={(e) => {
								setForm({ ...form, description: e.target.value });
							}}
						/>
					</Row>

					{/* Start and End Dates */}
					<Row type="flex">
						<Col span={12}>
							<Row className="rowLabel">
								<label className={errors.includes("startDate") ? "error" : ""}>
									Data Inicial*
								</label>
							</Row>
							<Row>
								<DatePicker
									name="startDate"
									allowClear={false}
									style={{ width: "100%" }}
									value={form?.startDate !== null ? moment(form.startDate, "YYYY-MM-DD") : null}
									placeholder={translation.defaultDatePickerPlaceholder}
									format={dateFormat}
									onChange={(date) => {
										setForm({ ...form, startDate: date });
									}}
								/>
							</Row>
						</Col>

						<Col span={11} offset={1}>
							<Row className="rowLabel">
								<label className={errors.includes("endDate") ? "error" : ""}>
									Data Final*
								</label>
							</Row>
							<Row>
								<DatePicker
									name="startDate"
									allowClear={false}
									style={{ width: "100%" }}
									value={form?.endDate !== null ? moment(form.endDate, "YYYY-MM-DD") : null}
									placeholder={translation.defaultDatePickerPlaceholder}
									format={dateFormat}
									disabledDate={(current) => current.isBefore(form?.startDate)}
									onChange={(date) => {
										setForm({ ...form, endDate: date });
									}}
								/>
							</Row>
						</Col>
					</Row>

				</Spin>
			</div>
			<Footer>
				<Row type="flex">
					<Col span={24} className="buttonsDiv">
						<ButtonStandard
							type="button"
							buttonType="type7"
							onClick={closeDrawer}
						>
							{translation.buttons.cancel}
						</ButtonStandard>

						<ButtonStandard type="button" buttonType="type6" onClick={handleSubmitForm}>
							{translation.buttons.save}
						</ButtonStandard>
					</Col>
				</Row>
			</Footer>
		</Container>
	);
};

export default DrawerReproductionPeriod;
