import apiFinancial from "../config/api_financial";
import apiMiddleware from "../config/api_middleware"
import getHeaders from "./getHeaders";

export function getPaymentInfo(payload) {
    const { groupId, farmId, userId } = payload;

    return apiFinancial.get(
        `/bovexo/${groupId}/farms/${farmId}/user/${userId}/payments`,
        getHeaders()
    );
}

export function updateDueDay(payload){
    const { groupId, farmId, userId, dueDay } = payload;

    return apiFinancial.put(
        `/bovexo/${groupId}/farms/${farmId}/user/${userId}/payments/due`, dueDay,
        getHeaders()
    );
}

export function updatePaymentMethod(payload){
    const { groupId, farmId, userId, data } = payload;
    return apiFinancial.put(`bovexo/${groupId}/farms/${farmId}/user/${userId}/payments/payment_method`, data, getHeaders()
    );
}

export function getLastNumberCreditCard(payload){
    const { groupId, farmId, customerId } = payload;
    return apiMiddleware.get(`bovexo/${groupId}/farms/${farmId}/customer/${customerId}/credit_card/info`, getHeaders()
    );
}