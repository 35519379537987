import apiFarms from "../config/api_farms";
import getHeaders from "./getHeaders";

export function getFarmIndex(payload) {
  const { groupId, page, sorter, filters, size, signal } = payload;

  const { field, order } = sorter;

  const orderS = order === "descend" ? "DESC" : "ASC";

  return apiFarms.get(
    `/bovexo/${groupId}/farms?
    ${page ? `&page=${page - 1}` : ""}
    ${size ? `&size=${size}` : ""}
    ${
      Object.entries(sorter).length > 0
        ? `&sort=${field}&direction=${orderS}`
        : ""
    }
    ${filters !== "" ? `&search=${filters}` : ""}&withoutPagination=false`,
    getHeaders(false, signal)
  );
}

export function getFarmListDropDown(payload) {
  const { groupId } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms?withoutPagination=true`,
    getHeaders(false, null)
  );
}

export function getFarmShow(payload) {
  const { groupId, id, signal } = payload;
  return apiFarms.get(
    `/bovexo/${groupId}/farms/${id}`,
    getHeaders(false, signal)
  );
}

export function saveFarm(payload) {
  const { groupId, id, farm, signal } = payload;
  const { logoFile, ...restFarmObj } = farm;
  const formData = new FormData();
  formData.append("logoFile", logoFile);
  formData.append("farm", JSON.stringify(restFarmObj));
  if (id != null) {
    return apiFarms.patch(
      `/bovexo/${groupId}/farms/${id}`,
      formData,
      getHeaders(true, signal)
    );
  } else {
    return apiFarms.post(
      `/bovexo/${groupId}/farms`,
      formData,
      getHeaders(true, signal)
    );
  }
}

export function saveWeatherSeasons(value) {
  return apiFarms.post(
    `/bovexo/weatherSeasons`,
    value,
    getHeaders(false, null)
  );
}

export function inactivateFarmByGroupIdAndId(payload) {
  const { groupId, id } = payload;
  return apiFarms.delete(
    `/bovexo/${groupId}/farms/${id}/inactivate`,
    getHeaders(false, null)
  );
}
export function reactivateFarmByGroupIdAndId(payload) {
  const { groupId, id } = payload;
  return apiFarms.put(
    `/bovexo/${groupId}/farms/${id}/reactivate`,
    null,
    getHeaders(false, null)
  );
}
