import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding-top: 13px;
  padding-bottom: 24px;
  .ant-row,
  .ant-row-flex {
    width: 100%;
  }

  .ant-card {
    margin-top: 16px;
  }
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;

export const LogTextContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;

  color: #4b4b4b;
  font-size: 14px;
  font-family: "Asap", sans-serif;
`;
