import React from 'react';

// import { Container } from './styles';

const HomeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.627"
    height="22.213"
    viewBox="0 0 23.627 22.213"
  >
    <g id="house-outline" transform="translate(0 -18.3)">
      <g id="Grupo_486" data-name="Grupo 486" transform="translate(0 18.3)">
        <path
          id="Caminho_904"
          data-name="Caminho 904"
          d="M100.115,300.251a.671.671,0,0,0-.681.681v9.308H94.605v-3.8a.673.673,0,0,0-.656-.668H90.458a.689.689,0,0,0-.693.668v3.8H84.948v-9a.669.669,0,0,0-1.337,0v9.68a.662.662,0,0,0,.656.658h6.191a.662.662,0,0,0,.656-.658v-3.8h2.154v3.8a.667.667,0,0,0,.681.658h6.166a.662.662,0,0,0,.656-.658v-9.989A.665.665,0,0,0,100.115,300.251Z"
          transform="translate(-80.383 -289.366)"
          fill="#4b4b4b"
        />
        <path
          id="Caminho_905"
          data-name="Caminho 905"
          d="M23.434,29.716l-5.373-5.4V20.64a.681.681,0,0,0-.693-.679.665.665,0,0,0-.656.679v2.33l-4.432-4.459a.651.651,0,0,0-.458-.211.673.673,0,0,0-.483.211L.207,29.642a.638.638,0,0,0,0,.94.638.638,0,0,0,.941,0L11.82,19.935,22.468,30.682a.658.658,0,0,0,.483.174.748.748,0,0,0,.483-.174A.7.7,0,0,0,23.434,29.716Z"
          transform="translate(0 -18.3)"
          fill="#4b4b4b"
        />
      </g>
    </g>
  </svg>
);
export default HomeIcon;
