import React from "react";

// import { Container } from './styles';

const CheckIcon = ({color ="#4b4b4b"}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.432"
    height="12.432"
    viewBox="0 0 12.432 12.432"
    className="checkIconBovexo"
  >
    <g
      id="Group_1016"
      data-name="Group 1016"
      transform="translate(-646.493 -100.493)"
    >
      <g
        id="Group_726"
        data-name="Group 726"
        transform="translate(646.493 100.493)"
      >
        <circle
          id="Ellipse_169"
          data-name="Ellipse 169"
          cx="6.216"
          cy="6.216"
          r="6.216"
          transform="translate(0 0)"
          fill={color}
        />
      </g>
      <path
        id="Path_973"
        data-name="Path 973"
        d="M-6316.687,3789.488l1.667,2.064,3.97-3.89"
        transform="translate(6966.791 -3682.724)"
        fill="none"
        stroke="#fff"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default CheckIcon;
