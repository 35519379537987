import React from "react";

// import { Container } from './styles';

const ParametersIcon = () => (
  <svg
    id="bovexoParameterIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
  >
    <g id="settings" transform="translate(-1.105 -1.001)">
      <g id="parameters" transform="translate(1.105 1.001)">
        <g id="settings_2_">
          <path
            id="Path_943"
            data-name="Path 943"
            d="M4.75,3.575V1.781a1.781,1.781,0,0,0-3.562,0V3.575a2.943,2.943,0,0,0,0,4.724v8.92a1.781,1.781,0,0,0,3.563,0V8.3a2.943,2.943,0,0,0,0-4.724ZM2.375,1.781a.594.594,0,0,1,1.188,0V3.028a3,3,0,0,0-1.187,0ZM3.563,17.219a.594.594,0,0,1-1.187,0V8.846a2.941,2.941,0,0,0,1.188,0ZM4.67,6.436c-.009.031-.018.062-.029.094a1.748,1.748,0,0,1-.224.437l-.008.009a1.8,1.8,0,0,1-.366.372l-.006,0a1.759,1.759,0,0,1-.474.255,1.738,1.738,0,0,1-.593.11,1.731,1.731,0,0,1-.594-.11A1.759,1.759,0,0,1,1.9,7.353l-.006,0a1.8,1.8,0,0,1-.366-.372l-.008-.009A1.768,1.768,0,0,1,1.3,6.529c-.011-.031-.02-.062-.029-.094a1.6,1.6,0,0,1,0-1c.01-.031.018-.062.029-.093a1.736,1.736,0,0,1,.224-.438L1.53,4.9A1.826,1.826,0,0,1,1.9,4.525l.006,0a1.812,1.812,0,0,1,.474-.255,1.759,1.759,0,0,1,.594-.109,1.759,1.759,0,0,1,.594.109,1.827,1.827,0,0,1,.474.255l.006,0a1.826,1.826,0,0,1,.366.372l.008.01a1.74,1.74,0,0,1,.224.438c.011.03.02.061.029.093a1.6,1.6,0,0,1,0,1Z"
            fill="#4b4b4b"
          />
          <path
            id="Path_944"
            data-name="Path 944"
            d="M26.75,3.575V1.781a1.781,1.781,0,0,0-3.562,0V3.575a2.943,2.943,0,0,0,0,4.724v8.92a1.781,1.781,0,0,0,3.563,0V8.3a2.943,2.943,0,0,0,0-4.724ZM24.375,1.781a.594.594,0,0,1,1.188,0V3.028a3,3,0,0,0-1.187,0Zm1.188,15.438a.594.594,0,0,1-1.187,0V8.846a2.941,2.941,0,0,0,1.188,0ZM26.67,6.436c-.01.031-.018.062-.029.094a1.75,1.75,0,0,1-.225.437l-.008.009a1.809,1.809,0,0,1-.365.372l-.006,0a1.768,1.768,0,0,1-.474.255A1.775,1.775,0,0,1,23.9,7.353l-.006,0a1.785,1.785,0,0,1-.366-.372l-.008-.009a1.767,1.767,0,0,1-.224-.437c-.012-.031-.02-.062-.029-.094a1.6,1.6,0,0,1,0-1c.01-.031.017-.062.029-.093a1.774,1.774,0,0,1,.224-.438l.008-.009a1.814,1.814,0,0,1,.366-.372l.006,0a1.818,1.818,0,0,1,.474-.255,1.671,1.671,0,0,1,1.187,0,1.837,1.837,0,0,1,.474.255l.006,0a1.839,1.839,0,0,1,.365.372l.008.01a1.8,1.8,0,0,1,.225.438c.011.03.02.062.029.093a1.6,1.6,0,0,1,0,1Z"
            transform="translate(-8.938)"
            fill="#4b4b4b"
          />
          <path
            id="Path_945"
            data-name="Path 945"
            d="M15.75,10.7V1.781a1.781,1.781,0,0,0-3.562,0V10.7a2.943,2.943,0,0,0,0,4.724v1.795a1.781,1.781,0,0,0,3.563,0V15.424a2.943,2.943,0,0,0,0-4.724ZM13.375,1.781a.594.594,0,0,1,1.188,0v8.372a3.06,3.06,0,0,0-.594-.059,3.005,3.005,0,0,0-.594.059Zm1.188,15.438a.594.594,0,0,1-1.187,0V15.971a2.941,2.941,0,0,0,1.188,0Zm1.107-3.658c-.01.031-.018.062-.029.094a1.75,1.75,0,0,1-.225.437l-.008.01a1.809,1.809,0,0,1-.365.372l-.006,0a1.768,1.768,0,0,1-.474.255,1.654,1.654,0,0,1-1.187,0,1.759,1.759,0,0,1-.474-.255l-.006,0a1.8,1.8,0,0,1-.366-.372l-.008-.01a1.768,1.768,0,0,1-.224-.437c-.011-.031-.02-.062-.029-.094a1.6,1.6,0,0,1,0-1c.009-.031.018-.062.029-.093a1.776,1.776,0,0,1,.224-.438l.008-.01a1.826,1.826,0,0,1,.366-.372l.006,0a1.827,1.827,0,0,1,.474-.255,1.671,1.671,0,0,1,1.187,0,1.837,1.837,0,0,1,.474.255l.006,0a1.839,1.839,0,0,1,.365.372l.008.009a1.8,1.8,0,0,1,.225.438c.011.03.02.062.029.093a1.6,1.6,0,0,1,0,1Z"
            transform="translate(-4.469)"
            fill="#4b4b4b"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default ParametersIcon;
