export const Types = {
  RESET_DATA: "user/RESET_DATA",
  GET_USER: "user/GET_USER",
  GET_USER_SUCCESS: "user/GET_USER_SUCCESS",
  GET_USER_ERROR: "user/GET_USER_ERROR",
  GET_USER_GROUPS_FARMS_DESCRIPTIONS: "user/GET_USER_GROUPS_FARMS_DESCRIPTIONS",
  GET_USER_GROUPS_FARMS_DESCRIPTIONS_SUCCESS:
    "user/GET_USER_GROUPS_FARMS_DESCRIPTIONS_SUCCESS",
  GET_USER_GROUPS_FARMS_DESCRIPTIONS_ERROR:
    "user/GET_USER_GROUPS_FARMS_DESCRIPTIONS_ERROR",
  STORE_USER: "user/STORE_USER",
  STORE_USER_SUCCESS: "user/STORE_USER_SUCCESS",
  STORE_USER_ERROR: "user/STORE_USER_ERROR",
  SHOW_DRAWER_EDIT_PROFILE: "user/SHOW_DRAWER_EDIT_PROFILE",
  HIDE_DRAWER_EDIT_PROFILE: "user/HIDE_DRAWER_EDIT_PROFILE",
  SHOW_DRAWER_REGISTER: "user/SHOW_DRAWER_REGISTER",
  HIDE_DRAWER_REGISTER: "user/HIDE_DRAWER_REGISTER",
  SHOW_DRAWER_CHANGE_PASSWORD: "user/SHOW_DRAWER_CHANGE_PASSWORD",
  HIDE_DRAWER_CHANGE_PASSWORD: "user/HIDE_DRAWER_CHANGE_PASSWORD",
  SHOW_OR_HIDE_INVITE_USER_DRAWER: "user/SHOW_OR_HIDE_INVITE_USER_DRAWER",
  SHOW_OR_HIDE_DETAILS_USER_DRAWER: "user/SHOW_OR_HIDE_DETAILS_USER_DRAWER",
  GET_ALL_ROLES: "user/GET_ALL_ROLES",
  GET_ALL_ROLES_SUCCESS: "user/GET_ALL_ROLES_SUCCESS",
  SHOW_OR_HIDE_HOME_CONFIG_DRAWER: "user/SHOW_OR_HIDE_HOME_CONFIG_DRAWER",
  GET_HOME_CONFIG: "user/GET_HOME_CONFIG",
  GET_HOME_CONFIG_SUCCESS: "user/GET_HOME_CONFIG_SUCCESS",
  GET_HOME_CONFIG_ERROR: "user/GET_HOME_CONFIG_ERROR",
  SAVE_HOME_CONFIG: "user/SAVE_HOME_CONFIG",
  SAVE_HOME_CONFIG_SUCCESS: "user/SAVE_HOME_CONFIG_SUCCESS",
  SAVE_HOME_CONFIG_ERROR: "user/SAVE_HOME_CONFIG_ERROR",
};

const INITIAL_STATE = {
  data: [],
  error: null,
  isLoading: false,
  userData: null,
  userValidate: null,
  drawerVisibleEditUser: false,
  drawerVisibleRegister: false,
  drawerVisibleChangePassword: false,
  userGroups: localStorage.getItem("@Bovexo:userGroups")
    ? JSON.parse(localStorage.getItem("@Bovexo:userGroups"))
    : {},
  drawerInviteUserVisible: false,
  drawerDetailsUserVisible: false,
  isLoadingHomeConfigData: false,
  isHomeConfigDrawerVisible: false,
  homeConfigData: null,
};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_USER:
      return { ...state, isLoading: true };
    case Types.GET_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        userData: action.payload.data,
      };
    case Types.GET_USER_ERROR:
      return { ...state, isLoading: false, error: action.payload.error };
    case Types.GET_USER_GROUPS_FARMS_DESCRIPTIONS:
      return { ...state, isLoading: true };
    case Types.GET_USER_GROUPS_FARMS_DESCRIPTIONS_SUCCESS:
      localStorage.setItem(
        "@Bovexo:userGroups",
        JSON.stringify(action.payload.data.groups)
      );
      return {
        ...state,
        isLoading: false,
        userGroups: action.payload.data.groups,
      };
    case Types.GET_USER_GROUPS_FARMS_DESCRIPTIONS_ERROR:
      return { ...state, isLoading: false, error: action.payload.error };
    case Types.STORE_USER:
      return { ...state, isLoading: true };
    case Types.STORE_USER_SUCCESS:
      return { ...state, isLoading: false };
    case Types.STORE_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        userData: null,
      };
    case Types.SHOW_DRAWER_EDIT_PROFILE:
      return {
        ...state,
        drawerVisibleEditUser: true,
        userData: state.userData,
      };
    case Types.HIDE_DRAWER_EDIT_PROFILE:
      return { ...state, drawerVisibleEditUser: false, userData: null };
    case Types.SHOW_DRAWER_REGISTER:
      return { ...state, drawerVisibleRegister: true };
    case Types.HIDE_DRAWER_REGISTER:
      return { ...state, drawerVisibleRegister: false, userData: null };
    case Types.SHOW_DRAWER_CHANGE_PASSWORD:
      return { ...state, drawerVisibleChangePassword: true };
    case Types.HIDE_DRAWER_CHANGE_PASSWORD:
      return { ...state, drawerVisibleChangePassword: false, userData: null };
    case Types.SHOW_OR_HIDE_INVITE_USER_DRAWER:
      return {
        ...state,
        drawerInviteUserVisible: !state.drawerInviteUserVisible,
      };
    case Types.SHOW_OR_HIDE_DETAILS_USER_DRAWER:
      return {
        ...state,
        userData: action.payload.user,
        drawerDetailsUserVisible: !state.drawerDetailsUserVisible,
      };
    case Types.SHOW_OR_HIDE_HOME_CONFIG_DRAWER:
      return {
        ...state,
        isHomeConfigDrawerVisible: !state.isHomeConfigDrawerVisible,
      };
    case Types.GET_HOME_CONFIG:
      return { ...state, isLoadingHomeConfigData: true };
    case Types.GET_HOME_CONFIG_SUCCESS:
      return {
        ...state,
        isLoadingHomeConfigData: false,
        homeConfigData: action.payload.data,
      };
    case Types.GET_HOME_CONFIG_ERROR:
      return {
        ...state,
        isLoadingHomeConfigData: false,
        error: action.payload.error,
        homeConfigData: INITIAL_STATE.homeConfigData,
      };
    case Types.SAVE_HOME_CONFIG:
      return { ...state, isLoadingHomeConfigData: true };
    case Types.SAVE_HOME_CONFIG_SUCCESS:
      return {
        ...state,
        isLoadingHomeConfigData: false,
        homeConfigData: action.payload.data,
        isHomeConfigDrawerVisible: false,
      };
    case Types.SAVE_HOME_CONFIG_ERROR:
      return {
        ...state,
        isLoadingHomeConfigData: false,
        error: action.payload.error,
        homeConfigData: INITIAL_STATE.homeConfigData,
      };
    default:
      return state;
  }
}

export const Creators = {
  indexUser: () => ({
    type: Types.GET_USER,
    payload: {},
  }),
  get_user_success: (data) => ({
    type: Types.GET_USER_SUCCESS,
    payload: { data },
  }),
  get_user_error: (error) => ({
    type: Types.GET_USER_ERROR,
    payload: { error },
  }),
  indexUserGroupsAndFarmsDescriptions: (userId) => ({
    type: Types.GET_USER_GROUPS_FARMS_DESCRIPTIONS,
    payload: { userId },
  }),
  indexUserGroupsAndFarmsDescriptionsSuccess: (data) => ({
    type: Types.GET_USER_GROUPS_FARMS_DESCRIPTIONS_SUCCESS,
    payload: { data },
  }),
  indexUserGroupsAndFarmsDescriptionsError: (error) => ({
    type: Types.GET_USER_GROUPS_FARMS_DESCRIPTIONS_ERROR,
    payload: { error },
  }),
  storeUser: (form) => ({
    type: Types.STORE_USER,
    payload: { form },
  }),
  storeUserSuccess: (data) => ({
    type: Types.STORE_USER_SUCCESS,
    payload: { data },
  }),
  storeUserError: (error) => ({
    type: Types.STORE_USER_ERROR,
    payload: { error },
  }),
  showDrawerEditProfile: () => ({
    type: Types.SHOW_DRAWER_EDIT_PROFILE,
    payload: {},
  }),
  hideDrawerEditProfile: () => ({
    type: Types.HIDE_DRAWER_EDIT_PROFILE,
    payload: {},
  }),
  showDrawerRegister: () => ({
    type: Types.SHOW_DRAWER_REGISTER,
    payload: {},
  }),
  hideDrawerRegister: () => ({
    type: Types.HIDE_DRAWER_REGISTER,
    payload: {},
  }),
  showDrawerChangePassword: () => ({
    type: Types.SHOW_DRAWER_CHANGE_PASSWORD,
    payload: {},
  }),
  hideDrawerChangePassword: () => ({
    type: Types.HIDE_DRAWER_CHANGE_PASSWORD,
    payload: {},
  }),
  showOrHideInviteUserDrawer: () => ({
    type: Types.SHOW_OR_HIDE_INVITE_USER_DRAWER,
    payload: {},
  }),
  showOrHideDetaisUserDrawer: (user) => ({
    type: Types.SHOW_OR_HIDE_DETAILS_USER_DRAWER,
    payload: {
      user,
    },
  }),

  showOrHideHomeConfigDrawer: () => ({
    type: Types.SHOW_OR_HIDE_HOME_CONFIG_DRAWER,
    payload: {},
  }),
  getHomeConfig: (groupId, farmId) => ({
    type: Types.GET_HOME_CONFIG,
    payload: { groupId, farmId },
  }),
  getHomeConfigSuccess: (data) => ({
    type: Types.GET_HOME_CONFIG_SUCCESS,
    payload: { data },
  }),
  getHomeConfigError: (error) => ({
    type: Types.GET_HOME_CONFIG_ERROR,
    payload: { error },
  }),
  saveHomeConfig: (groupId, farmId, body) => ({
    type: Types.SAVE_HOME_CONFIG,
    payload: { groupId, farmId, body },
  }),
  saveHomeConfigSuccess: (data) => ({
    type: Types.SAVE_HOME_CONFIG_SUCCESS,
    payload: { data },
  }),
  saveHomeConfigError: (error) => ({
    type: Types.SAVE_HOME_CONFIG_ERROR,
    payload: { error },
  }),
};
