import React from "react";

// import { Container } from './styles';

const RainIcon = () => (
  <svg
    id="raining"
    xmlns="http://www.w3.org/2000/svg"
    width="20.189"
    height="19.331"
    viewBox="0 0 20.189 19.331"
  >
    <g id="Group_2323" data-name="Group 2323" transform="translate(0 0)">
      <g id="Group_2322" data-name="Group 2322">
        <path
          id="Path_1907"
          data-name="Path 1907"
          d="M18.789,11.826a4.789,4.789,0,0,0-3.389-1.4,2.113,2.113,0,0,0-.393.025A6.035,6.035,0,0,0,5.624,8.436,6.139,6.139,0,0,0,3.684,11.6a4.243,4.243,0,0,0-2.456,1.2,4.21,4.21,0,0,0,0,5.944,4.132,4.132,0,0,0,3,1.253H15.4a4.793,4.793,0,0,0,3.389-8.179Zm-.982,5.895a3.511,3.511,0,0,1-2.481,1.032H4.225A2.935,2.935,0,0,1,1.277,15.8a2.849,2.849,0,0,1,.86-2.088,2.933,2.933,0,0,1,2.088-.86.583.583,0,0,0,.295-.074.6.6,0,0,0,.27-.418,4.743,4.743,0,0,1,1.6-2.9,4.774,4.774,0,0,1,5.846-.295,4.609,4.609,0,0,1,1.449,1.621c-.1.049-.2.074-.295.123A5.1,5.1,0,0,0,11.765,12.1a.626.626,0,1,0,.933.835,4.127,4.127,0,0,1,1.179-.884,3.571,3.571,0,0,1,1.449-.319,3.468,3.468,0,0,1,2.481,1.032,3.5,3.5,0,0,1,0,4.961Z"
          transform="translate(0 -6.987)"
          fill="#4a85ae"
        />
      </g>
    </g>
    <g
      id="Group_2325"
      data-name="Group 2325"
      transform="translate(7.786 13.308)"
    >
      <g id="Group_2324" data-name="Group 2324">
        <path
          id="Path_1908"
          data-name="Path 1908"
          d="M129.944,224.488a3.865,3.865,0,0,1-.295-.467.747.747,0,0,0-.2-.2.64.64,0,0,0-.884.2c-.123.172-.2.319-.319.467a7.09,7.09,0,0,0-1.449,2.972,2.264,2.264,0,0,0,2.284,2.284,2.184,2.184,0,0,0,1.646-.663,2.262,2.262,0,0,0,.663-1.621A7.09,7.09,0,0,0,129.944,224.488Zm-.1,3.709a1,1,0,0,1-.737.295,1.107,1.107,0,0,1-.737-.295,1,1,0,0,1-.295-.737,5.844,5.844,0,0,1,1.032-1.965,5.843,5.843,0,0,1,1.032,1.965A1.107,1.107,0,0,1,129.846,228.2Z"
          transform="translate(-126.8 -223.722)"
          fill="#4a85ae"
        />
      </g>
    </g>
    <g
      id="Group_2327"
      data-name="Group 2327"
      transform="translate(13.017 13.308)"
    >
      <g id="Group_2326" data-name="Group 2326">
        <path
          id="Path_1909"
          data-name="Path 1909"
          d="M215.144,224.488a3.863,3.863,0,0,1-.295-.467.747.747,0,0,0-.2-.2.64.64,0,0,0-.884.2,3.839,3.839,0,0,1-.319.467A7.09,7.09,0,0,0,212,227.459a2.264,2.264,0,0,0,2.284,2.284,2.166,2.166,0,0,0,1.621-.663,2.283,2.283,0,0,0,.688-1.621A7.09,7.09,0,0,0,215.144,224.488Zm-.123,3.709a1,1,0,0,1-.737.295,1.107,1.107,0,0,1-.737-.295,1,1,0,0,1-.295-.737,5.843,5.843,0,0,1,1.032-1.965,5.844,5.844,0,0,1,1.032,1.965A1.106,1.106,0,0,1,215.021,228.2Z"
          transform="translate(-212 -223.722)"
          fill="#4a85ae"
        />
      </g>
    </g>
    <g
      id="Group_2329"
      data-name="Group 2329"
      transform="translate(2.579 13.308)"
    >
      <g id="Group_2328" data-name="Group 2328">
        <path
          id="Path_1910"
          data-name="Path 1910"
          d="M45.119,224.488a3.863,3.863,0,0,1-.295-.467c-.025-.074-.1-.123-.172-.2a.64.64,0,0,0-.884.2,3.835,3.835,0,0,1-.319.467A7.09,7.09,0,0,0,42,227.459a2.264,2.264,0,0,0,2.284,2.284,2.228,2.228,0,0,0,1.572-.639c.025,0,.025-.025.049-.025a2.262,2.262,0,0,0,.663-1.621A7.09,7.09,0,0,0,45.119,224.488Zm-.074,3.709-.025.025a.983.983,0,0,1-.712.27,1.107,1.107,0,0,1-.737-.295,1,1,0,0,1-.295-.737,5.844,5.844,0,0,1,1.032-1.965,6.076,6.076,0,0,1,1.032,1.965A1.106,1.106,0,0,1,45.046,228.2Z"
          transform="translate(-42 -223.722)"
          fill="#4a85ae"
        />
      </g>
    </g>
  </svg>
);
export default RainIcon;
