import apiManagement from "../config/api_management";
import getHeaders from "./getHeaders";
// import axios from "axios";

// const apiManagement = axios.create({
//   baseURL: "http://localhost:8081",
// });

export function saveTriggerRequest(payload) {
  const { groupId, farmId, triggerAction, body } = payload;

  return apiManagement.post(
    `/bovexo/${groupId}/farms/${farmId}/trigger/${triggerAction}`,
    body,
    getHeaders(false, null)
  );
}

export function getLastUserTriggerRequest(payload) {
  const { groupId, farmId, triggerAction } = payload;

  return apiManagement.get(
    `/bovexo/${groupId}/farms/${farmId}/trigger/${triggerAction}/last/status`,
    getHeaders(false, null)
  );
}
