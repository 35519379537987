import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import CanvasJSReact from "../../../../../assets/canvasjs.react";
import { Tooltip, Spin } from "antd";
import InfoIcon from "../../../../../components/utils/icons/info";
import {
	getTwoDecimalDigits,
	numberMask,
} from "../../../../../services/helpersMethodsService";

const { CanvasJSChart } = CanvasJSReact;

const ScatterWeaningWeightChart = (props) => {
	const { translation } = useSelector((state) => state.app);

	const { isAnimalWeaningWeightDashboardLoading, animalWeaningWeightDashboard } = useSelector(
		(state) => state.animal
	);

	const labels = [
		"1ª Cria", "2ª Cria", "3ª Cria", "4ª Cria", "5ª Cria", "6ª Cria", "7ª Cria", "8ª Cria", "9ª Cria", "10ª Cria",
		"11ª Cria", "12ª Cria", "13ª Cria", "14ª Cria", "15ª Cria", "16ª Cria", "17ª Cria", "18ª Cria", "19ª Cria", "20ª Cria"]

	const toggleDataSeries = (e) => {
		if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
			e.dataSeries.visible = false;
		} else {
			e.dataSeries.visible = true;
		}
		e.chart.render();
	};

	const options = {
		height: 250,
		dataPointWidth: 36,
		toolTip: {
			enabled: true,
		},
		axisY: {
			valueFormatString: "0.00",
			includeZero: true,
			gridDashType: "dot",
		},
		legend: {
			verticalAlign: "bottom",
			horizontalAlign: "center",
			reversed: true,
			cursor: "pointer",
			itemclick: toggleDataSeries
		},
		data: [{
			type: "scatter",
			showInLegend: true,
			name: "Vaca",
			markerType: "square",
			markerSize: 13,
			toolTipContent: "<strong>Peso Vaca:</strong> {cowWeight} Kg</br><strong>Peso Bezerro:</strong> {calfWeight} Kg",
			dataPoints: animalWeaningWeightDashboard?.find(item => item.allCows === false)?.values.map((v) => {
				return {
					x: v.x, label: labels[v.x], y: v.y, cowWeight: v.cowWeight !== null ? numberMask(
						getTwoDecimalDigits(v.cowWeight),
						false
					) : 0, calfWeight: v.calfWeight !== null ? numberMask(
						getTwoDecimalDigits(v.calfWeight),
						false
					) : 0
				}
			})
		},
		{
			type: "scatter",
			showInLegend: true,
			name: "Contemporâneas",
			markerType: "square",
			markerSize: 13,
			toolTipContent: "<strong>Nº de vacas: </strong> {numberOfCows}",
			dataPoints: animalWeaningWeightDashboard?.find(item => item.allCows === true)?.values.map((v) => {
				return { x: v.x, label: labels[v.x], y: v.y, numberOfCows: v.numberOfCows }
			})
		}
		]
	}

	return (
		<Fragment>
			{animalWeaningWeightDashboard !== null && animalWeaningWeightDashboard?.length > 0 && (
				<Spin spinning={isAnimalWeaningWeightDashboardLoading}>
					<span className="detailTitle">
						KG Bez Desm/Peso Vaca
						<Tooltip title={(
							<>
								<p>Relação do Peso da Mãe e da Cria na data do desmame da Cria.</p>
								<p>Se a Mãe não tiver peso real registrado na data da desmama da cria, será considerado para calculo o peso projetado conforme estratégia de dieta para esta data.</p>
								<p>Contemporâneas, são as matrizes atuais.</p>
							</>)}>
							<InfoIcon />
						</Tooltip>
					</span>
					<div className="chartDiv">
						<CanvasJSChart options={options} />
					</div>
				</Spin>)}
		</Fragment>
	);
}

export default ScatterWeaningWeightChart;