import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import useHandlingReproductionCoverageContext from "../../hooks/useHandlingReproductionCoverageContext";
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Icon,
  Input,
  Menu,
  notification,
  Row,
  Select,
  Table,
} from "antd";
import ButtonStandard from "../../components/utils/button";
import { CardCustom, Title } from "./styles";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import MenuIcon from "../../components/utils/table/icons/menu";
import { HandlingReproductionCoverageContextProvider } from "../../contexts/handlingReproductionCoverageContext";
import moment from "moment";
import { numberMask } from "../../services/helpersMethodsService";
import HandlingReproductionCoverageForm from "./form";
import { deleteAnimalsCoverage } from "../../services/handlingReproductionCoverageService";
import CustomLink from "../../components/utils/customLink";

const HandlingReproductionCoverageLayout = () => {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const {
    handleTableChange,
    dataList,
    isLoadingDataList,
    showCardForm,
    openOrCloseForm,
    initializeData,
    tableProperties: { size, page, tableFilters, tableSorters },
  } = useHandlingReproductionCoverageContext();

  const menu = (id, record) => {
    return (
      <Menu>
        <Menu.Item onClick={() => handleEdit(record)}>
          {translation.table.menu.edit}
        </Menu.Item>
        {record.canDelete === true && (
          <Menu.Item onClick={() => handleDelete(id)}>
            {translation.table.menu.delete}
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const handleCreateNewRecord = () => {
    openOrCloseForm();
    initializeData(null);
  };

  const handleEdit = (record) => {
    openOrCloseForm();
    initializeData({
      id: record.id,
      type: record.type,
      animalId: record.animalId,
      animalHandlingNumber: record.animalHandlingNumber,
      semenId: record.semenId,
      maleId: record.maleId,
      dose: record.dose,
      reproductionPeriodId: record.reproductionPeriodId,
      inseminatorId: record.inseminatorId,
      coverageDate: record.coverageDate,
    });
  };

  const handleDelete = useCallback(
    async (id) => {
      try {
        await deleteAnimalsCoverage({ groupId, farmId, id });
        handleTableChange(
          { pageSize: size, current: page },
          tableFilters,
          tableSorters
        );
        notification.success({
          message: "Cobertura deletada com sucesso!",
        });
      } catch (error) {
        if (Object.keys(error).includes("response")) {
          const { response } = error;
          if (Object.keys(response).includes("data")) {
            const {
              data: { code: errorCode },
            } = response;
            if (errorCode === 5028) {
              notification.error({
                message:
                  "Erro ao deletar Cobertura! Esta já possui um Diagnóstico de Gestação, por favor exclua o diagnóstico primeiro.",
              });
            } else {
              notification.error({
                message: "Erro ao deletar Cobertura!",
              });
            }
          }
        } else {
          notification.error({
            message: "Erro ao deletar Cobertura!",
          });
        }
      }
    },
    [groupId, farmId, handleTableChange, size, page, tableFilters, tableSorters]
  );

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys && selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
    }),
    [handleReset, handleSearch]
  );

  const handleGetColumnDateSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Row type="flex" gutter={4}>
            <Col>
              <Select
                value={selectedKeys ? selectedKeys[1] : "equal"}
                onChange={(value) =>
                  setSelectedKeys(
                    value
                      ? selectedKeys
                        ? [selectedKeys[0], value]
                        : [null, value]
                      : selectedKeys
                      ? [selectedKeys[0], "equal"]
                      : [null, "equal"]
                  )
                }
              >
                <Select.Option value="equal">=</Select.Option>
                <Select.Option value="greaterThan">&gt;</Select.Option>
                <Select.Option value="greaterOrEqualThan">&ge;</Select.Option>
                <Select.Option value="lessThan">&lt;</Select.Option>
                <Select.Option value="lessOrEqualThan">&le;</Select.Option>
              </Select>
            </Col>
            <Col>
              <DatePicker
                value={selectedKeys && selectedKeys[0]}
                format={"DD/MM/YYYY"}
                onChange={(date) =>
                  setSelectedKeys(
                    date
                      ? selectedKeys && selectedKeys.length > 1
                        ? [date, selectedKeys[1]]
                        : [date, "equal"]
                      : []
                  )
                }
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </Col>
          </Row>

          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
    }),
    [handleReset, handleSearch]
  );

  const handleRefresh = () => {
    handleTableChange(
      { pageSize: size, current: page },
      tableFilters,
      tableSorters
    );
  };

  useEffect(() => {
    handleTableChange();
  }, [handleTableChange]);

  return (
    <CardCustom>
      <Row className="rowHeader" justify="space-between">
        <Col span={8}>
          <Title>{translation.handlingReproductionCoverage.title}</Title>
        </Col>
        <Col span={16}>
          <Row type="flex" justify="end" gutter={8}>
            <Col>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={handleRefresh}
              >
                <Icon type="reload" />
              </ButtonStandard>
              {!showCardForm && (
                <ButtonStandard
                  name="buttonCreateBudget"
                  buttonType="type8"
                  onClick={handleCreateNewRecord}
                >
                  {
                    translation.handlingReproductionCoverage
                      .buttonHandlingReproductionCoverage
                  }
                </ButtonStandard>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {showCardForm && (
        <Row type="flex">
          <HandlingReproductionCoverageForm />
        </Row>
      )}
      <Row type="flex">
        <Col span={24}>
          <Table
            loading={isLoadingDataList}
            rowKey="id"
            size="small"
            dataSource={dataList ? dataList.content : []}
            scroll={{
              x: true,
            }}
            pagination={{
              total: dataList != null ? dataList.totalElements : 0,
              size: dataList != null ? size : 0,
              current: dataList != null ? page : 0,
              hideOnSinglePage:
                dataList !== null && Object.entries(dataList).length !== 0
                  ? dataList.totalElements > 10
                    ? false
                    : true
                  : true,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
            }}
            onChange={handleTableChange}
          >
            <Table.Column
              title={
                translation.handlingReproductionCoverage.table.columns
                  .animalHandlingNumber
              }
              dataIndex="animalHandlingNumber"
              filteredValue={tableFilters?.animalHandlingNumber || null}
              sorter
              sortOrder={
                tableSorters?.columnKey === "animalHandlingNumber" &&
                tableSorters.order
              }
              key="animalHandlingNumber"
              {...handleGetColumnSearchProps("animalHandlingNumber")}
              render={(text, record) => (
                <CustomLink to={`/admin/animals/${record.animalId}`}>
                  {text}
                </CustomLink>
              )}
            />
            <Table.Column
              title={
                translation.handlingReproductionCoverage.table.columns
                  .coverageDate
              }
              dataIndex="coverageDate"
              filteredValue={tableFilters?.coverageDate || null}
              sorter
              sortOrder={
                tableSorters?.columnKey === "coverageDate" && tableSorters.order
              }
              key="coverageDate"
              {...handleGetColumnDateSearchProps("coverageDate")}
              render={(text, record) => (
                <span>
                  {`${
                    record.coverageDate !== null
                      ? moment(record.coverageDate).format("DD/MM/YYYY")
                      : ""
                  }`}
                </span>
              )}
            />

            <Table.Column
              title={
                translation.handlingReproductionCoverage.table.columns.type
              }
              dataIndex="type"
              filteredValue={tableFilters?.type || null}
              filtered
              filters={[
                {
                  text: "IA/IATF",
                  value: "IA/IATF",
                },
                {
                  text: "MC/REPASSE",
                  value: "MC/REPASSE",
                },
              ]}
              filterMultiple={false}
              sorter
              sortOrder={
                tableSorters?.columnKey === "type" && tableSorters.order
              }
              key="type"
              render={(text, record) => (
                <span>
                  {`${
                    record.type === "IA/IATF"
                      ? translation.handlingReproductionCoverage.type.iatf
                      : translation.handlingReproductionCoverage.type.repasse
                  }`}
                </span>
              )}
            />
            <Table.Column
              title={
                translation.handlingReproductionCoverage.table.columns
                  .semenOrMaleIdentification
              }
              dataIndex="semenOrMaleIdentification"
              filteredValue={tableFilters?.semenOrMaleIdentification || null}
              sorter
              sortOrder={
                tableSorters?.columnKey === "semenOrMaleIdentification" &&
                tableSorters.order
              }
              key="semenOrMaleIdentification"
              {...handleGetColumnSearchProps("semenOrMaleIdentification")}
            />

            <Table.Column
              title={
                translation.handlingReproductionCoverage.table.columns
                  .inseminatorName
              }
              dataIndex="inseminatorName"
              filteredValue={tableFilters?.inseminatorName || null}
              sorter
              sortOrder={
                tableSorters?.columnKey === "inseminatorName" &&
                tableSorters.order
              }
              key="inseminatorName"
              {...handleGetColumnSearchProps("inseminatorName")}
            />

            <Table.Column
              title={
                translation.handlingReproductionCoverage.table.columns.dose
              }
              dataIndex="dose"
              filteredValue={tableFilters?.dose || null}
              sorter
              sortOrder={
                tableSorters?.columnKey === "dose" && tableSorters.order
              }
              key="dose"
              {...handleGetColumnSearchProps("dose")}
              render={(text, record) => (
                <span>{`${
                  record.semenId !== null ? numberMask(record.dose, false) : "-"
                }`}</span>
              )}
            />
            <Table.Column
              title={
                translation.handlingReproductionCoverage.table.columns
                  .reproductionPeriodDescription
              }
              dataIndex="reproductionPeriodDescription"
              filteredValue={
                tableFilters?.reproductionPeriodDescription || null
              }
              sorter
              sortOrder={
                tableSorters?.columnKey === "reproductionPeriodDescription" &&
                tableSorters.order
              }
              key="reproductionPeriodDescription"
              {...handleGetColumnSearchProps("reproductionPeriodDescription")}
              render={(text, record) => (
                <span>
                  {`${
                    record.reproductionPeriodId !== null
                      ? record.reproductionPeriodDescription
                      : "-"
                  }`}
                </span>
              )}
            />

            <Table.Column
              title={
                translation.handlingReproductionCoverage.table.columns.lotName
              }
              dataIndex="lotName"
              filteredValue={tableFilters?.lotName || null}
              sorter
              sortOrder={
                tableSorters?.columnKey === "lotName" && tableSorters.order
              }
              key="lotName"
              {...handleGetColumnSearchProps("lotName")}
            />

            <Table.Column
              title={
                translation.handlingReproductionCoverage.table.columns
                  .pregnancyDiagnosisResult
              }
              dataIndex="pregnancyDiagnosisResult"
              filteredValue={tableFilters?.pregnancyDiagnosisResult || null}
              filtered
              filters={[
                {
                  text: "Positivo",
                  value: "Positivo",
                },
                {
                  text: "Negativo",
                  value: "Negativo",
                },
              ]}
              filterMultiple={false}
              sorter
              sortOrder={
                tableSorters?.columnKey === "pregnancyDiagnosisResult" &&
                tableSorters.order
              }
              key="pregnancyDiagnosisResult"
              render={(text) => <span>{text === null ? "-" : text}</span>}
            />

            <Table.Column
              title=""
              align="left"
              render={(text, record) => (
                <Dropdown
                  overlay={menu(record.id, record)}
                  trigger={["click"]}
                  key={record.id}
                >
                  <MenuContainer>
                    <MenuIcon />
                  </MenuContainer>
                </Dropdown>
              )}
            />
          </Table>
        </Col>
      </Row>
    </CardCustom>
  );
};

const HandlingReproductionCoverage = () => {
  return (
    <HandlingReproductionCoverageContextProvider>
      <HandlingReproductionCoverageLayout />
    </HandlingReproductionCoverageContextProvider>
  );
};

export default HandlingReproductionCoverage;
