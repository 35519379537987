import { notification } from "antd";
import moment from "moment";

export const Types = {
  RESET_DATA: "saleScenario/RESET_DATA",
  CHANGE_TAB: "saleScenario/CHANGE_TAB",
  ENABLE_DISABLE_BLOCK_NAVIGATION_NEW_SCENARIO:
    "saleScenario/ENABLE_DISABLE_BLOCK_NAVIGATION_NEW_SCENARIO",
  ADD_REMOVE_LOTS_IN_NEW_SALE_SCENARIO:
    "saleScenario/ADD_REMOVE_LOTS_IN_NEW_SALE_SCENARIO",
  ADD_REMOVE_DIET_STRATEGIES_IN_NEW_SALE_SCENARIO:
    "saleScenario/ADD_REMOVE_DIET_STRATEGIES_IN_NEW_SALE_SCENARIO",
  OPEN_MODAL_SALE_COMMMITMENT: "saleScenario/OPEN_MODAL_SALE_COMMMITMENT",
  ADD_UPDATE_REMOVE_COMMITMENTS_IN_NEW_SALE_SCENARIO:
    "saleScenario/ADD_UPDATE_REMOVE_COMMITMENTS_IN_NEW_SALE_SCENARIO",
  SET_MAX_DEADLINE_GOAL: "saleScenario/SET_MAX_DEADLINE_GOAL",
  UPDATE_SALE_SCENARIO_PARAM: "saleScenario/UPDATE_SALE_SCENARIO_PARAM",
  SELECT_SALE_SCENARIO_RESULT_ANIMAL_TO_SELL:
    "saleScenario/SELECT_SALE_SCENARIO_RESULT_ANIMAL_TO_SELL",
  REMOVE_SALE_SCENARIO_RESULT_ANIMAL_TO_SELL:
    "saleScenario/REMOVE_SALE_SCENARIO_RESULT_ANIMAL_TO_SELL",
  SHOW_OR_HIDE_DRAWER_EDIT_SALE_SCENARIO:
    "saleScenario/SHOW_OR_HIDE_DRAWER_EDIT_SALE_SCENARIO",
  SHOW_EDIT_SALE_SCENARIO: "saleScenario/SHOW_EDIT_SALE_SCENARIO",
  SHOW_EDIT_SALE_SCENARIO_SUCCESS:
    "saleScenario/SHOW_EDIT_SALE_SCENARIO_SUCCESS",
  SHOW_EDIT_SALE_SCENARIO_ERROR: "saleScenario/SHOW_EDIT_SALE_SCENARIO_ERROR",
  SHOW_OR_HIDE_DRAWER_DETAILS_SALE_SCENARIO:
    "saleScenario/SHOW_OR_HIDE_DRAWER_DETAILS_SALE_SCENARIO",
  REFRESH_TABLE: "saleScenario/REFRESH_TABLE",
  SET_COMPARE_SALE_SCENARIOS: "saleScenario/SET_COMPARE_SALE_SCENARIOS",
  SET_COMPARE_SALE_SCENARIOS_RESULTS:
    "saleScenario/SET_COMPARE_SALE_SCENARIOS_RESULTS",
};

export const Creators = {
  resetData: () => ({
    type: Types.RESET_DATA,
    payload: {},
  }),
  enableDisableBlockNavigationNewScenario: (isEnable) => ({
    type: Types.ENABLE_DISABLE_BLOCK_NAVIGATION_NEW_SCENARIO,
    payload: { isEnable },
  }),
  changeTab: (index) => ({
    type: Types.CHANGE_TAB,
    payload: {
      index,
    },
  }),
  addOrRemoveLots: (lots) => ({
    type: Types.ADD_REMOVE_LOTS_IN_NEW_SALE_SCENARIO,
    payload: {
      lots,
    },
  }),
  addOrRemoveDietStrategies: (dietStrategy, id, action) => ({
    type: Types.ADD_REMOVE_DIET_STRATEGIES_IN_NEW_SALE_SCENARIO,
    payload: {
      dietStrategy,
      id,
      action,
    },
  }),
  openModalSaleCommitment: (data = INITIAL_STATE.modalSaleCommitmentData) => ({
    type: Types.OPEN_MODAL_SALE_COMMMITMENT,
    payload: {
      data,
    },
  }),
  addUpdateOrRemoveCommitments: (commitment, key, action) => ({
    type: Types.ADD_UPDATE_REMOVE_COMMITMENTS_IN_NEW_SALE_SCENARIO,
    payload: {
      commitment,
      key,
      action,
    },
  }),
  setMaxDeadlineGoal: (date) => ({
    type: Types.SET_MAX_DEADLINE_GOAL,
    payload: {
      date,
    },
  }),
  updateSaleScenarioParam: (form) => ({
    type: Types.UPDATE_SALE_SCENARIO_PARAM,
    payload: {
      form,
    },
  }),
  selectSaleScenarioResultAnimals: (animals, animalsIds) => ({
    type: Types.SELECT_SALE_SCENARIO_RESULT_ANIMAL_TO_SELL,
    payload: {
      animals,
      animalsIds,
    },
  }),
  removeSaleScenarioResultAnimals: (id, animalId) => ({
    type: Types.REMOVE_SALE_SCENARIO_RESULT_ANIMAL_TO_SELL,
    payload: {
      id,
      animalId,
    },
  }),
  showOrHideDrawerEditSaleScenario: () => ({
    type: Types.SHOW_OR_HIDE_DRAWER_EDIT_SALE_SCENARIO,
    payload: {},
  }),
  showEditSaleScenario: (groupId, farmId, saleScenarioId) => ({
    type: Types.SHOW_EDIT_SALE_SCENARIO,
    payload: {
      groupId,
      farmId,
      saleScenarioId,
    },
  }),
  showEditSaleScenarioSuccess: (data) => ({
    type: Types.SHOW_EDIT_SALE_SCENARIO_SUCCESS,
    payload: {
      data,
    },
  }),
  showEditSaleScenarioError: (error) => ({
    type: Types.SHOW_EDIT_SALE_SCENARIO_ERROR,
    payload: {
      error,
    },
  }),
  showOrHideDrawerDetailsSaleScenario: () => ({
    type: Types.SHOW_OR_HIDE_DRAWER_DETAILS_SALE_SCENARIO,
    payload: {},
  }),
  refreshTable: () => ({
    type: Types.REFRESH_TABLE,
    payload: {},
  }),
  setCompareSaleScenario: (dataCompare) => ({
    type: Types.SET_COMPARE_SALE_SCENARIOS,
    payload: { dataCompare },
  }),
  setCompareSaleScenarioResults: (data) => ({
    type: Types.SET_COMPARE_SALE_SCENARIOS_RESULTS,
    payload: { data },
  }),
};

const INITIAL_STATE = {
  refreshTable: false,
  newSaleScenarioShouldBlockNavigation: false,
  newSaleScenario: {
    id: null,
    name: null,
    saleScenarioLots: [],
    saleScenarioParam: {
      id: null,
      retroactive: false,
      retroactiveDate: new Date(),
      animalsInTruck: null,
      animalsInTruckUnit: "cab",
      strongAnimalsInTruckRestriction: true,
      useCustomCarcassHarnessing: false,
      carcassHarnessing: null,
      useDefaultMaximumWeight: false,
      marketAnalysisId: null,
      allowSeparateSell: false,
      capacitySupportRestriction: "Strong",
      goalType: "MaxProfit",
      minimumAge: null,
      maximumAge: null,
      strongAgeRestriction: true,
      minimumWeight: null,
      maximumWeight: 720,
      strongWeightRestriction: true,
      useCarbonCredit: false,
      carbonCredit: null,
      useQuotaCriteria: true,
      quotaId: null,
    },
    saleCommitments: [],
    status: "Analyzing",
  },
  maxDeadLineGoal: null,
  minDeadLineGoal: null,
  modalSaleCommitmentVisible: false,
  modalSaleCommitmentData: {
    key: null,
    saleCommitmentType: "AmountAnimals",
    commitmentValue: null,
    commitmentDate: null,
  },
  showDrawerEditParameter: false,
  activeTab: "tab1",
  saleScenarioResultAnimalsSelected: localStorage.getItem(
    "@Bovexo:saleScenarioResultAnimalsSelected"
  )
    ? JSON.parse(
        localStorage.getItem("@Bovexo:saleScenarioResultAnimalsSelected")
      )
    : [],
  saleScenarioResultAnimalsSelectedIds: localStorage.getItem(
    "@Bovexo:saleScenarioResultAnimalsSelectedIds"
  )
    ? JSON.parse(
        localStorage.getItem("@Bovexo:saleScenarioResultAnimalsSelectedIds")
      )
    : [],
  showDrawerDetailsParameter: false,
  compareRequestData: localStorage.getItem("@Bovexo:saleScenarioCompare")
    ? JSON.parse(localStorage.getItem("@Bovexo:saleScenarioCompare"))
    : {
        selectedResults: [
          {
            reference: false,
            resultId: null,
          },
        ],
      },
  compareSaleScenarioResults: {
    farmInfo: null,
    results: null,
  },
};

export default function saleScenario(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.ENABLE_DISABLE_BLOCK_NAVIGATION_NEW_SCENARIO:
      return {
        ...state,
        newSaleScenarioShouldBlockNavigation: action.payload.isEnable,
      };
    case Types.CHANGE_TAB:
      if (validateTabs(state, action.payload.index))
        return { ...state, activeTab: action.payload.index };
      else return { ...state };
    case Types.ADD_REMOVE_LOTS_IN_NEW_SALE_SCENARIO:
      const newSaleScenarioLots = action.payload.lots.map((l) => ({
        lot: l,
        dietStrategies: l.dietStrategies.filter((ds) => ds.baseline === true),
      }));
      let maxDeadLineGoal = calculateMaxDeadLineGoal(newSaleScenarioLots);
      let minDeadLineGoal = calculateMinDeadLineGoal(newSaleScenarioLots);
      return {
        ...state,
        newSaleScenario: {
          ...state.newSaleScenario,
          saleScenarioLots: newSaleScenarioLots,
        },
        maxDeadLineGoal,
        minDeadLineGoal,
      };
    case Types.ADD_REMOVE_DIET_STRATEGIES_IN_NEW_SALE_SCENARIO:
      const lotId = state.newSaleScenario.saleScenarioLots.find((ssl) =>
        ssl.lot.dietStrategies.find((ds) => ds.newKey === action.payload.id)
      ).lot.id;
      const updatedSaleScenarioLots =
        action.payload.action === "add"
          ? state.newSaleScenario.saleScenarioLots.map((ssl) => {
              if (ssl.lot.id === lotId) {
                ssl.dietStrategies = [
                  ...ssl.dietStrategies,
                  action.payload.dietStrategy,
                ];
              }
              return ssl;
            })
          : state.newSaleScenario.saleScenarioLots.map((ssl) => {
              if (ssl.lot.id === lotId) {
                ssl.dietStrategies = ssl.dietStrategies.filter(
                  (ds) => ds.id !== action.payload.dietStrategy.id
                );
              }
              return ssl;
            });
      try {
        const newMaxDeadLineGoal = calculateMaxDeadLineGoal(
          updatedSaleScenarioLots
        );
        const newMinDeadLineGoal = calculateMinDeadLineGoal(
          updatedSaleScenarioLots
        );
        return {
          ...state,
          newSaleScenario: {
            ...state.newSaleScenario,
            saleScenarioLots: updatedSaleScenarioLots,
          },
          maxDeadLineGoal: newMaxDeadLineGoal,
          minDeadLineGoal: newMinDeadLineGoal,
        };
      } catch (error) {
        console.error(error);
        return {
          ...state,
          newSaleScenario: {
            ...state.newSaleScenario,
            saleScenarioLots: updatedSaleScenarioLots,
          },
        };
      }
    case Types.OPEN_MODAL_SALE_COMMMITMENT:
      return {
        ...state,
        modalSaleCommitmentVisible: !state.modalSaleCommitmentVisible,
        modalSaleCommitmentData: action.payload.data,
      };
    case Types.ADD_UPDATE_REMOVE_COMMITMENTS_IN_NEW_SALE_SCENARIO:
      let updatedSaleCommitments = [];
      let commitment = action.payload.commitment;
      const key = action.payload.key;
      switch (action.payload.action) {
        case "add":
          commitment.key = key;
          updatedSaleCommitments = [
            ...state.newSaleScenario.saleCommitments,
            commitment,
          ];
          break;
        case "update":
          updatedSaleCommitments = state.newSaleScenario.saleCommitments.map(
            (sc) => (sc.key === key ? commitment : sc)
          );
          break;
        case "remove":
          updatedSaleCommitments = state.newSaleScenario.saleCommitments.filter(
            (sc) => sc.key !== key
          );
          break;
        default:
          break;
      }
      return {
        ...state,
        newSaleScenario: {
          ...state.newSaleScenario,
          saleCommitments: updatedSaleCommitments,
        },
        modalSaleCommitmentVisible: false,
        modalSaleCommitmentData: {
          key: null,
          saleCommitmentType: "AmountAnimals",
          commitmentValue: null,
          commitmentDate: null,
        },
      };
    case Types.SET_MAX_DEADLINE_GOAL:
      return { ...state, maxDeadLineGoal: action.payload.date };
    case Types.UPDATE_SALE_SCENARIO_PARAM:
      return {
        ...state,
        newSaleScenario: {
          ...state.newSaleScenario,
          saleScenarioParam: action.payload.form,
        },
      };
    case Types.SELECT_SALE_SCENARIO_RESULT_ANIMAL_TO_SELL:
      localStorage.setItem(
        "@Bovexo:saleScenarioResultAnimalsSelected",
        JSON.stringify(action.payload.animals)
      );
      localStorage.setItem(
        "@Bovexo:saleScenarioResultAnimalsSelectedIds",
        JSON.stringify(action.payload.animalsIds)
      );
      return {
        ...state,
        saleScenarioResultAnimalsSelected: action.payload.animals,
        saleScenarioResultAnimalsSelectedIds: action.payload.animalsIds,
      };
    case Types.REMOVE_SALE_SCENARIO_RESULT_ANIMAL_TO_SELL:
      const filteredSaleScenarioResultAnimalsSelected =
        state.saleScenarioResultAnimalsSelected.filter(
          (x) => x.id !== action.payload.id
        );
      const filteredSaleScenarioResultAnimalsSelectedIds =
        state.saleScenarioResultAnimalsSelectedIds.filter(
          (x) => x !== action.payload.animalId
        );
      localStorage.setItem(
        "@Bovexo:saleScenarioResultAnimalsSelected",
        JSON.stringify(filteredSaleScenarioResultAnimalsSelected)
      );
      localStorage.setItem(
        "@Bovexo:saleScenarioResultAnimalsSelectedIds",
        JSON.stringify(filteredSaleScenarioResultAnimalsSelectedIds)
      );
      return {
        ...state,
        saleScenarioResultAnimalsSelected:
          filteredSaleScenarioResultAnimalsSelected,
        saleScenarioResultAnimalsSelectedIds:
          filteredSaleScenarioResultAnimalsSelectedIds,
      };
    case Types.SHOW_OR_HIDE_DRAWER_EDIT_SALE_SCENARIO:
      return {
        ...state,
        showDrawerEditParameter: !state.showDrawerEditParameter,
      };
    case Types.SHOW_EDIT_SALE_SCENARIO:
      return {
        ...state,
        showDrawerEditParameter: true,
        loadingRequestEditParameter: true,
      };
    case Types.SHOW_EDIT_SALE_SCENARIO_SUCCESS:
      const editMaxDeadLineGoal =
        action.payload.data?.saleScenarioParam?.finalSimulationDate;
      const editMinDeadLineGoal =
        action.payload.data?.saleScenarioParam?.initialSimulationDate;
      return {
        ...state,
        newSaleScenario: action.payload.data,
        showDrawerEditParameter: true,
        maxDeadLineGoal: editMaxDeadLineGoal,
        minDeadLineGoal: editMinDeadLineGoal,
        loadingRequestEditParameter: false,
      };
    case Types.SHOW_EDIT_SALE_SCENARIO_ERROR:
      return {
        ...state,
        showDrawerEditParameter: false,
        loadingRequestEditParameter: false,
      };
    case Types.SHOW_OR_HIDE_DRAWER_DETAILS_SALE_SCENARIO:
      return {
        ...state,
        showDrawerDetailsParameter: !state.showDrawerDetailsParameter,
      };
    case Types.REFRESH_TABLE:
      return {
        ...INITIAL_STATE,
        refreshTable: !state.refreshTable,
      };
    case Types.SET_COMPARE_SALE_SCENARIOS:
      localStorage.setItem(
        "@Bovexo:saleScenarioCompare",
        JSON.stringify(action.payload.dataCompare)
      );
      return {
        ...state,
        compareRequestData: action.payload.dataCompare,
        compareSaleScenarioResults: INITIAL_STATE.compareSaleScenarioResults,
      };
    case Types.SET_COMPARE_SALE_SCENARIOS_RESULTS:
      return {
        ...state,
        compareSaleScenarioResults: action.payload.data,
      };
    default:
      return state;
  }
}

function validateTabs(state, index) {
  const { newSaleScenario } = state;
  if (index === "tab2") {
    if (newSaleScenario.saleScenarioLots.length === 0) {
      notification.error({
        message:
          "Você precisa selecionar pelo menos um lote para ir para o próximo passo.",
      });
      return false;
    }
  }

  if (index === "tab3") {
    if (newSaleScenario.saleScenarioLots.length === 0) {
      notification.error({
        message:
          "Você precisa selecionar pelo menos um lote para ir para o próximo passo.",
      });
      return false;
    } else {
      if (
        newSaleScenario.saleScenarioLots.filter(
          (ssl) => ssl.dietStrategies.length === 0
        ).length > 0
      ) {
        notification.error({
          message:
            "Você precisa selecionar pelo menos uma estratégia para cada lote selecionado, para ir para o próximo passo.",
        });
        return false;
      }
    }
  }

  return true;
}

function calculateMaxDeadLineGoal(saleScenarioLots) {
  if (
    saleScenarioLots.length > 0 &&
    saleScenarioLots.filter((ssl) => ssl.dietStrategies.length > 0).length > 0
  ) {
    let dietStrategys = [];
    /** Get all dietStrategies from all lots selected */
    saleScenarioLots.forEach((sl) => {
      sl.dietStrategies.forEach((ds) => {
        if (!dietStrategys.find((ds2) => ds2.id === ds.id)) {
          dietStrategys.push(ds);
        }
      });
    });
    /** Get the longest dietStrategy */
    let longestDS = null;
    let sizeOfTheLongestDS = 0;
    dietStrategys.forEach((ds) => {
      let sizePeriods = ds.dietPeriods.length;
      let oldestStartDay = ds.dietPeriods[0].startDay;
      let newestEndDay = ds.dietPeriods[sizePeriods - 1].endDay;
      let amountDays = newestEndDay - oldestStartDay;

      if (sizeOfTheLongestDS === 0) {
        sizeOfTheLongestDS = amountDays;
        longestDS = ds;
      } else if (amountDays > sizeOfTheLongestDS) {
        sizeOfTheLongestDS = amountDays;
        longestDS = ds;
      }
    });
    const maxDeadLineGoal =
      longestDS.dietPeriods[longestDS.dietPeriods.length - 1].endDate;

    return maxDeadLineGoal;
  } else {
    return null;
  }
}

function calculateMinDeadLineGoal(saleScenarioLots) {
  if (
    saleScenarioLots.length > 0 &&
    saleScenarioLots.filter((ssl) => ssl.dietStrategies.length > 0).length > 0
  ) {
    let dietStrategys = [];
    /** Get all dietStrategies from all lots selected */
    saleScenarioLots.forEach((sl) => {
      sl.dietStrategies.forEach((ds) => {
        if (!dietStrategys.find((ds2) => ds2.id === ds.id)) {
          dietStrategys.push(ds);
        }
      });
    });
    let minimumDate = null;
    dietStrategys.forEach((ds) => {
      let firtPeriodStartDate = ds.dietPeriods[0].startDate;
      if (minimumDate === null) {
        minimumDate = firtPeriodStartDate;
      } else {
        if (moment(firtPeriodStartDate).isBefore(moment(minimumDate))) {
          minimumDate = firtPeriodStartDate;
        }
      }
    });
    return minimumDate;
  } else {
    return null;
  }
}
