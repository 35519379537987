import { useCallback } from "react";
import { useSelector } from "react-redux";

export default function useAuthentication() {
  const {
    user: { roles: userRoles },
  } = useSelector((state) => state.app);

  const isAllowed = useCallback(
    (roles, reverse) => {
      return userRoles?.includes("Admin")
        ? true
        : roles.length === 0
        ? true
        : reverse === false
        ? roles.some((r) => userRoles?.includes(r))
        : !roles.some((r) => userRoles?.includes(r));
    },
    [userRoles]
  );

  return { isAllowed };
}
