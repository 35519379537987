import React, { useRef, useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSelector } from "react-redux";

// Components
import { Container, Footer } from "./styles";
import { Row, Col, Input, notification, Spin, Select } from "antd";
import ButtonStandard from "../../utils/button";
import useSemenContext from "../../../hooks/useSemenContext";
import { getBreedIndexActive } from "../../../services/breedService";

// Services
import { save } from "../../../services/semenService";

const validationSchema = Yup.object({
  name: Yup.string().trim().max(30).required(),
  registration: Yup.string().trim().nullable().max(20),
  breedId: Yup.string().trim().required(),
});

const DrawerSemen = () => {
  const formRef = useRef(null);

  const [form, setForm] = useState({
    id: null,
    name: "",
    registration: "",
    breedId: null,
  });
  const [breeds, setBreeds] = useState([]);
  const [isLoadingBreeds, setIsLoadingBreeds] = useState(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  // Redux Variable
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const { isDrawerVisible, data, closeDrawer, fetchData } = useSemenContext();

  useEffect(() => {
    if (data !== null) {
      setForm(data);
    }
  }, [data]);

  // Method

  async function getBreeds() {
    setIsLoadingBreeds(true);
    try {
      const {
        data: { results },
      } = await getBreedIndexActive({
        groupId,
        farmId,
        withoutBreedCurveParam: true,
      });
      setBreeds(results);
    } catch (error) {
    } finally {
      setIsLoadingBreeds(false);
    }
  }

  function handleCloseDrawer() {
    setForm({
      id: null,
      name: "",
      registration: "",
      breedId: null,
    });
    formRef.current.resetForm({
      id: null,
      name: "",
      registration: "",
      breedId: null,
    });
    closeDrawer();
  }

  async function handleSubmitForm(values) {
    setIsLoadingRequest(true);
    try {
      await save({ groupId, farmId, id: values?.id, body: values });
      notification.success({
        message: "Sêmen cadastrado/atualizado com sucesso.",
      });
      fetchData();
      handleCloseDrawer();
    } catch (error) {
      notification.error({
        message: "Erro",
        description: "Erro ao cadastrar/editar sêmen",
      });
    } finally {
      setIsLoadingRequest(false);
    }
  }

  function handleDrawerVisible(visible) {
    if (visible) {
      getBreeds();
      if (data === null) {
        formRef.current.resetForm({
          id: null,
          name: "",
          registration: "",
          breedId: null,
        });
      }
    }
  }

  return (
    <Container
      title={
        data?.id == null
          ? translation.semen.form.titleCreateNewSemen
          : translation.semen.form.titleEditSemen
      }
      width={700}
      onClose={handleCloseDrawer}
      maskClosable={false}
      visible={isDrawerVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Spin spinning={isLoadingRequest}>
        <Formik
          ref={formRef}
          enableReinitialize={true}
          initialValues={form}
          onSubmit={handleSubmitForm}
          validationSchema={validationSchema}
          render={({
            values,
            errors,
            submitCount,
            setFieldValue,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="drawerForm">
                {errors.length > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                {/* Name */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={errors.name && submitCount > 0 ? "error" : ""}
                  >
                    {translation.semen.form.name}*
                  </label>
                </Row>
                <Row type="flex">
                  <Input
                    name="name"
                    value={values.name}
                    placeholder={translation.defaultPlaceholder}
                    autoCapitalize="off"
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                  />
                </Row>
                {/* Registry */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={
                      errors.registration && submitCount > 0 ? "error" : ""
                    }
                  >
                    {translation.semen.form.registration}
                  </label>
                </Row>
                <Row type="flex">
                  <Input
                    name="registration"
                    value={values.registration}
                    placeholder={translation.defaultPlaceholder}
                    autoCapitalize="off"
                    onChange={(e) => {
                      setFieldValue("registration", e.target.value);
                    }}
                  />
                </Row>
                {/* BreedId */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={errors.breedId && submitCount > 0 ? "error" : ""}
                  >
                    {translation.semen.form.breedId}*
                  </label>
                </Row>
                <Row>
                  <Select
                    style={{ width: "100%" }}
                    name="breedId"
                    loading={isLoadingBreeds}
                    placeholder={translation.defaultSelectPlaceholder}
                    value={
                      breeds.length === 0
                        ? undefined
                        : values.breedId || undefined
                    }
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      setFieldValue("breedId", value);
                    }}
                  >
                    {breeds != null &&
                      Object.entries(breeds).length > 0 &&
                      breeds.map((breed) => (
                        <Select.Option key={breed.id} value={breed.id}>
                          {breed.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Row>
              </div>
              <Footer>
                <Row type="flex">
                  <Col span={24} className="buttonsDiv">
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={closeDrawer}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard type="submit" buttonType="type6">
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
      </Spin>
    </Container>
  );
};

export default DrawerSemen;
