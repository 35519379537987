import { call, put } from "redux-saga/effects";
import { Creators as PastureActions } from "../ducks/pasture";
import { buildPastureWithStructuredSeasons } from "../../utils/pastureBuilder";
/** Services */
import {
  getPastureIndex,
  getPastureShow,
  savePasture,
  deletePastureServ,
} from "../../services/pastureService";
import { notification } from "antd";

export function* indexPasture(action) {
  try {
    const {
      data: { results: allPastures },
    } = yield call(getPastureIndex, action.payload);
    yield put(PastureActions.indexPastureSuccess(allPastures));
  } catch (error) {
    yield put(PastureActions.indexPastureError(error));
  }
}

export function* getPasture(action) {
  try {
    const {
      data: { results: pasture },
    } = yield call(getPastureShow, action.payload);

    const result = buildPastureWithStructuredSeasons(pasture);

    yield put(PastureActions.showPastureSuccess(result));
  } catch (error) {
    yield put(PastureActions.showPastureError(error));
  }
}

export function* storeOrUpdatePasture(action) {
  let result = null;
  try {
    const {
      data: { results: newPasture },
    } = yield call(savePasture, action.payload);
    result = newPasture;
    const { saveOptions } = action.payload;
    yield put(PastureActions.savePastureSuccess(newPasture, saveOptions));
    if (action.payload.id == null) {
      notification.success({ message: "Forrageira criada com sucesso" });
    } else {
      notification.success({ message: "Forrageira atualizada com sucesso" });
    }
  } catch (error) {
    if (action.payload.id == null) {
      notification.error({ message: "Forrageira não foi criada" });
    } else {
      notification.error({ message: "Forrageira não foi atualizada" });
    }
    yield put(PastureActions.savePastureError(error));
  }

  if (result != null && Object.entries(result).length > 0) {
    const { groupId, farmId } = action.payload;
    yield put(PastureActions.indexPasture(groupId, farmId));
  }
}

export function* deletePasture(action) {
  try {
    const { data } = yield call(deletePastureServ, action.payload);
    notification.success({
      message: "Forrageira inativada com sucesso",
    });
    yield put(PastureActions.deletePastureSuccess(data));
    const { groupId, farmId } = action.payload;
    yield put(PastureActions.indexPasture(groupId, farmId));
  } catch (error) {
    notification.error({ message: "Forrageira não foi inativada" });
    yield put(PastureActions.deletePastureError(error));
  }
}
