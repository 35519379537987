import React from "react";

// import { Container } from './styles';

const BookmarkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.868"
    height="16.545"
    viewBox="0 0 12.868 16.545"
  >
    <path
      id="Path_2052"
      data-name="Path 2052"
      d="M5.059,0A1.559,1.559,0,0,0,3.5,1.559V16.545l5.515-3.677,5.515,3.677V1.838A1.844,1.844,0,0,1,16.368,0Z"
      transform="translate(-3.5)"
      fill="#769bb5"
    />
  </svg>
);
export default BookmarkIcon;
