import React, { useEffect, useState } from "react";

// Redux
import { useSelector } from "react-redux";

// Libs
import NumberFormat from "react-number-format";
import {
  notification,
  Row,
  Col,
  Input,
  Radio,
} from "antd";

import { Container, Footer } from "../styles";

//Components
import InfoTooltip from "../../../utils/infoTooltip";
import ButtonStandard from "../../../utils/button";
import DocumentInput from "../../../utils/input/documentInput";

// Services
import {
  validateCNPJ,
  validateCPF,
} from "../../../../services/helpersMethodsService";

const FormFarmOwnerDrawer = ({
  ownerId,
  ownerData,
  onSave,
  onCancel,
  drawerVisible, }) => {

  /** Redux variables */
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const { Group: RadioGroup } = Radio;

  const initialStateForm = {
    id: null,
    code: null,
    name: null,
    documentType: "CPF",
    document: "",
    participation: null,
    mainOwner: false,
  };

  // State
  const [form, setForm] = useState(initialStateForm);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if(drawerVisible){
      if(ownerData) {
        setForm(ownerData);
      } else {
        setForm(initialStateForm);
      }
      setErrors([]);
    }
  }, [drawerVisible]);

  function validateForm() {
    let aErrors = [];
    if (form.name === null || form.name.trim() === "") {
      aErrors.push("name");
    }
    if (form.document === null || form.document.trim() === "") {
      aErrors.push("document");
    } else {
      if (form.documentType === "CNPJ" && !validateCNPJ(form.document)) {
        aErrors.push("document");
        notification.error({
          message: "CNPJ incorreto !",
          description: "Por favor, informe um CNPJ válido.",
        });
      } else if (form.documentType === "CPF" && !validateCPF(form.document)) {
        aErrors.push("document");
        notification.error({
          message: "CPF incorreto !",
          description: "Por favor, informe um CPF válido.",
        });
      }
    }
    if (form.participation === null || form.participation === 0 || form.participation > 100) {
      aErrors.push("participation");
    }
    if (form.mainOwner === null) {
      aErrors.push("mainOwner");
    }

    setErrors(aErrors)
    return aErrors.length > 0 ? false : true;
  }

  function handleSubmit() {
    if (validateForm()) {
      const body = { ...form, id: ownerId };
      onSave(body);
    }
  }

  return (
    <Container
      title={ownerId !== null ? "Editar Proprietário" : "Novo Proprietário"}
      width={640}
      onClose={onCancel}
      maskClosable={false}
      visible={drawerVisible}
    >
      <div className="drawerForm">
        <Row type="flex">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <span className="subTitle">
              Preencha os campos abaixo para cadastrar um proprietário
            </span>
          </Col>
        </Row>
        {errors.length > 0 && (
          <Row type="flex" justify="center" align="middle">
            <label className="error">{translation.error.formError}</label>
          </Row>
        )}

        {/* Code & Name */}
        <Row className="rowLabel" type="flex" gutter={8} justify="space-between" align="middle">
          {/* Code */}
          <Col xs={12} sm={12} md={12} lg={8} xl={8}>
            <label className={errors.includes("code") ? "error" : ""}>
              Código
            </label>
            <Input
              name="code"
              value={form?.code != null ? form?.code : undefined}
              type="code"
              maxLength={10}
              autoComplete="chrome-off"
              placeholder={translation.defaultPlaceholder}
              onChange={(e) => {
                setForm({ ...form, code: e.target.value });
                setErrors(errors.filter((e) => e !== "code"));
              }}
            />
          </Col>

          {/* Name */}
          <Col xs={12} sm={12} md={12} lg={16} xl={16}>
            <label className={errors.includes("name") ? "error" : ""}>
              Nome*
            </label>
            <Input
              name="name"
              value={form?.name != null ? form?.name : undefined}
              type="name"
              maxLength={35}
              autoComplete="chrome-off"
              placeholder={translation.defaultPlaceholder}
              onChange={(e) => {
                setForm({ ...form, name: e.target.value });
                setErrors(errors.filter((e) => e !== "name"));
              }}
            />
          </Col>
        </Row>

        {/* Document & Participation & MainOwner */}
        <Row className="rowLabel" type="flex" gutter={8} justify="space-between" align="middle">
          {/* Document */}
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <label className={errors.includes("document") ? "error" : ""}>
              CPF/CNPJ*
            </label>
            <DocumentInput
              kind="mixed"
              autoComplete="chrome-off"
              placeholder={
                translation.supplier.form.documentPlaceHolder
              }
              value={form?.document}
              name="document"
              onChange={(e) => {
                let value = e.target.value
                  ?.replace(/\./g, "")
                  .replace(/\//g, "")
                  .replace(/-/g, "");
                setForm({ ...form, document: value, documentType: value.length <= 11 ? "CPF" : "CNPJ" });
                setErrors(errors.filter((e) => e !== "document"));
              }}
            />
          </Col>

          {/* Participation */}
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <label className={errors.includes("participation") ? "error" : ""}>
              Participação*
            </label>
            <NumberFormat
              name="participation"
              customInput={Input}
              value={form?.participation}
              decimalScale={1}
              decimalSeparator=","
              thousandSeparator="."
              maxLength={6}
              fixedDecimalScale={true}
              addonAfter="%"
              placeholder={translation.defaultPlaceholder}
              onValueChange={
                ({ floatValue }) => {
                  if (floatValue > 100) {
                    setForm({ ...form, participation: 100 });
                    setErrors(errors.filter((e) => e !== "participation"));
                  } else {
                    setForm({ ...form, participation: floatValue });
                    setErrors(errors.filter((e) => e !== "participation"));
                  }
                }}
            />
          </Col>

          {/* Main Owner */}
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <label className={errors.includes("mainOwner") ? "error" : ""}>
              Principal ?*
            </label>
            <InfoTooltip
              title={"Proprietário principal da fazenda, a ser utilizado como padrão em lançamentos."}
            />
            <RadioGroup
              name="mainOwner"
              value={form?.mainOwner}
              onChange={(e) => {
                setForm({
                  ...form,
                  mainOwner: e.target.value,
                });
                setErrors(
                  errors.filter(
                    (e) => e !== "mainOwner"
                  )
                );
              }}
            >
              <Radio value={true}>Sim</Radio>
              <Radio value={false}>Não</Radio>
            </RadioGroup>
          </Col>
        </Row>
      </div>

      <Footer>
        <Row type="flex">
          <Col span={24} className="buttonsDiv">
            <ButtonStandard
              type="button"
              buttonType="type7"
              onClick={onCancel}
            >
              {translation.buttons.cancel}
            </ButtonStandard>

            <ButtonStandard
              type="button"
              buttonType="type6"
              onClick={handleSubmit}
            >
              {translation.buttons.save}
            </ButtonStandard>
          </Col>
        </Row>
      </Footer>
    </Container>
  );
}

export default FormFarmOwnerDrawer;