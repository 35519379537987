import React from "react";

// import { Container } from './styles';

const SunIcon = () => (
  <svg
    id="sun"
    xmlns="http://www.w3.org/2000/svg"
    width="20.09"
    height="20.09"
    viewBox="0 0 20.09 20.09"
  >
    <g
      id="Group_2287"
      data-name="Group 2287"
      transform="translate(4.996 4.996)"
    >
      <g id="Group_2286" data-name="Group 2286">
        <path
          id="Path_1889"
          data-name="Path 1889"
          d="M83.81,76.688A5.066,5.066,0,0,0,80.249,75.2a4.957,4.957,0,0,0-3.561,1.488,5.049,5.049,0,0,0,3.561,8.61A4.956,4.956,0,0,0,83.81,83.81,5.066,5.066,0,0,0,85.3,80.249,4.956,4.956,0,0,0,83.81,76.688Zm-.957,6.165a3.722,3.722,0,1,1,1.063-2.6A3.676,3.676,0,0,1,82.853,82.853Z"
          transform="translate(-75.2 -75.2)"
          fill="#d44c4c"
        />
      </g>
    </g>
    <g
      id="Group_2289"
      data-name="Group 2289"
      transform="translate(16.662 9.354)"
    >
      <g id="Group_2288" data-name="Group 2288">
        <path
          id="Path_1890"
          data-name="Path 1890"
          d="M253.537,140.8h-2.046a.691.691,0,1,0,0,1.382h2.046a.691.691,0,0,0,0-1.382Z"
          transform="translate(-250.8 -140.8)"
          fill="#d44c4c"
        />
      </g>
    </g>
    <g
      id="Group_2291"
      data-name="Group 2291"
      transform="translate(9.354 16.662)"
    >
      <g id="Group_2290" data-name="Group 2290">
        <path
          id="Path_1891"
          data-name="Path 1891"
          d="M141.491,250.8a.7.7,0,0,0-.691.691v2.046a.691.691,0,0,0,1.382,0v-2.046A.7.7,0,0,0,141.491,250.8Z"
          transform="translate(-140.8 -250.8)"
          fill="#d44c4c"
        />
      </g>
    </g>
    <g
      id="Group_2293"
      data-name="Group 2293"
      transform="translate(14.523 14.523)"
    >
      <g id="Group_2292" data-name="Group 2292">
        <path
          id="Path_1892"
          data-name="Path 1892"
          d="M221.218,220.261l-1.462-1.462a.676.676,0,1,0-.957.957l1.462,1.462a.676.676,0,0,0,.957-.957Z"
          transform="translate(-218.6 -218.6)"
          fill="#d44c4c"
        />
      </g>
    </g>
    <g id="Group_2295" data-name="Group 2295" transform="translate(9.354)">
      <g id="Group_2294" data-name="Group 2294">
        <path
          id="Path_1893"
          data-name="Path 1893"
          d="M141.491,0a.7.7,0,0,0-.691.691V2.737a.691.691,0,0,0,1.382,0V.691A.7.7,0,0,0,141.491,0Z"
          transform="translate(-140.8 0)"
          fill="#d44c4c"
        />
      </g>
    </g>
    <g id="Group_2297" data-name="Group 2297" transform="translate(14.55 2.75)">
      <g id="Group_2296" data-name="Group 2296">
        <path
          id="Path_1894"
          data-name="Path 1894"
          d="M221.618,41.6a.674.674,0,0,0-.957,0L219.2,43.061a.676.676,0,1,0,.957.957l1.462-1.462A.674.674,0,0,0,221.618,41.6Z"
          transform="translate(-219 -41.4)"
          fill="#d44c4c"
        />
      </g>
    </g>
    <g id="Group_2299" data-name="Group 2299" transform="translate(0 9.354)">
      <g id="Group_2298" data-name="Group 2298">
        <path
          id="Path_1895"
          data-name="Path 1895"
          d="M2.737,140.8H.691a.7.7,0,0,0-.691.691.686.686,0,0,0,.691.691H2.737a.691.691,0,0,0,0-1.382Z"
          transform="translate(0 -140.8)"
          fill="#d44c4c"
        />
      </g>
    </g>
    <g
      id="Group_2301"
      data-name="Group 2301"
      transform="translate(2.724 14.523)"
    >
      <g id="Group_2300" data-name="Group 2300">
        <path
          id="Path_1896"
          data-name="Path 1896"
          d="M43.618,218.8a.673.673,0,0,0-.957,0L41.2,220.261a.676.676,0,1,0,.957.957l1.462-1.462A.674.674,0,0,0,43.618,218.8Z"
          transform="translate(-41 -218.6)"
          fill="#d44c4c"
        />
      </g>
    </g>
    <g id="Group_2303" data-name="Group 2303" transform="translate(2.724 2.75)">
      <g id="Group_2302" data-name="Group 2302">
        <path
          id="Path_1897"
          data-name="Path 1897"
          d="M43.618,43.061,42.156,41.6a.676.676,0,0,0-.957.957l1.462,1.462a.676.676,0,1,0,.957-.957Z"
          transform="translate(-41 -41.4)"
          fill="#d44c4c"
        />
      </g>
    </g>
  </svg>
);
export default SunIcon;
