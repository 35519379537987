import React, { useCallback, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import useAnimalFarmReproductiveProtocolContext from "../../../hooks/useAnimalFarmReproductiveProtocolContext";

import {
  Button,
  DatePicker,
  Dropdown,
  Icon,
  Input,
  Menu,
  Row,
  Select,
  Table,
  notification,
} from "antd";
import MenuIcon from "../../../components/utils/table/icons/menu";
import { Container as MenuContainer } from "../../../components/utils/table/menu/styles";
import CustomLink from "../../../components/utils/customLink";
// Services
import { deleteAnimalFarmReproductiveProtocol } from "../../../services/animalFarmReproductiveProtocolService";

const AnimalFarmReproductiveProtocolTable = () => {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { dataList, isLoadingDataList, fetchData } =
    useAnimalFarmReproductiveProtocolContext();

  const handleDelete = useCallback(
    async (record) => {
      try {
        await deleteAnimalFarmReproductiveProtocol({
          groupId,
          farmId,
          id: record.id,
        });
        notification.success({
          message: "Lançamento do protocolo foi excluído com sucesso.",
        });
        fetchData();
      } catch (error) {
        notification.error({
          message: "Erro ao excluir lançamento de protocolo.",
        });
      }
    },
    [groupId, farmId, fetchData]
  );

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetDateSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 6,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Row style={{ marginBottom: 5, marginLeft: 5 }}>
          <Select
            style={{ width: 70 }}
            name="filterType"
            value={
              selectedKeys
                ? selectedKeys[0]?.type != null
                  ? selectedKeys[0]?.type
                  : "="
                : "="
            }
            placeholder={translation.defaultSelectPlaceholder}
            onChange={(value) => {
              setSelectedKeys(
                value
                  ? [{ ...selectedKeys[0], type: value }]
                  : [{ ...selectedKeys[0] }]
              );
            }}
          >
            <Select.Option key={"Lesser"} value={"Lesser"}>
              {"<"}
            </Select.Option>
            <Select.Option key={"LesserOrEqual"} value={"LesserOrEqual"}>
              {"<="}
            </Select.Option>
            <Select.Option key={"Equal"} value={"Equal"}>
              {"="}
            </Select.Option>
            <Select.Option key={"GreaterOrEqual"} value={"GreaterOrEqual"}>
              {">="}
            </Select.Option>
            <Select.Option key={"Greater"} value={"Greater"}>
              {">"}
            </Select.Option>
          </Select>

          <DatePicker
            allowClear={true}
            value={
              selectedKeys
                ? selectedKeys[0]?.value != null
                  ? selectedKeys[0]?.value
                  : null
                : null
            }
            style={{ width: 175, marginBottom: 8 }}
            format={"DD/MM/YYYY"}
            onChange={(date, dateString) => {
              setSelectedKeys(
                date
                  ? [
                      {
                        type: selectedKeys ? selectedKeys[0]?.type || "=" : "=",
                        value: date,
                      },
                    ]
                  : []
              );
            }}
          />
        </Row>
        <Row>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </Row>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon
        type="calendar"
        style={{ color: filtered ? "#684e94" : undefined }}
      />
    ),
    onFilter: (filterValue, record) => {
      if (filterValue.type) {
        if (filterValue.type === "Equal") {
          return moment(record[dataIndex], "YYYY-MM-DD").isSame(
            filterValue.value,
            "days",
            "[]"
          );
        } else if (filterValue.type === "Lesser")
          return moment(record[dataIndex], "YYYY-MM-DD").isBefore(
            filterValue.value,
            "days",
            "[]"
          );
        else if (filterValue.type === "LesserOrEqual")
          return moment(record[dataIndex], "YYYY-MM-DD").isSameOrBefore(
            filterValue.value,
            "days",
            "[]"
          );
        else if (filterValue.type === "Greater")
          return moment(record[dataIndex], "YYYY-MM-DD").isAfter(
            filterValue.value,
            "days",
            "[]"
          );
        else
          return moment(record[dataIndex], "YYYY-MM-DD").isSameOrAfter(
            filterValue.value,
            "days",
            "[]"
          );
      }
    },
  });

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            placeholder={translation.defaultPlaceholder}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return record[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    }),
    [handleReset, handleSearch, translation.defaultPlaceholder]
  );

  const menu = (id, record) => {
    return (
      <Menu>
        <Menu.Item key="1" onClick={() => handleDelete(record)}>
          {translation.table.menu.delete}
        </Menu.Item>
      </Menu>
    );
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Table
      loading={isLoadingDataList}
      rowKey="id"
      size="small"
      dataSource={dataList}
      scroll={{
        x: true,
      }}
      pagination={{
        hideOnSinglePage:
          dataList !== null && dataList.length > 10 ? false : true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
      }}
    >
      <Table.Column
        title={
          translation.animalFarmReproductiveProtocol.table.columns
            .handlingNumber
        }
        dataIndex="handlingNumber"
        sorter={(first, second) => {
          return first.handlingNumber.localeCompare(
            second.handlingNumber,
            "pt-BR",
            {
              numeric: false,
              ignorePunctuation: true,
            }
          );
        }}
        key="handlingNumber"
        {...handleGetColumnSearchProps("handlingNumber")}
        render={(text, record) => (
          <CustomLink to={`/admin/animals/${record.animalId}`}>
            {text}
          </CustomLink>
        )}
      />
      <Table.Column
        title={
          translation.animalFarmReproductiveProtocol.table.columns
            .dateApplication
        }
        dataIndex="dateApplication"
        sorter={(first, second) => {
          return first.dateApplication.localeCompare(
            second.dateApplication,
            "pt-BR",
            {
              numeric: false,
              ignorePunctuation: true,
            }
          );
        }}
        key="dateApplication"
        {...handleGetDateSearchProps("dateApplication")}
        render={(text) => <span>{moment(text).format("DD/MM/YYYY")}</span>}
      />
      <Table.Column
        title={
          translation.animalFarmReproductiveProtocol.table.columns
            .reproductiveProtocolName
        }
        dataIndex="reproductiveProtocolName"
        sorter={(first, second) => {
          return first.reproductiveProtocolName.localeCompare(
            second.reproductiveProtocolName,
            "pt-BR",
            {
              numeric: false,
              ignorePunctuation: true,
            }
          );
        }}
        key="reproductiveProtocolName"
        {...handleGetColumnSearchProps("reproductiveProtocolName")}
      />
      <Table.Column
        title={
          translation.animalFarmReproductiveProtocol.table.columns
            .reproductionPeriodDescription
        }
        dataIndex="reproductionPeriodDescription"
        sorter={(first, second) => {
          return first.reproductionPeriodDescription.localeCompare(
            second.reproductionPeriodDescription,
            "pt-BR",
            {
              numeric: false,
              ignorePunctuation: true,
            }
          );
        }}
        key="reproductionPeriodDescription"
        {...handleGetColumnSearchProps("reproductionPeriodDescription")}
      />
      <Table.Column
        title={
          translation.animalFarmReproductiveProtocol.table.columns.lotOriginName
        }
        dataIndex="lotOriginName"
        sorter={(first, second) => {
          return first.lotOriginName.localeCompare(
            second.lotOriginName,
            "pt-BR",
            {
              numeric: false,
              ignorePunctuation: true,
            }
          );
        }}
        key="lotOriginName"
        {...handleGetColumnSearchProps("lotOriginName")}
      />
      <Table.Column
        title={
          translation.animalFarmReproductiveProtocol.table.columns
            .lotDestinationName
        }
        dataIndex="lotDestinationName"
        sorter={(first, second) => {
          if (first.lotDestinationName == null) {
            first.lotDestinationName = "";
          }
          return first.lotDestinationName.localeCompare(
            second.lotDestinationName,
            "pt-BR",
            {
              numeric: false,
              ignorePunctuation: true,
            }
          );
        }}
        key="lotDestinationName"
        {...handleGetColumnSearchProps("lotDestinationName")}
      />
      <Table.Column
        title=""
        align="left"
        render={(_, record) =>
          record.lastReproductiveProtocol ? (
            <Dropdown
              overlay={menu(record.id, record)}
              trigger={["click"]}
              key={record.id}
            >
              <MenuContainer>
                <MenuIcon />
              </MenuContainer>
            </Dropdown>
          ) : null
        }
      />
    </Table>
  );
};

export default AnimalFarmReproductiveProtocolTable;
