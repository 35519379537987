import React from "react";

// import { Container } from './styles';

const NoteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.461"
    height="13.467"
    viewBox="0 0 13.461 13.467"
  >
    <g
      id="Group_2574"
      data-name="Group 2574"
      transform="translate(-1007.027 -340.638)"
    >
      <circle
        id="Ellipse_165"
        data-name="Ellipse 165"
        cx="1"
        cy="1"
        r="1"
        transform="translate(1010 346)"
        fill="#fe8d2a"
      />
      <circle
        id="Ellipse_166"
        data-name="Ellipse 166"
        cx="1"
        cy="1"
        r="1"
        transform="translate(1013 346)"
        fill="#fe8d2a"
      />
      <circle
        id="Ellipse_167"
        data-name="Ellipse 167"
        cx="1"
        cy="1"
        r="1"
        transform="translate(1016 346)"
        fill="#fe8d2a"
      />
      <path
        id="Path_1113"
        data-name="Path 1113"
        d="M1.106-8.526A6.688,6.688,0,0,0-3.654-10.5a6.689,6.689,0,0,0-4.76,1.974,6.72,6.72,0,0,0-1.962,4.438A6.7,6.7,0,0,0-8.976.357a2,2,0,0,1-.805.887.886.886,0,0,0-.484.927.884.884,0,0,0,.74.742,3.358,3.358,0,0,0,.5.038,4.4,4.4,0,0,0,2.335-.706,6.761,6.761,0,0,0,3.038.722A6.687,6.687,0,0,0,1.106,1a6.687,6.687,0,0,0,1.971-4.76A6.69,6.69,0,0,0,1.106-8.526ZM-7.616.52a.654.654,0,0,0-.11-.691A5.442,5.442,0,0,1-7.5-7.615,5.4,5.4,0,0,1-3.654-9.206,5.4,5.4,0,0,1,.189-7.615a5.445,5.445,0,0,1,0,7.691h0A5.4,5.4,0,0,1-3.654,1.668,5.43,5.43,0,0,1-6.406.921l0,0A.657.657,0,0,0-6.733.831a.662.662,0,0,0-.394.132l0,0a3.291,3.291,0,0,1-1.045.548A5.04,5.04,0,0,0-7.616.52Z"
        transform="translate(1017.411 351.138)"
        fill="#fe8d2a"
      />
    </g>
  </svg>
);
export default NoteIcon;
