import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as PicketActions } from "../../../../store/ducks/picket";
import { Row, Col, Icon, Table, notification } from "antd";
import {
  CustomDrawerAllocateLot,
  Container,
  Footer,
  Body,
  Title,
  SubTitle,
  SearchInput,
  AlertCard
} from "./styles";
// Components
import ButtonStandard from "../../../utils/button";
// Services
import { getLotIndex } from "../../../../services/lotService";
import { getProfitCenterShow } from "../../../../services/profitCenterService";

const SearchCustom = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <g id="magnifying-glass" transform="translate(0 6)">
      <path
        id="Path_672"
        data-name="Path 672"
        d="M14.92,14.235,11.274,10.59a6.4,6.4,0,1,0-.632.632l3.646,3.645A.452.452,0,0,0,14.6,15a.438.438,0,0,0,.316-.133A.45.45,0,0,0,14.92,14.235ZM.945,6.4a5.5,5.5,0,1,1,5.5,5.5A5.5,5.5,0,0,1,.945,6.4Z"
        transform="translate(-0.05 -6)"
        fill="#c4c4c4"
      />
    </g>
  </svg>
);

class DrawerAllocateLot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lots: null,
      selectedRowKeys: [],
      lotSelected: null,
      searchInput: null,
      loading: false
    };
  }

  fetchData = async (
    groupSelected,
    farmSelected,
    page = 0,
    sorter = {},
    filters = "",
    size = 10
  ) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    this.setState({ loading: true });
    try {
      const {
        data: { results: lots }
      } = await getLotIndex({
        groupId,
        farmId,
        page,
        sorter,
        filters,
        size
      });

      this.setState({
        lots,
        loading: false,
        selectedRowKeys: [],
        lotSelected: null
      });
    } catch (error) {
      this.setState({
        loading: false
      });
    }
  };

  closeDrawer = () => {
    const { picketActions } = this.props;
    picketActions.hideDrawerAllocateLot();
  };

  openNotificationError = (title, message) => {
    notification["error"]({
      message: title,
      description: message
    });
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, lotSelected: selectedRows[0] });
  };

  handleTableChange = async (pagination, filters, sorter) => {
    this.setState({
      paginationInfo: pagination,
      sortedInfo: sorter,
      filteredInfo: filters
    });
    const {
      app: { groupSelected, farmSelected }
    } = this.props;

    let search = "is_receipt=false;status='A'";

    await this.fetchData(
      groupSelected,
      farmSelected,
      pagination.current,
      sorter,
      search,
      pagination.pageSize
    );
  };

  handleSearchInputPressEnter = e => {
    let search = "is_receipt=false;status='A'";
    const {
      app: { groupSelected, farmSelected }
    } = this.props;
    const { searchInput } = this.state;
    if (searchInput.length > 0) {
      search = `${search};name='${searchInput}'`;
    }
    this.fetchData(groupSelected, farmSelected, 0, {}, search);
  };

  handleSeachInputChange = e => {
    let search = "is_receipt=false;status='A'";
    const {
      app: { groupSelected, farmSelected }
    } = this.props;
    this.setState({ searchInput: e.target.value });
    if (e.target.value.length === 0) {
      this.fetchData(groupSelected, farmSelected, 0, {}, search);
    }
  };

  handleAllocateLot = async () => {
    let {
      app: {
        translation,
        groupSelected: { id: groupId },
        farmSelected: { id: farmId }
      },
      picket: {
        picketData: { id, productionSubSystem }
      },
      picketActions
    } = this.props;
    const {
      lotSelected: { id: lotId, profitCenterId }
    } = this.state;

    const {
      data: { results: pcLot }
    } = await getProfitCenterShow({
      groupId,
      farmId,
      id: profitCenterId
    });

    productionSubSystem =
      productionSubSystem === "E"
        ? "Extensive"
        : productionSubSystem === "SI"
        ? "SemiIntensive"
        : "Intensive";

    if (productionSubSystem !== pcLot.productionSubSystem) {
      this.openNotificationError(
        translation.picket.form.error
          .productionSubSystemDifferentFromPicketProductionSubSystem.title,
        translation.picket.form.error
          .productionSubSystemDifferentFromPicketProductionSubSystem.message
      );
    } else {
      await picketActions.addLotToPicket(groupId, farmId, id, lotId);
    }
  };

  handleDrawerVisible = async visible => {
    if (visible) {
      const {
        app: { groupSelected, farmSelected }
      } = this.props;
      if (
        Object.entries(groupSelected).length > 0 &&
        Object.entries(farmSelected).length > 0
      ) {
        const search = "is_receipt=false;status='A'";
        await this.fetchData(groupSelected, farmSelected, 0, {}, search);
      }
    }
  };

  render() {
    const {
      app: { translation },
      picket: { picketData, drawerAllocateLot }
    } = this.props;
    const {
      lots,
      selectedRowKeys,
      searchInput,
      loading,
      lotSelected
    } = this.state;
    const { Column } = Table;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      type: "radio"
    };
    return (
      <CustomDrawerAllocateLot
        title={translation.picket.drawerAllocateLotTitle}
        width={768}
        onClose={this.closeDrawer}
        maskClosable={false}
        visible={drawerAllocateLot}
        afterVisibleChange={this.handleDrawerVisible}
      >
        {picketData != null ? (
          <Container>
            <Body>
              <Row type="flex" justify="start">
                <span className="message">
                  {translation.picket.drawerAllocateLotSubTitle}
                </span>
              </Row>
              <Row type="flex" justify="start">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Title>
                    {`${translation.picket.table.columns.picketName}: `}
                    <span className="picketName">{picketData.name}</span>
                  </Title>
                  {picketData.status === "B" ? (
                    <SubTitle borderLeft="#4A85AE" color="#4A85AE">
                      {translation.picket.status.break}
                    </SubTitle>
                  ) : picketData.status === "P" ? (
                    <SubTitle borderLeft="#106518" color="#106518">
                      {translation.picket.status.pasturage}
                    </SubTitle>
                  ) : picketData.status === "R" ? (
                    <SubTitle borderLeft="#FF4E00" color="#FF4E00">
                      {translation.picket.status.renovation}
                    </SubTitle>
                  ) : picketData.status === "M" ? (
                    <SubTitle borderLeft="#D44C4C" color="#D44C4C">
                      {translation.picket.status.maintenance}
                    </SubTitle>
                  ) : picketData.status === "L" ? (
                    <SubTitle borderLeft="#FE8D2A" color="#FE8D2A">
                      {translation.picket.status.leased}
                    </SubTitle>
                  ) : (
                    <SubTitle borderLeft="#D44C4C" color="#D44C4C">
                      {translation.picket.status.deleted}
                    </SubTitle>
                  )}
                </Col>
              </Row>
              <Row type="flex" justify="start">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <SearchInput
                    name="searchInput"
                    placeholder={translation.picket.searchInputPlaceHolder}
                    value={searchInput}
                    prefix={<Icon component={SearchCustom} />}
                    onChange={this.handleSeachInputChange}
                    onPressEnter={this.handleSearchInputPressEnter}
                  />
                </Col>
              </Row>
              {lotSelected != null &&
              picketData.profitCenterId !== lotSelected.profitCenterId ? (
                <AlertCard>
                  <Icon
                    type="exclamation-circle"
                    theme="filled"
                    style={{ color: "#d44c4c" }}
                    className="iconAlert"
                  />
                  <span>
                    {translation.picket.alertLotWithProfitCenterDifferent}
                  </span>
                </AlertCard>
              ) : null}
              <Row type="flex" justify="space-between">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Table
                    rowKey="id"
                    size="small"
                    rowSelection={rowSelection}
                    loading={loading}
                    dataSource={lots != null ? lots.content : []}
                    pagination={{
                      total: lots != null ? lots.totalElements : 0,
                      size: lots != null ? lots.size : 0,
                      current: lots != null ? lots.number + 1 : 0,
                      showSizeChanger: true,
                      pageSizeOptions: [
                        "10",
                        "20",
                        "30",
                        "100",
                        "500",
                        "1000",
                        "2000"
                      ],
                      hideOnSinglePage:
                        lots !== null && Object.entries(lots).length !== 0
                          ? lots.totalElements > 10
                            ? false
                            : true
                          : true
                    }}
                    scroll={{
                      x: true
                    }}
                    style={{ width: "100%", margin: "0" }}
                    onChange={this.handleTableChange}
                  >
                    <Column
                      title={translation.lot.production.table.columns.lotName}
                      dataIndex="name"
                      sorter
                      key="name"
                      align="left"
                    />
                    <Column
                      title="Picket"
                      dataIndex="picketName"
                      sorter
                      key="picketName"
                      align="left"
                    />
                    <Column
                      title={
                        translation.lot.production.table.columns.profitCenter
                      }
                      dataIndex="profitCenterName"
                      sorter
                      key="profitCenterName"
                      align="left"
                    />
                    <Column
                      title={translation.lot.production.table.columns.amount}
                      dataIndex="currentAmountAnimals"
                      key="currentAmountAnimals"
                      align="left"
                      sorter
                      sortDirections={["descend", "ascend"]}
                    />
                  </Table>
                </Col>
              </Row>
            </Body>
            {/* Footer */}
            <Footer>
              <Row type="flex" justify="end">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className="buttonsDiv"
                >
                  <ButtonStandard
                    type="button"
                    buttonType="type7"
                    onClick={this.closeDrawer}
                  >
                    {translation.buttons.cancel}
                  </ButtonStandard>

                  <ButtonStandard
                    type="button"
                    buttonType="type6"
                    disabled={lotSelected != null ? false : true}
                    onClick={this.handleAllocateLot}
                  >
                    {translation.buttons.allocateLot}
                  </ButtonStandard>
                </Col>
              </Row>
            </Footer>
          </Container>
        ) : null}
      </CustomDrawerAllocateLot>
    );
  }
}

const mapStateToProps = state => ({
  app: state.app,
  picket: state.picket
});

const mapDispatchToProps = dispatch => ({
  picketActions: bindActionCreators(PicketActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrawerAllocateLot);
