import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as SupplementActions } from "../../../store/ducks/supplement";
import { Creators as SupplierActions } from "../../../store/ducks/supplier";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Row,
  Col,
  Input,
  Select,
  Switch,
  notification,
  Modal,
  Icon,
  Button,
  Spin,
  Radio,
} from "antd";
import NumberFormat from "react-number-format";
import ButtonStandard from "../../utils/button";
import AlertIcon from "./icons/alert";
import SuccessIcon from "./icons/success";
import PencilIcon from "../../utils/icons/pencil";
import PlusBlueIcon from "../../utils/icons/plus/blue";
import NoteIcon from "../../utils/icons/note";
import {
  Container,
  Footer,
  CustomDivider,
  Table,
  SupplementInfoCard,
  RadioGroupCustom,
  RadioButtonCustom,
  Bold,
} from "./styles";
import DrawerSupplementSustenanceCapacity from "./supplementSustenanceCapacity";
import DrawerSupplier from "../supplier";
import InfoTooltip from "../../utils/infoTooltip";

import { getSupplierShow } from "../../../services/supplierService";

class DrawerSupplement extends Component {
  constructor(props) {
    super(props);
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;
    this.state = {
      formSupplement: {
        id: null,
        groupId,
        farmId,
        name: "",
        importCode: "",
        type: "Supplement",
        classification: "MineralEnergyProtein",
        industrialName: "",
        supplierId: null,
        cost: null,
        cp: null,
        tdn: null,
        dryMatterPercentage: null,
        nitrogen: null,
        phosphorus: null,
        zinc: null,
        sodium: null,
        calcium: null,
        proportion: null,
        status: "Active",
        additiveEffect: null,
        additiveEffectUnit: "G_DAY",
        fda: null,
        lignin: null,
        ee: null,
      },
      sustenanceCapacityData: {
        showMix: false,
        minimumVoluntaryConsumption: null,
        maximumVoluntaryConsumption: null,
        voluntaryConsumptionType: "G_ANIMAL",
        minimumPure: null,
        minimumPureTrough: null,
        maximumPure: null,
        maximumPureTrough: null,
        pureConsumptionType: "G_ANIMAL",
        minimumMix: null,
        minimumMixTrough: null,
        maximumMix: null,
        maximumMixTrough: null,
        mixConsumptionType: "G_ANIMAL",
        mixRatio: null,
        status: "NI",
      },
      saveOptions: "only_save",
      suppliers: [],
      drawerSupplementSustenanceCapacityVisible: false,
      onlyReadSupplier: null,
    };
    this.formik = React.createRef();
  }

  shouldComponentUpdate(nextProps) {
    return true;
  }

  getSuppliers = async (groupSelected, farmSelected, refresh = false) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    const {
      supplierActions,
      supplier: { listDrowpDown: suppliers },
    } = this.props;

    if (refresh === true || suppliers?.length === 0) {
      supplierActions.getDropdownSuppliers(groupId, farmId, "Supplement", true);
    }
  };

  closeDrawer = () => {
    const { supplementActions } = this.props;
    supplementActions.hideDrawer();
  };

  cleanDrawer = () => {};

  openNotificationError = (title, message) => {
    notification["error"]({
      message: title,
      description: message,
    });
  };

  handleDrawerVisible = async (visible) => {
    const {
      app: {
        groupSelected,
        farmSelected,
        user: { isInputSupplier },
      },
    } = this.props;
    if (visible) {
      if (
        Object.entries(groupSelected).length > 0 &&
        Object.entries(farmSelected).length > 0 &&
        !isInputSupplier
      ) {
        await Promise.all([this.getSuppliers(groupSelected, farmSelected)]);
      }
    } else {
      this.formik.current.resetForm({
        id: null,
        groupId: groupSelected.id,
        farmId: farmSelected.id,
        name: "",
        importCode: "",
        type: "Supplement",
        classification: "MineralEnergyProtein",
        industrialName: "",
        supplierId: null,
        cost: null,
        cp: null,
        tdn: null,
        dryMatterPercentage: null,
        nitrogen: null,
        phosphorus: null,
        zinc: null,
        sodium: null,
        calcium: null,
        proportion: null,
        status: "Active",
        additiveEffect: null,
        additiveEffectUnit: "G_DAY",
        fda: null,
        lignin: null,
        ee: null,
      });
      this.setState({
        formSupplement: {
          id: null,
          groupId: groupSelected.id,
          farmId: farmSelected.id,
          name: "",
          importCode: "",
          type: "Supplement",
          classification: "MineralEnergyProtein",
          industrialName: "",
          supplierId: null,
          cost: null,
          cp: null,
          tdn: null,
          dryMatterPercentage: null,
          nitrogen: null,
          phosphorus: null,
          zinc: null,
          sodium: null,
          calcium: null,
          proportion: null,
          status: "Active",
          additiveEffect: null,
          additiveEffectUnit: "G_DAY",
          fda: null,
          lignin: null,
          ee: null,
        },
        sustenanceCapacityData: {
          showMix: false,
          minimumVoluntaryConsumption: null,
          maximumVoluntaryConsumption: null,
          voluntaryConsumptionType: "G_ANIMAL",
          minimumPure: null,
          minimumPureTrough: null,
          maximumPure: null,
          maximumPureTrough: null,
          pureConsumptionType: "G_ANIMAL",
          minimumMix: null,
          minimumMixTrough: null,
          maximumMix: null,
          maximumMixTrough: null,
          mixConsumptionType: "G_ANIMAL",
          mixRatio: null,
          status: "NI",
        },
      });
    }
  };

  handleSubmitModalForm = async (values, actions) => {
    const {
      app: {
        translation,
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
        user: { isInputSupplier },
      },
      supplement: { sustenanceCapacityData: savedSustenanceCapacity },
    } = this.props;
    const { sustenanceCapacityData: stateSustenanceCapacity } = this.state;

    if (values.groupId == null && values.farmId == null) {
      values.groupId = groupId;
      values.farmId = farmId;
    }
    if (values.baseSupplement && !isInputSupplier) {
      values.referenceSupplementId = values.id;
      values.id = null;
    }

    let sustenanceCapacityData =
      stateSustenanceCapacity.status === "I"
        ? stateSustenanceCapacity
        : savedSustenanceCapacity;

    if (sustenanceCapacityData && sustenanceCapacityData.status === "NI") {
      this.openNotificationError(
        "Dados incompletos",
        translation.supplement.errorNecessarySustenanceCapacity
      );
    } else if (
      sustenanceCapacityData &&
      sustenanceCapacityData.status === "I"
    ) {
      const body = {
        id: values.id,
        groupId: values.groupId,
        farmId: values.farmId,
        name: values.name,
        importCode: values.importCode,
        type: values.type,
        classification:
          values.type === "Ration_Mixture"
            ? "MineralEnergyProtein"
            : values.classification,
        industrialName: values.industrialName,
        supplierId: values.supplierId,
        cost: values.cost,
        proportion: values.proportion,
        cp: values.cp,
        tdn: values.tdn,
        npn: values.npn,
        dryMatterPercentage: values.dryMatterPercentage,
        nitrogen: values.nitrogen,
        phosphorus: values.phosphorus,
        zinc: values.zinc,
        sodium: values.sodium,
        calcium: values.calcium,
        status: values.status,
        referenceSupplementId: values.referenceSupplementId,
        minimumVoluntaryConsumption:
          sustenanceCapacityData.minimumVoluntaryConsumption,
        maximumVoluntaryConsumption:
          sustenanceCapacityData.maximumVoluntaryConsumption,
        voluntaryConsumptionType:
          sustenanceCapacityData.voluntaryConsumptionType,
        minimumPure:
          values.type === "Ration_Mixture"
            ? null
            : sustenanceCapacityData.minimumPure,
        minimumPureTrough:
          values.type === "Ration_Mixture"
            ? null
            : sustenanceCapacityData.minimumPureTrough,
        maximumPure:
          values.type === "Ration_Mixture"
            ? null
            : sustenanceCapacityData.maximumPure,
        maximumPureTrough:
          values.type === "Ration_Mixture"
            ? null
            : sustenanceCapacityData.maximumPureTrough,
        pureConsumptionType:
          values.type === "Ration_Mixture"
            ? "G_ANIMAL"
            : sustenanceCapacityData.pureConsumptionType,
        minimumMix:
          values.type === "Supplement"
            ? null
            : sustenanceCapacityData.minimumMix,
        minimumMixTrough:
          values.type === "Supplement"
            ? null
            : sustenanceCapacityData.minimumMixTrough,
        maximumMix:
          values.type === "Supplement"
            ? null
            : sustenanceCapacityData.maximumMix,
        maximumMixTrough:
          values.type === "Supplement"
            ? null
            : sustenanceCapacityData.maximumMixTrough,
        mixConsumptionType:
          values.type === "Supplement"
            ? "G_ANIMAL"
            : sustenanceCapacityData.mixConsumptionType,
        mixRatio:
          values.type === "Supplement" ? null : sustenanceCapacityData.mixRatio,
        additiveEffect:
          values.classification === "Mineral" ? values.additiveEffect : null,
        additiveEffectUnit:
          values.classification === "Mineral"
            ? values.additiveEffectUnit
            : "G_DAY",
        fda: values.fda,
        lignin: values.lignin,
        ee: values.classification !== "Mineral" ? values.ee : null,
      };

      const title = "Ao confirmar, os itens abaixo devem ser considerados:";
      const content = (
        <ul>
          <li style={{ marginBottom: 26, marginTop: 25 }}>
            Atualize as informações de Suplemento/Concent. em todas as dietas em{" "}
            <Bold>Edição de "Estratégia de dieta", </Bold>
            caso contrário, todas as estratégias estarão considerando os valores
            antigos deste Suplemento/Concent.
          </li>
          <li style={{ marginBottom: 26 }}>
            Após finalizar o passo acima, <Bold>reprocesse</Bold> todas os
            Cenários de venda - <Bold>Favoritos</Bold> que utilizam as
            <Bold> estratégias acima como baseline,</Bold> caso contrário, todos
            os cenários estarão considerando os valores antigos deste
            Suplemento/Concent.
          </li>
          <li>
            Para os <Bold>Cenários de compra,</Bold> você deve atualizar a
            estratégia de dieta e reprocessar o cenário em{" "}
            <Bold>Edição de Cenário de compra</Bold>, caso contrário, todas as
            estratégias e cenários de compra estarão considerando os valores
            antigos deste Suplemento/Concent.
          </li>
        </ul>
      );
      if (body.npn > 420 && body.classification === "Mineral") {
        Modal.error({
          title: "Erro ao informar campo",
          content: translation.supplement.errorWrongNpnInput,
          width: 580,
          centered: true,
        });
        actions.setFieldError("npn", "Wrong value");
        return;
      } else {
        if (body.id === null) {
          if (!isInputSupplier) {
            Modal.confirm({
              title,
              content,
              width: 580,
              icon: <Icon type="reload" />,
              centered: true,
              onOk: () => this.handleNewSupplement(body),
            });
          } else {
            this.handleNewSupplement(body);
          }
        } else {
          if (!isInputSupplier) {
            Modal.confirm({
              title,
              content,
              width: 580,
              icon: <Icon type="reload" />,
              centered: true,
              onOk: () => this.handleUpdateSupplement(body, body.id),
            });
          } else {
            this.handleUpdateSupplement(body, body.id);
          }
        }
      }
    } else if (sustenanceCapacityData == null) {
      this.openNotificationError(
        "Dados incompletos",
        translation.supplement.errorNecessarySustenanceCapacity
      );
    }
  };

  handleNewSupplement = (supplement) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
        user: { isInputSupplier },
      },
      supplementActions,
    } = this.props;
    let saveOptions = this.state.saveOptions === "only_save" ? false : true;
    supplementActions.saveSupplement(
      groupId,
      farmId,
      null,
      supplement,
      saveOptions,
      isInputSupplier
    );
  };

  handleUpdateSupplement = (supplement, id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
        user: { isInputSupplier },
      },
      supplementActions,
    } = this.props;
    let saveOptions = this.state.saveOptions === "only_save" ? false : true;
    supplementActions.saveSupplement(
      groupId,
      farmId,
      id,
      supplement,
      saveOptions,
      isInputSupplier
    );
  };

  handleSaveSustenanceCapacity = (data) => {
    const { supplementActions } = this.props;
    if (data != null) {
      supplementActions.updateSupplementSustenanceCapacityData(data);
      this.setState({
        sustenanceCapacityData: data,
        drawerSupplementSustenanceCapacityVisible: false,
      });
    }
  };

  handleCancelSustenanceCapacity = () => {
    this.setState({
      drawerSupplementSustenanceCapacityVisible: false,
    });
  };

  handleCreateSupplier = () => {
    const { supplierActions } = this.props;

    supplierActions.showDrawer("new_supplement_supplier");
  };

  /* Method will be use in button on SustenanceCapacity */
  handleEditSustenanceCapacity = (
    supplement,
    sustenanceCapacityData,
    blockEdit
  ) => {
    this.setState({
      sustenanceCapacityData: {
        ...sustenanceCapacityData,
        showMix: supplement.type !== "Supplement",
        blockEdit,
      },
      drawerSupplementSustenanceCapacityVisible: true,
    });
  };

  handleLoadSupplier = async (groupId, farmId, supplierId) => {
    const {
      data: { results },
    } = await getSupplierShow({ groupId: groupId, id: supplierId });
    this.setState({ onlyReadSupplier: results.name });
  };

  selectAdditiveEffectUnits = (props, blockEdit) => {
    return (
      <Select
        disabled={blockEdit}
        value={props.values.additiveEffectUnit}
        style={{ width: 90 }}
        onChange={(value) => props.setFieldValue("additiveEffectUnit", value)}
      >
        <Select.Option value="G_DAY">g/dia</Select.Option>
        <Select.Option value="PERCENTAGE_PB">% PB</Select.Option>
      </Select>
    );
  };

  render() {
    const {
      app: {
        translation,
        groupSelected,
        farmSelected,
        user: { isInputSupplier },
      },
      supplement: {
        supplementData,
        isLoadingRequestSaveOrUpdate,
        sustenanceCapacityData: savedSustenanceCapacity,
        drawerVisible,
      },
      supplier: {
        listDrowpDown: suppliers,
        isLoadingDropDown: isLoadingDropDownSuppliers,
      },
    } = this.props;

    const {
      formSupplement,
      drawerSupplementSustenanceCapacityVisible,
      sustenanceCapacityData: stateSustenanceCapacity,
      onlyReadSupplier,
    } = this.state;

    const blockEdit =
      supplementData &&
      (supplementData.referenceSupplementId != null ||
        supplementData.baseSupplement);

    let block = null;
    if (supplementData && supplementData.supplierId && suppliers?.length > 0) {
      block =
        suppliers.find((supplier) => {
          if (supplier.id === supplementData.supplierId) {
            return true;
          }
          return false;
        }) == null
          ? true
          : false;

      if (block && onlyReadSupplier == null) {
        this.handleLoadSupplier(
          groupSelected.id,
          null,
          supplementData.supplierId
        );
      }
    }
    const blockSupplier = block;

    let sustenanceCapacityData =
      supplementData != null
        ? savedSustenanceCapacity
        : stateSustenanceCapacity;

    const validationSchema = Yup.object().shape({
      name: Yup.string().required(),
      supplierId: !isInputSupplier ? Yup.string().required() : null,
      classification: Yup.string().required(),
      cost: Yup.number().required(),
      tdn: Yup.number().required(),
      cp: Yup.number().required(),
      dryMatterPercentage: Yup.number().min(0).max(100).required(),
    });

    const { Option } = Select;

    return (
      <Container
        title={
          supplementData != null
            ? translation.supplement.drawerTitleEdit
            : translation.supplement.drawerTitle
        }
        width={600}
        onClose={this.closeDrawer}
        maskClosable={false}
        visible={drawerVisible}
        afterVisibleChange={this.handleDrawerVisible}
      >
        <Formik
          ref={this.formik}
          enableReinitialize={true}
          initialValues={
            supplementData != null ? supplementData : formSupplement
          }
          onSubmit={this.handleSubmitModalForm}
          validationSchema={validationSchema}
          render={(props) => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <Spin spinning={isLoadingRequestSaveOrUpdate}>
                <div className="drawerForm">
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <span style={{ fontSize: "14px", color: "#000" }}>
                        {supplementData != null
                          ? translation.supplement.drawerSubTitleEdit
                          : translation.supplement.drawerSubTitle}
                      </span>
                    </Col>
                  </Row>
                  {/* Supplement Visibility */}
                  {!isInputSupplier && (
                    <Row
                      type="flex"
                      style={{ marginTop: "13px" }}
                      align="middle"
                    >
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row>
                          <label>
                            {translation.supplement.form.groupFarmVisibility}
                          </label>
                        </Row>
                        <Row>
                          <RadioGroupCustom
                            value={
                              props.values.groupId != null &&
                              props.values.farmId != null
                                ? "farm"
                                : props.values.groupId != null &&
                                  props.values.farmId == null
                                ? "group"
                                : "farm"
                            }
                            buttonStyle="solid"
                          >
                            <RadioButtonCustom
                              value="farm"
                              onChange={(e) => {
                                props.setFieldValue(
                                  "groupId",
                                  groupSelected.id
                                );
                                props.setFieldValue("farmId", farmSelected.id);
                              }}
                            >
                              {translation.supplier.form.radiogroup.farm}
                            </RadioButtonCustom>
                            <RadioButtonCustom
                              value="group"
                              onChange={(e) => {
                                props.setFieldValue(
                                  "groupId",
                                  groupSelected.id
                                );
                                props.setFieldValue("farmId", null);
                              }}
                            >
                              {translation.supplement.form.radiogroup.group}:
                              {groupSelected.name}
                            </RadioButtonCustom>
                          </RadioGroupCustom>
                        </Row>
                      </Col>
                    </Row>
                  )}
                  {supplementData != null ? (
                    <Row
                      type="flex"
                      style={{ marginTop: "13px" }}
                      justify="space-between"
                      align="middle"
                    >
                      <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                        <label
                          style={{
                            color: "#9074bf",
                            fontWeight: "bold",
                          }}
                        >
                          {translation.supplement.form.status}
                        </label>
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6} align="right">
                        {props.values.status === "Active" ? (
                          <span
                            style={{
                              color: "#A9C133",
                              fontFamily: "Asap",
                              fontWeight: "normal",
                              marginRight: 6,
                            }}
                          >
                            {translation.status.active}
                          </span>
                        ) : (
                          <span
                            style={{
                              color: "#D44C4C",
                              fontFamily: "Asap",
                              fontWeight: "normal",
                              marginRight: 6,
                            }}
                          >
                            {translation.status.inactive}
                          </span>
                        )}
                        <Switch
                          checked={
                            props.values.status === "Active" ? true : false
                          }
                          onChange={(checked, event) => {
                            props.setValues({
                              ...props.values,
                              status: checked ? "Active" : "Inactive",
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  ) : null}
                  {/* Errors */}
                  {Object.entries(props.errors).length > 0 &&
                    props.submitCount > 0 && (
                      <Row type="flex" justify="center" align="middle">
                        <label className="error">
                          {translation.error.formError}
                        </label>
                      </Row>
                    )}
                  {/* Supplement type */}
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label>
                          {translation.supplement.form.supplementType}*
                          <InfoTooltip
                            title={
                              translation.supplement.form.supplementTypeHelp
                            }
                          />
                        </label>
                      </Row>
                      <Row>
                        <Radio.Group
                          onChange={(e) => {
                            props.setFieldValue("type", e.target.value);
                            if (e.target.value === "Ration_Mixture") {
                              props.setFieldValue(
                                "classification",
                                "MineralEnergyProtein"
                              );
                            } else {
                              props.setFieldValue("classification", null);
                            }
                            this.setState({
                              sustenanceCapacityData: {
                                ...this.state.sustenanceCapacityData,
                                showMix: e.target.value !== "Supplement",
                              },
                            });
                          }}
                          value={props.values.type}
                        >
                          <Radio
                            style={{ color: "#4b4b4b", fontWeight: "normal" }}
                            value={"Ration_Mixture"}
                          >
                            Ração/Mistura
                          </Radio>
                          <Radio
                            style={{ color: "#4b4b4b", fontWeight: "normal" }}
                            value={"Supplement"}
                          >
                            Suplemento
                          </Radio>
                        </Radio.Group>
                      </Row>
                    </Col>
                  </Row>
                  {/* Supplement Classification */}
                  {props.values.type === "Supplement" && (
                    <Row
                      type="flex"
                      style={{ marginTop: "13px" }}
                      align="middle"
                    >
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row>
                          <label
                            className={
                              props.errors.classification &&
                              props.submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            {
                              translation.supplement.form
                                .supplementClassification
                            }
                            *
                            <InfoTooltip
                              title={
                                <>
                                  <ol style={{ marginLeft: 16 }}>
                                    <li>
                                      <strong>
                                        {
                                          translation.supplement.form
                                            .supplementClassificationHelp
                                            .title_0
                                        }
                                        :
                                      </strong>
                                      {
                                        translation.supplement.form
                                          .supplementClassificationHelp
                                          .description_0
                                      }
                                    </li>
                                    <li>
                                      <strong>
                                        {
                                          translation.supplement.form
                                            .supplementClassificationHelp
                                            .title_1
                                        }
                                        :
                                      </strong>
                                      {
                                        translation.supplement.form
                                          .supplementClassificationHelp
                                          .description_1
                                      }
                                    </li>
                                    <li>
                                      <strong>
                                        {
                                          translation.supplement.form
                                            .supplementClassificationHelp
                                            .title_2
                                        }
                                        :
                                      </strong>
                                      {
                                        translation.supplement.form
                                          .supplementClassificationHelp
                                          .description_2
                                      }
                                    </li>
                                  </ol>
                                  <p>
                                    {
                                      translation.supplement.form
                                        .supplementClassificationHelp.source
                                    }
                                  </p>
                                </>
                              }
                            />
                          </label>
                        </Row>
                        <Row>
                          <Radio.Group
                            onChange={(e) => {
                              props.setFieldValue(
                                "classification",
                                e.target.value
                              );
                              if (e.target.value === "Mineral") {
                                props.setFieldValue("tdn", 0);
                              } else {
                                props.setFieldValue("npn", null);
                              }
                            }}
                            value={props.values.classification}
                          >
                            <Radio
                              style={{ color: "#4b4b4b", fontWeight: "normal" }}
                              value={"Mineral"}
                            >
                              Mineral
                            </Radio>
                            <Radio
                              style={{ color: "#4b4b4b", fontWeight: "normal" }}
                              value={"MineralProtein"}
                            >
                              Mineral Proteíco
                            </Radio>
                            <Radio
                              style={{ color: "#4b4b4b", fontWeight: "normal" }}
                              value={"MineralEnergyProtein"}
                            >
                              Mineral Proteíco / Energético
                            </Radio>
                          </Radio.Group>
                        </Row>
                      </Col>
                    </Row>
                  )}
                  {/* Input system identification */}
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.name && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.supplement.form.name}*
                        </label>
                      </Row>
                      <Row>
                        <Input
                          disabled={blockEdit}
                          placeholder={
                            translation.supplement.form.namePlaceHolder
                          }
                          value={props.values.name}
                          name="name"
                          autoComplete="chrome-off"
                          onChange={(e) => {
                            props.setFieldValue("name", e.target.value);
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  {/* Import code */}
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label>{translation.supplement.form.importCode}*</label>
                      </Row>
                      <Row>
                        <Input
                          disabled={blockEdit}
                          placeholder={translation.defaultPlaceholder}
                          value={props.values.importCode}
                          name="importCode"
                          autoComplete="chrome-off"
                          onChange={(e) => {
                            props.setFieldValue("importCode", e.target.value);
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  {/* Input additional data*/}
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label>
                          {translation.supplement.form.industrialName}
                        </label>
                      </Row>
                      <Row>
                        <Input
                          disabled={blockEdit}
                          placeholder={
                            translation.supplement.form
                              .industrialNamePlaceHolder
                          }
                          value={props.values.industrialName}
                          name="industrialName"
                          onChange={(e) => {
                            props.setFieldValue(
                              "industrialName",
                              e.target.value
                            );
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  {/* Supplier */}
                  {!isInputSupplier && (
                    <Row
                      type="flex"
                      style={{ marginTop: "13px" }}
                      align="middle"
                    >
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row>
                          <label
                            className={
                              props.errors.supplierId && props.submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            {translation.supplement.form.supplierName}*
                          </label>
                        </Row>
                        <Row type="flex" justify="start" align="middle">
                          {blockSupplier === true ? (
                            <Input
                              disabled={true}
                              value={onlyReadSupplier}
                              name="readOnlySupplier"
                              style={{ width: "80%" }}
                            />
                          ) : (
                            <Select
                              disabled={blockEdit || blockSupplier}
                              name="supplierId"
                              value={
                                suppliers != null &&
                                props.values.supplierId != null &&
                                isLoadingDropDownSuppliers === false
                                  ? props.values.supplierId
                                  : undefined
                              }
                              loading={isLoadingDropDownSuppliers}
                              style={{ width: "80%" }}
                              placeholder={
                                translation.supplement.form
                                  .supplierNamePlaceHolder
                              }
                              onChange={(value) => {
                                props.setFieldValue("supplierId", value);
                              }}
                            >
                              {suppliers != null &&
                                suppliers.map((supplier) => (
                                  <Option key={supplier.id} value={supplier.id}>
                                    {supplier.name}
                                  </Option>
                                ))}
                            </Select>
                          )}

                          <Button
                            disabled={blockEdit || blockSupplier}
                            shape="circle"
                            icon="plus"
                            style={{ marginLeft: 10 }}
                            onClick={this.handleCreateSupplier}
                          />
                        </Row>
                      </Col>
                    </Row>
                  )}
                  {/* Cost and Proportion */}
                  <Row
                    type="flex"
                    style={{ marginTop: "13px" }}
                    justify="start"
                  >
                    {/* Cost */}
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Row>
                        <label
                          className={
                            props.errors.cost && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          Custo*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          placeholder={
                            translation.supplement.form.costPlaceHolder
                          }
                          value={props.values.cost}
                          name="cost"
                          addonBefore="R$"
                          addonAfter="kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue(
                              "cost",
                              floatValue == null
                                ? null
                                : floatValue >= 0
                                ? floatValue > 10000
                                  ? 10000
                                  : floatValue
                                : 0
                            );
                          }}
                        />
                      </Row>
                    </Col>
                    {/* Proportion */}
                    {props.values.type === "Supplement" && (
                      <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
                        <Row>
                          <label>Proporção</label>
                        </Row>
                        <Row className="rowInput">
                          <NumberFormat
                            customInput={Input}
                            value={props.values.proportion}
                            placeholder={translation.defaultPlaceholder}
                            addonAfter="%"
                            name="proportion"
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale={true}
                            onValueChange={({ floatValue }) => {
                              props.setFieldValue(
                                "proportion",
                                floatValue == null
                                  ? null
                                  : floatValue >= 0
                                  ? floatValue > 100
                                    ? 100
                                    : floatValue
                                  : 0
                              );
                            }}
                          />
                        </Row>
                      </Col>
                    )}
                  </Row>
                  <CustomDivider dashed>
                    {translation.supplement.form.nutritionalDataDivider}
                  </CustomDivider>
                  {/* TDN AND CP */}
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    {/* TDN */}
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Row>
                        <label
                          className={
                            props.errors.tdn && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.supplement.form.tdn}*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          disabled={
                            blockEdit ||
                            props.values.classification === "Mineral"
                          }
                          customInput={Input}
                          placeholder={
                            translation.supplement.form.tdnPlaceHolder
                          }
                          value={props.values.tdn}
                          name="tdn"
                          addonAfter="g/kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue(
                              "tdn",
                              floatValue == null
                                ? null
                                : floatValue >= 0
                                ? floatValue > 1000
                                  ? 1000
                                  : floatValue
                                : 0
                            );
                          }}
                        />
                      </Row>
                    </Col>
                    {/* CP */}
                    <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
                      <Row>
                        <label
                          className={
                            props.errors.cp && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.supplement.form.cp}*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          disabled={blockEdit}
                          customInput={Input}
                          placeholder={
                            translation.supplement.form.cpPlaceHolder
                          }
                          value={props.values.cp}
                          name="cp"
                          addonAfter="g/kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue(
                              "cp",
                              floatValue == null
                                ? null
                                : floatValue >= 0
                                ? floatValue > 1000
                                  ? 1000
                                  : floatValue
                                : 0
                            );
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  {/* NPN & DRY MATTER PERCENTAGE */}
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    {/* NPN */}
                    {props.values.classification === "Mineral" && (
                      <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <Row>
                          <label
                            className={
                              props.errors.npn && props.submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            {translation.supplement.form.npn}
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <NumberFormat
                            customInput={Input}
                            placeholder={
                              translation.supplement.form.npnPlaceHolder
                            }
                            value={props.values.npn}
                            name="npn"
                            addonAfter="g/kg"
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale={true}
                            onValueChange={({ floatValue }) => {
                              props.setFieldValue(
                                "npn",
                                floatValue == null
                                  ? null
                                  : floatValue >= 0
                                  ? floatValue > 1000
                                    ? 1000
                                    : floatValue
                                  : 0
                              );
                            }}
                          />
                        </Row>
                      </Col>
                    )}
                    {/* DRY MATTER PERCENTAGE */}
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      offset={props.values.classification === "Mineral" ? 1 : 0}
                    >
                      <Row>
                        <label
                          className={
                            props.errors.dryMatterPercentage &&
                            props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.supplement.form.dryMatterPercentage}*
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          disabled={blockEdit}
                          customInput={Input}
                          placeholder={translation.defaultPlaceholder}
                          value={props.values.dryMatterPercentage}
                          name="dryMatterPercentage"
                          min={0}
                          max={100}
                          addonAfter="%MS"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue(
                              "dryMatterPercentage",
                              floatValue == null
                                ? null
                                : floatValue >= 0
                                ? floatValue > 100
                                  ? 100
                                  : floatValue
                                : 0
                            );
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  {/* CALCIUM, PHOSPHORUS AND NITROGEN */}
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Row>
                        <label>{translation.supplement.form.calcium}</label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          disabled={blockEdit}
                          customInput={Input}
                          placeholder={
                            translation.supplement.form.calciumPlaceHolder
                          }
                          value={props.values.calcium}
                          name="calcium"
                          addonAfter="g/kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue(
                              "calcium",
                              floatValue == null
                                ? null
                                : floatValue >= 0
                                ? floatValue > 1000
                                  ? 1000
                                  : floatValue
                                : 0
                            );
                          }}
                        />
                      </Row>
                    </Col>
                    <Col xs={7} sm={7} md={7} lg={7} xl={7} offset={1}>
                      <Row>
                        <label>{translation.supplement.form.phosphorus}</label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          disabled={blockEdit}
                          customInput={Input}
                          placeholder={
                            translation.supplement.form.phosphorusPlaceHolder
                          }
                          value={props.values.phosphorus}
                          name="phosphorus"
                          addonAfter="g/kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue(
                              "phosphorus",
                              floatValue == null
                                ? null
                                : floatValue >= 0
                                ? floatValue > 1000
                                  ? 1000
                                  : floatValue
                                : 0
                            );
                          }}
                        />
                      </Row>
                    </Col>
                    <Col xs={7} sm={7} md={7} lg={7} xl={7} offset={1}>
                      <Row>
                        <label>{translation.supplement.form.nitrogen}</label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          disabled={blockEdit}
                          customInput={Input}
                          placeholder={
                            translation.supplement.form.nitrogenPlaceHolder
                          }
                          value={props.values.nitrogen}
                          name="nitrogen"
                          addonAfter="mg/kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue(
                              "nitrogen",
                              floatValue == null
                                ? null
                                : floatValue >= 0
                                ? floatValue > 10000
                                  ? 10000
                                  : floatValue
                                : 0
                            );
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  {/* ZINC, SODIUM, ADDITIVE EFFECT */}
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Row>
                        <label>{translation.supplement.form.zinc}</label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          disabled={blockEdit}
                          customInput={Input}
                          placeholder={
                            translation.supplement.form.zincPlaceHolder
                          }
                          value={props.values.zinc}
                          name="zinc"
                          addonAfter="mg/kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue(
                              "zinc",
                              floatValue == null
                                ? null
                                : floatValue >= 0
                                ? floatValue > 10000
                                  ? 10000
                                  : floatValue
                                : 0
                            );
                          }}
                        />
                      </Row>
                    </Col>
                    <Col xs={7} sm={7} md={7} lg={7} xl={7} offset={1}>
                      <Row>
                        <label>{translation.supplement.form.sodium}</label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          disabled={blockEdit}
                          customInput={Input}
                          placeholder={
                            translation.supplement.form.sodiumPlaceHolder
                          }
                          value={props.values.sodium}
                          name="sodium"
                          addonAfter="g/kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue(
                              "sodium",
                              floatValue == null
                                ? null
                                : floatValue >= 0
                                ? floatValue > 1000
                                  ? 1000
                                  : floatValue
                                : 0
                            );
                          }}
                        />
                      </Row>
                    </Col>
                    {props.values.classification === "Mineral" && (
                      <Col xs={7} sm={7} md={7} lg={7} xl={7} offset={1}>
                        <Row>
                          <label>
                            {translation.supplement.form.additiveEffect}
                            <InfoTooltip
                              title={
                                translation.supplement.form.additiveEffectHelp
                              }
                            />
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <NumberFormat
                            disabled={blockEdit}
                            customInput={Input}
                            placeholder={
                              translation.supplement.form
                                .additiveEffectPlaceHolder
                            }
                            value={props.values.additiveEffect}
                            name="additiveEffect"
                            addonAfter={this.selectAdditiveEffectUnits(
                              props,
                              blockEdit
                            )}
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale={true}
                            maxLength={6}
                            onValueChange={({ floatValue }) => {
                              props.setFieldValue(
                                "additiveEffect",
                                props.values.additiveEffectUnit ===
                                  "PERCENTAGE_PB" && floatValue > 99
                                  ? 100
                                  : floatValue
                              );
                            }}
                          />
                        </Row>
                      </Col>
                    )}
                  </Row>
                  {/* FDA, LIGNIN, EE */}
                  <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Row>
                        <label>{translation.supplement.form.fda}</label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          disabled={blockEdit}
                          customInput={Input}
                          placeholder={
                            translation.supplement.form.fdaPlaceHolder
                          }
                          value={props.values.fda}
                          name="fda"
                          addonAfter="g/kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          maxLength={6}
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue(
                              "fda",
                              floatValue != null ? floatValue : 0
                            );
                          }}
                        />
                      </Row>
                    </Col>
                    <Col xs={7} sm={7} md={7} lg={7} xl={7} offset={1}>
                      <Row>
                        <label>{translation.supplement.form.lignin}</label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          disabled={blockEdit}
                          customInput={Input}
                          placeholder={
                            translation.supplement.form.ligninPlaceHolder
                          }
                          value={props.values.lignin}
                          name="lignin"
                          addonAfter="g/kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          maxLength={6}
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue(
                              "lignin",
                              floatValue != null ? floatValue : 0
                            );
                          }}
                        />
                      </Row>
                    </Col>
                    {props.values.classification !== "Mineral" && (
                      <Col xs={7} sm={7} md={7} lg={7} xl={7} offset={1}>
                        <Row>
                          <label>{translation.supplement.form.ee}</label>
                        </Row>
                        <Row className="rowInput">
                          <NumberFormat
                            disabled={blockEdit}
                            customInput={Input}
                            placeholder={
                              translation.supplement.form.eePlaceHolder
                            }
                            value={props.values.ee}
                            name="ee"
                            addonAfter="%MS"
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale={true}
                            onValueChange={({ floatValue }) => {
                              props.setFieldValue(
                                "ee",
                                floatValue != null
                                  ? floatValue > 100
                                    ? 100
                                    : floatValue
                                  : 0
                              );
                            }}
                          />
                        </Row>
                      </Col>
                    )}
                  </Row>
                  {/* Seasons */}
                  <CustomDivider dashed>
                    {translation.supplement.form.capacityDivider}
                  </CustomDivider>
                  <Row style={{ marginTop: "13px" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Table>
                        <tbody>
                          <tr>
                            <td>
                              <span>
                                {translation.supplement.form.sustenanceCapacity}
                                *
                              </span>
                            </td>
                            <td>
                              {sustenanceCapacityData.status === "NI" ? (
                                <span>
                                  <AlertIcon /> {translation.status.pendingData}
                                </span>
                              ) : (
                                <span className="success">
                                  <SuccessIcon />{" "}
                                  {translation.status.informedData}
                                </span>
                              )}
                            </td>
                            <td>
                              {sustenanceCapacityData.status === "NI" && (
                                <button
                                  className="add"
                                  type="button"
                                  onClick={() =>
                                    this.handleEditSustenanceCapacity(
                                      props.values,
                                      sustenanceCapacityData,
                                      blockEdit
                                    )
                                  }
                                >
                                  {translation.buttons.addInfo}
                                  <PlusBlueIcon />
                                </button>
                              )}
                              {sustenanceCapacityData.status === "I" && (
                                <button
                                  className="edit"
                                  type="button"
                                  style={{ marginLeft: "60px" }}
                                  onClick={() =>
                                    this.handleEditSustenanceCapacity(
                                      props.values,
                                      sustenanceCapacityData,
                                      blockEdit
                                    )
                                  }
                                >
                                  {translation.buttons.edit}
                                  <PencilIcon />
                                </button>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <SupplementInfoCard>
                    <NoteIcon /> {translation.supplement.form.information}
                  </SupplementInfoCard>
                </div>
                {/* Footer */}
                <Footer>
                  <Row type="flex" justify="space-between">
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="selectDiv"
                    >
                      {props.values.id === null ? (
                        <Select
                          value={this.state.saveOptions}
                          name="saveOptions"
                          onChange={(value) =>
                            this.setState({ saveOptions: value })
                          }
                        >
                          <Option key="1" value="only_save">
                            {translation.supplement.saveOptions.only_save}
                          </Option>
                          <Option key="2 " value="save_and_create_new">
                            {
                              translation.supplement.saveOptions
                                .save_and_create_new
                            }
                          </Option>
                        </Select>
                      ) : null}
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="buttonsDiv"
                    >
                      <ButtonStandard
                        type="button"
                        buttonType="type7"
                        onClick={this.closeDrawer}
                      >
                        {translation.buttons.cancel}
                      </ButtonStandard>

                      <ButtonStandard type="submit" buttonType="type6">
                        {translation.buttons.save}
                      </ButtonStandard>
                    </Col>
                  </Row>
                </Footer>
              </Spin>
            </form>
          )}
        />
        <DrawerSupplementSustenanceCapacity
          drawerVisible={drawerSupplementSustenanceCapacityVisible}
          data={stateSustenanceCapacity}
          onSave={this.handleSaveSustenanceCapacity}
          onCancel={this.handleCancelSustenanceCapacity}
        />
        <DrawerSupplier />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  supplement: state.supplement,
  supplier: state.supplier,
});

const mapDispatchToProps = (dispatch) => ({
  supplementActions: bindActionCreators(SupplementActions, dispatch),
  supplierActions: bindActionCreators(SupplierActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerSupplement);
