import { Col, Input, Radio, Row, Spin, notification } from "antd";
import React, { useEffect, useState } from "react";
import { FiMinus, FiPlus } from "react-icons/fi";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";

// Components
import ButtonStandard from "../../../../../../components/utils/button";
import { Container, CustomCollapse } from "./styles";

// Services
import { numberMask } from "../../../../../../services/helpersMethodsService";
import { indexAllWithApportionmentInfo } from "../../../../../../services/resultCenterService";

function ResultCenterApportionmentModal({
  isVisible,
  closeModal,
  saveModal,
  valueCurrency = "BRL",
  financialNatureId,
  transactionValue,
}) {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const [isLoading, setIsLoading] = useState(false);
  const [resultCenterList, setResultCenterList] = useState(null);
  const [errors, setErrors] = useState([]);

  const { Group: RadioGroup } = Radio;
  const { Panel } = CustomCollapse;

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const {
          data: { results },
        } = await indexAllWithApportionmentInfo({
          groupId,
          farmId,
          financialNatureId,
        });

        results.forEach((rc) => {
          rc.totalValue = transactionValue;
          rc.isEdited = false;
          rc.items.forEach(
            (rci) =>
              (rci.totalItemValue = (rci.customValue / 100) * transactionValue)
          );
        });

        setResultCenterList(results);
      } catch (error) {
        console.error(error);
        notification.error({
          title: "Erro ao carregar",
          message:
            "Não foi possível carregar informações dos centros de resultado. Contate o suporte",
        });
      } finally {
        setIsLoading(false);
      }
    }

    function clear() {
      setResultCenterList(null);
      setErrors([]);
    }

    if (isVisible) {
      fetchData();
    } else {
      clear();
    }
  }, [groupId, farmId, isVisible, financialNatureId, transactionValue]);

  const handleCancel = () => {
    closeModal();
  };

  const handleSave = () => {
    if (validate()) {
      saveModal(resultCenterList);
    }
  };

  function validate() {
    let aErrors = [];

    resultCenterList.forEach((rc) => {
      if (
        rc.apportionmentType === null ||
        rc.apportionmentType === undefined ||
        rc.apportionmentType.trim() === ""
      ) {
        aErrors.push({
          id: rc.id,
          name: rc.name,
          message: "Tipo de rateio não informado.",
        });
      } else {
        if (
          rc.items !== null &&
          rc.items !== undefined &&
          rc.items.length > 0
        ) {
          let total = +rc.items
            .reduce((total, item) => total + (item.customValue || 0), 0)
            .toFixed(2);
          if (total !== 100) {
            aErrors.push({
              id: rc.id,
              name: rc.name,
              message: "Somatório do rateio deve ser 100%.",
            });
          }
        }
      }
    });

    setErrors(aErrors);
    return aErrors.length > 0 ? false : true;
  }

  const handleNatureApportionmentTypeChange = (rcId, type) => {
    const rcList = [...resultCenterList];
    const resultCenter = rcList.find((rc) => rc.id === rcId);
    resultCenter.apportionmentType = type;
    resultCenter.isEdited = true;
    resultCenter.items.forEach((rc) => (rc.customValue = rc[`${type}Value`]));

    setResultCenterList(rcList);
  };

  const handleItemValueChange = (rcId, rciId, value) => {
    const rcList = [...resultCenterList];
    const resultCenter = rcList.find((rc) => rc.id === rcId);

    resultCenter.items.find((rci) => rci.id === rciId).totalItemValue =
      (value / 100) * transactionValue;
    resultCenter.totalValue = resultCenter.items.reduce(
      (a, b) => a + b.totalItemValue,
      0
    );
    resultCenter.isEdited = true;
    setResultCenterList(rcList);
  };

  const renderResultCenterItemNameLabel = (
    resultCenterItem,
    apportionmentType
  ) => {
    let name = resultCenterItem.name;
    if (name !== "Agricultura" && name !== "Outros") {
      if (apportionmentType === "numberOfAnimals") {
        name += ` (${resultCenterItem.amountAnimals} - cab)`;
      }

      if (apportionmentType === "area") {
        name += ` (${numberMask(resultCenterItem.areaSize)} - ha)`;
      }
    }
    return name;
  };

  const renderResultCenterCollapse = () => {
    return resultCenterList?.map((rc) => {
      const hasErrors = errors.find((e) => e.id === rc.id) !== -1;
      const errorMessage = errors.find((e) => e.id === rc.id)?.message;

      return (
        <div className="result-center-collapse-row" key={rc.id}>
          <Col span={24}>
            <CustomCollapse
              expandIconPosition={"right"}
              expandIcon={(panelProps) =>
                panelProps.isActive ? (
                  <FiMinus size={16} />
                ) : (
                  <FiPlus size={16} />
                )
              }
            >
              <Panel
                header={
                  <Row type="flex" align="middle" justify="start">
                    <div>
                      <strong>{rc.name}</strong>
                      {rc.isEdited === true ? (
                        <span className="result-center-edit-text">
                          (Editado)
                        </span>
                      ) : null}
                    </div>
                    {hasErrors === true ? (
                      <span className="message-error">{errorMessage}</span>
                    ) : null}
                  </Row>
                }
                key={rc.id}
              >
                {/* Apportionment Type */}
                <Row type="flex" justify="center" align="middle" gutter={16}>
                  <RadioGroup
                    name="apportionmentType"
                    value={rc.apportionmentType}
                    className="radio-label"
                    onChange={(e) => {
                      handleNatureApportionmentTypeChange(
                        rc.id,
                        e.target.value
                      );
                    }}
                  >
                    {rc.type !== "custom" ? (
                      <Radio className="vertical-radio" value="numberOfAnimals">
                        # Animais
                      </Radio>
                    ) : null}
                    {rc.type === "retreat" || rc.type === "activityType" ? (
                      <Radio className="vertical-radio" value="area">
                        Área
                      </Radio>
                    ) : null}
                    <Radio className="vertical-radio" value="percentage">
                      %
                    </Radio>
                  </RadioGroup>
                </Row>

                {/* Result center items with apportioment values */}
                {rc.items?.map((rci) => (
                  <Row
                    type="flex"
                    align="middle"
                    justify="start"
                    className="result-center-item-row"
                    key={rci.id}
                  >
                    <Col span={8}>
                      <span>
                        {renderResultCenterItemNameLabel(
                          rci,
                          rc.apportionmentType
                        )}
                      </span>
                    </Col>
                    <Col span={8}>
                      <NumberFormat
                        name="percentageValue"
                        customInput={Input}
                        value={rci.customValue}
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        maxLength={6}
                        placeholder={translation.defaultPlaceholder}
                        addonBefore={"% "}
                        onValueChange={({ floatValue }) => {
                          if (floatValue > 100) {
                            rci.customValue = 100;
                          } else {
                            rci.customValue = floatValue ? floatValue : 0;
                          }
                          handleItemValueChange(
                            rc.id,
                            rci.id,
                            floatValue ? floatValue : 0
                          );
                        }}
                      />
                    </Col>
                    <Col span={8} className="total-value">
                      <strong>
                        {numberMask(rci.totalItemValue, true, valueCurrency)}
                      </strong>
                    </Col>
                  </Row>
                ))}
                <Row type="flex" justify="end" align="middle">
                  <strong className="total-value">
                    {`Total: ${numberMask(rc.totalValue, true, valueCurrency)}`}
                  </strong>
                </Row>
              </Panel>
            </CustomCollapse>
          </Col>
        </div>
      );
    });
  };

  return (
    <Container
      title={
        <Row type="flex" justify="center">
          <strong>
            {
              translation.financial.transactions.modalResultCenterApportionment
                .title
            }
          </strong>
        </Row>
      }
      width={750}
      visible={isVisible}
      footer={null}
      centered
      closable={false}
    >
      <Spin spinning={isLoading}>
        <Row type="flex" justify="center" align="middle">
          {renderResultCenterCollapse()}
        </Row>
      </Spin>

      {/* Footer */}
      <Row
        className="rowFooter"
        type="flex"
        justify="center"
        align="middle"
        gutter={8}
      >
        <Col span={3}>
          <ButtonStandard
            type="submit"
            name="buttonSaveFinancialTransactionsForm"
            buttonType="principal"
            onClick={handleSave}
          >
            {translation.buttons.save}
          </ButtonStandard>
        </Col>
        <Col span={3}>
          <ButtonStandard
            name="buttonCancelFinancialTransactionsForm"
            buttonType="secondary"
            type="button"
            onClick={handleCancel}
          >
            {translation.buttons.cancel}
          </ButtonStandard>
        </Col>
      </Row>
    </Container>
  );
}

export default ResultCenterApportionmentModal;
