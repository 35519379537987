import React, { useCallback, useMemo, useRef, useState } from "react";
import { Formik } from "formik";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Creators as LotReceiptActions } from "../../../store/ducks/lotReceipt";

import {
  Col,
  DatePicker,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
} from "antd";
import NumberFormat from "react-number-format";
import { getLotIndexDropDown } from "../../../services/lotService";
import ButtonStandard from "../../utils/button";
import InfoTooltip from "../../utils/infoTooltip";
import { Container, Footer } from "./styles";

// import { Container } from './styles';

const SCHEMA_VALIDATION = Yup.object({
  entryDate: Yup.string().required(),
  amountAnimals: Yup.number().required(),
  averageWeight: Yup.number().required(),
  ageInDays: Yup.number().required(),
  prefixIdentification: Yup.string().required(),
  lotDestinationId: Yup.string().required(),
});

const DrawerLotReceiptItemEntry = () => {
  const formikRef = useRef(null);
  const [lots, setLots] = useState([]);
  const [form, setForm] = useState({
    entryDate: null,
    amountAnimals: null,
    averageWeight: null,
    prefixIdentification: null,
    lotDestinationId: null,
    ageInDays: null,
  });
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const {
    isLoadingRequestEntry,
    lotReceiptItemDetailsData,
    showDrawerItemEntry,
    lotId,
    lotItemId,
    lotItemEntryData,
  } = useSelector((state) => state.lotReceipt);

  const dispatch = useDispatch();

  const lotItemEntryDate = useMemo(() => {
    if (lotReceiptItemDetailsData && lotReceiptItemDetailsData.entryDate) {
      return moment(lotReceiptItemDetailsData.entryDate);
    }
    return null;
  }, [lotReceiptItemDetailsData]);

  const lotItemAmountAnimals = useMemo(() => {
    if (lotReceiptItemDetailsData) {
      return lotReceiptItemDetailsData.amount;
    }
    return 0;
  }, [lotReceiptItemDetailsData]);

  const fetchLots = useCallback(async () => {
    const {
      data: { results },
    } = await getLotIndexDropDown({
      groupId,
      farmId,
    });
    setLots(
      results.filter(
        (lot) =>
          lot.status === "Active" ||
          lot.status === "A" ||
          lot.status === "Pending" ||
          lot.status === "P"
      )
    );
  }, [groupId, farmId]);
  const handleSubmitForm = (values, actions) => {
    const { entryDate, amountAnimals } = values;

    try {
      if (lotItemEntryDate) {
        if (
          lotItemEntryDate.isAfter(moment(entryDate), "days") ||
          lotItemEntryDate.isBefore(moment(entryDate), "days")
        ) {
          Modal.confirm({
            centered: true,
            title:
              "Atenção! Entrada com data diferente da contratada. Deseja continuar?",
            okText: translation.buttons.no,
            onOk: () => {
              return;
            },
            cancelText: translation.buttons.yes,
            onCancel: () => {
              if (amountAnimals > lotItemAmountAnimals) {
                Modal.confirm({
                  centered: true,
                  title:
                    "Atenção! Entrada com mais animais do que previsto na contratação. Deseja continuar?”",
                  okText: translation.buttons.no,
                  onOk: () => {
                    return;
                  },
                  cancelText: translation.buttons.yes,
                  onCancel: () => {
                    handleSave(values);
                  },
                });
              } else {
                handleSave(values);
              }
            },
          });
        } else {
          handleSave(values);
        }
      } else if (amountAnimals > lotItemAmountAnimals) {
        Modal.confirm({
          centered: true,
          title:
            "Atenção! Entrada com mais animais do que previsto na contratação. Deseja continuar?”",
          okText: translation.buttons.no,
          onOk: () => {
            return;
          },
          cancelText: translation.buttons.yes,
          onCancel: () => {
            handleSave(values);
          },
        });
      } else {
        handleSave(values);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSave = (values) => {
    dispatch(
      LotReceiptActions.saveLotItemEntry(
        groupId,
        farmId,
        lotId,
        lotItemId,
        values
      )
    );
  };
  const handleOpenDrawer = (visible) => {
    if (visible) {
      fetchLots();
      if (lotItemEntryData) {
        setForm(lotItemEntryData);
      } else {
        formikRef.current.resetForm({
          entryDate: null,
          amountAnimals: null,
          averageWeight: null,
          prefixIdentification: null,
          lotDestinationId: null,
          ageInDays: null,
        });
      }
    }
  };
  const handleCloseDrawer = () => {
    dispatch(LotReceiptActions.openOrHideDrawerItemEntry());
  };
  const handleChangeAge = useCallback((e, type, setFieldValue) => {
    let days = 0;
    const inputValue = e.target.value;
    if (inputValue) {
      switch (type) {
        case "days":
          days = inputValue > 5478 ? 5478 : inputValue;
          break;
        case "months":
          days = inputValue > 180 ? 180 * 30.417 : inputValue * 30.417;
          break;
        case "years":
          days = inputValue > 15 ? 15 * 365 : inputValue * 365;
          break;
        default:
          break;
      }
    }
    setFieldValue("ageInDays", Math.round(days));
  }, []);
  return (
    <Container
      title="Cadastrar - Entrada de Animais"
      visible={showDrawerItemEntry}
      width={"40vw"}
      maskClosable={false}
      afterVisibleChange={handleOpenDrawer}
      onClose={handleCloseDrawer}
    >
      <Formik
        ref={formikRef}
        initialValues={form}
        enableReinitialize
        onSubmit={handleSubmitForm}
        validationSchema={SCHEMA_VALIDATION}
        render={({
          values,
          errors,
          submitCount,
          setFieldValue,
          handleSubmit,
        }) => (
          <Spin spinning={isLoadingRequestEntry}>
            <form
              className="drawerForm"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {Object.entries(errors).length > 0 && submitCount > 0 && (
                <Row type="flex" justify="center" align="middle">
                  <label className="error">{translation.error.formError}</label>
                </Row>
              )}
              <Row type="flex" gutter={8}>
                <Col span={12}>
                  <Row>
                    <label
                      className={
                        submitCount > 0 && errors.entryDate != null
                          ? "error"
                          : ""
                      }
                      htmlFor="entryDate"
                    >
                      {translation.lotItemEntry.form.entryDate}*
                    </label>
                  </Row>
                  <Row>
                    <DatePicker
                      name="entryDate"
                      value={
                        values.entryDate ? moment(values.entryDate) : undefined
                      }
                      format={"DD/MM/YYYY"}
                      disabledDate={(current) => current.isAfter(moment())}
                      onChange={(date) => setFieldValue("entryDate", date)}
                    />
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <label
                      className={
                        submitCount > 0 && errors.amountAnimals != null
                          ? "error"
                          : ""
                      }
                      htmlFor="amountAnimals"
                    >
                      {translation.lotItemEntry.form.amountAnimals}*
                    </label>
                  </Row>
                  <Row>
                    <InputNumber
                      value={values.amountAnimals || ""}
                      placeholder={translation.defaultPlaceholder}
                      min={1}
                      onChange={(value) =>
                        setFieldValue(
                          "amountAnimals",
                          value ? (value > 1000 ? 1000 : value) : null
                        )
                      }
                    />
                  </Row>
                </Col>
              </Row>
              <Row type="flex" gutter={8}>
                <Col span={12}>
                  <Row>
                    <label
                      className={
                        submitCount > 0 && errors.averageWeight != null
                          ? "error"
                          : ""
                      }
                      htmlFor="averageWeight"
                    >
                      {translation.lotItemEntry.form.averageWeight}*
                    </label>
                  </Row>
                  <Row>
                    <NumberFormat
                      customInput={Input}
                      value={values.averageWeight || ""}
                      placeholder={translation.defaultPlaceholder}
                      min={0}
                      addonAfter="Kg"
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      onValueChange={({ floatValue }) =>
                        setFieldValue(
                          "averageWeight",
                          floatValue
                            ? floatValue > 1000
                              ? 1000
                              : floatValue
                            : null
                        )
                      }
                    />
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <label
                      className={
                        submitCount > 0 && errors.ageInDays != null
                          ? "error"
                          : ""
                      }
                      htmlFor="ageInDays"
                    >
                      {translation.lotItemEntry.form.ageInDays}*
                    </label>
                  </Row>
                  <Row>
                    <Input.Group compact>
                      <Input
                        type="number"
                        style={{ width: 80, textAlign: "center" }}
                        name="ageDay"
                        min="0"
                        placeholder="Dias"
                        value={
                          values?.ageInDays != null ? values?.ageInDays : ""
                        }
                        onChange={(e) =>
                          handleChangeAge(e, "days", setFieldValue)
                        }
                      />
                      <Input
                        style={{
                          width: 80,
                          textAlign: "center",
                          borderLeft: "1px solid #c4c4c4",
                        }}
                        type="number"
                        name="ageMonth"
                        min="0"
                        placeholder="Meses"
                        value={
                          values?.ageInDays != null
                            ? Math.round(values?.ageInDays / 30.417)
                            : ""
                        }
                        onChange={(e) =>
                          handleChangeAge(e, "months", setFieldValue)
                        }
                      />
                      <Input
                        style={{
                          width: 80,
                          textAlign: "center",
                          borderLeft: "1px solid #c4c4c4",
                        }}
                        type="number"
                        name="ageYear"
                        min="0"
                        placeholder="Anos"
                        value={
                          values?.ageInDays != null
                            ? Math.round(values?.ageInDays / 365)
                            : ""
                        }
                        onChange={(e) =>
                          handleChangeAge(e, "years", setFieldValue)
                        }
                      />
                    </Input.Group>
                  </Row>
                </Col>
              </Row>
              <Row type="flex" gutter={8}>
                <Col span={12}>
                  <Row>
                    <label
                      className={
                        submitCount > 0 && errors.prefixIdentification != null
                          ? "error"
                          : ""
                      }
                      htmlFor="prefixIdentification"
                    >
                      {translation.lotItemEntry.form.prefixIdentification}*{" "}
                      <InfoTooltip
                        title={
                          'Adicione o Prefixo a ser utilizado na identificação provisória dos animais. A partir deste prefixo, serão gerados os animais com a identificação  "Prefixo" + "Odem" (001, 002, 003).'
                        }
                      />
                    </label>
                  </Row>
                  <Row>
                    <Input
                      value={values.prefixIdentification || ""}
                      placeholder={translation.defaultPlaceholder}
                      maxLength={10}
                      onChange={(e) =>
                        setFieldValue("prefixIdentification", e.target.value)
                      }
                    />
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <label
                      className={
                        submitCount > 0 && errors.lotDestinationId != null
                          ? "error"
                          : ""
                      }
                      htmlFor="lotDestinationId"
                    >
                      {translation.lotItemEntry.form.lotDestinationId}*
                    </label>
                  </Row>
                  <Row>
                    <Select
                      name="lotDestinationId"
                      value={values.lotDestinationId || undefined}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={translation.defaultMultiSelectPlaceholder}
                      onChange={(value) => {
                        setFieldValue("lotDestinationId", value);
                      }}
                    >
                      {lots.map((c) => (
                        <Select.Option value={c.id} key={c.id}>
                          {c.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Row>
                </Col>
              </Row>
              <Footer>
                <Row type="flex">
                  <Col span={24}>
                    <Row type="flex" justify="end" gutter={8}>
                      <Col>
                        <ButtonStandard
                          type="button"
                          buttonType="type7"
                          onClick={handleCloseDrawer}
                        >
                          {translation.buttons.cancel}
                        </ButtonStandard>
                      </Col>
                      <Col>
                        <ButtonStandard type="submit" buttonType="type6">
                          {translation.buttons.save}
                        </ButtonStandard>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Footer>
            </form>
          </Spin>
        )}
      />
    </Container>
  );
};

export default DrawerLotReceiptItemEntry;
