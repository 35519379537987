import styled from "styled-components";

export const Container = styled.div`
  padding: 5px 5px;
  height: 540px;
  overflow-y: auto;

  @media screen and (max-height: 700px) {
    height: 350px;
  }
  .ant-collapse {
    width: 100%;
    background: #f5f5f5;
    border-radius: 5px;
  }
  .ant-list-split .ant-list-item {
    border-bottom: none;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    position: relative;
    padding: 12px 16px;
    padding-left: 40px;
    line-height: 22px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-family: Asap;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
    color: #4b4b4b;
  }

  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    color: #4a85ae;
  }
`;
export const IconContainer = styled.div`
  svg {
    margin-right: 5px;
  }
`;
