import React, { useState, useEffect, useCallback } from "react";
import CanvasJSReact from "../../../../assets/canvasjs.react";
import { withRouter, Link, useRouteMatch, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
//Components
import { Row, Col, Input, Select, Spin, notification, Icon, Radio } from "antd";
import {
  Container,
  Body,
  CustomDivider,
  Title,
  HeadTitle,
  RadioGroupCustom,
  RadioButtonCustom,
  Grid,
} from "./styles";
import ButtonStandard from "../../../../components/utils/button";
import BreadCrumbs from "../../../../components/utils/breadCrumbs";
import NumberFormat from "react-number-format";
import RoutingLeavingGuardModal from "../../../../components/modals/routingLeavingGuardModal";
// Services
import { getFuturePrice } from "../../../../services/futurePriceService";
import {
  getMarketAnalysisDropDown,
  getMarketAnalysisShow,
  saveMarketAnalysis,
} from "../../../../services/marketAnalysisService";

const NewEditFuturePrice = () => {
  // Variable Redux
  const {
    translation,
    groupSelected,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  // Variables
  const { Group: RadioGroup } = Radio;
  const { CanvasJSChart } = CanvasJSReact;
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(true);
  const [dataChart, setDataChart] = useState([]);
  const [currentGroupId, setCurrentGroupId] = useState(null);
  const [currentFarmId, setCurrentFarmId] = useState(null);
  const [months, setMonths] = useState([]);
  const [formMarketAnalysis, setFormMarketAnalysis] = useState({
    id: null,
    groupId: groupId,
    farmId: farmId,
    type: "Free",
    referenceMarketAnalysisId: null,
    percentage: 0,
    automaticCalculation: false,
    shouldUpdate: true,
    status: "Active",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [globalMarketAnalysis, setGlobalMarketAnalysis] = useState([]);
  const [formFuturePrices, setFormFuturePrices] = useState([]);
  const toggleDataSeries = (e) => {
    const id = e?.dataSeries?.id;
    const index = dataChart.findIndex((dc) => dc?.id === id);
    const item = dataChart[index];
    if (
      dataChart.filter((dc) => dc.visible).length > 1 ||
      item?.visible === false
    ) {
      const newDataChart = dataChart.map((dc) =>
        id === dc.id ? { ...dc, visible: !dc.visible } : dc
      );
      setDataChart(newDataChart);
    }
  };
  /* Chart options */
  const options = {
    animationEnabled: true,
    zoomEnabled: true,
    height: 220,
    backgroundColor: "#fff",
    axisX: {
      labelFontSize: 12,
      valueFormatString: "MM/YY",
      interval: 1,
      intervalType: "month",
      viewportMinimum: moment().subtract(1, "months").toDate(),
      viewportMaximum: moment().add(1, "years").toDate(),
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
      },
    },
    toolTip: {
      fontFamily: "Asap",
      shared: true,
    },
    axisY: {
      labelFontSize: 12,
      includeZero: true,
      valueFormatString: "###0.##",
      suffix: " R$/@",
      gridDashType: "dot",
    },
    legend: {
      cursor: "pointer",
      itemclick: toggleDataSeries,
    },
    data: dataChart,
  };

  // Variable props
  const {
    params: { id: marketAnalysisId },
  } = useRouteMatch();
  const history = useHistory();

  // Effects

  /** Mount the months */
  useEffect(() => {
    let monthsArray = [];
    let today = moment().set("date", 1).add(-1, "M");

    for (var i = 0; i < 36; i++) {
      var monthObj = {
        month: today.add(1, "M").format("YYYY-MM"),
        order: parseInt(i / 4),
        first: i === 0,
      };
      monthsArray.push(monthObj);
    }
    setMonths(monthsArray);
  }, []);
  /** Get the infos */
  useEffect(() => {
    const getMarketAnalysis = async () => {
      let futurePricesArrayXY = [];
      let refFuturePricesArrayXY = [];
      if (marketAnalysisId) {
        try {
          /** Get market analysy */
          const {
            data: { results: formMarketAnalysis },
          } = await getMarketAnalysisShow({
            groupId,
            farmId,
            id: marketAnalysisId,
          });
          setFormMarketAnalysis(formMarketAnalysis);

          /** Set future prices */
          const {
            data: { results: dataMarketAnalysisFuturePrice },
          } = await getFuturePrice({
            groupId,
            farmId,
            marketAnalysisId,
            justFuture: true,
          });

          const formFuturePricesArray = dataMarketAnalysisFuturePrice.map(
            (r, i) => {
              if (i === 0) {
                return {
                  price: r.price,
                  month: r.month,
                  first: true,
                };
              } else {
                return {
                  price: r.price,
                  month: r.month,
                };
              }
            }
          );

          futurePricesArrayXY = formFuturePricesArray.map((fp) => ({
            x: moment(fp.month, "YYYY-MM").toDate(),
            y: fp.price,
          }));

          setFormFuturePrices(formFuturePricesArray);

          /** Create chart for reference market */
          if (formMarketAnalysis?.referenceMarketAnalysisId != null) {
            const {
              data: { results: dataRefMarketAnalysisFuturePrice },
            } = await getFuturePrice({
              groupId,
              farmId,
              marketAnalysisId: formMarketAnalysis?.referenceMarketAnalysisId,
              justFuture: true,
            });

            const refFuturePricesArray = dataRefMarketAnalysisFuturePrice.map(
              (r, i) => {
                if (i === 0) {
                  return {
                    price: r.price,
                    month: r.month,
                    first: true,
                  };
                } else {
                  return {
                    price: r.price,
                    month: r.month,
                  };
                }
              }
            );

            refFuturePricesArrayXY = refFuturePricesArray.map((fp) => ({
              x: moment(fp.month, "YYYY-MM").toDate(),
              y: fp.price,
            }));
          }
        } catch (error) {
          console.error(error);
          setFormMarketAnalysis({
            id: null,
            groupId: groupId,
            farmId: farmId,
            type: "Free",
            referenceMarketAnalysisId: null,
            percentage: 0,
            automaticCalculation: false,
            status: "Active",
          });
        }
      } else {
        let monthsArray = [];
        let today = moment().set("date", 1).add(-1, "M");

        for (var i = 0; i < 36; i++) {
          var monthObj = {
            month: today.add(1, "M").format("YYYY-MM"),
            order: parseInt(i / 4),
            first: i === 0,
          };
          monthsArray.push(monthObj);
        }
        const formFuturePricesArray = monthsArray.map((m, i) => {
          if (i === 0) {
            return {
              month: m.month,
              price: 0,
              first: true,
            };
          } else {
            return {
              month: m.month,
              price: 0,
            };
          }
        });

        futurePricesArrayXY = formFuturePricesArray.map((fp) => ({
          x: moment(fp.month, "YYYY-MM").toDate(),
          y: fp.price,
        }));

        setFormFuturePrices(formFuturePricesArray);
      }

      if (marketAnalysisId && refFuturePricesArrayXY.length > 0) {
        setDataChart([
          {
            id: "newMarket",
            type: "line",
            showInLegend: true,
            name: "Nova",
            color: "#4A85AE",
            visible: true,
            xValueFormatString: "DD/MM/YYYY",
            yValueFormatString: "R$#######.00",
            dataPoints: futurePricesArrayXY,
          },
          {
            id: "referenceMarket",
            type: "line",
            showInLegend: true,
            name: "Referência ",
            color: "#A3A3A3",
            visible: true,
            xValueFormatString: "DD/MM/YYYY",
            yValueFormatString: "R$#######.00",
            dataPoints: refFuturePricesArrayXY,
          },
        ]);
      } else {
        setDataChart([
          {
            id: "newMarket",
            type: "line",
            showInLegend: true,
            name: "Nova ",
            color: "#4A85AE",
            visible: true,
            xValueFormatString: "DD/MM/YYYY",
            yValueFormatString: "R$#######.00",
            dataPoints: futurePricesArrayXY,
          },
        ]);
      }
    };
    const getGlobalMarketAnalysis = async () => {
      try {
        const {
          data: { results: marketAnalysises },
        } = await getMarketAnalysisDropDown({
          groupId,
          farmId,
        });
        const globalMarketAnalysisArray = marketAnalysises.filter(
          (ma) => ma.id !== marketAnalysisId
        );
        setGlobalMarketAnalysis(globalMarketAnalysisArray);
      } catch (error) {
        setGlobalMarketAnalysis([]);
      }
    };
    const loadPage = async () => {
      setIsLoading(true);
      try {
        setCurrentGroupId(groupId);
        setCurrentFarmId(farmId);
        await Promise.all([
          getMarketAnalysis(groupId, farmId, marketAnalysisId),
          getGlobalMarketAnalysis(groupId, farmId),
        ]);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    if (currentGroupId === null && currentFarmId === null) {
      loadPage();
    } else if (currentGroupId !== groupId || farmId !== currentFarmId) {
      loadPage();
    }
  }, [
    months,
    marketAnalysisId,
    groupId,
    farmId,
    currentFarmId,
    currentGroupId,
  ]);

  // Callbacks
  /** On change formFuturePrices alter chart */

  const capitalize = useCallback((value) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }, []);
  const handlePriceChange = useCallback(
    (value, index) => {
      let item = formFuturePrices[index];
      if (item != null) {
        item = {
          ...item,
          price: value,
        };
        const newFormFuturePrices = formFuturePrices.map((d, i) =>
          i === index ? item : d
        );
        setFormFuturePrices(newFormFuturePrices);
      }
    },
    [formFuturePrices]
  );

  // Methods

  const recalcChart = (values = null) => {
    if (dataChart.length > 0) {
      const data = values != null ? values : formFuturePrices;
      const futurePricesArrayXY = data.map((fp) => ({
        x: moment(fp.month, "YYYY-MM").toDate(),
        y: fp.price,
      }));
      setDataChart(
        dataChart.map((dc) =>
          dc.id === "newMarket"
            ? {
                id: "newMarket",
                type: "line",
                showInLegend: true,
                name: "Nova",
                color: "#4A85AE",
                visible: true,
                xValueFormatString: "DD/MM/YYYY",
                yValueFormatString: "R$#######.00",
                dataPoints: futurePricesArrayXY,
              }
            : dc
        )
      );
    }
  };

  const formatArrayValues = (dataReference) => {
    return months.map((m) => {
      const index = dataReference.findIndex((p) => p.month === m.month);
      if (index >= 0) {
        return {
          price: dataReference[index].price,
          month: m.month,
          first: index === 0 ? true : false,
        };
      } else {
        return {
          price: 0,
          month: m.month,
          first: false,
        };
      }
    });
  };

  const calculateValues = async (useReference = false, ptype = null) => {
    try {
      let pricesValues = [];

      const type = ptype != null ? ptype : formMarketAnalysis?.type;

      if (
        useReference &&
        formMarketAnalysis?.referenceMarketAnalysisId != null
      ) {
        const {
          data: { results: dataReferenceFuturePrices },
        } = await getFuturePrice({
          groupId,
          farmId,
          marketAnalysisId: formMarketAnalysis?.referenceMarketAnalysisId,
          justFuture: true,
        });
        pricesValues = formatArrayValues(dataReferenceFuturePrices);
      } else {
        pricesValues = formFuturePrices;
      }

      const percentageValue = formMarketAnalysis.percentage / 100;

      if (pricesValues !== null && pricesValues.length > 0) {
        const firstMonth = pricesValues.find((ffp) => ffp.first);

        if (type === "Progressive") {
          let lastValue = firstMonth?.price || 0;
          pricesValues = pricesValues.map((fp) => {
            if (firstMonth.month === fp.month) {
              return fp;
            } else {
              lastValue = lastValue + lastValue * percentageValue;
              fp.price = lastValue !== 0 ? lastValue : 0;
              return fp;
            }
          });
        } else if (type === "Linear") {
          pricesValues = pricesValues.map((fp) => {
            fp.price =
              fp.price !== null && fp.price !== 0
                ? fp.price + fp.price * percentageValue
                : 0;
            return fp;
          });
        }
      }
      setFormFuturePrices(pricesValues);
      recalcChart(pricesValues);
    } catch (error) {
      console.error(error);
    }
  };

  const validateForm = () => {
    let errorsArray = [];
    if (!formMarketAnalysis.name) {
      errorsArray.push("name");
    }
    if (!formMarketAnalysis.type) {
      errorsArray.push("type");
    } else if (
      (formMarketAnalysis.type === "Linear" ||
        formMarketAnalysis.type === "Progressive") &&
      (!formMarketAnalysis.percentage || formMarketAnalysis.percentage === 0)
    ) {
      errorsArray.push("percentage");
    }
    if (
      formMarketAnalysis.type === "Linear" &&
      !formMarketAnalysis.referenceMarketAnalysisId
    ) {
      notification.error({
        message:
          "Selecionado o tipo 'Linear', a Curva de Preço de Referência é obrigatória",
      });
    }

    if (
      formMarketAnalysis.automaticCalculation &&
      !formMarketAnalysis.referenceMarketAnalysisId
    ) {
      notification.error({
        message:
          "Quando 'Calcular Automaticamente' é ativado, a Curva de Preço de Referência é obrigatória",
      });
    }

    if (formFuturePrices.filter((p) => p.price === 0).length > 0) {
      errorsArray.push("prices");
      notification.error({
        message:
          "Todos campos de preço devem ser populados com valores maior que zero",
      });
    }

    setErrors(errorsArray);

    if (errorsArray.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleCleanMonthsValues = () => {
    const newFuturePrices = months.map((m, i) => {
      if (i === 0) {
        return {
          month: m.month,
          price: 0,
          first: true,
        };
      } else {
        return {
          month: m.month,
          price: 0,
        };
      }
    });
    setFormMarketAnalysis({
      ...formMarketAnalysis,
      referenceMarketAnalysisId: null,
      type: "Free",
      percentage: 0,
    });
    setFormFuturePrices(newFuturePrices);
    const futurePricesArrayXY = newFuturePrices.map((fp) => ({
      x: moment(fp.month, "YYYY-MM").toDate(),
      y: fp.price,
    }));
    setDataChart([
      {
        id: "newMarket",
        type: "line",
        showInLegend: true,
        visible: true,
        name: "Nova",
        color: "#4A85AE",
        xValueFormatString: "DD/MM/YYYY",
        yValueFormatString: "R$#######.00",
        dataPoints: futurePricesArrayXY,
      },
    ]);
  };

  const handleReferenceMarketAnalysisChange = async (marketAnalysisId) => {
    let futurePricesArrayXY = [];

    setFormMarketAnalysis({
      ...formMarketAnalysis,
      referenceMarketAnalysisId: marketAnalysisId,
      percentage: 0,
    });
    setErrors(errors.filter((e) => e !== "referenceMarketAnalysisId"));

    if (marketAnalysisId !== null) {
      try {
        const {
          data: { results: dataReferenceFuturePrices },
        } = await getFuturePrice({
          groupId,
          farmId,
          marketAnalysisId,
          justFuture: true,
        });

        const formatedReferenceFuturePrices = formatArrayValues(
          dataReferenceFuturePrices
        );

        futurePricesArrayXY = formatedReferenceFuturePrices.map((fp) => ({
          x: moment(fp.month, "YYYY-MM").toDate(),
          y: fp.price,
        }));

        setFormFuturePrices(formatedReferenceFuturePrices);

        setDataChart([
          {
            id: "newMarket",
            type: "line",
            showInLegend: true,
            name: "Nova",
            color: "#4A85AE",
            visible: true,
            xValueFormatString: "DD/MM/YYYY",
            yValueFormatString: "R$#######.00",
            dataPoints: futurePricesArrayXY,
          },
          {
            id: "referenceMarket",
            type: "line",
            showInLegend: true,
            name: "Referência ",
            color: "#A3A3A3",
            visible: true,
            xValueFormatString: "DD/MM/YYYY",
            yValueFormatString: "R$#######.00",
            dataPoints: futurePricesArrayXY,
          },
        ]);
      } catch (error) {
        console.error(error);
        setFormMarketAnalysis({
          ...formMarketAnalysis,
          referenceMarketAnalysisId: null,
        });
      }
    }
  };

  const handlePercentageChange = (value) => {
    setFormMarketAnalysis({
      ...formMarketAnalysis,
      percentage: value,
    });
    setErrors(errors.filter((e) => e !== "percentage"));
  };

  const handleClickCalculateButton = () => {
    if (formMarketAnalysis.type !== "Free") {
      calculateValues(true);
    }
  };

  const handleTypeChange = async (value) => {
    let aErrors = errors;
    if (value !== "Free") {
      if (
        !formMarketAnalysis.percentage ||
        !formMarketAnalysis.referenceMarketAnalysisId
      ) {
        if (
          formMarketAnalysis.percentage === null ||
          formMarketAnalysis.percentage === 0
        )
          aErrors.push("percentage");
        if (value === "Linear" && !formMarketAnalysis.referenceMarketAnalysisId)
          aErrors.push("referenceMarketAnalysisId");
      }
      setFormMarketAnalysis({
        ...formMarketAnalysis,
        type: value,
      });
      calculateValues(true, value);
    } else {
      setFormMarketAnalysis({
        ...formMarketAnalysis,
        type: value,
        percentage: 0,
      });
      if (formMarketAnalysis?.referenceMarketAnalysisId != null) {
        const {
          data: { results: dataReferenceFuturePrices },
        } = await getFuturePrice({
          groupId,
          farmId,
          marketAnalysisId: formMarketAnalysis?.referenceMarketAnalysisId,
          justFuture: true,
        });
        const pricesValues = formatArrayValues(dataReferenceFuturePrices);
        setFormFuturePrices(pricesValues);
      }
    }
    aErrors = aErrors.filter((e) => e !== "type");
    setErrors(aErrors);
  };

  const handleCompleTypeChangeOfFirstValue = () => {
    calculateValues(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    if (validateForm()) {
      setShouldBlockNavigation(false);
      try {
        const bodyFuturePrices = formFuturePrices.map((m) => {
          m.month = moment(m.month, "YYYY-MM").format("YYYY-MM-01 23:00:00");
          return m;
        });

        const body = {
          marketAnalysis: formMarketAnalysis,
          prices: bodyFuturePrices,
        };

        await saveMarketAnalysis({
          groupId,
          farmId,
          id: formMarketAnalysis.id,
          body,
        });

        if (formMarketAnalysis.id == null)
          notification.success({
            message: "Curva de Preço criada com sucesso",
          });
        else
          notification.success({
            message: "Curva de Preço atualizada com sucesso",
          });

        setFormMarketAnalysis({
          id: null,
          groupId: groupId,
          farmId: farmId,
          type: "Free",
          referenceMarketAnalysisId: null,
          percentage: 0,
          automaticCalculation: false,
          status: "Active",
        });
        setErrors([]);
        setFormFuturePrices([]);
        history.push(`/admin/parameters?tabSelect=1`);
      } catch (error) {
        setShouldBlockNavigation(true);
        if (formMarketAnalysis.id == null)
          notification.error({ message: "A Curva de Preço não foi criada" });
        else
          notification.error({
            message: "A Curva de Preço não foi atualizada",
          });
      }
    }
    setIsLoading(false);
  };

  const handleCancel = async () => {
    await setShouldBlockNavigation(false);
    history.push("/admin/parameters?tabSelect=1");
  };

  return (
    <>
      <RoutingLeavingGuardModal
        when={shouldBlockNavigation}
        navigate={(path) => history.push(path)}
        message="Parece que você está saindo sem salvar a curva de preço."
      />
      <Container>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          style={{
            paddingTop: "20px",
            paddingLeft: "10px",
            paddingRight: "20px",
          }}
        >
          <Col span={12}>
            <HeadTitle>Curva de Preços</HeadTitle>
          </Col>
          <Col span={12} align="right">
            <BreadCrumbs>
              <Link to="/admin/parameters?tabSelect=1">
                <span className="pageTreeInative">Parâmetros</span>
              </Link>
              <span className="pageTreeActive">Curva de Preços</span>
            </BreadCrumbs>
          </Col>
        </Row>
        <CustomDivider dashed />
        <Spin spinning={isLoading}>
          {/* Message and buttons */}
          <Row className="headerButtonsAndMessagens" align="middle">
            <Col span={16}>
              <strong>Complete os campos da Curva de Preços</strong>
            </Col>
            <Col span={8} align="right" className="buttonsCol">
              <ButtonStandard
                type="button"
                buttonType="type4"
                size="s"
                onClick={handleCancel}
              >
                {translation.buttons.cancel}
              </ButtonStandard>
              <ButtonStandard
                type="button"
                buttonType="type1"
                width="121px"
                height="35px"
                padding="5px 10px 5px 10px"
                onClick={() => handleSubmit()}
              >
                Salvar
              </ButtonStandard>
            </Col>
          </Row>
          {/* Chart */}
          <Row type="flex">
            <Col span={24}>
              <CanvasJSChart options={options} />
            </Col>
          </Row>
          <Body>
            <Row type="flex">
              {/* Column params */}
              <Col span={8} className="colName">
                {/* Input name */}
                <Row typle="flex">
                  <label className={errors.includes("name") ? "error" : ""}>
                    Nome da Curva de Preços *
                  </label>
                </Row>
                <Row typle="flex">
                  <Input
                    name="name"
                    className={errors.includes("name") ? "error" : ""}
                    value={formMarketAnalysis?.name}
                    autoComplete="chrome-off"
                    placeholder="Nome da Curva de Preços"
                    onChange={(e) => {
                      setFormMarketAnalysis({
                        ...formMarketAnalysis,
                        name: e.target.value,
                      });
                      setErrors(errors.filter((e) => e !== "name"));
                    }}
                  />
                </Row>
                {/* Visibility */}
                <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <label>Quem poderá utilizar a Curva de Preços?</label>
                    </Row>
                    <Row>
                      <RadioGroupCustom
                        value={
                          formMarketAnalysis?.groupId != null &&
                          formMarketAnalysis?.farmId != null
                            ? "farm"
                            : formMarketAnalysis?.groupId != null &&
                              formMarketAnalysis?.farmId == null
                            ? "group"
                            : "farm"
                        }
                        buttonStyle="solid"
                      >
                        <RadioButtonCustom
                          value="farm"
                          onChange={(e) => {
                            setFormMarketAnalysis({
                              ...formMarketAnalysis,
                              groupId,
                              farmId,
                            });
                          }}
                        >
                          {translation.supplier.form.radiogroup.farm}
                        </RadioButtonCustom>
                        <RadioButtonCustom
                          value="group"
                          onChange={(e) => {
                            setFormMarketAnalysis({
                              ...formMarketAnalysis,
                              groupId,
                              farmId: null,
                            });
                          }}
                        >
                          {translation.supplement.form.radiogroup.group}:
                          {`${groupSelected?.name?.substring(0, 10)}...`}
                        </RadioButtonCustom>
                      </RadioGroupCustom>
                    </Row>
                  </Col>
                </Row>
                {/* Input type */}
                <Row type="flex">
                  <Col span={24}>
                    <Row>
                      <label className={errors.includes("type") ? "error" : ""}>
                        Tipo *
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <Select
                        value={formMarketAnalysis?.type ?? "Free"}
                        name="type"
                        placeholder={translation.defaultSelectPlaceholder}
                        onChange={(value) => {
                          handleTypeChange(value);
                        }}
                      >
                        <Select.Option value="Progressive">
                          Progressiva
                        </Select.Option>
                        <Select.Option value="Linear">Linear</Select.Option>
                        <Select.Option value="Free">Livre</Select.Option>
                      </Select>
                    </Row>
                  </Col>
                </Row>
                {/* Input marketAnalysis de Referencia */}
                <Row type="flex">
                  <Col span={24}>
                    <Row>
                      <label
                        className={
                          errors.includes("referenceMarketAnalysisId")
                            ? "error"
                            : ""
                        }
                      >
                        {formMarketAnalysis?.type !== "Free"
                          ? "Curva de Preço de Referência *"
                          : "Curva de Preço de Referência"}
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <Select
                        loading={isLoading}
                        value={
                          globalMarketAnalysis.length === 0
                            ? undefined
                            : !formMarketAnalysis?.referenceMarketAnalysisId
                            ? undefined
                            : formMarketAnalysis?.referenceMarketAnalysisId
                        }
                        placeholder={translation.defaultSelectPlaceholder}
                        onChange={(value) => {
                          handleReferenceMarketAnalysisChange(value);
                        }}
                      >
                        <Select.Option key={0} value={null}>
                          Nenhuma
                        </Select.Option>
                        {globalMarketAnalysis.map((b) => (
                          <Select.Option key={b.id} value={b.id}>
                            {b.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Row>
                  </Col>
                </Row>
                {/* Input shouldUpdate */}
                {formMarketAnalysis?.referenceMarketAnalysisId != null && (
                  <Row type="flex">
                    <Col span={24}>
                      <Row>
                        <label>
                          Atualização Automática, conforme curva referência?”
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <RadioGroup
                          name="shouldUpdate"
                          value={formMarketAnalysis.shouldUpdate}
                          defaultValue={true}
                          onChange={(e) => {
                            setFormMarketAnalysis((old) => ({
                              ...old,
                              shouldUpdate: e.target.value,
                            }));
                          }}
                        >
                          <Radio value={true}>{translation.buttons.yes}</Radio>
                          <Radio value={false}>{translation.buttons.no}</Radio>
                        </RadioGroup>
                      </Row>
                    </Col>
                  </Row>
                )}
                {/* Input percentage */}
                <Row type="flex" justify="space-between" align="middle">
                  <Col span={16}>
                    <Row>
                      <label
                        className={errors.includes("percentage") ? "error" : ""}
                      >
                        {formMarketAnalysis?.type !== "Free"
                          ? "Porcentagem *"
                          : "Porcentagem"}
                      </label>
                    </Row>
                    <Row typle="flex" className="rowInput">
                      <Col span={24}>
                        <NumberFormat
                          customInput={Input}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalSeparator=","
                          thousandSeparator="."
                          disabled={formMarketAnalysis.type === "Free"}
                          name="percentage"
                          value={
                            formMarketAnalysis !== null
                              ? formMarketAnalysis.percentage
                              : 0
                          }
                          addonAfter="%"
                          onValueChange={({ floatValue }) => {
                            if (floatValue !== null) {
                              handlePercentageChange(floatValue);
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={8} className="buttonCalculationColumn">
                    <ButtonStandard
                      type="submit"
                      buttonType="typeWithoutBackground"
                      color="#4A85AE"
                      isUpperCase={true}
                      onClick={() => handleClickCalculateButton()}
                    >
                      Calcular <Icon type="calculator" />
                    </ButtonStandard>
                  </Col>
                </Row>
              </Col>
              {/* Column with the months */}
              <Col span={15} offset={1}>
                <Row
                  type="flex"
                  justify="start"
                  align="middle"
                  style={{ marginBottom: 5 }}
                >
                  <Col span={16}>
                    <Title className="chartTitle">
                      Valores da Curva para os próximos 36 meses
                    </Title>
                  </Col>
                  <Col span={8} align="right">
                    <ButtonStandard
                      type="submit"
                      buttonType="typeWithoutBackground"
                      color="#FE8D2A"
                      isUpperCase={false}
                      onClick={() => handleCleanMonthsValues()}
                    >
                      Limpar valores <Icon type="rest" />
                    </ButtonStandard>
                  </Col>
                </Row>
                <Grid>
                  {formFuturePrices.map((obj, i) => (
                    <Col span={5} offset={1} key={i}>
                      <Row>
                        <label>
                          {capitalize(
                            moment(obj.month, "YYYY-MM-DD").format("MMMM/YYYY")
                          )}
                        </label>
                      </Row>
                      <Row typle="flex" className="rowInput">
                        <Col span={24}>
                          <NumberFormat
                            customInput={Input}
                            name={obj?.month}
                            value={obj?.price}
                            addonBefore="R$"
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale={true}
                            onValueChange={({ floatValue }) => {
                              handlePriceChange(floatValue, i);
                            }}
                            onBlur={() => {
                              if (obj.first) {
                                handleCompleTypeChangeOfFirstValue();
                              }
                              recalcChart();
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Grid>
              </Col>
            </Row>
          </Body>
        </Spin>
      </Container>
    </>
  );
};

export default withRouter(NewEditFuturePrice);
