import React, { useState, useEffect } from "react";
import moment from "moment";

// Components
import { Row, Icon, Col, Tooltip } from "antd";
import {
  Container,
  CardShadow,
  CustomDivider,
  Footer,
  ButtonNavigation,
} from "./styles";
import RainIcon from "../../../../../pages/generalParameter/weatherSeasonsParameter/display/icons/rain";
import SunIcon from "../../../../../pages/generalParameter/weatherSeasonsParameter/display/icons/sun";
import TransitionIcon from "../../../../../pages/generalParameter/weatherSeasonsParameter/display/icons/transition";
import AlertIcon from "../../../alert/icons/alert";
import { TiArrowDownThick, TiArrowUpThick } from "react-icons/ti";
// Services
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../../../services/helpersMethodsService";

const DrawerDisplayDietStrategyPeriod = ({
  data,
  drawerVisible,
  onCancel,
  goNextOrPrevius,
  index,
  nextIndex,
  previousIndex,
}) => {
  const [dietPeriod, setDietPeriod] = useState(null);
  const [seasons, setSeasons] = useState([]);
  /** The data of periods, seasons and actions is in DrawerDisplayDietStrategy */
  useEffect(() => {
    setDietPeriod(data);
    setSeasons(data != null ? data.season.split(",") : []);
  }, [data]);

  return dietPeriod != null ? (
    <Container
      title={
        <span className="title">
          Dieta
          {data.conflictedGdp && (
            <Tooltip title="Dieta com GDP conflitante">
              <div className="gdpConflited">
                <AlertIcon />
              </div>
            </Tooltip>
          )}
        </span>
      }
      width={500}
      onClose={onCancel}
      maskClosable={false}
      visible={drawerVisible}
    >
      <div className="drawerForm">
        <div className="info-box">
          <p>Período(s) climático(s)</p>
          <b>
            {seasons.map((season) =>
              season === "Water" ? (
                <RainIcon key={season} style={{ marginLeft: "3px" }} />
              ) : season === "Transition" ? (
                <TransitionIcon key={season} style={{ marginLeft: "3px" }} />
              ) : season === "Dry" ? (
                <SunIcon key={season} style={{ marginLeft: "3px" }} />
              ) : null
            )}
          </b>
        </div>
        <div className="info-box">
          <p>Início da dieta</p>
          <b>
            {moment(dietPeriod?.startDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
          </b>
        </div>
        <div className="info-box">
          <p>Fim da dieta</p>
          <b>
            {moment(dietPeriod?.endDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
          </b>
        </div>
        <div className="info-box">
          <p>Produção</p>
          <b>
            {dietPeriod?.productionSubSystem === "Extensive"
              ? "Extensivo"
              : dietPeriod?.productionSubSystem === "Intensive"
              ? "Intensivo"
              : "Semi-Intensivo"}
          </b>
        </div>
        <div className="info-box">
          <p>Expectativa de GDP</p>
          <b>{`${numberMask(dietPeriod?.gdpExpectation)} Kg`}</b>
        </div>
        <div className="info-box">
          <p>Aproveit. de carcaça</p>
          <b>{`${numberMask(dietPeriod?.carcassHarnessing)} %`}</b>
        </div>
        <CustomDivider dashed>DIETA</CustomDivider>
        {dietPeriod?.pasture != null ? (
          <CardShadow>
            <span className="title">Forrageira</span>
            <Row type="flex" justify="space-between">
              <b>{dietPeriod?.pasture?.name}</b>
              <p style={{ marginTop: 0 }}>
                {dietPeriod?.pasture?.pastureType.name}
              </p>
            </Row>
            <Row type="flex" justify="space-between">
              <p>Estação</p>
              <b>
                {dietPeriod?.pastureSeason === "Water"
                  ? "Chuva"
                  : dietPeriod?.pastureSeason === "Dry"
                  ? "Seca"
                  : dietPeriod?.pastureSeason === "Transition"
                  ? "Transição"
                  : null}
              </b>
            </Row>
            <Row type="flex" justify="space-between">
              <p>Adubado</p>
              <b>{dietPeriod?.pastureFertilized ? "Sim" : "Não"}</b>
            </Row>
            <Row type="flex" justify="space-between">
              <p>Custo/Kg</p>
              <b>{numberMask(dietPeriod?.pastureDailyCost || 0, true)}</b>
            </Row>
          </CardShadow>
        ) : null}
        {dietPeriod?.supplement != null ? (
          <CardShadow>
            <span className="title">Suplemento/Concent.</span>
            <Row type="flex" justify="space-between">
              <b>
                {`${dietPeriod?.supplement?.name} - Versão ${dietPeriod?.supplementVersion}`}
              </b>
            </Row>
            <Row type="flex" justify="space-between">
              <p>Consumo diário / animal</p>
              <b>
                {`${numberMask(
                  dietPeriod?.supplementConsumptionByAnimal || 0
                )} ${
                  dietPeriod?.supplementConsumptionMeasureType === "PV_ANIMAL"
                    ? "%PV"
                    : dietPeriod?.supplementConsumptionMeasureType ===
                      "G_KG_PV_ANIMAL"
                    ? "g/kg de PV"
                    : dietPeriod?.supplementConsumptionMeasureType ===
                      "G_ANIMAL"
                    ? "g/dia"
                    : dietPeriod?.supplementConsumptionMeasureType ===
                      "KG_ANIMAL"
                    ? "kg/dia"
                    : dietPeriod?.supplementConsumptionMeasureType ===
                      "I_MS_PV_ANIMAL"
                    ? "%IMS PV"
                    : null
                }`}
              </b>
            </Row>
            <Row type="flex" justify="space-between">
              <p>Custo/Kg</p>
              <b>{numberMask(dietPeriod?.supplementDailyCost || 0, true)}</b>
            </Row>
          </CardShadow>
        ) : null}
        <CardShadow>
          <span className="title">Resumo do Quadro Cálculo da Dieta</span>
          {/* Gdp Expected and Possible */}
          <Row type="flex" justify="start" gutter={2}>
            <Col span={16}>
              <label>
                GDP Esperado:
                <strong>{` ${getTwoDecimalDigits(
                  dietPeriod?.gdpExpectation || 0
                )} kg `}</strong>
              </label>
            </Col>
            <Col span={8}>
              <label>
                Possível:
                <strong>{` ${getTwoDecimalDigits(
                  dietPeriod?.gdpPossible || 0
                )} kg`}</strong>
              </label>
            </Col>
          </Row>
          {/* Pasture and CP */}
          <Row type="flex" justify="start" gutter={2}>
            <Col span={16}>
              {dietPeriod?.pasture != null && (
                <label>
                  {`Pasto(kg/d): Cons.: `}
                  <strong>{`${getTwoDecimalDigits(
                    dietPeriod?.pastureConsumed || 0
                  )}`}</strong>
                  {` Nec.: `}
                  <strong>
                    {`${getTwoDecimalDigits(dietPeriod?.pastureNeeded || 0)}`}
                  </strong>
                </label>
              )}
            </Col>
            <Col span={8}>
              <label>
                PB
                <strong>
                  {dietPeriod?.cpExceededOrMissing === "Missing" ? (
                    <TiArrowDownThick id="iconExOrMiss" color="#d44c4c" />
                  ) : dietPeriod?.cpExceededOrMissing === "Exceeded" ? (
                    <TiArrowUpThick id="iconExOrMiss" color="#27ff00" />
                  ) : null}
                  {` ${getTwoDecimalDigits(
                    dietPeriod?.cpExceededOrMissingValue || 0
                  )} g`}
                </strong>
              </label>
            </Col>
          </Row>
          {/* Trough and TDN */}
          <Row type="flex" justify="start" gutter={2}>
            <Col span={16}>
              {dietPeriod?.supplement != null && (
                <label>
                  {`Conc.(kg/d): Cons.: `}
                  <strong>{`${getTwoDecimalDigits(
                    dietPeriod?.supplementConsumed || 0
                  )}`}</strong>
                  {` Nec.: `}
                  <strong>
                    {`${getTwoDecimalDigits(
                      dietPeriod?.supplementNeeded || 0
                    )}`}
                  </strong>
                </label>
              )}
            </Col>
            <Col span={8}>
              <label>
                NDT
                <strong>
                  {dietPeriod?.tdnExceededOrMissing === "Missing" ? (
                    <TiArrowDownThick id="iconExOrMiss" color="#d44c4c" />
                  ) : dietPeriod?.tdnExceededOrMissing === "Exceeded" ? (
                    <TiArrowUpThick id="iconExOrMiss" color="#27ff00" />
                  ) : null}
                  {` ${getTwoDecimalDigits(
                    dietPeriod?.tdnExceededOrMissingValue || 0
                  )} g`}
                </strong>
              </label>
            </Col>
          </Row>
          {/* Calcium */}
          {/* Phosporus */}
          {/* Zinc */}
          {/* Sodium */}
          <Row type="flex" justify="start" gutter={2}>
            <Col span={8}>
              <label>
                Ca
                <strong>
                  {dietPeriod?.calciumCode === 1 ? (
                    <TiArrowDownThick id="iconExOrMiss" color="#d44c4c" />
                  ) : dietPeriod?.calciumCode === 2 ? (
                    <TiArrowUpThick id="iconExOrMiss" color="#27ff00" />
                  ) : null}
                  {` ${getTwoDecimalDigits(
                    Math.abs(
                      dietPeriod?.calciumNeeded - dietPeriod?.calciumConsumed
                    ) || 0
                  )} g`}
                </strong>
              </label>
            </Col>
            <Col span={8}>
              <label>
                P
                <strong>
                  {dietPeriod?.phosporusCode === 1 ? (
                    <TiArrowDownThick id="iconExOrMiss" color="#d44c4c" />
                  ) : dietPeriod?.phosporusCode === 2 ? (
                    <TiArrowUpThick id="iconExOrMiss" color="#27ff00" />
                  ) : null}
                  {` ${getTwoDecimalDigits(
                    Math.abs(
                      dietPeriod?.phosporusNeeded -
                        dietPeriod?.phosporusConsumed
                    ) || 0
                  )} g`}
                </strong>
              </label>
            </Col>
            <Col span={8}>
              <label>
                Zn
                <strong>
                  {dietPeriod?.zincCode === 1 ? (
                    <TiArrowDownThick id="iconExOrMiss" color="#d44c4c" />
                  ) : dietPeriod?.zincCode === 2 ? (
                    <TiArrowUpThick id="iconExOrMiss" color="#27ff00" />
                  ) : null}
                  {` ${getTwoDecimalDigits(
                    Math.abs(
                      dietPeriod?.zincNeeded - dietPeriod?.zincConsumed
                    ) || 0
                  )} mg`}
                </strong>
              </label>
            </Col>
            <Col span={8}>
              <label>
                Na
                <strong>
                  {dietPeriod?.sodiumCode === 1 ? (
                    <TiArrowDownThick id="iconExOrMiss" color="#d44c4c" />
                  ) : dietPeriod?.sodiumCode === 2 ? (
                    <TiArrowUpThick id="iconExOrMiss" color="#27ff00" />
                  ) : null}
                  {` ${getTwoDecimalDigits(
                    Math.abs(
                      dietPeriod?.sodiumNeeded - dietPeriod?.sodiumConsumed
                    ) || 0
                  )} g`}
                </strong>
              </label>
            </Col>
          </Row>
        </CardShadow>
      </div>
      <Footer>
        <Row type="flex" justify="space-between">
          <Col span={8}>
            <ButtonNavigation
              onClick={() => goNextOrPrevius(index, "previous")}
              disabled={index === 0 || previousIndex === null ? true : false}
            >
              <Icon type="left-circle" /> Anterior
            </ButtonNavigation>
          </Col>
          <Col span={8} align="right">
            <ButtonNavigation
              disabled={nextIndex === null ? true : false}
              onClick={() => goNextOrPrevius(index, "next")}
            >
              Próximo <Icon type="right-circle" />
            </ButtonNavigation>
          </Col>
        </Row>
      </Footer>
    </Container>
  ) : null;
};

export default DrawerDisplayDietStrategyPeriod;
