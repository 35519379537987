import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as AppActions } from "../../../store/ducks/app";
import LogoVertical from "../../login/logos/logoVertical";
import { StyledModal } from "./styles";

const AboutModal = () => {
  // Redux Variables
  const { appVersion, isModalSystemAboutVisible } = useSelector(
    (state) => state.app
  );
  const dispatch = useDispatch();
  function closeModal() {
    dispatch(AppActions.showOrHideSystemAboutModal());
  }
  return (
    <StyledModal
      visible={isModalSystemAboutVisible}
      width={550}
      footer={null}
      centered
      maskClosable={false}
      closable={true}
      onCancel={closeModal}
    >
      <main>
        <LogoVertical />
        <strong>Sobre</strong>
        <p>
          A BovExo surgiu para superar os principais desafios da pecuária.
          Levando o que há de mais atual em tecnologias digitais e ferramentas
          para aprimoramento de resultados, facilita o dia a dia dos pecuaristas
          através da otimização, durante a tomada de decisão, de cinco
          fatores-chave de criação/captura de valor:
        </p>
        <div>
          <ul>
            <li>GPD</li>
            <li>PRODUTIVIDADE</li>
            <li>DURAÇÃO DO CICLO</li>
            <li>RENDIMENTO DE CARCAÇA</li>
            <li>CUSTO VARIÁVEL</li>
          </ul>
        </div>
        <strong>Versão</strong>
        <p>{!!appVersion ? appVersion : "-"}</p>
      </main>
    </StyledModal>
  );
};

export default AboutModal;
