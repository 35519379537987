import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Axios from "axios";
import CanvasJSReact from "../../../../assets/canvasjs.react";
// Components
import { Container, Title } from "./styles";
import { Row, Col, Spin } from "antd";
// Services
import {
  getLotInputAndOutputAnimalsDashboard,
  getLotCapacityUtilizationExtensiveConsiderInAndOutAnimalsDashboard,
  getLotCapacityUtilizationIntensiveConsiderInAndOutAnimalsDashboard,
  getLotCapacityUtilizationSemiIntensiveConsiderInAndOutAnimalsDashboard,
} from "../../../../services/dashboards/dashboardLotService";
import { returnObjectFromAxis } from "../../../../services/dashboards/dashboardCommonService";
import { getTwoDecimalDigits } from "../../../../services/helpersMethodsService";
import InfoTooltip from "../../../../components/utils/infoTooltip";

function LotInAndOutAnimalsDash() {
  // Variables
  const { CanvasJSChart } = CanvasJSReact;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const chartOptions = {
    animationEnabled: true,
    zoomEnabled: true,
    height: 200,
    axisX: {
      valueFormatString: "MM/YY",
      interval: 1,
      intervalType: "month",
      viewportMinimum: moment().subtract(3, "months").toDate(),
      viewportMaximum: moment().add(6, "months").toDate(),
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
      },
    },
    toolTip: {
      fontFamily: "Asap",
      shared: true,
    },
    axisY: {
      includeZero: true,
      suffix: " animais",
      valueFormatString: "#0",
      gridDashType: "dot",
    },
    axisY2: {
      title: "Capacidade de Utilização",
      suffix: "%",
    },
    legend: {
      cursor: "pointer",
      itemclick: (e) => {
        if (
          typeof e.dataSeries.visible === "undefined" ||
          e.dataSeries.visible
        ) {
          e.dataSeries.visible = false;
        } else {
          e.dataSeries.visible = true;
        }
        e.chart.render();
      },
    },
    data: data,
  };
  // Redux variables
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  // Effects
  // Get Dashboards Values
  useEffect(() => {
    let signal = Axios.CancelToken.source();
    let mount = true;
    async function fetch() {
      let dData = [];
      setIsLoading(true);
      // Get input and output
      try {
        const {
          data: { results },
        } = await getLotInputAndOutputAnimalsDashboard({
          groupId,
          farmId,
          signal: signal,
        });
        const inAndOutData = returnObjectFromAxis(results);
        const inData = inAndOutData.map((x) => ({
          x: x.x,
          y: x.y,
        }));
        const outData = inAndOutData.map((x) => ({
          x: x.x,
          y: x.y2,
        }));
        dData = [
          ...dData,
          {
            type: "column",
            name: "Entrada de animais",
            color: "#87C79C",
            showInLegend: true,
            xValueFormatString: "MM/YY",
            dataPoints: inData,
          },
          {
            type: "column",
            name: "Saida de animais",
            color: "#D57A7A",
            showInLegend: true,
            xValueFormatString: "MM/YY",
            dataPoints: outData,
          },
        ];
      } catch (error) {}
      // Get extensive
      try {
        const {
          data: { results },
        } = await getLotCapacityUtilizationExtensiveConsiderInAndOutAnimalsDashboard(
          {
            groupId,
            farmId,
            signal: signal,
          }
        );
        const extensiveData = returnObjectFromAxis(results);
        const inExtData = extensiveData.map((x) => ({
          x: x.x,
          y: getTwoDecimalDigits(x.y) * 100,
        }));
        dData = [
          ...dData,
          {
            type: "line",
            name: "Extensivo",
            showInLegend: true,
            color: "#166AAB",
            xValueFormatString: "MM/YY",
            yValueFormatString: '#,##0.##"%"',
            axisYType: "secondary",
            dataPoints: inExtData,
          },
        ];
      } catch (error) {}
      // Get SemiIntensive
      try {
        const {
          data: { results },
        } = await getLotCapacityUtilizationSemiIntensiveConsiderInAndOutAnimalsDashboard(
          {
            groupId,
            farmId,
            signal: signal,
          }
        );
        const semiintensiveData = returnObjectFromAxis(results);
        const semiIntenData = semiintensiveData.map((x) => ({
          x: x.x,
          y: getTwoDecimalDigits(x.y) * 100,
        }));
        dData = [
          ...dData,
          {
            type: "line",
            name: "Semi-Intensivo",
            showInLegend: true,
            color: "#ffa500",
            xValueFormatString: "MM/YY",
            yValueFormatString: '#,##0.##"%"',
            axisYType: "secondary",
            dataPoints: semiIntenData,
          },
        ];
      } catch (error) {}
      // Get intensive
      try {
        const {
          data: { results },
        } = await getLotCapacityUtilizationIntensiveConsiderInAndOutAnimalsDashboard(
          {
            groupId,
            farmId,
            signal: signal,
          }
        );
        const intensiveData = returnObjectFromAxis(results);
        const intenData = intensiveData.map((x) => ({
          x: x.x,
          y: getTwoDecimalDigits(x.y) * 100,
        }));
        dData = [
          ...dData,
          {
            type: "line",
            name: "Intensivo",
            showInLegend: true,
            color: "#FF0057",
            xValueFormatString: "MM/YY",
            yValueFormatString: '#,##0.##"%"',
            axisYType: "secondary",
            dataPoints: intenData,
          },
        ];
      } catch (error) {}

      if (mount) {
        setData(dData);
        setIsLoading(false);
      }
    }
    if (groupId != null && farmId != null) {
      fetch();
    }
    return () => {
      mount = false;
      signal.cancel();
    };
  }, [groupId, farmId]);
  return (
    <Container>
      <Row type="flex">
        <Col span={24}>
          <Title>
            ENTRADA/SAÍDA DE ANIMAIS
            <InfoTooltip title="Para Saídas de Animais, são considerados os animais vendidos, mortos e as saídas previstas a partir dos Cenários de Vendas registrados como “Favoritos”. Ao “Cancelar” um Cenário de Venda, as saídas previstas não serão mais exibidas no Gráfico." />
          </Title>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <Spin spinning={isLoading}>
            <CanvasJSChart options={chartOptions} />
          </Spin>
        </Col>
      </Row>
    </Container>
  );
}

export default LotInAndOutAnimalsDash;
