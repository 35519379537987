import React from "react";
import useUnitContext from "../../hooks/useUnitContext";
import { useSelector } from "react-redux";

import { Col, Icon, Row } from "antd";
import { CardCustom, Title } from "./styles";
import ButtonStandard from "../../components/utils/button";
import UnitForm from "./form";
import UnitTable from "./table";

// import { Container } from './styles';

const UnitLayout = () => {
  const { translation } = useSelector((state) => state.app);
  const { fetchData, openDrawer } = useUnitContext();
  return (
    <CardCustom>
      <Row className="rowHeader" justify="space-between">
        <Col span={8}>
          <Title>{translation.unit.title}</Title>
        </Col>
        <Col span={16}>
          <Row type="flex" justify="end" gutter={8}>
            <Col>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={fetchData}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </Col>
            <Col>
              <ButtonStandard buttonType="type8" onClick={() => openDrawer()}>
                {translation.unit.buttonNew}
              </ButtonStandard>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <UnitTable />
        </Col>
      </Row>
    </CardCustom>
  );
};

const Unit = () => {
  return (
    <>
      <UnitLayout />
      <UnitForm />
    </>
  );
};

export default Unit;
