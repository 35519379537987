import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import {
  Col,
  Icon,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  notification,
} from "antd";
import {
  createNewFinancialClass,
  findAllFinancialClassesByGroupIdAndFarmId,
  updateFinancialClass,
} from "../../../services/financialClassService";
import ButtonStandard from "../../utils/button";
import { Container, Footer } from "./styles";
import NumberFormat from "react-number-format";
import InfoTooltip from "../../utils/infoTooltip";

const validationSchema = Yup.object({
  financialNatureId: Yup.string().required(),
  name: Yup.string().trim().required(),
  shouldCost: Yup.boolean().required(),
  shouldDisbursement: Yup.boolean().required(),
  depreciation: Yup.number()
    .notRequired()
    .when(["shouldCost"], {
      is: (shouldCost) => shouldCost === true,
      then: Yup.number().min(1).max(9999).required(),
      otherwise: Yup.number().notRequired().nullable(),
    }),
  costCenterName: Yup.string()
    .notRequired()
    .when(["id"], {
      is: (id) => id === null,
      then: Yup.string().required(),
      otherwise: Yup.string().nullable().notRequired(),
    }),
});

const DrawerFinancialClass = ({
  isDrawerFinancialClassVisible = false,
  data,
  handleCloseDrawer,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [listFinancialNature, setListFinancialNature] = useState([]);
  const [isLoadingFinancialNature, setIsLoadingFinancialNature] =
    useState(false);

  const formRef = useRef(null);

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  function handleSubmitForm(values) {
    const title = "Ao confirmar, os itens abaixo devem ser considerados:";
    const content = (
      <ul>
        <li style={{ marginBottom: 26, marginTop: 25 }}>
          Atualize as informações dessa nova Classe em "Centro de Custo X Centro
          de Resultado" para todos os Centro de Custos em{" "}
          <strong>Parâmetros &gt; Custeio &gt; Centro de Custo</strong>, caso
          contrário, os custos e movimentações desta Classe não serão
          apropriados.
        </li>
      </ul>
    );
    if (values.id === null) {
      Modal.confirm({
        title,
        content,
        width: 580,
        icon: <Icon type="reload" />,
        centered: true,
        onOk: () => handleSaveClass(values),
      });
    } else {
      handleSaveClass(values);
    }
  }
  async function handleSaveClass(values) {
    setIsLoading(true);
    try {
      if (values.id === null) {
        await createNewFinancialClass({
          groupId,
          farmId,
          body: values,
        });
        notification.success({
          message: "Classe financeira criada com sucesso.",
        });
      } else {
        await updateFinancialClass({
          groupId,
          farmId,
          id: values.id,
          body: values,
        });
        notification.success({
          message: "Classe financeira atualizada com sucesso.",
        });
      }
      handleCloseDrawer();
    } catch (error) {
      notification.success({
        message:
          "Erro ao criar/atualizar Classe financeira. Contate o suporte.",
      });
    } finally {
      setIsLoading(false);
    }
  }
  function handleDrawerVisible(visible) {
    if (visible) {
      formRef.current.resetForm(
        data
          ? data
          : {
              id: null,
              name: null,
              status: "Active",
              financialNatureId: null,
              depreciation: "",
              shouldCost: true,
              shouldDisbursement: true,
              costCenterName: null,
            }
      );
    } else {
      formRef.current.resetForm({
        id: null,
        name: null,
        status: "Active",
        financialNatureId: null,
        depreciation: "",
        shouldCost: true,
        shouldDisbursement: true,
        costCenterName: null,
      });
    }
  }
  useEffect(() => {
    async function getFinancialClassAndNature() {
      setIsLoadingFinancialNature(true);
      try {
        const {
          data: { results: classes },
        } = await findAllFinancialClassesByGroupIdAndFarmId({
          groupId,
          farmId,
        });
        const natures = [
          ...new Map(
            classes
              .map((c) => c.financialNature)
              .map((item) => [item["id"], item])
          ).values(),
        ];
        setListFinancialNature(natures);
      } catch (error) {
      } finally {
        setIsLoadingFinancialNature(false);
      }
    }

    getFinancialClassAndNature();
  }, [farmId, groupId]);
  return (
    <Container
      title={
        data?.id == null
          ? translation.financialClass.form.titleCreateNew
          : translation.financialClass.form.titleEdit
      }
      width={700}
      onClose={handleCloseDrawer}
      maskClosable={false}
      visible={isDrawerFinancialClassVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Spin spinning={isLoading}>
        <Formik
          ref={formRef}
          enableReinitialize={true}
          initialValues={data}
          onSubmit={handleSubmitForm}
          validationSchema={validationSchema}
          render={({
            values,
            errors,
            submitCount,
            setFieldValue,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="drawerForm">
                {Object.entries(errors).length > 0 && submitCount > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                {values.id === null && (
                  <>
                    {/* Financial Nature */}
                    <Row type="flex" className="rowLabel">
                      <label
                        className={
                          errors.financialNatureId && submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {translation.financialClass.form.financialNatureId}*
                      </label>
                    </Row>
                    <Row type="flex">
                      <Select
                        name="financialNatureId"
                        value={values.financialNatureId || undefined}
                        placeholder={translation.defaultSelectPlaceholder}
                        loading={isLoadingFinancialNature}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          setFieldValue("financialNatureId", value);
                        }}
                      >
                        {listFinancialNature.map((l) => (
                          <Select.Option key={l.id} value={l.id}>
                            {l.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Row>
                  </>
                )}
                {/* Name */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={errors.name && submitCount > 0 ? "error" : ""}
                  >
                    {translation.financialClass.form.name}*
                  </label>
                </Row>
                <Row type="flex">
                  <Input
                    name="name"
                    value={values.name}
                    maxLength={25}
                    disabled={!values.groupId && !values.farmId}
                    placeholder={translation.defaultPlaceholder}
                    autoCapitalize="off"
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                  />
                </Row>
                {/* ShouldCost */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={
                      errors.shouldCost && submitCount > 0 ? "error" : ""
                    }
                  >
                    {translation.financialClass.form.shouldCost}*{" "}
                    <InfoTooltip title="Selecione se as movimentações financeiras, associadas a esta Classe, deverão ser consideradas para compor o custo de produção dos animais. " />
                  </label>
                </Row>
                <Row type="flex">
                  <Radio.Group
                    value={values.shouldCost}
                    onChange={(e) => {
                      setFieldValue("shouldCost", e.target.value);
                      if (e.target.value === false) {
                        setFieldValue("depreciation", null);
                      }
                    }}
                  >
                    <Radio style={{ color: "#4f4f4f" }} value={true}>
                      {translation.buttons.yes}
                    </Radio>
                    <Radio style={{ color: "#4f4f4f" }} value={false}>
                      {translation.buttons.no}
                    </Radio>
                  </Radio.Group>
                </Row>
                {/* shouldDisbursement */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={
                      errors.shouldDisbursement && submitCount > 0
                        ? "error"
                        : ""
                    }
                  >
                    {translation.financialClass.form.shouldDisbursement}*{" "}
                    <InfoTooltip title="Selecione se as movimentações financeiras, associadas a esta Classe, deverão ser consideradas para compor o custo de desembolso dos animais. " />
                  </label>
                </Row>
                <Row type="flex">
                  <Radio.Group
                    value={values.shouldDisbursement}
                    onChange={(e) => {
                      setFieldValue("shouldDisbursement", e.target.value);
                    }}
                  >
                    <Radio style={{ color: "#4f4f4f" }} value={true}>
                      {translation.buttons.yes}
                    </Radio>
                    <Radio style={{ color: "#4f4f4f" }} value={false}>
                      {translation.buttons.no}
                    </Radio>
                  </Radio.Group>
                </Row>
                {/* Depreciation */}
                {values.shouldCost && (
                  <>
                    <Row type="flex" className="rowLabel">
                      <label
                        className={
                          errors.depreciation && submitCount > 0 ? "error" : ""
                        }
                      >
                        {translation.financialClass.form.depreciation}*
                      </label>
                    </Row>
                    <Row type="flex">
                      <Col span={8}>
                        <NumberFormat
                          customInput={Input}
                          value={values.depreciation}
                          placeholder={translation.defaultPlaceholder}
                          decimalScale={0}
                          allowNegative={false}
                          max={9999}
                          addonAfter="meses"
                          onValueChange={({ floatValue }) =>
                            setFieldValue(
                              "depreciation",
                              floatValue
                                ? floatValue > 9999
                                  ? 9999
                                  : floatValue
                                : null
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </>
                )}
                {values.id === null && (
                  <>
                    {/* CostCenterName */}
                    <Row type="flex" className="rowLabel">
                      <label
                        className={
                          errors.costCenterName && submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {translation.financialClass.form.costCenterName}*
                      </label>
                    </Row>
                    <Row type="flex">
                      <Select
                        name="costCenterName"
                        value={values.costCenterName || undefined}
                        placeholder={translation.defaultSelectPlaceholder}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          setFieldValue("costCenterName", value);
                        }}
                      >
                        <Select.Option value="PRODUCAO_ANIMAL">
                          Produção Animal
                        </Select.Option>
                        <Select.Option value="UTILIDADES">
                          Utilidades
                        </Select.Option>
                        <Select.Option value="INFRAEST_PRODUTIVA">
                          Infraest. Produtiva
                        </Select.Option>
                        <Select.Option value="INFRAEST_OPERACIONAL">
                          Infraest. Operacional
                        </Select.Option>
                        <Select.Option value="INFRAEST_APOIO">
                          Infraest. Apoio
                        </Select.Option>
                        <Select.Option value="TAXA_IMPOSTOS">
                          Taxas & Impostos
                        </Select.Option>
                        <Select.Option value="SERVICOS">Serviços</Select.Option>
                        <Select.Option value="INVSUMOS_OPERACIONAIS">
                          Insumos Operacionais
                        </Select.Option>
                        <Select.Option value="PRODUCAO_PASTO_AGRICOLA">
                          Produção Pasto/Agrícola
                        </Select.Option>
                        <Select.Option value="OUTRAS_DESPESAS">
                          Outras Despesas
                        </Select.Option>
                        <Select.Option value="OUTRAS_RECEITAS">
                          Outras Receitas
                        </Select.Option>
                        <Select.Option value="VENDA_DA_PRODUCAO">
                          Venda da Produção
                        </Select.Option>
                      </Select>
                    </Row>
                  </>
                )}
              </div>
              <Footer>
                <Row type="flex">
                  <Col span={24} className="buttonsDiv">
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={handleCloseDrawer}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard type="submit" buttonType="type6">
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
      </Spin>
    </Container>
  );
};

export default DrawerFinancialClass;
