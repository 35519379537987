import getHeaders from "./getHeaders";
import apiMiddleware from "../config/api_middleware";

export function callValidationGdp(payload) {
  const { groupId, farmId, lotId, breedId, signal, body } = payload;
  if (lotId === null) {
    return apiMiddleware.post(
      `/bovexo/${groupId}/farms/${farmId}/validate/gdp?breedId=${breedId}`,
      body,
      { ...getHeaders(false, signal), timeout: 60000 }
    );
  } else {
    return apiMiddleware.post(
      `/bovexo/${groupId}/farms/${farmId}/validate/gdp?lotId=${lotId}`,
      body,
      { ...getHeaders(false, signal), timeout: 60000 }
    );
  }
}
