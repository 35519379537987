import React, { useState, useEffect } from "react";
import CanvasJSReact from "../../../assets/canvasjs.react";
import moment from "moment";
/** Redux libs */
import { useSelector, useDispatch } from "react-redux";
import { Creators as SaleScenarioActions } from "../../../store/ducks/saleScenario";

/** Components */
import {
  Container,
  Footer,
  RadioButtonCustom,
  ListSaleCommitments,
  CustomDivider,
} from "./styles";
import {
  Row,
  Col,
  Input,
  Radio,
  Icon,
  Select,
  DatePicker,
  Spin,
  notification,
  Switch,
} from "antd";
import ButtonStandard from "../../../components/utils/button";
import PencilIcon from "../../../components/utils/icons/pencil";
import NumberFormat from "react-number-format";
import ModalCommitment from "./editModalCommitment";
import InfoTooltip from "../../utils/infoTooltip";

/* Services */
import { getMarketAnalysisDropDown } from "../../../services/marketAnalysisService";
import { getFuturePriceChart } from "../../../services/futurePriceService";
import { saveSaleScenarioParam } from "../../../services/saleScenarioService";
import { getParameterItems } from "../../../services/generalParameterService";
import { indexAllDropdownFarmQuota } from "../../../services/quotaService";

const DrawerEditSaleScenarioParameters = () => {
  /** Variables */
  const [loadingListCommitments, setLoadingListCommitments] = useState(true);
  // If in the future add new fields in SaleScenarioParam.class, update saleScenario(duck) => newSaleScenario.saleScenarioParam
  const [form, setForm] = useState(null);
  const [errors, setErrors] = useState([]);
  const [listMarketAnalysis, setListMarketAnalysis] = useState([]);
  const [quotas, setQuotas] = useState([]);
  const [loadingListMarketAnalysis, setLoadingListMarketAnalysis] =
    useState(false);
  const [loadingInternal, setLoadingInternal] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const [loadingQuota, setLoadingQuota] = useState(false);
  const [dataChart, setDataChart] = useState([]);
  const dateFormat = "DD/MM/YYYY";
  const { Group: InputGroup } = Input;
  const { Group: RadioGroup } = Radio;
  const { CanvasJSChart } = CanvasJSReact;
  const selectAnimalsInTruckUnit = (
    <Select
      value={form?.animalsInTruckUnit != null ? form?.animalsInTruckUnit : "UA"}
      onChange={(value) => {
        setForm({
          ...form,
          animalsInTruckUnit: value,
        });
      }}
    >
      <Select.Option value="UA">UA</Select.Option>
      <Select.Option value="cab">cabeças</Select.Option>
    </Select>
  );
  /* Chart options */
  const options = {
    animationEnabled: true,
    zoomEnabled: true,
    height: 320,
    axisX: {
      labelFontSize: 12,
      valueFormatString: "MM/YY",
      interval: 1,
      intervalType: "month",
      viewportMinimum: moment().toDate(),
      viewportMaximum: moment().add(1, "years").toDate(),
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
      },
    },
    toolTip: {
      fontFamily: "Asap",
      shared: true,
    },
    axisY: {
      labelFontSize: 12,
      includeZero: true,
      valueFormatString: "###0.##",
      suffix: " R$/@",
      gridDashType: "dot",
    },
    data: dataChart,
  };
  const StrongInput = (field, helper) => (
    <div className="inputStrong">
      <span>Forte?</span> <InfoTooltip title={helper} />{" "}
      <Switch
        checked={form?.[field] === true ? true : false}
        onChange={(checked, event) =>
          setForm({
            ...form,
            [field]: checked,
          })
        }
      />
    </div>
  );
  /** Redux variables */
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const {
    newSaleScenario: { saleScenarioParam, saleCommitments },
    showDrawerEditParameter,
    loadingRequestEditParameter,
    maxDeadLineGoal,
    minDeadLineGoal,
  } = useSelector((state) => state.saleScenario);
  const dispatch = useDispatch();
  /** Effect */

  // Set Data in Form and load Chart
  useEffect(() => {
    async function renderChart(marketAnalysisId) {
      setLoadingChart(true);
      let chartData = null;
      try {
        const {
          data: { results },
        } = await getFuturePriceChart({ groupId, farmId, marketAnalysisId });
        if (results.length > 0) {
          chartData = results.map((r) => {
            r.x = moment(r.x).toDate();
            r.y = Number.parseFloat(Number(r.y).toFixed(2));
            return r;
          });
        } else {
          chartData = null;
        }
      } catch (error) {
        chartData = null;
      }
      if (chartData !== null) {
        let nameMarketAnalysis =
          listMarketAnalysis.find((ma) => ma.id === marketAnalysisId)?.name ||
          "Valores";
        setDataChart([
          {
            type: "line",
            showInLegend: true,
            name: nameMarketAnalysis,
            color: "#4A85AE",
            xValueFormatString: "DD/MM/YYYY",
            yValueFormatString: "R$#######.00",
            dataPoints: chartData,
          },
        ]);
      } else {
        setDataChart([]);
      }

      setLoadingChart(false);
    }
    async function getCarbonCreditParam() {
      try {
        let {
          data: { results },
        } = await getParameterItems({ groupId, farmId, code: 1007 });
        setForm((old) => ({
          ...old,
          carbonCredit: results?.value,
        }));
      } catch (error) {}
    }
    setForm(saleScenarioParam);
    if (
      saleScenarioParam != null &&
      saleScenarioParam.marketAnalysisId != null
    ) {
      renderChart(saleScenarioParam.marketAnalysisId);
      if (saleScenarioParam?.carbonCredit === null) {
        getCarbonCreditParam();
      }
    }
  }, [groupId, farmId, saleScenarioParam, listMarketAnalysis]);

  // Load commitments
  useEffect(() => {
    setLoadingListCommitments(true);
    setTimeout(() => setLoadingListCommitments(false), 500);
  }, [saleCommitments]);

  // Load dropdowns data
  useEffect(() => {
    // Get MarketAnalysis
    async function fetchMarketAnalysis() {
      setLoadingListMarketAnalysis(true);
      try {
        const {
          data: { results },
        } = await getMarketAnalysisDropDown({ groupId, farmId });
        setListMarketAnalysis(results);
        setLoadingListMarketAnalysis(false);
      } catch (error) {
        setListMarketAnalysis([]);
        setLoadingListMarketAnalysis(false);
      }
    }

    // Get Quotas
    async function fetchQuotas() {
      setLoadingQuota(true);
      try {
        const {
          data: { results },
        } = await indexAllDropdownFarmQuota({ groupId, farmId });
        setQuotas(results);
        setLoadingQuota(false);
      } catch (error) {
        setLoadingQuota([]);
        setLoadingQuota(false);
      }
    }

    fetchMarketAnalysis();
    fetchQuotas();
  }, [groupId, farmId]);

  /** Methods */
  function editCommitment(item) {
    dispatch(SaleScenarioActions.openModalSaleCommitment(item));
  }
  function removeCommitment(item) {
    dispatch(
      SaleScenarioActions.addUpdateOrRemoveCommitments(item, item.key, "remove")
    );
  }
  async function renderChart(marketAnalysisId) {
    setLoadingChart(true);
    let chartData = null;
    try {
      const {
        data: { results },
      } = await getFuturePriceChart({ groupId, farmId, marketAnalysisId });
      if (results.length > 0) {
        chartData = results.map((r) => {
          r.x = moment(r.x).toDate();
          r.y = Number.parseFloat(Number(r.y).toFixed(2));
          return r;
        });
      } else {
        chartData = null;
      }
    } catch (error) {
      chartData = null;
    }
    if (chartData !== null) {
      let nameMarketAnalysis =
        listMarketAnalysis.find((ma) => ma.id === marketAnalysisId)?.name ||
        "Valores";
      setDataChart([
        {
          type: "line",
          showInLegend: true,
          name: nameMarketAnalysis,
          color: "#4A85AE",
          xValueFormatString: "DD/MM/YYYY",
          yValueFormatString: "R$#######.00",
          dataPoints: chartData,
        },
      ]);
    } else {
      setDataChart([]);
    }

    setLoadingChart(false);
  }

  function validateForm() {
    let errorArray = [];
    if (form == null) {
      errorArray.push("name");
      errorArray.push("animalsInTruck");
      errorArray.push("minimumAge");
      errorArray.push("minimumWeight");
      errorArray.push("maximumWeight");
      errorArray.push("allowSeparateSell");
      errorArray.push("marketAnalysisId");
      errorArray.push("capacitySupportRestriction");
      errorArray.push("deadlineGoal");
      errorArray.push("maximumAge");
    }

    if (!form?.saleScenario?.name || form?.saleScenario?.name.length === 0)
      errorArray.push("name");
    if (form?.retroactiveDate == null && form?.retroactive)
      errorArray.push("retroactiveDate");
    if (form?.animalsInTruck == null) errorArray.push("animalsInTruck");

    if (form?.useQuotaCriteria === false) {
      if (form?.minimumAge == null || form?.minimumAge === 0)
        errorArray.push("minimumAge");
      if (form?.maximumAge == null || form?.maximumAge === 0)
        errorArray.push("maximumAge");
      if (
        form?.maximumAge !== null &&
        form?.maximumAge > 0 &&
        form?.maximumAge < form?.minimumAge
      ) {
        errorArray.push("maximumAge");
        notification.error({
          message:
            'O Campo "Idade máxima para venda" deve ser maior que a idade mínima.',
        });
      }
      if (form?.minimumWeight == null || form?.minimumWeight === 0)
        errorArray.push("minimumWeight");
      if (form?.maximumWeight == null || form?.maximumWeight === 0)
        errorArray.push("maximumWeight");
      if (
        form?.maximumWeight !== null &&
        form?.maximumWeight > 0 &&
        form?.maximumWeight < form?.minimumWeight
      ) {
        errorArray.push("maximumWeight");
        notification.error({
          message:
            'O Campo "Peso máximo para venda" deve ser maior que o peso mínimo.',
        });
      }
    } else if (
      form.useQuotaCriteria === true &&
      (form?.quotaId === null || form?.quotaId === undefined)
    ) {
      errorArray.push("useQuotaCriteria");
    }

    if (form?.allowSeparateSell == null) errorArray.push("allowSeparateSell");
    if (form?.marketAnalysisId == null) errorArray.push("marketAnalysisId");
    if (form?.capacitySupportRestriction == null)
      errorArray.push("capacitySupportRestriction");
    if (
      form?.useCustomCarcassHarnessing === true &&
      (form?.carcassHarnessing === null || form?.carcassHarnessing <= 0)
    )
      errorArray.push("carcassHarnessing");
    if (
      form?.useCustomCarcassHarnessing === true &&
      form?.carcassHarnessing !== null &&
      (form?.carcassHarnessing < 45 || form?.carcassHarnessing > 60)
    ) {
      errorArray.push("carcassHarnessing");
      notification.error({
        message: "Aproveitamento de Carcaça inválida",
        description:
          'O valor do campo "Aproveitamento de Carcaça" deve estar entre o intervalo de valores (45% e 60%)',
      });
    }
    setErrors(errorArray);
    return errorArray.length > 0 ? false : true;
  }

  function closeDrawer() {
    dispatch(SaleScenarioActions.resetData());
  }

  async function handleSubmitForm() {
    let result = validateForm();
    if (result) {
      setLoadingInternal(true);
      try {
        const { id, ...formWithoutId } = form;
        const saleScenarioId = form.saleScenario.id;
        const body = {
          ...formWithoutId,
          saleCommitments,
          goalType: "MaxProfit",
        };
        await saveSaleScenarioParam({
          groupId,
          farmId,
          saleScenarioId,
          body,
        });
        notification.success({
          message: "Os parâmetros foram salvos !!",
          description: "Caso queira um novo resultado, clique em Reprocessar.",
        });
        dispatch(SaleScenarioActions.refreshTable());
      } catch (error) {
      } finally {
        setLoadingInternal(false);
      }
    }
  }

  return (
    <Container
      title="Editando Parâmetros do C. de Venda"
      width={700}
      onClose={closeDrawer}
      maskClosable={false}
      visible={showDrawerEditParameter}
    >
      <Spin spinning={loadingRequestEditParameter || loadingInternal}>
        <Row>
          <Col
            className="customCol colParam editing"
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
          >
            {/* Body */}
            <div>
              {errors.length > 0 && (
                <Row type="flex" justify="center">
                  <span className="errorMessage">
                    {translation.error.formError}
                  </span>
                </Row>
              )}
              {/* Input name */}
              <Row>
                <label className={errors.includes("name") ? "error" : ""}>
                  Nome *
                </label>
              </Row>
              <Row>
                <Input
                  style={{ width: 300 }}
                  value={form?.saleScenario?.name}
                  name="name"
                  maxLength={40}
                  onChange={(e) => {
                    form.saleScenario.name = e.target.value;
                    setForm({ ...form });
                    setErrors(errors.filter((e) => e !== "name"));
                  }}
                />
              </Row>
              {/* Input retroactive */}
              <Row>
                <label
                  className={errors.includes("retroactive") ? "error" : ""}
                >
                  Este cenário é retroativo? *
                </label>
              </Row>
              <Row>
                <RadioGroup
                  name="retroactive"
                  value={form != null ? form.retroactive : false}
                  defaultValue={false}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      retroactive: e.target.value,
                    });
                  }}
                >
                  <RadioButtonCustom value={true}>
                    {translation.buttons.yes}
                  </RadioButtonCustom>
                  <RadioButtonCustom value={false}>
                    {translation.buttons.no}
                  </RadioButtonCustom>
                </RadioGroup>
              </Row>
              {/* Input retroactiveDate */}
              {form != null && form.retroactive && (
                <>
                  <Row>
                    <label
                      className={
                        errors.includes("retroactiveDate") ? "error" : ""
                      }
                    >
                      Digite a data de inicio do processamento *
                    </label>
                  </Row>
                  <Row>
                    <DatePicker
                      name="retroactiveDate"
                      value={
                        form != null
                          ? form.retroactiveDate != null
                            ? moment(form.retroactiveDate, "YYYY-MM-DD")
                            : null
                          : null
                      }
                      disabledDate={(current) => current && current > moment()}
                      placeholder={translation.defaultDatePickerPlaceholder}
                      allowClear={false}
                      format={dateFormat}
                      onChange={(date, dateString) => {
                        if (
                          date != null &&
                          date.isBefore(moment(), "days") &&
                          date.isSameOrBefore(
                            moment(maxDeadLineGoal),
                            "days"
                          ) &&
                          date.isSameOrAfter(moment(minDeadLineGoal), "days")
                        ) {
                          setForm({
                            ...form,
                            retroactiveDate: date,
                          });
                          setErrors(
                            errors.filter((e) => e !== "retroactiveDate")
                          );
                        }
                      }}
                    />
                  </Row>
                </>
              )}
              {/* Input animalsInTruck */}
              <Row type="flex" gutter={8} justify="start" align="middle">
                <label
                  className={errors.includes("animalsInTruck") ? "error" : ""}
                >
                  Animais no caminhão *
                </label>
                {StrongInput(
                  "strongAnimalsInTruckRestriction",
                  "Ao marcar está opção, o sistema buscará a melhor oportunidade de venda, mesmo que o lote seja descascado, mas respeitando a quantidade de animais por caminhão."
                )}
              </Row>
              <Row>
                <NumberFormat
                  customInput={Input}
                  value={form?.animalsInTruck}
                  style={{ width: 300 }}
                  name="animalsInTruck"
                  placeholder={translation.defaultPlaceholder}
                  addonAfter={selectAnimalsInTruckUnit}
                  decimalScale={form?.animalsInTruckUnit === "cab" ? 0 : 2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      animalsInTruck:
                        floatValue >= 0
                          ? floatValue > 99
                            ? 99
                            : floatValue
                          : null,
                    });
                    setErrors(errors.filter((e) => e !== "animalsInTruck"));
                  }}
                />
              </Row>
              {/* Input quota */}
              <Row type="flex" gutter={8} justify="start">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row>
                    <label
                      className={
                        errors.includes("useQuotaCriteria") ? "error" : ""
                      }
                    >
                      Critérios de venda *
                    </label>
                  </Row>
                  <Row>
                    <RadioGroup
                      name="useQuotaCriteria"
                      value={form?.useQuotaCriteria}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          useQuotaCriteria: e.target.value,
                          quotaId: null,
                        });
                        setErrors(
                          errors.filter((e) => e !== "useQuotaCriteria")
                        );
                      }}
                    >
                      <Radio value={true}>Pré-Fixadas (Cotas)</Radio>
                      <Radio value={false}>Livre</Radio>
                    </RadioGroup>
                  </Row>
                  {form?.useQuotaCriteria === true ? (
                    <Row>
                      <Select
                        name="quotaId"
                        allowClear
                        style={{ width: "100%" }}
                        value={
                          form != null && form.quotaId != null
                            ? form.quotaId
                            : undefined
                        }
                        loading={loadingQuota}
                        placeholder={translation.defaultSelectPlaceholder}
                        onChange={(value) => {
                          setForm({
                            ...form,
                            quotaId: value,
                          });
                          setErrors(
                            errors.filter((e) => e !== "useQuotaCriteria")
                          );
                        }}
                      >
                        {quotas.map((q) => (
                          <Select.Option key={q.id} value={q.id}>
                            {q.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Row>
                  ) : null}
                </Col>
              </Row>
              {form?.useQuotaCriteria === false ? (
                <>
                  {/* Input minimumAge and maximumAge */}
                  <Row type="flex" gutter={8} justify="start" align="middle">
                    <label>Idade para Venda</label>
                    {StrongInput(
                      "strongAgeRestriction",
                      "Ao selecionar esta opção, o intervalo entre a idade mínima e máxima para venda, se tornará uma restrição forte, desta forma, o sistema buscará a melhor oportunidade de venda, respeitando esta premissa. Caso, esta opção, esteja desmarcada, o sistema  irá considerar o intervalo, porém, caso exista uma possibilidade melhor, mesmo que fora deste intervalo de idade, o sistema retornará com esta melhor opção."
                    )}
                  </Row>
                  <Row>
                    <label
                      className={errors.includes("minimumAge") ? "error" : ""}
                    >
                      Idade mínima para venda*
                    </label>
                  </Row>
                  <Row type="flex" align="middle">
                    <InputGroup compact>
                      <Input
                        type="number"
                        style={{ width: 80, textAlign: "center" }}
                        value={form != null ? form.minimumAge : null}
                        name="ageDay"
                        min="0"
                        placeholder="Dias"
                        onChange={(e) => {
                          setForm({
                            ...form,
                            minimumAge:
                              e.target.value > 0
                                ? e.target.value > 36135
                                  ? 36135
                                  : e.target.value
                                : 0,
                          });
                          setErrors(errors.filter((e) => e !== "minimumAge"));
                        }}
                      />
                      <Input
                        style={{
                          width: 80,
                          textAlign: "center",
                          borderLeft: "1px solid #c4c4c4",
                        }}
                        type="number"
                        name="ageMonth"
                        min="0"
                        placeholder="Meses"
                        value={
                          form != null && form.minimumAge != null
                            ? Number(form.minimumAge / 30.417).toFixed(0)
                            : null
                        }
                        onChange={(e) => {
                          setForm({
                            ...form,
                            minimumAge:
                              e.target.value > 0
                                ? e.target.value > 1188
                                  ? Math.floor(1188 * 30.417)
                                  : Math.floor(e.target.value * 30.417)
                                : 0,
                          });
                          setErrors(errors.filter((e) => e !== "minimumAge"));
                        }}
                      />
                      <Input
                        style={{
                          width: 80,
                          textAlign: "center",
                          borderLeft: "1px solid #c4c4c4",
                        }}
                        type="number"
                        name="ageYear"
                        min="0"
                        placeholder="Anos"
                        value={
                          form != null && form.minimumAge != null
                            ? form.minimumAge >= 365
                              ? Math.floor(form.minimumAge / 365)
                              : 0
                            : null
                        }
                        onChange={(e) => {
                          setForm({
                            ...form,
                            minimumAge:
                              e.target.value > 0
                                ? e.target.value > 99
                                  ? 99 * 365
                                  : e.target.value * 365
                                : 0,
                          });
                          setErrors(errors.filter((e) => e !== "minimumAge"));
                        }}
                      />
                    </InputGroup>
                  </Row>
                  <Row>
                    <label
                      className={errors.includes("maximumAge") ? "error" : ""}
                    >
                      Idade máxima para venda
                    </label>
                  </Row>
                  <Row type="flex" align="middle">
                    <InputGroup compact>
                      <Input
                        type="number"
                        style={{ width: 80, textAlign: "center" }}
                        value={form != null ? form.maximumAge : null}
                        name="ageDay"
                        min="0"
                        placeholder="Dias"
                        onChange={(e) => {
                          setForm({
                            ...form,
                            maximumAge:
                              e.target.value > 0
                                ? e.target.value > 36135
                                  ? 36135
                                  : e.target.value
                                : 0,
                          });
                          setErrors(errors.filter((e) => e !== "maximumAge"));
                        }}
                      />
                      <Input
                        style={{
                          width: 80,
                          textAlign: "center",
                          borderLeft: "1px solid #c4c4c4",
                        }}
                        type="number"
                        name="ageMonth"
                        min="0"
                        placeholder="Meses"
                        value={
                          form != null && form.maximumAge != null
                            ? Number(form.maximumAge / 30.417).toFixed(0)
                            : null
                        }
                        onChange={(e) => {
                          setForm({
                            ...form,
                            maximumAge:
                              e.target.value > 0
                                ? e.target.value > 1188
                                  ? Math.floor(1188 * 30.417)
                                  : Math.floor(e.target.value * 30.417)
                                : 0,
                          });
                          setErrors(errors.filter((e) => e !== "maximumAge"));
                        }}
                      />
                      <Input
                        style={{
                          width: 80,
                          textAlign: "center",
                          borderLeft: "1px solid #c4c4c4",
                        }}
                        type="number"
                        name="ageYear"
                        min="0"
                        placeholder="Anos"
                        value={
                          form != null && form.maximumAge != null
                            ? form.maximumAge >= 365
                              ? Math.floor(form.maximumAge / 365)
                              : 0
                            : null
                        }
                        onChange={(e) => {
                          setForm({
                            ...form,
                            maximumAge:
                              e.target.value > 0
                                ? e.target.value > 99
                                  ? 99 * 365
                                  : e.target.value * 365
                                : 0,
                          });
                          setErrors(errors.filter((e) => e !== "maximumAge"));
                        }}
                      />
                    </InputGroup>
                  </Row>
                  {/* minimum weight and maximum weight */}
                  <Row type="flex" gutter={8} justify="start" align="middle">
                    <label>Peso para Venda</label>
                    {StrongInput(
                      "strongWeightRestriction",
                      "Ao selecionar esta opção, o intervalo entre o peso mínimo e máximo para venda, se tornará uma restrição forte, desta forma, o sistema buscará a melhor oportunidade de venda, respeitando esta premissa. Caso, esta opção, esteja desmarcada, o sistema  irá considerar o intervalo, porém, caso exista uma possibilidade melhor, mesmo que fora deste intervalo de peso, o sistema retornará com esta melhor opção."
                    )}
                  </Row>
                  <Row>
                    <label
                      className={
                        errors.includes("minimumWeight") ? "error" : ""
                      }
                    >
                      Peso mínimo para venda*
                    </label>
                  </Row>
                  <Row>
                    <NumberFormat
                      customInput={Input}
                      style={{ width: 300 }}
                      value={form?.minimumWeight}
                      placeholder={translation.defaultPlaceholder}
                      name="minimumWeight"
                      addonAfter="Kg"
                      decimalScale={"2"}
                      onValueChange={({ floatValue }) => {
                        setForm({
                          ...form,
                          minimumWeight:
                            floatValue >= 0
                              ? floatValue > 9999
                                ? 9999
                                : floatValue
                              : null,
                        });
                        setErrors(errors.filter((e) => e !== "minimumWeight"));
                      }}
                    />
                  </Row>
                  {/* maximum weight */}
                  <Row>
                    <label
                      className={
                        errors.includes("maximumWeight") ? "error" : ""
                      }
                    >
                      Peso máximo para venda*
                    </label>
                  </Row>
                  <Row>
                    <NumberFormat
                      customInput={Input}
                      style={{ width: 300 }}
                      value={form?.maximumWeight}
                      placeholder={translation.defaultPlaceholder}
                      name="maximumWeight"
                      addonAfter="Kg"
                      decimalScale={"2"}
                      onValueChange={({ floatValue }) => {
                        setForm({
                          ...form,
                          maximumWeight:
                            floatValue >= 0
                              ? floatValue > 9999
                                ? 9999
                                : floatValue
                              : null,
                        });
                        setErrors(errors.filter((e) => e !== "maximumWeight"));
                      }}
                    />
                  </Row>
                </>
              ) : null}

              {/* Input useCustomCarcassHarnessing */}
              <Row>
                <label
                  className={
                    errors.includes("useCustomCarcassHarnessing")
                      ? "formLabel error"
                      : "formLabel"
                  }
                >
                  Informar novo % de Aprov. de Carcaça?{" "}
                  <InfoTooltip title="Por padrão, será utilizado o % de Aproveitamento de Carcaça da Estratégia de Dieta associada ao Lote. Nesta opção, poderá informar um novo % a ser considerado neste Cenário de Venda." />
                </label>
              </Row>
              <Row>
                <RadioGroup
                  name="useCustomCarcassHarnessing"
                  value={form?.useCustomCarcassHarnessing}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      useCustomCarcassHarnessing: e.target.value,
                    });
                    if (e.target.value === false) {
                      setForm({
                        ...form,
                        useCustomCarcassHarnessing: e.target.value,
                        carcassHarnessing: null,
                      });
                    }
                  }}
                >
                  <Radio value={true}>{translation.buttons.yes}</Radio>
                  <Radio value={false}>{translation.buttons.no}</Radio>
                </RadioGroup>
              </Row>
              {form?.useCustomCarcassHarnessing === true && (
                <>
                  <Row>
                    <label
                      className={
                        errors.includes("carcassHarnessing")
                          ? "formLabel error"
                          : "formLabel"
                      }
                    >
                      Aprov. de Carcaça
                    </label>
                  </Row>
                  <Row>
                    <NumberFormat
                      customInput={Input}
                      value={
                        form?.carcassHarnessing !== null
                          ? form?.carcassHarnessing
                          : null
                      }
                      placeholder={translation.defaultPlaceholder}
                      name="carcassHarnessing"
                      allowNegative={false}
                      addonAfter="%"
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      onValueChange={({ floatValue }) => {
                        setForm({
                          ...form,
                          carcassHarnessing: floatValue
                            ? floatValue <= 100
                              ? floatValue
                              : 100
                            : null,
                        });
                        setErrors((old) =>
                          old.filter((e) => e !== "carcassHarnessing")
                        );
                      }}
                    />
                  </Row>
                </>
              )}
              {/* Input allowSeparateSell */}
              <Row>
                <label
                  className={
                    errors.includes("allowSeparateSell") ? "error" : ""
                  }
                >
                  Lotes podem sofrer descasca? *
                </label>
              </Row>
              <Row>
                <RadioGroup
                  name="allowSeparateSell"
                  value={form != null ? form.allowSeparateSell : false}
                  defaultValue={false}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      allowSeparateSell: e.target.value,
                    });
                  }}
                >
                  <RadioButtonCustom value={true}>
                    {translation.buttons.yes}
                  </RadioButtonCustom>
                  <RadioButtonCustom value={false}>
                    {translation.buttons.no}
                  </RadioButtonCustom>
                </RadioGroup>
              </Row>
              {/* Input saleCommitments */}
              <Row>
                <label>Cenário de venda possui algum compromisso?</label>
              </Row>
              <Row>
                {saleCommitments.length > 0 ? (
                  <ListSaleCommitments
                    itemLayout="horizontal"
                    dataSource={saleCommitments}
                    loading={loadingListCommitments}
                    renderItem={(item, index) => (
                      <ListSaleCommitments.Item key={item.key}>
                        <Row type="flex" justify="space-between">
                          <div>
                            <span>
                              <strong>{index + 1}.</strong>
                              {` ${
                                item.saleCommitmentType === "AmountAnimals"
                                  ? `${item.commitmentValue} animal(is)`
                                  : `R$ ${item.commitmentValue.toFixed(2)}`
                              } até ${moment(item.commitmentDate).format(
                                "DD/MM/YYYY"
                              )}`}
                            </span>
                          </div>
                          <div className="buttonGroup">
                            <Icon
                              component={PencilIcon}
                              onClick={() => editCommitment(item)}
                            />
                            <Icon
                              type="delete"
                              className="delete"
                              onClick={() => removeCommitment(item)}
                            />
                          </div>
                        </Row>
                      </ListSaleCommitments.Item>
                    )}
                  />
                ) : (
                  <p className="emptySaleCommitments">
                    Nenhum compromisso informado
                  </p>
                )}
              </Row>
              <Row>
                <button
                  className="addSaleCommitment"
                  onClick={() =>
                    dispatch(SaleScenarioActions.openModalSaleCommitment())
                  }
                >
                  <Icon type="plus" />
                  Adicionar
                </button>
              </Row>
              {/* Input MarketAnalysis */}
              <Row>
                <label
                  className={errors.includes("marketAnalysisId") ? "error" : ""}
                >
                  Análise de mercado *
                </label>
              </Row>
              <Row>
                <Select
                  name="marketAnalysisId"
                  style={{ width: "100%" }}
                  value={
                    form != null && form.marketAnalysisId != null
                      ? form.marketAnalysisId
                      : undefined
                  }
                  loading={loadingListMarketAnalysis}
                  placeholder={translation.defaultSelectPlaceholder}
                  onChange={(value) => {
                    setForm({
                      ...form,
                      marketAnalysisId: value,
                    });
                    setErrors(errors.filter((e) => e !== "marketAnalysisId"));
                    renderChart(value);
                  }}
                >
                  {listMarketAnalysis
                    .filter((ma) => ma.status === "Active")
                    .map((ma) => (
                      <Select.Option key={ma.id} value={ma.id}>
                        {ma.name}
                      </Select.Option>
                    ))}
                </Select>
              </Row>
              {/* Chart */}
              {(dataChart.length > 0 || loadingChart === true) && (
                <Row>
                  <Spin spinning={loadingChart}>
                    <CanvasJSChart options={options} />
                  </Spin>
                </Row>
              )}
              <CustomDivider dashed />
              {/* Input useCarbonCredit */}
              <Row>
                <label
                  className={errors.includes("useCarbonCredit") ? "error" : ""}
                >
                  Simular potencial de créditos carbono ? *
                </label>
              </Row>
              <Row>
                <RadioGroup
                  name="useCarbonCredit"
                  value={form != null ? form.useCarbonCredit : false}
                  defaultValue={false}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      useCarbonCredit: e.target.value,
                    });
                  }}
                >
                  <RadioButtonCustom value={true}>
                    {translation.buttons.yes}
                  </RadioButtonCustom>
                  <RadioButtonCustom value={false}>
                    {translation.buttons.no}
                  </RadioButtonCustom>
                </RadioGroup>
              </Row>
              {form?.useCarbonCredit && (
                <>
                  {/* Input useCarbonCredit */}
                  <Row>
                    <label>Valor da crédito de carbono</label>
                  </Row>
                  <Row>
                    <NumberFormat
                      customInput={Input}
                      style={{ width: 300 }}
                      value={form !== null ? form.carbonCredit : null}
                      placeholder={translation.defaultPlaceholder}
                      name="carbonCredit"
                      addonAfter={<span>CO&sup2; kg</span>}
                      addonBefore="R$"
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      onValueChange={({ floatValue }) => {
                        setForm({
                          ...form,
                          carbonCredit:
                            floatValue >= 0
                              ? floatValue > 999.99
                                ? 999.99
                                : floatValue
                              : null,
                        });
                      }}
                    />
                  </Row>
                </>
              )}
              {/* Input capacitySupportRestriction (Strong = No | Weak = Yes) */}
              <Row>
                <label>
                  Você deseja arrendar mais terra caso falte capacidade de
                  suporte? *
                </label>
              </Row>
              <Row>
                <Select
                  name="capacitySupportRestriction"
                  style={{ width: "100%" }}
                  value={
                    form != null ? form.capacitySupportRestriction : undefined
                  }
                  placeholder={translation.defaultSelectPlaceholder}
                  onChange={(value) => {
                    setForm({
                      ...form,
                      capacitySupportRestriction: value,
                    });
                    setErrors(
                      errors.filter((e) => e !== "capacitySupportRestriction")
                    );
                  }}
                >
                  <Select.Option key="Weak" value="Weak">
                    {translation.buttons.yes}
                  </Select.Option>
                  <Select.Option key="Strong" value="Strong">
                    {translation.buttons.no}
                  </Select.Option>
                </Select>
              </Row>
            </div>
          </Col>
        </Row>
      </Spin>
      {/* Footer */}
      <Footer>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="buttonsDiv">
          <ButtonStandard
            type="button"
            buttonType="type7"
            onClick={(e) => closeDrawer()}
          >
            {translation.buttons.cancel}
          </ButtonStandard>

          <ButtonStandard
            type="button"
            buttonType="type6"
            onClick={(e) => handleSubmitForm()}
          >
            {translation.buttons.save}
          </ButtonStandard>
        </Col>
      </Footer>
      {/* Modal */}
      <ModalCommitment />
    </Container>
  );
};

export default DrawerEditSaleScenarioParameters;
