export const Types = {
  SHOW_DRAWER_INTEGRATION: "integration/SHOW_DRAWER_INTEGRATION",
  HIDE_DRAWER_INTEGRATION: "integration/HIDE_DRAWER_INTEGRATION",
  // SAVE_INTEGRATION: "integration/SAVE_CLIENT",
  // SAVE_CLIENT_SUCCESS: "integration/SAVE_CLIENT_SUCCESS",
  // SAVE_CLIENT_ERROR: "integration/SAVE_CLIENT_ERROR",
};

export const Creators = {
  showDrawerIntegrationVisible: (type, id) => ({
    type: Types.SHOW_DRAWER_INTEGRATION,
    payload: { type, id },
  }),
  hideDrawerIntegrationVisible: () => ({
    type: Types.HIDE_DRAWER_INTEGRATION,
    payload: {},
  }),
};

const INITIAL_STATE = {
  id: null,
  drawerIntegrationPartnerVisible: false,
};

export default function integrationPartner(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SHOW_DRAWER_INTEGRATION:
      if (action.payload.type === "new") {
        return {
          ...state,
          drawerIntegrationPartnerVisible: true,
          id: null,
        };
      } else if (action.payload.type === "edit") {
        return {
          ...state,
          drawerIntegrationPartnerVisible: true,
          id: action.payload.id,
        };
      } else if (action.payload.type === "details") {
        return {
          ...state,
          drawerIntegrationPartnerVisible: true,
          id: action.payload.id,
        };
      } else {
        return {
          ...state,
        };
      }
    case Types.HIDE_DRAWER_INTEGRATION:
      return {
        ...state,
        drawerIntegrationPartnerVisible: false,
        id: null,
      };
    default:
      return state;
  }
}
