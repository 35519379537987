import React from "react";
import { useConfinementMapContext } from "../../../hooks/useConfinementMapContext";

import { Col, Modal, Row, Table, Typography } from "antd";

import { numberMask } from "../../../services/helpersMethodsService";

// import { Container } from './styles';

const ConfinementMapSuppliersModal = () => {
  const { isModalSuppliersVisible, showOrHideModalSuppliers, suppliersData } =
    useConfinementMapContext();
  return (
    <Modal
      visible={isModalSuppliersVisible}
      width={700}
      centered
      closable
      onCancel={() => showOrHideModalSuppliers()}
      footer={null}
    >
      <Row type="flex">
        <Typography.Title level={4}>Fornecedores</Typography.Title>
      </Row>
      <Row type="flex" justify="center" style={{ width: "100%" }}>
        <Col span={24}>
          <Table
            dataSource={suppliersData || []}
            pagination={false}
            size="default"
            rowKey="supplierId"
          >
            <Table.Column dataIndex="supplierName" title="Fornecedor" />
            <Table.Column
              dataIndex="animalsCount"
              title="Qtde Animais"
              render={(text) => <span>{`${text} cab`}</span>}
            />
            <Table.Column
              dataIndex="averageProgressiveWeight"
              title="Peso Médio Projetado"
              render={(text) => (
                <span>{`${numberMask(text || 0, false)} kg`}</span>
              )}
            />
            <Table.Column
              dataIndex="averageStayDays"
              title="Dias de Cocho"
              render={(text) => <span>{`${text} dias`}</span>}
            />
          </Table>
        </Col>
      </Row>
    </Modal>
  );
};

export default ConfinementMapSuppliersModal;
