import apiFarms from "../config/api_farms";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiFarms = axios.create({
//   baseURL: "http://localhost:8082",
// });

export function getUnknownBullInfo(payload) {
  const { groupId, farmId, id } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/unknownBulls/${id}`,
    getHeaders(false, null)
  );
}
