import { call, put } from "redux-saga/effects";
import { notification } from "antd";
import { Creators as LotActions } from "../ducks/lot";
/** Services */
import {
  getLotIndex,
  saveNewLotProduction,
  addAnimalsToProductionLot,
  getLotShow,
  dismember,
  destroyLotProduction,
  reactivateLotProduction,
  inactivateLotProduction,
} from "../../services/lotService";
import { saveNewLotReceipt } from "../../services/lotReceiptService";

export function* indexLot(action) {
  try {
    const {
      data: { results: allLots },
    } = yield call(getLotIndex, action.payload);

    yield put(LotActions.indexLotsProductionSuccess(allLots));
  } catch (error) {
    yield put(LotActions.indexLotsProductionError(error));
  }
}

export function* getLot(action) {
  try {
    let {
      data: { results: lot },
    } = yield call(getLotShow, action.payload);
    // Transform lot
    lot = {
      ...lot,
      dietStrategyBaseLine:
        lot.dietStrategies.length > 0
          ? lot.dietStrategies.find((ds) => ds.baseline === true)
          : null,
      dietStrategies: lot.dietStrategies.filter((ds) => ds.baseline === false),
    };
    yield put(LotActions.showLotSuccess(lot));
  } catch (error) {
    yield put(LotActions.showLotError(error));
  }
}

export function* storeLotReceipt(action) {
  try {
    const {
      data: { results: newLot },
    } = yield call(saveNewLotReceipt, action.payload);
    notification.success({
      message: "Cadastro realizado com sucesso",
      description: "Lote de recebimento criado com sucesso",
    });
    yield put(
      LotActions.storeNewLotReceiptSuccess(newLot, action.payload.saveOption)
    );
  } catch (error) {
    notification.error({
      message: "Cadastro não realizado",
      description: "Lote de recebimento não foi criado",
    });
    yield put(LotActions.storeNewLotReceiptError(error));
  }
}

export function* storeLotProduction(action) {
  try {
    const {
      data: { results: lot },
    } = yield call(saveNewLotProduction, action.payload);
    if (action.payload.id != null) {
      notification.success({
        message: "Lote atualizado",
        description: "Lote de produção atualizado com sucesso",
      });
    } else {
      notification.success({
        message: "Cadastro realizado com sucesso",
        description: "Lote de produção criado com sucesso",
      });
    }
    if (action.payload.saveAndContinue) {
      yield put(LotActions.saveLotProductionAndContinueSuccess(lot));
    } else yield put(LotActions.saveLotProductionSuccess(lot));
  } catch (error) {
    if (Object.keys(error).includes("response")) {
      const { response } = error;
      if (Object.keys(response).includes("data")) {
        const {
          data: { code: errorCode },
        } = response;
        if (errorCode === 5034) {
          notification.error({
            message: "Cadastro não realizado",
            description:
              "Já existe um lote de produção ativo com esta identificação.",
          });
        } else {
          notification.error({
            message: "Cadastro não realizado",
            description: "Lote de produção não foi criado",
          });
        }
      }
    } else {
      notification.error({
        message: "Cadastro não realizado",
        description: "Lote de produção não foi criado",
      });
    }
    yield put(LotActions.saveLotProductionError(error));
  }
}

export function* addAnimalsToLotProduction(action) {
  try {
    const {
      data: { results },
    } = yield call(addAnimalsToProductionLot, action.payload);

    notification.success({
      message: "Operação realizada com sucesso",
      description: "Animais adicionados com sucesso no lote de produção.",
    });

    yield put(LotActions.addAnimalsToLotProductionSuccess(results));
    action.payload.history.push("/admin/lots");
  } catch (error) {
    notification.error({
      message: "Cadastro não realizado",
      description:
        "Animais não foram adicionados com sucesso ao lote de produção.",
    });
    yield put(LotActions.addAnimalsToLotProductionError(error));
  }
}

export function* dismemberLot(action) {
  try {
    const {
      data: { results },
    } = yield call(dismember, action.payload);

    notification.success({
      message: "Operação realizada com sucesso",
      description: "Lote desmembrado com sucesso",
    });

    yield put(LotActions.dismemberLotSuccess(results));
  } catch (error) {
    if (Object.keys(error).includes("response")) {
      const { response } = error;
      if (Object.keys(response).includes("data")) {
        const {
          data: { code: errorCode },
        } = response;
        if (errorCode === 5034) {
          notification.error({
            message: "Desmembramento não realizado",
            description:
              "Já existe um lote de produção ativo com esta identificação.",
          });
        } else {
          notification.error({
            message: "Desmembramento não realizado",
            description: "O Lote não foi desmembrado",
          });
        }
      }
    } else {
      notification.error({
        message: "Desmembramento não realizado",
        description: "O Lote não foi desmembrado",
      });
    }
    yield put(LotActions.dismemberLotError(error));
  }
}

export function* deleteLotProduction(action) {
  try {
    yield call(destroyLotProduction, action.payload);
    notification.success({
      message: "Lote deletado/inativado",
      description: "Lote de produção deletado/inativado com sucesso",
    });
    yield put(LotActions.deleteLotProductionSuccess());
  } catch (error) {
    notification.error({
      message: "Deleção não realizada",
      description: "Lote de produção não foi deletado",
    });
    yield put(LotActions.deleteLotProductionError(error.data));
  }
}

export function* activateLotProduction(action) {
  try {
    yield call(reactivateLotProduction, action.payload);
    notification.success({
      message: "Lote reativado",
      description: "Lote de produção foi reativado com sucesso",
    });
    yield put(LotActions.activateLotProductionSuccess());
  } catch (error) {
    notification.error({
      message: "Reativação não realizada",
      description: "Lote de produção não foi reativado. Contate o suporte",
    });
    yield put(LotActions.activateLotProductionError(error.data));
  }
}

export function* inactivateLotProductionSaga(action) {
  const { lot } = action.payload;
  try {
    yield call(inactivateLotProduction, action.payload);
    notification.success({
      message: "Lote inativado",
      description: `Lote de produção (${lot.name}) foi inativado com sucesso`,
    });
    yield put(LotActions.inactivateLotProductionSuccess());
  } catch (error) {
    notification.error({
      message: "Inativação não realizada",
      description: `Lote de produção (${lot.name}) não foi inativado. Contate o suporte.`,
    });
    yield put(LotActions.inactivateLotProductionError(error.data));
  }
}
