import styled from "styled-components";

export const Container = styled.div`
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 3px 6px #dcdcdc;
  margin-top: 10px;
  margin-left: 2px;
  padding-left: 22px;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  .activeAnimals {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 21px 10px 0px;
    border-right: 1px solid #f5f5f5;
    #bovexoInfoIcon {
      position: absolute;
      top: 20%;
      right: 25%;
    }
    span.title {
      font-family: Asap;
      font-weight: normal;
      font-size: 12px;
      color: #4b4b4b;
      text-transform: uppercase;
    }
    span.numberOfAnimals {
      color: #684e94;
      font-size: 25px;
      font-weight: bold;
    }
  }
  .unit {
    flex: 1;
    padding: 0px 0px 15px 21px;
    display: flex;
    flex-direction: column;
    div.header {
      padding: 10px 10px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      span {
        font-family: Asap, sans-serif;
        font-weight: bold;
        font-style: italic;
        font-size: 11px;
        text-align: left;
        color: #a5a5a5;
      }
    }
    div.data {
      display: flex;
      div.col {
        display: flex;
        flex-direction: column;
        height: 100%;
        &:not(:first-of-type) {
          margin-left: 25px;
        }
        div.cell {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 25px;
          svg {
            width: 20px;
            margin-right: 5px;
          }
          strong.genderCount {
            font-size: 16px;
          }
          label.lblGenderValues {
            font-family: Asap;
            font-weight: bold;
            font-size: 11px;
            text-align: left;
            color: #a5a5a5;
          }
          span.genderValues {
            font-family: Asap;
            font-size: 14px;
            text-align: left;
            color: #4b4b4b;
          }
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
    flex-direction: column;

    .activeAnimals {
      border-right: 0;
      border-bottom: 1px solid #f5f5f5;
    }
  }
`;
