import { all, takeEvery, takeLatest } from "redux-saga/effects";
import { Types as AppTypes } from "../ducks/app";
import { Types as UserTypes } from "../ducks/user";
import { Types as AnimalTypes } from "../ducks/animal";
import { Types as LotTypes } from "../ducks/lot";
import { Types as PicketTypes } from "../ducks/picket";
import { Types as SupplierTypes } from "../ducks/supplier";
import { Types as ProfitCenterTypes } from "../ducks/profitCenter";
import { Types as TypologyItemTypes } from "../ducks/typologyItem";
import { Types as BreedTypes } from "../ducks/breed";
import { Types as PastureTypes } from "../ducks/pasture";
import { Types as HandlingOperationTypes } from "../ducks/handlingOperation";
import { Types as DietTypes } from "../ducks/diet";
import { Types as DietStrategyTypes } from "../ducks/dietStrategy";
import { Types as SupplementTypes } from "../ducks/supplement";
import { Types as MarketAnalysisTypes } from "../ducks/marketAnalysis";
import { Types as BenchmarkTypes } from "../ducks/benchmark";
import { Types as ImportTypes } from "../ducks/import";
import { Types as FarmTypes } from "../ducks/farm";
import { Types as SaleScenarioTypes } from "../ducks/saleScenario";
import { Types as GeneralParametersTypes } from "../ducks/generalParameters";
import { Types as FinancialTypes } from "../ducks/financial";
import { Types as ClientTypes } from "../ducks/client";
import { Types as LotSupplementConsumptionTypes } from "../ducks/lotSupplementConsumption";
import { Types as AccountFarmTypes } from "../ducks/accountFarm";
import { Types as BoitelScenarioTypes } from "../ducks/boitelScenario";
import { Types as BoitelSaleScenarioTypes } from "../ducks/boitelSaleScenario";
import { Types as BreedReproductionScoreTypes } from "../ducks/breedReproductionScore";
import { Types as ReproductionPeriodTypes } from "../ducks/reproductionPeriod";
import { Types as FarmDeathReasonTypes } from "../ducks/farmDeathReason";
import { Types as UserTriggerTypes } from "../ducks/userTrigger";
import { Types as VacinationsTypes } from "../ducks/vacinations";
import { Types as QuotaTypes } from "../ducks/quota";
import { Types as ResultCenterTypes } from "../ducks/resultCenter";
import { Types as RetreatTypes } from "../ducks/retreat";
import { Types as CostCenterTypes } from "../ducks/costCenter";
import { Types as LotReceiptTypes } from "../ducks/lotReceipt";

/* Sagas Methods */
import { authenticate } from "./app";
import {
  storeUser,
  getAllRoles,
  indexUser,
  indexUserGroupsAndFarmsDescriptions,
  getUserHomeConfig,
  saveUserHomeConfig,
} from "./user";
import {
  indexAnimal,
  indexAnimalFemaleTable,
  indexAnimalMaleTable,
  indexAnimalDetails,
  indexAnimalWeightHistory,
  indexAnimalDailyWeight,
  indexAnimalDailyWeightGain,
  storeOrUpdateAnimal,
  indexAnimalTotalDailyWeightDashboard,
  indexAnimalTotalGMDDashboard,
  indexAnimalDailyWeightDashboard,
  indexAnimalDailyWeightGainDashboard,
  animalActivateOrInactivate,
  deleteAnimal,
  indexAnimalDailyCost,
  indexAnimalWeaningWeightDashboard,
  indexAnimalMotherDropdown,
  indexAnimalFatherDropdown,
  indexAnimalLotDailyWeightDashboard,
  indexAnimalComments,
  indexAnimalFemaleReproductionEventTable,
  getNumberOfAnimalsInFattenAndReproduction,
  indexAnimalPicketSupplmentSupplies,
} from "./animal";
import {
  indexLot,
  storeLotProduction,
  addAnimalsToLotProduction,
  getLot,
  dismemberLot,
  deleteLotProduction,
  activateLotProduction,
  inactivateLotProductionSaga,
} from "./lot";
import {
  indexPicket,
  storePicket,
  getPicket,
  allocateLotToPicket,
} from "./picket";
import {
  indexSupplier,
  getSupplier,
  storeOrUpdateSupplier,
  deleteSupplier,
  reactivateSupplier,
  listDropDownSupplier,
} from "./supplier";
import {
  indexProfitCenter,
  storeOrUpdateProfitCenter,
  getProfitCenter,
} from "./profitCenter";
import { indexTypologyItem } from "./typologyItem";
import {
  indexBreed,
  indexBreedActive,
  getBreed,
  getBreedParameters,
  storeOrUpdateBreed,
  storeOrUpdateBreedParameters,
  inactivateOrActivateBreedParameter,
} from "./breed";
import {
  indexPasture,
  getPasture,
  storeOrUpdatePasture,
  deletePasture,
} from "./pasture";
import { indexDiet, getDiet, storeOrUpdateDiet, deleteDiet } from "./diet";
import {
  indexDietStrategy,
  getDietStrategy,
  storeOrUpdateDietStrategy,
  deleteOrActivateDietStrategy,
} from "./dietStrategy";
import {
  indexSupplement,
  getSupplement,
  storeOrUpdateSupplement,
  deleteSupplement,
} from "./supplement";
import {
  indexMarketAnalysis,
  getMarketAnalysis,
  storeOrUpdateMarketAnalysis,
  deleteMarketAnalysis,
  activateMarketAnalysis,
} from "./marketAnalysis";
import {
  indexBenchmark,
  getBenchmark,
  storeOrUpdateBenchmark,
  deleteBenchmark,
} from "./benchmark";
import { indexAnimalImportLog, indexAnimalWeightImportLog } from "./import";
import {
  indexFarm,
  getFarm,
  storeOrUpdateFarm,
  deleteFarm,
  activateFarm,
} from "./farm";
import {
  indexHandlingOperation,
  getHandlingOperation,
  storeOrUpdateHandlingOperation,
  deleteHandlingOperation,
} from "./handlingOperation";

import { editSaleScenarioParam } from "./saleScenario";
import { indexWeatherSeasons } from "./generalParameters";
import {
  createOrUpdateFarmCosts,
  createOrUpdateSummarizeFarmCosts,
  indexFarmCosts,
  indexSummaryFarmCosts,
} from "./financial";
import { storeOrUpdateClient, listDropDownClient } from "./client";
import {
  createOrUpdateLotSupplementConsumption,
  deleteLotSupplementConsumption,
  fetchListOfSupplementConsumpiton,
  getLotInfoAndLotSupplementConsumption,
} from "./lotSupplementConsumption";
import {
  checkAccountFarmServiceSaga,
  renewExpirationAccountFarmServiceSaga,
} from "./accountFarm";

import {
  constractBoitelScenarioSaga,
  getBoitelScenarioDataSaga,
  storeUpdateBoitelScenarioSaga,
} from "./boitelScenario";
import {
  updateBoitelScenarioSimulationSaga,
  deleteBoitelScenarioSimulationSaga,
} from "./boitelScenarioSimulation";
import {
  storeUpdateBoitelSaleScenarioSaga,
  getBoitelSaleScenarioListDataSaga,
} from "./boitelSaleScenario";
import {
  indexBreedReproductionScore,
  storeOrBreedReproductionScore,
} from "./breedReproductionScore";
import { indexReproductionPeriods } from "./reproductionPeriod";
import {
  activateFarmDeathReason,
  inactivateFarmDeathReason,
  indexFarmDeathReasons,
  getDropdownFarmDeathReasons,
} from "./farmDeathReason";
import { getLastUserTrigger, saveUserTrigger } from "./userTrigger";
import { indexVacinations } from "./vacinations";
import {
  activateFarmQuota,
  inactivateFarmQuota,
  indexFarmQuotas,
  getDropdownFarmQuotas,
} from "./quota";
import {
  indexAllResultCenters,
  inactivateResultCenter,
  activateResultCenter,
} from "./resultCenter";
import {
  activateFarmRetreat,
  inactivateFarmRetreat,
  indexFarmRetreats,
} from "./retreat";
import {
  indexAllCostCenters,
  inactivateCostCenter,
  activateCostCenter,
} from "./costCenter";
import {
  getLotReceiptItem,
  saveOrUpdateLotItemEntry,
  storeLotReceipt,
} from "./lotReceipt";

/** Map Ducks Types and Sagas Methods */

const accountFarmSagas = [
  takeLatest(AccountFarmTypes.CHECK_ACCOUNT_FARM, checkAccountFarmServiceSaga),
  takeLatest(
    AccountFarmTypes.RENEW_EXPIRATION_ACCOUNT_FARM,
    renewExpirationAccountFarmServiceSaga
  ),
];

const lotSagas = [
  takeLatest(LotTypes.GET_ALL_LOTS_PRODUCTION, indexLot),
  takeLatest(LotTypes.GET_LOT, getLot),
  takeLatest(LotTypes.SAVE_LOT_PRODUCTION, storeLotProduction),
  takeLatest(LotTypes.ADD_ANIMALS_TO_LOT_PRODUCTION, addAnimalsToLotProduction),
  takeLatest(LotTypes.DISMEMBER_LOT, dismemberLot),
  takeLatest(LotTypes.DELETE_LOT_PRODUCTION, deleteLotProduction),
  takeLatest(LotTypes.ACTIVATE_LOT_PRODUCTION, activateLotProduction),
  takeLatest(LotTypes.INACTIVATE_LOT_PRODUCTION, inactivateLotProductionSaga),
];

const lotReceiptSagas = [
  takeLatest(LotTypes.STORE_NEW_LOT_RECEIPT, storeLotReceipt),
  takeLatest(LotReceiptTypes.GET_DETAILS_LOT_RECEIPT_ITEM, getLotReceiptItem),
  takeLatest(LotReceiptTypes.SAVE_LOT_ITEM_ENTRY, saveOrUpdateLotItemEntry),
];

const animalSagas = [
  takeLatest(AnimalTypes.GET_ANIMAL, indexAnimal),
  takeLatest(
    AnimalTypes.GET_ANIMAL_FEMALE_REPRODUCTION_EVENTS_TABLE,
    indexAnimalFemaleReproductionEventTable
  ),
  takeLatest(
    AnimalTypes.GET_NUMBER_ANIMALS,
    getNumberOfAnimalsInFattenAndReproduction
  ),
  takeLatest(AnimalTypes.GET_ANIMAL_FEMALE_TABLE, indexAnimalFemaleTable),
  takeLatest(AnimalTypes.GET_ANIMAL_MALE_TABLE, indexAnimalMaleTable),
  takeLatest(AnimalTypes.GET_ANIMAL_DETAILS, indexAnimalDetails),
  takeLatest(AnimalTypes.GET_ANIMAL_WEIGHT_HISTORY, indexAnimalWeightHistory),
  takeLatest(AnimalTypes.GET_ANIMAL_DAILY_WEIGHT, indexAnimalDailyWeight),
  takeLatest(
    AnimalTypes.GET_ANIMAL_DAILY_WEIGHT_PAGEABLE,
    indexAnimalDailyWeight
  ),
  takeLatest(
    AnimalTypes.GET_ANIMAL_DAILY_WEIGHT_GAIN,
    indexAnimalDailyWeightGain
  ),
  takeLatest(
    AnimalTypes.GET_ANIMAL_DAILY_WEIGHT_GAIN_PAGEABLE,
    indexAnimalDailyWeightGain
  ),
  takeLatest(
    AnimalTypes.GET_ANIMAL_TOTAL_DAILY_WEIGHT_DASHBOARD,
    indexAnimalTotalDailyWeightDashboard
  ),
  takeLatest(
    AnimalTypes.GET_ANIMAL_GMD_DASHBOARD,
    indexAnimalTotalGMDDashboard
  ),
  takeLatest(AnimalTypes.SAVE_ANIMAL, storeOrUpdateAnimal),
  takeLatest(ImportTypes.GET_ALL_ANIMAL_IMPORT, indexAnimalImportLog),
  takeLatest(
    ImportTypes.GET_ALL_ANIMAL_WEIGHT_IMPORT,
    indexAnimalWeightImportLog
  ),
  takeLatest(
    AnimalTypes.GET_ANIMAL_DAILY_WEIGHT_DASHBOARD,
    indexAnimalDailyWeightDashboard
  ),
  takeLatest(
    AnimalTypes.GET_ANIMAL_DAILY_WEIGHT_GAIN_DASHBOARD,
    indexAnimalDailyWeightGainDashboard
  ),
  takeLatest(
    AnimalTypes.ACTIVATE_INACTIVATE_ANIMAL,
    animalActivateOrInactivate
  ),
  takeLatest(AnimalTypes.DELETE_ANIMAL, deleteAnimal),
  takeLatest(AnimalTypes.GET_ANIMAL_DAILY_COST, indexAnimalDailyCost),
  takeLatest(
    AnimalTypes.GET_ANIMAL_WEANING_WEIGHT_DASHBOARD,
    indexAnimalWeaningWeightDashboard
  ),
  takeLatest(AnimalTypes.GET_ANIMAL_MOTHER_DROPDOWN, indexAnimalMotherDropdown),
  takeLatest(AnimalTypes.GET_ANIMAL_FATHER_DROPDOWN, indexAnimalFatherDropdown),
  takeLatest(
    AnimalTypes.GET_ANIMAL_LOT_WEIGHT_DASHBOARD,
    indexAnimalLotDailyWeightDashboard
  ),
  takeLatest(AnimalTypes.GET_ANIMAL_HANDLING_COMMENTS, indexAnimalComments),
  takeLatest(
    AnimalTypes.GET_ANIMAL_PICKET_SUPPLEMENT_SUPPLIES,
    indexAnimalPicketSupplmentSupplies
  ),
];

const picketSagas = [
  takeLatest(PicketTypes.GET_ALL_PICKETS, indexPicket),
  takeLatest(PicketTypes.GET_PICKET, getPicket),
  takeLatest(PicketTypes.SAVE_PICKET, storePicket),
  takeLatest(PicketTypes.ADD_LOT_TO_PICKET, allocateLotToPicket),
];

const supplierSagas = [
  takeLatest(SupplierTypes.GET_ALL_SUPPLIERS, indexSupplier),
  takeLatest(SupplierTypes.GET_DROPDOWN_SUPPLIERS, listDropDownSupplier),
  takeLatest(SupplierTypes.GET_ALL_SUPPLIERS_PAGEABLE, indexSupplier),
  takeLatest(SupplierTypes.GET_SUPPLIER, getSupplier),
  takeLatest(SupplierTypes.SAVE_SUPPLIER, storeOrUpdateSupplier),
  takeLatest(SupplierTypes.DELETE_SUPPLIER, deleteSupplier),
  takeLatest(SupplierTypes.REACTIVATE_SUPPLIER, reactivateSupplier),
];

const profitCenterSagas = [
  takeEvery(ProfitCenterTypes.GET_ALL_PROFITCENTER, indexProfitCenter),
  takeLatest(ProfitCenterTypes.GET_PROFITCENTER, getProfitCenter),
  takeLatest(ProfitCenterTypes.SAVE_PROFIT_CENTER, storeOrUpdateProfitCenter),
];

const breedSagas = [
  takeLatest(BreedTypes.GET_ALL_BREED, indexBreed),
  takeLatest(BreedTypes.GET_ALL_BREED_ACTIVE, indexBreedActive),
  takeLatest(BreedTypes.GET_BREED, getBreed),
  takeLatest(BreedTypes.GET_BREEDPARAMETERS, getBreedParameters),
  takeLatest(BreedTypes.SAVE_BREED, storeOrUpdateBreed),
  takeLatest(BreedTypes.SAVE_BREEDPARAMETERS, storeOrUpdateBreedParameters),
  takeLatest(
    BreedTypes.INACTIVATE_ACTIVATE_BREEDPARAMETERS,
    inactivateOrActivateBreedParameter
  ),
];

const dietStrategySagas = [
  takeLatest(DietTypes.GET_ALL_DIET, indexDiet),
  takeLatest(DietTypes.GET_DIET, getDiet),
  takeLatest(DietTypes.SAVE_DIET, storeOrUpdateDiet),
  takeLatest(DietTypes.DELETE_DIET, deleteDiet),

  takeLatest(DietStrategyTypes.GET_ALL_DIETSTRATEGY, indexDietStrategy),
  takeLatest(DietStrategyTypes.GET_DIETSTRATEGY, getDietStrategy),
  takeLatest(DietStrategyTypes.SAVE_DIETSTRATEGY, storeOrUpdateDietStrategy),
  takeLatest(
    DietStrategyTypes.INACTIVATE_ACTIVATE_DIETSTRATEGY,
    deleteOrActivateDietStrategy
  ),
];

const marketAnalyseSagas = [
  takeLatest(MarketAnalysisTypes.GET_ALL_MARKET_ANALYSIS, indexMarketAnalysis),
  takeLatest(MarketAnalysisTypes.GET_MARKET_ANALYSIS, getMarketAnalysis),
  takeLatest(
    MarketAnalysisTypes.SAVE_MARKET_ANALYSIS,
    storeOrUpdateMarketAnalysis
  ),
  takeLatest(MarketAnalysisTypes.DELETE_MARKET_ANALYSIS, deleteMarketAnalysis),
  takeLatest(
    MarketAnalysisTypes.ACTIVATE_MARKET_ANALYSIS,
    activateMarketAnalysis
  ),
];

const supplementSagas = [
  takeLatest(SupplementTypes.GET_ALL_SUPPLEMENT, indexSupplement),
  takeLatest(SupplementTypes.GET_SUPPLEMENT, getSupplement),
  takeLatest(SupplementTypes.SAVE_SUPPLEMENT, storeOrUpdateSupplement),
  takeLatest(SupplementTypes.DELETE_SUPPLEMENT, deleteSupplement),
];

const farmSagas = [
  takeLatest(FarmTypes.GET_ALL_FARM, indexFarm),
  takeLatest(FarmTypes.GET_FARM, getFarm),
  takeLatest(FarmTypes.SAVE_FARM, storeOrUpdateFarm),
  takeLatest(FarmTypes.DELETE_FARM, deleteFarm),
  takeLatest(FarmTypes.ACTIVATE_FARM, activateFarm),
];

const benchmarkSagas = [
  takeLatest(BenchmarkTypes.GET_ALL_BENCHMARK, indexBenchmark),
  takeLatest(BenchmarkTypes.GET_BENCHMARK, getBenchmark),
  takeLatest(BenchmarkTypes.SAVE_BENCHMARK, storeOrUpdateBenchmark),
  takeLatest(BenchmarkTypes.DELETE_BENCHMARK, deleteBenchmark),
];

const pastureSagas = [
  takeLatest(PastureTypes.GET_ALL_PASTURE, indexPasture),
  takeLatest(PastureTypes.GET_PASTURE, getPasture),
  takeLatest(PastureTypes.SAVE_PASTURE, storeOrUpdatePasture),
  takeLatest(PastureTypes.DELETE_PASTURE, deletePasture),
];

const handlingOperationSagas = [
  takeLatest(
    HandlingOperationTypes.GET_ALL_HANDLING_OPERATION,
    indexHandlingOperation
  ),
  takeLatest(
    HandlingOperationTypes.GET_HANDLING_OPERATION,
    getHandlingOperation
  ),
  takeLatest(
    HandlingOperationTypes.SAVE_HANDLING_OPERATION,
    storeOrUpdateHandlingOperation
  ),
  takeLatest(
    HandlingOperationTypes.DELETE_HANDLING_OPERATION,
    deleteHandlingOperation
  ),
];

const saleScenarioSagas = [
  takeLatest(SaleScenarioTypes.SHOW_EDIT_SALE_SCENARIO, editSaleScenarioParam),
];

const financialSagas = [
  takeLatest(
    FinancialTypes.SAVE_FARM_COST_SUMMARIZE,
    createOrUpdateSummarizeFarmCosts
  ),
  takeLatest(FinancialTypes.SAVE_FARM_COST, createOrUpdateFarmCosts),
  takeLatest(FinancialTypes.GET_FARM_COST, indexFarmCosts),
  takeLatest(FinancialTypes.GET_SUMMARY_FARM_COST, indexSummaryFarmCosts),
];

const userSagas = [
  takeLatest(UserTypes.STORE_USER, storeUser),
  takeLatest(UserTypes.GET_USER, indexUser),
  takeLatest(
    UserTypes.GET_USER_GROUPS_FARMS_DESCRIPTIONS,
    indexUserGroupsAndFarmsDescriptions
  ),

  takeLatest(UserTypes.GET_HOME_CONFIG, getUserHomeConfig),
  takeLatest(UserTypes.SAVE_HOME_CONFIG, saveUserHomeConfig),
];

const clientSagas = [
  takeLatest(ClientTypes.SAVE_CLIENT, storeOrUpdateClient),
  takeLatest(ClientTypes.GET_DROPDOWN_CLIENTS, listDropDownClient),
];

const lotSupplementConsumptionSagas = [
  takeLatest(
    LotSupplementConsumptionTypes.SHOW_HIDE_DRAWER_AND_FETCH_DATA,
    getLotInfoAndLotSupplementConsumption
  ),
  takeLatest(
    LotSupplementConsumptionTypes.FETCH_LIST_OF_SUPPLEMENT_CONSUMPTION_DATA,
    fetchListOfSupplementConsumpiton
  ),
  takeLatest(
    LotSupplementConsumptionTypes.CREATE_OR_UPDATE_DATA,
    createOrUpdateLotSupplementConsumption
  ),
  takeLatest(
    LotSupplementConsumptionTypes.DELETE_LOT_SUPPLEMENT_CONSUMPTION,
    deleteLotSupplementConsumption
  ),
];

const boitelScenarioSagas = [
  takeLatest(
    BoitelScenarioTypes.GET_BOITEL_SCENARIO_DATA,
    getBoitelScenarioDataSaga
  ),
  takeLatest(
    BoitelScenarioTypes.STORE_UPDATE_BOITEL_SCENARIO,
    storeUpdateBoitelScenarioSaga
  ),
  takeLatest(
    BoitelScenarioTypes.UPDATE_BOITEL_SCENARIO_SIMULATION,
    updateBoitelScenarioSimulationSaga
  ),
  takeLatest(
    BoitelScenarioTypes.DELETE_BOITEL_SCENARIO_SIMULATION,
    deleteBoitelScenarioSimulationSaga
  ),
  takeLatest(
    BoitelScenarioTypes.CONTRACT_BOITEL_SCENARIO,
    constractBoitelScenarioSaga
  ),
];

const boitelSaleScenarioSagas = [
  takeLatest(
    BoitelSaleScenarioTypes.STORE_UPDATE_BOITEL_SALE_SCENARIO,
    storeUpdateBoitelSaleScenarioSaga
  ),
  takeLatest(
    BoitelSaleScenarioTypes.GET_BOITEL_SALE_SCENARIO_LIST_DATA,
    getBoitelSaleScenarioListDataSaga
  ),
];

const breedReproductionScoreSagas = [
  takeLatest(
    BreedReproductionScoreTypes.GET_ALL_DATA,
    indexBreedReproductionScore
  ),
  takeLatest(BreedReproductionScoreTypes.SAVE, storeOrBreedReproductionScore),
];

const reproductionPeriodsSagas = [
  takeLatest(ReproductionPeriodTypes.GET_ALL_DATA, indexReproductionPeriods),
];

const farmDeathReasonSagas = [
  takeLatest(
    FarmDeathReasonTypes.GET_ALL_FARM_DEATH_REASON,
    indexFarmDeathReasons
  ),
  takeLatest(
    FarmDeathReasonTypes.INACTIVATE_FARM_DEATH_REASON,
    inactivateFarmDeathReason
  ),
  takeLatest(
    FarmDeathReasonTypes.ACTIVATE_FARM_DEATH_REASON,
    activateFarmDeathReason
  ),
  takeLatest(
    FarmDeathReasonTypes.GET_ALL_DROPDOWN,
    getDropdownFarmDeathReasons
  ),
];

const userTriggerSagas = [
  takeLatest(UserTriggerTypes.GET_LAST_STATUS_USER_TRIGGER, getLastUserTrigger),
  takeLatest(UserTriggerTypes.SAVE_USER_TRIGGER, saveUserTrigger),
];

const vacinationsSagas = [
  takeLatest(VacinationsTypes.GET_ALL_FARM_VACINATIONS, indexVacinations),
];

const quotaSagas = [
  takeLatest(QuotaTypes.GET_ALL_FARM_QUOTA, indexFarmQuotas),
  takeLatest(QuotaTypes.INACTIVATE_FARM_QUOTA, inactivateFarmQuota),
  takeLatest(QuotaTypes.ACTIVATE_FARM_QUOTA, activateFarmQuota),
  takeLatest(QuotaTypes.GET_ALL_DROPDOWN, getDropdownFarmQuotas),
];

const resultCentersSagas = [
  takeLatest(ResultCenterTypes.GET_ALL_RESULT_CENTER, indexAllResultCenters),
  takeLatest(
    ResultCenterTypes.INACTIVATE_RESULT_CENTER,
    inactivateResultCenter
  ),
  takeLatest(ResultCenterTypes.ACTIVATE_RESULT_CENTER, activateResultCenter),
];

const retreatSagas = [
  takeLatest(RetreatTypes.GET_ALL_FARM_RETREAT, indexFarmRetreats),
  takeLatest(RetreatTypes.INACTIVATE_FARM_RETREAT, inactivateFarmRetreat),
  takeLatest(RetreatTypes.ACTIVATE_FARM_RETREAT, activateFarmRetreat),
];

const costCentersSagas = [
  takeLatest(CostCenterTypes.GET_ALL_COST_CENTER, indexAllCostCenters),
  takeLatest(CostCenterTypes.INACTIVATE_COST_CENTER, inactivateCostCenter),
  takeLatest(CostCenterTypes.ACTIVATE_COST_CENTER, activateCostCenter),
];

export default function* rootSaga() {
  yield all([
    ...lotSagas,
    ...lotReceiptSagas,
    ...animalSagas,
    ...picketSagas,
    ...supplierSagas,
    ...profitCenterSagas,
    ...breedSagas,
    ...dietStrategySagas,
    ...marketAnalyseSagas,
    ...supplementSagas,
    ...farmSagas,
    ...benchmarkSagas,
    ...pastureSagas,
    ...handlingOperationSagas,
    ...saleScenarioSagas,
    ...financialSagas,
    ...clientSagas,
    ...userSagas,
    ...lotSupplementConsumptionSagas,
    ...accountFarmSagas,
    ...boitelScenarioSagas,
    ...boitelSaleScenarioSagas,
    ...breedReproductionScoreSagas,
    ...reproductionPeriodsSagas,
    ...farmDeathReasonSagas,
    ...userTriggerSagas,
    ...vacinationsSagas,
    ...quotaSagas,
    ...resultCentersSagas,
    ...retreatSagas,
    ...costCentersSagas,

    takeLatest(AppTypes.AUTHENTICATE, authenticate),
    takeLatest(AppTypes.GET_ALL_ROLES, getAllRoles),
    takeLatest(TypologyItemTypes.GET_ALL_TYPOLOGYITEM, indexTypologyItem),
    takeLatest(GeneralParametersTypes.LIST_WEATHER_SEASON, indexWeatherSeasons),
  ]);
}
