import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Axios from "axios";
/* Components */
import NumberFormat from "react-number-format";
import {
  Modal,
  Empty,
  Row,
  Col,
  Select,
  Input,
  Spin,
  notification,
  DatePicker,
  Tooltip,
  Icon,
  Button,
} from "antd";
import {
  Container,
  Header,
  Body,
  Footer,
  ButtonNavigation,
  CustomDivider,
  NutritionalBox,
  NutritionalSupplementBox,
  NutritionalGdpValidationBox,
} from "./styles";
import RainIcon from "../../../../../generalParameter/weatherSeasonsParameter/display/icons/rain";
import SunIcon from "../../../../../generalParameter/weatherSeasonsParameter/display/icons/sun";
import TransitionIcon from "../../../../../generalParameter/weatherSeasonsParameter/display/icons/transition";
import InfoIcon from "../../../../../../components/utils/icons/info";
import GdpModalValidation from "../../../../../../components/modals/gdpModalValidation";
import { TiArrowDownThick, TiArrowUpThick } from "react-icons/ti";

/** Services */
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../../../../services/helpersMethodsService";
import { callValidationGdp } from "../../../../../../services/validateGdpService";
import { classifyPeriodCarbonFootPrint } from "../../../../../../services/carbonFootprintService";
import InfoTooltip from "../../../../../../components/utils/infoTooltip";

const PeriodModel = ({
  onAction,
  pastures,
  supplements,
  dietStrategyData,
  seasons,
  visible,
  index,
  startDateDeadline,
  endDateDeadline,
  dietStrategyPeriodCurveDatesAndWeights,
  isEditing,
  carbonFootprintParameters,
  carbonBaseKg,
  carbonCredit,
}) => {
  /* Redux variables */
  const { dietStrategyPeriodSelected } = useSelector(
    (state) => state.purchaseScenario
  );
  const { translation } = useSelector((state) => state.app);
  /** Variables */
  const [signal, setSignal] = useState(null);
  const [executeAction, setExecuteAction] = useState(null);
  const [form, setForm] = useState(null);
  const [loadinDiets, setLoadingDiets] = useState(false);
  const [errors, setErrors] = useState([]);
  const productionSubSystems = [
    {
      code: "Extensive",
      name: translation.productionSubSystem.extensive,
    },
    {
      code: "SemiIntensive",
      name: translation.productionSubSystem.semiIntensive,
    },
    {
      code: "Intensive",
      name: translation.productionSubSystem.intensive,
    },
  ];
  const [gdpValidationReturn, setGdpValidationReturn] = useState({
    animal: {
      AssymptoticWeight: 0,
      GeneticGroup: "string",
      SexualClass: "string",
      System: "string",
      Weight: 0,
    },
    code: "string",
    minerals: {
      Ca: {
        Code: 1,
        Necessary: 0,
        Consumed: 0,
      },
      P: {
        Code: 1,
        Necessary: 0,
        Consumed: 0,
      },
      Zn: {
        Code: 1,
        Necessary: 0,
        Consumed: 0,
      },
      Na: {
        Code: 1,
        Necessary: 0,
        Consumed: 0,
      },
    },
    consumed: {
      NDT: 0,
      PB: 0,
    },
    day: 0,
    necessary: {
      NDT: 0,
      PB: 0,
    },
    pasture: {
      Consumed: 0,
      Need: 0,
    },
    trough: {
      Consumed: 0,
      Need: 0,
    },
    weightGain: 0,
  });
  const [modalGdpValidationVisible, setModalGdpValidationVisible] =
    useState(false);
  const [loadinGDPValidation, setLoadingGDPValidation] = useState(false);

  const selectSupplementConsumption = (
    <Select
      value={form?.supplementConsumptionMeasureType || "G_ANIMAL"}
      onChange={(value) => {
        setForm({
          ...form,
          supplementConsumptionMeasureType: value,
        });
        setErrors(
          errors.filter((e) => e !== "supplementConsumptionMeasureType")
        );
      }}
    >
      <Select.Option
        value="PV_ANIMAL"
        disabled={form?.supplement?.voluntaryConsumptionType !== "PV_ANIMAL"}
      >
        %PV
      </Select.Option>
      <Select.Option
        value="I_MS_PV_ANIMAL"
        disabled={
          form?.supplement?.voluntaryConsumptionType !== "I_MS_PV_ANIMAL"
        }
      >
        %IMS PV
      </Select.Option>
      <Select.Option
        value="G_KG_PV_ANIMAL"
        disabled={
          form?.supplement?.voluntaryConsumptionType !== "G_KG_PV_ANIMAL"
        }
      >
        g/kg de PV
      </Select.Option>
      <Select.Option
        value="KG_ANIMAL"
        disabled={form?.supplement?.voluntaryConsumptionType !== "KG_ANIMAL"}
      >
        kg/dia
      </Select.Option>
      <Select.Option
        value="G_ANIMAL"
        disabled={form?.supplement?.voluntaryConsumptionType !== "G_ANIMAL"}
      >
        g/dia
      </Select.Option>
    </Select>
  );
  const { Option } = Select;
  const dateFormat = "DD/MM/YYYY";
  /** Redux Variables */
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { newPurchaseScenario, breeds } = useSelector(
    (state) => state.purchaseScenario
  );

  /** Effects */
  useEffect(() => {
    async function setPastureAndSupplement() {
      setLoadingDiets(true);
      setTimeout(() => setLoadingDiets(false), 500);
    }
    setForm(dietStrategyPeriodSelected);
    setErrors([]);
    setPastureAndSupplement();
  }, [dietStrategyPeriodSelected]);

  /** Memo */
  const weightDateIn = useMemo(() => {
    if (form != null) {
      const startDay = moment(form.startDate).isSame(
        moment(newPurchaseScenario.initialSimulationDate),
        "day"
      )
        ? 0
        : moment(form?.startDate).diff(
            moment(newPurchaseScenario.initialSimulationDate),
            "days",
            false
          );
      return form != null
        ? startDay === 0
          ? 240
          : dietStrategyPeriodCurveDatesAndWeights[
              startDay > 1 ? startDay - 1 : startDay
            ]?.y
        : 0;
    } else {
      return 0;
    }
  }, [
    form,
    newPurchaseScenario.initialSimulationDate,
    dietStrategyPeriodCurveDatesAndWeights,
  ]);

  const weightDateOut = useMemo(() => {
    if (form != null) {
      const startDay = form.startDay;
      const endDay = form?.endDay && form.endDay;
      const gdp = form?.gdpExpectation ? form?.gdpExpectation : 0;
      const gdpSum = (endDay - startDay) * gdp;
      let sum = weightDateIn + gdpSum;
      return sum || 0;
    } else {
      return 0;
    }
  }, [form, weightDateIn]);
  // ************** PASTURE MEMOS ***********************
  const pastureCP = useMemo(() => {
    if (
      form != null &&
      form.pasture != null &&
      form.pastureSeason != null &&
      form.pastureFertilized != null
    ) {
      const pasture = pastures
        .find((p) => p.id === form.pasture.id)
        ?.seasons.find(
          (season) =>
            season.season === form.pastureSeason &&
            season.fertilized === form.pastureFertilized
        );
      return pasture.cp;
    } else {
      return 0;
    }
  }, [form, pastures]);
  const pastureTDN = useMemo(() => {
    if (
      form != null &&
      form.pasture != null &&
      form.pastureSeason != null &&
      form.pastureFertilized != null
    ) {
      const pasture = pastures
        .find((p) => p.id === form.pasture.id)
        .seasons.find(
          (season) =>
            season.season === form.pastureSeason &&
            season.fertilized === form.pastureFertilized
        );
      return pasture.tdn;
    } else {
      return 0;
    }
  }, [form, pastures]);
  // *********** SUPPLEMENTS MEMOS *****************
  const supplementCP = useMemo(() => {
    if (form != null && form.supplement != null) {
      const supplement = supplements.find((p) => p.id === form.supplement.id);
      const versionIndex = supplement.versions.findIndex(
        (v) => v.version === form?.supplementVersion
      );
      return versionIndex > -1 ? supplement.versions[versionIndex]?.cp : 0;
    } else {
      return 0;
    }
  }, [form, supplements]);
  const supplementTDN = useMemo(() => {
    if (form != null && form.supplement != null) {
      const supplement = supplements.find((p) => p.id === form.supplement.id);
      const versionIndex = supplement.versions.findIndex(
        (v) => v.version === form?.supplementVersion
      );
      return versionIndex > -1 ? supplement.versions[versionIndex]?.tdn : 0;
    } else {
      return 0;
    }
  }, [form, supplements]);
  const supplementDryMatterPercentage = useMemo(() => {
    if (form != null && form.supplement != null) {
      const supplement = supplements.find((p) => p.id === form.supplement.id);
      const versionIndex = supplement.versions.findIndex(
        (v) => v.version === form?.supplementVersion
      );
      return versionIndex > -1
        ? supplement.versions[versionIndex]?.dryMatterPercentage
        : 0;
    } else {
      return 0;
    }
  }, [form, supplements]);
  // Voluntary
  const supplementMinimumVoluntaryConsuption = useMemo(() => {
    if (form != null && form.supplement != null) {
      return supplements.find((p) => p.id === form.supplement.id)
        ?.minimumVoluntaryConsumption;
    } else {
      return 0;
    }
  }, [form, supplements]);
  const supplementMaximumVoluntaryConsuption = useMemo(() => {
    if (form != null && form.supplement != null) {
      return supplements.find((p) => p.id === form.supplement.id)
        ?.maximumVoluntaryConsumption;
    } else {
      return 0;
    }
  }, [form, supplements]);
  const supplementVoluntaryConsuptionUnit = useMemo(() => {
    if (form != null && form.supplement != null) {
      const voluntaryConsumptionType = supplements.find(
        (p) => p.id === form.supplement.id
      )?.voluntaryConsumptionType;
      return voluntaryConsumptionType !== null
        ? voluntaryConsumptionType === "G_ANIMAL"
          ? "g/dia"
          : voluntaryConsumptionType === "G_KG_PV_ANIMAL"
          ? "g/kg de PV"
          : voluntaryConsumptionType === "PV_ANIMAL"
          ? "%PV"
          : voluntaryConsumptionType === "I_MS_PV_ANIMAL"
          ? "%IMS PV"
          : voluntaryConsumptionType === "KG_ANIMAL"
          ? "kg/dia"
          : "g/dia"
        : "g/dia";
    } else {
      return "g";
    }
  }, [form, supplements]);
  // Pure
  const supplementMinimumPureConsuption = useMemo(() => {
    if (form != null && form.supplement != null) {
      return supplements.find((p) => p.id === form.supplement.id)?.minimumPure;
    } else {
      return 0;
    }
  }, [form, supplements]);
  const supplementMaximumPureConsuption = useMemo(() => {
    if (form != null && form.supplement != null) {
      return supplements.find((p) => p.id === form.supplement.id)?.maximumPure;
    } else {
      return 0;
    }
  }, [form, supplements]);
  const supplementMaxPureTrought = useMemo(() => {
    if (form != null && form.supplement != null) {
      return (
        supplements.find((p) => p.id === form.supplement.id)
          ?.maximumPureTrough || 0
      );
    } else {
      return 0;
    }
  }, [form, supplements]);
  const supplementMinPureTrought = useMemo(() => {
    if (form != null && form.supplement != null) {
      return (
        supplements.find((p) => p.id === form.supplement.id)
          ?.minimumPureTrough || 0
      );
    } else {
      return 0;
    }
  }, [form, supplements]);
  // Mix
  const supplementMinimumMixConsuption = useMemo(() => {
    if (form != null && form.supplement != null) {
      return supplements.find((p) => p.id === form.supplement.id)?.minimumMix;
    } else {
      return 0;
    }
  }, [form, supplements]);
  const supplementMaximumMixConsuption = useMemo(() => {
    if (form != null && form.supplement != null) {
      return supplements.find((p) => p.id === form.supplement.id)?.maximumMix;
    } else {
      return 0;
    }
  }, [form, supplements]);
  const supplementMaxMixTrought = useMemo(() => {
    if (form != null && form.supplement != null) {
      return (
        supplements.find((p) => p.id === form.supplement.id)
          ?.maximumMixTrough || 0
      );
    } else {
      return 0;
    }
  }, [form, supplements]);
  const supplementMinMixTrought = useMemo(() => {
    if (form != null && form.supplement != null) {
      return (
        supplements.find((p) => p.id === form.supplement.id)
          ?.minimumMixTrough || 0
      );
    } else {
      return 0;
    }
  }, [form, supplements]);
  const supplementCalcium = useMemo(() => {
    if (form != null && form.supplement != null) {
      const supplement = supplements.find((p) => p.id === form.supplement.id);
      const versionIndex = supplement.versions.findIndex(
        (v) => v.version === form?.supplementVersion
      );
      return versionIndex > -1 ? supplement.versions[versionIndex]?.calcium : 0;
    } else {
      return 0;
    }
  }, [form, supplements]);
  const supplementPhosphorus = useMemo(() => {
    if (form != null && form.supplement != null) {
      const supplement = supplements.find((p) => p.id === form.supplement.id);
      const versionIndex = supplement.versions.findIndex(
        (v) => v.version === form?.supplementVersion
      );
      return versionIndex > -1
        ? supplement.versions[versionIndex]?.phosphorus
        : 0;
    } else {
      return 0;
    }
  }, [form, supplements]);
  const supplementZinc = useMemo(() => {
    if (form != null && form.supplement != null) {
      const supplement = supplements.find((p) => p.id === form.supplement.id);
      const versionIndex = supplement.versions.findIndex(
        (v) => v.version === form?.supplementVersion
      );
      return versionIndex > -1 ? supplement.versions[versionIndex]?.zinc : 0;
    } else {
      return 0;
    }
  }, [form, supplements]);
  const supplementSodium = useMemo(() => {
    if (form != null && form.supplement != null) {
      const supplement = supplements.find((p) => p.id === form.supplement.id);
      const versionIndex = supplement.versions.findIndex(
        (v) => v.version === form?.supplementVersion
      );
      return versionIndex > -1 ? supplement.versions[versionIndex]?.sodium : 0;
    } else {
      return 0;
    }
  }, [form, supplements]);
  // Callback

  /** Methods */
  function goNext() {
    if (validation()) {
      validateGdpAndDoAction("next");
    }
  }

  function goPrevius() {
    if (validation()) {
      validateGdpAndDoAction("previous");
    }
  }

  function saveAndFinalize() {
    if (validation()) {
      validateGdpAndDoAction("finalize");
    }
  }

  function remove() {
    setForm(null);
    onAction(form, "remove");
  }

  function closeModal() {
    if (signal) signal.cancel("Canceling GDP");
    setLoadingGDPValidation(false);
    setForm(null);
    onAction(null);
  }

  /** Method validate gdp */
  async function validateGdpAndDoAction(action) {
    setLoadingGDPValidation(true);
    const newSignal = Axios.CancelToken.source();
    setSignal(newSignal);
    try {
      const body = createRequestToValidateGDP();
      let {
        data: { results: result },
      } = await callValidationGdp({
        groupId,
        farmId,
        lotId: null,
        breedId: newPurchaseScenario.breedId,
        body,
        signal: newSignal,
      });
      const carbonFootprintParameter = classifyPeriodCarbonFootPrint(
        carbonFootprintParameters,
        form
      );
      if (result != null) {
        setForm({
          ...form,
          carbonFootprintParameter,
          gdpPossible: result.weightGain,
          pastureConsumed: result.pasture?.Consumed,
          pastureNeeded: result.pasture?.Need,
          supplementConsumed: result.trough?.Consumed,
          supplementNeeded: result.trough?.Need,
          tdnExceededOrMissing: result.tdnExcededOrMissing,
          tdnExceededOrMissingValue: result.tdnExcededOrMissingValue,
          cpExceededOrMissing: result.cpExcededOrMissing,
          cpExceededOrMissingValue: result.cpExcededOrMissingValue,
        });
        setGdpValidationReturn(result);
        setExecuteAction(action);
        setModalGdpValidationVisible(true);
      } else {
        const diet = {
          ...form,
          carbonFootprintParameter,
          conflictedGdp: false,
        };
        notification.error({
          message: "Houve um erro ao validar ganho de peso para esta dieta",
        });
        setForm(null);
        onAction(diet, action);
      }
    } catch (error) {
      if (!Axios.isCancel(error)) {
        notification.error({
          message: "Houve um erro ao validar ganho de peso para esta dieta",
        });
        const body = {
          ...form,
          allWeightGains: [],
        };
        setForm(null);
        onAction(body, action);
      }
    } finally {
      setLoadingGDPValidation(false);
    }
  }

  /** Method validate period form */
  function validation() {
    let submitErrors = [];

    if (form.productionSubSystem == null) {
      submitErrors = [...submitErrors, "productionSubSystem"];
    }

    if (
      form.productionSubSystem != null &&
      (form.productionSubSystem === "SemiIntensive" ||
        form.productionSubSystem === "Extensive")
    ) {
      /* Validate pastures fields*/
      if (form.pasture === null) {
        submitErrors = [...submitErrors, "pasture"];
      }
      if (form.pastureSeason === null) {
        submitErrors = [...submitErrors, "pastureSeason"];
      }
      if (form.pastureFertilized === null) {
        submitErrors = [...submitErrors, "pastureFertilized"];
      }
      if (form.pastureDailyCost === null) {
        submitErrors = [...submitErrors, "pastureDailyCost"];
      }
    }

    /* If productionSubSystem is Semi-Intensive so validate supplement fields */
    if (
      form.supplement == null &&
      (form.productionSubSystem === "SemiIntensive" ||
        form.productionSubSystem === "Intensive")
    ) {
      submitErrors = [...submitErrors, "supplement"];
    }
    if (form.supplement != null) {
      if (form.supplementConsumptionByAnimal == null) {
        submitErrors = [...submitErrors, "supplementConsumptionByAnimal"];
      }
      if (form.supplementConsumptionMeasureType == null) {
        submitErrors = [...submitErrors, "supplementConsumptionMeasureType"];
      }
      if (form.supplementConsumptionType == null) {
        submitErrors = [...submitErrors, "supplementConsumptionType"];
      }
      if (form.supplementDailyCost == null) {
        submitErrors = [...submitErrors, "supplementDailyCost"];
      }
    }

    if (form.gdpExpectation == null || form.gdpExpectation === 0) {
      submitErrors = [...submitErrors, "gdpExpectation"];
    }
    if (form.carcassHarnessing == null) {
      submitErrors = [...submitErrors, "carcassHarnessing"];
    }
    if (
      form.gdpExpectation != null &&
      (form.gdpExpectation < -0.5 || form.gdpExpectation > 3)
    ) {
      submitErrors = [...submitErrors, "gdpExpectation"];
      notification.error({
        message: "A expectativa de GDP deve estar entre -0.5 Kg e 3 Kg",
      });
    }
    if (
      form.carcassHarnessing != null &&
      (form.carcassHarnessing < 45 || form.carcassHarnessing > 60)
    ) {
      submitErrors = [...submitErrors, "carcassHarnessing"];
      notification.error({
        message: "O aproveitamento de carcaça deve estar entre 45% e 60%",
      });
    }

    if (submitErrors.length > 0) {
      setErrors(submitErrors);
      return false;
    } else {
      setErrors([]);
      return true;
    }
  }

  /** Method to create the request to validate gdp */
  function createRequestToValidateGDP() {
    try {
      let body = [];
      let bodyItem = {
        ExpectedWeightGain: form.gdpExpectation,
        CO2BaseKg_KgPV: carbonBaseKg || 0,
        CarbonCredit: carbonCredit || 0,
        System:
          form.productionSubSystem === "Extensive"
            ? "Extensivo"
            : form.productionSubSystem === "SemiIntensive"
            ? "Semi-Intensivo"
            : form.productionSubSystem === "Intensive"
            ? "Intensivo"
            : null,
      };
      let FirstWeightOnDiet = weightDateIn;
      const PastureSelected =
        form?.pasture?.id != null
          ? pastures.find((p) => p.id === form?.pasture.id)
          : null;
      const SupplementSelected =
        form?.supplement?.id != null
          ? supplements.find((s) => s.id === form?.supplement.id)
          : null;

      /** Create DietDetails - Start */
      let DietDetails = [];
      const carbonFootprintParameter = classifyPeriodCarbonFootPrint(
        carbonFootprintParameters,
        form
      );
      let diet = {
        Begin: form.startDate,
        End: form.endDate,
        CO2Kg_KgPV:
          carbonFootprintParameter != null
            ? carbonFootprintParameter?.co2ProductionMean
            : 0,
      };
      /** Create pasture DTO */
      let Pasture = {};
      if (PastureSelected != null && form?.productionSubSystem !== "Intesive") {
        let pastureSeason = PastureSelected.seasons.find(
          (s) =>
            s.season === form.pastureSeason &&
            s.fertilized === form.pastureFertilized
        );
        Pasture = {
          ...Pasture,
          id: PastureSelected.id,
          NDT: pastureSeason?.tdn,
          PB: pastureSeason?.cp,
          FDN: pastureSeason?.fdn / 100,
          Ca: pastureSeason?.calcium || 0, // g/kg
          P: pastureSeason?.phosphorus || 0, // g/kg
          Na: pastureSeason?.sodium || 0, // g/kg
          Zn: pastureSeason?.zinc || 0, // mg/kg
          ConsumptionUnit:
            pastureSeason?.consumptionType === "PV_ANIMAL"
              ? "PV"
              : pastureSeason?.consumptionType === "KG"
              ? "g"
              : null,
          MaxConsumption:
            pastureSeason?.consumptionType === "PV_ANIMAL"
              ? pastureSeason?.maximumConsumption / 100
              : pastureSeason?.consumptionType === "KG"
              ? pastureSeason?.maximumConsumption * 1000
              : pastureSeason?.maximumConsumption,
          MinConsumption:
            pastureSeason?.consumptionType === "PV_ANIMAL"
              ? pastureSeason?.minimumConsumption / 100
              : pastureSeason?.consumptionType === "KG"
              ? pastureSeason?.minimumConsumption * 1000
              : pastureSeason?.minimumConsumption,
        };
      } else {
        Pasture = null;
      }
      /** Create Supplement DTO */
      let Trough = {};
      if (SupplementSelected != null) {
        const versionIndex = SupplementSelected.versions.findIndex(
          (v) => v.version === form?.supplementVersion
        );
        if (versionIndex > -1) {
          const supplementVersion = SupplementSelected.versions[versionIndex];
          Trough = {
            ...Trough,
            id: SupplementSelected?.id,
            NDT: supplementVersion?.tdn,
            PB: supplementVersion?.cp,
            Ca: supplementVersion?.calcium || 0, // g/kg
            P: supplementVersion?.phosphorus || 0, // g/kg
            Na: supplementVersion?.sodium || 0, // g/kg
            Zn: supplementVersion?.zinc || 0, // mg/kg
            DryMatterPercentage:
              supplementVersion?.dryMatterPercentage == null
                ? 1
                : supplementVersion?.dryMatterPercentage / 100,
            ConsumptionUnit:
              form?.supplementConsumptionMeasureType === "PV_ANIMAL"
                ? "PV"
                : form?.supplementConsumptionMeasureType === "I_MS_PV_ANIMAL"
                ? "I_MS_PV"
                : form?.supplementConsumptionMeasureType === "G_KG_PV_ANIMAL"
                ? "g_kg_pv"
                : form?.supplementConsumptionMeasureType === "G_ANIMAL"
                ? "g"
                : form?.supplementConsumptionMeasureType === "KG_ANIMAL"
                ? "g"
                : null,
            Consumption:
              form?.supplementConsumptionMeasureType === "PV_ANIMAL"
                ? form?.supplementConsumptionByAnimal / 100
                : form?.supplementConsumptionMeasureType === "I_MS_PV_ANIMAL"
                ? form?.supplementConsumptionByAnimal / 100
                : form?.supplementConsumptionMeasureType === "G_KG_PV_ANIMAL"
                ? form?.supplementConsumptionByAnimal
                : form?.supplementConsumptionMeasureType === "G_ANIMAL"
                ? form?.supplementConsumptionByAnimal
                : form?.supplementConsumptionMeasureType === "KG_ANIMAL"
                ? form?.supplementConsumptionByAnimal * 1000
                : form?.supplementConsumptionByAnimal,
            MaxConsumption:
              SupplementSelected.voluntaryConsumptionType === "PV_ANIMAL"
                ? SupplementSelected?.maximumVoluntaryConsumption / 100
                : SupplementSelected.voluntaryConsumptionType ===
                  "I_MS_PV_ANIMAL"
                ? SupplementSelected?.maximumVoluntaryConsumption / 100
                : SupplementSelected.voluntaryConsumptionType === "KG_ANIMAL"
                ? SupplementSelected?.maximumVoluntaryConsumption * 1000
                : SupplementSelected?.maximumVoluntaryConsumption,
            MinConsumption:
              SupplementSelected.voluntaryConsumptionType === "PV_ANIMAL"
                ? SupplementSelected?.minimumVoluntaryConsumption / 100
                : SupplementSelected.voluntaryConsumptionType ===
                  "I_MS_PV_ANIMAL"
                ? SupplementSelected?.minimumVoluntaryConsumption / 100
                : SupplementSelected.voluntaryConsumptionType === "KG_ANIMAL"
                ? SupplementSelected?.minimumVoluntaryConsumption * 1000
                : SupplementSelected?.minimumVoluntaryConsumption,
            MinMaxUnit:
              SupplementSelected?.voluntaryConsumptionType !== null
                ? SupplementSelected?.voluntaryConsumptionType === "G_ANIMAL"
                  ? "g"
                  : SupplementSelected?.voluntaryConsumptionType ===
                    "G_KG_PV_ANIMAL"
                  ? "g_kg_pv"
                  : SupplementSelected?.voluntaryConsumptionType === "KG_ANIMAL"
                  ? "g"
                  : SupplementSelected.voluntaryConsumptionType === "PV_ANIMAL"
                  ? "PV"
                  : SupplementSelected.voluntaryConsumptionType ===
                    "I_MS_PV_ANIMAL"
                  ? "I_MS_PV"
                  : "g"
                : "g",
          };
        } else {
          Trough = null;
        }
      } else {
        Trough = null;
      }
      const breed = breeds.find((b) => b.id === newPurchaseScenario?.breedId);
      const GeneticGroup = breed.geneticGroup;
      const SexualClass =
        newPurchaseScenario?.gender === "Male" && newPurchaseScenario?.cap
          ? "MC"
          : newPurchaseScenario?.gender === "Male" && !newPurchaseScenario?.cap
          ? "MNC"
          : "F";

      diet = {
        ...diet,
        Pasture,
        Trough,
      };

      DietDetails = [...DietDetails, diet];

      /** Create DietDetails - End */
      bodyItem = {
        ...bodyItem,
        GeneticGroup,
        SexualClass,
        FirstWeightOnDiet,
        DietDetails,
      };
      body = [...body, bodyItem];
      return { Diet: body };
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /** Method to cancel the gdp validation */
  function handleCancelGdpValidation() {
    signal.cancel("Canceling GDP");
    setLoadingGDPValidation(false);
  }

  /** Method that handle the date change */
  async function handleChangeDatePeriod(mDate, type) {
    const { initialSimulationDate } = newPurchaseScenario;
    /* Variables */
    let startDate = null;
    let endDate = null;
    let startDay = null;
    let endDay = null;

    if (mDate != null) {
      const date = mDate.toDate();

      /* Validate the startDate deadline (data atula) and endDate deadline (data atual + 1096 days) */
      if (
        (type === "start" &&
          moment(date).isSameOrAfter(moment(initialSimulationDate), "day")) || type === "end"
      ) {
        /** Validate if endDate is greater than startDate or inverse */
        if (
          (type === "end" &&
            moment(date).isAfter(moment(form.startDate), "day")) ||
          (type === "start" &&
            moment(date).isBefore(moment(form.endDate), "day"))
        ) {
          /** Validate deadLines */
          if (
            moment(date).isAfter(moment(startDateDeadline), "day")
          ) {
            /* Set the dates */
            if (type === "start") {
              startDate = moment(date).format("YYYY-MM-DD");
              startDay = moment(date).diff(
                initialSimulationDate,
                "days",
                false
              );
              endDate = form.endDate;
              endDay = form.endDay;
            } else if (type === "end") {
              startDate = form.startDate;
              startDay = form.startDay;
              endDate = moment(date).format("YYYY-MM-DD");
              endDay =
                moment(date).diff(moment(initialSimulationDate), "days", true) +
                1;
            }

            /* Recalculate season */
            let i = startDay + 1;
            let season = [];

            do {
              const currentMonth = Number(
                moment(initialSimulationDate).add(i, "days").month()
              );
              let loopSeason = seasons.find(
                (s) => s.month === currentMonth + 1
              ).season;
              if (!season.includes(loopSeason) || season.length === 0) {
                season.push(loopSeason);
              }
              i++;
            } while (i < endDay);

            setForm({
              ...form,
              startDate,
              endDate,
              startDay,
              endDay,
              season: season.join(","),
            });
          }
        }
      }
    }
  }

  /** Method that handle the change of ProductionSubSystem */
  function handleChangeProductionSubSystem(value) {
    let updateForm = { ...form, productionSubSystem: value };
    if (value === "Intensive") {
      updateForm = {
        ...updateForm,
        pasture: null,
        pastureSeason: null,
        pastureFertilized: null,
        pastureDailyCost: null,
      };
    }
    setForm(updateForm);
    setErrors(errors.filter((e) => e !== "productionSubSystem"));
  }

  /** Method to set Pasture Season Data */
  function handleSetPastureSeasonByData(value) {
    let formValue = { ...form };
    if (formValue.season.split(",").length === 1) {
      const pasturesValues = pastures.find((p) => p.id === value);
      const seasonValue = formValue.season.split(",")[0];
      const seasonSelected = pastures
        .find((p) => p.id === pasturesValues.id)
        .arraySeasonOptions.find((aso) => aso.season === seasonValue);

      if (seasonSelected != null) {
        const optionsFertilized = seasonSelected.optionsFertilized;
        if (optionsFertilized.length === 1) {
          let fertilizedValue = optionsFertilized[0];
          let pastureFertilized = fertilizedValue === "Yes" ? true : false;
          let pastureCost = pasturesValues.seasons.find(
            (season) =>
              season.season === seasonValue &&
              season.fertilized === pastureFertilized
          )?.cost;

          formValue = {
            ...form,
            pasture: pasturesValues,
            pastureSeason: seasonValue,
            pastureFertilized,
            pastureDailyCost: pasturesValues.seasons.find(
              (season) =>
                season.season === seasonValue &&
                season.fertilized === pastureFertilized
            )
              ? pastureCost
              : 0,
          };
        } else {
          formValue = {
            ...form,
            pasture: pasturesValues,
            pastureSeason: seasonValue,
            pastureFertilized: null,
            pastureDailyCost: null,
          };
        }
      } else {
        formValue = {
          ...form,
          pasture: pasturesValues,
          pastureSeason: seasonValue,
          pastureFertilized: null,
          pastureDailyCost: null,
        };
      }
      setForm(formValue);
    }
  }

  /** Method that handle the change of the Pasture */
  function handleChangePastureSelected(value) {
    if (value != null) {
      setForm({
        ...form,
        pasture: pastures.find((p) => p.id === value),
        pastureSeason: null,
        pastureFertilized: null,
        pastureDailyCost: null,
      });
    }
    handleSetPastureSeasonByData(value);
  }
  /** Method that handle the change of pasture season */
  function handleChangePastureSeasonSelected(value) {
    let formValue = { ...form };
    /** Verify if value is not null */
    if (value != null) {
      const seasonSelected = pastures
        .find((p) => p.id === form.pasture.id)
        .arraySeasonOptions.find((aso) => aso.season === value);
      /* Verify if the pasture have the season */
      if (seasonSelected != null) {
        const optionsFertilized = seasonSelected?.optionsFertilized;
        /* If the season selected is only fertilized or not, so already select the option and set the daily cost */
        if (optionsFertilized.length === 1) {
          let fertilizedValue = optionsFertilized[0];
          let pastureFertilized = fertilizedValue === "Yes" ? true : false;
          let pastureCost = pastures
            .find((p) => p.id === form.pasture.id)
            .seasons.find(
              (season) =>
                season.season === value &&
                season.fertilized === pastureFertilized
            )?.cost;

          formValue = {
            ...form,
            pastureSeason: value,
            pastureFertilized,
            pastureDailyCost: pastures
              .find((p) => p.id === form.pasture.id)
              .seasons.find(
                (season) =>
                  season.season === value &&
                  season.fertilized === pastureFertilized
              )
              ? pastureCost
              : 0,
          };
        } else {
          formValue = {
            ...form,
            pastureSeason: value,
            pastureFertilized: null,
            pastureDailyCost: null,
          };
        }
      } else {
        formValue = {
          ...form,
          pastureSeason: value,
          pastureFertilized: null,
          pastureDailyCost: null,
        };
      }
    }
    setErrors(errors.filter((e) => e !== "pastureFertilized"));
    setForm(formValue);
  }
  /** Method that handle the change of pasture season fertilized  */
  function handleChangePastureSeasonFertilizedSelected(value) {
    if (value != null) {
      let pastureFertilized = value === "Yes" ? true : false;
      const newForm = {
        ...form,
        pastureFertilized,
        pastureDailyCost: pastures
          .find((p) => p.id === form.pasture.id)
          .seasons.find(
            (season) =>
              season.season === form.pastureSeason &&
              season.fertilized === pastureFertilized
          )
          ? pastures
              .find((p) => p.id === form.pasture.id)
              .seasons.find(
                (season) =>
                  season.season === form.pastureSeason &&
                  season.fertilized === pastureFertilized
              ).cost
          : 0,
      };
      setForm(newForm);
    }
  }

  /** Method close ModalGdpValidation to edit Diet */
  function closeModalGdpValidation() {
    setExecuteAction(null);
    setModalGdpValidationVisible(false);
  }

  /** Method ignore ModalGdpValidation and save */
  function ignoreGdpAlertAndSave() {
    const action = executeAction;
    const { code, allWeightGains } = gdpValidationReturn;
    let conflictedGdp = false;
    switch (code) {
      case "2.2":
      case "4.1":
      case "4.2":
        conflictedGdp = false;
        break;
      case "2.1":
      case "2.3":
      case "2.4":
      case "4.3":
      case "4.4":
        conflictedGdp = true;
        break;
      default:
        break;
    }
    let dietPeriodAllWeightGains = [];
    if (allWeightGains?.length > 0) {
      let loopStartDay = form.startDay;
      allWeightGains.forEach((gdp, i) => {
        loopStartDay = i === 0 ? loopStartDay : loopStartDay + 1;
        dietPeriodAllWeightGains.push({
          gdp,
          gdpDate:
            i === 0
              ? moment(form.startDate)
              : moment(form.startDate).add(i, "days"),
          gdpDay: loopStartDay,
        });
      });
    }
    const body = {
      ...form,
      conflictedGdp,
      allWeightGains: dietPeriodAllWeightGains,
    };
    setExecuteAction(null);
    setForm(null);
    onAction(body, action);
    setModalGdpValidationVisible(false);
  }

  return (
    <Modal
      width={900}
      visible={visible}
      centered
      maskClosable={false}
      closable={true}
      onCancel={closeModal}
      footer={null}
    >
      {form != null ? (
        <>
          <Container>
            <Header>
              <span>
                Complete os campos abaixo para definir a dieta entre o período
                selecionado
              </span>
            </Header>
            <Body>
              <Spin
                spinning={loadinDiets || loadinGDPValidation}
                tip={
                  loadinGDPValidation ? (
                    <>
                      <span>Validando GDP...</span>
                      <br />
                      <Button
                        type="danger"
                        onClick={() => handleCancelGdpValidation()}
                      >
                        Cancelar
                      </Button>
                    </>
                  ) : (
                    ""
                  )
                }
              >
                {errors.length > 0 && (
                  <Row
                    type="flex"
                    justify="center"
                    align="middle"
                    style={{ marginBottom: 10 }}
                  >
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                {/* Start Date and End Date */}
                <Row type="flex">
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    xl={{ span: 24, offset: 0 }}
                  >
                    <Row
                      type="flex"
                      className="rowInput"
                      justify="space-between"
                    >
                      {/* Start Date */}
                      <Col
                        xs={{ span: 6, offset: 0 }}
                        sm={{ span: 6, offset: 0 }}
                        md={{ span: 6, offset: 0 }}
                        lg={{ span: 6, offset: 0 }}
                        xl={{ span: 6, offset: 0 }}
                      >
                        <Row>
                          <label>{`Ínicio (Min: ${moment(
                            startDateDeadline
                          ).format("DD/MM/YYYY")})`}</label>
                        </Row>
                        <Row>
                          <label>{`Peso: ${weightDateIn?.toFixed(
                            2
                          )} Kg`}</label>
                        </Row>
                        <Row>
                          <DatePicker
                            value={moment(form.startDate, "YYYY-MM-DD")}
                            format={dateFormat}
                            onChange={(date) => {
                              handleChangeDatePeriod(date, "start");
                            }}
                            disabled={true}
                            allowClear={false}
                          />
                        </Row>
                      </Col>
                      {/* Seasons */}
                      <Col
                        xs={{ span: 11, offset: 1 }}
                        sm={{ span: 11, offset: 1 }}
                        md={{ span: 11, offset: 1 }}
                        lg={{ span: 11, offset: 1 }}
                        xl={{ span: 11, offset: 1 }}
                      >
                        <CustomDivider dashed>
                          <div className="seasonContainer">
                            {form.season
                              .split(",")
                              .map((season) =>
                                season === "Water" ? (
                                  <RainIcon key={season} />
                                ) : season === "Dry" ? (
                                  <SunIcon key={season} />
                                ) : season === "Transition" ? (
                                  <TransitionIcon key={season} />
                                ) : null
                              )}
                          </div>
                        </CustomDivider>
                      </Col>
                      {/* End Date */}
                      <Col
                        xs={{ span: 5, offset: 1 }}
                        sm={{ span: 5, offset: 1 }}
                        md={{ span: 5, offset: 1 }}
                        lg={{ span: 5, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        className="align-bottom"
                      >
                        <Row>
                          <label>{`Peso: ${weightDateOut?.toFixed(
                            2
                          )} Kg`}</label>
                        </Row>
                        <Row>
                          <DatePicker
                            value={moment(form.endDate, "YYYY-MM-DD")}
                            format={dateFormat}
                            onChange={(date, dateString) => {
                              handleChangeDatePeriod(date, "end");
                            }}
                            disabled={
                              dietStrategyData.dietPeriods.length <= 1
                                ? false
                                : index >=
                                  dietStrategyData.dietPeriods.length - 1
                                ? false
                                : true
                            }
                            allowClear={false}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* ProductionSubSystem, Gdp Expectation e CarcassHarnessing */}
                <Row type="flex" style={{ marginTop: 10 }}>
                  {/* ProductionSubSystem */}
                  <Col
                    xs={{ span: 12, offset: 0 }}
                    sm={{ span: 12, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    lg={{ span: 12, offset: 0 }}
                    xl={{ span: 12, offset: 0 }}
                  >
                    <Row type="flex" justify="space-between">
                      <label
                        className={
                          errors.includes("productionSubSystem") ? "error" : ""
                        }
                      >
                        Sistema de Produção*
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <Select
                        style={{ width: "100%" }}
                        name="productionSubSystem"
                        value={
                          form.productionSubSystem == null
                            ? undefined
                            : form.productionSubSystem
                        }
                        placeholder={
                          translation.profitCenter.form
                            .productionSubSystemPlaceHolder
                        }
                        onChange={(value) => {
                          handleChangeProductionSubSystem(value);
                        }}
                      >
                        {Object.entries(productionSubSystems).length > 0 &&
                          productionSubSystems.map((pss, i) => (
                            <Option key={i} value={pss.code}>
                              {pss.name}
                            </Option>
                          ))}
                      </Select>
                    </Row>
                  </Col>
                  {/* Gdp Expectation */}
                  <Col
                    xs={{ span: 5, offset: 1 }}
                    sm={{ span: 5, offset: 1 }}
                    md={{ span: 5, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                  >
                    <Row>
                      <label
                        className={
                          errors.includes("gdpExpectation") ? "error" : ""
                        }
                      >
                        Expectativa de GDP*
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <NumberFormat
                        customInput={Input}
                        value={form?.gdpExpectation}
                        name="gdpExpectation"
                        addonAfter="Kg"
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        onValueChange={({ floatValue }) => {
                          setForm({
                            ...form,
                            gdpExpectation: floatValue ? floatValue : 0,
                          });
                          setErrors(
                            errors.filter((e) => e !== "gdpExpectation")
                          );
                        }}
                      />
                    </Row>
                  </Col>
                  {/* CarcassHarnessing */}
                  <Col
                    xs={{ span: 5, offset: 1 }}
                    sm={{ span: 5, offset: 1 }}
                    md={{ span: 5, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                  >
                    <Row>
                      <label
                        className={
                          errors.includes("carcassHarnessing") ? "error" : ""
                        }
                      >
                        Aproveit. de carcaça*
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <Tooltip
                        trigger="hover"
                        placement="bottomRight"
                        title="O valor mencionado não será utilizado em animais que possuem aproveitamento de carcaça próprio"
                      >
                        <NumberFormat
                          customInput={Input}
                          value={
                            form.carcassHarnessing != null
                              ? form.carcassHarnessing
                              : null
                          }
                          name="carcassHarnessing"
                          disabled={index > 0}
                          placeholder={translation.defaultPlaceholder}
                          addonAfter="%"
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalSeparator=","
                          thousandSeparator="."
                          onValueChange={({ floatValue }) => {
                            setForm({
                              ...form,
                              carcassHarnessing: floatValue,
                            });
                            setErrors(
                              errors.filter((e) => e !== "carcassHarnessing")
                            );
                          }}
                        />
                      </Tooltip>
                    </Row>
                  </Col>
                </Row>
                <CustomDivider>FORRAGEIRA</CustomDivider>
                <Row type="flex">
                  {/* Pasture */}
                  <Col
                    xs={{ span: 6, offset: 0 }}
                    sm={{ span: 6, offset: 0 }}
                    md={{ span: 6, offset: 0 }}
                    lg={{ span: 6, offset: 0 }}
                    xl={{ span: 6, offset: 0 }}
                  >
                    <Row>
                      <label
                        className={errors.includes("pasture") ? "error" : ""}
                      >
                        Forrageira*
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <Select
                        name="pasture"
                        style={{ width: "100%" }}
                        value={
                          form.pasture != null ? form.pasture.id : undefined
                        }
                        placeholder={translation.defaultSelectPlaceholder}
                        disabled={
                          form?.productionSubSystem === "Intensive"
                            ? true
                            : false
                        }
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          handleChangePastureSelected(value);
                          setErrors(errors.filter((e) => e !== "pasture"));
                        }}
                      >
                        {pastures != null && Object.entries(pastures).length > 0
                          ? pastures.map((p) => (
                              <Select.Option key={p.id} value={p.id}>
                                {p.name}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Row>
                  </Col>
                  {/* Pasture Season */}
                  <Col
                    xs={{ span: 5, offset: 1 }}
                    sm={{ span: 5, offset: 1 }}
                    md={{ span: 5, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                  >
                    <Row>
                      <label
                        className={
                          errors.includes("pastureSeason") ? "error" : ""
                        }
                      >
                        Período climático*
                        <Tooltip
                          placement="top"
                          title={`O período climático refere-se aos dados nutricionais e capacidade de
                              suporte referente a forrageira selecionada para este período`}
                        >
                          <InfoIcon />
                        </Tooltip>
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <Select
                        name="pastureSeason"
                        style={{ width: "100%" }}
                        value={
                          form.pastureSeason != null
                            ? form.pastureSeason
                            : undefined
                        }
                        placeholder={translation.defaultSelectPlaceholder}
                        disabled={
                          form?.productionSubSystem === "Intensive"
                            ? true
                            : false
                        }
                        onChange={(value) => {
                          handleChangePastureSeasonSelected(value);
                          setErrors(
                            errors.filter((e) => e !== "pastureSeason")
                          );
                        }}
                      >
                        {form.pasture != null &&
                        pastures.find((p) => p.id === form.pasture.id)
                          .arraySeasonOptions.length > 0
                          ? pastures
                              .find((p) => p.id === form.pasture.id)
                              .arraySeasonOptions.map((p) => (
                                <Select.Option key={p.season} value={p.season}>
                                  {p.season === "Water"
                                    ? "Chuva"
                                    : p.season === "Dry"
                                    ? "Seca"
                                    : p.season === "Transition"
                                    ? "Transição"
                                    : null}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Row>
                  </Col>
                  {/* Pasture Fertilized */}
                  <Col
                    xs={{ span: 5, offset: 1 }}
                    sm={{ span: 5, offset: 1 }}
                    md={{ span: 5, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                  >
                    <Row>
                      <label
                        className={
                          errors.includes("pastureFertilized") ? "error" : ""
                        }
                      >
                        Adubado*
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <Select
                        name="pastureFertilized"
                        style={{ width: "100%" }}
                        value={
                          form.pastureFertilized != null
                            ? form.pastureFertilized
                              ? "Yes"
                              : "No"
                            : undefined
                        }
                        placeholder={translation.defaultSelectPlaceholder}
                        disabled={
                          form?.productionSubSystem === "Intensive"
                            ? true
                            : false
                        }
                        onChange={(value) => {
                          handleChangePastureSeasonFertilizedSelected(value);
                          setErrors(
                            errors.filter((e) => e !== "pastureFertilized")
                          );
                        }}
                      >
                        {form.pastureSeason != null
                          ? pastures
                              .find((p) => p?.id === form?.pasture?.id)
                              ?.arraySeasonOptions.find(
                                (aso) => aso.season === form.pastureSeason
                              )
                              ?.optionsFertilized.map((oF) => (
                                <Select.Option key={oF} value={oF}>
                                  {oF === "Yes"
                                    ? translation.buttons.yes
                                    : translation.buttons.no}
                                </Select.Option>
                              ))
                          : null}
                      </Select>
                    </Row>
                  </Col>
                  {/* Pasto - Custo diário por animal */}
                  <Col
                    xs={{ span: 5, offset: 1 }}
                    sm={{ span: 5, offset: 1 }}
                    md={{ span: 5, offset: 1 }}
                    lg={{ span: 5, offset: 1 }}
                    xl={{ span: 5, offset: 1 }}
                  >
                    <Row>
                      <label
                        className={
                          errors.includes("pastureDailyCost") ? "error" : ""
                        }
                      >
                        Custo*
                        <Tooltip
                          placement="top"
                          title={`Caso este campo não for preenchido, será utilizado o valor
                        cadastrado em Pasto`}
                        >
                          <InfoIcon />
                        </Tooltip>
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <NumberFormat
                        customInput={Input}
                        value={
                          form?.pastureDailyCost !== null
                            ? form?.pastureDailyCost
                            : null
                        }
                        placeholder={translation.defaultPlaceholder}
                        name="pastureDailyCost"
                        addonBefore="R$"
                        addonAfter="kg"
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        disabled={
                          form?.productionSubSystem === "Intensive" ||
                          dietStrategyPeriodSelected?.pastureDailyCost !== null
                            ? true
                            : false
                        }
                        onValueChange={({ floatValue }) => {
                          setForm({
                            ...form,
                            pastureDailyCost:
                              floatValue >= 0 ? floatValue : null,
                          });
                          setErrors(
                            errors.filter((e) => e !== "pastureDailyCost")
                          );
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                {form?.pasture != null && (
                  <NutritionalBox>
                    <Row type="flex">
                      <Col span={16}>
                        <span className="title">DADOS NUTRICIONAIS</span>
                      </Col>
                      <Col span={8} align="right" className="colInfo">
                        <div className="groupInfo">
                          <label>NDT:</label>
                          <strong>{`${pastureTDN} g/Kg`}</strong>
                        </div>
                        <div className="groupInfo">
                          <label>PB:</label>
                          <strong>{`${pastureCP} g/Kg`}</strong>
                        </div>
                      </Col>
                    </Row>
                  </NutritionalBox>
                )}

                <CustomDivider>SUPLEMENTO</CustomDivider>

                <Row type="flex" justify="start" gutter={8}>
                  {/* Supplement */}
                  <Col span={12}>
                    <Row>
                      <label
                        className={errors.includes("supplement") ? "error" : ""}
                      >
                        Suplemento/Concent.*
                      </label>
                    </Row>
                    <Row type="flex" gutter={4} className="rowInput">
                      <Col span={16}>
                        <Select
                          name="supplement"
                          style={{ width: "100%" }}
                          value={
                            form.supplement != null
                              ? form.supplement.id
                              : undefined
                          }
                          placeholder={translation.defaultSelectPlaceholder}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          allowClear
                          onDeselect={() => {
                            setForm({
                              ...form,
                              supplement: null,
                              supplementVersion: null,
                              supplementConsumptionMeasureType: "G_ANIMAL",
                              supplementConsumptionByAnimal: null,
                              supplementDailyCost: null,
                            });
                          }}
                          onChange={(value) => {
                            if (value == null) {
                              setForm({
                                ...form,
                                supplement: null,
                                supplementVersion: null,
                                supplementConsumptionMeasureType: "G_ANIMAL",
                                supplementConsumptionByAnimal: null,
                                supplementDailyCost: null,
                              });
                            } else {
                              const sup = supplements.find(
                                (s) => s.id === value
                              );
                              setForm({
                                ...form,
                                supplement: sup,
                                supplementVersion: sup?.versions[0].version,
                                supplementConsumptionMeasureType:
                                  sup.voluntaryConsumptionType,
                                supplementDailyCost: sup.cost,
                                supplementConsumptionType:
                                  sup.type === "Supplement" ? "Pure" : "Mix",
                              });
                              setErrors(
                                errors.filter((e) => e !== "supplement")
                              );
                            }
                          }}
                        >
                          {supplements != null &&
                          Object.entries(supplements).length > 0
                            ? supplements.map((p) => (
                                <Select.Option key={p.id} value={p.id}>
                                  {p.name}
                                </Select.Option>
                              ))
                            : null}
                        </Select>
                      </Col>
                      <Col span={8}>
                        <Select
                          name="supplementVersion"
                          style={{ width: "100%" }}
                          value={
                            form.supplement != null
                              ? form.supplementVersion
                              : undefined
                          }
                          placeholder={translation.defaultSelectPlaceholder}
                          onChange={(value) => {
                            setForm({
                              ...form,
                              supplementVersion: value,
                            });
                          }}
                        >
                          {form.supplement != null
                            ? supplements
                                .find((s) => s.id === form.supplement.id)
                                .versions.map((v) => (
                                  <Select.Option key={v.id} value={v.version}>
                                    {`Versão ${v?.version}`}
                                  </Select.Option>
                                ))
                            : null}
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                  {/* SupplementConsumptionType */}
                  <Col span={6}>
                    <Row>
                      <label
                        className={
                          errors.includes("supplementConsumptionType")
                            ? "error"
                            : ""
                        }
                      >
                        Tipo de consumo*
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <Select
                        name="supplementConsumptionType"
                        value={
                          form.supplement?.type === "Supplement"
                            ? "Pure"
                            : "Mix"
                        }
                        disabled
                        style={{ width: "100%" }}
                        placeholder={translation.defaultSelectPlaceholder}
                      >
                        <Option key={"Pure"} value={"Pure"}>
                          Puro
                        </Option>
                        <Option key={"Mix"} value={"Mix"}>
                          Mistura
                        </Option>
                      </Select>
                    </Row>
                  </Col>
                  {/* SupplementDailyCost */}
                  <Col span={6}>
                    <Row>
                      <label
                        className={
                          errors.includes("supplementDailyCost") ? "error" : ""
                        }
                      >
                        Custo*
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <NumberFormat
                        customInput={Input}
                        value={
                          form?.supplementDailyCost !== null
                            ? form?.supplementDailyCost
                            : null
                        }
                        placeholder={translation.defaultPlaceholder}
                        addonBefore="R$"
                        addonAfter="kg"
                        name="supplementDailyCost"
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        disabled={
                          dietStrategyPeriodSelected?.supplementDailyCost !==
                          null
                        }
                        onValueChange={({ floatValue }) => {
                          setForm({
                            ...form,
                            supplementDailyCost:
                              floatValue >= 0 ? floatValue : null,
                          });
                          setErrors(
                            errors.filter((e) => e !== "supplementDailyCost")
                          );
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="start"
                  gutter={8}
                  style={{ marginTop: 10 }}
                >
                  {/* SupplementConsumptionByAnimal */}
                  <Col span={8}>
                    <Row>
                      <label
                        className={
                          errors.includes("supplementConsumptionByAnimal")
                            ? "error"
                            : ""
                        }
                      >
                        Consumo diário/Animal*
                      </label>
                    </Row>
                    <Row className="rowInput">
                      <NumberFormat
                        customInput={Input}
                        value={
                          form?.supplementConsumptionByAnimal !== null
                            ? form?.supplementConsumptionByAnimal
                            : null
                        }
                        addonAfter={selectSupplementConsumption}
                        placeholder={translation.defaultPlaceholder}
                        name="supplementConsumptionByAnimal"
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        onValueChange={({ floatValue }) => {
                          setForm({
                            ...form,
                            supplementConsumptionByAnimal: floatValue
                              ? floatValue > 10000
                                ? 10000
                                : floatValue
                              : 0,
                          });
                          setErrors(
                            errors.filter(
                              (e) => e !== "supplementConsumptionByAnimal"
                            )
                          );
                        }}
                      />
                    </Row>
                  </Col>
                  {/* Supplement Recommended consume values */}
                  <Col span={16}>
                    <NutritionalSupplementBox>
                      <Row>
                        <span className="title">CONSUMO DE SUPLEMENTO</span>
                      </Row>
                      <Row type="flex" justify="start" gutter={4}>
                        <Col span={10}>
                          <Row>
                            <span className="title">
                              VOLUNTÁRIO RECOMENDADO
                            </span>
                          </Row>
                          <Row type="flex">
                            <div className="groupInfo">
                              <label>Mínimo</label>
                              <strong>{`${numberMask(
                                supplementMinimumVoluntaryConsuption,
                                false
                              )} ${supplementVoluntaryConsuptionUnit}`}</strong>
                            </div>
                            <div className="groupInfo">
                              <label>Máximo</label>
                              <strong>{`${numberMask(
                                supplementMaximumVoluntaryConsuption,
                                false
                              )} ${supplementVoluntaryConsuptionUnit}`}</strong>
                            </div>
                          </Row>
                        </Col>
                        <Col span={10}>
                          <Row>
                            <span className="title">
                              {form?.supplement?.type === "Supplement"
                                ? "PURO"
                                : "MISTURA"}
                            </span>
                          </Row>
                          <Row type="flex">
                            <div className="groupInfo">
                              <label>Mínimo</label>
                              <strong>
                                {form?.supplement?.type === "Supplement" &&
                                supplementMinimumPureConsuption !== null
                                  ? `${numberMask(
                                      supplementMinimumPureConsuption,
                                      false
                                    )} ${supplementVoluntaryConsuptionUnit}`
                                  : form?.supplement?.type ===
                                      "Ration_Mixture" &&
                                    supplementMinimumMixConsuption !== null
                                  ? `${numberMask(
                                      supplementMinimumMixConsuption,
                                      false
                                    )} ${supplementVoluntaryConsuptionUnit}`
                                  : "-"}
                              </strong>
                            </div>
                            <div className="groupInfo">
                              <label>Máximo</label>
                              <strong>
                                {form?.supplement?.type === "Supplement" &&
                                supplementMaximumPureConsuption !== null
                                  ? `${numberMask(
                                      supplementMaximumPureConsuption,
                                      false
                                    )} ${supplementVoluntaryConsuptionUnit}`
                                  : form?.supplement?.type ===
                                      "Ration_Mixture" &&
                                    supplementMaximumMixConsuption !== null
                                  ? `${numberMask(
                                      supplementMaximumMixConsuption,
                                      false
                                    )} ${supplementVoluntaryConsuptionUnit}`
                                  : "-"}
                              </strong>
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </NutritionalSupplementBox>
                  </Col>
                </Row>
                {/* Supplement Nutrional info and trough info */}
                <Row type="flex">
                  <Col span={6}>
                    <NutritionalSupplementBox>
                      <Row>
                        <span className="title">DADOS NUTRICIONAIS</span>
                      </Row>
                      <Row type="flex">
                        <div className="groupInfo">
                          <label>NDT</label>
                          <strong>{`${numberMask(
                            supplementTDN || 0
                          )} g/Kg`}</strong>
                        </div>
                        <div className="groupInfo">
                          <label>PBs</label>
                          <strong>{`${numberMask(
                            supplementCP || 0
                          )} g/Kg`}</strong>
                        </div>
                      </Row>
                      <Row type="flex">
                        <div className="groupInfo">
                          <label>%MS</label>
                          <strong>{`${numberMask(
                            supplementDryMatterPercentage || 0
                          )} %`}</strong>
                        </div>
                        <div className="groupInfo">
                          <label>Ca</label>
                          <strong>{`${numberMask(
                            supplementCalcium || 0
                          )} g/Kg`}</strong>
                        </div>
                      </Row>
                      <Row type="flex">
                        <div className="groupInfo">
                          <label>P</label>
                          <strong>{`${numberMask(
                            supplementPhosphorus || 0
                          )} g/Kg`}</strong>
                        </div>
                        <div className="groupInfo">
                          <label>Zn</label>
                          <strong>{`${numberMask(
                            supplementZinc || 0
                          )} mg/Kg`}</strong>
                        </div>
                      </Row>
                      <Row type="flex">
                        <div className="groupInfo">
                          <label>Na</label>
                          <strong>{`${numberMask(
                            supplementSodium || 0
                          )} g/Kg`}</strong>
                        </div>
                      </Row>
                    </NutritionalSupplementBox>
                  </Col>
                  <Col span={17} offset={1}>
                    <NutritionalGdpValidationBox>
                      <Row type="flex" justify="start" gutter={4}>
                        {form?.supplement != null && (
                          <Col span={12}>
                            <Row>
                              <span className="title">
                                ESPAÇO DE COCHO RECOMENDADO
                                <InfoTooltip
                                  placement="top"
                                  trigger="hover"
                                  title="No caso de erro, a dieta pode ser salva, mas um alerta correlato pode ser gerado, no futuro, se a Dieta não entregar o GDP esperado."
                                />
                              </span>
                            </Row>
                            <Row type="flex">
                              <div className="groupInfo">
                                <label>Recom.(min/max)</label>
                                <strong>{`${
                                  form.supplement.minimumPureTrough != null
                                    ? supplementMinPureTrought
                                    : supplementMinMixTrought
                                }/${
                                  form.supplement.maximumPureTrough != null
                                    ? supplementMaxPureTrought
                                    : supplementMaxMixTrought
                                } cm/cab`}</strong>
                              </div>
                            </Row>
                          </Col>
                        )}
                        <Col span={form?.supplement != null ? 12 : 24}>
                          <Row type="flex">
                            <span className="title">
                              Resumo do Quadro Cálculo da Dieta
                            </span>
                          </Row>
                          {/* Gdp Expected and Possible */}
                          <Row type="flex" justify="start" gutter={2}>
                            <Col span={16}>
                              <label>
                                GDP Esperado:
                                <strong>{` ${getTwoDecimalDigits(
                                  form?.gdpExpectation || 0
                                )} kg `}</strong>
                              </label>
                            </Col>
                            <Col span={8}>
                              <label>
                                Possível:
                                <strong>{` ${getTwoDecimalDigits(
                                  form?.gdpPossible || 0
                                )} kg`}</strong>
                              </label>
                            </Col>
                          </Row>
                          {/* Pasture and CP */}
                          <Row type="flex" justify="start" gutter={2}>
                            <Col span={16}>
                              {form?.pasture != null && (
                                <label>
                                  {`Pasto(kg/d): Cons.: `}
                                  <strong>{`${getTwoDecimalDigits(
                                    form?.pastureConsumed || 0
                                  )}`}</strong>
                                  {` Nec.: `}
                                  <strong>
                                    {`${getTwoDecimalDigits(
                                      form?.pastureNeeded || 0
                                    )}`}
                                  </strong>
                                </label>
                              )}
                            </Col>
                            <Col span={8}>
                              <label>
                                PB
                                <strong>
                                  {form?.cpExceededOrMissing === "Missing" ? (
                                    <TiArrowDownThick
                                      id="iconExOrMiss"
                                      color="#d44c4c"
                                    />
                                  ) : form?.cpExceededOrMissing ===
                                    "Exceeded" ? (
                                    <TiArrowUpThick
                                      id="iconExOrMiss"
                                      color="#27ff00"
                                    />
                                  ) : null}
                                  {` ${getTwoDecimalDigits(
                                    form?.cpExceededOrMissingValue || 0
                                  )} g`}
                                </strong>
                              </label>
                            </Col>
                          </Row>
                          {/* Trough and TDN */}
                          <Row type="flex" justify="start" gutter={2}>
                            <Col span={16}>
                              {form?.supplement != null && (
                                <label>
                                  {`Conc.(kg/d): Cons.: `}
                                  <strong>{`${getTwoDecimalDigits(
                                    form?.supplementConsumed || 0
                                  )}`}</strong>
                                  {` Nec.: `}
                                  <strong>
                                    {`${getTwoDecimalDigits(
                                      form?.supplementNeeded || 0
                                    )}`}
                                  </strong>
                                </label>
                              )}
                            </Col>
                            <Col span={8}>
                              <label>
                                NDT
                                <strong>
                                  {form?.tdnExceededOrMissing === "Missing" ? (
                                    <TiArrowDownThick
                                      id="iconExOrMiss"
                                      color="#d44c4c"
                                    />
                                  ) : form?.tdnExceededOrMissing ===
                                    "Exceeded" ? (
                                    <TiArrowUpThick
                                      id="iconExOrMiss"
                                      color="#27ff00"
                                    />
                                  ) : null}
                                  {` ${getTwoDecimalDigits(
                                    form?.tdnExceededOrMissingValue || 0
                                  )} g`}
                                </strong>
                              </label>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </NutritionalGdpValidationBox>
                  </Col>
                </Row>
              </Spin>
            </Body>
            <Footer>
              <Row type="flex" justify="space-between">
                <Col span={6} align="left">
                  <div className="buttonContainer">
                    <ButtonNavigation
                      onClick={goPrevius}
                      disabled={index === 0 || loadinDiets ? true : false}
                    >
                      <Icon type="left-circle" />
                    </ButtonNavigation>
                    <ButtonNavigation
                      disabled={
                        loadinDiets
                          ? true
                          : dietStrategyData.dietPeriods.length > 1 &&
                            index < dietStrategyData.dietPeriods.length - 1
                          ? false
                          : true
                      }
                      onClick={goNext}
                    >
                      <Icon type="right-circle" />
                    </ButtonNavigation>
                    {dietStrategyData.dietPeriods.length > 0 &&
                      dietStrategyData.dietPeriods.find(
                        (dp, i) => i === index
                      ) && (
                        <ButtonNavigation
                          disabled={
                            loadinDiets
                              ? true
                              : dietStrategyData.dietPeriods.length <= 1
                              ? false
                              : index >= dietStrategyData.dietPeriods.length - 1
                              ? false
                              : true
                          }
                          onClick={remove}
                          className="remove"
                        >
                          <Icon type="delete" />
                          Deletar
                        </ButtonNavigation>
                      )}
                  </div>
                </Col>
                <Col span={12} align="center">
                  <span></span>
                </Col>
                <Col span={6} align="right">
                  <ButtonNavigation
                    disabled={loadinDiets ? true : false}
                    onClick={saveAndFinalize}
                    className="save"
                  >
                    SALVAR
                  </ButtonNavigation>
                </Col>
              </Row>
            </Footer>
          </Container>
        </>
      ) : (
        <Empty />
      )}
      {/* Modal Gdp Validation */}
      <GdpModalValidation
        form={form}
        gdpValidationReturn={gdpValidationReturn}
        modalGdpValidationVisible={modalGdpValidationVisible}
        ignoreGdpAlertAndSave={ignoreGdpAlertAndSave}
        closeModalGdpValidation={closeModalGdpValidation}
      />
    </Modal>
  );
};

export default PeriodModel;
