import apiFarms from "../config/api_farms";
import getHeaders from "./getHeaders";

export function findAllFarmOwners(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/owners`,
    getHeaders(false, signal)
  );
}