import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CardCustom, Title, Footer } from "../../../styles";
import { Spin, Col, Input, Row, Radio, notification, Table, Icon } from "antd";

import NumberFormat from "react-number-format";

import { Creators as ResultCenterActions } from "../../../../../store/ducks/resultCenter";

import ButtonStandard from "../../../../../components/utils/button";

import { findApportionmentValuesById, findById, updateApportionments } from "../../../../../services/resultCenterService";

const FinancialNatureApportionmentForm = () => {

  const initialStateForm = {
    resultCenterType: "custom",
    apportionmentType: "percentage",
    natures: [
      {
        financialNatureId: null,
        financialNatureName: null,
        apportionmentType: null,
        values: [
          {
            resultCenterItemId: null,
            resultCenterItemName: null,
            resultCenterItemCode: null,
            resultCenterItemValue: null,
          }
        ]
      }
    ],
  };

  const {
    resultCenter: { resultCenterId, isResultCenterFormVisible, isFinancialNatureApportionmentFormVisible, isLoadingApportionmentForm, isApportionmentFormExpanded },
    app: {
      translation,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },

  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const { Group: RadioGroup } = Radio;
  const { Column } = Table;

  const [form, setForm] = useState(initialStateForm);
  const [columns, setColumns] = useState(null);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (isFinancialNatureApportionmentFormVisible) {
      setForm(initialStateForm);
    }
  }, [isFinancialNatureApportionmentFormVisible]);

  // Fetch Data
  useEffect(() => {
    async function fetchData() {
      try {
        dispatch(ResultCenterActions.setIsLoadingApportionmentForm(true));
        const { data: { results } } = await findApportionmentValuesById({ groupId, farmId, id: resultCenterId });

        const { data: { results: resultCenterData } } = await findById({ groupId, farmId, id: resultCenterId });

        const columnsValue = resultCenterData.items.map(i => (i));

        const formValue = {
          resultCenterType: results?.resultCenterType,
          apportionmentType: results?.apportionmentType,
          natures: results?.natures,
        }

        setColumns(columnsValue);
        setForm(formValue);
      } catch (error) {
        console.error(error)
        notification.error({
          title: "Erro ao carregar",
          message: "Não foi possível carregar rateio do centro de resultado. Contate o suporte"
        });
      } finally {
        dispatch(ResultCenterActions.setIsLoadingApportionmentForm(false));
      }
    }

    if (resultCenterId !== null) {
      fetchData();
    }
  }, [groupId, farmId, resultCenterId]);

  const closeForm = () => {
    if (isApportionmentFormExpanded) {
      handleExpand();
    }
    dispatch(ResultCenterActions.showOrHideFinancialNatureApportionmentForm(resultCenterId));
  }

  const handleSubmit = async () => {
    if (validateForm()) {
      const body = { ...form };

      try {
        dispatch(ResultCenterActions.setIsLoadingApportionmentForm(true));

        await updateApportionments({ groupId, farmId, id: resultCenterId, body });
        notification.success({ message: "Rateio atualizado com sucesso" });

        if (isApportionmentFormExpanded) {
          handleExpand();
        }
        dispatch(ResultCenterActions.showOrHideFinancialNatureApportionmentForm(null));
        if (isResultCenterFormVisible) {
          dispatch(ResultCenterActions.showOrHideForm(null));
        }
        dispatch(ResultCenterActions.index(groupId, farmId));
      } catch (error) {
        notification.error({
          title: "Erro ao salvar",
          message: "Não foi possível atualizar o rateio. Contate o suporte"
        });
      } finally {
        dispatch(ResultCenterActions.setIsLoadingApportionmentForm(false));
      }
    }
  };

  function validateForm() {
    let aErrors = [];

    form.natures.forEach(financialNature => {
      if (financialNature.apportionmentType === null || financialNature.apportionmentType.trim() === "") {
        aErrors.push(financialNature.financialNatureName);
      }

      if (financialNature.apportionmentType === "percentage") {
        const result = financialNature.values.reduce((a, b) => a + (b.resultCenterItemValue || 0), 0) || 0;

        if (result > 0 && result !== 100) {
          notification.error({
            title: "Erro ao validar",
            message: "Valores devem fechar 100%"
          })
          aErrors.push(financialNature.financialNatureName);
        }
      }
    });

    setErrors(aErrors)
    return aErrors.length > 0 ? false : true;
  }

  function handleExpand() {
    dispatch(ResultCenterActions.expandOrCompactApportionmentForm());
  }

  const handleApplyNatureApportionmentTypeToAllItems = (value) => {
    const currentFormNatures = [...form.natures];

    if (currentFormNatures !== null && currentFormNatures !== undefined) {
      currentFormNatures.forEach(natureValue => natureValue.apportionmentType = value);
    }
    setForm({ ...form, natures: currentFormNatures });
  }

  const handleNatureApportionmentTypeChange = (value, natureId) => {
    const currentFormNatures = [...form.natures];
    const currentFinancialNatureValue = currentFormNatures?.find(n => n.financialNatureId === natureId);

    if (currentFinancialNatureValue !== null && currentFinancialNatureValue !== undefined) {
      currentFinancialNatureValue.apportionmentType = value;
    }
    setForm({ ...form, natures: currentFormNatures });
  }

  const handleResultCenterItemValueChange = (value, natureId, resultCenterItemId) => {
    const currentFormNatures = [...form.natures];
    const currentFormNatureValues = currentFormNatures?.find(n => n.financialNatureId === natureId);

    if (currentFormNatureValues !== null && currentFormNatureValues !== undefined) {
      currentFormNatureValues.values.find(rci => rci.resultCenterItemId === resultCenterItemId).resultCenterItemValue = value;
    }
    setForm({ ...form, natures: currentFormNatures });
  }

  const handleApportionmentTypeChange = (value) => {
    setForm({
      ...form,
      apportionmentType: value,
    });
  }

  return (
    <CardCustom className="grow-card">
      <Spin spinning={isLoadingApportionmentForm}>
        <Row type="flex" justify="space-between" align="middle" className="rowHeader">
          <Title>{translation.financial.parameters.resultCenter.financialNatureApportionmentForm.title}</Title>
          <Icon
            type={isApportionmentFormExpanded ? "fullscreen-exit" : "fullscreen"}
            onClick={() => handleExpand()}
          />
        </Row>

        <Table
          rowKey="financialNatureId"
          dataSource={form?.natures !== null ? form.natures : []}
          pagination={false}
          size="small"
          scroll={{ x: true, y: true }}
        >

          <Column
            title="Natureza"
            dataIndex="financialNatureName"
            key="financialNatureName"
            width={200}
            align="left"
            render={(name) => (<label className={errors.includes(name) ? "error" : ""}>{name}</label>)}
          />

          <Column
            title="Rateio"
            dataIndex="apportionmentType"
            key="apportionmentType"
            width={120}
            align="left"
            render={(type, record) => {
              return (<RadioGroup

                disabled={form.resultCenterType === "custom"}
                name="apportionmentType"
                value={type}
                className="radio-label"
                onChange={(e) => {
                  handleNatureApportionmentTypeChange(e.target.value, record.financialNatureId)
                }}
              >
                <Radio className="vertical-radio" value="numberOfAnimals"># Animais</Radio>
                <Radio className="vertical-radio" value="area">Área</Radio>
                <Radio className="vertical-radio" value="percentage">%</Radio>
              </RadioGroup>)
            }}
          />

          {columns !== null ? columns.map(column => {
            return (
              <Column
                title={column.name}
                dataIndex='financialNatureId'
                key={column.id}
                width={150}
                render={(natureId, record) => {
                  const resultCenterItem = record.values.find(rci => rci.resultCenterItemId === column.id)
                  return record.apportionmentType === "percentage" && resultCenterItem ? (
                    <NumberFormat
                      name="resultCenterItemValue"
                      customInput={Input}
                      value={resultCenterItem?.resultCenterItemValue}
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      maxLength={record.apportionmentType === "percentage" ? 6 : 10}
                      fixedDecimalScale={true}
                      addonAfter={record.apportionmentType === "percentage" ? "%" : null}
                      placeholder={translation.defaultPlaceholder}
                      onValueChange={
                        ({ floatValue }) => {
                          if (floatValue > 100) {
                            handleResultCenterItemValueChange(100, natureId, resultCenterItem.resultCenterItemId)
                          } else {
                            handleResultCenterItemValueChange(floatValue, natureId, resultCenterItem.resultCenterItemId)
                          }
                        }}
                    />
                  ) : <span>{record.apportionmentType === "percentage" ? resultCenterItem?.resultCenterItemValue : "Automático"}</span>
                }}
              />
            );
          }) : null}
        </Table>

        {/* Footer */}
        <Footer>
          <Row type="flex">
            <Col span={24} className="buttonsDiv">
              <ButtonStandard
                type="button"
                buttonType="type7"
                onClick={closeForm}
              >
                {translation.buttons.cancel}
              </ButtonStandard>

              <ButtonStandard
                type="button"
                buttonType="type6"
                onClick={handleSubmit}
              >
                {translation.buttons.save}
              </ButtonStandard>
            </Col>
          </Row>
        </Footer>
      </Spin>
    </CardCustom >
  );
}

export default FinancialNatureApportionmentForm;
