import styled from "styled-components";

export const Container = styled.div`
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
`;

export const Title = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: #4b4b4b;
  margin-bottom: 10px;
  margin-right: 8px;
`;
