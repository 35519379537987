import { Formik } from "formik";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { INITIAL_DATA_FORM_ANIMAL_DG } from "../../../contexts/animalBirthContext";
import useAnimalBirthContext from "../../../hooks/useAnimalBirthContext";

import {
  Button,
  Col,
  DatePicker,
  Icon,
  Input,
  InputNumber,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Spin,
  Tabs,
  Tooltip,
} from "antd";
import ButtonStandard from "../../../components/utils/button";
import ArrowDownIcon from "../../../components/utils/table/icons/arrows/down";
import ArrowUpIcon from "../../../components/utils/table/icons/arrows/up";
import {
  ButtonSelectCoverage,
  Container,
  CustomCheckBox,
  TableSelectCoverage,
} from "./styles";

import AlertIcon from "../../../components/drawers/alert/icons/alert";
import LogoBullGreen from "../../../components/login/logos/logoBullGreen";
import SelectAnimalsModal from "../../../components/modals/selectAnimalsModal";
import CustomCardDetails from "../../../components/utils/customCardDetails";
import AnimalBirthChildForm from "./childForm";

// Services

import { saveAnimalBirth } from "../../../services/animalBirthService";
import { getAnimalsByIdentificationHandlingNumber } from "../../../services/animalService";
import { getBreedIndexActive } from "../../../services/breedService";
import { findAllFarmOwners } from "../../../services/farmOwnerService";
import { getLotIndexDropDown } from "../../../services/lotService";
import { findAllReproductionParemeterFarmByGroupIdAndFarmId } from "../../../services/reproductionParameterFarmService";
import { handleKeyDownInputNumber } from "../../../utils/keyDownFunctions";
import { validateForm, validateSelectFemale } from "./validations";

const MAX_CHILD_PER_BIRTH = 3;
const validationSchema = Yup.object().shape({
  matrizAnimalId: Yup.string().required(),
  type: Yup.string().required(),
  birthDate: Yup.string().required(),
  gestationDays: Yup.number()
    .nullable()
    .notRequired()
    .when(["type", "matrizSituation"], {
      is: (type, matrizSituation) =>
        (matrizSituation === "vazia" || matrizSituation === null) &&
        type != null,
      then: Yup.number().required(),
      otherwise: Yup.number().nullable().notRequired(),
    }),
  discardMotive: Yup.string()
    .nullable()
    .notRequired()
    .when(["shouldDiscardFemale"], {
      is: (shouldDiscardFemale) => shouldDiscardFemale === true,
      then: Yup.string().required(),
      otherwise: Yup.string().nullable().notRequired(),
    }),
  childs: Yup.array().when(["type"], {
    is: (type) => type !== "parto",
    then: Yup.array().notRequired(),
    otherwise: Yup.array()
      .of(
        Yup.object().shape({
          handlingNumber: Yup.string().required(),
          sisbov: Yup.string().length(15).nullable().notRequired(),
          tagId: Yup.string().length(15).nullable().notRequired(),
          breedId: Yup.string().required(),
          lotId: Yup.string().required(),
          gender: Yup.string().required(),
          animalFarmFunction: Yup.string().required(),
          farmOwnerId: Yup.string().required(),
        })
      )
      .min(1)
      .max(MAX_CHILD_PER_BIRTH)
      .required(),
  }),
});

const listDiscardMotives = [
  {
    id: 0,
    value: "Age",
    label: "Idade",
  },
  {
    id: 1,
    value: "ReproductiveProblems",
    label: "Problemas Reprodutivos",
  },
  {
    id: 2,
    value: "IllnessesOrPhysicalProblems",
    label: "Doenças ou Problemas Físicos",
  },
  {
    id: 3,
    value: "BodyCondition",
    label: "Condição corporal",
  },
  {
    id: 4,
    value: "NegativePregnancy",
    label: "Prenhez Negativa",
  },
  {
    id: 5,
    value: "MaternalAbility",
    label: "Habilidade Materna",
  },
];

const GESTATION_DAYS_MORTE_EMBRIONARIA = 60;
const GESTATION_DAYS_ABORTO = 180;

const AnimalBirthForm = () => {
  const formikRef = useRef(null);
  const firstInputRef = useRef(null);
  const [form, setForm] = useState(INITIAL_DATA_FORM_ANIMAL_DG);
  const [shouldCollapse, setShouldCollapse] = useState(false);
  const [selectedFemale, setSelectedFemale] = useState(null);
  const [countChild, setCountChild] = useState(1);
  const [activeTab, setActiveTab] = useState("0");
  const [breeds, setBreeds] = useState([]);
  const [lots, setLots] = useState([]);
  const [farmOwners, setFarmOwners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleModalSelectAnimal, setIsVisibleModalSelectAnimal] =
    useState(false);
  const [animals, setAnimals] = useState([]);
  const [birthDateControll, setBirthDateControll] = useState(moment());
  const [reproductionParameters, setReproductionParameters] = useState([]);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [isModalDefineCoverageOpen, setIsModalDefineCoverageOpen] =
    useState(false);
  const [coverageSelectedId, setCoverageSelectedId] = useState(null);
  const [coverageSelectedGestationDays, setCoverageSelectedGestationDays] =
    useState(null);
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const {
    fetchData,
    tableProperties,
    showCardForm,
    openOrCloseForm,
    data,
    initializeData,
  } = useAnimalBirthContext();

  const infoRepasseCoverage = useMemo(() => {
    if (
      selectedFemale &&
      selectedFemale?.lastCoverages &&
      selectedFemale?.lastCoverages.length > 1
    ) {
      return selectedFemale?.lastCoverages.find(
        (coverage) => coverage.type === "MC/REPASSE"
      );
    } else {
      return null;
    }
  }, [selectedFemale]);

  const infoIaCoverage = useMemo(() => {
    if (
      selectedFemale &&
      selectedFemale?.lastCoverages &&
      selectedFemale?.lastCoverages.length > 1
    ) {
      return selectedFemale?.lastCoverages.find(
        (coverage) => coverage.type === "IA/IATF"
      );
    } else {
      return null;
    }
  }, [selectedFemale]);

  const medGestationDaysBreed = useMemo(() => {
    if (selectedFemale && selectedFemale?.breedId) {
      const animalBreed = breeds.find((b) => b.id === selectedFemale?.breedId);
      if (animalBreed) {
        return animalBreed.medGestationTime ?? 315;
      } else {
        return 315;
      }
    } else {
      return 315;
    }
  }, [breeds, selectedFemale]);

  const minGestationDaysBreed = useMemo(() => {
    if (selectedFemale && selectedFemale?.breedId) {
      const animalBreed = breeds.find((b) => b.id === selectedFemale?.breedId);
      if (animalBreed) {
        return animalBreed.minGestationTime ?? 315;
      } else {
        return 315;
      }
    } else {
      return 315;
    }
  }, [breeds, selectedFemale]);

  const maxGestationDaysBreed = useMemo(() => {
    if (selectedFemale && selectedFemale?.breedId) {
      const animalBreed = breeds.find((b) => b.id === selectedFemale?.breedId);
      if (animalBreed) {
        return animalBreed.maxGestationTime ?? 315;
      } else {
        return 315;
      }
    } else {
      return 315;
    }
  }, [breeds, selectedFemale]);

  const maxGestationDays = useMemo(() => {
    if (birthDateControll && selectedFemale) {
      return (
        moment(birthDateControll).diff(
          moment(selectedFemale.lastCoverageDate),
          "days"
        ) + 1
      );
    } else {
      return 315;
    }
  }, [selectedFemale, birthDateControll]);

  const coverageNotSelected = useMemo(() => {
    if (
      selectedFemale?.lastCoverages.length > 1 &&
      coverageSelectedId != null
    ) {
      const coverage = selectedFemale.lastCoverages.filter(
        (coverage) => coverage.id !== coverageSelectedId
      )[0];
      const daysBetweeLastCoverageAndDiagnosisDate = moment(
        birthDateControll
      ).diff(moment(coverage.coverageDate), "days");
      const gestationDays = daysBetweeLastCoverageAndDiagnosisDate + 1;
      const newBirthForecast = moment(coverage.coverageDate).add(
        medGestationDaysBreed,
        "days"
      );
      return {
        coverageDate: coverage.coverageDate,
        coverageType: coverage.type,
        gestationDays: gestationDays,
        birthForecast: newBirthForecast,
        reproductorName: coverage.semenOrMaleIdentification,
        lastCoverageDiagnosisDate: coverage.pregnancyDiagnosisDate,
        lastCoverageDiagnosisResult: coverage.pregnancyDiagnosisResult,
      };
    } else {
      return null;
    }
  }, [
    selectedFemale,
    coverageSelectedId,
    birthDateControll,
    medGestationDaysBreed,
  ]);

  function handleSubmitForm(values, actions) {
    try {
      validateForm(
        values,
        selectedFemale,
        minGestationDaysBreed,
        maxGestationDaysBreed
      );
      handleSave(values);
    } catch (error) {
      console.error(error);
    }
  }

  const handleSave = async (values) => {
    setIsLoadingRequest(true);
    try {
      let body = { ...values };
      if (body.type !== "parto") {
        body.childs = [];
      }
      await saveAnimalBirth({
        groupId,
        farmId,
        body,
      });
      notification.success({
        message: "Parto salvo com sucesso!",
      });
      fetchData(
        tableProperties.page,
        tableProperties.size,
        tableProperties.tableSorters,
        tableProperties.search
      );
      initializeData({
        ...INITIAL_DATA_FORM_ANIMAL_DG,
        birthDate: values.birthDate,
        shouldDiscardFemale: values.shouldDiscardFemale,
        discardMotive: values.discardMotive,
        lotDestinationId: values.lotDestinationId,
      });
      formikRef.current.resetForm({
        ...INITIAL_DATA_FORM_ANIMAL_DG,
        birthDate: values.birthDate,
        shouldDiscardFemale: values.shouldDiscardFemale,
        discardMotive: values.discardMotive,
        lotDestinationId: values.lotDestinationId,
      });
      setBirthDateControll(values.birthDate);
      setSelectedFemale(null);
      setActiveTab("0");
      setCountChild(1);
    } catch (error) {
      if (Object.keys(error).includes("response")) {
        const { response } = error;
        if (Object.keys(response).includes("data")) {
          const {
            data: { code: errorCode },
          } = response;
          if (errorCode === 5030) {
            notification.error({
              message: "Erro",
              description: `Atenção! Este parto não pode ser salvo, existem conflitos de datas entre os eventos reprodutivos.`,
            });
          } else {
            notification.error({
              message: "Erro ao salvar Parto, contate o suporte.",
            });
          }
        }
      } else {
        notification.error({
          message: "Erro ao salvar Parto, contate o suporte.",
        });
      }
    } finally {
      setIsLoadingRequest(false);
    }
  };

  const handleCancel = async () => {
    formikRef.current.resetForm({});
    initializeData(null);
    openOrCloseForm();
  };

  const changeTab = useCallback((key) => {
    setActiveTab(key);
  }, []);

  const calculateNewLastCoverageDate = useCallback((dgDate, days) => {
    return moment(dgDate).subtract(days, "days");
  }, []);

  const calculateBirthForecast = useCallback(
    (female, lastCoverage) => {
      const selectedFemaleBreed = breeds.find((b) => b.id === female?.breedId);
      const newBirthForecast = moment(lastCoverage).add(
        selectedFemaleBreed?.medGestationTime,
        "days"
      );
      return newBirthForecast;
    },
    [breeds]
  );

  const calculateGestationDays = useCallback((dgDate, compareDate) => {
    const daysBetweeLastCoverageAndDiagnosisDate = moment(dgDate).diff(
      moment(compareDate),
      "days"
    );
    const gestationDays = daysBetweeLastCoverageAndDiagnosisDate + 1;
    return gestationDays;
  }, []);

  const handleDeselectFemale = useCallback((setFieldValue) => {
    setFieldValue("matrizAnimalId", null);
    setFieldValue("matrizAnimalHandlingNumber", null);
    setSelectedFemale(null);
    setCountChild(1);
    setFieldValue("childs", [
      {
        tagId: null,
        sisbov: null,
        handlingNumber: null,
        nickname: null,
        gender: null,
        breedId: null,
        farmOwnerId: null,
        animalReproductionCategory: null,
        femaleSituation: null,
        animalFarmFunction: null,
        birthdayWeight: null,
        lotId: null,
        stillborn: false,
      },
    ]);
  }, []);

  const handleAfterSelectFemale = useCallback(
    (formikValue, female, setFieldValue) => {
      const animalBreed = breeds.find((b) => b.id === female?.breedId);
      setFieldValue("animalCoverageId", female.lastCoverageId);

      if (
        female.lastCoverageId != null &&
        female.femaleSituation !== null &&
        female.femaleSituation !== "vazia"
      ) {
        setCoverageSelectedId(female.lastCoverageId);
        setFieldValue("coverageDate", female.lastCoverageDate);
        const gestationTime = moment(formikValue.birthDate).diff(
          moment(female.lastCoverageDate),
          "days"
        );
        setFieldValue("gestationDays", gestationTime + 1);
        const birthForecast = moment(female.lastCoverageDate).add(
          animalBreed.medGestationTime,
          "days"
        );
        setFieldValue("birthForecast", birthForecast);
        setFieldValue("coverageType", female.lastCoverageType);
        setFieldValue("reproductorId", female.lastCoverageReproductorId);
        setFieldValue("reproductorName", female.lastCoverageReproductor);
        setFieldValue(
          "lastCoverageDiagnosisDate",
          female.lastCoverageDiagnosisDate
        );
        setFieldValue(
          "lastCoverageDiagnosisResult",
          female.lastCoverageDiagnosisResult === 1
            ? "Positivo"
            : female.lastCoverageDiagnosisResult === 2
            ? "Negativo"
            : null
        );
        setFieldValue("matrizSituation", female.femaleSituation);
      } else {
        setFieldValue("gestationDays", animalBreed.medGestationTime);
        setFieldValue("matrizSituation", "vazia");
      }

      setFieldValue("shouldDiscardFemale", false);
      setFieldValue("discardMotive", null);
      setFieldValue(
        "childs",
        formikValue.childs.map((child) => ({
          ...child,
          breedId: female.breedId,
          farmOwnerId: female.farmOwnerId,
          lotId: female.lotId,
        }))
      );
    },
    [breeds]
  );

  const handleErrorWhenSelectFemale = useCallback(
    (error, formikValue, female, setFieldValue) => {
      switch (error) {
        case "DG-ERROR-MALE":
          Modal.error({
            width: 700,
            centered: true,
            content: <h1>Esta operação não pode ser feitas em Machos!</h1>,
            onOk: () => {
              handleDeselectFemale(setFieldValue);
            },
          });
          break;
        case "ANIMAL-WITHOUT-AGE-FOR-REPRODUCTION":
          Modal.error({
            width: 700,
            centered: true,
            content: (
              <h1>Fêmea não possui idade para início da fase reprodutiva.</h1>
            ),
            onOk: () => {
              handleDeselectFemale(setFieldValue);
            },
          });
          break;
        case "DG-CHECK-FEMALE-FATTEN":
          Modal.confirm({
            width: 700,
            centered: true,
            icon: (
              <Icon
                type="exclamation-circle"
                style={{
                  color: "#faad14",
                  fontSize: 36,
                }}
              />
            ),
            content: <h1>Fêmea de finalidade Engorda, deseja continuar?</h1>,
            okText: "Sim",
            cancelText: "Não",
            onOk: () => {
              // Check if will change female function
              Modal.confirm({
                width: 700,
                centered: true,
                icon: (
                  <Icon
                    type="exclamation-circle"
                    style={{
                      color: "#faad14",
                      fontSize: 36,
                    }}
                  />
                ),
                content: <h1>Deseja alterar a fêmea para Reprodução?</h1>,
                okText: "Sim",
                cancelText: "Não",
                onOk: () => {
                  setFieldValue(
                    "shouldChangeFemaleCategoryForReproduction",
                    true
                  );
                  // Check if female is "VAZIA"
                  if (
                    female?.femaleSituation === "vazia" ||
                    female?.femaleSituation === null
                  ) {
                    Modal.confirm({
                      width: 700,
                      centered: true,
                      icon: (
                        <Icon
                          type="exclamation-circle"
                          style={{
                            color: "#faad14",
                            fontSize: 36,
                          }}
                        />
                      ),
                      content: <h1>Fêmea vazia. Deseja fazer o Parto?</h1>,
                      okText: "Sim",
                      cancelText: "Não",
                      onOk: () => {
                        handleAfterSelectFemale(
                          formikValue,
                          female,
                          setFieldValue
                        );
                      },
                      onCancel: () => {
                        handleDeselectFemale(setFieldValue);
                      },
                    });
                  } else {
                    handleAfterSelectFemale(formikValue, female, setFieldValue);
                  }
                },
                onCancel: () => {
                  setFieldValue(
                    "shouldChangeFemaleCategoryForReproduction",
                    false
                  );
                  // Check if female is "VAZIA"
                  if (
                    female?.femaleSituation === "vazia" ||
                    female?.femaleSituation === null
                  ) {
                    Modal.confirm({
                      width: 700,
                      centered: true,
                      icon: (
                        <Icon
                          type="exclamation-circle"
                          style={{
                            color: "#faad14",
                            fontSize: 36,
                          }}
                        />
                      ),
                      content: <h1>Fêmea vazia. Deseja fazer o Parto?</h1>,
                      okText: "Sim",
                      cancelText: "Não",
                      onOk: () => {
                        handleAfterSelectFemale(
                          formikValue,
                          female,
                          setFieldValue
                        );
                      },
                      onCancel: () => {
                        handleDeselectFemale(setFieldValue);
                      },
                    });
                  } else {
                    setFieldValue(
                      "shouldChangeFemaleCategoryForReproduction",
                      false
                    );
                    handleAfterSelectFemale(formikValue, female, setFieldValue);
                  }
                },
              });
            },
            onCancel: () => {
              handleDeselectFemale(setFieldValue);
            },
          });
          break;
        case "DG-CHECK-FEMALE-VAZIA":
          Modal.confirm({
            width: 700,
            centered: true,
            icon: (
              <Icon
                type="exclamation-circle"
                style={{
                  color: "#faad14",
                  fontSize: 36,
                }}
              />
            ),
            content: <h1>Fêmea vazia. Deseja fazer o Parto?</h1>,
            okText: "Sim",
            cancelText: "Não",
            onOk: () => {
              setFieldValue("shouldChangeFemaleCategoryForReproduction", false);
              handleAfterSelectFemale(formikValue, female, setFieldValue);
            },
            onCancel: () => {
              handleDeselectFemale(setFieldValue);
            },
          });
          break;
        default:
          setFieldValue("shouldChangeFemaleCategoryForReproduction", false);
          handleAfterSelectFemale(formikValue, female, setFieldValue);
          break;
      }
    },
    [handleAfterSelectFemale, handleDeselectFemale]
  );

  const handleConfirmSelectionAnimal = useCallback(
    async (keys) => {
      const formikValue = formikRef.current.state.values;
      const setFieldValue = formikRef.current.setFieldValue;
      const animalIndex = animals.findIndex((animal) => animal.id === keys[0]);
      const animal = animals[animalIndex];
      if (animal) {
        setSelectedFemale(animal);
        setFieldValue("matrizAnimalId", animal?.id);
        setFieldValue("matrizAnimalHandlingNumber", animal?.handlingNumber);

        try {
          await validateSelectFemale(animal, reproductionParameters);
          setFieldValue("shouldChangeFemaleCategoryForReproduction", false);
          handleAfterSelectFemale(formikValue, animal, setFieldValue);
        } catch (error) {
          handleErrorWhenSelectFemale(
            error,
            formikValue,
            animal,
            setFieldValue
          );
        }
      }
      setIsVisibleModalSelectAnimal(false);
    },
    [
      animals,
      handleAfterSelectFemale,
      handleErrorWhenSelectFemale,
      reproductionParameters,
    ]
  );

  const handleCancelSelectionAnimal = useCallback(() => {
    setIsVisibleModalSelectAnimal(false);
  }, []);

  const handleFindAnimal = useCallback(
    async (identification) => {
      const setFieldValue = formikRef.current.setFieldValue;
      const setFieldError = formikRef.current.setFieldError;
      if (!identification) {
        notification.warn({
          message: "Infome um valor no campo Identificação",
        });
        return;
      }
      setIsLoading(true);
      try {
        const {
          data: { results },
        } = await getAnimalsByIdentificationHandlingNumber({
          groupId,
          farmId,
          identification,
          specificGender: "Female",
        });
        if (results.length === 0) {
          notification.error({
            message: "Nenhum animal encontrado!",
          });
          setFieldError("matrizAnimalHandlingNumber", "Animal não encontrado");
        } else if (results.length > 1) {
          setAnimals(results);
          setIsVisibleModalSelectAnimal(true);
        } else {
          const female = results[0];
          const formikValue = formikRef.current.state.values;
          setSelectedFemale(female);
          setFieldValue("matrizAnimalId", female?.id);
          setFieldValue("matrizAnimalHandlingNumber", female?.handlingNumber);
          try {
            await validateSelectFemale(female, reproductionParameters);
            setFieldValue("shouldChangeFemaleCategoryForReproduction", false);
            handleAfterSelectFemale(formikValue, female, setFieldValue);
          } catch (error) {
            handleErrorWhenSelectFemale(
              error,
              formikValue,
              female,
              setFieldValue
            );
          }
        }
      } catch (error) {
        console.error(error);
        notification.error({
          message: "Nenhum animal encontrado!",
        });
        setFieldError("matrizAnimalHandlingNumber", "Animal não encontrado");
      } finally {
        setIsLoading(false);
      }
    },
    [
      farmId,
      groupId,
      handleAfterSelectFemale,
      handleErrorWhenSelectFemale,
      reproductionParameters,
    ]
  );

  const handleChangeBirthDate = useCallback(
    (date, setFieldValue) => {
      setFieldValue("birthDate", date);
      setBirthDateControll(date);
      if (selectedFemale && selectedFemale.lastCoverageId) {
        const gestationTime = moment(date).diff(
          moment(selectedFemale.lastCoverageDate),
          "days"
        );
        setFieldValue("gestationDays", gestationTime + 1);
        const birthForecast = moment(selectedFemale.lastCoverageDate).add(
          medGestationDaysBreed,
          "days"
        );
        setFieldValue("birthForecast", birthForecast);
      }
    },
    [medGestationDaysBreed, selectedFemale]
  );

  const handleAddChild = useCallback(
    (values, setFieldValue) => {
      if (countChild < MAX_CHILD_PER_BIRTH) {
        setFieldValue("childs", [
          ...values.childs,
          {
            tagId: null,
            sisbov: null,
            handlingNumber: null,
            nickname: null,
            gender: null,
            breedId: selectedFemale ? selectedFemale.breedId : null,
            farmOwnerId: selectedFemale ? selectedFemale.farmOwnerId : null,
            animalReproductionCategory: null,
            femaleSituation: null,
            animalFarmFunction: null,
            birthdayWeight: null,
            lotId: selectedFemale ? selectedFemale.lotId : null,
            stillborn: false,
          },
        ]);
        changeTab(countChild + "");
        setCountChild((old) => old + 1);
      }
    },
    [changeTab, countChild, selectedFemale]
  );

  const handleRemoveChild = useCallback((index, setFieldValue, values) => {
    const filterChilds = values.childs.filter((v, i) => i !== index);
    const tab = filterChilds.length - 1 + "";
    setFieldValue("childs", filterChilds);
    setCountChild((old) => old - 1);
    setActiveTab(tab);
  }, []);

  const handleModalConfirm = useCallback(() => {
    const formikValue = formikRef.current.state.values;
    const birthDate = formikValue.birthDate;
    if (
      selectedFemale &&
      selectedFemale?.lastCoverages.find(
        (coverage) => coverage.id === coverageSelectedId
      ).type === "MC/REPASSE" &&
      coverageSelectedGestationDays === null &&
      selectedFemale?.lastCoverages.find(
        (coverage) => coverage.id === coverageSelectedId
      ).pregnancyDiagnosisResult !== "Positivo"
    ) {
      notification.error({
        message: 'Informe um valor para o campo "Gestação(dias)" ',
      });
    } else {
      const setFieldValue = formikRef.current.setFieldValue;
      let newGestationDays = coverageSelectedGestationDays;

      // Repasse have a DG Positivo, so just calculate the new gestation days base on birthDate and infoRepasseCoverage?.coverageDate
      if (
        newGestationDays === null &&
        selectedFemale?.lastCoverages.find(
          (coverage) => coverage.id === coverageSelectedId
        ).pregnancyDiagnosisResult === "Positivo"
      ) {
        newGestationDays = calculateGestationDays(
          birthDate,
          infoRepasseCoverage?.coverageDate
        );
      }

      setFieldValue("animalCoverageId", coverageSelectedId);

      // Set data about gestation days and birthForecast
      if (
        selectedFemale &&
        selectedFemale.lastCoverages.find(
          (coverage) => coverage.id === coverageSelectedId
        ).type === "MC/REPASSE"
      ) {
        setFieldValue("gestationDays", newGestationDays);
        // Calculate new Coverage Date, based on input of MC/REPASSE gestationDays if MC/Repasse don't have DG Positivo
        if (
          newGestationDays &&
          selectedFemale?.lastCoverages.find(
            (coverage) => coverage.id === coverageSelectedId
          ).pregnancyDiagnosisResult !== "Positivo"
        ) {
          const newLastCoverageDate = calculateNewLastCoverageDate(
            birthDate,
            coverageSelectedGestationDays
          );
          const newBirthForecast = calculateBirthForecast(
            selectedFemale,
            newLastCoverageDate
          );
          setFieldValue("coverageDate", newLastCoverageDate);
          setFieldValue("birthForecast", newBirthForecast);
        } else {
          const newLastCoverageDate = infoRepasseCoverage?.coverageDate;
          const newBirthForecast = calculateBirthForecast(
            selectedFemale,
            newLastCoverageDate
          );
          setFieldValue("coverageDate", newLastCoverageDate);
          setFieldValue("birthForecast", newBirthForecast);
        }
        setFieldValue("coverageType", "MC/REPASSE");
        setFieldValue("reproductorId", infoRepasseCoverage?.maleId);
        setFieldValue(
          "reproductorName",
          infoRepasseCoverage?.semenOrMaleIdentification
        );
        setFieldValue(
          "lastCoverageDiagnosisDate",
          infoRepasseCoverage?.pregnancyDiagnosisDate
        );
        setFieldValue(
          "lastCoverageDiagnosisResult",
          infoRepasseCoverage?.pregnancyDiagnosisResult === 1
            ? "Positivo"
            : infoRepasseCoverage?.pregnancyDiagnosisResult === 2
            ? "Negativo"
            : null
        );
      } else {
        if (infoIaCoverage && formikValue) {
          // Calculate new birthForecast for IA/IATF
          const iaCoverageGestationDays = calculateGestationDays(
            birthDate,
            infoIaCoverage?.coverageDate
          );
          setFieldValue("gestationDays", iaCoverageGestationDays);
          setCoverageSelectedGestationDays(iaCoverageGestationDays);
          const newBirthForecast = calculateBirthForecast(
            selectedFemale,
            infoIaCoverage?.coverageDate
          );
          setFieldValue("birthForecast", newBirthForecast);
          setFieldValue("coverageDate", infoIaCoverage?.coverageDate);
          setFieldValue("coverageType", infoIaCoverage?.type);
          setFieldValue("reproductorId", infoIaCoverage?.semenId);
          setFieldValue(
            "reproductorName",
            infoIaCoverage?.semenOrMaleIdentification
          );
          setFieldValue(
            "lastCoverageDiagnosisDate",
            infoIaCoverage?.pregnancyDiagnosisDate
          );
          setFieldValue(
            "lastCoverageDiagnosisResult",
            infoIaCoverage?.pregnancyDiagnosisResult === 1
              ? "Positivo"
              : infoIaCoverage?.pregnancyDiagnosisResult === 2
              ? "Negativo"
              : null
          );
        }
      }

      setIsModalDefineCoverageOpen(false);
    }
  }, [
    calculateBirthForecast,
    calculateGestationDays,
    calculateNewLastCoverageDate,
    coverageSelectedGestationDays,
    coverageSelectedId,
    infoIaCoverage,
    infoRepasseCoverage,
    selectedFemale,
  ]);

  const handleModalCancel = useCallback(() => {
    setIsModalDefineCoverageOpen(false);
  }, []);

  // Get data of dropdowns
  useEffect(() => {
    async function fetchFarmOwnersOptions() {
      try {
        const {
          data: { results },
        } = await findAllFarmOwners({
          groupId,
          farmId,
        });

        setFarmOwners(results);
      } catch (error) {}
    }
    async function fetchBreeds() {
      const {
        data: { results },
      } = await getBreedIndexActive({
        groupId,
        farmId,
        withoutBreedCurveParam: true,
      });

      setBreeds(results);
    }
    async function fetchReproductionParameters() {
      try {
        const {
          data: { results },
        } = await findAllReproductionParemeterFarmByGroupIdAndFarmId({
          groupId,
          farmId,
        });
        if (results) {
          let newReproductionParameters = {};
          results.farmValues.forEach((fc) => {
            newReproductionParameters = {
              ...newReproductionParameters,
              [fc.reproductionParameter.name]: fc.value,
            };
          });
          setReproductionParameters(newReproductionParameters);
        }
      } catch (error) {
        console.error(error);
      }
    }
    async function fetchLots() {
      const {
        data: { results },
      } = await getLotIndexDropDown({
        groupId,
        farmId,
      });
      setLots(
        results.filter(
          (lot) =>
            lot.status === "Active" ||
            lot.status === "A" ||
            lot.status === "Pending" ||
            lot.status === "P"
        )
      );
    }
    async function fetchAll() {
      setIsLoadingRequest(true);
      await Promise.all([
        fetchBreeds(),
        fetchReproductionParameters(),
        fetchLots(),
        fetchFarmOwnersOptions(),
      ]);
      setIsLoadingRequest(false);
    }
    if (showCardForm === true) {
      fetchAll();
      firstInputRef.current.focus();
      setForm(data);
    }
  }, [showCardForm, groupId, farmId, data]);

  return (
    <Container
      title={
        <Row type="flex" justify="center">
          <strong>
            {form.id != null ? "Editar lançamento" : "Novo lançamento"}
          </strong>
        </Row>
      }
    >
      <Formik
        ref={formikRef}
        initialValues={form}
        initialErrors={{}}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmitForm}
        render={({
          values,
          setFieldValue,
          errors,
          submitCount,
          handleSubmit,
        }) => (
          <Spin spinning={isLoadingRequest || isLoading}>
            {!shouldCollapse && (
              <form autoComplete="off" onSubmit={handleSubmit}>
                {Object.entries(errors).length > 0 && submitCount > 0 && (
                  <>
                    <Row type="flex" justify="center" align="middle">
                      <label className="error">
                        {translation.error.formError}
                      </label>
                    </Row>
                  </>
                )}
                {/* Body */}
                {/* Fields birthDate, matrizAnimalId, and animal Data */}
                <Row type="flex" gutter={8}>
                  <Col span={6}>
                    <Row>
                      <label
                        className={
                          errors.matrizAnimalId && submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {translation.animalBirth.form.matrizAnimalId}*
                      </label>
                    </Row>
                    <Row
                      type="flex"
                      align="middle"
                      className="rowInput"
                      gutter={16}
                    >
                      <Col>
                        <Input
                          ref={firstInputRef}
                          value={values.matrizAnimalHandlingNumber ?? undefined}
                          placeholder={translation.defaultPlaceholder}
                          disabled={values.id != null}
                          onPressEnter={(e) => {
                            e.preventDefault();
                            handleFindAnimal(values.matrizAnimalHandlingNumber);
                          }}
                          onChange={(e) =>
                            setFieldValue(
                              "matrizAnimalHandlingNumber",
                              e.target.value
                            )
                          }
                        />
                      </Col>
                      <Col>
                        <Tooltip title="Digite uma identificação para liberar o botão de pesquisa">
                          <Button
                            disabled={
                              values.id != null ||
                              !values.animalHandlingNumber ||
                              values.animalHandlingNumber.trim().length === 0
                            }
                            shape="circle"
                            icon="search"
                            onClick={() =>
                              handleFindAnimal(
                                values.matrizAnimalHandlingNumber
                              )
                            }
                          />
                        </Tooltip>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <label
                        className={
                          errors.type && submitCount > 0 ? "error" : ""
                        }
                      >
                        {translation.animalBirth.form.type}*
                      </label>
                    </Row>
                    <Row>
                      <Select
                        value={values.type || undefined}
                        disabled={values.id !== null}
                        placeholder={translation.defaultSelectPlaceholder}
                        onChange={(value) => {
                          setFieldValue("type", value);
                          if (values.matrizSituation === "vazia") {
                            if (value === "parto") {
                              setFieldValue(
                                "gestationDays",
                                medGestationDaysBreed
                              );
                            } else if (value === "morteEmbrionaria") {
                              setFieldValue(
                                "gestationDays",
                                GESTATION_DAYS_MORTE_EMBRIONARIA
                              );
                            } else if (value === "aborto") {
                              setFieldValue(
                                "gestationDays",
                                GESTATION_DAYS_ABORTO
                              );
                            }
                          }
                        }}
                      >
                        <Select.Option value="parto">
                          {translation.animalBirth.types.parto}
                        </Select.Option>
                        <Select.Option value="morteEmbrionaria">
                          {translation.animalBirth.types.morteEmbrionaria}
                        </Select.Option>
                        <Select.Option value="aborto">
                          {translation.animalBirth.types.aborto}
                        </Select.Option>
                      </Select>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <label
                        className={
                          errors.birthDate && submitCount > 0 ? "error" : ""
                        }
                      >
                        {translation.animalBirth.form.birthDate}*
                      </label>
                    </Row>
                    <Row>
                      <DatePicker
                        value={values.birthDate || undefined}
                        format="DD/MM/YYYY"
                        placeholder={translation.defaultDatePickerPlaceholder}
                        allowClear={false}
                        disabledDate={(current) =>
                          selectedFemale?.lastCoverageDate != null
                            ? selectedFemale?.lastCoverageDiagnosisDate != null
                              ? moment(
                                  selectedFemale?.lastCoverageDiagnosisDate
                                ).isAfter(current) || moment().isBefore(current)
                              : moment(
                                  selectedFemale?.lastCoverageDate
                                ).isAfter(current) || moment().isBefore(current)
                            : moment().isBefore(current)
                        }
                        onChange={(date) =>
                          handleChangeBirthDate(date, setFieldValue)
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={3}>
                    <Row>
                      <label>
                        {
                          translation.animalBirth.form
                            .animalReproductionCategory
                        }
                      </label>
                    </Row>
                    <Row>
                      <strong>
                        {selectedFemale?.animalReproductionCategory ===
                        "nulipara"
                          ? "Nulípara"
                          : selectedFemale?.animalReproductionCategory ===
                            "novilha"
                          ? "Novilha"
                          : selectedFemale?.animalReproductionCategory ===
                            "primipara"
                          ? "Primípara"
                          : selectedFemale?.animalReproductionCategory ===
                            "secundipara"
                          ? "Secundípara"
                          : selectedFemale?.animalReproductionCategory ===
                            "multipara"
                          ? "Multípara"
                          : selectedFemale?.animalReproductionCategory ===
                            "reprodutor"
                          ? "Reprodutor"
                          : "-"}
                      </strong>
                    </Row>
                  </Col>
                  <Col span={3}>
                    <Row>
                      <label>
                        {translation.animalBirth.form.femaleSituation}
                      </label>
                    </Row>
                    <Row>
                      <strong>{`${
                        selectedFemale
                          ? selectedFemale?.gender === "M"
                            ? "-"
                            : selectedFemale?.femaleSituation === "vazia"
                            ? "Vazia"
                            : selectedFemale?.femaleSituation === "servida"
                            ? "Servida"
                            : selectedFemale?.femaleSituation === "prenha"
                            ? "Prenha"
                            : "-"
                          : "-"
                      }`}</strong>
                    </Row>
                  </Col>
                </Row>
                {/* Row With Last Covarege data  */}
                {(selectedFemale?.femaleSituation === "servida" ||
                  selectedFemale?.femaleSituation === "prenha") && (
                  <Row type="flex">
                    <Col span={24}>
                      <CustomCardDetails title="Dados Cobertura">
                        <Row type="flex" gutter={8}>
                          <Col span={4}>
                            <Row>
                              <label>
                                {translation.animalBirth.form.coverageInfoType}
                              </label>
                            </Row>
                            <Row>
                              <strong>{values?.coverageType ?? "-"}</strong>
                            </Row>
                          </Col>
                          <Col span={4}>
                            <Row>
                              <label>
                                {translation.animalBirth.form.coverageInfoDate}
                              </label>
                            </Row>
                            <Row>
                              <strong>
                                {values?.coverageDate
                                  ? moment(values?.coverageDate).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </strong>
                            </Row>
                          </Col>
                          <Col span={4}>
                            <Row>
                              <label>
                                {
                                  translation.animalBirth.form
                                    .coverageInfoGestationTime
                                }
                              </label>
                            </Row>
                            <Row>
                              <strong>
                                {values.gestationDays
                                  ? `${values.gestationDays} dias`
                                  : "-"}
                              </strong>
                            </Row>
                          </Col>
                          <Col span={4}>
                            <Row>
                              <label>
                                {
                                  translation.animalBirth.form
                                    .coverageInfoBirthForecast
                                }
                              </label>
                            </Row>
                            <Row>
                              <strong>
                                {values.birthForecast
                                  ? moment(values.birthForecast).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </strong>
                            </Row>
                          </Col>
                          <Col span={4}>
                            <Row>
                              <label>
                                {
                                  translation.animalBirth.form
                                    .coverageInfoReprodutor
                                }
                              </label>
                            </Row>
                            <Row>
                              <strong>{values.reproductorName || "-"}</strong>
                            </Row>
                          </Col>
                          <Col span={4}>
                            <Row>
                              <label>
                                {
                                  translation.animalBirth.form
                                    .coverageInfoDGDate
                                }
                              </label>
                            </Row>
                            <Row>
                              <strong>
                                {values.lastCoverageDiagnosisDate
                                  ? moment(
                                      values.lastCoverageDiagnosisDate
                                    ).format("DD/MM/YYYY")
                                  : "-"}
                              </strong>
                            </Row>
                          </Col>
                          <Col span={4}>
                            <Row>
                              <label>
                                {
                                  translation.animalBirth.form
                                    .coverageInfoDGResult
                                }
                              </label>
                            </Row>
                            <Row>
                              <strong
                                style={
                                  values.lastCoverageDiagnosisResult ===
                                  "Positivo"
                                    ? {
                                        color: "green",
                                      }
                                    : values.lastCoverageDiagnosisResult ===
                                      "Negativo"
                                    ? {
                                        color: "red",
                                      }
                                    : {}
                                }
                              >
                                {values.lastCoverageDiagnosisResult || "-"}
                              </strong>
                            </Row>
                          </Col>
                        </Row>
                        {selectedFemale?.lastCoverages?.length > 1 &&
                          coverageNotSelected && (
                            <Row type="flex" gutter={8}>
                              <Col span={4}>
                                <Row>
                                  <label>
                                    {
                                      translation.animalBirth.form
                                        .coverageInfoType
                                    }
                                  </label>
                                </Row>
                                <Row>
                                  <strong>
                                    {coverageNotSelected?.coverageType ?? "-"}
                                  </strong>
                                </Row>
                              </Col>
                              <Col span={4}>
                                <Row>
                                  <label>
                                    {
                                      translation.animalBirth.form
                                        .coverageInfoDate
                                    }
                                  </label>
                                </Row>
                                <Row>
                                  <strong>
                                    {coverageNotSelected?.coverageDate
                                      ? moment(
                                          coverageNotSelected?.coverageDate
                                        ).format("DD/MM/YYYY")
                                      : "-"}
                                  </strong>
                                </Row>
                              </Col>
                              <Col span={4}>
                                <Row>
                                  <label>
                                    {
                                      translation.animalBirth.form
                                        .coverageInfoGestationTime
                                    }
                                  </label>
                                </Row>
                                <Row>
                                  <strong>
                                    {coverageNotSelected.gestationDays
                                      ? `${coverageNotSelected.gestationDays} dias`
                                      : "-"}
                                  </strong>
                                </Row>
                              </Col>
                              <Col span={4}>
                                <Row>
                                  <label>
                                    {
                                      translation.animalBirth.form
                                        .coverageInfoBirthForecast
                                    }
                                  </label>
                                </Row>
                                <Row>
                                  <strong>
                                    {coverageNotSelected.birthForecast
                                      ? moment(
                                          coverageNotSelected.birthForecast
                                        ).format("DD/MM/YYYY")
                                      : "-"}
                                  </strong>
                                </Row>
                              </Col>
                              <Col span={4}>
                                <Row>
                                  <label>
                                    {
                                      translation.animalBirth.form
                                        .coverageInfoReprodutor
                                    }
                                  </label>
                                </Row>
                                <Row>
                                  <strong>
                                    {coverageNotSelected.reproductorName || "-"}
                                  </strong>
                                </Row>
                              </Col>
                              <Col span={4}>
                                <Row>
                                  <label>
                                    {
                                      translation.animalBirth.form
                                        .coverageInfoDGDate
                                    }
                                  </label>
                                </Row>
                                <Row>
                                  <strong>
                                    {coverageNotSelected.lastCoverageDiagnosisDate
                                      ? moment(
                                          coverageNotSelected.lastCoverageDiagnosisDate
                                        ).format("DD/MM/YYYY")
                                      : "-"}
                                  </strong>
                                </Row>
                              </Col>
                              <Col span={4}>
                                <Row>
                                  <label>
                                    {
                                      translation.animalBirth.form
                                        .coverageInfoDGResult
                                    }
                                  </label>
                                </Row>
                                <Row>
                                  <strong
                                    style={
                                      coverageNotSelected.lastCoverageDiagnosisResult ===
                                      "Positivo"
                                        ? {
                                            color: "green",
                                          }
                                        : coverageNotSelected.lastCoverageDiagnosisResult ===
                                          "Negativo"
                                        ? {
                                            color: "red",
                                          }
                                        : {}
                                    }
                                  >
                                    {coverageNotSelected.lastCoverageDiagnosisResult ||
                                      "-"}
                                  </strong>
                                </Row>
                              </Col>
                            </Row>
                          )}
                      </CustomCardDetails>
                    </Col>
                  </Row>
                )}
                {/* Row to input gestation Days */}
                {(selectedFemale?.femaleSituation === "vazia" ||
                  selectedFemale?.femaleSituation === null) && (
                  <Row type="flex" gutter={8}>
                    <Col span={4}>
                      <Row>
                        <label
                          className={
                            errors.gestationDays && submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.animalBirth.form.gestationDays}*
                        </label>
                      </Row>
                      <Row>
                        <InputNumber
                          min={1}
                          value={values.gestationDays || undefined}
                          placeholder={translation.defaultPlaceholder}
                          step={1}
                          onChange={(value) =>
                            setFieldValue("gestationDays", value)
                          }
                        />
                      </Row>
                    </Col>
                  </Row>
                )}
                {/* Row to select Coverage */}
                {selectedFemale &&
                  selectedFemale?.femaleSituation === "servida" &&
                  selectedFemale?.lastCoverages?.length > 1 &&
                  values.type === "parto" && (
                    <Row type="flex" justify="center">
                      <Col>
                        <ButtonSelectCoverage
                          type="button"
                          onClick={() => setIsModalDefineCoverageOpen(true)}
                        >
                          <LogoBullGreen />
                          Definir Cobertura
                        </ButtonSelectCoverage>
                      </Col>
                    </Row>
                  )}
                {/* Row with fields: Discard Female field and Discard Motive */}
                {(values.type === "morteEmbrionaria" ||
                  values.type === "aborto") && (
                  <Row type="flex" gutter={8}>
                    <Col span={6}>
                      <Row>
                        <label
                          className={
                            errors.lotId && submitCount > 0 ? "error" : ""
                          }
                        >
                          {translation.animalBirth.form.lotMatrizDestinationId}
                        </label>
                      </Row>
                      <Row>
                        <Select
                          name="lotId"
                          value={
                            values?.lotMatrizDestinationId != null
                              ? values?.lotMatrizDestinationId
                              : undefined
                          }
                          placeholder={translation.defaultSelectPlaceholder}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          allowClear={true}
                          onDeselect={() => {
                            setFieldValue(
                              "shouldMovimentMatrizToNewLot",
                              false
                            );
                            setFieldValue("lotMatrizDestinationId", null);
                          }}
                          onChange={(value) => {
                            setFieldValue("shouldMovimentMatrizToNewLot", true);
                            setFieldValue("lotMatrizDestinationId", value);
                          }}
                        >
                          {lots.length > 0 &&
                            lots.map((lot) => (
                              <Select.Option key={lot.id} value={lot.id}>
                                {`${lot.name} / ${
                                  lot.currentAmountAnimals
                                } animais / Atividade: ${
                                  lot.profitCenterName
                                } ${
                                  lot.picketId != null
                                    ? `/ Piquete: ${lot.picketName}`
                                    : ``
                                }`}
                              </Select.Option>
                            ))}
                        </Select>
                      </Row>
                    </Col>
                  </Row>
                )}
                {/* Row with fields: Discard Female field and Discard Motive */}
                {(values.type === "morteEmbrionaria" ||
                  values.type === "aborto") && (
                  <Row type="flex" gutter={8}>
                    <Col span={4}>
                      <Row>
                        <label>
                          {translation.animalBirth.form.shouldDiscardFemale}
                        </label>
                      </Row>
                      <Row>
                        <Radio.Group
                          value={values.shouldDiscardFemale}
                          onChange={(e) =>
                            setFieldValue("shouldDiscardFemale", e.target.value)
                          }
                        >
                          <Row type="flex" gutter={8}>
                            <Col>
                              <Radio.Button value={true}>Sim</Radio.Button>
                            </Col>
                            <Col>
                              <Radio.Button value={false}>Não</Radio.Button>
                            </Col>
                          </Row>
                        </Radio.Group>
                      </Row>
                    </Col>
                    {values.shouldDiscardFemale === true && (
                      <Col span={6}>
                        <Row>
                          <label
                            className={
                              errors.discardMotive && submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            {translation.animalBirth.form.discardMotive}*
                          </label>
                        </Row>
                        <Row>
                          <Select
                            value={values.discardMotive || undefined}
                            placeholder={translation.defaultSelectPlaceholder}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) =>
                              setFieldValue("discardMotive", value)
                            }
                          >
                            {listDiscardMotives.map((discardMotive) => (
                              <Select.Option
                                key={discardMotive.id}
                                value={discardMotive.value}
                              >
                                {discardMotive.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Row>
                      </Col>
                    )}
                  </Row>
                )}
                {/* Row with fields: Discard Female field and Discard Motive */}
                {(values.type === "morteEmbrionaria" ||
                  values.type === "aborto") && (
                  <Row type="flex" gutter={8}>
                    <Col span={24}>
                      <Row>
                        <label>
                          {translation.animalBirth.form.observation}
                        </label>
                      </Row>
                      <Row>
                        <Input
                          value={values.observation || undefined}
                          placeholder={translation.defaultPlaceholder}
                          onChange={(e) =>
                            setFieldValue("observation", e.target.value)
                          }
                        />
                      </Row>
                    </Col>
                  </Row>
                )}
                {/* Childrens */}
                {values.type === "parto" && (
                  <Row type="flex">
                    <Col span={24}>
                      <Row type="flex">
                        <Col>
                          <ButtonStandard
                            buttonType="alternative"
                            type="button"
                            onClick={() =>
                              handleAddChild(values, setFieldValue)
                            }
                            disabled={countChild === 3}
                          >
                            Nova Cria
                          </ButtonStandard>
                        </Col>
                      </Row>
                      <Row>
                        {values.childs.length > 0 && (
                          <Tabs
                            type="card"
                            activeKey={activeTab}
                            onChange={(key) => changeTab(key)}
                          >
                            {values.childs.map((child, index) => (
                              <Tabs.TabPane
                                key={index + ""}
                                tab={
                                  <span>
                                    {`Cria ${index + 1}  `}
                                    {Object.keys(errors).includes("childs") &&
                                      errors?.childs[index] &&
                                      submitCount > 0 && <AlertIcon />}
                                    {values.childs.length > 1 && (
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleRemoveChild(
                                            index,
                                            setFieldValue,
                                            values
                                          )
                                        }
                                        style={{
                                          background: "transparent",
                                          border: "none",
                                          marginLeft: 4,
                                        }}
                                      >
                                        <Icon
                                          type="delete"
                                          style={{ color: "#d44c4c" }}
                                        />
                                      </button>
                                    )}
                                  </span>
                                }
                              >
                                <AnimalBirthChildForm
                                  key={index}
                                  selectedFemale={selectedFemale}
                                  index={index}
                                  errors={
                                    errors &&
                                    Object.keys(errors).includes("childs")
                                      ? errors.childs[index]
                                      : []
                                  }
                                  submitCount={submitCount}
                                  values={child}
                                  setFieldValue={setFieldValue}
                                  lots={lots}
                                  breeds={breeds}
                                  farmOwners={farmOwners}
                                />
                              </Tabs.TabPane>
                            ))}
                          </Tabs>
                        )}
                      </Row>
                    </Col>
                  </Row>
                )}
                <Modal
                  centered
                  width={700}
                  visible={isModalDefineCoverageOpen}
                  onCancel={handleModalCancel}
                  onOk={handleModalConfirm}
                >
                  <Row justify="center" align="middle" gutter={8}>
                    <Col span={1}>
                      <Icon
                        type="exclamation-circle"
                        style={{
                          color: "#faad14",
                        }}
                      />
                    </Col>
                    <Col span={23}>
                      <strong style={{ fontSize: 18 }}>
                        Selecione a cobertura responsável pela prenhes:
                      </strong>
                    </Col>
                  </Row>
                  <TableSelectCoverage>
                    <div className="header">MC/Repasse</div>
                    <div className="col title">
                      <label>Data</label>
                    </div>
                    <div className="col title">
                      <label>Reprodutor</label>
                    </div>
                    <div className="col title center">
                      <label>Gestação(dias)</label>
                    </div>
                    <div className="col value check">
                      <CustomCheckBox
                        checked={infoRepasseCoverage?.id === coverageSelectedId}
                        onChange={() => {
                          setCoverageSelectedId(infoRepasseCoverage?.id);
                        }}
                      />
                    </div>
                    <div className="col value">
                      <strong>
                        {moment(infoRepasseCoverage?.coverageDate).format(
                          "DD/MM/YYYY"
                        )}
                      </strong>
                    </div>
                    <div className="col value">
                      <strong>
                        {infoRepasseCoverage?.semenOrMaleIdentification}
                      </strong>
                    </div>
                    <div className="col value center">
                      {infoRepasseCoverage?.pregnancyDiagnosisResult ===
                      "Positivo" ? (
                        <strong>
                          {moment(values.birthDate).diff(
                            moment(infoRepasseCoverage?.coverageDate),
                            "days"
                          ) + 1}
                        </strong>
                      ) : (
                        <InputNumber
                          onKeyDown={handleKeyDownInputNumber}
                          className="inputPregnancy"
                          disabled={
                            infoRepasseCoverage?.id !== coverageSelectedId
                          }
                          value={coverageSelectedGestationDays || undefined}
                          placeholder="Digite"
                          min={1}
                          max={maxGestationDays}
                          step={1}
                          onChange={(value) =>
                            setCoverageSelectedGestationDays(value)
                          }
                        />
                      )}
                    </div>
                    <div className="header">IA/IATF</div>
                    <div className="col title">
                      <label>Data</label>
                    </div>
                    <div className="col title">
                      <label>Reprodutor</label>
                    </div>
                    <div className="col title center">
                      <label>Gestação(dias)</label>
                    </div>
                    <div className="col value check">
                      <CustomCheckBox
                        checked={infoIaCoverage?.id === coverageSelectedId}
                        onChange={() => {
                          setCoverageSelectedId(infoIaCoverage?.id);
                        }}
                      />
                    </div>
                    <div className="col value">
                      <strong>
                        {moment(infoIaCoverage?.coverageDate).format(
                          "DD/MM/YYYY"
                        )}
                      </strong>
                    </div>
                    <div className="col value">
                      <strong>
                        {infoIaCoverage?.semenOrMaleIdentification}
                      </strong>
                    </div>
                    <div className="col value center">
                      <strong>
                        {moment(values.birthDate).diff(
                          moment(infoIaCoverage?.coverageDate),
                          "days"
                        ) + 1}
                      </strong>
                    </div>
                  </TableSelectCoverage>
                </Modal>
                {/* Footer */}
                <Row
                  className="rowFooter"
                  type="flex"
                  justify="center"
                  align="middle"
                  gutter={8}
                >
                  <Col>
                    <ButtonStandard
                      type="submit"
                      name="buttonSaveFinancialTransactionsForm"
                      buttonType="principal"
                    >
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                  <Col>
                    <ButtonStandard
                      tabIndex="-1"
                      name="buttonSaveFinancialTransactionsForm"
                      buttonType="secondary"
                      type="button"
                      onClick={() => handleCancel()}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>
                  </Col>
                </Row>
              </form>
            )}
            <Row type="flex" justify="center" align="middle">
              {shouldCollapse ? (
                <button
                  type="button"
                  tabIndex="-1"
                  className="buttonArrowCollapse"
                  onClick={() => setShouldCollapse(false)}
                >
                  <ArrowUpIcon />
                </button>
              ) : (
                <button
                  type="button"
                  tabIndex="-1"
                  className="buttonArrowCollapse withMarginBottom"
                  onClick={() => setShouldCollapse(true)}
                >
                  <ArrowDownIcon />
                </button>
              )}
            </Row>
          </Spin>
        )}
      />
      <SelectAnimalsModal
        data={animals}
        visible={isVisibleModalSelectAnimal}
        selectedAnimalsKeys={selectedFemale ? [selectedFemale] : []}
        onConfirmSelection={handleConfirmSelectionAnimal}
        onCancel={handleCancelSelectionAnimal}
        isMultiple={false}
        forReproductionPurpose={true}
      />
    </Container>
  );
};

export default AnimalBirthForm;
