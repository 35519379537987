import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CardCustom,
  Title,
  EditButton,
  DeleteButton,
  Footer,
} from "../../../styles";
import { Spin, Col, Input, Row, DatePicker, notification, Select } from "antd";
import ButtonStandard from "../../../../../components/utils/button";

import { Creators as ResultCenterActions } from "../../../../../store/ducks/resultCenter";

import AddIcon from "../../../../../components/utils/icons/add";
import TrashIcon from "../../../../../components/utils/icons/trash";

import moment from "moment";

import {
  findById,
  save,
  update,
} from "../../../../../services/resultCenterService";
import { findAllFarmOwners } from "../../../../../services/farmOwnerService";
import { getOnlyActives } from "../../../../../services/retreatService";

const FinancialParametersResultCenterForm = () => {
  const initialStateForm = {
    name: null,
    type: "custom",
    startDate: null,
    endDate: null,
    items: [
      {
        id: null,
        name: null,
        code: null,
        entityId: null,
      },
    ],
  };

  const {
    resultCenter: {
      resultCenterId,
      isResultCenterFormVisible,
      isLoadingForm,
      isFinancialNatureApportionmentFormVisible,
    },
    app: {
      translation,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [form, setForm] = useState(initialStateForm);
  const [isEditMode, setIsEditMode] = useState(true);
  const [canEditItems, setCanEditItems] = useState(true);
  const [errors, setErrors] = useState([]);
  const [farmOwnerList, setFarmOwnerList] = useState([]);
  const [retreatList, setRetreatList] = useState([]);

  const [resultCenterTypes] = useState([
    {
      code: "activityType",
      name: "Atividades",
    },
    {
      code: "retreat",
      name: "Retiros",
    },
    {
      code: "farmOwner",
      name: "Proprietários",
    },
    {
      code: "custom",
      name: "Manual",
    },
  ]);

  const [activityCostList] = useState([
    {
      code: "Growth",
      name: translation.financial.costType["Growth"],
    },
    {
      code: "RecreateFattenPasture",
      name: translation.financial.costType["RecreateFattenPasture"],
    },
    {
      code: "RecreateFattenSemiConfinement",
      name: translation.financial.costType["RecreateFattenSemiConfinement"],
    },
    {
      code: "RecreateFattenConfinement",
      name: translation.financial.costType["RecreateFattenConfinement"],
    },
    {
      code: "Agriculture",
      name: translation.financial.costType["Agriculture"],
    },
    {
      code: "Others",
      name: translation.financial.costType["Others"],
    },
  ]);

  useEffect(() => {
    async function getFarmOwnersOptions() {
      try {
        const {
          data: { results: ownerList },
        } = await findAllFarmOwners({
          groupId,
          farmId,
        });

        setFarmOwnerList(ownerList);
      } catch (error) {}
    }

    async function getRetreatOptions() {
      try {
        const {
          data: { results: retreatList },
        } = await getOnlyActives({
          groupId,
          farmId,
        });

        setRetreatList(retreatList);
      } catch (error) {}
    }

    async function fetchData() {
      try {
        dispatch(ResultCenterActions.setIsLoadingForm(true));
        const {
          data: { results },
        } = await findById({ groupId, farmId, id: resultCenterId });

        const formValue = {
          id: resultCenterId,
          name: results.name,
          startDate: results.startDate,
          endDate: results.endDate,
          type: results.type,
          items: results.items,
        };

        setForm(formValue);

        if (results.type !== "custom") {
          setCanEditItems(false);
        }
      } catch (error) {
        console.error(error);
        notification.error({
          title: "Erro ao carregar",
          message:
            "Não foi possível carregar o centro de resultado. Contate o suporte",
        });
      } finally {
        dispatch(ResultCenterActions.setIsLoadingForm(false));
      }
    }

    if (resultCenterId === null) {
      setForm(initialStateForm);
    } else {
      fetchData();
    }
    getFarmOwnersOptions();
    getRetreatOptions();
  }, [groupId, farmId, resultCenterId]);

  useEffect(() => {
    if (isResultCenterFormVisible) {
      setForm(initialStateForm);
    }
  }, [isResultCenterFormVisible]);

  useEffect(() => {
    if (isFinancialNatureApportionmentFormVisible) {
      setIsEditMode(false);
    } else {
      setIsEditMode(true);
    }
  }, [isFinancialNatureApportionmentFormVisible]);

  const closeForm = () => {
    dispatch(ResultCenterActions.showOrHideForm());
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const body = { ...form, id: resultCenterId };

      try {
        dispatch(ResultCenterActions.setIsLoadingForm(true));
        if (body.id === null) {
          const {
            data: { results },
          } = await save({ groupId, farmId, body });
          notification.success({
            message: "Centro de Resultado criado com sucesso",
          });
          dispatch(
            ResultCenterActions.showOrHideFinancialNatureApportionmentForm(
              results?.id
            )
          );
        } else {
          const {
            data: { results },
          } = await update({ groupId, farmId, id: resultCenterId, body });
          notification.success({
            message: "Centro de Resultado atualizado com sucesso",
          });
          dispatch(
            ResultCenterActions.showOrHideFinancialNatureApportionmentForm(
              results?.id
            )
          );
        }
      } catch (error) {
        notification.error({
          title: "Erro ao salvar",
          message:
            "Não foi possível salvar o Centro de Resultado. Contate o suporte",
        });
      } finally {
        dispatch(ResultCenterActions.setIsLoadingForm(false));
        dispatch(ResultCenterActions.index(groupId, farmId));
      }
    }
  };

  function validateForm() {
    let aErrors = [];
    if (form.name === null || form.name.trim() === "") {
      aErrors.push("name");
    }
    if (form.startDate === null) {
      aErrors.push("startDate");
    }
    if (form.endDate === null) {
      aErrors.push("endDate");
    }
    if (moment(form.startDate).isAfter(moment(form.endDate))) {
      aErrors.push("startDate");
      aErrors.push("endDate");
      notification.error({
        title: "Erro ao salvar",
        message: "A Validade Final não pode ser antes da Validade Inicial.",
      });
    }

    if (
      form.items === null ||
      form.items === undefined ||
      form.items.length <= 0
    ) {
      aErrors.push("items");

      switch (form.type) {
        case "retreat":
          notification.error({
            title: "Erro ao salvar",
            message: "Não existem retiros cadastrados.",
          });
          break;
        case "farmOwner":
          notification.error({
            title: "Erro ao salvar",
            message: "Não existem proprietários cadastrados.",
          });
          break;
        case "activityType":
          notification.error({
            title: "Erro ao salvar",
            message: "Atividades não informadas.",
          });
          break;
        default:
          notification.error({
            title: "Erro ao salvar",
            message: "Itens não informados.",
          });
      }
    } else {
      form.items.forEach((item, index) => {
        if (item.name === null || item.name.trim() === "") {
          aErrors.push(`name${index}`);
        }
      });
    }

    setErrors(aErrors);
    return aErrors.length > 0 ? false : true;
  }

  const handleNewItem = () => {
    const arrItems = form.items;
    arrItems.push({ name: null, code: null });
    setForm({
      ...form,
      items: arrItems,
    });
  };

  const handleDeleteItem = (index) => {
    setForm({
      ...form,
      items: form.items.filter((item, i) => index !== i),
    });
  };

  const handleChangeResultCenterType = (type) => {
    let formItems = form.items;

    switch (type) {
      case "retreat":
        if (!Array.isArray(retreatList) || retreatList?.length === 0) {
          notification.error({
            title: "Erro",
            message: "Não existem retiros cadastrados.",
          });
        }
        setCanEditItems(false);
        formItems =
          retreatList !== null && retreatList.length > 0
            ? retreatList.map((r) => ({
                id: null,
                name: r.name,
                code: null,
                entityId: r.id,
              }))
            : [];
        break;
      case "farmOwner":
        if (!Array.isArray(farmOwnerList) || farmOwnerList?.length === 0) {
          notification.error({
            title: "Erro",
            message: "Não existem proprietários cadastrados.",
          });
        }
        setCanEditItems(false);
        formItems =
          farmOwnerList !== null && farmOwnerList.length > 0
            ? farmOwnerList.map((fo) => ({
                id: null,
                name: fo.name,
                code: fo.code,
                entityId: fo.id,
              }))
            : [];
        break;
      case "activityType":
        setCanEditItems(false);
        formItems =
          activityCostList !== null && activityCostList.length > 0
            ? activityCostList.map((a) => ({
                id: null,
                name: a.name,
                code: null,
                entityId: null,
              }))
            : [];
        break;
      default:
        formItems = [{ id: null, name: null, code: null, entityId: null }];
        setCanEditItems(true);
    }

    setForm({ ...form, type: type, items: [...formItems] });
  };

  const handleChangeItem = (value, field, index) => {
    const currentFormValue = { ...form };
    currentFormValue.items[index][field] = value;

    setForm({ ...currentFormValue });
  };

  const { Option } = Select;

  return (
    <CardCustom className="grow-card">
      <Spin spinning={isLoadingForm}>
        <Row type="flex" justify="start" className="rowHeader">
          <Title>
            {translation.financial.parameters.resultCenter.form.title}
          </Title>
        </Row>

        {/* Name */}
        <Row
          type="flex"
          gutter={8}
          justify="start"
          align="middle"
          className="rowForm"
        >
          <Col span={12}>
            <label className={errors.includes("name") ? "error" : ""}>
              Título:
            </label>

            {isEditMode ? (
              <Input
                name="name"
                value={form?.name != null ? form?.name : undefined}
                type="name"
                autoComplete="chrome-off"
                placeholder={translation.defaultPlaceholder}
                onChange={(e) => {
                  setForm({ ...form, name: e.target.value });
                  setErrors(errors.filter((e) => e !== "name"));
                }}
              />
            ) : (
              <span className="display-label">{form?.name}</span>
            )}
          </Col>
        </Row>

        {/* Type */}
        <Row
          type="flex"
          gutter={8}
          justify="start"
          align="middle"
          className="rowForm"
        >
          <Col span={12}>
            <label className={errors.includes("tyoe") ? "error" : ""}>
              Tipo:
            </label>

            {isEditMode ? (
              <Select
                style={{ width: "100%" }}
                name="type"
                value={form?.type}
                onChange={(value) => {
                  setErrors(errors.filter((e) => e !== "type"));
                  handleChangeResultCenterType(value);
                }}
              >
                {resultCenterTypes !== null &&
                  Object.entries(resultCenterTypes).length > 0 &&
                  resultCenterTypes.map((type) => (
                    <Option key={type.code} value={type.code}>
                      {type.name}
                    </Option>
                  ))}
              </Select>
            ) : (
              <span className="display-label">
                {
                  resultCenterTypes.find((types) => types.code === form.type)
                    .name
                }
              </span>
            )}
          </Col>
        </Row>

        {/* Start and End Date */}
        <Row
          type="flex"
          gutter={8}
          justify="start"
          align="middle"
          className="rowForm"
        >
          <Col span={12}>
            <label className={errors.includes("startDate") ? "error" : ""}>
              Validade Inicial:
            </label>
            {isEditMode ? (
              <DatePicker.MonthPicker
                style={{ width: "95%" }}
                name="startDate"
                value={form.startDate ? moment(form.startDate) : undefined}
                placeholder={translation.defaultDatePickerPlaceholder}
                format={"MMM/YYYY"}
                allowClear={false}
                onChange={(date) => {
                  setForm({ ...form, startDate: date });
                  setErrors(errors.filter((e) => e !== "startDate"));
                }}
              />
            ) : (
              <span className="display-label">
                {moment(form.startDate).format("MMM/YYYY")}
              </span>
            )}
          </Col>

          <Col span={12}>
            <label className={errors.includes("endDate") ? "error" : ""}>
              Validade Final:
            </label>
            {isEditMode ? (
              <DatePicker.MonthPicker
                style={{ width: "95%" }}
                name="endDate"
                value={form.endDate ? moment(form.endDate) : undefined}
                disabledDate={(currentDate) =>
                  currentDate.isBefore(form.startDate)
                }
                placeholder={translation.defaultDatePickerPlaceholder}
                format={"MMM/YYYY"}
                allowClear={false}
                onChange={(date) => {
                  setForm({ ...form, endDate: date });
                  setErrors(errors.filter((e) => e !== "endDate"));
                }}
              />
            ) : (
              <span className="display-label">
                {moment(form.endDate).format("MMM/YYYY")}
              </span>
            )}
          </Col>
        </Row>

        {/* Plus item button */}
        {isEditMode && canEditItems ? (
          <Row
            type="flex"
            gutter={8}
            justify="center"
            align="middle"
            className="rowForm"
          >
            <EditButton type="button" onClick={handleNewItem}>
              <AddIcon />
            </EditButton>
          </Row>
        ) : null}

        {/* Result Center Items */}
        <div
          className="rowForm"
          style={{ maxHeight: "300px", overflowY: "auto", overflowX: "hidden" }}
        >
          {form.items.map((item, index) => {
            if (isEditMode && canEditItems) {
              return (
                <Row
                  key={index}
                  type="flex"
                  gutter={8}
                  justify="start"
                  align="middle"
                  className="rowForm"
                >
                  <Col span={12}>
                    <label
                      className={errors.includes(`name${index}`) ? "error" : ""}
                    >
                      {index + 1}. Nome
                    </label>
                    <Input
                      key={`name${index}`}
                      style={{ width: "100%" }}
                      name={`name${index}`}
                      value={item != null ? item.name : undefined}
                      autoComplete="chrome-off"
                      placeholder={translation.defaultPlaceholder}
                      onChange={(e) => {
                        handleChangeItem(e.target.value, "name", index);
                        setErrors(errors.filter((e) => e !== `name${index}`));
                      }}
                    ></Input>
                  </Col>
                  <Col span={10}>
                    <label
                      className={errors.includes(`code${index}`) ? "error" : ""}
                    >
                      Código
                    </label>
                    <Input
                      key={`code${index}`}
                      name={`code${index}`}
                      value={item != null ? item.code : undefined}
                      autoComplete="chrome-off"
                      placeholder={translation.defaultPlaceholder}
                      onChange={(e) => {
                        handleChangeItem(e.target.value, "code", index);
                        setErrors(errors.filter((e) => e !== `code${index}`));
                      }}
                    />
                  </Col>
                  <Col span={2}>
                    <label></label>
                    <DeleteButton
                      disabled={form.items.length <= 1}
                      type="button"
                      onClick={() => handleDeleteItem(index)}
                    >
                      <TrashIcon />
                    </DeleteButton>
                  </Col>
                </Row>
              );
            } else {
              return (
                <Row
                  key={index}
                  type="flex"
                  gutter={8}
                  justify="start"
                  align="middle"
                  className="rowForm"
                >
                  <Col span={12}>
                    <label
                      className={errors.includes(`name${index}`) ? "error" : ""}
                    >
                      {index + 1}. Nome:
                    </label>
                    <span className="display-label">{item.name}</span>
                  </Col>
                  <Col span={12}>
                    <label
                      className={errors.includes(`code${index}`) ? "error" : ""}
                    >
                      Código:
                    </label>
                    <span className="display-label">{item.code}</span>
                  </Col>
                </Row>
              );
            }
          })}
        </div>

        {/* Footer */}
        {isEditMode ? (
          <Footer>
            <Row type="flex">
              <Col span={24} className="buttonsDiv">
                <ButtonStandard
                  type="button"
                  buttonType="type7"
                  onClick={closeForm}
                >
                  {translation.buttons.cancel}
                </ButtonStandard>

                <ButtonStandard
                  type="button"
                  buttonType="type6"
                  onClick={handleSubmit}
                >
                  {translation.buttons.save}
                </ButtonStandard>
              </Col>
            </Row>
          </Footer>
        ) : null}
      </Spin>
    </CardCustom>
  );
};

export default FinancialParametersResultCenterForm;
