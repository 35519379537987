import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
/* Redux libs */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
/* Actions from ducks Farm */
import { Creators as AppActions } from "../../store/ducks/app";
import { Creators as AccountFarmActions } from "../../store/ducks/accountFarm";
// Components
import {
  notification,
  Row,
  Input,
  Col,
  Divider,
  Icon,
  Spin,
  Select,
  Checkbox,
  Radio,
} from "antd";

/* Styles */
import {
  Wrapper,
  Container,
  RegisterContainer,
  FormContainer,
  DisplayContainer,
  DisplayBody,
  DisplayBodyMessage,
  DisplayBox,
  FooterSideBar,
  LogoFooter,
  DisplayBoxFarm,
} from "./styles";

/* Services */
import { createGroupAndFarm } from "../../services/authService";
import { getCep } from "../../services/helpersMethodsService";
import { getStatesInfo, getCitiesByState } from "../../services/cityService";

/* Components */
import ButtonStandard from "../../components/utils/button";
import ArrowIcon from "../../components/utils/icons/arrows/red/left";
import GroupIcon from "../../components/utils/icons/group";
import ZipCodeInput from "../../components/utils/input/zipCodeInput";
import HectareInput from "../../components/utils/input/hectareInput";
import DocumentInput from "../../components/utils/input/documentInput";

/* Images */
import LogoVertical from "../../components/login/logos/logoVertical";
import { updateAccountFarmTrialToContract } from "../../services/accountFarmService";
import RadioButton from "../../components/utils/radioButton";

class RegisterGroupAndFarm extends Component {
  initialCountries = [];

  initialStates = [];

  initialCities = [];

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      form: {
        userId: "",
        group: null,
        statusGroup: "A",
        name: null,
        companyName: null,
        document: null,
        documentType: "CPF",
        address: null,
        state: null,
        city: null,
        country: null,
        complement_address: null,
        zipCode: null,
        status: "Active",
        totalArea: 0,
        livestockArea: 0,
        cultivationArea: 0,
        improvementsArea: 0,
        nonProductiveArea: 0,
        rentAreaOutSourced: 0,
        clientCheck: false,
        boitel: false,
        boitelVisibleToAllSystem: false,
        ownerName: "",
      },
      isLoadingRequest: false,
      fullname: "",
      email: "",
      loadingCep: false,
      zipCodeValid: null,
      statesArray: [],
      citiesArray: [],
      countriesArray: [],
      types: [],
      redirect: false,
    };
  }

  componentDidMount() {
    this.getCityStateCountryInfo();
  }

  getCityStateCountryInfo = async (visible) => {
    this.setState({
      isLoadingRequest: true,
    });

    const { data } = await getStatesInfo();

    this.setState({
      isLoadingRequest: false,
    });

    if (data) {
      this.initialStates = data.results;
      this.initialCountries = [
        {
          id: data.results[0].countryId,
          name: data.results[0].countryName,
        },
      ];

      this.setState({
        statesArray: this.initialStates,
        countriesArray: this.initialCountries,
      });
    }
  };

  handleSubmitForm = async (
    values,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) => {
    const accountFarmActions = this.props.accountFarmActions;
    const appActions = this.props.appActions;
    const userId = this.props.location.state.userId;
    const isFromAccountFarmModal =
      this.props.location.state.isFromAccountFarmModal;
    const plan = this.props.location.state?.plan;
    const due_day = this.props.location.state?.due_day;
    values.userId = userId;
    values.plan = plan;
    values.due_day = due_day;
    if (values.document.length > 14) {
      values.documentType = "CNPJ";
    }
    this.setState({ isLoadingRequest: true });

    try {
      if (isFromAccountFarmModal) {
        const {
          app: {
            groupSelected: { id: groupId },
            farmSelected: { id: farmId },
          },
        } = this.props;
        await updateAccountFarmTrialToContract({
          groupId,
          farmId,
          body: values,
        });
        accountFarmActions.resetData();
      } else {
        await createGroupAndFarm(values);
        appActions.hideModalGroupAndFarm();
      }
      notification.success({
        message: "Grupo e fazenda criados !",
      });
      this.setState({
        redirect: true,
      });
    } catch {
      this.setState({ isLoadingRequest: false });
      notification.error({
        message:
          "Houve um erro ao criar grupo e fazenda. Contate o suporte BovExo.",
      });
    }
  };

  handleZipCodeSearch = async (zipCode, props) => {
    if (zipCode.length === 9) {
      this.setState({
        loadingCep: true,
      });
      try {
        const {
          data: {
            erro,
            logradouro: street,
            uf: state,
            localidade: city,
            complemento: complementAddress,
          },
        } = await getCep(zipCode);
        if (erro) {
          this.setState({
            loadingCep: false,
            zipCodeValid: false,
          });
        } else {
          const states = this.initialStates.filter((s) => s.name === state);

          const { data } = await getCitiesByState(states[0].id);

          if (data) {
            const cities = data.results.filter(
              (c) => c.name.toLowerCase() === city.toLowerCase()
            );
            this.setState({
              citiesArray: data.results,
            });
            props.setFieldValue("city", cities[0].name);
          }
          props.setFieldValue("address", street);
          props.setFieldValue("state", state);
          props.setFieldValue("complement_address", complementAddress);
          props.setFieldValue("country", "Brasil");
          this.setState({
            loadingCep: false,
            zipCodeValid: true,
          });
        }
      } catch (error) {
        this.setState({
          loadingCep: false,
          zipCodeValid: false,
        });
      }
    }
  };

  handleCountryChange = (countryName) => {
    const country = this.initialCountries.filter((c) => c.name === countryName);
    const filteredStates = this.initialStates.filter(
      (s) => s.countryId === country[0].id
    );
    this.setState({ statesArray: filteredStates });
  };

  handleStateChange = async (stateName) => {
    const state = this.initialStates.filter((s) => s.name === stateName);
    const { data } = await getCitiesByState(state[0].id);
    if (data) {
      this.setState({ citiesArray: data.results });
    }
    this.formik.setFieldValue("city", undefined);
  };

  handleCalculateTotalArea = (props, field, currentValue) => {
    let {
      livestockArea,
      cultivationArea,
      improvementsArea,
      nonProductiveArea,
      rentAreaOutSourced,
    } = props.values;
    switch (field) {
      case "livestockArea":
        livestockArea = currentValue;
        break;
      case "cultivationArea":
        cultivationArea = currentValue;
        break;
      case "improvementsArea":
        improvementsArea = currentValue;
        break;
      case "nonProductiveArea":
        nonProductiveArea = currentValue;
        break;
      case "rentAreaOutSourced":
        rentAreaOutSourced = currentValue;
        break;
      default:
        break;
    }
    const totalArea =
      livestockArea +
      cultivationArea +
      improvementsArea +
      nonProductiveArea +
      rentAreaOutSourced;
    props.setFieldValue("areaTotal", totalArea);
  };

  handleLogout = async () => {
    const { appActions } = this.props;
    await appActions.logout_user(null);
    window.location.href = "/login";
  };

  render() {
    const {
      citiesArray,
      statesArray,
      countriesArray,
      form,
      loadingCep,
      zipCodeValid,
      redirect,
      isLoadingRequest,
    } = this.state;

    const validationSchema = Yup.object().shape({
      group: Yup.string().required(),
      companyName: Yup.string().required(),
      name: Yup.string().required(),
      document: Yup.string().required(),
      address: Yup.string().required(),
      city: Yup.string().required(),
      ownerName: Yup.string().required(),
    });

    const { Option } = Select;

    const { Group: RadioGroup } = Radio;

    if (redirect) {
      return <Redirect to="/" />;
    }

    return (
      <Wrapper>
        <span className="logo-vertical">
          <LogoVertical />
        </span>
        <Container>
          <RegisterContainer>
            <div>
              <DisplayBodyMessage>
                <span>CLIENTE</span>
                <span>{this.props.location?.state?.nome}</span>
                <span>{this.props.location?.state?.email}</span>
              </DisplayBodyMessage>
              <DisplayBox>
                <GroupIcon />
                <span>Grupos & fazendas</span>
              </DisplayBox>
            </div>
            <FooterSideBar>
              <button className="back-container" onClick={this.handleLogout}>
                <ArrowIcon className="arrow" />
                <span className="back">SAIR</span>
              </button>
              <LogoFooter>
                <LogoVertical className="logo" />
              </LogoFooter>
            </FooterSideBar>
          </RegisterContainer>
          <DisplayContainer>
            <DisplayBody>
              <FormContainer>
                <Spin spinning={isLoadingRequest}>
                  <Formik
                    ref={(ref) => (this.formik = ref)}
                    initialValues={form}
                    onSubmit={this.handleSubmitForm}
                    validationSchema={validationSchema}
                    render={(props) => (
                      <form onSubmit={props.handleSubmit} autoComplete="off">
                        <span className="title">Grupos & fazendas</span>
                        <span className="subTitle">
                          Complete os campos abaixo para inserir no sistema seu
                          primeiro grupo e fazenda
                        </span>

                        <div className="formFooter">
                          <ButtonStandard
                            type="submit"
                            buttonType="type1"
                            size="s"
                          >
                            Enviar
                          </ButtonStandard>
                        </div>

                        <Row
                          type="flex"
                          style={{ marginTop: 5, width: "100%", maxWidth: 400 }}
                          align="middle"
                        >
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row>
                              <label
                                className={
                                  props.errors.group && props.submitCount > 0
                                    ? "error"
                                    : ""
                                }
                              >
                                Grupo*
                              </label>
                            </Row>
                            <Row>
                              <Input
                                placeholder="Identificação de grupo"
                                value={props.values.group}
                                name="group"
                                onChange={(e) => {
                                  props.setFieldValue("group", e.target.value);
                                }}
                              />
                            </Row>
                          </Col>
                        </Row>

                        <DisplayBoxFarm>
                          <span className="title-green">
                            cadastro de fazenda
                          </span>

                          <Row
                            type="flex"
                            style={{
                              marginTop: 7,
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* Input document */}
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                              <Row>
                                <label
                                  className={
                                    props.errors.document &&
                                    props.submitCount > 0
                                      ? "error"
                                      : ""
                                  }
                                >
                                  CPF/CNPJ*
                                </label>
                              </Row>
                              <Row
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                              >
                                <DocumentInput
                                  kind="mixed"
                                  placeholder="CPF ou CNPJ"
                                  value={props.values.document}
                                  name="document"
                                  onChange={(e) => {
                                    let value = e.target.value
                                      ?.replace(/\./g, "")
                                      .replace(/\//g, "")
                                      .replace(/-/g, "");
                                    props.setFieldValue("document", value);
                                    props.setFieldValue(
                                      "documentType",
                                      value.length <= 11 ? "CPF" : "CNPJ"
                                    );
                                  }}
                                />
                              </Row>
                            </Col>
                            {/* Input CompanyName */}
                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                              <Row>
                                <label
                                  className={
                                    props.errors.companyName &&
                                    props.submitCount > 0
                                      ? "error"
                                      : ""
                                  }
                                >
                                  Razão Social*
                                </label>
                              </Row>
                              <Row
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                              >
                                <Input
                                  placeholder="Razão Social"
                                  value={props.values.companyName}
                                  name="companyName"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "companyName",
                                      e.target.value
                                    );
                                  }}
                                />
                              </Row>
                            </Col>
                            {/* Name */}
                            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                              <Row>
                                <label
                                  className={
                                    props.errors.name && props.submitCount > 0
                                      ? "error"
                                      : ""
                                  }
                                >
                                  Nome fantasia da fazenda*
                                </label>
                              </Row>
                              <Row
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                              >
                                <Input
                                  placeholder="Nome fantasia da fazenda"
                                  type="text"
                                  value={props.values.name}
                                  name="name"
                                  autoComplete="chrome-off"
                                  onChange={(e) => {
                                    props.setFieldValue("name", e.target.value);
                                  }}
                                />
                              </Row>
                            </Col>
                          </Row>
                          <Row
                            type="flex"
                            style={{
                              marginTop: 7,
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* Owner name */}
                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                              <Row>
                                <label
                                  className={
                                    props.errors.name && props.submitCount > 0
                                      ? "error"
                                      : ""
                                  }
                                >
                                  Nome do proprietário*
                                </label>
                              </Row>
                              <Row
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                              >
                                <Input
                                  placeholder="Nome do proprietário"
                                  type="text"
                                  maxLength={35}
                                  value={props.values.ownerName}
                                  name="ownerName"
                                  autoComplete="chrome-off"
                                  onChange={(e) => {
                                    props.setFieldValue("ownerName", e.target.value);
                                  }}
                                />
                              </Row>
                            </Col>
                          </Row>
                          {/* Boitel */}
                          <Row
                            type="flex"
                            style={{
                              marginTop: 7,
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* Input boitel */}
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <Row>
                                <label>É Boitel / Confinamento?</label>
                              </Row>
                              <Row>
                                <RadioGroup
                                  name="boitel"
                                  value={props.values?.boitel}
                                  defaultValue={false}
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "boitel",
                                      e.target.value
                                    );
                                    if (!e.target.value){
                                      props.setFieldValue("boitelVisibleToAllSystem", false);
                                    }
                                  }}
                                >
                                  <RadioButton value={true}>Sim</RadioButton>
                                  <RadioButton value={false}>Não</RadioButton>
                                </RadioGroup>
                              </Row>
                            </Col>
                          </Row>
                          {props.values?.boitel && (
                            <Row
                              type="flex"
                              style={{
                                marginTop: 7,
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                              align="middle"
                            >
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Row></Row>
                                <Row>
                                  <Checkbox
                                    name="boitelVisibleToAllSystem"
                                    onChange={() =>
                                      props.setFieldValue(
                                        "boitelVisibleToAllSystem",
                                        !props.values?.boitelVisibleToAllSystem
                                      )
                                    }
                                    style={{
                                      color: "#4B4B4B",
                                      fontWeight: "normal",
                                      fontSize: 14,
                                      textTransform: "none",
                                      marginTop: 10,
                                    }}
                                  >
                                    Assinale a caixa, caso você deseje aparecer na lista de Boitel - Diárias para todos usuários BovExo.
                                  </Checkbox>
                                </Row>
                              </Col>
                            </Row>
                          )}
                          <Divider
                            style={{
                              marginTop: 8,
                              marginBottom: 8,
                              color: "#c4c4c4",
                              fontSize: 13,
                              textTransform: "uppercase",
                            }}
                            dashed
                          >
                            <b>endereço da fazenda</b>
                          </Divider>

                          {/* ZipCode */}
                          <Row
                            type="flex"
                            style={{
                              marginTop: 7,
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <Col xs={24} sm={24} md={6} lg={4} xl={4}>
                              <Row>
                                <label
                                  className={
                                    props.errors.zipCode &&
                                    props.submitCount > 0
                                      ? "error"
                                      : ""
                                  }
                                >
                                  CEP
                                </label>
                              </Row>
                              <Row
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                              >
                                <ZipCodeInput
                                  placeholder="CEP"
                                  type="text"
                                  value={props.values.zipCode}
                                  name="zipCode"
                                  autoComplete="chrome-off"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "zipCode",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={(e) =>
                                    this.handleZipCodeSearch(
                                      e.target.value,
                                      props
                                    )
                                  }
                                />
                              </Row>
                            </Col>
                            <Col
                              xs={18}
                              sm={18}
                              md={18}
                              lg={20}
                              xl={20}
                              style={{ marginTop: 7 }}
                            >
                              {loadingCep === true ? (
                                <Row style={{ marginTop: 20 }}>
                                  <span
                                    style={{
                                      color: "#4b4b4b",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Icon
                                      type="loading"
                                      style={{ marginRight: 10 }}
                                    />
                                    Processando
                                  </span>
                                </Row>
                              ) : zipCodeValid != null &&
                                zipCodeValid === true ? (
                                <Row style={{ marginTop: 20 }}>
                                  <span
                                    style={{
                                      color: "#33C162",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Icon
                                      type="check-circle"
                                      style={{
                                        marginRight: 10,
                                        color: "#33C162",
                                        fontSize: 12,
                                      }}
                                    />
                                    CEP encontrado
                                  </span>
                                </Row>
                              ) : zipCodeValid != null &&
                                zipCodeValid === false ? (
                                <Row style={{ marginTop: 20 }}>
                                  <span
                                    style={{
                                      color: "#D44C4C",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Icon
                                      type="close-circle"
                                      style={{
                                        marginRight: 10,
                                        color: "#D44C4C",
                                        fontSize: 12,
                                      }}
                                    />
                                    CEP não encontrado
                                  </span>
                                </Row>
                              ) : null}
                            </Col>
                          </Row>

                          <Row
                            type="flex"
                            style={{
                              marginTop: 7,
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* Address */}
                            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                              <Row>
                                <label
                                  className={
                                    props.errors.address &&
                                    props.submitCount > 0
                                      ? "error"
                                      : ""
                                  }
                                >
                                  Endereço*
                                </label>
                              </Row>
                              <Row
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                              >
                                <Input
                                  placeholder="Endereço"
                                  value={props.values.address}
                                  name="address"
                                  autoComplete="chrome-off"
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "address",
                                      e.target.value
                                    );
                                  }}
                                />
                              </Row>
                            </Col>
                            {/* Country */}
                            <Col xs={24} sm={24} md={5} lg={4} xl={4}>
                              <Row>
                                <label>País</label>
                              </Row>
                              <Row
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                              >
                                <Select
                                  placeholder="País"
                                  value={
                                    props.values.country != null
                                      ? props.values.country
                                      : undefined
                                  }
                                  name="country"
                                  disabled={loadingCep || zipCodeValid}
                                  onChange={(value) => {
                                    props.setFieldValue("country", value);
                                    this.handleCountryChange(value);
                                  }}
                                >
                                  {countriesArray.length > 0 &&
                                    countriesArray.map((c) => (
                                      <Option key={c.id} value={c.name}>
                                        {c.name}
                                      </Option>
                                    ))}
                                </Select>
                              </Row>
                            </Col>
                            {/* State */}
                            <Col xs={24} sm={24} md={3} lg={2} xl={2}>
                              <Row>
                                <label>Estado</label>
                              </Row>
                              <Row
                                className="rowInput"
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                              >
                                <Select
                                  placeholder={"UF"}
                                  value={
                                    props.values.state != null
                                      ? props.values.state
                                      : undefined
                                  }
                                  name="state"
                                  disabled={
                                    loadingCep ||
                                    zipCodeValid ||
                                    props.values.country == null
                                  }
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  onChange={(value) => {
                                    props.setFieldValue("state", value);
                                    this.handleStateChange(value);
                                  }}
                                >
                                  {statesArray.length > 0 &&
                                    statesArray.map((state) => (
                                      <Option key={state.id} value={state.name}>
                                        {state.name}
                                      </Option>
                                    ))}
                                </Select>
                              </Row>
                            </Col>
                            {/* City */}
                            <Col xs={24} sm={24} md={6} lg={8} xl={8}>
                              <Row>
                                <label
                                  className={
                                    props.errors.city && props.submitCount > 0
                                      ? "error"
                                      : ""
                                  }
                                >
                                  Cidade*
                                </label>
                              </Row>
                              <Row
                                className="rowInput"
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                              >
                                <Select
                                  placeholder={"Cidade"}
                                  value={
                                    props.values.city != null
                                      ? props.values.city
                                      : undefined
                                  }
                                  name="city"
                                  disabled={
                                    loadingCep ||
                                    zipCodeValid ||
                                    props.values.state == null
                                  }
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  onChange={(value) => {
                                    props.setFieldValue("city", value);
                                  }}
                                >
                                  {citiesArray.length > 0 &&
                                    citiesArray.map((c) => (
                                      <Option
                                        key={Math.random()}
                                        value={c.name}
                                      >
                                        {c.name}
                                      </Option>
                                    ))}
                                </Select>
                              </Row>
                            </Col>
                          </Row>
                          {/* Complement */}
                          <Row
                            type="flex"
                            style={{
                              marginTop: 7,
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <Row>
                                <label>Complemento</label>
                              </Row>
                              <Row
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                              >
                                <Input
                                  placeholder="Utilize este campo para exemplificar como chegar em sua fazenda caso não exista sinalização..."
                                  value={props.values.complement_address}
                                  name="complement_address"
                                  maxLength={200}
                                  onChange={(e) => {
                                    props.setFieldValue(
                                      "complement_address",
                                      e.target.value
                                    );
                                  }}
                                />
                              </Row>
                            </Col>
                          </Row>

                          <Divider
                            style={{
                              marginTop: 8,
                              marginBottom: 8,
                              color: "#c4c4c4",
                              fontSize: 13,
                              textTransform: "uppercase",
                            }}
                            dashed
                          >
                            <b> área da fazenda </b>
                          </Divider>

                          <Row
                            type="flex"
                            style={{
                              marginTop: 7,
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                              <Row>
                                <label>Área total</label>
                              </Row>
                              <Row
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                              >
                                <HectareInput
                                  placeholder="Informe"
                                  value={props.values.areaTotal}
                                  name="areaTotal"
                                  disabled
                                />
                              </Row>
                            </Col>

                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                              <Row>
                                <label>Área útil</label>
                              </Row>
                              <Row
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                              >
                                <HectareInput
                                  placeholder="Informe"
                                  value={props.values.livestockArea}
                                  name="livestockArea"
                                  disabled={loadingCep}
                                  onValueChange={({ floatValue }) => {
                                    props.setFieldValue(
                                      "livestockArea",
                                      floatValue || 0
                                    );
                                    this.handleCalculateTotalArea(
                                      props,
                                      "livestockArea",
                                      floatValue || 0
                                    );
                                  }}
                                />
                              </Row>
                            </Col>

                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                              <Row>
                                <label>Área de cultivo</label>
                              </Row>
                              <Row
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                              >
                                <HectareInput
                                  placeholder="Informe"
                                  value={props.values.cultivationArea}
                                  name="cultivationArea"
                                  disabled={loadingCep}
                                  onValueChange={({ floatValue }) => {
                                    props.setFieldValue(
                                      "cultivationArea",
                                      floatValue || 0
                                    );
                                    this.handleCalculateTotalArea(
                                      props,
                                      "cultivationArea",
                                      floatValue || 0
                                    );
                                  }}
                                />
                              </Row>
                            </Col>
                          </Row>

                          <Row
                            type="flex"
                            style={{
                              marginTop: 7,
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                              <Row>
                                <label>Arrendada para terceiros</label>
                              </Row>
                              <Row
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                              >
                                <HectareInput
                                  placeholder="Informe"
                                  value={props.values.rentAreaOutSourced}
                                  name="rentAreaOutSourced"
                                  disabled={loadingCep}
                                  onValueChange={({ floatValue }) => {
                                    props.setFieldValue(
                                      "rentAreaOutSourced",
                                      floatValue || 0
                                    );
                                    this.handleCalculateTotalArea(
                                      props,
                                      "rentAreaOutSourced",
                                      floatValue || 0
                                    );
                                  }}
                                />
                              </Row>
                            </Col>

                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                              <Row>
                                <label>Não produtiva</label>
                              </Row>
                              <Row
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                              >
                                <HectareInput
                                  placeholder="Informe"
                                  value={props.values.nonProductiveArea}
                                  name="nonProductiveArea"
                                  disabled={loadingCep}
                                  onValueChange={({ floatValue }) => {
                                    props.setFieldValue(
                                      "nonProductiveArea",
                                      floatValue || 0
                                    );
                                    this.handleCalculateTotalArea(
                                      props,
                                      "nonProductiveArea",
                                      floatValue || 0
                                    );
                                  }}
                                />
                              </Row>
                            </Col>

                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                              <Row>
                                <label>Área de melhoria</label>
                              </Row>
                              <Row
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                              >
                                <HectareInput
                                  placeholder="Informe"
                                  value={props.values.improvementsArea}
                                  name="improvementsArea"
                                  disabled={loadingCep}
                                  onValueChange={({ floatValue }) => {
                                    props.setFieldValue(
                                      "improvementsArea",
                                      floatValue || 0
                                    );
                                    this.handleCalculateTotalArea(
                                      props,
                                      "improvementsArea",
                                      floatValue || 0
                                    );
                                  }}
                                />
                              </Row>
                            </Col>

                            <Row type="flex" align="middle">
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Checkbox
                                  onChange={() =>
                                    props.setFieldValue("clientCheck", true)
                                  }
                                  style={{
                                    color: "#4B4B4B",
                                    fontWeight: "normal",
                                    fontSize: 14,
                                    textTransform: "none",
                                    marginTop: 10,
                                  }}
                                >
                                  Assinale a caixa caso você deseje aparecer na
                                  lista de fornecedores e na lista de clientes
                                  nas vendas de animais de outros usuários
                                  Bovexo.
                                </Checkbox>
                              </Col>
                            </Row>
                          </Row>
                        </DisplayBoxFarm>
                      </form>
                    )}
                  />
                </Spin>
              </FormContainer>
            </DisplayBody>
          </DisplayContainer>
        </Container>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  accountFarmActions: bindActionCreators(AccountFarmActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterGroupAndFarm)
);
