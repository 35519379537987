/* Types & Action Creators */
export const Types = {
  RESET_DATA: "boitelSaleScenarioFarmSell/RESET_DATA",
  UPDATE_SELECT_ANIMALS: "boitelSaleScenarioFarmSell/UPDATE_SELECT_ANIMALS",
  SET_BOITEL_SALE_SCENARIO_FARM_SELL_SHOW_DATA:
    "boitelSaleScenarioFarmSell/SET_BOITEL_SALE_SCENARIO_FARM_SELL_SHOW_DATA",
};

export const Creators = {
  resetData: () => ({
    type: Types.RESET_DATA,
    payload: {},
  }),
  setBoitelSaleScenarioFarmSellShowData: (data) => ({
    type: Types.SET_BOITEL_SALE_SCENARIO_FARM_SELL_SHOW_DATA,
    payload: { data },
  }),
  updateSelectAnimals: (animals, action = "add") => ({
    type: Types.UPDATE_SELECT_ANIMALS,
    payload: {
      animals,
      action,
    },
  }),
};

/* Initial State */
const INITIAL_STATE = {
  isLoading: false,
  animalsSelected: localStorage.getItem(
    "@Bovexo:boitelSaleScenarioFarmSellsAnimals"
  )
    ? JSON.parse(
        localStorage.getItem("@Bovexo:boitelSaleScenarioFarmSellsAnimals")
      )
    : [],
  dataBoitelSaleScenarioFarmSell: {
    amountAnimalsSold: null,
    boitelSaleScenarioResultId: null,
    carcassHarnessing: null,
    clientName: null,
    createdAt: null,
    deletedAt: null,
    estimatedBilling: null,
    farmId: null,
    groupId: null,
    id: null,
    priceNegotiated: null,
    sellDate: null,
    totalBilling: null,
    totalMarginBilling: null,
    updatedAt: null,
    weightPerAnimal: null,
    animalsData: [],
  },
  error: null,
};

export default function boitelSaleScenarioFarmSell(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case Types.RESET_DATA:
      localStorage.removeItem("@Bovexo:boitelSaleScenarioFarmSellsAnimals");
      return { isLoading: false, animalsSelected: [], error: null };
    case Types.SET_BOITEL_SALE_SCENARIO_FARM_SELL_SHOW_DATA:
      return { ...state, dataBoitelSaleScenarioFarmSell: action.payload.data };
    case Types.UPDATE_SELECT_ANIMALS:
      switch (action.payload.action) {
        case "add":
          let animalsSelectedAdd = action.payload.animals;
          localStorage.setItem(
            "@Bovexo:boitelSaleScenarioFarmSellsAnimals",
            JSON.stringify(animalsSelectedAdd)
          );
          return {
            ...state,
            animalsSelected: animalsSelectedAdd,
          };
        case "remove":
          let animalsSelectedUpdated = state.animalsSelected.filter((as) => {
            if (
              action.payload.animals.filter(
                (removeAnimal) => removeAnimal.id === as.id
              ).length > 0
            ) {
              return false;
            } else {
              return true;
            }
          });
          localStorage.setItem(
            "@Bovexo:boitelSaleScenarioFarmSellsAnimals",
            JSON.stringify(animalsSelectedUpdated)
          );
          return {
            ...state,
            animalsSelected: animalsSelectedUpdated,
          };
        default:
          return { ...state };
      }
    default:
      return state;
  }
}
