import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CardCustom, Title, Footer } from "../../../styles";
import { Spin, Col, Input, Row, Radio, notification, Table, Select } from "antd";

import NumberFormat from "react-number-format";

import { Creators as CostCenterActions } from "../../../../../store/ducks/costCenter";

import ButtonStandard from "../../../../../components/utils/button";

import { indexAllWithItems } from "../../../../../services/resultCenterService";
import { findApportionmentInfo, saveApportionmentInfo } from "../../../../../services/costCenterService";

const initialStateForm = [
  {
    financialNatureId: null,
    financialNatureName: null,
    apportionmentType: null,
    resultCenters: [
      {
        resultCenterId: null,
        resultCenterItemId: null,
        resultCenterItemName: null,
        resultCenterItemValue: null,
      }
    ]
  }
];

const CostCenterItemApportionmentForm = () => {

  const {
    costCenter: { costCenterId, isApportionmentFormVisible, isLoadingApportionmentForm },
    app: {
      translation,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },

  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const { Group: RadioGroup } = Radio;
  const { Column } = Table;

  const [form, setForm] = useState(initialStateForm);
  const [errors, setErrors] = useState([]);

  const [isLoadingResultCenterList, setIsLoadingResultCenterList] = useState(false);
  const [selectedResultCenterId, setSelectedResultCenterId] = useState(null);
  const [resultCenterList, setResultCenterList] = useState([]);

  useEffect(() => {
    if (isApportionmentFormVisible) {
      setForm(initialStateForm);
    }
  }, [isApportionmentFormVisible]);

  // Fetch Data
  useEffect(() => {

    // fetch result center list
    async function fetchResultCenterList() {
      try {
        setIsLoadingResultCenterList(true);
        const { data: { results } } = await indexAllWithItems({ groupId, farmId });
        setResultCenterList(results);
        if (results?.length > 0) {
          setSelectedResultCenterId(results[0].id);
        }
      } catch (error) {
        console.error(error);
        notification.error({
          title: "Erro ao carregar",
          message: "Não foi possível carregar informações dos centros de resultado. Contate o suporte",
        });
      } finally {
        setIsLoadingResultCenterList(false);
      }
    }

    // Fetch cost center data
    async function fetchCostCenterData() {
      try {
        dispatch(CostCenterActions.setIsLoadingApportionmentForm(true));
        const { data: { results } } = await findApportionmentInfo({ groupId, farmId, id: costCenterId });

        setForm(results);

      } catch (error) {
        console.error(error)
        notification.error({
          title: "Erro ao carregar",
          message: "Não foi possível carregar informações do centro de custo. Contate o suporte"
        });
      } finally {
        dispatch(CostCenterActions.setIsLoadingApportionmentForm(false));
      }
    }

    if (costCenterId !== null) {
      fetchResultCenterList();
      fetchCostCenterData();
    }
  }, [groupId, farmId, costCenterId, dispatch]);

  const closeForm = () => {
    dispatch(CostCenterActions.showOrHideApportionmentForm(costCenterId));
  }

  const handleSubmit = async () => {
    if (validateForm()) {
      const body = [...form];

      try {
        dispatch(CostCenterActions.setIsLoadingApportionmentForm(true));
        await saveApportionmentInfo({ groupId, farmId, id: costCenterId, body });
        notification.success({ message: "Rateio atualizado com sucesso" });
        
        dispatch(CostCenterActions.index(groupId, farmId));
        dispatch(CostCenterActions.showOrHideApportionmentForm(null));
      } catch (error) {
        notification.error({
          title: "Erro ao salvar",
          message: "Não foi possível atualizar o rateio. Contate o suporte"
        });
      } finally {
        dispatch(CostCenterActions.setIsLoadingApportionmentForm(false));
      }
    }
  };

  function validateForm() {

    let aErrors = [];
    form.forEach(f => {
      resultCenterList.forEach(rc => {
        let resultCenterValues = f.resultCenters.filter(rci => rci.resultCenterId === rc.id)
        
        if(f.apportionmentType === "percentage") {
          let totalValues = +(resultCenterValues?.reduce((sumValue, currentObj) => sumValue + (currentObj.resultCenterItemValue || 0), 0).toFixed(2))

          if(totalValues > 0 && totalValues !== 100) {
            aErrors.push(f.costCenterItemName);
          }
        }
      });
    });

    setErrors(aErrors)
    return aErrors.length > 0 ? false : true;
  }

  // Update form with user input
  function handleNatureApportionmentTypeChange(newType, record) {
    const formValue = [...form];
    formValue.find(fv => fv.costCenterItemId === record.costCenterItemId).apportionmentType = newType;
    setForm(formValue);
  }

  // Update form with user input
  function handleChangeValue(resultCenterItemId, floatValue, costCenterItemId) {
    let value = floatValue;
    if(floatValue > 100) {
      value = 100;
    } else if(floatValue < 0) {
      value = 0
    }

    const formValue = [...form];
    const itemRow = formValue.find(fv => fv.costCenterItemId === costCenterItemId);
    itemRow.resultCenters.find(rci => rci.resultCenterItemId === resultCenterItemId).resultCenterItemValue = value;
    setForm(formValue);
  }

  const handleRenderSelectResultCenter = () => {
    return (
      <>
        <Row>
          <label>
            Centro de Resultado
          </label>
        </Row>
        <Row>
          <Select
            name="resultCenterId"
            value={selectedResultCenterId}
            placeholder={translation.defaultSelectPlaceholder}
            onChange={(value) =>
              setSelectedResultCenterId(value)
            }
            loading={isLoadingResultCenterList}
          >
            {resultCenterList.map((rc) => (
              <Select.Option key={rc.name} value={rc.id}>
                {rc.name}
              </Select.Option>
            ))}
          </Select>
        </Row>
      </>
    );
  }

  const handleRenderColumns = () => {
    let selectedResultCenter = resultCenterList.find(rc => rc.id === selectedResultCenterId);
    if (selectedResultCenter) {

      return selectedResultCenter.items.map(rci => (
        <Column
          title={rci.name}
          key={rci.id}
          align="left"
          render={(i, record) => {
            if(record.apportionmentType === "percentage") {
              return (
                <NumberFormat
                name="value"
                customInput={Input}
                value={record.resultCenters.find(rec => rec.resultCenterItemId === rci.id)?.resultCenterItemValue}
                decimalScale={2}
                decimalSeparator=","
                thousandSeparator="."
                maxLength={record.apportionmentType === "percentage" ? 6 : 10}
                fixedDecimalScale={true}
                addonAfter={record.apportionmentType === "percentage" ? "%" : null}
                placeholder={translation.defaultPlaceholder}
                onValueChange={
                  ({ floatValue }) => {
                    handleChangeValue(rci.id, floatValue, record.costCenterItemId)
                  }}
              />
              );
            } else {
              return (<span>Automático</span>);
            }
          }}
        />
      ));
    }
  }

  return (
    <CardCustom className="grow-card">
      <Spin spinning={isLoadingApportionmentForm}>
        <Row type="flex" justify="start" align="middle" className="rowHeader">
          <Title>{translation.financial.parameters.costCenter.apportionmentForm.title}</Title>
        </Row>

        <Row>
          <Table
            rowKey="costCenterItemId"
            loading={isLoadingApportionmentForm}
            dataSource={form !== null ? form : []}
            pagination={false}
            size="small"
            title={() => handleRenderSelectResultCenter()}
            >

            <Column
              title="Classe"
              dataIndex="financialClassName"
              key="financialClassName"
              align="left"
              render={(name) => (<label className={errors.includes(name) ? "error" : ""}>{name}</label>)}
            />
            
            <Column
              title="Nome"
              dataIndex="costCenterItemName"
              key="costCenterItemName"
              align="left"
              render={(name) => (<label className={errors.includes(name) ? "error" : ""}>{name}</label>)}
            />

            <Column
              title="Rateio"
              dataIndex="apportionmentType"
              key="apportionmentType"
              align="left"
              render={(type, record) => {
                return (<RadioGroup
                  name="apportionmentType"
                  value={type}
                  onChange={(e) => {
                    handleNatureApportionmentTypeChange(e.target.value, record)
                  }}
                >
                  <Radio className="table-row-radio-label" value="numberOfAnimals"># Animais</Radio>
                  <Radio className="table-row-radio-label" value="area">Área</Radio>
                  <Radio className="table-row-radio-label" value="percentage">%</Radio>
                </RadioGroup>)
              }}
            />

            {/* Render result center columns */}
            {handleRenderColumns()}

          </Table>

        </Row>
        {/* Footer */}
        <Footer>
          <Row type="flex">
            <Col span={24} className="buttonsDiv">
              <ButtonStandard
                type="button"
                buttonType="type7"
                onClick={closeForm}
              >
                {translation.buttons.cancel}
              </ButtonStandard>

              <ButtonStandard
                type="button"
                buttonType="type6"
                onClick={handleSubmit}
              >
                {translation.buttons.save}
              </ButtonStandard>
            </Col>
          </Row>
        </Footer>
      </Spin>
    </CardCustom >
  );
}

export default CostCenterItemApportionmentForm;