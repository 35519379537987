import React, { Component } from "react";
import CanvasJSReact from "../../../assets/canvasjs.react";
import * as Yup from "yup";
import { Formik } from "formik";
/** Redux  */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as CurveActions } from "../../../store/ducks/curve";
import { Creators as AnimalActions } from "../../../store/ducks/animal";
/** Components */
import { Row, Col, Select, Radio, Spin, notification, Empty } from "antd";
import ButtonStandard from "../../../components/utils/button";
import {
  Container,
  Footer,
  CustomDivider,
  ChartContainer,
  LotCurveParameterCard,
} from "./styles";
import NoteIcon from "../../../components/utils/icons/note";
/** Services */
import { getBreedCurveParam } from "../../../services/breedService";
import { setAnimalEstimatedCurve } from "../../../services/estimatedWeightCurveService";
import { calculateCurve } from "../../../services/curveService";
import { getTwoDecimalDigits } from "../../../services/helpersMethodsService";

class DrawerAnimalCurveParameter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDefault: true,
      saveOptions: "only_save",
      breedId: null,
      chartOptions: {
        animationEnabled: true,
        zoomEnabled: true,
        height: 300,
        axisX: {
          maximum: 830,
          interval: 210,
          intervalType: "number",
          crosshair: {
            enabled: true,
            snapToDataPoint: true,
          },
          stripLines: [
            {
              value: 210,
              label: "7 meses",
              labelPlacement: "outside", //"outside",
              lineDashType: "dashDot",
              color: "#4b4b4b",
              labelFontColor: "#4b4b4b",
              labelBackgroundColor: "#fff",
            },
            {
              value: 420,
              label: "14 meses",
              labelPlacement: "outside", //"outside",
              lineDashType: "dashDot",
              color: "#4b4b4b",
              labelFontColor: "#4b4b4b",
              labelBackgroundColor: "#fff",
            },
            {
              value: 630,
              label: "21 meses",
              labelPlacement: "outside", //"outside",
              lineDashType: "dashDot",
              color: "#4b4b4b",
              labelFontColor: "#4b4b4b",
              labelBackgroundColor: "#fff",
            },
            {
              value: 800,
              label: "32 meses",
              labelPlacement: "outside", //"outside",
              lineDashType: "dashDot",
              color: "#4b4b4b",
              labelFontColor: "#4b4b4b",
              labelBackgroundColor: "#fff",
            },
          ],
        },
        toolTip: {
          fontFamily: "Asap",
          shared: true,
        },
        axisY: {
          includeZero: true,
          valueFormatString: "###0.##",
          suffix: "kg",
          gridDashType: "dot",
        },
        legend: {
          cursor: "pointer",
          itemclick: (e) => {
            if (
              typeof e.dataSeries.visible === "undefined" ||
              e.dataSeries.visible
            ) {
              e.dataSeries.visible = false;
            } else {
              e.dataSeries.visible = true;
            }
            e.chart.render();
          },
        },
        data: [],
      },
      asymptoticWeight: null,
      breedCurveParams: null,
      curveSelected: {
        geneticCategory: "GT",
        handlingCategory: "DP",
        theoreticalCurveType: "Logistic",
      },
      isLoadingChart: true,
    };
  }

  closeDrawer = () => {
    const { curveActions } = this.props;
    this.handleResetState();
    curveActions.showOrHideAnimalCurveDrawer();
  };

  handleSubmitModalForm = async (values, actions) => {
    const {
      curveSelected: {
        theoreticalCurveType,
        geneticCategory,
        handlingCategory,
      },
      breedCurveParams,
    } = this.state;
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      animal: {
        animalData: { id, birthday },
      },
      curveActions,
      animalActions,
    } = this.props;

    if (
      breedCurveParams.find(
        (bcp) =>
          geneticCategory === bcp.geneticCategory &&
          handlingCategory === bcp.handlingCategory &&
          theoreticalCurveType === bcp.theoreticalCurveType
      ) != null
    ) {
      values.breedCurveParam = breedCurveParams.find(
        (bcp) =>
          geneticCategory === bcp.geneticCategory &&
          handlingCategory === bcp.handlingCategory &&
          theoreticalCurveType === bcp.theoreticalCurveType
      );
      const body = {
        asymptoticWeight: values.asymptoticWeight,
        breedCurveParam: {
          id: values.breedCurveParam.id,
        },
      };

      try {
        curveActions.showOrHideAnimalCurveDrawer();
        curveActions.startLoading();
        setAnimalEstimatedCurve({
          groupId,
          farmId,
          id,
          body,
        })
          .then(async ({ data: { results: curve } }) => {
            curveActions.saveAnimalCurve(curve);
            const result = calculateCurve(
              curve.asymptoticWeight,
              birthday,
              curve.breedCurveParam.correctionFactor,
              curve.breedCurveParam.maturationRate,
              curve.breedCurveParam.theoreticalCurveType
            );
            curveActions.saveAnimalCalculateCurve(result);
            curveActions.stopLoading();
            notification.success({
              message: "Curva estimada de crescimento salva.",
            });
            animalActions.refreshAnimalData({
              asymptoticWeight: curve.asymptoticWeight,
              customAsymptoticWeight: true,
            });
            this.handleResetState();
          })
          .catch((error) => {
            curveActions.stopLoading();
            notification.error({
              message:
                "Houve um erro ao salvar a curva estimada de crescimento.",
            });
            this.handleResetState();
          });
      } catch (error) {}
    }
  };

  handleChangeAsymptoticWeight = (value) => {
    const { breedCurveParams } = this.state;
    this.setState({ asymptoticWeight: value });
    this.handleCalculateCurves(breedCurveParams, value);
  };

  handleChangeCurveSelected = (field, value) => {
    const { breedCurveParams, asymptoticWeight } = this.state;
    this.setState(
      {
        isLoadingChart: true,
        curveSelected: {
          ...this.state.curveSelected,
          [field]: value,
        },
      },
      () => this.handleCalculateCurves(breedCurveParams, asymptoticWeight)
    );
  };

  handleCalculateCurves = (breedCurveParams, asymptoticWeight) => {
    const {
      curveSelected: {
        theoreticalCurveType,
        geneticCategory,
        handlingCategory,
      },
    } = this.state;

    let result = [];

    breedCurveParams
      .filter((bcp) => bcp.theoreticalCurveType === theoreticalCurveType)
      .forEach((bcp) => {
        let day = 1;
        let dataChart = {
          type: "line",
          name: `${bcp.geneticCategory} - ${bcp.handlingCategory}`,
          showInLegend: true,
          markerType: "none",
          markerSize: 5,
          yValueFormatString: "###0.## kg",
          lineDashType:
            geneticCategory === bcp.geneticCategory &&
            handlingCategory === bcp.handlingCategory
              ? "solid"
              : "dot",
          color:
            geneticCategory === bcp.geneticCategory &&
            handlingCategory === bcp.handlingCategory
              ? "#A9C133"
              : "#e8e5e5",
          dataPoints: [],
        };
        switch (theoreticalCurveType) {
          case "Logistic":
            do {
              dataChart.dataPoints.push({
                x: day,
                y: Number.parseFloat(
                  Number(
                    1.09441891891892 *
                      (asymptoticWeight /
                        (1 +
                          bcp.correctionFactor *
                            Math.exp(bcp.maturationRate * day)))
                  ).toFixed(2)
                ),
              });

              day++;
            } while (day <= 800);
            break;
          case "Brody":
            do {
              dataChart.dataPoints.push({
                x: day,
                y: Number.parseFloat(
                  Number(
                    1.21843243243243 *
                      (asymptoticWeight *
                        (1 -
                          bcp.correctionFactor *
                            Math.exp(-bcp.maturationRate * day)))
                  ).toFixed(2)
                ),
              });
              day++;
            } while (day <= 800);
            break;
          case "VonBertalanffy":
            do {
              dataChart.dataPoints.push({
                x: day,
                y: Number.parseFloat(
                  Number(
                    1.29818918918919 *
                      (asymptoticWeight *
                        Math.pow(
                          1 -
                            bcp.correctionFactor *
                              Math.exp(-bcp.maturationRate * day),
                          3
                        ))
                  ).toFixed(2)
                ),
              });
              day++;
            } while (day <= 800);
            break;
          default:
            break;
        }
        result.push(dataChart);
      });
    this.setState({
      isLoadingChart: false,
      chartOptions: {
        ...this.state.chartOptions,
        data: result,
      },
    });
  };

  handleVisibleDrawer = (visible) => {
    if (visible) {
      const {
        curve: { animalCurveData, lotCurveData },
        animal: { animalData },
      } = this.props;
      let weight = null;
      if (animalCurveData != null && animalCurveData.breedCurveParam != null) {
        weight = animalCurveData.asymptoticWeight;
        this.setState({
          isDefault: animalCurveData.default,
          asymptoticWeight:
            weight == null ? animalCurveData.asymptoticWeight : weight,
          curveSelected: {
            geneticCategory: animalCurveData.breedCurveParam.geneticCategory,
            handlingCategory: animalCurveData.breedCurveParam.handlingCategory,
            theoreticalCurveType:
              animalCurveData.breedCurveParam.theoreticalCurveType,
          },
        });
      } else {
        weight = lotCurveData.asymptoticWeight;
        this.setState({
          isDefault: true,
          asymptoticWeight:
            weight == null ? lotCurveData.asymptoticWeight : weight,
          curveSelected: {
            geneticCategory: lotCurveData.breedCurveParam.geneticCategory,
            handlingCategory: lotCurveData.breedCurveParam.handlingCategory,
            theoreticalCurveType:
              lotCurveData.breedCurveParam.theoreticalCurveType,
          },
        });
      }
      if (animalData != null) {
        try {
          getBreedCurveParam({
            id: animalData.breedId,
          }).then(({ data: { results: breedCurveParams } }) => {
            this.setState({
              breedCurveParams,
              asymptoticWeight:
                weight == null ? animalData.asymptoticWeight : weight,
            });
            if (weight == null) {
              weight = animalData.asymptoticWeight;
            }
            this.handleCalculateCurves(breedCurveParams, weight);
          });
        } catch (error) {}
      } else {
      }
    }
  };

  handleResetState = () => {
    this.setState({
      isDefault: true,
      saveOptions: "only_save",
      breedId: null,
      chartOptions: {
        animationEnabled: true,
        zoomEnabled: true,
        height: 300,
        axisX: {
          maximum: 830,
          interval: 210,
          intervalType: "number",
          crosshair: {
            enabled: true,
            snapToDataPoint: true,
          },
          stripLines: [
            {
              value: 210,
              label: "7 meses",
              labelPlacement: "outside", //"outside",
              lineDashType: "dashDot",
              color: "#4b4b4b",
              labelFontColor: "#4b4b4b",
              labelBackgroundColor: "#fff",
            },
            {
              value: 420,
              label: "14 meses",
              labelPlacement: "outside", //"outside",
              lineDashType: "dashDot",
              color: "#4b4b4b",
              labelFontColor: "#4b4b4b",
              labelBackgroundColor: "#fff",
            },
            {
              value: 630,
              label: "21 meses",
              labelPlacement: "outside", //"outside",
              lineDashType: "dashDot",
              color: "#4b4b4b",
              labelFontColor: "#4b4b4b",
              labelBackgroundColor: "#fff",
            },
            {
              value: 800,
              label: "32 meses",
              labelPlacement: "outside", //"outside",
              lineDashType: "dashDot",
              color: "#4b4b4b",
              labelFontColor: "#4b4b4b",
              labelBackgroundColor: "#fff",
            },
          ],
        },
        toolTip: {
          fontFamily: "Asap",
          shared: true,
        },
        axisY: {
          includeZero: true,
          valueFormatString: "###0.##",
          suffix: "kg",
          gridDashType: "dot",
        },
        legend: {
          cursor: "pointer",
          itemclick: (e) => {
            if (
              typeof e.dataSeries.visible === "undefined" ||
              e.dataSeries.visible
            ) {
              e.dataSeries.visible = false;
            } else {
              e.dataSeries.visible = true;
            }
            e.chart.render();
          },
        },
        data: [],
      },
      asymptoticWeight: null,
      breedCurveParams: null,
      curveSelected: {
        geneticCategory: "GT",
        handlingCategory: "DP",
        theoreticalCurveType: "Logistic",
      },
      isLoadingChart: true,
    });
  };

  render() {
    const {
      app: { translation },
      curve: {
        showDrawerAnimalCurve: drawerParametersVisible,
        animalCurveData,
        lotCurveData,
      },
    } = this.props;

    const {
      curveSelected,
      chartOptions,
      isLoadingChart,
      isDefault,
    } = this.state;

    const validationSchema = Yup.object().shape({
      asymptoticWeight: Yup.number().required(),
    });

    const { Option } = Select;
    const { CanvasJSChart } = CanvasJSReact;

    return (
      <Container
        title="Parâmetros de animal"
        width={650}
        onClose={this.closeDrawer}
        maskClosable={false}
        visible={drawerParametersVisible}
        afterVisibleChange={this.handleVisibleDrawer}
      >
        {animalCurveData === null ? (
          <Empty description="O lote e raça predominante não contem curvas, por favor cadastrar uma curva em Parêmtros -> Raças." />
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={
              animalCurveData.breedCurveParam === null
                ? lotCurveData
                : animalCurveData
            }
            onSubmit={this.handleSubmitModalForm}
            validationSchema={validationSchema}
            render={(props) => (
              <form onSubmit={props.handleSubmit} autoComplete="off">
                <div className="drawerForm">
                  <Row type="flex">
                    <span className="message">
                      Defina os parâmetros do lote
                    </span>
                  </Row>
                  <Row type="flex" justify="start" style={{ marginTop: 13 }}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Row>
                        <label className="title">
                          {translation.breed.table.columns.name}
                        </label>
                      </Row>
                      <Row>
                        <label className="subTitle">
                          {props.values.breedCurveParam != null &&
                            props.values.breedCurveParam.breed.name}
                        </label>
                      </Row>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Row>
                        <label className="title">
                          {translation.breed.table.columns.geneticGroup}
                        </label>
                      </Row>
                      <Row>
                        <label className="subTitle">
                          {props.values.breedCurveParam != null &&
                            props.values.breedCurveParam.breed.geneticGroup}
                        </label>
                      </Row>
                    </Col>
                  </Row>
                  <CustomDivider dashed>
                    {translation.breed.formParameters.extimatedCurveTitle}
                  </CustomDivider>
                  <Spin spinning={isLoadingChart}>
                    {Object.entries(props.errors).length > 0 &&
                      props.submitCount > 0 && (
                        <Row type="flex" justify="center" align="middle">
                          <label className="error">
                            {translation.error.formError}
                          </label>
                        </Row>
                      )}
                    <Row type="flex" justify="start">
                      <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                        <Row>
                          <label
                            className={
                              props.values.entity.asymptoticWeight === null
                                ? "withoutMarginLeft default"
                                : "withoutMarginLeft"
                            }
                          >
                            {translation.breed.formParameters.asymptoticWeight}*
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <strong className="customStrong">
                            {`${getTwoDecimalDigits(
                              props.values.asymptoticWeight
                            )} kg`}
                          </strong>
                        </Row>
                      </Col>
                      <Col xs={14} sm={14} md={14} lg={14} xl={14} offset={2}>
                        <Row>
                          <label className={isDefault ? "default" : ""}>
                            {translation.breed.formParameters.theoricalCurve} *
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <Select
                            value={curveSelected.theoreticalCurveType}
                            onChange={(value) =>
                              this.handleChangeCurveSelected(
                                "theoreticalCurveType",
                                value
                              )
                            }
                          >
                            <Option value="Brody">Brody</Option>
                            <Option value="Logistic">Logistic</Option>
                            <Option value="VonBertalanffy">
                              Von-Bertalanffy
                            </Option>
                          </Select>
                        </Row>
                      </Col>
                    </Row>
                    <Row
                      type="flex"
                      className="cardGrey"
                      justify="start"
                      align="middle"
                    >
                      <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                        <label className={isDefault ? "default" : ""}>
                          {translation.breed.formParameters.genetic}
                        </label>
                      </Col>
                      <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                        <Radio.Group
                          value={curveSelected.geneticCategory}
                          onChange={(e) =>
                            this.handleChangeCurveSelected(
                              "geneticCategory",
                              e.target.value
                            )
                          }
                        >
                          <Radio value="GT">Tauríno</Radio>
                          <Radio value="GZ">Zebuíno</Radio>
                          <Radio value="GC">Cruzado</Radio>
                        </Radio.Group>
                      </Col>
                    </Row>
                    <Row
                      type="flex"
                      className="cardGrey"
                      justify="start"
                      align="middle"
                    >
                      <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                        <label className={isDefault ? "default" : ""}>
                          {translation.breed.formParameters.diet}
                        </label>
                      </Col>
                      <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                        <Radio.Group
                          value={curveSelected.handlingCategory}
                          onChange={(e) =>
                            this.handleChangeCurveSelected(
                              "handlingCategory",
                              e.target.value
                            )
                          }
                        >
                          <Radio value="DP">Premium</Radio>
                          <Radio value="DS">Superior</Radio>
                          <Radio value="DC">Comum</Radio>
                        </Radio.Group>
                      </Col>
                    </Row>
                    <ChartContainer>
                      <CanvasJSChart options={chartOptions} />
                    </ChartContainer>
                    {isDefault && (
                      <LotCurveParameterCard>
                        <div className="header">
                          <span className="title">
                            <NoteIcon />
                            Os títulos em laranja indicam que os valores
                            atribuídos são obtidos do lote que o animal pertence
                          </span>
                        </div>
                      </LotCurveParameterCard>
                    )}
                  </Spin>
                </div>
                {/* Footer */}
                <Footer>
                  <Row type="flex" justify="space-between">
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="selectDiv"
                    ></Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="buttonsDiv"
                    >
                      <ButtonStandard
                        type="button"
                        buttonType="type7"
                        onClick={this.closeDrawer}
                      >
                        {translation.buttons.cancel}
                      </ButtonStandard>

                      <ButtonStandard type="submit" buttonType="type6">
                        {translation.buttons.save}
                      </ButtonStandard>
                    </Col>
                  </Row>
                </Footer>
              </form>
            )}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  breed: state.breed,
  curve: state.curve,
  animal: state.animal,
});

const mapDispatchToProps = (dispatch) => ({
  curveActions: bindActionCreators(CurveActions, dispatch),
  animalActions: bindActionCreators(AnimalActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrawerAnimalCurveParameter);
