import styled from "styled-components";
import { Card } from "antd";

export const Container = styled.div`
  padding: 14px 0 0 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
/*
  @media screen and (max-height: 700px) {
    height: 440px;
    overflow-y: auto;
  } */
`;

export const Body = styled.div`
  width: 757px;
  max-width: 757px;
  margin-top: 34px;
  margin-bottom: 10px;
  height: 100%;
  overflow-y: auto;

  @media screen and (max-height: 700px) {
    height: 440px;
    overflow-y: auto;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const ButtonApplyDefaultValues = styled.button`
  font-family: Asap;
  font-weight: bold;
  font-size: 12px;
  text-align: left;
  color: #4a85ae;
  border: none;
  background: none;
  svg {
    margin-right: 5px;
  }
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const GreyCard = styled(Card)`
  margin-top: 20px !important;
  border-radius: 5px !important;
  background: #f5f5f5 !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
`;

export const Title = styled.span`
  font-family: "Asap";
  font-weight: bold;
  font-style: italic;
  font-size: 16px;
  text-align: left;
  color: #4b4b4b;
`;

export const EditButton = styled.button`
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #9386aa;
  border: none;
  background: none;

  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
    path {
      fill: #9386aa !important;
    }
  }

  &:hover {
    color: #a9c133;
    cursor: pointer;

    svg {
      margin-left: 5px;
      path {
        fill: #a9c133 !important;
      }
    }
  }

  &:disabled {
    color: #a5a5a5;
    cursor: not-allowed;
    svg {
      margin-left: 5px;
      path {
        fill: #a5a5a5 !important;
      }
    }
  }
`;

export const Message = styled.span`
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #a5a5a5;
  .red {
    color: #d44c4c;
  }
`;

export const CardMessage = styled(Card)`
  margin-top: 10px !important;
  border-radius: 5px !important;
  background: #fff !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
`;
