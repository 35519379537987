export const Types = {
  RESET_DATA: "reproductionPeriod/RESET_DATA",
  GET_ALL_DATA: "reproductionPeriod/GET_ALL_DATA",
  GET_ALL_DATA_SUCCESS: "reproductionPeriod/GET_ALL_DATA_SUCCESS",
  GET_ALL_DATA_ERROR: "reproductionPeriod/GET_ALL_DATA_ERROR",
  SHOW_OR_HIDE_DRAWER: "reproductionPeriod/SHOW_OR_HIDE_DRAWER",

  GET_ALL_DROPDOWN: "reproductionPeriod/GET_ALL_DROPDOWN",
  GET_ALL_DROPDOWN_SUCCESS: "reproductionPeriod/GET_ALL_DROPDOWN_SUCCESS",
  GET_ALL_DROPDOWN_ERROR: "reproductionPeriod/GET_ALL_DROPDOWN_ERROR",
};

export const Creators = {
  getAllData: (groupId, farmId, page = 0, sorter = null, filters = null, size = 10,) => ({
    type: Types.GET_ALL_DATA,
    payload: {
      groupId,
      farmId,
      page,
      sorter,
      filters,
      size,
    },
  }),
  getAllDataSuccess: (data) => ({
    type: Types.GET_ALL_DATA_SUCCESS,
    payload: {
      data,
    },
  }),
  getAllDataError: (error) => ({
    type: Types.GET_ALL_DATA_ERROR,
    payload: {
      error,
    },
  }),
  showOrHideDrawer: (id = null) => ({
    type: Types.SHOW_OR_HIDE_DRAWER,
    payload: {
      id
    },
  }),
};

const INITIAL_STATE = {
  data: [],
  isLoading: true,
  isDrawerVisible: false,
  reproductionPeriodId: null,
  pagination: {
    page: 0,
    sorter: null,
    filters: null,
    size: 10,
  },
};

export default function reproductionPeriod(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_ALL_DATA:
      const { page, sorter, filters, size } =
        action.payload;
      return {
        ...state,
        pagination: {
          page,
          sorter,
          filters,
          size,
        }, isLoading: true
      };
    case Types.GET_ALL_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    case Types.GET_ALL_DATA_ERROR:
      return { ...state, isLoading: false };
    case Types.SHOW_OR_HIDE_DRAWER:
      return { ...state, reproductionPeriodId: action.payload.id, isDrawerVisible: !state.isDrawerVisible };
    default:
      return state;
  }
}
