import React from "react";
import useFinancialTransactionDashboardContext from "../../../../../hooks/useFinancialTransactionDashboardContext";

import { Container, Grid } from "./styles";
import { Col, Row } from "antd";

import { numberMask } from "../../../../../services/helpersMethodsService";
import { useCurrencyContext } from "../../../../../hooks/useCurrencyContext";

function CardAccountsPlayableAndReceivable() {
  const { dashboardResult } = useFinancialTransactionDashboardContext();
  const { defaultCurrencyCode } = useCurrencyContext();
  return (
    <Container>
      <Grid>
        <Row type="flex" className="header">
          <Col span={24}>
            <strong>CONTAS A PAGAR / RECEBER</strong>
          </Col>
        </Row>
        {/* SALDO ATUAL */}
        <Row type="flex" className="grid-row">
          <Col className="grid-row-col-title" span={12}>
            <strong>SALDO ATUAL</strong>
          </Col>
          <Col className="grid-row-col-value" span={12}>
            <strong>-</strong>
          </Col>
        </Row>
        {/* VALOR VENCIDO */}
        <Row type="flex" className="grid-row sub">
          <Col className="grid-row-col-title" span={12}>
            <strong>VALOR VENCIDO</strong>
          </Col>
          <Col className="grid-row-col-value" span={12}>
            <strong>
              {numberMask(
                dashboardResult.totalPastDueAmount,
                true,
                defaultCurrencyCode
              )}
            </strong>
          </Col>
        </Row>
        {/* VALOR PAGTO PARCIAL */}
        <Row type="flex" className="grid-row sub">
          <Col className="grid-row-col-title" span={12}>
            <strong>VALOR PAGTO PARCIAL</strong>
          </Col>
          <Col className="grid-row-col-value" span={12}>
            <strong>
              {numberMask(
                dashboardResult.totalPartialPayment,
                true,
                defaultCurrencyCode
              )}
            </strong>
          </Col>
        </Row>
        {/* VALOR A VENCER */}
        <Row type="flex" className="grid-row sub">
          <Col className="grid-row-col-title" span={12}>
            <strong>VALOR A VENCER</strong>
          </Col>
          <Col className="grid-row-col-value" span={12}>
            <strong>
              {numberMask(
                dashboardResult.totalValueToBeWon,
                true,
                defaultCurrencyCode
              )}
            </strong>
          </Col>
        </Row>
        {/* ENTRADAS PREVISTAS */}
        <Row type="flex" className="grid-row">
          <Col className="grid-row-col-title" span={12}>
            <strong>ENTRADAS PREVISTAS</strong>
          </Col>
          <Col className="grid-row-col-value" span={12}>
            <strong>
              {numberMask(
                dashboardResult.totalPredictedEntries,
                true,
                defaultCurrencyCode
              )}
            </strong>
          </Col>
        </Row>
        {/* BALANÇO PERÍODO */}
        <Row type="flex" className="grid-row">
          <Col className="grid-row-col-title" span={12}>
            <strong>BALANÇO PERÍODO</strong>
          </Col>
          <Col className="grid-row-col-value" span={12}>
            <strong>-</strong>
          </Col>
        </Row>
      </Grid>
    </Container>
  );
}

export default CardAccountsPlayableAndReceivable;
