import styled from "styled-components";
import { Modal, Collapse } from "antd";

export const Container = styled(Modal)`
  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;

    &.error {
      color: #d44c4c;
    }
  }

  .ant-row > label {
    display: inline-block;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked {
    color: #fff !important;
  }

  .rowFooter {
    margin-top: 32px;
  }

  .colButtonCalc {
    height: 70px;
    align-items: flex-end;
    justify-content: center;
    display: flex;
  }

  .alingMiddle {
    display: flex;
    justify-content: center;
  }

  .result-center-collapse-row {
    margin-top: 13px;
    width: 100%;
  }
`;

export const CustomCollapse = styled(Collapse)`
  background: transparent !important;

  .ant-collapse-item {
    .ant-collapse-header {
      border-radius: 0px !important;
      font-family: "Asap", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      color: #4b4b4b !important;
    }
  }

  .ant-collapse-arrow {
    box-shadow: none;
    color: #8a50a5 !important;
  }

  .ant-collapse-item:not(:first-of-type) {
    border-top: 1px solid #9d9d9d !important;
  }

  .ant-collapse-content {
    background: transparent !important;
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    &.error {
      color: #d44c4c;
    }
  }

  .message-error {
    color: #d44c4c;
    font-size: 13px;
    font-weight: bold;
    margin-left: 5px;
  }

  .bodyContent {
    margin-top: 8px;

    .ant-row > label {
      display: inline-block;
      margin-bottom: 8px;
    }

    .ant-row > .ant-input-group-wrapper {
      width: 50%;
    }

    .ant-row > .ant-input {
      width: 50%;
    }

    & > .rowFields {
      margin-top: 0px;
    }

    & > .rowFields ~ .rowFields {
      margin-top: 8px;
    }
  }

  .result-center-edit-text {
    color: #9386aa;
    font-size: 12px;
    margin-left: 5px;
  }

  .result-center-item-row {
    margin-top: 13px;
  }

  .total-value {
    text-align: end;
  }
`;

export const ItemsContainer = styled.div`
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;

  margin-top: 16px;

  .ant-row-flex {
    padding: 8px 0;
  }
`;
