import React, { useCallback, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import useVeterinaryContext from "../../../hooks/useVeterinaryContext";

import {
  Button,
  DatePicker,
  Dropdown,
  Icon,
  Input,
  Menu,
  notification,
  Row,
  Table,
} from "antd";
import { Container as MenuContainer } from "../../../components/utils/table/menu/styles";
import MenuIcon from "../../../components/utils/table/icons/menu";
import TagStatus from "../../../components/utils/tagStatus";
// Services
import {
  activate,
  inactivate,
  save,
} from "../../../services/veterinaryService";

const VeterinaryTable = () => {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const { dataList, isLoadingDataList, openDrawer, fetchData, TYPES } =
    useVeterinaryContext();

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const menu = (id, record) => {
    return (
      <Menu>
        {record.status === "Active" && (
          <Menu.Item key="1" onClick={() => handleEdit(record)}>
            {translation.table.menu.edit}
          </Menu.Item>
        )}
        {record.status === "Active" && (
          <Menu.Item key="2" onClick={() => handleInactivate(id, record)}>
            {translation.table.menu.inactivate}
          </Menu.Item>
        )}
        {record.status === "Inactive" && (
          <Menu.Item key="3" onClick={() => handleActivate(id, record)}>
            {translation.table.menu.activate}
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const handleEdit = useCallback(
    (record) => {
      openDrawer(record);
    },
    [openDrawer]
  );
  const handleInactivate = useCallback(
    async (id, record) => {
      try {
        await inactivate({ groupId, farmId, id });
        notification.success({
          message: "Insumo Veterinário inativado com sucesso!",
        });
        fetchData();
      } catch (error) {
        notification.error({
          message: "Erro ao inativar Insumo Veterinário. Contate o suporte.",
        });
      }
    },
    [farmId, fetchData, groupId]
  );
  const handleActivate = useCallback(
    async (id, record) => {
      try {
        await activate({ groupId, farmId, id });
        fetchData();
        notification.success({
          message: "Insumo Veterinário ativado com sucesso!",
        });
      } catch (error) {
        notification.error({
          message: "Erro ao ativar Insumo Veterinário. Contate o suporte.",
        });
      }
    },
    [farmId, fetchData, groupId]
  );

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetDateSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div
          style={{
            padding: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Row style={{ marginBottom: 5 }}>
            <DatePicker.RangePicker
              value={selectedKeys && selectedKeys[0]?.dates}
              format="DD/MM/YYYY"
              onChange={(dates, dateStrings) => setSelectedKeys([{ dates }])}
            />
          </Row>
          <Row>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{
                width: 90,
                marginRight: 8,
                background: "#684e94",
                borderColor: "none",
                border: "none",
              }}
            />
            <Button
              onClick={() => handleReset(clearFilters)}
              icon="delete"
              size="small"
              style={{ width: 90 }}
            />
          </Row>
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="calendar"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return moment(record[dataIndex]).isBetween(
          value?.dates[0],
          value?.dates[1],
          "days",
          "[]"
        );
      },
    };
  };
  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return record[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    }),
    [handleReset, handleSearch]
  );

  return (
    <Table
      loading={isLoadingDataList}
      rowKey="id"
      size="small"
      dataSource={dataList}
      scroll={{
        x: true,
      }}
      pagination={{
        hideOnSinglePage:
          dataList !== null && dataList.length > 10 ? false : true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
      }}
    >
      <Table.Column
        title={translation.supplement.table.columns.status}
        dataIndex="status"
        key="status"
        align="left"
        sorter={(first, second) => {
          return first.status.localeCompare(second.status, "pt-BR", {
            numeric: false,
            ignorePunctuation: true,
          });
        }}
        defaultFilteredValue={["Active"]}
        filtered
        filters={[
          {
            text: translation.status.active,
            value: "Active",
          },
          {
            text: translation.status.inactive,
            value: "Inactive",
          },
        ]}
        filterMultiple
        onFilter={(value, record) => record.status.includes(value)}
        render={(status) =>
          status === "Active" ? (
            <TagStatus
              background="#C5F1CA"
              borderColor="#106518"
              color="#106518"
            >
              {translation.status.active}
            </TagStatus>
          ) : (
            <TagStatus
              background="#FBC7C7"
              borderColor="#D44C4C"
              color="#D44C4C"
            >
              {translation.status.inactive}
            </TagStatus>
          )
        }
      />
      <Table.Column
        title={translation.veterinary.table.columns.type}
        dataIndex="type"
        sorter={(first, second) => {
          return first.type.localeCompare(second.type, "pt-BR", {
            numeric: false,
            ignorePunctuation: true,
          });
        }}
        key="type"
        {...handleGetColumnSearchProps("type")}
        render={(text) => (
          <span>
            {TYPES.find((bu) => bu.key === text)
              ? TYPES.find((bu) => bu.key === text).name
              : "-"}
          </span>
        )}
      />
      <Table.Column
        title={translation.veterinary.table.columns.name}
        dataIndex="name"
        sorter={(first, second) => {
          return first.name.localeCompare(second.name, "pt-BR", {
            numeric: false,
            ignorePunctuation: true,
          });
        }}
        key="name"
        {...handleGetColumnSearchProps("name")}
      />
      <Table.Column
        title={translation.veterinary.table.columns.supplierName}
        dataIndex="supplierName"
        sorter={(first, second) => {
          if (!first.supplierName) {
            first.supplierName = "";
          }
          return first.supplierName.localeCompare(
            second.supplierName,
            "pt-BR",
            {
              numeric: false,
              ignorePunctuation: true,
            }
          );
        }}
        key="supplierName"
        {...handleGetColumnSearchProps("supplierName")}
      />

      <Table.Column
        title={translation.veterinary.table.columns.createdAt}
        dataIndex="createdAt"
        sorter={(first, second) => {
          return first.createdAt.localeCompare(second.createdAt, "pt-BR", {
            numeric: false,
            ignorePunctuation: true,
          });
        }}
        key="createdAt"
        {...handleGetDateSearchProps("createdAt")}
        render={(text) => <span>{moment(text).format("DD/MM/YYYY")}</span>}
      />
      <Table.Column
        title={translation.veterinary.table.columns.createdBy}
        dataIndex="createdBy"
        sorter={(first, second) => {
          return first.createdBy.localeCompare(second.createdBy, "pt-BR", {
            numeric: false,
            ignorePunctuation: true,
          });
        }}
        key="createdBy"
        {...handleGetColumnSearchProps("createdBy")}
      />
      <Table.Column
        title=""
        align="left"
        render={(text, record) => (
          <Dropdown
            overlay={menu(record.id, record)}
            trigger={["click"]}
            key={record.id}
          >
            <MenuContainer>
              <MenuIcon />
            </MenuContainer>
          </Dropdown>
        )}
      />
    </Table>
  );
};

export default VeterinaryTable;
