import React from 'react';

// import { Container } from './styles';

const PlusPurpleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.382"
    height="12.382"
    viewBox="0 0 12.382 12.382"
  >
    <path
      id="União_4"
      data-name="União 4"
      d="M-4450.047,168.382v-4.953H-4455v-2.476h4.953V156h2.477v4.953h4.953v2.476h-4.953v4.953Z"
      transform="translate(4455 -156)"
      fill="#684e94"
    />
  </svg>
);
export default PlusPurpleIcon;
