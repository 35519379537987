import styled from "styled-components";

export const Container = styled.div`
  .ant-row {
    & > {
      label,
      .filter-label {
        display: inline-block;
        color: #9074bf;
        font-size: 14px;
        font-weight: bold;
        margin-left: 15px;
        margin-top: 4px;
        margin-bottom: 4px;

        &.error {
          color: #d44c4c;
        }
      }
    }
  }

  .filter-label.error {
    color: #d44c4c;
  }
`;
