import apiOptimization from "../config/api_optimization";
// import axios from "axios";
import getHeaders from "./getHeaders";

export function updateBoitelSaleScenarioResultService(payload) {
  const {
    groupId,
    farmId,
    boitelSaleScenarioId,
    boitelSaleScenarioResultId,
    body,
  } = payload;

  return apiOptimization.patch(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/boitels/${boitelSaleScenarioId}/results/${boitelSaleScenarioResultId}`,
    body,
    getHeaders(false, null)
  );
}

export function setBoitelSaleScenarioResultAsFavoriteService(payload) {
  const { groupId, farmId, boitelSaleScenarioId, boitelSaleScenarioResultId } =
    payload;

  return apiOptimization.patch(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/boitels/${boitelSaleScenarioId}/results/${boitelSaleScenarioResultId}/favorite`,
    null,
    getHeaders(false, null)
  );
}

export function getBoitelSaleScenarioResultService(payload) {
  const { groupId, farmId, boitelSaleScenarioId, boitelSaleScenarioResultId } =
    payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/boitels/${boitelSaleScenarioId}/results/${boitelSaleScenarioResultId}`,
    getHeaders(false, null)
  );
}

export function getBoitelSaleScenarioResultDietStrategiesSuggestedService(
  payload
) {
  const { groupId, farmId, boitelSaleScenarioId, boitelSaleScenarioResultId } =
    payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/boitels/${boitelSaleScenarioId}/results/${boitelSaleScenarioResultId}/diet/strategies/suggested`,
    getHeaders(false, null)
  );
}

export function getBoitelSaleScenarioResultViolationsService(payload) {
  const { groupId, farmId, boitelSaleScenarioId, boitelSaleScenarioResultId } =
    payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/boitels/${boitelSaleScenarioId}/results/${boitelSaleScenarioResultId}/violations`,
    getHeaders(false, null)
  );
}

export function getBoitelSaleScenarioResultCommitmentsService(payload) {
  const { groupId, farmId, boitelSaleScenarioId, boitelSaleScenarioResultId } =
    payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/boitels/${boitelSaleScenarioId}/results/${boitelSaleScenarioResultId}/commitments`,
    getHeaders(false, null)
  );
}

export function getBoitelSaleScenarioResultParameterService(payload) {
  const { groupId, farmId, boitelSaleScenarioId, boitelSaleScenarioResultId } =
    payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/boitels/${boitelSaleScenarioId}/results/${boitelSaleScenarioResultId}/parameters`,
    getHeaders(false, null)
  );
}

export function getBoitelSaleScenarioResultArrobaPricesService(payload) {
  const { groupId, farmId, boitelSaleScenarioId, boitelSaleScenarioResultId } =
    payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/boitels/${boitelSaleScenarioId}/results/${boitelSaleScenarioResultId}/arroba/prices`,
    getHeaders(false, null)
  );
}
