import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import NumberFormat from "react-number-format";

import { Container, Footer, CustomDivider } from "./styles";
import { Row, Col, DatePicker, Input, Spin, notification, Tabs } from "antd";
import ButtonStandard from "../../../../components/utils/button";
import ExclamationIcon from "../../../../components/utils/icons/exclamation";
import CheckIcon from "../../../../components/utils/icons/check";
import InfoTooltip from "../../../../components/utils/infoTooltip";

/* Service */
import { saveKPIGoalForParameters } from "../../../../services/kpiService";

const EditKPIs = ({ kpiGoalList, onSave, onCancel }) => {
  const { translation } = useSelector((state) => state.app);

  const { RangePicker } = DatePicker;

  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [harvestId, setHarvestId] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  // Engorda
  const [extensiveCapacity, setExtensiveCapacity] = useState(null);
  const [extensiveEnjoy, setExtensiveEnjoy] = useState(null);
  const [extensiveProduction, setExtensiveProduction] = useState(null);
  const [extensiveCost, setExtensiveCost] = useState(null);
  const [extensiveGdp, setExtensiveGdp] = useState(null);
  const [extensiveMargin, setExtensiveMargin] = useState(null);
  const [extensiveCarbonFootprint, setExtensiveCarbonFootprint] =
    useState(null);
  const [extensiveDisbursement, setExtensiveDisbursement] = useState(null); // Desembolso
  const [extensiveAssetValue, setExtensiveAssetValue] = useState(null); // Valor do Ativo
  const [extensiveIncrementalMargin, setExtensiveIncrementalMargin] =
    useState(null); // Margem Incremental

  const [semiIntensiveCapacity, setSemiIntensiveCapacity] = useState(null);
  const [semiIntensiveEnjoy, setSemiIntensiveEnjoy] = useState(null);
  const [semiIntensiveProduction, setSemiIntensiveProduction] = useState(null);
  const [semiIntensiveCost, setSemiIntensiveCost] = useState(null);
  const [semiIntensiveGdp, setSemiIntensiveGdp] = useState(null);
  const [semiIntensiveMargin, setSemiIntensiveMargin] = useState(null);
  const [semiIntensiveCarbonFootprint, setSemiIntensiveCarbonFootprint] =
    useState(null);
  const [semiIntensiveDisbursement, setSemiIntensiveDisbursement] =
    useState(null); // Desembolso
  const [semiIntensiveAssetValue, setSemiIntensiveAssetValue] = useState(null); // Valor do Ativo
  const [semiIntensiveIncrementalMargin, setSemiIntensiveIncrementalMargin] =
    useState(null); // Margem Incremental

  const [intensiveCapacity, setIntensiveCapacity] = useState(null);
  const [intensiveEnjoy, setIntensiveEnjoy] = useState(null);
  const [intensiveProduction, setIntensiveProduction] = useState(null);
  const [intensiveCost, setIntensiveCost] = useState(null);
  const [intensiveGdp, setIntensiveGdp] = useState(null);
  const [intensiveMargin, setIntensiveMargin] = useState(null);
  const [intensiveCarbonFootprint, setIntensiveCarbonFootprint] =
    useState(null);
  const [intensiveDisbursement, setIntensiveDisbursement] = useState(null); // Desembolso
  const [intensiveAssetValue, setIntensiveAssetValue] = useState(null); // Valor do Ativo
  const [intensiveIncrementalMargin, setIntensiveIncrementalMargin] =
    useState(null); // Margem Incremental

  // Cria
  const [createWeanCalf, setCreateWeanCalf] = useState(null);
  const [createIEP, setCreateIEP] = useState(null);
  const [createReproductiveEfficiency, setCreateReproductiveEfficiency] =
    useState(null);
  const [createWeanCalfCost, setCreateWeanCalfCost] = useState(null);
  const [createDiscardedCows, setCreateDiscardedCows] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        if (kpiGoalList != null) {
          let kpiWithHarvest = kpiGoalList.find((k) => k.harvest);

          setHarvestId(kpiWithHarvest.harvest.id);
          setStartDate(kpiWithHarvest.harvest.startDate);
          setEndDate(kpiWithHarvest.harvest.endDate);

          kpiGoalList.forEach((info) => {
            // Fatten
            // Extensive
            if (
              info.kpi.name === "LOTACAO" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "Extensive"
            ) {
              setExtensiveCapacity(info);
            } else if (
              info.kpi.name === "DESFRUTE" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "Extensive"
            ) {
              setExtensiveEnjoy(info);
            } else if (
              info.kpi.name === "PRODUCAO" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "Extensive"
            ) {
              setExtensiveProduction(info);
            } else if (
              info.kpi.name === "GDP" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "Extensive"
            ) {
              setExtensiveGdp(info);
            } else if (
              info.kpi.name === "CUSTO" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "Extensive"
            ) {
              setExtensiveCost(info);
            } else if (
              info.kpi.name === "MARGEM" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "Extensive"
            ) {
              setExtensiveMargin(info);
            } else if (
              info.kpi.name === "PEGADA_CARBONICA" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "Extensive"
            ) {
              setExtensiveCarbonFootprint(info);
            } else if (
              info.kpi.name === "DESEMBOLSO_CAB_MES" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "Extensive"
            ) {
              setExtensiveDisbursement(info);
            } else if (
              info.kpi.name === "VALOR_DO_ATIVO" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "Extensive"
            ) {
              setExtensiveAssetValue(info);
            } else if (
              info.kpi.name === "MARGEM_INCREMENTAL" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "Extensive"
            ) {
              setExtensiveIncrementalMargin(info);
            }

            // Semi-Intensive
            if (
              info.kpi.name === "LOTACAO" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "SemiIntensive"
            ) {
              setSemiIntensiveCapacity(info);
            } else if (
              info.kpi.name === "DESFRUTE" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "SemiIntensive"
            ) {
              setSemiIntensiveEnjoy(info);
            } else if (
              info.kpi.name === "PRODUCAO" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "SemiIntensive"
            ) {
              setSemiIntensiveProduction(info);
            } else if (
              info.kpi.name === "GDP" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "SemiIntensive"
            ) {
              setSemiIntensiveGdp(info);
            } else if (
              info.kpi.name === "CUSTO" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "SemiIntensive"
            ) {
              setSemiIntensiveCost(info);
            } else if (
              info.kpi.name === "MARGEM" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "SemiIntensive"
            ) {
              setSemiIntensiveMargin(info);
            } else if (
              info.kpi.name === "PEGADA_CARBONICA" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "SemiIntensive"
            ) {
              setSemiIntensiveCarbonFootprint(info);
            } else if (
              info.kpi.name === "DESEMBOLSO_CAB_MES" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "SemiIntensive"
            ) {
              setSemiIntensiveDisbursement(info);
            } else if (
              info.kpi.name === "VALOR_DO_ATIVO" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "SemiIntensive"
            ) {
              setSemiIntensiveAssetValue(info);
            } else if (
              info.kpi.name === "MARGEM_INCREMENTAL" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "SemiIntensive"
            ) {
              setSemiIntensiveIncrementalMargin(info);
            }

            // Intensive
            if (
              info.kpi.name === "LOTACAO" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "Intensive"
            ) {
              info.kpi.valueType = "%";
              setIntensiveCapacity(info);
            } else if (
              info.kpi.name === "DESFRUTE" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "Intensive"
            ) {
              setIntensiveEnjoy(info);
            } else if (
              info.kpi.name === "PRODUCAO" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "Intensive"
            ) {
              setIntensiveProduction(info);
            } else if (
              info.kpi.name === "GDP" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "Intensive"
            ) {
              setIntensiveGdp(info);
            } else if (
              info.kpi.name === "CUSTO" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "Intensive"
            ) {
              setIntensiveCost(info);
            } else if (
              info.kpi.name === "MARGEM" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "Intensive"
            ) {
              setIntensiveMargin(info);
            } else if (
              info.kpi.name === "PEGADA_CARBONICA" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "Intensive"
            ) {
              setIntensiveCarbonFootprint(info);
            } else if (
              info.kpi.name === "DESEMBOLSO_CAB_MES" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "Intensive"
            ) {
              setIntensiveDisbursement(info);
            } else if (
              info.kpi.name === "VALOR_DO_ATIVO" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "Intensive"
            ) {
              setIntensiveAssetValue(info);
            } else if (
              info.kpi.name === "MARGEM_INCREMENTAL" &&
              info.productionSystem === "Fatten" &&
              info.productionSubSystem === "Intensive"
            ) {
              setIntensiveIncrementalMargin(info);
            }

            /* Create */
            if (
              info.kpi.name === "Kg Bez. Desm / Kg Vaca" &&
              info.productionSystem === "Create"
            ) {
              setCreateWeanCalf(info);
            }

            if (info.kpi.name === "IEP" && info.productionSystem === "Create") {
              setCreateIEP(info);
            }

            if (
              info.kpi.name === "EFICIENCIA REPRODUTIVA" &&
              info.productionSystem === "Create"
            ) {
              setCreateReproductiveEfficiency(info);
            }

            if (
              info.kpi.name === "CUSTO BEZERRO DESMAMADO" &&
              info.productionSystem === "Create"
            ) {
              setCreateWeanCalfCost(info);
            }

            if (
              info.kpi.name === "VACAS DESCARTADAS" &&
              info.productionSystem === "Create"
            ) {
              setCreateDiscardedCows(info);
            }
          });
        }
        setIsLoading(false);
      } catch (ex) {
        notification.error({ message: "Ocorreu um erro ao carregar os dados" });
      }
    }

    fetchData();
  }, [groupId, farmId, kpiGoalList]);

  const handleSubmit = async () => {
    setIsLoading(true);
    const saveValuesDto = {
      goals: [
        extensiveCapacity,
        extensiveProduction,
        extensiveEnjoy,
        extensiveGdp,
        extensiveCost,
        extensiveMargin,
        extensiveCarbonFootprint,
        extensiveDisbursement,
        extensiveAssetValue,
        extensiveIncrementalMargin,
        semiIntensiveCapacity,
        semiIntensiveProduction,
        semiIntensiveEnjoy,
        semiIntensiveGdp,
        semiIntensiveCost,
        semiIntensiveMargin,
        semiIntensiveCarbonFootprint,
        semiIntensiveDisbursement,
        semiIntensiveAssetValue,
        semiIntensiveIncrementalMargin,
        intensiveCapacity,
        intensiveProduction,
        intensiveEnjoy,
        intensiveGdp,
        intensiveCost,
        intensiveMargin,
        intensiveCarbonFootprint,
        intensiveDisbursement,
        intensiveAssetValue,
        intensiveIncrementalMargin,
        createWeanCalf,
        createIEP,
        createReproductiveEfficiency,
        createWeanCalfCost,
        createDiscardedCows,
      ],
    };

    try {
      await saveKPIGoalForParameters({
        groupId,
        farmId,
        harvestId,
        data: saveValuesDto,
      });
      setIsLoading(false);
      notification.success({
        message: "Os dados foram atualizados com sucesso",
      });
      onSave();
    } catch (ex) {
      setIsLoading(false);
      notification.error({
        message: "Ocorreu um erro na atualização dos dados",
      });
    }
  };

  const handleValueChange = useCallback((type, value) => {
    switch (type) {
      case "extensiveCapacity":
        setExtensiveCapacity((prev) => ({ ...prev, goalValue: value }));
        break;
      case "extensiveProduction":
        setExtensiveProduction((prev) => ({ ...prev, goalValue: value }));
        break;
      case "extensiveEnjoy":
        setExtensiveEnjoy((prev) => ({ ...prev, goalValue: value }));
        break;
      case "extensiveCost":
        setExtensiveCost((prev) => ({ ...prev, goalValue: value }));
        break;
      case "extensiveGdp":
        setExtensiveGdp((prev) => ({ ...prev, goalValue: value }));
        break;
      case "extensiveMargin":
        setExtensiveMargin((prev) => ({ ...prev, goalValue: value }));
        break;
      case "extensiveCarbonFootprint":
        setExtensiveCarbonFootprint((prev) => ({ ...prev, goalValue: value }));
        break;
      case "extensiveDisbursement":
        setExtensiveDisbursement((prev) => ({ ...prev, goalValue: value }));
        break;
      case "extensiveAssetValue":
        setExtensiveAssetValue((prev) => ({ ...prev, goalValue: value }));
        break;
      case "extensiveIncrementalMargin":
        setExtensiveIncrementalMargin((prev) => ({
          ...prev,
          goalValue: value,
        }));
        break;
      case "intensiveCapacity":
        setIntensiveCapacity((prev) => ({ ...prev, goalValue: value }));
        break;
      case "intensiveProduction":
        setIntensiveProduction((prev) => ({ ...prev, goalValue: value }));
        break;
      case "intensiveEnjoy":
        setIntensiveEnjoy((prev) => ({ ...prev, goalValue: value }));
        break;
      case "intensiveCost":
        setIntensiveCost((prev) => ({ ...prev, goalValue: value }));
        break;
      case "intensiveGdp":
        setIntensiveGdp((prev) => ({ ...prev, goalValue: value }));
        break;
      case "intensiveMargin":
        setIntensiveMargin((prev) => ({ ...prev, goalValue: value }));
        break;
      case "intensiveCarbonFootprint":
        setIntensiveCarbonFootprint((prev) => ({ ...prev, goalValue: value }));
        break;
      case "intensiveDisbursement":
        setIntensiveDisbursement((prev) => ({ ...prev, goalValue: value }));
        break;
      case "intensiveAssetValue":
        setIntensiveAssetValue((prev) => ({ ...prev, goalValue: value }));
        break;
      case "intensiveIncrementalMargin":
        setIntensiveIncrementalMargin((prev) => ({
          ...prev,
          goalValue: value,
        }));
        break;
      case "semiIntensiveCapacity":
        setSemiIntensiveCapacity((prev) => ({ ...prev, goalValue: value }));
        break;
      case "semiIntensiveProduction":
        setSemiIntensiveProduction((prev) => ({ ...prev, goalValue: value }));
        break;
      case "semiIntensiveEnjoy":
        setSemiIntensiveEnjoy((prev) => ({ ...prev, goalValue: value }));
        break;
      case "semiIntensiveCost":
        setSemiIntensiveCost((prev) => ({ ...prev, goalValue: value }));
        break;
      case "semiIntensiveGdp":
        setSemiIntensiveGdp((prev) => ({ ...prev, goalValue: value }));
        break;
      case "semiIntensiveMargin":
        setSemiIntensiveMargin((prev) => ({ ...prev, goalValue: value }));
        break;
      case "semiIntensiveCarbonFootprint":
        setSemiIntensiveCarbonFootprint((prev) => ({
          ...prev,
          goalValue: value,
        }));
        break;
      case "semiIntensiveDisbursement":
        setSemiIntensiveDisbursement((prev) => ({ ...prev, goalValue: value }));
        break;
      case "semiIntensiveAssetValue":
        setSemiIntensiveAssetValue((prev) => ({ ...prev, goalValue: value }));
        break;
      case "semiIntensiveIncrementalMargin":
        setSemiIntensiveIncrementalMargin((prev) => ({
          ...prev,
          goalValue: value,
        }));
        break;
      case "createWeanCalfGoalValue": {
        setCreateWeanCalf((prev) => ({ ...prev, goalValue: value }));
        break;
      }
      case "createWeanCalfStartDate": {
        setCreateWeanCalf((prev) => ({
          ...prev,
          startDate: value != null ? value.format("yyyy-MM-DD") : null,
        }));
        break;
      }
      case "createWeanCalfEndDate": {
        setCreateWeanCalf((prev) => ({
          ...prev,
          endDate: value ? value.format("yyyy-MM-DD") : null,
        }));
        break;
      }
      case "createIepGoalValue": {
        setCreateIEP((prev) => ({ ...prev, goalValue: value }));
        break;
      }
      case "createIepStartDate": {
        setCreateIEP((prev) => ({
          ...prev,
          startDate: value != null ? value.format("yyyy-MM-DD") : null,
        }));
        break;
      }
      case "createIepEndDate": {
        setCreateIEP((prev) => ({
          ...prev,
          endDate: value ? value.format("yyyy-MM-DD") : null,
        }));
        break;
      }
      case "createReproductiveEfficiencyGoalValue": {
        setCreateReproductiveEfficiency((prev) => ({
          ...prev,
          goalValue: value,
        }));
        break;
      }
      case "createWeanCalfCostGoalValue": {
        setCreateWeanCalfCost((prev) => ({ ...prev, goalValue: value }));
        break;
      }
      case "createWeanCalfCostStartDate": {
        setCreateWeanCalfCost((prev) => ({
          ...prev,
          startDate: value != null ? value.format("yyyy-MM-DD") : null,
        }));
        break;
      }
      case "createWeanCalfCostEndDate": {
        setCreateWeanCalfCost((prev) => ({
          ...prev,
          endDate: value ? value.format("yyyy-MM-DD") : null,
        }));
        break;
      }
      case "createDiscardedCowsGoalValue": {
        setCreateDiscardedCows((prev) => ({ ...prev, goalValue: value }));
        break;
      }
      case "createDiscardedCowsStartDate": {
        setCreateDiscardedCows((prev) => ({
          ...prev,
          startDate: value != null ? value.format("yyyy-MM-DD") : null,
        }));
        break;
      }
      case "createDiscardedCowsEndDate": {
        setCreateDiscardedCows((prev) => ({
          ...prev,
          endDate: value ? value.format("yyyy-MM-DD") : null,
        }));
        break;
      }

      default:
      //
    }
  }, []);

  const isSemiIntensiveKpisChecked =
    semiIntensiveCapacity?.goalValue !== null &&
    semiIntensiveEnjoy?.goalValue !== null &&
    semiIntensiveProduction?.goalValue !== null &&
    semiIntensiveCost?.goalValue !== null &&
    semiIntensiveGdp?.goalValue !== null &&
    semiIntensiveMargin?.goalValue !== null &&
    semiIntensiveCarbonFootprint?.goalValue !== null &&
    semiIntensiveAssetValue?.goalValue !== null &&
    semiIntensiveIncrementalMargin?.goalValue !== null &&
    semiIntensiveDisbursement?.goalValue !== null;

  const isIntensiveKpisChecked =
    intensiveCapacity?.goalValue !== null &&
    intensiveEnjoy?.goalValue !== null &&
    intensiveProduction?.goalValue !== null &&
    intensiveCost?.goalValue !== null &&
    intensiveGdp?.goalValue !== null &&
    intensiveMargin?.goalValue !== null &&
    intensiveCarbonFootprint?.goalValue !== null &&
    intensiveAssetValue?.goalValue !== null &&
    intensiveIncrementalMargin?.goalValue !== null &&
    intensiveDisbursement?.goalValue !== null;

  const isExtensiveKpisChecked =
    extensiveCapacity?.goalValue !== null &&
    extensiveEnjoy?.goalValue !== null &&
    extensiveProduction?.goalValue !== null &&
    extensiveCost?.goalValue !== null &&
    extensiveGdp?.goalValue !== null &&
    extensiveMargin?.goalValue !== null &&
    extensiveCarbonFootprint?.goalValue !== null &&
    extensiveAssetValue?.goalValue !== null &&
    extensiveIncrementalMargin?.goalValue !== null &&
    extensiveDisbursement?.goalValue !== null;

  return (
    <Container>
      <Spin spinning={isLoading}>
        <Row type="flex" justify="space-between" style={{ marginLeft: 15 }}>
          <Col xl={24}>
            <p>Defina o período da safra e suas metas de resultado</p>
          </Col>
        </Row>

        <Row
          type="flex"
          justify="start"
          style={{ flexDirection: "column", marginLeft: 15 }}
        >
          <span className="title-purple">
            <b>Período da safra</b>
          </span>
          <RangePicker
            disabled={true}
            value={
              startDate && endDate
                ? [
                    moment(startDate, "YYYY-MM-DD"),
                    moment(endDate, "YYYY-MM-DD"),
                  ]
                : null
            }
            format={"DD/MM/YYYY"}
            style={{ width: 234 }}
          />
        </Row>

        <CustomDivider dashed>META DE RESULTADOS</CustomDivider>
        {/* ENGORDA */}
        <Row type="flex" justify="start" style={{ marginLeft: 15 }}>
          <div className="card">
            <span className="title-purple">
              Sistema: <b>Engorda</b>
            </span>
          </div>
        </Row>

        {/* Tabs */}
        <Row type="flex" style={{ marginTop: 10 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Tabs type="card" defaultActiveKey="1">
              {/* SEMI-INTENSIVO */}
              <Tabs.TabPane
                tab={
                  <span className="title-span">
                    {isSemiIntensiveKpisChecked ? (
                      <CheckIcon />
                    ) : (
                      <ExclamationIcon />
                    )}
                    <span>Semi-Intensivo</span>
                  </span>
                }
                key="1"
              >
                {/* LOCATION & DESFRUTE  */}
                <Row type="flex" justify="start" style={{ marginTop: 15 }}>
                  {/* Lotação */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.capacity}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipCapacity}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="semiIntensiveCapacity"
                          addonBefore={
                            semiIntensiveCapacity?.kpi.monetary ? "R$" : null
                          }
                          addonAfter={semiIntensiveCapacity?.kpi.valueType}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={semiIntensiveCapacity?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange(
                              "semiIntensiveCapacity",
                              floatValue
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  {/* Desfrute */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.enjoy}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipEnjoy}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="semiIntensiveEnjoy"
                          addonBefore={
                            semiIntensiveEnjoy?.kpi.monetary ? "R$" : null
                          }
                          addonAfter={semiIntensiveEnjoy?.kpi.valueType}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={semiIntensiveEnjoy?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange("semiIntensiveEnjoy", floatValue)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* PRODUCTION & COST */}
                <Row type="flex" justify="start" style={{ marginTop: 15 }}>
                  {/* PRODUCAO */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.production}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipProduction}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="semiIntensiveProduction"
                          addonBefore={
                            semiIntensiveProduction?.kpi.monetary ? "R$" : null
                          }
                          addonAfter={semiIntensiveProduction?.kpi.valueType}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={semiIntensiveProduction?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange(
                              "semiIntensiveProduction",
                              floatValue
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  {/* Custo */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.cost}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipCost}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="semiIntensiveCost"
                          addonBefore={
                            semiIntensiveCost?.kpi.monetary ? "R$" : null
                          }
                          addonAfter={semiIntensiveCost?.kpi.valueType}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={semiIntensiveCost?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange("semiIntensiveCost", floatValue)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* GDP & MARGEM */}
                <Row type="flex" justify="start" style={{ marginTop: 15 }}>
                  {/* GDP */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.gdp}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipGDP}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="semiIntensiveGdp"
                          addonBefore={
                            semiIntensiveGdp?.kpi.monetary ? "R$" : null
                          }
                          addonAfter={semiIntensiveGdp?.kpi.valueType}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={semiIntensiveGdp?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange("semiIntensiveGdp", floatValue)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  {/* Margem */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.margin}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipMargin}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="semiIntensiveMargin"
                          addonBefore={
                            semiIntensiveMargin?.kpi.monetary ? "R$" : null
                          }
                          addonAfter={semiIntensiveMargin?.kpi.valueType}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={semiIntensiveMargin?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange("semiIntensiveMargin", floatValue)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* CARBON FOOTPRINT & DESEMBOLSO_CAB_MES */}
                <Row type="flex" justify="start" style={{ marginTop: 15 }}>
                  {/* CARBON FOOTPRINT */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.carbonFootprint}</span>
                          <InfoTooltip
                            placement="top"
                            title={
                              translation.kpi.tooltips.tooltipCarbonFootprint
                            }
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="semiIntensiveCarbonFootprint"
                          addonBefore={
                            semiIntensiveCarbonFootprint?.kpi.monetary
                              ? "R$"
                              : null
                          }
                          addonAfter={
                            <span>
                              kg CO<sub>2</sub>E / kg GDP
                            </span>
                          }
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={semiIntensiveCarbonFootprint?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange(
                              "semiIntensiveCarbonFootprint",
                              floatValue
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  {/* DESEMBOLSO_CAB_MES */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.disbursement}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipDisbursement}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="semiIntensiveDisbursement"
                          addonBefore={
                            semiIntensiveDisbursement?.kpi.monetary
                              ? "R$"
                              : null
                          }
                          addonAfter={<span>Cab/Mês</span>}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={semiIntensiveDisbursement?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange(
                              "semiIntensiveDisbursement",
                              floatValue
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* VALOR DO ATIVO & MARGEM INCREMENTAL */}
                <Row type="flex" justify="start" style={{ marginTop: 15 }}>
                  {/* VALOR DO ATIVOT */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.assetValue}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltiAassetValue}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="semiIntensiveAssetValue"
                          addonBefore={
                            semiIntensiveAssetValue?.kpi.monetary ? "R$" : null
                          }
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={semiIntensiveAssetValue?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange(
                              "semiIntensiveAssetValue",
                              floatValue
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  {/* MARGEM INCREMENTA */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.incrementalMargin}</span>
                          <InfoTooltip
                            placement="top"
                            title={
                              translation.kpi.tooltips.tooltipIncrementalMargin
                            }
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="semiIntensiveIncrementalMargin"
                          addonBefore={
                            semiIntensiveIncrementalMargin?.kpi.monetary
                              ? "R$"
                              : null
                          }
                          addonAfter={<span>Cab/Mês</span>}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={semiIntensiveIncrementalMargin?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange(
                              "semiIntensiveIncrementalMargin",
                              floatValue
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Tabs.TabPane>
              {/* INTENSIVO */}
              <Tabs.TabPane
                tab={
                  <span className="title-span">
                    {isIntensiveKpisChecked ? (
                      <CheckIcon />
                    ) : (
                      <ExclamationIcon />
                    )}
                    <span>Intensivo</span>
                  </span>
                }
                key="2"
              >
                {/* LOCATION & DESFRUTE */}
                <Row type="flex" justify="start" style={{ marginTop: 15 }}>
                  {/* Lotação */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.capacity}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipCapacity}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="intensiveCapacity"
                          addonBefore={
                            intensiveCapacity?.kpi.monetary ? "R$" : null
                          }
                          addonAfter={intensiveCapacity?.kpi.valueType}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={intensiveCapacity?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange("intensiveCapacity", floatValue)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  {/* Custo */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.cost}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipCost}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="intensiveCost"
                          addonBefore={
                            intensiveCost?.kpi.monetary ? "R$" : null
                          }
                          addonAfter={intensiveCost?.kpi.valueType}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={intensiveCost?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange("intensiveCost", floatValue)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* GDP & MARGEM */}
                <Row type="flex" justify="start" style={{ marginTop: 15 }}>
                  {/* GDP */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.gdp}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipGDP}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="intensiveGdp"
                          addonBefore={intensiveGdp?.kpi.monetary ? "R$" : null}
                          addonAfter={intensiveGdp?.kpi.valueType}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={intensiveGdp?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange("intensiveGdp", floatValue)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  {/* Margin */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.margin}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipMargin}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="intensiveMargin"
                          addonBefore={
                            intensiveMargin?.kpi.monetary ? "R$" : null
                          }
                          addonAfter={intensiveMargin?.kpi.valueType}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={intensiveMargin?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange("intensiveMargin", floatValue)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* CARBON FOOTPRINT & DESEMBOLSO_CAB_MES */}
                <Row type="flex" justify="start" style={{ marginTop: 15 }}>
                  {/* CARBON FOOTPRINT */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.carbonFootprint}</span>
                          <InfoTooltip
                            placement="top"
                            title={
                              translation.kpi.tooltips.tooltipCarbonFootprint
                            }
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="intensiveCarbonFootprint"
                          addonBefore={
                            intensiveCarbonFootprint?.kpi.monetary ? "R$" : null
                          }
                          addonAfter={
                            <span>
                              kg CO<sub>2</sub>E / kg GDP
                            </span>
                          }
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={intensiveCarbonFootprint?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange(
                              "intensiveCarbonFootprint",
                              floatValue
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  {/* DESEMBOLSO_CAB_MES */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.disbursement}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipDisbursement}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="intensiveDisbursement"
                          addonBefore={
                            intensiveDisbursement?.kpi.monetary ? "R$" : null
                          }
                          addonAfter={<span>Cab/Mês</span>}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={intensiveDisbursement?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange(
                              "intensiveDisbursement",
                              floatValue
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* VALOR DO ATIVO & MARGEM INCREMENTAL */}
                <Row type="flex" justify="start" style={{ marginTop: 15 }}>
                  {/* VALOR DO ATIVOT */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.assetValue}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltiAassetValue}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="intensiveAssetValue"
                          addonBefore={
                            intensiveAssetValue?.kpi.monetary ? "R$" : null
                          }
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={intensiveAssetValue?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange("intensiveAssetValue", floatValue)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  {/* MARGEM INCREMENTA */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.incrementalMargin}</span>
                          <InfoTooltip
                            placement="top"
                            title={
                              translation.kpi.tooltips.tooltipIncrementalMargin
                            }
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="intensiveIncrementalMargin"
                          addonBefore={
                            intensiveIncrementalMargin?.kpi.monetary
                              ? "R$"
                              : null
                          }
                          addonAfter={<span>Cab/Mês</span>}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={intensiveIncrementalMargin?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange(
                              "intensiveIncrementalMargin",
                              floatValue
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Tabs.TabPane>
              {/* EXTENSIVO */}
              <Tabs.TabPane
                tab={
                  <span className="title-span">
                    {isExtensiveKpisChecked ? (
                      <CheckIcon />
                    ) : (
                      <ExclamationIcon />
                    )}
                    <span>Extensivo</span>
                  </span>
                }
                key="3"
              >
                {/* LOCATION & DESFRUTE */}
                <Row type="flex" justify="start" style={{ marginTop: 15 }}>
                  {/* Lotação */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.capacity}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipCapacity}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="extensiveCapacity"
                          addonBefore={
                            extensiveCapacity?.kpi.monetary ? "R$" : null
                          }
                          addonAfter={extensiveCapacity?.kpi.valueType}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={extensiveCapacity?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange("extensiveCapacity", floatValue)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  {/* Desfrute */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.enjoy}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipEnjoy}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="extensiveEnjoy"
                          addonBefore={
                            extensiveEnjoy?.kpi.monetary ? "R$" : null
                          }
                          addonAfter={extensiveEnjoy?.kpi.valueType}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={extensiveEnjoy?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange("extensiveEnjoy", floatValue)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* PRODUCTION & COST */}
                <Row type="flex" justify="start" style={{ marginTop: 15 }}>
                  {/* PRODUCAO */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.production}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipProduction}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="extensiveProduction"
                          addonBefore={
                            extensiveProduction?.kpi.monetary ? "R$" : null
                          }
                          addonAfter={extensiveProduction?.kpi.valueType}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={extensiveProduction?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange("extensiveProduction", floatValue)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  {/* Custo */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.cost}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipCost}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="extensiveCost"
                          addonBefore={
                            extensiveCost?.kpi.monetary ? "R$" : null
                          }
                          addonAfter={extensiveCost?.kpi.valueType}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={extensiveCost?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange("extensiveCost", floatValue)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* GDP & MARGEM */}
                <Row type="flex" justify="start" style={{ marginTop: 15 }}>
                  {/* GDP */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.gdp}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipGDP}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="extensiveGdp"
                          addonBefore={extensiveGdp?.kpi.monetary ? "R$" : null}
                          addonAfter={extensiveGdp?.kpi.valueType}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={extensiveGdp?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange("extensiveGdp", floatValue)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  {/* Margem */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.margin}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipMargin}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="extensiveMargin"
                          addonBefore={
                            extensiveMargin?.kpi.monetary ? "R$" : null
                          }
                          addonAfter={extensiveMargin?.kpi.valueType}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={extensiveMargin?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange("extensiveMargin", floatValue)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* CARBON FOOTPRINT & DESEMBOLSO_CAB_MES*/}
                <Row type="flex" justify="start" style={{ marginTop: 15 }}>
                  {/* CARBON FOOTPRINT */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.carbonFootprint}</span>
                          <InfoTooltip
                            placement="top"
                            title={
                              translation.kpi.tooltips.tooltipCarbonFootprint
                            }
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="extensiveCarbonFootprint"
                          addonBefore={
                            extensiveCarbonFootprint?.kpi.monetary ? "R$" : null
                          }
                          addonAfter={
                            <span>
                              kg CO<sub>2</sub>E / kg GDP
                            </span>
                          }
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={extensiveCarbonFootprint?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange(
                              "extensiveCarbonFootprint",
                              floatValue
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  {/* DESEMBOLSO_CAB_MES */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.disbursement}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipDisbursement}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="extensiveDisbursement"
                          addonBefore={
                            extensiveDisbursement?.kpi.monetary ? "R$" : null
                          }
                          addonAfter={<span>Cab/Mês</span>}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={extensiveDisbursement?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange(
                              "extensiveDisbursement",
                              floatValue
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* VALOR DO ATIVO & MARGEM INCREMENTAL */}
                <Row type="flex" justify="start" style={{ marginTop: 15 }}>
                  {/* VALOR DO ATIVOT */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.assetValue}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltiAassetValue}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="extensiveAssetValue"
                          addonBefore={
                            extensiveAssetValue?.kpi.monetary ? "R$" : null
                          }
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={extensiveAssetValue?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange("extensiveAssetValue", floatValue)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  {/* MARGEM INCREMENTA */}
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="flex">
                          <span>{translation.kpi.incrementalMargin}</span>
                          <InfoTooltip
                            placement="top"
                            title={
                              translation.kpi.tooltips.tooltipIncrementalMargin
                            }
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={18} lg={18} xl={18}>
                        <NumberFormat
                          customInput={Input}
                          placeholder={"Definir"}
                          name="extensiveIncrementalMargin"
                          addonBefore={
                            extensiveIncrementalMargin?.kpi.monetary
                              ? "R$"
                              : null
                          }
                          addonAfter={<span>Cab/Mês</span>}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={extensiveIncrementalMargin?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange(
                              "extensiveIncrementalMargin",
                              floatValue
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
        <CustomDivider dashed />
        {/* CRIA */}
        <Row type="flex" justify="start" style={{ marginLeft: 15 }}>
          <div className="card">
            <span className="title-purple">
              Sistema: <b>Cria</b>
            </span>
          </div>
        </Row>
        {/* Tabs */}
        <Row type="flex" style={{ marginTop: 10 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Tabs type="card" defaultActiveKey="1">
              {/* Valores */}
              <Tabs.TabPane
                tab={
                  <span className="title-span">
                    <CheckIcon />
                    <span>Valores</span>
                  </span>
                }
                key="1"
              >
                {/* WeanCalf */}
                <Row type="flex" justify="start" style={{ marginTop: 15 }}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                        <div className="flex">
                          <span>{translation.kpi.weanCalf}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipWeanCalf}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                        <NumberFormat
                          customInput={Input}
                          name="createWeanCalfGoalValue"
                          placeholder={"Definir"}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          maxLength={6}
                          value={createWeanCalf?.goalValue}
                          onValueChange={({ floatValue }) =>
                            handleValueChange(
                              "createWeanCalfGoalValue",
                              floatValue
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                        <div
                          className="flex"
                          style={{ justifyContent: "center" }}
                        >
                          <span>Início</span>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <DatePicker.MonthPicker
                          name="createWeanCalfStartDate"
                          value={
                            createWeanCalf?.startDate !== null
                              ? moment(createWeanCalf?.startDate)
                              : null
                          }
                          placeholder={translation.defaultDatePickerPlaceholder}
                          format={"MM/YYYY"}
                          onChange={(date) =>
                            handleValueChange("createWeanCalfStartDate", date)
                          }
                        />
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                        <div
                          className="flex"
                          style={{ justifyContent: "center" }}
                        >
                          <span>Final</span>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <DatePicker.MonthPicker
                          name="createWeanCalfEndDate"
                          value={
                            createWeanCalf?.endDate !== null
                              ? moment(createWeanCalf?.endDate)
                              : null
                          }
                          placeholder={translation.defaultDatePickerPlaceholder}
                          disabledDate={(current) =>
                            current.isBefore(createWeanCalf?.startDate)
                          }
                          format={"MM/YYYY"}
                          onChange={(date) =>
                            handleValueChange("createWeanCalfEndDate", date)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* IEP */}
                <Row type="flex" justify="start" style={{ marginTop: 15 }}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                        <div className="flex">
                          <span>{translation.kpi.iep}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipIEP}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                        <NumberFormat
                          customInput={Input}
                          name="createIepGoalValue"
                          placeholder={"Definir"}
                          decimalScale={0}
                          decimalSeparator=","
                          thousandSeparator="."
                          maxLength={5}
                          fixedDecimalScale={true}
                          value={createIEP?.goalValue}
                          addonAfter="Dias"
                          onValueChange={({ floatValue }) =>
                            handleValueChange("createIepGoalValue", floatValue)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                        <div
                          className="flex"
                          style={{ justifyContent: "center" }}
                        >
                          <span>Início</span>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <DatePicker.MonthPicker
                          name="createIepStartDate"
                          value={
                            createIEP?.startDate !== null
                              ? moment(createIEP?.startDate)
                              : null
                          }
                          placeholder={translation.defaultDatePickerPlaceholder}
                          format={"MM/YYYY"}
                          onChange={(date) =>
                            handleValueChange("createIepStartDate", date)
                          }
                        />
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                        <div
                          className="flex"
                          style={{ justifyContent: "center" }}
                        >
                          <span>Final</span>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <DatePicker.MonthPicker
                          name="createIepEndDate"
                          value={
                            createIEP?.endDate !== null
                              ? moment(createIEP?.endDate)
                              : null
                          }
                          placeholder={translation.defaultDatePickerPlaceholder}
                          disabledDate={(current) =>
                            current.isBefore(createIEP?.startDate)
                          }
                          format={"MM/YYYY"}
                          onChange={(date) =>
                            handleValueChange("createIepEndDate", date)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* ReproductiveEfficiency */}
                <Row type="flex" justify="start" style={{ marginTop: 15 }}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                        <div className="flex">
                          <span>{translation.kpi.reproductiveEfficiency}</span>
                          <InfoTooltip
                            placement="top"
                            title={
                              translation.kpi.tooltips
                                .tooltipReproductiveEfficiency
                            }
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                        <NumberFormat
                          customInput={Input}
                          name="createReproductiveEfficiencyGoalValue"
                          placeholder={"Definir"}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={createReproductiveEfficiency?.goalValue}
                          addonAfter="%"
                          maxLength={6}
                          onValueChange={({ floatValue }) =>
                            handleValueChange(
                              "createReproductiveEfficiencyGoalValue",
                              floatValue > 100 ? 100 : floatValue
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* WeanCalfCost */}
                <Row type="flex" justify="start" style={{ marginTop: 15 }}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                        <div className="flex">
                          <span>{translation.kpi.weanCalfCost}</span>
                          <InfoTooltip
                            placement="top"
                            title={translation.kpi.tooltips.tooltipWeanCalfCost}
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                        <NumberFormat
                          customInput={Input}
                          name="createWeanCalfCostGoalValue"
                          placeholder={"Definir"}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          maxLength={9}
                          value={createWeanCalfCost?.goalValue}
                          addonBefore={
                            createWeanCalfCost?.kpi?.monetary ? "R$" : null
                          }
                          onValueChange={({ floatValue }) =>
                            handleValueChange(
                              "createWeanCalfCostGoalValue",
                              floatValue
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                        <div
                          className="flex"
                          style={{ justifyContent: "center" }}
                        >
                          <span>Início</span>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <DatePicker.MonthPicker
                          name="createWeanCalfCostStartDate"
                          value={
                            createWeanCalfCost?.startDate !== null
                              ? moment(createWeanCalfCost?.startDate)
                              : null
                          }
                          placeholder={translation.defaultDatePickerPlaceholder}
                          format={"MM/YYYY"}
                          onChange={(date) =>
                            handleValueChange(
                              "createWeanCalfCostStartDate",
                              date
                            )
                          }
                        />
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                        <div
                          className="flex"
                          style={{ justifyContent: "center" }}
                        >
                          <span>Final</span>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <DatePicker.MonthPicker
                          name="createWeanCalfCostEndDate"
                          value={
                            createWeanCalfCost?.endDate !== null
                              ? moment(createWeanCalfCost?.endDate)
                              : null
                          }
                          placeholder={translation.defaultDatePickerPlaceholder}
                          disabledDate={(current) =>
                            current.isBefore(createWeanCalfCost?.startDate)
                          }
                          format={"MM/YYYY"}
                          onChange={(date) =>
                            handleValueChange("createWeanCalfCostEndDate", date)
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* DiscardedCows */}
                <Row type="flex" justify="start" style={{ marginTop: 15 }}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                        <div className="flex">
                          <span>{translation.kpi.discardedCows}</span>
                          <InfoTooltip
                            placement="top"
                            title={
                              translation.kpi.tooltips.tooltipDiscardedCows
                            }
                          ></InfoTooltip>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                        <NumberFormat
                          customInput={Input}
                          name="createDiscardedCowsGoalValue"
                          placeholder={"Definir"}
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          value={createDiscardedCows?.goalValue}
                          addonAfter="%"
                          maxLength={6}
                          onValueChange={({ floatValue }) =>
                            handleValueChange(
                              "createDiscardedCowsGoalValue",
                              floatValue > 100 ? 100 : floatValue
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Row type="flex" justify="start">
                      <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                        <div
                          className="flex"
                          style={{ justifyContent: "center" }}
                        >
                          <span>Início</span>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <DatePicker.MonthPicker
                          name="createDiscardedCowsStartDate"
                          value={
                            createDiscardedCows?.startDate !== null
                              ? moment(createDiscardedCows?.startDate)
                              : null
                          }
                          placeholder={translation.defaultDatePickerPlaceholder}
                          format={"MM/YYYY"}
                          onChange={(date) =>
                            handleValueChange(
                              "createDiscardedCowsStartDate",
                              date
                            )
                          }
                        />
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                        <div
                          className="flex"
                          style={{ justifyContent: "center" }}
                        >
                          <span>Final</span>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <DatePicker.MonthPicker
                          name="createDiscardedCowsEndDate"
                          value={
                            createDiscardedCows?.endDate !== null
                              ? moment(createDiscardedCows?.endDate)
                              : null
                          }
                          placeholder={translation.defaultDatePickerPlaceholder}
                          disabledDate={(current) =>
                            current.isBefore(createDiscardedCows?.startDate)
                          }
                          format={"MM/YYYY"}
                          onChange={(date) =>
                            handleValueChange(
                              "createDiscardedCowsEndDate",
                              date
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
        <Footer>
          <Row type="flex" justify="space-between">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="selectDiv"
            ></Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="buttonsDiv">
              <ButtonStandard
                type="button"
                buttonType="typeWithoutBackground"
                color="#d44c4c"
                isUpperCase={true}
                onClick={onCancel}
              >
                {translation.buttons.cancel}
              </ButtonStandard>

              <ButtonStandard
                type="submit"
                buttonType="typeWithoutBackground"
                color="#684E94"
                isUpperCase={true}
                onClick={() => handleSubmit()}
              >
                {translation.buttons.save}
              </ButtonStandard>
            </Col>
          </Row>
        </Footer>
      </Spin>
    </Container>
  );
};

export default EditKPIs;
