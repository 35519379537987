import React, { createContext, useCallback, useEffect, useState } from "react";
import { currencies } from "../../utils/currency";
import { useSelector } from "react-redux";
import {
  findAllFarmCurrency,
  findFarmStandardCurrency,
} from "../../services/farmCurrencyService";
import { getParameterItems } from "../../services/generalParameterService";
import { findAllCurrenciesConversions } from "../../services/currencyConversionService";

export const CurrencyContext = createContext({
  defaultCurrency: currencies.find((currency) => currency.code === "BRL"),
  defaultCurrencyCode: currencies.find((currency) => currency.code === "BRL")
    .code,
  shouldShowConversion: false,
  farmCurrencies: [],
  refreshCurrencyContextValues: () => {},
  convertCurrency: (basedCurrency = "BRL", currency = "BRL", value = 0) => {},
});

export const CurrencyContextProvider = ({ children }) => {
  const [parameter4001Value, setParameter4001Value] = useState(null);
  const [defaultCurrency, setDefaultCurrency] = useState(
    currencies.find((currency) => currency.code === "BRL")
  );
  const [farmCurrencies, setFarmCurrencies] = useState([]);
  const [currencyConversions, setCurrencyConversions] = useState(null);

  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const fetchCurrenciesConversions = useCallback(async () => {
    try {
      const {
        data: { results },
      } = await findAllCurrenciesConversions();
      let data = {};
      Object.entries(results?.conversions).forEach((value) => {
        let values = {};
        value[1].forEach((item) => {
          values[item.currency] = item.valueConverted;
        });
        data[value[0]] = values;
      });
      setCurrencyConversions(data);
    } catch (error) {
      console.error(error);
    } finally {
    }
  }, []);

  const fetchConversionParameter = useCallback(async () => {
    let dataResult = [];
    const CODES_PARAMETERS = [4001];
    try {
      for (var i = 0; i < CODES_PARAMETERS.length; i++) {
        let code = CODES_PARAMETERS[i];
        let {
          data: { results },
        } = await getParameterItems({ groupId, farmId, code });
        results.isDefault = results.farmId == null && results.groupId == null;
        results.updated = false;
        dataResult.push(results);
      }
      if (dataResult.length > 0) {
        setParameter4001Value(
          dataResult.find((parameter) => parameter.code === 4001)
        );
      }
    } catch (error) {
    } finally {
    }
  }, [groupId, farmId]);

  const fetchStandardCurrency = useCallback(async () => {
    try {
      const {
        data: { results },
      } = await findFarmStandardCurrency({
        groupId,
        farmId,
      });
      const currencySelected = currencies.find(
        (currency) => currency.code === results.currency
      );
      setDefaultCurrency(currencySelected);
    } catch (error) {}
  }, [groupId, farmId]);

  const fetchFarmCurrencies = useCallback(async () => {
    try {
      const {
        data: { results },
      } = await findAllFarmCurrency({
        groupId,
        farmId,
      });
      let farmCur = [];
      if (results && results.length > 0) {
        const activeCurrencies = results.filter((currency) =>
          ["Active", "A"].includes(currency.status)
        );
        for (let index = 0; index < activeCurrencies.length; index++) {
          const element = activeCurrencies[index];
          const currency = currencies.find(
            (currency) => currency.code === element.currency
          );
          farmCur.push(currency);
        }
      }
      setFarmCurrencies(farmCur);
    } catch (error) {}
  }, [groupId, farmId]);

  const refreshCurrencyContextValues = useCallback(async () => {
    await Promise.all([
      fetchConversionParameter(),
      fetchStandardCurrency(),
      fetchFarmCurrencies(),
      fetchCurrenciesConversions(),
    ]);
  }, [
    fetchConversionParameter,
    fetchStandardCurrency,
    fetchFarmCurrencies,
    fetchCurrenciesConversions,
  ]);

  const convertCurrency = useCallback(
    async (basedCurrency = "BRL", currency = "BRL", value = 0) => {
      return await new Promise((resolve) => {
        try {
          setTimeout(() => {
            const currencyTxConversion =
              currencyConversions[basedCurrency][currency];

            if (!currencyTxConversion) {
              resolve({
                status: "error",
                value: value,
              });
            }

            const valueConverted = value / currencyTxConversion;

            resolve({
              status: "success",
              value: Number.isNaN(valueConverted) ? null : valueConverted,
            });
          }, 2000);
        } catch (error) {
          resolve({
            status: "error",
            value: value,
          });
        }
      });
    },
    [currencyConversions]
  );

  useEffect(() => {
    refreshCurrencyContextValues();

    return () => {
      setDefaultCurrency(
        currencies.find((currency) => currency.code === "BRL")
      );
      setParameter4001Value(null);
      setFarmCurrencies([]);
    };
  }, [refreshCurrencyContextValues]);

  const value = {
    defaultCurrency,
    defaultCurrencyCode:
      parameter4001Value?.value === "true" ? defaultCurrency.code : "BRL",
    shouldShowConversion: parameter4001Value?.value === "true",
    farmCurrencies,
    refreshCurrencyContextValues,
    convertCurrency,
  };

  return (
    <CurrencyContext.Provider value={value}>
      {children}
    </CurrencyContext.Provider>
  );
};
