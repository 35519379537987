import React, { useState, useEffect } from "react";
import { withRouter, useRouteMatch, useHistory } from "react-router-dom";
import {
	Spin,
	Row
} from "antd";

/* Styles */
import {
	Wrapper,
	Container,
	RegisterContainer,
	DisplayContainer,
	DisplayBody,
	DisplayBodyMessage,
	LogoContainer,
	TitleMessage,
	MessageContainer,
} from "./styles";

/* Components */
import ButtonStandard from "../../components/utils/button";
import IconJustConfirm from "./icons/justConfirm";

/* Images */
import LogoVertical from "../../components/login/logos/logoVertical";

/** Services */
import { verifyApprovalStatus } from "../../services/supplierManagementService";

const AccessSupplierGroupConfirmation = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(null);

	/** Variable props */
	const {
		params: { id: userConfirmationId },
	} = useRouteMatch();

	// Effect
	useEffect(() => {
		async function fetchData() {
			setLoading(true);
			try {
				const {
					data: {
						results: {
							status
						},
					},
				} = await verifyApprovalStatus(userConfirmationId);

				setStatus(status);
				setLoading(false);
			} catch (error) {
				setStatus(null);
				setLoading(false);
			}
		}
		fetchData();
	}, [userConfirmationId]);

	// Methods
	function goToLogin() {
		history.push("/login");
	}

	return (
		<Wrapper>
			<span className="logo-vertical">
				<LogoVertical />
			</span>
			<Container>
				<RegisterContainer>
					<LogoContainer>
						<LogoVertical />
					</LogoContainer>
					<DisplayBodyMessage>
						<span>
							Decisões que <br />
							engordam <strong>o bolso</strong>
						</span>
					</DisplayBodyMessage>
				</RegisterContainer>
				<DisplayContainer>
					<DisplayBody>
						<Spin spinning={loading}>
							{status === "Approved" ? (
								<MessageContainer>
									<Row>
										<IconJustConfirm />
									</Row>
									<Row className="rowTitle">
										<TitleMessage>Convite foi aprovado!</TitleMessage>
									</Row>
									<Row>
										<ButtonStandard
											buttonType="typeWithoutBackground"
											padding="0px 0px"
											isUpperCase={true}
											onClick={goToLogin}
										>
											OK, ENTENDI
										</ButtonStandard>
									</Row>
								</MessageContainer>) : null}

						</Spin>
					</DisplayBody>
				</DisplayContainer>
			</Container>
		</Wrapper>
	);
};

export default withRouter(AccessSupplierGroupConfirmation);
