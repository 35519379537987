import React from "react";
import NumberFormat from "react-number-format";
// import { Container } from './styles';

const NumberInput = props => {
  return (
    <NumberFormat
      className="ant-input"
      decimalScale={"2"}
      maxLength={"20"}
      {...props}
    />
  );
};

export default NumberInput;
