import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { Button, Col, Icon, Input, Modal, Row, Table } from "antd";
import Column from "antd/lib/table/Column";
import { Container } from "./styles";
import ButtonStandard from "../../utils/button";
import useWindowSize from "../../../hooks/useWindowSize";

function SelectAnimalsModal({
  data,
  visible,
  selectedAnimalsKeys,
  onConfirmSelection,
  onCancel,
  isMultiple = true,
  forReproductionPurpose = false,
}) {
  const tableRef = useRef(null);
  const { width } = useWindowSize();
  const [selectedRowsKeys, setSelectedRowKeys] = useState([]);
  const [filters, setFilters] = useState(null);
  const [pagination, setPagination] = useState({
    total: null,
    size: 10,
    page: 0,
  });
  const { translation } = useSelector((state) => state.app);

  useEffect(() => {
    setFilters(null);
    setPagination({
      size: 10,
      page: 0,
    });
    setSelectedRowKeys(selectedAnimalsKeys);
  }, [selectedAnimalsKeys]);

  const onSelectRowTable = isMultiple
    ? {
        type: "checkbox",
        selectedRowKeys: selectedRowsKeys,
        getCheckboxProps: (record) => ({
          disabled: selectedAnimalsKeys.includes(record.id),
        }),
        onSelect: (record, selected, selectedRows, nativeEvent) => {
          if (selected) {
            setSelectedRowKeys((old) => [...old, record.id]);
          } else {
            setSelectedRowKeys((old) => old.filter((r) => r !== record.id));
          }
        },
        onSelectAll: (record, selected, selectedRows, nativeEvent) => {
          // Get just the ids
          const keys = selectedRows.map((sr) => sr.id);
          // Get all the selectedAnimalsKeys unless the unselected
          const lastSelectedAnimalsKeys = selectedRowsKeys.filter(
            (sAK) => !keys.includes(sAK)
          );
          if (record) {
            setSelectedRowKeys((old) => [...old, ...keys]);
          } else {
            setSelectedRowKeys(lastSelectedAnimalsKeys);
          }
        },
      }
    : {
        type: "radio",
        selectedRowKeys: selectedRowsKeys,
        getCheckboxProps: (record) => ({
          disabled: selectedAnimalsKeys.includes(record.id),
        }),
        onSelect: (record, selected, selectedRows, nativeEvent) => {
          if (selected) {
            setSelectedRowKeys((old) => [record.id]);
          } else {
            setSelectedRowKeys((old) => old.filter((r) => r !== record.id));
          }
        },
        onSelectAll: (record, selected, selectedRows, nativeEvent) => {
          // Get just the ids
          const keys = selectedRows.map((sr) => sr.id);
          // Get all the selectedAnimalsKeys unless the unselected
          const lastSelectedAnimalsKeys = selectedRowsKeys.filter(
            (sAK) => !keys.includes(sAK)
          );
          if (record) {
            setSelectedRowKeys((old) => [...keys]);
          } else {
            setSelectedRowKeys(lastSelectedAnimalsKeys);
          }
        },
      };

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys ? selectedKeys[0] : undefined}
            placeholder="Digite sua pesquisa"
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return record[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    }),
    [handleReset, handleSearch]
  );

  const doConfirm = () => {
    onConfirmSelection(selectedRowsKeys);
  };

  const doCancel = () => {
    setPagination({
      total: null,
      size: 10,
      page: 0,
    });
    setFilters(null);
    setSelectedRowKeys([]);
    onCancel();
  };

  const onTableChange = (pagination, filters, sorter) => {
    setPagination({
      total: pagination.total,
      page: pagination.current,
      size: pagination.pageSize,
    });
    setFilters(filters);
  };

  return (
    <Modal
      visible={visible}
      width={width >= 1440 ? 1000 : 800}
      title={isMultiple ? "Selecione os animais" : "Selecione o animal"}
      closable={false}
      footer={null}
    >
      <Container>
        <Row type="flex">
          <Col span={24}>
            <Table
              ref={tableRef}
              dataSource={data}
              rowKey="id"
              scroll={{
                x: true,
              }}
              size="small"
              rowSelection={onSelectRowTable}
              onChange={onTableChange}
              pagination={{
                current: pagination.page,
                hideOnSinglePage: pagination.size > 10 ? false : true,
                pageSize: pagination.size,
                showSizeChanger: true,
                pageSizeOptions: [
                  "10",
                  "20",
                  "30",
                  "100",
                  "500",
                  "1000",
                  "2000",
                ],
              }}
            >
              <Table.Column
                title={translation.animal.columns.handlingNumber}
                dataIndex="handlingNumber"
                sorter={(first, second) => {
                  if (!first.handlingNumber) {
                    first.handlingNumber = "";
                  }
                  return first.handlingNumber.localeCompare(
                    second.handlingNumber,
                    "pt-BR",
                    {
                      numeric: false,
                      ignorePunctuation: true,
                    }
                  );
                }}
                key="handlingNumber"
                filteredValue={filters?.handlingNumber || null}
                {...handleGetColumnSearchProps("handlingNumber")}
              />
              {forReproductionPurpose === true && (
                <Table.Column
                  title={translation.animal.columns.reproductionCategory}
                  dataIndex="animalReproductionCategory"
                  key="animalReproductionCategory"
                  filteredValue={filters?.animalReproductionCategory || null}
                  sorter={(first, second) => {
                    if (!first.animalReproductionCategory) {
                      first.animalReproductionCategory = "";
                    }
                    return first.animalReproductionCategory.localeCompare(
                      second.animalReproductionCategory,
                      "pt-BR",
                      {
                        numeric: false,
                        ignorePunctuation: true,
                      }
                    );
                  }}
                  {...handleGetColumnSearchProps("animalReproductionCategory")}
                  render={(text) => (
                    <span>
                      {text != null
                        ? translation.animalReproductionCategory[text]
                        : ""}
                    </span>
                  )}
                />
              )}
              {forReproductionPurpose === true && (
                <Table.Column
                  title={translation.animal.columns.reproductionStatus}
                  dataIndex="femaleSituation"
                  key="femaleSituation"
                  filteredValue={filters?.femaleSituation || null}
                  filters={[
                    {
                      text: "Servida",
                      value: "servida",
                    },
                    {
                      text: "Prenha",
                      value: "prenha",
                    },
                    {
                      text: "Vazia",
                      value: "vazia",
                    },
                  ]}
                  filtered
                  filterMultiple
                  align="left"
                  width={150}
                  sorter={(first, second) => {
                    if (!first.femaleSituation) {
                      first.femaleSituation = "";
                    }
                    return first.femaleSituation.localeCompare(
                      second.femaleSituation,
                      "pt-BR",
                      {
                        numeric: false,
                        ignorePunctuation: true,
                      }
                    );
                  }}
                  onFilter={(value, record) => {
                    if (
                      filters === null ||
                      !Object.keys(filters).includes("femaleSituation")
                    ) {
                      setFilters((old) =>
                        old != null
                          ? { ...old, femaleSituation: value }
                          : { femaleSituation: value }
                      );
                    }
                    return record.femaleSituation === value;
                  }}
                  render={(text) => (
                    <span>{text ? translation.femaleSituation[text] : ""}</span>
                  )}
                />
              )}
              <Column
                title={translation.animal.columns.breed}
                dataIndex="breedName"
                key="breedName"
                filteredValue={filters?.breedName || null}
                sorter={(first, second) => {
                  if (!first.breedName) {
                    first.breedName = "";
                  }
                  return first.breedName.localeCompare(
                    second.breedName,
                    "pt-BR",
                    {
                      numeric: false,
                      ignorePunctuation: true,
                    }
                  );
                }}
                {...handleGetColumnSearchProps("breedName")}
              />
              <Column
                title={translation.animal.columns.gender}
                dataIndex="gender"
                key="gender"
                filteredValue={filters?.gender || null}
                filters={[
                  {
                    text: "Macho",
                    value: "M",
                  },
                  {
                    text: "Fêmea",
                    value: "F",
                  },
                ]}
                filtered
                filterMultiple={false}
                onFilter={(value, record) => {
                  if (
                    filters === null ||
                    !Object.keys(filters).includes("gender")
                  ) {
                    setFilters((old) =>
                      old != null
                        ? { ...old, gender: value }
                        : { gender: value }
                    );
                  }
                  return record.gender === value;
                }}
                sorter={(first, second) => {
                  if (!first.gender) {
                    first.gender = "";
                  }
                  return first.gender.localeCompare(second.gender, "pt-BR", {
                    numeric: false,
                    ignorePunctuation: true,
                  });
                }}
                sortDirections={["descend", "ascend"]}
                render={(text, record) => (
                  <span>
                    {record.gender === "M"
                      ? translation.animal.details.male
                      : translation.animal.details.female}
                  </span>
                )}
              />
              <Column
                title={translation.animal.columns.birthdayMonths}
                dataIndex="birthdayMonths"
                key="birthdayMonths"
                sorter={(first, second) => {
                  if (!first.birthdayMonths) {
                    first.birthdayMonths = 0;
                  }
                  return first.birthdayMonths.localeCompare(
                    second.birthdayMonths,
                    "pt-BR",
                    {
                      numeric: true,
                      ignorePunctuation: true,
                    }
                  );
                }}
                sortDirections={["descend", "ascend"]}
              />
              <Column
                title={translation.animal.columns.lot}
                dataIndex="lotName"
                key="lotName"
                filteredValue={filters?.lotName || null}
                sorter={(first, second) => {
                  if (!first.lotName) {
                    first.lotName = "";
                  }
                  return first.lotName.localeCompare(second.lotName, "pt-BR", {
                    numeric: false,
                    ignorePunctuation: true,
                  });
                }}
                {...handleGetColumnSearchProps("lotName")}
              />
              <Column
                title={translation.animal.columns.picket}
                dataIndex="picketName"
                filteredValue={filters?.picketName || null}
                key="picketName"
                sorter={(first, second) => {
                  if (!first.picketName) {
                    first.picketName = "";
                  }
                  return first.picketName.localeCompare(
                    second.picketName,
                    "pt-BR",
                    {
                      numeric: false,
                      ignorePunctuation: true,
                    }
                  );
                }}
                {...handleGetColumnSearchProps("picketName")}
              />
              <Table.Column
                title={translation.animal.columns.sisbov}
                dataIndex="sisbov"
                sorter={(first, second) => {
                  if (!first.sisbov) {
                    first.sisbov = "";
                  }
                  return first.sisbov.localeCompare(second.sisbov, "pt-BR", {
                    numeric: false,
                    ignorePunctuation: true,
                  });
                }}
                key="sisbov"
                filteredValue={filters?.sisbov || null}
                {...handleGetColumnSearchProps("sisbov")}
              />
              <Table.Column
                title={translation.animal.columns.tagId}
                dataIndex="tagId"
                sorter={(first, second) => {
                  if (!first.tagId) {
                    first.tagId = "";
                  }
                  return first.tagId.localeCompare(second.tagId, "pt-BR", {
                    numeric: false,
                    ignorePunctuation: true,
                  });
                }}
                key="tagId"
                filteredValue={filters?.tagId || null}
                {...handleGetColumnSearchProps("tagId")}
              />
            </Table>
          </Col>
        </Row>
        <Row type="flex" style={{ marginTop: 8 }}>
          <Col span={24}>
            <Row type="flex" justify="end" gutter={8}>
              <Col>
                <ButtonStandard
                  type="button"
                  buttonType="type7"
                  onClick={doCancel}
                >
                  {translation.buttons.cancel}
                </ButtonStandard>
              </Col>
              <Col>
                <ButtonStandard
                  type="button"
                  buttonType="type6"
                  onClick={doConfirm}
                >
                  {translation.buttons.confirm}
                </ButtonStandard>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}

export default SelectAnimalsModal;
