import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as FarmDeathReasonActions } from "../../../store/ducks/farmDeathReason";

// Components
import { Container, Footer } from "./styles";
import { Row, Col, Input, notification, Spin } from "antd";
import ButtonStandard from "../../utils/button";

// Services
import { save, findById, update } from "../../../services/farmDeathReasonService";

const DrawerFarmDeathReason = () => {

	const [form, setForm] = useState({
		name: null,
	});

	const [errors, setErrors] = useState([]);
	const [loadRequest, setLoadRequest] = useState(false);
	// Redux Variable
	const {
		translation,
		groupSelected: { id: groupId },
		farmSelected: { id: farmId },
	} = useSelector((state) => state.app);

	const { farmDeathReasonId, isDrawerVisible } = useSelector((state) => state.farmDeathReason);

	const dispatch = useDispatch();

	// Methods
	function resetDrawer() {
		setErrors([]);
		setForm({
			name: null,
		});
	}

	function closeDrawer() {
		resetDrawer();
		dispatch(FarmDeathReasonActions.showOrHideDrawer());
	}

	async function handleSubmitForm() {
		if (validateForm()) {
			// Prepare request body
			const body = { ...form };

			setLoadRequest(true);

			//Save or Update
			try {
				if (farmDeathReasonId !== null) {
					await update({
						groupId,
						farmId,
						id: farmDeathReasonId,
						body,
					});

					notification.success({
						message: "Causa mortis atualizada com sucesso",
					});
				} else {
					await save({
						groupId,
						farmId,
						body,
					});

					notification.success({
						message: "Causa mortis cadastrada com sucesso",
					});
				}

				dispatch(FarmDeathReasonActions.index(groupId, farmId));
				setLoadRequest(false);
				closeDrawer();
			} catch (error) {
				notification.error({
					message: "Houve um erro ao cadastrar a causa mortis",
				});
				setLoadRequest(false);
			}
		
		}
	}

	function validateForm() {
		let errors = [];

		if (form?.name === null || form.name.trim() === "") {
			errors.push("name");
		}

		setErrors(errors);
		return errors.length > 0 ? false : true;
	}

	async function handleDrawerVisible(visible) {
		if (visible) {
			if (farmDeathReasonId !== null) {
				setLoadRequest(true);
				try {
					const { data: { results: farmDeathReasonData } } = await findById({ groupId, farmId, id: farmDeathReasonId });
					if (farmDeathReasonData !== null) {
						setForm({
							name: farmDeathReasonData?.name,
						});
					}
					setLoadRequest(false);
				} catch (error) {
					setLoadRequest(false);
				}
			}
		}
	}

	return (
		<Container
			title={farmDeathReasonId === null ? "Nova Causa Mortis" : "Editar Causa Mortis"}
			width={700}
			onClose={closeDrawer}
			maskClosable={false}
			visible={isDrawerVisible}
			afterVisibleChange={handleDrawerVisible}
		>
			<div className="drawerForm">
				<Spin spinning={loadRequest}>
					{farmDeathReasonId === null && (
						<Row type="flex">
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<span className="subTitle">
									Preencha os campos abaixo para cadastrar uma nova causa mortis
								</span>
							</Col>
						</Row>)}
					{errors.length > 0 && (
						<Row type="flex" justify="center" align="middle">
							<label className="error">{translation.error.formError}</label>
						</Row>
					)}
					<Row type="flex" className="rowLabel">
						<label className={errors.includes("name") ? "error" : ""}>
							Nome*
						</label>
					</Row>
					{/* Name */}
					<Row type="flex">
						<Input
							name="name"
							value={form?.name !== null ? form.name : null}
							type="name"
							placeholder={translation.defaultPlaceholder}
							autoCapitalize="off"
							onChange={(e) => {
								setForm({ ...form, name: e.target.value });
							}}
						/>
					</Row>

				</Spin>
			</div>
			<Footer>
				<Row type="flex">
					<Col span={24} className="buttonsDiv">
						<ButtonStandard
							type="button"
							buttonType="type7"
							onClick={closeDrawer}
						>
							{translation.buttons.cancel}
						</ButtonStandard>

						<ButtonStandard type="button" buttonType="type6" onClick={handleSubmitForm}>
							{translation.buttons.save}
						</ButtonStandard>
					</Col>
				</Row>
			</Footer>
		</Container>
	);
};

export default DrawerFarmDeathReason;
