import React, { useCallback, useEffect, useState } from "react";
import Axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
// Components
import { Icon, Spin } from "antd";
import { Main, Container } from "./styles";
import MaleIcon from "../../icons/dashboard/male";
import FemaleIcon from "../../icons/dashboard/female";
// Services
import { numberMask } from "../../../../services/helpersMethodsService";
import {
  getAnimalCountDashboard,
  getAnimalWeightStatisticsByMaleGenderDashboard,
  getAnimalWeightStatisticsByFemaleGenderDashboard,
  reprocessAnimalDashboard,
} from "../../../../services/dashboards/dashboardAnimalService";
import { returnObjectFromMapValues } from "../../../../services/dashboards/dashboardCommonService";
import InfoTooltip from "../../../../components/utils/infoTooltip";
import ButtonStandard from "../../../../components/utils/button";

const AnimalWeightAndCountDash = ({ onCallBack }) => {
  // Variables
  const [isLoading, setIsLoading] = useState(false);
  const [totalAnimals, setTotalAnimals] = useState({
    total: 0,
    male: 0,
    female: 0,
  });
  const [maleStatistics, setMaleStatistics] = useState({
    weight: 0,
    gdp: 0,
    age: 0,
  });
  const [femaleStatistics, setFemaleStatistics] = useState({
    weight: 0,
    gdp: 0,
    age: 0,
  });
  // Redux variables
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const handleRefresh = useCallback(async () => {
    if (groupId && farmId) {
      setIsLoading(true);
      try {
        await Promise.all([
          reprocessAnimalDashboard({
            groupId,
            farmId,
            dashboard: "AnimalCountDashboard",
          }),
          reprocessAnimalDashboard({
            groupId,
            farmId,
            dashboard: "AnimalWeightStatisticsByMaleGenderDashboard",
          }),
          reprocessAnimalDashboard({
            groupId,
            farmId,
            dashboard: "AnimalWeightStatisticsByFemaleGenderDashboard",
          }),
        ]);
        const [
          {
            data: { results: countDashValues },
          },
          {
            data: { results: maleDashValues },
          },
          {
            data: { results: femaleDashValues },
          },
        ] = await Promise.all([
          getAnimalCountDashboard({
            groupId,
            farmId,
          }),
          getAnimalWeightStatisticsByMaleGenderDashboard({
            groupId,
            farmId,
          }),
          getAnimalWeightStatisticsByFemaleGenderDashboard({
            groupId,
            farmId,
          }),
        ]);

        const animalCounts = returnObjectFromMapValues(countDashValues);
        setTotalAnimals({
          total: animalCounts.AnimalsTotalCount,
          male: animalCounts.AnimalsMaleCount,
          female: animalCounts.AnimalsFemaleCount,
        });

        const maleValues = returnObjectFromMapValues(maleDashValues);
        const ageMales = moment().diff(
          moment(maleValues?.AgeAverage),
          "months",
          false
        );
        setMaleStatistics({
          weight: numberMask(maleValues?.AverageWeight || 0),
          gdp: numberMask(maleValues?.AverageGdp || 0),
          age: ageMales,
        });

        const femaleValues = returnObjectFromMapValues(femaleDashValues);
        const ageFemales = moment().diff(
          moment(femaleValues?.AgeAverage),
          "months",
          false
        );
        setFemaleStatistics({
          weight: numberMask(femaleValues?.AverageWeight || 0),
          gdp: numberMask(femaleValues?.AverageGdp || 0),
          age: ageFemales,
        });
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  }, [farmId, groupId]);

  // Effect
  useEffect(() => {
    const signal = Axios.CancelToken.source();
    async function fetchData() {
      if (groupId == null || farmId == null) {
        setTotalAnimals({
          total: 0,
          male: 0,
          female: 0,
        });
        setMaleStatistics({
          weight: 0,
          gdp: 0,
          age: 0,
        });
        setFemaleStatistics({
          weight: 0,
          gdp: 0,
          age: 0,
        });
      } else {
        try {
          const [
            {
              data: { results: countDashValues },
            },
            {
              data: { results: maleDashValues },
            },
            {
              data: { results: femaleDashValues },
            },
          ] = await Promise.all([
            getAnimalCountDashboard({
              groupId,
              farmId,
            }),
            getAnimalWeightStatisticsByMaleGenderDashboard({
              groupId,
              farmId,
            }),
            getAnimalWeightStatisticsByFemaleGenderDashboard({
              groupId,
              farmId,
            }),
          ]);

          const animalCounts = returnObjectFromMapValues(countDashValues);
          setTotalAnimals({
            total: animalCounts.AnimalsTotalCount,
            male: animalCounts.AnimalsMaleCount,
            female: animalCounts.AnimalsFemaleCount,
          });

          const maleValues = returnObjectFromMapValues(maleDashValues);
          const ageMales = moment().diff(
            moment(maleValues?.AgeAverage),
            "months",
            false
          );
          setMaleStatistics({
            weight: numberMask(maleValues?.AverageWeight || 0),
            gdp: numberMask(maleValues?.AverageGdp || 0),
            age: ageMales,
          });

          const femaleValues = returnObjectFromMapValues(femaleDashValues);
          const ageFemales = moment().diff(
            moment(femaleValues?.AgeAverage),
            "months",
            false
          );
          setFemaleStatistics({
            weight: numberMask(femaleValues?.AverageWeight || 0),
            gdp: numberMask(femaleValues?.AverageGdp || 0),
            age: ageFemales,
          });
        } catch (error) {
        } finally {
          setIsLoading(false);
        }
      }
    }
    fetchData();
    return () => {
      setTotalAnimals({
        total: 0,
        male: 0,
        female: 0,
      });
      setMaleStatistics({
        weight: 0,
        gdp: 0,
        age: 0,
      });
      setFemaleStatistics({
        weight: 0,
        gdp: 0,
        age: 0,
      });
      signal.cancel();
    };
  }, [groupId, farmId]);

  return (
    <Container>
      <Spin spinning={isLoading}>
        <Main>
          <div className="activeAnimals">
            <span className="numberOfAnimals">{totalAnimals?.total || 0}</span>
            <span className="title">ANIMAIS ATIVOS</span>
            <InfoTooltip title="É considerado como animais ativos, todos os animais que não estejam inativos, vendidos ou mortos." />
          </div>
          <div className="unit">
            <div className="header">
              <span className="header">{`Dados referentes a ${moment().format(
                "DD/MM/YYYY"
              )}`}</span>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={handleRefresh}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </div>
            <div className="data">
              <div className="col">
                <div className="cell">
                  <br />
                </div>
                <div className="cell">
                  <MaleIcon />
                  <strong className="genderCount">
                    {totalAnimals?.male || 0}
                  </strong>
                </div>
                <div className="cell">
                  <FemaleIcon />
                  <strong className="genderCount">
                    {totalAnimals?.female || 0}
                  </strong>
                </div>
              </div>
              {/* Weight average */}
              <div className="col">
                <div className="cell">
                  <label className="lblGenderValues">Peso médio</label>
                </div>
                <div className="cell">
                  <span className="genderValues">
                    <strong>{numberMask(maleStatistics?.weight || 0)}</strong>{" "}
                    kg
                  </span>
                </div>
                <div className="cell">
                  <span className="genderValues">
                    <strong>{numberMask(femaleStatistics?.weight || 0)}</strong>{" "}
                    kg
                  </span>
                </div>
              </div>
              {/* GDP */}
              <div className="col">
                <div className="cell">
                  <label className="lblGenderValues">GDP médio</label>
                </div>
                <div className="cell">
                  <span className="genderValues">
                    <strong>{numberMask(maleStatistics?.gdp || 0)}</strong> kg
                  </span>
                </div>
                <div className="cell">
                  <span className="genderValues">
                    <strong>{numberMask(femaleStatistics?.gdp || 0)}</strong> kg
                  </span>
                </div>
              </div>
              {/* Age average */}
              <div className="col">
                <div className="cell">
                  <label className="lblGenderValues">Idade média</label>
                </div>
                <div className="cell">
                  <span className="genderValues">
                    <strong>{maleStatistics?.age || 0}</strong> Meses
                  </span>
                </div>
                <div className="cell">
                  <span className="genderValues">
                    <strong>{femaleStatistics?.age || 0}</strong> Meses
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Main>
      </Spin>
    </Container>
  );
};

export default AnimalWeightAndCountDash;
