import styled from "styled-components";
import { Card } from "antd";

export const CardCustom = styled(Card)`
  margin-top: 13px !important;
  border-radius: 7px !important;
  background: #fff !important;
  box-shadow: 0px 0px 10px #d8d8d8 !important;
  overflow-y: auto;
  height: 580px;
  padding: 0px !important;
  .ant-card-body {
    padding-top: 14px;
  }
  .rowHeader {
    margin-bottom: 17px;
  }
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;

export const NewSupplement = styled.button`
  width: 194.2px;
  height: 24px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #684e94;
  color: #684e94;
  text-align: center;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
`;

export const TagStatus = styled.span`
  padding: 2px 5px !important;
  border-radius: 5px !important;
  color: ${(props) => props.color}!important;
  font-size: 12px !important;
  font-weight: bold !important;
  border: ${(props) => `1px solid ${props.borderColor}`};
  background: ${(props) => props.background} !important;
`;

export const PageTitle = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 25px;
  text-align: left;
  color: #9074bf;
`;

export const DeleteButton = styled.button`
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #c94c4c;
  border: none;
  background: none;

  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
    path {
      fill: #c94c4c !important;
    }
  }

  &:hover {
    color: #eea29a;
    cursor: pointer;

    svg {
      margin-left: 5px;
      path {
        fill: #a9c133 !important;
      }
    }
  }

  &:disabled {
    color: #a5a5a5;
    cursor: not-allowed;
    svg {
      margin-left: 5px;
      path {
        fill: #a5a5a5 !important;
      }
    }
  }
`;
