import styled, { keyframes } from "styled-components";
import { darken, lighten } from "polished";

const opacityAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;

  .logo-vertical {
    display: none;
  }

  @media all and (max-width: 1024px) {
    .logo-vertical {
      margin-top: 30px;
      display: flex;
      display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox; /* TWEENER - IE 10 */
      display: -webkit-flex; /* NEW - Chrome */
      justify-content: center;
    }
  }

  @media all and (max-width: 900px) {
    .logo-vertical {
      margin-top: 30px;
      display: flex;
      display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox; /* TWEENER - IE 10 */
      display: -webkit-flex; /* NEW - Chrome */
      justify-content: center;
    }
  }

  @media screen and (max-width: 479px) {
    .logo-vertical {
      margin-top: 20px;
      display: flex;
      display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox; /* TWEENER - IE 10 */
      display: -webkit-flex; /* NEW - Chrome */
      justify-content: center;
    }
  }

  @media (min-width: 320px) and (max-width: 480px) {
    overflow-y: auto;
    .logo-vertical {
      margin-top: 20px;
      display: flex;
      display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox; /* TWEENER - IE 10 */
      display: -webkit-flex; /* NEW - Chrome */
      justify-content: center;
    }
  }
`;

export const Container = styled.div`
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 100%;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  flex-direction: row;
  background: #ffffff;

  @media all and (width: 812px) and (height: 375px) {
    height: 100%;
    min-height: 100%;
    width: 100%;
    min-width: 100%;
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  @media all and (max-width: 767px) {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex;
    flex-direction: column;
  }
`;

export const RegisterContainer = styled.div`
  position: relative;
  width: 500px;
  height: 100vh;
  min-height: 100vh;
  -webkit-box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.4);

  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  flex-direction: column;

  align-items: flex-start;

  background: #f5f5f5;

  padding: 40px 57px 32px 20px;

  svg {
    width: 136.56px !important;
    height: 24px !important;
    margin-left: 19px;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const LogoContainer = styled.div`
  text-align: center;
  margin-top: 200px;
  svg {
    width: 136.56px !important;
    height: 24px !important;
    margin-left: 19px;
  }

  @media screen and (max-width: 1024px) {
    /* start of large tablet styles */
    width: 100% !important;
    svg {
      width: auto !important;
      height: auto !important;
      margin: 0;
      margin-top: 40px;
    }
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
    width: 100% !important;
    svg {
      width: auto !important;
      height: auto !important;
      margin: 0;
    }
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
    width: 100% !important;
    svg {
      width: auto !important;
      height: auto !important;
      margin: 0;
    }
  }
`;

export const FormContainer = styled.div`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > * {
    position: relative;
    animation: ${opacityAnimation};
    -webkit-animation: ${opacityAnimation};
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
  }

  .hide {
    display: none;
  }

  .back-container {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
  }

  .title {
    position: relative;
    font-family: "Asap", sans-serif;
    font-weight: bold;
    font-size: 27px;
    color: #111;
    margin-left: 19px;
    margin-top: 10px;
  }

  .back {
    position: relative;
    font-family: "Asap", sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #d44c4c;
    margin-left: 5px;
  }

  .subTitle {
    font-family: "Asap", sans-serif;
    font-weight: normal;
    font-size: 16px;
    color: #111;
    margin-top: 10px;
    margin-left: 19px;
  }

  label {
    margin-left: 19px !important;
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #9074bf;
    margin-top: 10px;
    margin-bottom: 5px;

    &.error {
      color: #d44c4c;
    }
  }

  div.form {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

    span.error {
      color: #c14933;
      margin-left: 19px;
    }
    a {
      margin-top: 20px;
      font-size: 1em;
      text-decoration: none;
    }

    .formFooter {
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        margin-left: 19px;
        margin-bottom: 0;
      }
    }
    @media screen and (max-width: 1024px) {
      .formFooter {
        justify-content: center;
      }
    }
  }

  .input {
    max-width: 455px;
  }

  @media screen and (max-width: 479px) {
    .title {
      font-size: 20px;
    }
  }

  @media all and (max-height: 700px) {
    min-height: 720px;
    overflow-y: auto;
    div.form {
      width: 100%;
      padding: 0 5px;
    }
  }

  @media screen and (max-width: 1024px) {
    /* start of large tablet styles */
    div.form {
      width: 100%;
      padding: 0 5px;
    }
  }

  /*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

  @media (min-width: 320px) and (max-width: 480px) {
    .title {
      font-size: 19px;
    }
    .subTitle {
      font-size: 13px;
    }
    div.form {
      width: 100%;
      padding: 0 5px;
    }
  }
`;

export const FormContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-right: 24px;
  padding-left: 24px;
  margin-bottom: 70px;

  .title {
    font-family: "Asap", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    /* identical to box height */

    color: #591f74;
    &.small {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .subTitle {
    font-family: "Asap", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #591f74;
  }

  .marginRow {
    margin-top: 10px;
  }

  .marginDivider {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .actionButtonRow {
    height: 100%;
    align-content: end;
  }

  .back-container {
    display: flex;
    align-items: center;
    padding-right: 10px;
    cursor: pointer;
  }

  .back {
    position: relative;
    font-family: "Asap", sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #d44c4c;
    margin-left: 5px;
  }

  label {
    display: block;

    font-family: "Asap", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    color: #9074bf;

    margin-left: 19px;
    margin-top: 8px;
    margin-bottom: 8px;

    &.error {
      color: #d44c4c;
    }
  }

  .ant-input {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 10px !important;

    &:hover,
    &:active,
    &:focus {
      background: transparent !important;
      box-shadow: none !important;
    }
  }

  .input {
    position: relative;
    background: transparent !important;
    border: 1px solid #8a50a5;
    box-shadow: none !important;
    border-radius: 10px !important;
    margin-bottom: 16px;
    width: 100% !important;
    transition: all 0.2s ease;

    &.small {
      margin-bottom: 0px;
      height: 32px !important;
    }

    &.hectarInput {
      padding-left: 10px;
      padding-right: 10px;

      &.disable {
        background-color: #f5f5f5 !important;
        color: rgba(0, 0, 0, 0.25);
      }
    }

    &.documentInput {
      padding-left: 10px;
      padding-right: 10px;
      border: 1px solid #8a50a5 !important;
    }

    div.icon {
      width: 50px;
      height: 50px;
      & > svg {
        width: 50px;
        height: 50px;
      }
    }

    &.no-margin-bottom {
      margin-bottom: 0px;
    }

    &.text-uppercase {
      input {
        text-transform: uppercase !important;
        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          text-transform: none !important;
          color: #591f74 !important;
        }

        &::placeholder {
          text-transform: none !important;
          color: #591f74 !important;
        }
      }
    }

    &.disable {
      cursor: not-allowed;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        z-index: 2;

        background-color: rgba(196, 196, 196, 0.2);
      }
    }

    &:hover,
    &:active,
    &:focus {
      background: transparent !important;
      box-shadow: none !important;
    }
  }

  input {
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #591f74 !important;
    }

    &::placeholder {
      color: #591f74 !important;
    }
  }

  .ant-row-flex {
    width: 100%;
    & > button {
      margin-top: 10px;
    }
  }

  form {
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

    button.resend-code {
      margin: 16px 0;

      border: none;
      background: transparent;

      cursor: pointer;

      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      text-decoration-line: underline;

      color: #8a50a5;
    }

    span.error {
      color: #c14933;
      margin-left: 19px;
    }

    .ant-select-selection {
      background: transparent !important;
      border: 1px solid #8a50a5 !important;
      box-shadow: none !important;
      border-radius: 10px !important;
    }

    .hide {
      display: none;
    }

    div.captcha {
      width: 100%;
      margin-top: 24px;
      display: flex;
      justify-content: center;
    }
  }

  .form-footer {
  }

  // X-Small devices (portrait phones, less than 576px)
  @media all and (max-width: 575.98px) {
    padding-left: 0;
  }

  // Small devices (landscape phones, less than 768px)
  @media all and (max-width: 767.98px) {
    padding-left: 0;
  }
`;

export const DisplayContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100%;

  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: center;
  }
`;

export const DisplayBody = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1024px) {
    width: 100% !important;
    min-width: 100% !important;
    height: 100% !important;
    min-height: 100% !important;
    margin-top: 45px;
    margin-left: 50px;
    justify-content: initial;
  }

  @media screen and (max-width: 1024px) {
    justify-content: flex-start;
    align-items: center;
    margin-top: 0px;
    padding-top: 50px;
  }

  @media screen and (max-height: 800px) {
    overflow-y: auto;
    padding-top: 30px;
  }

  @media screen and (max-width: 479px) {
    justify-content: flex-start;
    padding-top: 10px;
  }
`;

export const DisplayBodyMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    -webkit-animation: ${opacityAnimation};
    animation: ${opacityAnimation};
    animation-timing-function: ease-in;
  }

  svg {
    width: 111px !important;
    height: 130px !important;
    margin-right: 41px !important;
    animation-duration: 1s;
    path {
      fill: #fff !important;
      opacity: 1 !important;
    }
  }
  span {
    padding-left: 19px;
    font-family: "Asap", sans-serif;
    font-size: 24px;
    text-align: left;
    color: #c4c4c4;
    animation-duration: 2s;
  }
`;

export const PageTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;

export const FormFooterContaier = styled.div`
  width: 100%;

  margin-top: 32px;
  margin-left: 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 242px;
    height: 40px;

    cursor: pointer;

    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;

    border: none;
    border-radius: 10px;

    transition: background-color 0.5s ease;

    &.next {
      margin-left: auto;
      background: #8a50a5;
      color: #ffffff;
    }

    &.previous {
      background: #ffffff;
      color: #8a50a5;
    }

    &:disabled {
      background: #c4c4c4;
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      &.next {
        background-color: ${lighten(0.2, "#8A50A5")};
      }

      &.previous {
        background-color: ${darken(0.2, "#FFFFFF")};
      }
    }
  }

  // Small devices (landscape phones, less than 768px)
  @media all and (max-width: 767.98px) {
    width: 100%;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      height: 48px;

      & + button {
        margin-top: 32px;
      }

      &.next {
        margin-left: 0;
      }
    }
  }
`;
