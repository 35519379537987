import React, { useState, useEffect, useRef } from "react";
import { withRouter, useRouteMatch, useHistory } from "react-router-dom";

// Components
import NumberFormat from "react-number-format";
import { Spin, Row, Col, notification, Checkbox } from "antd";
import InputStandard from "../../components/utils/input";
import PhoneNumberInput from "../../components/utils/input/phoneNumberInput";
import {
  Wrapper,
  Container,
  RegisterContainer,
  LogoContainer,
  DisplayBodyMessage,
  DisplayContainer,
  DisplayBody,
  FormContainer,
  FormFooterContaier,
} from "./styles";

// Services
import {
  getPreRegisterData,
  registerUserBySupplierGroupInvite,
} from "../../services/supplierManagementService";
import {
  getOnlyNumber,
  validatePhone,
  validateCPF,
} from "../../services/helpersMethodsService";

//Libs
import * as Yup from "yup";
import { Formik } from "formik";

// Assets
import LogoVertical from "../../components/login/logos/logoVertical";
import codicoesRegras from "../../assets/documents/CondicoesRegras.pdf";

const RegisterSupplierGroup = (props) => {
  /** Variable props */
  const {
    params: { id },
  } = useRouteMatch();

  // Variable
  const [isLoading, setIsLoading] = useState(true);
  const [form, setForm] = useState(null);

  // Effect
  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const {
          data: { results },
        } = await getPreRegisterData(id);
        setForm(results);
        setIsLoading(false);
      } catch (error) {
        notification.error({
          message: "Não foi possível buscar as informações do convite",
        });
        setForm(null);
        setIsLoading(false);
      }
    }
    fetchData();
  }, [id]);

  const history = useHistory();
  const formik = useRef(null);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required(),
    phone: Yup.string().required(),
    documentNumber: Yup.string().required(),
    password: Yup.string().required(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required(),
  });

  async function handleSubmitForm(values, props, event) {
    event.preventDefault();
    setIsLoading(true);
    if (formIsValid(values)) {
      const { fullName, phone, password, documentNumber, term } = values;

      let body = {
        supplierGroupInviteId: id,
        fullName: fullName,
        phone: getOnlyNumber(phone),
        documentNumber: getOnlyNumber(documentNumber),
        password: password,
        term: term,
      };

      try {
        await registerUserBySupplierGroupInvite(body);

        notification.success({
          message: "Cadastro realizado com sucesso!",
        });
        history.push("/login");
      } catch (e) {
        const errorMessage = e.response?.data?.message;
        if (
          errorMessage &&
          errorMessage.includes("#AUTHENTICATION_ERROR_001")
        ) {
          notification.error({
            message:
              "Erro ao efetuar o cadastro. CPF já cadastrado em nosso sistema.",
          });
        } else if (
          errorMessage &&
          errorMessage.includes("#AUTHENTICATION_ERROR_002")
        ) {
          notification.error({
            message:
              "Erro ao efetuar o cadastro. E-mail já cadastrado em nosso sistema.",
          });
        } else {
          notification.error({
            message: "Ocorreu um erro ao tentar salvar as informações!",
          });
        }
      }
    }

    setIsLoading(false);
  }

  // Validate form
  function formIsValid(values) {
    let isValid = true;

    const { fullName, phone, documentNumber, password, confirmPassword, term } =
      values;

    if (fullName == null || fullName?.trim() === "") {
      isValid = false;
      notification.error({
        message: "É necessário informar o Nome.",
      });
    } else if (phone == null || phone.trim() === "") {
      isValid = false;
      notification.error({
        message: "É necessário informar o Telefone.",
      });
    } else if (!validatePhone(phone)) {
      isValid = false;
      notification.error({
        message: "Telefone Inválido.",
      });
    } else if (!validateCPF(documentNumber)) {
      isValid = false;
      notification.error({
        message: "O CPF informado é invalido, informe outro.",
      });
    } else if (password == null || password.trim() === "") {
      isValid = false;
      notification.error({
        message: "É necessário informar uma Senha.",
      });
    } else if (confirmPassword == null || confirmPassword.trim() === "") {
      isValid = false;
      notification.error({
        message: "É necessário informar confirmação da senha.",
      });
    } else if (password != confirmPassword) {
      isValid = false;
      notification.error({
        message: "Senha e Confirmação da Senha precisam ser iguais.",
      });
    } else if (term == null || !term) {
      isValid = false;
      notification.error({
        message: "É necessário aceitar os termos de serviço.",
      });
    }

    return isValid;
  }

  return (
    <Wrapper>
      <span className="logo-vertical">
        <LogoVertical />
      </span>
      <Container>
        <RegisterContainer>
          <LogoContainer>
            <LogoVertical />
          </LogoContainer>
          <DisplayBodyMessage>
            <span>
              Decisões que <br />
              engordam <strong>o bolso</strong>
            </span>
          </DisplayBodyMessage>
        </RegisterContainer>
        <DisplayContainer>
          <Spin spinning={isLoading}>
            <DisplayBody>
              {form != null ? (
                <FormContainer>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Formik
                      ref={formik}
                      initialValues={form}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmitForm}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        ...props
                      }) => (
                        <form>
                          {/* Tittle */}
                          <Row type="flex" className="marginRow">
                            <Col>
                              <Row>
                                <span className="title">
                                  Cadastro de usuário
                                </span>
                              </Row>
                              <Row>
                                <span className="title small">
                                  Preencha o formulário abaixo para acessar
                                  bovexo
                                </span>
                              </Row>
                            </Col>
                          </Row>
                          <Row type="flex">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <Row>
                                <label
                                  className={
                                    errors.fullName && touched.fullName
                                      ? "error"
                                      : ""
                                  }
                                >
                                  Nome Completo*
                                </label>
                              </Row>
                              <Row>
                                <InputStandard
                                  border={
                                    errors.fullName && touched.fullName
                                      ? "error"
                                      : "normal"
                                  }
                                  className={"input"}
                                  width="100%"
                                >
                                  <input
                                    placeholder="Informe aqui..."
                                    maxLength="255"
                                    name="fullName"
                                    value={values?.fullName || ""}
                                    autoComplete="chrome-off"
                                    onChange={(event) =>
                                      props.setFieldValue(
                                        "fullName",
                                        event.target.value
                                      )
                                    }
                                    onBlur={handleBlur}
                                  />
                                </InputStandard>
                              </Row>
                            </Col>
                          </Row>
                          {/* Input phone */}
                          <Row type="flex">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <Row>
                                <label
                                  className={
                                    errors.phone && touched.phone ? "error" : ""
                                  }
                                >
                                  Contato*
                                </label>
                              </Row>
                              <Row>
                                <InputStandard
                                  border={
                                    errors.phone && touched.phone
                                      ? "error"
                                      : "normal"
                                  }
                                  width="100%"
                                  className="input"
                                >
                                  <PhoneNumberInput
                                    name="phone"
                                    value={values?.phone || ""}
                                    autoComplete="chrome-off"
                                    placeholder="(xx) xxxxx-xxxx"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </InputStandard>
                              </Row>
                            </Col>
                          </Row>
                          {/* Input documentNumber */}
                          <Row type="flex">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <Row>
                                <label
                                  className={
                                    errors.documentNumber &&
                                    touched.documentNumber
                                      ? "error"
                                      : ""
                                  }
                                >
                                  CPF*
                                </label>
                              </Row>
                              <Row>
                                <InputStandard
                                  border={
                                    errors.documentNumber &&
                                    touched.documentNumber
                                      ? "error"
                                      : "normal"
                                  }
                                  className={"input"}
                                  width="100%"
                                >
                                  <NumberFormat
                                    type="text"
                                    placeholder="Informe aqui..."
                                    name="documentNumber"
                                    value={values.documentNumber || undefined}
                                    autoComplete="chrome-off"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    format="###.###.###-##"
                                  />
                                </InputStandard>
                              </Row>
                            </Col>
                          </Row>
                          {/* Input password */}
                          <Row type="flex">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <Row>
                                <label
                                  className={
                                    errors.password && touched.password
                                      ? "error"
                                      : ""
                                  }
                                >
                                  Senha*
                                </label>
                              </Row>
                              <Row>
                                <InputStandard
                                  border={
                                    errors.password && touched.password
                                      ? "error"
                                      : "normal"
                                  }
                                  className={"input"}
                                  width="100%"
                                >
                                  <input
                                    type="password"
                                    placeholder="Senha"
                                    name="password"
                                    autoComplete="chrome-off"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </InputStandard>
                              </Row>
                            </Col>
                          </Row>
                          {/* Input confirmPassword */}
                          <Row type="flex">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <Row>
                                <label
                                  className={
                                    errors.confirmPassword &&
                                    touched.confirmPassword
                                      ? "error"
                                      : ""
                                  }
                                >
                                  Confirme a senha*
                                </label>
                              </Row>
                              <Row>
                                <InputStandard
                                  border={
                                    errors.confirmPassword &&
                                    touched.confirmPassword
                                      ? "error"
                                      : "normal"
                                  }
                                  className={"input"}
                                  width="100%"
                                >
                                  <input
                                    type="password"
                                    placeholder="Confirme a senha"
                                    name="confirmPassword"
                                    autoComplete="chrome-off"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </InputStandard>
                              </Row>
                            </Col>
                          </Row>

                          {/* Checkbox term */}
                          <Row type="flex">
                            <Checkbox
                              checked={values.term}
                              onChange={() =>
                                props.setFieldValue("term", !values.term)
                              }
                              style={{
                                fontFamily: "Asap, sans-serif",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: "16px",
                                lineHeight: "21px",
                                textTransform: "none",
                                color: "#591F74",
                                marginTop: 16,
                              }}
                            >
                              Ao assinalar a caixa você concorda com as{" "}
                              <a
                                href={codicoesRegras}
                                download="Condicoes e Regras de Uso BovExo.pdf"
                              >
                                <strong>condições e regras de uso</strong>
                              </a>{" "}
                              do sistema Bovexo
                            </Checkbox>
                          </Row>

                          <FormFooterContaier>
                            <Row type="flex" justify="start">
                              <Col xs={5} sm={5} md={4} lg={3} xl={2}>
                                <button
                                  disabled={!values.term}
                                  type="submit"
                                  className="next"
                                  onClick={(event) =>
                                    handleSubmitForm(values, props, event)
                                  }
                                >
                                  Salvar
                                </button>
                              </Col>
                            </Row>
                          </FormFooterContaier>
                        </form>
                      )}
                    </Formik>
                  </Col>
                </FormContainer>
              ) : null}
            </DisplayBody>
          </Spin>
        </DisplayContainer>
      </Container>
    </Wrapper>
  );
};

export default withRouter(RegisterSupplierGroup);
