import apiFinancial from "../config/api_financial";
import getHeaders from "./getHeaders";

export function getSummaryFarmCosts(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/costs/summary`,
    getHeaders(false, signal)
  );
}

export function saveOrUpdateCosts(payload) {
  const { groupId, farmId, body, signal } = payload;

  return apiFinancial.put(
    `/bovexo/${groupId}/farms/${farmId}/costs`,
    body,
    getHeaders(false, signal)
  );
}
export function saveOrUpdateSummarizeCosts(payload) {
  const { groupId, farmId, body, signal } = payload;

  return apiFinancial.put(
    `/bovexo/${groupId}/farms/${farmId}/costs/summary`,
    body,
    getHeaders(false, signal)
  );
}

export function getFarmCosts(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/costs`,
    getHeaders(false, signal)
  );
}
