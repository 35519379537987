import React, { Component } from "react";

// Components
import { Container, CardItem } from "./styles";
import { Col, Tooltip } from "antd";

import AlertIcon from "./icons/alert";

class DrawerKpiWhatToDo extends Component {

  render() {
    const { visible, onCloseDrawer, data } = this.props;

    return (
      <Container
        title={"O QUE FAZER?"}
        width={511}
        onClose={onCloseDrawer}
        maskClosable={false}
        visible={visible}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
        {data?.map(reason => 
            (reason?.sugestions.map(sugestion => 
                <CardItem key={sugestion?.id}>
                    <div>
                        <AlertIcon />
                        <Tooltip placement="topLeft" title={(<div dangerouslySetInnerHTML={{ __html: sugestion.steps }}/>)}>
                            <span>
                                {sugestion.name}
                            </span>
                        </Tooltip>
                    </div> 
                </CardItem>
            ))
        )}        
        </Col>
      </Container>
    );
  }
}

export default DrawerKpiWhatToDo;
