import { call, put } from "redux-saga/effects";
import { Creators as BreedReproductionScoreActions } from "../ducks/breedReproductionScore";

import { notification } from "antd";

// Services
import {
  findAllBreedReproductionScoreByGroupIdAndFarmId,
  saveBreedReproductionScore,
} from "../../services/breedReproductionScoreService";

export function* indexBreedReproductionScore(action) {
  try {
    const {
      data: { results: data },
    } = yield call(
      findAllBreedReproductionScoreByGroupIdAndFarmId,
      action.payload
    );
    yield put(BreedReproductionScoreActions.getAllDataSuccess(data));
  } catch (error) {
    yield put(BreedReproductionScoreActions.getAllDataError(error));
  }
}

export function* storeOrBreedReproductionScore(action) {
  try {
    const {
      data: { results },
    } = yield call(saveBreedReproductionScore, action.payload);
    yield put(BreedReproductionScoreActions.saveSuccess(results));
    if (action.payload.id == null)
      notification.success({ message: "Parametrização criada com sucesso." });
    else
      notification.success({
        message: "Parametrização atualizada com sucesso.",
      });
  } catch (error) {
    const {
      response: {
        data: { code: errorCode },
      },
    } = error;
    if (errorCode === 5022) {
      notification.error({
        message: "Parametrização não foi criada.",
        description:
          "Já existe uma parametrização para esta Idade e para esta raça.",
      });
    } else {
      notification.error({ message: "Parametrização não foi criada." });
    }
    yield put(BreedReproductionScoreActions.saveError(error));
  }
}
