import React, { useCallback, useEffect, useState } from "react";
import CanvasJSReact from "../../../../../../assets/canvasjs.react";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { getFuturePriceChart } from "../../../../../../services/futurePriceService";
import moment from "moment";
import { getTwoDecimalDigits } from "../../../../../../services/helpersMethodsService";

const { CanvasJSChart } = CanvasJSReact;

const CONTRACT01_VALUES = [
  { x: moment("28/06/2023", "DD/MM/YYYY").toDate(), y: 189.0 },
  { x: moment("05/07/2023", "DD/MM/YYYY").toDate(), y: 190.12 },
  { x: moment("12/07/2023", "DD/MM/YYYY").toDate(), y: 191.24 },
  { x: moment("19/07/2023", "DD/MM/YYYY").toDate(), y: 192.37 },
  { x: moment("26/07/2023", "DD/MM/YYYY").toDate(), y: 193.49 },
  { x: moment("02/08/2023", "DD/MM/YYYY").toDate(), y: 194.61 },
  { x: moment("09/08/2023", "DD/MM/YYYY").toDate(), y: 195.73 },
  { x: moment("16/08/2023", "DD/MM/YYYY").toDate(), y: 196.85 },
  { x: moment("23/08/2023", "DD/MM/YYYY").toDate(), y: 197.98 },
  { x: moment("30/08/2023", "DD/MM/YYYY").toDate(), y: 199.1 },
  { x: moment("06/09/2023", "DD/MM/YYYY").toDate(), y: 200.22 },
  { x: moment("13/09/2023", "DD/MM/YYYY").toDate(), y: 201.34 },
  { x: moment("20/09/2023", "DD/MM/YYYY").toDate(), y: 202.46 },
  { x: moment("27/09/2023", "DD/MM/YYYY").toDate(), y: 203.59 },
  { x: moment("04/10/2023", "DD/MM/YYYY").toDate(), y: 204.71 },
  { x: moment("11/10/2023", "DD/MM/YYYY").toDate(), y: 205.83 },
  { x: moment("18/10/2023", "DD/MM/YYYY").toDate(), y: 206.95 },
  { x: moment("25/10/2023", "DD/MM/YYYY").toDate(), y: 208.07 },
  { x: moment("01/11/2023", "DD/MM/YYYY").toDate(), y: 209.2 },
  { x: moment("08/11/2023", "DD/MM/YYYY").toDate(), y: 210.32 },
  { x: moment("15/11/2023", "DD/MM/YYYY").toDate(), y: 211.44 },
  { x: moment("22/11/2023", "DD/MM/YYYY").toDate(), y: 212.56 },
  { x: moment("29/11/2023", "DD/MM/YYYY").toDate(), y: 213.68 },
];

const CONTRACT02_VALUES = [
  { x: moment("28/06/2023", "DD/MM/YYYY").toDate(), y: 201.0 },
  { x: moment("05/07/2023", "DD/MM/YYYY").toDate(), y: 201.52 },
  { x: moment("12/07/2023", "DD/MM/YYYY").toDate(), y: 202.05 },
  { x: moment("19/07/2023", "DD/MM/YYYY").toDate(), y: 202.57 },
  { x: moment("26/07/2023", "DD/MM/YYYY").toDate(), y: 203.1 },
  { x: moment("02/08/2023", "DD/MM/YYYY").toDate(), y: 203.62 },
  { x: moment("09/08/2023", "DD/MM/YYYY").toDate(), y: 204.15 },
  { x: moment("16/08/2023", "DD/MM/YYYY").toDate(), y: 204.67 },
  { x: moment("23/08/2023", "DD/MM/YYYY").toDate(), y: 205.2 },
  { x: moment("30/08/2023", "DD/MM/YYYY").toDate(), y: 205.72 },
  { x: moment("06/09/2023", "DD/MM/YYYY").toDate(), y: 206.25 },
  { x: moment("13/09/2023", "DD/MM/YYYY").toDate(), y: 206.77 },
  { x: moment("20/09/2023", "DD/MM/YYYY").toDate(), y: 207.3 },
  { x: moment("27/09/2023", "DD/MM/YYYY").toDate(), y: 207.82 },
  { x: moment("04/10/2023", "DD/MM/YYYY").toDate(), y: 208.35 },
  { x: moment("11/10/2023", "DD/MM/YYYY").toDate(), y: 208.87 },
  { x: moment("18/10/2023", "DD/MM/YYYY").toDate(), y: 209.4 },
  { x: moment("25/10/2023", "DD/MM/YYYY").toDate(), y: 209.92 },
  { x: moment("01/11/2023", "DD/MM/YYYY").toDate(), y: 210.45 },
  { x: moment("08/11/2023", "DD/MM/YYYY").toDate(), y: 210.97 },
  { x: moment("15/11/2023", "DD/MM/YYYY").toDate(), y: 211.5 },
  { x: moment("22/11/2023", "DD/MM/YYYY").toDate(), y: 212.02 },
  { x: moment("29/11/2023", "DD/MM/YYYY").toDate(), y: 212.55 },
  { x: moment("06/12/2023", "DD/MM/YYYY").toDate(), y: 213.07 },
  { x: moment("13/12/2023", "DD/MM/YYYY").toDate(), y: 213.6 },
  { x: moment("20/12/2023", "DD/MM/YYYY").toDate(), y: 214.12 },
  { x: moment("27/12/2023", "DD/MM/YYYY").toDate(), y: 214.65 },
  { x: moment("03/01/2024", "DD/MM/YYYY").toDate(), y: 215.17 },
  { x: moment("10/01/2024", "DD/MM/YYYY").toDate(), y: 215.7 },
  { x: moment("17/01/2024", "DD/MM/YYYY").toDate(), y: 216.22 },
  { x: moment("24/01/2024", "DD/MM/YYYY").toDate(), y: 216.75 },
  { x: moment("31/01/2024", "DD/MM/YYYY").toDate(), y: 217.27 },
  { x: moment("07/02/2024", "DD/MM/YYYY").toDate(), y: 217.8 },
  { x: moment("14/02/2024", "DD/MM/YYYY").toDate(), y: 218.32 },
  { x: moment("21/02/2024", "DD/MM/YYYY").toDate(), y: 218.85 },
  { x: moment("28/02/2024", "DD/MM/YYYY").toDate(), y: 219.37 },
  { x: moment("06/03/2024", "DD/MM/YYYY").toDate(), y: 219.9 },
  { x: moment("13/03/2024", "DD/MM/YYYY").toDate(), y: 220.42 },
  { x: moment("20/03/2024", "DD/MM/YYYY").toDate(), y: 220.95 },
  { x: moment("27/03/2024", "DD/MM/YYYY").toDate(), y: 221.47 },
  { x: moment("03/04/2024", "DD/MM/YYYY").toDate(), y: 222.0 },
  { x: moment("10/04/2024", "DD/MM/YYYY").toDate(), y: 222.52 },
  { x: moment("17/04/2024", "DD/MM/YYYY").toDate(), y: 223.05 },
  { x: moment("24/04/2024", "DD/MM/YYYY").toDate(), y: 223.57 },
  { x: moment("01/05/2024", "DD/MM/YYYY").toDate(), y: 224.1 },
  { x: moment("08/05/2024", "DD/MM/YYYY").toDate(), y: 224.62 },
  { x: moment("15/05/2024", "DD/MM/YYYY").toDate(), y: 225.15 },
  { x: moment("22/05/2024", "DD/MM/YYYY").toDate(), y: 225.67 },
  { x: moment("29/05/2024", "DD/MM/YYYY").toDate(), y: 226.2 },
];

const SaleScenarioDetailsPricesLocksTabChart = ({
  marketAnalysisId,
  priceLockData,
}) => {
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const [data, setData] = useState(null);
  const [stripYLines, setStripYLines] = useState([]);

  const toggleDataSeries = useCallback(
    (e) => {
      const id = e?.dataSeries?.id;
      const index = data.findIndex((dc) => dc?.id === id);
      const item = data[index];
      if (
        data.filter((dc) => dc.visible).length > 1 ||
        item?.visible === false
      ) {
        const newDataChart = data.map((dc) =>
          id === dc.id ? { ...dc, visible: !dc.visible } : dc
        );
        setData(newDataChart);
      }
    },
    [data]
  );

  // Mount dashboards
  useEffect(() => {
    async function fetchMarketAnalysisData() {
      let chartData = [];
      if (marketAnalysisId) {
        try {
          const {
            data: { results: futurePrices },
          } = await getFuturePriceChart({
            groupId,
            farmId,
            marketAnalysisId: marketAnalysisId,
          });
          if (futurePrices.length > 0) {
            let values = [];
            futurePrices.forEach((r) => {
              if (moment(r.x).isBefore(moment("2024-12-31"))) {
                const x = moment(r.x).toDate();
                const y = Number.parseFloat(getTwoDecimalDigits(r.y));
                values.push({ x, y });
              }
            });
            chartData.push({
              id: "marketAnalysis",
              visible: true,
              type: "line",
              markerType: "none",
              color: "blue",
              showInLegend: true,
              name: "Curva de Preços",
              xValueFormatString: "DD/MM/YYYY",
              yValueFormatString: "R$#######.00",
              dataPoints: values,
            });
            buildStripLines(futurePrices);
            buildDataDashboard(chartData, futurePrices);
          }
        } catch (error) {
          buildDataDashboard([]);
        }
      }
    }
    function buildStripLines(futurePrices) {
      let stripLines = [];
      const min = futurePrices[0].y;
      const max = 330;
      let cont = Number(Number.parseFloat(min).toFixed(0));
      do {
        cont = cont + Number(Number.parseFloat(cont * 0.2).toFixed(0));
        stripLines.push({
          value: cont,
          label: `R$ ${cont}`,
          lineDashType: "dash",
          labelBackgroundColor: "#f2f2f2",
          labelFontColor: "black",
          labelPlacement: "inside",
          color: "#808080",
          showOnTop: true,
        });
      } while (cont <= max);
      setStripYLines(stripLines);
    }
    function buildDataDashboard(chartData = []) {
      let contractDashs = [];

      const contractDataDash01 = {
        type: "line",
        id: `contract_01`,
        visible: true,
        markerType: "none",
        showInLegend: true,
        name: `Contrato 01`,
        xValueFormatString: "DD/MM/YYYY",
        yValueFormatString: "R$#######.00",
        dataPoints: CONTRACT01_VALUES,
      };
      const contractDataDash02 = {
        type: "line",
        id: `contract_02`,
        visible: true,
        markerType: "none",
        showInLegend: true,
        name: `Contrato 02`,
        xValueFormatString: "DD/MM/YYYY",
        yValueFormatString: "R$#######.00",
        dataPoints: CONTRACT02_VALUES,
      };
      contractDashs.push(contractDataDash01);
      contractDashs.push(contractDataDash02);

      const scatter01 = {
        type: "scatter",
        id: `future`,
        visible: true,
        name: "Futuros Precificados",
        markerType: "triangle",
        showInLegend: true,
        xValueFormatString: "DD/MM/YYYY",
        yValueFormatString: "R$#######.00",
        dataPoints: [
          { x: moment("2023-12-05").toDate(), y: 245 },
          {
            x: moment("2023-12-05").toDate(),
            y: 254,
          },
          {
            x: moment("2023-12-05").toDate(),
            y: 250,
          },
          {
            x: moment("2023-12-05").toDate(),
            y: 243,
          },
          {
            x: moment("2023-12-05").toDate(),
            y: 248,
          },
          {
            x: moment("2023-12-05").toDate(),
            y: 267,
          },
          { x: moment("2024-06-04").toDate(), y: 220 },
          {
            x: moment("2024-06-04").toDate(),
            y: 230,
          },
          {
            x: moment("2024-06-04").toDate(),
            y: 245,
          },
          {
            x: moment("2024-06-04").toDate(),
            y: 250,
          },
        ],
      };

      setData([...chartData, ...contractDashs, scatter01]);
    }
    if (marketAnalysisId && priceLockData.length > 0) fetchMarketAnalysisData();
  }, [groupId, farmId, marketAnalysisId, priceLockData]);

  return (
    <Row type="flex">
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <CanvasJSChart
          options={{
            animationEnabled: true,
            zoomEnabled: true,
            backgroundColor: "transparent",
            height: 200,
            legend: {
              verticalAlign: "bottom",
              cursor: "pointer",
              itemclick: toggleDataSeries,
            },
            axisX: {
              valueFormatString: "MM/YY",
              crosshair: {
                enabled: true,
                snapToDataPoint: true,
              },
              // interval: 1,
              // intervalType: "month",
            },
            toolTip: {
              fontFamily: "Asap",
              shared: true,
            },
            axisY: {
              includeZero: true,
              prefix: "R$ ",
              minimum: 175,
              valueFormatString: "###0",
              gridThickness: 0,
              stripLines: stripYLines,
              crosshair: {
                enabled: true,
                snapToDataPoint: true,
              },
            },
            data: data,
          }}
        />
      </Col>
    </Row>
  );
};

export default SaleScenarioDetailsPricesLocksTabChart;
