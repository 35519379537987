import { call, put, select } from "redux-saga/effects";
import { Creators as PicketActions } from "../ducks/picket";
/** Services */
import {
  getPicketIndex,
  savePicket,
  getPicketShow,
  allocateLotInPicket,
  getPicketLotHistories,
} from "../../services/picketService";
import { notification } from "antd";

const getPicketState = (state) => state.picket;

export function* indexPicket(action) {
  try {
    const {
      data: { results: allPickets },
    } = yield call(getPicketIndex, action.payload);

    yield put(PicketActions.indexPicketSuccess(allPickets));
  } catch (error) {
    yield put(PicketActions.indexPicketError(error));
  }
}

export function* getPicket(action) {
  try {
    const {
      data: { results: picket },
    } = yield call(getPicketShow, action.payload);

    const {
      data: { results: lotHistories },
    } = yield call(getPicketLotHistories, action.payload);

    let sustenanceCapacityData = {
      pastureAnimalCapacity: picket.pastureAnimalCapacity,
      pastureArea: picket.pastureArea,
      pastureFertilized: picket.pastureFertilized,
      pastureId: picket.pastureId,
      pastureLeafDensity: picket.pastureLeafDensity,
      pastureDailyCost: picket.pastureDailyCost,
      pastureCurrentHeight: picket.pastureCurrentHeight,
      pastureCurrentHeightDate: picket.pastureCurrentHeightDate,
      pastureGrowthSpeed: picket.pastureGrowthSpeed,
      status: "I",
      pastures: [],
      supplements: [],
    };
    yield put(
      PicketActions.showPicketSuccess(
        picket,
        sustenanceCapacityData,
        lotHistories
      )
    );
  } catch (error) {
    console.error(error);
    yield put(PicketActions.showPicketError(error));
  }
}

export function* storePicket(action) {
  let result = null;
  try {
    const {
      data: { results: picket },
    } = yield call(savePicket, action.payload);
    const { saveOptions } = action.payload;
    if (action.payload.id == null) {
      notification.success({
        message: "Piquete / Baia criado com sucesso",
      });
    } else {
      notification.success({
        message: "Piquete / Baia atualizado com sucesso",
      });
    }
    result = picket;
    yield put(PicketActions.savePicketSuccess(picket, null, saveOptions));
  } catch (error) {
    notification.error({ message: "Piquete / Baia não foi criado" });
    yield put(PicketActions.savePicketError(error));
  }
  if (result != null && Object.entries(result).length > 0) {
    const {
      pagination: { page, size, sorter, filters },
    } = yield select(getPicketState);
    const newPayload = {
      ...action.payload,
      page,
      size,
      sorter,
      filters,
    };
    yield put(
      PicketActions.indexPicket(
        newPayload.groupId,
        newPayload.farmId,
        newPayload.page,
        newPayload.sorter,
        newPayload.filters,
        newPayload.size
      )
    );
  }
}

export function* allocateLotToPicket(action) {
  let result = null;
  try {
    const {
      data: { results: picket },
    } = yield call(allocateLotInPicket, action.payload);
    result = picket;
    notification.success({
      message: "Lote adicionado no piquete / baia com sucesso",
    });
    yield put(PicketActions.addLotToPicketSuccess(picket));
  } catch (error) {
    notification.error({
      message: "Ops, não teve como adicionar lote ao piquete / baia",
    });
    yield put(PicketActions.addLotToPicketError(error));
  }
  if (result != null && Object.entries(result).length > 0) {
    const {
      pagination: { page, size, sorter, filters },
    } = yield select(getPicketState);
    const newPayload = {
      ...action.payload,
      page,
      size,
      sorter,
      filters,
    };
    yield put(
      PicketActions.indexPicket(
        newPayload.groupId,
        newPayload.farmId,
        newPayload.page,
        newPayload.sorter,
        newPayload.filters,
        newPayload.size
      )
    );
  }
}
