import apiFarms from "../config/api_farms";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiFarms = axios.create({
//   baseURL: "http://localhost:8082",
// });

export function findFarmWeatherStationRainData(payload) {
  const { groupId, farmId, startDate, endDate } = payload;
  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/weather/station/rain?startDate=${startDate}&endDate=${endDate}`,
    getHeaders(false, null)
  );
}

export function findFarmWeatherStationBalanceHidricData(payload) {
  const { groupId, farmId, refDate, startDate, endDate, group, interval } =
    payload;
  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/weather/station/balance?refDate=${refDate}&startDate=${startDate}&endDate=${endDate}&group=${group}&interval=${interval}`,
    getHeaders(false, null)
  );
}

export function updateFarmWeatherStationRainDate(payload) {
  const { groupId, farmId, body } = payload;

  return apiFarms.put(
    `/bovexo/${groupId}/farms/${farmId}/weather/station/rain`,
    body,
    getHeaders(false, null)
  );
}
