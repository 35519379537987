import React, { useState } from "react";
import { useCallback } from "react";
import { createContext } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { getAnimalsWeightHistories } from "../../services/animalWeightHistoryService";

export const INITIAL_DATA_FORM_ANIMAL_WEIGHT_HISTORY = {
  id: null,
  handlingNumber: null,
  animalId: null,
  weight: null,
  date: moment(),
  type: "Manual",
  origination: "Manual",
  lotOriginalId: null,
  lotDestinationId: null,
  weaningWeight: false,
  newAnimalHandlingNumber: null,
  hasEditHandlingNumber: false,
  newAnimalSisbov: null,
  hasEditSisbov: false,
  newAnimalTag: null,
  hasEditAnimalTag: false,
  newAnimalNickName: null,
  hasEditNickName: false,
  saveOptions: "only_save",
};

export const AnimalWeightHistoryContext = createContext({
  dataList: [],
  tableProperties: {
    size: 10,
    page: 0,
    tableFilters: {},
    tableSorters: {},
    search: null,
  },
  showDrawerForm: false,
  showModalExport: false,
  isLoadingDataList: false,
  data: INITIAL_DATA_FORM_ANIMAL_WEIGHT_HISTORY,
  openOrCloseForm: () => {},
  openOrCloseModalExport: () => {},
  fetchData: (page = 0, size = 10, sort = {}, search = null) => {},
  initializeData: (data) => {},
  handleTableChange: (pagination, filters, sorter) => {},
});

export const AnimalWeightHistoryContextProvider = ({ children }) => {
  const [dataList, setDataList] = useState([]);
  const [tableProperties, setTableProperties] = useState({
    size: 10,
    page: 0,
    tableFilters: {},
    tableSorters: {},
    search: null,
  });
  const [data, setData] = useState(INITIAL_DATA_FORM_ANIMAL_WEIGHT_HISTORY);
  const [isLoadingDataList, setIsLoadingDataList] = useState(false);
  const [showDrawerForm, setShowDrawerForm] = useState(false);
  const [showModalExport, setShowModalExport] = useState(false);

  const [
    shouldShowWeightsOnlyForActiveAnimals,
    setShouldShowWeightsOnlyForActiveAnimals,
  ] = useState(true);

  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const fetchData = useCallback(
    async (page = 0, size = 10, sort = {}, search = null) => {
      setIsLoadingDataList(true);
      try {
        const {
          data: { results },
        } = await getAnimalsWeightHistories({
          groupId,
          farmId,
          page,
          size,
          sort,
          search,
        });
        setDataList(results);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingDataList(false);
      }
    },
    [farmId, groupId]
  );

  const initializeData = (data) => {
    if (data === null) {
      setData(INITIAL_DATA_FORM_ANIMAL_WEIGHT_HISTORY);
    } else {
      setData(data);
    }
  };

  const openOrCloseForm = () => {
    setShowDrawerForm((old) => !old);
  };

  const openOrCloseModalExport = useCallback(() => {
    setShowModalExport((old) => !old);
  }, []);

  const getSortString = useCallback((sorter) => {
    let customSort = sorter;

    if (sorter && Object.entries(sorter).length > 0) {
      const { field } = sorter;

      const fieldS = field
        ? field === "handlingNumber"
          ? "handling_number"
          : field === "originationLotName"
          ? "origination_lot_name"
          : field === "originationPicketName"
          ? "origination_picket_name"
          : field === "date"
          ? "weight_date"
          : field
        : null;

      customSort = {
        ...customSort,
        field: fieldS,
      };
    }

    return customSort;
  }, []);

  const getSearchString = useCallback(
    (filters) => {
      let search =
        shouldShowWeightsOnlyForActiveAnimals === true
          ? `animal_status = 'A'`
          : "";

      if (filters && Object.entries(filters).length > 0) {
        Object.entries(filters).forEach(([field, value]) => {
          if (value && value.length > 0) {
            const fieldS = field
              ? field === "handlingNumber"
                ? "handling_number"
                : field === "originationLotName"
                ? "origination_lot_name"
                : field === "originEvent"
                ? "event"
                : field === "originationPicketName"
                ? "origination_picket_name"
                : field === "date"
                ? "weight_date"
                : field
              : null;
            if (fieldS === "weight") {
              const operatorS =
                value[1] === "equal"
                  ? "="
                  : value[1] === "greaterThan"
                  ? ">"
                  : value[1] === "greaterOrEqualThan"
                  ? ">="
                  : value[1] === "lessThan"
                  ? "<"
                  : value[1] === "lessOrEqualThan"
                  ? "<="
                  : "=";
              search =
                search === ""
                  ? `${fieldS} ${operatorS} '${value[0]}'`
                  : `${search};${fieldS} ${operatorS} '${value[0]}'`;
            } else if (fieldS === "weight_date") {
              const operatorS =
                value[1] === "equal"
                  ? "="
                  : value[1] === "greaterThan"
                  ? ">"
                  : value[1] === "greaterOrEqualThan"
                  ? ">="
                  : value[1] === "lessThan"
                  ? "<"
                  : value[1] === "lessOrEqualThan"
                  ? "<="
                  : "=";

              search =
                search === ""
                  ? `cast(${fieldS} as date) ${operatorS} cast('${moment(
                      value[0]
                    ).format("YYYY-MM-DD")}' as date)`
                  : `${search};cast(${fieldS} as date) ${operatorS} cast('${moment(
                      value[0]
                    ).format("YYYY-MM-DD")}' as date)`;
            } else if (fieldS === "origination") {
              search =
                search === ""
                  ? `${fieldS} in (${value
                      .map((o) => (o = `'${o}'`))
                      .join(", ")})`
                  : `${search};${fieldS} in (${value
                      .map((o) => (o = `'${o}'`))
                      .join(", ")})`;
            } else {
              search =
                search === ""
                  ? `upper(${fieldS}) like upper('%25${value}%25')`
                  : `${search};upper(${fieldS}) like upper('%25${value}%25')`;
            }
          }
        });
      }

      return search !== "" ? search : "";
    },
    [shouldShowWeightsOnlyForActiveAnimals]
  );

  const handleTableChange = useCallback(
    async (pagination = null, filters = null, sorter = null) => {
      if (pagination && filters && sorter) {
        let customSort = getSortString(sorter);
        let search = getSearchString(filters);

        setTableProperties({
          tableFilters: filters,
          tableSorters: sorter,
          size: pagination.pageSize,
          page: pagination.current,
          search,
        });

        await fetchData(
          pagination.current,
          pagination.pageSize,
          customSort,
          search
        );
      } else {
        setTableProperties({
          size: 10,
          page: 0,
          tableFilters: {},
          tableSorters: {},
          search: null,
        });
        await fetchData(
          0,
          10,
          {},
          shouldShowWeightsOnlyForActiveAnimals === true
            ? `animal_status = 'A'`
            : ""
        );
      }
    },
    [
      getSortString,
      getSearchString,
      fetchData,
      shouldShowWeightsOnlyForActiveAnimals,
    ]
  );

  return (
    <AnimalWeightHistoryContext.Provider
      value={{
        dataList,
        fetchData,
        isLoadingDataList,
        data,
        initializeData,
        openOrCloseForm,
        showDrawerForm,
        tableProperties,
        handleTableChange,
        showModalExport,
        openOrCloseModalExport,
        shouldShowWeightsOnlyForActiveAnimals,
        setShouldShowWeightsOnlyForActiveAnimals,
      }}
    >
      {children}
    </AnimalWeightHistoryContext.Provider>
  );
};
