import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
/* Components */

import {
  Modal,
  Row,
  Col
} from "antd";
import {
  Container,
  Header,
  Body,
  Footer,
  ButtonNavigation,
} from "./styles";

/** Services */

const DuplicateModal = ({
  id,
  name,
  closeModal,
  visible
}) => {

  const DuplicateIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.982" height="36.293" viewBox="0 0 33.982 36.293">
      <g id="Group_4241" data-name="Group 4241" transform="translate(-488.095 -319)">
        <g id="document" transform="translate(444.127 325.504)">
          <g id="Group_1245" data-name="Group 1245" transform="translate(53.337 6.285)">
            <g id="Group_1244" data-name="Group 1244">
              <path id="Path_1089" data-name="Path 1089" d="M214.412,108.016h-8.257a1.164,1.164,0,1,0,0,2.328h8.257a1.164,1.164,0,1,0,0-2.328Z" transform="translate(-204.992 -108.016)"/>
            </g>
          </g>
          <g id="Group_1247" data-name="Group 1247" transform="translate(53.337 10.94)">
            <g id="Group_1246" data-name="Group 1246">
              <path id="Path_1090" data-name="Path 1090" d="M214.412,188.027h-8.257a1.164,1.164,0,1,0,0,2.328h8.257a1.164,1.164,0,1,0,0-2.328Z" transform="translate(-204.992 -188.027)"/>
            </g>
          </g>
          <g id="Group_1249" data-name="Group 1249" transform="translate(53.337 15.595)">
            <g id="Group_1248" data-name="Group 1248">
              <path id="Path_1091" data-name="Path 1091" d="M209.705,268.039h-3.55a1.164,1.164,0,1,0,0,2.328h3.55a1.164,1.164,0,1,0,0-2.328Z" transform="translate(-204.992 -268.039)"/>
            </g>
          </g>
          <g id="Group_1251" data-name="Group 1251" transform="translate(43.968)">
            <g id="Group_1250" data-name="Group 1250" transform="translate(0)">
              <path id="Path_1092" data-name="Path 1092" d="M67.477,15.828a1.164,1.164,0,0,0,1.164-1.164V4.655A4.66,4.66,0,0,0,63.985,0H53.278a4.661,4.661,0,0,0-4.655,4.651,4.66,4.66,0,0,0-4.655,4.655V25.134a4.66,4.66,0,0,0,4.655,4.655H59.912a4.66,4.66,0,0,0,4.655-4.655V25.1a4.662,4.662,0,0,0,4.073-4.619,1.164,1.164,0,1,0-2.328,0,2.33,2.33,0,0,1-2.327,2.328l-10.707,0h0a2.328,2.328,0,0,1-2.328-2.328V4.655a2.33,2.33,0,0,1,2.328-2.328H63.985a2.33,2.33,0,0,1,2.328,2.328V14.664A1.164,1.164,0,0,0,67.477,15.828Zm-14.2,9.309h0l8.96,0a2.33,2.33,0,0,1-2.328,2.326H48.623A2.33,2.33,0,0,1,46.3,25.134V9.306a2.33,2.33,0,0,1,2.328-2.328v13.5a4.655,4.655,0,0,0,4.655,4.655Z" transform="translate(-43.968)"/>
            </g>
          </g>
        </g>
        <g id="Group_1252" data-name="Group 1252" transform="translate(-88 65)">
          <path id="Path_1083" data-name="Path 1083" d="M10.731,0A10.731,10.731,0,1,1,0,10.731,10.731,10.731,0,0,1,10.731,0Z" transform="translate(588.615 254)" fill="#a9c133" opacity="0.64"/>
        </g>
      </g>
    </svg>
  );


  return (
    <Modal
      width={460}
      visible={visible}
      centered
      maskClosable={false}
      closable={true}
      onCancel={closeModal}
      footer={null}
      className="modal"
    >
        <Container>
          <Header>
          <Col align="center">
            <DuplicateIcon />
          </Col>
          <Col>
            <Row type="flex">
              <span>
                Duplicar Cenário de Compra
              </span>
            </Row>
            <Row type="flex">
              <p className="name">
                {name}
              </p>
            </Row>
          </Col>  
          </Header>
          <Body>            
            <Row type="flex" style={{padding: "0px 10px 0px 10px"}}>
              <ul>
                <li>Fique tranquilo, o cenário atual não será afetado ao ser duplicado;</li>
                <li>A estratégia de dieta será adaptada ao calendário atual, iniciando amanhã - {moment().add(1, "days").format("DD/MM/YYYY")};</li>
                <li>Não esqueça de conferir o custo de cada período climático da estratégia de dieta;</li>
              </ul>
            </Row>
          </Body>
          <Footer>
            <Row type="flex" justify="space-between">
              <Col span={6} align="left">
                <div className="buttonContainer">
                    <ButtonNavigation className="cancel" onClick={closeModal}>    
                      Cancelar
                    </ButtonNavigation>
                </div>
              </Col>
              <Col span={12} align="center">
                <span></span>
              </Col>
              <Col span={6} align="right">
                <Link to={`/admin/decisions/scenarios/purchases/${id}/duplicate`}>
                  <ButtonNavigation className="duplicate">
                    Duplicar cenário
                  </ButtonNavigation>
                </Link>
              </Col>
            </Row>
          </Footer>
        </Container>

    </Modal>
  );
};

export default DuplicateModal;
