import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import useReproductiveProtocolContext from "../../../hooks/useReproductiveProtocolContext";

import {
  Button,
  DatePicker,
  Dropdown,
  Icon,
  Input,
  Menu,
  notification,
  Row,
  Table,
} from "antd";
import { Container as MenuContainer } from "../../../components/utils/table/menu/styles";
import MenuIcon from "../../../components/utils/table/icons/menu";
import TagStatus from "../../../components/utils/tagStatus";
// Services
import {
  activate,
  inactivate,
  save,
} from "../../../services/reproductiveProtocolService";
import moment from "moment";

const ReproductiveProtocolTable = () => {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const {
    dataList,
    isLoadingDataList,
    openDrawer,
    fetchData,
    BASIC_ReproductiveProtocolS,
  } = useReproductiveProtocolContext();

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const menu = (id, record) => {
    return (
      <Menu>
        {record.status === "Active" && (
          <Menu.Item key="1" onClick={() => handleEdit(record)}>
            {translation.table.menu.edit}
          </Menu.Item>
        )}
        {record.status === "Active" && (
          <Menu.Item key="2" onClick={() => handleInactivate(id, record)}>
            {translation.table.menu.inactivate}
          </Menu.Item>
        )}
        {record.status === "Inactive" && (
          <Menu.Item key="3" onClick={() => handleActivate(id, record)}>
            {translation.table.menu.activate}
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const expandedRows = useCallback(
    (record, index, indent, expanded) => {
      const {
        applicationDays: { orderApplication, dayApplication, product },
      } = translation.reproductiveProtocol.table.columns;

      const columns = [
        {
          title: orderApplication,
          dataIndex: "orderApplication",
        },
        {
          title: product,
          dataIndex: "product",
        },
        {
          title: dayApplication,
          dataIndex: "dayApplication",
        },
      ];

      const { applicationDays } = record;

      if (!applicationDays || applicationDays.length === 0) return false;

      return (
        <Table
          rowKey="id"
          columns={columns}
          dataSource={applicationDays}
          pagination={false}
          style={{ padding: 2 }}
        />
      );
    },
    [translation]
  );

  const handleEdit = useCallback(
    (record) => {
      openDrawer(record);
    },
    [openDrawer]
  );

  const handleInactivate = useCallback(
    async (id, record) => {
      try {
        await inactivate({ groupId, farmId, id });
        notification.success({ message: "Protocolo inativado com sucesso!" });
        fetchData();
      } catch (error) {
        notification.error({
          message: "Erro ao inativar Protocolo. Contate o suporte.",
        });
      }
    },
    [farmId, fetchData, groupId]
  );

  const handleActivate = useCallback(
    async (id, record) => {
      try {
        await activate({ groupId, farmId, id });
        fetchData();
        notification.success({ message: "Protocolo ativado com sucesso!" });
      } catch (error) {
        notification.error({
          message: "Erro ao ativar Protocolo. Contate o suporte.",
        });
      }
    },
    [farmId, fetchData, groupId]
  );

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetDateSearchProps = (dataIndex) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div
          style={{
            padding: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Row style={{ marginBottom: 5 }}>
            <DatePicker.RangePicker
              value={selectedKeys && selectedKeys[0]?.dates}
              format="DD/MM/YYYY"
              onChange={(dates, dateStrings) => setSelectedKeys([{ dates }])}
            />
          </Row>
          <Row>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{
                width: 90,
                marginRight: 8,
                background: "#684e94",
                borderColor: "none",
                border: "none",
              }}
            />
            <Button
              onClick={() => handleReset(clearFilters)}
              icon="delete"
              size="small"
              style={{ width: 90 }}
            />
          </Row>
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="calendar"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return moment(record[dataIndex]).isBetween(
          value?.dates[0],
          value?.dates[1],
          "days",
          "[]"
        );
      },
    };
  };

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return record[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    }),
    [handleReset, handleSearch]
  );

  return (
    <Table
      loading={isLoadingDataList}
      rowKey="id"
      size="small"
      dataSource={dataList}
      scroll={{
        x: true,
      }}
      pagination={{
        hideOnSinglePage:
          dataList !== null && dataList.length > 10 ? false : true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
      }}
      expandedRowRender={expandedRows}
    >
      <Table.Column
        title={translation.reproductiveProtocol.table.columns.status}
        dataIndex="status"
        key="status"
        align="left"
        sorter={(first, second) => {
          return first.status.localeCompare(second.status, "pt-BR", {
            numeric: false,
            ignorePunctuation: true,
          });
        }}
        defaultFilteredValue={["Active"]}
        filtered
        filters={[
          {
            text: translation.status.active,
            value: "Active",
          },
          {
            text: translation.status.inactive,
            value: "Inactive",
          },
        ]}
        filterMultiple
        onFilter={(value, record) => record.status.includes(value)}
        render={(status) =>
          status === "Active" ? (
            <TagStatus
              background="#C5F1CA"
              borderColor="#106518"
              color="#106518"
            >
              {translation.status.active}
            </TagStatus>
          ) : (
            <TagStatus
              background="#FBC7C7"
              borderColor="#D44C4C"
              color="#D44C4C"
            >
              {translation.status.inactive}
            </TagStatus>
          )
        }
      />
      <Table.Column
        title={translation.reproductiveProtocol.table.columns.name}
        dataIndex="name"
        sorter={(first, second) => {
          return first.name.localeCompare(second.name, "pt-BR", {
            numeric: false,
            ignorePunctuation: true,
          });
        }}
        key="name"
        {...handleGetColumnSearchProps("name")}
      />
      <Table.Column
        title={translation.reproductiveProtocol.table.columns.duration}
        dataIndex="duration"
        sorter={(first, second) => {
          if (!first.duration) {
            first.duration = "";
          }
          return first.duration.localeCompare(second.duration, "pt-BR", {
            numeric: false,
            ignorePunctuation: true,
          });
        }}
        key="duration"
        {...handleGetColumnSearchProps("duration")}
        render={(text) => `${text} dias`}
      />
      <Table.Column
        title={translation.reproductiveProtocol.table.columns.createdAt}
        dataIndex="createdAt"
        sorter={(first, second) => {
          return first.createdAt.localeCompare(second.createdAt, "pt-BR", {
            numeric: false,
            ignorePunctuation: true,
          });
        }}
        key="createdAt"
        {...handleGetDateSearchProps("createdAt")}
        render={(text) => <span>{moment(text).format("DD/MM/YYYY")}</span>}
      />
      <Table.Column
        title={translation.reproductiveProtocol.table.columns.createdBy}
        dataIndex="createdByName"
        sorter={(first, second) => {
          return first.createdByName.localeCompare(
            second.createdByName,
            "pt-BR",
            {
              numeric: false,
              ignorePunctuation: true,
            }
          );
        }}
        key="createdByName"
        {...handleGetColumnSearchProps("createdByName")}
      />
      <Table.Column
        title=""
        align="left"
        render={(text, record) =>
          record.groupId !== null ? (
            <Dropdown
              overlay={menu(record.id, record)}
              trigger={["click"]}
              key={record.id}
            >
              <MenuContainer>
                <MenuIcon />
              </MenuContainer>
            </Dropdown>
          ) : null
        }
      />
    </Table>
  );
};

export default ReproductiveProtocolTable;
