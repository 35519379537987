import styled, { css } from "styled-components";

const COLORS_EVENT_BOX = {
  Coverage: "#845EC2",
  PregnancyDiagnosisPositive: "#0080ff",
  PregnancyDiagnosisNegative: "#ff0000",
  Anestrus: "#808080",
  Abortion: "#303030",
  EmbryonicDeath: "#3b3b3b",
  Birth: "#008F7A",
  Weaning: "#A77D74",
};

export const EventBox = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #3b3b3b;
  border-radius: 8px;
  cursor: pointer;
  ${(props) =>
    props.color &&
    css`
      background-color: ${(props) => COLORS_EVENT_BOX[props.color]};
      color: #f4f4f4;
      h2 {
        color: #f4f4f4;
      }
    `}
`;
