import apiFarms from "../config/api_farms";
import getHeaders from "./getHeaders";

export function getFarmMortality(payload) {
  const { groupId, farmId, startDate, endDate, signal } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/mortality?start_date=${startDate}&end_date=${endDate}`,
    getHeaders(false, signal)
  );
}