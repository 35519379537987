import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MovementAnimalsContextProvider } from "../../contexts/movementAnimalsContext";
import useMovementAnimalsContext from "../../hooks/useMovementAnimalsContext";

import { Col, notification, Row } from "antd";
import ButtonStandard from "../../components/utils/button";
import MovementAnimalsTable from "./animalsTable";
import MovementAnimalsDestinationLots from "./destinationLots";

import {
  ButtonDeselectAllAnimals,
  ButtonProperty,
  ButtonSelectAllAnimals,
  CardLotDismembered,
  ColSelectAnimals,
  Container,
  CustomDivider,
  DescriptionMessage,
  Title,
} from "./styles";
import MovementAnimalsFormDrawer from "./form";
import Loading from "../../components/utils/loading";

// Services
import {
  findAllAnimalsForMovement,
  saveMovementAnimals,
} from "../../services/movementAnimalsService";

const MovementAnimalsLayout = () => {
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [totalAnimalsActive, setTotalAnimalsActive] = useState(0);
  const {
    updatePagination,
    form,
    selectedAnimalsKeys,
    animalsDismembered,
    getAnimals,
    updateSelectedAnimalsKeys,
    openDrawer,
    switchIsLoadingAnimals,
    resetForm,
    updateAnimalsDismembered,
  } = useMovementAnimalsContext();
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  useEffect(() => {
    async function fetchAmountAnimalsActive() {
      try {
        const {
          data: {
            meta: { size },
          },
        } = await findAllAnimalsForMovement({
          groupId,
          farmId,
          justIds: true,
          filters: null,
        });
        setTotalAnimalsActive(size);
      } catch (error) {}
    }
    fetchAmountAnimalsActive();
  }, [farmId, groupId]);

  useEffect(() => {
    getAnimals();
  }, [getAnimals]);

  const resetAll = () => {
    updateSelectedAnimalsKeys([]);
    resetForm();
    updateAnimalsDismembered([]);
  };

  const handleCancel = () => {
    resetAll();
  };

  const handleSaveDismembering = async () => {
    setIsLoadingRequest(true);
    try {
      await saveMovementAnimals({ groupId, farmId, body: form });
      notification.success({
        message: "Movimentação de animais salva com sucesso!",
      });
      resetAll();
    } catch (error) {
      if (Object.keys(error).includes("response")) {
        const { response } = error;
        if (Object.keys(response).includes("data")) {
          const {
            data: { code: errorCode },
          } = response;
          if (errorCode === 5031) {
            notification.error({
              message: "Erro",
              description: `Atenção! Algum animal selecionado para movimentar já possui uma movimentação para algum lote posterior a data de desmembramento.`,
            });
          } else {
            notification.error({
              message:
                "Houve um problema ao processar movimentação de animais! Contate o suporte.",
            });
          }
        }
      } else {
        notification.error({
          message:
            "Houve um problema ao processar movimentação de animais! Contate o suporte.",
        });
      }
    } finally {
      setIsLoadingRequest(false);
    }
  };

  const handleDeselectAllAnimals = () => {
    updateSelectedAnimalsKeys();
  };

  const handleSelectMales = async () => {
    switchIsLoadingAnimals();
    try {
      let filters = "gender='M';";
      if (animalsDismembered.length > 0) {
        let filter = "";
        filter = `${filters}id not in (`;

        animalsDismembered.map((animal) => (filter = `${filter}'${animal}',`));
        filter = `${filter})`;
        filter = filter.replace(",)", ")");
        filters = filter;
      }
      const {
        data: { results: ids },
      } = await findAllAnimalsForMovement({
        groupId,
        farmId,
        justIds: true,
        filters,
      });
      updateSelectedAnimalsKeys(ids);
      updatePagination({
        page: 0,
        sorter: null,
        filters: null,
        size: 10,
        ids: null,
        tableSorters: null,
        tableFilters: null,
      });
    } catch (error) {
    } finally {
      switchIsLoadingAnimals();
    }
  };

  const handleSelectFemales = async () => {
    switchIsLoadingAnimals();
    try {
      let filters = "gender='F';";
      if (animalsDismembered.length > 0) {
        let filter = "";
        filter = `${filters}id not in (`;

        animalsDismembered.map((animal) => (filter = `${filter}'${animal}',`));
        filter = `${filter})`;
        filter = filter.replace(",)", ")");
        filters = filter;
      }
      const {
        data: { results: ids },
      } = await findAllAnimalsForMovement({
        groupId,
        farmId,
        justIds: true,
        filters,
      });
      updateSelectedAnimalsKeys(ids);
      updatePagination({
        page: 0,
        sorter: null,
        filters: null,
        size: 10,
        ids: null,
        tableSorters: null,
        tableFilters: null,
      });
    } catch (error) {
    } finally {
      switchIsLoadingAnimals();
    }
  };

  const handleSelectAll = async () => {
    switchIsLoadingAnimals();
    try {
      let filters = "";
      if (animalsDismembered.length > 0) {
        let filter = "";
        filter = `${filters}id not in (`;

        animalsDismembered.map((animal) => (filter = `${filter}'${animal}',`));
        filter = `${filter})`;
        filter = filter.replace(",)", ")");
        filters = filter;
      }
      const {
        data: { results: ids },
      } = await findAllAnimalsForMovement({
        groupId,
        farmId,
        justIds: true,
        filters,
      });
      updateSelectedAnimalsKeys(ids);
      updatePagination({
        page: 0,
        sorter: null,
        filters: null,
        size: 10,
        ids: null,
        tableSorters: null,
        tableFilters: null,
      });
    } catch (error) {
    } finally {
      switchIsLoadingAnimals();
    }
  };

  const handleDismembering = () => {
    openDrawer();
  };

  return (
    <Container>
      {isLoadingRequest && <Loading />}
      {/* Page Header */}
      <div className="pageHeader">
        <Row type="flex" justify="start">
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Title>{translation.movimentAnimals.title}</Title>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
            align="right"
            className="buttonsCol"
          >
            <ButtonStandard
              type="button"
              buttonType="type4"
              size="s"
              onClick={handleCancel}
            >
              {translation.buttons.cancel}
            </ButtonStandard>
            <ButtonStandard
              type="button"
              buttonType="type1"
              width="121px"
              height="35px"
              padding="5px 10px 5px 10px"
              disabled={
                form?.destinationLots == null ||
                form?.destinationLots.length === 0
              }
              onClick={handleSaveDismembering}
            >
              {translation.buttons.save}
            </ButtonStandard>
          </Col>
        </Row>
      </div>

      {/* Page Body */}
      <Row type="flex" justify="space-between" style={{ marginTop: 10 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <DescriptionMessage>
            {translation.movimentAnimals.description}
          </DescriptionMessage>
        </Col>
      </Row>

      {/* Menu buttons */}
      <Row type="flex">
        <Col xs={24} sm={24} md={24} lg={18} xl={18}>
          <Row
            type="flex"
            justify="start"
            gutter={16}
            style={{ marginTop: 10 }}
          >
            <Col span={18} className="buttonsTable">
              <ButtonSelectAllAnimals type="button" onClick={handleSelectAll}>
                Selecionar todos os animais
              </ButtonSelectAllAnimals>
              <ButtonSelectAllAnimals type="button" onClick={handleSelectMales}>
                Selecionar todos os machos
              </ButtonSelectAllAnimals>
              <ButtonSelectAllAnimals
                type="button"
                onClick={handleSelectFemales}
              >
                Selecionar todas as fêmeas
              </ButtonSelectAllAnimals>
            </Col>
            <Col span={6} align="right">
              <ButtonDeselectAllAnimals
                type="button"
                onClick={handleDeselectAllAnimals}
                icon="delete"
              >
                Limpar seleções
              </ButtonDeselectAllAnimals>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Main */}
      <Row type="flex" className="pageBody">
        {/* Col with animals table */}
        <Col xs={24} sm={24} md={24} lg={18} xl={18}>
          <ColSelectAnimals>
            <MovementAnimalsTable />
          </ColSelectAnimals>
        </Col>
        {/* Col with lot demembering info */}
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={6}
          xl={6}
          className="columnDememberingInfo"
        >
          <Row type="flex" style={{ marginTop: 10 }}>
            {translation.lot.production.dismemberLotPage.animalsTotal}
          </Row>
          <Row
            type="flex"
            style={{
              fontSize: 16,
              fontWeight: "bolder",
              color: "#FE8D2A",
            }}
          >
            <span>{totalAnimalsActive}</span>
          </Row>

          <Row type="flex" style={{ marginTop: 10 }}>
            {translation.lot.production.dismemberLotPage.animalsSelected}
          </Row>
          <Row
            type="flex"
            style={{
              fontSize: 16,
              fontWeight: "bolder",
              color: "#FE8D2A",
            }}
          >
            {selectedAnimalsKeys.length}
          </Row>
          <Row
            type="flex"
            style={{
              fontSize: 16,
              fontWeight: "bolder",
              color: "#FE8D2A",
            }}
          >
            <ButtonProperty
              shape="round"
              className={selectedAnimalsKeys.length > 0 ? "enabled" : ""}
              onClick={handleDismembering}
              disabled={selectedAnimalsKeys.length === 0}
            >
              {translation.movimentAnimals.buttonMovementAnimals}
            </ButtonProperty>
          </Row>
          {form?.destinationLots != null && form?.destinationLots.length > 0 ? (
            <>
              <CustomDivider dashed />
              <CardLotDismembered
                title={
                  translation.lot.production.dismemberLotPage.destinationLots
                }
              >
                {form.destinationLots.map((lot) => (
                  <MovementAnimalsDestinationLots key={lot.key} lot={lot} />
                ))}
              </CardLotDismembered>
            </>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

const MovementAnimals = () => {
  return (
    <MovementAnimalsContextProvider>
      <MovementAnimalsLayout />
      <MovementAnimalsFormDrawer />
    </MovementAnimalsContextProvider>
  );
};

export default MovementAnimals;
