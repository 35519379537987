import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as PastureActions } from "../../store/ducks/pasture";

import {
  Row,
  Col,
  Table,
  Input,
  Button,
  Icon,
  Menu,
  Dropdown,
  Popover,
} from "antd";
import { Title, CardCustom } from "./styles";
import TagStatus from "../../components/utils/tagStatus";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import MenuIcon from "../../components/utils/table/icons/menu";
import DrawerPasture from "../../components/drawers/pasture";
import ButtonStandard from "../../components/utils/button";
import DrawerPastureDetails from "../../components/drawers/pastureDetails";

class Pasture extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      success: false,
    };
  }

  async componentDidMount() {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;
    if (
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      await this.fetchData(groupSelected, farmSelected, 0, { groupId: "ASC" });
    }
  }

  async shouldComponentUpdate(nextProps) {
    const differentGroup =
      this.props.app.groupSelected?.id !== nextProps.app.groupSelected?.id;
    const differentFarm =
      this.props.app.farmSelected?.id !== nextProps.app.farmSelected?.id;
    if (differentGroup || differentFarm) {
      if (
        Object.entries(nextProps.app.groupSelected).length > 0 &&
        Object.entries(nextProps.app.farmSelected).length > 0
      ) {
        await this.fetchData(
          nextProps.app.groupSelected,
          nextProps.app.farmSelected,
          0,
          { groupId: "ASC" }
        );
      }
    }
    return true;
  }

  fetchData = async (
    groupSelected,
    farmSelected,
    page = 0,
    sorter = { groupId: "ASC" },
    filters = "",
    size = 10
  ) => {
    const { pastureActions } = this.props;
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    await pastureActions.indexPasture(
      groupId,
      farmId,
      page,
      sorter,
      filters,
      size
    );
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
            background: "#684e94",
            borderColor: "none",
            border: "none",
          }}
        />
        <Button
          onClick={() => this.handleReset(clearFilters)}
          icon="delete"
          size="small"
          style={{ width: 90 }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (dataIndex === "pastureType.name") {
        return record.pastureType.name.includes(value);
      } else {
        return record[dataIndex].toString().includes(value);
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleTableChange = async (pagination, filters, sorter) => {
    this.setState({
      paginationInfo: pagination,
      sortedInfo: sorter,
      filteredInfo: filters,
    });
  };

  handleDetails = (id) => {
    const { pastureActions } = this.props;
    pastureActions.showOrHideDrawerPastureDetails(id);
  };

  handleEdit = async (id) => {
    const { pastureActions } = this.props;

    pastureActions.showDrawer("edit", id);
  };

  render() {
    const {
      app: { translation },
      pasture: { data, isLoading },
      pastureActions,
    } = this.props;

    const Column = Table.Column;

    const menu = (id, record) => {
      return (
        <Menu>
          <Menu.Item key="0" onClick={() => this.handleDetails(id)}>
            {translation.table.menu.details}
          </Menu.Item>
          {(record.groupId !== null || record.status === "Pending") && (
            <Menu.Item key="1" onClick={() => this.handleEdit(id)}>
              {translation.table.menu.edit}
            </Menu.Item>
          )}
        </Menu>
      );
    };

    return (
      <CardCustom>
        <Row type="flex" justify="space-between" className="rowHeader">
          <Col>
            <Title>{translation.pasture.table.title}</Title>
          </Col>
          <Col>
            <ButtonStandard
              type="button"
              buttonType="type8"
              onClick={() => pastureActions.showDrawer("new")}
            >
              {translation.pasture.buttonNewPasture}
            </ButtonStandard>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              loading={isLoading}
              rowKey="id"
              size="small"
              dataSource={data != null ? data : []}
              pagination={{
                total: data != null ? data.length : 0,
                size: data != null ? data.length : 0,
                hideOnSinglePage: true,
              }}
              scroll={{
                x: true,
              }}
              style={{ width: "100%", margin: "0" }}
              onChange={this.handleTableChange}
            >
              <Column
                title={translation.pasture.table.columns.status}
                dataIndex="status"
                key="status"
                align="left"
                filtered
                filters={[
                  {
                    text: translation.status.active,
                    value: "Active",
                  },
                  {
                    text: "Pendente",
                    value: "Pending",
                  },
                ]}
                filterMultiple
                onFilter={(value, record) => record.status.includes(value)}
                render={(status) =>
                  status === "Active" ? (
                    <TagStatus
                      background="#C5F1CA"
                      borderColor="#106518"
                      color="#106518"
                    >
                      {translation.status.active}
                    </TagStatus>
                  ) : status === "Pending" ? (
                    <Popover
                      content={
                        <div>
                          Complete as informações de{" "}
                          <span style={{ fontWeight: "bold" }}>
                            adubado e/ou não adubado{" "}
                          </span>{" "}
                          para todas às{" "}
                          <span style={{ fontWeight: "bold" }}>estações</span>
                        </div>
                      }
                      trigger="hover"
                    >
                      <TagStatus
                        background="#FFDBBC"
                        borderColor="#FE8D2A"
                        color="#FE8D2A"
                      >
                        Pendente
                      </TagStatus>
                    </Popover>
                  ) : (
                    <TagStatus
                      background="#FBC7C7"
                      borderColor="#D44C4C"
                      color="#D44C4C"
                    >
                      {translation.status.inactive}
                    </TagStatus>
                  )
                }
              />
              <Column
                title="Origem"
                align="left"
                render={(text, record) =>
                  record.groupId !== null && record.clonePastureId === null ? (
                    <TagStatus
                      background="#684e75"
                      borderColor="#684e94"
                      color="#FFFFFF"
                    >
                      Local
                    </TagStatus>
                  ) : (
                    <TagStatus
                      background="#E3E3E3"
                      borderColor="#C4C4C4"
                      color="#4B4B4B"
                    >
                      Padrão
                    </TagStatus>
                  )
                }
              />
              <Column
                title={translation.pasture.table.columns.description}
                dataIndex="name"
                key="name"
                align="left"
                {...this.getColumnSearchProps("name")}
              />
              <Column
                title="Nome Científico"
                dataIndex="pastureType.name"
                key="pastureType.name"
                align="left"
                {...this.getColumnSearchProps("pastureType.name")}
              />
              <Column
                align="left"
                render={(text, record) => (
                  <Dropdown
                    overlay={menu(record.id, record)}
                    trigger={["click"]}
                    key={record.id}
                  >
                    <MenuContainer>
                      <MenuIcon />
                    </MenuContainer>
                  </Dropdown>
                )}
              />
            </Table>
          </Col>
        </Row>
        <DrawerPasture />
        <DrawerPastureDetails />
      </CardCustom>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  pasture: state.pasture,
});

const mapDispatchToProps = (dispatch) => ({
  pastureActions: bindActionCreators(PastureActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Pasture)
);
