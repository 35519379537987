import React, { useCallback, useRef } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useFinancial } from "../../../hooks/useFinancialReducer";
import useWindowSize from "../../../hooks/useWindowSize";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Button,
  Col,
  DatePicker,
  Icon,
  Input,
  Modal,
  Radio,
  Row,
  Spin,
  Switch,
  Table,
  Tooltip,
  notification,
} from "antd";
import TagStatus from "../../utils/tagStatus";
import { Container } from "./styles";
import ButtonStandard from "../../utils/button";

// Services
import { numberMask } from "../../../services/helpersMethodsService";
import { useState } from "react";
import {
  createFinancialTransactionsPartialPayment,
  settleFinancialTransactions,
  unsettleFinancialTransactions,
} from "../../../services/financialTransactionsService";
import NumberFormat from "react-number-format";
import ResultCenterApportionmentModal from "../../../pages/financial/tabs/transactions/form/resultCenterApportionmentModal";

// import { Container } from './styles';
const Column = Table.Column;

const INITIAL_FORM_STATE = {
  valuePartialPayment: null,
  fees: null,
  settleDate: null,
  expirationDate: null,
  valuePendingPayment: null,
  resultCenterApportionmentDefault: null,
};

const validationSchema = Yup.object().shape({
  settleDate: Yup.date().required(),
  expirationDate: Yup.date().required(),
  valuePartialPayment: Yup.number().min(1).required(),
  resultCenterApportionmentDefault: Yup.boolean().required(),
});

function PartialPaymentFinancialTransactionModal() {
  const formikRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [
    isResultCenterApportionmentModalVisible,
    setIsResultCenterApportionmentModalVisible,
  ] = useState(false);
  const [customApportionmentValues, setCustomApportionmentValues] =
    useState(null);
  const {
    refreshListFinancialTransaction,
    financialTransactionData,
    shouldShowModalPartialPaymentFinancialTransaction: visible,
    handleCloseModalPartialPayment,
  } = useFinancial();
  const { width } = useWindowSize();
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const saveData = useCallback(
    async (id, values) => {
      setIsLoading(true);
      try {
        const body = {
          ...values,
        };
        await createFinancialTransactionsPartialPayment({
          groupId,
          farmId,
          id,
          body,
        });
        formikRef.current.resetForm(INITIAL_FORM_STATE);
        notification.success({
          message: "Foi salvo o pagamento parcial com sucesso.",
        });
        refreshListFinancialTransaction();
        setIsLoading(false);
        handleCloseModalPartialPayment();
      } catch (error) {
        notification.error({
          message:
            "Não foi possível salvar o pagamento parcial. Contate o suporte.",
        });
        setIsLoading(false);
      }
    },
    [
      refreshListFinancialTransaction,
      groupId,
      farmId,
      handleCloseModalPartialPayment,
    ]
  );

  const handleCancel = useCallback(() => {
    formikRef.current.resetForm(INITIAL_FORM_STATE);
    handleCloseModalPartialPayment();
  }, [handleCloseModalPartialPayment]);

  const handleSubmit = useCallback(
    (values) => {
      if (values.valuePartialPayment >= financialTransactionData.value) {
        Modal.error({
          centered: true,
          width: 500,
          content: `O valor do campo "Valor Pag. Parcial" deve ser menor que o valor de ${numberMask(
            financialTransactionData.value,
            true
          )} da movimentação financeira original.`,
        });

        return;
      } else {
        saveData(financialTransactionData.id, {
          ...values,
          customApportionmentValues,
        });
      }
    },
    [saveData, customApportionmentValues, financialTransactionData]
  );

  const handleOpenCloseResultCenterApportionmentModal = () => {
    setIsResultCenterApportionmentModalVisible((old) => !old);
  };

  const handleCancelCustomApportionmentModal = () => {
    handleOpenCloseResultCenterApportionmentModal();
    setCustomApportionmentValues(null);
    formikRef.current.setFieldValue("resultCenterApportionmentDefault", true);
  };

  const handleSaveCustomApportionmentModal = (data) => {
    handleOpenCloseResultCenterApportionmentModal();
    setCustomApportionmentValues(data);
  };

  return (
    <Modal
      visible={visible}
      width={width >= 1440 ? 1000 : 800}
      title={translation.partialPayment.title}
      centered
      closable={true}
      onCancel={handleCancel}
      footer={null}
    >
      <Formik
        ref={formikRef}
        initialValues={INITIAL_FORM_STATE}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        render={({
          values,
          errors,
          submitCount,
          setFieldValue,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Spin spinning={isLoading}>
              <Container>
                {Object.entries(errors).length > 0 && submitCount > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                <Row
                  type="flex"
                  gutter={16}
                  style={{ marginBottom: 8, marginTop: 8 }}
                >
                  <Col>
                    <Row>
                      <label
                        className={
                          errors.valuePartialPayment && submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {translation.partialPayment.form.valuePartialPayment}*
                      </label>
                    </Row>
                    <Row>
                      <NumberFormat
                        name="valuePartialPayment"
                        customInput={Input}
                        value={
                          values.valuePartialPayment != null
                            ? values.valuePartialPayment
                            : ""
                        }
                        allowNegative={false}
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        placeholder={translation.defaultPlaceholder}
                        addonBefore="R$ "
                        onValueChange={({ floatValue }) => {
                          setFieldValue(
                            "valuePartialPayment",
                            floatValue != null
                              ? floatValue > 100000000
                                ? 100000000
                                : floatValue
                              : null
                          );
                          setFieldValue(
                            "valuePendingPayment",
                            (financialTransactionData?.value || 0) -
                              (floatValue || 0) +
                              (values.fees || 0)
                          );
                        }}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <label>{translation.partialPayment.form.fees}</label>
                    </Row>
                    <Row>
                      <NumberFormat
                        name="fees"
                        customInput={Input}
                        value={values.fees != null ? values.fees : ""}
                        allowNegative={false}
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        placeholder={translation.defaultPlaceholder}
                        addonBefore="R$ "
                        onValueChange={({ floatValue }) => {
                          setFieldValue(
                            "fees",
                            floatValue != null
                              ? floatValue > 100000000
                                ? 100000000
                                : floatValue
                              : null
                          );

                          setFieldValue(
                            "valuePendingPayment",
                            (financialTransactionData?.value || 0) -
                              (values.valuePartialPayment || 0) +
                              (floatValue || 0)
                          );
                        }}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <label
                        className={
                          errors.settleDate && submitCount > 0 ? "error" : ""
                        }
                      >
                        {translation.partialPayment.form.settleDate}*
                      </label>
                    </Row>
                    <Row>
                      <DatePicker
                        name="settleDate"
                        value={values.settleDate}
                        format={"DD/MM/YYYY"}
                        disabledDate={(current) =>
                          current.isAfter(moment()) ||
                          current.isBefore(
                            moment(financialTransactionData?.billingDate)
                          )
                        }
                        onChange={(date) => {
                          setFieldValue("settleDate", date);
                          setFieldValue("expirationDate", null);
                        }}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <label
                        className={
                          errors.expirationDate && submitCount > 0
                            ? "error"
                            : ""
                        }
                      >
                        {translation.partialPayment.form.expirationDate}*
                      </label>
                    </Row>
                    <Row>
                      <DatePicker
                        name="expirationDate"
                        value={values.expirationDate}
                        format={"DD/MM/YYYY"}
                        disabledDate={(current) =>
                          values.settleDate
                            ? current.isBefore(moment(values.settleDate))
                            : current.isBefore(moment().subtract(1, "days"))
                        }
                        onChange={(date) =>
                          setFieldValue("expirationDate", date)
                        }
                      />
                    </Row>
                  </Col>
                </Row>
                <Row
                  type="flex"
                  gutter={16}
                  style={{ marginBottom: 8, marginTop: 8 }}
                >
                  <Col>
                    <Row>
                      <label>
                        {translation.partialPayment.form.valuePendingPayment}*
                      </label>
                    </Row>
                    <Row>
                      <NumberFormat
                        name="valuePendingPayment"
                        customInput={Input}
                        value={
                          values.valuePendingPayment ||
                          financialTransactionData?.value
                        }
                        disabled
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        placeholder={translation.defaultPlaceholder}
                        addonBefore="R$ "
                      />
                    </Row>
                  </Col>
                  <Tooltip
                    title={
                      'É necessário digitar um valor primeiro no campo "Valor Pag. Parcial" '
                    }
                  >
                    <Col>
                      <Row>
                        <label
                          className={
                            errors.resultCenterApportionmentDefault &&
                            submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.financial.transactions.form.resultCenter}
                          *
                        </label>
                      </Row>
                      <Row>
                        <Radio.Group
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                          value={values.resultCenterApportionmentDefault}
                          disabled={values.valuePartialPayment === null}
                          buttonStyle="solid"
                          onChange={(e) =>
                            setFieldValue(
                              "resultCenterApportionmentDefault",
                              e.target.value
                            )
                          }
                        >
                          <Radio.Button value={true}>
                            {
                              translation.financial.transactions.form
                                .resultCenterApportionmentDefault
                            }
                          </Radio.Button>
                          <Radio.Button
                            value={false}
                            onClick={
                              handleOpenCloseResultCenterApportionmentModal
                            }
                          >
                            {
                              translation.financial.transactions.form
                                .resultCenterApportionmentCustom
                            }
                          </Radio.Button>
                        </Radio.Group>
                      </Row>
                    </Col>
                  </Tooltip>
                </Row>

                {/* Footer */}
                <Row
                  type="flex"
                  justify="center"
                  align="middle"
                  gutter={16}
                  style={{ marginTop: 16 }}
                >
                  <Col>
                    <ButtonStandard
                      type="button"
                      buttonType="type4"
                      size="s"
                      onClick={handleCancel}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>
                  </Col>
                  <Col>
                    <ButtonStandard type="submit" buttonType="type1" size="s">
                      {translation.buttons.confirm}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Container>
            </Spin>
            <ResultCenterApportionmentModal
              isVisible={isResultCenterApportionmentModalVisible}
              closeModal={handleCancelCustomApportionmentModal}
              saveModal={handleSaveCustomApportionmentModal}
              financialNatureId={financialTransactionData?.financialNatureId}
              transactionValue={values.valuePendingPayment}
            />
          </form>
        )}
      />
    </Modal>
  );
}

export default PartialPaymentFinancialTransactionModal;
