import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px;
  position: relative;

  border: 2px solid #c3c3c3;
  border-radius: 10px;

  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
`;

export const Title = styled.span`
  position: absolute;
  top: -10px;
  left: 0%;

  margin-left: 16px;

  font-size: 14px;
  color: #9074bf;

  background: #fff;
  z-index: 2;
`;
