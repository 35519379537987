import styled from "styled-components";

export const Container = styled.div`
  .table-row-green {
    color: rgb(51, 193, 98);
  }

  .table-row-red {
    color: rgb(212, 76, 76);
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked {
    color: #fff !important;
  }

  label {
    display: block;
    color: #9074bf;
    font-family: "Asap", sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    margin-top: 8px;
    margin-bottom: 8px;
    &.error {
      color: #d44c4c;
    }
  }
  .ant-radio-wrapper {
    span {
      color: black;
    }
  }
`;
