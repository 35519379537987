import styled from "styled-components";
import { Collapse } from "antd";

export const Container = styled.div`
  span.noValueText {
    font-family: "Asap", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }

  div.containerBoitelSaleScenarioParam {
    .ant-row-flex:not(:first-of-type) {
      margin-top: 16px;
    }
  }
`;

export const CustomCollapse = styled(Collapse)`
  border: none !important;
  .ant-collapse-item {
    box-shadow: none !important;
    border: none !important;
    border-radius: 0px !important;

    .ant-collapse-header {
      border-radius: 0px !important;
      font-family: "Asap", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #4b4b4b !important;
    }
  }

  .ant-collapse-arrow {
    box-shadow: none;
    color: #8a50a5 !important;
  }

  .ant-collapse-item:not(:first-of-type) {
    border-top: 1px solid #9d9d9d !important;
  }

  .ant-collapse-content {
    background-color: #f5f5f5;
  }
`;

export const CustomTable = styled.table`
  width: 100%;
  th {
    font-family: "Asap", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    text-align: left;

    padding: 16px 0px;

    border-bottom: 1px solid #9d9d9d;
  }

  td {
    font-family: "Asap", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;

    text-align: left;

    padding: 16px 0px;

    border-bottom: 1px solid #9d9d9d;
  }

  tbody > tr:last-of-type {
    td {
      border-bottom: none;
    }
  }
`;
