import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import useFarmAnimalSellContext from "../../../../../../hooks/useFarmAnimalSellContext";

// Components
import ButtonStandard from "../../../../../../components/utils/button";
import FarmAnimalsTable from "../../animalsTable";
import {
  ButtonDeselectAllAnimals,
  ButtonSelectAllAnimals,
  ColSelectAnimals,
  DescriptionMessage,
  Title,
} from "../styles";

// Services
import { findAllAnimalsForMovement } from "../../../../../../services/movementAnimalsService";

const AnimalSelection = ({ onConfirm, onCancel }) => {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const {
    getAnimals,
    updateSelectedAnimalsKeys,
    switchIsLoadingAnimals,
    pagination,
  } = useFarmAnimalSellContext();

  useEffect(() => {
    getAnimals();
  }, [getAnimals]);

  const handleSelectAll = async () => {
    switchIsLoadingAnimals();
    try {
      let filters = "";
      if (pagination?.filters !== null && pagination?.filters !== undefined) {
        filters = pagination.filters;
      }

      const {
        data: { results: ids },
      } = await findAllAnimalsForMovement({
        groupId,
        farmId,
        justIds: true,
        filters,
      });
      updateSelectedAnimalsKeys(ids);
    } catch (error) {
    } finally {
      switchIsLoadingAnimals();
    }
  };

  const handleDeselectAllAnimals = () => {
    updateSelectedAnimalsKeys();
  };

  const handleSelectMales = async () => {
    switchIsLoadingAnimals();
    try {
      let filters = "gender='M';";
      if (pagination?.filters !== null && pagination?.filters !== undefined) {
        filters += pagination.filters;
      }

      const {
        data: { results: ids },
      } = await findAllAnimalsForMovement({
        groupId,
        farmId,
        justIds: true,
        filters,
      });
      updateSelectedAnimalsKeys(ids);
    } catch (error) {
    } finally {
      switchIsLoadingAnimals();
    }
  };

  const handleSelectFemales = async () => {
    switchIsLoadingAnimals();
    try {
      let filters = "gender='F';";
      if (pagination?.filters !== null && pagination?.filters !== undefined) {
        filters += pagination.filters;
      }

      const {
        data: { results: ids },
      } = await findAllAnimalsForMovement({
        groupId,
        farmId,
        justIds: true,
        filters,
      });
      updateSelectedAnimalsKeys(ids);
    } catch (error) {
    } finally {
      switchIsLoadingAnimals();
    }
  };

  return (
    <>
      {/* Page Header */}
      <div className="pageHeader">
        <Row type="flex" justify="start">
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Title>{translation.financial.animalSell.form.title}</Title>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
            align="right"
            className="buttonsCol"
          >
            <ButtonStandard
              type="button"
              buttonType="type4"
              size="s"
              onClick={onCancel}
            >
              {translation.buttons.cancel}
            </ButtonStandard>
            <ButtonStandard
              type="button"
              buttonType="type1"
              width="121px"
              height="35px"
              padding="5px 10px 5px 10px"
              onClick={onConfirm}
            >
              Próximo
            </ButtonStandard>
          </Col>
        </Row>
      </div>

      {/* Table Title */}
      <Row type="flex" align="middle" justify="start" className="row-form">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <DescriptionMessage>
            {translation.financial.animalSell.form.animalSelectionDescription}
          </DescriptionMessage>
        </Col>
      </Row>

      {/* Table buttons */}
      <Row className="row-form">
        <Col span={24}>
          <Row type="flex" align="middle" justify="start" gutter={16}>
            <Col span={18} className="buttonsTable">
              <ButtonSelectAllAnimals type="button" onClick={handleSelectAll}>
                Selecionar todos os animais
              </ButtonSelectAllAnimals>
              <ButtonSelectAllAnimals type="button" onClick={handleSelectMales}>
                Selecionar todos os machos
              </ButtonSelectAllAnimals>
              <ButtonSelectAllAnimals
                type="button"
                onClick={handleSelectFemales}
              >
                Selecionar todas as fêmeas
              </ButtonSelectAllAnimals>
            </Col>
            <Col span={6} align="right">
              <ButtonDeselectAllAnimals
                type="button"
                onClick={handleDeselectAllAnimals}
                icon="delete"
              >
                Limpar seleções
              </ButtonDeselectAllAnimals>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Animals Table */}
      <Row className="pageBody">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <ColSelectAnimals>
            <FarmAnimalsTable />
          </ColSelectAnimals>
        </Col>
      </Row>
    </>
  );
};

export default AnimalSelection;
