import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";

/* Redux Actions */
import { Creators as UserActions } from "../../../store/ducks/user";
import { Creators as AppActions } from "../../../store/ducks/app";

import { Formik } from "formik";
import { Row, Col, Input, Spin, notification } from "antd";
import { Container, Footer, CustomDivider, CustomInput } from "./styles";

// Components
import ButtonStandard from "../../utils/button";
import IconCheck from "../../utils/icons/check/white";
import ReCAPTCHA from "react-google-recaptcha";

// Services
import {
  changePassword,
  validateCode,
  verifyExistsUserByEmail,
} from "../../../services/changePasswordService";

class DrawerChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formProfile: {
        password: "",
      },
      isLoading: false,
      confirmPassword: "",
      isCodeValidated: false,
      code: "",
      emailValidate: "",
      redirect: false,
      authorized: false,
      captchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
      captchaActive: process.env.REACT_APP_RECAPTCHA_ACTIVATE,
    };
    this.handleChangeValidate = this.handleChangeValidate.bind(this);
  }

  closeDrawer = () => {
    const { userActions } = this.props;
    userActions.hideDrawerChangePassword();
  };

  handleResendCode = async () => {
    const { emailValidate } = this.state;
    this.setState({
      isLoading: true,
      code: null,
      isCodeValidated: false,
    });
    try {
      await verifyExistsUserByEmail(emailValidate);
      notification.success({
        message: "Código de validação enviado para o e-mail.",
      });
      this.setState({
        isLoading: false,
      });
    } catch (e) {
      this.setState({
        isLoading: false,
      });
      if (e.response?.status === 409) {
        notification.error({
          message: "E-mail já cadastrado em nossa base de dados",
        });
      } else if (
        e.response?.status === 500 &&
        e.response?.data.results.description.includes(
          "query did not return a unique result"
        )
      ) {
        notification.error({
          message: "E-mail já cadastrado em nossa base de dados",
        });
      } else {
        notification.error({
          message: "Erro interno. Contate o suporte.",
        });
      }
    }
  };

  handleChangeValidate(event) {
    this.setState({ code: event.target.value });
  }

  handleValidateCode = async () => {
    const { emailValidate, code } = this.state;
    const obj = {
      email: emailValidate,
      codeValidate: code,
    };
    try {
      await validateCode(obj);
      notification.success({ message: "Código verificado com sucesso" });
      this.setState({ isCodeValidated: true });
    } catch {
      notification.error({ message: "Código inválido" });
    }
  };

  handleDrawerVisible = async (visible) => {
    if (visible) {
      const { formData } = this.props;
      this.setState({
        emailValidate: formData.email,
        id: formData.id,
      });
    }
  };

  handleSubmitModalForm = async (
    values,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) => {
    try {
      this.setState({
        isLoading: true,
      });
      const { authorized, captchaActive } = this.state;
      const { userActions } = this.props;
      if (authorized || captchaActive === "false") {
        await changePassword(this.state.id, values);
        notification.success({ message: "Senha redefinida com sucesso!" });
        userActions.hideDrawerChangePassword();
        this.setState({
          redirect: true,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
        notification.error({ message: "Prove que você não é um robô" });
      }
    } catch (e) {
      if (
        e !== undefined &&
        e !== null &&
        e.toString().indexOf("Request failed with status code 409") > -1
      ) {
        notification.error({ message: "Senha já utilizada anteriormente." });
      } else {
        notification.error({ message: "Erro ao redefinir as senha." });
      }
      this.setState({
        isLoading: false,
      });
    }
  };

  handleCaptcha = () => {
    this.setState({
      authorized: true,
    });
  };

  render() {
    const {
      app: { translation },
      user: { drawerVisibleChangePassword },
    } = this.props;

    const {
      isLoading,
      formProfile,
      isCodeValidated,
      redirect,
      captchaActive,
      captchaKey,
    } = this.state;

    const validationSchema = Yup.object().shape({
      password: Yup.string().required(),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    });

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }

    return (
      <Container
        title="Trocar senha"
        width={425}
        onClose={this.closeDrawer}
        maskClosable={false}
        visible={drawerVisibleChangePassword}
        afterVisibleChange={this.handleDrawerVisible}
      >
        <Spin spinning={isLoading}>
          <div className="drawerFormCustom">
            <Row type="flex">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <span style={{ fontSize: "14px", color: "#000" }}>
                  Foi enviado um código para o e-mail informado, copie e cole no{" "}
                  <br /> campo abaixo
                </span>
              </Col>
            </Row>
            <Row type="flex" style={{ marginTop: "13px" }} align="middle">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row>
                  <CustomInput
                    className="input-custom"
                    placeholder="INFORME O CÓDIGO AQUI"
                    value={this.state.code}
                    name="code"
                    onChange={this.handleChangeValidate}
                  />
                </Row>
              </Col>
            </Row>

            <Row
              type="flex"
              style={{ marginTop: "13px", justifyContent: "space-between" }}
              align="middle"
            >
              <button
                type="button"
                className="resend"
                onClick={this.handleResendCode}
              >
                <strong>ENVIAR CÓDIGO NOVAMENTE</strong>
              </button>

              <ButtonStandard
                className="verify"
                buttonType="type1"
                background={isCodeValidated ? "#30b142" : "#684e94"}
                type="submit"
                onClick={() => this.handleValidateCode()}
                size="s"
              >
                {isCodeValidated ? <IconCheck /> : null}
                {isCodeValidated ? " Verificado" : "Validar"}
              </ButtonStandard>
            </Row>
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={formProfile}
            initialErrors={{}}
            onSubmit={this.handleSubmitModalForm}
            validationSchema={validationSchema}
            render={(props) => (
              <form onSubmit={props.handleSubmit} autoComplete="off">
                <div className="drawerForm">
                  <div className={isCodeValidated ? "" : "hide"}>
                    <CustomDivider dashed />
                    <Row type="flex">
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <span style={{ fontSize: "14px", color: "#000" }}>
                          Defina sua senha de acesso
                        </span>
                      </Col>
                    </Row>

                    {/* Input password */}
                    <Row
                      type="flex"
                      style={{ marginTop: "13px" }}
                      align="middle"
                    >
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row>
                          <label
                            className={
                              props.errors.password && props.submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            Nova senha
                          </label>
                        </Row>
                        <Row>
                          <Input
                            type="password"
                            autoComplete="chrome-off"
                            placeholder={translation.defaultPlaceholder}
                            value={props.values.password}
                            name="password"
                            onChange={(e) => {
                              props.setFieldValue("password", e.target.value);
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>

                    {/* Input confirm password */}
                    <Row
                      type="flex"
                      style={{ marginTop: "13px" }}
                      align="middle"
                    >
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row>
                          <label
                            className={
                              props.errors.confirmPassword &&
                              props.submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            Confirme a nova senha
                          </label>
                        </Row>
                        <Row>
                          <Input
                            type="password"
                            autoComplete="chrome-off"
                            placeholder={translation.defaultPlaceholder}
                            value={props.values.confirmPassword}
                            name="confirmPassword"
                            onChange={(e) => {
                              props.setFieldValue(
                                "confirmPassword",
                                e.target.value
                              );
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <div className="captcha">
                    {captchaActive === "true" && isCodeValidated ? (
                      <ReCAPTCHA
                        sitekey={captchaKey}
                        onChange={this.handleCaptcha}
                      />
                    ) : null}
                  </div>
                </div>
                {/* Footer */}
                <Footer>
                  <Row type="flex" justify="space-between">
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="selectDiv"
                    />

                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="buttonsDiv"
                    >
                      <ButtonStandard
                        type="button"
                        buttonType="type7"
                        onClick={this.closeDrawer}
                      >
                        {translation.buttons.cancel}
                      </ButtonStandard>

                      <ButtonStandard buttonType="type6" type="submit">
                        {translation.buttons.save}
                      </ButtonStandard>
                    </Col>
                  </Row>
                </Footer>
              </form>
            )}
          />
        </Spin>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(UserActions, dispatch),
  appActions: bindActionCreators(AppActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrawerChangePassword);
