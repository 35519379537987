import styled from "styled-components";
import { Modal, Radio, Input } from "antd";

export const ModalCustom = styled(Modal)`
  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;

    &.error {
      color: #d44c4c;
    }

    &.alert {
      color: #fe8d2a;
    }

    svg {
      margin-left: 5px;
    }
  }

  &.ant-modal {
    padding-bottom: 0px;
  }

  .ant-row:not(:first-of-type) {
    margin-top: 8px;

    &.rowInputGroup {
      margin-top: 16px;
    }
  }
  .ant-row-flex {
    margin-top: 8px;
    &.rowButton {
      margin-top: 15px;
    }
  }

  .ant-row:first-of-type {
    margin-bottom: 12px;
  }

  .ant-calendar-picker-input.ant-input {
    width: 100%;
  }
`;

export const Title = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  color: #4b4b4b;
`;

export const CustomInput = styled(Input)`
  border-color: #684e94 !important;
  &.ant-input:hover,
  &.ant-input:active {
    border-color: #684e94 !important;
  }
  &.ant-input::-webkit-input-placeholder {
    /* Edge */
    color: #9074bf !important;
  }

  &.ant-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #9074bf !important;
  }

  &.ant-input::placeholder {
    color: #9074bf !important;
  }
`;

export const RadioButtonCustom = styled(Radio.Button)`
  margin-top: 6px !important;
  border-radius: 5px !important;
  background: transparent !important;
  border: 1px solid #4b4b4b !important;
  border-color: #4b4b4b !important;

  span {
    font-family: Asap !important;
    font-weight: normal !important;
    font-size: 14px !important;
    text-align: left !important;
    color: #4b4b4b !important;
  }

  &:first-of-type {
    margin-left: 0px;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  &:not(:first-of-type) {
    margin-left: 0px;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  &.ant-radio-button-wrapper-disabled {
    border-color: #c4c4c4 !important;
    span {
      color: #c4c4c4 !important;
    }
  }

  &.ant-radio-button-wrapper-checked {
    &:first-child,
    &:not(:first-child) {
      border-color: #4b4b4b !important;
    }
    color: #fff !important;
    background: #4b4b4b !important;
    border-color: #4b4b4b !important;

    &.ant-radio-button-wrapper-disabled {
      border-color: #c4c4c4 !important;
      background: #c4c4c4 !important;
    }

    span {
      font-family: Asap !important;
      font-weight: normal !important;
      font-size: 14px !important;
      text-align: left !important;
      color: #fff !important;
    }
  }
  &:not(:first-child)::before {
    visibility: hidden !important;
  }
`;

export const Button = styled.button`
  background: transparent;
  border: none;
  font-family: Asap;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #684e94;
  cursor: pointer;

  &.cancel {
    color: #d44c4c;
  }
`;
