export default {
  diet: {
    title: "Dietas",
    buttonNewDiet: "Nueva dieta",
    table: {
      title: "Lista de Dieta",
      columns: {
        status: "Estado",
        description: "Dieta",
        phosphorus: "Fósforo (mg)",
        nitrogen: "Nitrógenio (mg)",
        tdn: "NDT (g)",
        cp: "Proteína Cruda (g)",
        calcium: "Calcio (mg)",
        cost: "Costo (R$/Kg)",
      },
    },
    drawerTitle: "Registro de Dieta",
    drawerTitleEdit: "Editar Dieta",
    drawerSubTitle: "Completa los siguientes campos para crear una nueva dieta",
    drawerSubTitleEdit: "Completa los siguientes campos para editar la dieta",
    validationMessage:
      "Completa todos los campos obligatorios con * correctamente",
    form: {
      groupFarmVisibility: "¿Quién usará el pasto?",
      name: "Identificación de la dieta",
      namePlaceHolder: "Nombre de la dieta",
      pasture: "Pasto",
      pasturePlaceHolder: "¿Qué es el pasto de la dieta?",
      pastureNoSelection: "Ninguno",
      supplements: "Suplementos/Concent.",
      supplementsPlaceHolder: "¿Qué son los Suplemento/Concent. dietéticos?",
      cost: "Costo de la dieta (R$/Kg)",
      costPlaceHolder: "¿Cuál es el costo de la dieta en R$/Kg?",
      phosphorus: "Fósforo (mg)",
      phosphorusPlaceHolder: "¿Cuánto fósforo?",
      nitrogen: "Nitrógeno (mg)",
      nitrogenPlaceHolder: "¿Cuánto nitrógeno?",
      calcium: "Calcio (mg)",
      calciumPlaceHolder: "¿Cuánto calcio?",
      tdn: "NDT (g)",
      tdnPlaceHolder: "¿Cuánto NDT?",
      cp: "Proteína Cruda (g)",
      cpPlaceHolder: "¿Cuánta proteína cruda?",
      status: "Estado de la dieta",
      error: {
        requiredPastureSupplement: {
          title: "Error",
          message:
            "Se debe seleccionar pasto o al menos un Suplemento/Concent.",
        },
      },
    },
    saveOptions: {
      only_save: "Guardar solo",
      save_and_create_new: "Guardar y crear nueva entrada de dieta",
    },
  },
  pasture: {
    title: "Forrageiras",
    buttonNewPasture: "Nuevo forrageira",
    table: {
      title: "Lista de Forrageiras",
      columns: {
        status: "Estado",
        description: "Forrageira",
        phosphorus: "Fósforo (mg)",
        nitrogen: "Nitrógeno (mg)",
        tdn: "NDT (g)",
        cp: "PC (g)",
        calcium: "Calcio (mg)",
        cost: "Costo (animal/ha)",
        entryHeight: "Alt. Entrada",
        exitHeight: "Alt. Salida",
        growthSpeed: "Veloc. Cresc.",
        yield: "Rendimiento",
      },
    },
    drawerTitle: "Registro de Forrageira",
    drawerTitleEdit: "Editar Forrageira",
    drawerSubTitle:
      "Completa los siguientes campos para crear un nuevo forrageira",
    drawerSubTitleEdit:
      "Completa los siguientes campos para editar el forrageira",
    validationMessage:
      "Completa todos los campos obligatorios con * correctamente",
    form: {
      groupFarmVisibility: "Quem irá utilizar a forrageira?",
      name: "Identificación",
      namePlaceHolder: "Nombre del forrageira",
      pastureType: "Nombre científico",
      fertilized: "Fertilizado",
      notFertilized: "No fertilizado",
      status: "Estado del forrageira",
      cost: "Costo",
      costPlaceHolder: "Medida: R$",
      cp: "PC: Proteína Cruda",
      cpPlaceHolder: "Medida: g",
      fdn: "FDN: Fibra em detergente neutro",
      fdnPlaceHolder: "Medida: %",
      tdn: "NDT",
      tdnPlaceHolder: "Medida: g",
      phosphorus: "Fósforo",
      phosphorusPlaceHolder: "Medida: g/kg",
      nitrogen: "Nitrogênio",
      nitrogenPlaceHolder: "Medida: mg/kg",
      calcium: "Cálcio",
      calciumPlaceHolder: "Medida: g/kg",
      zinc: "Zinco",
      zincPlaceHolder: "Medida: mg/kg",
      sodium: "Sódio",
      sodiumPlaceHolder: "Medida: g/kg",
      formation: "Formação",
      formationPlaceHolder: "Medida: dias",
      rest: "Descanso",
      restPlaceHolder: "Medida: dias",
      entryHeight: "Altura Entrada",
      entryHeightPlaceHolder: "Medida: cm",
      exitHeight: "Altura Salida",
      exitHeightPlaceHolder: "Medida: cm",
      growthRate: "Tasa de Crecimiento",
      growthRatePlaceHolder: "Medida: kg/ha/dia",
      leafDensityProduction: "Produção MS Foliar",
      leafDensityProductionPlaceHolder: "Medida: t/ha/corte",
      growthSpeed: "Tasa de Crecimiento",
      growthSpeedPlaceHolder: "Medida: kg MS/㎡/dia",
      yield: "Rendimiento",
      yieldPlaceHolder: "Medida: kg MS/㎡",
      nutritionalData: "DATOS NUTRICIONALES",
      capacity: "CAPACIDAD",
      maximumConsumption: "Consumo máximo",
      minimumConsumption: "Consumo mínimo",
      radiogroup: {
        farm: "Solo esta granja",
        group: "Granjas del grupo",
      },
      fda: "FDA",
      lignin: "Lignina",
      ee: "Extrato Etéreo",
      maximumHeight: "Altura Máxima",
      maximumHeightPlaceHolder: "Altura Máxima",
    },
    modal: {
      confirmation: {
        title: "Troca do Tipo da Forrageira",
        message:
          "Ao confirmar a troca do tipo da forrageira os valores de altura ideal de entrada, altura ideal de saída e estações climáticas serão alterados. Deseja continuar?",
        confirmButton: "Confirmar",
        cancelButton: "Cancelar",
      },
    },
    errorConsumptionMaximumLessThanMinimum:
      "El consumo máximo debe ser mayor que el consumo mínimo",
    errorEntryHeightLessThanExitEntryHeight:
      "La altura de entrada ideal debe ser mayor que la altura de salida ideal",
    errorNecessaryOneSeason: "Debe tener al menos una estación ingresada",
    saveOptions: {
      only_save: "Guardar solo",
      create_new: "Guardar y crear nueva entrada de pasto",
    },
  },
  breed: {
    title: "Razas",
    buttonNewBreed: "Nueva raza",
    table: {
      title: "Lista de Razas",
      columns: {
        status: "Status",
        geneticGroup: "Grupo Genético",
        description: "Descripción",
        name: "Raza",
        asymptoticWeight: "Peso Asintótico(kg)",
        estimatedMaximumAge: "Edad Adulta",
        correctionFactor: "Factor de Corrección",
        maturationRate: "Tasa de maduración",
      },
      menu: {
        parameters: "Parámetros",
      },
    },
    drawerTitle: "Registro de Raça",
    drawerTitleEdit: "Editar Raça",
    drawerSubTitle: "Completa los siguientes campos para crear una nueva raza",
    drawerSubTitleEdit: "Completa los siguientes campos para editar la raza",
    validationMessage:
      "Completa todos los campos obligatorios con * correctamente",
    form: {
      name: "Identificación del raza",
      namePlaceHolder: "Nombre de la raza",
      description: "Descripción",
      descriptionPlaceHolder: "Descripción de la raza",
      geneticGroup: "Grupo Genético",
      geneticGroupPlaceHolder: "Grupo Genético de la raza",
      status: "Estado de la raza",
    },
    saveOptions: {
      only_save: "Guardar solo",
      save_and_create_new: "Guardar y crear nueva entrada de raza",
      save_and_add_parameters: "Guardar y crear parámetros",
    },
    drawerParametersTitle: "Parámetros de la raza",
    formParameters: {
      asymptoticWeight: "Peso asintótico",
      asymptoticWeightPlaceHolder: "Establecer peso",
      theoricalCurve: "Curva teórica",
      estimatedMaximumAge: "Edad Adulta",
      estimatedMaximumAgePlaceHolder: "Edad Adulta",
      correctionFactor: "Factor de Corrección",
      correctionFactorPlaceHolder:
        "Factor de corrección para el cálculo de la curva de la raza",
      maturationRate: "Tasa de maduración",
      maturationRatePlaceHolder:
        "Tasa de maduración para el cálculo de la curva de la raza",
      status: "Estado de los parámetros de la raza",
      extimatedCurveTitle: "CURVA DE CRESCIMIENTO ESTIMADA",
      genetic: "GENÉTICO",
      diet: "DIETA",
      gestationDivider: "Tempo de Gestação",
      minGestationTime: "Mínimo",
      medGestationTime: "Médio",
      maxGestationTime: "Máximo",
    },
  },
  handlingOperation: {
    title: "Operaciones",
    buttonNewPasture: "Nueva operación",
    table: {
      title: "Lista de operaciones",
      columns: {
        status: "Estado",
        operation: "Operación",
        action: "Acción",
      },
    },
    drawerTitle: "Registro de operaciones",
    drawerTitleEdit: "Editar operación",
    drawerSubTitle:
      "Completa los siguientes campos para crear una nueva operación",
    drawerSubTitleEdit:
      "Completa los siguientes campos para editar la operación",
    validationMessage:
      "Completa todos los campos obligatorios con * correctamente",
    form: {
      name: "Operación",
      namePlaceHolder: "Nombre de la operación",
      action: "Acción",
      actionPlaceHolder: "Seleccionar acción de procedimiento",
      recurrence: "Recurrencia",
      recurrenceAmount: "Qty",
      quarantine: "Cuarentena",
      quarantineAmount: "Ingrese el número de días",
      breed: "Raza",
      breedPlaceHolder: "Seleccionar razas",
      gender: "Sexo",
      genderPlaceHolder: "Seleccionar",
      age: "Edad",
      weight: "Peso",
      dontExecuteOperation: "No realizar operaciones en animales que están en:",
      inQuarantine: "Cuarentena",
      inPregnancy: "Embarazo",
      operationCost: "Costo de operación",
      noCost: "Sin cargo",
      haveCost: "Con costo (definir costo en manejo)",
      status: "Estado de la operación",
    },
    parameters: "PARÁMETROS",
    limitations: "LIMITACIONES",
    errors: {
      recurrenceMinAndMaxDayCanBeNull: {
        title: "Error",
        message:
          "El número mínimo y máximo de días de recurrencia no debe estar en blanco",
      },
      recurrenceQtdCanBeNull: {
        title: "Error",
        message:
          "El número de veces que la recurrencia no puede estar en blanco",
      },
    },
    saveOptions: {
      only_save: "Guardar solo",
      save_and_create_new: "Crear operación y agregar nueva",
    },
  },
  handling: {
    title: "Gestiones",
    tabs: {
      pending: "Pendiente",
      finalized: "Terminado",
      dietStrategies: "Estrategias de dieta",
      vacinations: "Veterinários",
      handlingReproduction: "Reprodução",
    },
    vacinations: {
      buttonNew: "Nova Aplicação",
      form: {
        titleEdit: "Editar Aplicação",
        titleCreateNew: "Nova Aplicação",
        dividerTitle: "APLICAÇÃO ANTERIOR",
        animalId: "Animal",
        lastDailyWeight: "Última pesagem",
        handlingNumber: "Animal",
        veterinarySupplementId: "Produto",
        veterinarySupplementApplicationType: "Forma de Aplicação",
        veterinarySupplementApplicationValue: "Dose",
        applicationQuantity: "Qtde Aplicada",
        applicationUnit: "Unidade",
        applicationDay: "Data Aplicação",
      },
      table: {
        title: "Veterinários",
        columns: {
          animalName: "Animal",
          veterinarySupplementName: "Nome",
          type: "Tipo",
          applicationDate: "Data",
          applicationQuantity: "Quantidade",
          applicationUnit: "Unidade",
        },
      },
    },
  },
  parameters: {
    title: "Parámetros",
    tabs: {
      general: "General",
      breed: "Razas",
      diet: "Dietas",
      supplement: "Suplementos/Concent.",
      pasture: "Forrageira",
      alert: "Alertas",
      benchmark: "Benchmarks",
      marketAnalysis: "Curva de Precios",
      cost: "Financieros/Costos",
      activity: "Atividades",
      boitelPrice: "Boitel - Diárias",
      integration: "Integrações",
      financial: "Custeio",
      reproduction: "Reprodução",
      reproductionParameter: "Parâmetros",
      semen: "Sêmens",
      inseminators: "Inseminadores",
      supplie: "Insumos",
      animal: "Animais",
      deathReasons: "Causa Mortis",
      unit: "Unidades",
      veterinary: "Veterinários",
      commercialization: "Comercialização",
      quota: "Cotas",
      multipleBull: "Touro Múltiplo",
      reproductiveProtocol: "Protocolos",
    },
  },
  integrationPartners: {
    table: {
      title: "Integrações",
      columns: {
        status: "Situação",
        system: "Sistema",
        authType: "Tipo Autenticação",
      },
    },
    authType: {
      Token: "Token",
      UserAndPassword: "Usuário e Senha",
    },
  },
  imports: {
    title: "Importações",
    tabs: {
      animal: "Animais",
      weight: "Pesagens",
      supplement: "Suplementos",
      financialTransactions: "Mov. Financeiras",
    },
    status: {
      queue: "Aguardando",
      processing: "Processando",
      processed: "Processado",
      deleting: "Deletando",
      error: "Erro",
    },
    animal: {
      buttonImport: "Importar",
      table: {
        title: "Lista de Importações de Animais",
        columns: {
          status: "Status",
          fileName: "Arquivo",
          date: "Data",
          total: "Total",
          numberImported: "Importados",
          numberAlerted: "Alertas",
          numberError: "Erros",
          numberDuplicated: "Duplicados",
          numberUpdated: "Atualizados",
          message: "Mensagem",
        },
      },
      details: {
        breadCumbsImportLog: "Importações",
        breadCumbsImportLogDetails: "Detalhes da Importação",
        collapseAnimals: "Animais",
        fileNameTitle: "Arquivo",
        statusTitle: "Status",
        dateTitle: "Data",
        table: {
          columns: {
            rowNumber: "Linha Excel",
            handlingNumber: "Identificação do Animal",
            message: "Motivo",
            alert: "Alertas",
          },
        },
      },
    },
    weight: {
      buttonImport: "Importar",
      table: {
        title: "Lista de Importações de Pesagens",
        columns: {
          status: "Status",
          fileName: "Arquivo",
          date: "Data",
          total: "Total",
          numberImported: "Importados",
          numberError: "Erros",
          numberDuplicated: "Duplicados",
          numberUpdated: "Atualizados",
          message: "Mensagem",
        },
      },
      details: {
        breadCumbsImportLog: "Importações",
        breadCumbsImportLogDetails: "Detalhes da Importação",
        collapseAnimals: "Pesagens",
        fileNameTitle: "Arquivo",
        statusTitle: "Status",
        dateTitle: "Data",
        table: {
          columns: {
            rowNumber: "Linha Excel",
            weight: "Peso",
            handlingNumber: "Identificação do Animal",
            message: "Motivo",
          },
        },
      },
    },
    supplement: {
      buttonImport: "Importar",
      table: {
        title: "Lista de Importações de Suplementos",
        columns: {
          status: "Status",
          fileName: "Arquivo",
          date: "Data",
          total: "Total",
          numberImported: "Importados",
          numberError: "Erros",
          numberDuplicated: "Duplicados",
          numberUpdated: "Atualizados",
          message: "Mensagem",
        },
      },
      details: {
        breadCumbsImportLog: "Importações",
        breadCumbsImportLogDetails: "Detalhes da Importação",
        collapseAnimals: "Suplementos",
        fileNameTitle: "Arquivo",
        statusTitle: "Status",
        dateTitle: "Data",
        table: {
          columns: {
            rowNumber: "Linha Excel",
            name: "Identificação do Suplemento",
            message: "Motivo",
          },
        },
      },
    },
    financialTransactions: {
      buttonImport: "Importar",
      table: {
        title: "Lista de Importações de Mov. Financeiras",
        columns: {
          status: "Status",
          fileName: "Arquivo",
          date: "Data",
          total: "Total",
          numberImported: "Importados",
          numberError: "Erros",
          numberDuplicated: "Duplicados",
          numberUpdated: "Atualizados",
          message: "Mensagem",
        },
      },
      details: {
        breadCumbsImportLog: "Importações",
        breadCumbsImportLogDetails: "Detalhes da Importação",
        collapseAnimals: "Mov. Financeiras",
        fileNameTitle: "Arquivo",
        statusTitle: "Status",
        dateTitle: "Data",
        table: {
          columns: {
            rowNumber: "Linha Excel",
            name: "Identificação da Mov. Financeira",
            message: "Motivo",
          },
        },
      },
    },
  },
  benchmark: {
    title: "Benchmarks",
    buttonNewBenchmark: "Nuevo benchmark",
    table: {
      title: "Lista de Benchmarks",
      columns: {
        status: "Status",
        description: "Benchmark",
      },
    },
    drawerTitle: "Registro de Benchmark",
    drawerTitleEdit: "Editar Benchmark",
    drawerSubTitle: "Complete los campos para registrar un nuevo benchmark",
    drawerSubTitleEdit: "Complete los campos para editar el benchmark",
    validationMessage:
      "Complete todos los campos obligatorios con * correctamente",
    form: {
      name: "Nombre",
      namePlaceHolder: "Identificación",
      status: "Estado del benchmark",
      groupFarmVisibility: "¿Quién usará el benchmark?",
      radiogroup: {
        farm: "Esta granja",
        group: "Granjas del grupo",
      },
      harvestDivider: "SAFRAS",
    },
    errorAtLeastOneKPIValue: "Debe ingresar datos de al menos uno valor de KPI",
    saveOptions: {
      only_save: "Guardar solo",
      save_and_create_new: "Crear benchmark y agregar nuevo",
    },
  },
  marketAnalysis: {
    title: "Curva de Precios",
    buttonNewMarketAnalysis: "Nueva Curva de Precios",
    table: {
      title: "Lista de Curva de Precios",
      columns: {
        status: "Estado",
        type: "Tipo",
        description: "Nombre",
        baseline: "Estándar",
      },
    },
    type: {
      bovexo: "BovExo",
      progressive: "Progressivo",
      linear: "Linear",
      free: "Livre",
    },
  },
  supplement: {
    title: "Suplementos/Concent.",
    buttonNewSupplement: "Nuevo Suplemento/Concent.",
    table: {
      title: "Lista de Suplementos/Concent.",
      columns: {
        status: "Estado",
        description: "Suplemento/Concent.",
        industrialName: "Nombre industrial",
        supplierName: "Proveedor",
        createdAt: "Data de Criação",
        userName: "Responsável",
        version: "Versão",
        cp: "PB: Proteína Bruta",
        tdn: "NDT: Nutrientes Digest. Totais",
        npn: "NNP - Equivalente Proteico (Máx)",
        dryMatterPercentage: "MS: Matéria Seca",
        phosphorus: "Fósforo",
        nitrogen: "Nitrogênio",
        calcium: "Cálcio",
        zinc: "Zinco",
        sodium: "Sódio",
        additiveEffect: "Efecto Adictivo",
        fda: "FDA",
        lignin: "Lignina",
        ee: "Extrato Etéreo",
      },
    },
    drawerTitle: "Registro de Suplemento/Concent.",
    drawerTitleEdit: "Editar Suplemento/Concent.",
    drawerSubTitle:
      "Complete los campos para registrar un nuevo Suplemento/Concent.",
    drawerSubTitleEdit:
      "Complete los campos para editar el Suplemento/Concent.",
    validationMessage:
      "Complete todos los campos obligatorios con * correctamente",
    form: {
      supplementType: "Ração/mistura ou Suplemento ?",
      supplementTypeHelp:
        "Pienso / Mezcla: Dieta preparada, producida en la granja. Suplementos: Producto adquirido y suministrado en forma pura.",
      supplementClassification: "Qual a classificação do Suplemento/Ração ?",
      supplementClassificationHelp: {
        title_0: "Suplemento Mineral",
        description_0:
          " quando possuir na sua composição, macro e/ou micro elemento mineral, podendo apresentar, no produto final, um valor menor que quarenta e dois porcento de equivalente protéico.",
        title_1: "Suplemento Mineral Protéico",
        description_1:
          " quando possuir na sua composição, macro e/ou micro elemento mineral, pelo menos 20% de proteína bruta (PB) e fornecer, no mínimo, 30 gramas de proteína bruta (PB) por 100 Kg de PV.",
        title_2: "Suplemento Mineral Protéico Energético",
        description_2:
          " quando possuir na sua composição, macro e/ou micro elemento mineral, pelo menos 20% de proteína bruta, fornecer, no mínimo, 30 gramas de proteína bruta e 100 gramas de nutrientes digestíveis totais (NDT) por 100 Kg de PV.",
        source: "*Conforme Mapa - IN12 de 30 de novembro de 2004.",
      },
      name: "Nombre genérico",
      namePlaceHolder: "Identificación",
      importCode: "Código de importação",
      industrialName: "Nombre industrial",
      industrialNamePlaceHolder: "Nombre industrial",
      supplierName: "Proveedor",
      supplierNamePlaceHolder: "Nombre del proveedor",
      status: "Estado del Suplemento/Concent.",
      nutritionalDataDivider: "DATOS NUTRICIONALES",
      capacityDivider: "CANTIDAD POR OFERTA",
      information: 'Toda la información se refiere a "Animal/día"',
      sustenanceCapacity: "CANTIDAD POR OFERTA",
      sustenanceCapacityDrawerTitle:
        "Cantidad por Oferta del Suplemento/Concent.",
      minimumAmount: "Qtd Mín",
      maximumAmount: "Qtd Máx",
      minimum: "Mínimo",
      maximum: "Máximo",
      ratio: "Ratio",
      cost: "Costo diario",
      costPlaceHolder: "Medida: $",
      cp: "PC: Proteína Cruda",
      cpPlaceHolder: "Medida: g/kg",
      tdn: "TDN",
      tdnPlaceHolder: "Medida: g/kg",
      npn: "NNP - Equivalente Proteico (Máx)",
      npnPlaceHolder: "Medida: g/kg",
      dryMatterPercentage: "MS: Matéria Seca",
      phosphorus: "Fósforo",
      phosphorusPlaceHolder: "Medida: g/kg",
      zinc: "Zinco",
      zincPlaceHolder: "Medida: mg/kg",
      sodium: "Sódio",
      sodiumPlaceHolder: "Medida: g/kg",
      nitrogen: "Nitrógeno",
      nitrogenPlaceHolder: "Medida: mg/kg",
      calcium: "Calcio",
      calciumPlaceHolder: "Medida: g/kg",
      voluntaryConsumption: "Consumo Voluntario",
      minimumVoluntaryConsumptionPlaceHolder: "Medida: g",
      maximumVoluntaryConsumptionPlaceHolder: "Medida: g",
      pure: "Oferta Pura",
      mix: "Oferta de Mezcla",
      recommendedTrough: "Canal Recomendado",
      recommendedTroughPlaceHolder: "Canal recomendado",
      groupFarmVisibility: "¿Quién usará el Suplemento/Concent.?",
      radiogroup: {
        farm: "Esta granja",
        group: "Granjas del grupo",
      },
      additiveEffect: "Efecto Adictivo",
      additiveEffectPlaceHolder: "Medida: g/dia",
      additiveEffectHelp:
        "Alguns suplementos minerais possuem aditivos em sua formulação que potencializam o ganho de peso dos animais, podendo o fornecedor indicar um ganho incremental (g/dia), somando ao ganho previsto calculado (Pasto + Suplemento) ou indicar por um aumento % do total de Proteína Bruta (PB) a ser considerado no calculo.",
      fda: "FDA",
      fdaPlaceHolder: "Medida: g/kg",
      lignin: "Lignina",
      ligninPlaceHolder: "Medida: g/kg",
      ee: "Extrato Etéreo",
      eePlaceHolder: "Medida: %/MS",
    },
    errorVoluntaryConsumptionMaximumLessThanMinimum:
      "El consumo voluntario máximo debe ser mayor o igual que el consumo mínimo",
    errorPureMaximumLessThanMinimum:
      "La oferta pura máxima debe ser mayor o igual que la oferta pura mínima",
    errorPureMaximumTroughLessThanMinimumTrough:
      "El canal recomendado máximo debe ser mayor o igual que el mínimo",
    errorMixMaximumLessThanMinimum:
      "La oferta de mezcla máxima debe ser mayor o igual que la oferta de mezcla mínima",
    errorNecessaryOneSeason:
      "Debe ingresar datos de al menos una estación meteorológica",
    errorNecessarySustenanceCapacity:
      "É necessário informar os dados de quantidades por modo de oferta.",
    errorWrongNpnInput:
      "Atenção! São classificados como Suplementos Minerais, somente produtos com até 42,0 % de NNP - Equivalente Proteico. Por favor, verifique a Classificação deste Suplemento.",
    saveOptions: {
      only_save: "Guardar solo",
      save_and_create_new: "Crear Suplemento/Concent. y agregar nuevo",
    },
  },
  lot: {
    validationMessage:
      "Rellene todos los campos obligatorios con * correctamente",
    tabs: {
      production: "Producción",
      movementHistory: "Histórico Mov.",
      receipt: "Recibo",
    },
    production: {
      title: "Muchos de Producción",
      buttonNewLot: "Nuevo mucho de Producción",
      details: {
        breadCumbsProductionLot: "Lotes en producción",
        breadCumbsLotDetails: "Detalles del lote",
        createdAt: "Creación:",
        options: "Opciones",
        labelSystem: "ACTIVIDAD",
        labelDietStrategy: "ESTRATEGIA DE DIETA",
        baseline: "Baseline",
        optionalStrategies: "Estrategias opcionales",
        optional: "Opcional",
        labelAnimals: "ANIMALES",
        labelAsymptoticWeight: "PESO ASÍMPTICO",
        labelMarketValuation: "EVALUACIÓN DEL MERCADO",
        labelProductionCost: "Custo de Produção",
        makeValuation: "Evaluar",
        labelWeights: "PESOS",
        labelAverageWeight: "PESO MEDIO:",
        labelAverageWeightGain: "GMD:",
        labelPicket: "PIQUETE:",
        labelProductionArea: "AREA UTIL:",
        labelTotalArea: "ÁREA UTIL:",
        labelSupportCapacity: "C. SOPORTE:",
        titleAnimals: "Animales",
        titleWeigth: "Peso",
        viewBy: "Ver por:",
        tooltipEstimatedCurve:
          'Curva teórica obtenida de RACE, haga clic en "Estimar lote" para establecer los valores del lote',
      },
      table: {
        columns: {
          lotNumber: "Nº Mucho",
          lotName: "Mucho",
          salesScenario: "Escenario de venta",
          dietStrategyName: "Estrategia de dieta",
          profitCenter: "Actividad",
          createdAtAndEndAt: "Creación / Cierre",
          status: "Estatus",
          amount: "Qtd.",
          gdp: "GDP",
          diet: "Dieta",
          averageWeight: "Peso medio",
          target: "Objetivo",
        },
      },
      drawer: {
        title: "Crear lote de producción",
        titleEdit: "Editar lote de producción",
        subtitle: "Completa los siguientes campos para crear un lote",
        subtitleEdit: "Completa los siguientes campos para editar un lote",
        form: {
          status: "Estado",
          name: "Nome do lote*",
          namePlaceHolder: "Nome do lote",
          identification: "Código de Importação",
          identificationPlaceHolder: "Código de Importação",
          profitCenter: "Actividad*",
          profitCenterPlaceHolder: "Seleccione la Actividad",
          asymptoticWeight: "Peso asintótico",
          carcassHarnessing: "Utilización de la Carcasa",
          informPlaceHolder: "Informe",
          picket: "Piquete",
          picketMessage: "Seleccione el piquete si ya desea asignar este lote",
          picketPlaceHolder: "Seleccione el piquete",
          dietStrategyBaseLine: "Estrategia de dieta",
          dietStrategyBaseLineMessage:
            "Seleccione la estrategia que se utilizará para alimentar a los animales agregados a este lote",
          dietStrategies: "Estrategia de dieta - Opcional",
          dietStrategiesPlaceHolder:
            "Seleccione una o más estrategias de dieta",
          dietStrategiesTooltip:
            "Las estrategias de dieta opcionales seleccionadas se utilizarán en futuras simulaciones de escenarios de compra y venta",
          checkboxSaveAndAddNewAnimals: "Guardar y agregar animales",
          picketId: "Piquete",
          picketLotEntry: "Data de entrada do Lote",
        },
      },
      saveOptions: {
        only_save: "Guardar solo",
        save_and_add_animals: "Guardar lote y agregar animales",
      },
      addAnimalsPage: {
        listLotProduction: "Lista: Lotes de producción",
        title: "Agregar animal al lote",
        descriptionMessage:
          "Seleccione animales en la columna gris y muévalos a la columna naranja haciendo clic en",
        selectPlaceHolder:
          "Seleccione la raza de animales que debe formar el lote",
        listAnimals: "Lista de animales",
        productionLot: "Lote de producción",
        modal: {
          message: "Haga clic en confirmar para agregar los animales al lote",
          lot: "Lot",
          profitCenter: "Actividad",
          amount: "Cantidad",
        },
        error: {
          noAnimalsTitle: "Error",
          noAnimalsMessage: "Debe seleccionar uno o más animales",
        },
      },
      dismemberLotPage: {
        listLotProduction: "Lista: lotes de producción",
        title: "Desmembrar Lote",
        descriptionMessage:
          "Seleccione los animales en la tabla y haga clic en el botón 'Dismembrar' para reubicar a los animales",
        profitCenter: "Actividad",
        productionSubSystem: "Subsistema de producción",
        animalsTotal: "Total de Animales",
        animalsSelected: "Animales seleccionados",
        dismemberButton: "Desmembrar",
        destinationLots: "Lotes de destino",
        drawer: {
          title: "Desmembrar el lote",
          description:
            "Seleccione a continuación si la separación será en un nuevo lote o en un lote existente",
          newButton: "Nuevo",
          existingButton: "Existente",
          alertMessage:
            "La actividad de lotes informado difiere del lote actual",
          searchInputPlaceHolder: "Buscar la Identificación del lote",
        },
      },
    },
    receipt: {
      title: "Lotes de recepción",
      buttonNewLot: "Nuevo mucho de recepción",
      collapse: {
        animals: "Animales",
        receiptIn: "Recibidos en",
      },
      table: {
        columns: {
          breed: "Raça",
          gender: "Sexo",
          birthday: "Idade/meses",
          weight: "Peso estimado",
          amount: "Qtd. animais",
          confirmedAmount: "Qtd. animais recebidos",
          purchaseCost: "Custo de aquisição",
          numbers: "Numeração",
        },
        boitelColumns: {
          identification: "Identificação",
          amount: "Qtd. animais",
          breed: "Raça",
          entryDate: "Data de entrada",
          weightRange: "Faixa de peso",
          confirmedAmount: "Qtd. animais recebidos",
          identifiedAmount: "Qtd. animais identificados",
          status: "Situação",
        },
      },
      finalize: {
        title: "Creación de lotes de recepción",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        buttonFinalize: "FINALIZAR RECEPCIÓN",
        buttonFinalizeCancel: "CANCELAR RECEPCIÓN",
        supplier: "Proveedor",
        dateReceiving: "Fecha de recepción",
        animalsReceived: "Animales recibidos",
        grid: {
          title: "Lote de recepción",
          animalsInserted: "animales insertados",
          buttonAddAnimals: "Añadir animales",
          columns: {
            breed: "Raza",
            gender: "Sexo",
            birthday: "Edad/mes",
            weight: "Peso estimado",
            amount: "Cant.",
            purchaseCost: "Coste de adquisición",
            numbers: "Numeración",
          },
          deleteBox: {
            description: "Al confirmar el lote se excluye",
            buttonConfirm: "Confirmar",
            buttonCancel: "Cancelar",
          },
        },
        newLotAnimalsReceipt: {
          title: "Nuevo animales",
          description: "Completa los detalles de la recepción de los animales",
          saveOptions: {
            only_save: "Guardar solo",
            save_and_create_new: "Guardar y crear nueva recepcíon",
            save_and_handle: "Guardar y hacer manejo",
          },
        },
        newLotItem: {
          title: "Agrupación de animales",
          description:
            "Agrupe los animales en recepción de acuerdo con sus características completando las informaciones abajo",
          tabGeneralTitle: "General",
          tabNumbersTitle: "Numeración",
          breed: "Raza",
          gender: "Sexo",
          birthday: "Nacimiento",
          birthdayPlaceHolder: "Seleccionar fecha",
          estimatedWeight: "Peso Estimado",
          estimatedWeightPlaceHolder: "Introduzca el peso - Kg",
          amount: "Cantidad",
          amoutPlaceHolder: "Introduzca la cantidad",
          purchaseCost: "Coste de adquisición",
          purchaseCostPlaceHolder: "Introduzca el costo de adquisición",
          rangeDescription:
            "Añada el número de animales que tienen las características",
          range: "Intervalos",
          numbersInserted: "Números insertados",
          saveOptions: {
            only_save: "Guardar solo",
            create_new: "Guardar y crear nueva entrada de animales",
          },
        },
        errors: {
          noAnimalsAdd: {
            title: "Ningún animal agregado",
            description:
              "Es necesario añadir la cantidad informada de animales",
          },
          amountAnimalsInLotItenBiggerThanAmountAnimalsReceveid: {
            title: "Cantidad de animales más grande",
            description:
              "La cantidad de animales insertada es mayor que la informada en el lote de recepción",
          },
          amountAnimalsInLotItenLessThanAmountAnimalsReceveid: {
            title: "Cantidad de animales menores",
            description:
              "La cantidad de animales insertada es menor que la informada en el lote de recepción",
          },
          amountAnimalsInLotItenBiggerThanAmountReported: {
            title: "Cantidad de animales invalida",
            description:
              "La cantidad de animales insertada es mayor que la indicada en la cantidad del lote",
          },
        },
      },
      supplier: {
        title: "Entrada de Animales - Compra",
        buttonNext: "Siguiente",
        buttonBack: "Regreso",
        titleBox: "Registro de Recepción",
        messageError: "Rellene todos los campos obligatorios *",
        supplierTitle: "Proveedor",
        supplierComboBoxPlaceHolder: "Seleccionar",
        lotName: "Nombre del lote",
        lotNameInputPlaceHolder: "Nombre del lote de recepción",
        dateTitle: "Fecha de entrada",
        datePlaceHolder: "Seleccionar",
        buttonToday: "HOY",
        qtyAnimalsTitle: "Cantidad de animales",
        qtyAnimalsInputPlaceHolder: "Cantidad de animales",
        receiptNameTitle: "Número de recepción",
        supplierInformationsTitle: "Datos del proveedor",
        documentTitle: "CNPJ",
        responsibleTitle: "Responsable",
        emailTitle: "E-mail",
        contactTitle: "Contacto",
        addressTitle: "Dirección",
      },
    },
  },
  picket: {
    status: {
      break: "Descanso",
      pasturage: "Pastejo",
      renovation: "Reforma",
      maintenance: "Manutenção",
      leased: "Arrendado",
      deleted: "Excluído",
      active: "Ativo",
      inactive: "Inativo",
    },
    dashboards: {},
    propertyType: {
      own: "Próprio",
      foreign: "Arrendado",
    },
    tabs: {
      picket: "Piquete/Baia",
      retreat: "Retiro/Linha",
    },
    title: "Lista de piquetes / baias",
    buttonNewPicket: "Novo piquete / baia",
    table: {
      columns: {
        picketName: "Piquete / Baia",
        totalAndProductionArea: "Área total/útil",
        propertyType: "Tipo",
        profitCenterName: "Atividade",
        lotName: "Lote",
        status: "Status",
        // inAndOutLot: "Entrada/Saída de lote",
        inAndOutLot: "Entrada de lote",
        supportCapacity: "Capac. suporte",
        averageWeight: "Peso médio",
        gmd: "GMD",
        amountAnimals: "Qtd.",
      },
    },
    pastureTable: {
      title: "Estados dos Pastos",
      titleTooltip:
        "O Gráfico Estado dos Pastos, apresenta diferentes visões para que possa acompanhar e planejar as movimentações de animais entre piquetes, de forma a otimizar o uso das pastagens." +
        " - Estoque MS: Apresenta a disponibilidade (%) de MS da forrageira utilizada, conforme a estação climática e consumo dos animais presentes no Piquete. Diariamente é executado a rotina de recalculo, para atualizar a estimativa de disponibilidade dos próximos 30 dias." +
        " - Taxa de Oferta: Apresenta a relação de Oferta e Consumo de Matéria Seca (MS) da forrageira disponível no piquete, exibindo a quantidade de dias em que o Lote de animais poderá ficar no piquete, de acordo com o estoque de MS disponível e consumo previsto dos animais.",
      columns: {
        picketName: "Piquete",
        status: "Status",
        days: "Dias acumulados",
        lotation: "Taxa de lotação",
      },
      tableType: {
        dryStockOption: "Estoque MS",
        OfferRateOption: "Taxa de Oferta",
      },
    },
    drawerTitle: "Cadastro de Piquete / Baia",
    drawerTitleEdit: "Editar Piquete",
    drawerTitleEditIntensiveSubSystem: "Editar Baia",
    drawerSubTitle:
      "Complete os campos abaixo para criar um novo piquete / baia",
    drawerSubTitleEdit:
      "Complete os campos abaixo para editar o piquete / baia",
    drawerAllocateLotTitle: "Alocação de lote no piquete / baia",
    drawerAllocateLotSubTitle: "Alocação de lote no piquete / baia",
    validationMessage:
      "Preencha todos os campos obrigatórios com * corretamente",
    searchInputPlaceHolder:
      "Pesquise pela identificação do lote e pressione ENTER.",
    alertLotWithProfitCenterDifferent:
      "O piquete / baia receberá a atividade do lote selecionado!",
    detail: {
      name: "Identificação",
      productionSubSystem: "Subsistema",
      supplierId: "Fornecedor",
      supplierDivider: "FORNECEDOR",
      sustenanceCapacityDivider: "CAPACIDADE DE SUPORTE",
      pastureDivider: "FORRAGEIRA",
      infrastructureDivider: "INFRAESTRUTURA",
      pasture: "Forrageira",
      pastureDailyCost: "Custo diário",
      pastureAnimalCapacity: "Ocupação por animal",
      pastureArea: "Área disponível",
      pastureLeafDensity: "Densidade foliar",
      pastureFertilized: "Adubado",
      pastureCurrentHeight: "Altura atual do capim",
      pastureCurrentHeightDate: "Data da altura atual",
      pastureGrowthSpeed: "Velocidade Crescimento",
      troughSpacePerAnimal: "Espaço de cocho por animal",
      totalTroughSpace: "Espaço total de cocho",
      drinkingFountain: "Espaço total do bebedouro",
      lotId: "Lote",
      leaseCost: "Custo do arrendamento",
      profitCenterId: "Atividade",
      profitCenterType: "Tipo",
      pastureId: "Tipo de forrageira",
      moreInformationButton: "MAIS INFORMAÇÃO",
    },
    form: {
      name: "Identificação do piquete / baia",
      namePlaceHolder: "Identificação do piquete / baia",
      importCode: "Código de importação",
      propertyType: "O piquete / baia é interno ou terceirizado?",
      productionSubSystem: "Subsistema de produção",
      productionSubSystemPlaceHolder: "Selecione o Sistema",
      supplierId: "Fornecedor",
      supplierIdPlaceHolder: "Selecione o fornecedor",
      sustenanceCapacity: "CAPACIDADE DE SUPORTE",
      alocateLotTitle: "ALOCAR LOTE DE PRODUÇÃO",
      infrastructureDivider: "INFRAESTRUTURA",
      lotId: "Lote",
      lotIdPlaceHolder: "Selecione um lote",
      lotStartDate: "Entrada do lote atual",
      oldLotEndDate: "Saída do lote antigo",
      leaseMonthlyCost: "Custo do arrendamento",
      profitCenterId: "Atividade utilizada",
      profitCenterIdPlaceHolder: "Selecione a atividade",
      status: "Status do picket",
      sustenanceCapacityDrawerTitle: "Capacidade de Suporte do Piquete",
      sustenanceCapacityDrawerTitleIntensiveSubSystem:
        "Capacidade de Suporte da Baia",
      drinkingFountain: "Espaço total do bebedouro",
      troughSpacePerAnimal: "Espaço de cocho por animal",
      totalTroughSpace: "Espaço total de cocho",
      pastureDivider: "FORRAGEIRA",
      pastureId: "Forrageira",
      pastureIdPlaceHolder: "Selecione o tipo de forrageira",
      pastureAnimalCapacity: "Ocupação por animal",
      pastureArea: "Área disponível",
      pastureLeafDensity: "Densidade foliar",
      pastureFertilized: "Adubado",
      pastureDailyCost: "Custo diário ",
      pastureDailyCostTooltip:
        "Ao informar algum valor neste campo, o mesmo será utilizado na Estratégia de Dieta, independente da Estação Climática. Se nenhum valor for informado, será utilizado o Custo cadastrado em Pasto referente à cada Estação Climática.",
      pastureCurrentHeight: "Altura atual do capim",
      pastureCurrentHeightDate: "Data da altura atual",
      pastureGrowthSpeed: "Velocidade Crescimento",
      observation: "Observações",
      error: {
        requiredPastureId: {
          title: "Dados inválidos",
          message:
            "Selecione o tipo de forrageira quando subsistema de produção for Intensivo ou Semi-Intensivo",
        },
        requiredSupplierId: {
          title: "Dados inválidos",
          message:
            "Selecione o fornecedor quando o tipo de picket for Terceiro",
        },
        supplementConsumptionMeasureTypeInvalid: {
          title: "Dados inválidos",
          message: "Tipo de medida inválida para Tipo de Consumo selecionado",
        },
        productionSubSystemDifferentFromPicketProductionSubSystem: {
          title: "Dados inválidos",
          message:
            "Não pode alocar este lote, pois o sub-sistema de produção é diferente do piquete / baia",
        },
        sustenanceCapacityNotInformed: {
          title: "Dados inválidos",
          message: "Os dados da Capacidade de Suporte devem ser informados",
        },
      },
    },
    saveOptions: {
      only_save: "Salvar somente",
      save_and_create_new: "Criar piquete / baia e adicionar novo",
      save_and_add_lot: "Salvar e adicionar lote ao piquete / baia",
    },
    drawerMaintenance: {
      title: "Colocar piquete / baia em manutenção",
      subtitle:
        "Indique no campo abaixo o tempo que o piquete / baia ficará em manutenção",
      labelAmountDays: "Quantidade de dias",
      finishIn: "Finalizado em:",
      infoMessage: "Você será notificado quando o período for finalizado.",
    },
    drawerRenovation: {
      title: "Colocar piquete / baia em reforma",
      subtitle:
        "Indique no campo abaixo o tempo que o piquete / baia ficará em reforma",
      labelAmountDays: "Quantidade de dias",
      finishIn: "Finalizado em:",
      infoMessage: "Você será notificado quando o período for finalizado.",
    },
    drawerBreakTitle:
      "Você tem certeza que quer colocar este picket em Descanso ?",
    drawerInactivate: "Você tem certeza que quer inativar este piquete ?",
    drawerReactivate: "Você tem certeza que quer reativar este piquete ?",
    drawerDelete: "Você tem certeza de que quer excluir este piquete ?",
  },
  retreat: {
    title: "Lista de retiros",
    buttonNew: "Novo Retiro",
    table: {
      columns: {
        status: "Status",
        retreatName: "Retiro",
        area: "Área",
      },
      expandedColumns: {
        picketName: "Piquete/Baia",
        area: "Área",
        activity: "Atividade",
        status: "Status",
        lot: "Lote",
      },
    },
  },
  profitCenter: {
    title: "Actividades",
    saveOptions: {
      only_save: "Guardar solo",
      save_and_create_new: "Crear actividad y agregar nueva",
    },
    table: {
      title: "Lista de actividades",
      columns: {
        name: "Nombre",
        status: "Estado",
        type: "Tipo",
        productionSubSystem: "Subsistema de producción",
      },
    },
    drawerTitle: "Registro dela actividad",
    drawerTitleEdit: "Editar actividad",
    drawerSubTitle:
      "Completa los siguientes campos para crear una nueva actividad",
    drawerSubTitleEdit:
      "Completa los siguientes campos para editar la actividad",
    validationMessage:
      "Completa todos los campos obligatorios con * correctamente",
    modalCheckbox: "Guardar y agregar lote al piquete",
    form: {
      name: "ID dela actividad",
      namePlaceHolder: "Nombre dela actividad",
      productionSubSystem: "Subsistema de producción",
      productionSubSystemPlaceHolder: "¿Qué es el subsistema de producción?",
      type: "Tipo",
      typePlaceHolder: "¿Cuál es el tipo dela actividad?",
      status: "Estado dela actividad",
    },
  },
  farm: {
    title: "Granjas",
    table: {
      title: "Lista de granjas",
      columns: {
        name: "Nombre",
        status: "Estado",
      },
    },
    drawerTitle: "Registro de la granja",
    drawerTitleEdit: "Editar granja",
    drawerSubTitle:
      "Completa los siguientes campos para crear una nueva granja",
    drawerSubTitleEdit: "Completa los siguientes campos para editar la granja",
    validationMessage:
      "Completa todos los campos obligatorios con * correctamente",
    form: {
      name: "Nombre de la granja de lujo",
      namePlaceHolder: "Fancy Farm Name",
      group: "Grupo",
      groupPlaceHolder: "Seleccionar grupo",
      companyName: "Nombre de la empresa",
      companyNamePlaceHolder: "Nombre de la empresa agrícola",
      document: "CNPJ",
      documentPlaceHolder: "CNPJ",
      zipCode: "Código postal",
      zipCodePlaceHolder: "Código postal",
      address: "Dirección",
      addressPlaceHolder: "Carretera, autopista, calle ...",
      city: "Ciudad",
      cityPlaceHolder: "Ciudad",
      state: "UF",
      statePlaceHolder: "UF",
      status: "Estado de la granja",
      country: "País",
      countryPlaceHolder: "País",
      totalArea: "Area total",
      rentAreaOutSourced: "Area arrendada para terceros",
      livestockArea: "Area util",
      nonProductiveArea: "Area no productiva",
      cultivationArea: "Area de cultivo",
      improvementsArea: "Area de mejora",
      complementAddress: "Complemento",
      complementAddressPlaceHolder: "Complemento",
      weatherStation: "Estação Metereológica",
      weatherStationPlaceHolder: "Estação Metereológica",
    },
    saveOptions: {
      only_save: "Guardar solo",
      save_and_create_new: "Crear granja y agregar nueva",
    },
  },
  farmOwner: {
    title: "Proprietários da Fazenda",
    buttonNew: "Novo",
    table: {
      columns: {
        code: "Cód.",
        name: "Nome",
        document: "CPF/CNPJ",
        participation: "% Part.",
        mainOwner: "Principal ?",
      },
    },
    form: {
      code: "Còdigo",
      name: "Nome",
      document: "CPF/CNPJ",
      participation: "Participação",
      mainOwner: "Principal ?",
    },
  },
  supplier: {
    title: "Proveedores",
    types: {
      supplement: "Suplemento/Concent.",
      technology: "Tecnología",
      cattleFarmer: "Ganadero",
      others: "Outros",
      veterinarySupplement: "Insumos Veterinários",
    },
    table: {
      title: "Lista de proveedores",
      columns: {
        name: "Nombre",
        type: "Tipo",
        status: "Estado",
        phoneNumber: "Contacto",
        email: "Correo electronico",
        city_uf: "Ciudad/UF",
      },
    },
    drawerTitle: "Registro de la proveedor",
    drawerTitleEdit: "Editar proveedor",
    drawerSubTitle:
      "Completa los siguientes campos para crear una nueva proveedor",
    drawerSubTitleEdit:
      "Completa los siguientes campos para editar la proveedor",
    validationMessage:
      "Completa todos los campos obligatorios con * correctamente",
    form: {
      name: "Proveedor",
      namePlaceHolder: "Nombre del proveedor de lujo",
      type: "Tipo",
      typePlaceHolder: "Tipo del proveedor",
      phoneNumber: "Contacto",
      phoneNumberPlaceHolder: "Telefono para contacto",
      document: "CPF/CNPJ",
      documentPlaceHolder: "CPF ou CNPJ",
      email: "Correo electronico",
      emailPlaceHolder: "Correo electronico",
      zipCode: "Código postal",
      zipCodePlaceHolder: "Código postal",
      address: "Dirección",
      addressPlaceHolder: "Carretera, autopista, calle ...",
      city: "Ciudad",
      cityPlaceHolder: "Ciudad",
      state: "UF",
      statePlaceHolder: "UF",
      country: "Pais",
      countryPlaceHolder: "Pais",
      status: "Estado de la proveedor",
      groupFarmVisibility: "¿Quién usará el proveedor?",
      radiogroup: {
        farm: "Esta granja",
        group: "Granjas del grupo",
      },
    },
    saveOptions: {
      only_save: "Guardar solo",
      save_and_create_new: "Crear proveedor y agregar nueva",
    },
  },
  animal: {
    dashboard: {
      activeAnimals: "ANIMALES ACTIVOS",
      activeLots: "MUCHOS ACTIVOS",
      animalsByGender: {
        title: "Cantidad por sexo",
        female: "Hembra",
        male: "Macho",
      },
    },
    columns: {
      title: "Lista de animales",
      buttonCompare: "COMPARAR",
      handlingNumber: "Identificación",
      picket: "Piquete",
      lot: "Mucho",
      breed: "Raza",
      gender: "Género",
      birthdayMonths: "Anõs",
      weightRanking: "Ranking",
      currentWeight: "Peso Actual",
      gdp: "GMD",
      target: "Objetivo",
      status: "Estado",
      reproductionCategory: "Categoria Rep.",
      reproductionStatus: "Situação Rep.",
      reproductionState: "Estado Rep.",
    },
    details: {
      animalProductionPageLink: "Animales en Producción",
      animalPageDescription: "Detalles del Animal",
      emptyMessage: "Datos del animal no encontrados",
      bovexoId: "Id Bovexo",
      age: "Nacimiento",
      sisbov: "SISBOV",
      source: "Proveedor",
      castrated: "Castrado",
      tagId: "Pendiente",
      sex: "Sexo",
      male: "Macho",
      female: "Hembra",
      acquisition: "Adquisición",
      handlingNumber: "Identificación",
      weight: "Peso",
      current: "Actual",
      location: "Situado",
      picket: "Piquete",
      lot: "Mucho",
      breed: "Raza",
      weightGain: "Aumento de peso",
      accumulated: "Acumulado",
      lastWeight: "Último peso",
      forSlaughter: "para Masacre",
      expected: "Esperado",
      asymptoticWeight: "Peso asintótico",
      economicReturn: "Retorno económico",
      currentValue: "Valor actual",
      accumulatedCost: "Costo acumulado",
      margin: "Margen",
      animalFarmFunction: "Finalidade",
      animalReproductionCategory: "Categoria",
      appropriateCosts: "Custos Apropriados",
      femaleSituation: "Situação",
      femaleSituationTime: "Tempo de situação",
      coverageDate: "Data Cobertura",
      coverageType: "Tipo Cobertura",
      coverageSemenOrMaleIdentification: "Touro",
      weightCoverage: "Peso na Cobertura",
      tab: {
        production: "Producción",
        genetic: "Genética",
        geneology: "Genealogia",
        evaluation: "Evaluación",
        zoonosis: "Zoonosis",
        weighing: "Pesar",
        dailyWeights: "Pesos diarios",
        weightGains: "Aumento de peso",
      },
      history: {
        title: "Historia de peso",
        tooltipHistoryWeight: "",
        grid: {
          emptyMessage: "No hay historial de peso registrado",
          in: "Entrada",
          out: "Salida",
          manual: "Manual",
          date: "Fecha",
          hour: "Hora",
          inOut: "E/S",
          weight: "Peso",
          eventTitle: "Tipo",
          originEvent: {
            birthday: "Nasc",
            purchase: "Compra",
            weaning: "Desm",
            sale: "Venda",
            weighing: "",
          },
        },
      },
      totalWeight: {
        title: "PESO TOTAL",
        tooltip:
          "Este cuadro compara el último peso del animal con el promedio de animales similares en el mismo grupo genético, raza y mismo lote",
        graph: {
          currentWeight: "Peso Actual",
          lot: "Mucho",
          genetic: "Genética",
          breed: "Raza",
          weight: "Peso",
          type: "Especie",
          estimated: "Estimado",
        },
      },
      averageDailyGain: {
        title: "GANADO MEDIO DIARIO",
        tooltip:
          "Este cuadro compara el último aumento de peso diario del animal con el promedio de animales similares en el mismo grupo genético, raza y mismo lote",
        graph: {
          currentGain: "Ganancia Actual",
          lot: "Mucho",
          genetic: "Genética",
          breed: "Raza",
          weight: "Peso",
          type: "Especie",
          estimated: "Estimado",
        },
      },
      dailyWeightGain: {
        title: "EVOLUCIÓN DEL PESO Y DEL GDP",
        graph: {
          weight: "Peso",
          time: "Dias",
        },
      },
    },
    drawerTitleEdit: "Editar animal",
    drawerSubTitleEdit:
      "Completa los datos a continuación para editar un animal",
    drawerSubTitleEditDeathAnimal:
      "Animal muerto, todos los campos han sido desactivados",
    drawerAnimalDeathTitle: "Animal Death",
    animalDeathPopConfirmTitle:
      "Tras la confirmación, el animal se considera en estado de muerte permanente",
    drawerAnimalDiscardTitle: "Descarte do animal",
    edit: {
      form: {
        nickname: "Nombre",
        nicknamePlaceHolder: "Nombre",
        sisbov: "SISBOV",
        sisbovPlaceHolder: "SISBOV",
        tagId: "Pendiente",
        tagIdPlaceHolder: "Pendiente",
        sexo: "Sexo",
        male: "Hombre",
        female: "Hembra",
        adquisición: "Adquisición",
        handlingNumber: "Identificación",
        handlingNumberPlaceHolder: "Animal ID",
        breed: "Raza",
        breedPlaceHolder: "Seleccione una raza",
        cap: "¿Animal castrado?",
        birthday: "Fecha de nacimiento",
        birthdayWeight: "Peso de Nacimiento",
        firstInseminationWeight: "Primera inserción",
        weaningWeight: "Peso de Destete",
        weaningDate: "Fecha de Destete",
        asymptoticWeight: "Asymptotic",
        asymptoticWeightInfoTooltip:
          "Peso assintótico é o peso que, com base nas informações que o usuário/produtor possui, sobre o histórico do Animal ou Lote de Animais, (considerando a genética, manejo anterior, interação genótipo-ambiente, frame, entre outras variáveis), acredite ser o potencial limite de peso. Ou seja, acima deste peso, dificilmente este animal ganhará peso, em condições normais.",
        acquisitionDate: "Fecha de adquisición",
        purchaseCost: "Precio de Adquisición",
        acquisitionEstimatedWeight: "Adquisición de peso",
        carcassHarnessing: "Utilización de la Carcasa",
        animalFarmFunction: "Finalidade",
        animalReproductionCategory: "Categoria",
        sisbovIdentificationDate: "Data Identificação",
        sisbovInsertBNDDate: "Data Inclusão BND",
        sisbovSlaughterDate: "Data Liberação Abate",
        farmOwner: "Proprietário",
      },
      weights: "PESOS",
      receipt: "ADQUISICIÓN",
      sale: "VENTA",
    },
    discard: {
      motives: {
        Age: "Idade",
        ReproductiveProblems: "Problemas Reprodutivos",
        IllnessesOrPhysicalProblems: "Doenças ou Problemas Físicos",
        BodyCondition: "Condição corporal",
        NegativePregnancy: "Prenhez Negativa",
        MaternalAbility: "Habilidade Materna",
      },
    },
    carcassHarnessingInfoTooltip:
      "O aprov do animal deve ser informado caso tenha uma análise de carcaça por ultrassom. O aproveitamento do Lote e da Estratégia de dieta são expectativas. Quando ambas estão presentes, prevalecem, nesta ordem Animal, Dieta, Lote",
  },
  dietStrategy: {
    new: {
      title: "Creación de estrategias",
      message:
        "Seleccione el período de estrategia total y establezca las dietas y el tiempo de ejecución de cada uno",
      name: "Nombre de la estrategia",
      namePlaceHolder: "Nombre de estrategia de dieta",
      totalPeriod: "Periodo total",
      year: "Año",
      oneYear: "1 año",
      twoYears: "2 años",
      threeYears: "3 años",
      dietAndExecutionTime: "Dietas y tiempo de ejecución",
      dietPlaceHolder: "Seleccione una dieta",
      deleteDietPeriod: "Borrar dieta",
      totalTime: "Tiempo total",
      weeks: "Semana (s)",
      buttonAddDiet: "Agregar dieta",
      drawerTitle: "Resumen de estrategia",
      start: "Inicio",
      end: "Fin",
      notification: {
        success: {
          message: "¡Estrategia de dieta creada!",
          description: "La estrategia se ha agregado al sistema",
        },
        error: {
          dontHaveName: {
            message: "Error",
            description:
              "¡Debe ingresar un nombre para la estrategia de dieta!",
          },
          dontHaveDiet: {
            message: "Error",
            description:
              "Todos los períodos deben tener una dieta seleccionada",
          },
        },
      },
    },
    title: "Lista de estrategias de dieta",
    buttonNewDietStrategy: "Nueva estrategia",
    table: {
      status: "Estado",
      name: "Nombre",
      diet: "Dieta",
      totalTime: "Tiempo total",
    },
  },
  generalParameters: {
    weatherSeason: {
      title: "Estaciones climáticas",
      editMessage:
        "Defina las estaciones meteorológicas más adecuadas para la ubicación de la granja usando el cuadro al lado:",
      table: {
        columnDayMonth: "DÍAS / MES",
        columnNextSeason: "PRÓXIMA TEMPORADA",
        columnMonthSeason: "TEMPORADA DEL MES",
      },
    },
    farmDeathReason: {
      buttonNew: "Novo",
      table: {
        title: "Causa Mortis",
        columns: {
          name: "Nome",
          status: "Situação",
        },
      },
    },
    quotas: {
      buttonNew: "Novo",
      table: {
        title: "Cotas",
        columns: {
          name: "Nome",
          type: "Tipo",
          status: "Situação",
        },
      },
    },
    successParameterUpdated: "¡Los parámetros cambiaron con éxito!",
    errorParameterUpdated: "Vaya ... los parámetros no han cambiado",
  },
  cost: {
    tabs: {
      fixedCost: "Imobilizados",
      infrastructureCost: "Infraestrutura e mão de obra",
      operatingVariableCost: "Variáveis operacionais",
      zootechnicalVariableCost: "Zootécnicos",
      sanityCost: "Sanity",
    },
    form: {
      error:
        'É necessário informa os campos "Valor do Custo" e "Depreciação" nos custos marcados em vermelho',
      growth: "Cria",
      recreateFattenPasture: "R&E - Pasto",
      recreateFattenSemiConfinement: "R&E - Semi-Confinamento",
      recreateFattenConfinement: "R&E - Confinamento",
      area: "Área",
      potentialOccupation: "Ocupação Potencial",
      staticCapacity: "Capacidade Estática",
      staticCapacityTooltip:
        "Os valores deste campo são calculados, multiplicando-se a Área pela Ocupação Potencial.",
      fence: "Cercas",
      costValue: "Valor do Custo",
      depreciationValue: "Depreciação",
      total: "Total",
      amountAnimals: "Qtd. de Cabeças",
      totalDailyCostPerAnimal: "Custo diário por Animal",
      dietCost: "Dieta",
    },
    costType: {
      FixedCost: "Imobilizados",
      InfrastructureCost: "Infraestrutura e mão de obra",
      OperatingVariableCost: "Variáveis operacionais",
      ZootechnicalVariableCost: "Zootécnicos",
      sanityCost: "Sanity",
    },
    code: {
      1: "Construção sede e instal.",
      2: "Impostos (ITR, CAN)",
      3: "Seguros",
      4: "Formação de pastos",
      5: "Construção de cercas",
      6: "Construção de curral",
      7: "Construção de açudes",
      8: "Aquisição de máquinas e implementos",
      9: "Construção de galpões e instal.",
      10: "Mão de obra operacional",
      11: "Mão de obra técnica prop.",
      12: "Assitência Técnica 3RDS",
      13: "Energia",
      14: "Telefone / Internet",
      15: "ERP",
      16: "Contador",
      17: "Manutenção de Instal. e Galpões",
      18: "Manutenção de Máquinas e Equipamentos",
      19: "Combustível e Lubrificantes",
      20: "Manutenção de pastos",
      21: "Adubação",
      22: "Manutenção de Currais e Cercas",
      23: "Aluguel de Pasto",
      24: "Vacinas",
      25: "Vermífugos",
      26: "Medicamentos",
      27: "Identificação",
      28: "Custo com Tropa & Relacionados",
      29: "Outros custos Adm.",
    },
  },
  saleScenario: {
    error: {
      DietStrategyExpired:
        "Não foi possível processar o cenário de venda, pois o mesmo possui estratégias de dietas vencidas.",
      InternalError: "Houve um erro ao processar o cenário. Contate o suporte.",
    },
  },
  movementHistory: {
    title: "Histórico(s) de Movimentações",
    table: {
      columns: {
        originalLotsNames: "Lotes de Origem",
        destinationLotsNames: "Lotes de Destino",
        requestDate: "Data da Movimentação",
        fromDismemberLot: "Desmembramento?",
        fileName: "Nome do arquivo",
        animalHandlingNumber: "Animal",
        lotOriginName: "Lote de Origem",
        lotDestinationName: "Lote de Destino",
      },
    },
  },
  financial: {
    tabs: {
      costing: "Custeio",
      cashFlow: "Fluxo de Caixa",
      parameters: "Parâmetros",
      transactions: "Movimentações Financeiras",
      animalSell: "Venda de Produção",
      resultCenterAnalysis: "Análise CR/CC",
    },
    costType: {
      Growth: "Cria",
      RecreateFattenPasture: "Extensivo",
      RecreateFattenSemiConfinement: "Semi-Intensivo",
      RecreateFattenConfinement: "Intensivo",
      Agriculture: "Agricultura",
      Others: "Outros",
    },
    transactions: {
      title: "Movimentações",
      buttonCreateNewFinancialTransaction: "Novo lançamento",
      buttonExportTransactions: "Exportar movimentações",
      form: {
        title: "Novo lançamento",
        documentNumber: "Nº Doc. Fiscal",
        supplierId: "Fornecedor",
        billingDate: "Data do faturamento",
        expirationDate: "Data do vencimento",
        mainValueCurrency: "Selecione uma moeda",
        mainValue: "Valor Principal",
        fees: "Valor Juros/Multas",
        discount: "Descontos",
        value: "Valor da transação",
        payment: "Pagamento",
        actions: "Ação",
        actionsOptions: {
          launch: "Lançar",
          launchAndSettle: "Lançar e Baixar",
          settle: "Baixar",
        },
        settleDate: "Data da Baixa",
        inCash: "À vista",
        part: "Parcelado",
        financialNatureId: "Natureza",
        financialClassId: "Classe",
        costActivityType: "Atividade",
        description: "Descrição",
        resultCenter: "Centro de Resultado",
        resultCenterApportionmentDefault: "Padrão",
        resultCenterApportionmentCustom: "Redefinir",
        transactionType: "Tipo",
        clientId: "Cliente",
        financialProjectId: "Projeto Financeiro",
        bonus: "Bonificação",
        errors: {
          installmentsNotGeneratedError:
            'É necessário gerar as parcelas. Para isso clique na opção "Parcelado" presente no campo "Pagamento".',
        },
      },
      modalInstallments: {
        title: "Gerar parcelas",
        value: "Valor da transação",
        expirationDate: "Data do vencimento",
        installmentTotal: "Nº de Parcelas",
        buttonCalc: "Calcular",
      },
      table: {
        columns: {
          nature: "Natureza",
          class: "Classe",
          values: "Valores",
          origin: "Origem",
          billingDate: "Data faturamento",
          expirationDate: "Data vencimento",
          installment: "Parcela",
          documentNumber: "Nº Doc. Fiscal",
          value: "Valor",
          isPaidPartially: "Parc. Pago",
          isSettled: "Status",
          settleDate: "Data da Baixa",
          financialClassDepreciation: "Alocação",
          valueDepreciation: "Custo Alocação",
          supplierName: "Fornecedor/Cliente",
          costActivityType: "Atividade",
          r: "CR",
          financialProjectName: "Projetos",
          transactionType: "Tipo",
          entityName: "Fornecedor/Cliente",
        },
      },
      modalResultCenterApportionment: {
        title: "Rateio por Centro de Resultado",
      },
    },
    cashFlow: {
      title: "Fluxo de Caixa",
      buttonCreateBudget: "Novo orçamento",
      filters: {
        status: "Status",
        visualization: "Visualização",
        exibition: "Exibição",
        harvest: "Safra",
        costType: "Atividade",
        financialProjectId: "Projeto Financeiro",
      },
      table: {
        columns: {
          nature: "Natureza",
          class: "Classe",
          values: "Valores",
        },
      },
    },
    costing: {
      title: "Tabela de Custos",
      filters: {
        visualization: "Visualização",
        harvest: "Safra",
        costType: "Atividade",
        financialProjectId: "Projeto Financeiro",
      },
      table: {
        columns: {
          nature: "Natureza",
          class: "Classe",
          values: "Valores",
        },
      },
    },
    parameters: {
      tabs: {
        parameters: "Parâmetros",
        costing: "Custeio",
        resultCenter: "Centro de Resultado",
        costCenter: "Centro de Custo",
        financialProject: "Projetos Financeiros",
      },
      generalFinancialParameters: {
        title: "Parâmetros",
        parameter4001: "Utilizar conversão de moedas?",
        parameter4002: "Custos Nutricionais",
        parameter4002Help:
          "Defina se os custos nutricionais serão apropriados aos animais a partir da Estratégia de Dieta ou do Fornecimento",
        farmConvertions: {
          title: "Moedas",
          buttonNew: "Nova Moeda",
          table: {},
          form: {
            titleNew: "Nova Moeda",
            currency: "Moeda",
            standard: "Colocar como padrâo?",
          },
        },
      },
      costing: {
        title: "Tabela de Parâmetros Custeio",
        table: {
          columns: {
            nature: "Natureza",
            stock: "Controle de Estoque",
            class: "Classe",
            depreciation: "Depreciação (Meses)",
            shouldCost: "Custeio",
            shouldDisbursement: "Desembolso",
            product: "Produto",
            costCenter: "Centro de Custo",
            type: "Tipo",
          },
        },
      },
      resultCenter: {
        title: "Tabela de Centros de Resultados",
        buttonNew: "Novo",
        form: {
          title: "Centros de Resultado",
        },
        financialNatureApportionmentForm: {
          title: "Critérios de Rateio de Despesas/Custos Indiretos",
        },
      },
      costCenter: {
        title: "Tabela de Centros de Custos",
        buttonNew: "Novo",
        form: {
          title: "Centros de Custos",
        },
        apportionmentForm: {
          title: "Centros de Custo x Centros de Resultado",
        },
        modal: {
          title: "Centros de Custos",
          table: {
            columns: {
              financialClass: "Classe",
            },
          },
        },
      },
    },
    animalSell: {
      title: "Venda de Produção",
      buttonNew: "Nova venda",
      buttonExport: "Resultado de Vendas",
      form: {
        title: "Venda de Animais",
        animalSelectionDescription:
          "Selecione os animais na tabela para proceder com a venda.",
        sellDate: "Data de saída",
        client: "Cliente",
        priceNegotiated: "Valor negociado",
        weightPerAnimal: "Peso médio/animal",
        carcassHarnessing: "Ap. carcaça",
        documentNumber: "Nº Doc. Fiscal",
        supplier: "Fornecedor",
        billingDate: "Data do faturamento",
        expirationDate: "Data do vencimento",
        payment: "Pagamento",
        inCash: "À vista",
        part: "Parcelado",
        resultCenter: "Centro de Resultado",
        resultCenterApportionmentDefault: "Padrão",
        resultCenterApportionmentCustom: "Redefinir",
        totalValue: "Valor Total",
      },
      table: {
        columns: {
          origin: "Origem",
          documentNumber: "Nº Doc. Fiscal",
          client: "Cliente",
          sellDate: "Dt. Venda",
          amountAnimais: "Qtde Animais",
          gender: "Sexo",
          totalValue: "Valor Total",
          averageValue: "Valor/Cabeça",
          carcassHarnessing: "% Aprov. Carcaça",
          paymentType: "Pagamento",
          financialProjectName: "Projetos",
        },
      },
    },
    resultCenterAnalysis: {
      filters: {
        resultCenter: "Centro de Resultado",
        costCenter: "Centro de Custo",
        period: "Período",
        visualization: {
          label: "Visualização",
          costing: "Custeio",
          cashFlow: "Fluxo de Caixa",
        },
        buttonConfirm: "Processar",
      },
      income: {
        title: "Receitas",
        costCenterName: "Centro de Custo",
        total: "Valor Total",
        totalPercentage: "Total (%)",
      },
      expense: {
        title: "Despesas",
        costCenterName: "Centro de Custo",
        total: "Valor Total",
        totalPercentage: "Total (%)",
      },
      total: "Resultado",
    },
  },
  costType: {
    AllTypes: "Todos",
    Growth: "Cria",
    RecreateFattenPasture: "R&E - Pasto",
    RecreateFattenSemiConfinement: "R&E - Semi-Confinamento",
    RecreateFattenConfinement: "R&E - Confinamento",
    Agriculture: "Agricultura",
    Others: "Outros",
  },
  buttons: {
    back: "Volver",
    save: "Guardar",
    cancel: "Cancelar",
    confirm: "Confirmar",
    login: "Iniciar sesión",
    buttonNewProfitCenter: "Nueva actividad",
    buttonNewFarm: "Nueva granja",
    buttonNewSupplier: "Nuevo proveedor",
    yes: "Sí",
    no: "No",
    putToDeath: "Muerto",
    allocateLot: "Asignar lote",
    download: "Download",
    processWeights: "Pesaje de procesos",
    processAnimals: "Procesar animales",
    processSupplements: "Process supplements",
    edit: "Editar",
    editing: "Edición",
    addInfo: "Datos de informe",
    delete: "Eliminar",
    useAsFavorite: "Utilizar como favorito",
    generate: "Gerar",
    putToDiscard: "Descartar",
    process: "Processar",
  },
  topbar: {
    title: "Administración",
    group: {
      title: "Grupos",
      subtitle: "Seleccione un grupo",
      submenus: {
        farm: "Granjas",
        add_farm: "ANÃDIR GRANJA",
      },
    },
    farm: {
      title: "Seleccione una granja",
      submenus: {
        edit_farm: "Editar la granja",
        change_farm: "Cambiar la granja",
        add_new_group: "Añadir nuevo grupo",
        add_new_farm: "Añadir nueva granja",
      },
    },
    question: {
      title: "Dudas",
      submenus: {},
    },
    notification: {
      title: "Notificaciones",
      submenus: {},
      footer: "Ver todos",
    },
    add: {
      title: "Anãdir",
      submenus: {
        profit_center: "Actividad",
        picket: "Piquete",
        picketSupplementSupply: "Novo Fornecimento",
        user: "Usuario",
        balance: "Balanza",
        sell_and_buy: "Escenario de compra y venta",
        animal: "Animal",
        lotProduction: "Mucho de producción",
        buy: "Compra",
        birth: "Nacimiento",
        importAnimalsWeights: "Importar pesos",
        importAnimals: "Importar animales",
        importSupplements: "Importar suplementos",
        importFinancialTransactions: "Importar mov. financeiras",
        downloadBovManager: "Dowload BovManager",
      },
    },
    language: {
      title: "Traducción",
      submenus: {
        pt_br: "Portugués - BR",
        en: "Inglés - US",
        es: "Espanõl - ES",
      },
    },
    user: {
      title: "User",
      submenus: {
        edit_profile: "Editar usuario",
        change_user: "Cambiar usuario",
        change_password: "Cambiar contraseña",
        add_new_user: "Añadir usuario",
        signout_application: "Salir de la aplicación",
      },
    },
    search: {
      title: "Búsqueda",
      submenus: {
        user: "Usuario",
        profit_center: "Actividad",
        picket: "Piquete",
        balance: "Balanza",
        animal: "Animal",
        buy: "Compra",
        birth: "Nacimiento",
      },
    },
  },
  table: {
    menu: {
      edit: "Editar",
      details: "Detalles",
      delete: "Eliminar",
      inactivate: "Inactivar",
      activate: "Activar",
      addAnimalsToLotProduction: "Agregar animales",
      dismemberLotProduction: "Desmembrar el Lote",
      allocateLotToPicket: "Asignar lote a piquete",
      putPicketInMaintenance: "Mantener",
      putPicketInBreak: "Descanso",
      putPicketInRenovation: "Reforma",
      setBaseline: "Definir como Padron",
      movePickets: "Mov. Piquetes",
    },
  },
  error: {
    formError: "Complete los campos obligatorios (*) correctamente",
    noGroupSelected: "Seleccione un grupo del menú superior",
    sisbovInvalidTitle: "Sisbov no válido",
    sisbovInvalidMessage:
      "El número SISBOV debe contener al menos 15 caracteres",
  },
  notifications: {
    types: {
      newObject: "Nuevo objeto",
      purchase: "Compra",
      sale: "Venta",
      alert: "Alerta",
      warning: "Advertencia",
      other: "Otro",
    },
    drawer: {
      title: "Tus notificaciones",
      buttonVilualizeAll: "MARCAR TODO COMO LEÍDO",
      buttonLoadOldNotifications: "Subir viejo",
      notificationsNotRead: "notificaciones no leídas",
    },
  },
  sessionExpired: {
    title: "¡Tu sesión ha expirado!",
    message:
      "Para mantener segura su cuenta, verificamos periódicamente su presencia iniciando sesión nuevamente",
    titleAlmostExpired: "¡Tu sesión caducará pronto!",
    messageAlmostExpired:
      "Para mantener su cuenta segura, verificamos periódicamente su presencia, asegúrese de guardar lo que está haciendo antes de que expire su sesión",
    buttonLogout: "Cerrar sesión ahora",
    buttonConfirm: "Está bien, lo tengo",
    toExpire: "expirar...",
  },
  sidebar: {
    home: "Home",
    farm: "Granjas",
    profit_center: "Actividad",
    picket: "Piquetes",
    lot: "Muchos",
    animal: "Animales",
    decision: "Decisiones",
    parameters: "Parámetros",
    imports: "Importaciones",
    administration: "Administracion",
    suppliers: "Proveedores",
    operations: "Operaciones",
    breed: "Razas",
    handling: "Gestiones",
    diet: "Dietas",
    pasture: "Pastos",
    supplement: "Suplementos/Concent.",
    adminAndUser: "Empleados agrícolas",
    userInvite: "Accede a invitaciones",
    client: "Clientes",
    payment: "Pagos",
    financial: "Movimentações Financeiras",
  },
  languages: {
    pt_br: "PT",
    en: "IN",
    es: "ES",
  },
  status: {
    active: "Activo",
    inactive: "Inactivo",
    dismembered: "Desmembrado",
    pendingData: "Datos pendientes",
    informedData: "Datos informados",
  },
  productionSubSystem: {
    semiIntensive: "Semi-Intensivo",
    intensive: "Intensivo",
    extensive: "Extensivo",
    SI: "Semi-Intensivo",
    I: "Intensivo",
    E: "Extensivo",
  },
  productionSystem: {
    fatten: "Engorda",
    create: "Cria",
    recreate: "Recria",
  },
  kpi: {
    capacity: "Lotação",
    production: "Produção",
    cost: "Custo",
    margin: "Margem",
    gdp: "GPD",
    enjoy: "Desfrute",
    carbonFootprint: "Pegada Carbônica",
    disbursement: "Desembolso Cab/Mês",
    weanCalf: "Kg Bez. Desm / Kg Vaca",
    iep: "IEP",
    reproductiveEfficiency: "Eficiência Reprodutiva",
    weanCalfCost: "Custo Bezerro Desmamado",
    discardedCows: "Vacas Descartadas",
    assetValue: "Valor do Ativo",
    incrementalMargin: "Margem Incremental",
    tooltips: {
      tooltipCapacity:
        "A Lotação é a relação (em %) da quantidade atual de cabeças alocadas e a capacidade total, conforme definido no cadastro das baias de confinamento.",
      tooltipEnjoy:
        "A taxa de desfrute mede a capacidade do rebanho em gerar excedente (@) em um determinado espaço de tempo.",
      tooltipProduction: "Quantidade de @ produzida por hectare/ano.",
      tooltipGDP: "Ganho de Peso Diário, desde a entrada no animal da Fazenda.",
      tooltipMargin: "Margem de lucro esperada por @ produzida.",
      tooltipCost: "Custo de Produção da @.",
      tooltipCarbonFootprint:
        "Meta de emissão máxima em Kg de CO₂E por Kg de Ganho de Peso.",
      tooltipDisbursement:
        "Desembolso Cabeça/Mês dos animais atualmente em cada subsistema de produção.",
      tooltipAssetValue: "Valor ativo.",
      tooltipIncrementalMargin: "Margem incremental.",
      tooltipWeanCalf:
        "Indicador da proporção do peso dos animais na desmama, em relação às fêmeas de reprodução aptas a reprodução. São consideradas, como aptas, as fêmeas de finalidade “Reprodução” e que atendam os parâmetros reprodutivos.",
      tooltipIEP:
        "Intervalo entre partos das fêmeas de reprodução, que tenham parido ao menos 2 vezes (Secundíparas)",
      tooltipReproductiveEfficiency:
        "Eficiência Reprodutiva final das fêmeas expostas, considerando a Taxa de Prenhez (TP), Taxa de nascimento (TN) e Taxa de Desmama (TD).",
      tooltipWeanCalfCost:
        "Custo do bezerro desmamado, incluindo todos os custos incorridos pela vaca e pelo bezerro.",
      tooltipDiscardedCows: "% de vacas descartadas no período.",
    },
  },
  zipCodeValid: "Código postal encontrado",
  zipCodeInvalid: "Código postal no encontrado",
  defaultSelectPlaceholder: "Seleccione una opción",
  defaultMultiSelectPlaceholder: "Selecione uma ou mais opções",
  defaultDatePickerPlaceholder: "Seleccione una fecha",
  defaultPlaceholder: "Ingrese un valor",
  filters: {
    applyFilter: "APLICAR FILTRO",
    cleanFilter: "Filtro limpio",
    filterBy: "Filtrar por",
    filterByOptions: {
      age: "Edad",
      gender: "Sexo",
      breed: "Raza",
      supplier: "Proveedores",
      farmOwner: "Proprietários",
    },
    compareBy: "Comparar por",
    value: "Valor(es)",
    male: "Hombre",
    female: "Hembra",
    min: "Mínimo",
    max: "Máximo",
    equal: "Igual",
    greaterThan: "Mayor que",
    lessThan: "Menos de",
    between: "Entre",
  },
  groupAndFarmModal: {
    hello: "Hola",
    groupSelectedLabel: "Seleccione un grupo de granjas",
    groupSelectedPlaceHolder: "Seleccione un grupo",
    farmSelectedLabel: "Seleccione una granja",
    farmSelectedPlaceHolder: "Seleccione una granja",
    buttonAccessFarm: "Granja de acceso",
  },
  importAnimalWeights: {
    title: "Historial de pesaje de importación",
    message:
      '<p class = "message"> Descargue <strong> la plantilla a continuación, complete la hoja de cálculo </strong> con las ponderaciones para este lote, <strong> importar </strong> y luego haga clic en procesar ponderaciones </p> ',
    messageForDifferentsLots:
      '<p class="message">Faça <strong>download do template abaixo, complete a planilha</strong> com os animais e seus respectivos lotes, <strong>faça a importação</strong> em seguida e clique em processar animais</p>',
    draggerMessage: "Haga clic o arrastre el archivo a esta área para cargar",
  },
  importAnimals: {
    title: "Importar animales en el lote de producción",
    message:
      '<p class = "message"> Seleccione un lote de producción, descargue la <strong> plantilla a continuación, complete la hoja de trabajo </strong> con los animales que componen el lote, <strong> importar </strong> en luego haga clic en procesar animales </p> ',
    messageForDifferentsLots:
      '<p class="message">Faça <strong>download do template abaixo, complete a planilha</strong> com os animais e seus respectivos lotes, <strong>faça a importação</strong> em seguida e clique em processar animais</p>',
    messageForIdentifyAnimals:
      '<p class="message">Faça <strong>download do template abaixo, complete a planilha</strong> com os animais e seus respectivos lotes e códigos de lote de recebimento, <strong>faça a importação</strong> em seguida e clique em processar animais</p>',
    draggerMessage: "Haga clic o arrastre el archivo a esta área para cargar",
    helpMessage:
      'Importar animales por lote y proveedor: Informe al lote y al proveedor de cada animal en la hoja de cálculo. De esta forma, es posible importar animales de diferentes proveedores y para más de un Lote de Producción. El Proveedor y el Lote de Producción deben estar registrados con anticipación, y el campo "Identificación de Lote" para el Lote de Producción debe ser utilizado en la hoja de cálculo, mientras que para el Proveedor, informar al "CPF / CNPJ" de su registro.\nImportar animales para un solo lote y proveedor: seleccione un lote de producción y un proveedor ya registrado. Si el animal a importar es de la propia granja, seleccione la opción "Animal (s) proporcionado por la propia granja".',
  },
  error404: {
    title: "ERROR 404",
    subTitle: "Página no encontrada",
    button: "Ir a Home",
  },
  months: {
    january: "enero",
    jan: "ene",
    february: "febrero",
    feb: "feb",
    march: "marzo",
    mar: "Mar",
    april: "Abril",
    apr: "Abr",
    may: "mayo",
    mmay: "may",
    june: "junio",
    jun: "Jun",
    july: "julio",
    jul: "Jul",
    august: "Agosto",
    aug: "Ago",
    september: "septiembre",
    sep: "Sep",
    october: "octubre",
    oct: "Oct",
    november: "noviembre",
    nov: "Nov",
    december: "diciembre",
    dec: "Dic",
  },
  notInformed: "No informado",

  Profile: {
    drawerTitle: "Datos Personales",
    form: {
      fullName: "Nombre Completo",
    },
  },
  scenarePage: {
    title: "Cenários Boitel",
    table: {
      column: {
        clientID: "Client ID",
        name: "Nome Cenário",
        createdAt: "Data Criação",
        updatedAt: "Data Ult. Atualiz.",
        status: "Status",
        labelStatus: {
          hired: "Contratado",
          saved: "Salvo",
        },
      },
      detailsRow: {
        rangeWeight: "Faixa Peso",
        amountAnimals: "Qtde Animais",
        entryDate: "Data Entrada",
        exitDay: "Data Saída",
        daysOfStay: "Qtd Diárias",
        dailyPrice: "Preço Dia",
        dietStrategy: "Dieta",
        carcassHarnessing: "Aprov. Carcaça - %",
        totalIncome: "Receita Total",
        incomeForCab: "Receita/Cab",
        marginForCab: "Lucro/Cab",
      },
    },
  },
  boitelSaleScenario: {
    title: "Cenários venda",
    result: "Resultado(s)",
    btn: {
      newScenare: "Novo Cenário",
    },
    table: {
      column: {
        scenare: "Cenário",
        client: "Cliente",
        favorite: "Favorito",
        status: "Status",
        actions: "Ações",
        labelStatus: {
          analyzing: "Avaliando",
          processed: "Processado",
          canceled: "Cancelado",
          partialCanceled: "Cancelado Parc.",
          sold: "Vendido",
          partialSold: "Vendido Parc.",
        },
      },
      detailsRow: {
        identification: "Identificação",
        saleAnimals: "Animais à vender",
        receive: "A receber",
        margin: "Margem",
        processedAt: "Processado às",
      },
    },
    new: {
      title: "Criação: Boitel Venda",
      tabs: {
        lots: {
          title: "Seleção de lotes",
        },
        dietStrategy: {
          title: "Seleção de estratégia de dieta",
        },
        parameters: {
          title: "Definição dos parâmetros",
          form: {
            name: "Nome do cenário*",
            animalsInTruck: "Animais no caminhão*",
            minimumAge: "Idade minima para venda*",
            maximumAge: "Idade máxima para venda*",
            minimumWeight: "Peso minimo para venda*",
            maximumWeight: "Peso máximo para venda*",
            useDefaultMaximumWeight: "Peso máximo para venda*",
            allowSeparateSell: "Lotes podem sofrer descasca?",
            goalType: "Objetivo do cenário*",
            commitments: "O cenário possui algum compromisso?",
            newCommitment: "Adicionar novo compromisso",
            marketAnalysisId: "Análise de mercado*",
            useCustomCarcassHarnessing: "Informar novo % de Aprov. de Carcaça?",
            useCustomCarcassHarnessingHelp:
              "Por padrão, o sistema utiliza o % de Aproveitamento de Carcaça informado no processo de contratação dos animais.",
            carcassHarnessing: "Aprov. de Carcaça",
          },
        },
      },
    },
    edit: {
      title: "Edição: Boitel Venda",
    },
    details: {
      messageSelectDates:
        "Selecione uma(s) data(s) sugeridas no calendário para visualizar as informações dos animais.",
      calendarSuggested: "Calendário sugerido",
      weekDateNull: "Animais com violação Boitel",
      animalsToSell: "Animais a vender",
      planningIncome: "Receita planejada",
      dietStrategiesSuggested: "Estrat. de dieta sugerida",
      commitments: "Compromissos",
      violations: "Violações",
      parameters: "Parâmetros do cenário",
      marketValues: "Referência de valores",
      violationAge: "Idade",
      violationWeight: "Peso",
      violationBoitel: "Boitel",
      table: {
        columns: {
          animalHandlingNumber: "Identificação",
          sellDate: "Data sugerida",
          weight: "Peso estim.",
          lastDailyWeight: "Último peso",
          averageWeightGain: "GMD",
          aggregateArroba: "@Liq. agregadas",
          saleIncome: "Receita",
          aggregateIncome: "Receita Agregada",
          cost: "Custo",
          aggregateMargin: "Lucro agregado",
          margin: "Lucro",
        },
      },
    },
  },
  importSupplements: {
    title: "Importar suplementos",
    message:
      '<p class="message">Faça <strong>download do template abaixo, complete a planilha</strong> com os suplementos, <strong>faça a importação</strong> em seguida e clique em processar suplementos</p>',
    draggerMessage:
      "Clique ou arraste o arquivo para esta área para fazer o upload",
  },
  eventLog: {
    fromValueLabel: "Modificado de",
    toValueLabel: "para",
    dateLabel: "Data",
    attributeLabel: "Propriedade",
    userLabel: "Usuário",
    nullLabel: "vazio",
    Supplement: {
      createdAt: "Criação do registro: ",
      name: "Nome",
      type: "Tipo",
      classification: "Classificação",
      industrialName: "Nome Industrial",
      status: "Situação",
      cost: "Custo",
      cp: "PB",
      tdn: "NDT",
      npn: "NNP",
      dryMatterPercentage: "MS",
      nitrogen: "Nitrogênio",
      calcium: "Cálcio",
      phosphorus: "Fósforo",
      zinc: "Zinco",
      sodium: "Sódio",
      minimumVoluntaryConsumption: "Consumo Vonluntário (Mínimo)",
      maximumVoluntaryConsumption: "Consumo Vonluntário (Máximo)",
      voluntaryConsumptionType: "Unidade",
      minimumPure: "Oferta Pura (Mínimo)",
      maximumPure: "Oferta Pura (Máximo)",
      minimumPureTrough: "Cocho Recomendado (Mínimo)",
      maximumPureTrough: "Cocho Recomendado (Máximo)",
      pureConsumptionType: "Unidade",
      minimumMix: "Oferta Mistura (Mínimo)",
      maximumMix: "Oferta Mistura (Máximo)",
      minimumMixTrough: "Cocho Recomendado (Mínimo)",
      maximumMixTrough: "Cocho Recomendado (Máximo)",
      mixConsumptionType: "Unidade",
      currentVersion: "Versão",
      proportion: "Proporção",
      additiveEffect: "Efeito Aditivo",
      additiveEffectUnit: "Unidade Efeito Aditivo",
      fda: "FDA",
      lignin: "Lignina",
      ee: "Extrato Etéreo",
    },
  },
  reproductionParameters: {
    title: "Parâmetros de Reprodução",
    parameters: {
      IDADE_INICIO_FASE_REPRODUTIVA: "Idade para início da fase reprodutiva*",
      PERIODO_DESCANSO_POS_PARTO: "Período de descanso pós parto*",
      TEMPO_APOS_COBERTURA_PARA_DG:
        "Tempo pós cobertura para Diagnóstico de Gestação*",
      TOURO_COBERTURAS_DESCONHECIDAS: "Touro de Coberturas Desconhecidas*",
      IDADE_DESMAMA: "Idade para Desmama*",
    },
    reproductionPeriod: {
      title: "Estações de Monta",
      titleHelper:
        "A Estação de Monta é o período reprodutivo da propriedade, sendo iniciado no D0 do protocolo do primeiro Lote de IATF e finalizando no Diagnostico de Gestação Final.",
    },
    score: {
      title: "Score de Probabilidade de Sucesso Reprodutivo",
      titleHelper:
        "Defina, com base na escala de 1 a 9 de score, a probabilidade em % de sucesso reprodutivo. Este score, será aplicado na tabela para geração da Curva de Idade X Peso X Score.",
    },
    breedScore: {
      title: "Curva de Idade X Peso X Score",
      titleHelper:
        "Defina para as Fêmeas de Reprodução, considerando a Idade Reprodutiva, Peso Atual e Score de Probabilidade de Sucesso Reprodução, as variáveis necessárias para cálculo do custo para, até a próxima Estação de Reprodutiva, atingir cada score.",
      form: {
        title: "Nova entrada (Raça X Idade X Peso X Score)",
        breedId: "Raça*",
        age: "Idade*",
        startWeight: "Peso inicial*",
        endWeight: "Peso final*",
        weight: "Peso",
        score: "Score*",
        errors: {
          errorWithFieldWeights:
            'Existem alguns pesos sem "Score". Preencha-los para salvar o formulário.',
        },
      },
    },
  },
  semen: {
    title: "Listagem de Sêmens",
    buttonNewSemen: "Novo Sêmen",
    table: {
      columns: {
        name: "Nome",
        registration: "Registro",
        breedName: "Raça",
      },
    },
    form: {
      titleCreateNewSemen: "Novo Sêmen",
      titleEditSemen: "Editar Sêmen",
      name: "Nome",
      registration: "Registro",
      breedId: "Raça",
    },
  },
  movimentAnimals: {
    title: "Movimentação entre lotes",
    description:
      'Selecione os animais na tabela e clique no botão "Movimentar" para realocar os animais',
    buttonMovementAnimals: "Movimentar",
    form: {
      title: "Nova movimentação - Lote de Destino",
    },
  },
  closedSalesReport: {
    form: {
      title: "Resultado de Vendas",
      salesPeriod: "Período das Vendas",
      clientId: "Cliente",
      supplierId: "Fornecedor",
      saleScenarioId: "Cenário de Venda(s)",
    },
  },
  exportFinancialTransactions: {
    modal: {
      title: "Exportar movimentações financeiras",
      billingPeriod: "Faturamento",
      expirationPeriod: "Vencimento",
      suppliers: "Fornecedores",
      costActivities: "Atividades",
      clients: "Cliente(s)",
      transactionType: "Tipo",
      financialProjectId: "Projeto Financeiro",
      status: "Status",
    },
  },
  unit: {
    title: "Listagem de Unidades",
    buttonNew: "Nova Unidade",
    table: {
      columns: {
        name: "Abreviatura",
        description: "Descrição",
        basicUnit: "Unidade Básica",
        multiplier: "Multiplicador",
        status: "Status",
        createdAt: "Data Criação",
        createdBy: "Responsável Criação",
      },
    },
    form: {
      titleCreateNew: "Nova Unidade",
      titleEdit: "Editar Unidade",
      groupFarmVisibility: "Quem irá utilizar a Unidade?",
      name: "Abreviatura",
      description: "Descrição",
      basicUnit: "Unidade Básica",
      multiplier: "Multiplicador",
      status: "Status",
    },
  },
  veterinary: {
    title: "Listagem de Insumos Veterinários",
    buttonNew: "Novo insumo veterinário",
    table: {
      columns: {
        name: "Nome Comercial",
        type: "Tipo",
        supplierId: "Fornecedor",
        supplierName: "Fornecedor",
        status: "Status",
        createdAt: "Data Criação",
        createdBy: "Responsável Criação",
      },
    },
    form: {
      titleCreateNew: "Novo Insumo Veterinário",
      titleEdit: "Editar Insumo Veterinário",
      groupFarmVisibility: "Quem irá utilizar o Insumo Veterinário?",
      name: "Nome Comercial",
      type: "Tipo",
      supplierId: "Fornecedor",
      applicationGender: "Sexo",
      applicationAge: "Idade",
      applicationType: "Forma de Aplicação",
      applicationValue: "Qtd. de Aplicação",
      applicationWeightValueParam: "Peso",
      unitId: "Unidade de uso",
      applicationCost: "Custo Aplicação",
      status: "Status",
    },
  },
  handlingReproduction: {
    title: "Manejos",
    tabs: {
      reproductiveProtocol: "Protocolos",
      coverage: "Cobertura",
      pregnancyDiagnosis: "Diagnóstico de Gestação",
      animalBirth: "Partos e Perdas",
    },
  },
  handlingReproductionCoverage: {
    title: "Listagem de Coberturas",
    buttonHandlingReproductionCoverage: "Nova Cobertura",
    type: {
      iatf: "IA/IATF",
      repasse: "MC/REPASSE",
    },
    table: {
      columns: {
        animalHandlingNumber: "Fêmea",
        coverageDate: "Data",
        type: "Tipo",
        maleHandlingNumber: "Reprodutor",
        semenNameAndRegistration: "Sêmen",
        semenOrMaleIdentification: "Reprodutor/Sêmen",
        lotName: "Lote",
        dose: "Dose",
        reproductionPeriodDescription: "Estação de Monta",
        inseminatorName: "Inseminador",
        pregnancyDiagnosisResult: "Resultado",
      },
    },
    form: {
      type: "Tipo",
      animalId: "Identificação",
      category: "Categoria",
      semenId: "Sêmen",
      maleId: "Reprodutor",
      dose: "Dose",
      inseminatorId: "Inseminador",
      coverageDate: "Data da Cobertura",
      reproductionPeriodId: "Estação de Monta",
    },
    errorsAndMessages: {
      successMessage: "Cobertura salva/atualizada com sucesso!",
      femaleWithoutReproductionAge:
        "Fêmea não possui idade para início da fase reprodutiva, deseja continuar?",
      femaleDontHaveAgeToCoverage:
        "Atenção! Fêmea não tem idade suficiente para esta cobertura.",
      reproductionPeriodWithoutCurrentDate:
        "Atenção! Necessário ter uma Estação de Monta cadastrada no período da cobertura.",
      errorFemaleWithFutureCoverages: `Atenção! Fêmea possui coberturas em datas posteriores.`,
      errorFemaleWithCoverageInSameDateAndType:
        "Atenção! Cobertura já registrada anteriormente.",
      errorInternalError:
        "Erro ao fazer o cadastro/atualizar cobertura. Contate o suporte.",
    },
  },
  inseminator: {
    title: "Listagem de Inseminadores",
    buttonNewInseminator: "Novo Inseminador",
    table: {
      columns: {
        name: "Nome",
        code: "Código",
        status: "Status",
      },
    },
    form: {
      titleCreateNewInseminator: "Novo Inseminador",
      titleEditInseminator: "Editar Inseminador",
      name: "Nome",
      code: "Código",
    },
  },
  multipleBull: {
    title: "Touros Múltiplos",
    buttonNewMultipleBull: "Novo Touro Múltiplo",
    table: {
      columns: {
        name: "Nome",
        code: "Código",
        males: "Reprodutores",
        formationDate: "Data de Formação",
        status: "Status",
      },
    },
    form: {
      titleCreateNewMultipleBull: "Novo Touro Múltiplo",
      titleEditMultipleBull: "Editar Touro Múltiplo",
      name: "Nome",
      code: "Código",
      males: "Reprodutores",
      formationDate: "Data de Formação",
      status: "Status",
    },
  },
  ovarianResult: {
    SemAvaliacao: "Sem Avaliação",
    CorpoLuteo: "Corpo Lúteo",
    Foliculo: "Folículo",
    Cisto: "Cisto",
    Anestro: "Anestro",
    Normal: "Normal",
    Reabsorvendo: "Reabsorvendo",
    Gemelar: "Gemelar",
  },
  animalPregnancyDiagnosis: {
    title: "Diagnóstico de Gestação",
    buttonNew: "Novo Diagnóstico de Gestação",
    table: {
      columns: {
        animalHandlingNumber: "Fêmea",
        lotOriginalName: "Lote",
        diagnosisDate: "Data do DG",
        result: "Resultado",
        ovarianEvaluationResult: "Avaliação Ovariana",
      },
    },
    form: {
      titleCreateNew: "Novo Diagnóstico de Gestação",
      titleEdit: "Editar Diagnóstico de Gestação",
      diagnosisDate: "Data do Diagnóstico",
      animalId: "Identificação",
      animalReproductionCategory: "Categoria",
      femaleSituation: "Situação",
      lastCoverage: "Última cobertura",
      lotName: "Lote Atual",
      result: "Resultado",
      ovarianEvaluationResult: "Avaliação Ovariana",
      lastCoverageReproductionPeriodId: "Estação de Monta",
      lotDestination: "Lote Destino",
      timeGestationMC: "Tempo de Gestação MC",
      gestationDays: "Tempo de Gestação",
      birthForecast: "Previsão de Parto",
      shouldDiscardFemale: "Descartar Fêmea?",
      discardMotive: "Causa",
    },
  },
  animalWeightHistory: {
    title: "Lista de Pesagens",
    buttonExport: "Análise de Pesagens",
    buttonNew: "Nova Pesagem",
    table: {
      columns: {
        origination: "Origem",
        handlingNumber: "Animal",
        date: "Data",
        weight: "Peso",
        originationLotName: "Lote",
        originationPicketName: "Piquete",
        event: "Tipo",
      },
    },
    originEvents: {
      birthday: "Nascimento",
      sale: "Venda",
      weaning: "Desmama",
      purchase: "Compra",
    },
    form: {
      titleCreateNew: "Adicionar Pesagem",
      titleEdit: "Editar Pesagem",
      animalId: "Identificação Animal",
      date: "Data da Pesagem",
      weight: "Peso",
      gmd: "GMD",
      lotOriginalId: "Lote Atual",
      lotDestinationId: "Lote Destino",
      lastDateWeight: "Data da Última Pesagem",
      lastWeight: "Última Pesagem",
      weaningWeight: "Desmama?",
      editIdentifications: "Editar Identificações?",
    },
    modalExportReport: {
      title: "Análise de Pesagens",
      periodDate: "Período das Pesagens",
      gender: "Sexo",
      status: "Status dos Animais",
      periodAge: "Idade(meses)",
      lotId: "Lote",
      supplierId: "Fornecedor",
      listAnimalsWithoutWeight: "Listar animais sem pesagem no período?",
      showProjectedWeight:
        "Apresentar o peso projetado pela Estratégia de Dieta na Data atual?",
    },
  },
  dailyLiveCattlesDataAgro: {
    codes: {
      D_PEPR_GO_BR: "@ GO",
      D_PEPR_MG_BR: "@ MG",
      D_PEPR_MT_BR: "@ MT",
      D_PEPR_MS_BR: "@ MS",
      D_PEPR_SP_BR: "@ SP",
      D_PEPR_RO_BR: "@ RO",
      D_PEPR_TO_BR: "@ TO",
      D_PEPR_PA_BR: "@ PA",
      D_PEPR_BA_BR: "@ BA",
    },
  },
  animalBirth: {
    title: "Listagem de Partos e Perdas Reprodutivas",
    buttonNew: "Novo Parto/Perda",
    table: {
      columns: {
        matrizAnimalHandlingNumber: "Matriz",
        birthDate: "Data do parto",
        type: "Tipo",
        coverageDate: "Dt. Cobertura",
        coverageType: "Tipo Cobertura",
        reproductor: "Reprodutor",
        childs: {
          handlingNumber: "Identificação",
          gender: "Sexo",
          birthdayWeight: "Peso Nascimento",
          situation: "Situação",
        },
      },
    },
    types: {
      parto: "Parto",
      aborto: "Aborto",
      morteEmbrionaria: "Morte Embrionária",
    },
    form: {
      titleCreateNew: "Novo Parto/Perda",
      titleEdit: "Editar Parto/Perda",
      matrizAnimalId: "Identificação",
      type: "Tipo",
      birthDate: "Data",
      animalReproductionCategory: "Categoria",
      femaleSituation: "Situação",
      gestationDays: "Tempo de Gestação",
      coverageInfoType: "Tipo",
      coverageInfoDate: "Data",
      coverageInfoGestationTime: "Tempo de Gestação",
      coverageInfoBirthForecast: "Prev. Parto",
      coverageInfoReprodutor: "Reprodutor",
      coverageInfoDGDate: "Data do DG",
      coverageInfoDGResult: "Resultado do DG",
      shouldDiscardFemale: "Descartar Fêmea?",
      discardMotive: "Causa",
      lotMatrizDestinationId: "Lote de Produção",
      observation: "Observações",
      child: {
        nickname: "Nome",
        nicknamePlaceHolder: "Nome",
        sisbov: "SISBOV",
        sisbovPlaceHolder: "SISBOV",
        tagId: "Id. Eletrônica",
        tagIdPlaceHolder: "Id. Eletrônica",
        sex: "Sexo",
        male: "Macho",
        female: "Fêmea",
        acquisition: "Aquisição",
        handlingNumber: "Identificação",
        handlingNumberPlaceHolder: "N. de identificação do animal",
        breed: "Raça",
        breedPlaceHolder: "Selecione uma raça",
        cap: "Animal castrado?",
        birthday: "Data de nascimento",
        birthdayWeight: "Peso Nascimento",
        firstInseminationWeight: "Primeira inserminação",
        weaningWeight: "Peso Desmame",
        weaningDate: "Data Desmame",
        asymptoticWeight: "Peso Assintótico",
        asymptoticWeightInfoTooltip:
          "Peso assintótico é o peso que, com base nas informações que o usuário/produtor possui, sobre o histórico do Animal ou Lote de Animais, (considerando a genética, manejo anterior, interação genótipo-ambiente, frame, entre outras variáveis), acredite ser o potencial limite de peso. Ou seja, acima deste peso, dificilmente este animal ganhará peso, em condições normais.",
        acquisitionDate: "Data de aquisição",
        acquisitionEstimatedWeight: "Peso aquisição",
        carcassHarnessing: "Aproveitamento da Carcaça",
        animalFarmFunction: "Finalidade",
        animalReproductionCategory: "Categoria",
        sisbovIdentificationDate: "Data Identificação",
        sisbovInsertBNDDate: "Data Inclusão BND",
        sisbovSlaughterDate: "Data Liberação Abate",
        lotId: "Lote de Produção",
        farmOwner: "Proprietário",
        purchaseCost: "Valor de Entrada",
        stillborn: "Natimorto?",
      },
    },
  },
  animalReproductionCategory: {
    nulipara: "Nulípara", // Nulíparas, são fêmeas de reprodução, desmamadas, que não foram trabalhadas (inseminadas ou colocadas à disposição do touro).
    novilha: "Novilha", // Novilhas, são fêmeas de reprodução, desmamadas, que foram trabalhadas, mas inda não pariram.
    primipara: "Primípara", // Primíparas, são fêmeas de reprodução, que tiverem 1 parto.
    secundipara: "Secundípara", // Secundíparas, são fêmeas de reprodução, que tiverem 2 partos.
    multipara: "Multípara", // Multíparas, são fêmeas de reprodução, que tiveram 3 ou mais partos.
    // Macho
    reprodutor: "Reprodutor", // Reprodutor: Macho destinado para uso na reprodução.
  },
  femaleSituation: {
    vazia: "Vazia",
    prenha: "Prenha",
    servida: "Servida",
  },
  animalReproductionState: {
    solteira: "Solteira",
    parida: "Parida",
  },
  animalFarmFunction: {
    reproduction: "Reprodução",
    fatten: "Engorda",
  },
  birthTypes: {
    parto: "Parto",
    aborto: "Aborto",
    morteEmbrionaria: "Morte Embrionária",
  },
  animalStatus: {
    A: "Ativo",
    Active: "Ativo",
    S: "Vendido",
    Sold: "Vendido",
    D: "Morto",
    Dead: "Morto",
    I: "Inativo",
    Inactive: "Inativo",
  },
  animalMovementEvents: {
    weight: "Pesagem",
    vacination: "Vacinação",
    coverage: "Cobertura",
    pregnancyDiagnosis: "Diagnóstico de Gestação",
    birth: "Parto",
    discard: "Descarte",
    lotEntry: "Entrada no Lote",
  },
  financialClass: {
    buttonNew: "Nova Classe",
    form: {
      titleCreateNew: "Nova Classe",
      titleEdit: "Editar Classe",
      financialNatureId: "Natureza",
      name: "Nome",
      shouldCost: "Considerado para Custeio?",
      shouldDisbursement: "Considerado para Desembolso?",
      depreciation: "Depreciação",
      costCenterName: "Centro de Custo",
    },
  },
  costCenterDefaultNames: {
    PRODUCAO_ANIMAL: "Produção Animal",
    UTILIDADES: "Utilidades",
    INFRAEST_PRODUTIVA: "Infraest. Produtiva",
    INFRAEST_OPERACIONAL: "Infraest. Operacional",
    INFRAEST_APOIO: "Infraest. Apoio",
    TAXA_IMPOSTOS: "Taxas & Impostos",
    SERVICOS: "Serviços",
    INVSUMOS_OPERACIONAIS: "Insumos Operacionais",
    PRODUCAO_PASTO_AGRICOLA: "Produção Pasto/Agrícola",
  },
  financialProject: {
    title: "Projetos Financeiros",
    buttonNew: "Novo Projeto",
    table: {
      columns: {
        name: "Nome Projeto",
        description: "Observações",
        startProjectDate: "Data Início",
        finishProjectDate: "Término",
        status: "Status",
      },
    },
    form: {
      titleCreateNew: "Novo Projeto Financeiro",
      titleEdit: "Editar Projeto Financeiro",
      name: "Nome Projeto",
      description: "Observações",
      startProjectDate: "Data Início",
      finishProjectDate: "Término",
      status: "Ativo?",
    },
  },
  animalGender: {
    Female: "Fêmea",
    Male: "Macho",
  },
  lotItemEntry: {
    form: {
      entryDate: "Data de Entrada",
      amountAnimals: "Quantidade",
      averageWeight: "Peso Médio",
      ageInDays: "Idade (dias/meses/anos)",
      prefixIdentification: "Prefixo para Identificação",
      lotDestinationId: "Lote Destino",
    },
  },
  picketSupplementSupply: {
    title: "Fornecimento",
    buttonNew: "Novo Fornecimento",
    table: {
      columns: {
        supplyDate: "Data",
        picketsCount: "Piquetes/Baias",
        totalSupplementSupplied: "Total Ofertado(Kg)",
        responsibleName: "Responsáveis",
        subTable: {
          retreatName: "Retiro/Linha",
          picketName: "Piquetes/Baias",
          totalAnimals: "Qtde. Animais",
          supplementName: "Suplemento/Ração",
          weightedAverageDryMatter: "%MS",
          totalSupplementSupplied: "Qtde. Ofertada",
          productionSubSystem: "Subsistema de Produção",
          subTable: {
            supplyNumber: "N° Trato",
            supplementName: "Suplemento/Ração",
            dryMatterPercentage: "%MS",
            quantitySupplied: "Qtde. Ofertada",
            responsibleName: "Responsável",
          },
        },
      },
    },
    form: {
      titleCreateNew: "Novo Fornecimento",
      titleEdit: "Editar Fornecimento",
      supplyDate: "Data",
      supplyNumber: "N° Trato",
      responsibleName: "Responsável",
      retreats: "Retiro/Linha",
      pickets: "Piquetes/Baias",
      subSystems: "Subsistema de Produção",
      quantitySupplied: "Qtde Ofertada",
      supplement: "Suplemento/Ração",
      saveOptions: {
        only_save: "Salvar somente",
        save_and_create_new: "Salvar e criar novo fornecimento",
      },
    },
  },
  partialPayment: {
    title: "Criar Pagamento Parcial",
    form: {
      valuePartialPayment: "Valor Pag. Parcial",
      settleDate: "Data Baixa",
      expirationDate: "Nova Data Vencimento",
      valuePendingPayment: "Valor Pend. Pgto",
      partialPayment: {
        title: "Criar Pagamento Parcial",
        form: {
          valuePartialPayment: "Valor Pag. Parcial",
          settleDate: "Data Baixa",
          expirationDate: "Nova Data Vencimento",
          valuePendingPayment: "Valor Pend. Pgto",
          fees: "Valor Juros/Multas",
        },
      },
    },
  },
  financialDashboard: {
    resultCenter: "Centro de Resultado",
    costCenter: "Centro de Custo",
    costType: "Atividade",
    period: "Período",
    financialProject: "Projeto",
    bankAccount: "Conta Bancária",
    buttonConfirm: "Processar",
    periods: {
      lastSevenDays: "Últimos 7 dias",
      lastThirdyDays: "Últimos 30 dias",
      currentMonth: "Mês Atual",
      lastMonth: "Mês Anterior",
      currentYear: "Ano Atual",
      lastYear: "Ano Anterior",
      currentHarvest: "Safra Atual",
      lastHarvest: "Safra Anterior",
      customDateRange: "Especificar Data",
    },
  },
  reproductiveProtocol: {
    title: "Protocolos Reprodutivos",
    buttonNew: "Nova Protocolo",
    table: {
      columns: {
        status: "Status",
        name: "Nome",
        duration: "Duração",
        createdAt: "Data de Criação",
        createdBy: "Responsável Criação",
        applicationDays: {
          orderApplication: "Ordem",
          dayApplication: "D?",
          product: "Produto/Hormônio",
        },
      },
    },
    form: {
      titleCreateNew: "Nova Protocolo",
      titleEdit: "Editar Protocolo",
      groupFarmVisibility: "Quem irá utilizar o Protocolo?",
      name: "Nome genérico",
      duration: "Duração Protocolo",
      newApplicationDay: "Linha",
      observations: "Observações",
      applicationDays: {
        orderApplication: "Ordem",
        dayApplication: "D?",
        product: "Produto/Hormônio",
      },
    },
  },
  animalFarmReproductiveProtocol: {
    title: "Lançamentos de Protocolos",
    buttonNew: "Aplicar Protocolo",
    table: {
      columns: {
        handlingNumber: "Fêmea",
        dateApplication: "Data",
        reproductiveProtocolName: "Protocolo",
        reproductionPeriodDescription: "Estação de Montar",
        lotOriginName: "Lote de Origem",
        lotDestinationName: "Lote Destino",
      },
    },
    form: {
      titleCreateNew: "Aplicar Protocolo",
      titleEdit: "Editar Protocolo",
      buttonApply: "Aplicar",
      applyForAnimalsGroupForm: {
        dateApplication: "Data D0",
        reproductiveProtocolId: "Protocolo",
        reproductionPeriodId: "Estação de Monta",
        lotDestinationId: "Lote Destino",
      },
      groupTitle: "Aplicações",
      animalsTable: {
        handlingNumber: "Identificação",
        lotName: "Lote",
        breedName: "Raça",
        age: "Idade",
        femaleSituation: "Situação",
        animalFarmFunction: "Finalidade",
        animalReproductionState: "Estado",
        animalReproductionCategory: "Categoria",
        lastBirthDate: "Dt. Último Parto",
      },
    },
  },
  currency: {
    AED: "Dirham dos Emirados",
    AFN: "Afghani do Afeganistão",
    ALL: "Lek Albanês",
    AMD: "Dram Armênio",
    ANG: "Guilder das Antilhas",
    AOA: "Kwanza Angolano",
    ARS: "Peso Argentino",
    AUD: "Dólar Australiano",
    AZN: "Manat Azeri",
    BAM: "Marco Conversível",
    BBD: "Dólar de Barbados",
    BDT: "Taka de Bangladesh",
    BGN: "Lev Búlgaro",
    BHD: "Dinar do Bahrein",
    BIF: "Franco Burundinense",
    BND: "Dólar de Brunei",
    BOB: "Boliviano",
    BRL: "Real Brasileiro",
    BRLT: "Real Brasileiro Turismo",
    BSD: "Dólar das Bahamas",
    BTC: "Bitcoin",
    BWP: "Pula de Botswana",
    BYN: "Rublo Bielorrusso",
    BZD: "Dólar de Belize",
    CAD: "Dólar Canadense",
    CHF: "Franco Suíço",
    CHFRTS: "Franco Suíço",
    CLP: "Peso Chileno",
    CNH: "Yuan chinês offshore",
    CNY: "Yuan Chinês",
    COP: "Peso Colombiano",
    CRC: "Colón Costarriquenho",
    CUP: "Peso Cubano",
    CVE: "Escudo cabo-verdiano",
    CZK: "Coroa Checa",
    DJF: "Franco do Djubouti",
    DKK: "Coroa Dinamarquesa",
    DOGE: "Dogecoin",
    DOP: "Peso Dominicano",
    DZD: "Dinar Argelino",
    EGP: "Libra Egípcia",
    ETB: "Birr Etíope",
    ETH: "Ethereum",
    EUR: "Euro",
    FJD: "Dólar de Fiji",
    GBP: "Libra Esterlina",
    GEL: "Lari Georgiano",
    GHS: "Cedi Ganês",
    GMD: "Dalasi da Gâmbia",
    GNF: "Franco de Guiné",
    GTQ: "Quetzal Guatemalteco",
    HKD: "Dólar de Hong Kong",
    HNL: "Lempira Hondurenha",
    HRK: "Kuna Croata",
    HTG: "Gourde Haitiano",
    HUF: "Florim Húngaro",
    IDR: "Rupia Indonésia",
    ILS: "Novo Shekel Israelense",
    INR: "Rúpia Indiana",
    IQD: "Dinar Iraquiano",
    IRR: "Rial Iraniano",
    ISK: "Coroa Islandesa",
    JMD: "Dólar Jamaicano",
    JOD: "Dinar Jordaniano",
    JPY: "Iene Japonês",
    JPYRTS: "Iene Japonês",
    KES: "Shilling Queniano",
    KGS: "Som Quirguistanês",
    KHR: "Riel Cambojano",
    KMF: "Franco Comorense",
    KRW: "Won Sul-Coreano",
    KWD: "Dinar Kuwaitiano",
    KYD: "Dólar das Ilhas Cayman",
    KZT: "Tengue Cazaquistanês",
    LAK: "Kip Laosiano",
    LBP: "Libra Libanesa",
    LKR: "Rúpia de Sri Lanka",
    LSL: "Loti do Lesoto",
    LTC: "Litecoin",
    LYD: "Dinar Líbio",
    MAD: "Dirham Marroquino",
    MDL: "Leu Moldavo",
    MGA: "Ariary Madagascarense",
    MKD: "Denar Macedônio",
    MMK: "Kyat de Mianmar",
    MNT: "Mongolian Tugrik",
    MOP: "Pataca de Macau",
    MRO: "Ouguiya Mauritana",
    MUR: "Rúpia Mauriciana",
    MVR: "Rufiyaa Maldiva",
    MWK: "Kwacha Malauiana",
    MXN: "Peso Mexicano",
    MYR: "Ringgit Malaio",
    MZN: "Metical de Moçambique",
    NAD: "Dólar Namíbio",
    NGN: "Naira Nigeriana",
    NGNI: "Naira Nigeriana",
    NGNPARALLEL: "Naira Nigeriana",
    NIO: "Córdoba Nicaraguense",
    NOK: "Coroa Norueguesa",
    NPR: "Rúpia Nepalesa",
    NZD: "Dólar Neozelandês",
    OMR: "Rial Omanense",
    PAB: "Balboa Panamenho",
    PEN: "Sol do Peru",
    PGK: "Kina Papua-Nova Guiné",
    PHP: "Peso Filipino",
    PKR: "Rúpia Paquistanesa",
    PLN: "Zlóti Polonês",
    PYG: "Guarani Paraguaio",
    QAR: "Rial Catarense",
    RON: "Leu Romeno",
    RSD: "Dinar Sérvio",
    RUB: "Rublo Russo",
    RUBTOD: "Rublo Russo",
    RUBTOM: "Rublo Russo",
    RWF: "Franco Ruandês",
    SAR: "Riyal Saudita",
    SCR: "Rúpias de Seicheles",
    SDG: "Libra Sudanesa",
    SDR: "DSE",
    SEK: "Coroa Sueca",
    SGD: "Dólar de Cingapura",
    SOS: "Shilling Somaliano",
    STD: "Dobra São Tomé/Príncipe",
    SVC: "Colon de El Salvador",
    SYP: "Libra Síria",
    SZL: "Lilangeni Suazilandês",
    THB: "Baht Tailandês",
    TJS: "Somoni do Tajiquistão",
    TMT: "TMT",
    TND: "Dinar Tunisiano",
    TRY: "Nova Lira Turca",
    TTD: "Dólar de Trinidad",
    TWD: "Dólar Taiuanês",
    TZS: "Shilling Tanzaniano",
    UAH: "Hryvinia Ucraniana",
    UGX: "Shilling Ugandês",
    USD: "Dólar Americano",
    USDT: "Dólar Americano",
    UYU: "Peso Uruguaio",
    UZS: "Som Uzbequistanês",
    VEF: "Bolívar Venezuelano",
    VND: "Dong Vietnamita",
    VUV: "Vatu de Vanuatu",
    XAF: "Franco CFA Central",
    XAGG: "Prata",
    XBR: "Brent Spot",
    XCD: "Dólar do Caribe Oriental",
    XOF: "Franco CFA Ocidental",
    XPF: "Franco CFP",
    XRP: "XRP",
    YER: "Riyal Iemenita",
    ZAR: "Rand Sul-Africano",
    ZMK: "Kwacha Zambiana",
    ZWL: "Dólar Zimbabuense",
    XAU: "Ouro",
  },
  importFinancialTransaction: {
    title: "Importar Movimentações Financeiras",
    message:
      '<p class="message">Faça <strong>download do template abaixo, complete a planilha</strong> com as movimentações financeiras, <strong>faça a importação</strong> em seguida e clique em processar.</p>',
    draggerMessage:
      "Clique ou arraste o arquivo para esta área para fazer o upload",
  },
  confinementResultReport: {
    form: {
      title: "Resultado do Confinamento",
      salesPeriod: "Período das Vendas",
      clientId: "Cliente",
      supplierId: "Fornecedor",
      saleScenarioId: "Cenário de Venda(s)",
      documentNumber: "N° do Documento",
      lotIds: "Lotes",
      showDeathData: "Exibir dados de Mortes no período?",
    },
  },
};
