import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter, useRouteMatch, Link } from "react-router-dom";
import moment from "moment";
import { Async } from "react-async";
/** Redux libs */
/** Components */
import { Container, MessageContainer } from "./styles";
import {
  Row,
  Col,
  Icon,
  Table,
  Divider,
  Spin,
  Popover,
  Input,
  Button,
  notification,
} from "antd";
import Column from "antd/lib/table/Column";
import ButtonStandard from "../../../components/utils/button";
import ExclamationIcon from "../../../components/utils/icons/exclamation";
import BreadCrumbs from "../../../components/utils/breadCrumbs";
import TrashIcon from "../../../components/utils/icons/trash";
/** Services */
import {
  showSaleScenarioResultFarmSell,
  destroySaleScenarioResultFarmSell,
  showSaleScenarioResultFarmSellAnimals,
} from "../../../services/saleScenarioResultFarmSellService";
import { getLastDailyWeightReactAsync } from "../../../services/animalService";
import { numberMask } from "../../../services/helpersMethodsService";

const FarmSellDetails = ({ history }) => {
  const [form, setForm] = useState({
    saleScenarioResultId: null,
    clientName: null,
    sellDate: null,
    priceNegotiated: null,
    weightPerAnimal: null,
    carcassHarnessing: null,
    amountAnimalsSold: null,
    totalBilling: null,
    totalMarginBilling: null,
    estimatedBilling: null,
  });
  const [animalsData, setAnimalsData] = useState(null);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [loadingInfoFarmSell, setLoadingInfoFarmSell] = useState(false);
  const [isLoadingAnimals, setIsLoadingAnimals] = useState(false);
  const searchInput = useRef();
  /** Props variable */
  const {
    params: { saleScenarioId, saleScenarioResultId, id },
  } = useRouteMatch();
  /** Redux variables */
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  /** Effects */
  useEffect(() => {
    async function fetch() {
      setLoadingInfoFarmSell(true);
      try {
        let {
          data: { results },
        } = await showSaleScenarioResultFarmSell({
          groupId,
          farmId,
          saleScenarioId,
          saleScenarioResultId,
          id,
        });
        /** Format sellDate */
        if (results != null) {
          results.sellDate = moment(results?.sellDate).format("DD/MM/YYYY");
        }
        setForm(results);
        setLoadingInfoFarmSell(false);
      } catch (error) {
        console.error(error);
        setLoadingInfoFarmSell(false);
      }
    }

    fetch();
  }, [groupId, farmId, saleScenarioId, saleScenarioResultId, id]);
  useEffect(() => {
    async function fetchAnimals() {
      setIsLoadingAnimals(true);
      try {
        let {
          data: { results },
        } = await showSaleScenarioResultFarmSellAnimals({
          groupId,
          farmId,
          saleScenarioId,
          saleScenarioFarmSellId: id,
        });
        /** Format sellDate */
        if (results != null) {
          results = results.map((c) => {
            c.sellDate = moment(results?.sellDate).format("DD/MM/YYYY");
            return c;
          });
        }
        setIsLoadingAnimals(false);
        setAnimalsData(results);
      } catch (error) {
        setIsLoadingAnimals(false);
        console.error(error);
      }
    }
    fetchAnimals();
  }, [groupId, farmId, saleScenarioId, saleScenarioResultId, id]);
  /** Methods */
  function showOrHidePopover() {
    setPopoverVisible(!popoverVisible);
  }
  async function deleteFarmSell() {
    try {
      await destroySaleScenarioResultFarmSell({
        groupId,
        farmId,
        saleScenarioId,
        saleScenarioResultId,
        id,
      });
      notification.success({
        message: "Venda excluída com sucesso.",
      });
      history.push(
        `/admin/decisions/scenarios/sales/${saleScenarioId}/results/${saleScenarioResultId}`
      );
    } catch (error) {
      notification.error({
        message: "Houve um erro ao excluir à venda.",
      });
    }
  }
  /** Table methds */
  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return record[dataIndex].toString().includes(value);
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
    };
  }
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
  }

  function handleReset(clearFilters) {
    clearFilters();
  }
  return (
    <Container>
      <Row type="flex" align="middle" className="header">
        <Col
          xs={{ span: 1, offset: 0 }}
          sm={{ span: 1, offset: 0 }}
          md={{ span: 1, offset: 0 }}
          lg={{ span: 1, offset: 0 }}
          xl={{ span: 1, offset: 0 }}
        >
          <Icon
            type="arrow-left"
            className="iconBack"
            onClick={() => history.goBack()}
          />
        </Col>
        <Col xs={23} sm={23} md={23} lg={23} xl={23} align="right">
          <BreadCrumbs>
            <Link to="/admin/decisions?tabSelect=1">
              <span className="pageTreeInative">Decisões</span>
            </Link>
            <Link
              to={`/admin/decisions/scenarios/sales/${saleScenarioId}/results/${saleScenarioResultId}`}
            >
              <span className="pageTreeInative">
                Detalhes: Cenário de venda
              </span>
            </Link>
            <span className="pageTreeActive">Detalhes: Venda</span>
          </BreadCrumbs>
        </Col>
      </Row>
      {/* Form */}
      <Spin spinning={loadingInfoFarmSell}>
        <Row type="flex">
          <Col span={16}>
            <div className="infoContainer first">
              <div className="infoGroup">
                <span>Cliente</span>
                <span>
                  <strong>{form.clientName}</strong>
                </span>
              </div>
              <div className="infoGroup">
                <span>Data de saída</span>
                <span>
                  <strong>{form.sellDate}</strong>
                </span>
              </div>
              <div className="infoGroup">
                <span>Faturamento final</span>
                <span>
                  <strong>{`${numberMask(
                    form.totalBilling || 0,
                    true
                  )}`}</strong>
                </span>
              </div>
            </div>
          </Col>
          <Col span={8} className="colWithBorderLeft">
            <div className="infoContainer">
              <div className="infoGroup">
                <span>Animais</span>
                <span>
                  <strong>{form.amountAnimalsSold || 0}</strong>
                </span>
              </div>
              <div className="infoGroup">
                <span>Fat. estimado</span>
                <span>
                  <strong>{`${numberMask(
                    form.estimatedBilling || 0,
                    true
                  )}`}</strong>
                </span>
              </div>
              <div className="infoGroup">
                <Popover
                  placement="bottomRight"
                  visible={popoverVisible}
                  content={
                    <MessageContainer>
                      <div>
                        <ExclamationIcon />
                        <span>
                          Ao confirmar, a venda será excluída e os animais
                          continurão na fazenda, sendo necessário criar uma nova
                          venda.
                        </span>
                      </div>
                      <div className="buttonContainer">
                        <ButtonStandard
                          background="#4A85AE"
                          padding="5px 10px"
                          color="#FFF"
                          fontSize="14px"
                          onClick={deleteFarmSell}
                        >
                          Confirmar
                        </ButtonStandard>
                        <ButtonStandard
                          background="#D44C4C"
                          padding="5px 10px"
                          color="#FFF"
                          fontSize="14px"
                          onClick={showOrHidePopover}
                        >
                          Cancelar
                        </ButtonStandard>
                      </div>
                    </MessageContainer>
                  }
                  trigger="click"
                >
                  <ButtonStandard
                    width="141px"
                    padding="5px 10px"
                    background="#D44C4C"
                    onClick={showOrHidePopover}
                  >
                    <TrashIcon /> Deletar venda
                  </ButtonStandard>
                </Popover>
              </div>
            </div>
          </Col>
        </Row>
      </Spin>
      <Divider dashed />
      {/* Animals Table */}
      {/* Animals Table */}
      <Row type="flex">
        <Col span={24}>
          <Table
            rowKey="id"
            loading={isLoadingAnimals}
            dataSource={animalsData != null ? animalsData : []}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 10,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
            }}
          >
            <Column
              title="Identificação / Lote"
              dataIndex="identification"
              key="identification"
              align="left"
              sorter={(first, second) => {
                return first.identification.localeCompare(
                  second.identification,
                  "pt-BR",
                  {
                    numeric: false,
                    ignorePunctuation: true,
                  }
                );
              }}
              {...getColumnSearchProps("identification")}
              render={(text, record) => <span>{text.substring(0, 15)}</span>}
            />

            <Column
              title="Peso estimado"
              dataIndex="weight"
              key="weight"
              align="left"
              sorter={(first, second) => {
                return first.weight.localeCompare(second.weight, "pt-BR", {
                  numeric: true,
                  ignorePunctuation: true,
                });
              }}
              {...getColumnSearchProps("weight")}
              render={(text, record) => (
                <span>{`${numberMask(record.weight || 0)} Kg`}</span>
              )}
            />

            <Column
              title="Peso venda"
              dataIndex="weightSell"
              key="weightSell"
              align="left"
              sorter
              {...getColumnSearchProps("weightSell")}
              render={(text, record) => <span>{`${numberMask(record.weightSell || 0)} Kg`}</span>}
            />

            <Column
              title="Último peso"
              dataIndex="lastWeight"
              key="lastWeight"
              align="left"
              render={(text, record) => (
                <Async
                  promiseFn={getLastDailyWeightReactAsync}
                  payload={{
                    groupId,
                    farmId,
                    animalId: record.animalId,
                    signal: null,
                  }}
                >
                  <Async.Pending>
                    <Icon type="loading" />
                  </Async.Pending>
                  <Async.Fulfilled>
                    {(resp) => (
                      <span>
                        {resp.data?.results != null
                          ? `${numberMask(resp.data?.results?.weight || 0)} Kg`
                          : `0 Kg`}
                      </span>
                    )}
                  </Async.Fulfilled>
                </Async>
              )}
            />

            <Column
              title="% Aprov. carcaça"
              dataIndex="carcassHarnessing"
              key="carcassHarnessing"
              align="left"
              sorter
              {...getColumnSearchProps("carcassHarnessing")}
              render={(text, record) => <span>{`${numberMask(record.carcassHarnessing || 0)} %`}</span>}
            />
            
            <Column
              title="Fat. estimado"
              dataIndex="animalPrice"
              key="animalPrice"
              align="left"
              sorter={(first, second) => {
                return first.animalPrice.localeCompare(
                  second.animalPrice,
                  "pt-BR",
                  {
                    numeric: true,
                    ignorePunctuation: true,
                  }
                );
              }}
              {...getColumnSearchProps("animalPrice")}
              render={(text, record) => (
                <span>{`${numberMask(record.animalPrice || 0, true)}`}</span>
              )}
            />
            <Column
              title="Lucro estimado"
              dataIndex="margin"
              key="margin"
              align="left"
              sorter={(first, second) => {
                return first.margin.localeCompare(second.margin, "pt-BR", {
                  numeric: true,
                  ignorePunctuation: true,
                });
              }}
              {...getColumnSearchProps("margin")}
              render={(text, record) => (
                <span>{`${numberMask(record.margin || 0, true)}`}</span>
              )}
            />
            <Column
              title="Data sugerida"
              dataIndex="sellDate"
              key="sellDate"
              align="left"
            />
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(FarmSellDetails);
