export const Types = {
  RESET_DATA: "profitCenter/RESET_DATA",
  GET_ALL_PROFITCENTER: "profitCenter/GET_ALL_PROFITCENTER",
  GET_ALL_PROFITCENTER_SUCCESS: "profitCenter/GET_ALL_PROFITCENTER_SUCCESS",
  GET_ALL_PROFITCENTER_ERROR: "profitCenter/GET_ALL_PROFITCENTER_ERROR",
  GET_PROFITCENTER: "profitCenter/GET_PROFITCENTER",
  GET_PROFITCENTER_SUCCESS: "profitCenter/GET_PROFITCENTER_SUCCESS",
  GET_PROFITCENTER_ERROR: "profitCenter/GET_PROFITCENTER_ERROR",
  SHOW_DRAWER_PROFIT_CENTER: "profitCenter/SHOW_DRAWER_PROFIT_CENTER",
  HIDE_DRAWER_PROFIT_CENTER: "profitCenter/HIDE_DRAWER_PROFIT_CENTER",
  SAVE_PROFIT_CENTER: "profitCenter/SAVE_PROFIT_CENTER",
  SAVE_PROFIT_CENTER_SUCCESS: "profitCenter/SAVE_PROFIT_CENTER_SUCCESS",
  SAVE_PROFIT_CENTER_ERROR: "profitCenter/SAVE_PROFIT_CENTER_ERROR",
};

export const Creators = {
  indexProfitCenter: (
    groupId,
    farmId,
    page = 0,
    sorter = {},
    filters = "",
    size = 10,
    type = "Growth"
  ) => ({
    type: Types.GET_ALL_PROFITCENTER,
    payload: {
      groupId,
      farmId,
      page,
      sorter,
      filters,
      size,
      type,
    },
  }),
  indexProfitCenterSuccess: (data, type) => ({
    type: Types.GET_ALL_PROFITCENTER_SUCCESS,
    payload: {
      data,
      type,
    },
  }),
  indexProfitCenterError: (error, type) => ({
    type: Types.GET_ALL_PROFITCENTER_ERROR,
    payload: {
      error,
      type,
    },
  }),
  showProfitCenter: (groupId, farmId, id) => ({
    type: Types.GET_PROFITCENTER,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),
  showProfitCenterSuccess: (data) => ({
    type: Types.GET_PROFITCENTER_SUCCESS,
    payload: {
      data,
    },
  }),
  showProfitCenterError: (error) => ({
    type: Types.GET_PROFITCENTER_ERROR,
    payload: {
      error,
    },
  }),
  showDrawer: (actionType = "edit", idProfitCenter = null) => ({
    type: Types.SHOW_DRAWER_PROFIT_CENTER,
    payload: {
      actionType,
      idProfitCenter,
    },
  }),
  hideDrawer: () => ({
    type: Types.HIDE_DRAWER_PROFIT_CENTER,
    payload: {},
  }),
  saveProfitCenter: (
    groupId,
    farmId,
    id = null,
    profitCenter,
    saveOptions
  ) => ({
    type: Types.SAVE_PROFIT_CENTER,
    payload: {
      groupId,
      farmId,
      id,
      profitCenter,
      saveOptions,
    },
  }),
  saveProfitCenterSuccess: (data, saveOptions) => ({
    type: Types.SAVE_PROFIT_CENTER_SUCCESS,
    payload: { data, saveOptions },
  }),
  saveProfitCenterError: (error) => ({
    type: Types.SAVE_PROFIT_CENTER_ERROR,
    payload: { error },
  }),
};

const INITIAL_STATE = {
  isLoading: false,
  isLoadingRequest: false,
  dataGrowth: [],
  paginationGrowth: {
    page: 0,
    sorter: {},
    filters: "",
    size: 10,
  },
  dataRecreateFatten: [],
  paginationRecreateFatten: {
    page: 0,
    sorter: {},
    filters: "",
    size: 10,
  },
  profitCenterData: null,
  drawerVisible: false,
  error: {},
  idProfitCenter: null,
};

export default function profitCenter(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_ALL_PROFITCENTER:
      const { page, sorter, filters, size } = action.payload;
      if (action.payload.type === "Growth") {
        return {
          ...state,
          paginationGrowth: {
            page,
            sorter,
            filters,
            size,
          },
          isLoading: true,
        };
      } else {
        return {
          ...state,
          paginationRecreateFatten: {
            page,
            sorter,
            filters,
            size,
          },
          isLoading: true,
        };
      }
    case Types.GET_ALL_PROFITCENTER_SUCCESS:
      const { data: profitCenters } = action.payload;
      if (action.payload.type === "Growth") {
        return {
          ...state,
          isLoading: false,
          dataGrowth: profitCenters,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          dataRecreateFatten: profitCenters,
        };
      }
    case Types.GET_ALL_PROFITCENTER_ERROR:
      const { error } = action.payload;
      if (action.payload.type === "Growth") {
        return {
          ...state,
          isLoading: false,
          dataGrowth: null,
          error,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          dataRecreateFatten: null,
          error,
        };
      }
    case Types.GET_PROFITCENTER:
      return { ...state, isLoadingRequest: true };
    case Types.GET_PROFITCENTER_SUCCESS:
      return {
        ...state,
        isLoadingRequest: false,
        profitCenterData: action.payload.data,
      };
    case Types.GET_PROFITCENTER_ERROR:
      return {
        ...state,
        isLoadingRequest: false,
        profitCenterData: null,
        error,
      };
    case Types.SHOW_DRAWER_PROFIT_CENTER:
      return {
        ...state,
        drawerVisible: true,
        profitCenterData: null,
        idProfitCenter: action.payload.idProfitCenter,
      };
    case Types.HIDE_DRAWER_PROFIT_CENTER:
      return {
        ...state,
        drawerVisible: false,
        profitCenterData: null,
        idProfitCenter: null,
      };
    case Types.SAVE_PROFIT_CENTER:
      return { ...state, isLoadingRequest: true };
    case Types.SAVE_PROFIT_CENTER_SUCCESS:
      return {
        ...state,
        isLoadingRequest: false,
        profitCenterData: null,
        drawerVisible: action.payload.saveOptions,
      };
    case Types.SAVE_PROFIT_CENTER_ERROR:
      return {
        ...state,
        isLoadingRequest: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
