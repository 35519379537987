import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { getAnimalGeneology } from "../../../../services/animalService";
import { Empty, Row } from "antd";
import { Tree, TreeNode } from "react-organizational-chart";
import { AnimalGeneologyContainer, StyledNode } from "./styles";
import FemaleIcon from "../../../../components/utils/icons/female";
import MaleIcon from "../../../../components/utils/icons/male";

// import { Container } from './styles';

function AnimalGeneology() {
  const [geneologyThree, setGeneologyThree] = useState(null);
  const {
    params: { id: animalId },
  } = useRouteMatch();

  const {
    app: {
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
  } = useSelector((state) => state);

  const fetchData = useCallback(async () => {
    try {
      const {
        data: { results },
      } = await getAnimalGeneology({
        groupId,
        farmId,
        animalId,
      });
      setGeneologyThree(results);
    } catch (error) {}
  }, [groupId, farmId, animalId]);

  const renderParents = useCallback((animal, parents, level = 0) => {
    return parents.map((parent, index) => {
      const fatherLevel =
        animal.fatherIdentification === parent.animalIdentification
          ? level === 0
            ? "PAI"
            : level === 1
            ? "AVÔ"
            : level === 2
            ? "BISAVÔ"
            : level === 3
            ? "TRISAVÔ"
            : level === 4
            ? "TRETAVÔ"
            : level === 5
            ? "TATARAVÔ"
            : null
          : null;
      const motherLevel =
        animal.motherIdentification === parent.animalIdentification
          ? level === 0
            ? "MÃE"
            : level === 1
            ? "AVÓ"
            : level === 2
            ? "BISAVÓ"
            : level === 3
            ? "TRISAVÓ"
            : level === 4
            ? "TRETAVÓ"
            : level === 5
            ? "TATARAVÓ"
            : null
          : null;
      return (
        <TreeNode
          key={parent.animalId}
          label={
            <StyledNode>
              <span>
                <div className="icon">
                  {fatherLevel ? (
                    <MaleIcon className="genderIcon" />
                  ) : (
                    <FemaleIcon className="genderIcon" />
                  )}
                </div>
                {fatherLevel ?? motherLevel}{" "}
              </span>
              <span>{parent.animalIdentification}</span>
            </StyledNode>
          }
        >
          {level < 2 &&
            parent.parents &&
            parent.parents.length > 0 &&
            renderParents(parent, parent.parents, level + 1)}
        </TreeNode>
      );
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <AnimalGeneologyContainer>
      {!geneologyThree && <Empty />}
      {geneologyThree && (
        <Row style={{ marginTop: "1rem" }}>
          <Tree
            lineWidth={"2px"}
            lineColor={"#99cc33"}
            lineBorderRadius={"10px"}
            label={
              <StyledNode>
                <span>ANIMAL</span>
                <span>{geneologyThree.animalIdentification}</span>
              </StyledNode>
            }
          >
            {geneologyThree.parents &&
              geneologyThree.parents.length > 0 &&
              renderParents(geneologyThree, geneologyThree.parents)}
          </Tree>
        </Row>
      )}
    </AnimalGeneologyContainer>
  );
}

export default AnimalGeneology;
