import React from "react";
import { Row, Col } from "antd";
/* Redux libs */
import { useSelector } from "react-redux";

import { Title, Message, AlertModal, ButtonSession } from "./styles";

const OkActionModal = ({
  cancelAction,
  confirmAction,
  title,
  message,
  show,
  width,
  icon,
  cancelShow,
  labelButton,
  colorTitle
}) => {
  const { translation } = useSelector((state) => state.app);

  return (
    <AlertModal
      visible={show}
      width={width != null ? width : 468}
      footer={null}
      centered
      maskClosable={false}
      closable={false}
      iconShow={icon}
    >
      <Row justify="start">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Title>
            {icon != null ? icon : null}
            <strong style={{ color: colorTitle ? colorTitle : "#000" }}>{title}</strong>
          </Title>
        </Col>
      </Row>
      <Row justify="start" style={{ marginTop: 8 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Message>{message}</Message>
        </Col>
      </Row>
      <Row justify={cancelShow ? "space-between" : "end"} style={{ marginTop: 21 }}>
        {cancelShow ?
          <Col xs={12} sm={12} md={12} lg={12} xl={12} align="left">
            <ButtonSession className="red" onClick={cancelAction}>
              {translation.pasture.modal.confirmation.cancelButton}
            </ButtonSession>
          </Col>
        : null}
        <Col xs={cancelShow ? 12 : 24} sm={cancelShow ? 12 : 24} md={cancelShow ? 12 : 24} lg={cancelShow ? 12 : 24} xl={cancelShow ? 12 : 24} align="right">
          <ButtonSession onClick={confirmAction}>
            {labelButton}
          </ButtonSession>
        </Col>
      </Row>
    </AlertModal>
  );
};

export default OkActionModal;
