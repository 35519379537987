import { notification } from "antd";
import { call, put } from "redux-saga/effects";
import { Creators as DietStrategyActions } from "../ducks/dietStrategy";
/** Services */
import {
  getDietStrategyIndex,
  getDietStrategyShow,
  saveDietStrategy,
  inactivateDietStrategy,
  activateDietStrategy,
} from "../../services/dietStrategyService";
import { validateDietStrategyValidation } from "../../services/helpersMethodsService";

export function* indexDietStrategy(action) {
  try {
    const {
      data: { results: allDietStrategys },
    } = yield call(getDietStrategyIndex, action.payload);

    const newContent = allDietStrategys.map((e) => {
      e.useEstablishedCurve = false;
      e.expired = validateDietStrategyValidation(e);
      return e;
    });
    yield put(DietStrategyActions.indexDietStrategySuccess(newContent));
  } catch (error) {
    console.error(error);
    yield put(DietStrategyActions.indexDietStrategyError(error));
  }
}

export function* getDietStrategy(action) {
  try {
    const {
      data: { results: allDietStrategys },
    } = yield call(getDietStrategyShow, action.payload);
    allDietStrategys.useEstablishedCurve = false;
    yield put(DietStrategyActions.showDietStrategySuccess(allDietStrategys));
  } catch (error) {
    yield put(DietStrategyActions.showDietStrategyError(error));
  }
}

export function* storeOrUpdateDietStrategy(action) {
  let result = null;
  try {
    const { groupId, farmId, saveOptions } = action.payload;

    const {
      data: { results: newDietStrategy },
    } = yield call(saveDietStrategy, action.payload);
    result = newDietStrategy;

    if (action.payload.id == null)
      notification.success({
        message: "Estratégia de dieta criada com sucesso",
      });
    else
      notification.success({
        message: "Estratégia de dieta atualizada com sucesso",
      });
    yield put(
      DietStrategyActions.saveDietStrategySuccess(newDietStrategy, saveOptions)
    );
    yield put(
      DietStrategyActions.indexDietStrategy(groupId, farmId, 0, {}, "", true)
    );
  } catch (error) {
    result = null;
    notification.error({ message: "Estratégia de dieta não foi criado" });
    yield put(DietStrategyActions.saveDietStrategyError(error));
  } finally {
    if (result !== null) {
    }
  }
}

export function* deleteOrActivateDietStrategy(action) {
  const {
    groupId,
    farmId,
    action: operationAction,
    dietStrategy,
  } = action.payload;
  try {
    if (operationAction === "inactivate") {
      yield call(inactivateDietStrategy, action.payload);
    } else {
      yield call(activateDietStrategy, action.payload);
    }
    notification.success({
      message: `Estratégia de dieta: ${dietStrategy.name} foi ${
        operationAction === "inactivate" ? "inativada" : "ativada"
      } com sucesso !`,
    });
    yield put(DietStrategyActions.inactivateOrActivateDietStrategySuccess());
    yield put(
      DietStrategyActions.indexDietStrategy(groupId, farmId, 0, {}, "", true)
    );
  } catch (error) {
    const { status } = error.response;
    if (operationAction === "inactivate" && status === 406) {
      notification.error({
        message: `Estratégia de dieta: ${dietStrategy.name} não foi inativada, por que está atribuída como favorita no lote.`,
      });
    } else {
      notification.error({
        message: `Estratégia de dieta: ${dietStrategy.name} não foi ${
          operationAction === "inactivate" ? "inativada" : "ativada"
        }. Contate o suporte !`,
      });
    }
    yield put(DietStrategyActions.inactivateOrActivateDietStrategyError(error));
  }
}
