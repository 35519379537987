export const Types = {
  RESET_DATA: "quota/RESET_DATA",
  GET_ALL_FARM_QUOTA: "quota/GET_ALL_FARM_QUOTA",
  GET_ALL_FARM_QUOTA_SUCCESS: "quota/GET_ALL_FARM_QUOTA_SUCCESS",
  GET_ALL_FARM_QUOTA_ERROR: "quota/GET_ALL_FARM_QUOTA_ERROR",
  INACTIVATE_FARM_QUOTA: "quota/INACTIVATE_FARM_QUOTA",
  ACTIVATE_FARM_QUOTA: "quota/ACTIVATE_FARM_QUOTA",
  SHOW_OR_HIDE_DRAWER: "quota/SHOW_HIDE_DRAWER",
  GET_ALL_DROPDOWN: "quota/GET_ALL_DROPDOWN",
  GET_ALL_DROPDOWN_SUCCESS: "quota/GET_ALL_DROPDOWN_SUCCESS",
  GET_ALL_DROPDOWN_ERROR: "quota/GET_ALL_DROPDOWN_ERROR",
};

export const Creators = {
  index: (
    groupId,
    farmId,
  ) => ({
    type: Types.GET_ALL_FARM_QUOTA,
    payload: {
      groupId,
      farmId
    },
  }),
  indexSuccess: (data) => ({
    type: Types.GET_ALL_FARM_QUOTA_SUCCESS,
    payload: {
      data,
    },
  }),
  indexError: (error) => ({
    type: Types.GET_ALL_FARM_QUOTA_ERROR,
    payload: {
      error,
    },
  }),
  showOrHideDrawer: (id = null) => ({
    type: Types.SHOW_OR_HIDE_DRAWER,
    payload: {
      id
    },
  }),
  inactivateQuota: (groupId, farmId, id) => ({
    type: Types.INACTIVATE_FARM_QUOTA,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),
  activateQuota: (groupId, farmId, id) => ({
    type: Types.ACTIVATE_FARM_QUOTA,
    payload: {
      groupId,
      farmId,
      id,
    },
  }),
  getDropdownQuota: (
    groupId,
    farmId,
  ) => ({
    type: Types.GET_ALL_DROPDOWN,
    payload: {
      groupId,
      farmId,
    },
  }),

  getDropdownQuotaSuccess: (data) => ({
    type: Types.GET_ALL_DROPDOWN_SUCCESS,
    payload: {
      data,
    },
  }),

  getDropdownQuotaError: (error) => ({
    type: Types.GET_ALL_DROPDOWN_ERROR,
    payload: {
      error,
    },
  }),
};

const INITIAL_STATE = {
  data: [],
  isDrawerVisible: false,
  quotaId: null,
  isLoadingTable: false,
  isLoadingDropDown: false,
  isLoadingDrawer: false,
  listDropDown: [],
  error: {},
};

export default function quota(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_ALL_FARM_QUOTA:
      return {
        ...state,
        isLoadingTable: true,
      };
    case Types.GET_ALL_FARM_QUOTA_SUCCESS:
      const { data: quotas } = action.payload;
      return { ...state, isLoadingTable: false, data: quotas };
    case Types.GET_ALL_FARM_QUOTA_ERROR:
      const { error } = action.payload;
      return {
        ...state,
        isLoadingTable: false,
        data: null,
        error,
      };
    case Types.SHOW_OR_HIDE_DRAWER:
      return { ...state, quotaId: action.payload.id, isDrawerVisible: !state.isDrawerVisible };
    case Types.INACTIVATE_FARM_QUOTA:
      return { ...state, isLoadingTable: true };
    case Types.ACTIVATE_FARM_QUOTA:
      return { ...state, isLoadingTable: true };
    case Types.GET_ALL_DROPDOWN:
      return { ...state, isLoadingDropDown: true };
    case Types.GET_ALL_DROPDOWN_SUCCESS:
      return {
        ...state,
        isLoadingDropDown: false,
        listDropDown: action.payload.data,
      };
    case Types.GET_ALL_DROPDOWN_ERROR:
      return {
        ...state,
        isLoadingDropDown: false,
        listDropDown: [],
        error: action.payload.error,
      };
    default:
      return state;
  }
}
