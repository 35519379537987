import apiIam from "../config/api_iam";
import getHeaders from "./getHeaders";

export function findInvitesBySupplierGroupId(payload) {
  const { supplierGroupId, supplierGroupFarmId, page, sorter, filters, size } =
    payload;

  const { columnKey, order } = sorter;

  const orderS = order === "descend" ? "DESC" : "ASC";

  return apiIam.get(
    `/bovexo/suppliers/groups/${supplierGroupId}/farms/${supplierGroupFarmId}/invites?${
      page ? `&page=${page - 1}` : ""
    }
		${size ? `&size=${size}` : ""}
		${
      Object.entries(sorter).length > 0
        ? `&sort=${columnKey}&direction=${orderS}`
        : ""
    }
		${filters !== "" ? `&search=${filters}` : ""}`,
    getHeaders(false, null)
  );
}

export function saveInvite(payload) {
  const { supplierGroupId, supplierGroupFarmId, body } = payload;

  return apiIam.post(
    `/bovexo/suppliers/groups/${supplierGroupId}/farms/${supplierGroupFarmId}/invites`,
    body,
    getHeaders(false, null)
  );
}

export function resendEmailInvite(payload) {
  const { supplierGroupId, supplierGroupFarmId, id } = payload;

  return apiIam.post(
    `/bovexo/suppliers/groups/${supplierGroupId}/farms/${supplierGroupFarmId}/invites/${id}/resubmit`,
    "",
    getHeaders(false, null)
  );
}
