import React, { Component, Fragment } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as LotActions } from "../../../store/ducks/lot";
import { withRouter, Link } from "react-router-dom";

import {
  Container,
  Title,
  SubTitle,
  ColButtons,
  ColSelectAnimals,
  ColAnimalsSelected,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  DescriptionMessage,
  CustomSelect,
  SubTitleItalic,
} from "./styles";

/** Icons */

import {
  Row,
  Col,
  Checkbox,
  Table,
  Input,
  Button,
  Icon,
  List,
  Modal,
  Tooltip,
  notification,
  Empty,
  Select,
  Skeleton,
} from "antd";
import Column from "antd/lib/table/Column";

/** Components */
import ButtonStandard from "../../../components/utils/button";
import Loading from "../../../components/utils/loading";

/** Services */
import {
  getAllAnimalsInReceiptLotOrDontHaveLot,
  getLastDailyWeightReactAsync,
} from "../../../services/animalService";
import { getLotShow } from "../../../services/lotService";
import { getBreedIndexActive } from "../../../services/breedService";
import BreadCrumbs from "../../../components/utils/breadCrumbs";
import { Async } from "react-async";

class AddAnimalsInLot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lotInfo: null,
      pageLoading: true,
      isLoading: false,
      listAnimalsDefault: "receipt",
      selectedAnimals: [],
      selectedAnimalsKeys: [],
      animalsSelectForLotProduction: [],
      animalsSelectForLotProductionKeys: [],
      animalsToRemoveKeys: [],
      allAnimalsFromReceipt: {},
      modalVisible: false,
      selectAllAnimalsForProduction: false,
      breeds: null,
      breedSelected: null,
    };
  }

  async componentDidMount() {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;
    if (
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      await this.getLotInfo(groupSelected, farmSelected);
      await this.getBreeds(groupSelected, farmSelected);
    }
  }

  getLotInfo = async (groupSelected, farmSelected) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    const { id } = this.props.match.params;
    try {
      let {
        data: { results: lotInfo },
      } = await getLotShow({
        groupId,
        farmId,
        id,
      });
      lotInfo = {
        ...lotInfo,
        dietStrategyBaseLine:
          lotInfo.dietStrategies.length > 0
            ? lotInfo.dietStrategies.find((ds) => ds.baseline === true)
            : null,
        dietStrategies: lotInfo.dietStrategies.filter(
          (ds) => ds.baseline === false
        ),
      };
      this.setState({
        lotInfo,
        breedSelected: lotInfo.breedId,
      });
      if (lotInfo.breedId != null) {
        await this.fetchData(groupSelected, farmSelected);
      }
    } catch (error) {}
  };

  getBreeds = async (groupSelected, farmSelected) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;

    if (groupId != null && farmId != null) {
      const {
        data: { results: breeds },
      } = await getBreedIndexActive({
        groupId,
        farmId,
        withoutBreedCurveParam: true,
      });

      this.setState({ breeds, pageLoading: false });
    }
  };

  fetchData = async (
    groupSelected,
    farmSelected,
    page = 0,
    sorter = {},
    filters = "",
    size = 10
  ) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    const { lotInfo } = this.state;
    this.setState({ isLoading: true });
    try {
      const {
        data: { results: allAnimalsFromReceipt },
      } = await getAllAnimalsInReceiptLotOrDontHaveLot({
        groupId,
        farmId,
        page,
        sorter,
        filters:
          filters !== ""
            ? filters
            : `handling_number is not null; status = 'A';${
                lotInfo.breedId != null
                  ? `breed_id = '${lotInfo.breedId}';`
                  : ""
              }`,
        size,
      });
      this.setState({ allAnimalsFromReceipt });
    } catch (error) {
      this.setState({
        allAnimalsFromReceipt: {},
        allAnimalsFromProduction: {},
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  getColumnSearchTableReceiptProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearchReceiptTable(selectedKeys, confirm)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearchReceiptTable(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
            background: "#684e94",
            borderColor: "none",
            border: "none",
          }}
        />
        <Button
          onClick={() => this.handleResetReceiptTable(clearFilters)}
          icon="delete"
          size="small"
          style={{ width: 90 }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  openNotificationError = (title, message) => {
    notification["error"]({
      message: title,
      description: message,
    });
  };

  showConfirm = () => {
    this.setState({ modalVisible: true });
  };

  handleTableReceiptChange = async (pagination, filters, sorter) => {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;

    const { breedSelected } = this.state;

    let search = `handlingNumber != ''; status = 'A';${
      breedSelected != null ? `breedId = '${breedSelected}';` : ""
    }`;

    if (Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v.length > 0)
          search = search === "" ? `${k}='${v}'` : `${search};${k}='${v}'`;
      });
    }
    await this.fetchData(
      groupSelected,
      farmSelected,
      pagination.current,
      sorter,
      search,
      pagination.pageSize
    );
  };

  handleSearchReceiptTable = (selectedKeys, confirm) => {
    confirm();
  };

  handleResetReceiptTable = (clearFilters) => {
    clearFilters();
  };

  // Method to pass animal from left to right (Add) and right to left (Remove)
  handleMoveAnimals = (action) => {
    const {
      selectedAnimals,
      animalsSelectForLotProduction,
      animalsSelectForLotProductionKeys,
      selectedAnimalsKeys,
      animalsToRemoveKeys,
    } = this.state;

    switch (action) {
      case "add":
        this.setState({
          animalsSelectForLotProduction: selectedAnimals,
          animalsSelectForLotProductionKeys: selectedAnimalsKeys,
          selectAllAnimalsForProduction: false,
        });
        break;
      case "remove":
        let removedAnimalsSelectForLotProduction =
          animalsSelectForLotProduction.filter(
            (obj) => !animalsToRemoveKeys.includes(obj.id)
          );
        let removedAnimalsSelectForLotProductionKeys =
          animalsSelectForLotProductionKeys.filter(
            (obj) => !animalsToRemoveKeys.includes(obj)
          );
        let removedSelectedAnimals = selectedAnimals.filter(
          (obj) => !animalsToRemoveKeys.includes(obj.id)
        );
        let removedSelectedAnimalsKeys = selectedAnimalsKeys.filter(
          (obj) => !animalsToRemoveKeys.includes(obj)
        );
        this.setState({
          animalsSelectForLotProduction: removedAnimalsSelectForLotProduction,
          animalsSelectForLotProductionKeys:
            removedAnimalsSelectForLotProductionKeys,
          selectedAnimals: removedSelectedAnimals,
          selectedAnimalsKeys: removedSelectedAnimalsKeys,
          animalsToRemoveKeys: [],
          selectAllAnimalsForProduction: false,
        });
        break;
      default:
        break;
    }
  };

  // Method to check animal in right list
  handleSelectAnimalsForProduction = (e) => {
    if (e.target.checked)
      this.setState({
        animalsToRemoveKeys: [
          ...this.state.animalsToRemoveKeys,
          e.target.value,
        ],
      });
    else
      this.setState({
        animalsToRemoveKeys: this.state.animalsToRemoveKeys.filter(
          (i) => i !== e.target.value
        ),
      });
  };

  // Button cancel in modal
  handleModalCancel = () => {
    this.setState({
      modalVisible: false,
    });
  };

  // Button confirm in modal
  handleModalConfirm = async () => {
    const { animalsSelectForLotProduction } = this.state;
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
        translation,
      },
      lotActions,
      history,
    } = this.props;
    const { id } = this.props.match.params;
    if (animalsSelectForLotProduction.length === 0) {
      this.openNotificationError(
        translation.lot.production.addAnimalsPage.error.noAnimalsTitle,
        translation.lot.production.addAnimalsPage.error.noAnimalsMessage
      );
    } else {
      let animals = [];
      animalsSelectForLotProduction.forEach((e) => animals.push({ id: e.id }));
      lotActions.addAnimalsToLotProduction(
        id,
        groupId,
        farmId,
        animals,
        history
      );
    }
  };

  // Method to select all animals in right list
  handleSelectAllAnimalsForProduction = (e) => {
    this.setState({ selectAllAnimalsForProduction: e.target.checked });
    if (e.target.checked)
      this.setState({
        animalsToRemoveKeys: this.state.animalsSelectForLotProductionKeys,
      });
    else
      this.setState({
        animalsToRemoveKeys: [],
      });
  };

  // Method responsable to fetchData when the breed (Select) is change
  handleBreedSelectChange = async (value) => {
    this.setState({
      breedSelected: value,
      selectedAnimals: [],
      selectedAnimalsKeys: [],
      animalsSelectForLotProduction: [],
      animalsSelectForLotProductionKeys: [],
      animalsToRemoveKeys: [],
      allAnimalsFromReceipt: {},
      selectAllAnimalsForProduction: false,
    });
    const {
      app: { groupSelected, farmSelected },
    } = this.props;

    let search = `handlingNumber != ''; status = 'A'; breedId = '${value}';`;

    await this.fetchData(groupSelected, farmSelected, 0, {}, search, 10);
  };

  render() {
    const {
      lotInfo,
      pageLoading,
      isLoading,
      modalVisible,
      listAnimalsDefault,
      selectedAnimalsKeys,
      animalsSelectForLotProduction,
      animalsSelectForLotProductionKeys,
      allAnimalsFromReceipt,
      animalsToRemoveKeys,
      selectAllAnimalsForProduction,
      breeds,
      breedSelected,
    } = this.state;

    const {
      app: {
        translation,
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      history,
    } = this.props;

    const rowSelection = {
      selectedRowKeys: this.state.selectedAnimalsKeys,
      getCheckboxProps: (record) => ({
        disabled: this.state.animalsSelectForLotProductionKeys.includes(
          record.id
        ),
      }),
      onSelect: (record, selected, selectedRows, nativeEvent) => {
        if (selected) {
          this.setState({
            selectedAnimals: [...this.state.selectedAnimals, record],
            selectedAnimalsKeys: [...this.state.selectedAnimalsKeys, record.id],
          });
        } else {
          this.setState({
            selectedAnimals: this.state.selectedAnimals.filter(
              (o) => o.id !== record.id
            ),
            selectedAnimalsKeys: this.state.selectedAnimalsKeys.filter(
              (r) => r !== record.id
            ),
          });
        }
      },
      onSelectAll: (record, selected, selectedRows, nativeEvent) => {
        // Get just the ids
        const keys = selectedRows.map((sr) => sr.id);
        // Get all the selectedAnimals unless the unselected
        const lastSelectedAnimals = this.state.selectedAnimals.filter(
          (sA) => !selectedRows.includes(sA)
        );
        // Get all the selectedAnimalsKeys unless the unselected
        const lastSelectedAnimalsKeys = this.state.selectedAnimalsKeys.filter(
          (sAK) => !keys.includes(sAK)
        );

        if (selected.length > 0) {
          this.setState({
            selectedAnimals:
              this.state.selectedAnimals.length > 0
                ? lastSelectedAnimals
                : [...this.state.selectedAnimals, ...selectedRows],
            selectedAnimalsKeys:
              this.state.selectedAnimalsKeys.length > 0
                ? lastSelectedAnimalsKeys
                : [...this.state.selectedAnimalsKeys, ...keys],
          });
        } else {
          this.setState({
            selectedAnimals: lastSelectedAnimals,
            selectedAnimalsKeys: lastSelectedAnimalsKeys,
          });
        }
      },
    };

    const TableReceipt = (
      <Table
        style={{ display: listAnimalsDefault !== "receipt" && "none" }}
        key="recpt"
        rowKey="id"
        loading={isLoading}
        dataSource={
          allAnimalsFromReceipt != null ? allAnimalsFromReceipt.content : []
        }
        pagination={{
          total:
            allAnimalsFromReceipt != null
              ? allAnimalsFromReceipt.totalElements
              : 0,
          size: allAnimalsFromReceipt != null ? allAnimalsFromReceipt.size : 0,
          current:
            allAnimalsFromReceipt != null
              ? allAnimalsFromReceipt.number + 1
              : 0,
          hideOnSinglePage:
            allAnimalsFromReceipt !== null &&
            Object.entries(allAnimalsFromReceipt).length !== 0
              ? allAnimalsFromReceipt.totalElements > 10
                ? false
                : true
              : true,
        }}
        scroll={{
          x: true,
        }}
        size="small"
        rowSelection={rowSelection}
        onChange={this.handleTableReceiptChange}
      >
        <Column
          title={translation.animal.columns.handlingNumber}
          dataIndex="handlingNumber"
          sorter
          key="handlingNumber"
        />
        <Column
          title={translation.animal.columns.lot}
          dataIndex="lotName"
          sorter
          key="lotName"
          {...this.getColumnSearchTableReceiptProps("lotName")}
          render={(text, record) =>
            record.lotName != null ? (
              record.lotName.length > 10 ? (
                <Tooltip title={record.lotName}>
                  <span>{`${record.lotName.substring(0, 10)}...`}</span>
                </Tooltip>
              ) : (
                <span>{record.lotName}</span>
              )
            ) : (
              <span />
            )
          }
        />
        <Column
          title="S."
          dataIndex="gender"
          key="gender"
          filtered
          filters={[
            {
              text: translation.animal.details.male,
              value: "M",
            },
            {
              text: translation.animal.details.female,
              value: "F",
            },
          ]}
          filterMultiple={false}
          sorter
          sortDirections={["descend", "ascend"]}
          render={(text, record) => (
            <span>
              {record.gender === "M"
                ? translation.animal.details.male
                : translation.animal.details.female}
            </span>
          )}
        />
        <Column
          title="Idade"
          dataIndex="birthday"
          key="birthday"
          sorter
          sortDirections={["descend", "ascend"]}
          render={(text, record) =>
            record.birthday !== null ? (
              <span>{`${record.birthdayMonth}m`}</span>
            ) : (
              ""
            )
          }
        />
        <Column
          title={translation.animal.columns.breed}
          dataIndex="breedName"
          key="breedName"
          sorter
          sortDirections={["descend", "ascend"]}
        />
        <Column
          title={translation.animal.columns.currentWeight}
          dataIndex="lastDailyWeight"
          key="lastDailyWeight"
          sorter
          sortDirections={["descend", "ascend"]}
          render={(text, record) => (
            <Async
              promiseFn={getLastDailyWeightReactAsync}
              payload={{
                groupId,
                farmId,
                animalId: record.id,
              }}
            >
              <Async.Pending>
                <Icon type="loading" />
              </Async.Pending>
              <Async.Fulfilled>
                {(resp) => (
                  <span>{`${
                    resp.data.results != null
                      ? resp.data.results.weight.toFixed(2)
                      : 0
                  } Kg`}</span>
                )}
              </Async.Fulfilled>
            </Async>
          )}
        />
      </Table>
    );

    const AlertIcon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        <g
          id="Group_720"
          data-name="Group 720"
          transform="translate(-1107 -524)"
        >
          <circle
            id="Ellipse_169"
            data-name="Ellipse 169"
            cx="9"
            cy="9"
            r="9"
            transform="translate(1107 524)"
            fill="#a9c133"
          />
          <g
            id="Group_716"
            data-name="Group 716"
            transform="translate(1116 528.656)"
          >
            <line
              id="Line_215"
              data-name="Line 215"
              y2="7.044"
              transform="translate(0)"
              fill="none"
              stroke="#fff"
            />
            <line
              id="Line_216"
              data-name="Line 216"
              y2="1.8"
              transform="translate(0 8.844)"
              fill="none"
              stroke="#fff"
            />
          </g>
        </g>
      </svg>
    );

    const ArrowIcon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
      >
        <g
          id="Group_922"
          data-name="Group 922"
          transform="translate(-865 -389)"
        >
          <circle
            id="Ellipse_173"
            data-name="Ellipse 173"
            cx="11"
            cy="11"
            r="11"
            transform="translate(865 389)"
            fill="#684e94"
          />
          <path
            id="Subtraction_1"
            data-name="Subtraction 1"
            d="M0,10.5v0L.027,8.273,3.115,5.186.1,2.173.13,0,5.315,5.186,0,10.5Z"
            transform="translate(874.586 394.867)"
            fill="#fff"
          />
        </g>
      </svg>
    );

    const { Option } = Select;

    return (
      <Container>
        {pageLoading ? <Loading /> : null}
        {lotInfo == null ? (
          <div className="pageHeader">
            <BreadCrumbs marginBottom="10px">
              <Link to="/admin/lots">
                <span className="pageTreeInative">
                  {translation.lot.production.addAnimalsPage.listLotProduction}
                </span>
              </Link>
              <span className="pageTreeActive">
                {translation.lot.production.addAnimalsPage.title}
              </span>
            </BreadCrumbs>
            <Empty style={{ marginTop: "30px" }} />
          </div>
        ) : (
          <Fragment>
            {/* Page Header */}
            <div className="pageHeader">
              <BreadCrumbs marginBottom="10px">
                <Link to="/admin/lots">
                  <span className="pageTreeInative">
                    {
                      translation.lot.production.addAnimalsPage
                        .listLotProduction
                    }
                  </span>
                </Link>
                <span className="pageTreeActive">
                  {translation.lot.production.addAnimalsPage.title}
                </span>
              </BreadCrumbs>
              <Row type="flex" justify="start">
                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                  <Title>
                    {translation.lot.production.addAnimalsPage.title}
                  </Title>
                  {lotInfo != null ? (
                    <SubTitle>{`${lotInfo.name}${
                      lotInfo.profitCenterName != null
                        ? `/ ${lotInfo.profitCenterName}`
                        : ""
                    }`}</SubTitle>
                  ) : null}
                  {lotInfo != null && lotInfo.dietStrategyBaseLine != null ? (
                    <Fragment>
                      <SubTitle borderLeftColor="#D44C4C">{`${lotInfo.dietStrategyBaseLine.dietStrategyName}`}</SubTitle>
                      <SubTitleItalic>{` - Estratégia de dieta baseline`}</SubTitleItalic>
                    </Fragment>
                  ) : null}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                  xl={8}
                  align="right"
                  className="buttonsCol"
                >
                  <ButtonStandard
                    type="button"
                    buttonType="type4"
                    size="s"
                    onClick={() => history.goBack()}
                  >
                    {translation.buttons.cancel}
                  </ButtonStandard>
                  <ButtonStandard
                    type="button"
                    buttonType="type1"
                    width="121px"
                    height="35px"
                    padding="5px 10px 5px 10px"
                    onClick={this.showConfirm}
                  >
                    {translation.buttons.save}
                  </ButtonStandard>
                </Col>
              </Row>
              <Row type="flex" justify="start" style={{ marginTop: 10 }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row>
                    <CustomSelect
                      style={{ width: "600px" }}
                      name="breedId"
                      placeholder={
                        translation.lot.production.addAnimalsPage
                          .selectPlaceHolder
                      }
                      value={
                        breeds != null && breedSelected != null
                          ? breedSelected
                          : undefined
                      }
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      disabled={lotInfo.breedId != null}
                      onChange={this.handleBreedSelectChange}
                      loading={breeds == null ? true : false}
                    >
                      {breeds != null &&
                        Object.entries(breeds).length > 0 &&
                        breeds.map((breed) => (
                          <Option key={breed.id} value={breed.id}>
                            {breed.name}
                          </Option>
                        ))}
                    </CustomSelect>
                  </Row>
                </Col>
              </Row>
            </div>
            {/* Page Body */}
            <Skeleton active loading={breedSelected != null ? false : true}>
              <Row
                type="flex"
                justify="space-between"
                style={{ marginTop: 10 }}
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <DescriptionMessage>
                    {`${translation.lot.production.addAnimalsPage.descriptionMessage}`}
                    {ArrowIcon}
                  </DescriptionMessage>
                </Col>
              </Row>

              <Row type="flex" className="pageBody">
                {/* Col with animals table */}
                <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                  <ColSelectAnimals>{TableReceipt}</ColSelectAnimals>
                </Col>
                {/* Col with buttons */}
                <Col xs={24} sm={24} md={24} lg={2} xl={2}>
                  <ColButtons>
                    <button
                      className={
                        selectedAnimalsKeys.length >
                        animalsSelectForLotProductionKeys.length
                          ? "active"
                          : ""
                      }
                      onClick={() => this.handleMoveAnimals("add")}
                      disabled={
                        selectedAnimalsKeys.length >
                        animalsSelectForLotProductionKeys.length
                          ? false
                          : true
                      }
                    >
                      <Icon type="right" />
                    </button>
                    <button
                      className={animalsToRemoveKeys.length > 0 ? "active" : ""}
                      onClick={() => this.handleMoveAnimals("remove")}
                      disabled={animalsToRemoveKeys.length > 0 ? false : true}
                    >
                      <Icon type="left" />
                    </button>
                  </ColButtons>
                </Col>
                {/* Col with list with animals selected */}
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <ColAnimalsSelected>
                    <div className="header">
                      <Checkbox
                        style={{
                          color: "#FFF",
                          fontSize: "14px",
                          fontWeight: "bold",
                          fontFamily: "Asap",
                        }}
                        onChange={this.handleSelectAllAnimalsForProduction}
                        disabled={
                          animalsSelectForLotProduction.length > 0
                            ? false
                            : true
                        }
                        checked={selectAllAnimalsForProduction}
                      >
                        {
                          translation.lot.production.addAnimalsPage
                            .productionLot
                        }
                      </Checkbox>
                      <span>{`${animalsSelectForLotProduction.length} animais`}</span>
                    </div>
                    <Row type="flex" style={{ width: "100%" }}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <List
                          itemLayout="horizontal"
                          dataSource={animalsSelectForLotProduction}
                          renderItem={(item) => (
                            <List.Item>
                              <Checkbox
                                style={{
                                  marginLeft: "12px",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  fontFamily: "Asap",
                                }}
                                key={item.id}
                                value={item.id}
                                onChange={this.handleSelectAnimalsForProduction}
                                checked={
                                  animalsToRemoveKeys.includes(item.id)
                                    ? true
                                    : false
                                }
                              >
                                {`${item.handlingNumber} - ${item.gender} - ${item.birthdayMonth} meses - ${item.breedName}`}
                              </Checkbox>
                            </List.Item>
                          )}
                        />
                      </Col>
                    </Row>
                  </ColAnimalsSelected>
                </Col>
              </Row>
            </Skeleton>
          </Fragment>
        )}
        {/* Modal */}
        <Modal
          visible={modalVisible}
          closable={false}
          maskClosable={false}
          footer={null}
          bodyStyle={{
            paddingBottom: 0,
          }}
        >
          <ModalContainer>
            <ModalHeader>
              {AlertIcon}
              <span>
                {translation.lot.production.addAnimalsPage.modal.message}
              </span>
            </ModalHeader>
            {lotInfo != null ? (
              <div style={{ marginTop: 18, marginLeft: 24 }}>
                <span>
                  {`- ${translation.lot.production.addAnimalsPage.modal.lot}:`}
                  <strong>{` ${lotInfo.name}`}</strong>
                </span>
                <br />
                <span>
                  {`- ${translation.lot.production.addAnimalsPage.modal.profitCenter}:`}
                  <strong>{` ${lotInfo.profitCenterName}`}</strong>
                </span>
                <br />
                <span>
                  {`- ${translation.lot.production.addAnimalsPage.modal.amount}:`}
                  <strong>{` ${animalsSelectForLotProductionKeys.length} animais`}</strong>
                </span>
              </div>
            ) : null}
            <ModalFooter>
              <ButtonStandard
                type="button"
                buttonType="type4"
                size="s"
                onClick={this.handleModalCancel}
              >
                {translation.buttons.cancel}
              </ButtonStandard>
              <ButtonStandard
                type="button"
                buttonType="type2"
                width="100px"
                height="30px"
                onClick={this.handleModalConfirm}
                padding="5px 10px 5px 10px"
              >
                {translation.buttons.confirm}
              </ButtonStandard>
            </ModalFooter>
          </ModalContainer>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  lot: state.lot,
  app: state.app,
  profitCenter: state.profitCenter,
  animal: state.animal,
});

const mapDispatchToProps = (dispatch) => ({
  lotActions: bindActionCreators(LotActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddAnimalsInLot)
);
