import React from 'react';

const InstagramHomeIcon = () => (
  <svg id="instagram" xmlns="http://www.w3.org/2000/svg" width="23.806" height="23.635" viewBox="0 0 23.806 23.635">
    <path id="Caminho_975" data-name="Caminho 975" d="M17.361,0H6.443A6.428,6.428,0,0,0,0,6.4v10.84a6.428,6.428,0,0,0,6.444,6.4H17.361a6.428,6.428,0,0,0,6.444-6.4V6.4A6.428,6.428,0,0,0,17.361,0ZM22.41,17.238a5.036,5.036,0,0,1-5.049,5.012H6.443a5.036,5.036,0,0,1-5.049-5.012V6.4A5.036,5.036,0,0,1,6.443,1.386H17.361A5.036,5.036,0,0,1,22.41,6.4Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    <path id="Caminho_976" data-name="Caminho 976" d="M122.513,116a6.424,6.424,0,1,0,6.509,6.424A6.474,6.474,0,0,0,122.513,116Zm0,11.47a5.047,5.047,0,1,1,5.114-5.046A5.086,5.086,0,0,1,122.513,127.474Zm0,0" transform="translate(-110.61 -110.61)" fill="#fff"/>
    <path id="Caminho_977" data-name="Caminho 977" d="M359.9,66.285a1.924,1.924,0,1,0,1.924,1.923A1.926,1.926,0,0,0,359.9,66.285Zm0,2.451a.528.528,0,1,1,.528-.528A.529.529,0,0,1,359.9,68.736Zm0,0" transform="translate(-341.332 -63.203)" fill="#fff"/>
  </svg>
);

export default InstagramHomeIcon;
