import React from "react";

import { Container } from "./styles";

function CompareButton({ onClick, disable, title }) {
  return (
    <Container type="button" onClick={onClick} disabled={disable}>
      {title != null ? title : `Comparar`}
    </Container>
  );
}

export default CompareButton;
