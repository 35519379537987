import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Redirect, Link } from "react-router-dom";
import { Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";

import { Checkbox, notification } from "antd";
import * as Yup from "yup";

/* Styles */
import {
  Wrapper,
  Container,
  LoginContainer,
  FormContainer,
  DisplayContainer,
  DisplayBody,
  DisplayBodyMessage,
  LogoContainer,
  FooterContainer,
  ButtonContainer,
  DisplayHeader,
  DisplayFooter,
} from "./styles";

import displayImage from "../../assets/images/display_image.png";

/* Components */
import InputStandard from "../../components/utils/input";
import ButtonStandard from "../../components/utils/button";
import Loading from "../../components/utils/loading";
import FacebookIcon from "../../components/utils/icons/facebook/facebookHome";
import LinkedinIcon from "../../components/utils/icons/linkedin/linkedinHome";
import InstagramIcon from "../../components/utils/icons/instagram/instagramHome";
import ContactUsModal from "../../components/modals/contactUs";

/* Images */
import LogoVertical from "../../components/login/logos/logoVertical";
import LogoBull from "../../components/login/logos/logoBull";

/* Redux Actions */
import { Creators as AppActions } from "../../store/ducks/app";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      showPassword: false,
      showCaptcha: false,
      authorized: true,
      attempts: 1,
      captchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
      captchaActive: process.env.REACT_APP_RECAPTCHA_ACTIVATE,
      form: {
        username: "",
        password: "",
        remember_me: false,
      },
    };
  }

  static propTypes = {
    appActions: PropTypes.shape({
      authenticate: PropTypes.func.isRequired,
    }).isRequired,
    app: PropTypes.shape({
      isLooged: PropTypes.bool,
      token: PropTypes.string,
    }).isRequired,
  };

  openCloseMenu = () => this.setState({ menuOpen: !this.state.menuOpen });

  openContactUsModal = () => {
    const { appActions } = this.props;
    appActions.showModalContactUs();
  };

  handleLogin = async (values, actions) => {
    const {
      appActions,
      app: { attemptsLogin },
    } = this.props;
    const { authorized, attempts, captchaActive } = this.state;
    const { username, password, remember_me } = values;

    if (authorized) {
      appActions.authenticate(username, password, remember_me);
      this.setState({
        attempts: attempts + attemptsLogin,
      });
      if (attempts === 3 && captchaActive === "true") {
        this.setState({
          authorized: false,
          showCaptcha: true,
          attempts: 1,
        });
      }
    } else {
      notification.error({ message: "Prove que você não é um robô" });
    }
  };

  handleCaptcha = () => {
    this.setState({
      authorized: true,
    });
  };

  render() {
    const {
      app: { isLogged, token, isLoading, user },
    } = this.props;
    const { showPassword, showCaptcha, captchaKey, form } = this.state;
    const validationSchema = Yup.object().shape({
      username: Yup.string()
        .email("Digite um e-mail válido")
        .required("É preciso informar um e-mail"),
      password: Yup.string().required("É preciso informar uma senha"),
    });

    if (isLogged && token !== "") {
      if(user.isInputSupplier){
        return <Redirect to="/supplier" />;
      }else{
        return <Redirect to="/admin" />;
      }
    }

    return (
      <Wrapper>
        {isLoading && <Loading />}
        <Container>
          <LoginContainer>
            <LogoContainer>
              <LogoVertical />
            </LogoContainer>
            <FormContainer marginTop={showCaptcha}>
              <span className="title">Login</span>
              <span className="subTitle">
                Faça login na plataforma e gerencie sua fazenda
              </span>
              <Formik
                initialValues={form}
                onSubmit={this.handleLogin}
                validationSchema={validationSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  ...props
                }) => (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <label>LOGIN</label>
                    <InputStandard
                      width="100%"
                      border={
                        !touched.username || isLoading
                          ? "normal"
                          : errors.username
                          ? "error"
                          : "success"
                      }
                    >
                      <input
                        type="email"
                        value={values?.username}
                        autoCapitalize="off"
                        placeholder="Digite o seu e-mail de cadastro"
                        name="username"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          props.setFieldValue(
                            "username",
                            e.target.value.toLowerCase()
                          );
                          this.setState({
                            form: {
                              ...this.state.form,
                              username: e.target.value.toLowerCase(),
                            },
                          });
                        }}
                        disabled={!!isLoading}
                      />
                    </InputStandard>
                    {touched.username && errors.username ? (
                      <span className="error">{errors.username}</span>
                    ) : null}
                    <label>senha</label>
                    <InputStandard
                      width="100%"
                      border={
                        !touched.password || isLoading
                          ? "normal"
                          : errors.password
                          ? "error"
                          : "success"
                      }
                    >
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Digite sua senha de cadastro"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={!!isLoading}
                      />
                    </InputStandard>
                    {touched.password && errors.password ? (
                      <span className="error">{errors.password}</span>
                    ) : null}
                    <Checkbox
                      onChange={() =>
                        this.setState({
                          showPassword: !this.state.showPassword,
                        })
                      }
                      style={{
                        color: "#4B4B4B",
                        fontWeight: "normal",
                        textTransform: "none",
                        marginTop: 10,
                      }}
                    >
                      Exibir senha
                    </Checkbox>
                    <div className="captcha">
                      {showCaptcha ? (
                        <ReCAPTCHA
                          sitekey={captchaKey}
                          onChange={this.handleCaptcha}
                        />
                      ) : null}
                    </div>

                    {/* <Checkbox
                      name="remember_me"
                      checked={values.remember_me}
                      onChange={handleChange}
                      style={{
                        color: "#4B4B4B",
                        fontWeight: "normal",
                        textTransform: "none",
                        marginTop: 10
                      }}
                    >
                      Manter conectado ?
                    </Checkbox> */}

                    <div className="formFooter">
                      <ButtonStandard
                        disabled={isLoading}
                        buttonType="type1"
                        size="m"
                      >
                        Fazer login
                      </ButtonStandard>

                      <span className="forgotPasswordSpan">
                        Esqueceu a senha?
                        <Link
                          to="/change/password"
                          className="forgotPasswordLink"
                        >
                          Clique aqui!
                        </Link>
                      </span>
                    </div>
                  </form>
                )}
              </Formik>
            </FormContainer>
          </LoginContainer>
          <DisplayContainer>
            <DisplayBody style={{ backgroundImage: `url(${displayImage})` }}>
              <DisplayHeader>
                <span onClick={this.openContactUsModal}>fale conosco</span>
                <span className="border">sobre nós</span>
                <span>suporte</span>
              </DisplayHeader>
              <DisplayBodyMessage>
                <LogoBull />
                <span>
                  Decisões que <br />
                  engordam o bolso
                </span>
              </DisplayBodyMessage>
              <DisplayFooter>
                <span>
                  <FacebookIcon />
                </span>
                <span>
                  <LinkedinIcon />
                </span>
                <span className="pad">
                  <InstagramIcon />
                </span>
              </DisplayFooter>
            </DisplayBody>
          </DisplayContainer>
        </Container>
        <ContactUsModal />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
