import styled, { keyframes } from "styled-components";

const opacityAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  @media screen and (min-width: 1024px) {
    .logo-vertical {
      display: none;
    }
  }

  @media (max-width: 900px) {
    .logo-vertical {
      margin-top: 50px;
      display: flex;
      justify-content: center;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  flex-direction: row;
  background: #ffffff;

  label {
    &.error {
      color: #d44c4c;
    }
  }

  @media all and (width: 812px) and (height: 375px) {
    height: 100% !important;
    min-height: 100% !important;
    width: 100% !important;
    min-width: 100% !important;
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;

    span {
      margin: 10px 0;
    }
  }

  @media all and (max-width: 767px) {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
`;

export const RegisterContainer = styled.div`
  position: relative;
  width: 380px;
  height: 100% !important;
  min-height: 100% !important;
  -webkit-box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.3);

  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-image: linear-gradient(#684e94, #34274a);
  padding: 70px 0px 0px 0px;

  svg {
    width: 136.56px !important;
    height: 24px !important;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const LogoContainer = styled.div`
  text-align: center;
  margin-top: 200px;
  svg {
    width: 136.56px !important;
    height: 24px !important;
    margin-left: 19px;
  }

  @media screen and (max-width: 1024px) {
    /* start of large tablet styles */
    width: 100% !important;
    svg {
      width: auto !important;
      height: auto !important;
      margin: 0;
      margin-top: 40px;
    }
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
    width: 100% !important;
    svg {
      width: auto !important;
      height: auto !important;
      margin: 0;
    }
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
    width: 100%;
    svg {
      width: auto !important;
      height: auto !important;
      margin: 0;
    }
  }
`;

export const FormContainer = styled.div`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 72vw;

  & > * {
    position: relative;
    animation: ${opacityAnimation};
    -webkit-animation: ${opacityAnimation};
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
  }

  .back-container {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .title {
    position: relative;
    font-family: "Asap", sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: #111;
    margin-left: 19px;
  }

  .title-green {
    position: relative;
    font-family: "Asap", sans-serif;
    font-weight: bold;
    font-size: 15px;
    font-style: italic;
    text-transform: uppercase;
    color: rgb(198, 222, 82);
    margin-left: 20px;
    margin-top: 7px;
  }

  .back {
    position: relative;
    font-family: "Asap", sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #d44c4c;
    margin-left: 5px;
  }

  .subTitle {
    font-family: "Asap", sans-serif;
    font-weight: normal;
    font-size: 12px;
    color: #111;
    margin-left: 19px;
  }

  .tooltip-grupo {
    padding-left: 5px;
  }

  .ant-spin-nested-loading {
    width: 100%;
  }

  label {
    margin-left: 19px !important;
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #9074bf;
    margin-top: 25px;
    margin-bottom: 7px;
  }

  form {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    width: 100% !important;
    min-width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

    span.error {
      color: #c14933;
      margin-left: 19px;
    }
    a {
      margin-top: 20px;
      font-size: 1em;
      text-decoration: none;
    }

    .formFooter {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        margin-left: 19px;
        margin-bottom: 0;
      }
    }
    @media screen and (max-width: 1024px) {
      .formFooter {
        margin-top: 20px;
        justify-content: center;
      }
    }
  }

  .input {
    max-width: 455px;
  }

  @media screen and (max-width: 576px) {
    width: 100%;
    max-width: 100%;
    .input {
      max-width: 100%;
    }
    .ant-row,
    .ant-row-flex {
      & + div.ant-row,
      div.ant-row-flex {
        margin-bottom: 7px;
      }
    }
  }
`;

export const DisplayContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100%;

  /* filter: drop-shadow(0px 3px 18px rgba(0, 0, 0, 0.38)) !important; */

  #myVideo {
    position: fixed;
    opacity: 0.69;
    min-width: 100%;
    min-height: 100%;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
  }
`;

export const DisplayBody = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;

  strong {
    color: #4a85ae;
  }

  @media screen and (min-width: 1024px) {
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    margin-top: 18px;
    margin-left: 30px;
    justify-content: initial;
  }

  @media screen and (max-width: 1024px) {
    padding-top: 70px;
  }
`;

export const DisplayBodyMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  & > * {
    -webkit-animation: ${opacityAnimation};
    animation: ${opacityAnimation};
    animation-timing-function: ease-in;
  }

  svg {
    width: 111px !important;
    height: 130px !important;
    margin-right: 41px !important;
    animation-duration: 1s;
    path {
      fill: #fff !important;
      opacity: 1 !important;
    }
  }
  span {
    padding-left: 19px;
    font-family: "Asap", sans-serif;
    font-size: 16px;
    text-align: left;
    color: #fff;
    animation-duration: 2s;
  }

  span:nth-child(1) {
    font-style: italic;
    font-size: 14px;
  }

  span:nth-child(2) {
    font-style: italic;
    font-weight: bold;
    font-size: 16px;
  }

  span:nth-child(3) {
    font-size: 14px;
  }
`;

export const DisplayBox = styled.div`
  display: flex;
  margin: 65px 0px 30px 20px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 285px;
  height: 48px;
  background: #684e94;
  padding-left: 19px;

  & > * {
    -webkit-animation: ${opacityAnimation};
    animation: ${opacityAnimation};
    animation-timing-function: ease-in;
  }

  svg {
    width: 20px !important;
    height: auto !important;
    margin-right: 20px !important;
    animation-duration: 1s;
    path {
      fill: #fff !important;
      opacity: 1 !important;
    }
  }

  span {
    font-size: 16px;
    text-align: left;
    color: #fff;
    animation-duration: 2s;
  }
`;

export const FooterSideBar = styled.div`
  display: flex;
  margin: 65px 20px 60px 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 285px;
  height: 48px;

  & > * {
    -webkit-animation: ${opacityAnimation};
    animation: ${opacityAnimation};
    animation-timing-function: ease-in;
  }

  svg {
    width: 20px !important;
    height: auto !important;
    animation-duration: 1s;
    path {
      fill: #fff !important;
      opacity: 1 !important;
    }
  }

  img {
    width: 150px;
  }

  span {
    font-size: 16px;
    text-align: left;
    color: #fff;
    animation-duration: 2s;
  }

  .back-container {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .back {
    position: relative;
    padding-left: 10px;
    font-family: "Asap", sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
  }
`;

export const LogoFooter = styled.div`
  svg {
    width: 100px !important;
    height: auto !important;
    animation-duration: 1s;
    path {
      fill: #fff !important;
      opacity: 1 !important;
    }
  }
`;

export const DisplayBoxFarm = styled.div`
  background: #fcfcfc;
  /* background: #ccc; */
  margin-top: 12px;
  padding-top: 10px;
  width: 72vw;
  height: 73vh;

  @media screen and (max-width: 1024px) {
    max-height: 45vh;
  }
  @media screen and (max-width: 576px) {
    width: 100%;
    max-width: 100%;
    .input {
      max-width: 100%;
    }
    .ant-row,
    .ant-row-flex {
      & + div.ant-row,
      div.ant-row-flex {
        margin-bottom: 7px;
      }
    }
  }
`;
