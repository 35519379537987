import React from "react";

import { Container, Title } from "./styles";

function CustomCardDetails({ title, children }) {
  return (
    <Container>
      <Title>{title}</Title>
      {children}
    </Container>
  );
}

export default CustomCardDetails;
