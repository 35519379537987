import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as ReportActions } from "../../../store/ducks/report";
// Components
import {
  Container,
  Title,
  Message,
  CustomSelect,
  ExtensionContainer,
  ExtensionButton,
} from "./styles";
import { Row, Col, Spin, notification, Icon } from "antd";
import CustomDivider from "../../utils/customDivider";
import ExportPdfIcon from "../../utils/icons/export/pdf";
import ExportExcelIcon from "../../utils/icons/export/excel";
// Services
import {
  processReport,
  processReportXlsxFromTemplate,
} from "../../../services/reportService";
import BoxText from "../../utils/boxText";

const ExportReportModal = () => {
  // Variables
  const [isLoading, setIsLoading] = useState(false);
  const [reportSelected, setReportSelected] = useState(null);
  // Redux Variables
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { title, reports, reportSearch, reportColumns, ids, modalVisible } =
    useSelector((state) => state.report);
  const dispatch = useDispatch();
  // Effect
  useEffect(() => {
    if (modalVisible) {
      setReportSelected(null);
    }
  }, [modalVisible]);
  // Methods
  function handleCancel() {
    dispatch(ReportActions.hideModal());
  }
  async function handleGetFile(reportType) {
    setIsLoading(true);
    notification.info({
      message:
        "O seu relatório está sendo preparado e logo será feito o download!",
    });
    setTimeout(() => {
      setIsLoading(false);
      handleCancel();
    }, 5);
    try {
      const {
        code: reportName,
        isByTemplate,
        fileName: reportFileName,
      } = reportSelected;
      let data = null;
      if (isByTemplate === true) {
        const { data: result } = await processReportXlsxFromTemplate({
          groupId,
          farmId,
          reportName,
          reportSearch,
          ids,
        });
        data = result;
      } else {
        const { data: result } = await processReport({
          groupId,
          farmId,
          reportName,
          reportType,
          reportSearch,
          reportColumns,
          ids,
        });
        data = result;
      }
      if (data != null) {
        const fileName =
          reportType === "PDF"
            ? `${reportFileName}.pdf`
            : `${reportFileName}.xlsx`;
        const downloadUrl = window.URL.createObjectURL(
          new Blob([data], {
            type: "application/x-download",
          })
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();

        notification.success({
          message: "Relatório gerado com sucesso.",
        });
      }
    } catch (error) {
      notification.error({
        message:
          "Houve um erro ao gerar relatório. Entre em contato com o suporte",
      });
    }
  }
  return (
    <Container
      width={400}
      visible={modalVisible}
      centered
      closable={true}
      footer={null}
      onCancel={handleCancel}
    >
      <Spin spinning={isLoading}>
        <Row type="flex">
          <Col span={24}>
            <Title>{title || "Relatórios"}</Title>
          </Col>
        </Row>
        <Row type="flex">
          <Col span={24}>
            <Message>
              {
                "Selecione abaixo o template e a extensão que você deseja exportar o relatório"
              }
            </Message>
          </Col>
        </Row>
        <Row type="flex">
          <Col span={24}>
            <CustomSelect
              value={reportSelected == null ? undefined : reportSelected.code}
              placeholder={translation.defaultSelectPlaceholder}
              onChange={(value) =>
                setReportSelected(reports.find((r) => r.code === value))
              }
            >
              {reports.map((r) => (
                <CustomSelect.Option key={r?.code} value={r?.code}>
                  {r.name}
                </CustomSelect.Option>
              ))}
            </CustomSelect>
          </Col>
        </Row>
        {reportSelected != null &&
          reportSelected?.exportExtensions.length > 0 && (
            <>
              <CustomDivider dashed title="EXTENSÃO" />
              <Row type="flex">
                <Col span={24}>
                  <ExtensionContainer>
                    {reportSelected?.exportExtensions.includes("pdf") && (
                      <ExtensionButton onClick={() => handleGetFile("PDF")}>
                        <ExportPdfIcon />
                      </ExtensionButton>
                    )}
                    {reportSelected?.exportExtensions.includes("xlsx") && (
                      <ExtensionButton onClick={() => handleGetFile("EXCEL")}>
                        <ExportExcelIcon />
                      </ExtensionButton>
                    )}
                  </ExtensionContainer>
                </Col>
              </Row>
            </>
          )}
        {(ids === null || ids.length === 0) && (
          <BoxText
            color="#D44C4C"
            iconComponent={<Icon type="stop" style={{ color: "#D44C4C" }} />}
            style={{ marginBottom: 24 }}
          >
            <span>
              Atenção! Caso não selecione os animais, todos os ativos serão
              exportados automaticamente.
            </span>
          </BoxText>
        )}
      </Spin>
    </Container>
  );
};

export default ExportReportModal;
