import { Col, Row } from "antd";
import React from "react";
import useFinancialTransactionDashboardContext from "../../../../../hooks/useFinancialTransactionDashboardContext";
import { Container, Grid } from "./styles";

import { useCurrencyContext } from "../../../../../hooks/useCurrencyContext";
import { numberMask } from "../../../../../services/helpersMethodsService";

function CardTransactions() {
  const { dashboardResult } = useFinancialTransactionDashboardContext();
  const { defaultCurrencyCode } = useCurrencyContext();
  return (
    <Container>
      <Grid>
        <Row type="flex" className="header">
          <Col span={6}></Col>
          <Col span={6}>
            <strong>ORÇADO</strong>
          </Col>
          <Col span={6}>
            <strong>REALIZADO</strong>
          </Col>
          <Col span={6}>
            <strong>DIFERENÇA</strong>
          </Col>
        </Row>
        {/* RECEITA */}
        <Row type="flex" className="grid-row">
          <Col className="grid-row-col-title" span={6}>
            <strong>RECEITA</strong>
          </Col>
          <Col className="grid-row-col-value" span={6}>
            <strong
              className={
                dashboardResult.totalIncomeForeseen < 0 ? "negative" : ""
              }
            >
              {numberMask(
                dashboardResult.totalIncomeForeseen,
                true,
                defaultCurrencyCode
              )}
            </strong>
          </Col>
          <Col className="grid-row-col-value" span={6}>
            <strong
              className={
                dashboardResult.totalIncomeAccomplished < 0 ? "negative" : ""
              }
            >
              {numberMask(
                dashboardResult.totalIncomeAccomplished,
                true,
                defaultCurrencyCode
              )}
            </strong>
          </Col>
          <Col className="grid-row-col-value" span={6}>
            <strong
              className={
                dashboardResult.difIncomeBetweenForeseenAndAccomplished < 0
                  ? "negative"
                  : ""
              }
            >
              {dashboardResult.difIncomeBetweenForeseenAndAccomplished < 0
                ? `(${numberMask(
                    Math.abs(
                      dashboardResult.difIncomeBetweenForeseenAndAccomplished
                    ),
                    true,
                    defaultCurrencyCode
                  )})`
                : numberMask(
                    dashboardResult.difIncomeBetweenForeseenAndAccomplished,
                    true,
                    defaultCurrencyCode
                  )}
            </strong>
          </Col>
        </Row>
        {/* DESPESA */}
        <Row type="flex" className="grid-row">
          <Col className="grid-row-col-title" span={6}>
            <strong>DESPESA</strong>
          </Col>
          <Col className="grid-row-col-value" span={6}>
            <strong
              className={
                dashboardResult.totalExpenseForeseen < 0 ? "negative" : ""
              }
            >
              {numberMask(
                dashboardResult.totalExpenseForeseen,
                true,
                defaultCurrencyCode
              )}
            </strong>
          </Col>
          <Col className="grid-row-col-value" span={6}>
            <strong
              className={
                dashboardResult.totalExpenseAccomplished < 0 ? "negative" : ""
              }
            >
              {numberMask(
                dashboardResult.totalExpenseAccomplished,
                true,
                defaultCurrencyCode
              )}
            </strong>
          </Col>
          <Col className="grid-row-col-value" span={6}>
            <strong
              className={
                dashboardResult.difExpenseBetweenForeseenAndAccomplished < 0
                  ? "negative"
                  : ""
              }
            >
              {dashboardResult.difExpenseBetweenForeseenAndAccomplished < 0
                ? `(${numberMask(
                    Math.abs(
                      dashboardResult.difExpenseBetweenForeseenAndAccomplished
                    ),
                    true,
                    defaultCurrencyCode
                  )})`
                : numberMask(
                    dashboardResult.difExpenseBetweenForeseenAndAccomplished,
                    true,
                    defaultCurrencyCode
                  )}
            </strong>
          </Col>
        </Row>
        {/* FLUXO DE CAIXA */}
        <Row type="flex" className="grid-row">
          <Col className="grid-row-col-title" span={6}>
            <strong>FLUXO DE CAIXA</strong>
          </Col>
          <Col className="grid-row-col-value" span={6}>
            <strong
              className={
                dashboardResult.totalCashFlowForeseen < 0 ? "negative" : ""
              }
            >
              {dashboardResult.totalCashFlowForeseen < 0
                ? `(${numberMask(
                    Math.abs(dashboardResult.totalCashFlowForeseen),
                    true,
                    defaultCurrencyCode
                  )})`
                : numberMask(
                    dashboardResult.totalCashFlowForeseen,
                    true,
                    defaultCurrencyCode
                  )}
            </strong>
          </Col>
          <Col className="grid-row-col-value" span={6}>
            <strong
              className={
                dashboardResult.totalCashFlowAccomplished < 0 ? "negative" : ""
              }
            >
              {dashboardResult.totalCashFlowAccomplished < 0
                ? `(${numberMask(
                    Math.abs(dashboardResult.totalCashFlowAccomplished),
                    true,
                    defaultCurrencyCode
                  )})`
                : numberMask(
                    dashboardResult.totalCashFlowAccomplished,
                    true,
                    defaultCurrencyCode
                  )}
            </strong>
          </Col>
          <Col className="grid-row-col-value" span={6}>
            <strong
              className={
                dashboardResult.difCashFlowBetweenForeseenAndAccomplished < 0
                  ? "negative"
                  : ""
              }
            >
              {dashboardResult.difCashFlowBetweenForeseenAndAccomplished < 0
                ? `(${numberMask(
                    Math.abs(
                      dashboardResult.difCashFlowBetweenForeseenAndAccomplished
                    ),
                    true,
                    defaultCurrencyCode
                  )})`
                : numberMask(
                    dashboardResult.difCashFlowBetweenForeseenAndAccomplished,
                    true,
                    defaultCurrencyCode
                  )}
            </strong>
          </Col>
        </Row>
        {/* RECEITAS */}
        <Row type="flex" className="grid-row">
          <Col className="grid-row-col-title" span={6}>
            <strong>RECEITAS</strong>
          </Col>
          <Col className="grid-row-col-value" span={6}>
            <strong
              className={
                dashboardResult.totalIncomeForeseen < 0 ? "negative" : ""
              }
            >
              {numberMask(
                dashboardResult.totalIncomeForeseen,
                true,
                defaultCurrencyCode
              )}
            </strong>
          </Col>
          <Col className="grid-row-col-value" span={6}>
            <strong
              className={
                dashboardResult.totalIncomeAccomplished < 0 ? "negative" : ""
              }
            >
              {numberMask(
                dashboardResult.totalIncomeAccomplished,
                true,
                defaultCurrencyCode
              )}
            </strong>
          </Col>
          <Col className="grid-row-col-value" span={6}>
            <strong
              className={
                dashboardResult.difIncomeBetweenForeseenAndAccomplished < 0
                  ? "negative"
                  : ""
              }
            >
              {dashboardResult.difIncomeBetweenForeseenAndAccomplished < 0
                ? `(${numberMask(
                    Math.abs(
                      dashboardResult.difIncomeBetweenForeseenAndAccomplished
                    ),
                    true,
                    defaultCurrencyCode
                  )})`
                : numberMask(
                    dashboardResult.difIncomeBetweenForeseenAndAccomplished,
                    true,
                    defaultCurrencyCode
                  )}
            </strong>
          </Col>
        </Row>
        {/* CUSTOS */}
        <Row type="flex" className="grid-row">
          <Col className="grid-row-col-title" span={6}>
            <strong>CUSTOS</strong>
          </Col>
          <Col className="grid-row-col-value" span={6}>
            <strong
              className={
                dashboardResult.totalCostsForeseen < 0 ? "negative" : ""
              }
            >
              {numberMask(
                dashboardResult.totalCostsForeseen,
                true,
                defaultCurrencyCode
              )}
            </strong>
          </Col>
          <Col className="grid-row-col-value" span={6}>
            <strong
              className={
                dashboardResult.totalCostsAccomplished < 0 ? "negative" : ""
              }
            >
              {numberMask(
                dashboardResult.totalCostsAccomplished,
                true,
                defaultCurrencyCode
              )}
            </strong>
          </Col>
          <Col className="grid-row-col-value" span={6}>
            <strong
              className={
                dashboardResult.difCostsBetweenForeseenAndAccomplished < 0
                  ? "negative"
                  : ""
              }
            >
              {dashboardResult.difCostsBetweenForeseenAndAccomplished < 0
                ? `(${numberMask(
                    Math.abs(
                      dashboardResult.difCostsBetweenForeseenAndAccomplished
                    ),
                    true,
                    defaultCurrencyCode
                  )})`
                : numberMask(
                    dashboardResult.difCostsBetweenForeseenAndAccomplished,
                    true,
                    defaultCurrencyCode
                  )}
            </strong>
          </Col>
        </Row>
        {/* LUCRO */}
        <Row type="flex" className="grid-row">
          <Col className="grid-row-col-title" span={6}>
            <strong>LUCRO</strong>
          </Col>
          <Col className="grid-row-col-value" span={6}>
            <strong
              className={
                dashboardResult.totalProfitForeseen < 0 ? "negative" : ""
              }
            >
              {dashboardResult.totalProfitForeseen < 0
                ? `(${numberMask(
                    Math.abs(dashboardResult.totalProfitForeseen),
                    true,
                    defaultCurrencyCode
                  )})`
                : numberMask(
                    dashboardResult.totalProfitForeseen,
                    true,
                    defaultCurrencyCode
                  )}
            </strong>
          </Col>
          <Col className="grid-row-col-value" span={6}>
            <strong
              className={
                dashboardResult.totalProfitAccomplished < 0 ? "negative" : ""
              }
            >
              {dashboardResult.totalProfitAccomplished < 0
                ? `(${numberMask(
                    Math.abs(dashboardResult.totalProfitAccomplished),
                    true,
                    defaultCurrencyCode
                  )})`
                : numberMask(
                    dashboardResult.totalProfitAccomplished,
                    true,
                    defaultCurrencyCode
                  )}
            </strong>
          </Col>
          <Col className="grid-row-col-value" span={6}>
            <strong
              className={
                dashboardResult.difProfitBetweenForeseenAndAccomplished < 0
                  ? "negative"
                  : ""
              }
            >
              {dashboardResult.difProfitBetweenForeseenAndAccomplished < 0
                ? `(${numberMask(
                    Math.abs(
                      dashboardResult.difProfitBetweenForeseenAndAccomplished
                    ),
                    true,
                    defaultCurrencyCode
                  )})`
                : numberMask(
                    dashboardResult.difProfitBetweenForeseenAndAccomplished,
                    true,
                    defaultCurrencyCode
                  )}
            </strong>
          </Col>
        </Row>
      </Grid>
    </Container>
  );
}

export default CardTransactions;
