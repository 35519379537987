import {
  Col,
  Collapse,
  DatePicker,
  Radio,
  Row,
  Select,
  Spin,
  Table,
  notification,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ButtonStandard from "../../../../components/utils/button";
import {
  ArrowIcon,
  CardCustom,
  ExpensePanel,
  IncomePanel,
  TotalPanel,
} from "./styles";

import moment from "moment";

import { indexAll } from "../../../../services/costCenterService";
import { findAll } from "../../../../services/financialProjectService";
import { numberMask } from "../../../../services/helpersMethodsService";
import { fetchResultCenterTransactionsInfo } from "../../../../services/resultCenterAnalysisService";
import { indexAllWithItems } from "../../../../services/resultCenterService";
import { useCurrencyContext } from "../../../../hooks/useCurrencyContext";
import SelectCurrency from "../../../../components/utils/selectCurrency";

const ResultCenterAnalysis = () => {
  const Column = Table.Column;

  const [resultCenterIdSelected, setResultCenterIdSelected] = useState(null);
  const [costCenterIdSelected, setCostCenterIdSelected] = useState(null);
  const [resultCenterColumns, setResultCenterColumns] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [visualization, setVisualization] = useState("cashFlow");
  const [isLoading, setIsLoading] = useState(false);

  const [resultCenterList, setResultCenterList] = useState([]);
  const [costCenterList, setCostCenterList] = useState([]);
  const [incomeData, setIncomeData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [total, setTotal] = useState(0);

  const [listFinancialProjects, setListFinancialProjects] = useState([]);
  const [isLoadingFinancialProjects, setIsLoadingFinancialProjects] =
    useState(false);
  const [selectedFinancialProject, setSelectedFinancialProject] =
    useState(null);

  const {
    app: {
      translation,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
  } = useSelector((state) => state);

  const { defaultCurrencyCode } = useCurrencyContext();

  // Fetch Data
  const fetchData = async () => {
    if (resultCenterIdSelected && costCenterIdSelected) {
      setIsLoading(true);
      try {
        const items = resultCenterList?.find(
          (rc) => rc.id === resultCenterIdSelected
        )?.items;

        setResultCenterColumns([...items]);

        const payload = {
          groupId,
          farmId,
          resultCenterId: resultCenterIdSelected,
          costCenterId: costCenterIdSelected,
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
          financialProjectId: selectedFinancialProject,
          currency: defaultCurrencyCode ?? "BRL",
        };

        const {
          data: { results },
        } = await fetchResultCenterTransactionsInfo(payload);

        if (Array.isArray(results) && results.length > 0) {
          const incomeResults = results.find(
            (r) => r.transactionType === "income"
          );
          setIncomeData(incomeResults);

          const expenseResults = results.find(
            (r) => r.transactionType === "expense"
          );
          setExpenseData(expenseResults);

          setTotal(
            results.reduce(
              (a, r) =>
                a +
                (r.transactionType === "expense"
                  ? -r.totalByType
                  : r.totalByType),
              0
            )
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const renderColumns = useCallback(() => {
    const resultCenterItems = resultCenterColumns;

    if (Array.isArray(resultCenterItems)) {
      return resultCenterItems.map((rci) => {
        return (
          <Column
            title={rci.name}
            dataIndex=""
            key={rci.id}
            width={200}
            align="left"
            render={(text, record) => {
              const value = record?.resultCenterItems?.find(
                (item) => item.resultCenterItemId === rci.id
              )?.totalByResultCenterItem;
              const percentage =
                record?.resultCenterItems?.find(
                  (item) => item.resultCenterItemId === rci.id
                )?.percentage || 0;
              if (value) {
                return "Total" === record.costCenterItemName ? (
                  <strong>
                    {numberMask(value || 0, true, defaultCurrencyCode)}{" "}
                    <span className="value-percentage">{`(${numberMask(
                      percentage,
                      false
                    )} %)`}</span>
                  </strong>
                ) : (
                  <span>
                    {numberMask(value || 0, true, defaultCurrencyCode)}{" "}
                    <span className="value-percentage">{`(${numberMask(
                      percentage,
                      false
                    )} %)`}</span>
                  </span>
                );
              } else {
                return <span>-</span>;
              }
            }}
          />
        );
      });
    }
  }, [defaultCurrencyCode, resultCenterColumns]);

  //Load result center list and cost center list
  useEffect(() => {
    async function fetchProjects() {
      setIsLoadingFinancialProjects(true);
      try {
        const {
          data: { results },
        } = await findAll({
          groupId,
          farmId,
        });
        setListFinancialProjects(results);
      } catch (error) {
      } finally {
        setIsLoadingFinancialProjects(false);
      }
    }
    async function fetchResultCentersAndCostCenters() {
      setIsLoading(true);
      try {
        const {
          data: { results: rcList },
        } = await indexAllWithItems({ groupId, farmId });
        setResultCenterList(rcList.filter((rc) => rc.status !== "Pending"));

        let resultCenterBovexo =
          rcList.find((rc) => rc.groupId === null && rc.farmId === null) ||
          null;
        setResultCenterIdSelected(resultCenterBovexo?.id || null);
        setResultCenterColumns(resultCenterBovexo?.items);

        const {
          data: { results: ccList },
        } = await indexAll({ groupId, farmId });
        setCostCenterList(ccList.filter((cc) => cc.status !== "Pending"));
        setCostCenterIdSelected(
          ccList.find((cc) => cc.groupId === null && cc.farmId === null)?.id ||
            null
        );
      } catch (error) {
        notification.error({
          message: "Nenhum centro de resultado encontrado!",
        });
      } finally {
        setIsLoading(false);
      }
    }
    if (groupId && farmId) {
      fetchResultCentersAndCostCenters();
      fetchProjects();
    }
  }, [groupId, farmId]);

  //Setup Initial Period
  useEffect(() => {
    function setupPeriod() {
      const today = new Date();
      const year = today.getFullYear();

      if (today.getMonth() < 6) {
        //Before july
        setStartDate(moment({ year: year - 1, month: 6, day: 1 }));
        setEndDate(moment({ year: year, month: 5, day: 30 }));
      } else {
        //After july
        setStartDate(moment({ year: year, month: 6, day: 1 }));
        setEndDate(moment({ year: year + 1, month: 5, day: 30 }));
      }
    }

    setupPeriod();
  }, []);

  return (
    <CardCustom>
      {/* Filter Row */}
      <Row
        type="flex"
        justify="start"
        align="middle"
        gutter={8}
        className="filter-row"
      >
        <Col span={20}>
          <Row type="flex" gutter={8}>
            {/* Result Center */}
            <Col span={5}>
              <Row>
                <label className="filter-label">
                  {
                    translation.financial.resultCenterAnalysis.filters
                      .resultCenter
                  }
                  *
                </label>
              </Row>
              <Row>
                <Select
                  style={{ width: "100%" }}
                  name="resultCenterSelected"
                  value={resultCenterIdSelected || null}
                  placeholder={translation.defaultSelectPlaceholder}
                  disabled={isLoading}
                  isLoading={isLoading}
                  onChange={(selectedId) =>
                    setResultCenterIdSelected(selectedId)
                  }
                >
                  {resultCenterList?.map((rc) => (
                    <Select.Option key={rc?.id} value={rc?.id}>
                      {rc?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Row>
            </Col>

            {/* Cost Center */}
            <Col span={5}>
              <Row>
                <label className="filter-label">
                  {
                    translation.financial.resultCenterAnalysis.filters
                      .costCenter
                  }
                  *
                </label>
              </Row>
              <Row>
                <Select
                  style={{ width: "100%" }}
                  name="costCenterSelected"
                  value={costCenterIdSelected || null}
                  placeholder={translation.defaultSelectPlaceholder}
                  disabled={isLoading}
                  isLoading={isLoading}
                  onChange={(selectedId) => setCostCenterIdSelected(selectedId)}
                >
                  {costCenterList?.map((cc) => (
                    <Select.Option key={cc?.id} value={cc?.id}>
                      {cc?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Row>
            </Col>

            {/* Period */}
            <Col span={6}>
              <Row>
                <label className="filter-label">
                  {translation.financial.resultCenterAnalysis.filters.period}
                </label>
              </Row>
              <Row type="flex" justify="start" align="middle" gutter={16}>
                <Col>
                  <DatePicker.MonthPicker
                    style={{ width: 110 }}
                    name="startDate"
                    value={startDate ? startDate : undefined}
                    placeholder={translation.defaultDatePickerPlaceholder}
                    format={"MMM/YYYY"}
                    allowClear={false}
                    onChange={(date) => {
                      if (date) {
                        setStartDate(moment(date));
                      } else {
                        setStartDate(null);
                      }
                    }}
                    disabledDate={(currentDate) => currentDate.isAfter(endDate)}
                  />
                </Col>
                <Col>
                  <DatePicker.MonthPicker
                    style={{ width: 110 }}
                    name="endDate"
                    value={endDate ? endDate : undefined}
                    placeholder={translation.defaultDatePickerPlaceholder}
                    format={"MMM/YYYY"}
                    allowClear={false}
                    onChange={(date) => {
                      if (date) {
                        setEndDate(moment(date));
                      } else {
                        setEndDate(null);
                      }
                    }}
                    disabledDate={(currentDate) =>
                      currentDate.isBefore(startDate)
                    }
                  />
                </Col>
              </Row>
            </Col>

            {/* Visualization */}
            <Col span={4}>
              <Row>
                <label className="filter-label">
                  {
                    translation.financial.resultCenterAnalysis.filters
                      .visualization.label
                  }
                </label>
              </Row>
              <Row>
                <Radio.Group
                  disabled={true}
                  name="visualization"
                  value={visualization}
                  onChange={(e) => setVisualization(e.target.value)}
                >
                  <Radio value={"costing"}>
                    {
                      translation.financial.resultCenterAnalysis.filters
                        .visualization.costing
                    }
                  </Radio>
                  <Radio value={"cashFlow"}>
                    {
                      translation.financial.resultCenterAnalysis.filters
                        .visualization.cashFlow
                    }
                  </Radio>
                </Radio.Group>
              </Row>
            </Col>

            {/* Project */}
            <Col span={4}>
              <Row>
                <label className="filter-label">
                  {translation.financial.costing.filters.financialProjectId}
                </label>
              </Row>
              <Row>
                <Select
                  style={{ width: "100%" }}
                  name="selectedFinancialProject"
                  value={selectedFinancialProject || undefined}
                  placeholder={translation.defaultSelectPlaceholder}
                  loading={isLoadingFinancialProjects}
                  allowClear
                  onDeselect={() => {
                    setSelectedFinancialProject(null);
                  }}
                  onChange={(value) => {
                    setSelectedFinancialProject(value);
                  }}
                >
                  {listFinancialProjects.map((l) => (
                    <Select.Option key={l.id} value={l.id}>
                      {l.name}
                    </Select.Option>
                  ))}
                </Select>
              </Row>
            </Col>
          </Row>
          <Row type="flex" gutter={8}>
            <Col>
              <Row>
                <label className="filter-label">Moeda padrão</label>
              </Row>
              <Row>
                <SelectCurrency value={defaultCurrencyCode} disabled />
              </Row>
            </Col>
          </Row>
        </Col>
        {/* Confirm Button */}
        <Col span={4}>
          <Row type="flex" justify="end" align="bottom" gutter={8}>
            <ButtonStandard
              disabled={isLoading}
              name="process"
              buttonType="type8"
              onClick={fetchData}
            >
              {translation.financial.resultCenterAnalysis.filters.buttonConfirm}
            </ButtonStandard>
          </Row>
        </Col>
      </Row>

      {/* Panels */}
      <Spin spinning={isLoading}>
        <Row>
          <Collapse
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) =>
              isActive ? <ArrowIcon type="up" /> : <ArrowIcon type="down" />
            }
          >
            {/* Incomes */}
            <IncomePanel
              key={"Income"}
              header={
                <Row type="flex" justify="space-between">
                  <span className="income-title">
                    {translation.financial.resultCenterAnalysis.income.title}
                  </span>
                  <span className="income-value">
                    {numberMask(
                      incomeData?.totalByType || 0,
                      true,
                      defaultCurrencyCode
                    )}
                  </span>
                </Row>
              }
            >
              <Table
                loading={isLoading}
                rowKey="id"
                size="small"
                dataSource={
                  incomeData !== null ? incomeData?.costCenterItems : []
                }
                pagination={false}
                scroll={{
                  x: true,
                  y: true,
                }}
                style={{ width: "100%", margin: "0" }}
                rowClassName={(record) =>
                  "Total" === record.costCenterItemName ? "row-summarize" : ""
                }
              >
                <Column
                  title={
                    translation.financial.resultCenterAnalysis.income
                      .costCenterName
                  }
                  dataIndex="costCenterItemName"
                  key="costCenterItemName"
                  width={200}
                  fixed
                  align="left"
                  render={(text) =>
                    "Total" === text ? <strong>{text}</strong> : text
                  }
                />

                {renderColumns()}

                <Column
                  title={
                    translation.financial.resultCenterAnalysis.income.total
                  }
                  dataIndex="totalByFinancialCostCenterItem"
                  key="totalByFinancialCostCenterItem"
                  align="left"
                  width={120}
                  render={(value, record) =>
                    "Total" === record.costCenterItemName ? (
                      <strong>
                        {numberMask(value || 0, true, defaultCurrencyCode)}
                      </strong>
                    ) : (
                      <span>
                        {numberMask(value || 0, true, defaultCurrencyCode)}
                      </span>
                    )
                  }
                />

                <Column
                  title={
                    translation.financial.resultCenterAnalysis.income
                      .totalPercentage
                  }
                  dataIndex="percentage"
                  key="totalPercentage"
                  align="left"
                  render={(value, record) => {
                    if (record.totalByFinancialCostCenterItem) {
                      return "Total" === record.costCenterItemName ? (
                        <strong>{numberMask(value || 0, false)}</strong>
                      ) : (
                        <span>{numberMask(value || 0, false)}</span>
                      );
                    } else {
                      return <span>-</span>;
                    }
                  }}
                />
              </Table>
            </IncomePanel>

            {/* Expenses */}
            <ExpensePanel
              key={"Expense"}
              header={
                <Row type="flex" justify="space-between">
                  <span className="expense-title">
                    {translation.financial.resultCenterAnalysis.expense.title}
                  </span>
                  <span className="expense-value">
                    {numberMask(
                      expenseData?.totalByType || 0,
                      true,
                      defaultCurrencyCode
                    )}
                  </span>
                </Row>
              }
            >
              <Table
                loading={isLoading}
                rowKey="id"
                size="small"
                dataSource={
                  expenseData !== null ? expenseData?.costCenterItems : []
                }
                pagination={false}
                scroll={{
                  x: true,
                  y: true,
                }}
                style={{ width: "100%", margin: "0" }}
                rowClassName={(record) =>
                  "Total" === record.costCenterItemName ? "row-summarize" : ""
                }
              >
                <Column
                  title={
                    translation.financial.resultCenterAnalysis.expense
                      .costCenterName
                  }
                  dataIndex="costCenterItemName"
                  key="costCenterItemName"
                  width={200}
                  fixed
                  align="left"
                  render={(text) =>
                    "Total" === text ? <strong>{text}</strong> : text
                  }
                />

                {renderColumns()}

                <Column
                  title={
                    translation.financial.resultCenterAnalysis.expense.total
                  }
                  dataIndex="totalByFinancialCostCenterItem"
                  key="totalByFinancialCostCenterItem"
                  align="left"
                  width={120}
                  render={(value, record) =>
                    "Total" === record.costCenterItemName ? (
                      <strong>
                        {numberMask(value || 0, true, defaultCurrencyCode)}
                      </strong>
                    ) : (
                      <span>
                        {numberMask(value || 0, true, defaultCurrencyCode)}
                      </span>
                    )
                  }
                />

                <Column
                  title={
                    translation.financial.resultCenterAnalysis.expense
                      .totalPercentage
                  }
                  dataIndex="percentage"
                  key="totalPercentage"
                  align="left"
                  render={(value, record) => {
                    if (record.totalByFinancialCostCenterItem) {
                      return "Total" === record.costCenterItemName ? (
                        <strong>{numberMask(value || 0, false)}</strong>
                      ) : (
                        <span>{numberMask(value || 0, false)}</span>
                      );
                    } else {
                      return <span>-</span>;
                    }
                  }}
                />
              </Table>
            </ExpensePanel>
          </Collapse>

          {/* Total */}
          <TotalPanel type="flex" justify="space-between" align="middle">
            <span>{translation.financial.resultCenterAnalysis.total}</span>
            <span className={total < 0 ? "negative-value" : "positive-value"}>
              {numberMask(total || 0, true, defaultCurrencyCode)}
            </span>
          </TotalPanel>
        </Row>
      </Spin>
    </CardCustom>
  );
};

export default ResultCenterAnalysis;
