import React, { Component } from "react";
import moment from "moment";
import { Async } from "react-async";
import { withRouter, Link } from "react-router-dom";
import Axios from "axios";
/** Redux libs  */
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as LotActions } from "../../../store/ducks/lot";

/** Icons */
import TrashIcon from "../../../components/utils/icons/trash";
import {
  Row,
  Col,
  Table,
  notification,
  Empty,
  Button,
  Input,
  InputNumber,
  Icon,
  Select,
  DatePicker,
} from "antd";
import Column from "antd/lib/table/Column";

/** Components */
import ButtonStandard from "../../../components/utils/button";
import Loading from "../../../components/utils/loading";
import BreadCrumbs from "../../../components/utils/breadCrumbs";
import {
  Container,
  Title,
  ColSelectAnimals,
  DescriptionMessage,
  CustomDivider,
  CardLotDismembered,
  ButtonProperty,
  ButtonSelectAllAnimals,
  ButtonDeselectAllAnimals,
} from "./styles";
import TagStatus from "../../../components/utils/tagStatus";
import RoutingLeavingGuardModal from "../../../components/modals/routingLeavingGuardModal";

/** Services */
import { getListOfProfitCenterIndex } from "../../../services/profitCenterService";
import { getTypologyItemIndex } from "../../../services/typologyItemService";
import {
  getLotShow,
  getLotAnimals,
  dismember,
} from "../../../services/lotService";
import { getParameterItems } from "../../../services/generalParameterService";
import { getBreedIndexActive } from "../../../services/breedService";
import { getLotEstimatedCurve } from "../../../services/estimatedWeightCurveService";
import {
  calculateCurve,
  returnDietStrategyCurve,
} from "../../../services/curveService";

class DismemberLot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lotDismember: null,
      lotTheoricalCurve: null,
      lotDietStrategyCurve: null,
      isLoadingPage: true,
      isLoading: true,
      filter: "",
      breeds: [],
      selectedAnimalsKeys: [],
      animalsSelectForLotProductionKeys: [],
      animalsDismembered: [],
      allAnimalsFromLot: {},
      listOfProfitCenters: [],
      weightFilterOperator: "=",
      weightDateFilterOperator: "=",
      fundoPercentage: 0,
      cabeceiraPercentage: 0,
      shouldBlockNavigation: true,
    };
    this.signal = Axios.CancelToken.source();
  }

  componentWillUnmount() {
    this.signal.cancel("Cancelando apis");
  }

  async componentDidMount() {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;
    if (
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      try {
        this.getWeightRanking(groupSelected, farmSelected);
        this.getBreeds(groupSelected, farmSelected);
        this.getLotInfo(groupSelected, farmSelected);
      } catch (error) {}
    }
  }

  async shouldComponentUpdate(nextProps) {
    if (
      this.props.lot.refresh !== nextProps.lot.refresh &&
      nextProps.lot.refresh === true
    ) {
      const { lotDismember } = nextProps.lot;
      const { groupSelected, farmSelected } = this.props.app;
      this.setState({
        selectedAnimalsKeys: [],
      });
      this.getLotInfo(groupSelected, farmSelected, lotDismember);
    }
    return true;
  }

  getLotInfo = async (groupSelected, farmSelected, lotReduxState = null) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    const { id } = this.props.match.params;
    const { lotActions } = this.props;
    const { filter } = this.state;
    try {
      if (
        lotReduxState == null ||
        lotReduxState.destinationLots == null ||
        lotReduxState.destinationLots.length === 0
      ) {
        let {
          data: { results: lotDismember },
        } = await getLotShow({
          groupId,
          farmId,
          id,
          signal: this.signal,
        });
        lotDismember = {
          ...lotDismember,
          dietStrategyBaseLine:
            lotDismember.dietStrategies.length > 0
              ? lotDismember.dietStrategies.find((ds) => ds.baseline === true)
              : null,
          dietStrategies: lotDismember.dietStrategies.filter(
            (ds) => ds.baseline === false
          ),
        };
        this.setState({
          lotDismember,
          isLoadingPage: false,
        });
        this.getProfitCenters(groupSelected, farmSelected);
        /** Get Curves */
        try {
          this.getAnimals(groupSelected, farmSelected);
        } catch (error) {}
      } else {
        let tempAnimalsDismember = [];
        lotReduxState.destinationLots.forEach((lot) =>
          lot.animals.forEach(
            (animal) =>
              (tempAnimalsDismember = [...tempAnimalsDismember, animal])
          )
        );
        this.setState({
          lotDismember: lotReduxState,
          animalsDismembered: tempAnimalsDismember,
          isLoadingPage: false,
        });
        lotActions.updateLotDismember({}, false);
        this.getProfitCenters(groupSelected, farmSelected);
        /** Get Animals */
        this.getAnimals(
          groupSelected,
          farmSelected,
          tempAnimalsDismember,
          1,
          {},
          filter,
          10
        );
      }
    } catch (error) {}
  };

  getLotTheoricalCurve = async (groupId, farmId, lotId, startDate) => {
    try {
      const {
        data: { results: curve },
      } = await getLotEstimatedCurve({
        groupId,
        farmId,
        id: lotId,
        signal: this.signal,
      });
      let result = [];
      result = calculateCurve(
        curve.asymptoticWeight,
        startDate,
        curve.breedCurveParam.correctionFactor,
        curve.breedCurveParam.maturationRate,
        curve.breedCurveParam.theoreticalCurveType
      );

      this.setState({
        lotTheoricalCurve: result,
      });
    } catch (error) {
      console.error(error);
    }
  };

  getLotDietStrategyCurve = async (groupId, farmId, lotId, startDate) => {
    try {
      const result = await returnDietStrategyCurve(
        groupId,
        farmId,
        lotId,
        "Lot",
        startDate,
        this.signal
      );
      this.setState({
        lotDietStrategyCurve: result,
      });
    } catch (error) {
      console.error(error);
    }
  };

  getWeightRanking = async (groupSelected, farmSelected) => {
    try {
      const { id: groupId } = groupSelected;
      const { id: farmId } = farmSelected;
      let code = 1003;
      const {
        data: {
          results: { value: fundoPercentage, valueExtra: cabeceiraPercentage },
        },
      } = await getParameterItems({
        groupId,
        farmId,
        code,
        signal: this.signal,
      });
      this.setState({
        fundoPercentage,
        cabeceiraPercentage,
      });
    } catch (error) {}
  };

  getBreeds = async (groupSelected, farmSelected) => {
    try {
      const { id: groupId } = groupSelected;
      const { id: farmId } = farmSelected;
      const {
        data: { results: breeds },
      } = await getBreedIndexActive({
        groupId,
        farmId,
        signal: this.signal,
        withoutBreedCurveParam: true,
      });
      this.setState({ breeds });
    } catch (error) {}
  };

  getBreedName = ({ breedId }) => {
    return new Promise((resolve, reject) => {
      const { breeds } = this.state;
      if (breeds.length > 0) {
        resolve(breeds.find((b) => b.id === breedId));
      } else {
        reject();
      }
    });
  };

  getProfitCenters = async (groupSelected, farmSelected) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;

    const {
      data: { results: allProfitCenters },
    } = await getListOfProfitCenterIndex({
      groupId,
      farmId,
      signal: this.signal,
    });
    const {
      data: { results: profitCenterTypes },
    } = await getTypologyItemIndex({
      groupId,
      typologyName: "Sistema Produtivo",
      signal: this.signal,
    });
    const { items } = profitCenterTypes[0];
    if (Object.entries(allProfitCenters).length > 0) {
      if (allProfitCenters.length > 0) {
        allProfitCenters.map((pc) => {
          if (pc.productionSystemId != null) {
            const { name: typeName } = items.find(
              (t) => t.id === pc.productionSystemId
            );
            pc.typeName = typeName;
          } else {
            pc.typeName = null;
          }
          return pc;
        });
      }
    }
    this.setState({
      listOfProfitCenters: allProfitCenters.filter(
        (e) => e.status === "Active"
      ),
    });
  };

  getAnimals = async (
    groupSelected,
    farmSelected,
    animalsDismembered = [],
    page = 1,
    sorter = {},
    filters = "",
    size = 10
  ) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    const { id } = this.props.match.params;

    this.setState({ isLoading: true });

    if (animalsDismembered.length > 0) {
      let filter = "";
      filter = `${filters}id not in (`;

      animalsDismembered.map((animal) => (filter = `${filter}'${animal}',`));
      filter = `${filter})`;
      filter = filter.replace(",)", ")");
      filters = filter;
    }

    try {
      const {
        data: { results: allAnimalsFromLot },
      } = await getLotAnimals({
        groupId,
        farmId,
        id,
        page,
        sorter,
        filters,
        size,
        signal: this.signal,
        withoutPagination: false,
      });
      this.setState({ allAnimalsFromLot: allAnimalsFromLot });
    } catch (error) {
      this.setState({
        allAnimalsFromLot: {},
        allAnimalsFromProduction: {},
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  openNotificationError = (title, message) => {
    notification["error"]({
      message: title,
      description: message,
    });
  };

  handleTableLotAnimalsChange = async (pagination, filters, sorter) => {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;

    const {
      animalsDismembered,
      weightFilterOperator,
      weightDateFilterOperator,
    } = this.state;

    let search = ``;

    if (sorter["field"]) {
      sorter["field"] =
        sorter["field"] === "lastDailyWeight"
          ? "last_daily_weight"
          : sorter["field"];
      sorter["field"] =
        sorter["field"] === "lastDailyWeightGain"
          ? "last_daily_weight_gain"
          : sorter["field"];
      sorter["field"] =
        sorter["field"] === "lastDailyWeightDate"
          ? "last_daily_weight_date"
          : sorter["field"];
    }

    if (Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v.length > 0) {
          if (k === "lastDailyWeight") {
            let operator = weightFilterOperator
              .replace("<", "%3C")
              .replace(">", "%3E");

            if (operator === "=") {
              const min_value = parseFloat(v[0]) - 0.5;
              const max_value = parseFloat(v[0]) + 0.49;
              search = `${search}last_daily_weight between ${min_value} and ${max_value};`;
            } else if (operator === "%3C=") {
              const max_value = parseFloat(v[0]) + 0.4999999;
              search = `${search}last_daily_weight ${operator} ${max_value};`;
            } else if (operator === "%3E=") {
              const min_value = parseFloat(v[0]) - 0.5;
              search = `${search}last_daily_weight ${operator} ${min_value};`;
            } else search = `${search}last_daily_weight${operator}'${v[0]}';`;
          } else if (k === "lastDailyWeightGain") {
            let operator = weightFilterOperator
              .replace("<", "%3C")
              .replace(">", "%3E");

            if (operator === "=") {
              const min_value = parseFloat(v[0]) - 0.005;
              const max_value = parseFloat(v[0]) + 0.004999999;
              search = `${search}last_daily_weight_gain between ${min_value} and ${max_value};`;
            } else if (operator === "%3C=") {
              const max_value = parseFloat(v[0]) + 0.004999999;
              search = `${search}last_daily_weight_gain ${operator} ${max_value};`;
            } else if (operator === "%3E=") {
              const min_value = parseFloat(v[0]) - 0.005;
              search = `${search}last_daily_weight_gain ${operator} ${min_value};`;
            } else {
              search = `${search}last_daily_weight_gain${operator}'${v[0]}';`;
            }
          } else if (k === "lastDailyWeightDate") {
            let operator = weightDateFilterOperator
              .replace("<", "%3C")
              .replace(">", "%3E");
            search = `${search}last_daily_weight_date${operator}'${v[0]}';`;
          } else if (k === "currentWeightRanking") {
            search =
              search === ""
                ? `current_weight_ranking = '${v}'`
                : `${search};current_weight_ranking = '${v}'`;
          } else {
            k = k === "handlingNumber" ? "handling_number" : k;
            search = `${search}${k}='${v}';`;
          }
        }
      });
    }

    this.setState({
      filter: search,
    });

    await this.getAnimals(
      groupSelected,
      farmSelected,
      animalsDismembered,
      pagination.current,
      sorter,
      search,
      pagination.pageSize
    );
  };

  handleRemoveDestinationLot = async (id) => {
    const {
      app: { groupSelected, farmSelected },
      lotActions,
    } = this.props;

    const { animalsDismembered, lotDismember } = this.state;

    let animals = [];

    lotDismember.destinationLots
      .filter((lot) => lot.id === id)
      .map((lot) => (animals = lot.animals));

    const newAnimalsDimemberWithoutAnimals = animalsDismembered.filter(
      (animal) => !animals.includes(animal)
    );

    const newLotDismember = {
      ...lotDismember,
      destinationLots: lotDismember.destinationLots.filter(
        (lot) => lot.id !== id
      ),
    };

    this.setState({
      lotDismember: newLotDismember,
      animalsDismembered: newAnimalsDimemberWithoutAnimals,
    });

    lotActions.updateLotDismember(newLotDismember, false);

    await this.getAnimals(
      groupSelected,
      farmSelected,
      newAnimalsDimemberWithoutAnimals
    );
  };

  /** Open drawer for dismembering lot */
  handleDismembering = () => {
    const { lotActions } = this.props;
    const { lotDismember, selectedAnimalsKeys } = this.state;
    lotActions.showDrawerLotDismember(selectedAnimalsKeys, lotDismember);
  };

  /** Save dismembering */
  handleSaveDismembering = async () => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      lotActions,
      history,
    } = this.props;
    const { lotDismember, allAnimalsFromLot } = this.state;
    const body = {
      currentAmountAnimals: allAnimalsFromLot.totalElements,
      destinationLots: lotDismember.destinationLots,
    };
    if (
      lotDismember.destinationLots != null &&
      lotDismember.destinationLots.length > 0
    ) {
      this.setState({
        isLoadingPage: true,
        shouldBlockNavigation: false,
      });
      try {
        await dismember({
          id: lotDismember.id,
          groupId,
          farmId,
          dismemberLot: body,
        });
        notification.success({
          message: "Operação realizada com sucesso",
          description: "Lote desmembrado com sucesso",
        });
        lotActions.dismemberLotSuccess(null);
        this.setState({
          lotDismember: null,
          isLoadingPage: false,
        });
        history.push("/admin/lots");
      } catch (error) {
        if (Object.keys(error).includes("response")) {
          const { response } = error;
          if (Object.keys(response).includes("data")) {
            const {
              data: { code: errorCode },
            } = response;
            if (errorCode === 5031) {
              notification.error({
                message: "Desmembramento não realizado",
                description: `Atenção! Algum animal selecionado para o desmembramento, já possui uma movimentação para algum lote posterior a data de desmembramento.`,
              });
            } else {
              notification.error({
                message: "Desmembramento não realizado",
                description: "O Lote não foi desmembrado",
              });
            }
          }
        } else {
          notification.error({
            message: "Desmembramento não realizado",
            description: "O Lote não foi desmembrado",
          });
        }
        this.setState({
          isLoadingPage: false,
          shouldBlockNavigation: true,
        });
      }
    } else {
      notification.error({
        message: "Desmembramento não realizado",
        description: "Não foi adicionando nenhum lote de destino",
      });
    }
  };

  handleCancel = () => {
    const { history, lotActions } = this.props;
    lotActions.updateLotDismember({}, false);
    this.setState(
      {
        lotDismember: null,
        isLoading: false,
        selectedAnimalsKeys: [],
        animalsSelectForLotProductionKeys: [],
        animalsDismembered: [],
        allAnimalsFromLot: {},
        listOfProfitCenters: [],
        weightFilterOperator: "=",
        weightDateFilterOperator: "=",
        shouldBlockNavigation: false,
      },
      () => {
        history.push("/admin/lots");
      }
    );
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
            background: "#684e94",
            borderColor: "none",
            border: "none",
          }}
        />
        <Button
          onClick={() => this.handleReset(clearFilters)}
          icon="delete"
          size="small"
          style={{ width: 90 }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  getColumnWeightSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ display: "block" }}>
          <Select
            value={
              this.state.weightFilterOperator == null
                ? "="
                : this.state.weightFilterOperator
            }
            style={{
              width: 90,
              textAlign: "center",
              marginBottom: 8,
              marginRight: 8,
            }}
            onChange={(value) => {
              this.setState({
                weightFilterOperator: value,
              });
              setSelectedKeys([...selectedKeys, ""]);
            }}
          >
            <Select.Option key="<" value="<" style={{ textAlign: "center" }}>
              &lt;
            </Select.Option>
            <Select.Option key="<=" value="<=" style={{ textAlign: "center" }}>
              &le;
            </Select.Option>
            <Select.Option key="=" value="=" style={{ textAlign: "center" }}>
              &#61;
            </Select.Option>
            <Select.Option key=">=" value=">=" style={{ textAlign: "center" }}>
              &ge;
            </Select.Option>
            <Select.Option key=">" value=">" style={{ textAlign: "center" }}>
              &gt;
            </Select.Option>
          </Select>
          <InputNumber
            ref={(node) => {
              this.searchInput = node;
            }}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e ? [e] : []);
            }}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 90, marginBottom: 8 }}
            defaultValue={0}
            min={0}
            max={1000}
            step={0.01}
            formatter={(value) => {
              if (value !== "") {
                value = `${value}`.replace(/[^0-9.,]*/g, "");
                value = value.replace(/\.{2,}/g, ".");
                value = value.replace(/\.,/g, ",");
                value = value.replace(/,\./g, ",");
                value = value.replace(/,{2,}/g, ",");
                value = value.replace(/\.[0-9]+\./g, ".");
                value =
                  value.split(".")[1] !== "" &&
                  value.split(".")[1] !== undefined
                    ? value.split(".")[0] +
                      "." +
                      value.split(".")[1].substring(0, 2)
                    : value;
              }
              return value;
            }}
          />
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => this.handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  getLastDailyWeightDateColumnSearchProps = (dataIndex, columnPlaceHolder) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ display: "block" }}>
          <Select
            value={
              this.state.weightDateFilterOperator == null
                ? "="
                : this.state.weightDateFilterOperator
            }
            style={{
              width: 70,
              textAlign: "center",
              marginBottom: 8,
              marginRight: 8,
            }}
            onChange={(value) => {
              this.setState({
                weightDateFilterOperator: value,
              });
              setSelectedKeys([...selectedKeys, ""]);
            }}
          >
            <Select.Option key="<" value="<" style={{ textAlign: "center" }}>
              &lt;
            </Select.Option>
            <Select.Option key="<=" value="<=" style={{ textAlign: "center" }}>
              &le;
            </Select.Option>
            <Select.Option key="=" value="=" style={{ textAlign: "center" }}>
              &#61;
            </Select.Option>
            <Select.Option key=">=" value=">=" style={{ textAlign: "center" }}>
              &ge;
            </Select.Option>
            <Select.Option key=">" value=">" style={{ textAlign: "center" }}>
              &gt;
            </Select.Option>
          </Select>
          {/* <Input

            ref={(node) => {
              this.searchInput = node;
            }}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 90, marginBottom: 8 }}
          /> */}
          <DatePicker
            ref={(node) => {
              this.searchInput = node;
            }}
            value={
              selectedKeys[0] != null
                ? moment(selectedKeys[0], "YYYY-MM-DD")
                : null
            }
            placeholder={columnPlaceHolder}
            format="DD/MM/YYYY"
            onChange={(e) => setSelectedKeys(e ? [e.format("YYYY-MM-DD")] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 110, marginBottom: 8 }}
          />
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => this.handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  handleReset = (clearFilters) => {
    clearFilters();
  };

  handleSelectAllAnimals = async () => {
    this.setState({ isLoading: true });
    const { id } = this.props.match.params;
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;
    try {
      const {
        data: { results: allAnimalsFromLot },
      } = await getLotAnimals({
        groupId,
        farmId,
        id,
        withoutPagination: true,
      });
      if (allAnimalsFromLot.length > 0) {
        const ids = allAnimalsFromLot.map((a) => a.id);
        this.setState({ selectedAnimalsKeys: ids, isLoading: false });
      }
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };
  handleSelectAllMaleAnimals = async () => {
    this.setState({ isLoading: true });
    const { id } = this.props.match.params;
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;
    try {
      const {
        data: { results: allAnimalsFromLot },
      } = await getLotAnimals({
        groupId,
        farmId,
        id,
        withoutPagination: true,
      });
      if (allAnimalsFromLot.length > 0) {
        const ids = allAnimalsFromLot
          .filter((a) => a.gender === "M")
          .map((a) => a.id);
        this.setState({ selectedAnimalsKeys: ids, isLoading: false });
      }
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };
  handleSelectAllFemaleAnimals = async () => {
    this.setState({ isLoading: true });
    const { id } = this.props.match.params;
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;
    try {
      const {
        data: { results: allAnimalsFromLot },
      } = await getLotAnimals({
        groupId,
        farmId,
        id,
        withoutPagination: true,
      });
      if (allAnimalsFromLot.length > 0) {
        const ids = allAnimalsFromLot
          .filter((a) => a.gender === "F")
          .map((a) => a.id);
        this.setState({ selectedAnimalsKeys: ids, isLoading: false });
      }
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  handleDeselectAllAnimals = async () => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({
        isLoading: false,
        selectedAnimalsKeys: [],
      });
    }, 1000);
  };

  render() {
    const {
      lotDismember,
      isLoading,
      isLoadingPage,
      selectedAnimalsKeys,
      allAnimalsFromLot,
      listOfProfitCenters: profitCenters,
      shouldBlockNavigation,
    } = this.state;

    const {
      app: { translation },
      lot: { isLoading: lotLoading },
      history,
    } = this.props;

    const rowSelection = {
      selectedRowKeys: this.state.selectedAnimalsKeys,
      getCheckboxProps: (record) => ({
        disabled: this.state.animalsSelectForLotProductionKeys.includes(
          record.id
        ),
      }),
      onSelect: (record, selected, selectedRows, nativeEvent) => {
        if (selected) {
          this.setState({
            selectedAnimalsKeys: [...this.state.selectedAnimalsKeys, record.id],
          });
        } else {
          this.setState({
            selectedAnimalsKeys: this.state.selectedAnimalsKeys.filter(
              (r) => r !== record.id
            ),
          });
        }
      },
      onSelectAll: (record, selected, selectedRows, nativeEvent) => {
        // Get just the ids
        const keys = selectedRows.map((sr) => sr.id);
        // Get all the selectedAnimalsKeys unless the unselected
        const lastSelectedAnimalsKeys = this.state.selectedAnimalsKeys.filter(
          (sAK) => !keys.includes(sAK)
        );

        if (selected.length > 0) {
          this.setState({
            selectedAnimalsKeys:
              this.state.selectedAnimalsKeys.length > 0
                ? lastSelectedAnimalsKeys
                : [...this.state.selectedAnimalsKeys, ...keys],
          });
        } else {
          this.setState({
            selectedAnimalsKeys: lastSelectedAnimalsKeys,
          });
        }
      },
    };

    const TableLotAnimals = (
      <Table
        key="dismemberLotAnimals"
        rowKey="id"
        loading={isLoading}
        dataSource={allAnimalsFromLot != null ? allAnimalsFromLot.content : []}
        pagination={{
          total:
            allAnimalsFromLot != null ? allAnimalsFromLot.totalElements : 0,
          size: allAnimalsFromLot != null ? allAnimalsFromLot.size : 0,
          current: allAnimalsFromLot != null ? allAnimalsFromLot.number + 1 : 0,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
          hideOnSinglePage:
            allAnimalsFromLot !== null &&
            Object.entries(allAnimalsFromLot).length !== 0
              ? allAnimalsFromLot.totalElements > 10
                ? false
                : true
              : true,
        }}
        scroll={{ x: true }}
        size="small"
        rowSelection={rowSelection}
        onChange={this.handleTableLotAnimalsChange}
      >
        <Column
          title={translation.animal.columns.handlingNumber}
          dataIndex="handlingNumber"
          sorter
          key="handlingNumber"
          {...this.getColumnSearchProps("handlingNumber")}
        />
        <Column
          title={translation.animal.columns.breed}
          dataIndex="breedName"
          key="breedName"
          render={(text, record) => (
            <Async promiseFn={this.getBreedName} breedId={record.breedId}>
              <Async.Pending>
                <Icon type="loading" />
              </Async.Pending>
              <Async.Fulfilled>
                {(resp) => <span>{`${resp?.name || "-"}`}</span>}
              </Async.Fulfilled>
            </Async>
          )}
        />
        <Column
          title={translation.animal.columns.gender}
          dataIndex="gender"
          key="gender"
          filtered
          filters={[
            {
              text: translation.animal.details.male,
              value: "M",
            },
            {
              text: translation.animal.details.female,
              value: "F",
            },
          ]}
          filterMultiple={false}
          sorter
          sortDirections={["descend", "ascend"]}
          render={(text, record) => (
            <span>
              {record.gender === "M"
                ? translation.animal.details.male
                : translation.animal.details.female}
            </span>
          )}
        />
        <Column
          title="Idade"
          dataIndex="birthday"
          key="birthday"
          sorter
          sortDirections={["descend", "ascend"]}
          render={(text, record) =>
            record.birthday !== null ? (
              <span>{`${moment().diff(record.birthday, "months")} m`}</span>
            ) : (
              ""
            )
          }
        />
        <Column
          title={translation.animal.columns.currentWeight}
          dataIndex="lastDailyWeight"
          key="lastDailyWeight"
          sorter
          {...this.getColumnWeightSearchProps("lastDailyWeight")}
          sortDirections={["descend", "ascend"]}
          render={(text, record) => (
            <span>{`${
              record.lastDailyWeight != null
                ? record.lastDailyWeight.toFixed(2)
                : 0
            } Kg`}</span>
          )}
        />
        <Column
          title={translation.animal.columns.gdp}
          dataIndex="lastDailyWeightGain"
          key="lastDailyWeightGain"
          sorter
          sortDirections={["descend", "ascend"]}
          {...this.getColumnWeightSearchProps("lastDailyWeightGain")}
          render={(text, record) => (
            <span>{`${
              record.lastDailyWeightGain != null
                ? record.lastDailyWeightGain.toFixed(2)
                : 0
            } Kg`}</span>
          )}
        />
        <Column
          title={translation.animal.columns.lastDailyWeightDate}
          dataIndex="lastDailyWeightDate"
          sorter
          key="lastDailyWeightDate"
          render={(date) =>
            date != null
              ? `${date.split("-")[2]}/${date.split("-")[1]}/${
                  date.split("-")[0]
                }`
              : "-"
          }
          {...this.getLastDailyWeightDateColumnSearchProps(
            "lastDailyWeightDate",
            "Data"
          )}
        />
        <Column
          title={translation.animal.columns.weightRanking}
          dataIndex="currentWeightRanking"
          key="currentWeightRanking"
          align="center"
          width={150}
          filtered
          filters={[
            {
              text: "Cabeceira",
              value: "top",
            },
            {
              text: "Meio",
              value: "medium",
            },
            {
              text: "Fundo",
              value: "worst",
            },
          ]}
          filterMultiple={false}
          render={(text, record) =>
            record.currentWeightRanking !== null ? (
              record.currentWeightRanking === "top" ? (
                <TagStatus
                  background="#D8FFF2"
                  borderColor="#99C9B9"
                  color="#99C9B9"
                >
                  Cabeceira
                </TagStatus>
              ) : record.currentWeightRanking === "medium" ? (
                <TagStatus
                  background="#E4F7FF"
                  borderColor="#99BBC9"
                  color="#99BBC9"
                >
                  Meio
                </TagStatus>
              ) : (
                record.currentWeightRanking === "worst" && (
                  <TagStatus
                    background="#FFDFDF"
                    borderColor="#E88D8D"
                    color="#E88D8D"
                  >
                    Fundo
                  </TagStatus>
                )
              )
            ) : null
          }
        />
      </Table>
    );

    return (
      <>
        <RoutingLeavingGuardModal
          when={shouldBlockNavigation}
          navigate={(path) => history.push(path)}
          message="Parece que você está saindo sem salvar o desmembramento."
        />
        <Container>
          {lotLoading || isLoadingPage ? <Loading /> : null}
          {lotDismember == null ? (
            <div className="pageHeader">
              <BreadCrumbs marginBottom="10px">
                <Link to="/admin/lots">
                  <span className="pageTreeInative">
                    {
                      translation.lot.production.dismemberLotPage
                        .listLotProduction
                    }
                  </span>
                </Link>
                <span className="pageTreeActive">
                  {translation.lot.production.dismemberLotPage.title}
                </span>
              </BreadCrumbs>
              <Empty style={{ marginTop: "30px" }} />
            </div>
          ) : (
            <>
              {/* Page Header */}
              <div className="pageHeader">
                <BreadCrumbs marginBottom="10px">
                  <Link to="/admin/lots">
                    <span className="pageTreeInative">
                      {
                        translation.lot.production.dismemberLotPage
                          .listLotProduction
                      }
                    </span>
                  </Link>
                  <span className="pageTreeActive">
                    {translation.lot.production.dismemberLotPage.title}
                  </span>
                </BreadCrumbs>
                <Row type="flex" justify="start">
                  <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                    <Title>
                      {translation.lot.production.dismemberLotPage.title}
                    </Title>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}
                    align="right"
                    className="buttonsCol"
                  >
                    <ButtonStandard
                      type="button"
                      buttonType="type4"
                      size="s"
                      onClick={this.handleCancel}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>
                    <ButtonStandard
                      type="button"
                      buttonType="type1"
                      width="121px"
                      height="35px"
                      padding="5px 10px 5px 10px"
                      onClick={this.handleSaveDismembering}
                    >
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </div>

              {/* Page Body */}
              <Row
                type="flex"
                justify="space-between"
                style={{ marginTop: 10 }}
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <DescriptionMessage>
                    {`${translation.lot.production.dismemberLotPage.descriptionMessage}`}
                  </DescriptionMessage>
                </Col>
              </Row>

              {/* Menu buttons */}
              <Row type="flex">
                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                  <Row
                    type="flex"
                    justify="start"
                    gutter={16}
                    style={{ marginTop: 10 }}
                  >
                    <Col span={18} className="buttonsTable">
                      <ButtonSelectAllAnimals
                        disabled={isLoading}
                        type="button"
                        onClick={() => this.handleSelectAllAnimals()}
                      >
                        Selecionar todos os animais
                      </ButtonSelectAllAnimals>
                      <ButtonSelectAllAnimals
                        disabled={isLoading}
                        type="button"
                        onClick={() => this.handleSelectAllMaleAnimals()}
                      >
                        Selecionar todos os machos
                      </ButtonSelectAllAnimals>
                      <ButtonSelectAllAnimals
                        disabled={isLoading}
                        type="button"
                        onClick={() => this.handleSelectAllFemaleAnimals()}
                      >
                        Selecionar todas as fêmeas
                      </ButtonSelectAllAnimals>
                    </Col>
                    <Col span={6} align="right">
                      <ButtonDeselectAllAnimals
                        type="button"
                        onClick={() => this.handleDeselectAllAnimals()}
                        icon="delete"
                      >
                        Limpar seleções
                      </ButtonDeselectAllAnimals>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* Main */}
              <Row type="flex" className="pageBody">
                {/* Col with animals table */}
                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                  <ColSelectAnimals>{TableLotAnimals}</ColSelectAnimals>
                </Col>
                {/* Col with lot demembering info */}
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={6}
                  xl={6}
                  className="columnDememberingInfo"
                >
                  <Row type="flex">Identificação</Row>
                  <Row
                    type="flex"
                    style={{ fontSize: 22, fontWeight: "bolder" }}
                  >
                    {lotDismember.name}
                  </Row>
                  <Row type="flex" style={{ marginTop: 10 }}>
                    {translation.lot.production.dismemberLotPage.profitCenter}
                  </Row>
                  <Row
                    type="flex"
                    style={{ fontSize: 16, fontWeight: "bolder" }}
                  >
                    {lotDismember.profitCenterName}
                  </Row>
                  <Row type="flex" style={{ marginTop: 10 }}>
                    {
                      translation.lot.production.dismemberLotPage
                        .productionSubSystem
                    }
                  </Row>
                  <Row
                    type="flex"
                    style={{ fontSize: 16, fontWeight: "bolder" }}
                  >
                    {profitCenters.length > 0
                      ? profitCenters.find(
                          (pc) => pc.id === lotDismember.profitCenterId
                        ).typeName
                      : null}
                  </Row>
                  <CustomDivider dashed />
                  <Row type="flex" style={{ marginTop: 10 }}>
                    {translation.lot.production.dismemberLotPage.animalsTotal}
                  </Row>
                  <Row
                    type="flex"
                    style={{ fontSize: 16, fontWeight: "bolder" }}
                  >
                    {allAnimalsFromLot != null
                      ? allAnimalsFromLot.totalElements || 0
                      : 0}
                  </Row>
                  <Row type="flex" style={{ marginTop: 10 }}>
                    {
                      translation.lot.production.dismemberLotPage
                        .animalsSelected
                    }
                  </Row>
                  <Row
                    type="flex"
                    style={{
                      fontSize: 16,
                      fontWeight: "bolder",
                      color: "#FE8D2A",
                    }}
                  >
                    {selectedAnimalsKeys.length}
                  </Row>
                  <Row
                    type="flex"
                    style={{
                      fontSize: 16,
                      fontWeight: "bolder",
                      color: "#FE8D2A",
                    }}
                  >
                    <ButtonProperty
                      shape="round"
                      className={
                        selectedAnimalsKeys.length > 0 ? "enabled" : ""
                      }
                      onClick={() =>
                        selectedAnimalsKeys.length
                          ? this.handleDismembering()
                          : null
                      }
                    >
                      {
                        translation.lot.production.dismemberLotPage
                          .dismemberButton
                      }
                    </ButtonProperty>
                  </Row>
                  {lotDismember.destinationLots != null &&
                  lotDismember.destinationLots.length > 0 ? (
                    <>
                      <CustomDivider dashed />
                      <CardLotDismembered
                        title={
                          translation.lot.production.dismemberLotPage
                            .destinationLots
                        }
                      >
                        {lotDismember.destinationLots.map((lot) => (
                          <Row type="flex" key={lot.id + lot.name}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              {lot.name}
                            </Col>
                            <Col
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={8}
                              style={{ textAlign: "right" }}
                            >
                              {lot.animals.length}{" "}
                              {lot.animals.length > 1 ? `animais` : `animal`}
                            </Col>
                            <Col
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              style={{ textAlign: "right" }}
                            >
                              <Button
                                onClick={() =>
                                  this.handleRemoveDestinationLot(lot.id)
                                }
                                style={{ border: 0, marginTop: -5 }}
                              >
                                <TrashIcon />
                              </Button>
                            </Col>
                          </Row>
                        ))}
                      </CardLotDismembered>
                    </>
                  ) : null}
                </Col>
              </Row>
            </>
          )}
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  lot: state.lot,
  app: state.app,
  profitCenter: state.profitCenter,
  animal: state.animal,
});

const mapDispatchToProps = (dispatch) => ({
  lotActions: bindActionCreators(LotActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DismemberLot)
);
