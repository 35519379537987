import apiHighProcess from "../config/api_highprocess";
import apiFinancial from "../config/api_financial";
import apiFarms from "../config/api_farms";
import apiAnimals from "../config/api_animals";
import getHeaders from "./getHeaders";
// import axios from "axios";
export function getImportLog(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiHighProcess.get(
    `/bovexo/${groupId}/farms/${farmId}/import/log/${id}`,
    getHeaders(false, signal)
  );
}

export function getAnimalImportLog(payload) {
  const { groupId, farmId, page, size, signal } = payload;

  return apiHighProcess.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/import/log?
    ${page ? `&page=${page - 1}` : ""}
    ${size ? `&size=${size}` : ""}`,
    getHeaders(false, signal)
  );
}

export function getAnimalWeightImportLog(payload) {
  const { groupId, farmId, page, size, signal } = payload;

  return apiHighProcess.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/weights/import/log?
    ${page ? `&page=${page - 1}` : ""}
    ${size ? `&size=${size}` : ""}`,
    getHeaders(false, signal)
  );
}

export function getSupplementWeightImportLog(payload) {
  const { groupId, farmId, page, size, signal } = payload;

  return apiHighProcess.get(
    `/bovexo/${groupId}/farms/${farmId}/supplements/import/log?
    ${page ? `&page=${page - 1}` : ""}
    ${size ? `&size=${size}` : ""}`,
    getHeaders(false, signal)
  );
}

export function getSupplementWeightImportLogSupplierGroup(payload) {
  const { groupId, farmId, page, size, signal } = payload;

  return apiFarms.get(
    `/bovexo/suppliers/groups/${groupId}/farms/${farmId}/imports?
    ${page ? `&page=${page - 1}` : ""}
    ${size ? `&size=${size}` : ""}`,
    getHeaders(false, signal)
  );
}

export function importAnimalWeightHistory(payload) {
  const { groupId, farmId, file, identificationType } = payload;

  let formData = new FormData();
  formData.append("file", file);

  let url = `/bovexo/${groupId}/farms/${farmId}/animals/weights/import`;

  if (identificationType !== null && identificationType !== "") {
    url = url + `?identificationType=${identificationType}`;
  }

  return apiHighProcess.post(url, formData, getHeaders(true));
}

export function importAnimals(payload) {
  const {
    groupId,
    farmId,
    lotId,
    supplierId,
    importType,
    file,
    lotReceiptId,
    lotItemReceiptId,
  } = payload;
  let formData = new FormData();
  formData.append("file", file);

  let url = "";

  if (supplierId != null) {
    url = `?supplierId=${supplierId}`;
  }

  if (lotReceiptId != null) {
    if (url !== "") {
      url = url + "&";
    } else {
      url = "?";
    }

    url =
      url + `lotReceiptId=${lotReceiptId}&lotItemReceiptId=${lotItemReceiptId}`;
  }

  if (importType === "ImportAnimalsForOneLotAndSupplier") {
    return apiHighProcess.post(
      `/bovexo/${groupId}/farms/${farmId}/lots/${lotId}/animals/import${url}`,
      formData,
      getHeaders(true)
    );
  } else if (importType === "ImportAnimalsForDifferentsLotsAndSuppliers") {
    return apiHighProcess.post(
      `/bovexo/${groupId}/farms/${farmId}/animals/import${url}`,
      formData,
      getHeaders(true)
    );
  } else if (importType === "ImportIdentifyAnimals") {
    return apiHighProcess.post(
      `/bovexo/${groupId}/farms/${farmId}/animals/import/identify`,
      formData,
      getHeaders(true)
    );
  }
}

export function importSupplement(payload) {
  const { groupId, farmId, supplierId, file, isInputSupplier } = payload;

  let formData = new FormData();
  formData.append("file", file);

  let url = "";

  if (supplierId != null) {
    url = `?supplierId=${supplierId}`;
  }

  if (isInputSupplier) {
    return apiFarms.post(
      `/bovexo/suppliers/groups/${groupId}/farms/${farmId}/supplements/import${url}`,
      formData,
      getHeaders(true)
    );
  } else {
    return apiFarms.post(
      `/bovexo/${groupId}/farms/${farmId}/supplements/import${url}`,
      formData,
      getHeaders(true)
    );
  }
}

export function deleteImportWeightFileByRequestId(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/animals/import/${id}/delete`,
    {},
    getHeaders(false, signal)
  );
}

export function generateTemplateImportFinancialTransaction(payload) {
  const { groupId, farmId } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/financial/transactions/template/download`,
    getHeaders(false, null, true)
  );
}

export function importFinancialTransaction(payload) {
  const { groupId, farmId, file } = payload;

  let formData = new FormData();
  formData.append("file", file);

  return apiFinancial.post(
    `/bovexo/${groupId}/farms/${farmId}/financial/transactions/import?fileName=BOV_EXO_TEMPLATE`,
    formData,
    getHeaders(true)
  );
}

export function getFinancialTransactionsImportLog(payload) {
  const { groupId, farmId, page, size, signal } = payload;

  return apiHighProcess.get(
    `/bovexo/${groupId}/farms/${farmId}/financial/transactions/import/log?
    ${page ? `&page=${page - 1}` : ""}
    ${size ? `&size=${size}` : ""}`,
    getHeaders(false, signal)
  );
}
