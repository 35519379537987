import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Input, Select, notification, Spin } from "antd";
import ButtonStandard from "../../../utils/button";
import NumberFormat from "react-number-format";
import { Container, Footer, CustomDivider } from "./styles";

const DrawerPastureWeatherSeason = ({
  data,
  onSave,
  onCancel,
  drawerVisible,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(null);
  const [errors, setErrors] = useState([]);
  const { translation } = useSelector((state) => state.app);

  function handleOpenDrawer(visible) {
    if (visible) {
      setIsLoading(true);
      setErrors([]);
      setForm({
        ...data,
        minimumConsumption:
          data.minimumConsumption == null || data.minimumConsumption === 0
            ? data.consumptionType === "PV_ANIMAL"
              ? 2
              : 0
            : data.minimumConsumption,
        maximumConsumption:
          data.maximumConsumption == null || data.maximumConsumption === 0
            ? data.consumptionType === "PV_ANIMAL"
              ? 3
              : 0
            : data.maximumConsumption,
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }

  function onSubmit() {
    let data = form;
    if (validateForm()) {
      data.status = "I";
      onSave(data);
    }
  }

  function validateForm() {
    let data = form;
    /* Validate fields */
    let submitErrors = [];
    if (data.cost === null || data.cost === 0)
      submitErrors = [...submitErrors, "cost"];
    if (data.tdn === null || data.tdn === 0)
      submitErrors = [...submitErrors, "tdn"];
    if (data.cp === null || data.cp === 0)
      submitErrors = [...submitErrors, "cp"];
    if (data.entryHeight === null || data.entryHeight === 0)
      submitErrors = [...submitErrors, "entryHeight"];
    if (data.exitHeight === null || data.exitHeight === 0)
      submitErrors = [...submitErrors, "exitHeight"];
    if (data.rest === null || data.rest === 0)
      submitErrors = [...submitErrors, "rest"];
    if (data.leafDensityProduction === null || data.leafDensityProduction === 0)
      submitErrors = [...submitErrors, "leafDensityProduction"];
    if (data.growthRate === null || data.growthRate === 0)
      submitErrors = [...submitErrors, "growthRate"];
    if (data.maximumConsumption === null || data.maximumConsumption === 0)
      submitErrors = [...submitErrors, "maximumConsumption"];
    if (data.minimumConsumption === null || data.minimumConsumption === 0)
      submitErrors = [...submitErrors, "minimumConsumption"];
    if (data.maximumConsumption < data.minimumConsumption) {
      notification.error({
        message: translation.pasture.errorConsumptionMaximumLessThanMinimum,
      });
      submitErrors = [...submitErrors, "maximumConsumption"];
    }
    if (data.maximumHeight === null || data.maximumHeight === 0)
      submitErrors = [...submitErrors, "maximumHeight"];
    setErrors(submitErrors);
    return submitErrors.length > 0 ? false : true;
  }

  const selectConsumption = (
    <Select
      value={form !== null ? form.consumptionType : "PV_ANIMAL"}
      style={{ width: 120 }}
      onChange={(value) => {
        setForm({
          ...form,
          consumptionType: value,
          minimumConsumption:
            value === "PV_ANIMAL"
              ? form.minimumConsumption == null || form.minimumConsumption === 0
                ? 2
                : form.minimumConsumption
              : value === "KG" && form.minimumConsumption === 2
                ? 0
                : form.minimumConsumption,
          maximumConsumption:
            value === "PV_ANIMAL"
              ? form.maximumConsumption == null || form.maximumConsumption === 0
                ? 3
                : form.maximumConsumption
              : value === "KG" && form.maximumConsumption === 3
                ? 0
                : form.maximumConsumption,
        });
      }}
    >
      <Select.Option value="PV_ANIMAL">%PV</Select.Option>
      <Select.Option value="KG">kg</Select.Option>
    </Select>
  );

  return (
    <Container
      title={
        <span>
          {`Dados da forrageira em `}
          <strong
            className={
              form != null
                ? form?.season === "Water"
                  ? "water"
                  : form.season === "Transition"
                    ? "transition"
                    : form.season === "Dry"
                      ? "dry"
                      : ""
                : ""
            }
          >
            {form != null
              ? form?.season === "Water"
                ? "Águas"
                : form.season === "Transition"
                  ? "Transição"
                  : form.season === "Dry"
                    ? "Seca"
                    : ""
              : " - "}
          </strong>
          {`: Forrageira `}
          <strong>
            {form != null
              ? form?.fertilized
                ? "adubado"
                : "não adubado"
              : " - "}
          </strong>
        </span>
      }
      width={511}
      onClose={onCancel}
      maskClosable={false}
      visible={drawerVisible}
      afterVisibleChange={handleOpenDrawer}
    >
      <Spin spinning={isLoading}>
        <div className="drawerForm">
          {errors.length > 0 && (
            <Row type="flex" justify="center" align="middle">
              <label className="error">{translation.error.formError}</label>
            </Row>
          )}
          {/* Body */}
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Row>
                <label className={errors.includes("cost") ? "error" : ""}>
                  {translation.pasture.form.cost}*
                </label>
              </Row>
              <Row className="rowInput">
                <NumberFormat
                  customInput={Input}
                  placeholder={translation.pasture.form.costPlaceHolder}
                  value={form !== null && form.cost}
                  name="cost"
                  addonBefore="R$"
                  addonAfter="kg"
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      cost: floatValue >= 0 ? floatValue : null,
                    });
                    setErrors(errors.filter((e) => e !== "cost"));
                  }}
                />
              </Row>
            </Col>
          </Row>
          <CustomDivider>
            {translation.pasture.form.nutritionalData}
          </CustomDivider>
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            {/* NDT */}
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Row>
                <label className={errors.includes("tdn") ? "error" : ""}>
                  {translation.pasture.form.tdn}*
                </label>
              </Row>
              <Row className="rowInput">
                <NumberFormat
                  customInput={Input}
                  placeholder={translation.pasture.form.tdnPlaceHolder}
                  value={form !== null && form.tdn}
                  name="tdn"
                  addonAfter="g/kg"
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      tdn: floatValue >= 0 ? floatValue : null,
                    });
                    setErrors(errors.filter((e) => e !== "tdn"));
                  }}
                />
              </Row>
            </Col>
            {/* PB */}
            <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
              <Row>
                <label className={errors.includes("cp") ? "error" : ""}>
                  {translation.pasture.form.cp}*
                </label>
              </Row>
              <Row className="rowInput">
                <NumberFormat
                  customInput={Input}
                  placeholder={translation.pasture.form.cpPlaceHolder}
                  value={form !== null && form.cp}
                  name="cp"
                  addonAfter="g/kg"
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      cp: floatValue >= 0 ? floatValue : null,
                    });
                    setErrors(errors.filter((e) => e !== "cp"));
                  }}
                />
              </Row>
            </Col>
          </Row>
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            {/* FDN */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <label>{translation.pasture.form.fdn}</label>
              </Row>
              <Row className="rowInput">
                <NumberFormat
                  customInput={Input}
                  placeholder={translation.pasture.form.fdnPlaceHolder}
                  style={{ width: 170 }}
                  value={form !== null && form.fdn}
                  name="fdn"
                  addonAfter="%"
                  decimalScale={0}
                  decimalSeparator=","
                  thousandSeparator="."
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      fdn: floatValue >= 0 ? floatValue : null,
                    });
                  }}
                />
              </Row>
            </Col>
          </Row>
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            {/* CALCIO */}
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
              <Row>
                <label>{translation.pasture.form.calcium}</label>
              </Row>
              <Row className="rowInput">
                <NumberFormat
                  customInput={Input}
                  placeholder={translation.pasture.form.calciumPlaceHolder}
                  value={form !== null && form.calcium}
                  name="calcium"
                  addonAfter="g/kg"
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      calcium: floatValue >= 0 ? floatValue : null,
                    });
                  }}
                />
              </Row>
            </Col>
            {/* FOSFORO */}
            <Col xs={7} sm={7} md={7} lg={7} xl={7} offset={1}>
              <Row>
                <label>{translation.pasture.form.phosphorus}</label>
              </Row>
              <Row className="rowInput">
                <NumberFormat
                  customInput={Input}
                  placeholder={translation.pasture.form.phosphorusPlaceHolder}
                  value={form !== null && form.phosphorus}
                  name="phosphorus"
                  addonAfter="g/kg"
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      phosphorus: floatValue >= 0 ? floatValue : null,
                    });
                  }}
                />
              </Row>
            </Col>
            {/* NITROGENIO */}
            <Col xs={7} sm={7} md={7} lg={7} xl={7} offset={1}>
              <Row>
                <label>{translation.pasture.form.nitrogen}</label>
              </Row>
              <Row className="rowInput">
                <NumberFormat
                  customInput={Input}
                  placeholder={translation.pasture.form.nitrogenPlaceHolder}
                  value={form !== null && form.nitrogen}
                  name="nitrogen"
                  addonAfter="mg/kg"
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      nitrogen: floatValue >= 0 ? floatValue : null,
                    });
                  }}
                />
              </Row>
            </Col>
          </Row>
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            {/* ZINCO */}
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
              <Row>
                <label>{translation.pasture.form.zinc}</label>
              </Row>
              <Row className="rowInput">
                <NumberFormat
                  customInput={Input}
                  placeholder={translation.pasture.form.zincPlaceHolder}
                  value={form !== null && form.zinc}
                  name="zinc"
                  addonAfter="mg/kg"
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      zinc: floatValue >= 0 ? floatValue : null,
                    });
                  }}
                />
              </Row>
            </Col>
            {/* SODIO */}
            <Col xs={7} sm={7} md={7} lg={7} xl={7} offset={1}>
              <Row>
                <label>{translation.pasture.form.sodium}</label>
              </Row>
              <Row className="rowInput">
                <NumberFormat
                  customInput={Input}
                  placeholder={translation.pasture.form.sodiumPlaceHolder}
                  value={form !== null && form.sodium}
                  name="sodium"
                  addonAfter="g/kg"
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      sodium: floatValue >= 0 ? floatValue : null,
                    });
                  }}
                />
              </Row>
            </Col>
          </Row>
          <CustomDivider>CAPACIDADE DE CRESCIMENTO ESPERADO</CustomDivider>
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            {/* FORMAÇÃO */}
            <Col s={12} sm={12} md={12} lg={12} xl={12}>
              <Row>
                <label>{translation.pasture.form.formation}</label>
              </Row>
              <Row className="rowInput">
                <NumberFormat
                  customInput={Input}
                  value={form !== null && form.formation}
                  placeholder={translation.pasture.formationPlaceHolder}
                  name="formation"
                  addonAfter="dias"
                  decimalScale={0}
                  decimalSeparator=","
                  thousandSeparator="."
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      formation: floatValue >= 0 ? floatValue : null,
                    });
                  }}
                />
              </Row>
            </Col>
            {/* DESCANSO */}
            <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
              <Row>
                <label className={errors.includes("rest") ? "error" : ""}>
                  {translation.pasture.form.rest}*
                </label>
              </Row>
              <Row className="rowInput">
                <NumberFormat
                  customInput={Input}
                  value={form !== null && form.rest}
                  placeholder={translation.pasture.restPlaceHolder}
                  addonAfter="dias"
                  name="rest"
                  decimalScale={0}
                  decimalSeparator=","
                  thousandSeparator="."
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      rest: floatValue >= 0 ? floatValue : null,
                    });
                    setErrors(errors.filter((e) => e !== "rest"));
                  }}
                />
              </Row>
            </Col>
          </Row>
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            {/* ALTURA DE ENTRADA */}
            <Col s={12} sm={12} md={12} lg={12} xl={12}>
              <Row>
                <label
                  className={errors.includes("entryHeight") ? "error" : ""}
                >
                  {translation.pasture.form.entryHeight}*
                </label>
              </Row>
              <Row className="rowInput">
                <NumberFormat
                  customInput={Input}
                  value={form !== null && form.entryHeight}
                  placeholder={translation.pasture.entryHeightPlaceHolder}
                  name="entryHeight"
                  addonAfter="cm"
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      entryHeight: floatValue >= 0 ? floatValue : null,
                    });
                    setErrors(errors.filter((e) => e !== "entryHeight"));
                  }}
                />
              </Row>
            </Col>
            {/* ALTURA DE SAÍDA */}
            <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
              <Row>
                <label className={errors.includes("exitHeight") ? "error" : ""}>
                  {translation.pasture.form.exitHeight}*
                </label>
              </Row>
              <Row className="rowInput">
                <NumberFormat
                  customInput={Input}
                  value={form !== null && form.exitHeight}
                  placeholder={translation.pasture.exitHeightPlaceHolder}
                  addonAfter="cm"
                  name="exitHeight"
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      exitHeight: floatValue >= 0 ? floatValue : null,
                    });
                    setErrors(errors.filter((e) => e !== "exitHeight"));
                  }}
                />
              </Row>
            </Col>
          </Row>
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            {/* TAXA DE CRESCIMENTO */}
            <Col s={12} sm={12} md={12} lg={12} xl={12}>
              <Row>
                <label className={errors.includes("growthRate") ? "error" : ""}>
                  {translation.pasture.form.growthRate}*
                </label>
              </Row>
              <Row className="rowInput">
                <NumberFormat
                  customInput={Input}
                  value={form !== null && form.growthRate}
                  placeholder={translation.pasture.growthRatePlaceHolder}
                  name="growthRate"
                  addonAfter="kg/ha/dia"
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      growthRate: floatValue >= 0 ? floatValue : null,
                    });
                    setErrors(errors.filter((e) => e !== "growthRate"));
                  }}
                />
              </Row>
            </Col>
            {/* PRODUÇÃO MS FOLIAR (t/ha/corte) */}
            <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
              <Row>
                <label
                  className={
                    errors.includes("leafDensityProduction") ? "error" : ""
                  }
                >
                  {translation.pasture.form.leafDensityProduction}*
                </label>
              </Row>
              <Row className="rowInput">
                <NumberFormat
                  customInput={Input}
                  value={form !== null && form.leafDensityProduction}
                  placeholder={
                    translation.pasture.leafDensityProductionPlaceHolder
                  }
                  addonAfter="t/ha/corte"
                  name="leafDensityProduction"
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      leafDensityProduction:
                        floatValue >= 0 ? floatValue : null,
                    });
                    setErrors(
                      errors.filter((e) => e !== "leafDensityProduction")
                    );
                  }}
                />
              </Row>
            </Col>
          </Row>
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            {/* ALTURA MÁXIMA */}
            <Col s={12} sm={12} md={12} lg={12} xl={12}>
              <Row>
                <label
                  className={errors.includes("maximumHeight") ? "error" : ""}
                >
                  {translation.pasture.form.maximumHeight}*
                </label>
              </Row>
              <Row className="rowInput">
                <NumberFormat
                  customInput={Input}
                  value={form !== null && form.maximumHeight}
                  placeholder={translation.pasture.maximumHeightPlaceHolder}
                  name="maximumHeight"
                  addonAfter="cm"
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      maximumHeight: floatValue >= 0 ? floatValue : null,
                    });
                    setErrors(errors.filter((e) => e !== "maximumHeight"));
                  }}
                />
              </Row>
            </Col>
          </Row>
          <CustomDivider>CAPACIDADE POR CONSUMO ESPERADO</CustomDivider>
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            {/* MINIMU CONSUMPTION */}
            <Col s={12} sm={12} md={12} lg={12} xl={12}>
              <Row>
                <label
                  className={
                    errors.includes("minimumConsumption") ? "error" : ""
                  }
                >
                  {translation.pasture.form.minimumConsumption}*
                </label>
              </Row>
              <Row className="rowInput">
                <NumberFormat
                  customInput={Input}
                  value={form !== null && form.minimumConsumption}
                  name="minimumConsumption"
                  addonAfter={selectConsumption}
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      minimumConsumption: floatValue >= 0 ? floatValue : null,
                    });
                    setErrors(errors.filter((e) => e !== "minimumConsumption"));
                  }}
                />
              </Row>
            </Col>
            {/* MAXIMUM CONSUMPTION */}
            <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
              <Row>
                <label
                  className={
                    errors.includes("maximumConsumption") ? "error" : ""
                  }
                >
                  {translation.pasture.form.maximumConsumption}*
                </label>
              </Row>
              <Row className="rowInput">
                <NumberFormat
                  customInput={Input}
                  value={form !== null && form.maximumConsumption}
                  addonAfter={selectConsumption}
                  name="maximumConsumption"
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  onValueChange={({ floatValue }) => {
                    setForm({
                      ...form,
                      maximumConsumption: floatValue >= 0 ? floatValue : null,
                    });
                    setErrors(errors.filter((e) => e !== "maximumConsumption"));
                  }}
                />
              </Row>
            </Col>
          </Row>
        </div>
      </Spin>
      {/* Footer */}
      <Footer>
        <Row type="flex" justify="space-between">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="selectDiv"
          ></Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="buttonsDiv">
            <ButtonStandard type="button" buttonType="type7" onClick={onCancel}>
              {translation.buttons.cancel}
            </ButtonStandard>

            <ButtonStandard
              type="submit"
              buttonType="type6"
              onClick={onSubmit}
              disabled={errors.length > 0 ? true : false}
            >
              {translation.buttons.save}
            </ButtonStandard>
          </Col>
        </Row>
      </Footer>
    </Container>
  );
};

export default DrawerPastureWeatherSeason;
