import { call, put } from "redux-saga/effects";
import { Creators as SupplementActions } from "../ducks/supplement";
/** Services */
import {
  getSupplementIndex,
  getSupplementShow,
  saveSupplement,
  deleteSupplementServ,
} from "../../services/supplementService";
import { notification } from "antd";

export function* indexSupplement(action) {
  try {
    const {
      data: { results: allSupplements },
    } = yield call(getSupplementIndex, action.payload);
    yield put(SupplementActions.indexSupplementSuccess(allSupplements));
  } catch (error) {
    yield put(SupplementActions.indexSupplementError(error));
  }
}

export function* getSupplement(action) {
  try {
    const {
      data: { results: supplement },
    } = yield call(getSupplementShow, action.payload);
    supplement.baseSupplement =
      supplement.groupId == null && supplement.farmId == null;
    let sustenanceCapacityData = {
      showMix: supplement.type === "Supplement" ? false : true,
      minimumVoluntaryConsumption: supplement.minimumVoluntaryConsumption,
      maximumVoluntaryConsumption: supplement.maximumVoluntaryConsumption,
      voluntaryConsumptionType: supplement.voluntaryConsumptionType,
      minimumPure: supplement.minimumPure,
      minimumPureTrough: supplement.minimumPureTrough,
      maximumPure: supplement.maximumPure,
      maximumPureTrough: supplement.maximumPureTrough,
      pureConsumptionType: supplement.pureConsumptionType,
      minimumMix: supplement.minimumMix,
      minimumMixTrough: supplement.minimumMixTrough,
      maximumMix: supplement.maximumMix,
      maximumMixTrough: supplement.maximumMixTrough,
      mixConsumptionType: supplement.mixConsumptionType,
      mixRatio: supplement.mixRatio,
      status: "I",
    };
    yield put(
      SupplementActions.showSupplementSuccess(
        supplement,
        sustenanceCapacityData
      )
    );
  } catch (error) {
    yield put(SupplementActions.showSupplementError(error));
  }
}

export function* storeOrUpdateSupplement(action) {
  let result = null;
  try {
    const {
      data: { results: newSupplement },
    } = yield call(saveSupplement, action.payload);
    result = newSupplement;
    const { saveOptions } = action.payload;
    yield put(
      SupplementActions.saveSupplementSuccess(newSupplement, saveOptions)
    );
    if (action.payload.id == null)
      notification.success({
        message: "Suplemento/Concent. criado com sucesso",
      });
    else
      notification.success({
        message: "Suplemento/Concent. atualizado com sucesso",
      });
  } catch (error) {
    notification.error({ message: "Suplemento/Concent. não foi criado" });
    yield put(SupplementActions.saveSupplementError(error));
  }

  if (result != null && Object.entries(result).length > 0) {
    const newPayload = {
      ...action.payload,
      page: 1,
      size: 10,
      filters: "",
      sorter: {},
    };
    yield put(
      SupplementActions.indexSupplement(
        newPayload.groupId,
        newPayload.farmId,
        newPayload.page,
        newPayload.sorter,
        newPayload.filters,
        newPayload.size,
        newPayload.isInputSupplier,
      )
    );
  }
}

export function* deleteSupplement(action) {
  try {
    const { data } = yield call(deleteSupplementServ, action.payload);
    yield put(SupplementActions.deleteSupplementSuccess(data));
    notification.success({
      message: "Suplemento/Concent. inativado com sucesso",
    });
  } catch (error) {
    notification.error({ message: "Suplemento/Concent. não foi inativado" });
    yield put(SupplementActions.deleteSupplementError(error));
  }
}
