import styled from "styled-components";
import { Divider, Card, Button } from "antd";

export const Container = styled.div`
  padding-bottom: 10px !important;

  .table-row-red {
    color: rgb(212, 76, 76);
  }

  div.pageHeader {
    padding-top: 24px;
    padding-right: 5px;
  }

  div.pageTree {
    /* margin-bottom: 22px !important; */
    /* margin-top: 9px;
    margin-bottom: 9px; */
  }

  span.backIcon {
    vertical-align: middle;
  }

  span.pageTreeInative {
    color: #c4c4c4;
    font-size: 16px;
  }
  span.pageTreeActive {
    color: #684e94;
    font-size: 16px;
  }

  .buttonsCol {
    button {
      &:first-of-type {
        margin-right: 19px;
      }
    }
  }

  .pageBody {
    padding: 20px 5px 0px 5px;
  }

  .columnDememberingInfo {
    padding: 24px;
    padding-top: 0px;
    height: 550px;
    overflow-y: auto;
  }

  .buttonsTable {
    button:not(:first-of-type) {
      margin-left: 8px;
    }
  }

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const Title = styled.span`
  font-family: "Asap", sans-serif;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  color: #4b4b4b;

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const DescriptionMessage = styled.span`
  font-family: "Asap", sans-serif;
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 18px;
  text-align: left;
  color: #4b4b4b;

  svg {
    margin-left: 6px;
  }

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const ButtonSelectAllAnimals = styled(Button)`
  border-radius: 5px !important;
  background: transparent !important;
  border: 1px solid #4a85ae !important;
  font-family: Asap !important;
  font-weight: normal !important;
  font-size: 14px !important;
  text-align: left !important;
  color: #4a85ae !important;
  margin: 4px 0px;
  cursor: pointer;
`;

export const ButtonDeselectAllAnimals = styled(Button)`
  border-radius: 5px !important;
  background: transparent !important;
  border: 1px solid #d44c4c !important;
  font-family: Asap !important;
  font-weight: normal !important;
  font-size: 14px !important;
  text-align: left !important;
  color: #d44c4c !important;
  margin: 4px 0px;
  cursor: pointer;
`;

export const ColSelectAnimals = styled.div`
  height: auto;
  /* min-height: 562px; */
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  background: #fff;
  box-shadow: 0px 0px 10px #d8d8d8;
  .ant-table-pagination.ant-pagination {
    margin-right: 10px;
  }
  .header {
    width: 100%;
    height: 60px;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;

    span.listAnimals {
      font-family: Asap;
      font-weight: bold;
      font-size: 18px;
      text-align: left;
      color: #4b4b4b;
    }
    .ant-radio-button-wrapper {
      color: #684e94 !important;
      background: #fff;
      border-color: #684e94 !important;
      box-shadow: -1px 0 0 0 #684e94 !important;
      font-size: 14px;
      font-weight: bold;
    }
    .ant-radio-button-wrapper-checked {
      color: #fff !important;
      background: #684e94;
      border-color: #684e94 !important;
      box-shadow: -1px 0 0 0 #684e94 !important;
      font-size: 14px;
      font-weight: bold;
    }
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;

export const TagStatus = styled.span`
  padding: 2px 5px !important;
  border-radius: 5px !important;
  color: ${(props) => props.color}!important;
  font-size: 12px !important;
  font-weight: bold !important;
  border: ${(props) => `1px solid ${props.borderColor}`};
  background: ${(props) => props.background} !important;
`;

export const CustomDivider = styled(Divider)`
  &.ant-divider-dashed {
    border-color: #a5a5a5 !important;
    border-width: 2px 0 0 !important;
  }
`;

export const CardGroupAnimals = styled(Card)`
  border-radius: 10px !important;
  background: #fff !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;

  .ant-card-head-title {
    height: 40px !important;
    background: #4b4b4b !important;
    color: #fff;
    padding: 12px 0;
  }

  .ant-card-head {
    background: #4b4b4b !important;
    border-radius: 10px 10px 0 0 !important;
  }
`;

export const ButtonProperty = styled(Button)`
  background: #c4c4c4 !important;
  border-color: #c4c4c4 !important;
  font-family: Asap !important;
  font-weight: bolder !important;
  font-size: 14px !important;
  color: #ffffff !important;
  text-align: center !important;
  margin-top: 6px;
  border-radius: 10px !important;
  cursor: not-allowed !important;

  &.enabled {
    background: #684e94 !important;
    border-color: none !important;
    color: #fff !important;
    font-weight: bolder !important;
    cursor: pointer !important;
  }
`;
