import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import Axios from "axios";
// Redux
import { useSelector } from "react-redux";
// Components
import { Row, Col, Icon, Spin, Tooltip } from "antd";
import {
  Container,
  Header,
  Main,
  ColLots,
  ColLotsItem,
  ColDashboards,
} from "./styles";
import EyeIcon from "../../../components/utils/icons/eye";
import LotAverageWeightDash from "../dashboards/lotAverageWeightDash";
import LotAverageGdpDash from "../dashboards/lotAveragGdpDash";
import LotProfitDash from "../dashboards/lotProfitDash";
// Services
import {
  getLotAverageWeightAndGdpDashboard,
  getLotProfitAndCostDashboard,
} from "../../../services/dashboards/dashboardLotService";
import { returnObjectFromAxis } from "../../../services/dashboards/dashboardCommonService";
import { getLotIndex } from "../../../services/lotService";
import { getTwoDecimalDigits } from "../../../services/helpersMethodsService";

function CompareLots() {
  // Variables
  const [isLoading, setIsLoading] = useState(false);
  const [lots, setLots] = useState([]);
  const [lotOpenDetails, setLotOpenDetails] = useState([]);
  const [lotShowDash, setLotShowDash] = useState([]);
  const [dataLotProfit, setDataLotProfit] = useState(null);
  const [dataLotAverageWeight, setDataLotAverageWeight] = useState([]);
  const [dataLotAverageGdp, setDataLotAverageGdp] = useState([]);
  // Variable Redux
  const { compareLots } = useSelector((state) => state.lot);
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
    groupSelected,
    farmSelected,
  } = useSelector((state) => state.app);

  // Effects
  // When open page set all lots to be visible on dashboards
  useEffect(() => {
    let signal = Axios.CancelToken.source();
    let mount = true;
    async function fetchData() {
      const { id: groupId } = groupSelected;
      const { id: farmId } = farmSelected;
      const ids = compareLots.map((l) => `'${l}'`).join(",");
      const size = compareLots.length;
      const search = `id in (${ids})`;
      try {
        const {
          data: {
            results: { content },
          },
        } = await getLotIndex({
          groupId,
          farmId,
          page: 0,
          sorter: { field: "createdAt", order: "descend" },
          filters: search,
          size,
          signal,
        });
        if (mount) {
          setLots(content);
          setLotShowDash(content);
        }
      } catch (error) {
        if (mount) {
          setLots([]);
          setLotShowDash([]);
        }
      }
    }
    if (
      compareLots.length > 0 &&
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      fetchData();
    } else {
      setLots([]);
      setLotShowDash([]);
    }
    return () => {
      mount = false;
      signal.cancel();
    };
  }, [compareLots, groupSelected, farmSelected]);

  // Get dashboards data
  useEffect(() => {
    let signal = Axios.CancelToken.source();
    let mount = true;
    async function getLotAverageGdp() {
      setIsLoading(true);
      try {
        if (lotShowDash.length === 0) {
          if (mount) {
            setDataLotAverageGdp([]);
            setDataLotAverageWeight([]);
            setIsLoading(false);
          }
        } else {
          const promises = lotShowDash.map(async (l, i) => {
            try {
              const {
                data: { results },
              } = await getLotAverageWeightAndGdpDashboard({
                groupId,
                farmId,
                dashboardParams: {
                  LotId: l.id,
                },
                signal: signal,
              });
              if (results != null) {
                let data = returnObjectFromAxis(results);
                let gdpData = data.map((x) => ({
                  x: x.x,
                  y: getTwoDecimalDigits(x.AverageGdp),
                }));
                let weightData = data.map((x) => ({
                  x: x.x,
                  y: [
                    getTwoDecimalDigits(x.MinWeight),
                    getTwoDecimalDigits(x.MaxWeight),
                  ],
                }));
                let dLoteGdp = {
                  type: "line",
                  name: l.name,
                  xValueFormatString: "MM/YY",
                  showInLegend: true,
                  dataPoints: gdpData,
                };
                let dLote = {
                  type: "rangeSplineArea",
                  name: l.name,
                  xValueFormatString: "MM/YY",
                  showInLegend: true,
                  toolTipContent:
                    "<strong style='\"'color: {color};'\"'>{x}</strong><br/></strong><strong style='\"'color: {color};'\"'>{name}: </strong> <strong style='\"'color: {color};'\"'>Animal + Pesado: </strong>{y[1]} Kg / <strong style='\"'color: {color};'\"'>+ Leve: </strong>{y[0]} Kg",
                  dataPoints: weightData,
                };
                return [dLoteGdp, dLote];
              } else {
                let dLoteGdp = {
                  type: "line",
                  name: l.name,
                  xValueFormatString: "MM/YY",
                  showInLegend: true,
                  dataPoints: [],
                };
                let dLote = {
                  type: "rangeSplineArea",
                  name: l.name,
                  xValueFormatString: "MM/YY",
                  showInLegend: true,
                  toolTipContent:
                    "<strong style='\"'color: {color};'\"'>{x}</strong><br/></strong><strong style='\"'color: {color};'\"'>{name}: </strong> <strong style='\"'color: {color};'\"'>Animal + Pesado: </strong>{y[1]} Kg / <strong style='\"'color: {color};'\"'>+ Leve: </strong>{y[0]} Kg",
                  dataPoints: [],
                };
                return [dLoteGdp, dLote];
              }
            } catch (error) {}
          });
          const chartData = await Promise.all(promises);
          const averageGdp = chartData.map((c) => c[0]);
          const averageWeight = chartData.map((c) => c[1]);
          if (mount) {
            setDataLotAverageGdp(averageGdp);
            setDataLotAverageWeight(averageWeight);
            setIsLoading(false);
          }
        }
      } catch (error) {
        if (mount) {
          setIsLoading(false);
        }
      }
    }
    async function getLotProfit() {
      setIsLoading(true);
      try {
        if (lotShowDash.length === 0) {
          if (mount) {
            setDataLotProfit([]);
            setIsLoading(false);
          }
        } else {
          let chartData = [];
          const promises = lotShowDash.map(async (l, i) => {
            let listEstimatedProfitForSoldAnimals = {
              label: l.name,
              y: 0,
            };
            let listLoss = {
              label: l.name,
              y: 0,
            };
            let listRealProfit = {
              label: l.name,
              y: 0,
            };
            try {
              const {
                data: { results },
              } = await getLotProfitAndCostDashboard({
                groupId,
                farmId,
                dashboardParams: {
                  LotId: l.id,
                },
                signal: signal,
              });
              if (results != null) {
                let data = returnObjectFromAxis(results);
                listEstimatedProfitForSoldAnimals = {
                  label: l.name,
                  y: getTwoDecimalDigits(data[0].y),
                };
                if (data.filter((x) => x.y2 < 0).length > 0) {
                  listLoss = {
                    label: l.name,
                    y: getTwoDecimalDigits(data[0].y2),
                  };
                } else {
                  listRealProfit = {
                    label: l.name,
                    y: getTwoDecimalDigits(data[0].y2),
                  };
                }
              }
            } catch (error) {}
            return [
              listEstimatedProfitForSoldAnimals,
              listRealProfit,
              listLoss,
            ];
          });
          const promisesData = await Promise.all(promises);
          if (promisesData.filter((pd) => pd !== null).length > 0) {
            const dataPointEstimatedProfit = promisesData.map((pd) => pd[0]);
            const dataPointRealProfit = promisesData.map((pd) => pd[1]);
            const dataPointLoss = promisesData.map((pd) => pd[2]);
            chartData = [
              {
                type: "column",
                name: "Lucratividade planejado dos animais vendidos",
                color: "#77F26E",
                showInLegend: true,
                yValueFormatString: "R$ ###0.00",
                dataPoints: dataPointEstimatedProfit,
              },
              {
                type: "column",
                name: "Lucro real",
                color: "#3CB16C",
                showInLegend: true,
                yValueFormatString: "R$ ###0.00",
                dataPoints: dataPointRealProfit,
              },
              {
                type: "column",
                name: "Prejuízo",
                color: "#BD4848",
                showInLegend: true,
                yValueFormatString: "R$ ###0.00",
                dataPoints: dataPointLoss,
              },
            ];
          }
          if (mount) {
            setDataLotProfit(chartData);
            setIsLoading(false);
          }
        }
      } catch (error) {
        if (mount) {
          setIsLoading(false);
        }
      }
    }
    if (groupId != null && farmId != null) {
      getLotAverageGdp();
      getLotProfit();
    } else {
      setDataLotAverageGdp([]);
      setDataLotAverageWeight([]);
      setDataLotProfit([]);
    }
    return () => {
      mount = false;
      signal.cancel();
    };
  }, [lotShowDash, groupId, farmId]);

  // Methods
  function openDetails(id) {
    let details = lotOpenDetails;
    if (details.includes(id)) {
      details = details.filter((d) => d !== id);
    } else {
      details = [...details, id];
    }
    setLotOpenDetails(details);
  }
  function showOnDash(lot) {
    let show = lotShowDash;
    if (show.includes(lot)) {
      show = show.filter((d) => d.id !== lot.id);
    } else {
      show = [...show, lot];
    }
    setLotShowDash(show);
  }
  return (
    <Container>
      <Header>
        <Row type="flex" align="middle">
          <Col span={4}>
            <Link className="back-container" to="/admin/lots">
              <Icon type="left-circle" theme="filled" /> Voltar
            </Link>
            <span className="title">Comparar Lotes</span>
          </Col>
        </Row>
      </Header>
      <Main>
        <Row type="flex">
          {/* Col select lots */}
          <Col span={6}>
            <ColLots>
              <ColLotsItem>
                <div className="main">
                  <div className="headerContent">
                    <span className="lotName">Lotes selecionados</span>
                  </div>
                </div>
              </ColLotsItem>
              {lots?.length > 0 &&
                lots?.map((lot) => (
                  <ColLotsItem key={lot.id}>
                    <div
                      className={
                        lotOpenDetails.includes(lot.id) === true
                          ? "main active"
                          : "main"
                      }
                    >
                      <div className="headerContent">
                        <span className="lotName">
                          {lot.name?.length > 20
                            ? `${lot.name.substr(0, 20)}...`
                            : lot.name}
                        </span>
                        <div className="icons">
                          <button onClick={() => showOnDash(lot)}>
                            <EyeIcon
                              isActive={
                                lotShowDash.find((l) => l.id === lot.id) != null
                                  ? true
                                  : false
                              }
                            />
                          </button>
                          <Icon
                            id="arrowIcon"
                            type={
                              lotOpenDetails.includes(lot.id)
                                ? "down-circle"
                                : "right-circle"
                            }
                            onClick={() => openDetails(lot.id)}
                          />
                        </div>
                      </div>
                      <div className="details">
                        <Row type="flex" justify="space-between">
                          <Col>
                            <span>Piquete / Baia</span>
                          </Col>
                          {lot.picketName?.length > 20 ? (
                            <Col>
                              <Tooltip title={lot.picketName}>
                                <strong>
                                  {`${lot.picketName?.substr(0, 20)}...`}
                                </strong>
                              </Tooltip>
                            </Col>
                          ) : (
                            <Col>
                              <strong>{lot.picketName}</strong>
                            </Col>
                          )}
                        </Row>
                        <Row type="flex" justify="space-between">
                          <Col>
                            <span>Est. de Dieta</span>
                          </Col>
                          {lot?.dietStrategies?.find(
                            (ds) => ds.baseline === true
                          )?.dietStrategyName.length > 20 ? (
                            <Col>
                              <Tooltip
                                title={
                                  lot.dietStrategies.find(
                                    (ds) => ds.baseline === true
                                  ).dietStrategyName
                                }
                              >
                                <strong>
                                  {`${lot.dietStrategies
                                    .find((ds) => ds.baseline === true)
                                    .dietStrategyName.substr(0, 20)}...`}
                                </strong>
                              </Tooltip>
                            </Col>
                          ) : (
                            <Col>
                              <strong>
                                {
                                  lot.dietStrategies.find(
                                    (ds) => ds.baseline === true
                                  )?.dietStrategyName
                                }
                              </strong>
                            </Col>
                          )}
                        </Row>
                        <Row type="flex" justify="space-between">
                          <Col>
                            <span>Cenário de Venda</span>
                          </Col>
                          {lot.saleScenarioName?.length > 20 ? (
                            <Col>
                              <Tooltip title={lot.saleScenarioName}>
                                <strong>
                                  {`${lot.saleScenarioName?.substr(0, 20)}...`}
                                </strong>
                              </Tooltip>
                            </Col>
                          ) : (
                            <Col>
                              <strong>{lot.saleScenarioName}</strong>
                            </Col>
                          )}
                        </Row>
                      </div>
                    </div>
                  </ColLotsItem>
                ))}
            </ColLots>
          </Col>
          {/* Col dashboards */}
          <Col span={18}>
            <ColDashboards>
              <Spin spinning={isLoading}>
                <LotAverageGdpDash externalData={dataLotAverageGdp} />
                <LotAverageWeightDash externalData={dataLotAverageWeight} />
                <LotProfitDash externalData={dataLotProfit} />
              </Spin>
            </ColDashboards>
          </Col>
        </Row>
      </Main>
    </Container>
  );
}

export default withRouter(CompareLots);
