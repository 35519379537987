import React from 'react';

const StarIcon = ( {color, size} ) => (
   <svg xmlns="http://www.w3.org/2000/svg" width={size} height="14.375" viewBox="0 0 15 14.375">
      <path id="star" d="M14.961,5.923a.8.8,0,0,0-.686-.548L9.945,4.982,8.233.975a.8.8,0,0,0-1.466,0L5.055,4.982l-4.33.393a.8.8,0,0,0-.453,1.4L3.545,9.64,2.58,13.891a.8.8,0,0,0,1.186.861L7.5,12.52l3.733,2.232a.8.8,0,0,0,1.186-.861L11.455,9.64l3.272-2.869a.8.8,0,0,0,.234-.847Zm0,0" 
      transform="translate(0 -0.491)" fill={color}/>
   </svg>
);


export default StarIcon;

