import apiFinancial from "../config/api_financial";
import getHeaders from "./getHeaders";

export function getBoitelPricesIndex(payload) {
  const { groupId, farmId } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/boitel/prices`,
    getHeaders(false)
  );
}

export function getBoitelPricesIndexActives(payload) {
  const { groupId, farmId } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/boitel/prices/actives`,
    getHeaders(false)
  );
}

export function getBoitelPricesCurrentDropDown(payload) {
  const { groupId, farmId } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/boitel/prices/current`,
    getHeaders(false)
  );
}

export function getBoitelPricesHistoryDropDown(payload) {
  const { groupId, farmId } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/boitel/prices/history`,
    getHeaders(false)
  );
}

export function showBoitelPrices(payload) {
  const { groupId, farmId, boitelPriceId } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/boitel/prices/${boitelPriceId}`,
    getHeaders(false)
  );
}

export function getBoitelPricesByIdAndValidityId(payload) {
  const { groupId, farmId, boitelPriceId, boitelPriceValidityId } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/boitel/prices/${boitelPriceId}/validities/${boitelPriceValidityId}`,
    getHeaders(false)
  );
}

export function getHistoryBoitelPrice(payload) {
  const { groupId, farmId, boitelPriceId } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/boitel/prices/${boitelPriceId}/history`,
    getHeaders(false)
  );
}

export function saveBoitelPrices(payload) {
  const { groupId, farmId, boitelPriceId, body } = payload;

  return apiFinancial.post(
    `/bovexo/${groupId}/farms/${farmId}/boitel/prices/update/${boitelPriceId}`,
    body,
    getHeaders(false)
  );
}
