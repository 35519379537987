import styled from "styled-components";

export const Container = styled.div`
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 2px;
  margin-right: 2px;
  padding-left: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const Title = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: #4b4b4b;

  svg {
    margin-left: 5px;
  }
`;
