import apiIam from "../config/api_iam";

export function sendCodeEmailFrom(user, email){
  return apiIam.get(`/bovexo/auth/user/${user}/send/code/${email}/from`);
}

export function verifyCodeEmailFrom(user, code, changeEmailId){
  return apiIam.get(`/bovexo/auth/user/${user}/verify/code/${code}/from/${changeEmailId}`);
}

export function sendCodeEmailTo(user, email, changeEmailId){
  return apiIam.get(`/bovexo/auth/user/${user}/send/code/${email}/to/${changeEmailId}`);
}

export function verifyCodeEmailTo(user, code, changeEmailId){
  return apiIam.get(`/bovexo/auth/user/${user}/verify/code/${code}/to/${changeEmailId}`);
}