import React, { useCallback } from "react";
import { Creators as ReportActions } from "../../../../../store/ducks/report";
import { Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { Table } from "../styles";
import { numberMask } from "../../../../../services/helpersMethodsService";
import InfoTooltip from "../../../../../components/utils/infoTooltip";

const DashboardReproductionWeaningTable = ({ dashboardResult }) => {
  const dispatch = useDispatch();
  const generateReport = useCallback(
    (ids = []) => {
      if (ids == null || ids.length === 0) {
        return;
      } else {
        dispatch(
          ReportActions.showModal("Relatório de animais", "animal", null, ids)
        );
      }
    },
    [dispatch]
  );
  return (
    <Table>
      {/* Header */}
      <Row type="flex" justify="start" gutter={16}>
        <Col span={12} className="col"></Col>
        <Col span={4} className="col value header">
          <strong>Macho</strong>
        </Col>
        <Col span={4} className="col value header">
          <strong>Fêmea</strong>
        </Col>
        <Col span={4} className="col value header">
          <strong>Total</strong>
        </Col>
      </Row>
      {/* Body */}
      <Row type="flex" justify="start" gutter={16}>
        <Col span={12} className="col">
          <strong>
            Bezerros Desmamados{" "}
            <InfoTooltip
              title={
                "Quantidade de animais desmamados no período (possuam registro de desmama)."
              }
            />
          </strong>
        </Col>

        <Col span={4} className="col value">
          <button
            onClick={() =>
              generateReport(dashboardResult?.idsOfWeaningCalfMale)
            }
          >
            {dashboardResult?.totalOfWeaningCalfMale || 0}
          </button>
        </Col>
        <Col span={4} className="col value">
          <button
            onClick={() =>
              generateReport(dashboardResult?.idsOfWeaningCalfFemale)
            }
          >
            {dashboardResult?.totalOfWeaningCalfFemale || 0}
          </button>
        </Col>
        <Col span={4} className="col value">
          <button
            onClick={() => generateReport(dashboardResult?.idsOfWeaningCalf)}
          >
            {dashboardResult?.totalOfWeaningCalf || 0}
          </button>
        </Col>
      </Row>
      <Row type="flex" justify="start" gutter={16}>
        <Col span={12} className="col">
          <strong>
            Bezerros a Desmamar{" "}
            <InfoTooltip
              title={
                "Quantidade de animais com idade para desmama no período, conforme parâmetros reprodutivo, que ainda não possuem registro de desmama."
              }
            />
          </strong>
        </Col>
        <Col span={4} className="col value">
          <button
            onClick={() =>
              generateReport(dashboardResult?.idsOfNotWeaningCalfMale)
            }
          >
            {dashboardResult?.totalOfNotWeaningCalfMale || 0}
          </button>
        </Col>
        <Col span={4} className="col value">
          <button
            onClick={() =>
              generateReport(dashboardResult?.idsOfNotWeaningCalfFemale)
            }
          >
            {dashboardResult?.totalOfNotWeaningCalfFemale || 0}
          </button>
        </Col>
        <Col span={4} className="col value">
          <button
            onClick={() => generateReport(dashboardResult?.idsOfNotWeaningCalf)}
          >
            {dashboardResult?.totalOfNotWeaningCalf || 0}
          </button>
        </Col>
      </Row>
      <Row type="flex" justify="start" gutter={16}>
        <Col span={12} className="col">
          <strong>
            Previsão Total{" "}
            <InfoTooltip
              title={
                "Quantidade de animais com idade para desmama no período, conforme parâmetros reprodutivos."
              }
            />
          </strong>
        </Col>

        <Col span={4} className="col value">
          <strong>
            {dashboardResult?.totalOfForecastWeaningCalfMale || 0}
          </strong>
        </Col>
        <Col span={4} className="col value">
          <strong>
            {dashboardResult?.totalOfForecastWeaningCalfFemale || 0}
          </strong>
        </Col>
        <Col span={4} className="col value">
          <strong>{dashboardResult?.totalOfForecastWeaningCalf || 0}</strong>
        </Col>
      </Row>
      <Row type="flex" justify="start" gutter={16}>
        <Col span={12} className="col">
          <strong>
            Idade Média (dias){" "}
            <InfoTooltip
              title={"Idade média dos animais desmamados no período."}
            />
          </strong>
        </Col>

        <Col span={4} className="col value">
          <strong>{dashboardResult?.averageCalfAgeInDaysMale || 0}</strong>
        </Col>
        <Col span={4} className="col value">
          <strong>{dashboardResult?.averageCalfAgeInDaysFemale || 0}</strong>
        </Col>
        <Col span={4} className="col value">
          <strong>{dashboardResult?.averageCalfAgeInDays || 0}</strong>
        </Col>
      </Row>
      <Row type="flex" justify="start" gutter={16}>
        <Col span={12} className="col">
          <strong>
            Peso Médio{" "}
            <InfoTooltip
              title={"Peso médio dos animais desmamados no período."}
            />
          </strong>
        </Col>

        <Col span={4} className="col value">
          <strong>
            {numberMask(dashboardResult?.averageCalfWeightMale || 0, false)}
          </strong>
        </Col>
        <Col span={4} className="col value">
          <strong>
            {numberMask(dashboardResult?.averageCalfWeightFemale || 0, false)}
          </strong>
        </Col>
        <Col span={4} className="col value">
          <strong>
            {numberMask(dashboardResult?.averageCalfWeight || 0, false)}
          </strong>
        </Col>
      </Row>
      <Row type="flex" justify="start" gutter={16}>
        <Col span={12} className="col">
          <strong>
            GDP{" "}
            <InfoTooltip
              title={
                "Ganho de peso diário dos animais do nascimento até a desmama."
              }
            />
          </strong>
        </Col>

        <Col span={4} className="col value">
          <strong>
            {numberMask(dashboardResult?.averageCalfGdpMale || 0, false)}
          </strong>
        </Col>
        <Col span={4} className="col value">
          <strong>
            {numberMask(dashboardResult?.averageCalfGdpFemale || 0, false)}
          </strong>
        </Col>
        <Col span={4} className="col value">
          <strong>
            {numberMask(dashboardResult?.averageCalfGdp || 0, false)}
          </strong>
        </Col>
      </Row>
      <Row type="flex" justify="start" gutter={16}>
        <Col span={12} className="col">
          <strong>
            Mortes até Desmama{" "}
            <InfoTooltip
              title={
                "Quantidade de Animais que morreram antes da desmama. Isto, considerando os animais que, se estivessem vivos, teriam a idade para desmama neste período selecionado."
              }
            />
          </strong>
        </Col>

        <Col span={4} className="col value">
          <button
            onClick={() =>
              generateReport(dashboardResult?.idsOfDeathUntilWeaningCalfMale)
            }
          >
            {dashboardResult?.totalOfDeathUntilWeaningCalfMale || 0}
          </button>
        </Col>
        <Col span={4} className="col value">
          <button
            onClick={() =>
              generateReport(dashboardResult?.idsOfDeathUntilWeaningCalfFemale)
            }
          >
            {dashboardResult?.totalOfDeathUntilWeaningCalfFemale || 0}
          </button>
        </Col>
        <Col span={4} className="col value">
          <button
            onClick={() =>
              generateReport(dashboardResult?.idsOfDeathUntilWeaningCalf)
            }
          >
            {dashboardResult?.totalOfDeathUntilWeaningCalf || 0}
          </button>
        </Col>
      </Row>
    </Table>
  );
};

export default DashboardReproductionWeaningTable;
