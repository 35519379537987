import styled from "styled-components";

export const Container = styled.div`
  padding-bottom: 16px;
  .labelCalendarSuggested {
    font-family: "Asap", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height */

    /* BovExo - Roxo primária */

    color: #8a50a5;
  }

  .rowFarmSell {
    margin-top: 16px;
    padding: 0 8px;
  }

  .rowButtonNewFarmSell {
    margin-top: 16px;
  }

  .rowMessageSelectDates {
    margin-top: 16px;
    margin-bottom: 24px;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  div {
    align-items: center;
  }
  svg {
    margin-right: 5px;
  }
  span {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;
  }
  div.buttonContainer {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 5px 5px 5px 0px;

    button:first-of-type {
      margin-right: 5px;
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 19px;
  justify-content: space-between;
  width: 100%;

  div.title-container {
    display: flex;
  }

  div.boitel {
    padding-right: 10px;
    span {
      font-weight: normal;
      font-size: 14px;
    }
  }

  div.title {
    padding-right: 14px;
    span {
      font-weight: bold;
      font-size: 18px;
    }
  }
  div.amountAnimals {
    padding: 0 14px;

    label {
      font-family: Asap;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: inherit;
    }

    span {
      font-family: Asap;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: #a9c133;
    }
  }
  div.providerAndDate {
    padding-left: 14px;
    padding-right: 14px;
    span {
      font-weight: bold;
      font-size: 16px;
    }
  }
  div.receinvingDate {
    padding-left: 14px;
    span {
      font-family: Asap;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: #c4c4c4;
    }
  }
`;

export const DateButton = styled.button`
  display: flex;
  align-items: center;

  margin-top: 8px;
  padding: 12px 8px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;

  text-align: center;

  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  cursor: pointer;

  color: #4b4b4b;

  svg {
    font-size: 16px;
    color: #8a50a5;
    margin-left: 8px;
  }

  &.active {
    background-color: #8a50a5;
    border: 1px solid #8a50a5;
    color: #ffffff;

    svg {
      color: #ffffff;
    }
  }
`;
