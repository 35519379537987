import { Card } from "antd";
import styled from "styled-components";

export const CardCustom = styled(Card)`
  margin-top: 13px !important;
  border-radius: 7px !important;
  background: #fff !important;
  box-shadow: 0px 0px 10px #d8d8d8 !important;

  .ant-card-body {
    padding-top: 14px;
  }
  .rowHeader {
    margin-bottom: 17px;
  }
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;
