import React from "react";

import {
  Button,
  ButtonTypeOne,
  ButtonTypeTwo,
  ButtonTypeThree,
  ButtonTypeFour,
  ButtonTypeFive,
  ButtonTypeSix,
  ButtonTypeSeven,
  ButtonTypeEight,
  ButtonTypeWithoutBackground,
  ButtonTypeWithoutBackgroundWithBorder,
  ButtonPrincipal,
  ButtonSecondary,
  ButtonThirdary,
  ButtonAlternative,
  ButtonArrow,
  ButtonCancel,
} from "./styles";

const ButtonStandard = ({
  disabled,
  children,
  width,
  height,
  type,
  onClick,
  buttonType,
  size,
  background,
  activeBackground,
  color,
  fontSize,
  isUpperCase,
  padding,
  style,
}) => {
  switch (buttonType) {
    case "type1":
      return (
        <ButtonTypeOne
          tabIndex={"-1"}
          type={type !== "" ? type : "submit"}
          background={background}
          activeBackground={activeBackground}
          width={width}
          height={height}
          onClick={onClick}
          size={size}
          disabled={disabled}
          padding={padding}
        >
          {children}
        </ButtonTypeOne>
      );
    case "type2":
      return (
        <ButtonTypeTwo
          tabIndex={"-1"}
          type={type !== "" ? type : "submit"}
          background={background}
          activeBackground={activeBackground}
          width={width}
          height={height}
          onClick={onClick}
          size={size}
          disabled={disabled}
          padding={padding}
        >
          {children}
        </ButtonTypeTwo>
      );
    case "type3":
      return (
        <ButtonTypeThree
          tabIndex={"-1"}
          type={type !== "" ? type : "submit"}
          background={background}
          activeBackground={activeBackground}
          width={width}
          height={height}
          onClick={onClick}
          size={size}
          disabled={disabled}
          padding={padding}
        >
          {children}
        </ButtonTypeThree>
      );
    case "type4":
      return (
        <ButtonTypeFour
          tabIndex={"-1"}
          type={type !== "" ? type : "submit"}
          background={background}
          activeBackground={activeBackground}
          onClick={onClick}
          size={size}
          disabled={disabled}
          padding={padding}
        >
          {children}
        </ButtonTypeFour>
      );
    case "type5":
      return (
        <ButtonTypeFive
          tabIndex={"-1"}
          type={type !== "" ? type : "submit"}
          background={background}
          activeBackground={activeBackground}
          onClick={onClick}
          size={size}
          disabled={disabled}
          padding={padding}
        >
          {children}
        </ButtonTypeFive>
      );
    case "type6": // Button will use in save in drawers
      return (
        <ButtonTypeSix
          tabIndex={"-1"}
          type={type !== "" ? type : "submit"}
          onClick={onClick}
          disabled={disabled}
        >
          {children}
        </ButtonTypeSix>
      );
    case "type7": // Button will use in cancel in drawers
      return (
        <ButtonTypeSeven
          tabIndex={"-1"}
          type={type !== "" ? type : "submit"}
          onClick={onClick}
          disabled={disabled}
          style={style}
        >
          {children}
        </ButtonTypeSeven>
      );
    case "type8": // Button blue in list cards
      return (
        <ButtonTypeEight
          tabIndex={"-1"}
          type={type !== "" ? type : "submit"}
          onClick={onClick}
          disabled={disabled}
        >
          {children}
        </ButtonTypeEight>
      );
    case "typeWithoutBackground": // Button withoutBackground
      return (
        <ButtonTypeWithoutBackground
          tabIndex={"-1"}
          type={type !== "" ? type : "submit"}
          padding={padding}
          onClick={onClick}
          color={color}
          isUpperCase={isUpperCase}
          disabled={disabled}
        >
          {children}
        </ButtonTypeWithoutBackground>
      );
    case "typeWithoutBackgroundWithBorder": // Button withoutBackground
      return (
        <ButtonTypeWithoutBackgroundWithBorder
          tabIndex={"-1"}
          type={type !== "" ? type : "submit"}
          size={size}
          padding={padding}
          onClick={onClick}
          color={color}
          isUpperCase={isUpperCase}
          disabled={disabled}
        >
          {children}
        </ButtonTypeWithoutBackgroundWithBorder>
      );
    case "principal":
      return (
        <ButtonPrincipal
          tabIndex={"-1"}
          type={type !== "" ? type : "button"}
          onClick={onClick}
          disabled={disabled}
          style={style}
        >
          {children}
        </ButtonPrincipal>
      );
    case "secondary":
      return (
        <ButtonSecondary
          tabIndex={"-1"}
          type={type !== "" ? type : "button"}
          onClick={onClick}
          disabled={disabled}
          style={style}
        >
          {children}
        </ButtonSecondary>
      );
    case "thirdary":
      return (
        <ButtonThirdary
          tabIndex={"-1"}
          type={type !== "" ? type : "button"}
          onClick={onClick}
          disabled={disabled}
          style={style}
        >
          {children}
        </ButtonThirdary>
      );
    case "alternative":
      return (
        <ButtonAlternative
          tabIndex={"-1"}
          type={type !== "" ? type : "submit"}
          onClick={onClick}
          disabled={disabled}
          style={style}
        >
          {children}
        </ButtonAlternative>
      );
    case "cancel":
      return (
        <ButtonCancel
          tabIndex={"-1"}
          type={type !== "" ? type : "submit"}
          onClick={onClick}
          disabled={disabled}
          style={style}
        >
          {children}
        </ButtonCancel>
      );
    case "arrow":
      return (
        <ButtonArrow onClick={onClick} disabled={disabled}>
          {children}
        </ButtonArrow>
      );
    default:
      return (
        <Button
          tabIndex={"-1"}
          type={type !== "" ? type : "submit"}
          background={background}
          color={color}
          fontSize={fontSize}
          width={width}
          height={height}
          onClick={onClick}
          disabled={disabled}
          padding={padding}
        >
          {children}
        </Button>
      );
  }
};

export default ButtonStandard;
