import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
/* Redux Libs */
import { useSelector, useDispatch } from "react-redux";
import { Creators as PurchaseScenarioActions } from "../../../store/ducks/purchaseScenario";
/** Components */
import { Container, Title, CustomDivider, Body } from "./styles";
import { Row, Col } from "antd";
import BreadCrumbs from "../../../components/utils/breadCrumbs";
import PurchaseParametersTab from "./tabs/parameters";
import PurchaseDietStrategyTab from "./tabs/dietStrategy";
import UserTriggerInProcessMessage from "../../../components/utils/userTriggerInProcessMessage";

const NewEditPurchaseScenario = (props) => {
  const { activeTab } = useSelector((state) => state.purchaseScenario);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(PurchaseScenarioActions.resetData());
    };
  }, [dispatch]);

  return (
    <Container>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={12}>
          <Title>Cenário de compra</Title>
        </Col>
        <Col span={12} align="right">
          <BreadCrumbs>
            <span
              onClick={() => props.history.goBack()}
              style={{ cursor: "pointer" }}
            >
              <span className="pageTreeInative">Decisões</span>
            </span>
            <span className="pageTreeActive">Cenário de Compra</span>
          </BreadCrumbs>
        </Col>
      </Row>
      <UserTriggerInProcessMessage triggerAction={"AnimalDailyCost"} />
      <CustomDivider dashed />
      <Body>
        {/* Tabs */}
        {activeTab === "tab1" ? (
          <PurchaseParametersTab props={props} />
        ) : activeTab === "tab2" ? (
          <PurchaseDietStrategyTab props={props} />
        ) : null}
      </Body>
    </Container>
  );
};

export default withRouter(NewEditPurchaseScenario);
