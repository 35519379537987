import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Creators as DietStrategyActions } from "../../../../store/ducks/dietStrategy";
// Components
import { Card, Col, Collapse, Row, Spin } from "antd";
import { FiMinus, FiPlus, FiX } from "react-icons/fi";
import { Container, CustomCollapse, CustomTable } from "./styles";
import ButtonStandard from "../../../../components/utils/button";
import InfoTooltip from "../../../../components/utils/infoTooltip";
// Services
import { numberMask } from "../../../../services/helpersMethodsService";

const BoitelSaleScenarioDetailsInfo = ({
  setShouldShowParameters,
  boitelSaleScenarioResult = null,
  dietStrategies = [],
  commitments = [],
  violations = [],
  arrobaPrices = [],
  boitelSaleScenarioParam = null,
  isLoadingData = false,
  isLoadingDietStrategiesData = false,
  isLoadingCommitmentsData = false,
  isLoadingViolationsData = false,
  isLoadingArrobaPricesData = false,
  isLoadingBoitelSaleScenarioParamData = false,
}) => {
  const dateFormat = "DD/MM/YYYY";
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  function showDietStrategyDetails(id) {
    dispatch(DietStrategyActions.showDietStrategy(groupId, farmId, id));
  }
  return (
    <Container>
      <Spin spinning={isLoadingData}>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          style={{ marginBottom: 24 }}
        >
          <p className="title">
            Período total
            <br />
            <span>{`${
              boitelSaleScenarioResult?.initialDate
                ? moment(boitelSaleScenarioResult?.initialDate).format(
                    dateFormat
                  )
                : "-"
            } a ${
              boitelSaleScenarioResult?.finalDate
                ? moment(boitelSaleScenarioResult?.finalDate).format(dateFormat)
                : "-"
            }`}</span>
          </p>
          <button
            className="buttonClose"
            onClick={() => setShouldShowParameters(false)}
          >
            <FiX />
          </button>
        </Row>
        <Card>
          <Row type="flex" justify="space-between" align="middle">
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Row>
                <span>
                  {translation.boitelSaleScenario.details.animalsToSell}
                </span>
              </Row>
              <Row>
                <strong>
                  {boitelSaleScenarioResult?.amountAnimals || "-"}
                </strong>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Row>
                <span>
                  {translation.boitelSaleScenario.details.planningIncome}
                </span>
              </Row>
              <Row>
                <strong>
                  {boitelSaleScenarioResult?.amountSaleIncome
                    ? numberMask(
                        boitelSaleScenarioResult?.amountSaleIncome,
                        true
                      )
                    : "-"}
                </strong>
              </Row>
            </Col>
          </Row>
        </Card>
        {/* DietStrategies */}
        <Collapse expandIconPosition="right">
          <Collapse.Panel
            header={
              translation.boitelSaleScenario.details.dietStrategiesSuggested
            }
            key="1"
          >
            <Spin spinning={isLoadingDietStrategiesData}>
              {dietStrategies.length > 0 ? (
                <CustomCollapse
                  accordion
                  expandIcon={(panelProps) =>
                    panelProps.isActive ? (
                      <FiMinus size={16} />
                    ) : (
                      <FiPlus size={16} />
                    )
                  }
                >
                  {dietStrategies.map((ds) => (
                    <CustomCollapse.Panel key={ds.id} header={ds.name}>
                      <Row type="flex" justify="start">
                        <Col span={24}>
                          <Row>
                            <span className="field">Período total</span>
                          </Row>
                          <Row>
                            <strong className="value">{`${
                              ds.initialDate
                                ? moment(ds.initialDate).format(dateFormat)
                                : "-"
                            } à ${
                              ds.finalDate
                                ? moment(ds.finalDate).format(dateFormat)
                                : "-"
                            }`}</strong>
                          </Row>
                        </Col>
                      </Row>
                      <Row type="flex" justify="end">
                        <ButtonStandard
                          buttonType="thirdary"
                          onClick={() => showDietStrategyDetails(ds.id)}
                        >
                          Visualizar detalhes
                        </ButtonStandard>
                      </Row>
                    </CustomCollapse.Panel>
                  ))}
                </CustomCollapse>
              ) : (
                <p className="noValueText">Nenhuma estratégia informada.</p>
              )}
            </Spin>
          </Collapse.Panel>
        </Collapse>
        {/* Commitments */}
        <Collapse expandIconPosition="right">
          <Collapse.Panel
            header={translation.boitelSaleScenario.details.commitments}
          >
            <Spin spinning={isLoadingCommitmentsData}>
              {commitments.length > 0 ? (
                <CustomTable>
                  <thead>
                    <tr>
                      <th>Valor</th>
                      <th>Quantidade</th>
                      <th>Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    {commitments.map((c) => (
                      <tr key={c.id}>
                        <td>
                          {c.saleCommitmentType === "SellValue"
                            ? numberMask(c.commitmentValue, true)
                            : "-"}
                        </td>
                        <td>
                          {c.saleCommitmentType === "AmountAnimals"
                            ? c.commitmentValue
                            : "-"}
                        </td>
                        <td>{moment(c.commitmentDate).format(dateFormat)}</td>
                      </tr>
                    ))}
                  </tbody>
                </CustomTable>
              ) : (
                <p className="noValueText">Nenhuma compromisso informado.</p>
              )}
            </Spin>
          </Collapse.Panel>
        </Collapse>
        {/* Violations */}
        <Collapse expandIconPosition="right">
          <Collapse.Panel
            header={translation.boitelSaleScenario.details.violations}
          >
            <Spin spinning={isLoadingViolationsData}>
              {violations.length > 0 ? (
                <CustomTable>
                  <thead>
                    <tr>
                      <th>Violação</th>
                      <th>Quantidade</th>
                    </tr>
                  </thead>
                  <tbody>
                    {violations.map((c) => (
                      <tr key={c.id}>
                        <td>
                          {
                            c.violation === "MinAge"
                              ? translation.boitelSaleScenario.details
                                .violationMinAge
                              : c.violation === "MinWeight"
                                ? translation.boitelSaleScenario.details
                                  .violationMinWeight
                                :
                                c.violation === "MaxAge"
                                  ? translation.boitelSaleScenario.details
                                    .violationMaxAge
                                  : c.violation === "MaxWeight"
                                    ? translation.boitelSaleScenario.details
                                      .violationMaxWeight
                                    :
                                    c.violation === "Age"
                                      ? translation.boitelSaleScenario.details
                                        .violationAge
                                      : c.violation === "Weight"
                                        ? translation.boitelSaleScenario.details
                                          .violationWeight
                                        : c.violation === "Boitel"
                                          ? translation.boitelSaleScenario.details
                                            .violationBoitel
                                          : c.violation}
                        </td>
                        <td>{c.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </CustomTable>
              ) : (
                <p className="noValueText">Nenhuma violação encontrada.</p>
              )}
            </Spin>
          </Collapse.Panel>
        </Collapse>
        {/* Parameters */}
        <Collapse expandIconPosition="right">
          <Collapse.Panel
            header={translation.boitelSaleScenario.details.parameters}
          >
            <Spin spinning={isLoadingBoitelSaleScenarioParamData}>
              {boitelSaleScenarioParam ? (
                <div className="containerBoitelSaleScenarioParam">
                  <Row type="flex" gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                      <Row>
                        <span className="field">Retroativo</span>
                      </Row>
                      <Row>
                        <strong className="value">
                          {boitelSaleScenarioParam?.retroactive
                            ? translation.buttons.yes
                            : translation.buttons.no}
                        </strong>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                      <Row>
                        <span className="field">Lote econômico</span>
                      </Row>
                      <Row>
                        <strong className="value">{`${boitelSaleScenarioParam?.animalsInTruck} cab`}</strong>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                      <Row>
                        <span className="field">Análise mercado</span>
                      </Row>
                      <Row>
                        <strong className="value">
                          {boitelSaleScenarioParam?.marketAnalysis.name}
                        </strong>
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                      <Row>
                        <span className="field">Peso mín. venda</span>
                      </Row>
                      <Row>
                        <strong className="value">
                          {boitelSaleScenarioParam?.minimumWeight
                            ? `${numberMask(
                                boitelSaleScenarioParam?.minimumWeight,
                                false
                              )} kg`
                            : "-"}
                        </strong>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                      <Row>
                        <span className="field">Peso máx. venda</span>
                      </Row>
                      <Row>
                        <strong className="value">
                          {boitelSaleScenarioParam?.maximumWeight
                            ? `${numberMask(
                                boitelSaleScenarioParam?.maximumWeight,
                                false
                              )} kg`
                            : "-"}
                        </strong>
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                      <Row>
                        <span className="field">
                          Usa novo % de Aprov. de Carcaça?{" "}
                          <InfoTooltip
                            title={
                              translation.boitelSaleScenario.new.tabs.parameters
                                .form.useCustomCarcassHarnessingHelp
                            }
                          />
                        </span>
                      </Row>
                      <Row>
                        <strong className="value">
                          {boitelSaleScenarioParam?.useCustomCarcassHarnessing ===
                          true
                            ? "Sim"
                            : "Não"}
                        </strong>
                      </Row>
                    </Col>
                    {boitelSaleScenarioParam?.useCustomCarcassHarnessing ===
                      true && (
                      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <Row>
                          <span className="field">Aprov. de Carcaça</span>
                        </Row>
                        <Row>
                          <strong className="value">
                            {`${numberMask(
                              boitelSaleScenarioParam?.carcassHarnessing,
                              false
                            )} %`}
                          </strong>
                        </Row>
                      </Col>
                    )}
                  </Row>
                  <Row type="flex" gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                      <Row>
                        <span className="field">Descasca</span>
                      </Row>
                      <Row>
                        <strong className="value">
                          {boitelSaleScenarioParam?.allowSeparateSell
                            ? translation.buttons.yes
                            : translation.buttons.no}
                        </strong>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                      <Row>
                        <span className="field">Idade mín. venda</span>
                      </Row>
                      <Row>
                        <strong className="value">
                          {boitelSaleScenarioParam?.minimumAge
                            ? `${boitelSaleScenarioParam?.minimumAge} dias`
                            : "-"}
                        </strong>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                      <Row>
                        <span className="field">Idade máx. venda</span>
                      </Row>
                      <Row>
                        <strong className="value">
                          {boitelSaleScenarioParam?.maximumAge
                            ? `${boitelSaleScenarioParam?.maximumAge} dias`
                            : "-"}
                        </strong>
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" gutter={8}>
                    <Col span={24}>
                      <strong className="value">Restrições</strong>
                    </Col>
                  </Row>
                  <Row type="flex" gutter={8}>
                    <Col xs={16} sm={16} md={16} lg={16} xl={16}></Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Row>
                        <span className="field">Forte ?</span>
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" gutter={8}>
                    <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                      <Row>
                        <span className="field">Animais no caminhão</span>
                      </Row>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Row>
                        <strong className="value">
                          {boitelSaleScenarioParam?.strongAnimalsInTruckRestriction
                            ? translation.buttons.yes
                            : translation.buttons.no}
                        </strong>
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" gutter={8}>
                    <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                      <Row>
                        <span className="field">Idade</span>
                      </Row>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Row>
                        <strong className="value">
                          {boitelSaleScenarioParam?.strongAgeRestriction
                            ? translation.buttons.yes
                            : translation.buttons.no}
                        </strong>
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" gutter={8}>
                    <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                      <Row>
                        <span className="field">Peso</span>
                      </Row>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Row>
                        <strong className="value">
                          {boitelSaleScenarioParam?.strongWeightRestriction
                            ? translation.buttons.yes
                            : translation.buttons.no}
                        </strong>
                      </Row>
                    </Col>
                  </Row>
                  <Row type="flex" gutter={8}>
                    <Col span={24}>
                      <strong className="value">Lotes</strong>
                    </Col>
                  </Row>
                  <CustomCollapse
                    accordion
                    expandIcon={(panelProps) =>
                      panelProps.isActive ? (
                        <FiMinus size={16} />
                      ) : (
                        <FiPlus size={16} />
                      )
                    }
                  >
                    {boitelSaleScenarioParam.lots.map((l) => (
                      <CustomCollapse.Panel key={l.id} header={l.lot?.name}>
                        <Row type="flex" gutter={8}>
                          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Row>
                              <span className="field">Atividade</span>
                            </Row>
                            <Row>
                              <strong className="value">
                                {l.lot?.profitCenterName}
                              </strong>
                            </Row>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Row>
                              <span className="field">Piquete</span>
                            </Row>
                            <Row>
                              <strong className="value">
                                {l.lot?.picketName}
                              </strong>
                            </Row>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Row>
                              <span className="field">Raça</span>
                            </Row>
                            <Row>
                              <strong className="value">
                                {l.lot?.breedName}
                              </strong>
                            </Row>
                          </Col>
                        </Row>
                        <Row type="flex" gutter={8}>
                          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Row>
                              <span className="field">Data de aquis.</span>
                            </Row>
                            <Row>
                              <strong className="value">
                                {moment(l.lot?.referenceAcquisitionDate).format(
                                  dateFormat
                                )}
                              </strong>
                            </Row>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Row>
                              <span className="field">Nasc. animal </span>
                            </Row>
                            <Row>
                              <strong className="value">
                                {moment(l.lot?.referenceBirthdayDate).format(
                                  dateFormat
                                )}
                              </strong>
                            </Row>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Row>
                              <span className="field">Peso médio</span>
                            </Row>
                            <Row>
                              <strong className="value">{`${numberMask(
                                l.lot?.referenceAcquisitionWeight,
                                false
                              )} kg`}</strong>
                            </Row>
                          </Col>
                        </Row>
                      </CustomCollapse.Panel>
                    ))}
                  </CustomCollapse>
                </div>
              ) : (
                <p className="noValueText">Nenhuma informação encontrada.</p>
              )}
            </Spin>
          </Collapse.Panel>
        </Collapse>
        {/* Reference values (Market Analysis) */}
        <Collapse expandIconPosition="right">
          <Collapse.Panel
            header={translation.boitelSaleScenario.details.marketValues}
          >
            <Spin spinning={isLoadingArrobaPricesData}>
              {arrobaPrices.length > 0 ? (
                <CustomTable>
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Valor/@</th>
                    </tr>
                  </thead>
                  <tbody>
                    {arrobaPrices.map((c, index) => (
                      <tr key={index}>
                        <td>{moment(c.Date).format(dateFormat)}</td>
                        <td>{numberMask(c.Value, true)}</td>
                      </tr>
                    ))}
                  </tbody>
                </CustomTable>
              ) : (
                <p className="noValueText">Nenhuma informação encontrada.</p>
              )}
            </Spin>
          </Collapse.Panel>
        </Collapse>
      </Spin>
    </Container>
  );
};

export default BoitelSaleScenarioDetailsInfo;
