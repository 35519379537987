import apiFinancial from "../config/api_financial";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiFinancial = axios.create({
//   baseURL: "http://localhost:8086",
// });

function findAllFinancialClassesByGroupIdAndFarmId(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/financial/classes`,
    getHeaders(false, signal)
  );
}
function createNewFinancialClass(payload) {
  const { groupId, farmId, body, signal } = payload;

  return apiFinancial.post(
    `/bovexo/${groupId}/farms/${farmId}/financial/classes`,
    body,
    getHeaders(false, signal)
  );
}
function updateFinancialClass(payload) {
  const { groupId, farmId, id, body, signal } = payload;

  return apiFinancial.put(
    `/bovexo/${groupId}/farms/${farmId}/financial/classes/${id}`,
    body,
    getHeaders(false, signal)
  );
}
function inactivateFinancialClass(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiFinancial.patch(
    `/bovexo/${groupId}/farms/${farmId}/financial/classes/${id}/inactivate`,
    null,
    getHeaders(false, signal)
  );
}
function activateFinancialClass(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiFinancial.patch(
    `/bovexo/${groupId}/farms/${farmId}/financial/classes/${id}/activate`,
    null,
    getHeaders(false, signal)
  );
}
function resetFinancialClassDefaultValues(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiFinancial.patch(
    `/bovexo/${groupId}/farms/${farmId}/financial/classes/${id}/reset/default/values`,
    null,
    getHeaders(false, signal)
  );
}
export {
  findAllFinancialClassesByGroupIdAndFarmId,
  createNewFinancialClass,
  updateFinancialClass,
  inactivateFinancialClass,
  activateFinancialClass,
  resetFinancialClassDefaultValues,
};
