import { DatePicker } from "antd";
import styled from "styled-components";

export const Container = styled(DatePicker)`
  .ant-calendar-picker-input.ant-input {
    line-height: 1.5;
    padding: 4px 5px;
    width: 85px;
    border: 0px;
    color: #d44c4c !important;
    font-weight: bold;
  }

  svg {
    height: 10px;
    width: 10px;
    path {
      fill: #d44c4c !important;
    }
  }
`;
