import React from "react";

// import { Container } from './styles';

const ExclamationIcon = () => (
  <svg
    id="bovexoExclamationIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="16.848"
    height="16.848"
    viewBox="0 0 16.848 16.848"
  >
    <g
      id="Group_3801"
      data-name="Group 3801"
      transform="translate(-1105 -519.424)"
    >
      <circle
        id="Ellipse_169"
        data-name="Ellipse 169"
        cx="8.424"
        cy="8.424"
        r="8.424"
        transform="translate(1105 519.424)"
        fill="#cf7070"
      />
      <g
        id="Group_716"
        data-name="Group 716"
        transform="translate(1113.424 525.328)"
      >
        <line
          id="Line_215"
          data-name="Line 215"
          y2="4"
          transform="translate(0 -1.904)"
          fill="none"
          stroke="#fffefe"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          id="Line_216"
          data-name="Line 216"
          transform="translate(0 6.096)"
          fill="none"
          stroke="#fffefe"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);
export default ExclamationIcon;
