import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as UserActions } from "../../../../store/ducks/user";
// Components
import {
  Container,
  CardDetail,
  BoxStatus,
  CardDetailWithBorder,
  ButtonActive,
} from "./styles";
import { Spin, Icon, notification } from "antd";
// Services
import {
  indexUserGroupsAndFarms,
  getRolesForUserInEspecificGroupAndFarm,
  activateUser,
} from "../../../../services/userService";
import ConfirmActionModal from "../../../modals/confirmActionModal";

const DrawerUserDetails = () => {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [groups, setGroups] = useState([]);
  const [farms, setFarms] = useState([]);
  const [modalActivateVisible, setModalActivateVisible] = useState(false);
  // Redux variables
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { drawerDetailsUserVisible, userData } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  // Methods
  function resetDrawer() {
    setFarms([]);
    setGroups([]);
    setRoles([]);
  }
  function closeDrawer() {
    resetDrawer();
    dispatch(UserActions.showOrHideDetaisUserDrawer(null));
  }
  function handleShowActiveModal() {
    setModalActivateVisible(true);
  }
  async function handleActivate() {
    try {
      await activateUser({
        groupId,
        farmId,
        userId: userData.id,
      });
      setModalActivateVisible(false);
      dispatch(UserActions.showOrHideDetaisUserDrawer(null));
      notification.success({
        message: "Usuário reativado",
      });
    } catch (error) {
      notification.error({
        message: "Não foi possível ativar o usuário",
      });
    }
  }
  async function handleDrawerVisible(visible) {
    if (visible) {
      setLoading(true);
      let aGroups = [];
      let aFarms = [];
      try {
        const [
          {
            data: { results: groupsFarms },
          },
          {
            data: { results: aRoles },
          },
        ] = await Promise.all([
          indexUserGroupsAndFarms({
            userId: userData.id,
          }),
          getRolesForUserInEspecificGroupAndFarm({
            groupId,
            farmId,
            userId: userData.id,
          }),
        ]);
        groupsFarms.forEach((gf) => {
          aGroups = [...aGroups, gf];
          gf.farms.forEach((f) => {
            aFarms = [...aFarms, f];
          });
        });
        setRoles(aRoles.map((r) => r.name));
        setGroups(aGroups);
        setFarms(aFarms);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  }
  return (
    <Container
      title="Detalhes de usuários"
      width={700}
      onClose={closeDrawer}
      maskClosable={false}
      visible={drawerDetailsUserVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      {userData?.status === "Active" ? (
        <BoxStatus className="active">
          <span>Ativo</span>
        </BoxStatus>
      ) : userData?.status === "Inactive" ? (
        <BoxStatus className="inactive">
          <span>Inativo</span>
        </BoxStatus>
      ) : null}
      <CardDetail>
        <div className="contentGroup">
          <strong>Nome</strong>
          <strong>E-mail</strong>
        </div>
        <div className="contentGroup">
          <span>{userData?.fullname}</span>
          <span>{userData?.email}</span>
        </div>
      </CardDetail>
      <Spin spinning={loading}>
        {(groups.length > 0 || farms.length > 0) && (
          <CardDetailWithBorder>
            <div className="row">
              <div className="contentGroup">
                <strong>Perfil de Acesso</strong>
                <span className="title">Grupos Gerenciados</span>
                <ul>
                  {groups.map((g) => (
                    <li key={g.id}>{g.name}</li>
                  ))}
                </ul>
              </div>
              <div className="contentGroup">
                <strong>
                  {roles
                    .map((r) =>
                      r === "Admin"
                        ? "Administrador"
                        : r === "Admin Group"
                        ? "Admin. de Grupo"
                        : r === "Admin Farmer"
                        ? "Admin. de Fazenda"
                        : r === "Technician"
                        ? "Técnico"
                        : r === "Admin Profit Center"
                        ? "Admin. de Atividade"
                        : null
                    )
                    .join(" , ")}
                </strong>
                <span className="title">Fazendas Gerenciadas</span>
                <ul>
                  {farms.map((f) => (
                    <li key={f.id}>{f.name}</li>
                  ))}
                </ul>
              </div>
            </div>
          </CardDetailWithBorder>
        )}
        {userData?.status === "Inactive" && (
          <div className="rowButton">
            <ButtonActive onClick={handleShowActiveModal}>
              <Icon type="poweroff" /> Ativar usuário
            </ButtonActive>
          </div>
        )}
      </Spin>
      {/* Modals */}
      <ConfirmActionModal
        show={modalActivateVisible}
        title="Ativar usuário"
        message="Você realmente deseja ativar este usuário ?"
        cancelAction={() => setModalActivateVisible(false)}
        confirmAction={handleActivate}
      />
    </Container>
  );
};

export default DrawerUserDetails;
