import { Statistic } from "antd";
import React from "react";
import useLotDetailsSupplementSupplyContext from "../../../../../../../hooks/useLotDetailsSupplementSupplyContext";
import { numberMask } from "../../../../../../../services/helpersMethodsService";

// import { Container } from './styles';

function CardAverageConsumeSupplementSupplyDashboard() {
  const { averageAnimalSupply } = useLotDetailsSupplementSupplyContext();
  return (
    <Statistic
      title="Consumo Médio"
      value={`${numberMask(averageAnimalSupply, false)} kg`}
      suffix="Cab/dia"
    />
  );
}

export default CardAverageConsumeSupplementSupplyDashboard;
