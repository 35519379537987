import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import useRegister from "../../../../hooks/useRegister";
// Components
import ReCAPTCHA from "react-google-recaptcha";
import { notification, Row, Col } from "antd";
import { Container, FormContainer, FormFooterContaier } from "../styles";
import InputStandard from "../../../../components/utils/input";
import CheckSmallIcon from "../../../../components/utils/icons/check/checkSmall";
import ExclamationSmallIcon from "../../../../components/utils/icons/exclamation/exclamationSmall";
// Services
import { validateCode } from "../../../../services/changePasswordService";
import { createUser, sendCodeToEmail } from "../../../../services/authService";
import { getOnlyNumber } from "../../../../services/helpersMethodsService";

const RegisterStep2 = () => {
  const captchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
  const captchaActive = process.env.REACT_APP_RECAPTCHA_ACTIVATE;

  const [authorized, setAuthorized] = useState(() => {
    if (captchaActive !== "true") {
      return true;
    } else {
      return false;
    }
  });
  const [codeValid, setCodeValid] = useState(null);
  const [formPassword, setFormPassword] = useState({
    code: null,
    password: null,
    confirmPassword: null,
  });
  const {
    form,
    setForm,
    setUserId,
    mode,
    goBackStep,
    goNextStep,
    setTrialEnvironmentSet,
  } = useRegister();

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(),
    password: Yup.string().required(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required(),
  });
  const formik = useRef(null);
  const passwordRef = useRef(null);
  /** Redux variables */
  /** Effect  */

  /** Methods */
  function handleCaptcha() {
    setAuthorized(true);
  }
  function handleGoBack() {
    setFormPassword({
      code: null,
      password: null,
      confirmPassword: null,
    });
    setCodeValid(null);
    formik.current.resetForm({});
    formik.current.setErrors([]);

    goBackStep();
  }
  function handleGoNext() {
    goNextStep();
  }
  function handleKeyDown(event) {
    if (event.key === "Enter" && event.target.value != null) {
      event.preventDefault();
      handleSubmitCode(event.target.value);
    }
  }
  async function handleResendCode() {
    const { email, phone, fullname, document } = form;
    setFormPassword({
      code: null,
      password: null,
      confirmPassword: null,
    });
    setCodeValid(null);
    formik.current.resetForm({
      code: null,
      password: null,
      confirmPassword: null,
    });
    formik.current.setErrors([]);
    try {
      await sendCodeToEmail({
        email: email,
        phone: getOnlyNumber(phone),
        document: getOnlyNumber(document),
        fullname: fullname,
      });
      notification.success({
        message: "Código de validação enviado para o e-mail.",
      });
    } catch (e) {
      if (e.response?.status === 409) {
        notification.error({
          message: "E-mail já cadastrado em nossa base de dados",
        });
      } else if (
        e.response?.status === 500 &&
        e.response?.data.results.description.includes(
          "query did not return a unique result"
        )
      ) {
        notification.error({
          message: "E-mail já cadastrado em nossa base de dados",
        });
      } else {
        notification.error({
          message: "Erro interno. Contate o suporte.",
        });
      }
    }
  }
  async function handleSubmitCode(code) {
    if (code !== null && code?.trim() !== "") {
      const obj = {
        email: form.email,
        codeValidate: code,
      };
      try {
        await validateCode(obj);
        setCodeValid(true);
        passwordRef.current.focus();
      } catch {
        notification.error({ message: "Código inválido" });
        formik.current.setErrors({ code: "Code invalid" });
        setCodeValid(false);
      }
    }
  }
  async function handleSubmitForm(values, actions) {
    const {
      email,
      birthday,
      phone,
      fullname,
      document,
      zip_code,
      address,
      address_number,
      neighborhood,
      country,
      state,
      city,
    } = form;
    const isTrial = mode === "Demo";

    const body = {
      email,
      birthday,
      phone: getOnlyNumber(phone),
      fullname,
      document: getOnlyNumber(document),
      zip_code,
      address,
      address_number,
      neighborhood,
      country,
      state,
      city,
      trial: isTrial,
      password: values.password,
    };
    if (authorized) {
      if (isTrial) {
        try {
          const {
            data: {
              results: { trialEnvironmentSet, id: userId },
            },
          } = await createUser(body);
          setTrialEnvironmentSet(trialEnvironmentSet);
          setUserId(userId);
          handleGoNext();
        } catch (e) {
          const errorMessage = e.response?.data?.message;
          if (
            errorMessage &&
            errorMessage.includes("#AUTHENTICATION_ERROR_001")
          ) {
            notification.error({
              message:
                "Erro ao efetuar o cadastro. CPF já cadastrado em nosso sistema.",
            });
          } else {
            notification.error({ message: "Erro ao efetuar o cadastro" });
          }
        }
      } else {
        setForm((old) => ({
          ...old,
          password: values.password,
        }));
        handleGoNext();
      }
    } else {
      notification.error({ message: "Prove que você não é um robô" });
    }
  }

  return (
    <Container>
      <FormContainer>
        <Formik
          ref={formik}
          initialValues={formPassword}
          validationSchema={validationSchema}
          onSubmit={handleSubmitForm}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            ...props
          }) => (
            <form onSubmit={handleSubmit} autoComplete="chrome-off">
              <span className="title">Confirmação de cadastro</span>
              <span className="subTitle">
                Por favor, copie e cole no campo abaixo, o código enviado para o
                seu e-mail.
              </span>
              <Row type="flex" justify="start">
                <Col span={24}>
                  <InputStandard
                    border={
                      errors.code || (codeValid !== null && !codeValid)
                        ? "error"
                        : "normal"
                    }
                    className="input text-uppercase no-margin-bottom"
                    width="100%"
                  >
                    <input
                      type="text"
                      autoCapitalize="off"
                      placeholder="Insira o código aqui"
                      name="code"
                      autoComplete="chrome-off"
                      onKeyPress={handleKeyDown}
                      onChange={(e) => {
                        if (
                          e.target.value == null ||
                          e.target.value?.trim() === ""
                        ) {
                          props.setFieldValue("code", null);
                        } else {
                          props.setFieldValue(
                            "code",
                            e.target.value.toUpperCase()
                          );
                        }
                      }}
                      onBlur={() => {
                        if (
                          values?.code !== null ||
                          values?.code?.trim() !== ""
                        ) {
                          handleSubmitCode(values?.code);
                        }
                      }}
                    />
                    {codeValid ? (
                      <CheckSmallIcon />
                    ) : codeValid !== null && !codeValid ? (
                      <ExclamationSmallIcon />
                    ) : null}
                  </InputStandard>
                </Col>
              </Row>
              <Row type="flex">
                <Col span={24}>
                  <button
                    type="button"
                    className="resend-code"
                    onClick={handleResendCode}
                  >
                    Enviar código novamente
                  </button>
                </Col>
              </Row>
              {codeValid && (
                <>
                  <Row type="flex">
                    <Col span={24}>
                      <span className="title small">Insira uma senha</span>
                    </Col>
                  </Row>
                  {/* Input password */}
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            errors.password && touched.password ? "error" : ""
                          }
                        >
                          Senha
                        </label>
                      </Row>
                      <Row>
                        <InputStandard
                          border={
                            errors.password && touched.password
                              ? "error"
                              : "normal"
                          }
                          className={"input"}
                          width="100%"
                        >
                          <input
                            type="password"
                            ref={passwordRef}
                            placeholder="Senha"
                            name="password"
                            autoComplete="chrome-off"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </InputStandard>
                      </Row>
                    </Col>
                  </Row>
                  {/* Input confirmPassword */}
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            errors.confirmPassword && touched.confirmPassword
                              ? "error"
                              : ""
                          }
                        >
                          Confirme a senha
                        </label>
                      </Row>
                      <Row>
                        <InputStandard
                          border={
                            errors.confirmPassword && touched.confirmPassword
                              ? "error"
                              : "normal"
                          }
                          className={"input"}
                          width="100%"
                        >
                          <input
                            type="password"
                            placeholder="Confirme a senha"
                            name="confirmPassword"
                            autoComplete="chrome-off"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </InputStandard>
                      </Row>
                    </Col>
                  </Row>
                  {/* Captcha */}
                  <Row type="flex">
                    <Col span={24}>
                      <div className="captcha">
                        {captchaActive === "true" ? (
                          <ReCAPTCHA
                            sitekey={captchaKey}
                            onChange={handleCaptcha}
                          />
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              <FormFooterContaier>
                <button
                  type="button"
                  className="previous"
                  onClick={handleGoBack}
                >
                  Voltar
                </button>
                <button
                  disabled={codeValid === null || !codeValid}
                  type="submit"
                  className="next"
                >
                  Próximo
                </button>
              </FormFooterContaier>
            </form>
          )}
        </Formik>
      </FormContainer>
    </Container>
  );
};

export default RegisterStep2;
