import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import axios from "axios";

// Services
import {
  getParameterItems,
  saveOrUpdateParameter,
} from "../../../../services/generalParameterService";
import { notification } from "antd";
import { useCurrencyContext } from "../../../../hooks/useCurrencyContext";

const GeneralFinancialParametersContext = createContext(null);

const CODES_PARAMETERS = [4001, 4002];

export const GeneralFinancialParametersContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [parametersValue, setParametersValue] = useState([]);
  const [parameter4001Value, setParameter4001Value] = useState(null);
  const [parameter4002Value, setParameter4002Value] = useState(null);

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const { refreshCurrencyContextValues } = useCurrencyContext();

  const handleChangeParameter4001Value = useCallback(
    async (checked) => {
      setIsLoading(true);
      try {
        const newParameter4001Value = {
          ...parameter4001Value,
          value: `${checked}`,
          updated: true,
        };
        setParameter4001Value(newParameter4001Value);
        await saveOrUpdateParameter({
          groupId,
          farmId,
          parameterItemId: newParameter4001Value.id,
          parameterId: newParameter4001Value.parameterId,
          edit: !newParameter4001Value.isDefault,
          body: newParameter4001Value,
        });
        notification.success({
          message: translation.generalParameters.successParameterUpdated,
        });
        refreshCurrencyContextValues();
      } catch (error) {
        notification.error({
          message: translation.generalParameters.errorParameterUpdated,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      farmId,
      groupId,
      parameter4001Value,
      refreshCurrencyContextValues,
      translation.generalParameters.errorParameterUpdated,
      translation.generalParameters.successParameterUpdated,
    ]
  );
  const handleChangeParameter4002Value = useCallback(
    async (value) => {
      setIsLoading(true);
      try {
        const newParameter4002Value = {
          ...parameter4002Value,
          value: `${value}`,
          updated: true,
        };
        setParameter4002Value(newParameter4002Value);
        await saveOrUpdateParameter({
          groupId,
          farmId,
          parameterItemId: newParameter4002Value.id,
          parameterId: newParameter4002Value.parameterId,
          edit: !newParameter4002Value.isDefault,
          body: newParameter4002Value,
        });
        notification.success({
          message: translation.generalParameters.successParameterUpdated,
        });
        refreshCurrencyContextValues();
      } catch (error) {
        notification.error({
          message: translation.generalParameters.errorParameterUpdated,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      farmId,
      groupId,
      parameter4002Value,
      refreshCurrencyContextValues,
      translation.generalParameters.errorParameterUpdated,
      translation.generalParameters.successParameterUpdated,
    ]
  );

  useEffect(() => {
    const signal = axios.CancelToken.source();
    async function fetchData() {
      setIsLoading(true);
      let dataResult = [];
      try {
        for (var i = 0; i < CODES_PARAMETERS.length; i++) {
          let code = CODES_PARAMETERS[i];
          let {
            data: { results },
          } = await getParameterItems({ groupId, farmId, code, signal });
          results.isDefault = results.farmId == null && results.groupId == null;
          results.updated = false;
          dataResult.push(results);
        }
        setParametersValue(dataResult);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
    return () => {
      signal.cancel();
    };
  }, [groupId, farmId]);

  useEffect(() => {
    if (parametersValue.length > 0) {
      setParameter4001Value(
        parametersValue.find((parameter) => parameter.code === 4001)
      );
      setParameter4002Value(
        parametersValue.find((parameter) => parameter.code === 4002)
      );
    }
  }, [parametersValue]);

  const value = {
    translation,
    isLoading,
    parametersValue,
    parameter4001Value,
    handleChangeParameter4001Value,
    parameter4002Value,
    handleChangeParameter4002Value,
  };
  return (
    <GeneralFinancialParametersContext.Provider value={value}>
      {children}
    </GeneralFinancialParametersContext.Provider>
  );
};

export function useGeneralFinancialParametersContext() {
  const values = useContext(GeneralFinancialParametersContext);

  return values;
}
