// import Axios from "axios";
import apiManagement from "../config/api_management";
import getHeaders from "./getHeaders";

// const apiManagement = Axios.create({
//   baseURL: `http://localhost:8081`,
// });

export async function storeUserLogAccess(token) {
  return apiManagement.post("/bovexo/logs/access", null, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}
