import React, { useState } from "react";
import moment from "moment";
import { useCallback } from "react";
import { createContext } from "react";
import { useSelector } from "react-redux";
import { getAnimalsPregnancyDiagnosis } from "../../services/animalPregnancyDiagnosisService";
import { getAnimalDropdownListByGender } from "../../services/animalService";
import { transformCamelCaseToSnakeCase } from "../../utils/stringUtils";

export const INITIAL_DATA_FORM_ANIMAL_DG = {
  id: null,
  animalId: null,
  animalHandlingNumber: null,
  semenId: null,
  maleId: null,
  gestationDays: null,
  result: null, // 1 - POSITIVE / 2 - NEGATIVE
  birthForecast: null,
  discardMotive: null,
  ovarianEvaluationResult: null,
  animalCoverageId: null,
  diagnosisDate: moment(),
  shouldDiscardFemale: false,
  shouldChangeFemaleCategoryForReproduction: false,
  lotOriginalId: null,
  lotDestinationId: null,
};

export const AnimalPregnancyDiagnosisContext = createContext({
  dataList: [],
  isLoadingDataList: false,
  showCardForm: false,
  data: INITIAL_DATA_FORM_ANIMAL_DG,
  tableProperties: {
    size: 10,
    page: 0,
    tableFilters: {},
    tableSorters: {},
    search: null,
  },
  handleTableChange: (pagination, filters, sorter) => {},
});

export const AnimalPregnancyDiagnosisContextProvider = ({ children }) => {
  const [dataList, setDataList] = useState([]);
  const [tableProperties, setTableProperties] = useState({
    size: 10,
    page: 0,
    tableFilters: {},
    tableSorters: {},
    search: null,
  });
  const [data, setData] = useState(INITIAL_DATA_FORM_ANIMAL_DG);
  const [isLoadingDataList, setIsLoadingDataList] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false);
  const [females, setFemales] = useState([]);
  const [isLoadingFemalesList, setIsLoadingFemalesList] = useState(false);

  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const fetchData = useCallback(
    async (page = 0, size = 10, sort = {}, search = null) => {
      setIsLoadingDataList(true);
      try {
        const {
          data: { results },
        } = await getAnimalsPregnancyDiagnosis({
          groupId,
          farmId,
          page,
          size,
          sort,
          search,
        });
        setDataList(results);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingDataList(false);
      }
    },
    [farmId, groupId]
  );

  const fetchFemalesDropDown = useCallback(async () => {
    setIsLoadingFemalesList(true);
    try {
      const {
        data: { results },
      } = await getAnimalDropdownListByGender({
        groupId,
        farmId,
        gender: "F",
      });
      setFemales(results);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingFemalesList(false);
    }
  }, [farmId, groupId]);

  const openOrCloseForm = () => {
    setShowCardForm((old) => !old);
  };

  const initializeData = (data) => {
    if (data === null) {
      setData(INITIAL_DATA_FORM_ANIMAL_DG);
    } else {
      setData(data);
    }
  };

  const getSortString = useCallback((sorter) => {
    let customSort = sorter;

    if (sorter && Object.entries(sorter).length > 0) {
      const { field } = sorter;

      const fieldS = field ? transformCamelCaseToSnakeCase(field) : null;

      customSort = {
        ...customSort,
        field: fieldS,
      };
    }

    return customSort;
  }, []);

  const getSearchString = useCallback((filters) => {
    let search = "";
    if (filters && Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([field, value]) => {
        if (value && value.length > 0) {
          const fieldS = field ? transformCamelCaseToSnakeCase(field) : null;
          if (fieldS === "diagnosis_date") {
            const operatorS =
              value[1] === "equal"
                ? "="
                : value[1] === "greaterThan"
                ? ">"
                : value[1] === "greaterOrEqualThan"
                ? ">="
                : value[1] === "lessThan"
                ? "<"
                : value[1] === "lessOrEqualThan"
                ? "<="
                : "=";

            search =
              search === ""
                ? `cast(${fieldS} as date) ${operatorS} cast('${moment(
                    value[0]
                  ).format("YYYY-MM-DD")}' as date)`
                : `${search};cast(${fieldS} as date) ${operatorS} cast('${moment(
                    value[0]
                  ).format("YYYY-MM-DD")}' as date)`;
          } else if (fieldS === "ovarian_evaluation_result") {
            search =
              search === ""
                ? `${fieldS} IN (${value
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`
                : `${search};${fieldS} IN (${value
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`;
          } else if (fieldS === "result") {
            search =
              search === ""
                ? `${fieldS} = ${value}`
                : `${search};${fieldS} = ${value}`;
          } else {
            search =
              search === ""
                ? `upper(${fieldS}) like upper('%25${value}%25')`
                : `${search};upper(${fieldS}) like upper('%25${value}%25')`;
          }
        }
      });
    }

    return search !== "" ? search : "";
  }, []);

  const handleTableChange = useCallback(
    async (pagination = null, filters = null, sorter = null) => {
      if (pagination && filters && sorter) {
        let customSort = getSortString(sorter);
        let search = getSearchString(filters);

        setTableProperties({
          tableFilters: filters,
          tableSorters: sorter,
          size: pagination.pageSize,
          page: pagination.current,
          search,
        });

        await fetchData(
          pagination.current,
          pagination.pageSize,
          customSort,
          search
        );
      } else {
        setTableProperties({
          size: 10,
          page: 0,
          tableFilters: {},
          tableSorters: {},
          search: null,
        });
        await fetchData(0, 10, {}, "");
      }
    },
    [getSortString, getSearchString, fetchData]
  );

  return (
    <AnimalPregnancyDiagnosisContext.Provider
      value={{
        dataList,
        tableProperties,
        fetchData,
        isLoadingDataList,
        showCardForm,
        openOrCloseForm,
        data,
        initializeData,
        females,
        isLoadingFemalesList,
        fetchFemalesDropDown,
        handleTableChange,
      }}
    >
      {children}
    </AnimalPregnancyDiagnosisContext.Provider>
  );
};
