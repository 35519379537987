import moment from "moment";

export function returnObjectFromMapValues(object) {
  const { values_map } = object;
  if (object != null && values_map != null && values_map.length > 0) {
    let result = {};
    values_map.forEach((v) => {
      let aValues = Object.entries(v);
      if (aValues.length > 1)
        result = { ...result, [aValues[0][1]]: aValues[1][1] };
    });
    return result;
  }
  return null;
}
export function returnObjectFromAxis(object, monthly = true) {
  const { values_axis } = object;
  if (object != null && values_axis != null && values_axis.length > 0) {
    let result = [];
    values_axis.forEach((v) => {
      let item = {
        x: monthly
          ? moment(moment(v.x).format("M/YY"), "M/YY").toDate()
          : moment(v.x).toDate(),
        sx: v.sx,
        y: v.y,
        y2: v.y2,
      };
      if ("parameters" in v && Object.entries(v.parameters).length > 0) {
        let aValues = Object.entries(v.parameters);
        if (aValues.length > 0) {
          aValues.forEach((p) => {
            item = { ...item, [p[0]]: p[1] };
          });
        }
      }
      result = [...result, item];
    });
    return result;
  }
  return [];
}
