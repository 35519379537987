import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

/* Redux libs */
import { useSelector, useDispatch } from "react-redux";

import { Creators as UserActions } from "../../store/ducks/user";

// Libs
import moment from "moment";

/* Styles */
import {
    Wrapper,
    Header,
    Container,
    Main,
} from "./styles";

/* Components */
import TopBar from "../../components/admin/topbar";

// Pages
import SupplierGroupHome from "./home"

/* Drawers */
import DrawerChangePasswordLogged from "../../components/drawers/changePassword/changePasswordLogged";
import DrawerEditProfile from "../../components/drawers/editProfile";
import DrawerNotifications from "../../components/drawers/notifications";
import DrawerInviteSupplierGroup from "./invites/form";

/* Modals */
import SupplementUploadModal from "../../components/modals/uploads/supplementUpload";
import ContactUsModal from "../../components/modals/contactUs";
import AboutModal from "../../components/modals/about";
import GroupAndFarmsModal from "../../components/modals/groupAndFarms";

const SupplierGroup = (props) => {

    const [newNotificationFromWS, setNewNotificationFromWS] = useState(null);

    const { translation, user, languageSelected, groupSelected, farmSelected, } = useSelector((state) => state.app);

    const dispatch = useDispatch();

    useEffect(()=>{

        /** Set locale */
        moment.locale(languageSelected);

        /** Get index Groups and Farms */
        if (Object.entries(user).length > 0)
        dispatch(UserActions.indexUserGroupsAndFarmsDescriptions(user.id))
        if (
        Object.entries(groupSelected).length > 0 &&
        Object.entries(farmSelected).length > 0
        ) {
        //this.getNotifications(groupSelected, farmSelected);
        }
        //this.connect(groupSelected, farmSelected);

        /** Verify AccountFarm */
        //if (groupId && farmId) accountFarmActions.checkAccountFarm(groupId, farmId);

        //this.updateWindowDimensions();
        //window.addEventListener("resize", this.updateWindowDimensions);
    },[]);

    return (
        <Wrapper>
            <Header>
                <TopBar />
            </Header>
            <Container>
                <Main>
                    <SupplierGroupHome />
                </Main>

            </Container>
            {/* DRAWERS */}
            <DrawerEditProfile />
            <DrawerChangePasswordLogged />
            <DrawerNotifications
                newNotificationFromWS={newNotificationFromWS}
            />
            <DrawerInviteSupplierGroup />

            {/* MODALS */}
            <ContactUsModal />
            <AboutModal />
            <SupplementUploadModal />
            <GroupAndFarmsModal />
        </Wrapper>
    );
}

export default withRouter(SupplierGroup);