import React from 'react';

const FilterIcon = ( {color, size} ) => (
   <svg xmlns="http://www.w3.org/2000/svg" width="15.137" height="24" viewBox="0 0 15.137 13.792">
      <g id="filter" transform="translate(0.26 -16.434)">
         <g id="Grupo_2332" data-name="Grupo 2332" transform="translate(0 16.684)">
            <g id="Grupo_2331" data-name="Grupo 2331" transform="translate(0 0)">
               <path id="Caminho_1872" data-name="Caminho 1872" d="M240.4,81.319h.476a.318.318,0,0,0,0-.635H240.4a.318.318,0,1,0,0,.635Z" transform="translate(-230.552 -78.143)" fill="#9074bf" stroke="#7c64a5" strokeWidth="0.5"/>
               <path id="Caminho_1873" data-name="Caminho 1873" d="M14.55,17.033a.633.633,0,0,0-.572-.349H.639a.663.663,0,0,0-.572.349.633.633,0,0,0,.064.667l5.606,7.051v4.589a.623.623,0,0,0,.333.556.657.657,0,0,0,.3.079.618.618,0,0,0,.365-.111L8.5,28.642a.971.971,0,0,0,.4-.778V24.735l5.59-7.035A.633.633,0,0,0,14.55,17.033Zm-6.225,7.4a.341.341,0,0,0-.064.191v3.224a.339.339,0,0,1-.127.27L6.372,29.341V24.624a.288.288,0,0,0-.127-.254L2.656,19.86H8.42a.318.318,0,1,0,0-.635H2.163L.639,17.319h13.34Z" transform="translate(0 -16.684)" fill="#fff" stroke="#fff" strokeWidth="0.5"/>
            </g>
         </g>
      </g>
   </svg>
);


export default FilterIcon;

