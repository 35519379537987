import axios from "axios";
import apiOptimization from "../config/api_optimization";
import getHeaders from "./getHeaders";

export function indexSaleScenario(payload) {
  const { groupId, farmId, page, sorter, filters, size, signal, ids } = payload;
  let sortS = "";
  if (sorter && Object.entries(sorter).length > 0) {
    const { field, order } = sorter;

    const fieldS = field
      ? field === "nextCommitmentDate"
        ? "next_commitment_date"
        : field
      : null;

    const orderS = order && order === "descend" ? "DESC" : "ASC";

    sortS = `&sort=${fieldS}&direction=${orderS}`;
  }

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/list?
    ${page ? `&page=${page - 1}` : ""}
    ${size && `&size=${size}`}
    ${sortS}
    ${filters !== "" && filters ? `&search=${filters}` : ""}`,
    ids != null ? ids : null,
    getHeaders(false, signal)
  );
}

export function indexDropDownSaleScenario(payload) {
  const { groupId, farmId, signal } = payload;
  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/list?withoutPagination=true`,
    null,
    getHeaders(false, signal)
  );
}

export function storeSaleScenario(payload) {
  const { groupId, farmId, body, signal } = payload;

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales`,
    body,
    getHeaders(false, signal)
  );
}

export function reprocessSaleScenario(payload) {
  const { groupId, farmId, id } = payload;

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${id}/reprocess`,
    null,
    getHeaders(false, null)
  );
}

export function inactiveSaleScenario(payload) {
  const { groupId, farmId, id } = payload;

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${id}/inactivate`,
    null,
    getHeaders(false, null)
  );
}

export function showSaleScenario(payload) {
  const { groupId, farmId, saleScenarioId, saleScenarioParamId } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/params/${saleScenarioParamId}`,
    getHeaders(false, null)
  );
}

export function showSaleScenarioLots(payload) {
  const { groupId, farmId, saleScenarioId } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/lots`,
    getHeaders(false, null)
  );
}

export function showSaleScenarioCommitments(payload) {
  const { groupId, farmId, saleScenarioId } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/commitments`,
    getHeaders(false, null)
  );
}

export function showSaleScenarioCommitmentsByParameter(payload) {
  const { groupId, farmId, saleScenarioId, saleScenarioParamId } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/params/${saleScenarioParamId}/commitments`,
    getHeaders(false, null)
  );
}

export function getAllSaleScenarioResultByIds(payload) {
  const { groupId, farmId, saleScenarioIds } = payload;

  let params =
    saleScenarioIds?.length > 0
      ? saleScenarioIds?.map((o, index) =>
          index === 0 ? `&saleScenarioIds=${o}` : `&saleScenarioIds=${o}`
        )
      : [];
  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/results?
    ${params.length > 0 ? `${params.join("")}` : ""}`,
    getHeaders(false, null)
  );
}

export function showSaleScenarioResult(payload) {
  const { groupId, farmId, saleScenarioId } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/results`,
    getHeaders(false, null)
  );
}
export function showAnimalSaleScenarioResultBaseline(payload) {
  const { groupId, farmId, saleScenarioId, animalId } = payload;
  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/results/baseline/${
      animalId != null ? `?animalId=${animalId}` : ``
    }`,
    getHeaders(false, null)
  );
}

export function showSaleScenarioResultBySaleScenarioResultId(payload) {
  const { groupId, farmId, saleScenarioId, saleScenarioResultId } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/results/${saleScenarioResultId}`,
    getHeaders(false, null)
  );
}

export function showSaleScenarioResultSells(payload) {
  const { groupId, farmId, saleScenarioId, saleScenarioResultId } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/results/${saleScenarioResultId}/sales`,
    getHeaders(false, null)
  );
}

export function showSaleScenarioResultSellsAnimals(payload) {
  const { groupId, farmId, page, sorter, filters, size, saleScenarioId } =
    payload;

  let sortS = "";

  if (sorter && Object.entries(sorter).length > 0) {
    const { field, order } = sorter;

    const fieldS = field
      ? field === "nextCommitmentDate"
        ? "next_commitment_date"
        : field
      : null;

    const orderS = order && order === "descend" ? "DESC" : "ASC";

    sortS = `&sort=${fieldS}&direction=${orderS}`;
  }

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/results/sales/animals?
    ${page ? `&page=${page - 1}` : ""}
    ${size && `&size=${size}`}
    ${sortS}`,
    filters,
    getHeaders(false, null)
  );
}

export function showAllSaleScenarioResultSellsAnimals(payload) {
  const { groupId, farmId, saleScenarioId, saleScenarioResultId } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/results/${saleScenarioResultId}/sales/not/sold/animals`,
    getHeaders(false, null)
  );
}

export function showEditSaleScenarioParam(payload) {
  const { groupId, farmId, saleScenarioId } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}?onlyParameters=true`,
    getHeaders(false, null)
  );
}

export function saveSaleScenarioParam(payload) {
  const { groupId, farmId, saleScenarioId, body } = payload;

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/params`,
    body,
    getHeaders(false, null)
  );
}

export function setSaleScenarioResultBaseline(payload) {
  const { groupId, farmId, saleScenarioId, saleScenarioResultId } = payload;

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/results/${saleScenarioResultId}`,
    null,
    getHeaders(false, null)
  );
}

export function cancelSaleScenarioResultBaseline(payload) {
  const { groupId, farmId, saleScenarioId, saleScenarioResultId } = payload;

  return apiOptimization.delete(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/results/${saleScenarioResultId}`,
    getHeaders(false, null)
  );
}

export function updateSaleScenarioResult(payload) {
  const { groupId, farmId, saleScenarioId, saleScenarioResultId, body } =
    payload;

  return apiOptimization.put(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/results/${saleScenarioResultId}`,
    body,
    getHeaders(false, null)
  );
}

export function getLotAndDietStragySaleScenarioResultSuggest(payload) {
  const { groupId, farmId, saleScenarioId, saleScenarioResultId } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/results/${saleScenarioResultId}/diet/strategies`,
    getHeaders(false, null)
  );
}

export function getResultSumarySell(payload) {
  const { groupId, farmId, saleScenarioId } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/results/summary/sell`,
    getHeaders(false, null)
  );
}

export function getResultFutureAndRealizedSell(payload) {
  const { groupId, farmId, saleScenarioId } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/results/future-and-realized/sell`,
    getHeaders(false, null)
  );
}

export function compareSaleScenarios(payload) {
  const { groupId, farmId, body } = payload;

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/compare`,
    body,
    getHeaders(false, null)
  );
}

export function compareSaleScenariosGeneratePdf(payload) {
  const { groupId, farmId, body } = payload;

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/compare/download/pdf`,
    body,
    getHeaders(false, null, true)
  );
}

export function exportXlsSaleScenarioResult(payload) {
  const { groupId, farmId, saleScenarioId, saleScenarioResultId, body } =
    payload;

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/results/${saleScenarioResultId}/export/xls`,
    body,
    getHeaders(false, null, true)
  );
}

export function findPriceLock(payload) {
  const { groupId, farmId, saleScenarioId, saleScenarioResultId } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/results/${saleScenarioResultId}/price/lock`,
    getHeaders(false, null)
  );
}

export function saveContracts(payload) {
  const { groupId, farmId, saleScenarioId, saleScenarioResultId, body } =
    payload;

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/${saleScenarioId}/results/${saleScenarioResultId}/price/lock`,
    body,
    getHeaders(false, null, true)
  );
}
