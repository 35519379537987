import React from 'react';

// import { Container } from './styles';

const FarmIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30.76" height="30.759" viewBox="0 0 30.76 30.759">
    <g id="icon-fazenda" transform="translate(-33 -187)">
      <path
        id="Caminho_681"
        data-name="Caminho 681"
        d="M338.764,17.8l-.011,0a.6.6,0,1,0-.446,1.116l.007,0a.6.6,0,1,0,.45-1.114Z"
        transform="translate(-284.627 170.313)"
        fill="#c4c4c4"
      />
      <path
        id="Caminho_677"
        data-name="Caminho 677"
        d="M157.764,76.769a.6.6,0,0,0-.37-.4,4.273,4.273,0,0,0-.793-.185,5.1,5.1,0,0,0,1.163-5.011.6.6,0,0,0-.37-.4,5.879,5.879,0,0,0-.721-.174,4.947,4.947,0,0,0-2.51.208,5.4,5.4,0,0,0,.15-1.283,5.1,5.1,0,0,0-2.724-4.546.6.6,0,0,0-.541-.018,5.084,5.084,0,0,0-2.759,4.564,5.4,5.4,0,0,0,.151,1.286,5.17,5.17,0,0,0-3.238-.036.6.6,0,0,0-.37.4,5.1,5.1,0,0,0,1.163,5.011,4.272,4.272,0,0,0-.793.185.6.6,0,0,0-.37.4,5.1,5.1,0,0,0,1.289,5.141,4.985,4.985,0,0,0,3.606,1.518,5.373,5.373,0,0,0,.967-.088v3.931a.6.6,0,1,0,1.2,0V83.339a5.381,5.381,0,0,0,.975.089,4.985,4.985,0,0,0,3.606-1.518A5.1,5.1,0,0,0,157.764,76.769Zm-7.072,4.7a4.006,4.006,0,0,1-.088.667,3.877,3.877,0,0,1-3.635-1.074c-1.365-1.365.121-3.82.993-3.537q.086.032.172.061a3.938,3.938,0,0,1,1.4.916,3.83,3.83,0,0,1,1.162,2.567Zm0-5.591a4,4,0,0,1-.088.667,3.992,3.992,0,0,1-2.023-.081q-.13-.05-.259-.091a3.967,3.967,0,0,1-1.354-.9c-1.365-1.365,1.182-3.94,2.561-2.561l.025.026q.079.089.158.17a3.8,3.8,0,0,1,.979,2.371Zm.609-3.014a4.085,4.085,0,0,1-.735-.607l-.086-.092a3.832,3.832,0,0,1-.991-2.629c0-1.958,3.621-1.932,3.621,0a3.832,3.832,0,0,1-.9,2.529c-.1.1-.188.2-.274.295A4.033,4.033,0,0,1,151.3,72.862Zm.6,2.82a3.8,3.8,0,0,1,.929-2.515c.089-.088.177-.183.266-.284,1.379-1.356,3.9,1.221,2.534,2.587a3.967,3.967,0,0,1-1.351.9q-.131.042-.263.093a3.991,3.991,0,0,1-2.02.08,4.032,4.032,0,0,1-.093-.846C151.9,75.692,151.9,75.687,151.9,75.682Zm3.728,5.379a3.878,3.878,0,0,1-3.634,1.075,3.9,3.9,0,0,1,1.073-3.635,3.966,3.966,0,0,1,1.355-.9q.128-.041.258-.091C155.533,77.239,156.992,79.694,155.626,81.06Z"
        transform="translate(-102.918 125.987)"
        fill="#c4c4c4"
      />
      <path
        id="Caminho_678"
        data-name="Caminho 678"
        d="M18.585.335A15.483,15.483,0,0,0,15.379,0,15.379,15.379,0,0,0,3.25,24.834a2.28,2.28,0,1,0,.9-.806A14.184,14.184,0,0,1,18.336,1.51.6.6,0,0,0,18.585.335ZM4.4,25.305a1.081,1.081,0,1,1-.317.765A1.078,1.078,0,0,1,4.4,25.305Z"
        transform="translate(33 187)"
        fill="#c4c4c4"
      />
      <path
        id="Caminho_679"
        data-name="Caminho 679"
        d="M217.9,43.57a2.28,2.28,0,1,0-.9.805,14.184,14.184,0,0,1-13.738,22.607.6.6,0,0,0-.211,1.183,15.522,15.522,0,0,0,2.715.239A15.379,15.379,0,0,0,217.9,43.57Zm-1.155-.471a1.081,1.081,0,1,1,.317-.765A1.074,1.074,0,0,1,216.741,43.1Z"
        transform="translate(-157.387 149.355)"
        fill="#c4c4c4"
      />
      <path
        id="Caminho_680"
        data-name="Caminho 680"
        d="M155.183,474.4l-.227.556.225-.557a.6.6,0,0,0-.45,1.114l.019.007a.6.6,0,0,0,.433-1.121Z"
        transform="translate(-112.082 -258.862)"
        fill="#c4c4c4"
      />
    </g>
  </svg>
);
export default FarmIcon;
