import { Tabs } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .cardGrid {
    width: 100%;

    .ant-card-body {
      padding-bottom: 0px !important;
    }
    .btn-comparable {
      &:disabled {
        background: #f0f0f0 !important;
        color: #adadad !important;
      }
    }

    .ant-table-wrapper {
      margin-bottom: 10px !important;
    }
  }

  .gridRow {
    /* height: 100vh !important; */
    margin-top: 23px !important;
  }

  div.colLabelForm {
    margin-bottom: 7px !important;
  }
`;

export const Dashboard = styled.div`
  min-height: 114px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 3px 6px #d8d8d8;
  margin-top: 10px;
  padding: 23px 13px 20px 20px;

  span {
    font-family: "Asap", sans-serif;
    text-align: left;
    font-size: 14px;
    color: #4b4b4b;

    &.title {
      font-weight: bold;
      font-style: italic;
      color: #9074bf;
      text-transform: uppercase;
    }

    &.subtitle {
      font-weight: bold;
      color: #9074bf;
      font-size: 12px;
      text-transform: uppercase;
    }

    &.value {
      font-weight: bold;
    }
  }
`;

export const PageTitle = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 25px;
  text-align: left;
  color: #9074bf;
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;

export const NewProfitCenter = styled.button`
  width: 194.2px;
  height: 24px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #684e94;
  color: #684e94;
  text-align: center;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
`;

export const TabContainer = styled(Tabs)`
  margin-bottom: 10px !important;

  .ant-tabs-ink-bar {
    background-color: #684e94;
    height: 5px;
  }

  .ant-tabs-line {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab {
    font-family: "Asap" !important;
    font-size: 14px !important;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab-disabled,
  .ant-tabs-nav .ant-tabs-tab-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: #4b4b4b;
    font-weight: bold;
  }

  .ant-tabs-bar {
    border-bottom: 1px solid #a9c133;
  }

  .ant-tabs-tabpane {
    background-color: #fff;
    margin-top: -15px;
  }
`;
