import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Creators as BoitelSaleScenarioActions } from "../../../store/ducks/boitelSaleScenario";
import useBoitelSaleScnearioNewContext from "../../../hooks/useBoitelSaleScnearioNewContext";
// Components
import { Col, notification, Row } from "antd";
import RoutingLeavingGuardModal from "../../../components/modals/routingLeavingGuardModal";
import BreadCrumbs from "../../../components/utils/breadCrumbs";
import ButtonStandard from "../../../components/utils/button";
import { Body, Container, CustomDivider, Title } from "./styles";
import Loading from "../../../components/utils/loading";
// Tabs
import TabBoitelSaleScenarioNewParameters from "./tabs/parameters";
import TabBoitelSaleScenarioNewSelectDietStragies from "./tabs/selectDietStrategies";
import TabBoitelSaleScenarioNewSelectLots from "./tabs/selectLots";

const NewBoitelSaleScenario = () => {
  const history = useHistory();

  const {
    form,
    shouldBlockNavigation,
    setShouldBlockNavigation,
    activeTab,
    setActiveTab,
  } = useBoitelSaleScnearioNewContext();
  const [isCanceling, setIsCaneling] = useState(false);

  const { translation } = useSelector((state) => state.app);
  const { redirectToListPage, isLoadingSaveRequest } = useSelector(
    (state) => state.boitelSaleScenario
  );
  const dispatch = useDispatch();

  // Effect
  useEffect(() => {
    if (isCanceling === true) {
      history.push("/admin/decisions", { tab: "4" });
    }
  }, [history, isCanceling]);
  useEffect(() => {
    setShouldBlockNavigation(true);
  }, [setShouldBlockNavigation]);
  useEffect(() => {
    if (redirectToListPage) {
      history.push("/admin/decisions", { tab: "4" });
      setIsCaneling(false);
      dispatch(BoitelSaleScenarioActions.resetRedirectToListPage());
    }
  }, [dispatch, history, redirectToListPage]);

  // Methods
  const validateSteps = useCallback((currentStep, form) => {
    let result = true;
    if (currentStep === "tab1") {
      if (form.parameter.lots.length === 0) {
        result = false;
        notification.error({
          message: "É necessário selecionar ao menos um lote.",
        });
      }
    }
    if (currentStep === "tab2") {
      if (
        form.parameter.lots.filter((l) => {
          return l.dietStrategies.filter((ds) => ds.check === false).length ===
            l.dietStrategies.length
            ? true
            : false;
        }).length > 0
      ) {
        result = false;
        notification.error({
          message:
            "É necessário selecionar ao menos uma estratégia de dieta por lote.",
        });
      }
    }
    return result;
  }, []);

  const goNextTab = useCallback(() => {
    if (validateSteps(activeTab, form)) {
      setActiveTab((old) =>
        old === "tab1" ? "tab2" : old === "tab2" ? "tab3" : "tab3"
      );
    }
  }, [activeTab, form, setActiveTab, validateSteps]);

  const handleCancel = useCallback(() => {
    setIsCaneling(true);
  }, []);

  return (
    <>
      <RoutingLeavingGuardModal
        when={shouldBlockNavigation && !redirectToListPage && !isCanceling}
        navigate={(path) =>
          path === "/admin/decisions"
            ? history.push(path, { tab: "4" })
            : history.push(path)
        }
        message="Parece que você está saindo sem salvar o cenário de venda."
      />
      {isLoadingSaveRequest && <Loading />}
      <Container>
        <Row type="flex" justify="space-between" align="middle">
          <Col span={12}>
            <Title>{translation.boitelSaleScenario.new.title}</Title>
          </Col>
          <Col span={12} align="right">
            <BreadCrumbs>
              <span
                onClick={handleCancel}
                className="pageTreeInative"
                style={{ cursor: "pointer" }}
              >
                Decisões
              </span>
              <span className="pageTreeActive">
                {translation.boitelSaleScenario.new.title}
              </span>
            </BreadCrumbs>
          </Col>
        </Row>
        <CustomDivider dashed />
        <Body>
          {/* Tabs */}
          <Row type="flex" style={{ marginBottom: 24 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {activeTab === "tab1" ? (
                <TabBoitelSaleScenarioNewSelectLots />
              ) : activeTab === "tab2" ? (
                <TabBoitelSaleScenarioNewSelectDietStragies />
              ) : activeTab === "tab3" ? (
                <TabBoitelSaleScenarioNewParameters />
              ) : null}
            </Col>
          </Row>
          {/* Footer */}
          {activeTab !== "tab3" && (
            <Row type="flex" justify="end" gutter={8}>
              <Col>
                <ButtonStandard
                  type="button"
                  buttonType="secondary"
                  onClick={handleCancel}
                >
                  {translation.buttons.cancel}
                </ButtonStandard>
              </Col>
              <Col>
                <ButtonStandard
                  type="button"
                  buttonType="principal"
                  onClick={goNextTab}
                >
                  Próximo
                </ButtonStandard>
              </Col>
            </Row>
          )}
        </Body>
      </Container>
    </>
  );
};

export default NewBoitelSaleScenario;
