import React, { Component } from "react";
import Axios from "axios";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import "moment/locale/pt-br";
import "moment/locale/en-nz";
import "moment/locale/es";

/* Redux libs */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as FarmActions } from "../../store/ducks/farm";

/** Styles Components */
import { Title, CardCustom } from "./styles";
import TagStatus from "../../components/utils/tagStatus";

/** Components */
import { Row, Col, Table, Dropdown, Menu, Icon } from "antd";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import MenuIcon from "../../components/utils/table/icons/menu";
import DrawerFarm from "../../components/drawers/farm";
import ButtonStandard from "../../components/utils/button";
import AllowedComponentTo from "../../components/utils/allowedComponentTo";

const { Column } = Table;

class Farm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      success: false,
      tableProps: null,
    };
  }

  static propTypes = {
    farmActions: PropTypes.any.isRequired,
    app: PropTypes.any.isRequired,
    farm: PropTypes.any.isRequired,
  };

  signal = Axios.CancelToken.source();

  componentWillUnmount() {
    this.signal.cancel("Cancelando apis");
  }

  async componentDidMount() {
    const {
      app: { groupSelected },
    } = this.props;
    if (Object.entries(groupSelected).length > 0) {
      this.fetchData(groupSelected);
    }
  }

  async shouldComponentUpdate(nextProps) {
    const differentGroup =
      this.props.app.groupSelected?.id !== nextProps.app.groupSelected?.id;
    if (differentGroup) {
      if (Object.entries(nextProps.app.groupSelected).length > 0) {
        this.fetchData(nextProps.app.groupSelected);
      }
    }
    return true;
  }

  fetchData = (
    groupSelected,
    page = null,
    sorter = null,
    filters = null,
    size = null
  ) => {
    const { farmActions } = this.props;
    const { tableProps } = this.state;
    const { id: groupId } = groupSelected;
    if (page === null && sorter === null && filters === null && size === null) {
      if (tableProps === null) {
        this.setState({
          ...this.state,
          tableProps: {
            page: 0,
            sorter: {},
            filters: "",
            size: 10,
          },
        });
        farmActions.indexFarm(groupId, 0, {}, "", 10, this.signal);
      } else {
        farmActions.indexFarm(
          groupId,
          tableProps?.page,
          tableProps?.sorter,
          tableProps?.filters,
          tableProps?.size,
          this.signal
        );
      }
    } else {
      this.setState({
        ...this.state,
        tableProps: {
          page,
          sorter,
          filters,
          size,
        },
      });
      farmActions.indexFarm(groupId, page, sorter, filters, size, this.signal);
    }
  };

  isAllowed = (roles, reverse) => {
    const {
      user: { roles: userRoles },
    } = this.props.app;
    return userRoles?.includes("Admin")
      ? true
      : roles.length === 0
      ? true
      : reverse === false
      ? roles.some((r) => userRoles?.includes(r))
      : !roles.some((r) => userRoles?.includes(r));
  };

  handleTableChange = (pagination, filters, sorter) => {
    const {
      app: { groupSelected },
    } = this.props;

    let search = "";

    if (Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v.length > 0)
          search = search === "" ? `${k}='${v}'` : `${search};${k}='${v}'`;
      });
    }
    this.fetchData(
      groupSelected,
      pagination.current,
      sorter,
      search,
      pagination.pageSize
    );
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  handleReset = (clearFilters) => {
    clearFilters();
  };

  handleEdit = (id) => {
    const {
      app: {
        groupSelected: { id: groupId },
      },
      farmActions,
    } = this.props;
    farmActions.showFarm(groupId, id);
    farmActions.showDrawer();
  };

  handleDelete = (id) => {
    const {
      app: {
        groupSelected: { id: groupId },
      },
      farmActions,
    } = this.props;
    farmActions.deleteFarm(groupId, id);
  };

  handleActivate = (id) => {
    const {
      app: {
        groupSelected: { id: groupId },
      },
      farmActions,
    } = this.props;
    farmActions.activateFarm(groupId, id);
  };

  render() {
    const {
      farm: { data, isLoading },
      app: { translation, groupSelected },
      farmActions,
    } = this.props;

    const menu = (id, status) => {
      const {
        app: {
          translation,
          farmSelected: { id: farmIdSelected },
        },
      } = this.props;
      return (
        <Menu>
          {status === "Active" &&
            this.isAllowed(["Trial", "Technician"], true) && (
              <Menu.Item key="0" onClick={() => this.handleEdit(id)}>
                {translation.table.menu.edit}
              </Menu.Item>
            )}
          {farmIdSelected !== id &&
            status === "Active" &&
            this.isAllowed(["Admin Farm", "Trial", "Technician"], true) && (
              <Menu.Item key="1" onClick={() => this.handleDelete(id)}>
                {translation.table.menu.inactivate}
              </Menu.Item>
            )}
          {farmIdSelected !== id &&
            status === "Inactive" &&
            this.isAllowed(["Admin Farm", "Trial", "Technician"], true) && (
              <Menu.Item key="2" onClick={() => this.handleActivate(id)}>
                {translation.table.menu.activate}
              </Menu.Item>
            )}
          {/* <Menu.Divider />
          <Menu.Item key="1">
            <Link to={`/admin/farms/${id}`}>
              {translation.table.menu.details}
            </Link>
          </Menu.Item> */}
        </Menu>
      );
    };
    return (
      <CardCustom>
        <Row type="flex" justify="space-between" className="rowHeader">
          <Col>
            <Title>{translation.farm.table.title}</Title>
          </Col>
          <Col>
            <ButtonStandard
              buttonType="typeWithoutBackground"
              onClick={() => this.fetchData(groupSelected)}
            >
              <Icon type="reload" />
            </ButtonStandard>
            <AllowedComponentTo roles={["Technician", "Trial"]} reverse hide>
              <ButtonStandard
                buttonType="type8"
                onClick={() => farmActions.showDrawer("new")}
              >
                {translation.buttons.buttonNewFarm}
              </ButtonStandard>
            </AllowedComponentTo>
          </Col>
        </Row>
        <Row type="flex">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              loading={isLoading}
              rowKey="id"
              dataSource={data != null ? data.content : []}
              pagination={{
                total: data != null ? data.totalElements : 0,
                size: data != null ? data.size : 0,
                current: data != null ? data.number + 1 : 0,
                hideOnSinglePage:
                  data !== null && Object.entries(data).length !== 0
                    ? data.totalElements > 10
                      ? false
                      : true
                    : true,
                showSizeChanger: true,
                pageSizeOptions: [
                  "10",
                  "20",
                  "30",
                  "100",
                  "500",
                  "1000",
                  "2000",
                ],
              }}
              size="small"
              scroll={{
                x: true,
              }}
              onChange={this.handleTableChange}
            >
              <Column
                title={translation.farm.table.columns.status}
                dataIndex="status"
                key="status"
                align="left"
                sorter
                render={(status) =>
                  status === "Active" ? (
                    <TagStatus
                      background="#C5F1CA"
                      borderColor="#106518"
                      color="#106518"
                    >
                      {translation.status.active}
                    </TagStatus>
                  ) : (
                    <TagStatus
                      background="#FBC7C7"
                      borderColor="#D44C4C"
                      color="#D44C4C"
                    >
                      {translation.status.inactive}
                    </TagStatus>
                  )
                }
              />
              <Column
                title="Grupo"
                dataIndex="groupName"
                sorter
                key="groupName"
                align="left"
              />
              <Column
                title={translation.farm.table.columns.name}
                dataIndex="name"
                sorter
                key="name"
                align="left"
              />
              <Column
                title="Cidade/UF"
                dataIndex="city"
                sorter
                key="city"
                align="left"
                render={(text, record) => (
                  <span>
                    {record.city != null &&
                      record.state != null &&
                      `${record.city}/${record.state}`}
                  </span>
                )}
              />
              <Column
                align="left"
                render={(text, record) => (
                  <Dropdown
                    overlay={menu(record.id, record.status)}
                    trigger={["click"]}
                    key={record.id}
                  >
                    <MenuContainer>
                      <MenuIcon />
                    </MenuContainer>
                  </Dropdown>
                )}
              />
            </Table>
          </Col>
        </Row>
        <DrawerFarm />
      </CardCustom>
    );
  }
}
const mapStateToProps = (state) => ({
  app: state.app,
  farm: state.farm,
});

const mapDispatchToProps = (dispatch) => ({
  farmActions: bindActionCreators(FarmActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Farm));
