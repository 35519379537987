import React, { useRef, useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSelector } from "react-redux";

// Components
import { Container, Footer } from "./styles";
import {
  Row,
  Col,
  Input,
  notification,
  Spin,
  DatePicker,
  Table,
  Button,
  Modal,
} from "antd";
import ButtonStandard from "../../utils/button";
import useMultipleBullContext from "../../../hooks/useMultipleBullContext";

// Services
import { save } from "../../../services/multipleBullService";
import moment from "moment";
import { getAnimalDropdownListByGender } from "../../../services/animalService";
import SelectAnimalsModal from "../../modals/selectAnimalsModal";
import TrashIcon from "../../utils/icons/trash";

const validationSchema = Yup.object({
  name: Yup.string().trim().max(30).required(),
  code: Yup.string().trim().max(10).nullable(),
  formationDate: Yup.date().required(),
  males: Yup.array()
    .of(
      Yup.object().shape({
        animalId: Yup.string().required(),
        dateIn: Yup.date().required(),
      })
    )
    .min(1)
    .required(),
});

const DrawerMultipleBull = () => {
  const formRef = useRef(null);

  const [form, setForm] = useState({
    id: null,
    name: "",
    code: "",
    formationDate: null,
    males: [],
    status: "Active",
  });
  const [generalDateIn, setGeneralDateIn] = useState(null);
  const [generalDateOut, setGeneralDateOut] = useState(null);
  const [males, setMales] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  // Redux Variable
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const { isDrawerVisible, data, closeDrawer, fetchData } =
    useMultipleBullContext();

  useEffect(() => {
    if (data !== null) {
      setForm(data);
    }
  }, [data]);

  // Method

  async function fetchMales() {
    try {
      const {
        data: { results },
      } = await getAnimalDropdownListByGender({
        groupId,
        farmId,
        gender: "M",
      });
      setMales(
        results.filter(
          (a) => a.animalFarmFunction === "reproduction" && a.cap === false && (a.status === "A" || a.status === "Active")
        )
      );
    } catch (error) {
      console.error(error);
    }
  }

  function handleCloseDrawer() {
    setForm({
      id: null,
      name: "",
      code: "",
      formationDate: null,
      males: [],
      status: "Active",
    });
    formRef.current.resetForm({
      id: null,
      name: "",
      code: "",
      formationDate: null,
      males: [],
      status: "Active",
    });
    closeDrawer();
  }

  function handleSubmitForm(values) {
    const animalsWithDateOut = values.males.filter(
      (m) => m.dateOut != null
    ).length;
    if (values.males.length === animalsWithDateOut) {
      Modal.confirm({
        content:
          'Todos os reprodutores foram marcados com saída, o status do Touro Múltiplo ficará como "Inativo". Deseja continuar?',
        centered: true,
        onOk: () => {
          handleSave(values);
        },
      });
    } else {
      const animalsWithDateInBeforeFormationDate = values.males.filter((m) =>
        moment(m.dateIn).isBefore(moment(values.formationDate))
      ).length;

      if (animalsWithDateInBeforeFormationDate > 0) {
        notification.error({
          message:
            "Existem reprodutores com a data de entrada registradas antes da formação do Touro Múltiplo.",
        });
      } else {
        handleSave(values);
      }
    }
  }

  async function handleSave(body) {
    setIsLoadingRequest(true);
    try {
      await save({ groupId, farmId, id: body?.id, body });
      notification.success({
        message: "Touro múltiplo cadastrado/atualizado com sucesso.",
      });
      fetchData();
      handleCloseDrawer();
    } catch (error) {
      if (Object.keys(error).includes("response")) {
        const { response } = error;
        if (Object.keys(response).includes("data")) {
          const {
            data: { code: errorCode, errors },
          } = response;
          if (errorCode === 5026) {
            notification.error({
              message: "Erro",
              description: `Os reprodutores (${errors
                .map((e) => e.name)
                .join(", ")}) já fazem parte em outro Touro Múltiplo.`,
            });
          } else {
            notification.error({
              message: "Erro",
              description: "Erro ao cadastrar/editar touro múltiplo.",
            });
          }
        }
      } else {
        notification.error({
          message: "Erro",
          description: "Erro ao cadastrar/editar touro múltiplo.",
        });
      }
    } finally {
      setIsLoadingRequest(false);
    }
  }

  function handleDrawerVisible(visible) {
    if (visible) {
      fetchMales();
      setGeneralDateIn(null);
      setGeneralDateOut(null);
      if (data === null) {
        formRef.current.resetForm({
          id: null,
          name: "",
          code: "",
          formationDate: null,
          males: [],
          status: "Active",
        });
      }
    }
  }

  function handleOpenModalSelect() {
    setModalVisible(true);
  }

  function handleCancelSelection() {
    setModalVisible(false);
  }

  function handleConfirmSelection(ids) {
    const malesValues = formRef.current.state.values.males ?? [];
    const formationDateValues =
      formRef.current.state.values.formationDate ?? null;
    const newMalesValues =
      malesValues.length === 0
        ? ids.map((id) => ({
            animalId: id,
            animalHandlingNumber:
              males.find((m) => m.id === id)?.handlingNumber || "",
            dateIn: formationDateValues
              ? moment(formationDateValues)
              : moment(),
            dateOut: null,
          }))
        : [
            ...malesValues,
            ...ids
              .filter(
                (id) => !malesValues.map((mv) => mv.animalId).includes(id)
              )
              .map((id) => ({
                animalId: id,
                animalHandlingNumber:
                  males.find((m) => m.id === id)?.handlingNumber || "",
                dateIn: formationDateValues
                  ? moment(formationDateValues)
                  : moment(),
                dateOut: null,
              })),
          ];
    formRef.current.setFieldValue("males", newMalesValues);
    setModalVisible(false);
  }

  return (
    <Container
      title={
        data?.id == null
          ? translation.multipleBull.form.titleCreateNewMultipleBull
          : translation.multipleBull.form.titleEditMultipleBull
      }
      width={700}
      onClose={handleCloseDrawer}
      maskClosable={false}
      visible={isDrawerVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Spin spinning={isLoadingRequest}>
        <Formik
          ref={formRef}
          enableReinitialize={true}
          initialValues={form}
          onSubmit={handleSubmitForm}
          validationSchema={validationSchema}
          render={({
            values,
            errors,
            submitCount,
            setFieldValue,
            handleSubmit,
          }) => (
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className="drawerForm">
                {errors.length > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                {/* Registry */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={errors.code && submitCount > 0 ? "error" : ""}
                  >
                    {translation.multipleBull.form.code}
                  </label>
                </Row>
                <Row type="flex">
                  <Input
                    name="code"
                    maxLength={10}
                    max={10}
                    value={values.code}
                    placeholder={translation.defaultPlaceholder}
                    autoCapitalize="off"
                    onChange={(e) => {
                      setFieldValue("code", e.target.value);
                    }}
                  />
                </Row>
                {/* Name */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={errors.name && submitCount > 0 ? "error" : ""}
                  >
                    {translation.multipleBull.form.name}*
                  </label>
                </Row>
                <Row type="flex">
                  <Input
                    name="name"
                    maxLength={30}
                    max={30}
                    value={values.name}
                    placeholder={translation.defaultPlaceholder}
                    autoCapitalize="off"
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                  />
                </Row>
                {/* Registry */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={
                      errors.formationDate && submitCount > 0 ? "error" : ""
                    }
                  >
                    {translation.multipleBull.form.formationDate}*
                  </label>
                </Row>
                <Row type="flex">
                  <DatePicker
                    name="formationDate"
                    format={"DD/MM/YYYY"}
                    allowClear={false}
                    disabledDate={(current) => current.isAfter(moment())}
                    value={
                      values.formationDate
                        ? moment(values.formationDate)
                        : undefined
                    }
                    placeholder={translation.defaultDatePickerPlaceholder}
                    onChange={(date) => {
                      setFieldValue("formationDate", date);
                      setGeneralDateIn(date);
                    }}
                  />
                </Row>
                {/* Registry */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={errors.males && submitCount > 0 ? "error" : ""}
                  >
                    {translation.multipleBull.form.males}
                  </label>
                </Row>
                <Row type="flex" justify="center">
                  <ButtonStandard
                    type="button"
                    buttonType="secondary"
                    onClick={handleOpenModalSelect}
                  >
                    Selecionar Reprodutores
                  </ButtonStandard>
                </Row>
                {values.males.length > 0 && (
                  <>
                    <Row type="flex">
                      <label>Entrada Geral</label>
                    </Row>
                    <Row gutter={8} type="flex">
                      <Col>
                        <DatePicker
                          name="generalDateIn"
                          format={"DD/MM/YYYY"}
                          allowClear={false}
                          disabledDate={(current) =>
                            current.isAfter(moment()) ||
                            (values.formationDate &&
                              current.isBefore(values.formationDate))
                          }
                          value={
                            generalDateIn ? moment(generalDateIn) : undefined
                          }
                          placeholder={translation.defaultDatePickerPlaceholder}
                          onChange={(date) => setGeneralDateIn(date)}
                        />
                      </Col>
                      <Col>
                        <Button
                          onClick={() =>
                            setFieldValue(
                              "males",
                              values.males.map((m) => {
                                m.dateIn = generalDateIn;
                                return m;
                              })
                            )
                          }
                        >
                          Aplicar
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
                {values.males.length > 0 && values.id !== null && (
                  <>
                    <Row type="flex" style={{ marginTop: 8 }}>
                      <label>Saída Geral</label>
                    </Row>
                    <Row gutter={8} type="flex">
                      <Col>
                        <DatePicker
                          name="generalDateOut"
                          format={"DD/MM/YYYY"}
                          allowClear
                          disabledDate={(current) =>
                            current.isAfter(moment()) ||
                            (values.formationDate &&
                              current.isBefore(values.formationDate))
                          }
                          value={
                            generalDateOut ? moment(generalDateOut) : undefined
                          }
                          placeholder={translation.defaultDatePickerPlaceholder}
                          onChange={(date) => setGeneralDateOut(date)}
                        />
                      </Col>
                      <Col>
                        <Button
                          onClick={() =>
                            setFieldValue(
                              "males",
                              values.males.map((m) => {
                                m.dateOut = generalDateOut;
                                return m;
                              })
                            )
                          }
                        >
                          Aplicar
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
                {values.males.length > 0 && (
                  <Row type="flex" justify="end">
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={() => setFieldValue("males", [])}
                    >
                      <TrashIcon /> Remover todos
                    </ButtonStandard>
                  </Row>
                )}
                <Row type="flex" style={{ marginTop: 16 }}>
                  <Col span={24}>
                    <Table
                      dataSource={values.males}
                      rowKey="animalId"
                      scroll={{}}
                      size="small"
                      pagination={false}
                    >
                      <Table.Column
                        title="Nome"
                        dataIndex="animalHandlingNumber"
                        align="left"
                        sorter={(first, second) => {
                          return first.animalHandlingNumber.localeCompare(
                            second.animalHandlingNumber,
                            "pt-BR",
                            {
                              numeric: false,
                              ignorePunctuation: true,
                            }
                          );
                        }}
                        key="name"
                      />
                      <Table.Column
                        title="Entrada"
                        dataIndex="dateIn"
                        key="dateIn"
                        align="left"
                        render={(text, record) => (
                          <DatePicker
                            name="dateIn"
                            format={"DD/MM/YYYY"}
                            disabledDate={(current) =>
                              current.isAfter(moment()) ||
                              (values.formationDate &&
                                current.isBefore(values.formationDate))
                            }
                            value={
                              record.dateIn ? moment(record.dateIn) : undefined
                            }
                            placeholder={
                              translation.defaultDatePickerPlaceholder
                            }
                            allowClear={false}
                            onChange={(date) =>
                              setFieldValue(
                                "males",
                                values.males.map((m) => {
                                  if (m.animalId === record.animalId) {
                                    m.dateIn = date;
                                  }
                                  return m;
                                })
                              )
                            }
                          />
                        )}
                      />
                      {values.id !== null && (
                        <Table.Column
                          title="Saída"
                          dataIndex="dateOut"
                          key="dateOut"
                          sorter={(first, second) => {
                            if (!first.dateOut) {
                              first.dateOut = "";
                            }
                            return first.dateOut.localeCompare(
                              second.dateOut,
                              "pt-BR",
                              {
                                numeric: false,
                                ignorePunctuation: true,
                              }
                            );
                          }}
                          render={(text, record) => (
                            <DatePicker
                              name="dateOut"
                              format={"DD/MM/YYYY"}
                              disabledDate={(current) =>
                                current.isAfter(moment()) ||
                                (values.formationDate &&
                                  current.isBefore(values.formationDate))
                              }
                              value={
                                record.dateOut
                                  ? moment(record.dateOut)
                                  : undefined
                              }
                              placeholder={
                                translation.defaultDatePickerPlaceholder
                              }
                              onChange={(date) =>
                                setFieldValue(
                                  "males",
                                  values.males.map((m) => {
                                    if (m.animalId === record.animalId) {
                                      m.dateOut = date;
                                    }
                                    return m;
                                  })
                                )
                              }
                            />
                          )}
                        />
                      )}
                      <Table.Column
                        title=""
                        render={(text, record) => (
                          <ButtonStandard
                            type="button"
                            buttonType="typeWithoutBackground"
                            onClick={() =>
                              setFieldValue(
                                "males",
                                values.males.filter(
                                  (m) => m.animalId !== record.animalId
                                )
                              )
                            }
                          >
                            <TrashIcon />
                          </ButtonStandard>
                        )}
                      />
                    </Table>
                  </Col>
                </Row>
              </div>
              <Footer>
                <Row type="flex">
                  <Col span={24} className="buttonsDiv">
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={closeDrawer}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard type="submit" buttonType="type6">
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
              <SelectAnimalsModal
                data={males}
                selectedAnimalsKeys={values.males.map((m) => m.animalId)}
                visible={modalVisible}
                onCancel={handleCancelSelection}
                onConfirmSelection={handleConfirmSelection}
              />
            </form>
          )}
        />
      </Spin>
    </Container>
  );
};

export default DrawerMultipleBull;
