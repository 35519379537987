import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as UserActions } from "../../../store/ducks/user";

import { Col, Row, Spin, Switch } from "antd";
import { Container, Footer, CustomDivider } from "./styles";
import ButtonStandard from "../../utils/button";

const DrawerHomeConfig = () => {
  const [form, setForm] = useState(null);
  // Redux variable
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { isLoadingHomeConfigData, isHomeConfigDrawerVisible, homeConfigData } =
    useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [createKPI] = useState([
    "VACAS DESCARTADAS",
    "EFICIENCIA REPRODUTIVA",
    "IEP",
    "CUSTO BEZERRO DESMAMADO",
    "Kg Bez. Desm / Kg Vaca",
  ]);

  const [fattenKPI] = useState([
    "GDP",
    "PEGADA_CARBONICA",
    "CUSTO",
    "PRODUÇÃO",
    "DESFRUTE",
    "LOTACAO",
    "MARGEM",
    "DESEMBOLSO_CAB_MES",
  ]);

  // Effect
  useEffect(() => {
    setForm(homeConfigData);
    return () => {
      setForm(null);
    };
  }, [homeConfigData]);

  // Methods
  const closeDrawer = () => {
    dispatch(UserActions.showOrHideHomeConfigDrawer());
  };
  const saveForm = () => {
    dispatch(UserActions.saveHomeConfig(groupId, farmId, form));
  };

  const handleSwitch = (key, value, section) => {
    const switchName = key[0];

    // Create a copy of form value
    const newData = JSON.parse(JSON.stringify(form));
    newData[section][switchName] = value;

    switch (section) {
      // If system changes
      case "systemVisibility":
        // Enable or disable fatten kpis
        if (key[0] === "Engorda") {
          // Enable or disable subsystems
          Object.entries(newData.subSystemVisibility).forEach((obj) => {
            newData.subSystemVisibility[obj[0]] = value;
          });

          fattenKPI.forEach((kpiName) => {
            newData.kpiVisibility[kpiName] = value;
          });
        }

        // Enable or disable create kpis
        if (key[0] === "Cria") {
          createKPI.forEach((kpiName) => {
            newData.kpiVisibility[kpiName] = value;
          });
        }
        break;

      // If subsystem changes
      case "subSystemVisibility":
        // Find if another subSystem is active
        const hasSubsystemActive = Object.entries(
          newData.subSystemVisibility
        ).find((obj) => {
          if (obj[0] !== switchName) {
            return newData.subSystemVisibility[obj[0]] === true;
          }
          return null;
        });

        // If not have any other subsystem active, then disable system and all fatten kpis
        if (hasSubsystemActive === null || hasSubsystemActive === undefined) {
          newData.systemVisibility.Engorda = value;
          fattenKPI.forEach((kpiName) => {
            newData.kpiVisibility[kpiName] = value;
          });
        }
        break;

      // If kpi changes
      case "kpiVisibility":
        let selectedKpi = fattenKPI.find((kpiName) => switchName === kpiName);
        let kpiSystem = "Engorda";

        if (selectedKpi === null || selectedKpi === undefined) {
          selectedKpi = createKPI.find((kpiName) => switchName === kpiName);
          kpiSystem = "Cria";
        }

        let kpi = null;
        if (kpiSystem === "Engorda") {
          //Find if fatten another kpi is active
          kpi = fattenKPI.find((kpiName) => {
            if (
              kpiName !== switchName &&
              newData.kpiVisibility[kpiName] === true
            ) {
              return kpiName;
            }
            return null;
          });
        } else {
          //Find if another create kpi is active
          kpi = createKPI.find((kpiName) => {
            if (
              kpiName !== switchName &&
              newData.kpiVisibility[kpiName] === true
            ) {
              return kpiName;
            }
            return null;
          });
        }

        if (kpi === null || kpi === undefined) {
          newData.systemVisibility[kpiSystem] = value;

          if (kpiSystem === "Engorda") {
            //Enable or disable subsystems
            Object.entries(newData.subSystemVisibility).forEach((obj) => {
              newData.subSystemVisibility[obj[0]] = value;
            });
          }
        }
        break;

      default:
        break;
    }

    setForm(newData);
  };

  const renderKpiName = useCallback((name) => {
    switch (name) {
      case "PEGADA_CARBONICA":
        return "PEGADA CARBÔNICA";
      case "DESEMBOLSO_CAB_MES":
        return "DESEMBOLSO CAB/MÊS";
      case "VALOR_DO_ATIVO":
        return "VALOR DO ATIVO";
      case "MARGEM_INCREMENTAL":
        return "MARGEM INCREMENTAL";
      default:
        return name;
    }
  }, []);

  return (
    <Container
      title="Editar configuração da tela Home"
      width={415}
      onClose={closeDrawer}
      maskClosable={false}
      visible={isHomeConfigDrawerVisible}
    >
      <Spin spinning={isLoadingHomeConfigData}>
        {/* Body */}
        <div className="drawerForm">
          <CustomDivider dashed>Sistemas</CustomDivider>
          {form != null &&
            form?.systemVisibility != null &&
            Object.entries(form?.systemVisibility).map((key, index) => (
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                key={index}
              >
                <label>{key[0]}</label>
                <Switch
                  className="switchButton"
                  checked={key[1]}
                  onChange={(checked, event) => {
                    handleSwitch(key, checked, "systemVisibility");
                  }}
                />
              </Row>
            ))}
          <CustomDivider dashed>Subsistema</CustomDivider>
          {form != null &&
            form?.subSystemVisibility != null &&
            Object.entries(form?.subSystemVisibility).map((key, index) => (
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                key={index}
              >
                <label>{key[0]}</label>
                <Switch
                  className="switchButton"
                  checked={key[1]}
                  onChange={(checked, event) => {
                    handleSwitch(key, checked, "subSystemVisibility");
                  }}
                />
              </Row>
            ))}
          <CustomDivider dashed>Indicadores (KPIs)</CustomDivider>
          <Row type="flex">
            <strong>
              Selecione quais Indicadores (KPIs) gostaria de visualizar:
            </strong>
          </Row>
          {form != null &&
            Object.entries(form?.kpiVisibility).map((key, index) => (
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                key={index}
              >
                <label>{renderKpiName(key[0])}</label>
                <Switch
                  className="switchButton"
                  checked={key[1]}
                  onChange={(checked, event) => {
                    handleSwitch(key, checked, "kpiVisibility");
                  }}
                />
              </Row>
            ))}
        </div>
        {/* Footer */}
        <Footer>
          <Row type="flex" justify="space-between">
            <Col span={12} className="selectDiv"></Col>
            <Col span={12} className="buttonsDiv">
              <ButtonStandard
                type="button"
                buttonType="type7"
                onClick={closeDrawer}
              >
                {translation.buttons.cancel}
              </ButtonStandard>

              <ButtonStandard
                type="button"
                buttonType="type6"
                onClick={saveForm}
              >
                {translation.buttons.save}
              </ButtonStandard>
            </Col>
          </Row>
        </Footer>
      </Spin>
    </Container>
  );
};

export default DrawerHomeConfig;
