import apiAnimals from "../config/api_animals";
import getHeaders from "./getHeaders";

export function getHandlingActionIndex(payload) {
  const { signal } = payload;
  return apiAnimals.get(`/bovexo/handling/actions`, getHeaders(false, signal));
}

// export function getHandlingActionShow(payload) {
//   const {  id } = payload;
//   return apiAnimals.get(
//     `/bovexo/handling/actions/${id}`,
//     getHeaders(false, signal)
//   );
// }

// export function saveHandlingAction(payload) {
//   const { groupId, id, pasture } = payload;
//   if (id != null) {
//     return apiAnimals.patch(
//       `/bovexo/handling/actions/${id}`,
//       pasture,
//       getHeaders(false, signal)
//     );
//   } else {
//     return apiAnimals.post(
//       `/bovexo/handling/actions`,
//       pasture,
//       getHeaders(false, signal)
//     );
//   }
// }

// export function deleteHandlingActionServ(payload) {
//   const { groupId, id } = payload;

//   return apiAnimals.delete(
//     `/bovexo/handling/actions/${id}`,
//     getHeaders(false, signal)
//   );
// }
