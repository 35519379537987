import { call, put, select } from "redux-saga/effects";
import { Creators as SupplierActions } from "../ducks/supplier";
/** Services */
import {
  getAllSuppliers,
  getSupplierShow,
  saveSupplier,
  hardDeleteSupplier,
  doReactivateSupplier,
  getAllSuppliersSpecificForFarm,
} from "../../services/supplierService";
import { notification } from "antd";

export function* indexSupplier(action) {
  try {
    const {
      data: { results: allSuppliers },
    } = yield call(getAllSuppliers, action.payload);
    yield put(SupplierActions.indexSuppliersSuccess(allSuppliers));
  } catch (error) {
    yield put(SupplierActions.indexSuppliersError(error));
  }
}
export function* listDropDownSupplier(action) {
  try {
    const { onlySpecifics } = action.payload;
    let onlyActives = [];
    if (onlySpecifics !== null && onlySpecifics === true) {
      const {
        data: { results: allSuppliers },
      } = yield call(getAllSuppliersSpecificForFarm, action.payload);
      onlyActives = allSuppliers.filter(
        (supplier) => supplier.status === "A" || supplier.status === "Active"
      );
    } else {
      const {
        data: { results: allSuppliers },
      } = yield call(getAllSuppliers, action.payload);
      onlyActives = allSuppliers.filter(
        (supplier) => supplier.status === "A" || supplier.status === "Active"
      );
    }
    yield put(SupplierActions.getDropdownSuppliersSuccess(onlyActives));
  } catch (error) {
    yield put(SupplierActions.getDropdownSuppliersError(error));
  }
}

export function* getSupplier(action) {
  try {
    const {
      data: { results: supplier },
    } = yield call(getSupplierShow, action.payload);
    yield put(SupplierActions.showSupplierSuccess(supplier));
  } catch (error) {
    yield put(SupplierActions.showSupplierError(error));
  }
}

export function* storeOrUpdateSupplier(action) {
  let result = null;
  try {
    const {
      data: { results: newSupplier },
    } = yield call(saveSupplier, action.payload);
    result = newSupplier;
    const { saveOptions } = action.payload;

    try {
      if (result != null && Object.entries(result).length > 0) {
        const newPayload = {
          ...action.payload,
        };
        yield put(
          SupplierActions.indexSuppliers(
            newPayload.groupId,
            newPayload.farmId,
            null
          )
        );
        const { drawerActionType } = yield select((state) => state.supplier);
        if (drawerActionType !== "new") {
          yield put(
            SupplierActions.getDropdownSuppliers(
              newPayload.groupId,
              newPayload.farmId,
              result?.type,
              true
            )
          );
        } else {
          yield put(
            SupplierActions.getDropdownSuppliers(
              newPayload.groupId,
              newPayload.farmId,
              null,
              true
            )
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
    if (action.payload.id == null)
      notification.success({ message: "Fornecedor criado com sucesso" });
    else notification.success({ message: "Fornecedor atualizado com sucesso" });

    yield put(SupplierActions.saveSupplierSuccess(newSupplier, saveOptions));
  } catch (error) {
    const {
      response: {
        data: { code },
      },
    } = error;

    if (action.payload.id == null)
      notification.error({ message: "Fornecedor não foi criado." });
    else notification.error({ message: "Fornecedor não foi atualizado." });

    if (code === 5006) {
      notification.error({
        message: "CPF/CNPJ já cadastrado em sua base de fornecedores.",
      });
    }

    yield put(SupplierActions.saveSupplierError(error));
  }
}

export function* deleteSupplier(action) {
  try {
    const {
      data: { results: deleted },
    } = yield call(hardDeleteSupplier, action.payload);

    if (deleted != null) {
      if (deleted) {
        yield put(SupplierActions.deleteSupplierSuccess());
        notification.success({ message: "Fornecedor inativado com sucesso" });
        yield put(
          SupplierActions.indexSuppliers(
            action.payload.groupId,
            action.payload.farmId
          )
        );
      } else {
        notification.error({ message: "Fornecedor não foi inativado" });
        yield put(
          SupplierActions.deleteSupplierError("Fornecedor não foi inativado")
        );
      }
    }
  } catch (error) {
    notification.error({ message: "Fornecedor não foi inativado" });
    yield put(SupplierActions.deleteSupplierError(error));
  }
}

export function* reactivateSupplier(action) {
  try {
    const {
      data: { results: deleted },
    } = yield call(doReactivateSupplier, action.payload);

    if (deleted != null) {
      if (deleted) {
        yield put(SupplierActions.reactivateSupplierSuccess());
        notification.success({ message: "Fornecedor reativado com sucesso" });
        yield put(
          SupplierActions.indexSuppliers(
            action.payload.groupId,
            action.payload.farmId
          )
        );
      } else {
        notification.error({ message: "Fornecedor não foi reativado" });
        yield put(
          SupplierActions.reactivateSupplierError(
            "Fornecedor não foi reativado"
          )
        );
      }
    }
  } catch (error) {
    notification.error({ message: "Fornecedor não foi reativado" });
    yield put(SupplierActions.reactivateSupplierError(error));
  }
}
