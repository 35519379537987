import React from "react";

// import { Container } from './styles';

const PencilIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.043"
    height="15.043"
    viewBox="0 0 15.043 15.043"
  >
    <g
      id="Btn_editar_lote"
      data-name="Btn editar lote"
      transform="translate(-1279.843 -439.979)"
    >
      <g id="unhover" transform="translate(1275 435)">
        <path
          id="edit"
          d="M8.2,17.494a.761.761,0,0,0,.113-.009l3.163-.555a.184.184,0,0,0,.1-.053l7.971-7.971a.187.187,0,0,0,0-.265L16.421,5.515a.191.191,0,0,0-.267,0L8.183,13.485a.191.191,0,0,0-.053.1l-.555,3.163a.63.63,0,0,0,.177.56A.637.637,0,0,0,8.2,17.494Zm1.267-3.279L16.287,7.4l1.378,1.378-6.82,6.818-1.672.3.293-1.674ZM19.9,19.074H6.062a.6.6,0,0,0-.6.6v.677a.151.151,0,0,0,.15.15H20.352a.151.151,0,0,0,.15-.15v-.677A.6.6,0,0,0,19.9,19.074Z"
          transform="translate(-0.617 -0.481)"
          fill="#4a85ae"
        />
      </g>
    </g>
  </svg>
);
export default PencilIcon;
