/* Types & Action Creators */
export const Types = {
  RESET_DATA: "curve/RESET_DATA",
  START_LOADING: "curve/START_LOADING",
  STOP_LOADING: "curve/STOP_LOADING",
  SAVE_ANIMAL_CURVE_DATA: "curve/SAVE_ANIMAL_CURVE_DATA",
  SAVE_ANIMAL_CALCULATE_CURVE_DATA: "curve/SAVE_ANIMAL_CALCULATE_CURVE_DATA",
  SAVE_LOT_CURVE_DATA: "curve/SAVE_LOT_CURVE_DATA",
  SAVE_LOT_CALCULATE_CURVE_DATA: "curve/SAVE_LOT_CALCULATE_CURVE_DATA",
  SAVE_DIET_STRATEGY_CALCULATE_CURVE_DATA:  "curve/SAVE_DIET_STRATEGY_CALCULATE_CURVE_DATA",
  SHOW_HIDE_ANIMAL_CURVE_DRAWER: "curve/SHOW_HIDE_ANIMAL_CURVE_DRAWER",
  SHOW_HIDE_LOT_CURVE_DRAWER: "curve/SHOW_HIDE_LOT_CURVE_DRAWER"
};

export const Creators = {
  startLoading: () => ({
    payload: {},
    type: Types.START_LOADING
  }),
  stopLoading: () => ({
    payload: {},
    type: Types.STOP_LOADING
  }),
  saveAnimalCurve: data => ({
    payload: { data },
    type: Types.SAVE_ANIMAL_CURVE_DATA
  }),
  saveAnimalCalculateCurve: data => ({
    payload: { data },
    type: Types.SAVE_ANIMAL_CALCULATE_CURVE_DATA
  }),
  saveLotCurve: data => ({
    payload: { data },
    type: Types.SAVE_LOT_CURVE_DATA
  }),
  saveLotCalculateCurve: data => ({
    payload: { data },
    type: Types.SAVE_LOT_CALCULATE_CURVE_DATA
  }),
  saveDietStrategyCalculateCurve: data => ({
    payload: { data },
    type: Types.SAVE_DIET_STRATEGY_CALCULATE_CURVE_DATA
  }),
  showOrHideAnimalCurveDrawer: () => ({
    payload: {},
    type: Types.SHOW_HIDE_ANIMAL_CURVE_DRAWER
  }),
  showOrHideLotCurveDrawer: () => ({
    payload: {},
    type: Types.SHOW_HIDE_LOT_CURVE_DRAWER
  })
};

/* Initial State */
const INITIAL_STATE = {
  isLoading: false,
  animalCurveData: null,
  animalCurveResult: [],
  lotCurveData: null,
  lotCurveResult: [],
  dietStrategyCurveResult: [],
  showDrawerAnimalCurve: false,
  showDrawerLotCurve: false,
  error: null
};

export default function curve(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.START_LOADING:
      return { ...state, isLoading: true };
    case Types.STOP_LOADING:
      return { ...state, isLoading: false };
    case Types.SAVE_ANIMAL_CURVE_DATA:
      return { ...state, animalCurveData: action.payload.data };
    case Types.SAVE_ANIMAL_CALCULATE_CURVE_DATA:
      return { ...state, animalCurveResult: action.payload.data };
    case Types.SAVE_LOT_CURVE_DATA:
      return { ...state, lotCurveData: action.payload.data };
    case Types.SAVE_LOT_CALCULATE_CURVE_DATA:
      return { ...state, lotCurveResult: action.payload.data };
    case Types.SAVE_DIET_STRATEGY_CALCULATE_CURVE_DATA:
      return { ...state, dietStrategyCurveResult: action.payload.data };
    case Types.SHOW_HIDE_ANIMAL_CURVE_DRAWER:
      return { ...state, showDrawerAnimalCurve: !state.showDrawerAnimalCurve };
    case Types.SHOW_HIDE_LOT_CURVE_DRAWER:
      return { ...state, showDrawerLotCurve: !state.showDrawerLotCurve };
    default:
      return state;
  }
}
