import styled from "styled-components";
import { Collapse } from "antd";

export const CustomCollapse = styled(Collapse)`
  background: transparent !important;

  .ant-collapse-item {
    .ant-collapse-header {
      border-radius: 0px !important;
      font-family: "Asap", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      color: #4b4b4b !important;
    }
  }

  .ant-collapse-arrow {
    box-shadow: none;
    color: #8a50a5 !important;
  }

  .ant-collapse-item:not(:first-of-type) {
    border-top: 1px solid #9d9d9d !important;
  }

  .ant-collapse-content {
    background: transparent !important;
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    &.error {
      color: #d44c4c;
    }
  }

  .bodyContent {
    margin-top: 8px;

    .ant-row > label {
      display: inline-block;
      margin-bottom: 8px;
    }

    .ant-row > .ant-input-group-wrapper {
      width: 50%;
    }

    .ant-row > .ant-input {
      width: 50%;
    }

    & > .rowFields {
      margin-top: 0px;
    }

    & > .rowFields ~ .rowFields {
      margin-top: 8px;
    }
  }
`;

export const Footer = styled.div`
  border-top: 1px solid #e8e8e8;
  margin-top: 20px;
  width: 100%;
  padding: 10px 24px;
  border-radius: 0 0 4px 4px;

  .buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-right: 24px; */

    button + button {
      margin-left: 16px !important;
    }
  }

  .ant-row-flex {
    margin: 0;
  }

  .selectDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: "Asap", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;

export const EditButton = styled.button`
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #9386aa;
  border: none;
  background: none;

  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
    path {
      fill: #9386aa !important;
    }
  }

  &:hover {
    color: #a9c133;
    cursor: pointer;

    svg {
      margin-left: 5px;
      path {
        fill: #a9c133 !important;
      }
    }
  }

  &:disabled {
    color: #a5a5a5;
    cursor: not-allowed;
    svg {
      margin-left: 5px;
      path {
        fill: #a5a5a5 !important;
      }
    }
  }
`;
