export const currencies = [
  {
    symbol: "AED",
    name: "United Arab Emirates Dirham",
    symbol_native: "د.إ",
    decimal_digits: 2,
    rounding: 0,
    code: "AED",
    name_plural: "UAE dirhams",
    type: "fiat",
    countries: ["AE"],
  },
  {
    symbol: "Af",
    name: "Afghan Afghani",
    symbol_native: "؋",
    decimal_digits: 0,
    rounding: 0,
    code: "AFN",
    name_plural: "Afghan Afghanis",
    type: "fiat",
    countries: ["AF"],
  },
  {
    symbol: "ALL",
    name: "Albanian Lek",
    symbol_native: "Lek",
    decimal_digits: 0,
    rounding: 0,
    code: "ALL",
    name_plural: "Albanian lekë",
    type: "fiat",
    countries: ["AL"],
  },
  {
    symbol: "AMD",
    name: "Armenian Dram",
    symbol_native: "դր.",
    decimal_digits: 0,
    rounding: 0,
    code: "AMD",
    name_plural: "Armenian drams",
    type: "fiat",
    countries: ["AM"],
  },
  {
    symbol: "ƒ",
    name: "NL Antillean Guilder",
    symbol_native: "NAƒ",
    decimal_digits: 2,
    rounding: 0,
    code: "ANG",
    icon_name: "ang",
    name_plural: "NL Antillean Guilders",
    type: "fiat",
    countries: ["CW", "SX"],
  },
  {
    symbol: "Kz",
    name: "Angolan Kwanza",
    symbol_native: "Kz",
    decimal_digits: 2,
    rounding: 0,
    code: "AOA",
    icon_name: "aoa",
    name_plural: "Angolan Kwanza",
    type: "fiat",
    countries: ["AO"],
  },
  {
    symbol: "AR$",
    name: "Argentine Peso",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "ARS",
    name_plural: "Argentine pesos",
    type: "fiat",
    countries: ["AR"],
  },
  {
    symbol: "AU$",
    name: "Australian Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "AUD",
    name_plural: "Australian dollars",
    type: "fiat",
    countries: ["AU", "CC", "CX", "HM", "KI", "NF", "NR", "TV"],
  },
  {
    symbol: "Afl.",
    name: "Aruban Florin",
    symbol_native: "Afl.",
    decimal_digits: 2,
    rounding: 0,
    code: "AWG",
    icon_name: "awg",
    name_plural: "Aruban Florin",
    type: "fiat",
    countries: ["AW"],
  },
  {
    symbol: "man.",
    name: "Azerbaijani Manat",
    symbol_native: "ман.",
    decimal_digits: 2,
    rounding: 0,
    code: "AZN",
    name_plural: "Azerbaijani manats",
    type: "fiat",
    countries: ["AZ"],
  },
  {
    symbol: "KM",
    name: "Bosnia-Herzegovina Convertible Mark",
    symbol_native: "KM",
    decimal_digits: 2,
    rounding: 0,
    code: "BAM",
    name_plural: "Bosnia-Herzegovina convertible marks",
    type: "fiat",
    countries: ["BA"],
  },
  {
    symbol: "Bds$",
    name: "Barbadian Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "BBD",
    icon_name: "bbd",
    name_plural: "Barbadian Dollars",
    type: "fiat",
    countries: ["BB"],
  },
  {
    symbol: "Tk",
    name: "Bangladeshi Taka",
    symbol_native: "৳",
    decimal_digits: 2,
    rounding: 0,
    code: "BDT",
    name_plural: "Bangladeshi takas",
    type: "fiat",
    countries: ["BD"],
  },
  {
    symbol: "BGN",
    name: "Bulgarian Lev",
    symbol_native: "лв.",
    decimal_digits: 2,
    rounding: 0,
    code: "BGN",
    name_plural: "Bulgarian leva",
    type: "fiat",
    countries: ["BG"],
  },
  {
    symbol: "BD",
    name: "Bahraini Dinar",
    symbol_native: "د.ب.‏",
    decimal_digits: 3,
    rounding: 0,
    code: "BHD",
    name_plural: "Bahraini dinars",
    type: "fiat",
    countries: ["BH"],
  },
  {
    symbol: "FBu",
    name: "Burundian Franc",
    symbol_native: "FBu",
    decimal_digits: 0,
    rounding: 0,
    code: "BIF",
    name_plural: "Burundian francs",
    type: "fiat",
    countries: ["BI"],
  },
  {
    symbol: "BD$",
    name: "Bermudan Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "BMD",
    icon_name: "bmd",
    name_plural: "Bermudan Dollars",
    type: "fiat",
    countries: ["BM"],
  },
  {
    symbol: "BN$",
    name: "Brunei Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "BND",
    name_plural: "Brunei dollars",
    type: "fiat",
    countries: ["BN"],
  },
  {
    symbol: "Bs",
    name: "Bolivian Boliviano",
    symbol_native: "Bs",
    decimal_digits: 2,
    rounding: 0,
    code: "BOB",
    name_plural: "Bolivian bolivianos",
    type: "fiat",
    countries: ["BO"],
  },
  {
    symbol: "R$",
    name: "Brazilian Real",
    symbol_native: "R$",
    decimal_digits: 2,
    rounding: 0,
    code: "BRL",
    name_plural: "Brazilian reals",
    type: "fiat",
    countries: ["BR"],
  },
  {
    symbol: "B$",
    name: "Bahamian Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "BSD",
    icon_name: "bsd",
    name_plural: "Bahamian Dollars",
    type: "fiat",
    countries: ["BS"],
  },
  {
    symbol: "Nu.",
    name: "Bhutanese Ngultrum",
    symbol_native: "Nu.",
    decimal_digits: 2,
    rounding: 0,
    code: "BTN",
    icon_name: "btn",
    name_plural: "Bhutanese Ngultrum",
    type: "fiat",
    countries: ["BT"],
  },
  {
    symbol: "BWP",
    name: "Botswanan Pula",
    symbol_native: "P",
    decimal_digits: 2,
    rounding: 0,
    code: "BWP",
    name_plural: "Botswanan pulas",
    type: "fiat",
    countries: ["BW", "ZW"],
  },
  {
    symbol: "Br",
    name: "Belarusian ruble",
    symbol_native: "Br",
    decimal_digits: 2,
    rounding: 0,
    code: "BYN",
    name_plural: "Belarusian rubles",
    type: "fiat",
    countries: [],
  },
  {
    symbol: "BYR",
    name: "Belarusian Ruble",
    symbol_native: "BYR",
    decimal_digits: 0,
    rounding: 0,
    code: "BYR",
    name_plural: "Belarusian rubles",
    type: "fiat",
    countries: ["BY"],
  },
  {
    symbol: "BZ$",
    name: "Belize Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "BZD",
    name_plural: "Belize dollars",
    type: "fiat",
    countries: ["BZ"],
  },
  {
    symbol: "CA$",
    name: "Canadian Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "CAD",
    name_plural: "Canadian dollars",
    type: "fiat",
    countries: ["CA"],
  },
  {
    symbol: "CDF",
    name: "Congolese Franc",
    symbol_native: "FrCD",
    decimal_digits: 2,
    rounding: 0,
    code: "CDF",
    name_plural: "Congolese francs",
    type: "fiat",
    countries: ["CD"],
  },
  {
    symbol: "CHF",
    name: "Swiss Franc",
    symbol_native: "CHF",
    decimal_digits: 2,
    rounding: 0,
    code: "CHF",
    name_plural: "Swiss francs",
    type: "fiat",
    countries: ["CH", "LI"],
  },
  {
    symbol: "UF",
    name: "Unidad de Fomento",
    symbol_native: "UF",
    decimal_digits: 2,
    rounding: 0,
    code: "CLF",
    name_plural: "Unidad de Fomentos",
    type: "fiat",
    countries: ["CL"],
  },
  {
    symbol: "CL$",
    name: "Chilean Peso",
    symbol_native: "$",
    decimal_digits: 0,
    rounding: 0,
    code: "CLP",
    name_plural: "Chilean pesos",
    type: "fiat",
    countries: ["CL"],
  },
  {
    symbol: "CN¥",
    name: "Chinese Yuan",
    symbol_native: "CN¥",
    decimal_digits: 2,
    rounding: 0,
    code: "CNY",
    name_plural: "Chinese yuan",
    type: "fiat",
    countries: ["CN"],
  },
  {
    symbol: "CO$",
    name: "Coombian Peso",
    symbol_native: "$",
    decimal_digits: 0,
    rounding: 0,
    code: "COP",
    name_plural: "Colombian pesos",
    type: "fiat",
    countries: ["CO"],
  },
  {
    symbol: "₡",
    name: "Costa Rican Colón",
    symbol_native: "₡",
    decimal_digits: 0,
    rounding: 0,
    code: "CRC",
    name_plural: "Costa Rican colóns",
    type: "fiat",
    countries: ["CR"],
  },
  {
    symbol: "CUC$",
    name: "Cuban Convertible Peso",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "CUC",
    icon_name: "cuc",
    name_plural: "Cuban Convertible Peso",
    type: "fiat",
    countries: ["CU"],
  },
  {
    symbol: "$MN",
    name: "Cuban Peso",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "CUP",
    icon_name: "cup",
    name_plural: "Cuban Peso",
    type: "fiat",
    countries: ["CU"],
  },
  {
    symbol: "CV$",
    name: "Cape Verdean Escudo",
    symbol_native: "CV$",
    decimal_digits: 2,
    rounding: 0,
    code: "CVE",
    name_plural: "Cape Verdean escudos",
    type: "fiat",
    countries: ["CV"],
  },
  {
    symbol: "Kč",
    name: "Czech Republic Koruna",
    symbol_native: "Kč",
    decimal_digits: 2,
    rounding: 0,
    code: "CZK",
    name_plural: "Czech Republic korunas",
    type: "fiat",
    countries: ["CZ"],
  },
  {
    symbol: "Fdj",
    name: "Djiboutian Franc",
    symbol_native: "Fdj",
    decimal_digits: 0,
    rounding: 0,
    code: "DJF",
    name_plural: "Djiboutian francs",
    type: "fiat",
    countries: ["DJ"],
  },
  {
    symbol: "Dkr",
    name: "Danish Krone",
    symbol_native: "kr",
    decimal_digits: 2,
    rounding: 0,
    code: "DKK",
    name_plural: "Danish kroner",
    type: "fiat",
    countries: ["DK", "FO", "GL"],
  },
  {
    symbol: "RD$",
    name: "Dominican Peso",
    symbol_native: "RD$",
    decimal_digits: 2,
    rounding: 0,
    code: "DOP",
    name_plural: "Dominican pesos",
    type: "fiat",
    countries: ["DO"],
  },
  {
    symbol: "DA",
    name: "Algerian Dinar",
    symbol_native: "د.ج.‏",
    decimal_digits: 2,
    rounding: 0,
    code: "DZD",
    name_plural: "Algerian dinars",
    type: "fiat",
    countries: ["DZ"],
  },
  {
    symbol: "EGP",
    name: "Egyptian Pound",
    symbol_native: "ج.م.‏",
    decimal_digits: 2,
    rounding: 0,
    code: "EGP",
    name_plural: "Egyptian pounds",
    type: "fiat",
    countries: ["EG", "PS"],
  },
  {
    symbol: "Nfk",
    name: "Eritrean Nakfa",
    symbol_native: "Nfk",
    decimal_digits: 2,
    rounding: 0,
    code: "ERN",
    name_plural: "Eritrean nakfas",
    type: "fiat",
    countries: ["ER"],
  },
  {
    symbol: "Br",
    name: "Ethiopian Birr",
    symbol_native: "Br",
    decimal_digits: 2,
    rounding: 0,
    code: "ETB",
    name_plural: "Ethiopian birrs",
    type: "fiat",
    countries: ["ET"],
  },
  {
    symbol: "€",
    name: "Euro",
    symbol_native: "€",
    decimal_digits: 2,
    rounding: 0,
    code: "EUR",
    name_plural: "Euros",
    type: "fiat",
    countries: [
      "AD",
      "AT",
      "AX",
      "BE",
      "BL",
      "CP",
      "CY",
      "DE",
      "EA",
      "EE",
      "ES",
      "EU",
      "FI",
      "FR",
      "FX",
      "GF",
      "GP",
      "GR",
      "IC",
      "IE",
      "IT",
      "LT",
      "LU",
      "LV",
      "MC",
      "ME",
      "MF",
      "MQ",
      "MT",
      "NL",
      "PM",
      "PT",
      "RE",
      "SI",
      "SK",
      "SM",
      "TF",
      "VA",
      "XK",
      "YT",
      "ZW",
    ],
  },
  {
    symbol: "FJ$",
    name: "Fijian Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "FJD",
    icon_name: "fjd",
    name_plural: "Fijian Dollar",
    type: "fiat",
    countries: ["FJ"],
  },
  {
    symbol: "FK£",
    name: "Falkland Islands Pound",
    symbol_native: "£",
    decimal_digits: 2,
    rounding: 0,
    code: "FKP",
    icon_name: "fkp",
    name_plural: "Falkland Islands Pound",
    type: "fiat",
    countries: ["FK"],
  },
  {
    symbol: "£",
    name: "British Pound Sterling",
    symbol_native: "£",
    decimal_digits: 2,
    rounding: 0,
    code: "GBP",
    name_plural: "British pounds sterling",
    type: "fiat",
    countries: ["GB", "GG", "GS", "IM", "JE", "TA", "UK", "ZW"],
  },
  {
    symbol: "GEL",
    name: "Georgian Lari",
    symbol_native: "GEL",
    decimal_digits: 2,
    rounding: 0,
    code: "GEL",
    name_plural: "Georgian laris",
    type: "fiat",
    countries: ["GE"],
  },
  {
    symbol: "£",
    name: "Guernsey pound",
    symbol_native: "£",
    decimal_digits: 2,
    rounding: 0,
    code: "GGP",
    name_plural: "Guernsey pounds",
    type: "fiat",
    countries: [],
  },
  {
    symbol: "GH₵",
    name: "Ghanaian Cedi",
    symbol_native: "GH₵",
    decimal_digits: 2,
    rounding: 0,
    code: "GHS",
    name_plural: "Ghanaian cedis",
    type: "fiat",
    countries: ["GH"],
  },
  {
    symbol: "£",
    name: "Gibraltar Pound",
    symbol_native: "£",
    decimal_digits: 2,
    rounding: 0,
    code: "GIP",
    icon_name: "gip",
    name_plural: "Gibraltar Pounds",
    type: "fiat",
    countries: ["GI"],
  },
  {
    symbol: "D",
    name: "Gambian Dalasi",
    symbol_native: "D",
    decimal_digits: 2,
    rounding: 0,
    code: "GMD",
    icon_name: "gmd",
    name_plural: "Gambian Dalasi",
    type: "fiat",
    countries: ["GM"],
  },
  {
    symbol: "FG",
    name: "Guinean Franc",
    symbol_native: "FG",
    decimal_digits: 0,
    rounding: 0,
    code: "GNF",
    name_plural: "Guinean francs",
    type: "fiat",
    countries: ["GN"],
  },
  {
    symbol: "GTQ",
    name: "Guatemalan Quetzal",
    symbol_native: "Q",
    decimal_digits: 2,
    rounding: 0,
    code: "GTQ",
    name_plural: "Guatemalan quetzals",
    type: "fiat",
    countries: ["GT"],
  },
  {
    symbol: "G$",
    name: "Guyanaese Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "GYD",
    icon_name: "gyd",
    name_plural: "Guyanaese Dollar",
    type: "fiat",
    countries: ["GY"],
  },
  {
    symbol: "HK$",
    name: "Hong Kong Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "HKD",
    name_plural: "Hong Kong dollars",
    type: "fiat",
    countries: ["HK"],
  },
  {
    symbol: "HNL",
    name: "Honduran Lempira",
    symbol_native: "L",
    decimal_digits: 2,
    rounding: 0,
    code: "HNL",
    name_plural: "Honduran lempiras",
    type: "fiat",
    countries: ["HN"],
  },
  {
    symbol: "kn",
    name: "Croatian Kuna",
    symbol_native: "kn",
    decimal_digits: 2,
    rounding: 0,
    code: "HRK",
    name_plural: "Croatian kunas",
    type: "fiat",
    countries: ["HR"],
  },
  {
    symbol: "G",
    name: "Haitian Gourde",
    symbol_native: "G",
    decimal_digits: 2,
    rounding: 0,
    code: "HTG",
    icon_name: "htg",
    name_plural: "Haitian Gourde",
    type: "fiat",
    countries: ["HT"],
  },
  {
    symbol: "Ft",
    name: "Hungarian Forint",
    symbol_native: "Ft",
    decimal_digits: 0,
    rounding: 0,
    code: "HUF",
    name_plural: "Hungarian forints",
    type: "fiat",
    countries: ["HU"],
  },
  {
    symbol: "Rp",
    name: "Indonesian Rupiah",
    symbol_native: "Rp",
    decimal_digits: 0,
    rounding: 0,
    code: "IDR",
    name_plural: "Indonesian rupiahs",
    type: "fiat",
    countries: ["ID"],
  },
  {
    symbol: "₪",
    name: "Israeli New Sheqel",
    symbol_native: "₪",
    decimal_digits: 2,
    rounding: 0,
    code: "ILS",
    name_plural: "Israeli new sheqels",
    type: "fiat",
    countries: ["IL", "PS"],
  },
  {
    symbol: "£",
    name: "Manx pound",
    symbol_native: "£",
    decimal_digits: 2,
    rounding: 0,
    code: "IMP",
    name_plural: "Manx pounds",
    type: "fiat",
    countries: [],
  },
  {
    symbol: "Rs",
    name: "Indian Rupee",
    symbol_native: "টকা",
    decimal_digits: 2,
    rounding: 0,
    code: "INR",
    name_plural: "Indian rupees",
    type: "fiat",
    countries: ["BT", "IN"],
  },
  {
    symbol: "IQD",
    name: "Iraqi Dinar",
    symbol_native: "د.ع.‏",
    decimal_digits: 0,
    rounding: 0,
    code: "IQD",
    name_plural: "Iraqi dinars",
    type: "fiat",
    countries: ["IQ"],
  },
  {
    symbol: "IRR",
    name: "Iranian Rial",
    symbol_native: "﷼",
    decimal_digits: 0,
    rounding: 0,
    code: "IRR",
    name_plural: "Iranian rials",
    type: "fiat",
    countries: ["IR"],
  },
  {
    symbol: "Ikr",
    name: "Icelandic Króna",
    symbol_native: "kr",
    decimal_digits: 0,
    rounding: 0,
    code: "ISK",
    name_plural: "Icelandic krónur",
    type: "fiat",
    countries: ["IS"],
  },
  {
    symbol: "£",
    name: "Jersey pound",
    symbol_native: "£",
    decimal_digits: 2,
    rounding: 0,
    code: "JEP",
    name_plural: "Jersey pound",
    type: "fiat",
    countries: [],
  },
  {
    symbol: "J$",
    name: "Jamaican Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "JMD",
    name_plural: "Jamaican dollars",
    type: "fiat",
    countries: ["JM"],
  },
  {
    symbol: "JD",
    name: "Jordanian Dinar",
    symbol_native: "د.أ.‏",
    decimal_digits: 3,
    rounding: 0,
    code: "JOD",
    name_plural: "Jordanian dinars",
    type: "fiat",
    countries: ["JO", "PS"],
  },
  {
    symbol: "¥",
    name: "Japanese Yen",
    symbol_native: "￥",
    decimal_digits: 0,
    rounding: 0,
    code: "JPY",
    name_plural: "Japanese yen",
    type: "fiat",
    countries: ["JP"],
  },
  {
    symbol: "Ksh",
    name: "Kenyan Shilling",
    symbol_native: "Ksh",
    decimal_digits: 2,
    rounding: 0,
    code: "KES",
    name_plural: "Kenyan shillings",
    type: "fiat",
    countries: ["KE"],
  },
  {
    symbol: "KGS",
    name: "Kyrgystani Som",
    symbol_native: "KGS",
    decimal_digits: 2,
    rounding: 0,
    code: "KGS",
    icon_name: "kgs",
    name_plural: "Kyrgystani Som",
    type: "fiat",
    countries: ["KG"],
  },
  {
    symbol: "KHR",
    name: "Cambodian Riel",
    symbol_native: "៛",
    decimal_digits: 2,
    rounding: 0,
    code: "KHR",
    name_plural: "Cambodian riels",
    type: "fiat",
    countries: ["KH"],
  },
  {
    symbol: "CF",
    name: "Comorian Franc",
    symbol_native: "FC",
    decimal_digits: 0,
    rounding: 0,
    code: "KMF",
    name_plural: "Comorian francs",
    type: "fiat",
    countries: ["KM"],
  },
  {
    symbol: "₩",
    name: "North Korean Won",
    symbol_native: "₩",
    decimal_digits: 2,
    rounding: 0,
    code: "KPW",
    icon_name: "kpw",
    name_plural: "North Korean Won",
    type: "fiat",
    countries: ["KP"],
  },
  {
    symbol: "₩",
    name: "South Korean Won",
    symbol_native: "₩",
    decimal_digits: 0,
    rounding: 0,
    code: "KRW",
    name_plural: "South Korean won",
    type: "fiat",
    countries: ["KR"],
  },
  {
    symbol: "KD",
    name: "Kuwaiti Dinar",
    symbol_native: "د.ك.‏",
    decimal_digits: 3,
    rounding: 0,
    code: "KWD",
    name_plural: "Kuwaiti dinars",
    type: "fiat",
    countries: ["KW"],
  },
  {
    symbol: "CI$",
    name: "Cayman Islands Dollar",
    symbol_native: "$‏",
    decimal_digits: 2,
    rounding: 0,
    code: "KYD",
    icon_name: "kyd",
    name_plural: "Cayman Islands Dollar",
    type: "fiat",
    countries: ["KY"],
  },
  {
    symbol: "KZT",
    name: "Kazakhstani Tenge",
    symbol_native: "тңг.",
    decimal_digits: 2,
    rounding: 0,
    code: "KZT",
    name_plural: "Kazakhstani tenges",
    type: "fiat",
    countries: ["KZ"],
  },
  {
    symbol: "₭N",
    name: "Laotian Kip",
    symbol_native: "₭‏‏",
    decimal_digits: 0,
    rounding: 0,
    code: "LAK",
    name_plural: "Laotian Kip",
    type: "fiat",
    countries: ["LA"],
  },
  {
    symbol: "LB£",
    name: "Lebanese Pound",
    symbol_native: "ل.ل.‏",
    decimal_digits: 0,
    rounding: 0,
    code: "LBP",
    name_plural: "Lebanese pounds",
    type: "fiat",
    countries: ["LB"],
  },
  {
    symbol: "SLRs",
    name: "Sri Lankan Rupee",
    symbol_native: "SL Re",
    decimal_digits: 2,
    rounding: 0,
    code: "LKR",
    name_plural: "Sri Lankan rupees",
    type: "fiat",
    countries: ["LK"],
  },
  {
    symbol: "LD$",
    name: "Liberian Dollar",
    symbol_native: "L$",
    decimal_digits: 2,
    rounding: 0,
    code: "LRD",
    icon_name: "lrd",
    name_plural: "Liberian Dollar",
    type: "fiat",
    countries: ["LR"],
  },
  {
    symbol: "L",
    name: "Lesotho Loti",
    symbol_native: "M",
    decimal_digits: 2,
    rounding: 0,
    code: "LSL",
    icon_name: "lsl",
    name_plural: "Lesotho Loti",
    type: "fiat",
    countries: ["LS"],
  },
  {
    symbol: "Lt",
    name: "Lithuanian Litas",
    symbol_native: "Lt",
    decimal_digits: 2,
    rounding: 0,
    code: "LTL",
    name_plural: "Lithuanian litai",
    type: "fiat",
    countries: [],
  },
  {
    symbol: "Ls",
    name: "Latvian Lats",
    symbol_native: "Ls",
    decimal_digits: 2,
    rounding: 0,
    code: "LVL",
    name_plural: "Latvian lati",
    type: "fiat",
    countries: [],
  },
  {
    symbol: "LD",
    name: "Libyan Dinar",
    symbol_native: "د.ل.‏",
    decimal_digits: 3,
    rounding: 0,
    code: "LYD",
    name_plural: "Libyan dinars",
    type: "fiat",
    countries: ["LY"],
  },
  {
    symbol: "MAD",
    name: "Moroccan Dirham",
    symbol_native: "د.م.‏",
    decimal_digits: 2,
    rounding: 0,
    code: "MAD",
    name_plural: "Moroccan dirhams",
    type: "fiat",
    countries: ["EH", "MA"],
  },
  {
    symbol: "MDL",
    name: "Moldovan Leu",
    symbol_native: "MDL",
    decimal_digits: 2,
    rounding: 0,
    code: "MDL",
    name_plural: "Moldovan lei",
    type: "fiat",
    countries: ["MD"],
  },
  {
    symbol: "MGA",
    name: "Malagasy Ariary",
    symbol_native: "MGA",
    decimal_digits: 0,
    rounding: 0,
    code: "MGA",
    name_plural: "Malagasy Ariaries",
    type: "fiat",
    countries: ["MG"],
  },
  {
    symbol: "MKD",
    name: "Macedonian Denar",
    symbol_native: "MKD",
    decimal_digits: 2,
    rounding: 0,
    code: "MKD",
    name_plural: "Macedonian denari",
    type: "fiat",
    countries: ["MK"],
  },
  {
    symbol: "MMK",
    name: "Myanma Kyat",
    symbol_native: "K",
    decimal_digits: 0,
    rounding: 0,
    code: "MMK",
    name_plural: "Myanma kyats",
    type: "fiat",
    countries: ["MM"],
  },
  {
    symbol: "₮",
    name: "Mongolian Tugrik",
    symbol_native: "₮",
    decimal_digits: 2,
    rounding: 0,
    code: "MNT",
    icon_name: "mnt",
    name_plural: "Mongolian Tugrik",
    type: "fiat",
    countries: ["MN"],
  },
  {
    symbol: "MOP$",
    name: "Macanese Pataca",
    symbol_native: "MOP$",
    decimal_digits: 2,
    rounding: 0,
    code: "MOP",
    name_plural: "Macanese patacas",
    type: "fiat",
    countries: ["MO"],
  },
  {
    symbol: "UM",
    name: "Mauritanian ouguiya",
    symbol_native: "UM",
    decimal_digits: 2,
    rounding: 0,
    code: "MRO",
    name_plural: "Mauritanian ouguiyas",
    type: "fiat",
    countries: ["MR"],
  },
  {
    symbol: "MURs",
    name: "Mauritian Rupee",
    symbol_native: "MURs",
    decimal_digits: 0,
    rounding: 0,
    code: "MUR",
    name_plural: "Mauritian rupees",
    type: "fiat",
    countries: ["MU"],
  },
  {
    symbol: "MRf",
    name: "Maldivian Rufiyaa",
    symbol_native: "Rf",
    decimal_digits: 2,
    rounding: 0,
    code: "MVR",
    name_plural: "Maldivian Rufiyaa",
    type: "fiat",
    countries: ["MV"],
  },
  {
    symbol: "MK",
    name: "Malawian Kwacha",
    symbol_native: "MK",
    decimal_digits: 2,
    rounding: 0,
    code: "MWK",
    icon_name: "mwk",
    name_plural: "Malawian Kwacha",
    type: "fiat",
    countries: ["MW"],
  },
  {
    symbol: "MX$",
    name: "Mexican Peso",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "MXN",
    name_plural: "Mexican pesos",
    type: "fiat",
    countries: ["MX"],
  },
  {
    symbol: "RM",
    name: "Malaysian Ringgit",
    symbol_native: "RM",
    decimal_digits: 2,
    rounding: 0,
    code: "MYR",
    name_plural: "Malaysian ringgits",
    type: "fiat",
    countries: ["MY"],
  },
  {
    symbol: "MTn",
    name: "Mozambican Metical",
    symbol_native: "MTn",
    decimal_digits: 2,
    rounding: 0,
    code: "MZN",
    name_plural: "Mozambican meticals",
    type: "fiat",
    countries: ["MZ"],
  },
  {
    symbol: "N$",
    name: "Namibian Dollar",
    symbol_native: "N$",
    decimal_digits: 2,
    rounding: 0,
    code: "NAD",
    name_plural: "Namibian dollars",
    type: "fiat",
    countries: ["NA"],
  },
  {
    symbol: "₦",
    name: "Nigerian Naira",
    symbol_native: "₦",
    decimal_digits: 2,
    rounding: 0,
    code: "NGN",
    name_plural: "Nigerian nairas",
    type: "fiat",
    countries: ["NG"],
  },
  {
    symbol: "C$",
    name: "Nicaraguan Córdoba",
    symbol_native: "C$",
    decimal_digits: 2,
    rounding: 0,
    code: "NIO",
    name_plural: "Nicaraguan córdobas",
    type: "fiat",
    countries: ["NI"],
  },
  {
    symbol: "Nkr",
    name: "Norwegian Krone",
    symbol_native: "kr",
    decimal_digits: 2,
    rounding: 0,
    code: "NOK",
    name_plural: "Norwegian kroner",
    type: "fiat",
    countries: ["BV", "NO", "SJ"],
  },
  {
    symbol: "NPRs",
    name: "Nepalese Rupee",
    symbol_native: "नेरू",
    decimal_digits: 2,
    rounding: 0,
    code: "NPR",
    name_plural: "Nepalese rupees",
    type: "fiat",
    countries: ["NP"],
  },
  {
    symbol: "NZ$",
    name: "New Zealand Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "NZD",
    name_plural: "New Zealand dollars",
    type: "fiat",
    countries: ["CK", "NU", "NZ", "PN", "TK"],
  },
  {
    symbol: "OMR",
    name: "Omani Rial",
    symbol_native: "ر.ع.‏",
    decimal_digits: 3,
    rounding: 0,
    code: "OMR",
    name_plural: "Omani rials",
    type: "fiat",
    countries: ["OM"],
  },
  {
    symbol: "B/.",
    name: "Panamanian Balboa",
    symbol_native: "B/.",
    decimal_digits: 2,
    rounding: 0,
    code: "PAB",
    name_plural: "Panamanian balboas",
    type: "fiat",
    countries: ["PA"],
  },
  {
    symbol: "S/.",
    name: "Peruvian Nuevo Sol",
    symbol_native: "S/.",
    decimal_digits: 2,
    rounding: 0,
    code: "PEN",
    name_plural: "Peruvian nuevos soles",
    type: "fiat",
    countries: ["PE"],
  },
  {
    symbol: "K",
    name: "Papua New Guinean Kina",
    symbol_native: "K",
    decimal_digits: 2,
    rounding: 0,
    code: "PGK",
    icon_name: "pgk",
    name_plural: "Papua New Guinean Kina",
    type: "fiat",
    countries: ["PG"],
  },
  {
    symbol: "₱",
    name: "Philippine Peso",
    symbol_native: "₱",
    decimal_digits: 2,
    rounding: 0,
    code: "PHP",
    name_plural: "Philippine pesos",
    type: "fiat",
    countries: ["PH"],
  },
  {
    symbol: "PKRs",
    name: "Pakistani Rupee",
    symbol_native: "₨",
    decimal_digits: 0,
    rounding: 0,
    code: "PKR",
    name_plural: "Pakistani rupees",
    type: "fiat",
    countries: ["PK"],
  },
  {
    symbol: "zł",
    name: "Polish Zloty",
    symbol_native: "zł",
    decimal_digits: 2,
    rounding: 0,
    code: "PLN",
    name_plural: "Polish zlotys",
    type: "fiat",
    countries: ["PL"],
  },
  {
    symbol: "₲",
    name: "Paraguayan Guarani",
    symbol_native: "₲",
    decimal_digits: 0,
    rounding: 0,
    code: "PYG",
    name_plural: "Paraguayan guaranis",
    type: "fiat",
    countries: ["PY"],
  },
  {
    symbol: "QR",
    name: "Qatari Rial",
    symbol_native: "ر.ق.‏",
    decimal_digits: 2,
    rounding: 0,
    code: "QAR",
    name_plural: "Qatari rials",
    type: "fiat",
    countries: ["QA"],
  },
  {
    symbol: "RON",
    name: "Romanian Leu",
    symbol_native: "RON",
    decimal_digits: 2,
    rounding: 0,
    code: "RON",
    name_plural: "Romanian lei",
    type: "fiat",
    countries: ["RO"],
  },
  {
    symbol: "din.",
    name: "Serbian Dinar",
    symbol_native: "дин.",
    decimal_digits: 0,
    rounding: 0,
    code: "RSD",
    name_plural: "Serbian dinars",
    type: "fiat",
    countries: ["RS"],
  },
  {
    symbol: "RUB",
    name: "Russian Ruble",
    symbol_native: "руб.",
    decimal_digits: 2,
    rounding: 0,
    code: "RUB",
    name_plural: "Russian rubles",
    type: "fiat",
    countries: ["RU", "SU"],
  },
  {
    symbol: "RWF",
    name: "Rwandan Franc",
    symbol_native: "FR",
    decimal_digits: 0,
    rounding: 0,
    code: "RWF",
    name_plural: "Rwandan francs",
    type: "fiat",
    countries: ["RW"],
  },
  {
    symbol: "SR",
    name: "Saudi Riyal",
    symbol_native: "ر.س.‏",
    decimal_digits: 2,
    rounding: 0,
    code: "SAR",
    name_plural: "Saudi riyals",
    type: "fiat",
    countries: ["SA"],
  },
  {
    symbol: "SI$",
    name: "Solomon Islands Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "SBD",
    icon_name: "sbd",
    name_plural: "Solomon Islands Dollars",
    type: "fiat",
    countries: ["SB"],
  },
  {
    symbol: "SRe",
    name: "Seychellois Rupee",
    symbol_native: "SR",
    decimal_digits: 2,
    rounding: 0,
    code: "SCR",
    icon_name: "scr",
    name_plural: "Seychellois Rupees",
    type: "fiat",
    countries: ["SC"],
  },
  {
    symbol: "SDG",
    name: "Sudanese Pound",
    symbol_native: "SDG",
    decimal_digits: 2,
    rounding: 0,
    code: "SDG",
    name_plural: "Sudanese pounds",
    type: "fiat",
    countries: ["SD"],
  },
  {
    symbol: "Skr",
    name: "Swedish Krona",
    symbol_native: "kr",
    decimal_digits: 2,
    rounding: 0,
    code: "SEK",
    name_plural: "Swedish kronor",
    type: "fiat",
    countries: ["SE"],
  },
  {
    symbol: "S$",
    name: "Singapore Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "SGD",
    name_plural: "Singapore dollars",
    type: "fiat",
    countries: ["SG"],
  },
  {
    symbol: "£",
    name: "Saint Helena Pound",
    symbol_native: "£",
    decimal_digits: 2,
    rounding: 0,
    code: "SHP",
    icon_name: "shp",
    name_plural: "Saint Helena Pounds",
    type: "fiat",
    countries: ["SH"],
  },
  {
    symbol: "Le",
    name: "Sierra Leonean Leone",
    symbol_native: "Le",
    decimal_digits: 2,
    rounding: 0,
    code: "SLL",
    icon_name: "sll",
    name_plural: "Sierra Leonean Leone",
    type: "fiat",
    countries: ["SL"],
  },
  {
    symbol: "Ssh",
    name: "Somali Shilling",
    symbol_native: "Ssh",
    decimal_digits: 0,
    rounding: 0,
    code: "SOS",
    name_plural: "Somali shillings",
    type: "fiat",
    countries: ["SO"],
  },
  {
    symbol: "$",
    name: "Surinamese Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "SRD",
    icon_name: "srd",
    name_plural: "Surinamese Dollar",
    type: "fiat",
    countries: ["SR"],
  },
  {
    symbol: "Db",
    name: "São Tomé and Príncipe dobra",
    symbol_native: "Db",
    decimal_digits: 2,
    rounding: 0,
    code: "STD",
    name_plural: "São Tomé and Príncipe dobras",
    type: "fiat",
    countries: ["ST"],
  },
  {
    symbol: "₡",
    name: "Salvadoran Colón",
    symbol_native: "₡",
    decimal_digits: 2,
    rounding: 0,
    code: "SVC",
    icon_name: "svc",
    name_plural: "Salvadoran Colón",
    type: "fiat",
    countries: [],
  },
  {
    symbol: "SY£",
    name: "Syrian Pound",
    symbol_native: "ل.س.‏",
    decimal_digits: 0,
    rounding: 0,
    code: "SYP",
    name_plural: "Syrian pounds",
    type: "fiat",
    countries: ["SY"],
  },
  {
    symbol: "L",
    name: "Swazi Lilangeni",
    symbol_native: "E‏",
    decimal_digits: 2,
    rounding: 0,
    code: "SZL",
    icon_name: "szl",
    name_plural: "Swazi Lilangeni",
    type: "fiat",
    countries: ["SZ"],
  },
  {
    symbol: "฿",
    name: "Thai Baht",
    symbol_native: "฿",
    decimal_digits: 2,
    rounding: 0,
    code: "THB",
    name_plural: "Thai baht",
    type: "fiat",
    countries: ["TH"],
  },
  {
    symbol: "TJS",
    name: "Tajikistani Somoni",
    symbol_native: "TJS",
    decimal_digits: 2,
    rounding: 0,
    code: "TJS",
    icon_name: "tjs",
    name_plural: "Tajikistani Somoni",
    type: "fiat",
    countries: ["TJ"],
  },
  {
    symbol: "T",
    name: "Turkmenistani Manat",
    symbol_native: "T‏",
    decimal_digits: 2,
    rounding: 0,
    code: "TMT",
    icon_name: "tmt",
    name_plural: "Turkmenistani Manat",
    type: "fiat",
    countries: ["TM"],
  },
  {
    symbol: "DT",
    name: "Tunisian Dinar",
    symbol_native: "د.ت.‏",
    decimal_digits: 3,
    rounding: 0,
    code: "TND",
    name_plural: "Tunisian dinars",
    type: "fiat",
    countries: ["TN"],
  },
  {
    symbol: "T$",
    name: "Tongan Paʻanga",
    symbol_native: "T$",
    decimal_digits: 2,
    rounding: 0,
    code: "TOP",
    name_plural: "Tongan paʻanga",
    type: "fiat",
    countries: ["TO"],
  },
  {
    symbol: "TL",
    name: "Turkish Lira",
    symbol_native: "TL",
    decimal_digits: 2,
    rounding: 0,
    code: "TRY",
    name_plural: "Turkish Lira",
    type: "fiat",
    countries: ["TR"],
  },
  {
    symbol: "TT$",
    name: "Trinidad and Tobago Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "TTD",
    name_plural: "Trinidad and Tobago dollars",
    type: "fiat",
    countries: ["TT"],
  },
  {
    symbol: "NT$",
    name: "New Taiwan Dollar",
    symbol_native: "NT$",
    decimal_digits: 2,
    rounding: 0,
    code: "TWD",
    name_plural: "New Taiwan dollars",
    type: "fiat",
    countries: ["TW"],
  },
  {
    symbol: "TSh",
    name: "Tanzanian Shilling",
    symbol_native: "TSh",
    decimal_digits: 0,
    rounding: 0,
    code: "TZS",
    name_plural: "Tanzanian shillings",
    type: "fiat",
    countries: ["TZ"],
  },
  {
    symbol: "₴",
    name: "Ukrainian Hryvnia",
    symbol_native: "₴",
    decimal_digits: 2,
    rounding: 0,
    code: "UAH",
    name_plural: "Ukrainian hryvnias",
    type: "fiat",
    countries: ["UA"],
  },
  {
    symbol: "USh",
    name: "Ugandan Shilling",
    symbol_native: "USh",
    decimal_digits: 0,
    rounding: 0,
    code: "UGX",
    name_plural: "Ugandan shillings",
    type: "fiat",
    countries: ["UG"],
  },
  {
    symbol: "$",
    name: "US Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "USD",
    name_plural: "US dollars",
    type: "fiat",
    countries: [
      "AC",
      "AS",
      "BQ",
      "DG",
      "EC",
      "FM",
      "GU",
      "HT",
      "IO",
      "MH",
      "MP",
      "PA",
      "PR",
      "PW",
      "SV",
      "TC",
      "TL",
      "UM",
      "US",
      "VG",
      "VI",
      "ZW",
    ],
  },
  {
    symbol: "$U",
    name: "Uruguayan Peso",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "UYU",
    name_plural: "Uruguayan pesos",
    type: "fiat",
    countries: ["UY"],
  },
  {
    symbol: "UZS",
    name: "Uzbekistan Som",
    symbol_native: "UZS",
    decimal_digits: 0,
    rounding: 0,
    code: "UZS",
    name_plural: "Uzbekistan som",
    type: "fiat",
    countries: ["UZ"],
  },
  {
    symbol: "Bs.F.",
    name: "Venezuelan Bolívar",
    symbol_native: "Bs.F.",
    decimal_digits: 2,
    rounding: 0,
    code: "VEF",
    name_plural: "Venezuelan bolívars",
    type: "fiat",
    countries: ["VE"],
  },
  {
    symbol: "₫",
    name: "Vietnamese Dong",
    symbol_native: "₫",
    decimal_digits: 0,
    rounding: 0,
    code: "VND",
    name_plural: "Vietnamese dong",
    type: "fiat",
    countries: ["VN"],
  },
  {
    symbol: "VUV",
    name: "Vanuatu Vatu",
    symbol_native: "VT",
    decimal_digits: 0,
    rounding: 0,
    code: "VUV",
    icon_name: "vuv",
    name_plural: "Vanuatu Vatu",
    type: "fiat",
    countries: ["VU"],
  },
  {
    symbol: "WS$",
    name: "Samoan Tala",
    symbol_native: "T",
    decimal_digits: 2,
    rounding: 0,
    code: "WST",
    icon_name: "wst",
    name_plural: "Samoan Tala",
    type: "fiat",
    countries: ["WS"],
  },
  {
    symbol: "FCFA",
    name: "CFA Franc BEAC",
    symbol_native: "FCFA",
    decimal_digits: 0,
    rounding: 0,
    code: "XAF",
    name_plural: "CFA francs BEAC",
    type: "fiat",
    countries: ["CF", "CG", "CM", "GA", "GQ", "TD"],
  },
  {
    symbol: "XAG",
    name: "Silver Ounce",
    symbol_native: "XAG",
    decimal_digits: 2,
    rounding: 0,
    code: "XAG",
    name_plural: "Silver Ounces",
    type: "metal",
    countries: [],
  },
  {
    symbol: "XAU",
    name: "Gold Ounce",
    symbol_native: "XAU",
    decimal_digits: 2,
    rounding: 0,
    code: "XAU",
    name_plural: "Gold Ounces",
    type: "metal",
    countries: [],
  },
  {
    symbol: "EC$",
    name: "East Caribbean Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "XCD",
    icon_name: "xcd",
    name_plural: "East Caribbean Dollars",
    type: "fiat",
    countries: ["AG", "AI", "DM", "GD", "KN", "LC", "MS", "VC"],
  },
  {
    symbol: "SDR",
    name: "Special drawing rights",
    symbol_native: "SDR",
    decimal_digits: 2,
    rounding: 0,
    code: "XDR",
    name_plural: "Special drawing rights",
    type: "fiat",
    countries: [],
  },
  {
    symbol: "CFA",
    name: "CFA Franc BCEAO",
    symbol_native: "CFA",
    decimal_digits: 0,
    rounding: 0,
    code: "XOF",
    name_plural: "CFA francs BCEAO",
    type: "fiat",
    countries: ["BF", "BJ", "CI", "GW", "ML", "NE", "SN", "TG"],
  },
  {
    symbol: "CFP",
    name: "CFP Franc",
    symbol_native: "CFP",
    decimal_digits: 0,
    rounding: 0,
    code: "XPF",
    icon_name: "xpf",
    name_plural: "CFP francs",
    type: "fiat",
    countries: ["NC", "PF", "WF"],
  },
  {
    symbol: "YR",
    name: "Yemeni Rial",
    symbol_native: "ر.ي.‏",
    decimal_digits: 0,
    rounding: 0,
    code: "YER",
    name_plural: "Yemeni rials",
    type: "fiat",
    countries: ["YE"],
  },
  {
    symbol: "R",
    name: "South African Rand",
    symbol_native: "R",
    decimal_digits: 2,
    rounding: 0,
    code: "ZAR",
    name_plural: "South African rand",
    type: "fiat",
    countries: ["LS", "NA", "ZA", "ZW"],
  },
  {
    symbol: "ZK",
    name: "Zambian Kwacha",
    symbol_native: "ZK",
    decimal_digits: 0,
    rounding: 0,
    code: "ZMK",
    name_plural: "Zambian kwachas",
    type: "fiat",
    countries: [],
  },
  {
    symbol: "ZK",
    name: "Zambian Kwacha",
    symbol_native: "ZK",
    decimal_digits: 0,
    rounding: 0,
    code: "ZMW",
    name_plural: "Zambian kwachas",
    type: "fiat",
    countries: ["ZM"],
  },
  {
    symbol: "ZWL",
    name: "Zimbabwean dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "ZWL",
    name_plural: "Zimbabwean dollars",
    type: "fiat",
    countries: [],
  },
  {
    symbol: "XPT",
    name: "Platinum Ounce",
    symbol_native: "XPT",
    decimal_digits: 6,
    rounding: 0,
    code: "XPT",
    name_plural: "Platinum Ounces",
    type: "metal",
    countries: [],
  },
  {
    symbol: "XPD",
    name: "Palladium Ounce",
    symbol_native: "XPD",
    decimal_digits: 6,
    rounding: 0,
    code: "XPD",
    name_plural: "Palladium Ounces",
    type: "metal",
    countries: [],
  },
  {
    symbol: "₿",
    name: "Bitcoin",
    symbol_native: "₿",
    decimal_digits: 8,
    rounding: 0,
    code: "BTC",
    name_plural: "Bitcoins",
    type: "crypto",
    countries: [],
  },
  {
    symbol: "Ξ",
    name: "Ethereum",
    symbol_native: "Ξ",
    decimal_digits: 18,
    rounding: 0,
    code: "ETH",
    name_plural: "Ethereum",
    type: "crypto",
    countries: [],
  },
  {
    symbol: "BNB",
    name: "Binance",
    symbol_native: "BNB",
    decimal_digits: 8,
    rounding: 0,
    code: "BNB",
    name_plural: "Binance",
    type: "crypto",
    countries: [],
  },
  {
    symbol: "XRP",
    name: "Ripple",
    symbol_native: "XRP",
    decimal_digits: 6,
    rounding: 0,
    code: "XRP",
    name_plural: "Ripple",
    type: "crypto",
    countries: [],
  },
  {
    symbol: "SOL",
    name: "Solana",
    symbol_native: "SOL",
    decimal_digits: 9,
    rounding: 0,
    code: "SOL",
    name_plural: "Solana",
    type: "crypto",
    countries: [],
  },
  {
    symbol: "DOT",
    name: "Polkadot",
    symbol_native: "DOT",
    decimal_digits: 10,
    rounding: 0,
    code: "DOT",
    name_plural: "Polkadot",
    type: "crypto",
    countries: [],
  },
  {
    symbol: "AVAX",
    name: "Avalanche",
    symbol_native: "AVAX",
    decimal_digits: 18,
    rounding: 0,
    code: "AVAX",
    name_plural: "Avalanche",
    type: "crypto",
    countries: [],
  },
  {
    symbol: "MATIC",
    name: "Matic Token",
    symbol_native: "MATIC",
    decimal_digits: 18,
    rounding: 0,
    code: "MATIC",
    name_plural: "Matic Tokens",
    type: "crypto",
    countries: [],
  },
  {
    symbol: "Ł",
    name: "Litecoin",
    symbol_native: "Ł",
    decimal_digits: 8,
    rounding: 0,
    code: "LTC",
    name_plural: "Litecoins",
    type: "crypto",
    countries: [],
  },
  {
    symbol: "ADA",
    name: "Cardano",
    symbol_native: "ADA",
    decimal_digits: 6,
    rounding: 0,
    code: "ADA",
    name_plural: "Cardanos",
    type: "crypto",
    countries: [],
  },
  {
    symbol: "USDT",
    name: "Tether",
    symbol_native: "USDT",
    decimal_digits: 2,
    rounding: 0,
    code: "USDT",
    name_plural: "Tethers",
    type: "crypto",
    countries: [],
  },
  {
    symbol: "USDC",
    name: "USD Coin",
    symbol_native: "USDC",
    decimal_digits: 2,
    rounding: 0,
    code: "USDC",
    name_plural: "USD Coins",
    type: "crypto",
    countries: [],
  },
  {
    symbol: "DAI",
    name: "Dai",
    symbol_native: "DAI",
    decimal_digits: 2,
    rounding: 0,
    code: "DAI",
    name_plural: "Dais",
    type: "crypto",
    countries: [],
  },
  {
    symbol: "ARB",
    name: "Arbitrum",
    symbol_native: "ARB",
    decimal_digits: 8,
    rounding: 0,
    code: "ARB",
    name_plural: "Arbitrums",
    type: "crypto",
    countries: [],
  },
  {
    symbol: "OP",
    name: "Optimism",
    symbol_native: "OP",
    decimal_digits: 8,
    rounding: 0,
    code: "OP",
    name_plural: "Optimism",
    type: "crypto",
    countries: [],
  },
  {
    symbol: "Bs.S.",
    name: "Venezuelan Bolívar",
    symbol_native: "Bs.S.",
    decimal_digits: 2,
    rounding: 0,
    code: "VES",
    name_plural: "Venezuelan bolívars",
    type: "fiat",
    countries: [],
  },
  {
    symbol: "STN",
    name: "São Tomé and Príncipe dobra",
    symbol_native: "STN",
    decimal_digits: 2,
    rounding: 0,
    code: "STN",
    name_plural: "dobra",
    type: "fiat",
    countries: [],
  },
  {
    symbol: "MRU",
    name: "Mauritanian ouguiya",
    symbol_native: "MRU",
    decimal_digits: 2,
    rounding: 0,
    code: "MRU",
    name_plural: "ouguiya",
    type: "fiat",
    countries: [],
  },
];
