import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as VacinationsActions } from "../../store/ducks/vacinations";

import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Icon,
  Input,
  InputNumber,
  Menu,
  Row,
  Select,
  Table,
  notification,
} from "antd";
import DrawerAnimalVaccinationForm from "../../components/drawers/animalVaccination";
import ButtonStandard from "../../components/utils/button";
import CustomLink from "../../components/utils/customLink";
import MenuIcon from "../../components/utils/table/icons/menu";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import { CardCustom, Title } from "./styles";

// Services
import { numberMask } from "../../services/helpersMethodsService";
import { deleteVaccination } from "../../services/vacinationService";
import { transformCamelCaseToSnakeCase } from "../../utils/stringUtils";

const Vaccinations = () => {
  const dispatch = useDispatch();
  const {
    vacinations: { data, isLoading, tableProperties },
    app: {
      translation,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
  } = useSelector((state) => state);

  const { Column } = Table;

  const handleNewVaccination = useCallback(() => {
    dispatch(VacinationsActions.openDrawerForm());
  }, [dispatch]);

  const handleRefresh = useCallback(() => {
    dispatch(VacinationsActions.index(groupId, farmId));
  }, [groupId, farmId, dispatch]);

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetDateSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 6,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Row type="flex" gutter={4}>
          <Col>
            <Select
              value={selectedKeys ? selectedKeys[1] : "equal"}
              onChange={(value) =>
                setSelectedKeys(
                  value
                    ? selectedKeys
                      ? [selectedKeys[0], value]
                      : [null, value]
                    : selectedKeys
                    ? [selectedKeys[0], "equal"]
                    : [null, "equal"]
                )
              }
            >
              <Select.Option value="equal">=</Select.Option>
              <Select.Option value="greaterThan">&gt;</Select.Option>
              <Select.Option value="greaterOrEqualThan">&ge;</Select.Option>
              <Select.Option value="lessThan">&lt;</Select.Option>
              <Select.Option value="lessOrEqualThan">&le;</Select.Option>
            </Select>
          </Col>
          <Col>
            <DatePicker
              allowClear={true}
              value={
                selectedKeys
                  ? selectedKeys[0] != null
                    ? moment(selectedKeys[0], "YYYY-MM-DD")
                    : undefined
                  : undefined
              }
              style={{ width: 175, marginBottom: 8 }}
              format={"DD/MM/YYYY"}
              onChange={(date, dateString) => {
                const operator =
                  selectedKeys && selectedKeys[1] ? selectedKeys[1] : "equal";
                setSelectedKeys(date ? [date, operator] : [null, operator]);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </Row>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon
        type="calendar"
        style={{ color: filtered ? "#684e94" : undefined }}
      />
    ),
  });

  const handleGetColumnNumberSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 6,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Row type="flex" gutter={4}>
          <Col>
            <Select
              value={selectedKeys ? selectedKeys[1] : "equal"}
              onChange={(value) =>
                setSelectedKeys(
                  value
                    ? selectedKeys
                      ? [selectedKeys[0], value]
                      : [null, value]
                    : selectedKeys
                    ? [selectedKeys[0], "equal"]
                    : [null, "equal"]
                )
              }
            >
              <Select.Option value="equal">=</Select.Option>
              <Select.Option value="greaterThan">&gt;</Select.Option>
              <Select.Option value="greaterOrEqualThan">&ge;</Select.Option>
              <Select.Option value="lessThan">&lt;</Select.Option>
              <Select.Option value="lessOrEqualThan">&le;</Select.Option>
            </Select>
          </Col>
          <Col>
            <InputNumber
              value={
                selectedKeys
                  ? selectedKeys[0] != null
                    ? selectedKeys[0]
                    : undefined
                  : undefined
              }
              onChange={(e) => {
                const operator =
                  selectedKeys && selectedKeys[1] ? selectedKeys[1] : "equal";
                setSelectedKeys(e ? [e, operator] : [null, operator]);
              }}
              onPressEnter={() => handleSearch(selectedKeys, confirm)}
              style={{ width: 90, marginBottom: 8 }}
              defaultValue={0}
              decimalSeparator=","
              min={-1000}
              max={1000}
              step={0.01}
              formatter={(value) => {
                if (value !== "") {
                  value = `${value}`.replace(/^-^[^0-9.,]*/g, "");
                  value = value.replace(/\.{2,}/g, ".");
                  value = value.replace(/\.,/g, ",");
                  value = value.replace(/,\./g, ",");
                  value = value.replace(/,{2,}/g, ",");
                  value = value.replace(/\.[0-9]+\./g, ".");
                  value =
                    value.split(".")[1] !== "" &&
                    value.split(".")[1] !== undefined
                      ? value.split(".")[0] +
                        "." +
                        value.split(".")[1].substring(0, 2)
                      : value;
                }
                return value;
              }}
            />
          </Col>
        </Row>
        <Row>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </Row>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys && selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
    }),
    [handleReset, handleSearch]
  );

  const handleEdit = useCallback(
    (data) => {
      dispatch(
        VacinationsActions.openDrawerForm({
          ...data,
          handlingNumber: data.animalName,
        })
      );
    },
    [dispatch]
  );

  const handleDelete = useCallback(
    async (id, data) => {
      try {
        await deleteVaccination({
          groupId,
          farmId,
          id,
        });
        notification.success({
          message: "Aplicação excluída com sucesso",
        });
        handleRefresh();
      } catch (error) {
        notification.error({
          message: "Não foi possível excluir aplicação. Contate o suporte",
        });
      }
    },
    [handleRefresh, groupId, farmId]
  );

  const menu = (id, record) => {
    return (
      <Menu>
        <Menu.Item key="1" onClick={() => handleEdit(record)}>
          {translation.table.menu.edit}
        </Menu.Item>
        <Menu.Item key="2" onClick={() => handleDelete(id, record)}>
          {translation.table.menu.delete}
        </Menu.Item>
      </Menu>
    );
  };

  const getSortString = useCallback((sorter) => {
    let customSort = sorter;

    if (sorter && Object.entries(sorter).length > 0) {
      const { field } = sorter;

      const fieldS = field ? transformCamelCaseToSnakeCase(field) : null;

      customSort = {
        ...customSort,
        field: fieldS,
      };
    }

    return customSort;
  }, []);

  const getSearchString = useCallback((filters) => {
    let search = "";
    if (filters && Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([field, value]) => {
        if (value && value.length > 0) {
          const fieldS = field ? transformCamelCaseToSnakeCase(field) : null;
          if (fieldS === "application_day") {
            const operatorS =
              value[1] === "equal"
                ? "="
                : value[1] === "greaterThan"
                ? ">"
                : value[1] === "greaterOrEqualThan"
                ? ">="
                : value[1] === "lessThan"
                ? "<"
                : value[1] === "lessOrEqualThan"
                ? "<="
                : "=";

            search =
              search === ""
                ? `cast(${fieldS} as date) ${operatorS} cast('${moment(
                    value[0]
                  ).format("YYYY-MM-DD")}' as date)`
                : `${search};cast(${fieldS} as date) ${operatorS} cast('${moment(
                    value[0]
                  ).format("YYYY-MM-DD")}' as date)`;
          } else if (fieldS === "veterinary_supplement_type") {
            search =
              search === ""
                ? `${fieldS} IN (${value
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`
                : `${search};${fieldS} IN (${value
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`;
          } else if (fieldS === "application_quantity") {
            const operatorS =
              value[1] === "equal"
                ? "="
                : value[1] === "greaterThan"
                ? ">"
                : value[1] === "greaterOrEqualThan"
                ? ">="
                : value[1] === "lessThan"
                ? "<"
                : value[1] === "lessOrEqualThan"
                ? "<="
                : "=";
            search =
              search === ""
                ? `(application_value ${operatorS} ${value[0]} OR applicate_value ${operatorS} ${value[0]})`
                : `${search};(application_value ${operatorS} ${value[0]} OR applicate_value ${operatorS} ${value[0]})`;
          } else {
            search =
              search === ""
                ? `upper(${fieldS}) like upper('%25${value}%25')`
                : `${search};upper(${fieldS}) like upper('%25${value}%25')`;
          }
        }
      });
    }

    return search !== "" ? search : "";
  }, []);

  const handleTableChange = useCallback(
    async (pagination = null, filters = null, sorter = null) => {
      if (pagination && filters && sorter) {
        let customSort = getSortString(sorter);
        let search = getSearchString(filters);
        dispatch(
          VacinationsActions.index(
            groupId,
            farmId,
            pagination.current,
            pagination.pageSize,
            customSort,
            search,
            filters,
            sorter
          )
        );
      } else {
        dispatch(
          VacinationsActions.index(groupId, farmId, 0, 10, {}, "", {}, {})
        );
      }
    },
    [getSortString, getSearchString, dispatch, groupId, farmId]
  );

  useEffect(() => {
    if (groupId && farmId) {
      dispatch(VacinationsActions.index(groupId, farmId));
    }
  }, [groupId, farmId, dispatch]);

  return (
    <CardCustom>
      <Row type="flex" justify="space-between" style={{ marginBottom: "19px" }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Title>{translation.handling.vacinations.table.title}</Title>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Row type="flex" justify="end" gutter={16}>
            <ButtonStandard
              buttonType="typeWithoutBackground"
              onClick={handleRefresh}
            >
              <Icon type="reload" />
            </ButtonStandard>
            <ButtonStandard buttonType="type8" onClick={handleNewVaccination}>
              {translation.handling.vacinations.buttonNew}
            </ButtonStandard>
          </Row>
        </Col>
      </Row>
      <Row type="flex">
        <Table
          loading={isLoading}
          rowKey="id"
          size="small"
          dataSource={data !== null ? data.content : []}
          scroll={{
            x: true,
          }}
          pagination={{
            total: data != null ? data.totalElements : 0,
            size: data != null ? tableProperties.size : 0,
            current: data != null ? tableProperties.page : 0,
            hideOnSinglePage:
              data !== null && Object.entries(data).length !== 0
                ? data.totalElements > 10
                  ? false
                  : true
                : true,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
          }}
          style={{ width: "100%", margin: "0" }}
          onChange={handleTableChange}
        >
          <Column
            title={translation.handling.vacinations.table.columns.animalName}
            dataIndex="animalName"
            key="animalName"
            align="left"
            filteredValue={tableProperties.tableFilters?.animalName || null}
            sorter
            sortOrder={
              tableProperties.tableSorters?.columnKey === "animalName" &&
              tableProperties.tableSorters.order
            }
            {...handleGetColumnSearchProps("animalName")}
            render={(text, record) => (
              <CustomLink to={`/admin/animals/${record.animalId}`}>
                {text}
              </CustomLink>
            )}
          />
          <Column
            title={
              translation.handling.vacinations.table.columns
                .veterinarySupplementName
            }
            dataIndex="veterinarySupplementName"
            key="veterinarySupplementName"
            align="left"
            filteredValue={
              tableProperties.tableFilters?.veterinarySupplementName || null
            }
            sorter
            sortOrder={
              tableProperties.tableSorters?.columnKey ===
                "veterinarySupplementName" && tableProperties.tableSorters.order
            }
            {...handleGetColumnSearchProps("veterinarySupplementName")}
          />
          <Column
            title={translation.handling.vacinations.table.columns.type}
            dataIndex="veterinarySupplementType"
            key="veterinarySupplementType"
            align="left"
            filteredValue={
              tableProperties.tableFilters?.veterinarySupplementType || null
            }
            sorter
            sortOrder={
              tableProperties.tableSorters?.columnKey ===
                "veterinarySupplementType" && tableProperties.tableSorters.order
            }
            filters={[
              {
                text: "Vacina",
                value: "Vacina",
              },
              {
                text: "Vermífugo",
                value: "Vermífugo",
              },
              {
                text: "Medicamento",
                value: "Medicamento",
              },
            ]}
            filterMultiple
          />
          <Column
            title={
              translation.handling.vacinations.table.columns.applicationDate
            }
            dataIndex="applicationDay"
            key="applicationDay"
            align="left"
            filteredValue={tableProperties.tableFilters?.applicationDay || null}
            sorter
            sortOrder={
              tableProperties.tableSorters?.columnKey === "applicationDay" &&
              tableProperties.tableSorters.order
            }
            render={(text) => <span>{moment(text).format("DD/MM/YYYY")}</span>}
            {...handleGetDateSearchProps("applicationDay")}
          />
          <Column
            title={
              translation.handling.vacinations.table.columns.applicationQuantity
            }
            dataIndex="applicationQuantity"
            key="applicationQuantity"
            align="left"
            filteredValue={
              tableProperties.tableFilters?.applicationQuantity || null
            }
            {...handleGetColumnNumberSearchProps("applicationQuantity")}
            render={(text) => (
              <span>{text != null ? numberMask(text) : 0}</span>
            )}
          />
          <Column
            title={
              translation.handling.vacinations.table.columns.applicationUnit
            }
            dataIndex="applicationUnit"
            key="applicationUnit"
            align="left"
            filteredValue={
              tableProperties.tableFilters?.applicationUnit || null
            }
            sorter
            sortOrder={
              tableProperties.tableSorters?.columnKey === "applicationUnit" &&
              tableProperties.tableSorters.order
            }
          />
          <Table.Column
            title=""
            align="left"
            render={(text, record) =>
              record.groupId !== null ? (
                <Dropdown
                  overlay={menu(record.id, record)}
                  trigger={["click"]}
                  key={record.id}
                >
                  <MenuContainer>
                    <MenuIcon />
                  </MenuContainer>
                </Dropdown>
              ) : null
            }
          />
        </Table>
      </Row>
      <DrawerAnimalVaccinationForm />
    </CardCustom>
  );
};

export default Vaccinations;
