import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Axios from "axios";
import CanvasJSReact from "../../../../assets/canvasjs.react";
// Components
import { Container, Title } from "./styles";
import { Row, Col, Spin } from "antd";
import InfoTooltip from "../../../../components/utils/infoTooltip";
// Services
import { returnObjectFromAxis } from "../../../../services/dashboards/dashboardCommonService";
import { getTwoDecimalDigits } from "../../../../services/helpersMethodsService";
import { getDecisionProfitAndCostDashboard } from "../../../../services/dashboards/dashboardDecisionService";

function DecisionProfitAndCostDash() {
  // Variables
  const { CanvasJSChart } = CanvasJSReact;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const toggleDataSeries = (e) => {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    e.chart.render();
  };
  const chartOptions = {
    animationEnabled: true,
    zoomEnabled: true,
    height: 200,
    dataPointWidth: 22,
    axisX: {
      valueFormatString: "DD/MM/YY",
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
      },
    },
    toolTip: {
      fontFamily: "Asap",
      shared: true,
    },
    axisY: {
      includeZero: true,
      prefix: "R$ ",
      valueFormatString: "###0.##",
      gridDashType: "dot",
    },
    legend: {
      cursor: "pointer",
      itemclick: toggleDataSeries,
    },
    data: data,
  };
  // Redux variables
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  // Effects
  // Get Dashboards Values
  useEffect(() => {
    let signal = Axios.CancelToken.source();
    let mount = true;
    async function fetch() {
      let dData = [];
      let listEstProfit = [];
      let listEstCost = [];
      let listActProfit = [];
      let listActCost = [];

      setIsLoading(true);
      try {
        const {
          data: { results },
        } = await getDecisionProfitAndCostDashboard({
          groupId,
          farmId,
          signal,
        });
        const decisionProfitAndCostData = returnObjectFromAxis(results, false);
        listEstProfit = decisionProfitAndCostData.map((dpcd) => {
          return { x: dpcd.x, y: getTwoDecimalDigits(dpcd.EstimatedProfit) };
        });
        listEstCost = decisionProfitAndCostData.map((dpcd) => {
          return { x: dpcd.x, y: getTwoDecimalDigits(dpcd.EstimatedCost) };
        });
        listActProfit = decisionProfitAndCostData.map((dpcd) => {
          return { x: dpcd.x, y: getTwoDecimalDigits(dpcd.ActualProfit) };
        });
        listActCost = decisionProfitAndCostData.map((dpcd) => {
          return { x: dpcd.x, y: getTwoDecimalDigits(dpcd.ActualCost) };
        });
        dData = [
          {
            type: "column",
            name: "Lucro planejado",
            xValueFormatString: "DD/MM/YY",
            color: "#56A1D5",
            showInLegend: true,
            yValueFormatString: "R$ ###0.00",
            dataPoints: listEstProfit,
          },
          {
            type: "column",
            name: "Custo planejado",
            xValueFormatString: "DD/MM/YY",
            color: "#FFD36A",
            showInLegend: true,
            yValueFormatString: "R$ ###0.00",
            dataPoints: listEstCost,
          },
          {
            type: "column",
            name: "Lucro real",
            xValueFormatString: "DD/MM/YY",
            color: "#87C79C",
            showInLegend: true,
            yValueFormatString: "R$ ###0.00",
            dataPoints: listActProfit,
          },
          {
            type: "column",
            name: "Custo real",
            xValueFormatString: "DD/MM/YY",
            color: "#D44C4C",
            showInLegend: true,
            yValueFormatString: "R$ ###0.00",
            dataPoints: listActCost,
          },
        ];
      } catch (error) {}
      if (mount) {
        setData(dData);
        setIsLoading(false);
      }
    }
    if (groupId != null && farmId != null) {
      fetch();
    }
    return () => {
      mount = false;
      signal.cancel();
    };
  }, [groupId, farmId]);
  return (
    <Container>
      <Row type="flex">
        <Col span={24}>
          <Title>
            Margem
            <InfoTooltip title="Esta sendo considerado somente Cenários de venda marcados como Favorito" />
          </Title>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <Spin spinning={isLoading}>
            <CanvasJSChart options={chartOptions} />
          </Spin>
        </Col>
      </Row>
    </Container>
  );
}

export default DecisionProfitAndCostDash;
