import React, { Component } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Row,
  Col,
  Table,
  Input,
  Button,
  Icon,
  Menu,
  Dropdown,
  DatePicker,
} from "antd";

import { Creators as SupplementActions } from "../../store/ducks/supplement";
import { Creators as SupplierActions } from "../../store/ducks/supplier";

import { Title, CardCustom } from "./styles";
import TagStatus from "../../components/utils/tagStatus";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import MenuIcon from "../../components/utils/table/icons/menu";
import DrawerSupplement from "../../components/drawers/supplement";
import DrawerSupplementDetails from "../../components/drawers/supplementDetails";
import ButtonStandard from "../../components/utils/button";
import { numberMask } from "../../services/helpersMethodsService";

class Supplement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      success: false,
    };
  }

  static propTypes = {
    supplementActions: PropTypes.any.isRequired,
    app: PropTypes.any.isRequired,
    supplement: PropTypes.any.isRequired,
  };

  async componentDidMount() {
    const {
      app: {
        groupSelected,
        farmSelected,
        user: { isInputSupplier },
      },
    } = this.props;
    if (
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      await Promise.all([
        this.fetchData(groupSelected, farmSelected, 0, {}),
        !isInputSupplier
          ? this.getSuppliers(groupSelected, farmSelected)
          : null,
      ]);
    }
  }

  //Update table data when supplier
  componentWillReceiveProps(nextProps) {
    const {
      app: { farmSelected: nextFarm, groupSelected: nextGroup },
    } = nextProps;

    if (nextProps && nextFarm && nextFarm?.id !== this.state.farmSelected?.id) {
      this.setState({ farmSelected: nextFarm });
      this.fetchData(nextGroup, nextFarm, 0, {});
    }
  }

  getSuppliers = async (groupSelected, farmSelected) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    const { supplierActions } = this.props;
    supplierActions.getDropdownSuppliers(groupId, farmId, "Supplement", true);
  };

  expandedRows = (record, index, indent, expanded) => {
    const {
      app: { translation },
    } = this.props;
    const columns = [
      {
        title: translation.supplement.table.columns.version,
        dataIndex: "version",
        key: "version",
      },
      {
        title: translation.supplement.table.columns.createdAt,
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text) => (
          <span>{moment(text).format("DD/MM/YYYY HH:mm")}</span>
        ),
      },
      {
        title: translation.supplement.table.columns.userName,
        dataIndex: "userName",
        key: "userName",
      },
      {
        title: translation.supplement.table.columns.tdn,
        dataIndex: "tdn",
        key: "tdn",
        render: (text) => <span>{`${numberMask(text || 0, false)} g/kg`}</span>,
      },
      {
        title: translation.supplement.table.columns.cp,
        dataIndex: "cp",
        key: "cp",
        render: (text) => <span>{`${numberMask(text || 0, false)} g/kg`}</span>,
      },
      {
        title: translation.supplement.table.columns.dryMatterPercentage,
        dataIndex: "dryMatterPercentage",
        key: "dryMatterPercentage",
        render: (text) => <span>{`${numberMask(text || 0, false)} %MS`}</span>,
      },
      {
        title: translation.supplement.table.columns.calcium,
        dataIndex: "calcium",
        key: "calcium",
        render: (text) => <span>{`${numberMask(text || 0, false)} g/kg`}</span>,
      },
      {
        title: translation.supplement.table.columns.phosphorus,
        dataIndex: "phosphorus",
        key: "phosphorus",
        render: (text) => <span>{`${numberMask(text || 0, false)} g/kg`}</span>,
      },
      {
        title: translation.supplement.table.columns.nitrogen,
        dataIndex: "nitrogen",
        key: "nitrogen",
        render: (text) => (
          <span>{`${numberMask(text || 0, false)} mg/kg`}</span>
        ),
      },
      {
        title: translation.supplement.table.columns.zinc,
        dataIndex: "zinc",
        key: "zinc",
        render: (text) => (
          <span>{`${numberMask(text || 0, false)} mg/kg`}</span>
        ),
      },
      {
        title: translation.supplement.table.columns.sodium,
        dataIndex: "sodium",
        key: "sodium",
        render: (text) => <span>{`${numberMask(text || 0, false)} g/kg`}</span>,
      },
    ];

    return (
      <Table
        rowKey="id"
        columns={columns}
        dataSource={record.versions}
        pagination={{ pageSize: 5, hideOnSinglePage: true }}
      />
    );
  };

  fetchData = async (
    groupSelected,
    farmSelected,
    page = 0,
    sorter = {},
    filters = "",
    size = 10
  ) => {
    const {
      supplementActions,
      app: {
        user: { isInputSupplier },
      },
    } = this.props;
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    supplementActions.indexSupplement(
      groupId,
      farmId,
      page,
      sorter,
      filters,
      size,
      isInputSupplier
    );
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        {dataIndex === "createdAt" ? (
          <DatePicker
            allowClear={true}
            value={
              selectedKeys[0] != null
                ? moment(selectedKeys[0], "YYYY-MM-DD")
                : undefined
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
            format={"DD/MM/YYYY"}
            onChange={(date, dateString) => {
              setSelectedKeys(date ? [date] : []);
            }}
          />
        ) : (
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              this.handleSearch(selectedKeys, confirm, dataIndex)
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        )}
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
            background: "#684e94",
            borderColor: "none",
            border: "none",
          }}
        />
        <Button
          onClick={() => this.handleReset(clearFilters)}
          icon="delete"
          size="small"
          style={{ width: 90 }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
    onFilter: (value, record) => {
      return dataIndex === "createdAt"
        ? record[dataIndex]
            ?.toString()
            .includes(moment(value).format("YYYY-MM-DD"))
        : record[dataIndex]?.toString().includes(value);
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleEdit = async (id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
        user: { isInputSupplier },
      },
      supplementActions,
    } = this.props;
    supplementActions.showSupplement(groupId, farmId, id, isInputSupplier);
    supplementActions.showDrawer();
  };

  handleDetails = async (id) => {
    const { supplementActions } = this.props;
    supplementActions.showHideSupplementDetails(id);
  };

  render() {
    const {
      app: { translation },
      supplement: { data, isLoading },
      supplier: { listDrowpDown: suppliers },
      supplementActions,
    } = this.props;

    const Column = Table.Column;

    const menu = (id) => {
      const {
        app: { translation },
      } = this.props;
      return (
        <Menu>
          <Menu.Item key="1" onClick={() => this.handleEdit(id)}>
            {translation.table.menu.edit}
          </Menu.Item>
          <Menu.Item key="2" onClick={() => this.handleDetails(id)}>
            {translation.table.menu.details}
          </Menu.Item>
        </Menu>
      );
    };

    return (
      <CardCustom>
        <Row type="flex" justify="space-between" className="rowHeader">
          <Col>
            <Title>{translation.supplement.table.title}</Title>
          </Col>
          <Col>
            <ButtonStandard
              type="button"
              buttonType="type8"
              onClick={() => supplementActions.showDrawer("new")}
            >
              {translation.supplement.buttonNewSupplement}
            </ButtonStandard>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              loading={isLoading}
              expandedRowRender={this.expandedRows}
              rowKey="id"
              size="small"
              dataSource={data != null ? data : []}
              pagination={{
                total: data != null ? data.length : 0,
                size: data != null ? data.length : 0,
                hideOnSinglePage: true,
              }}
              scroll={{
                x: true,
              }}
              style={{ width: "100%", margin: "0" }}
            >
              <Column
                title={translation.supplement.table.columns.status}
                dataIndex="status"
                key="status"
                align="left"
                sorter={(first, second) => {
                  return first.status.localeCompare(second.status, "pt-BR", {
                    numeric: false,
                    ignorePunctuation: true,
                  });
                }}
                filtered
                filters={[
                  {
                    text: translation.status.active,
                    value: "Active",
                  },
                  {
                    text: translation.status.inactive,
                    value: "Inactive",
                  },
                ]}
                filterMultiple
                onFilter={(value, record) => record.status.includes(value)}
                render={(status) =>
                  status === "Active" ? (
                    <TagStatus
                      background="#C5F1CA"
                      borderColor="#106518"
                      color="#106518"
                    >
                      {translation.status.active}
                    </TagStatus>
                  ) : (
                    <TagStatus
                      background="#FBC7C7"
                      borderColor="#D44C4C"
                      color="#D44C4C"
                    >
                      {translation.status.inactive}
                    </TagStatus>
                  )
                }
              />
              <Column
                title={translation.supplement.table.columns.description}
                dataIndex="name"
                align="left"
                sorter={(first, second) => {
                  return first.name.localeCompare(second.name, "pt-BR", {
                    numeric: false,
                    ignorePunctuation: true,
                  });
                }}
                key="name"
                {...this.getColumnSearchProps("name")}
              />
              <Column
                title={translation.supplement.table.columns.industrialName}
                dataIndex="industrialName"
                align="left"
                sorter={(first, second) => {
                  if (!first.industrialName) {
                    first.industrialName = "";
                  }
                  return first.industrialName.localeCompare(
                    second.industrialName,
                    "pt-BR",
                    {
                      numeric: false,
                      ignorePunctuation: true,
                    }
                  );
                }}
                key="industrialName"
                {...this.getColumnSearchProps("industrialName")}
              />
              <Column
                title="Tipo"
                dataIndex="type"
                key="type"
                align="left"
                sorter={(first, second) => {
                  return first.type.localeCompare(second.type, "pt-BR", {
                    numeric: false,
                    ignorePunctuation: true,
                  });
                }}
                filtered
                filters={[
                  {
                    text: "Ração/Mistura",
                    value: "Ration_Mixture",
                  },
                  {
                    text: "Suplemento",
                    value: "Supplement",
                  },
                ]}
                onFilter={(value, record) => record.type.includes(value)}
                render={(status) =>
                  status === "Supplement" ? (
                    <span>Suplemento</span>
                  ) : (
                    <span>Ração/Mistura</span>
                  )
                }
              />
              <Column
                title={translation.supplement.table.columns.supplierName}
                dataIndex="supplierId"
                align="left"
                key="supplierId"
                sorter={(first, second) => {
                  if (!first.supplierId) {
                    first.supplierId = "";
                  }
                  return suppliers
                    .find((s) => s.id === first.supplierId)
                    ?.name.localeCompare(
                      suppliers.find((s) => s.id === second.supplierId)?.name,
                      "pt-BR",
                      {
                        numeric: false,
                        ignorePunctuation: true,
                      }
                    );
                }}
                render={(text, record) =>
                  record.supplierId != null && suppliers.length > 0
                    ? suppliers.find((s) => s.id === record.supplierId)?.name
                    : ""
                }
              />
              <Column
                title={translation.supplement.table.columns.createdAt}
                dataIndex="createdAt"
                align="left"
                key="createdAt"
                {...this.getColumnSearchProps("createdAt")}
                sorter={(first, second) => {
                  return moment(first.createdAt).isBefore(
                    moment(second.createdAt)
                  );
                }}
                render={(text, record) => (
                  <span>{moment(record.createdAt).format("DD/MM/YYYY")}</span>
                )}
              />
              <Column
                title={translation.supplement.table.columns.userName}
                dataIndex="userName"
                align="left"
                key="userName"
                {...this.getColumnSearchProps("userName")}
                sorter={(first, second) => {
                  if (!first.userName) {
                    first.userName = "";
                  }
                  return first?.userName.localeCompare(
                    second?.userName,
                    "pt-BR",
                    {
                      numeric: false,
                      ignorePunctuation: true,
                    }
                  );
                }}
              />
              <Column
                align="left"
                render={(text, record) => (
                  <Dropdown
                    overlay={menu(record.id)}
                    trigger={["click"]}
                    key={record.id}
                  >
                    <MenuContainer>
                      <MenuIcon />
                    </MenuContainer>
                  </Dropdown>
                )}
              />
            </Table>
          </Col>
        </Row>
        <DrawerSupplement />
        <DrawerSupplementDetails />
      </CardCustom>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  supplement: state.supplement,
  supplier: state.supplier,
});

const mapDispatchToProps = (dispatch) => ({
  supplementActions: bindActionCreators(SupplementActions, dispatch),
  supplierActions: bindActionCreators(SupplierActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Supplement)
);
