import React from 'react';

// import { Container } from './styles';

const LogoBull = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28.94" height="34.051" viewBox="0 0 28.94 34.051">
    <g id="Logo_2" data-name="Logo 2" transform="translate(-146 -523)">
      <path
        id="Caminho_328"
        data-name="Caminho 328"
        d="M27.447,5.652,23.58-.02c-.052-.052-.155,0-.1.052.309,1.341,1.392,6.548,1.6,8.043.258,1.753-1.083,1.7-1.083,1.7H19.765a.365.365,0,0,0-.361.412l1.289,6.548a4.036,4.036,0,0,0-2.166,1.65,5.649,5.649,0,0,1-1.8,1.237c-.052,0-.052.1,0,.1a8.418,8.418,0,0,0,3.248.309L16.774,30.4H12.34L9.092,20.088a8.418,8.418,0,0,0,3.248-.309c.052,0,.052-.1,0-.1a6.268,6.268,0,0,1-1.8-1.237c-.619-.722-1.341-1.547-2.166-1.65L9.659,10.24A.34.34,0,0,0,9.3,9.828H5.07s-1.341.1-1.083-1.7c.206-1.547,1.289-6.7,1.6-8.043,0-.052-.1-.1-.1-.052L1.564,5.652S-.962,9.158.481,11.684c1.186,2.011,4.125,2.63,4.125,2.63s1.65.309,1.289,1.6a2.329,2.329,0,0,1-.722,1.237.05.05,0,0,0-.052.052l2.526,7.373L9.814,30.71a.465.465,0,0,1,.052.258V32.36a.56.56,0,0,0,.206.464l.825.928a.716.716,0,0,0,.516.258H17.6a.635.635,0,0,0,.516-.258l.825-.928a.927.927,0,0,0,.206-.464V30.968a.465.465,0,0,1,.052-.258l2.166-6.136L23.89,17.2c0-.052,0-.052-.052-.052a2.084,2.084,0,0,1-.722-1.237c-.361-1.289,1.289-1.6,1.289-1.6s2.939-.567,4.125-2.63C29.974,9.158,27.447,5.652,27.447,5.652Z"
        transform="translate(145.964 523.042)"
        fill="#6c6c6c"
        opacity="0.583"
      />
    </g>
  </svg>
);
export default LogoBull;
