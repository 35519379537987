import apiFinancial from "../config/api_financial";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiFinancial = axios.create({
//   baseURL: "http://localhost:8086",
// });

export function fetchResultCenterTransactionsInfo(payload) {
  const {
    groupId,
    farmId,
    resultCenterId,
    costCenterId,
    startDate,
    endDate,
    financialProjectId,
    currency,
    signal,
  } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/financial/result/center/${resultCenterId}/analysis/cost/center/${costCenterId}?currency=${currency}&startDate=${startDate}&endDate=${endDate}${
      financialProjectId ? `&financialProjectId=${financialProjectId}` : ""
    }`,
    getHeaders(false, signal)
  );
}
