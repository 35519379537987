import React from 'react';

// import { Container } from './styles';

const ArrowInactiveIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.651"
    height="21.651"
    viewBox="0 0 21.651 21.651"
  >
    <circle
      id="Ellipse_168"
      data-name="Ellipse 168"
      cx="10.826"
      cy="10.826"
      r="10.826"
      transform="translate(0 21.651) rotate(-90)"
      fill="#684e94"
    />
    <path
      id="Union_46"
      data-name="Union 46"
      d="M7043.7-3433.793l-.26.237.26-.237L7038-3439l5.7,5.206,5.7-5.206-5.7,5.206.26.237Z"
      transform="translate(3447.499 7054.132) rotate(-90)"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
    />
  </svg>
);
export default ArrowInactiveIcon;
