import styled, { keyframes } from "styled-components";

const loginContainerAnimation = keyframes`
  0% {
    right: 150%;
  }
  100% {
    right: 0%;
  }
`;

const opacityAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  height: 100% !important;
  min-height: 100% !important;
  width: 100% !important;
  min-width: 100% !important;
  display: flex;
  flex-direction: column;
  overflow-x: hidden !important;

  @media (max-width: 767.98px) {
    flex: 1;
    height: 100% !important;
    min-height: 100% !important;
    width: 100% !important;
    min-width: 100% !important;
  }
`;

export const Container = styled.div`
  height: 100% !important;
  min-height: 100% !important;
  width: 100% !important;
  min-width: 100% !important;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  flex-direction: row;
  background: #ffffff;

  @media all and (width: 812px) and (height: 375px) {
    height: 100% !important;
    min-height: 100% !important;
    width: 100% !important;
    min-width: 100% !important;
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;

    span {
      margin: 10px 0;
    }
  }

  @media all and (max-width: 767px) {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;

    span {
      margin: 10px 0;
    }
  }
`;

export const LoginContainer = styled.div`
  position: relative;
  width: 630px;
  height: 100% !important;
  min-height: 100% !important;

  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  flex-direction: column;

  align-items: flex-start;

  background: #fff;

  padding: 40px 57px 32px 46px;

  svg {
    width: 136.56px !important;
    height: 24px !important;
    margin-left: 19px;
  }

  @media screen and (max-width: 1024px) {
    /* start of large tablet styles */
    width: 100% !important;
    align-items: center;
    align-content: center;
    padding: 0;
    svg {
      width: auto !important;
      height: auto !important;
      margin: 0;
      margin-top: 40px;
    }
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
    width: 100% !important;
    align-items: center;
    align-content: center;
    padding: 0;
    svg {
      width: auto !important;
      height: auto !important;
      margin: 0;
    }
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
    width: 100% !important;
    align-items: center;
    align-content: center;
    padding: 0;
    svg {
      width: auto !important;
      height: auto !important;
      margin: 0;
    }
  }

  /* @media all and (max-width: 991px) {
    width: 630px !important;
    height: 560px !important;
    svg.logo_vertical {
      max-width: 180px !important;
      max-height: 160px !important;
    }
  }

  @media all and (width: 812px) and (height: 375px) {
    & {
      width: 100% !important;
      height: 446px;
      min-height: 446px;
      padding: 10px 0;
      svg.logo_vertical {
        max-width: 120px !important;
        max-height: 103px !important;
      }
    }
  }

  @media all and (max-width: 767px) {
    & {
      width: 100% !important;
      height: 446px;
      min-height: 446px;

      svg.logo_vertical {
        max-width: 120px !important;
        max-height: 103px !important;
      }
    }
  }

  @media all and (max-width: 479px) {
    & {
      width: 100% !important;
      height: 446px;
      min-height: 446px;
      svg.logo_vertical {
        width: 100%;
        height: auto;
        max-width: 120px !important;
        max-height: 103px !important;
      }
    }
  } */
`;

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    /* start of large tablet styles */
    width: 100% !important;
    img {
      width: auto !important;
      height: auto !important;
      margin: 0;
      margin-top: 40px;
    }
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
    width: 100% !important;
    img {
      width: auto !important;
      height: auto !important;
      margin: 0;
    }
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
    width: 100% !important;
    img {
      width: auto !important;
      height: auto !important;
      margin: 0;
    }
  }
`;

export const FormContainer = styled.div`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${(props) => (props.marginTop ? "50px" : "111px")};

  & > * {
    position: relative;
    animation: ${loginContainerAnimation};
    -webkit-animation: ${loginContainerAnimation};
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
  }

  .title {
    position: relative;
    font-family: "Asap", sans-serif;
    font-weight: bold;
    font-size: 45px;
    color: #111;
    margin-left: 19px;
  }
  .subTitle {
    font-family: "Asap", sans-serif;
    font-weight: normal;
    font-size: 16px;
    color: #111;
    margin-left: 19px;
  }

  .captcha {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  label {
    margin-left: 19px !important;
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #9074bf;
    text-transform: uppercase;
    margin-top: 25px;
    margin-bottom: 7px;
  }

  form {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    width: 100% !important;
    min-width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

    span.error {
      color: #c14933;
      margin-left: 19px;
    }
    a {
      margin-top: 20px;
      font-size: 1em;
      text-decoration: none;
    }

    .formFooter {
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        margin-left: 19px;
        margin-bottom: 0;
      }
      .forgotPasswordSpan {
        font-family: "Asap", sans-serif;
        font-size: 14px;
        text-align: right;
      }

      .forgotPasswordLink {
        text-decoration: none;
        font-family: "Asap", sans-serif;
        font-size: 14px;
        font-weight: bold;
        color: #684e94;
        text-align: right;
        margin-left: 6px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
    margin-top: 50px;
  }
`;

export const FooterContainer = styled.div`
  margin-top: auto;
  margin-bottom: 50px;
  & > * {
    position: relative;
    animation: ${loginContainerAnimation};
    -webkit-animation: ${loginContainerAnimation};
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
  }

  @media screen and (max-width: 1024px) {
    /* start of large tablet styles */
    margin-top: 50px;
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
    margin-top: 50px;
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
    margin-top: 10px;
  }
`;

export const ButtonContainer = styled.button`
  width: 139.35px;
  height: 30px;
  border-radius: 4px;
  background: transparent;
  border: 1px solid #684e94;
  color: #684e94;
  margin-left: 12px;

  &:hover {
    cursor: pointer;
  }
`;

export const DisplayContainer = styled.div`
  width: 100% !important;
  height: 100% !important;
  min-height: 100% !important;
  opacity: 0.69 !important;
  background: linear-gradient(#c6de52 0%, #6a7b16 100%);
  /* filter: drop-shadow(0px 3px 18px rgba(0, 0, 0, 0.38)) !important; */

  img {
    width: 100% !important;
    min-width: 100% !important;
    height: 100% !important;
    min-height: 100% !important;
  }

  #myVideo {
    position: fixed;
    opacity: 0.69;
    min-width: 100%;
    min-height: 100%;
  }
  @media screen and (max-width: 1024px) {
    /* start of large tablet styles */
    display: none;
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
    display: none;
  }

  @media screen and (max-width: 479px) {
    /* start of phone styles */
    display: none;
  }
`;

export const DisplayBody = styled.div`
  width: 100% !important;
  min-width: 100% !important;
  height: 100% !important;
  min-height: 100% !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 32px;
`;

export const DisplayBodyMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    -webkit-animation: ${opacityAnimation};
    animation: ${opacityAnimation};
    animation-timing-function: ease-in;
  }

  svg {
    width: 111px !important;
    height: 130px !important;
    margin-right: 41px !important;
    animation-duration: 1s;
    path {
      fill: #fff !important;
      opacity: 1 !important;
    }
  }
  span {
    font-family: "Asap", sans-serif;
    font-weight: bold;
    font-size: 47px;
    text-align: left;
    color: #fff;
    animation-duration: 2s;
  }
`;

export const DisplayHeader = styled.header`
  min-width: 480px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  & > * {
    -webkit-animation: ${opacityAnimation};
    animation: ${opacityAnimation};
    animation-timing-function: ease-in;
  }

  span {
    width: 160px;
    height: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: "Asap", sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    animation-duration: 2s;
    cursor: pointer;
  }

  span:hover {
    color: #ebebeb;
  }

  .border {
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }
`;

export const DisplayFooter = styled.footer`
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  & > * {
    -webkit-animation: ${opacityAnimation};
    animation: ${opacityAnimation};
    animation-timing-function: ease-in;
  }

  span {
    animation-duration: 2s;
    cursor: pointer;
  }

  .pad {
    padding-top: 3px;
  }
`;
