import styled from "styled-components";

export const AnimalGeneologyContainer = styled.div`
  padding: 1rem;
`;

export const StyledNode = styled.div`
  width: 200px;

  display: inline-block;
  gap: 0.5rem;
  padding: 1rem;

  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3);

  border-radius: 6px;
  border: 2px solid #684e94;

  font-family: Asap, sans-serif;
  font-size: 0.875rem;
  color: #684e94;
  font-weight: bold;

  span {
    position: relative;
  }

  .icon {
  }

  span:first-child {
    &::after {
      display: block;
      content: "";
      width: 100%;
      height: 2px;
      background-color: #684e94;
      margin-bottom: 0.5rem;
    }
  }
`;
