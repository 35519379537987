import React, { useEffect, useState } from "react";
import { Container } from "./styles";

// Redux
import { useSelector } from "react-redux";

// Libs
import {
	Spin,
	Timeline
} from "antd";
import Axios from "axios";

// Services
import { getEventsHistory } from "../../../services/eventLogService";

const EntityHistory = (props) => {
	/** Redux variables */
	const {
		translation,
		groupSelected: { id: groupId },
		farmSelected: { id: farmId },
	} = useSelector((state) => state.app);

	// State
	const [historyData, setHistoryData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [signal, setSignal] = useState(null);

	//On component start
	useEffect(() => {
		const newSignal = Axios.CancelToken.source();
		setSignal(newSignal);
	}, []);

	//On component load data
	useEffect(() => {

		async function fetchData() {
			setIsLoading(true);
			try {
				let {
					data: { results: response },
				} = await getEventsHistory({ groupId, farmId, entity: props.entity, entityId: props.entityId, signal: signal });
				setHistoryData(response);
			} catch (error) {
			} finally {
				setIsLoading(false);
			}
		}

		if (signal) {
			fetchData();

			// returned function will be called on component unmount 
			return () => {
				setHistoryData([])
				signal.cancel("Canceling EventLog History");
				setSignal(null);
			};
		}

	}, [signal, farmId, groupId, props.entity, props.entityId])

	function renderTimelineItem(item) {
		let text = null;
		let textDetail = null;

		let fieldName = translation.eventLog[props.entity][item.fieldName];
		let textUser = item.userName != null && (<span className="timeline-detail">{translation.eventLog.userLabel}: <b>{item.userName}</b></span>);
		
		if (item.fieldName === "createdAt") {
			text = `${fieldName} ${item.dateTime}.`
		} else if (fieldName) {
			text = `${item.dateTime} ${translation.eventLog.attributeLabel}: ${fieldName}.`
			textDetail = (<span className="timeline-detail">{`${translation.eventLog.fromValueLabel}: `} <b>{item.previousValue != null ?
				item.previousValue : translation.eventLog.nullLabel}</b> {` ${translation.eventLog.toValueLabel}: `} <b>{item.newValue != null ?
					item.newValue : translation.eventLog.nullLabel}</b></span>);
		} else {
			return null;
		}

		return (<Timeline.Item key={item.id}>
			<span className="timeline-title">{text}</span>
			{textDetail}
			{textUser}
		</Timeline.Item>);
	}

	return (
		<Spin spinning={isLoading}>
			<Container>
				<Timeline>
					{historyData && historyData.length > 0 ?
						historyData.map((h) => renderTimelineItem(h))
						: null}
				</Timeline>
			</Container>
		</Spin>
	);
}

export default EntityHistory;