import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as LotActions } from "../../../store/ducks/lot";
// Components
import { Col, Icon, Row, Spin } from "antd";
import {
  ButtonSession,
  ActivateLotModalContainer,
  Message,
  Title,
} from "./styles";
import TagStatus from "../../utils/tagStatus";

function ActivateLotModal() {
  // Redux variables
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { isLoadingRequest, showModalActivateLot, dataLot } = useSelector(
    (state) => state.lot
  );
  const dispatch = useDispatch();
  // Variables
  // Effects
  // Methods
  const cancelAction = () => {
    dispatch(LotActions.showOrHideModalActivate(null));
  };
  const confirmAction = () => {
    const { id } = dataLot;
    dispatch(LotActions.activateLotProduction(groupId, farmId, id));
  };
  return (
    <ActivateLotModalContainer
      visible={showModalActivateLot}
      footer={null}
      centered
      maskClosable={false}
      closable={false}
    >
      <Row type="flex" justify="start">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Title>
            <Icon
              type="question-circle"
              style={{ color: "#4A85AE", fontSize: 24 }}
            />
            <strong>{`Você realmente deseja reativar este lote ?`}</strong>
          </Title>
        </Col>
      </Row>
      <Spin spinning={isLoadingRequest}>
        <Row type="flex" justify="start" style={{ marginTop: 8 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Message>
              {"Após a confirmação desta ação o lote: "}
              <strong>{dataLot?.name || "-"}</strong>
              {" será reativado, e seu novo status poderá ser: "}
              <TagStatus
                background="#FFDBBC"
                borderColor="#FE8D2A"
                color="#FE8D2A"
              >
                Pendente
              </TagStatus>
              {" ou "}
              <TagStatus
                background="#C5F1CA"
                borderColor="#106518"
                color="#106518"
              >
                {translation.status.active}
              </TagStatus>
            </Message>
          </Col>
        </Row>
        <Row type="flex" justify={"space-between"} style={{ marginTop: 21 }}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} align="left">
            <ButtonSession className="red" onClick={cancelAction}>
              {translation.buttons.cancel}
            </ButtonSession>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
            <ButtonSession onClick={confirmAction}>
              {translation.buttons.confirm}
            </ButtonSession>
          </Col>
        </Row>
      </Spin>
    </ActivateLotModalContainer>
  );
}

export default ActivateLotModal;
