import React, { useState } from "react";
import { Container, CustomDivider, SupplementInfoCard, Table } from "./styles";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { Creators as SupplementActions } from "../../../store/ducks/supplement";

// Libs
import { Col, Row, Spin, Tabs } from "antd";

// Components
import EntityHistory from "../entityHistory";
import DrawerSupplementSustenanceCapacityDetails from "./supplementSustenanceCapacityDetails";

// Icons
import EyeIcon from "../../utils/icons/eye";
import NoteIcon from "../../utils/icons/note";

// Services
import { numberMask } from "../../../services/helpersMethodsService";
import { getSupplementShow } from "../../../services/supplementService";
import { getSupplierShow } from "../../../services/supplierService";

const DrawerSupplementDetails = () => {
  /** Redux variables */
  const dispatch = useDispatch();

  const { drawerDetailsVisible, idSupplementDetails } = useSelector(
    (state) => state.supplement
  );

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
    user: { isInputSupplier },
  } = useSelector((state) => state.app);

  // State
  const [tabSelect, setTabSelect] = useState("1");
  const [supplementData, setSupplementData] = useState(null);
  const [isLoadingDrawer, setIsLoadingDrawer] = useState(false);
  const [showDrawerSustenanceCapacity, setShowDrawerSustenanceCapacity] =
    useState(false);
  const [supplementType] = useState({
    Supplement: "Suplemento",
    Ration_Mixture: "Ração/Mistura",
  });
  const [supplementClassification] = useState({
    Mineral: "Mineral",
    MineralProtein: "Mineral Proteíco",
    MineralEnergyProtein: "Mineral Proteíco / Energético",
  });

  /** Methods variables */
  function closeDrawer() {
    setTabSelect("1");
    setSupplementData(null);
    dispatch(SupplementActions.showHideSupplementDetails(null));
  }

  function closeSustenanceCapacityDrawer() {
    setShowDrawerSustenanceCapacity(false);
  }

  async function handleDrawerVisible(visible) {
    if (visible) {
      setIsLoadingDrawer(true);
      try {
        let {
          data: { results: response },
        } = await getSupplementShow({
          groupId,
          farmId,
          id: idSupplementDetails,
          isInputSupplier,
        });

        let supplierName = null;

        if (response.supplierId && !isInputSupplier) {
          const {
            data: { results },
          } = await getSupplierShow({
            groupId: groupId,
            id: response.supplierId,
          });
          supplierName = results.name;
        }

        setSupplementData({ ...response, supplierName: supplierName });
      } catch (error) {
      } finally {
        setIsLoadingDrawer(false);
      }
    }
  }

  const handleTabClick = (key, e) => {
    setTabSelect(key);
  };

  function renderDetailsSupplementData() {
    return (
      <div className="drawerForm">
        {/* Type */}
        <Row type="flex" style={{ marginTop: "13px" }} align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row>
              <label>{translation.supplement.details.supplementType}</label>
            </Row>
            <Row className="rowInput">
              <span className="rowInputData">
                {supplementType[supplementData?.type]}
              </span>
            </Row>
          </Col>
        </Row>

        {/* Classification */}
        <Row type="flex" style={{ marginTop: "13px" }} align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row>
              <label>
                {translation.supplement.details.supplementClassification}
              </label>
            </Row>
            <Row className="rowInput">
              <span className="rowInputData">
                {supplementClassification[supplementData?.classification]}
              </span>
            </Row>
          </Col>
        </Row>

        {/* Name */}
        <Row type="flex" style={{ marginTop: "13px" }} align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row>
              <label>{translation.supplement.details.name}</label>
            </Row>
            <Row className="rowInput">
              <span className="rowInputData">{supplementData.name}</span>
            </Row>
          </Col>
        </Row>

        {/* Industrial Name */}
        <Row type="flex" style={{ marginTop: "13px" }} align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row>
              <label>{translation.supplement.details.industrialName}</label>
            </Row>
            <Row className="rowInput">
              <span className="rowInputData">
                {supplementData.industrialName}
              </span>
            </Row>
          </Col>
        </Row>

        {/* Supplier */}
        <Row type="flex" style={{ marginTop: "13px" }} align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row>
              <label>{translation.supplement.details.supplierName}</label>
            </Row>
            <Row className="rowInput">
              <span className="rowInputData">
                {supplementData.supplierName}
              </span>
            </Row>
          </Col>
        </Row>

        {/* Cost and Proportion */}
        <Row type="flex" style={{ marginTop: "13px" }} align="middle">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Row>
              <label>{translation.supplement.details.cost}</label>
            </Row>
            <Row className="rowInput">
              <span className="rowInputData">
                {numberMask(supplementData.cost, true)} Kg
              </span>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Row>
              <label>{translation.supplement.details.ratio}</label>
            </Row>
            <Row className="rowInput">
              <span className="rowInputData">
                {numberMask(supplementData.proportion, false)} %
              </span>
            </Row>
          </Col>
        </Row>

        <CustomDivider dashed>
          {translation.supplement.details.nutritionalDataDivider}
        </CustomDivider>

        {/* Tnd and Cp */}
        <Row type="flex" style={{ marginTop: "13px" }} align="middle">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Row>
              <label>{translation.supplement.details.tdn}</label>
            </Row>
            <Row className="rowInput">
              <span className="rowInputData">
                {numberMask(supplementData.tdn, false)} g/Kg
              </span>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Row>
              <label>{translation.supplement.details.cp}</label>
            </Row>
            <Row className="rowInput">
              <span className="rowInputData">
                {numberMask(supplementData.cp)} g/Kg
              </span>
            </Row>
          </Col>
        </Row>

        {/* NPN and Dry Matter Percentage */}
        <Row type="flex" style={{ marginTop: "13px" }} align="middle">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Row>
              <label>{translation.supplement.details.npn}</label>
            </Row>
            <Row className="rowInput">
              <span className="rowInputData">
                {numberMask(supplementData.npn, false)} g/Kg
              </span>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Row>
              <label>
                {translation.supplement.details.dryMatterPercentage}
              </label>
            </Row>
            <Row className="rowInput">
              <span className="rowInputData">
                {numberMask(supplementData.dryMatterPercentage)} %MS
              </span>
            </Row>
          </Col>
        </Row>

        {/* Calcium, Phosphorus and Nitrogen */}
        <Row type="flex" style={{ marginTop: "13px" }} align="middle">
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <Row>
              <label>{translation.supplement.details.calcium}</label>
            </Row>
            <Row className="rowInput">
              <span className="rowInputData">
                {numberMask(supplementData.calcium)} g/Kg
              </span>
            </Row>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <Row>
              <label>{translation.supplement.details.phosphorus}</label>
            </Row>
            <Row className="rowInput">
              <span className="rowInputData">
                {numberMask(supplementData.phosphorus)} g/Kg
              </span>
            </Row>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <Row>
              <label>{translation.supplement.details.nitrogen}</label>
            </Row>
            <Row className="rowInput">
              <span className="rowInputData">
                {numberMask(supplementData.nitrogen)} mg/Kg
              </span>
            </Row>
          </Col>
        </Row>

        {/* Zinc, Sodium and Additive Effect */}
        <Row type="flex" style={{ marginTop: "13px" }} align="middle">
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <Row>
              <label>{translation.supplement.details.zinc}</label>
            </Row>
            <Row className="rowInput">
              <span className="rowInputData">
                {numberMask(supplementData.zinc)} mg/Kg
              </span>
            </Row>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <Row>
              <label>{translation.supplement.details.sodium}</label>
            </Row>
            <Row className="rowInput">
              <span className="rowInputData">
                {numberMask(supplementData.sodium)} g/Kg
              </span>
            </Row>
          </Col>
        </Row>

        {/* FDA, Lignin and Ee */}
        <Row type="flex" style={{ marginTop: "13px" }} align="middle">
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <Row>
              <label>{translation.supplement.details.fda}</label>
            </Row>
            <Row className="rowInput">
              <span className="rowInputData">
                {numberMask(supplementData.fda)} g/Kg
              </span>
            </Row>
          </Col>

          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <Row>
              <label>{translation.supplement.details.lignin}</label>
            </Row>
            <Row className="rowInput">
              <span className="rowInputData">
                {numberMask(supplementData.lignin)} g/Kg
              </span>
            </Row>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <Row>
              <label>{translation.supplement.details.ee}</label>
            </Row>
            <Row className="rowInput">
              <span className="rowInputData">
                {numberMask(supplementData.ee)} %/MS
              </span>
            </Row>
          </Col>
        </Row>

        <CustomDivider dashed>
          {translation.supplement.details.capacityDivider}
        </CustomDivider>

        <Row style={{ marginTop: "13px" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table>
              <tbody>
                <tr>
                  <td>
                    <span>
                      {translation.supplement.details.sustenanceCapacity}
                    </span>
                  </td>
                  <td>
                    <button
                      type="button"
                      style={{ marginLeft: "60px" }}
                      onClick={() => setShowDrawerSustenanceCapacity(true)}
                    >
                      <EyeIcon isActive />
                    </button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>

        <SupplementInfoCard>
          <NoteIcon /> {translation.supplement.details.information}
        </SupplementInfoCard>
      </div>
    );
  }

  return (
    <Container
      title={"Detalhes do Suplemento"}
      width={600}
      afterVisibleChange={handleDrawerVisible}
      onClose={closeDrawer}
      maskClosable={false}
      visible={drawerDetailsVisible}
    >
      <Spin spinning={isLoadingDrawer}>
        <Tabs
          type="card"
          defaultActiveKey="1"
          activeKey={tabSelect}
          onTabClick={handleTabClick}
          forceRender={true}
        >
          <Tabs.TabPane
            tab={
              <span>{translation.supplement.details.tabs.supplementData}</span>
            }
            key="1"
          >
            {supplementData != null && renderDetailsSupplementData()}
          </Tabs.TabPane>

          <Tabs.TabPane
            tab={
              <span>
                {translation.supplement.details.tabs.supplementHistory}
              </span>
            }
            key="2"
          >
            {idSupplementDetails !== null && (
              <EntityHistory
                entity="Supplement"
                entityId={idSupplementDetails}
              />
            )}
          </Tabs.TabPane>
        </Tabs>
      </Spin>
      <DrawerSupplementSustenanceCapacityDetails
        drawerVisible={showDrawerSustenanceCapacity}
        data={supplementData}
        onCancel={closeSustenanceCapacityDrawer}
      />
    </Container>
  );
};

export default DrawerSupplementDetails;
