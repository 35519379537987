import apiManagement from "../config/api_management";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiManagement = axios.create({
//   baseURL: "http://localhost:8081",
// });

//Get all integrations of a farm
export function getIndexIntegrationsFarm(payload) {
  const { groupId, farmId } = payload;

  return apiManagement.get(
    `/bovexo/${groupId}/farms/${farmId}/configure/integration`,
    getHeaders(false)
  );
}

//Get dropdown list with all options of partners system
export function getDropdownIntegrationPartners(payload) {
  const { groupId, farmId } = payload;

  return apiManagement.get(
    `/bovexo/${groupId}/farms/${farmId}/integration/partner/avaliable`,
    getHeaders(false)
  );
}

//Find a farm integration by id
export function findIntegrationById(payload) {
  const { groupId, farmId, id } = payload;

  return apiManagement.get(
    `/bovexo/${groupId}/farms/${farmId}/configure/integration/${id}`,
    getHeaders(false)
  );
}

//Save farm integration
export function saveFarmIntegrationPartner(payload) {
  const { groupId, farmId, body } = payload;

  return apiManagement.post(
    `/bovexo/${groupId}/farms/${farmId}/configure/integration`,
    body,
    getHeaders(false)
  );
}

//Update farm integration
export function updateFarmIntegrationPartner(payload) {
  const { groupId, farmId, id, body } = payload;

  return apiManagement.patch(
    `/bovexo/${groupId}/farms/${farmId}/configure/integration/${id}`,
    body,
    getHeaders(false)
  );
}

//Inactivate farm integration
export function inactivateFarmIntegrationPartner(payload) {
  const { groupId, farmId, id } = payload;

  return apiManagement.put(
    `/bovexo/${groupId}/farms/${farmId}/configure/integration/${id}/inactivate`,
    null,
    getHeaders(false)
  );
}

//Reactivate farm integration
export function reactivateFarmIntegrationPartner(payload) {
  const { groupId, farmId, id } = payload;

  return apiManagement.put(
    `/bovexo/${groupId}/farms/${farmId}/configure/integration/${id}/reactivate`,
    null,
    getHeaders(false)
  );
}
