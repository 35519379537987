import apiIam from "../config/api_iam";
import getHeaders from "./getHeaders";

export function pageAllUserInvitesByFarmIdAndGroupId(payload) {
  const { groupId, farmId, page, sorter, filters, size } = payload;
  let sortS = "";

  if (sorter && Object.entries(sorter).length > 0) {
    const { field, order } = sorter;

    const fieldS = field
      ? field === "expirationDate"
        ? "expiration_date"
        : field === "createdAt"
        ? "created_at"
        : field
      : null;

    const orderS = order && order === "descend" ? "DESC" : "ASC";

    sortS = `&sort=${fieldS}&direction=${orderS}`;
  }

  return apiIam.get(
    `/bovexo/${groupId}/farms/${farmId}/users/invites?
    ${page ? `&page=${page - 1}` : ""}
    ${size && `&size=${size}`}
    ${sortS}
    ${filters !== "" && filters ? `&search=${filters}` : ""}`,
    getHeaders(false, null)
  );
}

export function storeUserInvite(payload) {
  const { groupId, farmId, body } = payload;

  return apiIam.post(
    `/bovexo/${groupId}/farms/${farmId}/users/invites`,
    body,
    getHeaders(false, null)
  );
}

export function resubmitUserInvite(payload) {
  const { groupId, farmId, id } = payload;

  return apiIam.put(
    `/bovexo/${groupId}/farms/${farmId}/users/invites/${id}/resubmit`,
    null,
    getHeaders(false, null)
  );
}

export function verifyInviteStatus(inviteId) {
  return apiIam.get(`/bovexo/auth/verify/invites/${inviteId}/status`);
}

export function verifyDocument(document) {
  return apiIam.get(`/bovexo/auth/verify/documents/${document}`);
}

export function registerUserByInvitation(body, inviteId) {
  return apiIam.post(`/bovexo/auth/invites/${inviteId}/register/users`, body);
}
