// import axios from "axios";
import apiFarms from "../config/api_farms";
import getHeaders from "./getHeaders";

// const apiFarms = axios.create({
//   baseURL: "http://localhost:8082",
// });

export function findAllReproductionScoreByGroupIdAndFarmId(payload) {
  const { groupId, farmId } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/reproduction/score`,
    getHeaders(false, null)
  );
}

export function saveReproductionScore(payload) {
  const { groupId, farmId, body } = payload;

  return apiFarms.post(
    `/bovexo/${groupId}/farms/${farmId}/reproduction/score`,
    body,
    getHeaders(false, null)
  );
}
