import React from "react";
import { useSelector } from "react-redux";
import { AnimalFarmReproductiveProtocolContextProvider } from "../../contexts/animalFarmReproductiveProtocolContext";
import useAnimalFarmReproductiveProtocolContext from "../../hooks/useAnimalFarmReproductiveProtocolContext";
import { Link } from "react-router-dom";

import { Col, Icon, Row } from "antd";
import { CardCustom, Title } from "./styles";
import ButtonStandard from "../../components/utils/button";
import ReproductiveProtocolTable from "./table";

const AnimalFarmReproductiveProtocolLayout = () => {
  const { translation } = useSelector((state) => state.app);
  const { fetchData } = useAnimalFarmReproductiveProtocolContext();
  return (
    <CardCustom>
      <Row className="rowHeader" justify="space-between">
        <Col span={8}>
          <Title>{translation.animalFarmReproductiveProtocol.title}</Title>
        </Col>
        <Col span={16}>
          <Row type="flex" justify="end" gutter={8}>
            <Col>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={fetchData}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </Col>
            <Col>
              <Link to="/admin/managements/reproductive/protocol/new">
                <ButtonStandard buttonType="type8">
                  {translation.animalFarmReproductiveProtocol.buttonNew}
                </ButtonStandard>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <ReproductiveProtocolTable />
        </Col>
      </Row>
    </CardCustom>
  );
};

const AnimalFarmReproductiveProtocol = () => {
  return (
    <AnimalFarmReproductiveProtocolContextProvider>
      <AnimalFarmReproductiveProtocolLayout />
    </AnimalFarmReproductiveProtocolContextProvider>
  );
};

export default AnimalFarmReproductiveProtocol;
