import React from "react";
import { Tooltip } from "antd";
import InfoIcon from "../icons/info";

const InfoTooltip = ({ placement, trigger, title }) => (
  <Tooltip placement={placement} trigger={trigger} title={title}>
    <InfoIcon />
  </Tooltip>
);

export default InfoTooltip;
