import { useContext } from "react";
import { AnimalBirthContext } from "../../contexts/animalBirthContext";

const useAnimalBirthContext = () => {
  const value = useContext(AnimalBirthContext);

  return value;
};

export default useAnimalBirthContext;
