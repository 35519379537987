import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Container } from "./styles";

import {
  getAnimalReproductionWeightScoreDashboard,
  reprocessAnimalDashboard,
} from "../../../../../services/dashboards/dashboardAnimalService";
import { Icon, Spin } from "antd";
import moment from "moment";
import ButtonStandard from "../../../../../components/utils/button";

const RED_COLUMN_INDEX = [1, 2, 9];
const ORANGE_COLUMN_INDEX = [3, 8];
const YELLOW_COLUMN_INDEX = [4, 7];
const GREEN_COLUMN_INDEX = [5, 6];
const WEIGHTS_RANGE = [
  {
    range: "540-2000",
    scoreCount: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
    },
  },
  {
    range: "510-540",
    scoreCount: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
    },
  },
  {
    range: "480-510",
    scoreCount: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
    },
  },
  {
    range: "450-480",
    scoreCount: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
    },
  },
  {
    range: "420-450",
    scoreCount: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
    },
  },
  {
    range: "390-420",
    scoreCount: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
    },
  },
  {
    range: "360-390",
    scoreCount: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
    },
  },
  {
    range: "330-360",
    scoreCount: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
    },
  },
  {
    range: "0-330",
    scoreCount: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
    },
  },
];

function AnimalWeightScoreDash() {
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const [dashboardData, setDashboardData] = useState([...WEIGHTS_RANGE]);

  const renderTableDataColumn = useCallback(
    (data, endRed, endOrange, endYellow, endGreen) => {
      return Object.entries(data.scoreCount).map((score) => {
        const color = RED_COLUMN_INDEX.includes(Number(score[0]))
          ? "red"
          : ORANGE_COLUMN_INDEX.includes(Number(score[0]))
          ? "orange"
          : YELLOW_COLUMN_INDEX.includes(Number(score[0]))
          ? "yellow"
          : GREEN_COLUMN_INDEX.includes(Number(score[0]))
          ? "green"
          : "blank";
        const key = `${data.range}_${score[0]}`;
        return (
          <td key={key} className={color}>
            {score[1].toString()}
          </td>
        );
      });
    },
    []
  );

  const renderTableData = useCallback(() => {
    return dashboardData.map((data, indexRow) => {
      const min = `${data.range.split("-")[0]}`;
      const max = `${data.range.split("-")[1]}`;

      const endRed = 4 - indexRow;
      const endOrange = 5 - indexRow;
      const endYellow = 6 - indexRow;
      const endGreen = 8 - indexRow;

      return (
        <tr key={data.range}>
          <td>{min === "0" ? "-" : min}</td>
          <td>{max === "2000" ? "-" : max}</td>
          {renderTableDataColumn(data, endRed, endOrange, endYellow, endGreen)}
        </tr>
      );
    });
  }, [dashboardData, renderTableDataColumn]);

  const handleRefresh = useCallback(async () => {
    if (groupId && farmId) {
      let dashDataList = [...WEIGHTS_RANGE];
      setIsLoadingInternal(true);
      try {
        await reprocessAnimalDashboard({
          groupId,
          farmId,
          dashboard: "AnimalReproductionWeightScoreDashboard",
        });
        const {
          data: { results },
        } = await getAnimalReproductionWeightScoreDashboard({
          groupId,
          farmId,
        });
        if (results) {
          const tableValues = results.values_table;
          if (tableValues && tableValues.length > 0) {
            for (let i = 0; i < tableValues.length; i++) {
              const currentTableValue = tableValues[i];
              const dataDashIndex = dashDataList.findIndex(
                (data) => data.range === currentTableValue.fieldRow
              );
              const dataDash = dashDataList[dataDashIndex];
              if (dataDash && dataDash.scoreCount) {
                const dataDashUpdated = {
                  ...dataDash,
                  scoreCount: {
                    ...dataDash.scoreCount,
                    [currentTableValue.fieldColumn]: currentTableValue.dvalue,
                  },
                };
                dashDataList[dataDashIndex] = dataDashUpdated;
              }
            }
            setDashboardData(dashDataList);
          }
        } else {
          setDashboardData([...WEIGHTS_RANGE]);
        }
      } catch (error) {
        console.error(error);
        setDashboardData([...WEIGHTS_RANGE]);
        setIsLoadingInternal(false);
      } finally {
        setIsLoadingInternal(false);
      }
    }
  }, [farmId, groupId]);

  useEffect(() => {
    const fetchData = async () => {
      let dashDataList = [...WEIGHTS_RANGE];
      setIsLoadingInternal(true);
      try {
        const {
          data: { results },
        } = await getAnimalReproductionWeightScoreDashboard({
          groupId,
          farmId,
        });
        if (results) {
          const tableValues = results.values_table;
          if (tableValues && tableValues.length > 0) {
            for (let i = 0; i < tableValues.length; i++) {
              const currentTableValue = tableValues[i];
              const dataDashIndex = dashDataList.findIndex(
                (data) => data.range === currentTableValue.fieldRow
              );
              const dataDash = dashDataList[dataDashIndex];
              if (dataDash && dataDash.scoreCount) {
                const dataDashUpdated = {
                  ...dataDash,
                  scoreCount: {
                    ...dataDash.scoreCount,
                    [currentTableValue.fieldColumn]: currentTableValue.dvalue,
                  },
                };
                dashDataList[dataDashIndex] = dataDashUpdated;
              }
            }
            setDashboardData(dashDataList);
          }
        } else {
          setDashboardData([...WEIGHTS_RANGE]);
        }
      } catch (error) {
        console.error(error);
        setDashboardData([...WEIGHTS_RANGE]);
        setIsLoadingInternal(false);
      } finally {
        setIsLoadingInternal(false);
      }
    };
    if (groupId && farmId) fetchData();
    return () => {
      setDashboardData([...WEIGHTS_RANGE]);
    };
  }, [groupId, farmId]);
  return (
    <Spin spinning={isLoadingInternal}>
      <Container>
        <thead>
          <tr>
            <th style={{ textAlign: "right" }} colSpan={11}>
              <span className="header">{`Dados referentes à ${moment().format(
                "DD/MM/YYYY"
              )}`}</span>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={handleRefresh}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </th>
          </tr>
          <tr>
            <th colSpan={2}>Peso Vivo(Kg)</th>
            <th colSpan={9}>
              <h2>Peso Vivo X Score</h2>
            </th>
          </tr>
        </thead>
        <tbody>{renderTableData()}</tbody>
        <tfoot>
          <tr>
            <td colSpan={2}>Score</td>
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>4</td>
            <td>5</td>
            <td>6</td>
            <td>7</td>
            <td>8</td>
            <td>9</td>
          </tr>
        </tfoot>
      </Container>
    </Spin>
  );
}

export default memo(AnimalWeightScoreDash);
