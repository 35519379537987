import React from "react";
import InputMask from "react-input-mask";
import { Input } from "antd";

// import { Container } from './styles';

const ZipCodeInput = ({ disabled, ...props }) => {
  return (
    <InputMask {...props} mask="99999-999" maskChar={null} disabled={disabled}>
      {(inputProps) => <Input {...inputProps} disabled={disabled} />}
    </InputMask>
  );
};

export default ZipCodeInput;
