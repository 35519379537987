import React from 'react';

// import { Container } from './styles';

const ArrowActiveIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.651"
    height="21.651"
    viewBox="0 0 21.651 21.651"
  >
    <circle
      id="Ellipse_111"
      data-name="Ellipse 111"
      cx="10.826"
      cy="10.826"
      r="10.826"
      fill="#684e94"
    />
    <path
      id="Union_45"
      data-name="Union 45"
      d="M7043.7-3433.793l-.26.237.26-.237L7038-3439l5.7,5.206,5.7-5.206-5.7,5.206.26.237Z"
      transform="translate(-7032.48 3447.5)"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
    />
  </svg>
);
export default ArrowActiveIcon;
