import apiAnimals from "../config/api_animals";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiAnimals = axios.create({
//   baseURL: "http://localhost:8084",
// });

export function getAnimalsCoverage(payload) {
  const { groupId, farmId, search, page, size, sort, signal } = payload;

  const orderS =
    Object.entries(sort).length > 0 && sort.order
      ? sort.order === "descend"
        ? "DESC"
        : sort.order === "ascend"
        ? "ASC"
        : "DESC"
      : "DESC";
  const requestParams = `page=${page > 0 ? page - 1 : 0}&size=${size ?? 10}${
    sort && Object.entries(sort).length > 0 && sort.order
      ? `&sort=${sort.field}&direction=${orderS}`
      : ""
  }${search && search !== "" ? `&search=${search}` : ""}`;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/coverages?${requestParams}`,
    getHeaders(false, signal)
  );
}

export function getAnimalsCoverageByAnimalId(payload) {
  const { groupId, farmId, animalId, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/${animalId}/coverages`,
    getHeaders(false, signal)
  );
}

export function saveAnimalsCoverage(payload) {
  const { groupId, farmId, body, signal } = payload;

  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/animals/coverages`,
    body,
    getHeaders(false, signal)
  );
}

export function updateAnimalsCoverage(payload) {
  const { groupId, farmId, id, body, signal } = payload;

  return apiAnimals.put(
    `/bovexo/${groupId}/farms/${farmId}/animals/coverages/${id}`,
    body,
    getHeaders(false, signal)
  );
}

export function deleteAnimalsCoverage(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiAnimals.delete(
    `/bovexo/${groupId}/farms/${farmId}/animals/coverages/${id}`,
    getHeaders(false, signal)
  );
}
