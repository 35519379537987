import React, { useState, useEffect, useCallback } from "react";
/** Redux libs */
import { useSelector } from "react-redux";
/** Components */
import { Container, Footer, Body } from "./styles";
import { Row, Col, Input, Tooltip, Icon, Select, Switch, Checkbox } from "antd";
import ButtonStandard from "../../../components/utils/button";
import NumberFormat from "react-number-format";
import BoxText from "../../../components/utils/boxText";
import InfoIcon from "../../../components/utils/icons/info";

const EditAlerts = ({ data, onSave, onCancel }) => {
  const [param6001, setParam6001] = useState(null);
  const [param6002, setParam6002] = useState(null);
  const [param6003, setParam6003] = useState(null);
  const [param6004, setParam6004] = useState(null);
  const [param6005, setParam6005] = useState(null);
  const [param6006, setParam6006] = useState(null);
  const [param6007, setParam6007] = useState(null);
  const [param6008, setParam6008] = useState(null);
  const [param6009, setParam6009] = useState(null);
  const [param6010, setParam6010] = useState(null);
  const [param6011, setParam6011] = useState(null);
  const [param6012, setParam6012] = useState(null);
  const [param6013, setParam6013] = useState(null);
  const [param6014, setParam6014] = useState(null);
  const [param6015, setParam6015] = useState(null);
  const [param6016, setParam6016] = useState(null);
  const [param6017, setParam6017] = useState(null);
  const [param6018, setParam6018] = useState(null);
  const [param6019, setParam6019] = useState(null);
  const [param6020, setParam6020] = useState(null);
  const [param6021, setParam6021] = useState(null);
  const [param6022, setParam6022] = useState(null);

  const { translation } = useSelector((state) => state.app);

  useEffect(() => {
    setParam6001(data.find((x) => x.code === 6001));
    setParam6002(data.find((x) => x.code === 6002));
    setParam6003(data.find((x) => x.code === 6003));
    setParam6004(data.find((x) => x.code === 6004));
    setParam6005(data.find((x) => x.code === 6005));
    setParam6006(data.find((x) => x.code === 6006));
    setParam6007(data.find((x) => x.code === 6007));
    setParam6008(data.find((x) => x.code === 6008));
    setParam6009(data.find((x) => x.code === 6009));
    setParam6010(data.find((x) => x.code === 6010));
    setParam6011(data.find((x) => x.code === 6011));
    setParam6012(data.find((x) => x.code === 6012));
    setParam6013(data.find((x) => x.code === 6013));
    setParam6014(data.find((x) => x.code === 6014));
    setParam6015(data.find((x) => x.code === 6015));
    setParam6016(data.find((x) => x.code === 6016));
    setParam6017(data.find((x) => x.code === 6017));
    setParam6018(data.find((x) => x.code === 6018));
    setParam6019(data.find((x) => x.code === 6019));
    setParam6020(data.find((x) => x.code === 6020));
    setParam6021(data.find((x) => x.code === 6021));
    setParam6022(data.find((x) => x.code === 6022));
  }, [data]);

  const selectParam6014 = (
    <Select
      value={param6014?.valueExtra ? param6014?.valueExtra : "Hours"}
      style={{ width: 90 }}
      onChange={(value) => {
        setParam6014({
          ...param6014,
          valueExtra: value,
          updated: true,
        });
      }}
    >
      <Select.Option value="Hours">horas</Select.Option>
      <Select.Option value="Days">dias</Select.Option>
    </Select>
  );

  const tooltip6014 = {
    text1: (
      <p>
        1. O tempo determinado só é válido após primeira pesagem; <br />
        2. O animal deve passar na balança destinada a ele, ou seja, no piquete
        / baia que o lote do mesmo esta alocado.
      </p>
    ),
  };

  // Methods

  const handleParam6020Change = useCallback((value, valueExtra) => {
    setParam6020((old) => ({
      ...old,
      updated: true,
      items: old.items.find((x) => x.value === value)
        ? old.items.map((x) => {
            if (x.value === value) {
              x.valueExtra = valueExtra;
              x.status = "Active";
            }
            return x;
          })
        : [
            ...old.items,
            {
              id: null,
              status: "Active",
              value,
              valueExtra,
            },
          ],
    }));
    setParam6020((old) => ({
      ...old,
      status: old.items.every(
        (x) => x.valueExtra === null || x.valueExtra === "0"
      )
        ? "Inactive"
        : "Active",
    }));
  }, []);

  // Function created to handle parameter list status activation
  function handleParam6005Status() {
    const activeItems = param6005.items.filter((p) => p.status === "Active");

    const status = activeItems.length > 0 ? "Active" : "Inactive";
    setParam6005({
      ...param6005,
      status,
    });
  }

  // Function submitForm
  function submitForm() {
    let form = [
      param6001,
      param6002,
      param6003,
      param6004,
      param6005,
      param6006,
      param6007,
      param6008,
      param6009,
      param6010,
      param6011,
      param6012,
      param6013,
      param6014,
      param6015,
      param6016,
      param6017,
      param6018,
      param6019,
      param6020,
      param6021,
      param6022,
    ];
    onSave(form);
  }

  return (
    <Container>
      {/* Body */}
      <Body type="flex">
        <Row type="flex" align="middle" style={{ width: "100%" }}>
          <Col span={22}>
            <Row type="flex">
              <span>
                Alertar caso GDP de<strong>Animal</strong> seja
                <strong>inferior</strong> ao GDP da Estratégia de Dieta em:
              </span>
            </Row>
            <Row type="flex" justify="center">
              <NumberFormat
                style={{ width: "100px", marginTop: "5px" }}
                customInput={Input}
                name="param6001value"
                value={param6001?.value}
                addonAfter="%"
                onValueChange={({ floatValue }) => {
                  if (floatValue + "" !== param6001.value) {
                    setParam6001({
                      ...param6001,
                      value: floatValue == null ? 0 : floatValue,
                      updated: true,
                    });
                  }
                }}
              />
            </Row>
          </Col>
          <Col span={2}>
            <Row style={{ textAlign: "right" }}>
              <Switch
                checked={param6001?.status === "Active"}
                onChange={(checked, event) => {
                  setParam6001({
                    ...param6001,
                    status: checked ? "Active" : "Inactive",
                    updated: true,
                  });
                }}
              />
            </Row>
          </Col>
        </Row>
      </Body>
      <Body type="flex" style={{ backgroundColor: "#e5e5e5" }}>
        <Row type="flex" align="middle" style={{ width: "100%" }}>
          <Col span={22}>
            <Row type="flex">
              <span>
                Alertar caso GDP de<strong>Lote</strong> seja
                <strong>inferior</strong> ao GDP da Estratégia de Dieta em:
              </span>
            </Row>
            <Row type="flex" justify="center">
              <NumberFormat
                style={{ width: "100px", marginTop: "5px" }}
                customInput={Input}
                name="param6002value"
                value={param6002?.value}
                addonAfter="%"
                onValueChange={({ floatValue }) => {
                  if (floatValue + "" !== param6002.value) {
                    setParam6002({
                      ...param6002,
                      value: floatValue == null ? 0 : floatValue,
                      updated: true,
                    });
                  }
                }}
              />
            </Row>
          </Col>
          <Col span={2}>
            <Row style={{ textAlign: "right" }}>
              <Switch
                checked={param6002?.status === "Active"}
                onChange={(checked, event) => {
                  setParam6002({
                    ...param6002,
                    status: checked ? "Active" : "Inactive",
                    updated: true,
                  });
                }}
              />
            </Row>
          </Col>
        </Row>
      </Body>
      <Body type="flex">
        <Row type="flex" align="middle" style={{ width: "100%" }}>
          <Col span={22}>
            <Row type="flex">
              <span>
                Alertar caso GDP de<strong>Animal</strong> seja
                <strong>superior</strong> ao GDP da Estratégia de Dieta em:
              </span>
            </Row>
            <Row type="flex" justify="center">
              <NumberFormat
                style={{ width: "100px", marginTop: "5px" }}
                customInput={Input}
                name="param6003value"
                value={param6003?.value}
                addonAfter="%"
                onValueChange={({ floatValue }) => {
                  if (floatValue + "" !== param6003.value) {
                    setParam6003({
                      ...param6003,
                      value: floatValue == null ? 0 : floatValue,
                      updated: true,
                    });
                  }
                }}
              />
            </Row>
          </Col>
          <Col span={2}>
            <Row style={{ textAlign: "right" }}>
              <Switch
                checked={param6003?.status === "Active"}
                onChange={(checked, event) => {
                  setParam6003({
                    ...param6003,
                    status: checked ? "Active" : "Inactive",
                    updated: true,
                  });
                }}
              />
            </Row>
          </Col>
        </Row>
      </Body>
      <Body type="flex" style={{ backgroundColor: "#e5e5e5" }}>
        <Row type="flex" align="middle" style={{ width: "100%" }}>
          <Col span={22}>
            <Row type="flex">
              <span>
                Alertar caso GDP de<strong>Lote</strong> seja
                <strong>superior</strong> ao GDP da Estratégia de Dieta em:
              </span>
            </Row>
            <Row type="flex" justify="center">
              <NumberFormat
                style={{ width: "100px", marginTop: "5px" }}
                customInput={Input}
                name="param6004value"
                value={param6004?.value}
                addonAfter="%"
                onValueChange={({ floatValue }) => {
                  if (floatValue + "" !== param6004.value) {
                    setParam6004({
                      ...param6004,
                      value: floatValue == null ? 0 : floatValue,
                      updated: true,
                    });
                  }
                }}
              />
            </Row>
          </Col>
          <Col span={2}>
            <Row style={{ textAlign: "right" }}>
              <Switch
                checked={param6004?.status === "Active"}
                onChange={(checked, event) => {
                  setParam6004({
                    ...param6004,
                    status: checked ? "Active" : "Inactive",
                    updated: true,
                  });
                }}
              />
            </Row>
          </Col>
        </Row>
      </Body>
      <Body type="flex">
        <Row type="flex" align="middle" style={{ width: "100%" }}>
          <Col span={22}>
            <Row type="flex">
              <span>
                Alertar vencimento de Cenários de Venda selecionados como
                Favoritos
              </span>
            </Row>
            <Row type="flex" justify="center">
              <Col>
                <Row type="flex">
                  <Checkbox
                    style={{ marginRight: "5px" }}
                    checked={
                      param6005?.items.find((item) => item.value === "30")
                        .status === "Active"
                    }
                    onChange={(e) => {
                      if (param6005 != null) {
                        param6005.items.find(
                          (item) => item.value === "30"
                        ).status = e.target.checked ? "Active" : "Inactive";
                        param6005.updated = true;
                        setParam6005({ ...param6005 });
                        handleParam6005Status();
                      }
                    }}
                  />
                  Faltando 30 dias
                </Row>
                <Row type="flex">
                  <Checkbox
                    style={{ marginRight: "5px" }}
                    checked={
                      param6005?.items.find((item) => item.value === "14")
                        .status === "Active"
                    }
                    onChange={(e) => {
                      if (param6005 != null) {
                        param6005.items.find(
                          (item) => item.value === "14"
                        ).status = e.target.checked ? "Active" : "Inactive";
                        param6005.updated = true;
                        setParam6005({ ...param6005 });
                        handleParam6005Status();
                      }
                    }}
                  />
                  Faltando 14 dias
                </Row>
                <Row type="flex">
                  <Checkbox
                    style={{ marginRight: "5px" }}
                    checked={
                      param6005?.items.find((item) => item.value === "7")
                        .status === "Active"
                    }
                    onChange={(e) => {
                      if (param6005 != null) {
                        param6005.items.find(
                          (item) => item.value === "7"
                        ).status = e.target.checked ? "Active" : "Inactive";
                        param6005.updated = true;
                        setParam6005({ ...param6005 });
                        handleParam6005Status();
                      }
                    }}
                  />
                  Faltando 7 dias
                </Row>
                <Row type="flex">
                  <Checkbox
                    style={{ marginRight: "5px" }}
                    checked={
                      param6005?.items.find((item) => item.value === "0")
                        .status === "Active"
                    }
                    onChange={(e) => {
                      if (param6005 != null) {
                        param6005.items.find(
                          (item) => item.value === "0"
                        ).status = e.target.checked ? "Active" : "Inactive";
                        param6005.updated = true;
                        setParam6005({ ...param6005 });
                        handleParam6005Status();
                      }
                    }}
                  />
                  Ao vencer o cenário
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={2}>
            <Row style={{ textAlign: "right" }}>
              <Switch
                disabled={true}
                checked={param6005?.items.some(
                  (item) => item.status === "Active"
                )}
              />
            </Row>
          </Col>
        </Row>
      </Body>
      <Body type="flex" style={{ backgroundColor: "#e5e5e5" }}>
        <Row type="flex" align="middle" style={{ width: "100%" }}>
          <Col span={22}>
            <span>
              Alertar caso valor da Lotação for inferior à Meta de Lotação
              definida para a Safra
            </span>
          </Col>
          <Col span={2}>
            <Row style={{ textAlign: "right" }}>
              <Switch
                checked={param6006?.status === "Active"}
                onChange={(checked, event) => {
                  setParam6006({
                    ...param6006,
                    status: checked ? "Active" : "Inactive",
                    updated: true,
                  });
                }}
              />
            </Row>
          </Col>
        </Row>
      </Body>
      <Body type="flex">
        <Row type="flex" align="middle" style={{ width: "100%" }}>
          <Col span={22}>
            <span>
              Alertar caso custo da "@" da Estratégia de Dieta esteja superior à
              Meta para Custo definida para a Safra
            </span>
          </Col>
          <Col span={2}>
            <Row style={{ textAlign: "right" }}>
              <Switch
                checked={param6007?.status === "Active"}
                onChange={(checked, event) => {
                  setParam6007({
                    ...param6007,
                    status: checked ? "Active" : "Inactive",
                    updated: true,
                  });
                }}
              />
            </Row>
          </Col>
        </Row>
      </Body>
      <Body type="flex" style={{ backgroundColor: "#e5e5e5" }}>
        <Row type="flex" align="middle" style={{ width: "100%" }}>
          <Col span={22}>
            <span>
              Alertar caso animal estiver abaixo da média estimada para o Lote -
              Animal de Fundo
            </span>
          </Col>
          <Col span={2}>
            <Row style={{ textAlign: "right" }}>
              <Switch
                checked={param6008?.status === "Active"}
                onChange={(checked, event) => {
                  setParam6008({
                    ...param6008,
                    status: checked ? "Active" : "Inactive",
                    updated: true,
                  });
                }}
              />
            </Row>
          </Col>
        </Row>
      </Body>
      <Body type="flex">
        <Row type="flex" align="middle" style={{ width: "100%" }}>
          <Col span={22}>
            <span>
              Alertar caso custo da "@" de um Lote esteja superior à Meta para
              Custo definida para a Safra
            </span>
          </Col>
          <Col span={2}>
            <Row style={{ textAlign: "right" }}>
              <Switch
                checked={param6009?.status === "Active"}
                onChange={(checked, event) => {
                  setParam6009({
                    ...param6009,
                    status: checked ? "Active" : "Inactive",
                    updated: true,
                  });
                }}
              />
            </Row>
          </Col>
        </Row>
      </Body>
      <Body type="flex" style={{ backgroundColor: "#e5e5e5" }}>
        <Row type="flex" align="middle" style={{ width: "100%" }}>
          <Col span={22}>
            <span>
              Alertar caso Estrutura do Piquete / Baia não for compatível com a
              Dieta estabelecida para o Lote alocado
            </span>
          </Col>
          <Col span={2}>
            <Row style={{ textAlign: "right" }}>
              <Switch
                checked={param6011?.status === "Active"}
                onChange={(checked, event) => {
                  setParam6011({
                    ...param6011,
                    status: checked ? "Active" : "Inactive",
                    updated: true,
                  });
                }}
              />
            </Row>
          </Col>
        </Row>
      </Body>
      <Body type="flex">
        <Row type="flex" align="middle" style={{ width: "100%" }}>
          <Col span={22}>
            <span>
              Alertar caso Animal passe em balança não destinada a ele
            </span>
          </Col>
          <Col span={2}>
            <Row style={{ textAlign: "right" }}>
              <Switch
                checked={param6013?.status === "Active"}
                onChange={(checked, event) => {
                  setParam6013({
                    ...param6013,
                    status: checked ? "Active" : "Inactive",
                    updated: true,
                  });
                }}
              />
            </Row>
          </Col>
        </Row>
      </Body>
      <Body type="flex" style={{ backgroundColor: "#e5e5e5" }}>
        <Row type="flex" align="middle" style={{ width: "100%" }}>
          <Col span={22}>
            <Row type="flex">
              <Col span={23}>
                <span>
                  Alertar caso não haja recorrência de pesagem de animal em:
                </span>
              </Col>
              <Col span={1} style={{ textAlign: "right" }}>
                <Tooltip title={tooltip6014.text1}>
                  <InfoIcon />
                </Tooltip>
                <Tooltip title="Necessário efetuar cadastro de balança">
                  <Icon type="exclamation-circle" className="iconError" />
                </Tooltip>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <NumberFormat
                style={{ width: "140px", marginTop: "5px" }}
                customInput={Input}
                name="param6014value"
                value={param6014?.value}
                addonAfter={selectParam6014}
                onValueChange={({ floatValue }) => {
                  if (floatValue + "" !== param6014.value) {
                    setParam6014({
                      ...param6014,
                      value: floatValue == null ? 0 : floatValue,
                      updated: true,
                    });
                  }
                }}
              />
            </Row>
          </Col>
          <Col span={2}>
            <Row style={{ textAlign: "right" }}>
              <Switch
                checked={param6014?.status === "Active"}
                onChange={(checked, event) => {
                  setParam6014({
                    ...param6014,
                    status: checked ? "Active" : "Inactive",
                    updated: true,
                  });
                }}
              />
            </Row>
          </Col>
        </Row>
      </Body>
      <Body type="flex">
        <Row type="flex" align="middle" style={{ width: "100%" }}>
          <Col span={22}>
            <Row type="flex">
              <span>
                Alertar caso{" "}
                <strong>
                  Valor Atualizado da "@" varie para mais ou para menos
                </strong>{" "}
                em relação ao valor estabelecido nas Estratégias de dieta
                Favoritas
              </span>
            </Row>
            <Row type="flex" justify="center">
              <NumberFormat
                style={{ width: "100px", marginTop: "5px" }}
                customInput={Input}
                name="param6015value"
                value={param6015?.value}
                addonAfter="%"
                onValueChange={({ floatValue }) => {
                  if (floatValue + "" !== param6015.value) {
                    setParam6015({
                      ...param6015,
                      value: floatValue == null ? 0 : floatValue,
                      updated: true,
                    });
                  }
                }}
              />
            </Row>
          </Col>
          <Col span={2}>
            <Row style={{ textAlign: "right" }}>
              <Switch
                checked={param6015?.status === "Active"}
                onChange={(checked, event) => {
                  setParam6015({
                    ...param6015,
                    status: checked ? "Active" : "Inactive",
                    updated: true,
                  });
                }}
              />
            </Row>
          </Col>
        </Row>
      </Body>
      <Body type="flex" style={{ backgroundColor: "#e5e5e5" }}>
        <Row type="flex" align="middle" style={{ width: "100%" }}>
          <Col span={22}>
            <Row type="flex">
              <Col span={23}>
                <span>
                  Alertar caso exista a possibilidade do valor do consumo
                  hídrico do animal estar fora da faixa destinada para sua idade
                  e raça
                </span>
              </Col>
              <Col span={1} style={{ textAlign: "right" }}>
                <Tooltip title="Necessário efetuar cadastro de balança">
                  <Icon type="exclamation-circle" className="iconError" />
                </Tooltip>
              </Col>
            </Row>
            <Row type="flex" style={{ marginTop: 5 }}>
              <BoxText
                color="#FE8D2A"
                iconComponent={
                  <Icon type="message" style={{ color: "#FE8D2A" }} />
                }
              >
                <span>
                  Procure a raça na lista, clique no botão de ações e acesse
                  Parâmetros para definir a faixa de valor
                </span>
              </BoxText>
            </Row>
            <Row type="flex">
              <a
                className="text-purple"
                href="/admin/parameters?tabSelect=2"
                target="_blank"
                style={{ width: "100%", textAlign: "right", marginTop: 5 }}
              >
                ACESSAR LISTA de RAÇAS
              </a>
            </Row>
          </Col>
          <Col span={2}>
            <Row style={{ textAlign: "right" }}>
              <Switch
                checked={param6016?.status === "Active"}
                onChange={(checked, event) => {
                  setParam6016({
                    ...param6016,
                    status: checked ? "Active" : "Inactive",
                    updated: true,
                  });
                }}
              />
            </Row>
          </Col>
        </Row>
      </Body>
      <Body type="flex">
        <Row type="flex" align="middle" style={{ width: "100%" }}>
          <Col span={22}>
            <Row type="flex">
              <Col span={23}>
                <span>
                  Alertar caso valor da Permanência for superior à Meta de Ciclo
                  Médio definida para cada lote
                </span>
              </Col>
              <Col span={1} style={{ textAlign: "right" }}>
                <Tooltip title="Permanência é definida pelo tempo total que o animal esta na fazenda">
                  <InfoIcon />
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col span={2}>
            <Row style={{ textAlign: "right" }}>
              <Switch
                checked={
                  param6017?.items?.filter((item) => item.status === "Active")
                    .length > 0
                    ? true
                    : false
                }
                onChange={(checked, event) => {
                  if (param6017 != null) {
                    param6017.items.forEach(
                      (item) => (item.status = checked ? "Active" : "Inactive")
                    );
                    param6017.updated = true;
                    param6017.status = checked ? "Active" : "Inactive";
                    setParam6017({ ...param6017 });
                  }
                }}
              />
            </Row>
          </Col>
        </Row>
      </Body>
      <Body type="flex" style={{ backgroundColor: "#e5e5e5" }}>
        <Row type="flex" align="middle" style={{ width: "100%" }}>
          <Col span={22}>
            <Row type="flex">
              <Col span={24}>
                <span>
                  Alertar caso existam piquetes com a capacidade atual da
                  pastagem inferior a:
                </span>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <NumberFormat
                style={{ width: "140px", marginTop: "5px" }}
                customInput={Input}
                name="param6018value"
                value={param6018?.value}
                addonAfter="%"
                onValueChange={({ floatValue }) => {
                  if (floatValue + "" !== param6018?.value) {
                    setParam6018({
                      ...param6018,
                      value: floatValue == null ? 0 : floatValue,
                      updated: true,
                    });
                  }
                }}
              />
            </Row>
          </Col>
          <Col span={2}>
            <Row style={{ textAlign: "right" }}>
              <Switch
                checked={param6018?.status === "Active"}
                onChange={(checked, event) => {
                  setParam6018({
                    ...param6018,
                    status: checked ? "Active" : "Inactive",
                    updated: true,
                  });
                }}
              />
            </Row>
          </Col>
        </Row>
      </Body>
      <Body type="flex">
        <Row type="flex" align="middle" style={{ width: "100%" }}>
          <Col span={22}>
            <Row type="flex">
              <Col span={24}>
                <span>
                  Alertar caso existam piquetes com a capacidade atual da
                  pastagem em 100 % há:
                </span>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <NumberFormat
                style={{ width: "140px", marginTop: "5px" }}
                customInput={Input}
                name="param6019value"
                value={param6019?.value}
                addonAfter="dias"
                onValueChange={({ floatValue }) => {
                  if (floatValue + "" !== param6019?.value) {
                    setParam6019({
                      ...param6019,
                      value: floatValue == null ? 0 : floatValue,
                      updated: true,
                    });
                  }
                }}
              />
            </Row>
          </Col>
          <Col span={2}>
            <Row style={{ textAlign: "right" }}>
              <Switch
                checked={param6019?.status === "Active"}
                onChange={(checked, event) => {
                  setParam6019({
                    ...param6019,
                    status: checked ? "Active" : "Inactive",
                    updated: true,
                  });
                }}
              />
            </Row>
          </Col>
        </Row>
      </Body>

      {/* ALERTA - 6020 */}
      <Body type="flex">
        <Row type="flex" align="middle" style={{ width: "100%" }}>
          <Col span={22}>
            <Row type="flex">
              <Col span={24}>
                <span>
                  Alertar caso existam lotes com Pegada Carbônica superior a
                  média das atividades abaixo:
                </span>
              </Col>
            </Row>
            {/* RecreateFattenPasture */}
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ marginTop: 4, marginBottom: 4 }}
            >
              <strong>{translation.costType.RecreateFattenPasture}</strong>
              <NumberFormat
                style={{ width: "250px", marginLeft: 8 }}
                customInput={Input}
                name={`param6020_RecreateFattenPasturevalue`}
                value={
                  Number(
                    param6020?.items.find(
                      (item) => item.value === "RecreateFattenPasture"
                    )?.valueExtra
                  ) || undefined
                }
                placeholder={translation.defaultPlaceholder}
                addonAfter={
                  <span>
                    kg CO<sub>2</sub>E / kg GDP
                  </span>
                }
                decimalScale={2}
                decimalSeparator=","
                thousandSeparator="."
                fixedDecimalScale={true}
                onValueChange={({ floatValue }) => {
                  handleParam6020Change(
                    "RecreateFattenPasture",
                    floatValue || null
                  );
                }}
              />
            </Row>
            {/* RecreateFattenSemiConfinement */}
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ marginTop: 4, marginBottom: 4 }}
            >
              <strong>
                {translation.costType.RecreateFattenSemiConfinement}
              </strong>
              <NumberFormat
                style={{ width: "250px", marginLeft: 8 }}
                customInput={Input}
                name={`param6020_RecreateFattenSemiConfinementvalue`}
                value={
                  Number(
                    param6020?.items.find(
                      (item) => item.value === "RecreateFattenSemiConfinement"
                    )?.valueExtra
                  ) || undefined
                }
                placeholder={translation.defaultPlaceholder}
                addonAfter={
                  <span>
                    kg CO<sub>2</sub>E / kg GDP
                  </span>
                }
                decimalScale={2}
                decimalSeparator=","
                thousandSeparator="."
                fixedDecimalScale={true}
                onValueChange={({ floatValue }) => {
                  handleParam6020Change(
                    "RecreateFattenSemiConfinement",
                    floatValue || null
                  );
                }}
              />
            </Row>
            {/* RecreateFattenConfinement */}
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ marginTop: 4, marginBottom: 4 }}
            >
              <strong>{translation.costType.RecreateFattenConfinement}</strong>
              <NumberFormat
                style={{ width: "250px", marginLeft: 8 }}
                customInput={Input}
                name={`param6020_RecreateFattenConfinementvalue`}
                value={
                  Number(
                    param6020?.items.find(
                      (item) => item.value === "RecreateFattenConfinement"
                    )?.valueExtra
                  ) || undefined
                }
                placeholder={translation.defaultPlaceholder}
                addonAfter={
                  <span>
                    kg CO<sub>2</sub>E / kg GDP
                  </span>
                }
                decimalScale={2}
                decimalSeparator=","
                thousandSeparator="."
                fixedDecimalScale={true}
                onValueChange={({ floatValue }) => {
                  handleParam6020Change(
                    "RecreateFattenConfinement",
                    floatValue || null
                  );
                }}
              />
            </Row>
          </Col>
          <Col span={2}>
            <Row style={{ textAlign: "right" }}>
              <Switch
                checked={param6020?.items.some(
                  (item) => item.valueExtra !== null && item.valueExtra !== "0"
                )}
                disabled
              />
            </Row>
          </Col>
        </Row>
      </Body>

      {/* ALERTA - 6021 */}
      <Body type="flex" style={{ backgroundColor: "#e5e5e5" }}>
        <Row type="flex" align="middle" style={{ width: "100%" }}>
          <Col span={22}>
            <span>
              Alertar caso existam piquetes com altura do pasto <strong>inferior</strong> à altura de <strong>saída</strong>.
            </span>
          </Col>
          <Col span={2}>
            <Row style={{ textAlign: "right" }}>
              <Switch
                checked={param6021?.status === "Active"}
                onChange={(checked, event) => {
                  setParam6021({
                    ...param6021,
                    status: checked ? "Active" : "Inactive",
                    updated: true,
                  });
                }}
              />
            </Row>
          </Col>
        </Row>
      </Body>

      {/* ALERTA - 6022 */}
      <Body type="flex" style={{ backgroundColor: "#e5e5e5" }}>
        <Row type="flex" align="middle" style={{ width: "100%" }}>
          <Col span={22}>
            <span>
              Alertar caso existam piquetes com altura do pasto <strong>superior</strong> à altura de <strong>entrada</strong>.
            </span>
          </Col>
          <Col span={2}>
            <Row style={{ textAlign: "right" }}>
              <Switch
                checked={param6022?.status === "Active"}
                onChange={(checked, event) => {
                  setParam6022({
                    ...param6022,
                    status: checked ? "Active" : "Inactive",
                    updated: true,
                  });
                }}
              />
            </Row>
          </Col>
        </Row>
      </Body>

      {/* Footer */}
      <Footer>
        <Row type="flex" justify="space-between">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="selectDiv"
          ></Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="buttonsDiv">
            <ButtonStandard
              type="button"
              buttonType="typeWithoutBackground"
              color="#d44c4c"
              isUpperCase={true}
              onClick={onCancel}
            >
              {translation.buttons.cancel}
            </ButtonStandard>

            <ButtonStandard
              type="submit"
              buttonType="typeWithoutBackground"
              color="#684E94"
              isUpperCase={true}
              onClick={submitForm}
            >
              {translation.buttons.save}
            </ButtonStandard>
          </Col>
        </Row>
      </Footer>
    </Container>
  );
};

export default EditAlerts;
