import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Creators as ClientActions } from "../../store/ducks/client";
// Components
import {
  Icon,
  Menu,
  Row,
  Col,
  Table,
  Dropdown,
  notification,
  Button,
  Input,
} from "antd";
import { Container, PageTitle } from "./styles";
import TagStatus from "../../components/utils/tagStatus";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import MenuIcon from "../../components/utils/table/icons/menu";
import ButtonStandard from "../../components/utils/button";
import DrawerClient from "../../components/drawers/client";
import DrawerClientDetails from "../../components/drawers/client/details";

// Services
import {
  inactivateClient,
  pageAllClientsByGroupId,
  reactivateClient,
} from "../../services/managementService";
import { cnpjMask, cpfMask } from "../../services/helpersMethodsService";

const Client = () => {
  // Variable
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Variable Redux
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { refreshTable } = useSelector((state) => state.client);
  const dispatch = useDispatch();
  // Effect
  useEffect(() => {
    async function fetchData(page = 0, sorter = {}, filters = "", size = 10) {
      try {
        setIsLoading(true);
        const {
          data: { results },
        } = await pageAllClientsByGroupId({
          groupId,
          farmId,
          withoutPagination: true,
        });
        setIsLoading(false);
        setData(results);
      } catch (error) {
        notification.error({
          message: "Não foi possível retornar os clientes",
        });
        setIsLoading(false);
        setData(null);
      }
    }
    fetchData();
  }, [groupId, farmId]);
  useEffect(() => {
    async function fetchData(page = 0, sorter = {}, filters = "", size = 10) {
      try {
        setIsLoading(true);
        const {
          data: { results },
        } = await pageAllClientsByGroupId({
          groupId,
          farmId,
          withoutPagination: true,
        });
        setIsLoading(false);
        setData(results);
      } catch (error) {
        notification.error({
          message: "Não foi possível retornar os clientes",
        });
        setIsLoading(false);
        setData(null);
      } finally {
        dispatch(ClientActions.refreshTable(false));
      }
    }
    if (refreshTable) fetchData();
  }, [groupId, farmId, refreshTable, dispatch]);

  // Methods
  async function fetchData(page = 0, sorter = {}, filters = "", size = 10) {
    try {
      setIsLoading(true);
      const {
        data: { results },
      } = await pageAllClientsByGroupId({
        groupId,
        farmId,
        withoutPagination: true,
      });
      setIsLoading(false);
      setData(results);
    } catch (error) {
      notification.error({
        message: "Não foi possível retornar os clientes",
      });
      setIsLoading(false);
      setData(null);
    }
  }

  function menu(id, record) {
    return (
      <Menu>
        {(record.status === "Inactive" ||
          record.status === "FarmBovexoInactive") && (
          <Menu.Item key="0">
            <button onClick={() => handleReactivate(id)}>
              {translation.table.menu.activate}
            </button>
          </Menu.Item>
        )}
        <Menu.Item key="2">
          <button onClick={() => handleEditOrDetails(id, "details")}>
            {translation.table.menu.details}
          </button>
        </Menu.Item>
        {record != null && record.status === "FarmBovexo" && (
          <Menu.Item key="1">
            <button onClick={() => handleInactivateOrDelete(id, "edit")}>
              {translation.table.menu.inactivate}
            </button>
          </Menu.Item>
        )}
        {record != null && record.status === "Active" && (
          <Menu.Item key="1">
            <button onClick={() => handleEditOrDetails(id, "edit")}>
              {translation.table.menu.edit}
            </button>
          </Menu.Item>
        )}
        {record.status === "Active" && (
          <Menu.Item key="3">
            <button onClick={() => handleInactivateOrDelete(id)}>
              {translation.table.menu.inactivate}
            </button>
          </Menu.Item>
        )}
      </Menu>
    );
  }

  function handleOpenDrawer(action = "new", id = null) {
    dispatch(ClientActions.showDrawerClientVisible(action, id));
  }

  function handleOpenDrawerDetails(id) {
    dispatch(ClientActions.showDrawerClientVisible("details", id));
  }

  function handleEditOrDetails(clientId, action) {
    try {
      if (action === "edit") {
        handleOpenDrawer("edit", clientId);
      } else {
        handleOpenDrawerDetails(clientId);
      }
    } catch (e) {}
  }

  async function handleInactivateOrDelete(id) {
    try {
      setIsLoading(true);
      await inactivateClient({
        id,
        groupId,
        farmId,
      });
      fetchData();
    } catch (error) {
      setIsLoading(false);
    }
  }

  async function handleReactivate(id) {
    try {
      setIsLoading(true);
      await reactivateClient({
        id,
        groupId,
        farmId,
      });
      fetchData();
    } catch (error) {
      setIsLoading(false);
    }
  }

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return "document" === dataIndex
          ? record[dataIndex]
              ?.toString()
              .toLowerCase()
              .includes(
                value
                  .toString()
                  .replaceAll(/[^0-9]/g, "")
                  .toLowerCase()
              )
          : "city" === dataIndex
          ? new String(`${record.city?.name}/${record.city?.state.name}`)
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          : record[dataIndex]
              ?.toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase());
      },
    }),
    [handleReset, handleSearch]
  );

  return (
    <Container>
      <Row type="flex" className="rowHeader" align="middle">
        <Col span={8}>
          <PageTitle>{translation.sidebar.client}</PageTitle>
        </Col>
        <Col span={16} align="right">
          <ButtonStandard
            buttonType="typeWithoutBackground"
            onClick={() => fetchData()}
          >
            <Icon type="reload" />
          </ButtonStandard>
          <ButtonStandard
            buttonType="type8"
            onClick={() => handleOpenDrawer("new", null)}
          >
            Novo cliente
          </ButtonStandard>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <Table
            loading={isLoading}
            rowKey="id"
            dataSource={data != null ? data : []}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 10,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
            }}
            size="small"
            scroll={{
              x: true,
            }}
          >
            <Table.Column
              title={translation.farm.table.columns.status}
              dataIndex="status"
              key="status"
              align="left"
              sorter={(first, second) => {
                return first.status.localeCompare(second.status, "pt-BR", {
                  numeric: false,
                  ignorePunctuation: true,
                });
              }}
              filtered
              filters={[
                {
                  text: translation.status.active,
                  value: "Active",
                },
                {
                  text: translation.status.inactive,
                  value: "Inactive",
                },
              ]}
              filterMultiple={false}
              onFilter={(value, record) => record.status === value}
              render={(status) =>
                status === "Active" ? (
                  <TagStatus
                    background="#E2FFE5"
                    borderColor="#33C162"
                    color="#33C162"
                  >
                    Ativo
                  </TagStatus>
                ) : status === "Inactive" ? (
                  <TagStatus
                    background="#FBC7C7"
                    borderColor="#D44C4C"
                    color="#D44C4C"
                  >
                    Inativo
                  </TagStatus>
                ) : status === "FarmBovexoInactive" ? (
                  <TagStatus
                    background="#FBC7C7"
                    borderColor="#D44C4C"
                    color="#D44C4C"
                  >
                    Bovexo
                  </TagStatus>
                ) : (
                  <TagStatus
                    background="#F6FFC8"
                    borderColor="#A9C133"
                    color="#A9C133"
                  >
                    Bovexo
                  </TagStatus>
                )
              }
            />

            <Table.Column
              title="Cliente"
              dataIndex="name"
              key="name"
              align="left"
              sorter={(first, second) => {
                return first.name.localeCompare(second.name, "pt-BR", {
                  numeric: false,
                  ignorePunctuation: true,
                });
              }}
              {...handleGetColumnSearchProps("name")}
              render={(text, record) => <span>{record.name}</span>}
            />

            <Table.Column
              title="CNPJ/CPF"
              dataIndex="document"
              key="document"
              align="left"
              sorter={(first, second) => {
                if (!first.document) {
                  first.document = "";
                }
                return first.document.localeCompare(second.document, "pt-BR", {
                  numeric: false,
                  ignorePunctuation: true,
                });
              }}
              render={(text, record) => (
                <span>
                  {record.documentType === "CNPJ"
                    ? cnpjMask(record.document)
                    : cpfMask(record.document ? record.document : "")}
                </span>
              )}
              {...handleGetColumnSearchProps("document")}
            />
            <Table.Column
              title="Cidade/UF"
              dataIndex="city"
              key="city"
              align="left"
              sorter={(first, second) => {
                return first.city?.name.localeCompare(
                  second.city?.name,
                  "pt-BR",
                  {
                    numeric: false,
                    ignorePunctuation: true,
                  }
                );
              }}
              {...handleGetColumnSearchProps("city")}
              render={(text, record) => (
                <span>
                  {record.city
                    ? `${record.city?.name}/${record.city?.state.name}`
                    : "-"}
                </span>
              )}
            />
            <Table.Column
              align="left"
              render={(text, record) => (
                <Dropdown
                  overlay={menu(record.id, record)}
                  trigger={["click"]}
                  key={record.id}
                >
                  <MenuContainer>
                    <MenuIcon />
                  </MenuContainer>
                </Dropdown>
              )}
            />
          </Table>
        </Col>
      </Row>
      {/* Drawers */}
      <DrawerClient />
      <DrawerClientDetails />
    </Container>
  );
};

export default withRouter(Client);
