import apiOptimization from "../config/api_optimization";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiOptimization = axios.create({
//   baseURL: "http://localhost:8088",
// });

export function indexBoitelScenario(payload) {
  const { groupId, farmId, page, sorter, filters, size, signal, ids } = payload;
  let sortS = "";
  if (sorter && Object.entries(sorter).length > 0) {
    const { field, order } = sorter;

    const fieldS = field
      ? field === "nextCommitmentDate"
        ? "next_commitment_date"
        : field
      : null;

    const orderS = order && order === "descend" ? "DESC" : "ASC";

    sortS = `&sort=${fieldS}&direction=${orderS}`;
  }

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/boitels/list?
    ${page ? `&page=${page - 1}` : ""}
    ${size && `&size=${size}`}
    ${sortS}
    ${filters !== "" && filters ? `&search=${filters}` : ""}`,
    ids != null ? ids : null,
    getHeaders(false, signal)
  );
}

export function indexDropDownBoitelScenario(payload) {
  const { groupId, farmId, signal } = payload;
  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/boitels/list?withoutPagination=true`,
    null,
    getHeaders(false, signal)
  );
}

export function storeUpdateBoitelScenarioService(payload) {
  const { groupId, farmId, id, body, signal } = payload;

  if (id != null) {
    return apiOptimization.patch(
      `/bovexo/${groupId}/farms/${farmId}/scenarios/boitels/${id}`,
      body,
      getHeaders(false, signal)
    );
  } else {
    return apiOptimization.post(
      `/bovexo/${groupId}/farms/${farmId}/scenarios/boitels`,
      body,
      getHeaders(false, signal)
    );
  }
}

export function showBoitelScenario(payload) {
  const { groupId, farmId, id } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/boitels/${id}`,
    getHeaders(false, null)
  );
}

export function downloadBoitelScenarioReport(payload) {
  const { groupId, farmId, id } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/boitels/${id}/download/pdf`,
    getHeaders(false, null, true)
  );
}

export function contractBoitelScenarioService(payload) {
  const { groupId, farmId, id, signal } = payload;

  if (id != null) {
    return apiOptimization.patch(
      `/bovexo/${groupId}/farms/${farmId}/scenarios/boitels/${id}/contract`,
      null,
      getHeaders(false, signal)
    );
  }
}
