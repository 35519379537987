import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as IntegrationPartnerActions } from "../../../store/ducks/integrationPartners";

// Components
import { Container, Footer } from "./styles";
import { Row, Col, Select, Input, Spin, notification } from "antd";
import ButtonStandard from "../../utils/button";

// Services
import {
  findIntegrationById,
  getDropdownIntegrationPartners,
  saveFarmIntegrationPartner,
  updateFarmIntegrationPartner,
} from "../../../services/integrationPartnersService";

const DrawerIntegrationPartner = () => {
  // Redux Variable
  const {
    translation,
    groupSelected: { id: groupIdSelected },
    farmSelected: { id: farmIdSelected },
  } = useSelector((state) => state.app);

  const { drawerIntegrationPartnerVisible, id } = useSelector(
    (state) => state.integrationPartner
  );

  const dispatch = useDispatch();

  const [form, setForm] = useState({
    id: null,
    groupId: groupIdSelected,
    farmId: farmIdSelected,
    integrationPartner: null,
    authToken: null,
    authUser: null,
    authPassword: null,
    status: "Active",
  });

  const [loadRequest, setLoadRequest] = useState(false);
  const [partnersList, setPartnersList] = useState([]);
  const [partnerIdSelected, setPartnerIdSelected] = useState(null);
  const [errors, setErrors] = useState([]);
  const { Option } = Select;

  // Methods
  function closeDrawer() {
    dispatch(IntegrationPartnerActions.hideDrawerIntegrationVisible());
    setForm({
      id: null,
      groupId: groupIdSelected,
      farmId: farmIdSelected,
      integrationPartner: null,
      authToken: null,
      authUser: null,
      authPassword: null,
      status: "Active",
    });
    setPartnerIdSelected(null);
  }

  function validateForm() {
    let aErrors = [];

    if (!form.integrationPartner) {
      aErrors.push("integrationPartner");
    }

    if (
      form?.integrationPartner?.authentication === "Token" &&
      (form.authToken === null || form.authToken.trim() === "")
    ) {
      aErrors.push("authToken");
    }

    if (
      form.integrationPartner?.authentication === "UserAndPassword" &&
      (form.authUser === null ||
        form.authUser.trim() === "" ||
        form.authPassword === null ||
        form.authPassword.trim() === "")
    ) {
      if (form.authUser === null || form.authUser.trim() === "") {
        aErrors.push("authUser");
      } else {
        aErrors.push("authPassword");
      }
    }

    setErrors(aErrors);
    return aErrors.length > 0 ? false : true;
  }

  async function handleSubmit() {
    if (validateForm()) {
      setLoadRequest(true);
      const body = {
        ...form,
        integrationPartnerId: form.integrationPartner.id,
      };
      try {
        if (body?.id === null) {
          const {
            data: { results },
          } = await saveFarmIntegrationPartner({
            groupId: groupIdSelected,
            farmId: farmIdSelected,
            body: body,
          });

          if (results) {
            notification.success({
              message: "Integração cadastrada com sucesso!",
            });
            closeDrawer();
          }
        } else {
          const {
            data: { results },
          } = await updateFarmIntegrationPartner({
            groupId: groupIdSelected,
            farmId: farmIdSelected,
            id: body?.id,
            body: body,
          });

          if (results) {
            notification.success({
              message: "Dados de integração atualizados com sucesso!",
            });
            closeDrawer();
          }
        }
      } catch {
        notification.error({
          message:
            "Ocorreu um erro ao tentar salvar as informações da integração!",
        });
      } finally {
        setLoadRequest(false);
      }
    }
  }

  async function handleDrawerVisible(visible) {
    if (visible) {
      setLoadRequest(true);
      try {
        //Get partners list
        const {
          data: { results },
        } = await getDropdownIntegrationPartners({
          groupId: groupIdSelected,
          farmId: farmIdSelected,
        });

        setPartnersList(results);
        //If edit find integration data
        if (id != null) {
          const {
            data: { results },
          } = await findIntegrationById({
            groupId: groupIdSelected,
            farmId: farmIdSelected,
            id: id,
          });
          setForm({ ...results, authPassword: null });
          setPartnerIdSelected(results?.integrationPartner?.id);
          setPartnersList(
            partnersList.filter(
              (p) =>
                p?.alreadyUsedByFarm === false ||
                p?.id === results?.integrationPartner?.id
            )
          );
        } else {
          setPartnersList(
            results?.filter((r) => r.alreadyUsedByFarm === false)
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadRequest(false);
      }
    }
  }

  return (
    <Container
      title={form?.id ? "Editar Integração" : "Nova Integração"}
      width={511}
      onClose={closeDrawer}
      maskClosable={false}
      visible={drawerIntegrationPartnerVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <div className="drawerForm">
        <Spin spinning={loadRequest}>
          <Row type="flex">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <span className="subTitle">
                Preencha os campos abaixo para cadastrar uma integração
              </span>
            </Col>
          </Row>
          {errors.length > 0 && (
            <Row type="flex" justify="center" align="middle">
              <label className="error">{translation.error.formError}</label>
            </Row>
          )}
          {/* IntegrationPartner */}
          <Row type="flex" justify="start" gutter={4}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row className="rowLabel">
                <label
                  className={
                    errors.includes("integrationPartner") ? "error" : ""
                  }
                >
                  Sistema Parceiro*
                </label>
              </Row>
              <Row>
                <Select
                  style={{ width: "100%" }}
                  value={
                    partnerIdSelected != null ? partnerIdSelected : undefined
                  }
                  showSearch
                  placeholder={translation.defaultPlaceholder}
                  name="integrationPartnerId"
                  disabled={!!id}
                  onChange={(value) => {
                    if (value) {
                      setPartnerIdSelected(value);
                      setForm({
                        ...form,
                        integrationPartner: partnersList.find(
                          (p) => p.id === value
                        ),
                      });
                    }
                    setErrors(errors.filter((e) => e !== "integrationPartner"));
                  }}
                >
                  {partnersList && Object.entries(partnersList).length > 0
                    ? partnersList.map((p) => (
                        <Option key={p.id} value={p.id}>
                          {p.partnerSystemName}
                        </Option>
                      ))
                    : null}
                </Select>
              </Row>
            </Col>
          </Row>
          {/* Token */}
          {form?.integrationPartner?.authentication === "Token" ? (
            <Row type="flex" justify="start" gutter={4}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row className="rowLabel">
                  <label
                    className={errors.includes("authToken") ? "error" : ""}
                  >
                    Token*
                  </label>
                </Row>
                <Row>
                  <Input
                    kind="mixed"
                    name="authToken"
                    type="text"
                    value={
                      form?.authToken != null ? form?.authToken : undefined
                    }
                    autoComplete="chrome-off"
                    placeholder={translation.defaultPlaceholder}
                    onChange={(e) => {
                      setForm({ ...form, authToken: e.target.value });
                      setErrors(errors.filter((e) => e !== "authToken"));
                    }}
                  />
                </Row>
              </Col>
            </Row>
          ) : null}
          {/* User and Password */}
          {form?.integrationPartner?.authentication === "UserAndPassword" ? (
            <>
              <Row type="flex" justify="start" gutter={4}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row className="rowLabel">
                    <label
                      className={errors.includes("authUser") ? "error" : ""}
                    >
                      Usuário*
                    </label>
                  </Row>
                  <Row>
                    <Input
                      kind="mixed"
                      name="authUser"
                      type="text"
                      value={
                        form?.authUser != null ? form?.authUser : undefined
                      }
                      autoComplete="chrome-off"
                      placeholder={translation.defaultPlaceholder}
                      onChange={(e) => {
                        setForm({ ...form, authUser: e.target.value });
                        setErrors(errors.filter((e) => e !== "authUser"));
                      }}
                    />
                  </Row>
                </Col>
              </Row>
              <Row type="flex" justify="start" gutter={4}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row className="rowLabel">
                    <label
                      className={errors.includes("authPassword") ? "error" : ""}
                    >
                      Senha*
                    </label>
                  </Row>
                  <Row>
                    <Input
                      name="authPassword"
                      type="password"
                      value={
                        form?.authPassword != null
                          ? form?.authPassword
                          : undefined
                      }
                      autoComplete="chrome-off"
                      placeholder={translation.defaultPlaceholder}
                      onChange={(e) => {
                        setForm({ ...form, authPassword: e.target.value });
                        setErrors(errors.filter((e) => e !== "authPassword"));
                      }}
                    />
                  </Row>
                </Col>
              </Row>
            </>
          ) : null}
        </Spin>
      </div>
      <Footer>
        <Row type="flex">
          <Col span={24} className="buttonsDiv">
            <ButtonStandard
              type="button"
              buttonType="type7"
              onClick={closeDrawer}
            >
              {translation.buttons.cancel}
            </ButtonStandard>

            <ButtonStandard
              type="button"
              buttonType="type6"
              onClick={handleSubmit}
            >
              {translation.buttons.save}
            </ButtonStandard>
          </Col>
        </Row>
      </Footer>
    </Container>
  );
};

export default DrawerIntegrationPartner;
