import React from "react";
import { useHistory } from "react-router-dom";

import { FarmAnimalSellContextProvider } from "../../../../../contexts/farmAnimalSellContext";

// Components
import { Container } from "./styles";
import FormProductionSell from "./form";

const EditFarmProductionSellLayout = () => {

	const history = useHistory();

	const cancelAnimals = () => {
		history.push("/admin/financial");
	}

	return (
		<Container>
			<FormProductionSell onCancel={cancelAnimals} />
		</Container>
	);

};

const EditFarmProductionSell = () => {
	return (
		<FarmAnimalSellContextProvider>
			<EditFarmProductionSellLayout />
		</FarmAnimalSellContextProvider>
	);
};

export default EditFarmProductionSell;