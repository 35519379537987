import { Col, Input, Modal, Row, Select, Spin, Switch } from "antd";
import React, { useCallback, useState } from "react";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import BullAvatarIcon from "../../../../components/drawers/animal/new/icons/bullAvatar";
import { AnimalCard } from "../../../../components/drawers/animal/new/styles";
import {
  verifyIfHaveOtherAnimalsWithSameHandlingNumber,
  verifyIfHaveOtherAnimalsWithSameSisbov,
} from "../../../../services/animalService";

const AnimalBirthChildForm = ({
  selectedFemale,
  index,
  errors,
  submitCount,
  values,
  setFieldValue,
  lots,
  breeds,
  farmOwners,
}) => {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const [isLoading, setIsLoading] = useState(false);

  const changeFieldValues = useCallback(
    (field, value) => {
      setFieldValue(`childs[${index}][${field}]`, value);
    },
    [index, setFieldValue]
  );

  const checkSisbov = useCallback(
    async (value) => {
      if (value != null && value.length > 0) {
        setIsLoading(true);
        try {
          const {
            data: { results: haveOthersAnimals },
          } = await verifyIfHaveOtherAnimalsWithSameSisbov({
            groupId,
            farmId,
            sisbov: value,
          });
          if (haveOthersAnimals === true) {
            Modal.error({
              width: 700,
              content: (
                <h1>Atenção! Já existe um animal com este Nº SISBOV.</h1>
              ),
              centered: true,
              onOk: () => {
                changeFieldValues("sisbov", null);
              },
            });
          }
        } catch (error) {
        } finally {
          setIsLoading(false);
        }
      }
    },
    [changeFieldValues, farmId, groupId]
  );

  const checkHandlingNumber = useCallback(
    async (value) => {
      if (value != null && value.length > 0) {
        setIsLoading(true);
        try {
          const {
            data: { results: haveOthersAnimals },
          } = await verifyIfHaveOtherAnimalsWithSameHandlingNumber({
            groupId,
            farmId,
            handlingNumber: value,
          });
          if (haveOthersAnimals === true) {
            Modal.error({
              width: 700,
              content: (
                <h1>Atenção! Existe um animal ATIVO com está identificação.</h1>
              ),
              centered: true,
              onOk: () => {
                changeFieldValues("handlingNumber", null);
              },
            });
          }
        } catch (error) {
        } finally {
          setIsLoading(false);
        }
      }
    },
    [changeFieldValues, farmId, groupId]
  );

  const checkTagId = useCallback(
    async (value) => {
      if (value != null && value.length > 0) {
        setIsLoading(true);
        try {
          const {
            data: { results: haveOthersAnimals },
          } = await verifyIfHaveOtherAnimalsWithSameSisbov({
            groupId,
            farmId,
            sisbov: value,
          });
          if (haveOthersAnimals === true) {
            Modal.error({
              width: 700,
              content: (
                <h1>
                  Atenção! Já existe um animal ATIVO, com esta Identificação
                  Eletrônica.
                </h1>
              ),
              centered: true,
              onOk: () => {
                changeFieldValues("tagId", null);
              },
            });
          }
        } catch (error) {
        } finally {
          setIsLoading(false);
        }
      }
    },
    [changeFieldValues, farmId, groupId]
  );

  const handleSelectLot = useCallback(
    (lotId) => {
      if (lotId) {
        changeFieldValues("lotId", lotId);
        if (
          selectedFemale &&
          selectedFemale.lotId &&
          lotId !== selectedFemale.lotId
        ) {
          Modal.confirm({
            width: 700,
            centered: true,
            content: (
              <h1>{`Atenção! A Matriz está alocado no Lote ${selectedFemale.lotName}, movimenta-la para o mesmo lote informado para a sua cria?`}</h1>
            ),
            onOk: () => {
              setFieldValue("shouldMovimentMatrizToNewLot", true);
              setFieldValue("lotMatrizDestinationId", lotId);
            },
          });
        }
      }
    },
    [changeFieldValues, selectedFemale, setFieldValue]
  );

  return (
    <Spin spinning={isLoading}>
      <Row type="flex">
        <Col span={24}>
          <AnimalCard>
            <Row type="flex" justify="start">
              <Col>
                <BullAvatarIcon />
              </Col>
              <Col
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                offset={1}
                className="animalCardCol"
              >
                <Row>
                  <label
                    className={
                      errors?.handlingNumber && submitCount > 0 ? "error" : ""
                    }
                  >
                    {translation.animalBirth.form.child.handlingNumber}*
                  </label>
                </Row>
                <Row className="rowInput">
                  <Input
                    placeholder={
                      translation.animalBirth.form.child
                        .handlingNumberPlaceHolder
                    }
                    value={values?.handlingNumber}
                    name="handlingNumber"
                    onChange={(e) => {
                      changeFieldValues("handlingNumber", e.target.value);
                    }}
                    onBlur={(e) => checkHandlingNumber(e.target.value)}
                  />
                </Row>
              </Col>
              <Col
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                offset={1}
                className="animalCardCol"
              >
                <Row>
                  <label
                    className={errors?.sisbov && submitCount > 0 ? "error" : ""}
                  >
                    {translation.animalBirth.form.child.sisbov}
                  </label>
                </Row>
                <Row className="rowInput">
                  <NumberFormat
                    customInput={Input}
                    placeholder={
                      translation.animalBirth.form.child.sisbovPlaceHolder
                    }
                    maxLength={15}
                    decimalScale={0}
                    allowLeadingZeros={false}
                    isNumericString={true}
                    value={values?.sisbov !== null ? values?.sisbov : null}
                    name="sisbov"
                    onValueChange={({ value }) => {
                      changeFieldValues("sisbov", value);
                    }}
                    onBlur={(e) => checkSisbov(e.target.value)}
                  />
                </Row>
              </Col>
              <Col
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                offset={1}
                className="animalCardCol"
              >
                <Row>
                  <label
                    className={errors?.tagId && submitCount > 0 ? "error" : ""}
                  >
                    {translation.animalBirth.form.child.tagId}
                  </label>
                </Row>
                <Row className="rowInput">
                  <NumberFormat
                    customInput={Input}
                    placeholder={
                      translation.animalBirth.form.child.tagIdPlaceHolder
                    }
                    value={values?.tagId !== null ? values?.tagId : null}
                    decimalScale={0}
                    maxLength={15}
                    allowLeadingZeros={true}
                    isNumericString={true}
                    name="tagId"
                    onValueChange={({ value }) => {
                      changeFieldValues("tagId", value);
                    }}
                    onBlur={(e) => checkTagId(e.target.value)}
                  />
                </Row>
                {errors?.tagId && submitCount > 0 && (
                  <p className="error">
                    Verifique se a identificação eletrônica contêm 15 números
                  </p>
                )}
              </Col>
            </Row>
          </AnimalCard>
        </Col>
      </Row>
      <Row type="flex" gutter={8}>
        <Col span={6}>
          <Row>
            <label>{translation.animalBirth.form.child.nickname}</label>
          </Row>
          <Row>
            <Input
              placeholder={
                translation.animalBirth.form.child.nicknamePlaceHolder
              }
              value={values?.nickname}
              name="nickname"
              onChange={(e) => {
                changeFieldValues("nickname", e.target.value);
              }}
            />
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <label
              className={errors?.breedId && submitCount > 0 ? "error" : ""}
            >
              {translation.animalBirth.form.child.breed}*
            </label>
          </Row>
          <Row>
            <Select
              style={{ width: "100%" }}
              name="breedId"
              placeholder={translation.animalBirth.form.child.breedPlaceHolder}
              value={
                breeds != null && values?.breedId != null
                  ? values?.breedId
                  : undefined
              }
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => {
                changeFieldValues("breedId", value);
              }}
            >
              {breeds != null &&
                Object.entries(breeds).length > 0 &&
                breeds.map((breed) => (
                  <Select.Option key={breed.id} value={breed.id}>
                    {breed.name}
                  </Select.Option>
                ))}
            </Select>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <label className={errors?.gender && submitCount > 0 ? "error" : ""}>
              {translation.animalBirth.form.child.sex} *
            </label>
          </Row>
          <Row>
            <Select
              style={{ width: "100%" }}
              name="gender"
              value={values?.gender != null ? values?.gender : undefined}
              placeholder={translation.defaultSelectPlaceholder}
              onChange={(value) => {
                changeFieldValues("gender", value);
                changeFieldValues(
                  "animalFarmFunction",
                  value === "Female" ? "reproduction" : "fatten"
                );
              }}
            >
              <Select.Option key="F" value="Female">
                {translation.animalBirth.form.child.female}
              </Select.Option>
              <Select.Option key="M" value="Male">
                {translation.animalBirth.form.child.male}
              </Select.Option>
            </Select>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <label
              className={
                errors?.animalFarmFunction && submitCount > 0 ? "error" : ""
              }
            >
              {translation.animalBirth.form.child.animalFarmFunction}*
            </label>
          </Row>
          <Row>
            <Select
              name="animalFarmFunction"
              value={values?.animalFarmFunction || undefined}
              placeholder={translation.defaultSelectPlaceholder}
              onChange={(value) => {
                changeFieldValues("animalFarmFunction", value);
                if (value === "fatten") {
                  changeFieldValues("animalReproductionCategory", null);
                } else if (
                  values?.gender === "Male" &&
                  value === "reproduction"
                ) {
                  changeFieldValues("animalReproductionCategory", "reprodutor");
                }
              }}
            >
              <Select.Option value="reproduction">Reprodução</Select.Option>
              <Select.Option value="fatten">Engorda</Select.Option>
            </Select>
          </Row>
        </Col>
      </Row>
      <Row type="flex" gutter={8}>
        <Col span={6}>
          <Row>
            <label>{translation.animalBirth.form.child.birthdayWeight}</label>
          </Row>
          <Row>
            <NumberFormat
              value={values.birthdayWeight || undefined}
              placeholder={translation.defaultPlaceholder}
              customInput={Input}
              addonAfter="Kg"
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              allowNegative={false}
              onValueChange={({ floatValue }) => {
                changeFieldValues(
                  "birthdayWeight",
                  floatValue ? (floatValue <= 99 ? floatValue : 99) : null
                );
              }}
            />
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <label className={errors?.lotId && submitCount > 0 ? "error" : ""}>
              {translation.animalBirth.form.child.lotId}*
            </label>
          </Row>
          <Row>
            <Select
              name="lotId"
              value={values?.lotId != null ? values?.lotId : undefined}
              placeholder={translation.defaultSelectPlaceholder}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              allowClear={true}
              onChange={(value) => {
                handleSelectLot(value);
              }}
            >
              {lots.length > 0 &&
                lots.map((lot) => (
                  <Select.Option key={lot.id} value={lot.id}>
                    {`${lot.name} / ${
                      lot.currentAmountAnimals
                    } animais / Atividade: ${lot.profitCenterName} ${
                      lot.picketId != null ? `/ Piquete: ${lot.picketName}` : ``
                    }`}
                  </Select.Option>
                ))}
            </Select>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <label
              className={errors?.farmOwnerId && submitCount > 0 ? "error" : ""}
            >
              {translation.animalBirth.form.child.farmOwner}*
            </label>
          </Row>
          <Row>
            <Select
              name="farmOwnerId"
              value={values.farmOwnerId || undefined}
              placeholder={translation.defaultSelectPlaceholder}
              onChange={(value) => changeFieldValues("farmOwnerId", value)}
            >
              {farmOwners != null &&
                Object.entries(farmOwners).length > 0 &&
                farmOwners.map((fo) => (
                  <Select.Option key={fo.id} value={fo.id}>
                    {fo.name}
                  </Select.Option>
                ))}
            </Select>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <label>{translation.animalBirth.form.child.purchaseCost}</label>
          </Row>
          <Row>
            <NumberFormat
              value={values.purchaseCost || undefined}
              placeholder={translation.defaultPlaceholder}
              customInput={Input}
              addonBefore="R$"
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              allowNegative={false}
              onValueChange={({ floatValue }) => {
                changeFieldValues(
                  "purchaseCost",
                  floatValue ? (floatValue <= 99999 ? floatValue : 99999) : null
                );
              }}
            />
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <label
              className={errors?.stillborn && submitCount > 0 ? "error" : ""}
            >
              {translation.animalBirth.form.child.stillborn}
            </label>
          </Row>
          <Row>
            <Switch
              style={{ width: 35 }}
              checked={values.stillborn || false}
              onChange={(checked) => changeFieldValues("stillborn", checked)}
            />
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default AnimalBirthChildForm;
