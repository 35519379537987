import { call, put, select } from "redux-saga/effects";
import { Creators as ProfitCenterActions } from "../ducks/profitCenter";
/** Services */
import {
  getProfitCenterIndex,
  saveProfitCenter,
  getProfitCenterShow,
} from "../../services/profitCenterService";
import { getTypologyItemIndex } from "../../services/typologyItemService";
import { notification } from "antd";

const getProfitCenterState = (state) => state.profitCenter;

export function* indexProfitCenter(action) {
  try {
    const {
      data: { results: allProfits },
    } = yield call(getProfitCenterIndex, action.payload);
    const {
      data: { results: profitCenterTypes },
    } = yield call(getTypologyItemIndex, {
      groupId: action.payload.groupId,
      typologyName: "Sistema Produtivo",
    });
    const { items } = profitCenterTypes[0];
    if (Object.entries(allProfits).length > 0) {
      if (allProfits.content.length > 0) {
        allProfits.content.map((pc) => {
          if (pc.productionSystemId != null) {
            const { name: typeName } = items.find(
              (t) => t.id === pc.productionSystemId
            );
            pc.typeName = typeName;
          } else {
            pc.typeName = null;
          }
          return pc;
        });
      }
    }
    yield put(
      ProfitCenterActions.indexProfitCenterSuccess(
        allProfits,
        action.payload.type
      )
    );
  } catch (error) {
    yield put(
      ProfitCenterActions.indexProfitCenterError(error, action.payload.type)
    );
  }
}

export function* getProfitCenter(action) {
  try {
    const {
      data: { results: profitCenter },
    } = yield call(getProfitCenterShow, action.payload);
    yield put(ProfitCenterActions.showProfitCenterSuccess(profitCenter));
  } catch (error) {
    yield put(ProfitCenterActions.showProfitCenterError(error));
  }
}

export function* storeOrUpdateProfitCenter(action) {
  let result = null;
  try {
    const {
      data: { results: newProfitCenter },
    } = yield call(saveProfitCenter, action.payload);
    result = newProfitCenter;
    const { saveOptions } = action.payload;
    yield put(
      ProfitCenterActions.saveProfitCenterSuccess(newProfitCenter, saveOptions)
    );
    if (action.payload.id == null)
      notification.success({ message: "Atividade criada com sucesso" });
    else notification.success({ message: "Atividade atualizada com sucesso" });
  } catch (error) {
    notification.error({ message: "Atividade não foi criada" });
    yield put(ProfitCenterActions.saveProfitCenterError(error));
  }

  if (result != null && Object.entries(result).length > 0) {
    const { paginationGrowth, paginationRecreateFatten } = yield select(
      getProfitCenterState
    );
    const newPayloadGrowth = {
      ...action.payload,
      ...paginationGrowth,
    };
    const newPayloadRecreateFatten = {
      ...action.payload,
      ...paginationRecreateFatten,
    };
    yield put(
      ProfitCenterActions.indexProfitCenter(
        newPayloadGrowth.groupId,
        newPayloadGrowth.farmId,
        newPayloadGrowth.page,
        newPayloadGrowth.sorter,
        newPayloadGrowth.filters,
        10,
        "Growth"
      )
    );
    yield put(
      ProfitCenterActions.indexProfitCenter(
        newPayloadRecreateFatten.groupId,
        newPayloadRecreateFatten.farmId,
        newPayloadRecreateFatten.page,
        newPayloadRecreateFatten.sorter,
        newPayloadRecreateFatten.filters,
        10,
        "RecreateFatten"
      )
    );
  }
}
