import styled from "styled-components";
import { Divider } from "antd";

export const Container = styled.div`
  width: 100% !important;
  min-width: 100% !important;
  height: 100% !important;
  min-height: 100% !important;
  border-radius: 5px;
  background: #f5f5f5;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 17px 17px;
  margin-top: 10px;

  /* * {
      border: 1px solid green;
  } */

  .ant-row-flex.rowInfo {
    margin-top: 4px;

    &:last-child {
      margin-top: 0px;
    }
  }

  .title-data {
    display: block;
    margin: 0px 0px 7px 0px;
    color: #a5a5a5;
    font-size: 18px;
    svg {
      margin-left: 5px;
      width: 14px;
      height: 14px;
      path {
        fill: #a5a5a5 !important;
      }
    }
  }

  .title-grid {
    display: block;
    margin: 8px 0px 6px 0px;
    color: #4a85ae;
    font-size: 15px;
    font-weight: bold;
  }

  .sub-title-grid {
    display: block;
    margin: 8px 0px 6px 0px;
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
  }

  .title-item {
    color: #000;
    font-size: 13px;
    font-weight: bold;
  }
  .number-item {
    color: #a5a5a5;
    font-size: 13px;
    font-weight: normal;
  }

  .sub-title-item {
    color: #000;
    font-size: 13px;
  }

  .sub-title-item-null {
    margin-left: 20px;
    color: #000;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }

  .monthName {
    font-family: Asap;
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    color: #4b4b4b;
    text-transform: uppercase;
  }

  .monthDays {
    align-self: flex-end;
    font-family: Asap;
    font-weight: normal;
    font-size: 10px;
    text-align: right;
    color: #a5a5a5;
  }
`;

export const KpiValuesTable = styled.div`
  width: 100%;
  display: flex;

  div.col-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90px;

    div.row {
      width: 90px;
    }
  }

  div.col-values {
    // width: 560px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-left: 8px;

    overflow-y: hidden;
    overflow-x: auto;

    div.row {
      width: 100%;

      &:not(:first-child) {
        div.row-col {
          border-top: 2px solid #e8e5e5;
          border-bottom: 2px solid #e8e5e5;

          &:first-child {
            border-left: 2px solid #e8e5e5;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }
          &:last-child {
            border-right: 2px solid #e8e5e5;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }
      }
    }
  }

  div.row {
    display: flex;
    height: 50px;
    display: flex;
    align-items: center;

    & + div.row {
      margin-bottom: 16px;
    }

    div.row-col {
      display: flex;
      height: 100%;
      min-width: 175px;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0 8px;
    }
  }
`;

export const CustomDivider = styled(Divider)`
  margin: 10px 0 10px 0 !important;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 8px 0 8px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;
