import apiManagement from "../config/api_management";
import getHeaders from "./getHeaders";

export function pageAllClientsByGroupId(payload) {
  const {
    groupId,
    farmId,
    page,
    sorter,
    filters,
    size,
    withoutPagination,
  } = payload;
  let sortS = "";

  if (withoutPagination) {
    return apiManagement.get(
      `/bovexo/${groupId}/farms/${farmId}/clients?withoutPagination=true`,
      getHeaders(false, null)
    );
  }

  if (sorter && Object.entries(sorter).length > 0) {
    const { field, order } = sorter;

    const fieldS = field;

    const orderS = order && order === "descend" ? "DESC" : "ASC";

    sortS = `&sort=${fieldS}&direction=${orderS}`;
  }

  return apiManagement.get(
    `/bovexo/${groupId}/farms/${farmId}/clients?
    ${page ? `&page=${page - 1}` : ""}
    ${size && `&size=${size}`}
    ${sortS}
    ${filters !== "" && filters ? `&search=${filters}` : ""}`,
    getHeaders(false, null)
  );
}

export function getListOfClientsDropDown(payload) {
  const { groupId, farmId, onlyActives } = payload;
  return apiManagement.get(
    `/bovexo/${groupId}/farms/${farmId}/clients/list?onlyActives=${
      onlyActives != null ? onlyActives : "false"
    }`,
    getHeaders(false, null)
  );
}

export function findClientById(payload) {
  const { clientId, groupId, farmId, signal } = payload;
  return apiManagement.get(
    `/bovexo/${groupId}/farms/${farmId}/clients/${clientId}`,
    getHeaders(false, signal)
  );
}

export function findClientByDocumentAndIsVisibleForAllSystem(payload) {
  const { groupId, farmId, document } = payload;
  return apiManagement.get(
    `/bovexo/${groupId}/farms/${farmId}/clients/document/${document}`,
    getHeaders(false, null)
  );
}

export function storeClient(payload) {
  const { form: data, groupId, farmId } = payload;
  return apiManagement.post(
    `/bovexo/${groupId}/farms/${farmId}/clients`,
    data,
    getHeaders(false, null)
  );
}

export function updateClient(payload) {
  const { id: clientId, form: data, groupId, farmId } = payload;
  return apiManagement.patch(
    `/bovexo/${groupId}/farms/${farmId}/clients/${clientId}`,
    data,
    getHeaders(false, null)
  );
}

export function reactivateClient(payload) {
  const { id: clientId, groupId, farmId } = payload;
  return apiManagement.patch(
    `/bovexo/${groupId}/farms/${farmId}/clients/${clientId}/reactivate`,
    null,
    getHeaders(false, null)
  );
}

export function inactivateClient(payload) {
  const { id: clientId, groupId, farmId } = payload;
  return apiManagement.delete(
    `/bovexo/${groupId}/farms/${farmId}/clients/${clientId}`,
    getHeaders(false, null)
  );
}

export function sendEmailContactUs(data) {
  return apiManagement.post(`/bovexo/admin/contact-us`, data);
}
