import { useContext } from "react";
import { AnimalListContext } from "../../contexts/animalListContext";

// import { Container } from './styles';

function useAnimalListContext() {
  const values = useContext(AnimalListContext);
  return values;
}

export default useAnimalListContext;
