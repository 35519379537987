import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  Row,
  Card,
  Col,
  Table,
  Input,
  Button,
  Icon,
  Menu,
  Dropdown,
} from "antd";

import { Creators as DietActions } from "../../store/ducks/diet";

import { Container, Title, NewDiet } from "./styles";
import TagStatus from "../../components/utils/tagStatus";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import MenuIcon from "../../components/utils/table/icons/menu";
import Loading from "../../components/utils/loading";

class Diet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      success: false,
    };
  }

  static propTypes = {
    dietActions: PropTypes.any.isRequired,
    app: PropTypes.any.isRequired,
    diet: PropTypes.any.isRequired,
  };

  async componentDidMount() {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;
    if (
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      await this.fetchData(groupSelected, farmSelected, 0, {});
    }
  }

  async shouldComponentUpdate(nextProps) {
    const differentGroup =
      this.props.app.groupSelected?.id !== nextProps.app.groupSelected?.id;
    const differentFarm =
      this.props.app.farmSelected?.id !== nextProps.app.farmSelected?.id;
    if (differentGroup || differentFarm) {
      if (
        Object.entries(nextProps.app.groupSelected).length > 0 &&
        Object.entries(nextProps.app.farmSelected).length > 0
      ) {
        await this.fetchData(
          nextProps.app.groupSelected,
          nextProps.app.farmSelected,
          0,
          {}
        );
      }
    }
    return true;
  }

  fetchData = async (
    groupSelected,
    farmSelected,
    page = 0,
    sorter = {},
    filters = "",
    size = 10
  ) => {
    const { dietActions } = this.props;
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    await dietActions.indexDiet(groupId, farmId, page, sorter, filters, size);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
            background: "#684e94",
            borderColor: "none",
            border: "none",
          }}
        />
        <Button
          onClick={() => this.handleReset(clearFilters)}
          icon="delete"
          size="small"
          style={{ width: 90 }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  handleReset = (clearFilters) => {
    clearFilters();
  };

  handleTableChange = async (pagination, filters, sorter) => {
    this.setState({
      paginationInfo: pagination,
      sortedInfo: sorter,
      filteredInfo: filters,
    });
    const {
      app: { groupSelected, farmSelected },
    } = this.props;

    let search = "";
    if (Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v.length > 0) {
          if (k === "status") {
            let statusString = "";
            for (let i = 0; i < v.length; i++) {
              statusString += i === v.length - 1 ? `'${v[i]}'` : `'${v[i]}',`;
            }
            search = `${search};${k} IN (${statusString})`;
          } else if (k === "closingDate") {
            search = `${search};${k} between '${v[0]}' and '${v[1]}'`;
          } else {
            search = `${search};${k}='${v}'`;
          }
        }
      });
    }
    await this.fetchData(
      groupSelected,
      farmSelected,
      pagination.current,
      sorter,
      search,
      pagination.pageSize
    );
  };

  handleEdit = async (id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      dietActions,
    } = this.props;
    await Promise.all([
      dietActions.showDiet(groupId, farmId, id),
      dietActions.showDrawer(),
    ]);
  };

  render() {
    const {
      app: { translation },
      diet: { data, isLoading },
      dietActions,
    } = this.props;

    const Column = Table.Column;

    const menu = (id) => {
      const {
        app: { translation },
      } = this.props;
      return (
        <Menu>
          <Menu.Item key="1">
            <button onClick={() => this.handleEdit(id)}>
              {translation.table.menu.edit}
            </button>
          </Menu.Item>
        </Menu>
      );
    };

    return (
      <Container>
        {isLoading && <Loading />}
        {/* HEADER */}
        {/* <Row type="flex">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <PageTitle>{translation.diet.title}</PageTitle>
          </Col>
        </Row> */}
        {/* GRID */}
        <Card>
          <Row
            type="flex"
            justify="space-between"
            style={{ marginBottom: "19px" }}
          >
            <Col>
              <Title>{translation.diet.table.title}</Title>
            </Col>
            <Col>
              <NewDiet onClick={() => dietActions.showDrawer("new")}>
                {translation.diet.buttonNewDiet}
              </NewDiet>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Table
                rowKey="id"
                size="small"
                dataSource={data != null ? data.content : []}
                pagination={{
                  total: data != null ? data.totalElements : 0,
                  size: data != null ? data.size : 0,
                  current: data != null ? data.number + 1 : 0,
                  showSizeChanger: true,
                  pageSizeOptions: [
                    "10",
                    "20",
                    "30",
                    "100",
                    "500",
                    "1000",
                    "2000",
                  ],
                  hideOnSinglePage: true,
                }}
                scroll={{
                  x: true,
                }}
                style={{ width: "100%", margin: "0" }}
                onChange={this.handleTableChange}
              >
                <Column
                  title={translation.diet.table.columns.status}
                  dataIndex="status"
                  key="status"
                  align="left"
                  sorter
                  sortDirections={["descend", "ascend"]}
                  render={(status) =>
                    status === "Active" ? (
                      <TagStatus
                        background="#C5F1CA"
                        borderColor="#106518"
                        color="#106518"
                      >
                        {translation.status.active}
                      </TagStatus>
                    ) : (
                      <TagStatus
                        background="#FBC7C7"
                        borderColor="#D44C4C"
                        color="#D44C4C"
                      >
                        {translation.status.inactive}
                      </TagStatus>
                    )
                  }
                />
                <Column
                  title={translation.diet.table.columns.description}
                  dataIndex="name"
                  sorter
                  key="name"
                  align="left"
                />
                <Column
                  title={translation.diet.table.columns.cost}
                  dataIndex="cost"
                  sorter
                  key="cost"
                  align="left"
                  render={(text, record) => (
                    <span>
                      {record.cost !== null
                        ? `${record.cost.toFixed(2)} R$/Kg`
                        : ""}
                    </span>
                  )}
                />
                <Column
                  title={translation.diet.table.columns.phosphorus}
                  dataIndex="phosphorus"
                  sorter
                  key="phosphorus"
                  align="left"
                  render={(text, record) => (
                    <span>
                      {record.phosphorus !== null
                        ? `${record.phosphorus} mg`
                        : ""}
                    </span>
                  )}
                />
                <Column
                  title={translation.diet.table.columns.nitrogen}
                  dataIndex="nitrogen"
                  sorter
                  key="nitrogen"
                  align="left"
                  render={(text, record) => (
                    <span>
                      {record.nitrogen !== null ? `${record.nitrogen} mg` : ""}
                    </span>
                  )}
                />
                <Column
                  title={translation.diet.table.columns.calcium}
                  dataIndex="calcium"
                  sorter
                  key="calcium"
                  align="left"
                  render={(text, record) => (
                    <span>
                      {record.calcium !== null ? `${record.calcium} mg` : ""}
                    </span>
                  )}
                />
                <Column
                  title={translation.diet.table.columns.tdn}
                  dataIndex="tdn"
                  sorter
                  key="tdn"
                  align="left"
                  render={(text, record) => (
                    <span>{record.tdn !== null ? `${record.tdn} g` : ""}</span>
                  )}
                />
                <Column
                  title={translation.diet.table.columns.cp}
                  dataIndex="cp"
                  sorter
                  key="cp"
                  align="left"
                  render={(text, record) => (
                    <span>{record.cp !== null ? `${record.cp} g` : ""}</span>
                  )}
                />
                <Column
                  align="left"
                  render={(text, record) => (
                    <Dropdown
                      overlay={menu(record.id)}
                      trigger={["click"]}
                      key={record.id}
                    >
                      <MenuContainer>
                        <MenuIcon />
                      </MenuContainer>
                    </Dropdown>
                  )}
                />
              </Table>
            </Col>
          </Row>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  diet: state.diet,
});

const mapDispatchToProps = (dispatch) => ({
  dietActions: bindActionCreators(DietActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Diet));
