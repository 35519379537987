import styled from "styled-components";
import { Card, Collapse } from "antd";

export const Container = styled(Card)`
  background: #fcfcfc !important;
  border-radius: 10px !important;
  width: 100%;
  margin-top: 24px !important;

  .ant-card-body {
    background: #f5f5f5 !important;
    padding-top: 8px !important;
  }

  .ant-card-head {
    border-bottom: none !important;

    button {
      background: transparent !important;
      border: none !important;
      cursor: pointer;

      i {
        color: #8a50a5 !important;
      }
    }
  }

  .ant-list-item {
    padding-top: 0px !important;
  }
`;

export const Body = styled.div`
  .deleteIcon {
    color: #94cf1c;
    font-size: 20px;
    margin-top: 8px;
  }
  .iconButton {
    background: transparent;
    border: none;
    cursor: pointer;
  }
  label.formLabel {
    display: block;
    font-family: "Asap", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #8a50a5;
    margin-left: 15px;
    margin-bottom: 8px;

    #bovexoInfoIcon {
      margin-left: 3px;
    }

    &.disabled {
      color: #c4c4c4;
    }
    &.error {
      color: #d44c4c;
    }

    @media screen and (max-width: 769px) {
      margin-top: 16px;
    }
  }

  .ant-row-flex:not(:first-of-type) {
    margin-top: 17px;
  }

  .ant-switch-checked {
    background-color: #a9c133;
  }
  .ant-switch-loading-icon,
  .ant-switch-checked::after {
    left: 110% !important;
    top: 0px;
    width: 20px;
    height: 20px;
    margin-left: -1px;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  .ant-switch-loading-icon,
  .ant-switch::after {
    position: absolute;
    top: 0px;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 15px;
    cursor: pointer;
    -webkit-transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content: " ";
  }
`;

export const CollapseContainer = styled(Collapse)`
  margin-top: 24px !important;
  background: #f5f5f5 !important;
  border: none !important;

  .ant-collapse-item,
  .ant-collapse-header,
  .ant-collapse-content {
    border: none !important;
    background: #f5f5f5 !important;
  }

  .ant-collapse-header {
    padding: 0px 16px !important;

    i {
      color: #8a50a5 !important;
    }

    font-family: "Asap", sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 18px !important;
    line-height: 40px !important    ;
    border-radius: 0 !important;
    background: #f5f5f5 !important;
    border-bottom: 1px solid #c4c4c4 !important;
  }

  @media screen and (max-width: 769px) {
    .ant-collapse-content > .ant-collapse-content-box {
      overflow-x: scroll;
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  font-family: sans-serif;
  border-collapse: separate;
  border-spacing: 0;

  font-family: "Asap", sans-serif;

  .width-140 {
    min-width: 140px;
  }

  .width-200 {
    min-width: 200px;
  }

  thead {
    tr {
      th {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        color: #4b4b4b;
        border-bottom: 1px solid #9d9d9d;
        padding: 8px 4px;

        &.error {
          color: #d44c4c;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #4b4b4b;
        padding: 8px 4px;

        .ant-select {
          width: 100%;
        }
      }
    }
  }
`;

export const Footer = styled.div`
  padding-top: 24px;
  width: 100%;
`;
