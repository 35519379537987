import apiCommons from "../config/api_commons";
import getHeaders from "./getHeaders";

export function getHomeConfig(payload) {
  const { groupId, farmId, signal } = payload;

  return apiCommons.get(
    `/bovexo/${groupId}/farms/${farmId}/home/configurations`,
    getHeaders(false, signal)
  );
}
export function saveHomeConfig(payload) {
  const { groupId, farmId, signal, body } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/home/configurations`,
    body,
    getHeaders(false, signal)
  );
}
