import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as SupplierActions } from "../../../../store/ducks/supplier";

// Components
import { Container, BoxStatus, CardDetail } from "./styles";
import { Row, Col, Spin, Icon } from "antd";
import CopyClipboard from "../../../utils/copyClipboard";
// Services
import {
  cnpjMask,
  cpfMask,
  getOnlyNumber,
  phoneMask,
} from "../../../../services/helpersMethodsService";
import { getSupplierShow } from "../../../../services/supplierService";

const DrawerSupplierDetails = () => {
  const [loadRequest, setLoadRequest] = useState(false);
  const [supplierData, setSupplierData] = useState(null);
  // Redux Variable
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { drawerSupplierDetailsVisible, idSupplierEditOrDetails } = useSelector(
    (state) => state.supplier
  );
  const dispatch = useDispatch();

  // Methods
  function closeDrawer() {
    setSupplierData(null);
    dispatch(SupplierActions.hideDrawer());
  }

  async function handleDrawerVisible(visible) {
    if (visible) {
      setLoadRequest(true);
      try {
        if (idSupplierEditOrDetails !== null) {
          const {
            data: { results },
          } = await getSupplierShow({
            groupId,
            farmId,
            id: idSupplierEditOrDetails,
          });
          let resultsFormated = {
            ...results,
            document: results.document
              ? results.document?.length <= 11
                ? cpfMask(results.document)
                : cnpjMask(results.document)
              : null,
            phoneNumber: phoneMask(getOnlyNumber(results?.phoneNumber)),
          };
          setSupplierData(resultsFormated);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadRequest(false);
      }
    }
  }

  return (
    <Container
      title="Detalhes do fornecedor"
      width={511}
      onClose={closeDrawer}
      maskClosable={false}
      visible={drawerSupplierDetailsVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <div className="drawerForm">
        <Spin spinning={loadRequest}>
          <Row type="flex">
            <CardDetail>
              <Row
                type="flex"
                justify="space-between"
                className="alingItensCenter"
              >
                <Col span={16}>
                  <div className="contentRow">
                    <Icon
                      type="user"
                      style={{ fontSize: 24, color: "#C4C4C4" }}
                    />
                    <span className="title">{supplierData?.name}</span>
                  </div>
                </Col>
                <Col span={8} align="right">
                  <Row type="flex" justify="end">
                    <strong>
                      {supplierData?.type === "Supplement"
                        ? translation.supplier.types.supplement
                        : supplierData?.type === "Technology"
                        ? translation.supplier.types.technology
                        : supplierData?.type === "CattleFarmer"
                        ? translation.supplier.types.cattleFarmer
                        : "Não informado"}
                    </strong>
                  </Row>
                  <Row type="flex" justify="end">
                    {supplierData?.status === "Active" ? (
                      <BoxStatus className="active">
                        <span>Ativo</span>
                      </BoxStatus>
                    ) : supplierData?.status === "Inactive" ? (
                      <BoxStatus className="inactive">
                        <span>Inativo</span>
                      </BoxStatus>
                    ) : supplierData?.status === "Bovexo" ||
                      supplierData?.status === "FarmBovexo" ? (
                      <BoxStatus className="bovexo">
                        <span>Bovexo</span>
                      </BoxStatus>
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </CardDetail>
          </Row>
          {supplierData?.document?.length > 11 ? (
            <Row type="flex">
              <CardDetail>
                <div className="contentRow">
                  <span className="document">CNPJ</span>
                  <span className="title margin-top">
                    {supplierData?.document}
                  </span>
                </div>
                <div className="contentRow svgCustom">
                  <CopyClipboard text={supplierData?.document || ""} />
                </div>
              </CardDetail>
            </Row>
          ) : null}

          {supplierData?.document?.length <= 11 ? (
            <Row type="flex">
              <CardDetail>
                <div className="contentRow">
                  <span className="document">CPF</span>
                  <span className="title margin-top">
                    {supplierData?.document}
                  </span>
                </div>
                <div className="contentRow svgCustom">
                  <CopyClipboard text={supplierData?.document || ""} />
                </div>
              </CardDetail>
            </Row>
          ) : null}

          <Row type="flex">
            <CardDetail>
              <div className="contentRow">
                <Icon type="phone" style={{ fontSize: 24, color: "#C4C4C4" }} />
                <span className="title">{supplierData?.phoneNumber}</span>
              </div>
              <div className="contentRow svgCustom">
                <CopyClipboard text={supplierData?.phoneNumber || ""} />
              </div>
            </CardDetail>
          </Row>
          {supplierData?.city !== null &&
            supplierData?.city?.name !== null &&
            supplierData?.city?.name?.trim() !== "" && (
              <Row type="flex">
                <CardDetail>
                  <Row style={{ width: "100%" }}>
                    <Row>
                      <div className="contentRowFlex">
                        <div>
                          <Icon
                            type="pushpin"
                            style={{ fontSize: 24, color: "#C4C4C4" }}
                          />
                          <span className="title">
                            <span>
                              {supplierData?.address || ""},{" "}
                              {supplierData?.number || ""}
                            </span>
                            <br />
                            <span className="title-margin">
                              {`${supplierData?.city?.name || ""} -
                          ${supplierData?.city?.state?.name || ""} -
                          ${supplierData?.zipCode || ""}`}
                            </span>
                            <br />
                            {supplierData?.complement != null && (
                              <>
                                <span className="title-card">Complemento</span>
                                <br />
                                <span className="title-margin">
                                  {supplierData?.complement}
                                </span>
                              </>
                            )}
                          </span>
                        </div>
                        <div className="svgCustom">
                          <CopyClipboard
                            text={`${supplierData?.address || ""}, ${
                              supplierData?.number || ""
                            }, ${supplierData?.city?.name || ""}/${
                              supplierData?.city?.state?.name || ""
                            }. CEP: ${supplierData?.zipCode || ""}`}
                          />
                        </div>
                      </div>
                    </Row>
                  </Row>
                </CardDetail>
              </Row>
            )}
        </Spin>
      </div>
    </Container>
  );
};

export default DrawerSupplierDetails;
