import React, { useState } from "react";
import moment from "moment";
import { useCallback } from "react";
import { createContext } from "react";
import { useSelector } from "react-redux";
import { getAnimalsBirth } from "../../services/animalBirthService";
import { getAnimalDropdownListByGender } from "../../services/animalService";

export const INITIAL_DATA_FORM_ANIMAL_DG = {
  id: null,
  matrizAnimalHandlingNumber: null,
  matrizAnimalId: null,
  matrizSituation: null,
  birthDate: moment(),
  type: "parto",
  discardMotive: null,
  animalCoverageId: null,
  birthForecast: null,
  gestationDays: null,
  coverageDate: null,
  coverageType: null,
  reproductorId: null,
  reproductorName: null,
  lastCoverageDiagnosisDate: null,
  lastCoverageDiagnosisResult: null,
  shouldDiscardFemale: false,
  shouldChangeFemaleCategoryForReproduction: false,
  shouldMovimentMatrizToNewLot: false,
  lotMatrizDestinationId: null,
  observation: null,
  childs: [
    {
      tagId: null,
      sisbov: null,
      handlingNumber: null,
      nickname: null,
      gender: null,
      breedId: null,
      farmOwnerId: null,
      animalReproductionCategory: null,
      femaleSituation: null,
      animalFarmFunction: null,
      birthdayWeight: null,
      lotId: null,
      stillborn: false,
    },
  ],
};

export const AnimalBirthContext = createContext({
  dataList: [],
  isLoadingDataList: false,
  tableProperties: {
    size: 10,
    page: 0,
    tableFilters: {},
    tableSorters: {},
    search: null,
  },
  showCardForm: false,
  data: INITIAL_DATA_FORM_ANIMAL_DG,
  fetchData: (page = 0, size = 10, sort = {}, search = null) => {},
  handleTableChange: (pagination, filters, sorter) => {},
});

export const AnimalBirthContextProvider = ({ children }) => {
  const [dataList, setDataList] = useState([]);
  const [data, setData] = useState(INITIAL_DATA_FORM_ANIMAL_DG);
  const [tableProperties, setTableProperties] = useState({
    size: 10,
    page: 0,
    tableFilters: {},
    tableSorters: {},
    search: null,
  });
  const [isLoadingDataList, setIsLoadingDataList] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false);
  const [females, setFemales] = useState([]);
  const [isLoadingFemalesList, setIsLoadingFemalesList] = useState(false);

  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const fetchData = useCallback(
    async (page = 0, size = 10, sort = {}, search = null) => {
      setIsLoadingDataList(true);
      try {
        const {
          data: { results },
        } = await getAnimalsBirth({
          groupId,
          farmId,
          page,
          size,
          sort,
          search,
        });
        setDataList(results);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingDataList(false);
      }
    },
    [farmId, groupId]
  );

  const fetchFemalesDropDown = useCallback(async () => {
    setIsLoadingFemalesList(true);
    try {
      const {
        data: { results },
      } = await getAnimalDropdownListByGender({
        groupId,
        farmId,
        gender: "F",
      });
      setFemales(results);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingFemalesList(false);
    }
  }, [farmId, groupId]);

  const openOrCloseForm = () => {
    setShowCardForm((old) => !old);
  };

  const initializeData = (data) => {
    if (data === null) {
      setData(INITIAL_DATA_FORM_ANIMAL_DG);
    } else {
      setData(data);
    }
  };

  const getSortString = useCallback((sorter) => {
    let customSort = sorter;

    if (sorter && Object.entries(sorter).length > 0) {
      const { field } = sorter;

      const fieldS = field
        ? field === "matrizAnimalHandlingNumber"
          ? "matriz_animal_handling_number"
          : field === "birthDate"
          ? "birth_date"
          : field === "coverageDate"
          ? "coverage_date"
          : field === "coverageType"
          ? "coverage_type"
          : field
        : null;

      customSort = {
        ...customSort,
        field: fieldS,
      };
    }

    return customSort;
  }, []);

  const getSearchString = useCallback((filters) => {
    let search = "";

    if (filters && Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([field, value]) => {
        if (value && value.length > 0) {
          const fieldS = field
            ? field === "matrizAnimalHandlingNumber"
              ? "matriz_animal_handling_number"
              : field === "birthDate"
              ? "birth_date"
              : field === "coverageDate"
              ? "coverage_date"
              : field === "coverageType"
              ? "coverage_type"
              : field
            : null;
          if (fieldS === "weight") {
            const operatorS =
              value[1] === "equal"
                ? "="
                : value[1] === "greaterThan"
                ? ">"
                : value[1] === "greaterOrEqualThan"
                ? ">="
                : value[1] === "lessThan"
                ? "<"
                : value[1] === "lessOrEqualThan"
                ? "<="
                : "=";
            search =
              search === ""
                ? `${fieldS} ${operatorS} '${value[0]}'`
                : `${search};${fieldS} ${operatorS} '${value[0]}'`;
          } else if (fieldS === "birth_date" || fieldS === "coverage_date") {
            const operatorS =
              value[1] === "equal"
                ? "="
                : value[1] === "greaterThan"
                ? ">"
                : value[1] === "greaterOrEqualThan"
                ? ">="
                : value[1] === "lessThan"
                ? "<"
                : value[1] === "lessOrEqualThan"
                ? "<="
                : "=";

            search =
              search === ""
                ? `cast(${fieldS} as date) ${operatorS} cast('${moment(
                    value[0]
                  ).format("YYYY-MM-DD")}' as date)`
                : `${search};cast(${fieldS} as date) ${operatorS} cast('${moment(
                    value[0]
                  ).format("YYYY-MM-DD")}' as date)`;
          } else {
            search =
              search === ""
                ? `upper(${fieldS}) like upper('%25${value}%25')`
                : `${search};upper(${fieldS}) like upper('%25${value}%25')`;
          }
        }
      });
    }

    return search !== "" ? search : "";
  }, []);

  const handleTableChange = useCallback(
    async (pagination = null, filters = null, sorter = null) => {
      if (pagination && filters && sorter) {
        let customSort = getSortString(sorter);
        let search = getSearchString(filters);

        setTableProperties({
          tableFilters: filters,
          tableSorters: sorter,
          size: pagination.pageSize,
          page: pagination.current,
          search,
        });

        await fetchData(
          pagination.current,
          pagination.pageSize,
          customSort,
          search
        );
      } else {
        setTableProperties({
          size: 10,
          page: 0,
          tableFilters: {},
          tableSorters: {},
          search: null,
        });
        await fetchData(0, 10, {}, "");
      }
    },
    [getSortString, getSearchString, fetchData]
  );

  return (
    <AnimalBirthContext.Provider
      value={{
        dataList,
        fetchData,
        isLoadingDataList,
        showCardForm,
        openOrCloseForm,
        data,
        initializeData,
        females,
        isLoadingFemalesList,
        fetchFemalesDropDown,
        tableProperties,
        handleTableChange,
      }}
    >
      {children}
    </AnimalBirthContext.Provider>
  );
};
