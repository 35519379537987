import React from "react";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { CardCustom, Title } from "./styles";
import CardReproductionParameters from "./cards/reproductionParameters";
import CardReproductionScore from "./cards/reproductionScore";
import CardBreedReproductionScore from "./cards/breedReproductionScore";
import CardReproductionPeriod from "./cards/reproductionPeriod"

const ReproductionParameters = () => {
  const { translation } = useSelector((state) => state.app);
  return (
    <CardCustom>
      <Row className="rowHeader" justify="start" gutter={8}>
        <Col span={24}>
          <Title>{translation.reproductionParameters.title}</Title>
        </Col>
      </Row>
      {/* Card reproduction parameters */}
      <Row className="rowCard" justify="start" gutter={8}>
        <Col span={24}>
          <CardReproductionParameters />
        </Col>
      </Row>
      {/* Card reproduction period*/}
      <Row className="rowCard" justify="start" gutter={8}>
        <Col span={24}>
          <CardReproductionPeriod />
        </Col>
      </Row>
      {/* Card reproduction score */}
      <Row className="rowCard" justify="start" gutter={8}>
        <Col span={24}>
          <CardReproductionScore />
        </Col>
      </Row>
      {/* Card animal reproduction score */}
      <Row className="rowCard" justify="start" gutter={8}>
        <Col span={24}>
          <CardBreedReproductionScore />
        </Col>
      </Row>
    </CardCustom>
  );
};

export default ReproductionParameters;
