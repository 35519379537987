export const resetAllDatas = (store) => {
  store.dispatch({
    type: "animal/RESET_DATA",
    payload: {},
  });
  store.dispatch({
    type: "farm/RESET_DATA",
    payload: {},
  });
  store.dispatch({
    type: "breed/RESET_DATA",
    payload: {},
  });
  store.dispatch({
    type: "diet/RESET_DATA",
    payload: {},
  });
  store.dispatch({
    type: "lot/RESET_DATA",
    payload: {},
  });
  store.dispatch({
    type: "pasture/RESET_DATA",
    payload: {},
  });
  store.dispatch({
    type: "picket/RESET_DATA",
    payload: {},
  });
  store.dispatch({
    type: "profitCenter/RESET_DATA",
    payload: {},
  });
  store.dispatch({
    type: "supplement/RESET_DATA",
    payload: {},
  });
  store.dispatch({
    type: "supplier/RESET_DATA",
    payload: {},
  });
  store.dispatch({
    type: "typologyItem/RESET_DATA",
    payload: {},
  });
  store.dispatch({
    type: "marketAnalysis/RESET_DATA",
    payload: {},
  });
  store.dispatch({
    type: "generalParameters/RESET_DATA",
    payload: {},
  });
  store.dispatch({
    type: "lotSupplementConsumption/RESET_DATA",
    payload: {},
  });
  store.dispatch({
    type: "saleScenario/RESET_DATA",
    payload: {},
  });
  store.dispatch({
    type: "import/RESET_DATA",
    payload: {},
  });
};

const resetDataMiddleware = (store) => (next) => (action) => {
  if (action.type === "app/LOGOUT_USER") {
    resetAllDatas(store);
  }
  if (action.type === "app/RESET_ALL_DATAS") {
    resetAllDatas(store);
  }

  next(action);
};

export default resetDataMiddleware;
