import React from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";

import { FiMinus, FiPlus } from "react-icons/fi";
import { CustomCollapse, EditButton, Footer } from "../styles";
import { Col, Input, Row, Spin } from "antd";
import PencilIcon from "../../../../components/utils/icons/pencil";
import ButtonStandard from "../../../../components/utils/button";
import NumberFormat from "react-number-format";

// Services
import {
  findAllReproductionParemeterFarmByGroupIdAndFarmId,
  saveReproductionParemeterFarm,
} from "../../../../services/reproductionParameterFarmService";
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../../services/helpersMethodsService";

// import { Container } from './styles';

function CardReproductionParameters() {
  const [isEditing, setIsEditing] = useState(false);
  const [defaultData, setDefaultData] = useState([]);
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [form, setForm] = useState({
    values: [],
  });
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  // Redux
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  // Callbacks
  const fetch = useCallback(async () => {
    setIsLoadingData(true);
    try {
      const {
        data: { results },
      } = await findAllReproductionParemeterFarmByGroupIdAndFarmId({
        groupId,
        farmId,
      });
      setDefaultData(results?.defaultValues);
      setData(results?.farmValues);
      setForm({
        values: [...results?.farmValues],
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingData(false);
    }
  }, [farmId, groupId]);

  const renderParameterVisible = useCallback(
    (type, unit, value, monetary = false) => {
      switch (type) {
        case "String":
          return <strong>{`${value} ${unit != null ? unit : ""}`}</strong>;
        case "Integer":
          return <strong>{`${value} ${unit != null ? unit : ""}`}</strong>;
        case "Double":
          return (
            <strong>
              {`${numberMask(getTwoDecimalDigits(value || 0), monetary)} ${
                unit != null ? unit : ""
              }`}
            </strong>
          );
        default:
          return <strong></strong>;
      }
    },
    []
  );

  const renderParameterEdit = useCallback(
    (
      rowIndex,
      index,
      name,
      type,
      unit,
      parameter,
      monetary = false,
      setFieldValue
    ) => {
      switch (type) {
        case "String":
          return (
            <Input
              name={name}
              value={parameter?.value}
              placeholder={translation.defaultPlaceholder}
              addonAfter={unit ? unit : null}
              onChange={(e) => {
                setFieldValue(
                  `values[${rowIndex > 0 ? 3 + index : index}].value`,
                  e.target.value
                );
                setFieldValue(
                  `values[${rowIndex > 0 ? 3 + index : index}].groupId`,
                  groupId
                );
                setFieldValue(
                  `values[${rowIndex > 0 ? 3 + index : index}].farmId`,
                  farmId
                );
              }}
            />
          );
        case "Integer":
          return (
            <Input
              type="number"
              name={name}
              placeholder={translation.defaultPlaceholder}
              min={0}
              value={parameter?.value}
              addonAfter={unit ? unit : null}
              onChange={(e) => {
                setFieldValue(
                  `values[${rowIndex > 0 ? 3 + index : index}].value`,
                  e.target.value != null
                    ? name === "IDADE_INICIO_FASE_REPRODUTIVA"
                      ? e.target.value > 99
                        ? 99
                        : e.target.value
                      : name === "PERIODO_DESCANSO_POS_PARTO"
                      ? e.target.value > 999
                        ? 999
                        : e.target.value
                      : name === "TEMPO_APOS_COBERTURA_PARA_DG"
                      ? e.target.value > 999
                        ? 999
                        : e.target.value
                      : e.target.value
                    : null
                );
                setFieldValue(
                  `values[${rowIndex > 0 ? 3 + index : index}].groupId`,
                  groupId
                );
                setFieldValue(
                  `values[${rowIndex > 0 ? 3 + index : index}].farmId`,
                  farmId
                );
              }}
            />
          );
        case "Double":
          return (
            <NumberFormat
              customInput={Input}
              name={name}
              value={parameter?.value}
              placeholder={translation.defaultPlaceholder}
              min={0}
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale={true}
              addonAfter={unit ? unit : null}
              onValueChange={({ floatValue }) => {
                setFieldValue(
                  `values[${rowIndex > 0 ? 3 + index : index}].value`,
                  floatValue
                );
                setFieldValue(
                  `values[${rowIndex > 0 ? 3 + index : index}].groupId`,
                  groupId
                );
                setFieldValue(
                  `values[${rowIndex > 0 ? 3 + index : index}].farmId`,
                  farmId
                );
              }}
            />
          );
        default:
          return null;
      }
    },
    [farmId, groupId]
  );

  const renderParams = useCallback(
    (pvalues, errors, submitCount, setFieldValue) => {
      let groupParametersIn3 = [];
      if (pvalues) {
        pvalues.values.forEach((param, index) => {
          if (groupParametersIn3.length === 0) {
            groupParametersIn3.push([param]);
          } else if (
            groupParametersIn3[groupParametersIn3.length - 1].length < 3
          ) {
            groupParametersIn3[groupParametersIn3.length - 1].push(param);
          } else {
            groupParametersIn3.push([param]);
          }
        });
      }
      return groupParametersIn3.map((arr, rowIndex) => (
        <Row
          className="rowFields"
          type="flex"
          key={rowIndex}
          justify="start"
          gutter={8}
        >
          {arr.map((param, colIndex) => (
            <Col span={8} key={param?.id}>
              <Row>
                <label
                  className={
                    errors[param.reproductionParameter.name] && submitCount > 0
                      ? "error"
                      : ""
                  }
                >
                  {translation.reproductionParameters.parameters[
                    param.reproductionParameter.name
                  ] || param.reproductionParameter.name}
                </label>
              </Row>
              <Row>
                {!isEditing ? (
                  <strong>
                    {renderParameterVisible(
                      param.reproductionParameter.valueType,
                      param.reproductionParameter.unit,
                      param.value,
                      param.reproductionParameter.monetary
                    )}
                  </strong>
                ) : (
                  renderParameterEdit(
                    rowIndex,
                    colIndex,
                    param.reproductionParameter.name,
                    param.reproductionParameter.valueType,
                    param.reproductionParameter.unit,
                    param,
                    param.reproductionParameter.monetary,
                    setFieldValue
                  )
                )}
              </Row>
            </Col>
          ))}
        </Row>
      ));
    },
    [
      isEditing,
      renderParameterEdit,
      renderParameterVisible,
      translation.reproductionParameters.parameters,
    ]
  );

  // Memos

  // Effects
  useEffect(() => {
    fetch();
  }, [fetch]);

  // Methods
  function handleCancel(resetForm) {
    setForm({
      values: [...data],
    });
    resetForm({
      values: [...data],
    });
    setIsEditing((old) => !old);
  }

  async function handleSubmitModalForm(pvalues, actions) {
    const { values } = pvalues;
    let hasErrors = false;
    let errorsObj = {};
    setIsLoadingRequest(true);
    try {
      values.forEach((v) => {
        if (v.value == null || v.value === "") {
          hasErrors = true;
          errorsObj = {
            ...errorsObj,
            [v.reproductionParameter.name]: "O campo está nulo",
          };
        }
      });

      if (hasErrors) {
        actions.setErrors(errorsObj);
        setIsLoadingRequest(false);
      } else {
        const body = {
          values: [
            ...values.map((v) => {
              v.value = `${v.value}`;
              return v;
            }),
          ],
        };
        const {
          data: { results },
        } = await saveReproductionParemeterFarm({
          groupId,
          farmId,
          body,
        });
        setDefaultData(results?.defaultValues);
        setData(results?.farmValues);
        setForm({
          values: [...results?.farmValues],
        });
        actions.resetForm({
          values: [...results?.farmValues],
        });
        setIsEditing(false);
        setIsLoadingRequest(false);
      }
    } catch (error) {
      setIsLoadingRequest(false);
    }
  }

  return (
    <CustomCollapse
      expandIconPosition={"right"}
      expandIcon={(panelProps) =>
        panelProps.isActive ? <FiMinus size={16} /> : <FiPlus size={16} />
      }
    >
      <CustomCollapse.Panel header="Parâmetros" key={"1"}>
        <Spin spinning={isLoadingData || isLoadingRequest}>
          <Row type="flex" justify="end" gutter={8}>
            <Col span={2}>
              <EditButton
                type="button"
                onClick={() => setIsEditing((old) => !old)}
                disabled={isEditing}
              >
                {isEditing
                  ? `${translation.buttons.editing}...`
                  : `${translation.buttons.edit}`}
                <PencilIcon />
              </EditButton>
            </Col>
          </Row>
          <Formik
            enableReinitialize={true}
            initialValues={form}
            initialErrors={{}}
            onSubmit={handleSubmitModalForm}
            render={({
              values,
              errors,
              submitCount,
              setFieldValue,
              handleSubmit,
              resetForm,
            }) => (
              <form autoComplete="off" onSubmit={handleSubmit}>
                {Object.entries(errors).length > 0 && submitCount > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                <Row className="bodyContent">
                  {renderParams(values, errors, submitCount, setFieldValue)}
                </Row>
                {isEditing && (
                  <Footer>
                    <Row type="flex" justify="start">
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        className="buttonsDiv"
                      >
                        <ButtonStandard buttonType="principal" type="submit">
                          {translation.buttons.save}
                        </ButtonStandard>
                        <ButtonStandard
                          type="button"
                          buttonType="secondary"
                          onClick={() => handleCancel(resetForm)}
                        >
                          {translation.buttons.cancel}
                        </ButtonStandard>
                      </Col>
                    </Row>
                  </Footer>
                )}
              </form>
            )}
          />
        </Spin>
      </CustomCollapse.Panel>
    </CustomCollapse>
  );
}

export default CardReproductionParameters;
