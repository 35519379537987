import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { DayBox, Title, Container } from "./styles";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { Creators as PastureActions } from "../../../../store/ducks/pasture";

// Libs
import { Row, Col, Card, Table, Tooltip, Radio } from "antd";
import moment from "moment";

// Components
import TagStatus from "../../../../components/utils/tagStatus";
import InfoTooltip from "../../../../components/utils/infoTooltip";
import DrawerPastureDetails from "../../../../components/drawers/pastureDetails";

// Icons
import AlertIcon from "../../../../components/drawers/alert/icons/alert";
import EyeIcon from "../../../../components/utils/icons/eye";
import RainIcon from "../../../generalParameter/weatherSeasonsParameter/display/icons/rain";
import SunIcon from "../../../generalParameter/weatherSeasonsParameter/display/icons/sun";
import TransitionIcon from "../../../generalParameter/weatherSeasonsParameter/display/icons/transition";

// Services
import { getPicketsWithPastureStatus } from "../../../../services/picketService";
import { numberMask } from "../../../../services/helpersMethodsService";
import { getWeatherSeason } from "../../../../services/generalParameterService";

const PastureStatusDashboard = ({ tab }) => {
  //Redux variables
  const { groupSelected, farmSelected, translation } = useSelector(
    (state) => state.app
  );

  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const [firstDate, setFirstDate] = useState(null);
  const [periodArray, setPeriodArray] = useState([]);
  const [colors, setColors] = useState([]);
  const [picketData, setPicketData] = useState(null);
  const [tableType, setTableType] = useState("DryStock");
  const [periodSeasons, setPeriodSeasons] = useState([]);

  const { Column } = Table;
  const { Group: RadioGroup } = Radio;

  const dispatch = useDispatch();

  //Setup colors
  useEffect(() => {
    function getColors() {
      let arrColors = [
        {
          mainColor: "#14fc03",
          secondaryColor: "#26b718",
          maxValueDryStock: Infinity,
          minValueDryStock: 75.0,
          maxValueOfferRate: Infinity,
          minValueOfferRate: 3.0,
        },
        {
          mainColor: "#d7fc03",
          secondaryColor: "#9db619",
          maxValueDryStock: 74.99,
          minValueDryStock: 50.0,
          maxValueOfferRate: 2.9,
          minValueOfferRate: 2.0,
        },
        {
          mainColor: "#fcba03",
          secondaryColor: "#b78814",
          maxValueDryStock: 49.99,
          minValueDryStock: 25.0,
          maxValueOfferRate: undefined,
          minValueOfferRate: undefined,
        },
        {
          mainColor: "#ff0000",
          secondaryColor: "#a51b0b",
          maxValueDryStock: 24.99,
          minValueDryStock: -999,
          maxValueOfferRate: 1.9,
          minValueOfferRate: -999,
        },
      ];
      setColors(arrColors);
    }

    getColors();
  }, [setColors]);

  //Create an array with every date since today - 15 until today + 30
  useEffect(() => {
    async function createPeriodArray() {
      let today = new Date();
      let lastDate = new Date();
      let firstDate = new Date();

      lastDate.setDate(today.getDate() + 30);
      firstDate.setDate(today.getDate() - 15);

      let startDate = new Date(firstDate);
      let arr = [];

      while (moment(startDate).isSameOrBefore(lastDate)) {
        arr.push(new Date(startDate));
        startDate.setDate(startDate.getDate() + 1);
      }

      //Update state
      setFirstDate(moment(firstDate).format("yyyy-MM-DD"));
      setLastDate(moment(lastDate).format("yyyy-MM-DD"));
      setPeriodArray(arr);

      //Fetch seasons
      try {
        const {
          data: { results },
        } = await getWeatherSeason({ farmId: farmSelected.id });

        let periodSeasons = [];
        let seasons = [];
        if (results.farmSelectedTypeSeason === "Default") {
          seasons = results.seasonsDefault;
        }
        if (results.farmSelectedTypeSeason === "Drier") {
          seasons = results.seasonsDrier;
        }
        if (results.farmSelectedTypeSeason === "Wetter") {
          seasons = results.seasonsWetter;
        }

        arr.forEach((a) => {
          let date = moment(a).format("yyyy-MM-DD");
          periodSeasons.push({
            date: date,
            season: seasons.find((s) => s.month === a.getMonth() + 1).season,
          });
        });

        setPeriodSeasons(periodSeasons);
      } catch (error) {}
    }
    createPeriodArray();
  }, []);

  //Fetch table data
  useEffect(() => {
    async function fetchData() {
      try {
        const {
          data: { results },
        } = await getPicketsWithPastureStatus({
          groupId: groupSelected.id,
          farmId: farmSelected.id,
          startDate: firstDate,
          endDate: lastDate,
        });
        setTableData(results);
        setPicketData(results);
      } finally {
        setIsLoading(false);
      }
    }

    if (firstDate && lastDate) {
      fetchData();
    }
  }, [firstDate, lastDate, groupSelected, farmSelected]);

  //Set inicial focus on today column
  useEffect(() => {
    function focusTodayColumn() {
      let table = document.querySelector(".ant-table-body");
      if (table) {
        table.scrollTo(1195, 0);
      }
    }

    focusTodayColumn();
  }, [picketData]);

  function handleTableTypeChange(type) {
    setTableType(type);
    if (type === "OfferRate") {
      setTableData(picketData.filter((p) => p.picketStatus === "Pasturage"));
    } else {
      setTableData(picketData);
    }
  }

  //Render column title
  function renderColumnTitle(date, title) {
    let season = periodSeasons.find((ps) => ps?.date === date);

    if (season) {
      let icon;
      if (season?.season === "Water") {
        icon = <RainIcon />;
      } else if (season?.season === "Transition") {
        icon = <TransitionIcon />;
      } else {
        icon = <SunIcon />;
      }
      return (
        <div className="columnTitleContainer">
          <div>{icon}</div>
          <div>
            <span>{title}</span>
          </div>
        </div>
      );
    }
  }

  //Render dynamic columns
  function renderPeriodColumns() {
    let today = new Date();
    today = moment(today).format("yyyy-MM-DD").toString();

    return periodArray.map((dayDate) => {
      let className =
        today === moment(dayDate).format("yyyy-MM-DD").toString()
          ? "todayColumn"
          : null;
      let title = moment(dayDate).format("DD/MM");
      let day = moment(dayDate).format("yyyy-MM-DD").toString();
      return (
        <Column
          title={renderColumnTitle(
            moment(dayDate).format("yyyy-MM-DD").toString(),
            title
          )}
          dataIndex="items"
          key={title}
          align="left"
          width={80}
          className={className}
          render={(items, record) => {
            if (tableType === "OfferRate") {
              return renderOfferRateColumns(day, items, record);
            } else {
              return renderDryStockColumns(day, items, record);
            }
          }}
        />
      );
    });
  }

  //Render dynamic columns - OfferRate
  function renderOfferRateColumns(day, items, record) {
    if (items && items.length > 0) {
      let item = items.find((item) => item?.date === day);
      let value = Math.round(item?.value / item?.consumption);
      let color = colors.find(
        (color) =>
          value >= color.minValueOfferRate && value <= color.maxValueOfferRate
      );
      let tooltipText = null;
      if (value >= 2.0 && value < 3.0) {
        tooltipText = "Oferta de MS pode ser insuficiente para o GMD desejado";
      } else if (value < 2.0) {
        tooltipText =
          "Oferta de MS pode não atender as necessidades para o GMD desejado";
      }

      if (item) {
        if (!item.dietStrategyExpired) {
          if (value < 0) {
            //Print a warning on negative values
            return (
              <Tooltip
                title="Não possui estoque de MS para Consumo."
                trigger="hover"
              >
                <DayBox style={{ border: "unset" }}>
                  <AlertIcon />
                </DayBox>
              </Tooltip>
            );
          } else if (value === Infinity) {
            //Print a warning on 0 consumption)
            return (
              <Tooltip title="Não há consumo nesta data" trigger="hover">
                <DayBox style={{ border: "unset" }}>
                  <AlertIcon />
                </DayBox>
              </Tooltip>
            );
          } else {
            return tooltipText ? (
              <Tooltip
                title={
                  <>
                    <div>{tooltipText}</div>
                  </>
                }
                trigger="hover"
              >
                <DayBox
                  style={{
                    backgroundColor: color?.mainColor,
                    borderColor: color?.secondaryColor,
                  }}
                >
                  {value}
                </DayBox>
              </Tooltip>
            ) : (
              <DayBox
                style={{
                  backgroundColor: color?.mainColor,
                  borderColor: color?.secondaryColor,
                }}
              >
                {value}
              </DayBox>
            );
          }
        } else {
          //Diet strategy expired
          return (
            <Tooltip title="Esta estratégia está vencida." trigger="hover">
              <DayBox style={{ border: "unset" }}>
                <AlertIcon />
              </DayBox>
            </Tooltip>
          );
        }
      }

      return <DayBox> {numberMask(0, false)} </DayBox>;
    } else {
      //Pickets not processed
      return (
        <DayBox style={{ border: "unset" }}>
          <InfoTooltip title="Piquete pendente de processamento." />
        </DayBox>
      );
    }
  }

  //Render dynamic columns - DryStock
  function renderDryStockColumns(day, items, record) {
    if (items && items.length > 0) {
      let item = items.find((item) => item?.date === day);
      let value = (item?.value / item?.pastureLimitCapacity) * 100;
      let color = colors.find(
        (color) =>
          value.toFixed(2) >= color.minValueDryStock &&
          value.toFixed(2) <= color.maxValueDryStock
      );

      if (item) {
        if (!item.dietStrategyExpired) {
          if (value > 100.0) {
            //Fixed 100% stock when bigger than 100% stock
            return (
              <Tooltip
                title={
                  <>
                    <div>
                      Consumo estimado: {numberMask(item?.consumption, false)}{" "}
                      Kg/Ms
                    </div>
                    <div>
                      Estoque estimado:{" "}
                      {numberMask(item?.pastureLimitCapacity, false)} Kg/Ms
                    </div>
                    <div>
                      Estoque máximo:{" "}
                      {numberMask(item?.pastureLimitCapacity, false)} Kg/Ms
                    </div>
                  </>
                }
                trigger="hover"
              >
                <DayBox
                  style={{
                    backgroundColor: color?.mainColor,
                    borderColor: color?.secondaryColor,
                  }}
                >
                  {numberMask(100, false) + " %"}
                </DayBox>
              </Tooltip>
            );
          } else if (value <= 0) {
            //Print a warning on negative values
            return (
              <Tooltip
                title="Não possui estoque de MS para Consumo."
                trigger="hover"
              >
                <DayBox style={{ border: "unset" }}>
                  <AlertIcon />
                </DayBox>
              </Tooltip>
            );
          } else {
            return (
              <Tooltip
                title={
                  <>
                    <div>
                      Consumo estimado: {numberMask(item?.consumption, false)}{" "}
                      Kg/Ms
                    </div>
                    <div>
                      Estoque estimado: {numberMask(item?.value, false)} Kg/Ms
                    </div>
                    <div>
                      Estoque máximo:{" "}
                      {numberMask(item?.pastureLimitCapacity, false)} Kg/Ms
                    </div>
                  </>
                }
                trigger="hover"
              >
                <DayBox
                  style={{
                    backgroundColor: color?.mainColor,
                    borderColor: color?.secondaryColor,
                  }}
                >
                  {numberMask(value, false) + " %"}
                </DayBox>
              </Tooltip>
            );
          }
        } else {
          //Diet strategy expired
          return (
            <Tooltip title="Esta estratégia está vencida." trigger="hover">
              <DayBox style={{ border: "unset" }}>
                <AlertIcon />
              </DayBox>
            </Tooltip>
          );
        }
      }

      return <DayBox> {numberMask(0, false)} </DayBox>;
    } else {
      //Pickets not processed
      return (
        <DayBox style={{ border: "unset" }}>
          <InfoTooltip title="Piquete pendente de processamento." />
        </DayBox>
      );
    }
  }

  //Call a drawer with pasture information
  const handleShowPastureDetails = (pastureId) => {
    dispatch(PastureActions.showOrHideDrawerPastureDetails(pastureId));
  };

  //Render table
  return (
    <Container>
      <Card>
        <Row type="flex">
          <Col span={24}>
            <Row type="flex">
              <div>
                <Title>{translation.picket.pastureTable.title}</Title>
                <InfoTooltip
                  title={
                    <div>
                      <p>
                        O Gráfico Estado dos Pastos, apresenta diferentes visões
                        para que possa acompanhar e planejar as movimentações de
                        animais entre piquetes, de forma a otimizar o uso das
                        pastagens.
                      </p>
                      <p>
                        {" "}
                        - Estoque MS: Apresenta a disponibilidade (%) de MS da
                        forrageira utilizada, conforme a estação climática e
                        consumo dos animais presentes no Piquete. Diariamente é
                        executado a rotina de recalculo, para atualizar a
                        estimativa de disponibilidade dos próximos 30 dias.
                      </p>
                      <p>
                        {" "}
                        - Taxa de Oferta: Apresenta a relação de Oferta e
                        Consumo de Matéria Seca (MS) da forrageira disponível no
                        piquete, exibindo a quantidade de dias em que o Lote de
                        animais poderá ficar no piquete, de acordo com o estoque
                        de MS disponível e consumo previsto dos animais.
                      </p>
                    </div>
                  }
                />
              </div>
              <div style={{ marginLeft: "25px" }}>
                <RadioGroup
                  name="type"
                  value={tableType}
                  onChange={(e) => {
                    handleTableTypeChange(e.target.value);
                  }}
                >
                  <Radio value={"DryStock"}>
                    {translation.picket.pastureTable.tableType.dryStockOption}
                  </Radio>
                  <Radio value={"OfferRate"}>
                    {translation.picket.pastureTable.tableType.OfferRateOption}
                  </Radio>
                </RadioGroup>
              </div>
            </Row>
          </Col>
        </Row>
        <Table
          rowKey="picketId"
          loading={isLoading}
          dataSource={tableData != null ? tableData : []}
          pagination={false}
          size="small"
          scroll={{ x: true, y: 350 }}
        >
          <Column
            title={translation.picket.pastureTable.columns.picketName}
            dataIndex="picketName"
            key="name"
            fixed="left"
            width={170}
            align="left"
            render={(picketName, record) => {
              return (
                <div>
                  <span>{picketName}</span>
                  <button
                    className="pastureDetails"
                    type="button"
                    onClick={() => handleShowPastureDetails(record?.pastureId)}
                  >
                    <EyeIcon isActive />
                  </button>
                </div>
              );
            }}
          />
          <Column
            title={translation.picket.pastureTable.columns.status}
            dataIndex="picketStatus"
            key="status"
            fixed="left"
            align="left"
            width={80}
            render={(status, record) => {
              return status === "Break" ? (
                <TagStatus
                  background="#E4F4FF"
                  borderColor="#4A85AE"
                  color="#4A85AE"
                >
                  {translation.picket.status.break}
                </TagStatus>
              ) : status === "Pasturage" ? (
                <TagStatus
                  background="#E1FFE4"
                  borderColor="#106518"
                  color="#106518"
                >
                  {record.productionSubSystem === "I"
                    ? translation.picket.status.active
                    : translation.picket.status.pasturage}
                </TagStatus>
              ) : status === "Renovation" ? (
                <TagStatus
                  background="#FFEAE1"
                  borderColor="#FF4E00"
                  color="#FF4E00"
                >
                  {translation.picket.status.renovation}
                </TagStatus>
              ) : status === "Maintenance" ? (
                <TagStatus
                  background="#FCEEEC"
                  borderColor="#D44C4C"
                  color="#D44C4C"
                >
                  {translation.picket.status.maintenance}
                </TagStatus>
              ) : status === "Leased" ? (
                <TagStatus background="#FE8D2A">
                  {translation.picket.status.leased}
                </TagStatus>
              ) : status === "FarmExclude" ? (
                <TagStatus
                  background="#FBC7C7"
                  borderColor="#D44C4C"
                  color="#D44C4C"
                >
                  {translation.status.inactive}
                </TagStatus>
              ) : (
                <TagStatus
                  background="#FBC7C7"
                  borderColor="#D44C4C"
                  color="#D44C4C"
                >
                  {translation.picket.status.deleted}
                </TagStatus>
              );
            }}
          />
          <Column
            title={translation.picket.pastureTable.columns.days}
            dataIndex="lotDateInOut"
            key="lotDateInOut"
            fixed="left"
            width={100}
            align="left"
            render={(text, record) => (
              <span>
                {record.lotDateInOut
                  ? `${moment().diff(record.lotDateInOut, "days")} dias`
                  : " - "}
              </span>
            )}
          />
          <Column
            title={translation.picket.pastureTable.columns.lotation}
            dataIndex="picketAnimalLotation"
            key="picketAnimalLotation"
            fixed="left"
            width={150}
            align="left"
            render={(text, record) =>
              record?.picketStatus === "Pasturage" ? (
                <span>
                  {`${numberMask(
                    record?.picketAnimalLotation,
                    false
                  )} - ( ${numberMask(
                    (record?.picketAnimalLotation /
                      record?.picketAnimalCapacity) *
                      100,
                    false
                  )}% )`}
                </span>
              ) : null
            }
          />
          {renderPeriodColumns()}
        </Table>
      </Card>
      <DrawerPastureDetails />
    </Container>
  );
};

export default withRouter(PastureStatusDashboard);
