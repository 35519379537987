import { call, put } from "redux-saga/effects";
import { Creators as HandlingOperationActions } from "../ducks/handlingOperation";
/** Services */
import {
  getHandlingOperationIndex,
  getHandlingOperationShow,
  saveHandlingOperation,
  deleteHandlingOperationServ
} from "../../services/handlingOperationService";
import { notification } from "antd";

export function* indexHandlingOperation(action) {
  try {
    const {
      data: { results: allHandlingOperations }
    } = yield call(getHandlingOperationIndex, action.payload);
    yield put(
      HandlingOperationActions.indexHandlingOperationSuccess(
        allHandlingOperations
      )
    );
  } catch (error) {
    yield put(HandlingOperationActions.indexHandlingOperationError(error));
  }
}

export function* getHandlingOperation(action) {
  try {
    const {
      data: { results: allHandlingOperations }
    } = yield call(getHandlingOperationShow, action.payload);
    yield put(
      HandlingOperationActions.showHandlingOperationSuccess(
        allHandlingOperations
      )
    );
  } catch (error) {
    yield put(HandlingOperationActions.showHandlingOperationError(error));
  }
}

export function* storeOrUpdateHandlingOperation(action) {
  try {
    const {
      data: { results: newHandlingOperation }
    } = yield call(saveHandlingOperation, action.payload);
    const { saveOptions } = action.payload;
    yield put(
      HandlingOperationActions.saveHandlingOperationSuccess(
        newHandlingOperation,
        saveOptions
      )
    );
    if (action.payload.id == null)
      notification.success({ message: "Operação criado com sucesso" });
    else notification.success({ message: "Operação atualizado com sucesso" });
  } catch (error) {
    notification.error({ message: "Operação não foi criado" });
    yield put(HandlingOperationActions.saveHandlingOperationError(error));
  }

  try {
    const newPayload = {
      ...action.payload,
      page: 1,
      sorter: {},
      filters: "",
      withoutPagination: false
    };
    const {
      data: { results: allHandlingOperations }
    } = yield call(getHandlingOperationIndex, newPayload);
    yield put(
      HandlingOperationActions.indexHandlingOperationSuccess(
        allHandlingOperations
      )
    );
  } catch (error) {
    yield put(HandlingOperationActions.indexHandlingOperationError(error));
  }
}

export function* deleteHandlingOperation(action) {
  try {
    const { data } = yield call(deleteHandlingOperationServ, action.payload);
    yield put(HandlingOperationActions.deleteHandlingOperationSuccess(data));
    notification.success({
      message: "Operação inativado com sucesso"
    });
  } catch (error) {
    notification.error({ message: "Operação não foi inativado" });
    yield put(HandlingOperationActions.deleteHandlingOperationError(error));
  }
}
