import styled from "styled-components";

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
  span {
    font-family: Asap;
    font-weight: bold;
    font-size: 16px;
    color: #4b4b4b;
  }
  .name {
    font-family: Asap;
    font-size: 20px;
    color: #FE8D2A;
    margin: 0px;
  }
`;

export const Body = styled.div`
  margin-top: 10px;
  li {
    font-family: Asap;
    font-size: 14px;
    color: #4b4b4b;
    margin: 5px;
  }

`;

export const Footer = styled.div`
  margin: 0px 0px -24px 0px;
  div.buttonContainer {
    display: flex;  
  }
`;

export const ButtonNavigation = styled.button`
  display: flex;
  height: 25px;
  align-items: center;
  font-family: Asap;
  font-size: 14px;
  text-align: left;
  color: #684e94;
  border: none;
  background: transparent;
  cursor: pointer;

  &.cancel {
    color: #D44C4C;
  }

  &:hover {
    &.duplicate {
      font-weight: bold;
    }
    &.cancel {
      font-weight: bold;
    }
  }

`;


