import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { InseminatorContextProvider } from "../../contexts/inseminatorContext";
import useInseminatorContext from "../../hooks/useInseminatorContext";
import {
  Button,
  Col,
  Dropdown,
  Icon,
  Input,
  Menu,
  notification,
  Row,
  Table,
} from "antd";
import ButtonStandard from "../../components/utils/button";
import { CardCustom, Title } from "./styles";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import MenuIcon from "../../components/utils/table/icons/menu";
import DrawerInseminator from "../../components/drawers/inseminator";
import { activate, inactivate } from "../../services/inseminatorService";
import TagStatus from "../../components/utils/tagStatus";

const InseminatorLayout = () => {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { dataList, isLoadingDataList, openDrawer, fetchData } =
    useInseminatorContext();

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const menu = (id, record) => {
    return (
      <Menu>
        {record.status === "Active" && (
          <Menu.Item key="1" onClick={() => handleEdit(record)}>
            {translation.table.menu.edit}
          </Menu.Item>
        )}
        {record.status === "Active" && (
          <Menu.Item key="2" onClick={() => handleInactive(id)}>
            {translation.table.menu.inactivate}
          </Menu.Item>
        )}
        {record.status === "Inactive" && (
          <Menu.Item key="3" onClick={() => handleActivate(id)}>
            {translation.table.menu.activate}
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const handleEdit = useCallback(
    (record) => {
      openDrawer(record);
    },
    [openDrawer]
  );

  const handleInactive = useCallback(
    async (id) => {
      try {
        await inactivate({ groupId, farmId, id });
        notification.success({ message: "Inseminador inativado com sucesso." });
        fetchData();
      } catch (error) {
        const {
          response: {
            data: { code: errorCode },
          },
        } = error;
        if (errorCode === 5023) {
          notification.error({
            message: "Inseminador não foi inativado.",
            description:
              "Já existe uma(s) cobertura(s) ligadas a este inseminador.",
          });
        } else {
          notification.error({
            message: "Inseminador não foi inativado. Contate o suporte.",
          });
        }
      }
    },
    [groupId, farmId, fetchData]
  );

  const handleActivate = useCallback(
    async (id) => {
      try {
        await activate({ groupId, farmId, id });
        notification.success({ message: "Inseminador ativado com sucesso." });
        fetchData();
      } catch (error) {
        notification.error({
          message: "Inseminador não foi ativado. Contate o suporte.",
        });
      }
    },
    [groupId, farmId, fetchData]
  );

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return record[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    }),
    [handleReset, handleSearch]
  );

  const handleOpenDrawer = () => {
    openDrawer();
  };

  return (
    <CardCustom>
      <Row className="rowHeader" justify="space-between">
        <Col span={8}>
          <Title>{translation.inseminator.title}</Title>
        </Col>
        <Col span={16}>
          <Row type="flex" justify="end" gutter={8}>
            <Col>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={fetchData}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </Col>
            <Col>
              <ButtonStandard buttonType="type8" onClick={handleOpenDrawer}>
                {translation.inseminator.buttonNewInseminator}
              </ButtonStandard>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <Table
            loading={isLoadingDataList}
            rowKey="id"
            size="small"
            dataSource={dataList}
            scroll={{
              x: true,
            }}
            pagination={{
              hideOnSinglePage:
                dataList !== null && dataList.length > 10 ? false : true,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
            }}
          >
            <Table.Column
              title={translation.inseminator.table.columns.name}
              dataIndex="name"
              sorter={(first, second) => {
                return first.name.localeCompare(second.name, "pt-BR", {
                  numeric: false,
                  ignorePunctuation: true,
                });
              }}
              key="name"
              {...handleGetColumnSearchProps("name")}
            />
            <Table.Column
              title={translation.inseminator.table.columns.code}
              dataIndex="code"
              sorter={(first, second) => {
                if (!first.code) {
                  first.code = "";
                }
                return first.code.localeCompare(second.code, "pt-BR", {
                  numeric: false,
                  ignorePunctuation: true,
                });
              }}
              key="code"
              {...handleGetColumnSearchProps("code")}
            />
            <Table.Column
              title={translation.inseminator.table.columns.status}
              dataIndex="status"
              key="status"
              align="left"
              sorter={(first, second) => {
                return first.status.localeCompare(second.status, "pt-BR", {
                  numeric: false,
                  ignorePunctuation: true,
                });
              }}
              defaultFilteredValue={["Active"]}
              filtered
              filters={[
                {
                  text: translation.status.active,
                  value: "Active",
                },
                {
                  text: translation.status.inactive,
                  value: "Inactive",
                },
              ]}
              filterMultiple
              onFilter={(value, record) => record.status.includes(value)}
              render={(status) =>
                status === "Active" ? (
                  <TagStatus
                    background="#C5F1CA"
                    borderColor="#106518"
                    color="#106518"
                  >
                    {translation.status.active}
                  </TagStatus>
                ) : (
                  <TagStatus
                    background="#FBC7C7"
                    borderColor="#D44C4C"
                    color="#D44C4C"
                  >
                    {translation.status.inactive}
                  </TagStatus>
                )
              }
            />
            <Table.Column
              title=""
              align="left"
              render={(text, record) => (
                <Dropdown
                  overlay={menu(record.id, record)}
                  trigger={["click"]}
                  key={record.id}
                >
                  <MenuContainer>
                    <MenuIcon />
                  </MenuContainer>
                </Dropdown>
              )}
            />
          </Table>
        </Col>
      </Row>
    </CardCustom>
  );
};

const Inseminator = () => {
  return (
    <InseminatorContextProvider>
      <InseminatorLayout />
      <DrawerInseminator />
    </InseminatorContextProvider>
  );
};

export default Inseminator;
