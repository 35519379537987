import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
// Components
import { Col, Icon, Row } from "antd";
import { Title, Message, AlertModal, ButtonSession } from "./styles";

function RoutingLeavingGuardModal({ when, navigate, title, message }) {
  // Variables
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [isConfirmedNavigation, setIsConfirmedNavigation] = useState(false);
  // Redux
  const { translation } = useSelector((state) => state.app);
  // Effects
  useEffect(() => {
    if (isConfirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [isConfirmedNavigation, lastLocation, navigate]);

  // Methods
  const closeModal = () => {
    setIsModalVisible(false);
  };
  const handleBlockedNavigation = (nextLocation) => {
    if (!isConfirmedNavigation) {
      setIsModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    setIsModalVisible(false);
    setIsConfirmedNavigation(true);
  };
  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <AlertModal
        visible={isModalVisible}
        width={468}
        footer={null}
        centered
        maskClosable={false}
        closable={false}
      >
        <Row justify="start">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Title>
              <Icon
                type="question-circle"
                style={{ color: "#4A85AE", fontSize: 24 }}
              />
              <strong style={{ color: "#000" }}>
                {title || "Você realmente deseja sair desta tela ?"}
              </strong>
            </Title>
          </Col>
        </Row>
        <Row justify="start" style={{ marginTop: 8 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Message>{message}</Message>
          </Col>
        </Row>
        <Row justify={"space-between"} style={{ marginTop: 21 }}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} align="left">
            <ButtonSession className="red" onClick={closeModal}>
              {translation.buttons.cancel}
            </ButtonSession>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
            <ButtonSession onClick={handleConfirmNavigationClick}>
              {translation.buttons.confirm}
            </ButtonSession>
          </Col>
        </Row>
      </AlertModal>
    </>
  );
}

export default RoutingLeavingGuardModal;
