import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidV4 } from "uuid";
import axios from "axios";
import { Formik } from "formik";
import moment from "moment";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import usePicketSupplementSupplyContext from "../../../hooks/usePicketSupplementSupplyContext";

// Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Icon,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tooltip,
  notification,
} from "antd";

import ButtonStandard from "../../utils/button";
import { Container, Footer } from "./styles";
import NumberFormat from "react-number-format";

// Services
import { getOnlyActives } from "../../../services/retreatService";
import { getPicketIndexDropDown } from "../../../services/picketService";
import { getSupplementIndexDropDown } from "../../../services/supplementService";
import {
  findAllBySupplyDate,
  findBySupplyDateAndSupplyNumber,
  save,
} from "../../../services/picketSupplementSupplyService";

const validationSchema = Yup.object({
  supplyDate: Yup.string().required(),
  supplyNumber: Yup.string().required(),
});

const DEFAULT_FORM_STATE_VALUES = {
  id: null,
  supplyDate: moment(),
  supplyNumber: null,
  responsibleName: null,
  status: "Active",
  pickets: [],
};

const DrawerPicketSupplementSupply = () => {
  const formRef = useRef(null);

  const [saveOptions, setSaveOptions] = useState("save_and_create_new");
  const [lastSuppliesOnDate, setLastSuppliesOnDate] = useState([]);
  const [form, setForm] = useState(DEFAULT_FORM_STATE_VALUES);
  const [dateSupply, setDateSupply] = useState(moment());
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  const [isLoadingPicketList, setIsLoadingPicketList] = useState(false);
  const [allPickets, setAllPickets] = useState([]);
  const [picketsFiltered, setPicketsFiltered] = useState([]);
  const [supplementsFiltered, setSupplementsFiltered] = useState([]);
  const [filterPicketsIdSelected, setFilterPicketsIdSelected] = useState([]);

  const [isLoadingRetreatList, setIsLoadingRetreatList] = useState(false);
  const [allRetreats, setAllRetreats] = useState([]);
  const [filterRetreatIdSelected, setFilterRetreatIdSelected] = useState([]);

  const [isLoadingSupplementsList, setIsLoadingSupplementsList] =
    useState(false);
  const [allSupplements, setAllSupplements] = useState([]);

  const [filterSubSystemSelected, setFilterSubSystemSelected] = useState([]);
  const [defaultQuantitySupplied, setDefaultQuantitySupplied] = useState(null);
  const [defaultSupplement, setDefaultSupplement] = useState(null);

  const signal = axios.CancelToken.source();

  const allSubSystems = [
    {
      id: "E",
      label: "extensive",
    },
    { id: "SI", label: "semiIntensive" },
    { id: "I", label: "intensive" },
  ];

  // Redux Variable
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const { isDrawerVisible, data, closeDrawer, fetchData } =
    usePicketSupplementSupplyContext();

  const selectPicketsList = useMemo(() => {
    let pickets = allPickets;
    if (allRetreats.length > 0 && filterRetreatIdSelected.length > 0) {
      return allRetreats
        .filter((retreat) => filterRetreatIdSelected.includes(retreat.id))
        .flatMap((retreat) => retreat.pickets)
        .filter(
          (picket) => picket.status === "Pasturage" || picket.status === "P"
        );
    }
    return pickets;
  }, [allPickets, allRetreats, filterRetreatIdSelected]);

  // Method

  const incrementPicketArrayWithPreviousSupplyData = useCallback(
    async (newPickets, date) => {
      let newPicketsWithPreviousData = newPickets;
      try {
        const {
          data: { results: lastSuppliesOnDate },
        } = await findAllBySupplyDate({
          groupId,
          farmId,
          supplyDate: moment(date).toDate(),
        });

        setLastSuppliesOnDate(lastSuppliesOnDate);

        const lastSupplyOnDate =
          lastSuppliesOnDate[lastSuppliesOnDate.length - 1];

        const {
          data: { results: lastSupplyOnDateMinusAndNumber },
        } = await findBySupplyDateAndSupplyNumber({
          groupId,
          farmId,
          supplyDate: moment(date).subtract(1, "days").toDate(),
          supplyNumber:
            lastSuppliesOnDate.length > 0
              ? lastSupplyOnDate.supplyNumber + 1
              : 1,
        });

        if (lastSupplyOnDateMinusAndNumber) {
          newPicketsWithPreviousData = newPickets.map((picket) => {
            const previousPicketSupplyIndex =
              lastSupplyOnDateMinusAndNumber.pickets.findIndex(
                (supply) => supply.picketId === picket.picketId
              );
            if (previousPicketSupplyIndex >= 0) {
              picket.previousQuantitySupplied =
                lastSupplyOnDateMinusAndNumber.pickets[
                  previousPicketSupplyIndex
                ].quantitySupplied;
            }
            return picket;
          });
        }

        if (formRef.current) {
          formRef.current.setFieldValue(
            "supplyNumber",
            lastSuppliesOnDate.length > 0
              ? lastSupplyOnDate.supplyNumber + 1
              : 1
          );
          formRef.current.setFieldValue("pickets", newPicketsWithPreviousData);
        }
      } catch (error) {
        console.error(error);
        if (formRef.current) {
          formRef.current.setFieldValue("pickets", newPicketsWithPreviousData);
        }
      } finally {
        setIsLoadingRequest(false);
      }
    },
    [formRef, groupId, farmId]
  );

  function cleanFields(shouldCleanPickets) {
    setForm(DEFAULT_FORM_STATE_VALUES);
    formRef.current.resetForm(DEFAULT_FORM_STATE_VALUES);
    setDateSupply(moment());
    setPicketsFiltered([]);

    setFilterRetreatIdSelected([]);
    setFilterPicketsIdSelected([]);
    setFilterSubSystemSelected([]);

    setDefaultQuantitySupplied(null);
    setDefaultSupplement(null);
  }

  function validatePickets(pickets) {
    let errorMessage = "";
    if (pickets.length === 0)
      errorMessage = "Nenhum piquete informado a oferta";
    if (
      pickets.filter((picket) => picket.quantitySupplied === null).length ===
      pickets.length
    )
      errorMessage = "É necessário informar ao menos uma oferta.";

    if (
      pickets.filter(
        (picket) =>
          picket.quantitySupplied != null &&
          (picket.supplementId === null || picket.supplementId === undefined)
      ).length > 0
    )
      errorMessage =
        "É necessário informar um suplemento para todos os piquetes com Qtd. Ofertada preenchida.";

    if (!!errorMessage)
      notification.error({
        message: errorMessage,
      });

    return !!errorMessage;
  }

  async function getAllPickets() {
    setIsLoadingPicketList(true);
    try {
      const {
        data: { results },
      } = await getPicketIndexDropDown({ groupId, farmId, signal });
      const filterPicketsActiveOrPasturage = results.filter(
        (picket) =>
          (picket.status === "Pasturage" || picket.status === "P") &&
          picket.lotAnimalsCount > 0
      );
      setAllPickets(filterPicketsActiveOrPasturage);
      setPicketsFiltered(filterPicketsActiveOrPasturage);
    } catch (error) {
    } finally {
      setIsLoadingPicketList(false);
    }
  }

  async function getAllRetreats() {
    setIsLoadingRetreatList(true);
    try {
      const {
        data: { results },
      } = await getOnlyActives({ groupId, farmId, signal });
      setAllRetreats(results);
    } catch (error) {
    } finally {
      setIsLoadingRetreatList(false);
    }
  }

  async function getAllSupplements() {
    setIsLoadingSupplementsList(true);
    try {
      const {
        data: { results },
      } = await getSupplementIndexDropDown({ groupId, farmId, signal });
      setAllSupplements(results);
    } catch (error) {
    } finally {
      setIsLoadingSupplementsList(false);
    }
  }

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys ? selectedKeys[0] : undefined}
            placeholder="Digite sua pesquisa"
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return record[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    }),
    [handleReset, handleSearch]
  );

  function handleCloseDrawer() {
    cleanFields();
    signal.cancel();
    closeDrawer();
  }

  function handleSubmitForm(values) {
    if (!validatePickets(values.pickets)) handleSave(values);
  }

  async function handleSave(bodyP) {
    setIsLoadingRequest(true);
    try {
      const body = {
        ...bodyP,
        pickets: bodyP.pickets.filter(
          (picket) => picket.quantitySupplied !== null
        ),
      };
      await save({ groupId, farmId, id: body?.id, body });
      notification.success({
        message: "Fornecimento cadastrado/atualizado com sucesso.",
      });
      fetchData();
      if (saveOptions === "save_and_create_new") {
        const newPickets = picketsFiltered.map((picket) => {
          let supplementIdSuggested = null;
          let dryMatterPercentageSuggested = null;
          let permitedSupplementsIds = [];
          if (picket.dietStrategy !== null) {
            const dietPeriods = picket.dietStrategy.dietPeriods || [];
            permitedSupplementsIds = dietPeriods.map(
              (period) => period.supplement?.id
            );
            const periodSelectedIndex = dietPeriods.findIndex((period) =>
              moment(dateSupply).isBetween(
                moment(period.startDate),
                moment(period.endDate),
                "days",
                "[]"
              )
            );
            if (periodSelectedIndex >= 0) {
              const period = dietPeriods[periodSelectedIndex];
              supplementIdSuggested = period.supplement?.id;
              dryMatterPercentageSuggested =
                period.supplement?.dryMatterPercentage;
            }
          }
          setSupplementsFiltered((old) => [
            ...new Set([...old, ...permitedSupplementsIds]),
          ]);
          return {
            id: uuidV4(),
            picketId: picket.id,
            productionSubSystem: picket.productionSubSystem,
            picketName: picket.name,
            lotName: picket.lotName,
            animalsCount: picket.lotAnimalsCount,
            previousQuantitySupplied: null,
            quantitySupplied: null,
            supplementId: supplementIdSuggested,
            dryMatterPercentage: dryMatterPercentageSuggested,
            permitedSupplementsIds,
            observation: null,
          };
        });
        incrementPicketArrayWithPreviousSupplyData(newPickets, dateSupply);
        setTimeout(() => setIsLoadingRequest(false), 500);
      } else {
        setIsLoadingRequest(false);
        handleCloseDrawer();
      }
    } catch (error) {
      setIsLoadingRequest(false);
      notification.error({
        message: "Erro",
        description: "Erro ao cadastrar/editar fornecimento.",
      });
    }
  }

  async function handleDrawerVisible(visible) {
    if (visible) {
      setSaveOptions("save_and_create_new");
      setIsLoadingRequest(true);
      await Promise.all([
        getAllPickets(),
        getAllRetreats(),
        getAllSupplements(),
      ]);
    }
  }

  function handleApplyFilter() {
    let newPickets = allPickets;
    if (filterPicketsIdSelected.length > 0) {
      newPickets = newPickets.filter((picket) =>
        filterPicketsIdSelected.includes(picket.id)
      );
    } else if (filterRetreatIdSelected.length > 0) {
      if (allRetreats.length > 0 && filterRetreatIdSelected.length > 0) {
        const retreatPicketIds = allRetreats
          .filter((retreat) => filterRetreatIdSelected.includes(retreat.id))
          .flatMap((retreat) => retreat.pickets)
          .map((picket) => picket.id);
        newPickets = newPickets.filter((picket) =>
          retreatPicketIds.includes(picket.id)
        );
      }
    }

    if (filterSubSystemSelected.length > 0) {
      newPickets = newPickets.filter((picket) =>
        filterSubSystemSelected.includes(picket.productionSubSystem)
      );
    }

    setPicketsFiltered(newPickets);
  }

  function handleApplyDefaultFieldValues(setFieldValue, picketsValues) {
    const shouldUpdateWeight = !!defaultQuantitySupplied;
    const shouldUpdateSupplement = !!defaultSupplement;

    const newPicketsValues = picketsValues.map((picket) => {
      if (shouldUpdateWeight && !!picket.quantitySupplied === false) {
        picket.quantitySupplied = defaultQuantitySupplied;
      }
      if (shouldUpdateSupplement && !!picket.supplementId === false) {
        const dryMatterPercentage = allSupplements.find(
          (supplement) => supplement.id === defaultSupplement
        ).dryMatterPercentage;
        picket.supplementId = defaultSupplement;
        picket.dryMatterPercentage = dryMatterPercentage;
      }
      return picket;
    });
    setFieldValue("pickets", newPicketsValues);
  }

  const handleChangeValuesTable = useCallback(
    (setFieldValue, field, newValue, picketsValues, id) => {
      const newPicketsValues = picketsValues.map((picket) => {
        if (picket.id === id) {
          picket[field] = newValue;
        }
        return picket;
      });
      setFieldValue("pickets", newPicketsValues);
    },
    []
  );

  useEffect(() => {
    if (data !== null) {
      setForm(data);
    }
  }, [data]);

  useEffect(() => {
    if (formRef.current && picketsFiltered.length > 0) {
      setIsLoadingRequest(true);
      const newPickets = picketsFiltered.map((picket) => {
        let supplementIdSuggested = null;
        let dryMatterPercentageSuggested = null;
        let permitedSupplementsIds = [];
        if (picket.dietStrategy !== null) {
          const dietPeriods = picket.dietStrategy.dietPeriods || [];
          permitedSupplementsIds = dietPeriods.map(
            (period) => period.supplement?.id
          );
          const periodSelectedIndex = dietPeriods.findIndex((period) =>
            moment(dateSupply).isBetween(
              moment(period.startDate),
              moment(period.endDate),
              "days",
              "[]"
            )
          );
          if (periodSelectedIndex >= 0) {
            const period = dietPeriods[periodSelectedIndex];
            supplementIdSuggested = period.supplement?.id;
            dryMatterPercentageSuggested =
              period.supplement?.dryMatterPercentage;
          }
        }
        setSupplementsFiltered((old) => [
          ...new Set([...old, ...permitedSupplementsIds]),
        ]);
        return {
          id: uuidV4(),
          picketId: picket.id,
          productionSubSystem: picket.productionSubSystem,
          picketName: picket.name,
          lotName: picket.lotName,
          animalsCount: picket.lotAnimalsCount,
          previousQuantitySupplied: null,
          quantitySupplied: null,
          supplementId: supplementIdSuggested,
          dryMatterPercentage: dryMatterPercentageSuggested,
          permitedSupplementsIds,
          observation: null,
        };
      });
      if (dateSupply != null) {
        incrementPicketArrayWithPreviousSupplyData(newPickets, dateSupply);
      } else {
        if (formRef.current)
          formRef.current.setFieldValue("pickets", newPickets);
        setTimeout(() => setIsLoadingRequest(false), 1000);
      }
    } else if (picketsFiltered.length === 0) {
      if (formRef.current) {
        formRef.current.setFieldValue("pickets", []);
      }
    }
  }, [picketsFiltered, dateSupply, formRef]);

  return (
    <Container
      title={
        data?.id == null
          ? translation.picketSupplementSupply.form.titleCreateNew
          : translation.picketSupplementSupply.form.titleEdit
      }
      width={"90vw"}
      onClose={handleCloseDrawer}
      maskClosable={false}
      visible={isDrawerVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Spin spinning={isLoadingRequest}>
        <Formik
          ref={formRef}
          enableReinitialize={true}
          initialValues={form}
          onSubmit={handleSubmitForm}
          validationSchema={validationSchema}
          render={({
            values,
            errors,
            submitCount,
            setFieldValue,
            handleSubmit,
          }) => (
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className="drawerForm">
                {Object.entries(errors).length > 0 && submitCount > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                <Row type="flex" gutter={8}>
                  <Col span={4}>
                    {/* supplyDate */}
                    <Row type="flex" className="rowLabel">
                      <label
                        className={
                          errors.supplyDate && submitCount > 0 ? "error" : ""
                        }
                      >
                        {translation.picketSupplementSupply.form.supplyDate}*
                      </label>
                    </Row>
                    <Row type="flex">
                      <DatePicker
                        name="supplyDate"
                        value={values.supplyDate}
                        allowClear={false}
                        format={"DD/MM/YYYY"}
                        disabledDate={(current) => current.isAfter(moment())}
                        onChange={(date) => {
                          setFieldValue("supplyDate", date);
                          setDateSupply(date);
                        }}
                      />
                    </Row>
                  </Col>
                  <Col span={4}>
                    {/* supplyNumber */}
                    <Row type="flex" className="rowLabel">
                      <label
                        className={
                          errors.supplyNumber && submitCount > 0 ? "error" : ""
                        }
                      >
                        {translation.picketSupplementSupply.form.supplyNumber}*
                      </label>
                    </Row>
                    <Row type="flex">
                      <Input
                        name="supplyNumber"
                        maxLength={30}
                        max={30}
                        disabled
                        value={values.supplyNumber}
                        placeholder={translation.defaultPlaceholder}
                        autoCapitalize="off"
                        onChange={(e) => {
                          setFieldValue("supplyNumber", e.target.value);
                        }}
                      />
                    </Row>
                  </Col>
                  <Col span={8}>
                    {/* responsibleName */}
                    <Row type="flex" className="rowLabel">
                      <label>
                        {
                          translation.picketSupplementSupply.form
                            .responsibleName
                        }
                      </label>
                    </Row>
                    <Row type="flex">
                      <Input
                        name="responsibleName"
                        maxLength={30}
                        max={30}
                        value={values.responsibleName || undefined}
                        placeholder={translation.defaultPlaceholder}
                        autoCapitalize="off"
                        onChange={(e) => {
                          setFieldValue("responsibleName", e.target.value);
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
                <Divider />
                {/* Filters */}
                <Row type="flex" justify="center" align="middle">
                  <Col>
                    <h2
                      style={{
                        color: "#9074bf",
                        marginBottom: 8,
                      }}
                    >
                      Filtros <Icon type="filter" />
                    </h2>
                  </Col>
                </Row>
                <Row type="flex" gutter={8} align="middle">
                  <Col span={6}>
                    {/* retreats */}
                    <Row type="flex" className="rowLabel">
                      <label>
                        {translation.picketSupplementSupply.form.retreats}
                      </label>
                    </Row>
                    <Row type="flex">
                      <Select
                        name="filterRetreatIdSelected"
                        value={filterRetreatIdSelected || undefined}
                        loading={isLoadingRetreatList}
                        allowClear
                        mode="multiple"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder={translation.defaultSelectPlaceholder}
                        onChange={(value) => {
                          setFilterRetreatIdSelected(value);
                          setFilterPicketsIdSelected([]);
                        }}
                      >
                        {allRetreats.map((retreat) => (
                          <Select.Option key={retreat.id} value={retreat.id}>
                            {retreat.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Row>
                  </Col>
                  <Col span={6}>
                    {/* pickets */}
                    <Row type="flex" className="rowLabel">
                      <label>
                        {translation.picketSupplementSupply.form.pickets}
                      </label>
                    </Row>
                    <Row type="flex">
                      <Select
                        name="filterPicketsIdSelected"
                        value={filterPicketsIdSelected || undefined}
                        loading={isLoadingPicketList}
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        mode="multiple"
                        placeholder={translation.defaultSelectPlaceholder}
                        onChange={(value) => setFilterPicketsIdSelected(value)}
                      >
                        {selectPicketsList.map((picket) => (
                          <Select.Option key={picket.id} value={picket.id}>
                            {picket.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Row>
                  </Col>
                  <Col span={6}>
                    {/* subSystems */}
                    <Row type="flex" className="rowLabel">
                      <label>
                        {translation.picketSupplementSupply.form.subSystems}
                      </label>
                    </Row>
                    <Row type="flex">
                      <Select
                        name="filterSubSystemSelected"
                        value={filterSubSystemSelected}
                        loading={isLoadingPicketList}
                        allowClear
                        mode="multiple"
                        placeholder={translation.defaultSelectPlaceholder}
                        onChange={(value) => setFilterSubSystemSelected(value)}
                      >
                        {allSubSystems.map((subSystem) => (
                          <Select.Option
                            key={subSystem.id}
                            value={subSystem.id}
                          >
                            {translation.productionSubSystem[subSystem.label]}
                          </Select.Option>
                        ))}
                      </Select>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row type="flex" justify="center">
                      <Tooltip title="Ao clicar em Aplicar, os dados da seção 'Fornecimento' serão sobreescritos pelos os piquetes filtrados.">
                        <ButtonStandard
                          tabIndex="-1"
                          type={"button"}
                          buttonType={"secundary"}
                          onClick={handleApplyFilter}
                        >
                          <Icon type="thunderbolt" /> Aplicar
                        </ButtonStandard>
                      </Tooltip>
                    </Row>
                  </Col>
                </Row>
                <Divider />
                {/* Speeders */}
                <Row type="flex" justify="center" align="middle">
                  <Col>
                    <h2
                      style={{
                        color: "#9074bf",
                        marginBottom: 8,
                      }}
                    >
                      Aceleradores <Icon type="snippets" />
                    </h2>
                  </Col>
                </Row>
                <Row type="flex" gutter={8} align="middle">
                  <Col span={6}>
                    {/* retreats */}
                    <Row type="flex" className="rowLabel">
                      <label>
                        {
                          translation.picketSupplementSupply.form
                            .quantitySupplied
                        }
                      </label>
                    </Row>
                    <Row type="flex">
                      <NumberFormat
                        customInput={Input}
                        value={defaultQuantitySupplied || ""}
                        name="defaultQuantitySupplied"
                        decimalScale={2}
                        placeholder={translation.defaultPlaceholder}
                        addonAfter="Kg"
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale={true}
                        onValueChange={({ floatValue }) =>
                          setDefaultQuantitySupplied(
                            floatValue
                              ? floatValue > 10000
                                ? 10000
                                : floatValue
                              : null
                          )
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={6}>
                    {/* pickets */}
                    <Row type="flex" className="rowLabel">
                      <label>
                        {translation.picketSupplementSupply.form.supplement}
                      </label>
                    </Row>
                    <Row type="flex">
                      <Select
                        name="defaultSupplement"
                        value={defaultSupplement || undefined}
                        loading={isLoadingSupplementsList}
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder={translation.defaultSelectPlaceholder}
                        onChange={(value) => setDefaultSupplement(value)}
                      >
                        {allSupplements
                          .filter((supplement) =>
                            supplementsFiltered.length === 0
                              ? true
                              : supplementsFiltered.includes(supplement.id)
                          )
                          .map((supplement) => (
                            <Select.Option
                              key={supplement.id}
                              value={supplement.id}
                            >
                              {supplement.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Row>
                  </Col>
                  <Col span={6}></Col>
                  <Col span={6}>
                    <Row type="flex" justify="center">
                      <Tooltip title="Ao clicar em Aplicar, os campos da seção 'Fornecimento' que estiverem vazios serão sobreescritos pelos os valores escolhidos nos campos ao lado.">
                        <ButtonStandard
                          tabIndex="-1"
                          type={"button"}
                          buttonType={"secundary"}
                          onClick={() =>
                            handleApplyDefaultFieldValues(
                              setFieldValue,
                              values.pickets
                            )
                          }
                        >
                          <Icon type="thunderbolt" /> Aplicar
                        </ButtonStandard>
                      </Tooltip>
                    </Row>
                  </Col>
                </Row>
                <Divider />
                <Row type="flex" justify="center" align="middle">
                  <Col>
                    <h2
                      style={{
                        color: "#9074bf",
                        marginBottom: 8,
                      }}
                    >
                      Fornecimento <Icon type="form" />
                    </h2>
                  </Col>
                </Row>
                <Row type="flex">
                  <Col span={24}>
                    <Table
                      dataSource={values.pickets || []}
                      pagination={false}
                      rowKey={"id"}
                    >
                      <Table.Column
                        dataIndex="picketName"
                        title="Piquete/Baia"
                        sorter={(first, second) => {
                          return first.picketName.localeCompare(
                            second.picketName,
                            "pt-BR",
                            {
                              numeric: false,
                              ignorePunctuation: true,
                            }
                          );
                        }}
                        filtered
                        {...handleGetColumnSearchProps("picketName")}
                      />
                      <Table.Column
                        dataIndex="lotName"
                        title="Lote"
                        sorter={(first, second) => {
                          return first.lotName.localeCompare(
                            second.lotName,
                            "pt-BR",
                            {
                              numeric: false,
                              ignorePunctuation: true,
                            }
                          );
                        }}
                        filtered
                        {...handleGetColumnSearchProps("lotName")}
                      />
                      <Table.Column
                        dataIndex="animalsCount"
                        title="Qtd. Animais"
                        width={130}
                      />
                      <Table.Column
                        dataIndex="quantitySupplied"
                        title="Qtd. Ofertada"
                        align="center"
                        width={300}
                        render={(text, record) => (
                          <Row type="flex" gutter={8} justify="center">
                            <Col>
                              <Row type="flex" justify="center">
                                <strong>Anterior</strong>
                              </Row>
                              <Row>
                                <NumberFormat
                                  style={{ width: 130 }}
                                  value={record.previousQuantitySupplied || ""}
                                  customInput={Input}
                                  disabled
                                  decimalScale={2}
                                  placeholder={translation.defaultPlaceholder}
                                  addonAfter="Kg"
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  fixedDecimalScale={true}
                                />
                              </Row>
                            </Col>
                            <Col>
                              <Row type="flex" justify="center">
                                <strong>Atual</strong>
                              </Row>
                              <Row>
                                <NumberFormat
                                  style={{ width: 130 }}
                                  customInput={Input}
                                  value={text || ""}
                                  decimalScale={2}
                                  placeholder={translation.defaultPlaceholder}
                                  addonAfter="Kg"
                                  decimalSeparator=","
                                  thousandSeparator="."
                                  fixedDecimalScale={true}
                                  onValueChange={({ floatValue }) =>
                                    handleChangeValuesTable(
                                      setFieldValue,
                                      "quantitySupplied",
                                      floatValue
                                        ? floatValue > 10000
                                          ? 10000
                                          : floatValue
                                        : null,
                                      values.pickets,
                                      record.id
                                    )
                                  }
                                />
                              </Row>
                            </Col>
                          </Row>
                        )}
                      />
                      <Table.Column
                        dataIndex="supplementId"
                        width={230}
                        title="Suplemento/Ração"
                        render={(text, record) => (
                          <Select
                            value={text || undefined}
                            loading={isLoadingSupplementsList}
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={translation.defaultSelectPlaceholder}
                            onChange={(value) => {
                              if (value == null) {
                                handleChangeValuesTable(
                                  setFieldValue,
                                  "supplementId",
                                  null,
                                  values.pickets,
                                  record.id
                                );

                                handleChangeValuesTable(
                                  setFieldValue,
                                  "dryMatterPercentage",
                                  null,
                                  values.pickets,
                                  record.id
                                );
                              } else {
                                const supplementSelected = allSupplements.find(
                                  (supplement) => supplement.id === value
                                );
                                handleChangeValuesTable(
                                  setFieldValue,
                                  "supplementId",
                                  value,
                                  values.pickets,
                                  record.id
                                );

                                handleChangeValuesTable(
                                  setFieldValue,
                                  "dryMatterPercentage",
                                  supplementSelected.dryMatterPercentage,
                                  values.pickets,
                                  record.id
                                );
                              }
                            }}
                          >
                            {allSupplements.map((supplement) => (
                              <Select.Option
                                key={supplement.id}
                                value={supplement.id}
                              >
                                {supplement.name}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      />
                      <Table.Column
                        dataIndex="observation"
                        title="Observação"
                        render={(text, record) => (
                          <Input
                            value={text}
                            placeholder={translation.defaultPlaceholder}
                            onChange={(e) =>
                              handleChangeValuesTable(
                                setFieldValue,
                                "observation",
                                e.target.value,
                                values.pickets,
                                record.id
                              )
                            }
                          />
                        )}
                      />
                    </Table>
                  </Col>
                </Row>
              </div>
              <Footer>
                <Row type="flex">
                  <Col span={12}>
                    <Select
                      value={saveOptions}
                      name="saveOptions"
                      onChange={(value) => setSaveOptions(value)}
                    >
                      <Select.Option key="1" value="only_save">
                        {
                          translation.picketSupplementSupply.form.saveOptions
                            .only_save
                        }
                      </Select.Option>
                      <Select.Option key="2" value="save_and_create_new">
                        {
                          translation.picketSupplementSupply.form.saveOptions
                            .save_and_create_new
                        }
                      </Select.Option>
                    </Select>
                  </Col>
                  <Col span={12} className="buttonsDiv">
                    <ButtonStandard
                      tabIndex="-1"
                      type="button"
                      buttonType="type7"
                      onClick={handleCloseDrawer}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard
                      tabIndex="-1"
                      type="submit"
                      buttonType="type6"
                    >
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
      </Spin>
    </Container>
  );
};

export default DrawerPicketSupplementSupply;
