import styled from "styled-components";
import { Card } from "antd";

export const Container = styled.div``;

export const Title = styled.span`
  font-family: "Asap";
  font-weight: bold;
  font-style: italic;
  font-size: 16px;
  text-align: left;
  color: #4b4b4b;
`;

export const EditButton = styled.button`
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #9386aa;
  border: none;
  background: none;

  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
    path {
      fill: #9386aa !important;
    }
  }

  &:hover {
    color: #a9c133;
    cursor: pointer;

    svg {
      margin-left: 5px;
      path {
        fill: #a9c133 !important;
      }
    }
  }

  &:disabled {
    color: #a5a5a5;
    cursor: not-allowed;
    svg {
      margin-left: 5px;
      path {
        fill: #a5a5a5 !important;
      }
    }
  }
`;

export const Message = styled.span`
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #a5a5a5;
  .red {
    color: #d44c4c;
  }
`;

export const CardMessage = styled(Card)`
  margin-top: 10px !important;
  border-radius: 5px !important;
  background: #fff !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
`;
