import React from "react";

// import { Container } from './styles';

const WarningIcon = () => (
  <svg
    id="warningIconBovexo"
    xmlns="http://www.w3.org/2000/svg"
    width="27.33"
    height="25.092"
    viewBox="0 0 27.33 25.092"
  >
    <g
      id="Group_4243"
      data-name="Group 4243"
      transform="translate(-475.598 -210.163)"
    >
      <g id="warnings" transform="translate(475.598 210.163)">
        <g id="Group_4244" data-name="Group 4244" transform="translate(0 0)">
          <path
            id="Path_2055"
            data-name="Path 2055"
            d="M27.305,39.029,14.543,15.836a.949.949,0,0,0-1.663,0L.118,39.029A.949.949,0,0,0,.95,40.436H26.474C26.81,40.436,27.467,39.323,27.305,39.029Zm-24.75-.492h0Z"
            transform="translate(-0.001 -15.344)"
            fill="#d44c4c"
          />
          <path
            id="Path_2056"
            data-name="Path 2056"
            d="M164.894,128.61v6.861a1.19,1.19,0,0,0,2.379,0V128.61a1.19,1.19,0,0,0-2.379,0Z"
            transform="translate(-152.372 -118.909)"
            fill="#fff"
          />
          <path
            id="Path_2057"
            data-name="Path 2057"
            d="M165.312,265.364a1.253,1.253,0,1,0,1.253,1.253A1.254,1.254,0,0,0,165.312,265.364Z"
            transform="translate(-151.601 -246.378)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default WarningIcon;
