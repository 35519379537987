import React from 'react';

// import { Container } from './styles';

const NotificationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.301"
    height="27.068"
    viewBox="0 0 21.301 27.068"
  >
    <g id="Btn_notificação" data-name="Btn notificação" transform="translate(-1581.661 -23.046)">
      <g id="Com_notificação" data-name="Com notificação">
        <g id="Grupo_535" data-name="Grupo 535">
          <g id="notification" transform="translate(1581.661 23.046)">
            <g id="Grupo_449" data-name="Grupo 449" transform="translate(0 0)">
              <g id="Grupo_448" data-name="Grupo 448">
                <path
                  id="Caminho_895"
                  data-name="Caminho 895"
                  d="M74.344,19.987a2.77,2.77,0,0,1-.9-2.035V12.688a.527.527,0,1,0-1.054,0v5.263a3.831,3.831,0,0,0,1.24,2.816,1.375,1.375,0,0,1-.924,2.389H56.271a1.375,1.375,0,0,1-.924-2.389,3.831,3.831,0,0,0,1.24-2.816V11.842a8.05,8.05,0,0,1,4.8-7.292,7.48,7.48,0,0,1,3.106-.638,7.886,7.886,0,0,1,3.448.794,3.9,3.9,0,1,0,.692-.834,8.913,8.913,0,0,0-2.5-.864,1.949,1.949,0,1,0-3.285,0,8.994,8.994,0,0,0-7.313,8.835v6.109a2.77,2.77,0,0,1-.9,2.035,2.433,2.433,0,0,0,1.635,4.227H61.16a3.37,3.37,0,0,0,6.661,0h4.888a2.433,2.433,0,0,0,1.635-4.227ZM71.249,3.912A2.855,2.855,0,1,1,68.4,6.767,2.853,2.853,0,0,1,71.249,3.912ZM64.49,1.057a.9.9,0,1,1-.9.9A.9.9,0,0,1,64.49,1.057Zm0,24.954a2.323,2.323,0,0,1-2.257-1.8h4.514A2.323,2.323,0,0,1,64.49,26.011Z"
                  transform="translate(-53.848 0)"
                  fill="#fff"
                />
              </g>
            </g>
            <g id="Grupo_451" data-name="Grupo 451" transform="translate(4.214 11.303)">
              <g id="Grupo_450" data-name="Grupo 450" transform="translate(0 0)">
                <path
                  id="Caminho_896"
                  data-name="Caminho 896"
                  d="M134.377,213.791a.529.529,0,0,0-.529.529v.011a.529.529,0,0,0,1.057,0v-.011A.529.529,0,0,0,134.377,213.791Z"
                  transform="translate(-133.848 -213.791)"
                  fill="#fff"
                />
              </g>
            </g>
            <g id="Grupo_453" data-name="Grupo 453" transform="translate(4.674 5.393)">
              <g id="Grupo_452" data-name="Grupo 452" transform="translate(0)">
                <path
                  id="Caminho_897"
                  data-name="Caminho 897"
                  d="M148.686,102a6.442,6.442,0,0,0-5.94,3.931.529.529,0,1,0,.973.413,5.386,5.386,0,0,1,4.966-3.287.529.529,0,0,0,0-1.057Z"
                  transform="translate(-142.704 -101.999)"
                  fill="#fff"
                />
              </g>
            </g>
            <path
              id="Caminho_906"
              data-name="Caminho 906"
              d="M0,5,5,0"
              transform="translate(14.839 4.454)"
              fill="none"
              stroke="#fff"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default NotificationIcon;
