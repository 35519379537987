import { all, call, put, select } from "redux-saga/effects";
import { notification } from "antd";
import { Creators as AnimalActions } from "../ducks/animal";
import { Creators as CurveActions } from "../ducks/curve";
/** Services */
import {
  callAnimalIndex,
  getAnimalById,
  getAnimalWeightHistoryById,
  getAnimalDailyWeights,
  getAnimalDailyWeightsGains,
  saveAnimal,
  activateAnimal,
  inactivateAnimal,
  destroyAnimal,
  getNumberAnimalsReproduction,
  getNumberAnimalsFatten,
  getAnimalDailyCosts,
  getAnimalMotherFatherDropdownList,
  getAnimalHandlingComments,
  callAnimalIndexReproductionEvents,
  getAnimalPicketSupplementSupplies,
} from "../../services/animalService";
import {
  getAnimalTotalWeightDashboard,
  getAnimalDailyGMDDashboard,
  getAnimalDailyWeightDashboard,
  getAnimalDailyWeightGainDashboard,
  getAnimalWeaningWeightDashboard,
  getAnimalLotDailyWeightGainDashboard,
} from "../../services/dashboards/dashboardAnimalService";
import {
  getAnimalEstimatedCurve,
  getLotEstimatedCurve,
} from "../../services/estimatedWeightCurveService";
import {
  calculateCurve,
  returnDietStrategyCurve,
} from "../../services/curveService";
import { getLotShow } from "../../services/lotService";
import {
  breedColor,
  dietStrategyColor,
  geneticColor,
  lotBreedColor,
} from "../../utils/colorsHandler";
import { getDietStrategyShow } from "../../services/dietStrategyService";

export const getTranslation = (state) => state.app.translation;
export const getAnimalState = (state) => state.animal;

export function* getNumberOfAnimalsInFattenAndReproduction(action) {
  try {
    let result = {};
    const {
      data: { results: numberFattenAnimals },
    } = yield call(getNumberAnimalsFatten, action.payload);

    const {
      data: { results: numberReproductionAnimals },
    } = yield call(getNumberAnimalsReproduction, action.payload);

    result = {
      numberFattenAnimals: numberFattenAnimals,
      numberReproductionAnimals: numberReproductionAnimals,
    };

    yield put(AnimalActions.getNumberAnimalsSuccess(result));
  } catch (error) {
    console.error(error);
    yield put(AnimalActions.getNumberAnimalsError(error));
  }
}

export function* indexAnimal(action) {
  try {
    let result = {};
    const {
      data: { results: allAnimals },
    } = yield call(callAnimalIndex, action.payload);

    result = {
      data: allAnimals,
    };

    yield put(AnimalActions.indexAnimalSuccess(result));
  } catch (error) {
    console.error(error);
    yield put(AnimalActions.indexAnimalError(error));
  }
}

export function* indexAnimalFemaleTable(action) {
  try {
    let result = {};
    const {
      data: { results: allAnimals },
    } = yield call(callAnimalIndex, action.payload);

    result = {
      reproductionFemaleData: allAnimals,
    };

    yield put(AnimalActions.indexAnimalSuccessFemaleTable(result));
  } catch (error) {
    console.error(error);
    yield put(AnimalActions.indexAnimalErrorFemaleTable(error));
  }
}

export function* indexAnimalFemaleReproductionEventTable(action) {
  try {
    const {
      data: { results: allAnimals },
    } = yield call(callAnimalIndexReproductionEvents, action.payload);

    const result = {
      reproductionFemaleReproductionEventData: allAnimals,
    };

    yield put(
      AnimalActions.indexAnimalSuccessFemaleReproductionEventsTable(result)
    );
  } catch (error) {
    console.error(error);
    yield put(
      AnimalActions.indexAnimalErrorFemaleReproductionEventsTable(error)
    );
  }
}

export function* indexAnimalMaleTable(action) {
  try {
    let result = {};
    const {
      data: { results: allAnimals },
    } = yield call(callAnimalIndex, action.payload);

    result = {
      reproductionMaleData: allAnimals,
    };

    yield put(AnimalActions.indexAnimalSuccessMaleTable(result));
  } catch (error) {
    console.error(error);
    yield put(AnimalActions.indexAnimalSuccessMaleTable(error));
  }
}

export function* indexAnimalDetails(action) {
  yield put(CurveActions.saveAnimalCalculateCurve([]));
  yield put(CurveActions.saveLotCalculateCurve([]));
  yield put(CurveActions.saveDietStrategyCalculateCurve([]));
  try {
    const {
      data: { results: animalDetails },
    } = yield call(getAnimalById, action.payload);
    if (Object.entries(animalDetails).length > 0) {
      yield put(AnimalActions.indexAnimalDetailsSuccess(animalDetails));
      if (action.payload.isDetails) {
        yield put(CurveActions.startLoading());
        /** Get Animal Curve
         * Get Lot Curve
         * Get Diet Strategy Curve
         */
        yield all([
          call(getAnimalCurve, action),
          call(getLotCurve, action, animalDetails),
        ]);
        yield put(CurveActions.stopLoading());
      }
    } else {
      yield put(AnimalActions.indexAnimalDetailsError(null));
    }
  } catch (error) {
    yield put(AnimalActions.indexAnimalDetailsError(error));
  }
}

export function* indexAnimalWeightHistory(action) {
  try {
    let result = {};
    const {
      data: { results: animalWeightHistory },
    } = yield call(getAnimalWeightHistoryById, action.payload);

    animalWeightHistory.content.map((item) => {
      item.key = Math.random();
      return item;
    });

    result = {
      animalWeightHistoryData: animalWeightHistory,
    };
    yield put(AnimalActions.indexAnimalWeightHistorySuccess(result));
  } catch (error) {
    yield put(AnimalActions.indexAnimalWeightHistoryError(error));
  }
}

export function* indexAnimalDailyWeight(action) {
  try {
    const {
      data: { results: animalDailyWeight },
    } = yield call(getAnimalDailyWeights, action.payload);
    const { doPageable } = action.payload;
    if (doPageable)
      yield put(
        AnimalActions.indexAnimalDailyWeightPageableSuccess(animalDailyWeight)
      );
    else
      yield put(AnimalActions.indexAnimalDailyWeightSuccess(animalDailyWeight));
  } catch (error) {
    yield put(AnimalActions.indexAnimalDailyWeightError(error));
  }
}

export function* indexAnimalDailyWeightGain(action) {
  try {
    const {
      data: { results: animalDailyWeightGain },
    } = yield call(getAnimalDailyWeightsGains, action.payload);
    const { doPageable } = action.payload;
    if (doPageable) {
      yield put(
        AnimalActions.indexAnimalDailyWeightGainPageableSuccess(
          animalDailyWeightGain
        )
      );
    } else {
      yield put(
        AnimalActions.indexAnimalDailyWeightGainSuccess(animalDailyWeightGain)
      );
    }
  } catch (error) {
    yield put(AnimalActions.indexAnimalDailyWeightGainError(error));
  }
}

export function* indexAnimalTotalDailyWeightDashboard(action) {
  const translation = yield select(getTranslation);
  const result = [];
  try {
    const {
      data: { results: response },
    } = yield call(getAnimalTotalWeightDashboard, action.payload);
    response.forEach((element) => {
      let label = "";
      let color = "";
      switch (element.answer) {
        case "estimatedWeight":
          label = translation.animal.details.totalWeight.graph.estimated;
          color = dietStrategyColor;
          break;
        case "geneticWeight":
          label = translation.animal.details.totalWeight.graph.genetic;
          color = geneticColor;
          break;
        case "breedWeight":
          label = translation.animal.details.totalWeight.graph.breed;
          color = breedColor;
          break;
        case "lotWeight":
          label = translation.animal.details.totalWeight.graph.lot;
          color = lotBreedColor;
          break;
        default:
          break;
      }

      result.push({
        label: label,
        y: element.count,
        color,
      });
    });

    yield put(AnimalActions.getAnimalTotalDailyWeightDashboardSuccess(result));
  } catch (error) {
    yield put(AnimalActions.getAnimalTotalDailyWeightDashboardSuccess([]));
  }
}

export function* indexAnimalTotalGMDDashboard(action) {
  const translation = yield select(getTranslation);
  const result = [];
  try {
    const {
      data: { results: response },
    } = yield call(getAnimalDailyGMDDashboard, action.payload);

    response.forEach((element) => {
      let label = "";
      let color = "";
      switch (element.answer) {
        case "estimatedWeight":
          label = translation.animal.details.totalWeight.graph.estimated;
          color = dietStrategyColor;
          break;
        case "geneticWeight":
          label = translation.animal.details.totalWeight.graph.genetic;
          color = geneticColor;
          break;
        case "breedWeight":
          label = translation.animal.details.totalWeight.graph.breed;
          color = breedColor;
          break;
        case "lotWeight":
          label = translation.animal.details.totalWeight.graph.lot;
          color = lotBreedColor;
          break;
        default:
          break;
      }

      result.push({
        label: label,
        y: element.count,
        color,
      });
    });

    yield put(AnimalActions.getAnimalGMDDashboardSuccess(result));
  } catch (error) {
    yield put(AnimalActions.getAnimalGMDDashboardSuccess([]));
  }
}

export function* storeOrUpdateAnimal(action) {
  let result = null;
  try {
    const {
      data: { results: animalData },
    } = yield call(saveAnimal, action.payload);
    result = animalData;
    const { saveOptions } = action.payload;
    yield put(AnimalActions.saveAnimalSuccess(animalData, saveOptions));
    if (action.payload.id == null)
      notification.success({ message: "Animal criado com sucesso" });
    else notification.success({ message: "Animal atualizado com sucesso" });
  } catch (error) {
    if (action.payload.id == null)
      notification.error({ message: "Animal não foi criado" });
    else notification.error({ message: "Animal não foi atualizado" });
    yield put(AnimalActions.saveAnimalError(error));
  }

  if (result != null) {
    const {
      pagination: {
        page,
        size,
        sorter,
        filters,
        ids,
        tableSorters,
        tableFilters,
      },
    } = yield select(getAnimalState);
    const newPayload = {
      ...action.payload,
      page,
      size,
      sorter,
      filters,
      ids,
      tableSorters,
      tableFilters,
    };
    yield put(
      AnimalActions.indexAnimal(
        newPayload.groupId,
        newPayload.farmId,
        newPayload.page,
        newPayload.sorter,
        newPayload.filters,
        newPayload.size,
        newPayload.ids,
        newPayload.tableSorters,
        newPayload.tableFilters
      )
    );
  }
}

function* getAnimalCurve(action) {
  try {
    const {
      data: { results: curve },
    } = yield call(getAnimalEstimatedCurve, {
      groupId: action.payload.groupId,
      farmId: action.payload.farmId,
      id: action.payload.animalId,
    });
    yield put(CurveActions.saveAnimalCurve(curve));
    if (curve != null && curve.breedCurveParam != null) {
      const result = calculateCurve(
        curve.asymptoticWeight,
        curve.entity.animal.birthday,
        curve.breedCurveParam.correctionFactor,
        curve.breedCurveParam.maturationRate,
        curve.breedCurveParam.theoreticalCurveType,
        1095
      );
      yield put(CurveActions.saveAnimalCalculateCurve(result));
    } else {
      yield put(CurveActions.saveAnimalCalculateCurve([]));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getLotCurve(action, animal) {
  const { groupId, farmId } = action.payload;
  const { lotId } = animal;
  try {
    const {
      data: { results: lot },
    } = yield call(getLotShow, { groupId, farmId, id: lotId });

    const {
      data: { results: curve },
    } = yield call(getLotEstimatedCurve, { groupId, farmId, id: lotId });

    yield put(CurveActions.saveLotCurve(curve));

    if (curve != null && curve.breedCurveParam != null) {
      const result = calculateCurve(
        curve.asymptoticWeight,
        lot.referenceBirthdayDate,
        curve.breedCurveParam.correctionFactor,
        curve.breedCurveParam.maturationRate,
        curve.breedCurveParam.theoreticalCurveType,
        1095
      );

      yield put(CurveActions.saveLotCalculateCurve(result));

      if (lot) {
        try {
          let entity = "Lot";

          const dietStrategyBaselineId =
            lot?.dietStrategies.find((l) => l.baseline === true) != null
              ? lot?.dietStrategies.find((l) => l.baseline === true)
                  .dietStrategyId
              : null;

          let dietStrategyBaseline = null;
          if (dietStrategyBaselineId) {
            const {
              data: { results: dietStrategyBaselineData },
            } = yield call(getDietStrategyShow, {
              groupId,
              farmId,
              id: dietStrategyBaselineId,
            });
            dietStrategyBaseline = dietStrategyBaselineData;
          }

          const curve = yield call(
            returnDietStrategyCurve,
            groupId,
            farmId,
            lotId,
            entity,
            lot.referenceBirthdayDate,
            null,
            dietStrategyBaseline
          );
          yield put(CurveActions.saveDietStrategyCalculateCurve(curve));
        } catch (error) {
          console.error(error);
          yield put(CurveActions.saveDietStrategyCalculateCurve([]));
        }
      }
    } else {
      yield put(CurveActions.saveLotCalculateCurve([]));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* indexAnimalDailyWeightDashboard(action) {
  try {
    const {
      data: { results: response },
    } = yield call(getAnimalDailyWeightDashboard, action.payload);
    yield put(AnimalActions.getAnimalDailyWeightDashboardSuccess(response));
  } catch (error) {
    yield put(AnimalActions.getAnimalDailyWeightDashboardError(error));
  }
}

export function* indexAnimalLotDailyWeightDashboard(action) {
  try {
    const {
      data: { results: response },
    } = yield call(getAnimalLotDailyWeightGainDashboard, action.payload);
    yield put(AnimalActions.indexAnimalLotWeightDashboardSuccess(response));
  } catch (error) {
    yield put(AnimalActions.indexAnimalLotWeightDashboardError(error));
  }
}

export function* indexAnimalDailyWeightGainDashboard(action) {
  try {
    const {
      data: { results: response },
    } = yield call(getAnimalDailyWeightGainDashboard, action.payload);
    yield put(AnimalActions.getAnimalDailyWeightGainDashboardSuccess(response));
  } catch (error) {
    yield put(AnimalActions.getAnimalDailyWeightGainDashboardError(error));
  }
}

export function* animalActivateOrInactivate(action) {
  const { animal, groupId, farmId, operationAction } = action.payload;

  try {
    if (operationAction === "activate") {
      yield call(activateAnimal, action.payload);
    } else {
      yield call(inactivateAnimal, action.payload);
    }
    notification.success({
      message: `O animal (${animal.handlingNumber}) foi ${
        operationAction === "activate" ? "ativado" : "inativado"
      } com sucesso.`,
    });
    const {
      pagination: {
        page,
        size,
        sorter,
        filters,
        ids,
        tableSorters,
        tableFilters,
      },
      paginationFemaleTable,
      paginationMaleTable,
    } = yield select(getAnimalState);

    if (animal.animalFarmFunction === "reproduction") {
      if (animal.gender === "F") {
        yield put(
          AnimalActions.indexAnimalFemaleTable(
            groupId,
            farmId,
            paginationFemaleTable.page,
            paginationFemaleTable.sorter,
            paginationFemaleTable.filters,
            paginationFemaleTable.size,
            paginationFemaleTable.ids,
            paginationFemaleTable.tableSorters,
            paginationFemaleTable.tableFilters
          )
        );
      } else {
        yield put(
          AnimalActions.indexAnimalMaleTable(
            groupId,
            farmId,
            paginationMaleTable.page,
            paginationMaleTable.sorter,
            paginationMaleTable.filters,
            paginationMaleTable.size,
            paginationMaleTable.ids,
            paginationMaleTable.tableSorters,
            paginationMaleTable.tableFilters
          )
        );
      }
    } else {
      yield put(
        AnimalActions.indexAnimal(
          groupId,
          farmId,
          page,
          sorter,
          filters,
          size,
          ids,
          tableSorters,
          tableFilters
        )
      );
    }

    yield put(AnimalActions.activateOrInactivateAnimalSuccess());
  } catch (error) {
    notification.error({
      message: `O animal (${animal.handlingNumber}) não foi ${
        operationAction === "activate" ? "ativado" : "inativado"
      }. Contate o suporte.`,
    });
    yield put(AnimalActions.activateOrInactivateAnimalError(error));
  }
}

export function* deleteAnimal(action) {
  const { groupId, farmId } = action.payload;

  try {
    yield call(destroyAnimal, action.payload);
    notification.success({
      message: `O animal excluído com sucesso.`,
    });
    const {
      pagination: {
        page,
        size,
        sorter,
        filters,
        ids,
        tableSorters,
        tableFilters,
      },
    } = yield select(getAnimalState);
    yield put(
      AnimalActions.indexAnimal(
        groupId,
        farmId,
        page,
        size,
        sorter,
        filters,
        ids,
        tableSorters,
        tableFilters
      )
    );
    yield put(AnimalActions.activateOrInactivateAnimalSuccess());
  } catch (error) {
    notification.error({
      message: `O animal não foi excluído. Contate o suporte.`,
    });
    yield put(AnimalActions.activateOrInactivateAnimalError(error));
  }
}

export function* indexAnimalDailyCost(action) {
  try {
    let result = {};
    const {
      data: { results: animalDailyCost },
    } = yield call(getAnimalDailyCosts, action.payload);

    result = {
      animalDailyCostData: animalDailyCost,
    };
    yield put(AnimalActions.indexAnimalDailyCostSuccess(result));
  } catch (error) {
    yield put(AnimalActions.indexAnimalDailyCostError(error));
  }
}

export function* indexAnimalWeaningWeightDashboard(action) {
  try {
    let result = {};

    const {
      data: { results: animalWeaningWeightDashboard },
    } = yield call(getAnimalWeaningWeightDashboard, action.payload);

    result = {
      animalWeaningWeightDashboard,
    };

    yield put(AnimalActions.indexAnimalWeaningWeightDashboardSuccess(result));
  } catch (error) {
    yield put(AnimalActions.indexAnimalWeaningWeightDashboardError(error));
  }
}

export function* indexAnimalMotherDropdown(action) {
  try {
    let result = {};

    action.payload = { ...action.payload, gender: "F" };

    const {
      data: { results: dropdownOptions },
    } = yield call(getAnimalMotherFatherDropdownList, action.payload);

    result = {
      motherDropdown: dropdownOptions,
    };

    yield put(AnimalActions.indexAnimalMotherDropdownSuccess(result));
  } catch (error) {
    yield put(AnimalActions.indexAnimalMotherDropdownError(error));
  }
}

export function* indexAnimalFatherDropdown(action) {
  try {
    let result = {};

    action.payload = { ...action.payload, gender: "M" };

    const {
      data: { results: dropdownOptions },
    } = yield call(getAnimalMotherFatherDropdownList, action.payload);

    result = {
      fatherDropdown: dropdownOptions,
    };

    yield put(AnimalActions.indexAnimalFatherDropdownSuccess(result));
  } catch (error) {
    yield put(AnimalActions.indexAnimalFatherDropdownError(error));
  }
}

export function* indexAnimalComments(action) {
  try {
    let result = {};
    const {
      data: { results: commentsData },
    } = yield call(getAnimalHandlingComments, action.payload);

    result = {
      commentsData,
    };
    yield put(AnimalActions.indexAnimalHandlingCommentsSuccess(result));
  } catch (error) {
    yield put(AnimalActions.indexAnimalHandlingCommentsError(error));
  }
}

export function* indexAnimalPicketSupplmentSupplies(action) {
  try {
    let result = {};
    const {
      data: { results: animalPicketSupplementSuppliesData },
    } = yield call(getAnimalPicketSupplementSupplies, action.payload);

    result = {
      animalPicketSupplementSuppliesData,
    };
    yield put(AnimalActions.indexAnimalPicketSupplementSuppliesSuccess(result));
  } catch (error) {
    yield put(AnimalActions.indexAnimalPicketSupplementSuppliesError(error));
  }
}
