import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import moment from "moment";
import { Creators as AnimalActions } from "../../store/ducks/animal";
import { Creators as AppActions } from "../../store/ducks/app";
import { Creators as ReportActions } from "../../store/ducks/report";
import { Creators as LotActions } from "../../store/ducks/lot";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { Icon } from "antd";
import ConfirmActionModal from "../../components/modals/confirmActionModal";

// Services
import { verifyLastAnimal } from "../../services/animalService";
import { showAlertsByDateAndEntity } from "../../services/alertService";
// import { Container } from './styles';

const AnimalListContext = createContext();

function AnimalListContextProvider({ children }) {
  const {
    animal: { numberFattenAnimals, numberReproductionAnimals },
    app: {
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
      alertSelected,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const history = useHistory();

  const [alerts, setAlerts] = useState([]);
  const [alertsEntityIds, setAlertsEntityIds] = useState(null);
  const [alertsFilterDate, setAlertsFilterDate] = useState(moment());
  const [animalSelected, setAnimalSelected] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInactivateModal, setShowInactivateModal] = useState(false);
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const [lastAnimal, setLastAnimal] = useState(false);

  const [selectedRowKeysFatten, setSelectedRowKeysFatten] = useState([]);
  const [
    selectedRowKeysFemaleReproduction,
    setSelectedRowKeysFemaleReproduction,
  ] = useState([]);
  const [selectedRowKeysMaleReproduction, setSelectedRowKeysMaleReproduction] =
    useState([]);

  const showFattenBlock = useMemo(() => {
    return numberFattenAnimals !== null &&
      numberFattenAnimals?.find((a) => a.count > 0)
      ? true
      : false;
  }, [numberFattenAnimals]);

  const showReproductionBlock = useMemo(() => {
    return numberReproductionAnimals !== null &&
      numberReproductionAnimals?.find((a) => a.count > 0)
      ? true
      : false;
  }, [numberReproductionAnimals]);

  // Methods

  const getSearchString = useCallback((filters) => {
    let search = "";

    if (filters && Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v && v.length > 0) {
          const fieldS = k
            ? k === "handlingNumber"
              ? "handling_number"
              : k === "tagId"
              ? "tag_id"
              : k === "farmStatus"
              ? "farm_status"
              : k === "lotName"
              ? "lot_name"
              : k === "picketName"
              ? "picket_name"
              : k === "breedName"
              ? "breed_name"
              : k === "supplierName"
              ? "supplier_name"
              : k === "currentWeightRanking"
              ? "current_weight_ranking"
              : k === "animalReproductionCategory"
              ? "animal_reproduction_category"
              : k === "femaleSituation"
              ? "female_situation"
              : k === "animalReproductionState"
              ? "animal_reproduction_state"
              : k
            : null;
          if (fieldS === "current_weight_ranking") {
            search =
              search === ""
                ? `${fieldS} = '${v}'`
                : `${search};${fieldS} = '${v}'`;
          } else if (fieldS === "farm_status") {
            if (v.includes("I")) v.push("X");
            search =
              search === ""
                ? `${fieldS} in (${v.map((o) => (o = `'${o}'`)).join(", ")})`
                : `${search};${fieldS} in (${v
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`;
          } else if (fieldS === "female_situation") {
            if (v.includes("I")) v.push("X");
            search =
              search === ""
                ? `${fieldS} in (${v.map((o) => (o = `'${o}'`)).join(", ")})`
                : `${search};${fieldS} in (${v
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`;
          } else {
            search =
              search === ""
                ? `upper(${fieldS}) like upper('%25${v}%25')`
                : `${search};upper(${fieldS}) like upper('%25${v}%25')`;
          }
        }
      });
    }

    return search !== "" ? search : "farm_status='A';";
  }, []);

  const getSortString = useCallback((sorter) => {
    let customSort = sorter;

    if (sorter && Object.entries(sorter).length > 0) {
      const { field } = sorter;

      const fieldS = field
        ? field === "handlingNumber"
          ? "handling_number"
          : field === "tagId"
          ? "tag_id"
          : field === "farmStatus"
          ? "farm_status"
          : field === "lotName"
          ? "lot_name"
          : field === "picketName"
          ? "picket_name"
          : field === "breedName"
          ? "breed_name"
          : field === "supplierName"
          ? "supplier_name"
          : field === "animalReproductionCategory"
          ? "animal_reproduction_category"
          : field === "femaleSituation"
          ? "female_situation"
          : field === "animalReproductionState"
          ? "animal_reproduction_state"
          : field
        : null;

      customSort = {
        ...customSort,
        field: fieldS,
      };
    }

    return customSort;
  }, []);

  const fetchData = useCallback(
    (page = 0, sorter = null, filters = null, size = 10, ids = null) => {
      let customSort = getSortString(sorter);

      let search = `animal_farm_function='fatten';` + getSearchString(filters);

      dispatch(
        AnimalActions.indexAnimal(
          groupId,
          farmId,
          page,
          customSort,
          search,
          size,
          ids,
          sorter,
          filters
        )
      );
    },
    [getSortString, getSearchString, dispatch, groupId, farmId]
  );

  const fetchReproductionMaleData = useCallback(
    (page = 0, sorter = null, filters = null, size = 10, ids = null) => {
      let customSort = getSortString(sorter);
      let search =
        `animal_farm_function='reproduction';gender='M';` +
        getSearchString(filters);

      dispatch(
        AnimalActions.indexAnimalMaleTable(
          groupId,
          farmId,
          page,
          customSort,
          search,
          size,
          ids,
          sorter,
          filters
        )
      );
    },
    [getSortString, getSearchString, dispatch, groupId, farmId]
  );

  const fetchReproductionFemaleData = useCallback(
    (page = 0, sorter = null, filters = null, size = 10, ids = null) => {
      let customSort = getSortString(sorter);
      let search =
        `animal_farm_function='reproduction';gender='F';` +
        getSearchString(filters);

      dispatch(
        AnimalActions.indexAnimalFemaleTable(
          groupId,
          farmId,
          page,
          customSort,
          search,
          size,
          ids,
          sorter,
          filters
        )
      );
    },
    [getSortString, getSearchString, dispatch, groupId, farmId]
  );

  const getAlerts = useCallback(
    async (date, loadPage) => {
      let selectedDate = date.format("YYYY-MM-DD");
      const executionDate = selectedDate;
      const entity = "animal";

      try {
        const {
          data: { results: alerts },
        } = await showAlertsByDateAndEntity({
          groupId,
          farmId,
          executionDate,
          entity,
        });
        dispatch(AppActions.setAlertSelected({}));
        setAlerts(alerts);
        setAlertsFilterDate(date);
        setAlertsEntityIds(null);
      } catch (error) {
        console.error(error);
        setAlerts([]);
        setAlertsFilterDate(date);
        setAlertsEntityIds(null);
      }
    },
    [groupId, farmId, dispatch]
  );

  const handleAlertSelected = useCallback(
    (alert) => {
      let ids = null;
      if (
        alertSelected &&
        alertSelected.id === alert.id &&
        alertSelected.failed === alert.failed
      ) {
        dispatch(AppActions.setAlertSelected({}));
      } else {
        dispatch(AppActions.setAlertSelected(alert));
        ids = "'" + alert.entityIds.join("','") + "'";
      }
      fetchData(0, null, null, 10, ids);
      fetchReproductionMaleData(0, null, null, 10, ids);
      fetchReproductionFemaleData(0, null, null, 10, ids);
      setAlertsEntityIds(ids);
    },
    [
      alertSelected,
      dispatch,
      fetchData,
      fetchReproductionMaleData,
      fetchReproductionFemaleData,
    ]
  );

  const handleCallBackDashboard = useCallback(
    (ids) => {
      fetchData(0, null, null, 10, ids);
      fetchReproductionMaleData(0, null, null, 10, ids);
      fetchReproductionFemaleData(0, null, null, 10, ids);
    },
    [fetchData, fetchReproductionMaleData, fetchReproductionFemaleData]
  );

  const handleDelete = useCallback(async (record) => {
    setIsLoadingInternal(true);

    if (record.lotId === null) {
      setAnimalSelected(record);
      setShowDeleteModal(true);
      setLastAnimal(false);
    } else {
      const {
        data: { results: lastAnimal },
      } = await verifyLastAnimal(record);

      setAnimalSelected(record);
      setShowDeleteModal(true);
      setLastAnimal(lastAnimal);
    }

    setIsLoadingInternal(false);
  }, []);

  const handleConfirmDelete = useCallback(() => {
    setAlertsEntityIds(null);
    dispatch(AppActions.setAlertSelected({}));
    dispatch(AnimalActions.deleteAnimal(groupId, farmId, animalSelected.id));
    setAnimalSelected(null);
    setShowDeleteModal(false);
    setLastAnimal(false);
  }, [dispatch, animalSelected, groupId, farmId]);

  const handleCancelDelete = useCallback(() => {
    setAnimalSelected(null);
    setShowDeleteModal(false);
    setLastAnimal(false);
  }, []);

  const handleInactivateOrActivate = useCallback(
    (id, animal, operationAction) => {
      dispatch(
        AnimalActions.activateOrInactivateAnimal(
          groupId,
          farmId,
          id,
          animal,
          operationAction
        )
      );
    },
    [groupId, farmId, dispatch]
  );

  const handleInactivate = useCallback(async (record) => {
    setIsLoadingInternal(true);

    if (record.lotId === null) {
      setAnimalSelected(record);
      setShowInactivateModal(true);
      setLastAnimal(false);
    } else {
      const {
        data: { results: lastAnimal },
      } = await verifyLastAnimal(record);

      setAnimalSelected(record);
      setShowInactivateModal(true);
      setLastAnimal(lastAnimal);
    }

    setIsLoadingInternal(false);
  }, []);

  const handleConfirmInactivate = useCallback(() => {
    handleInactivateOrActivate(animalSelected.id, animalSelected, "inactivate");
    setAnimalSelected(null);
    setShowInactivateModal(false);
    setLastAnimal(false);
  }, [animalSelected, handleInactivateOrActivate]);

  const handleCancelInactivate = useCallback(() => {
    setAnimalSelected(null);
    setShowInactivateModal(false);
    setLastAnimal(false);
  }, []);

  const handleEdit = useCallback(
    (id) => {
      dispatch(AnimalActions.showDrawerEditAnimal(id));
    },
    [dispatch]
  );

  const handleUpdateSelectedRowKeysFatten = useCallback((rowKeys) => {
    setSelectedRowKeysFatten(rowKeys);
  }, []);

  const handleUpdateSelectedRowKeysMaleReproduction = useCallback((rowKeys) => {
    setSelectedRowKeysMaleReproduction(rowKeys);
  }, []);

  const handleUpdateSelectedRowKeysFemaleReproduction = useCallback(
    (rowKeys) => {
      setSelectedRowKeysFemaleReproduction(rowKeys);
    },
    []
  );

  const handleGenerateReport = useCallback(() => {
    const ids = [
      ...selectedRowKeysFatten,
      ...selectedRowKeysFemaleReproduction,
      ...selectedRowKeysMaleReproduction,
    ];
    dispatch(
      ReportActions.showModal("Relatório de animais", "animal", null, ids)
    );
  }, [
    dispatch,
    selectedRowKeysFatten,
    selectedRowKeysFemaleReproduction,
    selectedRowKeysMaleReproduction,
  ]);

  const handleCreateNewAnimal = useCallback(() => {
    dispatch(AnimalActions.showDrawerNewAnimal());
  }, [dispatch]);

  const handleGoToLotDetails = useCallback(
    (animalData) => {
      dispatch(LotActions.showAnimalInChartFromAnimalDetails(animalData));
      history.push(`/admin/lots/${animalData?.lotId}`);
    },
    [dispatch, history]
  );

  useEffect(() => {
    getAlerts(moment(), true);
  }, [groupId, farmId, getAlerts]);

  return (
    <AnimalListContext.Provider
      value={{
        isLoadingInternal,
        selectedRowKeysFatten,
        showFattenBlock,
        showReproductionBlock,
        alerts,
        alertsFilterDate,
        alertsEntityIds,
        getAlerts,
        handleAlertSelected,
        handleUpdateSelectedRowKeysFatten,
        handleUpdateSelectedRowKeysMaleReproduction,
        handleUpdateSelectedRowKeysFemaleReproduction,
        handleDelete,
        handleInactivate,
        handleInactivateOrActivate,
        handleEdit,
        handleGenerateReport,
        handleCreateNewAnimal,
        handleGoToLotDetails,
        handleCallBackDashboard,
      }}
    >
      {children}
      <ConfirmActionModal
        show={showDeleteModal}
        width={600}
        title={
          lastAnimal
            ? `Atenção! Este é o último animal (${
                animalSelected?.handlingNumber
                  ? animalSelected?.handlingNumber
                  : "-"
              }) presente no lote (${
                animalSelected?.lotName
              }). Ao confirmar a exclusão do registro, o Lote ficará com a situação “Pendente”. Se não for mais utilizar este lote, poderá inativá-lo.`
            : `Você realmente deseja excluir o animal (${
                animalSelected?.handlingNumber
                  ? animalSelected?.handlingNumber
                  : "-"
              }) ?`
        }
        message="Esta ação será irreversível"
        icon={
          <Icon
            type="question-circle"
            style={{ color: "#4A85AE", fontSize: 24 }}
          />
        }
        confirmAction={handleConfirmDelete}
        cancelAction={handleCancelDelete}
      />
      <ConfirmActionModal
        show={showInactivateModal}
        width={600}
        title={
          lastAnimal
            ? `Atenção! Este é o último animal (${
                animalSelected?.handlingNumber
                  ? animalSelected?.handlingNumber
                  : "-"
              }) presente no lote (${
                animalSelected?.lotName
              }). Ao confirmar a desativação do registro, o Lote ficará com a situação “Pendente”. Se não for mais utilizar este lote, poderá inativá-lo.`
            : `Você realmente deseja inativar o animal (${
                animalSelected?.handlingNumber
                  ? animalSelected?.handlingNumber
                  : "-"
              }) ?`
        }
        message=""
        icon={
          <Icon
            type="question-circle"
            style={{ color: "#4A85AE", fontSize: 24 }}
          />
        }
        confirmAction={handleConfirmInactivate}
        cancelAction={handleCancelInactivate}
      />
    </AnimalListContext.Provider>
  );
}

export { AnimalListContext, AnimalListContextProvider };
