export const Types = {
  RESET_DATA: "report/RESET_DATA",
  SHOW_MODAL: "report/SHOW_MODAL",
  HIDE_MODAL: "report/HIDE_MODAL",
};

export const Creators = {
  showModalWithReports: (
    title = null,
    reports = null,
    reportSearch = null,
    reportColumns = null,
    ids = []
  ) => ({
    type: Types.SHOW_MODAL,
    payload: {
      title,
      reports,
      reportSearch,
      reportColumns,
      ids,
    },
  }),
  showModal: (
    title = null,
    reportSearch = null,
    reportColumns = null,
    ids = []
  ) => ({
    type: Types.SHOW_MODAL,
    payload: {
      title,
      reportSearch,
      reportColumns,
      ids,
    },
  }),
  hideModal: () => ({
    type: Types.HIDE_MODAL,
    payload: {},
  }),
};

const INITIAL_STATE = {
  modalVisible: false,
  title: null,
  reports: [
    {
      name: "Relatório de listagem de animais (Reprodução)",
      code: "ListAnimalReportReproduction",
      fileName: "BovExo_relatorio_listagem_animais_reproducao",
      exportExtensions: ["xlsx"],
      isByTemplate: true,
      hasParameters: false,
    },
    {
      name: "Relatório de listagem de animais (Engorda)",
      code: "ListAnimalReportFatten",
      fileName: "BovExo_relatorio_listagem_animais_engorda",
      exportExtensions: ["xlsx"],
      isByTemplate: true,
      hasParameters: false,
    },
    {
      name: "Relatório para importação de pesagens",
      code: "AnimalWeightImportReport",
      fileName: "BovExo_relatorio_importacao_pesagens",
      exportExtensions: ["xlsx"],
      isByTemplate: true,
      hasParameters: false,
    },
  ],
  reportSearch: "lot",
  reportColumns: null,
  ids: [],
};

export default function report(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.SHOW_MODAL:
      const { title, reports, reportSearch, reportColumns, ids } =
        action.payload;
      return {
        ...state,
        title,
        reports: reports != null ? reports : state.reports,
        reportSearch,
        reportColumns,
        ids,
        modalVisible: true,
      };
    case Types.HIDE_MODAL:
      return INITIAL_STATE;
    default:
      return state;
  }
}
