import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 200px;
  /* border: 1px solid red; */

  padding: 4px;

  margin: 16px 0px;
`;

export const Grid = styled.div`
  .header {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    padding: 16px 16px;

    margin-bottom: 16px;

    .ant-col {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .grid-row {
    padding: 8px 0px;
    border-bottom: 2px solid #9074bf;

    &:last-of-type {
      border-bottom: none;
    }

    .grid-row-col-title {
      strong {
        font-size: 0.875rem;
      }
    }

    .grid-row-col-value {
      text-align: center;
      strong {
        font-size: 0.75rem;
        &.negative {
          color: rgb(212, 76, 76);
        }
      }
    }

    &.sub {
      .grid-row-col-title {
        padding-left: 24px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .grid-row {
      .grid-row-col-title {
        strong {
          font-size: 0.75rem;
        }
      }
      .grid-row-col-value {
        text-align: center;
        strong {
          font-size: 0.65rem;
          &.negative {
            color: rgb(212, 76, 76);
          }
        }
      }
    }
  }
`;
