import React from "react";
import InputMask from "react-input-mask";
import { Input } from "antd";

// import { Container } from './styles';

const PhoneNumberInput = ({ disabled, ...props }) => {
  return (
    <InputMask
      {...props}
      mask={
        props?.value !== null
          ? props.value?.length <= 14
            ? "(99) 9999-99999"
            : "(99) 99999-9999"
          : ""
      }
      maskChar={null}
      disabled={disabled}
    >
      {(inputProps) => <Input {...inputProps} disabled={disabled} />}
    </InputMask>
  );
};

export default PhoneNumberInput;
