import { notification } from "antd";
import moment from "moment";

function validateForm(
  form,
  female,
  minGestationDaysBreed = 0,
  maxGestationDaysBreed = 0
) {
  // Check if need to select coverage
  if (
    female.femaleSituation !== "vazia" &&
    form.animalCoverageId == null &&
    form.type === "parto" &&
    female.lastCoverages &&
    female.lastCoverages?.length > 1
  ) {
    notification.error({
      message: 'Selecione uma cobertura através do botão "Definir Cobertura".',
    });
    throw Error("SELECT-ONE-COVERAGE");
  }

  // Check if female is vazia but have a last diagnosis negative, compare if new coverage date is before lastCoverageDate
  if (female.femaleSituation === "vazia" && female.lastCoverageDate != null) {
    const newLastCoverageDate = moment(form.birthDate).subtract(
      form.gestationDays,
      "days"
    );
    if (moment(female.lastCoverageDate).isAfter(newLastCoverageDate, "days")) {
      notification.error({
        message:
          "Atenção! Tempo de Gestação informado inconsistente, por compreender período em que a fêmea já possui cobertura(s) com resultado(s).",
      });
      throw Error("WRONG-GESTATION-DAYS");
    }
  }

  // Check gestation time
  if (
    female.femaleSituation !== "vazia" &&
    form.birthDate !== null &&
    form.type === "parto" &&
    female.lastCoverageDate !== null
  ) {
    const diffInDaysBetweenLastCoverageDateAndBirthDate = moment(
      form.birthDate
    ).diff(moment(female.lastCoverageDate), "days");
    if (diffInDaysBetweenLastCoverageDateAndBirthDate < minGestationDaysBreed) {
      notification.error({
        message: `Atenção! Tempo de gestação inferior ao tempo mínimo (${minGestationDaysBreed} dias), definido nos parâmetros da raça da Matriz. Operação cancelada!`,
      });
      throw Error("MIN-GESTATION-BREED-VIOLATION");
    } else if (
      diffInDaysBetweenLastCoverageDateAndBirthDate > maxGestationDaysBreed
    ) {
      notification.error({
        message: `Atenção! Tempo de gestação superior ao tempo máximo (${maxGestationDaysBreed} dias), definido nos parâmetros da raça da Matriz. Operação cancelada!`,
      });
      throw Error("MAX-GESTATION-BREED-VIOLATION");
    }
  }

  // Check if female(matriz) is vazia, validate gestation time
  if (
    (female.femaleSituation === "vazia" || female.femaleSituation === null) &&
    form.type === "parto" &&
    form.gestationDays != null
  ) {
    if (form.gestationDays < minGestationDaysBreed) {
      notification.error({
        message: `Atenção! Tempo de gestação inferior ao tempo mínimo (${minGestationDaysBreed} dias), definido nos parâmetros da raça da Matriz. Operação cancelada!`,
      });
      throw Error("MIN-GESTATION-BREED-VIOLATION");
    } else if (form.gestationDays > maxGestationDaysBreed) {
      notification.error({
        message: `Atenção! Tempo de gestação superior ao tempo máximo (${maxGestationDaysBreed} dias), definido nos parâmetros da raça da Matriz. Operação cancelada!`,
      });
      throw Error("MAX-GESTATION-BREED-VIOLATION");
    }
  }
}

async function validateSelectFemale(animal, reproductionParameters) {
  return new Promise((resolve, reject) => {
    const animalAge = animal?.birthdayMonths;
    const ageForReproduction = reproductionParameters
      ? Number(reproductionParameters["IDADE_INICIO_FASE_REPRODUTIVA"])
      : 0;

    // Check if is Male, if so dont continue the process
    if (animal.gender === "M") {
      reject("DG-ERROR-MALE");
    }
    // Check if female has age for reproduction
    else if (animalAge < ageForReproduction) {
      reject("ANIMAL-WITHOUT-AGE-FOR-REPRODUCTION");
    }
    // Check if allowFattenFemale = TRUE and ANIMAL IS FATTEN
    else if (animal.animalFarmFunction === "fatten") {
      reject("DG-CHECK-FEMALE-FATTEN");
    }
    // If female is REPRODUCTION and is VAZIA
    else if (
      animal.animalFarmFunction === "reproduction" &&
      animal.femaleSituation === "vazia"
    ) {
      reject("DG-CHECK-FEMALE-VAZIA");
    }
    resolve("OK");
  });
}

export { validateSelectFemale, validateForm };
