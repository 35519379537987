import apiFinancial from "../config/api_financial";
// import axios from "axios";
import getHeaders from "./getHeaders";

// const apiFinancial = axios.create({
//   baseURL: "http://localhost:8086",
// });

function generateFinancialCashFlow(payload) {
  const { groupId, farmId, financialProjectId, currency, signal } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/financial/cash/flow?currency=${currency}${
      financialProjectId ? `&financialProjectId=${financialProjectId}` : ""
    }`,
    getHeaders(false, signal)
  );
}

function checkFinancialCashFlowValue(payload) {
  const { groupId, farmId, body, signal } = payload;

  return apiFinancial.post(
    `/bovexo/${groupId}/farms/${farmId}/financial/cash/flow/check/is/greater`,
    body,
    getHeaders(false, signal)
  );
}

function storeOrUpdateFinancialCashFlowBugdet(payload) {
  const { groupId, farmId, body, signal } = payload;

  return apiFinancial.post(
    `/bovexo/${groupId}/farms/${farmId}/financial/cash/flow/budgets`,
    body,
    getHeaders(false, signal)
  );
}

export {
  generateFinancialCashFlow,
  storeOrUpdateFinancialCashFlowBugdet,
  checkFinancialCashFlowValue,
};
