import React from "react";
/** Components */
import { Container } from "./styles";
import { Row, Col } from "antd";
// Services
import { numberMask } from "../../../../services/helpersMethodsService";

const DisplayCarbonFootprintParameter = ({ data }) => {
  return (
    <Container>
      {data != null && (
        <>
          <Row type="flex" justify="start" gutter={8}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <span>Valor de Referência</span>
            </Col>
            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <strong>
                {`${
                  data != null
                    ? numberMask(
                        Number(data?.find((x) => x.code === 1006)?.value) || 0,
                        false
                      )
                    : 0
                } `}
                kg CO<sub>2</sub>E / kg GDP
              </strong>
            </Col>
          </Row>
          <Row type="flex" justify="start" gutter={8}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <span>Crédito de Carbono</span>
            </Col>
            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <strong>
                {`${
                  data != null
                    ? numberMask(
                        Number(data?.find((x) => x.code === 1007)?.value) || 0,
                        true
                      )
                    : 0
                } `}
                kg CO<sub>2</sub>E
              </strong>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default DisplayCarbonFootprintParameter;
