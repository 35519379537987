import styled from "styled-components";

export const Container = styled.div`
  min-width: 100%;
  height: calc(100vh - 110px);

  .rowLabel {
    margin-bottom: 7px;
    margin-top: 19px;
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    margin-bottom: 7px;
    margin-top: 19px;
    &.error {
      color: #d44c4c;
    }
  }

  .tableButtonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    margin-bottom: 24px;

    button {
      display: flex;
      justify-content: center !important;
      text-align: center !important;
      align-items: center !important;
      font-size: 14px !important;
      width: 77px !important;
      height: 30px !important;

      svg {
        margin-right: 3px !important;
      }
    }

    button:first-of-type {
      width: 116px !important;
      margin-right: 12px !important;
    }
  }
`;

export const AnimalsAmount = styled.div`
  height: 35px;
  border-radius: 17.5px;
  background: transparent;
  border: 1px solid #d44c4c;
  padding: 0 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-family: "Asap", sans-serif;
    font-size: 14px;
    color: #d44c4c;
  }

  span.number {
    font-size: 20px;
  }

  &.haveAnimals {
    background: #4a85ae;
    border: 1px solid #4a85ae;
    span {
      color: #fff;
    }

    span.number {
      font-size: 20px;
    }
  }
`;

export const ModalTitle = styled.span`
  font-size: 25px;
  font-family: "Asap", sans-serif;
  font-weight: bold;
  color: #4b4b4b;
`;

export const ButtonInputNumber = styled.button`
  background: transparent;
  color: #fff;
  border: none;
  width: 100% !important;
  height: 100% !important;

  &:hover {
    cursor: pointer;
  }

  svg {
    margin-left: 5px;
  }
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  border-top: 1px solid #e8e8e8;
  width: 100%;
  padding: 10px 24px;
  border-radius: 0 0 4px 4px;

  .buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 24px;

    button:first-of-type {
      margin-right: 12px !important;
    }
  }

  .selectDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: "Asap", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;
