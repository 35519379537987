import styled from "styled-components";

export const DayBox = styled.div`
  height: 55px;
  width: 65px;
  padding: 0px;
  margin: 0px;
  font-size: 12px;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
  border: solid 2px;
  border-radius: 5px;
`;

export const Container = styled.div`
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;

  .pastureDetails {
    cursor: pointer;
    border: unset;
    background-color: unset;
    align-items: center;
    color: #4a85ae;
    svg {
      margin-left: 6px;
    }
  }

  .todayColumn{
    //background-color: darkgray;
    border: solid 2px black;
    border-bottom: none;
    border-top: none;
  }

  .columnTitleContainer{
    width: 60px;
    padding: 0px;
    text-align: center;
  }

  thead{
    th.todayColumn{
      border-top: solid 2px black;
    }
  }

  .ant-table-small > .ant-table-content .ant-table-row:last-child td.todayColumn {
    border-bottom: solid 2px black !important;
  }
`;

export const Title = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: #4b4b4b;
  margin-bottom: 10px;
  margin-right: 5px;
`;