import apiFarms from "../config/api_farms";
import getHeaders from "./getHeaders";

export function getProfitCenterIndex(payload) {
  const {
    groupId,
    farmId,
    page,
    sorter,
    filters,
    size,
    signal,
    type,
  } = payload;

  const { field, order } = sorter;

  const orderS = order === "descend" ? "DESC" : "ASC";

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/profitcenters?
    ${page ? `&page=${page - 1}` : ""}
    ${size ? `&size=${size}` : ""}
    ${
      Object.entries(sorter).length > 0
        ? `&sort=${field}&direction=${orderS}`
        : ""
    }
    ${
      filters !== "" ? `&search=${filters}` : ""
    }&withoutPagination=false&indexRequestType=${type}`,
    getHeaders(false, signal)
  );
}

export function getProfitCenterDashboard(payload) {
  const { groupId, farmId, productionSubSystem, dashboard, signal } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/profitcenters/productionSubSystems/${productionSubSystem}/dashboards/${dashboard}`,
    getHeaders(false, signal)
  );
}

export function getListOfProfitCenterIndex(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/profitcenters?withoutPagination=true`,
    getHeaders(false, signal)
  );
}

export function getProfitCenterShow(payload) {
  const { groupId, farmId, id, signal } = payload;
  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/profitcenters/${id}`,
    getHeaders(false, signal)
  );
}

export function saveProfitCenter(payload) {
  const { groupId, farmId, id, profitCenter, signal } = payload;
  if (id != null) {
    return apiFarms.patch(
      `/bovexo/${groupId}/farms/${farmId}/profitcenters/${id}`,
      profitCenter,
      getHeaders(false, signal)
    );
  } else {
    return apiFarms.post(
      `/bovexo/${groupId}/farms/${farmId}/profitcenters`,
      profitCenter,
      getHeaders(false, signal)
    );
  }
}
