import styled from "styled-components";
import { Row } from "antd";

export const Container = styled.div`
  width: 100%;

  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  margin-top: 10px;
  background: #fff;

  div.valuesContainer {
    display: flex;
  }

  .ant-switch-checked {
    background-color: #a9c133 !important;
  }

  
  .iconError {
    color: #d44c4c;
  }

  .text-purple {
    color: #684E94 !important;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
  }

  span.sub-title {
    color: #9074BF;
    font-size: 14px;
    font-weight: bold;
  }

  span {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;

    &.values {
      display: flex;
      align-items: center;
      margin-right: 30px;

      &:last-of-type {
        margin-right: 0px;
      }
    }

    svg {
      margin-left: 6px;
    }

    strong {
      margin-left: 3px;
      &.gray {
        color: #a5a5a5;
      }
    }

    div.circle {
      width: 10px;
      height: 10px;
      margin-right: 6px;
      border-radius: 50%;
      &.red {
        background: linear-gradient(90deg, #b16060 50%, #9b4d4d 50%);
      }
      &.green {
        background: linear-gradient(90deg, #85b6a6 50%, #679c8b 50%);
      }
      &.purple {
        background: linear-gradient(90deg, #6c578f 50%, #5d4880 50%);
      }
    }
  }
`;

export const Body = styled(Row)`
  padding: 20px 10px;
`;

export const Footer = styled.div`
  border-top: 1px solid #e8e5e5;
  padding: 20px 10px;
  .buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .selectDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: "Asap", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;
