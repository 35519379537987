import React, { useState, useEffect } from "react";
import moment from "moment";
import { v4 as uuid } from "uuid";
/** Redux libs */
import { useSelector, useDispatch } from "react-redux";
import { Creators as SaleScenarioActions } from "../../../../../../store/ducks/saleScenario";
/** Components */
import { Spin, Row, Radio, DatePicker, notification } from "antd";
import {
  ModalCustom,
  RadioButtonCustom,
  Title,
  CustomInput,
  Button,
} from "./styles";
import NumberFormat from "react-number-format";

const ModalCommitment = () => {
  /** Variables */
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    key: null,
    saleCommitmentType: "AmountAnimals",
    commitmentValue: null,
    commitmentDate: null,
  });
  const { Group: RadioGroup } = Radio;
  const dateFormat = "DD/MM/YYYY";
  /** Redux variables */
  const { translation } = useSelector((state) => state.app);
  const {
    modalSaleCommitmentVisible,
    modalSaleCommitmentData,
    maxDeadLineGoal,
  } = useSelector((state) => state.saleScenario);
  const dispatch = useDispatch();
  /** Effect */
  useEffect(() => {
    setForm(modalSaleCommitmentData);
  }, [modalSaleCommitmentData]);
  useEffect(() => {
    if (modalSaleCommitmentVisible) {
      setIsLoading(true);
      setTimeout(() => setIsLoading(false), 500);
    }
  }, [modalSaleCommitmentVisible]);
  /** Methods */
  function submitForm() {
    if (validateForm()) {
      if (form.key === null) {
        let key = uuid();
        dispatch(
          SaleScenarioActions.addUpdateOrRemoveCommitments(form, key, "add")
        );
      } else {
        dispatch(
          SaleScenarioActions.addUpdateOrRemoveCommitments(
            form,
            form.key,
            "update"
          )
        );
      }
    }
  }
  function validateForm() {
    let message = null;
    if (form.commitmentDate == null) {
      message = "É necessário informar uma data para o comprometimento.";
    } else if (moment(form.commitmentDate).isAfter(maxDeadLineGoal, "days")) {
      message = `A data de comprometimento deve ser até ${moment(
        maxDeadLineGoal
      ).format("DD/MM/YYYY")}`;
    } else if (form.commitmentValue == null) {
      message =
        "É necessário informar um valor/quantidade de animais para o comprometimento.";
    }
    if (message === null) {
      return true;
    } else {
      notification.error({
        message,
      });
      return false;
    }
  }
  return (
    <ModalCustom
      width={360}
      visible={modalSaleCommitmentVisible}
      centered
      closable={false}
      footer={null}
    >
      <Spin spinning={isLoading}>
        <Row>
          <Title>Compromisso de Venda</Title>
        </Row>
        {/* Input saleCommitmentType  */}
        <Row>
          <RadioGroup
            name="saleCommitmentType"
            value={form != null ? form.saleCommitmentType : false}
            defaultValue={false}
            onChange={(e) => {
              setForm({
                ...form,
                saleCommitmentType: e.target.value,
              });
            }}
          >
            <RadioButtonCustom value="SellValue">
              Valor de Venda
            </RadioButtonCustom>
            <RadioButtonCustom value="AmountAnimals">
              Quantidade de animais
            </RadioButtonCustom>
          </RadioGroup>
        </Row>
        {/* Input commitmentValue */}
        <Row className="rowInputGroup">
          <NumberFormat
            customInput={CustomInput}
            value={form !== null ? form.commitmentValue : null}
            name="commitmentValue"
            addonBefore={form.saleCommitmentType === "SellValue" ? "R$" : null}
            addonAfter={
              form.saleCommitmentType === "AmountAnimals" ? "animais" : null
            }
            placeholder={
              form != null
                ? form.saleCommitmentType === "SellValue"
                  ? "Valor de venda"
                  : "Quantidade de animais"
                : ""
            }
            decimalScale={form.saleCommitmentType === "SellValue" ? 2 : 0}
            decimalSeparator=","
            thousandSeparator="."
            fixedDecimalScale={
              form.saleCommitmentType === "SellValue" ? true : false
            }
            onValueChange={({ floatValue }) => {
              setForm({
                ...form,
                commitmentValue: floatValue >= 0 ? floatValue : null,
              });
            }}
          />
        </Row>
        {/* Input commitmentDate */}
        <Row className="rowInputGroup">
          <label>{`Data de compromisso`}</label>
        </Row>
        <Row>
          <DatePicker
            name="commitmentDate"
            value={
              form != null
                ? form.commitmentDate != null
                  ? moment(form.commitmentDate, "YYYY-MM-DD")
                  : null
                : null
            }
            placeholder={translation.defaultDatePickerPlaceholder}
            allowClear={false}
            format={dateFormat}
            onChange={(date, dateString) => {
              if (date >= moment() && date !== null) {
                setForm({
                  ...form,
                  commitmentDate: date,
                });
              }
            }}
          />
        </Row>
        {/* Buttons */}
        <Row type="flex" justify="space-between" className="rowButton">
          <Button
            className="cancel"
            onClick={() =>
              dispatch(SaleScenarioActions.openModalSaleCommitment())
            }
          >
            CANCELAR
          </Button>
          <Button onClick={submitForm}>
            {form.key === null ? "ADICIONAR" : "ATUALIZAR"}
          </Button>
        </Row>
      </Spin>
    </ModalCustom>
  );
};
export default ModalCommitment;
