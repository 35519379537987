import styled from "styled-components";

export const Container = styled.div`
  margin-top: ${(props) => (props.marginTop != null ? props.marginTop : "0px")};
  margin-bottom: ${(props) =>
    props.marginBottom != null ? props.marginBottom : "0px"};
  margin-left: ${(props) =>
    props.marginLeft != null ? props.marginLeft : "0px"};

  .backIcon {
    vertical-align: middle;
  }

  .back-container {
    text-decoration: none;
    margin-right: 15px;
    padding-right: 10px;
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #d44c4c !important;
    cursor: pointer;

    svg {
      color: #d44c4c;
    }
  }

  .pageTreeInative {
    font-family: Asap;
    font-weight: normal;
    font-size: 12px !important;
    color: #c4c4c4;
    &::after {
      content: ">";
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .pageTreeActive {
    font-family: Asap;
    font-weight: normal;
    font-size: 12px !important;
    color: #684e94;
    font-weight: bold;
  }
`;
