import styled from "styled-components";

export const TableScore = styled.table`
  font-family: "Asap", sans-serif;
  border: 2px solid #ffffff;
  background-color: #eeeeee;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  td,
  th {
    border: 1px solid #ffffff;
    padding: 6px 4px;
  }
  tbody td {
    font-size: 13px;
    color: #4b4b4b;
  }
  thead {
    background: #ffffff;
    border-bottom: 4px solid #9074bf;
  }
  thead th {
    font-size: 15px;
    font-weight: bold;
    color: #9074bf;
    text-align: center;
    border-left: 2px solid #9074bf;
  }
  thead th:first-child {
    border-left: none;
  }
`;
