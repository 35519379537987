import moment from "moment";
import { getTwoDecimalDigits } from "./helpersMethodsService";
import { getLotDietStrategyCurve } from "./lotService";

export async function returnDietStrategyCurve(
  groupId,
  farmId,
  entityId,
  entity = "Lot",
  referenceDate = moment(),
  signal,
  dietStrategy
) {
  let result = [];
  try {
    switch (entity) {
      case "Lot":
        if (dietStrategy != null && dietStrategy.generic) {
          const { initialDate } = dietStrategy;
          const {
            data: {
              results: { dietStrategyCurveData },
            },
          } = await getLotDietStrategyCurve({
            groupId,
            farmId,
            id: entityId,
            signal,
          });
          result = dietStrategyCurveData.map((dscd) => {
            return {
              x: moment(initialDate).add(dscd.day, "days").toDate(),
              y: getTwoDecimalDigits(dscd.weight),
              toolTipContent: `<span style='"'color: {color};'"'>{name}:</span> ${moment(
                initialDate
              )
                .add(dscd.day, "days")
                .format("DD/MM/YY")}  | {y}`,
            };
          });
        } else {
          const {
            data: {
              results: { dietStrategyCurveData },
            },
          } = await getLotDietStrategyCurve({
            groupId,
            farmId,
            id: entityId,
            signal,
          });
          result = dietStrategyCurveData.map((dscd) => {
            return {
              x: moment(referenceDate).add(dscd.day, "days").toDate(),
              y: getTwoDecimalDigits(dscd.weight),
              toolTipContent: `<span style='"'color: {color};'"'>{name}:</span> ${moment(
                referenceDate
              )
                .add(dscd.day, "days")
                .format("DD/MM/YY")}  | {y}`,
            };
          });
        }
        break;
      default:
        break;
    }
  } catch (error) {
    return null;
  }
  return result;
}

export function calculateCurve(
  asymptoticWeight,
  date,
  correctionFactor,
  maturationRate,
  curveType,
  amountDays = 800,
  startDay = 0
) {
  let result = [];
  let day = startDay;

  if (date != null) {
    switch (curveType) {
      case "Logistic":
        do {
          result.push({
            x: moment(date).add(day, "days").toDate(),
            y: getTwoDecimalDigits(
              1.09441891891892 *
                (asymptoticWeight /
                  (1 + correctionFactor * Math.exp(maturationRate * day)))
            ),
            toolTipContent: `<span style='"'color: {color};'"'>{name}:</span> {x} | {y}`,
          });

          day++;
        } while (day <= amountDays);
        break;
      case "Brody":
        do {
          result.push({
            x: moment(date).add(day, "days").toDate(),
            y: getTwoDecimalDigits(
              1.21843243243243 *
                (asymptoticWeight *
                  (1 - correctionFactor * Math.exp(-maturationRate * day)))
            ),
            toolTipContent: `<span style='"'color: {color};'"'>{name}:</span> {x} | {y}`,
          });
          day++;
        } while (day <= amountDays);
        break;
      case "VonBertalanffy":
        do {
          result.push({
            x: moment(date).add(day, "days").toDate(),
            y: getTwoDecimalDigits(
              1.29818918918919 *
                (asymptoticWeight *
                  Math.pow(
                    1 - correctionFactor * Math.exp(-maturationRate * day),
                    3
                  ))
            ),
            toolTipContent: `<span style='"'color: {color};'"'>{name}:</span> {x} | {y}`,
          });
          day++;
        } while (day <= amountDays);
        break;
      default:
        break;
    }
  }
  return result;
}

export function calculateGdp(
  startDay,
  endDay,
  correctionFactor,
  maturationRate,
  curveType,
  asymptoticWeight
) {
  const amountDays = endDay - startDay;
  let startWeight = 0;
  let endWeight = 0;

  switch (curveType) {
    case "Logistic":
      startWeight = getTwoDecimalDigits(
        1.09441891891892 *
          (asymptoticWeight /
            (1 + correctionFactor * Math.exp(maturationRate * startDay)))
      );
      endWeight = getTwoDecimalDigits(
        1.09441891891892 *
          (asymptoticWeight /
            (1 + correctionFactor * Math.exp(maturationRate * endDay)))
      );
      break;
    case "Brody":
      startWeight = getTwoDecimalDigits(
        1.21843243243243 *
          (asymptoticWeight *
            (1 - correctionFactor * Math.exp(-maturationRate * startDay)))
      );
      endWeight = getTwoDecimalDigits(
        1.21843243243243 *
          (asymptoticWeight *
            (1 - correctionFactor * Math.exp(-maturationRate * endDay)))
      );
      break;
    case "VonBertalanffy":
      startWeight = getTwoDecimalDigits(
        1.29818918918919 *
          (asymptoticWeight *
            Math.pow(
              1 - correctionFactor * Math.exp(-maturationRate * startDay),
              3
            ))
      );
      endWeight = getTwoDecimalDigits(
        1.29818918918919 *
          (asymptoticWeight *
            Math.pow(
              1 - correctionFactor * Math.exp(-maturationRate * endDay),
              3
            ))
      );
      break;
    default:
      break;
  }

  const gdp = (endWeight - startWeight) / amountDays;

  return gdp;
}
