import apiCommons from "../config/api_commons";
import getHeaders from "./getHeaders";
// import axios from "axios";

// const apiCommons = axios.create({
//   baseURL: "http://localhost:8083",
// });

export function findAllFarmCurrency(payload) {
  const { groupId, farmId } = payload;
  return apiCommons.get(
    `/bovexo/${groupId}/farms/${farmId}/farm/currencies`,
    getHeaders(false, null)
  );
}

export function findFarmStandardCurrency(payload) {
  const { groupId, farmId } = payload;
  return apiCommons.get(
    `/bovexo/${groupId}/farms/${farmId}/farm/currencies/default`,
    getHeaders(false, null)
  );
}

export function saveFarmCurrency(payload) {
  const { groupId, farmId, id, body } = payload;
  if (id === null) {
    return apiCommons.post(
      `/bovexo/${groupId}/farms/${farmId}/farm/currencies`,
      body,
      getHeaders(false, null)
    );
  } else {
    return apiCommons.patch(
      `/bovexo/${groupId}/farms/${farmId}/farm/currencies/${id}`,
      body,
      getHeaders(false, null)
    );
  }
}

export function inactivateFarmCurrency(payload) {
  const { groupId, farmId, id } = payload;
  return apiCommons.patch(
    `/bovexo/${groupId}/farms/${farmId}/farm/currencies/${id}/inactive`,
    null,
    getHeaders(false, null)
  );
}

export function activateFarmCurrency(payload) {
  const { groupId, farmId, id } = payload;
  return apiCommons.patch(
    `/bovexo/${groupId}/farms/${farmId}/farm/currencies/${id}/active`,
    null,
    getHeaders(false, null)
  );
}

export function favoriteFarmCurrency(payload) {
  const { groupId, farmId, id } = payload;
  return apiCommons.patch(
    `/bovexo/${groupId}/farms/${farmId}/farm/currencies/${id}/favorite`,
    null,
    getHeaders(false, null)
  );
}
