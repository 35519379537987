import { ConfigProvider, Spin } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
// Languages
import en_US from "antd/lib/locale-provider/en_US";
import es_ES from "antd/lib/locale-provider/es_ES";
import pt_BR from "antd/lib/locale-provider/pt_BR";
/* Redux libs */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as AccountFarmActions } from "../../store/ducks/accountFarm";
import { Creators as AppActions } from "../../store/ducks/app";
import { Creators as UserActions } from "../../store/ducks/user";

/* Styles */
import {
  ButtonCollapse,
  CollapseContainer,
  Container,
  Header,
  LogoContainer,
  LogoContainerMobile,
  Main,
  Wrapper,
} from "./styles";

/* Components */
import LogoFull from "../../components/admin/logos/logo_full";
import LogoMinimize from "../../components/admin/logos/logo_minimize";
import SideBar from "../../components/admin/sidebar";
import TopBar from "../../components/admin/topbar";
/* Modals */
import AboutModal from "../../components/modals/about";
import AlmostExpiredModal from "../../components/modals/almostExpired";
import ContactUsModal from "../../components/modals/contactUs";
import ExpiredTrialAccountModal from "../../components/modals/expiredTrialAccountModal";
import ExportReportModal from "../../components/modals/exportReport";
import GroupAndFarmsModal from "../../components/modals/groupAndFarms";
import SessionExpiredModal from "../../components/modals/sessionExpired";
import AnimalUploadModal from "../../components/modals/uploads/animalUpload";
import AnimalWeightsUploadModal from "../../components/modals/uploads/animalWeightsUpload";
import SupplementUploadModal from "../../components/modals/uploads/supplementUpload";
import WelcomeTrialModal from "../../components/modals/welcomeTrial";

/* Drawers */
import DrawerEditAnimal from "../../components/drawers/animal/edit";
import DrawerNewAnimal from "../../components/drawers/animal/new";
import DrawerChangePasswordLogged from "../../components/drawers/changePassword/changePasswordLogged";
import DrawerDisplayDietStrategy from "../../components/drawers/dietStrategy/details";
import DrawerEditProfile from "../../components/drawers/editProfile";
import DrawerHandlingOperation from "../../components/drawers/handlingOperation";
import DrawerLotProduction from "../../components/drawers/lot";
import DrawerLotDismember from "../../components/drawers/lotDismember";
import DrawerLotSupplementConsumption from "../../components/drawers/lotSupplementConsumption";
import NewLotAnimalsReceipt from "../../components/drawers/newLotAnimalsReceipt";
import DrawerNotifications from "../../components/drawers/notifications";
import DrawerPicket from "../../components/drawers/picket";
import DrawerProfitCenter from "../../components/drawers/profitCenter";
import FinancialTransactionUploadModal from "../../components/modals/uploads/financialTransactionUpload";

/* Icons */
import MenuBars from "../../components/utils/icons/menu_bars";

/* Routes */
import RoutesAdmin from "../../routes/admin";

/* Services */
import LogoVertical from "../../components/login/logos/logoVertical";
import { ModuleContextProvider } from "../../contexts/moduleContext";
import { PicketSupplementSupplyContextProvider } from "../../contexts/picketSupplementSupplyContext";
import { getNotificationIndex } from "../../services/notificationsService";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOfSubcribes: [],
      newNotificationFromWS: null,
    };
    // this.stomp = Stomp.over(
    //   () => new WebSocket(process.env.REACT_APP_BOVEXO_WEBSOCKET_URL)
    // );
    // this.stomp.debug = () => {};
  }

  async componentDidMount() {
    const {
      userActions,
      accountFarmActions,
      app: {
        user,
        groupSelected,
        farmSelected,
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
        languageSelected,
        user: { id },
      },
    } = this.props;

    /** Set locale */
    moment.locale(languageSelected);

    /** Get index Groups and Farms */
    if (Object.entries(user).length > 0)
      userActions.indexUserGroupsAndFarmsDescriptions(id);
    if (
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      this.getNotifications(groupSelected, farmSelected);
    }
    // this.connect(groupSelected, farmSelected);

    /** Verify AccountFarm */
    if (groupId && farmId) accountFarmActions.checkAccountFarm(groupId, farmId);

    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  async shouldComponentUpdate(nextProps) {
    const differentGroup =
      this.props.app.groupSelected?.id !== nextProps.app.groupSelected?.id;
    const differentFarm =
      this.props.app.farmSelected?.id !== nextProps.app.farmSelected?.id;
    const differentLanguage =
      this.props.app.languageSelected !== nextProps.app.languageSelected;
    if (differentLanguage) {
      moment.locale(nextProps.app.languageSelected);
    }
    if (differentGroup || differentFarm) {
      // this.unSubscribeOnTopic();
      if (
        Object.entries(nextProps.app.groupSelected).length > 0 &&
        Object.entries(nextProps.app.farmSelected).length > 0
      ) {
        // const { id: groupId } = nextProps.app.groupSelected;
        // const { id: farmId } = nextProps.app.farmSelected;
        // this.subscribeOnTopic(groupId, farmId);
        this.getNotifications(
          nextProps.app.groupSelected,
          nextProps.app.farmSelected
        );
      } else if (Object.entries(nextProps.app.farmSelected).length === 0) {
        // const { id: groupId } = nextProps.app.groupSelected;
        // this.subscribeOnTopic(groupId, null);
        this.getNotifications(nextProps.app.groupSelected, null);
      }
    }
    return true;
  }

  getNotifications = async (groupSelected, farmSelected) => {
    const { id: groupId } = groupSelected;
    const { appActions } = this.props;
    if (farmSelected != null) {
      const { id: farmId } = farmSelected;
      try {
        const {
          data: { results: notifications },
        } = await getNotificationIndex({
          groupId,
          farmId,
          withoutPagination: false,
          sorter: {},
          filters: "",
          page: 0,
          size: 10,
          justNotRead: true,
        });
        const newNot =
          notifications != null && notifications?.content.length > 0
            ? notifications?.content.map((n) => {
                n.createdAt = moment(n.createdAt)
                  .subtract(3, "hours")
                  .format("DD/MM/YYYY - HH:mm");
                return n;
              })
            : [];
        appActions.setNotifications({
          content: newNot,
          totalElements: notifications?.totalElements,
        });
      } catch (error) {}
    } else {
      appActions.setNotifications({ content: [], totalElements: 0 });
    }
  };

  // connect = (groupSelected, farmSelected) => {
  //   this.stomp.connect(
  //     {},
  //     () => {
  //       this.stomp.subscribe("/topic/notification", this.onMessageReceive);
  //       if (
  //         Object.entries(groupSelected).length > 0 &&
  //         Object.entries(farmSelected).length > 0
  //       ) {
  //         const { id: groupId } = groupSelected;
  //         const { id: farmId } = farmSelected;
  //         this.subscribeOnTopic(groupId, farmId);
  //       }
  //     },
  //     () => {
  //       console.error("Nao Conectou");
  //     }
  //   );
  // };

  // subscribeOnTopic(groupId, farmId) {
  //   if (this.stomp.connected && this.stomp.active) {
  //     let sub1 = null;
  //     let sub2 = null;
  //     sub1 = this.stomp.subscribe(
  //       `/topic/notification-${groupId}`,
  //       this.onMessageReceive
  //     );
  //     if (farmId != null) {
  //       sub2 = this.stomp.subscribe(
  //         `/topic/notification-${groupId}-${farmId}`,
  //         this.onMessageReceive
  //       );
  //     }
  //     this.setState({
  //       listOfSubcribes: [sub1, sub2],
  //     });
  //   }
  // }

  // unSubscribeOnTopic() {
  //   if (this.stomp.connected && this.stomp.active) {
  //     const { listOfSubcribes } = this.state;
  //     listOfSubcribes.length > 0 &&
  //       listOfSubcribes.forEach((subscribe) => {
  //         if (subscribe != null) subscribe.unsubscribe();
  //       });
  //   }
  // }

  // onMessageReceive = (payload) => {
  //   const { appActions } = this.props;
  //   let newNot = JSON.parse(payload.body);
  //   newNot.read = false;
  //   newNot.createdAt = moment(newNot.newNot).format("DD/MM/YYYY - HH:mm");
  //   appActions.addNewNotificationFromWS(newNot);
  //   this.setState({
  //     newNotificationFromWS: newNot,
  //   });
  // };

  showSystemAbout = () => {
    const { appActions } = this.props;
    appActions.showOrHideSystemAboutModal();
  };

  updateWindowDimensions = () => {
    this.setState({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    });
  };

  render() {
    const {
      app: {
        isLogged,
        token,
        collapse_menu_admin: bCollapse,
        languageSelected,
        groupSelected,
        farmSelected,
      },
      appActions,
    } = this.props;

    const { screenWidth } = this.state;

    const Logo = () => (bCollapse === true ? <LogoMinimize /> : <LogoFull />);

    const Collapse = () => <MenuBars />;

    if (!isLogged && token.length < 10) {
      return <Redirect to="/login" />;
    }

    return (
      <ModuleContextProvider>
        <ConfigProvider
          locale={
            languageSelected === "pt_br"
              ? pt_BR
              : languageSelected === "en"
              ? en_US
              : languageSelected === "es"
              ? es_ES
              : pt_BR
          }
        >
          <PicketSupplementSupplyContextProvider>
            <Wrapper>
              <Spin
                className="spinAdmin"
                spinning={
                  Object.entries(groupSelected).length > 0 ||
                  Object.entries(farmSelected).length > 0
                    ? false
                    : true
                }
              >
                {screenWidth <= 768 && (
                  <LogoContainerMobile onClick={this.showSystemAbout}>
                    <LogoVertical />
                  </LogoContainerMobile>
                )}
                <Header>
                  <LogoContainer
                    collapse={bCollapse}
                    onClick={this.showSystemAbout}
                  >
                    <Logo />
                  </LogoContainer>
                  <CollapseContainer>
                    <ButtonCollapse
                      onClick={() => appActions.collapse_menu_admin()}
                    >
                      <Collapse />
                    </ButtonCollapse>
                  </CollapseContainer>
                  <TopBar />
                </Header>
                <Container id="container-admin-general">
                  <SideBar collapse={bCollapse} />
                  <Main id="container-admin-main">
                    <RoutesAdmin />
                  </Main>
                </Container>
              </Spin>
              {/* MODALS */}
              <SessionExpiredModal />
              <AlmostExpiredModal />
              <GroupAndFarmsModal />
              <AnimalWeightsUploadModal />
              <AnimalUploadModal />
              <ContactUsModal />
              <ExportReportModal />
              <AboutModal />
              <ExpiredTrialAccountModal />
              <WelcomeTrialModal />
              <SupplementUploadModal />
              <FinancialTransactionUploadModal />
              {/* DRAWERS */}
              <NewLotAnimalsReceipt />
              <DrawerEditAnimal />
              <DrawerLotProduction />
              <DrawerLotDismember />
              <DrawerHandlingOperation />
              <DrawerNotifications
                newNotificationFromWS={this.state.newNotificationFromWS}
              />
              <DrawerPicket />
              <DrawerProfitCenter />
              <DrawerEditProfile />
              <DrawerDisplayDietStrategy />
              <DrawerChangePasswordLogged />
              <DrawerNewAnimal />
              <DrawerLotSupplementConsumption />
            </Wrapper>
          </PicketSupplementSupplyContextProvider>
        </ConfigProvider>
      </ModuleContextProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  userActions: bindActionCreators(UserActions, dispatch),
  accountFarmActions: bindActionCreators(AccountFarmActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));
