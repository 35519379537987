import apiOptimization from "../config/api_optimization";
import getHeaders from "./getHeaders";

export function indexPurchaseScenario(payload) {
  const { groupId, farmId, page, sorter, filters, size, signal } = payload;
  let sortS = "";
  if (sorter && Object.entries(sorter).length > 0) {
    const { field, order } = sorter;

    const orderS = order && order === "descend" ? "DESC" : "ASC";

    sortS = `&sort=${field}&direction=${orderS}`;
  }

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/purchases?
    ${page ? `&page=${page - 1}` : ""}
    ${size && `&size=${size}`}
    ${sortS}
    ${filters !== "" && filters ? `&search=${filters}` : ""}`,
    getHeaders(false, signal)
  );
}

export function indexScenariosResults(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/purchases/${id}/results`,
    getHeaders(false, signal)
  );
}

export function indexScenariosResultsItems(payload) {
  const { groupId, farmId, id, purchaseScenarioResultId, signal } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/purchases/${id}/results/${purchaseScenarioResultId}/items`,
    getHeaders(false, signal)
  );
}

export function indexDropDownPurchaseScenario(payload) {
  const { groupId, farmId, signal } = payload;
  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/purchases?withoutPagination=true`,
    getHeaders(false, signal)
  );
}

export function storePurchaseScenario(payload) {
  const { groupId, farmId, body, signal } = payload;

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/purchases`,
    body,
    getHeaders(false, signal)
  );
}
export function updatePurchaseScenario(payload) {
  const { groupId, farmId, id, body, signal } = payload;

  return apiOptimization.put(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/purchases/${id}`,
    body,
    getHeaders(false, signal)
  );
}

export function showPurchaseScenario(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiOptimization.get(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/purchases/${id}`,
    getHeaders(false, signal)
  );
}

export function reprocessPurchaseScenario(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/purchases/${id}/reprocess`,
    null,
    getHeaders(false, signal)
  );
}

export function inactivatePurchaseScenario(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/purchases/${id}/inactivate`,
    null,
    getHeaders(false, signal)
  );
}

export function duplicatePurchaseScenario(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/purchases/${id}/duplicate`,
    null,
    getHeaders(false, signal)
  );
}

export function saveScenariosResultsItemsFavorite(payload) {
  const { groupId, farmId, id, purchaseScenarioResultId, items, signal } =
    payload;

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/purchases/${id}/results/${purchaseScenarioResultId}/items`,
    items,
    getHeaders(false, signal)
  );
}

export function downloadComparePurchaseScenarioReport(payload) {
  const { groupId, farmId, body } = payload;

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/purchases/download/pdf`,
    body,
    getHeaders(false, null, true)
  );
}
