import React from "react";

// import { Container } from './styles';

const StopIcon = ({ isRed = false }) =>
  isRed ? (
    <svg
      id="no-stopping"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 14 14"
    >
      <g id="Group_1270" data-name="Group 1270">
        <path
          id="Path_1083"
          data-name="Path 1083"
          d="M7,0a7,7,0,1,0,7,7A7.008,7.008,0,0,0,7,0ZM7,12.25A5.256,5.256,0,0,1,1.75,7a5.183,5.183,0,0,1,.972-3.041l7.319,7.319A5.183,5.183,0,0,1,7,12.25Zm4.278-2.209L3.959,2.722A5.183,5.183,0,0,1,7,1.75,5.256,5.256,0,0,1,12.25,7,5.183,5.183,0,0,1,11.278,10.041Z"
          fill="#d44c4c"
        />
      </g>
    </svg>
  ) : (
    <svg
      id="no-stopping"
      xmlns="http://www.w3.org/2000/svg"
      width="17.541"
      height="17.541"
      viewBox="0 0 17.541 17.541"
    >
      <g id="Group_1270" data-name="Group 1270">
        <path
          id="Path_1083"
          data-name="Path 1083"
          d="M8.771,0a8.771,8.771,0,1,0,8.771,8.771A8.781,8.781,0,0,0,8.771,0Zm0,15.348A6.586,6.586,0,0,1,2.193,8.771,6.493,6.493,0,0,1,3.41,4.961l9.17,9.17A6.493,6.493,0,0,1,8.771,15.348Zm5.36-2.768L4.961,3.41a6.493,6.493,0,0,1,3.81-1.218,6.586,6.586,0,0,1,6.578,6.578A6.493,6.493,0,0,1,14.131,12.58Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
export default StopIcon;
