import React, { createContext, useCallback, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as BoitelSaleScenarioActions } from "../../store/ducks/boitelSaleScenario";
//  Services
export const BoitelSaleScenarioNewContext = createContext(null);

export const BoitelSaleScenarioNewContextProvider = ({ children }) => {
  const { boitelSaleScenarioData, clearFields } = useSelector(
    (state) => state.boitelSaleScenario
  );
  const dispatch = useDispatch();
  const [form, setForm] = useState(() => boitelSaleScenarioData);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(true);
  const [activeTab, setActiveTab] = useState("tab1");

  /** Variables lot tab */
  const [supplierId, setSupplierId] = useState(null);
  const [allLots, setAllLots] = useState(null);
  const [allDietStrategies, setAllDietStrategies] = useState([]);
  const [lotsSelected, setLotsSelected] = useState([]);
  const [lotsSelectedKeys, setLotsSelectedKeys] = useState([]);
  const [lotsSelectForLotProduction, setLotsSelectForLotProduction] = useState(
    []
  );
  const [lotsSelectForLotProductionKeys, setLotsSelectForLotProductionKeys] =
    useState([]);
  const [lotsToRemoveKeys, setLotsToRemoveKeys] = useState([]);
  const [selectAllLotsForProduction, setSelectAllLotsForProduction] =
    useState(false);

  // Effects
  useEffect(() => {
    if (clearFields === true) {
      setActiveTab("tab1");
      setForm({
        id: null,
        name: null,
        parameter: {
          allowSeparateSell: true,
          animalsInTruck: null,
          animalsInTruckUnit: "cab",
          commitments: [],
          deadlineGoal: null,
          goalType: "MaxProfit",
          id: null,
          lots: [],
          // lots: [
          //   {
          //     dietStrategies: [],
          //     id: null,
          //     lotId: null,
          //     supplierId: null,
          //   },
          // ],
          marketAnalysisId: null,
          minimumAge: null,
          maximumAge: null,
          maximumWeight: 720,
          minimumWeight: null,
          retroactive: false,
          retroactiveDate: null,
          useDefaultMaximumWeight: true,
          useCustomCarcassHarnessing: false,
          carcassHarnessing: null,
          strongAgeRestriction: true,
          strongWeightRestriction: true,
          strongAnimalsInTruckRestriction: true,
        },
      });
      setSupplierId(null);
      setAllLots([]);
      setAllDietStrategies([]);
      setLotsSelected([]);
      setLotsSelectedKeys([]);
      setLotsSelectForLotProduction([]);
      setLotsSelectForLotProductionKeys([]);
      setLotsToRemoveKeys([]);
      setSelectAllLotsForProduction(false);
      dispatch(BoitelSaleScenarioActions.clearFields());
    }
  }, [clearFields, dispatch]);

  /** Variables lot tab - End */

  const [modalSaleCommitmentData, setModalSaleCommitmentData] = useState({
    key: null,
    saleCommitmentType: "AmountAnimals",
    commitmentValue: null,
    commitmentDate: null,
  });

  const goPreviousTab = useCallback(() => {
    setActiveTab((old) =>
      old === "tab2" ? "tab1" : old === "tab3" ? "tab2" : "tab1"
    );
  }, []);

  return (
    <BoitelSaleScenarioNewContext.Provider
      value={{
        form,
        setForm,
        shouldBlockNavigation,
        setShouldBlockNavigation,
        supplierId,
        setSupplierId,
        allLots,
        setAllLots,
        allDietStrategies,
        setAllDietStrategies,
        lotsSelected,
        setLotsSelected,
        lotsSelectedKeys,
        setLotsSelectedKeys,
        lotsSelectForLotProduction,
        setLotsSelectForLotProduction,
        lotsSelectForLotProductionKeys,
        setLotsSelectForLotProductionKeys,
        lotsToRemoveKeys,
        setLotsToRemoveKeys,
        selectAllLotsForProduction,
        setSelectAllLotsForProduction,
        modalSaleCommitmentData,
        setModalSaleCommitmentData,
        activeTab,
        setActiveTab,
        goPreviousTab,
      }}
    >
      {children}
    </BoitelSaleScenarioNewContext.Provider>
  );
};
