import { Card, Col, Dropdown, Menu, Row, Table, Tabs } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import "moment/locale/en-nz";
import "moment/locale/es";
import "moment/locale/pt-br";

/* Redux libs */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as ProfitCenterActions } from "../../store/ducks/profitCenter";

/** Styles Components */
import { Container, Title } from "./styles";

/** Components */
import ButtonStandard from "../../components/utils/button";
import MenuIcon from "../../components/utils/table/icons/menu";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import TagStatus from "../../components/utils/tagStatus";

/** Services */
import Axios from "axios";
import { getProfitCenterDashboard } from "../../services/profitCenterService";

const { Column } = Table;

class ProfitCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabSelect: "2",
      dashboardExtensive: {
        amountAnimals: null,
        amountLots: null,
        aVGWeight: null,
        aVGAge: null,
        gmd: null,
      },
      dashboardIntensive: {
        amountAnimals: null,
        amountLots: null,
        aVGWeight: null,
        aVGAge: null,
        gmd: null,
      },
      dashboardSemiIntensive: {
        amountAnimals: null,
        amountLots: null,
        aVGWeight: null,
        aVGAge: null,
        gmd: null,
      },
    };
  }

  static propTypes = {
    profitCenterActions: PropTypes.any.isRequired,
    app: PropTypes.any.isRequired,
    profitCenter: PropTypes.any.isRequired,
  };

  signal = Axios.CancelToken.source();

  componentWillUnmount() {
    this.signal.cancel("Cancelando apis");
  }

  async componentDidMount() {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;
    if (
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      this.fetchData(groupSelected, farmSelected);
      this.fetchDataRecreateFatten(groupSelected, farmSelected);
      this.fetchDashboards(groupSelected, farmSelected);
    }
  }

  fetchDashboards = async (groupSelected, farmSelected) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    // Get extensive
    try {
      const [
        amountAnimalsExtensive,
        amountLotsExtensive,
        aVGWeightExtensive,
        aVGAgeExtensive,
        gmdExtensive,
      ] = await Promise.all([
        getProfitCenterDashboard({
          groupId,
          farmId,
          productionSubSystem: "Extensive",
          dashboard: "AmountAnimals",
          signal: this.signal,
        }),
        getProfitCenterDashboard({
          groupId,
          farmId,
          productionSubSystem: "Extensive",
          dashboard: "AmountLots",
          signal: this.signal,
        }),
        getProfitCenterDashboard({
          groupId,
          farmId,
          productionSubSystem: "Extensive",
          dashboard: "AVGWeight",
          signal: this.signal,
        }),
        getProfitCenterDashboard({
          groupId,
          farmId,
          productionSubSystem: "Extensive",
          dashboard: "AVGAge",
          signal: this.signal,
        }),
        getProfitCenterDashboard({
          groupId,
          farmId,
          productionSubSystem: "Extensive",
          dashboard: "GMD",
          signal: this.signal,
        }),
      ]);
      this.setState({
        dashboardExtensive: {
          amountAnimals: amountAnimalsExtensive.data.results,
          amountLots: amountLotsExtensive.data.results,
          aVGWeight: Number(aVGWeightExtensive.data.results).toFixed(2),
          aVGAge: Number(aVGAgeExtensive.data.results).toFixed(0),
          gmd: Number(gmdExtensive.data.results).toFixed(2),
        },
      });
    } catch (error) {}
    // Get semiIntensive
    try {
      const [
        amountAnimalsSemiIntensive,
        amountLotsSemiIntensive,
        aVGWeightSemiIntensive,
        aVGAgeSemiIntensive,
        gmdSemiIntensive,
      ] = await Promise.all([
        getProfitCenterDashboard({
          groupId,
          farmId,
          productionSubSystem: "SemiIntensive",
          dashboard: "AmountAnimals",
          signal: this.signal,
        }),
        getProfitCenterDashboard({
          groupId,
          farmId,
          productionSubSystem: "SemiIntensive",
          dashboard: "AmountLots",
          signal: this.signal,
        }),
        getProfitCenterDashboard({
          groupId,
          farmId,
          productionSubSystem: "SemiIntensive",
          dashboard: "AVGWeight",
          signal: this.signal,
        }),
        getProfitCenterDashboard({
          groupId,
          farmId,
          productionSubSystem: "SemiIntensive",
          dashboard: "AVGAge",
          signal: this.signal,
        }),
        getProfitCenterDashboard({
          groupId,
          farmId,
          productionSubSystem: "SemiIntensive",
          dashboard: "GMD",
          signal: this.signal,
        }),
      ]);
      this.setState({
        dashboardSemiIntensive: {
          amountAnimals: amountAnimalsSemiIntensive.data.results,
          amountLots: amountLotsSemiIntensive.data.results,
          aVGWeight: Number(aVGWeightSemiIntensive.data.results).toFixed(2),
          aVGAge: Number(aVGAgeSemiIntensive.data.results).toFixed(0),
          gmd: Number(gmdSemiIntensive.data.results).toFixed(2),
        },
      });
    } catch (error) {}
    // Get intensive
    try {
      const [
        amountAnimalsIntensive,
        amountLotsIntensive,
        aVGWeightIntensive,
        aVGAgeIntensive,
        gmdIntensive,
      ] = await Promise.all([
        getProfitCenterDashboard({
          groupId,
          farmId,
          productionSubSystem: "Intensive",
          dashboard: "AmountAnimals",
          signal: this.signal,
        }),
        getProfitCenterDashboard({
          groupId,
          farmId,
          productionSubSystem: "Intensive",
          dashboard: "AmountLots",
          signal: this.signal,
        }),
        getProfitCenterDashboard({
          groupId,
          farmId,
          productionSubSystem: "Intensive",
          dashboard: "AVGWeight",
          signal: this.signal,
        }),
        getProfitCenterDashboard({
          groupId,
          farmId,
          productionSubSystem: "Intensive",
          dashboard: "AVGAge",
          signal: this.signal,
        }),
        getProfitCenterDashboard({
          groupId,
          farmId,
          productionSubSystem: "Intensive",
          dashboard: "GMD",
          signal: this.signal,
        }),
      ]);
      this.setState({
        dashboardIntensive: {
          amountAnimals: amountAnimalsIntensive.data.results,
          amountLots: amountLotsIntensive.data.results,
          aVGWeight: Number(aVGWeightIntensive.data.results).toFixed(2),
          aVGAge: Number(aVGAgeIntensive.data.results).toFixed(0),
          gmd: Number(gmdIntensive.data.results).toFixed(2),
        },
      });
    } catch (error) {}
  };

  handleEdit = async (id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      profitCenterActions,
    } = this.props;
    await Promise.all([
      profitCenterActions.showProfitCenter(groupId, farmId, id),
      profitCenterActions.showDrawer(),
    ]);
  };

  handleTabClick = (key, e) => {
    this.setState({
      tabSelect: key,
    });
  };

  // Methods table Growth

  fetchData = async (
    groupSelected,
    farmSelected,
    page = 0,
    sorter = {},
    filters = "",
    size = 10
  ) => {
    const { profitCenterActions } = this.props;
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    await profitCenterActions.indexProfitCenter(
      groupId,
      farmId,
      page,
      sorter,
      filters,
      size,
      "Growth"
    );
  };

  handleTableChange = async (pagination, filters, sorter) => {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;

    let search = "";

    if (Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v.length > 0)
          search = search === "" ? `${k}='${v}'` : `${search};${k}='${v}'`;
      });
    }
    await this.fetchData(
      groupSelected,
      farmSelected,
      pagination.current,
      sorter,
      search,
      pagination.pageSize
    );
  };

  // Methods table RecreateFatten

  fetchDataRecreateFatten = async (
    groupSelected,
    farmSelected,
    page = 0,
    sorter = {},
    filters = "",
    size = 10
  ) => {
    const { profitCenterActions } = this.props;
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    await profitCenterActions.indexProfitCenter(
      groupId,
      farmId,
      page,
      sorter,
      filters,
      size,
      "RecreateFatten"
    );
  };

  handleTableChangeRecreateFatten = async (pagination, filters, sorter) => {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;

    let search = "";

    if (Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v.length > 0)
          search = search === "" ? `${k}='${v}'` : `${search};${k}='${v}'`;
      });
    }
    await this.fetchDataRecreateFatten(
      groupSelected,
      farmSelected,
      pagination.current,
      sorter,
      search,
      pagination.pageSize
    );
  };

  render() {
    const {
      profitCenter: { dataGrowth, dataRecreateFatten, isLoading },
      app: { translation },
      profitCenterActions,
    } = this.props;

    const { tabSelect } = this.state;

    const menu = (id) => {
      return (
        <Menu>
          <Menu.Item key="0" onClick={() => this.handleEdit(id)}>
            {translation.table.menu.edit}
          </Menu.Item>
        </Menu>
      );
    };

    return (
      <Container>
        {/* HEADER */}
        {/* <Row type="flex">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <PageTitle>{translation.profitCenter.title}</PageTitle>
          </Col>
        </Row> */}
        {/* DASHSBOARDS */}
        {/* <Row type="flex" style={{ padding: "0px 5px" }}>
          <Col xs={24} sm={16} md={8} lg={8} xl={8}>
            <Dashboard>
              <Row type="flex">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <span className="title">EXTENSIVO</span>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                  <span className="subtitle">
                    {dashboardExtensive.amountAnimals === null ? (
                      <Icon type="loading" spin />
                    ) : (
                      dashboardExtensive.amountAnimals
                    )}
                    {" Animais | "}
                    {dashboardExtensive.amountLots === null ? (
                      <Icon type="loading" spin />
                    ) : (
                      dashboardExtensive.amountLots
                    )}
                    {" Lotes"}
                  </span>
                </Col>
              </Row>
              <Row gutter={48} style={{ marginTop: 20 }}>
                <Col span={8}>
                  <Row>
                    <span>GMD</span>
                  </Row>
                  <Row>
                    <span className="value">
                      {dashboardExtensive.gmd === null ? (
                        <Icon type="loading" spin />
                      ) : (
                        `${dashboardExtensive.gmd} kg`
                      )}
                    </span>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <span>Peso médio</span>
                  </Row>
                  <Row>
                    <span className="value">
                      {dashboardExtensive.aVGWeight === null ? (
                        <Icon type="loading" spin />
                      ) : (
                        `${dashboardExtensive.aVGWeight} kg`
                      )}
                    </span>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <span>Idade média</span>
                  </Row>
                  <Row>
                    <span className="value">
                      {dashboardExtensive.aVGAge === null ? (
                        <Icon type="loading" spin />
                      ) : (
                        `${dashboardExtensive.aVGAge} meses`
                      )}
                    </span>
                  </Row>
                </Col>
              </Row>
            </Dashboard>
          </Col>
          <Col xs={24} sm={16} md={7} lg={7} xl={7} offset={1}>
            <Dashboard>
              <Row type="flex">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <span className="title">SEMI-INTENSIVO</span>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                  <span className="subtitle">
                    {dashboardSemiIntensive.amountAnimals === null ? (
                      <Icon type="loading" spin />
                    ) : (
                      dashboardSemiIntensive.amountAnimals
                    )}
                    {" Animais | "}
                    {dashboardSemiIntensive.amountLots === null ? (
                      <Icon type="loading" spin />
                    ) : (
                      dashboardSemiIntensive.amountLots
                    )}
                    {" Lotes"}
                  </span>
                </Col>
              </Row>
              <Row gutter={48} style={{ marginTop: 20 }}>
                <Col span={8}>
                  <Row>
                    <span>GMD</span>
                  </Row>
                  <Row>
                    <span className="value">
                      {dashboardSemiIntensive.gmd === null ? (
                        <Icon type="loading" spin />
                      ) : (
                        `${dashboardSemiIntensive.gmd} kg`
                      )}
                    </span>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <span>Peso médio</span>
                  </Row>
                  <Row>
                    <span className="value">
                      {dashboardSemiIntensive.aVGWeight === null ? (
                        <Icon type="loading" spin />
                      ) : (
                        `${dashboardSemiIntensive.aVGWeight} kg`
                      )}
                    </span>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <span>Idade média</span>
                  </Row>
                  <Row>
                    <span className="value">
                      {dashboardSemiIntensive.aVGAge === null ? (
                        <Icon type="loading" spin />
                      ) : (
                        `${dashboardSemiIntensive.aVGAge} meses`
                      )}
                    </span>
                  </Row>
                </Col>
              </Row>
            </Dashboard>
          </Col>
          <Col xs={24} sm={24} md={7} lg={7} xl={7} offset={1}>
            <Dashboard>
              <Row type="flex">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <span className="title">INTENSIVO</span>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                  <span className="subtitle">
                    {dashboardIntensive.amountAnimals === null ? (
                      <Icon type="loading" spin />
                    ) : (
                      dashboardIntensive.amountAnimals
                    )}
                    {" Animais | "}
                    {dashboardIntensive.amountLots === null ? (
                      <Icon type="loading" spin />
                    ) : (
                      dashboardIntensive.amountLots
                    )}
                    {" Lotes"}
                  </span>
                </Col>
              </Row>
              <Row gutter={48} style={{ marginTop: 20 }}>
                <Col span={8}>
                  <Row>
                    <span>GMD</span>
                  </Row>
                  <Row>
                    <span className="value">
                      {dashboardIntensive.gmd === null ? (
                        <Icon type="loading" spin />
                      ) : (
                        `${dashboardIntensive.gmd} kg`
                      )}
                    </span>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <span>Peso médio</span>
                  </Row>
                  <Row>
                    <span className="value">
                      {dashboardIntensive.aVGWeight === null ? (
                        <Icon type="loading" spin />
                      ) : (
                        `${dashboardIntensive.aVGWeight} kg`
                      )}
                    </span>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <span>Idade média</span>
                  </Row>
                  <Row>
                    <span className="value">
                      {dashboardIntensive.aVGAge === null ? (
                        <Icon type="loading" spin />
                      ) : (
                        `${dashboardIntensive.aVGAge} meses`
                      )}
                    </span>
                  </Row>
                </Col>
              </Row>
            </Dashboard>
          </Col>
        </Row> */}
        {/* GRID */}
        <Row type="flex" className="gridRow">
          <Card className="cardGrid">
            <Row
              type="flex"
              justify="space-between"
              style={{ marginBottom: "19px" }}
            >
              <Col>
                <Title>{translation.profitCenter.table.title}</Title>
              </Col>
              <Col>
                <ButtonStandard
                  buttonType="type8"
                  onClick={() => profitCenterActions.showDrawer("new")}
                >
                  {translation.buttons.buttonNewProfitCenter}
                </ButtonStandard>
              </Col>
            </Row>
            <Tabs
              type="card"
              defaultActiveKey="2"
              activeKey={tabSelect}
              onTabClick={this.handleTabClick}
            >
              {/* Growth */}
              <Tabs.TabPane tab={<span>Cria</span>} key="1">
                <Table
                  rowKey="id"
                  loading={isLoading}
                  dataSource={dataGrowth != null ? dataGrowth.content : []}
                  pagination={{
                    total: dataGrowth != null ? dataGrowth.totalElements : 0,
                    size: dataGrowth != null ? dataGrowth.size : 0,
                    current: dataGrowth != null ? dataGrowth.number + 1 : 0,
                    hideOnSinglePage:
                      dataGrowth !== null &&
                      Object.entries(dataGrowth).length !== 0
                        ? dataGrowth.totalElements > 10
                          ? false
                          : true
                        : true,
                    showSizeChanger: true,
                    pageSizeOptions: [
                      "10",
                      "20",
                      "30",
                      "100",
                      "500",
                      "1000",
                      "2000",
                    ],
                  }}
                  size="small"
                  scroll={{
                    x: true,
                  }}
                  onChange={this.handleTableChange}
                >
                  <Column
                    title={translation.profitCenter.table.columns.status}
                    dataIndex="status"
                    key="status"
                    sorter
                    render={(status) =>
                      status === "Active" ? (
                        <TagStatus
                          background="#C5F1CA"
                          borderColor="#106518"
                          color="#106518"
                        >
                          {translation.status.active}
                        </TagStatus>
                      ) : (
                        <TagStatus
                          background="#FBC7C7"
                          borderColor="#D44C4C"
                          color="#D44C4C"
                        >
                          {translation.status.inactive}
                        </TagStatus>
                      )
                    }
                  />
                  <Column
                    title={translation.profitCenter.table.columns.name}
                    dataIndex="name"
                    sorter
                    key="name"
                    align="left"
                  />
                  <Column
                    title={translation.profitCenter.table.columns.type}
                    dataIndex="productionSystemId"
                    sorter
                    key="productionSystemId"
                    align="left"
                    render={(text, record) => <span>{record.typeName}</span>}
                  />
                  <Column
                    title={
                      translation.profitCenter.table.columns.productionSubSystem
                    }
                    dataIndex="productionSubSystem"
                    sorter
                    key="productionSubSystem"
                    align="left"
                    render={(text) => {
                      switch (text) {
                        case "Extensive":
                          return (
                            <span>
                              {translation.productionSubSystem.extensive}
                            </span>
                          );
                        case "Intensive":
                          return (
                            <span>
                              {translation.productionSubSystem.intensive}
                            </span>
                          );
                        case "SemiIntensive":
                          return (
                            <span>
                              {translation.productionSubSystem.semiIntensive}
                            </span>
                          );
                        default:
                          return <span />;
                      }
                    }}
                  />
                  <Column
                    align="left"
                    render={(text, record) => (
                      <Dropdown
                        overlay={menu(record.id)}
                        trigger={["click"]}
                        key={record.id}
                      >
                        <MenuContainer>
                          <MenuIcon />
                        </MenuContainer>
                      </Dropdown>
                    )}
                  />
                </Table>
              </Tabs.TabPane>
              {/* Recreate and Fatter */}
              <Tabs.TabPane tab={<span>Recria/Engorda</span>} key="2">
                <Table
                  rowKey="id"
                  loading={isLoading}
                  dataSource={
                    dataRecreateFatten != null ? dataRecreateFatten.content : []
                  }
                  pagination={{
                    total:
                      dataRecreateFatten != null
                        ? dataRecreateFatten.totalElements
                        : 0,
                    size:
                      dataRecreateFatten != null ? dataRecreateFatten.size : 0,
                    current:
                      dataRecreateFatten != null
                        ? dataRecreateFatten.number + 1
                        : 0,
                    hideOnSinglePage:
                      dataRecreateFatten !== null &&
                      Object.entries(dataRecreateFatten).length !== 0
                        ? dataRecreateFatten.totalElements > 10
                          ? false
                          : true
                        : true,
                    showSizeChanger: true,
                    pageSizeOptions: [
                      "10",
                      "20",
                      "30",
                      "100",
                      "500",
                      "1000",
                      "2000",
                    ],
                  }}
                  size="small"
                  scroll={{
                    x: true,
                  }}
                  onChange={this.handleTableChangeRecreateFatten}
                >
                  <Column
                    title={translation.profitCenter.table.columns.status}
                    dataIndex="status"
                    key="status"
                    sorter
                    render={(status) =>
                      status === "Active" ? (
                        <TagStatus
                          background="#C5F1CA"
                          borderColor="#106518"
                          color="#106518"
                        >
                          {translation.status.active}
                        </TagStatus>
                      ) : (
                        <TagStatus
                          background="#FBC7C7"
                          borderColor="#D44C4C"
                          color="#D44C4C"
                        >
                          {translation.status.inactive}
                        </TagStatus>
                      )
                    }
                  />
                  <Column
                    title={translation.profitCenter.table.columns.name}
                    dataIndex="name"
                    sorter
                    key="name"
                    align="left"
                  />
                  <Column
                    title={translation.profitCenter.table.columns.type}
                    dataIndex="productionSystemId"
                    sorter
                    key="productionSystemId"
                    align="left"
                    render={(text, record) => <span>{record.typeName}</span>}
                  />
                  <Column
                    title={
                      translation.profitCenter.table.columns.productionSubSystem
                    }
                    dataIndex="productionSubSystem"
                    sorter
                    key="productionSubSystem"
                    align="left"
                    render={(text) => {
                      switch (text) {
                        case "Extensive":
                          return (
                            <span>
                              {translation.productionSubSystem.extensive}
                            </span>
                          );
                        case "Intensive":
                          return (
                            <span>
                              {translation.productionSubSystem.intensive}
                            </span>
                          );
                        case "SemiIntensive":
                          return (
                            <span>
                              {translation.productionSubSystem.semiIntensive}
                            </span>
                          );
                        default:
                          return <span />;
                      }
                    }}
                  />
                  <Column
                    align="left"
                    render={(text, record) => (
                      <Dropdown
                        overlay={menu(record.id)}
                        trigger={["click"]}
                        key={record.id}
                      >
                        <MenuContainer>
                          <MenuIcon />
                        </MenuContainer>
                      </Dropdown>
                    )}
                  />
                </Table>
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </Row>
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  app: state.app,
  profitCenter: state.profitCenter,
});

const mapDispatchToProps = (dispatch) => ({
  profitCenterActions: bindActionCreators(ProfitCenterActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfitCenter)
);
