import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { ConfinementMapDashboardContextProvider } from "../../../../contexts/confinementMapDashboardContext";

import { Col, Empty, Icon, Row, Spin, Tooltip, notification } from "antd";

import ButtonStandard from "../../../../components/utils/button";
import { CardBoxInfoPicket, Container, Title } from "./styles";

import { generateConfinementMapDashboardData } from "../../../../services/confinementMapService";
import { numberMask } from "../../../../services/helpersMethodsService";
import { useConfinementMapContext } from "../../../../hooks/useConfinementMapContext";
import AlertIcon from "../../../../components/drawers/alert/icons/alert";
import InfoTooltip from "../../../../components/utils/infoTooltip";

const ConfinementMapDashboardTableItem = memo(({ picket }) => {
  const { translation } = useSelector((state) => state.app);
  const { confinementMapParameters, showOrHideModalSuppliers } =
    useConfinementMapContext();

  function handleCardClick() {
    if (picket.predominantAmountAnimals > 0) {
      showOrHideModalSuppliers(picket.suppliers);
    }
  }

  const color = useMemo(() => {
    const parameters = confinementMapParameters.sort((a, b) => a.min - b.min);
    for (const parameter of parameters) {
      if (
        picket.averageStayDays >= parameter.min &&
        parameter.max != null &&
        picket.averageStayDays <= parameter.max
      ) {
        return parameter.color;
      } else if (
        picket.averageStayDays >= parameter.min &&
        parameter.max === null
      ) {
        return parameter.color;
      }
    }
    return null;
  }, [picket, confinementMapParameters]);

  return (
    <Tooltip
      title={
        picket.predominantAmountAnimals === 0
          ? "Este piquete não possui lote, ou o lote está vazio."
          : null
      }
    >
      <CardBoxInfoPicket className={color} onClick={handleCardClick}>
        <strong>{picket.picketName}</strong>
        {picket.predominantAmountAnimals > 0 ? (
          <>
            <span>{`${picket.predominantAmountAnimals}`}</span>
            <span>{translation.animalGender[picket.predominantGender]}</span>
            <span>{`${numberMask(
              picket.predominantAverageWeightProjected,
              false
            )} Kg`}</span>
            <span>{`${picket.averageStayDays} Dias`}</span>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon style={{ fontSize: 24, marginTop: 16 }} type="stop" />
          </div>
        )}
        {picket.dietStrategyOutOfDate === true && (
          <Tooltip title="Estratégia de Dieta está vencida." trigger="hover">
            <div className="dietStrategyOutOfDate">
              <AlertIcon />
            </div>
          </Tooltip>
        )}
      </CardBoxInfoPicket>
    </Tooltip>
  );
});

const ConfinementMapDashboardTable = memo(({ dataList = [] }) => {
  return dataList.map((data, index) => (
    <Row
      key={data.retreatId}
      type="flex"
      justify="start"
      style={{
        marginBottom: 8,
        marginTop: 8,
        height: 120,
      }}
    >
      <Col
        span={2}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderTopLeftRadius: 15,
          borderBottomLeftRadius: 15,
          background: "#684e94",
        }}
      >
        <strong
          style={{
            fontSize: 16,
            fontFamily: "Asap, sans-serif",
            color: "#fff",
          }}
        >
          {data.retreatName}
        </strong>
      </Col>
      <Col
        span={22}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Row
          type="flex"
          style={{
            flexWrap: "nowrap",
            overflowX: "auto",
          }}
        >
          {data.pickets.map((picket) => (
            <ConfinementMapDashboardTableItem
              key={picket.picketId}
              picket={picket}
            />
          ))}
        </Row>
      </Col>
    </Row>
  ));
});

const ConfinementMapDashboard = () => {
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const { showOrHideModalParameters } = useConfinementMapContext();

  let signal = axios.CancelToken.source();

  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const fetchDashboardData = useCallback(async () => {
    setIsLoadingData(true);
    try {
      const {
        data: {
          results: { retreats },
        },
      } = await generateConfinementMapDashboardData({
        groupId,
        farmId,
        signal,
      });
      setData(retreats);
      setIsLoadingData(false);
    } catch (error) {
      if (!axios.isCancel(error))
        notification.error({
          message:
            "Houve um problema ao tentar buscar os dados do Mapa do Confinamento. Contate o suporte.",
        });
      setIsLoadingData(false);
    }
  }, [groupId, farmId, signal]);

  useEffect(() => {
    fetchDashboardData();
    return () => {
      signal.cancel();
    };
  }, [groupId, farmId]);

  return (
    <Container>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ marginBottom: 16 }}
      >
        <Col>
          <Title>
            Mapa do Confinamento{" "}
            <InfoTooltip title="O tempo de permanência é calculado de acordo com a data de entrada do lote na baia." />
          </Title>
        </Col>
        <Col>
          <Row type="flex" align="middle" gutter={8}>
            <Col>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                onClick={fetchDashboardData}
              >
                <Icon type="reload" />
              </ButtonStandard>
            </Col>
            <Col>
              <ButtonStandard
                buttonType="secondary"
                onClick={showOrHideModalParameters}
              >
                <Icon type="tool" />
                Configurar Parâmetros
              </ButtonStandard>
            </Col>
          </Row>
        </Col>
      </Row>
      <Spin spinning={isLoadingData}>
        <div
          style={{
            maxHeight: 500,
            minHeight: 100,
            overflowY: "auto",
          }}
        >
          <ConfinementMapDashboardTable dataList={data} />
        </div>
      </Spin>
    </Container>
  );
};

export default ConfinementMapDashboard;
