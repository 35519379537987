import React from 'react';

// import { Container } from './styles';

const LogoMinimize = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.996"
    height="29.411"
    viewBox="0 0 24.996 29.411"
  >
    <path
      id="Path_328"
      data-name="Path 328"
      d="M23.712,4.876l-3.34-4.9c-.045-.045-.134,0-.089.045.267,1.158,1.2,5.656,1.381,6.947.223,1.514-.935,1.47-.935,1.47H17.076a.316.316,0,0,0-.312.356l1.113,5.656a3.486,3.486,0,0,0-1.87,1.425,4.879,4.879,0,0,1-1.559,1.069c-.045,0-.045.089,0,.089a7.271,7.271,0,0,0,2.806.267l-2.761,8.951h-3.83L7.858,17.345a7.271,7.271,0,0,0,2.806-.267c.045,0,.045-.089,0-.089A5.413,5.413,0,0,1,9.1,15.92c-.534-.623-1.158-1.336-1.87-1.425L8.348,8.839a.294.294,0,0,0-.312-.356H4.384s-1.158.089-.935-1.47C3.627,5.677,4.562,1.224,4.829.066c0-.045-.089-.089-.089-.045L1.356,4.876S-.826,7.9.421,10.086c1.024,1.737,3.563,2.271,3.563,2.271s1.425.267,1.113,1.381a2.011,2.011,0,0,1-.623,1.069.044.044,0,0,0-.045.045L6.611,21.22l1.87,5.3a.4.4,0,0,1,.045.223v1.2a.484.484,0,0,0,.178.4l.713.8a.618.618,0,0,0,.445.223h5.344a.548.548,0,0,0,.445-.223l.713-.8a.8.8,0,0,0,.178-.4v-1.2a.4.4,0,0,1,.045-.223l1.87-5.3,2.182-6.368c0-.045,0-.045-.045-.045a1.8,1.8,0,0,1-.623-1.069c-.312-1.113,1.113-1.381,1.113-1.381a5.819,5.819,0,0,0,3.563-2.271C25.894,7.9,23.712,4.876,23.712,4.876Z"
      transform="translate(-0.036 0.041)"
      fill="#a9c133"
    />
  </svg>
);
export default LogoMinimize;
