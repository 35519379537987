import styled from "styled-components";

export const Container = styled.span`
  padding: 2px 5px !important;
  border-radius: 5px !important;
  color: ${(props) => props.color}!important;
  font-size: 11px !important;
  font-weight: bold !important;
  text-shadow: none !important;
  border: ${(props) => `1px solid ${props.borderColor}`};
  background: ${(props) => props.background} !important;
`;
