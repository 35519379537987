import styled from "styled-components";
import { Select } from "antd";

export const Container = styled.div`
  margin-top: 10px;
  background: #fff;
  padding: 24px 0;

  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  .ant-row-flex {
    padding-left: 27px !important;
    padding-right: 10px !important;

    p {
      font-family: Asap;
      font-weight: normal;
      font-size: 14px;
      text-align: left;
      color: #4b4b4b;
    }
    strong {
      font-family: Asap;
      font-size: 14px;
      text-align: left;
      color: #4b4b4b;
      margin-right: 5px;
    }
  }
`;

export const Table = styled.table`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100% !important;
  min-width: 100% !important;
  height: 765px;
  overflow: hidden;
  thead {
    th {
      position: relative;
      &.selected {
        &::after {
          content: "";
          background-color: transparent;
          position: absolute;
          left: -5px;
          height: 765px;
          top: 0px;
          width: 85%;
          border: 3px solid #c6de52;
          border-radius: 5px;
        }
      }
    }
  }
  tbody {
    tr {
      &:not(:first-child) {
        border-top: 1px solid #e8e5e5;
      }
      &:last-child {
        border-bottom: 1px solid #e8e5e5;
      }
      td {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 3px;
        font-family: Asap;
        font-weight: normal;
        font-size: 12px;
        text-align: left;
        color: #4b4b4b;

        &:first-child {
          padding-left: 27px;
          font-size: 14px;
        }
        &:last-child {
          padding-right: 10px;
        }
      }
    }
  }
`;

export const CustomSelect = styled(Select)`
  &.ant-select.ant-select-enabled {
    .ant-select-selection {
      border: 1px solid #684e94 !important;
      color: #684e94 !important;
    }
    .ant-select-arrow {
      color: #684e94 !important;
    }
    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
      color: #684e94 !important;
    }
  }
`;

export const Footer = styled.div`
  width: 100%;

  .buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .selectDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: "Asap", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;
