import styled from "styled-components";
import { Modal, Select } from "antd";

export const CustomModal = styled(Modal)`
  position: relative !important;
  &.ant-modal {
    padding-bottom: 0px !important;
  }
  .ant-modal-body {
    padding: 0px !important;
  }
`;

export const Container = styled.div`
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 32px 20px 22px 25px;

  .ant-row-flex {
    width: 100% !important;
  }

  .ant-row-flex.uploadRow,
  .ant-row-flex.downloadButtonRow {
    margin-top: 10px;
  }

  .ant-row-flex.footer {
    margin-top: 20px !important;
  }

  button:not(:last-child) {
    margin-right: 14px;
  }

  p.message {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    color: #4b4b4b;

    text-align: justify !important;
    margin-bottom: 10px;
  }

  label {
    font-family: Asap;
    color: #4b4b4b;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;

    &.disabled {
      font-weight: bold;
      font-size: 14px;
      margin-left: 0px;
      color: #c4c4c4;
    }
    &.error {
      color: #d44c4c;
    }
    svg {
      margin-left: 5px;
    }
  }

  .ant-inputs {
    margin-top: 8px;
  }

  .ant-select {
    margin-top: 8px;
  }

  .ant-select:last-child {
    margin-bottom: 13px;
  }

  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 50px !important;
  }

  .ant-input-affix-wrapper .ant-input-prefix,
  .ant-input-affix-wrapper .ant-input-suffix {
    top: 60% !important;
    font-weight: bold !important;
  }

  .ant-upload-drag-icon {
    svg {
      color: #9074bf;
    }
  }

  .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    position: relative;
    margin-bottom: 10px;
  }

  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #c4c4c4c4;
  }
`;

export const Header = styled.div`
  margin-bottom: 9px;
`;

export const Title = styled.span`
  font-family: "Asap";
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  color: #4b4b4b;
`;

export const SubTitle = styled.span`
  font-family: Asap;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  color: #4b4b4b;
`;

export const CustomSelect = styled(Select)`
  &.ant-select.ant-select-enabled {
    .ant-select-selection {
      border: 1px solid #684e94 !important;
      color: #684e94 !important;
    }
    .ant-select-arrow {
      color: #684e94 !important;
    }
    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
      color: #684e94 !important;
    }
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  bottom: 30px;
  background: transparent;
  border: none;
  &:hover {
    cursor: pointer;
  }
  svg {
    width: 25px;
    height: 25px;
    color: #4b4b4b;
  }
`;

export const FileName = styled.span`
  font-family: Asap;
  color: #4b4b4b;
  font-size: 12px;
  svg {
    margin-right: 10px;
  }
`;

export const DownloadButton = styled.a`
  font-family: Asap !important;
  font-weight: bold !important;
  font-size: 14px !important;
  text-align: left !important;
  color: #a9c133 !important;
  text-decoration: none !important;
  text-transform: uppercase;
  margin-top: 10px;
  svg {
    margin-right: 8px;
  }
`;

export const ButtonWithoutBackground = styled.button`
  background: transparent;
  border: none;
  font-family: Asap;
  font-weight: bold;
  font-size: 14px;
  text-align: right;
  color: #684e94;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    color: #c4c4c4c4;
  }
`;
