import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CardCustom, Title, MenuContainer, EditButton } from "../../../styles";
import { Col, Icon, notification, Row, List, Dropdown, Menu } from "antd";

import { Creators as ResultCenterActions } from "../../../../../store/ducks/resultCenter";

import MenuIcon from "../../../../../components/utils/table/icons/menu";
import AddIcon from "../../../../../components/utils/icons/add";
import TagStatus from "../../../../../components/utils/tagStatus";

import { deleteById } from "../../../../../services/resultCenterService";

const FinancialParametersResultCenterList = () => {

  const {
    resultCenter: { isResultCenterFormVisible, isLoadingList, resultCenterList },
    app: {
      translation,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },

  } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (groupId && farmId) {
      dispatch(ResultCenterActions.index(groupId, farmId));
    }
  }, [groupId, farmId]);

  const handleNew = () => {
    dispatch(ResultCenterActions.showOrHideForm());
  }

  const handleEdit = (id) => {
    dispatch(ResultCenterActions.showOrHideForm(id));
  }

  const handleEditApportionment = (id) => {
    dispatch(ResultCenterActions.showOrHideFinancialNatureApportionmentForm(id));
    dispatch(ResultCenterActions.expandOrCompactApportionmentForm());
  }

  const handleDelete = async (id) => {
    try {
      dispatch(ResultCenterActions.setIsLoadingList(true));
      await deleteById({
        groupId,
        farmId,
        id,
      });

      notification.success({ message: "Centro de Resultado excluído com sucesso" });
      dispatch(ResultCenterActions.index(groupId, farmId));
    } catch (error) {
      dispatch(ResultCenterActions.setIsLoadingList(false));
      notification.error({
        title: "Erro ao excluir",
        message: "Não foi possível excluir o Centro de Resultado. Contate o suporte"
      });
    } finally {
      dispatch(ResultCenterActions.index(groupId, farmId));
    }

  }

  const handleInactivateOrActivate = (record, mode) => {
    if (mode === "activate") {
      dispatch(ResultCenterActions.activateResultCenter(groupId, farmId, record.id));
    } else {
      dispatch(ResultCenterActions.inactivateResultCenter(groupId, farmId, record.id));
    }
  }

  function renderMenu(record) {
    if (record.farmId === null && record.groupId === null) {
      return <></>;
    } else {
    return (<Menu>
      <Menu.Item key="0">
        <button onClick={() => handleEdit(record.id)}>
          {translation.table.menu.edit}
        </button>
      </Menu.Item>
      {record.status !== "Pending" ?
        record.status !== "Active" ? (
          <Menu.Item key="1">
            <button
              onClick={() => handleInactivateOrActivate(record, "activate")}
            >
              {translation.table.menu.activate}
            </button>
          </Menu.Item>
        ) : (
          <Menu.Item key="2">
            <button
              onClick={() => handleInactivateOrActivate(record, "inactivate")}
            >
              {translation.table.menu.inactivate}
            </button>
          </Menu.Item>
        ) : null}
      <Menu.Item key="3">
        <button onClick={() => handleDelete(record.id)}>
          {translation.table.menu.delete}
        </button>
      </Menu.Item>
    </Menu>)
    }
  }

  return (
    <CardCustom className="grow-card">
      <Row type="flex" justify="start" className="rowHeader">
        <Title>{translation.financial.parameters.resultCenter.title}</Title>
      </Row>
      <Row type="flex" justify="end" className="rowHeader">
        <EditButton
          disabled={isLoadingList}
          onClick={() => dispatch(ResultCenterActions.index(groupId, farmId))}
        >
          <Icon type="reload" />
        </EditButton>
        <EditButton
          disabled={isResultCenterFormVisible}
          type="button"
          onClick={handleNew}
        >
          <AddIcon />
        </EditButton>

      </Row>
      <Row>
        <List
          loading={isLoadingList}
          itemLayout="horizontal"
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 10,
            pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
          }}
          dataSource={resultCenterList !== null ? resultCenterList : []}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                title={
                  (
                    <Row>
                      <Col span={12}><span>{item.name}</span></Col>
                      <Col span={12}>{
                        item.groupId === null && item.farmId === null ? (
                          <TagStatus
                            background="#C5F1CA"
                            borderColor="#106518"
                            color="#106518"
                          >
                            BovExo
                          </TagStatus>
                        ) : item.status === "Active" ? (
                          <TagStatus
                            background="#C5F1CA"
                            borderColor="#106518"
                            color="#106518"
                          >
                            {translation.status.active}
                          </TagStatus>
                        ) : item.status === "Pending" ? (
                          <TagStatus
                            background="#FFDBBC"
                            borderColor="#FE8D2A"
                            color="#FE8D2A"
                          >
                            Pendente
                          </TagStatus>
                        ) : (
                          <TagStatus
                            background="#FBC7C7"
                            borderColor="#D44C4C"
                            color="#D44C4C"
                          >
                            {translation.status.inactive}
                          </TagStatus>
                        )
                      }</Col>
                    </Row>
                  )}
              />

              <Dropdown
                disabled={isResultCenterFormVisible}
                overlay={renderMenu(item)}
                trigger={["click"]}
                key={item.id}
              >
                <MenuContainer>
                  <MenuIcon />
                </MenuContainer>
              </Dropdown>
            </List.Item>
          )}
        />
      </Row>
    </CardCustom>
  );
}

export default FinancialParametersResultCenterList;