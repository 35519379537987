import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

import { Col, Row, Table } from "antd";
import { numberMask } from "../../../../../services/helpersMethodsService";
import { AnimalDetailsCard, TabAnimalPaneContent } from "../../styles";

function TabAnimalCoverageInfo({ tabCoveragePane, coverage, index }) {
  const {
    app: { translation },
  } = useSelector((state) => state);

  /** Variable props */

  return (
    <TabAnimalPaneContent key={coverage.id} active={tabCoveragePane === index}>
      {/* Card with animal reproduction history */}
      <Row type="flex">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="columnWithCards"
        >
          <AnimalDetailsCard>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.coverageDate}: `}
              <span className="value">
                {moment(coverage.coverageDate).format("DD/MM/YYYY")}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.coverageType}: `}
              <span className="value">{coverage.type}</span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.coverageSemenOrMaleIdentification}: `}
              <span className="value">
                {coverage.semenOrMaleIdentification}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`${translation.animal.details.weightCoverage}: `}
              <span className="value">
                {coverage.weightCoverage
                  ? `${numberMask(coverage.weightCoverage, false)} kg`
                  : ""}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`Data do DG: `}
              <span className="value">
                {coverage.pregnancyDiagnosisDate
                  ? moment(coverage.pregnancyDiagnosisDate).format("DD/MM/YYYY")
                  : "-"}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`Resultado (DG): `}
              <span className="value">
                {coverage.pregnancyDiagnosisResult
                  ? coverage.pregnancyDiagnosisResult
                  : "-"}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`Avaliação Ovariana (DG): `}
              <span className="value">
                {coverage.pregnancyDiagnosisOvarianEvaluationResult
                  ? translation.ovarianResult[
                      coverage.pregnancyDiagnosisOvarianEvaluationResult
                    ]
                  : "-"}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`Parto: `}
              <span className="value">
                {coverage?.birthInfo?.birthDate
                  ? moment(coverage?.birthInfo?.birthDate).format("DD/MM/YYYY")
                  : ""}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`Tipo Parto: `}
              <span className="value">
                {coverage?.birthInfo?.birthType
                  ? translation.birthTypes[coverage?.birthInfo?.birthType]
                  : ""}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`Temp. Gestação: `}
              <span className="value">
                {coverage?.birthInfo?.gestationDays
                  ? `${coverage?.birthInfo?.gestationDays} dias`
                  : ""}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`Sexo Crias: `}
              <span className="value">
                {coverage?.birthInfo?.childs &&
                coverage?.birthInfo?.childs.length > 0
                  ? `${coverage?.birthInfo?.childs
                      .map((c) => c.gender)
                      .join(" - ")}`
                  : ""}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`Id. Crias: `}
              <span className="value">
                {coverage?.birthInfo?.childs &&
                coverage?.birthInfo?.childs.length > 0
                  ? `${coverage?.birthInfo?.childs
                      .map((c) => c.handlingNumber)
                      .join(" - ")}`
                  : ""}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`Peso Nascimento: `}
              <span className="value">
                {coverage?.birthInfo?.childs &&
                coverage?.birthInfo?.childs.length > 0
                  ? `${coverage?.birthInfo?.childs
                      .map(
                        (c) => `${numberMask(c.birthdayWeight || 0, false)} kg`
                      )
                      .join(" - ")}`
                  : ""}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`Situação Atual Crias: `}
              <span className="value">
                {coverage?.birthInfo?.childs &&
                coverage?.birthInfo?.childs.length > 0
                  ? `${coverage?.birthInfo?.childs
                      .map((c) => translation.animalStatus[c.status])
                      .join(" - ")}`
                  : ""}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`Dt. Desmame: `}
              <span className="value">
                {coverage?.birthInfo?.childs &&
                coverage?.birthInfo?.childs.length > 0
                  ? `${coverage?.birthInfo?.childs
                      .map((c) =>
                        c.weaningDate != null
                          ? moment(c.weaningDate).format("DD/MM/YYYY")
                          : ""
                      )
                      .join(" - ")}`
                  : ""}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`Peso Desmame: `}
              <span className="value">
                {coverage?.birthInfo?.childs &&
                coverage?.birthInfo?.childs.length > 0
                  ? `${coverage?.birthInfo?.childs
                      .map((c) =>
                        c.weaningWeight != null
                          ? `${numberMask(c.weaningWeight || 0, false)} kg`
                          : ""
                      )
                      .join(" - ")}`
                  : ""}
              </span>
            </span>
            <span className="label" style={{ marginTop: 11 }}>
              {`Interv. Partos: `}
              <span className="value">
                {coverage?.birthInfo?.iep
                  ? `${coverage?.birthInfo?.iep} dias`
                  : ""}
              </span>
            </span>
          </AnimalDetailsCard>
        </Col>
      </Row>
    </TabAnimalPaneContent>
  );
}

export default TabAnimalCoverageInfo;
