import apiFarms from "../config/api_farms";
import getHeaders from "./getHeaders";

export function indexAll(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/quotas`,
    getHeaders(false, signal)
  );
}

export function findById(payload) {
  const { groupId, farmId, id } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/quotas/${id}`,
    getHeaders(false, null)
  );
}

export function indexAllDropdown(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/quotas?onlyActives=true`,
    getHeaders(false, signal)
  );
}

export function indexAllDropdownFarmQuota(payload) {
  const { groupId, farmId, signal } = payload;

  return apiFarms.get(
    `/bovexo/${groupId}/farms/${farmId}/quotas?onlyActives=true`,
    getHeaders(false, signal)
  );
}

export function save(payload) {
  const { groupId, farmId, body } = payload;

  return apiFarms.post(`/bovexo/${groupId}/farms/${farmId}/quotas`,
    body,
    getHeaders(false, null)
  );
}

export function update(payload) {
  const { groupId, farmId, id, body } = payload;

  return apiFarms.patch(`/bovexo/${groupId}/farms/${farmId}/quotas/${id}`,
    body,
    getHeaders(false, null)
  );
}

export function Inactivate(payload) {
  const { groupId, farmId, id } = payload;

  return apiFarms.put(`/bovexo/${groupId}/farms/${farmId}/quotas/${id}/inactivate`,
    null,
    getHeaders(false, null)
  );
}

export function Activate(payload) {
  const { groupId, farmId, id } = payload;

  return apiFarms.put(`/bovexo/${groupId}/farms/${farmId}/quotas/${id}/activate`,
    null,
    getHeaders(false, null)
  );
}