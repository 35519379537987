import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col, Table, Menu, Icon, Dropdown, Input, Button } from "antd";
import moment from "moment";

//Ducks
import { Creators as BoitelScenarioActions } from "../../store/ducks/boitelScenario";

//components
import ButtonStandard from "../../components/utils/button";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import MenuIcon from "../../components/utils/table/icons/menu";

import { CardCustom, Title } from "./styles";
import TagStatus from "../../components/utils/tagStatus";

//Services
import { indexBoitelScenario } from "../../services/boitelScenarioService";

//Helpers
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../services/helpersMethodsService";

// import { Container } from './styles';

const TABLE_CHANGE = { page: 0, size: 10, sorter: null, filters: "" };
const TABLE_DATA_PAYLOAD = {
  totalElements: 0,
  size: 0,
  number: 0,
  content: [],
};

const ListBoitelScenario = () => {
  /** Redux libs */
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const history = useHistory();
  const dispatch = useDispatch();

  const [loadingTable, setLoadingTable] = useState(true);
  const [dataTableScenare, setDataTableScenare] = useState(TABLE_DATA_PAYLOAD);
  const [tableChange, setTableChange] = useState(TABLE_CHANGE);

  useEffect(() => {
    async function fetchData(page = 0, sorter = {}, filters = "", size = 10) {
      try {
        setLoadingTable(true);
        const {
          data: { results },
        } = await indexBoitelScenario({
          groupId,
          farmId,
          page,
          sorter,
          filters,
          size,
        });

        if (!!results) {
          setDataTableScenare(results);
        }
      } catch (error) {
        throw error;
      } finally {
        setLoadingTable(false);
      }
    }
    fetchData();
  }, [farmId, groupId]);

  // Methods
  const goToNewBoitelScenario = () => {
    dispatch(BoitelScenarioActions.clearFields(true));
    history.push("/admin/decisions/scenarios/boitel/new");
  };

  async function fetchData(page = 0, sorter = {}, filters = "", size = 10) {
    try {
      setLoadingTable(true);
      const {
        data: { results },
      } = await indexBoitelScenario({
        groupId,
        farmId,
        page,
        sorter,
        filters,
        size,
      });

      if (!!results) {
        setDataTableScenare(results);
      }
    } catch (error) {
      throw error;
    } finally {
      setLoadingTable(false);
    }
  }

  const handleReloadTable = () => {
    const { page, size, sorter, filters } = tableChange;
    fetchData(page, sorter, filters, size);
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    let search = "";

    if (Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v.length > 0) {
          if (k === "status") {
            search =
              search === ""
                ? `${k} in (${v.map((o) => (o = `'${o}'`)).join(", ")})`
                : `${search};${k} in (${v
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`;
          } else {
            search =
              search === ""
                ? `upper(${k}) like upper('%25${v}%25')`
                : `${search};upper(${k}) like upper('%25${v}%25')`;
          }
        }
      });
    } else {
      search = "";
    }

    setTableChange({
      page: pagination.current,
      size: pagination.pageSize,
      sorter,
      filters: search,
    });

    fetchData(pagination.current, sorter, search, pagination.pageSize);
  };

  function expandedRows(record, index, indent, expanded) {
    const {
      detailsRow: {
        rangeWeight,
        entryDate,
        exitDay,
        daysOfStay,
        dailyPrice,
        dietStrategy,
        totalIncome,
        incomeForCab,
        marginForCab,
      },
    } = translation.scenarePage.table;

    const columns = [
      {
        title: rangeWeight,
        dataIndex: ["minimumWeightSell", "maximumWeightSell"],
        render: (text, record) => (
          <span>
            {`${record.initialWeightRange} Kg (${getTwoDecimalDigits(
              record.initialWeightRange / 30,
              1
            )} @) -
             ${record.finalWeightRange} Kg (${getTwoDecimalDigits(
              record.finalWeightRange / 30,
              1
            )} @)`}
          </span>
        ),
      },
      {
        title: entryDate,
        dataIndex: "entryDate",
        align: "right",
        render: (text, record) => (
          <span>
            {record.entryDate !== null
              ? `${moment(record.entryDate, "yyyy-MM-DD").format("DD/MM/YYYY")}`
              : "-"}
          </span>
        ),
      },
      {
        title: exitDay,
        dataIndex: "exitDate",
        align: "right",
        render: (text, { exitDate }) => (
          <span>
            {!!exitDate
              ? `${moment(exitDate, "yyyy-MM-DD").format("DD/MM/YYYY")}`
              : "-"}
          </span>
        ),
      },
      {
        title: daysOfStay,
        dataIndex: ["limitTimeSimulation", "limitTimeTable"],
        align: "right",
        render: (text, { limitTimeSimulation, limitTimeTable }) =>
          limitTimeSimulation || limitTimeTable,
      },
      {
        title: dailyPrice,
        dataIndex: ["priceSimulation", "priceDalyTable"],
        align: "right",
        render: (text, record) =>
          numberMask(record.priceSimulation || record.priceDalyTable, true),
      },
      {
        title: dietStrategy,
        dataIndex: "dietStrategy.name",
        align: "right",
      },
      {
        title: totalIncome,
        dataIndex: "result",
        align: "right",
        render: (text, { result }) => (
          <span>
            {!result ? "-" : numberMask(result.userTotalIncome, true)}
          </span>
        ),
      },
      {
        title: incomeForCab,
        dataIndex: ["result", "amountAnimals"],
        align: "right",
        render: (text, { amountAnimals, result }) => (
          <span>
            {!result && amountAnimals > 0
              ? "-"
              : numberMask(result.userTotalIncome / amountAnimals, true)}
          </span>
        ),
      },
      {
        title: marginForCab,
        dataIndex: ["result", "amountAnimals"],
        align: "right",
        render: (text, { amountAnimals, result }) => (
          <span>
            {!result && amountAnimals > 0
              ? "-"
              : numberMask(result.userTotalMargin / amountAnimals, true)}
          </span>
        ),
      },
    ];

    const { simulations } = record;

    if (!simulations || simulations.length === 0) return false;

    return (
      <Table
        key="id"
        columns={columns}
        dataSource={simulations}
        pagination={false}
        style={{ padding: "2" }}
      />
    );
  }

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
            background: "#684e94",
            borderColor: "none",
            border: "none",
          }}
        />
        <Button
          onClick={() => handleReset(clearFilters)}
          icon="delete"
          size="small"
          style={{ width: 90 }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  return (
    <CardCustom>
      {/* Header */}
      <Row type="flex" justify="space-between" className="rowHeader">
        <Col>
          <Title>{translation.scenarePage.title}</Title>
        </Col>
        <Col>
          <ButtonStandard
            buttonType="typeWithoutBackground"
            onClick={handleReloadTable}
          >
            <Icon type="reload" />
          </ButtonStandard>

          <ButtonStandard buttonType="type8" onClick={goToNewBoitelScenario}>
            Novo cenário
          </ButtonStandard>
        </Col>
      </Row>

      {/* Table */}
      <Row type="flex" justify="space-between">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Table
            rowKey="id"
            size="small"
            dataSource={
              !dataTableScenare?.content ? [] : dataTableScenare.content
            }
            expandedRowRender={expandedRows}
            loading={loadingTable}
            pagination={{
              total:
                dataTableScenare != null
                  ? dataTableScenare.numberOfElements
                  : 0,
              size: dataTableScenare != null ? dataTableScenare.size : 0,
              current:
                dataTableScenare != null ? dataTableScenare.number + 1 : 0,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
              hideOnSinglePage:
                dataTableScenare !== null &&
                Object.entries(dataTableScenare).length !== 0
                  ? dataTableScenare.totalElements > 10
                    ? false
                    : true
                  : true,
            }}
            style={{ width: "100%", margin: "0" }}
            onChange={handleTableChange}
          >
            <Table.Column
              title={translation.scenarePage.table.column.clientID}
              dataIndex="supplier.name"
              sorter
              key="supplier.name"
              align="left"
              {...getColumnSearchProps("supplier.name")}
            />
            <Table.Column
              title={translation.scenarePage.table.column.name}
              dataIndex="name"
              sorter
              key="bs.name"
              align="left"
              {...getColumnSearchProps("bs.name")}
            />
            <Table.Column
              title={translation.scenarePage.table.column.createdAt}
              dataIndex="createdAt"
              sorter
              key="createdAt"
              align="left"
              render={(date) => (
                <span>
                  {date == null ? "-" : `${moment(date).format("DD/MM/YYYY")}`}
                </span>
              )}
            />
            <Table.Column
              title={translation.scenarePage.table.column.updatedAt}
              dataIndex="updatedAt"
              sorter
              key="updatedAt"
              align="left"
              render={(date) => (
                <span>
                  {date == null ? "-" : `${moment(date).format("DD/MM/YYYY")}`}
                </span>
              )}
            />
            <Table.Column
              title={translation.scenarePage.table.column.status}
              dataIndex="status"
              sorter
              key="status"
              align="right"
              render={(status, record) =>
                status === "Hired" ? (
                  <TagStatus
                    background="#C5F1CA"
                    borderColor="#106518"
                    color="#106518"
                  >
                    {translation.scenarePage.table.column.labelStatus.hired}
                  </TagStatus>
                ) : (
                  <TagStatus
                    background="#FFDBBC"
                    borderColor="#FE8D2A"
                    color="#FE8D2A"
                  >
                    {translation.scenarePage.table.column.labelStatus.saved}
                  </TagStatus>
                )
              }
            />
            <Table.Column
              align="left"
              render={(text, record) => (
                <Dropdown
                  overlay={menuDropdown(record.id)}
                  trigger={["click"]}
                  key={record.id}
                >
                  <MenuContainer>
                    <MenuIcon />
                  </MenuContainer>
                </Dropdown>
              )}
            />
          </Table>
        </Col>
      </Row>
    </CardCustom>
  );
};

const menuDropdown = (id) => {
  return (
    <Menu>
      <Menu.Item key="0">
        <Link to={`/admin/decisions/scenarios/boitel/${id}/edit`}>Editar</Link>
      </Menu.Item>
    </Menu>
  );
};

export default ListBoitelScenario;
