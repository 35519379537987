import styled from "styled-components";
import { Divider, Drawer } from "antd";

export const Container = styled(Drawer)`
  .ant-drawer-body {
    padding: 24px 0px 10px 0px;
  }

  .rowLabel {
    margin-bottom: 7px;
    margin-top: 19px;
  }

  .card {
    margin-top: 10px;
    max-width: 140px;
    padding: 7px 14px;
    background: #F5F5F5;
    border-radius: 6px;
    text-align: center;
    
    span {
      margin-left: 0px;
    }
  }

  .title-purple {
    color: #9074BF;
  }

  .drawerForm {
    width: 100%;
    padding: 0px 24px;
    min-height: -moz-calc(100vh - 165px);
    min-height: -webkit-calc(100vh - 165px);
    min-height: calc(100vh - 165px);
    overflow-y: auto;
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    &.error {
      color: #d44c4c;
    }
  }

  .ant-row.rowInput {
    margin-top: 6px;
  }

  .ant-select-selection-selected-value {
    color: #a5a5a5 !important;
  }

  .tableButtonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      display: flex;
      justify-content: center !important;
      text-align: center !important;
      align-items: center !important;
      font-size: 14px !important;
      width: 77px !important;
      height: 30px !important;

      svg {
        margin-right: 3px !important;
      }
    }

    button:first-of-type {
      width: 116px !important;
      margin-right: 12px !important;
    }
  }

  .ant-switch-checked {
    background-color: #a9c133 !important;
  }
`;

export const ModalTitle = styled.span`
  font-size: 25px;
  font-family: "Asap", sans-serif;
  font-weight: bold;
  color: #4b4b4b;
`;

export const ButtonInputNumber = styled.button`
  background: transparent;
  color: #fff;
  border: none;
  width: 100% !important;
  height: 100% !important;

  &:hover {
    cursor: pointer;
  }

  svg {
    margin-left: 5px;
  }
`;

export const SupplementInfoCard = styled.div`
  width: 100%;
  border-radius: 6px;
  background: transparent;
  border: 1px dashed #fe8d2a;
  margin-top: 13px;
  font-family: Asap;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  color: #fe8d2a;
  text-align: center;
  padding: 8px;
  svg {
    margin-right: 30px;
    g {
      path {
        fill: #fe8d2a !important;
        color: #fe8d2a !important;
      }
    }
  }
`;

export const CustomDivider = styled(Divider)`
  margin: 10px 0 !important;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 10px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;

export const Footer = styled.div`
  border-top: 1px solid #e8e8e8;
  margin-top: 20px;
  width: 100%;
  padding: 10px 24px;
  border-radius: 0 0 4px 4px;

  .buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding-right: 24px; */

    button:first-of-type {
      margin-right: 12px !important;
    }
  }

  .ant-row-flex {
    margin: 0;
  }

  .selectDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: "Asap", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;
