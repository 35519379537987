import styled, { css } from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: ${(props) => (props.collapse ? "67px" : "175px")};
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  transition: width 0.5s ease-in-out;
  padding-bottom: 60px;
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 769px) {
    /* start of medium tablet styles */
    ${(props) =>
      props.collapse
        ? css`
            display: none;
          `
        : css`
            display: flex;
            position: absolute;
            width: 100%;
            background: rgba(0, 0, 0, 0.4);
            z-index: 2;
          `}
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
    ${(props) =>
      props.collapse
        ? css`
            display: none;
          `
        : css`
            display: flex;
          `}
  }
`;

export const BlockContainer = styled.div`
  width: ${(props) => (props.collapse ? "67px" : "175px")};
  position: absolute;
  z-index: 8;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  transition: width 0.5s ease-in-out;
`;

export const Menu = styled.ul`
  background: #f5f5f5;
  width: ${(props) => (props.collapse ? "67px" : "175px")};
  height: 100%;
  list-style: none;
  margin: 0 !important;
  transition: width 0.5s ease-in-out;
  z-index: 2;
  li.dropdown {
    position: relative;
  }

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 769px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
  }
`;

export const MenuItem = styled.li`
  margin-top: 9.5px;
  width: 100% !important;
  height: 41px;
  background: #f5f5f5;
  position: relative;
  display: block;
  transition: width 0.5s ease-in-out;

  div.customTooltip {
    opacity: 0;
  }

  .initialFarmName {
    display: none;
  }

  &.active,
  &:hover:not(.divider),
  &:hover:not(.disabled) {
    background: #c6de52;

    z-index: 9999 !important;
  }

  &:first-of-type {
    /* margin-top: 24px; */
  }

  &:last-of-type {
    margin-bottom: 34px;
  }

  a {
    width: 100% !important;
    height: 100% !important;

    padding-left: 13px !important;
    color: #383838 !important;
    text-decoration: none !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    vertical-align: middle;

    div:not(div.badge) {
      position: relative;
      text-align: center;
      width: auto;
      margin-right: 15px !important;
      svg,
      img {
        width: 25px !important;
        height: 25px !important;
      }
    }
    span {
      font-size: 14px;
    }

    &.active {
      background: #c6de52;
    }
  }

  button {
    border: none;
    background: transparent;
    color: #383838;
    padding-left: 11px;
    height: 100%;
    text-decoration: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
      #openMenuFarm {
        #Elipse_152 {
          fill: #e8e5e5 !important;
        }
      }
    }
    div:not(div.badge) {
      position: relative;
      text-align: center;
      width: auto !important;
      margin-right: 15px !important;
      svg,
      img {
        width: 25px !important;
        height: 25px !important;
      }
    }
    span {
      font-size: 14px;
      margin-right: 25px;
    }

    #openMenuFarm {
      #Elipse_152 {
        fill: #f5f5f5 !important;
      }
    }
  }

  &.divider {
    border-top: 1px solid #cacaca;
    height: 1px !important;
  }

  &.dropdown.minimize {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 80px;
  }

  div.badge {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    color: #fff;
    background: #6c578f;
    border-radius: 50%;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: -12px;
    left: 110%;
    font-size: 10px;
    padding: 2px 4px;
    z-index: 100000;
  }

  &.minimize {
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;

    div.badge {
      left: 65%;
    }

    div.customTooltip {
      position: absolute;
      height: 41px;

      opacity: 0;

      /* vertically center */
      top: 50%;
      transform: translateY(-50%);

      /* move to right */
      left: 100%;
      margin-left: 15px; /* and add a small left margin */

      /* basic styles */
      padding: 10px;
      border: 2px solid #c6de52;
      border-radius: 10px;
      background: #f5f5f5;
      text-align: center;
      pointer-events: none;

      display: flex;
      align-items: center;
      justify-content: center;

      z-index: 99999999;

      transition: all 0.3s ease;

      &:after {
        content: "";
        position: absolute;

        /* position tooltip correctly */
        right: 100%;

        /* vertically center */
        top: 50%;
        transform: translateY(-50%);

        /* the arrow */
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid #c6de52;

        display: block;
      }

      span {
        font-size: 14px;
        color: #383838;
      }
    }

    &:hover {
      margin-left: 0px;
      margin-right: 0px;

      div.customTooltip {
        opacity: 1;
      }
    }

    a {
      width: 100% !important;
      padding-left: 0 !important;
      justify-content: center;
      span {
        display: none;
      }
      div:not(div.badge) {
        display: flex;
        position: relative;
        margin-right: 0% !important;
        width: 100% !important;
        justify-content: center;
        align-items: center;
        height: 100% !important;
        text-align: center;
        margin: 0;
        svg,
        img {
          width: 25px !important;
          height: 25px !important;
        }
      }
    }
    button {
      width: 100% !important;
      padding-left: 0 !important;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      span {
        display: none;
      }
      div:not(div.badge) {
        display: flex;
        position: relative;
        margin-right: 0% !important;
        width: 100%;
        justify-content: center;
        height: 35px !important;
        margin: 0;
        svg,
        img {
          width: 30px !important;
          height: 30px !important;
        }
      }
      #openMenuFarm {
        display: none;
      }
      .initialFarmName {
        display: block;
        font-weight: bolder;
        margin: 0 !important;
      }
    }

    div.badge {
      left: 65%;
    }
  }

  &.disabled {
    cursor: not-allowed;
    &:hover {
      background-color: transparent;
    }
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 769px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
  }
`;
export const DropDownMenuFarm = styled.ul`
  display: none;
  &.active {
    display: block;
    background: #f5f5f5;
    width: 100% !important;
    height: auto;
    li {
      width: 100% !important;
      display: block !important;
      margin: 0 !important;
      height: 30px !important;

      &.active {
        /* padding-left: 54px; */
        button {
          padding-left: 20px !important;
          &::before {
            content: "•";
            margin-right: 24px;
            color: #4b4b4b;
            font-weight: bold;
            font-size: 14px;
          }
          font-weight: bolder;
        }
      }

      &:hover {
        button {
          padding-left: 20px !important;
          &::before {
            content: "•";
            margin-right: 24px;
            color: #4b4b4b;
            font-weight: bold;
            font-size: 14px;
          }
        }
        /* padding-left: 54px; */
      }

      button {
        border: none;
        padding-left: 50px !important;
        background: transparent;
        width: 100% !important;
        height: 100% !important;
        color: #383838 !important;
        font-size: 14px !important;
        display: flex;
        justify-content: flex-start;
      }
    }
  }

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 767px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
  }
`;

export const MenuFooter = styled.ul`
  background: #f5f5f5;
  width: ${(props) => (props.collapse ? "67px" : "175px")};
  list-style: none;
  margin-top: auto;
  margin-bottom: 30px;
  border-top: 1px solid #c4c4c4;
  transition: width 0.5s ease-in-out;
  li.dropdown {
    position: relative;
  }

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 769px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
    height: 100%;
    margin-top: 0px;
    margin-bottom: 0;
  }
`;

export const MenuFooterItem = styled.li`
  margin-top: 9.5px;
  width: 100% !important;
  height: 41px !important;
  background: #f5f5f5;
  position: relative;
  display: block;
  transition: width 0.5s ease-in-out;

  .initialFarmName {
    display: none;
  }
  div.customTooltip {
    opacity: 0;
  }

  &.active,
  &:hover:not(.divider) {
    background: #c6de52;
  }

  &:last-of-type {
    margin-bottom: 34px;
  }

  a {
    color: #383838 !important;
    padding-left: 13px !important;
    height: 100% !important;
    text-decoration: none !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    vertical-align: middle !important;
    div {
      text-align: center;
      width: auto;
      margin-right: 15px !important;
      svg,
      img {
        width: 25px !important;
        height: 25px !important;
      }
    }
    span {
      font-size: 14px;
    }

    &.active {
      background: #c6de52;
    }
  }

  button {
    border: none;
    background: transparent;
    color: #383838;
    padding-left: 11px;
    height: 100%;
    text-decoration: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
      #openMenuFarm {
        #Elipse_152 {
          fill: #e8e5e5 !important;
        }
      }
    }
    div {
      text-align: center;
      width: auto !important;
      margin-right: 15px !important;
      svg,
      img {
        width: 25px !important;
        height: 25px !important;
      }
    }
    span {
      font-size: 14px;
      margin-right: 25px;
    }

    #openMenuFarm {
      #Elipse_152 {
        fill: #f5f5f5 !important;
      }
    }
  }

  &.divider {
    border-top: 1px solid #cacaca;
    height: 1px !important;
  }

  &.dropdown.minimize {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 80px;
  }

  &.minimize {
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;

    div.customTooltip {
      position: absolute;
      height: 41px;

      opacity: 0;

      /* vertically center */
      top: 50%;
      transform: translateY(-50%);

      /* move to right */
      left: 100%;
      margin-left: 15px; /* and add a small left margin */

      /* basic styles */
      padding: 10px;
      border: 2px solid #c6de52;
      border-radius: 10px;
      background: #f5f5f5;
      text-align: center;
      pointer-events: none;

      display: flex;
      align-items: center;
      justify-content: center;

      z-index: 99999999;

      transition: all 0.3s ease;

      &:after {
        content: "";
        position: absolute;

        /* position tooltip correctly */
        right: 100%;

        /* vertically center */
        top: 50%;
        transform: translateY(-50%);

        /* the arrow */
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid #c6de52;

        display: block;
      }

      span {
        font-size: 14px;
        color: #383838;
      }
    }

    &:hover {
      margin-left: 0px;
      margin-right: 0px;

      div.customTooltip {
        opacity: 1;
      }
    }

    a {
      width: 100% !important;
      padding-left: 0 !important;
      justify-content: center;
      span {
        display: none;
      }
      div {
        display: flex;
        margin-right: 0% !important;
        width: 100% !important;
        justify-content: center;
        align-items: center;
        height: 100% !important;
        text-align: center;
        margin: 0;
        svg,
        img {
          width: 25px !important;
          height: 25px !important;
        }
      }
    }
    button {
      width: 100% !important;
      padding-left: 0 !important;
      flex-direction: column;
      justify-content: center !important;
      align-items: center !important;
      text-align: center !important;
      span {
        display: none;
      }
      div {
        display: flex;
        margin-right: 0% !important;
        width: 100%;
        justify-content: center !important;
        align-items: center !important;
        height: 35px !important;
        margin: 0;
        svg,
        img {
          width: 25px !important;
          height: 25px !important;
        }
      }
      #openMenuFarm {
        display: none;
      }
      .initialFarmName {
        display: block;
        font-weight: bolder;
        margin: 0 !important;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    &:hover {
      background-color: transparent;
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  /* start of desktop styles */
  @media screen and (max-width: 991px) {
    /* start of large tablet styles */
  }

  @media screen and (max-width: 769px) {
    /* start of medium tablet styles */
  }

  @media screen and (max-width: 576px) {
    /* start of phone styles */
  }
`;

export const MenuFooterItemDropDown = styled.ul`
  position: relative;
  display: none;
  &.active {
    position: absolute;
    display: flex;
    left: 105%;
    bottom: 0%;
    display: block;
    background: #fff;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.28);
    z-index: 3 !important;
    padding: 5px 17px 5px 17px;
    li {
      width: 100% !important;
      display: block !important;
      margin: 0 !important;
      height: 30px !important;

      padding: 0 !important;

      &.active {
        /* padding-left: 54px; */
        a,
        button {
          font-weight: bolder;
        }
      }

      &:hover {
        a,
        button {
          font-weight: bolder;
        }
        /* padding-left: 54px; */
      }
      a,
      button {
        border: none;
        background: transparent;
        width: 100% !important;
        height: 100% !important;
        color: #383838 !important;
        font-size: 14px !important;
        font-family: "Asap", sans-serif !important;
        display: flex;
        align-items: center !important;
        text-align: left !important;
        justify-content: flex-start !important;
        padding: 0 !important;
      }
    }
  }
`;
