import { Col, Row } from "antd";
import moment from "moment";
import React, { memo } from "react";
import { numberMask } from "../../../../../services/helpersMethodsService";
import { CustomDivider, TabSaleScenarioInfoContent } from "../../styles";

const formatDate = "DD/MM/YYYY";
const SaleScenarioDetailsDatesAndWeightPricesTab = memo(
  ({ tabActive, listOfSaleScenarioResultSell }) => {
    return (
      <TabSaleScenarioInfoContent active={tabActive === 3 ? true : false}>
        <Row type="flex">
          <Col span={24}>
            <CustomDivider dashed>REFERÊNCIA DE VALORES</CustomDivider>
            <Row type="flex">
              <Col span={24}>
                <table className="tableWeightPrice">
                  <thead>
                    <tr>
                      <th>
                        <span className="lotTableTd">Datas</span>
                      </th>
                      <th className="lotTableTd alignRight">Valor / @</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listOfSaleScenarioResultSell != null &&
                    listOfSaleScenarioResultSell.length > 0 ? (
                      listOfSaleScenarioResultSell.map((sell, index) => (
                        <tr key={index}>
                          <td>
                            <span>
                              {moment(sell?.sellDate).format(formatDate)}
                            </span>
                          </td>
                          <td className="alignRight">
                            <strong>{`${
                              sell?.weightPrice != null
                                ? numberMask(sell?.weightPrice || 0, true)
                                : Number(0).toFixed(2)
                            }`}</strong>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="2" className="alignCenter">
                          <span>Nenhuma data</span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Col>
            </Row>
          </Col>
        </Row>
      </TabSaleScenarioInfoContent>
    );
  }
);

export default SaleScenarioDetailsDatesAndWeightPricesTab;
