import React, { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";
import { Formik } from "formik";
import { useHistory, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import useBoitelSaleScnearioNewContext from "../../../hooks/useBoitelSaleScnearioNewContext";
import { Creators as BoitelSaleScenarioActions } from "../../../store/ducks/boitelSaleScenario";
// Components
import CanvasJSReact from "../../../assets/canvasjs.react";
import {
  Card,
  Row,
  Col,
  Input,
  Select,
  Radio,
  Spin,
  Icon,
  notification,
  Switch,
} from "antd";
import { Container, Title } from "./styles";
import ButtonStandard from "../../../components/utils/button";
import NumberFormat from "react-number-format";
import RadioGroup from "antd/lib/radio/group";
import InfoTooltip from "../../../components/utils/infoTooltip";
import ModalBoitelSaleScenarioCommitment from "./modalCommitment";
import PencilIcon from "../../../components/utils/icons/pencil";
import { ListSaleCommitments } from "../../saleScenario/new/tabs/parameters/styles";
import BreadCrumbs from "../../../components/utils/breadCrumbs";
import Loading from "../../../components/utils/loading";
import BoxText from "../../../components/utils/boxText";
// Services
import { getMarketAnalysisDropDown } from "../../../services/marketAnalysisService";
import { getFuturePriceChart } from "../../../services/futurePriceService";
import { showBoitelSaleScenarioService } from "../../../services/boitelSaleScenarioService";
import { numberMask } from "../../../services/helpersMethodsService";
import { getDietStrategyShow } from "../../../services/dietStrategyService";

const EditBoitelSaleScenario = () => {
  const [localForm, setLocalForm] = useState({
    id: null,
    name: null,
    allowSeparateSell: true,
    animalsInTruck: null,
    animalsInTruckUnit: "cab",
    commitments: [],
    deadlineGoal: null,
    goalType: "MaxProfit",
    marketAnalysisId: null,
    minimumAge: null,
    maximumAge: null,
    maximumWeight: 720,
    minimumWeight: null,
    retroactive: false,
    retroactiveDate: null,
    useDefaultMaximumWeight: true,
    useCustomCarcassHarnessing: false,
    carcassHarnessing: null,
    strongAgeRestriction: true,
    strongWeightRestriction: true,
    strongAnimalsInTruckRestriction: true,
  });
  const [isCanceling, setIsCaneling] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [loadingListCommitments, setLoadingListCommitments] = useState(false);
  const [listMarketAnalysis, setListMarketAnalysis] = useState([]);
  const [loadingChart, setLoadingChart] = useState(false);
  const [loadingListMarketAnalysis, setLoadingListMarketAnalysis] =
    useState(false);
  const [modalSaleCommitmentVisible, setModalSaleCommitmentVisible] =
    useState(false);
  const [maxDeadLineGoal, setMaxDeadLineGoal] = useState(null);
  const [dataChart, setDataChart] = useState([]);
  let formikRef = useRef(null);
  const history = useHistory();
  const { id: boitelSaleScenarioId } = useParams();
  const { setModalSaleCommitmentData } = useBoitelSaleScnearioNewContext();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    animalsInTruck: Yup.number().required(),
    marketAnalysisId: Yup.string().required(),
    minimumAge: Yup.number().required(),
    maximumAge: Yup.number().moreThan(Yup.ref("minimumAge")).required(),
    minimumWeight: Yup.number().required(),
    maximumWeight: Yup.number().moreThan(Yup.ref("minimumWeight")).required(),
    useCustomCarcassHarnessing: Yup.boolean().required(),
    carcassHarnessing: Yup.number()
      .notRequired()
      .nullable()
      .when(["useCustomCarcassHarnessing"], {
        is: (useCustomCarcassHarnessing) => useCustomCarcassHarnessing === true,
        then: Yup.number().required(),
        otherwise: Yup.number().notRequired().nullable(),
      }),
  });
  const { CanvasJSChart } = CanvasJSReact;
  const { Group: InputGroup } = Input;
  /* Chart options */
  const options = {
    animationEnabled: true,
    zoomEnabled: true,
    height: 280,
    backgroundColor: "#fcfcfc",
    axisX: {
      labelFontSize: 12,
      valueFormatString: "MM/YY",
      interval: 1,
      intervalType: "month",
      viewportMinimum: moment().toDate(),
      viewportMaximum: moment().add(1, "years").toDate(),
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
      },
    },
    toolTip: {
      fontFamily: "Asap",
      shared: true,
    },
    axisY: {
      labelFontSize: 12,
      includeZero: true,
      valueFormatString: "###0.##",
      suffix: " R$/@",
      gridDashType: "dot",
    },
    data: dataChart,
  };
  // Redux variables
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const { redirectToListPage, isLoadingSaveRequest } = useSelector(
    (state) => state.boitelSaleScenario
  );
  const dispatch = useDispatch();

  // Efects
  // Redirect effect
  useEffect(() => {
    if (redirectToListPage) {
      history.push("/admin/decisions", { tab: "4" });
      setIsCaneling(false);
      dispatch(BoitelSaleScenarioActions.resetRedirectToListPage());
    }
  }, [dispatch, history, redirectToListPage]);
  // Canceling effect
  useEffect(() => {
    if (isCanceling === true) {
      history.push("/admin/decisions", { tab: "4" });
    }
  }, [history, isCanceling]);
  // Initialize local form
  useEffect(() => {
    async function fetchMarketAnalysis() {
      setLoadingListMarketAnalysis(true);
      try {
        const {
          data: { results },
        } = await getMarketAnalysisDropDown({ groupId, farmId });
        setListMarketAnalysis(results);
        setLoadingListMarketAnalysis(false);
        return results;
      } catch (error) {
        setListMarketAnalysis([]);
        setLoadingListMarketAnalysis(false);
        return [];
      }
    }
    async function renderChart(marketAnalysisId, listOfMarketAnalysis) {
      setLoadingChart(true);
      let chartData = null;
      try {
        const {
          data: { results },
        } = await getFuturePriceChart({ groupId, farmId, marketAnalysisId });
        if (results.length > 0) {
          chartData = results.map((r) => {
            r.x = moment(r.x).toDate();
            r.y = Number.parseFloat(Number(r.y).toFixed(2));
            return r;
          });
        } else {
          chartData = null;
        }
      } catch (error) {
        chartData = null;
      }
      if (chartData !== null) {
        let nameMarketAnalysis =
          listOfMarketAnalysis.find((ma) => ma.id === marketAnalysisId)?.name ||
          "Valores";
        setDataChart([
          {
            type: "line",
            showInLegend: true,
            name: nameMarketAnalysis,
            color: "#4A85AE",
            xValueFormatString: "DD/MM/YYYY",
            yValueFormatString: "R$#######.00",
            dataPoints: chartData,
          },
        ]);
      } else {
        setDataChart([]);
      }

      setLoadingChart(false);
    }
    async function fetch() {
      try {
        const {
          data: { results },
        } = await showBoitelSaleScenarioService({
          groupId,
          farmId,
          id: boitelSaleScenarioId,
        });
        const listOfMarketAnalysis = await fetchMarketAnalysis();
        const { id, name } = results;
        let parameter = results.params[results.params.length - 1];
        parameter.commitments.forEach((c) => {
          c.id = null;
          c.key = uuid();
        });

        // Set lots and diet strategies ids = null
        let maxDsEndDate = moment();
        if (parameter.lots != null && parameter.lots?.length > 0) {
          parameter.lots = await Promise.all(
            parameter.lots.map(async (l) => {
              l.id = null;
              try {
                if (l?.dietStrategies?.length > 0)
                  l.dietStrategies = await Promise.all(
                    l.dietStrategies.map(async (ds) => {
                      ds.id = null;
                      try {
                        const {
                          data: { results },
                        } = await getDietStrategyShow({
                          groupId,
                          farmId,
                          id: ds.dietStrategyId,
                        });
                        ds.dietPeriods = results.dietPeriods;
                        if (ds != null && ds.dietPeriods?.length > 0) {
                          let dsEndDate = moment(
                            ds?.dietPeriods[ds.dietPeriods?.length - 1]?.endDate
                          );
                          if (maxDsEndDate === null) {
                            maxDsEndDate = dsEndDate;
                          } else {
                            if (dsEndDate.isAfter(maxDsEndDate, "days")) {
                              maxDsEndDate = dsEndDate;
                            }
                          }
                        }
                      } catch (error) {
                        console.error(error);
                      } finally {
                        return ds;
                      }
                    })
                  );
              } catch (error) {
              } finally {
                return l;
              }
            })
          );
        }
        if (parameter.marketAnalysisId != null) {
          await renderChart(parameter.marketAnalysisId, listOfMarketAnalysis);
        }
        const { id: idParameter, ...parameterWithoutId } = parameter;
        setMaxDeadLineGoal(maxDsEndDate);
        setLocalForm({
          id,
          name,
          ...parameterWithoutId,
        });
      } catch (error) {
        setLocalForm(null);
        notification.error({
          message: "Erro ao buscar dados do cenário. Contate o suporte.",
        });
        console.error(error);
      } finally {
        setLoadingInfo(false);
      }
    }
    fetch();
  }, [boitelSaleScenarioId, farmId, groupId]);

  // Methods

  // Methods - Commintment
  function newOrEditCommitment(item = null) {
    if (item === null) {
      setModalSaleCommitmentData({
        key: null,
        saleCommitmentType: "AmountAnimals",
        commitmentValue: null,
        commitmentDate: null,
      });
    } else {
      setModalSaleCommitmentData(item);
    }
    setModalSaleCommitmentVisible(true);
  }

  function removeCommitment(item) {
    formikRef.current.setFieldValue(
      "commitments",
      formikRef.current.state.values.commitments.filter(
        (c) => c.key !== item.key
      )
    );
  }

  function saveCommitment(value) {
    if (validateCommitmentForm(value)) {
      try {
        setLoadingListCommitments(true);
        let body = { ...value };
        if (value.key === null) {
          let key = uuid();
          body.key = key;
          formikRef.current.setFieldValue("commitments", [
            ...formikRef.current.state.values.commitments,
            body,
          ]);
        } else {
          formikRef.current.setFieldValue(
            "commitments",
            formikRef.current.state.values.commitments.map((c) =>
              c.key === body.key ? body : c
            )
          );
        }
        setModalSaleCommitmentVisible(false);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingListCommitments(false);
      }
    }
  }

  function validateCommitmentForm(value) {
    let message = null;
    if (value.commitmentDate == null) {
      message = "É necessário informar uma data para o comprometimento.";
    } else if (moment(value.commitmentDate).isAfter(maxDeadLineGoal, "days")) {
      message = `A data de comprometimento deve ser até ${moment(
        maxDeadLineGoal
      ).format("DD/MM/YYYY")}`;
    } else if (value.commitmentValue == null) {
      message =
        "É necessário informar um valor/quantidade de animais para o comprometimento.";
    }
    if (message === null) {
      return true;
    } else {
      notification.error({
        message,
      });
      return false;
    }
  }
  // Methods - Commintment - End

  async function renderChart(marketAnalysisId) {
    setLoadingChart(true);
    let chartData = null;
    try {
      const {
        data: { results },
      } = await getFuturePriceChart({ groupId, farmId, marketAnalysisId });
      if (results.length > 0) {
        chartData = results.map((r) => {
          r.x = moment(r.x).toDate();
          r.y = Number.parseFloat(Number(r.y).toFixed(2));
          return r;
        });
      } else {
        chartData = null;
      }
    } catch (error) {
      chartData = null;
    }
    if (chartData !== null) {
      let nameMarketAnalysis =
        listMarketAnalysis.find((ma) => ma.id === marketAnalysisId)?.name ||
        "Valores";
      setDataChart([
        {
          type: "line",
          showInLegend: true,
          name: nameMarketAnalysis,
          color: "#4A85AE",
          xValueFormatString: "DD/MM/YYYY",
          yValueFormatString: "R$#######.00",
          dataPoints: chartData,
        },
      ]);
    } else {
      setDataChart([]);
    }

    setLoadingChart(false);
  }

  const handleSubmit = useCallback(
    (values, actions) => {
      const { id, name, ...parameterForm } = values;
      const body = {
        id,
        name,
        parameter: {
          ...parameterForm,
        },
      };
      dispatch(
        BoitelSaleScenarioActions.storeUpdateBoitelSaleScenario(
          groupId,
          farmId,
          boitelSaleScenarioId,
          body
        )
      );
    },
    [boitelSaleScenarioId, dispatch, farmId, groupId]
  );

  const handleCancel = useCallback(() => {
    setIsCaneling(true);
  }, []);

  const StrongInput = (field, helper, props) => (
    <div className="inputStrong">
      <span>Forte?</span> <InfoTooltip title={helper} />{" "}
      <Switch
        checked={props?.values?.[field]}
        onChange={(checked, event) => props.setFieldValue(field, checked)}
      />
    </div>
  );

  return (
    <Container>
      {(isLoadingSaveRequest || loadingInfo) && <Loading />}
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ marginBottom: 24 }}
      >
        <Col span={12}>
          <Title>{translation.boitelSaleScenario.edit.title}</Title>
        </Col>
        <Col span={12} align="right">
          <BreadCrumbs>
            <span
              onClick={handleCancel}
              className="pageTreeInative"
              style={{ cursor: "pointer" }}
            >
              Decisões
            </span>
            <span className="pageTreeActive">
              {translation.boitelSaleScenario.edit.title}
            </span>
          </BreadCrumbs>
        </Col>
      </Row>
      {localForm === null ? (
        <BoxText
          color="#D44C4C"
          iconComponent={<Icon type="stop" style={{ color: "#D44C4C" }} />}
        >
          <span>
            Ocorreu um erro ao buscar dados do cenário. Contate o suporte.
          </span>
        </BoxText>
      ) : (
        <Card>
          <Formik
            ref={formikRef}
            enableReinitialize={true}
            initialValues={localForm}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            render={(props) => (
              <form onSubmit={props.handleSubmit} autoComplete="off">
                {/* Error message */}
                {Object.entries(props.errors).length > 0 &&
                  props.submitCount > 0 && (
                    <Row type="flex" justify="center" align="middle">
                      <label className="formLabel error">
                        {translation.error.formError}
                      </label>
                    </Row>
                  )}
                {/* Body */}
                <Row type="flex" gutter={16} justify="start">
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    {/* Name */}
                    <Row type="flex" gutter={8} justify="start">
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row>
                          <label
                            className={
                              props.errors.name && props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            {
                              translation.boitelSaleScenario.new.tabs.parameters
                                .form.name
                            }
                          </label>
                        </Row>
                        <Row>
                          <Input
                            placeholder={translation.defaultPlaceholder}
                            value={props.values?.name}
                            name="name"
                            maxLength={40}
                            onChange={(e) => {
                              props.setFieldValue("name", e.target.value);
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                    {/* MinimumAge and MaximumAge */}
                    <Row type="flex" gutter={8} justify="start" align="middle">
                      <label className="formLabel">Idade para Venda</label>
                      {StrongInput(
                        "strongAgeRestriction",
                        "Ao selecionar esta opção, o intervalo entre a idade mínima e máxima para venda, se tornará uma restrição forte, desta forma, o sistema buscará a melhor oportunidade de venda, respeitando esta premissa. Caso, esta opção, esteja desmarcada, o sistema  irá considerar o intervalo, porém, caso exista uma possibilidade melhor, mesmo que fora deste intervalo de idade, o sistema retornará com esta melhor opção.",
                        props
                      )}
                    </Row>
                    <Row type="flex" gutter={8} justify="start">
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Row>
                          <label
                            className={
                              props.errors.minimumAge && props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            {
                              translation.boitelSaleScenario.new.tabs.parameters
                                .form.minimumAge
                            }
                          </label>
                        </Row>
                        <Row>
                          <InputGroup name="minimumAge" compact>
                            <Input
                              type="number"
                              style={{ width: 80, textAlign: "center" }}
                              value={props.values.minimumAge}
                              name="ageDay"
                              min="0"
                              placeholder="Dias"
                              onChange={(e) => {
                                props.setFieldValue(
                                  "minimumAge",
                                  e.target.value > 0 ? e.target.value : 0
                                );
                              }}
                            />
                            <Input
                              style={{
                                width: 80,
                                textAlign: "center",
                                borderLeft: "1px solid #c4c4c4",
                              }}
                              type="number"
                              name="ageMonth"
                              min="0"
                              placeholder="Meses"
                              value={
                                props.values.minimumAge != null
                                  ? Number(
                                      props.values.minimumAge / 30.417
                                    ).toFixed(0)
                                  : null
                              }
                              onChange={(e) => {
                                props.setFieldValue(
                                  "minimumAge",
                                  e.target.value > 0
                                    ? Math.floor(e.target.value * 30.417)
                                    : 0
                                );
                              }}
                            />
                            <Input
                              style={{
                                width: 80,
                                textAlign: "center",
                                borderLeft: "1px solid #c4c4c4",
                              }}
                              type="number"
                              name="ageYear"
                              min="0"
                              placeholder="Anos"
                              value={
                                props.values.minimumAge != null
                                  ? props.values.minimumAge >= 365
                                    ? Math.floor(props.values.minimumAge / 365)
                                    : 0
                                  : null
                              }
                              onChange={(e) => {
                                props.setFieldValue(
                                  "minimumAge",
                                  e.target.value > 0 ? e.target.value * 365 : 0
                                );
                              }}
                            />
                          </InputGroup>
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Row>
                          <label
                            className={
                              props.errors.maximumAge && props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            {
                              translation.boitelSaleScenario.new.tabs.parameters
                                .form.maximumAge
                            }
                          </label>
                        </Row>
                        <Row>
                          <InputGroup name="maximumAge" compact>
                            <Input
                              type="number"
                              style={{ width: 80, textAlign: "center" }}
                              value={props.values.maximumAge}
                              name="ageDay"
                              min="0"
                              placeholder="Dias"
                              onChange={(e) => {
                                props.setFieldValue(
                                  "maximumAge",
                                  e.target.value > 0 ? e.target.value : 0
                                );
                              }}
                            />
                            <Input
                              style={{
                                width: 80,
                                textAlign: "center",
                                borderLeft: "1px solid #c4c4c4",
                              }}
                              type="number"
                              name="ageMonth"
                              min="0"
                              placeholder="Meses"
                              value={
                                props.values.maximumAge != null
                                  ? Number(
                                      props.values.maximumAge / 30.417
                                    ).toFixed(0)
                                  : null
                              }
                              onChange={(e) => {
                                props.setFieldValue(
                                  "maximumAge",
                                  e.target.value > 0
                                    ? Math.floor(e.target.value * 30.417)
                                    : 0
                                );
                              }}
                            />
                            <Input
                              style={{
                                width: 80,
                                textAlign: "center",
                                borderLeft: "1px solid #c4c4c4",
                              }}
                              type="number"
                              name="ageYear"
                              min="0"
                              placeholder="Anos"
                              value={
                                props.values.maximumAge != null
                                  ? props.values.maximumAge >= 365
                                    ? Math.floor(props.values.maximumAge / 365)
                                    : 0
                                  : null
                              }
                              onChange={(e) => {
                                props.setFieldValue(
                                  "maximumAge",
                                  e.target.value > 0 ? e.target.value * 365 : 0
                                );
                              }}
                            />
                          </InputGroup>
                        </Row>
                      </Col>
                    </Row>
                    {/* MinimumWeight and MaximumWeight*/}
                    <Row type="flex" gutter={8} justify="start" align="middle">
                      <label className="formLabel">Peso para Venda</label>
                      {StrongInput(
                        "strongWeightRestriction",
                        "Ao selecionar esta opção, o intervalo entre o peso mínimo e máximo para venda, se tornará uma restrição forte, desta forma, o sistema buscará a melhor oportunidade de venda, respeitando esta premissa. Caso, esta opção, esteja desmarcada, o sistema  irá considerar o intervalo, porém, caso exista uma possibilidade melhor, mesmo que fora deste intervalo de peso, o sistema retornará com esta melhor opção.",
                        props
                      )}
                    </Row>
                    <Row type="flex" gutter={8} justify="start">
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Row>
                          <label
                            className={
                              props.errors.minimumWeight &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            {
                              translation.boitelSaleScenario.new.tabs.parameters
                                .form.minimumWeight
                            }
                          </label>
                        </Row>
                        <Row>
                          <NumberFormat
                            customInput={Input}
                            value={
                              props.values?.minimumWeight !== null
                                ? props.values?.minimumWeight
                                : null
                            }
                            placeholder={translation.defaultPlaceholder}
                            name="minimumWeight"
                            allowNegative={false}
                            addonAfter="Kg"
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale={true}
                            onValueChange={({ floatValue }) => {
                              props.setFieldValue(
                                "minimumWeight",
                                floatValue
                                  ? floatValue < 1000
                                    ? floatValue
                                    : 999.99
                                  : null
                              );
                            }}
                          />
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Row>
                          <label
                            className={
                              props.errors.maximumWeight &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            {
                              translation.boitelSaleScenario.new.tabs.parameters
                                .form.maximumWeight
                            }
                          </label>
                        </Row>
                        <Row>
                          <NumberFormat
                            customInput={Input}
                            value={
                              props.values?.maximumWeight !== null
                                ? props.values?.maximumWeight
                                : null
                            }
                            placeholder={translation.defaultPlaceholder}
                            name="maximumWeight"
                            allowNegative={false}
                            addonAfter="Kg"
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale={true}
                            onValueChange={({ floatValue }) => {
                              props.setFieldValue(
                                "maximumWeight",
                                floatValue
                                  ? floatValue < 1000
                                    ? floatValue
                                    : 999.99
                                  : null
                              );
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    {/* AnimalsInTruck*/}
                    <Row type="flex" gutter={8} justify="start">
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Row type="flex" gutter={8} align="middle">
                          <label
                            className={
                              props.errors.animalsInTruck &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            {
                              translation.boitelSaleScenario.new.tabs.parameters
                                .form.animalsInTruck
                            }
                          </label>
                          {StrongInput(
                            "strongAnimalsInTruckRestriction",
                            "Ao marcar está opção, o sistema buscará a melhor oportunidade de venda, mesmo que o lote seja descascado, mas respeitando a quantidade de animais por caminhão.",
                            props
                          )}
                        </Row>
                        <Row>
                          <NumberFormat
                            customInput={Input}
                            value={
                              props.values?.animalsInTruck !== null
                                ? props.values?.animalsInTruck
                                : null
                            }
                            allowNegative={false}
                            placeholder={translation.defaultPlaceholder}
                            name="animalsInTruck"
                            addonAfter="cab"
                            decimalScale={0}
                            onValueChange={({ floatValue }) => {
                              props.setFieldValue("animalsInTruck", floatValue);
                            }}
                          />
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Row>
                          <label
                            className={
                              props.errors.allowSeparateSell &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            {
                              translation.boitelSaleScenario.new.tabs.parameters
                                .form.allowSeparateSell
                            }
                          </label>
                        </Row>
                        <Row>
                          <RadioGroup
                            name="allowSeparateSell"
                            value={props.values.allowSeparateSell}
                            defaultValue={false}
                            onChange={(e) => {
                              props.setFieldValue(
                                "allowSeparateSell",
                                e.target.value
                              );
                            }}
                          >
                            <Radio value={true}>
                              {translation.buttons.yes}
                            </Radio>
                            <Radio value={false}>
                              {translation.buttons.no}
                            </Radio>
                          </RadioGroup>
                        </Row>
                      </Col>
                    </Row>
                    {/* useCustomCarcassHarnessing and CarcassHarnessing*/}
                    <Row type="flex" gutter={8} justify="start">
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Row>
                          <label
                            className={
                              props.errors.useCustomCarcassHarnessing &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            {
                              translation.boitelSaleScenario.new.tabs.parameters
                                .form.useCustomCarcassHarnessing
                            }{" "}
                            <InfoTooltip
                              title={
                                translation.boitelSaleScenario.new.tabs
                                  .parameters.form
                                  .useCustomCarcassHarnessingHelp
                              }
                            />
                          </label>
                        </Row>
                        <Row>
                          <RadioGroup
                            name="useCustomCarcassHarnessing"
                            value={props.values.useCustomCarcassHarnessing}
                            defaultValue={false}
                            onChange={(e) => {
                              props.setFieldValue(
                                "useCustomCarcassHarnessing",
                                e.target.value
                              );
                              if (e.target.value === false) {
                                props.setFieldValue("carcassHarnessing", null);
                              }
                            }}
                          >
                            <Radio value={true}>
                              {translation.buttons.yes}
                            </Radio>
                            <Radio value={false}>
                              {translation.buttons.no}
                            </Radio>
                          </RadioGroup>
                        </Row>
                      </Col>
                      {props.values.useCustomCarcassHarnessing === true && (
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <Row>
                            <label
                              className={
                                props.errors.carcassHarnessing &&
                                props.submitCount > 0
                                  ? "formLabel error"
                                  : "formLabel"
                              }
                            >
                              {
                                translation.boitelSaleScenario.new.tabs
                                  .parameters.form.carcassHarnessing
                              }
                            </label>
                          </Row>
                          <Row>
                            <NumberFormat
                              customInput={Input}
                              value={
                                props.values?.carcassHarnessing !== null
                                  ? props.values?.carcassHarnessing
                                  : null
                              }
                              placeholder={translation.defaultPlaceholder}
                              name="carcassHarnessing"
                              allowNegative={false}
                              addonAfter="%"
                              decimalScale={2}
                              decimalSeparator=","
                              thousandSeparator="."
                              fixedDecimalScale={true}
                              onValueChange={({ floatValue }) => {
                                props.setFieldValue(
                                  "carcassHarnessing",
                                  floatValue
                                    ? floatValue <= 100
                                      ? floatValue
                                      : 100
                                    : null
                                );
                              }}
                            />
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
                <Row
                  type="flex"
                  className="withBackground"
                  gutter={16}
                  justify="start"
                >
                  {/* Commitments */}
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Row>
                      <label className="formLabel">
                        {
                          translation.boitelSaleScenario.new.tabs.parameters
                            .form.commitments
                        }
                      </label>
                    </Row>
                    <Row>
                      {props.values.commitments.length > 0 ? (
                        <ListSaleCommitments
                          itemLayout="horizontal"
                          dataSource={props.values.commitments}
                          loading={loadingListCommitments}
                          renderItem={(item, index) => (
                            <ListSaleCommitments.Item key={item.key}>
                              <Row type="flex" justify="space-between">
                                <div>
                                  <span>
                                    <strong>{index + 1}.</strong>
                                    {` ${
                                      item.saleCommitmentType ===
                                      "AmountAnimals"
                                        ? `${item.commitmentValue} animais`
                                        : `${numberMask(
                                            item.commitmentValue,
                                            true
                                          )}`
                                    } até ${moment(item.commitmentDate).format(
                                      "DD/MM/YYYY"
                                    )}  `}
                                  </span>
                                </div>
                                <div className="buttonGroup">
                                  <Icon
                                    component={PencilIcon}
                                    onClick={() => newOrEditCommitment(item)}
                                  />
                                  <Icon
                                    type="delete"
                                    className="delete"
                                    onClick={() => removeCommitment(item)}
                                  />
                                </div>
                              </Row>
                            </ListSaleCommitments.Item>
                          )}
                        />
                      ) : (
                        <p className="emptySaleCommitments">
                          Nenhum compromisso informado
                        </p>
                      )}
                    </Row>
                    <Row>
                      <ButtonStandard
                        type="button"
                        buttonType="typeWithoutBackground"
                        color="#94CF1C"
                        onClick={() => newOrEditCommitment(null)}
                      >
                        <Icon type="plus-circle" />{" "}
                        {
                          translation.boitelSaleScenario.new.tabs.parameters
                            .form.newCommitment
                        }
                      </ButtonStandard>
                    </Row>
                  </Col>
                  {/* MarketAnalysisId */}
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Row type="flex" gutter={8} justify="start">
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row>
                          <label
                            className={
                              props.errors.marketAnalysisId &&
                              props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            {
                              translation.boitelSaleScenario.new.tabs.parameters
                                .form.marketAnalysisId
                            }
                          </label>
                        </Row>
                        <Row>
                          <Select
                            name="marketAnalysisId"
                            style={{ width: "100%" }}
                            value={props.values.marketAnalysisId || undefined}
                            loading={loadingListMarketAnalysis}
                            placeholder={translation.defaultSelectPlaceholder}
                            onChange={(value) => {
                              props.setFieldValue("marketAnalysisId", value);
                              renderChart(value);
                            }}
                          >
                            {listMarketAnalysis
                              .filter((ma) => ma.status === "Active")
                              .map((ma) => (
                                <Select.Option key={ma.id} value={ma.id}>
                                  {ma.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </Row>
                      </Col>
                    </Row>
                    <Row type="flex" gutter={8} justify="start">
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        {(dataChart.length > 0 || loadingChart === true) && (
                          <Spin spinning={loadingChart}>
                            <CanvasJSChart options={options} />
                          </Spin>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* Footer */}
                <Row type="flex" justify="end" gutter={8}>
                  <Col>
                    <ButtonStandard
                      type="button"
                      buttonType="secondary"
                      onClick={handleCancel}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>
                  </Col>
                  <Col>
                    <ButtonStandard type="submit" buttonType="principal">
                      {translation.buttons.edit}
                    </ButtonStandard>
                  </Col>
                </Row>
              </form>
            )}
          />
        </Card>
      )}
      <ModalBoitelSaleScenarioCommitment
        modalSaleCommitmentVisible={modalSaleCommitmentVisible}
        saveCommitment={saveCommitment}
        cancelCommitment={() => setModalSaleCommitmentVisible(false)}
      />
    </Container>
  );
};

export default EditBoitelSaleScenario;
