import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
/* Redux Libs */
import { useSelector, useDispatch } from "react-redux";
import { Creators as SaleScenarioActions } from "../../../store/ducks/saleScenario";
/** Components */
import { Container, Title, CustomDivider, Body } from "./styles";
import { Row, Col, Icon } from "antd";

import BreadCrumbs from "../../../components/utils/breadCrumbs";
import ButtonStandard from "../../../components/utils/button";
import TabSelectLots from "./tabs/selectLots";
import TabSelectDietStragies from "./tabs/selectDietStrategies";
import TabParameters from "./tabs/parameters";
import RoutingLeavingGuardModal from "../../../components/modals/routingLeavingGuardModal";
import UserTriggerInProcessMessage from "../../../components/utils/userTriggerInProcessMessage";

const NewSaleScenario = ({ history }) => {
  /** Variables */
  /** Redux variables */
  const { translation } = useSelector((state) => state.app);
  const { activeTab, newSaleScenarioShouldBlockNavigation } = useSelector(
    (state) => state.saleScenario
  );
  const dispatch = useDispatch();
  /** Effect */
  useEffect(() => {
    dispatch(SaleScenarioActions.enableDisableBlockNavigationNewScenario(true));
  }, [dispatch]);
  /** Methods */
  function goNextTab() {
    dispatch(
      SaleScenarioActions.changeTab(
        activeTab === "tab1" ? "tab2" : activeTab === "tab2" ? "tab3" : "tab3"
      )
    );
  }
  function goPreviousTab() {
    dispatch(
      SaleScenarioActions.changeTab(
        activeTab === "tab2" ? "tab1" : activeTab === "tab3" ? "tab2" : "tab1"
      )
    );
  }
  const handleCancel = () => {
    dispatch(
      SaleScenarioActions.enableDisableBlockNavigationNewScenario(false)
    );
    history.goBack();
  };
  return (
    <>
      <RoutingLeavingGuardModal
        when={newSaleScenarioShouldBlockNavigation}
        navigate={(path) => history.push(path)}
        message="Parece que você está saindo sem salvar o cenário de venda."
      />
      <Container>
        <Row type="flex" justify="space-between" align="middle">
          <Col span={12}>
            <Title>Criação: Cenário de venda</Title>
          </Col>
          <Col span={12} align="right">
            <BreadCrumbs>
              <Link to="/admin/decisions">
                <span className="pageTreeInative">Decisões</span>
              </Link>
              <span className="pageTreeActive">Criação: Cenário de Venda</span>
            </BreadCrumbs>
          </Col>
        </Row>
        <UserTriggerInProcessMessage triggerAction={"AnimalDailyCost"} />
        <CustomDivider dashed />
        <Body>
          {/* Message and buttons */}
          <Row type="flex" style={{ marginBottom: 10, alignItems: "center" }}>
            <Col xs={16} sm={16} md={16} lg={16} xl={16}>
              {activeTab !== "tab1" && (
                <ButtonStandard buttonType="arrow" onClick={goPreviousTab}>
                  <Icon type="arrow-left" className="iconBack" />
                </ButtonStandard>
              )}
              <strong style={{ marginLeft: 8 }}>
                {activeTab === "tab1"
                  ? `Selecione os lotes que irão compor o cenário de venda`
                  : activeTab === "tab2"
                  ? `Agora selecione as estratégias por lote que irão compor o cenário de venda`
                  : activeTab === "tab3" &&
                    `Finalize definindo os parâmetros do cenário de venda`}
              </strong>
            </Col>
            {activeTab !== "tab3" ? (
              <Col
                xs={{ span: 8 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                xl={{ span: 8 }}
                align="right"
                className="buttonsCol"
              >
                <ButtonStandard
                  type="button"
                  buttonType="type4"
                  size="s"
                  onClick={handleCancel}
                >
                  {translation.buttons.cancel}
                </ButtonStandard>

                <ButtonStandard
                  type="button"
                  buttonType="type1"
                  width="121px"
                  height="35px"
                  padding="5px 10px 5px 10px"
                  onClick={goNextTab}
                >
                  Próximo
                </ButtonStandard>
              </Col>
            ) : null}
          </Row>
          {/* Tabs */}
          <Row type="flex" style={{ marginBottom: 24 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {activeTab === "tab1" ? (
                <TabSelectLots />
              ) : activeTab === "tab2" ? (
                <TabSelectDietStragies />
              ) : activeTab === "tab3" ? (
                <TabParameters />
              ) : null}
            </Col>
          </Row>
        </Body>
      </Container>
    </>
  );
};

export default withRouter(NewSaleScenario);
