import React, { createContext, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const ModuleContext = createContext({
  module: null,
  functions: [],
  switchModule: (newModule, newFunctions) => {},
  isAllowedFunctions: (functionsName) => {},
  isAllowedSomeFunctions: (functionsName) => {},
  isAllowedRoles: (roles, reverse) => {},
});

export const ModuleContextProvider = ({ children }) => {
  const [module, setModule] = useState(null);
  const [functions, setFunctions] = useState([]);

  const {
    groupSelected,
    user: { roles: userRoles },
  } = useSelector((state) => state.app);

  const switchModule = useCallback((newModule, newFunctions) => {
    if (newModule && newFunctions) {
      setModule(newModule);
      setFunctions(newFunctions.map((f) => f.name));
    }
  }, []);

  const isAllowedFunctions = useCallback(
    (functionsName) => {
      if (functions.length > 0) {
        if (functionsName.length > 0) {
          let countTrue = 0;
          for (const functionName of functionsName) {
            const result = functions.findIndex((f) => f === functionName) >= 0;
            if (result === true) {
              countTrue++;
            }
          }
          return countTrue === functionsName.length;
        }
        return false;
      } else {
        return false;
      }
    },
    [functions]
  );

  const isAllowedSomeFunctions = useCallback(
    (functionsName) => {
      if (functions.length > 0) {
        if (functionsName.length > 0) {
          let countTrue = 0;

          for (const functionName of functionsName) {
            const result = functions.findIndex((f) => f === functionName) >= 0;
            if (result === true) {
              countTrue++;
            }
          }
          return countTrue >= 1;
        }
        return false;
      } else {
        return false;
      }
    },
    [functions]
  );

  const isAllowedRoles = useCallback(
    (roles, reverse) => {
      return userRoles?.includes("Admin")
        ? true
        : roles.length === 0
        ? true
        : reverse === false
        ? roles.some((r) => userRoles?.includes(r))
        : !roles.some((r) => userRoles?.includes(r));
    },
    [userRoles]
  );

  useEffect(() => {
    if (groupSelected)
      switchModule(groupSelected.module, groupSelected.functions);
  }, [groupSelected, switchModule]);

  return (
    <ModuleContext.Provider
      value={{
        module,
        functions,
        switchModule,
        isAllowedFunctions,
        isAllowedSomeFunctions,
        isAllowedRoles,
      }}
    >
      {children}
    </ModuleContext.Provider>
  );
};
