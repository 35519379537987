import auth from "../config/api_auth";
import apiIam from "../config/api_iam";
import moment from "moment";
import querystring from "querystring";

export function doAuthentication(payload) {
  const { username, password } = payload;
  const body = querystring.stringify({
    grant_type: "password",
    username: username,
    password: password,
  });
  return auth.post("", body);
}

export function isAuthenticated() {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("@Bovexo:token");
    const expires_in = moment(
      new Date(localStorage.getItem("@Bovexo:expires_in"))
    );
    const now = moment();
    const diff = now.diff(expires_in, "minutes");
    if (token != null) {
      if (diff >= -1440 && diff < 0) {
        resolve("almostExpired");
      } else {
        if (diff >= 0) {
          resolve("expired");
        } else {
          resolve("noExpired");
        }
      }
    } else {
      resolve("expired");
    }
  });
}

export async function doRefreshToken(refresh_token) {
  const body = querystring.stringify({
    grant_type: "refresh_token",
    refresh_token,
  });

  return auth.post("", body);
}

export function sendCodeToEmail(body) {
  return apiIam.post(`/bovexo/auth/signup/send/code`, body);
}

export function verifyExistsByEmail(values) {
  return apiIam.post(`/bovexo/auth/signup/verify/emails`, { email: values });
}

export function createUser(values) {
  return apiIam.post(`/bovexo/auth/signup/users`, values);
}

export function createGroupAndFarm(values) {
  return apiIam.post(`/bovexo/auth/signup/groups/farms`, values);
}
