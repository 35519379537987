import styled from "styled-components";
import { Divider, Drawer, Tabs } from "antd";

export const Table = styled.table`
  width: 100% !important;
  min-width: 100% !important;
  margin-top: 10px;
  tbody {
    tr {
      background: #f5f5f5;
      td {
        padding-top: 10px;
        padding-bottom: 10px;
        font-family: Asap;
        font-weight: normal;
        font-size: 12px;

        color: #4b4b4b;

        &:first-child {
          text-align: left;
          padding-left: 15px;
        }
        &:last-child {
          width: 120px;
          padding-right: 15px;
          text-align: right;
        }

        span {
          font-family: Asap;
          font-weight: bold;
          font-size: 12px;
          text-align: center;
          color: #a5a5a5;
          display: flex;
          align-items: center;
          &.success {
            color: #41c36f;
          }
          svg {
            margin-right: 5px;
          }
        }

        div.buttonGroup {
          display: flex;
          align-items: flex-end;
        }

        button {
          border: none;
          background: transparent;
          font-family: Asap;
          font-weight: normal;
          font-size: 12px;
          display: flex;

          &.add {
            align-items: center;
            color: #4a85ae;
            svg {
              margin-left: 5px;
            }
          }

          &.edit {
            color: #9386aa;
            svg {
              margin-left: 5px;
              path {
                fill: #9386aa !important;
              }
            }
          }

          &:hover {
            cursor: pointer;
          }

          &.delete {
            color: #d44c4c;
            svg {
              margin-left: 0px;
              path {
                fill: #d44c4c !important;
              }
            }

            &:before {
              content: "|";
              color: #a5a5a5;
              margin-left: 10px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
`;

export const Container = styled(Drawer)`
  .rowLabel {
    margin-bottom: 7px;
    margin-top: 19px;
  }

  .drawerForm {
    width: 100%;
    padding: 0px 24px;
    min-height: -moz-calc(100vh - 165px);
    min-height: -webkit-calc(100vh - 165px);
    min-height: calc(100vh - 165px);
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;

    &:first-child {
      margin-top: 5px;
    }
    &.error {
      color: #d44c4c;
    }
  }

  .rowInputData {
    font-family: "Asap", sans-serif;
    font-size: 12px;
    margin-top: 9px;
    color: #4b4b4b;
  }

  .tableButtonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      display: flex;
      justify-content: center !important;
      text-align: center !important;
      align-items: center !important;
      font-size: 14px !important;
      width: 77px !important;
      height: 30px !important;

      svg {
        margin-right: 3px !important;
      }
    }

    button:first-of-type {
      width: 116px !important;
      margin-right: 12px !important;
    }
  }

  .ant-switch-checked {
    background-color: #684e94 !important;
  }
`;

export const CustomDivider = styled(Divider)`
  margin: 10px 0 !important;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 10px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;

export const SupplementInfoCard = styled.div`
  width: 100%;
  border-radius: 6px;
  background: transparent;
  border: 1px dashed #fe8d2a;
  margin-top: 13px;
  font-family: Asap;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  color: #fe8d2a;
  text-align: center;
  padding: 8px;
  svg {
    margin-right: 30px;
    g {
      path {
        fill: #fe8d2a !important;
        color: #fe8d2a !important;
      }
    }
  }
`;

export const TabContainer = styled(Tabs)`
  margin-bottom: 10px !important;

  .ant-tabs-ink-bar {
    background-color: #684e94;
    height: 5px;
  }

  .ant-tabs-line {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab {
    font-family: "Asap" !important;
    font-size: 14px !important;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab-disabled,
  .ant-tabs-nav .ant-tabs-tab-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: #4b4b4b;
    font-weight: bold;
  }

  .ant-tabs-bar {
    border-bottom: 1px solid #a9c133;
  }

  .ant-tabs-tabpane {
    background-color: #fff;
    margin-top: -15px;
  }
`;
