import React, { useCallback, useState } from "react";

import { Dropdown, Menu, Spin, Tooltip } from "antd";
import { Container } from "./styles";
import MenuVerticalIcon from "../icons/menuVertical";
import { processReport } from "../../../services/reportService";
import { useSelector } from "react-redux";

function AlertButton({ alert, alertSelected, handleAlertSelected }) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  // Methods
  const handleDownloadReport = useCallback(
    async (pAlert) => {
      setIsLoading(true);
      try {
        const reportType = "PDF";
        const reportName =
          pAlert.entity === "lot"
            ? "LotAlertReport"
            : pAlert.entity === "animal"
            ? "AnimalAlertReport"
            : pAlert.entity === "sale_scenario"
            ? "SaleScenarioAlertReport"
            : pAlert.entity === "diet_strategy"
            ? "DietStrategyAlertReport"
            : "";
        const reportSearch = `type=${pAlert.type};executionDate=${pAlert.executionDate}`;
        const ids = pAlert.entityIds;
        const { data } = await processReport({
          groupId,
          farmId,
          reportName,
          reportType,
          reportSearch,
          ids,
        });
        if (data != null) {
          const fileName =
            reportType === "PDF"
              ? `BovExo_relatorio_${reportName.toLowerCase()}.pdf`
              : `BovExo_relatorio_${reportName.toLowerCase()}.xlsx`;
          const downloadUrl = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/x-download",
            })
          );
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
    [farmId, groupId]
  );
  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => handleDownloadReport(alert)}>
        Exportar relatório
      </Menu.Item>
    </Menu>
  );
  return (
    <Container
      alertSelected={
        alert.id === alertSelected?.id && alertSelected?.failed === alert.failed
          ? true
          : false
      }
    >
      <Spin spinning={isLoading}>
        <Tooltip title={alert.tooltip}>
          <div
            className="messageContainer"
            onClick={() => handleAlertSelected(alert)}
          >
            <p dangerouslySetInnerHTML={{ __html: alert.button }}></p>
          </div>
        </Tooltip>
        <Dropdown overlay={menu} trigger={["click"]}>
          <button className="buttonMenu">
            <MenuVerticalIcon />
          </button>
        </Dropdown>
      </Spin>
    </Container>
  );
}

export default AlertButton;
