import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Creators as SupplierGroupActions } from "../../../store/ducks/supplierGroup";

// Components
import {
	Icon,
	Menu,
	Row,
	Col,
	Table,
	Dropdown,
	notification,
} from "antd";

import { Container, PageTitle } from "./styles";
import TagStatus from "../../../components/utils/tagStatus";
import { Container as MenuContainer } from "../../../components/utils/table/menu/styles";
import MenuIcon from "../../../components/utils/table/icons/menu";
import ButtonStandard from "../../../components/utils/button";

// Services
import { findInvitesBySupplierGroupId, resendEmailInvite } from "../../../services/supplierInvitesService";
import DrawerInviteSupplierGroup from "./form";

const InvitesSupplierGroup = (props) => {

	// Variable
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const dispatch = useDispatch();

	// Variable Redux
	const {
		translation,
		user: { id: userId },
		groupSelected: { id: supplierGroupId },
		farmSelected: { id: supplierGroupFarmId },
	} = useSelector((state) => state.app);

	// Effect
	useEffect(() => {
		async function fetchData(page = 0, sorter = {}, filters = "", size = 10) {
			try {
				setIsLoading(true);
				const {
					data: { results },
				}
					= await findInvitesBySupplierGroupId({
						supplierGroupId,
						supplierGroupFarmId,
						page,
						sorter,
						filters,
						size,
					});
				setIsLoading(false);
				setData(results);
			} catch (error) {
				notification.error({
					message: "Não foi possível retornar os convites",
				});
				setIsLoading(false);
				setData(null);
			}
		}
		fetchData();
	}, [supplierGroupId, supplierGroupId]);

	// Methods
	async function fetchData(page = 0, sorter = {}, filters = "", size = 10) {
		try {
			setIsLoading(true);
			const {
				data: { results },
			} = await findInvitesBySupplierGroupId({
				supplierGroupId,
				supplierGroupFarmId,
				page,
				sorter,
				filters,
				size,
			});
			setIsLoading(false);
			setData(results);
		} catch (error) {
			notification.error({
				message: "Não foi possível retornar os convites",
			});
			setIsLoading(false);
			setData(null);
		}
	}

	function menu(id, record) {
		return (
			<Menu>
				{(record != null && record.status === "Sended") && (
					<Menu.Item key="1" onClick={() => handleResendEmail(id)}>
						Reenviar convite
					</Menu.Item>
				)}
			</Menu>
		);
	}

	async function handleResendEmail(id) {
		try {
			await resendEmailInvite(
				{
					supplierGroupId,
					supplierGroupFarmId,
					id
				});
			notification.success({
				message: "Reenvio de email realizado com sucesso!",
			});
		} catch (error) {
			notification.error({
				message: "Ocorreu um erro ao tentar reenviar os emails!",
			});
		}

	}

	async function handleTableChange(pagination, filters, sorter) {
		let search = "";
		await fetchData(
			pagination.current,
			sorter,
			search,
			pagination.pageSize
		);
	};

	function openDrawerInvites() {
		dispatch(SupplierGroupActions.showDrawer());
	}

	return (
		<Container>
			<Row type="flex" className="rowHeader" align="middle">
				<Col span={8}>
					<PageTitle>Convites</PageTitle>
				</Col>
				<Col span={16} align="right">
					<ButtonStandard
						buttonType="typeWithoutBackground"
						onClick={() => fetchData()}
					>
						<Icon type="reload" />
					</ButtonStandard>
					<ButtonStandard buttonType="type8" onClick={() => openDrawerInvites()}>
						Convidar
					</ButtonStandard>

				</Col>
			</Row>
			<Row type="flex">
				<Col span={24}>
					<Table
						loading={isLoading}
						rowKey="id"
						dataSource={data != null ? data.content : []}
						pagination={{
							total: data != null ? data.totalElements : 0,
							size: data != null ? data.size : 0,
							current: data != null ? data.number + 1 : 0,
							hideOnSinglePage:
								data !== null && Object.entries(data).length !== 0
									? data.totalElements > 10
										? false
										: true
									: true,
							showSizeChanger: true,
							pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
						}}
						size="small"
						scroll={{
							x: true,
						}}
						onChange={handleTableChange}
					>
						<Table.Column
							title={translation.farm.table.columns.status}
							dataIndex="status"
							key="status"
							align="left"
							sorter
							render={(status) =>
								status === "Sended" ? (
									<TagStatus
										background="#FFDBBC"
										borderColor="#FE8D2A"
										color="#FE8D2A"
									>
										Enviado
									</TagStatus>
								) : status === "Accepted" ? (
									<TagStatus
										background="#E2FFE5"
										borderColor="#33C162"
										color="#33C162"
									>
										Confirmado
									</TagStatus>
								) : (
									<TagStatus
										background="#FBC7C7"
										borderColor="#D44C4C"
										color="#D44C4C"
									>
										Cancelado
									</TagStatus>
								)
							}
						/>
						<Table.Column
							title="Nome"
							dataIndex="fullName"
							key="name"
							align="left"
							sorter

						/>
						<Table.Column
							title="Email"
							dataIndex="email"
							key="email"
							align="left"
							sorter
						/>

						<Table.Column
							align="left"
							render={(text, record) => (
								<Dropdown
									overlay={menu(record.id, record)}
									trigger={["click"]}
									key={record.id}
								>
									<MenuContainer>
										<MenuIcon />
									</MenuContainer>
								</Dropdown>
							)}
						/>
					</Table>
				</Col>
			</Row>

		</Container>
	);
};

export default withRouter(InvitesSupplierGroup);
