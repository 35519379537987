import React, { Component } from "react";
import { Async } from "react-async";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import moment from "moment";
/** Redux libs */
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as LotActions } from "../../../store/ducks/lot";
/** Components */
import {
  Row,
  Col,
  Input,
  Select,
  Tooltip,
  Icon,
  Table,
  DatePicker,
  notification,
  Modal,
} from "antd";
import ButtonStandard from "../../utils/button";
import {
  Container,
  Footer,
  ButtonProperty,
  CustomDivider,
  AlertCard,
  InfoCardDashed,
} from "./styles";
import InfoIcon from "../../utils/icons/info";
import NoteIcon from "../../utils/icons/note";
import NumberFormat from "react-number-format";
import RadioGroup from "antd/lib/radio/group";
import RadioButton from "../../utils/radioButton";
/** Services */
import { getListOfProfitCenterIndex } from "../../../services/profitCenterService";
import { getPicketIndex } from "../../../services/picketService";
import {
  getLotIndex,
  getLotStatisticsAsync,
} from "../../../services/lotService";
import { getBreedIndexActive } from "../../../services/breedService";

class DrawerLotDismember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breeds: [],
      formLot: {
        id: null,
        name: "",
        breedId: null,
        gender: "Male",
        cap: false,
        existing: true,
        lotsData: null,
        identification: null,
        asymptoticWeight: null,
        carcassHarnessing: null,
        supplierId: null,
        profitCenterId: null,
        dietStrategyBaseLine: null,
        dietStrategies: [],
        referenceAcquisitionDate: null,
        referenceAcquisitionWeight: null,
        referenceBirthdayDate: null,
        status: "Pending",
        averageAnimalStay: null,
      },
      loadingTable: false,
      listOfProfitCenters: null,
      listOfPickets: null,
      listofDietStrategies: null,
      error: "",
      saveOptions: "only_save",
    };

    this.formik = React.createRef();
  }

  closeDrawer = () => {
    const { lotActions, lotDismember } = this.props;
    this.formik.current.resetForm({
      id: null,
      name: "",
      breedId: null,
      gender: "Male",
      cap: false,
      existing: true,
      lotsData: null,
      identification: null,
      asymptoticWeight: null,
      carcassHarnessing: null,
      supplierId: null,
      profitCenterId: null,
      dietStrategyBaseLine: null,
      dietStrategies: [],
      referenceAcquisitionDate: null,
      referenceAcquisitionWeight: null,
      referenceBirthdayDate: null,
      status: "Pending",
      averageAnimalStay: null,
    });
    this.setState({
      error: "",
      formLot: {
        id: null,
        name: "",
        breedId: null,
        gender: "Male",
        cap: false,
        existing: true,
        lotsData: null,
        identification: null,
        asymptoticWeight: null,
        carcassHarnessing: null,
        supplierId: null,
        profitCenterId: null,
        dietStrategyBaseLine: null,
        dietStrategies: [],
        referenceAcquisitionDate: null,
        referenceAcquisitionWeight: null,
        referenceBirthdayDate: null,
        status: "Pending",
        averageAnimalStay: null,
      },
    });
    lotActions.hideDrawerLotDismember(lotDismember);
  };

  fetchData = async (
    groupSelected,
    farmSelected,
    page = 0,
    sorter = {},
    filters = "",
    size = 10
  ) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    const { formLot } = this.state;
    this.setState({ loadingTable: true });
    try {
      let {
        data: { results: lots },
      } = await getLotIndex({ groupId, farmId, page, sorter, filters, size });
      this.setState({
        formLot: {
          ...formLot,
          lotsData: lots,
          existing: true,
        },
      });
    } catch (error) {
    } finally {
      this.setState({ loadingTable: false });
    }
  };

  getBreeds = async (groupSelected, farmSelected) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    try {
      const {
        data: { results: breeds },
      } = await getBreedIndexActive({
        groupId,
        farmId,
        withoutBreedCurveParam: true,
      });
      this.setState({ breeds });
    } catch (error) {
      this.setState({ breeds: [] });
    }
  };

  getProfitCenters = async (groupSelected, farmSelected) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;

    const {
      data: { results: allProfitCenters },
    } = await getListOfProfitCenterIndex({ groupId, farmId });
    this.setState({
      listOfProfitCenters: allProfitCenters.filter(
        (e) => e.status === "Active"
      ),
    });
  };

  getPickets = async (profitCenterId) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;

    let filters =
      profitCenterId !== null
        ? `profitCenterId='${profitCenterId}'&status='B'`
        : "";
    let page = 0;
    let sorter = {};
    let size = 10;
    let withoutPagination = true;
    const {
      data: { results: allPickets },
    } = await getPicketIndex({
      groupId,
      farmId,
      page,
      sorter,
      filters,
      size,
      withoutPagination,
    });
    this.setState({
      listOfPickets: allPickets,
    });
  };

  onSelectChange = (selectedRowKeys) => {
    const { formLot } = this.state;
    formLot.lotsData.content.map((lot) =>
      lot.id === selectedRowKeys[0]
        ? this.setState({
            formLot: {
              ...formLot,
              id: lot.id,
              name: lot.name,
              saleScenarioId: lot.saleScenarioId,
              saleScenarioName: lot.saleScenarioName,
              selectedRowKeys,
            },
          })
        : null
    );
  };

  validateForm = (lot, existing) => {
    if (existing && lot.id === null) {
      notification.error({
        message: "É necessário selecionar um lote.",
      });
      return false;
    }

    if (
      moment(lot.referenceAcquisitionDate).isBefore(lot.referenceBirthdayDate)
    ) {
      if (!existing) {
        this.setState({ error: "referenceBirthdayDate" });
        return false;
      }
    }
    if (moment(lot.referenceAcquisitionDate).isAfter(lot.dismembermentDate)) {
      this.setState({ error: "dismembermentDate" });
      return false;
    }
    if (
      lot.carcassHarnessing != null &&
      (lot.carcassHarnessing < 45 || lot.carcassHarnessing > 60)
    ) {
      if (!existing) {
        notification.error({
          message:
            'O valor do campo "Aproveitamento de Carcaça" deve estar entre o intervalo de valores (45% e 60%)',
        });
        return false;
      }
    }

    return true;
  };

  handleSubmitModalForm = async (values, actions) => {
    const {
      lot: {
        lotDismember: { saleScenarioId, saleScenarioName },
      },
    } = this.props;

    const isLotOriginFromSaleScenario = saleScenarioId !== null;
    const isLotDestinationFromSaleScenario =
      values.saleScenarioId != null ? true : false;

    if (isLotOriginFromSaleScenario) {
      Modal.confirm({
        centered: true,
        title: "Atenção !",
        content: (
          <div>
            <p style={{ margin: 0, textAlign: "justify" }}>
              O Lote de Origem selecionado está associado ao Cenário de Venda{" "}
              <strong>{saleScenarioName}</strong>, que foi definido como
              “Favorito”.
            </p>
            <p style={{ fontSize: 12, textAlign: "justify", marginTop: 10 }}>
              Sugerimos reavaliar o Cenário de Venda, diante destas alterações
              no Lote de Produção.
            </p>
          </div>
        ),
        onOk: () => this.handleUpdateLotDismember(values),
      });
    } else if (isLotDestinationFromSaleScenario) {
      Modal.confirm({
        centered: true,
        title: "Atenção !",
        content: (
          <div>
            <p style={{ margin: 0 }}>
              O Lote de Destino dos animais está associado ao Cenário de Venda{" "}
              <strong>{values.saleScenarioName}</strong>, que foi definido como
              “Favorito”.
            </p>
            <p style={{ fontSize: 12, textAlign: "justify", marginTop: 10 }}>
              Sugerimos reavaliar o Cenário de Venda, diante destas alterações
              no Lote de Produção.
            </p>
          </div>
        ),
        onOk: () => this.handleUpdateLotDismember(values),
      });
    } else {
      this.handleUpdateLotDismember(values);
    }
  };

  handleUpdateLotDismember = (values) => {
    const { existing } = this.state.formLot;
    const {
      lot: { lotDismember, lotDismemberSelectedAnimalsKeys },
      lotActions,
    } = this.props;

    values.dietStrategies =
      values.dietStrategies != null && values.dietStrategies.length > 0
        ? values.dietStrategies.map((ds) => ({
            dietStrategyId: ds.dietStrategyId,
            baseline: ds.baseline,
          }))
        : [];

    let lot = {
      id: values.id,
      name: values.name,
      breedId: values.breedId,
      gender: values.gender,
      cap: values.cap,
      profitCenterId: values.profitCenterId,
      picketId: values.picketId,
      dietStrategies: [...values.dietStrategies],
      status: values.status,
      referenceAcquisitionDate: values.referenceAcquisitionDate,
      referenceAcquisitionWeight: values.referenceAcquisitionWeight,
      referenceBirthdayDate: values.referenceBirthdayDate,
      asymptoticWeight: values.asymptoticWeight,
      carcassHarnessing: values.carcassHarnessing,
      dismembermentDate: values.dismembermentDate,
      animals: lotDismemberSelectedAnimalsKeys,
      averageAnimalStay: values.averageAnimalStay,
    };
    if (values.dietStrategyBaseLine != null) {
      const { dietStrategyId, baseline } = values.dietStrategyBaseLine;
      lot = {
        ...lot,
        dietStrategies: [...lot.dietStrategies, { dietStrategyId, baseline }],
      };
    }
    if (this.validateForm(lot, existing)) {
      const { destinationLots } = lotDismember;

      let tempDestinationLots = [];

      if (destinationLots == null) {
        tempDestinationLots = [lot];
      } else {
        tempDestinationLots = [...destinationLots, lot];
      }

      const newLotDismember = {
        ...lotDismember,
        destinationLots: tempDestinationLots,
      };

      lotActions.updateLotDismember(newLotDismember, true);

      this.formik.current.resetForm({
        id: null,
        name: "",
        breedId: null,
        gender: "Male",
        cap: false,
        existing: true,
        lotsData: null,
        identification: null,
        asymptoticWeight: null,
        carcassHarnessing: null,
        supplierId: null,
        profitCenterId: null,
        dietStrategyBaseLine: null,
        dietStrategies: [],
        referenceAcquisitionDate: null,
        referenceAcquisitionWeight: null,
        referenceBirthdayDate: null,
        status: "Pending",
        averageAnimalStay: null,
      });

      this.setState({
        error: "",
        formLot: {
          id: null,
          name: "",
          breedId: null,
          gender: "Male",
          cap: false,
          existing: true,
          lotsData: null,
          identification: null,
          asymptoticWeight: null,
          carcassHarnessing: null,
          supplierId: null,
          profitCenterId: null,
          dietStrategyBaseLine: null,
          dietStrategies: [],
          referenceAcquisitionDate: null,
          referenceAcquisitionWeight: null,
          referenceBirthdayDate: null,
          status: "Pending",
          averageAnimalStay: null,
        },
      });
    }
  };

  handleSelectProperty = (props, existing) => {
    const {
      lot: { lotDismember },
    } = this.props;

    if (existing === false) {
      props.setValues({
        ...props.values,
        id: null,
        name: "",
        profitCenterId: null,
        picketId: null,
        dietStrategyBaseLine: null,
        dietStrategies: [],
        status: "Pending",
        lotsData: [],
        selectedRowKeys: [],
        searchInput: "",
        averageAnimalStay: 366,
        referenceAcquisitionDate: lotDismember?.referenceAcquisitionDate,
        referenceAcquisitionWeight: lotDismember?.referenceAcquisitionWeight,
        referenceBirthdayDate: lotDismember?.referenceBirthdayDate,
        existing,
      });
      this.setState({
        formLot: {
          id: null,
          name: "",
          profitCenterId: null,
          picketId: null,
          dietStrategyBaseLine: null,
          dietStrategies: [],
          status: "Pending",
          lotsData: [],
          selectedRowKeys: [],
          searchInput: "",
          averageAnimalStay: 366,
          referenceAcquisitionDate: lotDismember?.referenceAcquisitionDate,
          referenceAcquisitionWeight: lotDismember?.referenceAcquisitionWeight,
          referenceBirthdayDate: lotDismember?.referenceBirthdayDate,
          existing,
        },
      });
    } else {
      const {
        app: { groupSelected, farmSelected },
        lot: { lotDismember },
      } = this.props;

      this.fetchData(
        groupSelected,
        farmSelected,
        0,
        {},
        `is_receipt=false;status in ('A', 'P');id != '${lotDismember.id}';`,
        10
      );
    }
  };

  handleDrawerVisible = async (visible) => {
    if (visible) {
      const {
        app: { groupSelected, farmSelected },
        lot: { lotDismember },
      } = this.props;

      this.fetchData(
        groupSelected,
        farmSelected,
        0,
        {},
        `is_receipt=false;status in ('A', 'P');id != '${lotDismember.id}';`,
        10
      );

      this.setState({
        formLot: {
          ...this.state.formLot,
          status: "Pending",
          breedId: lotDismember?.breedId,
          cap: lotDismember?.cap,
          gender: lotDismember?.gender,
          referenceAcquisitionDate: lotDismember?.referenceAcquisitionDate,
          referenceAcquisitionWeight: lotDismember?.referenceAcquisitionWeight,
          referenceBirthdayDate: lotDismember?.referenceBirthdayDate,
        },
      });
      if (
        Object.entries(groupSelected).length > 0 &&
        Object.entries(farmSelected).length > 0
      ) {
        this.getBreeds(groupSelected, farmSelected);
        this.getProfitCenters(groupSelected, farmSelected);
      }
    }
  };

  handleSelectProfitCenter = (value, props) => {
    const { formLot } = this.state;

    props.setValues({
      ...props.values,
      profitCenterId: value,
    });
    this.setState({
      formLot: {
        ...formLot,
        profitCenterId: value,
      },
    });

    if (value !== null) {
      this.getPickets(value);
    } else {
      this.setState({
        listOfPickets: [],
      });
    }
  };

  handleTableChange = async (pagination, filters, sorter) => {
    this.setState({
      paginationInfo: pagination,
      sortedInfo: sorter,
      filteredInfo: filters,
    });
    const {
      app: { groupSelected, farmSelected },
      lot: { lotDismember },
    } = this.props;

    let searchInput = "";
    if (
      this.state.formLot?.searchInput !== null &&
      this.state.formLot?.searchInput !== undefined
    ) {
      searchInput = `upper(name) like upper('%25${this.state.formLot.searchInput
        .toString()
        .toLowerCase()}%25');`;
    }

    let search = `is_receipt=false;status in ('A', 'P');${searchInput}id != '${lotDismember.id}';`;

    await this.fetchData(
      groupSelected,
      farmSelected,
      pagination.current,
      sorter,
      search,
      pagination.pageSize
    );
  };

  handleSearchLot = (e) => {
    e.preventDefault();
    const {
      app: { groupSelected, farmSelected },
      lot: { lotDismember },
    } = this.props;
    const { formLot } = this.state;

    this.fetchData(
      groupSelected,
      farmSelected,
      0,
      {},
      `is_receipt=false;status in ('A', 'P');upper(name) like upper('%25${formLot.searchInput
        .toString()
        .toLowerCase()}%25');id != '${lotDismember.id}';`,
      10
    );
  };

  handleChangeAge = (e, type, props) => {
    let newDate = null;
    switch (type) {
      case "days":
        newDate = moment().subtract(e.target.value, "days");
        break;
      case "months":
        newDate = moment().subtract(e.target.value, "months");
        break;
      case "years":
        newDate = moment().subtract(e.target.value, "years");
        break;
      default:
        break;
    }
    this.setState({
      formLot: {
        ...this.state.formLot,
        averageAnimalStay: moment().diff(newDate, "days"),
      },
    });
    props.setValues({
      ...props.values,
      averageAnimalStay: moment().diff(newDate, "days"),
    });
  };

  render() {
    const {
      app: {
        translation,
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      lot: { dataLot, drawerLotDismemberVisible, lotDismember },
    } = this.props;

    const {
      breeds,
      formLot,
      listOfProfitCenters: profitCenters,
      listOfPickets: pickets,
      loadingTable,
      error,
    } = this.state;

    const validationSchema = !formLot.existing
      ? Yup.object().shape({
          name: Yup.string().required(),
          profitCenterId: Yup.string().required(),
          referenceAcquisitionDate: Yup.string().required(),
          referenceAcquisitionWeight: Yup.string().required(),
          referenceBirthdayDate: Yup.string().required(),
          dismembermentDate: Yup.string().required(),
        })
      : Yup.object().shape({
          dismembermentDate: Yup.string().required(),
        });

    const dateFormat = "DD/MM/YYYY";

    const { Option } = Select;

    const { Search, Group: InputGroup } = Input;

    const { Column } = Table;

    const { selectedRowKeys } = formLot;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      type: "radio",
      style: { width: 10 },
    };

    return (
      <Container
        title={translation.lot.production.dismemberLotPage.drawer.title}
        width={770}
        onClose={this.closeDrawer}
        maskClosable={false}
        visible={drawerLotDismemberVisible}
        afterVisibleChange={this.handleDrawerVisible}
      >
        <Formik
          ref={this.formik}
          enableReinitialize={true}
          initialValues={dataLot != null ? dataLot : formLot}
          onSubmit={this.handleSubmitModalForm}
          validationSchema={validationSchema}
          render={(props) => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <div className="drawerForm">
                <Row type="flex">
                  <p>
                    {
                      translation.lot.production.dismemberLotPage.drawer
                        .description
                    }
                  </p>
                </Row>
                <Row type="flex">
                  <ButtonProperty
                    shape="round"
                    type="button"
                    className={!props.values.existing ? "selected" : ""}
                    onClick={() => this.handleSelectProperty(props, false)}
                  >
                    {
                      translation.lot.production.dismemberLotPage.drawer
                        .newButton
                    }
                  </ButtonProperty>
                  <ButtonProperty
                    shape="round"
                    type="button"
                    className={props.values.existing ? "selected" : ""}
                    onClick={() => {
                      this.handleSelectProperty(props, true);
                    }}
                  >
                    {
                      translation.lot.production.dismemberLotPage.drawer
                        .existingButton
                    }
                  </ButtonProperty>
                </Row>
                <CustomDivider dashed />
                {formLot !== null &&
                formLot.profitCenterId !== null &&
                lotDismember != null &&
                lotDismember.profitCenterId !== formLot.profitCenterId ? (
                  <Row type="flex" justify="space-between" align="middle">
                    <AlertCard>
                      <Icon
                        type="exclamation-circle"
                        theme="filled"
                        style={{ color: "#d44c4c" }}
                        className="iconAlert"
                      />
                      <span>
                        {
                          translation.lot.production.dismemberLotPage.drawer
                            .alertMessage
                        }
                      </span>
                    </AlertCard>
                  </Row>
                ) : null}
                {error !== "" ? (
                  <Row type="flex" justify="space-between" align="middle">
                    <AlertCard>
                      <Icon
                        type="exclamation-circle"
                        theme="filled"
                        style={{ color: "#d44c4c" }}
                        className="iconAlert"
                      />
                      <span>
                        {error === "referenceBirthdayDate" ? (
                          <>
                            A data de nascimento do animal não pode ser maior
                            que <br /> a data de aquisição
                          </>
                        ) : error === "dismembermentDate" ? (
                          <>
                            A data de desmembramento não pode ser menor que a
                            data <br /> de aquisição{" "}
                          </>
                        ) : null}
                      </span>
                    </AlertCard>
                  </Row>
                ) : null}
                {/* Error message */}
                {Object.entries(props.errors).length > 0 &&
                  props.submitCount > 0 && (
                    <Row type="flex" justify="center" align="middle">
                      <label className="error">
                        {translation.error.formError}
                      </label>
                    </Row>
                  )}
                {props.values.existing ? (
                  <>
                    {/* Input search */}
                    <Row type="flex" justify="space-between" align="middle">
                      <Search
                        name="searchInput"
                        value={props.values.searchInput}
                        placeholder={
                          translation.lot.production.dismemberLotPage.drawer
                            .searchInputPlaceHolder
                        }
                        onChange={(e) => {
                          props.setFieldValue("searchInput", e.target.value);
                          this.setState({
                            formLot: {
                              ...this.state.formLot,
                              searchInput: e.target.value,
                            },
                          });
                        }}
                        onPressEnter={this.handleSearchLot}
                        allowClear={true}
                      ></Search>
                    </Row>
                    {/* dismembermentDate */}
                    <Row type="flex" justify="start" gutter={4}>
                      <Col span={8}>
                        <Row>
                          <label
                            className={
                              (props.errors.dismembermentDate &&
                                props.submitCount > 0) ||
                              error === "dismembermentDate"
                                ? "error"
                                : ""
                            }
                          >
                            Data de desmembramento *
                          </label>
                        </Row>
                        <Row>
                          <DatePicker
                            name="dismembermentDate"
                            allowClear={false}
                            value={
                              props.values.dismembermentDate != null
                                ? moment(
                                    props.values.dismembermentDate,
                                    "YYYY-MM-DD"
                                  )
                                : null
                            }
                            placeholder={
                              translation.defaultDatePickerPlaceholder
                            }
                            format={dateFormat}
                            disabledDate={(current) =>
                              current.isAfter(moment())
                            }
                            onChange={(date, dateString) => {
                              if (date <= moment() && date !== null) {
                                props.setFieldValue("dismembermentDate", date);
                                this.setState({
                                  formLot: {
                                    ...this.state.formLot,
                                    dismembermentDate: date,
                                  },
                                });
                              }
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                    {/* Table */}
                    <Row
                      type="flex"
                      style={{ marginTop: 10 }}
                      justify="space-between"
                      align="middle"
                    >
                      <Table
                        rowKey="id"
                        size="small"
                        style={{ width: "100%" }}
                        scroll={{ x: true }}
                        dataSource={
                          formLot?.lotsData != null
                            ? formLot?.lotsData.content
                            : []
                        }
                        pagination={{
                          total:
                            formLot?.lotsData != null
                              ? formLot?.lotsData.totalElements
                              : 0,
                          size:
                            formLot?.lotsData != null
                              ? formLot?.lotsData.size
                              : 0,
                          current:
                            formLot?.lotsData != null
                              ? formLot?.lotsData.number + 1
                              : 0,
                          showSizeChanger: true,
                          pageSizeOptions: [
                            "10",
                            "20",
                            "30",
                            "100",
                            "500",
                            "1000",
                            "2000",
                          ],
                          hideOnSinglePage:
                            formLot?.lotsData !== null &&
                            Object.entries(formLot?.lotsData).length !== 0
                              ? formLot.lotsData?.totalElements > 10
                                ? false
                                : true
                              : true,
                        }}
                        onChange={this.handleTableChange}
                        rowSelection={rowSelection}
                        loading={loadingTable}
                      >
                        <Column
                          title={
                            translation.lot.production.table.columns.lotName
                          }
                          dataIndex="name"
                          key="name"
                          align="left"
                        />
                        <Column
                          title={
                            translation.lot.production.table.columns
                              .profitCenter
                          }
                          dataIndex="profitCenterName"
                          key="profitCenterName"
                          align="left"
                        />
                        <Column
                          title={
                            translation.lot.production.table.columns.amount
                          }
                          dataIndex="currentAmountAnimals"
                          key="currentAmountAnimals"
                          align="center"
                          render={(text, record) => (
                            <Async
                              promiseFn={getLotStatisticsAsync}
                              payload={{
                                groupId,
                                farmId,
                                id: record.id,
                                statistics: "AmountActiveAnimals",
                              }}
                            >
                              <Async.Pending>
                                <Icon type="loading" />
                              </Async.Pending>
                              <Async.Fulfilled>
                                {(resp) => <span>{resp.data.results}</span>}
                              </Async.Fulfilled>
                            </Async>
                          )}
                        />
                      </Table>
                    </Row>
                  </>
                ) : (
                  <>
                    {/* Create new lot */}
                    {/* Input lot identification */}
                    <Row
                      type="flex"
                      style={{ marginTop: "13px" }}
                      align="middle"
                    >
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row>
                          <label
                            className={
                              props.errors.name && props.submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            {translation.lot.production.drawer.form.name}
                          </label>
                        </Row>
                        <Row>
                          <Input
                            placeholder={
                              translation.lot.production.drawer.form.name
                            }
                            value={props.values.name}
                            name="name"
                            autoComplete="chrome-off"
                            onChange={(e) => {
                              props.setValues({
                                ...props.values,
                                name: e.target.value,
                              });
                              this.setState({
                                formLot: {
                                  ...formLot,
                                  name: e.target.value,
                                },
                              });
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                    {/* Input profitCenterId */}
                    <Row
                      type="flex"
                      style={{ marginTop: "13px" }}
                      align="middle"
                    >
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row>
                          <label
                            className={
                              props.errors.profitCenterId &&
                              props.submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            {
                              translation.lot.production.drawer.form
                                .profitCenter
                            }
                          </label>
                        </Row>
                        <Row>
                          <Select
                            name="profitCenterId"
                            value={
                              props.values.profitCenterId != null
                                ? props.values.profitCenterId
                                : undefined
                            }
                            disabled={props.values.lotId != null}
                            placeholder={
                              translation.lot.production.drawer.form
                                .profitCenter
                            }
                            onChange={(value) =>
                              this.handleSelectProfitCenter(value, props)
                            }
                            style={{ marginTop: 6 }}
                          >
                            {profitCenters != null &&
                            Object.entries(profitCenters).length > 0
                              ? profitCenters.map((pc) =>
                                  pc.productionSubSystem !== null &&
                                  pc.productionSubSystem !== "" ? (
                                    <Option key={pc.id} value={pc.id}>
                                      {pc.name}
                                    </Option>
                                  ) : null
                                )
                              : null}
                          </Select>
                        </Row>
                      </Col>
                    </Row>

                    <CustomDivider dashed />

                    {/* Input média de permanência dos animais na fazenda */}
                    <Row type="flex" justify="start">
                      <Col span={20}>
                        <Row>
                          <label>
                            Média de permanência dos animais na fazenda
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <InputGroup compact>
                            <Input
                              type="number"
                              style={{ width: 74, textAlign: "center" }}
                              name="ageDay"
                              min="0"
                              placeholder="Dias"
                              value={
                                props.values.averageAnimalStay != null
                                  ? props.values.averageAnimalStay
                                  : null
                              }
                              onChange={(e) => {
                                this.handleChangeAge(e, "days", props);
                              }}
                            />
                            <Input
                              style={{
                                width: 74,
                                textAlign: "center",
                                borderLeft: "1px solid #c4c4c4",
                              }}
                              type="number"
                              name="ageMonth"
                              min="0"
                              placeholder="Meses"
                              value={
                                props.values.averageAnimalStay != null
                                  ? Math.round(
                                      moment
                                        .duration(
                                          props.values.averageAnimalStay,
                                          "days"
                                        )
                                        .asMonths()
                                    )
                                  : null
                              }
                              onChange={(e) =>
                                this.handleChangeAge(e, "months", props)
                              }
                            />
                            <Input
                              style={{
                                width: 74,
                                textAlign: "center",
                                borderLeft: "1px solid #c4c4c4",
                              }}
                              type="number"
                              name="ageYear"
                              min="0"
                              placeholder="Anos"
                              value={
                                props.values.averageAnimalStay != null
                                  ? Math.round(
                                      moment
                                        .duration(
                                          props.values.averageAnimalStay,
                                          "days"
                                        )
                                        .asYears()
                                    )
                                  : null
                              }
                              onChange={(e) =>
                                this.handleChangeAge(e, "years", props)
                              }
                            />
                          </InputGroup>
                        </Row>
                      </Col>
                    </Row>

                    <CustomDivider dashed />

                    {/* Input picketId */}
                    <Row
                      type="flex"
                      style={{ marginTop: "13px" }}
                      align="middle"
                    >
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row>
                          <label>
                            {translation.lot.production.drawer.form.picket}
                          </label>
                          <p>
                            {
                              translation.lot.production.drawer.form
                                .picketMessage
                            }
                          </p>
                        </Row>
                        <Row>
                          <Select
                            name="picketId"
                            value={
                              props.values.picketId != null
                                ? props.values.picketId
                                : undefined
                            }
                            disabled={
                              props.values.profitCenterId == null ||
                              pickets == null ||
                              (pickets != null &&
                                Object.entries(pickets).length === 0)
                            }
                            placeholder={
                              translation.lot.production.drawer.form
                                .picketPlaceHolder
                            }
                            onChange={(value) => {
                              props.setValues({
                                ...props.values,
                                picketId: value,
                              });
                              this.setState({
                                formLot: {
                                  ...formLot,
                                  picketId: value,
                                },
                              });
                            }}
                          >
                            {pickets != null &&
                            Object.entries(pickets).length > 0
                              ? pickets.map((p) => (
                                  <Option key={p.id} value={p.id}>
                                    {p.name}
                                  </Option>
                                ))
                              : null}
                          </Select>
                        </Row>
                      </Col>
                    </Row>

                    <InfoCardDashed>
                      <NoteIcon />
                      As informações referentes à aquisição do lote como data de
                      nascimento, peso médio dos animais, data de aquisição,
                      serão mantidas do lote originário.
                    </InfoCardDashed>

                    {/* Input Peso assintótico */}
                    <Row type="flex" justify="start">
                      <Col span={12}>
                        <Row>
                          <label>
                            {
                              translation.lot.production.drawer.form
                                .asymptoticWeight
                            }
                            <Tooltip
                              title="Entende-se Peso assintótico, sendo o ideal para abate do boi.
  O peso assintótico é definido pela raça de animais adicionados ao lote e pode ser alterado caso exija necessidade"
                            >
                              <InfoIcon />
                            </Tooltip>
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <NumberFormat
                            customInput={Input}
                            value={props.values.asymptoticWeight}
                            placeholder={
                              translation.lot.production.drawer.form
                                .informPlaceHolder
                            }
                            name="asymptoticWeight"
                            addonAfter="Kg"
                            decimalScale={"2"}
                            onValueChange={({ floatValue }) => {
                              props.setFieldValue(
                                "asymptoticWeight",
                                floatValue
                              );
                              this.setState({
                                formLot: {
                                  ...formLot,
                                  asymptoticWeight: floatValue,
                                },
                              });
                            }}
                          />
                        </Row>
                      </Col>
                      {/* Input  Carcass Harnessing  */}
                      <Col span={11} offset={1}>
                        <Row>
                          <label>
                            {
                              translation.lot.production.drawer.form
                                .carcassHarnessing
                            }
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <NumberFormat
                            customInput={Input}
                            value={props.values.carcassHarnessing}
                            placeholder={
                              translation.lot.production.drawer.form
                                .informPlaceHolder
                            }
                            name="carcassHarnessing"
                            addonAfter="%"
                            decimalScale={"2"}
                            onValueChange={({ floatValue }) => {
                              props.setFieldValue(
                                "carcassHarnessing",
                                floatValue
                              );
                              this.setState({
                                formLot: {
                                  ...formLot,
                                  carcassHarnessing: floatValue,
                                },
                              });
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                    <CustomDivider dashed>
                      DADOS DE REFERÊNCIA: AQUISIÇÃO DO LOTE
                    </CustomDivider>
                    {/* Input Raça, Gender and Cap */}
                    <Row type="flex" justify="start" gutter={4}>
                      {/* Breed */}
                      <Col span={props.values?.gender === "Male" ? 12 : 16}>
                        <Row>
                          <label
                            className={
                              props.errors.breedId && props.submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            Raça*
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <Select
                            style={{ width: "100%" }}
                            name="breedId"
                            placeholder={
                              translation.animal.edit.form.breedPlaceHolder
                            }
                            value={
                              breeds != null && props.values?.breedId != null
                                ? props.values?.breedId
                                : undefined
                            }
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) => {
                              props.setValues({
                                ...props.values,
                                breedId: value,
                              });
                              this.setState({
                                formLot: {
                                  ...formLot,
                                  breedId: value,
                                },
                              });
                            }}
                          >
                            {breeds != null &&
                              Object.entries(breeds).length > 0 &&
                              breeds.map((breed) => (
                                <Option key={breed.id} value={breed.id}>
                                  {breed.name}
                                </Option>
                              ))}
                          </Select>
                        </Row>
                      </Col>
                      {/* Gender */}
                      <Col span={props.values?.gender === "Male" ? 6 : 8}>
                        <Row>
                          <label
                            className={
                              props.errors.gender && props.submitCount > 0
                                ? "formLabel error"
                                : "formLabel"
                            }
                          >
                            {translation.animal.edit.form.sex} *
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <Select
                            style={{ width: "100%" }}
                            name="gender"
                            value={
                              props.values?.gender != null
                                ? props.values?.gender
                                : undefined
                            }
                            placeholder={translation.defaultSelectPlaceholder}
                            onChange={(value) => {
                              props.setValues({
                                ...props.values,
                                gender: value,
                              });
                              this.setState({
                                formLot: {
                                  ...formLot,
                                  gender: value,
                                },
                              });
                            }}
                          >
                            <Option key="Female" value="Female">
                              {translation.animal.edit.form.female}
                            </Option>
                            <Option key="Male" value="Male">
                              {translation.animal.edit.form.male}
                            </Option>
                          </Select>
                        </Row>
                      </Col>
                      {/* Cap */}
                      {props.values?.gender === "Male" ? (
                        <Col span={6}>
                          <Row>
                            <label className="formLabel">
                              {translation.animal.edit.form.cap}
                            </label>
                          </Row>
                          <Row className="rowInput">
                            <RadioGroup
                              name="cap"
                              value={props.values?.cap}
                              defaultValue={false}
                              onChange={(e) => {
                                props.setValues({
                                  ...props.values,
                                  cap: e.target.value,
                                });
                                this.setState({
                                  formLot: {
                                    ...formLot,
                                    cap: e.target.value,
                                  },
                                });
                              }}
                            >
                              <RadioButton value={true}>
                                {translation.buttons.yes}
                              </RadioButton>
                              <RadioButton value={false}>
                                {translation.buttons.no}
                              </RadioButton>
                            </RadioGroup>
                          </Row>
                        </Col>
                      ) : null}
                    </Row>
                    {/* Input data de aquisição e Nascimento animal, Input data de desmembramento de lote */}
                    <Row type="flex" justify="start" gutter={4}>
                      <Col span={8}>
                        <Row>
                          <label
                            className={
                              props.errors.referenceAcquisitionDate &&
                              props.submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            Data de aquisição *
                          </label>
                        </Row>
                        <Row>
                          <DatePicker
                            name="referenceAcquisitionDate"
                            allowClear={false}
                            value={
                              props.values.referenceAcquisitionDate != null
                                ? moment(
                                    props.values.referenceAcquisitionDate,
                                    "YYYY-MM-DD"
                                  )
                                : null
                            }
                            placeholder={
                              translation.defaultDatePickerPlaceholder
                            }
                            format={dateFormat}
                            onChange={(date, dateString) => {
                              if (date <= moment() && date !== null) {
                                props.setValues({
                                  ...props.values,
                                  referenceAcquisitionDate: date,
                                });
                                this.setState({
                                  formLot: {
                                    ...formLot,
                                    referenceAcquisitionDate: date,
                                  },
                                });
                              }
                            }}
                          />
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <label
                            className={
                              (props.errors.referenceBirthdayDate &&
                                props.submitCount > 0) ||
                              error === "referenceBirthdayDate"
                                ? "error"
                                : ""
                            }
                          >
                            Nascimento animal *
                          </label>
                        </Row>
                        <Row>
                          <DatePicker
                            name="referenceBirthdayDate"
                            allowClear={false}
                            value={
                              props.values.referenceBirthdayDate != null
                                ? moment(
                                    props.values.referenceBirthdayDate,
                                    "YYYY-MM-DD"
                                  )
                                : null
                            }
                            placeholder={
                              translation.defaultDatePickerPlaceholder
                            }
                            format={dateFormat}
                            onChange={(date, dateString) => {
                              if (date <= moment() && date !== null) {
                                props.setValues({
                                  ...props.values,
                                  referenceBirthdayDate: date,
                                });
                                this.setState({
                                  formLot: {
                                    ...formLot,
                                    referenceBirthdayDate: date,
                                  },
                                });
                              }
                            }}
                          />
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <label
                            className={
                              (props.errors.dismembermentDate &&
                                props.submitCount > 0) ||
                              error === "dismembermentDate"
                                ? "error"
                                : ""
                            }
                          >
                            Data de desmembramento *
                          </label>
                        </Row>
                        <Row>
                          <DatePicker
                            name="dismembermentDate"
                            allowClear={false}
                            value={
                              props.values.dismembermentDate != null
                                ? moment(
                                    props.values.dismembermentDate,
                                    "YYYY-MM-DD"
                                  )
                                : null
                            }
                            placeholder={
                              translation.defaultDatePickerPlaceholder
                            }
                            disabledDate={(current) =>
                              current.isAfter(moment())
                            }
                            format={dateFormat}
                            onChange={(date, dateString) => {
                              if (date <= moment() && date !== null) {
                                props.setFieldValue("dismembermentDate", date);
                                this.setState({
                                  formLot: {
                                    ...formLot,
                                    dismembermentDate: date,
                                  },
                                });
                              }
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                    {/* Input peso médio por animal */}
                    <Row type="flex" justify="start">
                      <Col span={12}>
                        <Row>
                          <label
                            className={
                              props.errors.referenceAcquisitionWeight &&
                              props.submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            Peso médio por animal *
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <NumberFormat
                            customInput={Input}
                            value={props.values.referenceAcquisitionWeight}
                            placeholder={
                              translation.lot.production.drawer.form
                                .informPlaceHolder
                            }
                            name="referenceAcquisitionWeight"
                            addonAfter="Kg"
                            decimalScale={"2"}
                            onValueChange={({ floatValue }) => {
                              props.setFieldValue(
                                "referenceAcquisitionWeight",
                                floatValue
                              );
                              this.setState({
                                formLot: {
                                  ...formLot,
                                  referenceAcquisitionWeight: floatValue,
                                },
                              });
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
              {/* Footer */}
              <Footer>
                <Row type="flex" justify="space-between">
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    className="buttonsDiv"
                  >
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={this.closeDrawer}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard type="submit" buttonType="type6">
                      {translation.buttons.confirm}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  lot: state.lot,
});

const mapDispatchToProps = (dispatch) => ({
  lotActions: bindActionCreators(LotActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DrawerLotDismember)
);
