import styled from "styled-components";

export const CardSupplementSupplyPerDietDashboardTableContainer = styled.div`
  width: 100%;
  max-height: 300px;

  overflow-y: auto;
  overflow-x: hidden;

  table {
    width: 100%;
    border-collapse: collapse;

    th {
      span {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          bottom: -0.5rem;
          left: 0;
          width: 100%;
          height: 2px;
          background: #684e94;
        }
      }
    }

    th,
    td {
      text-align: left;
      padding: 0.75rem 1rem;
    }

    tbody {
      tr:nth-child(even) {
        background-color: #e1e1e6;
      }

      td:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      td:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
`;
