import { Col, Icon, Row, Select, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DrawerFinancialCashFlowBudget from "../../../../components/drawers/financialCashFlowBudget";
import ButtonStandard from "../../../../components/utils/button";
import { useFinancial } from "../../../../hooks/useFinancialReducer";
import { findAll } from "../../../../services/financialProjectService";
import FinancialCashFlowExpense from "./expense";
import FinancialCashFlowIncome from "./income";
import StackedColumnBarChart from "./stackedColumnBarChart";
import { CardCustom, Title } from "./styles";
import SelectCurrency from "../../../../components/utils/selectCurrency";
import { useCurrencyContext } from "../../../../hooks/useCurrencyContext";

// Services

const FinancialCashFlow = () => {
  const [tabSelected, setTabSelected] = useState("0");
  const [listFinancialProjects, setListFinancialProjects] = useState([]);
  const [isLoadingFinancialProjects, setIsLoadingFinancialProjects] =
    useState(false);
  const [selectedFinancialProject, setSelectedFinancialProject] =
    useState(null);
  // Redux
  const {
    refreshListFinancialCashFlow,
    openOrCloseDrawerFinancialCashFlowBudget,
    isLoadingListFinancialCashFlow: isLoading,
  } = useFinancial();
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const { defaultCurrencyCode } = useCurrencyContext();

  // Effects
  // Memo
  // Methods
  const handleTabClick = (key, e) => {
    setTabSelected(key);
  };

  useEffect(() => {
    async function fetchProjects() {
      setIsLoadingFinancialProjects(true);
      try {
        const {
          data: { results },
        } = await findAll({
          groupId,
          farmId,
        });
        setListFinancialProjects(results);
      } catch (error) {
      } finally {
        setIsLoadingFinancialProjects(false);
      }
    }
    fetchProjects();
  }, [farmId, groupId]);

  return (
    <CardCustom>
      <Row type="flex" className="rowHeader">
        <Col span={4}>
          <Title>{translation.financial.cashFlow.title}</Title>
        </Col>
        <Col span={20}>
          <Row type="flex" justify="end" align="middle" gutter={8}>
            <Col>
              <ButtonStandard
                buttonType="typeWithoutBackground"
                disabled={isLoading}
                onClick={() =>
                  refreshListFinancialCashFlow(selectedFinancialProject)
                }
              >
                <Icon type="reload" />
              </ButtonStandard>
            </Col>
            <Col>
              <Row>
                <label className="filterLabel">Moeda padrão</label>
              </Row>
              <Row>
                <SelectCurrency value={defaultCurrencyCode} disabled />
              </Row>
            </Col>
            <Col span={8}>
              <Row>
                <label className="filterLabel">
                  {translation.financial.costing.filters.financialProjectId}
                </label>
              </Row>
              <Row>
                <Select
                  style={{ width: "100%" }}
                  name="selectedFinancialProject"
                  value={selectedFinancialProject || undefined}
                  placeholder={translation.defaultSelectPlaceholder}
                  loading={isLoadingFinancialProjects}
                  allowClear
                  onDeselect={() => {
                    setSelectedFinancialProject(null);
                    refreshListFinancialCashFlow(null);
                  }}
                  onChange={(value) => {
                    setSelectedFinancialProject(value);
                    refreshListFinancialCashFlow(value);
                  }}
                >
                  {listFinancialProjects.map((l) => (
                    <Select.Option key={l.id} value={l.id}>
                      {l.name}
                    </Select.Option>
                  ))}
                </Select>
              </Row>
            </Col>
            <Col>
              <ButtonStandard
                name="buttonCreateBudget"
                buttonType="type8"
                onClick={openOrCloseDrawerFinancialCashFlowBudget}
              >
                {translation.financial.cashFlow.buttonCreateBudget}
              </ButtonStandard>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <StackedColumnBarChart />
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          <Tabs
            type="card"
            defaultActiveKey="0"
            activeKey={tabSelected}
            onTabClick={handleTabClick}
          >
            <Tabs.TabPane tab={<span>Despesas</span>} key="0">
              <FinancialCashFlowExpense />
            </Tabs.TabPane>
            <Tabs.TabPane tab={<span>Receitas</span>} key="1">
              <FinancialCashFlowIncome />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
      <DrawerFinancialCashFlowBudget />
    </CardCustom>
  );
};

export default FinancialCashFlow;
