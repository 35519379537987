import { call, put } from "redux-saga/effects";
import { notification } from "antd";
import { Creators as LotReceiptActions } from "../ducks/lotReceipt";
import { Creators as LotActions } from "../ducks/lot";
import {
  getLotReceiptItemService,
  saveLotItemEntryService,
  saveNewLotReceipt,
} from "../../services/lotReceiptService";

export function* getLotReceiptItem(action) {
  try {
    let {
      data: { results: lot },
    } = yield call(getLotReceiptItemService, action.payload);
    yield put(LotReceiptActions.getDetailsLotReceiptItemSuccess(lot));
  } catch (error) {
    yield put(LotReceiptActions.getDetailsLotReceiptItemError(error));
  }
}

export function* storeLotReceipt(action) {
  try {
    const {
      data: { results: newLot },
    } = yield call(saveNewLotReceipt, action.payload);
    notification.success({
      message: "Cadastro realizado com sucesso",
      description: "Lote de recebimento criado com sucesso",
    });
    yield put(
      LotActions.storeNewLotReceiptSuccess(newLot, action.payload.saveOption)
    );
  } catch (error) {
    notification.error({
      message: "Cadastro não realizado",
      description: "Lote de recebimento não foi criado",
    });
    yield put(LotActions.storeNewLotReceiptError(error));
  }
}

export function* saveOrUpdateLotItemEntry(action) {
  try {
    const { groupId, farmId, lotId, lotItemId } = action.payload;

    yield call(saveLotItemEntryService, action.payload);

    yield put(LotReceiptActions.saveLotItemEntrySuccess());
    yield put(
      LotReceiptActions.getDetailsLotReceiptItem(
        groupId,
        farmId,
        lotId,
        lotItemId
      )
    );
    notification.success({
      message:
        "Entrada de animais cadastradas com sucesso! Os animais serão inseridos no sistema de forma gradativa.",
    });
  } catch (error) {
    notification.error({
      message:
        "Houve um erro ao tentar salvar entrada de animais. Contate o suporte.",
    });
    yield put(LotReceiptActions.saveLotItemEntryError());
  }
}
