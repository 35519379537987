import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

import { Col, Row } from "antd";
import InfoTooltip from "../../../../../components/utils/infoTooltip";
import { TabAnimalPaneContent } from "../../styles";

import { numberMask } from "../../../../../services/helpersMethodsService";

function TabDefaultInfo({
  tabAnimalPane,
  translation,
  lotCarcassHarnessing,
  dietStrategyCarcassHarnessing,
  accumulatedGMD,
  avgGMD,
  animalData,
  tabFarmFunction,
}) {
  const {
    animal: {},
    app: {},
  } = useSelector((state) => state);

  return (
    <TabAnimalPaneContent active={tabAnimalPane === 1 ? true : false}>
      {/* Card with assytoptic weight */}
      <Row type="flex">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="cardBovexo withBorder"
        >
          <Row type="flex" style={{ marginBottom: 10 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} align="left">
              <Row>
                <span className="span14Bold">
                  Aproveitamento de carcaça
                  <InfoTooltip
                    title={translation.animal.carcassHarnessingInfoTooltip}
                  />
                </span>
              </Row>
            </Col>
          </Row>
          <Row type="flex">
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <Row>
                <span className="span14Bold">Animal</span>
              </Row>
              <Row>
                <span className="span16Regular">
                  {animalData?.carcassHarnessing != null
                    ? `${numberMask(animalData?.carcassHarnessing, false)} %`
                    : `-`}
                </span>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} offset={2}>
              <Row>
                <span className="span14Bold">Lote</span>
              </Row>
              <Row>
                {lotCarcassHarnessing === null ? (
                  <span className="span16Regular">-</span>
                ) : (
                  <span className="span16Regular">
                    {`${numberMask(lotCarcassHarnessing, false)} %`}
                  </span>
                )}
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Row>
                <span className="span14Bold">Estratégia Dieta</span>
              </Row>
              <Row>
                {dietStrategyCarcassHarnessing === null ? (
                  <span className="span16Regular">-</span>
                ) : (
                  <span className="span16Regular">
                    {`${numberMask(dietStrategyCarcassHarnessing, false)} %`}
                  </span>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Card with weight gain */}
      <Row type="flex">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="cardBovexo withBorder"
        >
          <Row type="flex" justify="start" gutter={4}>
            <Col span={7}>
              <Row>
                <span className="span14Bold">
                  GDP médio{" "}
                  <InfoTooltip title="Está sendo contabilizado também os ganhos de pesos estimados até a data atual." />
                </span>
              </Row>
              <Row>
                <span className="span16Regular">
                  {numberMask(avgGMD || 0, false)} Kg
                </span>
              </Row>
            </Col>
            <Col span={9}>
              <Row>
                <span className="span14Bold">
                  GDP Acumulado{" "}
                  <InfoTooltip title="Está sendo contabilizado também os ganhos de pesos estimados até a data atual." />
                </span>
              </Row>
              <Row>
                <span className="span16Regular">
                  {numberMask(accumulatedGMD || 0, false)} Kg
                </span>
              </Row>
            </Col>
            <Col span={8}>
              <Row>
                <span className="span14Bold">Último peso real</span>
              </Row>
              <Row>
                <span className="span16Regular">
                  {animalData?.currentWeight != null
                    ? `${numberMask(animalData?.currentWeight || 0, false)} Kg`
                    : `0 Kg`}
                </span>
              </Row>
            </Col>
          </Row>
          <Row type="flex" justify="start" gutter={4} style={{ marginTop: 10 }}>
            {/* Peso assintótico */}
            <Col span={8}>
              <Row>
                <span className="span14Bold">
                  {`${translation.animal.details.asymptoticWeight}${
                    animalData?.customAsymptoticWeight === false ? "*" : ""
                  }`}
                </span>
              </Row>
              <Row type="flex" justify="space-between">
                <span className="span16Regular">
                  {animalData?.asymptoticWeight != null
                    ? `${numberMask(
                        animalData?.asymptoticWeight || 0,
                        false
                      )} Kg`
                    : `0 Kg`}
                </span>
              </Row>
              <Row>
                {animalData != null &&
                animalData?.customAsymptoticWeight === false ? (
                  <span className="span12Orange">* do Lote</span>
                ) : null}
              </Row>
            </Col>
            {/* Peso de aquisição  */}
            <Col span={8}>
              <Row>
                <span className="span14Bold">Peso de Aquisição</span>
              </Row>
              <Row>
                <span className="span16Regular">
                  {animalData?.acquisitionEstimatedWeight != null
                    ? `${numberMask(
                        animalData?.acquisitionEstimatedWeight || 0,
                        false
                      )} Kg`
                    : `0 Kg`}
                </span>
              </Row>
            </Col>
            {/* Data da última pesagem */}
            <Col span={8}>
              <Row>
                <span className="span14Bold">Data do último peso real</span>
              </Row>
              <Row>
                <span className="span16Regular">
                  {animalData?.currentWeightDate != null
                    ? moment(animalData?.currentWeightDate).format("DD/MM/YYYY")
                    : ` - `}
                </span>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </TabAnimalPaneContent>
  );
}

export default TabDefaultInfo;
