import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as DietStrategyActions } from "../../../store/ducks/dietStrategy";
import { Row, Icon } from "antd";
import moment from "moment";

import { Container, CardShadow, CustomDivider, CardDiet } from "./styles";
// Components
import DrawerDisplayPurchaseScenarioPeriod from "./purchaseScenarioPeriod";
import RainIcon from "../../../pages/generalParameter/weatherSeasonsParameter/display/icons/rain";
import SunIcon from "../../../pages/generalParameter/weatherSeasonsParameter/display/icons/sun";
import TransitionIcon from "../../../pages/generalParameter/weatherSeasonsParameter/display/icons/transition";
import TagStatus from "../../../components/utils/tagStatus";
import ButtonStandard from "../../utils/button";
// Services
import { getMarketAnalysisDropDown } from "../../../services/marketAnalysisService";
import { getDietStrategyIndex } from "../../../services/dietStrategyService";
import { getBreedIndexActive } from "../../../services/breedService";

const DrawerDisplayPurchaseScenario = ({ data, drawerVisible, onCancel }) => {
  const [allDietStrategies, setAllDietStrategies] = useState([]);
  const [allBreeds, setAllBreeds] = useState([]);
  const [listMarketAnalysis, setListMarketAnalysis] = useState([]);
  const [purchaseScenario, setPurchaseScenario] = useState({
    id: null,
    name: null,
    marginProfit: 0,
    breedId: null,
    breedName: null,
    asymptoticWeight: null,
    cap: false,
    gender: "Male",
    marketAnalysisId: null,
    dietStrategyId: null,
    createNewDietStrategy: false,
    initialSimulationDate: moment().day(1),
    productionSubSystem: null,
    status: "Analyzing",
  });
  const [purchaseScenarioDietPeriod, setPurchaseScenarioDietPeriod] =
    useState(null);
  const [drawerScenarioPeriodVisible, setDrawerScenarioPeriodVisible] =
    useState(false);
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  // Get DietStrategies and Market Analysis
  useEffect(() => {
    async function getBreeds() {
      try {
        const {
          data: { results: breeds },
        } = await getBreedIndexActive({
          groupId,
          farmId,
          withoutBreedCurveParam: false,
        });
        setAllBreeds(breeds);
      } catch (error) {}
    }
    async function getDietStrategies() {
      try {
        const {
          data: { results: allDietStrategies },
        } = await getDietStrategyIndex({
          groupId,
          farmId,
          withoutPagination: true,
        });
        const filteredDS = allDietStrategies.filter(
          (e) => e.status === "Active" && e.generic === true
        );
        setAllDietStrategies(filteredDS);
      } catch (error) {}
    }
    async function fetchMarketAnalysis() {
      try {
        const {
          data: { results },
        } = await getMarketAnalysisDropDown({ groupId, farmId });
        setListMarketAnalysis(results);
      } catch (error) {
        setListMarketAnalysis([]);
      }
    }
    if (groupId != null && farmId != null) {
      getBreeds();
      fetchMarketAnalysis();
      getDietStrategies();
    }
  }, [groupId, farmId]);

  useEffect(() => {
    setPurchaseScenario(data);
    return () => {};
  }, [data]);

  function handleOpenPeriodDrawer(period) {
    setPurchaseScenarioDietPeriod(period);
    setDrawerScenarioPeriodVisible(true);
  }

  function handleClosePeriodDrawer() {
    setDrawerScenarioPeriodVisible(false);
  }

  function handleShowDietStrategyDetails() {
    if (purchaseScenario?.dietStrategyId)
      dispatch(
        DietStrategyActions.showDietStrategy(
          groupId,
          farmId,
          purchaseScenario?.dietStrategyId
        )
      );
  }

  function compareStartDay(a, b) {
    let comparison = 0;
    if (a.startDay > b.startDay) {
      comparison = 1;
    } else if (a.startDay < b.startDay) {
      comparison = -1;
    }
    return comparison;
  }

  return purchaseScenario != null ? (
    <Container
      title={purchaseScenario.name}
      width={550}
      visible={drawerVisible}
      onClose={onCancel}
      maskClosable={false}
    >
      <div className="drawerForm">
        <div className="info-box">
          <div>
            <p>Validade</p>
            <b>
              {moment(purchaseScenario.expirationDate, "YYYY-MM-DD").format(
                "DD/MM/YYYY"
              )}
            </b>
          </div>
          {purchaseScenario.status === "Analyzing" ? (
            <TagStatus
              className="status"
              background="#FFF6EE"
              borderColor="#FE8D2A"
              color="#FE8D2A"
            >
              Analizando
            </TagStatus>
          ) : purchaseScenario.status === "Processed" ? (
            <TagStatus
              className="status"
              background="#EBF7FF"
              borderColor="#4A85AE"
              color="#4A85AE"
            >
              Processado
            </TagStatus>
          ) : purchaseScenario.status === "Error" ? (
            <Icon
              type="exclamation-circle"
              className="iconError"
              theme="filled"
            />
          ) : purchaseScenario.status === "Expired" ? (
            <TagStatus
              className="status"
              background="#FBC7C7"
              borderColor="#D44C4C"
              color="#D44C4C"
            >
              Vencido
            </TagStatus>
          ) : purchaseScenario.status === "Canceled" ? (
            <TagStatus
              className="status"
              background="#F5F5F5"
              borderColor="#A5A5A5"
              color="#A5A5A5"
            >
              Cancelado
            </TagStatus>
          ) : (
            <TagStatus
              className="status"
              background="#FBC7C7"
              borderColor="#D44C4C"
              color="#D44C4C"
            >
              {translation.status.inactive}
            </TagStatus>
          )}
        </div>
        <CardShadow>
          <span className="title">Parâmetros da Raça</span>
          <Row type="flex" justify="space-between" align="middle">
            <p>Raça:</p>
            <b>
              {allBreeds.find((b) => b.id === purchaseScenario.breedId)?.name ||
                " - "}
            </b>
          </Row>
          <Row type="flex" justify="space-between" align="middle">
            <p>Gênero:</p>
            <b>{purchaseScenario.gender === "Male" ? "Macho" : "Fêmea"}</b>
          </Row>
          {purchaseScenario.gender === "Male" && (
            <Row type="flex" justify="space-between" align="middle">
              <p>Capado:</p>
              <b>{purchaseScenario.cap ? "Sim" : "Não"}</b>
            </Row>
          )}
          <Row type="flex" justify="space-between" align="middle">
            <p>Peso assintótico:</p>
            <b>{purchaseScenario?.asymptoticWeight?.toFixed(2) || 0} kg</b>
          </Row>
        </CardShadow>
        <CardShadow>
          <span className="title">Parâmetros de venda</span>
          <Row type="flex" justify="space-between" align="middle">
            <p>Margem de lucro:</p>
            <b>{purchaseScenario?.marginProfit?.toFixed(2) || 0} %</b>
          </Row>
          <Row type="flex" justify="space-between" align="middle">
            <p>Análise de Mercado:</p>
            <b>
              {listMarketAnalysis.find(
                (ma) => ma.id === purchaseScenario?.marketAnalysisId
              )?.name || " - "}
            </b>
          </Row>
          <Row type="flex" justify="space-between" align="middle">
            <p> Restrições de Peso de Venda:</p>
            <b>Mínimo {purchaseScenario?.minWeight?.toFixed(2) || 0} -
               Máximo {purchaseScenario?.maxWeight?.toFixed(2) || 0}</b>
          </Row>
          <Row type="flex" justify="space-between" align="middle">
            <p> Comissão:</p>
            <b>{purchaseScenario?.commission?.toFixed(2) || 0} {purchaseScenario?.commissionUnit === "PERCENTAGE" ? "%" : "R$/Cab" || "%"}</b>
          </Row>
          <Row type="flex" justify="space-between" align="middle">
            <p> Frete:</p>
            <b>{purchaseScenario?.freight?.toFixed(2) || 0}</b>
          </Row>
          <Row type="flex" justify="space-between" align="middle">
            <p>Respeitar capacidade de suporte:</p>
            <b>{purchaseScenario?.respectCapacity === false ? "Não" : "Sim"}</b>
          </Row>
        </CardShadow>

        <CustomDivider dashed>ESTRATÉGIA DE DIETA</CustomDivider>

        {purchaseScenario?.createNewDietStrategy ? (
          <Row type="flex">
            {purchaseScenario.dietPeriods
              .sort(compareStartDay)
              .map((period) => (
                <CardDiet
                  key={period.id}
                  onClick={() => handleOpenPeriodDrawer(period)}
                >
                  <Row
                    type="flex"
                    justify="space-between"
                    style={{
                      paddingLeft: 3,
                      paddingRight: 3,
                      paddingTop: 5,
                      paddingBottom: 5,
                    }}
                  >
                    {period.season
                      .split(",")
                      .map((season) =>
                        season === "Water" ? (
                          <RainIcon key={Math.random()} />
                        ) : season === "Transition" ? (
                          <TransitionIcon key={Math.random()} />
                        ) : season === "Dry" ? (
                          <SunIcon key={Math.random()} />
                        ) : null
                      )}
                  </Row>
                  <p>
                    {moment(period.startDate, "YYYY-MM-DD").format("DD/MM/YY")}
                  </p>
                  <p>
                    {moment(period.endDate, "YYYY-MM-DD").format("DD/MM/YY")}
                  </p>
                </CardDiet>
              ))}
          </Row>
        ) : (
          <Row type="flex">
            <Row type="flex" justify="space-between" align="middle">
              <p>Estratégia de Dieta: </p>
              <b>
                {allDietStrategies.find(
                  (ma) => ma.id === purchaseScenario?.dietStrategyId
                )?.name || " - "}
              </b>
            </Row>
            <Row
              type="flex"
              style={{ marginTop: "13px" }}
              justify="center"
              align="middle"
            >
              <ButtonStandard
                buttonType="typeWithoutBackground"
                color="#684E94"
                type="button"
                isUpperCase={true}
                onClick={handleShowDietStrategyDetails}
                disabled={purchaseScenario?.dietStrategyId === null}
              >
                VISUALIZAR DETALHES SOBRE A ESTRATÉGIA DE DIETA
              </ButtonStandard>
            </Row>
          </Row>
        )}
      </div>

      <DrawerDisplayPurchaseScenarioPeriod
        drawerVisible={drawerScenarioPeriodVisible}
        data={purchaseScenarioDietPeriod}
        onCancel={handleClosePeriodDrawer}
      />
    </Container>
  ) : null;
};

export default DrawerDisplayPurchaseScenario;
