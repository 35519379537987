import commons from '../config/api_commons';

export function getCityStateCountryInfo() {
  return commons.get('/bovexo/cities/info');
}

export function getCitiesByState(stateId) {
  return commons.get(`/bovexo/states/${stateId}/cities`);
}

export function getStatesInfo() {
  return commons.get('/bovexo/states');
}