import styled from "styled-components";
import { Drawer } from "antd";

export const Container = styled(Drawer)`
  .ant-drawer-body {
    padding: 24px 0px 10px 0px;
  }

  .drawerForm {
    width: 100%;
    padding: 0px 24px;
    min-height: -moz-calc(100vh - 165px);
    min-height: -webkit-calc(100vh - 165px);
    min-height: calc(100vh - 165px);
  }

  .rowLabel {
    margin-bottom: 5px;
    margin-top: 10px;
  }

  span.subTitle {
    font-size: 16px;
    color: #684e94;
  }

  span.bold {
    font-size: 16px;
    color: #4b4b4b;
    font-weight: bold;
  }

  span.farmMessage {
    font-family: Asap;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    color: #4b4b4b;
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    &.error {
      color: #d44c4c;
      margin-left: 15px;
    }
  }

  .ant-select {
    width: 100%;
  }
`;

export const BoxStatus = styled.div`
  height: 28px;
  width: 57px;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 5px;
  border-radius: 6px;
  font-family: Asap;
  font-weight: normal;
  font-size: 12px;
  text-align: center;

  &.active {
    margin-left: 70px;
    background: #c5f1ca;
    border: 1px solid #106518;

    color: #106518;
  }

  &.inactive {
    margin-left: 70px;
    background: #ffd8d8;
    border: 1px solid #d44c4c;

    color: #d44c4c;
  }

  &.bovexo {
    margin-left: 70px;
    background: #f6ffc8;
    border: 1px solid #a9c133;

    color: #a9c133;
  }
`;

export const CardDetail = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  margin: 10px 0px;
  padding: 15px 15px 15px 12px;
  background: #fcfcfc;

  .ant-row,
  .ant-row-flex {
    width: 100%;
  }

  .alingItensCenter {
    align-items: center !important;
  }

  div.contentGroup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    font-family: Asap;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;

    strong,
    span {
      margin-bottom: 10px;
    }

    span.title-grey {
      font-size: 18px;
      color: #c4c4c4;
      font-weight: bold;
    }
  }

  span.document {
    font-size: 18px;
    color: #c4c4c4;
    font-weight: bold;
  }

  div.contentRow {
    display: flex;
    justify-content: flex-start;

    font-family: Asap;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;

    strong,
    span.title {
      margin-left: 10px;
      font-size: 14px;
      color: #222;
    }

    div.svgCustom {
      svg {
        width: 24px !important;
        height: 24px !important;
        fill: #c4c4c4 !important;
      }
    }
  }

  span.title-card {
    margin-left: 35px;
    font-size: 14px;
    color: #c4c4c4;
    font-weight: bold;
  }

  div.contentRowFlex {
    display: flex;
    justify-content: space-between;
    font-family: Asap;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;

    strong,
    span.title {
      margin-left: 10px;
      font-size: 14px;
      color: #222;
    }

    span.title-margin {
      margin-left: 35px;
      font-size: 14px;
      color: #222;
    }
  }
  .margin-top {
    margin-top: 5px;
  }
`;
