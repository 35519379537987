export default function getHeaders(
  multipart = false,
  signal = null,
  download = false
) {
  if (download === true) {
    const headers = {
      cancelToken: signal ? signal.token : null,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@Bovexo:token")}`,
      },
      responseType: "blob",
    };
    return headers;
  }
  if (multipart) {
    const headers = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("@Bovexo:token")}`,
      },
    };
    return headers;
  } else {
    const headers = {
      cancelToken: signal ? signal.token : null,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("@Bovexo:token")}`,
      },
    };
    return headers;
  }
}
