import { Statistic } from "antd";
import React from "react";
import useLotDetailsSupplementSupplyContext from "../../../../../../../hooks/useLotDetailsSupplementSupplyContext";
import { numberMask } from "../../../../../../../services/helpersMethodsService";

// import { Container } from './styles';

function CardAnimalCostPerDaySupplementSupplyDashboard() {
  const { averageAnimalSupplyCost } = useLotDetailsSupplementSupplyContext();
  return (
    <Statistic
      title="Custo apropriado"
      value={numberMask(averageAnimalSupplyCost, true)}
      precision={2}
      suffix={"Cab/dia"}
    />
  );
}

export default CardAnimalCostPerDaySupplementSupplyDashboard;
