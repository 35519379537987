import {
  Button,
  Col,
  DatePicker,
  Icon,
  Input,
  Row,
  Select,
  Spin,
  Table,
  notification,
} from "antd";
import axios from "axios";
import { Formik } from "formik";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useFinancial } from "../../../hooks/useFinancialReducer";

import ButtonStandard from "../../utils/button";
import { Container, Footer } from "./styles";

// Services
import { useCurrencyContext } from "../../../hooks/useCurrencyContext";
import { findAllCostActivityTypeByGroupIdAndFarmId } from "../../../services/costActivityTypeService";
import { storeOrUpdateFinancialCashFlowBugdet } from "../../../services/financialCashFlowService";
import { findAllFinancialClassesByGroupIdAndFarmId } from "../../../services/financialClassService";
import {
  getTwoDecimalDigits,
  numberMask,
} from "../../../services/helpersMethodsService";
import InputCurrency from "../../utils/inputCurrency";
import SelectCurrency from "../../utils/selectCurrency";

const DrawerFinancialCashFlowBudget = () => {
  const formikRef = useRef(null);
  const signal = axios.CancelToken.source();
  const { Column } = Table;
  const [listCostType, setListCostType] = useState([
    { costType: "Growth", amountAnimals: 0 },
    { costType: "RecreateFattenPasture", amountAnimals: 0 },
    { costType: "RecreateFattenSemiConfinement", amountAnimals: 0 },
    { costType: "RecreateFattenConfinement", amountAnimals: 0 },
    { costType: "Agriculture", amountAnimals: 0 },
    { costType: "Others", amountAnimals: 0 },
  ]);
  const [isLoadingCostType, setIsLoadingCostType] = useState(false);
  const [listOfClasses, setListOfClasses] = useState([]);
  const [isLoadingListOfClasses, setIsLoadingListOfClasses] = useState(false);
  const [minDate, setMinDate] = useState(null);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [form, setForm] = useState({
    dateStart: null,
    dateEnd: null,
    costType: null,
  });
  const [values, setValues] = useState([]);
  const validationSchema = Yup.object().shape({
    dateStart: Yup.date().required(),
    costType: Yup.string().required(),
  });

  const {
    shouldShowDrawerFinancialCashFlowBudget: drawerVisible,
    openOrCloseDrawerFinancialCashFlowBudget,
    refreshListFinancialCashFlow,
    refreshListFinancialCosting,
  } = useFinancial();

  const { defaultCurrency, defaultCurrencyCode } = useCurrencyContext();

  // Redux
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const sumValues = useMemo(() => {
    return numberMask(
      getTwoDecimalDigits(
        values
          .filter((v) => v.value !== null)
          .map((v) => v.value)
          .reduce((a, b) => a + b, 0) || 0
      ),
      true,
      defaultCurrencyCode
    );
  }, [values, defaultCurrencyCode]);

  // Methods
  const closeDrawer = useCallback(() => {
    openOrCloseDrawerFinancialCashFlowBudget();
    signal.cancel();
  }, [openOrCloseDrawerFinancialCashFlowBudget, signal]);
  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
  }, []);
  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);
  const handleGetColumnSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return dataIndex === "financialNature.name"
          ? record.financialNature.name
              ?.toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          : record[dataIndex]
              ?.toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase());
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
        }
      },
    }),
    [handleReset, handleSearch]
  );
  const handleDrawerVisible = useCallback(
    async (visible) => {
      if (visible === false) {
        setForm({
          dateStart: null,
          dateEnd: null,
          costType: null,
        });
        formikRef.current.resetForm({
          dateStart: null,
          dateEnd: null,
          costType: null,
        });
        signal.cancel();
      } else {
        setIsLoadingListOfClasses(true);
        try {
          const {
            data: { results },
          } = await findAllFinancialClassesByGroupIdAndFarmId({
            groupId,
            farmId,
            signal,
          });
          setListOfClasses(
            results.filter((c) => c.financialNature.canBudget === true)
          );
          setValues(
            results.map((r) => ({
              financialClass: r,
              value: null,
              valueCurrency: defaultCurrencyCode ?? "BRL",
            }))
          );
        } catch (error) {
        } finally {
          setIsLoadingListOfClasses(false);
        }
      }
    },
    [signal, groupId, farmId, defaultCurrencyCode]
  );
  const handleInputValues = useCallback(
    (floatValue, record) => {
      try {
        if (floatValue == null) {
          setValues((old) =>
            old.map((v) => {
              if (v.financialClass.id === record.id) v.value = null;
              return v;
            })
          );
        } else {
          const valueIndex = values?.findIndex(
            (v) => v.financialClass.id === record.id
          );
          if (valueIndex > -1) {
            let valueRec = values[valueIndex];
            valueRec.value = floatValue > 1000000000 ? 999999999 : floatValue;
            setValues((old) =>
              old.map((v) => (v.financialClass.id === record.id ? valueRec : v))
            );
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [values]
  );
  const handleSubmitModalForm = async (formValues, actions) => {
    setIsLoadingRequest(true);
    try {
      const valuesWithValueNotNull = values.filter((v) => v.value != null);
      const body = {
        values: valuesWithValueNotNull,
        monthStart: formValues.dateStart.month() + 1,
        yearStart: formValues.dateStart.year(),
        costType: formValues.costType,
        monthEnd:
          formValues.dateEnd != null ? formValues.dateEnd.month() + 1 : null,
        yearEnd: formValues.dateEnd != null ? formValues.dateEnd.year() : null,
      };
      if (
        formValues.dateEnd != null &&
        formValues.dateEnd.isBefore(formValues.dateStart)
      ) {
        notification.error({
          message:
            'O mês e o ano do campo "Inserir/Atualizar até Mês/Ano" tem que ser maior que o mês e ano de inicio.',
        });
        return;
      }
      if (valuesWithValueNotNull.length === 0) {
        notification.error({
          message: 'É necessário informar um valor para alguma "Classe".',
        });
        return;
      }
      await storeOrUpdateFinancialCashFlowBugdet({
        groupId,
        farmId,
        body,
        signal,
      });
      notification.success({
        message: "Orçamento cadastrado com sucesso",
      });
      refreshListFinancialCashFlow();
      refreshListFinancialCosting();
      closeDrawer();
    } catch (error) {
      notification.error({
        message: "Erro ao cadastrar orçamento. Contate o suporte.",
      });
    } finally {
      setIsLoadingRequest(false);
    }
  };

  // Effects
  // Create minDate to control insert of start budget input
  useEffect(() => {
    let now = moment();
    let minDateCurrent = moment().month(5).date(30);
    if (now.isBefore(minDateCurrent)) {
      setMinDate(now.subtract(1, "years").month(6).date(1));
    } else {
      setMinDate(now.month(6).date(1));
    }
  }, []);
  // Fetch costs types
  useEffect(() => {
    async function fetch() {
      setIsLoadingCostType(true);
      try {
        const {
          data: { results },
        } = await findAllCostActivityTypeByGroupIdAndFarmId({
          groupId,
          farmId,
        });
        setListCostType(results.filter((c) => c.costType !== "AllTypes"));
      } catch (error) {
      } finally {
        setIsLoadingCostType(false);
      }
    }
    fetch();
  }, [farmId, groupId]);

  return (
    <Container
      title={"Novo orçamento"}
      width={750}
      onClose={closeDrawer}
      maskClosable={false}
      visible={drawerVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Spin spinning={isLoadingRequest}>
        <Formik
          ref={formikRef}
          enableReinitialize={true}
          initialValues={form}
          initialErrors={{}}
          onSubmit={handleSubmitModalForm}
          validationSchema={validationSchema}
          render={(props) => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <div className="drawerForm">
                {Object.entries(props.errors).length > 0 &&
                  props.submitCount > 0 && (
                    <Row type="flex" justify="center" align="middle">
                      <label className="error">
                        {translation.error.formError}
                      </label>
                    </Row>
                  )}
                {/* monthStart and yearStart */}
                <Row type="flex" justify="start" gutter={8}>
                  <Col span={12}>
                    <Row>
                      <label
                        className={
                          props.errors.dateStart && props.submitCount > 0
                            ? "error"
                            : ""
                        }
                        htmlFor="costType"
                      >
                        Mês/Ano*
                      </label>
                    </Row>
                    <Row>
                      <DatePicker.MonthPicker
                        name="dateStart"
                        value={props.values.dateStart}
                        placeholder={translation.defaultDatePickerPlaceholder}
                        disabledDate={(current) => current.isBefore(minDate)}
                        format={"MM/YYYY"}
                        onChange={(date) =>
                          props.setFieldValue("dateStart", date)
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <label
                        className={
                          props.errors.costType && props.submitCount > 0
                            ? "error"
                            : ""
                        }
                        htmlFor="costType"
                      >
                        Atividades*
                      </label>
                    </Row>
                    <Row>
                      <Select
                        style={{ width: "100%" }}
                        name="costType"
                        value={props.values.costType || undefined}
                        placeholder={translation.defaultSelectPlaceholder}
                        loading={isLoadingCostType}
                        onChange={(value) => {
                          props.setFieldValue("costType", value);
                        }}
                      >
                        {listCostType.map((l) => (
                          <Select.Option key={l.costType} value={l.costType}>
                            {l.costType !== "Agriculture" &&
                            l.costType !== "Others"
                              ? `${translation.costType[l.costType]} - ${
                                  l.amountAnimals
                                } cab.`
                              : `${translation.costType[l.costType]}`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Row>
                  </Col>
                </Row>
                <Row type="flex" gutter={8}>
                  <Col>
                    <Row>
                      <label>Moeda padrão</label>
                    </Row>
                    <Row>
                      <SelectCurrency value={defaultCurrencyCode} disabled />
                    </Row>
                  </Col>
                </Row>
                {/* Values */}
                <Row>
                  <Table
                    loading={isLoadingListOfClasses}
                    rowKey="id"
                    size="small"
                    dataSource={listOfClasses != null ? listOfClasses : []}
                    pagination={{
                      hideOnSinglePage:
                        listOfClasses !== null && listOfClasses.length > 10
                          ? false
                          : true,
                      showSizeChanger: true,
                      pageSizeOptions: [
                        "10",
                        "20",
                        "30",
                        "100",
                        "500",
                        "1000",
                        "2000",
                      ],
                    }}
                    scroll={{
                      x: true,
                      y: true,
                    }}
                    style={{ width: "100%", margin: "0" }}
                    footer={() => (
                      <span>
                        <strong>Total: </strong>
                        {sumValues}
                      </span>
                    )}
                  >
                    <Column
                      title={
                        translation.financial.cashFlow.table.columns.nature
                      }
                      dataIndex="financialNature.name"
                      key="financialNature.name"
                      fixed
                      width={225}
                      align="left"
                      sorter={(first, second) => {
                        return first.financialNature.name.localeCompare(
                          second.financialNature.name,
                          "pt-BR",
                          {
                            numeric: false,
                            ignorePunctuation: true,
                          }
                        );
                      }}
                      {...handleGetColumnSearchProps("financialNature.name")}
                    />
                    <Column
                      title={translation.financial.cashFlow.table.columns.class}
                      dataIndex="name"
                      key="name"
                      fixed
                      width={225}
                      align="left"
                      sorter={(first, second) => {
                        if (!first.name) {
                          first.name = "";
                        }
                        return first.name.localeCompare(second.name, "pt-BR", {
                          numeric: false,
                          ignorePunctuation: true,
                          sensitivity: "base",
                        });
                      }}
                      {...handleGetColumnSearchProps("name")}
                    />
                    <Column
                      dataIndex="field"
                      key="field"
                      width={200}
                      render={(text, record) => (
                        <InputCurrency
                          value={
                            values?.find(
                              (v) => v.financialClass.id === record.id
                            )?.value
                          }
                          name="values"
                          addonBefore={defaultCurrency.symbol}
                          setValue={(floatValue) => {
                            handleInputValues(floatValue, record);
                          }}
                        />
                      )}
                    />
                  </Table>
                </Row>
                {/* EndDate */}
                <Row type="flex" justify="start" gutter={8}>
                  <Col span={12}>
                    <Row>
                      <label htmlFor="costType">
                        Inserir/Atualizar até Mês/Ano
                      </label>
                    </Row>
                    <Row>
                      <DatePicker.MonthPicker
                        name="dateEnd"
                        value={props.values.dateEnd}
                        placeholder={translation.defaultDatePickerPlaceholder}
                        disabled={props.values.dateStart === null}
                        disabledDate={(current) =>
                          current.isBefore(
                            moment(props.values.dateStart).add(1, "months")
                          )
                        }
                        format={"MM/YYYY"}
                        onChange={(date) =>
                          props.setFieldValue("dateEnd", date)
                        }
                      />
                    </Row>
                  </Col>
                </Row>
              </div>
              {/* Footer */}
              <Footer>
                <Row type="flex" justify="space-between">
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="selectDiv"
                  ></Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="buttonsDiv"
                  >
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={closeDrawer}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard buttonType="type6" type="submit">
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
      </Spin>
    </Container>
  );
};

export default DrawerFinancialCashFlowBudget;
