import React from "react";
import { Button, Col, Row } from "antd";
import TrashIcon from "../../../components/utils/icons/trash";
import useMovementAnimalsContext from "../../../hooks/useMovementAnimalsContext";

// import { Container } from './styles';

function MovementAnimalsDestinationLots({ lot }) {
  const { removeDestinationLot } = useMovementAnimalsContext();
  return (
    <Row type="flex">
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        {lot.name}
      </Col>
      <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ textAlign: "right" }}>
        {lot.animals.length} {lot.animals.length > 1 ? `animais` : `animal`}
      </Col>
      <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right" }}>
        <Button
          onClick={() => removeDestinationLot(lot.key)}
          style={{ border: 0, marginTop: -5 }}
        >
          <TrashIcon />
        </Button>
      </Col>
    </Row>
  );
}

export default React.memo(MovementAnimalsDestinationLots);
