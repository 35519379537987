import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as ClientActions } from "../../../store/ducks/client";

// Components
import {
  Container,
  Footer,
  RadioGroupCustom,
  RadioButtonCustom,
  CustomDivider,
} from "./styles";
import { Row, Col, Select, Input, Spin, Icon } from "antd";
import ButtonStandard from "../../utils/button";
import ZipCodeInput from "../../utils/input/zipCodeInput";
import PhoneNumberInput from "../../utils/input/phoneNumberInput";

// Services
import { findClientById } from "../../../services/managementService";
import {
  getCep,
  cpfMask,
  cnpjMask,
  getOnlyNumber,
  validateCPF,
  validateCNPJ,
} from "../../../services/helpersMethodsService";
import { getStatesInfo, getCitiesByState } from "../../../services/cityService";

const DrawerClient = () => {
  // Redux Variable
  const {
    translation,
    groupSelected: { id: groupIdSelected, name: groupName },
    farmSelected: { id: farmIdSelected },
  } = useSelector((state) => state.app);
  const {
    drawerClientVisible,
    idClientEditOrDetails,
    isLoadingRequest: loadRequestRedux,
  } = useSelector((state) => state.client);
  const [form, setForm] = useState({
    id: null,
    groupId: groupIdSelected,
    farmId: farmIdSelected,
    type: null,
    document: null,
    register: null,
    name: null,
    responsibleName: null,
    phone: "",
    documentType: "CNPJ",
    zipCode: null,
    address: null,
    state: null,
    city: {
      name: null,
    },
    country: null,
    complement: null,
    number: null,
    status: "Active",
  });
  const [loadRequest, setLoadRequest] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);
  const [zipCodeValid, setZipCodeValid] = useState(null);
  const [statesArray, setStatesArray] = useState([]);
  const [citiesArray, setCitiesArray] = useState([]);
  const [countriesArray, setCountriesArray] = useState([]);
  const [type, setType] = useState("CNPJ");
  const [errors, setErrors] = useState([]);
  const [initialStates, setInitialStates] = useState([]);
  const [initialCountries, setInitialCountries] = useState([]);
  const { Option } = Select;

  const dispatch = useDispatch();
  // Methods
  function closeDrawer() {
    dispatch(ClientActions.hideDrawerClientVisible());
  }

  function setDocument(value) {
    if (type === "CNPJ") {
      setForm({ ...form, document: cnpjMask(value) });
    } else {
      setForm({ ...form, document: cpfMask(value) });
    }
    setErrors(errors.filter((e) => e !== "document"));
  }

  function validateForm() {
    let aErrors = [];
    if (form.name === null || form.name.trim() === "") {
      aErrors.push("name");
    }
    if (form.zipCode === null || form.zipCode.trim() === "") {
      aErrors.push("zipCode");
    }
    if (form.address === null || form.address.trim() === "") {
      aErrors.push("address");
    }
    if (form.country === null || form.country.trim() === "") {
      aErrors.push("country");
    }
    if (form.state === null || form.state.trim() === "") {
      aErrors.push("state");
    }
    if (
      form.city === null ||
      form.city?.name === null ||
      form.city?.name.trim() === ""
    ) {
      aErrors.push("city");
    }
    if (form.document === null || form.document.trim() === "") {
      aErrors.push("document");
    } else if (
      form.document !== null &&
      form.document.trim() !== "" &&
      form.documentType === "CPF" &&
      !validateCPF(form.document)
    ) {
      aErrors.push("document");
    } else if (
      form.document !== null &&
      form.document.trim() !== "" &&
      form.documentType === "CNPJ" &&
      !validateCNPJ(form.document)
    ) {
      aErrors.push("document");
    }
    setErrors(aErrors);
    return aErrors.length > 0 ? false : true;
  }

  function handleSubmit() {
    if (validateForm()) {
      const body = { ...form, phone: getOnlyNumber(form?.phone) };
      if (body?.id === null) {
        dispatch(
          ClientActions.saveClient(groupIdSelected, farmIdSelected, body)
        );
      } else {
        dispatch(
          ClientActions.saveClient(
            groupIdSelected,
            farmIdSelected,
            body,
            body?.id
          )
        );
      }
    }
  }

  async function handleZipCodeSearch(zipCode) {
    if (zipCode.length === 9) {
      setLoadingCep(true);
      try {
        const {
          data: {
            erro,
            logradouro: street,
            uf: state,
            localidade: city,
            complemento: complementAddress,
          },
        } = await getCep(zipCode);
        if (erro) {
          setLoadingCep(false);
          setZipCodeValid(false);
        } else {
          const countries = initialCountries.filter((c) => c.name === "Brasil");
          const states = initialStates.filter((s) => s.name === state);

          const { data } = await getCitiesByState(states[0].id);

          if (data) {
            const cities = data.results.filter(
              (c) => c.name.toLowerCase() === city.toLowerCase()
            );
            setCitiesArray(data.results);
            setForm({
              ...form,
              address: street,
              city: {
                name: cities[0].name,
              },
              state: states[0].name,
              complement: complementAddress,
              country: countries[0].name,
            });
            setZipCodeValid(true);
            setErrors(
              errors.filter(
                (e) =>
                  e !== "city" &&
                  e !== "zipCode" &&
                  e !== "state" &&
                  e !== "address" &&
                  e !== "country"
              )
            );
          }
          setLoadingCep(false);
        }
      } catch (error) {
        setLoadingCep(false);
        setZipCodeValid(false);
      }
    } else {
      setLoadingCep(false);
      setZipCodeValid(false);
    }
  }

  async function handleDrawerVisible(visible) {
    if (visible) {
      setLoadRequest(true);
      setZipCodeValid(null);
      try {
        const { data } = await getStatesInfo();
        if (data) {
          setInitialStates(data.results);
          setInitialCountries([
            {
              id: data.results[0].countryId,
              name: data.results[0].countryName,
            },
          ]);
          setStatesArray(data.results);
          setCountriesArray([
            {
              id: data.results[0].countryId,
              name: data.results[0].countryName,
            },
          ]);

          if (idClientEditOrDetails !== null) {
            const {
              data: { results },
            } = await findClientById({
              clientId: idClientEditOrDetails,
              groupId: groupIdSelected,
              farmId: farmIdSelected,
            });

            let clientData = {
              ...results,
              document:
                results.documentType === "CNPJ"
                  ? cnpjMask(results.document)
                  : cpfMask(results.document),
              existingClientId: null,
            };
            if (clientData.state) {
              const filteredStates = clientData?.state
                ? data.results.filter((s) => s.name === clientData?.state)
                : [];
              const { data: citiesData } = await getCitiesByState(
                filteredStates[0]?.id
              );
              if (citiesData) {
                setCitiesArray(citiesData.results);
              }
            }

            setForm(clientData);
            setType(clientData.documentType);
          } else {
            setForm({
              id: null,
              existingClientId: null,
              groupId: groupIdSelected,
              farmId: farmIdSelected,
              type: null,
              document: null,
              register: null,
              name: null,
              responsibleName: null,
              phone: "",
              documentType: "CNPJ",
              zipCode: null,
              address: null,
              state: null,
              city: null,
              country: null,
              complement: null,
              number: null,
              status: "Active",
            });
            setType("CNPJ");
          }
        }
      } catch (error) {
        setForm({
          id: null,
          existingClientId: null,
          groupId: groupIdSelected,
          farmId: farmIdSelected,
          type: null,
          document: null,
          register: null,
          name: null,
          responsibleName: null,
          phone: "",
          documentType: "CNPJ",
          zipCode: null,
          address: null,
          state: null,
          city: {
            name: null,
          },
          country: null,
          complement: null,
          number: null,
          status: "Active",
        });
        setType("CNPJ");
      } finally {
        setLoadRequest(false);
      }
    } else {
      setForm({
        id: null,
        existingClientId: null,
        groupId: groupIdSelected,
        farmId: farmIdSelected,
        type: null,
        document: null,
        register: null,
        name: null,
        responsibleName: null,
        phone: "",
        documentType: "CNPJ",
        zipCode: null,
        address: null,
        state: null,
        city: {
          name: null,
        },
        country: null,
        complement: null,
        number: null,
        status: "Active",
      });
      setType("CNPJ");
      setLoadRequest(false);
    }
  }

  function handleCountryChange(countryName) {
    const country = initialCountries.filter((c) => c.name === countryName);
    const filteredStates = initialStates.filter(
      (s) => s.countryId === country[0].id
    );
    setStatesArray(filteredStates);
  }

  async function handleStateChange(stateName) {
    const state = initialStates.filter((s) => s.name === stateName);
    const country = initialCountries.filter((c) => c.id === state[0].countryId);

    const { data } = await getCitiesByState(state[0].id);

    if (data) {
      const city =
        form?.city != null
          ? data.results.filter((c) => c.name === form?.city?.name) != null
            ? data.results.filter((c) => c.name === form?.city?.name)
            : undefined
          : undefined;
      setForm({
        ...form,
        state: stateName,
        country: country[0].name,
        city: city != null ? city : undefined,
      });
      setCitiesArray(data.results);
    }
  }

  function handleCityChange(cityName) {
    const city = citiesArray.filter((c) => c.name === cityName);
    setForm({
      ...form,
      city: city[0],
    });
  }

  return (
    <Container
      title={
        idClientEditOrDetails === null
          ? "Cadastro de Cliente"
          : "Editar Cliente"
      }
      width={511}
      onClose={closeDrawer}
      maskClosable={false}
      visible={drawerClientVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <div className="drawerForm">
        <Spin spinning={loadRequest || loadRequestRedux}>
          <Row type="flex">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <span className="subTitle">
                Preencha os campos abaixo para cadastrar um cliente
              </span>
            </Col>
          </Row>
          {errors.length > 0 && (
            <Row type="flex" justify="center" align="middle">
              <label className="error">{translation.error.formError}</label>
            </Row>
          )}
          {form?.id === null && (
            <>
              <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row>
                    <label className={errors.includes("email") ? "error" : ""}>
                      Quem irá utilizar o Cliente?*
                    </label>
                  </Row>
                  <Row>
                    <RadioGroupCustom
                      defaultValue={
                        form?.id === null
                          ? "onlyFarm"
                          : form.farmId === null
                          ? "allFarmsGroup"
                          : "onlyFarm"
                      }
                      buttonStyle="solid"
                    >
                      <RadioButtonCustom
                        value="onlyFarm"
                        onChange={(e) => {
                          setForm({
                            ...form,
                            farmId: farmIdSelected,
                            groupId: groupIdSelected,
                          });
                        }}
                      >
                        Somente esta fazenda
                      </RadioButtonCustom>
                      <RadioButtonCustom
                        value="allFarmsGroup"
                        onChange={(e) => {
                          setForm({
                            ...form,
                            farmId: null,
                            groupId: groupIdSelected,
                          });
                        }}
                      >
                        Fazendas do grupo:{" "}
                        {`${
                          groupName.length > 10
                            ? `${groupName.substr(0, 10)}...`
                            : groupName
                        }`}
                      </RadioButtonCustom>
                    </RadioGroupCustom>
                  </Row>
                </Col>
              </Row>
              <CustomDivider dashed />
            </>
          )}
          {/* Document type */}
          <Row type="flex" align="middle">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Row>
                <label>Tipo de cliente*</label>
              </Row>
              <Row>
                <RadioGroupCustom value={type} buttonStyle="solid">
                  <RadioButtonCustom
                    value={"CNPJ"}
                    disabled={form?.id !== null}
                    onChange={(e) => {
                      setType(e.target.value);
                      setForm({
                        ...form,
                        document:
                          form.document?.length !== 18 ? null : form.document,
                        documentType: e.target.value,
                      });
                    }}
                  >
                    Empresa
                  </RadioButtonCustom>
                  <RadioButtonCustom
                    value={"CPF"}
                    disabled={form?.id !== null}
                    onChange={(e) => {
                      setType(e.target.value);
                      setForm({
                        ...form,
                        document:
                          form.document?.length > 11 ? null : form.document,
                        documentType: "CPF",
                      });
                    }}
                  >
                    Pessoa física
                  </RadioButtonCustom>
                </RadioGroupCustom>
              </Row>
            </Col>
          </Row>
          {/* Document */}
          <Row type="flex" justify="start" gutter={4}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Row className="rowLabel">
                <label className={errors.includes("document") ? "error" : ""}>
                  Documento*
                </label>
              </Row>
              <Row>
                <Input
                  kind="mixed"
                  name="document"
                  type="text"
                  value={
                    form?.document != null
                      ? type === "CPF"
                        ? cpfMask(form?.document)
                        : type === "CNPJ"
                        ? cnpjMask(form?.document)
                        : form?.document
                      : undefined
                  }
                  disabled={form?.id !== null}
                  maxLength={type === "CNPJ" ? 18 : 14}
                  autoComplete="chrome-off"
                  placeholder={translation.defaultPlaceholder}
                  onChange={(e) => {
                    setDocument(e.target.value);
                  }}
                />
              </Row>
            </Col>
          </Row>
          {/* Name */}
          <Row type="flex">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row className="rowLabel">
                <label className={errors.includes("name") ? "error" : ""}>
                  Nome {type === "CNPJ" ? " da empresa*" : "do cliente*"}
                </label>
              </Row>
              <Row>
                <Input
                  name="name"
                  value={form?.name != null ? form?.name : undefined}
                  type="name"
                  autoComplete="chrome-off"
                  placeholder={translation.defaultPlaceholder}
                  disabled={form?.existingClientId !== null}
                  onChange={(e) => {
                    setForm({ ...form, name: e.target.value });
                    setErrors(errors.filter((e) => e !== "name"));
                  }}
                />
              </Row>
            </Col>
          </Row>
          {/* Responsible Name */}
          {type === "CNPJ" ? (
            <Row type="flex">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row className="rowLabel">
                  <label
                    className={
                      errors.includes("responsibleName") ? "error" : ""
                    }
                  >
                    Responsável
                  </label>
                </Row>
                <Row>
                  <Input
                    name="responsibleName"
                    value={
                      form?.responsibleName != null
                        ? form?.responsibleName
                        : undefined
                    }
                    type="responsibleName"
                    placeholder={translation.defaultPlaceholder}
                    disabled={form?.existingClientId !== null}
                    onChange={(e) => {
                      setForm({ ...form, responsibleName: e.target.value });
                      setErrors(errors.filter((e) => e !== "responsibleName"));
                    }}
                  />
                </Row>
              </Col>
            </Row>
          ) : null}
          {/* Phone */}
          <Row type="flex">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row className="rowLabel">
                <label>Telefone (Opcional)</label>
              </Row>
              <Row>
                <PhoneNumberInput
                  name="phone"
                  value={form?.phone != null ? form?.phone : ""}
                  type="text"
                  placeholder={translation.defaultPlaceholder}
                  disabled={form?.existingClientId !== null}
                  onChange={(e) => {
                    setForm({ ...form, phone: e.target.value });
                    setErrors(errors.filter((e) => e !== "phone"));
                  }}
                />
              </Row>
            </Col>
          </Row>
          <CustomDivider dashed />
          {/* ZipCode */}
          <Row type="flex" style={{ marginTop: 13 }} align="middle">
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
              <Row>
                <label className={errors.includes("zipCode") ? "error" : ""}>
                  CEP*
                </label>
              </Row>
              <Row>
                <ZipCodeInput
                  placeholder={translation.farm.form.zipCodePlaceHolder}
                  type="text"
                  value={form?.zipCode != null ? form?.zipCode : ""}
                  name="zipCode"
                  autoComplete="chrome-off"
                  disabled={form?.existingClientId !== null}
                  onChange={(e) => {
                    setForm({ ...form, zipCode: e.target.value });
                    setErrors(errors.filter((e) => e !== "zipCode"));
                  }}
                  onBlur={(e) => handleZipCodeSearch(e.target.value)}
                />
              </Row>
            </Col>
            <Col xs={14} sm={14} md={14} lg={14} xl={14} push={1}>
              {loadingCep === true ? (
                <Row style={{ marginTop: 18 }}>
                  <span
                    style={{
                      color: "#4b4b4b",
                      fontWeight: "bold",
                    }}
                  >
                    <Icon type="loading" style={{ marginRight: 10 }} />
                    Processando
                  </span>
                </Row>
              ) : zipCodeValid != null && zipCodeValid === true ? (
                <Row style={{ marginTop: 18 }}>
                  <span
                    style={{
                      color: "#33C162",
                      fontWeight: "bold",
                    }}
                  >
                    <Icon
                      type="check-circle"
                      style={{
                        marginRight: 10,
                        color: "#33C162",
                        fontSize: 12,
                      }}
                    />
                    {translation.zipCodeValid}
                  </span>
                </Row>
              ) : zipCodeValid != null && zipCodeValid === false ? (
                <Row style={{ marginTop: 18 }}>
                  <span style={{ color: "#D44C4C", fontWeight: "bold" }}>
                    <Icon
                      type="close-circle"
                      style={{
                        marginRight: 10,
                        color: "#D44C4C",
                        fontSize: 12,
                      }}
                    />
                    {translation.zipCodeInvalid}
                  </span>
                </Row>
              ) : null}
            </Col>
          </Row>
          {/* Address */}
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <label className={errors.includes("address") ? "error" : ""}>
                  Endereço*
                </label>
              </Row>
              <Row>
                <Input
                  placeholder={translation.defaultPlaceholder}
                  value={form?.address}
                  name="address"
                  autoComplete="chrome-off"
                  disabled={loadingCep || form?.existingClientId !== null}
                  onChange={(e) => {
                    setForm({ ...form, address: e.target.value });
                    setErrors(errors.filter((e) => e !== "address"));
                  }}
                />
              </Row>
            </Col>
          </Row>
          {/* Input complement and number */}
          <Row type="flex" style={{ marginTop: 13 }} align="middle">
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
              <Row className="rowInput">
                <Input
                  value={form?.number}
                  name="number"
                  disabled={loadingCep || form?.existingClientId !== null}
                  maxLength={200}
                  addonBefore="Nº"
                  onChange={(e) => {
                    setForm({ ...form, number: e.target.value });
                    setErrors(errors.filter((e) => e !== "number"));
                  }}
                />
              </Row>
            </Col>
            <Col xs={15} sm={15} md={15} lg={15} xl={15} offset={1}>
              <Row className="rowInput">
                <Input
                  placeholder={
                    translation.farm.form.complementAddressPlaceHolder
                  }
                  value={form?.complement}
                  name="complement"
                  addonBefore="Comp."
                  disabled={loadingCep || form?.existingClientId !== null}
                  maxLength={200}
                  onChange={(e) => {
                    setForm({ ...form, complement: e.target.value });
                    setErrors(errors.filter((e) => e !== "complement"));
                  }}
                />
              </Row>
            </Col>
          </Row>
          {/* Country, state and city */}
          <Row type="flex" style={{ marginTop: 13 }} align="middle">
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <Row>
                <label className={errors.includes("country") ? "error" : ""}>
                  {translation.supplier.form.country}*
                </label>
              </Row>
              <Row>
                <Select
                  placeholder={translation.supplier.form.countryPlaceHolder}
                  value={form?.country != null ? form?.country : undefined}
                  name="country"
                  disabled={
                    loadingCep ||
                    zipCodeValid ||
                    form?.existingClientId !== null
                  } //loadingCep}
                  onChange={(value) => {
                    setForm({ ...form, country: value });
                    handleCountryChange(value);
                  }}
                >
                  {countriesArray.length > 0 &&
                    countriesArray.map((c, index) => (
                      <Option key={c.id} value={c.name}>
                        {c.name}
                      </Option>
                    ))}
                </Select>
              </Row>
            </Col>
            <Col xs={5} sm={5} md={5} lg={5} xl={5} offset={1}>
              <Row>
                <label className={errors.includes("state") ? "error" : ""}>
                  {translation.farm.form.state}*
                </label>
              </Row>
              <Row>
                <Select
                  placeholder={translation.farm.form.statePlaceHolder}
                  value={form?.state != null ? form?.state : undefined}
                  name="state"
                  disabled={
                    loadingCep ||
                    zipCodeValid ||
                    form?.country == null ||
                    form?.existingClientId !== null
                  } //loadingCep}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    handleStateChange(value);
                  }}
                >
                  {statesArray.length > 0 &&
                    statesArray.map((state) => (
                      <Option key={state.id} value={state.name}>
                        {state.name}
                      </Option>
                    ))}
                </Select>
              </Row>
            </Col>
            <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
              <Row>
                <label className={errors.includes("city") ? "error" : ""}>
                  {translation.supplier.form.city}*
                </label>
              </Row>
              <Row>
                <Select
                  placeholder={translation.supplier.form.cityPlaceHolder}
                  value={form?.city != null ? form?.city.name : undefined}
                  name="city"
                  disabled={
                    loadingCep ||
                    zipCodeValid ||
                    form?.state == null ||
                    form?.existingClientId !== null
                  } //loadingCep}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    handleCityChange(value);
                  }}
                >
                  {citiesArray.length > 0 &&
                    citiesArray.map((c, index) => (
                      <Option key={`${c.id} - ${index}`} value={c.name}>
                        {c.name}
                      </Option>
                    ))}
                </Select>
              </Row>
            </Col>
          </Row>
        </Spin>
      </div>
      <Footer>
        <Row type="flex">
          <Col span={24} className="buttonsDiv">
            <ButtonStandard
              type="button"
              buttonType="type7"
              onClick={closeDrawer}
            >
              {translation.buttons.cancel}
            </ButtonStandard>

            <ButtonStandard
              type="button"
              buttonType="type6"
              onClick={handleSubmit}
            >
              {translation.buttons.save}
            </ButtonStandard>
          </Col>
        </Row>
      </Footer>
    </Container>
  );
};

export default DrawerClient;
