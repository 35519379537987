// import axios from "axios";
import getHeaders from "./getHeaders";
import apiCommons from "../config/api_commons";
import axios from "axios";

// const apiCommons = axios.create({
//   baseURL: "http://localhost:8083",
// });

export function processReport(payload) {
  const {
    groupId,
    farmId,
    reportName,
    reportType,
    reportSearch,
    // reportColumns,
    ids,
  } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/reports/${reportName}?report_search=${reportSearch}&report_file_type=${reportType}`,
    ids,
    getHeaders(false, null, true)
  );
}

export function processReportXlsxFromTemplate(payload) {
  const { groupId, farmId, reportName, reportSearch, ids } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/reports/${reportName}/from/template/excel?report_search=${reportSearch}`,
    ids,
    getHeaders(false, null, true)
  );
}

export function processReportNutritionalConsumption(payload) {
  const { groupId, farmId, body } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/reports/nutritional/consumptions`,
    body,
    getHeaders(false, null, true)
  );
}

export function processReportConfinementResult(payload) {
  const { groupId, farmId, body } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/reports/intensive/result`,
    body,
    getHeaders(false, null, true)
  );
}
