import React, { useCallback, useEffect, useMemo, useState } from "react";
import useRegister from "../../../../hooks/useRegister";
import {
  isValid,
  isExpirationDateValid,
  isSecurityCodeValid,
  getCreditCardNameByNumber,
} from "creditcard.js";
// Components
import { Row, Col, notification, Checkbox, Select } from "antd";
import {
  Container,
  CustomRadio,
  CustomRadioGroup,
  FormContainer,
  FormFooterContaier,
} from "../styles";
import CreditCard from "../../../../components/utils/creditCard";
import InputStandard from "../../../../components/utils/input";
import NumberFormat from "react-number-format";
import {
  getOnlyNumber,
  validateCPF,
} from "../../../../services/helpersMethodsService";
import { createUser } from "../../../../services/authService";
import { getTokenCreditCard } from "../../../../services/paymentGatewayService";
import {
  EloBrand,
  HiperCardBrand,
  MasterCardBrand,
  VisaBrand,
} from "../../../../components/utils/icons/creditCardBrand";
// Services

const Radio = ({ value, selected, children, onClick }) => {
  return (
    <CustomRadio onClick={onClick}>
      <div
        className={`radio-outer-circle ${
          selected === null
            ? "unselected"
            : value !== selected
            ? "unselected"
            : ""
        }`}
      >
        <div
          className={`radio-inner-circle ${
            selected === null
              ? "unselected-circle"
              : value !== selected
              ? "unselected-circle"
              : ""
          }`}
        />
      </div>
      {children}
    </CustomRadio>
  );
};

const RegisterStep4 = () => {
  const { Option } = Select;
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [differentHolderData, setdifferentHolderData] = useState(false);
  const [due_day, setDueDay] = useState(null);

  const [errors, setErrors] = useState([]);
  const {
    form,
    setForm,
    setUserId,
    mode,
    goBackStep,
    goNextStep,
    setIsLoading,
  } = useRegister();

  const [creditCardForm, setCreditCardForm] = useState({
    holder_name: form.fullname, // Nome do titular/portador do perfil de pagamento
    registry_code: form.document, // CPF ou CNPJ do titular/portador
    bank_branch: "", // Agência da conta bancária
    bank_account: "", // Número da conta bancária
    card_expiration: "", // Validade do cartão de crédito no formato MM/AA
    allow_as_fallback: true, // Permite utilizar o perfil de pagamento em retentativas de cobranças não pagas.
    card_number: "", // Número completo do cartão de crédito
    card_cvv: "", // Código de segurança do cartão de crédito com 3 ou 4 dígitos
    payment_method_code: "", // Código do método de pagamento
    payment_company_code: "", //	Código do banco ou bandeira
  });
  const creditCardBrand = useMemo(() => {
    switch (creditCardForm.payment_company_code) {
      case "Visa":
        return <VisaBrand />;
      case "Mastercard":
        return <MasterCardBrand />;
      case "Elo":
        return <EloBrand />;
      case "Hipercard":
        return <HiperCardBrand />;
      default:
        return null;
    }
  }, [creditCardForm.payment_company_code]);
  /** Redux variables */
  /** Effect  */

  /** Methods */

  function handleGoBack() {
    goBackStep();
  }
  const handleGoNext = useCallback(() => {
    goNextStep();
  }, [goNextStep]);

  const handleSelectPaymentMethod = useCallback((value) => {
    if (value !== "CreditCard") {
    }
    setPaymentMethod(value);
  }, []);

  const handleSelectDueDay = useCallback(
    (value) => {
      setDueDay(value);
      setForm((old) => ({
        ...old,
        due_day: value,
      }));
    },
    [setForm]
  );

  async function handleSubmitForm() {
    const {
      email,
      birthday,
      phone,
      fullname,
      document,
      zip_code,
      address,
      address_number,
      neighborhood,
      country,
      state,
      city,
      password,
      plan,
      due_day,
    } = form;

    const isTrial = mode === "Demo";

    const body = {
      email,
      birthday,
      phone: getOnlyNumber(phone),
      fullname,
      document: getOnlyNumber(document),
      zip_code,
      address,
      address_number,
      neighborhood,
      country,
      state,
      city,
      trial: isTrial,
      password,
      gateway_token: "",
      due_day: due_day,
      plan: plan,
      paymentMethod: paymentMethod,
    };
    if (handleValidateForm()) {
      setIsLoading(true);
      try {
        if (paymentMethod === "CreditCard") {
          //Payment gateway token with credit_card info
          const {
            data: {
              payment_profile: { gateway_token: token },
            },
          } = await getTokenCreditCard(creditCardForm);

          //Attach gateway token with body content
          body.gateway_token = token;
        }

        //Create user and update id
        const {
          data: {
            results: { id: userId },
          },
        } = await createUser(body);
        setUserId(userId);
        setIsLoading(false);
        handleGoNext();
      } catch (e) {
        setIsLoading(false);
        const errorMessage = e.response?.data?.message;
        if (
          errorMessage &&
          errorMessage.includes("#AUTHENTICATION_ERROR_001")
        ) {
          notification.error({
            message:
              "Erro ao efetuar o cadastro. CPF já cadastrado em nosso sistema.",
          });
        } else {
          notification.error({ message: "Erro ao efetuar o cadastro" });
        }
      }
    }
  }

  function handleValidateForm() {
    const {
      holder_name,
      registry_code,
      card_expiration,
      card_number,
      card_cvv,
    } = creditCardForm;
    let errorsArray = [];

    if (due_day == null || due_day === 0) {
      errorsArray.push("due_day");
      notification.error({
        message: "Dia de vencimento da fatura inválido.",
      });
    }

    if (paymentMethod === "CreditCard") {
      if (holder_name == null || holder_name === "") {
        errorsArray.push("holder_name");
        notification.error({
          message: "Nome do titular/portardor é obrigatório.",
        });
      }
      if (
        registry_code == null ||
        registry_code === "" ||
        !validateCPF(registry_code)
      ) {
        errorsArray.push("registry_code");
        notification.error({
          message: "CPF inválido.",
        });
      }
      if (
        card_number == null ||
        card_number === "" ||
        !handleValidateCreditCardNumber(card_number)
      ) {
        errorsArray.push("card_number");
        notification.error({
          message: "Número do Cartão inválido.",
        });
      }
      if (
        card_expiration == null ||
        card_expiration === "" ||
        !handleValidateCreditCardExpiration(card_expiration)
      ) {
        errorsArray.push("card_expiration");
        notification.error({
          message: "Data do Vencimento inválida.",
        });
      }
      if (
        card_cvv == null ||
        card_cvv === "" ||
        !handleValidateCreditCardCVV(card_cvv)
      ) {
        errorsArray.push("card_cvv");
        notification.error({
          message: "Código de segurança inválido.",
        });
      }

      setErrors(errorsArray);

      return errorsArray.length === 0;
    } else {
      return errorsArray.length === 0;
    }
  }

  function handleValidateCreditCardNumber(value) {
    if (value != null) {
      const number = getOnlyNumber(value.trim());
      if (isValid(number)) {
        const brand = getCreditCardNameByNumber(number);
        setCreditCardForm((old) => ({
          ...old,
          payment_company_code: brand,
        }));
        return true;
      } else {
        setErrors((old) => [...old, "card_number"]);
        return false;
      }
    }
  }

  function handleValidateCreditCardExpiration(value) {
    if (value != null) {
      const month = value.trim().substr(0, 2);
      const year = value.trim().substr(3, 2);
      if (!isExpirationDateValid(month, year)) {
        setErrors((old) => [...old, "card_expiration"]);
        return false;
      }
      return true;
    }
  }

  function handleValidateCreditCardCVV(value) {
    const { card_number } = creditCardForm;
    const number = getOnlyNumber(card_number.trim());
    if (card_number != null && value != null) {
      if (!isSecurityCodeValid(number, value)) {
        setErrors((old) => [...old, "card_cvv"]);
        return false;
      }
      return true;
    }
  }

  // If plan selected is custom go to step 5
  useEffect(() => {
    function handleSkipStep() {
      if (form?.plan?.planSelected === "Custom") {
        handleGoNext();
      }
    }

    handleSkipStep();
  }, [form, handleGoNext]);

  return (
    <Container>
      <FormContainer>
        <Row type="flex" justify="start">
          <Col span={24}>
            <span className="title">Escolha a forma de pagamento</span>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={24}>
            <CustomRadioGroup>
              <Radio
                value="CreditCard"
                selected={paymentMethod}
                onClick={() => handleSelectPaymentMethod("CreditCard")}
              >
                <label>Cartão de Crédito</label>
              </Radio>
              <Row type="flex" justify="start" gutter={8}>
                {/* Credit Card info */}
                <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Checkbox
                        disabled={paymentMethod !== "CreditCard"}
                        style={{
                          fontFamily: "Asap, sans-serif",
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "16px",
                          lineHeight: "21px",
                          textTransform: "none",
                          color: "#591F74",
                          marginTop: 16,
                        }}
                        checked={differentHolderData}
                        onChange={(e) => {
                          setdifferentHolderData(e.target.checked);
                          if (!e.target.checked) {
                            setCreditCardForm({
                              ...creditCardForm,
                              holder_name: form.fullname,
                              registry_code: form.document,
                            });
                          }
                        }}
                      >
                        Dados do titular do cartão diferentes do cadastro
                      </Checkbox>
                    </Col>
                  </Row>

                  {differentHolderData ? (
                    <>
                      {/* holder_name */}
                      <Row type="flex" justify="start" gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Row>
                            <label
                              className={
                                errors.includes("holder_name") ? "error" : ""
                              }
                            >
                              Nome do titular/portador
                            </label>
                          </Row>
                          <Row>
                            <InputStandard
                              border={
                                errors.includes("holder_name")
                                  ? "error"
                                  : "normal"
                              }
                              className={`input text-uppercase ${
                                paymentMethod !== "CreditCard" ? "disable" : ""
                              }`}
                              width="100%"
                            >
                              <input
                                type="text"
                                placeholder="Informe aqui..."
                                disabled={paymentMethod !== "CreditCard"}
                                name="holder_name"
                                value={creditCardForm?.holder_name}
                                autoComplete="chrome-off"
                                onChange={(event) => {
                                  setErrors((old) =>
                                    old.filter((e) => e !== "holder_name")
                                  );
                                  setCreditCardForm({
                                    ...creditCardForm,
                                    holder_name: event.target.value,
                                  });
                                }}
                              />
                            </InputStandard>
                          </Row>
                        </Col>
                      </Row>
                      {/* registry_code */}
                      <Row type="flex" justify="start" gutter={8}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Row>
                            <label
                              className={
                                errors.includes("registry_code") ? "error" : ""
                              }
                            >
                              CPF
                            </label>
                          </Row>
                          <Row>
                            <InputStandard
                              border={
                                errors.includes("registry_code")
                                  ? "error"
                                  : "normal"
                              }
                              className={`input ${
                                paymentMethod !== "CreditCard" ? "disable" : ""
                              }`}
                              width="100%"
                            >
                              <NumberFormat
                                type="text"
                                disabled={paymentMethod !== "CreditCard"}
                                placeholder="Informe aqui..."
                                name="registry_code"
                                value={creditCardForm?.registry_code}
                                autoComplete="chrome-off"
                                format="###.###.###-##"
                                onChange={(event) => {
                                  setErrors((old) =>
                                    old.filter((e) => e !== "registry_code")
                                  );
                                  setCreditCardForm({
                                    ...creditCardForm,
                                    registry_code: event.target.value,
                                  });
                                }}
                              />
                            </InputStandard>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* card_number */}
                  <Row type="flex" justify="start" gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            errors.includes("card_number") ? "error" : ""
                          }
                        >
                          Número do Cartão
                        </label>
                      </Row>
                      <Row>
                        <InputStandard
                          border={
                            errors.includes("card_number") ? "error" : "normal"
                          }
                          className={`input ${
                            paymentMethod !== "CreditCard" ? "disable" : ""
                          }`}
                          width="100%"
                        >
                          <NumberFormat
                            type="text"
                            disabled={paymentMethod !== "CreditCard"}
                            placeholder="#### #### #### ####"
                            name="card_number"
                            autoComplete="chrome-off"
                            format="#### #### #### ####"
                            value={creditCardForm?.card_number}
                            onChange={(event) => {
                              setCreditCardForm({
                                ...creditCardForm,
                                card_number: event.target.value,
                              });
                              setErrors((old) =>
                                old.filter((e) => e !== "card_number")
                              );
                            }}
                            onBlur={(event) =>
                              handleValidateCreditCardNumber(event.target.value)
                            }
                          />
                          <div className="icon">{creditCardBrand}</div>
                        </InputStandard>
                      </Row>
                    </Col>
                  </Row>
                  {/* card_expiration - card_cvv */}
                  <Row type="flex" justify="start" gutter={8}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Row>
                        <label
                          className={
                            errors.includes("card_expiration") ? "error" : ""
                          }
                        >
                          Data do vencimento
                        </label>
                      </Row>
                      <Row>
                        <InputStandard
                          border={
                            errors.includes("card_expiration")
                              ? "error"
                              : "normal"
                          }
                          className={`input ${
                            paymentMethod !== "CreditCard" ? "disable" : ""
                          }`}
                          width="100%"
                        >
                          <NumberFormat
                            type="text"
                            disabled={paymentMethod !== "CreditCard"}
                            placeholder="MM/AA"
                            name="card_expiration"
                            autoComplete="chrome-off"
                            format="##/##"
                            value={creditCardForm?.card_expiration}
                            onChange={(event) => {
                              setCreditCardForm({
                                ...creditCardForm,
                                card_expiration: event.target.value,
                              });
                              setErrors((old) =>
                                old.filter((e) => e !== "card_expiration")
                              );
                            }}
                            onBlur={(event) =>
                              handleValidateCreditCardExpiration(
                                event.target.value
                              )
                            }
                          />
                        </InputStandard>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Row>
                        <label
                          className={errors.includes("card_cvv") ? "error" : ""}
                        >
                          Código de segurança
                        </label>
                      </Row>
                      <Row>
                        <InputStandard
                          border={
                            errors.includes("card_cvv") ? "error" : "normal"
                          }
                          className={`input ${
                            paymentMethod !== "CreditCard" ? "disable" : ""
                          }`}
                          width="100%"
                        >
                          <NumberFormat
                            type="text"
                            disabled={paymentMethod !== "CreditCard"}
                            placeholder="####"
                            name="card_cvv"
                            autoComplete="chrome-off"
                            format="####"
                            value={creditCardForm?.card_cvv}
                            onChange={(event) => {
                              setCreditCardForm({
                                ...creditCardForm,
                                card_cvv: event.target.value?.trim(),
                              });

                              setErrors((old) =>
                                old.filter((e) => e !== "card_cvv")
                              );
                            }}
                            onBlur={(event) =>
                              handleValidateCreditCardCVV(
                                event.target.value?.trim()
                              )
                            }
                          />
                        </InputStandard>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                {/* Credit Card Component */}
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={10}
                  xl={10}
                  className="card-col"
                >
                  <CreditCard
                    card_number={getOnlyNumber(creditCardForm?.card_number)}
                    card_expiration={creditCardForm?.card_expiration}
                    holder_name={creditCardForm?.holder_name}
                    card_cvv={creditCardForm?.card_cvv}
                    payment_company_code={creditCardForm?.payment_company_code}
                  />
                </Col>
              </Row>
              <Radio
                value="BankSlip"
                selected={paymentMethod}
                onClick={() => handleSelectPaymentMethod("BankSlip")}
              >
                <label>Boleto</label>
              </Radio>
            </CustomRadioGroup>
          </Col>
        </Row>
        <Row type="flex" justify="start" style={{ marginTop: "24px" }}>
          <Col span={24}>
            <span className="title">
              Escolha a data de vencimento da fatura
            </span>
          </Col>
        </Row>
        <Row type="flex" justify="start" style={{ marginTop: "24px" }}>
          <Col span={8}>
            <Select
              placeholder="Selecione uma data"
              style={{ width: "100%" }}
              className={errors.includes("due_day") ? "error" : ""}
              onChange={(day) => handleSelectDueDay(day)}
            >
              {[...Array(31).keys()].map((el) => (
                <Option value={el + 1} key={el}>
                  {" "}
                  Dia {el + 1}{" "}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <FormFooterContaier>
          <button type="button" className="previous" onClick={handleGoBack}>
            Voltar
          </button>
          <button
            type="button"
            disabled={paymentMethod === null}
            onClick={handleSubmitForm}
            className="next"
          >
            Próximo
          </button>
        </FormFooterContaier>
      </FormContainer>
    </Container>
  );
};

export default RegisterStep4;
