import moment from "moment";
import React, { useEffect, useState } from "react";
import { Async } from "react-async";
/** Redux libs */
import { useDispatch, useSelector } from "react-redux";
import { Creators as SaleScenarioActions } from "../../../../../store/ducks/saleScenario";
/** Components */
import { Collapse, Icon, List, Row, Spin, Table, Tag } from "antd";
import CheckIcon from "../../../../../components/utils/icons/check";
import { Container } from "./styles";
/** Services */
import { getDietStrategyShowFromSaleScenario } from "../../../../../services/dietStrategyService";

const TabSelectDietStragies = () => {
  /** Variables */
  const [loadingList, setLoadingList] = useState(true);
  const [dietStragiesSelectedKeys, setDietStratgySelectedKeys] = useState([]);
  /** Redux variables */
  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const {
    newSaleScenario: { saleScenarioLots },
  } = useSelector((state) => state.saleScenario);
  const dispatch = useDispatch();

  /** Methods */
  const rowSelection = {
    selectedRowKeys: dietStragiesSelectedKeys,
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      if (selected) {
        let dietStrategy = record;
        if (dietStrategy != null) {
          dietStrategy = { ...dietStrategy, baseline: record.baseline };
          setDietStratgySelectedKeys((oldDietStragiesSelectedKeys) => [
            ...oldDietStragiesSelectedKeys,
            record.id,
          ]);
          dispatch(
            SaleScenarioActions.addOrRemoveDietStrategies(
              dietStrategy,
              record.newKey,
              "add"
            )
          );
        }
      } else {
        setDietStratgySelectedKeys((oldDietStragiesSelectedKeys) =>
          oldDietStragiesSelectedKeys.filter((r) => r !== record.newKey)
        );
        dispatch(
          SaleScenarioActions.addOrRemoveDietStrategies(
            record,
            record.newKey,
            "remove"
          )
        );
      }
    },
  };

  /** Effects */
  useEffect(() => {
    setTimeout(() => setLoadingList(false), 500);
  }, []);

  useEffect(() => {
    let dsKeys = [];
    saleScenarioLots.forEach((ssl) => {
      if (ssl.dietStrategies.length > 0) {
        dsKeys.push(...ssl.dietStrategies.map((ds) => ds.newKey));
      }
    });
    setDietStratgySelectedKeys(dsKeys);
  }, [saleScenarioLots]);

  return (
    <Container>
      <Spin spinning={loadingList}>
        <List
          itemLayout="horizontal"
          dataSource={saleScenarioLots}
          renderItem={(item) => (
            <List.Item>
              <Collapse
                defaultActiveKey={saleScenarioLots.map((x) => x.lot.id)}
              >
                <Collapse.Panel header={item.lot.name} key={item.lot.id}>
                  {item.lot.dietStrategies == null ||
                  item.lot.dietStrategies.length === 0 ? (
                    <Row type="flex" justify="center">
                      <span>
                        Este lote não possui estratégia de dieta, ou suas
                        estratégias estão vencidas.
                      </span>
                    </Row>
                  ) : (
                    <Table
                      key="recpt"
                      rowKey="newKey"
                      dataSource={
                        item.lot.dietStrategies != null
                          ? item.lot.dietStrategies
                          : []
                      }
                      pagination={false}
                      size="small"
                      rowSelection={rowSelection}
                    >
                      <Table.Column
                        title="Estratégia de dieta"
                        dataIndex="dietStrategyName"
                        key="dietStrategyName"
                        render={(text, record) => (
                          <Async
                            promiseFn={getDietStrategyShowFromSaleScenario}
                            payload={{
                              groupId,
                              farmId,
                              id: record.id,
                            }}
                          >
                            <Async.Pending>
                              <Icon type="loading" />
                            </Async.Pending>
                            <Async.Fulfilled>
                              {(resp) => (
                                <span>
                                  {resp.data?.results != null
                                    ? `${resp.data.results.name}`
                                    : ``}
                                </span>
                              )}
                            </Async.Fulfilled>
                          </Async>
                        )}
                      />
                      <Table.Column
                        title="Período total"
                        key="totalPeriod"
                        dataIndex="totalPeriod"
                        render={(text, record) => (
                          <Async
                            promiseFn={getDietStrategyShowFromSaleScenario}
                            payload={{
                              groupId,
                              farmId,
                              id: record.id,
                            }}
                          >
                            <Async.Pending>
                              <Icon type="loading" />
                            </Async.Pending>
                            <Async.Fulfilled>
                              {(resp) => (
                                <span>
                                  {resp.data.results != null
                                    ? `De ${moment(
                                        resp.data.results.dietPeriods[0]
                                          .startDate
                                      ).format("DD/MM/YYYY")} até ${moment(
                                        resp.data.results.dietPeriods[
                                          resp.data.results.dietPeriods.length -
                                            1
                                        ].endDate
                                      ).format("DD/MM/YYYY")} `
                                    : ``}
                                  {resp.data.results != null
                                    ? moment(
                                        resp.data.results.dietPeriods[
                                          resp.data.results.dietPeriods.length -
                                            1
                                        ].endDate
                                      ).isSameOrBefore(moment()) && (
                                        <Tag color="#d44c4c">Vencida</Tag>
                                      )
                                    : null}
                                </span>
                              )}
                            </Async.Fulfilled>
                          </Async>
                        )}
                      />
                      <Table.Column
                        title="Favorito"
                        dataIndex="baseline"
                        key="baseline"
                        align="center"
                        render={(baseline) =>
                          baseline === true && <CheckIcon />
                        }
                      />
                    </Table>
                  )}
                </Collapse.Panel>
              </Collapse>
            </List.Item>
          )}
        />
      </Spin>
    </Container>
  );
};

export default TabSelectDietStragies;
