import styled from "styled-components";
import { Card } from "antd";

export const CardCustom = styled(Card)`
  margin-top: 13px !important;
  overflow-x: hidden;
  border-radius: 7px !important;
  background: #fff !important;
  box-shadow: 0px 0px 10px #d8d8d8 !important;
  overflow-y: hidden;
  padding: 0px !important;

  .mark {
    font-weight: bold;
  }

  .ant-card-body {
    padding-top: 14px;
  }

  .rowHeader {
    margin-bottom: 17px;
  }

  .filterRow {
    margin-bottom: 16px;
    .ant-row:first-child {
      margin-bottom: 8px;
    }
  }
  .filterLabel {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
  }
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;

export const PageTitle = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 25px;
  text-align: left;
  color: #9074bf;
`;
