import React, { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
//  Services
import { getBreedIndexActive } from "../../services/breedService";
import { getDietStrategyIndex } from "../../services/dietStrategyService";
import { getMarketAnalysisDropDown } from "../../services/marketAnalysisService";

export const BoitelScenarioNewEditContext = createContext(null);

export const BoitelScenarioNewEditContextProvider = ({ children }) => {
  const [breeds, setBreeds] = useState([]);
  const [marketAnalysis, setMarketAnalysis] = useState([]);
  const [allDietStrategies, setAllDietStrategies] = useState([]);

  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  // Get Generic DietStrategies
  useEffect(() => {
    async function getDietStrategies() {
      try {
        const {
          data: { results },
        } = await getDietStrategyIndex({
          groupId,
          farmId,
          withoutPagination: true,
        });
        const filteredDS = results.filter(
          (e) => e.status === "Active" && e.generic === true
        );
        setAllDietStrategies(filteredDS);
      } catch (error) {
        setAllDietStrategies([]);
      }
    }
    getDietStrategies();
  }, [groupId, farmId]);

  // Get MarketAnalysis
  useEffect(() => {
    async function fetchMarketAnalysis() {
      try {
        const {
          data: { results },
        } = await getMarketAnalysisDropDown({ groupId, farmId });
        setMarketAnalysis(results);
      } catch (error) {
        setMarketAnalysis([]);
      }
    }
    fetchMarketAnalysis();
  }, [groupId, farmId]);

  // Get Breeds
  useEffect(() => {
    async function getBreeds() {
      const {
        data: { results: breeds },
      } = await getBreedIndexActive({
        groupId,
        farmId,
        withoutBreedCurveParam: true,
      });
      setBreeds(breeds);
    }
    getBreeds();
  }, [farmId, groupId]);

  return (
    <BoitelScenarioNewEditContext.Provider
      value={{
        breeds,
        marketAnalysis,
        allDietStrategies,
      }}
    >
      {children}
    </BoitelScenarioNewEditContext.Provider>
  );
};
