import styled from "styled-components";
import { Modal } from "antd";

export const Container = styled(Modal)`
  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;

    &.error {
      color: #d44c4c;
    }
  }

  .ant-row > label {
    display: inline-block;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked {
    color: #fff !important;
  }

  .rowFooter {
    margin-top: 16px;
  }

  .colButtonCalc {
    height: 70px;
    align-items: flex-end;
    justify-content: center;
    display: flex;
  }

  .alingMiddle {
    display: flex;
    justify-content: center;
  }
`;

export const InstallmentsContainer = styled.div`
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;

  margin-top: 16px;

  .ant-row-flex {
    padding: 8px 0;
  }
`;
