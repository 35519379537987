import styled from "styled-components";
import { shade } from "polished";

export const Button = styled.button`
  background: ${(props) =>
    props.background ? props.background : "#a9c133"} !important;
  display: flex !important;
  color: ${(props) => (props.color ? props.color : "#ffffff")} !important;
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : "14px"} !important;
  font-weight: 500 !important;
  border: none;
  border-radius: 5px !important;
  /* width: ${(props) => (props.width ? props.width : "150px")} !important;
  height: ${(props) => (props.height ? props.height : "30px")} !important; */
  padding: 5px 20px;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer;
  &:disabled {
    background: #f0f0f0 !important;
    color: #adadad !important;
    border-color: #adadad !important;
    cursor: not-allowed;
  }
  svg {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
`;

export const ButtonTypeOne = styled.button`
  width: ${(props) => {
    switch (props.size) {
      case "xl":
        return "149px";
      case "l":
        return "127px";
      case "m":
        return "121px";
      case "s":
        return "121px";
      case "xs":
        return "97px";
      default:
        return props.width ? props.width : "215px";
    }
  }}!important;
  height: ${(props) => {
    switch (props.size) {
      case "xl":
        return "60px";
      case "l":
        return "50px";
      case "m":
        return "40px";
      case "s":
        return "30px";
      case "xs":
        return "24px";
      default:
        return props.height ? props.height : "56px";
    }
  }} !important;
  border-radius: 30px;
  border: none;
  font-size: ${(props) => {
    switch (props.size) {
      case "xl":
        return "18px";
      case "l":
        return "16px";
      case "m":
        return "16px";
      case "s":
        return "16px";
      case "xs":
        return "14px";
      default:
        return "16px";
    }
  }} !important;
  font-weight: bold !important;
  color: #fff !important;
  padding: ${(props) => (props.padding ? props.padding : 0)} !important;
  background: ${(props) =>
    props.background ? props.background : "#684e94"} !important;
  transition: box-shadow ease 0.2s;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);
  }
  &:active {
    background: ${(props) =>
      props.activeBackground ? props.activeBackground : "#a9c133"} !important;
  }

  &:disabled {
    cursor: not-allowed;
    color: #fff !important;
    background: #e8e5e5 !important;
  }
`;

export const ButtonTypeTwo = styled.button`
  width: ${(props) => {
    switch (props.size) {
      case "xl":
        return "97.5px";
      case "l":
        return "81.25px";
      case "m":
        return "65px";
      case "s":
        return "65px";
      case "xs":
        return "34px";
      default:
        return props.width ? props.width : "97.5px";
    }
  }}!important;
  height: ${(props) => {
    switch (props.size) {
      case "xl":
        return "60px";
      case "l":
        return "50px";
      case "m":
        return "40px";
      case "s":
        return "30px";
      case "xs":
        return "24px";
      default:
        return props.height ? props.height : "60px";
    }
  }} !important;
  border-radius: 9px;
  border: none;
  font-size: ${(props) => {
    switch (props.size) {
      case "xl":
        return "18px";
      case "l":
        return "16px";
      case "m":
        return "16px";
      case "s":
        return "14px";
      case "xs":
        return "14px";
      default:
        return "16px";
    }
  }} !important;
  font-weight: bold !important;
  color: #fff !important;
  background: ${(props) =>
    props.background ? props.background : "#4A85AE"} !important;
  padding: ${(props) => (props.padding ? props.padding : 0)} !important;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);
  }
  &:active {
    background: ${(props) =>
      props.activeBackground ? props.activeBackground : "#a9c133"} !important;
  }

  &:disabled {
    cursor: not-allowed;
    color: #fff !important;
    background: #e8e5e5 !important;
  }
`;

export const ButtonTypeThree = styled.button`
  width: ${(props) => {
    switch (props.size) {
      case "xl":
        return "60px";
      case "l":
        return "50px";
      case "m":
        return "40px";
      case "s":
        return "30px";
      case "xs":
        return "24px";
      default:
        return props.width ? props.width : "97.5px";
    }
  }}!important;
  height: ${(props) => {
    switch (props.size) {
      case "xl":
        return "60px";
      case "l":
        return "50px";
      case "m":
        return "40px";
      case "s":
        return "30px";
      case "xs":
        return "24px";
      default:
        return props.height ? props.height : "60px";
    }
  }} !important;
  border-radius: 30px;
  border: none;
  font-size: ${(props) => {
    switch (props.size) {
      case "xl":
        return "18px";
      case "l":
        return "16px";
      case "m":
        return "16px";
      case "s":
        return "16px";
      case "xs":
        return "14px";
      default:
        return "16px";
    }
  }} !important;
  font-weight: bold !important;
  color: #fff !important;
  background: ${(props) =>
    props.background ? props.background : "#4A85AE"} !important;
  padding: ${(props) => (props.padding ? props.padding : 0)} !important;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);
  }
  &:active {
    background: ${(props) =>
      props.activeBackground ? props.activeBackground : "#a9c133"} !important;
  }

  &:disabled {
    cursor: not-allowed;
    color: #fff !important;
    background: #e8e5e5 !important;
  }
`;

export const ButtonTypeFour = styled.button`
  width: auto;
  height: auto;
  border: none;
  font-size: ${(props) => {
    switch (props.size) {
      case "xl":
        return "18px";
      case "l":
        return "16px";
      case "m":
        return "16px";
      case "s":
        return "16px";
      case "xs":
        return "14px";
      default:
        return "16px";
    }
  }} !important;
  font-weight: bold !important;
  color: #d44c4c !important;
  background: transparent !important;
  padding: ${(props) => (props.padding ? props.padding : 0)} !important;
  &:hover {
    cursor: pointer;
    color: #c6de52 !important;
  }
  &:active {
    color: #c6de52 !important;
  }

  &:disabled {
    cursor: not-allowed;
    color: #fff !important;
    color: #e8e5e5 !important;
  }
`;

export const ButtonTypeFive = styled.button`
  width: ${(props) => (props.width ? props.width : "229px")} !important;
  height: ${(props) => (props.height ? props.height : "50px")} !important;
  border-radius: 30px;
  border: none;
  font-size: 16px !important;
  font-weight: bold !important;
  color: #fff !important;
  background: ${(props) =>
    props.background ? props.background : "#D44C4C"} !important;
  padding: ${(props) => (props.padding ? props.padding : 0)} !important;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);
  }
  &:active {
    background: ${(props) =>
      props.activeBackground ? props.activeBackground : "#a9c133"} !important;
  }

  &:disabled {
    cursor: not-allowed;
    color: #fff !important;
    background: #e8e5e5 !important;
  }
`;

// Button will use in save in drawers
export const ButtonTypeSix = styled.button`
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  background: #684e94;
  box-shadow: 0px 6px 6px rgba(104, 78, 148, 0.3);
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #fff;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);
  }
  &:disabled {
    cursor: not-allowed;
    background: #c4c4c4;
  }
`;
// Button will use in cancel in drawers
export const ButtonTypeSeven = styled.button`
  padding: 5px 20px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #d44c4c;
  font-family: "Asap", sans-serif;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  color: #d44c4c;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);
  }

  &:disabled {
    background: #f0f0f0 !important;
    color: #adadad !important;
    border-color: #adadad !important;
    cursor: not-allowed;
  }
`;

// Button blue in card list
export const ButtonTypeEight = styled.button`
  padding: 5px 20px;
  border-radius: 5px;
  background: #4a85ae;
  border: none;
  box-shadow: 0px 6px 6px rgba(158, 192, 203, 1);
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #fff;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);
  }
  &:disabled {
    cursor: not-allowed;
    background: #c4c4c4;
  }
`;

// Button without background ButtonTypeWithoutBackground
export const ButtonTypeWithoutBackground = styled.button`
  padding: ${(props) => (props.padding ? props.padding : "5px 20px")};
  border-radius: none;
  background: transparent;
  border: none;
  box-shadow: none;
  font-family: Asap;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  text-transform: ${(props) => (props.isUpperCase ? "uppercase" : "none")};
  color: ${(props) => (props.color ? props.color : "#684E94")};
  transition: color 0.2s;

  svg,
  i {
    margin-right: 5px;
  }

  &:hover {
    cursor: pointer;
    color: ${(props) =>
      props.color ? shade(0.4, props.color) : shade(0.4, "#684E94")};
  }
  &:disabled {
    cursor: not-allowed;
    color: #c4c4c4;
  }
`;

// Button without background ButtonTypeWithoutBackgroundWithBorder
export const ButtonTypeWithoutBackgroundWithBorder = styled.button`
  width: ${(props) => {
    switch (props.size) {
      case "xl":
        return "149px";
      case "l":
        return "127px";
      case "m":
        return "121px";
      case "s":
        return "121px";
      case "xs":
        return "97px";
      default:
        return props.width ? props.width : "215px";
    }
  }}!important;
  height: ${(props) => {
    switch (props.size) {
      case "xl":
        return "60px";
      case "l":
        return "50px";
      case "m":
        return "40px";
      case "s":
        return "30px";
      case "xs":
        return "24px";
      default:
        return props.height ? props.height : "56px";
    }
  }};
  border-radius: 30px;
  border: 2px solid #684e94;
  font-size: ${(props) => {
    switch (props.size) {
      case "xl":
        return "18px";
      case "l":
        return "16px";
      case "m":
        return "16px";
      case "s":
        return "16px";
      case "xs":
        return "14px";
      default:
        return "16px";
    }
  }};
  font-weight: bold;
  color: #684e94;
  background: transparent;
  transition: all ease 0.2s;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);
  }

  &:disabled {
    cursor: not-allowed;
    color: #fff;
    background: #e8e5e5;
  }

  &:active {
    color: #fff;
    background: #684e94;
  }
`;

export const ButtonPrincipal = styled.button`
  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  display: flex;
  align-items: center;

  color: #ffffff;

  padding: 8px 16px;

  background: #8a50a5;
  border: 1px solid #8a50a5;
  border-radius: 5px;

  filter: drop-shadow(0px 6px 6px rgba(74, 133, 174, 0.412));

  cursor: pointer;

  &:disabled {
    background: #f0f0f0 !important;
    color: #adadad !important;
    border-color: #adadad !important;
    cursor: not-allowed;
  }

  svg {
    margin: 0 8px;
  }
`;
export const ButtonSecondary = styled.button`
  display: flex;
  align-items: center;

  padding: 8px 16px;

  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #8a50a5;

  background: #ffffff;
  border: 1px solid #8a50a5;
  border-radius: 5px;
  box-sizing: border-box;

  filter: drop-shadow(0px 6px 6px rgba(74, 133, 174, 0.412));

  cursor: pointer;

  &:disabled {
    background: #f0f0f0 !important;
    color: #adadad !important;
    border-color: #adadad !important;
    cursor: not-allowed;
  }

  svg {
    margin: 0 8px;
  }
`;
export const ButtonThirdary = styled.button`
  font-family: "Asap", sans-serif;
  display: flex;

  align-items: center;

  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  color: #ffffff;

  padding: 8px 16px;

  background: #94cf1c;
  border: 1px solid #94cf1c;
  border-radius: 5px;

  filter: drop-shadow(0px 6px 6px rgba(74, 133, 174, 0.412));
  cursor: pointer;

  &:disabled {
    background: #f0f0f0 !important;
    color: #adadad !important;
    border-color: #adadad !important;
    cursor: not-allowed;
  }

  svg {
    margin: 0 8px;
  }
`;

export const ButtonAlternative = styled.button`
  display: flex;
  align-items: center;

  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #94cf1c;

  padding: 8px 16px;

  background: #ffffff;
  border: 1px solid #94cf1c;
  box-sizing: border-box;

  filter: drop-shadow(0px 6px 6px rgba(74, 133, 174, 0.412));
  cursor: pointer;

  &:disabled {
    background: #f0f0f0 !important;
    color: #adadad !important;
    border-color: #adadad !important;
    cursor: not-allowed;
  }

  svg {
    margin: 0 8px;
  }
`;

export const ButtonCancel = styled.button`
  display: flex;
  align-items: center;

  font-family: "Asap", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #d44c4c;

  padding: 8px 16px;

  background: #ffffff;
  border: 1px solid #d44c4c;
  box-sizing: border-box;

  filter: drop-shadow(0px 6px 6px rgba(74, 133, 174, 0.412));
  cursor: pointer;

  &:disabled {
    background: #f0f0f0 !important;
    color: #adadad !important;
    border-color: #adadad !important;
    cursor: not-allowed;
  }

  svg {
    margin: 0 8px;
  }
`;

export const ButtonArrow = styled.button`
  font-family: "Asap", sans-serif;
  font-style: normal;
  background: #ffffff;
  border: none;
  text-align: center;
  width: 24px;
  height: 24px;
  color: #8a50a5;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;
`;
