import React from "react";

// import { Container } from './styles';

const PicketIcon = () => (
  <svg
    id="bovexoPicketIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="24.524"
    height="22.533"
    viewBox="0 0 24.524 22.533"
  >
    <path
      id="icon-piquete"
      d="M23.856,20.157H20.121V8.786h3.734a.669.669,0,0,0,0-1.338H20.088a9.8,9.8,0,0,0-1.272-3.872l-.163-.256a2.136,2.136,0,0,0-1.764-1.087,2.137,2.137,0,0,0-1.764,1.087l-.162.255A9.794,9.794,0,0,0,13.69,7.448H10.835A9.793,9.793,0,0,0,9.564,3.576L9.4,3.32A2.135,2.135,0,0,0,7.637,2.233,2.137,2.137,0,0,0,5.872,3.32l-.162.255A9.786,9.786,0,0,0,4.438,7.447H.669a.669.669,0,0,0,0,1.338H4.4V20.156H.669a.669.669,0,0,0,0,1.338H4.4v.373a2.9,2.9,0,0,0,2.9,2.9h.669a2.9,2.9,0,0,0,2.9-2.9v-.373h2.787v.374a2.9,2.9,0,0,0,2.9,2.9h.669a2.9,2.9,0,0,0,2.9-2.9v-.374h3.734a.669.669,0,1,0,0-1.337ZM9.531,21.868a1.562,1.562,0,0,1-1.561,1.561H7.3a1.562,1.562,0,0,1-1.561-1.561V8.045A8.332,8.332,0,0,1,6.837,4.3L7,4.042a.853.853,0,0,1,.637-.47.853.853,0,0,1,.637.469l.163.256a8.335,8.335,0,0,1,1.1,3.748V21.868Zm1.338-1.711V8.786h2.787V20.157Zm7.915,1.711a1.562,1.562,0,0,1-1.561,1.561h-.669a1.562,1.562,0,0,1-1.561-1.561V8.045a8.332,8.332,0,0,1,1.1-3.749l.162-.255a.854.854,0,0,1,.638-.47.852.852,0,0,1,.637.469l.163.256a8.335,8.335,0,0,1,1.1,3.748V21.868ZM8.528,20.825a.892.892,0,1,1-.892-.892A.892.892,0,0,1,8.528,20.825Zm9.253,0a.892.892,0,1,1-.892-.892A.892.892,0,0,1,17.78,20.825ZM8.528,8.117a.892.892,0,1,1-.892-.892A.892.892,0,0,1,8.528,8.117Zm9.253,0a.892.892,0,1,1-.892-.892A.892.892,0,0,1,17.78,8.117Z"
      transform="translate(0 -2.233)"
      fill="#4b4b4b"
    />
  </svg>
);
export default PicketIcon;
