import React from "react";
import { useSelector } from "react-redux";

import { Col, Row, } from "antd";

import FinancialParametersCostCenterList from "./list";
import FinancialParametersCostCenterForm from "./form";
import CostCenterItemApportionmentForm from "./apportionmentForm"

const FinancialParametersCostCenterLayout = () => {

  const {
    costCenter: { isCostCenterFormVisible, isApportionmentFormVisible },
  } = useSelector((state) => state);

  return (
    <div className="row-of-cards">
      <Row type="flex" className="div-row" justify="start" align="middle" gutter={16}>
        <Col className="card-container" span={!isApportionmentFormVisible ? 8 : 0}>
          <FinancialParametersCostCenterList />
        </Col>
        {isCostCenterFormVisible && !isApportionmentFormVisible ? (
          <Col className="card-container" span={16} >
            <FinancialParametersCostCenterForm />
          </Col>
        ) : null}
        {isApportionmentFormVisible ? (
          <Col className="card-container" span={24} >
          <CostCenterItemApportionmentForm />
        </Col>
        ) : null}
      </Row>
    </div>
  );
}

export default FinancialParametersCostCenterLayout;