import PropTypes from "prop-types";
import React, { Component } from "react";
import { Async } from "react-async";
import { Link, withRouter } from "react-router-dom";

import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Icon,
  Input,
  Menu,
  Modal,
  Row,
  Table,
  Tabs,
  Tooltip,
  notification,
} from "antd";

import moment from "moment";
import "moment/locale/en-nz";
import "moment/locale/es";
import "moment/locale/pt-br";

/* Redux libs */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as AppActions } from "../../store/ducks/app";
import { Creators as PicketActions } from "../../store/ducks/picket";

/** Styles Components */
import { AlertButton, Container, PageTitle, Title } from "./styles";

import ChangePicketToMaintenanceModal from "../../components/modals/picket/maintenance";
import ChangePicketToRenovationModal from "../../components/modals/picket/renovation";
import ConfinementMapDashboard from "./dashboards/confinementMap";
import PastureStatusDashboard from "./dashboards/pastureStatus";
import PicketAreaUtilizationDashboard from "./dashboards/picketAreaUtilization";
import PicketUtilizationCapacityDashboard from "./dashboards/picketUtilizationCapacity";

/** Components */
import PicketIcon from "../../components/admin/sidebar/icons/picket";
import DrawerAllocateLot from "../../components/drawers/picket/allocateLot";
import DrawerDetailPicket from "../../components/drawers/picket/detail";
import AlertDateIcon from "../../components/utils/icons/alertDate";
import ArrowDownWhite from "../../components/utils/icons/arrows/green/down";
import MenuVerticalIcon from "../../components/utils/icons/menuVertical";
import MenuIcon from "../../components/utils/table/icons/menu";
import { Container as MenuContainer } from "../../components/utils/table/menu/styles";
import TagStatus from "../../components/utils/tagStatus";

import ButtonStandard from "../../components/utils/button";

// Pages
import Retreat from "./retreat";

/** Services */
import { ConfinementMapDashboardContextProvider } from "../../contexts/confinementMapDashboardContext";
import { showAlertsByDateAndEntity } from "../../services/alertService";
import {
  deletePicket,
  getPicketStatisticsAsync,
  inactivatePicket,
  putPicketInBreak,
  reactivatePicket,
} from "../../services/picketService";
import { getListOfProfitCenterIndex } from "../../services/profitCenterService";

class Picket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      sortedInfo: null,
      filteredInfo: null,
      paginationInfo: null,
      success: false,
      showModalMaintenance: false,
      showModalRenovation: false,
      showDrawerDismemberPicket: true,
      picketId: null,
      picketData: null,
      alerts: [],
      alertsFilterDate: moment(),
      tabSelected: "1",
      shouldShowDashboardsExtensive: false,
      shouldShowDashboardsIntensive: false,
      isVisibleDashboards: true,
    };
  }

  static propTypes = {
    picketActions: PropTypes.any.isRequired,
    app: PropTypes.any.isRequired,
    picket: PropTypes.any.isRequired,
  };

  fetchData = async (
    groupSelected,
    farmSelected,
    page = 0,
    sorter = {},
    filters = "",
    size = 10,
    ids = null
  ) => {
    const { picketActions } = this.props;
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;

    await picketActions.indexPicket(
      groupId,
      farmId,
      page,
      sorter,
      filters,
      size,
      ids
    );
  };

  fetchActivities = async (groupSelected, farmSelected) => {
    try {
      const { id: groupId } = groupSelected;
      const { id: farmId, confinement } = farmSelected;
      const {
        data: { results: listActivities },
      } = await getListOfProfitCenterIndex({
        groupId,
        farmId,
      });
      let enableDashboardsExtensive = true;
      let enableDashboardsIntensive = true;
      if (
        listActivities.filter(
          (activity) =>
            activity.productionSubSystem === "Extensive" ||
            activity.productionSubSystem === "SemiIntensive"
        ).length > 0
      ) {
        enableDashboardsExtensive = true;
      } else {
        enableDashboardsExtensive = false;
      }

      if (
        listActivities.filter(
          (activity) => activity.productionSubSystem === "Intensive"
        ).length > 0 &&
        confinement
      ) {
        enableDashboardsIntensive = true;
      } else {
        enableDashboardsIntensive = false;
      }
      this.setState({
        shouldShowDashboardsExtensive: enableDashboardsExtensive,
        shouldShowDashboardsIntensive: enableDashboardsIntensive,
      });
    } catch (error) {}
  };

  openNotificationError = (title, message) => {
    notification["error"]({
      message: title,
      description: message,
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
            width: 90,
            marginRight: 8,
            background: "#684e94",
            borderColor: "none",
            border: "none",
          }}
        />
        <Button
          onClick={() => this.handleReset(clearFilters)}
          icon="delete"
          size="small"
          style={{ width: 90 }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#684e94" : undefined }} />
    ),
  });

  getAlerts = async (groupSelected, farmSelected, date, loadPage) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;
    const {
      app: { alertSelected },
    } = this.props;
    let selectedDate = date.format("YYYY-MM-DD");

    if (loadPage) {
      if (alertSelected != null && alertSelected.entity === "picket") {
        if (alertSelected.alertPage) {
          selectedDate = alertSelected.executionDate;
          let ids = "'" + alertSelected.entityIds.join("','") + "'";
          this.fetchData(groupSelected, farmSelected, 0, {}, "", 10, ids);
          alertSelected.alertPage = false;
        } else {
          const { appActions } = this.props;
          appActions.setAlertSelected({});
        }
      }
    } else if (alertSelected?.id != null) {
      const { appActions } = this.props;
      appActions.setAlertSelected({});
      this.fetchData(groupSelected, farmSelected, 0, {}, "", 10, null);
    }
    const executionDate = selectedDate;

    const entity = "picket";
    try {
      const {
        data: { results: alerts },
      } = await showAlertsByDateAndEntity({
        groupId,
        farmId,
        executionDate,
        entity,
        signal: this.signal,
      });
      this.setState({ alerts, alertsFilterDate: selectedDate });
    } catch (error) {
      this.setState({ alerts: null, alertsFilterDate: selectedDate });
    }
  };

  handleAlertSelected = (alert) => {
    const {
      app: { groupSelected, farmSelected, alertSelected },
    } = this.props;
    const { appActions } = this.props;
    let ids = null;
    if (
      alertSelected &&
      alertSelected.id === alert.id &&
      alertSelected.failed === alert.failed
    ) {
      appActions.setAlertSelected({});
    } else {
      appActions.setAlertSelected(alert);
      ids = "'" + alert.entityIds.join("','") + "'";
    }
    this.fetchData(groupSelected, farmSelected, 0, {}, "", 10, ids);
  };

  openModal = (modal, id) => {
    const {
      app: {
        translation,
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      picketActions,
    } = this.props;

    switch (modal) {
      case "Maintenance":
        this.setState({
          showModalMaintenance: true,
          picketId: id,
        });
        break;

      case "Renovation":
        this.setState({
          showModalMaintenance: false,
          showModalRenovation: true,
          picketId: id,
        });
        break;

      case "Break":
        Modal.confirm({
          centered: true,
          title: translation.picket.drawerBreakTitle,
          okText: translation.buttons.yes,
          cancelText: translation.buttons.cancel,
          async onOk() {
            await putPicketInBreak({
              groupId,
              farmId,
              id,
            }).then((response) => {
              picketActions.updatePicketInList(id, response.data.results);
            });
          },
          onCancel() {},
        });
        break;
      case "Inactivate":
        Modal.confirm({
          centered: true,
          title: translation.picket.drawerInactivate,
          okText: translation.buttons.yes,
          cancelText: translation.buttons.cancel,
          async onOk() {
            await inactivatePicket({
              groupId,
              farmId,
              id,
            }).then((response) => {
              picketActions.updatePicketInList(id, response.data.results);
            });
          },
          onCancel() {},
        });
        break;
      case "Reactivate":
        Modal.confirm({
          centered: true,
          title: translation.picket.drawerReactivate,
          okText: translation.buttons.yes,
          cancelText: translation.buttons.cancel,
          async onOk() {
            await reactivatePicket({
              groupId,
              farmId,
              id,
            }).then((response) => {
              picketActions.updatePicketInList(id, response.data.results);
            });
          },
          onCancel() {},
        });
        break;
      case "Delete":
        Modal.confirm({
          centered: true,
          title: translation.picket.drawerDelete,
          okText: translation.buttons.yes,
          cancelText: translation.buttons.cancel,
          async onOk() {
            return Promise.all([
              await deletePicket({
                groupId,
                farmId,
                id,
              }),
            ]).then((response) => {
              picketActions.indexPicket(groupId, farmId);
            });
          },
          onCancel() {},
        });
        break;
      default:
        break;
    }
  };

  openDrawer = (drawer, picket) => {
    switch (drawer) {
      case "DismemberPicket":
        this.setState({ showDrawerDismemberPicket: true, picketData: picket });
        break;
      default:
        break;
    }
  };

  closeDrawerDismemberPicket = (success = false) => {
    this.setState({
      showDrawerDismemberPicket: false,
      picketData: null,
    });
    if (success) {
      const {
        app: { groupSelected, farmSelected },
      } = this.props;
      this.fetchData(groupSelected, farmSelected);
    }
  };

  closeModal = (success = false) => {
    this.setState({
      showModalMaintenance: false,
      showModalRenovation: false,
      picketId: null,
    });
    if (success) {
      const {
        app: { groupSelected, farmSelected },
      } = this.props;
      this.fetchData(groupSelected, farmSelected);
    }
  };

  handleTableChange = async (pagination, filters, sorter) => {
    this.setState({
      paginationInfo: pagination,
      sortedInfo: sorter,
      filteredInfo: filters,
    });
    const {
      app: { groupSelected, farmSelected },
    } = this.props;

    let search = "";

    if (Object.entries(filters).length > 0) {
      Object.entries(filters).forEach(([k, v]) => {
        if (v.length > 0)
          if (k === "status") {
            if (v.includes("D")) v.push("X");
            search =
              search === ""
                ? `${k} in (${v.map((o) => (o = `'${o}'`)).join(", ")})`
                : `${search};${k} in (${v
                    .map((o) => (o = `'${o}'`))
                    .join(", ")})`;
          } else {
            var lower = v.toString().toLowerCase();
            search =
              search === ""
                ? `upper(${k}) like upper('%25${lower}%25')`
                : `${search};upper(${k}) like upper('%25${lower}%25')`;
          }
      });
    }
    await this.fetchData(
      groupSelected,
      farmSelected,
      pagination.current,
      sorter,
      search,
      pagination.pageSize
    );
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  handleReset = (clearFilters) => {
    clearFilters();
  };

  handleEdit = async (id) => {
    const { picketActions } = this.props;
    picketActions.showDrawer("edit", id);
    // await Promise.all([
    //   picketActions.showPicket(groupId, farmId, id),
    // ]);
  };

  handleDetails = async (id) => {
    const { picketActions } = this.props;
    picketActions.showDrawerDetailPicket(id);
    // await Promise.all([
    //   picketActions.showPicket(groupId, farmId, id),
    // ]);
  };

  handleAllocateLot = async (id) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      picketActions,
    } = this.props;
    await Promise.all([
      picketActions.showPicket(groupId, farmId, id),
      picketActions.showDrawerAllocateLot(),
    ]);
  };

  handlePutPicketInBreak = async (id) => {};

  handleTabChange = (newValue) => {
    this.setState({ tabSelected: newValue });
  };

  expandedRows = (record, index, indent, expanded) => {
    const {
      app: {
        translation,
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;

    const columns = [
      {
        title: translation.picket.table.columns.amountAnimals,
        dataIndex: "currentAmountAnimals",
        key: "currentAmountAnimals",
        render: (text, record) => (
          <Async
            promiseFn={getPicketStatisticsAsync}
            payload={{
              groupId,
              farmId,
              id: record.key,
              statistics: "AmountActiveAnimals",
            }}
          >
            <Async.Pending>
              <Icon type="loading" />
            </Async.Pending>
            <Async.Fulfilled>
              {(resp) => {
                record.currentAmountAnimals = resp.data.results;
                return <span>{`${resp.data.results || 0} animais`}</span>;
              }}
            </Async.Fulfilled>
          </Async>
        ),
      },
      {
        title: translation.picket.table.columns.gmd,
        dataIndex: "avgGmdWeight",
        key: "avgGmdWeight",
        render: (text, record) => (
          <Async
            promiseFn={getPicketStatisticsAsync}
            payload={{
              groupId,
              farmId,
              id: record.key,
              statistics: "AvgGDPActiveAnimals",
            }}
          >
            <Async.Pending>
              <Icon type="loading" />
            </Async.Pending>
            <Async.Fulfilled>
              {(resp) => (
                <span>{`${resp.data.results?.toFixed(2) || 0} Kg`}</span>
              )}
            </Async.Fulfilled>
          </Async>
        ),
      },
      {
        title: translation.picket.table.columns.averageWeight,
        dataIndex: "avgDailyWeight",
        key: "avgDailyWeight",
        render: (text, record) => (
          <Async
            promiseFn={getPicketStatisticsAsync}
            payload={{
              groupId,
              farmId,
              id: record.key,
              statistics: "AvgWeightActiveAnimals",
            }}
          >
            <Async.Pending>
              <Icon type="loading" />
            </Async.Pending>
            <Async.Fulfilled>
              {(resp) => (
                <span>{`${resp.data.results?.toFixed(2) || 0} Kg`}</span>
              )}
            </Async.Fulfilled>
          </Async>
        ),
      },
    ];

    let columnData = [
      {
        key: record.id,
        currentAmountAnimals: null,
        avgGmdWeight: null,
        avgDailyWeight: null,
      },
    ];

    return (
      <Table columns={columns} dataSource={columnData} pagination={false} />
    );
  };

  async componentDidMount() {
    const {
      app: { groupSelected, farmSelected },
    } = this.props;
    if (
      Object.entries(groupSelected).length > 0 &&
      Object.entries(farmSelected).length > 0
    ) {
      this.fetchData(groupSelected, farmSelected);
      this.fetchActivities(groupSelected, farmSelected);
      this.getAlerts(groupSelected, farmSelected, moment(), true);
    }
  }

  async shouldComponentUpdate(nextProps) {
    const differentGroup =
      this.props.app.groupSelected?.id !== nextProps.app.groupSelected?.id;
    const differentFarm =
      this.props.app.farmSelected?.id !== nextProps.app.farmSelected?.id;
    if (differentGroup || differentFarm) {
      if (
        Object.entries(nextProps.app.groupSelected).length > 0 &&
        Object.entries(nextProps.app.farmSelected).length > 0
      ) {
        await this.fetchData(
          nextProps.app.groupSelected,
          nextProps.app.farmSelected
        );
        this.getAlerts(
          nextProps.app.groupSelected,
          nextProps.app.farmSelected,
          moment(),
          true
        );
      }
    }
    return true;
  }

  render() {
    const {
      picket: { data, isLoading },
      app: {
        translation,
        languageSelected,
        groupSelected,
        farmSelected,
        alertSelected,
      },
      picketActions,
    } = this.props;

    const { Column } = Table;

    const {
      showModalMaintenance,
      showModalRenovation,
      shouldShowDashboardsExtensive,
      shouldShowDashboardsIntensive,
      picketId,
      alerts,
      alertsFilterDate,
      tabSelected,
      isVisibleDashboards,
    } = this.state;

    const menu = (id, picket) => {
      const {
        app: { translation },
      } = this.props;
      return (
        <Menu>
          <Menu.Item key="0" onClick={() => this.handleEdit(id)}>
            {translation.table.menu.edit}
          </Menu.Item>
          <Menu.Item key="1" onClick={() => this.handleDetails(id)}>
            {translation.table.menu.details}
          </Menu.Item>
          {(picket.status === "Maintenance" ||
            picket.status === "Renovation") && (
            <Menu.Item key="3" onClick={() => this.openModal("Break", id)}>
              {translation.table.menu.putPicketInBreak}
            </Menu.Item>
          )}
          {picket.status === "Break" && (
            <Menu.Item
              key="5"
              onClick={() => this.openModal("Maintenance", id)}
            >
              {translation.table.menu.putPicketInMaintenance}
            </Menu.Item>
          )}
          {(picket.status === "Break" || picket.status === "Maintenance") && (
            <Menu.Item key="6" onClick={() => this.openModal("Renovation", id)}>
              {translation.table.menu.putPicketInRenovation}
            </Menu.Item>
          )}
          {picket.status === "Break" && (
            <Menu.Item key="7" onClick={() => this.openModal("Inactivate", id)}>
              {translation.table.menu.inactivate}
            </Menu.Item>
          )}
          {picket.status === "Inactive" && (
            <Menu.Item key="8" onClick={() => this.openModal("Reactivate", id)}>
              {translation.table.menu.activate}
            </Menu.Item>
          )}
          {picket.numberLotHistory === 0 &&
            picket.numberStatusHistory === 1 && (
              <Menu.Item key="9" onClick={() => this.openModal("Delete", id)}>
                {translation.table.menu.delete}
              </Menu.Item>
            )}
        </Menu>
      );
    };

    return (
      <Container>
        {/* Header */}
        <Row type="flex" style={{ marginBottom: 16 }}>
          <Col span={24}>
            <PageTitle>
              <PicketIcon />
              <span>{translation.sidebar.picket}</span>
            </PageTitle>
          </Col>
        </Row>
        <Tabs
          type="card"
          activeKey={tabSelected}
          onChange={(key) => this.handleTabChange(key)}
        >
          <Tabs.TabPane
            tab={<span>{translation.picket.tabs.retreat}</span>}
            key="0"
          >
            <Retreat />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<span>{translation.picket.tabs.picket}</span>}
            key="1"
          >
            <Row type="flex" justify="end" style={{ marginTop: 10 }}>
              <Col>
                <ButtonStandard
                  type="button"
                  buttonType="secondary"
                  onClick={() =>
                    this.setState({
                      isVisibleDashboards: !isVisibleDashboards,
                    })
                  }
                >
                  {isVisibleDashboards ? (
                    <>
                      <Icon type="fullscreen-exit" /> Ocultar painéis
                    </>
                  ) : (
                    <>
                      <Icon type="fullscreen" /> Exibir painéis
                    </>
                  )}
                </ButtonStandard>
              </Col>
            </Row>
            {/* DASHSBOARDS Extensive*/}
            {isVisibleDashboards && (
              <>
                <Row type="flex" gutter={16}>
                  <Col
                    xs={24}
                    sm={shouldShowDashboardsExtensive ? 16 : 24}
                    md={shouldShowDashboardsExtensive ? 12 : 24}
                    lg={shouldShowDashboardsExtensive ? 12 : 24}
                    xl={shouldShowDashboardsExtensive ? 12 : 24}
                  >
                    <PicketUtilizationCapacityDashboard />
                  </Col>
                  {shouldShowDashboardsExtensive && (
                    <Col xs={24} sm={16} md={12} lg={12} xl={12}>
                      <PicketAreaUtilizationDashboard />
                    </Col>
                  )}
                  {shouldShowDashboardsExtensive && (
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <PastureStatusDashboard />
                    </Col>
                  )}
                  {shouldShowDashboardsIntensive && (
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <ConfinementMapDashboardContextProvider>
                        <ConfinementMapDashboard />
                      </ConfinementMapDashboardContextProvider>
                    </Col>
                  )}
                </Row>
              </>
            )}
            {/* Alerts */}
            <Row
              type="flex"
              align="middle"
              style={{ paddingLeft: "20px", marginTop: "10px" }}
            >
              <AlertDateIcon />
              <DatePicker
                name="alertsFilterDate"
                allowClear={false}
                bordered={false}
                suffixIcon={
                  <ArrowDownWhite
                    color="#D44C4C"
                    style={{ color: "#D44C4C" }}
                  />
                }
                className="alertDate"
                value={
                  alertsFilterDate == null
                    ? moment()
                    : moment(alertsFilterDate, "YYYY-MM-DD")
                }
                placeholder={translation.defaultDatePickerPlaceholder}
                format="DD/MM/YYYY"
                onChange={(date, dateString) => {
                  if (date <= moment() && date !== null) {
                    this.getAlerts(groupSelected, farmSelected, date, false);
                  }
                }}
              />
              {alerts?.length <= 0 ? (
                <span style={{ color: "#D44C4C", marginLeft: "10px" }}>
                  {" "}
                  Nenhum alerta de piquete / baia nessa data
                </span>
              ) : (
                alerts?.map((alert) => (
                  <Tooltip title={alert.tooltip} key={alert.id}>
                    <AlertButton
                      className={
                        alert.id === alertSelected?.id &&
                        alertSelected?.failed === alert.failed
                          ? "alertSelected"
                          : ""
                      }
                      onClick={() => this.handleAlertSelected(alert)}
                    >
                      {" "}
                      <div
                        dangerouslySetInnerHTML={{ __html: alert.button }}
                        style={{ display: "inline" }}
                      />{" "}
                      <MenuVerticalIcon />{" "}
                    </AlertButton>
                  </Tooltip>
                ))
              )}
            </Row>
            {/* GRID */}
            <Row type="flex" className="gridRow">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ height: "100%" }}
              >
                <Card className="cardGrid">
                  <Row
                    type="flex"
                    justify="space-between"
                    style={{ marginBottom: "19px" }}
                  >
                    <Col>
                      <Title>{translation.picket.title}</Title>
                    </Col>
                    <Col>
                      <ButtonStandard
                        buttonType="type8"
                        onClick={() => picketActions.showDrawer("new")}
                      >
                        {translation.picket.buttonNewPicket}
                      </ButtonStandard>
                    </Col>
                  </Row>
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Table
                        rowKey="id"
                        loading={isLoading}
                        dataSource={data != null ? data.content : []}
                        pagination={{
                          total: data != null ? data.totalElements : 0,
                          size: data != null ? data.size : 0,
                          current: data != null ? data.number + 1 : 0,
                          hideOnSinglePage:
                            data !== null && Object.entries(data).length !== 0
                              ? data.totalElements > 10
                                ? false
                                : true
                              : true,
                          showSizeChanger: true,
                          pageSizeOptions: [
                            "10",
                            "20",
                            "30",
                            "100",
                            "500",
                            "1000",
                            "2000",
                          ],
                        }}
                        size="small"
                        scroll={{
                          x: true,
                        }}
                        expandedRowRender={this.expandedRows}
                        onChange={this.handleTableChange}
                      >
                        <Column
                          title={translation.picket.table.columns.picketName}
                          dataIndex="name"
                          sorter
                          key="name"
                          align="left"
                          {...this.getColumnSearchProps("name")}
                        />
                        <Column
                          title={translation.picket.table.columns.propertyType}
                          dataIndex="propertyType"
                          sorter
                          key="propertyType"
                          align="left"
                          filtered
                          filters={[
                            {
                              text: translation.picket.propertyType.own,
                              value: "O",
                            },
                            {
                              text: translation.picket.propertyType.foreign,
                              value: "F",
                            },
                          ]}
                          filterMultiple={false}
                          render={(text, record) => (
                            <span>
                              {record.propertyType === "Own"
                                ? translation.picket.propertyType.own
                                : translation.picket.propertyType.foreign}
                            </span>
                          )}
                        />
                        <Column
                          title={
                            translation.picket.table.columns.profitCenterName
                          }
                          dataIndex="profitCenterName"
                          key="profitCenterName"
                          align="left"
                          sorter
                          {...this.getColumnSearchProps("profitCenterName")}
                        />

                        <Column
                          title={translation.picket.table.columns.status}
                          dataIndex="status"
                          key="status"
                          align="left"
                          filtered
                          filters={[
                            {
                              text: translation.picket.status.break,
                              value: "B",
                            },
                            {
                              text: translation.picket.status.pasturage,
                              value: "P",
                            },
                            {
                              text: translation.picket.status.renovation,
                              value: "R",
                            },
                            {
                              text: translation.picket.status.maintenance,
                              value: "M",
                            },
                            {
                              text: translation.picket.status.leased,
                              value: "L",
                            },
                            {
                              text: `${translation.status.inactive}`,
                              value: "I",
                            },
                          ]}
                          filterMultiple
                          sorter
                          render={(status, record) => {
                            return status === "Break" ? (
                              <TagStatus
                                background="#E4F4FF"
                                borderColor="#4A85AE"
                                color="#4A85AE"
                              >
                                {translation.picket.status.break}
                              </TagStatus>
                            ) : status === "Pasturage" ? (
                              <TagStatus
                                background="#E1FFE4"
                                borderColor="#106518"
                                color="#106518"
                              >
                                {record.productionSubSystem === "I"
                                  ? translation.picket.status.active
                                  : translation.picket.status.pasturage}
                              </TagStatus>
                            ) : status === "Renovation" ? (
                              <TagStatus
                                background="#FFEAE1"
                                borderColor="#FF4E00"
                                color="#FF4E00"
                              >
                                {translation.picket.status.renovation}
                              </TagStatus>
                            ) : status === "Maintenance" ? (
                              <TagStatus
                                background="#FCEEEC"
                                borderColor="#D44C4C"
                                color="#D44C4C"
                              >
                                {translation.picket.status.maintenance}
                              </TagStatus>
                            ) : status === "Leased" ? (
                              <TagStatus background="#FE8D2A">
                                {translation.picket.status.leased}
                              </TagStatus>
                            ) : status === "FarmExclude" ? (
                              <TagStatus
                                background="#FBC7C7"
                                borderColor="#D44C4C"
                                color="#D44C4C"
                              >
                                {translation.status.inactive}
                              </TagStatus>
                            ) : status === "Inactive" ? (
                              <TagStatus
                                background="#FBC7C7"
                                borderColor="#D44C4C"
                                color="#D44C4C"
                              >
                                {translation.status.inactive}
                              </TagStatus>
                            ) : (
                              <TagStatus
                                background="#FBC7C7"
                                borderColor="#D44C4C"
                                color="#D44C4C"
                              >
                                {translation.picket.status.deleted}
                              </TagStatus>
                            );
                          }}
                        />
                        <Column
                          title={translation.picket.table.columns.lotName}
                          dataIndex="lotName"
                          key="lotName"
                          align="left"
                          sorter
                          {...this.getColumnSearchProps("lotName")}
                          render={(text, record) => (
                            <Link
                              to={`/admin/lots/${record.lotId}`}
                              className="link"
                            >
                              <span>{record.lotName}</span>
                            </Link>
                          )}
                        />
                        <Column
                          title={translation.picket.table.columns.inAndOutLot}
                          dataIndex="lotDateIn"
                          key="lotDateIn"
                          align="left"
                          sorter
                          render={(text, record) => (
                            <span>
                              {`${
                                record.lotDateIn !== null &&
                                record.lotId !== null
                                  ? languageSelected === "en"
                                    ? `${moment(record.lotDateIn).format(
                                        "MM/DD/YYYY"
                                      )} - ${moment().diff(
                                        record.lotDateIn,
                                        "days"
                                      )} dias`
                                    : `${moment(record.lotDateIn).format(
                                        "DD/MM/YYYY"
                                      )} - ${moment().diff(
                                        record.lotDateIn,
                                        "days"
                                      )} dias`
                                  : ""
                              }`}
                            </span>
                          )}
                        />
                        <Column
                          align="left"
                          render={(text, record) => (
                            <Dropdown
                              overlay={menu(record.id, record)}
                              trigger={["click"]}
                              key={record.id}
                            >
                              <MenuContainer>
                                <MenuIcon />
                              </MenuContainer>
                            </Dropdown>
                          )}
                        />
                      </Table>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            {/* Modals */}
            <ChangePicketToMaintenanceModal
              visible={showModalMaintenance}
              picketId={picketId}
              onClose={this.closeModal}
            />
            <ChangePicketToRenovationModal
              visible={showModalRenovation}
              picketId={picketId}
              onClose={this.closeModal}
            />
            {/* Drawers */}
            <DrawerAllocateLot />
            {/* <DrawerDismemberPicket
          picket={picketData}
          visible={showDrawerDismemberPicket}
          onClose={this.closeDrawerDismemberPicket}
        /> */}
            <DrawerDetailPicket />
          </Tabs.TabPane>
        </Tabs>
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  app: state.app,
  picket: state.picket,
});

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(AppActions, dispatch),
  picketActions: bindActionCreators(PicketActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Picket));
