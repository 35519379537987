import React, { Fragment } from "react";
import CanvasJSReact from "../../../../assets/canvasjs.react";

const { CanvasJSChart } = CanvasJSReact;

const BarBenchmarkChart = ({ benchmarkDashboard, currentValue }) => {
  const options = {
    height: 200,
    dataPointWidth: 45,
    axisY: {
      valueFormatString: "0.00",
      includeZero: true,
      gridDashType: "dot",
      maximum: benchmarkDashboard?.reduce(
        (max, p) => (p.y > max ? p.y : max),
        currentValue != null ? currentValue + 0.2 : 0
      ),
      stripLines: [
        {
          value: currentValue != null ? currentValue?.toFixed(2) : 0,
          color: "#4B4B4B",
          thickness: 3,
          labelPlacement: "inside",
          labelFontColor: "#4B4B4B",
          labelAlign: "far",
          showOnTop: false,
          label: `Minha fazenda: ${
            currentValue != null ? currentValue?.toFixed(2) : 0
          }`,
        },
      ],
    },
    toolTip: {
      enabled: true, //enable here
    },
    data: [
      {
        type: "column",
        yValueFormatString: "0.00",
        indexLabel: "{y}",
        indexLabelFontSize: 12,
        indexLabelFontColor: "#FFF",
        indexLabelPlacement: "inside",
        dataPoints: benchmarkDashboard,
      },
    ],
  };
  return (
    <Fragment>
      <div className="chartDiv">
        <CanvasJSChart options={options} />
      </div>
    </Fragment>
  );
};

export default BarBenchmarkChart;
