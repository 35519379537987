import styled from "styled-components";
import { Switch, Divider, Popconfirm, Modal } from "antd";

export const PeriodModal = styled(Modal)`
  .ant-modal-body {
    padding-bottom: 0px !important;
  }
`;

export const Container = styled.div`
  .update {
    color: #4a85ae;
    margin-top: 20px;
    cursor: pointer;
  }
  .bold {
    margin-left: 7px;
    font-weight: bold;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  span {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    color: #4b4b4b;
  }
`;

export const Title = styled.span`
  display: flex;
  align-items: center;
  font-family: Asap;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  text-align: left;

  &.water {
    color: #568db3;
  }

  &.transition {
    color: #fe8d2a;
  }

  &.dry {
    color: #d44c4c;
  }

  svg {
    margin-right: 5px;
  }

  &:not(:first-of-type) {
    margin-left: 5px;
  }
`;

export const SubTitle = styled.span`
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  &.water {
    color: #568db3;
  }

  &.transition {
    color: #fe8d2a;
  }

  &.dry {
    color: #d44c4c;
  }
  &::before {
    content: " ";
    width: 1px;
    height: 19px;
    background: #a5a5a5;
    margin: 0px 9px;
  }
`;

export const Body = styled.div`
  margin-top: 10px;
  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;

    &.error {
      color: #d44c4c;
    }

    &.alert {
      color: #fe8d2a;
    }

    svg {
      margin-left: 5px;
    }
  }

  div.seasonContainer {
    display: flex;

    svg {
      width: 23px;
      height: 23px;
      &:not(:first-of-type) {
        margin-left: 5px;
      }
    }
  }

  .ant-row.rowInput {
    margin-top: 8px;
  }
  .ant-row-flex.rowInput {
    margin-top: 8px;
  }
`;

export const Footer = styled.div`
  margin-top: 20px;
  div.buttonContainer {
    display: flex;
  }
`;

export const ButtonNavigation = styled.button`
  display: flex;
  height: 25px;
  align-items: center;
  font-family: Asap;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  color: #684e94;
  border: none;
  background: transparent;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    margin: 0 5px;
    color: #684e94;
  }

  &.remove {
    color: #d44c4c;
    margin-left: 15px;
    svg {
      color: #d44c4c;
    }
  }

  &:hover {
    &.save {
      border-bottom: 1px solid #684e94;
    }
    &.remove {
      border-bottom: 1px solid #d44c4c;
      &:disabled {
        border-bottom: 1px solid #a5a5a5;
      }
    }
  }

  &:disabled {
    color: #a5a5a5;
    cursor: not-allowed;
    svg {
      path {
        fill: #a5a5a5;
      }
    }
  }
`;

export const LabelSwitch = styled.span`
  font-family: Asap;
  font-weight: normal;
  font-style: italic;
  font-size: 12px;
  text-align: right;
  color: #a9c133;

  &.disabled {
    color: #a5a5a5;
  }

  .ant-switch {
    margin-left: 10px;
  }
`;

export const CustomSwitch = styled(Switch)`
  &.ant-switch {
    width: 29px !important;
    min-width: 29px !important;
    height: 7px !important;
  }
  &.ant-switch.ant-switch-checked {
    background-color: #a9c133 !important;
  }

  .ant-switch-loading-icon,
  &.ant-switch-checked::after {
    left: 110% !important;
    border: 1px solid #a9c133 !important;
    /* top: -42%; */
    margin-left: -1px;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  .ant-switch-loading-icon,
  &.ant-switch::after {
    position: absolute;
    top: -4px;
    left: -2px;
    width: 15px;
    height: 15px;
    background-color: #4b4b4b;
    border-radius: 15px;
    cursor: pointer;
    -webkit-transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content: " ";
  }
`;
export const CustomDivider = styled(Divider)`
  margin: 10px 0 10px 0 !important;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 10px 0 10px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;

export const MessageCard = styled.div`
  width: 100%;
  border-radius: 6px;
  background: transparent;
  border: 1px dashed #fe8d2a;
  margin: 10px 0px;

  .header {
    padding: 10px 5px 10px 5px;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;

    span.title {
      font-family: Asap;
      font-weight: bold;
      font-size: 14px;
      text-align: left;
      color: #fe8d2a;
      text-align: center;
      svg {
        margin-right: 15px;
        g {
          path {
            fill: #fe8d2a !important;
            color: #fe8d2a !important;
          }
        }
      }
    }

    span.date {
      font-family: Asap;
      font-weight: normal;
      font-style: italic;
      font-size: 14px;
      text-align: right;
      color: #d44c4c;
    }
  }

  .discription {
    margin: 12px 10px;
    font-family: Asap;
    font-weight: normal;
    font-style: italic;
    font-size: 14px;
    text-align: left;
    color: #4b4b4b;
  }
`;

export const NutritionalPastureBox = styled.div`
  border-radius: 5px;
  background: #fcfcfc;
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;

  span.title {
    font-family: Asap;
    font-weight: bold;
    font-size: 12px;
    text-align: left;
    color: #a5a5a5;
  }

  .colInfo {
    display: flex;
    justify-content: flex-end;
  }

  div.groupInfo {
    width: auto;
    &:first-of-type {
      margin-right: 15px;
    }
  }

  label {
    font-family: Asap;
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    color: #a5a5a5;
  }

  strong {
    font-family: Asap;
    font-size: 12px;
    text-align: left;
    color: #a5a5a5;
    margin-left: 5px;
  }
`;

export const NutritionalSupplementBox = styled.div`
  border-radius: 5px;
  background: #fcfcfc;
  margin-top: 5px;
  padding-left: 15px;
  padding-right: 15px;

  .title {
    font-family: Asap;
    font-weight: bold;
    font-size: 12px;
    text-align: left;
    color: #a5a5a5;
    margin-top: 5px;

    svg {
      margin-left: 5px;
    }
  }

  .ant-col {
    min-height: 77px;
  }

  .rowInfo {
    display: flex;
    justify-content: flex-end;
  }

  .colWithBorder {
    border-left: 1px solid #c4c4c4;
    padding-left: 10px;
  }

  div.groupInfo {
    width: auto;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
    &:first-of-type {
      margin-right: 10px;
    }
  }

  p {
    font-family: Asap;
    font-weight: normal;
    font-style: italic;
    font-size: 12px;
    text-align: left;
    color: #a5a5a5;
    margin: 0px;

    &.error {
      color: #d44c4c;
    }
  }

  label {
    font-family: Asap;
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    margin-left: 0px;
    color: #a5a5a5;
  }

  strong {
    font-family: Asap;
    font-size: 12px;
    text-align: left;
    color: #a5a5a5;
  }
`;

export const NutritionalGdpValidationBox = styled.div`
  border-radius: 5px;
  background: #fcfcfc;
  margin-top: 5px;
  padding-left: 15px;
  padding-right: 15px;

  .title {
    font-family: Asap;
    font-weight: bold;
    font-size: 12px;
    text-align: left;
    color: #a5a5a5;
    margin-top: 5px;

    svg {
      margin-left: 5px;
    }
  }

  .ant-row-flex + .ant-row-flex {
    margin-bottom: 5px;
  }

  .rowInfo {
    display: flex;
    justify-content: flex-end;
  }

  .colWithBorder {
    border-left: 1px solid #c4c4c4;
    padding-left: 10px;
  }

  div.groupInfo {
    width: auto;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
    &:first-of-type {
      margin-right: 30px;
    }
  }

  p {
    font-family: Asap;
    font-weight: normal;
    font-style: italic;
    font-size: 12px;
    text-align: left;
    color: #a5a5a5;
    margin: 0px;

    &.error {
      color: #d44c4c;
    }
  }

  label {
    font-family: Asap;
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    margin-left: 0px;
    color: #a5a5a5;
  }

  strong {
    font-family: Asap;
    font-size: 12px;
    text-align: left;
    color: #a5a5a5;
  }
`;

export const GdpModal = styled(Modal)`
  padding-bottom: 0px !important;
`;

export const ContainerGdpModal = styled.div`
  .colTitle {
    display: flex !important;
    align-items: center !important;
    svg {
      margin-right: 8px;
    }
  }
  .footer {
    svg {
      margin-left: 8px;
    }
  }
  div.boxConsume {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    padding: 0px 10px;
    background: #fcfcfc;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span.message {
      font-family: Asap;
      font-weight: normal;
      font-size: 12px;
      text-align: left;
      color: #707070;
    }
    span.messageValue {
      font-family: Asap;
      font-weight: bold;
      font-size: 12px;
      color: #707070;
    }
  }
`;

export const TitleGDPModal = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: ${(prop) =>
    prop.status && prop.status === "OK" ? "#684e94" : "#d44c4c"};
`;

export const PopConfirmCustom = styled(Popconfirm)`
  &.ant-popover-buttons {
    display: flex !important;
    justify-content: space-between !important;
  }

  &.ant-btn-primary {
    padding-left: 180px !important;
    background: #4a85ae;
  }
`;

export const SubTitleGDPModal = styled.div`
  padding-left: 48px;
  span {
    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #a5a5a5;

    &.black {
      color: #4b4b4b;
    }
  }
`;

export const BodyCardGDPModal = styled.div`
  min-height: 95px;
  margin: 20px 0px;
  padding: 15px 20px;
  background: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);

  .col:not(:first-of-type) {
    margin-left: 50px;
  }

  div.groupValues {
    display: flex;
    flex-direction: column;

    div.realWeightGain {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 0px;
      svg {
        margin-left: 5px;
      }
    }
    label {
      font-family: Asap, sans-serif;
      font-weight: normal;
      text-align: left;
      font-size: 14px;
      color: #4b4b4b;

      &.value {
        font-size: 20px;
        color: #a5a5a5;

        #iconExOrMiss {
          margin-right: 5px;
        }

        &.realWeightGain {
          padding: 5px 5px;
          min-width: 95px;
          text-align: center;
          border-radius: 2px;
          background: #d44c4c;
          color: #fff;
        }
        &.strong {
          font-weight: bold;
          color: #4b4b4b;
          padding: 5px 0px;
        }
        &.necessary {
          font-weight: bold;
          font-size: 12px;
          color: #a9c133;
        }
        &.consumed {
          font-weight: bold;
          font-size: 12px;
          color: #4a85ae;
        }
      }

      &:not(:last-of-type) {
        margin-bottom: 4px;
      }
    }
  }
`;
export const BodyFooterGDPModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  background: #f5f5f5;
  padding: 5px 20px;

  .colTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    label {
      font-family: Asap, sans-serif;
      font-weight: normal;
      text-align: left;
      font-size: 14px;
      color: #684e94 !important;
    }
  }

  label {
    font-family: Asap, sans-serif;
    font-weight: normal;
    text-align: left;
    font-size: 14px;
    color: #4b4b4b;
  }

  strong {
    margin-left: 5px;
    font-family: Asap, sans-serif;
    font-size: 16px;
    color: #4b4b4b;

    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
`;
