import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import moment from "moment";
import { useSelector } from "react-redux";
import * as Yup from "yup";

// Components
import {
  Col,
  DatePicker,
  Input,
  Modal,
  Row,
  Spin,
  Switch,
  notification,
} from "antd";
import useFinancialProjectContext from "../../../hooks/useFinancialProjectContext";
import ButtonStandard from "../../utils/button";
import { Container, Footer } from "./styles";

// Services

import { findAll, save } from "../../../services/financialProjectService";

const validationSchema = Yup.object({
  name: Yup.string().trim().max(30).required(),
  description: Yup.string().notRequired().nullable(),
  status: Yup.string().required(),
  startProjectDate: Yup.string().required(),
  finishProjectDate: Yup.string()
    .notRequired()
    .when(["id", "status"], {
      is: (id, status) => id !== null && status === "Inactive",
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired().nullable(),
    }),
});

const DrawerFinancialProject = () => {
  const formRef = useRef(null);

  const [form, setForm] = useState({
    id: null,
    name: null,
    description: null,
    startProjectDate: null,
    finishProjectDate: null,
    status: "Active",
  });
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  // Redux Variable
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const { isDrawerVisible, data, closeDrawer, fetchData } =
    useFinancialProjectContext();

  useEffect(() => {
    if (data !== null) {
      setForm(data);
    }
  }, [data]);

  // Method

  function handleCloseDrawer() {
    setForm({
      id: null,
      name: null,
      description: null,
      startProjectDate: null,
      finishProjectDate: null,
      status: "Active",
    });
    formRef.current.resetForm({
      id: null,
      name: null,
      description: null,
      startProjectDate: null,
      finishProjectDate: null,
      status: "Active",
    });
    closeDrawer();
  }

  function handleSubmitForm(values) {
    handleSave(values);
  }

  async function handleSave(body) {
    setIsLoadingRequest(true);
    try {
      const {
        data: { results: financialProjectsList },
      } = await findAll({
        groupId,
        farmId,
      });
      if (
        financialProjectsList.filter(
          (financialProject) => financialProject.name === body.name
        ).length > 0
      ) {
        notification.error({
          message: "Atenção! Já existe um projeto cadastrado com este nome.",
        });
        return;
      }
      await save({ groupId, farmId, id: body?.id, body });
      notification.success({
        message: "Projeto Financeiro cadastrado/atualizado com sucesso.",
      });
      fetchData();
      handleCloseDrawer();
    } catch (error) {
      notification.error({
        message: "Erro",
        description: "Erro ao cadastrar/editar projeto financeiro.",
      });
    } finally {
      setIsLoadingRequest(false);
    }
  }

  function handleDrawerVisible(visible) {
    if (visible) {
      setForm(
        data
          ? data
          : {
              id: null,
              name: null,
              description: null,
              startProjectDate: null,
              finishProjectDate: null,
              status: "Active",
            }
      );
      formRef.current.resetForm(
        data
          ? data
          : {
              id: null,
              name: null,
              description: null,
              startProjectDate: null,
              finishProjectDate: null,
              status: "Active",
            }
      );
    }
  }

  return (
    <Container
      title={
        data?.id == null
          ? translation.financialProject.form.titleCreateNew
          : translation.financialProject.form.titleEdit
      }
      width={700}
      onClose={handleCloseDrawer}
      maskClosable={false}
      visible={isDrawerVisible}
      afterVisibleChange={handleDrawerVisible}
    >
      <Spin spinning={isLoadingRequest}>
        <Formik
          ref={formRef}
          enableReinitialize={true}
          initialValues={form}
          onSubmit={handleSubmitForm}
          validationSchema={validationSchema}
          render={({
            values,
            errors,
            submitCount,
            setFieldValue,
            handleSubmit,
          }) => (
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className="drawerForm">
                {Object.entries(errors).length > 0 && submitCount > 0 && (
                  <Row type="flex" justify="center" align="middle">
                    <label className="error">
                      {translation.error.formError}
                    </label>
                  </Row>
                )}
                {/* Name */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={errors.name && submitCount > 0 ? "error" : ""}
                  >
                    {translation.financialProject.form.name}*
                  </label>
                </Row>
                <Row type="flex">
                  <Input
                    name="name"
                    maxLength={30}
                    max={30}
                    value={values.name}
                    placeholder={translation.defaultPlaceholder}
                    autoCapitalize="off"
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                  />
                </Row>
                {/* startProjectDate */}
                <Row type="flex" className="rowLabel">
                  <label
                    className={
                      errors.startProjectDate && submitCount > 0 ? "error" : ""
                    }
                  >
                    {translation.financialProject.form.startProjectDate}*
                  </label>
                </Row>
                <Row type="flex">
                  <DatePicker
                    name="startProjectDate"
                    value={
                      values.startProjectDate
                        ? moment(values.startProjectDate)
                        : undefined
                    }
                    format={"DD/MM/YYYY"}
                    disabledDate={(current) => current.isAfter(moment())}
                    onChange={(date) => setFieldValue("startProjectDate", date)}
                  />
                </Row>
                {/* Description */}
                <Row type="flex" className="rowLabel">
                  <label>{translation.financialProject.form.description}</label>
                </Row>
                <Row type="flex">
                  <Input
                    name="description"
                    maxLength={30}
                    max={30}
                    value={values.description}
                    placeholder={translation.defaultPlaceholder}
                    autoCapitalize="off"
                    onChange={(e) => {
                      setFieldValue("description", e.target.value);
                    }}
                  />
                </Row>
                {values.id !== null && (
                  <Row type="flex">
                    <Col span={6}>
                      {/* Status */}
                      <Row type="flex" className="rowLabel">
                        <label>
                          {translation.financialProject.form.status}
                        </label>
                      </Row>
                      <Row type="flex">
                        <Switch
                          checked={values.status === "Active"}
                          onChange={(checked) => {
                            setFieldValue(
                              "status",
                              checked ? "Active" : "Inactive"
                            );
                            if (checked) {
                              setFieldValue("finishProjectDate", null);
                            }
                          }}
                        />
                      </Row>
                    </Col>
                    {values.status === "Inactive" && (
                      <Col span={6}>
                        {/* finishProjectDate */}
                        <Row type="flex" className="rowLabel">
                          <label
                            className={
                              errors.finishProjectDate && submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            {
                              translation.financialProject.form
                                .finishProjectDate
                            }
                            *
                          </label>
                        </Row>
                        <Row type="flex">
                          <DatePicker
                            name="finishProjectDate"
                            value={
                              values.finishProjectDate
                                ? moment(values.finishProjectDate)
                                : undefined
                            }
                            format={"DD/MM/YYYY"}
                            disabledDate={(current) =>
                              current.isBefore(moment(values.startProjectDate))
                            }
                            onChange={(date) =>
                              setFieldValue("finishProjectDate", date)
                            }
                          />
                        </Row>
                      </Col>
                    )}
                  </Row>
                )}
              </div>
              <Footer>
                <Row type="flex">
                  <Col span={24} className="buttonsDiv">
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={closeDrawer}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard type="submit" buttonType="type6">
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
      </Spin>
    </Container>
  );
};

export default DrawerFinancialProject;
