import React, { useState, useEffect } from "react";

/** Redux Libs */
import { useSelector, useDispatch } from "react-redux";
import { Creators as GeneralParameterActions } from "../../../store/ducks/generalParameters";
/** Components */
import { Container, Title, Message, CardMessage, EditButton } from "./styles";
import { Row, Col, Spin, notification } from "antd";
import EditKPIs from "./edit";
import DisplayKPIs from "./display";
import PencilIcon from "../../../components/utils/icons/pencil";

/* Services */
import {
  showKPIGoalForParameters,
  showKPIsHarvests,
} from "../../../services/kpiService";

const KPIsParameter = () => {
  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [emptyValue, setEmptyValue] = useState(true);
  const [kpiGoalList, setKpiGoalList] = useState([]);
  const [kpiHarvestList, setKpiHarvestList] = useState([]);
  const [selectedHarvest, setSelectedHarvest] = useState(null);

  const { parameterBeingEdit } = useSelector(
    (state) => state.generalParameters
  );

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const {
          data: { results: kpiHarvestList },
        } = await showKPIsHarvests({ groupId, farmId });

        setKpiHarvestList(kpiHarvestList);

        let selHarvest = kpiHarvestList.find((harvest) => harvest.current);

        setSelectedHarvest(selHarvest);

        let harvestId = selHarvest?.id;

        const {
          data: { results: kpiGoalList },
        } = await showKPIGoalForParameters({ groupId, farmId, harvestId });

        setKpiGoalList(kpiGoalList);

        if (Object.keys(kpiGoalList).length !== 0) {
          setEmptyValue(false);
        }
        setIsLoading(false);
      } catch (ex) {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [groupId, farmId]);

  function cancelEdit() {
    dispatch(GeneralParameterActions.changeParameterBeingEdited(null));
    setEditing(false);
  }

  const saveEdit = async () => {
    cancelEdit();
    setIsLoading(true);
    try {
      const harvestId = selectedHarvest?.id;
      const {
        data: { results: kpiGoalList },
      } = await showKPIGoalForParameters({ groupId, farmId, harvestId });

      setKpiGoalList(kpiGoalList);

      if (Object.keys(kpiGoalList).length !== 0) {
        setEmptyValue(false);
      }

      setIsLoading(false);
    } catch (ex) {
      setIsLoading(false);
      notification.error({ message: "Ocorreu um erro ao carregar os dados" });
    }
  };

  function openEdit() {
    dispatch(GeneralParameterActions.changeParameterBeingEdited("kpis"));
    setEditing(true);
  }

  async function handleHarvestChange(harvestId) {
    let selHarvest = kpiHarvestList.find((harvest) => harvest.id === harvestId);
    setSelectedHarvest(selHarvest);
    setIsLoading(true);
    try {
      let harvestId = selHarvest?.id;
      const {
        data: { results: kpiGoalList },
      } = await showKPIGoalForParameters({ groupId, farmId, harvestId });

      setKpiGoalList(kpiGoalList);

      if (Object.keys(kpiGoalList).length !== 0) {
        setEmptyValue(false);
      }

      setIsLoading(false);
    } catch (ex) {
      setIsLoading(false);
      notification.error({
        message: "Ocorreu um erro ao carregar os dados da Safra selecionada",
      });
    }
  }

  return (
    <Container>
      <Row type="flex" justify="space-between">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Title>Meta de resultados por safra - Indicadores (KPIs) </Title>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} align="right">
          <EditButton
            type="button"
            onClick={openEdit}
            disabled={
              !selectedHarvest?.current ||
              isEditing ||
              (parameterBeingEdit !== null && parameterBeingEdit !== "kpis")
            }
          >
            {isEditing
              ? `${translation.buttons.editing}...`
              : `${translation.buttons.edit}`}
            <PencilIcon />
          </EditButton>
        </Col>
      </Row>
      {isEditing ? (
        <EditKPIs
          onSave={saveEdit}
          onCancel={cancelEdit}
          kpiGoalList={kpiGoalList}
        />
      ) : (
        <Spin spinning={isLoading}>
          {emptyValue ? (
            <CardMessage>
              <Row type="flex" justify="space-between" align="middle">
                <Message>
                  Defina por safra as{" "}
                  <span className="red">
                    metas de Lotação, Produção, Desfrute, GDP, Custo e Margem
                  </span>
                </Message>
              </Row>
            </CardMessage>
          ) : (
            <DisplayKPIs
              kpiGoalList={kpiGoalList}
              kpiHarvestList={kpiHarvestList}
              onHarvestChange={handleHarvestChange}
              selectedHarvest={selectedHarvest}
            />
          )}
        </Spin>
      )}
    </Container>
  );
};

export default KPIsParameter;
