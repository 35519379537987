import styled from "styled-components";
import { Drawer } from "antd";

export const Container = styled(Drawer)`
  .ant-drawer-body {
    padding: 24px 0px 10px 0px;
  }

  .rowLabel {
    margin-bottom: 7px;
    margin-top: 19px;
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-left: 15px;
    margin-bottom: 7px;
    margin-top: 19px;

    &.error {
      color: #d44c4c;
    }
  }

  .ant-input {
    margin-top: 6px;
  }

  .ant-select {
    margin-top: 6px;
  }

  .ant-radio-group {
    margin-top: 6px;
  }

  .tableButtonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      display: flex;
      justify-content: center !important;
      text-align: center !important;
      align-items: center !important;
      font-size: 14px !important;
      width: 77px !important;
      height: 30px !important;

      svg {
        margin-right: 3px !important;
      }
    }

    button:first-of-type {
      width: 116px !important;
      margin-right: 12px !important;
    }
  }

  .ant-switch-checked {
    background-color: #a9c133 !important;
  }

  .drawerForm {
    width: 100%;
    padding: 0px 24px;
    min-height: -moz-calc(100vh - 165px);
    min-height: -webkit-calc(100vh - 165px);
    min-height: calc(100vh - 165px);

    .personal-image {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .personal-image input[type="file"] {
      display: none;
    }
    .personal-figure {
      position: relative;
      width: 400px;
      height: 120px;
      border-radius: 25px;
      background-color: transparent;
      border: 2px dashed #4b4b4b;
      border-spacing: 2px;
    }
    .personal-avatar {
      cursor: pointer;
      width: 395px;
      height: 115px;
      box-sizing: border-box;
      border-radius: 25px;
      border: 2px solid transparent;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
      transition: all ease-in-out 0.3s;
    }
    .personal-avatar:hover {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    }
    .personal-figcaption,
    .personal-figcaption-with-image {
      cursor: pointer;
      position: absolute;
      top: 0px;
      width: 395px;
      height: 115px;
      border-radius: 25px;
      border: 2px solid transparent;
      opacity: 1;
      background-color: rgba(0, 0, 0, 0);
      transition: all ease-in-out 0.3s;
    }
    .personal-figcaption:hover,
    .personal-figcaption-with-image:hover {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .personal-figcaption-with-image:hover > img {
      visibility: visible;
      margin-top: 32.5px;
      width: 50px;
      height: 50px;
    }
    .personal-figcaption-with-image > img {
      visibility: hidden;
    }
    .personal-figcaption > img {
      margin-top: 32.5px;
      width: 50px;
      height: 50px;
    }
  }
`;

export const AnimalsAmount = styled.div`
  height: 35px;
  border-radius: 17.5px;
  background: transparent;
  border: 1px solid #d44c4c;
  padding: 0 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-family: "Asap", sans-serif;
    font-size: 14px;
    color: #d44c4c;
  }

  span.number {
    font-size: 20px;
  }

  &.haveAnimals {
    background: #4a85ae;
    border: 1px solid #4a85ae;
    span {
      color: #fff;
    }

    span.number {
      font-size: 20px;
    }
  }
`;

export const Footer = styled.div`
  border-top: 1px solid #e8e8e8;
  margin-top: 20px;
  width: 100%;
  padding: 10px 24px;
  border-radius: 0 0 4px 4px;

  .buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding-right: 24px; */

    button:first-of-type {
      margin-right: 12px !important;
    }
  }

  .ant-row-flex {
    margin: 0;
  }

  .selectDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: "Asap", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;

export const Table = styled.table`
  width: 100% !important;
  min-width: 100% !important;
  margin-top: 10px;
  tbody {
    tr {
      background: #f5f5f5;
      td {
        padding-top: 10px;
        padding-bottom: 10px;
        font-family: Asap;
        font-weight: normal;
        font-size: 12px;

        color: #4b4b4b;

        &:first-child {
          text-align: left;
          padding-left: 15px;
        }
        &:last-child {
          width: 120px;
          padding-right: 15px;
          text-align: right;
        }

        span {
          font-family: Asap;
          font-weight: bold;
          font-size: 12px;
          text-align: center;
          color: #a5a5a5;
          display: flex;
          align-items: center;
          &.success {
            color: #41c36f;
          }
          svg {
            margin-right: 5px;
          }
        }

        div.buttonGroup {
          display: flex;
          align-items: flex-end;
        }

        button {
          border: none;
          background: transparent;
          font-family: Asap;
          font-weight: normal;
          font-size: 12px;
          display: flex;

          &.add {
            align-items: center;
            color: #4a85ae;
            #bovexoPlusBlueIcon {
              margin-left: 5px;
            }

            &:disabled {
              cursor: not-allowed;
              color: #a5a5a5;
            }
          }

          &.edit {
            color: #9386aa;
            svg {
              margin-left: 5px;
              path {
                fill: #9386aa !important;
              }
            }
          }

          &:hover {
            cursor: pointer;
          }

          &.delete {
            color: #d44c4c;
            svg {
              margin-left: 0px;
              path {
                fill: #d44c4c !important;
              }
            }

            &:before {
              content: "|";
              color: #a5a5a5;
              margin-left: 10px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
`;