import apiManagement from "../config/api_management";
import getHeaders from "./getHeaders";

export function checkAccountFarmService(payload) {
  const { groupId, farmId } = payload;
  return apiManagement.get(
    `/bovexo/${groupId}/farms/${farmId}/accounts/farms/check`,
    getHeaders(false, null)
  );
}

export function renewExpirationAccountFarmService(payload) {
  const { groupId, farmId } = payload;
  return apiManagement.put(
    `/bovexo/${groupId}/farms/${farmId}/accounts/farms/renew/expiration`,
    null,
    getHeaders(false, null)
  );
}

export function accountFarmContractBovExoService(payload) {
  const { groupId, farmId } = payload;
  return apiManagement.put(
    `/bovexo/${groupId}/farms/${farmId}/accounts/farms/contract`,
    null,
    getHeaders(false, null)
  );
}

export function updateAccountFarmTrialToContract(payload) {
  const { groupId, farmId, body } = payload;
  return apiManagement.put(
    `/bovexo/${groupId}/farms/${farmId}/accounts/farms/update/contract`,
    body,
    getHeaders(false, null)
  );
}
