import getHeaders from "../getHeaders";
import apiCommons from "../../config/api_commons";

const DECISION_ENTITY = "decision";

export function getDecisionMarketAnalysisDashboard(payload) {
  const { groupId, farmId, dashboardParams, signal } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/${DECISION_ENTITY}/dashboards/DecisionMarketAnalysisDashboard`,
    dashboardParams,
    getHeaders(false, signal)
  );
}

export function getDecisionProfitAndCostDashboard(payload) {
  const { groupId, farmId, dashboardParams, signal } = payload;

  return apiCommons.post(
    `/bovexo/${groupId}/farms/${farmId}/${DECISION_ENTITY}/dashboards/DecisionProfitAndCostDashboard`,
    dashboardParams,
    getHeaders(false, signal)
  );
}
