import React, { useState } from "react";
import { useCallback } from "react";
import { createContext } from "react";
import { useSelector } from "react-redux";
import { findAll } from "../../services/veterinaryService";

export const VeterinaryContext = createContext({
  dataList: [],
  isLoadingDataList: false,
  data: null,
  isDrawerVisible: false,
});

export const VeterinaryContextProvider = ({ children }) => {
  const TYPES = [
    { key: "Vacina", name: "Vacina" },
    { key: "Vermífugo", name: "Vermífugo" },
    { key: "Medicamento", name: "Medicamento" },
  ];
  const GENDER_APPLICATION_TYPES = [
    { key: "Male", name: "Macho" },
    { key: "Female", name: "Fêmea" },
    { key: "Both", name: "Ambos" },
  ];
  const APPLICATION_TYPES = [
    { key: "Fixo", name: "Fixo" },
    { key: "PorPeso", name: "Por Peso" },
  ];

  const [dataList, setDataList] = useState([]);
  const [data, setData] = useState(null);
  const [isLoadingDataList, setIsLoadingDataList] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const {
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const fetchData = useCallback(async () => {
    setIsLoadingDataList(true);
    try {
      const {
        data: { results },
      } = await findAll({ groupId, farmId });
      setDataList(results);
    } catch (error) {
    } finally {
      setIsLoadingDataList(false);
    }
  }, [farmId, groupId]);

  const openDrawer = useCallback((data = null) => {
    if (data) setData(data);
    setIsDrawerVisible(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setData(null);
    setIsDrawerVisible(false);
  }, []);

  return (
    <VeterinaryContext.Provider
      value={{
        dataList,
        fetchData,
        data,
        isLoadingDataList,
        isDrawerVisible,
        openDrawer,
        closeDrawer,
        TYPES,
        GENDER_APPLICATION_TYPES,
        APPLICATION_TYPES,
      }}
    >
      {children}
    </VeterinaryContext.Provider>
  );
};
