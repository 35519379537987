import { isAuthenticated } from "../services/authService";

const sessionExpiredMiddleware = (store) => (next) => (action) => {
  if (
    !action.type.includes("app") &&
    !action.type.includes("RESET_DATA") &&
    !action.type.includes("SHOW_DRAWER_REGISTER") &&
    !action.type.includes("HIDE_DRAWER_REGISTER") &&
    !action.type.includes("SHOW_DRAWER_CHANGE_PASSWORD") &&
    !action.type.includes("HIDE_DRAWER_CHANGE_PASSWORD")
  ) {
    isAuthenticated().then((result) => {
      if (result === "almostExpired") {
        return store.dispatch({
          type: "app/SHOW_ALMOST_EXPIRED",
          payload: {},
        });
      }
      if (result === "expired") {
        return store.dispatch({
          type: "app/SESSION_EXPIRED",
          payload: {},
        });
      }
    });
  }

  next(action);
};

export default sessionExpiredMiddleware;
