import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useSelector } from "react-redux";
import { withRouter, Link, useParams } from "react-router-dom";

import { Collapse, Row, Col, Table, List } from "antd";

import { Container, HeaderContainer, Title } from "./styles";
import ArrowActiveIcon from "./icons/arrow/active";
import ArrowInactiveIcon from "./icons/arrow/inactive";
import BreadCrumbs from "../../../../components/utils/breadCrumbs";

import { getImportLog } from "../../../../services/importService";

const SupplementImportDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [importLogData, setImportLogData] = useState(null);
  const [importItensData, setImportItensData] = useState([]);

  const customPanelStyle = {
    borderRadius: 7,
    background: "#fff",
    boxShadow: "0px 0px 10px #d8d8d8",
    marginBottom: 15,
  };

  const Panel = Collapse.Panel;
  const Column = Table.Column;

  const Header = (status, amount) => (
    <HeaderContainer>
      <div className="title">
        <span>
          {status === "Imported"
            ? "Importados"
            : status === "Updated"
            ? "Atualizados"
            : "Não Importados"}
        </span>
      </div>
      <div className="amountAnimals">
        <span>{`${amount} ${translation.imports.supplement.details.collapseAnimals}`}</span>
      </div>
    </HeaderContainer>
  );

  const { id: importLogId } = useParams();

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  useEffect(() => {
    const signal = Axios.CancelToken.source();
    function compare(a, b) {
      let comparison = 0;
      if (a.rowNumber > b.rowNumber) {
        comparison = 1;
      } else if (a.rowNumber < b.rowNumber) {
        comparison = -1;
      }
      return comparison;
    }
    async function fetch() {
      setIsLoading(true);
      try {
        const {
          data: { results },
        } = await getImportLog({ groupId, farmId, id: importLogId, signal });
        setImportLogData(results);
        let importedData = results.supplements
          .filter((x) => x.success && !x.updated)
          .sort(compare);
        let notImportedData = results.supplements
          .filter((x) => !x.success)
          .sort(compare);
        let updatedData = results.supplements
          .filter((x) => x.updated)
          .sort(compare);

        setImportItensData([
          {
            status: "Imported",
            total: results.numberImported + results.numberDuplicated,
            data: importedData,
          },
          {
            status: "Not Imported",
            total: results.numberError,
            data: notImportedData,
          },
          {
            status: "Updated",
            total: results.numberUpdated,
            data: updatedData,
          },
        ]);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }

    fetch();

    return () => {
      signal.cancel("Cancelando apis");
    };
  }, [farmId, groupId, importLogId]);

  return (
    <Container>
      <BreadCrumbs marginBottom="16px">
        <Link to="/admin/imports?tabSelect=3">
          <span className="pageTreeInative">
            {translation.imports.supplement.details.breadCumbsImportLog}
          </span>
        </Link>
        <span className="pageTreeActive">
          {translation.imports.supplement.details.breadCumbsImportLogDetails}
        </span>
      </BreadCrumbs>
      <Row type="flex" justify="space-between" style={{ marginBottom: "19px" }}>
        <Col>
          <Title>{translation.imports.supplement.details.statusTitle}: </Title>
          {importLogData != null
            ? importLogData.status === "Processed"
              ? translation.imports.status.processed
              : importLogData.status === "Queue"
              ? translation.imports.status.queue
              : importLogData.status === "Processing"
              ? translation.imports.status.processing
              : importLogData.status === "Error"
              ? translation.imports.status.error
              : null
            : null}
        </Col>
        <Col>
          <Title>
            {translation.imports.supplement.details.fileNameTitle}:{" "}
          </Title>
          {importLogData != null ? importLogData.fileName : null}
        </Col>
        <Col>
          <Title>{translation.imports.supplement.details.dateTitle}: </Title>
          {importLogData != null ? importLogData.date : null}
        </Col>
      </Row>
      <List
        itemLayout="vertical"
        loading={isLoading}
        size="large"
        dataSource={importItensData != null ? importItensData : []}
        renderItem={(item) => (
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) =>
              isActive ? <ArrowActiveIcon /> : <ArrowInactiveIcon />
            }
          >
            <Panel
              header={Header(item.status, item.total)}
              key={item.id}
              style={customPanelStyle}
            >
              <Row type="flex" justify="start">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Table
                    rowKey="id"
                    dataSource={item.data}
                    pagination={true}
                    size="small"
                  >
                    <Column
                      title={
                        translation.imports.supplement.details.table.columns
                          .rowNumber
                      }
                      dataIndex="rowNumber"
                      key="rowNumber"
                      align="left"
                    />
                    {(item.status === "Imported" ||
                      item.status === "Updated") && (
                      <Column
                        title={
                          translation.imports.supplement.details.table.columns
                            .name
                        }
                        dataIndex="entity.name"
                        key="entity.name"
                        align="left"
                      />
                    )}
                    {item.status === "Not Imported" ? (
                      <Column
                        title={
                          translation.imports.supplement.details.table.columns
                            .message
                        }
                        align="left"
                        render={(text, record) =>
                          record.errors != null ? (
                            <ol>
                              {record.errors.map((e) => (
                                <li>{e}</li>
                              ))}
                            </ol>
                          ) : null
                        }
                      />
                    ) : null}
                  </Table>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        )}
      />
    </Container>
  );
};

export default withRouter(SupplementImportDetails);
