// import axios from "axios";
import apiAnimals from "../config/api_animals";
import apiFinancial from "../config/api_financial";
import apiOptimization from "../config/api_optimization";
import getHeaders from "./getHeaders";

export function callAnimalIndex(payload) {
  const { groupId, farmId, page, sorter, filters, size, signal, ids } = payload;

  const orderS = sorter && sorter.order === "descend" ? "DESC" : "ASC";

  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/animals/list?
    ${page ? `&page=${page - 1}` : ""}
    ${size && `&size=${size}`}
    ${
      sorter && Object.entries(sorter).length > 0
        ? `&sort=${sorter.field}&direction=${orderS}`
        : ""
    }
    ${filters ? `&search=${filters}` : ""}`,
    ids != null ? ids : null,
    getHeaders(false, signal)
  );
}

export function callAnimalIndexReproductionEvents(payload) {
  const { groupId, farmId, year, page, sorter, filters, size, signal, ids } =
    payload;

  const orderS = sorter && sorter.order === "descend" ? "DESC" : "ASC";

  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/animals/list/reproduction/events?
    ${year ? `&year=${year}` : ""}
    ${page ? `&page=${page - 1}` : ""}
    ${size && `&size=${size}`}
    ${
      sorter && Object.entries(sorter).length > 0
        ? `&sort=${sorter.field}&direction=${orderS}`
        : ""
    }
    ${filters ? `&search=${filters}` : ""}`,
    ids != null ? ids : null,
    getHeaders(false, signal)
  );
}

export function getActiveAnimals(payload) {
  const { groupId, farmId, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals?dashboard=activeAnimalsDashboard`,
    getHeaders(false, signal)
  );
}

export function getAnimalsGroupByGender(payload) {
  const { groupId, farmId, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals?dashboard=genderDashboard`,
    getHeaders(false, signal)
  );
}

export function getAnimalsByIdentificationHandlingNumber(payload) {
  const { groupId, farmId, identification, specificGender, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/identifications?identification=${identification}&identificationType=HandlingNumber${
      specificGender ? `&specificGender=${specificGender}` : ""
    }`,
    getHeaders(false, signal)
  );
}

export function getNumberAnimalsFatten(payload) {
  const { groupId, farmId, signal } = payload;

  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/animals/list?dashboard=genderFattenFunction`,
    null,
    getHeaders(false, signal)
  );
}

export function getNumberAnimalsReproduction(payload) {
  const { groupId, farmId, signal } = payload;

  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/animals/list?dashboard=genderReproductionFunction`,
    null,
    getHeaders(false, signal)
  );
}

export function getAnimalsGroupByProfitCenterInLot(payload) {
  const { groupId, farmId, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/lots?dashboard=allAnimalInLotsGroupByProfitCenterDashboard`,
    getHeaders(false, signal)
  );
}

export function getAnimalById(payload) {
  const { groupId, farmId, animalId, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/${animalId}`,
    getHeaders(false, signal)
  );
}

export function getAnimalWeightHistoryById(payload) {
  const { groupId, farmId, animalId, page, sorter, filters, signal } = payload;

  const { order } = sorter;

  const orderS = order === "ascend" ? "ASC" : "DESC";
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/${animalId}/weights?${
      Object.entries(sorter).length > 0
        ? `&sort=weightDate&direction=${orderS}`
        : "&sort=weightDate&direction=DESC"
    }${page ? `&page=${page - 1}` : ""}${
      filters !== "" ? `&search=${filters}` : ""
    }`,
    getHeaders(false, signal)
  );
}

export function getAnimalDailyWeights(payload) {
  const { groupId, farmId, animalId, doPageable, page, sorter, signal } =
    payload;
  if (doPageable === true) {
    const { field, order } = sorter;
    const orderS = order === "descend" ? "DESC" : "ASC";
    return apiAnimals.get(
      `/bovexo/${groupId}/farms/${farmId}/animals/${animalId}/daily/weights?doPageable=true${
        Object.entries(sorter).length > 0
          ? `&sort=${field}&direction=${orderS}`
          : "&sort=weighingDate&direction=DESC"
      }${page ? `&page=${page - 1}` : ""}`,
      getHeaders(false, signal)
    );
  } else {
    return apiAnimals.get(
      `/bovexo/${groupId}/farms/${farmId}/animals/${animalId}/daily/weights?doPageable=false`,
      getHeaders(false, signal)
    );
  }
}

export function getAnimalDailyWeightsGains(payload) {
  const { groupId, farmId, animalId, doPageable, page, sorter, signal } =
    payload;
  if (doPageable) {
    const { field, order } = sorter;
    const orderS = order === "descend" ? "DESC" : "ASC";
    return apiAnimals.get(
      `/bovexo/${groupId}/farms/${farmId}/animals/${animalId}/daily/weights/gains?doPageable=${doPageable}${
        Object.entries(sorter).length > 0
          ? `&sort=${field}&direction=${orderS}`
          : "&sort=dateDailyWeightGain&direction=DESC"
      }${page ? `&page=${page - 1}` : ""}`,
      getHeaders(false, signal)
    );
  } else {
    return apiAnimals.get(
      `/bovexo/${groupId}/farms/${farmId}/animals/${animalId}/daily/weights/gains?doPageable=${doPageable}`,
      getHeaders(false, signal)
    );
  }
}

export function getAllAnimalsInReceiptLotOrDontHaveLot(payload) {
  const { groupId, farmId, page, sorter, filters, size, signal } = payload;
  const { field, order } = sorter;

  const orderS = order === "descend" ? "DESC" : "ASC";

  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/animals/list?
    ${page ? `&page=${page - 1}` : ""}
    ${size && `&size=${size}`}
    ${
      Object.entries(sorter).length > 0
        ? `&sort=${field}&direction=${orderS}`
        : ""
    }
    ${
      filters !== "" ? `&search=${filters}` : ""
    }&justAnimalsInLotRecepitOrNoLot=true`,
    null,
    getHeaders(false, signal)
  );
}

export function getAllAnimalsfromLot(payload) {
  const { groupId, farmId, lotId, page, sorter, filters, size, signal } =
    payload;
  const { field, order } = sorter;

  const orderS = order === "descend" ? "DESC" : "ASC";

  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/animals?
    ${page ? `&page=${page - 1}` : ""}
    ${size && `&size=${size}`}
    ${
      Object.entries(sorter).length > 0
        ? `&sort=${field}&direction=${orderS}`
        : ""
    }
    &search=lotId='${lotId}';
    ${filters !== "" ? `${filters}` : ""}`,
    getHeaders(false, signal)
  );
}

export function getLastDailyWeight(payload) {
  const { groupId, farmId, animalId, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/${animalId}/last/daily/weights`,
    getHeaders(false, signal)
  );
}

export function getLastDailyWeightGain(payload) {
  const { groupId, farmId, animalId, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/${animalId}/last/daily/weights/gains`,
    getHeaders(false, signal)
  );
}

export function getLastDailyWeightReactAsync({ payload }) {
  const { groupId, farmId, animalId, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/${animalId}/last/daily/weights`,
    getHeaders(false, signal)
  );
}
export function getLastDailyWeightGainReactAsync({ payload }) {
  const { groupId, farmId, animalId, signal } = payload;
  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/${animalId}/last/daily/weights/gains`,
    getHeaders(false, signal)
  );
}

export function saveAnimal(payload) {
  const { groupId, farmId, id, animal, signal } = payload;
  animal.gender =
    animal.gender === "M"
      ? "Male"
      : animal.gender === "F"
      ? "Female"
      : animal.gender;
  if (id != null) {
    return apiAnimals.patch(
      `/bovexo/${groupId}/farms/${farmId}/animals/${id}`,
      animal,
      getHeaders(false, signal)
    );
  } else {
    return apiAnimals.post(
      `/bovexo/${groupId}/farms/${farmId}/animals`,
      animal,
      getHeaders(false, signal)
    );
  }
}

export function inactivateAnimal(payload) {
  const { groupId, farmId, animalId, signal } = payload;
  return apiAnimals.patch(
    `/bovexo/${groupId}/farms/${farmId}/animals/${animalId}/inactivate`,
    {},
    getHeaders(false, signal)
  );
}

export function activateAnimal(payload) {
  const { groupId, farmId, animalId, revertDiscard, signal } = payload;
  return apiAnimals.patch(
    `/bovexo/${groupId}/farms/${farmId}/animals/${animalId}/activate${
      revertDiscard != null ? `?revertDiscard=${revertDiscard}` : ""
    }`,
    {},
    getHeaders(false, signal)
  );
}

export function destroyAnimal(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiAnimals.delete(
    `/bovexo/${groupId}/farms/${farmId}/animals/${id}`,
    getHeaders(false, signal)
  );
}

export function verifyLastAnimal(payload) {
  const { groupId, farmId, id, lotId, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/${id}/last-one-alive-in-lot/${lotId}`,
    getHeaders(false, signal)
  );
}

export function verifyIfHaveOtherAnimalsWithSameHandlingNumber(payload) {
  const { groupId, farmId, handlingNumber, excludeAnimalId, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/with-same-handling-number?handlingNumber=${handlingNumber}${
      excludeAnimalId != null ? `&excludeAnimalId=${excludeAnimalId}` : ""
    }`,
    getHeaders(false, signal)
  );
}

export function verifyIfHaveOtherAnimalsWithSameSisbov(payload) {
  const { groupId, farmId, sisbov, excludeAnimalId, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/with-same-sisbov/${sisbov}${
      excludeAnimalId != null ? `?excludeAnimalId=${excludeAnimalId}` : ""
    }`,
    getHeaders(false, signal)
  );
}

export function verifyIfHaveOtherAnimalsWithSameTag(payload) {
  const { groupId, farmId, tagId, excludeAnimalId, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/with-same-tag/${tagId}${
      excludeAnimalId != null ? `?excludeAnimalId=${excludeAnimalId}` : ""
    }`,
    getHeaders(false, signal)
  );
}

export function verifyLastAnimalMovementEvent(payload) {
  const { groupId, farmId, id, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/${id}/last/movement`,
    getHeaders(false, signal)
  );
}

export function verifyIfAnimalsHaveMovementEventAfterDate(payload) {
  const { groupId, farmId, date, animalsIds, signal } = payload;

  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/animals/last/movement/after/${date}`,
    animalsIds,
    getHeaders(false, signal)
  );
}

export function updateAnimalWeightHistory(payload) {
  const { groupId, farmId, animalId, id, weight, signal } = payload;

  return apiAnimals.put(
    `/bovexo/${groupId}/farms/${farmId}/animals/${animalId}/weights/${id}`,
    {
      newWeight: weight,
    },
    getHeaders(false, signal)
  );
}

export function deleteAnimalWeightHistory(payload) {
  const { groupId, farmId, animalId, id, signal } = payload;

  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/animals/${animalId}/weights/${id}/delete`,
    {},
    getHeaders(false, signal)
  );
}

export function getWeightHistoryByAnimalsIdsAndDate(payload) {
  const { groupId, farmId, animalsIds, weightDate, signal } = payload;

  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/animals/weights?date=${weightDate}`,
    animalsIds,
    getHeaders(false, signal)
  );
}

export function getAnimalDailyCosts(payload) {
  const { groupId, farmId, animalId, signal, limitDate } = payload;

  return apiFinancial.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/${animalId}/daily/cost?limit_date=${limitDate}`,
    getHeaders(false, signal)
  );
}

export function getAnimalMotherFatherDropdownList(payload) {
  const { groupId, farmId, gender, parentId } = payload;

  let queryParam = "";
  if (parentId !== null) {
    queryParam = `parentId=${parentId}`;
  }

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/dropdown/parents/${gender}?${queryParam}`,
    getHeaders(false, null)
  );
}

export function getAnimalHandlingComments(payload) {
  const { groupId, farmId, animalId, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/${animalId}/handling/comments`,
    getHeaders(false, signal)
  );
}

export function getAnimalPicketSupplementSupplies(payload) {
  const { groupId, farmId, animalId, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/${animalId}/supplements/supplies`,
    getHeaders(false, signal)
  );
}

export function getAnimalDropdownListByGender(payload) {
  const { groupId, farmId, gender } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/dropdown/gender/${gender}`,
    getHeaders(false, null)
  );
}

export function getAnimalsSaleScenariosResults(payload) {
  const { groupId, farmId, animalsIds, signal } = payload;

  return apiOptimization.post(
    `/bovexo/${groupId}/farms/${farmId}/scenarios/sales/from/animals`,
    animalsIds,
    getHeaders(false, signal)
  );
}

export function reprocessAnimalReproductionEvents(payload) {
  const { groupId, farmId, signal } = payload;

  return apiAnimals.post(
    `/bovexo/${groupId}/farms/${farmId}/animals/reproduction/statistic/process`,
    null,
    getHeaders(false, signal)
  );
}

export function getAnimalGeneology(payload) {
  const { groupId, farmId, animalId, signal } = payload;

  return apiAnimals.get(
    `/bovexo/${groupId}/farms/${farmId}/animals/${animalId}/genealogy`,
    getHeaders(false, signal)
  );
}
