import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as AppActions } from "../../../store/ducks/app";
import LogoVertical from "../../login/logos/logoVertical";
import ButtonStandard from "../../utils/button";
import { StyledModal } from "./styles";

const WelcomeTrialModal = () => {
  // Redux Variables
  const { isModalWelcomeTrialVisible } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  function closeModal() {
    dispatch(AppActions.showOrHideModalWelcomeTrial());
  }
  function openTutorial() {
    dispatch(AppActions.showOrHideModalWelcomeTrial());
    if (window.WalkMePlayerAPI) {
      window.WalkMeAPI.startFlowById(1056350);
    }
  }
  return (
    <StyledModal
      visible={isModalWelcomeTrialVisible}
      width={550}
      footer={null}
      centered
      maskClosable={false}
      closable={false}
      onCancel={closeModal}
    >
      <main>
        <LogoVertical />
        <strong>Seja, bem-vindo !!</strong>
        <p>Vamos começar o seu tutorial ?</p>
      </main>
      <footer>
        <ButtonStandard
          buttonType="type7"
          size="m"
          type="button"
          onClick={closeModal}
        >
          Não, obrigado
        </ButtonStandard>
        <ButtonStandard
          buttonType="type1"
          width="auto"
          height="auto"
          padding="5px 20px"
          type="button"
          onClick={openTutorial}
        >
          Sim, vamos lá!
        </ButtonStandard>
      </footer>
    </StyledModal>
  );
};

export default WelcomeTrialModal;
