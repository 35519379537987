import React from "react";

const InvoiceIcon = () => (
    <svg width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.0625 0.75V11.25C22.0625 11.664 22.4685 12 22.9688 12C23.469 12 23.875 11.664 23.875 11.25V0.75C23.875 0.336 23.469 0 22.9688 0C22.4685 0 22.0625 0.336 22.0625 0.75Z" fill="#707070" />
        <path d="M18.4375 0.75V8.25C18.4375 8.664 18.8435 9 19.3438 9C19.844 9 20.25 8.664 20.25 8.25V0.75C20.25 0.336 19.844 0 19.3438 0C18.8435 0 18.4375 0.336 18.4375 0.75Z" fill="#707070" />
        <path d="M14.8125 0.75V8.25C14.8125 8.664 15.2185 9 15.7188 9C16.219 9 16.625 8.664 16.625 8.25V0.75C16.625 0.336 16.219 0 15.7188 0C15.2185 0 14.8125 0.336 14.8125 0.75Z" fill="#707070" />
        <path d="M11.1875 0.75V8.25C11.1875 8.664 11.5935 9 12.0938 9C12.594 9 13 8.664 13 8.25V0.75C13 0.336 12.594 0 12.0938 0C11.5935 0 11.1875 0.336 11.1875 0.75Z" fill="#707070" />
        <path d="M8 0.75V8.25C8 8.664 8.406 9 8.90625 9C9.4065 9 9.8125 8.664 9.8125 8.25V0.75C9.8125 0.336 9.4065 0 8.90625 0C8.406 0 8 0.336 8 0.75Z" fill="#707070" />
        <path d="M2.125 11.25L2.125 0.75C2.125 0.336 1.719 0 1.21875 0C0.7185 0 0.3125 0.336 0.3125 0.75L0.3125 11.25C0.3125 11.664 0.7185 12 1.21875 12C1.719 12 2.125 11.664 2.125 11.25Z" fill="#707070" />
        <path d="M3.9375 0.75L3.9375 8.25C3.9375 8.664 4.3435 9 4.84375 9C5.344 9 5.75 8.664 5.75 8.25L5.75 0.75C5.75 0.336 5.344 0 4.84375 0C4.3435 0 3.9375 0.336 3.9375 0.75Z" fill="#707070" />
    </svg>
);

export default InvoiceIcon;