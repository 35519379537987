import React from "react";
import { call, put } from "redux-saga/effects";
import { notification } from "antd";
import { Creators as BoitelScenarioActions } from "../ducks/boitelScenario";
/** Services */
import {
  contractBoitelScenarioService,
  showBoitelScenario,
  storeUpdateBoitelScenarioService,
} from "../../services/boitelScenarioService";

export function* storeUpdateBoitelScenarioSaga(action) {
  try {
    const {
      data: { results },
    } = yield call(storeUpdateBoitelScenarioService, action.payload);
    notification.success({
      message: `${action.payload.id != null ? "Atualização" : "Cadastro"} ${
        action.payload.id != null ? "realizada" : "realizado"
      }`,
      description: `${
        action.payload.id != null ? "Atualização" : "Cadastro"
      } do Cenário Boitel ${
        action.payload.id != null ? "realizada" : "realizado"
      }`,
    });
    if (action.payload.id === null) {
      window.history.pushState(
        "",
        "",
        `/admin/decisions/scenarios/boitel/${results?.id}/edit`
      );
    }
    yield put(BoitelScenarioActions.storeUpdateBoitelScenarioSuccess(results));
  } catch (error) {
    notification.error({
      message: `${action.payload.id != null ? "Atualização" : "Cadastro"} não ${
        action.payload.id != null ? "realizada" : "realizado"
      }`,
      description: `${
        action.payload.id != null ? "Atualização" : "Cadastro"
      } do Cenário Boitel não ${
        action.payload.id != null ? "realizada" : "realizado"
      }`,
    });
    yield put(BoitelScenarioActions.storeUpdateBoitelScenarioError(error));
  }
}

export function* getBoitelScenarioDataSaga(action) {
  try {
    const {
      data: { results },
    } = yield call(showBoitelScenario, action.payload);
    yield put(BoitelScenarioActions.getBoitelScenarioDataSuccess(results));
  } catch (error) {
    notification.error({
      message: `Erro ao buscar Cenário Boitel.`,
      description: `Erro ao buscar Cenário Boitel. Contate o suporte.`,
    });
    yield put(BoitelScenarioActions.getBoitelScenarioDataError(error));
  }
}

export function* constractBoitelScenarioSaga(action) {
  try {
    const {
      data: { results },
    } = yield call(contractBoitelScenarioService, action.payload);
    notification.success({
      message: `Atualização realizada.`,
      description: `Cenário contratado com sucesso.`,
    });
    yield put(BoitelScenarioActions.contractBoitelScenarioSuccess(results));
  } catch (error) {
    if (error.response.data.code === 5013) {
      notification.error({
        message: `Atualização não realizada.`,
        description: (
          <div>
            <span>
              Fazenda sem capacidade de suporte para contratar cenário.
            </span>
            <br />
            <span>Simulação não atendida:</span>
            <p>
              <strong>{error.response.data.errors[0]?.name}</strong>:
              {error.response.data.errors[0]?.message}
            </p>
          </div>
        ),
      });
    } else {
      notification.error({
        message: `Atualização não realizada.`,
        description: `Cenário não contratado.`,
      });
    }
    yield put(
      BoitelScenarioActions.contractBoitelScenarioError(error.response.data)
    );
  }
}
