import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Input, notification, Spin, Select } from "antd";
import ButtonStandard from "../../../utils/button";
import NumberFormat from "react-number-format";
import { Container, CustomDivider, Footer, SupplementInfoCard } from "./styles";
import NoteIcon from "../../../utils/icons/note";

const DrawerSupplementSustenanceCapacity = ({
  data,
  onSave,
  onCancel,
  drawerVisible,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    showMix: false,
    minimumVoluntaryConsumption: null,
    maximumVoluntaryConsumption: null,
    voluntaryConsumptionType: "G_ANIMAL",
    minimumPure: null,
    maximumPure: null,
    pureConsumptionType: "G_ANIMAL",
    minimumPureTrough: null,
    maximumPureTrough: null,
    minimumMix: null,
    maximumMix: null,
    mixConsumptionType: "G_ANIMAL",
    minimumMixTrough: null,
    maximumMixTrough: null,
    mixRatio: null,
    status: "NI",
  });
  const [errors, setErrors] = useState([]);
  const { translation } = useSelector((state) => state.app);

  function validation(data) {
    let submitErrors = [];

    /* Validate fields */

    // Validate Voluntary fields
    if (
      data.minimumVoluntaryConsumption === null ||
      data.minimumVoluntaryConsumption === 0
    ) {
      submitErrors = [...submitErrors, "minimumVoluntaryConsumption"];
    }
    if (
      data.maximumVoluntaryConsumption === null ||
      data.maximumVoluntaryConsumption === 0
    ) {
      submitErrors = [...submitErrors, "maximumVoluntaryConsumption"];
    }

    if (data.maximumVoluntaryConsumption < data.minimumVoluntaryConsumption) {
      notification.error({
        title: "Dados incorretos",
        message:
          translation.supplement
            .errorVoluntaryConsumptionMaximumLessThanMinimum,
      });
    }

    // Validate pure values
    if (!data.showMix) {
      if (data.minimumPure === null && data.maximumPure != null)
        submitErrors = [...submitErrors, "pureOffer"];

      if (data.minimumPure != null && data.maximumPure === null)
        submitErrors = [...submitErrors, "pureOffer"];

      if (data.maximumPureTrough === null && data.minimumPureTrough != null)
        submitErrors = [...submitErrors, "pureTrough"];

      if (data.minimumPureTrough === null && data.maximumPureTrough != null)
        submitErrors = [...submitErrors, "pureTrough"];

      if (data.maximumPure < data.minimumPure) {
        submitErrors = [...submitErrors, "pureOffer"];
        notification.error({
          title: "Dados incorretos",
          message: translation.supplement.errorPureMaximumLessThanMinimum,
        });
      }

      if (data.maximumPureTrough < data.minimumPureTrough) {
        submitErrors = [...submitErrors, "pureTrough"];
        notification.error({
          title: "Dados incorretos",
          message:
            translation.supplement.errorPureMaximumTroughLessThanMinimumTrough,
        });
      }
    }

    // Validate mix values
    if (data.showMix) {
      if (data.minimumMix != null && data.maximumMix === null)
        submitErrors = [...submitErrors, "mixOffer"];

      if (data.maximumMix != null && data.minimumMix === null)
        submitErrors = [...submitErrors, "mixOffer"];

      if (data.minimumMixTrough != null && data.maximumMixTrough === null)
        submitErrors = [...submitErrors, "mixTrough"];

      if (data.maximumMixTrough != null && data.minimumMixTrough === null)
        submitErrors = [...submitErrors, "mixTrough"];

      if (data.maximumMix < data.minimumMix) {
        submitErrors = [...submitErrors, "mixOffer"];
        notification.error({
          title: "Dados incorretos",
          message: translation.supplement.errorMixMaximumLessThanMinimum,
        });
      }

      if (data.maximumMixTrough < data.minimumMixTrough) {
        submitErrors = [...submitErrors, "mixTrough"];
        notification.error({
          title: "Dados incorretos",
          message:
            translation.supplement.errorMixMaximumTroughLessThanMinimumTrough,
        });
      }
    }

    // Validate if minimum pure/mix is less or equal to minimum voluntary
    if (
      (data.minimumPure !== null &&
        data.minimumPure > 0 &&
        data.minimumPure > data.minimumVoluntaryConsumption) ||
      (data.minimumMix !== null &&
        data.minimumMix > 0 &&
        data.minimumMix > data.minimumVoluntaryConsumption)
    ) {
      if (data.showMix) {
        submitErrors = [...submitErrors, "mixOffer"];
      } else {
        submitErrors = [...submitErrors, "pureOffer"];
      }
      notification.error({
        title: "Dados incorretos",
        message: `O valor do mínimo ${
          data.showMix ? "Mistura" : "Puro"
        } deve ser menor ou igual ao mínimo do consumo voluntário.`,
      });
    }

    // Validate if maximum pure/mix is greater or equal to minimum voluntary
    if (
      (data.maximumPure !== null &&
        data.maximumPure > 0 &&
        data.maximumPure < data.maximumVoluntaryConsumption) ||
      (data.maximumMix !== null &&
        data.maximumMix > 0 &&
        data.maximumMix < data.maximumVoluntaryConsumption)
    ) {
      if (data.showMix) {
        submitErrors = [...submitErrors, "mixOffer"];
      } else {
        submitErrors = [...submitErrors, "pureOffer"];
      }
      notification.error({
        title: "Dados incorretos",
        message: `O valor do máximo ${
          data.showMix ? "Mistura" : "Puro"
        } deve ser maior ou igual ao máximo do consumo voluntário.`,
      });
    }

    setErrors(submitErrors);
    return submitErrors.length === 0;
  }

  function onSubmit() {
    let data = form;
    if (validation(data)) {
      data.status = "I";
      onSave(data);
    }
  }

  function handleOpenDrawer(visible) {
    if (visible) {
      setIsLoading(true);
      setErrors([]);
      setForm(data);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }

  function handleChangeType(value) {
    setForm({
      ...form,
      voluntaryConsumptionType: value,
      pureConsumptionType: value,
      mixConsumptionType: value,
      minimumVoluntaryConsumption:
        form?.minimumVoluntaryConsumption == null
          ? null
          : form?.minimumVoluntaryConsumption >= 0
          ? value === "PV_ANIMAL"
            ? form?.minimumVoluntaryConsumption > 100
              ? 100
              : form?.minimumVoluntaryConsumption
            : value === "I_MS_PV_ANIMAL"
            ? form?.minimumVoluntaryConsumption > 100
              ? 100
              : form?.minimumVoluntaryConsumption
            : value === "KG_ANIMAL"
            ? form?.minimumVoluntaryConsumption > 100
              ? 100
              : form?.minimumVoluntaryConsumption
            : form?.minimumVoluntaryConsumption > 10000
            ? 10000
            : form?.minimumVoluntaryConsumption
          : 0,
      maximumVoluntaryConsumption:
        form?.maximumVoluntaryConsumption == null
          ? null
          : form?.maximumVoluntaryConsumption >= 0
          ? value === "PV_ANIMAL"
            ? form?.maximumVoluntaryConsumption > 100
              ? 100
              : form?.maximumVoluntaryConsumption
            : value === "I_MS_PV_ANIMAL"
            ? form?.maximumVoluntaryConsumption > 100
              ? 100
              : form?.maximumVoluntaryConsumption
            : value === "KG_ANIMAL"
            ? form?.maximumVoluntaryConsumption > 100
              ? 100
              : form?.maximumVoluntaryConsumption
            : form?.maximumVoluntaryConsumption > 10000
            ? 10000
            : form?.maximumVoluntaryConsumption
          : 0,
      minimumPure:
        form?.minimumPure == null
          ? null
          : form?.minimumPure >= 0
          ? value === "PV_ANIMAL"
            ? form?.minimumPure > 100
              ? 100
              : form?.minimumPure
            : value === "I_MS_PV_ANIMAL"
            ? form?.minimumPure > 100
              ? 100
              : form?.minimumPure
            : value === "KG_ANIMAL"
            ? form?.minimumPure > 100
              ? 100
              : form?.minimumPure
            : form?.minimumPure > 10000
            ? 10000
            : form?.minimumPure
          : 0,
      maximumPure:
        form?.maximumPure == null
          ? null
          : form?.maximumPure >= 0
          ? value === "PV_ANIMAL"
            ? form?.maximumPure > 100
              ? 100
              : form?.maximumPure
            : value === "I_MS_PV_ANIMAL"
            ? form?.maximumPure > 100
              ? 100
              : form?.maximumPure
            : value === "KG_ANIMAL"
            ? form?.maximumPure > 100
              ? 100
              : form?.maximumPure
            : form?.maximumPure > 10000
            ? 10000
            : form?.maximumPure
          : 0,
      minimumMix:
        form?.minimumMix == null
          ? null
          : form?.minimumMix >= 0
          ? value === "PV_ANIMAL"
            ? form?.minimumMix > 100
              ? 100
              : form?.minimumMix
            : value === "I_MS_PV_ANIMAL"
            ? form?.minimumMix > 100
              ? 100
              : form?.minimumMix
            : value === "KG_ANIMAL"
            ? form?.minimumMix > 100
              ? 100
              : form?.minimumMix
            : form?.minimumMix > 10000
            ? 10000
            : form?.minimumMix
          : 0,
      maximumMix:
        form?.maximumMix == null
          ? null
          : form?.maximumMix >= 0
          ? value === "PV_ANIMAL"
            ? form?.maximumMix > 100
              ? 100
              : form?.maximumMix
            : value === "I_MS_PV_ANIMAL"
            ? form?.maximumMix > 100
              ? 100
              : form?.maximumMix
            : value === "KG_ANIMAL"
            ? form?.maximumMix > 100
              ? 100
              : form?.maximumMix
            : form?.maximumMix > 10000
            ? 10000
            : form?.maximumMix
          : 0,
    });
  }

  const selectVoluntaryConsumption = (
    <Select
      value={form?.voluntaryConsumptionType}
      disabled={form?.blockEdit}
      style={{ width: 100 }}
      onChange={(value) => handleChangeType(value)}
    >
      <Select.Option value="PV_ANIMAL">%PV</Select.Option>
      <Select.Option value="I_MS_PV_ANIMAL">%IMS PV</Select.Option>
      <Select.Option value="G_KG_PV_ANIMAL">g/kg de PV</Select.Option>
      <Select.Option value="G_ANIMAL">g/dia</Select.Option>
      <Select.Option value="KG_ANIMAL">kg/dia</Select.Option>
    </Select>
  );

  const selectPureConsumption = (
    <Select
      value={form?.pureConsumptionType}
      style={{ width: 100 }}
      onChange={(value) => handleChangeType(value)}
    >
      <Select.Option value="PV_ANIMAL">%PV</Select.Option>
      <Select.Option value="I_MS_PV_ANIMAL">%IMS PV</Select.Option>
      <Select.Option value="G_KG_PV_ANIMAL">g/kg de PV</Select.Option>
      <Select.Option value="G_ANIMAL">g/dia</Select.Option>
      <Select.Option value="KG_ANIMAL">kg/dia</Select.Option>
    </Select>
  );

  const selectMixConsumption = (
    <Select
      value={form.mixConsumptionType}
      style={{ width: 100 }}
      onChange={(value) => handleChangeType(value)}
    >
      <Select.Option value="PV_ANIMAL">%PV</Select.Option>
      <Select.Option value="I_MS_PV_ANIMAL">%IMS PV</Select.Option>
      <Select.Option value="G_KG_PV_ANIMAL">g/kg de PV</Select.Option>
      <Select.Option value="G_ANIMAL">g/dia</Select.Option>
      <Select.Option value="KG_ANIMAL">kg/dia</Select.Option>
    </Select>
  );

  return (
    <Container
      title={translation.supplement.form.sustenanceCapacityDrawerTitle}
      width={600}
      onClose={onCancel}
      maskClosable={false}
      visible={drawerVisible}
      afterVisibleChange={handleOpenDrawer}
    >
      {/* Body */}
      <div className="drawerForm">
        <Spin spinning={isLoading}>
          {errors.length > 0 && (
            <Row type="flex" justify="center" align="middle">
              <label className="error">{translation.error.formError}</label>
            </Row>
          )}
          <Row type="flex" style={{ marginTop: "13px" }} align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <label
                  className={
                    errors.includes("minimumVoluntaryConsumption") ||
                    errors.includes("maximumVoluntaryConsumption")
                      ? "error"
                      : ""
                  }
                >
                  {translation.supplement.form.voluntaryConsumption}*
                </label>
              </Row>
              <Row className="rowInput">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <NumberFormat
                    disabled={form?.blockEdit}
                    customInput={Input}
                    placeholder={translation.defaultPlaceholder}
                    value={form !== null && form.minimumVoluntaryConsumption}
                    name="minimumVoluntaryConsumption"
                    addonBefore={translation.supplement.form.minimum}
                    addonAfter={selectVoluntaryConsumption}
                    decimalScale={4}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale={true}
                    onValueChange={({ floatValue }) => {
                      setForm({
                        ...form,
                        minimumVoluntaryConsumption:
                          floatValue == null
                            ? null
                            : floatValue >= 0
                            ? form?.voluntaryConsumptionType === "PV_ANIMAL"
                              ? floatValue > 100
                                ? 100
                                : floatValue
                              : form?.voluntaryConsumptionType ===
                                "I_MS_PV_ANIMAL"
                              ? floatValue > 100
                                ? 100
                                : floatValue
                              : form?.voluntaryConsumptionType === "KG_ANIMAL"
                              ? floatValue > 100
                                ? 100
                                : floatValue
                              : floatValue > 10000
                              ? 10000
                              : floatValue
                            : 0,
                      });
                      setErrors(
                        errors.filter(
                          (e) => e !== "minimumVoluntaryConsumption"
                        )
                      );
                    }}
                  />
                </Col>
                <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
                  <NumberFormat
                    disabled={form?.blockEdit}
                    customInput={Input}
                    placeholder={translation.defaultPlaceholder}
                    value={form !== null && form.maximumVoluntaryConsumption}
                    name="maximumVoluntaryConsumption"
                    addonBefore={translation.supplement.form.maximum}
                    addonAfter={selectVoluntaryConsumption}
                    decimalScale={4}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale={true}
                    onValueChange={({ floatValue }) => {
                      setForm({
                        ...form,
                        maximumVoluntaryConsumption:
                          floatValue == null
                            ? null
                            : floatValue >= 0
                            ? form?.voluntaryConsumptionType === "PV_ANIMAL"
                              ? floatValue > 100
                                ? 100
                                : floatValue
                              : form?.voluntaryConsumptionType ===
                                "I_MS_PV_ANIMAL"
                              ? floatValue > 100
                                ? 100
                                : floatValue
                              : form?.voluntaryConsumptionType === "KG_ANIMAL"
                              ? floatValue > 100
                                ? 100
                                : floatValue
                              : floatValue > 10000
                              ? 10000
                              : floatValue
                            : 0,
                      });
                      setErrors(
                        errors.filter(
                          (e) => e !== "maximumVoluntaryConsumption"
                        )
                      );
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <CustomDivider>LIMITES METABÓLICOS</CustomDivider>
          {/* Input Pure Offer */}
          {form?.showMix === false && (
            <>
              {/* Min */}
              <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Row>
                    <label
                      className={errors.includes("pureOffer") ? "error" : ""}
                    >
                      {translation.supplement.form.pure}
                    </label>
                  </Row>
                  <Row className="rowInput">
                    <NumberFormat
                      customInput={Input}
                      value={form !== null && form.minimumPure}
                      placeholder={translation.defaultPlaceholder}
                      addonAfter={selectPureConsumption}
                      addonBefore={translation.supplement.form.minimumAmount}
                      name="minimumPure"
                      decimalScale={4}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      onValueChange={({ floatValue }) => {
                        setForm({
                          ...form,
                          minimumPure:
                            floatValue == null
                              ? null
                              : floatValue >= 0
                              ? form?.pureConsumptionType === "PV_ANIMAL"
                                ? floatValue > 100
                                  ? 100
                                  : floatValue
                                : form?.pureConsumptionType === "I_MS_PV_ANIMAL"
                                ? floatValue > 100
                                  ? 100
                                  : floatValue
                                : form?.pureConsumptionType === "KG_ANIMAL"
                                ? floatValue > 100
                                  ? 100
                                  : floatValue
                                : floatValue > 10000
                                ? 10000
                                : floatValue
                              : 0,
                        });
                        setErrors(errors.filter((e) => e !== "pureOffer"));
                      }}
                    />
                  </Row>
                </Col>
                <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
                  <Row>
                    <label
                      className={errors.includes("pureTrough") ? "error" : ""}
                    >
                      {translation.supplement.form.recommendedTrough}
                    </label>
                  </Row>
                  <Row className="rowInput">
                    <NumberFormat
                      customInput={Input}
                      placeholder={translation.defaultPlaceholder}
                      value={form !== null && form.minimumPureTrough}
                      name="minimumPureTrough"
                      addonBefore={translation.supplement.form.minimum}
                      addonAfter="cm/animal"
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      onValueChange={({ floatValue }) => {
                        setForm({
                          ...form,
                          minimumPureTrough:
                            floatValue == null
                              ? null
                              : floatValue >= 0
                              ? floatValue > 1000
                                ? 1000
                                : floatValue
                              : 0,
                        });
                        setErrors(errors.filter((e) => e !== "pureTrough"));
                      }}
                    />
                  </Row>
                </Col>
              </Row>
              {/* Max */}
              <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Row className="rowInput">
                    <NumberFormat
                      customInput={Input}
                      value={form !== null && form.maximumPure}
                      placeholder={translation.defaultPlaceholder}
                      addonAfter={selectPureConsumption}
                      addonBefore={translation.supplement.form.maximumAmount}
                      name="maximumPure"
                      decimalScale={4}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      onValueChange={({ floatValue }) => {
                        setForm({
                          ...form,
                          maximumPure:
                            floatValue == null
                              ? null
                              : floatValue >= 0
                              ? form?.pureConsumptionType === "PV_ANIMAL"
                                ? floatValue > 100
                                  ? 100
                                  : floatValue
                                : form?.pureConsumptionType === "I_MS_PV_ANIMAL"
                                ? floatValue > 100
                                  ? 100
                                  : floatValue
                                : form?.pureConsumptionType === "KG_ANIMAL"
                                ? floatValue > 100
                                  ? 100
                                  : floatValue
                                : floatValue > 10000
                                ? 10000
                                : floatValue
                              : 0,
                        });
                        setErrors(errors.filter((e) => e !== "pureOffer"));
                      }}
                    />
                  </Row>
                </Col>
                <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
                  <Row className="rowInput">
                    <NumberFormat
                      customInput={Input}
                      placeholder={translation.defaultPlaceholder}
                      value={form !== null && form.maximumPureTrough}
                      name="maximumPureTrough"
                      addonBefore={translation.supplement.form.maximum}
                      addonAfter="cm/animal"
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      onValueChange={({ floatValue }) => {
                        setForm({
                          ...form,
                          maximumPureTrough:
                            floatValue == null
                              ? null
                              : floatValue >= 0
                              ? floatValue > 1000
                                ? 1000
                                : floatValue
                              : 0,
                        });
                        setErrors(errors.filter((e) => e !== "pureTrough"));
                      }}
                    />
                  </Row>
                </Col>
              </Row>
            </>
          )}
          {/* Input Mix Offer */}
          {form?.showMix === true && (
            <>
              {/* Min */}
              <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Row>
                    <label
                      className={errors.includes("mixOffer") ? "error" : ""}
                    >
                      {translation.supplement.form.mix}
                    </label>
                  </Row>
                  <Row className="rowInput">
                    <NumberFormat
                      customInput={Input}
                      value={form !== null && form.minimumMix}
                      addonAfter={selectMixConsumption}
                      placeholder={translation.defaultPlaceholder}
                      addonBefore={translation.supplement.form.minimumAmount}
                      name="minimumMix"
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      onValueChange={({ floatValue }) => {
                        setForm({
                          ...form,
                          minimumMix:
                            floatValue == null
                              ? null
                              : floatValue >= 0
                              ? form?.mixConsumptionType === "PV_ANIMAL"
                                ? floatValue > 100
                                  ? 100
                                  : floatValue
                                : form?.mixConsumptionType === "I_MS_PV_ANIMAL"
                                ? floatValue > 100
                                  ? 100
                                  : floatValue
                                : form?.mixConsumptionType === "KG_ANIMAL"
                                ? floatValue > 100
                                  ? 100
                                  : floatValue
                                : floatValue > 10000
                                ? 10000
                                : floatValue
                              : 0,
                        });
                        setErrors(errors.filter((e) => e !== "mixOffer"));
                      }}
                    />
                  </Row>
                </Col>
                <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
                  <Row>
                    <label
                      className={errors.includes("mixTrough") ? "error" : ""}
                    >
                      {translation.supplement.form.recommendedTrough}
                    </label>
                  </Row>
                  <Row className="rowInput">
                    <NumberFormat
                      customInput={Input}
                      placeholder={translation.defaultPlaceholder}
                      value={form !== null && form.minimumMixTrough}
                      name="minimumMixTrough"
                      addonBefore={translation.supplement.form.minimum}
                      addonAfter="cm/animal"
                      decimalScale={0}
                      decimalSeparator=","
                      thousandSeparator="."
                      onValueChange={({ floatValue }) => {
                        setForm({
                          ...form,
                          minimumMixTrough:
                            floatValue == null
                              ? null
                              : floatValue >= 0
                              ? floatValue > 1000
                                ? 1000
                                : floatValue
                              : 0,
                        });
                        setErrors(errors.filter((e) => e !== "mixTrough"));
                      }}
                    />
                  </Row>
                </Col>
              </Row>
              {/* Max */}
              <Row type="flex" style={{ marginTop: "13px" }} align="middle">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Row className="rowInput">
                    <NumberFormat
                      customInput={Input}
                      value={form !== null && form.maximumMix}
                      placeholder={translation.defaultPlaceholder}
                      addonAfter={selectMixConsumption}
                      addonBefore={translation.supplement.form.maximumAmount}
                      name="maximumMix"
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      onValueChange={({ floatValue }) => {
                        setForm({
                          ...form,
                          maximumMix:
                            floatValue == null
                              ? null
                              : floatValue >= 0
                              ? form?.mixConsumptionType === "PV_ANIMAL"
                                ? floatValue > 100
                                  ? 100
                                  : floatValue
                                : form?.mixConsumptionType === "I_MS_PV_ANIMAL"
                                ? floatValue > 100
                                  ? 100
                                  : floatValue
                                : form?.mixConsumptionType === "KG_ANIMAL"
                                ? floatValue > 100
                                  ? 100
                                  : floatValue
                                : floatValue > 10000
                                ? 10000
                                : floatValue
                              : 0,
                        });
                        setErrors(errors.filter((e) => e !== "mixOffer"));
                      }}
                    />
                  </Row>
                </Col>
                <Col xs={11} sm={11} md={11} lg={11} xl={11} offset={1}>
                  <Row className="rowInput">
                    <NumberFormat
                      customInput={Input}
                      placeholder={translation.defaultPlaceholder}
                      value={form !== null && form.maximumMixTrough}
                      name="maximumMixTrough"
                      addonBefore={translation.supplement.form.maximum}
                      addonAfter="cm/animal"
                      decimalScale={0}
                      decimalSeparator=","
                      thousandSeparator="."
                      onValueChange={({ floatValue }) => {
                        setForm({
                          ...form,
                          maximumMixTrough:
                            floatValue == null
                              ? null
                              : floatValue >= 0
                              ? floatValue > 1000
                                ? 1000
                                : floatValue
                              : 0,
                        });
                        setErrors(errors.filter((e) => e !== "mixTrough"));
                      }}
                    />
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </Spin>
        <SupplementInfoCard>
          <NoteIcon />
          {translation.supplement.form.information}
        </SupplementInfoCard>
      </div>
      {/* Footer */}
      <Footer>
        <Row type="flex" justify="space-between">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="selectDiv"
          ></Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="buttonsDiv">
            <ButtonStandard type="button" buttonType="type7" onClick={onCancel}>
              {translation.buttons.cancel}
            </ButtonStandard>

            <ButtonStandard
              type="submit"
              buttonType="type6"
              onClick={onSubmit}
              disabled={errors.length > 0 ? true : false}
            >
              {translation.buttons.save}
            </ButtonStandard>
          </Col>
        </Row>
      </Footer>
    </Container>
  );
};

export default DrawerSupplementSustenanceCapacity;
