export default {
  diet: {
    title: "Diets",
    buttonNewDiet: "New Diet",
    table: {
      title: "Diet List",
      columns: {
        status: "Status",
        description: "Diet",
        phosphorus: "Phosphorus (mg)",
        nitrogen: "Nitrogen (mg)",
        tdn: "TDN (g)",
        cp: "Crude Protein (g)",
        calcium: "Calcium (mg)",
        cost: "Cost (R$/Kg)",
      },
    },
    drawerTitle: "Diet Registration",
    drawerTitleEdit: "Edit Diet",
    drawerSubTitle: "Complete the fields below to create a new diet",
    drawerSubTitleEdit: "Complete the fields below to edit the diet",
    validationMessage: "Fill in all required fields with * correctly",
    form: {
      groupFarmVisibility: "Who will use the pasture?",
      name: "Diet Identification",
      namePlaceHolder: "Diet Name",
      pasture: "Pasture",
      pasturePlaceHolder: "What is the diet pasture?",
      pastureNoSelection: "None",
      supplements: "Supplements",
      supplementsPlaceHolder: "What are dietary supplements?",
      cost: "Diet Cost (R$/Kg)",
      costPlaceHolder: "What is the cost of the diet in R$/Kg?",
      phosphorus: "Phosphorus (mg)",
      phosphorusPlaceHolder: "How much phosphorus?",
      nitrogen: "Nitrogen (mg)",
      nitrogenPlaceHolder: "How much Nitrogen?",
      calcium: "Calcium (mg)",
      calciumPlaceHolder: "How much Calcium?",
      tdn: "TDN (g)",
      tdnPlaceHolder: "How much NDT?",
      cp: "Crude Protein (g)",
      cpPlaceHolder: "How much Crude Protein?",
      status: "Diet Status",
      error: {
        requiredPastureSupplement: {
          title: "Error",
          message: "Pasture or at least one Supplement must be selected",
        },
      },
    },
    saveOptions: {
      only_save: "Save Only",
      save_and_create_new: "Create diet and add new",
    },
  },
  pasture: {
    title: "Forages",
    buttonNewPasture: "New Forage",
    table: {
      title: "List of Forages",
      columns: {
        status: "Status",
        description: "Forage",
        phosphorus: "Phosphorus (mg)",
        nitrogen: "Nitrogen (mg)",
        tdn: "TDN (g)",
        cp: "CP (g)",
        calcium: "Calcium (mg)",
        cost: "Cost (animal/ha)",
        entryHeight: "Entry Height",
        exitHeight: "Exit Height",
        growthSpeed: "Growth Speed",
        yield: "Yield",
      },
    },
    drawerTitle: "Forage Register",
    drawerTitleEdit: "Edit Forage",
    drawerSubTitle: "Complete the fields below to create a new forage",
    drawerSubTitleEdit: "Complete the fields below to edit the forage",
    validationMessage: "Fill in all required fields with * correctly",
    form: {
      groupFarmVisibility: "Who will use the forrage?",
      name: "Identification",
      namePlaceHolder: "Forage Name",
      pastureType: "Scientific Name",
      fertilized: "Fertilized",
      notFertilized: "Not fertilized",
      status: "Forage Status",
      cost: "Cost",
      costPlaceHolder: "Measure: R$",
      cp: "CP: Proteína Bruta",
      cpPlaceHolder: "Measure: g",
      fdn: "FDN: Fibra em detergente neutro",
      fdnPlaceHolder: "Measure: %",
      tdn: "TDN",
      tdnPlaceHolder: "Measure: g",
      phosphorus: "Fósforo",
      phosphorusPlaceHolder: "Medida: g/kg",
      nitrogen: "Nitrogênio",
      nitrogenPlaceHolder: "Medida: mg/kg",
      calcium: "Cálcio",
      calciumPlaceHolder: "Medida: g/kg",
      zinc: "Zinco",
      zincPlaceHolder: "Medida: mg/kg",
      sodium: "Sódio",
      sodiumPlaceHolder: "Medida: g/kg",
      formation: "Formation",
      formationPlaceHolder: "Measure: dias",
      rest: "Rest",
      restPlaceHolder: "Measure: dias",
      entryHeight: "Ideal Entry Height",
      entryHeightPlaceHolder: "Measure: cm",
      exitHeight: "Ideal Exit Height",
      exitHeightPlaceHolder: "Measure: cm",
      growthRate: "Growth Rate",
      growthRatePlaceHolder: "Measure: kg/ha/dia",
      leafDensityProduction: "Leafy Density Production",
      leafDensityProductionPlaceHolder: "Measure: t/ha/corte",
      growthSpeed: "Growth Rate",
      growthSpeedPlaceHolder: "Measure: kg MS/㎡/dia",
      yield: "Yield",
      yieldPlaceHolder: "Measure: kg MS/㎡",
      nutritionalData: "NUTRITIONAL DATA",
      capacity: "CAPACITY",
      maximumConsumption: "Maximum consumption",
      minimumConsumption: "Minimum consumption",
      radiogroup: {
        farm: "Only this farm",
        group: "Group's farms",
      },
      fda: "FDA",
      lignin: "Lignina",
      ee: "Extrato Etéreo",
      maximumHeight: "Altura Máxima",
      maximumHeightPlaceHolder: "Altura Máxima",
    },
    modal: {
      confirmation: {
        title: "Troca do Tipo da Forrageira",
        message:
          "Ao confirmar a troca do tipo da forrageira os valores de altura ideal de entrada, altura ideal de saída e estações climáticas serão alterados. Deseja continuar?",
        confirmButton: "Confirmar",
        cancelButton: "Cancelar",
      },
    },
    errorConsumptionMaximumLessThanMinimum:
      "Maximum consumption must be greater than minimum consumption",
    errorEntryHeightLessThanExitEntryHeight:
      "The ideal entry height must be greater than the ideal exit height",
    errorNecessaryOneSeason: "You must have at least one station entered",
    saveOptions: {
      only_save: "Save Only",
      save_and_create_new: "Create pasture and add new",
    },
  },
  breed: {
    title: "Breeds",
    buttonNewBreed: "New Breed",
    table: {
      title: "List of Breeds",
      columns: {
        status: "Status",
        geneticGroup: "Genetic Group",
        name: "Breed",
        description: "Description",
        asymptoticWeight: "Asymptotic Weight(kg)",
        estimatedMaximumAge: "Adulthood",
        correctionFactor: "Correction Factor",
        maturationRate: "Maturation Rate",
      },
      menu: {
        parameters: "Parameters",
      },
    },
    drawerTitle: "Breed Register",
    drawerTitleEdit: "Edit Breed",
    drawerSubTitle: "Complete the fields below to create a new breed",
    drawerSubTitleEdit: "Complete the fields below to edit the breed",
    validationMessage: "Fill in all required fields with * correctly",
    form: {
      name: "Breed identification",
      namePlaceHolder: "Breed Name",
      description: "Description",
      descriptionPlaceHolder: "Breed Description",
      geneticGroup: "Genetic Group",
      geneticGroupPlaceHolder: "Breed Genetic Group",
      status: "Breed Status",
    },
    saveOptions: {
      only_save: "Save Only",
      save_and_create_new: "Create breed and add new",
      save_and_add_parameters: "Create breed and set parameters",
    },
    drawerParametersTitle: "Breed Parameters",
    formParameters: {
      asymptoticWeight: "Asymptotic Weight",
      asymptoticWeightPlaceHolder: "Set Weight",
      theoricalCurve: "Theoretical curve",
      estimatedMaximumAge: "Adulthood",
      estimatedMaximumAgePlaceHolder: "Adulthood",
      correctionFactor: "Correction Factor",
      correctionFactorPlaceHolder:
        "Correction factor for breed's curve calculation",
      maturationRate: "Maturation Rate",
      maturationRatePlaceHolder:
        "Maturation rate for breed's curve calculation",
      status: "Breed Parameters Status",
      extimatedCurveTitle: "ESTIMATED GROWTH CURVE",
      genetic: "GENETIC",
      diet: "DIET",
      gestationDivider: "Tempo de Gestação",
      minGestationTime: "Mínimo",
      medGestationTime: "Médio",
      maxGestationTime: "Máximo",
    },
  },
  handlingOperation: {
    title: "Operations",
    buttonNewPasture: "New Operation",
    table: {
      title: "List of Operations",
      columns: {
        status: "Status",
        operation: "Operation",
        action: "Action",
      },
    },
    drawerTitle: "Register of Operation",
    drawerTitleEdit: "Edit Operation",
    drawerSubTitle: "Complete the fields below to create a new operation",
    drawerSubTitleEdit: "Complete the fields below to edit the operation",
    validationMessage: "Fill in all required fields with * correctly",
    form: {
      name: "Operation",
      namePlaceHolder: "Operation Name",
      action: "Action",
      actionPlaceHolder: "Select procedure action",
      recurrence: "Recurrence",
      recurrenceAmount: "Qty.",
      quarantine: "Quarantine",
      quarantineAmount: "Enter the number of days",
      breed: "Race",
      breedPlaceHolder: "Select races",
      gender: "Sex",
      genderPlaceHolder: "Select",
      age: "Age",
      weight: "Weight",
      dontExecuteOperation: "Do not perform operation on animals that are in:",
      inQuarantine: "Quarantine",
      inPregnancy: "Pregnancy",
      operationCost: "Operation Cost",
      noCost: "No Charge",
      haveCost: "With cost (define cost in handling)",
      status: "Operation Status",
    },
    parameters: "PARAMETERS",
    limitations: "LIMITATIONS",
    errors: {
      recurrenceMinAndMaxDayCanBeNull: {
        title: "Error",
        message:
          "Minimum and maximum number of recurrence days must not be blank.",
      },
      recurrenceQtdCanBeNull: {
        title: "Error",
        message: "The number of times the recurrence cannot be blank.",
      },
    },
    saveOptions: {
      only_save: "Save Only",
      save_and_create_new: "Create operation and add new",
    },
  },
  handling: {
    title: "Managements",
    tabs: {
      pending: "Pending",
      finalized: "Finalized",
      dietStrategies: "Diet strategies",
      vacinations: "Veterinários",
      handlingReproduction: "Reprodução",
    },
    vacinations: {
      buttonNew: "Nova Aplicação",
      form: {
        titleEdit: "Editar Aplicação",
        titleCreateNew: "Nova Aplicação",
        dividerTitle: "APLICAÇÃO ANTERIOR",
        animalId: "Animal",
        lastDailyWeight: "Última pesagem",
        handlingNumber: "Animal",
        veterinarySupplementId: "Produto",
        veterinarySupplementApplicationType: "Forma de Aplicação",
        veterinarySupplementApplicationValue: "Dose",
        applicationQuantity: "Qtde Aplicada",
        applicationUnit: "Unidade",
        applicationDay: "Data Aplicação",
      },
      table: {
        title: "Veterinários",
        columns: {
          animalName: "Animal",
          veterinarySupplementName: "Nome",
          type: "Tipo",
          applicationDate: "Data",
          applicationQuantity: "Quantidade",
          applicationUnit: "Unidade",
        },
      },
    },
  },
  parameters: {
    title: "Parameters",
    tabs: {
      general: "General",
      breed: "Breeds",
      diet: "Diets",
      supplement: "Supplements",
      pasture: "Forager",
      alert: "Alert",
      benchmark: "Benchmarks",
      marketAnalysis: "Price Curve",
      cost: "Financial/Costs",
      activity: "Activities",
      boitelPrice: "Boitel - Diárias",
      integration: "Integrações",
      financial: "Custeio",
      reproduction: "Reprodução",
      reproductionParameter: "Parâmetros",
      semen: "Sêmens",
      inseminators: "Inseminadores",
      supplie: "Insumos",
      animal: "Animais",
      deathReasons: "Causa Mortis",
      unit: "Unidades",
      veterinary: "Veterinários",
      commercialization: "Comercialização",
      quota: "Cotas",
      multipleBull: "Touro Múltiplo",
      reproductiveProtocol: "Protocolos",
    },
  },
  integrationPartners: {
    table: {
      title: "Integrações",
      columns: {
        status: "Situação",
        system: "Sistema",
        authType: "Tipo Autenticação",
      },
    },
    authType: {
      Token: "Token",
      UserAndPassword: "Usuário e Senha",
    },
  },
  imports: {
    title: "Importações",
    tabs: {
      animal: "Animais",
      weight: "Pesagens",
      supplement: "Suplementos",
      financialTransactions: "Mov. Financeiras",
    },
    status: {
      queue: "Aguardando",
      processing: "Processando",
      processed: "Processado",
      deleting: "Deletando",
      error: "Erro",
    },
    animal: {
      buttonImport: "Importar",
      table: {
        title: "Lista de Importações de Animais",
        columns: {
          status: "Status",
          fileName: "Arquivo",
          date: "Data",
          total: "Total",
          numberImported: "Importados",
          numberAlerted: "Alertas",
          numberError: "Erros",
          numberDuplicated: "Duplicados",
          numberUpdated: "Atualizados",
          message: "Mensagem",
        },
      },
      details: {
        breadCumbsImportLog: "Importações",
        breadCumbsImportLogDetails: "Detalhes da Importação",
        collapseAnimals: "Animais",
        fileNameTitle: "Arquivo",
        statusTitle: "Status",
        dateTitle: "Data",
        table: {
          columns: {
            rowNumber: "Linha Excel",
            handlingNumber: "Identificação do Animal",
            message: "Motivo",
            alert: "Alertas",
          },
        },
      },
    },
    weight: {
      buttonImport: "Importar",
      table: {
        title: "Lista de Importações de Pesagens",
        columns: {
          status: "Status",
          fileName: "Arquivo",
          date: "Data",
          total: "Total",
          numberImported: "Importados",
          numberError: "Erros",
          numberDuplicated: "Duplicados",
          numberUpdated: "Atualizados",
          message: "Mensagem",
        },
      },
      details: {
        breadCumbsImportLog: "Importações",
        breadCumbsImportLogDetails: "Detalhes da Importação",
        collapseAnimals: "Pesagens",
        fileNameTitle: "Arquivo",
        statusTitle: "Status",
        dateTitle: "Data",
        table: {
          columns: {
            rowNumber: "Linha Excel",
            weight: "Peso",
            handlingNumber: "Identificação do Animal",
            message: "Motivo",
          },
        },
      },
    },
    supplement: {
      buttonImport: "Importar",
      table: {
        title: "Lista de Importações de Suplementos",
        columns: {
          status: "Status",
          fileName: "Arquivo",
          date: "Data",
          total: "Total",
          numberImported: "Importados",
          numberError: "Erros",
          numberDuplicated: "Duplicados",
          numberUpdated: "Atualizados",
          message: "Mensagem",
        },
      },
      details: {
        breadCumbsImportLog: "Importações",
        breadCumbsImportLogDetails: "Detalhes da Importação",
        collapseAnimals: "Suplementos",
        fileNameTitle: "Arquivo",
        statusTitle: "Status",
        dateTitle: "Data",
        table: {
          columns: {
            rowNumber: "Linha Excel",
            name: "Identificação do Suplemento",
            message: "Motivo",
          },
        },
      },
    },
    financialTransactions: {
      buttonImport: "Importar",
      table: {
        title: "Lista de Importações de Mov. Financeiras",
        columns: {
          status: "Status",
          fileName: "Arquivo",
          date: "Data",
          total: "Total",
          numberImported: "Importados",
          numberError: "Erros",
          numberDuplicated: "Duplicados",
          numberUpdated: "Atualizados",
          message: "Mensagem",
        },
      },
      details: {
        breadCumbsImportLog: "Importações",
        breadCumbsImportLogDetails: "Detalhes da Importação",
        collapseAnimals: "Mov. Financeiras",
        fileNameTitle: "Arquivo",
        statusTitle: "Status",
        dateTitle: "Data",
        table: {
          columns: {
            rowNumber: "Linha Excel",
            name: "Identificação da Mov. Financeira",
            message: "Motivo",
          },
        },
      },
    },
  },
  benchmark: {
    title: "Benchmarks",
    buttonNewBenchmark: "New benchmark",
    table: {
      title: "List of Benchmarks",
      columns: {
        status: "Status",
        description: "Benchmark",
      },
    },
    drawerTitle: "Register of Benchmark",
    drawerTitleEdit: "Edit Benchmark",
    drawerSubTitle: "Complete the fields below to register a new benchmark",
    drawerSubTitleEdit: "Complete the fields below to edit the benchmark",
    validationMessage: "Fill in all required fields with * correctly",
    form: {
      name: "Name",
      namePlaceHolder: "Identification",
      status: "Benchmark Status",
      groupFarmVisibility: "Who will see the benchmark?",
      radiogroup: {
        farm: "This farm",
        group: "Farms of Group",
      },
      harvestDivider: "HARVESTS",
    },
    errorAtLeastOneKPIValue: "Inform at least one value of KPI",
    saveOptions: {
      only_save: "Save Only",
      save_and_create_new: "Save Benchmark and add New",
    },
  },
  marketAnalysis: {
    title: "Price Curve",
    buttonNewMarketAnalysis: "New Price Curve",
    table: {
      title: "List of Price Curve",
      columns: {
        status: "Status",
        type: "Type",
        description: "Name",
        baseline: "Baseline",
      },
    },
    type: {
      bovexo: "BovExo",
      progressive: "Progressive",
      linear: "Linear",
      free: "Free",
    },
  },
  supplement: {
    title: "Supplements",
    buttonNewSupplement: "New Supplement",
    table: {
      title: "List of Supplements",
      columns: {
        status: "Status",
        description: "Supplement",
        industrialName: "Industrial Name",
        supplierName: "Supplier",
        createdAt: "Data de Criação",
        userName: "Responsável",
        version: "Versão",
        cp: "PB: Proteína Bruta",
        tdn: "NDT: Nutrientes Digest. Totais",
        npn: "NNP - Equivalente Proteico (Máx)",
        dryMatterPercentage: "MS: Matéria Seca",
        phosphorus: "Fósforo",
        nitrogen: "Nitrogênio",
        calcium: "Cálcio",
        zinc: "Zinco",
        sodium: "Sódio",
        additiveEffect: "Additive Effect",
        fda: "FDA",
        lignin: "Lignina",
        ee: "Extrato Etéreo",
      },
    },
    drawerTitle: "Register of Supplement",
    drawerTitleEdit: "Edit Supplement",
    drawerSubTitle: "Complete the fields below to register a new supplement",
    drawerSubTitleEdit: "Complete the fields below to edit the supplement",
    validationMessage: "Fill in all required fields with * correctly",
    form: {
      supplementType: "Ração/mistura ou Suplemento ?",
      supplementTypeHelp:
        "Feed/Mixture: Ready-made diet, produced on the farm. Supplements: Product purchased and supplied in a pure form.",
      supplementClassification: "Qual a classificação do Suplemento/Ração ?",
      supplementClassificationHelp: {
        title_0: "Suplemento Mineral",
        description_0:
          " quando possuir na sua composição, macro e/ou micro elemento mineral, podendo apresentar, no produto final, um valor menor que quarenta e dois porcento de equivalente protéico.",
        title_1: "Suplemento Mineral Protéico",
        description_1:
          " quando possuir na sua composição, macro e/ou micro elemento mineral, pelo menos 20% de proteína bruta (PB) e fornecer, no mínimo, 30 gramas de proteína bruta (PB) por 100 Kg de PV.",
        title_2: "Suplemento Mineral Protéico Energético",
        description_2:
          " quando possuir na sua composição, macro e/ou micro elemento mineral, pelo menos 20% de proteína bruta, fornecer, no mínimo, 30 gramas de proteína bruta e 100 gramas de nutrientes digestíveis totais (NDT) por 100 Kg de PV.",
        source: "*Conforme Mapa - IN12 de 30 de novembro de 2004.",
      },
      name: "Generic Name",
      namePlaceHolder: "Identification",
      importCode: "Código de importação",
      industrialName: "Industrial Name",
      industrialNamePlaceHolder: "Industrial Name",
      supplierName: "Supplier",
      supplierNamePlaceHolder: "Supplier Name",
      status: "Supplement Status",
      nutritionalDataDivider: "NUTRITIONAL DATA",
      capacityDivider: "QUANTITIES BY OFFER MODE",
      information: 'All information refers to "Animal/day"',
      sustenanceCapacity: "QUANTITIES BY OFFER MODE",
      sustenanceCapacityDrawerTitle: "Quantities by Supplement Offer Mode",
      minimumAmount: "Min Amount",
      maximumAmount: "Max Amount",
      minimum: "Minimum",
      maximum: "Maximum",
      ratio: "Ratio",
      cost: "Daily Cost",
      costPlaceHolder: "Measure: $",
      cp: "CP: Crude Protein",
      cpPlaceHolder: "Measure: g/kg",
      tdn: "TDN",
      tdnPlaceHolder: "Measure: g/kg",
      dryMatterPercentage: "MS: Matéria Seca",
      npn: "NNP - Equivalente Proteico (Máx)",
      npnPlaceHolder: "Medida: g/kg",
      phosphorus: "Phosphorus",
      phosphorusPlaceHolder: "Measure: g/kg",
      zinc: "Zinco",
      zincPlaceHolder: "Medida: mg/kg",
      sodium: "Sódio",
      sodiumPlaceHolder: "Medida: g/kg",
      nitrogen: "Nitrogen",
      nitrogenPlaceHolder: "Measure: mg/kg",
      calcium: "Calcium",
      calciumPlaceHolder: "Measure: g/kg",
      voluntaryConsumption: "Voluntary Consumption",
      minimumVoluntaryConsumptionPlaceHolder: "Measure: g",
      maximumVoluntaryConsumptionPlaceHolder: "Measure: g",
      pure: "Puro Offer",
      mix: "Mix Offer",
      recommendedTrough: "Recommended Trough",
      recommendedTroughPlaceHolder: "Trough recommended",
      groupFarmVisibility: "Who will use the supplement?",
      radiogroup: {
        farm: "This farm",
        group: "Farms of Group",
      },
      additiveEffect: "Additive Effect",
      additiveEffectPlaceHolder: "Measure: g/dia",
      additiveEffectHelp:
        "Alguns suplementos minerais possuem aditivos em sua formulação que potencializam o ganho de peso dos animais, podendo o fornecedor indicar um ganho incremental (g/dia), somando ao ganho previsto calculado (Pasto + Suplemento) ou indicar por um aumento % do total de Proteína Bruta (PB) a ser considerado no calculo.",
      fda: "FDA",
      fdaPlaceHolder: "Medida: g/kg",
      lignin: "Lignina",
      ligninPlaceHolder: "Medida: g/kg",
      ee: "Extrato Etéreo",
      eePlaceHolder: "Medida: %/MS",
    },
    errorVoluntaryConsumptionMaximumLessThanMinimum:
      "The maximum voluntary consumption must be greater than or equal to the minimum consumption",
    errorPureMaximumLessThanMinimum:
      "Maximum Pure Offer must be greater than or equal to Minimum Pure Offer",
    errorPureMaximumTroughLessThanMinimumTrough:
      "Maximum Recommended Trough must be greater than or equal to Minimum",
    errorMixMaximumLessThanMinimum:
      "Maximum Mix Offer must be greater than or equal to Minimum Mix Offer",
    errorNecessaryOneSeason:
      "You must enter data from at least one weather station",
    errorNecessarySustenanceCapacity:
      "É necessário informar os dados de quantidades por modo de oferta.",
    errorWrongNpnInput:
      "Atenção! São classificados como Suplementos Minerais, somente produtos com até 42,0 % de NNP - Equivalente Proteico. Por favor, verifique a Classificação deste Suplemento.",
    saveOptions: {
      only_save: "Save Only",
      save_and_create_new: "Save Supplement and add New",
    },
  },
  lot: {
    validationMessage: "Fill in all required fields with * correctly",
    tabs: {
      production: "Production",
      movementHistory: "Histórico Mov.",
      receipt: "Receipt",
    },

    production: {
      title: "Production lots",
      buttonNewLot: "New lots of production",
      details: {
        breadCumbsProductionLot: "Lots in Production",
        breadCumbsLotDetails: "Lot Details",
        createdAt: "Creation:",
        options: "Options",
        labelSystem: "ACTIVITY",
        labelDietStrategy: "DIET STRATEGY",
        baseline: "Baseline",
        optionalStrategies: "Optional Strategies",
        optional: "Optional",
        labelAnimals: "ANIMALS",
        labelAsymptoticWeight: "ASYMPTIC WEIGHT",
        labelMarketValuation: "MARKET EVALUATION",
        labelProductionCost: "Custo de Produção",
        makeValuation: "Evaluate",
        labelWeights: "WEIGHTS",
        labelAverageWeight: "AVERAGE WEIGHT:",
        labelAverageWeightGain: "GMD:",
        labelPicket: "PEN:",
        labelProductionArea: "USER AREA:",
        labelTotalArea: "TOTAL AREA:",
        labelSupportCapacity: "C. SUPPORT:",
        titleAnimals: "Animals",
        titleWeigth: "Weight",
        viewBy: "View by:",
        tooltipEstimatedCurve:
          'Theoretical curve obtained from RACE, click "Estimate for batch" to set Lot values',
      },
      table: {
        columns: {
          lotNumber: "Nº Lot",
          lotName: "Lot",
          salesScenario: "Selling Scenario",
          dietStrategyName: "Diet Strategy",
          profitCenter: "Activity",
          createdAtAndEndAt: "Creation / Closing",
          status: "Status",
          amount: "Amt.",
          gdp: "GDP",
          diet: "Diet",
          averageWeight: "Average weight",
          target: "Target",
        },
      },
      drawer: {
        title: "Create Production Lot",
        titleEdit: "Edit Production Lot",
        subtitle: "Complete the fields below to create a lot",
        subtitleEdit: "Complete the fields below to edit a lot",
        form: {
          status: "Status",
          name: "Nome do lote*",
          namePlaceHolder: "Nome do lote",
          identification: "Código de Importação",
          identificationPlaceHolder: "Código de Importação",
          profitCenter: "Activity*",
          profitCenterPlaceHolder: "Select the Activity",
          asymptoticWeight: "Asymptotic Weight",
          carcassHarnessing: "Carcass Harnessing",
          informPlaceHolder: "Inform",
          picket: "Pen",
          picketMessage:
            "Select the pen if you already want to allocate this lot",
          picketPlaceHolder: "Select the pen",
          dietStrategyBaseLine: "Diet Strategy",
          dietStrategyBaseLineMessage:
            "Select the strategy that will be used to feed the animals added to this lot",
          dietStrategies: "Diet Strategy - Optional",
          dietStrategiesPlaceHolder: "Select one or more diet strategies",
          dietStrategiesTooltip:
            "The selected optional diet strategies will be used in future Buy and Sell Scenarios simulations",
          checkboxSaveAndAddNewAnimals: "Save and Add Animals",
          picketId: "Piquete",
          picketLotEntry: "Data de entrada do Lote",
        },
      },
      saveOptions: {
        only_save: "Save Only",
        save_and_add_animals: "Save lot and add animals",
      },
      addAnimalsPage: {
        listLotProduction: "List: Production Lots",
        title: "Add animal to the lot",
        descriptionMessage:
          "Select animals in the gray column and move them to the orange column by clicking the",
        selectPlaceHolder:
          "Select the breed of animals that should make up the lot",
        listAnimals: "Animal List",
        productionLot: "Production Lot",
        modal: {
          message: "Click confirm to add the animals to the lot",
          lot: "Lot",
          profitCenter: "Activity",
          amount: "Quantity",
        },
        error: {
          noAnimalsTitle: "Error",
          noAnimalsMessage: "You must select one or more animals",
        },
      },
      dismemberLotPage: {
        listLotProduction: "List: Production Lots",
        title: "Dismembering Lot",
        descriptionMessage:
          "Select the animals in the table and click the 'Dismember' button to relocate the animals",
        profitCenter: "Activity",
        productionSubSystem: "Production Subsystem",
        animalsTotal: "Total Animals",
        animalsSelected: "Selected Animals",
        dismemberButton: "Dismember",
        destinationLots: "Destination Lots",
        drawer: {
          title: "Dismember Lot",
          description:
            "Select below if the dismembering will be in a new lot or an existing lot",
          newButton: "New",
          existingButton: "Existing",
          alertMessage: "The reported lot system differs from the current lot",
          searchInputPlaceHolder: "Search for the lot identification",
        },
      },
    },
    receipt: {
      title: "Receipt lot",
      buttonNewLot: "New receipt lot",
      collapse: {
        animals: "Animals",
        receiptIn: "Received in",
      },
      table: {
        columns: {
          breed: "Raça",
          gender: "Sexo",
          birthday: "Idade/meses",
          weight: "Peso estimado",
          amount: "Qtd. animais",
          confirmedAmount: "Qtd. animais recebidos",
          purchaseCost: "Custo de aquisição",
          numbers: "Numeração",
        },
        boitelColumns: {
          identification: "Identificação",
          amount: "Qtd. animais",
          breed: "Raça",
          entryDate: "Data de entrada",
          weightRange: "Faixa de peso",
          confirmedAmount: "Qtd. animais recebidos",
          identifiedAmount: "Qtd. animais identificados",
          status: "Situação",
        },
      },
      finalize: {
        title: "Creating Receiving Lots",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        buttonFinalize: "FINALIZE RECEIPT",
        buttonFinalizeCancel: "CANCEL RECEIPT",
        supplier: "Supplier",
        dateReceiving: "Receiving Date",
        animalsReceived: "Animals received",
        grid: {
          title: "Receiving Lots",
          animalsInserted: "animals inserted",
          buttonAddAnimals: "Add animals",
          columns: {
            breed: "Breed",
            gender: "Gender",
            birthday: "Age/months",
            weight: "Estimated weight",
            amount: "Amt.",
            purchaseCost: "Purchase cost",
            numbers: "Numbering",
          },
          deleteBox: {
            description: "Confirming the lot will be deleted",
            buttonConfirm: "Confirm",
            buttonCancel: "Cancel",
          },
        },
        newLotAnimalsReceipt: {
          title: "New animals",
          description: "Fill in the data for receiving animals",
          saveOptions: {
            only_save: "Save only ",
            save_and_create_new: "Save and create new receipt",
            save_and_handle: "Save and manage",
          },
        },
        newLotItem: {
          title: "Animal grouping",
          description:
            "Group the animals on receipt according to their characteristics by completing the information below",
          tabGeneralTitle: "General",
          tabNumbersTitle: "Numbering",
          breed: "Breed",
          gender: "Gender",
          birthday: "Birthday",
          birthdayPlaceHolder: "Select date",
          estimatedWeight: "Estimated Weight",
          estimatedWeightPlaceHolder: "Enter weight - kg",
          amount: "Amount",
          amoutPlaceHolder: "Enter amount",
          purchaseCost: "Purchase cost",
          purchaseCostPlaceHolder: "Enter purchase cost",
          rangeDescription:
            "Add the number of animals that have the characteristics",
          range: "Ranges",
          numbersInserted: "Numbers inserted",
          saveOptions: {
            only_save: "Save only",
            create_new: "Save and create new incoming animals",
          },
        },
        errors: {
          noAnimalsAdd: {
            title: "No animal added",
            description:
              "It is necessary to add the reported amount of animals",
          },
          amountAnimalsInLotItenBiggerThanAmountAnimalsReceveid: {
            title: "Amount of animals greater",
            description:
              "The amount of animals inserted is greater than that reported in the receiving lot",
          },
          amountAnimalsInLotItenLessThanAmountAnimalsReceveid: {
            title: "Amount of animals smaller",
            description:
              "The amount of animals inserted is less than that reported in the receiving lot",
          },
          amountAnimalsInLotItenBiggerThanAmountReported: {
            title: "Amount of animals invalid",
            description:
              "The amount of animals inserted is greater than that reported in the lot amount",
          },
        },
      },
      supplier: {
        title: "Entry of animals - Purchase",
        buttonNext: "Next",
        buttonBack: "Back",
        titleBox: "Receipt Registration",
        messageError: "Fill in all required fields *",
        supplierTitle: "Supplier",
        supplierComboBoxPlaceHolder: "Select",
        lotName: "Lot Name",
        lotNameInputPlaceHolder: "Receipt Lot Name",
        dateTitle: "Entry date",
        datePlaceHolder: "Select",
        buttonToday: "TODAY",
        qtyAnimalsTitle: "Number of animals",
        qtyAnimalsInputPlaceHolder: "Number of animals",
        receiptNameTitle: "Receipt Number",
        supplierInformationsTitle: "Supplier Information",
        documentTitle: "CNPJ",
        responsibleTitle: "Responsible",
        emailTitle: "E-mail",
        contactTitle: "Contact",
        addressTitle: "Address",
      },
    },
  },
  picket: {
    status: {
      break: "Descanso",
      pasturage: "Pastejo",
      renovation: "Reforma",
      maintenance: "Manutenção",
      leased: "Arrendado",
      deleted: "Excluído",
      active: "Ativo",
      inactive: "Inativo",
    },
    dashboards: {},
    propertyType: {
      own: "Próprio",
      foreign: "Arrendado",
    },
    tabs: {
      picket: "Piquete/Baia",
      retreat: "Retiro/Linha",
    },
    title: "Lista de piquetes / baias",
    buttonNewPicket: "Novo piquete / baia",
    table: {
      columns: {
        picketName: "Piquete / Baia",
        totalAndProductionArea: "Área total/útil",
        propertyType: "Tipo",
        profitCenterName: "Atividade",
        lotName: "Lote",
        status: "Status",
        // inAndOutLot: "Entrada/Saída de lote",
        inAndOutLot: "Entrada de lote",
        supportCapacity: "Capac. suporte",
        averageWeight: "Peso médio",
        gmd: "GMD",
        amountAnimals: "Qtd.",
      },
    },
    pastureTable: {
      title: "Estados dos Pastos",
      titleTooltip:
        "O Gráfico Estado dos Pastos, apresenta diferentes visões para que possa acompanhar e planejar as movimentações de animais entre piquetes, de forma a otimizar o uso das pastagens." +
        " - Estoque MS: Apresenta a disponibilidade (%) de MS da forrageira utilizada, conforme a estação climática e consumo dos animais presentes no Piquete. Diariamente é executado a rotina de recalculo, para atualizar a estimativa de disponibilidade dos próximos 30 dias." +
        " - Taxa de Oferta: Apresenta a relação de Oferta e Consumo de Matéria Seca (MS) da forrageira disponível no piquete, exibindo a quantidade de dias em que o Lote de animais poderá ficar no piquete, de acordo com o estoque de MS disponível e consumo previsto dos animais.",
      columns: {
        picketName: "Piquete",
        status: "Status",
        days: "Dias acumulados",
        lotation: "Taxa de lotação",
      },
      tableType: {
        dryStockOption: "Estoque MS",
        OfferRateOption: "Taxa de Oferta",
      },
    },
    drawerTitle: "Cadastro de Piquete / Baia",
    drawerTitleEdit: "Editar Piquete",
    drawerTitleEditIntensiveSubSystem: "Editar Baia",
    drawerSubTitle:
      "Complete os campos abaixo para criar um novo piquete / baia",
    drawerSubTitleEdit:
      "Complete os campos abaixo para editar o piquete / baia",
    drawerAllocateLotTitle: "Alocação de lote no piquete / baia",
    drawerAllocateLotSubTitle: "Alocação de lote no piquete / baia",
    validationMessage:
      "Preencha todos os campos obrigatórios com * corretamente",
    searchInputPlaceHolder:
      "Pesquise pela identificação do lote e pressione ENTER.",
    alertLotWithProfitCenterDifferent:
      "O piquete / baia receberá a atividade do lote selecionado!",
    detail: {
      name: "Identificação",
      productionSubSystem: "Subsistema",
      supplierId: "Fornecedor",
      supplierDivider: "FORNECEDOR",
      sustenanceCapacityDivider: "CAPACIDADE DE SUPORTE",
      pastureDivider: "FORRAGEIRA",
      infrastructureDivider: "INFRAESTRUTURA",
      pasture: "Forrageira",
      pastureDailyCost: "Custo diário",
      pastureAnimalCapacity: "Ocupação por animal",
      pastureArea: "Área disponível",
      pastureLeafDensity: "Densidade foliar",
      pastureFertilized: "Adubado",
      pastureCurrentHeight: "Altura atual do capim",
      pastureCurrentHeightDate: "Data da altura atual",
      pastureGrowthSpeed: "Velocidade Crescimento",
      troughSpacePerAnimal: "Espaço de cocho por animal",
      totalTroughSpace: "Espaço total de cocho",
      drinkingFountain: "Espaço total do bebedouro",
      lotId: "Lote",
      leaseCost: "Custo do arrendamento",
      profitCenterId: "Atividade",
      profitCenterType: "Tipo",
      pastureId: "Tipo de forrageira",
      moreInformationButton: "MAIS INFORMAÇÃO",
    },
    form: {
      name: "Identificação do piquete / baia",
      namePlaceHolder: "Identificação do piquete / baia",
      importCode: "Código de importação",
      propertyType: "O piquete / baia é interno ou terceirizado?",
      productionSubSystem: "Subsistema de produção",
      productionSubSystemPlaceHolder: "Selecione o Sistema",
      supplierId: "Fornecedor",
      supplierIdPlaceHolder: "Selecione o fornecedor",
      sustenanceCapacity: "CAPACIDADE DE SUPORTE",
      alocateLotTitle: "ALOCAR LOTE DE PRODUÇÃO",
      infrastructureDivider: "INFRAESTRUTURA",
      lotId: "Lote",
      lotIdPlaceHolder: "Selecione um lote",
      lotStartDate: "Entrada do lote atual",
      oldLotEndDate: "Saída do lote antigo",
      leaseMonthlyCost: "Custo do arrendamento",
      profitCenterId: "Atividade utilizada",
      profitCenterIdPlaceHolder: "Selecione a atividade",
      status: "Status do picket",
      sustenanceCapacityDrawerTitle: "Capacidade de Suporte do Piquete",
      sustenanceCapacityDrawerTitleIntensiveSubSystem:
        "Capacidade de Suporte da Baia",
      drinkingFountain: "Espaço total do bebedouro",
      troughSpacePerAnimal: "Espaço de cocho por animal",
      totalTroughSpace: "Espaço total de cocho",
      pastureDivider: "FORRAGEIRA",
      pastureId: "Forrageira",
      pastureIdPlaceHolder: "Selecione o tipo de forrageira",
      pastureAnimalCapacity: "Ocupação por animal",
      pastureArea: "Área disponível",
      pastureLeafDensity: "Densidade foliar",
      pastureFertilized: "Adubado",
      pastureDailyCost: "Custo diário ",
      pastureDailyCostTooltip:
        "Ao informar algum valor neste campo, o mesmo será utilizado na Estratégia de Dieta, independente da Estação Climática. Se nenhum valor for informado, será utilizado o Custo cadastrado em Pasto referente à cada Estação Climática.",
      pastureCurrentHeight: "Altura atual do capim",
      pastureCurrentHeightDate: "Data da altura atual",
      pastureGrowthSpeed: "Velocidade Crescimento",
      observation: "Observações",
      error: {
        requiredPastureId: {
          title: "Dados inválidos",
          message:
            "Selecione o tipo de forrageira quando subsistema de produção for Intensivo ou Semi-Intensivo",
        },
        requiredSupplierId: {
          title: "Dados inválidos",
          message:
            "Selecione o fornecedor quando o tipo de picket for Terceiro",
        },
        supplementConsumptionMeasureTypeInvalid: {
          title: "Dados inválidos",
          message: "Tipo de medida inválida para Tipo de Consumo selecionado",
        },
        productionSubSystemDifferentFromPicketProductionSubSystem: {
          title: "Dados inválidos",
          message:
            "Não pode alocar este lote, pois o sub-sistema de produção é diferente do piquete / baia",
        },
        sustenanceCapacityNotInformed: {
          title: "Dados inválidos",
          message: "Os dados da Capacidade de Suporte devem ser informados",
        },
      },
    },
    saveOptions: {
      only_save: "Salvar somente",
      save_and_create_new: "Criar piquete / baia e adicionar novo",
      save_and_add_lot: "Salvar e adicionar lote ao piquete / baia",
    },
    drawerMaintenance: {
      title: "Colocar piquete / baia em manutenção",
      subtitle:
        "Indique no campo abaixo o tempo que o piquete / baia ficará em manutenção",
      labelAmountDays: "Quantidade de dias",
      finishIn: "Finalizado em:",
      infoMessage: "Você será notificado quando o período for finalizado.",
    },
    drawerRenovation: {
      title: "Colocar piquete / baia em reforma",
      subtitle:
        "Indique no campo abaixo o tempo que o piquete / baia ficará em reforma",
      labelAmountDays: "Quantidade de dias",
      finishIn: "Finalizado em:",
      infoMessage: "Você será notificado quando o período for finalizado.",
    },
    drawerBreakTitle:
      "Você tem certeza que quer colocar este picket em Descanso ?",
    drawerInactivate: "Você tem certeza que quer inativar este piquete ?",
    drawerReactivate: "Você tem certeza que quer reativar este piquete ?",
    drawerDelete: "Você tem certeza de que quer excluir este piquete ?",
  },
  retreat: {
    title: "Lista de retiros",
    buttonNew: "Novo Retiro",
    table: {
      columns: {
        status: "Status",
        retreatName: "Retiro",
        area: "Área",
      },
      expandedColumns: {
        picketName: "Piquete/Baia",
        area: "Área",
        activity: "Atividade",
        status: "Status",
        lot: "Lote",
      },
    },
  },
  profitCenter: {
    title: "Activities",
    saveOptions: {
      only_save: "Save Only",
      save_and_create_new: "Create activity and add new",
    },
    table: {
      title: "Activities list",
      columns: {
        name: "Name",
        status: "Status",
        type: "Type",
        productionSubSystem: "Production Subsystem",
      },
    },
    drawerTitle: "Activity Registration",
    drawerTitleEdit: "Edit Activity",
    drawerSubTitle: "Complete the fields below to create a new activity",
    drawerSubTitleEdit: "Complete the fields below to edit the activity",
    validationMessage: "Fill in all required fields with * correctly",
    modalCheckbox: "Save and add lot to picket",
    form: {
      name: "Activity ID",
      namePlaceHolder: "Activity Name",
      productionSubSystem: "Production Subsystem",
      productionSubSystemPlaceHolder: "What is the production subsystem?",
      type: "Type",
      typePlaceHolder: "What is the Activity type?",
      status: "Activity Status",
    },
  },
  farm: {
    title: "Farms",
    table: {
      title: "List of Farms",
      columns: {
        name: "Name",
        status: "Status",
      },
    },
    drawerTitle: "Farm Registration",
    drawerTitleEdit: "Edit Farm",
    drawerSubTitle: "Complete the fields below to create a new farm",
    drawerSubTitleEdit: "Complete the fields below to edit the farm",
    validationMessage: "Fill in all required fields with * correctly",
    form: {
      name: "Fancy Farm Name",
      namePlaceHolder: "Fancy Farm Name",
      group: "Group",
      groupPlaceHolder: "Select group",
      companyName: "Company Name",
      companyNamePlaceHolder: "Farm Company Name",
      document: "CNPJ",
      documentPlaceHolder: "CNPJ",
      zipCode: "Zip Code",
      zipCodePlaceHolder: "Zip Code",
      address: "Address",
      addressPlaceHolder: "Road, Highway, Street ....",
      city: "City",
      cityPlaceHolder: "City",
      state: "UF",
      statePlaceHolder: "UF",
      status: "Farm Status",
      country: "Country",
      countryPlaceHolder: "Country",
      totalArea: "Total Area",
      rentAreaOutSourced: "Leased area for third parties",
      livestockArea: "Living Area",
      nonProductiveArea: "Non-Productive Area",
      cultivationArea: "Cultivation area",
      improvementsArea: "Improvement Area",
      complementAddress: "Complement",
      complementAddressPlaceHolder: "Complement",
      weatherStation: "Estação Metereológica",
      weatherStationPlaceHolder: "Estação Metereológica",
    },
    saveOptions: {
      only_save: "Save Only",
      save_and_create_new: "Create farm and add new",
    },
  },
  farmOwner: {
    title: "Proprietários da Fazenda",
    buttonNew: "Novo",
    table: {
      columns: {
        code: "Cód.",
        name: "Nome",
        document: "CPF/CNPJ",
        participation: "% Part.",
        mainOwner: "Principal ?",
      },
    },
    form: {
      code: "Còdigo",
      name: "Nome",
      document: "CPF/CNPJ",
      participation: "Participação",
      mainOwner: "Principal ?",
    },
  },
  supplier: {
    title: "Suppliers",
    types: {
      supplement: "Supplement",
      technology: "Technology",
      cattleFarmer: "Cattle Farmer",
      others: "Outros",
      veterinarySupplement: "Insumos Veterinários",
    },
    table: {
      title: "List of Suppliers",
      columns: {
        name: "Name",
        type: "Type",
        status: "Status",
        phoneNumber: "Contact",
        email: "Email",
        city_uf: "City/State",
      },
    },
    drawerTitle: "Supplier Registration",
    drawerTitleEdit: "Edit Supplier",
    drawerSubTitle: "Complete the fields below to create a new supplier",
    drawerSubTitleEdit: "Complete the fields below to edit the supplier",
    validationMessage: "Fill in all required fields with * correctly",
    form: {
      name: "Supplier",
      namePlaceHolder: "Supplier name",
      type: "Type",
      typePlaceHolder: "Supplier type",
      phoneNumber: "Contact",
      phoneNumberPlaceHolder: "Contact phone",
      document: "CPF/CNPJ",
      documentPlaceHolder: "CPF or CNPJ",
      email: "Email",
      emailPlaceHolder: "E-mail",
      zipCode: "Zip Code",
      zipCodePlaceHolder: "Zip Code",
      address: "Address",
      addressPlaceHolder: "Road, Highway, Street ....",
      city: "City",
      cityPlaceHolder: "City",
      state: "UF",
      statePlaceHolder: "UF",
      country: "Country",
      countryPlaceHolder: "Country",
      status: "Supplier Status",
      groupFarmVisibility: "Who will use the supplier?",
      radiogroup: {
        farm: "This farm",
        group: "Farms of Group",
      },
    },
    saveOptions: {
      only_save: "Save Only",
      save_and_create_new: "Create supplier and add new",
    },
  },
  animal: {
    dashboard: {
      activeAnimals: "ACTIVE ANIMALS",
      activeLots: "ACTIVE LOTS",
      animalsByGender: {
        title: "Amount by sex",
        female: "Female",
        male: "Male",
        status: "Status",
      },
    },
    columns: {
      title: "List of animals",
      buttonCompare: "COMPARE",
      handlingNumber: "Identification",
      picket: "Pen",
      lot: "Lot",
      breed: "Breed",
      gender: "Gender",
      birthdayMonths: "Age",
      weightRanking: "Ranking",
      currentWeight: "Current Weight",
      gdp: "GMD",
      target: "Target",
      status: "Status",
      reproductionCategory: "Categoria Rep.",
      reproductionStatus: "Situação Rep.",
      reproductionState: "Estado Rep.",
    },
    details: {
      animalProductionPageLink: "Producion Animals",
      animalPageDescription: "Animal Details",
      emptyMessage: "No animal data found",
      bovexoId: "Id Bovexo",
      age: "Birthday",
      sisbov: "SISBOV",
      source: "Supplier",
      castrated: "Castrated",
      tagId: "Tag Id",
      sex: "Sex",
      male: "Male",
      female: "Female",
      acquisition: "Acquisition",
      handlingNumber: "Identification",
      weight: "Weight",
      current: "Current",
      location: "Location",
      picket: "Pen",
      lot: "Lot",
      breed: "Breed",
      weightGain: "Weight gain",
      accumulated: "Accumulated",
      lastWeight: "Last Weight",
      forSlaughter: "to Slaughter",
      expected: "Expected",
      asymptoticWeight: "Asymptotic Weight",
      economicReturn: "Economic Return",
      currentValue: "Current Value",
      accumulatedCost: "Accumulated Cost",
      margin: "Margin",
      animalFarmFunction: "Finalidade",
      animalReproductionCategory: "Categoria",
      appropriateCosts: "Custos Apropriados",
      femaleSituation: "Situação",
      femaleSituationTime: "Tempo de situação",
      coverageDate: "Data Cobertura",
      coverageType: "Tipo Cobertura",
      coverageSemenOrMaleIdentification: "Touro",
      weightCoverage: "Peso na Cobertura",
      tab: {
        production: "Production",
        genetic: "Genetic",
        geneology: "Genealogia",
        evaluation: "Evaluation",
        zoonosis: "Zoonosis",
        weighing: "Weighing",
        dailyWeights: "Daily Weights",
        weightGains: "Weight Gains",
      },
      history: {
        title: "Weight history",
        tooltipHistoryWeight: "",
        grid: {
          emptyMessage: "There is no registered weight history",
          in: "In",
          out: "Out",
          manual: "Manual",
          date: "Date",
          hour: "Hour",
          inOut: "I/O",
          weight: "Weight",
          eventTitle: "Tipo",
          originEvent: {
            birthday: "Nasc",
            purchase: "Compra",
            weaning: "Desm",
            sale: "Venda",
            weighing: "",
          },
        },
      },
      totalWeight: {
        title: "TOTAL WEIGHT",
        tooltip:
          "This chart compares the animal's last weight with the average of similar animals in the same genetic group, breed and same lot",
        graph: {
          currentWeight: "Current Weight",
          lot: "Lot",
          genetic: "Genetic",
          breed: "Breed",
          weight: "Weight",
          type: "Type",
          estimated: "Estimated",
        },
      },
      averageDailyGain: {
        title: "AVERAGE DAILY GAIN",
        tooltip:
          "This chart compares the animal's last daily weight gain with the average of similar animals in the same genetic group, breed and same lot",
        graph: {
          currentGain: "Current Gain",
          lot: "Lot",
          genetic: "Genetic",
          breed: "Breed",
          weight: "Weight",
          type: "Type",
          estimated: "Estimated",
        },
      },
      dailyWeightGain: {
        title: "WEIGHT AND GDP EVOLUTION",
        graph: {
          weight: "Weight",
          time: "Days",
        },
      },
    },
    drawerTitleEdit: "Edit Animal",
    drawerSubTitleEdit: "Fill in the data below to edit an animal",
    drawerSubTitleEditDeathAnimal: "Animal dead, all fields have been disabled",
    drawerAnimalDeathTitle: "Animal Death",
    animalDeathPopConfirmTitle:
      "Upon confirmation, the animal is considered to be in a state of permanent death",
    drawerAnimalDiscardTitle: "Descarte do animal",
    edit: {
      form: {
        nickname: "Name",
        nicknamePlaceHolder: "Name",
        sisbov: "SISBOV",
        sisbovPlaceHolder: "SISBOV",
        tagId: "Tag ID",
        tagIdPlaceHolder: "Tag ID",
        sex: "Sex",
        male: "Male",
        female: "Female",
        acquisition: "Acquisition",
        handlingNumber: "Identification",
        handlingNumberPlaceHolder: "Animal ID",
        breed: "Race",
        breedPlaceHolder: "Select a breed",
        cap: "Castrated animal?",
        birthday: "Date of birth",
        birthdayWeight: "Birth Weight",
        firstInseminationWeight: "First Insertion",
        weaningWeight: "Weaning Weight",
        weaningDate: "Weaning Date",
        asymptoticWeight: "Asymptotic Weight",
        asymptoticWeightInfoTooltip:
          "Peso assintótico é o peso que, com base nas informações que o usuário/produtor possui, sobre o histórico do Animal ou Lote de Animais, (considerando a genética, manejo anterior, interação genótipo-ambiente, frame, entre outras variáveis), acredite ser o potencial limite de peso. Ou seja, acima deste peso, dificilmente este animal ganhará peso, em condições normais.",
        acquisitionDate: "Acquisition Date",
        purchaseCost: "Acquisition Price",
        acquisitionEstimatedWeight: "Weight acquisition",
        carcassHarnessing: "Carcass Harnessing",
        animalFarmFunction: "Finalidade",
        animalReproductionCategory: "Categoria",
        sisbovIdentificationDate: "Data Identificação",
        sisbovInsertBNDDate: "Data Inclusão BND",
        sisbovSlaughterDate: "Data Liberação Abate",
        farmOwner: "Proprietário",
      },
      weights: "WEIGHTS",
      receipt: "RECEIPT",
      sale: "SALE",
    },
    discard: {
      motives: {
        Age: "Idade",
        ReproductiveProblems: "Problemas Reprodutivos",
        IllnessesOrPhysicalProblems: "Doenças ou Problemas Físicos",
        BodyCondition: "Condição corporal",
        NegativePregnancy: "Prenhez Negativa",
        MaternalAbility: "Habilidade Materna",
      },
    },
    carcassHarnessingInfoTooltip:
      "O aprov do animal deve ser informado caso tenha uma análise de carcaça por ultrassom. O aproveitamento do Lote e da Estratégia de dieta são expectativas. Quando ambas estão presentes, prevalecem, nesta ordem Animal, Dieta, Lote",
  },
  dietStrategy: {
    new: {
      title: "Strategy Making",
      message:
        "Select the total strategy period and set the diets and execution time of each",
      name: "Strategy Name",
      namePlaceHolder: "Diet Strategy Name",
      totalPeriod: "Total Period",
      year: "Year",
      oneYear: "1 year",
      twoYears: "2 years",
      threeYears: "3 years",
      dietAndExecutionTime: "Diets & Runtime",
      dietPlaceHolder: "Select a diet",
      deleteDietPeriod: "Erase Diet",
      totalTime: "Total Time",
      weeks: "Week (s)",
      buttonAddDiet: "Add Diet",
      drawerTitle: "Strategy Summary",
      start: "Start",
      end: "End",
      notification: {
        success: {
          message: "Diet strategy created!",
          description: "The strategy has been added to the system.",
        },
        error: {
          dontHaveName: {
            message: "Error",
            description: "You must enter a name for the diet strategy!",
          },
          dontHaveDiet: {
            message: "Error",
            description: "All periods must have a selected diet.",
          },
        },
      },
    },
    title: "List of Diet Strategies",
    buttonNewDietStrategy: "New Strategy",
    table: {
      status: "Status",
      name: "Name",
      diet: "Diet",
      totalTime: "Total Time",
    },
  },
  generalParameters: {
    weatherSeason: {
      title: "Climate Seasons",
      editMessage:
        "Define the most suitable weather stations for the farm location using the box next to it:",
      table: {
        columnDayMonth: "DAYS / MONTH",
        columnNextSeason: "NEXT SEASON",
        columnMonthSeason: "SEASON OF THE MONTH",
      },
    },
    farmDeathReason: {
      buttonNew: "Novo",
      table: {
        title: "Causa Mortis",
        columns: {
          name: "Nome",
          status: "Situação",
        },
      },
    },
    quotas: {
      buttonNew: "Novo",
      table: {
        title: "Cotas",
        columns: {
          name: "Nome",
          type: "Tipo",
          status: "Situação",
        },
      },
    },
    successParameterUpdated: "Parameters changed successfully!",
    errorParameterUpdated: "Oops ... the parameters have not changed",
  },
  cost: {
    tabs: {
      fixedCost: "Imobilizados",
      infrastructureCost: "Infraestrutura e mão de obra",
      operatingVariableCost: "Variáveis operacionais",
      zootechnicalVariableCost: "Zootécnicos",
      sanityCost: "Cordura",
    },
    form: {
      error:
        'É necessário informa os campos "Valor do Custo" e "Depreciação" nos custos marcados em vermelho',
      growth: "Cria",
      recreateFattenPasture: "R&E - Pasto",
      recreateFattenSemiConfinement: "R&E - Semi-Confinamento",
      recreateFattenConfinement: "R&E - Confinamento",
      area: "Área",
      potentialOccupation: "Ocupação Potencial",
      staticCapacity: "Capacidade Estática",
      staticCapacityTooltip:
        "Os valores deste campo são calculados, multiplicando-se a Área pela Ocupação Potencial.",
      fence: "Cercas",
      costValue: "Valor do Custo",
      depreciationValue: "Depreciação",
      total: "Total",
      amountAnimals: "Qtd. de Cabeças",
      totalDailyCostPerAnimal: "Custo diário por Animal",
      dietCost: "Dieta",
    },
    costType: {
      FixedCost: "Imobilizados",
      InfrastructureCost: "Infraestrutura e mão de obra",
      OperatingVariableCost: "Variáveis operacionais",
      ZootechnicalVariableCost: "Zootécnicos",
      sanityCost: "Cordura",
    },
    code: {
      1: "Construção sede e instal.",
      2: "Impostos (ITR, CAN)",
      3: "Seguros",
      4: "Formação de pastos",
      5: "Construção de cercas",
      6: "Construção de curral",
      7: "Construção de açudes",
      8: "Aquisição de máquinas e implementos",
      9: "Construção de galpões e instal.",
      10: "Mão de obra operacional",
      11: "Mão de obra técnica prop.",
      12: "Assitência Técnica 3RDS",
      13: "Energia",
      14: "Telefone / Internet",
      15: "ERP",
      16: "Contador",
      17: "Manutenção de Instal. e Galpões",
      18: "Manutenção de Máquinas e Equipamentos",
      19: "Combustível e Lubrificantes",
      20: "Manutenção de pastos",
      21: "Adubação",
      22: "Manutenção de Currais e Cercas",
      23: "Aluguel de Pasto",
      24: "Vacinas",
      25: "Vermífugos",
      26: "Medicamentos",
      27: "Identificação",
      28: "Custo com Tropa & Relacionados",
      29: "Outros custos Adm.",
    },
  },
  saleScenario: {
    error: {
      DietStrategyExpired:
        "Não foi possível processar o cenário de venda, pois o mesmo possui estratégias de dietas vencidas.",
      InternalError: "Houve um erro ao processar o cenário. Contate o suporte.",
    },
  },
  movementHistory: {
    title: "Histórico(s) de Movimentações",
    table: {
      columns: {
        originalLotsNames: "Lotes de Origem",
        destinationLotsNames: "Lotes de Destino",
        requestDate: "Data da Movimentação",
        fromDismemberLot: "Desmembramento?",
        fileName: "Nome do arquivo",
        animalHandlingNumber: "Animal",
        lotOriginName: "Lote de Origem",
        lotDestinationName: "Lote de Destino",
      },
    },
  },
  financial: {
    tabs: {
      costing: "Custeio",
      cashFlow: "Fluxo de Caixa",
      parameters: "Parâmetros",
      transactions: "Movimentações Financeiras",
      animalSell: "Venda de Produção",
      resultCenterAnalysis: "Análise CR/CC",
    },
    costType: {
      Growth: "Cria",
      RecreateFattenPasture: "Extensivo",
      RecreateFattenSemiConfinement: "Semi-Intensivo",
      RecreateFattenConfinement: "Intensivo",
      Agriculture: "Agricultura",
      Others: "Outros",
    },
    transactions: {
      title: "Movimentações",
      buttonCreateNewFinancialTransaction: "Novo lançamento",
      buttonExportTransactions: "Exportar movimentações",
      form: {
        title: "Novo lançamento",
        documentNumber: "Nº Doc. Fiscal",
        supplierId: "Fornecedor",
        billingDate: "Data do faturamento",
        expirationDate: "Data do vencimento",
        mainValueCurrency: "Selecione uma moeda",
        mainValue: "Valor Principal",
        fees: "Valor Juros/Multas",
        discount: "Descontos",
        value: "Valor da transação",
        payment: "Pagamento",
        actions: "Ação",
        actionsOptions: {
          launch: "Lançar",
          launchAndSettle: "Lançar e Baixar",
          settle: "Baixar",
        },
        settleDate: "Data da Baixa",
        inCash: "À vista",
        part: "Parcelado",
        financialNatureId: "Natureza",
        financialClassId: "Classe",
        costActivityType: "Atividade",
        description: "Descrição",
        resultCenter: "Centro de Resultado",
        resultCenterApportionmentDefault: "Padrão",
        resultCenterApportionmentCustom: "Redefinir",
        transactionType: "Tipo",
        clientId: "Cliente",
        financialProjectId: "Projeto Financeiro",
        bonus: "Bonificação",
        errors: {
          installmentsNotGeneratedError:
            'É necessário gerar as parcelas. Para isso clique na opção "Parcelado" presente no campo "Pagamento".',
        },
      },
      modalInstallments: {
        title: "Gerar parcelas",
        value: "Valor da transação",
        expirationDate: "Data do vencimento",
        installmentTotal: "Nº de Parcelas",
        buttonCalc: "Calcular",
      },
      table: {
        columns: {
          nature: "Natureza",
          class: "Classe",
          values: "Valores",
          origin: "Origem",
          billingDate: "Data faturamento",
          expirationDate: "Data vencimento",
          installment: "Parcela",
          documentNumber: "Nº Doc. Fiscal",
          value: "Valor",
          isPaidPartially: "Parc. Pago",
          isSettled: "Status",
          settleDate: "Data da Baixa",
          financialClassDepreciation: "Alocação",
          valueDepreciation: "Custo Alocação",
          supplierName: "Fornecedor/Cliente",
          costActivityType: "Atividade",
          r: "CR",
          financialProjectName: "Projetos",
          transactionType: "Tipo",
          entityName: "Fornecedor/Cliente",
        },
      },
      modalResultCenterApportionment: {
        title: "Rateio por Centro de Resultado",
      },
    },
    cashFlow: {
      title: "Fluxo de Caixa",
      buttonCreateBudget: "Novo orçamento",
      filters: {
        status: "Status",
        visualization: "Visualização",
        exibition: "Exibição",
        harvest: "Safra",
        costType: "Atividade",
        financialProjectId: "Projeto Financeiro",
      },
      table: {
        columns: {
          nature: "Natureza",
          class: "Classe",
          values: "Valores",
        },
      },
    },
    costing: {
      title: "Tabela de Custos",
      filters: {
        visualization: "Visualização",
        harvest: "Safra",
        costType: "Atividade",
        financialProjectId: "Projeto Financeiro",
      },
      table: {
        columns: {
          nature: "Natureza",
          class: "Classe",
          values: "Valores",
        },
      },
    },
    parameters: {
      tabs: {
        parameters: "Parâmetros",
        costing: "Custeio",
        resultCenter: "Centro de Resultado",
        costCenter: "Centro de Custo",
        financialProject: "Projetos Financeiros",
      },
      generalFinancialParameters: {
        title: "Parâmetros",
        parameter4001: "Utilizar conversão de moedas?",
        parameter4002: "Custos Nutricionais",
        parameter4002Help:
          "Defina se os custos nutricionais serão apropriados aos animais a partir da Estratégia de Dieta ou do Fornecimento",
        farmConvertions: {
          title: "Moedas",
          buttonNew: "Nova Moeda",
          table: {},
          form: {
            titleNew: "Nova Moeda",
            currency: "Moeda",
            standard: "Colocar como padrâo?",
          },
        },
      },
      costing: {
        title: "Tabela de Parâmetros Custeio",
        table: {
          columns: {
            nature: "Natureza",
            stock: "Controle de Estoque",
            class: "Classe",
            depreciation: "Depreciação (Meses)",
            shouldCost: "Custeio",
            shouldDisbursement: "Desembolso",
            product: "Produto",
            costCenter: "Centro de Custo",
            type: "Tipo",
          },
        },
      },
      resultCenter: {
        title: "Tabela de Centros de Resultados",
        buttonNew: "Novo",
        form: {
          title: "Centros de Resultado",
        },
        financialNatureApportionmentForm: {
          title: "Critérios de Rateio de Despesas/Custos Indiretos",
        },
      },
      costCenter: {
        title: "Tabela de Centros de Custos",
        buttonNew: "Novo",
        form: {
          title: "Centros de Custos",
        },
        apportionmentForm: {
          title: "Centros de Custo x Centros de Resultado",
        },
        modal: {
          title: "Centros de Custos",
          table: {
            columns: {
              financialClass: "Classe",
            },
          },
        },
      },
    },
    animalSell: {
      title: "Venda de Produção",
      buttonNew: "Nova venda",
      buttonExport: "Resultado de Vendas",
      form: {
        title: "Venda de Animais",
        animalSelectionDescription:
          "Selecione os animais na tabela para proceder com a venda.",
        sellDate: "Data de saída",
        client: "Cliente",
        priceNegotiated: "Valor negociado",
        weightPerAnimal: "Peso médio/animal",
        carcassHarnessing: "Ap. carcaça",
        documentNumber: "Nº Doc. Fiscal",
        supplier: "Fornecedor",
        billingDate: "Data do faturamento",
        expirationDate: "Data do vencimento",
        payment: "Pagamento",
        inCash: "À vista",
        part: "Parcelado",
        resultCenter: "Centro de Resultado",
        resultCenterApportionmentDefault: "Padrão",
        resultCenterApportionmentCustom: "Redefinir",
        totalValue: "Valor Total",
      },
      table: {
        columns: {
          origin: "Origem",
          documentNumber: "Nº Doc. Fiscal",
          client: "Cliente",
          sellDate: "Dt. Venda",
          amountAnimais: "Qtde Animais",
          gender: "Sexo",
          totalValue: "Valor Total",
          averageValue: "Valor/Cabeça",
          carcassHarnessing: "% Aprov. Carcaça",
          paymentType: "Pagamento",
          financialProjectName: "Projetos",
        },
      },
    },
    resultCenterAnalysis: {
      filters: {
        resultCenter: "Centro de Resultado",
        costCenter: "Centro de Custo",
        period: "Período",
        visualization: {
          label: "Visualização",
          costing: "Custeio",
          cashFlow: "Fluxo de Caixa",
        },
        buttonConfirm: "Processar",
      },
      income: {
        title: "Receitas",
        costCenterName: "Centro de Custo",
        total: "Valor Total",
        totalPercentage: "Total (%)",
      },
      expense: {
        title: "Despesas",
        costCenterName: "Centro de Custo",
        total: "Valor Total",
        totalPercentage: "Total (%)",
      },
      total: "Resultado",
    },
  },
  costType: {
    AllTypes: "Todos",
    Growth: "Cria",
    RecreateFattenPasture: "R&E - Pasto",
    RecreateFattenSemiConfinement: "R&E - Semi-Confinamento",
    RecreateFattenConfinement: "R&E - Confinamento",
    Agriculture: "Agricultura",
    Others: "Outros",
  },
  buttons: {
    back: "Back",
    save: "Save",
    cancel: "Cancel",
    confirm: "Confirm",
    login: "Login",
    buttonNewProfitCenter: "New system",
    buttonNewFarm: "New farm",
    buttonNewSupplier: "New supplier",
    yes: "Yes",
    no: "No",
    putToDeath: "Put to death",
    allocateLot: "Allocate Lot",
    download: "Download",
    processWeights: "Process weighing",
    processAnimals: "Process animals",
    processSupplements: "Process supplements",
    edit: "Edit",
    editing: "Editing",
    addInfo: "Report data",
    delete: "Delete",
    useAsFavorite: "Use as favorite",
    generate: "Gerar",
    putToDiscard: "Descartar",
    process: "Processar",
  },
  topbar: {
    title: "Administration",
    group: {
      title: "Group",
      subtitle: "Select a group",
      submenus: {
        farm: "Farms",
        add_farm: "ADD FARM",
      },
    },
    question: {
      title: "Questions",
      submenus: {},
    },
    notification: {
      title: "Notifications",
      submenus: {},
      footer: "See all",
    },
    add: {
      title: "Add",
      submenus: {
        profit_center: "Activity",
        picket: "Pen",
        picketSupplementSupply: "Novo Fornecimento",
        user: "User",
        balance: "Balance",
        sell_and_buy: "Buying and selling scenario",
        animal: "Animal",
        lotProduction: "Production lot",
        buy: "Buy",
        birth: "Birth",
        importAnimalsWeights: "Import weighs",
        importAnimals: "Import animals",
        importSupplements: "Import supplements",
        importFinancialTransactions: "Importar mov. financeiras",
        downloadBovManager: "Dowload BovManager",
      },
    },
    language: {
      title: "Translation",
      submenus: {
        pt_br: "Portuguese - BR",
        en: "English - US",
        es: "Spanish - SP",
      },
    },
    user: {
      title: "User",
      submenus: {
        edit_profile: "Edit profile",
        change_user: "Change user",
        change_password: "Change Password",
        add_new_user: "Create new user",
        signout_application: "Sign-out Application",
      },
    },
    search: {
      title: "Search",
      submenus: {
        user: "User",
        profit_center: "Activity",
        picket: "Pen",
        balance: "Balance",
        animal: "Animal",
        buy: "Buy",
        birth: "Birth",
      },
    },
  },
  table: {
    menu: {
      edit: "Edit",
      details: "Details",
      delete: "Delete",
      inactivate: "Inativate",
      activate: "Activate",
      addAnimalsToLotProduction: "Add animals",
      dismemberLotProduction: "Lot Dismembering",
      allocateLotToPicket: "Allocate Lot to Pen",
      putPicketInMaintenance: "Maintain",
      putPicketInBreak: "Rest",
      putPicketInRenovation: "Reform",
      setBaseline: "Set as Baseline",
      movePickets: "Mov. Pickets",
    },
  },
  error: {
    formError: "Please fill in the required fields (*) correctly.",
    noGroupSelected: "Select a group from the top menu",
    sisbovInvalidTitle: "Invalid Sisbov",
    sisbovInvalidMessage: "SISBOV number must contain at least 15 characters",
  },
  notifications: {
    types: {
      newObject: "New Object",
      purchase: "Purchase",
      sale: "Sale",
      alert: "Alert",
      warning: "Warning",
      other: "Other",
    },
    drawer: {
      title: "Your notifications",
      buttonVilualizeAll: "MARK ALL AS READ",
      buttonLoadOldNotifications: "Load Old",
      notificationsNotRead: "unread notifications",
    },
  },
  sessionExpired: {
    title: "Your session has expired!",
    message:
      "To keep your account secure, we periodically check your presence by logging in again",
    titleAlmostExpired: "Your session will expire soon!",
    messageAlmostExpired:
      "To keep your account secure, we periodically check your presence, be sure to save what you are doing before your session expires",
    buttonLogout: "Log Out Now",
    buttonConfirm: "Okay, got it",
    toExpire: "to expire...",
  },
  sidebar: {
    home: "Home",
    farm: "Farms",
    profit_center: "Activity",
    picket: "Pens",
    lot: "Lots",
    animal: "Animals",
    decision: "Decisions",
    parameters: "Parameters",
    imports: "Imports",
    administration: "Administration",
    suppliers: "Suppliers",
    operations: "Operations",
    breed: "Breeds",
    handling: "Managements",
    diet: "Diets",
    pasture: "Pastures",
    supplement: "Supplements",
    adminAndUser: "Farm employees",
    userInvite: "Access invitations",
    client: "Clients",
    payment: "Payments",
    financial: "Movimentações Financeiras",
  },
  languages: {
    pt_br: "PT",
    en: "IN",
    es: "ES",
  },
  status: {
    active: "Active",
    inactive: "Inactive",
    dismembered: "Dismembered",
    pendingData: "Pending Data",
    informedData: "Informed Data",
  },
  productionSubSystem: {
    semiIntensive: "Semi-Intensivo",
    intensive: "Intensivo",
    extensive: "Extensivo",
    SI: "Semi-Intensivo",
    I: "Intensivo",
    E: "Extensivo",
  },
  productionSystem: {
    fatten: "Fatten",
    create: "Create",
    recreate: "Recreate",
  },
  kpi: {
    capacity: "Lotação",
    production: "Produção",
    cost: "Custo",
    margin: "Margem",
    gdp: "GPD",
    enjoy: "Desfrute",
    carbonFootprint: "Pegada Carbônica",
    disbursement: "Desembolso Cab/Mês",
    weanCalf: "Kg Bez. Desm / Kg Vaca",
    iep: "IEP",
    reproductiveEfficiency: "Eficiência Reprodutiva",
    weanCalfCost: "Custo Bezerro Desmamado",
    discardedCows: "Vacas Descartadas",
    assetValue: "Valor do Ativo",
    incrementalMargin: "Margem Incremental",
    tooltips: {
      tooltipCapacity:
        "A Lotação é a relação (em %) da quantidade atual de cabeças alocadas e a capacidade total, conforme definido no cadastro das baias de confinamento.",
      tooltipEnjoy:
        "A taxa de desfrute mede a capacidade do rebanho em gerar excedente (@) em um determinado espaço de tempo.",
      tooltipProduction: "Quantidade de @ produzida por hectare/ano.",
      tooltipGDP: "Ganho de Peso Diário, desde a entrada no animal da Fazenda.",
      tooltipMargin: "Margem de lucro esperada por @ produzida.",
      tooltipCost: "Custo de Produção da @.",
      tooltipCarbonFootprint:
        "Meta de emissão máxima em Kg de CO₂E por Kg de Ganho de Peso.",
      tooltipDisbursement:
        "Desembolso Cabeça/Mês dos animais atualmente em cada subsistema de produção.",
      tooltipAssetValue: "Valor ativo.",
      tooltipIncrementalMargin: "Margem incremental.",
      tooltipWeanCalf:
        "Indicador da proporção do peso dos animais na desmama, em relação às fêmeas de reprodução aptas a reprodução. São consideradas, como aptas, as fêmeas de finalidade “Reprodução” e que atendam os parâmetros reprodutivos.",
      tooltipIEP:
        "Intervalo entre partos das fêmeas de reprodução, que tenham parido ao menos 2 vezes (Secundíparas)",
      tooltipReproductiveEfficiency:
        "Eficiência Reprodutiva final das fêmeas expostas, considerando a Taxa de Prenhez (TP), Taxa de nascimento (TN) e Taxa de Desmama (TD).",
      tooltipWeanCalfCost:
        "Custo do bezerro desmamado, incluindo todos os custos incorridos pela vaca e pelo bezerro.",
      tooltipDiscardedCows: "% de vacas descartadas no período.",
    },
  },
  zipCodeValid: "Zip Code finded",
  zipCodeInvalid: "Zip Code not finded",
  defaultSelectPlaceholder: "Select an option",
  defaultMultiSelectPlaceholder: "Selecione uma ou mais opções",
  defaultDatePickerPlaceholder: "Select a date",
  defaultPlaceholder: "Enter a value",
  filters: {
    applyFilter: "APPLY FILTER",
    cleanFilter: "Clean Filter",
    filterBy: "Filter by",
    filterByOptions: {
      age: "Age",
      gender: "Sex",
      breed: "Race",
      suplier: "Suppliers",
      farmOwner: "Proprietários",
    },
    compareBy: "Compare by",
    value: "Value(s)",
    male: "Male",
    female: "Female",
    min: "Minimal",
    max: "Maximum",
    equal: "Equal",
    greaterThan: "Greater than",
    lessThan: "Less than",
    between: "Between",
  },
  groupAndFarmModal: {
    hello: "Hello",
    groupSelectedLabel: "Select a farm group",
    groupSelectedPlaceHolder: "Select a group",
    farmSelectedLabel: "Select a farm",
    farmSelectedPlaceHolder: "Select a farm",
    buttonAccessFarm: "Access Farm",
  },
  importAnimalWeights: {
    title: "Import Weighing History",
    message:
      '<p class = "message"> Download <strong> the template below, complete the spreadsheet </strong> with the weighings for this lot, <strong> import </strong> then and click process weighings </p> ',
    messageForDifferentsLots:
      '<p class="message">Faça <strong>download do template abaixo, complete a planilha</strong> com os animais e seus respectivos lotes, <strong>faça a importação</strong> em seguida e clique em processar animais</p>',
    draggerMessage: "Click or drag the file to this area to upload",
  },
  importAnimals: {
    title: "Import animals into production lot",
    message:
      '<p class = "message"> Select a production lot, download <strong> template below, complete worksheet </strong> with the animals that make up the lot, <strong> import </strong> in then click on process animals </p> ',
    messageForDifferentsLots:
      '<p class="message">Faça <strong>download do template abaixo, complete a planilha</strong> com os animais e seus respectivos lotes, <strong>faça a importação</strong> em seguida e clique em processar animais</p>',
    messageForIdentifyAnimals:
      '<p class="message">Faça <strong>download do template abaixo, complete a planilha</strong> com os animais e seus respectivos lotes e códigos de lote de recebimento, <strong>faça a importação</strong> em seguida e clique em processar animais</p>',
    draggerMessage: "Click or drag the file to this area to upload",
    helpMessage:
      'Import animals by lot and supplier: Inform the Batch and Supplier for each animal in the spreadsheet. In this way, it is possible to import animals from different suppliers and for more than one Production Lot. The Supplier and the Production Batch must be registered in advance, and the "Batch Identification" field for the Production Batch must be used in the spreadsheet, while for the Supplier, inform the "CPF/CNPJ" of its registration.\nImport animals for a single batch and supplier: Select a Production Batch and a Supplier already registered. If the animal to be imported is from the farm itself, select the option "Animal(s) provided by the farm itself".',
  },
  error404: {
    title: "ERROR 404",
    subTitle: "Page not found",
    button: "Go Home",
  },
  months: {
    january: "January",
    jan: "Jan",
    february: "February",
    feb: "Feb",
    march: "March",
    mar: "Mar",
    april: "April",
    apr: "Apr",
    may: "May",
    mmay: "May",
    june: "June",
    jun: "Jun",
    july: "July",
    jul: "Jul",
    august: "August",
    aug: "Aug",
    september: "September",
    sep: "Sep",
    october: "October",
    oct: "Oct",
    november: "november",
    nov: "Nov",
    december: "December",
    dec: "Dec",
  },
  notInformed: "Uninformed",

  Profile: {
    drawerTitle: "Personal Data",
    form: {
      fullName: "Complete Name",
    },
  },

  scenarePage: {
    title: "Cenários Boitel",
    table: {
      column: {
        clientID: "Client ID",
        name: "Nome Cenário",
        createdAt: "Data Criação",
        updatedAt: "Data Ult. Atualiz.",
        status: "Status",
        labelStatus: {
          hired: "Contratado",
          saved: "Salvo",
        },
      },
      detailsRow: {
        rangeWeight: "Faixa Peso",
        amountAnimals: "Qtde Animais",
        entryDate: "Data Entrada",
        exitDay: "Data Saída",
        daysOfStay: "Qtd Diárias",
        dailyPrice: "Preço Dia",
        dietStrategy: "Dieta",
        carcassHarnessing: "Aprov. Carcaça - %",
        totalIncome: "Receita Total",
        incomeForCab: "Receita/Cab",
        marginForCab: "Lucro/Cab",
      },
    },
  },

  boitelSaleScenario: {
    title: "Cenários venda",
    result: "Resultado(s)",
    btn: {
      newScenare: "Novo Cenário",
    },
    table: {
      column: {
        scenare: "Cenário",
        client: "Cliente",
        favorite: "Favorito",
        status: "Status",
        actions: "Ações",
        labelStatus: {
          analyzing: "Avaliando",
          processed: "Processado",
          canceled: "Cancelado",
          partialCanceled: "Cancelado Parc.",
          sold: "Vendido",
          partialSold: "Vendido Parc.",
        },
      },
      detailsRow: {
        identification: "Identificação",
        saleAnimals: "Animais à vender",
        receive: "A receber",
        margin: "Margem",
        processedAt: "Processado às",
      },
    },
    new: {
      title: "Criação: Boitel Venda",
      tabs: {
        lots: {
          title: "Seleção de lotes",
        },
        dietStrategy: {
          title: "Seleção de estratégia de dieta",
        },
        parameters: {
          title: "Definição dos parâmetros",
          form: {
            name: "Nome do cenário*",
            animalsInTruck: "Animais no caminhão*",
            minimumAge: "Idade minima para venda*",
            maximumAge: "Idade máxima para venda*",
            minimumWeight: "Peso minimo para venda*",
            maximumWeight: "Peso máximo para venda*",
            useDefaultMaximumWeight: "Peso máximo para venda*",
            allowSeparateSell: "Lotes podem sofrer descasca?",
            goalType: "Objetivo do cenário*",
            commitments: "O cenário possui algum compromisso?",
            newCommitment: "Adicionar novo compromisso",
            marketAnalysisId: "Análise de mercado*",
            useCustomCarcassHarnessing: "Informar novo % de Aprov. de Carcaça?",
            useCustomCarcassHarnessingHelp:
              "Por padrão, o sistema utiliza o % de Aproveitamento de Carcaça informado no processo de contratação dos animais.",
            carcassHarnessing: "Aprov. de Carcaça",
          },
        },
      },
    },
    edit: {
      title: "Edição: Boitel Venda",
    },
    details: {
      messageSelectDates:
        "Selecione uma(s) data(s) sugeridas no calendário para visualizar as informações dos animais.",
      calendarSuggested: "Calendário sugerido",
      weekDateNull: "Animais com violação Boitel",
      animalsToSell: "Animais a vender",
      planningIncome: "Receita planejada",
      dietStrategiesSuggested: "Estrat. de dieta sugerida",
      commitments: "Compromissos",
      violations: "Violações",
      parameters: "Parâmetros do cenário",
      marketValues: "Referência de valores",
      violationAge: "Idade",
      violationWeight: "Peso",
      violationBoitel: "Boitel",
      table: {
        columns: {
          animalHandlingNumber: "Identificação",
          sellDate: "Data sugerida",
          weight: "Peso estim.",
          lastDailyWeight: "Último peso",
          averageWeightGain: "GMD",
          aggregateArroba: "@Liq. agregadas",
          saleIncome: "Receita",
          aggregateIncome: "Receita Agregada",
          cost: "Custo",
          aggregateMargin: "Lucro agregado",
          margin: "Lucro",
        },
      },
    },
  },
  importSupplements: {
    title: "Importar suplementos",
    message:
      '<p class="message">Faça <strong>download do template abaixo, complete a planilha</strong> com os suplementos, <strong>faça a importação</strong> em seguida e clique em processar suplementos</p>',
    draggerMessage:
      "Clique ou arraste o arquivo para esta área para fazer o upload",
  },
  eventLog: {
    fromValueLabel: "Modificado de",
    toValueLabel: "para",
    dateLabel: "Data",
    attributeLabel: "Propriedade",
    userLabel: "Usuário",
    nullLabel: "vazio",
    Supplement: {
      createdAt: "Criação do registro: ",
      name: "Nome",
      type: "Tipo",
      classification: "Classificação",
      industrialName: "Nome Industrial",
      status: "Situação",
      cost: "Custo",
      cp: "PB",
      tdn: "NDT",
      npn: "NNP",
      dryMatterPercentage: "MS",
      nitrogen: "Nitrogênio",
      calcium: "Cálcio",
      phosphorus: "Fósforo",
      zinc: "Zinco",
      sodium: "Sódio",
      minimumVoluntaryConsumption: "Consumo Vonluntário (Mínimo)",
      maximumVoluntaryConsumption: "Consumo Vonluntário (Máximo)",
      voluntaryConsumptionType: "Unidade",
      minimumPure: "Oferta Pura (Mínimo)",
      maximumPure: "Oferta Pura (Máximo)",
      minimumPureTrough: "Cocho Recomendado (Mínimo)",
      maximumPureTrough: "Cocho Recomendado (Máximo)",
      pureConsumptionType: "Unidade",
      minimumMix: "Oferta Mistura (Mínimo)",
      maximumMix: "Oferta Mistura (Máximo)",
      minimumMixTrough: "Cocho Recomendado (Mínimo)",
      maximumMixTrough: "Cocho Recomendado (Máximo)",
      mixConsumptionType: "Unidade",
      currentVersion: "Versão",
      proportion: "Proporção",
      additiveEffect: "Efeito Aditivo",
      additiveEffectUnit: "Unidade Efeito Aditivo",
      fda: "FDA",
      lignin: "Lignina",
      ee: "Extrato Etéreo",
    },
  },
  reproductionParameters: {
    title: "Parâmetros de Reprodução",
    parameters: {
      IDADE_INICIO_FASE_REPRODUTIVA: "Idade para início da fase reprodutiva*",
      PERIODO_DESCANSO_POS_PARTO: "Período de descanso pós parto*",
      TEMPO_APOS_COBERTURA_PARA_DG:
        "Tempo pós cobertura para Diagnóstico de Gestação*",
      TOURO_COBERTURAS_DESCONHECIDAS: "Touro de Coberturas Desconhecidas*",
      IDADE_DESMAMA: "Idade para Desmama*",
    },
    reproductionPeriod: {
      title: "Estações de Monta",
      titleHelper:
        "A Estação de Monta é o período reprodutivo da propriedade, sendo iniciado no D0 do protocolo do primeiro Lote de IATF e finalizando no Diagnostico de Gestação Final.",
    },
    score: {
      title: "Score de Probabilidade de Sucesso Reprodutivo",
      titleHelper:
        "Defina, com base na escala de 1 a 9 de score, a probabilidade em % de sucesso reprodutivo. Este score, será aplicado na tabela para geração da Curva de Idade X Peso X Score.",
    },
    breedScore: {
      title: "Curva de Idade X Peso X Score",
      titleHelper:
        "Defina para as Fêmeas de Reprodução, considerando a Idade, Peso Atual e Score de Probabilidade de Sucesso Reprodução, as variáveis necessárias para cálculo do custo para, até a próxima Estação de Reprodutiva, atingir cada score.",
      form: {
        title: "Nova entrada (Raça X Idade X Peso X Score)",
        breedId: "Raça*",
        age: "Idade*",
        startWeight: "Peso inicial*",
        endWeight: "Peso final*",
        weight: "Peso",
        score: "Score*",
        errors: {
          errorWithFieldWeights:
            'Existem alguns pesos sem "Score". Preencha-los para salvar o formulário.',
        },
      },
    },
  },
  semen: {
    title: "Listagem de Sêmens",
    buttonNewSemen: "Novo Sêmen",
    table: {
      columns: {
        name: "Nome",
        registration: "Registro",
        breedName: "Raça",
      },
    },
    form: {
      titleCreateNewSemen: "Novo Sêmen",
      titleEditSemen: "Editar Sêmen",
      name: "Nome",
      registration: "Registro",
      breedId: "Raça",
    },
  },
  movimentAnimals: {
    title: "Movimentação entre lotes",
    description:
      'Selecione os animais na tabela e clique no botão "Movimentar" para realocar os animais',
    buttonMovementAnimals: "Movimentar",
    form: {
      title: "Nova movimentação - Lote de Destino",
    },
  },
  closedSalesReport: {
    form: {
      title: "Resultado de Vendas",
      salesPeriod: "Período das Vendas",
      clientId: "Cliente",
      supplierId: "Fornecedor",
      saleScenarioId: "Cenário de Venda(s)",
    },
  },
  exportFinancialTransactions: {
    modal: {
      title: "Exportar movimentações financeiras",
      billingPeriod: "Faturamento",
      expirationPeriod: "Vencimento",
      suppliers: "Fornecedores",
      costActivities: "Atividades",
      clients: "Cliente(s)",
      transactionType: "Tipo",
      financialProjectId: "Projeto Financeiro",
      status: "Status",
    },
  },
  unit: {
    title: "Listagem de Unidades",
    buttonNew: "Nova Unidade",
    table: {
      columns: {
        name: "Abreviatura",
        description: "Descrição",
        basicUnit: "Unidade Básica",
        multiplier: "Multiplicador",
        status: "Status",
        createdAt: "Data Criação",
        createdBy: "Responsável Criação",
      },
    },
    form: {
      titleCreateNew: "Nova Unidade",
      titleEdit: "Editar Unidade",
      groupFarmVisibility: "Quem irá utilizar a Unidade?",
      name: "Abreviatura",
      description: "Descrição",
      basicUnit: "Unidade Básica",
      multiplier: "Multiplicador",
      status: "Status",
    },
  },
  veterinary: {
    title: "Listagem de Insumos Veterinários",
    buttonNew: "Novo insumo veterinário",
    table: {
      columns: {
        name: "Nome Comercial",
        type: "Tipo",
        supplierId: "Fornecedor",
        supplierName: "Fornecedor",
        status: "Status",
        createdAt: "Data Criação",
        createdBy: "Responsável Criação",
      },
    },
    form: {
      titleCreateNew: "Novo Insumo Veterinário",
      titleEdit: "Editar Insumo Veterinário",
      groupFarmVisibility: "Quem irá utilizar o Insumo Veterinário?",
      name: "Nome Comercial",
      type: "Tipo",
      supplierId: "Fornecedor",
      applicationGender: "Sexo",
      applicationAge: "Idade",
      applicationType: "Forma de Aplicação",
      applicationValue: "Qtd. de Aplicação",
      applicationWeightValueParam: "Peso",
      unitId: "Unidade de uso",
      applicationCost: "Custo Aplicação",
      status: "Status",
    },
  },
  handlingReproduction: {
    title: "Manejos",
    tabs: {
      reproductiveProtocol: "Protocolos",
      coverage: "Cobertura",
      pregnancyDiagnosis: "Diagnóstico de Gestação",
      animalBirth: "Partos e Perdas",
    },
  },
  handlingReproductionCoverage: {
    title: "Listagem de Coberturas",
    buttonHandlingReproductionCoverage: "Nova Cobertura",
    type: {
      iatf: "IA/IATF",
      repasse: "MC/REPASSE",
    },
    table: {
      columns: {
        animalHandlingNumber: "Fêmea",
        coverageDate: "Data",
        type: "Tipo",
        maleHandlingNumber: "Reprodutor",
        semenNameAndRegistration: "Sêmen",
        semenOrMaleIdentification: "Reprodutor/Sêmen",
        lotName: "Lote",
        dose: "Dose",
        reproductionPeriodDescription: "Estação de Monta",
        inseminatorName: "Inseminador",
        pregnancyDiagnosisResult: "Resultado",
      },
    },
    form: {
      type: "Tipo",
      animalId: "Identificação",
      category: "Categoria",
      semenId: "Sêmen",
      maleId: "Reprodutor",
      dose: "Dose",
      inseminatorId: "Inseminador",
      coverageDate: "Data da Cobertura",
      reproductionPeriodId: "Estação de Monta",
    },
    errorsAndMessages: {
      successMessage: "Cobertura salva/atualizada com sucesso!",
      femaleWithoutReproductionAge:
        "Fêmea não possui idade para início da fase reprodutiva, deseja continuar?",
      femaleDontHaveAgeToCoverage:
        "Atenção! Fêmea não tem idade suficiente para esta cobertura.",
      reproductionPeriodWithoutCurrentDate:
        "Atenção! Necessário ter uma Estação de Monta cadastrada no período da cobertura.",
      errorFemaleWithFutureCoverages: `Atenção! Fêmea possui coberturas em datas posteriores.`,
      errorFemaleWithCoverageInSameDateAndType:
        "Atenção! Cobertura já registrada anteriormente.",
      errorInternalError:
        "Erro ao fazer o cadastro/atualizar cobertura. Contate o suporte.",
    },
  },
  inseminator: {
    title: "Listagem de Inseminadores",
    buttonNewInseminator: "Novo Inseminador",
    table: {
      columns: {
        name: "Nome",
        code: "Código",
        status: "Status",
      },
    },
    form: {
      titleCreateNewInseminator: "Novo Inseminador",
      titleEditInseminator: "Editar Inseminador",
      name: "Nome",
      code: "Código",
    },
  },
  multipleBull: {
    title: "Touros Múltiplos",
    buttonNewMultipleBull: "Novo Touro Múltiplo",
    table: {
      columns: {
        name: "Nome",
        code: "Código",
        males: "Reprodutores",
        formationDate: "Data de Formação",
        status: "Status",
      },
    },
    form: {
      titleCreateNewMultipleBull: "Novo Touro Múltiplo",
      titleEditMultipleBull: "Editar Touro Múltiplo",
      name: "Nome",
      code: "Código",
      males: "Reprodutores",
      formationDate: "Data de Formação",
      status: "Status",
    },
  },
  ovarianResult: {
    SemAvaliacao: "Sem Avaliação",
    CorpoLuteo: "Corpo Lúteo",
    Foliculo: "Folículo",
    Cisto: "Cisto",
    Anestro: "Anestro",
    Normal: "Normal",
    Reabsorvendo: "Reabsorvendo",
    Gemelar: "Gemelar",
  },
  animalPregnancyDiagnosis: {
    title: "Diagnóstico de Gestação",
    buttonNew: "Novo Diagnóstico de Gestação",
    table: {
      columns: {
        animalHandlingNumber: "Fêmea",
        lotOriginalName: "Lote",
        diagnosisDate: "Data do DG",
        result: "Resultado",
        ovarianEvaluationResult: "Avaliação Ovariana",
      },
    },
    form: {
      titleCreateNew: "Novo Diagnóstico de Gestação",
      titleEdit: "Editar Diagnóstico de Gestação",
      diagnosisDate: "Data do Diagnóstico",
      animalId: "Identificação",
      animalReproductionCategory: "Categoria",
      femaleSituation: "Situação",
      lastCoverage: "Última cobertura",
      lotName: "Lote Atual",
      result: "Resultado",
      ovarianEvaluationResult: "Avaliação Ovariana",
      lastCoverageReproductionPeriodId: "Estação de Monta",
      lotDestination: "Lote Destino",
      timeGestationMC: "Tempo de Gestação MC",
      gestationDays: "Tempo de Gestação",
      birthForecast: "Previsão de Parto",
      shouldDiscardFemale: "Descartar Fêmea?",
      discardMotive: "Causa",
    },
  },
  animalWeightHistory: {
    title: "Lista de Pesagens",
    buttonExport: "Análise de Pesagens",
    buttonNew: "Nova Pesagem",
    table: {
      columns: {
        origination: "Origem",
        handlingNumber: "Animal",
        date: "Data",
        weight: "Peso",
        originationLotName: "Lote",
        originationPicketName: "Piquete",
        event: "Tipo",
      },
    },
    originEvents: {
      birthday: "Nascimento",
      sale: "Venda",
      weaning: "Desmama",
      purchase: "Compra",
    },
    form: {
      titleCreateNew: "Adicionar Pesagem",
      titleEdit: "Editar Pesagem",
      animalId: "Identificação Animal",
      date: "Data da Pesagem",
      weight: "Peso",
      gmd: "GMD",
      lotOriginalId: "Lote Atual",
      lotDestinationId: "Lote Destino",
      lastDateWeight: "Data da Última Pesagem",
      lastWeight: "Última Pesagem",
      weaningWeight: "Desmama?",
      editIdentifications: "Editar Identificações?",
    },
    modalExportReport: {
      title: "Análise de Pesagens",
      periodDate: "Período das Pesagens",
      gender: "Sexo",
      status: "Status dos Animais",
      periodAge: "Idade(meses)",
      lotId: "Lote",
      supplierId: "Fornecedor",
      listAnimalsWithoutWeight: "Listar animais sem pesagem no período?",
      showProjectedWeight:
        "Apresentar o peso projetado pela Estratégia de Dieta na Data atual?",
    },
  },
  dailyLiveCattlesDataAgro: {
    codes: {
      D_PEPR_GO_BR: "@ GO",
      D_PEPR_MG_BR: "@ MG",
      D_PEPR_MT_BR: "@ MT",
      D_PEPR_MS_BR: "@ MS",
      D_PEPR_SP_BR: "@ SP",
      D_PEPR_RO_BR: "@ RO",
      D_PEPR_TO_BR: "@ TO",
      D_PEPR_PA_BR: "@ PA",
      D_PEPR_BA_BR: "@ BA",
    },
  },
  animalBirth: {
    title: "Listagem de Partos e Perdas Reprodutivas",
    buttonNew: "Novo Parto/Perda",
    table: {
      columns: {
        matrizAnimalHandlingNumber: "Matriz",
        birthDate: "Data do parto",
        type: "Tipo",
        coverageDate: "Dt. Cobertura",
        coverageType: "Tipo Cobertura",
        reproductor: "Reprodutor",
        childs: {
          handlingNumber: "Identificação",
          gender: "Sexo",
          birthdayWeight: "Peso Nascimento",
          situation: "Situação",
        },
      },
    },
    types: {
      parto: "Parto",
      aborto: "Aborto",
      morteEmbrionaria: "Morte Embrionária",
    },
    form: {
      titleCreateNew: "Novo Parto/Perda",
      titleEdit: "Editar Parto/Perda",
      matrizAnimalId: "Identificação",
      type: "Tipo",
      birthDate: "Data",
      animalReproductionCategory: "Categoria",
      femaleSituation: "Situação",
      gestationDays: "Tempo de Gestação",
      coverageInfoType: "Tipo",
      coverageInfoDate: "Data",
      coverageInfoGestationTime: "Tempo de Gestação",
      coverageInfoBirthForecast: "Prev. Parto",
      coverageInfoReprodutor: "Reprodutor",
      coverageInfoDGDate: "Data do DG",
      coverageInfoDGResult: "Resultado do DG",
      shouldDiscardFemale: "Descartar Fêmea?",
      discardMotive: "Causa",
      lotMatrizDestinationId: "Lote de Produção",
      observation: "Observações",
      child: {
        nickname: "Nome",
        nicknamePlaceHolder: "Nome",
        sisbov: "SISBOV",
        sisbovPlaceHolder: "SISBOV",
        tagId: "Id. Eletrônica",
        tagIdPlaceHolder: "Id. Eletrônica",
        sex: "Sexo",
        male: "Macho",
        female: "Fêmea",
        acquisition: "Aquisição",
        handlingNumber: "Identificação",
        handlingNumberPlaceHolder: "N. de identificação do animal",
        breed: "Raça",
        breedPlaceHolder: "Selecione uma raça",
        cap: "Animal castrado?",
        birthday: "Data de nascimento",
        birthdayWeight: "Peso Nascimento",
        firstInseminationWeight: "Primeira inserminação",
        weaningWeight: "Peso Desmame",
        weaningDate: "Data Desmame",
        asymptoticWeight: "Peso Assintótico",
        asymptoticWeightInfoTooltip:
          "Peso assintótico é o peso que, com base nas informações que o usuário/produtor possui, sobre o histórico do Animal ou Lote de Animais, (considerando a genética, manejo anterior, interação genótipo-ambiente, frame, entre outras variáveis), acredite ser o potencial limite de peso. Ou seja, acima deste peso, dificilmente este animal ganhará peso, em condições normais.",
        acquisitionDate: "Data de aquisição",
        acquisitionEstimatedWeight: "Peso aquisição",
        carcassHarnessing: "Aproveitamento da Carcaça",
        animalFarmFunction: "Finalidade",
        animalReproductionCategory: "Categoria",
        sisbovIdentificationDate: "Data Identificação",
        sisbovInsertBNDDate: "Data Inclusão BND",
        sisbovSlaughterDate: "Data Liberação Abate",
        lotId: "Lote de Produção",
        farmOwner: "Proprietário",
        purchaseCost: "Valor de Entrada",
        stillborn: "Natimorto?",
      },
    },
  },
  animalReproductionCategory: {
    nulipara: "Nulípara", // Nulíparas, são fêmeas de reprodução, desmamadas, que não foram trabalhadas (inseminadas ou colocadas à disposição do touro).
    novilha: "Novilha", // Novilhas, são fêmeas de reprodução, desmamadas, que foram trabalhadas, mas inda não pariram.
    primipara: "Primípara", // Primíparas, são fêmeas de reprodução, que tiverem 1 parto.
    secundipara: "Secundípara", // Secundíparas, são fêmeas de reprodução, que tiverem 2 partos.
    multipara: "Multípara", // Multíparas, são fêmeas de reprodução, que tiveram 3 ou mais partos.
    // Macho
    reprodutor: "Reprodutor", // Reprodutor: Macho destinado para uso na reprodução.
  },
  femaleSituation: {
    vazia: "Vazia",
    prenha: "Prenha",
    servida: "Servida",
  },
  animalReproductionState: {
    solteira: "Solteira",
    parida: "Parida",
  },
  animalFarmFunction: {
    reproduction: "Reprodução",
    fatten: "Engorda",
  },
  birthTypes: {
    parto: "Parto",
    aborto: "Aborto",
    morteEmbrionaria: "Morte Embrionária",
  },
  animalStatus: {
    A: "Ativo",
    Active: "Ativo",
    S: "Vendido",
    Sold: "Vendido",
    D: "Morto",
    Dead: "Morto",
    I: "Inativo",
    Inactive: "Inativo",
  },
  animalMovementEvents: {
    weight: "Pesagem",
    vacination: "Vacinação",
    coverage: "Cobertura",
    pregnancyDiagnosis: "Diagnóstico de Gestação",
    birth: "Parto",
    discard: "Descarte",
    lotEntry: "Entrada no Lote",
  },
  financialClass: {
    buttonNew: "Nova Classe",
    form: {
      titleCreateNew: "Nova Classe",
      titleEdit: "Editar Classe",
      financialNatureId: "Natureza",
      name: "Nome",
      shouldCost: "Considerado para Custeio?",
      shouldDisbursement: "Considerado para Desembolso?",
      depreciation: "Depreciação",
      costCenterName: "Centro de Custo",
    },
  },
  costCenterDefaultNames: {
    PRODUCAO_ANIMAL: "Produção Animal",
    UTILIDADES: "Utilidades",
    INFRAEST_PRODUTIVA: "Infraest. Produtiva",
    INFRAEST_OPERACIONAL: "Infraest. Operacional",
    INFRAEST_APOIO: "Infraest. Apoio",
    TAXA_IMPOSTOS: "Taxas & Impostos",
    SERVICOS: "Serviços",
    INVSUMOS_OPERACIONAIS: "Insumos Operacionais",
    PRODUCAO_PASTO_AGRICOLA: "Produção Pasto/Agrícola",
  },
  financialProject: {
    title: "Projetos Financeiros",
    buttonNew: "Novo Projeto",
    table: {
      columns: {
        name: "Nome Projeto",
        description: "Observações",
        startProjectDate: "Data Início",
        finishProjectDate: "Término",
        status: "Status",
      },
    },
    form: {
      titleCreateNew: "Novo Projeto Financeiro",
      titleEdit: "Editar Projeto Financeiro",
      name: "Nome Projeto",
      description: "Observações",
      startProjectDate: "Data Início",
      finishProjectDate: "Término",
      status: "Ativo?",
    },
  },
  animalGender: {
    Female: "Fêmea",
    Male: "Macho",
  },
  lotItemEntry: {
    form: {
      entryDate: "Data de Entrada",
      amountAnimals: "Quantidade",
      averageWeight: "Peso Médio",
      ageInDays: "Idade (dias/meses/anos)",
      prefixIdentification: "Prefixo para Identificação",
      lotDestinationId: "Lote Destino",
    },
  },
  picketSupplementSupply: {
    title: "Fornecimento",
    buttonNew: "Novo Fornecimento",
    table: {
      columns: {
        supplyDate: "Data",
        picketsCount: "Piquetes/Baias",
        totalSupplementSupplied: "Total Ofertado(Kg)",
        responsibleName: "Responsáveis",
        subTable: {
          retreatName: "Retiro/Linha",
          picketName: "Piquetes/Baias",
          totalAnimals: "Qtde. Animais",
          supplementName: "Suplemento/Ração",
          weightedAverageDryMatter: "%MS",
          totalSupplementSupplied: "Qtde. Ofertada",
          productionSubSystem: "Subsistema de Produção",
          subTable: {
            supplyNumber: "N° Trato",
            supplementName: "Suplemento/Ração",
            dryMatterPercentage: "%MS",
            quantitySupplied: "Qtde. Ofertada",
            responsibleName: "Responsável",
          },
        },
      },
    },
    form: {
      titleCreateNew: "Novo Fornecimento",
      titleEdit: "Editar Fornecimento",
      supplyDate: "Data",
      supplyNumber: "N° Trato",
      responsibleName: "Responsável",
      retreats: "Retiro/Linha",
      pickets: "Piquetes/Baias",
      subSystems: "Subsistema de Produção",
      quantitySupplied: "Qtde Ofertada",
      supplement: "Suplemento/Ração",
      saveOptions: {
        only_save: "Salvar somente",
        save_and_create_new: "Salvar e criar novo fornecimento",
      },
    },
  },
  partialPayment: {
    title: "Criar Pagamento Parcial",
    form: {
      valuePartialPayment: "Valor Pag. Parcial",
      settleDate: "Data Baixa",
      expirationDate: "Nova Data Vencimento",
      valuePendingPayment: "Valor Pend. Pgto",
      partialPayment: {
        title: "Criar Pagamento Parcial",
        form: {
          valuePartialPayment: "Valor Pag. Parcial",
          settleDate: "Data Baixa",
          expirationDate: "Nova Data Vencimento",
          valuePendingPayment: "Valor Pend. Pgto",
          fees: "Valor Juros/Multas",
        },
      },
    },
  },
  financialDashboard: {
    resultCenter: "Centro de Resultado",
    costCenter: "Centro de Custo",
    costType: "Atividade",
    period: "Período",
    financialProject: "Projeto",
    bankAccount: "Conta Bancária",
    buttonConfirm: "Processar",
    periods: {
      lastSevenDays: "Últimos 7 dias",
      lastThirdyDays: "Últimos 30 dias",
      currentMonth: "Mês Atual",
      lastMonth: "Mês Anterior",
      currentYear: "Ano Atual",
      lastYear: "Ano Anterior",
      currentHarvest: "Safra Atual",
      lastHarvest: "Safra Anterior",
      customDateRange: "Especificar Data",
    },
  },
  reproductiveProtocol: {
    title: "Protocolos Reprodutivos",
    buttonNew: "Nova Protocolo",
    table: {
      columns: {
        status: "Status",
        name: "Nome",
        duration: "Duração",
        createdAt: "Data de Criação",
        createdBy: "Responsável Criação",
        applicationDays: {
          orderApplication: "Ordem",
          dayApplication: "D?",
          product: "Produto/Hormônio",
        },
      },
    },
    form: {
      titleCreateNew: "Nova Protocolo",
      titleEdit: "Editar Protocolo",
      groupFarmVisibility: "Quem irá utilizar o Protocolo?",
      name: "Nome genérico",
      duration: "Duração Protocolo",
      newApplicationDay: "Linha",
      observations: "Observações",
      applicationDays: {
        orderApplication: "Ordem",
        dayApplication: "D?",
        product: "Produto/Hormônio",
      },
    },
  },
  animalFarmReproductiveProtocol: {
    title: "Lançamentos de Protocolos",
    buttonNew: "Aplicar Protocolo",
    table: {
      columns: {
        handlingNumber: "Fêmea",
        dateApplication: "Data",
        reproductiveProtocolName: "Protocolo",
        reproductionPeriodDescription: "Estação de Montar",
        lotOriginName: "Lote de Origem",
        lotDestinationName: "Lote Destino",
      },
    },
    form: {
      titleCreateNew: "Aplicar Protocolo",
      titleEdit: "Editar Protocolo",
      buttonApply: "Aplicar",
      applyForAnimalsGroupForm: {
        dateApplication: "Data D0",
        reproductiveProtocolId: "Protocolo",
        reproductionPeriodId: "Estação de Monta",
        lotDestinationId: "Lote Destino",
      },
      groupTitle: "Aplicações",
      animalsTable: {
        handlingNumber: "Identificação",
        lotName: "Lote",
        breedName: "Raça",
        age: "Idade",
        femaleSituation: "Situação",
        animalFarmFunction: "Finalidade",
        animalReproductionState: "Estado",
        animalReproductionCategory: "Categoria",
        lastBirthDate: "Dt. Último Parto",
      },
    },
  },
  currency: {
    AED: "Dirham dos Emirados",
    AFN: "Afghani do Afeganistão",
    ALL: "Lek Albanês",
    AMD: "Dram Armênio",
    ANG: "Guilder das Antilhas",
    AOA: "Kwanza Angolano",
    ARS: "Peso Argentino",
    AUD: "Dólar Australiano",
    AZN: "Manat Azeri",
    BAM: "Marco Conversível",
    BBD: "Dólar de Barbados",
    BDT: "Taka de Bangladesh",
    BGN: "Lev Búlgaro",
    BHD: "Dinar do Bahrein",
    BIF: "Franco Burundinense",
    BND: "Dólar de Brunei",
    BOB: "Boliviano",
    BRL: "Real Brasileiro",
    BRLT: "Real Brasileiro Turismo",
    BSD: "Dólar das Bahamas",
    BTC: "Bitcoin",
    BWP: "Pula de Botswana",
    BYN: "Rublo Bielorrusso",
    BZD: "Dólar de Belize",
    CAD: "Dólar Canadense",
    CHF: "Franco Suíço",
    CHFRTS: "Franco Suíço",
    CLP: "Peso Chileno",
    CNH: "Yuan chinês offshore",
    CNY: "Yuan Chinês",
    COP: "Peso Colombiano",
    CRC: "Colón Costarriquenho",
    CUP: "Peso Cubano",
    CVE: "Escudo cabo-verdiano",
    CZK: "Coroa Checa",
    DJF: "Franco do Djubouti",
    DKK: "Coroa Dinamarquesa",
    DOGE: "Dogecoin",
    DOP: "Peso Dominicano",
    DZD: "Dinar Argelino",
    EGP: "Libra Egípcia",
    ETB: "Birr Etíope",
    ETH: "Ethereum",
    EUR: "Euro",
    FJD: "Dólar de Fiji",
    GBP: "Libra Esterlina",
    GEL: "Lari Georgiano",
    GHS: "Cedi Ganês",
    GMD: "Dalasi da Gâmbia",
    GNF: "Franco de Guiné",
    GTQ: "Quetzal Guatemalteco",
    HKD: "Dólar de Hong Kong",
    HNL: "Lempira Hondurenha",
    HRK: "Kuna Croata",
    HTG: "Gourde Haitiano",
    HUF: "Florim Húngaro",
    IDR: "Rupia Indonésia",
    ILS: "Novo Shekel Israelense",
    INR: "Rúpia Indiana",
    IQD: "Dinar Iraquiano",
    IRR: "Rial Iraniano",
    ISK: "Coroa Islandesa",
    JMD: "Dólar Jamaicano",
    JOD: "Dinar Jordaniano",
    JPY: "Iene Japonês",
    JPYRTS: "Iene Japonês",
    KES: "Shilling Queniano",
    KGS: "Som Quirguistanês",
    KHR: "Riel Cambojano",
    KMF: "Franco Comorense",
    KRW: "Won Sul-Coreano",
    KWD: "Dinar Kuwaitiano",
    KYD: "Dólar das Ilhas Cayman",
    KZT: "Tengue Cazaquistanês",
    LAK: "Kip Laosiano",
    LBP: "Libra Libanesa",
    LKR: "Rúpia de Sri Lanka",
    LSL: "Loti do Lesoto",
    LTC: "Litecoin",
    LYD: "Dinar Líbio",
    MAD: "Dirham Marroquino",
    MDL: "Leu Moldavo",
    MGA: "Ariary Madagascarense",
    MKD: "Denar Macedônio",
    MMK: "Kyat de Mianmar",
    MNT: "Mongolian Tugrik",
    MOP: "Pataca de Macau",
    MRO: "Ouguiya Mauritana",
    MUR: "Rúpia Mauriciana",
    MVR: "Rufiyaa Maldiva",
    MWK: "Kwacha Malauiana",
    MXN: "Peso Mexicano",
    MYR: "Ringgit Malaio",
    MZN: "Metical de Moçambique",
    NAD: "Dólar Namíbio",
    NGN: "Naira Nigeriana",
    NGNI: "Naira Nigeriana",
    NGNPARALLEL: "Naira Nigeriana",
    NIO: "Córdoba Nicaraguense",
    NOK: "Coroa Norueguesa",
    NPR: "Rúpia Nepalesa",
    NZD: "Dólar Neozelandês",
    OMR: "Rial Omanense",
    PAB: "Balboa Panamenho",
    PEN: "Sol do Peru",
    PGK: "Kina Papua-Nova Guiné",
    PHP: "Peso Filipino",
    PKR: "Rúpia Paquistanesa",
    PLN: "Zlóti Polonês",
    PYG: "Guarani Paraguaio",
    QAR: "Rial Catarense",
    RON: "Leu Romeno",
    RSD: "Dinar Sérvio",
    RUB: "Rublo Russo",
    RUBTOD: "Rublo Russo",
    RUBTOM: "Rublo Russo",
    RWF: "Franco Ruandês",
    SAR: "Riyal Saudita",
    SCR: "Rúpias de Seicheles",
    SDG: "Libra Sudanesa",
    SDR: "DSE",
    SEK: "Coroa Sueca",
    SGD: "Dólar de Cingapura",
    SOS: "Shilling Somaliano",
    STD: "Dobra São Tomé/Príncipe",
    SVC: "Colon de El Salvador",
    SYP: "Libra Síria",
    SZL: "Lilangeni Suazilandês",
    THB: "Baht Tailandês",
    TJS: "Somoni do Tajiquistão",
    TMT: "TMT",
    TND: "Dinar Tunisiano",
    TRY: "Nova Lira Turca",
    TTD: "Dólar de Trinidad",
    TWD: "Dólar Taiuanês",
    TZS: "Shilling Tanzaniano",
    UAH: "Hryvinia Ucraniana",
    UGX: "Shilling Ugandês",
    USD: "Dólar Americano",
    USDT: "Dólar Americano",
    UYU: "Peso Uruguaio",
    UZS: "Som Uzbequistanês",
    VEF: "Bolívar Venezuelano",
    VND: "Dong Vietnamita",
    VUV: "Vatu de Vanuatu",
    XAF: "Franco CFA Central",
    XAGG: "Prata",
    XBR: "Brent Spot",
    XCD: "Dólar do Caribe Oriental",
    XOF: "Franco CFA Ocidental",
    XPF: "Franco CFP",
    XRP: "XRP",
    YER: "Riyal Iemenita",
    ZAR: "Rand Sul-Africano",
    ZMK: "Kwacha Zambiana",
    ZWL: "Dólar Zimbabuense",
    XAU: "Ouro",
  },
  importFinancialTransaction: {
    title: "Importar Movimentações Financeiras",
    message:
      '<p class="message">Faça <strong>download do template abaixo, complete a planilha</strong> com as movimentações financeiras, <strong>faça a importação</strong> em seguida e clique em processar.</p>',
    draggerMessage:
      "Clique ou arraste o arquivo para esta área para fazer o upload",
  },
  confinementResultReport: {
    form: {
      title: "Resultado do Confinamento",
      salesPeriod: "Período das Vendas",
      clientId: "Cliente",
      supplierId: "Fornecedor",
      saleScenarioId: "Cenário de Venda(s)",
      documentNumber: "N° do Documento",
      lotIds: "Lotes",
      showDeathData: "Exibir dados de Mortes no período?",
    },
  },
};
