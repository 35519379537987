import {
  Button,
  Col,
  DatePicker,
  Icon,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
  notification,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Contexts
import useFarmAnimalSellContext from "../../../../../../hooks/useFarmAnimalSellContext";
import { Creators as ClientActions } from "../../../../../../store/ducks/client";

// Components
import DrawerClient from "../../../../../../components/drawers/client";
import ButtonStandard from "../../../../../../components/utils/button";
import InfoTooltip from "../../../../../../components/utils/infoTooltip";
import { Title } from "../styles";

// Services
import {
  getAnimalsSaleScenariosResults,
  verifyIfAnimalsHaveMovementEventAfterDate,
} from "../../../../../../services/animalService";
import {
  getFinancialNatureForSales,
  saveFarmSell,
} from "../../../../../../services/farmSellService";
import {
  cnpjMask,
  cpfMask,
  getTwoDecimalDigits,
  numberMask,
} from "../../../../../../services/helpersMethodsService";

// Modais
import InstallmentsModal from "../../../transactions/form/installmentsModal";
import ResultCenterApportionmentModal from "../../../transactions/form/resultCenterApportionmentModal";

// Ícones
import PencilIcon from "../../../../../../components/utils/icons/pencil";
import StopIcon from "../../../../../../components/utils/icons/stop";
import { findAll } from "../../../../../../services/financialProjectService";
import { findAllAnimalsForMovement } from "../../../../../../services/movementAnimalsService";

const { Column } = Table;

const initialFormValue = {
  clientId: null,
  sellDate: null,
  priceNegotiated: null,
  priceNegotiatedUnit: "VALUE_PER_ARROBA",
  weightPerAnimal: null,
  carcassHarnessing: null,
  documentNumber: null,
  supplierId: null,
  billingDate: null,
  expirationDate: null,
  payment: "inCash",
  installmentTotal: null,
  installments: [],
  resultCenterApportionmentDefault: true,
  customApportionmentValues: [],
  financialNatureId: null,
  totalValue: 0,
  valueCurrency: "BRL",
  financialProjectId: null,
  bonus: null,
};

const FormProductionSell = ({ onCancel }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    app: {
      translation,
      groupSelected: { id: groupId },
      farmSelected: { id: farmId },
    },
    client: { listDropdown: clientData },
  } = useSelector((state) => state);

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialFormValue);
  const [errors, setErrors] = useState([]);
  const [formatDate] = useState("DD/MM/YYYY");

  const [animalsCustomData, setAnimalsCustomData] = useState([]);
  const [customCarcassHarnessing, setCustomCarcassHarnessing] = useState(false);
  const [customSellWeight, setCustomSellWeight] = useState(false);
  const [financialNature, setFinancialNature] = useState(null);

  const [
    isResultCenterApportionmentModalVisible,
    setIsResultCenterApportionmentModalVisible,
  ] = useState(false);
  const [isInstallmentsModalVisible, setIsInstallmentsModalVisible] =
    useState(false);
  const [customApportionmentValues, setCustomApportionmentValues] =
    useState(null);
  const [amountAnimals, setAmountAnimals] = useState(null);

  const [animalsLastEvents, setAnimalsLastEvents] = useState([]);

  const [listFinancialProjects, setListFinancialProjects] = useState([]);
  const [isLoadingFinancialProjects, setIsLoadingFinancialProjects] =
    useState(false);
  const [financialProjectSelected, setFinancialProjectSelected] =
    useState(null);

  const { selectedAnimalsKeys } = useFarmAnimalSellContext();

  const avgCarcassHarnessing = useMemo(() => {
    if (animalsCustomData && animalsCustomData.length > 0) {
      const sumCarcassHarnessing = animalsCustomData.reduce(
        (sumValue, currentObj) =>
          sumValue + +(currentObj?.carcassHarnessing || 0),
        0
      );
      return +(sumCarcassHarnessing / animalsCustomData.length).toFixed(2);
    }

    return 0;
  }, [animalsCustomData]);

  const avgSellWeight = useMemo(() => {
    if (animalsCustomData && animalsCustomData.length > 0) {
      const sumWeightSell = animalsCustomData.reduce(
        (sumValue, currentObj) => sumValue + (currentObj?.weightSell || 0),
        0
      );
      return +(sumWeightSell / animalsCustomData.length).toFixed(2);
    }

    return 0;
  }, [animalsCustomData]);

  const avgSellWeightInArroba = useMemo(() => {
    if (amountAnimals && form.carcassHarnessing && form.weightPerAnimal) {
      const weight = avgSellWeight ? avgSellWeight : form.weightPerAnimal;
      const carcassHarnessing = avgCarcassHarnessing
        ? avgCarcassHarnessing
        : form.carcassHarnessing;
      return (weight * amountAnimals * (carcassHarnessing / 100 || 1)) / 15;
    }
    return 0;
  }, [
    amountAnimals,
    avgCarcassHarnessing,
    avgSellWeight,
    form.carcassHarnessing,
    form.weightPerAnimal,
  ]);

  const bonusPerArroba = useMemo(() => {
    return form.bonus && form.bonus > 0
      ? form.bonus / avgSellWeightInArroba
      : 0;
  }, [avgSellWeightInArroba, form.bonus]);

  const totalValue = useMemo(() => {
    const {
      priceNegotiated,
      weightPerAnimal,
      carcassHarnessing,
      priceNegotiatedUnit,
    } = form;
    if (priceNegotiatedUnit === "VALUE_PER_KG") {
      const totalPrice = getTwoDecimalDigits(
        (priceNegotiated || 0) * (weightPerAnimal || 0) * amountAnimals
      );
      return totalPrice + (form.bonus ? form.bonus : 0);
    } else {
      const totalPrice = getTwoDecimalDigits(
        (priceNegotiated || 0) *
          ((weightPerAnimal || 0) / 15) *
          ((carcassHarnessing || 0) / 100) *
          amountAnimals
      );
      return totalPrice + (form.bonus ? form.bonus : 0);
    }
  }, [form, amountAnimals]);

  async function handleSellDateChange(sellDate) {
    try {
      const animalsIds = selectedAnimalsKeys?.map((a) => a.animalId);

      if (
        animalsIds !== null &&
        animalsIds !== undefined &&
        animalsIds.length > 0
      ) {
        try {
          const {
            data: { results: events },
          } = await verifyIfAnimalsHaveMovementEventAfterDate({
            groupId,
            farmId,
            animalsIds,
            date: sellDate,
            signal: null,
          });
          setAnimalsLastEvents(events);

          if (Array.isArray(events) && events.length > 0) {
            Modal.error({
              icon: null,
              centered: true,
              title:
                "Animais com eventos posteriores à data de venda informada!",
              content: animalLastEventModalContent(events),
              okText: "Ok",
            });
          }
        } catch (error) {
          console.error(error);
        }

        // let animalsClone = [...animalsCustomData];
        // animalsClone = animalsClone.map((ac) => {
        //   if (ac.lastDailyWeightDate === sellDate) {
        //     ac.weightSell = ac.lastDailyWeight;
        //   }
        //   return ac;
        // });
        // setAnimalsCustomData([...animalsClone]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleConsistExpirationDate = useCallback(
    (expirationDate, billingDate) => {
      return new Promise((resolve, reject) => {
        if (
          expirationDate &&
          billingDate &&
          moment(expirationDate).isBefore(moment(billingDate))
        ) {
          Modal.confirm({
            content: "Atenção! Data de Vencimento menor que a de Faturamento.",
            centered: true,
            okText: "Confirmar",
            onOk: () => resolve(true),
            cancelText: "Cancelar",
            onCancel: () => reject(),
          });
        } else {
          resolve(true);
        }
      });
    },
    []
  );

  // Build content modal animals last event table
  const animalLastEventModalContent = useCallback(
    (animalsLastEvents) => (
      <Row className="row-form">
        <Table
          rowKey="animalId"
          dataSource={Array.isArray(animalsLastEvents) ? animalsLastEvents : []}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 10,
            pageSizeOptions: ["10", "20", "30", "100"],
          }}
          scroll={{ x: true }}
          size="small"
        >
          <Column
            title="Identificação"
            dataIndex="handlingNumber"
            key="handlingNumber"
            align="left"
          />
          <Column
            title="Evento"
            dataIndex="type"
            key="type"
            align="left"
            render={(type) => (
              <span>{translation.animalMovementEvents[type]}</span>
            )}
          />
          <Column
            title="Data"
            dataIndex="date"
            key="date"
            align="left"
            render={(value) => <span>{moment(value).format(formatDate)}</span>}
          />
        </Table>
      </Row>
    ),
    [translation, formatDate]
  );

  function clearCustomWeight() {
    const animalsClone = [...animalsCustomData];
    animalsClone.forEach((a) => (a.weightSell = null));
    setAnimalsCustomData([...animalsClone]);
  }

  function completeCustomWeight(sellDate) {
    const animalsClone = [...animalsCustomData];
    animalsClone.forEach((ac) => {
      if (
        sellDate != null &&
        moment(ac.lastDailyWeightDate).format("DD/MM/YYYY") ===
          moment(sellDate).format("DD/MM/YYYY")
      ) {
        ac.weightSell = ac.lastDailyWeight;
      }
      return ac;
    });
    setAnimalsCustomData([...animalsClone]);
  }

  function clearCustomCarcassHarnessing() {
    const animalsClone = [...animalsCustomData];
    animalsClone.forEach((a) => (a.carcassHarnessing = null));
    setAnimalsCustomData([...animalsClone]);
  }

  async function handleSubmit() {
    const body = {
      ...form,
      financialNatureId: financialNature?.id,
      customApportionmentValues,
      totalValue,
      animals: animalsCustomData,
    };
    if (validateForm()) {
      try {
        setIsLoading(true);
        await saveFarmSell({ groupId, farmId, body });
        notification.success({
          message: "A sua Venda está sendo processada, e logo será concluída.",
        });
        history.push("/admin/financial");
      } catch (error) {
        if (Object.keys(error).includes("response")) {
          const { response } = error;
          if (Object.keys(response).includes("data")) {
            const {
              data: { code: errorCode },
            } = response;
            if (errorCode === 5021) {
              notification.error({
                message: "Não foi possível cadastrar transação.",
                description: `O "${translation.financial.transactions.form.documentNumber}" já existe no sistema.`,
              });
            } else {
              notification.error({
                title: "Erro ao salvar",
                message: "Não foi possível realizar a venda. Contate o suporte",
              });
            }
          }
        } else {
          notification.error({
            title: "Erro ao salvar",
            message: "Não foi possível realizar a venda. Contate o suporte",
          });
        }
      } finally {
        setIsLoading(false);
      }
    }
  }

  function validateForm() {
    let aErrors = [];

    if (!Array.isArray(animalsCustomData)) {
      aErrors.push("animals");
      notification.error({
        message: "Erro",
        description: "Não é possível continuar sem informar animais",
      });
    }

    if (form.clientId == null || form.clientName == null) {
      aErrors.push("clientName");
    }

    if (form.sellDate == null) {
      aErrors.push("sellDate");
    }

    if (form.priceNegotiatedUnit == null) {
      aErrors.push("priceNegotiatedUnit");
    }

    if (form.priceNegotiated == null) {
      aErrors.push("priceNegotiated");
    }

    if (
      customSellWeight === false &&
      (form.weightPerAnimal == null || form.weightPerAnimal === 0)
    ) {
      aErrors.push("weightPerAnimal");
    } else if (customSellWeight === true) {
      let index = animalsCustomData.findIndex(
        (a) => a.weightSell == null || a.weightSell === 0
      );
      if (index !== -1) {
        aErrors.push("weightPerAnimal");
        notification.error({
          message: "Erro",
          description: "Existem animais sem informação de peso de venda",
        });
      }
    }

    if (customCarcassHarnessing === false) {
      if (form.carcassHarnessing == null || form.carcassHarnessing === 0) {
        aErrors.push("carcassHarnessing");
      } else if (form.carcassHarnessing < 25 || form.carcassHarnessing > 75.5) {
        aErrors.push("carcassHarnessing");
        notification.error({
          message: "Erro",
          description: "% Aprov. carcaça deve estar entre 25% e 75,5%",
        });
      }
    } else if (customCarcassHarnessing === true) {
      let index = animalsCustomData.findIndex(
        (a) => a.carcassHarnessing == null || a.carcassHarnessing === 0
      );
      let indexMinMax = animalsCustomData.findIndex(
        (a) => a.carcassHarnessing < 25 || a.carcassHarnessing > 75.5
      );
      if (index !== -1) {
        aErrors.push("carcassHarnessing");
        notification.error({
          message: "Erro",
          description: "Existem animais sem informação de % aprov. carcaça",
        });
      }

      if (indexMinMax !== -1) {
        aErrors.push("carcassHarnessing");
        notification.error({
          message: "Erro",
          description:
            "Existem animais com % aprov. carcaça fora do intervalo permitido 25% até 75,5%",
        });
      }
    }

    if (form.documentNumber == null) {
      aErrors.push("documentNumber");
    }

    if (form.billingDate == null) {
      aErrors.push("billingDate");
    } else if (
      form.sellDate !== null &&
      form.sellDate.isAfter(form.billingDate)
    ) {
      aErrors.push("sellDate");
      aErrors.push("billingDate");
      notification.error({
        message: "Erro",
        description:
          "Data de faturamento não pode ser anterior à data de saída.",
      });
    }

    if (form.expirationDate == null) {
      aErrors.push("expirationDate");
    }

    if (form.payment === "part" && !Array.isArray(form.installments)) {
      aErrors.push("payment");
      notification.error({
        message: "Erro",
        description: "Parcelamento inválido!",
      });
    }

    if (
      form.resultCenterApportionmentDefault === false &&
      !Array.isArray(customApportionmentValues)
    ) {
      aErrors.push("resultCenter");
      notification.error({
        message: "Erro",
        description: "Centro de Resultado inválido!",
      });
    }

    if (Array.isArray(animalsLastEvents) && animalsLastEvents.length > 0) {
      aErrors.push("sellDate");
      notification.error({
        message: "Erro",
        description:
          "Não é permitido venda de animais com eventos posteriores à data de saída!",
      });
    }

    if (form.financialProjectId !== null && financialProjectSelected !== null) {
      const initialProjectDate = moment(
        financialProjectSelected.startProjectDate
      );
      const billingDate = moment(form.billingDate);

      if (billingDate.isBefore(initialProjectDate)) {
        notification.error({
          message:
            "O projeto financeiro selecionado inicia depois da data de faturamento. Por favor, selecione outro projeto.",
        });
        aErrors.push("financialProjectId");
      }
    }

    setErrors(aErrors);
    return aErrors.length > 0 ? false : true;
  }
  // Modal methods
  const handleOpenCloseResultCenterApportionmentModal = () => {
    setIsResultCenterApportionmentModalVisible((old) => !old);
  };

  const handleCancelCustomApportionmentModal = () => {
    handleOpenCloseResultCenterApportionmentModal();
    setCustomApportionmentValues(null);
    setForm({ ...form, resultCenterApportionmentDefault: true });
  };

  const handleSaveCustomApportionmentModal = (data) => {
    handleOpenCloseResultCenterApportionmentModal();
    setCustomApportionmentValues(data);
  };

  const handleOpenCloseInstallmentsModal = () => {
    setIsInstallmentsModalVisible((old) => !old);
  };

  const handleUpdateInstallmentsFields = (field, value) => {
    setForm((old) => ({ ...old, [field]: value }));
  };

  function handleCarcassHarnessingChange(value, animalId) {
    const animalsClone = [...animalsCustomData];

    let index = animalsClone.findIndex((a) => a.id === animalId);
    if (index !== null) {
      animalsClone[index].carcassHarnessing = value;
      setAnimalsCustomData([...animalsClone]);
    }
  }

  function handleWeightSellChange(value, animalId) {
    const animalsClone = [...animalsCustomData];
    let index = animalsClone.findIndex((a) => a.id === animalId);
    if (index !== null) {
      animalsClone[index].weightSell = value;
      setAnimalsCustomData([...animalsClone]);
    }
  }

  function getColumnSearchAnimalsProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearchAnimals(selectedKeys, confirm, dataIndex)
            }
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() =>
              handleSearchAnimals(selectedKeys, confirm, dataIndex)
            }
            icon="search"
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              background: "#684e94",
              borderColor: "none",
              border: "none",
            }}
          />
          <Button
            onClick={() => handleAnimalsReset(clearFilters)}
            icon="delete"
            size="small"
            style={{ width: 90 }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? "#684e94" : undefined }}
        />
      ),
      onFilter: (value, record) => {
        return record[dataIndex]?.toString().includes(value);
      },
    };
  }

  function handleSearchAnimals(selectedKeys, confirm) {
    confirm();
  }

  function handleAnimalsReset(clearFilters) {
    clearFilters();
  }

  const handleCreateClient = () => {
    dispatch(ClientActions.showDrawerClientVisible("new", null, true));
  };

  // Fetch animals
  useEffect(() => {
    async function fetchAnimals() {
      const ids = selectedAnimalsKeys.map((s) => s.animalId);
      setIsLoading(true);
      try {
        const {
          data: { results },
        } = await findAllAnimalsForMovement({
          groupId,
          farmId,
          withoutPagination: true,
          ids,
        });

        const {
          data: { results: saleScenariosResults },
        } = await getAnimalsSaleScenariosResults({
          groupId,
          farmId,
          animalsIds: selectedAnimalsKeys.map((a) => a.animalId),
        });

        setAmountAnimals(selectedAnimalsKeys.length);
        setAnimalsCustomData(results);

        if (
          Array.isArray(saleScenariosResults) &&
          saleScenariosResults.length > 0
        ) {
          Modal.warning({
            centered: true,
            title: "Atenção!",
            content:
              "Existem animais selecionados, que estão associados a Cenários de Vendas Ativos. Ao confirmar a venda, estes Cenário de Venda, serão reprocessados, gerando um novo resultado, somente para os animais ainda ativos.",
            okText: "Ok",
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }

    if (Array.isArray(selectedAnimalsKeys)) {
      fetchAnimals();
    }
  }, [groupId, farmId, selectedAnimalsKeys]);

  // Fetch Clients, financial nature
  useEffect(() => {
    async function getFinancialProjects() {
      setIsLoadingFinancialProjects(true);
      try {
        const {
          data: { results: projects },
        } = await findAll({
          groupId,
          farmId,
        });
        const activeProjects = projects.filter(
          (project) => project.status === "Active"
        );
        setListFinancialProjects(activeProjects);
      } catch (error) {
      } finally {
        setIsLoadingFinancialProjects(false);
      }
    }

    async function getFinancialNature() {
      try {
        const {
          data: { results: financialNature },
        } = await getFinancialNatureForSales({ groupId, farmId });

        setFinancialNature(financialNature);
      } catch (error) {
        console.error(error);
      }
    }

    if (groupId && farmId) {
      dispatch(ClientActions.getDropdownClients(groupId, farmId, true));
      getFinancialNature();
      getFinancialProjects();
    }
  }, [dispatch, groupId, farmId]);

  return (
    <>
      {/* Page Header */}
      <div className="pageHeader">
        <Row type="flex" justify="start">
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Title>{translation.financial.animalSell.form.title}</Title>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
            align="right"
            className="buttonsCol"
          >
            <ButtonStandard
              type="button"
              buttonType="type4"
              size="s"
              onClick={onCancel}
            >
              {translation.buttons.cancel}
            </ButtonStandard>
            <ButtonStandard
              type="button"
              buttonType="type1"
              width="121px"
              height="35px"
              padding="5px 10px 5px 10px"
              onClick={handleSubmit}
            >
              {translation.buttons.save}
            </ButtonStandard>
          </Col>
        </Row>
      </div>

      {/* Form section */}
      <Spin spinning={isLoading}>
        <Row type="flex" align="stretch" justify="start">
          {/* Fields */}
          <Col span={21}>
            {/* Sale fields */}
            <Row type="flex" align="middle" justify="start" gutter={8}>
              {/* Client */}
              <Col xs={6} sm={6} md={6} lg={8} xl={8}>
                <Row>
                  <label
                    className={errors.includes("clientName") ? "error" : ""}
                  >
                    {translation.financial.animalSell.form.client}*
                  </label>
                </Row>
                <Row>
                  <div className="inputWithButton">
                    <Select
                      style={{ width: "100%" }}
                      value={form.clientId ?? undefined}
                      placeholder={translation.defaultSelectPlaceholder}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSelect={(value) => {
                        const clientSelected = clientData.find(
                          (c) => c.id === value
                        );
                        setForm({
                          ...form,
                          clientName: clientSelected.name,
                          clientId: clientSelected.id,
                        });
                        setErrors(errors.filter((e) => e !== "clientName"));
                      }}
                    >
                      {clientData &&
                        clientData.map((client) => (
                          <Select.Option key={client.id} value={client.id}>
                            {`${client.name} - ${
                              client.documentType === "CPF"
                                ? `CPF : ${cpfMask(client.document)}`
                                : `CNPJ : ${cnpjMask(client.document)}`
                            }`}
                          </Select.Option>
                        ))}
                    </Select>
                    <Tooltip
                      title={`Novo ${translation.financial.animalSell.form.client}`}
                    >
                      <Button
                        shape="circle"
                        icon="plus"
                        onClick={handleCreateClient}
                      />
                    </Tooltip>
                  </div>
                </Row>
              </Col>

              {/* Sell date */}
              <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                <Row>
                  <label className={errors.includes("sellDate") ? "error" : ""}>
                    {translation.financial.animalSell.form.sellDate}*
                  </label>
                </Row>
                <Row>
                  <DatePicker
                    name="sellDate"
                    className="maxWidthField"
                    value={
                      form?.sellDate != null
                        ? moment(form?.sellDate, "YYYY-MM-DD")
                        : null
                    }
                    format={formatDate}
                    placeholder={translation.defaultPlaceholder}
                    disabledDate={(date) =>
                      moment(date).isAfter(moment()) ? true : false
                    }
                    onChange={(date) => {
                      if (date <= moment() && date !== null) {
                        setForm({ ...form, sellDate: date, billingDate: null });
                        setErrors(errors.filter((e) => e !== "sellDate"));
                        handleSellDateChange(date.format("yyyy-MM-DD"));
                      }
                    }}
                  />
                </Row>
              </Col>

              {/* Price negotiated */}
              <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                <Row>
                  <label
                    className={
                      errors.includes("priceNegotiated") ? "error" : ""
                    }
                  >
                    {translation.financial.animalSell.form.priceNegotiated}*
                  </label>
                </Row>
                <Row>
                  <NumberFormat
                    name="priceNegotiated"
                    value={
                      form?.priceNegotiated !== null
                        ? form?.priceNegotiated
                        : null
                    }
                    customInput={Input}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale={true}
                    addonBefore={
                      <Select
                        value={
                          form?.priceNegotiatedUnit != null
                            ? form.priceNegotiatedUnit
                            : "VALUE_PER_ARROBA"
                        }
                        onChange={(value) =>
                          setForm({ ...form, priceNegotiatedUnit: value })
                        }
                      >
                        <Select.Option value="VALUE_PER_ARROBA">
                          R$/@
                        </Select.Option>
                        <Select.Option value="VALUE_PER_KG">
                          R$/Kg
                        </Select.Option>
                      </Select>
                    }
                    onValueChange={({ floatValue }) => {
                      setForm({ ...form, priceNegotiated: floatValue });
                      setErrors(errors.filter((e) => e !== "priceNegotiated"));
                    }}
                    placeholder={translation.defaultPlaceholder}
                  />
                </Row>
              </Col>

              {/* Weight Per Animal */}
              <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                <Row>
                  <label
                    className={
                      errors.includes("weightPerAnimal") ? "error" : ""
                    }
                  >
                    {translation.financial.animalSell.form.weightPerAnimal}*
                  </label>
                </Row>
                <Row>
                  <div className="inputWithIconButton">
                    <NumberFormat
                      disabled={customSellWeight}
                      name="weightPerAnimal"
                      customInput={Input}
                      value={
                        customSellWeight
                          ? avgSellWeight
                          : form?.weightPerAnimal !== null
                          ? form?.weightPerAnimal
                          : ""
                      }
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      addonAfter="Kg"
                      placeholder={translation.defaultPlaceholder}
                      onValueChange={({ floatValue }) => {
                        setForm({ ...form, weightPerAnimal: floatValue });
                        setErrors(
                          errors.filter((e) => e !== "weightPerAnimal")
                        );
                      }}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setForm({ ...form, weightPerAnimal: null });
                        if (customSellWeight) {
                          clearCustomWeight();
                        } else {
                          completeCustomWeight(form?.sellDate);
                        }
                        setCustomSellWeight(!customSellWeight);
                      }}
                    >
                      {customSellWeight ? (
                        <StopIcon isRed={true} />
                      ) : (
                        <PencilIcon />
                      )}
                    </button>
                  </div>
                </Row>
              </Col>

              {/* Carcass harnessing */}
              <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                <Row>
                  <label
                    className={
                      errors.includes("carcassHarnessing") ? "error" : ""
                    }
                  >
                    {translation.financial.animalSell.form.carcassHarnessing}*
                  </label>
                </Row>
                <Row>
                  <div className="inputWithIconButton">
                    <NumberFormat
                      disabled={customCarcassHarnessing}
                      name="carcassHarnessing"
                      customInput={Input}
                      value={
                        customCarcassHarnessing
                          ? avgCarcassHarnessing
                          : form?.carcassHarnessing !== null
                          ? form?.carcassHarnessing
                          : null
                      }
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale={true}
                      addonAfter="%"
                      placeholder={translation.defaultPlaceholder}
                      onValueChange={({ floatValue }) => {
                        let value = floatValue;
                        if (value > 75.5) {
                          value = 75.5;
                        }
                        setForm({ ...form, carcassHarnessing: value });
                        setErrors(
                          errors.filter((e) => e !== "carcassHarnessing")
                        );
                      }}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setForm({ ...form, carcassHarnessing: null });
                        if (customCarcassHarnessing) {
                          clearCustomCarcassHarnessing();
                        }
                        setCustomCarcassHarnessing(!customCarcassHarnessing);
                      }}
                    >
                      {customCarcassHarnessing ? (
                        <StopIcon isRed={true} />
                      ) : (
                        <PencilIcon />
                      )}
                    </button>
                  </div>
                </Row>
              </Col>
            </Row>

            {/* Transaction fields */}
            <Row
              type="flex"
              align="middle"
              justify="start"
              gutter={8}
              className="row-form"
            >
              {/* Document number */}
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <Row>
                  <label
                    className={errors.includes("documentNumber") ? "error" : ""}
                  >
                    {translation.financial.animalSell.form.documentNumber}*
                  </label>
                </Row>
                <Row>
                  <div className="inputWithButton">
                    <Input
                      name="documentNumber"
                      value={form?.documentNumber}
                      maxLength={22}
                      placeholder={translation.defaultPlaceholder}
                      onChange={(e) => {
                        setForm({ ...form, documentNumber: e.target.value });
                        setErrors(errors.filter((e) => e !== "documentNumber"));
                      }}
                    />
                    <Tooltip
                      title={`Gerar "${translation.financial.transactions.form.documentNumber}" único automaticamente.`}
                    >
                      <Button
                        icon="form"
                        onClick={() => {
                          let doc = `BX${String(
                            new Date().valueOf() + Math.random()
                          )
                            .replace(/[^\w\s]/gi, "")
                            .padStart(20, "0")}`;
                          setForm({ ...form, documentNumber: doc });
                        }}
                      >
                        Gerar
                      </Button>
                    </Tooltip>
                  </div>
                </Row>
              </Col>
              {/* Billing date */}
              <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                <Row>
                  <label
                    className={errors.includes("billingDate") ? "error" : ""}
                  >
                    {translation.financial.animalSell.form.billingDate}*
                  </label>
                </Row>
                <Row>
                  <DatePicker
                    name="billingDate"
                    className="maxWidthField"
                    value={form?.billingDate || undefined}
                    placeholder={translation.defaultDatePickerPlaceholder}
                    format={formatDate}
                    disabledDate={(current) => current.isBefore(form?.sellDate)}
                    onChange={(date) => {
                      setForm({ ...form, billingDate: date });
                    }}
                  />
                </Row>
              </Col>
              {/* Expiration date */}
              <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                <Row>
                  <label
                    className={errors.includes("expirationDate") ? "error" : ""}
                  >
                    {translation.financial.animalSell.form.expirationDate}*
                  </label>
                </Row>
                <Row>
                  <DatePicker
                    name="expirationDate"
                    className="maxWidthField"
                    value={form?.expirationDate || undefined}
                    placeholder={translation.defaultDatePickerPlaceholder}
                    format={formatDate}
                    disabled={!form?.billingDate || !form?.sellDate}
                    disabledDate={(current) =>
                      current.isBefore(moment(form.sellDate))
                    }
                    onChange={async (date) => {
                      try {
                        await handleConsistExpirationDate(
                          date,
                          form.billingDate
                        );
                        setForm({ ...form, expirationDate: date });
                      } catch (error) {
                        setForm({ ...form, expirationDate: null });
                      }
                    }}
                  />
                </Row>
              </Col>
              {/* Payment */}
              <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                <Row>
                  <label className={errors.includes("payment") ? "error" : ""}>
                    {translation.financial.animalSell.form.payment}
                  </label>
                </Row>
                <Row>
                  <Radio.Group
                    value={form.payment}
                    defaultValue="inCash"
                    buttonStyle="solid"
                    onChange={(e) => {
                      setForm({ ...form, payment: e.target.value });
                    }}
                  >
                    <Radio.Button
                      value="inCash"
                      onClick={() => setForm({ ...form, installments: [] })}
                    >
                      {translation.financial.animalSell.form.inCash}
                    </Radio.Button>
                    <Radio.Button
                      value="part"
                      onClick={handleOpenCloseInstallmentsModal}
                    >
                      {translation.financial.animalSell.form.part}
                    </Radio.Button>
                  </Radio.Group>
                </Row>
              </Col>
              {/* Result Center */}
              <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                <Row>
                  <label
                    className={errors.includes("resultCenter") ? "error" : ""}
                  >
                    {translation.financial.animalSell.form.resultCenter}
                  </label>
                </Row>
                <Row>
                  <Radio.Group
                    value={form?.resultCenterApportionmentDefault}
                    defaultValue={true}
                    buttonStyle="solid"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        resultCenterApportionmentDefault: e.target.value,
                      });
                    }}
                  >
                    <Radio.Button value={true}>
                      {
                        translation.financial.animalSell.form
                          .resultCenterApportionmentDefault
                      }
                    </Radio.Button>
                    <Radio.Button
                      disabled={
                        financialNature?.id === null ||
                        totalValue === null ||
                        totalValue === 0
                      }
                      value={false}
                      onClick={handleOpenCloseResultCenterApportionmentModal}
                    >
                      {
                        translation.financial.animalSell.form
                          .resultCenterApportionmentCustom
                      }
                    </Radio.Button>
                  </Radio.Group>
                </Row>
              </Col>
            </Row>
            <Row
              type="flex"
              align="middle"
              justify="start"
              gutter={8}
              className="row-form"
            >
              {/* Financial Projects */}
              <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                <Row>
                  <label>
                    {translation.financial.transactions.form.financialProjectId}
                  </label>
                </Row>
                <Row>
                  <Select
                    name="financialProjectId"
                    style={{ width: "100%" }}
                    value={form.financialProjectId || undefined}
                    placeholder={translation.defaultSelectPlaceholder}
                    loading={isLoadingFinancialProjects}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    onDeselect={() => {
                      setForm({
                        ...form,
                        financialProjectId: null,
                      });
                      setFinancialProjectSelected(null);
                    }}
                    onChange={(value) => {
                      setForm({
                        ...form,
                        financialProjectId: value,
                      });
                      setFinancialProjectSelected(
                        listFinancialProjects.find((f) => f.id === value)
                      );
                    }}
                  >
                    {listFinancialProjects.map((l) => (
                      <Select.Option key={l.id} value={l.id}>
                        {l.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Row>
              </Col>
              {/* Bonus */}
              <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                <Row>
                  <label>{translation.financial.transactions.form.bonus}</label>
                </Row>
                <Row>
                  <NumberFormat
                    name="priceNegotiated"
                    value={form?.bonus !== null ? form?.bonus : null}
                    customInput={Input}
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale={true}
                    onValueChange={({ floatValue }) => {
                      setForm({ ...form, bonus: floatValue });
                    }}
                    placeholder={translation.defaultPlaceholder}
                  />
                </Row>
                {form.bonus && (
                  <Row>
                    <label>{`${numberMask(bonusPerArroba, true)}/@`}</label>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>

          {/* Total value */}
          <Col span={3} className="colWithBorderLeft">
            <Row
              type="flex"
              align="bottom"
              justify="center"
              className="row-total-value"
            >
              <span className="label-total-value">
                Fat. Total
                <InfoTooltip
                  placement="top"
                  trigger="click"
                  title={
                    <>
                      <p>
                        Este valor, será calculado, de acordo com o tipo de
                        Valor Negociado, sendo:
                      </p>
                      <p>
                        <strong>Valor/@ Negociado:</strong> Corresponde ao peso
                        médio dos animais, transformado em @, ou seja, dividido
                        por 15. A quantidade de @ é multiplicada pelo valor da @
                        negociado com o comprador, multiplicado pelo
                        aproveitamento de carcaça. Desta forma, se tem o valor
                        médio por animal e multiplicado pela quantidade de
                        animais, chegando assim, no valor de faturamento total.
                      </p>
                      <p>
                        <strong>Valor/Kg Negociado:</strong> Corresponde ao peso
                        médio dos animais, multiplicando pela quantidade de
                        animais e posteriormente multiplicado pelo valor do Kg
                        informado, chegando assim, no valor de faturamento
                        total.
                      </p>
                    </>
                  }
                />
              </span>
            </Row>
            <Row type="flex" align="top" justify="center">
              <span className="total-value">
                {`${numberMask(totalValue || Number(0), true)}`}
              </span>
            </Row>
          </Col>
        </Row>
      </Spin>

      {/* Selected Animals Table */}
      <Row className="row-form">
        <Table
          rowKey="id"
          loading={isLoading}
          dataSource={Array.isArray(animalsCustomData) ? animalsCustomData : []}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 10,
            pageSizeOptions: ["10", "20", "30", "100", "500", "1000", "2000"],
          }}
          scroll={{ x: true }}
          size="small"
        >
          <Column
            title="Identificação"
            dataIndex="handlingNumber"
            key="handlingNumber"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("handlingNumber")}
          />
          <Column
            title="Lote"
            dataIndex="lotName"
            key="lotName"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("lotName")}
          />
          <Column
            title="Piquete/Baia"
            dataIndex="picketName"
            key="picketName"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("picketName")}
          />
          <Column
            title="Raça"
            dataIndex="breedName"
            key="breedName"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("breedName")}
          />
          <Column
            title="Sexo"
            dataIndex="gender"
            key="gender"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("gender")}
            render={(value) => {
              if (value === "M") {
                return <span>Macho</span>;
              } else {
                return <span>Fêmea</span>;
              }
            }}
          />
          <Column
            title="Idade"
            dataIndex="age"
            key="age"
            align="left"
            sorter
            render={(text, record) =>
              record.age !== null ? <span>{`${record.age} m`}</span> : ""
            }
            {...getColumnSearchAnimalsProps("age")}
          />
          <Column
            title="Último peso"
            dataIndex="lastDailyWeight"
            key="lastDailyWeight"
            align="left"
            render={(text, record) => (
              <span>
                {record.lastDailyWeight != null
                  ? `${numberMask(record.lastDailyWeight || 0)} Kg`
                  : `0 Kg`}
              </span>
            )}
            {...getColumnSearchAnimalsProps("lastDailyWeight")}
          />
          <Column
            title="Últ Pesagem"
            dataIndex="lastDailyWeightDate"
            key="lastDailyWeightDate"
            align="left"
            render={(text, record) => (
              <span>
                {record.lastDailyWeightDate != null
                  ? moment(record.lastDailyWeightDate).format(formatDate)
                  : null}
              </span>
            )}
            {...getColumnSearchAnimalsProps("lastDailyWeightDate")}
          />
          <Column
            title="Peso venda"
            dataIndex="weightSell"
            key="weightSell"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("weightSell")}
            render={(text, record) =>
              customSellWeight ? (
                <NumberFormat
                  name="weightSell"
                  customInput={Input}
                  value={
                    animalsCustomData.find((a) => a.id === record.id)
                      ?.weightSell || 0
                  }
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  addonAfter="Kg"
                  placeholder={translation.defaultPlaceholder}
                  onValueChange={({ floatValue }) =>
                    handleWeightSellChange(floatValue, record.id)
                  }
                />
              ) : (
                <span>{`${numberMask(record.weightSell || 0)} Kg`}</span>
              )
            }
          />
          <Column
            title="% Aprov. carcaça"
            dataIndex="carcassHarnessing"
            key="carcassHarnessing"
            align="left"
            sorter
            {...getColumnSearchAnimalsProps("carcassHarnessing")}
            render={(text, record) =>
              customCarcassHarnessing ? (
                <NumberFormat
                  name="carcassHarnessing"
                  customInput={Input}
                  value={
                    animalsCustomData.find((a) => a.id === record.id)
                      ?.carcassHarnessing
                  }
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale={true}
                  addonAfter="%"
                  placeholder={translation.defaultPlaceholder}
                  onValueChange={({ floatValue }) => {
                    let value = floatValue;
                    if (value > 75.5) {
                      value = 75.5;
                    }
                    handleCarcassHarnessingChange(value, record.id);
                  }}
                />
              ) : (
                <span>{`${numberMask(record.carcassHarnessing || 0)}`}</span>
              )
            }
          />
        </Table>
      </Row>

      <DrawerClient />

      <InstallmentsModal
        isInstallmentsModalVisible={isInstallmentsModalVisible}
        closeInstallmentsModal={handleOpenCloseInstallmentsModal}
        installments={form?.installments}
        installmentTotal={form?.installmentTotal}
        expirationDate={form?.expirationDate}
        value={totalValue}
        setFieldValue={handleUpdateInstallmentsFields}
      />
      <ResultCenterApportionmentModal
        isVisible={isResultCenterApportionmentModalVisible}
        closeModal={handleCancelCustomApportionmentModal}
        saveModal={handleSaveCustomApportionmentModal}
        financialNatureId={financialNature?.id}
        transactionValue={totalValue}
      />
    </>
  );
};

export default FormProductionSell;
