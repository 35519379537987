import { useContext } from "react";
import { AnimalFarmReproductiveProtocolContext } from "../../contexts/animalFarmReproductiveProtocolContext";

const useAnimalFarmReproductiveProtocolContext = () => {
  const value = useContext(AnimalFarmReproductiveProtocolContext);

  return value;
};

export default useAnimalFarmReproductiveProtocolContext;
