import styled from "styled-components";
import { Row } from "antd";

export const Container = styled.div`
  width: 100%;

  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  margin-top: 10px;
  background: #fff;

  font-family: "Asap", sans-serif;
  font-weight: normal;
  font-size: 14px;
  color: #4b4b4b;
`;

export const Body = styled(Row)`
  padding: 20px 10px;

  .ant-row-flex {
    width: 100%;
    align-items: center;
    & + .ant-row-flex {
      margin-top: 16px;
    }
  }
`;

export const Footer = styled.div`
  border-top: 1px solid #e8e5e5;
  padding: 20px 10px;
  .buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .selectDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: "Asap", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;
