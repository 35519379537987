export const Types = {
  RESET_DATA: "lotSupplementConsumption/RESET_DATA",
  CHANGE_OPERATION_MODE: "lotSupplementConsumption/CHANGE_OPERATION_MODE",
  SHOW_HIDE_DRAWER_AND_FETCH_DATA:
    "lotSupplementConsumption/SHOW_HIDE_DRAWER_AND_FETCH_DATA",
  SHOW_HIDE_DRAWER_AND_FETCH_DATA_SUCCESS:
    "lotSupplementConsumption/SHOW_HIDE_DRAWER_AND_FETCH_DATA_SUCCESS",
  SHOW_HIDE_DRAWER_AND_FETCH_DATA_ERROR:
    "lotSupplementConsumption/SHOW_HIDE_DRAWER_AND_FETCH_DATA_ERROR",
  FETCH_LIST_OF_SUPPLEMENT_CONSUMPTION_DATA:
    "lotSupplementConsumption/FETCH_LIST_OF_SUPPLEMENT_CONSUMPTION_DATA",
  FETCH_LIST_OF_SUPPLEMENT_CONSUMPTION_DATA_SUCCESS:
    "lotSupplementConsumption/FETCH_LIST_OF_SUPPLEMENT_CONSUMPTION_DATA_SUCCESS",
  FETCH_LIST_OF_SUPPLEMENT_CONSUMPTION_DATA_ERROR:
    "lotSupplementConsumption/FETCH_LIST_OF_SUPPLEMENT_CONSUMPTION_DATA_ERROR",
  CREATE_OR_UPDATE_DATA: "lotSupplementConsumption/CREATE_OR_UPDATE_DATA",
  CREATE_OR_UPDATE_DATA_SUCCESS:
    "lotSupplementConsumption/CREATE_OR_UPDATE_DATA_SUCCESS",
  CREATE_OR_UPDATE_DATA_ERROR:
    "lotSupplementConsumption/CREATE_OR_UPDATE_DATA_ERROR",
  DELETE_LOT_SUPPLEMENT_CONSUMPTION:
    "lotSupplementConsumption/DELETE_LOT_SUPPLEMENT_CONSUMPTION",
  DELETE_LOT_SUPPLEMENT_CONSUMPTION_SUCCESS:
    "lotSupplementConsumption/DELETE_LOT_SUPPLEMENT_CONSUMPTION_SUCCESS",
  DELETE_LOT_SUPPLEMENT_CONSUMPTION_ERROR:
    "lotSupplementConsumption/DELETE_LOT_SUPPLEMENT_CONSUMPTION_ERROR",
};

export const Creators = {
  resetData: () => ({
    type: Types.RESET_DATA,
    payload: {},
  }),
  changeOperationMode: (operationMode = "list", form) => ({
    type: Types.CHANGE_OPERATION_MODE,
    payload: {
      operationMode,
      form,
    },
  }),
  showOrHideDrawerAndFetch: (groupId, farmId, lotId = null) => ({
    type: Types.SHOW_HIDE_DRAWER_AND_FETCH_DATA,
    payload: {
      groupId,
      farmId,
      lotId,
    },
  }),
  showOrHideDrawerAndFetchSuccess: (
    lotData,
    listOfLotSupplementConsumption
  ) => ({
    type: Types.SHOW_HIDE_DRAWER_AND_FETCH_DATA_SUCCESS,
    payload: {
      lotData,
      listOfLotSupplementConsumption,
    },
  }),
  showOrHideDrawerAndFetchError: () => ({
    type: Types.SHOW_HIDE_DRAWER_AND_FETCH_DATA_ERROR,
    payload: {},
  }),
  fetchListOfSupplementConsumpiton: (groupId, farmId, lotId = null) => ({
    type: Types.FETCH_LIST_OF_SUPPLEMENT_CONSUMPTION_DATA,
    payload: {
      groupId,
      farmId,
      lotId,
    },
  }),
  fetchListOfSupplementConsumpitonSuccess: (
    listOfLotSupplementConsumption
  ) => ({
    type: Types.FETCH_LIST_OF_SUPPLEMENT_CONSUMPTION_DATA_SUCCESS,
    payload: {
      listOfLotSupplementConsumption,
    },
  }),
  fetchListOfSupplementConsumpitonError: () => ({
    type: Types.FETCH_LIST_OF_SUPPLEMENT_CONSUMPTION_DATA_ERROR,
    payload: {},
  }),
  createOrUpdate: (groupId, farmId, lotId = null, id = null, body) => ({
    type: Types.CREATE_OR_UPDATE_DATA,
    payload: {
      groupId,
      farmId,
      lotId,
      id,
      body,
    },
  }),
  createOrUpdateSuccess: () => ({
    type: Types.CREATE_OR_UPDATE_DATA_SUCCESS,
    payload: {},
  }),
  createOrUpdateError: () => ({
    type: Types.CREATE_OR_UPDATE_DATA_ERROR,
    payload: {},
  }),
  delete: (groupId, farmId, lotId = null, id = null) => ({
    type: Types.DELETE_LOT_SUPPLEMENT_CONSUMPTION,
    payload: {
      groupId,
      farmId,
      lotId,
      id,
    },
  }),
  deleteSuccess: () => ({
    type: Types.DELETE_LOT_SUPPLEMENT_CONSUMPTION_SUCCESS,
    payload: {},
  }),
  deleteError: () => ({
    type: Types.DELETE_LOT_SUPPLEMENT_CONSUMPTION_ERROR,
    payload: {},
  }),
};

const INITIAL_STATE = {
  lotData: null,
  isLoading: false,
  drawerVisible: false,
  listOfLotSupplementConsumption: [],
  operationMode: "list", // edit || new || list
  formLotSupplementConsumption: null,
};

export default function lotSupplementConsumption(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.CHANGE_OPERATION_MODE:
      return {
        ...state,
        operationMode: action.payload.operationMode,
        formLotSupplementConsumption: action.payload.form,
      };
    case Types.SHOW_HIDE_DRAWER_AND_FETCH_DATA:
      return {
        ...state,
        drawerVisible: !state.drawerVisible,
        operationMode: "list",
        isLoading: action.payload.lotId !== null ? true : false,
        lotData: null,
        listOfLotSupplementConsumption: [],
      };
    case Types.SHOW_HIDE_DRAWER_AND_FETCH_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lotData: action.payload.lotData,
        listOfLotSupplementConsumption:
          action.payload.listOfLotSupplementConsumption,
      };
    case Types.SHOW_HIDE_DRAWER_AND_FETCH_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case Types.FETCH_LIST_OF_SUPPLEMENT_CONSUMPTION_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case Types.FETCH_LIST_OF_SUPPLEMENT_CONSUMPTION_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listOfLotSupplementConsumption:
          action.payload.listOfLotSupplementConsumption,
      };
    case Types.FETCH_LIST_OF_SUPPLEMENT_CONSUMPTION_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case Types.CREATE_OR_UPDATE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case Types.CREATE_OR_UPDATE_DATA_SUCCESS:
      return {
        ...state,
        operationMode: "list",
        isLoading: false,
      };
    case Types.CREATE_OR_UPDATE_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case Types.DELETE_LOT_SUPPLEMENT_CONSUMPTION:
      return {
        ...state,
        isLoading: true,
      };
    case Types.DELETE_LOT_SUPPLEMENT_CONSUMPTION_SUCCESS:
      return {
        ...state,
        operationMode: "list",
        isLoading: false,
      };
    case Types.DELETE_LOT_SUPPLEMENT_CONSUMPTION_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
