import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import moment from "moment";
import CreditCard from "../../components/utils/creditCard";
import ButtonStandard from "../../components/utils/button";
import InputStandard from "../../components/utils/input";
import PaymentIcon from "../../components/admin/sidebar/icons/payment";

// Components
import {
  Row,
  Col,
  Timeline,
  Carousel,
  Radio,
  Select,
  Modal,
  notification,
} from "antd";
import {
  Container,
  PageTitle,
  ListInvoicesItem,
  ListItemCircle,
  Title,
  SubTitle,
  TotalInvoice,
  CardCustom,
  ListItemTitle,
  ListItemSubTitle,
} from "./styles";
import CreditCardIcon from "../../components/utils/icons/creditCard";
import {
  isValid,
  isExpirationDateValid,
  isSecurityCodeValid,
  getCreditCardNameByNumber,
} from "creditcard.js";
import {
  getPaymentInfo,
  getLastNumberCreditCard,
  updateDueDay,
  updatePaymentMethod,
} from "../../services/paymentService";
import {
  getOnlyNumber,
  validateCPF,
} from "../../services/helpersMethodsService";
import { getTokenCreditCard } from "../../services/paymentGatewayService";
import InvoiceIcon from "../../components/utils/icons/invoice";

const Payments = (props) => {
  // Variable Redux
  const {
    translation,
    user: { id: userId },
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  const [errors, setErrors] = useState([]);

  const [creditCardForm, setCreditCardForm] = useState({
    holder_name: "", // Nome do titular/portador do perfil de pagamento
    registry_code: "", // CPF ou CNPJ do titular/portador
    card_expiration: "", // Validade do cartão de crédito no formato MM/AA
    card_number: "", // Número completo do cartão de crédito
    card_cvv: "", // Código de segurança do cartão de crédito com 3 ou 4 dígitos
    payment_company_code: "",
  });

  // const creditCardBrand = useMemo(() => {
  //   switch (creditCardForm.payment_company_code) {
  //     case "Visa":
  //       return <VisaBrand />;
  //     case "Mastercard":
  //       return <MasterCardBrand />;
  //     case "Elo":
  //       return <EloBrand />;
  //     case "Hipercard":
  //       return <HiperCardBrand />;
  //     default:
  //       return null;
  //   }
  // }, [creditCardForm.payment_company_code]);

  const [showModal, setShowModal] = useState(false);
  const [loadingBills, setLoadingBills] = useState(true);
  const [selectedDueDay, setSelectedDueDay] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [bills, setBills] = useState(null);
  const [selectedBill, setSelectedBill] = useState(null);
  const [currentDueDay, setCurrentDueDay] = useState(null);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState(null);
  const [creditCardPartialNumber, setCreditCardPartialNumber] = useState(null);
  const [customerId, setCustomerId] = useState(null);

  // const dispatch = useDispatch();
  const carousel = useRef();

  useEffect(() => {
    async function loadBills() {
      try {
        const {
          data: { results },
        } = await getPaymentInfo({ groupId, farmId, userId });

        setCustomerId(results.customerId);
        setSelectedDueDay(results.dueDay);
        setCurrentDueDay(results.dueDay);
        setSelectedPaymentMethod(results.paymentMethod);
        setCurrentPaymentMethod(results.paymentMethod);

        if (results.paymentMethod === "CreditCard") {
          let customerId = results.customerId;
          const {
            data: {
              results: { cardNumberLastFour },
            },
          } = await getLastNumberCreditCard({
            groupId,
            farmId,
            userId,
            customerId,
          });
          setCreditCardPartialNumber(cardNumberLastFour);
        }

        if (results.bills && results.bills.length > 0) {
          setBills(results.bills);
          setSelectedBill(results.bills[0]);
          setLoadingBills(false);
        }
      } catch (error) {
        // notification.error({
        //   message:
        //     "Ocorreu um erro ao acessar as informações das faturas.",
        // });
      }
    }

    loadBills();
  }, [groupId, farmId, userId]);

  async function updatePaymentInfo() {
    const {
      data: {
        results: { cardNumberLastFour },
      },
    } = await getLastNumberCreditCard({ groupId, farmId, userId, customerId });
    setCreditCardPartialNumber(cardNumberLastFour);
  }

  const nextSlide = () => {
    carousel.current.next();
  };

  const previousSlide = () => {
    carousel.current.prev();
  };

  const handleSelectBill = (bill) => {
    setSelectedBill(bill);
  };

  function openModal() {
    setShowModal(true);
  }

  const closeModal = () => {
    setSelectedPaymentMethod(currentPaymentMethod);
    setShowModal(false);
  };

  const confirmModal = () => {
    if (handleValidateNewCreditCardForm()) {
      setShowModal(false);
    }
  };

  function handleRadioButton(value) {
    if (value === "NewCreditCard") {
      openModal();
    }
    setSelectedPaymentMethod(value);
  }

  function renderCarouselItem(bill) {
    if (!loadingBills) {
      return (
        <div key={bill.id}>
          <ListInvoicesItem
            key={bill.id}
            className="paid"
            onClick={() => handleSelectBill(bill)}
          >
            <Row type="flex" className="row-centralized">
              <Col span={24}>
                <ListItemTitle>
                  {moment(bill.dueAt).format("MMM/YYYY")}
                </ListItemTitle>
              </Col>
            </Row>
            <Row type="flex" className="row-centralized">
              <Col span={24}>
                <ListItemCircle />
                <ListItemSubTitle>R$ {bill.value}</ListItemSubTitle>
              </Col>
            </Row>
          </ListInvoicesItem>
        </div>
      );
    } else {
      return;
    }
  }

  function renderInvoiceHistory() {
    const carouselSettings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      infinite: false,
    };

    return (
      <>
        <Row type="flex" align="middle">
          <Title>Acompanhe sua movimentação mensal</Title>
        </Row>
        <Row type="flex" align="middle">
          <Col span={2}>
            <ButtonStandard
              buttonType="type2"
              width="100%"
              height="26px"
              onClick={previousSlide}
              background="#591F74"
            >
              {"<"}
            </ButtonStandard>
          </Col>
          <Col span={20}>
            <Carousel
              ref={(ref) => {
                carousel.current = ref;
              }}
              dotPosition="top"
              style={{ padding: "20px" }}
              {...carouselSettings}
            >
              {bills.map((bill) => renderCarouselItem(bill))}
            </Carousel>
          </Col>
          <Col span={2}>
            <ButtonStandard
              buttonType="type2"
              width="100%"
              height="26px"
              onClick={nextSlide}
              background="#591F74"
            >
              {">"}
            </ButtonStandard>
          </Col>
        </Row>
      </>
    );
  }

  function renderInvoiceDescription() {
    return (
      <CardCustom>
        <Row type="flex" align="middle">
          <Title>Descrição da fatura</Title>
        </Row>
        <Row type="flex" align="middle" style={{ marginTop: "13px" }}>
          {renderSubtitleInvoiceDescription()}
        </Row>
        <Row type="flex" align="middle" style={{ marginTop: "13px" }}>
          <Timeline>{renderTimelineItemDescription()}</Timeline>
        </Row>
        <Row type="flex" className="row-centralized">
          <TotalInvoice>
            <span>Valor R$ {selectedBill?.value}</span>
          </TotalInvoice>
        </Row>
        <Row type="flex" align="middle" style={{ marginTop: "13px" }}>
          <Col span={11}>
            <ButtonStandard
              buttonType="type2"
              width="100%"
              height="39px"
              background="#A0BF08"
              disabled={currentPaymentMethod === "credit_card"}
            >
              Visualizar Boleto
            </ButtonStandard>
          </Col>
          <Col span={11} offset={2}>
            <ButtonStandard
              buttonType="type2"
              width="100%"
              height="39px"
              background="#707070"
            >
              Enviar por E-mail
            </ButtonStandard>
          </Col>
        </Row>
        <Row type="flex" className="row-centralized">
          <ButtonStandard buttonType="type2" width="100%" height="39px">
            2º Via
          </ButtonStandard>
        </Row>
      </CardCustom>
    );
  }

  function renderSubtitleInvoiceDescription() {
    if (!selectedBill) {
      return;
    }

    let startDate = new Date(
      moment(`${selectedBill.chargeYear}-${selectedBill.chargeMonth}-01`)
    );

    let finalDate;
    if (selectedBill.chargeMonth === "12") {
      finalDate = new Date(moment(`${++selectedBill.chargeYear}-01-01`));
    } else {
      finalDate = new Date(
        moment(`${selectedBill.chargeYear}-${++selectedBill.chargeMonth}-01`)
      );
    }

    finalDate.setDate(0);
    return (
      <SubTitle>
        De <b>{moment(startDate).format("DD/MMM")}</b> até{" "}
        <b>{moment(finalDate).format("DD/MMM")}</b>
      </SubTitle>
    );
  }

  function renderTimelineItemDescription() {
    return (
      <Timeline.Item>
        <b>{moment(selectedBill?.dueAt).format("DD/MMM")}</b> - Serviços
        referente à BovExo
      </Timeline.Item>
    );
  }

  function renderPaymentMethod() {
    return (
      <CardCustom>
        <Row type="flex" align="middle">
          <Title>Como você prefere pagar?</Title>
        </Row>
        <Row type="flex" className="row-centralized">
          <Radio.Group
            style={{ width: "90%" }}
            value={selectedPaymentMethod}
            onChange={(event) => {
              handleRadioButton(event.target.value);
            }}
          >
            {creditCardPartialNumber ? (
              <Radio value="CreditCard">
                <CreditCardIcon />
                <b>Final: {creditCardPartialNumber}</b>
              </Radio>
            ) : (
              <></>
            )}
            <Radio value="NewCreditCard">
              <CreditCardIcon />
              Novo cartão de crédito
            </Radio>
            <Radio value="BankSlip">
              <InvoiceIcon />
              Boleto
            </Radio>
          </Radio.Group>
        </Row>
        <Row type="flex" className="row-centralized">
          <ButtonStandard
            disabled={selectedPaymentMethod === currentPaymentMethod}
            buttonType="type2"
            width="100%"
            height="39px"
            background="#591F74"
            onClick={() => submitPaymentMethod()}
          >
            Confirmar
          </ButtonStandard>
        </Row>
      </CardCustom>
    );
  }

  function renderChangeDueDate() {
    const { Option } = Select;
    return (
      <CardCustom>
        <Row type="flex" align="middle">
          <Title>Altere a data de vencimento</Title>
        </Row>
        <Row type="flex" align="middle">
          <SubTitle style={{ fontWeight: "normal" }}>
            Atualmente sua fatura vence no <b>dia {currentDueDay}</b>
          </SubTitle>
        </Row>
        <Row type="flex" align="middle">
          <Col span={16}>
            <Select
              placeholder="Informe aqui a data de vencimento"
              style={{ width: "100%" }}
              value={selectedDueDay != null ? selectedDueDay : undefined}
              onChange={(day) => setSelectedDueDay(day)}
            >
              {[...Array(31).keys()].map((el) => (
                <Option value={el + 1} key={el}>
                  Dia {el + 1}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={7} offset={1}>
            <ButtonStandard
              disabled={selectedDueDay === currentDueDay}
              buttonType="type2"
              width="100%"
              height="39px"
              background="#591F74"
              onClick={() => submitDueDay()}
            >
              Confirmar
            </ButtonStandard>
          </Col>
        </Row>
      </CardCustom>
    );
  }

  function renderModalCreditCard() {
    return (
      <Modal
        title="Cartão de Crédito"
        visible={showModal}
        onOk={confirmModal}
        onCancel={closeModal}
        okText="Confirmar"
        cancelText="Cancelar"
        style={{ minWidth: "60%" }}
      >
        <Container>{renderCreditCardForm()}</Container>
      </Modal>
    );
  }

  function renderCreditCardForm() {
    return (
      <>
        {/*<Row type="flex" justify="start" gutter={8}>*/}
        {/* Credit Card info */}
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          {/* holder_name */}
          <Row type="flex" justify="start" gutter={8}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <label
                  className={errors.includes("holder_name") ? "error" : ""}
                >
                  Nome do titular/portador
                </label>
              </Row>
              <Row>
                <InputStandard
                  border={errors.includes("holder_name") ? "error" : "normal"}
                  className={`input text-uppercase`}
                  width="100%"
                >
                  <input
                    type="text"
                    placeholder="Informe aqui..."
                    name="holder_name"
                    value={creditCardForm?.holder_name}
                    autoComplete="chrome-off"
                    onChange={(event) => {
                      setErrors((old) =>
                        old.filter((e) => e !== "holder_name")
                      );
                      setCreditCardForm({
                        ...creditCardForm,
                        holder_name: event.target.value,
                      });
                    }}
                  />
                </InputStandard>
              </Row>
            </Col>
          </Row>
          {/* registry_code */}
          <Row type="flex" justify="start" gutter={8}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <label
                  className={errors.includes("registry_code") ? "error" : ""}
                >
                  CPF
                </label>
              </Row>
              <Row>
                <InputStandard
                  border={errors.includes("registry_code") ? "error" : "normal"}
                  className={`input`}
                  width="100%"
                >
                  <NumberFormat
                    type="text"
                    placeholder="Informe aqui..."
                    name="registry_code"
                    value={creditCardForm?.registry_code}
                    autoComplete="chrome-off"
                    format="###.###.###-##"
                    onChange={(event) => {
                      setErrors((old) =>
                        old.filter((e) => e !== "registry_code")
                      );
                      setCreditCardForm({
                        ...creditCardForm,
                        registry_code: event.target.value,
                      });
                    }}
                  />
                </InputStandard>
              </Row>
            </Col>
          </Row>
          {/* card_number */}
          <Row type="flex" justify="start" gutter={8}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <label
                  className={errors.includes("card_number") ? "error" : ""}
                >
                  Número do Cartão
                </label>
              </Row>
              <Row>
                <InputStandard
                  border={errors.includes("card_number") ? "error" : "normal"}
                  className={`input`}
                  width="100%"
                >
                  <NumberFormat
                    type="text"
                    placeholder="#### #### #### ####"
                    name="card_number"
                    autoComplete="chrome-off"
                    format="#### #### #### ####"
                    value={creditCardForm?.card_number}
                    onChange={(event) => {
                      setCreditCardForm({
                        ...creditCardForm,
                        card_number: event.target.value,
                      });
                      setErrors((old) =>
                        old.filter((e) => e !== "card_number")
                      );
                    }}
                    onBlur={(event) =>
                      handleValidateCreditCardNumber(event.target.value)
                    }
                  />
                  {/* <div className="icon">{creditCardBrand}</div> */}
                </InputStandard>
              </Row>
            </Col>
          </Row>
          {/* card_expiration - card_cvv */}
          <Row type="flex" justify="start" gutter={8}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Row>
                <label
                  className={errors.includes("card_expiration") ? "error" : ""}
                >
                  Data do vencimento
                </label>
              </Row>
              <Row>
                <InputStandard
                  border={
                    errors.includes("card_expiration") ? "error" : "normal"
                  }
                  className={`input`}
                  width="100%"
                >
                  <NumberFormat
                    type="text"
                    placeholder="MM/AA"
                    name="card_expiration"
                    autoComplete="chrome-off"
                    format="##/##"
                    value={creditCardForm?.card_expiration}
                    onChange={(event) => {
                      setCreditCardForm({
                        ...creditCardForm,
                        card_expiration: event.target.value,
                      });
                      setErrors((old) =>
                        old.filter((e) => e !== "card_expiration")
                      );
                    }}
                    onBlur={(event) =>
                      handleValidateCreditCardExpiration(event.target.value)
                    }
                  />
                </InputStandard>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Row>
                <label className={errors.includes("card_cvv") ? "error" : ""}>
                  Código de segurança
                </label>
              </Row>
              <Row>
                <InputStandard
                  border={errors.includes("card_cvv") ? "error" : "normal"}
                  className={`input`}
                  width="100%"
                >
                  <NumberFormat
                    type="text"
                    placeholder="####"
                    name="card_cvv"
                    autoComplete="chrome-off"
                    format="####"
                    value={creditCardForm?.card_cvv}
                    onChange={(event) => {
                      setCreditCardForm({
                        ...creditCardForm,
                        card_cvv: event.target.value?.trim(),
                      });

                      setErrors((old) => old.filter((e) => e !== "card_cvv"));
                    }}
                    onBlur={(event) =>
                      handleValidateCreditCardCVV(event.target.value?.trim())
                    }
                  />
                </InputStandard>
              </Row>
            </Col>
          </Row>
        </Col>
        {/* Credit Card Component */}
        <Col xs={24} sm={24} md={24} lg={12} xl={12} className="card-col">
          <Row type="flex" className="row-centralized">
            <CreditCard
              card_number={getOnlyNumber(creditCardForm?.card_number)}
              card_expiration={creditCardForm?.card_expiration}
              holder_name={creditCardForm?.holder_name}
              card_cvv={creditCardForm?.card_cvv}
              payment_company_code={creditCardForm?.payment_company_code}
            />
          </Row>
        </Col>
        {/*</Row>*/}
      </>
    );
  }

  function handleValidateCreditCardNumber(value) {
    if (value != null) {
      const number = getOnlyNumber(value.trim());
      if (isValid(number)) {
        const brand = getCreditCardNameByNumber(number);
        setCreditCardForm((old) => ({
          ...old,
          payment_company_code: brand,
        }));
        return true;
      } else {
        setErrors((old) => [...old, "card_number"]);
        return false;
      }
    }
  }

  function handleValidateCreditCardExpiration(value) {
    if (value != null) {
      const month = value.trim().substr(0, 2);
      const year = value.trim().substr(3, 2);
      if (!isExpirationDateValid(month, year)) {
        setErrors((old) => [...old, "card_expiration"]);
        return false;
      }
      return true;
    }
  }

  function handleValidateCreditCardCVV(value) {
    const { card_number } = creditCardForm;
    const number = getOnlyNumber(card_number.trim());
    if (card_number != null && value != null) {
      if (!isSecurityCodeValid(number, value)) {
        setErrors((old) => [...old, "card_cvv"]);
        return false;
      }
      return true;
    }
  }

  function handleValidateNewCreditCardForm() {
    const {
      holder_name,
      registry_code,
      card_expiration,
      card_number,
      card_cvv,
    } = creditCardForm;
    let errorsArray = [];

    if (selectedPaymentMethod === "NewCreditCard") {
      if (holder_name == null || holder_name === "") {
        errorsArray.push("holder_name");
        notification.error({
          message: "Nome do titular/portardor é obrigatório.",
        });
      }
      if (
        registry_code == null ||
        registry_code === "" ||
        !validateCPF(registry_code)
      ) {
        errorsArray.push("registry_code");
        notification.error({
          message: "CPF inválido.",
        });
      }
      if (
        card_number == null ||
        card_number === "" ||
        !handleValidateCreditCardNumber(card_number)
      ) {
        errorsArray.push("card_number");
        notification.error({
          message: "Número do Cartão inválido.",
        });
      }
      if (
        card_expiration == null ||
        card_expiration === "" ||
        !handleValidateCreditCardExpiration(card_expiration)
      ) {
        errorsArray.push("card_expiration");
        notification.error({
          message: "Data do Vencimento inválida.",
        });
      }
      if (
        card_cvv == null ||
        card_cvv === "" ||
        !handleValidateCreditCardCVV(card_cvv)
      ) {
        errorsArray.push("card_cvv");
        notification.error({
          message: "Código de segurança inválido.",
        });
      }

      setErrors(errorsArray);

      return errorsArray.length === 0;
    }
  }

  // function submitShowBankSlip() {
  //   try {
  //     //await
  //   } catch (e) {
  //     notification.error({ message: "Erro ao carregar detalhes do boleto" });
  //   }
  // }

  // function submitResendByEmail() {
  //   try {
  //     //await
  //   } catch (e) {
  //     notification.error({ message: "Erro ao tentar enviar email" });
  //   }
  // }

  // function submit2Via() {
  //   try {
  //     //await
  //   } catch (e) {
  //     notification.error({ message: "Erro ao carregar os dados da fatura" });
  //   }
  // }

  async function submitPaymentMethod() {
    try {
      if (selectedPaymentMethod === "NewCreditCard") {
        if (handleValidateNewCreditCardForm()) {
          const {
            data: {
              payment_profile: { gateway_token: token },
            },
          } = await getTokenCreditCard(creditCardForm);
          let data = { paymentMethod: "CreditCard", gatewayToken: token };
          await updatePaymentMethod({ groupId, farmId, userId, data });
          updatePaymentInfo();
          setCurrentPaymentMethod("CreditCard");
          notification.success({
            message: "Forma de pagamento atualizada com sucesso",
          });
        } else {
          notification.error({ message: "Dados do cartão inválidos" });
        }
      } else {
        let data = { paymentMethod: selectedPaymentMethod };
        await updatePaymentMethod({ groupId, farmId, userId, data });
        notification.success({
          message: "Forma de pagamento atualizada com sucesso",
        });
      }
    } catch (e) {
      notification.error({
        message: "Ocorreu um erro durante a atualização da forma de pagamento",
      });
    }
  }

  async function submitDueDay() {
    try {
      let dueDay = { newDueDay: selectedDueDay };
      await updateDueDay({ groupId, farmId, userId, dueDay });
      notification.success({
        message: "Data de vencimento atualizada com sucesso",
      });
      setCurrentDueDay(selectedDueDay);
    } catch (e) {
      notification.error({
        message: "Ocorreu um erro durante a atualização do dia de vencimento",
      });
    }
  }

  return (
    <Container>
      {/* HEADER */}
      <Row type="flex" align="middle">
        <PageTitle>
          <PaymentIcon />
          <span>{translation.payments.title}</span>
        </PageTitle>
      </Row>
      <Col span={10} offset={1}>
        {!loadingBills ? (
          <>
            {renderInvoiceHistory()}
            <Row>{renderInvoiceDescription()}</Row>
          </>
        ) : (
          <Row type="flex" align="middle" style={{ marginTop: "13px" }}>
            <Title>Não há faturas disponíveis para consulta</Title>
          </Row>
        )}
      </Col>
      <Col span={11} offset={1}>
        <Row>{renderPaymentMethod()}</Row>
        <Row style={{ marginTop: "13px" }}>{renderChangeDueDate()}</Row>
      </Col>
      {renderModalCreditCard()}
    </Container>
  );
};

export default withRouter(Payments);
