import apiCommons from "../config/api_commons";
import getHeaders from "./getHeaders";

export function getEventsHistory(payload) {
    const { groupId, farmId, entity, entityId, signal } = payload;
    return apiCommons.get(
      `/bovexo/${groupId}/farms/${farmId}/logs/${entity}/${entityId}`,
      getHeaders(false, signal)
    );
  }
  