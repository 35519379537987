import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Creators as AppActions } from "../../../store/ducks/app";
import {
  Container,
  CustomModal,
  LeftContent,
  RightContent,
  Title,
  SubTitle,
  ListTitle,
  CustomDivider,
  CardProblem,
} from "./styles";
import { CustomSelect } from "../../../pages/lot/addAnimalsInLot/styles";
import { Select, Row, Col, Input, notification } from "antd";
import ButtonStandard from "../../utils/button";
import MessageIcon from "../../utils/icons/message";
import CopyClipboard from "../../utils/copyClipboard";

import { Icon } from "antd";

/* Services */
import { indexUserById } from "../../../services/userService";
import { sendEmailContactUs } from "../../../services/managementService";
import { phoneMask } from "../../../services/helpersMethodsService";

const ContactUsModal = () => {
  const [visible, setVisible] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [subject, setSubject] = useState(undefined);
  const [content, setContent] = useState("");

  const {
    modalContactUsVisible,
    isLogged,
    user,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadingData = async () => {
      if (isLogged && modalContactUsVisible) {
        const {
          data: { results: dataUser },
        } = await indexUserById(groupId, farmId, user.id);
        let { fullname, email, phone } = dataUser;
        setName(fullname);
        setEmail(email);
        setContact(phone);
        setVisible(true);
      }
    };

    loadingData();
  }, [modalContactUsVisible, isLogged, user, groupId, farmId]);

  /* Methods */
  const handleFinish = async () => {
    try {
      let data = {
        name: name,
        email: email,
        contact: contact,
        subject: subject,
        content: content,
      };
      await sendEmailContactUs(data);
      notification.success({
        message: "Mensagem enviada com sucesso, em breve entraremos em contato",
      });
      handleCancel();
    } catch (e) {
      if (e.response.status === 400) {
        notification.error({ message: "Formato de e-mail inválido" });
      } else if (e.response.status === 500) {
        notification.error({
          message: "Algo deu errado, por favor, tente mais tarde",
        });
      }
    }
  };

  const handleCancel = () => {
    dispatch(AppActions.hideModalContactUs());
    if (isLogged) setVisible(true);
  };

  const handleEdit = () => {
    setVisible(false);
  };

  return (
    <CustomModal
      height={521}
      width={705}
      visible={modalContactUsVisible}
      centered
      maskClosable={false}
      closable={true}
      footer={null}
      onCancel={handleCancel}
    >
      <Container>
        <LeftContent>
          <Title>
            <MessageIcon />
            <b>Fale Conosco</b>
          </Title>
          <SubTitle>
            Todos os canais de comunicação <br />
            disponíveis para você
          </SubTitle>

          <ListTitle>
            <div>
              <Icon
                type="phone"
                style={{ fontSize: "15px", color: "#C4DF3D" }}
              />
              <b>+55 (11) 4210-6098</b>
            </div>
            <div className="pad">
              <CopyClipboard text="+55 (11) 4210-6098" />
            </div>
          </ListTitle>

          <ListTitle>
            <div>
              <Icon
                type="mail"
                style={{ fontSize: "15px", color: "#C4DF3D" }}
              />
              <b>faleconosco@bovexo.com</b>
            </div>
            <div className="pad">
              <CopyClipboard text="faleconosco@bovexo.com" />
            </div>
          </ListTitle>

          <ListTitle>
            <div>
              <Icon
                type="pushpin"
                style={{ fontSize: "15px", color: "#C4DF3D" }}
              />
              <b>Rua Campo Verde, Nrº61</b> <br />
              <span>Edifício Ceilão - Conj. 71</span> <br />
              <span>
                Jardim Europa - São Paulo - SP <br />
              </span>
              <span>CEP 01456-010</span>
            </div>
            <div className="pad">
              <CopyClipboard text="Rua Campo Verde, Nrº61, Edifício Ceilão - Conj. 71, Jardim Europa - São Paulo - SP, CEP 01456-010 " />
            </div>
          </ListTitle>
        </LeftContent>
        <RightContent>
          <Title>Envie uma mensagem para a Bovexo</Title>

          <Row
            type="flex"
            style={{ marginTop: "13px", padding: "0 26px" }}
            align="middle"
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <label className={false ? "error" : ""}>
                  {visible ? "Usuário" : "Seu nome*"}
                </label>
              </Row>
              <Row>
                {!visible ? (
                  <Input
                    placeholder={"Informe"}
                    addonAfter={<Icon type="user" />}
                    value={name}
                    name="name"
                    autoComplete="chrome-off"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                ) : (
                  <span className="pad-2">
                    <b>{name}</b>
                  </span>
                )}
              </Row>
            </Col>
          </Row>

          <Row
            type="flex"
            style={{ marginTop: "13px", padding: "0 26px" }}
            align="middle"
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <label className={false ? "error" : ""}>E-mail*</label>
              </Row>
              <Row>
                {!visible ? (
                  <Input
                    placeholder={"Informe"}
                    addonAfter={<Icon type="mail" />}
                    value={email}
                    name="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                ) : (
                  <span className="pad-2">{email}</span>
                )}
              </Row>
            </Col>
          </Row>

          <Row
            type="flex"
            style={{
              marginTop: "13px",
              marginBottom: "13px",
              padding: "0 26px",
            }}
            align="middle"
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <label className={false ? "error" : ""}>Contato*</label>
              </Row>
              <Row>
                {!visible ? (
                  <Input
                    placeholder={"Informe"}
                    addonAfter={<Icon type="phone" />}
                    value={phoneMask(contact)}
                    name="contact"
                    onChange={(e) => {
                      setContact(e.target.value);
                    }}
                  />
                ) : (
                  <span className="pad-2">{contact}</span>
                )}
              </Row>
            </Col>
          </Row>

          <Row type="flex" style={{ marginBottom: "5px", padding: "0 26px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} align="right">
              {visible ? (
                <label className="edit" onClick={handleEdit}>
                  EDITAR DADOS
                </label>
              ) : null}
            </Col>
          </Row>

          <CustomDivider dashed />

          <Row type="flex" style={{ padding: "0 26px" }} align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {!visible ? (
                <Row>
                  <label className={false ? "error" : ""}>Assunto*</label>
                </Row>
              ) : null}
              <Row>
                {!visible ? (
                  <Row
                    type="flex"
                    style={{ marginBottom: "13px" }}
                    align="middle"
                  >
                    <Input
                      placeholder={"Informe"}
                      value={subject}
                      name="subject"
                      onChange={(e) => {
                        setSubject(e.target.value);
                      }}
                    />
                  </Row>
                ) : (
                  <CustomSelect
                    name="subject"
                    style={{ width: "100%" }}
                    placeholder="Assunto*"
                    onChange={(e) => {
                      setSubject(e);
                    }}
                  >
                    <Select.Option value={"other"}>Outro</Select.Option>
                    <Select.Option value={"problem"}>
                      Problemas no sistema
                    </Select.Option>
                  </CustomSelect>
                )}
              </Row>
            </Col>
          </Row>

          {subject === "problem" ? (
            <Row
              type="flex"
              style={{ marginBottom: "13px", padding: "0 26px" }}
              align="middle"
            >
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <CardProblem>
                  <Col xs={4} sm={4} md={4} lg={4} xl={3}>
                    <Icon
                      type="message"
                      style={{ fontSize: "15px", color: "#FE8D2A" }}
                    />
                  </Col>

                  <Col xs={4} sm={4} md={4} lg={4} xl={21}>
                    <span>
                      Problemas no sistema podem ser resolvidos abrindo um
                      chamado, clique em Abrir chamado
                    </span>
                  </Col>
                </CardProblem>
                <Row type="flex" style={{ marginTop: "13px" }}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} align="middle">
                    <ButtonStandard
                      buttonType="type1"
                      type="button"
                      width="165px"
                      height="35px"
                      margin="20px"
                      padding="0 0 3px 0"
                      // onClick={handleFinish}
                    >
                      Abrir chamado
                    </ButtonStandard>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <>
              <Row
                type="flex"
                style={{ marginBottom: "5px", padding: "0 26px" }}
                align="middle"
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row>
                    <Input.TextArea
                      placeholder={"Escreva pra nós aqui..."}
                      value={content}
                      style={{
                        width: "100%",
                        height: 100,
                        padding: "10px 10px",
                      }}
                      name="content"
                      onChange={(e) => {
                        setContent(e.target.value);
                      }}
                    />
                  </Row>
                </Col>
              </Row>

              <Row type="flex">
                <Col xs={22} sm={22} md={22} lg={22} xl={22} align="right">
                  <ButtonStandard
                    buttonType="type1"
                    type="button"
                    width="88px"
                    height="27px"
                    padding="0 0 5px 0"
                    onClick={handleFinish}
                  >
                    Enviar
                  </ButtonStandard>
                </Col>
              </Row>
            </>
          )}
        </RightContent>
      </Container>
    </CustomModal>
  );
};

export default ContactUsModal;
