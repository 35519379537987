import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import logo_image from "../../../../assets/images/dataAgro.png";

import { Container, Logo, Slide, SliderTrack } from "./styles";
import { Icon, Tooltip } from "antd";
import AlertIcon from "../../../drawers/alert/icons/alert";

// Services
import { getAllDailyLiveCattle } from "../../../../services/dataAgroService";
import { numberMask } from "../../../../services/helpersMethodsService";
import moment from "moment";

// import { Container } from './styles';
//GO, MG, MT, MS, SP, RO, TO, BA e PA
const SliderDataAgro = ({ isCollapse }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isObsolete, setIsObsolete] = useState(null);
  const [dataDate, setDataDate] = useState(null);
  const [dailyLiveCattles, setDailyLiveCattles] = useState([]);

  const {
    translation,
    groupSelected: { id: groupId },
    farmSelected: { id: farmId },
  } = useSelector((state) => state.app);

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      try {
        const {
          data: { results },
        } = await getAllDailyLiveCattle({ groupId, farmId });
        const { isObsolete, datas, syncDateData } = results;

        const filterIndicatorData = datas.filter((r) =>
          r.code.includes("D_PEPR_")
        );

        setIsObsolete(isObsolete);
        setDataDate(syncDateData);
        setDailyLiveCattles(filterIndicatorData);
      } catch (error) {
        console.error(error);
        setIsObsolete(true);
      } finally {
        setIsLoading(false);
      }
    }

    fetch();
  }, [groupId, farmId]);

  return (
    <>
      <Tooltip
        title={dataDate ? `Data: ${moment(dataDate).format("DD/MM/YYYY")}` : ""}
        placement="bottom"
      >
        <Logo>
          <img src={logo_image} alt="Logo Data Agro" />
          {isObsolete === true && (
            <div className="alert-icon">
              <AlertIcon />
            </div>
          )}
        </Logo>
      </Tooltip>
      <Container isCollapse={isCollapse}>
        {isLoading === false ? (
          <SliderTrack>
            {dailyLiveCattles.map((dlc) => (
              <Slide key={dlc.id}>
                <Icon
                  type={dlc.var > 0 ? "caret-up" : "caret-down"}
                  className={dlc.var > 0 ? "green" : "red"}
                />
                <span className="code">
                  {translation.dailyLiveCattlesDataAgro.codes[dlc.code]}
                </span>
                <span
                  className={dlc.var > 0 ? "value green" : "value red"}
                >{`${numberMask(dlc.ult, false)} (${
                  dlc.var > 0 ? "+" : "-"
                } ${numberMask(Math.abs(dlc.var), false)}%)`}</span>
              </Slide>
            ))}
          </SliderTrack>
        ) : null}
      </Container>
    </>
  );
};

export default SliderDataAgro;
