import styled from "styled-components";
import { Tabs, Card } from "antd";

export const Container = styled.div`
  overflow-y: hidden;

  .row-of-cards {
    display: flex;
    flex-direction: row;
    height: 100%;
    flex-wrap: wrap;
    width: 100%;
  }

  .div-row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .card-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    &.ant-col-0 {
      display: none;
    }
  }

  .grow-card {
    flex-grow: 1;
  }
`;

export const CardCustom = styled(Card)`
  margin-top: 13px !important;
  margin-bottom: 5px !important;
  overflow-x: hidden;
  border-radius: 7px !important;
  background: #fff !important;
  box-shadow: 0px 0px 10px #d8d8d8 !important;
  overflow-y: hidden;
  padding: 0px !important;

  .ant-card-body {
    padding-top: 14px;
  }

  .rowHeader {
    margin-bottom: 17px;
  }

  .rowForm {
    margin-top: 13px;
  }

  label {
    color: #9074bf;
    font-size: 14px;
    font-weight: bold;
    margin-right: 5px;
    &.error {
      color: #d44c4c;
      margin-right: 5px;
    }
  }

  .ant-select {
    width: 100%;
  }

  .ant-switch-checked {
    background-color: #a9c133 !important;
  }

  .display-label {
    font-weight: bold;
    display: block;
  }

  .ant-radio-wrapper span {
    font-weight: bold;
    font-size: 12px;
    margin-right: 0px;
  }

  .vertical-radio {
    display: block;
    height: 20px;
    line-height: 20px;
  }

  .table-row-radio-label {
    color: rgba(0, 0, 0, 0.65);

    span {
      font-weight: normal;
      // margin-right: 5px;
      font-size: 14px;
    }
  }
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  font-family: "Asap", sans-serif;
`;

export const PageTitle = styled.span`
  font-family: Asap;
  font-weight: bold;
  font-size: 25px;
  text-align: left;
  color: #9074bf;
`;

export const TabContainer = styled(Tabs)`
  margin-bottom: 10px !important;

  .ant-tabs-ink-bar {
    background-color: #684e94;
    height: 5px;
  }

  .ant-tabs-line {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab {
    font-family: "Asap" !important;
    font-size: 14px !important;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #4b4b4b;
  }

  .ant-tabs-nav .ant-tabs-tab-disabled,
  .ant-tabs-nav .ant-tabs-tab-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: #4b4b4b;
    font-weight: bold;
  }

  .ant-tabs-bar {
    border-bottom: 1px solid #a9c133;
  }

  .ant-tabs-tabpane {
    background-color: #fff;
    margin-top: -15px;
  }
`;

export const EditButton = styled.button`
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #9386aa;
  border: none;
  background: none;

  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
    path {
      fill: #9386aa !important;
    }
  }

  &:hover {
    color: #a9c133;
    cursor: pointer;

    svg {
      margin-left: 5px;
      path {
        fill: #a9c133 !important;
      }
    }
  }

  &:disabled {
    color: #a5a5a5;
    cursor: not-allowed;
    svg {
      margin-left: 5px;
      path {
        fill: #a5a5a5 !important;
      }
    }
  }
`;

export const ConfirmEditButton = styled.button`
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #a9c133;
  border: none;
  background: none;

  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
    path {
      fill: #a9c133 !important;
    }
  }

  &:hover {
    color: #9386aa;
    cursor: pointer;

    svg {
      margin-left: 5px;
      path {
        fill: #9386aa !important;
      }
    }
  }

  &:disabled {
    color: #a5a5a5;
    cursor: not-allowed;
    svg {
      margin-left: 5px;
      path {
        fill: #a5a5a5 !important;
      }
    }
  }
`;

export const CancelEditButton = styled.button`
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #c94c4c;
  border: none;
  background: none;

  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
    path {
      fill: #c94c4c !important;
    }
  }

  &:hover {
    color: #989898;
    cursor: pointer;

    svg {
      margin-left: 5px;
      path {
        fill: #989898 !important;
      }
    }
  }

  &:disabled {
    color: #a5a5a5;
    cursor: not-allowed;
    svg {
      margin-left: 5px;
      path {
        fill: #a5a5a5 !important;
      }
    }
  }
`;

export const DeleteButton = styled.button`
  font-family: Asap;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #c94c4c;
  border: none;
  background: none;

  display: flex;
  align-items: center;

  svg {
    path {
      fill: #c94c4c !important;
    }
  }

  &:hover {
    color: #eea29a;
    cursor: pointer;

    svg {
      path {
        fill: #a9c133 !important;
      }
    }
  }

  &:disabled {
    color: #a5a5a5;
    cursor: not-allowed;
    svg {
      path {
        fill: #a5a5a5 !important;
      }
    }
  }
`;

export const Footer = styled.div`
  border-top: 1px solid #e8e8e8;
  margin-top: 20px;
  width: 100%;
  padding: 10px 24px;
  border-radius: 0 0 4px 4px;

  .buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding-right: 24px; */

    button:first-of-type {
      margin-right: 12px !important;
    }
  }

  .ant-row-flex {
    margin: 0;
  }

  .selectDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: "Asap", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;

export const MenuContainer = styled.button`
  position: relative;
  background: transparent;
  border: none;
  &:hover {
    cursor: pointer;
    svg > g > circle {
      fill: #684e94 !important;
    }
  }
  &.active {
    cursor: pointer;
    svg > g > circle {
      fill: #684e94 !important;
    }
  }

  &:disabled {
    color: #a5a5a5;
    cursor: not-allowed;
    svg {
      margin-left: 5px;
      path {
        fill: #a5a5a5 !important;
      }
    }
  }

  & > * {
    text-decoration: none !important;
    color: #4b4b4b !important;
    font-size: 14px !important;
  }
`;
