import styled from "styled-components";
import { Card, Divider, Radio, Drawer, Popconfirm } from "antd";

export const Container = styled(Drawer)`
  .ant-drawer-body {
    padding: 24px 0px 10px 0px;
  }

  .rowLabel {
    margin-bottom: 7px;
    margin-top: 19px;
  }

  label.formLabel {
    font-family: Asap !important;
    color: #9074bf !important;
    font-size: 12px !important;
    font-weight: bold !important;
    margin-left: 15px !important;

    #bovexoInfoIcon {
      margin-left: 3px;
    }

    &.disabled {
      font-weight: bold !important;
      font-size: 12px !important;
      color: #c4c4c4 !important;
    }
    &.error {
      color: #d44c4c !important;
    }
  }

  .ant-row.rowInput {
    margin-top: 6px;
  }

  .tableButtonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      display: flex;
      justify-content: center !important;
      text-align: center !important;
      align-items: center !important;
      font-size: 14px !important;
      width: 77px !important;
      height: 30px !important;

      svg {
        margin-right: 3px !important;
      }
    }

    button:first-of-type {
      width: 116px !important;
      margin-right: 12px !important;
    }
  }

  .ant-switch-checked {
    background-color: #a9c133 !important;
  }

  .buttonPutToDeath {
    width: auto;
    height: 30px;
    border: none;
    padding: 0px 10px;
    border-radius: 5px;
    background: #684e94;
    box-shadow: 0px 6px 6px rgba(104, 78, 148, 0.3);

    font-family: Asap;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    color: #fff;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const Body = styled.div`
  width: 100%;
  padding: 0px 24px;
  min-height: -moz-calc(100vh - 165px);
  min-height: -webkit-calc(100vh - 165px);
  min-height: calc(100vh - 165px);

  .lotMessage {
    font-family: Asap;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
    color: #4b4b4b;
  }
`;

export const AnimalCard = styled(Card)`
  border-radius: 9px !important;
  background: #f5f5f5 !important;
  border: 1px dashed #707070 !important;
  padding: 11px 0px 11px 16px !important;
  margin-top: 21px !important;

  &.disabled {
    border: none !important;
  }

  &.ant-card {
    box-shadow: none !important;
  }

  .ant-card-body {
    padding: 0px !important;
  }

  .ant-input {
    background: transparent !important;
    border-color: #fe8d2a !important;

    &.ant-input-disabled {
      border-color: #c4c4c4 !important;
    }
  }

  .animalCardCol {
    padding-top: 9px !important;
  }
`;

export const CustomDivider = styled(Divider)`
  margin: 10px 0 !important;

  .ant-divider-horizontal.ant-divider-with-text-center,
  .ant-divider-horizontal.ant-divider-with-text-left,
  .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 10px 0 !important;
  }

  &.ant-divider-dashed {
    border-color: #e8e5e5 !important;
  }
  &.ant-divider-with-text-center::before,
  &.ant-divider-with-text-left::before,
  &.ant-divider-with-text-right::before,
  &.ant-divider-with-text-center::after,
  &.ant-divider-with-text-left::after,
  &.ant-divider-with-text-right::after {
    border-top: 1px dashed #e8e5e5 !important;
  }
  .ant-divider-inner-text {
    font-family: Asap;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #a5a5a5;
  }
`;

export const RadioButtonCustom = styled(Radio.Button)`
  margin-top: 6px !important;
  border-radius: 15px !important;
  background: transparent !important;
  border: 1px solid #4b4b4b !important;
  border-color: #4b4b4b !important;

  span {
    font-family: Asap !important;
    font-weight: normal !important;
    font-size: 14px !important;
    text-align: left !important;
    color: #4b4b4b !important;
  }

  &:first-of-type {
    margin-left: 15px;
  }

  &:not(:first-of-type) {
    margin-left: 4px;
  }

  &.ant-radio-button-wrapper-disabled {
    border-color: #c4c4c4 !important;
    span {
      color: #c4c4c4 !important;
    }
  }

  &.ant-radio-button-wrapper-checked {
    &:first-child,
    &:not(:first-child) {
      border-color: #4b4b4b !important;
    }
    color: #fff !important;
    background: #4b4b4b !important;
    border-color: #4b4b4b !important;

    &.ant-radio-button-wrapper-disabled {
      border-color: #c4c4c4 !important;
      background: #c4c4c4 !important;
    }

    span {
      font-family: Asap !important;
      font-weight: normal !important;
      font-size: 14px !important;
      text-align: left !important;
      color: #fff !important;
    }
  }
  &:not(:first-child)::before {
    visibility: hidden !important;
  }
`;

export const GeneticBox = styled.div`
  width: 100%;
  border-radius: 9px;
  background: transparent;
  border: 1px dashed #c4c4c4;
  padding: 20px 15px;

  span.geneticTitle {
    font-family: Asap;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    color: #a5a5a5;
    margin-left: 13px;
    margin-bottom: 16px;
  }
`;

export const PopConfirmCustom = styled(Popconfirm)`
  .ant-popover-buttons {
    display: flex !important;
    justify-content: space-between !important;
  }

  .ant-btn:first-of-type {
    background: #d44c4c !important;
    color: #fff !important;
  }

  #iconExclamation {
    font-size: 18px !important;
    color: #d44c4c !important;
  }
`;

export const Footer = styled.div`
  border-top: 1px solid #e8e8e8;
  margin-top: 20px;
  width: 100%;
  padding: 10px 24px;
  border-radius: 0 0 4px 4px;

  .buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding-right: 24px; */

    button:first-of-type {
      margin-right: 12px !important;
    }
  }

  .ant-row-flex {
    margin: 0;
  }

  .selectDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-family: "Asap", sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;
