import React from "react";
import { numberMask } from "../../../../../../../../services/helpersMethodsService";
import { CardSupplementSupplyPerDietDashboardTableContainer } from "./styles";
import useLotDetailsSupplementSupplyContext from "../../../../../../../../hooks/useLotDetailsSupplementSupplyContext";

// import { Container } from './styles';

function CardSupplementSupplyPerDietDashboard() {
  const { supplementSuppliedPerDiet } = useLotDetailsSupplementSupplyContext();
  return (
    <CardSupplementSupplyPerDietDashboardTableContainer>
      <table>
        <thead>
          <tr>
            <th>
              <span>Dieta</span>
            </th>
            <th>
              <span>Dias</span>
            </th>
            <th>
              <span>Qtd. Ofertada (kg)</span>
            </th>
            <th>
              <span>Custo</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {supplementSuppliedPerDiet.map((supplement) => {
            return (
              <tr key={supplement.dietName}>
                <td>{supplement.dietName}</td>
                <td>{supplement.days}</td>
                <td>{numberMask(supplement.amountSupplied, false)}</td>
                <td>{numberMask(supplement.totalCost, true)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </CardSupplementSupplyPerDietDashboardTableContainer>
  );
}

export default CardSupplementSupplyPerDietDashboard;
