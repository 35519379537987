export const Types = {
  RESET_DATA: "handlingOperation/RESET_DATA",
  GET_ALL_HANDLING_OPERATION: "handlingOperation/GET_ALL_HANDLING_OPERATION",
  GET_ALL_HANDLING_OPERATION_SUCCESS:
    "handlingOperation/GET_ALL_HANDLING_OPERATION_SUCCESS",
  GET_ALL_HANDLING_OPERATION_ERROR:
    "handlingOperation/GET_ALL_HANDLING_OPERATION_ERROR",
  GET_HANDLING_OPERATION: "handlingOperation/GET_HANDLING_OPERATION",
  GET_HANDLING_OPERATION_SUCCESS:
    "handlingOperation/GET_HANDLING_OPERATION_SUCCESS",
  GET_HANDLING_OPERATION_ERROR:
    "handlingOperation/GET_HANDLING_OPERATION_ERROR",
  SHOW_DRAWER_HANDLING_OPERATION:
    "handlingOperation/SHOW_DRAWER_HANDLING_OPERATION",
  HIDE_DRAWER_HANDLING_OPERATION:
    "handlingOperation/HIDE_DRAWER_HANDLING_OPERATION",
  SAVE_HANDLING_OPERATION: "handlingOperation/SAVE_HANDLING_OPERATION",
  SAVE_HANDLING_OPERATION_SUCCESS:
    "handlingOperation/SAVE_HANDLING_OPERATION_SUCCESS",
  SAVE_HANDLING_OPERATION_ERROR:
    "handlingOperation/SAVE_HANDLING_OPERATION_ERROR",
  DELETE_HANDLING_OPERATION: "handlingOperation/DELETE_HANDLING_OPERATION",
  DELETE_HANDLING_OPERATION_SUCCESS:
    "handlingOperation/DELETE_HANDLING_OPERATION_SUCCESS",
  DELETE_HANDLING_OPERATION_ERROR:
    "handlingOperation/DELETE_HANDLING_OPERATION_ERROR"
};

export const Creators = {
  indexHandlingOperation: (
    groupId,
    page = 0,
    sorter = {},
    filters = "",
    withoutPagination = false
  ) => ({
    type: Types.GET_ALL_HANDLING_OPERATION,
    payload: {
      groupId,

      page,
      sorter,
      filters,
      withoutPagination
    }
  }),
  indexHandlingOperationSuccess: data => ({
    type: Types.GET_ALL_HANDLING_OPERATION_SUCCESS,
    payload: {
      data
    }
  }),
  indexHandlingOperationError: error => ({
    type: Types.GET_ALL_HANDLING_OPERATION_ERROR,
    payload: {
      error
    }
  }),
  showHandlingOperation: (groupId, id) => ({
    type: Types.GET_HANDLING_OPERATION,
    payload: {
      groupId,

      id
    }
  }),
  showHandlingOperationSuccess: data => ({
    type: Types.GET_HANDLING_OPERATION_SUCCESS,
    payload: {
      data
    }
  }),
  showHandlingOperationError: error => ({
    type: Types.GET_HANDLING_OPERATION_ERROR,
    payload: {
      error
    }
  }),
  showDrawer: (actionType = "edit") => ({
    type: Types.SHOW_DRAWER_HANDLING_OPERATION,
    payload: {
      actionType
    }
  }),
  hideDrawer: () => ({
    type: Types.HIDE_DRAWER_HANDLING_OPERATION,
    payload: {}
  }),
  saveHandlingOperation: (
    groupId,
    id = null,
    handlingOperation,
    saveOptions
  ) => ({
    type: Types.SAVE_HANDLING_OPERATION,
    payload: {
      groupId,
      id,
      handlingOperation,
      saveOptions
    }
  }),
  saveHandlingOperationSuccess: (data, saveOptions) => ({
    type: Types.SAVE_HANDLING_OPERATION_SUCCESS,
    payload: { data, saveOptions }
  }),
  saveHandlingOperationError: error => ({
    type: Types.SAVE_HANDLING_OPERATION_ERROR,
    payload: { error }
  })
};

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  handlingOperationData: null,
  drawerVisible: false,
  error: {}
};

export default function handlingOperation(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_DATA:
      return INITIAL_STATE;
    case Types.GET_ALL_HANDLING_OPERATION:
      return { ...state, isLoading: true };
    case Types.GET_ALL_HANDLING_OPERATION_SUCCESS:
      const { data: handlingOperations } = action.payload;
      return { ...state, isLoading: false, data: handlingOperations };
    case Types.GET_ALL_HANDLING_OPERATION_ERROR:
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: null,
        error
      };
    case Types.GET_HANDLING_OPERATION:
      return { ...state, isLoading: true };
    case Types.GET_HANDLING_OPERATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        handlingOperationData: action.payload.data
      };
    case Types.GET_HANDLING_OPERATION_ERROR:
      return {
        ...state,
        isLoading: false,
        handlingOperationData: null,
        error
      };
    case Types.SHOW_DRAWER_HANDLING_OPERATION:
      return {
        ...state,
        drawerVisible: true,
        handlingOperationData:
          action.payload.actionType === "new"
            ? null
            : state.handlingOperationData
      };
    case Types.HIDE_DRAWER_HANDLING_OPERATION:
      return { ...state, drawerVisible: false, handlingOperationData: null };
    case Types.SAVE_HANDLING_OPERATION:
      return { ...state, isLoading: true };
    case Types.SAVE_HANDLING_OPERATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        handlingOperationData: null,
        drawerVisible: action.payload.saveOptions
      };
    case Types.SAVE_HANDLING_OPERATION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    case Types.DELETE_HANDLING_OPERATION:
      return { ...state, isLoading: true };
    case Types.DELETE_HANDLING_OPERATION_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case Types.DELETE_HANDLING_OPERATION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}
