import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "moment/locale/pt-br";
import "moment/locale/en-nz";
import "moment/locale/es";
import * as Yup from "yup";
import { Formik } from "formik";
/** Redux libs */
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Creators as LotActions } from "../../../store/ducks/lot";
/** Components */
import {
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  notification,
  Spin,
  Radio,
  Modal,
} from "antd";
import ButtonStandard from "../../utils/button";
import { Container, Footer, CustomDivider, InfoCard } from "./styles";
import NoteIcon from "../../utils/icons/note";
import NumberFormat from "react-number-format";
import OkActionModal from "../../modals/okActionModal";
import InfoTooltip from "../../utils/infoTooltip";
import RadioButton from "../../utils/radioButton";
/** Services */
import { getListOfProfitCenterIndex } from "../../../services/profitCenterService";
import { getDietStrategyIndex } from "../../../services/dietStrategyService";
import {
  getLoatAcquisitionData,
  getLotShow,
  getLotStatistics,
} from "../../../services/lotService";
import { getBreedIndexActive } from "../../../services/breedService";
import { getPicketIndexDropDown } from "../../../services/picketService";

class DrawerLotProduction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breeds: [],
      pickets: [],
      formLot: {
        id: null,
        name: "",
        breedId: null,
        gender: "Male",
        cap: false,
        identification: null,
        asymptoticWeight: "",
        carcassHarnessing: "",
        supplierId: null,
        profitCenterId: null,
        dietStrategyBaseLine: null,
        dietStrategies: [],
        referenceAcquisitionDate: null,
        referenceAcquisitionWeight: "",
        referenceBirthdayDate: null,
        status: "Pending",
        averageAnimalStay: null,
        changeAnimalsFunction: false,
        animalFarmFunction: null,
        picketId: null,
        picketLotEntry: null,
      },
      listOfSuppliers: null,
      listOfProfitCenters: null,
      isLoadingDietStrategies: false,
      allDietStrategies: [],
      listofDietStrategies: [],
      listofPickets: null,
      saveOptions: "only_save",
      isLoading: false,
      amountAnimals: null,
      showModal: false,
      valueSubmit: null,
      valueId: null,
      lotAlreadyHavePicket: false,
      selectedPicket: null,
    };
    this.inputNameRef = React.createRef();
    this.formik = React.createRef();
  }

  closeDrawer = () => {
    const { lotActions } = this.props;
    this.setState({
      formLot: {
        id: null,
        name: "",
        breedId: null,
        gender: "Male",
        cap: false,
        identification: null,
        asymptoticWeight: "",
        carcassHarnessing: "",
        supplierId: null,
        profitCenterId: null,
        dietStrategyBaseLine: null,
        dietStrategies: [],
        referenceAcquisitionDate: null,
        referenceAcquisitionWeight: "",
        referenceBirthdayDate: null,
        status: "Pending",
        averageAnimalStay: null,
        changeAnimalsFunction: false,
        animalFarmFunction: null,
        picketId: null,
        picketLotEntry: null,
      },
    });
    this.formik.current.resetForm({
      id: null,
      name: "",
      breedId: null,
      gender: "Male",
      cap: false,
      identification: null,
      asymptoticWeight: "",
      carcassHarnessing: "",
      supplierId: null,
      profitCenterId: null,
      dietStrategyBaseLine: null,
      dietStrategies: [],
      referenceAcquisitionDate: null,
      referenceAcquisitionWeight: "",
      referenceBirthdayDate: null,
      status: "Pending",
      averageAnimalStay: null,
      changeAnimalsFunction: false,
      animalFarmFunction: null,
      picketId: null,
      picketLotEntry: null,
    });
    lotActions.hideDrawerLotProduction();
  };

  getBreeds = async (groupId, farmId) => {
    const {
      data: { results: breeds },
    } = await getBreedIndexActive({
      groupId,
      farmId,
      withoutBreedCurveParam: true,
    });

    this.setState({ breeds });
  };

  getPickets = async (groupId, farmId) => {
    const {
      data: { results: pickets },
    } = await getPicketIndexDropDown({
      groupId,
      farmId,
    });

    this.setState({ pickets });
  };

  getProfitCenters = async (groupSelected, farmSelected) => {
    const { id: groupId } = groupSelected;
    const { id: farmId } = farmSelected;

    const {
      data: { results: allProfitCenters },
    } = await getListOfProfitCenterIndex({ groupId, farmId });
    this.setState({
      listOfProfitCenters: allProfitCenters.filter(
        (e) => e.status === "Active"
      ),
    });
  };

  getAmountAnimals = async (idLot) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
    } = this.props;
    const {
      data: { results: amountAnimals },
    } = await getLotStatistics({
      groupId: groupId,
      farmId: farmId,
      id: idLot,
      statistics: "AmountActiveAnimals",
    });
    this.setState({
      amountAnimals: amountAnimals,
    });
  };

  getDietStrategies = async (lot) => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      lot: { lotIdEdit },
    } = this.props;
    try {
      this.setState({ isLoadingDietStrategies: true });
      const {
        data: { results: allDietStrategies },
      } = await getDietStrategyIndex({
        groupId,
        farmId,
        withoutPagination: true,
      });
      let listofDietStrategies = [];
      let allDietStrategiesFiltered = allDietStrategies.filter(
        (e) => e.status === "Active"
      );
      if (lotIdEdit != null) {
        if (lot?.breedId != null && lot?.cap != null && lot?.gender != null) {
          listofDietStrategies = allDietStrategies.filter(
            (e) =>
              e.status === "Active" &&
              (e.lot?.id === lotIdEdit ||
                (e.lot === null &&
                  e.breedId === lot?.breedId &&
                  e.cap === lot?.cap &&
                  e.gender === lot?.gender))
          );
        } else {
          listofDietStrategies = allDietStrategies.filter(
            (e) => e.status === "Active" && e.lot?.id === lotIdEdit
          );
        }
      }
      this.setState({
        listofDietStrategies,
        allDietStrategies: allDietStrategiesFiltered,
        isLoadingDietStrategies: false,
      });
    } catch (error) {
      this.setState({ isLoadingDietStrategies: false });
    }
  };

  getLotStatus = (values) => {
    if (
      values?.picketId === null ||
      values?.dietStrategyBaseLine === undefined
    ) {
      return "Pending";
    } else if (
      values?.picketId !== null &&
      values?.dietStrategyBaseLine !== null
    ) {
      return "Active";
    } else {
      return values?.status;
    }
  };

  openModal = (values, lot) => {
    this.setState({
      showModal: true,
      valueSubmit: lot,
      valueId: values?.id,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      continueProccess: true,
    });
  };

  onSubmitModal = async () => {
    const { valueSubmit, valueId } = this.state;

    if (valueId === null) {
      await this.handleNewLot(valueSubmit);
      this.closeModal();
    } else {
      await this.handleUpdateLot(valueSubmit, valueId);
      this.closeModal();
    }
  };

  textModal = () => {
    return (
      <div>
        <p>
          Conclua os passos abaixo para{" "}
          <span style={{ fontWeight: "bold" }}>tornar o lote ativo</span>
        </p>
        <ul style={{ marginLeft: 17 }}>
          <li>
            <span style={{ fontWeight: "bold" }}>Adicione animais</span> no lote
          </li>
          <li>
            Aloque o lote em um{" "}
            <span style={{ fontWeight: "bold" }}>piquete/baia</span>
          </li>
          <li>
            Defina uma{" "}
            <span style={{ fontWeight: "bold" }}>Estratégia de dieta</span> para
            o lote em <span style={{ fontWeight: "bold" }}>Manejos</span>
          </li>
        </ul>
      </div>
    );
  };

  handleFilterDietStrategies = (values) => {
    const { allDietStrategies } = this.state;
    const {
      lot: { lotIdEdit },
    } = this.props;
    let newDietStrategiesList = [];
    if (values?.breeId !== null && values?.gender !== null) {
      if (lotIdEdit) {
        newDietStrategiesList = allDietStrategies.filter(
          (e) =>
            e.status === "Active" &&
            (e.lot?.id === lotIdEdit ||
              (e.lot === null &&
                e.breedId === values.breedId &&
                e.cap === values.cap &&
                e.gender === values.gender))
        );
      } else {
        newDietStrategiesList = allDietStrategies.filter(
          (e) =>
            e.status === "Active" &&
            e.lot === null &&
            e.breedId === values.breedId &&
            e.cap === values.cap &&
            e.gender === values.gender
        );
      }
    }

    /** If not fiend in the diet stategy the current dietStrategy selected, remove */
    if (
      newDietStrategiesList.filter(
        (ds) => ds.id === values.dietStrategyBaseLine?.dietStrategyId
      ).length === 0
    ) {
      this.formik.current.setFieldValue("dietStrategyBaseLine", null);
      this.formik.current.setFieldValue("dietStrategies", null);
    }

    this.setState({
      listofDietStrategies: newDietStrategiesList,
    });
  };

  handleSubmitModalForm = async (values, actions) => {
    const { lotAlreadyHavePicket } = this.state;
    values.dietStrategies =
      values.dietStrategies != null && values.dietStrategies.length > 0
        ? values.dietStrategies.map((ds) => ({
            dietStrategyId: ds.dietStrategyId,
            baseline: ds.baseline,
          }))
        : [];
    let lot = {
      name: values.name,
      identification: values.identification,
      asymptoticWeight: values.asymptoticWeight,
      carcassHarnessing: values.carcassHarnessing,
      profitCenterId: values.profitCenterId,
      dietStrategies: [...values.dietStrategies],
      breedId: values.breedId,
      gender: values.gender,
      cap: values.cap,
      referenceAcquisitionDate: values.referenceAcquisitionDate,
      referenceAcquisitionWeight: values.referenceAcquisitionWeight,
      referenceBirthdayDate: values.referenceBirthdayDate,
      status: values.status,
      averageAnimalStay: values.averageAnimalStay,
      changeAnimalsFunction: values.changeAnimalsFunction,
      animalFarmFunction: values.animalFarmFunction,
      picketId: values.picketId || null,
      picketLotEntry: values.picketLotEntry || null,
    };
    if (values.dietStrategyBaseLine != null) {
      const { dietStrategyId, baseline } = values.dietStrategyBaseLine;
      lot = {
        ...lot,
        dietStrategies: [...lot.dietStrategies, { dietStrategyId, baseline }],
      };
    }
    if (
      lotAlreadyHavePicket === false &&
      lot.picketId !== null &&
      lot.picketLotEntry == null
    ) {
      notification.error({
        message:
          "É necessário informar uma data de entrada do lote no piquete selecionado.",
      });
      this.formik.current.setErrors({
        picketLotEntry:
          "É necessário informar uma data de entrada do lote no piquete",
      });
      return;
    }
    if (
      lot.carcassHarnessing != null &&
      lot.carcassHarnessing != "" &&
      (lot.carcassHarnessing < 45 || lot.carcassHarnessing > 60)
    ) {
      notification.error({
        message:
          'O valor do campo "Aproveitamento de Carcaça" deve estar entre o intervalo de valores (45% e 60%)',
      });
      return;
    } else {
      if (values?.status === "Pending") {
        this.openModal(values, lot);
      } else {
        if (values.id === null) {
          await this.handleNewLot(lot);
        } else {
          await this.handleUpdateLot(lot, values.id);
        }
      }
    }
  };

  handleNewLot = async (lot) => {
    const {
      lotActions,
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      history,
    } = this.props;
    const saveOptions = this.state.saveOptions === "only_save" ? false : true;
    await lotActions.saveLotProduction(
      lot,
      groupId,
      farmId,
      null,
      saveOptions,
      history
    );

    if (saveOptions) {
      this.handleDrawerVisible(saveOptions);
    }
  };

  handleUpdateLot = async (lot, id) => {
    const {
      lotActions,
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      history,
    } = this.props;
    await lotActions.saveLotProduction(
      lot,
      groupId,
      farmId,
      id,
      false,
      history
    );
  };

  handleDrawerVisible = async (visible) => {
    if (visible) {
      const {
        app: {
          groupSelected,
          farmSelected,
          groupSelected: { id: groupId },
          farmSelected: { id: farmId },
        },
        lot: { lotIdEdit },
      } = this.props;
      this.setState({
        isLoading: true,
      });
      if (
        Object.entries(groupSelected).length > 0 &&
        Object.entries(farmSelected).length > 0
      ) {
        await Promise.all([
          this.getProfitCenters(groupSelected, farmSelected),
          this.getBreeds(groupId, farmId),
          this.getPickets(groupId, farmId),
        ]);
        if (lotIdEdit != null) {
          const {
            data: { results: lotData },
          } = await getLotShow({
            groupId: groupSelected.id,
            farmId: farmSelected.id,
            id: lotIdEdit,
          });
          await Promise.all([
            this.getDietStrategies(lotData),
            this.getAmountAnimals(lotIdEdit),
          ]);
          this.setState({
            formLot: {
              ...lotData,
              dietStrategyBaseLine: lotData?.dietStrategies.filter(
                (item) => item.baseline === true
              )[0],
              dietStrategies: lotData?.dietStrategies.filter(
                (item) => item.baseline === false
              ),
            },
            lotAlreadyHavePicket: lotData?.picketId !== null,
            isLoading: false,
            selectedPicket: null,
          });
        } else {
          this.getDietStrategies(null);
          this.formik.current.resetForm({
            id: null,
            name: "",
            breedId: null,
            gender: "Male",
            cap: false,
            identification: null,
            asymptoticWeight: "",
            carcassHarnessing: "",
            profitCenterId: null,
            dietStrategyBaseLine: null,
            dietStrategies: [],
            referenceAcquisitionDate: null,
            referenceAcquisitionWeight: "",
            referenceBirthdayDate: null,
            status: "Pending",
            picketId: null,
            picketLotEntry: null,
          });
          this.setState({
            formLot: {
              id: null,
              name: "",
              breedId: null,
              gender: "Male",
              cap: false,
              identification: null,
              asymptoticWeight: "",
              carcassHarnessing: "",
              profitCenterId: null,
              dietStrategyBaseLine: null,
              dietStrategies: [],
              referenceAcquisitionDate: null,
              referenceAcquisitionWeight: "",
              referenceBirthdayDate: null,
              status: "Pending",
              picketId: null,
              picketLotEntry: null,
            },
            isLoading: false,
            lotAlreadyHavePicket: false,
          });
        }
      }
    } else {
      this.setState({
        formLot: {
          id: null,
          name: "",
          breedId: null,
          gender: "Male",
          cap: false,
          identification: null,
          asymptoticWeight: "",
          carcassHarnessing: "",
          profitCenterId: null,
          dietStrategyBaseLine: null,
          dietStrategies: [],
          referenceAcquisitionDate: null,
          referenceAcquisitionWeight: "",
          referenceBirthdayDate: null,
          status: "Pending",
          picketId: null,
          picketLotEntry: null,
        },
        selectedPicket: null,
      });
    }
  };

  handleSelectDietStrategyBaseLine = (value, props) => {
    if (value == null) {
      props.setFieldValue("dietStrategyBaseLine", null);
      props.setFieldValue("dietStrategies", null);
    } else {
      props.setFieldValue("dietStrategyBaseLine", {
        dietStrategyId: value,
        baseline: true,
      });
      props.setFieldValue(
        "dietStrategies",
        props.values.dietStrategies != null
          ? props.values.dietStrategies.filter(
              (ds) => ds.dietStrategyId !== value
            )
          : null
      );
    }
  };

  handleSelectOptionalDietStrategy = (value, props) => {
    let selectedArray = [];
    value.map((selected) =>
      selectedArray.push({
        dietStrategyId: selected,
        baseline: false,
      })
    );
    props.setFieldValue("dietStrategies", selectedArray);
  };

  handleChangeAge = (e, type, props) => {
    let newDate = null;
    switch (type) {
      case "days":
        newDate = moment().subtract(e.target.value, "days");
        break;
      case "months":
        newDate = moment().subtract(e.target.value, "months");
        break;
      case "years":
        newDate = moment().subtract(e.target.value, "years");
        break;
      default:
        break;
    }
    props.setValues({
      ...props.values,
      averageAnimalStay: moment().diff(newDate, "days"),
    });
  };

  handleAskUpdateLotAcquisitionData = () => {
    Modal.confirm({
      width: 500,
      centered: true,
      title: "Atenção!",
      content:
        "Ao atualizar os dados do Lote, conforme animais, não será possível alterar os dados referentes à aquisição do lote.",
      okText: "Confirmar",
      onOk: () => {
        this.handleUpdateLotAcquisitionData();
      },
    });
  };

  handleUpdateLotAcquisitionData = async () => {
    const {
      app: {
        groupSelected: { id: groupId },
        farmSelected: { id: farmId },
      },
      lot: { lotIdEdit },
    } = this.props;

    if (lotIdEdit !== null) {
      this.setState({
        isLoading: true,
      });
      try {
        const {
          data: { results },
        } = await getLoatAcquisitionData({
          groupId,
          farmId,
          lotId: lotIdEdit,
        });
        this.formik.current.setFieldValue("breedId", results.breedId);
        this.formik.current.setFieldValue(
          "gender",
          results.animalGender === null ? "Male" : results.animalGender
        );
        this.formik.current.setFieldValue(
          "referenceAcquisitionDate",
          results.referenceAcquisitionDate
        );
        this.formik.current.setFieldValue(
          "referenceAcquisitionWeight",
          results.referenceAcquisitionWeight || ""
        );
        this.formik.current.setFieldValue(
          "referenceBirthdayDate",
          results.referenceBirthdayDate
        );
      } catch (error) {
      } finally {
        this.setState({
          isLoading: false,
        });
      }
    }
  };

  render() {
    const {
      app: { translation },
      lot: { lotIdEdit, drawerLotProduction, isLoadingRequest },
    } = this.props;
    const {
      pickets,
      breeds,
      formLot,
      listOfProfitCenters: profitCenters,
      listofDietStrategies: dietStrategies,
      amountAnimals,
      isLoading,
      isLoadingDietStrategies,
      showModal,
      lotAlreadyHavePicket,
      selectedPicket,
    } = this.state;

    const validationSchema = Yup.object().shape({
      name: Yup.string().required(),
      profitCenterId: Yup.string().required(),
      changeAnimalsFunction: Yup.boolean(),
      animalFarmFunction: Yup.string()
        .notRequired()
        .when(["changeAnimalsFunction"], {
          is: (changeAnimalsFunction) => changeAnimalsFunction === true,
          then: Yup.string().required(),
          otherwise: Yup.string().notRequired().nullable(),
        }),
    });

    const { Option } = Select;

    const { Group: RadioGroup } = Radio;

    const { Group: InputGroup } = Input;

    const dateFormat = "DD/MM/YYYY";

    return (
      <Container
        title={
          lotIdEdit != null
            ? translation.lot.production.drawer.titleEdit
            : translation.lot.production.drawer.title
        }
        width={770}
        onClose={this.closeDrawer}
        maskClosable={false}
        visible={drawerLotProduction}
        afterVisibleChange={this.handleDrawerVisible}
      >
        <Formik
          ref={this.formik}
          enableReinitialize={true}
          initialValues={formLot}
          onSubmit={this.handleSubmitModalForm}
          validationSchema={validationSchema}
          render={(props) => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <div className="drawerForm">
                <Spin spinning={isLoading || isLoadingRequest}>
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <span style={{ fontSize: "14px", color: "#000" }}>
                        {lotIdEdit != null
                          ? translation.lot.production.drawer.subtitleEdit
                          : translation.lot.production.drawer.subtitle}
                      </span>
                    </Col>
                  </Row>
                  {Object.entries(props.errors).length > 0 &&
                    props.submitCount > 0 && (
                      <Row type="flex" justify="center" align="middle">
                        <label className="error">
                          {translation.error.formError}
                        </label>
                      </Row>
                    )}
                  {/* Input lot name */}
                  <Row type="flex" align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.name && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.lot.production.drawer.form.name}
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Input
                          placeholder={
                            translation.lot.production.drawer.form
                              .namePlaceHolder
                          }
                          value={props.values.name}
                          name="name"
                          autoComplete="chrome-off"
                          ref={this.inputNameRef}
                          onChange={(e) => {
                            props.setFieldValue("name", e.target.value);
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  {/* Input lot identification */}
                  <Row type="flex" align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label>
                          {
                            translation.lot.production.drawer.form
                              .identification
                          }
                          <InfoTooltip title="A identificação será utilizada nos processos de importação." />
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Input
                          placeholder={
                            translation.lot.production.drawer.form
                              .identificationPlaceHolder
                          }
                          value={props.values.identification}
                          name="name"
                          autoComplete="chrome-off"
                          onChange={(e) => {
                            props.setFieldValue(
                              "identification",
                              e.target.value.length > 0 ? e.target.value : null
                            );
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  {/* Input profitCenterId */}
                  <Row type="flex" align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label
                          className={
                            props.errors.profitCenterId && props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          {translation.lot.production.drawer.form.profitCenter}
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          name="profitCenterId"
                          value={
                            props.values.profitCenterId != null
                              ? profitCenters != null &&
                                profitCenters.find(
                                  (pc) => pc.id === props.values.profitCenterId
                                ) != null
                                ? props.values.profitCenterId
                                : undefined
                              : undefined
                          }
                          placeholder={
                            translation.lot.production.drawer.form
                              .profitCenterPlaceHolder
                          }
                          onChange={(value) => {
                            props.setFieldValue("profitCenterId", value);
                            if (props.values.id === null) {
                              props.setFieldValue("picketId", null);
                              props.setFieldValue("picketLotEntry", null);
                              this.setState({
                                selectedPicket: null,
                              });
                            }
                          }}
                        >
                          {profitCenters != null &&
                          Object.entries(profitCenters).length > 0
                            ? profitCenters.map((pc) =>
                                pc.productionSubSystem !== null &&
                                pc.productionSubSystem !== "" &&
                                pc.productionSystemId !== null ? (
                                  <Option key={pc.id} value={pc.id}>
                                    {pc.name}
                                  </Option>
                                ) : null
                              )
                            : null}
                        </Select>
                      </Row>
                    </Col>
                  </Row>
                  {/* Input  Carcass Harnessing  */}
                  <Row type="flex" justify="start">
                    <Col span={12}>
                      <Row>
                        <label>
                          {
                            translation.lot.production.drawer.form
                              .carcassHarnessing
                          }
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          value={
                            props.values?.carcassHarnessing !== null
                              ? props.values?.carcassHarnessing
                              : null
                          }
                          placeholder={
                            translation.lot.production.drawer.form
                              .informPlaceHolder
                          }
                          name="carcassHarnessing"
                          addonAfter="%"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue(
                              "carcassHarnessing",
                              floatValue
                            );
                          }}
                        />
                      </Row>
                    </Col>

                    <Col span={11} offset={1}>
                      <Row>
                        <label>
                          {
                            translation.lot.production.drawer.form
                              .asymptoticWeight
                          }
                        </label>
                        <InfoTooltip
                          title="Peso assintótico é o peso que, com base nas informações que o usuário/produtor possui, sobre o histórico do Animal ou Lote de Animais, (considerando a genética, manejo anterior, interação genótipo-ambiente, frame, entre outras variáveis), acredite ser o potencial limite de peso. Ou seja, acima deste peso, dificilmente este animal ganhará peso, em condições normais."
                          placement="bottom"
                        />
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          value={
                            props.values?.asymptoticWeight !== null
                              ? props.values?.asymptoticWeight
                              : null
                          }
                          placeholder={
                            translation.lot.production.drawer.form
                              .informPlaceHolder
                          }
                          name="asymptoticWeight"
                          addonAfter="kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          disabled={lotIdEdit == null || amountAnimals === 0}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue("asymptoticWeight", floatValue);
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  {amountAnimals === 0 ? (
                    <InfoCard>
                      <NoteIcon /> Adicione animais ao lote para determinar o
                      Peso assintótico
                    </InfoCard>
                  ) : null}
                  {/************** Input dados de referência *************************/}
                  <CustomDivider dashed>
                    DADOS REFERENTE À AQUISIÇÃO DO LOTE
                  </CustomDivider>
                  {props.values?.id !== null &&
                    props.values.dietStrategyBaseLine == null && (
                      <Row type="flex" justify="end">
                        <ButtonStandard
                          type="button"
                          buttonType="alternative"
                          onClick={this.handleAskUpdateLotAcquisitionData}
                        >
                          Atualizar dados
                        </ButtonStandard>
                      </Row>
                    )}
                  {/* Input Raça, Gender and Cap */}
                  <Row type="flex" justify="start" gutter={4}>
                    {/* Breed */}
                    <Col span={props.values?.gender === "Male" ? 12 : 16}>
                      <Row>
                        <label>Raça</label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          style={{ width: "100%" }}
                          name="breedId"
                          placeholder={
                            translation.animal.edit.form.breedPlaceHolder
                          }
                          value={
                            breeds != null && props.values?.breedId != null
                              ? props.values?.breedId
                              : undefined
                          }
                          disabled={props.values.dietStrategyBaseLine != null}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => {
                            const newValues = {
                              ...props.values,
                              breedId: value,
                            };
                            props.setValues(newValues);
                            this.handleFilterDietStrategies(newValues);
                          }}
                        >
                          {breeds != null &&
                            Object.entries(breeds).length > 0 &&
                            breeds.map((breed) => (
                              <Option key={breed.id} value={breed.id}>
                                {breed.name}
                              </Option>
                            ))}
                        </Select>
                      </Row>
                    </Col>
                    {/* Gender */}
                    <Col span={props.values?.gender === "Male" ? 6 : 8}>
                      <Row>
                        <label>{translation.animal.edit.form.sex}</label>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          style={{ width: "100%" }}
                          name="gender"
                          value={
                            props.values?.gender != null
                              ? props.values?.gender
                              : undefined
                          }
                          disabled={props.values.dietStrategyBaseLine != null}
                          placeholder={translation.defaultSelectPlaceholder}
                          onChange={(value) => {
                            const newValues = {
                              ...props.values,
                              gender: value,
                              cap:
                                value === "Female" ? false : props.values.cap,
                            };
                            props.setValues(newValues);
                            this.handleFilterDietStrategies(newValues);
                          }}
                        >
                          <Option key="Female" value="Female">
                            {translation.animal.edit.form.female}
                          </Option>
                          <Option key="Male" value="Male">
                            {translation.animal.edit.form.male}
                          </Option>
                        </Select>
                      </Row>
                    </Col>
                    {/* Cap */}
                    {props.values?.gender === "Male" ? (
                      <Col span={6}>
                        <Row>
                          <label className="formLabel">
                            {translation.animal.edit.form.cap}
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <RadioGroup
                            name="cap"
                            value={props.values?.cap}
                            disabled={props.values.dietStrategyBaseLine != null}
                            defaultValue={false}
                            onChange={(e) => {
                              const newValues = {
                                ...props.values,
                                cap: e.target.value,
                              };
                              props.setValues(newValues);
                              this.handleFilterDietStrategies(newValues);
                            }}
                          >
                            <RadioButton value={true}>
                              {translation.buttons.yes}
                            </RadioButton>
                            <RadioButton value={false}>
                              {translation.buttons.no}
                            </RadioButton>
                          </RadioGroup>
                        </Row>
                      </Col>
                    ) : null}
                  </Row>
                  {/* Input data de aquisição, Nascimento animal e Peso médio */}
                  <Row type="flex" justify="start" gutter={4}>
                    {/* Input data aquisição animal */}
                    <Col span={8}>
                      <Row>
                        <label>Data de aquisição</label>
                      </Row>
                      <Row className="rowInput">
                        <DatePicker
                          name="referenceAcquisitionDate"
                          allowClear={false}
                          value={
                            props.values.referenceAcquisitionDate != null
                              ? moment(
                                  props.values.referenceAcquisitionDate,
                                  "YYYY-MM-DD"
                                )
                              : null
                          }
                          disabled={props.values.dietStrategyBaseLine != null}
                          placeholder={translation.defaultDatePickerPlaceholder}
                          format={dateFormat}
                          onChange={(date, dateString) => {
                            if (date <= moment() && date !== null) {
                              props.setValues({
                                ...props.values,
                                referenceAcquisitionDate: date,
                              });
                            }
                          }}
                        />
                      </Row>
                    </Col>
                    {/* Input nascimento animal */}
                    <Col span={8}>
                      <Row>
                        <label>Nascimento animal</label>
                      </Row>
                      <Row className="rowInput">
                        <DatePicker
                          name="referenceBirthdayDate"
                          allowClear={false}
                          value={
                            props.values.referenceBirthdayDate != null
                              ? moment(
                                  props.values.referenceBirthdayDate,
                                  "YYYY-MM-DD"
                                )
                              : null
                          }
                          disabled={props.values.dietStrategyBaseLine != null}
                          placeholder={translation.defaultDatePickerPlaceholder}
                          format={dateFormat}
                          onChange={(date, dateString) => {
                            if (date <= moment() && date !== null) {
                              props.setValues({
                                ...props.values,
                                referenceBirthdayDate: date,
                              });
                            }
                          }}
                        />
                      </Row>
                    </Col>
                    {/* Input peso médio por animal */}
                    <Col span={8}>
                      <Row>
                        <label>Peso médio por animal</label>
                      </Row>
                      <Row className="rowInput">
                        <NumberFormat
                          customInput={Input}
                          value={
                            props.values?.referenceAcquisitionWeight !== null
                              ? props.values?.referenceAcquisitionWeight
                              : null
                          }
                          placeholder={
                            translation.lot.production.drawer.form
                              .informPlaceHolder
                          }
                          disabled={props.values.dietStrategyBaseLine != null}
                          name="referenceAcquisitionWeight"
                          addonAfter="Kg"
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale={true}
                          onValueChange={({ floatValue }) => {
                            props.setFieldValue(
                              "referenceAcquisitionWeight",
                              floatValue
                            );
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <CustomDivider dashed />
                  {/* Input mudar finalidade dos animais */}
                  {props.values.id !== null && (
                    <Row type="flex" justify="start">
                      <Col span={16}>
                        <Row>
                          <label
                            className={
                              props.errors.averageAnimalStay &&
                              props.submitCount > 0
                                ? "error"
                                : ""
                            }
                          >
                            Deseja mudar a "Finalidade" dos animais do Lote?
                          </label>
                        </Row>
                        <Row className="rowInput">
                          <RadioGroup
                            name="changeAnimalsFunction"
                            defaultValue={false}
                            value={
                              props.values.changeAnimalsFunction === true
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              props.setFieldValue(
                                "changeAnimalsFunction",
                                e.target.value
                              )
                            }
                          >
                            <RadioButton value={true}>
                              {translation.buttons.yes}
                            </RadioButton>
                            <RadioButton value={false}>
                              {translation.buttons.no}
                            </RadioButton>
                          </RadioGroup>
                        </Row>
                      </Col>
                      {props.values.changeAnimalsFunction === true && (
                        <Col span={8}>
                          <Row>
                            <label
                              className={
                                props.errors.animalFarmFunction &&
                                props.submitCount > 0
                                  ? "formLabel error"
                                  : "formLabel"
                              }
                            >
                              Nova Finalidade*
                            </label>
                          </Row>
                          <Row className="rowInput">
                            <Select
                              name="animalFarmFunction"
                              value={
                                props.values.animalFarmFunction || undefined
                              }
                              placeholder={translation.defaultSelectPlaceholder}
                              onChange={(value) => {
                                props.setFieldValue(
                                  "animalFarmFunction",
                                  value
                                );
                              }}
                            >
                              <Option value="reproduction">Reprodução</Option>
                              <Option value="fatten">Engorda</Option>
                            </Select>
                          </Row>
                        </Col>
                      )}
                    </Row>
                  )}
                  {/* Input média de permanência dos animais na fazenda */}
                  <Row type="flex" justify="start">
                    <Col span={20}>
                      <Row>
                        <label
                          className={
                            props.errors.averageAnimalStay &&
                            props.submitCount > 0
                              ? "error"
                              : ""
                          }
                        >
                          Média de permanência dos animais na fazenda
                        </label>
                      </Row>
                      <Row className="rowInput">
                        <InputGroup compact>
                          <Input
                            type="number"
                            style={{ width: 74, textAlign: "center" }}
                            name="ageDay"
                            min="0"
                            placeholder="Dias"
                            value={
                              props.values.averageAnimalStay != null
                                ? props.values.averageAnimalStay
                                : null
                            }
                            onChange={(e) =>
                              this.handleChangeAge(e, "days", props)
                            }
                          />
                          <Input
                            style={{
                              width: 74,
                              textAlign: "center",
                              borderLeft: "1px solid #c4c4c4",
                            }}
                            type="number"
                            name="ageMonth"
                            min="0"
                            placeholder="Meses"
                            value={
                              props.values.averageAnimalStay != null
                                ? Math.round(
                                    moment
                                      .duration(
                                        props.values.averageAnimalStay,
                                        "days"
                                      )
                                      .asMonths()
                                  )
                                : null
                            }
                            onChange={(e) =>
                              this.handleChangeAge(e, "months", props)
                            }
                          />
                          <Input
                            style={{
                              width: 74,
                              textAlign: "center",
                              borderLeft: "1px solid #c4c4c4",
                            }}
                            type="number"
                            name="ageYear"
                            min="0"
                            placeholder="Anos"
                            value={
                              props.values.averageAnimalStay != null
                                ? Math.round(
                                    moment
                                      .duration(
                                        props.values.averageAnimalStay,
                                        "days"
                                      )
                                      .asYears()
                                  )
                                : null
                            }
                            onChange={(e) =>
                              this.handleChangeAge(e, "years", props)
                            }
                          />
                        </InputGroup>
                      </Row>
                    </Col>
                  </Row>
                  {/* Input dietStrategies */}
                  <CustomDivider dashed />
                  {/* Input dietStrategyBaseLine */}
                  <Row type="flex">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <label>
                          {
                            translation.lot.production.drawer.form
                              .dietStrategyBaseLine
                          }
                        </label>
                        <p>
                          {
                            translation.lot.production.drawer.form
                              .dietStrategyBaseLineMessage
                          }
                        </p>
                      </Row>
                      <Row className="rowInput">
                        <Select
                          name="dietStrategyBaseLine"
                          loading={isLoadingDietStrategies}
                          value={
                            dietStrategies.length > 0
                              ? props.values.dietStrategyBaseLine != null
                                ? props.values.dietStrategyBaseLine
                                    .dietStrategyId
                                : undefined
                              : undefined
                          }
                          placeholder="Selecione a estratégia"
                          allowClear
                          onChange={(value) =>
                            this.handleSelectDietStrategyBaseLine(value, props)
                          }
                          style={{ marginTop: 6 }}
                        >
                          {dietStrategies.map((ds) => (
                            <Option key={ds.id} value={ds.id}>
                              {ds.name}
                            </Option>
                          ))}
                        </Select>
                      </Row>
                    </Col>
                  </Row>
                  {!lotAlreadyHavePicket ? (
                    <>
                      <CustomDivider dashed>
                        ALOCAR LOTE EM PIQUETE
                      </CustomDivider>
                      <Row type="flex" gutter={16}>
                        <Col span={12}>
                          <Row>
                            <label>
                              {translation.lot.production.drawer.form.picketId}
                            </label>
                          </Row>
                          <Row className="rowInput">
                            <Select
                              name="picketId"
                              value={props.values.picketId || undefined}
                              placeholder="Selecione o piquete"
                              allowClear
                              onChange={(value) => {
                                props.setFieldValue("picketId", value);
                                if (value == null) {
                                  props.setFieldValue("picketLotEntry", null);
                                  this.setState({
                                    selectedPicket: null,
                                  });
                                } else {
                                  this.setState({
                                    selectedPicket: pickets.find(
                                      (p) => p.id === value
                                    ),
                                  });
                                }
                              }}
                            >
                              {pickets
                                .filter((picket) => picket.status === "Break")
                                .filter(
                                  (picket) =>
                                    picket.profitCenterId ===
                                    props.values.profitCenterId
                                )
                                .map((picket) => (
                                  <Option key={picket.id} value={picket.id}>
                                    {picket.name}
                                  </Option>
                                ))}
                            </Select>
                          </Row>
                        </Col>
                        <Col span={8}>
                          <Row>
                            <label
                              className={
                                props.errors.picketLotEntry &&
                                props.submitCount > 0
                                  ? "error"
                                  : ""
                              }
                            >
                              {
                                translation.lot.production.drawer.form
                                  .picketLotEntry
                              }
                              {selectedPicket != null && "*"}
                            </label>
                          </Row>
                          <Row className="rowInput">
                            <DatePicker
                              name="picketLotEntry"
                              allowClear
                              value={
                                props.values.picketLotEntry != null
                                  ? moment(
                                      props.values.picketLotEntry,
                                      "YYYY-MM-DD"
                                    )
                                  : undefined
                              }
                              placeholder={
                                translation.defaultDatePickerPlaceholder
                              }
                              format={dateFormat}
                              disabledDate={(current) =>
                                selectedPicket != null &&
                                selectedPicket.lastLotMovimentDate != null
                                  ? current.isBefore(
                                      moment(selectedPicket.lastLotMovimentDate)
                                    ) || current.isAfter(moment())
                                  : current.isAfter(moment())
                              }
                              disabled={!selectedPicket}
                              onChange={(date) => {
                                props.setFieldValue("picketLotEntry", date);
                              }}
                            />
                          </Row>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <CustomDivider dashed>
                        DADOS REFERENTE AO PIQUETE
                      </CustomDivider>
                      <Row type="flex" gutter={16}>
                        <Col span={12}>
                          <Row>
                            <label>
                              {translation.lot.production.drawer.form.picketId}
                            </label>
                          </Row>
                          <Row className="rowInput">
                            <strong>{props.values.picketName}</strong>
                          </Row>
                        </Col>
                        {props.values.picketLotEntry != null ? (
                          <Col span={8}>
                            <Row>
                              <label>
                                {
                                  translation.lot.production.drawer.form
                                    .picketLotEntry
                                }
                              </label>
                            </Row>
                            <Row className="rowInput">
                              <strong>
                                {moment(props.values.picketLotEntry).format(
                                  "DD/MM/YYYY"
                                )}
                              </strong>
                            </Row>
                          </Col>
                        ) : null}
                      </Row>
                    </>
                  )}
                </Spin>
              </div>
              {/* Footer */}
              <Footer>
                <Row type="flex" justify="space-between">
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="selectDiv"
                  >
                    {props.values.id === null ? (
                      <Select
                        value={this.state.saveOptions}
                        name="saveOptions"
                        onChange={(value) =>
                          this.setState({ saveOptions: value })
                        }
                      >
                        <Option key="1" value="only_save">
                          {translation.lot.production.saveOptions.only_save}
                        </Option>
                        <Option key="2 " value="save_and_create_new">
                          {
                            translation.lot.production.saveOptions
                              .save_and_create_new
                          }
                        </Option>
                      </Select>
                    ) : null}
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="buttonsDiv"
                  >
                    <ButtonStandard
                      type="button"
                      buttonType="type7"
                      onClick={this.closeDrawer}
                    >
                      {translation.buttons.cancel}
                    </ButtonStandard>

                    <ButtonStandard type="submit" buttonType="type6">
                      {translation.buttons.save}
                    </ButtonStandard>
                  </Col>
                </Row>
              </Footer>
            </form>
          )}
        />
        <OkActionModal
          show={showModal}
          cancelAction={false}
          confirmAction={this.onSubmitModal}
          title="Lote pendente"
          message={this.textModal()}
          icon={null}
          colorTitle="#FE8D2A"
          labelButton="Ok, entendi"
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  lot: state.lot,
});

const mapDispatchToProps = (dispatch) => ({
  lotActions: bindActionCreators(LotActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DrawerLotProduction)
);
